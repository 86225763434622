import * as React from 'react';
import * as _ from 'lodash';
import { parseColorToString } from '@utils/graphicsUtils';

import { UIComponent } from '@editor/comps';
import { IComponentData } from '@fbs/rp/models/component';
import { IconValue } from '@fbs/rp/models/value';
import * as SystemsColor from '@consts/colors';
import { StyleHelper } from '@helpers/styleHelper';
import appOptions from '@helpers/appOptions';
import { IProperties } from '@/fbs/rp/models/property';
import { ISize } from '@/fbs/common/models/common';
import { IComponentValue } from '@fbs/rp/models/value';
import { SizeMode } from '@/libs/enum';
import i18n from '@/i18n';

import { makeCommonComponent, getDefaultShadow } from '../../helper';
import { CIcon } from '../../constants';
import { IComponentItem } from '../../types';

import initCSSStyleParser = StyleHelper.initCSSStyleParser;

interface IIconProps {
  comp: UIComponent;
}

export const config: IComponentItem = {
  type: CIcon,
  name: i18n('resource.components.icon'),
  hidden: true,
  thumb: {},
  sizeMode: SizeMode.ratio,
  getDefaultData: () => {
    return {
      properties: {
        shadow: {
          ...getDefaultShadow(),
          disabled: true,
        },
        icon: {
          color: SystemsColor.DefaultIconColor,
        },
      },
    };
  },
};

export function makeIcon(id: string, data?: Partial<IComponentData>): IComponentData {
  return makeCommonComponent(id, CIcon, {
    size: {
      width: appOptions.lastIconSize,
      height: appOptions.lastIconSize,
      lockedRatio: true,
    },
    properties: {},
    states: {
      disabled: {
        enabled: false,
        opacity: 30,
      },
    },
    ...data,
  });
}

// Pure
export default class Icon extends React.Component<IIconProps, { style: React.CSSProperties; value: string }> {
  constructor(props: IIconProps) {
    super(props);
    const { value } = props.comp;
    this.state = {
      style: this.doParsePropertiesToStyle(props),
      value: value ? String.fromCharCode((value as IconValue).iconCode) : '',
    };
    this.param = this.getParams(props);
  }

  private param: {
    size: ISize;
    properties: IProperties;
    value?: IComponentValue;
    opacity: number;
    transition: string;
    version: string;
  } & { [key: string]: any };

  private getParams(props: IIconProps) {
    const { size, properties, value, opacity, version } = props.comp;
    const transition = props.comp.getTransition();
    return {
      size: _.cloneDeep(size),
      properties: _.cloneDeep(properties),
      value: _.cloneDeep(value),
      transition,
      opacity,
      version,
    };
  }

  shouldComponentUpdate(nextProps: IIconProps) {
    const newParam: { [key: string]: any } = this.getParams(nextProps);

    let flag = false;
    Object.keys(this.param).forEach((key) => {
      if (!_.isEqual(newParam[key], this.param[key])) {
        flag = true;
        this.param[key] = _.cloneDeep(newParam[key]);
      }
    });

    return flag;
  }

  UNSAFE_componentWillReceiveProps(nextProps: IIconProps) {
    this.setState({
      style: this.doParsePropertiesToStyle(nextProps),
      value: String.fromCharCode((nextProps.comp.value as IconValue).iconCode),
    });
  }

  doParsePropertiesToStyle = (props: IIconProps): React.CSSProperties => {
    const { size, properties, value, opacity } = props.comp;
    const { width, height } = size;
    const compSize = Math.min(width, height);
    const fontSize = Math.max(12, compSize);
    const parser = initCSSStyleParser(properties);

    const style: React.CSSProperties = {
      fontFamily: value ? (value as IconValue).fontName || '' : '',
      fontSize,
      lineHeight: `${fontSize}px`,
      textAlign: 'center',
      color: properties.icon ? parseColorToString(properties.icon.color!) : '#000',
      transition: props.comp.getTransition(),
      ...parser.getTextShadow(),
      width,
      height,
    };
    let scale = 1;
    if (compSize < 12) {
      scale = compSize / 12;
      style.width = style.height = 12;
      style.transform = `scale(${scale})`;
      style.transformOrigin = '0 0';
    }
    if (_.isUndefined(opacity)) {
      style.opacity = 1;
    } else {
      style.opacity = opacity / 100;
    }
    return style;
  };

  render() {
    const { comp } = this.props;
    if (comp.opacity === 0) {
      return null;
    }
    return (
      <div className="lib-comp-icon" style={{ ...this.state.style, transition: comp.getTransition() }}>
        {this.state.value}
      </div>
    );
  }
}
