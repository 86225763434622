/****************************************************************
 * 仪表盘常量
 ****************************************************************/

export const StageColorList = [
  ['#2CD5BC', '#009DFF', '#FF9D43'],
  ['#93D2F9', '#4AB8FC', '#009DFF'],
  ['#A2EBD0', '#5DDDAC', '#17CE89'],
];

export const GradeColorList = [
  ['#2CD5BC', '#009DFF', '#FF9D43', '#FF6666'],
  ['#DDEDF6', '#93D2F9', '#4AB8FC', '#009DFF'],
  ['#E8FAF3', '#A2EBD0', '#5DDDAC', '#17CE89'],
];

// 如果仪表盘组件最小边小于该值，仪表盘上的部分元素同比缩小，维持组件元素基本正常
export const MinScaleWidth = 350;

// 仪表盘占组件的比例
export const RadiusRatio = 0.9;

// 仪表盘值占半径比例
export const ValueOffsetRatio = 0.4;

export const StartAngle = 270;

export const EndAngle = -90;

// 分割线和刻度线离轴线距离
export const SplitDistance = 10;

// Normal Split length
export const SplitLengthRatio1 = 0.08;

// Grade Split length
export const SplitLengthRatio2 = 0.1;

// Grade pointer length
export const GradePointerLengthRatio = 0.12;
