import PropertyBase from './base';
import { Color, ILinearGradientColor, IRadialGradientColor } from './color';

export enum FillType {
  solid = 'solid',
  linear = 'linear',
  radial = 'radial',
}

export type FillData = Color | ILinearGradientColor | IRadialGradientColor;

export default interface IFill extends PropertyBase {
  type?: FillType;
  color?: FillData;
}


export const FillPropertyName = 'fill';
