import * as React from 'react';

import { isEqual } from 'lodash';
import classNames from 'classnames';

import i18n from '@/i18n';
import { MobileTypeList, previewAndDownWatchTypeList, VehicleTypeList } from '@/helpers/shellHelper';
import { IShareOption } from '@/helpers/previewHelper';
import { ControlPanelState } from '@/fbs/rp/models/preview';
import { MobileType } from '@/fbs/rp/utils/preview';

// import { Checkbox, CheckboxGroup } from '@/dsm2';
import { Label, Select, IListItem, CheckBox, CheckBoxGroup } from '@/dsm';

import './index.scss';

interface IControllerPanelData {
  id: keyof IShareOption;
  text: string;
  checked: boolean;
}

interface IPreviewCommonOptions {
  appType: string;
  shareOpt: IShareOption;
  filterKeys?: typeof OPTIONS[number][];
  onChange(value: IShareOption): void;
}

const ControlPanelKey = 'controlPanelState'; // 控制面板key值
const RemarkPanelKey = 'remarkPanelState'; // 备注面板key值
const ShowNavKey = 'showNavigationBar'; // 是否自动隐藏导航条

const CONTROL_PANEL_DATA = [
  { id: ControlPanelKey, text: i18n('preview.showPagePanel') },
  { id: RemarkPanelKey, text: i18n('preview.showRemarkPanel') },
  { id: ShowNavKey, text: i18n('preview.showNavigationBar') },
];

const OPTIONS = ['showLinkAreaWhenHovered', 'alwaysShowLinkArea', 'noBoundary', 'showRemarkTag', 'autoScreen'] as const;

const PreviewCommonOptions: React.FC<IPreviewCommonOptions> = ({
  appType,
  shareOpt,
  filterKeys,
  onChange,
}: IPreviewCommonOptions) => {
  const realControlPanelData = React.useMemo(() => {
    const isControlPanelExpand = shareOpt.controlPanelState === ControlPanelState.Expand;
    const isRemarkPanelExpand = shareOpt.remarkPanelState === ControlPanelState.Expand;
    const isShowNavigationBar = shareOpt.showNavigationBar;

    return CONTROL_PANEL_DATA.map((item) => {
      const checked =
        (item.id === ControlPanelKey && isControlPanelExpand) ||
        (item.id === RemarkPanelKey && isRemarkPanelExpand) ||
        (item.id === ShowNavKey && isShowNavigationBar);
      return { ...item, checked };
    }) as IControllerPanelData[];
  }, [shareOpt.controlPanelState, shareOpt.remarkPanelState, shareOpt.showNavigationBar]);

  // 修改每项的值
  const handleSubmit = (key: keyof IShareOption, value: string | boolean | number | ControlPanelState) => {
    if (!isEqual(shareOpt[key], value)) {
      onChange({ ...shareOpt, [key]: value });
    }
  };

  // 修改控制面板的值
  const handleControlPanelChange = (checked: boolean) => {
    const state = checked ? ControlPanelState.Expand : ControlPanelState.Hide;
    const newOpt = {
      ...shareOpt,
      controlPanelState: state,
      remarkPanelState: state,
      showNavigationBar: checked,
    };
    onChange(newOpt);
  };

  // 修改控制面板子项的值
  const handleControlPanelItemChange = ({ id, checked }: { id: string | number; checked: boolean }) => {
    const state = checked ? ControlPanelState.Expand : ControlPanelState.Hide;
    if (id === ControlPanelKey) {
      handleSubmit(ControlPanelKey, state);
    } else if (id === RemarkPanelKey) {
      handleSubmit(RemarkPanelKey, state);
    } else if (id === ShowNavKey) {
      handleSubmit(ShowNavKey, checked);
    }
  };

  const handleShareOptChecked = {
    showNavigationBar: (value: boolean) => {
      handleSubmit('showNavigationBar', value);
    },
    alwaysShowLinkArea(value: boolean) {
      handleSubmit('alwaysShowLinkArea', value);
    },
    showLinkAreaWhenHovered(value: boolean) {
      handleSubmit('showLinkAreaWhenHovered', value);
    },
    noBoundary(value: boolean) {
      handleSubmit('noBoundary', value);
    },
    controlPanelState(value: ControlPanelState) {
      handleSubmit('controlPanelState', value);
    },
    remarkPanelState(value: ControlPanelState) {
      handleSubmit('remarkPanelState', value);
    },
    showRemarkTag(value: boolean) {
      handleSubmit('showRemarkTag', value);
    },
    mobileType(value: IListItem) {
      handleSubmit('mobileType', value.id);
    },
    autoScreen(value: boolean) {
      handleSubmit('autoScreen', value);
    },
  };

  // 移动项目
  const renderMobileType = () => {
    let isShow = true;
    let title = i18n('preview.mobileType');
    let data: {
      id: MobileType;
      text: string;
    }[] = [];
    switch (appType) {
      case 'watch':
        data = previewAndDownWatchTypeList;
        break;
      case 'vehicle':
        data = VehicleTypeList;
        break;
      case 'phone':
      case 'pad':
        data = MobileTypeList;
        break;
      default:
        isShow = false;
        break;
    }
    return (
      isShow && (
        <div className="device-select">
          <Label>{title}</Label>
          <Select
            theme="light"
            data={data}
            isInPopup
            selectedIndex={data.findIndex((item) => item.id === shareOpt.mobileType)}
            onSelect={handleShareOptChecked['mobileType']}
          />
        </div>
      )
    );
  };

  // 控制面板显示设置
  const renderControlPanelSetting = () => {
    const checked = realControlPanelData.some((item) => item.checked);
    const indeterminate = realControlPanelData.every((item) => item.checked);
    return (
      <div className="control-panel-setting">
        <CheckBox
          checked={indeterminate ? checked : undefined}
          text={i18n('preview.showControlInterface')}
          onChange={handleControlPanelChange}
          theme="light"
          indeterminate={!indeterminate && checked}
        />
        {checked && (
          <div className="checkbox-group-wrap">
            <CheckBoxGroup data={realControlPanelData} onChange={handleControlPanelItemChange} theme="light" />
          </div>
        )}
      </div>
    );
  };

  const renderShareOptSetting = () => {
    const showOpts = OPTIONS.filter((item) => !(filterKeys || []).includes(item));
    return (
      <div className={classNames('share-opt')}>
        {showOpts.map((item) => (
          <div className="opt-item" key={item}>
            <CheckBox
              checked={shareOpt[item] as boolean}
              theme="light"
              text={i18n(`preview.${item}`)}
              onChange={handleShareOptChecked[item as typeof OPTIONS[number]]}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="preview-common-options">
      {renderMobileType()}
      {renderControlPanelSetting()}
      {renderShareOptSetting()}
    </div>
  );
};

export default React.memo(PreviewCommonOptions);
