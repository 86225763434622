import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import classnames from 'classnames';
import { isUndefined } from 'lodash';

import './index.scss';

export type ScrollViewProp = {
  autoHeight?: boolean;
  autoHeightMin?: number;
  autoHeightMax?: number;
  width?: number | string;
  height?: number | string;
  setRef?: React.RefObject<Scrollbars>;
  children?: React.ReactChildren[] | React.ReactChildren | any;
  disabledScroll?: boolean;
  className?: string;
  onScroll?(e: React.UIEvent): void;
  onScrollStart?(): void;
  onScrollStop?(): void;
};

function ScrollView(props: ScrollViewProp) {
  const {
    width,
    height,
    children,
    setRef,
    autoHeight,
    autoHeightMin,
    autoHeightMax,
    disabledScroll,
    className,
    onScroll,
    onScrollStart,
    onScrollStop,
  } = props;

  return (
    <Scrollbars
      autoHeight={autoHeight}
      autoHeightMin={autoHeightMin}
      autoHeightMax={autoHeightMax}
      className={classnames('dsm-c-scrollview', className, {
        disabled: disabledScroll,
      })}
      style={{ width, height }}
      autoHide={true}
      renderTrackHorizontal={(props: any) => <div {...props} className="track-bar h-track" />}
      renderTrackVertical={(props: any) => <div {...props} className="track-bar v-track" />}
      renderView={({ style, ...props }) => (
        <div
          {...props}
          className="dsm-c-scrollview-box"
          style={{
            ...style,
            marginBottom: 0,
            marginRight: 0,
            maxHeight:
              isUndefined(style?.maxHeight) || isUndefined(style?.marginBottom)
                ? undefined
                : style.maxHeight + style.marginBottom,
            minHeight: 'unset',
          }}
        />
      )}
      ref={setRef}
      onScroll={onScroll}
      onScrollStart={onScrollStart}
      onScrollStop={onScrollStop}
    >
      {children}
    </Scrollbars>
  );
}

ScrollView.defaultProps = {
  autoHeight: false,
  autoHeightMin: 0,
  autoHeightMax: 200,
  width: '100%',
  height: '100%',
};

export default ScrollView;
