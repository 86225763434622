import { IComponentData } from '@/fbs/rp/models/component';
import { EChartType_basic } from '@/fbs/rp/models/properties/chart';
import { IChartValue } from '@/fbs/rp/models/chart';
import { makeCommonComponent } from '@/libs/helper';

import { IComponentItem } from '../../../types';
import { CLineChart } from '../../../constants';
import { getChartConfig, makeChartData, getSeriesChartOneDefaultData } from '../common';

export const LineChartConfig: IComponentItem = getChartConfig(CLineChart, EChartType_basic.Line);

export function makeLineChart(id: string): IComponentData {
  return makeChartData(id, CLineChart, EChartType_basic.Line);
}

export function makeAILineChart(id: string, value: IChartValue): IComponentData {
  return makeCommonComponent(id, CLineChart, {
    ...getSeriesChartOneDefaultData(CLineChart, EChartType_basic.Line),
    size: {
      width: 400,
      height: 250,
    },
    lib: {
      id: 'chart',
      type: CLineChart,
    },
    value: value,
  });
}
