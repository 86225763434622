/* *****************************************************************************
 * 自定义的数学函数及方法，其中部分常用的通过自定义计算方式实现，就项目使用情况来说，性能高于对应原生方法
 * TODO 该文件目前暂未启用，之前这些方法放在 globalUtils中，待 2021-3的版本发布后，全局统一替换到这个模块
 * @author Matt 2021-3-19
 ***************************************************************************** */

const EPSILON = 0.00001;

/**
 * 获取两个数之间差值
 * @param {number} v1
 * @param {number} v2
 * @returns {number}
 */
function distance(v1: number, v2: number): number {
  if (v1 > v2) {
    return v1 - v2;
  }
  return v2 - v1;
}

interface INumberRange {
  min: number;
  max: number;
}

/**
 * 判断一个数据是否包含在某个范围内
 * @param {number} value
 * @param {INumberRange} range
 * @param {boolean} includeRange
 * @returns {boolean}
 */
function between(value: number, range: INumberRange, includeRange?: boolean) {
  if (includeRange) {
    return value >= range.min && value <= range.max;
  }
  return value > range.min && value < range.max;
}

/**
 * 返回某个范围内的值
 * @param value 原值
 * @param between
 */
function betweenNumber(value: number, between: INumberRange) {
  return min(between.max, max(value, between.min));
}

/**
 * 判断两个数据范围是否包含
 * @param {INumberRange} s
 * @param {INumberRange} t
 * @param {boolean} includeRange
 * @returns {boolean}
 */
function betweenRange(s: INumberRange, t: INumberRange, includeRange?: boolean) {
  return (
    (between(t.min, s, includeRange) && between(t.max, s, includeRange)) ||
    (between(s.min, t, includeRange) && between(s.max, t, includeRange))
  );
}

function intersectRange(s: INumberRange, t: INumberRange, includeRange?: boolean): boolean {
  return (
    between(s.min, t, includeRange) ||
    between(s.max, t, includeRange) ||
    between(t.max, s, includeRange) ||
    between(t.min, s, includeRange)
  );
}

function equalRange(s: INumberRange, t: INumberRange): boolean {
  return equal(s.min, t.min) && equal(s.max, t.max);
}

/**
 * 判断一个数是否在某个区间内
 * @param {number} reference
 * @param {number} target
 * @param {number} offset
 * @returns {boolean}
 */
function isMoreOrLess(reference: number, target: number, offset: number): boolean {
  return target >= reference - offset && target <= reference + offset;
}

/**
 * 四舍五入取整 解决Math函数性能低的问题，结果等同于Math.round
 * @param {number} value
 * @returns {number}
 */
function round(value: number): number {
  const mod = value % 1;
  if (mod === 0) {
    return value;
  }
  let v = value;
  if (value > 0) {
    v = v + 0.5;
    return v - (v % 1);
  } else {
    v = 0 - v + 0.5;
    return 0 - (v - (v % 1));
  }
}

/**
 * 取绝对值，结果等同于Math.abs
 * @param {number} value
 * @returns {number}
 */
function abs(value: number): number {
  return value > 0 ? value : 0 - value;
}

/**
 * 取下整数，结果等同于Math.floor
 * @param {number} value
 * @returns {number}
 */
function floor(value: number): number {
  const mod = value % 1;
  if (mod === 0) {
    return value;
  }
  if (value > 0) {
    return value - mod;
  }
  return value - 1 - mod;
}

/**
 * 取上整数，结果等同于Math.ceil
 * @param {number} value
 * @returns {number}
 */
function ceil(value: number): number {
  const mod = value % 1;
  if (mod === 0) {
    return value;
  }
  if (value > 0) {
    return value - mod + 1;
  }
  return value - mod;
}

/**
 * 取小，结果等同于Math.min
 * @param {number} vn
 * @returns {number}
 */
function min(...vn: number[]): number {
  const values = Array.from(vn);
  if (!values.length) {
    return NaN;
  }
  if (values.length < 2) {
    return values[0];
  }
  let result = values[0];
  for (let i = 1; i < values.length; i++) {
    const v = values[i];
    if (v < result) {
      result = v;
    }
  }
  return result;
}

/**
 * 取大，结果等同于Math.max
 * @param {number} vn
 * @returns {number}
 */
function max(...vn: number[]): number {
  const values = Array.from(vn);
  if (!values.length) {
    return NaN;
  }
  if (values.length < 2) {
    return values[0];
  }
  let result = values[0];
  for (let i = 1; i < values.length; i++) {
    const v = values[i];
    if (v > result) {
      result = v;
    }
  }
  return result;
}

/**
 * 返回一个数的整数部分
 * @param {number} v
 * @returns {number}
 */
function trunc(v: number) {
  return v - (v % 1);
}

/**
 * 判断两个数是否相等
 * @param {number} v1
 * @param {number} v2
 * @param {number} epsilon
 * @returns {boolean}
 */
function equal(v1: number, v2: number, epsilon?: number): boolean {
  const _epsilon = epsilon || EPSILON;
  return abs(v1 - v2) <= _epsilon || v1 === v2;
}

function pow(value: number, v: number) {
  return value ** v;
}

function value(v?: number, defaultValue?: number): number {
  return v ?? defaultValue ?? 0;
}

function variable<T>(v: T | undefined, defaultValue: T): T {
  return v ?? defaultValue;
}

export default {
  max,
  min,
  ceil,
  floor,
  abs,
  round,
  pow,
  trunc,
  hypot: Math.hypot,
  sqrt: Math.sqrt,
  cos: Math.cos,
  acos: Math.acos,
  sin: Math.sin,
  asin: Math.asin,
  tan: Math.tan,
  atan: Math.atan,
  random: Math.random,

  distance,
  between,
  betweenNumber,
  betweenRange,
  isMoreOrLess,
  equal,
  equalRange,
  intersectRange,
  value,
  variable,
};
