import ValueEditorType from '@consts/enums/valueEditorType';
import i18n from '@i18n';
import { UIComponent } from '@/editor/comps';
import { PropertyValue } from '@/fbs/rp/models/property';
import {
  EChartDataLabelType,
  EChartType_pie,
  ELegendPosition,
  ILegendPosition,
  ChartLegendPositionPropertyName,
  ChartDataLabelPropertyName,
} from '@fbs/rp/models/properties/chart';
import { IComponentData } from '@fbs/rp/models/component';
import { ArtboardPatches } from '@/fbs/rp/utils/patch';
import ITextFormat from '@/fbs/rp/models/properties/text';
import { ISize } from '@/fbs/common/models/common';
import { IComponentValue } from '@/fbs/rp/models/value';

import { makeCommonComponent } from '../../helper';
import { getLegendSize, getPatchesByLegendSizeChanged, onValueUpdate, getSizePatches } from '../SeriesChartOne/common';
import { IComponentItem } from '../../types';
import { CPieChart, CDoughnutChart } from '../../constants';
import { getChartCommonProperties, legendPositionList, getChartConfigPartial, getCharPopupDefault } from '../common';

type ChartType = typeof CPieChart | typeof CDoughnutChart;

const MinSizeRate = 0.3; // 图表占据整个组件的最小比例

/**
 * 图例间距变更生成patches
 * 间距变化，对整个组件size进行变更
 * @param comp
 * @param propValue
 */
function legendPropertyChange(comp: UIComponent, propValue: ILegendPosition): ArtboardPatches | null {
  const { size: oldSize, properties } = comp;
  const legendPosition = properties.pieLegendPosition as ILegendPosition;
  const oldDic = legendPosition.value;
  const newSpace = propValue.gap;
  const newDic = propValue.value;
  const isOldVertical = oldDic === ELegendPosition.Bottom || oldDic === ELegendPosition.Top;
  const isNewVertical = newDic === ELegendPosition.Bottom || newDic === ELegendPosition.Top;
  const newSize = { ...oldSize };

  let oldSpace = legendPosition.gap;
  // 间距发生变化， 从自动间距设置成固定间距，先计算自动间距的值
  if (newSpace !== undefined && newSpace !== '' && oldSpace !== newSpace) {
    const actualValue = Math.min(oldSize.width, oldSize.height);
    const legendSize = oldSpace === undefined || oldSpace === '' ? getLegendSize(comp) : undefined;
    // 0.7是默认图标缩放为70%
    const chartValue = actualValue * 0.7;

    // 垂直方向变更间距，高度增加
    if (isOldVertical) {
      if (oldSpace === undefined || oldSpace === '') {
        // 图表的实际高度加上上下间距得到更改后的组件高度
        newSize.height = chartValue + ((legendSize?.height || 0) + newSpace) * 2;
      } else {
        newSize.height = oldSize.height + (newSpace - oldSpace) * 2;
      }
    } else {
      // 水平方向变更间距，宽度增加
      if (oldSpace === undefined || oldSpace === '') {
        newSize.width = chartValue + ((legendSize?.width || 0) + newSpace) * 2;
      } else {
        newSize.width = oldSize.width + (newSpace - oldSpace) * 2;
      }
    }
    return getSizePatches(comp, newSize);
  }
  // 方向发生变更
  if (isOldVertical !== isNewVertical && oldSpace !== undefined && oldSpace !== '') {
    const oldLegendSize = getLegendSize(comp);
    const newLegendSize = getLegendSize(comp, { position: newDic });
    let chartValue = 0;
    if (isOldVertical) {
      chartValue = oldSize.height - (oldLegendSize.height + oldSpace) * 2;
    } else {
      chartValue = oldSize.width - (oldLegendSize.width + oldSpace) * 2;
    }
    if (isNewVertical) {
      newSize.height = chartValue + (newLegendSize.height + oldSpace) * 2;
    } else {
      newSize.width = chartValue + (newLegendSize.width + oldSpace) * 2;
    }
    return getSizePatches(comp, newSize);
  }
  return null;
}

function onPropertyChange(comp: UIComponent, propertyName: string, propValue: PropertyValue): ArtboardPatches | null {
  if (propertyName === 'pieLegendPosition') {
    return legendPropertyChange(comp, propValue as ILegendPosition);
  }
  if (propertyName === 'textStyle') {
    return getPatchesByLegendSizeChanged(comp, { textStyle: propValue as ITextFormat }, undefined, 2);
  }
  return null;
}

/**
 * resize变更size
 * @param comp
 * @param size
 * @param space
 */
function getSizeByResize(comp: UIComponent, size: ISize, space: number): ISize | undefined {
  const { size: oldSize, properties } = comp;
  if (size.width === oldSize.width && size.height === oldSize.height) {
    return;
  }
  const changeByVertical = Math.abs(size.height - oldSize.height) > Math.abs(size.width - oldSize.width);
  const legendPosition = properties.pieLegendPosition! as ILegendPosition;

  const dic = legendPosition.value;
  const isVertical = dic === ELegendPosition.Top || dic === ELegendPosition.Bottom;

  const oldLegendSize = getLegendSize(comp);
  const newLegendSize = getLegendSize(comp, { size });

  let newSize = { ...size };
  if (isVertical && oldLegendSize.height !== newLegendSize.height) {
    const diff = newLegendSize.height - oldLegendSize.height;
    newSize.height = size.height + diff;
  } else if (!isVertical && oldLegendSize.width !== newLegendSize.width) {
    const diff = newLegendSize.width - oldLegendSize.width;
    newSize.width = size.width + diff;
  }

  const changeSize = { ...newSize };
  const chartValue = isVertical
    ? newSize.height - (newLegendSize.height + space) * 2
    : newSize.width - (newLegendSize.width + space) * 2;

  if (!isVertical && changeSize.height - chartValue < changeSize.height * MinSizeRate) {
    if (changeByVertical) {
      newSize.width = changeSize.height * (1 - MinSizeRate) + (newLegendSize.width + space) * 2;
    } else {
      newSize.height = chartValue / (1 - MinSizeRate);
      // 此时宽度变化可能会引起图例的换列，再次执行
      return getSizeByResize(comp, newSize, space);
    }
  }
  if (isVertical && changeSize.width - chartValue < changeSize.width * MinSizeRate) {
    if (changeByVertical) {
      newSize.width = chartValue / (1 - MinSizeRate);
      // 此时宽度变化可能会引起图例的换行，再次执行
      return getSizeByResize(comp, newSize, space);
    } else {
      newSize.height = changeSize.width * (1 - MinSizeRate) + (newLegendSize.height + space) * 2;
    }
  }
  return newSize;
}

function onResize(comp: UIComponent, size: ISize) {
  const { size: oldSize, properties } = comp;
  if (size.width === oldSize.width && size.height === oldSize.height) {
    return null;
  }

  const legendPosition = properties.pieLegendPosition as ILegendPosition;
  const space = legendPosition.gap;
  if (space === undefined || space === '') {
    return null;
  }

  const newSize = getSizeByResize(comp, size, space!);
  return getSizePatches(comp, newSize);
}

/**
 * 获取配置数据
 * @param {ChartType} type
 * @return {IComponentItem}
 */
export const getChartConfig = (type: ChartType): IComponentItem => {
  return {
    ...getChartConfigPartial(type),
    type,
    property: {
      getValueNames: (key: string): { name: string; value: any }[] | undefined => {
        if (key === 'pieLegendPosition') {
          return legendPositionList;
        }
        if (key === 'pieDataLabel') {
          return [
            { value: EChartDataLabelType.number, name: i18n('property.component.number') },
            { value: EChartDataLabelType.percent, name: i18n('property.component.percent') },
          ];
        }
        if (key === 'pieType') {
          return [
            { value: EChartType_pie.Normal, name: i18n('property.component.pie_normal') },
            { value: EChartType_pie.AbNormal, name: i18n('property.component.pie_abNormal') },
          ];
        }
      },
    },
    value: {
      type: ValueEditorType.Chart,
    },
    editor: {
      onPropertyUpdate: onPropertyChange,
      onResize,
      onValueUpdate: (comp: UIComponent, newValue: IComponentValue) => onValueUpdate(comp, newValue, 2),
    },
    getDefaultData() {
      return getSeriesChartTwoDefaultData(type);
    },
  };
};

export function getSeriesChartTwoDefaultData(type: ChartType): Partial<IComponentData> {
  return {
    properties: {
      ...getChartCommonProperties(),
      pieDataLabel: {
        prop: ChartDataLabelPropertyName,
        disabled: false,
        value: EChartDataLabelType.number,
        showValue: true,
        unit: '',
      },
      pieLegendPosition: {
        prop: ChartLegendPositionPropertyName,
        disabled: false,
        value: ELegendPosition.Bottom,
        gap: '',
      },
      pieType: {
        prop: 'enum',
        name: i18n('property.propertyNames.type'),
        value: type === CPieChart ? EChartType_pie.Normal : EChartType_pie.AbNormal,
      },
      animation: {
        prop: 'boolean',
        value: true,
        name: i18n('property.propertyNames.showAnimation'),
      },
      popup: getCharPopupDefault(),
    },
  };
}

/**
 * 创建图表数据
 * @param {string} id
 * @param {ChartType} type
 * @return {IComponentData}
 */
export const makeChartData = (id: string, type: ChartType): IComponentData => {
  return makeCommonComponent(id, type, {
    ...getSeriesChartTwoDefaultData(type),
    size: {
      width: 400,
      height: 250,
    },
    value: {
      xAxis: [i18n('resource.components.data')],
      yAxis: [],
      dataSource: [
        {
          data: [10],
          name: i18n('resource.components.project_one'),
        },
        {
          data: [15],
          name: i18n('resource.components.project_two'),
        },
        {
          data: [20],
          name: i18n('resource.components.project_three'),
        },
        {
          data: [25],
          name: i18n('resource.components.project_four'),
        },
        {
          data: [30],
          name: i18n('resource.components.project_five'),
        },
      ],
    },
  });
};
