export interface IProps<T> {
  data: T;
  expand?: boolean;
  isLeaf?: boolean;
  selected?: boolean;
  children?: Array<IProps<T>>;
  parent?: IProps<T>;
  matched?: boolean;
  marked?: boolean;
  editing?: boolean;
}

export type TreeItemData<T> = IProps<T>;

export type NodeInfo<T> = {
  expand?: boolean;
  isLeaf?: boolean;
  selected?: boolean;
  children?: T[];
  matched?: boolean;
  marked?: boolean;
  editing?: boolean;
};

type CompareFunction<T> = (data: T, value: string) => boolean;

export function builderTreeItemData<T>(data: T[], resolve: (item: T) => NodeInfo<T>): TreeItemData<T>[] {
  const doBuilder = (item: T, parent?: TreeItemData<T>): TreeItemData<T> => {
    const { expand, isLeaf, selected, children, marked, matched, editing } = resolve(item);
    const result: TreeItemData<T> = {
      data: item,
      expand,
      isLeaf,
      selected,
      marked,
      matched,
      editing,
    };
    result.parent = parent;
    if (children?.length) {
      result.children = children.map((item: T) => doBuilder(item, result));
    }
    return result;
  };
  return data.map((item) => doBuilder(item));
}

/**
 * 树搜索
 * @param {TreeItemData<T>[]} comps 需要搜索的树节点数据集
 * @param {string} searchValue 搜索字符串
 * @param {CompareFunction<T>} compareFun 节点数据比对函数
 * @param {boolean} needMark 是否要对匹配的结果进行样式上的标记
 * @returns {TreeItemData<T>[]}
 */
export function searchTreeNodes<T>(
  comps: TreeItemData<T>[],
  searchValue: string,
  compareFun: CompareFunction<T>,
  needMark?: boolean,
): TreeItemData<T>[] {
  const search = (
    nodes: TreeItemData<T>[],
    isMatchAllChildren?: boolean,
  ): { treeData: TreeItemData<T>[]; treeIsMatch: boolean } => {
    const template: TreeItemData<T>[] = [];
    let searchIsMatch = false;
    const treeData = nodes.reduce((acc, node) => {
      const findNode = { ...node };
      let isMatch = compareFun(node.data, searchValue);
      //是否匹配，自己存在匹配 & 祖先节点匹配
      let isTrue = isMatchAllChildren || isMatch;
      findNode.marked = isTrue && needMark;
      if (node.children) {
        //只要父节点有匹配，下面的所有子节点都要添加进去匹配结果
        const { treeData: findChildNodes, treeIsMatch: childrenIsMatch } = search(node.children, isTrue);
        if (!isTrue) {
          isTrue = findChildNodes.length > 0;
        }
        if (isTrue) {
          findNode.children = findChildNodes;
          //展开只存在，自己有匹配，或者自己的子节点存在匹配
          findNode.expand = isMatch || childrenIsMatch;
        }
        //子节点有匹配
        if (childrenIsMatch) {
          searchIsMatch = true;
        }
      }
      //本身有匹配
      if (isMatch) {
        searchIsMatch = true;
      }
      if (isTrue) {
        acc.push(findNode);
      }
      return acc;
    }, template);
    return { treeData, treeIsMatch: searchIsMatch };
  };
  return search(comps).treeData;
}

export function searchNodes<T>(
  comps: TreeItemData<T>[],
  searchValue: string,
  compareFun: CompareFunction<T>,
): TreeItemData<T>[] {
  const result: TreeItemData<T>[] = [];
  const search = (nodes: TreeItemData<T>[]) => {
    nodes.forEach((node) => {
      if (compareFun(node.data, searchValue)) {
        result.push(node);
      }
      if (node.children) {
        search(node.children);
      }
    });
  };
  search(comps);
  return result;
}

export function cloneTreeItemData<T>(
  node: TreeItemData<T> | TreeItemData<T>[],
  fn?: (node: TreeItemData<T>) => void,
): TreeItemData<T> | TreeItemData<T>[] {
  if (Array.isArray(node)) {
    return node.map((item) => {
      return cloneTreeItemData(item, fn) as TreeItemData<T>;
    });
  }
  const { parent, children, ...other } = node;
  let c: TreeItemData<T>[] | undefined = undefined;
  if (children) {
    c = cloneTreeItemData(children, fn) as TreeItemData<T>[];
  }
  const result = { ...other, parent, children: c } as TreeItemData<T>;
  fn && fn(result);
  return result;
}

/**
 * 展开所有子节点
 */
export function modifyAllChilrenExpand<T>(group: TreeItemData<T>[], expand = true): TreeItemData<T>[] {
  var reulst: TreeItemData<T>[] = [];
  group.forEach((item) => {
    if (!item.isLeaf) {
      item.expand = expand;
      reulst.push(item);
      if (item.children?.length) {
        reulst = reulst.concat(modifyAllChilrenExpand(item.children, expand));
      }
    }
  });
  return reulst;
}

/**
 * 寻找指定子节点（一个）
 */
export function findNode<T>(group: TreeItemData<T>[], filter: (item: TreeItemData<T>) => boolean) {
  const selectedItem = group.find(filter);
  if (selectedItem) {
    return selectedItem;
  }
  const newGroups = group.filter((item) => item.children?.length);
  if (!newGroups.length) {
    return undefined;
  }
  let node: TreeItemData<T> | undefined;
  for (let i = 0; i < newGroups.length; i++) {
    const result = findNode(newGroups[i].children!, filter);
    if (result) {
      node = result;
      break;
    }
  }
  return node;
}

/**
 * 寻找根节点
 */
export function findRootNode<T>(node: TreeItemData<T>): TreeItemData<T> {
  if (node.parent) {
    return findRootNode(node.parent);
  }
  return node;
}
