import * as React from 'react';

import { IPoint } from '../../models';

import PopContent from './PopContent';

import './index.scss';

interface IPopoverFixProps {
  children?: React.ReactChild;
  content: React.ReactNode;
  minWidth?: number | string;
  active?: boolean;
}

const PopoverFix: React.FC<IPopoverFixProps> = (props: IPopoverFixProps) => {
  const { children, content, minWidth, active } = props;

  const [show, setShow] = React.useState(false);
  const [popPos, setPopPos] = React.useState<IPoint>({ x: 0, y: 0 });
  const ref = React.useRef<HTMLDivElement>(null);

  const onMouseEnter = () => {
    if (!active) {
      return;
    }
    const titlePos = ref.current?.getBoundingClientRect();
    if (titlePos) {
      setPopPos({ x: titlePos.x + titlePos.width / 2, y: titlePos.y });
      setShow(true);
    }
  };

  const onMouseLeave = () => {
    setShow(false);
    setPopPos({ x: 0, y: 0 });
  };

  return (
    <>
      <div className="dsm-c-popover-fix" ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {children}
        {show && <PopContent content={content} minWidth={minWidth} pos={popPos} />}
      </div>
    </>
  );
};

PopoverFix.defaultProps = {
  active: true,
};

export default PopoverFix;
