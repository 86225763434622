import { ITeamMember } from './member';

// v1/team/ PUT 添加新的团队
export interface ICreateTeam {
  id: string;
}

export enum authorize {
  // 旗舰版
  ULTIMATE = 'ultimate',
  // 专业版
  PROFESSIONAL = 'professional',
  // 免费版
  FREE = 'free',
}

export interface ILicense {
  version: string;
  payment: string;
  expired: boolean;
  expireTime: Date;
  remainDay: number;
  memberLimit: number;
  appNumberLimit: number;
  teamMemberLimit?: number;
  display: string;
  buyOrUpgrade: string;
  ccProjectsLimit?: number;
  rpProjectsLimit?: number;
  dtProjectsLimit?: number;
  mockProjectsLimit?: number;
  sampleLimit?: number;
  projectsLimit?: number;
}

export enum RoleInTeam {
  SuperAdmin = 'super-admin', // 超级管理员
  Admin = 'admin', // 管理员
  Member = 'member', // 成员
  Guest = 'guest', // 协同人
  Observer = 'observer', // 访客
}

// v1/team/ GET 获取一个用户的团队列表 Array
// v1/team/:teamID GET获取团队信息
export interface ITeams {
  id: string;
  name: string;
  license: ILicense;
  licenseForBuy?: ILicense;
  roleInTeam: string;
  role?: string;
  cover?: string;
  admin?: string;
  isDelay?: boolean;
  teamType?: string; // 团队类型，teambition，lark
  appShowType?: string;
  rpDesignExpireTime?: Date;
  rpCount?: {
    totalAppInTeam: number;
    totalAppUserIn: number;
    allApps: number;
  };
  dtCount?: {
    totalAppInTeam: number;
    totalAppUserIn: number;
    allApps: number;
  };
  // TODO：下面两种具体不知道是哪种，有拼写错误
  rpDesignPayment?: string;
  /**
   * @deprecated
   */
  rpDesiginPayment?: string;
  isOnlyAdminMakeTeam?: boolean;
  inviteCheck?: boolean;
  superAdminViewAllApp?: boolean;
  enableMemberExportRpDt?: boolean; // 是否允许成员导出RP/DT源文件
  createdAt?: string;
  ccCount?: {
    // cc所有设计稿当前私有部署的数量
    normalAppNum: number;
    // cc摹客设计稿当前私有部署的数量
    mockAppNum: number;
  };
}
// v1/team/:teamID PATCH 修改团队信息
export interface IPatchTeamInfo extends ITeams {
  members: Array<ITeamMember>;
}

// v1/team/:teamID/member/:memberID PATCH 修改团队中成员的昵称
export interface IPatchTeamMember {
  name: string;
}

// v1/team/join/:token/ PUT 加入团队
export interface IJoinTeam {
  teamID: string;
}

// patch /team/config/:teamID
export interface teamConfig {
  teamID: string;
  // 是否开启邀请验证流程
  inviteCheck?: boolean;
}

const teamLimitArr = ['bnnsdafaox'];

// 这些团队特殊地限制了不允许协同人下载
export function isTeamLimited(teamID: string): boolean {
  return teamLimitArr.includes(teamID);
}
/**
 * 获取项目各类型数量
 */
export interface IAppTypeNumF {
  appNum: number;
  appSetNum: number;
  dsNum: number;
  joinNum: number;
  joinSingleRpNum: number;
  singleRpNum: number;
}

/**
 * 团队二维码类型
 */
export enum QRCodeType {
  //价格策略缓冲期二维码
  PriceStrategyTrial = 'price-strategy-trial',
}
/**
 * 获取团队二维码请求参数
 */
export interface IGetTheTeamQRCodeReq {
  QRType: QRCodeType;
}

/**
 * 获取团队二维码返回
 */
export interface IGetTheTeamQRCodeF {
  url: string;
}

export interface ISetTheTeamQRCodeReq extends IGetTheTeamQRCodeReq {
  url: string;
}
