import * as React from 'react';
import * as ReactDom from 'react-dom';
import classnames from 'classnames';

import Icon from '../Icon';

import './index.scss';

interface IHelpIconProps {
  cls?: string;
  color?: string;
  helpInfo?: {
    text: string;
    helpText?: string;
  };
  helpNode?: React.ReactNode;
  onLinkClick?: React.MouseEventHandler;
}

interface IHelpIconState {
  popup?: boolean;
  fadeIn?: boolean;
}

export default class HelpIcon extends React.Component<IHelpIconProps, IHelpIconState> {
  private popupPosition: { left: number; top: number } = { left: 0, top: 0 };

  constructor(props: IHelpIconProps) {
    super(props);
    this.state = {};
  }

  private timeID?: Timeout;

  componentWillUnmount() {
    clearTimeout(this.timeID);
  }

  handleMouseEnter = (e: React.MouseEvent) => {
    const dom = e.currentTarget as HTMLElement;
    const { right, top, height } = dom.getBoundingClientRect();
    this.popupPosition = { left: right, top: Math.round(top + height / 2) };
    this.setState({ popup: true }, () => {
      this.timeID = window.setTimeout(() => {
        this.setState({ fadeIn: true });
      });
    });
  };

  handleMouseLeave = () => {
    clearTimeout(this.timeID);
    this.setState({ popup: false, fadeIn: false });
  };

  renderPopup() {
    const { popup, fadeIn } = this.state;
    if (!popup) {
      return null;
    }
    const { helpInfo, onLinkClick, helpNode } = this.props;
    const { text, helpText } = helpInfo || {};
    const node = (
      <div style={this.popupPosition} className={classnames('help-popup-tips', { 'fade-in': fadeIn })}>
        <div className="left-triangle" />
        <div className="help-tips-content">
          {helpNode || (
            <>
              <p>{text}</p>
              {helpText && (
                <label className="help-link" onClick={onLinkClick}>
                  {helpText}
                </label>
              )}
            </>
          )}
        </div>
      </div>
    );
    return ReactDom.createPortal(node, document.body);
  }

  render() {
    const { cls, color } = this.props;
    return (
      <div className="dsm-c-rp-help-icon" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
        <Icon cls={cls || 'icon_fill_help'} style={{ color }} theme="tag" />
        {this.renderPopup()}
      </div>
    );
  }
}
