import { getShellInfo, IReturnOption } from '@/helpers/shellHelper';
import { MobileType } from '@/fbs/rp/utils/preview';
import { getSizeFromOrientation } from '@/helpers/artboardDimensionHelper';
import { Orientation, ISize } from '@/fbs/common/models/common';
import { IPreviewOption, getDefaultOption, isMobileAppType, isWatchAppType } from '@/helpers/previewHelper';
import { IBounds } from '../type';
import { IAppWithNestedChildren } from '@/fbs/rp/models/app';
import { UIFragment } from '@/editor/comps';

/**
 * 判断是否使用手机壳
 */
export const useShell = (mobileType?: MobileType) => {
  return mobileType && mobileType !== MobileType.None;
};

export const getShellOption = (mobileType?: MobileType, app?: IAppWithNestedChildren): IPreviewOption => {
  if (mobileType) {
    return {
      mobileType,
    };
  }
  const option = app ? getDefaultOption(app) : {};
  return option;
};

interface IShellBoundsOption {
  mobileType?: MobileType;
  app?: IAppWithNestedChildren;
  artboard?: UIFragment;
}

// 机型尺寸的倍率，@2x @3x 这种
const getDefaultScale = (shellOption: IReturnOption, appSize: ISize) => {
  if (!shellOption) {
    return 1;
  }

  const { screenBounds } = shellOption;
  return Math.min(appSize.width, appSize.height) / Math.min(screenBounds.width, screenBounds.height);
};

export const isLandscape = (orientation?: Orientation) => {
  return orientation === 'landscape';
};

export interface IShellBonds extends IBounds {
  otherHeight?: number;
  otherWidth?: number;
  screenWidth?: number;
  screenHeight?: number;
}

/**
 * 手机壳大小
 * @param app
 */
export const getShellSize = (app?: IAppWithNestedChildren): ISize | undefined => {
  if (app) {
    let size = app.size;
    const option = getDefaultOption(app);
    if (option.mobileType) {
      const bounds = getShellBounds({
        mobileType: option.mobileType,
        app,
      });
      size = bounds;
    }
    return size;
  }
};

export const getAdptiveShellSize = (options: IShellBoundsOption): ISize | undefined => {
  const bounds = getAdptiveShellBounds(options);
  return bounds as ISize;
};

/**
 * 自适应手机壳
 * 宽度按项目定义手机壳宽
 * 高度按画板高度
 * @param options
 */
export const getAdptiveShellBounds = (options: IShellBoundsOption): IShellBonds => {
  const bounds = getShellBounds(options);
  const { artboard, app } = options;
  const appType = app?.appType ?? '';

  const isWatch = isWatchAppType(appType);
  // 手表，大小等比缩放
  if (isWatch) {
    return bounds;
  }

  const isMobile = isMobileAppType(appType);
  if (!isMobile) {
    // 固定大小
    return bounds;
  }

  // 自适应
  if (artboard) {
    if (isLandscape(artboard?.orientation)) {
      bounds.width = Math.max(bounds.width, artboard.size.width + bounds.x * 2);
      bounds.width -= 1;
      bounds.height -= 1;
    } else {
      bounds.height = Math.max(bounds.height, artboard.size.height + bounds.y * 2);
      // 防止边上出现空白
      bounds.width -= 1;
      bounds.height -= 1;
    }
  }
  return bounds;
};

/**
 * 设备外壳大小
 * @param options
 */
export const getShellBounds = (options: IShellBoundsOption): IShellBonds => {
  const { app, artboard } = options;
  const orientation = artboard?.orientation;
  const appSize = app?.size;

  let { mobileType } = options;
  if (!mobileType && app) {
    const option = getDefaultOption(app);
    if (option.mobileType) {
      mobileType = option.mobileType;
    }
  }
  const appType = app?.appType ?? '';
  // 只有竖向 ，手表
  const isWatch = isWatchAppType(appType);
  const portraitOnly = isWatch;

  const landscape = portraitOnly ? false : isLandscape(orientation);

  const shellInfo = getShellInfo(mobileType, landscape);
  if (!shellInfo || !appSize) {
    return {
      width: 100,
      height: 100,
      x: 0,
      y: 0,
    };
  }
  const { screenBounds, shellSize, otherScale } = shellInfo;

  const isMobile = isMobileAppType(appType);
  if (!isMobile && !isWatch) {
    // 固定大小
    return {
      width: shellSize.width,
      height: shellSize.height,
      x: screenBounds.left,
      y: screenBounds.top,
      otherHeight: shellSize.height * otherScale,
      screenWidth: screenBounds.width,
      screenHeight: screenBounds.height,
    };
  }

  // 自适应
  const defaultScale = getDefaultScale(shellInfo, appSize);
  // 旋转后的 appsize
  const rotatedAppSize = portraitOnly ? appSize : orientation ? getSizeFromOrientation(orientation, appSize) : appSize;

  let shellWidth;
  let shellHeight;
  let shellOtherHeight;
  let shellOtherWidth;
  if (landscape) {
    // 横屏
    shellWidth = (shellSize.width - screenBounds.width) * defaultScale + rotatedAppSize.width;
    shellHeight = shellSize.height * defaultScale;

    shellOtherWidth = rotatedAppSize.width - otherScale * shellSize.height * defaultScale;
  } else {
    // 竖屏
    shellWidth = shellSize.width * defaultScale;
    shellHeight = (shellSize.height - screenBounds.height) * defaultScale + rotatedAppSize.height;

    shellOtherHeight = rotatedAppSize.height - otherScale * shellSize.width * defaultScale;
  }

  // 偏移
  const x = Math.floor(screenBounds.left * defaultScale);
  const y = Math.floor(screenBounds.top * defaultScale);
  if (isWatch) {
    // 手表 ，等比缩放
    return {
      width: Math.floor(shellWidth) - 1,
      height: Math.floor(shellHeight) - 1,
      screenWidth: Math.floor(screenBounds.width * defaultScale) + 1,
      screenHeight: Math.floor(screenBounds.height * defaultScale) + 1,
      otherHeight: Math.floor(shellHeight * otherScale) - 1,
      otherWidth: shellOtherWidth,
      x: x - 1,
      y: y - 1,
    };
  }
  return {
    width: Math.round(shellWidth),
    height: Math.round(shellHeight),
    otherHeight: shellOtherHeight,
    otherWidth: shellOtherWidth,
    x,
    y,
  };
};
const MIN_MARGIN = 0;
interface IShellOffsetOptions {
  deviceSize: { width: number; height: number };
  shellOption: IReturnOption | null;
  // defaultScale: number;
  viewPortSize: { viewPortHeight: number; viewPortWidth: number };
  option: IPreviewOption;
}
/**
 * 设备外壳相对于演示区偏移
 * @param deviceSize
 * @param shellOption
 * @param defaultScale
 */
export const getShellOffset = (options: IShellOffsetOptions) => {
  const { deviceSize, shellOption, viewPortSize, option } = options;

  let minPaddingTop = MIN_MARGIN;
  let minPaddingLeft = MIN_MARGIN;
  let minBottom = MIN_MARGIN;
  let minRight = MIN_MARGIN;

  const defaultScale = 1; //getDefaultScale(shellInfo, appSize); //1;

  const { viewPortHeight, viewPortWidth } = viewPortSize;
  const { height, width } = deviceSize;
  const screenScale = 1; // this.props.scale / 100;
  let offsetX = Math.round(0.5 * (viewPortWidth - width * screenScale));
  let offsetY = Math.round(0.5 * (viewPortHeight - height * screenScale));
  if (!shellOption || option.noBoundary) {
    offsetX = offsetX < minPaddingLeft ? minPaddingLeft : offsetX;
    offsetY = offsetY < minPaddingTop ? minPaddingTop : offsetY;
    return { offsetX, offsetY, minBottom, minRight };
  }
  const defaultX = Math.round(shellOption.screenBounds.left * defaultScale * screenScale);
  const defaultY = Math.round(shellOption.screenBounds.top * defaultScale * screenScale);
  offsetX = offsetX < defaultX + minPaddingLeft ? defaultX + minPaddingLeft : offsetX;
  offsetY = offsetY < defaultY + minPaddingTop ? defaultY + minPaddingTop : offsetY;

  return { offsetX, offsetY, minBottom, minRight };
};

export const isMobileType = (app?: IAppWithNestedChildren): boolean => {
  return ['phone', 'pad'].includes(app?.appType ?? '');
};

export const getRotatedAppSize = (app?: IAppWithNestedChildren, artboard?: UIFragment) => {
  const appSize = app?.size ?? { width: 100, height: 100 };
  return artboard ? getSizeFromOrientation(artboard.orientation, appSize) : appSize;
};

export const getDeviceSize = (app?: IAppWithNestedChildren, artboard?: UIFragment): ISize => {
  const rotatedAppSize = getRotatedAppSize(app, artboard);
  const screenScale = 1;
  const pageSize = artboard?.size ?? { width: 100, height: 100 };
  const isMobile = isMobileType(app);

  return {
    width: isMobile ? rotatedAppSize.width * screenScale : pageSize.width * screenScale,
    height: isMobile ? rotatedAppSize.height * screenScale : pageSize.height * screenScale,
  };
};

/**
 * 自动适应设备大小
 * @param app
 * @param artboard
 */
export const getAdptiveDeviceSize = (app?: IAppWithNestedChildren, artboard?: UIFragment): ISize => {
  const rotatedAppSize = getRotatedAppSize(app, artboard);
  const pageSize = artboard?.size ?? { width: 100, height: 100 };
  const isMobile = isMobileType(app);

  return {
    width: isMobile ? rotatedAppSize.width : pageSize.width,
    height: pageSize.height,
  };
};

/**
 * 自适应设备bounds
 * adptive：是否自适应
 * @param options
 */
export const getAdptiveDeviceBounds = (options: IShellBoundsOption) => {
  const { app, mobileType, artboard } = options;
  const bounds = app
    ? getAdptiveShellBounds({
        mobileType,
        app,
        artboard,
      })
    : {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
      };

  const appType = app?.appType ?? '';

  // 手表：等比缩放
  const isWatch = isWatchAppType(appType);
  const portraitOnly = isWatch;
  if (isWatch) {
    return {
      height: bounds.screenHeight || bounds.height,
      width: bounds.screenWidth || bounds.width,
      left: bounds.x,
      top: bounds.y,
    };
  }

  const isMobile = isMobileAppType(appType);

  // 固定大小
  if (!isMobile) {
    return {
      height: bounds.screenHeight || bounds.height,
      width: bounds.screenWidth || bounds.width,
      left: bounds.x,
      top: bounds.y,
    };
  }

  // 自适应 artboard
  const rotatedAppSize = getRotatedAppSize(app, artboard);
  const pageSize = artboard?.size ?? { width: 100, height: 100 };
  const landscape = portraitOnly ? false : isLandscape(artboard?.orientation);
  const deviceSize = landscape
    ? {
        width: Math.max(pageSize.width, rotatedAppSize.width),
        height: isMobile ? rotatedAppSize.height : pageSize.height,
      }
    : {
        width: isMobile ? rotatedAppSize.width : pageSize.width,
        height: Math.max(pageSize.height, rotatedAppSize.height),
      };

  return {
    height: deviceSize.height,
    width: deviceSize.width,
    left: bounds.x,
    top: bounds.y,
  };
};
