import { IAppWithNestedChildren } from '@/fbs/rp/models/app';
import { MobileType } from '@/fbs/rp/utils/preview';
import { UIArtboard, UIComponent, UIFragment } from '@/editor/comps';
import Doc from '@/editor/document';
import { makeSvgByGroup, ISvgInfo } from '../svg';
import { IExportData, IParseComponentProcessorOptions, IAddZipFileOptions, IRenderBlankFilter } from '../type';
import { parseExportData } from '../parser';
import { TriggerImageLoad } from '../util/triggerImageLoad';
import { exceptionCollector } from '../exception';
import { getPageName } from '../util/filenameUtil';

/**
 * 解析单个组件过程
 * */
export class ParseComponentProcessor {
  // 计数 当前序列号
  private count: number = 0;
  // 总任务数
  private total: number;
  private onSuccess: (options: IAddZipFileOptions) => Promise<void>;

  private scales: number[];
  private trigger: TriggerImageLoad;
  private doc?: Doc;
  private app?: IAppWithNestedChildren;
  private renderBlankFilter?: IRenderBlankFilter;
  // 手机壳
  private mobileType?: MobileType;

  constructor(options: IParseComponentProcessorOptions) {
    const { total, onSuccess, scales, doc, app, renderBlankFilter, mobileType } = options;
    this.total = total;
    this.onSuccess = onSuccess;
    this.doc = doc;
    this.app = app;
    this.renderBlankFilter = renderBlankFilter;
    this.mobileType = mobileType;

    this.scales = scales;
    // 触发下载，某些场景下用
    this.trigger = new TriggerImageLoad();
  }

  /**
   * 执行
   * @param t
   */
  exec = async (t: UIArtboard | UIFragment | UIComponent): Promise<void> => {
    try {
      const { doc, app, renderBlankFilter, mobileType } = this;
      const data: IExportData[] = await parseExportData({ components: [t], doc, app, renderBlankFilter, mobileType });
      const json = t.toJSON();
      const size = data[0].size;
      const svgGroup = await makeSvgByGroup({ data, size, scales: this.scales });

      const doSvgItem = async (s: ISvgInfo) => {
        if (s) {
          const { svg, scale, index } = s;
          await this.onSuccess({
            t: json,
            index,
            scale,
            svg,
            size,
            id: json._id,
          });

          this.count++;
          if (this.count === this.total) {
            this.trigger.end();
          } else {
            this.trigger.start();
          }
        }
      };

      if (svgGroup) {
        await Promise.all(svgGroup.map(doSvgItem));
      }
    } catch (e) {
      // 异常信息收集
      window.debug && console.log(t, e);

      let prefix = '';
      if (t instanceof UIArtboard || t instanceof UIFragment) {
        const pageName = getPageName(t.$data, this.app);
        prefix = pageName + '-';
      }

      exceptionCollector.add(prefix + t.name ?? '');
    }
  };
}
