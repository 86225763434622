import * as sanitizeHtml from 'sanitize-html';
import { isUndefined, isNumber, merge } from 'lodash';

import { UIContainerComponent, UIComponent, UIPanelComponent } from '.';
import { ETextBehaviour, IComponentData } from '@fbs/rp/models/component';
import { PagePatches, Ops, ArtboardPatches } from '@fbs/rp/utils/patch';
import ITextFormatEx from '@/fbs/rp/models/properties/textFormat';
import { ITableValue, CellTypes, ITableCell, ITableColumn } from '@fbs/rp/models/table';
import { ISize } from '@fbs/common/models/common';
import { PureColor } from '@/fbs/rp/models/properties/color';
import * as tableHelper from '@helpers/tableHelper';
import { ICellPosition, ISelectArea, TableClipboardHelper } from '@helpers/tableHelper';
import { coverPatches, mergePatches, getPatchOperationOfSupplementingMissingProperty } from '@helpers/patchHelper';
import { ITableClipboardData } from '@helpers/tableHelper';
import { getNewID } from '@/helpers/idHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { depthClone, min, jsonClone } from '@utils/globalUtils';
import { measureTextSize } from '@utils/textUtils';
import {
  DefalutCellStyle,
  DefaultCell,
  DefaultCellPadding,
  MaxRowsCount,
  MaxColumnsCount,
} from '@consts/defaultData/table';
import { CText, CTableCell, CCanvasPanel } from '@libs/constants';
import { ComponentChange, ContainerPatches, updateEditComponentsPatches } from './resizeHelper';
import UITextComponent from './UITextComponent';
import { makeCommonComponent } from '../../libs/helper';
import { makeText } from '@/libs/basic/NewText';

export default class UITableComponent extends UIContainerComponent {
  selectedComps: UIComponent[] = [];

  selectArea?: tableHelper.ISelectArea;
  // 禁用状态标志
  static DISABLED_FLAGS = {
    none: 0,
    remark: 1,
    interaction: 2,
    remarkAndInteraction: 3,
  };
  public disabledInteractionRemark: number = 0; //禁止备注交互 1 禁用备注 2禁用交互
  private tableCellContainer: UIPanelComponent | undefined;
  // 是否需要更新选中区的属性面板
  public needUpdateCellRightPanel = false;

  addComponents(
    components: IComponentData[],
    index = -1,
  ): {
    patches: PagePatches;
    newActiveGroup?: UIContainerComponent;
  } {
    const patches: PagePatches = {
      [this.ownerArtboardID]: {
        do: {
          [this.id]: [Ops.addChildren(`${index}`, components)],
        },
        undo: {
          [this.id]: [Ops.removeChildren(components.map((comp) => comp._id))],
        },
      },
    };
    return { patches };
  }

  getPositionPatchesOfChildrenChanged(changes: ComponentChange[]): ContainerPatches {
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    // added, removed 让调用者处理
    // 处理 edit 的组件
    updateEditComponentsPatches(
      this.components,
      changes,
      {
        x: 0,
        y: 0,
      },
      patches,
    );

    return {
      patches,
    };
  }

  /**
   * 当尺寸改变时，更新表格组件的value数据, 见UITableComponent
   * 文本组件不能在这里修改，因为不会被应用！！！
   */
  protected doUpdateTableValues(newSize: ISize): ArtboardPatches | null {
    // 1.获取原来的大小
    const oldSize = this.data.size;
    const sizeDiff = {
      width: newSize.width - oldSize.width,
      height: newSize.height - oldSize.height,
    };
    const patches: ArtboardPatches = { do: {}, undo: {} };
    const newValue = tableHelper.updateValueWithTableSizeDiff(this.data.value as ITableValue, sizeDiff);
    const valuePatches = this.setValue(newValue);
    coverPatches(patches, valuePatches);
    return patches;
  }

  /**
   * 获取选中区的所有组件
   */
  getCompsInArea = (selectArea?: ISelectArea) => {
    if (!selectArea) {
      return [];
    }
    return this.components.filter((comp) => {
      return tableHelper.isCellSelected(comp.row, comp.column, selectArea);
    });
  };

  /**
   * 重置文本组件尺寸
   */
  updateTextCompSize = (newTableValue: ITableValue) => {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    this.components
      .filter((comp) => comp.type === CText)
      .forEach((comp) => {
        const position = { row: comp.row, column: comp.column };
        const cell = tableHelper.findCell(newTableValue, position);
        if (!cell) return;
        const sizePatches = this.getTextSizePatches(newTableValue, comp, cell, position);
        coverPatches(patches, sizePatches);
      });
    return patches;
  };

  /**
   * 修改文本格式
   */
  updateTextFormat = (newTableValue: ITableValue, selectArea: ISelectArea, fragment: Partial<ITextFormatEx>) => {
    const patches: ArtboardPatches = { do: {}, undo: {} };

    const cells = tableHelper.getAllCellsOfSelectArea(newTableValue, selectArea);
    cells.forEach(({ position }) => {
      const textPatches = this.getTextPatchesByFormatChange(newTableValue, position, fragment);
      if (!textPatches) {
        return;
      }
      coverPatches(patches, textPatches);
    });
    return patches;
  };

  /**
   * 重置文本组件对齐方式
   */
  updateTextCompAlign = (newTableValue: ITableValue, selectArea: ISelectArea) => {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    this.components
      .filter((comp) => comp.type === CText)
      .forEach((comp) => {
        const position = { row: comp.row, column: comp.column };
        const cell = tableHelper.findCell(newTableValue, position);
        if (!cell) return;
        const isSelected = tableHelper.isCellSelected(position.row, position.column, selectArea);
        if (!isSelected) {
          return;
        }
        const textFormatPath = comp.getCurrentPropertiesPath('properties/textFormat');
        // 补全缺失的操作路径
        const textFormatFullOperations = getPatchOperationOfSupplementingMissingProperty(comp.$data, textFormatPath, {
          '/states/*': {
            properties: {},
            enabled: false,
          },
        });
        const newTextFormat = depthClone(comp.properties.textFormat);
        if (newTextFormat) {
          newTextFormat.textAlign = cell.style.textAlign;
        }
        const textFormatPatches = {
          do: { [comp.id]: [...textFormatFullOperations.do, Ops.replace(textFormatPath, newTextFormat)] },
          undo: {
            [comp.id]: [Ops.replace(textFormatPath, comp.properties.textFormat), ...textFormatFullOperations.undo],
          },
        };
        coverPatches(patches, textFormatPatches);
      });
    return patches;
  };

  /**
   * 子组件由于单元格的合并、拆分、增删等而需要重新计算size和对齐方式
   */
  updateTextComps = (
    newTableValue: ITableValue,
    changeComps?: { id: string; row: number; column: number }[],
    removeComps?: string[],
  ) => {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    this.components
      .filter((comp) => comp.type === CText && !removeComps?.includes(comp.id))
      .map((comp) => {
        const change = { comp, row: comp.row, column: comp.column };
        const cellChange = changeComps?.find((item) => item.id === comp.id);
        if (cellChange) {
          change.column = cellChange.column;
          change.row = cellChange.row;
        }
        return change;
      })
      .forEach((change) => {
        const { comp, row, column } = change;
        const position = { row, column };
        const cell = tableHelper.findCell(newTableValue, position);
        if (!cell) return;
        const sizePatches = this.getTextSizePatches(newTableValue, comp, cell, position);
        coverPatches(patches, sizePatches);

        const textFormatPath = comp.getCurrentPropertiesPath('properties/textFormat');
        // 补全缺失的操作路径
        const textFormatFullOperations = getPatchOperationOfSupplementingMissingProperty(comp.$data, textFormatPath, {
          '/states/*': {
            properties: {},
            enabled: false,
          },
        });
        const newTextFormat = depthClone(comp.properties.textFormat);
        if (newTextFormat) {
          newTextFormat.textAlign = cell.style.textAlign;
        }
        const textFormatPatches = {
          do: { [comp.id]: [...textFormatFullOperations.do, Ops.replace(textFormatPath, newTextFormat)] },
          undo: {
            [comp.id]: [Ops.replace(textFormatPath, comp.properties.textFormat), ...textFormatFullOperations.undo],
          },
        };
        coverPatches(patches, textFormatPatches);
      });
    return patches;
  };

  private getTextSizePatches(
    newTableValue: ITableValue,
    comp: UIComponent,
    cell: ITableColumn | ITableCell,
    position: ICellPosition,
  ) {
    const cssParser = StyleHelper.initCSSStyleParser(comp.properties);
    const realArea = tableHelper.getAreaOfCell(newTableValue, cell, position);
    const bounds = tableHelper.getBoundsOfSelectArea(newTableValue, realArea);
    const opt = {
      isMultiText: true,
      isRich: true,
      wrap: true,
      defaultWidth: bounds.width - 2 * DefaultCellPadding,
    };
    const { width, height } = measureTextSize(cssParser.getTextStyle(), `${comp.value}`, opt);
    const sizePath = comp.getCurrentSizePath();
    const sizePatches = {
      do: { [comp.id]: [Ops.replace(sizePath, { ...comp.size, width, height })] },
      undo: { [comp.id]: [Ops.replace(sizePath, comp.size)] },
    };
    return sizePatches;
  }

  /**
   * 修改表格的单元格文本样式，修改文本及复选框， 获取patches
   */
  private getTextPatchesByFormatChange(
    newTableValue: ITableValue,
    position: ICellPosition,
    format: Partial<ITextFormatEx>,
  ) {
    const component = this.components.find((item) => {
      const { row, column } = item.toJSON();
      return position.row === row && position.column === column;
    });
    if (!component) {
      return;
    }
    const cell = tableHelper.findCell(newTableValue, position);
    if (!cell) {
      return;
    }
    if (component instanceof UITextComponent) {
      const realArea = tableHelper.getAreaOfCell(newTableValue, cell, position);
      const bounds = tableHelper.getBoundsOfSelectArea(newTableValue, realArea);
      const opt = {
        isMultiText: true,
        isRich: true,
        wrap: true,
        defaultWidth: bounds.width - 2 * DefaultCellPadding,
      };
      const originTextFormat = component.toJSON().properties.textFormat;
      const newTextFormat = jsonClone(Object.assign({}, originTextFormat, format));
      const childPropName = Object.keys(format)[0];
      const newValue = component.resetValueWithNewFontStyle(component.value as string, newTextFormat, childPropName);
      const cssParser = StyleHelper.initCSSStyleParser({ ...component.properties, ...{ textFormat: newTextFormat } });
      const { width, height } = measureTextSize(cssParser.getTextStyle(), `${newValue}`, opt);
      const sizePath = component.getCurrentSizePath();
      const formatPath = component.getCurrentPropertiesPath('/properties/textFormat');
      // 补全缺失的操作路径
      const textFormatFullOperations = getPatchOperationOfSupplementingMissingProperty(component.$data, formatPath, {
        '/states/*': {
          properties: {},
          enabled: false,
        },
      });
      const valuePath = component.getValuePath('/value');
      return {
        do: {
          [component.id]: [
            ...textFormatFullOperations.do,
            Ops.replace(sizePath, { ...component.size, width, height }),
            Ops.replace(formatPath, newTextFormat),
            Ops.replace(valuePath, newValue),
          ],
        },
        undo: {
          [component.id]: [
            Ops.replace(sizePath, component.size),
            Ops.replace(formatPath, originTextFormat),
            Ops.replace(valuePath, component.value),
            ...textFormatFullOperations.undo,
          ],
        },
      };
    } else {
      const originTextStyle = component.toJSON().properties.textStyle;
      const newTextStyle = jsonClone(Object.assign({}, originTextStyle, format));
      return component.setProperty('textStyle', newTextStyle);
    }
  }

  /**
   * 拆分单元格
   */
  splitCells(selectArea: ISelectArea) {
    const tableValue = this.value as ITableValue;
    const newValue = tableHelper.splitCells(tableValue, selectArea);
    const textPatches = this.updateTextComps(newValue);

    return {
      newValue,
      childrenPatches: textPatches,
    };
  }

  /**
   * 合并单元格
   */
  mergeCells(selectArea: ISelectArea) {
    const tableValue = this.value as ITableValue;
    const newValue = tableHelper.mergeCells(tableValue, selectArea);
    const patches: ArtboardPatches = { do: {}, undo: {} };
    // 把选择区域中最靠近左上角的单元格内容赋给左上角单元格, 移除其他内容
    const selectedComps = this.getCompsInArea(selectArea)
      .sort((a, b) => a.column - b.column)
      .sort((a, b) => a.row - b.row);
    if (selectedComps.length) {
      const firstComp = selectedComps[0];
      const oldPosition = { row: firstComp.row, column: firstComp.column };
      patches.do[firstComp.id] = [
        ...(patches.do[firstComp.id] || []),
        Ops.replace('/row', selectArea.start.row),
        Ops.replace('/column', selectArea.start.column),
      ];
      patches.undo[firstComp.id] = [
        ...(patches.undo[firstComp.id] || []),
        Ops.replace('/row', firstComp.row),
        Ops.replace('/column', firstComp.column),
      ];

      this.selectedComps = [firstComp];

      const firstCell = tableHelper.findCell(newValue, selectArea.start);
      const oldCell = tableHelper.findCell(newValue, oldPosition);
      if (firstCell && oldCell) {
        firstCell.data = depthClone(oldCell.data);
        firstCell.dataSource = depthClone(oldCell.dataSource);
        firstCell.type = oldCell.type;
      }
      const removeComponents = selectedComps.filter((comp) => comp.id !== firstComp.id);
      if (removeComponents.length) {
        const { patches: pagePatches } = this.removeComponents(removeComponents);
        coverPatches(patches, pagePatches[this.ownerArtboardID]);
      }

      const textPatches = this.updateTextComps(
        newValue,
        [{ id: firstComp.id, row: selectArea.start.row, column: selectArea.start.column }],
        removeComponents.map((c) => c.id),
      );
      coverPatches(patches, textPatches);
    }
    // 同步单元格交互数据
    const cellCompPatches = this.mergeCellComps(selectArea);
    coverPatches(patches, cellCompPatches);
    return {
      newValue,
      childrenPatches: patches,
    };
  }

  /**
   * 向前添加行
   */
  unshiftRows(selectRows: number[], count: number) {
    const tableValue = this.value as ITableValue;
    // 限制行数
    const newCount = min(count, MaxRowsCount - tableValue.rows.length);
    // 子组件位置迁移
    const addRowIndex = selectRows.sort((a, b) => a - b)[0];
    const patches: ArtboardPatches = { do: {}, undo: {} };
    const compCellChanges: { id: string; row: number; column: number }[] = [];
    this.components.forEach((comp) => {
      if (comp.row >= addRowIndex) {
        patches.do[comp.id] = [...(patches.do[comp.id] || []), Ops.replace('/row', comp.row + newCount)];
        patches.undo[comp.id] = [...(patches.undo[comp.id] || []), Ops.replace('/row', comp.row)];
        compCellChanges.push({ id: comp.id, row: comp.row + newCount, column: comp.column });
      }
    });
    // 交互组件行位置变化
    this.patchTableCellCompRowPosition(patches, addRowIndex, newCount);
    const newValue = tableHelper.unshiftRows(tableValue, addRowIndex, newCount);
    const textPatches = this.updateTextComps(newValue, compCellChanges);
    coverPatches(patches, textPatches);
    return {
      newValue,
      childrenPatches: patches,
    };
  }

  /**
   * 向后添加行
   */
  pushRows(selectRows: number[], count: number) {
    const tableValue = this.value as ITableValue;
    // 限制行数
    const newCount = min(count, MaxRowsCount - tableValue.rows.length);
    // 子组件位置迁移
    const addRowIndex = selectRows.sort((a, b) => b - a)[0] + 1;
    const patches: ArtboardPatches = { do: {}, undo: {} };
    const compCellChanges: { id: string; row: number; column: number }[] = [];
    if (addRowIndex < tableValue.rows.length) {
      this.components.forEach((comp) => {
        if (comp.row >= addRowIndex) {
          patches.do[comp.id] = [...(patches.do[comp.id] || []), Ops.replace('/row', comp.row + newCount)];
          patches.undo[comp.id] = [...(patches.undo[comp.id] || []), Ops.replace('/row', comp.row)];
          compCellChanges.push({ id: comp.id, row: comp.row + newCount, column: comp.column });
        }
      });
    }
    // 交互组件行位置变化
    this.patchTableCellCompRowPosition(patches, addRowIndex, newCount);
    const newValue = tableHelper.pushRows(tableValue, addRowIndex, newCount);
    const textPatches = this.updateTextComps(newValue, compCellChanges);
    coverPatches(patches, textPatches);
    return {
      newValue,
      childrenPatches: patches,
    };
  }

  /**
   * 向前添加列
   */
  unshiftColumns(selectColumns: number[], count: number) {
    const tableValue = this.value as ITableValue;
    // 限制列数
    const newCount = min(count, MaxColumnsCount - tableValue.columns.length);
    // 子组件位置迁移
    const addColumnIndex = selectColumns.sort((a, b) => a - b)[0];
    const patches: ArtboardPatches = { do: {}, undo: {} };
    const compCellChanges: { id: string; row: number; column: number }[] = [];
    this.components.forEach((comp) => {
      if (comp.column >= addColumnIndex) {
        patches.do[comp.id] = [...(patches.do[comp.id] || []), Ops.replace('/column', comp.column + newCount)];
        patches.undo[comp.id] = [...(patches.undo[comp.id] || []), Ops.replace('/column', comp.column)];
        compCellChanges.push({ id: comp.id, row: comp.row, column: comp.column + newCount });
      }
    });
    // 交互组件列位置变化
    this.patchTableCellCompColumnPosition(patches, addColumnIndex, newCount);
    const newValue = tableHelper.unshiftColumns(tableValue, addColumnIndex, newCount);
    const textPatches = this.updateTextComps(newValue, compCellChanges);
    coverPatches(patches, textPatches);
    return {
      newValue,
      childrenPatches: patches,
    };
  }

  /**
   * 向后添加列
   */
  pushColumns(selectColumns: number[], count: number) {
    const tableValue = this.value as ITableValue;
    // 限制列数
    const newCount = min(count, MaxColumnsCount - tableValue.columns.length);
    // 子组件位置迁移
    const addColumnIndex = selectColumns.sort((a, b) => b - a)[0] + 1;
    const patches: ArtboardPatches = { do: {}, undo: {} };
    const compCellChanges: { id: string; row: number; column: number }[] = [];
    if (addColumnIndex < tableValue.columns.length) {
      this.components.forEach((comp) => {
        if (comp.column >= addColumnIndex) {
          patches.do[comp.id] = [...(patches.do[comp.id] || []), Ops.replace('/column', comp.column + newCount)];
          patches.undo[comp.id] = [...(patches.undo[comp.id] || []), Ops.replace('/column', comp.column)];
          compCellChanges.push({ id: comp.id, row: comp.row, column: comp.column + newCount });
        }
      });
    }
    // 交互组件列位置变化
    this.patchTableCellCompColumnPosition(patches, addColumnIndex, newCount);
    const newValue = tableHelper.pushColumns(tableValue, addColumnIndex, newCount);
    const textPatches = this.updateTextComps(newValue, compCellChanges);
    coverPatches(patches, textPatches);
    return {
      newValue,
      childrenPatches: patches,
    };
  }

  /**
   * 删除行，处理子组件
   */
  removeRows(selectRows: number[]) {
    const tableValue = this.value as ITableValue;
    const newRowArr = Array.from(new Set(selectRows)).sort((a, b) => b - a);

    const patches: ArtboardPatches = { do: {}, undo: {} };

    const removeComponents: UIComponent[] = [];
    const removeCellUIComponents: UIComponent[] = [];
    const compCellChanges: { id: string; row: number; column: number }[] = [];
    this.components.forEach((comp) => {
      if (newRowArr.includes(comp.row)) {
        removeComponents.push(comp);
      } else {
        const step = newRowArr.filter((item) => item < comp.row).length;
        if (step) {
          // 移动组件
          patches.do[comp.id] = [...(patches.do[comp.id] || []), Ops.replace('/row', comp.row - step)];
          patches.undo[comp.id] = [...(patches.undo[comp.id] || []), Ops.replace('/row', comp.row)];
          compCellChanges.push({ id: comp.id, row: comp.row - step, column: comp.column });
        }
      }
    });
    this.eachTableCellComps((comp) => {
      if (newRowArr.includes(comp.row)) {
        removeCellUIComponents.push(comp);
      } else {
        const step = newRowArr.filter((item) => item < comp.row).length;
        if (step) {
          // 移动组件
          patches.do[comp.id] = [...(patches.do[comp.id] || []), Ops.replace('/row', comp.row - step)];
          patches.undo[comp.id] = [...(patches.undo[comp.id] || []), Ops.replace('/row', comp.row)];
        }
      }
    });
    // 操作单元格组件
    const tableCellContainer = this.getTableCellContainerComp();
    if (tableCellContainer) {
      // 删除单元格UI组件，目前单元格UI组件，主要用于单元格交互事件
      const { patches: removeCellPatches } = tableCellContainer.removeComponents(removeCellUIComponents);
      coverPatches(patches, removeCellPatches[tableCellContainer.ownerArtboardID]);
    }
    // 删除组件
    const { patches: pagePatches } = this.removeComponents(removeComponents);
    coverPatches(patches, pagePatches[this.ownerArtboardID]);

    let newValue = depthClone(tableValue);
    newRowArr.forEach((rowIndex) => {
      if (rowIndex < -1 || rowIndex >= tableValue.rows.length) return;
      newValue = tableHelper.removeRow(newValue, rowIndex);
    });
    const textPatches = this.updateTextComps(
      newValue,
      compCellChanges,
      removeComponents.map((comp) => comp.id),
    );
    coverPatches(patches, textPatches);
    return {
      newValue,
      childrenPatches: patches,
    };
  }

  /**
   * 删除列, 处理子组件
   */
  removeColumns(selectColumns: number[]) {
    const tableValue = this.value as ITableValue;
    const newColumnArr = Array.from(new Set(selectColumns)).sort((a, b) => b - a);

    const patches: ArtboardPatches = { do: {}, undo: {} };
    const compCellChanges: { id: string; row: number; column: number }[] = [];
    const removeComponents: UIComponent[] = [];
    const removeCellUIComponents: UIComponent[] = [];
    this.components.forEach((comp) => {
      if (newColumnArr.includes(comp.column)) {
        removeComponents.push(comp);
      } else {
        const step = newColumnArr.filter((item) => item < comp.column).length;
        if (step) {
          // 移动组件
          patches.do[comp.id] = [...(patches.do[comp.id] || []), Ops.replace('/column', comp.column - step)];
          patches.undo[comp.id] = [...(patches.undo[comp.id] || []), Ops.replace('/column', comp.column)];
          compCellChanges.push({ id: comp.id, row: comp.row, column: comp.column - step });
        }
      }
    });
    this.eachTableCellComps((comp) => {
      if (newColumnArr.includes(comp.column)) {
        removeCellUIComponents.push(comp);
      } else {
        const step = newColumnArr.filter((item) => item < comp.column).length;
        if (step) {
          // 移动组件
          patches.do[comp.id] = [...(patches.do[comp.id] || []), Ops.replace('/column', comp.column - step)];
          patches.undo[comp.id] = [...(patches.undo[comp.id] || []), Ops.replace('/column', comp.column)];
        }
      }
    });
    // 操作单元格组件
    const tableCellContainer = this.getTableCellContainerComp();
    if (tableCellContainer) {
      // 删除单元格UI组件，目前单元格UI组件，主要用于单元格交互事件
      const { patches: removeCellPatches } = tableCellContainer.removeComponents(removeCellUIComponents);
      coverPatches(patches, removeCellPatches[tableCellContainer.ownerArtboardID]);
    }
    // 删除组件
    const { patches: pagePatches } = this.removeComponents(removeComponents);
    coverPatches(patches, pagePatches[this.ownerArtboardID]);

    let newValue = depthClone(tableValue);
    newColumnArr.forEach((columnIndex) => {
      if (columnIndex < 0 || columnIndex >= tableValue.columns.length) return;
      newValue = tableHelper.removeColumn(newValue, columnIndex);
    });
    const textPatches = this.updateTextComps(
      newValue,
      compCellChanges,
      removeComponents.map((comp) => comp.id),
    );
    coverPatches(patches, textPatches);
    return {
      newValue,
      childrenPatches: patches,
    };
  }

  /**
   * 移动行
   */
  moveRows(start: number, end: number, newIndex: number) {
    const moveOffset = newIndex - start;
    const copy = depthClone(this.value) as ITableValue;
    const { columns } = copy;
    const selectArea: ISelectArea = {
      start: {
        row: start,
        column: 0,
      },
      end: {
        row: end,
        column: columns.length - 1,
      },
    };
    const moveUp = newIndex < start;
    const exchangeArea: ISelectArea = {
      start: {
        row: moveUp ? newIndex : end + 1,
        column: 0,
      },
      end: {
        row: moveUp ? start - 1 : end + newIndex - start,
        column: columns.length - 1,
      },
    };

    const patches: ArtboardPatches = { do: {}, undo: {} };
    // 移动组件
    this.components.forEach((comp) => {
      const cellPosition = { row: comp.row, column: comp.column };
      let step = 0;
      if (tableHelper.isCellSelected(cellPosition.row, cellPosition.column, selectArea)) {
        step = moveOffset;
      } else if (tableHelper.isCellSelected(cellPosition.row, cellPosition.column, exchangeArea)) {
        step = Math.sign(moveOffset) * (start - end - 1);
      }
      if (step) {
        // 移动组件
        patches.do[comp.id] = [...(patches.do[comp.id] || []), Ops.replace('/row', comp.row + step)];
        patches.undo[comp.id] = [...(patches.undo[comp.id] || []), Ops.replace('/row', comp.row)];
      }
    });
    // 移动对应单元格的交互组件
    this.eachTableCellComps((comp) => {
      const cellPosition = { row: comp.row, column: comp.column };
      let step = 0;
      if (tableHelper.isCellSelected(cellPosition.row, cellPosition.column, selectArea)) {
        step = moveOffset;
      } else if (tableHelper.isCellSelected(cellPosition.row, cellPosition.column, exchangeArea)) {
        step = Math.sign(moveOffset) * (start - end - 1);
      }
      if (step) {
        // 移动组件
        patches.do[comp.id] = [...(patches.do[comp.id] || []), Ops.replace('/row', comp.row + step)];
        patches.undo[comp.id] = [...(patches.undo[comp.id] || []), Ops.replace('/row', comp.row)];
      }
    });
    const newValue = tableHelper.moveRows(copy, start, end, newIndex);
    return {
      newValue,
      childrenPatches: patches,
    };
  }

  /**
   * 移动列
   */
  moveColumns(start: number, end: number, newIndex: number) {
    const moveOffset = newIndex - start;
    const copy = depthClone(this.value) as ITableValue;
    const { rows, showHeader } = copy;
    const selectArea: ISelectArea = {
      start: {
        row: showHeader ? -1 : 0,
        column: start,
      },
      end: {
        row: rows.length - 1,
        column: end,
      },
    };
    const moveUp = newIndex < start;
    const exchangeArea: ISelectArea = {
      start: {
        row: showHeader ? -1 : 0,
        column: moveUp ? newIndex : end + 1,
      },
      end: {
        row: rows.length - 1,
        column: moveUp ? start - 1 : end + newIndex - start,
      },
    };

    const patches: ArtboardPatches = { do: {}, undo: {} };
    // 移动组件
    this.components.forEach((comp) => {
      const cellPosition = { row: comp.row, column: comp.column };
      let step = 0;
      if (tableHelper.isCellSelected(cellPosition.row, cellPosition.column, selectArea)) {
        step = moveOffset;
      } else if (tableHelper.isCellSelected(cellPosition.row, cellPosition.column, exchangeArea)) {
        step = Math.sign(moveOffset) * (start - end - 1);
      }
      if (step) {
        // 移动组件
        patches.do[comp.id] = [...(patches.do[comp.id] || []), Ops.replace('/column', comp.column + step)];
        patches.undo[comp.id] = [...(patches.undo[comp.id] || []), Ops.replace('/column', comp.column)];
      }
    });
    // 移动对应的单元格的交互组件
    this.eachTableCellComps((comp) => {
      const cellPosition = { row: comp.row, column: comp.column };
      let step = 0;
      if (tableHelper.isCellSelected(cellPosition.row, cellPosition.column, selectArea)) {
        step = moveOffset;
      } else if (tableHelper.isCellSelected(cellPosition.row, cellPosition.column, exchangeArea)) {
        step = Math.sign(moveOffset) * (start - end - 1);
      }
      if (step) {
        // 移动组件
        patches.do[comp.id] = [...(patches.do[comp.id] || []), Ops.replace('/column', comp.column + step)];
        patches.undo[comp.id] = [...(patches.undo[comp.id] || []), Ops.replace('/column', comp.column)];
      }
    });
    const newValue = tableHelper.moveColumns(copy, start, end, newIndex);
    return {
      newValue,
      childrenPatches: patches,
    };
  }

  /**
   * 清除样式, 清除左、上相邻格的边框样式
   */
  cleanStyle(cellPositions: ICellPosition[]) {
    const copy = depthClone(this.value as ITableValue);
    const patches: ArtboardPatches = { do: {}, undo: {} };

    cellPositions.forEach((position) => {
      const cell = tableHelper.findCell(copy, position);
      const selectComp = this.components.find((comp) => comp.row === position.row && comp.column === position.column);
      if (cell) {
        if (cell.mergeAcross + cell.mergeDown) {
          const realArea = tableHelper.getAreaOfCell(copy, cell, position);
          const cells = tableHelper.getAllCellsOfSelectArea(copy, realArea);
          cells.forEach((c) => {
            c.cell.style = depthClone(DefalutCellStyle);
          });
        } else {
          cell.style = depthClone(DefalutCellStyle);
        }
        if (selectComp) {
          const data = tableHelper.formatCompPropertiesAndValue(selectComp, cell.style);

          if (data) {
            const { newProperties, newValue } = data;

            const cssParser = StyleHelper.initCSSStyleParser(newProperties);
            // 文本尺寸也需要重新计算
            const { width, height } = measureTextSize(cssParser.getTextStyle(), newValue, {
              isMultiText: true,
              isRich: true,
              wrap: true,
              defaultWidth: selectComp.size.width,
            });

            Object.keys(newProperties).forEach((key) => {
              const value = newProperties[key];
              if (value) {
                const propertyPatches = selectComp.setProperty(key, value);
                coverPatches(patches, propertyPatches);
              }
            });
            if (!isUndefined(newValue)) {
              const sizePatches = selectComp.setSize({ width, height });
              const valuePatches = selectComp.setValue(newValue);
              coverPatches(patches, valuePatches);
              coverPatches(patches, {
                do: {
                  [selectComp.id]: sizePatches.do,
                },
                undo: {
                  [selectComp.id]: sizePatches.undo,
                },
              });
            }
          }
        }
      }
    });

    return {
      newValue: copy,
      childrenPatches: patches,
    };
  }

  /**
   * 清除单元格内容
   */
  cleanContent(cellPositions: ICellPosition[]) {
    const copy = depthClone(this.value as ITableValue);
    const removeComps: UIComponent[] = [];
    cellPositions.forEach((position) => {
      const cell = tableHelper.findCell(copy, position);
      const comps = this.components.filter((comp) => comp.row === position.row && comp.column === position.column);
      if (comps.length) {
        removeComps.push(...comps);
      }
      if (cell) {
        // if (cell.mergeAcross + cell.mergeDown) {
        //   const realArea = tableHelper.getAreaOfCell(copy, cell, position);
        //   const cells = tableHelper.getAllCellsOfSelectArea(copy, realArea);
        //   cells.forEach(c => {
        //     c.cell.data = depthClone(DefaultCell.data);
        //     c.cell.type = depthClone(DefaultCell.type);
        //     c.cell.dataSource = depthClone(DefaultCell.dataSource);
        //   })
        // } else {
        cell.data = depthClone(DefaultCell.data);
        cell.type = depthClone(DefaultCell.type);
        cell.dataSource = depthClone(DefaultCell.dataSource);
        // }
      }
    });
    // 删除组件
    const { patches: pagePatches } = this.removeComponents(removeComps);
    return {
      newValue: copy,
      childrenPatches: pagePatches[this.ownerArtboardID],
    };
  }
  /**
   * 清除内容与样式
   */
  cleanContentAndStyle(cellPositions: ICellPosition[]) {
    const copy = depthClone(this.value as ITableValue);
    const removeComps: UIComponent[] = [];
    const patches: ArtboardPatches = { do: {}, undo: {} };
    cellPositions.forEach((position) => {
      const cell = tableHelper.findCell(copy, position);
      const selectComp = this.components.find((comp) => comp.row === position.row && comp.column === position.column);
      if (selectComp) {
        removeComps.push(selectComp);
      }
      if (cell) {
        cell.data = depthClone(DefaultCell.data);
        cell.type = depthClone(DefaultCell.type);
        cell.dataSource = depthClone(DefaultCell.dataSource);
        //style
        if (cell.mergeAcross + cell.mergeDown) {
          const realArea = tableHelper.getAreaOfCell(copy, cell, position);
          const cells = tableHelper.getAllCellsOfSelectArea(copy, realArea);
          cells.forEach((c) => {
            c.cell.style = depthClone(DefalutCellStyle);
          });
        } else {
          cell.style = depthClone(DefalutCellStyle);
        }
        if (selectComp) {
          const data = tableHelper.formatCompPropertiesAndValue(selectComp, cell.style);
          if (data) {
            const { newProperties, newValue } = data;
            Object.keys(newProperties).forEach((key) => {
              const value = newProperties[key];
              if (value) {
                const propertyPatches = selectComp.setProperty(key, value);
                coverPatches(patches, propertyPatches);
              }
            });
            if (!isUndefined(newValue)) {
              const valuePatches = selectComp.setValue(newValue);
              coverPatches(patches, valuePatches);
            }
          }
        }
      }
    });
    // 删除组件
    const { patches: pagePatches } = this.removeComponents(removeComps);
    return {
      newValue: copy,
      childrenPatches: mergePatches(pagePatches[this.ownerArtboardID], patches),
    };
  }

  /**
   * 转化单元格类型
   */
  convertCellContentType(cellPositions: ICellPosition[], type: CellTypes) {
    const copy = depthClone(this.value as ITableValue);
    const removeComps: UIComponent[] = [];
    const addCompDatas: IComponentData[] = [];
    cellPositions.forEach((position) => {
      const cell = tableHelper.findCell(copy, position);
      if (cell?.mergedBy) return;
      const comps = this.components.filter((comp) => comp.row === position.row && comp.column === position.column);
      const curType = cell?.type || CellTypes.Text;
      let couldConvert = type !== curType;
      const change = { text: '' };
      if (comps.length && couldConvert) {
        removeComps.push(...comps);
        const firstComp = comps[0];
        change.text = sanitizeHtml((firstComp.value || '') as string, {
          allowedTags: [],
          allowedAttributes: {},
        });
        const { properties } = firstComp;
        const textFormat = firstComp.type === CText ? properties.textFormat : properties.textStyle;
        Object.assign(change, textFormat);
      } else {
        const textFormat = cell?.style.textFormat;
        Object.assign(change, textFormat);
      }
      if (couldConvert) {
        // 新类型组件
        const newComp = tableHelper.makeCompInTable(type, change, copy, position);
        if (newComp) {
          addCompDatas.push(newComp);
        }
        if (cell) {
          cell.data = depthClone(DefaultCell.data);
          cell.type = depthClone(type);
          cell.dataSource = depthClone(DefaultCell.dataSource);
        }
      }
    });
    // 删除组件
    const { patches: removePatches } = this.removeComponents(removeComps);
    // 增加组件
    const { patches: addPatches } = this.addComponents(addCompDatas);
    const patches = removePatches[this.ownerArtboardID];
    coverPatches(patches, addPatches[this.ownerArtboardID]);
    return {
      newValue: copy,
      childrenPatches: patches,
    };
  }

  /**
   *  表格数据粘贴
   */
  doPasteData(
    copyData: ITableClipboardData,
    pasteAreas: ISelectArea[],
    addRows: number,
    addColumns: number,
  ): {
    newValue: ITableValue;
    childrenPatches: ArtboardPatches;
  } {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    let newValue = jsonClone(this.value as ITableValue);
    const { cells: copyCells, rowHeights, colWidths, components, cellComponents } = copyData;
    // 如果复制的数据存在组件就不必重新通过cells构建事件数据；
    const hasCopyComps = isNumber(components?.length);
    if (!copyCells.length || !copyCells[0].length || !pasteAreas.length) {
      return {
        newValue,
        childrenPatches: patches,
      };
    }
    const addCompDatas: IComponentData[] = [];

    const addCellCompDatas: IComponentData[] = []; // 添加单元格组件数据,目前主要功能是为了粘贴时拷贝交互
    const removeCellComps: UIComponent[] = []; // 删除被粘贴区域的单元格组件
    // 增加行列
    if (addRows) {
      newValue = tableHelper.pushRows(newValue, newValue.rows.length, addRows);
    }
    if (addColumns) {
      newValue = tableHelper.pushColumns(newValue, newValue.columns.length, addColumns);
    }
    // 粘贴区域（合）
    const unitPasteArea = jsonClone(pasteAreas).reduce((area1, area2) => tableHelper.uniteSelectArea(area1, area2));
    // 清除粘贴区域的单元格合并
    newValue = tableHelper.splitCells(newValue, unitPasteArea);
    // 粘贴单元格结构
    pasteAreas.forEach((area) => {
      const start = area.start;
      const cells = tableHelper.getAllCellsOfSelectArea(newValue, area);
      TableClipboardHelper.setPasteAreaRowAndColumnSize(newValue, area, rowHeights, colWidths);
      cells.forEach((item) => {
        const { cell, position } = item;
        const copyCellRowIndex = position.row - start.row;
        const copyCellColumnIndex = position.column - start.column;
        const copyCell = copyCells[copyCellRowIndex][copyCellColumnIndex];
        if (!copyCell) {
          return;
        }
        const { mergeAcross, mergeDown, data, style, dataSource, type, mergedBy } = copyCell;
        cell.mergeAcross = mergeAcross;
        cell.mergeDown = mergeDown;
        cell.data = data;
        cell.style = merge({}, cell.style, style); // 合并
        cell.dataSource = dataSource;
        cell.type = type;
        if (mergedBy) {
          const [colIndex, rowIndex] = mergedBy;
          cell.mergedBy = [colIndex + start.column, rowIndex + start.row];
        }
        // 操作拷贝区的单元格组件数据
        if (cellComponents && cellComponents.length) {
          const currentCellComp = this.getTableCellComp(position.row, position.column);
          const compData = cellComponents?.find((c) => c.row === copyCellRowIndex && c.column === copyCellColumnIndex);
          if (compData) {
            const newComp = jsonClone(compData);
            newComp._id = getNewID();
            newComp.row = position.row;
            newComp.column = position.column;
            addCellCompDatas.push(newComp);
          }
          if (currentCellComp) {
            removeCellComps.push(currentCellComp);
          }
        }
        if (hasCopyComps) {
          const compData = components?.find((c) => c.row === copyCellRowIndex && c.column === copyCellColumnIndex);
          if (compData) {
            const newComp = jsonClone(compData);
            newComp._id = getNewID();
            newComp.row = position.row;
            newComp.column = position.column;
            addCompDatas.push(newComp);
          }
        } else if (data.text) {
          const newComp = tableHelper.makeCompInTable(
            type,
            {
              text: data.text,
              fontSize: cell.style.textFormat?.fontSize,
              color: cell.style.textFormat?.color as PureColor,
              fontStyle: cell.style.textFormat?.fontStyle,
              fontFamily: cell.style.textFormat?.fontFamily,
            },
            newValue,
            position,
          );
          newComp && addCompDatas.push(newComp);
        }
      });
      // 修改粘贴区域的行高列宽
    });

    // 删除组件
    const removeComps: UIComponent[] = this.components.filter((comp) => {
      return tableHelper.isCellSelected(comp.row, comp.column, unitPasteArea);
    });
    const { patches: removePatches } = this.removeComponents(removeComps);
    coverPatches(patches, removePatches[this.ownerArtboardID]);

    // 新增组件
    const { patches: addPatches } = this.addComponents(addCompDatas);
    coverPatches(patches, addPatches[this.ownerArtboardID]);

    // 操作单元格组件
    const tableCellContainer = this.getTableCellContainerComp();
    // 粘贴新的拷贝区的单元格组件
    if (tableCellContainer && addCellCompDatas.length) {
      const { patches: addCellPatches } = tableCellContainer.addComponents(addCellCompDatas);
      coverPatches(patches, addCellPatches[tableCellContainer.ownerArtboardID]);
    }
    // 删除粘贴区当前单元格组件,只有拷贝存在单元格组件
    if (tableCellContainer && removeCellComps.length) {
      const { patches: removeCellPatches } = tableCellContainer.removeComponents(removeCellComps);
      coverPatches(patches, removeCellPatches[tableCellContainer.ownerArtboardID]);
    }

    return {
      newValue,
      childrenPatches: patches,
    };
  }

  /**
   * 提交的patches都对组件位置进行修正
   */
  public getNewPatchesWithFixChildrenData = (oldPatches?: ArtboardPatches) => {
    const newPatches: ArtboardPatches = oldPatches ?? { do: {}, undo: {} };

    const editingIDs = Object.keys(oldPatches?.do ?? {});
    const fixPositionIDs: string[] = [];
    const fixBehaverIDs: string[] = [];
    this.components.forEach((item) => {
      const {
        id,
        position: { x, y },
      } = item;
      if (editingIDs.includes(id) || x || y) {
        fixPositionIDs.push(id);
      }
      const { autoSize, textBehaviour } = item.toJSON();
      if (editingIDs.includes(id) || !autoSize || textBehaviour !== ETextBehaviour.Height) {
        fixBehaverIDs.push(id);
      }
    });
    if (fixPositionIDs.length) {
      const fixPatches: ArtboardPatches = { do: {}, undo: {} };
      fixPositionIDs.forEach((id) => {
        fixPatches.do[id] = [Ops.replace('/position', { x: 0, y: 0 })];
        fixPatches.undo[id] = [];
      });
      mergePatches(newPatches, fixPatches);
    }
    if (fixBehaverIDs.length) {
      const fixPatches: ArtboardPatches = { do: {}, undo: {} };
      fixBehaverIDs.forEach((id) => {
        fixPatches.do[id] = [Ops.replace('/autoSize', true), Ops.replace('/textBehaviour', ETextBehaviour.Height)];
        fixPatches.undo[id] = [];
      });
      mergePatches(newPatches, fixPatches);
    }
    return newPatches;
  };

  /**
   * 获取单元格UI组件的容器
   */
  public getTableCellContainerComp(): UIPanelComponent | undefined {
    return (
      this.tableCellContainer ||
      (this.tableCellContainer = this.getComponentByAlias('TableCellContainer') as UIPanelComponent | undefined)
    );
  }

  /**
   * 获取对应单元格的ui组件
   * @param {number} row 行索引
   * @param {number} column 列索引
   */
  public getTableCellComp(row: number, column: number) {
    const container = this.getTableCellContainerComp();
    if (!container) {
      return;
    }
    return container.components.find((d) => d.row === row && d.column === column);
  }

  /**
   * 创建对应的单元格UI组件
   * @param {number} row 行索引
   * @param {number} column 列索引
   */
  public createTableCellComp(row: number, column: number): PagePatches {
    const cellData: IComponentData = makeCommonComponent(getNewID(), CTableCell, {
      lib: {
        id: 'common',
        type: CTableCell,
      },
      row: row,
      column: column,
    });
    const container = this.getTableCellContainerComp();
    // 创建容器，只创建一次
    if (!container) {
      const containerCompData = makeCommonComponent(getNewID(), CCanvasPanel, {
        alias: 'TableCellContainer',
        row: Number.MAX_SAFE_INTEGER,
        column: Number.MAX_SAFE_INTEGER,
        components: [],
        sealed: true,
      });
      const { patches } = this.addComponents([containerCompData]);
      patches[this.ownerArtboardID].do[containerCompData._id] = [Ops.addChildren(`-1`, [cellData])];
      return patches;
    } else {
      const { patches } = container.addComponents([cellData]);
      return patches;
    }
  }

  /**
   * 从选中区域获取所有单元格组件
   */
  getCellCompsFromArea(selectArea?: ISelectArea) {
    if (!selectArea) {
      return [];
    }
    return this.getTableCellContainerComp()?.components.filter((comp) => {
      return tableHelper.isCellSelected(comp.row, comp.column, selectArea);
    });
  }

  /**
   * 从单元格组件中找出存在交互事件靠前的组件，如果没有就返回最靠前的
   */
  getFrontInteractionFromCellComps(comps: UIComponent[]): UIComponent | null {
    let frontInteractionComp = null,
      frontComp = null,
      row = Infinity,
      column = Infinity,
      interactionRow = Infinity,
      interactionColumn = Infinity;
    comps.forEach((comp) => {
      const r = comp.row;
      const c = comp.column;
      if (r < row || (r <= row && c < column)) {
        row = r;
        column = c;
        frontComp = comp;
      }
      if ((r < interactionRow || (r <= interactionRow && c < interactionColumn)) && comp.hasInteraction()) {
        interactionRow = r;
        interactionColumn = c;
        frontInteractionComp = comp;
      }
    });
    return frontInteractionComp || frontComp;
  }

  /**
   * 合并单元格组件数据
   */
  mergeCellComps(selectArea: ISelectArea) {
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    const container = this.getTableCellContainerComp();
    const components = this.getCellCompsFromArea(selectArea);
    // 获取靠前的组件
    const frontComponent = components && this.getFrontInteractionFromCellComps(components);
    // 如果当前组件的位置与选中区域左上角不同，更新位置
    if (
      container &&
      frontComponent &&
      (frontComponent.row !== selectArea.start.row || frontComponent.column !== selectArea.start.column)
    ) {
      patches.do[frontComponent.id] = [
        Ops.replace('/row', selectArea.start.row),
        Ops.replace('/column', selectArea.start.column),
      ];
      patches.undo[frontComponent.id] = [
        Ops.replace('/row', frontComponent.row),
        Ops.replace('/column', frontComponent.column),
      ];
    }
    // 删除(frontComponent)以外选中区域所有组件
    if (container && components && frontComponent) {
      const { patches: removeCompPatches } = container.removeComponents(
        components.filter((comp) => comp.id !== frontComponent.id),
      );
      coverPatches(patches, removeCompPatches[container.ownerArtboardID]);
    }
    return patches;
  }
  /**
   * 访问表格每个单元格组件
   * @author 樊勇龙
   * @time 2022.10.24
   */
  eachTableCellComps(callback: (comp: UIComponent, index: number) => void) {
    const tableContainer = this.getTableCellContainerComp();
    if (tableContainer) {
      tableContainer.components.forEach(callback);
    }
  }

  /**
   * 更新表格交互组件的行位置
   * @author 樊勇龙
   * @time 2022.10.24
   */
  patchTableCellCompRowPosition(patches: ArtboardPatches, addRowIndex: number, newCount: number) {
    // 交互组件行位置变化
    this.eachTableCellComps((comp) => {
      if (comp.row >= addRowIndex) {
        patches.do[comp.id] = [...(patches.do[comp.id] || []), Ops.replace('/row', comp.row + newCount)];
        patches.undo[comp.id] = [...(patches.undo[comp.id] || []), Ops.replace('/row', comp.row)];
      }
    });
  }

  /**
   * 更新表格交互组件的列位置
   * @author 樊勇龙
   * @time 2022.10.24
   */
  patchTableCellCompColumnPosition(patches: ArtboardPatches, addColumnIndex: number, newCount: number) {
    // 交互组件行位置变化
    this.eachTableCellComps((comp) => {
      if (comp.column >= addColumnIndex) {
        patches.do[comp.id] = [...(patches.do[comp.id] || []), Ops.replace('/column', comp.column + newCount)];
        patches.undo[comp.id] = [...(patches.undo[comp.id] || []), Ops.replace('/column', comp.column)];
      }
    });
  }

  autoFill(valueBuildCallback: (value?: string) => string): ArtboardPatches | undefined {
    const tableValue = this.value as ITableValue;
    const rowCount = tableValue.rows.length;
    const colCount = tableValue.columns.length;
    const startRow = this.selectArea?.start.row ?? 0;
    const endRow = this.selectArea?.end.row ?? rowCount - 1;
    const startCol = this.selectArea?.start.column ?? 0;
    const endCol = this.selectArea?.end.column ?? colCount - 1;

    const patches: ArtboardPatches = { do: {}, undo: {} };
    const artboardID = this.ownerArtboardID;

    const comps = this.components;

    const container = this.getTableCellContainerComp();

    let cellTextData: IComponentData;

    // 合并patches， 表格的特殊性，每个单元格是一个新的数据，理论上不存在重复
    const _doMergePatches = (source: ArtboardPatches, target: ArtboardPatches) => {
      const { do: _do, undo } = target;
      Object.keys(_do).forEach((id) => {
        if (!source.do[id]) {
          source.do[id] = _do[id];
        } else {
          source.do[id].push(..._do[id]);
        }
      });
      Object.keys(undo).forEach((id) => {
        if (!source.undo[id]) {
          source.undo[id] = undo[id];
        } else {
          source.undo[id].push(...undo[id]);
        }
      });
    };

    // 创建单元格中的文本组件
    const createTextComp = (patches: ArtboardPatches, rowIndex: number, colIndex: number) => {
      const column = tableValue.columns[colIndex];
      const value = valueBuildCallback(CText);
      const textID = getNewID();
      let textData;
      if (!cellTextData) {
        cellTextData = makeText('', value);
      }
      textData = { ...cellTextData, _id: textID, value, properties: { ...cellTextData.properties } };
      const cell = tableValue.cells[rowIndex][colIndex];
      const cssParser = StyleHelper.initCSSStyleParser(textData.properties);
      textData.row = rowIndex;
      textData.column = colIndex;
      textData.autoSize = true;
      textData.textBehaviour = ETextBehaviour.Height;
      textData.position = { x: 0, y: 0 };
      const { width, height } = measureTextSize(cssParser.getTextStyle(), value, {
        defaultWidth: column.width - DefaultCellPadding * 2,
        wrap: true,
        isMultiText: true,
        isRich: true,
      });
      textData.size = { width, height };
      const textProp = textData.properties.textFormat;
      if (textProp) {
        const textFormat = { ...textProp };
        textFormat.textAlign = cell.style.textAlign;
        textFormat.verticalAlign = cell.style.verticalAlign;
        textFormat.wrap = true;
        textData.properties.textFormat = textFormat;
      }
      const { patches: textArtboardPatches } = this.addComponents([textData]);
      if (textArtboardPatches) {
        _doMergePatches(patches, textArtboardPatches[artboardID]);
      }
    };

    // 创建单元格中的容器
    const createCellComp = (rowIndex: number, colIndex: number): IComponentData => {
      return makeCommonComponent(getNewID(), CTableCell, {
        lib: {
          id: 'common',
          type: CTableCell,
        },
        row: rowIndex,
        column: colIndex,
      });
    };

    // 获取单元格文本组件
    const getTextComp = (rowIndex: number, colIndex: number): UIComponent | undefined => {
      return comps.find((comp) => {
        return comp.row === rowIndex && comp.column === colIndex;
      });
    };

    // 填充整个表格
    const fillAll = () => {
      const containerCompData = makeCommonComponent(getNewID(), CCanvasPanel, {
        alias: 'TableCellContainer',
        row: Number.MAX_SAFE_INTEGER,
        column: Number.MAX_SAFE_INTEGER,
        components: [],
        sealed: true,
      });

      for (let i = startRow; i <= endRow; i++) {
        for (let j = startCol; j <= endCol; j++) {
          const cellData = createCellComp(i, j);
          containerCompData.components?.push(cellData);
          createTextComp(patches, i, j);
        }
      }
      const { patches: containerPatches } = this.addComponents([containerCompData], 0);
      if (containerPatches) {
        _doMergePatches(patches, containerPatches[artboardID]);
      }
      return patches;
    };

    // 填充选中单元格
    const fillSelectCell = () => {
      for (let i = startRow; i <= endRow; i++) {
        for (let j = startCol; j <= endCol; j++) {
          // 这个获取的是单元格
          const comp = this.getTableCellComp(i, j);
          const textComp = getTextComp(i, j);

          if (!comp) {
            const { patches: cellPatches } = container!.addComponents([createCellComp(i, j)]);
            cellPatches && _doMergePatches(patches, cellPatches[artboardID]);
          }

          if (textComp) {
            const opt = textComp.autoFill(valueBuildCallback);
            opt && _doMergePatches(patches, opt);
          } else {
            createTextComp(patches, i, j);
          }
        }
      }
      return patches;
    };

    // 全量填充表格
    if (!container) {
      return fillAll();
    }
    return fillSelectCell();
  }

  getSelectedTextCompsCountWithAutoFill() {
    if (this.selectArea) {
      const { start, end } = this.selectArea;
      return (end.row - start.row + 1) * (end.column - start.column + 1);
    }
    const value = this.value as ITableValue;
    return value.rows.length * value.columns.length;
  }
}
