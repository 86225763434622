export const SpriteThumb = {
  APQC: {
    className: 'sprite-icon-APQC',
    position: { x: 0, y: 0 },
  },
  Arrow: {
    className: 'sprite-icon-arrow',
    position: { x: 40, y: 0 },
  },
  Beveledrectangle: {
    className: 'sprite-icon-beveledrectangle',
    position: { x: 0, y: 40 },
  },
  BreadCrumbs: {
    className: 'sprite-icon-breadcrumbs',
    position: { x: 40, y: 40 },
  },
  Button: {
    className: 'sprite-icon-button',
    position: { x: 80, y: 0 },
  },
  CallOut: {
    className: 'sprite-icon-callout',
    position: { x: 80, y: 40 },
  },
  CanvasPanel: {
    className: 'sprite-icon-canvas-panel',
    position: { x: 0, y: 80 },
  },
  Frame: {
    className: 'sprite-icon-frame',
    position: { x: 200, y: 360 },
  },
  CheckBox: {
    className: 'sprite-icon-checkbox',
    position: { x: 40, y: 80 },
  },
  CheckBoxGroup: {
    className: 'sprite-icon-checkbox-group',
    position: { x: 80, y: 80 },
  },
  Circle: {
    className: 'sprite-icon-circle',
    position: { x: 0, y: 400 },
  },

  CircleProgress: {
    className: 'sprite-icon-circle-progress',
    position: { x: 120, y: 40 },
  },
  ClassicTab: {
    className: 'sprite-icon-classic-tab',
    position: { x: 120, y: 80 },
  },
  Cloud: {
    className: 'sprite-icon-cloud',
    position: { x: 0, y: 120 },
  },
  Collate: {
    className: 'sprite-icon-collate',
    position: { x: 40, y: 120 },
  },
  ContentPanel: {
    className: 'sprite-icon-content-panel',
    position: { x: 80, y: 120 },
  },
  ContentPanelV2: {
    className: 'sprite-icon-content-panel-v2',
    position: { x: 280, y: 440 },
  },
  Cross: {
    className: 'sprite-icon-cross',
    position: { x: 120, y: 120 },
  },
  Database: {
    className: 'sprite-icon-database',
    position: { x: 160, y: 0 },
  },
  Delay: {
    className: 'sprite-icon-delay',
    position: { x: 160, y: 40 },
  },
  Diamond: {
    className: 'sprite-icon-diamond',
    position: { x: 160, y: 80 },
  },
  Display: {
    className: 'sprite-icon-display',
    position: { x: 160, y: 120 },
  },

  Document: {
    className: 'sprite-icon-document',
    position: { x: 0, y: 160 },
  },
  DownArrow: {
    className: 'sprite-icon-down-arrow',
    position: { x: 40, y: 160 },
  },
  Drop: {
    className: 'sprite-icon-drop',
    position: { x: 80, y: 160 },
  },
  Drops: {
    className: 'sprite-icon-drops',
    position: { x: 120, y: 160 },
  },
  Ellipse: {
    className: 'sprite-icon-ellipse',
    position: { x: 160, y: 160 },
  },
  ExternalData: {
    className: 'sprite-icon-external-data',
    position: { x: 200, y: 0 },
  },
  Heart: {
    className: 'sprite-icon-heart',
    position: { x: 200, y: 40 },
  },
  Hexagon: {
    className: 'sprite-icon-hexagon',
    position: { x: 200, y: 80 },
  },
  HorizontalDoubleArrow: {
    className: 'sprite-icon-horizontal-double-arrow',
    position: { x: 200, y: 120 },
  },
  Hotspot: {
    className: 'sprite-icon-hotspot',
    position: { x: 200, y: 160 },
  },

  Image: {
    className: 'sprite-icon-image',
    position: { x: 0, y: 200 },
  },
  Keyboard: {
    className: 'sprite-icon-keyboard',
    position: { x: 40, y: 200 },
  },
  Keying: {
    className: 'sprite-icon-keying',
    position: { x: 80, y: 200 },
  },
  LeftArrow: {
    className: 'sprite-icon-left-arrow',
    position: { x: 120, y: 200 },
  },
  LeftArrowButton: {
    className: 'sprite-icon-left-arrow-button',
    position: { x: 160, y: 200 },
  },
  LeftBrace: {
    className: 'sprite-icon-left-brace',
    position: { x: 200, y: 200 },
  },
  LeftBracket: {
    className: 'sprite-icon-left-bracket',
    position: { x: 240, y: 0 },
  },
  LeftGrid: {
    className: 'sprite-icon-left-grid',
    position: { x: 240, y: 40 },
  },
  LeftTurn: {
    className: 'sprite-icon-left-turn',
    position: { x: 240, y: 80 },
  },
  LimitCycle: {
    className: 'sprite-icon-limit-cycle',
    position: { x: 240, y: 120 },
  },

  Line: {
    className: 'sprite-icon-line',
    position: { x: 240, y: 160 },
  },
  List: {
    className: 'sprite-icon-list',
    position: { x: 240, y: 200 },
  },
  ManualInput: {
    className: 'sprite-icon-manual-input',
    position: { x: 0, y: 240 },
  },
  ManualOperation: {
    className: 'sprite-icon-manual-operation',
    position: { x: 40, y: 240 },
  },
  Numeric: {
    className: 'sprite-icon-numeric',
    position: { x: 80, y: 240 },
  },
  Octagon: {
    className: 'sprite-icon-octagon',
    position: { x: 120, y: 240 },
  },
  OffPageConnector: {
    className: 'sprite-icon-off-page-connector',
    position: { x: 160, y: 240 },
  },
  parallelogram: {
    className: 'sprite-icon-parallelogram',
    position: { x: 200, y: 240 },
  },
  pentagon: {
    className: 'sprite-icon-pentagon',
    position: { x: 240, y: 240 },
  },
  PieChart: {
    className: 'sprite-icon-pie-chart',
    position: { x: 280, y: 0 },
  },

  Polygon: {
    className: 'sprite-icon-polygon',
    position: { x: 280, y: 40 },
  },
  Progress: {
    className: 'sprite-icon-progress',
    position: { x: 280, y: 80 },
  },
  PunchedCard: {
    className: 'sprite-icon-punched-card',
    position: { x: 280, y: 120 },
  },
  PunchedTape: {
    className: 'sprite-icon-punched-tape',
    position: { x: 280, y: 160 },
  },
  QRCode: {
    className: 'sprite-icon-QRCode',
    position: { x: 280, y: 200 },
  },
  QueueData: {
    className: 'sprite-icon-queue-data',
    position: { x: 280, y: 240 },
  },
  Radio: {
    className: 'sprite-icon-radio',
    position: { x: 0, y: 280 },
  },
  RadioGroup: {
    className: 'sprite-icon-radio-group',
    position: { x: 40, y: 280 },
  },
  Ready: {
    className: 'sprite-icon-ready',
    position: { x: 80, y: 280 },
  },
  Rect: {
    className: 'sprite-icon-rect',
    position: { x: 120, y: 280 },
  },

  Rectangle: {
    className: 'sprite-icon-rectangle',
    position: { x: 160, y: 280 },
  },
  RightAngle: {
    className: 'sprite-icon-right-angle',
    position: { x: 200, y: 280 },
  },
  RightArrow: {
    className: 'sprite-icon-right-arrow',
    position: { x: 240, y: 280 },
  },
  RightArrowButton: {
    className: 'sprite-icon-right-arrow-button',
    position: { x: 280, y: 280 },
  },
  RightBrace: {
    className: 'sprite-icon-right-brace',
    position: { x: 320, y: 0 },
  },
  RightBracket: {
    className: 'sprite-icon-right-bracket',
    position: { x: 320, y: 40 },
  },
  RightTriangle: {
    className: 'sprite-icon-right-triangle',
    position: { x: 320, y: 80 },
  },
  RightTurn: {
    className: 'sprite-icon-right-turn',
    position: { x: 320, y: 120 },
  },
  RoundedSpeechBubble: {
    className: 'sprite-icon-rounded-speech-bubble',
    position: { x: 320, y: 160 },
  },
  RoundRectangle: {
    className: 'sprite-icon-round-rectangle',
    position: { x: 320, y: 200 },
  },

  Score: {
    className: 'sprite-icon-score',
    position: { x: 320, y: 240 },
  },
  Sector: {
    className: 'sprite-icon-sector',
    position: { x: 320, y: 280 },
  },
  SectorDiagram: {
    className: 'sprite-icon-sector-diagram',
    position: { x: 0, y: 320 },
  },
  SegmentControls: {
    className: 'sprite-icon-segment-controls',
    position: { x: 40, y: 320 },
  },
  Select: {
    className: 'sprite-icon-select',
    position: { x: 80, y: 320 },
  },
  MultipleSelect: {
    className: 'sprite-icon-multiple-select',
    position: { x: 320, y: 400 },
  },
  CarouselChart: {
    className: 'sprite-icon-carousel-chart',
    position: { x: 120, y: 440 },
  },
  Semicircle: {
    className: 'sprite-icon-semicircle',
    position: { x: 120, y: 320 },
  },
  Slider: {
    className: 'sprite-icon-slider',
    position: { x: 160, y: 320 },
  },
  SquareSpeechBubble: {
    className: 'sprite-icon-square-speech-bubble',
    position: { x: 200, y: 320 },
  },
  Star: {
    className: 'sprite-icon-star',
    position: { x: 240, y: 320 },
  },
  Start: {
    className: 'sprite-icon-start',
    position: { x: 280, y: 320 },
  },

  StickNote: {
    className: 'sprite-icon-stick-note',
    position: { x: 320, y: 320 },
  },
  Switch: {
    className: 'sprite-icon-switch',
    position: { x: 360, y: 0 },
  },
  TabBar: {
    className: 'sprite-icon-tab-bar',
    position: { x: 360, y: 40 },
  },
  ImageTextTab: {
    className: 'sprite-icon-image-text-tab',
    position: { x: 80, y: 440 },
  },
  VerticalTab: {
    className: 'sprite-icon-vertical-tab',
    position: { x: 360, y: 440 },
    newBadge: true,
  },
  Table: {
    className: 'sprite-icon-table',
    position: { x: 360, y: 80 },
  },
  Text: {
    className: 'sprite-icon-text',
    position: { x: 360, y: 120 },
  },
  Textarea: {
    className: 'sprite-icon-textarea',
    position: { x: 360, y: 160 },
  },
  TextInput: {
    className: 'sprite-icon-text-input',
    position: { x: 360, y: 200 },
  },
  trapezoid: {
    className: 'sprite-icon-trapezoid',
    position: { x: 360, y: 240 },
  },
  Tree: {
    className: 'sprite-icon-tree',
    position: { x: 360, y: 280 },
  },
  DatePicker: {
    className: 'sprite-icon-date-picker',
    position: { x: 280, y: 360 },
  },
  TimePicker: {
    className: 'sprite-icon-time-picker',
    position: { x: 240, y: 360 },
  },
  Triangle: {
    className: 'sprite-icon-triangle',
    position: { x: 360, y: 320 },
  },

  UpArrow: {
    className: 'sprite-icon-up-arrow',
    position: { x: 0, y: 360 },
  },
  UpGrid: {
    className: 'sprite-icon-up-grid',
    position: { x: 40, y: 360 },
  },
  VerticalDoubleArrow: {
    className: 'sprite-icon-vertical-double-arrow',
    position: { x: 80, y: 360 },
  },
  Video: {
    className: 'sprite-icon-video',
    position: { x: 120, y: 360 },
  },
  Snapshot: {
    className: 'sprite-icon-snapshot',
    position: { x: 160, y: 360 },
  },
  BarChart: {
    className: 'sprite-icon-barChart',
    position: { x: 320, y: 360 },
  },
  Pie: {
    className: 'sprite-icon-pie',
    position: { x: 360, y: 360 },
  },
  DoughnutChart: {
    className: 'sprite-icon-doughnut-chart',
    position: { x: 80, y: 400 },
  },
  LineChart: {
    className: 'sprite-icon-line-chart',
    position: { x: 120, y: 400 },
  },
  BarChartHorizontal: {
    className: 'sprite-icon-bar-chart-horizontal',
    position: { x: 40, y: 400 },
  },
  AreaChart: {
    className: 'sprite-icon-area-chart',
    position: { x: 160, y: 400 },
  },
  RadarChart: {
    className: 'sprite-icon-radar-chart',
    position: { x: 240, y: 400 },
  },
  ScatterChart: {
    className: 'sprite-icon-scatter-chart',
    position: { x: 200, y: 400 },
  },
  Audio: {
    className: 'sprite-icon-audio',
    position: { x: 280, y: 400 },
  },
  NavigationMenu: {
    className: 'sprite-icon-navigation-menu',
    position: { x: 360, y: 400 },
  },
  VerticalMenu: {
    className: 'sprite-icon-vertical-menu',
    position: { x: 0, y: 440 },
  },
  HorizontalMenu: {
    className: 'sprite-icon-horizontal-menu',
    position: { x: 40, y: 440 },
  },
  CombinationChart: {
    className: 'sprite-icon-combination-chart',
    position: { x: 160, y: 440 },
  },
  GaugeChart: {
    className: 'sprite-icon-gauge-chart',
    position: { x: 200, y: 440 },
  },
  MapChart: {
    className: 'sprite-icon-map-chart',
    position: { x: 240, y: 440 },
  },
  Collapse: {
    className: 'sprite-icon-collapse',
    position: { x: 320, y: 440 },
  },
};

export const WaitingImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAApVJREFUWEe9l73OMVEQx0dBpVAKBdFQaX20KldAqUDELVCIcAsiKLSuQKVdNApR0AgJIiqFisKbOW/O5uzu+dp98jzb2bMz5zdz/jNn+L7f7xc8POPxGJrNJgwGA6jVah48/DfxeQWIxWJwu90gEonA+Xx2ABwOBwgGgxCNRqVwUoD5fA6bzQZarZbDid/vN999Ph/L+nK5hOfzSd4Vi0VvANfrFfL5PDHOZrMwm80sjkQAaLfdbsm3gUAACoWCNwC0wjTTZzgcWqIRASwWC3i/38QskUhAMpn0DtDv92E0GhEH4XAY1uu16YwHgOd+PB61o9cSYSaTgfv9TpzW63VTDzwA1Ax90um0UoBaAOi00WiYjg3DII7tAGz0oVAIcrmcJfXsOgtHqgA36XQ6xKBarTrqulQqwWq1cmTBfrhUgKzy8d1+vzd1YdcGAcCmMplMTH/xeBwqlYopOiyrcrkM7XbbVdNhy5E6t2fH7AMY5el0sgSFIN1uV6lknsxZPdCSTKVSDl1YGhEaTadTBwirfmlNMYvsmcvKkdsJ2SNBbai6mQgKITz3ARQPZoPXhnWzoPOd58tIxzl+Y9cCtcM2jZrwGYbx3e128Hg8uD5/mgERABWmr9frSeeB3wL4swyojurXNaCqBCWAyoEsQp1eIATQMVall50NRN3QAcC7PHQmGxGM6j6wAPA+drs5zZzujWgCsCkXpQtrWjZo6MwEl8uFXM3UjwnADpO8y4PNDl1nmwyN2O1UpKwCzAYLh795m9F3budCLQDRpMvLAAK6mYyVALKIRACijPEqRQnARmMXoAgAN9L9d6QEoJvwJl0ZAELo/D9UAmA6X68Xd7Kh2XHbK9ijUALI2i3tmrxhU9Wm6fo/KZ8NVIX2HwYAAAAASUVORK5CYII=';

export const WaitingImageBgTransparency =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOGSURBVHgBzVdLTuNAEK0E81khL1mAxrlBIgFiN84SECKcgHAChhPEnAA4wXhOEBAClsls2ZAb0BIcIGLFn3nPdJsm+JM4I808Kepu96deV1VXVUSKw5uYmLjmj30piLIUhOM4zVKp5PHHvhSEkzW5sLBAIT/RVXd3d7U+IEOg3W6709PTV/Kumb21tbXDtLV5GtjUrTc7O/tDhsTMzMyufJhlM2ttHoETq787Nzfn5ayXi4sL7+3tLTBj9H9JUQI3NzchTNDVQ3dycvJA8tGy+mp9fT2UogSIl5eXfdMHmcb8/Lyftvb09NTHjZtm/PDwUJccxASWlpZ28evg17QX3N7edtHEaiyXy5EvPD8/hxCm+GOf3/AkWxbZcGtrS9lnnZ2dNc/Pz9tszTfH2mA81V9eXm49Pj7We72e0kKD19dXOpOL1hyqoJ2KLQBkfuMcH93+/f19rDlqRpPztawGmjDqm0UQ+iWg8BZQ4z6JeAAJKKV6kgEKg0Z6uH2fDolPLdsshjyeZuUTgWq16k5NTVG9rYHFfWijAhJDxQAD/RoYC1z7LHw7wnmHJMgPsQm0gGBlZSWEmgP0t/WUi0hXRduVEYAzXGjQFn4Mbe4N+kUp7YDFxcXIbrTr5eVlIAUAZzsEiW/wlaONjY2u/I8oBUHArNZMmgTzEPNKxgB9AeZoJs3BF0IH0a0DNXtJCzBHP6jIGMDZHWbMpDkkrO3C6fhvwXl6eqqnmcBEuHGA29ezTCD/GqnPUIdP5nVGrT0pADggn7HPhJb2DEsJmxjBmHYb5hvGNaTVzBA8CH2BTiwIYZ35YTAQxU7IMkozvrKFy3soHikME8wH3GvGzAfw+mvKoKwvBFjD6UrGtVkjfNbImpt4qxy5jH5VrPUY67G/JlYq10QCXS9+JiCfM2EXdquvrq7uUDjNQvZUKQQEKbIFuf4AQq8ogCSwn/7DwpaxRCXJcixmO1i4ifYkoYxihow0gzXf2WpfiWzMykfb1hzsojDlnh0OSARNhYUI9jO4xbVm6isw0IKuzZiaoUdTEzispcnvg3Qw6Hhmbdb5uZHQ3DJiC5/IOlDPHZuxXaIVIqBrN8+M7TIrDSDJmGG837frv5EJaHtFoJoH33ASaG9WPbGAcrkhRQnIh7MollEyJPRaxT7ywHHW2sz/hvo/3dCCDXS9N1QaL5yOodpQ3m+pxslqfwCptNR62kVYJQAAAABJRU5ErkJggg==';
