import * as React from 'react';
import { Link } from 'react-router-dom';
import * as ReactDOM from 'react-dom';
import classnames from 'classnames';

import Icon from '../Icon';
import Menu from '../Menu';
import DialogWithFullScreen from '../DialogWithFullScreen';

import './index.scss';

export interface IMenuOption {
  id?: string | number;
  text: string;
  description?: string;
  linkTo?: string;
  href?: string;
  target?: string;
  disable?: boolean;
  icon?: string;
}

interface IPanelWithDialogProp {
  title?: string;
  backLink?: string;
  actions?: string[];
  disableActions?: string[];
  isDialog?: boolean;
  usePropsMenu?: boolean;
  titleMenu?: Array<IMenuOption>;
  isZh?: boolean;
  dom?: Element;
  backOnTitle?: boolean;

  onEditTitle?(value: string): void;
  onClickAction(index: number): void;
  onClose(index: number): void;
  onSelectMenuItem?(id: string | number, index: number): void;
  onClickArrow?(): void;
  onFormatDesc?(): React.ReactChild;
}

interface IPanelWithDialogState {
  title: string;
  showMenu: boolean;
}

class PanelWithDialog extends React.Component<IPanelWithDialogProp, IPanelWithDialogState> {
  static defaultProps: Partial<IPanelWithDialogProp> = {
    actions: [],
    disableActions: [],
    isZh: true,
    backOnTitle: false,
  };

  titleRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

  constructor(props: IPanelWithDialogProp) {
    super(props);
    this.state = {
      title: props.title || '',
      showMenu: false,
    };
  }

  onToggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  render() {
    const {
      actions,
      disableActions,
      onClickAction,
      children,
      backLink,
      isDialog,
      onClose,
      onSelectMenuItem,
      titleMenu,
      dom,
      usePropsMenu,
      onClickArrow,
      isZh,
      backOnTitle,
      onFormatDesc,
    } = this.props;
    const onClick = (idx: number) => onClickAction(idx);

    if (isDialog) {
      return ReactDOM.createPortal(
        <DialogWithFullScreen showOk={false} showCancel={false} onCancel={onClose}>
          <div
            className={classnames('dsm-c-panel-with-dialog', {
              'dsm-c-panel-with-dialog-en': !isZh,
            })}
          >
            <div className="dsm-c-panel-title">
              {titleMenu ? (
                <div className="edit-title" onClick={this.onToggleMenu}>
                  <div className="title-name"> {this.props.title} </div>
                  <Icon cls="tag_downarrow" />
                  <div className="top-operation">
                    {this.state.showMenu && (
                      <Menu
                        options={titleMenu}
                        onSelect={(id: number | string, index: number) => {
                          this.setState({ showMenu: false });
                          onSelectMenuItem && onSelectMenuItem(id, index);
                        }}
                        onClose={() => this.setState({ showMenu: false })}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className="panel-title-left">
                  <div className={classnames('panel-title-back-container', { backOnTitle })}>
                    {backLink && backOnTitle ? (
                      <Link to={backLink} className="back-link">
                        <Icon cls="tree_leftback" />
                        <h5 className="back-title">{this.props.title}</h5>
                      </Link>
                    ) : backLink ? (
                      <>
                        <Link to={backLink} className="back-link">
                          <Icon cls="tree_leftback" />
                        </Link>
                        <h5 className="back-title">{this.props.title}</h5>
                      </>
                    ) : (
                      <h5>{this.props.title}</h5>
                    )}
                  </div>
                </div>
              )}
              <div className="actions">
                {actions &&
                  actions.map((action, index) => (
                    <span
                      className={classnames('link-b', { disabled: disableActions?.includes(action) })}
                      key={index}
                      onClick={() => {
                        if (disableActions?.includes(action)) {
                          return;
                        }
                        onClick(index);
                      }}
                    >
                      {action}
                    </span>
                  ))}
              </div>
            </div>
            <div className="dsm-c-panel-content">{children}</div>
          </div>
        </DialogWithFullScreen>,
        dom || document.body,
      );
    }
    // 本身的menu，props传入的menu和不使用menu
    return (
      <div className={classnames('dsm-c-panel-with-dialog', { 'dsm-c-panel-with-dialog-en': !isZh })}>
        <div className="dsm-c-panel-title">
          {titleMenu ? (
            <div className="edit-title" onClick={this.onToggleMenu}>
              <div className="title-name">{this.props.title}</div>
              <Icon cls="tag_downarrow" />
              <div className="top-operation">
                {this.state.showMenu && (
                  <Menu
                    options={titleMenu}
                    onSelect={(id: number | string, index: number) => {
                      this.setState({ showMenu: false });
                      onSelectMenuItem && onSelectMenuItem(id, index);
                    }}
                    onClose={() => this.setState({ showMenu: false })}
                  />
                )}
              </div>
            </div>
          ) : usePropsMenu ? (
            <div className="edit-title" onClick={onClickArrow}>
              <div className="title-name use-props-menu-title" id="title-name">
                {this.props.title}
              </div>
              <Icon cls="tag_downarrow" />
            </div>
          ) : (
            <div className="panel-title-left">
              <div className={classnames('panel-title-back-container', { backOnTitle })}>
                {backLink && backOnTitle ? (
                  <Link to={backLink} className="back-link">
                    <Icon cls="tree_leftback" />
                    <h5 className="back-title">{this.props.title}</h5>
                  </Link>
                ) : backLink ? (
                  <>
                    <Link to={backLink} className="back-link">
                      <Icon cls="tree_leftback" />
                    </Link>
                    <h5 className="back-title">{this.props.title}</h5>
                  </>
                ) : (
                  <h5>{this.props.title}</h5>
                )}
              </div>
              {onFormatDesc && onFormatDesc()}
            </div>
          )}

          <div className="actions">
            {actions &&
              actions.map((action, index) => (
                <span
                  className={classnames('link-b', { disabled: disableActions?.includes(action) })}
                  key={index}
                  onClick={() => {
                    if (disableActions?.includes(action)) {
                      return;
                    }
                    onClick(index);
                  }}
                >
                  {action}
                </span>
              ))}
          </div>
        </div>
        <div className="dsm-c-panel-content">{children}</div>
      </div>
    );
  }
}

export default PanelWithDialog;
