import * as React from 'react';

import { ITag } from '../../constants';
import i18n from '../../i18n';

import Panel from '../Panel';
import Dialog from '../Dialog';
import DialogWithInput from '../DialogWithInput';
import Tag from './Tag';

import './index.scss';

export interface ITagsProp {
  title?: string;
  actionName?: string;
  backLink?: string;
  dialogTitle?: string;
  editTitle?: string;
  noTagsTip?: string;
  tags?: Array<ITag>;

  onCreateNewTag(name: string): void;
  onRenameTag(id: string | number, name: string): void;
  onDeleteTag(id: number): void;
}

class TagsManager extends React.Component<
  ITagsProp,
  { toDelete: any; createNewTag: boolean; newTag: string; error: string }
> {
  static defaultProps: Partial<ITagsProp> = {
    title: i18n('TagsManager.title'),
    tags: [],
    actionName: i18n('TagsManager.actionName'),
  };

  newTagInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: ITagsProp) {
    super(props);
    this.state = {
      toDelete: null,
      createNewTag: false,
      newTag: '',
      error: '',
    };
    this.onOk = this.onOk.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onStartCreateNewTag = this.onStartCreateNewTag.bind(this);
    this.onCancelCreateNewTag = this.onCancelCreateNewTag.bind(this);
    this.onNewTagNameChange = this.onNewTagNameChange.bind(this);
    this.onCreateNewTag = this.onCreateNewTag.bind(this);
    this.newTagInputRef = React.createRef();
  }

  onRenameTag(id: string | number, newName: string) {
    const { onRenameTag } = this.props;
    onRenameTag(id, newName);
  }

  onDeleteTag(id: string | number) {
    this.setState({
      toDelete: id,
    });
  }

  onOk() {
    const { toDelete } = this.state;
    const { onDeleteTag } = this.props;
    this.setState(
      {
        toDelete: null,
      },
      () => {
        onDeleteTag(toDelete);
      },
    );
  }

  onCancel() {
    this.setState({
      toDelete: null,
    });
  }

  onStartCreateNewTag() {
    this.setState({
      createNewTag: true,
    });
  }

  onCancelCreateNewTag() {
    this.setState({
      createNewTag: false,
      newTag: '',
      error: '',
    });
  }

  onNewTagNameChange(value: string) {
    if (value.length > parseInt(i18n('TagsManager.maxLength'), 10)) {
      this.setState({
        newTag: value,
        error: i18n('TagsManager.error'),
      });
    } else {
      this.setState({
        error: '',
        newTag: value,
      });
    }
  }

  onCreateNewTag() {
    const { onCreateNewTag } = this.props;
    const { error, newTag } = this.state;
    if (!error) {
      onCreateNewTag(newTag);
      this.setState({
        createNewTag: false,
        newTag: '',
        error: '',
      });
    }
  }

  render() {
    const { title, backLink, actionName, tags, dialogTitle, editTitle, noTagsTip } = this.props;
    const { toDelete, createNewTag, newTag, error } = this.state;
    return (
      <div className="tags-manager-wrapper">
        <Panel
          title={title}
          backLink={backLink}
          actions={actionName ? [actionName] : []}
          onClickAction={this.onStartCreateNewTag}
        >
          {tags && tags.length === 0 && <span className="no-tags-tip">{noTagsTip}</span>}
          {tags &&
            tags.map(({ id, text }) => (
              <Tag
                key={id}
                editTitle={editTitle || ''}
                value={text}
                onRename={(value: string) => {
                  this.onRenameTag(id, value);
                }}
                onDelete={() => {
                  this.onDeleteTag(id);
                }}
              />
            ))}
          {toDelete !== null && (
            <Dialog
              showCancel
              sureDelete
              lightTitle
              onOk={this.onOk}
              onCancel={this.onCancel}
              title={i18n('TagsManager.dialog')}
            />
          )}
          {createNewTag && (
            <DialogWithInput
              showClose
              title={dialogTitle}
              placeholder={i18n('TagsManager.placeholder', [actionName])}
              onOk={this.onCreateNewTag}
              onChange={this.onNewTagNameChange}
              onCancel={this.onCancelCreateNewTag}
              value={newTag}
              errorMsg={error}
            />
          )}
        </Panel>
      </div>
    );
  }
}

export default TagsManager;
