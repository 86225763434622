/**
 * 
 CSS属性组：
  动画、背景、网格、边框轮廓、超链接、分页、框、线框、Ruby、颜色、字幕、页面媒体、定位等等
动画属性

@keyframes:定义一个动画，@keyframes定义的动画名称用来被animation-name所使用
animation:复合属性。检索或设置对象所应用的动画特效
animation-name:检索或设置对象所应用的动画名称，必须与规则@keyframes配合使用，因为动画名有@kayframes定义
animation-duration:检索或设置动画的延续时间
animation-timing-function:检索或设置动画的过度类型
animation-delay:检索或设置动画的延时时间
animation-iteration-count:检索或设置动画的循环次数
animation-direction:检索或设置动画是否反向播放
animation-paly-state:检索或设置对象动画的状态
背景属性

background:复合属性。设置对象的背景特征
background-attachment:设置或检索背景图像是随对象内容滚动还是固定的。必须先指定background-image属性
    body
    { 
      background-image:url('smiley.gif');
      background-repeat:no-repeat;
      background-attachment:fixed;
    }
1
2
3
4
5
6
设置图片浮动于固定位置

backgroung-image:设置或检索对象的背景图像
background-position:设置或检索对象的背景图像位置，必须先指定backgroung-image属性
backgroung-repeat:设置或检索对象的背景图像如何铺排填充。必须先指定background-image属性
background-clip:指定对象的背景图像向外剪裁的区域
该对象指定背影颜色内容的大小默认为包括margin在内的全屏背景
    div
    {
      background-color:yellow;
      background-clip:content-box;或padding-box;
    }
1
2
3
4
5
background-origin:设置或检索对象的背景图像计算background-position时的参考原点
background-size:检索或设置对象的背景图像的尺寸的大小
边框或轮廓属性

border:复合属性。设置对象边框的特征
border-bottom:复合属性。设置对象底部边框的特征
boeder-bottom-color:设置或检索对象底部边框的颜色
border-bottom-style:设置或检索对象底部边框的样式
border-bottom-width:设置或检索对象底部边框的宽度
border-color:设置或检索对象边框的颜色
border-left:复合属性。设置对象左边边框特性
border-left-color:设置或检索对象的左边边框颜色
border-left-style:设置或检索对象左边边框的样式
border-left-width:设置或检索对象左边边框的宽度
border-right:复合属性。设置对象右边边框特性
border-right-color:设置或检索对象的右边边框颜色
border-right-style:设置或检索对象右边边框的样式
border-right-width:设置或检索对象右边边框的宽度
border-style:设置或检索对象边框属性
border-top:复合属性。设置对象上边边框特性
border-top-color:设置或检索对象的上边边框颜色
border-top-style:设置或检索对象上边边框的样式
border-top-width:设置或检索对象上边边框的宽度
border-width:设置或检索对象边框的宽度
outline:复合属性。设置或检索对象外的线条轮廓
outline-color:设置或检索对象外的轮廓的颜色
outline-style:设置或检索对象外的线条轮廓的样式
outline-width:设置或检索对象外线条轮廓的宽度
border-image:设置或检索对象边框样式使用的图像来填充
border-image-outset:规定边框图像超过边框的量
border-image-slice:规定图像边框的向内偏移。
border-image-repeat:规定图像边框是否应该被重复（repeated）、拉伸（stretched）或铺满（rounded)
border-image-width:规定图像边框宽度
border-radius:设置或检索对象使用的圆角边框
box-decration-break:规定行内元素被折行
box-shadow:向边框添加一个或多个阴影、
盒子属性

overflow-x:如果内容溢出了元素内容区域，是否对内容的左右边缘进行裁剪
overflow-y:如果内容溢出了元素内容区域，是否对内容的上下边缘进行裁剪
overflow-style:规定溢出元素的首选滚动方法
rotation:围绕由ratation-point属性定义的点对元素进行旋转（目前无浏览器支持）
rotation-point:规定距离上左边框的偏移点
颜色属性

color-profile:允许使用源的颜色配置文件的默认以外的规范
opacity:设置一个元素的透明度级别
rendering-intent:允许超过默认颜色配置文件渲染意向的其他规范
内边距属性

padding:在一个声明中设置所有填充属性
padding-bottom:设置元素的底填充
padding-left:设置元素的左填充
padding-right:设置颜色的右填充
padding-top:设置颜色的上填充
媒体页面内容属性

bookmark-label:指定书签的标签
bookmark-level:指定了书签的级别
bookmark-target:指定了书签链接的目标
float-offset:在相反的方向推动浮动的元素，他们一直具有浮动
hyphenate-before:指定一个断字的单词断字前的最少字符
hyphenate-after:指定一个断字的单词断字后的最少字符
hyphenate-character:指定了一个断字发生时，要显示的字符串
hyphenate-lines:表示连续断字的行在元素的最大数目
hyphenate-resource:外部资源指定一个逗号分隔的列表，可以帮助确定浏览器的断字点
hyphens:设置如何分割单词以改善该段的布局
image-resolution:指定了正确的图像分辨率
marks:将crop and/or cross标志添加到文档
string-set
尺寸属性

height:设置元素的高度
max-height:设置元素的最大高度
max-width:设置元素的最大宽度
min-height:设置元素的最小高度
min-width:设置元素的最小宽度
width:设置元素的宽度
弹性盒子模型(Flexible box)属性

flex:符合属性。设置或检索弹性盒子对象的子元素如何分配空间
让所有弹性盒模型对象的子元素都有相同的长度，且忽略它们内部的内容：
    div{
      flex:1;
    }
1
2
3
flex-grow:设置或检索弹性盒的扩充比率

flex-shrink:设置或检索弹性盒的收缩比率

flex-basis:设置或检索弹性盒伸缩基准值

flex-flow:复合属性。设置或检索弹性盒模型对象的子元排列方式


flex-direction:该属性通过定义flex容器的主轴方向来决定flex子项在flex容器中的位置

flex-wrap:该属性控制flex容器是单行或者多行，同时横轴的方向决定了新行堆叠的方向

align-content:在弹性容器内的各项没有占用交叉轴上所有可用的空间时对齐容器内的各项（垂直）

align-items:定义flex子项在flex容器的当前行的侧轴方向上的对齐方式（主要）

align-self:定义flex子项单独在侧轴方向上的对齐方式

justify-content:设置或检索弹性盒子元素在主轴方向上的对齐方式（主要）

order:设置或检索弹性盒模型对象的子元素出现的顺序

弹性盒子模型属性（旧）

box-align:指定何如对齐一个框的子元素
box-direction:指定在哪个方向，显示一个框的子元素
boe-flex:指定一个框的子元素是否是灵活的或固定的大小
box-flex-group:指派灵活的元素到flex组
box-lines:每当它在父框的空间运行时，是否指定将再上一个新的行列
box-ordinal-group:指定一个框的子元素的显示顺序
box-orient:指定一个框的子元素是否在水平或垂直方向应铺设
box-pack:指定横向盒在垂直框的水平位置和垂直位置
字体属性

font:在一个声明中设置所有字体属性
font-family:规定文本的字体系列
font-style:规定文本的字体样式（如正常或者倾斜）
font-variant:规定文本的字体样式
font-weight:规定字体的粗细
@font-face:一个规则，允许网站下载并使用其他web-safe字体的字体
font-size-adjust:为元素规定aspect值
font-stretch:伸缩或拉伸当前的字体系列
内容生成属性

content:与::before以及::after伪元素配合使用，来插入生成的内容
content-increment:递增或递减一个或多个计数器
counter-reset:创建或重置一个或多个计数器
quotes:设置嵌套引用的引号类型
crop:允许replaced元素只是作为一个对象代替整个对象的矩形区域
move-to:从流中删除元素，然后在 文档后面的点上重新插入
page-policy:判定基于页面给定的元素的适用于计算器的字符串值
网格属性

grid-columns:指定在网格中每个列的宽度
grid-rows:指定在网格中每列的高度
超链接属性

target:简写属性设置target-name,target-new,和target-position属性
target-name:指定在何处打开链接
target-new:指定是否有新的目标链接打开在一个新窗口或在现有窗口打开标签
target-position:指定应该放置新的目标链接
线框属性
alignment-adjust 允许更精确的元素的对齐方式
alignment-baseline 其父级指定的内联级别的元素如何对齐
baseline-shift 允许重新定位相对于dominant-baseline的dominant-baseline
dominant-baseline 指定scaled-baseline-table
drop-initial-after-adjust 设置下拉的主要连接点的初始对齐点
drop-initial-after-align 校准行内的初始行的设置就是具有首字母的框使用初级连接点
drop-initial-before-adjust 设置下拉的辅助连接点的初始对齐点
drop-initial-before-align 校准行内的初始行的设置就是具有首字母的框使用辅助连接点
drop-initial-size 控制局部的首字母下沉
drop-initial-value 激活一个下拉式的初步效果
inline-box-align 设置一个多行的内联块内的行具有前一个和后一个内联元素的对齐
line-stacking 一个速记属性设置line-stacking-strategy, line-stacking-ruby,和line-stacking-shift属性
line-stacking-ruby 设置包含Ruby注释元素的行对于块元素的堆叠方法
line-stacking-shift 设置base-shift行中块元素包含元素的堆叠方法
line-stacking-strategy 设置内部包含块元素的堆叠线框的堆叠方法
text-height 行内框的文本内容区域设置block-progression维数

列表属性

list-style:在一个声明中设置所有的类表属性
list-style-image:将图像设置为列表项标记
list-style-position:设置列表项标记的放置位置
list-style-type:设置列表项标记的类型
外边距属性

margin:在一个声明中设置所有外边距属性
margin-bottom:设置元素下外边距
margin-left:设置属性左外边距
margin-top:设置属性上外边距
margin-right:设置属性右外边距
字幕属性

marquee-direction:设置内容运动的方向
marquee-play-count:设置内容移动的次数
marquee-sqeed:设置内容滚动的速度有多快
marquee-style:设置内容的样式
多列属性

column-count:指定元素应该被分的列数
column-fill:指定如何填充列
column-gap:指定列之间的距离
column-rule:对于所有column-rule-*属性的简写属性
column-rule-color:指定列之间的颜色规则
column-rule-style:指定列之间的样式规则
column-rule-width:指定列之间的宽度规则
column-span:指定元素应该跨越多少列
column-width:指定列的宽度（注意保暖和column-count一起使用）
columns:缩写属性设置列宽和列数
页面媒体属性

fit:如果其宽度和高度都不是auto给出的提示，如何大规模替换元素
fit-position:判定方框内对象的对齐方式
image-orientation:指定用户代理适用于图像中的向右或顺时针方向旋转
page:指定一个元素应显示的页面的特定类型
size:指定包含BOX的页面的内容的大小和方位
定位属性

bottom:设置定位元素下外边距边界与其包含块下边界的偏移
clear:规定元素的哪一侧不允许其他浮动元素
clip:剪裁绝对单位元素
cursor:规定要显示的光标的类型
display:规定元素应该生成的框的类型
float:规定框是否应该浮动
left:设置定位元素左外边距边界与包含块左边界之间的距离
overflow:规定当内容溢出时框发生的事情
position:规定元素的单位类型
right:设置定位元素右外边距边界与包含右边界之间的偏移
top:设置定位元素上外边距边界与包含上边界之间的偏移
visibility:规定元素是否可见
z-index:设置元素的堆叠顺序
分页属性

orphans:设置当元素内部发生分页时必须要在页面底部保留的最少行数
page-break-after:设置元素后的分页行为
page-break-before:设置元素前的分页行为
page-break-inside:设置元素内部的分页行为
widows:设置当元素内部放生分页时必须在顶部保留的最少行数
Ruby属性
ruby-align 控制Ruby文本和Ruby基础内容相对彼此的文本对齐方式
ruby-overhang 当Ruby文本超过Ruby的基础宽，确定ruby文本是否允许局部悬置任意相邻的文本，除了自己的基础
ruby-position 它的base控制Ruby文本的位置
ruby-span 控制annotation 元素的跨越行为

语音属性

mark:缩写属性设置mark-before和mark-after属性
mark-after:允许命名的标记连接到音频流
mark-before:允许命名的标记连接到音频流
phonemes:指定包含文本的相应元素中的一个音标发音
rest:一个缩写属性设置rest-before和rest-after
rest-after:一个元素的内容讲完之后，指定要休息一下或遵守韵律边界
rest-before:一个元素的内容讲完之后，指定要休息一下或遵守韵律边界
voice-balance:指定了左，右声道之间的平衡
voice-duration:指定应采取所选的内容的长度
voice-pitch:指定平均说话的声音的音调（频率）
voice-pitch-range:指定平均间距的变化
voice-rate:控制语速
voice-stress:指示着重力度
voice-volumn:语音合成是指波形输出幅度
表格属性

border-collapse:规定是否合并表格边距
border-spacing:规定相邻单元边框之间的距离
caption-side:规定表格标题的位置
empty-cells:规定是否显示表格的空单元格上的边框和背景
table-layout:设置用于表格的布局算法
文本属性

color:设置文本的颜色
direction:规定文本的方向/书写方向
letter-spacing:设置字符间距
line-height:设置行高
text-align:规定文本的水平对齐方式
text-decoration:规定添加的文本的修饰效果
text-indent:规定文本首行的缩进
text-transform:控制文本的大小写
unicode-bidi:
vertical-align:设置元素的垂直对齐方式
white-space:设置怎样给一个元素空间留白
word-spacing:设置单词间距
text-emphasis:向元素的文本应用重点标记以及重点标记的前颜色
hanging-punctuation:指定一个标点是否可能超出行框
punctuation-trim:指定一个标点符号是否要去掉
text-align-last:当 text-align 设置为 justify 时，最后一行的对齐方式。
text-justify:当 text-align 设置为 justify 时指定分散对齐的方式。
text-outline:设置文字的轮廓
text-overflow:指定当文本溢出包含的元素，应该发生什么
text-shadow:为文本添加阴影
text-wrap:指定文本换行规则
word-break:指定非CJK文字的断行规则
word-wrap:设置为浏览器是否对过长的单词进行换行
2D/3D转换属性

transform:适用于2D或3D转换的元素
transform-origin:允许你更换元素的位置
transform-style:3D空间中的指定如何嵌套元素
perspective:指定3D元素是如何查看透视图
perspective-origin:指定3D元素底部位置
backgace-visibolity:定义一个元素是否应该是可见的，不对着屏幕时
过度属性

transition:此属性是一下属性的简写形式
transition-property:设置用来进行过度的CSS属性
transition-duration:设置过度进行的时间长度
transition-timing-function:设置过度进行的时序函数
transition-delay:设置过度开始的时间
用户外观属性

appearance:定义元素的外观样式
box-sizing:允许您为了适应区域以某种方式定义某些元素
icon：为元素指定图标
nav-down:指定用户按下键时向下导航的位置
nav-index:指定导航（tab）顺序
nav-left:指定用户按下左键向左导航的位置
nav-right:指定用户按下右键向右导航的位置
nav-up:指定用户按下上键向上导航的位置
outline-offset:设置轮廓框架在border边缘外的位移
resize:定义元素是否可以改变大小
注：以上学习来源于菜鸟驿站学习平台，更多查看菜鸟官网。
 */

import { FIELDS_SHAPE_INFO, FIELDS_TEXT_INFO } from '../domToImage/fields';
import { ICopyStyleRuleOptions, PropValue } from '../type';

export const defaultStyleValue: {
  [k: string]: string | number;
} = {
  zoom: '1',
  filter: 'none',
  float: 'none',
  clear: 'none',
  opacity: '1',
  translate: 'none',
  isolation: 'auto',
  order: '0',
  content: 'normal',
  display: 'block',
  visibility: 'visible',

  'z-index': 'auto',
  'row-gap': 'normal',
  'dominant-baseline': 'auto',
  'table-layout': 'auto',

  // 文本样式默认
  'font-style': 'normal',
  'font-variant-caps': 'normal',
  'font-variant-ligatures': 'normal',
  'font-variant-numeric': 'normal',
  'font-variant-east-asian': 'normal',
  'font-stretch': 'normal',
  'font-kerning': 'auto',
  'font-optical-sizing': 'auto',
  'font-palette': 'normal',
  'letter-spacing': 'normal',
  'white-space': 'normal',
  'word-break': 'normal',

  'max-block-size': 'none',
  'max-height': 'none',
  'max-inline-size': 'none',
  'max-width': 'none',

  //布局样式默认
  'align-content': 'normal',
  'justify-content': 'normal',
  'align-items': 'normal',
  'justify-items': 'normal',
  'align-self': 'auto',
  'justify-self': 'auto',
  'alignment-baseline': 'auto',
  'background-blend-mode': 'normal',
  'vertical-align': 'baseline',
  // flex布局
  'flex-direction': 'row',
  'flex-wrap': 'nowrap',
  'flex-grow': '0',
  'flex-shrink': '1',
  'grid-auto-flow': 'row',

  // 定位
  inset: 'auto',
  top: 'auto',
  right: 'auto',
  bottom: 'auto',
  left: 'auto',
  'inset-block-start': 'auto',
  'inset-block-end': 'auto',
  'inset-inline-start': 'auto',
  'inset-inline-end': 'auto',

  'transform-style': 'flat',

  // grid布局
  'grid-row-start': 'auto',
  'grid-column-start': 'auto',
  'grid-row-end': 'auto',
  'grid-column-end': 'auto',

  // 特效
  'box-shadow': 'none',
  'mask-type': 'luminance',
  // 动画
  transition: 'unset',
  // 鼠标事件
  'pointer-events': 'auto',
  //   盒子模型
  '-webkit-box-align': 'stretch',
  '-webkit-box-decoration-break': 'slice',
  '-webkit-box-flex': '0',
  '-webkit-box-ordinal-group': '1',
  '-webkit-box-pack': 'start',
  // 边框
  'border-collapse': 'separate',
};

/**
 * 属性继承策略
 * 组件库中的svg图形，不需要样式的标签
 * @param name
 * @returns
 */
export const copyStyleItemRuler = (options: ICopyStyleRuleOptions): boolean => {
  const { name, isDiectTextNode, node, value, source } = options;
  // const flag=true
  // if(flag) return true

  // 用户行为 user-select
  if (/^(user-|-webkit-user-)/.test(name)) {
    return false;
  }
  // 动画 过度属性
  if (/^(animation|transition)/.test(name)) {
    return false;
  }

  // 文本样式
  // 无文字，不需要字体相关样式 |word- |line-|
  if (!isDiectTextNode) {
    if (/^(text-|font-|-webkit-text-|-webkit-font-|min-|max-|letter-)/.test(name)) {
      return false;
    }
    // direction
    if (/^direction/.test(name)) {
      return false;
    }
    // 文本颜色
    if (/^color$/.test(name)) {
      return false;
    }
    // 换行 line-break -webkit-line-break
    if (/line-break/.test(name)) {
      return false;
    }
    // 文本换行
    if (/white-space/.test(name)) {
      return false;
    }

    // 非直接文本节点， 复制位置信息 flex布局信息
    // if (node && hasTextContent(node)) {
    //   if (/^(position|top|bottom|left|right|z-index|display|justify-content|-webkit-box-pack)$/.test(name)) {
    //     return true;
    //   }
    //   return false;
    // }
  }

  // 字幕属性
  if (/^marquee-/.test(name)) {
    return false;
  }

  // 分页 page-
  if (/^page-/.test(name)) {
    return false;
  }

  // 表格 empty-cells
  if (/^empty-/.test(name)) {
    return false;
  }

  // 列表
  if (/^list-/.test(name)) {
    return false;
  }

  // 滚动
  if (/^scroll-/.test(name)) {
    return false;
  }
  // overscroll-
  if (/^overscroll-/.test(name)) {
    return false;
  }
  // scrollbar-gutter
  if (/^scrollbar-/.test(name)) {
    return false;
  }

  // 语音 voice-
  if (/^(voice-|mark-|rest-)/.test(name)) {
    return false;
  }

  // 多列 column-
  if (/^column-/.test(name)) {
    return false;
  }

  // touch-action
  if (/^touch-/.test(name)) {
    return false;
  }
  //
  // ruby-
  if (/^ruby-/.test(name)) {
    return false;
  }
  //     math-
  if (/^math-/.test(name)) {
    return false;
  }
  // object-
  if (/^object-/.test(name)) {
    return false;
  }
  // marker-
  if (/^marker-/.test(name)) {
    return false;
  }
  // shape-
  if (/^shape-/.test(name)) {
    return false;
  }
  // object-
  if (/^object-/.test(name)) {
    return false;
  }
  // accent-color
  if (/^accent-/.test(name)) {
    return false;
  }
  // perspective
  if (/^perspective/.test(name)) {
    return false;
  }
  // cursor widows speak
  if (/^(speak|cursor|widows|orphans|hyphens|appearance)$/.test(name)) {
    return false;
  }

  if (
    /^(buffered-rendering|backface-visibility|backdrop-filter|app-region|will-change|vector-effect|pointer-events|paint-order|unicode-bidi)$/.test(
      name,
    )
  ) {
    return false;
  }
  // view-transition-name
  if (/^view-/.test(name)) {
    return false;
  }
  // mix-blend-mode
  if (/^mix-/.test(name)) {
    return false;
  }
  // flood-
  if (/^flood-/.test(name)) {
    return false;
  }
  // contain-
  if (/^contain-/.test(name)) {
    return false;
  }
  // clip  clip-path
  if (/^clip/.test(name)) {
    return false;
  }
  // caret-color
  if (/^caret-/.test(name)) {
    return false;
  }
  // caption-side
  if (/^caption-/.test(name)) {
    return false;
  }
  // place-content
  if (/^place-/.test(name)) {
    return false;
  }
  // break-after break-before
  if (/^break-/.test(name)) {
    return false;
  }
  // hyphenate-character
  if (/^hyphenate-/.test(name)) {
    return false;
  }
  // lighting-color
  if (/^lighting-/.test(name)) {
    return false;
  }
  if (/^color-/.test(name)) {
    return false;
  }
  if (/^image-/.test(name)) {
    return false;
  }
  // overflow-
  if (/^overflow-/.test(name)) {
    return false;
  }
  // if (!isSvgTag(node.tagName)) {
  if (/^(x|y|d|r|rx|ry|cx|cy|scale|rotate)$/.test(name)) {
    return false;
  }
  // 描边 stroke-  svg样式
  if (/^stroke/.test(name)) {
    return false;
  }

  // 填充 svg样式
  if (/^fill/.test(name)) {
    return false;
  }
  // 渐变色
  if (/^stop-/.test(name)) {
    return false;
  }

  // -webkit-locale
  if (/^-webkit-locale/.test(name)) {
    return false;
  }
  // -webkit-mask
  if (/^-webkit-mask/.test(name)) {
    return false;
  }
  // 打印
  // -webkit-print-
  if (/^-webkit-print/.test(name)) {
    return false;
  }
  // -webkit-writing-mode writing-mode
  if (/writing-mode/.test(name)) {
    return false;
  }
  // -webkit-rtl-ordering
  if (/^-webkit-rtl/.test(name)) {
    return false;
  }
  // -webkit-highlight  -webkit-tap-highlight-color
  if (/-webkit-highlight|-webkit-tap-highlight-color/.test(name)) {
    return false;
  }

  // 容器设置resize:none
  // 非容器
  const tagName = node?.tagName ?? '';
  if (!isContainer(tagName)) {
    if (/^container/.test(name)) {
      return false;
    }
    if (/tab-size/.test(name)) {
      return false;
    }
    // textarea中不设置none右下角会显示拖动图标
    if (/resize/.test(name)) {
      return false;
    }
    // 背景  background-color:
    // background-image:none 对input偏移有影响
    // if (/^background-/.test(name) && name !== 'background-color') {
    //   const keyPropValue = source.getPropertyValue('background-image');
    //   if (keyPropValue === 'none') {
    //     return false;
    //   }
    // }
  }

  // 以下为关联字段
  if (!checkRelatedFields(source, name)) {
    return false;
  }

  // 以下为默认值：
  // 默认值不要设置
  if (!checkDefalutValue(name, value)) {
    return false;
  }
  return true;
};

// 'svg', 'defs', 'use', 'clipPath', 'g', 'rect', 'path', 'text'
export const isSvgTag = (tagName: string): boolean => {
  return /^(svg|g|path|rect|circle|defs|use|text|clipPath)$/i.test(tagName);
};

// tab-size container
export const isContainer = (tagName: string): boolean => {
  return /^(textarea|pre|input)$/i.test(tagName);
};

export const hasTextContent = (node: Element): boolean => {
  return Boolean(node.textContent);
};

/**
 * 存在直接的文本子节点
 */
export const hasDirctTextChild = (node: Element): boolean => {
  return Array.from(node.childNodes).some((t) => t.nodeType === 3 && t.textContent);
};

/**
 * 非直接文本节点， 复制位置信息 flex布局信息
位置信息
 * @param source 
 * @param target 
 */
export const copyShapeInfo = (source: CSSStyleDeclaration, target: CSSStyleDeclaration): void => {
  handleFields(FIELDS_SHAPE_INFO, source, target);
};

/**
 * 文本信息
 */
export const copyTextInfo = (source: CSSStyleDeclaration, target: CSSStyleDeclaration): void => {
  handleFields(FIELDS_TEXT_INFO, source, target);

  // 垂直居中
  handleTextAlignItemsCenter(source, target);
};

const handleFields = (fields: string[], source: CSSStyleDeclaration, target: CSSStyleDeclaration) => {
  fields.forEach((name) => {
    if (!checkRelatedFields(source, name)) {
      return false;
    }

    if (name) {
      const value = source.getPropertyValue(name); //source.getPropertyPriority(name) ||

      if (!checkDefalutValue(name, value)) {
        return false;
      }

      target.setProperty(name, value);
    }
  });

  handleOverflow(source, target);
};

// 以下为关联字段
const checkRelatedFields = (source: CSSStyleDeclaration, name: string): boolean => {
  if (!checkBackground(source, name)) {
    return false;
  }

  //  边框图片 border-image -webkit-border-image
  if (!checkBorderImage(source, name)) {
    return false;
  }

  // border-style
  if (!checkBorderStyle(source, name)) {
    return false;
  }

  // offset
  if (!checkOffsetPath(source, name)) {
    return false;
  }

  // transform: 'none',
  if (!checkTransform(source, name)) {
    return false;
  }

  // 	默认值。没有定位，元素出现在正常的流中（忽略 top, bottom, left, right 或者 z-index 声明）。
  if (!checkPosition(source, name)) {
    return false;
  }

  return true;
};

// 文本行高定义，在scale缩放条件下，需要定义，否则显示有问题
// 或者采用 flex-box居中
// const handleLineHeight = (source: CSSStyleDeclaration, target: CSSStyleDeclaration): void => {
//   const name = 'line-height';
//   const value = source.getPropertyValue(name);
//   if (value === PropValue.normal) {
//     target.setProperty(name, '1');
//   }
// };

/**
 * 文本垂直居中
 * @param source
 * @param target
 */
const handleTextAlignItemsCenter = (source: CSSStyleDeclaration, target: CSSStyleDeclaration): void => {
  const position = source.getPropertyValue('position');
  const margin = source.getPropertyValue('margin');
  const padding = source.getPropertyValue('padding');
  if (position === 'static' && margin === '0px' && padding === '0px') {
    target.setProperty('display', 'flex');
    target.setProperty('align-items', 'center');
  }
};

const handleOverflow = (source: CSSStyleDeclaration, target: CSSStyleDeclaration): void => {
  // 当  overflow =scroll   设置size （width或），会出现滚动条
  // 策略:去掉滚动条，设置overflow =hidden
  if (source.getPropertyValue('overflow-x') === PropValue.scroll) {
    target.setProperty('overflow-x', PropValue.hidden);
  }
  if (source.getPropertyValue('overflow-y') === PropValue.scroll) {
    target.setProperty('overflow-y', PropValue.hidden);
  }
};

// transform: 'none',
const checkTransform = (source: CSSStyleDeclaration, name: string): boolean => {
  if (/^transform/.test(name)) {
    const keyPropValue = source.getPropertyValue('transform');
    if (
      keyPropValue === PropValue.none ||
      keyPropValue === 'matrix(1, 0, 0, 1, 0, 0)' ||
      keyPropValue === 'scale(1, 1)'
    ) {
      return false;
    }
  }
  return true;
};

// 	默认值。没有定位，元素出现在正常的流中（忽略 top, bottom, left, right 或者 z-index 声明）。
const checkPosition = (source: CSSStyleDeclaration, name: string): boolean => {
  const position = source.getPropertyValue('position');
  if (position === 'static') {
    if (/^(position|top|bottom|left|right|z-index)$/.test(name)) {
      return false;
    }
  }
  return true;
};

/**
 * // border-style
 * @param source
 * @param name
 * @returns
 */
const checkBorderStyle = (source: CSSStyleDeclaration, name: string): boolean => {
  if (/^border-.*?(width|color)$/.test(name)) {
    const keyPropValue = source.getPropertyValue('border-style');
    if (keyPropValue === PropValue.none) {
      return false;
    }
  }
  return true;
};

/**
 * 边框图片 border-image -webkit-border-image
 * @param source
 * @param name
 * @returns
 */
const checkBorderImage = (source: CSSStyleDeclaration, name: string): boolean => {
  if (/border-image/.test(name)) {
    const keyPropValue = source.getPropertyValue('border-image-source');
    if (keyPropValue === PropValue.none) {
      return false;
    }
  }
  return true;
};

const checkOffsetPath = (source: CSSStyleDeclaration, name: string): boolean => {
  if (/^offset-/.test(name)) {
    const keyPropValue = source.getPropertyValue('offset-path');
    if (keyPropValue === PropValue.none) {
      return false;
    }
  }
  return true;
};

const checkBackground = (source: CSSStyleDeclaration, name: string): boolean => {
  // background-image:none 对input偏移有影响，不能去掉
  if (/^background-/.test(name) && name !== 'background-color') {
    const keyPropValue = source.getPropertyValue('background-image');
    if (keyPropValue === 'none') {
      return false;
    }
  }
  return true;
};

/**
 * 默认值检查
 * @param name
 * @param value
 * @returns
 */
const checkDefalutValue = (name: string, value: string | undefined): boolean => {
  // 默认值不要设置
  if (defaultStyleValue[name] === value) {
    return false;
  }
  // 值是 auto normal的
  if (PropValue.auto === value || PropValue.normal === value) {
    return false;
  }
  return true;
};
