import * as React from 'react';

import { ITreeData, ITreeDataItem } from '@fbs/rp/models/value';
import { IComponentData } from '@/fbs/rp/models/component';
import {
  ITreeItem,
  mapRelationWithComp,
  createAvlTreeWithTreeComp,
  getParentIDsByCompTree,
} from '@helpers/treeCompHelper';
import { UIHorizontalMenuComponent, UITreeItemComponent } from '@editor/comps';

import { IComponentProps } from '../../types';
import CanvasPanel from '../../containers/Canvas';
import HorizontalMenuItem from './HorizontalMenuItem';

import './index.scss';

interface IHorizontalMenuState {
  isHorizontalItemHover?: boolean;
  horizontalMenuHoverItemID?: string;
}

export default class HorizontalMenu extends React.Component<IComponentProps, IHorizontalMenuState> {
  protected horizontalContentRef: React.RefObject<HTMLDivElement> = React.createRef();
  protected initTree: ITreeItem[] = [];
  private selectedIDs: string[] = [];
  protected maxChildLength: number = 0;
  private timer: number | undefined = undefined;
  // 此对象为最初始的值，window.pageScale变更时注意换算
  private floatPanelRects: Record<string, number> = {};
  private defaultScale = window.pageScale;
  constructor(props: IComponentProps) {
    super(props);
    this.state = { isHorizontalItemHover: false, horizontalMenuHoverItemID: '' };
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleDisPlayItemChild, true);
    window.addEventListener('resize', this.handleDisPlayItemChild, true);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    document.removeEventListener('scroll', this.handleDisPlayItemChild, true);
    window.removeEventListener('resize', this.handleDisPlayItemChild, true);
  }

  handleDisPlayItemChild = () => {
    this.setState({ horizontalMenuHoverItemID: '' });
  };

  private getCompFormNode = (node: ITreeDataItem) => {
    const { comp } = this.props;
    return (comp as UIHorizontalMenuComponent).getItemCompById(node.id);
  };

  handleItemClick = (node: ITreeDataItem, e: React.MouseEvent) => {
    const { comp, isPreview } = this.props;
    if (isPreview && !comp.disabled) {
      const itemComp = this.getCompFormNode(node);
      if (itemComp) {
        const clickEvent = this.props.event?.onClick;
        clickEvent && clickEvent(e, itemComp);
      }
    }
  };

  handleItemMouseDown = (node: ITreeDataItem, e: React.MouseEvent) => {
    const { comp, isPreview } = this.props;
    if (isPreview && !comp.disabled) {
      const itemComp = this.getCompFormNode(node);
      if (itemComp) {
        const onMouseDown = this.props.event?.onMouseDown;
        onMouseDown && onMouseDown(e, itemComp);
      }
    }
  };

  handleItemExpandClick = (node: ITreeDataItem, e: React.MouseEvent) => {
    const { comp } = this.props;
    if (comp.disabled) {
      return;
    }
    const itemComp = this.getCompFormNode(node);
    const checkboxComp = itemComp.expandComp;
    if (checkboxComp) {
      const clickEvent = this.props.event?.onClick;
      clickEvent && clickEvent(e, checkboxComp);
    }
  };

  handleItemDoubleClick = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      const comp = this.getCompFormNode(node);
      const onDoubleClick = this.props.event?.onDoubleClick;
      comp && onDoubleClick && onDoubleClick(e, comp);
    }
  };

  handleItemMouseUp = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      const comp = this.getCompFormNode(node);
      const onMouseUp = this.props.event?.onMouseUp;
      comp && onMouseUp && onMouseUp(e, comp);
    }
  };

  handleItemMouseEnter = (node: ITreeDataItem, isHover: boolean, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      const comp = this.getCompFormNode(node);
      const onMouseEnter = this.props.event?.onMouseEnter;
      comp && onMouseEnter && onMouseEnter(e, comp);
      this.setState({ horizontalMenuHoverItemID: node.id, isHorizontalItemHover: isHover });
    }
  };

  handleItemMouseLeave = (node: ITreeDataItem, isHover: boolean, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      const comp = this.getCompFormNode(node);
      const onMouseLeave = this.props.event?.onMouseLeave;
      comp && onMouseLeave && onMouseLeave(e, comp);
      this.setState({ isHorizontalItemHover: isHover });
    }
  };

  handleItemContext = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      const comp = this.getCompFormNode(node);
      const onContextMenu = this.props.event?.onContextMenu;
      comp && onContextMenu && onContextMenu(e, comp);
    }
  };

  handleContentMouseLeave = () => {
    this.timer = window.setTimeout(() => {
      const { isHorizontalItemHover } = this.state;
      if (!isHorizontalItemHover) {
        this.setState({ horizontalMenuHoverItemID: '' });
      }
    }, 200);
  };

  private _paddingStyle: { paddingLeft: number; paddingRight: number; paddingTop: number; paddingBottom: number } = {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  };

  private getPaddingStyle = (): React.CSSProperties => {
    const { comp } = this.props;
    const {
      padding: { left, top, right, bottom },
    } = (comp as UIHorizontalMenuComponent).parseProperties();
    const isChanged =
      left !== this._paddingStyle.paddingLeft ||
      right !== this._paddingStyle.paddingRight ||
      top !== this._paddingStyle.paddingTop ||
      bottom !== this._paddingStyle.paddingBottom;
    isChanged &&
      (this._paddingStyle = {
        paddingLeft: left,
        paddingBottom: bottom,
        paddingRight: right,
        paddingTop: top,
      });

    return this._paddingStyle;
  };

  renderItem = (item: ITreeItem, level: number, itemIndex: number, parentIndex: number, index: number) => {
    const { isPreview, comp, hotAreaVisibleModel, showMobileCursor } = this.props;
    const itemComp = (comp as UIHorizontalMenuComponent).components.find((comp) => comp.id === item.data.id);
    if (!itemComp) return;
    const { horizontalMenuHoverItemID } = this.state;
    const {
      lineHeight,
      intention,
      checkedFill,
      checkedMarkerStrip,
      padding,
      showExpandIcon,
      showNodeIcon,
      size,
      checkedTextStyle,
      checkedIconColor,
    } = (comp as UIHorizontalMenuComponent).parseProperties();
    const horizontalContentRects = this.horizontalContentRef.current?.getClientRects();
    let showInteractionBackGround = false;
    if (hotAreaVisibleModel === 'only-hover' && !!horizontalMenuHoverItemID) {
      showInteractionBackGround = true;
    }
    if (hotAreaVisibleModel === 'always') {
      showInteractionBackGround = true;
    }
    return (
      <HorizontalMenuItem
        key={item.data.id}
        _id={item.data.id}
        horizontalMenuHoverItemID={horizontalMenuHoverItemID}
        horizontalContentRects={horizontalContentRects!}
        item={item}
        itemIndex={itemIndex}
        initTree={this.initTree}
        comp={itemComp as UITreeItemComponent}
        horizontalComp={comp as UIHorizontalMenuComponent}
        padding={padding}
        paddingStyle={this.getPaddingStyle()}
        lineHeight={lineHeight}
        level={level}
        size={size}
        selectedIDs={this.selectedIDs}
        index={index}
        parentIndex={parentIndex}
        maxChildLength={this.maxChildLength}
        checkedTextStyle={checkedTextStyle}
        checkedIconColor={checkedIconColor}
        intention={intention}
        checkedFill={checkedFill}
        checkedMarkerStrip={checkedMarkerStrip}
        showExpandIcon={showExpandIcon}
        showNodeIcon={showNodeIcon}
        showInteractionBackGround={showInteractionBackGround}
        isPreview={!!isPreview}
        floatPanelRects={this.floatPanelRects}
        defaultScale={this.defaultScale}
        showMobileCursor={showMobileCursor}
        onExpandClick={this.handleItemExpandClick}
        onItemClick={this.handleItemClick}
        onItemMouseDown={this.handleItemMouseDown}
        onItemMouseUp={this.handleItemMouseUp}
        onItemDoubleClick={this.handleItemDoubleClick}
        onItemMouseEnter={this.handleItemMouseEnter}
        onItemMouseLeave={this.handleItemMouseLeave}
        onItemContext={this.handleItemContext}
      >
        {item.children?.length && this.renderItems(item.children, level + 1, itemIndex, index)}
      </HorizontalMenuItem>
    );
  };

  renderItems = (items: ITreeItem[], level: number, itemIndex: number, index: number) => {
    return items.map((item, itemsIndex) => {
      return this.renderItem(item, level, itemIndex + itemsIndex, itemsIndex, index);
    });
  };

  renderHorizontalMenu = () => {
    const { comp } = this.props;
    const childComps: IComponentData[] = (comp as UIHorizontalMenuComponent).components.map((comp) => comp.$data);
    const { relation } = comp.value as ITreeData;
    this.initTree = mapRelationWithComp(relation, childComps);
    const { tree } = createAvlTreeWithTreeComp(relation, childComps);
    this.maxChildLength = this.initTree
      .map((item) => {
        return item.children?.length ?? 0;
      })
      .reduce((prev, curr) => {
        return prev > curr ? prev : curr;
      });
    const selectComp = childComps.filter((item) => {
      return item.selected === true;
    });
    const selectCompTree = selectComp.length && tree.get(selectComp[0]._id);
    if (selectCompTree) {
      this.selectedIDs = getParentIDsByCompTree(selectCompTree);
    } else {
      this.selectedIDs = [];
    }
    return this.initTree.map((item, index) => this.renderItem(item, 0, index, index, index));
  };

  render() {
    const { isPreview, comp } = this.props;
    return (
      <div className="lib-comp-horizontal-menu" onMouseLeave={this.handleContentMouseLeave}>
        <CanvasPanel comp={comp} isPreview={isPreview}>
          <div className="lib-comp-horizontal-menu-content" ref={this.horizontalContentRef}>
            {this.renderHorizontalMenu()}
          </div>
        </CanvasPanel>
      </div>
    );
  }
}
