/**
 * @description:
 * @author: koujing
 * @date: 2022-05-19 11:22
 **/
//@ts-nocheck
import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

import Menu from '../../components/Menu';
import { ShareType } from '../../constants';
import { setWeMeetShare, weMeetShare } from '../../helper/weMeet';
import i18n from '../../i18n';
import { ISetShareOpenAppConfig } from '../../models/weMeet';

const MockIcon = require('../../assets/images/weMeet/mockIcon.png');
const WeMeetIcon = require('../../assets/images/weMeet/weMeetIcon.png');

interface IShareSelect {
  className?: string;
  shareConfig?: ISetShareOpenAppConfig;
  shareMock(): void;
  onClose(): void;
  // For RP-App
  getInvitationLink(): Promise<string | undefined>;
}

const shareOption = [
  {
    id: ShareType.weMeet,
    text: i18n('txShare.tencentShare'),
    prefix: <img src={WeMeetIcon} alt="" />,
  },
  {
    id: ShareType.mock,
    text: i18n('txShare.mockShare'),
    prefix: <img src={MockIcon} alt="" />,
  },
];

const ShareSelect: React.FC<IShareSelect> = ({
  shareMock,
  onClose,
  shareConfig = {},
  className,
  getInvitationLink,
}: IShareSelect) => {
  const tencentShare = async () => {
    const invitattionUrl = await getInvitationLink();
    await setWeMeetShare({
      shareEnable: true,
      ...shareConfig,
      pcUrl: invitattionUrl ?? location.href,
      mobileUrl: invitattionUrl ?? location.href,
    });
    await weMeetShare();
    await setWeMeetShare({ shareEnable: true });
  };

  const onSelect = async (id: ShareType) => {
    if (id === ShareType.weMeet) {
      try {
        await tencentShare();
      } catch (error) {
        console.log(error);
      }
      onClose();
    } else if (id === ShareType.mock) {
      shareMock();
    }
  };

  return (
    <div className={classnames('dsm-c-tx-share', className)}>
      <Menu onSelect={onSelect} options={shareOption} onClose={onClose} />
    </div>
  );
};

export default ShareSelect;
