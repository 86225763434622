import { MobileType, PreviewUrlSearchKey } from '@fbs/rp/utils/preview';
import i18n from '@/i18n';
import * as _ from 'lodash';
import { parseUrlSearch } from './urlHelper';
import { ISize } from '@/fbs/common/models/common';

export interface IShareOption {
  showLinkAreaWhenHovered?: boolean;
  alwaysShowLinkArea?: boolean;
  autoScreen?: boolean;
  mobileType?: MobileType;
  noBoundary?: boolean;
  showRemarkTag?: boolean;
  controlPanelState?: boolean;
  remarkPanelState?: boolean;
  showNavigationBar?: boolean;
}

export const enum Role {
  ROLE_SUPER_ADMIN = 'super-admin',
  ROLE_ADMIN = 'admin',
  ROLE_MEMBER = 'member',
  ROLE_GUEST = 'guest',
  // 访客
  ROLE_OBSERVER = 'observer',
  ROLE_VISITOR = 'visitor',
  ROLE_DEFAULT = 'default',
}

export const panel: { hide: string; expand: string } = {
  hide: 'hide',
  expand: 'expand',
};

export const MobileTypeList: {
  id: MobileType;
  text: string;
}[] = [
  {
    id: MobileType.Iphone,
    text: i18n('preview.iPhone'),
  },
  {
    id: MobileType.iPhone_black,
    text: i18n('preview.iPhoneBlack'),
  },
  {
    id: MobileType.IphoneX,
    text: i18n('preview.iPhoneX'),
  },
  // {
  //   id: MobileType.Android,
  //   text: 'Android',
  // },
  {
    id: MobileType.HUAWEI_P40,
    text: i18n('preview.huaweiP40'),
  },
  {
    id: MobileType.HUAWEI_Mate,
    text: i18n('preview.huaweiMate'),
  },
  {
    id: MobileType.XiaoMi,
    text: i18n('preview.xiaoMi'),
  },
  {
    id: MobileType.Galaxy,
    text: i18n('preview.galaxy'),
  },
  {
    id: MobileType.Pad,
    text: i18n('preview.iPad'),
  },
  {
    id: MobileType.Surface_Pro,
    text: i18n('preview.surfacePro'),
  },
  {
    id: MobileType.None,
    text: i18n('general.none'),
  },
];

// eslint-disable-next-line no-magic-numbers
export function mobileTypeState(appType: string, width: number = 375, height: number = 812) {
  let shellType: MobileType = MobileType.None;
  switch (appType) {
    case 'pad':
      shellType = MobileType.Pad;
      break;
    case 'phone':
      shellType = adapterPhoneShell(height / width);
      break;
    default:
      shellType = MobileType.None;
      break;
  }
  return shellType;
}

const getRatio = (size: ISize): number => {
  return size.height / size.width;
};

const iphoneXOptions = {
  defaultScreenSize: {
    width: 375,
    height: 812,
  },
};

const iphoneOptions = {
  defaultScreenSize: {
    width: 375,
    height: 667,
  },
};

const huaweiP40Options = {
  defaultScreenSize: {
    width: 768,
    height: 1024,
  },
};

const padOptions = {
  defaultScreenSize: {
    width: 768,
    height: 1024,
  },
};

const iPhoneBlackOptions = {
  defaultScreenSize: {
    width: 768,
    height: 1024,
  },
};

const galaxyOptions = {
  defaultScreenSize: {
    // FIXME: 尺寸
    width: 360,
    height: 760,
  },
};

const huaweiMateOptions = {
  defaultScreenSize: {
    width: 360,
    height: 792,
  },
};

const xiaoMiOptions = {
  defaultScreenSize: {
    width: 768,
    height: 1024,
  },
};

const surfaceProOptions = {
  defaultScreenSize: {
    width: 912,
    height: 1368,
  },
};

const shellOptions = {
  [MobileType.IphoneX]: iphoneXOptions,
  [MobileType.Iphone]: iphoneOptions,
  // [MobileType.Android]: androidOptions,
  [MobileType.Pad]: padOptions,
  [MobileType.iPhone_black]: iPhoneBlackOptions,
  // [MobileType.iPad_Air]: padAirOptions,
  [MobileType.Galaxy]: galaxyOptions,
  [MobileType.HUAWEI_Mate]: huaweiMateOptions,
  [MobileType.HUAWEI_P40]: huaweiP40Options,
  [MobileType.XiaoMi]: xiaoMiOptions,
  [MobileType.Surface_Pro]: surfaceProOptions,
  [MobileType.None]: null,
};

// TODO 适配
export const adapterPhoneShell = (aspectRatio: number): MobileType => {
  const shells = [
    {
      type: MobileType.IphoneX,
      ratiodiff: Math.abs(getRatio(shellOptions.iphoneX.defaultScreenSize) - aspectRatio),
    },
    { type: MobileType.Iphone, ratiodiff: Math.abs(getRatio(shellOptions.iphone.defaultScreenSize) - aspectRatio) },
    { type: MobileType.Pad, ratiodiff: Math.abs(getRatio(shellOptions.pad.defaultScreenSize) - aspectRatio) },
    {
      type: MobileType.iPhone_black,
      ratiodiff: Math.abs(getRatio(shellOptions.iphoneX.defaultScreenSize) - aspectRatio),
    },
    { type: MobileType.Galaxy, ratiodiff: Math.abs(getRatio(shellOptions.iphoneX.defaultScreenSize) - aspectRatio) },
    {
      type: MobileType.HUAWEI_Mate,
      ratiodiff: Math.abs(getRatio(shellOptions.iphoneX.defaultScreenSize) - aspectRatio),
    },
    {
      type: MobileType.HUAWEI_P40,
      ratiodiff: Math.abs(getRatio(shellOptions.iphoneX.defaultScreenSize) - aspectRatio),
    },
    { type: MobileType.XiaoMi, ratiodiff: Math.abs(getRatio(shellOptions.iphoneX.defaultScreenSize) - aspectRatio) },
    {
      type: MobileType.Surface_Pro,
      ratiodiff: Math.abs(getRatio(shellOptions.iphoneX.defaultScreenSize) - aspectRatio),
    },
    // { type: 'pad', ratiodiff: Math.abs(getRatio(shellOptions.pad.defaultScreenSize) - aspectRatio) },
  ];
  return shells.sort((a, b) => {
    return a.ratiodiff - b.ratiodiff;
  })[0].type as MobileType;
};

// showNavigationBar 这个设置项不会在演示页被修改，链接里是什么就是什么
export function showNavigationBar() {
  const urlSearchObj = getUrlSearchObj();
  const searchValue = urlSearchObj[PreviewUrlSearchKey.ShowNavigationBar] as boolean | undefined;
  return searchValue ?? true;
}

/**
 * 将对象值中的对应字符串转为布尔值，数字
 */
function convertSearchOptData(oldData: { [key: string]: any }): { [key: string]: boolean | number | string } {
  const copy = _.cloneDeep(oldData);
  Object.keys(copy).forEach((key) => {
    const oldValue = oldData[key].toLowerCase();
    const num = Number(oldValue);
    if (oldValue === '1') {
      copy[key] = true;
    } else if (oldValue === '0') {
      copy[key] = false;
    } else if (!isNaN(num)) {
      copy[key] = num;
    }
  });
  return copy;
}

export function getUrlSearchObj() {
  const obj = parseUrlSearch();
  return convertSearchOptData(obj);
}

/**
 * 将演示分享配置转化为search字符串
 */
export function shareOptionToSearchStr(opt: IShareOption) {
  const keys = Object.keys(opt).filter((k) => !_.isUndefined(opt[k as keyof IShareOption])) as Array<
    keyof IShareOption
  >;
  let searchStr = '';
  const transData = (value: any, isPanel?: boolean) => {
    if (isPanel) {
      return value ? panel.expand : panel.hide;
    }
    if (_.isBoolean(value)) {
      return value ? 1 : 0;
    } else {
      return value;
    }
  };
  const searchArray = keys
    .map((k) => {
      const value: any = opt[k];
      switch (k) {
        case 'showNavigationBar':
          return `${PreviewUrlSearchKey.ShowNavigationBar}=${transData(value)}`;
        case 'alwaysShowLinkArea':
          return `${PreviewUrlSearchKey.AlwayshowLinkArea}=${transData(value)}`;
        case 'showLinkAreaWhenHovered':
          return `${PreviewUrlSearchKey.ShowLinkAreaWhenMouseHover}=${transData(value)}`;
        case 'noBoundary':
          return `${PreviewUrlSearchKey.ShowOutside}=${transData(value)}`;
        case 'showRemarkTag':
          return `${PreviewUrlSearchKey.ShowRemarkTag}=${transData(value)}`;
        case 'controlPanelState':
          return `${PreviewUrlSearchKey.ControlPanelState}=${transData(value, true)}`;
        case 'remarkPanelState':
          return `${PreviewUrlSearchKey.RemarkPanelState}=${transData(value, true)}`;
        case 'mobileType':
          return `${PreviewUrlSearchKey.DeviceType}=${transData(value)}`;
        default:
          return undefined;
      }
    })
    .filter((item) => !_.isUndefined(item));

  if (searchArray.length) {
    searchStr = `${searchArray.join('&')}`;
  }
  return searchStr;
}

export function copy(text: string) {
  const textarea = document.createElement('textarea');
  textarea.textContent = text;
  textarea.style.position = 'fixed';
  document.body.appendChild(textarea);
  textarea.select();
  try {
    return document.execCommand('copy');
  } catch (e) {
    console.warn('Copy to clipboard failed', e);
  } finally {
    document.body.removeChild(textarea);
  }
}
