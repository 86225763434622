import { hasDirctTextChild } from '../config/copyStyleItemRuler';
import { inliner } from './inliner';

export const PropfontFamily = 'font-family';
interface IWebFontInfo {
  resolve: () => Promise<string> | undefined;
  src: () => string;
}

// 字体缓存
export const FONT_FACES_CACHE = new Set();
export const clearFontFacesCache = () => {
  FONT_FACES_CACHE.clear();
};

export const addFontFacesCache = (t: string) => {
  // 只要英文空格 ，不要中文
  if (/^[a-zA-Z0-9\s]/.test(t)) {
    FONT_FACES_CACHE.add(t);
  }
};

/**
 * 过滤字体
 * @param styleSheets
 * @returns
 */
export function getWebFontRules(styleSheets: CSSStyleSheet[]): CSSFontFaceRule[] {
  const cssRules: CSSFontFaceRule[] = [];
  const usedFontList = Array.from(FONT_FACES_CACHE);
  // 名字相同的只取一个
  const fontFamilySet = new Set();
  styleSheets.forEach((sheet: CSSStyleSheet) => {
    try {
      Array.from(sheet.cssRules).forEach((rule: CSSRule) => {
        if (rule instanceof CSSFontFaceRule) {
          const style = rule.style;
          const fontFamily = style.fontFamily;
          if (
            !fontFamilySet.has(fontFamily) &&
            usedFontList.includes(fontFamily) &&
            inliner.shouldProcess(style.getPropertyValue('src'))
          ) {
            cssRules.push(rule);
            fontFamilySet.add(fontFamily);
          }
        }
      });
    } catch (e) {
      // console.log('Error while reading CSS rules from ' + sheet.href, e.toString());
    }
  });

  return cssRules;
}

const fontFaces = newFontFaces();

export const fetchWebFont = async (fontFamily: string): Promise<string> => {
  addFontFamilyToCache(fontFamily);
  const cssText = await fontFaces.resolveAll();
  // clearFontFacesCache()
  return cssText;
};

/**
 * 嵌入字体
 * @param node
 * @returns
 */
export function embedFonts(node: Element): Promise<Element> {
  console.time('embedFonts');
  return fontFaces.resolveAll().then((cssText) => {
    if (cssText) {
      console.timeEnd('embedFonts');
      console.log('fonts size:' + (cssText.length / 1024).toFixed(2) + 'kb');
      const styleNode = document.createElement('style');
      node.appendChild(styleNode);
      styleNode.appendChild(document.createTextNode(cssText));
    }
    return node;
  });
}

export const getFontFamily = (source: CSSStyleDeclaration): string => {
  const propName = PropfontFamily;
  const propValue = source.getPropertyPriority(propName) || source.getPropertyValue(propName);
  return propValue;
};

/**
 * 添加字体到cache
 * @param node
 */
export const addUsedFontFaceToCache = (source: CSSStyleDeclaration, node: HTMLElement): boolean => {
  // 存在文本
  const textContent = node.textContent;
  if (textContent && hasDirctTextChild(node)) {
    const fontFamily = getFontFamily(source);
    addPropValueToCache(fontFamily);
    // 标记在使用的字体
    return true;
  }
  return false;
};

export const addPropValueToCache = (propValue: string) => {
  if (!propValue) {
    return;
  }
  propValue
    .split(',')
    // 去头尾空格，去双引号//.trim()
    .map((t) => t.replace(/(^\s+?|\s+?$|")/g, ''))
    .forEach((t) => {
      // FONT_FACES_CACHE.add(t);
      addFontFacesCache(t);
    });
};

export const addFontFamilyToCache = (fontFamily: string) => {
  addPropValueToCache(fontFamily);
};

// 用于伪元素字体
export const addFontFaceToCache = (source: CSSStyleDeclaration): void => {
  const fontFamily = getFontFamily(source);
  addPropValueToCache(fontFamily);
};

export function newFontFaces() {
  return {
    resolveAll: resolveAll,
    impl: {
      readAll: readAll,
    },
  };

  function resolveAll() {
    return readAll(document)
      .then((webFonts) => {
        return Promise.all(
          webFonts.map((webFont: IWebFontInfo) => {
            return webFont.resolve();
          }),
        );
      })
      .then((cssStrings) => {
        return cssStrings.join('\n');
      });
  }

  function readAll(document: Document): Promise<IWebFontInfo[]> {
    function newWebFont(webFontRule: CSSFontFaceRule): IWebFontInfo {
      return {
        resolve: function resolve() {
          let baseUrl = (webFontRule.parentStyleSheet || {}).href ?? undefined;
          //  href:window.location.origin
          // if (baseUrl) {
          // 无baseUrl表示本地已经下载
          return inliner.inlineAll(webFontRule.cssText, baseUrl);
          // }
        },
        src: function () {
          return webFontRule.style.getPropertyValue('src');
        },
      };
    }
    return (
      Promise.resolve(Array.from(document.styleSheets))
        // .then(getCssRules)
        // .then(selectWebFontRules)
        .then(getWebFontRules)
        .then((rules: CSSFontFaceRule[]) => {
          // font-family: lightIconFont;
          console.log(
            rules.map((t) => {
              const ff = t.cssText.match(/font-family:(.*?);/);
              return ff ? ff[1] : '';
            }),
          );
          // fixme:中 英文 下载两次情况需要排除
          // woff2 和tff相同字体情况处理

          return rules.map((t) => {
            return newWebFont(t);
          });
        })
    );
  }
}
