export enum NodeIconType {
  None = '',
  // 文件夹或者分组
  Folder = 'tree_group',
  // 文档
  Doc = 'new_example',
  PDF = 'File_Pdf',
  Word = 'info_file',
  Excel = 'add_psd',
}

export enum NodeMoveDirectionType {
  ArrowUp = 'MoveUp',
  ArrowDown = 'MoveDown',
  ArrowLeft = 'MoveUpLevel',
  ArrowRight = 'MoveDownLevel',
}
export enum ContextCommand {
  Copy = 'copy',
  Paste = 'paste',
  Cut = 'cut',
  Find = 'find',
  Delete = 'delete',
  Rename = 'rename',
  AddPage = 'addPage',
  AddFolder = 'addFolder',
  Clone = 'clone',
  Redo = 'redo',
  Undo = 'undo',
}

// 拖拽时根据鼠标的位置不同，放入的位置不一样
export enum DropMode {
  // 禁止放置
  None = 'none',
  // 放在该节点的前面
  Before = 'before',
  // 放在该节点的后面
  After = 'after',
  // 作为该节点的子
  Child = 'child',
}

export const TREE_NODE = Symbol('tree_node');

export interface INode {
  id: string;
  serialNumber?: string;
  childCount?: number;
  name: string;
  pinyinName?: string;
  // 预览图片
  previewImage?: string;
  icon?: NodeIconType;
  color?: string;
  // 是否是首页，如果是首页，显示 Home 图标
  isHomepage?: boolean;
  // 是否是横屏，如果是横屏，显示横屏图标
  isHorizontal?: boolean;
  suffixIcon?: {
    icon: NodeIconType | string;
    color: string;
    label: string;
    disabled: boolean;
  };
  optionsIcon?: {
    icon: NodeIconType | string;
    color: string;
    label: string;
  };
  commentsIcon?: {
    icon: string;
    color: string;
    label: string;
  };
  // 不太确定是否是这个数据结构
  mark?: {
    icon: string;
    color: string;
  };
  children?: INode[];
  // 是不是页面
  isLeaf?: boolean;
  data?: any;
  disabled?: boolean;
  showInteractionIcon?: boolean;
  isInteractionTarget?: boolean;
  groupNum?: number;
  source?: string;
}

export interface INodeWithParentID extends INode {
  parentID: string;
}
