import * as React from 'react';
import classnames from 'classnames';
import i18n from '../../../i18n';

import { IItemProps, IGroupProps } from '../../../models';
import Checkbox from '../../Checkbox';
import Icon from '../../Icon';
import CheckItem from './CheckItem';

import './CheckGroup.scss';

interface ICheckGroupProps {
  group: IGroupProps;
  hideGroupCheck?: boolean;
  checkIds: string[];
  showAddGroup?: boolean;
  canCustomStatus?: boolean;
  addGroupTooltip?: string;

  onCheck(ids: string[]): void;

  handleScroll(): void;

  onCheckDisable?(id: string): void;
}

enum CheckType {
  All = 'all',
  None = 'none',
  Indeterminate = 'indeterminate',
}

const getGroupCheckType = (checkIds: string[], items: IItemProps[]): CheckType => {
  let indeterminate = items.some((item) => checkIds.includes(item.id));
  let all = items.every((item) => checkIds.includes(item.id)) && indeterminate;

  if (all) {
    return CheckType.All;
  }
  if (indeterminate) {
    return CheckType.Indeterminate;
  }
  return CheckType.None;
};

const CheckGroup: React.FC<ICheckGroupProps> = (props: ICheckGroupProps) => {
  const {
    group,
    hideGroupCheck,
    checkIds,
    showAddGroup,
    addGroupTooltip,
    canCustomStatus,
    onCheck,
    onCheckDisable,
  } = props;
  const { name, children, id } = group;

  const [expand, setExpand] = React.useState(group.expand);
  const [showTips, setShowTips] = React.useState(false);

  const checkType: CheckType = getGroupCheckType(checkIds, children);

  const renderSelectMaxiumTips = () => {
    return (
      <div className="select-member-maxium">
        <Icon cls="tag_exclamation" color="#F8AF1D" size={16} />
        <span>{i18n('checkSelect.maximumOfPeople')}</span>
      </div>
    );
  };
  // @ts-ignore
  const disabledFilterArr = children.filter((item) => item.disabled);
  const disableIDs = (disabledFilterArr && disabledFilterArr.map((item) => item.id)) || [];

  return (
    <div className="check-group">
      {canCustomStatus && (
        <div className="check-group-box">
          <span className="check-group-expand-wrapper">
            <Icon
              cls={expand ? 'tag_downarrow' : 'tag_rightarrow'}
              size={16}
              onClick={(e) => {
                setExpand(!expand);
              }}
            />
          </span>
          {!hideGroupCheck && (
            <Checkbox
              checked={checkType !== CheckType.None}
              indeterminate={checkType === CheckType.Indeterminate}
              disabled={children.length === 0}
              onChange={(isChecked: boolean) => {
                const childrenArr = children.map((item) => item.id);
                const filterArr = checkIds.filter((checked) => !childrenArr.includes(checked));
                switch (checkType) {
                  case CheckType.All:
                    if (!!disableIDs.length) {
                      const filterDsArr = checkIds.filter(
                        (checked) => !childrenArr.includes(checked) || disableIDs.includes(checked),
                      );
                      onCheck(filterDsArr);
                      return;
                    }
                    onCheck(filterArr);
                    break;
                  case CheckType.Indeterminate:
                  case CheckType.None:
                    onCheck(filterArr.concat(childrenArr));
                    break;
                  default:
                    throw Error('Check Type Missing!!!');
                }
              }}
            />
          )}
          <span
            className={classnames('group-title-wrapper', {
              'can-add-group': showAddGroup,
            })}
          >
            {<span className="group-name">{name}</span>}
            {children && <span className="group-child-count">({children.length})</span>}
          </span>
          {showAddGroup && id !== 'single-group' && id !== 'last' && (
            <span className="check-group-add">
              <Icon
                cls={'layer_plus'}
                size={14}
                clickArea={{ width: 16, height: 16 }}
                tooltip={addGroupTooltip}
                tooltipAlign="right"
                onClick={() => {
                  if (!checkIds.includes(id)) {
                    onCheck([...checkIds, id]);
                  }
                }}
              />
            </span>
          )}
        </div>
      )}
      <div className="check-items">
        {(expand || !canCustomStatus) &&
          children &&
          children.map((item: IItemProps) => {
            return (
              <CheckItem
                key={item.id}
                item={item}
                checked={checkIds.includes(item.id)}
                onCheck={(id: string) => {
                  if (hideGroupCheck && checkIds.length >= 1 && checkIds.indexOf(id) === -1) {
                    setShowTips(true);
                    setTimeout(() => {
                      setShowTips(false);
                    }, 2000);
                    return;
                  }
                  if (checkIds.includes(id)) {
                    onCheck(checkIds.filter((checked) => checked !== id));
                  } else {
                    onCheck([...checkIds, id]);
                  }
                }}
                onCheckDisable={onCheckDisable}
              />
            );
          })}
      </div>
      {showTips && renderSelectMaxiumTips()}
    </div>
  );
};

CheckGroup.defaultProps = {
  canCustomStatus: true,
};

export default CheckGroup;
