import * as React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';

import { MouseButtons } from '@/consts/enums/mouseButton';

import './index.scss';

export interface ISliderProp {
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  width?: number;
  className?: string;
  left?: boolean;
  disabled?: boolean;
  showText?: boolean;
  measureText?: string;
  onChanged: (value: number) => void;
  //代表正在移动中的数据
  onChanging: (value: number) => void;

  onStartChanging?: () => void;
}

class Slider extends React.PureComponent<ISliderProp, { mouseDown: boolean; disX: number; vue: number }> {
  static defaultProps: Partial<ISliderProp> = {
    min: 0,
    max: 100,
    step: 1,
    value: 50,
    className: '',
    // width: 200,
    left: false,
    disabled: false,
    showText: true,
    measureText: '%',
  };

  self: React.RefObject<HTMLDivElement> = React.createRef();
  selfWidth: number | undefined;

  constructor(props: ISliderProp) {
    super(props);
    const { max, min, value } = props;
    this.state = {
      mouseDown: false,
      disX: 0,
      vue: Math.max(min || 0, Math.min(value || 0, max || 100)),
    };
    this.doChange = this.doChange.bind(this);
    this.OnMouseDown = this.OnMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleBlock = this.handleBlock.bind(this);
    this.calculateValue = this.calculateValue.bind(this);
  }

  componentDidMount() {
    // FIXME: state.width只用于计算，不用于展示，在计算时设置width
    // if (!this.state.width) {
    //   const { width } = this.self.current!.getBoundingClientRect();
    //   this.setState({ width: Math.round(width) });
    // }
  }

  componentDidUpdate(prevProps: ISliderProp) {
    const { value, min, max } = this.props;
    if (isUndefined(value) || Number.isNaN(value) || isUndefined(prevProps.value) || Number.isNaN(prevProps.value)) {
      return;
    }
    if (value !== prevProps.value) {
      this.setState({ vue: Math.max(min!, Math.min(value!, max!)) });
    }
  }

  // 获取self dom的width
  updateSelfWidth = () => {
    let width = this.selfWidth;
    if (width === undefined && this.self.current) {
      width = this.self.current!.getBoundingClientRect().width;
      width = Math.round(width);
      this.selfWidth = width;
    }
    return width;
  };

  calculateValue(disX: number) {
    const width = this.updateSelfWidth();
    const { max, min } = this.props;
    const _max = max || 100;
    const _min = min || 0;

    const x = Math.max(0, Math.min(disX, width!));
    const percent = x / (width || 1);
    const len = _max - _min;
    const step = this.props.step || 1;
    const decimal = step % 1;
    let decimalCount = 0;
    if (decimal !== 0) {
      decimalCount = `${decimal}`.substring(2).length;
    }
    let start = 0;
    const unitLen = (step / len) * width!;
    let realGraduation = 0;
    let value = percent * len + _min;
    if (decimalCount) {
      return parseFloat(value.toFixed(decimalCount));
    }
    value = this.state.vue;
    const range = unitLen / 2;
    while (start <= width! + step) {
      if (start > x - range && start < x + range) {
        value = step * realGraduation + _min;
        break;
      }
      realGraduation++;
      start += unitLen;
    }
    return value;
  }

  OnMouseDown(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    this.doStartChange();
    const target = e.target as HTMLElement;
    const parent: HTMLElement = (target.parentNode as HTMLElement).offsetParent as HTMLElement;
    const parentLeft = parent.getBoundingClientRect().left;
    const disX = e.pageX - parentLeft;
    this.setState(
      {
        mouseDown: true,
        disX: parentLeft,
        vue: this.calculateValue(disX),
      },
      () => {
        window.addEventListener('mousemove', this.handleMouseMove);
        window.addEventListener('mouseup', this.handleMouseUp, { capture: true });
      },
    );
  }

  handleMouseMove(e: MouseEvent) {
    e.preventDefault();
    const { onChanging } = this.props;

    const { disX, mouseDown } = this.state;
    if (mouseDown && e.buttons === MouseButtons.Left) {
      let left = e.pageX - disX;
      const width = this.updateSelfWidth();
      if (left < 0) {
        left = 0;
      }
      if (left > width!) {
        left = width!;
      }
      const vue = this.calculateValue(left);
      this.setState({
        vue,
      });
      onChanging && onChanging(vue);
    }
  }

  handleMouseUp(e: MouseEvent | React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    const { vue } = this.state;
    this.doChange(vue);
    this.setState({
      mouseDown: false,
    });
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp, { capture: true });
  }

  doStartChange() {
    const { onStartChanging } = this.props;
    onStartChanging && onStartChanging();
  }

  handleBlock(e: React.MouseEvent<HTMLElement>) {
    this.doStartChange();
    e.stopPropagation();
    const target = e.target as HTMLElement;
    const parentLeft = target.offsetParent!.getBoundingClientRect().left;
    let disX = e.pageX - parentLeft;
    if (disX < 0) {
      disX = 0;
    }
    const width = this.updateSelfWidth();
    if (disX > width!) {
      disX = width!;
    }
    this.setState(
      {
        mouseDown: true,
        disX: parentLeft,
        vue: this.calculateValue(disX),
      },
      () => {
        window.addEventListener('mousemove', this.handleMouseMove);
        window.addEventListener('mouseup', this.handleMouseUp, { capture: true });
      },
    );
  }

  doChange(value: number) {
    const { onChanged, min, max } = this.props;
    const v = Math.max(min!, Math.min(value, max!));
    onChanged && onChanged(v);
  }

  handleDragStart = (e: React.DragEvent) => {
    e.preventDefault();
    this.doStartChange();
  };

  render() {
    const { width, left, disabled, className, showText, measureText, max, min } = this.props;

    const len = (max || 100) - (min || 0);
    const { vue } = this.state;
    const v = vue - (min || 0);
    return (
      <div ref={this.self} className={classnames('dsm-c-rp-slider', className, { disabled })}>
        {left && (
          <span className="dsm-c-rp-block-title">
            {vue}
            {measureText}
          </span>
        )}
        <div
          className="dsm-c-rp-sliding-block"
          style={{ width }}
          onDragStart={this.handleDragStart}
          onMouseUp={disabled ? undefined : this.handleMouseUp}
          draggable={false}
        >
          <div
            className="dsm-c-rp-slider-click"
            style={{ width: width || '100%' }}
            onMouseDown={disabled ? undefined : this.handleBlock}
          />
          <div className="dsm-c-rp-block" style={{ width: `${v >= len ? 100 : (v / len) * 100}%` }}>
            <span className="dsm-c-rp-block-btn" onMouseDown={disabled ? undefined : this.OnMouseDown} />
          </div>
        </div>
        {showText && !left && (
          <span className="dsm-c-rp-block-title">
            {vue}
            {measureText}
          </span>
        )}
      </div>
    );
  }
}

export default Slider;
