import { ISize } from '@fbs/common/models/common';
import { IPathValue, INoteValue } from '@fbs/rp/models/value';
import { FillType } from '@/fbs/rp/models/properties/fill';
import { TextAlign, VerticalAlign as TextVerticalAlign } from '@/fbs/rp/models/properties/text';
import { HorizontalAlign, VerticalAlign } from '@/fbs/rp/models/layout';
import { ArtboardPatches, Ops } from '@/fbs/rp/utils/patch';
import { NoteConnectedLinePropertyName } from '@/fbs/rp/models/properties/noteConnectedLine';
import { PropertyValue } from '@/fbs/rp/models/property';
import { IPoint } from '@/fbs/common/models/common';
import { IComponentData } from '@fbs/rp/models/component';

import i18n from '@i18n';
import { makeCommonComponent } from '@/libs/helper';
import { CPath } from '@/libs/constants';
import { UIContainerComponent, UIComponent } from '@editor/comps';
import { SpriteThumb } from '@/consts/spriteIcons';
import { DefaultNoteConnectedLine, DEFAULT_END_POINT } from '@/consts/noteConnectedLine';
import { scalePath } from '@helpers/pathHelper';
import appOptions from '@/helpers/appOptions';
import { IComponentItem } from '../../types';

function onPropertyUpdate(comp: UIComponent, propertyName: string, newValue: PropertyValue): ArtboardPatches | null {
  const value = comp.value as INoteValue;
  // 显示隐藏连接线切换，重新打开，终点为默认值
  if (propertyName === NoteConnectedLinePropertyName && newValue.disabled !== comp.properties.connectedLine?.disabled) {
    const path = comp.getCurrentPath('value');
    const endPoint = newValue.disabled ? undefined : { ...DEFAULT_END_POINT, y: comp.size.height / 2 };
    return {
      do: { [comp.id]: [Ops.replace(path, { ...value, endPoint })] },
      undo: { [comp.id]: [Ops.replace(path, value)] },
    };
  }
  return null;
}

/**
 * 获取默认连接线样式
 */
export function getDefaultConnectedLine() {
  const cache = appOptions.noteConnectedLineProperty;
  return cache || DefaultNoteConnectedLine;
}

const DefaultProperties = {
  fill: {
    disabled: false,
    type: FillType.solid,
    color: { r: 255, g: 223, b: 37, a: 1 },
  },
  stroke: {
    hidden: true,
    thickness: 0,
  },
  textFormat: {
    disabled: false,
    fontFamily: 'Microsoft YaHei',
    fontSize: 14,
    textAlign: TextAlign.left,
    color: { r: 0, g: 0, b: 0, a: 1 },
    fontStyle: { bold: false, italic: false, underline: false, strike: false },
    wrap: true,
    isMulti: true,
    verticalAlign: TextVerticalAlign.top,
  },
  padding: {
    hidden: false,
    disabled: false,
    left: 10,
    right: 10,
    top: 10,
    bottom: 10,
  },
  shadow: {
    disabled: false,
    x: 0,
    y: 2,
    blur: 4,
    color: { r: 0, g: 0, b: 0, a: 0.3 },
  },
};

export function makeStickNote(id: string) {
  const connectedLine = getDefaultConnectedLine();
  return makeCommonComponent(id, CPath, {
    properties: {
      ...DefaultProperties,
      connectedLine,
    },
    fixContent: true,
    size: {
      width: 150,
      height: 180,
    },
    layout: {
      vertical: VerticalAlign.Auto,
      horizontal: HorizontalAlign.Auto,
      fixedWidth: false,
      fixedHeight: false,
      auto: true,
      responsive: true,
    },
    value: {
      endPoint: !connectedLine.disabled ? DEFAULT_END_POINT : undefined,
      data: [
        {
          point: {
            x: 0,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 150,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 150,
            y: 180,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 180,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
      closed: true,
    },
    text: i18n('resource.components.stickNote'),
  });
}

export const StickNoteCfg: IComponentItem = {
  type: 'stickNote',
  name: `${i18n('resource.components.stickNote')}`,
  thumb: {
    spriteIconClass: SpriteThumb.StickNote.className,
    dragPosition: SpriteThumb.StickNote.position,
  },
  predefinedStates: ['hover', 'pressed', 'disabled'],
  isTextComp: true,
  shortCut: 'S',
  editor: {
    onValidateAllowEditor: () => 'value',
    onPropertyUpdate,
    specials: {
      onPositionChanged: (comp: UIComponent, newPosition: IPoint, newSize: ISize) => {
        const position = comp.position;
        const size = comp.size;
        const value = comp.value as INoteValue;
        if (!value.endPoint) {
          return null;
        }

        const scale = {
          x: newSize.width / size.width,
          y: newSize.height / size.height,
        };
        const newPathData = scalePath(value, scale).data;
        const path = comp.getCurrentPath('value');
        const newValue = {
          ...value,
          data: newPathData,
          endPoint: {
            x: value.endPoint.x - (newPosition.x - position.x),
            y: value.endPoint.y - (newPosition.y - position.y),
          },
        };

        return {
          do: { [comp.id]: [Ops.replace(path, newValue)] },
          undo: { [comp.id]: [Ops.replace(path, value)] },
        };
      },
    },
  },
  initialization: (_appType: string, data: IComponentData, _container?: UIContainerComponent, size?: ISize) => {
    const {
      size: { width, height },
    } = data;
    if (size) {
      const scale = {
        x: size.width / width,
        y: size.height / height,
      };
      const value = data.value as IPathValue;
      data.value = scalePath(value, scale);
    }
  },
  getDefaultData() {
    return {
      properties: DefaultProperties,
    };
  },
};
