import PropertyBase from './base';
import { LinePointType } from './line';
import { PureColor } from './color';
import { PresetDashModel } from './stroke';

export const NoteConnectedLinePropertyName = 'connectedLine';

interface INoteConnectedLine extends PropertyBase {
  color: PureColor;
  lineWidth: number;
  lineType: PresetDashModel;
  startType: LinePointType;
  endType: LinePointType;
}

export default INoteConnectedLine;
