import * as React from 'react';

import CustomLink from '../../../components/Link';

import i18n from '../../../i18n';

import './index.scss';

interface IHistoryPanelProps {
  options: string[];

  onClearHistory(): void;
  onClickItem(value: string, fromHistory: boolean): void;
}

const HistoryPanel: React.FC<IHistoryPanelProps> = (props: IHistoryPanelProps) => {
  const { options, onClearHistory, onClickItem } = props;

  const firstFiveOptions = options.slice(0, 5);

  return (
    <div className="dsm-c-search-input-history-panel">
      <div className="header">
        <span>{i18n('searchInput.searchHistory')}</span>
        <span className="clear" onClick={onClearHistory}>
          {i18n('searchInput.clearHistory')}
        </span>
      </div>
      <ul className="content">
        {firstFiveOptions.map((item) => (
          <li
            key={item}
            onClick={(e) => {
              e.stopPropagation();
              onClickItem(item, true);
            }}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HistoryPanel;
