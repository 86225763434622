import { unstable_batchedUpdates } from 'react-dom';
import { isEqual, cloneDeep } from 'lodash';
import { depthClone, isNotEqual0, max, min, notSameNumber, round, roundNumberObject } from '@utils/globalUtils';
import { getDeltaForOrderRotate } from '@utils/rotateUtils';
import { createBoundsBySize, ISize } from '@utils/boundsUtils';
import { MoveDelta } from '@fbs/common/models/resize';
import { IBoundsOffset, IPoint, IPosition } from '@fbs/common/models/common';
import { HorizontalAlign, IBasicLayout, ILayout, VerticalAlign } from '@fbs/rp/models/layout';
import { ILineValue, ISnapshotValue } from '@fbs/rp/models/value';
import { ArtboardOperations, ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import {
  allCompsSatisfy,
  getAnchors,
  getCompAbsoluteMatrix,
  getCompChangeByNewPosition,
  getLayoutDirection,
  getSelectionBoxPoints,
  getSelectionFrameTypeByConstraint,
  IEditAnchors,
  tansPointInArtBoardToGroup,
} from '@helpers/componentHelper';
import ComponentAlignType from '@consts/enums/comAlignType';
import SelectionFrameType, { SelectionPoints } from '@consts/enums/selectionBoxType';
import {
  UIComponent,
  UICompoundPathComponent,
  UIContainerComponent,
  UIListLayoutSealedComponent,
  UITextComponent,
} from '@editor/comps';
import { dragResizePoint, getCenter, getNWPoint } from '@helpers/rotateHelper';
import editorValidate from '@helpers/editorValidate';
import { getViewBoundsOfComponents } from '@editor/bounds';
import { collectComponentsLayout } from '@helpers/responseLayoutHelper';
import { CText, CCompoundPath, CImage, CSnapshot } from '@libs/constants';
import { SizeMode } from '@libs/enum';
import { getLibData } from '@libs/libs';
import { setListItemsValue } from '@libs/valueEditHelper';
import { TransformOriginType } from '@consts/types/component';
import { SelectionGroupSetting } from '@/components/Application/WorkContainer/Workspace/SelectionGroupDialog';
import { UIPanelComponent, getNewCreatedSGData } from '@editor/comps';
import { ArtboardPatchesClass } from '@editor/patches/artboardPatches';
import AdvancedEditor from '@editor/advancedEditor';
import { Vector } from '@helpers/vector';
import { coverPatches, mergePatches } from '@helpers/patchHelper';
import { getMovingInfoWhenControlMove, SpacingChangeInfo } from '@helpers/compSpaceHelper';
import CoreDoc from './doc';
import {
  ComponentChange,
  ComponentChangeType,
  ContainerPatches,
  getMinSizeOfComp,
  getOffsetByNewSizeAndFixPoint,
  getResizeSingLinePatch,
  isDragStartOrEndPoint,
  LineInfoDuringResize,
} from '../comps/resizeHelper';
import { convertRemovedComponentsToAddOps, getFinalPositionWhenMove, reRenderComps } from './helper';
import { MoveSelectedToOtherArtboardCommand } from '../commands';

/**
 * 针对已选组件的操作
 */
export default class CoreBox extends CoreDoc {
  /**
   * 获取选中组件区域的各个点
   * @returns {IPoint[] | null}
   */
  get selectedBoxPoints(): IPoint[] | null {
    return getSelectionBoxPoints(this.selectedComponents);
  }

  /**
   * 获取选择框类型
   * @returns {SelectionFrameType}
   */
  get selectionFrameType(): SelectionFrameType {
    const selectedComponents = this.selectedComponentList;
    if (selectedComponents.length === 1) {
      const comp = selectedComponents[0];
      let sizeMode: SizeMode | undefined;
      if (comp.libData?.sizeMode) {
        sizeMode = comp.libData.sizeMode;
      } else {
        sizeMode = comp?.constraintInSealedComp?.resize;
      }
      let alias = comp.alias;
      if (this.isAdvancedEditor && alias) {
        const constraint = ((this as unknown) as AdvancedEditor).originContainer?.constraint;
        sizeMode = constraint && constraint[alias] && constraint[alias].resize;
      }
      if (sizeMode !== undefined) {
        return getSelectionFrameTypeByConstraint(sizeMode);
      }
    }
    const allCompIsText = selectedComponents.every((comp) => comp.type === CText);
    if (allCompIsText) {
      // 多个文本组件一起多选时,如果全部都为横向或者纵向,应该给与特殊处理
      if (selectedComponents.length > 1) {
        const allCompIsVertical = allCompsSatisfy(selectedComponents, (comp: UIComponent) => {
          const { textFormat, multiText } = comp.properties;
          const format = textFormat || multiText;

          return !!format?.vertical;
        });
        if (allCompIsVertical) {
          return SelectionFrameType.topMiddle_to_bottomMiddle;
        }
        return SelectionFrameType.leftMiddle_to_rightMiddle;
      } else {
        return selectedComponents[0].selectFrameType;
      }
    }
    if (this.activeContainer instanceof UIListLayoutSealedComponent) {
      return SelectionFrameType.none;
    }

    if (this.hasOnlyOneSelectedComponents) {
      return this.firstSelectedComponent!.selectFrameType;
    }
    return SelectionFrameType.box;
  }

  /**
   * 获取选中组件旋转角度，目前获取的是第一个组件的旋转角度
   * @returns {number}
   */
  get selectionFrameRotate(): number {
    if (this.hasOnlyOneSelectedComponents) {
      return this.firstSelectedComponent!.rotate;
    }
    return 0;
  }

  get canDoCompMoveOperation() {
    const { selectedComponentList, allSelectedComponentAreLocked, canMove } = this;
    const { horizontal, vertical } = canMove;
    if (
      (!horizontal && !vertical) ||
      allSelectedComponentAreLocked ||
      selectedComponentList.findIndex((comp) => !comp.isConnector) === -1
    ) {
      return false;
    }
    return true;
  }

  dragResizeSingleLineComp(
    lineComp: UIComponent,
    originalPoints: IPoint[],
    lineInfoDuringResize: LineInfoDuringResize,
  ) {
    const resizeSingLinePatch = getResizeSingLinePatch(lineComp, originalPoints, lineInfoDuringResize);
    this.update({ [this.activeArtboard.artboardID]: resizeSingLinePatch });
  }

  /**
   * 旋转组件
   * @param {number} newRotate 旋转角度
   */
  rotate(newRotate: number): void {
    if (!this.hasOnlyOneSelectedComponents) {
      return;
    }
    const component = this.firstSelectedComponent!;
    if (component.rotate === newRotate) {
      return;
    }
    const res = this.activeContainer.getPositionPatchesOfChildrenChanged(
      [
        {
          id: component.id,
          type: ComponentChangeType.Edit,
          position: component.position,
          size: component.size,
          rotate: newRotate,
        },
      ],
      true,
    );

    this.update({
      [this.activeArtboard.artboardID]: res.patches,
    });
  }

  moveSelectedComps(
    delta: MoveDelta,
    comps: UIComponent[],
    config?: { willRemoveComps?: UIComponent[]; needRoundedPosition?: boolean },
  ) {
    const willRemoveComps = config && config.willRemoveComps;
    // 这里moveDelta是相对与画板坐标系的，而我们要将其转化成相对于当前组坐标系的位移
    const futureCompsData: ComponentChange[] = getFinalPositionWhenMove(comps, delta, config?.needRoundedPosition);
    let artboardPatches: ContainerPatches;
    if (this.activeContainer.type === CCompoundPath) {
      artboardPatches = (this.activeContainer as UICompoundPathComponent).getPositionPatchesOfChildrenChanged(
        futureCompsData,
        true,
        willRemoveComps,
      );
    } else {
      artboardPatches = this.activeContainer.getPositionPatchesOfChildrenChanged(futureCompsData, true);
    }

    return { [this.activeArtboard.artboardID]: artboardPatches.patches };
  }

  /**
   *  移动组件过程中的方法，先借用面板方法,验证计算性能
   */
  movingSelectedComps(
    delta: MoveDelta,
    comps: UIComponent[],
    config?: { willRemoveComps?: UIComponent[]; needRoundedPosition?: boolean },
  ) {
    // 这里moveDelta是相对与画板坐标系的，而我们要将其转化成相对于当前组坐标系的位移
    const futureCompsData: ComponentChange[] = getFinalPositionWhenMove(comps, delta, config?.needRoundedPosition);
    const artboardPatches = UIPanelComponent.prototype.getPositionPatchesOfChildrenChanged.bind(this.activeContainer)(
      futureCompsData,
    ).patches;
    return { [this.activeArtboard.artboardID]: artboardPatches };
  }

  resizeSelectedComps(
    components: UIComponent[],
    args: {
      offset: IBoundsOffset;
      backupAllCompsLayout?: WeakMap<UIComponent, IBasicLayout>;
      willRemoveComps?: UIComponent[];
      dragIndex?: number;
    },
    options: {
      shift: boolean;
      isScale?: boolean;
      isSwitchToShift?: boolean;
      changeRadius?: boolean;
      changeShadow?: boolean;
      scalePercent?: number;
    },
  ): ArtboardPatches {
    return this.activeContainer.resizeChildren(components, args, options);
  }

  /**
   * 组件resize过程中的方法，不会对容器进行更新
   */
  resizingSelectedComps(
    components: UIComponent[],
    args: {
      offset: IBoundsOffset;
      backupAllCompsLayout?: WeakMap<UIComponent, IBasicLayout>;
      willRemoveComps?: UIComponent[];
    },
    options: {
      shift: boolean;
      isScale?: boolean;
      isSwitchToShift?: boolean;
      changeRadius?: boolean;
      changeShadow?: boolean;
      scalePercent?: number;
    },
  ): ArtboardPatches {
    return this.activeContainer.resizeChildrenWithoutUpdateSelf(components, args, options);
  }

  moveCompsInSealedComp(delta: MoveDelta, comps: UIComponent[] = this.selectedCompsExcludeConnector) {
    if (comps[0].isInSuchParent((comp) => comp.isSealed)) {
      const sealedComp = comps[0].nearestSealedComponent!;
      const parentLibType = sealedComp.lib?.type;
      if (parentLibType) {
        const onChildMove = getLibData(parentLibType)?.editor?.onChildMove;
        return onChildMove && onChildMove(delta, sealedComp as UIContainerComponent);
      }
    }
  }

  /**
   * 跨画板拖拽组件，仅当拖拽画板的直接子组件时调用
   * @author Matt
   * @param {IPoint} offset
   * @param {string} from
   * @param {string} to
   */
  moveSelectedToOtherArtboard(offset: IPoint, from: string, to: string) {
    // @ts-ignore
    const command = new MoveSelectedToOtherArtboardCommand(this, offset, from, to);
    command.execute();
  }

  /**
   * 修改layout的方法，考虑几个属性之间的关系, 不用于直接修改Horizontal，Vertical
   * (直接修改Horizontal，Vertical使用 @function anchorsChange )
   * @param key
   * @param {(HorizontalAlign | VerticalAlign | boolean)} value
   * @returns {void}
   * @memberof CoreBox
   */
  layoutItemChange(key: keyof ILayout, value: HorizontalAlign | VerticalAlign | boolean): void {
    if (!editorValidate.allowChildrenAnchorsChange(this.activeContainer.toJSON())) {
      return;
    }
    if (['horizontal', 'vertical'].includes(key)) {
      return;
    }
    const comps = this.selectedComponentList;
    const doOps: ArtboardOperations = {};
    const undoOps: ArtboardOperations = {};
    const layoutMap = collectComponentsLayout(comps, createBoundsBySize(comps[0].parent!.size));
    const patches: ArtboardPatches = {
      do: doOps,
      undo: undoOps,
    };
    comps.forEach((comp) => {
      const currentLayout = comp.layout;
      if (currentLayout[key] === value) {
        return;
      }
      if (key === 'responsive') {
        const childPatches = comp.updateResponsiveLayout(value as boolean);
        coverPatches(patches, childPatches);
      }
      doOps[comp.id] = [...(doOps[comp.id] || []), Ops.replace(`/layout/${key}`, value)];
      undoOps[comp.id] = [...(undoOps[comp.id] || []), Ops.replace(`/layout/${key}`, currentLayout[key])];
      // 修改高宽固定，处理锚定方向
      if (key === 'fixedWidth' && value && currentLayout.horizontal === HorizontalAlign.LeftAndRight) {
        doOps[comp.id] = [...(doOps[comp.id] || []), Ops.replace(`/layout/horizontal`, HorizontalAlign.Left)];
        undoOps[comp.id] = [...(undoOps[comp.id] || []), Ops.replace(`/layout/horizontal`, currentLayout.horizontal)];
      }
      if (key === 'fixedHeight' && value && currentLayout.vertical === VerticalAlign.TopAndBottom) {
        doOps[comp.id] = [...(doOps[comp.id] || []), Ops.replace(`/layout/vertical`, VerticalAlign.Top)];
        undoOps[comp.id] = [...(undoOps[comp.id] || []), Ops.replace(`/layout/vertical`, currentLayout.vertical)];
      }
      if (key === 'auto' && !value) {
        // 修改为手动同时修改智能锚定
        const newLayout = layoutMap.get(comp);
        if (newLayout) {
          doOps[comp.id] = [
            ...(doOps[comp.id] || []),
            Ops.replace(`/layout/vertical`, newLayout.vertical),
            Ops.replace(`/layout/horizontal`, newLayout.horizontal),
            Ops.replace(`/layout/fixedWidth`, newLayout.fixedWidth),
            Ops.replace(`/layout/fixedHeight`, newLayout.fixedHeight),
          ];
          undoOps[comp.id] = [
            ...(undoOps[comp.id] || []),
            Ops.replace(`/layout/vertical`, currentLayout.vertical),
            Ops.replace(`/layout/horizontal`, currentLayout.horizontal),
            Ops.replace(`/layout/fixedWidth`, currentLayout.fixedWidth),
            Ops.replace(`/layout/fixedHeight`, currentLayout.fixedHeight),
          ];
        }
      }
    });
    this.update({
      [this.activeArtboard.artboardID]: patches,
    });
  }

  /**
   * 通过anchors修改layout的vertical/horizontal方法
   * @param key
   * @param {boolean} value
   * @returns {void}
   * @memberof CoreBox
   */
  anchorsChange(key: keyof IEditAnchors, value: boolean): void {
    if (!editorValidate.allowChildrenAnchorsChange(this.activeContainer.toJSON())) {
      return;
    }

    const comps = this.selectedComponentList;
    const doOps: ArtboardOperations = {};
    const undoOps: ArtboardOperations = {};

    comps.forEach((comp) => {
      const currentLayout = comp.layout;
      const newAnchors = { ...getAnchors(currentLayout), [key]: value };
      const parentCenter = getCenter({ x: 0, y: 0 }, comp.parent!.size);
      const newPosition = { ...comp.position };
      //还要修正居中组件的位置
      const path = comp.getCurrentPositionPath();
      if (key === 'center' && value) {
        newAnchors.left = false;
        newAnchors.right = false;
        newPosition.x = round(getNWPoint(parentCenter, comp.size, 0).x);
      } else if (key === 'middle' && value) {
        newAnchors.top = false;
        newAnchors.bottom = false;
        newPosition.y = round(getNWPoint(parentCenter, comp.size, 0).y);
      }
      const newLayout = getLayoutDirection(newAnchors);
      // 结合当前是否高宽固定
      if (currentLayout.fixedHeight) {
        if (key === 'top' && newLayout.vertical === VerticalAlign.TopAndBottom) {
          newLayout.vertical = VerticalAlign.Top;
        } else if (key === 'bottom' && newLayout.vertical === VerticalAlign.TopAndBottom) {
          newLayout.vertical = VerticalAlign.Bottom;
        }
      }
      if (currentLayout.fixedWidth) {
        if (key === 'left' && newLayout.horizontal === HorizontalAlign.LeftAndRight) {
          newLayout.horizontal = HorizontalAlign.Left;
        } else if (key === 'right' && newLayout.horizontal === HorizontalAlign.LeftAndRight) {
          newLayout.horizontal = HorizontalAlign.Right;
        }
      }
      if (newLayout.horizontal !== currentLayout.horizontal) {
        doOps[comp.id] = [...(doOps[comp.id] || []), Ops.replace(`/layout/horizontal`, newLayout.horizontal)];
        undoOps[comp.id] = [...(undoOps[comp.id] || []), Ops.replace(`/layout/horizontal`, currentLayout.horizontal)];
      }
      if (newLayout.vertical !== currentLayout.vertical) {
        doOps[comp.id] = [...(doOps[comp.id] || []), Ops.replace(`/layout/vertical`, newLayout.vertical)];
        undoOps[comp.id] = [...(undoOps[comp.id] || []), Ops.replace(`/layout/vertical`, currentLayout.vertical)];
      }
      if (!isEqual(comp.position, newPosition)) {
        if (!doOps[comp.id]) {
          doOps[comp.id] = [];
          undoOps[comp.id] = [];
        }
        doOps[comp.id].push(Ops.replace(path, newPosition));
        undoOps[comp.id].push(Ops.replace(path, comp.position));
      }
    });

    this.update({
      [this.activeArtboard.artboardID]: {
        do: doOps,
        undo: undoOps,
      },
    });
  }

  // 修改位置
  // TODO: 如果修改位置后，组件离开了当前画板，并且进入了另外一个画板，得移动该组件到另外一个画板内
  positionChange(position: IPosition): void {
    if (!this.hasSelectedComponents) {
      return;
    }
    //如果是多选组件,那么接受的 position,实际是将多选组件的外框移动到 position 的位置,内部的组件的位置要相应调整
    const selectedComps = this.selectedComponentList;
    const isMoveMultiComps = selectedComps.length > 1;
    let change: ComponentChange[] = [];
    if (isMoveMultiComps) {
      change = selectedComps.map((comp) => {
        const { left: originAreaLeft, top: originAreaTop } = getViewBoundsOfComponents(selectedComps)!;
        const positionDiff = Vector(position).subtract({ x: originAreaLeft, y: originAreaTop });
        const newPosition = Vector(comp.position).add(positionDiff);
        return {
          id: comp.id,
          type: ComponentChangeType.Edit,
          size: comp.size,
          rotate: comp.rotate || 0,
          position: newPosition,
        };
      });
    } else {
      //只移动一个
      change = selectedComps.map((comp) => getCompChangeByNewPosition(comp, position));
    }

    // 输入进去的点的位置没有小数，getPositionPatchesOfChildrenChanged里面也不会有小数
    const { patches } = this.activeContainer.getPositionPatchesOfChildrenChanged(change, true);

    if (this.activeContainer.type === CCompoundPath) {
      const newComps = depthClone(this.activeContainer.components.map((comp) => comp.toJSON()));
      change.forEach((change) => {
        const comp = newComps.find((item) => item._id === change.id);
        comp!.position = change.position;
        comp!.size = change.size;
        comp!.rotate = change.rotate;
      });
      const { newActiveGroup, patches: newPatches } = (this
        .activeContainer as UICompoundPathComponent).refreshPatchesWithNewChildren(newComps);
      if (newActiveGroup && newActiveGroup.id !== this.activeContainer.id) {
        // 如果当前容器被删除了，那么久不合并patches
        patches.do = newPatches.do;
        patches.undo = newPatches.undo;
        this.setActiveContainer(newActiveGroup);
      } else {
        mergePatches(patches, newPatches);
      }
    }

    this.update({
      [this.activeArtboard.artboardID]: patches,
    });
  }

  floatComp(float: boolean) {
    if (!this.activeContainer.isArtboard) {
      return;
    }
    if (!this.activeArtboard.isMain) {
      return;
    }
    if (!this.isNormalEditor) {
      return;
    }
    const comps = this.selectedComponentList;
    const artboardPatches = comps.reduce(
      (acc, curr) => {
        acc.do[curr.id] = [Ops.replace('/float', float)];
        acc.undo[curr.id] = [Ops.replace('/float', curr.toJSON().float)];
        return acc;
      },
      { do: {}, undo: {} } as ArtboardPatches,
    );
    this.updateSingleArtboard(this.activeArtboard.artboardID, artboardPatches);
  }

  /**
   * 当尺寸变化时，文本尺寸适配行为变化
   * @param  size
   */
  private doSizeChangeWithAutoSizeTextComps(size: { height: number | undefined; width: number | undefined }) {
    const comps = this.selectedComponentList.filter(
      (comp) => comp instanceof UITextComponent && comp.type === CText,
    ) as UITextComponent[];
    if (!comps.length) {
      return;
    }
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    const group = this.activeContainer;
    comps.forEach((comp) => {
      const { size: originSize } = comp;
      const newSize = { ...originSize };
      if (size.height) {
        newSize.height = size.height;
      }
      if (size.width) {
        newSize.width = size.width;
      }
      if (newSize.width !== originSize.width || newSize.height !== originSize.height) {
        const offset = {
          left: 0,
          top: 0,
          right: newSize.width - originSize.width,
          bottom: newSize.height - originSize.height,
        };
        const compPatches = group.resizeChildren([comp], { offset }, { shift: false });
        const textBehaviourPatches = comp.updateTextBehaviourWhenSizeChange(newSize, originSize);
        coverPatches(patches, compPatches);
        textBehaviourPatches && coverPatches(patches, textBehaviourPatches);
      }
    });
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  // 修改组件间距 --多个组件间距相等的情况下
  public spacingChange(data: SpacingChangeInfo): void {
    const comps = this.selectedComponentList;
    if (comps.length < 2) {
      return;
    }
    const { type, spacingOffset, scale, spaceInfo, controls, config } = data;
    const newControl = controls.filter((item) => {
      return item.type == type;
    });
    const allCompInitialInfoRelativeToArtboard = comps.map((item) => {
      return {
        id: item.id,
        size: item.size,
        position: item.positionRelativeToArtboard,
        rotate: item.rotateRelativeToArtboard,
      };
    });
    const diff = type === 'horizontal' ? { x: spacingOffset / 2, y: 0 } : { x: 0, y: spacingOffset / 2 };
    const { neededChangeComp, dynamicInfoMap } = getMovingInfoWhenControlMove(
      spaceInfo,
      newControl[0],
      diff,
      allCompInitialInfoRelativeToArtboard,
      scale,
      config,
    );
    const artboardPatches = neededChangeComp.reduce(
      (acc, curr) => {
        acc.do[curr.id] = [Ops.replace('/position', roundNumberObject(dynamicInfoMap[curr.id].position as IPosition))];
        acc.undo[curr.id] = [Ops.replace('/position', curr.toJSON().position)];
        return acc;
      },
      { do: {}, undo: {} } as ArtboardPatches,
    );
    this.updateSingleArtboard(this.activeArtboard.artboardID, artboardPatches);
  }

  // 修改组件尺寸
  public sizeChange(
    key: 'width' | 'height' | 'all',
    size: {
      height: number | undefined;
      width: number | undefined;
      lockedRatio: boolean;
    },
    options: {
      allowLockedComp: boolean; // 是否可以改变锁定图层组件的尺寸
      targetComponents?: UIComponent[]; //目标组件集合
    } = { allowLockedComp: false },
  ): ArtboardPatches | undefined {
    const { targetComponents } = options;
    const comps = targetComponents ? targetComponents : this.selectedComponentList.filter((comp) => !comp.autoSize);
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    if (comps.length === 0) {
      this.doSizeChangeWithAutoSizeTextComps(size);
      return;
    }
    //线条的处理需要单独处理,这里先将线条过滤
    comps
      .filter((comp) => comp.type !== 'line')
      .forEach((comp) => {
        const minSize = getMinSizeOfComp(comp);
        const originalSize = comp.size;
        let offset = {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        };
        const { width: originalWidth, height: originalHeight } = originalSize;
        const widthHeightRatio = originalWidth / originalHeight;
        let newWidth = originalWidth;
        let newHeight = originalHeight;
        if (key === 'width') {
          newWidth = size[key]! < minSize.width ? minSize.width : size[key]!;
          if (originalSize.lockedRatio) {
            newHeight = newWidth / widthHeightRatio;
          }
        }
        if (key === 'height') {
          newHeight = size[key]! < minSize.height ? minSize.height : size[key]!;
          if (originalSize.lockedRatio) {
            newWidth = newHeight * widthHeightRatio;
          }
        }
        if (key === 'all') {
          newWidth = size.width || originalWidth;
          newHeight = size.height || originalHeight;
        }
        const isWidthChange = notSameNumber(originalWidth, newWidth);
        const isHeightChange = notSameNumber(originalHeight, newHeight);
        if (isWidthChange) {
          offset.right = newWidth - originalWidth;
        }
        if (isHeightChange) {
          offset.bottom = newHeight - originalHeight;
        }
        const posPatches = comp.parent!.resizeChildren(
          [comp],
          { offset },
          { shift: false, allowLockedComp: options.allowLockedComp },
        );
        coverPatches(patches, posPatches);
      });
    comps
      .filter((comp) => comp.type === 'line')
      .forEach((comp) => {
        // 斜率为正时，高度增减，体现为se点的挪动
        // 斜率为负时，高度增减，体现为sw点的挪动
        // 模拟resize所需要的数据
        const originalPoints = comp.getBoxPointsInArtboard(false);
        const { startPoint: originStartPoint, endPoint: originEndPoint } = comp.value as ILineValue;
        const k = (originStartPoint.y - originEndPoint.y) / (originStartPoint.x - originEndPoint.x);
        const sizeChange = size.width! - comp.size.width;
        const heightChange = size.height! - comp.size.height;
        let dragIndex: SelectionPoints;
        if (k === Infinity) {
          if (isNotEqual0(sizeChange)) {
            dragIndex = SelectionPoints.rightTop;
          } else {
            dragIndex = SelectionPoints.leftBottom;
          }
        } else if (k === -Infinity) {
          dragIndex = SelectionPoints.leftBottom;
        } else if (k === 0) {
          dragIndex = SelectionPoints.rightTop;
          // 斜率为正，那么拖动的就是右下角点
        } else if (k > 0) {
          dragIndex = SelectionPoints.rightBottom;
          // 斜率为负的时候，如果是宽度变化，就拖动右上角点，高度变化，就拖动左下角点
        } else {
          dragIndex = key === 'width' ? SelectionPoints.rightTop : SelectionPoints.leftBottom;
        }
        //模拟线段的拖动点的变化
        let originPoint = originalPoints[dragIndex];
        let newPoint = {
          x: originPoint.x,
          y: originPoint.y,
        };
        if (key === 'width') {
          newPoint.x = originPoint.x + sizeChange;
        } else {
          newPoint.y = originPoint.y + heightChange;
        }
        const isWidthHeightBothChange = comp.size.lockedRatio || false;
        //计算出拖曳后的所有的点
        const newPoints = dragResizePoint(originalPoints, dragIndex, newPoint, isWidthHeightBothChange);
        const dragStartOrEndPointInfo = isDragStartOrEndPoint(comp, originalPoints, dragIndex);
        // 将resize所需要的数据
        const linePatches = getResizeSingLinePatch(comp, originalPoints, {
          newPoints,
          dragIndex,
          dragStartOrEndPointInfo,
        });
        coverPatches(patches, linePatches);
      });
    return patches;
  }

  /**
   * 还原到原始尺寸
   */
  revertOriginSize() {
    const comp = this.firstSelectedComponent;
    if (!comp || comp.locked) {
      return;
    }
    const { type } = comp;
    if (type !== CImage && type !== CSnapshot) {
      return;
    }
    const { value } = comp;
    if (!value) {
      return;
    }

    const applySizeWithImage = (url: string) => {
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;
        applyNewSize({ width, height }, true);
      };
      img.src = url;
    };

    const applyNewSize = (size: ISize, lockedRatio: boolean = false) => {
      const patches = this.sizeChange('all', { lockedRatio, ...size });
      if (patches) {
        this.update({
          [this.activeArtboard.artboardID]: patches,
        });
      }
    };

    if (type === CImage) {
      applySizeWithImage(value as string);
    } else {
      const { cropAreaInfo, url } = comp.value as ISnapshotValue;
      if (cropAreaInfo && cropAreaInfo.height && cropAreaInfo.width) {
        applyNewSize({ width: cropAreaInfo.width, height: cropAreaInfo.height }, comp.size.lockedRatio);
      } else if (url) {
        applySizeWithImage(url);
      }
    }
  }

  /**
   * 设置锚定激活状态
   * @param {ILayout} anchors
   * FIXME 这里就考虑每个选中的组件一个对应的anchors，修改锚定的状态时，不应修改组件原来的坐标，居中情况下除外
   */
  activeAnchors(anchors: ILayout) {
    const comps: UIComponent[] = this.selectedComponentList!;
    if (!editorValidate.allowChildrenAnchorsChange(this.activeContainer.toJSON())) {
      return;
    }
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    //if (comps.length <= 1) {
    comps.forEach((com) => {
      const op = com.setAnchors(anchors);
      patches.do[com.id] = op.do;
      patches.undo[com.id] = op.undo;
    });
    //}
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  /**
   * 切换等比例尺寸
   * @param {boolean} lockedRatio
   */
  lockSizeRatio(lockedRatio: boolean) {
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    // FIXME  此处如果size.lockRatio !== comp.size.lockRatio，且size.lockRatio === true时， 则需要修改comp.anchors
    this.selectedComponentList
      .filter((comp) => comp.canSetSizeLockRatio)
      .forEach((com: UIComponent) => {
        if (!com.autoSize) {
          const op = com.setSize({ ...com.toJSON().size, lockedRatio: lockedRatio });
          patches.do[com.id] = op.do;
          patches.undo[com.id] = op.undo;
        }
      });

    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  /**
   * 对齐组件
   * @param {ComponentAlignType} alignType
   */
  align(alignType: ComponentAlignType) {
    // fix: 锁定图层不参与对其操作
    this.selectedComponentList.filter((t) => t.locked).forEach((t) => this.selectedComponents.delete(t));

    const selectedComponentList = this.selectedComponentList;
    if (!selectedComponentList.length) {
      return;
    }
    if (selectedComponentList.length === 1 && !selectedComponentList[0].isConnector) {
      const comp = selectedComponentList[0];
      const parent = comp.parent!;
      const { size: parentSize } = parent;
      const compBounds = comp.getViewBoundsInParent();
      const boundsSize = {
        height: compBounds.height,
        width: compBounds.width,
      };
      let boundsAfterAlign = depthClone(compBounds);
      switch (alignType) {
        case ComponentAlignType.left:
          // bounds左上角的left应该变为0
          boundsAfterAlign.left = 0;
          break;
        case ComponentAlignType.center:
          boundsAfterAlign.left = parentSize.width / 2 - compBounds.width / 2;
          break;
        case ComponentAlignType.right:
          boundsAfterAlign.left = parentSize.width - compBounds.width;
          break;
        case ComponentAlignType.top:
          boundsAfterAlign.top = 0;
          break;
        case ComponentAlignType.middle:
          boundsAfterAlign.top = parentSize.height / 2 - compBounds.height / 2;
          break;
        case ComponentAlignType.bottom:
          boundsAfterAlign.top = parentSize.height - compBounds.height;
          break;
      }

      const newCenter = getCenter({ x: boundsAfterAlign.left, y: boundsAfterAlign.top }, boundsSize, 0);
      const newPosition = getNWPoint(newCenter, comp.size, 0);
      if (comp.isLayoutCenterAtHorizontal) {
        newPosition.x = comp.position.x;
      }
      if (comp.isLayoutMiddleAtVertical) {
        newPosition.y = comp.position.y;
      }
      const newCompData = {
        id: comp.id,
        type: ComponentChangeType.Edit,
        position: newPosition,
        size: comp.size,
        rotate: comp.rotate,
      };
      const { patches: posPatches } = this.activeContainer.getPositionPatchesOfChildrenChanged([newCompData], true);
      if (this.activeContainer.type === CCompoundPath) {
        const newComps = depthClone(this.activeContainer.components.map((comp) => comp.toJSON()));
        const comp = newComps.find((item) => item._id === newCompData.id);
        comp!.position = newCompData.position;
        comp!.size = newCompData.size;
        comp!.rotate = newCompData.rotate;
        const { newActiveGroup, patches: newPatches } = (this
          .activeContainer as UICompoundPathComponent).refreshPatchesWithNewChildren(newComps);
        if (newActiveGroup && newActiveGroup.id !== this.activeContainer.id) {
          // 如果当前容器被删除了，那么久不合并patches
          posPatches.do = newPatches.do;
          posPatches.undo = newPatches.undo;
          this.setActiveContainer(newActiveGroup);
        } else {
          mergePatches(posPatches, newPatches);
        }
      }
      this.updateSingleArtboard(this.activeArtboard.artboardID, posPatches);
    } else {
      let comps = [...this.selectedComponents];
      // 忽略流程线
      comps = comps.filter((comp: UIComponent) => {
        return !comp.isConnector;
      });
      const firstComp = comps[0];
      comps.shift();
      let { x, y } = firstComp.position;
      let { width, height } = firstComp.size;
      let bound = getDeltaForOrderRotate(width, height, 0, firstComp.rotate);
      //获得锚定左上的right和bottom
      let right = x + width + bound.right;
      let bottom = y + height + bound.bottom;
      x += bound.left;
      y += bound.top;
      comps.forEach((comp) => {
        const compPosition = comp.position;
        const compSize = comp.size;
        bound = getDeltaForOrderRotate(compSize.width, compSize.height, 0, comp.rotate);
        right = max(right, compPosition.x + compSize.width + bound.right);
        bottom = max(bottom, compPosition.y + compSize.height + bound.bottom);
        x = min(x, compPosition.x + bound.left);
        y = min(y, compPosition.y + bound.top);
      });
      width = right - x;
      height = bottom - y;
      const cx = round(x + width / 2);
      const cy = round(y + height / 2);
      comps.unshift(firstComp);

      const futureCompsData = comps
        .filter((comp) => !(comp.isLayoutMiddleAtVertical || comp.isLayoutCenterAtHorizontal))
        .map((comp) => {
          const { size, position, rotate } = comp;
          const offset = { x: 0, y: 0 };
          const bound = getDeltaForOrderRotate(size.width, size.height, 0, rotate);
          switch (alignType) {
            case ComponentAlignType.left:
              offset.x = x - (position.x + bound.left);
              break;
            case ComponentAlignType.center:
              offset.x = cx - round(position.x + bound.left + (size.width - bound.left + bound.right) / 2);
              break;
            case ComponentAlignType.right:
              offset.x = right - (position.x + (size.width + bound.right));
              break;
            case ComponentAlignType.top:
              offset.y = y - (position.y + bound.top);
              break;
            case ComponentAlignType.middle:
              offset.y = cy - round(position.y + bound.top + (size.height - bound.top + bound.bottom) / 2);
              break;
            case ComponentAlignType.bottom:
              offset.y = bottom - (position.y + (size.height + bound.bottom));
              break;
          }
          return {
            id: comp.id,
            type: ComponentChangeType.Edit,
            position: {
              x: comp.position.x + offset.x,
              y: comp.position.y + offset.y,
            },
            size: comp.size,
            rotate: comp.rotate,
          };
        });
      const { patches: posPatches } = this.activeContainer.getPositionPatchesOfChildrenChanged(futureCompsData, true);
      if (this.activeContainer.type === CCompoundPath) {
        const newComps = depthClone(this.activeContainer.components.map((comp) => comp.toJSON()));
        futureCompsData.forEach((change) => {
          const comp = newComps.find((item) => item._id === change.id);
          comp!.position = change.position;
          comp!.size = change.size;
          comp!.rotate = change.rotate;
        });
        const { newActiveGroup, patches: newPatches } = (this
          .activeContainer as UICompoundPathComponent).refreshPatchesWithNewChildren(newComps);
        if (newActiveGroup && newActiveGroup.id !== this.activeContainer.id) {
          // 如果当前容器被删除了，那么久不合并patches
          posPatches.do = newPatches.do;
          posPatches.undo = newPatches.undo;
          this.setActiveContainer(newActiveGroup);
        } else {
          mergePatches(posPatches, newPatches);
        }
      }
      this.updateSingleArtboard(this.activeArtboard.artboardID, posPatches);
    }
  }

  /**
   * 等间距组件
   * @param {"vertical" | "horizontal"} type
   */
  sameGap(type: 'vertical' | 'horizontal') {
    let comps = [...this.allSelectedUnLockedComps];
    if (!comps.length) {
      return;
    }
    // 忽略流程线
    comps = comps.filter((comp: UIComponent) => {
      return !comp.isConnector;
    });
    const matrix = getCompAbsoluteMatrix(this.activeContainer);
    // 计算选区宽度和高度，组件总的宽度和高度，间距等
    const bounds = comps[0].getViewBoundsInArtboard(matrix);
    let { width, height } = bounds;
    let x = bounds.left;
    let y = bounds.top;
    let right = x + width;
    let bottom = y + height;
    let someWidth = width;
    let someHeight = height;

    comps.forEach((comp, index) => {
      if (index === 0) {
        return;
      }
      const compBounds = comp.getViewBoundsInArtboard(matrix);
      const size = {
        height: compBounds.height,
        width: compBounds.width,
      };
      const position = {
        x: compBounds.left,
        y: compBounds.top,
      };
      x = min(x, position.x);
      y = min(y, position.y);
      right = max(right, position.x + size.width);
      bottom = max(bottom, position.y + size.height);
      someWidth += size.width;
      someHeight += size.height;
    });
    width = right - x;
    height = bottom - y;
    const horizontalGap = round((width - someWidth) / (comps.length - 1));
    const verticalGap = round((height - someHeight) / (comps.length - 1));

    /*
       1、根据空间坐标对所有组件排序
       2、设置新坐标
     */
    // 排序组件
    comps.sort((comp1, comp2) => {
      const p1 = comp1.positionRelativeToArtboard;
      const p2 = comp2.positionRelativeToArtboard;
      switch (type) {
        case 'vertical':
          return p1.y - p2.y;
        case 'horizontal':
          return p1.x - p2.x;
        default:
          return 0;
      }
    });
    const first = comps[0];

    const firstBounds = first.getViewBoundsInArtboard(matrix);
    const position = { x: firstBounds.left, y: firstBounds.top };
    const size = { width: firstBounds.width, height: firstBounds.height };
    // 首尾的不需要处理
    comps.shift();
    // comps.pop();
    x = position.x + size.width + horizontalGap;
    y = position.y + size.height + verticalGap;

    const futureCompsData = comps
      .filter((comp) => !(comp.isLayoutMiddleAtVertical || comp.isLayoutCenterAtHorizontal))
      .map((comp) => {
        const offset = { x: 0, y: 0 };
        const compBounds = comp.getViewBoundsInArtboard(matrix);
        let pt = { x: compBounds.left, y: compBounds.top };
        let siz = { width: compBounds.width, height: compBounds.height };
        const center = getCenter(pt, siz, 0);

        if (type === 'vertical') {
          offset.y = y - pt.y;
          y = y + siz.height + verticalGap;
        } else {
          offset.x = x - pt.x;
          x = x + siz.width + horizontalGap;
        }
        const newCenter = {
          x: center.x + offset.x,
          y: center.y + offset.y,
        };
        const centerInGroup = tansPointInArtBoardToGroup([newCenter], comp.parent!)[0];
        const newPosition = getNWPoint(centerInGroup, comp.size, 0);
        return {
          id: comp.id,
          type: ComponentChangeType.Edit,
          position: newPosition,
          size: comp.size,
          rotate: comp.rotate,
        };
      });
    const { patches } = this.activeContainer.getPositionPatchesOfChildrenChanged(futureCompsData, true);
    if (this.activeContainer.type === CCompoundPath) {
      const newComps = depthClone(this.activeContainer.components.map((comp) => comp.toJSON()));
      futureCompsData.forEach((change) => {
        const comp = newComps.find((item) => item._id === change.id);
        comp!.position = change.position;
        comp!.size = change.size;
        comp!.rotate = change.rotate;
      });
      const { newActiveGroup, patches: newPatches } = (this
        .activeContainer as UICompoundPathComponent).refreshPatchesWithNewChildren(newComps);
      if (newActiveGroup && newActiveGroup.id !== this.activeContainer.id) {
        // 如果当前容器被删除了，那么久不合并patches
        patches.do = newPatches.do;
        patches.undo = newPatches.undo;
        this.setActiveContainer(newActiveGroup);
      } else {
        mergePatches(patches, newPatches);
      }
    }
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  /**
   * 等尺寸组件，当前选框内，组件改变尺寸，如果是组，则改变内部组件位置，以让组改变尺寸
   * @param {"minWidth" | "maxWidth" | "minHeight" | "maxHeight"} type
   */
  sameSize(type: 'minWidth' | 'maxWidth' | 'minHeight' | 'maxHeight') {
    const comps = [...this.allSelectedUnLockedComps];
    let { width, height } = comps[0].size;
    let minWidth = width;
    let maxWidth = width;
    let minHeight = height;
    let maxHeight = height;
    comps.forEach((comp) => {
      const size = getViewBoundsOfComponents([comp])!;
      minWidth = min(minWidth, size.width);
      maxWidth = max(maxWidth, size.width);
      minHeight = min(minHeight, size.height);
      maxHeight = max(maxHeight, size.height);
    });

    const offsets: IBoundsOffset[] = [];
    comps.forEach((curr) => {
      const offsetBounds = { left: 0, right: 0, top: 0, bottom: 0 };

      const size = getViewBoundsOfComponents([curr])!;
      switch (type) {
        case 'minWidth':
          offsetBounds.right = minWidth - size.width;
          break;
        case 'minHeight':
          offsetBounds.bottom = minHeight - size.height;
          break;
        case 'maxWidth':
          offsetBounds.right = maxWidth - size.width;
          break;
        case 'maxHeight':
          offsetBounds.bottom = maxHeight - size.height;
          break;
      }
      offsets.push(offsetBounds);
    });
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    const newCompsData = comps.map((comp, index) => {
      const offset = offsets[index];
      const bounds = getViewBoundsOfComponents([comp])!;
      const layout: IBasicLayout = {
        fixedWidth: false,
        fixedHeight: false,
        horizontal: HorizontalAlign.LeftAndRight,
        vertical: VerticalAlign.TopAndBottom,
      };
      const childrenResizeOptions = {
        container: {
          before: {
            position: {
              x: bounds.left,
              y: bounds.top,
            },
            size: {
              width: bounds.width,
              height: bounds.height,
            },
          },
          after: {
            position: {
              x: bounds.left + offset.left,
              y: bounds.top + offset.top,
            },
            size: {
              width: bounds.width + offset.right - offset.left,
              height: bounds.height + offset.bottom - offset.top,
            },
          },
          isResponsive: true,
        },
        shift: false,
        scale: {
          h: (bounds.width + offset.right - offset.left) / bounds.width,
          v: (bounds.height + offset.bottom - offset.top) / bounds.height,
        },
      };
      const res = comp.resizeHandler2(offset, layout, childrenResizeOptions);
      if (res.patches) {
        coverPatches(patches, res.patches);
      }
      return {
        id: comp.id,
        type: ComponentChangeType.Edit,
        size: res.size,
        position: res.position,
        rotate: res.rotate,
      };
    });
    const res = this.activeContainer.getPositionPatchesOfChildrenChanged(newCompsData, true);
    coverPatches(patches, res.patches);
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  /**
   * 组件重新渲染，更新视图
   * @param comps
   * @param onlyBounds
   */
  notifyCompsToReRender(comps: UIComponent[], onlyBounds?: boolean, includeChild?: boolean) {
    // 少量组件更新组件，大量组件更新页面
    if (comps.length <= 10) {
      reRenderComps(comps, onlyBounds, includeChild);
    } else {
      comps.forEach((comp) => comp.updateVision());
      this.notifyUpdateListener();
    }
  }

  /**
   * 选中的组件启用异步渲染方式 （推荐）
   * @param comps
   * @param onlyBounds
   */
  notifyBatchCompsToReRender(comps: UIComponent[], onlyBounds?: boolean) {
    unstable_batchedUpdates(() => {
      reRenderComps(comps, onlyBounds);
    });
  }

  /**
   * 缩放组件
   * @param originSize 原始大小
   * @param newSize 新的大小
   * @param option.transformOrigin 固定点:例如左上,右上等
   * @param option.changeRadius 是否缩放圆角
   * @param option.changeShadow 是否缩放阴影
   */
  zoomComponent(
    originSize: ISize,
    newSize: ISize,
    option: {
      transformOrigin: TransformOriginType;
      changeRadius?: boolean;
      changeShadow?: boolean;
      scalePercent?: number;
    },
  ) {
    const offset = getOffsetByNewSizeAndFixPoint(
      originSize,
      { ...newSize },
      option.transformOrigin,
      this.selectedComponentList,
      true,
    );
    const patches = this.activeContainer.resizeChildren(
      this.selectedComponentList,
      { offset },
      {
        shift: true,
        isScale: true,
        changeRadius: option.changeRadius,
        changeShadow: option.changeShadow,
        scalePercent: option.scalePercent,
      },
    );
    this.update({
      [this.activeArtboard.artboardID]: patches,
    });
  }

  handleCompsToBeDeleted(confirmDeleteComps: UIComponent[]) {
    const newPatches: ArtboardPatches = { do: {}, undo: {} };
    confirmDeleteComps.forEach((comp) => {
      if (comp.parent?.isArtboard) {
        const addOperation = convertRemovedComponentsToAddOps(comp.parent, [comp]);
        newPatches.do[comp.parent.id] = [...(newPatches.do[comp.parent.id] || []), Ops.removeChildren([comp.id])];
        newPatches.undo[comp.parent.id] = [...(newPatches.undo[comp.parent.id] || []), ...addOperation];
        this.setActiveContainer(comp.parent);
      } else {
        const data = comp.parent!.removeComponents([comp]);
        if (data) mergePatches(newPatches, data.patches[this.activeArtboard.artboardID]);
      }
    });
    this.update({
      [this.activeArtboard.artboardID]: newPatches,
    });
  }

  setListItemsValue(
    comp: UIContainerComponent,
    newValue: string,
    libInfo: {
      id: string;
      type: string;
    },
  ) {
    const patches = setListItemsValue(comp as UIContainerComponent, newValue.trim(), libInfo);
    if (patches) {
      this.update(patches);
    }
  }

  createSelectionGroup(selectionGroupSetting: SelectionGroupSetting) {
    const firstSelectedComponent = this.firstSelectedComponent;

    if (!firstSelectedComponent) {
      return;
    }

    //生成一个二维数组
    const item = firstSelectedComponent;

    let item1 = cloneDeep(item.toJSON());
    item1.selected = false;
    let activeContainer = this.activeContainer;
    const addedCompData = getNewCreatedSGData(item1, selectionGroupSetting, firstSelectedComponent.position);
    //获取添加 patch

    const patches = new ArtboardPatchesClass();
    const { patches: addPatches } = activeContainer.addComponents(
      [addedCompData],
      activeContainer.components.findIndex((child) => child.id === firstSelectedComponent.id),
    );
    //获取删除老组件的 patch
    const { patches: removePatch } = activeContainer.removeComponents([firstSelectedComponent]);
    const artboardID = this.activeArtboard.artboardID;

    [addPatches, removePatch].forEach((p) => coverPatches(patches, p[artboardID]));

    //跟新 patch
    this.update({
      [artboardID]: patches,
    });
    this.selectByIDs([addedCompData._id], false);
  }
}
