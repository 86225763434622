// 不要class id 属性, 调试时候打开
export const deleteUselessAttributes = (clone: HTMLElement): void => {
  const flag = true;
  if (flag) {
    clone.removeAttribute('class');
    clone.removeAttribute('id');
  }
};

// 结合具体业务场景,调整组件样式  组件样式调整
export const adjustComponentStyle = (original: Element, target: CSSStyleDeclaration): void => {
  if (original.className === 'lib-comp-checkbox-item-box') {
    setFlexBoxCenter(target);
  }
};

// 居中
const setFlexBoxCenter = (target: CSSStyleDeclaration): void => {
  target.setProperty('display', 'flex');
  target.setProperty('align-items', 'center');
  target.setProperty('justify-content', 'center');
};
