export type GeneralPropertyName = 'name' | 'size' | 'rotate' | 'hidden' | 'opacity' | 'disabled' | 'selected';

export enum TransformOriginType {
  NW = 'nw',
  NE = 'ne',
  SE = 'se',
  SW = 'sw',
  N = 'n',
  E = 'e',
  S = 's',
  W = 'w',
  MIDDLE = 'middle',
}
