import { IComponentData } from '@/fbs/rp/models/component';
import { EChartType_basic } from '@/fbs/rp/models/properties/chart';
import { IChartValue } from '@/fbs/rp/models/chart';
import { makeCommonComponent } from '@/libs/helper';

import { IComponentItem } from '../../../types';
import { CRadarChart } from '../../../constants';
import { getChartConfig, makeChartData, getSeriesChartOneDefaultData } from '../common';

export const RadarChartConfig: IComponentItem = getChartConfig(CRadarChart, EChartType_basic.Radar);

export function makeRadarChart(id: string): IComponentData {
  return makeChartData(id, CRadarChart, EChartType_basic.Radar);
}

export function makeAIRadarChart(id: string, value: IChartValue): IComponentData {
  return makeCommonComponent(id, CRadarChart, {
    ...getSeriesChartOneDefaultData(CRadarChart, EChartType_basic.Radar),
    size: {
      width: 400,
      height: 250,
    },
    lib: {
      id: 'chart',
      type: CRadarChart,
    },
    value: value,
  });
}
