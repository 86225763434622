import * as React from 'react';

import { isEqual } from 'lodash';

import { measureTextSize } from '@/utils/textUtils';

interface MenuTextProps {
  value: string;
  defaultTextWidth: number;
  level: number;
  textStyle: React.CSSProperties;
}

function calcTextWidth(textStyle: React.CSSProperties, value: string) {
  return measureTextSize(textStyle, value).width / 2;
}

/**
 * MenuText文本缩小2倍，但所占位置仍为原始空间，需要在文本展示标签上添加一层div且固定为展示宽度，使整个文本组件的宽度为实际展示宽度
 * 当前编辑器操作并无法保证属性修改获取的文本宽度完全正确，直接从数据上取的textSize当前已经不准确了，需要计算取出
 * componentWillReceiveProps减少计算次数
 */
class MenuText extends React.Component<MenuTextProps> {
  private textWidth: number | undefined;

  constructor(props: MenuTextProps) {
    super(props);
    // 首层可不需要固定宽度，在更高层的标签内固定了宽度，且溢出隐藏
    // 弹出层宽度自适应，需要给文本固定宽度，否则文本的宽展示上被放大一倍
    this.textWidth = props.level === 0 ? undefined : calcTextWidth(props.textStyle, props.value);
  }

  UNSAFE_componentWillReceiveProps(nextProps: MenuTextProps) {
    if (
      nextProps.level !== 0 &&
      (!isEqual(nextProps.textStyle, this.props.textStyle) || !isEqual(nextProps.value, this.props.value))
    ) {
      this.textWidth = calcTextWidth(nextProps.textStyle, nextProps.value);
    }
  }

  render() {
    const { textStyle, value } = this.props;
    return (
      <div className="comp-vertical-menu-textBox" style={{ width: this.textWidth }}>
        <div className="comp-vertical-menu-text" style={textStyle}>
          {value}
        </div>
      </div>
    );
  }
}

export default MenuText;
