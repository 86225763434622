import { ArtboardPatches } from '@fbs/rp/utils/patch';
import { PropertyValue } from '@fbs/rp/models/property';
import { default as PropertyBase, IRange } from '@fbs/rp/models/properties/base';

import i18n from '@i18n';

import { UIComponent, UIContainerComponent } from '@editor/comps';
import { ComponentChangeType } from '@editor/comps/resizeHelper';
import { PredefinedStates } from '@consts/state';
import { SpriteThumb } from '@consts/spriteIcons';
import { getDefaultDataFromTemplate } from '@/libs/helper';

import { IComponentItem } from '../../types';
import { MoveMode, SizeMode } from '../../enum';

function onPropertyUpdate(comp: UIComponent, key: string, value: PropertyValue): ArtboardPatches | null {
  if (key === 'progress') {
    const range = (value as PropertyBase).value as IRange;
    const { width } = comp.size;
    const group = comp as UIContainerComponent;
    const progress = group.getComponentByAlias('progress', true);
    if (progress) {
      const { position, size } = progress;
      const maxWidth = width - position.x * 2;
      const newWidth = Math.round((maxWidth * range.value) / 100);
      const { patches } = group.getPositionPatchesOfChildrenChanged(
        [
          {
            type: ComponentChangeType.Edit,
            id: progress.id,
            position,
            size: { ...size, width: newWidth },
            rotate: 0,
          },
        ],
        false,
      );
      return patches;
    }
  }
  return null;
}

export const ProgressBarCfg: IComponentItem = {
  type: 'progressBar',
  name: i18n('resource.components.progressBar'),
  thumb: {
    spriteIconClass: SpriteThumb.Progress.className,
    dragPosition: SpriteThumb.Progress.position,
  },
  predefinedStates: [PredefinedStates.disabled],
  constraint: {
    progress: {
      move: MoveMode.neither,
      resize: SizeMode.none,
    },
  },
  template: `{
    type: @@C.CanvasPanel,
    size:{width: 300,height: 3},
    layout:{
      vertical: 'middle',
      horizontal: 'left',
      fixedWidth: true,
      fixedHeight: true,
      auto: true,
      responsive: true,
    },
    sealed: true,
    properties: {
      fill: {
        name: '${i18n('property.propertyNames.trackFill')}',
        type: @@FillType.solid,
        color: @@SystemColors.SilverWhiteColor,
        disabled: false,
      },
      stroke: {
        name: '${i18n('property.propertyNames.trackStyle')}',
        thickness: 1,
        color: @@SystemColors.GrayColor,
        disabled: true,
      },
      indicatorColor: {
        prop: 'fill',
        name: '${i18n('property.propertyNames.indicatorColor')}',
        type: @@FillType.solid,
        color: @@SystemColors.DeepBlueColor,
        disabled: false,
      },
      radius: {
        topLeft: 100,
        topRight: 100,
        bottomLeft: 100,
        bottomRight: 100,
        isPercent: true,
        disabled: false,
      },
      progress:{
        prop: 'number',
        name: '${i18n('property.propertyNames.progress')}',
        value:{
          max: 100,
          min: 0,
          value: 70,
          unit: '%'
        }
      },
    },
    states:{
      disabled:{
        enabled: false,
        properties:{
          indicatorColor: {
            type: @@FillType.solid,
            color: @@SystemColors.HalfTransparentDarkGreenColor,
          },
        },
      },
    },
    components: [
    {
      type: @@C.Rect,
      alias: 'progress',
      layout:{
        horizontal: 'left',
        vertical: 'top&bottom',
        fixedWidth: false,
        fixedHeight: false,
        auto: false,
        responsive: true,
      },
      position:{x: 0, y: 0},
      size: {width: ${Math.round(300 * 0.7)}, height: 3},
      properties: {
        fill: { ref: '@properties.indicatorColor' },
        radius: { ref: '@properties.radius' },
        stroke: {
          disabled: true,
        },
      },
    }],
  }`,
  editor: {
    onPropertyUpdate,
  },
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
};

const defaultData = getDefaultDataFromTemplate(ProgressBarCfg);
