import * as React from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';

import { styleSpace } from '../../constants';

import { Link, Icon, MatchStrSpan } from '../../index';

import './index.scss';

interface ITopMessageTipsProps {
  showClose?: boolean;
  linkText?: string;
  textInfo?: string;
  targetStr?: string;
  targetClassName?: string;
  onClick?(): void;
  onClose?(): void;
  onClickTargetStr?(): void;
  children?: React.ReactChild;
  className?: string;
  /**
   * 排序，数字越大排在越下面
   */
  order?: number;
}

const TopMessageTips: React.FC<ITopMessageTipsProps> = (props: ITopMessageTipsProps) => {
  const {
    showClose,
    linkText,
    textInfo,
    targetStr,
    targetClassName,
    onClick,
    onClose,
    onClickTargetStr,
    children,
    order = 0,
  } = props;

  const [box, setBox] = React.useState<HTMLElement | null>(null);
  const [close, setClose] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    checkBox();
  }, []);

  const checkBox = () => {
    const boxDom = document.getElementById('dsm-c-top-message-tip-box') || null;
    if (!boxDom) {
      const addBoxDom = document.createElement('div');
      addBoxDom.setAttribute('id', 'dsm-c-top-message-tip-box');
      document.body.appendChild(addBoxDom);
      setBox(addBoxDom);
    } else {
      setBox(boxDom);
    }
  };

  const handleClose = () => {
    setClose(true);
    if (ref.current) {
      ref.current.addEventListener('animationend', () => {
        onClose && onClose();
      });
    }
  };

  if (!box) return null;

  return createPortal(
    <div
      className={classnames('dsm-c-top-message-tip', { 'dsm-c-top-message-tip-close': close })}
      style={{ order }}
      ref={ref}
    >
      <div className="text-container">
        {children ? (
          children
        ) : (
          <>
            <div className="text-info">
              <MatchStrSpan
                str={textInfo || ''}
                targetStr={targetStr}
                targetClassName={targetClassName}
                onClickTarget={onClickTargetStr}
              />
            </div>
            {linkText && <Link onClick={onClick}>{linkText}</Link>}
          </>
        )}
      </div>
      {showClose && (
        <div className="close-btn">
          <Icon
            cls="demo_close"
            size={styleSpace.space8}
            clickArea={{ width: styleSpace.space16, height: styleSpace.space16 }}
            onClick={handleClose}
          />
        </div>
      )}
    </div>,
    box,
  );
};

TopMessageTips.defaultProps = {
  showClose: false,
  linkText: '',
  textInfo: '',
  targetStr: '',
  order: 0,
};

export default TopMessageTips;
