import i18n from '@i18n';
import { IPathValue } from '@fbs/rp/models/value';
import { scalePath } from '@helpers/pathHelper';
import { IComponentData } from '@fbs/rp/models/component';
import { UIComponent } from '@editor/comps';
import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import ValueEditorType from '@consts/enums/valueEditorType';
import { SpriteThumb } from '@/consts/spriteIcons';

import { IComponentItem } from '../types';
import { CPath, CCompoundPath } from '../constants';
import { getDefaultDataFromTemplate } from '../helper';

// const updatePath = (pathValue: IPathValue, oldSize: ISize, newSize: ISize) => {
//   const scale = {
//     x: newSize.width / oldSize.width,
//     y: newSize.height / oldSize.height,
//   };
//   return scalePath(pathValue, scale);
// };

export const DropsCfg: IComponentItem = {
  type: 'drops',
  name: `${i18n('resource.components.drop')}`,
  thumb: {
    spriteIconClass: SpriteThumb.Drops.className,
    dragPosition: SpriteThumb.Drops.position,
  },
  shortCut: 'D',
  predefinedStates: ['hover', 'pressed', 'disabled'],
  isTextComp: true,
  initialization: (appType, data, container, size) => {
    const oldSize = data.size;
    if (size) {
      const scale = {
        x: size.width / oldSize.width,
        y: size.height / oldSize.height,
      };

      const doScaleValue = (comp: IComponentData) => {
        const paths: IPathValue[] = [];
        if (comp.type === CPath) {
          const value = comp.value as IPathValue;
          paths.push(value);
        } else if (comp.type === CCompoundPath) {
          paths.push(...(comp.value as IPathValue[]));
        }
        const newPaths = paths.map((p) => {
          return scalePath(p, scale);
        });
        if (newPaths.length) {
          if (comp.type === CPath) {
            comp.value = newPaths[0];
          } else if (comp.type === CCompoundPath) {
            comp.value = [...newPaths];
          }
        }
      };
      doScaleValue(data);
      data.components?.forEach((comp) => {
        doScaleValue(comp);
        comp.size.width *= scale.x;
        comp.size.height *= scale.y;
        comp.position.x *= scale.x;
        comp.position.y *= scale.y;
      });
      if (data.properties.padding) {
        data.properties.padding.bottom = Math.round(size.height / 3);
      }
    }
  },
  value: {
    type: ValueEditorType.RichText,
    getValue: (comp: UIComponent): any => {
      return comp.text;
    },
    setValue: (comp: UIComponent, value: any): ArtboardPatches | null => {
      const { text } = comp;
      const path = comp.getCurrentPropertiesPath('/text');
      return {
        do: {
          [comp.id]: [Ops.replace(path, value)],
        },
        undo: {
          [comp.id]: [Ops.replace(path, text)],
        },
      };
    },
  },
  editor: {
    // eslint-disable-next-line no-unused-vars
    onValidateAllowEditor: () => {
      return 'value';
    },
  },
  property: {
    disabledFlip: true,
  },
  template: `{
    type: @@C.Path,
    fixContent: true,
    connectors: [],
    layout: {
      responsive: true,
      auto: true,
      fixedWidth: false,
      fixedHeight: false,
      horizontal: 'auto',
      vertical: 'auto'
    },
    properties: {
      fill: {
        disabled: false,
        type: @@FillType.solid,
        color: @@SystemColors.DeepBlueColor,
      },
      stroke: {
        thickness: 1,
        color: { r: 119, g: 119, b: 119, a: 1 },
        disabled: true
      },
      textFormat:{
        disabled: false,
        fontFamily: 'Microsoft YaHei',
        fontSize: 14,
        textAlign: @@TextAlign.center,
        color: {r: 255, g: 255, b: 255, a: 1},
        fontStyle: {
          bold: true,
          italic: false,
          underline: false,
          strike: false,
        },
        letterSpace: 0,
        wrap: true,
        isMulti: true,        
      },
      shadow: {
        disabled: false,
        blur: 4,
        x: 0,
        y: 2,
        color: {r: 0, g: 0, b: 0, a: 0.3}
      },
      padding:{
        hidden: true,
        top: 0,
        bottom: 13,
        left: 0,
        right: 0
      },
    },
    size: {
      width: 30,
      height: 40
    },
    value: {
      data: [
        {
          point: {  x: 15,  y: 0},
          handleIn: {  x: -5,  y: 0},
          handleOut: {  x: 4,  y: 0}
        }, 
        {
          point: {    x: 30,    y: 15  },
          handleIn: {    x: 0,    y: -12  },
          handleOut: {    x: 0,    y: 12  }
        }, {
          point: {    x: 15,    y: 40  },
          handleIn: {    x: 0,    y: 0  },
          handleOut: {    x: 0,    y: 0  }
        }, {
          point: {    x: 0,    y: 15  },
          handleIn: {    x: 0,    y: 12  },
          handleOut: {    x: 0,    y: -12  }
        }
      ],
      closed: true
    },
    text: '',
  }`,
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
};

const defaultData = getDefaultDataFromTemplate(DropsCfg);
