export enum PreviewUrlSearchKey {
  Lark = 'lark',
  PhoneUrl = 'phoneUrl',
  ShowHiddenPages = 'showHiddenPages',
  // 显示画板外内容
  ShowOutside = 'out',
  // 设备类型
  DeviceType = 'dt',
  // 自适应尺寸
  FitToScreen = 'fc',
  // 始终显示交互区域
  AlwayshowLinkArea = 'la',
  // 鼠标经过显示交互区域
  ShowLinkAreaWhenMouseHover = 'ha',
  // 是否显示备注编号
  ShowRemarkTag = 'rt',
  // TODO 删掉 ShowControllerPanel，现已改用 ControlPanelState
  // 显示右侧控制面板
  ShowControllerPanel = 'ps',
  // 控制面板的状态：收起，展开，隐藏
  ControlPanelState = 'cps',
  // 注释面板的状态：收起，展开，隐藏
  RemarkPanelState = 'rps',
  // 显示导航条
  ShowNavigationBar = 'nav',
  // 手机端演示的语言类型
  XMockplusLang = 'x-mockplus-lang',
}

export enum MobileType {
  IphoneX = 'iphoneX',
  Iphone = 'iphone',
  Pad = 'pad',
  iPhone_black = 'iPhone_Black',
  Galaxy = 'Galaxy',
  HUAWEI_Mate = 'HUAWEI_Mate',
  HUAWEI_P40 = 'HUAWEI_P40',
  XiaoMi = 'XiaoMi',
  Surface_Pro = 'SurfacePro',
  Vehicle = 'Vehicle',
  Apple_Watch_38mm = 'Apple_Watch_38mm',
  Apple_Watch_40mm = 'Apple_Watch_40mm',
  Apple_Watch_42mm = 'Apple_Watch_42mm',
  Apple_Watch_44mm = 'Apple_Watch_44mm',
  Apple_Watch_45mm = 'Apple_Watch_45mm',
  Apple_Watch_49mm = 'Apple_Watch_49mm',
  None = 'none',
}
