import { ITeams } from '@fbs/idoc/models/team';
import * as team from '../team';
// import { getOfflineDemoData } from './data';
import { IAppPages } from '@fbs/rp/models/embedDesignDraft';

import { getSnapshot } from './helper';

/**
 * 获取团队信息(离线演示包)
 * @param {string} teamID
 * @returns {Promise<ITeams[]>}
 */
async function getTeamInfo(): Promise<ITeams> {
  return new Promise<ITeams>((resolve) => {
    resolve(undefined);
  });
}

/**
 * 项目中中获取存储的快照页面资源(离线演示包)
 */
function getSnapshotAllPages(): Promise<IAppPages> {
  return getSnapshot();
  // return new Promise((resolve, reject) => {
  //   const snapshotPages = getOfflineDemoData()?.snapshotPages;
  //   if (snapshotPages) {
  //     resolve(snapshotPages);
  //   } else {
  //     reject();
  //   }
  // });
}

export default Object.assign({}, team, {
  getTeamInfo,
  getSnapshotAllPages,
});
