export interface IMakeRegForFilter {
  (names: string[]): RegExp;
}
export const makeRegByNames: IMakeRegForFilter = (names: string[]) => {
  return new RegExp(names.join('|'), 'i');
};

/**
 * 获取样式
 * @param styleSheets
 * @returns
 */
export function getCssRules(names: string[], makRegForFilter?: IMakeRegForFilter): CSSStyleRule[] {
  const result: CSSStyleRule[] = [];
  const reg = (makRegForFilter ?? makeRegByNames)(names);
  const cssfilter = (rule: CSSStyleRule, reg: RegExp) => {
    const { selectorText } = rule;
    // 不用交互
    if (/:hover|:focus/.test(selectorText)) {
      return false;
    }
    return reg.test(selectorText);
  };
  const styleSheets = Array.from(document.styleSheets);
  styleSheets.forEach((sheet: CSSStyleSheet) => {
    try {
      const cssRules = Array.from(sheet.cssRules);
      cssRules.forEach((rule: CSSRule) => {
        if (rule instanceof CSSStyleRule) {
          if (cssfilter(rule, reg)) {
            result.push(rule);
          }
        }
      });
    } catch (e) {
      // console.log('Error while reading CSS rules from ' + sheet.href, e.toString());
    }
  });

  return result;
}
