import * as React from 'react';
import classnames from 'classnames';

import { LoadingPointPic } from '../../constants/Loading/loading-point';

import './index.scss';

interface ILoadingPointsProps {
  showMask?: boolean;
  showShadow?: boolean;
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
  style?: React.CSSProperties;
  pastDelay?: number;
}

const LoadingPoints: React.FC<ILoadingPointsProps> = (props: ILoadingPointsProps) => {
  const { showMask, showShadow, position, style, pastDelay } = props;

  const [show, setShow] = React.useState(!pastDelay || false);
  let timer: number | null = null;

  React.useEffect(() => {
    if (pastDelay) {
      timer = window.setTimeout(() => {
        !show && setShow(true);
      }, pastDelay);

      return () => {
        timer && window.clearTimeout(timer);
      };
    }
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div
      className={classnames('dsm-c-loading-points', {
        mask: showMask,
        shadow: showShadow,
      })}
      style={{ position, ...style }}
    >
      <div className="loading-container">
        <img src={LoadingPointPic} alt="" />
      </div>
    </div>
  );
};
LoadingPoints.defaultProps = {
  showMask: false,
  showShadow: false,
  position: 'fixed',
  style: {},
  pastDelay: 200,
};

export default LoadingPoints;
