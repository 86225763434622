import * as React from 'react';

import InvitationProgress from './InvitationProgress';

import './index.scss';

interface IBasicInfoProps {
  invitedCount: number;
  isNewRpActivity: boolean;
  hasGotPackage: boolean;
  expireTime?: Date;
  viewAllInvitation(): void;
}

const BasicInfo: React.FC<IBasicInfoProps> = (props: IBasicInfoProps) => {
  const { invitedCount, isNewRpActivity, hasGotPackage, expireTime, viewAllInvitation } = props;
  // const renderConcept = () => {
  //   return (
  //     <div className="concept-container">
  //       <div className="title">{i18n('rpActivity.whatUnlimitedDesign')}</div>
  //       <div className="desc">{i18n('rpActivity.definitionOfUnlimitedDesign')}</div>
  //     </div>
  //   );
  // };

  return (
    <div className="rp-free-basic-info">
      {/* {renderConcept()} */}
      <InvitationProgress
        isNewRpActivity={isNewRpActivity}
        invitedCount={invitedCount}
        hasGotPackage={hasGotPackage}
        expireTime={expireTime}
        viewAllInvitation={viewAllInvitation}
      />
    </div>
  );
};

export default BasicInfo;
