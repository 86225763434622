import * as React from 'react';
import * as tinycolor from 'tinycolor2';

import { checkRange, IColorInfo } from './models';

interface IHueProps {
  color: IColorInfo;

  changeColor(color: tinycolor.ColorFormats.HSVA): void;
}

class Hue extends React.Component<IHueProps> {
  container: HTMLElement | null = null;

  constructor(props: IHueProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.unbindEventListeners = this.unbindEventListeners.bind(this);
  }

  handleMouseDown() {
    window.addEventListener('mousemove', this.handleChange);
    window.addEventListener('mouseup', this.handleChange);
    window.addEventListener('mouseup', this.unbindEventListeners);
  }

  unbindEventListeners() {
    window.removeEventListener('mousemove', this.handleChange);
    window.removeEventListener('mouseup', this.handleChange);
    window.removeEventListener('mouseup', this.unbindEventListeners);
  }

  handleChange(e: MouseEvent) {
    e.preventDefault();
    const container = this.container;
    if (!container) {
      return;
    }
    const containerWidth = container.clientWidth;
    // const containerHeight = container.clientHeight;

    const rect = container.getBoundingClientRect();
    const offsetX = rect.left + (window.scrollX || window.pageXOffset);
    // const offsetY = rect.top + (window.scrollY || window.pageYOffset);
    let left = e.pageX - offsetX;
    // let top = e.pageY - offsetY;

    left = checkRange(left, [0, containerWidth]);
    // top = checkRange(top, [0, containerHeight]);

    let h;

    if (left < 0) {
      h = 0;
    } else if (left > containerWidth) {
      h = 360;
    } else {
      h = (left / containerWidth) * 360;
    }

    this.changeColor({
      ...this.props.color.hsv,
      h,
    });
  }

  changeColor(param: tinycolor.ColorFormats.HSVA) {
    this.props.changeColor(param);
  }

  render() {
    const pickerStyle = {
      left: `${(this.props.color.hsv.h / 360) * 100}%`,
    };
    return (
      <div className="c-hue-wrap" ref={(div) => (this.container = div)} onMouseDown={this.handleMouseDown}>
        <div className="hue-pointer">
          <div className="hue-picker" style={pickerStyle} />
        </div>
      </div>
    );
  }
}

export default Hue;
