import * as React from 'react';

import * as echarts from 'echarts';
import classnames from 'classnames';
import { cloneDeep, debounce, isEqual, isUndefined } from 'lodash';

import { IProperties } from '@/fbs/rp/models/property';
import { IComponentValue } from '@/fbs/rp/models/value';
import { IComponentSize } from '@/fbs/rp/models/component';
import { StyleHelper } from '@/helpers/styleHelper';
import { PureColor, rgba2hex } from '@/utils/graphicsUtils';
import { IChartValue } from '@/fbs/rp/models/chart';
import {
  EChartDataLabelType,
  EChartType_pie,
  ELegendPosition,
  IChartDataLabel,
  ILegendPosition,
  IChartPopup,
} from '@/fbs/rp/models/properties/chart';
import { IBoundsOffset } from '@/fbs/common/models/common';

import { IComponentProps } from '../../types';
import { DefaultChartColorList, setChartsTooltip } from '../common';
import { getLegendSize } from '../SeriesChartOne/common';

import './index.scss';

interface IPieChartState {
  boxStyle: React.CSSProperties;
}
interface IChartDataType {
  name: string;
  value: number;
  itemStyle: { color: string };
}
export default class PieChart extends React.Component<IComponentProps, IPieChartState> {
  properties?: IProperties;
  value?: IComponentValue;
  size?: IComponentSize;
  chartInstance?: echarts.ECharts;
  opacity?: number;
  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      boxStyle: this.parserStyle(props),
    };
  }

  chartDomID = `pie-chart-${this.props.comp.id}-${Date.now()}`;

  componentDidMount() {
    const dom = document.getElementById(this.chartDomID);
    if (!dom) {
      return;
    }

    this.chartInstance = echarts.init(dom, undefined, {
      renderer: 'svg',
    });
    window.requestAnimationFrame(() => {
      this.renderPieChartChart(this.props);
    });
  }

  componentWillUnmount() {
    const { chartInstance: chart } = this;
    if (chart) chart.dispose();
  }

  private parserStyle = (props: IComponentProps): React.CSSProperties => {
    const { properties, size, opacity } = props.comp;
    const parser = StyleHelper.initCSSStyleParser(properties);
    return {
      ...parser.getFillStyle(),
      ...parser.getShadowStyle(),
      ...parser.getStrokeStyle(),
      ...parser.getRadiusStyle(size),
      opacity: isUndefined(opacity) ? 1 : opacity / 100,
    };
  };

  debouncedRenderBarChart = debounce((nextProps) => {
    this.setState({ boxStyle: this.parserStyle(nextProps) });
    this.renderPieChartChart(nextProps);
  }, 0);

  UNSAFE_componentWillReceiveProps(nextProps: IComponentProps) {
    const { properties, size, value, opacity } = nextProps.comp;
    if (
      !isEqual(this.properties, properties) ||
      !isEqual(this.size, size) ||
      !isEqual(this.value, value) ||
      !isEqual(this.opacity, opacity)
    ) {
      this.debouncedRenderBarChart(nextProps);
      this.properties = cloneDeep(properties);
      this.size = cloneDeep(size);
      this.value = cloneDeep(value);
      this.opacity = cloneDeep(opacity);
    }
  }

  /**
   * 注意在导出图片使用该方法，修改时需要注意
   * @param nextProps
   * @param chart
   */
  renderPieChartChart = (nextProps: IComponentProps, chart = this.chartInstance) => {
    const { isPreview, comp } = nextProps;
    const { properties, value, size } = comp;
    if (!chart) {
      return;
    }

    const { textStyle, stroke, border, pieType, animation } = properties;

    const dataLabel = properties.pieDataLabel as IChartDataLabel;
    const showDataLabel = !dataLabel.disabled;
    const isPercentage = dataLabel.value === EChartDataLabelType.percent;
    const unit = dataLabel.unit || '';
    const formatterStr = isPercentage ? `{d}%${unit}` : `{c}${unit}`;

    const legendPosition = properties.pieLegendPosition as ILegendPosition;
    const { disabled: legendDisabled, value: legendValue, gap: legendAndChartSpace } = legendPosition;
    const legendTop = legendValue === ELegendPosition.Top;
    const legendBottom = legendValue === ELegendPosition.Bottom;
    const legendRight = legendValue === ELegendPosition.Right;
    const legendLeft = legendValue === ELegendPosition.Left;
    const pieOffset: Partial<IBoundsOffset> = {};
    const radius = pieType?.value === EChartType_pie.Normal ? ['0%', '70%'] : ['50%', '70%'];
    const center = [
      legendTop || legendBottom || !legendDisabled ? '50%' : legendLeft ? '60%' : '40%',
      legendTop ? '60%' : '50%',
    ];
    if (!legendDisabled && legendAndChartSpace !== undefined && legendAndChartSpace !== '') {
      const legendSize = getLegendSize(nextProps.comp);
      const actualValue = Math.min(size.width, size.height);
      center[1] = '50%';
      if (legendBottom || legendTop) {
        const gap = legendAndChartSpace + legendSize.height;
        const wrapRadius = (size.height - gap * 2) / actualValue;
        radius[1] = `${wrapRadius * 100}%`;
        if (pieType?.value === EChartType_pie.AbNormal) {
          // 环图内外环的半径比未5:7
          radius[0] = `${((wrapRadius * 5) / 7) * 100}%`;
        }
      } else {
        const gap = legendAndChartSpace + legendSize.width;
        const wrapRadius = (size.width - gap * 2) / actualValue;
        radius[1] = `${wrapRadius * 100}%`;
        if (pieType?.value === EChartType_pie.AbNormal) {
          radius[0] = `${((wrapRadius * 5) / 7) * 100}%`;
        }
      }
    }

    const { dataSource } = value as IChartValue;
    const { width, height } = size;

    const { top: bTop, left: bLeft, right: bRight, bottom: bBottom } = border!;
    const { thickness, disabled } = stroke!;
    const borderSize = disabled ? 0 : thickness;
    const b_top = bTop ? borderSize : 0;
    const b_left = bLeft ? borderSize : 0;
    const b_right = bRight ? borderSize : 0;
    const b_bottom = bBottom ? borderSize : 0;
    const selectedStyle = {
      fontFamily: textStyle?.fontFamily,
      fontSize: textStyle?.fontSize,
      color: rgba2hex(textStyle?.color as PureColor),
    };

    const chartSeries: IChartDataType[] = [];

    dataSource.forEach((item, i) => {
      const { name, data, color } = item;
      if (!name) {
        return;
      }
      const hex = rgba2hex(color ?? DefaultChartColorList[i % DefaultChartColorList.length]);
      data.forEach((data) => {
        chartSeries.push({
          name: name,
          value: data as number,
          itemStyle: { color: hex },
        });
      });
    });

    chart.resize({
      width: width - b_right! - b_left!,
      height: height - b_top! - b_bottom!,
    });
    const chartOption: Record<string, any> = {
      series: [
        {
          center,
          type: 'pie',
          radius,
          data: chartSeries,
          selectedMode: 'single',
          label: {
            ...selectedStyle,
            show: showDataLabel,
            formatter: formatterStr,
          },
          ...pieOffset,
        },
      ],
      legend: {
        textStyle: selectedStyle,
        show: !legendDisabled,
        x: legendBottom || legendTop ? 'center' : legendValue,
        y: legendLeft || legendRight ? 'center' : legendValue,
        orient: legendBottom || legendTop ? 'horizontal' : 'vertical',
      },
      animation: isPreview && !!animation?.value,
    };

    //悬浮窗口
    const popUpconfig = properties.popup as IChartPopup;
    chartOption.tooltip = setChartsTooltip(popUpconfig);

    chart.clear();
    chart.setOption(chartOption, true);
  };

  render() {
    const { isPreview } = this.props;

    return (
      <div
        id={this.chartDomID}
        style={{ ...this.state.boxStyle, pointerEvents: isPreview ? 'auto' : 'none' }}
        className={classnames('pie-chart', { 'pie-chart-preview': isPreview })}
      />
    );
  }
}
