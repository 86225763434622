import { ETimeFormat } from '@/fbs/rp/models/properties/common';

export interface ITime {
  hour: number;
  minute: number;
  second?: number;
}

export type TimeRange = [ITime | undefined, ITime | undefined];

/**
 *
 */
export enum ETimeKey {
  Hour,
  Minute,
  Second,
}

export const TimeKeyMaxValueMap: { [key: string]: number } = {
  Hour: 24,
  Minute: 60,
  Second: 60,
};

function getTimeText(value?: number): string {
  if (!value) {
    return '00';
  }
  if (value < 10) {
    return `0${value}`;
  }
  return `${value}`;
}

function getTimeNumber(value?: string): number {
  if (!value) {
    return 0;
  }
  try {
    return parseInt(value, 10);
  } catch {
    return 0;
  }
}

export function getTime(timeStr: string): ITime | undefined {
  if (!timeStr) {
    return undefined;
  }
  const [h, m, s] = timeStr.split(':');
  return {
    hour: getTimeNumber(h),
    minute: getTimeNumber(m),
    second: getTimeNumber(s),
  };
}

export function getTimeStr(time: ITime | undefined, format: ETimeFormat): string {
  if (!time) {
    return '';
  }
  const { hour, minute, second } = time;
  const arr = [getTimeText(hour), getTimeText(minute)];
  if (format === ETimeFormat.HH_mm_ss) {
    arr.push(getTimeText(second));
  }
  return arr.join(':');
}

export const defaultTime: ITime = {
  hour: 0,
  minute: 0,
  second: 0,
};
