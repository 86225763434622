import * as React from 'react';
import * as ReactDom from 'react-dom';

import Icon from '../../Icon';
import Button from '../../Button';
import Dialog from '../../Dialog';
import BasicInfo from './BasicInfo';
import Invitation from './Invitation';
import Record from './Record';
import ReceiveForever from './ReceiveForever';

import { RPFreeProgramPageType, IRPFreeProgramInvitationRecord } from '../../../models/rpFreeProgram';
import { rpFreeProgressInviteCount } from '../../../constants/rpFreeProgram';
import i18n from '../../../i18n';

import './index.scss';

interface IMainProps {
  invitedCount: number;
  hasGotPackage: boolean;
  inviteCode: string;
  teamName: string;
  isRoleGuest: boolean;
  allInvitation: IRPFreeProgramInvitationRecord[];
  isNewRpActivity: boolean;
  expireTime?: Date;
  domain?: string;
  role?: string;

  getInviteLink(): void;
  sendInvitation(emails: string[], token: string): void;
  viewAllInvitation(): void;
  onBackMain(): void;
  getCustomerServiceQR(cb: Function): void;
  // 保存当前客服二维码
  setCustomerServiceQR(url: string): void;
  getReceivedForeverRecord(cb: Function): void;
}

const Main: React.FC<IMainProps> = (props: IMainProps) => {
  const {
    invitedCount,
    hasGotPackage,
    inviteCode,
    teamName,
    isRoleGuest,
    allInvitation,
    isNewRpActivity,
    expireTime,
    getInviteLink,
    sendInvitation,
    viewAllInvitation,
    onBackMain,
    getCustomerServiceQR,
    setCustomerServiceQR,
    getReceivedForeverRecord,
  } = props;

  const [currentType, setCurrenType] = React.useState<RPFreeProgramPageType>(RPFreeProgramPageType.Basic);
  const [showGuestLimitTip, setShowGuestLimitTip] = React.useState(false);

  const handleGoInvite = () => {
    if (isRoleGuest) {
      setShowGuestLimitTip(true);
      return;
    }
    getInviteLink();
    setCurrenType(RPFreeProgramPageType.Invitation);
  };

  const handleViewAllInvitation = () => {
    viewAllInvitation();
    setCurrenType(RPFreeProgramPageType.InvitationRecord);
  };

  const renderContent = () => {
    switch (currentType) {
      case RPFreeProgramPageType.Basic:
        return (
          <BasicInfo
            isNewRpActivity={isNewRpActivity}
            invitedCount={invitedCount}
            hasGotPackage={hasGotPackage}
            expireTime={expireTime}
            viewAllInvitation={handleViewAllInvitation}
          />
        );
      case RPFreeProgramPageType.Invitation:
        return (
          <React.Fragment>
            {renderBack()}
            <Invitation
              inviteCode={inviteCode}
              teamName={teamName}
              domain={props.domain}
              sendInvitation={sendInvitation}
              getReceivedForeverRecord={getReceivedForeverRecord}
            />
          </React.Fragment>
        );
      case RPFreeProgramPageType.InvitationRecord:
        return (
          <React.Fragment>
            {renderBack()}
            <Record allInvitation={allInvitation} />
          </React.Fragment>
        );
      case RPFreeProgramPageType.ReceiveForever:
        return (
          <React.Fragment>
            {renderBack()}
            <ReceiveForever getCustomerServiceQR={getCustomerServiceQR} setCustomerServiceQR={setCustomerServiceQR} />
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  const renderBack = () => {
    return (
      <div className="back-wrapper">
        <div className="back" onClick={goBack}>
          <Icon cls={'tag_leftarrow'} />
          <span>{i18n('rpActivity.goBack')}</span>
        </div>
      </div>
    );
  };

  const goBack = () => {
    if (invitedCount < rpFreeProgressInviteCount) {
      onBackMain();
    }
    setCurrenType(RPFreeProgramPageType.Basic);
  };

  const renderButton = () => {
    switch (currentType) {
      case RPFreeProgramPageType.Basic:
      case RPFreeProgramPageType.InvitationRecord: {
        if (invitedCount < rpFreeProgressInviteCount && !hasGotPackage) {
          return (
            <Button width={144} onClick={handleGoInvite}>
              {i18n('rpActivity.inviteNow')}
            </Button>
          );
        }
        if (!hasGotPackage) {
          return (
            <Button
              width={144}
              classList={['get-forever-btn']}
              onClick={() => setCurrenType(RPFreeProgramPageType.ReceiveForever)}
            >
              {i18n('rpActivity.getForeverPackage')}
            </Button>
          );
        }
        return (
          <Button disabled width={144}>
            {i18n('rpActivity.hasGotPackage')}
          </Button>
        );
      }

      default:
        return null;
    }
  };

  const renderViewerTips  = (): React.ReactNode => {
    if(!showGuestLimitTip) {
      return null;
    }
    const getRoleName = (): string => {
      switch(props.role) {
        case 'guest':
          return i18n('role.guest');
        case 'observer':
          return i18n('role.observer');
          default: break;
      }
      return '';
    }
    return ReactDom.createPortal(
      <Dialog showClose onCancel={() => setShowGuestLimitTip(false)} onOk={() => setShowGuestLimitTip(false)}>
        <div className="content">
          <p>{i18n('rpActivity.guestLimitTip', teamName, getRoleName())}</p>
          <p>{i18n('rpActivity.guestSwitchTeam')}</p>
        </div>
      </Dialog>,
      document.body,
    );
  }

  return (
    <div className="rp-free-dialog-main">
      <div className="main-bg"></div>
      <div className="main-content">
        <div className="main">
          {renderContent()}
          <div className="button-wrapper">{renderButton()}</div>
          {renderViewerTips()}
        </div>
      </div>
    </div>
  );
};

export default Main;
