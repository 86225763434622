import * as React from 'react';
import classnames from 'classnames';

import { isEqualDate } from '@utils/globalUtils';

import { IFragmentAction, FragmentPositionMode } from '@fbs/rp/models/interactions';
import { ISize, IPosition } from '@fbs/common/models/common';

import { IPreviewOption, isMobileAppType } from '@helpers/previewHelper';
import { getClassNameAndStyleOfFragmentAction } from '@/helpers/interactionHelper';

import { ScrollBars } from '@dsm';

import Theater, { PreviewCache } from '../../../../../../theater';
import Page from '../../../../Page';

export interface IDeviceShellProp {
  page: Theater;
  scale: number;
  zoomByWheel?: boolean;
  pageType: 'current' | 'next';
  option: IPreviewOption;
  deviceSize: ISize;
  appType: string;
  isStandalone?: boolean;
  remarkTagId?: string;
  devicePosition?: React.CSSProperties;
  showMobileCursor?: boolean;
}

export interface IDeviceShellState {
  activeFragmentAction?: IFragmentAction;
}

class DeviceShell extends React.Component<IDeviceShellProp, IDeviceShellState> {
  static defaultProps: Partial<IDeviceShellProp> = {};

  page: React.RefObject<Page>;
  self: React.RefObject<HTMLDivElement> = React.createRef();
  private scrollBar: React.RefObject<ScrollBars> = React.createRef();
  private oldScrollPosition: IPosition = { x: 0, y: 0 };

  constructor(props: IDeviceShellProp) {
    super(props);
    this.state = {};
    this.page = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps: IDeviceShellProp) {
    if (!isEqualDate(this.state.activeFragmentAction, nextProps.page.activeFragmentAction)) {
      this.setState({ activeFragmentAction: nextProps.page.activeFragmentAction });
    }
    if (nextProps.page.doc.pageID !== this.props.page.doc.pageID) {
      this.scrollBar.current?.scrollToTop();
      this.scrollBar.current?.scrollToLeft();
    }
    // 滚轮缩放时，记住项目的滚动条
    const isZoom = nextProps.scale !== this.props.scale;
    if (nextProps.zoomByWheel && isZoom) {
      this.upDateOldScrollPosition();
    }
  }

  /**
   * 记住缩放的滚动条位置
   */
  upDateOldScrollPosition = () => {
    const scrollBar = this.scrollBar.current;
    if (!scrollBar) {
      return;
    }
    const { getScrollTop, getScrollLeft } = scrollBar;
    const curScrollTop = getScrollTop();
    const curScrollLeft = getScrollLeft();
    this.oldScrollPosition = {
      x: curScrollLeft,
      y: curScrollTop,
    };
  };

  /**
   *  滚轮缩放时，保持项目的滚动条
   */
  holdScrollWhenZoom = (preScale: number) => {
    const scrollBar = this.scrollBar.current;
    if (!scrollBar) {
      return;
    }
    const { scrollLeft, scrollTop } = scrollBar;
    const { scale } = this.props;
    const { x: oldScrollLeft, y: oldScrollTop } = this.oldScrollPosition;
    const newTop = (oldScrollTop / preScale) * scale;
    const newLeft = (oldScrollLeft / preScale) * scale;
    scrollTop(newTop);
    scrollLeft(newLeft);
  };

  componentDidMount() {
    this.cachePageBounds();
  }

  componentDidUpdate(prevProps: IDeviceShellProp) {
    this.cachePageBounds();
    // 滚轮缩放时，保持项目的滚动条
    const isZoom = prevProps.scale !== this.props.scale;
    if (this.props.zoomByWheel && isZoom) {
      this.holdScrollWhenZoom(prevProps.scale);
    }
    if (prevProps.page.doc.pageID !== this.props.page.doc.pageID) {
      window.dispatchEvent(new CustomEvent('resetPanelScrollbar', { detail: { reset: true } }));
    }
  }

  private cachePageBounds() {
    if (this.self.current) {
      const { width, height, left, top, right, bottom } = this.self.current.getBoundingClientRect();
      PreviewCache['pageBounds'] = { left, top, right, bottom, width, height };
    }
  }

  /**
   * 画板动画结束
   * @memberof DeviceShell
   * targetId 辅助画板交互目标的ID
   */
  handleFragmentAnimationEnd = (targetId?: string): void => {
    const { activeFragmentAction } = this.state;
    if (targetId) {
      // 辅助画板交互为关闭时有效
      this.props.page.fragmentCommandMap?.delete(targetId);
      this.update();
      return;
    }
    if (activeFragmentAction && activeFragmentAction.isExit) {
      this.handleFragmentActionClean(this.props.page.removeFragmentAction);
    }
  };

  /**
   * 页面动画结束
   * @memberof DeviceShell
   */
  handleSkipEnd = () => {
    this.handleFragmentAnimationEnd();
    if (this.props.pageType === 'next') {
      this.props.page.pageAnimationEnd();
    }
  };

  /**
   * 点击退出辅画板叠加
   * @memberof DeviceShell
   */
  handleEndFragment = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { onEndFragmentAction, activeFragmentAction } = this.props.page;
    if (onEndFragmentAction && activeFragmentAction) {
      onEndFragmentAction(activeFragmentAction);
      // removeFragmentRevertAction();
    }
  };

  /**
   * 清除动画
   */
  handleFragmentActionClean = (fn: Function) => {
    this.setState({ activeFragmentAction: undefined }, () => {
      fn && fn();
    });
  };

  getSkipStyleAndClassName(
    page: Theater | undefined,
    pageType: 'current' | 'next',
    noAnimation: boolean,
  ): { style: React.CSSProperties; classNames: string } {
    let animationName = 'none';
    const style: React.CSSProperties = {};
    if (!page) {
      return { style, classNames: '' };
    }
    const { animationEffect } = page;
    if (animationEffect) {
      style.animationTimingFunction = animationEffect.animation.effect;
      style.animationDuration = `${animationEffect.animation.duration || 1}ms`;
      animationName = animationEffect.params;
      style.animationFillMode = `${pageType == 'next' ? 'forwards' : ''}`;
    }
    if (noAnimation) {
      style.animationDuration = '1ms';
      animationName = 'none';
    }
    const classNames = classnames([`${pageType}-${animationName}`], [`${pageType}-page`]);
    return {
      style,
      classNames,
    };
  }

  renderFragment = (
    scale: number,
    action: IFragmentAction,
    noBoundary?: boolean,
    initialStyle?: React.CSSProperties,
  ) => {
    const fragment = this.page.current?.getActiveFragment(action.target);
    const { deviceSize } = this.props;
    if (!fragment) return null;
    let size = fragment && {
      height: fragment.size.height * (scale || 1),
      width: fragment.size.width * (scale || 1),
    };

    let { animationStyle, bgStyle, keyframeName } = getClassNameAndStyleOfFragmentAction(action, {
      fragment,
      scale,
      deviceSize,
    });

    // 动效为自动时使用反转
    if (action.params.autoEffect) {
      keyframeName = keyframeName + '-reverse';
    }

    const hotAreaVisibleModel = this.page.current?.getHotAreaVisibleModel(this.props.option);
    const classNames = classnames('active-fragment', keyframeName, this.props.option ? hotAreaVisibleModel : '', {
      ['over-visible']: !!noBoundary,
    });
    return (
      <div
        key={fragment.id}
        className="fragment-bg"
        style={{ ...(initialStyle ?? {}), ...bgStyle }}
        onClick={this.props.page.closeFragmentByAction.bind(this.props.page, action, this.forceUpdate.bind(this))}
      >
        <div
          className={classNames}
          onAnimationEnd={() => {
            if (!keyframeName.endsWith('null')) {
              this.handleFragmentAnimationEnd(action.params.autoEffect || action.isExit ? action.target : '');
            }
          }}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              this.props.page.closeFragmentByAction(action, this.forceUpdate.bind(this));
            }
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            e.stopPropagation();
          }}
          onContextMenu={(e) => {
            e.stopPropagation();
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
            this.page.current?.handlePageDown();
          }}
          onMouseUp={(e) => {
            e.stopPropagation();
            this.page.current?.handlePageUp();
          }}
          onTouchStart={(e) => {
            e.stopPropagation();
            this.page.current?.handleTouchStart(e);
          }}
          onTouchEnd={(e) => {
            e.stopPropagation();
            this.page.current?.handleTouchEnd(e);
          }}
          onTouchMove={(e) => {
            e.stopPropagation();
            this.page.current?.handleTouchMove(e);
          }}
          style={{
            ...animationStyle,
            ...size,
          }}
        >
          {fragment && this.page.current?.renderComponents(fragment, scale, undefined, hotAreaVisibleModel || null)}
        </div>
      </div>
    );
  };

  private update = () => {
    this.forceUpdate();
  };

  private get nonCustomFragmentCommands() {
    const { page, pageType } = this.props;
    if (pageType !== 'current') {
      return [];
    }
    return Array.from(page.fragmentCommandMap?.values() ?? []).filter((item) => {
      return item.params.mode !== FragmentPositionMode.Custom;
    });
  }

  public renderFloatComps(scale: number, style?: React.CSSProperties) {
    const {
      page: {
        doc: { mainArtboard },
      },
      option,
    } = this.props;
    return this.page.current?.renderFloatComps(mainArtboard, {
      scale: scale / 100,
      noBoundary: option.noBoundary,
      style,
      options: option,
    });
  }

  renderNonCustomFragment = () => {
    const nonCustomFragments = this.nonCustomFragmentCommands;
    if (!nonCustomFragments.length) return null;
    return nonCustomFragments.map((item) =>
      this.renderFragment(this.props.scale / 100, item, this.props.option.noBoundary),
    );
  };

  render() {
    const { activeFragmentAction } = this.state;
    const {
      page,
      scale,
      option,
      pageType,
      deviceSize,
      isStandalone,
      remarkTagId,
      appType,
      showMobileCursor,
    } = this.props;
    const screenScale = scale / 100;
    const { width, height } = page.doc.mainArtboard.size;

    const { style, classNames } = this.getSkipStyleAndClassName(page, pageType, !!option.noBoundary);
    const contentSize = {
      width: width * screenScale,
      height: height * screenScale,
    };
    const contentStyle: React.CSSProperties = {
      width: width,
      height: height,
      transform: `scale(${screenScale}, ${screenScale})`,
      transformOrigin: '0% 0%',
    };
    const scrollBarStyle: React.CSSProperties = { width: '100%', height: '100%' };
    const isContains = deviceSize.height >= contentSize.height! && deviceSize.width >= contentSize.width!;
    const pageDom = (
      <Page
        ref={this.page}
        option={option}
        remarkTagId={remarkTagId}
        page={page}
        pageType={pageType}
        scale={1}
        action={activeFragmentAction}
        isStandalone={isStandalone}
        showMobileCursor={showMobileCursor}
        onEndFragment={this.handleEndFragment}
        onParentForceUpdate={this.update}
        onFragmentAnimationEnd={this.handleFragmentAnimationEnd}
      />
    );
    let floatStyle = this.props.devicePosition;
    if (isMobileAppType(appType) && !option.noBoundary) {
      floatStyle = { ...floatStyle, ...deviceSize, overflow: 'hidden' };
    }
    const contentDom = (
      <>
        <div style={{ ...contentSize }} className="content-wrapper">
          <div className={classnames('content', { 'no-boundary': option.noBoundary })} style={contentStyle}>
            {pageDom}
          </div>
        </div>
        {this.renderFloatComps(this.props.scale, floatStyle)}
        {this.page.current?.renderCustomFragmentCommands(this.props.scale / 100)}
      </>
    );

    return (
      <div
        className={'preview-device-shell ' + classNames}
        style={style}
        ref={this.self}
        onAnimationEnd={this.handleSkipEnd}
      >
        {!option.noBoundary && !isContains ? (
          <>
            <ScrollBars className="no-scale-scroll" ref={this.scrollBar} style={scrollBarStyle}>
              {contentDom}
            </ScrollBars>
            {this.renderNonCustomFragment()}
          </>
        ) : (
          <>
            {contentDom}
            {this.renderNonCustomFragment()}
          </>
        )}
        {/** 交互被设为置于顶部的元素 占位 */}
        <div
          className={classnames(
            'bring-front-comps',
            { mobile: showMobileCursor },
            this.page.current?.getHotAreaVisibleModel(option),
          )}
        ></div>
      </div>
    );
  }
}

export default DeviceShell;
