/**
 * @description:
 * @author: koujing
 * @date: 2022-05-25 09:55
 **/
// @ts-nocheck
import { IJsTicketConfig, IMeetingInfo, IMeetingStatus } from '../constants/weMeet';
import { get, put } from './helper';

const URL = RP_CONFIGS.TxMeetApiUrl;
export async function getJsTicket(token: string): Promise<IJsTicketConfig> {
  return get(`${URL}/txmeeting/jsticket`, { headers: { Authorization: `Bearer ${token}` } }); //获取JSTicket
}

export async function getAuthor(token: string): Promise<string> {
  return get(`${URL}/txmeeting/txMeetingLoginUrl`, { headers: { Authorization: `Bearer ${token}` } }); //获取授权页面
}
export async function getIsMeetingExist(appID: string, token: string): Promise<IMeetingStatus> {
  return get(`${URL}/txmeeting/meeting/${appID}`, { headers: { Authorization: `Bearer ${token}` } }); //获取会议状态
}

export async function createMeeting(appName: string, appID: string, url?: string, token?: string): Promise<IMeetingInfo> {
  url = url ? url : location.href;
  return put(`${URL}/txmeeting/meeting/${appID}`, { appName, url }, { headers: { Authorization: `Bearer ${token}` } }); //创建会议
}

export async function getMeetingLink(appID: string, token: string): Promise<string> {
  return get(`${URL}/txmeeting/meeting/url/${appID}`, { headers: { Authorization: `Bearer ${token}` } }); //获取会议链接
}
export async function getAuthorStatus(token: string): Promise<boolean> {
  return get(`${URL}/txmeeting/check/token`, { headers: { Authorization: `Bearer ${token}` } });
}

export async function getAnchorUrl(meetingID: string, token: string): Promise<string> {
  return get(`${URL}/txmeeting/meeting/cc/url/${meetingID}`, { headers: { Authorization: `Bearer ${token}` } });
}