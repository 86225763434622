import * as React from 'react';
import classNames from 'classnames';

import * as GraphicsUtils from '@utils/graphicsUtils';
import { dragDelegate } from '@/utils/mouseUtils';

import { isStandalonePreview } from '@/helpers/previewHelper';
import { StyleHelper } from '@/helpers/styleHelper';
import { UIContainerComponent } from '@/editor/comps';
import { IListCompositeProps } from '@libs/types';
import ICarouselChart, { EffectType } from '@/fbs/rp/models/properties/carouselChart';

import Background from '@/libs/basic/common/Background';
import { FadeCarouselEffect, PushCarouselEffect, SlideCarouselEffect } from './CarouselEffect';
import SimpleShape from '../../simpleBasic/Shape';

import './index.scss';

const SWIPE_INTERVAL = 500; // 滑动事件最大滑动时长(ms)
const SWIPE_X = 10; // 滑动事件最小滑动距离

const CarouselChart: React.FC<IListCompositeProps> = (props: IListCompositeProps) => {
  const { comp, isPreview, itemEvents, isStandalone } = props;
  const container = comp as UIContainerComponent;
  const { properties, size, disabled } = container;
  const imageMain = container.getComponentByAlias('image-main') as UIContainerComponent;
  const indicatorMain = container.getComponentByAlias('indicator-main') as UIContainerComponent;
  const imageComps = imageMain.components as UIContainerComponent[];
  const { indicator, showAutoplay, playInterval, showPageTurnBtn, effect, playSpeed } = container.properties
    .carousel as ICarouselChart;

  // 移动端滑动启始时间
  const mobileSwipeRef = React.useRef({
    startTime: 0,
    startX: 0,
  });
  const carouselEffect = React.useRef<FadeCarouselEffect>(null);
  const timer = React.useRef<NodeJS.Timeout>();
  // 是否可操作
  const allowChange = React.useRef(true);
  // 指示器下标在单张图片时和图片不一致，单张图片是需要重复播放第一张，拷贝图片，每次播放不更改指示器下标，只修改图片下标
  const activeImgIndex = React.useRef(container.value as number);

  React.useEffect(() => {
    doAutoPlay();
    return () => {
      doCancelAutoPlay();
    };
  }, []);

  // 取消自动播放
  const doCancelAutoPlay = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  // 自动播放
  const doAutoPlay = () => {
    if (isPreview && showAutoplay && playInterval > 0 && (!isStandalone || isStandalonePreview)) {
      doCancelAutoPlay();
      timer.current = setTimeout((e) => {
        doCarouseChange(activeImgIndex.current + 1, e);
      }, playInterval);
    }
  };

  const getCompStyle = () => {
    const { border } = properties;
    const parser = StyleHelper.createCSSStyleParser(properties);
    return {
      ...size,
      ...parser.getShadowStyle(true),
      ...parser.getRadiusStyle(size, border),
      opacity: StyleHelper.getOpacity(container.opacity),
    };
  };

  const doCarouseChange = (index: number, e?: React.MouseEvent) => {
    if (!isPreview || !allowChange.current || (isStandalone && !isStandalonePreview)) {
      return;
    }
    const len = imageComps.length;
    const isSimple = len === 1;
    const imgLen = isSimple ? len + 1 : len;

    let nextImageIndex = index;
    if (nextImageIndex > imgLen - 1) {
      nextImageIndex = 0;
    } else if (nextImageIndex < 0) {
      nextImageIndex = imgLen - 1;
    }
    // 单张图片时，移动图片下标，指示器下标保持初始值
    allowChange.current = false;
    const nextIndicatorIndex = isSimple ? 0 : nextImageIndex;
    if (nextIndicatorIndex !== container.value) {
      props.event?.onClick?.(e, indicatorMain.components[nextImageIndex]);
    }
    doCancelAutoPlay();
    carouselEffect.current?.doActiveIndexChange(index, nextImageIndex);
    activeImgIndex.current = nextImageIndex;
  };

  // 指示器点击切换
  const handleIndicatorChange = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    const index = Number(e.currentTarget.dataset.index);
    if (index === container.value) {
      doAutoPlay();
      return;
    }
    doCarouseChange(index, e);
  };

  const doCarouselChangeByDic = (type: string, e: React.MouseEvent) => {
    const direction = type === 'prev' ? -1 : 1;
    const activeIndex = activeImgIndex.current;
    doCarouseChange(activeIndex + direction, e);
  };

  // 翻页器点击切换
  const handleCarouselChangeByTurnBtn = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    const type = e.currentTarget.dataset.type!;
    doCarouselChangeByDic(type, e);
  };

  // 处理滑动事件
  const doSwipe = (x: number, startTime: number, e: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    const endTime = performance.now();
    if (endTime - startTime <= SWIPE_INTERVAL && Math.abs(x) >= SWIPE_X) {
      doCarouselChangeByDic(x < 0 ? 'next' : 'prev', e);
    }
  };

  // web端滑动事件触发
  const handleMouseDown = (event: React.MouseEvent) => {
    const startTime = performance.now();
    dragDelegate(
      () => {},
      (_e, delta) => {
        doSwipe(delta.x, startTime, event);
      },
    );
  };

  // 移动端滑动事件触发
  const handleTouchStart = (e: React.TouchEvent) => {
    mobileSwipeRef.current.startTime = performance.now();
    mobileSwipeRef.current.startX = e.touches[0].pageX;
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    const endX = e.changedTouches[0].pageX;
    doSwipe(endX - mobileSwipeRef.current.startX, mobileSwipeRef.current.startTime, (e as unknown) as React.MouseEvent);
  };

  const handleTransitionEnd = () => {
    allowChange.current = true;
    doAutoPlay();
  };

  // 阻止按钮触发整体的滑动交互
  const handleTurnBtnMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation();
  };

  const renderPageTurnButton = (pageTurnComp: UIContainerComponent) => {
    if (!showPageTurnBtn) {
      return null;
    }
    const iconComp = pageTurnComp.components[0];
    const type = pageTurnComp.name === 'pageTurnLeft' ? 'prev' : 'next';
    const style = {
      ...pageTurnComp.size,
      top: pageTurnComp.position.y,
      left: pageTurnComp.position.x,
    };
    const { icon: iconStyle } = iconComp.properties;
    const iconColor = GraphicsUtils.parseColorToString(iconStyle?.color || '#fff');
    const size = iconComp.size.width;
    const sizeStyle = {
      width: size,
      height: size,
    };
    return (
      <div
        className="page-turn-item"
        style={style}
        data-type={type}
        onClick={handleCarouselChangeByTurnBtn}
        onMouseDown={handleTurnBtnMouseDown}
        onTouchStart={handleTurnBtnMouseDown}
      >
        <div className="page-turn-icon" style={sizeStyle}>
          <svg
            style={{ ...sizeStyle, color: iconColor, transform: `rotate(${iconComp.rotate || 0}deg)` }}
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.25025 0.75L2.25 6L7.25025 11.25L8.25 10.2L4.25025 6L8.25 1.8L7.25025 0.75Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
    );
  };

  const renderImageMain = () => {
    let C = SlideCarouselEffect;
    switch (effect) {
      case EffectType.Push: {
        C = PushCarouselEffect;
        break;
      }
      case EffectType.Slide: {
        C = SlideCarouselEffect;
        break;
      }
      case EffectType.Fade: {
        C = FadeCarouselEffect;
        break;
      }
      default: {
        C = PushCarouselEffect;
      }
    }
    return (
      <C
        itemEvents={itemEvents}
        isPreview={isPreview}
        onTransitionEnd={handleTransitionEnd}
        ref={carouselEffect}
        imageMainComp={imageMain}
        playSpeed={playSpeed}
        value={container.value as number}
        disabled={disabled}
      />
    );
  };

  const renderIndicatorMask = () => {
    const maskComp = container.getComponentByAlias('indicator-mask');
    if (maskComp) {
      const style = {
        ...maskComp.size,
        top: maskComp.position.y,
        left: maskComp.position.x,
      };
      return <div className="mask" style={style} />;
    }
    return null;
  };

  const renderIndicatorMain = () => {
    const style = {
      ...indicatorMain.size,
      top: indicatorMain.position.y,
      left: indicatorMain.position.x,
    };
    const indicatorComps = indicatorMain.components;
    return (
      <div className="indicator-main" style={style}>
        {indicatorComps.map((item, index) => (
          <div
            onClick={handleIndicatorChange}
            key={item.id}
            data-index={index}
            className="indicator-item"
            style={{ top: item.position.y, left: item.position.x, ...item.size }}
          >
            <SimpleShape comp={item} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={classNames('lib-comp-carousel-chart', { isPreview })}
      style={getCompStyle()}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
    >
      {renderPageTurnButton(container.getComponentByAlias('page-turn-left') as UIContainerComponent)}
      {renderImageMain()}
      {indicator.showIndicator && renderIndicatorMask()}
      {indicator.showIndicator && renderIndicatorMain()}
      {renderPageTurnButton(container.getComponentByAlias('page-turn-right') as UIContainerComponent)}
      <Background size={container.size} properties={properties} />
    </div>
  );
};

export default CarouselChart;
