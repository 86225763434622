import * as React from 'react';
import { drawCanvasWm } from '../../helper/watermarkHelper';

interface IWaterMarkProps {
  show: boolean;
  content: string;
  style?: React.CSSProperties;
}

const WaterMark: React.FC<IWaterMarkProps> = (props: IWaterMarkProps) => {
  const { show, content, style } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    drawCanvasWm({ content, container: ref?.current as HTMLElement });
  }, []);

  if (!show) {
    return null;
  }

  return <div className="dsm-c-watermark" ref={ref} style={{position: 'relative',...(style||{})}}/>;
};

WaterMark.defaultProps = {
  show: false,
  content: '',
};

export default WaterMark;
