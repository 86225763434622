import * as React from 'react';
import * as ReactDom from 'react-dom';
import classnames from 'classnames';

import { isContainerPoint } from '@/utils/boundsUtils';
import { parseColorToString } from '@utils/graphicsUtils';

import { IProperties } from '@/fbs/rp/models/property';
import { ETimeFormat, ETimePickerType } from '@fbs/rp/models/properties/common';
import { IRange } from '@fbs/rp/models/properties/base';

import i18n from '@i18n';

import { StyleHelper } from '@/helpers/styleHelper';
import appOptions from '@/helpers/appOptions';
import { DefaultTextColor } from '@consts/colors';

import { IComponentProps } from '../../types';
import { getTime, getTimeStr, ITime, TimeRange } from './type';
import TimeSelector from './TimeSelector';

import './index.scss';
import { PureColor } from '@fbs/rp/models/properties/color';

interface IState {
  value: TimeRange;
  position?: { left: number; top: number; right: number; bottom: number };
  isPopup?: boolean;
  changeIndex: 0 | 1;
}

export default class TimerPicker extends React.Component<IComponentProps, IState> {
  private startTimeDom: React.RefObject<HTMLDivElement> = React.createRef();
  private endTimeDom: React.RefObject<HTMLDivElement> = React.createRef();
  private self: React.RefObject<HTMLDivElement> = React.createRef();
  private selector: React.RefObject<TimeSelector> = React.createRef();
  private compBounds: { x: number; y: number; width: number; height: number; rotate: number };

  constructor(props: IComponentProps) {
    super(props);
    const {
      comp: { text, size, position, rotate },
    } = props;
    const timeStr = text ? (text as string).split(',') : [];
    const time: TimeRange = [undefined, undefined];
    if (timeStr.length) {
      time[0] = getTime(timeStr[0]);
    }
    if (timeStr.length > 1) {
      time[1] = getTime(timeStr[1]);
    }
    this.compBounds = { ...size, ...position, rotate };
    this.state = {
      value: time,
      changeIndex: 0,
    };
  }

  componentDidMount() {
    if (this.props.isPreview) {
      window.addEventListener('pageScaleChange', this.handlePageScaleChange);
      document.addEventListener('scroll', this.handleScrollChange, true);
    } else {
      window.addEventListener('pageZoomChange', this.handlePageChange);
      window.addEventListener('pagePositionChange', this.handlePageChange);
    }
  }

  componentDidUpdate() {
    if (!this.props.isPreview) {
      if (this.props.onlySelected && this.props.selected) {
        this.registerDoubleClickListener();
      } else {
        this.removeDoubleClickListener();
      }
      const {
        comp: { position, size, rotate },
      } = this.props;
      if (this.state.isPopup) {
        if (!this.compBounds) {
          return;
        }
        if (
          this.compBounds.x !== position.x ||
          this.compBounds.y !== position.y ||
          this.compBounds.width !== size.width ||
          this.compBounds.height !== size.height ||
          this.compBounds.rotate !== rotate
        ) {
          this.setState({ isPopup: false });
        }
      }
      this.compBounds = { ...position, ...size, rotate };
    } else {
      if (this.state.isPopup) {
        window.addEventListener('mousedown', this.handleWindowMouseDown, true);
      } else {
        window.removeEventListener('mousedown', this.handleWindowMouseDown, true);
      }
    }
  }

  componentWillUnmount() {
    this.removeDoubleClickListener();
    window.removeEventListener('mousedown', this.handleWindowMouseDown, true);
    window.removeEventListener('pageScaleChange', this.handlePageScaleChange);
    window.removeEventListener('pageZoomChange', this.handlePageChange);
    window.removeEventListener('pagePositionChange', this.handlePageChange);
    document.removeEventListener('scroll', this.handleScrollChange, true);
  }

  static getDerivedStateFromProps(nextProps: IComponentProps): Partial<IState> | null {
    if (!nextProps.isPreview && (!nextProps.onlySelected || !nextProps.selected)) {
      return {
        isPopup: false,
      };
    }
    return null;
  }

  private registerDoubleClickListener = () => {
    this.removeDoubleClickListener();
    window.addEventListener('dblclick', this.handleWindowDoubleClick, true);
  };

  private removeDoubleClickListener = () => {
    window.removeEventListener('dblclick', this.handleWindowDoubleClick, true);
  };

  handlePageChange = () => {
    this.state.isPopup && this.setState({ isPopup: false });
  };

  /**
   * 滚动关闭弹窗
   * @param e
   */
  private handleScrollChange = (e: Event) => {
    const dom = e.target as HTMLElement;
    const selector = this.selector.current;
    if (selector && selector.self.current?.contains(dom)) {
      return;
    }
    this.handleCloseSelector();
  };

  private handleWindowMouseDown = (e: MouseEvent) => {
    const { pageX: x, pageY: y } = e;
    const pt = { left: x, top: y };
    const selfBounds = this.self.current?.getBoundingClientRect();
    if ((selfBounds && isContainerPoint(selfBounds, pt)) || this.selector.current?.containerPoint({ x, y })) {
      return;
    }
    this.setState({ isPopup: false });
  };

  private handleWindowDoubleClick = (e: MouseEvent) => {
    const { pageX, pageY } = e;
    const pt = { left: pageX, top: pageY };
    const pageDom = document.querySelector('.page-container');
    const scale = appOptions.scale;
    const { comp } = this.props;
    if (!pageDom) {
      return;
    }
    const pageBounds = pageDom.getBoundingClientRect();
    const bounds = comp.getViewBoundsInScreen(pageBounds!, scale);

    type IBounds = { left: number; top: number; right: number; bottom: number; width: number; height: number };
    let startBounds: IBounds | undefined = undefined;
    let endBounds: IBounds | undefined = undefined;

    const getDomBounds = (dom: HTMLElement) => {
      const { offsetLeft, offsetWidth, offsetHeight, offsetTop } = dom;
      const left = offsetLeft * scale + bounds.left;
      const top = offsetTop * scale + bounds.top;
      const width = offsetWidth * scale;
      const height = offsetHeight * scale;
      return {
        left,
        top,
        width,
        height,
        right: left + width,
        bottom: top + height,
      };
    };

    if (this.startTimeDom.current) {
      startBounds = getDomBounds(this.startTimeDom.current);
      const { padding } = comp.properties;
      const offsetLeft = padding?.left ?? 0;
      startBounds.left -= offsetLeft;
      startBounds.right -= offsetLeft;
    }

    if (this.endTimeDom.current) {
      endBounds = getDomBounds(this.endTimeDom.current);
    }

    if (endBounds && isContainerPoint(endBounds, pt)) {
      this.doPopup(1);
    } else if (startBounds && isContainerPoint(startBounds, pt)) {
      this.doPopup(0);
    }
  };

  private handleTimeSelected = (time: ITime | undefined, index: 0 | 1) => {
    const { value } = this.state;
    const newValue: TimeRange = [...value];
    newValue[index] = time;
    const {
      comp: {
        properties: { format },
      },
    } = this.props;
    this.setState({ value: newValue }, () => {
      if (!this.props.isPreview && this.props.onValueEdited) {
        this.props.onValueEdited(
          this.props.comp,
          this.state.value
            .map((time) => getTimeStr(time, (format?.value as ETimeFormat) ?? ETimeFormat.HH_mm))
            .join(','),
        );
      }
    });
  };

  private handleCloseSelector = () => {
    this.setState({ isPopup: false });
  };

  private handlePageScaleChange = () => {
    if (this.state.isPopup && this.props.isPreview) {
      this.doPopup(this.state.changeIndex);
    }
  };

  private handlePopupSelector = (index: 0 | 1, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      index && e.stopPropagation();
      this.doPopup(index);
    }
  };

  private doPopup(index: 0 | 1) {
    const {
      comp: {
        disabled,
        properties: { padding },
      },
    } = this.props;
    if (disabled) {
      return;
    }
    const dom = index === 0 ? this.startTimeDom.current : this.endTimeDom.current;
    if (!dom) {
      return;
    }
    const parent = this.getParent();
    if (!parent) {
      return;
    }
    const { left, bottom, right, top } = dom.getBoundingClientRect();
    const { left: parentLeft, top: parentTop } = parent.getBoundingClientRect();

    let offsetLeft = 0;
    const scale = window.pageScale;
    if (index === 0) {
      offsetLeft = (padding?.left || 0) * scale;
    }
    const offsetBottom = 1;
    const _left = left - parentLeft - offsetLeft;
    const _bottom = bottom - parentTop + offsetBottom;
    const _top = top - parentTop;
    const _rifht = right - parentLeft;
    this.setState({
      isPopup: true,
      position: {
        left: _left,
        bottom: _bottom,
        right: _rifht,
        top: _top,
      },
      changeIndex: index,
    });
  }

  private getParent() {
    const { isPreview } = this.props;
    if (isPreview) {
      // 弹窗不跟随页面滚动，挂载到body
      return document.body;
    } else {
      return document.querySelector('#workspace');
    }
  }

  private renderSelector(properties: IProperties) {
    const { value, isPopup, changeIndex } = this.state;
    if (!isPopup) {
      return null;
    }
    const parent = this.getParent();
    if (!parent) {
      return null;
    }
    const { format } = properties;
    return ReactDom.createPortal(
      <TimeSelector
        ref={this.selector}
        appType={'web'}
        type={(properties.model?.value || ETimePickerType.Normal) as ETimePickerType}
        onChange={this.handleTimeSelected}
        onClose={this.handleCloseSelector}
        position={this.state.position!}
        time={value[changeIndex]}
        selected={this.state.changeIndex!}
        format={(format?.value as ETimeFormat) ?? ETimeFormat.HH_mm}
        preview={this.props.isPreview}
        showMobileCursor={this.props.showMobileCursor}
      />,
      parent,
    );
  }

  renderTime(index: 0 | 1, properties: IProperties, style: React.CSSProperties, time?: ITime) {
    const { format, model, placehoder, iconColor, padding, placeholderColor } = properties;
    const _format = (format?.value as ETimeFormat) ?? ETimeFormat.HH_mm;

    let text = getTimeStr(time, _format);
    let placeholderStyle: React.CSSProperties = {};
    const empty = !text;
    if (!text) {
      placeholderStyle.color = this.parsePlaceholderColorStr(placeholderColor?.value as PureColor);
      if (model?.value === ETimePickerType.Normal) {
        text = placehoder?.value as string;
      } else {
        text = index === 0 ? i18n('resource.componentsText.startTime') : i18n('resource.componentsText.endTime');
      }
    }
    let paddingLeft = 0;
    let paddingRight = 0;
    if (!padding?.disabled) {
      paddingLeft = padding?.left || 0;
      paddingRight = padding?.right || 0;
    }

    return (
      <div
        className={classnames('timer-value', { empty })}
        onClick={this.handlePopupSelector.bind(this, index)}
        ref={index ? this.endTimeDom : this.startTimeDom}
        style={
          index
            ? {
                ...style,
                ...placeholderStyle,
                marginRight: iconColor?.disabled ? paddingRight : 0,
              }
            : {
                ...style,
                ...placeholderStyle,
                marginLeft: paddingLeft,
              }
        }
      >
        <label>{text}</label>
      </div>
    );
  }

  parsePlaceholderColorStr(value: PureColor): string {
    let color = parseColorToString(value);
    return color;
  }
  render() {
    const {
      isPreview,
      comp: { properties, size, opacity },
    } = this.props;
    const { value } = this.state;
    const { model, padding, iconColor, iconSize } = properties;
    const parser = StyleHelper.initCSSStyleParser(properties);
    const showIcon = !iconColor?.disabled;
    const style = {
      opacity: StyleHelper.getOpacity(opacity),
      ...parser.getFillStyle(),
      ...parser.getStrokeStyle(),
      ...parser.getShadowStyle(),
      ...parser.getRadiusStyle(size),

      ...size,
      lineHeight: `${size.height}px`,
    };
    const textStyle: React.CSSProperties = {
      ...parser.getTextStyle(),
    };

    let marginRight = 0;
    if (!padding?.disabled) {
      marginRight = padding?.right || 0;
    }
    const iconStyle: React.CSSProperties = {
      fontSize: Number((iconSize?.value as IRange).value ?? 14),
      color: parseColorToString((iconColor?.value ?? DefaultTextColor) as PureColor),
    };

    return (
      <>
        <div
          ref={this.self}
          className={classnames('lib-comp-time-picker', { 'component-cursor-pointer': isPreview })}
          style={style}
          onClick={!isPreview ? undefined : this.handlePopupSelector.bind(this, 0)}
        >
          {this.renderTime(0, properties, textStyle, value.length ? value[0] : undefined)}
          {model?.value === ETimePickerType.Range && (
            <>
              <label className="separator-text" style={{ color: textStyle.color }}>
                ~
              </label>
              {this.renderTime(1, properties, textStyle, value.length > 1 ? value[1] : undefined)}
            </>
          )}
          {showIcon && (
            <div
              style={{
                marginLeft: 10,
                marginRight,
              }}
            >
              <span className="time-icon" style={iconStyle} />
            </div>
          )}
        </div>
        {this.renderSelector(properties)}
      </>
    );
  }
}
