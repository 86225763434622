import * as React from 'react';
import * as ReactDom from 'react-dom';
import { ISize } from '@/fbs/idoc/models/common';
import classnames from 'classnames';

import './index.scss';

interface INoticeProps {
  size?: Partial<ISize>;
  duration?: number; //持续显示时间(ms)
  positionType?: 'top' | 'center' | 'bottom';
  onClose?: () => void;
}

export default class Notice extends React.Component<INoticeProps> {
  private closeTimer: any;

  static defaultProps = {
    duration: 3000,
    positionType: 'top',
  };

  componentDidMount() {
    if (this.props?.duration && this.props?.duration > 0) {
      this.closeTimer = setTimeout(() => {
        this.close();
      }, this.props.duration);
    }
  }

  componentWillUnmount() {
    this.clearCloseTimer();
  }

  clearCloseTimer = () => {
    if (this.closeTimer) {
      clearTimeout(this.closeTimer);
      this.closeTimer = null;
    }
  };

  close = () => {
    // 清除计时器
    this.clearCloseTimer();
    const { onClose } = this.props;
    onClose && onClose();
  };

  render() {
    return ReactDom.createPortal(
      <div style={{ ...this.props.size }} className={classnames('dsm-notice', this.props.positionType)}>
        {this.props.children}
      </div>,
      document.getElementById('notice-container') as HTMLDivElement,
    );
  }
}
