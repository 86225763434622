import * as React from 'react';
import classnames from 'classnames';
import { isEqual, kebabCase } from 'lodash';

import {
  execCommandFontSize,
  measureTextSize,
  filterHTMLText,
  replaceOldFontSize,
  applyFontStyleToLI,
  execCommandOutdent,
} from '@utils/textUtils';
import * as BoundsUtils from '@utils/boundsUtils';
import { dragDelegate } from '@utils/mouseUtils';
import { convertEventToHotKey, isControlKeyPressed } from '@utils/hotkeysUtils';
import { transColorToRGBA } from '@utils/graphicsUtils';

import { TextAlign } from '@fbs/rp/models/properties/text';
import { ISize } from '@fbs/common/models/common';
import { TextFormatExPropertyName } from '@fbs/rp/models/properties/textFormat';
import { ITextEditor } from '@/customTypes';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';

import { ClipboardType } from '@helpers/clipboardHelper';
import { stopBubbleWhenSortCut, textStyleShortCut } from '@helpers/shortCutHelper';
import { getExecuteCommandName } from '@helpers/resourceHelper';
import { upgradeTextFormatForApply } from '@/helpers/propertiesHelper';
import { StyleHelper } from '@helpers/styleHelper';

import { UIComponent } from '@editor/comps';
import { EditorCommand, getCommandByHotKey } from '@editor/core';
import { MinRichTextSize, FontBoxScale } from '@consts/fonts';
import { MouseButtons } from '@/consts/enums/mouseButton';
import { Cursors } from '@consts/resources/cursor';
import EditorContext from '@contexts/editor';

import CustomCaret from './CustomCaret';

import './index.scss';

const RICH_TEXT_EDITOR_ID = 'rich-text-editor';

type FontStyle = 'italic' | 'bold' | 'underline' | 'strike';

export interface IRichTextEditorProps {
  value: string;
  style: React.CSSProperties;
  align?: 'left' | 'center' | 'right';
  onlyText?: boolean;
  className: string;
  wrap?: boolean;
  multi?: boolean;
  autoSize?: boolean;
  vertical?: boolean;
  ownerComp: UIComponent;
  autoSave?: boolean;
  /**
   * TODO：当此编辑器在表格中使用时：
   * 1. enter：提交并退出编辑；ctrl(/command)+enter: 文字回车。(原enter：文字回车；ctrl(/command) + enter: 提交并退出编辑)；
   * 2. 关闭定时自动提交this.doStartAutoSave，关闭回车自动保存；
   */
  useInTable?: boolean;
  onTyped?: () => void;
  onValueChanged: (value: string, option: { wrap?: boolean; size?: ISize }, holdEdit?: boolean) => void;
  onSelectChanged: () => void;
  //输入框大小变动回调
  onSizeChange?: (width: number, height: number) => void;
}

interface IRichTextEditorState {
  value: string;
  style: React.CSSProperties;
  height?: number;
  customCaret?: boolean;
  showSelectionTextRange?: boolean;
}

interface ISelectNodes {
  focusNode: Node | null;
  anchorNode: Node | null;
  anchorOffset?: number;
  focusOffset?: number;
}

/**
 * 自动保存时间间隔
 * @type {number}
 */
const AUTO_SAVE_TIME = 3e4;

export default class RichTextEditor extends React.Component<IRichTextEditorProps, IRichTextEditorState>
  implements ITextEditor {
  inputRef: React.RefObject<HTMLDivElement> = React.createRef();

  private isChanged = false;
  private _focused: boolean = false;
  static contextType = EditorContext;
  context: React.ContextType<typeof EditorContext> | undefined;
  private selRange: ISelectNodes = { focusNode: null, anchorNode: null };
  private isDisabledEnter: boolean = false;

  private defaultWrap?: boolean;
  private defaultSize: ISize;
  private lockedSize?: boolean;
  private originalSize: { width: number; height: number };
  private timeout?: Timeout;
  private interval?: Timeout;

  constructor(props: IRichTextEditorProps) {
    super(props);
    this.defaultWrap = props.wrap;
    this.lockedSize = this.defaultWrap === true;
    const { size } = props.ownerComp;
    this.defaultSize = { ...size };
    this.originalSize = { ...size };
    let height: number | undefined = size.height;
    if (!props.onlyText) {
      height = undefined;
    }
    this.state = {
      style: props.style,
      value: props.value,
      height,
    };
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.handleWindowMouseDown, true);
    window.addEventListener('mouseup', this.handleWindowMouseUp);
    window.addEventListener('beforeunload', this.handleUnload);
    // FIXME Mxj addEventListener的方式没有被触发
    document.onvisibilitychange = this.handleDocumentHidden;
    this.doFocusWhenCreated();
    this.doStartAutoSave();
    this.context!.uiManager.textEditor = this;
    this.context?.uiManager.showRichTextProperties(true);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleWindowMouseDown, true);
    window.removeEventListener('mouseup', this.handleWindowMouseUp);
    window.removeEventListener('beforeunload', this.handleUnload);
    document.onvisibilitychange = null;
    if (this.timeID) {
      clearTimeout(this.timeID);
    }
    this.clearAutoSaveTimer();
    this.context!.uiManager.textEditor = undefined;
    this.context?.uiManager.showRichTextProperties(false);
  }

  UNSAFE_componentWillReceiveProps(nextProps: IRichTextEditorProps) {
    const { style, useInTable } = nextProps;
    let newStyle = style;
    if (this.defaultWrap) {
      newStyle = this.state.style;
    }
    this.setState(
      {
        style: newStyle,
      },
      () => {
        // 当从外部更新后，获取焦点，并定位到最后输入的位置
        !useInTable && this.focus();
      },
    );
  }

  private handleDocumentHidden = () => {
    if (document.visibilityState === 'hidden') {
      this.doSubmit();
    }
  };

  private clearAutoSaveTimer() {
    if (this.interval) {
      window.clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  private handleUnload = () => {
    this.autoSubmit();
  };

  private doStartAutoSave() {
    if (this.props.useInTable) {
      return;
    }
    this.clearAutoSaveTimer();
    // 30s自动提交一次
    if (this.props.autoSave) {
      this.interval = window.setInterval(() => {
        if (this.isComposition) {
          return;
        }
        this.autoSubmit();
      }, AUTO_SAVE_TIME);
    }
  }

  private doFocusWhenCreated() {
    setTimeout(() => {
      if (!this.inputRef.current) {
        return;
      }

      this.inputRef.current?.focus();
      this._focused = true;
      const selection = document.getSelection();
      selection?.selectAllChildren(this.inputRef.current!);
      this.doRecordSelection();
    }, 100);
  }

  private get currentValue() {
    return this.inputRef.current!.innerHTML;
  }

  private autoSubmit = () => {
    if (this.isChanged) {
      this.doSubmit(true);
    }
  };

  focus(): void {
    // 当前焦点元素就是本身，就跳过
    if (document.activeElement === this.inputRef.current) {
      return;
    }
    this.setContentEditable('true');
    this._focused = true;
    const selection = document.getSelection()!;
    const { anchorOffset, anchorNode, focusOffset, focusNode } = this.selRange;
    if (selection && anchorNode && focusNode) {
      this.inputRef.current?.focus();
      const anchor = anchorOffset || 0;
      const extend = focusOffset || 0;
      if (extend <= focusNode.textContent!.length && anchor <= anchorNode.textContent!.length) {
        if (typeof selection.setBaseAndExtent === 'function') {
          selection.setBaseAndExtent(anchorNode, anchor, focusNode, extend);
        } else if (typeof selection.addRange === 'function') {
          const range = document.createRange();
          range.setStart(anchorNode, anchor);
          range.setEnd(focusNode, extend);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    } else {
      this.inputRef.current?.focus();
    }
  }

  blur() {
    this._focused = false;
    this.inputRef.current?.blur();
  }

  doHideCustomCaret() {
    this.setState({ customCaret: false });
  }

  doShowCustomCaret() {
    this.setState({ customCaret: true });
  }

  get focused(): boolean {
    return this._focused;
  }

  get isSelected(): boolean {
    const selection = document.getSelection();
    if (selection) {
      if (selection.isCollapsed) {
        return false;
      }
      let node = selection.focusNode;
      if (node) {
        if (node.nodeType === Node.TEXT_NODE) {
          node = node.parentNode;
        }
        let dom: HTMLElement | undefined = node as HTMLElement;
        if (this.inputRef.current!.contains(dom) || dom === this.inputRef.current) {
          return true;
        }
      }
    }
    return false;
  }

  get isRichTextEditorInputting(): boolean {
    return document.activeElement?.id === RICH_TEXT_EDITOR_ID;
  }

  getFormat() {
    document.execCommand('styleWithCSS');
    let fontSize = 28;
    const selection = document.getSelection();
    if (selection) {
      let node = selection.focusNode;
      if (node) {
        if (node === this.inputRef.current) {
          if (this.inputRef.current.childElementCount > 0) {
            node = this.inputRef.current.firstChild!;
          }
        }
        if (node.nodeType === Node.TEXT_NODE) {
          node = node.parentNode;
        }
        let dom: HTMLElement | undefined = node as HTMLElement;
        while (!dom.style.getPropertyValue('font-size')) {
          if (!dom.hasAttribute('contenteditable')) {
            if (dom?.parentElement) {
              dom = dom.parentElement!;
            } else {
              dom = undefined;
              break;
            }
          } else {
            break;
          }
        }
        const fontSizeValue = dom?.style.getPropertyValue('font-size');
        if (fontSizeValue) {
          fontSize = parseInt(fontSizeValue.replace('px', ''), 10);
        }
      }
    }
    let textAlign = TextAlign.left;
    if (document.queryCommandState('justifyRight')) {
      textAlign = TextAlign.right;
    } else if (document.queryCommandState('justifyCenter')) {
      textAlign = TextAlign.center;
    } else if (document.queryCommandState('justifyFull')) {
      textAlign = TextAlign.justify;
    }
    return {
      fontStyle: {
        italic: document.queryCommandState('italic'),
        bold: document.queryCommandState('bold'),
        underline: document.queryCommandState('underline'),
        strike: document.queryCommandState('strikeThrough'),
      },
      orderList: document.queryCommandState('insertOrderedList'),
      unOrderList: document.queryCommandState('insertUnorderedList'),
      color: document.queryCommandValue('foreColor'),
      fontSize,
      textAlign,
      fontFamily: document.queryCommandValue('fontName').replace(/"/g, ''),
    };
  }

  private resetFontSize(model: 'increase' | 'decrease') {
    this.focus();
    const format = this.getFormat();
    const { fontSize } = format;
    const { textFormat } = this.context?.coreEditor?.firstSelectedComponent!;
    const lineHeight = textFormat?.lineHeightEx!;
    let newFontSize = fontSize;
    if (model === 'increase') {
      newFontSize = Math.min(300, fontSize + 1 / FontBoxScale);
    } else {
      newFontSize = Math.max(MinRichTextSize / FontBoxScale, fontSize - 1 / FontBoxScale);
    }
    execCommandFontSize('.rich-text-editor', newFontSize, lineHeight);
  }

  changeFormatWithHotKey(hotKey: string) {
    if (hotKey === 'ctrl+[') {
      this.resetFontSize('decrease');
    } else if (hotKey === 'ctrl+]') {
      this.resetFontSize('increase');
    }
    this.isChanged = true;
    if (textStyleShortCut.includes(hotKey)) {
      setTimeout(() => {
        this.doSelectedChanged();
      }, 10);
    }
  }

  setFontSize(size: number, disabledEnter?: boolean): void {
    window.addEventListener('keyup', this.handleFontSizeKeyUp, { once: true });
    // const lineHeight = this.context.coreEditor.firstSelectedComponent!.properties.multiText?.lineHeight;
    const comp = this.context?.coreEditor?.firstSelectedComponent;
    if (comp) {
      const { textFormat } = comp;
      execCommandFontSize('.rich-text-editor', size, textFormat?.lineHeightEx!);
      this.doRecordSelection();
    }
    if (disabledEnter) {
      this.setContentEditable('false');
    }
    this.isDisabledEnter = !!disabledEnter;
    this.focus();
  }

  setContentEditable(status: 'true' | 'false') {
    if (!this.inputRef.current) {
      return;
    }
    this.inputRef.current.contentEditable = status;
  }

  /**
   *
   * @param {string} command
   * @param value
   * @param {boolean} param
   * @param {Function} fn
   * @implements ITextEditor.execRichTextCommand
   */
  execRichTextCommand = (command: string, value?: any, param?: boolean, fn?: Function) => {
    applyFontStyleToLI(command, value);
    this.focus();
    const format = this.getFormat();
    const commandName = getExecuteCommandName(command);
    if (!['fontSize', 'foreColor'].includes(commandName)) {
      let needApply = true;
      let lastValue: any;
      let isApplyFontStyle = false;
      if (['italic', 'bold', 'underline', 'strike'].includes(command)) {
        if (format) {
          const style = format.fontStyle || {};
          const k = command as FontStyle;
          lastValue = style[k];
          isApplyFontStyle = true;
          if (value === lastValue) {
            needApply = false;
          }
        }
      }
      // 需要被修改
      if (needApply) {
        if (isApplyFontStyle && lastValue) {
          // B|I|U|S && 取消
          document.execCommand('styleWithCSS', false, 'true');
          document.execCommand(commandName, false, value);
        } else {
          document.execCommand(commandName, false, value);
        }
      }
    } else if (commandName === 'foreColor') {
      document.execCommand('foreColor', false, value);
    } else if (commandName === 'fontSize') {
      this.setFontSize(value, param);
    }
    document.execCommand('styleWithCSS', false, 'true');
    this.isChanged = true;
    // FIXME 这里立即要把焦点转移走，否则可能会把选中的文本通过回车替换掉
    param && this.blur();
    fn && fn();
  };

  private timeID: Timeout | undefined;

  handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const { vertical } = this.props;
    const { types } = e.clipboardData;
    let text: string;
    let needWrap = false;
    const str = (text = e.clipboardData.getData('text/plain'));
    this.isChanged = true;
    if (types.includes('text/html')) {
      let pasteHtml = e.clipboardData.getData('text/html');
      // 过滤掉A内的标签，只保留文本
      const doc = new DOMParser().parseFromString(pasteHtml, 'text/html');
      doc.querySelectorAll('a').forEach((el) => {
        el.innerHTML = el.textContent || '';
      });
      pasteHtml = doc.documentElement.outerHTML;
      text = filterHTMLText(
        pasteHtml,
        this.props.multi,
        (allowedTags, allowedStyles) => {
          delete allowedStyles['font-size'];
          delete allowedStyles['line-height'];
          delete allowedStyles['letter-spacing'];
          const index = allowedTags.indexOf('a');
          if (index !== -1) {
            allowedTags.splice(index, 1);
          }
        },
        {
          isHTML: true,
          transformTags: {
            h1: 'p',
            h2: 'p',
            h3: 'p',
            h4: 'p',
            h5: 'p',
            h6: 'p',
          },
        },
      ).trim();

      document.execCommand('styleWithCSS');
      if (document.execCommand('insertHTML', true, text)) {
        needWrap = true;
        this.defaultWrap = needWrap;
        this.setState({
          style: { ...this.state.style, whiteSpace: 'pre-wrap', wordWrap: 'break-word' },
        });
        return;
      }
    }
    if (types.includes('text/plain')) {
      text = str;
      needWrap = str.length > 200;
      document.execCommand('insertText', false, text);
    }
    if (needWrap) {
      this.defaultWrap = true;
      this.lockedSize = true;
      if (!vertical) {
        this.defaultSize.width = Math.max(this.defaultSize.width, 300);
      } else {
        this.defaultSize.height = Math.max(this.defaultSize.height, 300);
      }
    }
  };

  doCalculateSizeWithOnlyOneLineText = () => {
    const dom = this.inputRef.current!;
    const value = dom.innerHTML;
    const { autoSize, vertical } = this.props;
    const style: React.CSSProperties = {
      ...this.state.style,
      whiteSpace: this.defaultWrap ? 'break-spaces' : 'pre',
    };
    if (autoSize) {
      delete style.width;
      delete style.height;
      const { width, height } = measureTextSize(style, value, {
        isMultiText: true,
        wrap: this.defaultWrap,
        isRich: true,
        editing: true,
        defaultWidth: !vertical && this.lockedSize ? this.defaultSize.width : undefined,
        defaultHeight: vertical && this.lockedSize ? this.defaultSize.height : undefined,
      });
      if (this.defaultWrap) {
        if (vertical) {
          style.width = 'auto';
          style.height = Math.max(height, this.originalSize.height);
          style.minHeight = Math.max(height, this.originalSize.height) / FontBoxScale;
        } else {
          // style.width = Math.max(width, this.originalSize.width);
          style.width = 'auto';
          style.minWidth = Math.max(width, this.originalSize.width) / FontBoxScale;
          style.height = 'auto';
        }
        style.whiteSpace = 'pre';
        style.wordWrap = 'break-word';
      } else {
        style.whiteSpace = 'pre';
        style.wordWrap = 'normal';
      }
      if (vertical) {
        style.right = 0;
        delete style.left;
        style.position = 'absolute';
      }
      this.defaultSize = {
        width: Math.max(width, this.originalSize.width),
        height: Math.max(height, this.originalSize.height),
      };
      this.setState({ /* value,  */ style });
    } else {
      style.whiteSpace = 'pre-wrap';
    }
  };

  handleValueChange = (e: React.FormEvent) => {
    let nativeEvent = e.nativeEvent as InputEvent;
    const { onTyped } = this.props;
    // 如果是历史回退操作阻止进入编辑模式，引起的值变化
    // 处理在选中cell，rich进入假编辑状态（只是移动到看不见的地方了，并且已聚焦），导致按ctrl+z会引起值变化会触发该事件
    if (nativeEvent.inputType !== 'historyUndo' && nativeEvent.inputType !== 'historyRedo') {
      onTyped && onTyped();
    }
    if (this.isComposition) {
      this.handleEditorSizeChange();
      return;
    }
    if (!this.isDisabledEnter) {
      this.doRecordSelection();
    }
    if (this.timeID) {
      clearTimeout(this.timeID);
    }
    this.isChanged = true;
    this.timeID = window.setTimeout(() => {
      this.doCalculateSizeWithOnlyOneLineText();
      this.handleEditorSizeChange();
    });
  };

  //每次输入导致的容器width&height变化检测
  handleEditorSizeChange = () => {
    const { onSizeChange } = this.props;
    if (!this.inputRef.current || !onSizeChange) {
      return;
    }
    const { vertical } = this.props;
    const dom = this.inputRef.current!;
    const value = dom.innerHTML;
    const style: React.CSSProperties = {
      ...this.state.style,
      whiteSpace: this.defaultWrap ? 'break-spaces' : 'pre',
    };
    let { width, height } = measureTextSize(style, value, {
      isMultiText: true,
      wrap: this.defaultWrap,
      isRich: true,
      editing: true,
      defaultWidth: !vertical && this.lockedSize ? this.defaultSize.width : undefined,
      defaultHeight: vertical && this.lockedSize ? this.defaultSize.height : undefined,
    });
    onSizeChange(Math.max(width, this.originalSize.width), Math.max(height, this.originalSize.height));
  };

  private isComposition: boolean = false;

  handleCompositionStart = () => {
    this.isComposition = true;
  };

  handleCompositionEnd = () => {
    this.isComposition = false;
    this.isChanged = true;
    this.doCalculateSizeWithOnlyOneLineText();
  };

  private doSubmit = (holdEdit?: boolean) => {
    this._focused = holdEdit || false;
    const newValue = this.currentValue;
    const value = replaceOldFontSize(newValue, 'in');

    !holdEdit && document.getSelection()?.empty();
    const oldTextFormat = this.props.ownerComp.textFormat;
    if (oldTextFormat) {
      const textFormat = upgradeTextFormatForApply({ ...oldTextFormat }, value);
      if (!isEqual(oldTextFormat, textFormat)) {
        if (textFormat.color) {
          textFormat.color = transColorToRGBA(textFormat.color);
        }
        this.context?.coreEditor?.setProperty(TextFormatExPropertyName, textFormat);
      }
      // 更新属性面板
      this.context?.uiManager.updateTextFormat();
    }
    const { onValueChanged } = this.props;
    this.doRecordSelection();
    onValueChanged && onValueChanged(value, {}, holdEdit);
    this.isChanged = false;
  };

  cancel() {
    if (this.isChanged) {
      this.doSubmit();
    }
  }

  reloadValue() {
    this.selRange = { focusNode: null, anchorNode: null };
    // const value = this.props.value;
    this.setState(
      {
        style: this.props.style,
        value: this.props.value,
      },
      () => {
        // 重置输入框内容
        const input = this.inputRef.current;
        const text = replaceOldFontSize(this.state.value as string, 'out');
        if (input) {
          input.innerHTML = text;
          const selection = document.getSelection();
          selection?.selectAllChildren(input);
          input.focus();
          this.doRecordSelection();
        }
      },
    );
  }

  handleMouseUp = (e: React.MouseEvent) => {
    this.setState({ showSelectionTextRange: false });
    this.doRevertEditable();
    e.stopPropagation();
    this.doSelectedChanged();
    document.getSelection()?.removeAllRanges;
  };

  handleDragEnd = () => {
    this.setState({ showSelectionTextRange: false });
  };

  doSelectedChanged() {
    const { onSelectChanged } = this.props;
    onSelectChanged();
  }

  doRevertEditable() {
    if (this.inputRef.current && !this.inputRef.current.isContentEditable) {
      this.doRecordSelection();
      this.setContentEditable('true');
      setTimeout(() => {
        this.focus();
      });
    }
  }

  handleWindowMouseUp = () => {
    this.setState({ showSelectionTextRange: false });
    if (this.inputRef.current) {
      if (!this.inputRef.current.isContentEditable) {
        this.doRecordSelection();
        this.doSelectedChanged();
      }
    }
  };
  handleWindowMouseDown = (e: MouseEvent) => {
    const self = this.inputRef.current;
    //点的是自己不做操作
    if (self) {
      if (self.contains(e.target as HTMLElement)) {
        return;
      }

      // const bounds = BoundsUtils.create(self.getBoundingClientRect());

      const { pageX: left, pageY: top } = e;
      // TODO 现在内容超出时，不可显示，不用这部分判断
      //这里也是附近扩大5px的位置
      // if (BoundsUtils.isContainerPoint(bounds, { left, top })) {
      //   e.stopPropagation();
      //   return;
      // }

      // 点击到右侧面板或左侧面板中的颜色和文本样式时
      let dom = document.querySelector('.right-panel');
      if (dom) {
        const bounds = dom.getBoundingClientRect();
        if (BoundsUtils.isContainerPoint(bounds, { left: e.pageX, top: e.pageY })) {
          return;
        }
      }

      // 点到左侧面板的设计资源
      dom = document.querySelector('.resource-libraries-group-list');
      if (dom) {
        const bounds = dom.getBoundingClientRect();
        if (BoundsUtils.isContainerPoint(bounds, { left: e.pageX, top: e.pageY })) {
          return;
        }
      }

      // 点击到弹出上时
      dom = document.querySelector('.popup-with-body');
      if (dom && dom.contains(e.target as HTMLElement)) {
        return;
      }

      // 拾色器
      dom = document.querySelector('.dsm-c-rp-color-collector');
      if (dom && dom.contains(e.target as HTMLElement)) {
        return;
      }

      const editor = this.context?.coreEditor;
      if (editor && editor.firstSelectedComponent?.type !== 'line') {
        const parent = self.parentElement! as HTMLElement;
        const bounds = BoundsUtils.create(parent.getBoundingClientRect());
        if (BoundsUtils.isContainerPoint(bounds, { left, top })) {
          e.stopPropagation();
          return;
        }
      }

      this.doSubmit();
    }
  };

  doClearCommand = () => {
    const selection = document.getSelection();
    if (!selection) {
      return;
    }
    if (selection.focusOffset !== 0 || selection.anchorOffset !== 0) {
      return;
    }
    // FIXME https://redmine.jongde.com/issues/92808
    if (!this.currentValue) {
      return;
    }
    document.execCommand('outdent', false);
    document.queryCommandValue('insertOrderedList') === 'true' && document.execCommand('insertOrderedList', false);
    document.queryCommandValue('insertUnorderedList') === 'true' && document.execCommand('insertUnorderedList', false);
    this.isChanged = true;
  };

  // 按下tab
  doIndent = () => {
    const selection = document.getSelection();
    if (!selection?.rangeCount) {
      return;
    }
    const range = selection.getRangeAt(0);
    if (range.collapsed) {
      // 已折叠（输入中）
      document.execCommand('insertHTML', false, '&nbsp;&nbsp;');
    } else {
      range.collapse();
    }
  };

  // 按下 shift + tab
  doOutdent = () => {
    const selection = document.getSelection();
    if (!selection?.rangeCount) {
      return;
    }
    const range = selection.getRangeAt(0);
    const container = range.commonAncestorContainer as HTMLElement;
    if (range.collapsed) {
      // 光标跟随
      execCommandOutdent(container);
      const { nodeType, innerText, nodeName, nodeValue } = container;
      const isNull = (nodeType === 1 && !innerText && nodeName === 'SPAN') || (nodeType === 3 && !nodeValue);
      // 删除无内容元素
      isNull && container.remove();
    } else {
      range.collapse();
    }
  };

  doCancelOutdent() {
    document.execCommand('outdent', false);
    this.isChanged = true;
  }

  doInsertParagraph() {
    document.execCommand('InsertParagraph');
    this.isChanged = true;
  }

  handleKeyUp = (e: KeyboardEvent | React.KeyboardEvent) => {
    e.keyCode === KeyCodeMap.VK_TAB && e.preventDefault();
  };

  private lastEnterKeyTime: number = 0;

  handleKeyDown = (e: KeyboardEvent | React.KeyboardEvent) => {
    const { keyCode, key, altKey, shiftKey } = e;

    if (keyCode === KeyCodeMap.VK_TAB) {
      e.preventDefault();
      shiftKey ? this.doOutdent() : this.doIndent();
    }

    window.clearTimeout(this.timeout);
    const oldSelected = this.isSelected;
    // 按下键盘可能文本选择发生变化
    this.timeout = window.setTimeout(() => {
      if (oldSelected !== this.isSelected) {
        this.doSelectedChanged();
      }
    }, 10);

    // @ts-ignore
    const hotKey = convertEventToHotKey(e);
    const isEnter = key.toLowerCase() === 'enter'; // 在合成输入时，使用keyCode判断enter会存在问题
    const command = getCommandByHotKey(hotKey);
    // 如果是在输入状态，并且不是撤销和重做动作就阻止冒泡
    if (this.isChanged || (command !== EditorCommand.Undo && command !== EditorCommand.Redo)) {
      e.stopPropagation();
    }
    // @ts-ignore
    stopBubbleWhenSortCut(e);
    if (this.isDisabledEnter) {
      return;
    }
    // @ts-ignore
    const ctrlKey = isControlKeyPressed(e);

    this.changeFormatWithHotKey(hotKey);

    const { wrap, useInTable } = this.props;
    if (!wrap && !ctrlKey && !altKey && isEnter) {
      this.defaultWrap = true;
      this.lockedSize = true;
    }

    const isExitEditor = keyCode === KeyCodeMap.VK_ESCAPE;
    const isSubmitWithCtrlAndEnter = isEnter && ctrlKey;
    if (isExitEditor || (!altKey && !useInTable && isSubmitWithCtrlAndEnter)) {
      //FIXME MengXiaoJiang 2022-11-06 ctrl + enter时，阻止冒泡，此快捷键在其它场景有使用
      isSubmitWithCtrlAndEnter && e.stopPropagation();
      this.doSubmit();
      return;
    }

    // 回车自动保存
    if (!ctrlKey && !altKey && isEnter) {
      const time = Date.now();
      if (!this.lastEnterKeyTime) {
        this.lastEnterKeyTime = time;
      }
      // 距离上次回车成功保存超过 5s 时，才可以提交
      if (time - this.lastEnterKeyTime > 5e3) {
        this.doSubmit(true);
        this.clearAutoSaveTimer();
        this.focus();
        this.lastEnterKeyTime = time;
        if (!this.interval) {
          this.doStartAutoSave();
        }
      }
    }

    // 处理表格文字换行， shift不需要
    if (useInTable && hotKey === 'alt+enter') {
      this.doInsertParagraph();
    }

    if (keyCode === KeyCodeMap.VK_BACKSPACE) {
      this.doClearCommand();
    }

    //TODO 2022-11-06 MengXiaoJiang 此行似乎多余，在前面已被处理过
    // e.shiftKey && e.keyCode === KeyCodeMap.VK_TAB && this.doCancelOutdent();
  };

  handleFontSizeKeyUp = () => {
    this.isDisabledEnter = false;
    this.setContentEditable('true');
    this.focus();
  };

  handleFocus = () => {
    this._focused = true;
    this.doShowCustomCaret();
  };

  /**
   * 验证是否有选中区
   */
  doCheckSelection() {
    const selection = document.getSelection();
    if (!selection) {
      return;
    }
    // 针对safari浏览器点击非文本框会失焦的问题做处理
    if (this.selRange.anchorNode && selection.rangeCount === 0) {
      // 处理blur再聚焦导致，无法从左边组件库拖拽组件
      setTimeout(() => {
        this.focus();
      });
    }
  }

  handleBlur = (e: React.FocusEvent) => {
    this.doCheckSelection();
    e.preventDefault();
    this._focused = false;
    this.doHideCustomCaret();
  };

  handleSelect = () => {
    this.doRecordSelection();
    this.context?.uiManager.updatePropertiesPanel();
  };

  doRecordSelection = () => {
    const selection = document.getSelection()!;

    if (selection.type !== 'None') {
      this.selRange.anchorNode = selection.anchorNode;
      this.selRange.focusNode = selection.focusNode;
      this.selRange.anchorOffset = selection.anchorOffset;
      this.selRange.focusOffset = selection.focusOffset;
    }
  };

  handleMouseDown = (e: React.MouseEvent) => {
    this.setState({ showSelectionTextRange: true });
    if (e.buttons !== MouseButtons.Wheel) {
      e.stopPropagation();
    }
    const selection = window.getSelection();
    const { focusNode, focusOffset, anchorNode, anchorOffset } = this.selRange;
    if (selection && focusNode && anchorNode && e.shiftKey) {
      const range = document.createRange();
      range.setStart(anchorNode, anchorOffset || 0);
      range.setEnd(focusNode, focusOffset || 0);
      selection.addRange(range);
    }
    dragDelegate(
      () => {},
      () => {
        /**
         * NOTE：
         * 选择文本，点击字号输入框有时会出发这里的逻辑，所以这里加个保护判断,
         * 避免更新富文本编辑器中的 selection。
         */
        if (!this.isRichTextEditorInputting) {
          return;
        }

        this.doRevertEditable();
        this.doRecordSelection();
      },
    );
  };

  handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  /**
   * 阻止默认复制行为，预处理需要复制的内容
   */
  handleCopy = (e: React.ClipboardEvent) => {
    const selection = document.getSelection();
    const range = selection?.getRangeAt(0);
    if (range) {
      const parser = StyleHelper.initCSSStyleParser(this.props.ownerComp.properties);
      const wrapCssStyle = Array.from(
        Object.entries({
          ...parser.getTextStyle(),
        }),
      )
        .reduce((styles, [name, value]) => {
          if (value === undefined || value === null) {
            return styles;
          }
          if (name === 'fontSize') {
            value += 'px';
          }
          styles.push(`${kebabCase(name)}:${value as string}`);

          return styles;
        }, [] as string[])
        .join(';');
      // 把将要复制的内容的字体大小，预处理一次。因为系统的字体
      const tempDiv = document.createElement('div');
      const tempComment = document.createComment(ClipboardType.RICH_HTML_COPY); // 注释标识是不是来自内部的复制
      const wrap = document.createElement('div');
      wrap.appendChild(tempComment);
      wrap.appendChild(range.cloneContents());
      tempDiv.appendChild(wrap);
      const queue = Array.from(tempDiv.children);
      while (queue.length) {
        const current = queue.shift() as HTMLElement;
        if (current.nodeType !== Node.ELEMENT_NODE) {
          continue;
        }
        if (current.nodeName.toLowerCase() === 'font' && current.hasAttribute('size')) {
          current.setAttribute('size', parseFloat(current.getAttribute('font') as string) * FontBoxScale + '');
        } else if (current.style.fontSize) {
          current.style.fontSize = parseFloat(current.style.fontSize) * FontBoxScale + 'px';
        }
        const childrenCount = current.children.length;
        if (childrenCount) {
          for (let i = 0; i < childrenCount; i++) {
            queue.push(current.children[i]);
          }
        }
      }
      // 位置不要动，保证不受fontSize处理影响
      wrap.style.cssText = wrapCssStyle;
      const text = selection ? selection.toString() : '';
      e.clipboardData.setData('text/plain', text);
      e.clipboardData.setData('text/html', tempDiv.innerHTML);
    }
    e.preventDefault();
  };

  // 自定义焦点
  renderCustomCaret() {
    const {
      style: { textAlign, color },
      customCaret,
    } = this.state;
    if (!customCaret) {
      return null;
    }
    const {
      vertical,
      ownerComp: { rotateRelativeToArtboard },
    } = this.props;
    // FIXME 暂时不处理旋转和竖排的情况
    if (!this.canCustomCaret()) {
      return null;
    }
    return <CustomCaret rotate={rotateRelativeToArtboard} color={color} vertical={vertical} textAlign={textAlign} />;
  }

  canCustomCaret = (): boolean => {
    return false;
  };

  render() {
    const { className, onlyText, vertical } = this.props;
    const style = { ...this.state.style };
    const value = replaceOldFontSize(this.state.value as string, 'out');
    const textRangeStyle: React.CSSProperties = {
      display: this.state.showSelectionTextRange ? 'block' : 'none',
    };

    return (
      <>
        <div className="selection-text-range" style={textRangeStyle}></div>
        <div
          ref={this.inputRef}
          id={RICH_TEXT_EDITOR_ID}
          className={classnames(className, 'rich-text-editor', {
            'only-text': onlyText,
            vertical,
            'no-wrap': !this.defaultWrap,
            'custom-caret': this.canCustomCaret(),
          })}
          // 取消掉 textDecorationLine 是为了编辑内容的 U|S 时，样式加在选中的内容上，而不是调整此根元素
          // 这里旧数据在进入编辑时会显示没有 U|S，但重新编辑一次后就好了
          style={{
            ...style,
            cursor: `url(${Cursors.DrawText}) 12 12, text`,
            textDecorationLine: undefined,
            minHeight: style.minHeight || '1px', // 可能chrome渲染有关,在空文本时,输入中文,需要保证可编辑元素有一定的空间
            minWidth: style.minWidth || '1px',
          }}
          contentEditable={true}
          onInput={this.handleValueChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          onDragEnd={this.handleDragEnd}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          onCopy={this.handleCopy}
          onPaste={this.handlePaste}
          onSelect={this.handleSelect}
          onCompositionStart={this.handleCompositionStart}
          onCompositionEnd={this.handleCompositionEnd}
          onContextMenu={this.handleContextMenu}
          dangerouslySetInnerHTML={{ __html: value }}
          spellCheck={false}
        />
        {this.renderCustomCaret()}
      </>
    );
  }
}
