// 编辑器核心 CoreEditor
//
// CoreEditor 使用继承的方式将代码按照业务进行分离，防止代码膨胀，继承顺序，顺序是有意义的，不能随意调整：
//
//   - [CoreBase](./corePartial/base.ts)      通知更新，或许后续还会把提交数据到服务器放到其中
//   - [CoreActive](./corePartial/active.ts)
//   - [CoreSelect](./corePartial/select.ts)  选中相关： select/un select/remove selected 等
//   - [CoreState](./corePartial/state.ts)
//   - [CoreGroup](./corePartial/group.ts)    编组相关：group/un group
//   - [CoreDoc](./corePartial/doc.ts)        文档相关：update/undo/redo/can undo/can redo 等
//   - [CoreBox](./corePartial/box.ts)        位置、大小相关： move/resize 等
//   - [CoreInteraction](./corePartial/interaction.ts)
//   - [CoreStyle](./corePartial/style.ts)    风格编辑相关
//
//  如果还有其它需求，也考虑这种拆分方式。
import * as _ from 'lodash';
import { cloneDeep, flatten, unescape } from 'lodash';
import * as shortid from 'shortid';
import * as sanitizeHtml from 'sanitize-html';

import { initBoundsWithPositionAndSize, isIntersect, union } from '@utils/boundsUtils';
import { depthClone, sameNumber, roundNumberObject } from '@utils/globalUtils';
import { adjustHTMLLineHeight, filterHTMLText, deleteHTMLNoHeightEmptyLine } from '@utils/textUtils';

import { CustomError } from '@/fbs/common/models/error';
import { IUserPreference } from '@/fbs/rp/models/grid';
import { EventType, IActionBase, IInteraction } from '@/fbs/rp/models/interactions';
import { IBounds, IPosition, ISize } from '@fbs/common/models/common';
import IArtboard, { IArtboardMetaPatch, IArtboardAddPatch } from '@fbs/rp/models/artboard';
import { IComponentData, IComponentSize } from '@fbs/rp/models/component';
import { SessionInfo } from '@fbs/rp/models/io';
import { IBasicLayout } from '@fbs/rp/models/layout';
import { IProperties } from '@fbs/rp/models/property';
import { ArtboardPatches, Operation, Ops, PageOperations, PagePatches, ReplaceOperation } from '@fbs/rp/utils/patch';

import { getSupportEventsInfo } from '@/helpers/interactionHelper';
import {
  convertUIOperationToPagePatches,
  getEmptyArtboardPatchesOfArtboardSelf,
  mergePatches,
} from '@/helpers/patchHelper';
import { modifyPathWithOffset } from '@/helpers/pathFinderHelper';
import {
  getCopiedArtboardData,
  copyArtboardData,
  getCopiedComponentData,
  copiedDataType,
  copyStyleData,
  getCopiedStyleData,
  ClipboardType,
  copyComponentDataV2,
  ICopiedComponentData,
} from '@helpers/clipboardHelper';
import {
  getBoundsInParent,
  tansPointInArtBoardToGroup,
  findChildrenByFiltering,
  getMaxIndexOfComponents,
  findParentByFiltering,
  getRefArtboardsData,
} from '@helpers/componentHelper';
import editorValidate from '@helpers/editorValidate';
import {
  groupFiles,
  batchUploadFiles,
  FileType as UploadFileType,
  BatchUploadFilesItem,
  uploadFile,
  blobToFile,
} from '@helpers/fileUploadHelper';
import { getNewID } from '@helpers/idHelper';
import {
  mergePropertyCacheToComp,
  getPublicPropertiesByComps,
  getNewPropertiesByAllowProperties,
} from '@helpers/propertiesHelper';
import { getCompDataIntegratedPosition } from '@helpers/propertyPanelHelper';
import { getCenter, getNWPoint } from '@helpers/rotateHelper';
import { getNewStateByAllowStateAndProperties, getSupportStates } from '@helpers/stateHelper';
import MockClipboard from '@/helpers/MockClipboard';
import {
  contenPanelLayoutLineLimit,
  setArtboardSyncLayoutPatch,
  checkContainContentPanel,
  checkContainContentPanelV2,
} from '@/helpers/contentPanelv2Helper';
import { copyArtboardName } from '@/helpers/stringHelper';

import i18n, { languageManager } from '@i18n';

import {
  getComponent,
  getNameForNewComponent,
  makeImageComponent,
  makeVideoComponent,
  makeAudioComponent,
} from '@libs/libs';
import { CLine, CSymbol, CText, CContentPanelV2 } from '@libs/constants';

import designManager from '@/managers/DesignManager';
import IDesignManager from '@/managers/DesignManager/types/custom';

import apis from '@apis';

import ComponentAlignType from '@consts/enums/comAlignType';
import ValueEditorType from '@consts/enums/valueEditorType';
import ZOrderModel from '@consts/enums/zOrderType';
import { MaxImageDimention, MaxSimultaneouslyUploadFileCount } from '@consts/file';
import RefactorRemarkManager from '@/managers/refactorRemarkManager';
import { StyleHelper } from '@/helpers/styleHelper';
import { IUICompConstructOptions } from '@/customTypes';
import { TriggerType } from '@/customTypes/interaction';
import { makeImage } from '@/libs/basic/Image/config';
import { MoveDelta } from '@/fbs/common/models/resize';

import InteractionClipboard, {
  IPasteActionBase,
} from '../components/Application/RightPanel/PropertyPanel/InteractionPanel/interactionClipboard';
import { makeText, measureTextCompInitialSize } from '../libs/basic/NewText';
import * as io from '../services/io';
import { defaultAllowedTags, defaultAllowedAttributes } from '../consts/defaultData/svgTag';
import { RemoveFragmentCommand, BatchRemoveFragmentCommand } from './commands';
import {
  UIArtboard,
  UIContainerComponent,
  UIComponent,
  UIFragment,
  UISymbolComponent,
  UIContentPanelComponent,
} from './comps';
import { createFakeArtboard } from './comps/factory';
import { cloneComponents, cloneComponentsV2, cloneArtboardDataV2 } from './coreHelper';
import CoreDesign from './corePartial/design';
import { AddComponentsOptions } from './corePartial/group';
import Doc from './document';
import { createArtboardPositionCalculator } from './strategies/ArtboardPositionCalculator';
import { getOrientationFromSize } from '@/helpers/artboardDimensionHelper';

type ExecutePasteOptions = {
  isExample: boolean;
  onStart: (total: number) => void;
  onProgress: (percent: number, total: number, completeCount: number) => void;
  onComplete: (results: BatchUploadFilesItem[], total: number) => void;
  alert: (msg: string) => void;
  showMessage: (msg: string) => void;
};
export interface IArtboardPasteInfo {
  data: IComponentData;
  position: IPosition;
}

export type IAlertInfoType = 'page' | 'fragment' | 'pageAndFragment';

type TActionsType = {
  link?: Array<[number, string]>;
  page?: Array<[number, string]>;
  fragment?: Array<[number, string]>;
};

const transformTags = {
  h1: 'p',
  h2: 'p',
  h3: 'p',
  h4: 'p',
  h5: 'p',
  h6: 'p',
};

const regulate = (allowedTags: string[], allowedStyles: { [key: string]: RegExp[] }) => {
  delete allowedStyles['font-size'];
  delete allowedStyles['line-height'];
  delete allowedStyles['letter-spacing'];
};

export enum EditorCommand {
  SelectAll,
  UnSelect,
  Delete,
  Undo,
  Redo,
  Copy,
  Artboard_Copy,
  Artboard_Paste,
  SubArtboard_Cut,
  SubArtboard_Clone,
  SubArtboard_Delete,
  PasteFromClipboard,
  Cut,
  Group,
  UnGroup,
  UnGroupMore,
  Clone,
  Lock,
  UnLock,
  Save,
  SendToBack,
  SendToFront,
  Backward,
  Forward,

  AutoSize,

  AlignLeft,
  AlignRight,
  AlignCenter,
  AlignTop,
  AlignBottom,
  AlignMiddle,
  SpaceVertical,
  SpaceHorizontal,

  QuickPlusFontSize,
  QuickLessFontSize,
  QuickSetBold,
  QuckSetItalic,
  QuckSetUnderline,
  Hidden,

  StyleCopy,
  StylePaste,
}

export function getCommandByHotKey(key: string): EditorCommand | null {
  // const enableCmdShiftGToUnGrouping = userPreference?.generalSettings.enableCmdShiftGToUnGrouping ?? false;
  switch (key) {
    case 'delete':
    case 'backspace':
      return EditorCommand.Delete;
    case 'ctrl+a':
      return EditorCommand.SelectAll;
    case 'ctrl+shift+a':
      return EditorCommand.UnSelect;
    case 'ctrl+z':
      return EditorCommand.Undo;
    case 'ctrl+shift+z':
    case 'ctrl+y':
      return EditorCommand.Redo;
    case 'ctrl+c':
      return EditorCommand.Copy;
    case 'ctrl+x':
      return EditorCommand.Cut;
    case 'ctrl+g':
      return EditorCommand.Group;
    case 'ctrl+shift+g':
      return EditorCommand.UnGroupMore; // enableCmdShiftGToUnGrouping ? EditorCommand.UnGroup : null;
    case 'ctrl+d':
      return EditorCommand.Clone;
    case 'ctrl+l':
      return EditorCommand.Lock;
    case 'ctrl+shift+l':
      return EditorCommand.UnLock;
    case 'ctrl+s':
      return EditorCommand.Save;

    case 'ctrl+]': // 1键位
    case 'ctrl+arrowup': // 2键位
      return EditorCommand.Forward;
    case 'ctrl+alt+]': // 1键位
    case 'ctrl+shift+arrowup': // 2键位
    case 'ctrl+alt+‘': // mac键位
      return EditorCommand.SendToFront;
    case 'ctrl+[': // 1键位
    case 'ctrl+arrowdown': // 2键位
      return EditorCommand.Backward;
    case 'ctrl+alt+[': // 1键位
    case 'ctrl+shift+arrowdown': // 2键位
    case 'ctrl+alt+“': // mac键位
      return EditorCommand.SendToBack;

    case 'alt+a': // 1键位
    case 'ctrl+alt+l': // 2键位
    case 'alt+å':
    case 'ctrl+alt+¬':
      return EditorCommand.AlignLeft;
    case 'alt+d': // 1键位
    case 'ctrl+alt+r': // 2键位
    case 'alt+∂':
    case 'ctrl+alt+®':
      return EditorCommand.AlignRight;
    case 'alt+h': // 1键位
    case 'alt+˙':
      return EditorCommand.AlignCenter;
    case 'alt+w': // 1键位
    case 'ctrl+alt+t': // 2键位
    case 'alt+∑':
    case 'ctrl+alt+†':
      return EditorCommand.AlignTop;
    case 'alt+s': // 1键位
    case 'ctrl+alt+b': // 2键位
    case 'alt+ß':
    case 'ctrl+alt+∫':
      return EditorCommand.AlignBottom;
    case 'alt+v': // 1键位
    case 'ctrl+alt+m': // 2键位
    case 'alt+√':
    case 'ctrl+alt+µ':
      return EditorCommand.AlignMiddle;
    case 'ctrl+shift+h':
      return EditorCommand.SpaceHorizontal;
    case 'ctrl+shift+u':
      return EditorCommand.SpaceVertical;
    case 'ctrl+u':
      return EditorCommand.QuckSetUnderline;
    case 'ctrl+i':
      return EditorCommand.QuckSetItalic;
    case 'ctrl+b':
      return EditorCommand.QuickSetBold;
    case 'ctrl+alt+-':
    case 'ctrl+alt+–':
      return EditorCommand.QuickLessFontSize;
    case 'ctrl+alt+=':
    case 'ctrl+alt++':
    case 'ctrl+alt+≠':
      return EditorCommand.QuickPlusFontSize;
    case 'ctrl+enter':
      return null; //EditorCommand.AutoSize; 此项只用于文本组件，目前文本组件ctrl+enter也变成进入编辑
    case 'ctrl+h':
      return EditorCommand.Hidden;
    case 'ctrl+alt+c':
    case 'ctrl+alt+ç':
      return EditorCommand.StyleCopy;
    case 'ctrl+alt+v':
    case 'ctrl+alt+√':
      return EditorCommand.StylePaste;
  }
  return null;
}

export enum EditorModel {
  normal,
  symbol,
  theme,
  component,
  contentPanel,
}

/**
 * 组编辑类
 */
class CoreEditor extends CoreDesign {
  public appType?: string;
  /**
   * 是否删除的节点，回收站的页面该值为true，否则为false;
   */
  public isDeletedNode?: boolean;

  // 交互专用的全局回调
  private interactionAlertCallback: ((type?: IAlertInfoType) => void) | undefined;
  // 框选组件过程中
  public isArtboardChooseRectInMoving?: boolean;

  public interactionInsertByIndex?: number;

  constructor(
    artboards: IArtboard[],
    appID: string,
    nodeID: string,
    session: SessionInfo,
    // TODO 参数处理，太多了
    options?: IUICompConstructOptions,
    doc?: Doc,
  ) {
    super(doc ?? new Doc(appID, nodeID, artboards, options), appID, nodeID, session);
    // 初始化交互剪贴板
    InteractionClipboard.create(this);
  }

  reload() {
    const ids = this.selectedComponentList.map((comp) => comp.id);
    const fragments = this.doc.artboardsFragments;
    fragments.forEach((fragment) => fragment.forceRefreshComponents());

    const remarkManager = RefactorRemarkManager.getInstance(this.doc);
    remarkManager?.reload(this.doc);

    const activeArtboard = fragments.find((fragment) => fragment.artboardID === this.activeArtboard.artboardID)!;

    const groups = findChildrenByFiltering(this.activeArtboard, (comp) => {
      return comp.id === this.activeContainer.id;
    });
    this.setActiveArtboard(activeArtboard);
    if (groups?.length && groups[0] !== activeArtboard) {
      this.setActiveContainer(groups[0] as UIContainerComponent);
    } else {
      this.setActiveContainer(activeArtboard);
    }
    if (ids) {
      this.selectByIDs(ids, false);
    }
    // TODO Matt 2021-12-10 这里在symbol更新后，后续的动作因为无变化，全部阻断了界面更新，此处加上一个强制更新，暂时解决问题，后续考虑策略改进
    this.notifyUpdateListener();
    // 通知coreEditor堆栈的其他editor更新
  }

  /**
   * 是否可以删除操作
   * @returns {boolean}
   */
  get canDelete(): boolean {
    return this.selectedComponents.size > 0;
  }

  get isResizingSingleLine(): boolean {
    // const components = this.selectedComponentList;
    if (this.hasOnlyOneSelectedComponents) {
      const { type } = this.firstSelectedComponent!;
      if (type === CLine) {
        return true;
      }
    }
    return false;
  }

  get isResizingSingleLineInSymbol(): boolean {
    const components = this.selectedComponentList;
    const component = components[0];
    if (components.length === 1 && component.type === CSymbol) {
      const child = (component as UIContainerComponent).components;
      if (child.length === 1 && child[0].type === CLine) {
        return true;
      }
    }
    return false;
  }

  get canNotResizing(): boolean {
    // 当垂直的一条线成为symbol 时
    if (
      this.isResizingSingleLineInSymbol &&
      sameNumber((this.selectedComponentList[0] as UISymbolComponent).components[0].size.width, 0)
    ) {
      return true;
    }
    // 如果选中组件全是锁定的组件
    return this.allSelectedComponentAreLocked;
    // 有锁定组件时
    //return this.hasSelectLockedComps;
  }

  public canCutOrCloneOrDelete() {
    const comps = this.selectedComponentList;
    if (comps.length) {
      const comp = comps[0];
      const owner = comp.nearestSealedComponent;
      if (owner) {
        let isList = false;
        if (owner.lib) {
          const lib = getComponent(owner.lib);
          if (lib) {
            isList = !!lib.isList;
          }
        }
        if (!isList) {
          return false;
        } else {
          return comp.parent === owner;
        }
      }
    }
    // if (comps.length === 0 && !this.activeArtboard.isMain) {
    //   return true;
    // }
    return true;
  }

  private getCompData(comp: UIComponent): IComponentData {
    const data = depthClone(comp.toJSON());
    const sealed = comp.nearestSealedComponent;
    if (sealed) {
      if (comp.isRefValue()) {
        data.value = sealed.value;
      }
      const sealedProperties = sealed.properties;
      const { properties, states } = data;
      const propList: IProperties[] = [properties];
      Object.values(states).forEach((state) => {
        if (state.value === '@value') {
          state.value = sealed.value;
        }
        if (state.properties) {
          propList.push(state.properties);
        }
      });
      type PropName = keyof IProperties;
      propList.forEach((properties) => {
        Object.keys(properties).forEach((k) => {
          const propName = k as PropName;
          const prop = properties[propName]!;
          if (prop.ref) {
            const sealedPropName = prop.ref.replace('@properties.', '') as PropName;
            properties[propName] = sealedProperties[sealedPropName];
          }
        });
      });
    }

    return data;
  }

  private validateCommand(command: EditorCommand): boolean {
    if (this.isAdvancedEditor) {
      if (
        command &&
        [
          EditorCommand.Clone,
          EditorCommand.Cut,
          EditorCommand.Artboard_Paste,
          EditorCommand.PasteFromClipboard,
          EditorCommand.Forward,
          EditorCommand.SendToFront,
          EditorCommand.Backward,
          EditorCommand.SendToBack,
          EditorCommand.Delete,
          EditorCommand.Group,
        ].includes(command)
      ) {
        return false;
      }
    }
    // 离线状态禁止画板复制粘贴克隆
    if (
      (this.socketOffline || this.isExample) &&
      [
        EditorCommand.Artboard_Copy,
        EditorCommand.Artboard_Paste,
        EditorCommand.SubArtboard_Clone,
        EditorCommand.SubArtboard_Cut,
      ].includes(command)
    ) {
      return false;
    }
    return true;
  }

  workSpaceBoundsOfActiveContainer?: () => IBounds | undefined;

  activeContainerOffsetWorkSpace?: () => IPosition | undefined;

  private isOriginPositionHide(originPosition: IPosition, compsSize: ISize) {
    const bounds = this.workSpaceBoundsOfActiveContainer && this.workSpaceBoundsOfActiveContainer();
    const compsBounds = initBoundsWithPositionAndSize(originPosition, compsSize);
    if (!bounds) {
      return true;
    }
    return !isIntersect(bounds, compsBounds);
  }

  private getInitPositionContainer(size: IComponentSize, containerBounds: IBounds) {
    let centerPoint = { x: 0, y: 0 };
    const bounds = this.workSpaceBoundsOfActiveContainer && this.workSpaceBoundsOfActiveContainer();
    if (_.isUndefined(bounds)) {
      // 容器中心
      centerPoint = {
        x: Math.round(0.5 * containerBounds.width - 0.5 * size.width),
        y: Math.round(0.5 * containerBounds.height - 0.5 * size.height),
      };
    } else {
      // 容器可视中心
      centerPoint = {
        x: Math.round(0.5 * bounds.width - 0.5 * size.width + bounds.left),
        y: Math.round(0.5 * bounds.height - 0.5 * size.height + bounds.top),
      };
      if (bounds.width === 0 || bounds.height === 0) {
        // 工作区中心
        let offset = { x: 0, y: 0 };
        if (this.activeContainerOffsetWorkSpace) {
          offset = this.activeContainerOffsetWorkSpace() || offset;
        }
        centerPoint = {
          x: Math.round(-offset.x - 0.5 * size.width),
          y: Math.round(-offset.y - 0.5 * size.width),
        };
      }
    }
    return centerPoint;
  }

  /**
   * 处理键盘快捷键
   * @param command
   * @param params
   */
  async execCommand(command: EditorCommand, ...params: any) {
    if (!this.validateCommand(command)) {
      return;
    }
    const usePreferenceSetting = params[0];
    switch (command) {
      case EditorCommand.Delete:
        if (!this.canCutOrCloneOrDelete()) {
          return;
        }
        if (this.activeContainer.isSealed) {
          this.removeSealedChildComponents(this.allSelectedUnLockedComps, this.activeContainer);
        } else {
          this.removeSelectedComponent();
        }
        return;
      case EditorCommand.SelectAll:
        this.selectAll(params.length ? params[0] : false);
        return;
      case EditorCommand.UnSelect:
        this.clearSelected();
        return;
      case EditorCommand.Undo:
        this.undo();
        return;
      case EditorCommand.Redo:
        this.redo();
        return;
      case EditorCommand.Copy: {
        // clearCopyData('MOCKPLUS-ARTBOARD-DATA');
        const comps = this.doGetCanCopyComponentsFromSelected();
        if (comps) {
          const components: IComponentData[] = [];

          const container = this.activeContainer;
          let containerID;
          if (container instanceof UIArtboard) {
            containerID = container.artboardID;
          } else {
            containerID = container.id;
          }

          comps.forEach((comp) => {
            const compData = _.cloneDeep(this.getCompData(comp));
            // 修改组件位置为相对画板位置
            const rotateInArtboard = comp.rotateRelativeToArtboard;
            compData.position = comp.positionRelativeToArtboard;
            compData.rotate = rotateInArtboard;
            // 流程线的data.size 不够准确，会影响粘贴位置的计算
            if (comp.isConnector) {
              compData.size = comp.size;
            }
            components.push(compData);
          });

          const refArtboardsData: IArtboard[] = getRefArtboardsData(comps, this.doc);

          await copyComponentDataV2({
            type: ClipboardType.Component_Copy,
            containerID,
            data: components,
            refArtboardsData,
          });
        }
        return;
      }
      case EditorCommand.Cut: {
        if (this.selectedComponentList.length === 0 && !this.activeArtboard.isMain) {
          await this.execCommand(EditorCommand.SubArtboard_Cut);
        } else {
          //  clearCopyData('MOCKPLUS-ARTBOARD-DATA');
          if (!this.canCutOrCloneOrDelete()) {
            return;
          }
          const comps = this.doGetCanCopyComponentsFromSelected();
          if (comps) {
            await this.doCopyComponent(ClipboardType.Component_Cut, comps);
            this.removeSelectedComponent();
          }
          return;
        }
        break;
      }
      case EditorCommand.Artboard_Copy: {
        await this.doCopyArtboard();
        return;
      }
      case EditorCommand.Artboard_Paste: {
        const data = await getCopiedArtboardData();
        if (!data) {
          return;
        }
        this.doPasteArtboard(data.data, usePreferenceSetting);
        return;
      }
      case EditorCommand.SubArtboard_Cut: {
        await this.doCopyArtboard();
        this.doSubArtboardDelete();
        break;
      }
      case EditorCommand.SubArtboard_Clone: {
        if (!this.isNormalEditor && !this.isContentPanelEditor) {
          return;
        }
        await this.doCopyArtboard();
        const data = await getCopiedArtboardData();
        if (!data) {
          return;
        }
        this.doPasteArtboard(data.data, usePreferenceSetting);
        break;
      }
      case EditorCommand.SubArtboard_Delete: {
        this.doSubArtboardDelete();
        break;
      }
      case EditorCommand.PasteFromClipboard: {
        const [opts, userPreference] = params;
        this.doPasteFromClipboard(opts, userPreference);
        break;
      }
      case EditorCommand.Group: {
        const [userPreference] = params;
        const enableCmdShiftGToUnGrouping = (userPreference as IUserPreference)?.generalSettings
          .enableCmdShiftGToUnGrouping;
        if (this.canGroup) {
          this.group();
        } else if (!enableCmdShiftGToUnGrouping && this.canUnGroup) {
          this.unGroup();
        }
        return;
      }
      case EditorCommand.UnGroup:
        if (this.canUnGroup) {
          this.unGroup();
        }
        return;
      case EditorCommand.UnGroupMore:
        this.unGroupMore();
        return;
      case EditorCommand.Clone:
        if (!this.canCutOrCloneOrDelete()) {
          return;
        }
        if (this.selectedComponentList.length === 0 && !this.activeArtboard.isMain) {
          await this.execCommand(EditorCommand.SubArtboard_Clone);
        } else {
          await this.clone(this.cloneOffset, params?.[0]);
        }
        return;
      case EditorCommand.Lock:
        this.changeLocked(true);
        return;
      case EditorCommand.UnLock:
        this.changeLocked(false);
        return;
      case EditorCommand.SendToBack:
        this.changeZOrder(ZOrderModel.sendToBack);
        return;
      case EditorCommand.Backward:
        this.changeZOrder(ZOrderModel.backForward);
        return;
      case EditorCommand.SendToFront:
        this.changeZOrder(ZOrderModel.bringToFront);
        break;
      case EditorCommand.Forward:
        this.changeZOrder(ZOrderModel.frontForward);
        return;
      case EditorCommand.AlignLeft:
        this.align(ComponentAlignType.left);
        return;
      case EditorCommand.AlignRight:
        this.align(ComponentAlignType.right);
        return;
      case EditorCommand.AlignCenter:
        this.align(ComponentAlignType.center);
        return;
      case EditorCommand.AlignTop:
        this.align(ComponentAlignType.top);
        return;
      case EditorCommand.AlignBottom:
        this.align(ComponentAlignType.bottom);
        return;
      case EditorCommand.AlignMiddle:
        this.align(ComponentAlignType.middle);
        return;
      case EditorCommand.SpaceHorizontal:
        this.sameGap('horizontal');
        return;
      case EditorCommand.SpaceVertical:
        this.sameGap('vertical');
        return;
      case EditorCommand.Save:
        return;
      case EditorCommand.QuickPlusFontSize:
      case EditorCommand.QuickLessFontSize:
      case EditorCommand.QuckSetItalic:
      case EditorCommand.QuckSetUnderline:
      case EditorCommand.QuickSetBold:
        return this.execTextCommand(command);
      case EditorCommand.AutoSize:
        this.selectedTextCompAutoSize();
        return;
      case EditorCommand.Hidden: {
        this.switchComponentVisible();
        return;
      }
      case EditorCommand.StyleCopy: {
        this.hasOnlyOneSelectedComponents && (await this.copyComponentStyle());
        return;
      }
      case EditorCommand.StylePaste: {
        await this.pasteComponentStyle();
        return;
      }
    }
  }

  public execTextCommand(cmd: EditorCommand, textComps?: UIComponent[]) {
    switch (cmd) {
      case EditorCommand.QuickPlusFontSize:
        return this.quickSetFontSize('plus', textComps);
      case EditorCommand.QuickLessFontSize:
        return this.quickSetFontSize('less', textComps);
      case EditorCommand.QuckSetItalic:
        return this.quickSetFontStyle('italic', textComps);
      case EditorCommand.QuckSetUnderline:
        return this.quickSetFontStyle('underline', textComps);
      case EditorCommand.QuickSetBold:
        return this.quickSetFontStyle('bold', textComps);
      default:
        break;
    }
  }

  private switchComponentVisible() {
    const comp = this.firstSelectedComponent;
    if (comp && !this.activeContainer.isCompoundPath) {
      this.setGeneralProperties('hidden', comp ? !comp.hidden : false);
    }
  }

  private doGetCanCopyComponentsFromSelected() {
    const comps = this.selectedComponentList.filter((comp) => {
      return !comp.locked;
    });
    // 没有选中的组件，或单个选中的组件是流程线，不触发复制
    if (comps.length === 0 || (comps.length == 1 && comps[0].isConnector)) {
      return null;
    }
    // 复制组件到剪贴板时，需要保持原来的自然顺序
    const parent = comps[0].parent as UIContainerComponent;
    comps.sort((a, b) => {
      const aIndex = parent.components.indexOf(a);
      const bIndex = parent.components.indexOf(b);
      return aIndex - bIndex;
    });
    return comps;
  }

  doRenameArtboard(value: string) {
    const artboard = this.activeArtboard;
    if (value === artboard.name) {
      return;
    }

    if (value === '') {
      value = artboard.name || i18n('resource.artboard');
    }

    if (this.isSymbolEditor || this.isComponentEditor) {
      // @ts-ignore
      const symbol = this.data;
      const { libID, _id } = symbol;
      designManager.getInstance<IDesignManager>().updateComponent(libID, _id, { name: value });
    }

    this.patchArtboardMeta({
      name: value.toString(),
    });
  }

  async doCopyComponent(type: ClipboardType, comps: UIComponent[]) {
    const payload = this.getComponentCopyPayload(type, comps);
    await copyComponentDataV2(payload);
  }

  getComponentCopyPayload(
    type: ClipboardType,
    comps: UIComponent[],
    options: {
      relativeToArtboard: boolean;
    } = { relativeToArtboard: true },
  ) {
    const { relativeToArtboard } = options;
    const components: IComponentData[] = [];

    const container = this.activeContainer;
    let containerID;
    if (container instanceof UIArtboard) {
      containerID = container.artboardID;
    } else {
      containerID = container.id;
    }

    comps.forEach((comp) => {
      const compData = _.cloneDeep(this.getCompData(comp));
      if (relativeToArtboard) {
        // 修改组件位置为相对画板位置
        compData.position = comp.positionRelativeToArtboard;
        compData.rotate = comp.rotateRelativeToArtboard;
      }
      // 流程线的data.size 不够准确，会影响粘贴位置的计算
      if (comp.isConnector) {
        compData.size = comp.size;
      }
      components.push(compData);
    });

    const refArtboardsData: IArtboard[] = getRefArtboardsData(comps, this.doc);
    return {
      type,
      containerID,
      data: components,
      refArtboardsData,
    };
  }

  /**
   *  处理复制的面板数据
   */
  async doCopyArtboard() {
    if (this.socketOffline || this.isExample) {
      return;
    }

    const artboardData: IArtboard[] = [];
    const artboards = this.hasSelectedFragments() ? Array.from(this.selectedFragments) : [this.activeArtboard];
    for (const fragment of artboards) {
      artboardData.push(depthClone(fragment.$data));
    }
    // 数据准备，获取关联画板数据
    artboardData.push(...getRefArtboardsData(artboards, this.doc));

    await copyArtboardData(artboardData, ClipboardType.Artboard_Copy);
  }

  private get originPointPosition() {
    return this.doc.mainArtboard.position;
  }

  private get positionCalcThreshold() {
    return this.appType && ['phone', 'pad'].includes(this.appType) ? 3000 : 10000;
  }

  /**
   * 用于处理画板 position，避免画板批量粘贴、克隆时重叠在一起
   */
  temporaryAllFragmentsData: IComponentData[] = [];

  // addLength 给新内容编辑模式，复写使用的
  // eslint-disable-next-line no-unused-vars
  getArtboardDataAndPositionForPaste(artboardData: IComponentData, addLength?: number): IArtboardPasteInfo {
    window.debug && console.log(addLength);
    // 粘贴时才进行重置面板内组件的ID， 不然次复制会出问题
    // 画板本身的交互、组件中关于画板的交互也要重置 ID
    const clonedArtboardData = artboardData as IArtboard;

    // 粘贴画板时，页面id改为选中页面的id
    clonedArtboardData.nodeID = this.activeArtboard.doc.pageID;
    //复制名称
    const copyName = copyArtboardName(clonedArtboardData, this);
    clonedArtboardData.name = copyName;

    // 计算画板位置
    const positionCalculator = createArtboardPositionCalculator(
      this.activeArtboard,
      this.temporaryAllFragmentsData,
      artboardData,
      this.originPointPosition,
      this.positionCalcThreshold,
    );
    const position = positionCalculator.calc();
    clonedArtboardData.position = position;

    return {
      data: clonedArtboardData,
      position,
    };
  }

  getArtboardPasteInfos(artboardsData: IComponentData[]) {
    this.temporaryAllFragmentsData = [this.doc.mainArtboard, ...this.doc.visibleFragments].map((fragment) => {
      return fragment.toJSON();
    });
    const artboardInfos = [];
    const length = Object.keys(artboardsData).length;
    for (const data of artboardsData) {
      const artboardInfo = this.getArtboardDataAndPositionForPaste(data, length);
      artboardInfos.push(artboardInfo);
      this.temporaryAllFragmentsData.push({
        ...artboardInfo.data,
        position: artboardInfo.position,
      });
    }
    this.temporaryAllFragmentsData = [];
    return artboardInfos;
  }

  /**
   *  粘贴面板
   */
  doPasteArtboard(
    artboardData: IComponentData | IComponentData[],
    userPreference?: IUserPreference,
    onMorePatch?: (newArtboardIDs: string[]) => PagePatches,
  ) {
    if (this.socketOffline || this.isExample || !this.isNormalEditor) {
      return;
    }

    let artboards: IComponentData[];
    if (Array.isArray(artboardData)) {
      artboards = artboardData;
    } else {
      artboards = [artboardData];
    }

    const newArtboards = cloneArtboardDataV2(artboards) as IArtboard[];

    // 里面有对data修改，只对当前editor可见的画板
    this.getArtboardPasteInfos(newArtboards.filter((artboard) => !artboard.ownerID));

    const artboardInfos = newArtboards.map((artboard) => {
      return {
        data: artboard,
        position: artboard.position,
      };
    });
    this.doBatchCloneArtboard(artboards, artboardInfos, userPreference, onMorePatch);
  }

  doBatchCloneArtboard(
    artboards: IComponentData[],
    artboardInfos: { data: IComponentData; position: IPosition }[],
    userPreference?: IUserPreference,
    onMorePatch?: (newArtboardIDs: string[]) => PagePatches,
  ) {
    return this.batchCloneArtboard(artboardInfos, onMorePatch).then((newArtboardIDs = []) => {
      if (!userPreference) {
        return newArtboardIDs;
      }
      const preference = userPreference.artboardsLayoutAndGrid;
      const appId = userPreference._id;
      for (let i = 0; i < newArtboardIDs.length; i++) {
        preference[newArtboardIDs[i]] = preference[artboards[i]._id];
      }
      apis.userPreference.patchUserPreference(appId, { _id: appId, artboardsLayoutAndGrid: preference });
      return newArtboardIDs;
    });
  }

  protected doSubArtboardDelete() {
    if (this.hasSelectedFragments() && !this.isMainArtboardSelected()) {
      const selectedFragments = Array.from(this.selectedFragments);
      this.batchRemoveFragment(selectedFragments);
    }
  }

  /**
   * 公有版本，删除粘贴音频与视频
   */
  private doPublicPasteFile(clipboardData: DataTransfer | FileList, options: ExecutePasteOptions): void {
    let fileList: FileList;
    if (clipboardData instanceof DataTransfer) {
      fileList = clipboardData.files;
    } else {
      fileList = clipboardData;
    }
    if (fileList.length <= 0) {
      return;
    }
    let files = Array.from(fileList);

    const containerBoundsInArtboard = this.activeContainer.getViewBoundsInArtboard();
    const artboardID = this.activeArtboard.artboardID;

    const fileGroup = groupFiles(files, {
      types: [UploadFileType.Image, UploadFileType.SVG],
      maxUploadCount: MaxSimultaneouslyUploadFileCount,
    });
    const uploadFiles = fileGroup.getFiles();
    const invalidFileCount = fileGroup.invalidCount;
    const validFileCount = uploadFiles.length;
    const unknownFormatFileCount = fileGroup.unknownFormatFileCount; // 无效格式数量
    const hasLargFile = fileGroup.hasLargFile; //是否存在大体积
    const hasFormatFailFile = fileGroup.hasFormatFailFile; //是否存在无效格式
    let hasLargSizeFile = false; // 是否存在大尺寸
    // 如果文件上传数量超过最大数量限制
    if (fileGroup.hasUploadLimit) {
      options.showMessage(i18n('alert.uploadMaxCount'));
    }

    let { start: startBatchUpload } = batchUploadFiles(uploadFiles, {
      isExample: options.isExample,
      onProgress: (percent, total, completeCount) => {
        options.onProgress(percent, total, completeCount);
      },
      onComplete: (result, total) => {
        options.onComplete(result, total);
        let comps = result
          .map((item) => {
            let res = item.data,
              comp;
            try {
              // 生成各类组件数据
              if (item.type === UploadFileType.Image || item.type === UploadFileType.SVG) {
                if (res.size.width <= MaxImageDimention && res.size.height <= MaxImageDimention) {
                  comp = makeImageComponent(res.URL, res.name, { ...res.size, lockedRatio: true });
                  comp.position = getPosition(res.size);
                } else {
                  hasLargSizeFile = true;
                }
              }
            } catch (e) {
              console.error(e);
              return null;
            }
            return comp;
          })
          .filter(Boolean) as IComponentData[];
        if (comps.length) {
          this.addComponents(comps, { artboardID, autoSelect: true });
        }

        const failCount = validFileCount - comps.length + invalidFileCount; // 50个文件上传数中上传失败的数量及不合规数量
        const errors: boolean[] = []; // 多种无效原因同时存在
        hasLargFile && errors.push(hasLargFile);
        hasLargSizeFile && errors.push(hasLargSizeFile);
        hasFormatFailFile && errors.push(hasFormatFailFile);

        const onlyLargFile = hasLargFile && errors.length === 1;
        const onlyLargSizeFile = hasLargSizeFile && errors.length === 1;
        const onlyFormatFailFile = hasFormatFailFile && errors.length === 1;

        let format = 'jpg、png、svg、gif';
        let msg: string | undefined = undefined;
        if (errors.length > 1) {
          if (languageManager.isEnLanguage) {
            format = 'jpg, png, svg, gif';
          }
          msg = i18n('alert.moreFileUploadFailAll', failCount, format);
        } else if (onlyLargFile) {
          msg = i18n('alert.moreFileUploadFaiFile', failCount);
        } else if (onlyLargSizeFile) {
          msg = i18n('alert.moreFileUploadFailSize', failCount);
        } else if (onlyFormatFailFile) {
          if (languageManager.isEnLanguage) {
            format = format.replace(/、/g, ', ');
          }
          msg = i18n('alert.moreFileUploadFailFormat', unknownFormatFileCount, format);
        }
        if (msg) {
          options.alert(msg);
        }
      },
    });

    const getPosition = (size: ISize): IPosition => {
      return this.getInitPositionContainer(size, containerBoundsInArtboard);
    };

    const doStart = () => {
      if (validFileCount) {
        options.onStart(validFileCount);
      }
      startBatchUpload();
    };
    doStart();
  }

  /**
   * 私有版本，保留粘贴音频与视频
   */
  private doPrivatePasteFile(clipboardData: DataTransfer | FileList, options: ExecutePasteOptions): void {
    let fileList: FileList;
    if (clipboardData instanceof DataTransfer) {
      fileList = clipboardData.files;
    } else {
      fileList = clipboardData;
    }
    if (fileList.length <= 0) {
      return;
    }
    let files = Array.from(fileList);

    const containerBoundsInArtboard = this.activeContainer.getViewBoundsInArtboard();
    const artboardID = this.activeArtboard.artboardID;

    const fileGroup = groupFiles(files, {
      types: [UploadFileType.Image, UploadFileType.SVG, UploadFileType.Video, UploadFileType.Audio],
      maxUploadCount: MaxSimultaneouslyUploadFileCount,
    });
    const uploadFiles = fileGroup.getFiles();
    const allTotal = fileGroup.total;
    const validFileCount = uploadFiles.length;
    const unknownFormatFileCount = fileGroup.unknownFormatFileCount; // 无效格式数量
    const hasLargFile = fileGroup.hasLargFile; //是否存在大体积
    const hasFormatFailFile = fileGroup.hasFormatFailFile; //是否存在无效格式
    let hasLargSizeFile = false; // 是否存在大尺寸
    // 如果文件上传数量超过最大数量限制
    if (fileGroup.hasUploadLimit) {
      options.showMessage(i18n('alert.uploadMaxCount'));
    }

    let { start: startBatchUpload } = batchUploadFiles(uploadFiles, {
      isExample: options.isExample,
      onProgress: (percent, total, completeCount) => {
        options.onProgress(percent, total, completeCount);
      },
      onComplete: (result, total) => {
        options.onComplete(result, total);
        let comps = result
          .map((item) => {
            let res = item.data,
              comp;
            try {
              // 生成各类组件数据
              if (item.type === UploadFileType.Image || item.type === UploadFileType.SVG) {
                if (res.size.width <= MaxImageDimention && res.size.height <= MaxImageDimention) {
                  comp = makeImageComponent(res.URL, res.name, { ...res.size, lockedRatio: true });
                  comp.position = getPosition(res.size);
                } else {
                  hasLargSizeFile = true;
                }
              } else if (item.type === UploadFileType.Video) {
                comp = makeVideoComponent(res.URL, res.name, {
                  width: Math.round(res.size.width || 100),
                  height: Math.round(res.size.height || 100),
                  lockedRatio: true,
                });
                comp.position = getPosition(comp.size);
              } else if (item.type === UploadFileType.Audio) {
                comp = makeAudioComponent(res.URL, res.name);
                comp.position = getPosition(comp.size);
              }
            } catch (e) {
              console.error(e);
              return null;
            }
            return comp;
          })
          .filter(Boolean) as IComponentData[];
        if (comps.length) {
          this.addComponents(comps, { artboardID, autoSelect: true });
        }
        const totalFailCount = allTotal - comps.length;
        const failCount = total - comps.length;
        const isNetworkError = !hasLargSizeFile && !hasLargFile && !hasFormatFailFile && !!failCount;
        const onlyLargFile = hasLargFile && !hasLargSizeFile && !hasFormatFailFile;
        const onlyLargSizeFile = hasLargSizeFile && !hasLargFile && !hasFormatFailFile;
        const onlyFormatFailFile = hasFormatFailFile && !hasLargFile && !hasLargSizeFile;

        let format = 'jpg、png、svg、gif、mp3、mp4';
        let msg: string | undefined = undefined;
        if (isNetworkError) {
          msg = i18n('alert.uploadNetworkError');
        } else if (onlyLargFile) {
          msg = i18n('alert.moreFileUploadFaiFile', totalFailCount);
        } else if (onlyLargSizeFile) {
          msg = i18n('alert.moreFileUploadFailSize', totalFailCount);
        } else if (onlyFormatFailFile) {
          if (languageManager.isEnLanguage) {
            format = format.replace(/、/g, ', ');
          }
          msg = i18n('alert.moreFileUploadFailFormat', unknownFormatFileCount, format);
        } else if (failCount) {
          if (languageManager.isEnLanguage) {
            format = 'jpg, png, svg, gif, mp3 and mp4';
          }
          msg = i18n('alert.moreFileUploadFailAll', failCount, format);
        }
        if (msg) {
          options.alert(msg);
        }
      },
    });

    const getPosition = (size: ISize): IPosition => {
      return this.getInitPositionContainer(size, containerBoundsInArtboard);
    };

    const doStart = () => {
      if (validFileCount) {
        options.onStart(validFileCount);
      }
      startBatchUpload();
    };
    doStart();
  }

  private doPasteFile(clipboardData: DataTransfer | FileList, options: ExecutePasteOptions): void {
    if (RP_CONFIGS.isPrivateDeployment) {
      this.doPrivatePasteFile(clipboardData, options);
    } else {
      this.doPublicPasteFile(clipboardData, options);
    }
  }

  private doPasteComponent(componentData: IComponentData[], userPreference?: IUserPreference /*cloneArtboards?: any*/) {
    if (this.isContentPanelEditor && checkContainContentPanel(componentData)) {
      if (this.onError) {
        this.onError(new CustomError(i18n('alert.contentPanelEditorPasteContentPanelComp')));
      }
      return;
    }
    //symbol是否包含动态画板
    if (this.isSymbolEditor && checkContainContentPanelV2(componentData)) {
      if (this.onError) {
        this.onError(new CustomError(i18n('alert.symbolEditorPasteContentPanelComp')));
      }
      return;
    }
    // 此处组件的位置是相对于画板的
    const { x: xPasteOffset, y: yPasteOffset } = userPreference?.generalSettings.pasteOffset ?? { x: 0, y: 0 };
    const newData = cloneComponents({
      componentsData: componentData,
      parent: this.activeContainer,
      delta: {
        x: xPasteOffset,
        y: yPasteOffset,
      },
      enableSort: false,
    });
    const currentContainer = this.activeContainer;
    const containerBoundsInArtboad = currentContainer.getViewBoundsInArtboard();
    //修改position
    const bounds = newData.map((comp) => {
      // 修正组件位置
      const originCenter = getCenter(comp.position, comp.size, 0);
      const centerInTargetContainer = tansPointInArtBoardToGroup([originCenter], currentContainer)[0];
      comp.position = getNWPoint(centerInTargetContainer, comp.size, 0);
      const rotateOffset = currentContainer.rotateRelativeToArtboard;
      comp.rotate = (comp.rotate || 0) - rotateOffset;
      return getBoundsInParent({
        size: comp.size,
        position: comp.position,
        rotate: comp.rotate || 0,
      });
    });

    const unionBounds = union(...bounds);
    // 多组件size,position
    const compsSize = {
      height: unionBounds.height,
      width: unionBounds.width,
    };
    const compsPosition = getCompDataIntegratedPosition(newData);
    const centerPosition = this.getInitPositionContainer(compsSize, containerBoundsInArtboad);

    newData.forEach((compData) => {
      if (this.isOriginPositionHide(compsPosition, compsSize)) {
        const offsetX = 0 - compsPosition.x + centerPosition.x;
        const offsetY = 0 - compsPosition.y + centerPosition.y;

        if (compData.type === 'connector') {
          modifyPathWithOffset(compData, { x: offsetX, y: offsetY });
        }

        compData.position = {
          x: compData.position.x + offsetX,
          y: compData.position.y + offsetY,
        };
      }
    });
    const addCompsOptions = this.getAddCompsIndexOptions(userPreference?.generalSettings.pasteAtTop);
    //根据父容器的特性修正子的特性(比如父是翻转的,会影响子的翻转)
    this.addComponents(newData, { ...addCompsOptions, autoSelect: true, isPaste: true } /*, cloneArtboards*/);
  }

  private doPasteFromClipboard(options?: any, userPreference?: IUserPreference) {
    if (this.isAdvancedEditor) {
      return;
    }
    if (!editorValidate.allowChildrenStructureChange(this.activeContainer, 'paste')) {
      return;
    }
    // 浏览器支持系统剪切板
    if (MockClipboard.clipboard.isSupport()) {
      const event = window.event;
      if (event && event.target) {
        // 判断表单使用原生事件
        const { tagName, isContentEditable } = event.target as HTMLElement;
        if (tagName === 'INPUT' || tagName === 'TEXTAREA' || isContentEditable) {
          return;
        }
      }

      MockClipboard.getBoardAsync().then((boardData) => {
        const containerBoundsInArtboad = this.activeContainer.getViewBoundsInArtboard();
        if (boardData) {
          const componentData = boardData.get(ClipboardType.Mock_Component);
          const artboardData = boardData.get(ClipboardType.Mock_Artboard);
          const filesData = boardData.get('files')?.slice(0, 50); // 最多只能上传50个
          const svgData = boardData.get('svgs');
          const textData = boardData.get('text');
          // 粘贴组件、画板 -- 优先
          if (componentData || artboardData) {
            componentData && this.doPasteComponent(componentData.data, userPreference /*componentData.cloneArtboards*/);
            if (this.isNormalEditor && artboardData) {
              this.doPasteArtboard(artboardData.data, userPreference);
            }
            // 粘贴文件
          } else if (filesData) {
            this.doPasteFile(filesData, options as ExecutePasteOptions);
            // 粘贴svg
          } else if (svgData) {
            svgData.forEach((str: string) => {
              this.getPasteDataToSvgComp(str).then((res: IComponentData) => {
                res.position = this.getInitPositionContainer(res.size, containerBoundsInArtboad);
                this.addComponents([res], { artboardID: this.activeArtboard.artboardID, autoSelect: true });
                // 保证粘贴后，焦点继续在工作区，使后续一些快捷键能正常使用
                options && options?.onPasteComplete();
              });
            });
            // 粘贴文本
          } else if (textData) {
            let comp: IComponentData = this.getPasteDataToTextComp(textData);
            comp.position = this.getInitPositionContainer(comp.size, containerBoundsInArtboad);
            this.addComponents([comp], { artboardID: this.activeArtboard.artboardID, autoSelect: true });
          }
        }
      });
      return true;
    }

    const event = window.event;
    if (!event) {
      return;
    }
    if (!(event instanceof ClipboardEvent)) {
      return;
    }
    if (event.type !== 'paste') {
      return;
    }

    const { clipboardData } = event;
    const { tagName, isContentEditable } = event.target as HTMLElement;
    if (!clipboardData || tagName === 'INPUT' || tagName === 'TEXTAREA' || isContentEditable) {
      return;
    }

    const { items, types, files } = clipboardData;
    // 如果粘贴的文件列表不为空，先走这个文件处理逻辑
    if (files.length) {
      this.doPasteFile(clipboardData, options as ExecutePasteOptions);
    }

    if (types.includes('text/plain')) {
      const idx = types.indexOf('text/plain');
      if (idx === -1) {
        return;
      }
      let platTextItem = items[idx];
      const isHTML = types.includes('text/html');
      if (isHTML) {
        platTextItem = items[types.indexOf('text/html')];
      }
      platTextItem.getAsString(async (data) => {
        const trimmedData = isHTML ? filterHTMLText(data, true, regulate, { transformTags }).trim() : data.trim();
        // 在工作区，以下类型标识的不允许粘贴成功
        const isDenyCopyFlag = [
          ClipboardType.Mock_Interaction,
          ClipboardType.Mock_Page,
          ClipboardType.Node_Copy,
        ].includes(trimmedData as ClipboardType);
        if (!trimmedData || isDenyCopyFlag) {
          return;
        }
        const type = copiedDataType(trimmedData);
        if (type === ClipboardType.Component_Copy || type === ClipboardType.Component_Cut) {
          const data = await getCopiedComponentData();
          if (!data) {
            return;
          }
          await this.pasteComponents({ data, userPreference, compsPositionFixFn: this.fixClipboardPasteCompsPosition });
        } else if (type === ClipboardType.Artboard_Copy) {
          const data = await getCopiedArtboardData();
          if (!data) {
            return;
          }
          if (!this.isNormalEditor && !this.isContentPanelEditor) {
            return;
          }
          if (data.data instanceof Array) {
            const refactorRemarkManager = RefactorRemarkManager.getInstance(this.doc);
            refactorRemarkManager?.upRemarkIndex(data.data as IComponentData[]);
          }
          this.doPasteArtboard(data.data, userPreference);

          // FIXME: zjq表格局部数据粘贴成表格组件，抽空搞定
        } else if (type === ClipboardType.Normal) {
          let domCheck = document.createElement('div');
          // trimmedData反转义
          domCheck.innerHTML = sanitizeHtml(unescape(trimmedData), {
            allowedTags: defaultAllowedTags,
            allowedAttributes: { '*': defaultAllowedAttributes },
          });
          const isHasSvgElement = domCheck.firstElementChild instanceof SVGElement && domCheck.childNodes.length == 1;
          const containerBoundsInArtboad = this.activeContainer.getViewBoundsInArtboard();
          if (isHasSvgElement) {
            this.getPasteDataToSvgComp(domCheck.innerHTML).then((res: IComponentData) => {
              res.position = this.getInitPositionContainer(res.size, containerBoundsInArtboad);
              this.addComponents([res], { artboardID: this.activeArtboard.artboardID, autoSelect: true });
              // 保证粘贴后，焦点继续在工作区，使后续一些快捷键能正常使用
              options && options?.onPasteComplete();
            });
          } else {
            // 富文本
            let comp: IComponentData = this.getPasteDataToTextComp(trimmedData);
            comp.position = this.getInitPositionContainer(comp.size, containerBoundsInArtboad);
            this.addComponents([comp], { artboardID: this.activeArtboard.artboardID, autoSelect: true });
            // 保证粘贴后，焦点继续在工作区，使后续一些快捷键能正常使用
            options && options?.onPasteComplete();
          }
        }
      });
    }
  }

  private fixClipboardPasteCompsPosition = (comps: IComponentData[], userPreference?: IUserPreference) => {
    // 修改position
    // 此处组件的位置是相对于画板的
    const { x: xPasteOffset, y: yPasteOffset } = userPreference?.generalSettings?.pasteOffset ?? { x: 0, y: 0 };
    comps.forEach((compData) => {
      compData.position.x += xPasteOffset;
      compData.position.y += yPasteOffset;
      compData.position = roundNumberObject(compData.position);
    });
    const currentContainer = this.activeContainer;
    const containerBoundsInArtboad = currentContainer.getViewBoundsInArtboard();
    const bounds = comps.map((comp) => {
      // 修正组件位置
      const originCenter = getCenter(comp.position, comp.size, 0);
      const centerInTargetContainer = tansPointInArtBoardToGroup([originCenter], currentContainer)[0];
      comp.position = roundNumberObject(getNWPoint(centerInTargetContainer, comp.size, 0));
      const rotateOffset = currentContainer.rotateRelativeToArtboard;
      comp.rotate = (comp.rotate || 0) - rotateOffset;
      return getBoundsInParent({
        size: comp.size,
        position: comp.position,
        rotate: comp.rotate || 0,
      });
    });

    const unionBounds = union(...bounds);
    // 多组件size,position
    const compsSize = roundNumberObject({
      height: unionBounds.height,
      width: unionBounds.width,
    });
    const compsPosition = roundNumberObject(getCompDataIntegratedPosition(comps));
    const centerPosition = roundNumberObject(this.getInitPositionContainer(compsSize, containerBoundsInArtboad));
    comps.forEach((compData) => {
      if (this.isOriginPositionHide(compsPosition, compsSize)) {
        const offsetX = 0 - compsPosition.x + centerPosition.x;
        const offsetY = 0 - compsPosition.y + centerPosition.y;

        if (compData.type === 'connector') {
          modifyPathWithOffset(compData, { x: offsetX, y: offsetY });
        }

        compData.position = roundNumberObject({
          x: compData.position.x + offsetX,
          y: compData.position.y + offsetY,
        });
      }
    });
  };

  async pasteComponents(options: {
    data: ICopiedComponentData;
    delta?: MoveDelta;
    resetCloneOffset?: boolean;
    toArtboardID?: string;
    userPreference?: IUserPreference;
    compsPositionFixFn?: (comps: IComponentData[], userPreference?: IUserPreference) => void;
  }) {
    const {
      data,
      delta = {
        x: 0,
        y: 0,
      },
      resetCloneOffset = true,
      toArtboardID = this.activeArtboard.artboardID,
      userPreference,
      compsPositionFixFn,
    } = options;

    const { componentData, refArtboardsData } = data;

    //检测是否包含内容画板
    if (this.isContentPanelEditor && checkContainContentPanel(componentData)) {
      if (this.onError) {
        this.onError(new CustomError(i18n('alert.contentPanelEditorPasteContentPanelComp')));
      }
      return;
    }

    //symbol是否包含动态画板
    if (this.isSymbolEditor && checkContainContentPanelV2(componentData)) {
      if (this.onError) {
        this.onError(new CustomError(i18n('alert.symbolEditorPasteContentPanelComp')));
      }
      return;
    }

    // TODO 变量重命名
    let { componentsData: newData, refArtboardsData: newRefArtboardsData } = cloneComponentsV2({
      componentsData: componentData,
      refArtboardsData,
      parent: this.activeContainer,
      delta,
      enableSort: false,
    });

    const refactorRemarkManager = RefactorRemarkManager.getInstance(this.doc);
    refactorRemarkManager?.upRemarkIndex(newData);

    compsPositionFixFn?.(newData, userPreference);

    // 准备生成 patch
    if (this.isSymbolEditor) {
      newData = this.doDetachSymbolComponents(newData);
    }

    const addCompsOptions: AddComponentsOptions = {
      artboardID: toArtboardID,
      autoSelect: true,
      resetCloneOffset,
      ...this.getAddCompsIndexOptions(userPreference?.generalSettings?.pasteAtTop),
    };

    const addComponentsPatches = this.doAddComponents(newData, addCompsOptions);

    const addRefArtboardsPatches = await this.getBatchCloneArtboardPatches(
      newRefArtboardsData.map((refArtboard) => {
        return {
          data: refArtboard,
          position: refArtboard.position,
        };
      }),
    );

    const pasteComponentsPatches = { ...addRefArtboardsPatches, ...addComponentsPatches };

    this.batchSyncNoticeUpdate(() => {
      this.update(pasteComponentsPatches);
      if (addCompsOptions?.autoSelect) {
        this.selectByIDs(
          newData.map((comp) => comp._id),
          false,
        );
      }
    });
  }

  /**
   *处理粘贴内容 svg
   */
  public async getPasteDataToSvgComp(trimmedData: string): Promise<IComponentData> {
    const newId = getNewID();
    const newName = getNameForNewComponent(this.activeContainer.components, {
      id: newId,
      type: 'image',
    });
    const svgBlob = new Blob([trimmedData]);
    const svgFlie = blobToFile(svgBlob, newName + '.svg', 'image/svg+xml');
    const svgUploadFile = await uploadFile(svgFlie, ValueEditorType.Svg);
    const comp: IComponentData = makeImage(newId, svgUploadFile.URL, svgUploadFile.name, {
      width: svgUploadFile.size.width,
      height: svgUploadFile.size.height,
      lockedRatio: true,
    });
    return comp;
  }

  /**
   *处理粘贴内容 text
   */
  public getPasteDataToTextComp(trimmedData: string): IComponentData {
    // clean
    let value = sanitizeHtml(trimmedData, {
      allowedTags: ['div', 'p', 'span'],
      allowedAttributes: {
        '*': [],
      },
      transformTags: {
        ol: 'p',
        ul: 'p',
        li: 'p',
        dl: 'p',
        dt: 'p',
        dd: 'p',
      },
    });
    value = adjustHTMLLineHeight(value).replace(/\n/gi, '<br/>').replace(/\t/gi, '');
    value = deleteHTMLNoHeightEmptyLine(value);

    let comp: IComponentData = makeText(getNewID(), value);
    comp.autoSize = false;

    // 备忘：这个已有的 propertyCache 的粒度偏大，可考虑直接 cache patch
    // 这里会重新 measureText (side effect)
    mergePropertyCacheToComp(comp);

    // 纠正上一步之后造成的尺寸错误
    const properties = comp.properties;
    const parser = StyleHelper.initCSSStyleParser(properties);
    const style = parser.getTextStyle();
    const { width, height } = measureTextCompInitialSize(style, comp.value);
    comp.size.width = width;
    comp.size.height = height;

    return comp;
  }

  /**
   *
   * @return maxIndexOfOriginComps 源复制组件的最大index
   */
  public getAddCompsIndexOptions(pasteAtTop?: boolean): AddComponentsOptions {
    const _pasteAtTop = pasteAtTop ?? true;

    let result = {};
    if (!_pasteAtTop) {
      const selectedComponentList = this.selectedComponentList;
      const maxIndex = selectedComponentList.length
        ? getMaxIndexOfComponents(selectedComponentList, this.activeContainer.components) + 1
        : -1;
      result = {
        index: maxIndex,
      };
    }
    return result;
  }

  /**
   * 克隆操作，指在当前画板进行的操作
   */
  async clone(delta: MoveDelta, userPreference?: IUserPreference) {
    const comps = this.doGetCanCopyComponentsFromSelected();
    if (comps) {
      const { containerID, data, refArtboardsData } = this.getComponentCopyPayload(
        ClipboardType.Component_Copy,
        comps,
        { relativeToArtboard: false },
      );
      await this.pasteComponents({
        data: {
          componentData: data,
          refArtboardsData,
          containerID,
        },
        delta,
        resetCloneOffset: false,
        userPreference,
      });
    }
  }

  async cloneToOther(options: { toArtboardID: string; delta: MoveDelta; userPreference?: IUserPreference }) {
    const comps = this.doGetCanCopyComponentsFromSelected();
    if (comps) {
      const { containerID, data, refArtboardsData } = this.getComponentCopyPayload(ClipboardType.Component_Copy, comps);
      await this.pasteComponents({
        data: {
          componentData: data,
          refArtboardsData,
          containerID,
        },
        ...options,
      });
    }
  }

  /**
   * 构建画板patchs
   * @param meta
   * @param artboardID
   * @param backupLayoutMap
   * @param needInfluenceChild
   * @returns
   */
  getArtboardMetaChangePatches(
    meta: IArtboardMetaPatch,
    artboardID: string,
    backupLayoutMap?: WeakMap<UIComponent, IBasicLayout>,
    needInfluenceChild?: boolean,
  ) {
    const dolist: Operation[] = [];
    const undolist: Operation[] = [];
    const componentSizePatch: ArtboardPatches = {
      do: {},
      undo: {},
    };

    // TODO 这有问题，外面可以处理非active画板，这里咋直接用 active
    const currentArtboard =
      this.doc.artboardsFragments.find((item) => item.realID === artboardID) ?? this.activeArtboard;

    if (!_.isUndefined(meta.name)) {
      dolist.push(Ops.replace('/name', meta.name));
      undolist.push(Ops.replace('/name', currentArtboard.name));
    }

    if (!_.isUndefined(meta.size)) {
      dolist.push(Ops.replace('/size', roundNumberObject(meta.size)));
      undolist.push(Ops.replace('/size', currentArtboard.size));
      const isNeedInfluenceChild =
        (needInfluenceChild === undefined || needInfluenceChild) && currentArtboard.layout.responsive;
      if (isNeedInfluenceChild) {
        const componentPatches = currentArtboard.updateSize(currentArtboard.size, meta.size, backupLayoutMap);
        Object.keys(componentPatches.do).forEach((id) => {
          componentSizePatch.do[id] = componentPatches.do[id];
        });
        Object.keys(componentPatches.undo).forEach((id) => {
          componentSizePatch.undo[id] = componentPatches.undo[id];
        });
      }
    }
    // 与size分开管理
    if (!_.isUndefined(meta.orientation)) {
      if (currentArtboard.orientation) {
        dolist.push(Ops.replace('/orientation', meta.orientation));
        undolist.push(Ops.replace('/orientation', currentArtboard.orientation));
      } else {
        dolist.push(Ops.add('/orientation', meta.orientation));
        undolist.push(Ops.add('/orientation', currentArtboard.orientation));
      }
    }

    if (!_.isUndefined(meta.position)) {
      dolist.push(Ops.replace('/position', roundNumberObject(meta.position)));
      undolist.push(Ops.replace('/position', currentArtboard.position));
    }

    if (!_.isUndefined(meta.interaction)) {
      dolist.push(Ops.replace('/interaction', meta.interaction));
      undolist.push(Ops.replace('/interaction', currentArtboard.interactions));
    }

    if (!_.isUndefined(meta.responsive)) {
      dolist.push(Ops.replace('/responsive', meta.responsive));
      undolist.push(Ops.replace('/responsive', currentArtboard.layout.responsive));
    }

    if (!_.isUndefined(meta.background)) {
      dolist.push(Ops.replace('/background', meta.background));
      undolist.push(Ops.replace('/background', currentArtboard.background));
    }

    if (!_.isUndefined(meta.guides)) {
      dolist.push(Ops.replace('/guides', meta.guides));
      undolist.push(Ops.replace('/guides', currentArtboard.guides));
    }

    const patches: ArtboardPatches = {
      do: {
        self: dolist,
        ...componentSizePatch.do,
      },
      undo: {
        self: undolist,
        ...componentSizePatch.undo,
      },
    };

    return patches;
  }

  //合并多个画板的元信息：name, size, position, interaction 等
  moreBatchPatchArtboardMeta(metaRecordCollection: { meta: IArtboardMetaPatch; artboardID: string }[]): PagePatches {
    return metaRecordCollection.reduce<PagePatches>((pagePatches, { artboardID, meta }) => {
      pagePatches[artboardID] = this.getArtboardMetaChangePatches(meta, artboardID);
      return pagePatches;
    }, {});
  }

  //如果更新patch里面。存在新的内容画板，需要同步内置画板的宽高
  contentPanelV2UpdateFragmentLayout(patches: ArtboardPatches) {
    const ContentPanelV2Components = Object.keys(patches.do)
      .map((id) => this.doc.getComponentByID(id)!)
      .filter((comp) => comp && comp.type === CContentPanelV2);
    //如果存在
    if (ContentPanelV2Components.length) {
      //存在新的内容画板，同步关联的辅助画板宽高
      const synsArtboardLayoutCollectionPatchs = ContentPanelV2Components
        //同步内置辅助画板的宽高，生成metaArtboardPatch
        .map((compData) => {
          const compId = compData?._id as string;
          const size = (patches.do[compId]?.find((op) => {
            if (op.op === 'replace' && op.path.indexOf('size') > -1) {
              return true;
            }
            return false;
          }) as ReplaceOperation<ISize>)?.value;

          const artboardsInContentPanel = (compData.value as string[]).map(
            (fragmentID) => this.doc.getArtboardByID(fragmentID)!,
          );

          const childrenComponents = artboardsInContentPanel.reduce((components: UIComponent[], artboard) => {
            return components.concat(artboard.components);
          }, []);

          //同步修改内层的组件自适应大小
          this.sizeChange(
            'all',
            { ...size, lockedRatio: false },
            { allowLockedComp: true, targetComponents: childrenComponents },
          );
          const layoutLine = compData.properties?.contentPanelLayout?.layoutLine || contenPanelLayoutLineLimit;
          //同步画板布局
          const metaRecordCollection = setArtboardSyncLayoutPatch(artboardsInContentPanel, {
            layoutLine: layoutLine,
            uniDynamicInfo: size ? { size } : undefined,
            clearDynamic: true,
          });
          return metaRecordCollection;
        })
        //过滤掉不包含辅助画板，为空的动态面板情况
        .filter((patch) => patch)
        //合并metaPatch
        .reduce((prevMetaPatchs, nextMetaPatchs) => {
          return prevMetaPatchs?.concat(nextMetaPatchs || []);
        }, []);

      //转化pagePatch
      const batchArtboardPatch = this.moreBatchPatchArtboardMeta(
        synsArtboardLayoutCollectionPatchs as { meta: IArtboardMetaPatch; artboardID: string }[],
      );
      return batchArtboardPatch;
    }
  }

  // 更新多个画板的元信息：name, size, position, interaction 等
  batchPatchArtboardMeta(metaRecordCollection: { meta: IArtboardMetaPatch; artboardID: string }[]) {
    const batchChangeArtboardMetaPatches = this.moreBatchPatchArtboardMeta(metaRecordCollection);
    this.update(batchChangeArtboardMetaPatches);
  }

  // 更新画板的元信息：name, size, position, interaction 等
  patchArtboardMeta(meta: IArtboardMetaPatch, artboardID?: string) {
    const id = artboardID || this.activeArtboard.artboardID;
    const patchs = this.getArtboardMetaChangePatches(meta, id, undefined, undefined);
    this.updateSingleArtboard(id, patchs);
  }

  // 删除 artboard, 刷新对应内容面板
  public removeFragment(artboardID: string) {
    this.removeFragmentImplement(artboardID);
  }

  // removeFragment 有需要被覆盖,实际实现方法放在这里
  public removeFragmentImplement(artboardID: string) {
    const command = new RemoveFragmentCommand(this, artboardID);
    command.execute();
  }

  // 批量删除 artboard, 刷新对应内容面板
  public batchRemoveFragment(fragments: UIFragment[]) {
    this.batchRemoveFragmentImplement(fragments);
  }

  // batchRemoveFragment 有需要被覆盖,实际实现方法放在这里
  public batchRemoveFragmentImplement(fragments: UIFragment[], relatingPatch?: PagePatches) {
    const command = new BatchRemoveFragmentCommand(this, fragments, relatingPatch);
    command.execute();
  }

  // TODO 单体的计算，支持批量
  private calcArtboardPosition(artboard: IComponentData) {
    this.temporaryAllFragmentsData = [this.doc.mainArtboard, ...this.doc.fragments].map((fragment) => {
      return fragment.toJSON();
    });

    const positionCalculator = createArtboardPositionCalculator(
      this.activeArtboard,
      this.temporaryAllFragmentsData,
      artboard,
      this.originPointPosition,
      this.positionCalcThreshold,
    );
    const result = positionCalculator.calc();

    this.temporaryAllFragmentsData = [];
    return result;
  }

  // TODO v2，计算位置可能要改

  createContentPanelRelatingArtboardData(payload: Partial<IArtboardAddPatch>) {
    const { size = { width: 100, height: 100 }, ownerID } = payload;
    // 创建画板数据
    const fakeArtboard = createFakeArtboard();
    fakeArtboard.size = size;

    // TODO 这里可覆写，以适应新内容面板内编辑
    const position = this.calcArtboardPosition(fakeArtboard);

    const artboards = this.doc.artboards;
    const length = artboards.filter((artboards) => !artboards.ownerID).length;

    const newArtboardData: IArtboardAddPatch = {
      _id: shortid.generate(),
      // 此处appID由于融合项目可能与全局的this.appID不一致
      appID: artboards[0]?.appID || this.appID,
      nodeID: this.nodeID,
      ownerID,
      size,
      orientation: getOrientationFromSize(size),
      name: i18n('project.subArtboard', length),
      position,
      ...payload,
    };

    return newArtboardData;
  }

  async _addFragmentFromContentPanel(
    contentPanel: UIContentPanelComponent,
    data: IArtboardAddPatch,
    isSelected?: boolean,
  ) {
    try {
      // io 添加
      const artboardData = await io.addArtboard(data);

      // 创建 patches
      const addArtboardPagePatches = this.addArtboardPatches(artboardData._id);
      const setContentPanelRefPagePatches = contentPanel.setContentPanelRefValue(artboardData._id, isSelected);
      // 更新
      this.update({ ...addArtboardPagePatches, ...setContentPanelRefPagePatches });
      return artboardData;
    } catch (e) {
      console.error(e);
      this.onError && this.onError(e as CustomError);
    }
  }

  // TODO
  // 在内容面板的值编辑器中添加辅助画板
  async addFragmentFromContentPanel(contentPanel: UIContentPanelComponent) {
    try {
      const data = this.createContentPanelRelatingArtboardData({ size: contentPanel.size });
      const artboardData = await io.addArtboard(data);
      const addArtboardPagePatches = this.addArtboardPatches(artboardData._id);
      const setContentPanelRefPagePatches = contentPanel.setContentPanelRefValue(artboardData._id);
      this.update({ ...addArtboardPagePatches, ...setContentPanelRefPagePatches });
      return artboardData;
    } catch (e) {
      console.error(e);
      this.onError && this.onError(e as CustomError);
    }
  }

  // 添加辅助画板
  public addFragment(position: IPosition, size: ISize, ownerID?: string, name?: string): Promise<IArtboard> {
    const artboards = this.doc.artboards;
    const length = artboards.filter((artboards) => !artboards.ownerID).length;
    return io
      .addArtboard({
        _id: shortid.generate(),
        // 此处appID由于融合项目可能与全局的this.appID不一致
        appID: artboards[0]?.appID || this.appID,
        nodeID: this.nodeID,
        //新内容面板所属id
        ownerID,
        position,
        size,
        name: name ? name : i18n('project.subArtboard', length),
      })
      .then((artboard) => {
        this.update(this.addArtboardPatches(artboard._id));
        return artboard;
      })
      .catch((e) => {
        console.error(e);
        this.onError && this.onError(e);
      }) as Promise<IArtboard>;
  }

  // 克隆画板
  cloneArtboard(artboard: IComponentData, newPosition: IPosition) {
    apis.artboard
      .cloneArtboard(this.nodeID, artboard as IArtboard, newPosition)
      .then((newArtboardID) => {
        this.update(this.addArtboardPatches(newArtboardID));
        this.clearSelectedFragments();
      })
      .catch((e) => this.onError && this.onError(e));
  }

  // 批量克隆画板
  batchCloneArtboard(
    artboardInfos: { data: IComponentData; position: IPosition }[],
    onMorePatch?: (newArtboardIDs: string[]) => PagePatches,
  ) {
    return Promise.all(
      artboardInfos.map(({ data, position }) => {
        const artboard = data as IArtboard;
        artboard.nodeID = this.nodeID;
        return apis.artboard.cloneArtboard(this.nodeID, artboard, position);
      }),
    )
      .then((newArtboardIDs) => {
        const batchAddFragmentPatches = newArtboardIDs.reduce((prevPatches, newArtboardID) => {
          return mergePatches(prevPatches, this.addArtboardPatches(newArtboardID)['ROOT']);
        }, getEmptyArtboardPatchesOfArtboardSelf());
        //如果存在额外的patch
        const morePatch = onMorePatch ? onMorePatch(newArtboardIDs) : {};

        // clearSelectedFragments内的全量更新不执行，在update时统一执行
        this.blockNoticeUpdate = true;
        this.clearSelectedFragments();
        this.blockNoticeUpdate = false;
        this.update({ ...morePatch, ROOT: batchAddFragmentPatches });
        return newArtboardIDs;
      })
      .catch((e) => this.onError && this.onError(e));
  }

  async getBatchCloneArtboardPatches(
    artboardInfos: { data: IComponentData; position: IPosition }[],
  ): Promise<PagePatches | undefined> {
    try {
      const newArtboardIDs = await Promise.all(
        artboardInfos.map(({ data, position }) => {
          const artboard = data as IArtboard;
          artboard.nodeID = this.nodeID;
          return apis.artboard.cloneArtboard(this.nodeID, artboard, position);
        }),
      );

      const batchAddFragmentPatches = newArtboardIDs.reduce((prevPatches, newArtboardID) => {
        return mergePatches(prevPatches, this.addArtboardPatches(newArtboardID)['ROOT']);
      }, getEmptyArtboardPatchesOfArtboardSelf());

      return { ROOT: artboardInfos.length ? batchAddFragmentPatches : { do: {}, undo: {} } };
    } catch (e) {
      this.onError && this.onError(e);
    }
  }

  // 自动重新选择激活的画板：检查选中的画板是否已经为空，如果为空，自动重新选择
  autoReselectActiveArtboard(patches: PageOperations) {
    if (patches.ROOT && patches.ROOT.self && patches.ROOT.self.length > 0) {
      const op = patches.ROOT.self[0];
      if (op.op === 'remove') {
        const id = op.path;
        if (this.activeArtboard.realID === id) {
          this.setActiveArtboard(this.doc.mainArtboard);
          this.clearSelected();
        }
      }
    }
  }

  getCommonInteractionInfo(): {
    component: UIComponent | null;
    componentID?: string;
    artboard: UIFragment;
    artboardID: string;
    advanceComp?: UIComponent;
    isMainArtboard: boolean;
    interaction: IInteraction;
  } {
    let component = this.firstSelectedComponent;
    const isEditComp = !!this.selectedComponents.size;
    let advanceComp: UIComponent | undefined = component!;

    if (isEditComp) {
      // 判断是复合组件时的comp
      if (component instanceof UIContainerComponent) {
        component = component.selectedItem || component;
      } else {
        advanceComp = undefined;
      }
    }
    return {
      component,
      componentID: component?.id,
      artboard: this.activeArtboard,
      artboardID: this.activeArtboard.artboardID,
      advanceComp,
      isMainArtboard: this.activeArtboard.isMain,
      interaction: this.getInteraction(),
    };
  }

  getInteraction(): IInteraction {
    let interaction: IInteraction = {};
    const isEditComp = !!this.selectedComponents.size;

    let component = this.firstSelectedComponent;
    if (isEditComp) {
      if (component instanceof UIContainerComponent) {
        component = component.selectedItem || component;
      }
      interaction = component?.interactions || {};
    } else {
      interaction = this.activeArtboard.interactions;
    }
    return interaction;
  }

  // 复制单个事件的交互,支持多选
  copySingleEventInteraction(triggerType: TriggerType, selectedEvents: string[], selectedActions: string[]) {
    const instance = InteractionClipboard.create();
    const interaction = this.getInteraction();
    const { component, componentID, artboardID } = this.getCommonInteractionInfo();

    const newInteraction: IInteraction = {};
    const isTriggerTypeInHandle = triggerType === TriggerType.HANDLE;
    let fragmentId: string | undefined = '';

    selectedEvents.forEach((event) => {
      const handle = interaction[event];
      const newActions = cloneDeep(
        isTriggerTypeInHandle
          ? handle.actions
          : handle.actions.filter((action) => selectedActions.includes(action._id)),
      );

      newInteraction[event] = { ...handle, actions: newActions };
    });

    if (component) {
      const fragment = findParentByFiltering(component, (comp: UIComponent) => {
        return comp.type === 'artboard';
      });
      fragmentId = fragment?.realID;
    }

    instance.setClipData(newInteraction, componentID || artboardID, fragmentId);
  }

  // 复制组件或者画板所有交互
  copyAllInteraction() {
    const instance = InteractionClipboard.create();
    const fragment = findParentByFiltering(this.firstSelectedComponent!, (comp: UIComponent) => {
      return comp.type === 'artboard';
    });
    const fragmentId = this.firstSelectedComponent ? fragment?.realID : undefined;
    const { componentID, artboardID } = this.getCommonInteractionInfo();
    instance.setClipData(cloneDeep(this.getInteraction()), componentID || artboardID, fragmentId);
  }

  // 粘贴组件或者画板所有交互
  async pasteAllInteraction(callback?: (type?: IAlertInfoType) => void) {
    const clipboardInstance = InteractionClipboard.create();
    const {
      interaction: clipboardInteraction,
      isPrivateDeployment: isClipboardPrivateDeployment,
    } = await clipboardInstance.getClipData();
    const { isPrivateDeployment } = clipboardInstance;
    const { component, componentID, interaction } = this.getCommonInteractionInfo();
    // 设置全局的提示回调，避免多层参数传递
    this.interactionAlertCallback = callback;

    // 部署环境不一致
    if (isPrivateDeployment !== isClipboardPrivateDeployment) {
      return;
    }

    const supportedEvents = getSupportEventsInfo(component!);
    const clipboardEvents = Object.keys(clipboardInteraction);
    const usedEvents = clipboardEvents.filter((event) => supportedEvents.findIndex((item) => item.id === event) > -1);

    // 剪贴板中有不支持的触发方式
    if (usedEvents.length !== clipboardEvents.length) {
      this.interactionAlertCallback && this.interactionAlertCallback();
    }

    if (usedEvents.length === 0) {
      return;
    }

    const finalInteraction: IInteraction = await this.getConstructPastedInteraction(interaction);

    if (componentID) {
      this.replaceInteraction(finalInteraction, interaction, component!);
    } else {
      this.patchArtboardMeta({
        interaction: finalInteraction,
      });
    }
  }

  // 构建粘贴后各个组件或者画板的交互
  getConstructPastedInteraction = async (interaction: IInteraction): Promise<IInteraction> => {
    const clipboardInstance = InteractionClipboard.create();
    const { interaction: clipboardInteraction, targetId, fragmentId } = await clipboardInstance.getClipData();
    const newInteraction: IInteraction = {};

    const clipboardEvents = clipboardInteraction ? Object.keys(clipboardInteraction) : [];
    clipboardEvents.forEach((event) => {
      let newActions: IActionBase[];
      // 已有事件，则合并命令。没有事件，则新增。
      if (!!clipboardInteraction[event] && !!interaction[event]) {
        newActions = interaction[event].actions.concat(clipboardInteraction[event].actions);
      } else {
        newActions = clipboardInteraction[event].actions;
      }

      const filterNewActions = this.getFitActions(cloneDeep(newActions), {
        targetId,
        fragmentId,
      });

      if (filterNewActions.length) {
        newInteraction[event] = {
          ...(interaction[event] ? interaction[event] : clipboardInteraction[event]),
          actions: filterNewActions,
        };
      }
    });
    return Object.assign({}, interaction, newInteraction);
  };

  // 多选组件或者粘贴交互
  pasteInteractionToMulti = async (callback?: (type?: IAlertInfoType) => void) => {
    const { artboardID, componentID } = this.getCommonInteractionInfo();
    const isToComp = this.selectedComponents.size > 1;
    const selectedCompsOrFragments = [...(isToComp ? this.selectedComponents : this.selectedFragments)];
    this.interactionAlertCallback = callback;

    const patchInfo = [];
    for (let i = 0; i < selectedCompsOrFragments.length; i++) {
      const item = selectedCompsOrFragments[i];
      const newInteraction = await this.getConstructPastedInteraction(item.interactions);
      for (const event in newInteraction) {
        newInteraction[event].actions.forEach((action) => {
          const pasteAction = action as IPasteActionBase;
          if (pasteAction.isCreateByPaste && pasteAction.target === componentID) {
            pasteAction.target = item.id;
            delete pasteAction.isCreateByPaste;
          }
        });
      }
      const newData = {
        ...item,
        interactions: newInteraction,
      } as UIComponent | UIFragment;

      patchInfo.push({
        id: item.realID,
        oldData: item,
        newData,
      });
    }

    this.multiReplaceInteraction(patchInfo, isToComp ? artboardID : 'ROOT');
  };

  // 根据指定位置粘贴命令
  async pasteCommandByIndex(
    selectedEvents: string[],
    insertIndex?: number,
    callback?: (type?: IAlertInfoType) => void,
  ) {
    const instance = InteractionClipboard.create();
    const {
      interaction: clipboardInteraction,
      targetId,
      fragmentId,
      isPrivateDeployment: isClipboardPrivateDeployment,
    } = await instance.getClipData();
    const { componentID, interaction, component } = this.getCommonInteractionInfo();
    const { isPrivateDeployment } = instance;
    this.interactionAlertCallback = callback;

    // 部署环境不一致
    if (isPrivateDeployment !== isClipboardPrivateDeployment) {
      return;
    }

    const newInteraction: IInteraction = {};

    selectedEvents.forEach((event) => {
      const allClipboardActions = Object.keys(clipboardInteraction).map((event) => clipboardInteraction[event].actions);
      let insertedActions = cloneDeep(interaction[event].actions);
      if (insertIndex) {
        this.interactionInsertByIndex = insertIndex;
        insertedActions.splice(insertIndex, 0, ...flatten(allClipboardActions));
      } else {
        insertedActions = interaction[event].actions.concat(...allClipboardActions);
      }

      const filterNewActions = this.getFitActions(cloneDeep(insertedActions), {
        targetId,
        fragmentId,
      });

      if (filterNewActions.length) {
        newInteraction[event] = {
          ...interaction[event],
          actions: filterNewActions,
        };
      }
    });

    // 没有选中有交互，则粘贴交互应该是新增的
    if (!selectedEvents.length) {
      Object.assign(newInteraction, clipboardInteraction);
    }

    const finalInteraction = {
      ...interaction,
      ...newInteraction,
    };

    if (componentID) {
      this.replaceInteraction(finalInteraction, interaction, component!);
    } else {
      this.patchArtboardMeta({
        interaction: finalInteraction,
      });
    }
  }
  /**
   * 清除交互
   */
  cleanInteraction = () => {
    const selected = this.selectedComponents.size > 0 ? Array.from(this.selectedComponents) : [this.activeArtboard];
    const selectedPagePatches: PagePatches = {};
    selected.forEach((sourceComp: UIComponent) => {
      const interactions = sourceComp.interactions;
      const events = Object.keys(interactions);
      events.forEach((t) => {
        const ops = sourceComp.removeAllInteractionActions(t as EventType);
        // this.removeAllAction(t as EventType, sourceComp);
        if (ops) {
          const pagePatches = convertUIOperationToPagePatches(sourceComp, ops);
          const artboadID = sourceComp.ownerArtboardID;
          if (selectedPagePatches[artboadID]) {
            mergePatches(selectedPagePatches[artboadID], pagePatches[artboadID]);
          } else {
            selectedPagePatches[artboadID] = pagePatches[artboadID];
          }
        }
      });
    });

    this.update(selectedPagePatches);
  };

  // 多选删除交互命令
  deleteMultiInteraction = (triggerType: TriggerType, selectedEvents: string[], selectedActions: string[]) => {
    const { componentID, interaction, component } = this.getCommonInteractionInfo();
    const clonedInteraction = cloneDeep(interaction);
    const currEvent = selectedEvents[0];

    if (triggerType === TriggerType.COMMAND) {
      clonedInteraction[currEvent].actions = clonedInteraction[currEvent].actions.filter(
        (action) => !selectedActions.includes(action._id),
      );
      // 当一个事件中的所有命令全部删除，则删除该事件
      if (!clonedInteraction[selectedEvents[0]].actions.length) {
        delete clonedInteraction[selectedEvents[0]];
      }
    } else if (triggerType === TriggerType.HANDLE) {
      selectedEvents.forEach((event) => {
        delete clonedInteraction[event];
      });
    }

    if (componentID) {
      this.replaceInteraction(clonedInteraction, interaction, component!);
    } else {
      this.patchArtboardMeta({
        interaction: clonedInteraction,
      });
    }
  };

  // 处理一些特殊情况的action
  getFitActions = (
    newActions: IActionBase[],
    ids: {
      targetId: string;
      fragmentId?: string;
    },
  ) => {
    const { componentID, isMainArtboard } = this.getCommonInteractionInfo();
    const { targetId: clipboardTargetId } = ids;
    let filterNewActions: IActionBase[] = newActions;
    /*  过滤不兼容的命令

     1. 主画板不能成为target
     2. 重复的页面命令
     3，重复的画板命令
     4. 重复的页面与画板命令
     
     */
    if (!componentID) {
      filterNewActions = newActions.filter((action) => !(isMainArtboard && action.target === clipboardTargetId));
    }

    filterNewActions = this.filterActionsAndAlert(filterNewActions);

    return filterNewActions;
  };

  // 修改action需要修改的指向
  changeActionsTarget = (
    action: IActionBase,
    targetIds: { newTargetId: string; originTargetId: string },
    fragmentIds: {
      newFragmentId: string;
      originFragmentId?: string;
    },
    advanceComp?: UIComponent,
  ) => {
    const { newTargetId, originTargetId } = targetIds;
    const { newFragmentId, originFragmentId } = fragmentIds;
    // 将复制时 "自己" 的指向改成粘贴的目标
    // 复合组件的小项的"自己"，需要改为针对复合组件本身
    if (action.target === originTargetId) {
      action.target = advanceComp ? advanceComp.realID : newTargetId;
    }
    // 该组件与自身父级画板有交互关系
    if (action.type === 'fragment' && action.target === originFragmentId) {
      action.target = newFragmentId;
    }
  };

  filterActionsAndAlert = (newActions: IActionBase[], callback?: (type?: IAlertInfoType) => void) => {
    const filterNewActions = newActions;
    const cb = callback ?? this.interactionAlertCallback;

    // 多余的页面和画板
    const actionsData: TActionsType = {};
    filterNewActions.forEach((item, index) => {
      const itemType = item.type as keyof typeof actionsData;
      if (!actionsData[itemType]) {
        actionsData[itemType] = [];
      }
      actionsData[itemType]!.push([index, item._id]);
    });

    const filterActionIds: string[] = []; // 通过filterInteractionPageOrFragment方法过虑后得到的数据

    // 过滤多余的页面及跳转和多余的画板
    const filterInteractionPageOrFragment = (filterData: [number, string][]) => {
      filterData.forEach((item, index) => {
        const typeIsNumber = typeof this.interactionInsertByIndex === 'number';
        if (typeIsNumber && this.interactionInsertByIndex === item[0]) {
          return;
        }
        if (!typeIsNumber && index === filterData.length - 1) {
          return;
        }
        filterActionIds.push(item[1]);
      });
    };

    const jumpActionData = (actionsData['page'] || []).concat(actionsData['link'] || []);
    if (jumpActionData.length > 1) {
      cb && cb('page');
      filterInteractionPageOrFragment(jumpActionData);
    }

    const fragmentData = actionsData['fragment'] ?? [];
    if (fragmentData.length > 1) {
      cb && cb('fragment');
      filterInteractionPageOrFragment(fragmentData);
    }
    this.interactionInsertByIndex = undefined;
    return filterNewActions.filter((item) => !filterActionIds.includes(item._id));
  };

  distinguishAlertType = (type?: IAlertInfoType) => {
    let alertText: string = '';
    switch (type) {
      case 'page':
        alertText = i18n('alert.redundantPage');
        break;
      case 'fragment':
        alertText = i18n('alert.redundantFragment');
        break;
      case 'pageAndFragment':
        alertText = i18n('alert.redundantPageAndFragment');
        break;
      default:
        alertText = i18n('alert.pasteInteractionFail');
        break;
    }
    return alertText;
  };

  //复制组件样式（文本及形状）
  async copyComponentStyle() {
    const compData = _.cloneDeep(this.getCompData(this.firstSelectedComponent!));
    await copyStyleData(compData, ClipboardType.Style_Copy);
  }

  // 粘贴组件样式
  async pasteComponentStyle() {
    const artboardID = this.activeArtboard.artboardID;
    const selectedComps = [...this.selectedComponents];
    const data = await getCopiedStyleData();
    if (!data) {
      return;
    }
    const { properties, opacity, states, textBehaviour, type } = data;
    const patchInfo = selectedComps.map((item) => {
      let newSelectedComponents: Set<UIComponent> = new Set([item]);
      const newProperties = getNewPropertiesByAllowProperties(
        cloneDeep(item.properties),
        cloneDeep(properties),
        getPublicPropertiesByComps(newSelectedComponents),
        type === item.type,
      );
      const newStates = getNewStateByAllowStateAndProperties(
        cloneDeep(item.$data.states),
        cloneDeep(states),
        getSupportStates(item),
        getPublicPropertiesByComps(newSelectedComponents),
      );

      const newData: IComponentData = {
        ...item.$data,
        properties: newProperties,
        opacity,
        states: newStates,
        textBehaviour,
      };
      return {
        id: item.realID,
        oldData: item,
        newData: newData,
      };
    });
    this.updateCompStyle(patchInfo, artboardID);
  }

  setCompTextValue(comp: UIComponent, value: string, option: { wrap?: boolean; size?: ISize }) {
    if (!value && comp.type === CText) {
      this.removeSelectedComponent();
      return;
    }
    super.setCompTextValue(comp, value, option);
  }
}

export default CoreEditor;
