import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// 经典标签
export default {
  ...flowBase,
  name: i18n('resource.flow.classicTab'),
  type: 'classicTab',
  thumb: {
    spriteIconClass: SpriteThumb.ClassicTab.className,
    dragPosition: SpriteThumb.ClassicTab.position,
  },
  template: getShapeData(
    i18n('resource.flow.classicTab'),
    { width: 60, height: 60 },
    {
      data: [
        {
          point: {
            x: 0,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 36,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 60,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 60,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
      closed: false,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: false }),
    };
  },
};
