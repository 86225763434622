import * as React from 'react';

import { ScrollBars } from '@dsm';

import './index.scss';

export interface IPureTextAreaEditorProps {
  width: number;
  height: number;
  value: string;
  autoFocus?: boolean;
  autoSelectWhenFocus?: boolean;
  onTyped?: () => void;
  onChange?: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

export interface IPureTextAreaEditorState {
  areaVal: string;
  scrollDomTop: number;
}

export default class PureTextAreaEditor extends React.Component<IPureTextAreaEditorProps, IPureTextAreaEditorState> {
  private textareaDom: React.RefObject<HTMLTextAreaElement> = React.createRef();
  private scrollDom: React.RefObject<ScrollBars> = React.createRef();

  constructor(props: IPureTextAreaEditorProps) {
    super(props);
    this.state = {
      areaVal: props.value,
      scrollDomTop: 0,
    };
  }

  get value() {
    return this.state.areaVal;
  }

  get textarea() {
    return this.textareaDom.current;
  }

  reloadValue() {
    const value = this.props.value;
    this.setState(
      {
        areaVal: value,
      },
      () => {
        window.setTimeout(() => {
          const textarea = this.textarea;
          if (textarea) {
            textarea.value = value;
            textarea.focus();
            textarea.select();
          }
        }, 100);
      },
    );
  }

  componentDidMount() {
    const { autoFocus, autoSelectWhenFocus } = this.props;
    if (autoFocus && this.textareaDom.current) {
      this.textareaDom.current.focus();
      if (autoSelectWhenFocus) {
        this.textareaDom.current.select();
      }
    }
  }

  handleAreaBlur = () => {
    const { onChange } = this.props;
    onChange && onChange(this.value);
  };

  handleAreaChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const areaVal = e.currentTarget.value;
    const { onChange } = this.props;
    this.setState({ areaVal }, () => {
      if (this.scrollDom.current) {
        this.scrollDom.current.scrollToBottom();
      }
    });
    onChange && onChange(areaVal);
  };

  handleScrollBar = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.scrollDom.current && this.textareaDom.current) {
      const top = this.scrollDom.current.getScrollTop();
      this.setState({ scrollDomTop: top });
      this.textareaDom.current.scrollTo(0, top);
    }
  };

  render() {
    const { width, height, onKeyDown, onTyped } = this.props;
    const { areaVal, scrollDomTop } = this.state;
    //处理最后一个字符是换行符丢失的问题
    const lastIndex = areaVal.lastIndexOf('\n');
    let areaLastEmpty = false;
    if (lastIndex === areaVal.length - 1) {
      areaLastEmpty = true;
    }

    return (
      <div style={{ width, height }} className="pure-textarea-editor">
        <ScrollBars
          // height={height}
          style={{ height: height }}
          onScroll={this.handleScrollBar}
          ref={this.scrollDom as React.RefObject<ScrollBars>}
        >
          <div className="pure-textarea-editor-support">
            {areaVal}
            {areaLastEmpty && <br />}
          </div>
          <textarea
            ref={this.textareaDom as React.RefObject<HTMLTextAreaElement>}
            className="pure-textarea-editor-area"
            value={areaVal}
            style={{ top: scrollDomTop }}
            onChange={this.handleAreaChange}
            onKeyDown={onKeyDown}
            onBlur={this.handleAreaBlur}
            onInput={onTyped}
          ></textarea>
        </ScrollBars>
      </div>
    );
  }
}
