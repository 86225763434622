import * as React from 'react';

import { PaginationMaxDisplayItemCount } from '../../../constants';
import { UITheme } from '../../../models';

import PaginationItem from './PaginationItem';
import PaginationPrevOrNext from './PaginationPrevOrNext';
import PaginationForwardOrBackward from './PaginationForwardOrBackward';
import SizeChanger from './SizeChanger';
import QuickJumper from './QuickJumper';

import './index.scss';

interface IPaginationContainerProps {
  theme: UITheme;
  total: number;
  pageIndex: number;
  pageSize: number;
  showPageIndex: boolean;
  showQuickJumper: boolean;
  showSizeChanger: boolean;
  containerRef: React.RefObject<HTMLUListElement>;

  onChange?(pageIndex: number, pageSize?: number): void;
  onChangeSize?(pageSize: number, pageIndex: number): void;
}

const PaginationContainer: React.FC<IPaginationContainerProps> = (props: IPaginationContainerProps) => {
  const {
    theme,
    pageIndex,
    pageSize,
    total,
    showPageIndex,
    showQuickJumper,
    showSizeChanger,
    containerRef,
    onChange,
    onChangeSize,
  } = props;

  const changeSize = (size: number) => {
    const newMaxPage = Math.ceil(total / size);
    if (pageIndex > newMaxPage) {
      onChange && onChange(newMaxPage, size);
    }
    onChangeSize && onChangeSize(size, pageIndex > newMaxPage ? newMaxPage : pageIndex);
  };

  const computePageItemCount = () => {
    if (typeof pageSize !== 'number' || !pageSize) {
      return 0;
    }
    if (typeof total !== 'number') {
      return 0;
    }
    return Math.ceil(total / pageSize);
  };

  const computeCanShowAllPage = (total: number) => {
    return total >= 1 && total <= PaginationMaxDisplayItemCount;
  };

  const computeIsShowPagePrev = (totalCount: number) => {
    if (computeCanShowAllPage(totalCount)) {
      return false;
    }
    return pageIndex > 4;
  };

  const computeIsShowPageNext = (totalCount: number) => {
    if (computeCanShowAllPage(totalCount)) {
      return false;
    }
    return totalCount - pageIndex >= 4;
  };

  const pageItemTotalCount = computePageItemCount();

  const renderPager = () => {
    const isShowAllPage = computeCanShowAllPage(pageItemTotalCount);

    if (isShowAllPage) {
      return [...new Array(pageItemTotalCount)].map((_, index: number) => (
        <PaginationItem data={index + 1} key={index} current={pageIndex} onChange={onChange} />
      ));
    }

    const isShowPrev = computeIsShowPagePrev(pageItemTotalCount);
    const isShowNext = computeIsShowPageNext(pageItemTotalCount);

    let displayCounts = Array.from(new Array(pageItemTotalCount), (_, i) => i + 1);

    displayCounts = displayCounts.filter((item) => {
      return item == 1 || item === pageItemTotalCount || (item >= pageIndex - 2 && item <= pageIndex + 2);
    });
    return displayCounts.map((item, index) => {
      return (
        <React.Fragment key={index}>
          {isShowPrev && index === 1 && (
            <PaginationForwardOrBackward type="forward" current={pageIndex} onChange={onChange} />
          )}
          <PaginationItem data={item} key={item} current={pageIndex} onChange={onChange} />
          {isShowNext && index === displayCounts.length - 2 && (
            <PaginationForwardOrBackward type="backward" current={pageIndex} onChange={onChange} />
          )}
        </React.Fragment>
      );
    });
  };

  // TODO
  if (!showPageIndex && !showQuickJumper && !showSizeChanger) {
    return null;
  }

  return (
    <ul className="dsm-c-pagination-container" ref={containerRef}>
      <PaginationPrevOrNext type="left" current={pageIndex} maxPage={pageItemTotalCount} onChange={onChange} />
      {renderPager()}
      <PaginationPrevOrNext type="right" current={pageIndex} maxPage={pageItemTotalCount} onChange={onChange} />
      <li className="dsm-c-pagination-options">
        <SizeChanger theme={theme} size={pageSize} show={showSizeChanger} onChangeSize={changeSize} />
        <QuickJumper show={showQuickJumper} totalPage={pageItemTotalCount} onChange={onChange} />
      </li>
    </ul>
  );
};

export default PaginationContainer;
