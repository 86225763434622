import { PureColor } from '@fbs/rp/models/properties/color';

export const TransparentColor: PureColor = { r: 0, g: 0, b: 0, a: 0 };

export const ShadowColor: PureColor = { r: 0, g: 0, b: 0, a: 0.15 };

export const TextShadowColor: PureColor = { r: 0, g: 0, b: 0, a: 0.15 };
//纯白'#FFF';
export const WhiteColor: PureColor = { r: 255, g: 255, b: 255, a: 1 };
//纯黑 '#000';
export const BlackColor: PureColor = { r: 0, g: 0, b: 0, a: 1 };
//灰'#707070';
export const DarkGrayColor: PureColor = { r: 112, g: 112, b: 112, a: 1 };
//绿 '#41D662';
export const DarkGreenColor: PureColor = { r: 74, g: 214, b: 98, a: 1 };
//系统红 '#FE4066';
export const RedColor: PureColor = { r: 254, g: 64, b: 102, a: 1 };
//系统蓝 '#418FD7';
export const BlueColor: PureColor = { r: 65, g: 143, b: 215, a: 1 };
//深蓝色 '#009DFF'
export const DeepBlueColor: PureColor = { r: 0, g: 157, b: 255, a: 1 };
// 淡红 '#FFD9E0'
export const PaleRedColor: PureColor = { r: 255, g: 217, b: 224, a: 1 };

export const HalfTransparentDarkGreenColor: PureColor = { r: 65, g: 143, b: 215, a: 0.6 };

export const LightRedColor: PureColor = { r: 254, g: 64, b: 102, a: 0.9 };

export const DarkRedColor: PureColor = { r: 254, g: 64, b: 102, a: 0.8 };
// #F0F0F0
export const SilverWhiteColor: PureColor = { r: 240, g: 240, b: 240, a: 1 };
// #E2E2E2
export const LightGrayColor: PureColor = { r: 226, g: 226, b: 226, a: 1 };
// #999
export const GrayColor: PureColor = { r: 153, g: 153, b: 153, a: 1 };

export const DefaultWhiteFillColor: PureColor = WhiteColor;

export const DisabledWhiteFillColor: PureColor = { ...WhiteColor, a: 0.3 };

export const DefaultDarkFillColor: PureColor = { r: 240, g: 240, b: 240, a: 1 };

export const DisabledDarkFillColor: PureColor = { r: 224, g: 224, b: 224, a: 1 };

export const DefaultStrokeColor: PureColor = { r: 119, g: 119, b: 119, a: 1 };
export const DefaultStrokeColor1: PureColor = { r: 121, g: 121, b: 121, a: 1 };
export const DisabledStrokeColor: PureColor = { r: 119, g: 119, b: 119, a: 0.3 };

export const SelectedStrokeColor: PureColor = { r: 65, g: 143, b: 215, a: 1 };

export const FocusStrokeColor: PureColor = { r: 65, g: 143, b: 215, a: 1 };

export const HoverStrokeColor: PureColor = { r: 65, g: 143, b: 215, a: 1 };

export const PressedStrokeColor: PureColor = { r: 65, g: 143, b: 215, a: 1 };

// '#333'
export const DefaultTextColor: PureColor = { r: 51, g: 51, b: 51, a: 1 };
//'#777'
export const DisabledTextColor: PureColor = { r: 119, g: 119, b: 119, a: 0.3 };
// '#777
export const DefaultIconColor: PureColor = { r: 119, g: 119, b: 119, a: 1 };

export const DisabledIconColor: PureColor = { ...BlackColor, a: 0.3 };

export const DefaultSelectColor: PureColor = { r: 119, g: 119, b: 119, a: 1 };

export const DefaultTableOuterStrokeColor: PureColor = { r: 216, g: 216, b: 216, a: 1 };

export const DefaultTableInnerStrokeColor: PureColor = { r: 216, g: 216, b: 216, a: 1 };

export const SystemDarkColor: PureColor = { r: 153, g: 165, b: 190, a: 1 };

export const SystemGreenColor: PureColor = { r: 27, g: 190, b: 10, a: 1 };

export const RuleGraduationColor: PureColor = { r: 66, g: 81, b: 108, a: 1 };
// 修改前 '#223148'
// 修改后 '#009DFF'
export const RulerActiveAreaColor: PureColor = { r: 0, g: 157, b: 255, a: 0.2 };

export const AndDesignColors: { [key: string]: PureColor } = {
  PrimaryColor: { r: 24, g: 144, b: 255, a: 1 },
  LinkColor: { r: 24, g: 144, b: 255, a: 1 },
  SuccessColor: { r: 82, g: 196, b: 26, a: 1 },
  WarningColor: { r: 250, g: 173, b: 20, a: 1 },
  ErrorColor: { r: 245, g: 34, b: 45, a: 1 },
  HeadingColor: { r: 0, g: 0, b: 0, a: 0.85 },
  TextColor: { r: 0, g: 0, b: 0, a: 0.65 },
  TextSecondDarkColor: { r: 0, g: 0, b: 0, a: 0.45 },
  DisabledColor: { r: 0, g: 0, b: 0, a: 0.25 },
  BorderColor: { r: 217, g: 217, b: 217, a: 1 },
  BoxShadowColor: { r: 0, g: 0, b: 0, a: 0.15 },
};

// #A3A3A3
export const MarkerStripColor: PureColor = { r: 163, g: 163, b: 163, a: 1 };
// #f1f1f1
export const NavigationCheckedBackColor: PureColor = { r: 241, g: 241, b: 241, a: 1 };

// #323e52
export const MapSingleColor: PureColor = { r: 40, g: 49, b: 66, a: 1 };
