import * as React from 'react';

import * as _ from 'lodash';
import classnames from 'classnames';

import { transBlankChart } from '@utils/textUtils';
import { min } from '@utils/globalUtils';
import { parseColorToString } from '@utils/graphicsUtils';

import { IconValue, IPathValue } from '@fbs/rp/models/value';
import { IPosition, ISize } from '@fbs/common/models/common';

import { PropertyStructureAlign } from '@/libs/enum';
import { DefaultIconColor } from '@consts/colors';
import { StyleHelper } from '@helpers/styleHelper';
import ISVGStroke = StyleHelper.ISVGStroke;
import { UIContainerComponent } from '@editor/comps';

import { scalePath, transformPathDataToPath } from '@helpers/pathHelper';
import { mergeProperties } from '@/helpers/propertiesHelper';

import { IComponentProps } from '../../types';

import './index.scss';

interface IState {
  checked: boolean;
  text: string;
  icon: string;
  textStyle: React.CSSProperties;
  checkViewerStyle?: {
    style: React.CSSProperties;
    stroke: ISVGStroke;
  };
  checkIconStyle?: {
    style: React.CSSProperties;
    text: string;
  };
  checkBounds: IPosition & ISize;
  boxStyle: React.CSSProperties;
  opacity: number;
}

function parserState(props: IComponentProps): IState {
  const { comp } = props;
  const group = comp as UIContainerComponent;
  const { opacity } = group;
  const checker = (group.getComponentByAlias('icon') || group.components[0]) as UIContainerComponent;
  const checkerIcon = checker.getComponentByAlias('selectIcon') || checker.components[0];
  const text = group.getComponentByAlias('text') || group.components[1];

  const { properties: boxProperties, size: boxSize } = checker!;
  const {
    type: checkerType,
    properties: iconProperties,
    size: iconSize,
    value: iconValue,
    position: iconPosition,
    hidden,
  } = checkerIcon!;

  const boxStyleParser = StyleHelper.createCSSStyleParser(boxProperties);

  const checkerStyleParser = StyleHelper.initSVGStyleParser(iconProperties);
  const d = checkerType === 'path' ? transformPathDataToPath(scalePath(iconValue as IPathValue, { x: 1, y: 1 })) : '';

  let checkIconStyle = undefined;
  if (checkerType === 'icon') {
    checkIconStyle = {
      style: {
        fontFamily: (iconValue as IconValue).fontName,
        fontSize: min(iconSize.width, iconSize.height),
        lineHeight: `${iconSize.height}px`,
        color: parseColorToString(iconProperties.icon?.color || DefaultIconColor),
        transform: '',
        transformOrigin: '',
      },
      text: String.fromCharCode((iconValue as IconValue).iconCode),
    };
    if (iconSize.height < 12) {
      checkIconStyle.style.transformOrigin = '0 0';
      checkIconStyle.style.transform = `scale(${iconSize.height / 12})`;
    }
  }
  let textStyle = {};
  let label = '';
  if (text) {
    const { value, properties: textProps, size: textSize, position: textPosition, opacity } = text;
    const textStyleParser = StyleHelper.createCSSStyleParser(textProps);
    const { size, style } = textStyleParser.getTextStyleData(textSize, textProps.textStyle);
    label = value as string;
    textStyle = {
      left: textPosition.x,
      top: textPosition.y,
      ...size,
      ...style,
      opacity: StyleHelper.getOpacity(opacity),
    };
  }
  return {
    opacity: StyleHelper.getOpacity(opacity),
    checked: group.selected || !hidden,
    text: transBlankChart(`${label}`),
    icon: d,
    textStyle,
    checkBounds: {
      ...iconPosition,
      ...iconSize,
    },
    checkViewerStyle:
      checkerType === 'path'
        ? {
            stroke: checkerStyleParser.getStroke(),
            style: {
              opacity: StyleHelper.getOpacity(checkerIcon?.opacity),
            },
          }
        : undefined,
    checkIconStyle,
    boxStyle: {
      ...boxSize,
      ...boxStyleParser.getRadiusStyle(boxSize),
      ...boxStyleParser.getStrokeStyle(),
      ...boxStyleParser.getFillStyle(boxSize),
      ...boxStyleParser.getShadowStyle(),
      opacity: StyleHelper.getOpacity(checker.opacity),
    },
  };
}

const CheckBox: React.FC<IComponentProps> = (props) => {
  const {
    checked,
    text,
    textStyle,
    checkViewerStyle,
    checkIconStyle,
    checkBounds: { x, y, width, height },
    boxStyle,
    icon,
    opacity,
  } = _.memoize(parserState)(props);

  const { comp } = props;
  const compData = comp.toJSON();
  const compProperties = mergeProperties(comp.properties, compData.properties);
  if (compProperties.structure?.value === PropertyStructureAlign.TextLeft) {
    textStyle.left = x;
    textStyle.right = undefined;
    boxStyle.left = undefined;
    boxStyle.right = 0;
  }

  return (
    <div
      className={classnames('lib-comp-checkbox', { 'component-cursor-pointer': props.isPreview })}
      style={{ opacity }}
    >
      <div className="lib-comp-checker-box" style={boxStyle}>
        {checked && checkViewerStyle && (
          <svg
            className="lib-comp-checker-icon"
            style={{
              width,
              height,
              ...checkViewerStyle.style,
            }}
          >
            <path {...checkViewerStyle.stroke} d={icon} fill="none" />
          </svg>
        )}
        {checked && checkIconStyle && (
          <label
            className="lib-comp-checker-icon"
            style={{
              left: x,
              top: y,
              width,
              height,
              ...checkIconStyle.style,
            }}
          >
            {checkIconStyle.text}
          </label>
        )}
      </div>
      {text && (
        <label className="lib-comp-checker-text" style={textStyle}>
          {text}
        </label>
      )}
    </div>
  );
};

export default CheckBox;
