import i18n from '@i18n';

import { SpriteThumb } from '@consts/spriteIcons';
import { BlackColor, DefaultWhiteFillColor } from '@consts/colors';

import { makeCommonComponent } from '../../helper';
import { IComponentItem } from '../../types';
import { CQRCode } from '../../constants';
import { SizeMode } from '../../enum';

export const QRCodeConfig: IComponentItem = {
  type: CQRCode,
  thumb: {
    spriteIconClass: SpriteThumb.QRCode.className,
    dragPosition: SpriteThumb.QRCode.position,
  },
  name: i18n('resource.components.qrcode'),
  sizeMode: SizeMode.ratio,
  getDefaultData() {
    return {
      properties: {
        background: {
          prop: 'color',
          name: i18n('property.propertyNames.bgcolor'),
          value: DefaultWhiteFillColor,
        },
        forgetColor: {
          prop: 'color',
          name: i18n('property.propertyNames.forgetColor'),
          value: BlackColor,
        },
      },
    };
  },
};

export function makeQRCode(id: string) {
  return makeCommonComponent(id, CQRCode, {
    ...QRCodeConfig.getDefaultData?.(),
    size: { width: 100, height: 100, lockedRatio: true },
    value: i18n('resource.componentsText.qrCode'),
  });
}
