import * as React from 'react';
import classnames from 'classnames';

import Icon from '../Icon';
import Link from '../Link';

import './index.scss';

export interface ICheckboxProps {
  darkMode?: boolean;
  id?: number | string;
  disabled?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  text?: string;
  icon?: string;
  iconLink?: string;
  desc?: string;
  autoLinefeed?: boolean; //自动换行
  width?: number | string;
  maxWidth?: number | string;
  fontSize?: number;
  onChange?(value: boolean): void;
}

class Checkbox extends React.Component<ICheckboxProps, {}> {
  static defaultProps: Partial<ICheckboxProps> = {
    darkMode: false,
    checked: false,
    indeterminate: false,
    text: '',
    autoLinefeed: false,
    width: 'auto',
    maxWidth: 200,
    fontSize: 13,
  };

  constructor(props: ICheckboxProps) {
    super(props);
  }

  check = (e: React.MouseEvent) => {
    const newState = !this.props.checked;
    if (this.props.disabled) {
      return;
    }
    if (this.props.onChange) {
      this.props.onChange(newState);
      e.stopPropagation();
    }
  };

  render() {
    const {
      disabled,
      checked,
      indeterminate,
      text,
      icon,
      iconLink,
      desc,
      autoLinefeed,
      width,
      maxWidth,
      fontSize,
    } = this.props;
    return (
      <div
        className={classnames('dsm-c-checkbox', {
          'disabled-checkbox': disabled,
          'dsm-c-checkbox-dark': this.props.darkMode,
          'dsm-c-checkbox-auto-line': autoLinefeed,
        })}
        style={{
          width,
          maxWidth: autoLinefeed ? maxWidth : 'max-content',
        }}
        onClick={this.check}
        onDoubleClick={(e) => {
          e.stopPropagation();
        }}
      >
        <span
          className={classnames('checkbox-viewer', {
            'checkbox-checked': checked && !indeterminate,
            'checkbox-checked-indeterminate': checked && indeterminate,
          })}
        >
          <span className="checkbox-viewer-inner"></span>
        </span>
        {autoLinefeed ? (
          <span
            className="checkbox-text"
            style={{
              maxWidth: icon ? `calc(${maxWidth} - 45px)` : `calc(${maxWidth} - 23px)`,
              fontSize,
            }}
          >
            {text}
          </span>
        ) : (
          <span
            className="checkbox-text"
            style={{
              maxWidth: icon ? 'calc(100% - 45px)' : 'calc(100% - 23px)',
              fontSize,
            }}
          >
            {text}
          </span>
        )}
        {icon && (
          <Link href={iconLink} target="target">
            <Icon cls={icon} />
          </Link>
        )}
        {desc && <div className="dsc">{desc}</div>}
      </div>
    );
  }
}

export default Checkbox;
