/* eslint-disable react/prop-types */
import * as React from 'react';
import classnames from 'classnames';
import { UIComponent } from '@/editor/comps';
import RefactorRemarkManager from '@/managers/refactorRemarkManager';

import { dragDelegate } from '@utils/mouseUtils';
import { IRemark } from '@/fbs/rp/models/component';
import CoreEditor from '@/editor/core';

interface IRemarkOrderNumProps {
  comp: UIComponent;
  editor?: CoreEditor;
  selectedTag?: string;
  instance?: RefactorRemarkManager;
  onClick?: React.DOMAttributes<HTMLSpanElement>['onClick'];
  onMouseDown?: React.DOMAttributes<HTMLSpanElement>['onMouseDown'];
  showPreviewRemarkPopupId?: string;
}

export default React.forwardRef<HTMLSpanElement, IRemarkOrderNumProps>(function RemarkOrderNum(props, ref) {
  const { instance, comp, selectedTag, onClick, onMouseDown, editor, showPreviewRemarkPopupId } = props;
  if (!instance || !comp.remark) {
    return null;
  }

  const [, setUpdateTimestamp] = React.useState(Date.now());
  const isSelected = false; // editor?.selectedComponents.has(comp); 屏蔽该策略

  React.useEffect(() => {
    const update = () => {
      setUpdateTimestamp(Date.now());
    };
    instance.subscribe(update);

    return () => {
      instance.unsubscribe(update);
    };
  }, []);

  // 依赖的是instance.compsWithRemark,它的引用对象会一直存在，无法避免循环调用
  const index = instance.compsWithRemark.findIndex((item) => item.realID === comp.realID);
  // 位置属性、TODO: 缓存依赖comp.version
  const positionInfo = React.useMemo(() => {
    // 当前水平垂直坐标
    const point = ((position?: { x: number; y: number }) => {
      if (!position) {
        return {
          x: -17,
          y: 0,
        };
      }
      return {
        x: position.x - comp.size.width > 0 ? comp.size.width + 1 : position.x,
        y: position.y - comp.size.height > 0 ? comp.size.height + 1 : position.y,
      };
    })(comp.remark!.position);
    comp.remark!.position = point;
    return {
      maxX: comp.size.width + 1,
      minX: -17,
      minY: -17,
      maxY: comp.size.height + 1,
      ...point,
      mx: 0,
      my: 0,
      moving: false,
    };
  }, [comp.version]);
  const handleMouseDown = React.useCallback(
    function (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
      onMouseDown && onMouseDown(e);
      if (isSelected) {
        return false;
      }
      if (!comp.remark) {
        return false;
      }
      if (comp.isPreview) {
        return false;
      }
      const current = (ref as React.RefObject<HTMLSpanElement>).current!;
      const rate = current.getBoundingClientRect().width / current.clientWidth; // 缩放率
      dragDelegate(
        function (e, delta) {
          positionInfo.moving = true;
          positionInfo.mx = positionInfo.x - delta.x / rate;
          positionInfo.my = positionInfo.y + delta.y / rate;
          if (positionInfo.mx < positionInfo.minX) {
            positionInfo.mx = positionInfo.minX;
          }
          if (positionInfo.mx > positionInfo.maxX) {
            positionInfo.mx = positionInfo.maxX;
          }
          if (positionInfo.my < positionInfo.minY) {
            positionInfo.my = positionInfo.minY;
          }
          if (positionInfo.my > positionInfo.maxY) {
            positionInfo.my = positionInfo.maxY;
          }
          // 使用dom设计样式， 不使用react状态去渲染
          current.style.cssText = `right:${positionInfo.mx}px;top:${positionInfo.my}px`;
        },
        function () {
          if (!positionInfo.moving) {
            return false;
          }
          const remark: IRemark = {
            ...comp.remark!,
            position: {
              x: positionInfo.mx,
              y: positionInfo.my,
            },
          };
          positionInfo.moving = false;
          positionInfo.x = positionInfo.mx;
          positionInfo.y = positionInfo.my;
          editor?.setRemark(remark, comp);
        },
      );
    },
    [comp.version, isSelected],
  );
  return index === -1 ? null : (
    <span
      ref={ref}
      style={{
        right: positionInfo.x + 'px',
        top: positionInfo.y + 'px',
      }}
      className={classnames([
        'remark-tag',
        {
          'no-preview': !comp.isPreview,
          'remark-popup-none': comp.isPreview && showPreviewRemarkPopupId === comp.id,
          'selected-tag': selectedTag === comp.id || RefactorRemarkManager.getRemarkFocus(comp.id),
        },
      ])}
      onClick={onClick}
      onMouseDown={handleMouseDown}
    >
      {index}
    </span>
  );
});
