import { IPosition } from '@fbs/common/models/common';
import { Ops, PageOperations } from '@fbs/rp/utils/patch';
import { IMoveCommandParams } from '@fbs/rp/models/interactions';
import { EventTypes } from '@fbs/rp/models/event';

import CommandBase from './CommandBase';

export default class MoveCommand extends CommandBase {
  public get afterEvent() {
    return EventTypes.afterMove;
  }

  cashOriginParams() {
    this.saveCacheOriginParams('position', this.command.target.position);
  }

  private getMoveTargetPosition = (revert: boolean) => {
    const {
      params,
      target: { position },
    } = this.command;
    const { x, y, fromCurrent } = params as IMoveCommandParams;

    if (fromCurrent) {
      return revert ? { x: position.x - x, y: position.y - y } : { x: position.x + x, y: position.y + y };
    }
    return revert ? this.getCacheOriginParams('position') : { x, y };
  };

  private initOperations = (position: IPosition): PageOperations => {
    const { target } = this.command;
    const { id, ownerArtboardID } = target;
    return {
      [ownerArtboardID]: {
        [id]: [Ops.replace(this.getCurrentPath('position'), position), this.initAnimationOperation()],
      },
    };
  };

  protected run = () => {
    this.patch(this.initOperations(this.getMoveTargetPosition(false)));
    this.fulfilled = true;
  };

  revert = () => {
    this.patch(this.initOperations(this.getMoveTargetPosition(true)));
    this.fulfilled = true;
  };
}
