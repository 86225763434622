import { PagePatches, reversePagePatches, Ops } from '@/fbs/rp/utils/patch';

import { updateConnectByDiff, removeContactBetweenStickNoteAndComp } from '@/helpers/pathFinderHelper';
import editorValidate from '@helpers/editorValidate';
import { mergePatches } from '@helpers/patchHelper';

import { CCompoundPath } from '@libs/constants';
import { makeGroup } from '@libs/containers/Group/index.tsx';

import { getViewBoundsOfComponents } from '../bounds';
import { UIConnectorComponent, UIComponent, UIContainerComponent } from '../comps';
import CoreEditor from '../core';
import { convertRemovedComponentsToAddOps } from '../corePartial/helper';
import { BaseCommand } from './Command';

interface ActionInfo {
  patch: PagePatches;
  active: {
    isFragment: boolean;
    container: string;
  };
  select: {
    from: string[];
    to: string[];
  };
}

export class GroupComponentsCommand extends BaseCommand {
  constructor(private editor: CoreEditor) {
    super();
  }

  actionInfo: ActionInfo | undefined;

  action() {
    const { editor } = this;

    if (editor.selectedComponents.size === 0) {
      return false;
    }
    if (editor.activeContainer.isSealed || !editorValidate.allowChildrenStructureChange(editor.activeContainer)) {
      return false;
    }
    // if (editor.hasSelectLockedComps) {
    //   return false;
    // }
    if (editor.activeContainer.type === CCompoundPath) {
      return false;
    }

    const noConnectLine: UIConnectorComponent[] = []; //删除孤立的线
    const comps = editor.activeContainer.components;
    const remainComponents = editor.activeContainer.components.filter((comp) => !editor.selectedComponents.has(comp));
    //成组的组件，过滤掉成组后会孤立的流程线
    const toGroupComponents = editor.selectedComponentList.filter((comp) => {
      return !(
        comp.isConnector &&
        (comp as UIConnectorComponent).isAloneLineAfterDelete(remainComponents.map((comp) => comp.id))
      );
    });

    if (toGroupComponents.length === 1) {
      return false;
    }

    //留下来的流程线是孤立的
    remainComponents
      .filter((comp) => comp.isConnector)
      .forEach((c) => {
        const comp = c as UIConnectorComponent;
        const startID = comp.getStartCompID();
        const endID = comp.getEndCompID();
        const startCompIsSelected = startID && toGroupComponents.some((sc) => sc.id === startID);
        const endCompIsSelected = endID && toGroupComponents.some((sc) => sc.id === endID);
        // 线条未选中，但是线条的起点在被选择的组件内，那么该线条也被一起编组
        if (startCompIsSelected) {
          toGroupComponents.push(c);
        }
        //线条未被选中，终点在被选择的组件内， 起点未有绑定的，则删除这个线条
        if (!startID && endCompIsSelected) {
          noConnectLine.push(comp);
        }
      });

    const newPosition = getViewBoundsOfComponents(toGroupComponents.filter((comp) => !comp.isConnector));
    if (!newPosition) {
      return false;
    }
    // 按原来在组中的顺序排列
    toGroupComponents.sort((a: UIComponent, b: UIComponent) => {
      const aIndex = comps.indexOf(a);
      const bIndex = comps.indexOf(b);
      return aIndex - bIndex;
    });

    const activeContainer = editor.activeContainer;
    const newComponent = makeGroup(
      newPosition,
      toGroupComponents.map((uiComp) => {
        if (uiComp.isConnector) {
          const comp = uiComp as UIConnectorComponent;
          return comp.resetConnectPointWhenGroup(
            newPosition,
            toGroupComponents.map((comp) => comp.id),
          );
        }
        uiComp.resetCenterAnchorWhenGroup(toGroupComponents);
        const data = uiComp.resetPositionWhenGroup(newPosition);
        data._currentState = undefined;
        return data;
      }),
    );
    // 合并后的组,以toGroupComponents，index最大的那个为参考，其下未选中的列表为A，用A的长度为组的index
    const maxIndexInToGroupComponents = toGroupComponents.reduce((acc, curr) => {
      return Math.max(acc, editor.activeContainer.components.indexOf(curr));
    }, -1);
    // 根据toGroupComp中index最大的，获得index比其小的，作为数组Ａ
    const underMaxIndexComps = editor.activeContainer.components.slice(0, maxIndexInToGroupComponents);
    // 将数组A中不需要合并成组的筛选出来，剩下的元素的个数即为我们需要的index
    const index = underMaxIndexComps.filter(
      (comp) => !toGroupComponents.find((toGroupComp) => toGroupComp.id === comp.id),
    ).length;
    const revertAdd = convertRemovedComponentsToAddOps(activeContainer, toGroupComponents);

    const revertNoConnectLine = convertRemovedComponentsToAddOps(activeContainer, noConnectLine);

    const connectPatches = updateConnectByDiff(
      editor.activeContainer,
      editor.selectedComponentList,
      {
        offsetX: 0 - newPosition.left,
        offsetY: 0 - newPosition.top,
      },
      true,
    );

    // 便签条组件连接终点组件被编入组内，移除连接关系
    const notePatches = removeContactBetweenStickNoteAndComp(editor.activeContainer, editor.selectedComponentList);
    notePatches && mergePatches(connectPatches, notePatches);

    const patches: PagePatches = {
      [editor.activeContainer.ownerArtboardID]: {
        do: Object.assign(
          {
            [activeContainer.id]: [
              Ops.removeChildren(toGroupComponents.map((comp) => comp.id)),
              Ops.removeChildren(noConnectLine.map((comp) => comp.id)),
              Ops.addChildren(`${index}`, [newComponent]),
            ],
          },
          connectPatches.do,
        ),
        undo: Object.assign(
          {
            [activeContainer.id]: [Ops.removeChildren([newComponent._id]), ...revertAdd, ...revertNoConnectLine],
          },
          connectPatches.undo,
        ),
      },
    };

    const prevSelectedIds = editor.selectedComponentList.map((comp) => comp.id);
    const nextSelectedIds = [newComponent._id];

    const actionInfo: ActionInfo = {
      patch: patches,
      active: {
        isFragment: activeContainer.isArtboard,
        container: activeContainer.realID,
      },
      select: {
        from: prevSelectedIds,
        to: nextSelectedIds,
      },
    };

    editor.batchSyncNoticeUpdate(() => {
      editor.new_update(patches);

      editor.selectByIDs(nextSelectedIds, false);
    });

    return actionInfo;
  }

  execute() {
    const actionInfo = this.action();
    if (!actionInfo) {
      return;
    }

    this.actionInfo = actionInfo;
    this.editor.pushUndoStack(this);
    this.editor.clearRedoStack();
  }

  undo() {
    if (!this.actionInfo) {
      return;
    }

    const {
      editor,
      actionInfo: { patch, select },
    } = this;

    editor.pushRedoStack(this);

    const undoPatches = reversePagePatches(patch);
    editor.new_update(undoPatches);

    editor.setActiveContainer(this.getContainerToActive());

    editor.selectByIDs(select.from, false);
  }

  redo() {
    if (!this.actionInfo) {
      return;
    }

    const {
      editor,
      actionInfo: { patch, select },
    } = this;

    editor.pushUndoStack(this);

    editor.new_update(patch);

    editor.setActiveContainer(this.getContainerToActive());

    editor.selectByIDs(select.to, false);
  }

  // TODO 去除 actionInfo 空处理
  getContainerToActive() {
    const { editor, actionInfo } = this;
    const { active } = actionInfo!;
    let container: UIContainerComponent;
    if (active.isFragment) {
      container = editor.doc.getArtboardByID(active.container)!;
    } else {
      container = editor.doc.getComponentsByFilter((comp) => comp.id === active.container)[0] as UIContainerComponent;
    }
    return container;
  }
}
