import IArtboard from '@fbs/rp/models/artboard';
import { IPosition } from '@fbs/common/models/common';

import { get, put } from '../helper';

export async function getArtboardByID(artboardID: string): Promise<IArtboard> {
  return await get<IArtboard>(`/artboard/${artboardID}?rnd=${new Date().getTime()}`);
}

export async function getArtboardsByPageID(nodeID: string, isExample: Boolean = false): Promise<IArtboard[]> {
  return await get<IArtboard[]>(`/artboard/all/${nodeID}?isExample=${isExample}&rnd=${new Date().getTime()}`);
}

export async function getArtboardsByMainID(
  mainArtboardID: string,
  moduleID: string = '',
  params: { [key: string]: string | undefined },
): Promise<IArtboard[]> {
  return await get<IArtboard[]>(
    `/artboard/all-by-main-artboard-id/${mainArtboardID}/${moduleID || ''}?rnd=${new Date().getTime()}`,
    { params },
  );
}

export async function getArtboardsByPageIDWithPreview(nodeID: string, prototypeID: string = ''): Promise<IArtboard[]> {
  return await get<IArtboard[]>(
    `/artboard/preview/all/${nodeID}?prototypeID=${prototypeID || ''}&rnd=${new Date().getTime()}`,
  );
}

export async function cloneArtboard(nodeID: string, artboard: IArtboard, position: IPosition): Promise<string> {
  return await put<string>(`/artboard/clone/${nodeID}`, {
    artboard: Object.assign({}, artboard, {
      position,
    }),
  });
}
