import { jsonClone } from '@utils/globalUtils';

import { ITableValue, ITableCell, ITableRow, ITableColumn } from '@/fbs/rp/models/table';
import { IProperties } from '@/fbs/rp/models/property';

import { getNewID } from '@/helpers/idHelper';
import { SpriteThumb } from '@consts/spriteIcons';
import { DefalutTableOuterStroke } from '@consts/defaultData/table';
import i18n from '@i18n';

import { DefalutTableValue, defaultCell, defaultCol, defaultRow, defaultCellTextProperties } from './value';
import { IComponentItem } from '../../types';
import { CTable, CText, CCanvasPanel, CTableCell } from '../../constants';
import { makeCommonComponent, getDefaultShadow } from '../../helper';

interface ICellValue {
  type: 'text';
  value: string;
}

const defaultData = {
  properties: {
    shadow: getDefaultShadow(),
    stroke: DefalutTableOuterStroke,
    tableLine: {
      rowLine: true,
      columnLine: true,
    },
  },
};

export function makeTable(id: string) {
  return makeCommonComponent(id, CTable, {
    ...defaultData,
    size: { width: 320, height: 120 },
    components: [],
    value: jsonClone(DefalutTableValue),
    sealed: true,
  });
}

export function makeTableContainer() {
  return makeCommonComponent(getNewID(), CCanvasPanel, {
    alias: 'TableCellContainer',
    row: Number.MAX_SAFE_INTEGER,
    column: Number.MAX_SAFE_INTEGER,
    components: [],
    sealed: true,
  });
}

export function makeTableCell(row: number, column: number) {
  return makeCommonComponent(getNewID(), CTableCell, {
    lib: {
      id: 'common',
      type: CTableCell,
    },
    row: row,
    column: column,
  });
}

export function makeAiTable(id: string, values?: Array<ICellValue[] | string[]>) {
  if (!values || !values.length) {
    return;
  }

  let totalWidth: number = 0;
  let totalHeight: number = 0;

  const cells: ITableCell[][] = [];
  const rows: ITableRow[] = [];
  const columns: ITableColumn[] = [];

  const components = [];
  const containerComponents = makeTableContainer();

  for (let row = 0; row < values.length; row++) {
    cells.push([]);
    rows.push(defaultRow);
    totalHeight += defaultRow.height;
    for (let col = 0; col < values[0].length; col++) {
      cells[row].push(defaultCell);
      components.push(
        makeCommonComponent(getNewID(), CText, {
          value: String(values[row][col]),
          row,
          column: col,
          size: {
            width: defaultCol.width,
            height: 22,
          },
          properties: defaultCellTextProperties as IProperties,
        }),
      );
      containerComponents.components?.push(makeTableCell(row, col));
    }
  }
  for (let col = 0; col < values[0].length; col++) {
    columns.push(defaultCol);
    totalWidth += defaultCol.width;
  }

  const tableValue: ITableValue = {
    showHeader: false,
    headerHeight: 30,
    columns,
    rows,
    cells,
  };

  const result = makeCommonComponent(id, CTable, {
    ...defaultData,
    size: { width: totalWidth, height: totalHeight },
    components: [],
    value: jsonClone(tableValue),
    sealed: true,
  });
  result.components?.push(containerComponents, ...components);
  return result;
}

export const TableConfig: IComponentItem = {
  type: CTable,
  name: i18n('resource.components.table'),
  thumb: {
    spriteIconClass: SpriteThumb.Table.className,
    dragPosition: SpriteThumb.Table.position,
  },
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
};
