import { ActionType, DialogType, ToastType, IDialogOptions } from '../types';
import { GlobalAction } from './actions';

import { ErrorCode } from '@fbs/idoc/consts/ErrorCode';

export interface IToast {
  type: ToastType;
  tip: string;
  options?: { autoClose?: boolean; delay?: number; iconCls?: string; iconColor?: string; rightIcon?: boolean };
  btnOptions?: {
    needCloseIcon?: boolean;
    needSupport?: boolean;
    submitBtn?: string;
    closeBtn?: string;
    onSubmit?: Function;
    onClose?: Function;
  };
}
export interface IGlobalState {
  toast?: IToast[];
  dialogQueue: React.ReactNode[];
  ioMessage: string;
  socketAvailable: boolean;
  networkAvailable: boolean;
  afk: boolean;
  waiting?: boolean;
  loadingTheme?: string; // loading主题
  isOfflineSync: boolean;
  forceLogout?: boolean;
  loginExpired?: boolean;
  networkErrorCode?: ErrorCode;
  loseProjectPermission?: boolean;
  unsubscribeTime?: number;
  showWeMeetLimitTipDialog?: boolean;
  dialogType?: DialogType;
  dialogOptions?: IDialogOptions;
  logOuted?: boolean; // 已退出登录
}

const initialState: IGlobalState = {
  toast: [],
  dialogQueue: [],
  ioMessage: '',
  socketAvailable: true,
  networkAvailable: true,
  afk: false,
  isOfflineSync: false,
  unsubscribeTime: 0,
  showWeMeetLimitTipDialog: false,
  dialogType: DialogType.none,
};

const maxToastCount = 4;

export default function (state = initialState, action: GlobalAction): IGlobalState {
  switch (action.type) {
    case ActionType.Global_Toast: {
      let hasSameType = false;
      const newToast =
        state.toast?.map((item) => {
          if (item.type === action.payload.type) {
            hasSameType = true;
            return action.payload;
          }
          return item;
        }) ?? [];
      if (!hasSameType && newToast.length < maxToastCount) {
        newToast?.push(action.payload);
      }
      return Object.assign({}, state, {
        toast: newToast,
      });
    }
    case ActionType.Global_Close_Toast: {
      const newToast = state.toast?.filter((item) => {
        return item.type !== action.payload;
      });
      return Object.assign({}, state, {
        toast: newToast,
      });
    }
    case ActionType.Global_IO_Message:
      return Object.assign({}, state, {
        ioMessage: action.payload,
      });
    case ActionType.Global_SOCKET_STATE_CHANGE:
      if (state.socketAvailable !== action.payload) {
        return Object.assign({}, state, {
          socketAvailable: action.payload,
        });
      }
      return state;
    case ActionType.Global_NETWORK_STATE_CHANGE:
      if (state.networkAvailable !== action.payload) {
        return Object.assign({}, state, {
          networkAvailable: action.payload,
        });
      }
      return state;
    case ActionType.Global_AFK:
      if (!state.afk) {
        return Object.assign({}, state, {
          afk: true,
        });
      }
      return state;
    case ActionType.Global_Waiting: {
      const { waiting, theme } = action.payload;
      return {
        ...state,
        waiting,
        loadingTheme: theme,
      };
    }
    case ActionType.Global_Offline_Sync:
      return {
        ...state,
        isOfflineSync: action.payload,
      };
    case ActionType.Global_Remote_Login:
      return {
        ...state,
        forceLogout: true,
      };
    case ActionType.Global_Login_Expired:
      return {
        ...state,
        loginExpired: true,
      };
    case ActionType.Global_ErrorCode:
      return {
        ...state,
        networkErrorCode: action.payload,
      };
    case ActionType.Global_Loss_Of_Project_Permission:
      return {
        ...state,
        loseProjectPermission: true,
      };
    case ActionType.Global_Log_Off_Team:
      return {
        ...state,
        unsubscribeTime: action.payload,
      };
    case ActionType.Global_Show_We_Meet_Limit_Tip_Dialog:
      return {
        ...state,
        showWeMeetLimitTipDialog: action.payload,
      };
    case ActionType.Global_Dialog:
      return {
        ...state,
        dialogType: action.payload.type,
        dialogOptions: action.payload.opts,
      };
    case ActionType.Global_Push_Dialog_To_Queue:
      return {
        ...state,
        dialogQueue: state.dialogQueue.concat(action.payload),
      };
    case ActionType.Global_Pop_Dialog_From_Queue:
      return {
        ...state,
        dialogQueue: state.dialogQueue.slice(0, -1),
      };
    case ActionType.Global_Login_Out:
      return {
        ...state,
        logOuted: action.payload,
      };
    default:
      return state;
  }
}
