/**
 * @description:
 * @author: koujing
 * @date: 2022-05-24 13:46
 **/
export { ShareType } from './txShare';

export { weMeetConf } from './weMeet';

export type { IJsTicketConfig } from './weMeet';

export { styleColor, styleSpace, styleFont } from './style';

export { errorCode } from './errorCode';
