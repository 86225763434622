import { EventTypes, CommandTypes, AnimateEffects, PageSkipEffects } from '@fbs/rp/models/event';
import { FragmentPositionMode, FragmentAnimationEffects } from '@/fbs/rp/models/interactions';

import i18n from '@i18n';

// 交互触发事件类型
export const EventNames: { [eventType: string]: string } = {
  [EventTypes.click]: i18n('property.interaction.click'),
  [EventTypes.doubleClick]: i18n('property.interaction.doubleClick'),
  [EventTypes.contextMenu]: i18n('property.interaction.contextMenu'),
  [EventTypes.mouseDown]: i18n('property.interaction.mouseDown'),
  [EventTypes.mouseUp]: i18n('property.interaction.mouseUp'),
  [EventTypes.mouseEnter]: i18n('property.interaction.mouseEnter'),
  [EventTypes.mouseLeave]: i18n('property.interaction.mouseLeave'),
  [EventTypes.loaded]: i18n('property.interaction.loaded'),
  [EventTypes.afterMove]: i18n('property.interaction.afterMove'),
  [EventTypes.afterZoom]: i18n('property.interaction.afterZoom'),
  [EventTypes.afterRotate]: i18n('property.interaction.afterRotate'),
  [EventTypes.afterVisible]: i18n('property.interaction.afterVisible'),
  [EventTypes.afterHide]: i18n('property.interaction.afterHide'),
  [EventTypes.afterResize]: i18n('property.interaction.afterResize'),
  [EventTypes.checked]: i18n('property.interaction.checked'),
  [EventTypes.unChecked]: i18n('property.interaction.unChecked'),
  [EventTypes.toggleCheck]: i18n('property.interaction.toggleCheck'),
  [EventTypes.focus]: i18n('property.interaction.focus'),
  [EventTypes.blur]: i18n('property.interaction.blur'),
  [EventTypes.scroll]: i18n('property.interaction.scroll'),
  [EventTypes.swipeLeft]: i18n('property.interaction.swipeLeft'),
  [EventTypes.swipeUp]: i18n('property.interaction.swipeUp'),
  [EventTypes.swipeRight]: i18n('property.interaction.swipeRight'),
  [EventTypes.swipeDown]: i18n('property.interaction.swipeDown'),
};

// 组件交互类型
export const CommandNames: { [cmdType: string]: string } = {
  [CommandTypes.move]: i18n('property.interaction.move'),
  [CommandTypes.resize]: i18n('property.interaction.resize'),
  [CommandTypes.rotation]: i18n('property.interaction.rotation'),
  [CommandTypes.toggleVisible]: i18n('property.interaction.toggleVisible'),
  [CommandTypes.zoom]: i18n('property.interaction.zoom'),
  [CommandTypes.toggleState]: i18n('property.interaction.toggleState'),
  [CommandTypes.switchContent]: i18n('property.interaction.switchContent'),
  [CommandTypes.scroll]: i18n('property.interaction.scroll'),
};

// 动画曲线
export const AnimateEffectNames: { [effect: string]: string } = {
  [AnimateEffects.none]: i18n('property.interaction.noTiming'),
  [AnimateEffects.linear]: i18n('property.interaction.linear'),
  [AnimateEffects.easeIn]: i18n('property.interaction.easeIn'),
  [AnimateEffects.easeOut]: i18n('property.interaction.easeOut'),
  [AnimateEffects.easeInOut]: i18n('property.interaction.easeInOut'),
};

// 动画曲线
export const OnlyLinearAnimateEffectNames: { [effect: string]: string } = {
  [AnimateEffects.none]: i18n('property.interaction.noTiming'),
  [AnimateEffects.linear]: i18n('property.interaction.linear'),
};

// 页面跳转动效
export const PageSkipEffectNames: { [effect: string]: string } = {
  [PageSkipEffects.none]: i18n('property.interaction.none'),
  [PageSkipEffects.pushToBottom]: i18n('property.interaction.pushToBottom'),
  [PageSkipEffects.pushToTop]: i18n('property.interaction.pushToTop'),
  [PageSkipEffects.pushToRight]: i18n('property.interaction.pushToRight'),
  [PageSkipEffects.pushToLeft]: i18n('property.interaction.pushToLeft'),
  [PageSkipEffects.slideToBottom]: i18n('property.interaction.slideToBottom'),
  [PageSkipEffects.slideToTop]: i18n('property.interaction.slideToTop'),
  [PageSkipEffects.slideToRight]: i18n('property.interaction.slideToRight'),
  [PageSkipEffects.slideToLeft]: i18n('property.interaction.slideToLeft'),
  [PageSkipEffects.fadeIn]: i18n('property.interaction.fadeIn'),
  [PageSkipEffects.zoomIn]: i18n('property.interaction.zoomIn'),
  [PageSkipEffects.flipInTop]: i18n('property.interaction.flipInTop'),
  [PageSkipEffects.flipInBottom]: i18n('property.interaction.flipInBottom'),
  [PageSkipEffects.flipInLeft]: i18n('property.interaction.flipInLeft'),
  [PageSkipEffects.flipInRight]: i18n('property.interaction.flipInRight'),
};

// 内容面板切换动效
export const ContentPanelEffectNames: { [effect: string]: string } = {
  [PageSkipEffects.none]: i18n('property.interaction.none'),
  [PageSkipEffects.pushToBottom]: i18n('property.interaction.pushToBottom'),
  [PageSkipEffects.pushToTop]: i18n('property.interaction.pushToTop'),
  [PageSkipEffects.pushToRight]: i18n('property.interaction.pushToRight'),
  [PageSkipEffects.pushToLeft]: i18n('property.interaction.pushToLeft'),
  [PageSkipEffects.slideToBottom]: i18n('property.interaction.slideToBottom'),
  [PageSkipEffects.slideToTop]: i18n('property.interaction.slideToTop'),
  [PageSkipEffects.slideToRight]: i18n('property.interaction.slideToRight'),
  [PageSkipEffects.slideToLeft]: i18n('property.interaction.slideToLeft'),
  [PageSkipEffects.fadeIn]: i18n('property.interaction.fadeIn'),
};

// 辅画板叠加动效
export const FragmentEffectNames: { [effect: string]: string } = {
  [FragmentAnimationEffects.none]: i18n('property.interaction.none'),
  [FragmentAnimationEffects.fadeIn]: i18n('property.interaction.fadeIn'),
  [FragmentAnimationEffects.zoomIn]: i18n('property.interaction.zoomIn'),
  [FragmentAnimationEffects.slideToBottom]: i18n('property.interaction.slideToBottom'),
  [FragmentAnimationEffects.slideToTop]: i18n('property.interaction.slideToTop'),
  [FragmentAnimationEffects.slideToRight]: i18n('property.interaction.slideToRight'),
  [FragmentAnimationEffects.slideToLeft]: i18n('property.interaction.slideToLeft'),
  [FragmentAnimationEffects.spreadIn]: i18n('property.interaction.spreadIn'),
};

// 辅画板退出动效
export const FragmentOutEffectNames: { [effect: string]: string } = {
  [FragmentAnimationEffects.none]: i18n('property.interaction.none'),
  [FragmentAnimationEffects.autoEffect]: i18n('property.interaction.autoEffect'),
  [FragmentAnimationEffects.fadeInRevert]: i18n('property.interaction.fadeInRevert'),
  [FragmentAnimationEffects.zoomInRevert]: i18n('property.interaction.zoomInRevert'),
  [FragmentAnimationEffects.slideToTopRevert]: i18n('property.interaction.slideToTopRevert'),
  [FragmentAnimationEffects.slideToBottomRevert]: i18n('property.interaction.slideToBottomRevert'),
  [FragmentAnimationEffects.slideToLeftRevert]: i18n('property.interaction.slideToLeftRevert'),
  [FragmentAnimationEffects.slideToRightRevert]: i18n('property.interaction.slideToRightRevert'),
  [FragmentAnimationEffects.spreadInRevert]: i18n('property.interaction.spreadInRevert'),
  // 以下部分归于mode === 'left'\'right'\'top'\'bottom'的默认效果
  // [PageSkipEffects.slideToBottom]: i18n('property.interaction.slideFromBottom'),
  // [PageSkipEffects.slideToTop]: i18n('property.interaction.slideFromTop'),
  // [PageSkipEffects.slideToRight]: i18n('property.interaction.slideFromRight'),
  // [PageSkipEffects.slideToLeft]: i18n('property.interaction.slideFromLeft'),
};

// 辅画板交互位置模式
export const FragmentPositionModes: { [mode: string]: string } = {
  [FragmentPositionMode.Custom]: i18n('property.interaction.custom'),
  [FragmentPositionMode.Center]: i18n('property.interaction.center'),
  [FragmentPositionMode.Top]: i18n('property.interaction.top'),
  [FragmentPositionMode.Right]: i18n('property.interaction.right'),
  [FragmentPositionMode.Bottom]: i18n('property.interaction.bottom'),
  [FragmentPositionMode.Left]: i18n('property.interaction.left'),
};
