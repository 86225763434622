import { copyStyle } from './copyStyle';
import { clearFontFacesCache } from './fontFaces';
import { makeImage } from '../image';
import { ICloneChildrenOptions, ICloneNodeOptions, ICopyStyleRuler, Replacer } from '../type';

export function cloneNode(options: ICloneNodeOptions): Promise<void | HTMLElement> {
  const { node, filter, isRoot, replacer, copyStyleRuler } = options;
  if (isRoot) {
    // 清除字体缓存
    clearFontFacesCache();
  }

  // 过滤器，只作用与子节点
  if (!isRoot && filter && !filter(node)) {
    return Promise.resolve();
  }

  return Promise.resolve(node)
    .then((node) => {
      return makeNodeCopy(node, replacer);
    })
    .then((clone) => {
      if (clone) {
        return cloneChildren({ node, clone, filter, replacer, copyStyleRuler });
      }
    })
    .then((clone) => {
      if (clone) {
        return processClone(node, clone as HTMLElement, copyStyleRuler, isRoot);
      }
    });
}

async function makeNodeCopy(node: Element, replacer?: Replacer): Promise<Node> {
  if (node instanceof HTMLCanvasElement) {
    return await makeImage(node.toDataURL());
  }

  if (checkUnpackingNode(node)) {
    // 复制节点包括子节点
    return node.cloneNode(true);
  }

  // 只复制当前节点
  const cloneNode = node.cloneNode(false);
  // 整理器
  const newNode = replacer ? replacer(cloneNode as HTMLElement, node) : cloneNode;
  return Promise.resolve(newNode);
}

//
// 不需要开箱的节点,直接复制，不做处理
export const checkUnpackingNode = (node: Node): boolean => {
  return node instanceof SVGElement || node instanceof Text; //|| node instanceof HTMLLabelElement ;
};

// 遍历节点树
function cloneChildren(options: ICloneChildrenOptions): Promise<Node> {
  const { node, clone, filter, replacer, copyStyleRuler } = options;
  if (checkUnpackingNode(clone)) {
    return Promise.resolve(clone);
  }
  const children: NodeListOf<ChildNode> = node.childNodes;
  if (children.length === 0) {
    return Promise.resolve(clone);
  }

  return cloneChildrenInOrder(clone, Array.from(children)).then(() => {
    return clone;
  });

  function cloneChildrenInOrder(parent: Node, children: Array<ChildNode>) {
    let done = Promise.resolve();
    children.forEach((child) => {
      done = done
        .then(() => {
          return cloneNode({
            node: child as Element,
            filter,
            isRoot: false,
            replacer,
            copyStyleRuler,
          });
        })
        .then((childClone) => {
          if (childClone) {
            try {
              parent.appendChild(childClone);
            } catch (e) {
              // console.log(e, parent, parent.hasChildNodes());
            }
          }
        });
    });
    return done;
  }
}

/**
 * isRoot=true 需要去掉位置信息
 * @param node
 * @param clone
 * @param copyStyleRuler
 * @param isRoot
 * @returns
 */
function processClone(
  node: Element,
  clone: HTMLElement,
  copyStyleRuler?: ICopyStyleRuler,
  isRoot?: boolean,
): Promise<HTMLElement> {
  if (checkUnpackingNode(clone)) {
    return Promise.resolve(clone);
  }

  return Promise.resolve()
    .then(() => {
      copyStyle(node, clone, copyStyleRuler, isRoot);
    })
    .then(() => {
      return clone;
    });
}
