import { IUserPreference } from '@fbs/rp/models/grid';

import { get, patch } from './helper';

export async function loadUserPreference(id: string): Promise<IUserPreference> {
  return await get<IUserPreference>(`/preference/${id}?rnd=${new Date().getTime()}`);
}

export async function patchUserPreference(id: string, data: Partial<IUserPreference>): Promise<IUserPreference> {
  return await patch<IUserPreference>(`/preference/${id}`, data);
}
// 获取用户团队/个人空间偏好
export function getUserPreference() {
  return get<{ preferences: string }>(`/user/userPreference`, {
    params: {
      source: 'rp',
    },
  });
}
// 设置用户团队/个人空间偏好
export function setUserDefaultTeamType(teamType: string) {
  return patch(`/user/userPreference/teamType/${teamType}`, {});
}
