import * as React from 'react';
import { isEqual, cloneDeep } from 'lodash';

import { Icon } from '@/dsm';

import i18n from '@i18n';

import { IComponentData, IComponentSize } from '@fbs/rp/models/component';
import { StrokeLineCap, StrokeLineJoin } from '@fbs/rp/models/properties/stroke';
import { ArtboardPatches, Ops } from '@/fbs/rp/utils/patch';
import { ISnapshotValue } from '@fbs/rp/models/value';

import * as SystemsColor from '@consts/colors';

import { StyleHelper } from '@helpers/styleHelper';
import { getImageSize } from '@/helpers/fileUploadHelper';

import { UIComponent } from '@editor/comps';
import snapshotManager from '@managers/snapshotManager';

import { makeCommonComponent, getVPCPrivateURL, getDefaultShadow } from '../../helper';
import { IComponentProps } from '../../types';
import { CSnapshot } from '../../constants';

import Preview from './Preview';

import './index.scss';

// 是否是在 RP 截图模式下的渲染，在这个模式下面，要将图片转为内网地址，加快访问速度
const isStandalonePreviewMode =
  window.location.href.indexOf('localhost') !== -1 && window.location.href.indexOf('/standalone-preview') !== -1;

export function updateSnapshotValue(comp: UIComponent, value: any): ArtboardPatches | null {
  const { id, properties } = comp;
  const url = (comp.value as ISnapshotValue).url;

  if (value && url !== value && properties.image) {
    const newImg = { ...properties.image, clipBounds: undefined };
    const path = comp.getCurrentPath('properties/image');
    return {
      do: {
        [id]: [Ops.replace(path, newImg)],
      },
      undo: {
        [id]: [Ops.replace(path, properties.image)],
      },
    };
  }
  return null;
}

interface ICanvasData {
  compWidth: number;
  compHeight: number;
  imageWidth: number;
  imageHeight: number;
  left: number;
  top: number;
}
interface ISnapshotState {
  stroke: StyleHelper.ISVGStroke;
  strokePathData: string;
  style: React.CSSProperties;
  showPreview: boolean;
  url: string;
  imageSize: {
    width: number;
    height: number;
  } | null;
  imageStyles: React.CSSProperties;
  imageBounds: ICanvasData | null;
  currValue: ISnapshotValue;
}

enum ImageStateEnum {
  None,
  Invalid,
  Normal,
}

interface IImageInfo {
  uri: string;
  width: number;
  height: number;
}

export function makeSnapshot(
  id: string,
  url: string = '',
  name: string = '',
  size: IComponentSize = {
    width: 300,
    height: 200,
  },
): IComponentData {
  const template: Partial<IComponentData> = {
    name,
    properties: {
      shadow: getDefaultShadow(),
      stroke: {
        disabled: true,
        thickness: 1,
        color: SystemsColor.DefaultStrokeColor,
        cap: StrokeLineCap.Round,
        join: StrokeLineJoin.Round,
      },
    },
    states: {},
    size,
    value: url,
  };
  return makeCommonComponent(id, CSnapshot, template);
}

class SnapshotComponent extends React.Component<IComponentProps, ISnapshotState> {
  private canvas: HTMLCanvasElement | null = null;
  private ctx: CanvasRenderingContext2D | null | undefined = null;
  private tmpImg: HTMLImageElement | null = null;
  private imageState: ImageStateEnum = ImageStateEnum.None;
  private canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();
  private previewImage: HTMLImageElement | null = null;
  private previewImageRef: React.RefObject<HTMLImageElement> = React.createRef();
  private imageChanged = false;
  private initdCropArea = {
    width: 0,
    height: 0,
    left: 0,
    top: 0,
  };

  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      style: this.doParsePropertiesToStyle(this.props),
      ...this.doParserBorder(this.props),
      showPreview: false,
      url: '',
      imageSize: null,
      imageStyles: {},
      imageBounds: null,
      currValue: cloneDeep(props.comp.value) as ISnapshotValue,
    };
  }

  componentDidMount() {
    this.doRefresh(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: IComponentProps) {
    const nextValue = cloneDeep(nextProps.comp.value as ISnapshotValue);

    if (!isEqual(this.state.currValue, nextValue)) {
      this.setState({
        currValue: nextValue,
      });
    }
    this.doRefresh(nextProps);
  }

  private doCreateCtx = () => {
    if (!this.canvas) {
      this.canvas = this.canvasRef.current;
      this.ctx = this.canvas?.getContext('2d');
    }
  };

  doRefresh = async (props: IComponentProps) => {
    const { imgState, url } = this.getUrlWithState(props);
    this.imageState = imgState;

    if (url) {
      try {
        const lastValue = this.state.currValue;
        const imageSize = await getImageSize(url);

        if (!isEqual(imageSize, this.state.imageSize) || !isEqual(lastValue, props.comp.value)) {
          this.setState(
            {
              url,
              imageSize,
              imageStyles: this.doCalcImgStyles(imageSize, url),
              imageBounds: this.doCalcCanvasImg(imageSize),
            },
            this.doDrawImage,
          );
        }
      } catch (e) {
        window.debug && console.log(e);
      }
    }
    this.doParserClipBounds(props);
  };

  getUrlWithState = (props: IComponentProps): { url: string; imgState: ImageStateEnum } => {
    let imgState = ImageStateEnum.Normal;
    const { url, source } = props.comp.value as ISnapshotValue;
    const urlInPage = snapshotManager.getURLByPageID(source?.pageID);

    this.imageChanged = !!urlInPage && urlInPage !== url;

    const realUrl = urlInPage ?? url;
    // 离线演示状态
    const isOfflineDemo = RP_CONFIGS.isOfflineDemo && props.comp.isPreview;

    if (!source?.pageID && !realUrl) {
      imgState = ImageStateEnum.None;
    }

    if (!isOfflineDemo) {
      if (source?.pageID && !urlInPage) {
        imgState = ImageStateEnum.Invalid;
      }
    } else {
      if (source?.pageID && !realUrl) {
        imgState = ImageStateEnum.Invalid;
      }
    }

    // if (window.debug) {
    //   return {
    //     url: 'https://img02.mockplus.cn/rp/image/2023-06-28/30038860-1557-11ee-b323-6d2e14dd776c.jpg',
    //     imgState: ImageStateEnum.Normal,
    //   };
    // }

    return {
      url: realUrl,
      imgState,
    };
  };

  private doParserClipBounds(props: IComponentProps) {
    const style = this.doParsePropertiesToStyle(props);
    const { stroke, strokePathData } = this.doParserBorder(props);
    if (
      isEqual(
        { style, stroke, strokePathData },
        {
          style: this.state.style,
          stroke: this.state.stroke,
          strokePathData: this.state.strokePathData,
        },
      )
    ) {
      return;
    }

    this.setState({
      style: this.doParsePropertiesToStyle(props),
      ...this.doParserBorder(props),
    });
  }

  doParsePropertiesToStyle = (props: IComponentProps): React.CSSProperties => {
    const { size, properties, opacity } = props.comp;
    const parser = StyleHelper.initCSSStyleParser(properties);
    return {
      ...(props.isPreview ? size : {}),
      ...parser.getRadiusStyle(size),
      ...parser.getStrokeStyle(),
      filter: StyleHelper.parserDropShadow(properties.shadow),
      overflow: 'hidden',
      opacity: StyleHelper.getOpacity(opacity),
    } as React.CSSProperties;
  };

  doParserBorder = (
    props: IComponentProps,
  ): {
    stroke: StyleHelper.ISVGStroke;
    strokePathData: string;
  } => {
    const { properties, size } = props.comp;
    const prop = { ...properties };
    if (!(props.comp.value as ISnapshotValue).url) {
      if (prop.stroke) {
        prop.stroke = { ...prop.stroke, thickness: 1, disabled: false };
      }
    }
    const thickness = prop.stroke?.thickness || 1;
    const svgParser = StyleHelper.initSVGStyleParser(prop);
    const {
      stroke,
      strokeDasharray,
      strokePathData,
      strokeWidth,
      strokeLinejoin,
      strokeLinecap,
      position,
    } = svgParser.getStrokeEx({ width: size.width - thickness, height: size.height - thickness });
    return {
      stroke: { stroke, strokeDasharray, strokeLinecap, strokeLinejoin, strokeWidth, position },
      strokePathData,
    };
  };

  handleSnapshotPreviewVisible = (e: React.MouseEvent | KeyboardEvent) => {
    if (this.imageState !== ImageStateEnum.Normal) {
      return;
    }
    e.stopPropagation();
    const { showPreview } = this.state;
    const {
      comp: { value },
    } = this.props;
    const snapValue = value as ISnapshotValue;
    if (!snapValue.url) {
      return;
    }
    this.setState({
      showPreview: !showPreview,
    });
  };

  renderPageInvalid = () => {
    return (
      <div className="snapshot-placeholder">
        <div>
          <p className="page-invalid">{i18n('resource.componentsText.snapshotInvalid')}</p>
          <p>{i18n('resource.componentsText.snapshotCanNotFound')}</p>
        </div>
        <Icon cls="icon_snapshot" theme="tag" />
      </div>
    );
  };

  renderEmptyImage = () => {
    return (
      <div className="snapshot-placeholder">
        <p>{i18n('resource.componentsText.snapshotText')}</p>
        <Icon cls="icon_snapshot" theme="tag" />
      </div>
    );
  };

  doCalcImgStyles = (imageSize: { width: number; height: number }, url: string) => {
    const { comp } = this.props;
    const { properties, value, size: resizingSize } = comp;
    const { cropAreaInfo } = value as ISnapshotValue;
    const { image } = properties;
    const { clipBounds } = image || {};
    const realAreaInfo = this.imageChanged ? this.initdCropArea : cropAreaInfo;

    let imgStyles: React.CSSProperties;

    if (clipBounds && clipBounds.width > 0 && clipBounds.height > 0) {
      imgStyles = {
        background: `url(${url})`,
      };
    } else {
      // 图片组件的图片重新上传之后需使用这个样式
      imgStyles = {
        backgroundImage: `url(${isStandalonePreviewMode ? getVPCPrivateURL(url!) : url})`,
      };
    }

    const { width: resizingWidth, height: resizingHeight } = resizingSize;
    const { width: imageWidth, height: imageHeight } = imageSize;

    if (realAreaInfo && Object.keys(realAreaInfo).length) {
      const { width: cropAreaWidth, height: cropAreaHeight, left: cropAreaLeft, top: cropAreaTop } = realAreaInfo!;
      const scaleX = resizingWidth / cropAreaWidth,
        scaleY = resizingHeight / cropAreaHeight;

      Object.assign(imgStyles, {
        width: `${cropAreaWidth}px`,
        height: `${cropAreaHeight}px`,
        backgroundSize: `${imageWidth}px ${imageHeight}px`,
        backgroundPosition: `${-cropAreaLeft}px ${-cropAreaTop}px`,
        transform: `scale(${scaleX}, ${scaleY})`,
      });
    } else {
      const scaleX = resizingWidth / imageWidth,
        scaleY = resizingHeight / imageHeight;

      Object.assign(imgStyles, {
        width: `${imageWidth}px`,
        height: `${imageHeight}px`,
        transform: `scale(${scaleX}, ${scaleY})`,
      });
    }

    return imgStyles;
  };

  renderImg = () => {
    const { imageStyles } = this.state;
    const { comp } = this.props;
    return (
      <div
        className="img-box"
        style={{
          transition: comp.getTransition(),
          ...imageStyles,
        }}
      />
    );
  };

  doCalcCanvasImg = (imageSize: { width: number; height: number }) => {
    const { comp } = this.props;
    const { value } = comp;
    const { cropAreaInfo } = value as ISnapshotValue;
    const { width: imageWidth, height: imageHeight } = imageSize;
    const realAreaInfo = this.imageChanged ? this.initdCropArea : cropAreaInfo;

    return {
      imageWidth,
      imageHeight,
      compWidth: realAreaInfo?.width || imageWidth,
      compHeight: realAreaInfo?.height || imageHeight,
      left: -(realAreaInfo?.left || 0),
      top: -(realAreaInfo?.top || 0),
    };
  };

  doDrawImage = () => {
    const { url, imageBounds } = this.state;
    if (!imageBounds) {
      return;
    }
    const { imageWidth, imageHeight, compWidth, compHeight, left, top } = imageBounds;
    this.doCreateCtx();
    if (!this.ctx || !this.canvas || !imageBounds) {
      return;
    }
    this.canvas.width = compWidth;
    this.canvas.height = compHeight;
    this.tmpImg = new Image(compWidth, compHeight);
    this.tmpImg.src = url;
    this.tmpImg.onload = () => {
      window.requestAnimationFrame(() => {
        this.ctx?.drawImage(this.tmpImg!, left, top, imageWidth, imageHeight);
      });
    };
  };

  renderCanvasImg = () => {
    return <canvas className="img-box" ref={this.canvasRef}></canvas>;
  };

  renderNotEmpty = () => {
    const pageInvalid = this.imageState === ImageStateEnum.Invalid;

    return pageInvalid ? this.renderPageInvalid() : this.renderCanvasImg();
  };

  render() {
    const { comp, isPreview } = this.props;
    const { cropAreaInfo } = comp.value as ISnapshotValue;
    const { imageStyles, showPreview, url } = this.state;
    const realAreaInfo = this.imageChanged ? this.initdCropArea : cropAreaInfo;
    const hasCropped =
      realAreaInfo && !!Object.keys(realAreaInfo).length && realAreaInfo.width !== 0 && realAreaInfo.height !== 0;
    const isEmptyImage = this.imageState === ImageStateEnum.None;
    return (
      <div
        style={{ ...this.state.style, transition: comp.getTransition() }}
        className="lib-comp-snapshot"
        onDoubleClick={isPreview ? this.handleSnapshotPreviewVisible : undefined}
      >
        {isEmptyImage ? this.renderEmptyImage() : this.renderNotEmpty()}
        {showPreview && (
          <Preview
            url={url}
            cropAreaInfo={realAreaInfo}
            hasCropped={hasCropped}
            imageStyles={imageStyles}
            onClosePreview={this.handleSnapshotPreviewVisible}
          />
        )}
      </div>
    );
  }
}

export default SnapshotComponent;
