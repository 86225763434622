import * as React from 'react';
import { isEqual } from 'lodash';
import classnames from 'classnames';

import { isIframe, isMacOS, isMockRPD, isShareFrame } from '@utils/envUtils';
import { parseUrlSearch } from '@utils/urlUtils';
import MathUtils from '@utils/mathUtils';
import { loadFromCache } from '@utils/cacheUtils';
import { formatString } from '@/utils/globalUtils';

import { Button, Dialog, Icon, IListItem, ScrollBars } from '@dsm';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';
import { WaterMark } from '@dsm2';
import { setTxConfig } from '@wemeet/src/helper/weMeet';

import { IAppWithNestedChildren, IPreviewAppInfo } from '@fbs/rp/models/app';
import { PreviewUrlSearchKey } from '@/fbs/rp/utils/preview';
import INode, { INodeWithChildren } from '@fbs/rp/models/node';
import { RpPreviewOption } from '@fbs/idoc/models/preview';
import { isElectron } from '@/helpers/desktopHelper';
import AppOptions from '@helpers/appOptions';
import {
  getDefaultOption,
  getPreviewOptFromUrl,
  getUrlSearchObj,
  IPreviewOption,
  IShareOption,
  isMobileAppType,
  isMobileDevice,
  shareOptionToSearchStr,
  updatePreviewPanelCatch,
  isWatchAppType,
  isVehicleAppType,
} from '@helpers/previewHelper';
import { LARK_TITLE_HEIGHT, needShowLarkRefreshTitle } from '@helpers/larkHelper';
import { IUserInfo } from '@/fbs/idoc/models/user';
import { listenerShortCut, unListenerShortCut } from '@helpers/shortCutHelper';
import { isFullscreen } from '@helpers/fullscreenHelper';
import appOptions from '@helpers/appOptions';
import i18n from '@i18n';
import { desktopServer, TITLE_HEIGHT } from '@/services/desktop';
import { clearLocalStorageItem } from '@/fbs/common/utils/localStorage';
import { ControlPanelState } from '@/fbs/rp/models/preview';
import { IGlobalState } from '@/store/global/reducers';
import { getOfflineDemoData } from '@/apis/offlineDemo/data';
import { UIComponent, UIFragment } from '@/editor/comps';
import { IScrollCommandParams } from '@/fbs/rp/models/interactions';
import { LOGIN_URL } from '@/consts/link';
import { Role, ITeams } from '@/fbs/teamManagement';
import { IPopularize } from '@/fbs/teamManagement/utils/popularize';
import RefactorRemarkManager from '@managers/refactorRemarkManager';

import { IPreviewDispatcher } from '@dispatchers/previewDispatcher';
import { IAppMembersInfo } from '@/fbs/idoc/models/app';

import Theater from '../../../theater';
import ControllerPanel from './ControllerPanel';
import ViewPort from './ViewPort';
import Loading from '../../Common/Loading';
import DesktopContainer from '../../DesktopContainer';
import RemarkGroup from '../../Common/Remark/RemarkGroup';
import ToastMessage from '../../Common/ToastMessage';
import ToastEscMessage from '../../Common/DesktopESC';
import GlobalToast from '../../Common/GlobalToast';

import NavigationPanel from './NavigationPanel';
import Transition from './Transition';

import * as noRemarkImg from '@assets/image/no-remark.png';

import './index.scss';

export interface IWebIndexProp {
  global: IGlobalState;
  appID: string;
  pageID: string;
  realAppID: string;
  app: IAppWithNestedChildren;
  currentPage?: Theater;
  nextPage?: Theater;
  message: string;
  showMsg: boolean;
  msgId: number;
  msg: string;
  userInfo?: IUserInfo;
  role?: Role;
  isStandalone: boolean;
  isOfflineDemo: boolean;
  waterMark?: string;
  popularize?: IPopularize;
  teamInfo?: ITeams;
  previewDispatcher: IPreviewDispatcher;
  membersFromApp?: IAppMembersInfo[];
  onPageSelect: (node: INode) => void;
  onMsgPopup: (msg: string, time?: number | undefined) => void;
  onFullScreenMsgPopup: (msg: string, time: number, id: number) => void;
  onScrollToComp: (target: string, params: IScrollCommandParams, duration: number, isUsedByRemark: boolean) => void;
  onDidMount: () => void;
  onSearchParams: (value: string) => void;
}

export interface IWebIndexState {
  scale: number;
  zoomByWheel?: boolean;
  option: IPreviewOption;
  controlPanelState: ControlPanelState;
  remarkPanelState: ControlPanelState;
  isFullscreen: boolean;
  theme: 'dark' | 'light';
  allComponents: UIComponent[];
  remarkTagId?: string;
  isInCC?: boolean;
}

class WebIndex extends React.Component<IWebIndexProp, IWebIndexState> {
  static defaultProps: Partial<IWebIndexProp> = {};
  private viewPortDom: React.RefObject<ViewPort> = React.createRef();
  private leftPanel: React.RefObject<ControllerPanel> = React.createRef();
  private remarkManagerInstance: RefactorRemarkManager | undefined;
  private tempPageId: string | undefined;
  private msgId: number;
  selfRef: React.RefObject<HTMLDivElement>;

  constructor(props: IWebIndexProp) {
    super(props);

    this.remarkManagerInstance = RefactorRemarkManager.getInstance(props.currentPage?.doc);
    this.selfRef = React.createRef();
    this.msgId = 0;
    const defaultControlPanelState = this.controlPanelState;
    const defaultRemarkPanelState = this.remarkPanelState;
    const defaultOption = getDefaultOption(props.app);
    const defaultScale = defaultOption.scale || 100;
    this.state = {
      controlPanelState: defaultControlPanelState,
      remarkPanelState: defaultRemarkPanelState,
      scale: defaultScale,
      option: defaultOption,
      isFullscreen: isFullscreen,
      theme: loadFromCache(`mp_specs_appearance_${props.userInfo?.id || -1}`, 'dark'),
      allComponents: [],
      isInCC: false,
    };
    window.pageScale = defaultScale / 100;
  }

  componentDidMount() {
    // 避免第一次打开分享弹窗时使用存储的 shareOptions，
    // 第一次要和当前演示页的设置一样。
    clearLocalStorageItem('shareOptions');
    desktopServer.readyToShow();

    this.initCurUrlSearchStr();

    const ignoreKey = ['ctrl+f', 'ctrl+r'];

    listenerShortCut(ignoreKey);
    window.addEventListener('message', this.messageListener);
    window.addEventListener('resize', this.handleWindowResize);
    document.addEventListener('fullscreenchange', this.handleWindowFullscreenChange);
    this.props.onDidMount();
    // 腾讯会议鉴权
    setTxConfig(this.props.userInfo?.token ?? '');

    // 处理cc嵌套axure项目中再次嵌套rp演示界面导航条隐藏问题，发送至cc项目，cc通过postMessage将信息再次返还
    this.postMessageToCC();
  }

  componentWillUnmount() {
    unListenerShortCut();
    window.removeEventListener('message', this.messageListener);
    document.removeEventListener('fullscreenchange', this.handleWindowFullscreenChange);
    window.removeEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate() {
    this.remarkManagerInstance = RefactorRemarkManager.getInstance(this.props.currentPage?.doc);
    this.updateCurUrlSearchStr();
  }

  UNSAFE_componentWillReceiveProps(nextProps: IWebIndexProp) {
    if (nextProps.currentPage && this.tempPageId !== nextProps.currentPage.pageID) {
      this.tempPageId = nextProps.currentPage.pageID;
      this.remarkManagerInstance = RefactorRemarkManager.getInstance(this.props.currentPage?.doc);
    }
  }

  // 处理演示界面嵌套至CC的情况，无法预知rp与cc之间嵌套层级，递归向上级界面发送信息，上级的界面存在cc,cc返回信息
  postMessageToCC = () => {
    let currentWin = window as Window;
    // 当window === window.top时，表示当前已经是最顶层
    while (currentWin !== window.top) {
      currentWin = currentWin.parent;
      currentWin.postMessage('IS_IN_CC_SEND', '*');
    }
  };

  handleShowRightPanel = (remarkPanelState: ControlPanelState, e: React.MouseEvent) => {
    this.setState(
      {
        remarkPanelState,
      },
      () => {
        this.updateCurUrlSearchStr();
        updatePreviewPanelCatch(this.props.appID, { remarkPanelState });
      },
    );
    this.doTriggerCollapseChanging(e.timeStamp);
  };

  handleScrollToComp = (id: string) => {
    const { onScrollToComp } = this.props;
    onScrollToComp &&
      onScrollToComp(
        id,
        {
          horizontal: true,
          vertical: true,
        },
        200,
        true,
      );
  };

  private handleWindowFullscreenChange = () => {
    this.setState({
      isFullscreen: !!document.fullscreenElement,
    });
    if (isElectron()) {
      if (document.fullscreenElement) {
        this.props.onFullScreenMsgPopup(i18n('tips.useEscExit', i18n('application.fullScreen')), 4000, ++this.msgId);
      } else {
        this.props.onFullScreenMsgPopup('', -1, -1);
      }
    }
  };

  private handleDesktopFullscreenChange = (isFull: boolean) => {
    this.setState({
      isFullscreen: isFull,
    });

    if (isElectron()) {
      if (isFull) {
        this.props.onFullScreenMsgPopup(i18n('tips.useEscExit', i18n('application.fullScreen')), 4000, ++this.msgId);
      } else {
        this.props.onFullScreenMsgPopup('', -1, -1);
      }
      console.log('handleDesktopFullscreenChange this.msgId:' + this.msgId);
    }
  };

  private handleFullScreen = (isFullscreen: boolean) => {
    this.setState({
      isFullscreen,
    });
  };

  messageListener = (e: MessageEvent) => {
    const {
      data: { event, payload },
    } = e;
    if (e.data === 'IS_IN_CC_RETURNED') {
      this.setState({ isInCC: true });
    }
    if (this.props.isStandalone) {
      switch (event) {
        case RpPreviewOption.PAGE_OPTION: {
          const newOption = getPreviewOptFromUrl(payload);
          this.handleOptionChange(Object.assign({}, this.state.option, newOption));
          break;
        }
        case RpPreviewOption.SCALE_CHANGED: {
          const scale = MathUtils.value(payload.scale, 100);
          // 在快照预览时禁用缩放
          if (scale !== this.state.scale && !document.querySelector('.snapshot-preview-mask')) {
            this.setScale(scale);
          }
          break;
        }
        case RpPreviewOption.PAGE_CHANGED: {
          const pageID = payload.pageID;
          const { app, onPageSelect } = this.props;
          const pages: INode[] = [];
          const doFlat = (nodes: INode[]) => {
            pages.push(...nodes);
            nodes.forEach((node) => {
              if (node.type === 'folder' || node.type === 'page') {
                doFlat((node as INodeWithChildren).children);
              }
            });
          };
          doFlat(app.children);
          const targetNode = pages.find((item) => item._id === pageID);
          if (targetNode) {
            onPageSelect(targetNode);
          }
          break;
        }
        case 'SPEC_APPEARANCE_CHANGED': {
          const { theme } = payload as { theme: 'dark' | 'light' };
          this.setState({ theme });
          break;
        }
      }
    }
  };

  private initCurUrlSearchStr = () => {
    const currentSearch = location.search;
    // 第一次加载时应该以 url 中的信息为准
    if (!currentSearch) {
      const newSearchStr = shareOptionToSearchStr(this.currentShareOpt);
      this.props.onSearchParams(newSearchStr);
    }
  };

  private updateCurUrlSearchStr = () => {
    const currentSearch = location.search;
    const newSearchStr = shareOptionToSearchStr(this.currentShareOpt);
    if (!currentSearch || !isEqual(parseUrlSearch(currentSearch), parseUrlSearch(newSearchStr))) {
      this.props.onSearchParams(newSearchStr);
    }
  };

  // showNavigationBar 这个设置项不会在演示页被修改，链接里是什么就是什么
  get showNavigationBar() {
    if (RP_CONFIGS.isOfflineDemo) {
      return getOfflineDemoData()?.config.showNavigationBar ?? true;
    }
    const urlSearchObj = getUrlSearchObj();
    const searchValue = urlSearchObj[PreviewUrlSearchKey.ShowNavigationBar] as boolean | undefined;
    return searchValue ?? true;
  }

  /**
   * 将老的演示链接中 ps 参数值转换为 cps 值，例如：'ps=1' -> 'cps=expand'
   * @param psValue
   */
  private convertPsValueToCpsValue(psValue: unknown): ControlPanelState | undefined {
    if (psValue === true) {
      return ControlPanelState.Expand;
    }
    if (psValue === false) {
      return ControlPanelState.Hide;
    }
    return undefined;
  }

  private getCachePanelState(type: 'controlPanel' | 'remarkPanel', defaultCacheValue: boolean): ControlPanelState {
    return appOptions.previewPanel.find((item) => item.appID === this.props.appID)?.option[
      type === 'controlPanel' ? 'controlPanelState' : 'remarkPanelState'
    ] ?? defaultCacheValue
      ? ControlPanelState.Expand
      : ControlPanelState.Hide;
  }

  get controlPanelState(): ControlPanelState {
    if (isShareFrame) {
      return ControlPanelState.Hide;
    }
    if (RP_CONFIGS.isOfflineDemo) {
      return getOfflineDemoData()?.config.controlPanelState ?? ControlPanelState.Expand;
    }
    const urlSearchObj = getUrlSearchObj();
    const psValueFromOldPreviewLink = urlSearchObj[PreviewUrlSearchKey.ShowControllerPanel];
    const cpsSearchValue = urlSearchObj[PreviewUrlSearchKey.ControlPanelState] as ControlPanelState | undefined;
    const urlState = this.convertPsValueToCpsValue(psValueFromOldPreviewLink) ?? cpsSearchValue;
    if (urlState !== undefined) {
      return urlState;
    }
    return this.getCachePanelState('controlPanel', true);
  }

  get remarkPanelState(): ControlPanelState {
    if (isShareFrame) {
      return ControlPanelState.Hide;
    }
    if (RP_CONFIGS.isOfflineDemo) {
      return getOfflineDemoData()?.config.remarkPanelState ?? ControlPanelState.Expand;
    }
    const urlSearchObj = getUrlSearchObj();
    const rpsSearchValue = urlSearchObj[PreviewUrlSearchKey.RemarkPanelState] as ControlPanelState | undefined;
    const urlState = rpsSearchValue;
    if (urlState !== undefined) {
      return urlState;
    }
    return this.getCachePanelState('remarkPanel', true);
  }

  get hideControlPanel(): boolean {
    return this.controlPanelState === ControlPanelState.Hide;
  }

  get currentShareOpt(): IShareOption {
    const { option, controlPanelState, remarkPanelState } = this.state;
    const opt: IShareOption = {
      controlPanelState,
      remarkPanelState,
      showNavigationBar: this.showNavigationBar,
      ...option,
    };
    if (!this.isMobileType) {
      delete opt.mobileType;
    }
    return opt;
  }

  /**
   * 获取整体缩放
   */
  get globalScale() {
    return this.state.scale / 100;
  }

  handleChangeCollapse = (e: React.MouseEvent, controlPanelState: ControlPanelState) => {
    this.setState({ controlPanelState }, () => {
      this.updateCurUrlSearchStr();
      updatePreviewPanelCatch(this.props.appID, { controlPanelState });
    });

    this.doTriggerCollapseChanging(e.timeStamp);
  };

  handleWindowResize = () => {
    window.postMessage('viewportChanged', '*');
  };

  private doTriggerCollapseChanging(startTimeStamp: number) {
    let start: number = startTimeStamp;
    const trigger = () => {
      window.requestAnimationFrame((timestamp) => {
        window.postMessage('viewportChanged', '*');
        if (timestamp - start < 150) {
          trigger();
        }
      });
    };
    trigger();
  }

  private setScale = (value: number, zoomByWheel?: boolean) => {
    window.pageScale = (value || 100) / 100;
    this.setState(
      {
        scale: value,
        zoomByWheel,
      },
      () => {
        window.dispatchEvent(new Event('pageScaleChange'));
        AppOptions.previewScale = this.state.scale;
        this.handleOptionChange({ scale: value });
      },
    );
  };

  handleChangeScale = (value: number, zoomByWheel?: boolean) => {
    // 在快照预览时禁用缩放
    if (!document.querySelector('.snapshot-preview-mask')) {
      this.setScale(value, zoomByWheel);
    }
    if (this.props.isStandalone) {
      this.postMsgAfterScaleChange(value);
    }
  };

  // 缩放
  handleScaleChange = (scaleInfo: IListItem) => {
    if (scaleInfo.scale) {
      this.handleChangeScale(scaleInfo.scale as number);
      this.handleOptionChange({ ...this.state.option, fullWidth: false, autoScreen: false });
    } else if (scaleInfo.isFullWidth) {
      // 适应宽度
      this.handleOptionChange({ ...this.state.option, fullWidth: true, autoScreen: false });
    } else if (scaleInfo.isFullScreen) {
      // 适应屏幕
      this.handleOptionChange({ ...this.state.option, autoScreen: true, fullWidth: false });
    }
  };

  /**
   * idoc单页模式postMessage
   */
  postMsgAfterScaleChange = (scale: number) => {
    if (window.parent && this.props.isStandalone) {
      window.parent.postMessage(
        {
          event: RpPreviewOption.SCALE_CHANGED,
          payload: {
            scale,
          },
        },
        '*',
      );
    }
  };

  handleOptionChange = (option: IPreviewOption) => {
    const { realAppID } = this.props;
    const newOption = { ...this.state.option, ...option };
    if (!this.isMobileType) {
      delete newOption.mobileType;
    }
    this.setState({ option: newOption }, () => {
      const cacheOptions = AppOptions.previewOption;
      const cacheOption = cacheOptions.find((item) => {
        return item.appID === realAppID;
      });
      if (cacheOption) {
        cacheOption.option = newOption;
      } else {
        cacheOptions.push({
          appID: realAppID,
          option: newOption,
        });
        if (cacheOptions.length > 20) {
          cacheOptions.shift();
        }
      }
      AppOptions.previewOption = cacheOptions;
      this.updateCurUrlSearchStr();
    });
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    const { isStandalone } = this.props;
    if (isStandalone) {
      return;
    }
    const { keyCode } = e;
    const dom = e.target as HTMLElement;
    if ([KeyCodeMap.VK_LEFT, KeyCodeMap.VK_UP].includes(keyCode)) {
      if (dom.tagName === 'TEXTAREA') {
        return;
      } else if (dom.tagName === 'INPUT' && keyCode === KeyCodeMap.VK_LEFT) {
        return;
      }
      this.leftPanel.current?.goPage('last');
    } else if ([KeyCodeMap.VK_RIGHT, KeyCodeMap.VK_DOWN].includes(keyCode)) {
      if (dom.tagName === 'TEXTAREA') {
        return;
      } else if (dom.tagName === 'INPUT' && keyCode === KeyCodeMap.VK_RIGHT) {
        return;
      }
      this.leftPanel.current?.goPage('next');
    }
  };

  renderMessage = () => {
    const { message, showMsg } = this.props;
    if (showMsg) {
      return <ToastMessage type="success" message={message} />;
    }
  };

  renderEscMessage = () => {
    const { msg, msgId } = this.props;
    if (msg !== '') {
      return <ToastEscMessage message={msg} />;
    } else {
      //定时器事件过来时，如果msgId = -1,表示用户取消全屏，不在显示toast,如果msgId == this.msgId表示与发起该定时器的id对应，显示toast时间已到，不再显示toast
      //如果msgId !== -1 && msgId !== this.msgId 表示用户快速点击多次全屏取消，前边的点击创建的定时器到期时，不应该影响当下是否显示toast，当下toast是否显示
      //应该由当下记录的状态决定
      if (msgId !== -1 && msgId !== this.msgId) {
        if (document.fullscreenElement || this.state.isFullscreen) {
          return <ToastEscMessage message={i18n('tips.useEscExit', i18n('application.fullScreen'))} />;
        }
      }
    }
  };

  handleLogout = () => {
    if (isMockRPD) {
      desktopServer.logout();
    } else {
      window.location.href = formatString(LOGIN_URL, encodeURIComponent(window.location.href));
    }
  };

  renderReLoginDialog() {
    if (!this.props.global.forceLogout) {
      return null;
    }

    return (
      <Dialog
        onClose={this.handleLogout}
        onSubmit={this.handleLogout}
        backFade
        contentClassName="rp-remote-login-dialog"
      >
        <p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: i18n('tips.remoteLoginTips') }} />
        <div className="rp-remote-login-button">
          <Button width={90} theme="onlyText" activated className="idoc" onClick={this.handleLogout}>
            {i18n('tips.reLogin')}
          </Button>
        </div>
      </Dialog>
    );
  }

  get isMobileType() {
    const { appType } = this.props.app;
    return isMobileAppType(appType) || isWatchAppType(appType) || isVehicleAppType(appType);
  }

  renderNavigationPanel = (offsetLeft: number, offsetX: number) => {
    const { app, appID, realAppID, pageID, onMsgPopup, currentPage, onPageSelect, userInfo, isStandalone } = this.props;
    if (!currentPage) return null;
    const { scale, option, controlPanelState, remarkPanelState, isFullscreen } = this.state;
    return (
      <NavigationPanel
        onControllerPanelCollapseChange={this.handleChangeCollapse}
        controlPanelState={controlPanelState}
        remarkPanelState={remarkPanelState}
        onRemarkPanelCollapseChange={this.handleShowRightPanel}
        app={app}
        appID={appID}
        realAppID={realAppID}
        pageID={pageID}
        page={currentPage}
        option={option}
        onMsgPopup={onMsgPopup}
        onChangeScale={this.handleChangeScale}
        onScaleChange={this.handleScaleChange}
        onOptionChange={this.handleOptionChange}
        appType={app.appType}
        scale={scale}
        onFullScreen={this.handleFullScreen}
        onPageSelect={onPageSelect}
        parentOffsetLeft={offsetLeft}
        parentOffsetX={offsetX}
        userInfo={userInfo}
        isStandalone={isStandalone}
        isFullScreen={isFullscreen}
        shouldHideNavPanel={isFullscreen || isStandalone || this.state.isInCC}
      />
    );
  };

  renderContent() {
    const {
      app,
      nextPage,
      currentPage,
      pageID,
      userInfo,
      global: { waiting },
      role,
      isStandalone,
      popularize,
      teamInfo,
      onPageSelect,
      membersFromApp,
    } = this.props;
    const { scale, option, controlPanelState, zoomByWheel, isFullscreen, remarkTagId } = this.state;
    const { mobileType } = option;
    const showControlPanel = !isStandalone;
    const orientation = undefined;
    // let orientation = app.orientation;
    // if (this.isMobileType) {
    //   orientation = orientation ?? 'portrait';
    // }
    const desktopTitleHeight = isFullscreen ? 0 : TITLE_HEIGHT;

    return (
      <div
        className={classnames('preview-container', {
          mac: isMacOS,
          desktop: isMockRPD && !isIframe && !isFullscreen,
        })}
        onKeyDown={this.handleKeyDown}
        style={{ top: needShowLarkRefreshTitle() && !isMobileDevice() ? LARK_TITLE_HEIGHT : 0 }}
      >
        {waiting && <Loading />}
        {showControlPanel && (
          <ControllerPanel
            ref={this.leftPanel}
            collapse={controlPanelState !== ControlPanelState.Expand}
            onCollapseChange={this.handleChangeCollapse}
            app={app as IPreviewAppInfo}
            option={option}
            mobileType={mobileType}
            onOptionChange={this.handleOptionChange}
            onPageSelect={onPageSelect}
            selected={currentPage?.doc.pageID ?? pageID}
            userInfo={userInfo}
            role={role}
            isFullscreen={isFullscreen}
            teamInfo={teamInfo}
            popularize={popularize}
            membersFromApp={membersFromApp}
          />
        )}

        <ViewPort
          ref={this.viewPortDom}
          option={option}
          scale={scale}
          zoomByWheel={zoomByWheel}
          page={currentPage}
          advancePage={nextPage}
          appType={app.appType}
          appSize={app.size}
          desktopTitleHeight={desktopTitleHeight}
          orientation={orientation}
          onAutoScale={this.handleChangeScale}
          isStandalone={isStandalone}
          remarkTagId={remarkTagId}
          theme={this.state.theme}
          renderNavigationPanel={this.renderNavigationPanel}
        />

        {/* <LayerInfoPanel /> */}

        {this.renderMessage()}
        {isElectron() && this.renderEscMessage()}
        {!isFullscreen && this.renderRemarkPanel()}
      </div>
    );
  }

  public get isRemarkEmpty() {
    const compsWithRemark = this.remarkManagerInstance?.compsWithRemark;
    if (!compsWithRemark) {
      return true;
    }
    return compsWithRemark.length === 0 || (compsWithRemark.length === 1 && !compsWithRemark[0].remark);
  }

  public remarkFocusIds: { [key: string]: boolean } = {};

  renderRemarks() {
    return this.remarkManagerInstance?.compsWithRemark.map((comp, index) => {
      this.remarkFocusIds[comp.id] = false;
      return (
        <RemarkGroup
          key={comp.id}
          target={comp}
          onShowRemark={this.doUpdateHoverRemark}
          remark={comp.remark}
          showTag={!(comp as UIFragment).isArtboard}
          orderNumber={index}
          onScrollToComp={this.handleScrollToComp}
          isPreview
        />
      );
    });
  }

  private remarkOverTimer?: number;

  private doUpdateHoverRemark = (comp?: UIComponent) => {
    if (this.remarkOverTimer) {
      clearTimeout(this.remarkOverTimer);
    }

    this.remarkOverTimer = window.setTimeout(() => {
      this.setState({ remarkTagId: comp?.id });
    }, 2);
  };

  renderRemarkPanel = () => {
    let visible = this.state.remarkPanelState === ControlPanelState.Expand;

    if (!this.remarkManagerInstance) {
      visible = false;
    }

    if (isMobileDevice() || this.props.isStandalone) {
      visible = false;
    }

    return (
      <Transition
        visible={visible}
        enterClassName="right-remark-panel--enter"
        leaveClassName="right-remark-panel--leave"
      >
        <div className="right-remark-panel">
          {this.isRemarkEmpty ? (
            <div className="no-remark">
              <img src={noRemarkImg} />
              <p>{i18n('preview.noRemark')}</p>
            </div>
          ) : (
            <div className="remark-content">
              <ScrollBars>{this.renderRemarks()}</ScrollBars>
            </div>
          )}
        </div>
      </Transition>
    );
  };

  renderExpandBtn = () => {
    if (this.state.remarkPanelState === ControlPanelState.Hide) {
      return null;
    }

    if (isMobileDevice() || this.props.isStandalone) {
      return null;
    }

    return (
      <div className="remark-expand-btn" onClick={this.handleShowRightPanel.bind(this, ControlPanelState.Expand)}>
        <Icon cls="list" theme="tag" tips={i18n('preview.remark')} />
      </div>
    );
  };

  renderToastMessage(): React.ReactNode | null {
    const { toast } = this.props.global;
    if (!toast) {
      return null;
    }
    return <GlobalToast toast={toast} dispacher={this.props.previewDispatcher} />;
  }

  render() {
    const { app, waterMark } = this.props;
    return (
      <>
        {this.renderToastMessage()}
        {this.renderReLoginDialog()}
        <DesktopContainer title={app.name} status="maximized" onFullscreenChange={this.handleDesktopFullscreenChange}>
          {this.renderContent()}
          {waterMark && <WaterMark show content={waterMark} style={{ position: 'absolute' }} />}
        </DesktopContainer>
      </>
    );
  }
}

export default WebIndex;
