export type MoveDelta = {
  readonly x: number,
  readonly y: number,
  readonly rotate?: number,
};

export enum Direction {
  nw = 'nw',
  n = 'n',
  ne = 'ne',
  e = 'e',
  se = 'se',
  s = 's',
  sw = 'sw',
  w = 'w',
}

export const Directions: Array<Direction> = [
  Direction.nw,
  Direction.n,
  Direction.ne,
  Direction.e,
  Direction.s,
  Direction.sw,
  Direction.w,
  Direction.se,
];

