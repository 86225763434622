import { cloneDeep } from 'lodash';
import INode, { INodeWithChildren, NodeType } from '../models/node';
import IArtboard from '../models/artboard';
import { IAppsOfTeam } from '@/fbs/teamManagement';

export enum NodeState {
  Normal = 0,
  Deleted = 3,
}

function nodeToINodeWithChildren(node: INode): INodeWithChildren {
  return Object.assign({}, node, {
    _id: node._id,
    name: node.name,
    type: node.type,
    index: 0,
    appID: node.appID,
    artboardID: node.artboardID,
    path: node.path,
    children: [],
    userID: node.userID,
    _collapse: node._collapse || false,
    hidden: node.hidden,
  });
}

function putNode(node: INode, nodes: INodeWithChildren[], useOriginIndex?: boolean) {
  const newNode = nodeToINodeWithChildren(node);
  if (node.path === 'ROOT') {
    newNode.index = nodes.length;
    if (useOriginIndex) {
      newNode.index = node.index;
    }
    nodes.push(newNode);
  } else {
    const ancestors = node.path.split(',').slice(1);
    ancestors.reduce((prev, id, index) => {
      const parent = prev.find((n) => n.nodeID === id || n._id === id);
      // 已经找到最后了
      if (index === ancestors.length - 1) {
        if (parent) {
          newNode.parent = parent;
          newNode.index = parent.children.length;
          if (useOriginIndex) {
            newNode.index = node.index;
          }
          if (parent.hidden) {
            newNode.hidden = true;
          }
          parent.children.push(newNode);
        } else {
          // 如果父不存在，这是一个没有爸爸的孩子，就放到根下面
          newNode.index = nodes.length;
          if (useOriginIndex) {
            newNode.index = node.index;
          }
          nodes.push(newNode);
        }
      }
      if (parent) {
        return parent.children;
      }
      return prev;
    }, nodes);
  }
}

export function parseNodesToTree(nodes: INode[], useOriginIndex?: boolean): INodeWithChildren[] {
  const res: INodeWithChildren[] = [];

  nodes.forEach((node) => putNode(node, res, useOriginIndex));
  return res;
}

// 判断多层级的父是否已经删除
function hasDeletedParent(node: INodeWithChildren, delNodeIDs: string[]) {
  const path = node.path;
  for (let j = 0, len = delNodeIDs.length; j < len; j++) {
    if (path.includes(delNodeIDs[j])) {
      return true;
    }
  }
  return false;
}

export function parseNodeToTreeByState(
  nodes: INode[],
  useOriginIndex?: boolean,
): {
  realNodes: INodeWithChildren[];
  tempDeleteNodes: INodeWithChildren[];
  pageFlatNodes: { [key: string]: INode };
} {
  const newNodes = nodes.map((node) => nodeToINodeWithChildren(node));

  // 分类删除和未删除
  const normalNodes = newNodes.filter((node) => node.state === NodeState.Normal);
  const tempDeleteNodes = newNodes.filter((node) => node.state === NodeState.Deleted);
  const delNodeIDs = tempDeleteNodes.map((node) => node._id);
  // 找到未删除中父被删除的后代 及 真正未删除的节点
  const realNormalNodes: INodeWithChildren[] = [];
  const delNodesChildren: INodeWithChildren[] = [];
  const pageFlatNodes: { [key: string]: INode } = {};
  normalNodes.forEach((node) => {
    if (hasDeletedParent(node, delNodeIDs)) {
      delNodesChildren.push(node);
    } else {
      realNormalNodes.push(node);
      pageFlatNodes[node._id] = node;
    }
  });
  const realNodes = parseNodesToTree(realNormalNodes, useOriginIndex);

  // 先把间接删除的子构造树结构，然后将节点组装到删除的父
  const delNodeChildTree = parseNodesToTree(delNodesChildren, useOriginIndex);
  delNodeChildTree.forEach((node) => {
    const ancestors = node.path.split(',').reverse();
    const parentId = ancestors[0];
    const parendNode = tempDeleteNodes.find((node) => node._id === parentId);
    if (parendNode) {
      parendNode.children.push(node);
    }
  });

  // 删除的按删除时间倒序(刚删除的在第一个)
  tempDeleteNodes.sort((a, b) => {
    const aTime = new Date(a.deletedAt || 0).getTime();
    const bTime = new Date(b.deletedAt || 0).getTime();
    return bTime - aTime;
  });

  return {
    realNodes,
    tempDeleteNodes,
    pageFlatNodes,
  };
}

export function parseNodesWithArtboardsToTreeByState(nodes: INode[], artboards: IArtboard[]) {
  const tree = parseNodeToTreeByState(nodes);
  const pageNodes = tree.realNodes;
  const states: any = [];
  addFragmentsToPageNodes(pageNodes, artboards, states);
  return {
    pageNodes,
    states,
  };
}

function addFragmentsToPageNodes(nodes: INodeWithChildren[], artboards: IArtboard[], states: any[]) {
  nodes.forEach((node) => {
    // 先处理子，避免加了 fragments 之后影响
    if (node.children && node.children.length > 0) {
      addFragmentsToPageNodes(node.children, artboards, states);
    }
    const nodeID = node.nodeID;
    const fragments = artboards.filter((art) => art.nodeID === nodeID);
    if (fragments.length > 0) {
      // @ts-ignore
      node.stateID = nodeID;
      const statePages = fragments.map((fragment) => ({
        _id: `${fragment._id}$${fragment.artboardID}`,
        relID: (<any>fragment).relID,
        name: `[${fragment.name || '-'}]`,
        type: 'fragment',
        size: fragment.size,
        position: fragment.position,
        index: 0,
        appID: fragment.appID,
        color: fragment.color,
        icon: fragment.icon,
        artboardID: fragment.artboardID,
        path: node.path,
        children: [],
        userID: fragment.userID,
        _collapse: false,
        // @ts-ignore
        stateID: nodeID,
        source: 'rp-fragment',
        imageURL: fragment.imageURL,
        imageCreatedAt: fragment.imageCreatedAt,
        revisionID: fragment.revisionID,
        designDescription: fragment.designDescription,
        updatedAt: fragment.updatedAt,
        createdAt: fragment.createdAt,
      }));
      node.children.unshift(...statePages);
      const state = {
        _id: nodeID,
        pages: [node, ...statePages],
      };
      states.push(state);
    }
  });
}

export function getFirstPageNodeOfTree(
  nodes: INodeWithChildren[],
  filter?: (node: INodeWithChildren) => boolean,
): INodeWithChildren | null {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    const isAllow = filter ? filter(node) : true;
    if (node.type === NodeType.Page && isAllow) {
      return node;
    }
    if (node.children) {
      const subNode = getFirstPageNodeOfTree(node.children, filter);
      if (subNode) {
        return subNode;
      }
    }
  }
  return null;
}

/**
 * 找到第一个页面节点
 * @param nodes
 */
export function getFirstPageNode(nodes: INode[]): INode | null {
  const tree = parseNodesToTree(nodes);
  return getFirstPageNodeOfTree(tree);
}

export function changeTreeToArray(nodes: INodeWithChildren[], result: INodeWithChildren[] = []) {
  nodes.forEach((node) => {
    result.push(node);
    if (node.children.length) {
      changeTreeToArray(node.children, result);
    }
  });
  return result;
}

/**
 * 获取移除的节点，包括这些节点的子节点
 * @param nodes
 * @param delNodeIds
 * @param allDelNodeIds
 */
export function getRemoveNodeIncludeChild(nodes: INodeWithChildren[], delNodeIds: string[], allDelNodeIds: string[]) {
  nodes.forEach((node) => {
    if (delNodeIds.includes(node._id)) {
      allDelNodeIds.push(node._id);
      if (node.children.length) {
        getRemoveNodeIncludeChild(
          node.children,
          node.children.map((node) => node._id),
          allDelNodeIds,
        );
      }
    } else {
      if (node.children.length) {
        getRemoveNodeIncludeChild(node.children, delNodeIds, allDelNodeIds);
      }
    }
  });
}

/**
 * 查找删除后的，下一个应该选中的页面
 * 这里会遍历子节点
 * @param nodes
 * @param delNodeIds
 * @param pageID
 */
export function findNextPageNode(nodes: INodeWithChildren[], delNodeIds: string[], pageID: string): INode | null {
  //获取会被删除的节点
  let allDelNodeIds: string[] = [];
  getRemoveNodeIncludeChild(nodes, delNodeIds, allDelNodeIds);

  //如果删除的页面不包含选中的页面或组内, 不做跳转
  if (!allDelNodeIds.includes(pageID)) {
    const node = findNodeByID(nodes, pageID);
    if (node) {
      return node;
    }
  }

  //如果删除的页面包含选中的页面或组内，则跳转最近的一个页面
  const arrTreeData: INodeWithChildren[] = changeTreeToArray(nodes);

  let maxIndex = 0;
  allDelNodeIds.forEach((delId) => {
    const index = arrTreeData.findIndex((data) => data._id === delId);
    maxIndex = Math.max(index, maxIndex);
  });

  let nextIndex = maxIndex + 1;
  let nextNode = arrTreeData[nextIndex];
  //向下查找
  while (nextNode && (nextNode?.type !== 'page' || allDelNodeIds.includes(nextNode._id))) {
    ++nextIndex;
    nextNode = arrTreeData[nextIndex];
  }

  //向下没有查找到，nextNode 回到原来的节点，然后向上查找
  if (!nextNode) {
    nextNode = arrTreeData[maxIndex];
  }

  //向上查找
  while (nextNode && (nextNode?.type !== 'page' || allDelNodeIds.includes(nextNode._id))) {
    --nextIndex;
    nextNode = arrTreeData[nextIndex];
  }

  return nextNode;
}

/**
 * 查询 node
 * @param nodes
 * @param nodeID
 */
export function findNodeByID(nodes: INodeWithChildren[], nodeID: string): INodeWithChildren | null {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (node._id === nodeID) {
      return node;
    }
    if (node.children) {
      const foundFromNodeChildren = findNodeByID(node.children, nodeID);
      if (foundFromNodeChildren) {
        return foundFromNodeChildren;
      }
    }
  }
  return null;
}

export function findAppByID(apps: IAppsOfTeam[], id: string): IAppsOfTeam | null {
  for (let i = 0; i < apps.length; i++) {
    const node = apps[i];
    if (node._id === id) {
      return node;
    }
    if (node.children) {
      const foundFromNodeChildren = findAppByID(node.children, id);
      if (foundFromNodeChildren) {
        return foundFromNodeChildren;
      }
    }
  }
  return null;
}

// 查询一个节点的所有父节点
export function findAllAncestors(node: INodeWithChildren): string[] {
  const ancestors: string[] = [];
  let currentNode: INodeWithChildren = node;
  while (currentNode.parent) {
    ancestors.unshift(currentNode.parent._id);
    currentNode = currentNode.parent;
  }
  return ancestors;
}

/**
 * 查询 node 的 parent 和该 node 的位置
 * @param nodes
 * @param nodeID
 */
export function findParentNodeByID(
  nodes: INodeWithChildren[],
  nodeID: string,
  parent: INodeWithChildren | null = null,
): {
  parent: INodeWithChildren | null;
  index: number;
} {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (node._id === nodeID) {
      return {
        parent,
        index: i,
      };
    }
    if (node.children) {
      const foundFromNodeChildren = findParentNodeByID(node.children, nodeID, node);
      if (foundFromNodeChildren.index !== -1) {
        return foundFromNodeChildren;
      }
    }
  }
  return {
    parent,
    index: -1,
  };
}

export function renameNodeOfTree(nodes: INodeWithChildren[], nodeID: string, name: string): INodeWithChildren[] {
  return nodes.map((node) => {
    if (node._id === nodeID) {
      return Object.assign({}, node, {
        name,
      });
    }
    if (node.children) {
      node.children = renameNodeOfTree(node.children, nodeID, name);
    }
    return node;
  });
}

export function collapseOfTree(nodes: INodeWithChildren[], nodeID: string, collapse: boolean): INodeWithChildren[] {
  return nodes.map((node) => {
    if (node._id === nodeID) {
      return Object.assign({}, node, {
        _collapse: collapse,
      });
    }
    if (node.children) {
      node.children = collapseOfTree(node.children, nodeID, collapse);
    }
    return node;
  });
}

export function getNodesByIDs(nodes: INodeWithChildren[], IDs: string[]): INodeWithChildren[] {
  if (IDs.length === 0) {
    return [];
  }
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (IDs.includes(node._id)) {
      // 只要发现该级有一个节点是被选中，找出所有的，直接返回
      return nodes.filter((item) => IDs.includes(item._id));
    }
    if (node.children && node.children.length > 0) {
      const subRes = getNodesByIDs(node.children, IDs);
      if (subRes.length > 0) {
        return subRes;
      }
    }
  }
  return [];
}

export function prettyPrintTree(nodes: INodeWithChildren[], level: number = 0) {
  nodes.forEach((node) => {
    const prefix = Array(level).fill('  ').join('');
    console.log(`${prefix} [${node.index}] ${node._id} ${node.name}`);
    if (node.children && node.children.length > 0) {
      prettyPrintTree(node.children, level + 1);
    }
  });
}

export function containsNode(node: INodeWithChildren, id: string): boolean {
  if (node._id === id) {
    return true;
  }
  if (!node.children || node.children.length === 0) {
    return false;
  }
  for (let i = 0; i < node.children.length; i++) {
    if (containsNode(node.children[i], id)) {
      return true;
    }
  }
  return false;
}

export function getAllPageCount(
  nodes: INodeWithChildren[],
  options?: {
    noHiddenPage: boolean;
  },
) {
  let result = 0;
  const countFun = (list: INodeWithChildren[]) => {
    const arr = list.filter((item) => item.state !== 3);

    arr.forEach((item) => {
      if (options && options.noHiddenPage && item.hidden) {
        return;
      }
      if (item.type === 'page') {
        result++;
      }
      if (item.children) {
        countFun(item.children);
      }
    });
  };
  countFun(nodes);
  return result;
}

export function getHiddenParentNode(
  node: INodeWithChildren,
  hiddenParentNodes: INodeWithChildren[] = [],
): INodeWithChildren[] {
  const parent = node.parent;
  if (parent?.hidden) {
    hiddenParentNodes.push(parent);
  }
  if (parent?.parent) {
    return getHiddenParentNode(parent, hiddenParentNodes);
  }
  return hiddenParentNodes;
}

export function isPageHidden(node: INodeWithChildren) {
  const hiddenParents = getHiddenParentNode(node);
  return hiddenParents.length ? true : node.hidden;
}

export function removeChildren(nodes: INodeWithChildren[], selectedId: string): INodeWithChildren[] | null {
  const newNodes = cloneDeep(nodes);
  for (let i = 0; i < newNodes.length; i++) {
    const node = newNodes[i];
    if (node._id === selectedId) {
      node.parent ? node.parent.children.splice(i, 1) : nodes.splice(i, 1);
      return newNodes;
    }
    if (node.children) {
      removeChildren(node.children, selectedId);
    }
  }
  return null;
}
