import * as React from 'react';
import * as tinycolor from 'tinycolor2';

import { dragDelegate } from '@utils/mouseUtils';
import { depthClone, isEqualDate } from '@utils/globalUtils';
import { parseColorToString } from '@utils/graphicsUtils';

import { IColorInfo } from '../model';

import './index.scss';

export interface IColorPlateProp {
  color: IColorInfo;
  disabled?: boolean;
  onChanged: (color: tinycolor.ColorFormats.HSV, isChanging?: boolean) => void;
}

export interface IColorPlateState {
  color: tinycolor.ColorFormats.HSV;
  rgb: tinycolor.ColorFormats.RGB;
  afterChooseOffset: { left: string; top: string };
}

class ColorPlate extends React.Component<IColorPlateProp, IColorPlateState> {
  static defaultProps: Partial<IColorPlateProp> = {};

  selfRef: React.RefObject<HTMLDivElement>;

  constructor(props: IColorPlateProp) {
    super(props);
    this.state = {
      color: props.color.hsv,
      rgb: tinycolor(props.color.hsv).toRgb(),
      afterChooseOffset: {
        left: `${props.color.hsv.s * 100}%`,
        top: `${(1 - props.color.hsv.v) * 100}%`,
      },
    };
    this.selfRef = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(newProps: IColorPlateProp) {
    const newColor = newProps.color.hsv;
    if (
      this.state.color !== newColor &&
      !isEqualDate(this.state.rgb, newProps.color.rgb) &&
      this.state.color.v + newColor.v !== 0
    ) {
      this.setState({
        color: newColor,
        rgb: tinycolor(newColor).toRgb(),
        afterChooseOffset: {
          left: `${newColor.s * 100}%`,
          top: `${(1 - newColor.v) * 100}%`,
        },
      });
    }
  }

  onPointMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    const dom = e.target as HTMLElement;
    const { offsetTop, offsetLeft } = dom;
    const parent = dom.parentElement;
    const { onChanged } = this.props;
    if (parent) {
      const { offsetWidth, offsetHeight } = parent;
      const newColor = { ...this.state.color, s: offsetLeft / offsetWidth, v: 1 - offsetTop / offsetHeight };
      this.setState(
        {
          color: newColor,
          rgb: tinycolor(newColor).toRgb(),
          afterChooseOffset: { left: `${offsetLeft}px`, top: `${offsetTop}px` },
        },
        () => {
          onChanged && onChanged(newColor, true);
        },
      );
      dragDelegate(
        (e: MouseEvent, delta: { x: number; y: number }) => {
          e.preventDefault();
          const newLeft = Math.max(0, Math.min(offsetLeft + delta.x, offsetWidth));
          const newTop = Math.max(0, Math.min(offsetTop + delta.y, offsetHeight));
          const newColor = { ...this.state.color, s: newLeft / offsetWidth, v: 1 - newTop / offsetHeight };
          this.setState(
            {
              color: newColor,
              rgb: tinycolor(newColor).toRgb(),
              afterChooseOffset: { left: `${newLeft}px`, top: `${newTop}px` },
            },
            () => {
              onChanged && onChanged(newColor, true);
            },
          );
        },
        () => {
          onChanged && onChanged(this.state.color);
        },
      );
    }
  };
  onPlateMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    const dom = e.target as HTMLElement;
    const { offsetX, offsetY } = e.nativeEvent;
    const { offsetHeight, offsetWidth } = dom;
    const { color } = this.state;
    const { onChanged } = this.props;
    const newColor = { ...depthClone(color), s: offsetX / offsetWidth, v: 1 - offsetY / offsetHeight };
    this.setState(
      {
        color: newColor,
        rgb: tinycolor(newColor).toRgb(),
        afterChooseOffset: { left: `${offsetX}px`, top: `${offsetY}px` },
      },
      () => {
        onChanged && onChanged(newColor, true);
      },
    );
    dragDelegate(
      (e: MouseEvent, delta: { x: number; y: number }) => {
        e.preventDefault();
        const newLeft = Math.max(0, Math.min(offsetX + delta.x, offsetWidth));
        const newTop = Math.max(0, Math.min(offsetY + delta.y, offsetHeight));
        const newColor = { ...this.state.color, s: newLeft / offsetWidth, v: 1 - newTop / offsetHeight };
        this.setState(
          {
            color: newColor,
            rgb: tinycolor(newColor).toRgb(),
            afterChooseOffset: { left: `${newLeft}px`, top: `${newTop}px` },
          },
          () => {
            onChanged && onChanged(newColor, true);
          },
        );
      },
      (e: MouseEvent, delta: { x: number; y: number }) => {
        const newLeft = Math.max(0, Math.min(offsetX + delta.x, offsetWidth));
        const newTop = Math.max(0, Math.min(offsetY + delta.y, offsetHeight));
        const newColor = { ...this.state.color, s: newLeft / offsetWidth, v: 1 - newTop / offsetHeight };
        this.setState(
          {
            color: newColor,
            rgb: tinycolor(newColor).toRgb(),
            afterChooseOffset: { left: `${newLeft}px`, top: `${newTop}px` },
          },
          () => {
            onChanged && onChanged(newColor);
          },
        );
      },
    );
  };

  render() {
    const { afterChooseOffset, rgb } = this.state;
    const { color, disabled } = this.props;
    const { left, top } = afterChooseOffset;
    return (
      <div
        ref={this.selfRef}
        className="dsm-c-rp-color-plate"
        onMouseDown={disabled ? undefined : this.onPlateMouseDown}
      >
        <div style={{ background: `hsl(${color.hsv.h}, 100%, 50%)` }} />
        <div />
        <div />
        <div
          style={{ left, top, background: parseColorToString({ ...rgb, a: 1 }) }}
          onMouseDown={disabled ? undefined : this.onPointMouseDown}
        />
      </div>
    );
  }
}

export default ColorPlate;
