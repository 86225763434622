import { IComponentData } from '@fbs/rp/models/component';
import { ISize } from '@fbs/common/models/common';
import { HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import {
  reArrangeComponentOfWrapPanel,
  reArrangeComponentsOfStackPanel,
  reArrangeGridPanel,
} from '@helpers/groupHelper';
import { getCompsLayoutByData } from '@helpers/responseLayoutHelper';
import * as BoundsUtils from '@utils/boundsUtils';
import { initBoundsWithPositionAndSize } from '@utils/boundsUtils';
import { getBoundsOffsetBySizeChange } from '@helpers/componentHelper';
import {
  extractDynamicInfoFromPatch,
  getCompAndItsChildren,
  getResizeResultByParentChange,
  setCompData,
} from '@editor/comps/resizeHelper';
import { makeUIComponent } from '@editor/comps/factory';
import { getLibData } from '@libs/libs';
import { depthClone, sameNumber } from '@/utils/globalUtils';
import { ArtboardPatches, Operation } from '@/fbs/rp/utils/patch';
import { UICompoundPathComponent } from '@/editor/comps';
import { CStackPanel, CWrapPanel, CGridPanel, CLine, CSymbol, CCompoundPath } from '@libs/constants';

import { assignPatches, coverPatches } from './patchHelper';

const updateSymbolDataChildren = (comp: IComponentData, parentData: IComponentData, parentOriginalSize: ISize) => {
  const { size } = comp;
  const { type: parentType } = parentData;
  // 根据父容器来
  const compLayout = getCompsLayoutByData([comp], BoundsUtils.createBoundsBySize(parentOriginalSize)).get(comp._id);
  const containerOffset = getBoundsOffsetBySizeChange(parentOriginalSize, parentData.size);
  const resizeOptions = {
    container: {
      before: {
        position: {
          x: 0,
          y: 0,
        },
        size: parentOriginalSize,
      },
      // 对于旋转的组件，需要传递给子的是左上角顶点的变化
      after: {
        position: {
          x: 0,
          y: 0,
        },
        size: parentData.size,
      },
      isResponsive: parentData.layout.responsive,
    },
    shift: false,
    scale: {
      h: parentData.size.width / parentOriginalSize.width,
      v: parentData.size.height / parentOriginalSize.height,
    },
  };
  if (parentType === CStackPanel) {
    reArrangeComponentsOfStackPanel(parentData, parentData.properties.layout);
  } else if (parentType === CWrapPanel) {
    reArrangeComponentOfWrapPanel(parentData);
  } else if (parentType === CGridPanel) {
    reArrangeGridPanel(parentData);
  } else {
    if (comp.sealed) {
      let patches: ArtboardPatches = { do: {}, undo: {} };
      const { position, size, patches: childPatches } = makeUIComponent(comp).resizeHandler2(
        containerOffset,
        compLayout!,
        resizeOptions,
      );
      childPatches && coverPatches(patches, childPatches);
      const libData = getLibData(comp.lib?.type || '');
      const originSize = depthClone(comp.size);
      if (libData) {
        const resizeFn = libData.editor?.onResize;
        if (resizeFn) {
          comp.size = originSize;
          const specialPatches = resizeFn(makeUIComponent(comp), size);
          comp.size = size;
          patches = assignPatches(patches, specialPatches || { do: {}, undo: {} });
        }
      }
      const children = getCompAndItsChildren([comp]) as IComponentData[];
      const data = extractDynamicInfoFromPatch(patches);
      children.forEach((child) => {
        const childId = child._id;
        const childData = data[childId];
        if (childData) {
          const { position, size, rotate } = childData;
          position && (child.position = position);
          size && (child.size = size);
          rotate && (child.rotate = rotate);
        }
      });
      comp.position = position;
      comp.size = size;
      return;
    } else {
      // 这里修改当前组件的大小和位置
      // 这里修改layout，当symbol中仅仅只有垂直直线或水平直线
      if (comp.type === CLine && parentData.type === CSymbol && parentData.components?.length === 1) {
        if (sameNumber(comp.size.height, 0)) {
          compLayout!.vertical = VerticalAlign.Middle;
        }
        if (sameNumber(comp.size.width, 0)) {
          compLayout!.horizontal = HorizontalAlign.Center;
        }
      }
      const { position, size, rotate } = getResizeResultByParentChange(
        comp,
        containerOffset,
        compLayout!,
        resizeOptions,
      );
      const newBounds = initBoundsWithPositionAndSize(position, size);
      const uicomponent = makeUIComponent(comp);
      const compPatches = uicomponent.updateValueOrPropertiesWithBoundsChanged(newBounds);
      const patches: ArtboardPatches = { do: {}, undo: {} };
      const doOps: Operation[] = [];
      const undoOps: Operation[] = [];
      if (compPatches) {
        compPatches.do[uicomponent.id].forEach((ops) => {
          doOps.push(ops);
        });
        compPatches.undo[uicomponent.id].forEach((ops) => {
          undoOps.push(ops);
        });
      }
      if (doOps.length > 0) {
        const oldDoPath = patches.do[uicomponent.id];
        const oldUndoPath = patches.undo[uicomponent.id];
        patches.do[uicomponent.id] = oldDoPath ? [...oldDoPath, ...doOps] : doOps;
        patches.undo[uicomponent.id] = oldUndoPath ? [...oldUndoPath, ...undoOps] : undoOps;
      }
      if (uicomponent.type === CCompoundPath) {
        const oldSize = uicomponent.currentState.size || uicomponent.size;
        const scale = {
          x: newBounds.width / (oldSize.width || 1),
          y: newBounds.height / (oldSize.height || 1),
        };
        (uicomponent as UICompoundPathComponent).updateChildrenOfCompoundPathByZoom(scale, patches);
      }
      const data = extractDynamicInfoFromPatch(patches);
      const allComp = getCompAndItsChildren([uicomponent]);
      comp.position = position;
      comp.size = size;
      comp.rotate = rotate;
      setCompData(allComp, data);
    }
  }
  comp.components?.forEach((c) => updateSymbolDataChildren(c, comp, size));
};

/**
 * 修改symbol中子组件的数据
 * @param comp
 * @param symbolData
 */
export function updateSymbolDataByComp(comp: IComponentData, symbolData: IComponentData) {
  const { size, sizeVersion: compSizeVersion } = comp;
  const {
    size: { width, height },
    sizeVersion,
  } = symbolData;
  if ((compSizeVersion || 0) <= (sizeVersion || 0)) {
    return false;
  }
  if (size.width !== width || size.height !== height) {
    symbolData.size = { ...size };
    symbolData.components?.forEach((item) => {
      updateSymbolDataChildren(item, symbolData, { width, height });
    });
    return true;
  }
  return false;
}
