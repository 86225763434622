import i18n from '@/i18n';
import { IComponentItem } from '@/libs/types';
import { CRect } from '@/libs/constants';
import { SpriteThumb } from '@/consts/spriteIcons';
import { UIComponent } from '@/editor/comps';
import { IComponentData } from '@/fbs/rp/models/component';
import { IProperties } from '@/fbs/rp/models/property';
import { makeShapeBase, getShapeBaseDefaultData } from '../common/ShapeTextBase';

// flow 那里要用
export function getRectBaseDefaultData() {
  const shapeBaseDefaultData = getShapeBaseDefaultData(true);

  Object.assign(shapeBaseDefaultData.properties, {
    radius: {
      topRight: 0,
      topLeft: 0,
      bottomLeft: 0,
      bottomRight: 0,
      isPercent: false,
      disabled: false,
    },
    border: {
      left: true,
      top: true,
      right: true,
      bottom: true,
    },
  });

  return shapeBaseDefaultData;
}

export const rectConfig: IComponentItem = {
  type: CRect,
  name: i18n('resource.components.rect'),
  shortCut: 'R',
  thumb: {
    spriteIconClass: SpriteThumb.Rect.className,
    dragPosition: SpriteThumb.Rect.position,
  },
  editor: {
    onValidateAllowEditor: (comp: UIComponent, trigger: 'enter' | 'dblClick'): 'path' | 'value' => {
      return trigger === 'dblClick' ? 'path' : 'value';
    },
  },
  getDefaultData() {
    return getRectBaseDefaultData();
  },
};

export function makeRect(id: string, data?: Partial<IComponentData>, properties?: IProperties): IComponentData {
  const template: Partial<IComponentData> = {
    ...data,
    properties: properties ?? {},
  };
  return makeShapeBase(id, CRect, true, template);
}
