const regExpQuote = function (str: string) {
  return str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
};

// 节点名字和搜索匹配修改显示
export function nodeMatchUpdate(
  name: string,
  searchKey: string = '',
  showGroupNum: boolean = false,
  groupNum: number = 0,
) {
  //没有搜索内容的时候直接返回
  if (!searchKey) {
    return showGroupNum && !!groupNum ? name.concat(`<b class="group-num" >（${groupNum}）</b>`) : name;
  }
  const searchKeyQuote = regExpQuote(searchKey);
  const re = new RegExp(`${searchKeyQuote}`, 'g');
  if (re.test(name)) {
    name = name.replace(re, `<b class="searchKey-highlight" >${searchKey}</b>`);
  }
  return showGroupNum && !!groupNum ? name.concat(`<b class="group-num" >（${groupNum}）</b>`) : name;
}
