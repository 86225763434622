import * as React from 'react';
import classnames from 'classnames';

import { sameObject } from '@utils/globalUtils';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';

import { Icon, PopupManager, Input, InputNumber, IListItem } from '..';
import DropDown from './DropDown';

import { ComponentTheme } from '../common';

import './index.scss';

// TODO Matt 这个组件应支持上向方向键顺序选择上、下一个

export interface ISelectProp {
  data: Array<IListItem>;
  width?: number | string;
  value?: string;
  selectedIndex?: number;
  selected?: string | number;
  disabled?: boolean;
  itemHeight?: number;
  placeholder: string;
  multiple?: boolean; // 是否存在多个值
  // 是否位于弹窗中
  isInPopup?: boolean;
  onSelect?: (item: IListItem) => void;
  itemRender?: (data: IListItem, selected: boolean) => React.ReactNode;
  render?: (data: IListItem | null, value?: string) => React.ReactNode;
  buttonRender?: () => React.ReactNode;
  className?: string;
  dropDownClassName?: string;
  maxRowCount?: number;
  onDropMouseDown?: React.MouseEventHandler;
  theme?: 'normal' | 'no-border' | 'light';
  dropDownMaxWidth?: number;
  placement?: 'left' | 'right';
  editOption?: {
    enabled?: boolean;
    editModel?: 'string' | 'number';
    min?: number;
    max?: number;
  };
  hiddenArrow?: boolean;
  disabbleArrow?: boolean;
  onInputKeyUp?: React.KeyboardEventHandler;
  onInputComplete?: (value: string | number) => void;
  dropDownAllowHover?: boolean;
  onDropDownVisible?: (visible: boolean) => void;
  titleRender?: (item?: IListItem) => React.ReactNode;
}

export interface ISelectState {
  popup: boolean;
  selectedIndex?: number;
  editing?: boolean;
  dropDownWidth?: number;
}

class Select extends React.PureComponent<ISelectProp, ISelectState> {
  static defaultProps: Partial<ISelectProp> = {
    maxRowCount: 6,
    theme: 'no-border',
    dropDownMaxWidth: 200,
    placement: 'left',
    placeholder: '—',
  };

  selfRef: React.RefObject<HTMLDivElement> = React.createRef();
  inputDom: React.RefObject<InputNumber | Input> = React.createRef();
  buttonDom: React.RefObject<HTMLDivElement> = React.createRef();

  private released?: boolean;

  constructor(props: ISelectProp) {
    super(props);
    this.state = {
      popup: false,
      selectedIndex: this.doCalculateSelectedIndex(props),
      dropDownWidth: props.dropDownMaxWidth,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: ISelectProp) {
    this.setState({ selectedIndex: this.doCalculateSelectedIndex(nextProps) });
    if (!sameObject(this.props.data, nextProps.data)) {
      this.setState({ popup: false });
    }
  }

  componentWillUnmount() {
    this.released = true;
    window.removeEventListener('mousedown', this.handleWindowMouseDown);
    window.removeEventListener('keydown', this.handleWindowKeyDown);
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.handleWindowMouseDown);
    window.addEventListener('keydown', this.handleWindowKeyDown);
    // FIXME: mouseDown, 展开下拉选项时计算dropDownWidth，不在初始时设置
    // const { dropDownMaxWidth } = this.props;
    // const dom = this.selfRef.current;
    // if (dom && dropDownMaxWidth) {
    //   const width = Math.round(dom.getBoundingClientRect().width);
    //   if (width > dropDownMaxWidth) {
    //     this.setState({ dropDownWidth: width });
    //   }
    // }
  }

  private doCalculateSelectedIndex(props: ISelectProp): number {
    const { selected, selectedIndex, data } = props;
    if (data) {
      if (typeof selectedIndex !== 'undefined' && selectedIndex !== -1) {
        return selectedIndex;
      }
      if (typeof selected !== 'undefined') {
        return data.findIndex((item) => item.id === selected);
      }
    }
    return -1;
  }

  doPopup = (value: boolean) => {
    if (!this.released) {
      this.setState({ popup: value, editing: false });
      this.props.onDropDownVisible?.(value);
    }
  };

  handleWindowMouseDown = (e: MouseEvent) => {
    const { editing } = this.state;
    if (editing) {
      if (this.inputDom.current) {
        const { left, top, right, bottom } = this.inputDom.current.getBoundingClientRect();
        const { pageY, pageX } = e;
        if (pageX > left && pageX < right && pageY > top && pageY < bottom) {
          return;
        }
        this.setState({ editing: false });
      }
    }
  };

  handleWindowKeyDown = (e: KeyboardEvent) => {
    if ((this.state.editing && e.keyCode === KeyCodeMap.VK_ESCAPE) || e.keyCode === KeyCodeMap.VK_TAB) {
      this.setState({ editing: false });
    }
  };

  handleSelect = (item: IListItem) => {
    const { onSelect } = this.props;
    this.doPopup(false);
    PopupManager.manager.locked();
    onSelect && onSelect(item);
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    const { disabbleArrow } = this.props;
    if (disabbleArrow) {
      return;
    }
    if ([KeyCodeMap.VK_ESCAPE, KeyCodeMap.VK_TAB, KeyCodeMap.VK_ENTER].includes(e.keyCode)) {
      this.doPopup(false);
    }
  };

  handleClose = () => {
    this.doPopup(false);
  };

  handleMouseDown = (e: React.MouseEvent) => {
    const { editOption, disabbleArrow } = this.props;
    if (disabbleArrow) {
      return;
    }
    if (editOption && editOption.enabled && this.buttonDom.current) {
      const { left, top, right, bottom } = this.buttonDom.current.getBoundingClientRect();

      const { pageX: x, pageY: y } = e;
      if (!(x > left && x < right && y > top && y < bottom)) {
        this.setState({ editing: true, popup: false });
        return;
      }
    }
    PopupManager.manager.locked();
    if (this.props.data?.length) {
      this.doPopup(!this.state.popup);
    }
    if (!this.state.popup) {
      const dom = this.selfRef.current;
      if (dom) {
        this.setState({ dropDownWidth: Math.max(this.props.dropDownMaxWidth || 0, dom.getBoundingClientRect().width) });
      }
    }
  };

  handleDropMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { onDropMouseDown } = this.props;
    onDropMouseDown && onDropMouseDown(e);
  };

  // eslint-disable-next-line no-unused-vars
  handleInputEnter = () => {};

  handleInputBlur = (value: string | number) => {
    // 上面通过监听window的事件临时处理
    // this.setState({ editing: false });
    this.setState({ editing: false }, () => {
      const { onInputComplete } = this.props;
      if (onInputComplete) {
        const { editOption } = this.props;
        let text = value;
        if (editOption!.editModel === 'number') {
          let { min, max } = editOption!;
          if (min && max) {
            min = Math.min(min, max);
            max = Math.max(editOption!.max!, editOption!.min!);
          }
          if (min) {
            text = Math.max(min, text as number);
          }
          if (max) {
            text = Math.min(max, text as number);
          }
        }
        onInputComplete(text);
      }
    });
  };

  renderDropDown = () => {
    const { popup, selectedIndex, dropDownWidth } = this.state;
    const {
      data,
      itemRender,
      maxRowCount,
      dropDownClassName,
      itemHeight,
      isInPopup,
      placement,
      theme,
      dropDownAllowHover,
    } = this.props;
    if (!data || !data.length) {
      return null;
    }
    if (!popup) {
      return null;
    }
    return (
      <DropDown
        className={classnames('select-drop', dropDownClassName, {
          'in-dialog': isInPopup,
          'light-drop': theme === 'light',
        })}
        listClassName="drop-down-list"
        selectedIndex={selectedIndex}
        selectDom={this.selfRef}
        data={data}
        theme={theme === 'light' ? ComponentTheme.light : ComponentTheme.dark}
        placement={placement}
        maxWidth={dropDownWidth}
        maxRowCount={maxRowCount}
        itemHeight={itemHeight}
        itemRender={itemRender}
        allowHover={dropDownAllowHover}
        onSelect={this.handleSelect}
        onClose={this.handleClose}
        onMouseDown={this.handleDropMouseDown}
      />
    );
  };

  renderSelected = (data: IListItem | null) => {
    const { render, value, placeholder, multiple } = this.props;
    if (multiple) {
      return '—';
    }
    if (render) {
      return render(data, value);
    }
    const _value = multiple ? '—' : data?.text || value;
    const displayValue = _value || placeholder;
    return <label className={classnames({ placeholder: !_value })}>{displayValue}</label>;
  };

  renderNumberInput(value?: number) {
    const { editOption, onInputKeyUp } = this.props;
    return (
      <InputNumber
        autoFocus
        autoSelectWhenFocus
        value={value}
        className="input"
        min={editOption!.min}
        max={editOption!.max}
        onEnter={this.handleInputEnter}
        onBlur={this.handleInputBlur}
        onKeyUp={onInputKeyUp}
      />
    );
  }

  renderStringInput(value?: string) {
    const { onInputKeyUp } = this.props;
    return (
      <Input
        autoFocus
        autoSelectWhenFocus
        value={value}
        className="input"
        onEnter={this.handleInputEnter}
        onBlur={this.handleInputBlur}
        onKeyUp={onInputKeyUp}
      />
    );
  }

  renderInput(selected: IListItem | null) {
    const { editOption, value } = this.props;
    if (editOption && editOption.enabled) {
      const { editModel } = editOption;
      if (editModel === 'number') {
        return this.renderNumberInput(selected ? parseFloat(selected.text) : value ? parseFloat(value) : undefined);
      }
      return this.renderStringInput(selected ? selected.text : value);
    }
    return this.renderSelected(selected);
  }

  render() {
    const {
      data,
      width,
      disabled,
      className,
      buttonRender,
      theme,
      hiddenArrow,
      disabbleArrow,
      titleRender,
    } = this.props;
    const { selectedIndex, editing } = this.state;
    const index = selectedIndex !== undefined ? selectedIndex : -1;
    const selected = data ? (index > -1 && index < data.length ? data[index] : null) : null;
    return (
      <React.Fragment>
        <div
          style={{ width }}
          className={classnames(`dsm-c-rp-select ${className || ''}`, {
            disabled,
            'no-border': theme === 'no-border',
            light: theme === 'light',
            'hidden-button': hiddenArrow,
          })}
          onKeyDown={this.handleKeyDown}
          onMouseDown={this.handleMouseDown}
          ref={this.selfRef}
          tabIndex={-1}
        >
          {titleRender ? (
            titleRender()
          ) : (
            <div className="select-value">{editing ? this.renderInput(selected) : this.renderSelected(selected)}</div>
          )}
          {!hiddenArrow && (
            <div className="button" ref={this.buttonDom}>
              {buttonRender && buttonRender()}
              {!buttonRender && <Icon cls="tag_downarrow" disabled={disabbleArrow} />}
            </div>
          )}
        </div>
        {this.renderDropDown()}
      </React.Fragment>
    );
  }
}

export default Select;
