/**
 * 时间选择器类型
 */
export enum ETimePickerType {
  Normal = 'normal', // 普通时间选择器
  Range = 'range', // 时间范围选择器
}

/**
 * 时间格式
 */
export enum ETimeFormat {
  HH_mm = 'HH:mm', // 时：分
  HH_mm_ss = 'HH:mm:ss', // 时：分：秒
}

/**
 * 日期选择器类型
 */
export enum EDatePickerType {
  Normal = 'normal', // 普通的日期选择器
  Range = 'range', // 日期范围选择器
}

/**
 * 日期格式
 */
export enum EDateFormat {
  YYYY_MM_DD = 'YYYY-MM-DD', // 年-月-日，根据语言不同，实际显示结果顺序不同，数据上统一用这一种
  MM_DD = 'MM-DD', // 月-日
}