import { cloneNode } from './cloneNode';
import { embedFonts } from './fontFaces';
import { makeImage } from '../image';
import { inlineImages } from './images';
import { IToImageOptions } from '../type';
import { canvasToBlob, delay, escapeXhtml, getSize, svgToDataUri } from '../util';

export function toSvg(node: Element, options?: IToImageOptions): Promise<string | undefined> {
  console.time('toSvg');
  console.time('cloneNode');
  //   const parent = node.parentElement;
  return Promise.resolve(node)
    .then((node) => {
      return cloneNode({
        ...(options ?? {}),
        node,
        isRoot: true,
        filter: options?.filter,
        replacer: options?.replacer,
        copyStyleRuler: options?.copyStyleRuler,
      });
    })
    .then((node) => {
      if (node) {
        console.timeEnd('cloneNode');
        return embedFonts(node);
      }
    })
    .then((node) => {
      if (node) {
        return inlineImages(node);
      }
    })
    .then((clone) => {
      if (clone) {
        const { width, height } = getSize(options || {}, node);
        return makeSvgDataUri(clone, width, height);
      }
    });
}

export function toPng(node: Element, options?: IToImageOptions): Promise<string> {
  return draw(node, options).then(function (canvas) {
    return canvas.toDataURL();
  });
}

export function toBlob(node: Element, options?: IToImageOptions): Promise<Blob> {
  return draw(node, options).then(canvasToBlob);
}

function draw(domNode: Element, options?: IToImageOptions) {
  return toSvg(domNode, options)
    .then((uri) => {
      if (uri) {
        return makeImage(uri);
      }
    })
    .then(delay(100))
    .then((image) => {
      console.time('drawImage');
      const canvas: HTMLCanvasElement = newCanvas(domNode);
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(image as CanvasImageSource, 0, 0);
      }
      console.timeEnd('drawImage');
      return canvas;
    });

  function newCanvas(domNode: Element): HTMLCanvasElement {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const { width, height } = getSize(options || {}, domNode);
    canvas.width = width;
    canvas.height = height;

    if (options?.bgcolor) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.fillStyle = options.bgcolor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }
    }

    return canvas;
  }
}

function makeSvg(node: Element, width: number, height: number): Promise<string> {
  return Promise.resolve(node)
    .then((node) => {
      node.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
      return new XMLSerializer().serializeToString(node);
    })
    .then(escapeXhtml)
    .then((xhtml) => {
      return '<foreignObject x="0" y="0" width="100%" height="100%">' + xhtml + '</foreignObject>';
    })
    .then((foreignObject) => {
      return (
        '<svg xmlns="http://www.w3.org/2000/svg" width="' +
        width +
        '" height="' +
        height +
        '">' +
        foreignObject +
        '</svg>'
      );
    });
}

function makeSvgDataUri(node: Element, width: number, height: number): Promise<string> {
  console.time('makeSvgDataUri');
  return makeSvg(node, width, height).then((svg) => {
    console.timeEnd('makeSvgDataUri');
    console.timeEnd('toSvg');
    console.log('svg size:' + (svg.length / 1024).toFixed(2) + 'kb');
    return svgToDataUri(svg);
  });
}
