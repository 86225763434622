import * as React from 'react';
import { IComponentData } from '@fbs/rp/models/component';

import i18n from '@i18n';
import { UIComponent } from '@editor/comps';

import { getNewID } from '@helpers/idHelper';

import Icon, { makeIcon, config as iconConfig } from './Icon';
import Image from './Image';
import Snapshot, { makeSnapshot } from './Snapshot';
import Video, { makeVideo } from './Video';
import Audio, { makeAudio } from './Audio';
import Line from './Line';
import PathItem from './Path/PathItem';
import CompoundPath from './Path/CompoundPath';
import Polygon, { makeTriangle } from './Polygon';
import Text, { makeText, config as textConfig } from './NewText';
import Input, { defaultInputSize } from './Input';
import TextArea from './TextArea';

import PureText from './PureText';
import SymbolGroup from './Symbol';

import CanvasPanel, { makeCanvas } from '../containers/Canvas';
import Frame, { makeFrame } from '../containers/Frame';
import StackPanel, { makeStackPanel } from '../containers/Stack';
import ListLayoutPanel, { makeListLayoutPanel } from '../containers/ListLayoutPanel';
import WrapPanel, { makeWrapPanel } from '../containers/Wrap';
import GridPanel, { makeGridPanel } from '../containers/Grid';

import ContentPanelV2, { makeContentPanelV2 } from '../containers/ContentPanelV2';
import ContentPanel from '../containers/ContentPanel';
import Group from '../containers/Group';
import SelectPanel, { makeSelectPanel } from '../containers/SelectPanel';

import { makeSelect } from '../compoundComponents/SelectBox/config';
import { makeSlider } from '../compoundComponents/Slider/config';

import { makeQRCode } from '../compoundComponents/QRCodeComp/config';
import { makeNumericStep } from '../compoundComponents/Numeric/config';
import { makeKeyboard } from '../compoundComponents/Keyboard/config';
import { makeTable } from '../compoundComponents/Table/config';
import { makeTree } from '../compoundComponents/Tree/config';
import { makeDatePicker } from '../compoundComponents/DatePicker/config';
import { makeTimePicker } from '../compoundComponents/TimerPicker/config';
import { makeBarChart } from '../ChartComponents/SeriesChartOne/BarChart/config';
import { makePieChart } from '../ChartComponents/SeriesChartTwo/PieChart/config';
import { makeLineChart } from '../ChartComponents/SeriesChartOne/LineChart/config';
import { makeDoughnutChartChart } from '../ChartComponents/SeriesChartTwo/DoughnutChart/config';
import { makeBarChartHorizontal } from '../ChartComponents/SeriesChartOne/CBarChartHorizontal/config';
import { makeAreaChart } from '../ChartComponents/SeriesChartOne/AreaChart/config';
import { makeRadarChart } from '../ChartComponents/SeriesChartOne/RadarChart/config';
import { makeNavigationMenu } from '../compoundComponents/NavigationMenu/config';
import { makeHorizontalMenu } from '../compoundComponents/HorizontalMenu/config';
import { makeVerticalMenu } from '../compoundComponents/VerticalMenu/config';

import Connector, { ConnectorCfg } from './Connector';
import { Arc } from './Shape';

import SimpleShape from '../simpleBasic/Shape';

import { CompoundComponentClass } from '../compoundComponents/lib';
import { makeScatterChart } from '../ChartComponents/SeriesChartOne/Scatter/config';
import { makeMultipleSelect } from '../compoundComponents/MultipleSelect/config';
import { makeImageTextTabs } from '../compoundComponents/ImageTextTabs/config';
import { makeCarouselChart } from '../compoundComponents/CarouselChart/config';
import { makeCombinationChart } from '../ChartComponents/SeriesChartOne/CombinationChart/config';

import {
  CInput,
  CRect,
  CEllipse,
  CIcon,
  CImage,
  CSnapshot,
  CVideo,
  CLine,
  CPath,
  CCompoundPath,
  CPolygon,
  CText,
  CTextArea,
  CParagraph,
  CCanvasPanel,
  CStackPanel,
  CFrame,
  CListLayoutPanel,
  CWrapPanel,
  CGridPanel,
  CContentPanel,
  CContentPanelV2,
  CSelectPanel,
  CGroup,
  CAudio,
  CConnector,
  CArc,
  CPureText,
  CSymbol,
  CTreeItem,
  CSlider,
  CSelect,
  CNumericStep,
  CTable,
  CTree,
  CNavigationMenu,
  CHorizontalMenu,
  CVerticalMenu,
  CQRCode,
  CKeyboard,
  CDatePicker,
  CTimePicker,
  CBarChart,
  CPieChart,
  CDoughnutChart,
  CLineChart,
  CBarChartHorizontal,
  CAreaChart,
  CRadarChart,
  CScatterChart,
  CMultipleSelect,
  CImageTextTabs,
  CCarouselChart,
  CCombinationChart,
  CStickNote,
  CGaugeChart,
  CMapChart,
  CRectButton,
  CCollapse,
  CVerticalTabs,
} from '../constants';
import { AtomUIComponentClass, CompositeUIComponentClass, IComponentBasicLib } from '../types';
import { rectConfig, makeRect } from './Rect/config';
import { ellipseConfig, makeEllipse } from './Ellipse/config';
import { polygonConfig, makePolygon } from './Polygon/config';
import { lineConfig, makeLine } from './Line/config';
import { imageConfig, makeImage } from './Image/config';
import { inputConfig, makeInput } from './Input/config';
import { textAreaConfig, makeTextArea } from './TextArea/config';
import { makePathItem, pathConfig, compoundPathConfig } from './Path/config';
import { makeContentPanel } from '../containers/ContentPanel/config';
import { makeStickNote } from '../comment/StickNote/config';
import { makeGaugeChart } from '../ChartComponents/GaugeChart/config';
import { makeMapChart } from '../ChartComponents/MapChart/config';
import { makeCollapse } from '../compoundComponents/Collapse/config';
import { RectButtonConfig, makeRectButton } from '../compoundComponents/Button/rectButtonConfig';
import { ButtonCfg } from '../compoundComponents/Button/config';
import { makeVerticalTabs } from '../compoundComponents/VerticalTabs/config';

export const DefaultBasicComponentsSize: {
  [type: string]: { [appType: string]: { width: number; height: number } };
} = {
  [CInput]: defaultInputSize,
};

let AllBasicComponents:
  | {
      [name: string]: AtomUIComponentClass | CompositeUIComponentClass;
    }
  | undefined = undefined;

function getBasicComponents(type: string): AtomUIComponentClass | CompositeUIComponentClass {
  if (!AllBasicComponents) {
    AllBasicComponents = {
      // [CRect]: Rect,
      [CRect]: SimpleShape,
      // [CEllipse]: Ellipse,
      [CEllipse]: SimpleShape,
      [CIcon]: Icon,
      [CImage]: Image,
      [CSnapshot]: Snapshot,
      [CVideo]: Video,
      [CLine]: Line,
      [CPath]: PathItem,
      [CCompoundPath]: CompoundPath,
      [CPolygon]: Polygon,
      [CText]: Text,
      [CTextArea]: TextArea,
      [CParagraph]: Text,
      [CInput]: Input,
      [CCanvasPanel]: CanvasPanel,
      [CStackPanel]: StackPanel,
      [CFrame]: Frame,
      [CListLayoutPanel]: ListLayoutPanel,
      [CWrapPanel]: WrapPanel,
      [CGridPanel]: GridPanel,
      [CContentPanel]: ContentPanel,
      [CContentPanelV2]: ContentPanelV2,
      [CSelectPanel]: SelectPanel,
      // [CSlider]: Slider,
      [CGroup]: Group,
      // [CSelect]: SelectBox,
      // [CQRCode]: QRCode,
      // [CNumericStep]: NumericStep,
      // [CKeyboard]: KeyboardComp,
      [CAudio]: Audio,
      [CConnector]: Connector,
      [CArc]: Arc,
      [CPureText]: PureText,
      [CSymbol]: SymbolGroup,
      // [CTable]: Table,
      // [CTree]: Tree,
      [CTreeItem]: CanvasPanel,
    };
  }
  return AllBasicComponents[type];
}

function isNeedReRenderWhenEdit(comp: UIComponent) {
  let needReRender = true;
  if (comp.type !== CTable && ([CRect, CParagraph, CText, CPureText].includes(comp.type) || comp.isSealed)) {
    let moving = comp.isMoving;
    //移动过程中,组件的形状内容等没有变. 只有 position 变化了,所以Rect,text没有必要重新渲染
    needReRender = !moving;
  }
  if (comp.isConnector) {
    needReRender = true;
  }
  return needReRender;
}

type NormalUIComponentCreator = (id: string) => IComponentData;
type PresetValueUIComponentCreate = (id: string, value: any) => IComponentData;
type UIComponentCreator = NormalUIComponentCreator | PresetValueUIComponentCreate;

const makeConfig: { [key: string]: UIComponentCreator } = {
  [CRectButton]: makeRectButton,
  [CRect]: makeRect,
  [CEllipse]: makeEllipse,
  [CIcon]: makeIcon,
  [CImage]: makeImage,
  [CSnapshot]: makeSnapshot,
  [CVideo]: makeVideo,
  [CLine]: makeLine,
  [CPolygon]: makePolygon,
  [CText]: makeText,
  [CParagraph]: makeText,
  triangle: makeTriangle,
  [CInput]: makeInput,
  [CTextArea]: makeTextArea,
  [CPath]: makePathItem,
  [CAudio]: makeAudio,

  [CCanvasPanel]: makeCanvas,
  [CStackPanel]: makeStackPanel,
  [CFrame]: makeFrame,
  [CWrapPanel]: makeWrapPanel,
  [CContentPanel]: makeContentPanel,
  [CContentPanelV2]: makeContentPanelV2,
  [CGridPanel]: makeGridPanel,
  [CListLayoutPanel]: makeListLayoutPanel,
  [CSelectPanel]: makeSelectPanel,

  [CSlider]: makeSlider,
  [CSelect]: makeSelect,
  [CNumericStep]: makeNumericStep,
  [CTable]: makeTable,
  [CTree]: makeTree,
  [CNavigationMenu]: makeNavigationMenu,
  [CHorizontalMenu]: makeHorizontalMenu,
  [CVerticalMenu]: makeVerticalMenu,

  [CQRCode]: makeQRCode,
  [CKeyboard]: makeKeyboard,
  [CDatePicker]: makeDatePicker,
  [CTimePicker]: makeTimePicker,
  [CBarChart]: makeBarChart,
  [CPieChart]: makePieChart,
  [CDoughnutChart]: makeDoughnutChartChart,
  [CLineChart]: makeLineChart,
  [CBarChartHorizontal]: makeBarChartHorizontal,
  [CAreaChart]: makeAreaChart,
  [CRadarChart]: makeRadarChart,
  [CScatterChart]: makeScatterChart,
  [CCombinationChart]: makeCombinationChart,
  [CGaugeChart]: makeGaugeChart,
  [CMapChart]: makeMapChart,

  [CMultipleSelect]: makeMultipleSelect,
  [CImageTextTabs]: makeImageTextTabs,
  [CVerticalTabs]: makeVerticalTabs,
  [CCarouselChart]: makeCarouselChart,

  [CStickNote]: makeStickNote,
  [CCollapse]: makeCollapse,
};

/**
 * RectButtonConfig 新矩形按钮
 * ButtonCfg 原复合按钮
 */
const BasicComponentLib: IComponentBasicLib = {
  id: 'basic',
  name: i18n('resource.components.basic'),
  components: [
    rectConfig,
    ellipseConfig,
    polygonConfig,
    lineConfig,
    textConfig,
    imageConfig,
    RectButtonConfig,
    ButtonCfg,
    inputConfig,
    textAreaConfig,
    pathConfig,
    iconConfig,
    ConnectorCfg,
    compoundPathConfig,
  ],

  make(type: string, value: any): IComponentData {
    const id = getNewID();

    const creator: UIComponentCreator = makeConfig[type];
    if (creator) {
      const len = creator.length;
      if (len === 1) {
        return (creator as NormalUIComponentCreator)(id);
      }
      return creator(id, value);
    }
    return makeText(id, `${type} is not supported.`);
  },

  render(comp: UIComponent, options, children: any): React.ReactNode {
    const { type, lib, id } = comp;
    let ComponentClass: React.ReactType | undefined;
    if (lib?.type) {
      ComponentClass = CompoundComponentClass[lib.type] || CompoundComponentClass[type];
    }
    if (!ComponentClass) {
      ComponentClass = getBasicComponents(type);
    }
    if (!ComponentClass) {
      ComponentClass = CompoundComponentClass[type];
    }
    if (!ComponentClass) {
      window.debug && console.error(`${type} is missed`);
      return `${type} is missed.`;
    }
    const needReRender = isNeedReRenderWhenEdit(comp);
    const { isStandalone } = options;
    const Component = (
      <ComponentClass key={id} comp={comp} {...options}>
        {children}
      </ComponentClass>
    );
    if (isStandalone) {
      return Component;
    }
    return <CompWrapper needReRender={needReRender}>{Component}</CompWrapper>;
  },
};

interface IComponentWrapperProps {
  needReRender: boolean;
  children: React.ReactNode;
}

class CompWrapper extends React.Component<IComponentWrapperProps> {
  shouldComponentUpdate(nextProps: IComponentWrapperProps) {
    const { needReRender } = nextProps;
    // 移动的过程中,元素本身的样子不会改变,位置信息是由上一层的 libs/comp 来确定的
    return needReRender;
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export default BasicComponentLib;
