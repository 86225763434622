export let currentEnv: MockplusEnv;
export enum MockplusEnv {
  Development = 'development',
  Test = 'test',
  EnTest = 'EnTest',
  Demo = 'demo',
  EnDemo = 'EnDemo',
  Production = 'production',
  EnProduction = 'EnProduction',
}
declare global {
  interface Window {
    env: MockplusEnv; // 桌面端可能会注入
  }
}
type Config = {
  loginUrl: string; //登录地址
  signupUrl: string; // 注册地址
  apicc: string; // 登录检查API前缀
  apirp: string; //rp 查询用户团队
};

const devConfig = {
  loginUrl: 'http://192.168.0.152:4004/plugin-signin/:code?type=rp',
  signupUrl: 'http://192.168.0.152:4004/signup',
  apicc: 'http://192.168.0.152:5006/api/v1',
  apirp: 'http://192.168.0.152:7006/api/v1',
};
const prodConfig = {
  loginUrl: 'https://user.mockplus.cn/plugin-signin/:code?type=rp',
  signupUrl: 'https://user.mockplus.cn/signup',
  apicc: `https://app.mockplus.cn/api/v1`,
  apirp: `${window.desktopOrigin}/api/v1`,
};
const EnProdConfig = {
  loginUrl: 'https://user.mockplus.com/plugin-signin/:code?type=rp',
  signupUrl: 'https://user.mockplus.com/signup',
  apicc: `https://app.mockplus.com/api/v1`,
  apirp: `${window.desktopOrigin}/api/v1`,
};
const testConfig = {
  loginUrl: 'http://192.168.0.152:4004/plugin-signin/:code?type=rp',
  signupUrl: 'http://192.168.0.152:4004/signup',
  apicc: `http://192.168.0.152:5006/api/v1`,
  apirp: `${window.desktopOrigin}/api/v1`,
};
const demoConfig = {
  loginUrl: 'https://user.mockplus.cn/plugin-signin/:code?type=rp',
  signupUrl: 'https://user.mockplus.cn/signup',
  apicc: `https://app.mockplus.cn/api/v1`,
  apirp: `${window.desktopOrigin}/api/v1`,
};
const EnDemoConfig = {
  loginUrl: 'https://user.mockplus.com/plugin-signin/:code?type=rp',
  signupUrl: 'https://user.mockplus.com/signup',
  apicc: `https://app.mockplus.com/api/v1`,
  apirp: `${window.desktopOrigin}/api/v1`,
};
export let config: Config = devConfig;

export const setConfig = (env: MockplusEnv) => {
  currentEnv = env;
  if (env === MockplusEnv.Production) {
    config = prodConfig;
  } else if (env === MockplusEnv.EnProduction) {
    config = EnProdConfig;
  } else if (env === MockplusEnv.Test || env === MockplusEnv.EnTest) {
    config = testConfig;
  } else if (env === MockplusEnv.Demo) {
    config = demoConfig;
  } else if (env === MockplusEnv.EnDemo) {
    config = EnDemoConfig;
  } else {
    config = devConfig;
  }
};
