import lightIconFont from '@libs/icons/lightIconFont';
import boldIconFont from '@libs/icons/boldIconFont';
import { IconValue } from '@/fbs/rp/models/value';
import { IconFontName, IconType } from '@/libs/enum';

interface IIconMap {
  [lightKey: string]: number;
}

const generateIconCodeMap = ((): IIconMap => {
  const iconCodeMap: IIconMap = {};
  const lightIcons = lightIconFont.icons;
  const boldIcons = boldIconFont.icons;

  for (let i = 0; i < lightIcons.length; i++) {
    const lightIconItems = lightIcons[i].items;
    const boldIconItems = boldIcons[i].items;
    for (let j = 0; j < lightIconItems.length; j++) {
      iconCodeMap[`l-${lightIconItems[j].iconCode}`] = boldIconItems[j].iconCode;
      iconCodeMap[`b-${boldIconItems[j].iconCode}`] = lightIconItems[j].iconCode;
    }
  }

  return iconCodeMap;
})();

export const resetIconValue = (value: IconValue, originFontName: string) => {
  if (value.fontName === originFontName) {
    return value;
  }
  if (originFontName === IconFontName[IconType.bold]) {
    return {
      iconCode: iconMap[`l-${value.iconCode}`],
      fontName: originFontName,
    };
  } else {
    return {
      fontName: originFontName,
      iconCode: iconMap[`b-${value.iconCode}`],
    };
  }
};

export const iconMap = generateIconCodeMap;
