import * as React from 'react';

import { IRange } from '@fbs/rp/models/properties/base';

import { UIContainerComponent, UIComponent } from '@editor/comps';
import { StyleHelper } from '@helpers/styleHelper';
import initSVGStyleParser = StyleHelper.initSVGStyleParser;
import getOpacity = StyleHelper.getOpacity;

import { IComponentProps } from '../../types';

import './index.scss';

function calcIndicatorPath(comp: UIComponent) {
  const {
    size: { width, height },
    properties,
  } = comp;

  const center = {
    x: width / 2,
    y: height / 2,
  };

  const { angle, close } = properties;
  const parser = StyleHelper.initSVGStyleParser(properties);
  const { strokeWidth, stroke } = parser.getStroke();
  const thickness = stroke === 'none' ? 0 : strokeWidth || 0;
  const radius = (Math.min(width, height) - thickness) / 2;
  const range = angle!.value as IRange;
  let value = range.value;
  value = Math.max(0, Math.min(value, 100));
  value = 3.6 * value - 90;
  const rad = (value / 180) * Math.PI;
  const xr = center.x + radius * Math.cos(rad);
  const yr = center.y + radius * Math.sin(rad);
  const largeArcFlag = value < 90 ? 0 : 1;
  let data = '';
  if (value + 90 === 360) {
    data = `M${center.x} ${center.y - radius} A${radius} ${radius} ${radius} 0 1 ${center.x + radius} ${
      center.y
    }  A${radius} ${radius} ${radius} 0 1 ${center.x} ${center.y + radius} A${radius} ${radius} ${radius} 0 1 ${
      center.x - radius
    } ${center.y} A${radius} ${radius} ${radius} 0 1 ${center.x} ${center.y - radius}`;
  } else {
    data = `M${center.x} ${center.y - radius} A${radius} ${radius} ${radius} ${largeArcFlag} 1 ${xr} ${yr}`;
    if (close && close!.value) {
      data = `${data} L${center.x} ${center.y}Z`;
    }
  }
  return data;
}

const CircleProgressBar: React.FC<IComponentProps> = (props: IComponentProps) => {
  const container = props.comp as UIContainerComponent;
  const {
    size: { width, height },
    opacity,
    components,
  } = container;
  const track = container.getComponentByAlias('track') || components[0];
  const indicator = container.getComponentByAlias('progressBar') || components[1];
  const svgParser = initSVGStyleParser(track.properties);
  const style = {
    filter: svgParser.getShadow(),
    opacity: getOpacity(opacity),
  };
  const { stroke, strokeDasharray, strokeWidth } = svgParser.getStroke();
  const indicatorParser = initSVGStyleParser(indicator.properties);
  const { stroke: indicatorStroke, strokeDasharray: dashArray } = indicatorParser.getStroke();
  const pathData = calcIndicatorPath(indicator);
  const w = width - (strokeWidth || 0);
  return (
    <svg
      className="lib-comp-circle-progreaa"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={style}
    >
      <circle
        r={w / 2}
        cx={width / 2}
        cy={height / 2}
        fill="none"
        strokeLinecap="round"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
        strokeOpacity={StyleHelper.getOpacity(track.opacity)}
      />
      <path
        d={pathData}
        fill="none"
        strokeLinecap="round"
        stroke={indicatorStroke}
        strokeWidth={strokeWidth}
        strokeDasharray={dashArray}
        strokeOpacity={StyleHelper.getOpacity(indicator.opacity)}
      />
    </svg>
  );
};

export default CircleProgressBar;
