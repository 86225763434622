import * as React from 'react';
import { connect } from 'react-redux';

import * as _ from 'lodash';

import i18n from '@i18n';

import {
  builderTreeItemData,
  Button,
  cloneTreeItemData,
  Dialog,
  findNode,
  Icon,
  IListItem,
  Tooltip,
  Tree,
  TreeItemData,
  searchTreeNodes,
  Select,
} from '@dsm';

import { addNewSnapshotPage, getSnapshotPage, getSnapshotProjects, getSnapshotTeams } from '@/apis/team';

import { ISize } from '@fbs/idoc/models/common';
import { IEmbedApp, IEmbedPage, IEmbedTeam, ModuleType } from '@fbs/rp/models/embedDesignDraft';
import { AppTypes } from '@/fbs/idoc/models/app';
import { ISnapshotValue } from '@/fbs/rp/models/value';
import AppOptions from '@helpers/appOptions';
import KeyCodeMap from '@/dsm2/constants/KeyCodeMap';
import { UIComponent } from '@/editor/comps';

import mathUtils from '@utils/mathUtils';
import { dragDelegate } from '@/utils/mouseUtils';
import { isControlKeyPressed } from '@/utils/hotkeysUtils';
import { offsetPoint } from '@/utils/rotateUtils';
import { getPointsBounds, initBoundsWithPositionAndSize, offsetBounds } from '@/utils/boundsUtils';
import { getImageSize } from '@/helpers/fileUploadHelper';
import { Direction } from '@consts/enums/direction';

import { IMainState } from '@/store/webTypes';
import { IAppState } from '@/store/app/reducers';
import { IPoint } from '@/dsm2/models';
import { IBounds } from '@/fbs/common/models/common';
import snapshotManager from '@/managers/snapshotManager';

import ImgPreview from './ImgPreview';
import LeftWrapper from './LeftWrapper';
import SelectorBox from './SelectorBox';

import './index.scss';

export interface ISnapshotEditorProps {
  app: IAppState;
  comp: UIComponent;
  onCancel: () => void;
  onSelectSnapshot?: (updateInfo: ISnapshotValue, imgSize?: { height: number; width: number }, compID?: string) => void;
  onWaiting: (state: boolean) => void;
}

export interface IAreaStyle {
  width: number;
  height: number;
  left: number;
  top: number;
}

export interface ISnapshotEditorState {
  selected?: string;
  url?: string;
  popupTargetAlert?: boolean;
  alertContent?: string;
  selectedTeam: IListItem | null;
  selectedProject: IEmbedApp | null;
  selectedPage: IEmbedPage | null;
  teams: IEmbedTeam[];
  projects: TreeItemData<treeUniteType>[];
  pages: TreeItemData<treeUniteType>[];
  showTeamPanel: boolean;
  showProjectPanel: boolean;
  point: {
    x: number;
    y: number;
  } | null;
  popUpPanelWidth: number;
  popUpPanelHeight: number;
  showCropArea: boolean;
  isSpaceKey: boolean;

  cropBounds?: IAreaStyle;
  imageBounds?: IAreaStyle;
  scale: number;
  selectedSize: SnapshotSize;
}

export type treeUniteType = IEmbedApp | IEmbedPage;

enum SnapshotSize {
  half = 0.5,
  one = 1,
  two = 2,
  fitComp = 'fitComp',
}

const SCROLL_STEP = 30;
const SCALE_STEP = 0.1;
const MIN_SIZE = 100;
const MAX_SCALE = 4;

const selectorPaddingWidth: number = 32;
const searchBoxHeightInPopPanel: number = 60;
const allowShowRange: number = 8;
const treeItemLineHeight: number = 30;
const imageMinViewDistance: number = 24;
const viewPortSize: { width: number; height: number } = { width: 669, height: 465 };

const sizeSelectorData = [
  {
    id: 0.5,
    text: '0.5x',
  },
  {
    id: 1,
    text: '1x',
  },
  {
    id: 2,
    text: '2x',
  },
  {
    id: 'fitComp',
    text: i18n('resource.componentsText.fitCompText'),
  },
];

class SnapshotEditor extends React.PureComponent<ISnapshotEditorProps, ISnapshotEditorState> {
  private isHuaWei = RP_CONFIGS.isHuaWei;
  private originTeams: IEmbedTeam[] = [];
  private originProjects: TreeItemData<IEmbedApp>[] = [];
  private originPages: TreeItemData<IEmbedPage>[] = [];

  private minScale = 1;
  private snapshotSelectedInfo = AppOptions.snapshotSelectedInfo;
  private originImageSize: {
    width: number;
    height: number;
  } | null = null;
  private echoFlag: boolean = true;

  private imageWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  private imageRef: React.RefObject<HTMLImageElement> = React.createRef();
  private cropRef: React.RefObject<HTMLDivElement> = React.createRef();
  private pageTree: React.RefObject<Tree<IEmbedPage>> = React.createRef();
  private projectTree: React.RefObject<Tree<IEmbedApp>> = React.createRef();

  constructor(props: ISnapshotEditorProps) {
    super(props);
    this.state = {
      url: undefined,
      selectedTeam: null,
      selectedProject: null,
      selectedPage: null,
      teams: [],
      projects: [],
      pages: [],
      showTeamPanel: false,
      showProjectPanel: false,
      point: null,
      popUpPanelWidth: 0,
      popUpPanelHeight: 0,
      showCropArea: false,
      isSpaceKey: false,
      scale: 1,
      imageBounds: { left: 0, top: 0, width: 0, height: 0 },
      selectedSize: AppOptions.snapshotSizeSelectedInfo,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleWindowKeyDown);
    document.addEventListener('keyup', this.handleWindowKeyUp);
    this.doLoadDataByComp();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleWindowKeyDown);
    document.removeEventListener('keyup', this.handleWindowKeyUp);
  }

  private doLoadDataByComp = () => {
    const { app, comp } = this.props;
    const { source: selectedParams } = comp.value as ISnapshotValue;

    if (!this.isHuaWei) {
      this.getSnapshotProjectsData(selectedParams ? selectedParams.teamID : app.teamInfo?.id!);
      return;
    }
    this.getSnapshotTeamsData();
  };

  private getSnapshotTeamsData = () => {
    const { app, comp, onWaiting } = this.props;
    const { source: selectedParams } = comp.value as ISnapshotValue;
    onWaiting(true);
    getSnapshotTeams()
      .then((res) => {
        this.originTeams = res;
        let currTeamID: number | string = '';

        if (selectedParams) {
          currTeamID = selectedParams.teamID!;
        } else if (this.snapshotSelectedInfo.teamID) {
          // 内存中记忆信息
          currTeamID = this.snapshotSelectedInfo.teamID;
        } else {
          currTeamID = app.teamInfo?.id!;
        }

        const selectedTeam = this.originTeams.find((team) => team.id === currTeamID);
        this.setState(
          {
            teams: this.originTeams,
            selectedTeam: selectedTeam
              ? {
                  id: selectedTeam.id,
                  text: selectedTeam.name,
                }
              : null,
          },
          () => {
            if (selectedTeam) {
              this.getSnapshotProjectsData(selectedTeam.id);
            } else {
              onWaiting(false);
            }
          },
        );
      })
      .catch((err) => {
        window.debug && console.log(err);
        onWaiting(false);
      });
  };

  private getSnapshotProjectsData = (teamID?: string | number) => {
    const { app, comp, onWaiting } = this.props;
    const { source: selectedParams } = comp.value as ISnapshotValue;
    onWaiting(true);
    getSnapshotProjects(teamID as string)
      .then((res) => {
        this.originProjects = builderTreeItemData<IEmbedApp>(res, (project) => {
          return {
            data: project,
            expand: true,
            isLeaf: !project.children?.length,
            selected: project._id === selectedParams?.appID,
            children: project.children,
            marked: false,
            matched: false,
            editing: false,
          };
        });

        let currProjectID: number | string = '';

        if (selectedParams) {
          currProjectID = selectedParams.appID!;
        } else if (this.snapshotSelectedInfo.projectID) {
          // 内存中记忆信息
          currProjectID = this.snapshotSelectedInfo.projectID;
        } else {
          currProjectID = app.appID;
        }
        const selectedProject = findNode(this.originProjects, (project) => project.data._id === currProjectID)?.data!;

        this.setState(
          {
            projects: this.originProjects,
            selectedProject,
          },
          () => {
            if (selectedProject) {
              this.projectTree.current?.scrollToNode(selectedProject);
              this.getSnapshotPageData(selectedProject._id);
            } else {
              onWaiting(false);
            }
          },
        );
      })
      .catch((err) => {
        window.debug && console.log(err);
        onWaiting(false);
      });
  };

  doGetOriginImageSize = (url: string) => {
    const { comp } = this.props;
    const { cropAreaInfo } = comp.value as ISnapshotValue;

    getImageSize(url)
      .then(({ width: imageWidth, height: imageHeight }) => {
        const { width: boxWidth, height: boxHeight } = viewPortSize!;
        const pageWidth = imageWidth || 1;
        const pageHeight = imageHeight || 1;
        const scale = Math.min(boxWidth / pageWidth, boxHeight / pageHeight);
        const width = Math.round(pageWidth * scale);
        const height = Math.round(pageHeight * scale);
        const left = Math.round((boxWidth - width) / 2);
        const top = Math.round((boxHeight - height) / 2);
        this.minScale = mathUtils.max(scale, MIN_SIZE / mathUtils.max(boxHeight, boxWidth));
        this.originImageSize = {
          width: imageWidth,
          height: imageHeight,
        };

        if (cropAreaInfo && Object.keys(cropAreaInfo).length && this.echoFlag) {
          this.echoFlag = false;
          const { width: cropWidth, height: cropHeight, left: cropLeft, top: cropTop } = cropAreaInfo;
          this.setState({
            showCropArea: true,
            cropBounds: {
              width: cropWidth / imageWidth,
              height: cropHeight / imageHeight,
              left: cropLeft / imageWidth,
              top: cropTop / imageHeight,
            },
          });
        }

        this.setState({
          imageBounds: { left, top, width, height },
          scale,
        });
      })
      .catch((err) => {
        window.debug && console.log(err);
      });
  };

  private getSnapshotPageData = (appID: string, moduleType?: string) => {
    const { comp, onWaiting } = this.props;
    const { url, source } = comp.value as ISnapshotValue;
    const sourceUrl = snapshotManager.getURLByPageID(source?.pageID);
    const finalUrl = sourceUrl ?? url;

    onWaiting(true);
    this.doGetOriginImageSize(finalUrl);

    getSnapshotPage(appID, moduleType)
      .then((res) => {
        onWaiting(false);
        this.originPages = builderTreeItemData<IEmbedPage>(res, (page) => {
          return {
            data: page,
            expand: true,
            isLeaf: !page.children?.length,
            selected: page.id === source?.pageID,
            children: page.children,
            marked: false,
            matched: false,
            editing: false,
          };
        });

        let currPageID: number | string = '';

        if (source?.pageID) {
          currPageID = source?.pageID;
        } else if (this.snapshotSelectedInfo?.pageID) {
          // 内存中记忆信息
          currPageID = this.snapshotSelectedInfo.pageID;
        }

        const selectedPage = findNode(this.originPages, (page) => page.data.id === currPageID)?.data!;
        this.setState(
          {
            pages: this.originPages,
            url: selectedPage?.imageURL,
            selectedPage,
          },
          () => {
            if (selectedPage) {
              this.pageTree.current?.scrollToNode(selectedPage);
            }
          },
        );
      })
      .catch((err) => {
        onWaiting(false);
        window.debug && console.log(err);
      });
  };

  private addNewSnapshotPageData = (appID: string, pageID: string, moduleType?: string) => {
    addNewSnapshotPage(appID, pageID, moduleType)
      .then(() => {
        snapshotManager.loadAllUsedResource(appID).then(() => {
          this.handleCancel();
        });
      })
      .catch((err) => {
        window.debug && console.log(err);
      });
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
  };

  handleOKBtnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.doSubmit();
  };

  handleSubmit = () => {
    this.doSubmit();
  };

  handlePagesSearch = (value: string) => {
    const pages = searchTreeNodes(this.originPages, value.toLowerCase(), (page, searchValue) =>
      page.name.toLowerCase().includes(searchValue),
    );
    this.setState({
      pages: value ? pages : this.originPages,
    });
  };

  handleTeamsSearch = (value: string) => {
    const str = value.toLowerCase();
    const teams = this.originTeams.filter((team) => team.name.toLowerCase().includes(str));
    this.setState({
      teams: value ? teams : this.originTeams,
    });
  };

  handleProjectsSearch = (value: string) => {
    const projects = searchTreeNodes(this.originProjects, value.toLowerCase(), (project, searchValue) =>
      project.name.toLowerCase().includes(searchValue),
    );
    this.setState({
      projects: value ? projects : this.originProjects,
    });
  };

  toggleTeamSelectorPanel = (e: React.MouseEvent) => {
    this.doCalcPopUpPanelPosition(e);
    this.setState({
      showTeamPanel: !this.state.showTeamPanel,
      showProjectPanel: false,
      teams: this.originTeams,
    });
  };

  toggleProjectSelectorPanel = (e: React.MouseEvent) => {
    this.doCalcPopUpPanelPosition(e);
    this.setState({
      showTeamPanel: false,
      showProjectPanel: !this.state.showProjectPanel,
      projects: this.originProjects,
    });
  };

  doCalcPopUpPanelPosition = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();

    this.setState({
      point: {
        x: left + selectorPaddingWidth,
        y: top + height,
      },
      popUpPanelWidth: width - 2 * selectorPaddingWidth,
      popUpPanelHeight: treeItemLineHeight * allowShowRange + searchBoxHeightInPopPanel,
    });
  };

  handleTeamSelect = (team: IListItem) => {
    this.getSnapshotProjectsData(team.id);

    AppOptions.snapshotSelectedInfo = {
      teamID: team.id,
    };

    this.setState({
      selectedTeam: team,
      selectedProject: null,
      showTeamPanel: false,
      pages: [],
      url: '',
    });

    this.doClearCropArea();
  };

  handleProjectTreeItemClick = (e: React.MouseEvent, project: IEmbedApp) => {
    if (project.type === AppTypes.App) {
      this.getSnapshotPageData(project._id);

      AppOptions.snapshotSelectedInfo = {
        ...AppOptions.snapshotSelectedInfo,
        projectID: project._id,
      };

      this.setState({
        selectedProject: project,
        showProjectPanel: false,
        url: '',
      });

      this.doClearCropArea();
    }
  };

  handlePageTreeItemClick = (e: React.MouseEvent, page: IEmbedPage) => {
    if (page.isGroup) {
      return;
    }
    if (!page.imageURL) {
      return;
    }

    this.doGetOriginImageSize(page.imageURL);

    AppOptions.snapshotSelectedInfo = {
      ...AppOptions.snapshotSelectedInfo,
      pageID: page.id,
    };

    const pages = cloneTreeItemData(this.state.pages, (node) => {
      node.selected = node.data === page;
    }) as TreeItemData<IEmbedPage>[];

    this.setState({
      selectedPage: page,
      url: page.imageURL,
      pages,
    });

    this.doClearCropArea();
  };

  private doDragMove() {
    const { imageBounds } = this.state;
    const originBounds = { ...imageBounds! };
    const { width: imageBoxWidth, height: imageBoxHeight } = this.imageWrapperRef?.current?.getBoundingClientRect()!;

    dragDelegate(
      (e, delta) => {
        const { x, y } = delta;
        const newBounds = { ...originBounds };

        newBounds.left = _.clamp(
          newBounds.left + x,
          imageMinViewDistance - newBounds.width,
          imageBoxWidth - imageMinViewDistance,
        );
        newBounds.top = _.clamp(
          newBounds.top + y,
          imageMinViewDistance - newBounds.height,
          imageBoxHeight - imageMinViewDistance,
        );

        this.setState({
          imageBounds: newBounds,
        });
      },
      () => {},
    );
  }

  private restrictPointWithBounds = (point: IPoint, restrictBounds: IBounds) => {
    return {
      x: _.clamp(point.x, restrictBounds.left, restrictBounds.right),
      y: _.clamp(point.y, restrictBounds.top, restrictBounds.bottom),
    };
  };

  private doChooseBounds(e: React.MouseEvent) {
    const point = { x: e.pageX, y: e.pageY };
    const imgBounds = this.imageRef.current!.getBoundingClientRect();
    const { left, top } = imgBounds;
    const { imageBounds } = this.state;
    const { width: imageWidth, height: imageHeight } = imageBounds!;
    const areaBounds = initBoundsWithPositionAndSize({ x: left, y: top }, { width: imageWidth, height: imageHeight });
    const startPoint = this.restrictPointWithBounds(point, areaBounds);

    dragDelegate(
      (e, delta) => {
        const newPoint = offsetPoint(point, delta);
        const bounds = getPointsBounds([startPoint, this.restrictPointWithBounds(newPoint, areaBounds)]);
        const newBounds = offsetBounds(bounds, { left: -left, top: -top });
        this.setState({
          showCropArea: true,
          cropBounds: {
            left: newBounds.left / imageWidth,
            top: newBounds.top / imageHeight,
            width: newBounds.width / imageWidth,
            height: newBounds.height / imageHeight,
          },
        });
      },
      () => {},
    );
  }

  doCalcPercent = (num: number) => {
    return `${parseFloat(`${num}`) * 100}%`;
  };

  handleImgWrapperMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { isSpaceKey, url } = this.state;
    if (!url) {
      return;
    }
    if (isSpaceKey) {
      this.doDragMove();
    } else {
      this.doChooseBounds(e);
    }
  };

  private doScale(e: React.WheelEvent) {
    const { url } = this.state;
    if (!url) {
      return;
    }
    const zoom = 1 - (e.deltaY / mathUtils.abs(e.deltaY)) * SCALE_STEP;
    const scale = _.clamp(this.state.scale * zoom, this.minScale, MAX_SCALE);

    if (this.state.scale === scale) {
      return;
    }

    const imgBounds = this.imageRef.current!.getBoundingClientRect();
    const mousePoint = {
      x: e.pageX - imgBounds.left,
      y: e.pageY - imgBounds.top,
    };
    const newRelativeMousePoint = {
      x: mousePoint.x * zoom,
      y: mousePoint.y * zoom,
    };
    const offset = {
      x: mousePoint.x - newRelativeMousePoint.x,
      y: mousePoint.y - newRelativeMousePoint.y,
    };

    const imageBounds = { ...this.state.imageBounds! };
    imageBounds.left = mathUtils.round(imageBounds.left + offset.x);
    imageBounds.top = mathUtils.round(imageBounds.top + offset.y);
    imageBounds.width = mathUtils.round(this.originImageSize!.width * scale);
    imageBounds.height = mathUtils.round(this.originImageSize!.height * scale);
    window.requestAnimationFrame(() => {
      this.setState({ imageBounds, scale });
    });
  }

  protected doScroll(e: React.WheelEvent) {
    const { url, imageBounds } = this.state;
    if (!url) {
      return;
    }
    const newBounds = { ...imageBounds! };
    const offset = (-e.deltaY / mathUtils.abs(e.deltaY)) * SCROLL_STEP;
    if (e.shiftKey) {
      newBounds.left = _.clamp(newBounds.left + offset, -newBounds.width, viewPortSize!.width);
    } else {
      newBounds.top = _.clamp(newBounds.top + offset, -newBounds.height, viewPortSize!.height);
    }
    this.setState({ imageBounds: newBounds });
  }

  handleImageBoxWheel = (e: React.WheelEvent) => {
    if (isControlKeyPressed(e)) {
      this.doScale(e);
    } else {
      this.doScroll(e);
    }
  };

  /*
    计算拖拽边角
  */
  doCalcDragPoints = (type: Direction, delta: { x: number; y: number }, cropAreaStyle: IAreaStyle) => {
    // 缩放后的图片
    const { imageBounds } = this.state;
    const { width: imageWidth, height: imageHeight } = imageBounds!;
    // 相对imageBounds的比
    const { width: widthDecimal, height: heightDecimal, left: leftDecimal, top: topDecimal } = cropAreaStyle;
    // 鼠标移动
    const { x, y } = delta;

    const { xDecimal, yDecimal } = {
      xDecimal: x / imageWidth,
      yDecimal: y / imageHeight,
    };

    let computedWidth: number = 0;
    let computedHeight: number = 0;
    let computedLeft: number = 0;
    let computedTop: number = 0;

    if (type === Direction.WN /* 西北 */) {
      computedWidth = widthDecimal - xDecimal;
      computedHeight = heightDecimal - yDecimal;
      computedTop = topDecimal + yDecimal;
      computedLeft = leftDecimal + xDecimal;
    } else if (type === Direction.WS /* 西南 */) {
      computedWidth = widthDecimal - xDecimal;
      computedHeight = heightDecimal + yDecimal;
      computedTop = topDecimal;
      computedLeft = leftDecimal + xDecimal;
    } else if (type === Direction.EN /* 东北 */) {
      computedWidth = widthDecimal + xDecimal;
      computedHeight = heightDecimal - yDecimal;
      computedTop = topDecimal + yDecimal;
      computedLeft = leftDecimal;
    } else if (type === Direction.ES /* 东南 */) {
      computedWidth = widthDecimal + xDecimal;
      computedHeight = heightDecimal + yDecimal;
      computedTop = topDecimal;
      computedLeft = leftDecimal;
    }

    if (computedLeft < 0) {
      computedWidth += computedLeft;
      computedLeft = 0;
    }

    if (computedTop < 0) {
      computedHeight += computedTop;
      computedTop = 0;
    }
    const computedRight = computedLeft + computedWidth;
    if (computedRight > 1) {
      computedWidth -= computedRight - 1;
    }
    const computedBottom = computedTop + computedHeight;
    if (computedBottom > 1) {
      computedHeight -= computedBottom - 1;
    }

    if (computedWidth > 0 && computedHeight > 0) {
      this.setState({
        cropBounds: {
          width: computedWidth,
          height: computedHeight,
          left: computedLeft,
          top: computedTop,
        },
      });
    }
  };

  /* 
    计算偏移,以便后续模拟截图功能
  */
  doCalcBoundingRatioToPX = () => {
    const { cropBounds } = this.state;
    const { left, top, width, height } = cropBounds!;
    const { width: imageWidth, height: imageHeight } = this.originImageSize!;
    return {
      width: width * imageWidth,
      height: height * imageHeight,
      left: left * imageWidth,
      top: top * imageHeight,
    };
  };

  handleCropAreaMouseDown = (e: React.MouseEvent) => {
    const { cropBounds, imageBounds, isSpaceKey } = this.state;
    if (isSpaceKey) {
      return;
    }
    e.stopPropagation();
    dragDelegate(
      (e, delta) => {
        const { x, y } = delta;
        const { left, top, width, height } = cropBounds!;
        const { width: imageWidth, height: imageHeight } = imageBounds!;

        const leftLimit = _.clamp(left + x / imageWidth, 0, 1 - width);
        const topLimit = _.clamp(top + y / imageHeight, 0, 1 - height);

        this.setState({
          cropBounds: {
            ...cropBounds!,
            left: leftLimit,
            top: topLimit,
          },
        });
      },
      () => {},
    );
  };

  handleDragPointsMouseDown = (type: Direction, e: React.MouseEvent) => {
    e.stopPropagation();
    if (this.state.isSpaceKey) {
      return;
    }

    const { cropBounds } = this.state;

    dragDelegate(
      (e, delta) => {
        this.doCalcDragPoints(type, delta, cropBounds!);
      },
      () => {},
    );
  };

  doCalcRealCompSize = (size: ISize, fitComp: boolean) => {
    const { width, height } = this.state.cropBounds!;
    const { width: compWidth, height: compHeight } = size;

    if (!fitComp) {
      return {
        width: compWidth * width,
        height: compHeight * height,
      };
    } else {
      return {
        width: compWidth,
        height: compHeight,
      };
    }
  };

  doSubmit = () => {
    const { url, selectedPage, showCropArea, selectedTeam, selectedProject, selectedSize } = this.state;
    const { comp, app, onSelectSnapshot } = this.props;
    const fitComp = selectedSize === SnapshotSize.fitComp;

    if (!url || !onSelectSnapshot) {
      return;
    }

    if (onSelectSnapshot) {
      let newSize;
      if (!fitComp) {
        const { width, height } = this.originImageSize!;
        newSize = {
          width: width * (selectedSize as number),
          height: height * (selectedSize as number),
        };
      } else {
        newSize = comp.size;
      }

      let realCompSize = undefined;
      let cropAreaBounds = undefined;
      let finalSize = undefined;

      if (showCropArea) {
        realCompSize = this.doCalcRealCompSize(newSize!, fitComp);
        cropAreaBounds = this.doCalcBoundingRatioToPX();
        finalSize = {
          width: realCompSize.width,
          height: realCompSize.height,
        };
      } else {
        finalSize = newSize;
      }

      const snapshotUpdateInfo: ISnapshotValue = {
        url,
        source: {
          teamID: selectedTeam?.id as string,
          // 项目ID
          appID: selectedProject?._id!,
          // 页面ID
          pageID: selectedPage?.id!,
          // 所属功能模块
          moduleType: ModuleType.Design,
        },
        cropAreaInfo: {
          ...cropAreaBounds!,
        },
      };
      snapshotManager.setURLByPageID(selectedPage?.id!, url);
      onSelectSnapshot(snapshotUpdateInfo, finalSize!, comp.id);
    }
    this.addNewSnapshotPageData(app.appID, selectedPage?.id!);
  };

  doPanelClose = () => {
    this.setState({
      showTeamPanel: false,
      showProjectPanel: false,
    });
  };

  handleWindowKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === KeyCodeMap.VK_SPACE && !this.state.isSpaceKey) {
      this.setState({
        isSpaceKey: true,
      });
    }
  };

  handleWindowKeyUp = (e: KeyboardEvent) => {
    if (e.keyCode === KeyCodeMap.VK_SPACE && this.state.isSpaceKey) {
      this.setState({
        isSpaceKey: false,
      });
    }
  };

  handleResetCropArea = () => {
    this.doClearCropArea();
  };

  handleSizeSelect = (item: IListItem) => {
    this.setState({
      selectedSize: item.id as SnapshotSize,
    });
    AppOptions.snapshotSizeSelectedInfo = item.id as SnapshotSize;
  };

  doClearCropArea = () => {
    this.setState({
      showCropArea: false,
      cropBounds: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
    });
  };

  renderTreeItem = (item: IEmbedApp | IEmbedPage) => {
    return (
      <Tooltip text={item.name?.length > 16 ? item.name : ''} popupClassName="snapshot-tooltip">
        <div className="project-tree-item">
          {((item as IEmbedPage).isGroup || (item as IEmbedApp).type === AppTypes.AppSet) && (
            <Icon theme="tag" cls="icon_tree_group" />
          )}
          {item.name}
        </div>
      </Tooltip>
    );
  };

  renderDialogContent = () => {
    const {
      url,
      selectedTeam,
      selectedProject,
      teams,
      pages,
      projects,
      point,
      showTeamPanel,
      showProjectPanel,
      popUpPanelWidth,
      popUpPanelHeight,
      imageBounds,
      isSpaceKey,
      cropBounds,
      showCropArea,
      selectedSize,
    } = this.state;

    const { width, height } = imageBounds!;

    return (
      <div className="snapshot-wrapper">
        <LeftWrapper
          pages={pages}
          pageTree={this.pageTree}
          onPagesSearch={this.handlePagesSearch}
          onRenderTreeItem={this.renderTreeItem}
          onPageTreeItemClick={this.handlePageTreeItemClick}
        />
        <div className="right-wrapper">
          <SelectorBox
            showTeamPanel={showTeamPanel}
            showProjectPanel={showProjectPanel}
            selectedTeam={selectedTeam}
            selectedProject={selectedProject}
            point={point}
            popUpPanelWidth={popUpPanelWidth}
            popUpPanelHeight={popUpPanelHeight}
            teams={teams}
            projects={projects}
            projectTree={this.projectTree}
            onToggleTeamSelectorPanel={this.toggleTeamSelectorPanel}
            onDoPanelClose={this.doPanelClose}
            onRenderTreeItem={this.renderTreeItem}
            onTeamsSearch={this.handleTeamsSearch}
            onTeamSelect={this.handleTeamSelect}
            onToggleProjectSelectorPanel={this.toggleProjectSelectorPanel}
            onProjectsSearch={this.handleProjectsSearch}
            onProjectTreeItemClick={this.handleProjectTreeItemClick}
          />
          <div className="file-selector">
            <ImgPreview
              url={url}
              imageWrapperRef={this.imageWrapperRef}
              imageRef={this.imageRef}
              cropRef={this.cropRef}
              isSpaceKey={isSpaceKey}
              imageBounds={imageBounds}
              viewPortSize={viewPortSize}
              showCropArea={showCropArea}
              cropBounds={cropBounds}
              onImgWrapperMouseDown={this.handleImgWrapperMouseDown}
              onImageBoxWheel={this.handleImageBoxWheel}
              onDoCalcPercent={this.doCalcPercent}
              onCropAreaMouseDown={this.handleCropAreaMouseDown}
              onDragPointsMouseDown={this.handleDragPointsMouseDown}
              onResetCropArea={this.handleResetCropArea}
            />
            <div className="button-bar footer-without-separator">
              {/* <CheckBox
                theme="light"
                id="value"
                checked={!fitComp}
                text={i18n('editor.applyOriginalSize')}
                onChange={this.changeFitComp}
              /> */}
              <span>{i18n('resource.componentsText.currentSize')}</span>
              <Select
                width={120}
                dropDownClassName="snapshot-size-selector"
                data={sizeSelectorData}
                selected={selectedSize}
                theme="light"
                onSelect={this.handleSizeSelect}
              />
              <Button
                theme="dialog"
                width={60}
                activated={!!url && width > 0 && height > 0}
                disabled={!url || width === 0 || height === 0}
                onClick={this.handleOKBtnClick}
              >
                {i18n('general.ok')}
              </Button>
              <Button theme="dialog" onClick={this.handleCancel}>
                {i18n('general.cancel')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Dialog
        title={i18n('resource.componentsText.snapshotTitle')}
        titleClassName="snapshot-title"
        width={900}
        height={680}
        backFade
        allowDrag
        contentClassName="snapshot-content"
        onClose={this.handleCancel}
        onSubmit={this.handleSubmit}
      >
        {this.renderDialogContent()}
      </Dialog>
    );
  }
}

const mapStateToProps = (state: IMainState) => {
  return {
    app: state.app,
  };
};

export default connect(mapStateToProps)(SnapshotEditor);
