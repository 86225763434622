import * as React from 'react';

import { IFill, getFillToolColorString } from '@utils/graphicsUtils';
import { MouseButton } from '@/consts/enums/mouseButton';
import Tooltip from '../../Tooltip';
import { renderClipFill } from '../../../libs/basic/common/GradientFragment';

import './index.scss';

export interface IRecentColorsProp {
  recentColors: Array<IFill>;
  disabledGradient: boolean | undefined;
  onColorClick?: (color: IFill) => void;
}

export interface IRecentColorsState {}

class RecentColors extends React.Component<IRecentColorsProp, IRecentColorsState> {
  static defaultProps: Partial<IRecentColorsProp> = {};

  selfRef: React.RefObject<HTMLDivElement>;

  constructor(props: IRecentColorsProp) {
    super(props);
    this.state = {};
    this.selfRef = React.createRef();
  }

  handleItemColorSelect = (color: IFill, e: React.MouseEvent) => {
    if (e.button !== MouseButton.Right) {
      const { onColorClick } = this.props;
      onColorClick && onColorClick(color);
    }
  };

  renderItem = () => {
    const { recentColors, disabledGradient } = this.props;
    return recentColors
      .filter((item) => {
        if (disabledGradient) {
          return item.type === 'solid';
        } else {
          return true;
        }
      })
      .map((colorItem: IFill, index) => {
        const tip = getFillToolColorString(colorItem);
        const option = {
          id: Math.random().toString(16),
          type: 'recent',
          size: {
            width: 18,
            height: 18,
          },
          fill: colorItem,
          scale: 1,
        };
        return (
          <Tooltip key={`tooltip-${index}`} text={tip} ownerIsDialog>
            <div
              key={`${index}`}
              className="recent-item"
              onMouseDown={this.handleItemColorSelect.bind(this, colorItem)}
            >
              <div className="color-content" style={{ pointerEvents: 'none' }}>
                <svg version="1.2" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
                  {renderClipFill(option, <rect width={option.size.width} height={option.size.height} />)}
                </svg>
              </div>
            </div>
          </Tooltip>
        );
      });
  };

  render() {
    const { recentColors } = this.props;
    if (!recentColors || !recentColors.length) {
      return null;
    }
    return <div className="dsm-c-rp-color-recent-colors">{this.renderItem()}</div>;
  }
}

export default RecentColors;
