import { Dispatch } from 'redux';

import i18n from '@/i18n';

import { IPageArtboardRevisions, IPageRevision } from '@fbs/rp/models/revisions';
import IArtboard from '@fbs/rp/models/artboard';
import { CustomError } from '@fbs/common/models/error';

import apis from '@apis';

import { ActionsUnion, ActionType, createAction, ToastType } from '../types';
import { ThunkActions as AppThunkActions } from '../app/actions';
import { ThunkActions as GlobalThunkActions } from '../global/actions';
import { ThunkActions as ResourceThunkActions } from '../resource/actions';

export interface IPageVersionParams {
  appID: string;
  pageID: string;
  lastSnapshotID?: string;
  pageSize?: number;
  hasAuto?: boolean;
}

export const Actions = {
  loadPageRevisions: (revisions: IPageArtboardRevisions) => createAction(ActionType.Page_Revisions_Load, revisions),
  deletePageVersion: (snapshotID: string) => createAction(ActionType.Page_Version_Delete, snapshotID),
  renamePageVersion: (params: { snapshotID: string; name: string }) =>
    createAction(ActionType.Page_Version_rename, params),
  createPageVersion: (pageVersion: IPageArtboardRevisions) => createAction(ActionType.Page_Version_create, pageVersion),
  clearPageVersion: () => createAction(ActionType.Page_Version_clear),

  revertRevisions: (pageID: string, artboards: IArtboard[]) =>
    createAction(ActionType.Page_Revisions_revert, {
      pageID,
      artboards,
    }),

  loadArtboardRevision: (artboard?: IArtboard) => createAction(ActionType.Page_Revisions_loadArtboard, artboard),
  loadArtboardFragmentsRevision: (artboards?: IArtboard[]) =>
    createAction(ActionType.Page_Revisions_loadArtboardFragments, artboards),

  switchRevision: () => createAction(ActionType.Page_Revision_Switch),

  waiting: (waiting: boolean) => createAction(ActionType.Page_Revision_Waring, waiting),
};

export const ThunkActions = {
  thunkSavePageVersion: (appID: string, pageID: string, name: string) => async (dispatch: Dispatch) => {
    try {
      const data: IPageRevision = await apis.app.savePageVersion(appID, pageID, name);
      dispatch(
        Actions.createPageVersion({
          appID,
          nodeID: pageID,
          pageTotal: 1,
          revisions: [data],
        }),
      );
      GlobalThunkActions.showToast(ToastType.Success, i18n('project.revisions.saveSuccessText'))(dispatch);
    } catch (e) {
      GlobalThunkActions.thunkErrorMessage(e)(dispatch);
      throw new Error(e); // 请在调用时捕获异常
    }
  },

  thunkLoadPageRevision: (params: IPageVersionParams) => async (dispatch: Dispatch) => {
    const { appID, pageID, lastSnapshotID = '', pageSize = 20, hasAuto = true } = params;
    if (!lastSnapshotID) {
      // 为第一页数据时，清除原始数据。消除一些副作用影响
      dispatch(Actions.clearPageVersion());
    }
    try {
      const data = await apis.app.getPageRevisions(appID, pageID, lastSnapshotID, pageSize, hasAuto);
      if (data.revisions) {
        dispatch(Actions.loadPageRevisions(data));
      }
    } catch (e) {
      e.code === 202 && GlobalThunkActions.thunkErrorMessage(e)(dispatch);
      throw new Error(e); // 请在调用时捕获异常
    }
  },

  thunkRevertPageRevision: (appID: string, pageID: string, snapShortID: string) => async (dispatch: Dispatch) => {
    dispatch(Actions.waiting(true));
    apis.app
      .reversionPageRevision(appID, pageID, snapShortID)
      .then(() => {
        ResourceThunkActions.thunkLoadLib(appID)(dispatch);
        AppThunkActions.thunkLoadRevisionPageData(appID, pageID)(dispatch);
      })
      .catch((e: CustomError) => {
        GlobalThunkActions.thunkErrorMessage(e, true, undefined, false)(dispatch);
      })
      .finally(() => {
        dispatch(Actions.waiting(false));
      });
  },

  thunkLoadArtboardByRevision: (data: {
    appID: string;
    pageID: string;
    snapShortID: string;
    artboardID: string;
  }) => async (dispatch: Dispatch) => {
    dispatch(Actions.waiting(true));
    dispatch(Actions.loadArtboardRevision()); // 请求新增数据画板时，清空上一次画板数据。处理在与当前画板切换时的UI问题
    apis.app
      .getArtboardDataByRevision(data)
      .then((artboard) => {
        artboard._id = data.artboardID;
        dispatch(Actions.loadArtboardRevision(artboard));
        dispatch(Actions.waiting(false));
      })
      .catch((e) => {
        dispatch(Actions.waiting(false));
        GlobalThunkActions.thunkErrorMessage(e, true)(dispatch);
      });
  },

  thunkLoadArtboardFragmentsByRevision: (
    datas: {
      appID: string;
      pageID: string;
      snapShortID: string;
      artboardID: string;
    }[],
  ) => async (dispatch: Dispatch) => {
    dispatch(Actions.waiting(true));
    dispatch(Actions.loadArtboardFragmentsRevision());
    Promise.all(datas.map((data) => apis.app.getArtboardDataByRevision(data)))
      .then((artboardFragments) => {
        dispatch(Actions.loadArtboardFragmentsRevision(artboardFragments));
        dispatch(Actions.waiting(false));
      })
      .catch((e) => {
        dispatch(Actions.waiting(false));
        GlobalThunkActions.thunkErrorMessage(e, true)(dispatch);
      });
  },

  thunkDeletePageVersion: (snapShortID: string) => async (dispatch: Dispatch) => {
    dispatch(Actions.waiting(true));
    apis.app
      .deletePageVersion(snapShortID)
      .then(() => {
        GlobalThunkActions.showToast(ToastType.Success, i18n('project.revisions.deleteSuccessText'))(dispatch);
        dispatch(Actions.deletePageVersion(snapShortID));
      })
      .catch((e) => {
        GlobalThunkActions.thunkErrorMessage(e, true)(dispatch);
      })
      .finally(() => {
        dispatch(Actions.waiting(false));
      });
  },

  thunkClearPageVersion: () => async (dispatch: Dispatch) => {
    dispatch(Actions.clearPageVersion());
  },

  thunkPageVersionRename: (snapshotID: string, name: string) => async (dispatch: Dispatch) => {
    dispatch(Actions.waiting(true));
    apis.app
      .renamePageVersion(snapshotID, name)
      .then(() => {
        dispatch(Actions.renamePageVersion({ snapshotID, name }));
      })
      .catch((e) => {
        GlobalThunkActions.thunkErrorMessage(e, true)(dispatch);
      })
      .finally(() => {
        dispatch(Actions.waiting(false));
      });
  },
};

export type PageRevisions = ActionsUnion<typeof Actions>;
