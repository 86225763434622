import {
  IActionBase,
  ICommandActionParams,
  IComponentAction,
  IEventHandle,
  IPageAction,
  IFragmentAction,
} from '@fbs/rp/models/interactions';
import { ILinkAction } from '@fbs/rp/models/interactions';

import { UIComponent } from '@editor/comps';

import { IWorker, ICommand, IWorkerManager } from '../types';
import CommandBase from '../Commands/CommandBase';
import {
  MoveCommand,
  VisibleCommand,
  ContentSwitchCommand,
  ScrollCommand,
  StateCommand,
  ScaleCommand,
  RotationCommand,
  ResizeCommand,
  PageSkipCommand,
  FragmentCommand,
  LinkCommand,
} from '../Commands';

export abstract class WorkerBase implements IWorker {
  public onFinish?: () => void;
  private _isRunning: boolean = false;
  private _manager: IWorkerManager;

  private _runAgain = false;
  private _termination = false;

  protected currentRunCommandIndex = 0;

  protected commands: ICommand[] = [];
  protected handle: IEventHandle;

  protected needRevertWorker: boolean = false;

  public get isRunning() {
    return this._isRunning;
  }

  constructor(protected manager: IWorkerManager, protected trigger: UIComponent, protected event: string) {
    this._manager = manager;
    this.handle = this.trigger.interactions[event];
    this.parserCommand();
  }

  public runAgainAfterFinished(): void {
    this._runAgain = true;
  }

  public get doc() {
    return this._manager.doc;
  }

  public get docTree() {
    return this._manager.docTree;
  }

  public termination() {
    this._termination = true;
    this.commands.forEach((item) => {
      item.termination();
    });
    this.commands = [];
    this._isRunning = false;
  }

  public isTermination() {
    return this._termination;
  }

  protected abstract start(): void;

  protected get nextCommand() {
    return this.commands[this.currentRunCommandIndex];
  }

  protected doSkipToNextIndex() {
    if (this.needRevertWorker) {
      this.currentRunCommandIndex--;
    } else {
      this.currentRunCommandIndex++;
    }
  }

  protected get isFinish() {
    if (this.isTermination()) {
      return true;
    }
    if (this.needRevertWorker) {
      return this.currentRunCommandIndex < 0;
    }
    return this.currentRunCommandIndex >= this.commands.length;
  }

  protected parserCommand() {
    if (!this.handle) {
      return;
    }
    this.handle.actions.forEach((action) => {
      const cmd = this.buildCommand(action);
      if (cmd) {
        cmd.workManager = this.manager;
        cmd.onFinish = this.doFinishCommand.bind(this);
        this.commands.push(cmd);
      }
    });
  }

  protected doFinishCommand(cmd: ICommand) {
    if (!this.isTermination() && cmd instanceof CommandBase) {
      cmd.startSubWorker();
    }
    this.doSkipToNextIndex();
    if (this.isFinish) {
      this.finish();
    }
  }

  protected finish() {
    this.onFinish && this.onFinish();
    if (this.handle.autoRevert) {
      this.needRevertWorker = !this.needRevertWorker;
    }
    this.currentRunCommandIndex = this.needRevertWorker ? this.commands.length - 1 : 0;
    if (this._runAgain) {
      this._runAgain = false;
      this.execute();
    } else {
      this._isRunning = false;
    }
  }

  protected executeNext(command: ICommand) {
    if (this.isTermination()) {
      return;
    }
    if (command instanceof CommandBase || command instanceof FragmentCommand) {
      command.execute(this.needRevertWorker);
    } else {
      command.execute();
    }
  }

  public refreshCommands() {
    this.commands.forEach((cmd) => {
      cmd.refreshSelf && cmd.refreshSelf();
    });
  }

  protected buildCommand = (action: IActionBase): ICommand | null => {
    const { target, type, params } = action;
    if (type === 'component') {
      const componentAction = action as IComponentAction;
      const { command, animation } = componentAction;
      const cmdParam = params as ICommandActionParams;

      const comp: UIComponent | null | undefined = this.docTree.getElementInNodeValue(target);
      if (!comp) {
        return null;
      }
      const actionParam = {
        target: comp,
        animate: animation,
        params: cmdParam,
      };
      switch (command) {
        case 'move':
          return new MoveCommand(this, actionParam);
        case 'resize':
          return new ResizeCommand(this, actionParam);
        case 'scale':
          return new ScaleCommand(this, actionParam);
        case 'rotation':
          return new RotationCommand(this, actionParam);
        case 'toggleVisible':
          return new VisibleCommand(this, actionParam);
        case 'toggleState':
          return new StateCommand(this, actionParam);
        case 'scroll':
          return new ScrollCommand(this, actionParam);
        case 'switchContent':
          return new ContentSwitchCommand(this, actionParam);
        default:
          return null;
      }
    }
    if (type === 'page') {
      const { target } = action as IPageAction;
      // 忽略无效的页面跳转，否则会有逻辑问题
      if (!this._manager.isValidPageTarget(target)) {
        return null;
      }
      const cmd = new PageSkipCommand(this, action as IPageAction);
      cmd.onSkip = this.manager.extensionFeature.onPageSkip;
      return cmd;
    } else if (type === 'fragment') {
      return this.buildFragmentCommand(action as IFragmentAction);
    } else if (type === 'link') {
      return new LinkCommand(this, action as ILinkAction);
    }
    return null;
  };

  protected buildFragmentCommand = (action: IFragmentAction) => {
    if (!action.pageId) {
      action.pageId = this.doc.pageID;
    }
    // 要验证，目前只允许同一页面全局存在一个
    const cmd = new FragmentCommand(this, action);
    cmd.onBegin = this.manager.extensionFeature.onFragmentOverlay;
    return cmd;
  };

  public execute() {
    if (!this.commands.length || this.isTermination()) {
      this.finish();
      return;
    }
    this._isRunning = true;
    this.start();
  }

  /**
   * 初始化一些数据，
   */
  public init() {}
}
