import * as React from 'react';
import * as ReactDOM from 'react-dom';
import classNames from 'classnames';
import * as _ from 'lodash';

import { isMacOS } from '@/utils/envUtils';
import * as BoundsUtils from '@utils/boundsUtils';
import { dragDelegate } from '@utils/mouseUtils';
import { measureTextSize } from '@utils/textUtils';
import { convertEventToHotKey } from '@utils/hotkeysUtils';
import { isEqualDate, max, min, depthClone, jsonClone, isInputting } from '@utils/globalUtils';
import { parseColorToString } from '@/utils/graphicsUtils';

import { UIComponent, UIContainerComponent } from '@editor/comps';
import { UITableComponent } from '@editor/comps';

import { ArtboardPatches } from '@fbs/rp/utils/patch';
import { IComponentValue, ILineValue } from '@fbs/rp/models/value';
import { PureColor, Color } from '@fbs/rp/models/properties/color';
import { IPosition, IBounds } from '@fbs/common/models/common';
import { ITableValue, CellTypes } from '@fbs/rp/models/table';
import { TextAlign, VerticalAlign } from '@fbs/rp/models/properties/text';
import { IProperties } from '@fbs/rp/models/property';
import ITextFormatEx from '@/fbs/rp/models/properties/textFormat';
import { ETextBehaviour } from '@/fbs/rp/models/component';
import { ITypographyValue } from '@fbs/rp/models/ds/typography';

import { getNewID } from '@helpers/idHelper';
import * as tableHelper from '@helpers/tableHelper';
import { getTextCompInSealed } from '@helpers/componentHelper';
import { getAllCellsOfSelectArea, ICellPosition, ISelectArea, TableClipboardHelper } from '@helpers/tableHelper';
import {
  getHasCopyTableCellsData,
  parseClipboardExcelToTable,
  copy,
  copyHtmlOrText,
  copyTableCellsData,
  ClipboardType,
  getCopiedTableCellsData,
} from '@/helpers/clipboardHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { textStyleShortCut } from '@helpers/shortCutHelper';
import { mergePatches } from '@/helpers/patchHelper';

import { BlueColor } from '@/consts/colors';
import {
  MinCellWidth,
  MinCellHeight,
  DefaultCellPadding,
  DefaultCellWidth,
  DefaultCellHeight,
  MaxRowsCount,
  MaxColumnsCount,
} from '@consts/defaultData/table';
import { MouseButtons, MouseButton } from '@/consts/enums/mouseButton';
import { getComponent } from '@libs/libs';
import { CText } from '@libs/constants';
import EditorContext from '@contexts/editor';
import { EditorCommand, getCommandByHotKey } from '@/editor/core';
import i18n from '@i18n';
import { Icon } from '@/dsm';
import KeyCodeMap from '@/dsm2/constants/KeyCodeMap';

import ValueEditor from '../../../../components/Application/WorkContainer/Workspace/ValueEditor';
import { getDefaultTextFormat, makeText } from '../../NewText';
import TableMenu, { ITableMenuType } from './TableMenu';

import './index.scss';

interface ITableEditorProps {
  compStyle: React.CSSProperties;
  value: ITableValue;
  scale: number;
  comp: UITableComponent;
  doTablehidden: (value: boolean) => void;
  onChangesSubmit?: (value: ITableValue, childrenPatches?: ArtboardPatches) => void;
}

interface ITableEditorStates {
  tableValue: ITableValue;
  selectArea?: ISelectArea;
  selectRows: Array<number>;
  selectColumns: Array<number>;
  menuType: ITableMenuType;
  selectedAll: boolean;
  hasTableCellsData: boolean;
  cursor?: 'pointer' | 'grabbing' | 'move' | 'ns-resize' | 'ew-resize';
  menuPosition?: IPosition;
  editorPosition?: IPosition;
  editorBounds?: IBounds;
}

type TOperate =
  | 'cellSelect'
  | 'rowsSelect'
  | 'columnsSelect'
  | 'shiftSelect'
  | 'rowsDrag'
  | 'columnsDrag'
  | 'resizeHeight'
  | 'resizeWidth'
  | 'smartCorner'
  | null;

// const offscreenBounds: IBounds = {
//   left: -0,
//   top: -0,
//   right: -0 + 20,
//   bottom: -0 + 20,
//   width: 20,
//   height: 20,
// };

const offscreenBounds: IBounds = {
  left: -1e6,
  top: -1e6,
  right: -1e6 + 20,
  bottom: -1e6 + 20,
  width: 20,
  height: 20,
};

export default class TableEditor extends React.Component<ITableEditorProps, ITableEditorStates> {
  private tableEditor: React.RefObject<HTMLDivElement> = React.createRef();
  private selectBox: React.RefObject<HTMLDivElement> = React.createRef();
  private valueEditor: React.RefObject<ValueEditor> = React.createRef();
  private tableOuterWrap: HTMLDivElement; // 挂载到外部DOM容器

  static contextType = EditorContext;
  // @ts-ignore
  context: React.ContextType<typeof EditorContext>;
  constructor(props: ITableEditorProps) {
    super(props);

    this.tableOuterWrap = document.createElement('div');

    this.state = {
      hasTableCellsData: false,
      tableValue: props.value,
      selectRows: [],
      selectColumns: [],
      selectedAll: false,
      menuType: 'cell',
    };
  }
  //记录按下时间，处理因为row高发生变化，导致mouseenter目标不正确
  private downTime?: number;
  private operate?: TOperate;
  // 框选开始的单元格
  private cursorPosition?: IPosition;
  private startCell?: ICellPosition;
  // 多选开始的列
  private startColumn?: number;
  // 多选开始的行
  private startRow?: number;
  // 单元格最小宽
  private minWidth = MinCellWidth;
  // 单元格最小高
  private minHeight = MinCellHeight;
  // 拖拽尺寸
  private dragSize?: number;
  // 虚线x
  private lineX?: number;
  // 虚线y
  private lineY?: number;
  // 通过列尾拖拽添加多列的拖动距离
  private columnAddOffset?: number;
  // 通过行尾拖拽添加多列的拖动距离
  private rowAddOffset?: number;
  // shift键
  private shiftKey?: boolean;
  // 编辑的组件的位置
  private editCellPosition?: ICellPosition;
  private dragOffset?: IPosition;
  private newDragIndex?: number;
  // // 表格剪切板
  // private clipboard?: ITableCell[][];
  // 判断键是否按下
  private keyPress = new Set();
  private cells: HTMLTableDataCellElement[] = [];
  // 模拟点击的次数(解决mousedown与doubleclick冲突)
  private firstClickTimer: number = 0;
  // 汉字宽度
  private hanziWidth: number = 14;
  private hanziReg: RegExp = /[\u4e00-\u9fa5]/;
  // 小写字母与数字宽度
  private numAndLowerCaseWidth: number = 8;
  private numAndLowerCaseReg: RegExp = /[a-z0-9]/;
  // 大写字母宽度
  private upperCaseWidth: number = 10;
  private upperCaseReg: RegExp = /[A-Z]/;
  // 符号
  private symbolWidth: number = 5;
  // 跳过自动选中
  skipSelectArea = false;

  private setCells = (el: HTMLTableDataCellElement | null) => {
    if (!el) {
      return;
    }
    if (!this.cells.find((c) => c === el)) {
      this.cells.push(el);
    }
  };

  componentDidMount() {
    const tableDom = this.tableEditor.current?.closest('.component-tabel')!;
    tableDom.parentNode?.insertBefore(this.tableOuterWrap, tableDom.parentNode?.nextSibling);
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
    window.addEventListener('mousedown', this.handleMouseDown);
    window.addEventListener('keydown', this.handleKeyDownCapture, { capture: true });
    window.addEventListener('keyup', this.handleWindowKeyUpCapture, { capture: true });
    window.addEventListener('keydown', this.handleWindowKeyDown);
    window.addEventListener('mousewheel', this.handleWheel);
    window.addEventListener('tableCellSelecting', this.handleCellOutClick);
    window.addEventListener('tableCellSelected', this.resetSelectFrame);
    document.addEventListener('paste', this.handlePasteCapture, { capture: true });
    this.context.uiManager.tableEditor = {
      tableComponent: this.props.comp,
      selectArea: this.state.selectArea,
      selectRows: this.state.selectRows,
      selectColumns: this.state.selectColumns,
      textEditing: false,

      setRowsHeight: this.handleHeightChange,
      setColumnsWidth: this.handleWidthChange,
      setTextAlign: this.handleTextAlignChange,
      setVerticalAlign: this.handleVerticalAlignChange,
      setCellsBorder: this.handleBorderChange,
      setFill: this.handleFillChange,
      setTextFormat: this.handleTextFormatChange,
      setTextColor: this.handleTextColorChange,
      applyTypography: this.handleTypographyApply,
    };
    // this.scrollToEditor();
    this.context.uiManager.updateApplicationBar();
    this.context.uiManager.updateRightPanel();
  }

  componentDidUpdate() {
    if (this.props.comp.needUpdateCellRightPanel === true && this.state.selectArea) {
      this.props.comp.needUpdateCellRightPanel = false;
      this.updateTableSelectedArea(this.props.value, this.state.selectArea);
    }
  }

  componentWillUnmount() {
    this.tableOuterWrap.parentNode?.removeChild(this.tableOuterWrap);
    this.context.uiManager.closeTableEditor();
    this.context.uiManager.tableEditor = undefined;
    this.context.uiManager.workSpace?.focus();
    this.selectedComps = [];
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('mousedown', this.handleMouseDown);
    window.removeEventListener('keydown', this.handleWindowKeyDown);
    window.removeEventListener('keydown', this.handleKeyDownCapture, { capture: true });
    window.removeEventListener('keyup', this.handleWindowKeyUpCapture, { capture: true });
    window.removeEventListener('mousewheel', this.handleWheel);
    window.removeEventListener('tableCellSelecting', this.handleCellOutClick);
    window.removeEventListener('tableCellSelected', this.resetSelectFrame);
    document.removeEventListener('paste', this.handlePasteCapture, { capture: true });
    // 恢复选择框显示
    this.switchSelectFrame(true);
    // 退出时清空
    this.props.comp.selectedItem = undefined;
    this.props.comp.selectArea = undefined;
    this.props.comp.disabledInteractionRemark = UITableComponent.DISABLED_FLAGS.none;
  }

  private getRowMinHeight(index: number): number {
    const heightArray = this.props.comp.components
      .filter((item) => item.row === index)
      .map((item) => item.size.height + DefaultCellPadding * 2);
    return Math.max(...heightArray, MinCellHeight);
  }

  /**
   * 单元格外部单击选中
   */
  private handleCellOutClick = (evt: Event) => {
    const { position, id, canDrag } = (evt as CustomEvent).detail;
    if (id !== this.props.comp.id) {
      return;
    }
    this.handleSelectedCellByPosition(position, canDrag);
  };

  private getColMinWidth(index: number): number {
    const currColDoms = [].slice.call(
      document.querySelectorAll(`.table-col-index-${index} .atom-comp-text`),
    ) as HTMLElement[];
    const textDoms = currColDoms.map((element) => element.innerHTML);
    const textDomsWithoutDiv = textDoms.map((item) => item?.split(/<\/?div>/g));
    const widthArray = _.flattenDeep(textDomsWithoutDiv)
      .filter((item) => !!item)
      .map((item) => {
        if (item) {
          return this.doCalcTextWidth(item) + DefaultCellPadding * 2;
        }
      });

    return Math.max(...(widthArray as number[]), MinCellWidth);
  }

  doCalcTextWidth = (text: string) => {
    let width = 0;
    for (let i = 0; i < text.length; i++) {
      if (this.hanziReg.test(text[i])) {
        width += this.hanziWidth;
      } else if (this.upperCaseReg.test(text[i])) {
        width += this.upperCaseWidth;
      } else if (this.numAndLowerCaseReg.test(text[i])) {
        width += this.numAndLowerCaseWidth;
      } else {
        width += this.symbolWidth;
      }
    }
    return width;
  };

  /**
   * 进入编辑时将表格移动到视野中
   */
  scrollToEditor = () => {
    const workSpace = this.context.uiManager.workSpace?.dom;
    const tableEditor = this.tableEditor.current;
    if (!workSpace || !tableEditor) {
      return;
    }
    const workSpaceBounds = workSpace.getBoundingClientRect();
    const editorBounds = tableEditor.getBoundingClientRect();

    const leftOffset = workSpaceBounds.left - editorBounds.left;
    const rightOffset = workSpaceBounds.right - editorBounds.right;
    const topOffset = workSpaceBounds.top - editorBounds.top;
    const bottomOffset = workSpaceBounds.bottom - editorBounds.bottom;

    let x = 0;
    if (leftOffset > 0) {
      x = leftOffset;
    } else if (rightOffset < 0) {
      x = Math.max(rightOffset, leftOffset);
    }

    let y = 0;
    if (topOffset > 0) {
      y = topOffset;
    } else if (bottomOffset < 0) {
      y = Math.max(bottomOffset, topOffset);
    }
    this.context.uiManager.workSpace?.movePageBy({ x, y });
  };

  /**
   * 返回合规区域
   */
  normalizeArea = (tableValue: ITableValue, selectArea: ISelectArea) => {
    let newSelectArea: ISelectArea | undefined = _.cloneDeep(selectArea);
    const { start, end } = newSelectArea;
    const { columns, rows } = tableValue;
    if (start.column >= columns.length) {
      start.column = Math.max(0, columns.length - 1);
    }
    if (start.row >= rows.length) {
      start.row = Math.max(0, rows.length - 1);
    }
    if (end.column >= columns.length) {
      end.column = Math.max(0, columns.length - 1);
    }
    if (end.row >= rows.length) {
      end.row = Math.max(0, rows.length - 1);
    }
    if (rows.length <= 0) {
      newSelectArea = undefined;
    }
    return newSelectArea;
  };

  UNSAFE_componentWillReceiveProps(props: ITableEditorProps) {
    let selectArea = this.state.selectArea;
    if (!isEqualDate(this.props.value, props.value)) {
      // 处理undo,redo操作，引发数据发生变化，导致选中行和列找不到对应数据，影响其他组件报错
      if (!this.skipSelectArea) {
        selectArea = selectArea ? this.normalizeArea(props.value, selectArea) : selectArea;
      } else {
        selectArea = undefined;
      }
      this.setState({
        tableValue: props.value,
        selectArea: selectArea,
      });
    }
    const selectedComps = props.comp.getCompsInArea(selectArea);
    const oldIDs = selectedComps.map((comp) => comp.id);
    const newIDs = this.selectedComps.map((comp) => comp.id);
    if (!_.isEqual(oldIDs, newIDs)) {
      this.selectedComps = selectedComps;
    }
  }

  handleWheel = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();
    return false;
  };

  /**
   * 阻止编辑器右键冒泡和默认行为
   */
  handleTableEditorContextMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  /**
   * 设置填充
   */
  handleFillChange = (fill: PureColor) => {
    const { tableValue, selectArea } = this.state;
    const newValue = tableHelper.setFill(tableValue, selectArea ?? this.getAllArea(), fill);
    this.doSubmit(newValue);
  };

  /**
   * 设置行高
   */
  handleHeightChange = (height: number, inputValue?: string) => {
    const { tableValue, selectArea } = this.state;
    const { rows } = tableHelper.getRowsAndColumnsOfSelectArea(selectArea ?? this.getAllArea());
    if (rows.length) {
      // 用户清空右边属性面板，行高时。恢复行高自适应高度
      if (inputValue === '') {
        this.refreshTableCellRowHeight(rows, true);
      } else {
        const newValue = tableHelper.setRowsHeight(tableValue, rows, height);
        rows.forEach((r) => {
          newValue.rows[r].fixedHeight = true;
        });
        this.doSubmit(newValue);
      }
    }
  };

  /**
   * 设置列宽
   * 修改文本组件宽
   */
  handleWidthChange = (width: number) => {
    const { tableValue, selectArea } = this.state;
    const { columns } = tableHelper.getRowsAndColumnsOfSelectArea(selectArea ?? this.getAllArea());
    if (columns.length) {
      const newValue = tableHelper.setColumnsWidth(tableValue, columns, width);
      const patches = this.props.comp.updateTextCompSize(newValue);
      this.doSubmit(newValue, patches);
    }
  };

  /**
   * 修改单元格及组件（富文本、复选框）文本
   * 修改文本组件样式及尺寸高度，修改单元格预设样式
   */
  handleTextFormatChange = (key: keyof ITextFormatEx, value: ITextFormatEx[keyof ITextFormatEx]) => {
    this.doTextStyleChange({ [key]: value });
  };

  handleTypographyApply = (value: ITypographyValue) => {
    const { family, size, bold, italic, underline, strike, color } = value;
    const fragment: Partial<ITextFormatEx> = {
      fontFamily: family,
      fontSize: size,
      fontStyle: {
        bold,
        italic,
        underline,
        strike,
      },
      color,
    };
    this.doTextStyleChange(fragment);
  };

  private doTextStyleChange(style: Partial<ITextFormatEx>): void {
    const { tableValue, selectArea } = this.state;
    const area = selectArea ?? this.getAllArea();
    const newValue = tableHelper.setTextFormat(tableValue, area, style);
    const patches = this.props.comp.updateTextFormat(tableValue, area, style);
    this.doSubmit(newValue, patches);
  }

  private handleTextColorChange = (color: PureColor) => {
    this.doTextStyleChange({ color });
  };

  /**
   * 设置水平对齐
   * EIXME：修改文本组件的水平对齐属性
   */
  handleTextAlignChange = (align: TextAlign) => {
    const { tableValue, selectArea } = this.state;
    const area = selectArea ?? this.getAllArea();
    const newValue = tableHelper.setTextAlign(tableValue, area, align);
    const patches = this.props.comp.updateTextCompAlign(newValue, area);
    this.doSubmit(newValue, patches);
  };

  /**
   * 设置垂直对齐
   */
  handleVerticalAlignChange = (align: VerticalAlign) => {
    const { tableValue, selectArea } = this.state;
    const newValue = tableHelper.setVerticalAlign(tableValue, selectArea ?? this.getAllArea(), align);
    this.doSubmit(newValue);
  };

  /**
   * 设置边框
   */
  handleBorderChange = (width: number | undefined, color: Color | undefined, mode?: string | undefined) => {
    const { tableValue, selectArea } = this.state;
    const borderChange = { width, color };
    const newValue = tableHelper.setBorder(tableValue, selectArea ?? this.getAllArea(), borderChange, mode);
    this.doSubmit(newValue);
  };

  private doSubmit = (value: ITableValue, childrenPatches?: ArtboardPatches) => {
    const { onChangesSubmit, comp } = this.props;
    // 修正子组件位置/textBehaver
    const newPatches = comp.getNewPatchesWithFixChildrenData(childrenPatches);
    onChangesSubmit && onChangesSubmit(value, newPatches);
  };

  // 单元格是否全部选择
  get isSelectedAll(): boolean {
    const {
      tableValue: { columns, rows, showHeader },
      selectArea,
    } = this.state;
    const area = {
      start: { row: showHeader ? -1 : 0, column: 0 },
      end: { row: rows.length - 1, column: columns.length - 1 },
    };
    return isEqualDate(area, selectArea);
  }

  // 选择框对应的行
  get activedRows(): Array<number> {
    const { selectArea } = this.state;
    if (!selectArea) return [];
    const { start, end } = selectArea;
    return new Array(end.row - start.row + 1).fill('').map((item, i) => i + start.row);
  }

  // 选择框对应的列
  get activedColumns(): Array<number> {
    const { selectArea } = this.state;
    if (!selectArea) return [];
    const { start, end } = selectArea;
    return new Array(end.column - start.column + 1).fill('').map((item, i) => i + start.column);
  }

  /**
   * 获取选择的单元格的组件
   */
  get selectedComps(): UIComponent[] {
    return this.props.comp.selectedComps;
  }

  /**
   * 设置选择的单元格的组件
   */
  set selectedComps(comps: UIComponent[]) {
    this.restoreLastSelCellComponentsState();
    this.props.comp.selectedComps = comps;
    this.context.uiManager.updateRightPanel();
    this.context.uiManager.updateApplicationBar();
  }

  private restoreLastSelCellComponentsState() {
    const { comp } = this.props;
    const states: { comp: UIComponent; stateID?: string }[] = [];
    for (const item of comp.selectedComps) {
      if (item.toJSON()._currentState) {
        states.push({ comp: item, stateID: undefined });
      }
    }
    this.context.coreEditor?.switchState(states);
  }

  /**
   * 编辑器偏移位置
   */
  getStyleOnEditorMove = (position?: IPosition) => {
    const style: React.CSSProperties = {};
    if (position) {
      style.transform = `translate(${position.x}px, ${position.y}px) translateZ(0)`;
    }
    return style;
  };

  /**
   *  更新选择的单元格的组件
   */
  updateSelectComps = (comps: UIComponent[]) => {
    this.selectedComps = comps;
  };

  private resetSelectFrame = () => {
    if (this.state.selectArea) {
      this.switchSelectFrame(false);
    }
  };

  private switchSelectFrame(show: boolean): void {
    const event = new CustomEvent(show ? 'showSelectFrame' : 'hideSelectFrame');
    window.dispatchEvent(event);
  }

  selectTableCellToInteraction(row: number, column: number) {
    let currentCellComp = this.props.comp.getTableCellComp(row, column);
    if (!currentCellComp) {
      let patches = this.props.comp.createTableCellComp(row, column);
      this.context.coreEditor?.new_update(patches);
      currentCellComp = this.props.comp.getTableCellComp(row, column);
    }
    this.props.comp.selectedItem = currentCellComp;
  }

  /**
   * 设置选中区域，更新选中组件
   * 设置选中区，必须在此！
   */
  setSelectedArea = (area: ISelectArea | undefined, showSelectFrame?: boolean, fn?: () => void) => {
    const callback = () => {
      this.switchSelectFrame(showSelectFrame ?? !area);
      this.handleInputFocus();
      this.props.comp.selectArea = this.state.selectArea;
      fn && fn();
    };
    if (area) {
      tableHelper.resetSelectAreaOrigin(area);
      const selectedComps = this.props.comp.getCompsInArea(area);
      if (this.context.uiManager.tableEditor) {
        this.context.uiManager.tableEditor.selectArea = area;
      }
      // 表示选中了多个
      const isMultipleCell =
        tableHelper.getAllCellsOfSelectArea(this.state.tableValue, area).filter((cell) => !cell.cell.mergedBy).length >
        1;
      // 处理单元格选中，增加交互相关业务
      if (isMultipleCell) {
        this.props.comp.disabledInteractionRemark = UITableComponent.DISABLED_FLAGS.remarkAndInteraction;
        this.props.comp.selectedItem = undefined;
      } else {
        this.selectTableCellToInteraction(area.start.row, area.start.column);
        this.props.comp.disabledInteractionRemark = UITableComponent.DISABLED_FLAGS.remark;
      }
      this.selectedComps = selectedComps;
      this.setState({ selectArea: area }, callback);
    } else {
      if (this.context.uiManager.tableEditor) {
        this.context.uiManager.tableEditor.selectArea = undefined;
      }
      this.props.comp.selectedItem = undefined;
      this.props.comp.disabledInteractionRemark = UITableComponent.DISABLED_FLAGS.none;
      this.selectedComps = [];
      this.setState({ selectArea: undefined }, callback);
    }
  };

  /**
   * 更新表格选中区
   */
  updateTableSelectedArea = (tableValue: ITableValue, selectArea: ISelectArea) => {
    const isMultipleCell =
      tableHelper.getAllCellsOfSelectArea(tableValue, selectArea).filter((cell) => !cell.cell.mergedBy).length > 1;
    if (isMultipleCell) {
      this.props.comp.disabledInteractionRemark = UITableComponent.DISABLED_FLAGS.remarkAndInteraction;
      this.props.comp.selectedItem = undefined;
      this.context.uiManager.updateRightPanel();
    } else {
      this.selectTableCellToInteraction(selectArea.start.row, selectArea.start.column);
      this.props.comp.disabledInteractionRemark = UITableComponent.DISABLED_FLAGS.remark;
      this.context.uiManager.updateRightPanel();
    }
  };

  /**
   * 设置选中行，同步更新context
   */
  setSelectRows = (rows: number[]) => {
    const sortRows = depthClone(rows).sort((a, b) => a - b);
    if (this.context.uiManager.tableEditor) {
      this.context.uiManager.tableEditor.selectRows = sortRows;
    }
    this.setState({
      selectRows: sortRows,
    });
  };

  /**
   * 设置选中行，同步更新context
   */
  setSelectColumns = (columns: number[]) => {
    const sortCols = depthClone(columns).sort((a, b) => a - b);
    if (this.context.uiManager.tableEditor) {
      this.context.uiManager.tableEditor.selectColumns = sortCols;
    }
    this.setState({
      selectColumns: sortCols,
    });
  };

  /**
   *  单元格选中变化时，清除行列选中
   */
  clearRowsAndColumns = () => {
    this.setState({ selectedAll: false });
    this.setSelectColumns([]);
    this.setSelectRows([]);
  };

  /**
   * 表格全选区域
   */
  private getAllArea = () => {
    const { rows, columns, showHeader } = this.state.tableValue;
    return {
      start: { row: showHeader ? -1 : 0, column: 0 },
      end: { row: rows.length - 1, column: columns.length - 1 },
    };
  };

  /**
   * 全选单元格
   */
  selectAllCells = () => {
    const { rows, columns, showHeader } = this.state.tableValue;
    const selectArea = this.getAllArea();
    this.clearRowsAndColumns();
    const selectColumns = columns.map((item, i) => i);
    const selectRows = rows.map((item, i) => i);
    if (showHeader) {
      selectRows.push(-1);
    }
    this.setState({ selectedAll: true });
    this.setSelectRows(selectRows);
    this.setSelectColumns(selectColumns);
    this.setSelectedArea(selectArea);
  };

  /**
   * 清除单元格选择
   */
  clearSelectArea = () => {
    this.clearRowsAndColumns();
    this.setSelectedArea(undefined);
  };

  private handleClearSelectArea = () => {
    this.skipSelectArea = true;
    this.clearSelectArea();
    this.setState({}, () => {
      this.skipSelectArea = false;
    });
  };

  private handleTableDoubleClick = () => {
    const { selectArea, tableValue } = this.state;
    if (selectArea) {
      const cells = getAllCellsOfSelectArea(tableValue, selectArea);
      const visibleCells = cells.filter((item) => !item.cell.mergedBy);
      // 多选不进入编辑
      if (visibleCells.length > 1) {
        return;
      }
    }
    this.handleContentEdit();
  };

  handleContextMenu = {
    cell: async (e: React.MouseEvent) => {
      const { pageX, pageY } = e;

      const hasTableCellsData = await getHasCopyTableCellsData();

      this.setState({
        hasTableCellsData: hasTableCellsData,
        menuPosition: {
          x: pageX,
          y: pageY,
        },
        menuType: 'cell',
      });
    },
    column: async (e: React.MouseEvent) => {
      const { pageX, pageY } = e;

      const hasTableCellsData = await getHasCopyTableCellsData();

      this.setState({
        hasTableCellsData: hasTableCellsData,
        menuPosition: {
          x: pageX,
          y: pageY,
        },
        menuType: 'column',
      });
    },
    row: async (e: React.MouseEvent) => {
      const { pageX, pageY } = e;

      const hasTableCellsData = await getHasCopyTableCellsData();

      this.setState({
        hasTableCellsData: hasTableCellsData,
        menuPosition: {
          x: pageX,
          y: pageY,
        },
        menuType: 'row',
      });
    },
  };

  /**
   * 清除表格内容
   */
  cleanCellsContent = () => {
    const { selectArea } = this.state;
    if (selectArea) {
      const selectPositions: ICellPosition[] = [];
      for (let i = selectArea.start.row; i <= selectArea.end.row; i++) {
        for (let j = selectArea.start.column; j <= selectArea.end.column; j++) {
          selectPositions.push({ row: i, column: j });
        }
      }
      const { newValue, childrenPatches } = this.props.comp.cleanContent(selectPositions);
      // this.updateSelectComps([]);
      this.doSubmit(newValue, childrenPatches);
    }
  };
  /**
   * 清除表格内容和样式
   */
  cleanCellsContentAndStyle = () => {
    const { selectArea } = this.state;
    if (selectArea) {
      const selectPositions: ICellPosition[] = [];
      for (let i = selectArea.start.row; i <= selectArea.end.row; i++) {
        for (let j = selectArea.start.column; j <= selectArea.end.column; j++) {
          selectPositions.push({ row: i, column: j });
        }
      }
      const { newValue, childrenPatches } = this.props.comp.cleanContentAndStyle(selectPositions);
      this.doSubmit(newValue, childrenPatches);
    }
  };
  /**
   * 转化表格内容
   */
  convertCellsContent = (type: CellTypes) => {
    const { selectArea } = this.state;
    if (selectArea) {
      const selectPositions: ICellPosition[] = [];
      for (let i = selectArea.start.row; i <= selectArea.end.row; i++) {
        for (let j = selectArea.start.column; j <= selectArea.end.column; j++) {
          selectPositions.push({ row: i, column: j });
        }
      }
      const { newValue, childrenPatches } = this.props.comp.convertCellContentType(selectPositions, type);
      // this.updateSelectComps([]);
      this.doSubmit(newValue, childrenPatches);
    }
  };

  handlePasteCapture = async (e?: ClipboardEvent) => {
    // 焦点在表格的输入框，且未进入输入状态
    const focusInTaleInput = this.isTableInputFocused;
    if (!focusInTaleInput) {
      return;
    }
    // 获取选区数据, 表格数据
    const { selectArea, tableValue, editorBounds } = this.state;
    if (!selectArea || editorBounds) {
      return;
    }
    e?.stopImmediatePropagation();
    e?.preventDefault();
    // 获取复制的表格数据；
    let copyData: tableHelper.ITableClipboardData | undefined | null;
    if (e) {
      copyData = await parseClipboardExcelToTable(e);
    } else {
      copyData = await getCopiedTableCellsData();
    }
    if (!copyData) {
      return;
    }
    const { cells: copyCells } = copyData;
    if (!copyCells.length) {
      return;
    }
    if (!copyCells[0].length) {
      return;
    }
    const copySize = {
      row: copyCells.length,
      column: copyCells[0].length,
    };
    const pasteInfo = TableClipboardHelper.getPasteAreas(tableValue, selectArea, copySize);
    // 行列数超过最大值或可粘贴区为空
    if (!pasteInfo || !pasteInfo.pasteAreas.length) {
      console.warn('行列数超过最大值或可粘贴区为空');
      return;
    }
    const { addRows, addColumns, pasteAreas } = pasteInfo;
    const { newValue, childrenPatches } = this.props.comp.doPasteData(copyData, pasteAreas, addRows, addColumns);
    const unitPasteArea = depthClone(pasteAreas).reduce((area1, area2) => tableHelper.uniteSelectArea(area1, area2));
    this.setSelectedArea(unitPasteArea);
    // this.updateSelectComps([]);
    // 修复粘贴时因为table采取比例分配列宽导致实际列有宽度问题
    const sizePathchs = this.props.comp.updateTextCompSize(newValue);
    mergePatches(childrenPatches, sizePathchs);
    this.doSubmit(newValue, childrenPatches);
    const rows = Array.from(new Set(this.selectedComps.map((comp) => comp.row)));
    this.refreshTableCellRowHeight(rows); // 粘贴内容后应该刷新行高
  };

  handleEditorMouseDown = (e: React.MouseEvent) => {
    // 鼠标中间按下可以拖拽
    if (e.buttons === MouseButtons.Wheel) {
      return;
    }
    if (!(e.target as Element).closest('.drag-handler') && (e.buttons !== MouseButtons.Left || isInputting())) {
      e.stopPropagation();
    }
  };

  /**
   * 复制表格选中区域到localstorage
   */
  handleCopyCells = async () => {
    const { tableValue, selectArea } = this.state;
    if (!selectArea) {
      return;
    }
    const { selectedComps } = this.props.comp;
    // localstorage存储表格数据
    const cellComponents = this.props.comp.getCellCompsFromArea(selectArea) || [];
    const copyData = tableHelper.TableClipboardHelper.buildTableClipboardData(
      tableValue,
      selectArea,
      selectedComps,
      cellComponents,
    );
    copyTableCellsData(copyData);
    if (copyData) {
      const html = tableHelper.TableClipboardHelper.buildHtmlByTableClipboardData(copyData);
      //如果复制报错，回退到以前只对内复制
      await copyHtmlOrText(html, ClipboardType.Table_Copy).catch(() => {
        copy(ClipboardType.Table_Copy);
      });
    }
    // AppOptions.tableClipboardData
  };

  /**
   * 剪切表格选中区域到localstorage
   */
  handleCutCells = () => {
    this.handleCopyCells();
    // 删除选中区
    this.cleanCellsContentAndStyle();
  };
  /**
   * 选择框向上移动
   */
  selectedUpper() {
    const { tableValue, selectArea } = this.state;
    if (!selectArea) {
      return;
    }
    const { rows, showHeader } = tableValue;
    const newPostion = depthClone(selectArea.start);
    let resultPostion: ICellPosition | undefined = undefined;
    let curCell = tableHelper.findCell(tableValue, newPostion);
    const minR = showHeader ? -1 : 0;
    const minC = 0;
    const maxR = rows.length - 1;
    // 下一个单元格
    do {
      if (newPostion.row > minR) {
        newPostion.row -= 1;
      } else if (newPostion.column > minC) {
        newPostion.row = maxR;
        newPostion.column -= 1;
      }
      curCell = tableHelper.findCell(tableValue, newPostion);
      if (curCell && _.isUndefined(curCell.mergedBy)) {
        resultPostion = newPostion;
      }
    } while (!(newPostion.column === minC && newPostion.row === minR) && !(curCell && _.isUndefined(curCell.mergedBy)));
    if (!resultPostion || !curCell) {
      return;
    }
    const newArea = tableHelper.getAreaOfCell(tableValue, curCell, resultPostion);
    this.setSelectedArea(newArea);
  }

  /**
   * 选择框向下移动
   */
  selectedLower() {
    const { tableValue, selectArea } = this.state;
    if (!selectArea) {
      return;
    }
    const { rows, columns, showHeader } = tableValue;
    const newPostion = depthClone(selectArea.start);
    let resultPostion: ICellPosition | undefined = undefined;
    let curCell = tableHelper.findCell(tableValue, newPostion);
    const maxR = rows.length - 1;
    const maxC = columns.length - 1;
    const minR = showHeader ? -1 : 0;
    // 下一个单元格
    do {
      if (newPostion.row < maxR) {
        newPostion.row += 1;
      } else if (newPostion.column < maxC) {
        newPostion.row = minR;
        newPostion.column += 1;
      }
      curCell = tableHelper.findCell(tableValue, newPostion);
      if (curCell && _.isUndefined(curCell.mergedBy)) {
        resultPostion = newPostion;
      }
    } while (!(newPostion.column === maxC && newPostion.row === maxR) && !(curCell && _.isUndefined(curCell.mergedBy)));
    if (!resultPostion || !curCell) {
      return;
    }
    const newArea = tableHelper.getAreaOfCell(tableValue, curCell, resultPostion);
    this.setSelectedArea(newArea);
  }

  /**
   * 选择框向左移动
   */
  selectedLeft() {
    const { tableValue, selectArea } = this.state;
    if (!selectArea) {
      return;
    }
    const { rows, showHeader } = tableValue;
    const newPostion = depthClone(selectArea.start);
    let resultPostion: ICellPosition | undefined = undefined;
    let curCell = tableHelper.findCell(tableValue, newPostion);
    const minR = showHeader ? -1 : 0;
    const minC = 0;
    const maxR = rows.length - 1;
    // 下一个单元格
    do {
      if (newPostion.column > minC) {
        newPostion.column -= 1;
      } else if (newPostion.row > minR) {
        newPostion.column = maxR;
        newPostion.row -= 1;
      }
      curCell = tableHelper.findCell(tableValue, newPostion);
      if (curCell && _.isUndefined(curCell.mergedBy)) {
        resultPostion = newPostion;
      }
    } while (!(newPostion.column === minC && newPostion.row === minR) && !(curCell && _.isUndefined(curCell.mergedBy)));
    if (!resultPostion || !curCell) {
      return;
    }
    const newArea = tableHelper.getAreaOfCell(tableValue, curCell, resultPostion);
    this.setSelectedArea(newArea);
  }

  /**
   * 选择框向右移动
   */
  selectedRight() {
    const { tableValue, selectArea } = this.state;
    if (!selectArea) {
      return;
    }
    const { rows, columns } = tableValue;
    const newPostion = depthClone(selectArea.start);
    let resultPostion: ICellPosition | undefined = undefined;
    let curCell = tableHelper.findCell(tableValue, newPostion);
    const maxR = rows.length - 1;
    const maxC = columns.length - 1;
    // 下一个单元格
    do {
      if (newPostion.column < maxC) {
        newPostion.column += 1;
      } else if (newPostion.row < maxR) {
        newPostion.column = 0;
        newPostion.row += 1;
      }
      curCell = tableHelper.findCell(tableValue, newPostion);
      if (curCell && _.isUndefined(curCell.mergedBy)) {
        resultPostion = newPostion;
      }
    } while (!(newPostion.column === maxC && newPostion.row === maxR) && !(curCell && _.isUndefined(curCell.mergedBy)));
    if (!resultPostion || !curCell) {
      return;
    }
    const newArea = tableHelper.getAreaOfCell(tableValue, curCell, resultPostion);
    this.setSelectedArea(newArea);
  }

  // /**
  //  * 键盘松开
  //  */
  // handleKeyUp = (e: React.KeyboardEvent) => {
  //   if (e.shiftKey) {
  //     this.shiftKey = false;
  //     if (this.operate === 'shiftSelect') {
  //       this.operate = undefined;
  //     }
  //   }
  // };

  /**
   * 列宽拖拽
   */
  handleWidthDrag = (columnIndex: number) => {
    if (this.state.editorBounds) return;
    const { tableValue, selectColumns } = this.state;
    const { columns } = tableValue;
    const originWidth = columns[columnIndex].width;
    let originX = 0;
    for (let i = 0; i <= columnIndex; i++) {
      originX += columns[i].width;
    }
    this.setState({ cursor: 'ew-resize' });
    dragDelegate(
      (e, delta) => {
        this.operate = 'resizeWidth';
        const { dragSize, linePosition } = this.getChangesInSizeDrag(originWidth, originX, delta.x, this.minWidth);
        this.dragSize = dragSize;
        this.lineX = linePosition;
        this.forceUpdate();
      },
      (e, delta) => {
        this.operate = undefined;
        if (delta.x) {
          columns.forEach((column, i) => {
            if (selectColumns.includes(i) && selectColumns.includes(columnIndex)) {
              column.width = Math.max(originWidth + delta.x / this.props.scale, this.minWidth);
            } else if (i === columnIndex) {
              column.width = Math.max(originWidth + delta.x / this.props.scale, this.minWidth);
            }
          });
          this.setState({ tableValue }, () => {
            const patches = this.props.comp.updateTextCompSize(tableValue);
            this.doSubmit(tableValue, patches);
            this.refreshTableCellRowHeight(tableValue.rows.map((row, rowIndex) => rowIndex));
          });
        }
        this.setState({ cursor: undefined });
      },
    );
  };

  /**
   * 高宽拖拽过程中的虚线位置和尺寸改变
   */
  getChangesInSizeDrag = (originSize: number, originPosition: number, delta: number, minSize: number) => {
    const { scale } = this.props;
    const scaleDelta = delta / scale;
    const isOver = originSize + scaleDelta < minSize;
    return {
      dragSize: isOver ? minSize : originSize + scaleDelta,
      linePosition: isOver ? originPosition + minSize - originSize : originPosition + scaleDelta,
    };
  };

  private handleCellVerticalBorderMouseDown(index: number, e: React.MouseEvent) {
    e.stopPropagation();
    this.handleHeightDrag(index);
  }

  private handleCellHorizontalBorderMouseDown(index: number, e: React.MouseEvent) {
    e.stopPropagation();
    this.handleWidthDrag(index);
  }

  /**
   * 行高拖拽
   */
  private handleHeightDrag = (rowIndex: number) => {
    if (this.state.editorBounds) return;
    const { tableValue, selectRows } = this.state;
    const { rows, headerHeight, showHeader } = tableValue;
    let originHeight = headerHeight;
    if (rowIndex !== -1) {
      originHeight = rows[rowIndex].height;
    }
    let originY = 0;
    for (let i = -1; i <= rowIndex; i++) {
      if (i === -1) {
        showHeader && (originY += headerHeight);
      } else {
        originY += rows[i].height;
      }
    }
    this.setState({ cursor: 'ns-resize' });
    dragDelegate(
      (e, delta) => {
        this.operate = 'resizeHeight';
        const { dragSize, linePosition } = this.getChangesInSizeDrag(originHeight, originY, delta.y, this.minHeight);
        this.dragSize = dragSize;
        this.lineY = linePosition;
        this.forceUpdate();
      },
      (e, delta) => {
        this.operate = undefined;
        if (delta.y) {
          rows.forEach((row, i) => {
            if (selectRows.includes(i) && selectRows.includes(rowIndex)) {
              row.height = Math.max(originHeight + delta.y / this.props.scale, this.minHeight);
              // 当前手动调整高度时，固定行高，不跟随内容自增高
              row.fixedHeight = true;
            } else if (i === rowIndex) {
              row.height = Math.max(originHeight + delta.y / this.props.scale, this.minHeight);
              row.fixedHeight = true;
            }
          });
          if (rowIndex === -1 || (selectRows.includes(-1) && selectRows.includes(rowIndex))) {
            tableValue.headerHeight = Math.max(originHeight + delta.y / this.props.scale, this.minHeight);
          }
          this.setState({ tableValue }, () => {
            this.doSubmit(tableValue);
          });
        }
        this.setState({ cursor: undefined });
      },
    );
  };

  handleCellAutoFitContent = (rowIndex: number, colIndex: number, direction: 'vertical' | 'horizontal') => {
    const { tableValue } = this.state;
    const { rows, columns } = tableValue;

    if (direction === 'horizontal') {
      rows.forEach((row, i) => {
        if (rowIndex === i) {
          row.height = this.getRowMinHeight(rowIndex);
        }
      });
    } else if (direction === 'vertical') {
      columns.forEach((col, i) => {
        if (colIndex === i) {
          col.width = this.getColMinWidth(colIndex);
        }
      });
    }

    this.setState({ tableValue }, () => {
      const patches = this.props.comp.updateTextCompSize(tableValue);
      this.doSubmit(tableValue, patches);
    });
  };

  /**
   * 行拖拽增加
   */
  handleDragToAddRows = (e: React.MouseEvent) => {
    if (e.button !== MouseButton.Left) {
      return;
    }
    e.stopPropagation();
    const { comp, scale } = this.props;
    const { tableValue } = this.state;
    const lastRowIndex = tableValue.rows.length - 1;
    const defaultHeight = tableHelper.getRowHeight(tableValue, lastRowIndex);
    let isClick = true;
    this.setState({ cursor: 'pointer' });
    dragDelegate(
      (e, delta) => {
        if (delta.y) {
          isClick = false;
        }
        // this.operate = 'resizeHeight';
        this.rowAddOffset = max(delta.y / scale, 0);
        this.forceUpdate();
      },
      (e, delta) => {
        this.operate = undefined;
        let addCount = Math.ceil(max(delta.y, 0) / scale / defaultHeight);
        if (isClick) {
          addCount = 1;
        }
        this.rowAddOffset = undefined;
        if (addCount) {
          const { newValue, childrenPatches } = comp.pushRows([lastRowIndex], addCount);
          this.clearSelectArea();
          this.setState({ tableValue: newValue, cursor: undefined }, () => {
            this.doSubmit(newValue, childrenPatches);
          });
        } else {
          this.setState({ cursor: undefined });
        }
      },
    );
  };

  /**
   * 列拖拽增加
   */
  handleDragToAddColumns = (e: React.MouseEvent) => {
    if (e.button !== MouseButton.Left) {
      return;
    }
    e.stopPropagation();
    const { comp, scale } = this.props;
    const { tableValue } = this.state;
    const lastColumnIndex = tableValue.columns.length - 1;
    const defaultWidth = tableHelper.getColumnWidth(tableValue, lastColumnIndex);
    let isClick = true;
    this.setState({ cursor: 'pointer' });
    dragDelegate(
      (e, delta) => {
        if (delta.x) {
          isClick = false;
        }
        // this.operate = 'resizeHeight';
        this.columnAddOffset = max(delta.x / scale, 0);
        this.forceUpdate();
      },
      (e, delta) => {
        this.operate = undefined;
        let addCount = Math.ceil(max(delta.x, 0) / scale / defaultWidth);
        if (isClick) {
          addCount = 1;
        }
        this.columnAddOffset = undefined;
        if (addCount) {
          const { newValue, childrenPatches } = comp.pushColumns([lastColumnIndex], addCount);
          this.clearSelectArea();
          this.setState({ tableValue: newValue, cursor: undefined }, () => {
            this.doSubmit(newValue, childrenPatches);
          });
        } else {
          this.setState({ cursor: undefined });
        }
      },
    );
  };

  handleMouseMove = (e: MouseEvent) => {
    const { pageX, pageY } = e;
    this.cursorPosition = { x: pageX, y: pageY };
  };

  handleMouseUp = (e: MouseEvent) => {
    this.operate = undefined;
    const editorDom = this.tableEditor.current;
    // 鼠标按住，会导致selectBox层在上层，e.target变成了selectBox层下面的元素
    if (
      (!this.state.editorBounds && editorDom && editorDom.contains(e.target as Node)) ||
      (this.state.selectArea && e.target && (e.target as HTMLDivElement).closest('.selection-box'))
    ) {
      // 增加selectArea判断单元格是否处于选中状态，放到setTimeout下执行，解决workspace工作聚焦影响
      setTimeout(() => {
        this.handleInputFocus();
      });
    }
  };

  handleMouseDown = (e: MouseEvent) => {
    const { pageX: left, pageY: top } = e;
    const { editorBounds } = this.state;
    const self = this.tableEditor.current!;
    if (self.contains(e.target as HTMLElement) || editorBounds) {
      this.closeMenu();
      return;
    }
    if (this.context.uiManager.statePanel?.isContainerPagePoint(left, top)) {
      this.closeMenu();
      return;
    }
    let bounds = BoundsUtils.create(self.getBoundingClientRect());
    bounds = BoundsUtils.inflate(bounds, { left: 5, top: 5 });

    if (BoundsUtils.isContainerPoint(bounds, { left, top })) {
      this.closeMenu();
      return;
    }
    // 点击到弹出上时
    let dom = document.querySelector('.popup-with-body');
    if (dom) {
      const bounds = dom.getBoundingClientRect();
      const isContainsDom = dom.contains(e.target as HTMLElement);
      const isContainsPoint = BoundsUtils.isContainerPoint(bounds, { left, top });
      if (isContainsDom || isContainsPoint) {
        this.closeMenu();
        return;
      }
    }
    // 点击到右侧面板时
    dom = document.querySelector('.right-panel');
    if (dom) {
      const bounds = dom.getBoundingClientRect();
      if (BoundsUtils.isContainerPoint(bounds, { left, top })) {
        return;
      }
    }
    this.context.uiManager.closeTableEditor();
  };

  handleWindowKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === KeyCodeMap.VK_TAB) {
      e.preventDefault();
    }
  };

  handleWindowKeyUpCapture = (e: KeyboardEvent) => {
    this.keyPress.delete(e.key?.toLowerCase());
    this.shiftKey = false;
    if (this.operate === 'shiftSelect') {
      this.operate = undefined;
    }
  };

  private get isTableInputFocused() {
    return this.valueEditor.current?.inputDom === document.activeElement;
  }

  /**
   * 输入状态下键盘切换单元格
   */
  handleKeyDownCapture = async (e: KeyboardEvent) => {
    // 在合成输入时，不应该对任何热键做出反应
    const key = e.key?.toLowerCase();
    if (e.isComposing || this.keyPress.has(key)) {
      return;
    }
    this.keyPress.add(key);
    this.shiftKey = e.shiftKey;
    const altPressed = e.altKey;
    const focusInEditor = this.isTableInputFocused;
    const { editorBounds } = this.state;
    const selectedCells = focusInEditor && !editorBounds;
    const editingCells = focusInEditor && editorBounds;

    const hotKey = convertEventToHotKey(e);
    if (textStyleShortCut.includes(hotKey) && selectedCells) {
      e.stopPropagation();
      e.preventDefault();
      this.executeTextStyleHotKey(hotKey);
      return;
    }
    // 增加在单元格选中状态话，可以回退操作
    const command = getCommandByHotKey(hotKey);
    // 在单格非编辑状态下，任何时候都能撤销
    if ((command === EditorCommand.Undo || command === EditorCommand.Redo) && !editingCells) {
      const coreEditor = this.context.coreEditor;
      if (coreEditor && command !== null) {
        this.props.comp.needUpdateCellRightPanel = true;
        coreEditor.execCommand(command);
      }
      e.stopPropagation();
      return;
    }
    // 如果单元格按的撤销，并且不在
    switch (hotKey) {
      case 'tab': {
        e.stopPropagation();
        if (editingCells) {
          e.preventDefault();
          // 切换至下一个单元格进行编辑
          this.doNextCellEditing();
        } else if (selectedCells) {
          e.preventDefault();
          this.selectedRight();
        }
        break;
      }
      case 'arrowright': {
        if (selectedCells) {
          e.preventDefault();
          this.selectedRight();
        }
        break;
      }
      case 'arrowleft': {
        if (selectedCells) {
          e.preventDefault();
          this.selectedLeft();
        }
        break;
      }
      case 'arrowup': {
        if (selectedCells) {
          e.preventDefault();
          this.selectedUpper();
        }
        break;
      }
      case 'arrowdown': {
        if (selectedCells) {
          e.preventDefault();
          this.selectedLower();
        }
        break;
      }
      case 'enter':
      case 'ctrl+enter': {
        if (selectedCells) {
          e.preventDefault();
          // this.handleContentEdit();
          this.selectedLower();
        } else if (editingCells && !altPressed && !this.shiftKey && hotKey === 'enter') {
          e.preventDefault();
          // 切换至下一个单元格进行编辑
          this.doNextCellEditing(false);
        }
        break;
      }
      case 'backspace':
      case 'delete':
        if (selectedCells) {
          e.preventDefault();
          e.stopPropagation();
          const rows = Array.from(new Set(this.selectedComps.map((comp) => comp.row)));
          this.cleanCellsContent();
          this.refreshTableCellRowHeight(rows);
        }
        break;
      case 'ctrl+a':
        if (selectedCells) {
          e.preventDefault();
          e.stopPropagation();
          this.selectAllCells();
        }
        break;
      case 'ctrl+c':
        if (selectedCells) {
          e.preventDefault();
          e.stopPropagation();
          await this.handleCopyCells();
          // copy失败导致焦点不在编辑区域，复制完成，将焦点移会编辑区域
          this.valueEditor.current?.inputDom?.focus();
          // mac系统，按住其余键，松开单个键，keyup不会触发，复制完成后将c移出keyPress
          if (isMacOS) {
            this.keyPress.delete(key);
          }
        }
        break;
      case 'ctrl+x':
        if (selectedCells) {
          e.preventDefault();
          e.stopPropagation();
          this.handleCutCells();
        }
        break;
      //选中单元格时,并且非编辑状态，使用esc，应选中整个表格组件；
      case 'escape': {
        const isSelect = this.context.uiManager.tableEditor?.selectArea;
        const coreEditor = this.context.coreEditor;
        const { comp } = this.props;
        if (!this.editCellPosition && isSelect) {
          e.stopPropagation();
          e.preventDefault();
          this.clearSelectArea();
          this.switchSelectFrame(true);
        } else if (!this.editCellPosition && !isSelect && coreEditor?.firstSelectedComponent?.id === comp.id) {
          //选中整个表格状态下，再次esc 应该取消选中整个表格
          coreEditor.unSelect([comp]);
        }
        break;
      }
      default:
        break;
    }
  };

  private executeTextStyleHotKey(hotkey: string) {
    const coreEditor = this.context.coreEditor;
    if (!coreEditor) {
      return;
    }
    const command = getCommandByHotKey(hotkey);
    if (command !== null) {
      coreEditor.execTextCommand(command, this.selectedComps);
      this.handleInputFocus();
    }
  }

  doNextCellEditing = (right = true) => {
    if (!this.editCellPosition) {
      return;
    }
    const { tableValue } = this.state;
    const { rows, columns } = tableValue;
    const newPostion = depthClone(this.editCellPosition);
    let resultPostion: ICellPosition | undefined = undefined;
    let curCell = tableHelper.findCell(tableValue, newPostion);
    const maxR = rows.length - 1;
    const maxC = columns.length - 1;
    // 下一个单元格
    do {
      if (right) {
        if (newPostion.column < maxC) {
          newPostion.column += 1;
        } else if (newPostion.row < maxR) {
          newPostion.column = 0;
          newPostion.row += 1;
        }
      } else {
        if (newPostion.row < maxR) {
          newPostion.row += 1;
        } else if (newPostion.column < maxC) {
          newPostion.row = tableValue.showHeader ? -1 : 0;
          newPostion.column += 1;
        }
      }
      curCell = tableHelper.findCell(tableValue, newPostion);
      if (curCell && _.isUndefined(curCell.mergedBy)) {
        resultPostion = newPostion;
      }
    } while (!(newPostion.column === maxC && newPostion.row === maxR) && !(curCell && _.isUndefined(curCell.mergedBy)));
    if (!resultPostion || !curCell) {
      return;
    }
    // 保存修改并退出
    this.exitTextEditor();
    const newArea = tableHelper.getAreaOfCell(tableValue, curCell, resultPostion);
    // const newArea: ISelectArea = {
    //   start: depthClone(resultPostion),
    //   end: depthClone(resultPostion),
    // };
    this.setSelectedArea(newArea, undefined, () => {
      // const dom = this.valueEditor.current?.inputDom;
      // if (dom instanceof HTMLDivElement) {
      //   dom.innerHTML = '';
      // } else {
      //   dom && (dom.value = '');
      // }
      this.handleContentEdit();
    });
  };

  exitTextEditor = () => {
    this.valueEditor.current?.doTextEditorExit();
  };

  /**
   * 全选选择器鼠标按下
   */
  handleDragHandlerMouseDown = (e: React.MouseEvent) => {
    if (this.state.editorBounds) return;
    // const { coreEditor } = this.context;
    this.clearSelectArea();
    e.preventDefault();
    // dragDelegate(
    //   (e: MouseEvent, delta: { x: number; y: number }) => {
    //     if (!coreEditor) {
    //       return;
    //     }
    //     const { scale, doTablehidden } = this.props;
    //     doTablehidden(true);
    //     this.setState({
    //       editorPosition: {
    //         x: Math.floor(delta.x / scale),
    //         y: Math.floor(delta.y / scale),
    //       },
    //     });
    //   },
    //   () => {
    //     const { comp, doTablehidden } = this.props;
    //     const { position } = comp;
    //     const { editorPosition } = this.state;
    //     if (!editorPosition || (!editorPosition.x && !editorPosition.y) || !coreEditor) {
    //       // this.selectAllCells();
    //       return;
    //     }
    //     const newPosition = {
    //       x: position.x + editorPosition.x,
    //       y: position.y + editorPosition.y,
    //     };
    //     doTablehidden(false);
    //     this.setState({ editorPosition: undefined });
    //     coreEditor.positionChange(newPosition);
    //   },
    // );
  };

  /**
   * 单元格上鼠标按下
   */
  handleCellMouseDown = (row: number, column: number, e?: React.MouseEvent) => {
    if (this.state.editorBounds || e?.button === MouseButton.Wheel) {
      return;
    }
    const { selectArea, tableValue } = this.state;
    if (e?.button === MouseButton.Right && tableHelper.isCellSelected(row, column, selectArea)) {
      return;
    }
    this.downTime = Date.now();
    this.operate = this.shiftKey ? 'shiftSelect' : 'cellSelect';
    e && e.stopPropagation();
    if (this.operate === 'shiftSelect' && selectArea) {
      const { start, end } = selectArea;
      this.startRow = min(row, start.row);
      this.startColumn = min(column, start.column);
      const endRow = max(row, end.row);
      const endColumn = max(column, end.column);
      this.clearRowsAndColumns();
      const area: ISelectArea = {
        start: {
          row: this.startRow,
          column: this.startColumn,
        },
        end: {
          row: endRow,
          column: endColumn,
        },
      };
      // 获取合并的全部区域
      tableHelper.toRealAreaOfSelectArea(tableValue, area);
      this.setSelectedArea(area);
    } else {
      this.startCell = { row, column };
      const cell = tableHelper.findCell(tableValue, { row, column });
      if (cell) {
        const selectArea = tableHelper.getAreaOfCell(tableValue, cell, { row, column });
        this.clearRowsAndColumns();
        this.setSelectedArea(selectArea);
      }
    }
  };

  /**
   * 列选择器上鼠标按下
   */
  handleColumnMouseDown = (column: number, e: React.MouseEvent) => {
    e.stopPropagation();
    if (this.state.editorBounds) return;
    if (this.state.selectColumns.includes(column)) {
      if (e.button === MouseButton.Left && !this.isSelectedAll) {
        this.operate = 'columnsDrag';
        this.doRowOrColumnDragging();
      }
      return;
    }
    this.operate = this.shiftKey ? 'shiftSelect' : 'columnsSelect';
    if (this.operate === 'shiftSelect') {
      this.doColumnShiftSelect(column);
    } else {
      const selectArea = this.selectColumns(column, column);
      this.startColumn = column;
      this.clearRowsAndColumns();
      this.setSelectColumns([column]);
      this.setSelectedArea(selectArea);
    }
  };

  /**
   * 通过首尾选中多列
   */
  doColumnsSelect = (startCol: number, endCol: number) => {
    this.startColumn = startCol;
    this.clearRowsAndColumns();
    const area = this.selectColumns(startCol, endCol);
    const colCount = endCol - startCol + 1;
    const cols = new Array(colCount).fill(1).map((item, i) => startCol + i);
    this.setSelectColumns(cols);
    this.setSelectedArea(area);
  };

  /**
   * 按下shift时，对列的选择进行合并
   */
  doColumnShiftSelect = (selectCol: number) => {
    if (this.isSelectedAll) {
      return;
    }
    const { selectRows, selectColumns, selectArea } = this.state;
    if (selectRows.length) {
      this.doColumnsSelect(0, selectCol);
    } else if (selectColumns.length) {
      this.doColumnsSelect(min(selectCol, selectColumns[0]), max(selectCol, selectColumns[selectColumns.length - 1]));
    } else if (selectArea) {
      const { start, end } = selectArea;
      this.doColumnsSelect(min(selectCol, start.column), max(selectCol, end.column));
    }
  };

  /**
   * 列选择器上鼠标移入
   */
  handleColumnMouseEnter = (column: number) => {
    switch (this.operate) {
      case 'columnsSelect': {
        const selectArea = this.selectColumns(this.startColumn || 0, column);
        this.clearRowsAndColumns();
        const selectColumns = new Array(selectArea.end.column - selectArea.start.column + 1)
          .fill('')
          .map((item, i) => selectArea.start.column + i);
        this.setSelectColumns(selectColumns);
        this.setSelectedArea(selectArea);
        break;
      }
      default:
        break;
    }
  };

  /**
   * 行选择器上鼠标按下
   */
  handleRowMouseDown = (row: number, e: React.MouseEvent) => {
    e.stopPropagation();
    if (this.state.editorBounds) return;
    if (this.state.selectRows.includes(row)) {
      if (e.button === MouseButton.Left && !this.isSelectedAll) {
        this.operate = 'rowsDrag';
        this.doRowOrColumnDragging();
      }
      return;
    }
    this.operate = this.shiftKey ? 'shiftSelect' : 'rowsSelect';
    if (this.operate === 'shiftSelect') {
      this.doRowShiftSelect(row);
    } else {
      const selectArea = this.selectRows(row, row);
      this.startRow = row;
      this.clearRowsAndColumns();
      this.setSelectRows([row]);
      this.setSelectedArea(selectArea);
    }
  };

  /**
   * 按下shift时，对行的选择进行合并
   */
  doRowShiftSelect = (selectRow: number) => {
    if (this.isSelectedAll) {
      return;
    }
    const {
      selectRows,
      selectColumns,
      selectArea,
      tableValue: { showHeader },
    } = this.state;

    if (selectColumns.length) {
      this.doRowsSelect(showHeader ? -1 : 0, selectRow);
    } else if (selectRows.length) {
      this.doRowsSelect(min(selectRow, selectRows[0]), max(selectRow, selectRows[selectRows.length - 1]));
    } else if (selectArea) {
      const { start, end } = selectArea;
      this.doRowsSelect(min(selectRow, start.row), max(selectRow, end.row));
    }
  };

  /**
   * 通过首尾选中多行
   */
  doRowsSelect = (startRow: number, endRow: number) => {
    this.startRow = startRow;
    this.clearRowsAndColumns();
    const area = this.selectRows(startRow, endRow);
    const rowCount = endRow - startRow + 1;
    const rows = new Array(rowCount).fill(1).map((item, i) => startRow + i);
    this.setSelectRows(rows);
    this.setSelectedArea(area);
  };

  /**
   * 行选择器上鼠标移入
   */
  handleRowMouseEnter = (row: number) => {
    switch (this.operate) {
      case 'rowsSelect': {
        const selectArea = this.selectRows(this.startRow || 0, row);
        const selectRows = new Array(Math.abs(row - (this.startRow || 0)) + 1)
          .fill('')
          .map((item, i) => selectArea.start.row + i);
        this.clearRowsAndColumns();
        this.setSelectRows(selectRows);
        this.setSelectedArea(selectArea);
        break;
      }
      default:
        break;
    }
  };

  /**
   * 单元格上鼠标移入
   */
  handleCellMouseEnter = (row: number, column: number) => {
    // 解决down行高变化后，enter移入错误的单元格
    if (this.downTime && Date.now() - this.downTime < 50) {
      return;
    }
    switch (this.operate) {
      case 'cellSelect': {
        const originSelectArea: ISelectArea = {
          start: { ...(this.startCell || { row: 0, column: 0 }) },
          end: { row, column },
        };
        tableHelper.resetSelectAreaOrigin(originSelectArea);
        tableHelper.toRealAreaOfSelectArea(this.state.tableValue, originSelectArea);
        this.clearRowsAndColumns();
        this.setSelectedArea(originSelectArea);
        break;
      }
      case 'columnsSelect': {
        const selectArea = this.selectColumns(this.startColumn || 0, column);
        this.clearRowsAndColumns();
        const selectColumns = new Array(selectArea.end.column - selectArea.start.column + 1)
          .fill('')
          .map((item, i) => selectArea.start.column + i);
        this.setSelectColumns(selectColumns);
        this.setSelectedArea(selectArea);
        break;
      }
      case 'rowsSelect': {
        const selectArea = this.selectRows(this.startRow || 0, row);
        const selectRows = new Array(Math.abs(row - (this.startRow || 0)) + 1)
          .fill('')
          .map((item, i) => selectArea.start.row + i);
        this.clearRowsAndColumns();
        this.setSelectRows(selectRows);
        this.setSelectedArea(selectArea);
        break;
      }
      case 'smartCorner': {
        break;
      }
      default:
        break;
    }
  };

  /**
   * 选中所在单元格
   */
  handleSelectedCellByPosition = (point: IPosition, canDrag: boolean) => {
    const { tableValue } = this.state;
    const el = this.cells.find((c) => {
      if (!c) {
        return false;
      }
      const bounds = BoundsUtils.create(c.getBoundingClientRect());
      return BoundsUtils.isContainerPoint(bounds, { top: point.y, left: point.x }, true);
    });
    if (!el) {
      return false;
    }
    const { column, row } = el.dataset;
    const _column = parseInt(column || '0');
    const _row = parseInt(row || '0');
    const position = { row: isNaN(_row) ? 0 : _row, column: isNaN(_column) ? 0 : _column };
    const currentCell = tableHelper.findCell(tableValue, position);
    if (!currentCell) {
      return;
    }
    if (canDrag) {
      this.handleCellMouseDown(position.row, position.column);
    } else {
      const area = tableHelper.getAreaOfCell(tableValue, currentCell, position);
      this.setSelectedArea(area, true);
    }
  };

  /**
   * 行列拖拽
   */
  doRowOrColumnDragging = () => {
    const { tableValue, selectArea } = this.state;
    if (this.operate !== 'rowsDrag' && this.operate !== 'columnsDrag') {
      return;
    }
    if (!selectArea || (selectArea.start.row === -1 && this.operate === 'rowsDrag')) {
      return;
    }
    this.setState({ cursor: 'grabbing' });
    const isDragRows = this.operate === 'rowsDrag';
    dragDelegate(
      (e: MouseEvent, delta: { x: number; y: number }) => {
        const { scale } = this.props;
        const curOffset = {
          x: Math.floor(delta.x / scale),
          y: Math.floor(delta.y / scale),
        };

        const { left, top, width, height } = tableHelper.getBoundsOfSelectArea(tableValue, selectArea);
        const size = tableHelper.getTableSizeFromValue(tableValue);
        const offset: IPosition = {
          x: !isDragRows ? curOffset?.x || 0 : 0,
          y: isDragRows ? curOffset?.y || 0 : 0,
        };
        const minTop = tableValue.showHeader ? tableValue.headerHeight : 0;
        const position = {
          left: max(min(size.width - width, left + offset.x), 0),
          top: max(min(size.height - height, top + offset.y), minTop),
        };
        this.dragOffset = {
          x: position.left - left,
          y: position.top - top,
        };
        const start = isDragRows ? selectArea.start.row : selectArea.start.column;
        const end = isDragRows ? selectArea.end.row : selectArea.end.column;
        const offsetData = isDragRows ? this.dragOffset.y : this.dragOffset.x;
        this.newDragIndex = tableHelper.getNewIndexAfterMoved(tableValue, start, end, offsetData, isDragRows);
        if (this.newDragIndex === start) {
          this.newDragIndex = undefined;
        }
        this.forceUpdate();
      },
      () => {
        if (_.isNumber(this.newDragIndex)) {
          const start = isDragRows ? selectArea.start.row : selectArea.start.column;
          const end = isDragRows ? selectArea.end.row : selectArea.end.column;
          const { enable, text } = isDragRows
            ? tableHelper.canMoveRows(tableValue, start, end, this.newDragIndex)
            : tableHelper.canMoveColumns(tableValue, start, end, this.newDragIndex);

          text && this.context?.uiManager?.updateAlert(text);
          if (enable) {
            const { comp } = this.props;
            const { newValue, childrenPatches } = isDragRows
              ? comp.moveRows(start, end, this.newDragIndex)
              : comp.moveColumns(start, end, this.newDragIndex);
            this.doSubmit(newValue, childrenPatches);
            this.clearSelectArea();
          }
          this.newDragIndex = undefined;
        }
        this.dragOffset = undefined;
        this.setState({ cursor: undefined });
      },
    );
  };

  /**
   * 关闭菜单
   */
  closeMenu = () => {
    if (this.state.menuPosition) {
      this.setState({ menuPosition: undefined });
    }
  };

  /**
   * 进入内容文本编辑
   */
  handleContentEdit = () => {
    const { selectArea } = this.state;

    if (!selectArea || !this.selectBox.current) return;
    const cellPosition = selectArea.start;
    const cell = tableHelper.findCell(this.state.tableValue, cellPosition);
    if (!cell) return;
    const realArea = tableHelper.getAreaOfCell(this.state.tableValue, cell, cellPosition);
    const bounds = tableHelper.getBoundsOfSelectArea(this.state.tableValue, realArea);
    const comp = this.props.comp.components.find(
      (comp) => comp.row === cellPosition.row && comp.column === cellPosition.column,
    );
    if (comp) {
      this.selectedComps = [comp];
    }

    this.editCellPosition = cellPosition;
    this.setState({ editorBounds: bounds }, () => {
      const tableEditor = this.context.uiManager.tableEditor;
      if (tableEditor) {
        tableEditor.textEditing = true;
      }
    });
  };

  handleTyped = () => {
    if (!this.state.editorBounds) {
      this.handleContentEdit();
    }
  };

  /**
   * 值编辑
   */
  handleValueChange = (value: IComponentValue) => {
    const { tableValue } = this.state;
    if (!this.editCellPosition) return;
    const cell = tableHelper.findCell(tableValue, this.editCellPosition);
    if (!cell) return;
    const realArea = tableHelper.getAreaOfCell(tableValue, cell, this.editCellPosition);
    const bounds = tableHelper.getBoundsOfSelectArea(tableValue, realArea);
    const defaultWidth = bounds.width - 2 * DefaultCellPadding;
    const comp = this.selectedComps.find(
      (item) => item.row === realArea.start.row && item.column === realArea.start.column,
    );
    if (comp) {
      if (comp.type === CText) {
        this.editText(value, comp, tableValue, defaultWidth);
      } else {
        // 编辑复选框
        this.editOther(comp, value);
      }
    } else {
      this.createText(tableValue, value, defaultWidth);
    }
    this.refreshTableCellRowHeight([realArea.start.row]);
  };

  /**
   * 刷新行的最大高度
   */
  private refreshTableCellRowHeight = (rows: number[], resetFixedRowHeight: boolean = false) => {
    // 需要刷新的行
    if (!rows || (rows && !rows.length)) {
      return;
    }

    let isNeedSubmit = false;
    const tableValue = _.cloneDeep(this.state.tableValue);
    rows.forEach((rowIndex) => {
      const currentRow = tableValue.rows[rowIndex];
      const rowMaxHeight = this.props.comp.components.reduce((maxHeight, comp) => {
        if (comp.row === rowIndex) {
          return Math.max(maxHeight, comp.size.height);
        }
        return maxHeight;
      }, DefaultCellHeight);
      // 当前行高固定时和当前行高大于内容高度时，不应该改变现在高度
      if (!resetFixedRowHeight && (currentRow.fixedHeight || rowMaxHeight <= currentRow.height)) {
        return;
      }
      isNeedSubmit = true;
      if (resetFixedRowHeight) {
        tableValue.rows[rowIndex].fixedHeight = false;
      }
      // 当前行如果大于默认高度时，需要加上单元格的内间距
      tableValue.rows[rowIndex].height =
        rowMaxHeight > DefaultCellHeight ? rowMaxHeight + DefaultCellPadding * 2 : rowMaxHeight;
    });
    isNeedSubmit && this.doSubmit(tableValue);
  };

  private isDoubleClick(e: React.MouseEvent): boolean {
    let isDoubleClick = false;
    // 模拟双击
    if (e.button === 0) {
      if (this.firstClickTimer === 0) {
        this.firstClickTimer = Date.now();
      } else {
        if (Date.now() - this.firstClickTimer < 250) {
          isDoubleClick = true;
          this.firstClickTimer = 0;
        } else {
          this.firstClickTimer = Date.now();
        }
      }
    }
    return isDoubleClick;
  }

  /**
   * 单元格拖拽边框拖拽
   */
  handleCellDragAreaMouseDown = (
    rowIndex: number,
    colIndex: number,
    direction: 'vertical' | 'horizontal',
    e: React.MouseEvent,
  ) => {
    e.stopPropagation();

    if (this.isDoubleClick(e)) {
      this.handleCellAutoFitContent(rowIndex, colIndex, direction);
    }

    if (direction === 'vertical') {
      this.handleWidthDrag(colIndex);
    } else if (direction === 'horizontal') {
      this.handleHeightDrag(rowIndex);
    }
  };

  /**
   * 计算单元格可拖拽边框是否处于合并的单元格中
   */
  doCellDragAreaInSelected = (type: 'right' | 'bottom', value: number) => {
    const { selectArea } = this.state;

    if (!selectArea) {
      return true;
    }

    const { start, end } = selectArea;

    if (_.isEqual(start, end)) {
      return true;
    }

    if (type === 'right') {
      return !(value >= start.column && value < end.column);
    } else if (type === 'bottom') {
      return !(value >= start.row && value < end.row);
    }
  };

  /**
   * 创建文本组件
   */
  createText = (tableValue: ITableValue, value: IComponentValue, defaultWidth: number) => {
    if (!this.editCellPosition || !value) return;
    const cell = tableHelper.findCell(tableValue, this.editCellPosition);
    if (!cell) {
      return;
    }
    // 新建
    const textFormat = jsonClone(Object.assign({}, getDefaultTextFormat(), cell.style.textFormat));
    const textComp = makeText(getNewID(), value.toString().replace(/@/g, '@@'), textFormat);
    textComp.row = this.editCellPosition.row;
    textComp.column = this.editCellPosition.column;
    textComp.properties.textFormat!.textAlign = cell.style.textAlign;
    textComp.properties.textFormat!.verticalAlign = cell.style.verticalAlign;
    textComp.properties.textFormat!.wrap = true;
    textComp.size = this.getTextSize(`${textComp.value}`, textComp.properties, defaultWidth);
    textComp.autoSize = true;
    textComp.textBehaviour = ETextBehaviour.Height;
    textComp.position = { x: 0, y: 0 };
    const { patches } = this.props.comp.addComponents([textComp]);
    this.doSubmit(tableValue, patches[this.props.comp.ownerArtboardID]);
  };

  /**
   * 修改文本以外的组件value
   */
  editOther = (comp: UIComponent, value: IComponentValue) => {
    if (comp.isSealed && comp.lib) {
      const comData = getComponent(comp.lib);
      if (comData?.isTextComp) {
        const textComp = getTextCompInSealed(comp as UIContainerComponent);
        if (textComp) {
          this.context.coreEditor?.setComponentValue(textComp, value);
          return;
        }
      }
    } else {
      this.context.coreEditor?.setValue(comp.type, value, [comp]);
      return;
    }
  };

  /**
   * 修改文本组件value
   */
  editText = (value: IComponentValue, comp: UIComponent, tableValue: ITableValue, defaultWidth: number) => {
    if (!this.editCellPosition) return;
    if (!value) {
      // 删除文本组件
      const { newValue, childrenPatches } = this.props.comp.cleanContent([this.editCellPosition]);
      this.selectedComps = [];
      this.doSubmit(newValue, childrenPatches);
      return;
    } else {
      // 修改文本组件
      const valuePatches = comp.setValue(value);
      const textSize = this.getTextSize(`${value}`, comp.properties, defaultWidth);
      const sizeOpt = comp.setSize(textSize);
      valuePatches.do[comp.id].push(...sizeOpt.do);
      valuePatches.undo[comp.id].push(...sizeOpt.undo);
      this.doSubmit(tableValue, valuePatches);
      return;
    }
  };

  getTextSize(value: string, properties: IProperties, defaultWidth: number) {
    const cssParser = StyleHelper.initCSSStyleParser(properties);
    const { width, height } = measureTextSize(cssParser.getTextStyle(), value, {
      isMultiText: true,
      isRich: true,
      wrap: true,
      defaultWidth,
    });
    return { width, height };
  }

  /**
   * 关闭文本编辑器
   */
  handleValueEditorExit = () => {
    this.setState({ editorBounds: undefined }, () => {
      const tableEditor = this.context.uiManager.tableEditor;
      if (tableEditor) {
        tableEditor.textEditing = false;
      }
      this.editCellPosition = undefined;
    });
  };

  /**
   * 列选择
   */
  selectColumns(start: number, end: number): ISelectArea {
    const {
      tableValue: { rows, showHeader },
    } = this.state;
    const startRow = showHeader ? -1 : 0;
    const endRow = rows.length - 1;
    const startColumn = Math.min(start, end);
    const endColumn = Math.max(start, end);
    return {
      start: { row: startRow, column: startColumn },
      end: { row: endRow, column: endColumn },
    };
  }

  /**
   * 行选择
   */
  selectRows(start: number, end: number) {
    const {
      tableValue: { columns },
    } = this.state;
    const startRow = Math.min(start, end);
    const endRow = Math.max(start, end);
    const startColumn = 0;
    const endColumn = columns.length - 1;
    return {
      start: { row: startRow, column: startColumn },
      end: { row: endRow, column: endColumn },
    };
  }

  /**
   * 取消选中按钮及拖拽按钮
   */
  renderDragHandler = () => {
    return (
      <div
        className={classNames('drag-handler', {
          actived: this.isSelectedAll,
          selected: this.state.selectedAll,
        })}
        onMouseDown={this.handleDragHandlerMouseDown}
      />
    );
  };

  /**
   * 列选择器
   */
  renderColumnSelector = () => {
    const { columns } = this.state.tableValue;
    return (
      <div className="column-selector" onContextMenu={this.handleContextMenu['column']}>
        {columns.map((column, i) => {
          return (
            <React.Fragment key={i}>
              <div
                className={classNames('column-item', {
                  selected: this.state.selectColumns.includes(i),
                  actived: this.activedColumns.includes(i),
                })}
                onMouseDown={this.handleColumnMouseDown.bind(this, i)}
                onMouseEnter={this.handleColumnMouseEnter.bind(this, i)}
                style={{
                  width: column.width,
                }}
              >
                {tableHelper.index2String(i)}
              </div>
              <div className="right-border">
                <div className="handle" onMouseDown={this.handleCellHorizontalBorderMouseDown.bind(this, i)} />
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  /**
   * 行选择器
   */
  renderRowSelector = () => {
    const { rows, showHeader, headerHeight } = this.state.tableValue;
    return (
      <div className="row row-wrap">
        <div className="row-selector" onContextMenu={this.handleContextMenu['row']}>
          {showHeader && (
            <React.Fragment>
              <div
                className={classNames('row-item', {
                  selected: this.state.selectRows.includes(-1),
                  actived: this.activedRows.includes(-1),
                })}
                onMouseDown={this.handleRowMouseDown.bind(this, -1)}
                onMouseEnter={this.handleRowMouseEnter.bind(this, -1)}
                style={{
                  height: headerHeight,
                  lineHeight: `${headerHeight}px`,
                }}
              >
                {1}
              </div>
              <div className="bottom-border">
                <div className="handle" onMouseDown={this.handleCellVerticalBorderMouseDown.bind(this, -1)} />
              </div>
            </React.Fragment>
          )}
          {rows.map((row, i) => {
            return (
              <React.Fragment key={i}>
                <div
                  className={classNames('row-item', {
                    selected: this.state.selectRows.includes(i),
                    actived: this.activedRows.includes(i),
                  })}
                  onMouseDown={this.handleRowMouseDown.bind(this, i)}
                  onMouseEnter={this.handleRowMouseEnter.bind(this, i)}
                  style={{
                    height: row.height,
                    lineHeight: `${row.height}px`,
                  }}
                >
                  {showHeader ? i + 2 : i + 1}
                </div>
                <div className="bottom-border">
                  <div className="handle" onMouseDown={this.handleCellVerticalBorderMouseDown.bind(this, i)} />
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  /**
   * 行列信息
   */
  renderTableInfoTips = () => {
    if (!this.columnAddOffset && !this.rowAddOffset) {
      return null;
    }
    if (!this.cursorPosition) {
      return null;
    }
    const { tableValue } = this.state;

    const { rows, columns, showHeader } = tableValue;

    const addColCount = min(
      MaxColumnsCount - columns.length,
      Math.ceil((this.columnAddOffset || 0) / DefaultCellWidth),
    );
    const addRowCount = min(MaxRowsCount - rows.length, Math.ceil((this.rowAddOffset || 0) / DefaultCellHeight));
    const rowCount = rows.length + addRowCount + (showHeader ? 1 : 0);
    const colCount = columns.length + addColCount;
    const { x, y } = this.cursorPosition;
    return ReactDOM.createPortal(
      <div
        className="table-info-tips"
        style={{
          top: y - 10,
          left: x + 10,
        }}
      >
        {i18n('editor.tableInfo', rowCount, colCount)}
      </div>,
      document.body,
    );
  };

  renderCursor = () => {
    const { cursor } = this.state;
    if (!cursor) {
      return null;
    }
    return ReactDOM.createPortal(<div className={classNames('table-editor-cursor', cursor)} />, document.body);
  };

  /**
   * 拖拽新增行
   */
  renderDynRows = (height: number) => {
    if (!this.rowAddOffset) return null;
    const { tableValue } = this.state;
    const { rows, columns } = tableValue;
    const lastRowIndex = rows.length - 1;
    const defaultHeight = tableHelper.getRowHeight(tableValue, lastRowIndex);
    const count = min(MaxRowsCount - rows.length, Math.ceil(this.rowAddOffset / defaultHeight));
    if (!count) return null;
    const columnLines: number[] = [];
    columnLines.push(0);
    columns.forEach((c) => {
      columnLines.push((columnLines[columnLines.length - 1] || 0) + c.width);
    });
    const offset = 0.5;
    return (
      <>
        {columnLines.map((c, i) => {
          return (
            <line
              className="dyn-line"
              key={`col-${i}`}
              x1={c + offset}
              x2={c + offset}
              y1={height}
              y2={defaultHeight * count + height}
              strokeWidth={1}
              strokeDasharray="5,5"
            />
          );
        })}
        {new Array(count).fill(1).map((r, i) => {
          return (
            <line
              className="dyn-line"
              key={`row-${i}`}
              x1={0}
              x2={columnLines[columnLines.length - 1]}
              y1={defaultHeight * (i + 1) + height + offset}
              y2={defaultHeight * (i + 1) + height + offset}
              strokeWidth={1}
              strokeDasharray="5,5"
            />
          );
        })}
      </>
    );
  };

  /**
   * 拖拽新增列
   */
  renderDynColumns = (width: number) => {
    if (!this.columnAddOffset) return null;
    const { tableValue } = this.state;
    const { rows, columns, showHeader, headerHeight } = tableValue;
    const lastColumnIndex = columns.length - 1;
    const defaultWidth = tableHelper.getColumnWidth(tableValue, lastColumnIndex);
    const count = min(MaxColumnsCount - columns.length, Math.ceil(this.columnAddOffset / defaultWidth));
    if (!count) return null;
    const rowLines: number[] = [];
    rowLines.push(0);
    if (showHeader) {
      rowLines.push(headerHeight);
    }
    rows.forEach((c) => {
      rowLines.push((rowLines[rowLines.length - 1] || 0) + c.height);
    });

    const offset = 0.5;
    return (
      <>
        {rowLines.map((r, i) => {
          return (
            <line
              className="dyn-line"
              key={`row-${i}`}
              x1={width}
              x2={width + defaultWidth * count}
              y1={r + offset}
              y2={r + offset}
              strokeWidth={1}
              strokeDasharray="5,5"
            />
          );
        })}
        {new Array(count).fill(1).map((c, i) => {
          return (
            <line
              className="dyn-line"
              key={`col-${i}`}
              x1={defaultWidth * (i + 1) + width + offset}
              x2={defaultWidth * (i + 1) + width + offset}
              y1={0}
              y2={rowLines[rowLines.length - 1]}
              strokeWidth={1}
              strokeDasharray="5,5"
            />
          );
        })}
      </>
    );
  };

  renderAddButton = () => {
    const { width, height } = tableHelper.getTableSizeFromValue(this.state.tableValue);
    return (
      <>
        <div
          className={classNames('row addBtn', { actived: !_.isUndefined(this.rowAddOffset) })}
          style={{ top: height + 38 + (this.rowAddOffset || 0) }}
          onMouseDown={this.handleDragToAddRows}
        >
          <Icon cls="layer_plus" tips={!_.isUndefined(this.rowAddOffset) ? '' : i18n('editor.dragToAddRows')} />
        </div>
        <div
          className={classNames('column addBtn', { actived: !_.isUndefined(this.columnAddOffset) })}
          style={{ left: width + 38 + (this.columnAddOffset || 0) }}
          onMouseDown={this.handleDragToAddColumns}
        >
          <Icon cls="layer_plus" tips={!_.isUndefined(this.columnAddOffset) ? '' : i18n('editor.dragToAddColumns')} />
        </div>
      </>
    );
  };
  /**
   * 表格区域
   */
  renderContentArea = () => {
    const { rows, columns, showHeader, headerHeight } = this.state.tableValue;
    const { width, height } = tableHelper.getTableSizeFromValue(this.state.tableValue);

    return (
      <div className="content-area" style={{ width, height }}>
        <table
          onContextMenu={this.handleContextMenu['cell']}
          onDoubleClick={this.handleTableDoubleClick}
          className="table-grid"
        >
          <tbody>
            {showHeader && (
              <tr
                style={{
                  height: headerHeight,
                }}
              >
                {columns.map((item, i) => {
                  return (
                    <td
                      ref={this.setCells}
                      key={i}
                      onMouseDown={this.handleCellMouseDown.bind(this, -1, i)}
                      onMouseEnter={this.handleCellMouseEnter.bind(this, -1, i)}
                      data-row={-1}
                      data-column={i}
                      style={{
                        width: item.width,
                      }}
                    />
                  );
                })}
              </tr>
            )}
            {rows.map((row, index) => {
              return (
                <tr
                  key={index}
                  style={{
                    height: row.height,
                  }}
                >
                  {columns.map((item, i) => {
                    return (
                      <td
                        className="cell-drag-wrapper"
                        ref={this.setCells}
                        key={i}
                        onMouseDown={this.handleCellMouseDown.bind(this, index, i)}
                        onMouseEnter={this.handleCellMouseEnter.bind(this, index, i)}
                        data-row={index}
                        data-column={i}
                        style={{
                          width: item.width,
                        }}
                      >
                        <span
                          className="right-drag-area"
                          onMouseDown={this.handleCellDragAreaMouseDown.bind(this, index, i, 'vertical')}
                        ></span>
                        <span
                          className="bottom-drag-area"
                          onMouseDown={this.handleCellDragAreaMouseDown.bind(this, index, i, 'horizontal')}
                        ></span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {this.renderSelectArea()}
        {this.renderDraggingArea()}
        {this.renderBorderOnMoving()}
      </div>
    );
  };

  renderOuterContentArea = () => {
    const { width, height } = tableHelper.getTableSizeFromValue(this.state.tableValue);

    const lineX = `M ${this.lineX || 0} -30 V ${height}`;
    const lineY = `M -30 ${this.lineY || 0} H ${width}`;
    const textPosition = { x: 0, y: 0 };
    if (this.operate === 'resizeHeight') {
      textPosition.x = -50;
      textPosition.y = (this.lineY || 0) + 4;
    } else if (this.operate === 'resizeWidth') {
      textPosition.x = (this.lineX || 0) - 8;
      textPosition.y = -40;
    }
    const blueColor = parseColorToString(BlueColor);
    return (
      <div className="content-area content-outer-area" style={{ width, height }}>
        {(this.operate === 'resizeHeight' ||
          this.operate === 'resizeWidth' ||
          this.operate === 'rowsDrag' ||
          this.operate === 'columnsDrag' ||
          !!this.rowAddOffset ||
          !!this.columnAddOffset) && (
          <svg
            className={classNames('svg', this.operate)}
            style={{ width, height, overflow: 'visible' }}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            {this.operate === 'resizeHeight' && <path d={lineY} stroke={blueColor} strokeWidth={2} />}
            {this.operate === 'resizeWidth' && <path d={lineX} stroke={blueColor} strokeWidth={2} />}
            {(this.operate === 'resizeHeight' || this.operate === 'resizeWidth') && (
              <text x={textPosition.x} y={textPosition.y} fill={blueColor}>
                {Math.round(this.dragSize || 0)}
              </text>
            )}
            {this.renderDynRows(height)}
            {this.renderDynColumns(width)}
            {this.renderDragingLine()}
          </svg>
        )}
        {(this.operate === 'resizeHeight' || this.operate === 'resizeWidth') && (
          <div className={classNames('over', this.operate)} />
        )}
      </div>
    );
  };

  /**
   * 表格选择框
   */
  renderSelectArea = () => {
    const { selectArea, tableValue } = this.state;
    if (!selectArea || !selectArea.start || !selectArea.end) return null;
    const { left, top, width, height } = tableHelper.getBoundsOfSelectArea(tableValue, selectArea);
    return (
      <div
        ref={this.selectBox}
        className="select-box"
        style={{
          left,
          top,
          width,
          height,
        }}
      >
        {/* <div className="smart-corner" /> */}
      </div>
    );
  };

  /**
   * 表格行列拖拽区域
   */
  renderDraggingArea = () => {
    const { selectArea, tableValue } = this.state;
    if (
      !selectArea ||
      !selectArea.start ||
      !selectArea.end ||
      !(this.operate === 'rowsDrag' || this.operate === 'columnsDrag')
    )
      return null;
    const { left, top, width, height } = tableHelper.getBoundsOfSelectArea(tableValue, selectArea);
    return (
      <div
        className="dragging-area"
        style={{
          left: left + (this.dragOffset?.x || 0),
          top: top + (this.dragOffset?.y || 0),
          width,
          height,
        }}
      />
    );
  };

  /**
   * 表格移动时的边框
   */
  renderBorderOnMoving = () => {
    const { editorPosition, tableValue } = this.state;
    if (!editorPosition) return null;
    const { columns, rows, showHeader } = tableValue;
    const allArea: ISelectArea = {
      start: {
        row: showHeader ? -1 : 0,
        column: 0,
      },
      end: {
        row: rows.length - 1,
        column: columns.length - 1,
      },
    };
    const { left, top, width, height } = tableHelper.getBoundsOfSelectArea(tableValue, allArea);
    return (
      <div
        className="moving-border"
        style={{
          left,
          top,
          width,
          height,
        }}
      />
    );
  };

  getNewLineData = (isRow: boolean): ILineValue | undefined => {
    const { tableValue, selectArea } = this.state;
    if (!selectArea || _.isUndefined(this.newDragIndex)) {
      return undefined;
    }
    const oldIndex = isRow ? selectArea.start.row : selectArea.start.column;
    const indexChange = this.newDragIndex - oldIndex;
    const newArea: ISelectArea = {
      start: {
        row: selectArea.start.row + (isRow ? indexChange : 0),
        column: selectArea.start.column + (!isRow ? indexChange : 0),
      },
      end: {
        row: selectArea.end.row + (isRow ? indexChange : 0),
        column: selectArea.end.column + (!isRow ? indexChange : 0),
      },
    };
    const size = tableHelper.getTableSizeFromValue(tableValue);
    const bounds = tableHelper.getBoundsOfSelectArea(tableValue, newArea);
    return {
      startPoint: {
        x: isRow ? 0 : indexChange < 0 ? bounds.left : bounds.right,
        y: isRow ? (indexChange < 0 ? bounds.top : bounds.bottom) : 0,
      },
      endPoint: {
        x: isRow ? size.width : indexChange < 0 ? bounds.left : bounds.right,
        y: isRow ? (indexChange < 0 ? bounds.top : bounds.bottom) : size.height,
      },
    };
  };

  renderDragingLine = () => {
    if (this.operate !== 'columnsDrag' && this.operate !== 'rowsDrag') {
      return null;
    }
    const { selectArea } = this.state;
    if (!selectArea) {
      return null;
    }
    const isRow = this.operate === 'rowsDrag';
    const line = this.getNewLineData(isRow);
    if (!line) {
      return null;
    }
    return (
      <line
        x1={line.startPoint.x}
        x2={line.endPoint.x}
        y1={line.startPoint.y}
        y2={line.endPoint.y}
        stroke={parseColorToString(BlueColor)}
        strokeWidth={2}
      />
    );
  };

  handleInputFocus() {
    this.valueEditor.current?.reloadInputValue();
  }

  render() {
    const {
      tableValue,
      menuPosition,
      menuType,
      selectArea,
      selectedAll,
      selectRows,
      selectColumns,
      editorBounds,
      editorPosition,
    } = this.state;
    const { rows, columns, showHeader, headerHeight } = tableValue;
    const width = columns.map((i) => i.width).reduce((a, b) => a + b);
    let height = rows.map((i) => i.height).reduce((a, b) => a + b);
    if (showHeader) {
      height += headerHeight;
    }
    const row = selectArea?.start.row ?? 0;
    const column = selectArea?.start.column ?? 0;
    let selectedComp = this.selectedComps.find((item) => item.row === row && item.column === column);
    if (!selectedComp /* && editorBounds */) {
      const cell = tableHelper.findCell(tableValue, { row, column });
      const textFormat = jsonClone(Object.assign({}, getDefaultTextFormat(), cell?.style?.textFormat));
      const textComp = makeText('', '', textFormat);
      // textComp.value = '';
      textComp.row = selectArea?.start.row ?? 0;
      textComp.column = selectArea?.start.column || 0;
      selectedComp = new UIComponent(textComp, this.props.comp);
    }
    const style = this.getStyleOnEditorMove(editorPosition);
    return (
      <>
        <div
          ref={this.tableEditor}
          className="table-editor"
          style={style}
          onContextMenu={this.handleTableEditorContextMenu}
          onMouseDown={this.handleEditorMouseDown}
        >
          <div
            className="row"
            style={{
              width: width + 30,
              height: 32,
            }}
          ></div>
          {/***********begin******渲染到外部******begin**************/}
          {ReactDOM.createPortal(
            <div className="table-editor-outer-tool-wrap" style={this.props.compStyle}>
              <div className="table-editor-outer-tool">
                {this.renderRowSelector()}
                {this.renderAddButton()}
              </div>
              <div className="table-editor-outer-tool">
                <div
                  className="row column-wrap"
                  style={{
                    width: width + 30,
                    height: 32,
                  }}
                >
                  {this.renderDragHandler()}
                  {this.renderColumnSelector()}
                </div>
              </div>
              {this.renderOuterContentArea()}
            </div>,
            this.tableOuterWrap,
          )}
          {/**********end*******渲染到外部****end****************/}
          <div
            className="row"
            style={{
              width: width + 30,
              height: height,
            }}
          >
            <div className="row-selector" style={{ visibility: 'hidden' }}></div>
            {this.renderContentArea()}
          </div>
        </div>
        {selectArea && (
          <ValueEditor
            ref={this.valueEditor}
            bounds={editorBounds ?? offscreenBounds}
            component={selectedComp}
            onChange={this.handleValueChange}
            onExit={this.handleValueEditorExit}
            onTyped={this.handleTyped}
            useInTable={true}
          />
        )}
        {menuPosition && (
          <TableMenu
            hasTableCellsData={this.state.hasTableCellsData}
            comp={this.props.comp}
            tableValue={tableValue}
            selectArea={selectArea}
            selectRows={selectRows}
            selectColumns={selectColumns}
            selectedAll={selectedAll}
            type={menuType}
            position={menuPosition}
            onMenuClose={this.closeMenu}
            onSelectedCompsUpdate={this.updateSelectComps}
            onSelectedCleared={this.handleClearSelectArea}
            onCellsContentCleared={this.cleanCellsContent}
            onCellsContentConvert={this.convertCellsContent}
            onConfirm={this.context.uiManager.updateConfirm}
            onCopyCells={this.handleCopyCells}
            onPasteCells={this.handlePasteCapture}
            onSubmit={this.doSubmit}
          />
        )}
        {this.renderCursor()}
        {this.renderTableInfoTips()}
      </>
    );
  }
}
