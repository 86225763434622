import { depthClone } from '@utils/globalUtils';
import { enumToArray } from '@/utils/enumUtils';

import { MobileType } from '@/fbs/rp/utils/preview';
import { ISize } from '@fbs/common/models/common';
import { IPreviewOption, isMobileAppType, isVehicleAppType, isWatchAppType } from './previewHelper';

import * as IPhone_h1 from '@assets/image/shell/iPhone/h/01.png';
import * as IPhone_h2 from '@assets/image/shell/iPhone/h/02.png';
import * as IPhone_h3 from '@assets/image/shell/iPhone/h/03.png';
import * as IPhone_v1 from '@assets/image/shell/iPhone/v/01.png';
import * as IPhone_v2 from '@assets/image/shell/iPhone/v/02.png';
import * as IPhone_v3 from '@assets/image/shell/iPhone/v/03.png';

import * as IPhoneX_h1 from '@assets/image/shell/iPhoneX/h/01.png';
import * as IPhoneX_h2 from '@assets/image/shell/iPhoneX/h/02.png';
import * as IPhoneX_h3 from '@assets/image/shell/iPhoneX/h/03.png';
import * as IPhoneX_v1 from '@assets/image/shell/iPhoneX/v/01.png';
import * as IPhoneX_v2 from '@assets/image/shell/iPhoneX/v/02.png';
import * as IPhoneX_v3 from '@assets/image/shell/iPhoneX/v/03.png';

// import * as Android_h1 from '@assets/image/shell/android/h/01.png';
// import * as Android_h2 from '@assets/image/shell/android/h/02.png';
// import * as Android_h3 from '@assets/image/shell/android/h/03.png';
// import * as Android_v1 from '@assets/image/shell/android/v/01.png';
// import * as Android_v2 from '@assets/image/shell/android/v/02.png';
// import * as Android_v3 from '@assets/image/shell/android/v/03.png';

import * as Pad_h1 from '@assets/image/shell/pad/h/01.png';
import * as Pad_h2 from '@assets/image/shell/pad/h/02.png';
import * as Pad_h3 from '@assets/image/shell/pad/h/03.png';
import * as Pad_v1 from '@assets/image/shell/pad/v/01.png';
import * as Pad_v2 from '@assets/image/shell/pad/v/02.png';
import * as Pad_v3 from '@assets/image/shell/pad/v/03.png';

// import * as iPad_Air_h1 from '@assets/image/shell/iPad_Air/h/01.png';
// import * as iPad_Air_h2 from '@assets/image/shell/iPad_Air/h/02.png';
// import * as iPad_Air_h3 from '@assets/image/shell/iPad_Air/h/03.png';
// import * as iPad_Air_v1 from '@assets/image/shell/iPad_Air/v/01.png';
// import * as iPad_Air_v2 from '@assets/image/shell/iPad_Air/v/02.png';
// import * as iPad_Air_v3 from '@assets/image/shell/iPad_Air/v/03.png';

import * as iPhone_black_h1 from '@assets/image/shell/iPhone_black/h/01.png';
import * as iPhone_black_h2 from '@assets/image/shell/iPhone_black/h/02.png';
import * as iPhone_black_h3 from '@assets/image/shell/iPhone_black/h/03.png';
import * as iPhone_black_v1 from '@assets/image/shell/iPhone_black/v/01.png';
import * as iPhone_black_v2 from '@assets/image/shell/iPhone_black/v/02.png';
import * as iPhone_black_v3 from '@assets/image/shell/iPhone_black/v/03.png';

import * as Galaxy_h1 from '@assets/image/shell/Galaxy/h/01.png';
import * as Galaxy_h2 from '@assets/image/shell/Galaxy/h/02.png';
import * as Galaxy_h3 from '@assets/image/shell/Galaxy/h/03.png';
import * as Galaxy_v1 from '@assets/image/shell/Galaxy/v/01.png';
import * as Galaxy_v2 from '@assets/image/shell/Galaxy/v/02.png';
import * as Galaxy_v3 from '@assets/image/shell/Galaxy/v/03.png';

import * as HUAWEI_Mate_h1 from '@assets/image/shell/HUAWEI_Mate/h/01.png';
import * as HUAWEI_Mate_h2 from '@assets/image/shell/HUAWEI_Mate/h/02.png';
import * as HUAWEI_Mate_h3 from '@assets/image/shell/HUAWEI_Mate/h/03.png';
import * as HUAWEI_Mate_v1 from '@assets/image/shell/HUAWEI_Mate/v/01.png';
import * as HUAWEI_Mate_v2 from '@assets/image/shell/HUAWEI_Mate/v/02.png';
import * as HUAWEI_Mate_v3 from '@assets/image/shell/HUAWEI_Mate/v/03.png';

import * as HUAWEI_P40_h1 from '@assets/image/shell/HUAWEI_P40/h/01.png';
import * as HUAWEI_P40_h2 from '@assets/image/shell/HUAWEI_P40/h/02.png';
import * as HUAWEI_P40_h3 from '@assets/image/shell/HUAWEI_P40/h/03.png';
import * as HUAWEI_P40_v1 from '@assets/image/shell/HUAWEI_P40/v/01.png';
import * as HUAWEI_P40_v2 from '@assets/image/shell/HUAWEI_P40/v/02.png';
import * as HUAWEI_P40_v3 from '@assets/image/shell/HUAWEI_P40/v/03.png';

import * as XiaoMi_h1 from '@assets/image/shell/XiaoMi/h/01.png';
import * as XiaoMi_h2 from '@assets/image/shell/XiaoMi/h/02.png';
import * as XiaoMi_h3 from '@assets/image/shell/XiaoMi/h/03.png';
import * as XiaoMi_v1 from '@assets/image/shell/XiaoMi/v/01.png';
import * as XiaoMi_v2 from '@assets/image/shell/XiaoMi/v/02.png';
import * as XiaoMi_v3 from '@assets/image/shell/XiaoMi/v/03.png';

import * as Surface_Pro_h1 from '@assets/image/shell/Surface_Pro/h/01.png';
import * as Surface_Pro_h2 from '@assets/image/shell/Surface_Pro/h/02.png';
import * as Surface_Pro_h3 from '@assets/image/shell/Surface_Pro/h/03.png';
import * as Surface_Pro_v1 from '@assets/image/shell/Surface_Pro/v/01.png';
import * as Surface_Pro_v2 from '@assets/image/shell/Surface_Pro/v/02.png';
import * as Surface_Pro_v3 from '@assets/image/shell/Surface_Pro/v/03.png';

import * as vehicle_h1 from '@assets/image/shell/vehicle/h/01.png';
import * as vehicle_h2 from '@assets/image/shell/vehicle/h/02.png';
import * as vehicle_h3 from '@assets/image/shell/vehicle/h/03.png';
import * as vehicle_v1 from '@assets/image/shell/vehicle/v/01.png';
import * as vehicle_v2 from '@assets/image/shell/vehicle/v/02.png';
import * as vehicle_v3 from '@assets/image/shell/vehicle/v/03.png';

import * as Apple_Watch_38mm_v1 from '@assets/image/shell/Apple_Watch_38mm/v/01.png';
import * as Apple_Watch_38mm_v2 from '@assets/image/shell/Apple_Watch_38mm/v/02.png';
import * as Apple_Watch_38mm_v3 from '@assets/image/shell/Apple_Watch_38mm/v/03.png';

import * as Apple_Watch_40mm_v1 from '@assets/image/shell/Apple_Watch_40mm/v/01.png';
import * as Apple_Watch_40mm_v2 from '@assets/image/shell/Apple_Watch_40mm/v/02.png';
import * as Apple_Watch_40mm_v3 from '@assets/image/shell/Apple_Watch_40mm/v/03.png';

import * as Apple_Watch_42mm_v1 from '@assets/image/shell/Apple_Watch_42mm/v/01.png';
import * as Apple_Watch_42mm_v2 from '@assets/image/shell/Apple_Watch_42mm/v/02.png';
import * as Apple_Watch_42mm_v3 from '@assets/image/shell/Apple_Watch_42mm/v/03.png';

import * as Apple_Watch_44mm_v1 from '@assets/image/shell/Apple_Watch_44mm/v/01.png';
import * as Apple_Watch_44mm_v2 from '@assets/image/shell/Apple_Watch_44mm/v/02.png';
import * as Apple_Watch_44mm_v3 from '@assets/image/shell/Apple_Watch_44mm/v/03.png';

import * as Apple_Watch_45mm_v1 from '@assets/image/shell/Apple_Watch_45mm/v/01.png';
import * as Apple_Watch_45mm_v2 from '@assets/image/shell/Apple_Watch_45mm/v/02.png';
import * as Apple_Watch_45mm_v3 from '@assets/image/shell/Apple_Watch_45mm/v/03.png';

import * as Apple_Watch_49mm_v1 from '@assets/image/shell/Apple_Watch_49mm/v/01.png';
import * as Apple_Watch_49mm_v2 from '@assets/image/shell/Apple_Watch_49mm/v/02.png';
import * as Apple_Watch_49mm_v3 from '@assets/image/shell/Apple_Watch_49mm/v/03.png';

import i18n from '@i18n';

// import * as IPhone_Portrait from '@assets/image/iphone/portrait.svg';
// import * as IPhone_Landscape from '@assets/image/iphone/landscape.svg';

// import * as IPhoneX_Portrait from '@assets/image/iphoneX/portrait.svg';
// import * as IPhoneX_Landscape from '@assets/image/iphoneX/landscape.svg';

// import * as Android_Portrait from '@assets/image/android/portrait.svg';
// import * as Android_Landscape from '@assets/image/android/landscape.svg';

// import * as Pad_Portrait from '@assets/image/pad/portrait.svg';
// import * as Pad_Landscape from '@assets/image/pad/landscape.svg';

export const MobileTypeList: {
  id: MobileType;
  text: string;
}[] = [
  {
    id: MobileType.Iphone,
    text: i18n('preview.iPhone'),
  },
  {
    id: MobileType.iPhone_black,
    text: i18n('preview.iPhoneBlack'),
  },
  {
    id: MobileType.IphoneX,
    text: i18n('preview.iPhoneX'),
  },
  // {
  //   id: MobileType.Android,
  //   text: 'Android',
  // },
  {
    id: MobileType.HUAWEI_P40,
    text: i18n('preview.huaweiP40'),
  },
  {
    id: MobileType.HUAWEI_Mate,
    text: i18n('preview.huaweiMate'),
  },
  {
    id: MobileType.XiaoMi,
    text: i18n('preview.xiaoMi'),
  },
  {
    id: MobileType.Galaxy,
    text: i18n('preview.galaxy'),
  },
  {
    id: MobileType.Pad,
    text: i18n('preview.iPad'),
  },
  {
    id: MobileType.Surface_Pro,
    text: i18n('preview.surfacePro'),
  },
  {
    id: MobileType.None,
    text: i18n('general.none'),
  },
];

export const WatchTypeList: {
  id: MobileType;
  text: string;
}[] = [
  {
    id: MobileType.Apple_Watch_38mm,
    text: i18n('preview.appleWatch38mm'),
  },
  {
    id: MobileType.Apple_Watch_40mm,
    text: i18n('preview.appleWatch40mm'),
  },
  {
    id: MobileType.Apple_Watch_42mm,
    text: i18n('preview.appleWatch42mm'),
  },
  {
    id: MobileType.Apple_Watch_44mm,
    text: i18n('preview.appleWatch44mm'),
  },
  {
    id: MobileType.Apple_Watch_45mm,
    text: i18n('preview.appleWatch45mm'),
  },
  {
    id: MobileType.Apple_Watch_49mm,
    text: i18n('preview.appleWatch49mm'),
  },
  {
    id: MobileType.None,
    text: i18n('general.none'),
  },
];

export const previewAndDownWatchTypeList: {
  id: MobileType;
  text: string;
}[] = [
  {
    id: MobileType.Apple_Watch_45mm,
    text: i18n('preview.appleWatch'),
  },
  {
    id: MobileType.Apple_Watch_49mm,
    text: i18n('preview.appleWatchUltra'),
  },
  {
    id: MobileType.None,
    text: i18n('general.none'),
  },
];

export const VehicleTypeList: {
  id: MobileType;
  text: string;
}[] = [
  {
    id: MobileType.Vehicle,
    text: i18n('preview.vehicle'),
  },
  {
    id: MobileType.None,
    text: i18n('general.none'),
  },
];

/**
 * 检测是否有效
 * @param option
 */
export const isValidateOption = (option: IPreviewOption, appType: string): boolean => {
  if (!option) {
    return false;
  }
  const isMobile = isMobileAppType(appType);
  const isWatch = isWatchAppType(appType);
  const isVehicle = isVehicleAppType(appType);

  if (isMobile) {
    return !!MobileTypeList.find((t) => t.id === option.mobileType);
  }
  if (isWatch) {
    return !!WatchTypeList.find((t) => t.id === option.mobileType);
  }
  if (isVehicle) {
    return !!VehicleTypeList.find((t) => t.id === option.mobileType);
  }

  return false;
};

interface IShellImage {
  landscape: string[];
  portrait: string[];
}

interface IBounds {
  left: number;
  top: number;
  width: number;
  height: number;
}

interface IShellOption {
  defaultScreenSize: ISize;
  shellImage: IShellImage;
  shellSize: ISize;
  screenBounds: IBounds;
  // 其他裁切部分 / 壳长度  ,0表示没有裁切
  otherScale: number;
}

export interface IReturnOption {
  defaultScreenSize: ISize;
  shellImage: string[];
  shellSize: ISize;
  screenBounds: IBounds;
  otherScale: number;
}

const iphoneXOptions: IShellOption = {
  defaultScreenSize: {
    width: 375,
    height: 812,
  },
  shellImage: {
    landscape: [IPhoneX_h1, IPhoneX_h2, IPhoneX_h3],
    portrait: [IPhoneX_v1, IPhoneX_v2, IPhoneX_v3],
  },
  shellSize: {
    width: 1308,
    height: 2655,
  },
  screenBounds: {
    left: 69,
    top: 63,
    width: 1170,
    height: 2532,
  },
  otherScale: 384 / 1308,
};

const iphoneOptions: IShellOption = {
  defaultScreenSize: {
    width: 375,
    height: 667,
  },
  shellImage: {
    landscape: [IPhone_h1, IPhone_h2, IPhone_h3],
    portrait: [IPhone_v1, IPhone_v2, IPhone_v3],
  },
  shellSize: {
    width: 1410,
    height: 2802,
  },
  screenBounds: {
    left: 84,
    top: 270,
    width: 1242,
    height: 2208,
  },
  otherScale: 0,
};

// const androidOptions: IShellOption = {
//   defaultScreenSize: {
//     width: 360,
//     height: 640,
//   },
//   shellImage: {
//     landscape: [Android_h1, Android_h2, Android_h3],
//     portrait: [Android_v1, Android_v2, Android_v3],
//   },
//   shellSize: {
//     width: 1204,
//     height: 2458,
//   },
//   screenBounds: {
//     left: 62,
//     top: 252,
//     width: 1080,
//     height: 1920,
//   },
//   otherScale: 0,
// };

const padOptions: IShellOption = {
  defaultScreenSize: {
    width: 768,
    height: 1024,
  },
  shellImage: {
    landscape: [Pad_h1, Pad_h2, Pad_h3],
    portrait: [Pad_v1, Pad_v2, Pad_v3],
  },
  shellSize: {
    width: 2718,
    height: 3918,
  },
  screenBounds: {
    left: 144,
    top: 324,
    width: 2430,
    height: 3240,
  },
  otherScale: 0,
};

// const padAirOptions: IShellOption = {
//   defaultScreenSize: {
//     width: 820,
//     height: 1180,
//   },
//   shellImage: {
//     landscape: [iPad_Air_h1, iPad_Air_h2, iPad_Air_h3],
//     portrait: [iPad_Air_v1, iPad_Air_v2, iPad_Air_v3],
//   },
//   shellSize: {
//     width: 2790,
//     height: 4014,
//   },
//   screenBounds: {
//     left: 144,
//     top: 324,
//     width: 2502,
//     height: 3336,
//   },
//   otherScale: 0,
// };

const iPhoneBlackOptions: IShellOption = {
  defaultScreenSize: {
    width: 768,
    height: 1024,
  },
  shellImage: {
    landscape: [iPhone_black_h1, iPhone_black_h2, iPhone_black_h3],
    portrait: [iPhone_black_v1, iPhone_black_v2, iPhone_black_v3],
  },
  shellSize: {
    width: 1410,
    height: 2802,
  },
  screenBounds: {
    left: 84,
    top: 270,
    width: 1242,
    height: 2208,
  },
  otherScale: 0,
};

const galaxyOptions: IShellOption = {
  defaultScreenSize: {
    // FIXME: 尺寸
    width: 360,
    height: 760,
  },
  shellImage: {
    landscape: [Galaxy_h1, Galaxy_h2, Galaxy_h3],
    portrait: [Galaxy_v1, Galaxy_v2, Galaxy_v3],
  },
  shellSize: {
    width: 1116,
    height: 2376,
  },
  screenBounds: {
    left: 18,
    top: 48,
    width: 1080,
    height: 2280,
  },
  otherScale: 291 / 1116,
};

const huaweiMateOptions: IShellOption = {
  defaultScreenSize: {
    width: 360,
    height: 792,
  },
  shellImage: {
    landscape: [HUAWEI_Mate_h1, HUAWEI_Mate_h2, HUAWEI_Mate_h3],
    portrait: [HUAWEI_Mate_v1, HUAWEI_Mate_v2, HUAWEI_Mate_v3],
  },
  shellSize: {
    width: 1116, //1762,
    height: 2472, //2496,
  },
  screenBounds: {
    left: 18, //112,
    top: 48, //225,
    width: 1080,
    height: 2376,
  },
  otherScale: 288 / 1116,
};

const huaweiP40Options: IShellOption = {
  defaultScreenSize: {
    width: 768,
    height: 1024,
  },
  shellImage: {
    landscape: [HUAWEI_P40_h1, HUAWEI_P40_h2, HUAWEI_P40_h3],
    portrait: [HUAWEI_P40_v1, HUAWEI_P40_v2, HUAWEI_P40_v3],
  },
  shellSize: {
    width: 1164, //1762,
    height: 2412, //2496,
  },
  screenBounds: {
    left: 42, //112,
    top: 36, //225,
    width: 1080,
    height: 2340,
  },
  otherScale: 252 / 1164,
};

const xiaoMiOptions: IShellOption = {
  defaultScreenSize: {
    width: 768,
    height: 1024,
  },
  shellImage: {
    landscape: [XiaoMi_h1, XiaoMi_h2, XiaoMi_h3],
    portrait: [XiaoMi_v1, XiaoMi_v2, XiaoMi_v3],
  },
  shellSize: {
    width: 1164, //1762,
    height: 2412, //2496,
  },
  screenBounds: {
    left: 42, //112,
    top: 36, //225,
    width: 1080,
    height: 2340,
  },
  otherScale: 276 / 1164,
};

const surfaceProOptions: IShellOption = {
  defaultScreenSize: {
    width: 912,
    height: 1368,
  },
  shellImage: {
    landscape: [Surface_Pro_h1, Surface_Pro_h2, Surface_Pro_h3],
    portrait: [Surface_Pro_v1, Surface_Pro_v2, Surface_Pro_v3],
  },
  shellSize: {
    width: 3360, //1762,
    height: 4500, //2496,
  },
  screenBounds: {
    left: 240, //112,
    top: 90, //225,
    width: 2880,
    height: 4320,
  },
  otherScale: 252 / 3360,
};

// // 手表
// const watchOptions: IShellOption = {
//   defaultScreenSize: {
//     width: 912,
//     height: 1368,
//   },
//   shellImage: {
//     landscape: [Surface_Pro_h1, Surface_Pro_h2, Surface_Pro_h3],
//     portrait: [Surface_Pro_v1, Surface_Pro_v2, Surface_Pro_v3],
//   },
//   shellSize: {
//     width: 3360, //1762,
//     height: 4500, //2496,
//   },
//   screenBounds: {
//     left: 240, //112,
//     top: 90, //225,
//     width: 2880,
//     height: 4320,
//   },
//   otherScale: 252 / 3360,
// };
// 车载
const vehicleOptions: IShellOption = {
  defaultScreenSize: {
    width: 720,
    height: 1280,
  },
  shellImage: {
    landscape: [vehicle_h1, vehicle_h2, vehicle_h3],
    portrait: [vehicle_v1, vehicle_v2, vehicle_v3],
  },
  shellSize: {
    width: 796,
    height: 1356,
  },
  screenBounds: {
    left: 38,
    top: 38,
    width: 720,
    height: 1280,
  },
  otherScale: 1280 / 1356,
};

// 手表
const AppleWatch38mmOptions: IShellOption = {
  defaultScreenSize: {
    width: 136, // 224 -38 -50
    height: 170, //398- 114- 114
  },
  shellImage: {
    landscape: [],
    portrait: [Apple_Watch_38mm_v1, Apple_Watch_38mm_v2, Apple_Watch_38mm_v3],
  },
  shellSize: {
    width: 224,
    height: 398, // 116 + 166+116
  },
  screenBounds: {
    left: 38,
    top: 114,
    width: 136,
    height: 170,
  },
  otherScale: 166 / 398,
};

const AppleWatch40mmOptions: IShellOption = {
  defaultScreenSize: {
    width: 162, //228 - 27- 39
    height: 197, //398 - 100 -101
  },
  shellImage: {
    landscape: [],
    portrait: [Apple_Watch_40mm_v1, Apple_Watch_40mm_v2, Apple_Watch_40mm_v3],
  },
  shellSize: {
    width: 228,
    height: 398, // 127 +144 + 127
  },
  screenBounds: {
    left: 27,
    top: 100,
    width: 162,
    height: 197,
  },
  otherScale: 144 / 398,
};

const AppleWatch42mmOptions: IShellOption = {
  defaultScreenSize: {
    width: 156, //244 - 38 - 50
    height: 195, //436 - 121 -120
  },
  shellImage: {
    landscape: [],
    portrait: [Apple_Watch_42mm_v1, Apple_Watch_42mm_v2, Apple_Watch_42mm_v3],
  },
  shellSize: {
    width: 244,
    height: 436, // 130 + 176 +130
  },
  screenBounds: {
    left: 38,
    top: 121,
    width: 156,
    height: 195,
  },
  otherScale: 176 / 436,
};

const AppleWatch44mmOptions: IShellOption = {
  defaultScreenSize: {
    width: 184, //252- 28 - 40
    height: 224, //430 -103-103
  },
  shellImage: {
    landscape: [],
    portrait: [Apple_Watch_44mm_v1, Apple_Watch_44mm_v2, Apple_Watch_44mm_v3],
  },
  shellSize: {
    width: 252,
    height: 430, // 138 + 154 +138
  },
  screenBounds: {
    left: 28,
    top: 103,
    width: 184,
    height: 224,
  },
  otherScale: 154 / 430,
};

const AppleWatch45mmOptions: IShellOption = {
  defaultScreenSize: {
    width: 198, // 266 - 28- 40
    height: 242, //447 - 103 -103
  },
  shellImage: {
    landscape: [],
    portrait: [Apple_Watch_45mm_v1, Apple_Watch_45mm_v2, Apple_Watch_45mm_v3],
  },
  shellSize: {
    width: 266,
    height: 447, // 140 +167+140
  },
  screenBounds: {
    left: 28,
    top: 103,
    width: 198,
    height: 242,
  },
  otherScale: 167 / 447,
};

const AppleWatch49mmOptions: IShellOption = {
  defaultScreenSize: {
    width: 205, //282- 32- 45
    height: 251, //469- 109 -109
  },
  shellImage: {
    landscape: [],
    portrait: [Apple_Watch_49mm_v1, Apple_Watch_49mm_v2, Apple_Watch_49mm_v3],
  },
  shellSize: {
    width: 282,
    height: 469, // 157 +155 +157
  },
  screenBounds: {
    left: 32,
    top: 109,
    width: 205,
    height: 251,
  },
  otherScale: 155 / 469,
};

const shellOptions = {
  [MobileType.IphoneX]: iphoneXOptions,
  [MobileType.Iphone]: iphoneOptions,
  // [MobileType.Android]: androidOptions,
  [MobileType.Pad]: padOptions,
  [MobileType.iPhone_black]: iPhoneBlackOptions,
  // [MobileType.iPad_Air]: padAirOptions,
  [MobileType.Galaxy]: galaxyOptions,
  [MobileType.HUAWEI_Mate]: huaweiMateOptions,
  [MobileType.HUAWEI_P40]: huaweiP40Options,
  [MobileType.XiaoMi]: xiaoMiOptions,
  [MobileType.Surface_Pro]: surfaceProOptions,
  [MobileType.Vehicle]: vehicleOptions,
  [MobileType.Apple_Watch_38mm]: AppleWatch38mmOptions,
  [MobileType.Apple_Watch_40mm]: AppleWatch40mmOptions,
  [MobileType.Apple_Watch_42mm]: AppleWatch42mmOptions,
  [MobileType.Apple_Watch_44mm]: AppleWatch44mmOptions,
  [MobileType.Apple_Watch_45mm]: AppleWatch45mmOptions,
  [MobileType.Apple_Watch_49mm]: AppleWatch49mmOptions,
  [MobileType.None]: null,
};

const getRatio = (size: ISize): number => {
  return size.height / size.width;
};

// TODO 适配
export const adapterPhoneShell = (aspectRatio: number): MobileType => {
  const shells = [
    {
      type: MobileType.HUAWEI_P40,
      ratiodiff: Math.abs(getRatio(shellOptions.HUAWEI_P40.defaultScreenSize) - aspectRatio),
    },
    { type: MobileType.Iphone, ratiodiff: Math.abs(getRatio(shellOptions.iphone.defaultScreenSize) - aspectRatio) },
    { type: MobileType.IphoneX, ratiodiff: Math.abs(getRatio(shellOptions.iphoneX.defaultScreenSize) - aspectRatio) },
    // { type: 'pad', ratiodiff: Math.abs(getRatio(shellOptions.pad.defaultScreenSize) - aspectRatio) },
  ];
  return shells.sort((a, b) => {
    return a.ratiodiff - b.ratiodiff;
  })[0].type as MobileType;
};

const rotateSize = (size: ISize) => {
  const { width, height } = size;
  return {
    width: height,
    height: width,
  };
};

const rotate = (cfg: IShellOption) => {
  const { defaultScreenSize, shellImage, shellSize, screenBounds } = cfg;
  const { left, top, width, height } = screenBounds;
  return {
    defaultScreenSize: rotateSize(defaultScreenSize),
    shellImage,
    shellSize: rotateSize(shellSize),
    screenBounds: {
      left: top,
      width: height,
      height: width,
      top: left,
    },
    otherScale: cfg.otherScale,
  };
};

export function getShellInfo(deviceType?: MobileType, isLandscape = false): IReturnOption | null {
  if (!deviceType || deviceType == MobileType.None) {
    return null;
  }
  const options = shellOptions[deviceType];
  if (options) {
    let cfg = depthClone(options);
    const { shellImage } = cfg;
    if (isLandscape) {
      cfg = rotate(cfg);
    }
    return {
      ...cfg,
      shellImage: isLandscape ? shellImage.landscape : shellImage.portrait,
    };
  }
  return null;
}

export function isMobileShell(type?: string): type is MobileType {
  return enumToArray(MobileType).includes(type) && type !== MobileType.None;
}
