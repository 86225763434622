import * as React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';

import i18n from '@/i18n';
import { transBlankChart } from '@utils/textUtils';

import { ScrollBars } from '@dsm';

import { UIComponent, UIContainerComponent } from '@editor/comps';
import { StyleHelper } from '@helpers/styleHelper';
import { hasInteraction } from '@helpers/interactionHelper';
import { FontBoxScale } from '@consts/fonts';

import { IListCompositeProps } from '../../types';
import { getListEvents } from '../helps';

import Background from '../../basic/common/Background';

import './index.scss';
import '../general.scss';

interface IListProps extends IListCompositeProps {
  isDropDown?: boolean;
  searchAfterComp?: UIContainerComponent;
  renderLabelText?: (textComp: UIComponent) => React.ReactNode;
}

const List: React.FC<IListProps> = (props: IListProps) => {
  const { comp, searchAfterComp, isPreview, itemEvents, isDropDown, isStandalone, showInteract } = props;
  const group = (searchAfterComp as UIContainerComponent) ?? (comp as UIContainerComponent);
  const {
    properties,
    size: { width, height },
    opacity,
    components,
  } = group;
  const { container, padding, separator, layout } = properties;
  const { stroke, border } = properties;
  const parser = StyleHelper.createCSSStyleParser(properties);

  const fill = parser.getFillStyle({ width, height });
  const allowScroll = !!container?.scroll;
  const showScroll = !!container?.showScroll;
  const paddingLeft = padding?.disabled ? 0 : padding?.left;
  const paddingRight = padding?.disabled ? 0 : padding?.right;
  const paddingTop = padding?.disabled ? 0 : padding?.top;
  const paddingBottom = padding?.disabled ? 0 : padding?.bottom;
  const style = {
    left: paddingLeft || 0,
    top: isDropDown ? undefined : paddingTop || 0,
    width: width - (paddingLeft || 0) - (paddingRight || 0),
    height: isDropDown ? undefined : height - (paddingTop || 0) - (paddingBottom || 0),
    marginTop: isDropDown ? paddingTop || 0 : undefined,
    marginBottom: isDropDown ? paddingBottom || 0 : undefined,
    flex: isDropDown ? 1 : undefined,
  };

  const itemGap = layout?.verticalGap || 0;
  const events = isPreview ? getListEvents(comp.components, itemEvents) : {};

  const listContentNode = (comps: UIContainerComponent[]) => {
    const len = comps.length;
    return comps.map((comp, i) => {
      const { size, opacity } = comp;
      const text = comp.getComponentByAlias('text') || comp.components[0];
      const { size: txSize, properties, position } = text;
      const { size: textSize, style } = StyleHelper.createCSSStyleParser({}).getTextStyleData(
        txSize,
        properties.textStyle,
      );
      const itemStyle = _.memoize(
        (): React.CSSProperties => ({
          ...StyleHelper.initCSSStyleParser(comp.properties).getFillStyle(),
          ...StyleHelper.parserSeparator('bottom', separator),
          ...style,
          height: size.height,
          lineHeight: `${size.height}px`,
          marginBottom: i < len - 1 ? itemGap : 0,
          paddingLeft: position.x,
          paddingRight: position.x,
          opacity: StyleHelper.getOpacity(opacity),
        }),
      )();
      const _hasInteraction = hasInteraction(comp);
      const needShowInteraction = !isPreview && _hasInteraction && false !== showInteract;
      return (
        <div
          key={comp.id}
          className={classnames('lib-comp-list-item', {
            'item-hot-area': isPreview && _hasInteraction,
            'component-cursor-pointer': isPreview,
            'item-interaction-flag': needShowInteraction,
          })}
          style={itemStyle}
          {...(events[comp.id] || {})}
        >
          <label
            style={{
              ...textSize,
              ...style,
              height: textSize.height / FontBoxScale,
              lineHeight: `${textSize.height / FontBoxScale}px`,
              left: position.x,
              // top: position.y,
              opacity: StyleHelper.getOpacity(text.opacity),
            }}
            className="lib-comp-list-item-value"
          >
            {props.renderLabelText ? props.renderLabelText(text) : transBlankChart(text.value as string)}
          </label>
        </div>
      );
    });
  };

  if (searchAfterComp && components.length === 0) {
    return <div className="lib-comp-list-noResult">{i18n('tips.notSearchResult')}</div>;
  }
  let _showScrollBar = false;
  if (isPreview && !isStandalone) {
    _showScrollBar = allowScroll && showScroll;
  }

  return (
    <div
      className={classnames('lib-comp-list', { preview: isPreview })}
      style={{
        width,
        height: isDropDown ? '100%' : height,
        opacity: StyleHelper.getOpacity(opacity),
        display: isDropDown ? 'flex' : undefined,
        flexDirection: isDropDown ? 'column' : undefined,
        ...fill,
        ...parser.getShadowStyle(),
      }}
    >
      <ScrollBars
        style={style}
        className="lib-comp-list-content"
        disabled={!isPreview}
        hiddenHorizontalScrollBar={true}
        hiddenVerticalScrollBar={!_showScrollBar}
        disabledVertScroll={!allowScroll}
      >
        {listContentNode(components as UIContainerComponent[])}
      </ScrollBars>
      {/* 预览模式下。列表div设置了border,受到box-sizing怪异模式的影响，缩放的时候scale会照成border被吃掉问题 */}
      {/* 使用额外的遮罩层去实现border相关的内容 */}
      {stroke && !stroke.disabled && <Background size={{ width, height }} properties={{ stroke, border }} />}
    </div>
  );
};

export default List;
