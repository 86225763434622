import axios from 'axios';

import * as app from './app';
import * as artboard from './artboard';
import * as team from './team';
import * as file from './file';
import * as user from './user';
import ds from './ds';
import * as userPreference from './userPreference';
import * as share from './share';
import * as appSet from './appSet';
import * as activity from './activity';
import { languageManager } from '@i18n';

import offlineUser from './offlineDemo/user';
import offlineTeam from './offlineDemo/team';
import offlineApp from './offlineDemo/app';
import offlineArtboard from './offlineDemo/artboard';
import offlineDs from './offlineDemo/ds';
import { getOSSystem, isMockRPD } from '@utils/envUtils';
import * as publish from './publish';
import * as config from '../../package.json';

const Token = window.apis?.Token || '';

//
// const {
//   API_PREFIX,
//   Token,
// } = apis;
// // axios 默认配置
axios.defaults.baseURL = window.apis?.Prefix || '';
if (Token) {
  axios.defaults.headers['Authorization'] = `Bearer ${Token}`;
}
axios.defaults.headers['x-mockplus-lang'] = languageManager.currentLanguage;
const isOfflineDemo = !!RP_CONFIGS.isOfflineDemo;

const init = () => {
  let from = 'rp-for-web';
  if (isMockRPD) {
    axios.defaults.headers['x-mockplus-user-agent'] = 'rpClient';
    from = 'rp-for-desktop';
  }
  axios.defaults.headers['x-mockplus-app'] = `${from}|${config.version}|${getOSSystem()}`;
};

init();

export default {
  app: isOfflineDemo ? offlineApp : app,
  artboard: isOfflineDemo ? offlineArtboard : artboard,
  team: isOfflineDemo ? offlineTeam : team,
  file,
  user: isOfflineDemo ? offlineUser : user,
  ds: isOfflineDemo ? offlineDs : ds,
  userPreference,
  share,
  appSet,
  publish,
  activity,
};
