import * as React from 'react';

import Icon from '../../Icon';
import i18n from '@i18n';

interface IDirection {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

interface IGradientDirection {
  direction: IDirection;
  onChange?: (direction: IDirection) => void;
}

class GradientDirection extends React.Component<IGradientDirection> {
  handleDirectionClick = () => {
    const { direction } = this.props;
    const { x1, x2, y1, y2 } = direction;
    const width = x2 - x1;
    const height = y2 - y1;
    const cx = x1 + width / 2;
    const cy = y1 + height / 2;
    let sx = cx;
    let ex = cx;
    let sy = cy;
    let ey = cy;
    const diffX = Math.abs(width / 2);
    const diffY = Math.abs(height / 2);

    if (width > 0) {
      sy -= diffX;
      ey += diffX;
      if (height > 0) {
        sx += diffY;
        ex -= diffY;
      } else if (height < 0) {
        sx -= diffY;
        ex += diffY;
      }
    } else if (width < 0) {
      sy += diffX;
      ey -= diffX;
      if (height > 0) {
        sx += diffY;
        ex -= diffY;
      } else if (height < 0) {
        sx -= diffY;
        ex += diffY;
      }
    } else {
      if (height > 0) {
        sx += diffY;
        ex -= diffY;
      } else {
        sx -= diffY;
        ex += diffY;
      }
    }
    const newDirection = { x1: sx, x2: ex, y1: sy, y2: ey };
    this.props.onChange && this.props.onChange(newDirection);
  };

  render() {
    return (
      <div className="dsm-c-rp-color-gradient-direction">
        <div className="gradient-reset">
          <Icon cls="reset" tips={i18n('tips.rotationAngle')} onClick={this.handleDirectionClick} />
        </div>
      </div>
    );
  }
}

export default GradientDirection;
