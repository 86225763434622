import * as path from 'path';

import { ISize } from '@fbs/common/models/common';
import { IDemoConfig } from '@fbs/rp/models/preview';

import { getLocalImageFileSize } from '@helpers/fileUploadHelper';

import { get, post, put } from './helper';

// 上传图片
export async function uploadImg(
  img: File,
  fn?: (complete: number) => void,
): Promise<{ URL: string; size: ISize; name: string }> {
  const name = path.basename(img.name, path.extname(img.name));
  const formData = new FormData();
  formData.append('image', img);
  const result = await put<{ URL: string; size: { width: number; height: number } }>('/file/image', formData, {
    onUploadProgress: (progressEvent) => {
      let complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
      fn && fn(complete);
    },
  });
  return { ...result, name };
}

// 上传 svg
export async function uploadSvg(
  file: File,
  fn?: (complete: number) => void,
): Promise<{ URL: string; size: ISize; name: string }> {
  const formData = new FormData();
  formData.append('file', file);
  const res = await put<{ URL: string }>('/file/svg', formData, {
    onUploadProgress: (progressEvent) => {
      let complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
      fn && fn(complete);
    },
  });
  const { size } = await getLocalImageFileSize(file);
  return {
    URL: res.URL,
    size,
    name: path.basename(file.name, path.extname(file.name)),
  };
}

// 获取视屏的大小
export async function getVideoDimensions(url: string): Promise<ISize> {
  return new Promise<ISize>((resolve, reject) => {
    const dom = document.createElement('video');
    dom.addEventListener('loadedmetadata', function () {
      resolve({
        width: this.videoWidth,
        height: this.videoHeight,
      });
    });
    dom.addEventListener('error', function () {
      reject();
    });
    dom.src = url;
  });
}

// 获取svg的大小
// async function getSvgSize(url: string): Promise<ISize> {
//   return new Promise<ISize>((resolve, reject) => {
//     const dom = document.createElement('img');
//     dom.addEventListener('load', function () {
//       resolve({
//         width: this.width,
//         height: this.height,
//       });
//     });
//     dom.src = url;
//   });
// }

// 上传视屏
export async function uploadVideo(
  video: File,
  fn?: (complete: number) => void,
): Promise<{ URL: string; size: ISize; name: string }> {
  const formData = new FormData();
  formData.append('file', video);
  const res = await put<{ URL: string }>('/file/video', formData, {
    onUploadProgress: (progressEvent) => {
      let complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
      fn && fn(complete);
    },
  });
  const size = await getVideoDimensions(res.URL);
  return {
    URL: res.URL,
    size,
    name: path.basename(video.name, path.extname(video.name)),
  };
}
// 上传音频
export async function uploadAudio(
  audio: File,
  fn?: (complete: number) => void,
): Promise<{ URL: string; size: ISize; name: string }> {
  const formData = new FormData();
  formData.append('file', audio);
  const res = await put<{ URL: string }>('/file/audio', formData, {
    onUploadProgress: (progressEvent) => {
      let complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
      fn && fn(complete);
    },
  });
  const size = { width: 300, height: 45 };
  return {
    URL: res.URL,
    size,
    name: path.basename(audio.name, path.extname(audio.name)),
  };
}

/**
 * 请求下载离线演示包
 */
export async function exportPreview(appID: string, v: string, config: IDemoConfig, checkedPage: string[]) {
  return await post(`/app/exportPreview/${appID}`, { v, config, nodeIDs: checkedPage });
}

/**
 * 获取项目文件下载路径
 * @param appID
 * @param delayID
 * @param nodeIDs
 */
export function getRPFileDownloadURL(
  appID: string,
  delayID: string,
  nodeIDs: string[],
  exportLib: boolean,
): Promise<void> {
  // return get<{ appID: string; url: string }>(`/app/RPAppZip/${appID}/${delayID}?nodeIDs=${nodeIDs}`);
  return post(`/app/RPAppZip/${appID}/${delayID}`, { nodeIDs, exportLib });
}

export function delayInfo<T>(delayID: string) {
  return get<T>(`/app/delayInfo/${delayID}`);
}
