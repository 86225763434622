import ITextFormat from './text';
import IMultiText from './multiText';

interface ITextFormatEx extends ITextFormat, IMultiText {
  isMulti?: boolean;// 是否多行的，隐藏的属性
  lineHeightEx?: number;
}

export default ITextFormatEx;

export const TextFormatExPropertyName = 'textFormat';
export { FontStyle, FontStyleName, TextAlign, TextPropertyName, TextTransform, VerticalAlign } from './text';
