import PropertyBase from './base';

export enum FilterName {
  // grayScale = 'grayScale',
  brightness = 'brightness',
  contrast = 'contrast',
  hueRotate = 'hueRotate',
  saturate = 'saturate',
  // opacity = 'opacity',
  invert = 'invert'
}

export interface IFilter {
  hueRotate?: number;// 色相 0-360 default 0
  saturate?: number;//饱和度 0-100 default 100
  brightness?: number;//亮度0-100 default 100
  contrast?: number; //对比度 0-100 default 100
  invert?: number;//反相 0-100 default 0
  // grayScale?: number;// 灰度 0-100 default 0
  // opacity?: number;// 透明度 0-100 default 100
}

export default interface IColorFilter extends PropertyBase, IFilter {

}


export const ColorFilterPropertyName = 'colorFilter';