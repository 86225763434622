import * as React from 'react';
import { ColorPicker } from '@/dsm';
import { IColorPickerProp } from '@/dsm/ColorPicker';

import { IUserPreference } from '@fbs/rp/models/grid';

import EditorContext from '@contexts/editor';
import { getDownloadURL } from '@/apis/download';

interface IState {}
interface IProps extends Omit<IColorPickerProp, 'onDownloadExtension'> {}

class ReduxColorPicker extends React.PureComponent<IProps, IState> {
  static contextType = EditorContext;
  // @ts-ignore
  context: React.ContextType<typeof EditorContext>;

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  private handlePatchPreference = (value: Partial<IUserPreference>): void => {
    if (this.context.changeUsePreference) {
      this.context.changeUsePreference(value, true);
    }
  };

  handleDownloadChromeExtension = async () => {
    let downloadUrl;

    try {
      if (RP_CONFIGS.isPrivateDeployment) {
        downloadUrl = `${window.apis.iDocDomain}/plugin-downloads/mockplus_for_chrome_latest.zip`;
      } else {
        const res = await getDownloadURL({
          softwareName: 'rp-pipette-for-chrome',
          product: 'rp-pipette',
          version: '1.0.3',
          platform: 'chrome',
        });
        downloadUrl = res.downloadURL;
      }

      window.open(downloadUrl, '_blank');
    } catch (e) {
      console.warn('Failed to get mockplus color picker extension url.');
      return;
    }
  };

  public render(): React.ReactNode {
    return (
      <ColorPicker
        {...this.props}
        userPreference={this.context.userPreference}
        onPatchPreference={this.handlePatchPreference}
        onDownloadExtension={this.handleDownloadChromeExtension}
      />
    );
  }
}

export default ReduxColorPicker;
