/**
 * 文本内容辅助工具
 */
import * as sanitizeHtml from 'sanitize-html';
import { LoremIpsum } from 'lorem-ipsum';
import { escapeRegExp } from 'lodash';
import { checkFontFamily, FontBoxScale, getFontKey } from '@consts/fonts';

enum CSSFontStyle {
  color = 'color',
  fontFamily = 'fontFamily',
  fontSize = 'fontSize',
  fontWeight = 'fontWeight',
  fontStyle = 'fontStyle',
  textDecorationLine = 'textDecorationLine',
  textAlign = 'textAlign',
}

interface IRichTextStyleSet {
  color: string[];
  fontFamily: string[];
  fontSize: string[];
  fontWeight: string[];
  fontStyle: string[];
  textDecorationLine: string[];
  textAlign: string[];
}

interface IRichTextStyle {
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  fontStyle?: string;
  textAlign?: string;
  textDecorationLine?: string;
}

interface ISize {
  width: number;
  height: number;
}

interface IStyle {
  fontFamily?: string;
  fontSize?: number;
  lineHeight?: number;
  letterSpacing?: number;
  color?: string;
  fontStyle?: {
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    strike?: boolean;
  };
  textAlign?: string;
}

export enum IMeasureReturnType {
  Exact = 'exact', // 精确值
  Floor = 'floor',
  Ceil = 'ceil',
  Round = 'round',
}

export interface IMeasureOptions {
  defaultWidth?: number;
  defaultHeight?: number;
  isMultiText?: boolean;
  wrap?: boolean;
  indent?: boolean;
  isRich?: boolean;
  editing?: boolean;
  cannotReplaceSpaceChar?: boolean; // 是否无需替换空格
  returnType?: IMeasureReturnType;
}

export const loremIpsum = new LoremIpsum({
  sentencesPerParagraph: {
    max: 7,
    min: 5,
  },
  wordsPerSentence: {
    max: 16,
    min: 4,
  },
});

export function getParagraph(count: number = 1) {
  let value = loremIpsum.generateParagraphs(count);
  value = value[0].toLocaleLowerCase() + value.substr(1);
  return `Lorem ipsum ${value}`;
}

const textSizeCache: { [key: string]: { width: number; height: number } } = {};

export const allowedBlockTags = ['pre', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'dt', 'dl', 'dd'];

export const allowedTextTags = [
  'label',
  'span',
  'font',
  'em',
  'i',
  'b',
  'u',
  'strike',
  'strong',
  'a',
  ...allowedBlockTags,
];

export const allowedTextStyles: { [key: string]: RegExp[] } = {
  color: [
    /^#(0x)?[0-9a-f]+$/i,
    /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
    /^rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(0.)?\d+\s*\)$/,
  ],
  // 'text-align': [/^left$/, /^right$/, /^center$/],
  'font-size': [/^.*$/], //[/^(\d+(\.*\d*)(?:px|em|pt|%))|(x*-small|large)|(medium)|((larg|small)er)$/],
  'font-weight': [/^.*$/],
  'font-style': [/^.*$/],
  'text-decoration': [/^.*$/],
  'text-decoration-line': [/^.*$/],
  'white-space': [/^.*$/],
  'font-family': [/^.*$/],
  'line-height': [/^.*$/],
  'vertical-align': [/^.*$/],
  transform: [/^.*$/],
};

export const allowedParagraphTextTags = ['p', 'div', 'br'];

const blockTags = [...allowedParagraphTextTags, ...allowedBlockTags].map((t) => t.toUpperCase());
const TAB_SIZE = 2;
const tabDentReg = /^\s/;

export function filterHTMLText(
  text: string,
  wrap?: boolean,
  regulate?: (allowedTags: string[], allowedStyles: { [key: string]: RegExp[] }) => void,
  options?: {
    isHTML?: boolean;
    transformTags?: { [key: string]: string };
  },
) {
  const { isHTML = false, ...sanitizeHtmlOptions } = options || {};
  if (!isHTML) {
    // 验证是否 html 格式内容， 非HTML格式内容，直接返回
    const beforeAngleBrackets = text.match(/</g);
    if (beforeAngleBrackets?.length) {
      const afterAngleBrackets = text.match(/>/g);
      if (beforeAngleBrackets.length !== afterAngleBrackets?.length) {
        return text;
      }
    } else {
      return text;
    }
  }
  const allowedTags = [...allowedTextTags];
  const allowedStyles = { ...allowedTextStyles };

  if (wrap) {
    allowedTags.push(...allowedParagraphTextTags);
    allowedStyles['line-height'] = [/^.*$/];
  }
  // 要忽略内部所有的换行，统一使用组件上的换行属性
  delete allowedStyles['white-space'];
  if (regulate) {
    regulate(allowedTags, allowedStyles);
  }
  return sanitizeHtml(text, {
    allowedTags,
    allowedAttributes: { '*': ['style'], a: ['href'] },
    allowedStyles: { '*': allowedStyles },
    nonTextTags: ['textarea', 'input', 'style', 'script', 'noscript'],
    ...(sanitizeHtmlOptions || {}),
  });
}

export function getPureText(text: string) {
  return text ? sanitizeHtml(text, {}) : '';
}

function hashCode(str: string): string {
  let hash = 0;
  if (str.length > 0) {
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
  }
  return hash.toString(32);
}

function parseToDOM(str: string): Node | null {
  let xmlDoc;
  let parser;
  const text = `<root>${str.replace(/(&nbsp;)/g, '')}</root>`;
  if (window.DOMParser) {
    parser = new DOMParser();
    xmlDoc = parser.parseFromString(text, 'text/xml');
    const root = xmlDoc.getRootNode().firstChild!;
    if (root.firstChild && root.firstChild.nodeName === 'parsererror') {
      return null;
    }
  } else if (window.ActiveXObject) {
    xmlDoc = new window.ActiveXObject('Microsoft.XMLDOM');
    xmlDoc.async = false;
    xmlDoc.loadXML(text);
  } else {
    throw new Error('cannot parse xml');
  }
  return xmlDoc;
}

const fontSizeMap: { [key: string]: number } = {
  'xx-small': 10,
  'x-small': 12,
  small: 13,
  medium: 16,
  large: 18,
  'x-large': 24,
  'xx-large': 32,
};
const hTagFontSizeMap: { [key: string]: number } = {
  h1: 32,
  h2: 24,
  h3: 18,
  h4: 16,
  h5: 13,
  h6: 12,
};

const reg = /(font-size|line-height|letter-spacing):\s*(\d+)px;?/gi;

// const tagStyleDoubleReg = /<([\u4e00-\u9fa5\w\s"'=:;\-().]*)(font-size|line-height|letter-spacing):\s?(\d+)px;?([\u4e00-\u9fa5\w\s"'=:;\-().]*)>/gi;

function doReplaceFontSizeWithRegExp(text: string, zoom?: 'in' | 'out') {
  const scale = (zoom === 'in' && FontBoxScale) || (zoom === 'out' && 1 / FontBoxScale) || 1;
  return text.replace(reg, (total, name, px) => `${name}:${px * scale}px;`);
  // return text.replace(tagStyleDoubleReg, (total, start, name, px, end) => {
  //   return `<${start}${name}:${px * (scale)}px;${end}>`;
  // }); // -> 仅修改<>标签内的属性【暂未使用】
}

/**
 * 字号处理
 * @param {string} text
 * @returns {string}
 */
export function replaceOldFontSize(text: string, zoom?: 'in' | 'out') {
  return doReplaceFontSizeWithRegExp(text, zoom);
}

function parseFontSize(styleValue: string) {
  if (typeof styleValue !== 'string') {
    return 0;
  }
  if (styleValue.indexOf('px') !== -1) {
    return parseInt(`${styleValue}`.replace(/px/g, ''), 10);
  } else if (styleValue.indexOf('pt') !== -1) {
    return Math.round(parseInt(`${styleValue}`.replace(/pt/g, ''), 10) / 0.75);
  } else {
    return fontSizeMap[styleValue as string];
  }
}

function getStyleFromNode(node: Node) {
  const nodeName = node.nodeName;
  const result: IStyle = {};
  const style = (node as Element).getAttribute('style');
  if (style) {
    const styleMap = style.split(';').reduce((acc, curr) => {
      const items = curr.split(':');
      const key = items[0]?.trim();
      acc[key] = items[1]?.trim();
      return acc;
    }, {} as { [key: string]: any });
    if (styleMap['font-family']) {
      result.fontFamily = checkFontFamily(styleMap['font-family']);
    }
    result.color = styleMap['color'];
    const fs = styleMap['font-size'];
    if (fs) {
      result.fontSize = parseFontSize(fs);
    } else {
      if (hTagFontSizeMap[nodeName]) {
        result.fontSize = hTagFontSizeMap[nodeName];
      }
    }
    if (styleMap['font-style'] || styleMap['font-weight'] || styleMap['text-decoration']) {
      result.fontStyle = {};
      if (styleMap['font-weight'] === 'bold' || styleMap['font-weight'] >= 700) {
        result.fontStyle.bold = true;
      }
      if (styleMap['font-style'] === 'italic') {
        result.fontStyle.italic = true;
      }
      if (styleMap['text-decoration']) {
        if (styleMap['text-decoration'].indexOf('underline') !== -1) {
          result.fontStyle.underline = true;
        } else if (styleMap['text-decoration'].indexOf('line-through') !== -1) {
          result.fontStyle.strike = true;
        }
      }
    } else if (hTagFontSizeMap[nodeName]) {
      result.fontStyle = { bold: true };
    }

    if (styleMap['line-height']) {
      const lh = styleMap['line-height'];
      if (lh.includes('px') || lh.includes('pt')) {
        // result.lineHeight = parseFontSize(lh) - (result.fontSize || 14);
        result.lineHeight = parseFontSize(lh);
      } else if (lh.indexOf('%') !== -1) {
        const l = parseInt(lh.replace('%', ''), 10) / 100;
        // result.lineHeight = Math.round(l * (result.fontSize || 14) - (result.fontSize || 14));
        result.lineHeight = Math.round(l * (result.fontSize || 14));
      } else {
        try {
          const l = parseFloat(lh);
          // result.lineHeight = Math.round(l * (result.fontSize || 14) - (result.fontSize || 14));
          result.lineHeight = Math.round(l * (result.fontSize || 14));
        } catch (e) {
          //其它情况不作解析了
        }
      }
    }
  }
  return result;
}

export function getTextFormatFromHTML(html: string): IStyle {
  const xml = parseToDOM(html);
  if (xml && xml.childNodes) {
    const root = xml.childNodes[0];
    if (root.childNodes.length === 1 && root.childNodes[0].nodeName !== '#text') {
      return getStyleFromNode(root.firstChild!);
    }
  }
  return {};
}

export function adjustHTMLLineHeight(html: string) {
  if (!html) {
    return '';
  }
  const dom = parseToDOM(html);

  const doSetNodeLineHeight = (node: Node) => {
    const type = node.nodeName;
    if (type !== '#text') {
      const { fontSize, lineHeight } = getStyleFromNode(node);
      if (!lineHeight) {
        if (fontSize) {
          const el = node as Element;
          let style = el.getAttribute('style');
          if (style) {
            style = `${style}; line-height: ${fontSize + 6}px`;
          } else {
            style = `line-height: ${fontSize + 6}px`;
          }
          // 忽略复制来的rem/em单位的字号
          style = style.replace(/(font-size:\d*\.*\d*(rem|em));*/, ``);
          // 替换pt单位的字号为px
          style = style.replace(/(font-size:\d*\.*\d*pt);*/, `font-size: ${fontSize}px;`);
          el.setAttribute('style', style);
        }
      }
      if (node.childNodes.length) {
        node.childNodes.forEach((cnode) => doSetNodeLineHeight(cnode));
      }
    }
  };
  if (dom) {
    const root = dom.childNodes[0];
    root.childNodes.forEach((node) => {
      doSetNodeLineHeight(node);
    });
    return (root as Element).innerHTML;
  }
  return html;
}

export function isMultiLine(text: string): boolean {
  const lineTags = ['<div>', '<p>', '<br>'];
  for (let i = 0; i < lineTags.length; i++) {
    if (text.indexOf(lineTags[i]) !== -1) {
      return true;
    }
  }
  return false;
}

export function resetRichTextElements(text: string) {
  const dom = document.createElement('div') as HTMLElement;
  dom.innerHTML = text;
  let nodes = [...new Array(dom.children.length)].map((v, i) => dom?.children[i]);
  let maxFontSize = 0;
  let maxLineHeight = maxFontSize;
  let maxLetterSpacing = 0;
  while (nodes.length !== 0) {
    const ele = nodes[0] as HTMLElement;
    if (ele) {
      let style = ele.getAttribute('style');
      if (style && /(font-size)|(letter-spacing)|(line-height)/.test(style)) {
        const fontSize = parseFontSize(ele.style.fontSize);
        const lineHeight = parseFontSize(ele.style.lineHeight);
        const letterSpacing = parseFontSize(ele.style.letterSpacing);
        if (fontSize > maxFontSize) {
          maxFontSize = fontSize;
        }
        if (lineHeight > maxLineHeight) {
          maxLineHeight = lineHeight;
        }
        if (letterSpacing > maxLetterSpacing) {
          maxLetterSpacing = letterSpacing;
        }
        style = style.replace(/(font-size:\s*\d*\.*\d*px);*/, `font-size: ${fontSize / FontBoxScale}px;`);
        style = style.replace(/(line-height:\s*\d*\.*\d*px);*/, `line-height: ${lineHeight / FontBoxScale}px;`);
        style = style.replace(
          /(letter-spacing:\s*\d*\.*\d*px);*/,
          `letter-spacing: ${letterSpacing / FontBoxScale}px;`,
        );
        ele.setAttribute('style', style);
      }
      if (ele.hasChildNodes()) {
        const childNodes = [...new Array(ele.children.length)].map((v, i) => ele.children[i]);
        nodes = nodes.concat(childNodes);
      }
    }
    nodes.shift();
  }
  const domStyle = {
    text: dom.innerHTML,
    style: {
      maxFontSize,
      maxLineHeight: Math.max(maxFontSize, maxLineHeight),
      maxLetterSpacing,
    },
  };
  return domStyle;
}

/**
 * 文本测量 在调用该方法之前，需要把文本中的script标签过虑掉
 * @param {{}} style
 * @param {string} text
 * @param {IMeasureOptions} option
 * @returns {ISize}
 */
export function measureTextSize(style: { [key: string]: any }, text: string, option?: IMeasureOptions): ISize {
  // TODO 度量时 text 值应该和 render 时一致，但这里好像没考虑

  let str = filterHTMLText(
    replaceOldFontSize(text, 'out'),
    option ? option.isMultiText : false,
    (allowedTags: string[], allowedStyles: { [key: string]: RegExp[] }) => {
      if (option?.indent) {
        allowedStyles['text-indent'] = [/^.*$/];
      }
    },
  );

  if (!option?.isRich) {
    str = transBlankChartNotInTag(str);
  }

  // 处理 '&nbsp;', '\n' 和 render 时保持一致
  let temp = str.split('\n');
  if (!option?.cannotReplaceSpaceChar) {
    //TODO MengXiaoJiang 2022-11-24 保留全角空格
    temp = temp.map((str) => str.replace(/[ ]/g, '\u0020'));
  }
  str = temp.join('<br/>');

  const { defaultWidth, defaultHeight, isMultiText, wrap, editing } = option || {};
  const _style = { ...style };
  if (defaultWidth) {
    _style.width = defaultWidth;
  }

  if (!isMultiText) {
    delete _style.whiteSpace;
  } else {
    if (wrap) {
      // 新增，判断是否富文本，为真则识别空格换行问题
      _style.whiteSpace = 'break-spaces';
      _style.wordBreak = 'break-word';
      _style.wordWrap = 'break-word';
    } else {
      _style.whiteSpace = 'pre'; // 由于nbsp都被换成普通空格，所以通过这个样式保留空格空间
      _style.wordBreak = 'normal';
      _style.width = 'unset';
    }
  }
  const isMultiLineStr = isMultiLine(str);
  const keyJSON = {
    style: _style,
    text: str,
    option,
  };
  const key = hashCode(JSON.stringify(keyJSON));
  let dom = document.getElementById('measure');
  if (!dom) {
    dom = document.createElement('div');
    document.body.appendChild(dom);
  }
  if (dom) {
    dom.id = 'measure';
    // dom.style.opacity = '0';
    dom.style.lineHeight = _style.lineHeight || (isMultiLineStr ? '1.42' : 'unset');
    dom.style.textIndent = '';
    dom.style.writingMode = '';
    dom.style.whiteSpace = '';
    dom.style.height = 'auto';
    dom.style.wordWrap = 'normal';
    dom.style.wordBreak = wrap ? 'break-word' : 'keep-all';

    dom.className = `measure-text-layer debugger ${isMultiText && wrap ? 'wrap' : ''}`;
    dom.innerHTML = str;
    dom.style.position = 'absolute';

    dom.style.zIndex = '100000';
    dom.style.display = 'block';
    dom.style.fontFamily = _style.fontFamily || '';
    dom.style.fontWeight = (_style.fontWeight as string) || 'normal';
    dom.style.fontStyle = _style.fontStyle || '';
    dom.style.padding = '0px';
    dom.style.margin = '0px';

    // FIXME 边框由SVG绘制 与富文本无关
    // dom.style.border = _style.border || 'none';
    // dom.style.boxSizing = _style.boxSizing || 'content-box';
    if (typeof defaultWidth !== 'undefined') {
      dom.style.width = `${defaultWidth}px`;
    } else {
      dom.style.width = 'auto';
    }

    if (!editing) {
      dom.style.transform = `scale(${FontBoxScale})`;
      dom.style.letterSpacing = (_style.letterSpacing || 0) / FontBoxScale + 'px';
      dom.style.lineHeight = parseFontSize(_style.lineHeight || '') / FontBoxScale + 'px';
      dom.style.fontSize = _style.fontSize / FontBoxScale + 'px';
    }
    if (dom.style.writingMode === 'vertical-rl') {
      defaultHeight && wrap && (dom.style.height = defaultHeight / FontBoxScale + 'px');
    } else {
      defaultWidth && wrap && (dom.style.width = defaultWidth / FontBoxScale + 'px');
    }
    dom.style.overflow = _style.overflow || 'hidden';
    dom.style.textTransform = _style.textTransform;
    dom.style.verticalAlign = _style.verticalAlign;
    if (isMultiText) {
      // @ts-ignore
      // if (_style.lineHeight) {
      //   dom.style.lineHeight = _style.lineHeight;
      // }
      dom.style.whiteSpace = _style.whiteSpace || 'pre-wrap';
      dom.style.writingMode = _style.writingMode || 'horizontal-tb';
      dom.style.textIndent = _style.textIndent;
      if (dom.style.whiteSpace === 'pre') {
        dom.style.width = 'auto';
      }
      if (dom.style.writingMode === 'vertical-rl') {
        dom.style.height = 'auto';
        dom.classList.add('vertical');
        if (defaultHeight) {
          dom.style.height = wrap ? `${defaultHeight / FontBoxScale}px` : 'auto';
          dom.style.width = 'auto';
        }
      }
    } else {
      dom.style.whiteSpace = 'pre';
      dom.classList.add('no-wrap');
    }
    // FIXED: 这里的clientWidth 是四舍五入取整，导致类似14.125 的数据直接变为了14， 在没有指定wrap的dom上，应用后换行
    const { width, height } = dom.getBoundingClientRect();

    const returnType = option?.returnType || IMeasureReturnType.Ceil; // 默认返回Math.ceil
    textSizeCache[key] = {
      width: returnType === IMeasureReturnType.Exact ? width : Math[returnType](width),
      height: returnType === IMeasureReturnType.Exact ? height : Math[returnType](height),
    };
    return textSizeCache[key];
  }
  return {
    width: 0,
    height: 0,
  };
}

// FIXME Matt 以下代码 10-1考虑完善，9-2暂不开放，用于处理富文本中选中编辑内容时，设置编号的样式
// type StyleName = 'color' | 'fontSize' | 'fontStyle' | 'fontWeight' | 'textDecoration' | 'fontFamily';
function applyFontStyle(dom: HTMLElement, command: string, value: string, noUpdate: boolean) {
  const _style: { [key: string]: string } = {
    color: dom.style.color || '',
    'font-size': dom.style.fontSize,
    'font-family': dom.style.fontFamily,
    'font-style': dom.style.fontStyle,
    'font-weight': dom.style.fontWeight,
    'text-decoration-line': dom.style.textDecorationLine,
  };
  if (!noUpdate) {
    switch (command) {
      case 'foreColor':
        _style['color'] = value;
        break;
      case 'fontSize':
        _style['font-size'] = value + 'px';
        break;
      case 'fontName':
        _style['font-family'] = value;
        break;
      case 'italic':
        _style['font-style'] = value ? 'italic' : 'normal';
        break;
      case 'bold':
        _style['font-weight'] = value ? 'bold' : 'normal';
        break;
      case 'strike': {
        const defaultTextDecoration = _style['text-decoration-line'] || '';
        _style['text-decoration-line'] = value
          ? defaultTextDecoration + ' ' + 'line-through'
          : defaultTextDecoration.replace('line-through', '');
        break;
      }
      case 'underline': {
        const defaultTextDecoration = _style['text-decoration-line'] || '';
        _style['text-decoration-line'] = value
          ? defaultTextDecoration + ' ' + 'underline'
          : defaultTextDecoration.replace('underline', '');
        break;
      }
      default:
        break;
    }
  }
  const styles = Object.keys(_style)
    .filter((k) => _style[k])
    .map((k) => `${k}: ${_style[k]};`);
  const newStyle = styles.join('');
  setTimeout(() => {
    // 等待浏览器命令执行完毕 私自修改样式 防止被强制取消掉样式
    newStyle && dom.setAttribute('style', newStyle);
  }, 0);
}

//FIXME  应用颜色到富文本选中内容时，如果是选中了li中的所有内容，则把颜色要应用到编号上
export function applyFontStyleToLI(command: string, value: string) {
  const selection = document.getSelection();
  // 是否有选中区
  if (!selection || !selection.rangeCount) {
    return;
  }
  const { startContainer, endContainer, startOffset, endOffset } = selection.getRangeAt(0);
  // 是否有选中内容
  if (!startContainer || !endContainer) {
    return;
  }
  // 鼠标开头选中内容
  const anchorLi = getSuperiorElementByTagName(startContainer, 'li');
  // 鼠标结尾选中内容
  const focusLi = getSuperiorElementByTagName(endContainer, 'li');
  if (!anchorLi || !focusLi) {
    return;
  }
  // 列表开始的开始
  const anchorFirstChild = getFirstNode(anchorLi);
  // 列表结束的结束
  const focusLastChild = getLastNode(focusLi);
  const firstLiHasSelected = startOffset === 0 && anchorFirstChild === startContainer;
  const lastLiHasSelected = endContainer?.nodeValue?.length === endOffset && focusLastChild === endContainer;
  // 是否只有一行
  const onlyLi = focusLi === anchorLi;
  const selectedElements = onlyLi ? [anchorLi] : getNextElements(anchorLi, focusLi);
  applyFontStyleByList(selectedElements, {
    command,
    value,
    firstLiHasSelected,
    lastLiHasSelected,
  });
}

/**
 * 将已选中的元素列表设置样式
 * @param selectedElements 已选中的元素列表
 * @param options.command
 * @param options.value
 * @param options.firstLiHasSelected 首行是否全选
 * @param options.lastLiHasSelected 末行是否全选
 */
function applyFontStyleByList(
  selectedElements: HTMLElement[],
  options: {
    command: string;
    value: string;
    firstLiHasSelected: boolean;
    lastLiHasSelected: boolean;
  },
) {
  let { command, value, firstLiHasSelected, lastLiHasSelected } = options;
  selectedElements.forEach((element, index, { length }) => {
    const noUpdate = (index === 0 && !firstLiHasSelected) || (index === length - 1 && !lastLiHasSelected);
    applyFontStyle(element, command, value, noUpdate);
  });
}

/**
 * 获取元素的第一个Node元素 树的尖端
 * @param element
 */
function getFirstNode(node: Node) {
  if (node.nodeType === Node.TEXT_NODE || !node.hasChildNodes()) {
    return node;
  }
  let firstNode = node.childNodes.item(0);
  while (firstNode.childNodes.length) {
    firstNode = firstNode.childNodes.item(0);
  }
  return firstNode;
}

/**
 * 获取元素最后一个元素的 树的尖端
 * @param element
 */
function getLastNode(node: Node) {
  if (node.nodeType === Node.TEXT_NODE || !node.hasChildNodes()) {
    return node;
  }
  const elements = node.childNodes;
  let lastNode = elements.item(elements.length - 1);
  while (lastNode.childNodes.length) {
    const childElements = lastNode.childNodes;
    lastNode = childElements.item(childElements.length - 1);
  }
  return lastNode;
}

/**
 * 获取某段位置的元素节点列表
 * @param startElement 开始节点
 * @param endElement 结束节点
 */
function getNextElements(startElement: HTMLElement, endElement?: HTMLElement) {
  let nextAnchorElement = startElement.nextElementSibling;
  const selectedElements = [startElement];
  while (nextAnchorElement) {
    selectedElements.push(nextAnchorElement as HTMLElement);
    if (nextAnchorElement === endElement) {
      break;
    }
    nextAnchorElement = nextAnchorElement.nextElementSibling;
  }
  return selectedElements;
}

/**
 * 按标签名获取上级元素
 * @param node 当前元素
 * @param searchTagName 上级元素标签名
 */
function getSuperiorElementByTagName(node: Node, searchTagName: string): HTMLElement | null {
  let parent = node.parentElement;
  if (!searchTagName) {
    return parent;
  }
  if (node.nodeName.toLocaleLowerCase() === searchTagName) {
    return node as HTMLElement;
  }
  const tagName = searchTagName.toLocaleLowerCase();
  while (parent) {
    if (parent.tagName.toLocaleLowerCase() === tagName) {
      return parent;
    }
    parent = parent.parentElement;
  }
  return null;
}

export function execCommandFontSize(selector: string, size: number, lineHeight: number) {
  const success = document.execCommand('fontSize', false, '7');
  document.execCommand('styleWithCSS');
  if (!success) {
    // TODO 如果执行不成功，考虑有没有其它方式
    return;
  }
  // chrome
  const parentNodeArrChrome = document.querySelectorAll(`${selector} span[style*="xxx-large"]`) as NodeList;
  parentNodeArrChrome.forEach((item) => {
    const dom = item as HTMLElement;
    if (dom.tagName.toLowerCase() === 'span') {
      dom.style.fontSize = `${size}px`;
      if (lineHeight) {
        dom.style.lineHeight = `${lineHeight / FontBoxScale}px`;
      }
    }
  });
  // ff
  const parentNodeArr = document.querySelectorAll(`${selector} font[size*="7"]`) as NodeList;
  parentNodeArr.forEach((item) => {
    if ((item as HTMLElement).tagName.toLowerCase() === 'font') {
      (item as HTMLFontElement).removeAttribute('size');
      (item as HTMLFontElement).style.fontSize = `${size}px`;
      if (lineHeight) {
        (item as HTMLFontElement).style.lineHeight = `${lineHeight / FontBoxScale}px`;
      }
    }
  });
}

export function containerValue(source: string, target: string, ignoreCase: boolean = false): boolean {
  if (ignoreCase) {
    return source.toLowerCase().indexOf(target.toLowerCase()) !== -1;
  }
  return source.indexOf(target) !== -1;
}

export function transBlankChart(text: string) {
  if (!text) {
    return text;
  }
  return text.replace(/[ ]/g, `\u00A0`);
}

export function transBlankChartNotInTag(text: string) {
  if (!text) {
    return text;
  }
  let start = false;
  let end = false;
  return text
    .split('')
    .map((char) => {
      if (char === '<') {
        start = true;
        end = false;
      }
      if (char === '>') {
        end = true;
        start = false;
      }
      const isInTag = start && !end;
      if (!isInTag && char === ' ') {
        return '\u0020'; // char.replace(/[ ]/g, `\u00a0`);
      } else {
        return char;
      }
    })
    .join('');
}

/**
 * 填充/取消序号
 * @param text 填充文本
 * @param type 序号类型
 */
export function insertOrderedList(text: string, type?: 'order' | 'unOrder'): string {
  const dom = document.createElement('div');
  dom.id = 'insertOrderedList';
  dom.innerHTML = `${text}`;
  dom.querySelectorAll('ul,ol').forEach((e) => spliceChildNodeAll(e, 'p'));
  // 取消列表样式
  if (!type) {
    return dom.innerHTML;
  }
  // let li = document.createElement('li');
  const tagName = (type === 'order' && 'OL') || (type === 'unOrder' && 'UL') || 'DIV';
  return replaceChildNodeAllAsHtml(dom, tagName, 'li');
}

/**
 * 修改元素的子元素并覆盖当前元素 - 改变原数据
 * @param element 当前元素
 * @param childName 子元素修改后的tagName
 */
function spliceChildNodeAll(element: Node, childName: string) {
  const parent = element.parentElement;
  if (!parent) {
    // 暂未发现无父元素
    return;
  }
  const temp = document.createDocumentFragment();
  element.childNodes.forEach((node) => {
    const p = document.createElement(childName);
    if (node.nodeType === 1) {
      const e = node as HTMLElement;
      p.innerHTML = e.innerHTML;
      const style = e.getAttribute('style');
      style && p.setAttribute('style', style);
    } else {
      p.appendChild(node.cloneNode());
    }
    temp.appendChild(p);
  });
  parent.replaceChild(temp, element);
}

/**
 * 修改元素的tagName 并将所有子元素/子节点 修改成某个元素 - 不改变原数据
 * @param element 当前元素
 * @param parentName 当前元素修改后的tagName
 * @param childName 子元素修改后的tagName
 * @return 当前元素被修改后的outHTML
 */
function replaceChildNodeAllAsHtml(element: HTMLElement, parentName: string, childName: string) {
  const parent = document.createElement(parentName);
  element.childNodes.forEach((node) => {
    const child = document.createElement(childName);
    if (node.nodeType === 1) {
      // dom 元素
      const el = node as HTMLElement;
      const style = el.getAttribute('style');
      if (blockTags.includes(node.nodeName)) {
        // 块级元素
        child.innerHTML = el.innerHTML;
        style && child.setAttribute('style', style);
      } else {
        // 非块级元素
        const temp = document.createElement(el.tagName);
        temp.innerHTML = el.innerHTML;
        style && temp.setAttribute('style', style);
        child.appendChild(temp);
      }
    } else {
      // 文本节点或其他
      child.appendChild(node.cloneNode());
    }
    parent.appendChild(child);
  });
  return parent.outerHTML;
}

export function getNewCSSStyle(
  styleName: string,
  value: string,
  oldStyle?: string | null,
  replace?: (oldValue: string) => string,
) {
  const oldStyles = oldStyle?.split(';') || [];
  const styles: string[][] =
    oldStyles.reduce((acc: string[][], curr: string) => {
      curr = curr.trim();
      if (!curr) {
        return acc;
      }
      const style = curr.split(':').map((s) => s.trim());
      acc.push(style);
      return acc;
    }, []) || [];
  const index = styles.findIndex((style) => style[0] === styleName);
  if (index === -1) {
    styles.push([styleName, replace ? replace('') : value]);
  } else {
    const style = styles[index];
    style[1] = !replace ? value : replace(style[1]);
    styles.splice(index, 1, style);
  }
  const newStyle = styles.reduce((acc: string, curr: string[]) => curr[1] && `${acc + curr.join(':')};`, '');
  return newStyle;
}

/**
 * 缩进
 * @param node 需要缩进的node
 * @returns {Number} 缩进量
 */
export function execCommandIndent(node: Node): number {
  const tabText = [...new Array(TAB_SIZE)].map(() => '&nbsp;').join('');
  const parent = node.parentElement;
  const fragment = document.createDocumentFragment();
  const span = document.createElement('span');
  span.innerHTML = tabText;
  if (node.nodeType === 1) {
    const element = node as HTMLElement;
    const newNode = document.createElement(element.tagName);
    if (element.tagName === 'BR') {
      fragment.appendChild(newNode);
      fragment.appendChild(span);
    } else if (blockTags.includes(element.tagName)) {
      newNode.innerHTML = tabText + element.innerHTML;
      fragment.appendChild(newNode);
    } else {
      const style = newNode.getAttribute('style');
      style && newNode.setAttribute('style', style);
      newNode.innerHTML = element.innerHTML;
      fragment.appendChild(span);
      fragment.appendChild(newNode);
    }
  } else {
    fragment.appendChild(span);
    fragment.appendChild(node.cloneNode());
  }
  parent?.replaceChild(fragment, node);
  return TAB_SIZE;
}

/**
 * 取消缩进
 * @param node 需要取消缩进的node
 * @returns {Number} 缩进量
 */
export function execCommandOutdent(node: Node): number {
  if (node.nodeType === 1 && blockTags.includes(node.nodeName)) {
    const element = node as HTMLElement;
    // 块级元素自缩
    element.innerText = replaceOutdentText(element.innerText).value;
    // 块级元素前面的元素也缩进
    replaceBeforeOutdent(element);
  } else {
    let parentNode = node.parentElement;
    while (parentNode) {
      if (blockTags.includes(parentNode.nodeName)) {
        const element = parentNode;
        const { value, tabSize: _tabSize } = replaceOutdentText(element.innerText);
        element.innerText = value;
        return _tabSize;
      }
      parentNode = parentNode.parentElement;
    }
  }
  return TAB_SIZE;
}

/**
 * 删除文本首位的两个空字符
 * @param value 传入需要切割的字符串
 * @returns { -, value, tabSize } 切割后的值，切割量
 */

function replaceOutdentText(value: string): { value: string; tabSize: number } {
  let tabSize = 0;
  let index = 0;
  while (index < TAB_SIZE) {
    index++;
    if (tabDentReg.test(value)) {
      value = value.replace(tabDentReg, '');
      tabSize++;
    }
  }
  return {
    value,
    tabSize,
  };
}

/**
 * 将当前元素之前块级元素为止的区域 删除首位两个空字符
 * @param node 当前元素
 * @returns {Number} 切割量
 */
function replaceBeforeOutdent(node: Node): number {
  const temp: Node[] = [];
  let prevNode = node.previousSibling;
  // 收集 node 元素之前的所有非块级元素
  while (prevNode && !blockTags.includes(prevNode.nodeName)) {
    temp.unshift(prevNode);
    prevNode = prevNode.previousSibling;
  }
  let _tabSize = 0;
  // 总共切割 tabSize 个空格
  temp.forEach((n) => {
    if (_tabSize === TAB_SIZE) {
      return;
    }
    if (n.nodeType === 1) {
      const e = n as HTMLElement;
      const { value, tabSize } = replaceOutdentText(e.innerText);
      e.innerText = value;
      _tabSize += tabSize;
    } else if (n.nodeValue) {
      const { value, tabSize } = replaceOutdentText(n.nodeValue);
      n.nodeValue = value;
      _tabSize += tabSize;
    }
  });
  return _tabSize;
}

/**
 * 获取元素中的所有子元素 同[HTML].children
 * @param dom 元素节点
 */
export function getHTMLElementChildren(dom: HTMLElement): HTMLElement[] {
  const childNodes = dom.childNodes;
  return [...new Array(childNodes.length)].reduce((prev: HTMLElement[], curr, index) => {
    const node = childNodes.item(index);
    if (node.nodeType === Node.ELEMENT_NODE) {
      prev.push(node as HTMLElement);
    }
    return prev;
  }, []);
}

/**
 * 获取元素节点中的所有子节点 同[NODE].childNodes
 * @param dom 元素节点
 */
export function getChildNodes(dom: HTMLElement) {
  const childNodes = dom.childNodes;
  return [...new Array(childNodes.length)].map((v, i) => childNodes.item(i));
}

/**
 * 获取某样式名在样式表中出现过样式值列表
 * @param styles 样式列表
 * @param styleName 样式名
 */
export function getCSSStyleValue(styles: CSSStyleDeclaration[], styleName: CSSFontStyle): undefined | string[] {
  const values = styles.reduce((prev, curr) => {
    const value = curr[styleName];
    if (value && !prev.includes(value)) {
      prev.push(value);
    }
    return prev;
  }, [] as string[]);
  return values;
}

/**
 * 获取字符串中出现过的样式 { [样式名]： 样式值 }
 * @param {HTMLElement} richTextDom
 */
export function getManyRichStyleByRichTextDom(richTextDom: HTMLElement): IRichTextStyleSet {
  const richTextStyle = {
    color: [],
    fontFamily: [],
    fontSize: [],
    fontWeight: [],
    fontStyle: [],
    textDecorationLine: [],
    textAlign: [],
  } as IRichTextStyleSet;
  const textStyles = Object.keys(richTextStyle);

  richTextDom.id = 'getcssstyles';
  const nodes = getAllChildNode(richTextDom);

  return nodes.reduce((prev, node) => {
    // 返回字符串在DOM状态下的所有样式
    textStyles.forEach((name) => {
      const styleName = name as CSSFontStyle;
      const value = getParentStyle(node, { endID: 'getcssstyles', endStyle: styleName });
      if (!prev[styleName].includes(value)) {
        prev[styleName].push(value);
      }
    });
    return prev;
  }, richTextStyle);
}

/**
 * 将富文本样式转换成组件的属性
 * @param style
 * @param sourceStyle
 */
function getTextFormatByManyRichStyle(style: IRichTextStyleSet, sourceStyle: IRichTextStyle): IStyle {
  const textFormat: IStyle = {};
  textFormat.fontStyle = {};

  const { fontFamily, fontSize, color, fontWeight, fontStyle, textAlign, textDecorationLine } = style;

  if (fontFamily.length === 1 && fontFamily[0] && fontFamily[0] !== sourceStyle.fontFamily) {
    textFormat.fontFamily = getFontKey(fontFamily[0]);
  }

  if (fontSize.length === 1 && fontSize[0] && fontSize[0] !== sourceStyle.fontSize) {
    textFormat.fontSize = parseInt(fontSize[0].split('px')[0]);
  }

  if (color.length === 1 && color[0] && color[0] !== sourceStyle.color) {
    textFormat.color = color[0];
  }

  if (textAlign.length === 1 && textAlign[0] && textAlign[0] !== sourceStyle.textAlign) {
    textFormat.textAlign = textAlign[0];
  }

  if (fontWeight.length === 1 && fontWeight[0] && fontWeight[0] !== sourceStyle.fontWeight) {
    textFormat.fontStyle.bold = fontWeight[0] === 'bold' || parseInt(fontWeight[0]) > 400;
  }

  if (fontStyle.length === 1 && fontStyle[0] && fontStyle[0] !== sourceStyle.fontStyle) {
    textFormat.fontStyle.italic = fontStyle[0] === 'italic';
  }

  if (textDecorationLine.length) {
    const strike = textDecorationLine.every((value) => value.includes('line-through'));
    textFormat.fontStyle.strike = strike;

    const underline = textDecorationLine.every((value) => value.includes('underline'));
    textFormat.fontStyle.underline = underline;
  }

  return textFormat;
}

/**
 * 获取字符串中需要调整的样式。
 *
 * mty的理解：处理文本编辑后，整个组件的的样式变化，比如全选后bold，则组件的 textFormat.bold 应该为 true
 * @param richTextDom
 * @param sourceStyle
 */
export function getTextFormatByRichTextDom(richTextDom: HTMLElement, sourceStyle: IRichTextStyle): IStyle {
  const manyRichStyle = getManyRichStyleByRichTextDom(richTextDom);
  const adjustedTextFormat = getTextFormatByManyRichStyle(manyRichStyle, sourceStyle);
  return adjustedTextFormat;
}

/**
 * 向上获取当前节点最近的样式
 * @param node
 * @param options
 */
function getParentStyle(
  node: Node,
  options: {
    endID: string;
    endStyle: CSSFontStyle;
  },
) {
  const { endID, endStyle } = options;
  let parent = node.parentElement;
  while (parent) {
    const styleValue = parent.style[endStyle];
    if (styleValue) {
      return styleValue;
    }
    if (parent.id === endID) {
      return '';
    }
    parent = parent.parentElement;
  }
  return '';
}

/**
 * 获取所有底端Node Text
 * @param element
 */
function getAllChildNode(element: HTMLElement) {
  let elements = getChildNodes(element);
  const children = elements.filter((n) => n.nodeType === Node.TEXT_NODE);
  while (elements.length) {
    const _element = elements[0];
    if (_element.nodeType === Node.ELEMENT_NODE) {
      const childElements = getChildNodes(_element as HTMLElement);
      elements = [...elements, ...childElements];
    } else {
      children.push(_element);
    }
    elements.shift();
  }
  return children;
}

/**
 * 通过innerHTML格式化文本
 * @param {string} text 文本
 * dom.innerHTML 会导致性能消耗，应尽量减少使用次数
 */
export function transformTextToRichTextDom(text: string): HTMLElement {
  const element = document.createElement('div');
  element.innerHTML = text;
  return element;
}

/**
 * 获取格式化文本的的列表类型
 * @param {HTMLElement} formattedTextDom
 */
export function getListTypeByRichTextDom(innerHTMLDom: HTMLElement): 'order' | 'unOrder' | undefined {
  if (innerHTMLDom.childNodes.length === 1) {
    const firstChild = innerHTMLDom.firstChild;
    switch (firstChild?.nodeName) {
      case 'UL':
        return 'unOrder';
      case 'OL':
        return 'order';
      default:
        break;
    }
  }
  return;
}

/**
 * 遍历富文本 dom 树，对行内容处理
 * @param richTextRootNode
 * @param visitor
 * @author miaotianyugo
 */
export function traverseRichTextByLine(
  richTextRootNode: Node,
  visitor?: (inlineNodes: ChildNode[], parentNode: Node) => void,
) {
  const childNodes = richTextRootNode.childNodes;

  let inlineNodes: ChildNode[] = [];

  for (let i = 0; i < childNodes.length; i++) {
    const childNode = childNodes[i];

    if (blockTags.includes(childNode.nodeName)) {
      traverseRichTextByLine(childNode, visitor);
      continue;
    }

    // 收集同一行的节点
    inlineNodes.push(childNode);

    // 处理同一行的节点，访问 visitor
    const nextChildNode = childNodes[i + 1];
    if (!nextChildNode || blockTags.includes(nextChildNode.nodeName)) {
      if (visitor) {
        visitor(inlineNodes, richTextRootNode);
      }
      inlineNodes = [];
    }
  }
}

export const NUMBER_FILTER_CHARS = '-0123456789.';

export function filterValueText(value: string, filterChars: string): string {
  const pattern = escapeRegExp(filterChars);
  const regex = new RegExp('[^' + pattern + ']', 'g');
  return value.replace(regex, '');
}

function deleteNoHeightEmptyLine(htmlRootNode: Node) {
  const childNodes = Array.from(htmlRootNode.childNodes);

  for (let i = 0; i < childNodes.length; i++) {
    const node = childNodes[i];
    const nodeName = node.nodeName;

    if (nodeName !== 'BR' && blockTags.includes(nodeName)) {
      if (node.hasChildNodes()) {
        deleteNoHeightEmptyLine(node);
      } else {
        node.remove();
      }
    }
  }
}

/**
 * 清除 html 字符串中的没有占据高度空间的空行
 * @param htmlString
 */
export function deleteHTMLNoHeightEmptyLine(htmlString: string): string {
  const rootNode = document.createElement('div');
  rootNode.innerHTML = htmlString;
  deleteNoHeightEmptyLine(rootNode);
  return rootNode.innerHTML;
}

export function queryRichTextBoldState(richTextRootNode: Node) {
  let childNodes = Array.from(richTextRootNode.childNodes);
  let node;

  while ((node = childNodes.shift())) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.parentElement && Number(window.getComputedStyle(node.parentElement).fontWeight) <= 400) {
        // 有文本不是加粗的
        return false;
      }
    } else {
      if (node.hasChildNodes()) {
        childNodes = childNodes.concat(Array.from(node.childNodes));
      }
    }
  }

  // 所有文本节点样式都是加粗
  return true;
}
