import * as React from 'react';

import './index.scss';
import { isMacOS } from '../utils';

const Shortcut: React.FC<{
  shortCut: string;
  macOSShortCut?: string;
}> = ({ shortCut, macOSShortCut }) => {
  if (!shortCut) {
    return null;
  }
  let keyStr = shortCut;
  if (isMacOS && macOSShortCut) {
    keyStr = macOSShortCut;
  }

  const keys = keyStr
    .split('+')
    .join(' + ')
    .split(' ')
    .filter((key) => key.trim() !== '');
  const innerContent = keys.map((k: string, index: number) => {
    if (k === '+' && index !== keys.length - 1) {
      return k;
    }
    return (
      <kbd className="key" key={`${k}-${index}`}>
        {k}
      </kbd>
    );
  });
  return <kbd>{innerContent}</kbd>;
};

export default Shortcut;
