import * as echarts from 'echarts';
import {
  CAreaChart,
  CBarChart,
  CBarChartHorizontal,
  CDoughnutChart,
  CLineChart,
  CPieChart,
  CRadarChart,
  CScatterChart,
  CCombinationChart,
  CGaugeChart,
  CMapChart,
} from '@/libs/constants';
import { UIComponent } from '@/editor/comps';
import { renderBarChartChart } from '../render/renderBarChartChart';
import { renderPieChartChart } from '../render/renderPieChart';
import { renderGaugeChart } from '../render/renderGaugeChart';
import { renderMapChartChart } from '../render/renderMapChart';

// [CBarChart]: NormalChart,
// [CPieChart]: PieChart,
// [CDoughnutChart]: PieChart,
// [CLineChart]: NormalChart,
// [CBarChartHorizontal]: NormalChart,
// [CAreaChart]: NormalChart,
// [CRadarChart]: NormalChart,
// [CScatterChart]: NormalChart,

export const parseChartDom = (t: UIComponent): Element => {
  const dom = document.createElement('div');
  const chartInstance = echarts.init(dom, undefined, {
    renderer: 'svg',
  });

  const type = t.type;

  switch (type) {
    case CBarChart:
    case CBarChartHorizontal:
    case CLineChart:
    case CAreaChart:
    case CRadarChart:
    case CScatterChart:
    case CCombinationChart:
      renderBarChartChart(chartInstance, t);
      break;

    case CPieChart:
    case CDoughnutChart:
      renderPieChartChart(chartInstance, t);
      break;
    case CGaugeChart:
      renderGaugeChart(chartInstance, t);
      break;
    default:
      renderBarChartChart(chartInstance, t);
  }
  return dom;
};

export const parseChart = (t: UIComponent): string => {
  const dom = t.type === CMapChart ? renderMapChartChart(t) : parseChartDom(t);
  return dom.innerHTML;
};
