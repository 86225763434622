import * as React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';
import { UIComponent } from '@editor/comps';
import { StyleHelper } from '@helpers/styleHelper';
import { FillType } from '@fbs/rp/models/properties/fill';
import { defaultDisabledStroke } from '@/consts/stroke';
import Background from '@/libs/basic/common/Background';

/**
 * 为了不受组件编辑的影响，导出图片这里特地独立写一个渲染
 * @param comp
 * @param children
 * @returns
 */
export const renderContentPanelV2 = (comp: UIComponent, children?: JSX.Element) => {
  const isPreview = true;
  const { size, properties } = comp;
  const { stroke } = properties;
  const fill = properties.fill ?? {
    type: FillType.solid,
    color: '#fff',
    disabled: true,
  };
  const showPlaceBorder = !isPreview && fill?.disabled && stroke?.disabled;
  const showStroke = showPlaceBorder ? defaultDisabledStroke : stroke;
  const showProperties = { ...properties, stroke: showStroke };
  // 默认边框不受border属性影响
  if (stroke?.disabled) {
    delete showProperties.border;
  }
  const styleParser = StyleHelper.initCSSStyleParser(showProperties);
  const opacity = isUndefined(comp.opacity) ? 1 : comp.opacity / 100;
  const isTransparent = opacity === 0;
  const transition = comp.getTransition();
  return (
    <div
      className={classnames('lib-comp-content-panel-v2', {
        preview: isPreview,
        editing: !isPreview,
      })}
      style={{
        opacity,
        transition,
        ...size,
        ...styleParser.getRadiusStyle(size),
        ...styleParser.getStrokeStyle(),
      }}
    >
      {!fill?.disabled && !isTransparent && <Background size={size} properties={{ fill }} transition={transition} />}
      {children}
    </div>
  );
};
