import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// 对话框（圆）
export default {
  ...flowBase,
  name: i18n('resource.flow.roundedSpeechBubble'),
  type: 'roundedSpeechBubble',
  thumb: {
    spriteIconClass: SpriteThumb.RoundedSpeechBubble.className,
    dragPosition: SpriteThumb.RoundedSpeechBubble.position,
  },
  template: getShapeData(
    i18n('resource.flow.roundedSpeechBubble'),
    {
      width: 100,
      lockedRatio: false,
      height: 60,
    },
    {
      data: [
        {
          point: {
            x: 0,
            y: 30,
          },
          handleIn: {
            x: 0,
            y: -22,
          },
          handleOut: {
            x: 0,
            y: 9.5,
          },
        },
        {
          point: {
            x: 13,
            y: 50,
          },
          handleIn: {
            x: -7,
            y: -4.5,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 22,
            y: 55,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 8.5,
            y: 3.5,
          },
        },
        {
          point: {
            x: 50,
            y: 60,
          },
          handleIn: {
            x: -7.5,
            y: 0,
          },
          handleOut: {
            x: 16.5,
            y: 0,
          },
        },
        {
          point: {
            x: 100,
            y: 30,
          },
          handleIn: {
            x: 0,
            y: 22,
          },
          handleOut: {
            x: 0,
            y: -22,
          },
        },
        {
          point: {
            x: 50,
            y: 0,
          },
          handleIn: {
            x: 22,
            y: 0,
          },
          handleOut: {
            x: -22,
            y: 0,
          },
        },
      ],
      closed: true,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
