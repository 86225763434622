import * as React from 'react';
import * as ReactDom from 'react-dom';
import * as tinyColor from 'tinycolor2';
import classnames from 'classnames';

import { depthClone, isEqualDate, isInputting } from '@utils/globalUtils';
import {
  Color,
  FillData,
  FillType,
  IColorStop,
  IFill,
  IGradient,
  ILinearGradient,
  IRadialGradient,
  parseColorToString,
  PureColor,
  transitionGradientToPureColor,
  transitionPureColorToLinearGradient,
  transitionPureColorToRadialGradient,
} from '@utils/graphicsUtils';
import { dragDelegate } from '@utils/mouseUtils';
import { getEnumValue } from '@utils/enumUtils';

import KeyCodeMap from '@dsm2/constants/KeyCodeMap';

import i18n from '@i18n';
import { larkPC } from '@helpers/larkHelper';
import { ComponentTheme } from '../../common';
import colorPanelManager, { IColorPanel } from '../types';
import { IValueEditorPanel } from '@/customTypes';
import { IPresetColor, IUserPreference } from '@/fbs/rp/models/grid';

import { IColorInfo } from '../model';
import withAutoClose, { IAutoCloseComponentProps, IAutoCloseProps } from '../../withAutoClose';
import { Icon, IListItem, PopupManager } from '../../index';

import ColorPlate from '../ColorPlate';
import PresetColors from '../PresetColors';
import ColorExtractor from '../ColorExtractor';
import Hue from '../Hue';
import Alpha from '../Alpha';
import Select from '../../Select';
import Gradient from '../Gradient';
import ColorInput from '../ColorInput';
import RecentColors from '../RecentColors';
import PreferenceColors from '../PreferenceColors';

import './index.scss';

export interface IColorPanelProp extends IAutoCloseComponentProps {
  color: IFill;
  disabledGradient?: boolean;
  colorModels?: FillType[];
  disabledAlpha?: boolean;
  favorite?: Array<IFill>;
  recentColors: Array<IFill>;
  autoFocusToColorValue?: boolean;
  sourceBounds: { left: number; top: number; width: number; height: number };
  onClose: () => void;
  onChanged: (color: IFill) => void;
  onChanging: (color: IFill) => void;
  onDownloadExtension: () => void;
  onFavoriteColor?: (color: IFill) => void;
  onRemoveFavoriteColor?: (color: IFill) => void;
  isInPopup?: boolean;
  theme?: ComponentTheme;
  presetColors?: IPresetColor[];
  onColorStopIndexChanged?: (index: number) => void;
  onPanelMouseDown?: React.MouseEventHandler;
  onSwitchToGradient?: (isGradient: boolean) => void;
  onPatchPreference?: (value: Partial<IUserPreference>) => void;
}

interface IColorPanelPropsEx extends IColorPanelProp {
  onMouseDown?: React.MouseEventHandler;
  onMouseUp?: React.MouseEventHandler;
  allowPickupColor?: boolean;
}

export interface IColorPanelState {
  position: { left: number; top: number };
  selectColor: number;
  currentColor?: IFill;
  colorInfo: IColorInfo;
  colorSourceModel: ColorSourceModel;
  showCollectBg?: boolean;
  clientX: number;
  clientY: number;
  colorModel?: { id: FillType; text: string }[];
}

const ColorTypeConfig: Array<{ id: FillType; text: string }> = [
  { id: FillType.solid, text: i18n('editor.solidFill') },
  { id: FillType.linear, text: i18n('editor.linearFill') },
  { id: FillType.radial, text: i18n('editor.radialFill') },
];

enum ColorSourceModel {
  plate = 'plate',
  preset = 'preset',
}

class ColorPanel extends React.Component<IColorPanelPropsEx, IColorPanelState> implements IColorPanel {
  static defaultProps: Partial<IColorPanelProp> = {
    autoFocusToColorValue: true,
  };
  selfRef: React.RefObject<HTMLDivElement>;

  constructor(props: IColorPanelProp) {
    super(props);
    const key = localStorage.getItem('colorSourceModel');
    const colorSourceModel = key ? getEnumValue(ColorSourceModel, key) : ColorSourceModel.plate;
    let colorModel: { id: FillType; text: string }[] | undefined = undefined;
    if (!props.disabledGradient) {
      if (props.colorModels) {
        colorModel = props.colorModels.map((type) => ColorTypeConfig.find((item) => item.id === type)!);
      } else {
        colorModel = ColorTypeConfig;
      }
    }
    this.state = {
      position: {
        left: props.sourceBounds.left,
        top: props.sourceBounds.top,
      },
      colorInfo: this.getColorInfo(props.color, 0),
      selectColor: 0,
      currentColor: props.color,
      colorSourceModel,
      clientX: 0,
      clientY: 0,
      colorModel,
    };
    this.selfRef = props.forwardedRef || React.createRef();
  }

  componentDidMount() {
    if (colorPanelManager.currentColorPanel) {
      colorPanelManager.currentColorPanel.exitGradient();
    }
    colorPanelManager.currentColorPanel = this;
    this.doSwitchGradient(this.color.type !== FillType.solid);
    this.doAdjustPosition();
  }

  componentWillUnmount() {
    if (colorPanelManager.currentColorPanel === this) {
      this.doSwitchGradient(false);
      colorPanelManager.currentColorPanel = undefined;
    }
    window.removeEventListener('mouseup', this.handleWindowMouseUpWhenCloseColorExtractor);
  }

  UNSAFE_componentWillReceiveProps(newProps: IColorPanelProp) {
    if (!isEqualDate(newProps.color, this.props.color)) {
      let selectedIndex = this.state.selectColor;
      if (newProps.color.type !== FillType.solid && !(newProps.color.color as IGradient).colorStops[selectedIndex]) {
        selectedIndex = 0;
      }
      const colorInfo = this.getColorInfo(newProps.color, selectedIndex);
      if (colorInfo.hsv.v === 0) {
        this.setState({
          selectColor: selectedIndex,
          colorInfo: {
            ...colorInfo,
            hsv: {
              ...colorInfo.hsv,
              h: this.state.colorInfo.hsv.h,
            },
            hsl: {
              ...colorInfo.hsl,
              h: this.state.colorInfo.hsv.h,
            },
          },
        });
      } else {
        this.setState({
          selectColor: selectedIndex,
          colorInfo,
        });
      }
    }
    this.setState({ currentColor: undefined });
    if (this.props.color.type !== newProps.color.type && newProps.color.type !== FillType.solid) {
      this.doSwitchGradient(true);
    }
  }

  exitGradient() {
    if (!this.props.disabledGradient) {
      this.doSwitchGradient(false);
    }
  }

  private get colorType() {
    return this.props.color.type;
  }

  private get allowChange() {
    if (this.props.disabledGradient) {
      return true;
    }
    return this.state.colorModel?.map((item) => item.id).includes(this.colorType);
  }

  private doSwitchGradient = (isGradient: boolean) => {
    const { onSwitchToGradient } = this.props;
    onSwitchToGradient && onSwitchToGradient(isGradient);
  };

  get color(): IFill {
    return depthClone(this.props.color);
  }

  get selectedIndex(): number {
    return this.state.selectColor;
  }

  selectedColorStop(index: number): void {
    this.doSelectedPoint(index);
  }

  colorStopChanged(newColorStops: IColorStop[], selectIndex: number): void {
    if (!this.allowChange) {
      return;
    }
    this.onGradientChanged(newColorStops, selectIndex);
  }

  colorStopChanging(newColorStops: IColorStop[]): void {
    if (!this.allowChange) {
      return;
    }
    const { color } = this.props;
    const newColor = depthClone(color);
    (newColor.color as IGradient).colorStops = newColorStops;
    this.props.onChanging(newColor);
    this.setState({
      colorInfo: this.getColorInfo(newColor, this.state.selectColor),
      currentColor: newColor,
    });
  }

  moveLinePoint(newColor: IFill, isChanging?: boolean): void {
    if (!this.allowChange) {
      return;
    }
    if (isChanging) {
      this.props.onChanging(newColor);
    } else {
      this.props.onChanged(newColor);
    }
  }

  removeColorStop(): void {
    if (!this.allowChange) {
      return;
    }
    const { selectColor } = this.state;
    const colorStops = (this.color.color as IGradient).colorStops;
    if (selectColor !== 0 && selectColor !== colorStops.length - 1) {
      const newColorStops: IColorStop[] = depthClone(colorStops);
      newColorStops.splice(selectColor, 1);
      this.onGradientChanged(newColorStops, selectColor);
    }
  }

  getColorInfo = (color: IFill, selectColor: number): IColorInfo => {
    let selColor;
    if (color.type === FillType.solid) {
      selColor = color.color as Color;
    } else {
      selColor = (color.color as IGradient).colorStops[selectColor].color;
    }
    const tcolor = tinyColor(parseColorToString(selColor));
    return {
      rgb: tcolor.toRgb(),
      hsv: tcolor.toHsv(),
      alpha: tcolor.getAlpha() * 100,
      hsl: tcolor.toHsl(),
    };
  };

  getFill: (colorValue: Color) => IFill = (colorValue) => {
    const { color: currentFill } = this.props;
    const { selectColor: selectColorStopIndex } = this.state;
    const { type, color: currentFillData } = currentFill;

    let nextFillData: FillData;

    if (type === FillType.solid) {
      // 纯色
      nextFillData = colorValue;
    } else {
      // 渐变
      const currentGradientFillData = currentFillData as IGradient;
      const newColorStop: IColorStop = {
        ...currentGradientFillData.colorStops[selectColorStopIndex],
        color: colorValue,
      };
      nextFillData = {
        ...currentGradientFillData,
        colorStops: currentGradientFillData.colorStops.map((colorStop, index) =>
          index === selectColorStopIndex ? newColorStop : colorStop,
        ),
      };
    }

    return { type, color: nextFillData };
  };

  doSelectedPoint = (index: number) => {
    const { color, onColorStopIndexChanged } = this.props;
    this.setState(
      {
        selectColor: index,
        colorInfo: this.getColorInfo(color, index),
      },
      () => {
        onColorStopIndexChanged && onColorStopIndexChanged(index);
      },
    );
  };

  private needStop = false;

  handlePresetColorSelected = (rgba: tinyColor.ColorFormats.RGBA, isChanging?: boolean) => {
    if (!this.allowChange) {
      return;
    }
    this.needStop = true;
    this.doRGBAColorChanged(rgba, isChanging);
  };

  handleColorInputChanged = (rgba: tinyColor.ColorFormats.RGBA, isChanging?: boolean) => {
    if (!this.allowChange) {
      return;
    }
    if (this.needStop) {
      this.needStop = false;
      return;
    }
    this.doRGBAColorChanged(rgba, isChanging);
  };

  doRGBAColorChanged = (rgba: tinyColor.ColorFormats.RGBA, isChanging?: boolean) => {
    if (!this.allowChange) {
      return;
    }
    const { color, onChanged } = this.props;
    const newColor = depthClone(color);
    const { selectColor } = this.state;
    if (color.type === FillType.solid) {
      newColor.color = { ...rgba };
    } else {
      const { colorStops } = newColor.color as IGradient;
      colorStops[selectColor].color = { ...rgba };
      (newColor.color as IGradient).colorStops = [...colorStops];
    }
    if (isChanging) {
      this.setState({
        colorInfo: this.getColorInfo(newColor, selectColor),
      });
    } else {
      onChanged && onChanged(newColor);
    }
  };

  /**
   * 注意色相的变化
   */
  onHSVChanged = (hsv: tinyColor.ColorFormats.HSV, isChanging?: boolean) => {
    if (!this.allowChange) {
      return;
    }
    const { color, onChanged, onChanging } = this.props;
    const newColor = tinyColor(hsv).toRgb();
    const { selectColor } = this.state;
    let oldColor;
    if (color.type === FillType.solid) {
      oldColor = tinyColor(parseColorToString(color.color as Color)).toRgb();
    } else {
      const item = (color.color as IGradient).colorStops[selectColor];
      oldColor = tinyColor(parseColorToString(item.color as Color)).toRgb();
    }
    newColor.a = oldColor.a;
    const newFill = depthClone(color);
    if (color.type === FillType.solid) {
      newFill.color = newColor;
    } else {
      (newFill.color as IGradient).colorStops[selectColor].color = newColor;
    }

    if (isChanging) {
      onChanging && onChanging(newFill);
      this.setState({
        colorInfo: {
          ...this.getColorInfo(newFill, selectColor),
          hsv: hsv,
        },
      });
    } else {
      onChanged && onChanged(newFill);
    }
  };

  handleAlphaChanged = (alpha: number, isChanging?: boolean) => {
    if (!this.allowChange) {
      return;
    }
    const a = parseFloat((alpha / 100).toFixed(2));
    const { color, onChanged } = this.props;
    const { selectColor } = this.state;
    const newColor = depthClone(color);
    if (color.type === FillType.solid) {
      newColor.color = { ...tinyColor(parseColorToString(newColor.color as Color)).toRgb(), a };
    } else {
      const colorStops = [...(newColor.color as IGradient).colorStops];
      const c = colorStops[selectColor].color;
      const item = colorStops[selectColor];
      item.color = { ...tinyColor(parseColorToString(c as Color)).toRgb(), a };
      colorStops.splice(selectColor, 1, item);
      (newColor.color as IGradient).colorStops = colorStops;
    }

    if (isChanging) {
      this.setState({
        colorInfo: this.getColorInfo(newColor, selectColor),
      });
    } else {
      onChanged && onChanged(newColor);
    }
  };

  onGradientChanged = (colorStops: Array<IColorStop>, index: number) => {
    if (!this.allowChange) {
      return;
    }
    const { color, onChanged } = this.props;
    if (color.type !== FillType.solid) {
      const newColor = depthClone(color);
      (newColor.color as IGradient).colorStops = colorStops;
      this.setState({ selectColor: index }, () => {
        onChanged && onChanged(newColor);
      });
    }
  };

  onLinearGradientDirectionChange = (direction: { x1: number; y1: number; x2: number; y2: number }) => {
    const { color, onChanged } = this.props;
    if (color.type === FillType.linear) {
      const newValue = depthClone(color);
      (newValue.color as ILinearGradient).direction = direction;
      onChanged && onChanged(newValue);
    }
  };

  onRadialGradientPositionChange = (position: {
    from: { x: number; y: number; r: number };
    to: { x: number; y: number; r: number };
  }) => {
    if (!this.allowChange) {
      return;
    }
    const { color, onChanged } = this.props;
    if (color.type === FillType.radial) {
      const newValue = depthClone(color);
      (newValue.color as IRadialGradient).from = position.from;
      (newValue.color as IRadialGradient).to = position.to;
      onChanged && onChanged(newValue);
    }
  };

  onTypeChanged = ({ id }: IListItem) => {
    const { color, onChanged } = this.props;
    const newColor = depthClone(color);
    const { type } = color;
    const fillData = color.color;
    if (id === FillType.solid) {
      if (type !== FillType.solid) {
        newColor.color = transitionGradientToPureColor(fillData as IGradient);
      }
    } else if (id === FillType.linear) {
      if (type === FillType.solid) {
        newColor.color = transitionPureColorToLinearGradient(fillData as Color);
      } else if (type === FillType.radial) {
        newColor.color = {
          colorStops: (fillData as IGradient).colorStops,
          direction: { x1: 0.5, x2: 0.5, y1: 0, y2: 1 },
        };
      }
    } else {
      if (type === FillType.solid) {
        newColor.color = transitionPureColorToRadialGradient(fillData as Color);
      } else if (type === FillType.linear) {
        newColor.color = {
          colorStops: (fillData as IGradient).colorStops,
          to: { x: 0.5, y: 0.5, r: 0.5 },
          from: { x: 0.5, y: 0.5, r: 0 },
          widthRatio: 1,
          angle: 0,
        };
      }
    }

    if (id !== this.props.color.type) {
      this.setState({ selectColor: 0, colorInfo: this.getColorInfo(color, 0) });
    }
    newColor.type = id as FillType;
    onChanged && onChanged(newColor);
  };

  handleAddFavoriteColor = () => {
    const { color, onFavoriteColor } = this.props;
    const data = depthClone(color);
    onFavoriteColor && onFavoriteColor(data);
  };

  doAdjustPosition = () => {
    const { innerHeight, innerWidth } = window;
    if (this.selfRef.current) {
      const bounds = this.selfRef.current.getBoundingClientRect();
      const { sourceBounds } = this.props;
      let { left, top } = this.state.position;
      if (bounds.bottom > innerHeight) {
        top = Math.round(sourceBounds.top - bounds.height + sourceBounds.height);
        top = Math.min(top, Math.round(innerHeight - bounds.height + sourceBounds.height));
      }
      if (top < 2) {
        top = 2;
      }
      if (bounds.right > innerWidth) {
        left = Math.round(sourceBounds.left - bounds.width - 2);
      }
      if (left !== this.state.position.left || top !== this.state.position.top) {
        this.setState({ position: { left, top } });
      }
    }
  };

  handleDropMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  handlePanelDrag = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!(e.target as HTMLElement).classList.contains('dsm-c-rp-color-panel-type')) {
      return;
    }
    const startPosition = this.state.position;
    const { innerWidth, innerHeight } = window;
    const { width, height } = this.selfRef.current!.getBoundingClientRect();
    const maxLeft = innerWidth - width;
    const maxTop = innerHeight - height;
    dragDelegate(
      (e, delta) => {
        this.setState({
          position: this.validatePosition({
            left: Math.min(Math.max(startPosition.left + delta.x, 0), maxLeft),
            top: Math.min(Math.max(startPosition.top + delta.y, 0), maxTop),
          }),
        });
      },
      () => {},
    );
  };

  handleColorSourceModelSwitch = (colorSourceModel: ColorSourceModel) => {
    this.setState({ colorSourceModel }, () => {
      localStorage.setItem('colorSourceModel', colorSourceModel);
    });
  };

  handleStrawColor = (rgba: Color) => {
    const { onChanged } = this.props;
    this.doCloseColorExtractor();
    if (!this.allowChange) {
      return;
    }
    const newFill = this.getFill(rgba);
    this.setState({ colorInfo: this.getColorInfo(newFill, this.selectedIndex) });
    onChanged && onChanged(newFill);
  };

  doCloseColorExtractor = () => {
    this.setState({ showCollectBg: false });
    window.addEventListener('mouseup', this.handleWindowMouseUpWhenCloseColorExtractor);
  };

  handleWindowMouseUpWhenCloseColorExtractor() {
    window.removeEventListener('mouseup', this.handleWindowMouseUpWhenCloseColorExtractor);
    setTimeout(() => {
      const panel = PopupManager.manager.getLastPanel();
      if (panel && !panel.isModal && PopupManager.manager.count - PopupManager.manager.modalCount === 1) {
        panel.preventClose = false;
      }
    });
  }

  handleExtractColor = (event: React.MouseEvent) => {
    const { clientX, clientY } = event;
    this.setState({
      showCollectBg: true,
      clientX,
      clientY,
    });
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    const inputing = isInputting();
    const { onClose } = this.props;
    if (inputing) {
      return;
    }
    if (e.keyCode === KeyCodeMap.VK_ESCAPE) {
      onClose && onClose();
    }
  };

  handleEscInput = () => {
    const dom = this.selfRef.current;
    if (dom) {
      dom.tabIndex = -1;
      dom.focus();
    }
  };

  handleColorInputSubmit = (value: PureColor) => {
    if (!this.allowChange) {
      return;
    }
    const { color, onChanged } = this.props;
    const fill = this.getFill(value);

    if (isEqualDate(color, fill)) {
      return;
    }

    onChanged && onChanged(fill);
  };

  handlePanelMouseDown = (e: React.MouseEvent) => {
    const { onMouseDown, onPanelMouseDown } = this.props;
    onPanelMouseDown && onPanelMouseDown(e);
    const dom = e.target as HTMLElement;
    if (dom.nodeName.toLowerCase() === 'input') {
      return;
    }
    e.preventDefault();
    onMouseDown && onMouseDown(e);
  };

  validatePosition = (position: {
    left: number;
    top: number;
  }): {
    left: number;
    top: number;
  } => {
    return {
      left: position.left < 2 ? 2 : position.left,
      top: position.top < 2 ? 2 : position.top,
    };
  };

  renderColorExtractor() {
    const { clientX, clientY } = this.state;
    const { onDownloadExtension } = this.props;
    return ReactDom.createPortal(
      <ColorExtractor
        x={clientX}
        y={clientY}
        onClick={this.handleStrawColor}
        onCancel={this.doCloseColorExtractor}
        onDownloadExtension={onDownloadExtension}
      />,
      document.body,
    );
  }

  private handlePatchPreferenceColor = (value: IPresetColor[]): void => {
    this.props.onPatchPreference?.({ colors: value });
  };

  private renderPreferenceColors(): React.ReactNode {
    const { color, presetColors, onChanged, disabledGradient } = this.props;
    if (!presetColors) {
      return null;
    }
    return (
      <PreferenceColors
        colors={presetColors}
        value={color}
        onChange={onChanged}
        disabledGradient={disabledGradient}
        onPatch={this.handlePatchPreferenceColor}
      />
    );
  }

  render() {
    const {
      disabledGradient,
      disabledAlpha,
      color,
      recentColors,
      onChanged,
      onChanging,
      isInPopup,
      theme,
      onMouseUp,
    } = this.props;
    const { selectColor, position, colorInfo, colorSourceModel, showCollectBg } = this.state;
    const showHue = colorSourceModel === ColorSourceModel.plate;
    return (
      <div className={classnames('dsm-c-rp-color-panel popup-with-body', theme, { 'in-popup': isInPopup })}>
        <div
          className="dsm-c-rp-color-panel-content"
          ref={this.selfRef}
          style={position}
          onMouseDown={this.handlePanelMouseDown}
          onMouseUp={onMouseUp}
          onKeyDown={this.handleKeyDown}
        >
          <div className="dsm-c-rp-color-panel-content-special">
            <div className="dsm-c-rp-color-content">
              <div className="dsm-c-rp-color-panel-type" onMouseDown={this.handlePanelDrag}>
                <div>
                  {!disabledGradient && (
                    <Select
                      dropDownMaxWidth={200}
                      data={this.state.colorModel}
                      selectedIndex={ColorTypeConfig.findIndex((item) => item.id === color.type)}
                      onSelect={this.onTypeChanged}
                      onDropMouseDown={this.handleDropMouseDown}
                      theme={theme === ComponentTheme.light ? 'light' : 'no-border'}
                      isInPopup={isInPopup}
                    />
                  )}
                </div>
                <div className="dsm-c-rp-color-source-model">
                  <Icon
                    cls="icon_palette"
                    selected={colorSourceModel === ColorSourceModel.plate}
                    onClick={this.handleColorSourceModelSwitch.bind(this, ColorSourceModel.plate)}
                  />
                  <Icon
                    cls="grid"
                    selected={colorSourceModel === ColorSourceModel.preset}
                    onClick={this.handleColorSourceModelSwitch.bind(this, ColorSourceModel.preset)}
                  />
                </div>
              </div>
              {color.type !== FillType.solid && (
                <Gradient
                  disabled={!this.allowChange}
                  color={this.state.currentColor || color}
                  selectedIndex={selectColor}
                  onSelect={this.doSelectedPoint}
                  onColorStopChanged={this.onGradientChanged}
                  onChanged={this.allowChange ? onChanged : undefined}
                  onChanging={this.allowChange ? onChanging : undefined}
                  onDirectionChange={this.onLinearGradientDirectionChange}
                  onPositionChange={this.onRadialGradientPositionChange}
                />
              )}
              <div className="dsm-c-rp-color-panel-general">
                {colorSourceModel === ColorSourceModel.plate && (
                  <ColorPlate disabled={!this.allowChange} color={colorInfo} onChanged={this.onHSVChanged} />
                )}
                {colorSourceModel === ColorSourceModel.preset && (
                  <PresetColors onChanged={this.handlePresetColorSelected} />
                )}
                {showCollectBg && this.allowChange && this.renderColorExtractor()}
                {(showHue || !disabledAlpha) && (
                  <div className="dsm-c-rp-color-panel-general-change">
                    <div className="dsm-c-rp-color-panel-general-collector">
                      <Icon
                        cls="icon_picker"
                        disabled={larkPC || !this.allowChange}
                        size={18}
                        onClick={this.handleExtractColor}
                      />
                    </div>
                    <div className="dsm-c-rp-color-panel-general-changeOn">
                      {colorSourceModel === ColorSourceModel.plate && (
                        <Hue disabled={!this.allowChange} color={colorInfo} onChange={this.onHSVChanged} />
                      )}
                      {!disabledAlpha && (
                        <Alpha
                          disabled={!this.allowChange}
                          alpha={colorInfo.alpha}
                          color={colorInfo.rgb}
                          onChange={this.handleAlphaChanged}
                        />
                      )}
                    </div>
                    <div className="dsm-c-rp-color-panel-general-afterChange">
                      <div
                        style={{
                          backgroundColor: `rgba(${colorInfo.rgb.r}, ${colorInfo.rgb.g}, ${colorInfo.rgb.b}, ${
                            colorInfo.alpha / 100
                          })`,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="dsm-c-rp-color-panel-edit-input">
                <ColorInput
                  disabled={!this.allowChange}
                  color={colorInfo}
                  autoFocusToColorValue={this.props.autoFocusToColorValue}
                  onChanged={this.handleColorInputChanged}
                  onEsc={this.handleEscInput}
                  onSubmit={this.handleColorInputSubmit}
                />
              </div>
            </div>
            <div
              className={classnames('dsm-c-rp-color-before-use', { 'no-list': !recentColors || !recentColors.length })}
            >
              <div>
                <RecentColors
                  recentColors={recentColors}
                  onColorClick={onChanged}
                  disabledGradient={disabledGradient}
                />
              </div>
            </div>
          </div>
          {this.renderPreferenceColors()}
        </div>
      </div>
    );
  }
}

const PanelClass: React.ComponentClass<IColorPanelPropsEx & IAutoCloseProps> = withAutoClose<IColorPanelPropsEx>(
  ColorPanel,
);

export default class C extends React.Component<IColorPanelProp & IAutoCloseProps> implements IValueEditorPanel {
  handleMouseDown = () => {
    this.preventClose = true;
  };

  handleMouseUp = () => {
    setTimeout(() => {
      this.preventClose = false;
    }, 10);
  };

  get preventClose(): boolean {
    return this.panel.current?.preventClose;
  }

  set preventClose(value: boolean) {
    if (this.panel.current) {
      this.panel.current.preventClose = value;
    }
  }

  // @ts-ignore
  panel: React.RefObject<PanelClass> = React.createRef();

  render() {
    return (
      <PanelClass ref={this.panel} {...this.props} onMouseDown={this.handleMouseDown} onMouseUp={this.handleMouseUp} />
    );
  }
}
