import * as React from 'react';
import * as tinyColor from 'tinycolor2';
import * as _ from 'lodash';

import { IListItem, Input, InputNumber, Select } from '../..';
import { autoCompleHex, compareHex, hex2Rgb, hexChipPattern, rgb2Hex } from '@utils/graphicsUtils';
import { PureColor } from '@fbs/rp/models/properties/color';
import KeyCodeMap from '@/dsm2/constants/KeyCodeMap';

import { IColorInfo } from '../model';

import './index.scss';

export interface IColorPlateProp {
  color: IColorInfo;
  disabled?: boolean;
  onChanging: (color: tinyColor.ColorFormats.RGBA) => void;
  onChanged: (color: tinyColor.ColorFormats.RGBA) => void;
  onEsc?: () => void;
  onSubmit: (color: tinyColor.ColorFormats.RGBA) => void;
  autoFocusToColorValue?: boolean;
}

enum ColorFormats {
  RGB = 'rgb',
  Hex = 'hex',
  HSV = 'hsv',
  HSL = 'hsl',
}

const colorFormatList: IListItem[] = [
  { id: ColorFormats.RGB, text: 'RGB' },
  { id: ColorFormats.Hex, text: 'Hex' },
  { id: ColorFormats.HSV, text: 'HSV' },
  { id: ColorFormats.HSL, text: 'HSL' },
];

type ColorKey = 'r' | 'g' | 'b' | 'h' | 's' | 'l' | 'v' | 'a';

export interface IColorPlateState {
  stateColor: IColorInfo;
  type: ColorFormats;
  hex: string;
}

class ColorInput extends React.Component<IColorPlateProp, IColorPlateState> {
  static defaultProps: Partial<IColorPlateProp> = {
    autoFocusToColorValue: true,
  };

  private self: React.RefObject<HTMLDivElement> = React.createRef();
  private timeout?: Timeout;

  constructor(props: IColorPlateProp) {
    super(props);
    this.state = {
      stateColor: props.color,
      hex: rgb2Hex(props.color.rgb),
      type: ColorFormats.RGB,
    };
  }

  componentDidMount() {
    this.setTabIndex();
  }

  UNSAFE_componentWillReceiveProps(newProps: IColorPlateProp) {
    if (!_.isEqual(newProps.color, this.props.color)) {
      const hex = rgb2Hex(newProps.color.rgb);
      const color = this.mergeStateFromProps(newProps.color);
      this.setState({ stateColor: color, hex: compareHex(hex, this.state.hex) ? this.state.hex : hex });
    }
  }

  private mergeStateFromProps(propColor: IColorInfo) {
    const { stateColor } = this.state;
    const newColor = _.cloneDeep(propColor);
    ['rgb', 'hsv', 'hsl'].forEach((item) => {
      const key = item as 'rgb' | 'hsv' | 'hsl';
      const hex1 = tinyColor(newColor[key]).toHex();
      const hex2 = tinyColor(stateColor[key]).toHex();
      if (hex1 === hex2) {
        newColor[key] = stateColor[key] as any;
      }
    });
    return newColor;
  }

  private setTabIndex() {
    const inputList = this.self.current?.querySelectorAll('input');
    if (inputList) {
      inputList.forEach((input, i) => {
        input.tabIndex = i + 10000;
      });
    }
  }

  private handleHexChange = (value: string) => {
    const { onChanged } = this.props;
    const { stateColor, hex } = this.state;
    if (hexChipPattern.test(value)) {
      const fullHex = autoCompleHex(value);
      if (hex !== fullHex) {
        const rgb = hex2Rgb(fullHex);
        this.setState({ hex: fullHex }, () => {
          onChanged && onChanged({ ...rgb, a: this.dividedBy100('a', stateColor.alpha) });
        });
      }
    } else {
      return;
    }
  };

  private handleRgbaChange = (key: ColorKey, value: number) => {
    const { onChanged } = this.props;
    this.updateStateColor(key, value, onChanged);
  };

  private updateStateColor(key: ColorKey, value: number, fn?: (color: PureColor) => void) {
    const { stateColor, type } = this.state;

    const oldRgba = { ...stateColor.rgb, a: this.dividedBy100('a', stateColor.alpha) };
    const newRgba = this.getNewRGBA(key, value, oldRgba);
    if (!newRgba) {
      return;
    }
    if (_.isEqual(oldRgba, newRgba)) {
      return;
    }

    if (type === ColorFormats.Hex) {
      fn && fn(newRgba);
      return;
    }
    const newStateColor = _.cloneDeep(stateColor);
    const data: { [key: string]: number } = newStateColor[type] as any;
    data[key] = this.dividedBy100(key, value);
    this.setState({ stateColor: newStateColor }, () => {
      fn && fn(newRgba);
    });
  }

  private rgbaChangeMap = (key: ColorKey) => {
    return _.partial(this.handleRgbaChange, key);
  };

  private handleCheckHex = (value: string) => {
    return hexChipPattern.test(value);
  };

  private inputDoms: Array<Input | InputNumber> = [];

  private setInputRef = (index: number, el: Input | InputNumber | null) => {
    if (!el) {
      return;
    }
    this.inputDoms[index] = el;
  };

  private inputRefMap = (index: number) => {
    return _.partial(this.setInputRef, index);
  };

  private handleInputKeyDown = (e: React.KeyboardEvent, index: number) => {
    if (e.keyCode === KeyCodeMap.VK_TAB) {
      e.preventDefault();
      this.inputDoms[index]?.blur();
      if (!this.timeout) {
        this.timeout = window.setTimeout(() => {
          const nextIndex = index === this.inputDoms.length - 1 ? 0 : index + 1;
          this.inputDoms[nextIndex]?.focus();
          this.timeout = undefined;
        }, 150);
      } else {
        window.clearTimeout(this.timeout);
      }
    } else if (e.keyCode === KeyCodeMap.VK_ESCAPE) {
      this.props.onEsc && this.props.onEsc();
    }
  };

  private handleSubmit = (key: ColorKey, value: number) => {
    const { type } = this.state;
    if (type === ColorFormats.Hex) {
      return;
    }
    this.updateStateColor(key, value, this.props.onSubmit);
  };

  private getNewRGBA = (key: ColorKey, value: number, oldRgba: PureColor) => {
    const { stateColor, type } = this.state;
    if (type === ColorFormats.Hex) {
      return;
    }
    const newRgba = _.cloneDeep(oldRgba);
    if (key === 'a') {
      newRgba.a = this.dividedBy100('a', value);
    } else {
      const data = stateColor[type];
      const newData = Object.assign({}, data, { [key]: this.dividedBy100(key, value) });
      Object.assign(newRgba, tinyColor(newData).toRgb(), { a: newRgba.a });
    }
    return newRgba;
  };

  private colorSubmitMap = (key: ColorKey) => {
    return _.partial(this.handleSubmit, key);
  };

  private handleColorFormatChange = (item: IListItem) => {
    const { id } = item;
    if (this.state.type !== id) {
      this.setState({ type: id as ColorFormats });
    }
  };

  private handleHexInputKeyDown = (e: React.KeyboardEvent) => {
    this.handleInputKeyDown(e, 0);
  };

  private handleAlphaInputKeyDown = (e: React.KeyboardEvent) => {
    this.handleInputKeyDown(e, 4);
  };

  private renderTypeSelect = () => {
    const { type } = this.state;
    return (
      <div className="color-select">
        <div className="inner-block">
          <Select
            width={50}
            selectedIndex={colorFormatList.findIndex((item) => item.id === type)}
            onSelect={this.handleColorFormatChange}
            data={colorFormatList}
          />
        </div>
      </div>
    );
  };

  /**
   *Hex
   */
  private renderHexInput = () => {
    const { hex } = this.state;
    return (
      <div className="color-hex">
        <div className="inner-block">
          <Input
            ref={this.inputRefMap(0)}
            prefix="#"
            filterChar="abcdefABCDEF1234567890"
            value={hex}
            width={70}
            maxlength={6}
            autoSelectWhenFocus
            disabled={this.props.disabled}
            autoFocus={this.props.autoFocusToColorValue}
            onCheck={this.handleCheckHex}
            onBlur={this.handleHexChange}
            onKeyDown={this.handleHexInputKeyDown}
          />
          <label>HEX</label>
        </div>
      </div>
    );
  };

  /**
   * Alpha
   */
  private renderAlphaInput = () => {
    const { stateColor } = this.state;
    return (
      <div className="color-alpha">
        <div className="inner-block">
          <InputNumber
            ref={this.inputRefMap(4)}
            value={stateColor.alpha}
            width={40}
            suffix="%"
            autoSelectWhenFocus
            disabled={this.props.disabled}
            onBlur={this.rgbaChangeMap('a')}
            onKeyDown={this.handleAlphaInputKeyDown}
            onEnter={this.colorSubmitMap('a')}
            min={0}
            max={100}
            step={1}
          />
          <label>Alpha</label>
        </div>
      </div>
    );
  };

  private multiplyBy100 = (key: ColorKey, value: number) => {
    if (['a', 's', 'v', 'l'].includes(key)) {
      return Math.round(value * 100);
    }
    return value;
  };

  private dividedBy100 = (key: ColorKey, value: number) => {
    if (['a', 's', 'v', 'l'].includes(key)) {
      return value / 100;
    }
    return value;
  };

  private getMaxByKey = (key: ColorKey) => {
    if (key === 'h') {
      return 360;
    } else if (['r', 'g', 'b'].includes(key)) {
      return 255;
    }
    return 100;
  };

  /**
   * rgb/hsl/hsv
   */
  private renderRGBInput = () => {
    const { stateColor, type } = this.state;
    if (type === ColorFormats.Hex) {
      return;
    }
    let keyList: Array<ColorKey> = [];
    switch (type) {
      case ColorFormats.HSL:
        keyList = ['h', 's', 'l'];
        break;
      case ColorFormats.HSV:
        keyList = ['h', 's', 'v'];
        break;
      case ColorFormats.RGB:
      default:
        keyList = ['r', 'g', 'b'];
        break;
    }
    const data = (stateColor[type] as unknown) as { [key: string]: number };
    return (
      <div className="color-rgb">
        {keyList.map((key, i) => {
          return (
            <div key={key} className="inner-block">
              <InputNumber
                ref={this.inputRefMap(i + 1)}
                value={this.multiplyBy100(key, data[key])}
                width={30}
                // autoFocus={i === 0 && this.props.autoFocusToColorValue}
                autoSelectWhenFocus
                disabled={this.props.disabled}
                onBlur={this.rgbaChangeMap(key)}
                onKeyDown={(e) => {
                  this.handleInputKeyDown(e, i + 1);
                }}
                onEnter={this.colorSubmitMap(key)}
                min={0}
                max={this.getMaxByKey(key)}
                step={1}
              />
              <label>{key.toLocaleUpperCase()}</label>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <div className="dsm-c-rp-color-input" ref={this.self}>
        {/* {this.renderTypeSelect()} */}
        {this.renderHexInput()}
        {this.renderRGBInput()}
        {this.renderAlphaInput()}
      </div>
    );
  }
}

export default ColorInput;
