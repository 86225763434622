import { IComponentData } from '@/fbs/rp/models/component';
import { IPosition } from '@/fbs/common/models/common';

import { UIFragment } from '@/editor/comps';

import { FirstQuadrantArtboardPositionCalculator } from './FirstQuadrantArtboardPositionCalculator';
import { SecondQuadrantArtboardPositionCalculator } from './SecondQuadrantArtboardPositionCalculator';
import { ThirdQuadrantArtboardPositionCalculator } from './ThirdQuadrantArtboardPositionCalculator';
import { FourthQuadrantArtboardPositionCalculator } from './FourthQuadrantArtboardPositionCalculator';

// 象限
enum Quadrant {
  first,
  second,
  third,
  fourth,
}

// 获取激活画板所属的象限
function getQuadrantTheArtboardBelongsTo(artboardData: IComponentData, originPointPosition: IPosition): Quadrant {
  const { x, y } = artboardData.position;
  if (x >= originPointPosition.x && y < originPointPosition.y) {
    // 右上
    return Quadrant.first;
  }
  if (x < originPointPosition.x && y < originPointPosition.y) {
    // 左上
    return Quadrant.second;
  }
  if (x < originPointPosition.x && y >= originPointPosition.y) {
    // 左下
    return Quadrant.third;
  }
  // 右下
  return Quadrant.fourth;
}

export function createArtboardPositionCalculator(
  activeArtboard: UIFragment,
  artboards: IComponentData[],
  artboardData: IComponentData,
  originPointPosition: IPosition,
  positionCalcThreshold: number,
) {
  // 始终取激活画板所在的象限
  const quadrant = getQuadrantTheArtboardBelongsTo(activeArtboard.toJSON(), originPointPosition);
  switch (quadrant) {
    case Quadrant.first:
      return new FirstQuadrantArtboardPositionCalculator(
        artboards,
        artboardData,
        originPointPosition,
        positionCalcThreshold,
      );

    case Quadrant.second:
      return new SecondQuadrantArtboardPositionCalculator(
        artboards,
        artboardData,
        originPointPosition,
        positionCalcThreshold,
      );

    case Quadrant.third:
      return new ThirdQuadrantArtboardPositionCalculator(
        artboards,
        artboardData,
        originPointPosition,
        positionCalcThreshold,
      );

    case Quadrant.fourth:
      return new FourthQuadrantArtboardPositionCalculator(
        artboards,
        artboardData,
        originPointPosition,
        positionCalcThreshold,
      );

    default:
      return new FourthQuadrantArtboardPositionCalculator(
        artboards,
        artboardData,
        originPointPosition,
        positionCalcThreshold,
      );
  }
}
