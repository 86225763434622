/**
 * 矩形顶点顺序：0-左上、1-右上、2-右下、3-左下
 * 矩形四边顺序：0-上、1-右、2-下、3-左
 * resize点顺序：0-左上(nw)、1-右上(ne)、2-右下(se)、3-左下(sw)、  4-上(n)、5-右(e)、6-下(s)、7-左(w)
 */
import { IBounds, IPoint, IPosition, ISize } from '@fbs/common/models/common';
import {
  getCenterPoint,
  getTowPointDis,
  ILine,
  moveLineToPoint,
  offsetPoints,
  rotatePoint,
  getLinesByPoints,
  getPointsByLines,
} from '@utils/rotateUtils';
import { Direction } from '@fbs/common/models/resize';
import SelectionFrameType, { SelectionPoints } from '@consts/enums/selectionBoxType';
import { sameNumber } from '@utils/globalUtils';
import { Point } from '@helpers/point';

export enum ILineDirection {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

// 将选择点的index转换为方向
export function getDirectionByIndex(resizeIndex: number): Direction {
  const directions = [
    Direction.nw,
    Direction.ne,
    Direction.se,
    Direction.sw,
    Direction.n,
    Direction.e,
    Direction.s,
    Direction.w,
  ];
  return directions[resizeIndex];
}

export enum ILineIndex {
  Top = 0,
  Right = 1,
  Bottom = 2,
  left = 3,
}

/**
 * 居中的组件的拖曳的结果点的计算
 * @param originalPoints 原来的点
 * @param dragPointIndex 拖曳的点的index
 * @param newPoint 新的拖曳点的结束点
 * @
 * @param isHeightWidthBothChange
 * @param resizingCenterAnchorComp
 */
export function dragResizePoint(
  originalPoints: IPoint[],
  dragPointIndex: SelectionPoints,
  newPoint: IPoint,
  isHeightWidthBothChange: boolean,
  rotate: number = 0,
  resizingCenterAnchorComp?: {
    isVerticalMiddle: boolean;
    isHorizontalCenter: boolean;
  },
): IPoint[] {
  // 如果是水平居中，那么只有水平方向会两侧都移动
  // 如果是垂直居中，那么只有垂直方向两侧都移动
  // 只有同时垂直和水平居中，拉动顶点才是四条边都移动
  // 边按照顺时针 0、1、2、3的顺序排序
  const isVerticalMiddle = resizingCenterAnchorComp?.isVerticalMiddle || false;
  const isHorizontalCenter = resizingCenterAnchorComp?.isHorizontalCenter || false;
  const lines = getLinesByPoints(originalPoints);
  if (isVerticalMiddle || isHorizontalCenter) {
    const centerPoint = getCenterPoint(originalPoints);
    // 1、根据四个顶点计算出四条边
    // 2、根据移动的点和新位置，计算出新的四条边
    const isDragCornerPoint = dragPointIndex >= 0 && dragPointIndex < 4;
    if (isDragCornerPoint) {
      const lineIndex1 = dragPointIndex - 1 < 0 ? 3 : dragPointIndex - 1;
      const lineIndex2 = dragPointIndex;
      const oppositePoint = {
        x: 2 * centerPoint.x - newPoint.x,
        y: 2 * centerPoint.y - newPoint.y,
      };
      lines[lineIndex1] = moveLineToPoint(lines[lineIndex1], newPoint);
      lines[lineIndex2] = moveLineToPoint(lines[lineIndex2], newPoint);
      const remainLineIndex = [0, 1, 2, 3].filter((index) => {
        return index !== lineIndex1 && index !== lineIndex2;
      });
      // 移动另外两个边的时候要分开处理，水平居中时，才移动1、3边，垂直居中时，才移动0、2边
      remainLineIndex.forEach((index) => {
        if (index === 1 || index === 3) {
          if (isHorizontalCenter) {
            lines[index] = moveLineToPoint(lines[index], oppositePoint);
          }
        } else {
          // 垂直移动时，只有居中时，另一条边才发生移动
          if (isVerticalMiddle) {
            lines[index] = moveLineToPoint(lines[index], oppositePoint);
          }
        }
      });
    } else {
      // 移动边点的话，不仅要将边点所在边移动，它的对边也要移动
      const lineIndex = dragPointIndex - 4;
      const oppositeLineIndex = (lineIndex + 2) % 4;
      const oppositePoint = {
        x: 2 * centerPoint.x - newPoint.x,
        y: 2 * centerPoint.y - newPoint.y,
      };
      lines[lineIndex] = moveLineToPoint(lines[lineIndex], newPoint);
      // 如果移动的是1,3号边，代表在水平移动，
      const isResizeHorizontally = lineIndex === 1 || lineIndex === 3;
      if (isResizeHorizontally) {
        if (isHorizontalCenter || (rotate && isVerticalMiddle)) {
          lines[oppositeLineIndex] = moveLineToPoint(lines[oppositeLineIndex], oppositePoint);
        }
      } else {
        // 垂直移动时，只有居中时，另一条边才发生移动
        if (isVerticalMiddle || (rotate && isHorizontalCenter)) {
          lines[oppositeLineIndex] = moveLineToPoint(lines[oppositeLineIndex], oppositePoint);
        }
      }
    }
  } else {
    // 1、根据四个顶点计算出四条边
    // 2、根据移动的点和新位置，计算出新的四条边
    moveLineAccordingToPoint(originalPoints, dragPointIndex, lines, newPoint);
  }
  // todo 代码待整理
  // 处理四条边均垂直的情况
  const firstLine = lines[0];
  const secondLine = lines[1];
  if (firstLine.k === secondLine.k) {
    const lineIsPoint = firstLine.isPoint ? firstLine : secondLine;
    const index = firstLine.isPoint ? 0 : 1;
    lineIsPoint.k = 0;
    lineIsPoint.b = originalPoints[index].y;
    if (dragPointIndex === SelectionPoints.leftTop || dragPointIndex === SelectionPoints.rightTop) {
      lineIsPoint.b = newPoint.y;
    }
  }
  const thirdLine = lines[2];
  const fourthLine = lines[3];
  if (thirdLine.k === fourthLine.k) {
    const lineIsPoint = thirdLine.isPoint ? thirdLine : fourthLine;
    const index = thirdLine.isPoint ? 2 : 3;
    lineIsPoint.k = 0;
    lineIsPoint.b = originalPoints[index].y;
    if (dragPointIndex === SelectionPoints.rightBottom || dragPointIndex === SelectionPoints.leftBottom) {
      lineIsPoint.b = newPoint.y;
    }
  }
  return getPointsByLines(lines).map((point) => Point(point).round());
}

export function moveLineAccordingToPoint(
  // eslint-disable-next-line no-unused-vars
  originalPoints: IPoint[],
  dragPointIndex: number,
  lines: ILine[],
  newPoint: IPoint,
) {
  const isDragCornerPoint = dragPointIndex >= SelectionPoints.leftTop && dragPointIndex <= SelectionPoints.leftBottom;
  if (isDragCornerPoint) {
    const lineIndex1 = dragPointIndex - 1 < 0 ? 3 : dragPointIndex - 1;
    const lineIndex2 = dragPointIndex;
    lines[lineIndex1] = moveLineToPoint(lines[lineIndex1], newPoint);
    lines[lineIndex2] = moveLineToPoint(lines[lineIndex2], newPoint);
  } else {
    const lineIndex = dragPointIndex - 4;
    lines[lineIndex] = moveLineToPoint(lines[lineIndex], newPoint);
    // // 旋转情况会影响这里
    //fixme:贺瑞丰，如果resize过程中要显示选择框，就需要下面注释的代码，功能还不完善

    // if (isHeightWidthBothChange) {
    //   const allOriginalDragPoints = getAllResizePoints(originalPoints)
    //   if ([SelectionPoints.leftMiddle, SelectionPoints.rightMiddle].includes(dragPointIndex)) {
    //     const originBottomMiddlePoint = getAllPointsByCornerPoint(originalPoints)[SelectionPoints.bottomMiddle];
    //     let widthChange: number;
    //     if (SelectionPoints.leftMiddle === dragPointIndex) {
    //       const xChange = allOriginalDragPoints[SelectionPoints.leftMiddle].x - newPoint.x
    //       const yChange = allOriginalDragPoints[SelectionPoints.leftMiddle].y - newPoint.y
    //       widthChange = mapVectorToTargetCoordinates({x:xChange,y:yChange},-rotateRelativeToArtboard).x;
    //     } else {
    //       widthChange = newPoint.x - allOriginalDragPoints[SelectionPoints.rightMiddle].x;
    //     }
    //     const newWidth = width + widthChange;
    //     const heightChange = newWidth / originWidthHeightRatio - height;
    //     const newYOfBottomMiddlePoint = (heightChange + originBottomMiddlePoint.y);
    //     const newBottomMiddlePoint = {
    //       ...originBottomMiddlePoint,
    //       y: newYOfBottomMiddlePoint,
    //     };
    //     lines[ILineIndex.Bottom] = moveLineToPoint(lines[ILineIndex.Bottom], newBottomMiddlePoint);
    //   }
    //   if ([SelectionPoints.topMiddle, SelectionPoints.bottomMiddle].includes(dragPointIndex)) {
    //     const originRightMiddlePoint = getAllPointsByCornerPoint(originalPoints)[SelectionPoints.rightMiddle];
    //     let heightChange: number;
    //     if (SelectionPoints.topMiddle === dragPointIndex) {
    //       heightChange = originalPoints[SelectionPoints.leftTop].y - newPoint.y;
    //     } else {
    //       heightChange = newPoint.y - originalPoints[SelectionPoints.leftBottom].y;
    //     }
    //     const newHeight = height + heightChange;
    //     const widthChange = newHeight * originWidthHeightRatio - width;
    //     const newXOfRightMiddlePoint = round(widthChange + originRightMiddlePoint.x);
    //     const newRightMiddlePoint = {
    //       ...originRightMiddlePoint,
    //       x: newXOfRightMiddlePoint,
    //     };
    //     lines[ILineIndex.Right] = moveLineToPoint(lines[ILineIndex.Right], newRightMiddlePoint);
    //   }
    // }
  }
}

/**
 * 根据吸附效果生成的新bounds调整新的点
 * @param {IPoint[]} resizePoints
 * @param {number} resizePointIndex
 * @param {IBounds} newBounds
 * @returns {{x: number, y: number}}
 */
export function adjustPointByAdsorbedBounds(
  resizePoints: IPoint[],
  resizePointIndex: SelectionPoints,
  newBounds: IBounds,
): { x: number; y: number } {
  // 1、获取新bounds的中心点
  const centerPoint = {
    x: newBounds.left + newBounds.width / 2,
    y: newBounds.top + newBounds.height / 2,
  };
  const oppositePoint = getOppositePoint(resizePoints, resizePointIndex);

  // 3、根据对称点和中心点计算新的点
  return {
    x: centerPoint.x * 2 - oppositePoint.x,
    y: centerPoint.y * 2 - oppositePoint.y,
  };
}

export function getOppositePoint(resizePoints: IPoint[], resizeIndex: number): IPoint {
  let oppositeIndex;
  if (resizeIndex >= 0 && resizeIndex < 4) {
    oppositeIndex = resizeIndex - 2 < 0 ? resizeIndex + 2 : resizeIndex - 2;
  } else {
    const tempIndex = resizeIndex - 4;
    oppositeIndex = tempIndex - 2 < 0 ? tempIndex + 2 : tempIndex - 2;
    oppositeIndex = oppositeIndex + 4;
  }
  return resizePoints[oppositeIndex];
}

/**
 * 根据四个顶点获取矩形的宽高
 * @param {IPoint[]} points
 * @returns {ISize}
 */
export function getSizeByPoints(points: IPoint[]): ISize {
  const width = getTowPointDis(points[0], points[1]);
  const height = getTowPointDis(points[1], points[2]);
  return { width, height };
}

export function getAllResizePoints(points: IPoint[]): IPoint[] {
  const resizePoints = points.concat([]);

  let fromPoint = points[0];
  let nextPoint: IPoint;
  points.forEach((point, index) => {
    const nextIndex = index + 1 === points.length ? 0 : index + 1;
    nextPoint = points[nextIndex];
    resizePoints.push({
      x: (fromPoint.x + nextPoint.x) / 2,
      y: (fromPoint.y + nextPoint.y) / 2,
    });
    fromPoint = nextPoint;
  });
  return resizePoints;
}

export function getAllMiddlePointByCornerPoint(cornerPoint: IPoint[]) {
  return cornerPoint.reduce((acc, curr, index) => {
    const nextIndex = index + 1 === cornerPoint.length ? 0 : index + 1;
    const fromPoint = curr;
    const nextPoint = cornerPoint[nextIndex];
    acc.push({
      x: (fromPoint.x + nextPoint.x) / 2,
      y: (fromPoint.y + nextPoint.y) / 2,
    });
    return acc;
  }, [] as IPoint[]);
}

// 根据selectionFrame的类型返回可以被拖拽的点
export function getDragPoints(points: IPoint[], type: SelectionFrameType): IPoint[] {
  switch (type) {
    case SelectionFrameType.box:
      return points;
    case SelectionFrameType.none:
      return [];
    case SelectionFrameType.leftTop_to_rightBottom:
      return [points[0], points[2]];
    case SelectionFrameType.leftBottom_to_rightTop:
      return [points[1], points[3]];
    case SelectionFrameType.leftMiddle_to_rightMiddle:
      return [points[5], points[7]];
    case SelectionFrameType.topMiddle_to_bottomMiddle:
      return [points[4], points[6]];
    case SelectionFrameType.corner:
      return [points[0], points[1], points[2], points[3]];
    case SelectionFrameType.control:
      return [points[4], points[5], points[6], points[7]];
  }

  return points;
}

/**
 * 以矩形左上角为起点，获取矩形的四个顶点
 * @param {IPosition} position
 * @param {ISize} size
 * @param {number} rotate
 */
export function getRectPoints(position: IPosition, size: ISize, rotate: number = 0) {
  // 返回的点是按照顺时针顺序
  if (sameNumber(rotate, 0)) {
    return [
      { x: position.x, y: position.y },
      { x: position.x + size.width, y: position.y },
      { x: position.x + size.width, y: position.y + size.height },
      { x: position.x, y: position.y + size.height },
    ];
  }
  // 1.先转换切换成标准坐标系坐标 y = -y
  // 应用圆极坐标 x = a + r*cos(θ) y = b + r*sin(θ)
  // θ为该点与极轴的夹角，取逆时针
  // 最后将值变化回来 y = -y再来一次
  const { x: centerX, y: centerY } = getCenter(position, size, 0);
  const r = Math.hypot(size.width / 2, size.height / 2);
  const angle1 = Math.atan(size.height / size.width);
  const rotateInHuDu = (rotate / 180) * Math.PI;
  const xitaForNw = Math.PI - angle1 - rotateInHuDu;
  const xitaForNE = angle1 - rotateInHuDu;
  const xitaForSE = 2 * Math.PI - angle1 - rotateInHuDu;
  const xitaForSW = Math.PI + angle1 - rotateInHuDu;
  const cos = Math.cos;
  const sin = Math.sin;
  return [
    { x: centerX + r * cos(xitaForNw), y: -(-centerY + r * sin(xitaForNw)) },
    { x: centerX + r * cos(xitaForNE), y: -(-centerY + r * sin(xitaForNE)) },
    { x: centerX + r * cos(xitaForSE), y: -(-centerY + r * sin(xitaForSE)) },
    { x: centerX + r * cos(xitaForSW), y: -(-centerY + r * sin(xitaForSW)) },
  ];
}

/**
 * 获取缩放后四个顶点
 */
export function getScalePoints(points: IPosition[] | undefined, scale: number) {
  if (points) {
    return points.map((item) => {
      return {
        x: item.x * scale,
        y: item.y * scale,
      };
    });
  }
}

export function fixPointsWithMinimum(points: IPoint[], min: number) {
  const xArr = points.map((p) => p.x);
  const yArr = points.map((p) => p.y);
  const xDiff = Math.max(...xArr) - Math.min(...xArr);
  const yDiff = Math.max(...yArr) - Math.min(...yArr);
  if (xDiff < min) {
    const add = Math.ceil((min - xDiff) / 2);
    points[0].x -= add;
    points[3].x -= add;
    points[1].x += add;
    points[2].x += add;
  }
  if (yDiff < min) {
    const add = Math.ceil((min - yDiff) / 2);
    points[0].y -= add;
    points[1].y -= add;
    points[2].y += add;
    points[3].y += add;
  }
}

/**
 * 将给定的点，围绕中心点旋转rotate度
 * 返回旋转过后的四个顶点坐标
 * @param {IPoint[]} points
 * @param {IPoint} center
 * @param {number} rotate
 * @returns {IPoint[]}
 */
export function centerRotatePoints(points: IPoint[], center: IPoint, rotate: number): IPoint[] {
  if (rotate) {
    points = offsetPoints(points, { x: -center.x, y: -center.y });
    points = points.map((point) => rotatePoint(point, rotate));
    points = offsetPoints(points, { x: center.x, y: center.y });
  }
  return points;
}

export function getNWPoint(center: IPoint, size: ISize, rotate: number): IPoint {
  // 1.先转换切换成标准坐标系坐标 y = -y
  // 应用圆极坐标 x = a + r*cos(θ) y = b + r*sin(θ)
  // θ为该点与极轴的夹角，取逆时针
  // 最后将值变化回来 y = -y再来一次

  //西北角点本身是逆时针135度，顺时针旋转rotate度
  // 0/0 = NaN
  let radio = size.height / size.width;
  radio = Number.isNaN(radio) ? 0 : radio;
  const originNWXiTa = 180 - (Math.atan(radio) / Math.PI) * 180;
  const xita = ((originNWXiTa - rotate + 360) / 180) * Math.PI;
  const r = Math.sqrt(Math.pow(size.width / 2, 2) + Math.pow(size.height / 2, 2));
  return {
    x: center.x + r * Math.cos(xita),
    y: -(-center.y + r * Math.sin(xita)),
  };
}

/**
 * 根据给定的点，获取其未旋转时的矩形
 * @param {IPoint[]} points
 * @returns {IRect}
 */
export function getNoRotateRect(points: IPoint[]): IBounds {
  const newSize = getSizeByPoints(points);
  const centerPoint = getCenterPoint(points);
  return {
    ...newSize,
    left: centerPoint.x - newSize.width / 2,
    top: centerPoint.y - newSize.height / 2,
    right: centerPoint.x + newSize.width / 2,
    bottom: centerPoint.y + newSize.height / 2,
  };
}

/**
 * 旋转坐标
 * 公式：
 *   X = x*cos(θ) - y*sin(θ)
 *   Y = x*sin(θ) + y*cos(θ)
 * 参考：https://gamedev.stackexchange.com/questions/86755/how-to-calculate-corner-positions-marks-of-a-rotated-tilted-rectangle
 * RP 中不是相对原点旋转，所以要先偏移，再旋转，最后偏移回去
 * @param point 要旋转的点
 * @param originPoint 相对哪个点旋转
 * @param angle 旋转的角度（RP 中是顺时针旋转的，所以套用公式的时候需要取负数）
 */
export function rotateSinglePoint(point: IPoint, originPoint: IPoint, angle: number): IPoint {
  const tempX = point.x - originPoint.x;
  const tempY = point.y - originPoint.y;

  return {
    x:
      originPoint.x +
      Math.sqrt(tempY ** 2 + tempX ** 2) *
        Math.cos((((Math.atan2(-tempY, tempX) * 180) / Math.PI - angle) * Math.PI) / 180),
    y:
      originPoint.y -
      Math.sqrt(tempY ** 2 + tempX ** 2) *
        Math.sin((((Math.atan2(-tempY, tempX) * 180) / Math.PI - angle) * Math.PI) / 180),
  };
}

/**
 * 获取旋转后的矩形的 bounds
 */
export function getBoundsOfRotatedRectangle({
  position,
  size,
  rotate,
}: {
  position: IPosition;
  size: ISize;
  rotate: number;
}): IBounds {
  const points = [
    {
      x: position.x,
      y: position.y,
    },
    {
      x: position.x + size.width,
      y: position.y,
    },
    {
      x: position.x,
      y: position.y + size.height,
    },
    {
      x: position.x + size.width,
      y: position.y + size.height,
    },
  ];

  const rotateOriginPoint = {
    x: position.x + size.width / 2,
    y: position.y + size.height / 2,
  };
  const rotatedPoints = points.map((point) => rotateSinglePoint(point, rotateOriginPoint, rotate));
  let minX = Number.POSITIVE_INFINITY;
  let minY = Number.POSITIVE_INFINITY;
  let maxX = Number.NEGATIVE_INFINITY;
  let maxY = Number.NEGATIVE_INFINITY;
  rotatedPoints.forEach((point) => {
    minX = Math.min(minX, point.x);
    minY = Math.min(minY, point.y);
    maxX = Math.max(maxX, point.x);
    maxY = Math.max(maxY, point.y);
  });
  return {
    left: minX,
    top: minY,
    right: maxX,
    bottom: maxY,
    width: maxX - minX,
    height: maxY - minY,
  };
}

export function getCenter(nwPoint: IPosition, size: ISize, rotate?: number) {
  if (!rotate) {
    return {
      x: nwPoint.x + size.width / 2,
      y: nwPoint.y + size.height / 2,
    };
  }
  const nwPointInStandardCoordinates = {
    x: nwPoint.x,
    y: nwPoint.y,
  };
  const originNWXiTa = 180 - (Math.atan(size.height / size.width) / Math.PI) * 180;
  const xita = ((originNWXiTa - rotate + 360) / 180) * Math.PI;

  const r = Math.sqrt(Math.pow(size.width / 2, 2) + Math.pow(size.height / 2, 2));
  return {
    x: nwPointInStandardCoordinates.x - r * Math.cos(xita),
    y: -(-nwPointInStandardCoordinates.y - r * Math.sin(xita)),
  };
}

// export function degreesToRadians(degrees: number): number {
//   let newDegrees = degrees % 360;
//   newDegrees < 0 && (newDegrees += 360);
//   return newDegrees * (Math.PI / 180);
// }

/**
 * 计算点关于直线对称的对称点的坐标
 * @param originPoint 起点的坐标
 * @param line 直线方程:k代表斜率，b代表与X轴的交点,a代表与Y轴的交点
 */
export function getSymmetricPoint(
  originPoint: IPoint,
  line: {
    direction: ILineDirection;
    intersectionOnYAxes: number;
    intersectionOnXAxes: number;
  },
) {
  const { direction, intersectionOnYAxes, intersectionOnXAxes } = line;
  if (direction === 'vertical') {
    return {
      x: 2 * intersectionOnXAxes - originPoint.x,
      y: originPoint.y,
    };
  } else {
    return {
      x: originPoint.x,
      y: 2 * intersectionOnYAxes - originPoint.y,
    };
  }
}

/**
 * 计算两个点的中点
 * @param point1
 * @param point2
 */
export function getMidpointOfTwoPoints(point1: IPoint, point2: IPoint) {
  return {
    x: (point1.x + point2.x) / 2,
    y: (point1.y + point2.y) / 2,
  };
}

/**
 *
 * @param step
 * @param angle
 */
export function getAngleWhenShiftPress(step: number, angle: number) {
  let result = angle % 360;
  const remainder = angle % step;
  const adjustedRemain = remainder >= step / 2 ? step : 0;
  return result - remainder + adjustedRemain;
}
// 线条与水平向右的轴的夹角
export function getAngleOfLine(startPoint: IPoint, endPoint: IPoint): number {
  const { x: startX, y: startY } = startPoint;
  const { x: endX, y: endY } = endPoint;
  let result: number;
  const isVerticalLine = startX === endX;
  const isToTop = startY > endY;

  if (isVerticalLine) {
    if (isToTop) {
      result = 90;
    } else {
      result = 270;
    }
  } else {
    const k = (startY - endY) / (startX - endX);
    const plus = sameNumber(startX, 0) && sameNumber(startY, 0) ? 0 : 180;
    const angle = (Math.atan(k) * 180) / Math.PI;
    result = angle + plus;
  }
  return result;
}
