import * as React from 'react';

import { IItemProps } from '../../../models';
import Icon from '../../Icon';

import './SelectItem.scss';

interface ISelectListProps {
  item: IItemProps;

  onRemove(): void;
  onCheckDisable?(id: string): void;
}

const SelectItem: React.FC<ISelectListProps> = (props: ISelectListProps) => {
  const { item, onRemove, onCheckDisable } = props;
  const { name, imageURL } = item;

  return (
    <div className="select-item-box">
      <div className="select-avatar">{imageURL ? <img src={imageURL} /> : <Icon size={20} />}</div>
      <span className="name">{name}</span>
      <div className="item-remove">
        <Icon
          cls="demo_close"
          disableHover
          // @ts-ignore
          disabled={item.disabled}
          allowDisabledClick
          onClick={() => {
            // @ts-ignore
            if (item.disabled) {
              onCheckDisable && onCheckDisable(item.id);
              return;
            }
            onRemove();
          }}
        />
      </div>
    </div>
  );
};

export default SelectItem;
