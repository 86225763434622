import { InputModel } from '@fbs/rp/models/properties/inputModel';
import i18n from '@i18n';

export const InputModelArrays = [
  { id: InputModel.normal, text: i18n('property.component.inputModel.normal') },
  { id: InputModel.numeric, text: i18n('property.component.inputModel.numeric') },
  { id: InputModel.password, text: i18n('property.component.inputModel.password') },
];

export const TabDefaultValue = '  ';
