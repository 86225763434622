import * as React from 'react';
import classnames from 'classnames';

import { isCtrlKey } from '@/dsm/utils';
import { IProps } from './helper';

import { Icon } from '..';

import './index.scss';

interface ITreeItemProp<T> {
  props: IProps<T>;
  itemIndex: number;
  marked?: boolean;
  indent?: number;
  height?: number;
  nodeId?: string;
  disabled?: boolean;
  isClickFolderOnExpand?: boolean;
  draggable?: boolean;
  parentNode?: string;
  inDragging?: boolean;
  needUpdateWhenMouseEnterOrLeave?: boolean | ((item: T) => boolean); // 鼠标移入或移出时是否需要更新
  render: (data: T, collapse?: boolean) => React.ReactNode;
  getNodeLevel?(node: IProps<T>): number;
  onClick?: (e: React.MouseEvent, data: T) => void;
  onTreeItemClick?: (e: React.MouseEvent, data: T) => void;
  onDoubleClick?: (e: React.MouseEvent, data: T) => void;
  onItemExpandChanged?: (item: T, expand: boolean) => void;
  onItemExpandAllChanged?: (expand: boolean) => void;
  onDragStart?: (e: DragEvent, data: T) => void;
  onDrag?: (e: DragEvent) => void;
  onDragEnd?: (e: DragEvent, data: T) => void;
  onDrop?: (e: React.DragEvent, data: T) => void;
  onDragOver?: (e: React.DragEvent, data: IProps<T>, itemIndex: number) => void;
  onDragEnter?: (e: React.DragEvent, data: T) => void;
  onContextMenu?: (e: React.MouseEvent, data: IProps<T>) => void;
  onMouseEnter?: (e: React.MouseEvent, data: IProps<T>) => void;
  onMouseLeave?: (e: React.MouseEvent, data: IProps<T>) => void;
  onMouseUp?(e: React.MouseEvent, data: IProps<T>): void;
  onItemShowHighlightBorder?(data: IProps<T>): boolean;
}

export interface ITreeItemState {
  expand: boolean | undefined;
  updateTimes: number;
}

class VirtualTreeItem<T> extends React.Component<ITreeItemProp<T>, ITreeItemState> {
  selfRef: React.RefObject<HTMLDivElement>;
  treeItemRef: React.RefObject<HTMLDivElement>;

  constructor(props: ITreeItemProp<T>) {
    super(props);
    this.state = {
      expand: props.props.expand,
      updateTimes: 0,
    };
    this.selfRef = React.createRef();
    this.treeItemRef = React.createRef();
  }

  componentDidMount() {
    const treeItem = this.treeItemRef.current;
    if (treeItem) {
      treeItem.addEventListener('dragstart', this.onDragStart);
    }
  }

  componentWillUnmount() {
    const treeItemEle = this.treeItemRef.current;
    if (treeItemEle) {
      treeItemEle.removeEventListener('dragstart', this.onDragStart);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: ITreeItemProp<T>) {
    // 因为新的 hover 实现，hover 时会将 expand 设置为 true。
    // 内部展开状态和 item.expand 保持同步或只维护一个内部 state 即可。
    if (nextProps.props.expand !== this.state.expand || nextProps.props.expand !== this.props.props.expand) {
      this.setState({ expand: nextProps.props.expand }, () => {
        if (this.props.onItemExpandChanged) {
          this.props.onItemExpandChanged(this.props.props.data, !!this.state.expand);
        }
      });
    }
  }

  get clientBounds() {
    return this.selfRef.current?.getBoundingClientRect();
  }

  get data(): T {
    return this.props.props.data;
  }

  get expandItemCount(): number {
    const { props } = this.props;
    let count = 0;
    const doCalc = (nodes: IProps<T>[]) => {
      count += nodes.length;
      nodes.forEach((n) => {
        if (n.children) {
          doCalc(n.children);
        }
      });
    };
    if (props.children) {
      doCalc(props.children);
    }
    return count;
  }

  get needUpdateItem(): boolean {
    const { needUpdateWhenMouseEnterOrLeave, props } = this.props;
    return (
      (typeof needUpdateWhenMouseEnterOrLeave === 'function' && needUpdateWhenMouseEnterOrLeave(props.data)) ||
      !!needUpdateWhenMouseEnterOrLeave
    );
  }

  onClick = (e: React.MouseEvent) => {
    const { props, onClick } = this.props;
    onClick && onClick(e, props.data);
  };

  onDoubleClick = (e: React.MouseEvent) => {
    const {
      props: { data },
      onDoubleClick,
    } = this.props;
    onDoubleClick && onDoubleClick(e, data);
  };

  onDragStart = (e: DragEvent) => {
    e.stopPropagation();
    const { props, onDragStart, onDrag } = this.props;
    onDragStart && onDragStart(e, props.data);
    const treeItem = this.treeItemRef.current;
    if (treeItem) {
      onDrag && treeItem.addEventListener('drag', onDrag);
      treeItem.addEventListener('dragend', this.onDragEnd);
    }
  };

  onDragOver = (e: React.DragEvent) => {
    e.stopPropagation();
    const { props, onDragOver, itemIndex } = this.props;
    onDragOver && onDragOver(e, props, itemIndex);
  };

  onDragEnter = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const { props, onDragEnter } = this.props;
    onDragEnter && onDragEnter(e, props.data);
  };

  onDragEnd = (e: DragEvent) => {
    e.stopPropagation();
    const { props, onDragEnd, onDrag } = this.props;
    onDragEnd && onDragEnd(e, props.data);
    const treeItem = this.treeItemRef.current;
    if (treeItem) {
      onDrag && treeItem.removeEventListener('drag', onDrag);
      treeItem.removeEventListener('dragend', this.onDragEnd);
    }
  };

  onDrop = (e: React.DragEvent) => {
    e.stopPropagation();
    const { props, onDrop } = this.props;
    onDrop && onDrop(e, props.data);
  };

  onExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    //ctrl 全部收起或者全部展开
    if (isCtrlKey(e)) {
      if (this.props.onItemExpandAllChanged) {
        this.props.onItemExpandAllChanged(!this.state.expand);
      }
      return;
    }
    this.setState({ expand: !this.state.expand }, () => {
      if (this.props.onItemExpandChanged) {
        this.props.onItemExpandChanged(this.props.props.data, !!this.state.expand);
      }
    });
  };

  handleArrowDoubleClick(e: React.MouseEvent) {
    e.stopPropagation();
  }

  handleMouseEnter = (e: React.MouseEvent) => {
    const { onMouseEnter, props } = this.props;
    onMouseEnter && onMouseEnter(e, props);
    this.needUpdateItem && this.setState({ updateTimes: this.state.updateTimes + 1 });
  };

  handleMouseLeave = (e: React.MouseEvent) => {
    const { onMouseLeave, props } = this.props;
    onMouseLeave && onMouseLeave(e, props);
    this.needUpdateItem && this.setState({ updateTimes: this.state.updateTimes + 1 });
  };

  handleMouseUp = (e: React.MouseEvent) => {
    const { onMouseUp, props } = this.props;
    onMouseUp && onMouseUp(e, props);
  };

  onContextMenu = (e: React.MouseEvent) => {
    const { props, onContextMenu } = this.props;
    onContextMenu && onContextMenu(e, props);
  };

  private handleOpenFolder = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (this.props.isClickFolderOnExpand) {
      this.onExpand(e);
    }
  };

  private renderItem() {
    // 每行单独渲染的，需要考虑怎么重新渲染的问题；
    const { render, props, marked, indent, height, getNodeLevel, inDragging } = this.props;
    const { expand } = this.state;
    const level = getNodeLevel?.(props) || 0;

    const paddingLeft = (indent || 0) + level * 16;
    const style: React.CSSProperties = {
      paddingLeft: `${paddingLeft}px`,
      minWidth: `calc(100% - ${paddingLeft + 4}px)`, // 加上边框的高度
    };
    if (height) {
      style.height = height;
      style.lineHeight = `${height}px`;
    }

    return (
      <div
        className={classnames(
          'main-content main-content-flat',
          { selected: props.selected, marked },
          { 'remove-hover': inDragging },
        )}
        style={style}
        onContextMenu={this.onContextMenu}
        ref={this.selfRef}
        onDragEnter={this.onDragEnter}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.onClick}
        onDoubleClick={this.onDoubleClick}
      >
        <div
          className={classnames('button', { leaf: props.isLeaf, editing: props.editing })}
          onClick={this.onExpand}
          onDoubleClick={this.handleArrowDoubleClick}
        >
          <Icon
            className={classnames('arrow', 'arrow-flat-item', { expand: expand, collapse: !expand })}
            size={16}
            theme="tag"
            cls="tag_downarrow"
          />
        </div>
        <div className="title" onDrop={this.onDrop} onMouseUp={this.handleMouseUp}>
          {render(props.data, !expand)}
        </div>
      </div>
    );
  }

  render() {
    const { onItemShowHighlightBorder, props, draggable, parentNode } = this.props;
    return (
      <div
        className={classnames('tree-item', {
          'highlight-target-node-border': onItemShowHighlightBorder && onItemShowHighlightBorder(props),
        })}
        ref={this.treeItemRef}
        data-parent-id={parentNode}
        draggable={draggable}
        onDragOver={this.onDragOver}
        onClick={this.handleOpenFolder}
      >
        {this.renderItem()}
      </div>
    );
  }
}

export default VirtualTreeItem;
