import { AppTypes } from '@fbs/idoc/models/app';
import { AppShareLinkVisibility } from '@fbs/rp/models/share';
import { IAppPreviewLinkF } from '@fbs/idoc/models/appPreviewLink';
import { IAppShareLink, LinkType } from '@fbs/idoc/models/appShareLink';
import { get, patch, post, put } from './helper';

/**
 * 分享嵌入链接参数
 */
export interface IEmbedShareLinkParams {
  appID: string;
  module: string;
  linkType: LinkType;
  linkParams?: string;
  rpAppID?: string;
}
interface IProvisionalShareLink {
  shortID: string;
  visibility: AppShareLinkVisibility;
  onlyShareThisModel?: boolean;
}

/**
 * 首次获取项目演示链接
 */
export async function getShareRunLink(appID: string, moduleID: string): Promise<IAppPreviewLinkF> {
  return get<IAppPreviewLinkF>(`/app/preview/link/${appID}/${moduleID}`);
}

/**
 * 首次获取项目演示的页面
 */
export async function getPageInShareRunLink(
  linkID: string,
  shareID?: string,
  pageID?: string,
  previewNeedPassword?: boolean,
  moduleID?: string,
  iDocAppID?: string,
): Promise<{ nodes: string[]; shortLinkID?: string }> {
  const extraParams = moduleID && iDocAppID ? `/${moduleID}/${iDocAppID}` : '';

  if (!shareID) {
    return get<{ nodes: string[]; shortLinkID: string }>(`/app/preview/extraLinkInfo/${linkID}`);
  }

  return get<{ nodes: string[]; shortLinkID: string }>(
    `/app/preview/extraLinkInfo/${shareID}/${linkID}/${pageID}/${previewNeedPassword || false}${extraParams}`,
  );
}

/**
 * 设置项目演示的页面
 */
export async function setPageInShareRunLink(linkID: string, nodes: string[]): Promise<{ nodes: string[] }> {
  return put<{ nodes: string[] }>(`/app/preview/extraLinkInfo/${linkID}`, { nodes });
}

/**
 * 设置演示链接密码
 */
export async function setShareLinkPass(appID: string, moduleID: string, password: string): Promise<IAppPreviewLinkF> {
  return patch<IAppPreviewLinkF>(`/app/preview/link/${appID}/${moduleID}`, { password });
}

/**
 * 通过加密链接查看演示
 */
export async function getShareLinkPass(appID: string, password: string): Promise<IAppPreviewLinkF> {
  return post<IAppPreviewLinkF>(`/app/preview/link/${appID}`, { password });
}

/**
 * 启用/取消项目演示链接密码
 */
export async function changePwEnable(appID: string, enabled: boolean): Promise<IAppPreviewLinkF> {
  return patch<IAppPreviewLinkF>(`/app/preview/setting/${appID}`, { previewNeedPassword: enabled });
}

/* 
  重置分享链接
*/
export async function resetShareLink(
  appID: string,
  shareID?: string,
  pageID?: string,
  moduleID?: string,
  iDocAppID?: string,
) {
  const extraParams = moduleID && iDocAppID ? `/${moduleID}/${iDocAppID}` : '';
  // 短链接
  return patch(`/app/preview/resetLinkID/${appID}/${AppTypes.RP}/${shareID}/${pageID}${extraParams}`, {});
}

/**
 * 修改短 链接属性
 * @param params
 */
export async function setShortLinkProps(shortLinkID: string, url: string) {
  return post(`/app/preview/shortLink/${shortLinkID}`, { url });
}

export async function getShortLinkUrl(shortLinkID: string): Promise<string> {
  return get<string>(`/app/preview/shortLink/${shortLinkID}`);
}

/* 
  验证分享链接是否重置
*/
export async function checkShareLinkReset(shareID: string, linkID: string) {
  return post(`/app/preview/checkLinkID/${shareID}/${linkID}/${AppTypes.RP}`, {});
}

/**
 * 获取项目分享链接
 * @param {string} appID
 * @param {boolean} invite
 * @returns {Promise<IAppPreviewLinkF>}
 */
export async function getAppShareLink(appID: string, invite: boolean): Promise<IProvisionalShareLink> {
  return get(`/appShare/getShareLink/${appID}/rp?invite=${invite}`);
}

export async function getAppPublicShareLink(appID: string): Promise<IProvisionalShareLink> {
  return get(`/appShare/public/getShareLink/${appID}/rp`);
}

export async function resetAppPublicShareLink(appID: string): Promise<IProvisionalShareLink> {
  return patch(`/appShare/resetLink/${appID}/rp`, {});
}

export async function patchAppShareLink(
  appID: string,
  shortID: string,
  onlyShareThisModel: boolean,
): Promise<IProvisionalShareLink> {
  return patch(`/appShare/patch/${appID}/${shortID}`, { onlyShareThisModel });
}

/**
 * 获取嵌入演示链接
 * @param appID
 * @param module
 * @param linkParams
 */
export function getEmbedShareLink(params: IEmbedShareLinkParams) {
  const { appID, module, linkType, linkParams } = params;
  return put<IAppShareLink>(`/appShareLink/getEmbedShareLink/${appID}/${module}`, {
    linkType,
    linkParams,
  });
}

/**
 *重置嵌入演示链接
 * @param appID
 * @param module
 * @param linkParams
 */
export function resetEmbedShareLink(params: IEmbedShareLinkParams) {
  const { appID, module, linkType, linkParams } = params;
  return put<IAppShareLink>(`/appShareLink/resetEmbedShareLink/${appID}/${module}`, {
    linkType,
    linkParams,
  });
}
