import { getCenterPoint, offsetPoints } from '@utils/rotateUtils';
import { IBounds, IPoint, ISize } from '@fbs/common/models/common';
import { centerRotatePoints, getRectPoints } from '@helpers/rotateHelper';
import AnchoredComponent from './AnchoredComponent';

/**
 * 和组件旋转相关的逻辑集中在这里
 * 计算思路：将点偏移到旋转中心，使用三角函数计算旋转后的点坐标，再偏移回原位
 */
export default abstract class RotatedComponent extends AnchoredComponent {
  // 获取组件相对父容器的四个顶点坐标（考虑旋转）
  getBoxPointsInParent(size?: ISize): IPoint[] {
    let points = getRectPoints({ x: 0, y: 0 }, size || this.size);

    points = this.rotatePointsInBox(points);

    const position = this.position;
    return offsetPoints(points, { x: position.x, y: position.y });
  }

  // 获取组件相对画板的四个顶点坐标（考虑旋转）
  getBoxPointsInArtboard(ignoreArtboardPosition: boolean = true): IPoint[] {
    let points = getRectPoints({ x: 0, y: 0 }, this.size);
    points = (this as RotatedComponent).rotatePoints(points, ignoreArtboardPosition);
    // todo 加一个校验，确保4个点是一个矩形
    return points;
  }

  // 以组件中心为原点，旋转组件内的点
  rotatePointsInBox(childPoints: IPoint[]): IPoint[] {
    let points = childPoints;
    const rotate = this.rotate;
    if (rotate) {
      const size = this.size;
      const centerPoint = { x: size.width / 2, y: size.height / 2 };
      points = centerRotatePoints(childPoints, centerPoint, rotate);
    }

    return points;
  }

  // 相对于画板的点转化为相对该组件的点
  // 主要用于拖拽、resize后计算组件偏移量
  recoveryRotatedPoints(rotatedPoints: IPoint[]): IPoint[] {
    const rotateChain: RotatedComponent[] = [];
    let com: RotatedComponent | undefined = this;
    while (com) {
      rotateChain.push(com);
      com = com.parent as RotatedComponent;
    }

    let points = rotatedPoints;
    com = rotateChain.pop();
    while (com) {
      const size = com.size;
      const position = com.position;
      const centerPoint = {
        x: position.x + size.width / 2,
        y: position.y + size.height / 2,
      };

      if (com.rotate) {
        points = centerRotatePoints(points, centerPoint, -com.rotate);
      }

      points = offsetPoints(points, { x: -position.x, y: -position.y });
      com = rotateChain.pop();
    }

    return points;
  }

  // 以组件中心点为原点旋转组件内的点
  // 递归向上应用父级的旋转
  rotatePoints(childPoints: IPoint[], ignoreArtboardPosition: boolean = false): IPoint[] {
    let points = this.rotatePointsInBox(childPoints);

    // 忽略画板本身的坐标
    if (this.data.type === 'artboard' && ignoreArtboardPosition) {
      return points;
    }
    const position = this.position;
    points = offsetPoints(points, { x: position.x, y: position.y });

    if (this.parent) {
      points = (this.parent as RotatedComponent).rotatePoints(points, ignoreArtboardPosition);
    }

    return points;
  }

  // 根据相对画板的组件顶点，计算出组件相对画板的旋转角度
  getRotateAngleInRoot(): number {
    const points = this.getBoxPointsInArtboard(false);

    // 1、计算出中心点的坐标
    const centerPoint = getCenterPoint(points);

    // 2、计算新的旋转点
    const newRotatePoint = {
      x: (points[1].x + points[2].x) / 2,
      y: (points[1].y + points[2].y) / 2,
    };

    // 3、计算旋转角度
    const xrad = Math.atan2(newRotatePoint.y - centerPoint.y, newRotatePoint.x - centerPoint.x);
    return ((xrad / Math.PI) * 180) % 360;
  }

  // 获取相对于画板未旋转的组件区域
  getRotateCenterBounds(): IBounds {
    const points = this.getBoxPointsInArtboard(false);
    const centerPoint = getCenterPoint(points);
    const size = this.size;
    const left = centerPoint.x - size.width / 2;
    const top = centerPoint.y - size.height / 2;
    return {
      left,
      top,
      width: size.width,
      height: size.height,
      right: left + size.width,
      bottom: top + size.height,
    };
  }

  /**根据旋转过程中的信息，得到未旋转时的position
   *
   * @param currentNWPoint 当前西北角顶点的位置
   * @param currentRotateAngle 当前旋转的角度
   * @param size 当前组件的大小
   */
  getOriginPositionAccordingRotatingInfo = (currentNWPoint: IPoint, currentRotateAngle: number, size: ISize) => {
    // 我们已知新组的左上顶点坐标，新组旋转角度，新组的size,height,要求中心坐标（a,b）
    // 旋转的圆极坐标方程为 x = a + r*cos(θ)；y = b + r*sin(θ)
    // 注意极角是逆时针的
    // 初始未旋转时左上顶点与极轴的夹角为α = 180-actan(height/weight)
    // 旋转后左上顶点与极昼的夹角为β = α - 旋转角度 （顺时针转所以减）
    // x顶 = a + r*cos(β) ； y顶 = b + r*sin(β) 所以可求出a b
    // 那么算出a b 之后，未旋转时的坐标就是把θ换成α带入计算就得到

    const radius = Math.sqrt(Math.pow(size.height / 2, 2) + Math.pow(size.width / 2, 2));
    const initialAngleOfNW = Math.PI - Math.atan(size.height / size.width);
    const angleOfNWAfterRotate = initialAngleOfNW - (currentRotateAngle / 180) * Math.PI;
    const centerX = currentNWPoint.x - radius * Math.cos(angleOfNWAfterRotate);
    // 这里的负号是因为极坐标方程对应的是标准直角坐标系，我们的坐标系是关于标准直角坐标系X轴的对称，所以y' = -y
    const centerY = -currentNWPoint.y - radius * Math.sin(angleOfNWAfterRotate);
    const originXOfNWPoint = centerX + radius * Math.cos(initialAngleOfNW);
    const originYofNWPoint = -(centerY + radius * Math.sin(initialAngleOfNW));
    return {
      x: originXOfNWPoint,
      y: originYofNWPoint,
    };
  };
}
