import { cloneDeep, isString, isEqual } from 'lodash';
import { PureColor } from '@/fbs/rp/models/properties/color';
import { IChartValue, IChartData } from '@fbs/rp/models/chart';
import { EChartType_basic } from '@/fbs/rp/models/properties/chart';

import { ISelectArea } from '@helpers/tableHelper';
import i18n from '@/i18n';
import { ChartAxisNameConfig } from '../../../ChartComponents/common';

function getDefaultRow(columnCount: number): IChartData {
  return {
    name: i18n('resource.componentsText.yAxisText', 0),
    data: new Array(columnCount).fill(0),
  };
}

function setValue(chartValue: IChartValue, row: number, column: number, value: string | number | undefined): boolean {
  const { xAxis, dataSource } = chartValue;

  if (row === 0) {
    if (xAxis.length < column || !column || xAxis[column - 1] === value) {
      return false;
    }
    xAxis[column - 1] = (value ?? '').toString();
    return true;
  }

  if (dataSource.length < row) {
    return false;
  }

  if (column === 0) {
    if (dataSource[row - 1].name === value) {
      return false;
    }
    dataSource[row - 1].name = (value ?? '').toString();
    return true;
  }

  if (dataSource[row - 1].data[column - 1] === value || isString(value)) {
    return false;
  }
  dataSource[row - 1].data[column - 1] = value;
  return true;
}

export function getAxisName(type: string, index: number, isXAxis: boolean): string {
  const names = ChartAxisNameConfig[type];
  if (!names) {
    return i18n('resource.componentsText.xAxisText', index);
  }
  return i18n(isXAxis ? names.x : names.y, index);
}

/**
 * 获取名字不重复
 */
function createNewAxisName(
  type: string,
  names: Array<string | undefined>,
  isXAxis: boolean,
  startIndex: number,
  count = 1,
): string[] {
  const map = new Set(names);
  let curSize = map.size;
  let i = startIndex;

  const result: string[] = [];
  while (result.length < count) {
    const newName = getAxisName(type, ++i, isXAxis);

    map.add(newName);
    if (map.size !== curSize) {
      curSize = map.size;
      result.push(newName);
    }
  }

  return result;
}

export namespace ChartUtils {
  // 修改某一项value
  export const changeValue = (
    chartValue: IChartValue,
    row: number,
    column: number,
    value: string | number | undefined,
  ): IChartValue | undefined => {
    const copy = cloneDeep(chartValue);
    const successful = setValue(copy, row, column, value);

    return successful ? copy : undefined;
  };

  // 修改某一项颜色
  export const changeColor = (chartValue: IChartValue, row: number, color: PureColor): IChartValue | undefined => {
    const copy = cloneDeep(chartValue);
    const { dataSource } = copy;

    if (row < 1 || row > dataSource.length) {
      return;
    }
    if (isEqual(dataSource[row - 1].color, color)) {
      return;
    }

    dataSource[row - 1].color = { ...color };
    return copy;
  };

  // 插入行
  export const insertRows = (
    type: string,
    chartValue: IChartValue,
    index: number,
    count = 1,
    dataType?: EChartType_basic.Bar | EChartType_basic.Line,
  ): IChartValue | undefined => {
    if (count < 1) {
      return;
    }

    const copy = cloneDeep(chartValue);
    const { dataSource, xAxis } = copy;

    let i = index;
    if (i < 1 || i > dataSource.length + 1) {
      i = dataSource.length + 1;
    }

    let item = i === 1 ? dataSource[0] : dataSource[i - 2];
    if (!item) {
      item = getDefaultRow(xAxis.length);
    }

    const newNames = createNewAxisName(
      type,
      dataSource.map((item) => item.name),
      false,
      i - 1,
      count,
    );

    const add = new Array(count).fill(0).map((v, j) => {
      const newItem = cloneDeep(item);
      // 组合图表新增行，维持添加行数据与当前行数据type值一致
      if (dataType) {
        item.type = dataType;
      }
      delete newItem.color;
      newItem.name = newNames[j];
      return newItem;
    });

    dataSource.splice(i - 1, 0, ...add);
    return copy;
  };

  // 删除行
  export const removeRows = (chartValue: IChartValue, index: number, count = 1): IChartValue | undefined => {
    const copy = cloneDeep(chartValue);
    const { dataSource } = copy;

    let _count = count;
    let _index = index;
    if (index < 1) {
      _count += _index - 1;
      _index = 1;
    }
    if (_index < 1 || _index > dataSource.length + 1 || _count < 1) {
      return;
    }

    dataSource.splice(_index - 1, _count);
    return copy;
  };

  // 插入列
  export const insertColumns = (
    type: string,
    chartValue: IChartValue,
    index: number,
    count = 1,
  ): IChartValue | undefined => {
    if (count < 1) {
      return;
    }

    const copy = cloneDeep(chartValue);
    const { dataSource, xAxis } = copy;

    let i = index;
    if (index < 1 || index > xAxis.length + 1) {
      i = xAxis.length + 1;
    }

    const itemIndex = i === 1 ? 0 : i - 2;
    const newNames = createNewAxisName(type, xAxis, true, itemIndex, count);
    xAxis.splice(
      i - 1,
      0,
      ...new Array(count).fill(0).map((v, j) => {
        return newNames[j];
      }),
    );
    dataSource.forEach((item) => {
      const num = item.data[itemIndex] ?? 0;
      item.data.splice(i - 1, 0, ...new Array(count).fill(num));
    });

    return copy;
  };

  // 删除列
  export const removeColumns = (chartValue: IChartValue, index: number, count = 1): IChartValue | undefined => {
    const copy = cloneDeep(chartValue);
    const { dataSource, xAxis } = copy;

    let _count = count;
    let _index = index;
    if (index < 1) {
      _count += _index - 1;
      _index = 1;
    }
    if (_index < 1 || _index > xAxis.length + 1 || _count < 1) {
      return;
    }

    xAxis.splice(_index - 1, _count);
    dataSource.forEach((item) => {
      item.data.splice(_index - 1, _count);
    });

    return copy;
  };

  // 区域清除
  export const clearContent = (chartValue: IChartValue, area: ISelectArea): IChartValue | undefined => {
    const copy = cloneDeep(chartValue);
    const { start, end } = area;

    let flag = false;

    for (let r = start.row; r <= end.row; r++) {
      for (let c = start.column; c <= end.column; c++) {
        const successful = setValue(copy, r, c, undefined);
        successful && (flag = true);
      }
    }

    return flag ? copy : undefined;
  };
}
