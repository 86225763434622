import { ThunkDispatch } from 'redux-thunk';

import { IUserInfo } from '@fbs/idoc/models/user';
import { ITeams } from '@fbs/idoc/models/team';

import * as userApi from '@apis/user';
import * as teamApi from '@apis/team';

import i18n from '@/i18n';

import { ActionsUnion, ActionType, createAction, ToastType } from '../types';
import { GlobalThunkActions } from '../desktopActions';
import apis from '@/apis';

export const Actions = {
  login: (userInfo?: IUserInfo) => createAction(ActionType.User_Login, userInfo),
  logout: () => createAction(ActionType.User_Logout),
  loadAllTeams: (teams: ITeams[]) => createAction(ActionType.Team_AllTeams, teams),
  switchTeam: (team: ITeams) => createAction(ActionType.Team_SwitchTeam, team),
  createTeam: (team: ITeams) => createAction(ActionType.Team_Create, team),
  patchToken: (token: string) => createAction(ActionType.User_Patch_Token, token),
  patchTeam: (team: Partial<ITeams>) => createAction(ActionType.Team_Patch, team),
  patchUserInfo: (user: Partial<IUserInfo>) => createAction(ActionType.User_Patch_Info, user),
};

type CustomThunkDispatch = ThunkDispatch<any, null, any>;

export const ThunkActions = {
  /**
   * 检查当前用户是否登录失效
   */
  checkLoginValid: () => async (dispatch: CustomThunkDispatch) => {
    apis.user.getUserInfo().catch((e) => {
      GlobalThunkActions.thunkErrorMessage(e)(dispatch);
    });
  },

  /**
   * 检查登录状态
   */
  checkLoginState: (token?: string, fn?: (user?: IUserInfo) => void) => async (dispatch: CustomThunkDispatch) => {
    token && userApi.sigin(token);
    userApi
      .getUserInfo()
      .then((user) => {
        fn && fn(user);
        dispatch(Actions.login(user));
      })
      .catch(() => {
        fn && fn();
        dispatch(Actions.logout());
      });
  },

  logout: (fn?: Function) => async (dispatch: CustomThunkDispatch) => {
    userApi
      .logout()
      .then(() => {
        fn && fn();
        userApi.clearTokenWithSignout();
        dispatch(Actions.logout());
      })
      .catch((e) => dispatch(GlobalThunkActions.thunkError(e.message)));
  },

  desktopLogout: () => async (dispatch: CustomThunkDispatch) => {
    dispatch(Actions.logout());
  },

  /**
   * 注册
   */
  register: (
    email: string,
    password: string,
    vocation: string,
    fn?: (user?: IUserInfo, token?: string) => void,
  ) => async (dispatch: CustomThunkDispatch) => {
    const name = email.split('@')[0]?.replace(/[^\w]/g, '') || 'username';
    userApi
      .register({
        email,
        password,
        profession: vocation,
        name,
        source: 'rp',
      })
      .then(() => {
        dispatch(ThunkActions.signWithEmailAndPassword(email, password, fn));
      })
      .catch((e) => {
        dispatch(GlobalThunkActions.thunkError(e.message));
        fn && fn();
      });
  },

  /**
   * 载入团队信息
   */
  signWithEmailAndPassword: (
    email: string,
    password: string,
    fn?: (user?: IUserInfo, token?: string) => void,
  ) => async (dispatch: CustomThunkDispatch) => {
    userApi
      .signWithUserAndPassword({ email, password })
      .then((res) => {
        userApi.sigin(res.token);
        fn && fn(res.user, res.token);
        dispatch(Actions.patchToken(res.token));
        dispatch(Actions.login(res.user));
      })
      .catch((e) => {
        dispatch(GlobalThunkActions.thunkError(e.message));
        fn && fn();
      });
  },

  getWechatSignURL: () => async (dispatch: CustomThunkDispatch) => {
    console.log('微信登录?', dispatch);
  },

  checkWechatSignState: () => async (dispatch: CustomThunkDispatch) => {
    console.log('验证？', dispatch);
  },

  signWithLark: () => async (dispatch: CustomThunkDispatch) => {
    console.log('飞书登录？', dispatch);
  },

  /**
   * 加载全部团队
   */
  loadAllTeams: (currentTeamID: string, fn?: (team: ITeams) => void, newTeamName?: string) => async (
    dispatch: CustomThunkDispatch,
  ) => {
    teamApi
      .getAllTeams()
      .then((teams) => {
        if (teams.length === 0) {
          ThunkActions.createTeam(newTeamName || '', fn)(dispatch);
          return;
        }
        dispatch(Actions.loadAllTeams(teams));
        const currentTeam = teams.find((item) => item.id === currentTeamID);
        const firstTeam = teams[0];
        const team = currentTeam || firstTeam;
        ThunkActions.loadTeamInfo(team.id, fn)(dispatch);
      })
      .catch((e) => {
        dispatch(GlobalThunkActions.thunkError(e.message));
      });
  },

  /**
   * 加载团队信息
   */
  loadTeamInfo: (teamID: string, fn?: (team: ITeams) => void) => async (dispatch: CustomThunkDispatch) => {
    teamApi
      .getTeamInfo(teamID)
      .then((team: ITeams) => {
        fn && fn(team);
        dispatch(Actions.switchTeam(team));
      })
      .catch((e) => {
        dispatch(GlobalThunkActions.thunkError(e.message));
      });
  },

  /**
   * 切换团队
   */
  switchTeam: (team: ITeams) => async (dispatch: CustomThunkDispatch) => {
    // apis.team
    // .changeTeam(team.id)
    // .then(() => {
    dispatch(Actions.switchTeam(team));
    // })
    // .catch((e) => dispatch(GlobalThunkActions.thunkError(e.message)));
  },

  createTeam: (name: string, fn?: (team: ITeams) => void) => async (dispatch: CustomThunkDispatch) => {
    try {
      const { id } = await teamApi.createTeam(name);
      const res = await teamApi.getTeamInfo(id);
      fn?.(res);
      dispatch(Actions.createTeam(res));
    } catch (err) {
      dispatch(GlobalThunkActions.thunkError(err.message));
    }
  },
  patchTeam: (team: Partial<ITeams>) => (dispatch: CustomThunkDispatch) => {
    dispatch(Actions.patchTeam(team));
  },
  // 撤回团队注销
  recallCancellation: () => async (dispatch: CustomThunkDispatch) => {
    userApi
      .cancelUnsubscribe()
      .then(() => {
        GlobalThunkActions.showToast(ToastType.Success, i18n('message.recallSuccess'))(dispatch);
        dispatch(Actions.patchUserInfo({ unsubscribeTime: 0 }));
      })
      .catch((e) => {
        GlobalThunkActions.thunkErrorMessage(e, true)(dispatch);
      });
  },
};

export type UserActions = ActionsUnion<typeof Actions>;
