import { RPFreeProgramProgressNode, IRPFreeProgressNode } from '../models/rpFreeProgram';
import i18n from '../i18n';

export const rpFreeProgressList: IRPFreeProgressNode[] = [
  {
    id: RPFreeProgramProgressNode.Initial,
    text: i18n('rpActivity.oneYear'),
    active: true,
    count: 0,
    value: i18n('rpActivity.firstPrice'),
  },
  {
    id: RPFreeProgramProgressNode.First,
    text: i18n('rpActivity.addThreeMonth'),
    active: false,
    count: 1,
    value: i18n('rpActivity.secondPrice'),
  },
  {
    id: RPFreeProgramProgressNode.Second,
    text: i18n('rpActivity.addThreeMonth'),
    active: false,
    count: 2,
    value: i18n('rpActivity.secondPrice'),
  },
  {
    id: RPFreeProgramProgressNode.Third,
    text: i18n('rpActivity.addThreeMonth'),
    active: false,
    count: 3,
    value: i18n('rpActivity.secondPrice'),
  },
  {
    id: RPFreeProgramProgressNode.Fourth,
    text: i18n('rpActivity.addThreeMonth'),
    active: false,
    count: 4,
    value: i18n('rpActivity.secondPrice'),
  },
  {
    id: RPFreeProgramProgressNode.Last,
    text: i18n('rpActivity.forever'),
    active: false,
    count: 5,
    value: i18n('rpActivity.thirdPrice'),
  },
];

export const rpFreeProgressInviteCount: number = 5;

export const rpFreeDeadline: Date = new Date('2021-12-31');

const QR_XY = 'https://img02.mockplus.cn/image/qr-codes/1.png';
const QR_XT = 'https://img02.mockplus.cn/image/qr-codes/2.png';
const QR_TWX = 'https://img02.mockplus.cn/image/qr-codes/3.png';

export const CustomerServiceQRList = [QR_XT, QR_XY, QR_TWX];
