import * as React from 'react';
import './index.scss';

export type ProgressProp = {
  width: number | string;
  percent: number;
  showTips: boolean;
};

function Progress({ width, percent, showTips }: ProgressProp) {
  return (
    <div
      className="dsm-c-progress"
      style={{
        width,
      }}
    >
      <div className="track">
        <div className="progress" style={{ width: `${percent}%` }} />
      </div>
      {showTips && (
        <i className="tips" style={{ left: `${percent}%` }}>
          {percent}%
        </i>
      )}
    </div>
  );
}

Progress.defaultProps = {
  showTips: true,
  width: 400,
};

export default Progress;
