import * as React from 'react';
import classnames from 'classnames';

import { convertEventToHotKey } from '@utils/hotkeysUtils';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';

import PopupMenu, { IMenuItem } from '../PopupMenu';
import Input from '../Input';
import Icon from '../Icon';

import './index.scss';

export interface ISearchBoxProp {
  placeHolder?: string;
  autoFocus?: boolean;
  allowClear?: boolean;
  clearBox?: boolean;
  theme?: 'dark' | 'light' | 'border';
  quickFocusKey?: string;
  defaultValue?: string;
  presetMenuItems?: IMenuItem[];
  height?: number;
  width?: number;
  onChange: (value: string) => void;
  onClear?: () => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  onBlur?: (value: string) => void;
  onMouseDown?: React.MouseEventHandler;
  onMouseUp?: React.MouseEventHandler;
  onFocus?: React.FocusEventHandler;
  onMenuSelected?: (item: IMenuItem) => void;
}

export interface ISearchBoxState {
  searchText: string;
  focus?: boolean;
  popup?: boolean;
  popupPosition: { x: number; y: number };
}

class SearchBox extends React.Component<ISearchBoxProp, ISearchBoxState> {
  static defaultProps: Partial<ISearchBoxProp> = {
    allowClear: true,
  };

  private inputTimeID?: Timeout;
  private input: React.RefObject<Input> = React.createRef();

  constructor(props: ISearchBoxProp) {
    super(props);
    this.state = {
      searchText: props.defaultValue || '',
      popupPosition: { x: 0, y: 0 },
    };
  }

  componentDidMount() {
    if (this.props.quickFocusKey) {
      window.addEventListener('keydown', this.handleWindowKeyDown, { capture: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: ISearchBoxProp) {
    if (nextProps.clearBox) {
      this.setState({
        searchText: '',
      });
    }
  }

  componentWillUnmount() {
    window.addEventListener('keydown', this.handleWindowKeyDown, { capture: false });
    if (this.inputTimeID) {
      clearTimeout(this.inputTimeID);
    }
  }

  handleWindowKeyDown = (e: KeyboardEvent) => {
    const dom = e.target as HTMLElement;
    if (dom.tagName === 'INPUT' || dom.tagName === 'TEXTAREA' || dom.isContentEditable) {
      return;
    }
    if (this.props.quickFocusKey) {
      const hotKey = convertEventToHotKey(e);
      if (hotKey === this.props.quickFocusKey.toLowerCase()) {
        this.inputTimeID = window.setTimeout(() => {
          this.input.current?.focus();
          this.inputTimeID = undefined;
        }, 100);
      }
    }
  };

  handleChange = (value: string) => {
    const { onChange } = this.props;
    if (this.inputTimeID) {
      clearTimeout(this.inputTimeID);
    }
    this.input.current?.focus();
    this.disabledFocus = false;
    this.setState({ searchText: value }, () => {
      this.inputTimeID = window.setTimeout(() => {
        if (onChange) {
          onChange(value);
        }
      }, 100);
    });
  };

  handleFocus = (e: React.FocusEvent) => {
    this.setState({ focus: true });
    const { onFocus } = this.props;
    onFocus && onFocus(e);
  };

  private disabledFocus?: boolean;

  handleBlur = (value: string) => {
    if (this.disabledFocus) {
      return;
    }
    this.setState({ focus: false });
    if (this.props.onBlur) {
      this.props.onBlur(value);
    }
  };

  handleCloseMouseDown = () => {
    this.disabledFocus = true;
  };

  handleClear = () => {
    const { onClear } = this.props;
    if (onClear) {
      onClear();
    }
    // else {
    this.handleChange('');
    // }
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === KeyCodeMap.VK_ESCAPE) {
      (e.target as HTMLElement).blur();
      this.handleChange('');
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  };

  focus = () => {
    this.input.current!.focus();
  };

  handleTriggerPopup = (e: React.MouseEvent) => {
    if (!this.state.popup) {
      const { left: x, bottom: y } = (e.currentTarget as HTMLElement).getBoundingClientRect();
      this.setState({ popupPosition: { x, y }, popup: true });
    }
  };

  handleMenuClose = () => {
    this.setState({ popup: false });
  };

  handleMenuItemClick = (item: IMenuItem) => {
    this.setState({ popup: false });
    this.props.onMenuSelected && this.props.onMenuSelected(item);
  };

  renderPopupMenu() {
    if (!this.state.popup || !this.props.presetMenuItems) {
      return null;
    }
    return (
      <PopupMenu
        useCheck
        items={this.props.presetMenuItems}
        position={this.state.popupPosition}
        onClose={this.handleMenuClose}
        onItemClick={this.handleMenuItemClick}
      />
    );
  }

  private hasCheck = (): boolean => {
    const { presetMenuItems } = this.props;
    if (presetMenuItems) {
      return presetMenuItems.some((ite) => ite.checked);
    }
    return false;
  };

  renderPresetItem() {
    if (!this.props.presetMenuItems) {
      return null;
    }
    const checkList = this.props.presetMenuItems.filter((item) => item.checked).map((item) => item.text);
    return this.hasCheck() ? (
      <div className="search-box-preset-selected">
        {checkList.map((text) => (
          <span key={text as string}>{text}</span>
        ))}
      </div>
    ) : null;
  }

  render() {
    const {
      autoFocus,
      placeHolder,
      allowClear,
      width,
      height,
      onMouseDown,
      onMouseUp,
      theme,
      presetMenuItems,
    } = this.props;
    const { searchText, focus } = this.state;
    const hasCheck = this.hasCheck();
    return (
      <div
        className={classnames('dsm-c-rp-search-box', theme || '', { focus })}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        style={{
          height,
          width,
          lineHeight: height,
        }}
      >
        <Icon
          cls="search_normal"
          theme="tag"
          className={classnames('search-box-icon', { 'show-menu': presetMenuItems && presetMenuItems.length !== 0 })}
          onMouseDown={this.handleTriggerPopup}
        />
        {this.renderPresetItem()}
        <Input
          ref={this.input}
          them="no-border"
          autoFocus={autoFocus}
          placeHolder={placeHolder}
          className="search-input"
          onChange={this.handleChange}
          value={searchText}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
        />
        {allowClear && (searchText || hasCheck) && (
          <Icon
            className="clear-button"
            cls="close"
            onMouseDown={this.handleCloseMouseDown}
            onClick={this.handleClear}
          />
        )}
        {this.renderPopupMenu()}
      </div>
    );
  }
}

export default SearchBox;
