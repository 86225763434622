import * as _ from 'lodash';
import { applyOperation, compare } from 'fast-json-patch';

import { depthClone, getDataType, DataType } from '@utils/globalUtils';

import { IComponentData } from '@/fbs/rp/models/component';
import {
  ArtboardPatches,
  ComponentPatches,
  Ops,
  ComponentOperations,
  PageOperations,
  ArtboardOperations,
  Operation,
  ReplaceOperation,
  PagePatches,
} from '@fbs/rp/utils/patch';
import { UIComponent } from '@editor/comps';
import { getLibDefaultData } from '@/libs/libs';

export function mergeArtboardOperations(
  artboardOperation: ArtboardOperations,
  newArtboardOperation: ArtboardOperations,
) {
  const compIds = new Set(Object.keys(artboardOperation));
  const newCompIds = new Set(Object.keys(newArtboardOperation));
  newCompIds.forEach((id) => {
    if (compIds.has(id)) {
      artboardOperation[id] = [...artboardOperation[id], ...newArtboardOperation[id]];
    } else {
      artboardOperation[id] = [...newArtboardOperation[id]];
    }
  });
  return artboardOperation;
}

export function mergePageOperations(pageOperation: PageOperations, newPageOperation: PageOperations) {
  const artboardIds = new Set(Object.keys(pageOperation));
  const newArtboardIds = new Set(Object.keys(newPageOperation));
  newArtboardIds.forEach((id) => {
    if (artboardIds.has(id)) {
      mergeArtboardOperations(pageOperation[id], newPageOperation[id]);
    } else {
      pageOperation[id] = { ...newPageOperation[id] };
    }
  });
  return pageOperation;
}

// 将一个组件的操作转为页面的更新
// 注意仅适用于单个组件的修改导致页面更新的情况
export function convertUIOperationToPagePatches(comp: UIComponent, ops: ComponentPatches): PagePatches {
  return {
    [comp.ownerArtboardID]: {
      do: {
        [comp.id]: ops.do,
      },
      undo: {
        [comp.id]: ops.undo,
      },
    },
  };
}

export function coverPatches(patches: ArtboardPatches, newPatches: ArtboardPatches): ArtboardPatches {
  //在newPatches中的id如果能在patches中找到的话，patches.do[key] =
  // 在newPatches中的id如果能在patches中找不到的话 patches.do[key] = []
  for (let CompIdInNewPatches in newPatches.do) {
    patches.do[CompIdInNewPatches] = patches.do[CompIdInNewPatches]
      ? patches.do[CompIdInNewPatches].filter((item) => {
          return !newPatches.do[CompIdInNewPatches].some((cur) => {
            return cur.path === item.path;
          });
        })
      : [];
    patches.undo[CompIdInNewPatches] = patches.undo[CompIdInNewPatches]
      ? patches.undo[CompIdInNewPatches].filter((item) => {
          return !newPatches.undo[CompIdInNewPatches].some((cur) => {
            return cur.path === item.path;
          });
        })
      : [];
    patches.do[CompIdInNewPatches].push(...newPatches.do[CompIdInNewPatches]);
    patches.undo[CompIdInNewPatches].push(...newPatches.undo[CompIdInNewPatches]);
  }
  return patches;
}

/**
 * 如果两个patches里面关于某个id里面的数据修改有冲突，那么优先使用新的patches中的修改
 * @param oldPatches
 * @param newPatches
 */
export function assignPatches(oldPatches: ArtboardPatches, newPatches: ArtboardPatches) {
  const { do: oldDo, undo: oldUnDo } = oldPatches;
  const { do: newDo, undo: newUnDo } = newPatches;
  return depthClone({
    do: { ...oldDo, ...newDo },
    undo: { ...oldUnDo, ...newUnDo },
  });
}

export function mergePatches(patches: ArtboardPatches, newPatches: ArtboardPatches): ArtboardPatches {
  for (let key in newPatches.do) {
    patches.do[key] = patches.do[key]
      ? patches.do[key].filter((item) => {
          return !newPatches.do[key].some((cur) => {
            if (item.op === 'remove-children' && cur.op === 'remove-children') {
              cur.value = [...item.value, ...cur.value];
            }

            if (item.op === 'add-children' && cur.op === 'add-children' && item.path === cur.path) {
              cur.value = [...item.value, ...cur.value];
            }
            return cur.path === item.path && cur.op === item.op;
          });
        })
      : [];
    patches.do[key].push(...newPatches.do[key]);

    patches.undo[key] = patches.undo[key]
      ? patches.undo[key].filter((item) => {
          return !newPatches.undo[key].some((cur) => {
            if (item.op === 'remove-children' && cur.op === 'remove-children') {
              cur.value = [...item.value, ...cur.value];
            }
            if (item.op === 'add-children' && cur.op === 'add-children' && item.path === cur.path) {
              cur.value = [...item.value, ...cur.value];
            }
            return cur.path === item.path && cur.op !== item.op;
          });
        })
      : [];
    patches.undo[key].push(...newPatches.undo[key]);
  }
  return patches;
}

export const OperationOrder: { [key: string]: number } = ['remove-children', 'add-children'].reduce((acc, curr, i) => {
  return {
    ...acc,
    [curr]: i,
  };
}, {});

// 先删除后添加，处理替换相同id，慎用
export function sortPatches(patches: ArtboardPatches) {
  for (let key in patches.do) {
    patches.do[key].sort((a, b) => {
      return OperationOrder[a.op] - OperationOrder[b.op];
    });
    patches.undo[key].sort((a, b) => {
      return OperationOrder[a.op] - OperationOrder[b.op];
    });
  }
}

export function getEmptyArtboardPatchesOfArtboardSelf(): ArtboardPatches {
  return {
    do: {
      self: [],
    },
    undo: {
      self: [],
    },
  };
}

export function isValidArtboardPatches(patches: ArtboardPatches) {
  return Boolean(Object.keys(patches.do).length);
}

type ValueMapValueType = { [key: string]: string | boolean | Object | Array<Object> } | Array<Object>;
type ValueMapFunc = () => ValueMapValueType;
/**
 * 获取补充缺失属性的补丁操作
 * @param data 操作的对象
 * @param path 路径
 * @param valueMap 路径映射值
 * @author FanYongLong
 * @time 2022.11.24
 * 
 * @examples 
 * getFullOperationFromPath({states:{}},'/states/disabled/properties/textFormat',{'/states/disabled':{enable:false,properties:{}}})
 * 或者
 * getFullOperationFromPath({states:{}},'/states/disabled/properties/textFormat',{'/states/*':{enable:false,properties:{}}})
 *
   结果：{"do":[{"op":"add","path":"/states/disabled","value":{"enable":false,"properties":{}}}],"undo":[{"op":"remove","path":"/states/disabled"}]}
 */
export function getPatchOperationOfSupplementingMissingProperty<T extends IComponentData = IComponentData>(
  data: T,
  path: string,
  valueMap?: { [key: string]: ValueMapValueType | ValueMapFunc },
): { do: ComponentOperations; undo: ComponentOperations } {
  const keyPaths = path.split('/');
  const operation: { do: ComponentOperations; undo: ComponentOperations } = { do: [], undo: [] };
  let prefix = '';
  let prevValue;
  let prevIndex = -1;
  const matchValueMap: { match: RegExp; value: ValueMapValueType }[] = [];
  // 第一个通常为''或.
  if (keyPaths[0] === '' || keyPaths[0] === '.') {
    keyPaths.shift();
    prefix = '/';
  }
  keyPaths.pop(); // 排除最后一个
  if (keyPaths.length <= 0) {
    return operation;
  }

  // 属性默认值匹配,允许*代替当前路径未知属性名
  if (valueMap) {
    Object.keys(valueMap).forEach((key) => {
      matchValueMap.push({
        match: new RegExp('^' + key.replace(/\*/g, '[^/]+') + '$'),
        value: _.result(valueMap, key),
      });
    });
  }

  // 补全patches操作
  for (let i = 0; i < keyPaths.length; i++) {
    const keys = keyPaths.slice(0, i + 1);
    const rangeKey = keyPaths.slice(prevIndex + 1, i + 1);
    const completePath = prefix + keys.join('/');
    if (!_.has(data, keys) && (!prevValue || !_.has(prevValue, rangeKey))) {
      const match = matchValueMap.find((d) => d.match.test(completePath));
      const value = match ? match.value : {};
      prevValue = value;
      prevIndex = i;
      operation.do.push(Ops.add(completePath, value));
      operation.undo.unshift(Ops.remove(completePath));
    }
  }
  return operation;
}

function checkIsReplaceValueOp(operation: Operation) {
  return operation.op === 'replace' && /^\.?\/value$/.test(operation.path);
}

/**
 * 修改原 patches 中关于组件 value 属性的 operations
 * @param data
 * @param patches
 */
export function simplifyValuePropPatches(data: IComponentData, patches: ArtboardPatches) {
  // clone, check, apply, compare with origin, update prev patches
  const compID = data._id;
  // 因为 value 是整体替换，浅拷贝即可
  const clonedData = { ...data };

  let patchedData: IComponentData | undefined;

  patches.do[compID] = patches.do[compID].flatMap((operation) => {
    if (checkIsReplaceValueOp(operation)) {
      patchedData = applyOperation(clonedData, operation as ReplaceOperation<any>).newDocument;
      const newOperations = compare(data, patchedData);
      return newOperations as Operation[];
    }
    return operation;
  });

  patches.undo[compID] = patches.undo[compID].flatMap((operation) => {
    if (patchedData && checkIsReplaceValueOp(operation)) {
      const newOperations = compare(patchedData, data);
      return newOperations as Operation[];
    }
    return operation;
  });

  return patches;
}

export function simplifyAddChildrenPageOperations(pageOperations: PageOperations): PageOperations {
  Object.values(pageOperations).forEach((artboardOperations) => {
    Object.entries(artboardOperations).forEach(([compID, operations]) => {
      if (compID === 'self') {
        return;
      }

      for (let operation of operations) {
        // TODO 把if弄成个函数，精简add children
        if (Ops.isAddChildrenOperation(operation)) {
          const newValue = operation.value.map((data) => {
            // NOTE 这里得克隆，传输的patch才能是精简过的
            return simplifyComponentData(depthClone(data));
          });

          operation.value = newValue;
        }
      }
    });
  });

  return pageOperations;
}

function simplifyComponentData(data: IComponentData) {
  const libDefaultData = getLibDefaultData(data) ?? {};
  // depth 2 表示properties里面的对象不再精细精简
  _simplify(data, libDefaultData, 1, 2);

  // 复合组件不精简它的子，期望只对组、面板、复合路径
  if (!data.sealed && data.components?.length) {
    data.components.forEach(simplifyComponentData);
  }

  return data;
}

/**
 * 取差集，删除 originData中与defaultData值一样的字段
 * @param originData
 * @param defaultData
 * @param currentDepth
 * @param depth
 */
function _simplify(originData: any, defaultData: any, currentDepth: number, depth: number) {
  for (const key of Object.keys(defaultData)) {
    const defaultValue = defaultData[key];
    const currentValue = originData[key];
    if (
      currentDepth < depth &&
      getDataType(defaultValue) === DataType.Object &&
      getDataType(currentValue) === DataType.Object
    ) {
      currentDepth++;
      _simplify(currentValue, defaultValue, currentDepth, depth);
    } else {
      if (_.isEqual(currentValue, defaultValue)) {
        delete originData[key];
      }
    }
  }

  return originData;
}
