import { isMacOS } from './envUtils';
import { isInputting } from '@utils/globalUtils';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';

const keyIsNotModifierKey = (key: string): boolean =>
  ['control', 'shift', 'alt', 'meta'].indexOf(key.toLowerCase()) === -1;

// 是否是删除键
// 在 mac 上，删除是 backspace 兼容IE上的key 为del
const isDeleteKey = (key: string): boolean => (isMacOS ? key === 'backspace' : key === 'delete' || key === 'del');

const SPECIAL_KEY_MAP: { [key: string]: string } = {
  control: 'ctrl',
  shift: 'shift',
  alt: 'alt',
  backspace: 'delete',
  '⌘': 'ctrl',
  '⇧': 'shift',
  '⌥': 'alt',
  '⌫': 'delete',
  '↩': 'enter',
};

/**
 * macos系统匹配getShortCutKey有顺序要求
 * @param keysPressed
 */
const getMacShortKey = function (keysPressed: string[]) {
  const macControllerKey = ['shift', 'alt', 'ctrl'];
  return keysPressed.sort((a: string, b: string) => {
    const aIndex = macControllerKey.indexOf(a);
    const bIndex = macControllerKey.indexOf(b);
    if (aIndex === -1 || bIndex === -1) {
      return 1;
    }
    return aIndex - bIndex;
  });
};

/**
 * 根据按键事件获取当前按键字符串组合，如果按下控制键，则控制键字符串在前，多个按钮用+号连接，始终为小写字符串
 * @param e
 * @returns {string}
 */
export const convertEventToHotKey = (e: React.KeyboardEvent | KeyboardEvent, isMac?: boolean): string => {
  const keysPressed = [];
  const key = e.key?.toLowerCase();
  if (!key) {
    return '';
  }
  if (isControlKeyPressed(e)) {
    keysPressed.push('ctrl');
  }
  if (e.altKey || key === 'alt') {
    keysPressed.push('alt');
  }
  if (e.shiftKey || key === 'shift') {
    keysPressed.push('shift');
  }
  if (isDeleteKey(key)) {
    keysPressed.push('delete');
  } else if (keyIsNotModifierKey(e.key)) {
    keysPressed.push(e.key);
  }
  const hotkeys = isMac ? getMacShortKey(keysPressed) : keysPressed;
  return hotkeys.join('+').toLowerCase();
};

/**
 * 把快捷键字符串转换成热键字符串
 * @param {string} shortcut
 * @returns {string}
 */
export const convertShortCutToHotKey = (shortcut: string): string => {
  const keyList: string[] = [];
  if (isMacOS) {
    return shortcut
      .replace(/ /g, '')
      .replace('⌘', 'ctrl+')
      .replace('⇧', 'shift+')
      .replace('⌥', 'alt+')
      .replace('⌫', 'delete')
      .replace('↩', 'enter')
      .toLowerCase();
  } else {
    shortcut.split(' ').forEach((k, i) => {
      if (i % 2 === 0) {
        keyList.push(k);
      }
    });
  }
  if (keyList.length > 1) {
    keyList.forEach((k, i) => {
      let value = k.toLowerCase();
      if (value === '⌘' || value === 'control') {
        value = 'ctrl';
      } else if (value === '⌥') {
        value = 'alt';
      } else if (value === '⇧') {
        value = 'shift';
      } else if (value === '⌫') {
        value = 'delete';
      } else if (value === '↩') {
        value = 'enter';
      }
      keyList[i] = value;
    });
    return keyList.join('+').toLowerCase();
  }
  return shortcut.toLowerCase();
};

/**
 * 是否按下ctrl键,mac为meta键
 * @param e
 * @returns {boolean}
 */
export function isControlKeyPressed(e: React.KeyboardEvent | KeyboardEvent | React.MouseEvent | MouseEvent): boolean {
  return (isMacOS && e.metaKey) || (!isMacOS && e.ctrlKey);
}

/**
 * 当前按下键是否包含macos的control键
 * @param e
 * @returns
 */
export function isMacControlKeyPressed(
  e: React.KeyboardEvent | KeyboardEvent | React.MouseEvent | MouseEvent,
): boolean {
  return isMacOS && e.ctrlKey;
}

/**
 * 是否按下shift
 * @param e
 * @returns {boolean}
 */
export function isShiftKeyPressed(e: React.KeyboardEvent | KeyboardEvent | React.MouseEvent | MouseEvent): boolean {
  return e.shiftKey;
}

/**
 * 是否按下删除键
 *
 * @export
 * @param e
 * @returns {boolean}
 */
export function isDelPressed(e: React.KeyboardEvent | KeyboardEvent): boolean {
  const key = e.key.toLowerCase();
  return isMacOS ? key === 'backspace' : key === 'delete' || key === 'del';
}

/**
 * 是否是字符键
 */
export function isCharsKey(keyCode: number) {
  const Key_Z = 90;
  const Key_A = 65;
  const Key_0 = 48;
  const Key_9 = 57;
  const Key_pad_0 = 96;
  const Key_pad_add = 107;
  const Key_pad_substract = 109;
  const Key_pad_divide = 111;
  const Key_space = 32;
  const Key_semicolon = 186;
  const Key_backquote = 192;
  const Key_bracketLeft = 219;
  const Key_quote = 222;

  const inRange = (num: number, range: { min: number; max: number }) => {
    return num <= range.max && num >= range.min;
  };

  const ranges = [
    { min: Key_A, max: Key_Z },
    { min: Key_0, max: Key_9 },
    { min: Key_pad_0, max: Key_pad_add },
    { min: Key_pad_divide, max: Key_pad_substract },
    { min: Key_backquote, max: Key_semicolon },
    { min: Key_quote, max: Key_bracketLeft },
    { min: Key_space, max: Key_space },
  ];

  const isInRange = !ranges.some((r) => !inRange(keyCode, r));
  if (isInRange) {
    return true;
  }
  return false;
}

/**
 * 数字键连续双击管理
 */
export class NumberKeyDoubleClickEventHandle {
  private _capture: boolean = false;
  private DURATION = 300;
  private clickCount = 0;
  private isKeyPressed: boolean = false;
  private timeStamp: number = 0;
  private handle?: (value: number) => void;
  private NUM_KEY = '0123456789';
  private numStr: string = '';
  private timeOut?: Timeout;

  constructor(capture?: boolean) {
    this._capture = !!capture;
  }

  private reset() {
    this.numStr = '';
    this.clickCount = 0;
    this.isKeyPressed = false;
    this.timeStamp = 0;
    clearTimeout(this.timeOut);
    this.timeOut = undefined;
  }

  private isControllerKey(e: KeyboardEvent) {
    return e.shiftKey || e.altKey || e.ctrlKey || e.metaKey;
  }

  private isNumberChar(key: string) {
    return this.NUM_KEY.indexOf(key) !== -1;
  }

  private doExecute = () => {
    let str = this.numStr.trim();
    const isNumStr = /\d+/.test(str);
    this.reset();
    if (!isNumStr || !str.length) {
      return;
    }
    // 几种特殊情况字符串转换
    if (str === '0') {
      str = '100';
    } else if (str === '00') {
      str = '0';
    } else if (str.length === 1) {
      str = `${str}0`;
    } else if (str[0] === '0') {
      str = str.substr(1);
    }
    const value = parseInt(str, 10);
    this.handle && this.handle(value);
  };

  private validateKey(key: string) {
    const isNumberKey = this.isNumberChar(key);
    // 非数字键
    if (!isNumberKey) {
      this.timeStamp = 0;
      this.clickCount = 0;
      this.numStr = '';
    }
    const now = Date.now();
    const isContinuous = now - this.timeStamp <= this.DURATION;
    // 按一次，连续按两次
    if (!this.isKeyPressed && isNumberKey) {
      this.timeStamp = now;
      this.numStr = `${key}`;
      this.clickCount = 1;
    }
    // 连续多次
    if (isNumberKey && isContinuous && this.isKeyPressed) {
      this.clickCount++;
      if (this.clickCount >= 2 && this.numStr.length < 2) {
        this.clickCount = 0;
        this.numStr = this.numStr ? `${this.numStr}${key}` : key;
      }
    }
  }

  private handleKeyDown = (e: KeyboardEvent) => {
    // 内容输入或有控制键按下
    if (isInputting() || this.isControllerKey(e)) {
      this.reset();
      return;
    }
    const { key } = e;
    this.validateKey(key);
    clearTimeout(this.timeOut);
    if (this.isNumberChar(key)) {
      this.timeOut = window.setTimeout(this.doExecute, this.DURATION);
    }
  };

  private handleKeyUp = (e: KeyboardEvent) => {
    if (isInputting() || this.isControllerKey(e)) {
      this.reset();
      return;
    }
    if (this.isNumberChar(e.key)) {
      this.isKeyPressed = true;
    }
  };

  private get capture() {
    return {
      capture: this._capture,
    };
  }

  addEventListener(handle: (value: number) => void) {
    this.handle = handle;
    window.addEventListener('keydown', this.handleKeyDown, this.capture);
    window.addEventListener('keyup', this.handleKeyUp, this.capture);
  }

  removeEventListener() {
    window.removeEventListener('keydown', this.handleKeyDown, this.capture);
    window.removeEventListener('keyup', this.handleKeyUp, this.capture);
  }
}

/**
 * 自定义快捷键函数
 *
 * @param {(e: KeyboardEvent, hotKey: string) => void} callback
 * @return {(e: KeyboardEvent) => undefined}
 *
 * @description 回调函数第一个娄事件参数，第二个为快捷键组合字符串，以加号连接，前面为控制键，后面为按顺序排列的其它任意键，整体字符串转为小写
 */
export function customShortcutKeyEventHandle(callback: (e: KeyboardEvent, hotKey: string) => void) {
  const keyMap: string[] = [];
  let preHotKey: string = '';

  const getHotKey = () => {
    let altKey = false;
    let ctrlKey = false;
    let shiftKey = false;
    let waveKey = false;
    const _key: string[] = [];
    keyMap.forEach((k) => {
      switch (k) {
        case 'ctrl':
          ctrlKey = true;
          break;
        case 'alt':
          altKey = true;
          break;
        case 'shift':
          shiftKey = true;
          break;
        case '`':
          waveKey = true;
          break;
        default:
          _key.push(k);
          break;
      }
    });
    _key.sort();
    if (waveKey) {
      _key.unshift('~');
    }
    if (shiftKey) {
      _key.unshift('shift');
    }
    if (altKey) {
      _key.unshift('shift');
    }
    if (ctrlKey) {
      _key.unshift('ctrl');
    }
    return _key.join('+');
  };

  const getKey = (e: KeyboardEvent) => {
    const { key, keyCode } = e;
    if (keyCode === KeyCodeMap.VK_SPACE) {
      return 'space';
    }
    const k = key.toLocaleLowerCase();
    return SPECIAL_KEY_MAP[k] || k;
  };

  const winBlur = () => {
    window.removeEventListener('keyup', keyUpHandle, true);
    window.removeEventListener('blur', winBlur);
    keyMap.splice(0, keyMap.length);
    preHotKey = '';
  };

  const keyUpHandle = (e: KeyboardEvent) => {
    const key = getKey(e);
    if (keyMap.includes(key)) {
      keyMap.splice(keyMap.indexOf(key), 1);
    }
    preHotKey = getHotKey();
    if (!keyMap.length) {
      window.removeEventListener('keyup', keyUpHandle, true);
    }
  };

  return (e: KeyboardEvent) => {
    if (isInputting()) {
      return;
    }
    if (!keyMap.length) {
      window.addEventListener('keyup', keyUpHandle, true);
    }
    window.addEventListener('blur', winBlur);
    const key = getKey(e);
    if (!keyMap.includes(key)) {
      keyMap.push(key);
    }
    const hotKey = getHotKey();
    if (hotKey !== preHotKey) {
      callback(e, hotKey);
    }
  };
}
