import { IAppWithNestedChildren } from '@/fbs/rp/models/app';
import IArtboard from '@/fbs/rp/models/artboard';
import { INodeWithChildren } from '@/fbs/rp/models/node';
import { CContentPanel, CContentPanelV2, CCarouselChart } from '@/libs/constants';
import { IComponentData } from '@/fbs/rp/models/component';
import { FillType } from '@/fbs/rp/models/properties/fill';
import {
  UIFragment,
  UIArtboard,
  UIComponent,
  UIContentPanelComponent,
  UIContentPanelV2Component,
  UIContainerComponent,
} from '@/editor/comps';
import Doc from '@/editor/document';
import { getArtboardsByPageID } from '@/apis/common/artboard';
import { getCenterOffsetInViewportAfterRotate } from './boundsUtil';
import { offseOpposite } from '.';

/**
 * 是否画板
 * @param components
 */
export const isArtboard = (components: (UIArtboard | UIFragment | UIComponent)[]): boolean => {
  return components.every((t) => t instanceof UIFragment);
};

/**
 * 是否单独组件
 * @param components
 */
export const isSingleComponent = (components: (UIArtboard | UIFragment | UIComponent)[]): boolean => {
  return components.length === 1 && components.every((t) => !t.isArtboard);
};

export const plainArtboards = (artboards: IArtboard[][]) => {
  const list: IArtboard[] = [];
  artboards.forEach((t) => {
    list.push(...t);
  });
  return list;
};

export const plainPage = (children: INodeWithChildren[], checkedPages?: string[]) => {
  const list: INodeWithChildren[] = [];
  children.forEach((t) => {
    if (t.type === 'page') {
      if (checkedPages) {
        if (checkedPages.includes(t._id)) {
          list.push(t);
        }
      } else {
        list.push(t);
      }
    }

    if (t.children && t.children.length) {
      list.push(...plainPage(t.children, checkedPages));
    }
  });
  return list;
};

export const getPlainArtboards = async (
  app: IAppWithNestedChildren,
  checkedPages?: string[],
  exportMainArtboardsOnly?: boolean,
): Promise<IArtboard[]> => {
  const pageList = plainPage(app.children, checkedPages);
  const processer = pageList.map(async (t) => {
    const id = t._id;
    const artbords = await getArtboardsByPageID(id, false);
    // 仅导出主画板
    if (exportMainArtboardsOnly) {
      // fixme: 不能过滤掉，可能存在 内容面板 数据关联情况
      return artbords;
      // return artbords.filter((t) => t.type === 'main');
    }
    return artbords;
  });
  const artboards = await Promise.all(processer);

  const artboardList = plainArtboards(artboards);
  return artboardList;
};

export const createDocWtithPlainArtboards = async (
  app: IAppWithNestedChildren,
  checkedPages?: string[],
  exportMainArtboardsOnly?: boolean,
): Promise<{ doc: Doc; fragments: UIFragment[]; ids: string[] }> => {
  const artboardList = await getPlainArtboards(app, checkedPages, exportMainArtboardsOnly);

  // 创建一个新的doc
  const { appID } = app;
  const newDoc = new Doc(appID, 'testNodeID', artboardList, { isPreview: true });
  artboardList.forEach((t) => {
    // 排除相同画板
    if (!newDoc.fragments.find((item) => item.realID === t._id)) {
      newDoc.addArtboardKeepType(t);
    }
  });
  const fragments = newDoc.fragments;
  // console.log(artboardList.map(t=>t.name))
  // console.log(fragments.map(t=>t.name))
  window.debug && console.log(newDoc);
  return {
    doc: newDoc,
    fragments,
    // 画板顺序，额外保存一个，因为构建doc过程fragments因为主从关系会被打乱
    ids: artboardList.map((t) => t._id),
  };
};

/**
 * 另一种方法创建doc
 * @param artboardList
 * @param doc
 * @returns
 */
export const createUIArtboards = (artboardList: IArtboard[], doc: Doc) => {
  const uis = artboardList.map((t) => {
    const ui = new UIArtboard({ ...t, type: 'artboard' }, doc);
    return ui;
  });
  console.log(uis);
  return uis;
};

const getContantPanels = (component: UIComponent): UIContainerComponent[] => {
  const contentPanels: UIContainerComponent[] = [];
  // if ([CGroup, CArtboard, CCanvasPanel].includes(component.type)) {  //
  // 更新 contentPanels
  if (component.isContainer) {
    if (component instanceof UIContentPanelComponent || component instanceof UIContentPanelV2Component) {
      contentPanels.push(component);
    }
    (component as UIContainerComponent).components.forEach((t) => {
      const children = getContantPanels(t) as any[];
      contentPanels.push(...children);
    });
  }
  return contentPanels;
};

/**
 * 内容面板数据
 * @param components
 * @returns
 */
const getContantPanelsData = (components: IComponentData[]): IComponentData[] => {
  const contentPanels: IComponentData[] = [];
  components.forEach((t) => {
    if ([CContentPanelV2, CContentPanel].includes(t.type)) {
      contentPanels.push(t);
    }
    if (t.components?.length) {
      const children = getContantPanelsData(t.components);
      contentPanels.push(...children);
    }
  });
  return contentPanels;
};

/**
 * 这里只需要装载 选中的面板内容
 * @param artboard
 * @param doc
 */
export const updateContentPanel = (artboard: UIComponent, doc: Doc) => {
  const contentPanels: UIContainerComponent[] = getContantPanels(artboard);
  contentPanels.forEach((comp: UIContainerComponent) => {
    const id = comp.components.find((t) => t.selected)?.value as string;
    if (id && doc) {
      const fragment = doc.getArtboardByID(id); // getFragmentDataFromDoc(doc, id) //   findFragmentData(comp.id,id)  //  doc.getArtboardByID(id)
      if (fragment) {
        const json = fragment.toJSON() as IArtboard;
        const newFragment = new UIArtboard({ ...json, position: { x: 0, y: 0 } }, doc);
        updateContentPanel(newFragment, doc);
        comp.components = newFragment.components;

        // 内容面板背景
        let fill = fragment.background;
        if (!fill?.color) {
          fill = { color: { r: 255, g: 255, b: 255, a: 1 }, type: FillType.solid, disabled: false };
        }
        comp.toJSON().properties.fill = fill;
      }
    }
  });
};

/**
 * 替换画板内的内容面板数据
 * @param components
 * @param doc
 */
export const updateContentPanelDataInArtboard = (components: IComponentData[], doc: Doc) => {
  const contentPanels: IComponentData[] = getContantPanelsData(components);
  contentPanels.forEach((comp: IComponentData) => {
    if (!comp.components) {
      return;
    }
    const id = comp.components.find((t) => t.selected)?.value as string;
    if (id && doc) {
      const fragment = doc.getArtboardByID(id); // getFragmentDataFromDoc(doc, id) //   findFragmentData(comp.id,id)  //  doc.getArtboardByID(id)
      if (fragment) {
        const json = fragment.toJSON() as IArtboard;
        if (json.components) {
          updateContentPanelDataInArtboard(json.components, doc);
        }
        comp.components = json.components;

        // 内容面板背景
        let fill = fragment.background;
        if (!fill?.color) {
          fill = { color: { r: 255, g: 255, b: 255, a: 1 }, type: FillType.solid, disabled: false };
        }
        comp.properties.fill = fill;
      }
    }
  });
};

export const getContentPanelComponets = (comp: UIContainerComponent, doc?: Doc) => {
  const id = comp.components.find((t) => t.selected)?.value as string;
  if (id && doc) {
    const fragment = doc.getArtboardByID(id); // getFragmentDataFromDoc(doc, id) //   findFragmentData(comp.id,id)  //  doc.getArtboardByID(id)
    if (fragment) {
      const json = fragment.toJSON() as IArtboard;
      const newFragment = new UIArtboard({ ...json, position: { x: 0, y: 0 } }, doc);
      updateContentPanel(newFragment, doc);

      // 内容面板背景
      let fill = fragment.background;
      if (!fill?.color) {
        fill = { color: { r: 255, g: 255, b: 255, a: 1 }, type: FillType.solid, disabled: false };
      }

      return {
        components: newFragment.components.map((t) => t.toJSON()),
        properties: {
          fill,
        },
      };
    }
  }
};

/**
 * 性能优化：保留可见组件
 * @param t
 */
export const getVisibleComponents = (t: IArtboard) => {
  const { width, height } = t.size;

  return t.components
    ?.filter((t) => {
      const { size, rotate } = t;
      let position = t.position;

      let { x, y } = position;
      if (y < height && x < width) {
        return true;
      }

      if (rotate && rotate > 0) {
        // 旋转后中心偏移
        const offset = getCenterOffsetInViewportAfterRotate(size, rotate);
        position = offseOpposite(position, offset);
        x = position.x;
        y = position.y;
      }

      if (y > height || x > width) {
        return false;
      }

      return true;
    })
    .map((t) => {
      return deepCloneContentPanel(t);
    });
};

// 克隆内容面板数据
//  后续要更新背景属性和子组件，不能影响到编辑区
export const deepCloneContentPanel = (t: IComponentData): IComponentData => {
  if (CCarouselChart === t.type) {
    // 轮播图图片精简
    return simplifyCarouselChartData(t);
  }
  if (t.type === CContentPanel || t.type === CContentPanelV2) {
    return { ...t };
  }
  t.components = t.components?.map((t) => {
    return deepCloneContentPanel(t);
  });

  return t;
};

export const simplifyCarouselChartData = (json: IComponentData) => {
  const { type, components, value } = json;
  if (CCarouselChart === type) {
    const imageMain = components?.find((t) => t.alias === 'image-main');
    if (imageMain?.components) {
      const activeImage = imageMain.components[value];
      const newImageMain = { ...imageMain, components: [activeImage] };
      if (components) {
        const index = components.findIndex((t) => t.alias === 'image-main');
        const newConmponents = [...components];
        // 替换，去掉其他图片
        newConmponents.splice(index, 1, newImageMain);
        return { ...json, components: newConmponents, value: 0 };
      }
    }
  }
  return json;
};
