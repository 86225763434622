import { IPrototypeInfo } from '@fbs/idoc/models/app';
import { AppShareLinkVisibility } from '@fbs/rp/models/share';

import { ActionType } from '../types';
import { ShareAppAction } from './actions';

export interface IShareAppState {
  appID: string;
  publishShareID: string;
  shareID: string;
  shareAndInviteCode: string;
  onlyShareCurrentMode: boolean;
  iframeShareLinkID: string;
  rpApps: IPrototypeInfo[];
  waiting?: boolean;
}

const initState: IShareAppState = {
  appID: '',
  publishShareID: '',
  shareID: '',
  shareAndInviteCode: '',
  onlyShareCurrentMode: true,
  iframeShareLinkID: '',
  rpApps: [],
};

export default (state: IShareAppState = initState, action: ShareAppAction): IShareAppState => {
  switch (action.type) {
    case ActionType.ShareApp_GetLink:
      if (action.payload.visibility === AppShareLinkVisibility.Private) {
        return {
          ...state,
          shareID: action.payload.shortID,
        };
      }
      return {
        ...state,
        shareAndInviteCode: action.payload.shortID,
        onlyShareCurrentMode: !!action.payload.onlyShareThisModel,
      };
    case ActionType.ShareApp_GetPublicLink:
      return {
        ...state,
        publishShareID: action.payload.shortID,
      };
    case ActionType.ShareApp_Patch:
      return {
        ...state,
        onlyShareCurrentMode: !!action.payload.onlyShareThisModel,
      };
    case ActionType.ShareApp_ResetLink:
      return {
        ...state,
        publishShareID: action.payload.shortID,
      };
    case ActionType.ShareApp_LoadEmbedShareLink: {
      const { _id: iframeShareLinkID } = action.payload;
      return {
        ...state,
        iframeShareLinkID,
      };
    }
    case ActionType.App_LoadAllPrototypeApps:
      return {
        ...state,
        rpApps: action.payload,
      };
    case ActionType.ShareApp_Waiging:
      return {
        ...state,
        waiting: action.payload,
      };
    default:
      return state;
  }
};
