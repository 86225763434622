import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// 高度小于45，y轴开始收缩
// const basePosition = '(sh>=45?20:sh*(20/45))';
const basePosition = 'Math.min(sw,sh)*(20/45)';

// 下箭头
export default {
  ...flowBase,
  name: i18n('resource.flow.downArrow'),
  type: 'downArrow',
  thumb: {
    spriteIconClass: SpriteThumb.DownArrow.className,
    dragPosition: SpriteThumb.DownArrow.position,
  },
  template: getShapeData(
    i18n('resource.flow.downArrow'),
    { width: 45, height: 60 },
    {
      data: [
        { point: { x: 15, y: 40 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 15, y: 0 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 30, y: 0 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 30, y: 40 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 45, y: 40 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 22.5, y: 60 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 0, y: 40 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      ],
      closed: true,
      isTemplateShape: true,
      templateData: [
        { point: { x: 'sw*(15/45)', y: `sh-${basePosition}` }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 'sw*(15/45)', y: 0 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 'sw*(30/45)', y: 0 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 'sw*(30/45)', y: `sh-${basePosition}` }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 'sw', y: `sh-${basePosition}` }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 'sw/2', y: 'sh' }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 0, y: `sh-${basePosition}` }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      ],
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
