import { saveAs } from 'file-saver';
import { ExportFileFormat, ExportRatio } from '@/fbs/rp/models/component';
import * as domtoimage from '../domToImage/domToImage';
import { config } from '../config';
import {
  IExportArboardOptions,
  IExportComponentsOptions,
  IExportPageOptions,
  IExportSvgOptions,
  OuputType,
} from '../type';
import { createDocWtithPlainArtboards } from '../util/artboardUtil';
import { exportSvgDataUri } from './saveFiles';
import { runExportTask } from './runExportTask';
import UIFragment from '@/editor/comps/UIFragment';
import { IExportFile } from './packImages';

export const exportActiveArtboardAsPng = () => {
  exportActiveArtboard(ExportFileFormat.Png);
};

export const exportActiveArtboardAsSvg = () => {
  exportActiveArtboard(ExportFileFormat.SVG);
};

/**
 * 导出当前画板
 * @param type
 * @returns
 */
export const exportActiveArtboard = (type: ExportFileFormat) => {
  const node = document.querySelector('.active-artboard .content');
  if (node) {
    exportFile(node, type);
  }
};

/**
 * 导出当前页面
 * @param node
 */
export const exportCurrentPageArtboards = (options: IExportPageOptions) => {
  const { doc, exportFormats, app } = options;
  if (!doc) {
    return;
  }
  const artboards = [doc.mainArtboard, ...doc.fragments];
  return exportComponents({ components: artboards, doc, exportFormats, app });
};
/**
 * 导出所有页面
 * @param options
 * @returns
 */
export const exportAllPages = async (options: IExportPageOptions) => {
  const { app, exportFormats } = options;
  if (!app) {
    return;
  }
  const { fragments, doc: newDoc } = await createDocWtithPlainArtboards(app);
  return exportComponents({ components: fragments, doc: newDoc, app, exportFormats, isOutputAllPages: true });
};

/**
 * 选择页面，导出画板
 * @param options
 */
export const exportCheckedPages = async (options: IExportPageOptions) => {
  const { app, exportFormats, checkedPages, mobileType, onProgress, exportMainArtboardsOnly } = options;
  // window.debug && exportFormats?.splice(0, 1, { fileFormat: ExportFileFormat.SVG });
  if (!app) {
    return;
  }
  const { fragments, doc: newDoc, ids } = await createDocWtithPlainArtboards(
    app,
    checkedPages,
    exportMainArtboardsOnly,
  );
  return exportComponents({
    components: fragments,
    doc: newDoc,
    app,
    exportFormats,
    isOutputAllPages: true,
    mobileType,
    onProgress,
    exportMainArtboardsOnly,
    ids,
  });
};
/**
 * 导出png
 */
export const exportPngFile = (node: Element) => {
  exportFile(node, ExportFileFormat.Png);
};

/**
 * 导出svg
 */
export const exportSvgFile = (node: Element) => {
  exportFile(node, ExportFileFormat.SVG);
};

const exportFile = (node: Element, type: ExportFileFormat) => {
  if (!node) {
    return;
  }
  switch (type) {
    case ExportFileFormat.SVG:
      domtoimage.toSvg(node, config).then(function (svg) {
        if (svg) {
          saveAs(svg, 'test.svg');
        }
      });
      break;
    case ExportFileFormat.Png:
      console.time('total_toPng');
      domtoimage.toBlob(node, config).then((blob) => {
        console.timeEnd('total_toPng');
        saveAs(blob, 'test.png');
      });
      break;
  }
};

/**
 * 导出选择组件：
 * 支持：图片 画板
 * @param components
 */
export const exportComponents = async (options: IExportComponentsOptions) => {
  runExportTask(options);
};

/**
 * 导出画板
 * @param options
 */
export const exportArtboard = async (options: IExportArboardOptions): Promise<void> => {
  const { fragments, doc, exportFormats, app, mobileType, onProgress } = options;
  // window.debug && exportFormats?.splice(0, 1, { fileFormat: ExportFileFormat.SVG });
  runExportTask({ components: fragments, doc, app, exportFormats, mobileType, onProgress });
};

/**
 * 导出svg
 * @param options
 */
export const exportSvg = (options: IExportSvgOptions): Promise<string> => {
  return exportSvgDataUri(options);
};

/**
 *
 * 保存画板为图片blob数据
 * @param fragment
 * @returns
 */

export const fragmentToBlob = (
  fragment: UIFragment,
  // options?: { size?: { width: number; height: number }; scale: number = 1 },
): Promise<Blob> => {
  return new Promise((resolve) => {
    const fragments = [fragment];
    const exportFormats = [{ fileFormat: undefined, exportRatio: ExportRatio.OneScale }];
    const ouputType = OuputType.Blob;
    const onFinished = (files: IExportFile[]) => {
      const result = files.map((t) => t.content as Blob)[0];
      resolve(result);
    };
    runExportTask({ components: fragments, doc: fragment.doc, exportFormats, ouputType, onFinished });
  });
};
