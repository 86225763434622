import { isUndefined } from 'lodash';

import { merge, depthClone, mergeStateData, valueToProxy, getDataType, DataType } from '@utils/globalUtils';

import {
  IComponentData,
  IComponentSize,
  IComponentState,
  IComponentStateData,
  IRemark,
} from '@fbs/rp/models/component';
import { IPosition } from '@fbs/common/models/common';
import { ILayout } from '@fbs/rp/models/layout';
import { IComponentValue } from '@fbs/rp/models/value';
import { Animation } from '@fbs/rp/models/animation';
import { IInteraction } from '@fbs/rp/models/interactions';
import { IProperties, PropertyName } from '@fbs/rp/models/property';
import { isPredefinedState, PredefinedStates } from '@consts/state';
import { DefaultFontSize } from '@consts/fonts';
import {
  CContentPanel,
  CContentPanelV2,
  CText,
  CTable,
  CCompoundPath,
  CPath,
  CRect,
  CEllipse,
  CPolygon,
  CLine,
  CInput,
  CTextArea,
  CHotArea,
} from '@libs/constants';
import { mergeProperties, adaptCompData } from '@helpers/propertiesHelper';
import { IFlipModel } from '@helpers/flipHelper';
import { IUICompConstructOptions } from '@/customTypes';
import { getLibDefaultData, adaptSimplifiedCompData } from '@/libs/libs';
import { UIFragment, UIComponent, UIContainerComponent } from '..';

const BaseAttributes = new Set([
  'anchors',
  'size',
  'colorStops',
  'color',
  'radius',
  'dashArray',
  'data',
  'cells',
  'rows',
  'columns',
]);

function getProxyProperties(source: IProperties, target: IProperties) {
  if (!target) {
    return source;
  }

  const result: Record<string, any> = {};
  const keys = new Set([...Object.keys(source).concat(Object.keys(target))]);

  for (let key of keys) {
    const propName = key as PropertyName;
    const sourceValue = source[propName];
    const targetValue = target[propName];
    const sourceValueType = getDataType(sourceValue);
    const targetValueType = getDataType(targetValue);

    if (targetValueType === DataType.Object && sourceValueType === DataType.Object) {
      result[propName] = mergeStateData(sourceValue, targetValue, (k, s, t) => {
        if (['colorStops', 'color', 'radius', 'dashArray'].includes(k)) {
          return t;
        }
      });
      if (!targetValue!.ref && result[propName]) {
        result[propName] = depthClone(result[propName]);
        delete result[propName]!.ref;
      }
    } else {
      result[key] = targetValue ?? sourceValue;
    }
  }
  return result;
}

export default abstract class ComponentBase {
  private _data: IComponentData;
  private _adoptSimplify = false;

  // 封装组件
  public readonly isSealed: boolean;

  public isPreview: boolean;

  protected constructor(data: IComponentData, public parent?: ComponentBase, public options?: IUICompConstructOptions) {
    // data._currentState = undefined;
    this._data = data;
    this.isSealed = data.sealed || false;
    this.isPreview = !!options?.isPreview;

    adaptCompData(this._data);

    this.updateSymbolData();
  }

  protected updateSymbolData() {}

  private getStateData(source: IComponentData, target: IComponentState) {
    return mergeStateData<IComponentData, IComponentState>(source, target, (key, source, target) => {
      if (BaseAttributes.has(key)) {
        return target;
      }
      if (key === 'value') {
        if (Array.isArray(target) || Array.isArray(source)) {
          return target || source;
        }
      }
      if (key === 'properties') {
        return getProxyProperties(source, target);
      }
      if (key === 'relation') {
        return source;
      }
      return undefined;
    });
  }

  private mergeState(data: IComponentData, currentState: IComponentState): IComponentStateData {
    return merge(data, currentState, (key, source, target) => {
      if (BaseAttributes.has(key)) {
        if (Array.isArray(target)) {
          return [...target];
        } else if (typeof target === 'object') {
          return { ...target };
        }
        return target;
      }
      if (key === 'value') {
        if (Array.isArray(target) || Array.isArray(source)) {
          return [...(target || source)];
        }
      }
      if (key === 'properties') {
        return mergeProperties(source, target);
      }
      if (key === 'relation') {
        return source;
      }
      return undefined;
    });
  }

  getFontSize(): number {
    let fontSize = DefaultFontSize;
    const { textStyle, textFormat } = this.properties;
    if (textFormat) {
      fontSize = textFormat.fontSize || fontSize;
    } else {
      fontSize = textStyle?.fontSize || fontSize;
    }
    return fontSize;
  }

  get nearestSealedComponent(): UIComponent | UIContainerComponent | null {
    if (!this.parent) {
      return null;
    }
    if (this.parent.isSealed) {
      return this.parent as UIContainerComponent;
    }
    return this.parent.nearestSealedComponent;
  }

  protected get data(): IComponentData {
    // 演示时，只补上一次，这样提高性能
    if (this.isPreview) {
      if (!this._adoptSimplify) {
        adaptSimplifiedCompData(this._data);
        this._adoptSimplify = true;
      }
      return this._data;
    }

    // 编辑时，会有默认属性被删除的情况，所以需要始终补上默认属性
    adaptSimplifiedCompData(this._data);
    return this._data;
  }

  public get type(): string {
    return this.data.type;
  }

  get $data(): IComponentData {
    return this.data;
  }

  toJSON(): IComponentData & { _bringFront?: boolean } {
    return this.data;
  }

  get componentType(): string {
    return this.data.type;
  }

  get symbolInfo() {
    return this._data.symbol;
  }

  get version(): string {
    return `${this.data.v}`;
  }

  get lib(): { id: string; type: string } | undefined {
    return this.data.lib;
  }

  get fontSize(): number {
    return this.getFontSize();
  }

  get lineHeight(): number {
    return Math.round(this.fontSize * 1.4);
  }

  /**
   * 获取文字属性
   * @returns {string | undefined}
   */
  get text(): string {
    if (this.fixContent) {
      return this.data.text || '';
    }
    return this.currentState.text || '';
  }

  // 组件当前的状态
  // 自动继承父的状态
  get _currentState(): string | undefined {
    const { data } = this;
    const { _currentState } = data;
    // 其它状态优先级第四
    if (_currentState) {
      return _currentState;
    }

    // 禁用状态优先级最高
    if (data.disabled) {
      return PredefinedStates.disabled;
    }
    //选中状态优先级第三
    if (!isUndefined(data.selected)) {
      return data.selected ? PredefinedStates.checked : PredefinedStates.unchecked;
    }

    // 如果是内容面板组件，不继承状态
    if (this.parent?.parent?.$data.type === CContentPanel || this.parent?.parent?.$data.type === CContentPanelV2) {
      return undefined;
    }

    // 父状态优先级第五
    if (this.parent) {
      return this.parent._currentState;
    }
    // 默认状态第六
    return undefined;
  }

  get libDefaultData() {
    return getLibDefaultData(this._data);
  }

  get currentState(): IComponentStateData {
    const { data, _currentState: currentState, getStateData } = this;

    if (isUndefined(currentState) || !this.hasState(currentState)) {
      return data;
    }
    const stateData = data.states[currentState];
    // return this.mergeState(this.data, stateData);

    // const defaultState = this.libDefaultData.states ?? {};
    // const defaultStateData = defaultState[currentState];

    // 获取state和data合并数据，该返回值只用于读取
    // return valueToProxy(getStateData(data, { ...defaultStateData, ...stateData }));
    return valueToProxy(getStateData(data, stateData));
  }

  get properties(): IProperties {
    // 无需在这里补全了
    const originProperties = this.data.properties;

    const properties = this.currentState.properties || originProperties;
    if (properties.placeholder && this.fixContent) {
      properties.placeholder = originProperties.placeholder;
    }

    return properties;
  }

  // 真实 id
  get realID(): string {
    return this.data._id;
  }

  // 组件 id
  get id(): string {
    const { data } = this;
    if (data.type === 'artboard') {
      return 'ROOT';
    }
    return data._id;
  }

  // 获取组件所属的画板 ID
  get ownerArtboardID(): string {
    let node: ComponentBase = this;
    while (node.parent) {
      node = node.parent;
    }
    return node.realID;
  }

  // 获取组件所属的画板
  get ownerArtboard(): UIFragment {
    let node: ComponentBase = this;
    while (node.parent) {
      node = node.parent;
    }
    return node as UIFragment;
  }

  get name(): string | null {
    return this.data.name || null;
  }

  get alias(): string | undefined {
    return this.data.alias;
  }

  get rotate(): number {
    return this.currentState.rotate || 0;
  }

  // 是否锁定尺寸
  get lockedRatio(): boolean {
    return this.data.size.lockedRatio || false;
  }

  get layout(): ILayout {
    return this.data.layout;
  }
  get position(): IPosition {
    return this.currentState.position || this.data.position;
  }

  get size(): IComponentSize {
    // FIXME 通过模板配置的数据中，可能得到的size为undefined
    // 解决表格在禁用状态下，文本组件的尺寸计算问题，表格下的文本组件不需要走状态resize
    if (this.$data.type === CText && this.parent && this.parent.$data.type === CTable) {
      return this.data.size;
    }
    const stateSize = this.currentState.size;
    const dataSize = this.data.size;
    return stateSize || dataSize;
  }

  get flip(): { horizontal: boolean; vertical: boolean } | undefined {
    return this.data.flip;
  }
  // 是否隐藏
  get hidden(): boolean {
    let value = !!this.currentState.hidden;
    if (this.isPreview && !value && this.parent) {
      value = this.parent.hidden;
    }
    return value;
    // return this.currentState.hidden || false;
  }

  get disabled(): boolean {
    if (this.data.disabled) {
      return true;
    }
    return this.parent ? this.parent.disabled : false;
  }

  // 版本号，包括父的
  chainedVersion(): string {
    if (this.data.type === 'artboard') {
      return '$';
    }

    let v: string = '0';
    if (this.data.v) {
      v = this.data.v.toString(32);
    }
    if (this.parent) {
      v += this.parent.chainedVersion();
    }

    return v;
  }

  get _scale(): {
    x: number;
    y: number;
  } {
    return Object.assign({ x: 1, y: 1 }, this.currentState._scale || this.data._scale);
  }

  get value(): IComponentValue | undefined {
    if (this.fixContent) {
      return this.data.value;
    }
    return this.currentState.value;
  }

  get opacity(): number {
    const value = this.currentState.opacity;
    return isUndefined(value) ? 100 : value;
  }

  get _animation(): Animation | undefined {
    return this.data._animation;
  }

  get interactions(): IInteraction {
    return this.data.interaction;
  }

  get autoSize(): boolean {
    return this.data.autoSize || false;
  }

  get states(): { [name: string]: IComponentStateData } {
    return this.data.states || {};
  }

  get locked() {
    return this.data.locked;
  }

  get selected() {
    // return (this.data.selected && this.data.states[PredefinedStates.checked]?.enabled) || false;
    return this.data.selected || false;
  }

  get remark(): IRemark | undefined {
    const { remark, _id } = this.data;
    if (remark && typeof remark === 'string') {
      return {
        value: remark,
        createdAt: _id,
        num: 0,
      };
    }
    return remark;
  }

  /**
   * 获取属性在当前状态下的路径，获取前必须判断路径是否存在
   * @param {string} path
   * @returns {string}
   * @deprecated 废弃该方法，改用下面三个方法来获取
   */
  public getCurrentPath(path: string): string {
    if (!path) {
      if (this.data._currentState) {
        return `/states/${this.data._currentState}`;
      }
      return '';
    }
    let state = this.data._currentState;
    if (!state) {
      if (this.data.disabled) {
        state = 'disabled';
      } else if (this.data.selected) {
        state = 'checked';
      }
    }
    if (state) {
      const stateData = this.data.states[state];
      if (stateData) {
        return `/states/${state}/${path}`;
      } else {
        console.warn(`/states/${state}/${path}`, '这个属性路径不存在，请先判断');
      }
    }

    return `/${path}`;
  }

  getValuePath(type: string) {
    return [CCompoundPath, CPath].includes(type) ? '/value' : this.getCurrentPropertiesPath('value');
  }

  /**
   * 属性修改的patch
   * @param path patch路径
   * @param SyncStateNormalProperty 是否把该属性同步为正常状态的值
   */
  getCurrentPropertiesPath(path: string, syncPropertyToStateNormal?: boolean): string {
    const regExp = /^\/*/;
    const tx = path.replace(regExp, '');
    const fullPath = `/${tx}`;

    // 华为私有：各状态内容统一
    if (this.fixContent && /^(text|value|properties\/placeholder)\/?$/.test(tx)) {
      return fullPath;
    }

    // 同步为正常状态属性值
    if (syncPropertyToStateNormal === true) {
      return fullPath;
    }

    const currentStateID = this.currentStateID;
    const _currentStateID = this.data._currentState;
    // 不为默认状态时，使用选中的状态
    if (currentStateID && currentStateID !== PredefinedStates.normal) {
      return `./states/${this.currentStateID}/${tx}`;
    }
    // 这种情况下返回默认状态，必须注意，在状态面板上，切换到默认状态时，必须把值设置成PredfinedState.normal
    if (
      !isUndefined(currentStateID) ||
      currentStateID === PredefinedStates.normal ||
      _currentStateID === PredefinedStates.normal
    ) {
      return fullPath;
    }

    if (this.disabled && this.states['disabled']) {
      return `./states/disabled/${tx}`;
    }
    if (this.data.selected && this.states['checked']) {
      return `./states/checked/${tx}`;
    }
    return fullPath;
  }

  getStatePath = (state: string, path: string): string => {
    let tx = path;
    if (tx[0] === '/') {
      tx = tx.substring(1);
    }
    return `./states/${state}/${tx}`;
  };
  getFlipPath(flipModel: IFlipModel) {
    return flipModel === IFlipModel.Horizontal ? './flip/horizontal' : './flip/vertical';
  }
  getCurrentSizePath(forceUsedStatePath?: boolean): string {
    if (
      !this.currentStateID ||
      [CPath, CCompoundPath].includes(this.componentType) ||
      this.currentStateID === PredefinedStates.normal ||
      (!forceUsedStatePath && isPredefinedState(this.currentStateID))
    ) {
      return './size';
    }
    return `./states/${this.currentStateID}/size`;
  }

  getCurrentPositionPath(forceUsedStatePath?: boolean) {
    if (
      !this.currentStateID ||
      this.currentStateID === PredefinedStates.normal ||
      [CPath, CCompoundPath].includes(this.componentType) ||
      (!forceUsedStatePath && isPredefinedState(this.currentStateID))
    ) {
      return '/position';
    }
    return `./states/${this.currentStateID}/position`;
  }

  getCurrentRotatePath() {
    if (
      !this.currentStateID ||
      [CPath, CCompoundPath].includes(this.componentType) ||
      isPredefinedState(this.currentStateID)
    ) {
      return '/rotate';
    }
    return `./states/${this.currentStateID}/rotate`;
  }

  get currentStateID(): string | undefined {
    const { disabled, selected, _currentState, states } = this.data;
    if (_currentState === PredefinedStates.normal) {
      return undefined;
    }
    if (_currentState) {
      return _currentState;
    }
    if (disabled && states[PredefinedStates.disabled]) {
      return PredefinedStates.disabled;
    }
    if (selected && states[PredefinedStates.checked]) {
      return PredefinedStates.checked;
    }
    return undefined;
  }

  getCurrentStateIDByPreview(): string | undefined {
    let stateID = this.data._currentState;
    if (!stateID) {
      if (this.data.disabled) {
        stateID = PredefinedStates.disabled;
      } else if (this.data.selected) {
        stateID = PredefinedStates.checked;
      }
    }

    return stateID;
  }

  hasState(stateID: string): boolean {
    if (this.states) {
      return !isUndefined(this.states[stateID]);
    }
    return false;
  }

  hasActiveState(stateID: string) {
    if (stateID === PredefinedStates.normal) return true;
    if (this.states) {
      const state = this.states[stateID];
      if (!isUndefined(state)) {
        return (state as IComponentState).enabled;
      }
    }
    return false;
  }

  /**
   * 固定内容
   * 不同状态下只应用默认状态下的内容
   */
  get fixContent(): boolean {
    if (!this.canFixContent) {
      return false;
    }
    // 华为老数据默认勾选
    if (RP_CONFIGS.isHuaWei) {
      return this.data.fixContent !== false;
    }
    // 线上老数据默认不勾选
    return !!this.data.fixContent;
  }

  /**
   * 默认状态或非固定内容状态可以编辑富文本
   */
  public get canEditRichText() {
    return !this.fixContent || !this.currentStateID;
  }

  public get canFixContent(): boolean {
    return (
      [CRect, CEllipse, CPolygon, CLine, CText, CInput, CTextArea, CPath, CCompoundPath].includes(this.data.type) &&
      this.data.lib?.type !== CHotArea
    );
  }
}
