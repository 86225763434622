import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// 备注左
export default {
  ...flowBase,
  name: i18n('resource.flow.leftBrace'),
  type: 'leftBrace',
  thumb: {
    spriteIconClass: SpriteThumb.LeftBrace.className,
    dragPosition: SpriteThumb.LeftBrace.position,
  },
  template: getShapeData(
    i18n('resource.flow.leftBrace'),
    {
      width: 10,
      lockedRatio: false,
      height: 60,
    },
    {
      data: [
        {
          point: { x: 10, y: 60 },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
        },
        {
          point: { x: 6, y: 55 },
          handleIn: { x: 0, y: 5 },
          handleOut: { x: 0, y: -5 },
        },
        {
          point: { x: 6, y: 35 },
          handleIn: { x: 0, y: 5 },
          handleOut: { x: 0, y: -5 },
        },
        {
          point: { x: 0, y: 30 },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
        },
        {
          point: { x: 6, y: 25 },
          handleIn: { x: 0, y: 5 },
          handleOut: { x: 0, y: -5 },
        },
        {
          point: { x: 6, y: 5 },
          handleIn: { x: 0, y: 5 },
          handleOut: { x: 0, y: -5 },
        },
        {
          point: { x: 10, y: 0 },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
        },
      ],
      closed: false,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: false, closed: false }),
    };
  },
};
