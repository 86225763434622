import * as React from 'react';
import * as ReactDom from 'react-dom';
import classnames from 'classnames';

import { titleBarHeight } from '@/utils/envUtils';
import { dragDelegate } from '@utils/mouseUtils';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';

import { IPopupComponent, PopupManager } from '../withAutoClose';
import Icon from '../Icon';

import './index.scss';

export interface IDialogProp {
  children: any;
  onClose: () => void;
  onSubmit?: () => void;
  closable?: boolean;
  title?: string;
  titleClassName?: string;
  showTitleSeparator?: boolean;
  width?: number;
  height?: number;
  backFade?: boolean;
  allowDrag?: boolean;
  contentClassName?: string;
  topDisplay?: boolean;
  style?: React.CSSProperties;
  containerClassName?: string;
  stick?: boolean;
  transparent?: boolean;
}

export interface IDialogState {
  left: number;
  top: number;
  dragIng?: boolean;
}

class Dialog extends React.Component<IDialogProp, IDialogState> implements IPopupComponent {
  static defaultProps: Partial<IDialogProp> = {
    width: 500,
    allowDrag: true,
    closable: true,
    showTitleSeparator: false,
  };

  selfRef: React.RefObject<HTMLDivElement>;

  constructor(props: IDialogProp) {
    super(props);
    this.state = {
      left: 0,
      top: 0,
    };
    this.selfRef = React.createRef();
  }

  get isModal() {
    return true;
  }

  private focusedEl: Element | null = null;
  private selection?: { start: number; end: number };
  private timeOut?: Timeout;

  UNSAFE_componentWillMount() {
    // FIXME: 此处导致比如交互弹窗等处使用该组件时控制台提示错误，暂移到didMount
    // if (document.activeElement) {
    //   (document.activeElement as HTMLElement).blur();
    // }
  }

  componentDidMount() {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
    this.selfRef.current!.focus();
    window.addEventListener('keydown', this.handleWindowKeyDown);
    window.addEventListener('mouseup', this.handleWindowMouseUp);
    PopupManager.manager.addPopup(this);
    window.addEventListener('wheel', this.preventDefaultZoom, { capture: true, passive: false });
    this.focusedEl = document.activeElement;
    if (this.focusedEl instanceof HTMLInputElement || this.focusedEl instanceof HTMLTextAreaElement) {
      this.timeOut = window.setTimeout(() => {
        if (this.focusedEl) {
          const { selectionStart, selectionEnd } = this.focusedEl as HTMLInputElement | HTMLTextAreaElement;
          this.selection = {
            start: selectionStart!,
            end: selectionEnd!,
          };
        }
      }, 50);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleWindowKeyDown);
    PopupManager.manager.remove(this);
    window.removeEventListener('wheel', this.preventDefaultZoom, true);
    window.removeEventListener('mouseup', this.handleWindowMouseUp);
    window.clearTimeout(this.timeOut);
  }

  preventDefaultZoom = (e: WheelEvent) => {
    if (e.ctrlKey) {
      e.preventDefault();
    }
  };

  handleWindowMouseUp = () => {
    // 处理默认焦点对象的聚焦
    window.removeEventListener('mouseup', this.handleWindowMouseUp);
    if (this.focusedEl) {
      this.timeOut = window.setTimeout(() => {
        const currentActiveEl = document.activeElement;
        if (currentActiveEl && currentActiveEl === this.focusedEl) {
          return;
        }
        (this.focusedEl as HTMLElement).focus();
        if (
          this.focusedEl instanceof HTMLTextAreaElement ||
          (this.focusedEl instanceof HTMLInputElement && this.selection)
        ) {
          const { start, end } = this.selection!;
          this.focusedEl.setSelectionRange(start, end);
          this.selection = undefined;
        }
      }, 100);
    }
  };

  handleWindowKeyDown = (e: KeyboardEvent) => {
    const { onClose, onSubmit } = this.props;
    if (e.keyCode === KeyCodeMap.VK_ESCAPE) {
      if (PopupManager.manager.getLastModal() === this) {
        onClose && onClose();
      }
    } else if (e.keyCode === KeyCodeMap.VK_ENTER) {
      onSubmit && onSubmit();
    }
  };

  handleTitleMouseDown = () => {
    if (!this.props.allowDrag) {
      return;
    }
    const dom = this.selfRef.current!.children[0] as HTMLElement;
    const bounds = dom.getBoundingClientRect();
    const { offsetTop, offsetLeft } = dom;
    const winWidth = window.innerWidth;
    const winHeight = window.innerHeight;
    const { left, top } = this.state;
    const minLeft = 50 - bounds.width - offsetLeft;
    const maxLeft = winWidth - 50 - offsetLeft;
    const minTop = -offsetTop + titleBarHeight;
    const maxTop = winHeight - 50 - offsetTop;
    dragDelegate(
      (e, delta) => {
        const newLeft = Math.max(minLeft, Math.min(maxLeft, left + delta.x));
        const newTop = Math.max(minTop, Math.min(maxTop, top + delta.y));
        this.setState({ left: newLeft, top: newTop });
      },
      () => {
        this.setState({ dragIng: false });
      },
    );
  };

  private handleDialogEventHandle() {
    // e.stopPropagation();
    // e.preventDefault();
  }

  handleMouseWheel = (e: React.WheelEvent) => {
    if (this.props.backFade) {
      e.stopPropagation();
    }
  };

  renderTitle = () => {
    const { title, titleClassName, showTitleSeparator } = this.props;
    const hidden = !title;
    return (
      <div
        className={classnames(
          'title',
          titleClassName,
          { hidden: hidden },
          !hidden && (showTitleSeparator ? 'title-with-separator' : 'title-without-separator'),
        )}
        onMouseDown={this.handleTitleMouseDown}
      >
        {title && (
          <div className="title-value">
            <span>{title}</span>
          </div>
        )}
        <div className={classnames('separator', { hidden: hidden })} />
      </div>
    );
  };

  render() {
    const {
      children,
      title,
      width,
      height,
      onClose,
      backFade: fade,
      closable,
      contentClassName,
      topDisplay,
      style,
      containerClassName,
      stick,
      transparent,
    } = this.props;
    const { left, top } = this.state;
    return ReactDom.createPortal(
      <div
        ref={this.selfRef}
        className={classnames('dsm-c-rp-dialog popup-with-body', { fade, top: topDisplay }, containerClassName)}
        onMouseDown={this.handleDialogEventHandle}
        onClick={this.handleDialogEventHandle}
        onContextMenu={this.handleDialogEventHandle}
        onWheel={this.handleMouseWheel}
      >
        <div
          style={{ width, height, transform: `translate(${left}px,${top}px) translateZ(0)`, ...style }}
          className={classnames('dsm-c-rp-dialog-content', { transparent })}
        >
          <div className={classnames('dialog-panel-content')}>
            {closable && <Icon className="close-button" onClick={onClose} cls="close" />}
            {!transparent && this.renderTitle()}
            <div className={classnames('content', contentClassName, { fit: !title, stick })}>{children}</div>
          </div>
        </div>
      </div>,
      document.body,
    );
  }
}

export default Dialog;
