import { IComponentData } from '@fbs/rp/models/component';
import { ArtboardPatches, Ops, PagePatches } from '@fbs/rp/utils/patch';
import { mergePatches } from '@helpers/patchHelper';

import { UIContainerComponent, UIContentPanelComponent } from '@editor/comps';

import BasicComponentLib from '@libs/basic';
import { CContentPanelV2, CCanvasPanel } from '@libs/constants';
import { IUICompConstructOptions } from '@/customTypes';

/**
 * 内容面板组件UI类
 */
export default class UIContentPanelV2Component extends UIContentPanelComponent {
  public canMoveChildren = false;
  public $actionIndex: number = 0; // 演示交互下,切换当前索引

  constructor(data: IComponentData, public parent?: UIContainerComponent, public options?: IUICompConstructOptions) {
    super(data, parent, options);
    const defaultProperties = BasicComponentLib.make(CContentPanelV2).properties;
    if (data.properties) {
      data.properties = {
        ...data.properties,
        contentPanelLayout: Object.assign(
          defaultProperties.contentPanelLayout!,
          data.properties.contentPanelLayout || {},
        ),
        container: Object.assign(defaultProperties.container!, data.properties.container || {}),
      };
    }
  }

  /**
   * @override 处理类型问题
   */
  get value(): string[] {
    return super.value as string[];
  }

  changeLayout(newLayoutLine: number) {
    const groupID = this.type === 'artboard' ? 'ROOT' : this.id;
    return {
      do: {
        [groupID]: [Ops.replace(`/properties/contentPanelLayout`, { layoutLine: newLayoutLine })],
      },
      undo: {
        [groupID]: [
          Ops.replace(`/properties/contentPanelLayout`, {
            layoutLine: this.properties?.contentPanelLayout?.layoutLine,
          }),
        ],
      },
    };
  }

  moveChildOrder(oldIndex: number, newIndex: number): ArtboardPatches {
    let from = oldIndex;
    let to = newIndex;
    const groupID = this.type === 'artboard' ? 'ROOT' : this.id;
    return {
      do: {
        [groupID]: [Ops.move(`/components/${to}`, `/components/${from}`)],
      },
      undo: {
        [groupID]: [Ops.move(`/components/${from}`, `/components/${to}`)],
      },
    };
  }

  moveValue(oldIndex: number, newIndex: number): ArtboardPatches | null {
    const value = this.value;
    if (!value) {
      return null;
    }
    const values = [...(value as string[])];
    const currentIndex = newIndex;
    if (currentIndex < 0 || currentIndex >= values.length || oldIndex < 0 || oldIndex >= values.length) {
      return null;
    }
    if (currentIndex === oldIndex) {
      return null;
    }
    const tx = values[oldIndex];
    values.splice(oldIndex, 1);
    values.splice(currentIndex, 0, tx);
    return {
      do: {
        [this.id]: [Ops.replace('./value', values)],
      },
      undo: {
        [this.id]: [Ops.replace('./value', value)],
      },
    };
  }

  /**
   * 批量设置内容面板引用页面
   * @param {string} refValue
   */
  batchSetContentPanelRefValue(refValues: string[]) {
    let value: string[] = [...((this.value as string[]) ?? [])];
    if (value.length !== this.components.length) {
      value = this.components.map((c) => `${c.value}`);
    }
    const ownerArtboardID = this.ownerArtboardID;
    const batchPatch = refValues
      .map((refValue) => {
        if (value.indexOf(refValue) === -1) {
          value.push(refValue);
          const { patches } = this.addComponents([
            BasicComponentLib.make(CCanvasPanel, {
              components: [],
              properties: {
                container: {
                  showScroll: true,
                  scroll: true,
                  disabled: false,
                  hidden: true,
                },
              },
              value: refValue,
              selected: value.length === 1,
            }),
          ]);
          const p = this.setValue(value);
          Object.keys(p.do).forEach((id) => {
            if (patches[ownerArtboardID].do[id]) {
              patches[ownerArtboardID].do[id].push(...p.do[id]);
              patches[ownerArtboardID].undo[id].push(...p.undo[id]);
            } else {
              patches[ownerArtboardID].do[id] = p.do[id];
              patches[ownerArtboardID].undo[id] = p.undo[id];
            }
          });
          return patches;
        }
      })
      .filter((e) => e)
      .reduce(
        (prePatch, nextPatch) => {
          return mergePatches(prePatch, (nextPatch as PagePatches)[ownerArtboardID]);
        },
        { do: {}, undo: {} },
      );
    return batchPatch;
  }
}
