import { IMakePatternOptions } from '../type';

export const makePattern = (options: IMakePatternOptions): string => {
  const { uri, id, size, filter } = options;
  if (!uri) {
    return '';
  }
  const { width, height } = size;

  return `<pattern id="${id}" x="0" y="0" width="1" height="1">
        <image preserveAspectRatio="none" vector-effect="non-scaling-stroke"  width="${width}" height="${height}" ${filter} xlink:href="${uri}" />
        </pattern>`;
};
