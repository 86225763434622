/**
 * 文件夹 文件名重复情况处理
 * 重命名：名字+n
 */

interface INameCach {
  id: string;
  originalName: string;
  name: string;
}

enum FileType {
  Folder = 'folder',
  File = 'file',
}

export class UniqueName {
  private filenameCach: INameCach[] = [];
  private folderNameCach: INameCach[] = [];

  makeUniqueName = (folderNameCach: INameCach[], foldername: string, pageID: string, fileType: FileType) => {
    // 检查重名
    const tmp = folderNameCach.find((t) => t.name === foldername);
    if (tmp) {
      if (tmp.id === pageID && fileType === FileType.Folder) {
        // id相同 ,同一个页面中视为同一个文件
        return foldername.replace('$index', '');
      } else {
        // id不同，重名
        const num = folderNameCach.filter((t) => t.originalName === foldername).length + 1;
        const rename = foldername.replace('$index', '' + num); //+ num;
        folderNameCach.push({
          id: pageID,
          name: rename,
          originalName: foldername,
        });
        return rename;
      }
    }

    // 不重名
    folderNameCach.push({
      id: pageID,
      name: foldername,
      originalName: foldername,
    });
    return foldername.replace('$index', '');
  };

  clearFilenameCach = () => {
    this.filenameCach.length = 0;
  };

  clearFolderNameCach = () => {
    this.clearFilenameCach();
    this.folderNameCach.length = 0;
  };

  reset = () => {
    this.clearFolderNameCach();
  };

  makeFolderNmae = (foldername: string, pageID: string) => {
    return this.makeUniqueName(this.folderNameCach, foldername, pageID, FileType.Folder);
  };

  makeFilename = (filename: string, id: string) => {
    return this.makeUniqueName(this.filenameCach, filename, id, FileType.File);
  };
}

export const uniqueName = new UniqueName();
