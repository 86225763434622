// 上一个有效操作的时间
let lastHeartbeatTime = Date.now();

const onAFKCallbacks: Function[] = [];

export function heartbeat() {
  lastHeartbeatTime = Date.now();
}

// 添加 AFK 事件监听器
export function addAFKListener(onAFK: Function) {
  onAFKCallbacks.push(onAFK);
}

// 判断是否已经 AFK 了
export function isAfk(): boolean {
  return Date.now() - lastHeartbeatTime >= 60 * 60 * 1e3;
}

export function startAFKCheck() {
  let id = setInterval(() => {
    if (isAfk() && onAFKCallbacks.length > 0) {
      clearInterval(id);
      onAFKCallbacks.forEach(cb => {
        cb();
      });
    }
  }, 1e3 * 5);
}
