import * as React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';

import { UIComponent } from '@editor/comps';
import { IComponentData } from '@fbs/rp/models/component';
import { getNewID } from '@helpers/idHelper';

import { makeCommonComponent } from '../../helper';
import { makeCanvas } from '../Canvas';
import { CSelectPanel } from '../../constants';

import './index.scss';

interface ISelectPanelProps {
  comp: UIComponent;
}

function makeItem(index: number, selected: boolean = false) {
  const itemWidth = Math.round(375 / 4);
  return makeCanvas(getNewID(), {
    position: {
      x: itemWidth * index,
      y: 0,
    },
    size: {
      width: itemWidth,
      height: 30,
    },
    properties: {
      container: {
        scroll: true,
        showScroll: true,
        hidden: true,
      },
      layout: {
        direction: 'horizontal',
      },
    },
    selected,
  });
}

/**
 * 多选组容器
 * @param {string} id
 * @param {Partial<IComponentData>} data
 * @returns {IComponentData}
 */
export function makeSelectPanel(id: string, data?: Partial<IComponentData>): IComponentData {
  if (data) {
    return makeCommonComponent(id, CSelectPanel, data);
  }
  return makeCommonComponent(id, CSelectPanel, {
    size: {
      width: 375,
      height: 30,
    },
    properties: {
      layout: {
        direction: 'horizontal',
        verticalAlign: 'top',
        horizontalAlign: 'center',
        horizontalGap: 0,
        verticalGap: 0,
        hidden: true,
      },
    },
    components: [makeItem(0, true), makeItem(1), makeItem(2), makeItem(3)],
    select: {
      enabled: true,
      target: 'child',
      minCount: 0,
      maxCount: 1,
      reversible: true,
      autoUnselect: true,
    },
  });
}

/**
 * 它的子只能是面板
 */
export default class SelectPanel extends React.Component<ISelectPanelProps> {
  render() {
    const { comp } = this.props;
    const { properties, size } = comp;
    const { layout } = properties;
    const { direction, verticalAlign, horizontalAlign } = layout || {
      direction: 'vertical',
      verticalAlign: 'top',
      horizontalAlign: 'center',
    };
    const style: React.CSSProperties = {
      opacity: isUndefined(comp.opacity) ? 1 : comp.opacity / 100,
      transition: comp.getTransition(),
      ...size,
    };
    return (
      <div
        style={style}
        className={classnames(`lib-comp-select-panel ${verticalAlign || ''} ${horizontalAlign || ''}`, {
          vertical: direction === 'vertical',
          horizontal: direction === 'horizontal',
        })}
      >
        {this.props.children}
      </div>
    );
  }
}
