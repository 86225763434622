import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';

import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';
import { CFlowTriangle } from '../constants';

// 三角形
export default {
  ...flowBase,
  name: i18n('resource.flow.triangle'),
  type: CFlowTriangle,
  thumb: {
    spriteIconClass: SpriteThumb.Triangle.className,
    dragPosition: SpriteThumb.Triangle.position,
  },
  template: getShapeData(
    i18n('resource.flow.triangle'),
    {
      width: 100,
      lockedRatio: false,
      height: 60,
    },
    {
      data: [
        {
          point: { x: 0, y: 60 },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
        },
        {
          point: { x: 100, y: 60 },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
        },
        {
          point: { x: 50, y: 0 },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
        },
      ],
      closed: true,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
