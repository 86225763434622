/* eslint-disable */
// @ts-nocheck
/**
 * 示例  TODO: 必须在触发事件里执行
 * import myClipboard from 'Clipboard';
 * document.addEventListener('copy', function () {
     myClipboard.setBoard(1111);
     myClipboard.setBoard('string');
     myClipboard.setBoard({board:'111'});
     myClipboard.setBoard('<div>111</div>', 'html');
     myClipboard.setBoard('', 'mock-component');
   });
 * document.addEventListener('paste', function () {
     myClipboard.getBoard();
   });
 * 
 * 关于数据结构安全性方案
 *  一、 使用setBoard设置内容，方法设置一组text，一组html，text内容是外部内容，html为mock内容
 */

import i18n from '@i18n';

type Platform = 'mock' | undefined;
type DataType = 'text' | 'html' | 'file';
type ParseType = 'json' | 'text' | 'files' | 'html' | 'svgs' | MockType;
type MockType = 'mock-component' | 'mock-artboard' | 'mock-page' | 'mock-interaction';

enum ReadWriteRuleName {
  MockComponent = 'mock-component',
  MockArtboard = 'mock-artboard',
  MockPage = 'mock-page',
  MockInteraction = 'mock-interaction',
}

enum ErrorNumber {
  NumberType_1001 = '1001',
  NumberType_1002 = '1002',
  NumberType_1003 = '1003',
  NumberType_1004 = '1004',
  NumberType_1005 = '1005',
}

const Errors = {
  [ErrorNumber.NumberType_1001]: '写入剪切板错误',
  [ErrorNumber.NumberType_1002]: '读取剪切板错误',
  [ErrorNumber.NumberType_1003]: '没有写入权限',
  [ErrorNumber.NumberType_1004]: '没有读取权限',
  [ErrorNumber.NumberType_1005]: '数据类型不支持写入',
};

interface IRules {
  write: {
    [name: string]: (data: any) => ClipboardItem;
  };
  read: {
    [name: string]: (vals: any) => boolean | string | { [name: string]: string };
  };
}

const _write = function (type: string) {
  return function (this: MockClipboard, data: any) {
    if (typeof data !== 'object') {
      throw Errors[ErrorNumber.NumberType_1005];
    }
    const _data = `<div>${i18n('clipboard.safeCopyText')}</div><div><!--${type}>${JSON.stringify(
      data,
    )}<${type}--></div>`;
    this.clipboardData.setData('text/plain', i18n('clipboard.safeCopyText'));
    this.clipboardData.setData('text/html', _data);
    return new ClipboardItem({
      ['text/plain']: new Blob([i18n('clipboard.safeCopyText')], { type: 'text/plain' }),
      ['text/html']: new Blob([_data], { type: 'text/html' }),
    });
  };
};

class MockClipboard {
  static instance: MockClipboard;
  static platform?: Platform;
  protected copied: boolean = false;
  protected readType: ParseType[] = [];
  protected clipboardData: DataTransfer = new DataTransfer();
  protected userTypes = [];
  protected rules: IRules = {
    // 内建规则（不可修改），可通过setRules新增自己的规则
    write: {
      [ReadWriteRuleName.MockComponent]: _write(ReadWriteRuleName.MockComponent).bind(this),
      [ReadWriteRuleName.MockArtboard]: _write(ReadWriteRuleName.MockArtboard).bind(this),
      [ReadWriteRuleName.MockPage]: _write(ReadWriteRuleName.MockPage).bind(this),
      [ReadWriteRuleName.MockInteraction]: _write(ReadWriteRuleName.MockInteraction).bind(this),
    },
    read: {
      [ReadWriteRuleName.MockComponent]: function (vals: string) {
        const result = new RegExp(
          `<!--${ReadWriteRuleName.MockComponent}>(.*)<${ReadWriteRuleName.MockComponent}-->`,
          'ig',
        ).exec(vals);
        if (result && result[1].trim()) {
          return JSON.parse(result[1]);
        }
        return false;
      },
      [ReadWriteRuleName.MockArtboard]: function (vals: string) {
        const result = new RegExp(
          `<!--${ReadWriteRuleName.MockArtboard}>(.*)<${ReadWriteRuleName.MockArtboard}-->`,
          'ig',
        ).exec(vals);
        if (result && result[1].trim()) {
          return JSON.parse(result[1]);
        }
        return false;
      },
      [ReadWriteRuleName.MockPage]: function (vals: string) {
        const result = new RegExp(`<!--${ReadWriteRuleName.MockPage}>(.*)<${ReadWriteRuleName.MockPage}-->`, 'ig').exec(
          vals,
        );
        if (result && result[1].trim()) {
          return JSON.parse(result[1]);
        }
        return false;
      },
      [ReadWriteRuleName.MockInteraction]: function (vals: string) {
        const result = new RegExp(
          `<!--${ReadWriteRuleName.MockInteraction}>(.*)<${ReadWriteRuleName.MockInteraction}-->`,
          'ig',
        ).exec(vals);
        if (result && result[1].trim()) {
          return JSON.parse(result[1]);
        }
        return false;
      },
    },
  };

  constructor() {}

  // 判断是否支持系统剪切板
  public isSupport() {
    return false;
    // window.CLIP_DEBUG && console.log('调试暂勿删:是否支持该方法=======>', navigator.clipboard);
    // return Boolean(navigator.clipboard && navigator.clipboard.read && navigator.clipboard.write);
  }

  // 系统剪切板被当前类写入了数据则标记为true
  public isCopied() {
    // 防止外部引入丢失this出错
    if (this instanceof MockClipboard === false) {
      return false;
    }
    return this.copied;
  }

  // 获取当前系统剪切板写入值的类型，可用于判断是否存在的类型
  public async getTypes<T extends string>(str?: T): Promise<string[]> {
    // 防止外部引入丢失this出错
    if (this instanceof MockClipboard === false) {
      return [];
    }
    try {
      const data = await this.readAsync();
      if (data) {
        this.readType = Array.from(data.keys()).filter((item) => {
          const val = data.get(item);
          if (typeof val === 'object') {
            const str = JSON.stringify(val);
            return str !== '{}' && str !== '[]';
          } else {
            return val;
          }
        });
        return this.readType;
      } else {
        return [];
      }
    } catch (error) {
      window.CLIP_DEBUG && console.log(error);
      return [];
    }
  }

  public setRules<T, Y>() {}

  /**
   * 统一读取解析剪切板方法
   * 注意：
   *    系统剪切板内容类型目前只区分 text/plain、text/html、File
   *    只有text/html类型可被自定义解析
   */
  private parseClipboard(dataTranser: DataTransfer) {
    const parseMap: Map<ParseType, any> = new Map();
    for (const type of dataTranser.types) {
      const vals = dataTranser.getData(type);
      if (type.indexOf('text/plain') !== -1 && vals !== i18n('clipboard.safeCopyText')) {
        const svgs =
          vals
            .replaceAll('\r\n', '')
            .replaceAll('\n', '')
            .replaceAll('\t', '')
            .match(new RegExp('<svg.*?</svg>', 'g')) || [];
        if (svgs.length) {
          parseMap.set('svgs', svgs);
          // 目前RP有单独处理逻辑，使用这个剪切板策略的话，右键菜单如果粘贴文件会报错
          // parseMap.set(
          //   'files',
          //   svgs.map((item, i) => {
          //     // svg需要有xmlns属性才能被createObjectURL创建成功
          //     if (item.indexOf('xmlns') === -1) {
          //       item = item.replace('<svg', '<svg xmlns="http://www.w3.org/2000/svg"');
          //     }
          //     return blobToFile(i + '.svg', new Blob([item], { type: 'image/svg+xml' }));
          //   }),
          // );
        }
        try {
          parseMap.set('json', JSON.parse(vals));
        } catch (error) {
          parseMap.set('text', vals);
        }
      } else if (type.indexOf('text/html') !== -1) {
        const { read } = this.rules;
        const ruleReadKey = Object.keys(read);
        const isPartter = ruleReadKey.some((tag) => {
          const str = typeof read[tag] === 'function' ? read[tag](vals) : null;
          if (str) {
            parseMap.set(tag as ParseType, str);
            return true;
          }
          return false;
        });
        if (!isPartter) {
          parseMap.set('html', vals);
        }
      } else if (type.indexOf('Files') !== -1) {
        const files = Array.from(dataTranser.files);
        if (files.length) {
          parseMap.set('files', files);
        }
      } else {
        parseMap.set(type as any, vals);
      }
    }
    return parseMap;
  }

  /**
   * 同步写入， 这里同步方法只是一个兼容方法
   * @param data  any  任意数据
   * @param type  DataType 写入类型
   */
  private write(data: any, type: DataType) {
    try {
      this.writeAsync(data, type);
    } catch (err) {
      throw err;
    }
  }

  /**
   * 异步写入数据到系统剪切板
   * @param data ClipboardItem[]  剪切板类型的任意数据
   * @param type  DataType 将数据要存的类型
   * @returns Promise
   */
  private async writeAsync(data: any, type: DataType) {
    // TODO: 调试暂勿删
    window.CLIP_DEBUG && console.log('调试暂勿删:writeAsync=======>', data, type);
    const content: ClipboardItem[] = [];
    if (type === 'text') {
      const _data = typeof data === 'object' ? JSON.stringify(data) : data;
      const _type = 'text/plain';
      content.push(
        new ClipboardItem({
          [_type]: new Blob([_data], { type: _type }),
        }),
      );
      this.clipboardData.setData(_type, _data);
    } else if (type === 'html') {
      const _data = data;
      const _type = 'text/html';
      content.push(
        new ClipboardItem({
          [_type]: new Blob([_data], { type: _type }),
        }),
      );
      this.clipboardData.setData(_type, _data);
    } else if (type === 'file') {
      // 后续可能会支持
      // this.clipboardData.items.add(file);
      throw Errors[ErrorNumber.NumberType_1005];
    } else if (Object.keys(this.rules.write).includes(type)) {
      if (this.rules.write[type] instanceof Function) {
        const items = this.rules.write[type](data);
        content.push(items);
      }
    } else {
      throw Errors[ErrorNumber.NumberType_1005];
    }
    try {
      this.copied = true;
      return await window.navigator.clipboard.write(content);
    } catch (err) {
      window.CLIP_DEBUG && console.log('调试暂勿删:write错误=======>', err);
      throw Errors[ErrorNumber.NumberType_1001];
    }
  }

  /**
   * 同步解析剪切板数据
   * @param dataTranser DataTransfer  源数据，读取对象
   * @returns
   */
  private read(dataTranser: DataTransfer): Map<ParseType, any> {
    // 判断并使用临时剪切板内容
    if (this.clipboardData.types.length) {
      dataTranser = this.clipboardData;
      this.clipboardData = new DataTransfer();
    }
    try {
      return this.parseClipboard(dataTranser);
    } catch (err) {
      throw Errors[ErrorNumber.NumberType_1002];
    }
  }

  /**
   * 读取系统剪切板
   * 当有dataTranser时，说明用户是通过快捷键ctrl+v粘贴的
   * @param dataTranser  数据
   * @returns Promise<Map<string,any>> 返回剪切板内容
   */
  private async readAsync(dataTranser?: DataTransfer) {
    if (dataTranser) {
      try {
        return this.parseClipboard(dataTranser);
      } catch (err) {
        throw err;
      }
    }
    // 尝试使用navigator.clipboard读取剪切板内容
    // TODO: 调用navigator.clipboard后会使dataTranser数据被清空
    // 不支持图片、音频等文件类型粘贴
    try {
      const newDataTranser = new DataTransfer();
      if (window.navigator.permissions) {
        // 部分浏览器没有permissions
        const permission = await window.navigator.permissions.query({ name: 'clipboard-read' });
        document.documentElement.focus();
        const errors = [];
        if (permission.state === 'denied') {
          errors.push(Errors[ErrorNumber.NumberType_1004]);
        }
      }
      try {
        const clipboardItems = await window.navigator.clipboard.read();
        for (const item of clipboardItems) {
          for (const type of item.types) {
            if (type.indexOf('image') > -1) {
              newDataTranser.items.add(blobToFile('img', await item.getType(type)));
            } else {
              newDataTranser.setData(type, await blobToString(await item.getType(type)));
            }
          }
        }
      } catch (error) {
        errors.push(Errors[ErrorNumber.NumberType_1004]);
      }
      return this.parseClipboard(newDataTranser);
    } catch (err) {
      window.CLIP_DEBUG && console.log('调试暂勿删:read错误=======>', err);
      throw err;
    }
  }

  /**
   * 获取剪切板实例
   */
  static get clipboard(): MockClipboard {
    // if (this.instance instanceof MockClipboard === false) {
    //   this.instance = new MockClipboard();
    // }
    // return this.instance;
    // NOTE: 临时回退方案
    return {
      isSupport() {
        return false;
      },
    };
  }

  /**
   * 写入数据
   * @param data 任意数据
   * @param type  string 写入类型
   */
  static setBoard(data: any, type: string) {
    try {
      this.clipboard.write(data, type);
    } catch (err) {
      throw err;
    }
  }

  /**
   * 异步写入
   */
  static async setBoardAsync(data: any, type: string) {
    try {
      await this.clipboard.writeAsync(data, type);
    } catch (err) {
      throw err;
    }
  }

  /**
   * 读取剪切板数据
   * 只能通过事件触发并执行该方法
   * @param  event 系统事件，读取剪切板内容
   */
  static getBoard(event: ClipboardEvent = window.event): Map<ParseType, any> {
    if (event instanceof ClipboardEvent === false) {
      return new Map();
    }
    if (event.type !== 'paste') {
      return new Map();
    }
    const { clipboardData } = event;
    if (!clipboardData) {
      return new Map();
    }
    if (!MockClipboard.clipboard.isSupport()) {
      throw Errors[ErrorNumber.NumberType_1005];
    }
    // TODO: 调试暂勿删
    window.CLIP_DEBUG && console.log('调试暂勿删:getBoard=======>', event, clipboardData);
    try {
      return this.clipboard.read(clipboardData);
    } catch (err) {
      window.CLIP_DEBUG && console.log('error===>', err);
      throw err;
    }
  }

  /**
   * 异步读取
   * 异步读取可以不需要event
   */
  static async getBoardAsync(event?: Event) {
    const { clipboardData } = event || window.event || {};
    // TODO: 调试暂勿删
    window.CLIP_DEBUG && console.log('调试暂勿删:getBoardAsync=======>', event);
    if (!MockClipboard.clipboard.isSupport()) {
      throw Errors[ErrorNumber.NumberType_1005];
    }
    try {
      const result = await this.clipboard.readAsync(clipboardData);
      window.CLIP_DEBUG && console.log('调试暂勿删:getBoardAsync-result', result);
      return result;
    } catch (err) {
      window.CLIP_DEBUG && console.log('error===>', err);
      throw err;
    }
  }
}

/**
 * 将二进制数据转换为字符串
 * @param blobData Blob  二进制数据
 * @returns
 */
const blobToString: (blobData: Blob) => Promise<string> = async function (blobData: Blob) {
  const reader = new FileReader();
  blobData.type.includes('image') ? reader.readAsDataURL(blobData) : reader.readAsText(blobData, 'utf-8');
  return await new Promise(function (resolve, reject) {
    reader.onload = function () {
      resolve(reader.result as string);
    };
    reader.onerror = function () {
      reject();
    };
  });
};

const blobToFile = function (name: string, blobData: Blob) {
  const file = new File([blobData], name, { type: blobData.type });
  return file;
};

export default MockClipboard;
