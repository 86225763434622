/* ************************************************************
 * 时间切片。同步执行一些较多或较重的任务很容易导致JS线程长期被占用，不能将
 * 执行权交还渲染线程来更新界面，导致用户感受到卡顿。使用本模块可以将任务拆
 * 分成一个个执行小组，保证每个小组总执行时间不超过半帧，否则将执行权交还。
 ************************************************************ */

interface IExecutor {
  (done: () => void): void;
}

interface IDispose {
  (): void;
}

class Task {
  private _isDisposed = false;

  constructor(private _executor: IExecutor) {}

  public dispose(): void {
    this._isDisposed = true;
  }

  public execute(done: () => void): void {
    if (this._isDisposed) {
      done();
    } else {
      this._executor(() => {
        this.dispose();
        done();
      });
    }
  }
}

export class FrameTasksScheduler {
  public static HALF_FRAME_TIME = 1000 / 60 / 2;

  private _taskQueue: Task[] = [];
  public _isPending = false;
  private _timeoutId = -1;
  private _frameHandle = -1;

  constructor(private _label = '', private _maxFrameOccupancy = FrameTasksScheduler.HALF_FRAME_TIME) {}

  private resetTimeout(callback?: () => void) {
    window.clearTimeout(this._timeoutId);
    this._timeoutId = window.setTimeout(() => {
      callback?.();
    });
  }

  private resetAnimationFrame(callback?: () => void) {
    window.cancelAnimationFrame(this._frameHandle);
    this._frameHandle = window.requestAnimationFrame(() => {
      callback?.();
    });
  }

  public push(_executor: IExecutor): IDispose {
    const task = new Task(_executor);
    this._taskQueue.push(task);

    return () => {
      const index = this._taskQueue.findIndex((item) => item === task);
      if (index > -1) {
        const [task] = this._taskQueue.splice(index, 1);
        task.dispose();
      }
    };
  }

  /**
   * 注意：reset后，不会调用onfinish函数
   */
  public reset(): void {
    window.debug && console.log('task reset');
    this.suspend();
    while (this._taskQueue.length) {
      this._taskQueue.shift();
    }
  }

  /** 暂停任务调度 */
  public suspend(): void {
    window.debug && console.log('task suspend');
    this._isPending = false;
    this.resetTimeout();
    this.resetAnimationFrame();
  }

  /** 继续任务调度（该方法可以多次同步调用，但只在下一次的宏任务中执行一次） */
  public continue(onFinish?: () => void): void {
    this._isPending = true;
    this.resetTimeout(async () => {
      while (this._isPending) {
        if (this._taskQueue.length) {
          await this.performNextFrame();
        } else {
          this.suspend();
          onFinish?.();
        }
      }
    });
  }

  /** 注册一组任务到下一帧 */
  private performNextFrame(): Promise<void> {
    return new Promise((resolve) => {
      this.resetAnimationFrame(async () => {
        await this.performTasksUntilYield();
        resolve();
      });
    });
  }

  /** 持续取出任务并执行，直到总执行时间超过最大帧占用时间 */
  private async performTasksUntilYield(): Promise<void> {
    const startAt = Date.now();
    let [count, duration] = [0, 0];

    while (this._isPending && duration < this._maxFrameOccupancy) {
      const task = this._taskQueue.shift();
      if (task) {
        await new Promise<void>((resolve) => task.execute(resolve));
        duration = Date.now() - startAt;
        count += 1;
      } else {
        break;
      }
    }

    if (window.debug && this._label) {
      console.log(
        '[%s] 本帧执行任务 %d 个, 耗时 %d ms, 还剩 %d 个任务.',
        this._label,
        count,
        duration,
        this._taskQueue.length,
      );
    }
  }
}
// 定义成全局变量
export const scheduler = new FrameTasksScheduler(`export images`);
