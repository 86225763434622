/**
 * 字体较大，无法缓存在 storage中，存在内存中，下载完清空
 * 在多图片下载时候，相同文件不必多次下载
 *
 * 因为浏览器的 HTTP 缓存提供了最好和最健壮的机制来向浏览器提供字体资源缓存策略。
 * 所以你不应该使用 localStorage 或 IndexedDB 存储字体；他们每一个都多多少少的有自己的一些性能问题。
 */
class HttpCache {
  private fontCach: Map<string, Promise<string>> = new Map();

  /**
   * 缓存数据
   * @param {string} key
   * @param value
   */
  public save(key: string, value: Promise<string>): void {
    if (this.fontCach.has(key)) {
      return;
    }
    this.fontCach.set(key, value);
  }

  /**
   * 从缓存中获取数据
   * @template T
   * @param {string} key
   * @param {T} [defaultValue]
   * @returns {(T | null)}
   */

  public load(key: string): Promise<string> | undefined {
    return this.fontCach.get(key);
  }

  public clear() {
    this.fontCach.clear();
  }
}

export const httpCach = new HttpCache();
