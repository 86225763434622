import { IComponentData } from '@/fbs/rp/models/component';
import { makeBarChart } from '@libs/ChartComponents/SeriesChartOne/BarChart/config';
import { IUICompConstructOptions } from '@/customTypes';
import { UIComponent, UIContainerComponent } from '.';

export default class UIBarChartComponent extends UIComponent {
  constructor(data: IComponentData, parent?: UIContainerComponent, options?: IUICompConstructOptions) {
    const defaultData = makeBarChart(data._id);
    const { axis, dataLabel, ...restProperties } = defaultData.properties;

    // 适配兼容
    Object.assign(axis, {
      ...data.properties.axis,
      value: data.properties.axis?.value ?? !data.properties.axis?.disabled,
    });
    Object.assign(dataLabel, {
      ...data.properties.dataLabel,
      value: data.properties.dataLabel?.value ?? !data.properties.dataLabel?.disabled,
    });

    const adaptedProperties = {
      ...restProperties,
      ...data.properties,
      axis,
      dataLabel,
    };

    // 必须修改原数据的方式，保证修改和读取同源
    data.properties = adaptedProperties;

    super(data, parent, options);
  }
}
