import i18n from '@/i18n';
import { IComponentItem } from '@/libs/types';
import { CEllipse } from '@/libs/constants';
import { SpriteThumb } from '@/consts/spriteIcons';
import { UIComponent } from '@/editor/comps';
import { IComponentData } from '@/fbs/rp/models/component';
import { makeShapeBase, getShapeBaseDefaultData } from '../common/ShapeTextBase';

export const ellipseConfig: IComponentItem = {
  type: CEllipse,
  name: i18n('resource.components.circle'),
  shortCut: 'O',
  thumb: {
    spriteIconClass: SpriteThumb.Ellipse.className,
    dragPosition: SpriteThumb.Ellipse.position,
  },
  editor: {
    onValidateAllowEditor: (comp: UIComponent, trigger: 'enter' | 'dblClick'): 'path' | 'value' => {
      return trigger === 'dblClick' ? 'path' : 'value';
    },
  },
  getDefaultData() {
    return getShapeBaseDefaultData(true);
  },
};

export function makeEllipse(id: string): IComponentData {
  return makeShapeBase(id, CEllipse, true);
}
