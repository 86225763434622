import * as React from 'react';

import { SearchBox, Tree, TreeItemData } from '@/dsm';

import { IEmbedApp, IEmbedPage } from '@/fbs/rp/models/embedDesignDraft';
import { treeUniteType } from '..';
import { ComponentTheme } from '@/dsm/common';

import NoContent from '../NoContent';

interface ILeftWrapperProps {
  pages: TreeItemData<treeUniteType>[];
  pageTree: React.RefObject<Tree<IEmbedPage>>;
  onPagesSearch?: (value: string) => void;
  onRenderTreeItem?: (item: IEmbedApp | IEmbedPage) => JSX.Element;
  onPageTreeItemClick?: (e: React.MouseEvent, page: IEmbedPage) => void;
}

export default React.memo(function LeftWrapper(props: ILeftWrapperProps) {
  const { pages, pageTree, onPagesSearch, onRenderTreeItem, onPageTreeItemClick } = props;
  return (
    <div className="left-wrapper">
      <SearchBox onChange={onPagesSearch} />
      {pages.length > 0 ? (
        <Tree
          className="snapshot-page-tree"
          items={pages as TreeItemData<IEmbedPage>[]}
          itemRender={onRenderTreeItem}
          onItemClick={onPageTreeItemClick}
          prefixIndent={10}
          theme={ComponentTheme.light}
          ref={pageTree}
        />
      ) : (
        <NoContent />
      )}
    </div>
  );
});
