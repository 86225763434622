const UA = window.navigator.userAgent;

export const isMacOS = window.navigator.userAgent.indexOf('Macintosh') !== -1;

export const isCtrlKey = (e: { ctrlKey: boolean; metaKey: boolean }) => {
  return e.ctrlKey || e.metaKey;
};

export const isIE = (): boolean => {
  const { userAgent } = window.navigator;
  if ('ActiveXObject' in window) {
    return true;
  }
  // 判断是否Edge浏览器
  return userAgent.indexOf('Trident') > -1;
};

export const isFigma = (): boolean => {
  return /Figma/.test(UA);
};

export const isChrome = (): boolean => {
  return /Chrome/.test(UA);
};

export const isSafari = (): boolean => {
  return /Safari/.test(UA) && !isChrome();
};

export const isFirefox = () => {
  return /Firefox/.test(UA);
};

export const isMac = UA.indexOf('Macintosh') !== -1;

/**
 * 缓存数据
 * @param {string} key
 * @param value
 */
export function saveToCahche(key: string, value: any) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log(e);
  }
}

/**
 * 从缓存中获取数据
 * @param {string} key
 * @param {any} defaultValue
 * @returns {any | null}
 */
export function loadFromCache(key: string, defaultValue?: any): any | null {
  const value = window.localStorage.getItem(key);
  if (value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      console.error(`not find ${key} from cache`);
      return defaultValue;
    }
  }
  return defaultValue;
}

export const getMacCmdOrCtrl = (): string => {
  return isSafari() ? '^' : '⌘';
};

/**
 * keydown 快捷键监听
 * preventKeys 黏键禁用 [ctrl+1, ctrl+2]
 * return ctrl+1+2+3+4+...
 */
export const keydown = (preventKeys?: string[]) => {
  const UA = window.navigator.userAgent;
  const isMacOS = UA.indexOf('Macintosh') !== -1;
  const keysPressed: string[] = [];
  const keyIsNotModifierKey = (key: string) => ['control', 'shift', 'alt', 'meta'].indexOf(key.toLowerCase()) === -1;
  const isDeleteKey = (key: string) => (isMacOS ? key === 'backspace' : key === 'delete' || key === 'del');

  const setKey = (e: KeyboardEvent) => {
    const key = e.key.toLowerCase();
    if ((key === 'control' || key === 'meta') && keysPressed.indexOf('ctrl') === -1) {
      keysPressed.push('ctrl');
    }
    if (key === 'alt' && keysPressed.indexOf('alt') === -1) {
      keysPressed.push('alt');
    }
    if (key === 'shift' && keysPressed.indexOf('shift') === -1) {
      keysPressed.push('shift');
    }
    if (isDeleteKey(key) && keysPressed.indexOf('delete') === -1) {
      keysPressed.push('delete');
    } else if (
      keyIsNotModifierKey(e.key.toLocaleLowerCase()) &&
      keysPressed.indexOf(e.key.toLocaleLowerCase()) === -1
    ) {
      keysPressed.push(e.key.toLocaleLowerCase());
    }
    const keyStr = keysPressed.join('+').toLowerCase();
    if (preventKeys && preventKeys.indexOf(keyStr) !== -1) {
      e.preventDefault();
    }
    return keyStr;
  };

  const removeKey = (e: KeyboardEvent) => {
    const key = e.key.toLowerCase();
    if ((key === 'control' || key === 'meta') && keysPressed.indexOf('ctrl') !== -1) {
      keysPressed.splice(keysPressed.indexOf('ctrl'), 1);
    }
    if (key === 'alt' && keysPressed.indexOf('alt') !== -1) {
      keysPressed.splice(keysPressed.indexOf('alt'), 1);
    }
    if (key === 'shift' && keysPressed.indexOf('shift') !== -1) {
      keysPressed.splice(keysPressed.indexOf('shift'), 1);
    }
    if (isDeleteKey(key) && keysPressed.indexOf('delete') !== -1) {
      keysPressed.splice(keysPressed.indexOf('delete'), 1);
    } else if (
      keyIsNotModifierKey(e.key.toLocaleLowerCase()) &&
      keysPressed.indexOf(e.key.toLocaleLowerCase()) !== -1
    ) {
      keysPressed.splice(keysPressed.indexOf(e.key.toLocaleLowerCase()), 1);
    }
  };

  const getKeys = () => keysPressed;

  const clear = () => (keysPressed.length = 0);

  return {
    clear,
    setKey,
    removeKey,
    getKeys,
  };
};

export const copy = (text: string): boolean => {
  const textarea = document.createElement('textarea');
  textarea.textContent = text;
  textarea.style.position = 'fixed';
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand('copy');
    document.body.removeChild(textarea);
    return true;
  } catch (e) {
    console.warn('copy failed.');
  }
  document.body.removeChild(textarea);
  return false;
};

export const validateEmail = (email: string): boolean => {
  const reg =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
};

/**
 * 是否按下ctrl键,mac为meta键
 * @param e
 * @returns {boolean}
 */
const isControlKeyPressed = (e: KeyboardEvent): boolean => (isMacOS && e.metaKey) || (!isMacOS && e.ctrlKey);

const isDeleteKey = (key: string): boolean => (isMacOS ? key === 'backspace' : key === 'delete' || key === 'del');

const keyIsNotModifierKey = (key: string): boolean =>
  ['control', 'shift', 'alt', 'meta'].indexOf(key.toLowerCase()) === -1;

export const convertEventToHotKey = (e: KeyboardEvent): string => {
  const keysPress: string[] = [];
  const key = e.key?.toLocaleLowerCase();
  if (!key) {
    return '';
  }
  if (isControlKeyPressed(e)) {
    keysPress.push('ctrl');
  }
  if (e.altKey || key === 'shift') {
    keysPress.push('shift');
  }
  if (isDeleteKey(key)) {
    keysPress.push('delete');
  } else if (keyIsNotModifierKey(e.key)) {
    keysPress.push(e.key);
  }
  return keysPress.join('+').toLocaleLowerCase();
};
