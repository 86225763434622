import * as React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';

import { UIComponent } from '@editor/comps';
import { IComponentData } from '@fbs/rp/models/component';
import { getNewID } from '@helpers/idHelper';

import { makeCommonComponent } from '../../helper';
import { makeCanvas } from '../Canvas';
import { CStackPanel } from '../../constants';

import './index.scss';

interface IStackPanelProps {
  comp: UIComponent;
}

function makeItem() {
  return makeCanvas(getNewID(), {
    size: {
      width: 100,
      height: 30,
    },
    properties: {
      container: {
        scroll: false,
        showScroll: false,
        hidden: true,
      },
      layout: {
        direction: 'horizontal',
      },
    },
  });
}

export function makeStackPanel(id: string, data?: Partial<IComponentData>): IComponentData {
  if (data) {
    return makeCommonComponent(id, CStackPanel, data);
  }
  return makeCommonComponent(id, CStackPanel, {
    size: {
      width: 100,
      height: 300,
    },
    properties: {
      layout: {
        direction: 'vertical',
        verticalAlign: 'top',
        horizontalAlign: 'center',
        horizontalGap: 0,
        verticalGap: 0,
      },
    },
    components: [makeItem(), makeItem(), makeItem(), makeItem()],
  });
}

/**
 * 它的子只能是面板
 */
export default class StackPanel extends React.Component<IStackPanelProps> {
  render() {
    const { comp } = this.props;
    const { properties, opacity, size } = comp;
    const { layout } = properties;
    const { direction, verticalAlign, horizontalAlign } = layout || {
      direction: 'vertical',
      verticalAlign: 'top',
      horizontalAlign: 'center',
    };
    const alpha = isUndefined(opacity) ? 1 : opacity / 100;

    let style: React.CSSProperties = {
      opacity: alpha,
      ...size,
    };

    return (
      <div
        style={style}
        className={classnames(`lib-comp-stack-panel ${verticalAlign || ''} ${horizontalAlign || ''}`, {
          vertical: direction === 'vertical',
          horizontal: direction === 'horizontal',
        })}
      >
        {this.props.children}
      </div>
    );
  }
}
