import i18n, { languageManager } from '@/i18n';
import * as SystemsColor from '@consts/colors';
import { makeCommonComponent, getDefaultShadow } from '../../helper';
import { SpriteThumb } from '@/consts/spriteIcons';
import { TextAlign } from '@/fbs/rp/models/properties/text';
import { StrokeLineCap, StrokeLineJoin } from '@/fbs/rp/models/properties/stroke';
import { enumToArray } from '@/utils/enumUtils';
import { IComponentData } from '@/fbs/rp/models/component';
import { FillType } from '@/fbs/rp/models/properties/fill';
import { ArtboardPatches, Ops } from '@/fbs/rp/utils/patch';
import { PropertyValue } from '@/fbs/rp/models/property';
import { UIComponent } from '@/editor/comps';
import { PredefinedStates } from '@/consts/state';
import { EDatePickerType } from '@/fbs/rp/models/properties/common';

import { IComponentItem } from '../../types';
import { CDatePicker } from '../../constants';

export enum DeviceType {
  Web = 'web',
  Phone = 'phone',
}

// enum DateTheme {
//   Dark = 'dark',
//   Light = 'light',
// }

export enum DateFormat {
  Normal = 'normal',
  SecondStyle = 'secondStyle',
  ThirdStyle = 'thirdStyle',
  FourStyle = 'fourStyle',
}

export const DatePickerConfig: IComponentItem = {
  type: CDatePicker,
  thumb: {
    spriteIconClass: SpriteThumb.DatePicker.className,
    dragPosition: SpriteThumb.DatePicker.position,
  },
  predefinedStates: [PredefinedStates.disabled, PredefinedStates.hover, PredefinedStates.pressed],
  name: i18n('resource.components.datePicker'),
  property: {
    getValueNames: (key: string): { name: string; value: any }[] | undefined => {
      if (key === 'dateType') {
        return enumToArray(EDatePickerType).map((value) => {
          return {
            value,
            name:
              value === EDatePickerType.Normal
                ? i18n('property.component.dateType_normal')
                : i18n('property.component.dateType_range'),
          };
        });
      }
      if (key === 'deviceType') {
        return enumToArray(DeviceType).map((value) => {
          return {
            value,
            name: value === DeviceType.Web ? i18n('property.component.web') : i18n('property.component.phone'),
          };
        });
      }
      if (key === 'dateFormat') {
        if (languageManager.isEnVersion) {
          return [
            { value: DateFormat.Normal, name: i18n('property.component.dateformat_normal') },
            { value: DateFormat.SecondStyle, name: i18n('property.component.dateformat_secondStyle') },
            { value: DateFormat.FourStyle, name: i18n('property.component.dateformat_fourStyle') },
          ];
        } else {
          return [
            { value: DateFormat.Normal, name: i18n('property.component.dateformat_normal') },
            { value: DateFormat.SecondStyle, name: i18n('property.component.dateformat_secondStyle') },
            { value: DateFormat.ThirdStyle, name: i18n('property.component.dateformat_thirdStyle') },
          ];
        }
      }
      return undefined;
    },
  },
  initialization: (appType: string, data: IComponentData) => {
    if (['pad', 'phone'].includes(appType)) {
      data.properties.phoneDatePickerMark!.hidden = false;
      data.properties.showSelectedYear!.hidden = false;
      data.properties.deviceType!.value = DeviceType.Phone;
    } else {
      data.properties.showSelectedYear!.hidden = true;
      data.properties.phoneDatePickerMark!.hidden = true;
      data.properties.deviceType!.value = DeviceType.Web;
    }
  },
  editor: {
    onPropertyUpdate: onPropertyChange,
  },
  getDefaultData() {
    return {
      properties: {
        fill: {
          type: FillType.solid,
          color: SystemsColor.DefaultWhiteFillColor,
          disabled: false,
        },
        stroke: {
          thickness: 1,
          color: SystemsColor.DefaultStrokeColor1,
          cap: StrokeLineCap.Round,
          join: StrokeLineJoin.Round,
          disabled: false,
        },
        shadow: getDefaultShadow(),
        radius: {
          topRight: 2,
          topLeft: 2,
          bottomLeft: 2,
          bottomRight: 2,
          isPercent: false,
          disabled: false,
        },
        border: {
          disabled: true,
          top: true,
          left: true,
          right: true,
          bottom: true,
        },
        textStyle: {
          fontFamily: 'Microsoft YaHei',
          fontStyle: { underline: false, bold: false, strike: false, italic: false },
          fontSize: 14,
          color: SystemsColor.DefaultTextColor,
          textAlign: TextAlign.left,
        },
        deviceType: {
          prop: 'enum',
          name: i18n('property.propertyNames.deviceType'),
          value: DeviceType.Phone,
        },
        dateType: {
          prop: 'enum',
          name: i18n('property.propertyNames.dateType'),
          value: EDatePickerType.Normal,
        },
        // dateTheme: {
        //   prop: 'enum',
        //   name: i18n('resource.components.datePickerInfo.dateTheme'),
        //   value: DateTheme.Dark,
        // },

        dateFormat: {
          prop: 'enum',
          name: i18n('property.propertyNames.dateFormat'),
          value: DateFormat.Normal,
        },
        //手机端特有属性 遮罩与显示年份
        showSelectedYear: {
          name: i18n('property.propertyNames.showSelectedYear'),
          disabled: false,
          allowDisabled: true,
          hidden: true,
        },

        phoneDatePickerMark: {
          prop: 'color',
          name: i18n('property.propertyNames.showMark'),
          value: SystemsColor.ShadowColor,
          disabled: false,
          allowDisabled: true,
          hidden: true,
        },

        placeholder: {
          prop: 'string',
          name: i18n('property.propertyNames.placeholder'),
          value: i18n('resource.components.datePickerInfo.optionDate'),
          disabled: false,
          hidden: false,
        },
        placeHolderStyle: {
          prop: 'color',
          name: i18n('property.propertyNames.placeholderColor'),
          value: SystemsColor.GrayColor,
        },
        datePickerIcon: {
          name: i18n('property.propertyNames.icon'),
          prop: 'color',
          value: SystemsColor.DefaultTextColor,
          disabled: false,
          allowDisabled: true,
        },
        iconSize: {
          prop: 'number',
          name: i18n('property.propertyNames.iconSize'),
          value: {
            min: 10,
            max: 100,
            step: 1,
            value: 14,
          },
          hidden: false,
        },
        padding: {
          left: 6,
          top: 0,
          right: 6,
          bottom: 0,
          disabled: false,
        },
      },
    };
  },
};

export function onPropertyChange(
  comp: UIComponent,
  propertyName: string,
  propValue: PropertyValue,
): ArtboardPatches | null {
  if (propertyName === 'deviceType') {
    return {
      do: {
        [comp.id]: [
          Ops.replace('/properties/phoneDatePickerMark/hidden', propValue.value !== DeviceType.Phone),
          Ops.replace('/properties/showSelectedYear/hidden', propValue.value !== DeviceType.Phone),
        ],
      },
      undo: {
        [comp.id]: [
          Ops.replace('/properties/phoneDatePickerMark/hidden', propValue.value === DeviceType.Phone),
          Ops.replace('/properties/showSelectedYear/hidden', propValue.value === DeviceType.Phone),
        ],
      },
    };
  }
  if (propertyName === 'dateType') {
    return {
      do: {
        [comp.id]: [
          Ops.replace(
            '/properties/placeholder/value',
            propValue.value === EDatePickerType.Normal
              ? i18n('resource.components.datePickerInfo.optionDate')
              : i18n('resource.components.datePickerInfo.optionDateRange'),
          ),
          Ops.replace('/properties/placeholder/hidden', propValue.value === EDatePickerType.Range),
        ],
      },
      undo: {
        [comp.id]: [
          Ops.replace(
            '/properties/placeholder/value',
            propValue.value === EDatePickerType.Normal
              ? i18n('resource.components.datePickerInfo.optionDateRange')
              : i18n('resource.components.datePickerInfo.optionDate'),
          ),
          Ops.replace('/properties/placeholder/hidden', propValue.value !== EDatePickerType.Range),
        ],
      },
    };
  }
  if (propertyName === 'datePickerIcon') {
    return {
      do: {
        [comp.id]: [Ops.replace('/properties/iconSize/hidden', propValue.disabled === true)],
      },
      undo: {
        [comp.id]: [Ops.replace('/properties/iconSize/hidden', propValue.disabled !== true)],
      },
    };
  }
  return null;
}

export function makeDatePicker(id: string): IComponentData {
  return makeCommonComponent(id, CDatePicker, {
    ...DatePickerConfig.getDefaultData?.(),
    size: {
      width: 150,
      height: 30,
    },

    text: '',
  });
}
