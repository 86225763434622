import { IComponentData } from '@fbs/rp/models/component';
import IArtboard from '@fbs/rp/models/artboard';
import { HorizontalAlign, VerticalAlign } from '@/fbs/rp/models/layout';

import { IUICompConstructOptions } from '@/customTypes';
import {
  CGroup,
  CSymbol,
  CStackPanel,
  CWrapPanel,
  CGridPanel,
  CContentPanel,
  CContentPanelV2,
  CListLayoutPanel,
  CSelectPanel,
  CMultipleSelect,
  CCanvasPanel,
  CConnector,
  CCompoundPath,
  CTable,
  CTree,
  CTreeItem,
  CLine,
  CRect,
  CEllipse,
  CPolygon,
  CPath,
  CText,
  CPureText,
  CInput,
  CTextArea,
  CBarChart,
  CPieChart,
  CNavigationMenu,
  CVerticalMenu,
  CHorizontalMenu,
  CVideo,
  CArtboard,
  CCollapse,
} from '@libs/constants';

import {
  UIComponent,
  UIContainerComponent,
  UIPanelComponent,
  UIGroupComponent,
  UIStackPanelComponent,
  UIWrapPanelComponent,
  UIGridPanelComponent,
  UIContentPanelComponent,
  UIContentPanelV2Component,
  UIListLayoutSealedComponent,
  UISelectPanelComponent,
  UIConnectorComponent,
  UICompoundPathComponent,
  UISymbolComponent,
  UITableComponent,
  UITreeComponent,
  UITreeItemComponent,
  UIShapeComponent,
  UITextComponent,
  UIPureTextComponent,
  UIChartBarComponent,
  UIChartPieComponent,
  UIMultipleSelectPanelComponent,
  UINavigationMenuComponent,
  UIHorizontalMenuComponent,
  UIVerticalMenuComponent,
  UIMediaComponent,
  UICollapseComponent,
} from '.';
import { Orientation } from '@/fbs/common/models/common';

type UIComponentClass = typeof UIComponent;

let compFactory: { [key: string]: UIComponentClass };

function getFactory(type: string): UIComponentClass {
  if (!compFactory) {
    compFactory = {
      [CGroup]: UIGroupComponent,
      [CSymbol]: UISymbolComponent,
      [CStackPanel]: UIStackPanelComponent,
      [CWrapPanel]: UIWrapPanelComponent,
      [CGridPanel]: UIGridPanelComponent,
      [CContentPanel]: UIContentPanelComponent,
      [CContentPanelV2]: UIContentPanelV2Component,
      [CListLayoutPanel]: UIListLayoutSealedComponent,
      [CSelectPanel]: UISelectPanelComponent,
      [CMultipleSelect]: UIMultipleSelectPanelComponent,
      [CCanvasPanel]: UIPanelComponent,
      [CConnector]: UIConnectorComponent,
      [CCompoundPath]: UICompoundPathComponent,
      [CTable]: UITableComponent,
      [CTree]: UITreeComponent,
      [CTreeItem]: UITreeItemComponent,
      [CLine]: UIShapeComponent,
      [CRect]: UIShapeComponent,
      [CEllipse]: UIShapeComponent,
      [CPolygon]: UIShapeComponent,
      [CPath]: UIShapeComponent,
      [CText]: UITextComponent,
      [CPureText]: UIPureTextComponent,
      [CInput]: UIPureTextComponent,
      [CTextArea]: UIPureTextComponent,
      [CBarChart]: UIChartBarComponent,
      [CPieChart]: UIChartPieComponent,
      [CNavigationMenu]: UINavigationMenuComponent,
      [CVerticalMenu]: UIVerticalMenuComponent,
      [CHorizontalMenu]: UIHorizontalMenuComponent,
      [CVideo]: UIMediaComponent,
      [CCollapse]: UICollapseComponent,
    };
  }
  return compFactory[type];
}

export function makeUIComponent(
  comp: IComponentData,
  parent?: UIContainerComponent,
  options?: IUICompConstructOptions,
): UIComponent {
  const type = comp?.type;
  const Component = getFactory(type);
  if (Component) {
    return new Component(comp, parent, options);
  }
  if (comp?.sealed) {
    return new UIPanelComponent(comp, parent, options);
  } else if (comp?.alias === CTreeItem) {
    return new UITreeItemComponent(comp, parent, options);
  } else {
    return new UIComponent(comp, parent, options);
  }
}

/**
 * 清理组件中的无效数据
 * @param {IComponentData[]} comps
 * @author Matt
 * @date 2020-12-23
 */
function filterInvalidateData(comps: IComponentData[]) {
  const validateDatas = comps.filter((comp) => !!comp);
  if (comps.length !== validateDatas.length) {
    comps.splice(0, comps.length, ...validateDatas);
  }
}

export function makeComponents(
  comps: IComponentData[],
  parent: UIContainerComponent,
  options?: IUICompConstructOptions,
): UIComponent[] {
  filterInvalidateData(comps);
  return comps.map((comp) => makeUIComponent(comp, parent, options));
}

export interface ICreateArtboardInfo {
  id: string;
  appID: string;
  nodeID: string;
  name: string;
  position: {
    x: number;
    y: number;
  };
  size: {
    width: number;
    height: number;
  };
  orientation?: Orientation;
  userID: number;
  type: 'main' | 'fragment';
  ownerID?: string;
}

// 创建画板
export function createArtboard(info: ICreateArtboardInfo): IArtboard {
  return ({
    _id: info.id,
    appID: info.appID,
    background: undefined,
    components: [],
    imageURL: '',
    interaction: {},
    latestRevisionID: '',
    modified: false,
    name: info.name,
    nodeID: info.nodeID,
    position: info.position,
    responsive: false,
    size: info.size,
    type: info.type,
    userID: info.userID,
    updatedBy: info.userID,
    ownerID: info.ownerID,
    state: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  } as unknown) as IArtboard;
}

// 创建假的画板
export function createFakeArtboard(): IArtboard {
  const artboard: IArtboard = {
    _id: '',
    appID: '',
    nodeID: '',

    position: { x: 0, y: 0 },
    size: { width: 0, height: 0 },
    type: CArtboard,
    interaction: {},
    responsive: false,
    components: [],
    latestRevisionID: '',
    variables: [],
    modified: false,
    imageURL: '',
    imageCreatedAt: new Date(),
    userID: 0,
    layout: {
      responsive: false,
      auto: true,
      horizontal: HorizontalAlign.Auto,
      vertical: VerticalAlign.Auto,
      fixedWidth: false,
      fixedHeight: false,
    },
    properties: {},
    states: {},
    connectors: [],
    v: 0,
    artboardID: '',
  };

  return artboard;
}
