import * as React from 'react';

import i18n from '@/i18n';

import * as noContentImg from '@/assets/image/snapshot-no-content.png';

export default React.memo(function NoContent() {
  return (
    <div className="no-content">
      <div>
        <img src={noContentImg} alt="no-content" />
        <p>{i18n('resource.componentsText.noContent')}</p>
      </div>
    </div>
  );
});
