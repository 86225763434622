import { ErrorCode } from '@fbs/teamManagement';

export enum ActionType {
  App_GetApp = '[app] get-app',
  App_AddNode = '[app] add-node',
  App_CloneNode = '[app] clone-node',
  App_NodeGroup = '[app] node-group',
  App_NodeUnGroup = '[app] node-ungroup',
  App_InsertNode = '[app] insert-node',
  App_UpdateNodes = '[app] update-nodes',
  App_PatchNode = '[app] patch-node',
  App_LoadArtboards = '[app] load-artboards',
  App_SelectNode = '[app] select-node',
  App_ArtboardSelectSync = '[app] select-sync',
  App_Tree_UpdateSelectedIDs = '[app] tree_update_selected',
  App_Tree_UpdateExpandedIDs = '[app] tree_update_expanded',
  App_Tree_UpdateSearchExpandedIDs = '[app] tree_update_search_expanded',
  App_Tree_HidePage = '[app] tree_hide_page',
  // App_RemoveArtboard = '[app] remove-artboard',
  App_UpdateCoopers = '[app] update-coopers',
  App_KillCoopers = '[app] kill-coopers',
  App_DownloadArtboard = '[app] download-artboard',
  APP_RefreshPage = '[app] refresh-page',
  App_Tree_UpdateTrashSelectedIDs = '[app] tree_update_trash_selected',
  App_ChangeProjectSize = '[app] change_project_size',
  App_Patch_Info = '[app] patch info',
  App_LoadAllPrototypeApps = '[app] load all prototype apps',
  App_ShowWater = '[app] show water',
  App_IsNotExist = '[app] app is not exist',
  App_Tree_Quick_Select_Next_Node = '[App_Tree] quick selected node',
  App_MoveAPP = '[app] move app',
  App_RefreshIdocApp = '[app] refresh idoc app',
  App_CoopersSelectedIds = '[app] coopers selected ids',
  App_Tree_In_Switch_Page = '[app] tree_in_switch_page',

  IO_UPDATE = '[io] update',

  Preview_GetApp = '[preview] get-app',
  Preview_LoadArtBoard = '[preview] load-artboard',
  Preview_ReloadPage = '[preview] reload-page',
  Preview_AfterSkipToPage = '[preview] skip-to-page',
  Preview_SelectNode = '[preview] select-node',
  Preview_Exit = '[preview] exit',
  Preview_beforeSkipToPage = '[preview] before-skip-to-page',
  Preview_goBackward = '[preview] go-backward',
  Preview_goForward = '[preview] go-forward',
  Preview_returnHome = '[preview] go-home',
  Preview_showFragment = '[preview] show-fragment',
  // Preview_exitFragment = '[preview] exit-fragment',
  Preview_showMessage = '[preview] show-message',
  Preview_showMessageWithId = '[preview] show-message-with-id',
  Preview_hideMessage = '[preview] hide-message',
  Preview_hideMessageWithId = '[preview] hide-message-with-id',
  Preview_toLogIn = '[preview] to-log-in',
  Preview_PwdEnabled = '[preview] app pwd enabled',
  Preview_resetLink = '[preview] reset link ',
  Preview_resetShortLink = '[preview] reset short link ',
  Preview_StartRequestLink = '[preview] start request link',
  Preview_EndRequestLink = '[preview] end request link',
  // Preview_checkShareLinkReset = '[preview] check share link has reset',
  Preview_setCurrLinkID = '[preview] get current linkID',
  Preview_hasShareLinkReset = '[preview] check share link has reset',
  Preview_ProjectExist = '[preview] check the project exist',
  Preview_ShowWater = '[preview] show water',
  Preview_SetIDocAppShareMsg = '[preview] set iDoc app share msg',
  Preview_SetQueryLinkId = '[preview] set query link id',

  Global_Toast = '[global] toast',
  Global_Close_Toast = '[global] close toast',
  Global_IO_Message = '[global] io-message',
  Global_SOCKET_STATE_CHANGE = '[global] socket-state-change',
  Global_NETWORK_STATE_CHANGE = '[global] network-state-change',
  Global_AFK = '[global] afk',
  Global_Waiting = '[global] waiting',
  Global_Offline_Sync = '[global] offline-sync',
  Global_Remote_Login = '[global] remote login',
  Global_Login_Expired = '[global] login expired',
  Global_ErrorCode = '[global] error code',
  Global_Loss_Of_Project_Permission = '[global] loss of project permission',
  Global_Log_Off_Team = '[global] log off team',
  Global_Show_We_Meet_Limit_Tip_Dialog = '[global] show we meet tip dialog',
  Global_Dialog = '[global] dialog',
  Global_Push_Dialog_To_Queue = '[global] push dialog to queue',
  Global_Pop_Dialog_From_Queue = '[global] pop dialog from queue',
  Global_Login_Out = '[global] login out',

  Lib_LoadLib = '[Lib] load-app',
  Lib_LoadLibs = '[Lib] load-libs',
  Lib_LoadError = '[Lib] load-libs-error',
  Lib_AddLib = '[Lib] add-lib',
  Lib_UpdateLib = '[Lib] update-lib',
  Lib_AddColor = '[Lib] add-color',
  Lib_AddTypography = '[Lib] add-typography',
  Lib_AddComponent = '[Lib] add-component',

  Lib_PatchColorItem = '[Lib] patch-color-item',
  Lib_PatchComponentItem = '[Lib] patch-component-item',
  Lib_PatchTypographyItem = '[Lib] patch-typography-item',

  Lib_RemoveLibItem = '[Lib] remove-lib-item',
  Lib_ResourceItemIndexChanged = '[Lib] item-index-changed',
  Lib_SwitchLib = '[Lib] switch-lib',

  Lib_AddGroup = '[Lib] add-group',
  Lib_RemoveGroup = '[Lib] remove-group',
  Lib_RenameGroup = '[Lib] rename-group',
  Lib_MoveGroup = '[Lib] move-group',
  Lib_ResetGroup = '[Lib] reset-group',
  Lib_RemoveAfterMoveGroup = '[Lib] remove-after-move-group',
  Lib_RemoveComponents = '[Lib] remove-components',
  Lib_PatchComponents = '[Lib] patch-components',

  Lib_AddRelations = '[Lib] add-relations',
  Lib_PatchRelations = '[Lib] patch-relations',
  Lib_RemoveRelations = '[Lib] remove-relations',

  User_Preference_Load = '[User] load preference',
  User_Preference_Patch = '[User] patch preference',

  Page_Revisions_Load = '[Revisions] load',
  Page_Revisions_revert = '[Revisions] revert',
  Page_Revisions_loadArtboard = '[Revisions] load artboard',
  Page_Revisions_loadArtboardFragments = '[Revisions] load artboardFragments',
  Page_Revision_Switch = '[Revisions] switch',
  Page_Revision_Waring = '[Revisions] waring',
  Page_Version_Delete = '[Revisions] delete',
  Page_Version_clear = '[Revisions] clear',
  Page_Version_rename = '[Revisions] rename',
  Page_Version_create = '[Revisions] create',

  User_GetUserInfo = '[USER] get user info',
  User_GetSession = '[USER] get sission',
  User_NotSignIn = '[User] not signin',

  User_RecallCancellation = '[User] recall cancellation',
  Team_GetTeamInfo = '[TEAM] get team info',
  PD_GetLicense = '[PD] get license info',
  // Team_GetTeamAppCount = '[TEAM] get team app count',

  Share_GetTeamInfo = '[Share] get team info',
  Share_GetUserInfo = '[Share] get user info',

  Share_GetRunLink = '[Share] get run link',
  Share_GetRunLinkDone = '[Share] get run link done',
  Share_GetRunLinkError = '[Share] get run link error',

  Share_SetLinkPass = '[Share] set link pass',
  Share_SetLinkPassDone = '[Share] set link pass done',
  Share_SetLinkPassError = '[Share] set link pass error',
  Share_SetLinkNodeIDs = '[Share] set link nodeIDs',

  Share_GetLinkPass = '[Share] get link pass',
  Share_GetLinkPassDone = '[Share] get link pass done',
  Share_ValidatePasswordError = '[Share] validate password error',

  Share_GetPwEnableDone = '[Share] get password enable',

  Share_ChangePwEnable = '[Share] change password enable',
  Share_ChangePwEnableDone = '[Share] change password enable done',
  Share_ChangePwEnableError = '[Share] change password enable error',

  Share_ChangeValidatePassword = '[Share] change validate pwd',

  User_Login = '[User] login',
  User_Logout = '[User] logout',
  User_Patch_Token = '[User] patch token',
  User_Patch_Info = '[User] patch info',
  Team_AllTeams = '[Team] all teams',
  Team_SwitchTeam = '[Team] switch team',
  // Team_ChangeStatus = '[Team] change status',
  Team_Create = '[Team] create',
  Team_Patch = '[Team] patch',
  Team_AIInfo = '[app] team_aiInfo',

  Tag_Add = '[Tag] add',
  Tag_ALL_Tags = '[Tag] get all tags',

  AppSet_All = '[AppSet] all',
  AppSet_AddAppSet = '[AppSet] add appset',
  AppSet_AddApp = '[AppSet] add app',
  AppSet_RemoveAppSet = '[AppSet] remove appSet',
  AppSet_RemoveApp = '[AppSet] remove app',
  AppSet_MoveApp = '[AppSet] move app',
  AppSet_Recycle = '[AppSet] recycle',
  AppSet_Restore = '[AppSet] restore',
  AppSet_ClearFromRecycle = '[AppSet] clrear from recycle',
  AppSet_Star = '[AppSet] star',
  AppSet_PatchOrder = '[AppSet] patch order',
  AppSet_Clear_Apps = '[AppSet] clear apps',
  AppSet_Patch = '[AppSet] Patch',
  AppSet_InsertRecent = '[AppSet] insert recent',

  Example_Load_All = '[Example] load all',
  Example_Page_Down = '[Example] page down',
  Example_SetAllTeams = '[Example] set all teams',
  Example_GetAppSetsByTeam = '[Example] get appSets by team',
  Example_SetDefaultTeam = '[Example] set default team',
  Example_CreateTeam = '[Example] create team',

  ShareApp_GetLink = '[Share App] get link',
  ShareApp_GetPublicLink = '[Share App] get public link',
  ShareApp_Patch = '[Share App] patch',
  ShareApp_ResetLink = '[Share App] reset link',
  ShareApp_LoadEmbedShareLink = '[Share App] load embed share link',
  // ShareApp_ResetEmbedShareLink = '[Share App] reset embed share link',
  ShareApp_Waiging = '[Share App] waiting',

  // Activity_Invitation_All = '[Activity] all invitation',
  // Activity_Invitation_Progress_Info = '[Activity] invitation progress info',
  // Activity_Upgrade = '[Activity] upgrade',
  // Activity_Link_Token = '[Activity] Link Token',

  Message_Get_UnreadCount = '[Message] get unread count',
  Message_Read_App = '[Message] read app message',
  Message_Read_App_About_Me = '[Message] read app message about me',
  Message_Resolve_Join = '[Message] read join app message',
  Message_Read_All = '[Message] read all message',
  Message_Read_All_About_Me = '[Message] read all message about me',

  Member_Load_All = '[Member] load team members',
  Member_Load_App_Members = '[Member] load app members',
  Member_Update_App_Members = '[Member] update app members',
  Member_Update_App_Groups = '[Member] update app groups',

  Invitation_Load_Link = '[invitation] load link',
  Invitation_Patch_Link = '[invitation] patch link',
  Invitation_Patch_Group = '[invitation] patch group',

  Department_Load_List = '[department] load list',

  Publish_GetAllTeams = '[Publish] get teams',
  Publish_GetAppsFromTeam = '[Publish] get apps',
  Publish_GetPrototypesFromApp = '[Publish] get prototypes',
  Publish_SwitchTeam = '[Publish] switch team',
  Publish_SwitchApp = '[Publish] switch app',
  Publish_SwitchPrototype = '[Publish] switch prototype',
  Publish_CreateApp = '[Publish] create app',
  Publish_CreatePrototype = '[Publish] create prototype',
  Publish_Error = '[Publish] error',
  Publish_Success = '[publish] success',
  Publish_PrototypeReleaded = '[publish] prototype released',
  Publish_Task = '[publish] task',
  Publish_GetAppsFromTeamByPersonalSpace = '[Publish] get apps of personal space',

  Popularize_Loaded = '[Popularize] loaded',

  //第三方组件库
  CustomComponentLibs_SetComponentsLibs = '[CustomComponentLibs] set components',
  CustomComponentLibs_UpdateComponentsDataMap = '[CustomComponentLibs] update components data map',
}

export interface Action<T extends ActionType> {
  type: T;
}

export interface ActionWithPayload<T extends ActionType, P> extends Action<T> {
  payload: P;
}

export function createAction<T extends ActionType>(type: T): Action<T>;
export function createAction<T extends ActionType, P>(type: T, payload: P): ActionWithPayload<T, P>;
export function createAction<T extends ActionType, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { type, payload };
}

type FunctionType = (...args: any[]) => any;
type ActionCreatorsMapObject = { [actionCreator: string]: FunctionType };

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>;

export function appIsNotExits(code: ErrorCode) {
  return [ErrorCode.AppDestroy].includes(code);
}

export function noPermission(code: ErrorCode) {
  return [
    ErrorCode.NotMember,
    ErrorCode.HasNoRight,
    ErrorCode.NotAppMember,
    ErrorCode.HasNoRightInApp,
    ErrorCode.NeedJoinTeam,
    ErrorCode.TeamDestroy,
  ].includes(code);
}

export function noSignIn(code: ErrorCode) {
  return code === ErrorCode.Unauthorized;
}

export function notFound(code: ErrorCode) {
  return code === ErrorCode.NotFound;
}

// 用于全局的dialog显示
export enum DialogType {
  none = 'none',
  // 移动项目dialog
  moveProject = 'moveProject',
  // 移动项目受限，没有可移动的目标团队
  moveLimit = 'moveLimit',
  // 移动项目目标团队项目上限
  fullProject = 'fullProject',
  // 创建项目数量上限
  fullApp = 'fullApp',
  // 创建页面数量上限
  fullPage = 'fullPage',
  // 模板例子 -- 创建团队
  createTeam = 'createTeam',
  // 模板例子 -- 保存例子项目至团队
  saveProject = 'saveProject',
  // 保存模板例子，提示去升级
  upgradeSaveExample = 'upgrade-save-example',
  // 导出图片提示升级
  upgradeExportPicture = 'upgradeVersion',
  // 导出PDF提示升级
  upgradeExportPDF = 'upgradeExportPDF',
  // 分享资源提示升级
  upgradeResources = 'upgradeResources',
  // 导出画板提示升级
  upgradeExportArtboard = 'upgradeExportArtboard',
  // 版本回退
  rollbackPageVersion = 'rollbackPageVersion',
  // 确认是否升级弹窗
  sureUpgrade = 'sure-upgrade',
  // 团队升级成功
  upgradeSuc = 'upgrade-success',
  // 当前团队未升级成功，可选取其他付费团队
  upgradeSelect = 'upgrade-select',
  // 团队升级失败
  upgradeFail = 'upgrade-fail',
  // 下载离线包需要升级提示
  upgradeOfflineDemo = 'upgrade-offline-demo',
  // 导出MRP需要升级提示
  upgradeExportMrpLabel = 'upgrade-export-mrp-label',
  // 是否使用iframe显示页面
  showIframePage = 'show-iframe-page',
  // 导出图片加载中
  exportImageLoading = 'exportImageLoading',
  exportImageError = 'exportImageError',
  // 导出画板为图片，选择页面
  exportArtboardsByChoosePages = 'exportArtboardsByChoosePages',
  // 导出画板为图片，选择手机壳
  exportArtboardWithShell = 'exportArtboardWithShell',
  // 导出画板为pdf
  exportArtboardsAsPdfByChoosePages = 'exportArtboardsAsPdfByChoosePages',
  // AI生成中，关闭提示
  aiCreateCloseTips = 'ai-create-close-tips',
  //
  aiNotEnoughCreateTips = 'ai-not-enough-create-tips',
}

export interface IDialogOptions {
  content?: string;
  success?: () => void;
  cancel?: () => void;
  [key: string]: any;
}

export enum ToastType {
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
  Normal = 'normal',
  Info = 'info',
  LogOffTeamPage = 'logOffTeamPage',
  NetworkAvailable = 'networkAvailable',
  IndexedDBError = 'indexedDBError',
}
