import * as React from 'react';
import classnames from 'classnames';

import { parseColorToString } from '@utils/graphicsUtils';
import { transBlankChart } from '@utils/textUtils';

import { DefaultIconColor } from '@consts/colors';
import { StyleHelper } from '@helpers/styleHelper';
import { hasInteraction } from '@helpers/interactionHelper';
import { ITreeItem } from '@helpers/treeCompHelper';
import { UITreeItemComponent } from '@editor/comps';
import { IconValue, ITreeDataItem } from '@fbs/rp/models/value';
import { IconPosition, ICollapseExpand } from '@/fbs/rp/models/properties/collapse';
import ITextFormat from '@/fbs/rp/models/properties/text';

import './index.scss';

export interface ICollapseItemProps {
  item: ITreeItem;
  comp: UITreeItemComponent;
  level: number;
  isPreview: boolean;
  titleBgColor: string;
  contentBgColor: string;
  verticalPadding: number;
  textStyle: ITextFormat;
  isLast: boolean;
  sepInfo?: string;
  children?: React.ReactNode;
  hotAreaVisibleModel?: string;

  onExpandClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseDown?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseUp?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemDoubleClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemContext?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseLeave?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseEnter?: (node: ITreeDataItem, e: React.MouseEvent) => void;
}

const CollapseItem: React.FC<ICollapseItemProps> = (props: ICollapseItemProps) => {
  const isExpand = props.item.data.children && props.item.data.children.length > 0 && props.item.data.expand;
  const isTitle = props.level === 0;

  const handleExpandChange = (e: React.MouseEvent) => {
    const { onExpandClick, item } = props;
    if (!item.data.children || item.data.children?.length === 0) {
      return;
    }
    const node = props.item.data;
    onExpandClick?.(node, e);
    document.getSelection()?.removeAllRanges();
  };

  const handleItemClick = (e: React.MouseEvent) => {
    const { onItemClick, item } = props;
    handleExpandChange(e);
    onItemClick?.(item.data, e);
  };

  const handleItemMouseDown = (e: React.MouseEvent) => {
    const { onItemMouseDown, item } = props;
    onItemMouseDown?.(item.data, e);
  };

  const handleItemDoubleClick = (e: React.MouseEvent) => {
    const { onItemDoubleClick, item } = props;
    onItemDoubleClick?.(item.data, e);
  };

  const handleItemMouseUp = (e: React.MouseEvent) => {
    const { onItemMouseUp, item } = props;
    onItemMouseUp?.(item.data, e);
  };

  const handleItemMouseEnter = (e: React.MouseEvent) => {
    const { onItemMouseEnter, item } = props;
    onItemMouseEnter?.(item.data, e);
  };

  const handleItemMouseLeave = (e: React.MouseEvent) => {
    const { onItemMouseLeave, item } = props;
    onItemMouseLeave?.(item.data, e);
  };

  const handleItemContext = (e: React.MouseEvent) => {
    const { onItemContext, item } = props;
    onItemContext?.(item.data, e);
  };

  const handleExpandIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleExpandChange(e);
  };

  const renderExtendIcon = () => {
    const expandComp = props.comp.expandComp;
    if (!expandComp) {
      return null;
    }

    const { size, value, properties } = expandComp;
    const treeExpand = properties.expand as ICollapseExpand;
    if (treeExpand?.disabled) {
      return null;
    }
    const icon = value as IconValue;
    const fontSize = Math.min(size.width, size.height);
    const style: React.CSSProperties = {
      width: size.width,
      minWidth: size.width,
      height: size.height,
      fontSize,
      fontFamily: icon.fontName,
      lineHeight: `${fontSize}px`,
      color: parseColorToString(properties.icon?.color || DefaultIconColor),
      right: treeExpand.iconPosition === IconPosition.Right ? 12 : undefined,
      left: treeExpand.iconPosition === IconPosition.Left ? 12 : undefined,
    };

    return (
      <div className="item-expand" style={style} onClick={handleExpandIconClick}>
        {String.fromCharCode(icon.iconCode)}
      </div>
    );
  };

  const renderInner = () => {
    const { comp, textStyle, item } = props;
    const { size, value } = comp.textComp;
    const parser = StyleHelper.createCSSStyleParser({});
    const { style } = parser.getTextStyleData(size, textStyle);

    delete style.lineHeight;
    delete style.whiteSpace;

    return (
      <>
        <div className={classnames('item-text-box', { title: isTitle })}>
          <div className="item-text" style={style}>
            {transBlankChart(`${value}`)}
          </div>
          <div className="item-text item-text-temp" style={style}>
            {transBlankChart(`${value}`)}
          </div>
        </div>
        {isTitle && item.data.children && item.data.children?.length > 0 && renderExtendIcon()}
      </>
    );
  };

  const renderInteractionBack = React.useMemo(() => {
    const { comp, hotAreaVisibleModel } = props;
    if (!hotAreaVisibleModel || !hasInteraction(comp)) {
      return null;
    }
    return <div className={`item-interaction-background item-interaction-background-${hotAreaVisibleModel}`} />;
  }, [props.comp.interactions.length, props.hotAreaVisibleModel]);

  const renderMain = () => {
    const { isLast, isPreview, comp, titleBgColor, sepInfo, verticalPadding } = props;

    const sepPadding: React.CSSProperties = {};
    if (!isLast || (isLast && isTitle && isExpand)) {
      sepPadding.borderBottom = sepInfo;
    }

    const padding = {
      paddingTop: verticalPadding,
      paddingBottom: verticalPadding,
      paddingRight: 34,
      paddingLeft: 28,
    };

    if (isTitle) {
      padding.paddingLeft = 18;
      padding.paddingRight = 18;
      // 不展示图标或者图标显示在右边
      const treeExpand = comp.expandComp.properties.expand as ICollapseExpand;
      if (treeExpand?.disabled || treeExpand.iconPosition === IconPosition.Right) {
        padding.paddingLeft = 18;
        padding.paddingRight = 11 * 2 + treeExpand.iconSize;
      } else {
        padding.paddingLeft = 11 * 2 + treeExpand.iconSize;
      }
    } else {
      padding.paddingLeft = 0;
    }

    return (
      <div
        className={classnames('item-main', {
          'item-interaction-flag': !isPreview && hasInteraction(comp),
          'component-cursor-pointer': isPreview,
        })}
        style={{ background: isTitle ? titleBgColor : 'transparent' }}
        onClick={handleItemClick}
        onMouseDown={handleItemMouseDown}
        onDoubleClick={handleItemDoubleClick}
        onMouseUp={handleItemMouseUp}
        onMouseEnter={handleItemMouseEnter}
        onMouseLeave={handleItemMouseLeave}
        onContextMenu={handleItemContext}
      >
        {renderInteractionBack}
        <div className="item-main-inner" style={{ ...padding, ...sepPadding }}>
          {renderInner()}
        </div>
      </div>
    );
  };

  const getItemBorderStyle = () => {
    if (isTitle && isExpand && !props.isLast) {
      return { borderBottom: props.sepInfo };
    }
  };

  return (
    <div className="lib-comp-collapse-item" style={{ ...getItemBorderStyle() }}>
      {renderMain()}
      <div className="item-child" style={{ background: props.contentBgColor }}>
        {props.children}
      </div>
    </div>
  );
};

export default React.memo(CollapseItem);
