import { AvlTree } from '@tyriar/avl-tree';

/**
 * 只在 theater 中用到，用于处理内容面板导致的相同 id 的 comp 有多个克隆副本的情况，
 * 需要把副本都存起来，所以 tree node 的 value 为数组。他们会被同步更新，取的时候只需取一个就行。
 */
export class ComponentAvlTree<K, E, V = E[]> extends AvlTree<K, V> {
  // 目前只针对 theater.componentTree 中 id 重复的情况
  getElementInNodeValue(key: K): E | null | undefined {
    const value = super.get(key);
    if (Array.isArray(value)) {
      return value[0];
    }
    return null;
  }

  insertElementToNodeValue(key: K, element: E) {
    const value = (super.get(key) as unknown) as E[];
    value.push(element);
  }
}
