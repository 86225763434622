// 定义React中使用的颜色、字号、间距等。

export const styleColor = {
  //light
  colorLightText: '#2e2f30', //主要文字
  color: '#696b6c',
  colorGreyBlueBorder: '#dcddde',
  colorGrey: '#969798',
  colorDeepBlue: '#009dff',
  colorIconGrey: '#686b6c',
  colorBackgroundGrey: '#f9f9f9',
  colorSysRed: '#fe4066',
  colorBlackModeYellow: '#f8af1d',
  colorWhite: '#fff',
  colorSuccess: '#1bbe0a',
  colorYellow: '#ffb300',
  colorPlaceHolder: '#B8BBBF',
  // dark
};

export const styleSpace = {
  space4: 4,
  space6: 6,
  space8: 8,
  space10: 10,
  space12: 12,
  space14: 14,
  space16: 16,
  space20: 20,
  space22: 22,
  space24: 24,
  space30: 30,
  space32: 32,
  space36: 36,
  space40: 40,
  space46: 46,
  space48: 48,
  space50: 50,
  space60: 60,
  space70: 70,
  space72: 72,
  space80: 80,
  space100: 100,
  space120: 120,
  space140: 140,
  space200: 200,
  space250: 250,
  space256: 256,
  space300: 300,
  space400: 400,
  space500: 500,
  space640: 640,
};

export const styleFont = {
  font18: 18,
  font12: 12,
  font14: 14,
};
