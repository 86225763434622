import { Dispatch } from 'redux';
import { IAppPreviewLinkF } from '@fbs/idoc/models/appPreviewLink';
import { ITeams } from '@fbs/idoc/models/team';
import { IUserInfo } from '@fbs/idoc/models/user';
import { IAppF } from '@fbs/rp/models/app';
import { AppTypes } from '@fbs/idoc/models/app';

import i18n from '@i18n';
import apis from '@apis';

import { ActionsUnion, ActionType, createAction } from '../types';
import { Actions as PreviewActions } from '../preview/actions';
import { ThunkActions as GlobalActions } from '../global/actions';
import ErrorCode from '@consts/ErrorCode';

export const Actions = {
  getTeamInfo: (payload: ITeams) => createAction(ActionType.Share_GetTeamInfo, payload),
  getUserInfo: (payload: IUserInfo) => createAction(ActionType.Share_GetUserInfo, payload),

  getShareRunLink: (appID: string) => createAction(ActionType.Share_GetRunLink, appID),
  getShareRunLinkDone: (payload: IAppPreviewLinkF) => createAction(ActionType.Share_GetRunLinkDone, payload),
  getShareRunLinkError: (error: any) => createAction(ActionType.Share_GetRunLinkError, error),

  setShareLinkPass: () => createAction(ActionType.Share_SetLinkPass),
  setShareLinkPassDone: (payload: IAppPreviewLinkF) => createAction(ActionType.Share_SetLinkPassDone, payload),
  setShareLinkPassError: (error: any) => createAction(ActionType.Share_SetLinkPassError, error),

  getShareLinkPass: (shareID: string, password: string) =>
    createAction(ActionType.Share_GetLinkPass, { shareID, password }),
  getShareLinkPassDone: (payload: IAppPreviewLinkF) => createAction(ActionType.Share_GetLinkPassDone, payload),
  validatePasswordError: () => createAction(ActionType.Share_ValidatePasswordError),

  getPwEnableDone: (payload: IAppF) => createAction(ActionType.Share_GetPwEnableDone, payload),

  changePwEnable: () => createAction(ActionType.Share_ChangePwEnable),
  changePwEnableDone: (payload: boolean) => createAction(ActionType.Share_ChangePwEnableDone, payload),
  changePwEnableError: (error: any) => createAction(ActionType.Share_ChangePwEnableError, error),

  changeValidatePassword: (val: string) => createAction(ActionType.Share_ChangeValidatePassword, val),

  showMessage: (message: string) =>
    createAction(ActionType.Preview_showMessage, {
      message,
    }),
  hideMessage: () => createAction(ActionType.Preview_hideMessage),
  resetLink: (newLinkID: string) => createAction(ActionType.Preview_resetLink, { linkID: newLinkID }),
  resetShortLink: (newLinkID: string, newShortLinkID: string) =>
    createAction(ActionType.Preview_resetShortLink, { linkID: newLinkID, shortLinkID: newShortLinkID }),
  setCurrLinkID: (linkID: string) => createAction(ActionType.Preview_setCurrLinkID, { linkID }),
  hasShareLinkReset: (flag: boolean) => createAction(ActionType.Preview_hasShareLinkReset, { hasShareLinkReset: flag }),
  startRequestLink: () => createAction(ActionType.Preview_StartRequestLink),
  endRequestLink: () => createAction(ActionType.Preview_EndRequestLink),

  setShareLinkNodeIDs: (linkNodeIDs: string[]) => createAction(ActionType.Share_SetLinkNodeIDs, { linkNodeIDs }),
};

export const ThunkActions = {
  /**
   * 载入团队信息
   */
  getTeamInfo: (teamID: string) => async (dispatch: Function) => {
    apis.team.getTeamInfo(teamID).then((payload) => {
      dispatch(Actions.getTeamInfo(payload));
    });
  },

  /**
   * 载入user信息
   */
  getUserInfo: () => async (dispatch: Function) => {
    apis.user.getUserInfo().then((info) => {
      dispatch(Actions.getUserInfo(info));
    });
  },

  /**
   * 获取分享演示密码加密链接
   */
  getShareRunLink: (appID: string) => async (dispatch: Function) => {
    dispatch(Actions.getShareRunLink(appID));
    apis.share
      .getShareRunLink(appID, AppTypes.RP)
      .then((payload) => {
        dispatch(Actions.getShareRunLinkDone(payload));
        dispatch(Actions.setCurrLinkID(payload.linkID!));
      })
      .catch((error) => {
        if (error.code === ErrorCode.ERROR_MULTI_SIGN_IN) {
          GlobalActions.thunkErrorMessage(error)(dispatch as Dispatch);
        } else {
          dispatch(Actions.getShareRunLinkError(error));
        }
      });
  },

  /**
   * 设置演示链接密码
   */
  setShareLinkPass: (appID: string, password: string) => async (dispatch: Dispatch) => {
    dispatch(Actions.setShareLinkPass());

    apis.share
      .setShareLinkPass(appID, AppTypes.RP, password)
      .then((payload) => {
        dispatch(Actions.setShareLinkPassDone(payload));
        dispatch(Actions.showMessage(i18n('preview.updateSuccess')));
        setTimeout(() => {
          dispatch(Actions.hideMessage());
        }, 1000);
      })
      .catch((e) => {
        e.code === ErrorCode.ERROR_MULTI_SIGN_IN && GlobalActions.thunkErrorMessage(e)(dispatch);
      });
  },

  /**
   * 验证密码
   */
  getShareLinkPass: (shareID: string, password: string) => async (dispatch: Function) => {
    dispatch(Actions.getShareLinkPass(shareID, password));

    apis.share
      .getShareLinkPass(shareID, password)
      .then((payload) => {
        dispatch(Actions.getShareLinkPassDone(payload));
      })
      .catch((error) => {
        if (error.message === i18n('preview.passwordErrorfromBackEnd')) {
          dispatch(Actions.validatePasswordError());
        } else {
          // dispatch({
          //   type: ActionTypes.common.error,
          //   error,
          // });
        }
      });
  },

  /**
   * 输入验证信息
   */
  changeValidatePassword: (val: string) => (dispatch: Function) => {
    dispatch(Actions.changeValidatePassword(val));
  },

  /**
   * 获取当前项目是否选中
   */
  getPwEnable: (shareID: string, prototypeID?: string) => async (dispatch: Dispatch) => {
    apis.app
      .getAppByShareID(shareID, prototypeID)
      .then((payload) => {
        dispatch(Actions.getPwEnableDone(payload));
      })
      .catch((error) => {
        if (error && error.message === 'Network Error') {
          error = new Error(i18n('alert.uploadNetworkError'));
        }
        GlobalActions.thunkErrorMessage(error)(dispatch);
      });
  },

  /**
   * 启用/取消演示密码
   */
  changePwEnable: (appID: string, enabled: boolean) => async (dispatch: Dispatch) => {
    dispatch(Actions.changePwEnable());
    apis.share
      .changePwEnable(appID, enabled)
      .then(() => {
        dispatch(PreviewActions.pwdEnabled(enabled));
        dispatch(Actions.changePwEnableDone(enabled));
      })
      .catch((error) => {
        if (error.code === ErrorCode.ERROR_MULTI_SIGN_IN) {
          GlobalActions.thunkErrorMessage(error)(dispatch);
        } else {
          dispatch(Actions.changePwEnableError(error));
        }
      });
  },

  /**
   * 重置链接
   */
  resetLink: (appID: string, shareID?: string, pageID?: string, moduleID?: string, iDocAppID?: string) => async (
    dispatch: Dispatch,
  ) => {
    dispatch(Actions.startRequestLink());
    apis.share
      .resetShareLink(appID, shareID, pageID, moduleID, iDocAppID)
      .then((payload: any) => {
        if (payload.shortLinkID) {
          // todo :短链接
          dispatch(Actions.resetShortLink(payload.linkID, payload.shortLinkID));
        } else {
          dispatch(Actions.resetLink(payload.linkID));
        }
        dispatch(Actions.endRequestLink());
      })
      .catch((error) => {
        if (error && error.message === 'Network Error') {
          error = new Error(i18n('alert.uploadNetworkError'));
        }
        GlobalActions.thunkErrorMessage(error)(dispatch);
        dispatch(Actions.endRequestLink());
      });
  },
  /**
   * 检验当前链接是否已重置
   */
  checkShareLinkReset: (shareID: string, linkID: string) => async (dispatch: Dispatch) => {
    apis.share
      .checkShareLinkReset(shareID, linkID)
      .then((payload: any) => {
        dispatch(Actions.hasShareLinkReset(!payload.isValid));
      })
      .catch((error) => {
        GlobalActions.thunkErrorMessage(error)(dispatch);
      });
  },

  /*
   * 设置分享项目的页面
   */
  setShareLinkNodeIds: (linkID: string, nodes: string[]) => async (dispatch: Dispatch) => {
    apis.share
      .setPageInShareRunLink(linkID, nodes)
      .then(() => {
        dispatch(Actions.showMessage(i18n('preview.setNodeIdsSuccess')));
        setTimeout(() => dispatch(Actions.hideMessage()), 1000);
      })
      .catch((e) => {
        e.code === ErrorCode.ERROR_MULTI_SIGN_IN && GlobalActions.thunkErrorMessage(e)(dispatch);
      });
  },

  // 短链接属性
  setShortLinkProps: (shortLinkID: string, url: string) => async (dispatch: Dispatch) => {
    apis.share
      .setShortLinkProps(shortLinkID, url)
      .then(() => {
        dispatch(Actions.showMessage(i18n('preview.setNodeIdsSuccess')));
        setTimeout(() => dispatch(Actions.hideMessage()), 1000);
      })
      .catch((e) => {
        e.code === ErrorCode.ERROR_MULTI_SIGN_IN && GlobalActions.thunkErrorMessage(e)(dispatch);
      });
  },
};

export type ShareAction = ActionsUnion<typeof Actions>;
