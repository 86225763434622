import * as React from 'react';

import { color2hexString } from '@utils/graphicsUtils';
import { PureColor } from '@fbs/rp/models/properties/color';
import Tooltip from '../../Tooltip';

import './index.scss';

export interface IPresetColorsProp {
  onChanged: (color: tinycolor.ColorFormats.RGBA) => void;
}

const PresetColorList: PureColor[][] = [
  [
    { r: 0, g: 0, b: 0, a: 1 },
    { r: 51, g: 51, b: 51, a: 1 },
    { r: 85, g: 85, b: 85, a: 1 },
    { r: 127, g: 127, b: 127, a: 1 },
    { r: 170, g: 170, b: 170, a: 1 },
    { r: 215, g: 215, b: 215, a: 1 },
    { r: 242, g: 242, b: 242, a: 1 },
    { r: 255, g: 255, b: 255, a: 1 },
  ],
  [
    { r: 236, g: 128, b: 141, a: 1 },
    { r: 249, g: 205, b: 144, a: 1 },
    { r: 255, g: 255, b: 128, a: 1 },
    { r: 202, g: 249, b: 130, a: 1 },
    { r: 128, g: 255, b: 255, a: 1 },
    { r: 128, g: 211, b: 248, a: 1 },
    { r: 128, g: 128, b: 255, a: 1 },
    { r: 192, g: 129, b: 255, a: 1 },
  ],
  [
    { r: 216, g: 0, b: 27, a: 1 },
    { r: 245, g: 155, b: 34, a: 1 },
    { r: 255, g: 255, b: 0, a: 1 },
    { r: 149, g: 242, b: 2, a: 1 },
    { r: 2, g: 255, b: 255, a: 1 },
    { r: 1, g: 167, b: 240, a: 1 },
    { r: 6, g: 0, b: 255, a: 1 },
    { r: 144, g: 19, b: 254, a: 1 },
  ],
  [
    { r: 163, g: 0, b: 20, a: 1 },
    { r: 184, g: 116, b: 26, a: 1 },
    { r: 191, g: 190, b: 0, a: 1 },
    { r: 112, g: 181, b: 3, a: 1 },
    { r: 2, g: 191, b: 191, a: 1 },
    { r: 0, g: 126, b: 180, a: 1 },
    { r: 3, g: 0, b: 191, a: 1 },
    { r: 99, g: 1, b: 190, a: 1 },
  ],
  [
    { r: 110, g: 1, b: 15, a: 1 },
    { r: 127, g: 77, b: 18, a: 1 },
    { r: 128, g: 128, b: 0, a: 1 },
    { r: 75, g: 122, b: 2, a: 1 },
    { r: 0, g: 128, b: 127, a: 1 },
    { r: 2, g: 84, b: 120, a: 1 },
    { r: 2, g: 0, b: 128, a: 1 },
    { r: 67, g: 0, b: 128, a: 1 },
  ],
];

class PresetColors extends React.Component<IPresetColorsProp> {
  static defaultProps: Partial<IPresetColorsProp> = {};

  handleItemColorSelect = (color: tinycolor.ColorFormats.RGBA, e: React.MouseEvent) => {
    e.stopPropagation();
    const { onChanged } = this.props;
    onChanged && onChanged(color);
  };

  renderItem = (color: PureColor) => {
    const text = color2hexString(color);
    return (
      <Tooltip key={`${text}`} text={text} ownerIsDialog className="preset-item">
        <div onMouseDown={this.handleItemColorSelect.bind(this, color)}>
          <div className="color-content" style={{ backgroundColor: text }} />
        </div>
      </Tooltip>
    );
  };

  render() {
    return (
      <div className="dsm-c-rp-color-preset-colors">
        {PresetColorList.map((colors, i) => (
          <div key={`${i}`} className="preset-color-row">
            {colors.map((color) => this.renderItem(color))}
          </div>
        ))}
      </div>
    );
  }
}

export default PresetColors;
