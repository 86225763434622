import { measureTextSize } from '@utils/textUtils';
import { round } from '@utils/globalUtils';
import { toFixedPoint } from '@utils/boundsUtils';

import { EventTypes } from '@fbs/rp/models/event';
import { PropertyName, PropertyValue } from '@/fbs/rp/models/property';
import { ArtboardPatches, Ops } from '@/fbs/rp/utils/patch';
import { IRange } from '@fbs/rp/models/properties/base';
import ITextFormatEx from '@/fbs/rp/models/properties/textFormat';

import i18n from '@i18n';

import UIContainerComponent from '@editor/comps/UIContainerComponent';
import { ComponentChangeType, getNewPositionWhenCenter, getOffsetBySizeChange } from '@editor/comps/resizeHelper';
import { UIComponent } from '@/editor/comps';

import { PredefinedStates } from '@consts/state';
import ValueEditorType from '@consts/enums/valueEditorType';
import { SpriteThumb } from '@consts/spriteIcons';
import { coverPatches } from '@helpers/patchHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { getChangedValue } from '@/helpers/componentHelper';
import appOptions from '@/helpers/appOptions';
import { getDefaultDataFromTemplate } from '@/libs/helper';

import { IComponentItem } from '../../types';
import { MoveMode, PropertyStructureAlign } from '../../enum';
import { onChildMove } from '../CheckBoxGroup/config';

const defaltValue = [
  `${i18n('resource.componentsText.optionText')} 1`,
  `${i18n('resource.componentsText.optionText')} 2`,
  `${i18n('resource.componentsText.optionText')} 3`,
];

const defaultTextSize = defaltValue.map((text) =>
  measureTextSize({ fontSize: 14, fontFamily: 'Microsoft YaHei' }, text),
);
/*
Item 结构
  {
    iconWrapper
      outerEllipse
      innerEllipse
  }
*/
function onPropertyUpdate(comp: UIComponent, propertyName: PropertyName, value: PropertyValue): ArtboardPatches | null {
  if (propertyName !== 'iconSize' && propertyName !== 'textStyle1') {
    return null;
  }

  const radioItems = (comp as UIContainerComponent).components;
  const artboardPatches: ArtboardPatches = {
    do: {},
    undo: {},
  };

  if (propertyName === 'iconSize') {
    const iconSize = (value.value as IRange).value;
    radioItems.forEach((item) => {
      const radio = item as UIContainerComponent;
      const iconWrapper = radio.getComponentByAlias('iconWrapper');
      if (iconWrapper) {
        const wrapper = iconWrapper as UIContainerComponent;
        const [outer, inner] = wrapper.components;
        const wrapperSize = wrapper.size;
        const outerSize = outer.size;
        const innerSize = inner.size;
        const newWrapperSize = {
          ...wrapperSize,
          width: iconSize,
          height: iconSize,
        };
        const newOuterSize = {
          ...newWrapperSize,
          lockedRatio: outerSize.lockedRatio,
        };
        const scale = {
          x: innerSize.width / outerSize.width,
          y: innerSize.height / outerSize.height,
        };
        const newInterSize = {
          ...innerSize,
          width: iconSize * scale.x,
          height: iconSize * scale.y,
        };

        const { position, rotate, size } = inner.getResizeMySelfResult(getOffsetBySizeChange(innerSize, newInterSize));
        const newInnerPosition = getNewPositionWhenCenter(inner, position, size, newWrapperSize, {
          isLayoutCenterAtHorizontal: true,
          isLayoutMiddleAtVertical: true,
        });
        const { patches } = wrapper.getPositionPatchesOfChildrenChanged(
          [
            {
              id: inner.id,
              type: ComponentChangeType.Edit,
              size: { ...size, width: round(size.width), height: round(size.height) },
              rotate,
              //TODO Meng XiaoJiang 2022-10-12 保留最多一位小数，确保一视觉上任何尺寸基本能居中
              position: toFixedPoint(newInnerPosition, 1),
            },
            {
              id: outer.id,
              type: ComponentChangeType.Edit,
              size: newOuterSize,
              rotate: outer.rotate,
              position: outer.position,
            },
          ],
          false,
        );

        const path = wrapper.getCurrentSizePath();
        artboardPatches.do[wrapper.id] = [Ops.replace(path, newWrapperSize)];
        artboardPatches.undo[wrapper.id] = [Ops.replace(path, wrapperSize)];
        coverPatches(artboardPatches, patches);
      }
    });
  } else if (propertyName === 'textStyle1') {
    // 文本样式修改，同步修改选中项的size
    const textStyle = value as ITextFormatEx;
    const defaultGroupTextStyle = comp.properties.textStyle1 as ITextFormatEx;
    const diffTextStyle = getChangedValue(textStyle, defaultGroupTextStyle);
    radioItems.forEach((item) => {
      const radio = item as UIContainerComponent;
      const textComp = radio.getComponentByAlias('pureText');
      if (textComp && item.selected) {
        const { size: oldSize, id } = textComp;
        const oldTextStyle = textComp.properties.textStyle;
        const style = StyleHelper.initCSSStyleParser({}).doGetTextStyle({ ...oldTextStyle, ...diffTextStyle });
        const { width, height } = measureTextSize(style, textComp.value as string, {});
        artboardPatches.do[id] = [Ops.replace('./size', { ...oldSize, width, height })];
        artboardPatches.undo[id] = [Ops.replace('./size', oldSize)];
      }
    });
  }
  return artboardPatches;
}

export const RadioGroupCfg: IComponentItem = {
  type: 'radio-button-group',
  name: i18n('resource.components.radioButtonGroup'),
  thumb: {
    spriteIconClass: SpriteThumb.RadioGroup.className,
    dragPosition: SpriteThumb.RadioGroup.position,
  },
  isList: true,
  isTextComp: true, // 数据填充文本-判断需要
  predefinedStates: [PredefinedStates.disabled],
  value: {
    type: ValueEditorType.ListItemPureTextValues,
  },
  constraint: {
    pureText: {
      move: MoveMode.horizontal,
    },
    iconWrapper: {
      move: MoveMode.neither,
    },
    outerEllipse: {
      move: MoveMode.neither,
    },
    innerEllipse: {
      move: MoveMode.neither,
    },
  },
  editor: {
    onChildMove,
    onPropertyUpdate,
  },
  interactions: {
    defaultEvent: EventTypes.checked,
  },
  syncPropertyToStateNormal: {
    structure: true,
  },
  property: {
    getExtensionDefaultProperties: (comp: UIComponent) => {
      const compData = comp.toJSON();
      const { iconSize, structure } = compData.properties;
      const typeName = compData.lib?.type ?? compData.type;
      if (iconSize && structure) {
        return null;
      }
      return {
        iconSize: iconSize || {
          name: i18n('property.propertyNames.iconSize'),
          prop: 'number',
          value: {
            value: 13,
            min: 10,
            max: 100,
          },
        },
        structure: structure ||
          appOptions.getStructureAlign(typeName) || {
            value: PropertyStructureAlign.TextRight,
            prop: 'enum',
            name: 'structure',
          },
      };
    },
  },
  item: `
  {
    type: @@C.StackPanel,
    size: {
      width: {3},
      height: ${defaultTextSize[0].height},
    },
    properties: {
     layout:{
        direction: 'horizontal',
        horizontalGap: 8,
        verticalAlign: 'middle',
        disabledSwitch: true,
        disabled: false,
      },
    },
    value: '',
    components: [
      {
        type: @@C.CanvasPanel,
        alias:'iconWrapper',
        size: {
          width: 13,
          height: 13,
        },
        position:{
          x: 0, y: 3,
        },
        layout:{
          vertical: 'auto',
          horizontal: 'left',
          fixedWidth: true,
          fixedHeight: true,
          auto: false,
          responsive: true,
        },
        components: [
          {
            type: @@C.Ellipse,
            alias:'outerEllipse',
            position: {
              x: 0,
              y: 0,
            },
            size:{width: 13, height: 13},
            layout:{
              vertical: 'top&bottom',
              horizontal: 'left&right',
              fixedWidth: false,
              fixedHeight: false,
              auto: false,
              responsive: true,
            },
            properties: {
              fill: {
                type: @@FillType.solid,
                color: @@SystemColors.WhiteColor,
                disabled: false,
              },
              stroke: {
                thickness: 1,
                color: "#AAAAAA",
                disabled: false,
                position: 'inner',
              },
            },
          },
          {
            type: @@C.Ellipse,
            alias:'innerEllipse',
            position: {
              x: 3,
              y: 3,
            },
            size:{width: 7, height: 7,lockedRatio:true},
            layout:{
              vertical: 'middle',
              horizontal: 'center',
              fixedWidth: true,
              fixedHeight: true,
              auto: false,
              responsive: true,
            },
            properties: {
              fill: {
                disabled: true,
                type: @@FillType.solid,
                color: @@SystemColors.TransparentColor,
              },
              stroke: {
                thickness: 1,
                color: @@SystemColors.BlackColor,
                disabled: true,
              },
            },
            states: {
              checked: {
                enabled: true,
                name: '${i18n('property.propertyNames.checked')}',
                properties: {
                  fill: {
                    disabled: false,
                    type: @@FillType.solid,
                    color: @@SystemColors.DarkGrayColor,
                  },
                },
              },
            },
          },
        ],
      },
      {
        type: @@C.PureText,
        alias:'pureText',
        autoSize: true,
        size: {
          width: {2},
          height: ${defaultTextSize[0].height},
        },
        position: {x: 20, y: 2},
        layout:{
          vertical: 'auto',
          horizontal: 'left&right',
          fixedWidth: false,
          fixedHeight: false,
          auto: false,
          responsive: true,
        },
        properties: {
          textStyle: {
            ref: '@properties.textStyle1',
          },
        },
        value: '{0}',
        states: {
          checked: {
            name: '${i18n('property.propertyNames.checked')}',
            enabled: true,
            properties: {
              textStyle: {
                ref: '@properties.textStyle2',
              },
            },
          },
        },
      },
    ],
    selected: {1},
  }
  `,
  template: `
  {
    type: @@C.GridPanel,
    size: {
      width: 86,
      height: 92,
    },
    layout:{
      vertical: 'middle',
      horizontal: 'left',
      fixedWidth: true,
      fixedHeight: true,
      auto: true,
      responsive: true,
    },
    properties: {
      cell:{
        columnCount:1
      },
      layout: {
        direction: 'vertical',
        verticalAlign: 'top',
        horizontalAlign: 'left',
        verticalGap: 10,
        horizontalGap: 10,
        disabledSwitch:true,
      },
      textStyle1: {
        name: '${i18n('property.propertyNames.defaultText')}',
        prop: 'textStyle',
        fontFamily: 'Microsoft YaHei',
        fontSize: 14,
        color: @@SystemColors.DefaultTextColor,
        disabled: false,
      },
      textStyle2: {
        name: '${i18n('property.propertyNames.checkedText')}',
        prop: 'textStyle',
        fontFamily: 'Microsoft YaHei',
        fontSize: 14,
        color: @@SystemColors.DefaultTextColor,
        disabled: false,
      },
      iconSize: {
        prop: 'number',
        name: '${i18n('property.propertyNames.iconSize')}',
        value: {
          value: 13,
          min: 10,
          max: 100
        }
      }
    },
    states:{
      disabled:{
        enabled: false,
        properties:{
          textStyle1: {
            prop: 'textStyle',
            color: @@SystemColors.DisabledTextColor,
          },
          textStyle2: {
            prop: 'textStyle',
            color: @@SystemColors.DisabledTextColor,
          },
        },
      },
    },    
    value: '',
    components: [
      ${defaltValue
        .map(
          (text, index) =>
            `@@ITEM-${text}|${index === 0}|${defaultTextSize[index].width}|${defaultTextSize[index].width + 26},`,
        )
        .join('\n')}
    ],
    select: {
      target: 'child',
      enabled: true,
      maxCount: 1,
      minCount: 1,
      reversible: false,
      autoUnselect: true,
    },
    sealed: true,
  }
  `,
  itemArgs: [
    {
      type: 'string',
      value: i18n('resource.componentsText.optionText'),
    },
    {
      type: 'boolean',
      value: false,
    },
  ],
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
};

const defaultData = getDefaultDataFromTemplate(RadioGroupCfg);
