import * as React from 'react';
import classnames from 'classnames';
import './index.scss';
import Input from '../Input';
import Icon from '../Icon';

export interface IStepperProp {
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  width?: number;
  className?: string;
  left?: boolean;
  disabled?: boolean;
  showText?: boolean;
  isZh?: boolean;
  leftText: string;
  rightText?: string;
  onBlur?(value: number): void;

  onSlider?(value: number): void;
  //代表正在移动中的数据
  onMovingSlider?(value: number): void;
}

const STEPPER_MIN = 0;
const STEPPER_MAX = 100;
const STEPPER_VALUE = 0;
const STEPPER_WIDTH = 48;
const STEPPER_STEP = 1;

class Stepper extends React.Component<IStepperProp, { mouseDown: boolean; disX: number; value: number }> {
  static defaultProps = {
    min: STEPPER_MIN,
    max: STEPPER_MAX,
    step: STEPPER_STEP,
    value: STEPPER_VALUE,
    className: '',
    width: STEPPER_WIDTH,
    left: false,
    disabled: false,
    showText: true,
    isZh: true,
  };

  private InputDom: React.RefObject<HTMLInputElement> = React.createRef();

  constructor(props: IStepperProp) {
    super(props);
    this.state = {
      mouseDown: false,
      disX: 0,
      value: Math.max(props.min ?? STEPPER_MIN, Math.min(props.value ?? STEPPER_VALUE, props.max ?? STEPPER_MAX)),
    };
  }

  componentDidUpdate(prevProps: IStepperProp) {
    const { value, min, max } = this.props;
    if (value !== prevProps.value) {
      this.setState({ value: Math.max(min ?? STEPPER_MIN, Math.min(value ?? STEPPER_VALUE, max ?? STEPPER_MAX)) });
    }
  }

  calculateValue(disX: number) {
    const { min, max, width } = this.props;
    return Math.max(
      min ?? STEPPER_MIN,
      Math.min(Math.floor((disX / (width ?? STEPPER_WIDTH)) * 100), max ?? STEPPER_MAX),
    );
  }

  handleBlock(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    const target = e.target as HTMLElement;
    if (!target.offsetParent) {
      return;
    }
    let disX = e.pageX - target.offsetParent.getBoundingClientRect().left;
    if (disX < 0) {
      disX = 0;
    }
    const width = this.props.width ?? STEPPER_WIDTH;
    if (disX > width) {
      disX = width;
    }
    this.setState({
      value: this.calculateValue(disX),
    });
  }

  doChange(value: number) {
    const { onSlider, min, max } = this.props;
    const v = Math.max(min ?? STEPPER_MIN, Math.min(value, max ?? STEPPER_MAX));
    onSlider && onSlider(v);
  }

  setValue = (value: number) => {
    const max = this.props.max ?? STEPPER_MAX;
    const min = this.props.min ?? STEPPER_MIN;
    if (value > max) {
      this.setState({ value: max });
      return;
    }
    if (value < min) {
      this.setState({ value: min });
      return;
    }
    this.setState({ value });
  };
  render() {
    const { width, leftText, disabled, className, rightText, min, max, step, isZh } = this.props;
    const { value } = this.state;
    const leftIconClass = isZh ? 'tag_uparrow up-arrow' : 'tag_uparrow up-arrow  up-arrow-en';
    const rightIconClass = isZh ? 'tag_downarrow down-arrow' : 'tag_downarrow down-arrow down-arrow-en';
    return (
      <div className={['dsm-c-stepper', className || ''].join(' ')}>
        {leftText && (
          <span
            className={classnames({
              'disabled-stepper-title': disabled,
            })}
          >
            {leftText}
          </span>
        )}
        <Input
          width={width}
          value={value}
          autoSelect
          onFocus={() => {
            if (this.InputDom.current) {
              this.InputDom.current.select();
            }
          }}
          setInputRef={this.InputDom}
          onChange={(value) => {
            value = value.replace(/[^\d]/g, '');
            this.setValue(value);
          }}
          onBlur={() => {
            if (this.props.onBlur) {
              this.props.onBlur(value);
            }
          }}
        />
        <Icon
          cls={leftIconClass}
          disabled={value >= (max ?? STEPPER_MAX)}
          onClick={() => {
            if (this.InputDom.current) {
              this.InputDom.current.select();
            }
            this.setValue(value + (step ?? STEPPER_STEP));
          }}
        ></Icon>
        <Icon
          cls={rightIconClass}
          disabled={value <= (min ?? STEPPER_MIN)}
          onClick={() => {
            if (this.InputDom.current) {
              this.InputDom.current.select();
            }
            this.setValue(value - (step ?? STEPPER_STEP));
          }}
        ></Icon>
        {rightText && (
          <span
            className={classnames({
              'disabled-stepper-title': disabled,
            })}
          >
            {rightText}
          </span>
        )}
      </div>
    );
  }
}

export default Stepper;
