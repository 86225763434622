import * as React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import i18n from '../../i18n';
import TagButton from './TagButton';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

import './index.scss';

interface IImageStyle {
  width?: number | string;
  height?: number | string;
}

export interface ITagButtonProp {
  id: number | string;
  text: string;
  tooltip?: string;
  icon?: string;
  // 图片图标
  imgUrl?: string;
  // 图片
  imgTooltip?: string;
  imgStyle?: IImageStyle;
}

export interface ITagButtonGroupProp {
  tags: ITagButtonProp[];
  firstTagId?: string;
  showAll?: boolean;
  textAll?: string;
  selected: number | string | Array<number | string>;
  multiple?: boolean;
  toolTipText?: string;
  settingsLinkTo?: string;
  // 全部按钮的位置index(从0开始，-1 表示忽略)
  allIndex?: number;
  hideSettingButton?: boolean;
  onClickSetting?(): void;
  onSelect?(id: string | number): void;
  onClickImg?(id: string | number): void;
}

const TagButtonGroup: React.FC<ITagButtonGroupProp> = (props: ITagButtonGroupProp) => {
  const {
    tags,
    showAll,
    textAll,
    multiple,
    selected,
    settingsLinkTo,
    toolTipText,
    allIndex,
    firstTagId,
    hideSettingButton,
    onClickSetting,
  } = props;
  if (showAll) {
    const all = { id: 'all', text: textAll || 'All' };
    tags.unshift(all);
    if (firstTagId) {
      //指定第一个tag
      let firstTag;
      tags.forEach((tag, index) => {
        if (tag.id === firstTagId) {
          firstTag = tag;
          tags.splice(index, 1);
        }
      });
      firstTag && tags.unshift(firstTag);
    }
    if (allIndex !== undefined && allIndex !== -1) {
      if (allIndex !== 0) {
        // 先删除
        tags.splice(0, 1);
        // 在放入
        tags.splice(allIndex, 0, all);
      }
    }
  }

  return (
    <div className="dsm-c-tag-button-group">
      {props.tags.map((tag: ITagButtonProp) => {
        if (!tag) {
          return null;
        }
        const selected = props.multiple
          ? (props.selected as Array<number | string>).includes(tag.id)
          : props.selected === tag.id;
        return (
          <TagButton
            key={tag.id}
            selected={selected}
            onClick={() => {
              props.onSelect && props.onSelect(tag.id);
            }}
          >
            {tag.tooltip ? (
              <Tooltip text={tag.tooltip} theme="small">
                {
                  <span>
                    {tag.icon ? <Icon cls={tag.icon} disableHoverColor={true} /> : ''}
                    {tag.text ? <span className="tag-text">{tag.text}</span> : ''}
                  </span>
                }
              </Tooltip>
            ) : (
              <span>
                {tag.icon ? <Icon cls={tag.icon} /> : ''}
                {tag.text ? <span className="tag-text">{tag.text}</span> : ''}
                {tag.imgUrl && selected && (
                  <React.Fragment>
                    {tag.imgTooltip ? (
                      <Tooltip text={tag.imgTooltip} theme="small">
                        <img
                          className="tag-img"
                          src={tag.imgUrl}
                          width={tag.imgStyle?.width || 10}
                          height={tag.imgStyle?.height || 12}
                          onClick={() => {
                            props.onClickImg && props.onClickImg(tag.id);
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <img
                        className="tag-img"
                        src={tag.imgUrl}
                        width={tag.imgStyle?.width || 10}
                        height={tag.imgStyle?.height || 12}
                        onClick={() => {
                          props.onClickImg && props.onClickImg(tag.id);
                        }}
                      />
                    )}
                  </React.Fragment>
                )}
              </span>
            )}
          </TagButton>
        );
      })}
      {!hideSettingButton && (
        <div className="tag-button-group-setting">
          <Tooltip theme="small" text={toolTipText} align={'right'}>
            {settingsLinkTo && (
              <Link className={classnames('settings')} to={settingsLinkTo}>
                <Icon cls="index_set" />
              </Link>
            )}
            {onClickSetting && <Icon cls="index_set" onClick={onClickSetting} />}
          </Tooltip>
        </div>
      )}
    </div>
  );
};

TagButtonGroup.defaultProps = {
  showAll: true,
  textAll: i18n('TagButtonGroup.all'),
  toolTipText: i18n('TagButtonGroup.settings'),
  settingsLinkTo: '',
  multiple: false,
  firstTagId: '',
  allIndex: -1,
};

export default TagButtonGroup;
