import { IAppsOfTeam } from '@fbs/teamManagement';
import { ITeams } from '@fbs/idoc/models/team';
import { IAppF, IPrototypeInfo } from '@fbs/idoc/models/app';
import { PublishActions } from './actions';
import { ActionType } from '../types';

export interface IPublishState {
  teams: ITeams[];
  apps: IAppF[];
  prototypes: IPrototypeInfo[];
  // selectedTeams?: ITeams;
  selectedApps?: IAppF;
  selectedPrototype?: IPrototypeInfo;
  publishError?: string;
  publishSuccess?: boolean;
  taskID?: string;
  currentTeamOfPersonalSpace?: ITeams[];
  appSetsOfPersonalSpaceByTeam?: IAppsOfTeam[];
}

const initState: IPublishState = {
  teams: [],
  apps: [],
  prototypes: [],
};

function flatApps(apps: IAppF[]) {
  const result: IAppF[] = [];
  const doFlat = (apps: IAppF[]) => {
    result.push(...apps);
    apps.forEach((app) => {
      if (app.children?.length) {
        doFlat(app.children);
      }
    });
  };
  doFlat(apps);
  return result;
}

// 过滤删除的项目
function filterIDocApps(apps: IAppF[]) {
  const result = apps.filter((item) => !item.state);
  result.forEach((item) => {
    if (item.children) {
      item.children = filterIDocApps(item.children);
    }
  });
  return result;
}

// 过滤项目，保留项目集
function filterAppSets(apps: IAppsOfTeam[]) {
  return apps.filter((app) => app.type === 'app-set');
}

export default function (state = initState, action: PublishActions): IPublishState {
  switch (action.type) {
    case ActionType.Publish_GetAllTeams:
      return {
        ...state,
        teams: action.payload,
      };
    case ActionType.Publish_GetAppsFromTeam: {
      return {
        ...state,
        apps: filterIDocApps(action.payload),
      };
    }
    case ActionType.Publish_GetAppsFromTeamByPersonalSpace: {
      return {
        ...state,
        appSetsOfPersonalSpaceByTeam: filterAppSets(action.payload!),
      };
    }
    case ActionType.Publish_GetPrototypesFromApp: {
      // TODO 过滤，只显示RP的原型项目及未删除的项目
      const prototypes = action.payload.filter((item) => !item.state && ['sub-rp-app', 'rp'].includes(`${item.type}`));
      return {
        ...state,
        prototypes,
      };
    }
    // case ActionType.Publish_SwitchTeam:
    //   return {
    //     ...state,
    //     selectedTeams: state.teams.find((item) => item.id === action.payload),
    //   };
    case ActionType.Publish_SwitchApp: {
      const apps = flatApps(state.apps);
      return {
        ...state,
        selectedApps: apps.find((app) => app._id === action.payload),
      };
    }
    case ActionType.Publish_SwitchPrototype:
      return {
        ...state,
        selectedPrototype: state.prototypes.find((item) => item._id === action.payload),
      };
    case ActionType.Publish_CreateApp: {
      const apps = [...state.apps];
      let arr = apps;
      const { appSetID } = action.payload;
      if (appSetID) {
        let _flatApps = flatApps(state.apps);
        const appSet = _flatApps.find((item) => item._id === appSetID);
        if (appSet) {
          arr = [...(appSet.children || [])];
          appSet.children = arr;
        }
      }
      arr.unshift(action.payload);
      return {
        ...state,
        apps,
        selectedApps: action.payload,
        prototypes: [],
        selectedPrototype: undefined,
      };
    }

    case ActionType.Publish_CreatePrototype: {
      const prototype = action.payload as IPrototypeInfo;
      return {
        ...state,
        prototypes: [prototype].concat(state.prototypes),
        selectedPrototype: prototype,
      };
    }

    case ActionType.Publish_Error: {
      return {
        ...state,
        publishError: action.payload,
      };
    }
    case ActionType.Publish_Success: {
      return {
        ...state,
        taskID: undefined,
        publishSuccess: action.payload,
      };
    }
    case ActionType.Publish_Task: {
      return {
        ...state,
        taskID: action.payload,
        publishSuccess: false,
      };
    }
    default:
      break;
  }
  return state;
}
