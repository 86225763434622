import * as moment from 'moment';

import { languageManager } from '@/i18n';

/**
 * 获取水印内容
 * userInfo 用户信息
 * @param {string | undefined}: email 用户邮箱
 * @returns {string} 水印内容
 */
export const getWaterMarkContent = (email: string | undefined): string => {
  const { isZHLanguage, currentLanguage } = languageManager;
  let date: string;
  if (isZHLanguage) {
    date = moment().locale(currentLanguage).format('LL');
  } else {
    date = moment().format('MM/DD/YYYY');
  }
  return `${email || ''} ${date}`;
};
