const el = document.documentElement;

export const isFullscreen = !!document.fullscreenElement || document.mozIsFullScreen || document.webkitIsFullScreen;

export const openFullScreen = () => {
  if (el.requestFullscreen || false) {
    el.requestFullscreen();
  } else if (typeof window.ActiveXObject !== 'undefined') {
    // for Internet Explorer
    const wScript = new window.ActiveXObject('WScript.Shell');
    if (wScript) {
      wScript.SendKeys('{F11}');
    }
  }
};

export const exitFullScreen = () => {
  if (document.exitFullscreen || false) {
    document.exitFullscreen();
  } else if (typeof window.ActiveXObject !== 'undefined') {
    // for Internet Explorer
    const wScript = new window.ActiveXObject('WScript.Shell');
    if (wScript) {
      wScript.SendKeys('{F11}');
    }
  }
};
