import PropertyBase from './base';

export default interface IRadius extends PropertyBase {
  topLeft?: number;
  topRight?: number;
  bottomRight?: number;
  bottomLeft?: number;
  isPercent?: boolean;
}

export const RadiusPropertyName = 'radius';
