import { IUserInfo } from '@fbs/idoc/models/user';
import * as user from '../user';

/**
 * 获取用户信息(离线演示包)
 * @returns {Promise<IUserInfo>}
 */
export async function getUserInfo(): Promise<IUserInfo> {
  return Promise.reject();
}

export default {
  ...user,
  getUserInfo,
};
