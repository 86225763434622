import * as _ from 'lodash';

import { IResizeCommandParams } from '@fbs/rp/models/interactions';
import { IPosition, ISize } from '@fbs/common/models/common';
import { Ops, PageOperations, ReplaceOperation } from '@fbs/rp/utils/patch';
import { EventTypes } from '@fbs/rp/models/event';
import { IPathValue, IComponentValue } from '@/fbs/rp/models/value';

import { scalePath } from '@helpers/pathHelper';

import { CCompoundPath, CPath, CGroup } from '@libs/constants';

import CommandBase from './CommandBase';

export default class ResizeCommand extends CommandBase {
  public get afterEvent() {
    return EventTypes.afterResize;
  }

  cashOriginParams() {
    this.saveCacheOriginParams('bounds', {
      size: this.command.target.size,
      position: this.command.target.position,
    });
  }

  private getTargetChanges = (revert: boolean) => {
    const { target, params } = this.command;
    const { fromCurrent, height: h, width: w, transformOrigin } = params as IResizeCommandParams;
    if (!fromCurrent && revert) {
      return this.getCacheOriginParams('bounds');
    }
    const { position, size, type, value } = target;
    let x = position.x;
    let y = position.y;
    let width = w;
    let height = h;
    if (fromCurrent) {
      width = revert ? size.width - w : size.width + w;
      height = revert ? size.height - h : size.height + h;
    }
    switch (transformOrigin) {
      case 'center':
      case 'top':
      case 'bottom':
        x = x + (size.width - width) / 2;
        break;
      case 'right':
      case 'topRight':
      case 'bottomRight':
        x = x + size.width - width;
        break;
      default:
        break;
    }
    switch (transformOrigin) {
      case 'center':
      case 'left':
      case 'right':
        y = y + (size.height - height) / 2;
        break;
      case 'bottom':
      case 'bottomLeft':
      case 'bottomRight':
        y = y + size.height - height;
        break;
      default:
        break;
    }

    const newSize = { ...size, width, height };

    // 处理路径value缩放
    let newValue: IPathValue | IPathValue[] | undefined = undefined;
    if ([CCompoundPath, CPath].includes(type)) {
      const oldValue = value as IPathValue | IPathValue[];
      const scale = {
        x: newSize.width / size.width,
        y: newSize.height / size.height,
      };
      if (_.isArray(oldValue)) {
        newValue = oldValue.map((item) => {
          return scalePath(item, scale, false);
        });
      } else {
        newValue = scalePath(oldValue, scale, false);
      }
    }

    return {
      position: { x, y },
      size: newSize,
      value: newValue,
    };
  };

  private initOperation = ({
    position,
    size,
    value,
  }: {
    position: IPosition;
    size: ISize;
    value?: IComponentValue;
  }): PageOperations => {
    const { target } = this.command;
    const { id, ownerArtboardID, type } = target;
    const operations: ReplaceOperation<any>[] = [
      Ops.replace(this.getCurrentPath('position'), position),
      Ops.replace(this.getCurrentPath('size'), size),
      this.initAnimationOperation(),
    ];
    if (type === CGroup) {
      //TODO 针对组写入一个真实尺寸数据，用于在判断到组有resize的时候，直接从该数据获取
      operations.push(Ops.replace('/realSize', size));
    }
    if (value) {
      operations.unshift(Ops.replace(this.getCurrentPath('value'), value));
    }
    return {
      [ownerArtboardID]: {
        [id]: operations,
      },
    };
  };

  revert = () => {
    this.patch(this.initOperation(this.getTargetChanges(true)));
    this.fulfilled = true;
  };

  run = () => {
    this.patch(this.initOperation(this.getTargetChanges(false)));
    this.fulfilled = true;
  };
}
