import PropertyBase from './base';

export type DirectionType = 'vertical' | 'horizontal'

export default interface ILayout extends PropertyBase {
  direction: DirectionType;
  verticalAlign?: VerticalAlignType;
  horizontalAlign?: HorizontalAlignType;
  verticalGap?: number;
  horizontalGap?: number;
  disabledSwitch?: boolean;
}
export type VerticalAlignType = 'top' | 'middle' | 'bottom'
export type HorizontalAlignType = 'left' | 'center' | 'right'

export const LayoutPropertyName = 'layout';
