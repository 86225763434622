import { FontStyle } from '@/fbs/rp/models/properties/text';

import { queryRichTextBoldState } from '@/utils/textUtils';

export class FontStyleHelper {
  private getCommand(type: 'strike' | 'underline' | 'italic' | 'bold'): string {
    switch (type) {
      case 'strike':
        return 'strikeThrough';
      case 'underline':
        return 'underline';
      case 'italic':
        return 'italic';
      case 'bold':
        return 'bold';
    }
  }

  private get textDom(): HTMLDivElement {
    let dom = document.getElementById('text-decoration-line-editor');
    if (dom) {
      return dom as HTMLDivElement;
    } else {
      const dom = document.createElement('div');
      dom.id = 'text-decoration-line-editor';
      dom.contentEditable = 'true';
      dom.style.pointerEvents = 'none';
      dom.style.opacity = '0';
      dom.style.position = 'fixed';
      dom.style.top = '-100000px';
      dom.style.left = '-100000px';
      dom.style.background = '#ccc';
      dom.style.border = '1px solid red';
      dom.style.padding = '10px 10px';
      dom.style.userSelect = 'all';
      dom.style[('-webkit-user-select' as unknown) as number] = 'all';
      document.body.appendChild(dom);
      return dom;
    }
  }

  /**
   *  修改组件删除线，下划线 ,斜体， 加粗 样式
   * @memberof FontStyleHelper
   */
  changeTextFontStyle = (
    value: string,
    options: {
      type: 'strike' | 'underline' | 'bold' | 'italic';
      boolean: boolean | undefined;
    }[],
    oldFontStyle: FontStyle = {},
  ): { value: string; strike: boolean; underline: boolean; bold: boolean; italic: boolean } => {
    // FIXME: 考虑使用sanitizeHtml处理Safari
    this.textDom.innerHTML = value;
    const result = {
      value: value,
      strike: true,
      underline: true,
      bold: true,
      italic: true,
    };
    // NOTE: 这种方式会导致非预期的表单blur
    this.textDom.focus();
    const selection = document.getSelection();
    selection?.selectAllChildren(this.textDom);

    options.forEach((option) => {
      const command = this.getCommand(option.type);
      // NOTE: mac 上 document.queryCommandState('bold') 的返回值和 win 不一样，所以弃用
      const commandState =
        command === 'bold' ? queryRichTextBoldState(this.textDom) : document.queryCommandState(command);

      if (oldFontStyle[option.type] !== option.boolean && commandState !== option.boolean) {
        document.execCommand('styleWithCSS', false, 'true');
        document.execCommand(command, false);
      }

      result[option.type] = !!option.boolean;
    });
    selection?.empty();
    return { ...result, value: this.textDom.innerHTML };
  };

  /**
   *  获取组件value删除线，下划线样式，斜体， 加粗
   * @memberof FontStyleHelper
   */
  getTextFontStyle = (
    value: string,
  ): {
    strike: boolean;
    underline: boolean;
    italic: boolean;
    bold: boolean;
  } => {
    this.textDom.innerHTML = value;
    const selection = document.getSelection();
    const range = document.createRange();
    range.selectNodeContents(this.textDom);
    selection!.removeAllRanges();
    selection!.addRange(range);
    const result = {
      strike: document.queryCommandState(this.getCommand('strike')),
      underline: document.queryCommandState(this.getCommand('underline')),
      italic: document.queryCommandState(this.getCommand('italic')),
      bold: document.queryCommandState(this.getCommand('bold')),
    };
    selection!.empty();
    return result;
  };
}
