import { IExampleApp, IExampleCategory, IPageInfo, IExampleInfo } from '@fbs/rp/models/example';
import { ITeams } from '@fbs/idoc/models/team';
import { IAppsOfTeam } from '@fbs/teamManagement';

import { ActionType } from '../types';
import { ExampleActions } from './actions';

export interface IExampleState {
  apps: IExampleApp[];
  categories: IExampleCategory[];
  pageInfo: IPageInfo;
  teams: ITeams[];
  appSets: IAppsOfTeam[];
  defaultTeam?: ITeams;
}

const initState: IExampleState = {
  apps: [],
  categories: [],
  pageInfo: {
    pageIndex: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
  },
  teams: [],
  appSets: [],
};

export default (state: IExampleState = initState, action: ExampleActions): IExampleState => {
  const { apps } = state;
  const { type, payload } = action;
  switch (type) {
    case ActionType.Example_Load_All: {
      const { exampleCategories, examples, pageInfo } = payload as IExampleInfo;
      return {
        ...state,
        apps: examples,
        categories: exampleCategories,
        pageInfo,
      };
    }
    case ActionType.Example_Page_Down: {
      const { examples, pageInfo } = payload as IExampleInfo;
      return {
        ...state,
        apps: apps.concat(examples),
        pageInfo,
      };
    }
    case ActionType.Example_SetAllTeams:
      return {
        ...state,
        teams: payload as ITeams[],
      };
    case ActionType.Example_GetAppSetsByTeam:
      return {
        ...state,
        appSets: payload as IAppsOfTeam[],
      };
    case ActionType.Example_SetDefaultTeam:
      return {
        ...state,
        defaultTeam: payload as ITeams,
      };
    default:
      break;
  }
  return state;
};
