import * as React from 'react';
import classnames from 'classnames';
import { ISize } from '@fbs/common/models/common';

import './index.scss';
import { calcaulateSizeAndRealScaleWhenScale } from '@helpers/workspaceHelper';
import appOptions from '@helpers/appOptions';

interface IConnectorProps {
  //组件ID
  id: string;
  size: ISize;
  isShowConnector: boolean;
  isActiveParent?: boolean;
  scale?: number;

  //鼠标按下时通知工作区开始绘制连线
  onMouseDownStartConnect?(compId: string, direction: string): void;
  //鼠标抬起时通知工作区结束绘制连线
  onMouseUpEndConnect?(compId: string, direction: string): void;
  onControlMouseEnter?(compId: string, direction: string): void;
  onControlMouseLeave?(): void;
}

interface IConnectorState {
  scaleNum: number;
  isShowConnector: boolean;
}

// TODO: 处理这几个连接点相关的事件
//   1. 拖动连接点，不触发选中组件
//   2. 拖动连接点，开始画线
//   3. 拖动连接点，然后在某个连接点上释放鼠标，创建这两个连接点之间的连线
//   4. 拖动连接点，在空白地方释放鼠标，创建...
export default class Connector extends React.Component<IConnectorProps, IConnectorState> {
  constructor(props: IConnectorProps) {
    super(props);
    this.state = {
      scaleNum: props.scale || 1,
      isShowConnector: props.isShowConnector,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: IConnectorProps) {
    if (nextProps.scale && this.state.scaleNum !== nextProps.scale) {
      this.setState({ scaleNum: nextProps.scale });
    }
    if (this.state.isShowConnector !== nextProps.isShowConnector) {
      this.setState({ isShowConnector: nextProps.isShowConnector });
    }
  }

  shouldComponentUpdate(nextProps: IConnectorProps, nextState: IConnectorState) {
    //当缩放比改变，且鼠标在组件上时
    if (this.state.scaleNum !== nextState.scaleNum && nextState.isShowConnector && this.props.isActiveParent) {
      return true;
    }
    //当切换是否显示隐藏连接点时
    if (this.state.isShowConnector !== nextState.isShowConnector) {
      return true;
    }
    return false;
  }

  handleMouseDown = (direction: string, e: React.MouseEvent) => {
    if (!this.state.isShowConnector) {
      return;
    }
    e.stopPropagation();
    const { onMouseDownStartConnect } = this.props;
    onMouseDownStartConnect && onMouseDownStartConnect(this.props.id, direction);
  };

  // eslint-disable-next-line no-unused-vars
  handleMouseUp = (direction: string, e: React.MouseEvent) => {
    if (!this.state.isShowConnector) {
      return;
    }
    const { onMouseUpEndConnect } = this.props;
    onMouseUpEndConnect && onMouseUpEndConnect(this.props.id, direction);
  };

  handleMouseEnter = (direction: string) => {
    const { onControlMouseEnter } = this.props;
    onControlMouseEnter && onControlMouseEnter(this.props.id, direction);
  };

  handleMouseLeave = () => {
    const { onControlMouseLeave } = this.props;
    onControlMouseLeave && onControlMouseLeave();
  };

  caculateStyle = (direction: string) => {
    const { size: compSize } = this.props;
    // const { scaleNum: stateScale } = this.state;
    const scale = appOptions.scale;
    const initScaleNum = 1 / scale;
    const { controlSize, scaleNum } = calcaulateSizeAndRealScaleWhenScale(direction, compSize, scale, initScaleNum);
    let translateStr = '';
    if (direction === 'top' || direction === 'left') {
      translateStr = 'translate(-50%,-50%)';
    }
    if (direction === 'right') {
      translateStr = 'translate(50%, -50%)';
    }

    if (direction === 'bottom') {
      translateStr = 'translate(-50%, 50%)';
    }
    return {
      width: controlSize,
      height: controlSize,
      transform: `${translateStr} scale(${scaleNum})`,
    };
  };

  render() {
    const { isActiveParent } = this.props;
    const { isShowConnector } = this.state;
    const directions = ['top', 'right', 'bottom', 'left'];
    return (
      <div className={classnames('connector')}>
        {directions.map((direction) => {
          const styles = this.caculateStyle(direction);
          return (
            <div
              key={direction}
              className={classnames(direction)}
              style={styles}
              onMouseDown={this.handleMouseDown.bind(this, direction)}
              onMouseUp={this.handleMouseUp.bind(this, direction)}
              onMouseEnter={this.handleMouseEnter.bind(this, direction)}
              onMouseLeave={this.handleMouseLeave}
            >
              {isShowConnector && isActiveParent && <span className="connector-icon" />}
            </div>
          );
        })}
      </div>
    );
  }
}
