import * as React from 'react';
import classnames from 'classnames';

import Icon from '../Icon';

import './index.scss';
import { INode } from '../Tree/model';
import { findParentNodeByID, isNodeOrChildrenMatch } from '../Tree/utils';
import { isCtrlKey } from '../utils';

interface ILayerStyleProps {
  data: {
    styleType: string;
    styleID: string;
    type: string;
    opacity: number;
    blendingMode: string;
    borderOptions: {
      startArrowhead: string;
      endArrowhead: string;
      dashPattern: number[];
      lineEnd: string;
      lineJoin: string;
    };
    blur: {
      center: {
        x: number;
        y: number;
      };
      motionAngle: number;
      radius: number;
      enabled: boolean;
      blurType: string;
    };
    fills: {
      fillType: string;
      color: string;
      gradient: {
        gradientType: string;
        from: {
          x: number;
          y: number;
        };
        to: {
          x: number;
          y: number;
        };
        aspectRatio: number;
        stops: {
          position: number;
          color: string;
        }[];
      };
      pattern: {
        patternType: string;
        image: any;
        tileScale: number;
      };
      enabled: boolean;
    }[];
    borders: {
      fillType: string;
      position: string;
      color: string;
      gradient: {
        gradientType: string;
        from: {
          x: number;
          y: number;
        };
        to: {
          x: number;
          y: number;
        };
        aspectRatio: number;
        stops: {
          position: number;
          color: string;
        }[];
      };
      thickness: number;
      enabled: boolean;
    }[];
    shadows: {
      blur: number;
      x: number;
      y: number;
      spread: number;
      color: string;
      enabled: boolean;
    }[];
    innerShadows: {
      blur: number;
      x: number;
      y: number;
      spread: number;
      color: string;
      enabled: boolean;
    }[];
  };
}

class LayerStyle extends React.Component<ILayerStyleProps> {
  render() {
    const { borderOptions, blur, fills, borders, shadows, innerShadows } = this.props.data;
    let blurCss = '';
    if (blur && blur.enabled) {
      blurCss = `blur(${blur.radius})`;
    }
    return (
      <div className={classnames('layer-style-content', {})}>
        <div
          className={classnames('layer-style-box', {})}
          style={{
            filter: blurCss,
          }}
        >
          {fills.length > 0 &&
            fills.map((item, index) => {
              if (!item.enabled) {
                return null;
              }
              let top = '50%';
              let left = '50%';
              let transform;
              let background = item.color;
              let desc = 'to bottom';
              let arr: string[] = [];
              if (item.fillType === 'Gradient') {
                switch (item.gradient.gradientType) {
                  case 'Linear':
                    item.gradient.stops.map((stops) => {
                      arr.push(stops.color);
                    });
                    if (item.gradient.from.x === item.gradient.to.x) {
                      desc = `to ${item.gradient.from.y > item.gradient.to.y ? 'top' : 'bottom'}`;
                    } else if (item.gradient.from.y === item.gradient.to.y) {
                      desc = `to ${item.gradient.from.x > item.gradient.to.x ? 'left' : 'right'}`;
                    } else {
                      desc = `to ${item.gradient.from.y > item.gradient.to.y ? 'top' : 'bottom'} ${
                        item.gradient.from.x > item.gradient.to.x ? 'left' : 'right'
                      }`;
                    }
                    background = `linear-gradient(${desc}, ${arr.join(', ')})`;
                    break;
                  case 'Radial':
                    item.gradient.stops.map((stops) => {
                      arr.push(`${stops.color} ${Math.round(stops.position * 100)}%`);
                    });
                    background = `radial-gradient(${arr.join(', ')})`;
                    break;
                  case 'Angular':
                    if (item.gradient.stops.length === 2) {
                      background = `conic-gradient(${item.gradient.stops[0].color}, ${item.gradient.stops[1].color})`;
                    } else {
                      item.gradient.stops.map((stops) => {
                        arr.push(`${stops.color} ${Math.round(stops.position * 100)}%`);
                      });
                      background = `conic-gradient(${arr.join(', ')})`;
                    }
                    transform = 'rotate(90deg)';
                    top = '4px';
                    left = '4px';
                    break;
                  default:
                    background = item.color;
                    break;
                }
              }
              return (
                <span
                  className="layer-style-item"
                  key={index}
                  style={{
                    background: background,
                    transform: transform,
                    left,
                    top,
                  }}
                />
              );
            })}

          {borders.length > 0 &&
            borders.map((item, index) => {
              if (!item.enabled) {
                return null;
              }
              return (
                <span
                  className="layer-style-item borders"
                  key={index}
                  style={{
                    border: `${item.thickness}px solid ${item.color}`,
                  }}
                />
              );
            })}
          {shadows.length > 0 &&
            shadows.map((item, index) => {
              if (!item.enabled) {
                return null;
              }
              return (
                <span
                  className="layer-style-item"
                  key={index}
                  style={{
                    boxShadow: `${item.x}px ${item.y}px ${item.blur}px ${item.spread}px ${item.color}`,
                  }}
                />
              );
            })}
          {innerShadows.length > 0 &&
            innerShadows.map((item, index) => {
              if (!item.enabled) {
                return null;
              }
              return (
                <span
                  className="layer-style-item"
                  key={index}
                  style={{
                    boxShadow: `${item.x}px ${item.y}px ${item.blur}px ${item.spread}px ${item.color} inset`,
                  }}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

export default LayerStyle;
