import { each, isString, isUndefined } from 'lodash';

import ILanguage from './local/type';

import zh_CN from './local/zh_CN';
import en_US from './local/en_US';
import zh_TW from './local/zh_TW';
import { getOfflineDemoData } from '../apis/offlineDemo/data';
import { getEnumName, getEnumValue } from '@utils/enumUtils';
import { parseUrlSearch } from '../utils/urlUtils';
import { MockplusEnv } from '../components/ClientLogin/utils/config';

import { PreviewUrlSearchKey } from '@/fbs/rp/utils/preview';
import { LanguageIDs } from '@/consts/enums/language';
import { isMockRPD } from '@utils/envUtils';

declare global {
  interface Window {
    env: MockplusEnv; // 桌面端可能会注入
  }
}
class LanguageManager {
  static get simple(): () => LanguageManager {
    let manager: LanguageManager;
    return () => {
      if (!manager) {
        manager = new LanguageManager();
      }
      return manager;
    };
  }

  private useLanguage: ILanguage = zh_CN;

  private languageList: { [key: string]: ILanguage } = {
    [LanguageIDs.zhCN]: zh_CN,
    [LanguageIDs.enUS]: en_US,
    [LanguageIDs.zhTW]: zh_TW,
  };

  constructor() {
    this.useLanguage = this.languageConfig;
  }

  get currentLanguage(): LanguageIDs {
    try {
      // 离线演示包
      if (RP_CONFIGS.isOfflineDemo) {
        return (getOfflineDemoData()?.config.lang || LanguageIDs.zhCN) as LanguageIDs;
      }
      if (RP_CONFIGS.isPrivateDeployment) {
        if (this.isEnVersion) {
          return LanguageIDs.enUS;
        }
        // 手机端演示私有部署支持语言切换
        const search = parseUrlSearch();
        if (search[PreviewUrlSearchKey.PhoneUrl] === 'true') {
          const key = getEnumName(LanguageIDs, search[PreviewUrlSearchKey.XMockplusLang]);
          if (key) {
            return getEnumValue(LanguageIDs, key) as LanguageIDs;
          }
        }
        const userLanguage = getEnumName(LanguageIDs, window.USER_LANG);
        if (userLanguage) {
          return getEnumValue(LanguageIDs, userLanguage);
        }
      }
      return (window?.LANG || LanguageIDs.zhCN) as LanguageIDs;
    } catch {
      return LanguageIDs.zhCN;
    }
  }

  /**
   * 当前的版本（中文版本还是英文版本）
   * @returns {LanguageIDs}
   */
  get languageVersion(): LanguageIDs {
    try {
      return (window?.LANG || LanguageIDs.zhCN) as LanguageIDs;
    } catch {
      return LanguageIDs.zhCN;
    }
  }

  /**
   * 当前是否中文版本
   * @returns {boolean}
   */
  get isZHVersion(): boolean {
    const version = this.languageVersion;
    return version === LanguageIDs.zhTW || version === LanguageIDs.zhCN;
  }

  /**
   * 当前是否英文版本
   * @returns {boolean}
   */
  get isEnVersion(): boolean {
    return !this.isZHVersion;
  }

  /**
   * 当前是否显示中文界面
   * @returns {boolean}
   */
  get isZHLanguage(): boolean {
    const lang = this.currentLanguage;
    return lang === LanguageIDs.zhCN || lang === LanguageIDs.zhTW;
  }

  /**
   * 当前是否显示英文界面
   * @returns {boolean}
   */
  get isEnLanguage(): boolean {
    return this.currentLanguage === LanguageIDs.enUS;
  }

  /**
   * 当前语言配置，i18n中的对应的语言文件内容
   * @returns {ILanguage}
   */
  get languageConfig(): ILanguage {
    if (isMockRPD) {
      if (
        window.env === MockplusEnv.EnProduction ||
        window.env === MockplusEnv.EnTest ||
        window.env === MockplusEnv.EnDemo
      ) {
        return this.languageList[LanguageIDs.enUS];
      }
      return this.languageList[LanguageIDs.zhCN];
    } else {
      return this.languageList[this.currentLanguage];
    }
  }

  /**
   * 获取一个语言配置中第一级中指定key的节点内容
   * @param {string} name
   * @returns {string | ILanguage}
   */
  getLanguageValue(name: string): string | ILanguage {
    return this.useLanguage[name];
  }
}

export const languageManager = LanguageManager.simple();

export default (key: string, ...args: any[]): string => {
  let pathArr: string[];
  let value: any;
  pathArr = key.split('.');
  value = void 0;
  each(pathArr, (name: string) => {
    if (isUndefined(value)) {
      return (value = languageManager.getLanguageValue(name));
    }
    return (value = value[name]);
  });
  if (isUndefined(value)) {
    console.log(`i18n 错误: ${key} not exist`);
    return '';
  }
  if (!isString(value)) {
    console.log(`[i18n error] ${key} not string`);
    return '';
  }
  return value.replace(/{(\d+)}/g, (match: string, index: number) => {
    if (isUndefined(args[index])) {
      return match;
    }
    return args[index];
  });
};
