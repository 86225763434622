import { StrokeLineCap, StrokeLineJoin } from '@/fbs/rp/models/properties/stroke';
import { DefaultStrokeColor, DefaultWhiteFillColor, DefaultTextColor } from '@consts/colors';
import { ELegendPosition } from '@/fbs/rp/models/properties/chart';
import { IChartPopup } from '@/fbs/rp/models/properties/chart';

import { FillType } from '@/utils/graphicsUtils';
import { rgba2hex } from '@/utils/graphicsUtils';
import { StyleHelper } from '@/helpers/styleHelper';
import i18n from '@/i18n';
import * as SystemsColor from '@consts/colors';
import { SpriteThumb } from '@consts/spriteIcons';
import { PredefinedStates } from '@consts/state';

import {
  CBarChart,
  CBarChartHorizontal,
  CLineChart,
  CAreaChart,
  CScatterChart,
  CRadarChart,
  CPieChart,
  CDoughnutChart,
  CCombinationChart,
  CMapChart,
} from '../constants';
import { getDefaultShadow } from '../helper';

export const legendPositionList = [
  { value: ELegendPosition.Bottom, name: i18n('property.component.legendPosition_bottom') },
  { value: ELegendPosition.Left, name: i18n('property.component.legendPosition_left') },
  { value: ELegendPosition.Right, name: i18n('property.component.legendPosition_right') },
  { value: ELegendPosition.Top, name: i18n('property.component.legendPosition_top') },
];

// 默认颜色配置
export const DefaultChartColorList = [
  { r: 0, g: 157, b: 255, a: 1 }, //  '#009DFF'
  { r: 34, g: 228, b: 255, a: 1 }, //  '#22E4FF'
  { r: 59, g: 255, b: 208, a: 1 }, //  '#3BFFD0'
  { r: 4, g: 227, b: 138, a: 1 }, //  '#04E38A'
  { r: 157, g: 255, b: 134, a: 1 }, //  '#9DFF86'
  { r: 254, g: 229, b: 136, a: 1 }, //  '#FEE588'
  { r: 254, g: 168, b: 68, a: 1 }, //  '#FEA844'
  { r: 254, g: 112, b: 68, a: 1 }, //  '#FE7044'
  { r: 254, g: 68, b: 68, a: 1 }, //  '#FE4444'
  { r: 255, g: 124, b: 140, a: 1 }, //  '#FF7C8C'
];

export const getChartCommonProperties = () => {
  return {
    fill: {
      type: FillType.solid,
      color: SystemsColor.DefaultWhiteFillColor,
      disabled: true,
    },
    stroke: {
      thickness: 1,
      color: SystemsColor.DefaultStrokeColor1,
      cap: StrokeLineCap.Round,
      join: StrokeLineJoin.Round,
      disabled: true,
    },
    shadow: getDefaultShadow(),
    radius: {
      topRight: 2,
      topLeft: 2,
      bottomLeft: 2,
      bottomRight: 2,
      isPercent: false,
      disabled: false,
    },
    border: {
      top: true,
      left: true,
      right: true,
      bottom: true,
    },
    textStyle: {
      fontFamily: 'Microsoft YaHei',
      fontSize: 14,
      color: SystemsColor.DefaultTextColor,
    },
  };
};

export const ChartAxisNameConfig: { [key: string]: { x: string; y: string } } = {
  [CBarChart]: {
    x: 'resource.componentsText.xAxisText',
    y: 'resource.componentsText.yAxisText',
  },
  [CBarChartHorizontal]: {
    x: 'resource.componentsText.xAxisText',
    y: 'resource.componentsText.yAxisText',
  },
  [CLineChart]: {
    x: 'resource.componentsText.xAxisText',
    y: 'resource.componentsText.yAxisText',
  },
  [CAreaChart]: {
    x: 'resource.componentsText.xAxisText',
    y: 'resource.componentsText.yAxisText',
  },
  [CScatterChart]: {
    x: 'resource.componentsText.xAxisText',
    y: 'resource.componentsText.yAxisText',
  },
  [CRadarChart]: {
    x: 'resource.componentsText.xAxisText',
    y: 'resource.componentsText.yAxisText',
  },
  [CPieChart]: {
    x: 'resource.componentsText.xAxisText',
    y: 'resource.componentsText.xAxisText',
  },
  [CDoughnutChart]: {
    x: 'resource.componentsText.xAxisText',
    y: 'resource.componentsText.xAxisText',
  },
  [CCombinationChart]: {
    x: 'resource.componentsText.xAxisText',
    y: 'resource.componentsText.yAxisText',
  },
  [CMapChart]: {
    x: 'resource.componentsText.mapAxisText',
    y: 'resource.componentsText.mapAxisText',
  },
};

type AllChartType =
  | typeof CPieChart
  | typeof CDoughnutChart
  | typeof CBarChart
  | typeof CBarChartHorizontal
  | typeof CAreaChart
  | typeof CLineChart
  | typeof CRadarChart
  | typeof CScatterChart
  | typeof CCombinationChart;

const ALL_CHART_CONFIG_PARTIAL = {
  [CPieChart]: {
    name: i18n('resource.components.pieChart'),
    thumb: {
      spriteIconClass: SpriteThumb.Pie.className,
      dragPosition: SpriteThumb.Pie.position,
    },
  },
  [CDoughnutChart]: {
    name: i18n('resource.components.doughnutChart'),
    thumb: {
      spriteIconClass: SpriteThumb.DoughnutChart.className,
      dragPosition: SpriteThumb.DoughnutChart.position,
    },
  },
  [CBarChart]: {
    name: i18n('resource.components.barChart'),
    thumb: {
      spriteIconClass: SpriteThumb.BarChart.className,
      dragPosition: SpriteThumb.BarChart.position,
    },
  },
  [CScatterChart]: {
    name: i18n('resource.components.scatterChart'),
    thumb: {
      spriteIconClass: SpriteThumb.ScatterChart.className,
      dragPosition: SpriteThumb.ScatterChart.position,
    },
  },
  [CAreaChart]: {
    name: i18n('resource.components.areaChart'),
    thumb: {
      spriteIconClass: SpriteThumb.AreaChart.className,
      dragPosition: SpriteThumb.AreaChart.position,
    },
  },
  [CLineChart]: {
    name: i18n('resource.components.lineChart'),
    thumb: {
      spriteIconClass: SpriteThumb.LineChart.className,
      dragPosition: SpriteThumb.LineChart.position,
    },
  },
  [CRadarChart]: {
    name: i18n('resource.components.radarChart'),
    thumb: {
      spriteIconClass: SpriteThumb.RadarChart.className,
      dragPosition: SpriteThumb.RadarChart.position,
    },
  },
  [CBarChartHorizontal]: {
    name: i18n('resource.components.barChartHorizontal'),
    thumb: {
      spriteIconClass: SpriteThumb.BarChartHorizontal.className,
      dragPosition: SpriteThumb.BarChartHorizontal.position,
    },
  },
  [CCombinationChart]: {
    name: i18n('resource.components.combinationChart'),
    thumb: {
      spriteIconClass: SpriteThumb.CombinationChart.className,
      dragPosition: SpriteThumb.CombinationChart.position,
    },
  },
};

export const getChartConfigPartial = (type: AllChartType) => {
  const cfg = ALL_CHART_CONFIG_PARTIAL[type];
  // @ts-ignore
  cfg.predefinedStates = [PredefinedStates.disabled, PredefinedStates.hover, PredefinedStates.pressed];
  return cfg;
};

export const getCharPopupDefault = () => {
  return {
    disabled: false,
    border: {
      disabled: true,
      color: DefaultStrokeColor,
    },
    fill: {
      disabled: false,
      color: DefaultWhiteFillColor,
    },
    textStyle: {
      fontFamily: 'Microsoft YaHei',
      fontSize: 12,
      color: DefaultTextColor,
    },
  };
};

export const setChartsTooltip = (config: IChartPopup) => {
  let tooltip = null;
  let popupFillColor = config.fill.color;
  if (config.fill.disabled && popupFillColor instanceof Object) {
    popupFillColor = { ...popupFillColor, a: 0 };
  }
  if (!config.disabled) {
    tooltip = {
      trigger: 'item',
      backgroundColor: rgba2hex(popupFillColor),
      borderColor: rgba2hex(config.border.color),
      borderWidth: config.border.disabled ? 0 : 1,
      textStyle: { ...StyleHelper.initCSSStyleParser({ textStyle: config.textStyle }).doGetTextStyle() },
      padding: [8, 16],
    };
  }
  return tooltip;
};
