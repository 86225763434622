import * as React from 'react';

import { NUMBER_FILTER_CHARS } from '@/utils/textUtils';
import { ISize } from '@utils/boundsUtils';
import { validateNumberText } from '@utils/globalUtils';

import { Input, withAutoClose, IAutoCloseComponentProps } from '@dsm';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';

import { TextAlign } from '@fbs/rp/models/properties/text';

import { MouseButton } from '@/consts/enums/mouseButton';

import PureTextAreaEditor from '../PureTextAreaEditor';

import './index.scss';

export interface IPureTextEditorProp extends IAutoCloseComponentProps {
  wrap: boolean;
  size?: ISize;

  value: string | number;
  valueType?: 'text' | 'number';
  textAlign?: TextAlign; //编辑中文本的位置
  fontSize?: number;
  position?: { left: number; top: number };
  style?: React.CSSProperties;
  applyStyle?: boolean;
  maxlength?: number;
  hocRef?: React.RefObject<any>;
  onClose(): void;
  onTyped?: () => void;
  onChange: (value: string) => void;
  onMouseDown?: React.MouseEventHandler;
}

export interface IState {
  value: string | number;
  areaTopWhenScroll: number;
}

export class PureTextEditor extends React.Component<IPureTextEditorProp, IState> {
  private inputRef: React.RefObject<Input | PureTextAreaEditor> = React.createRef();

  get inputDom() {
    const component = this.inputRef.current;
    if (component instanceof Input) {
      return component.input;
    }
    return component?.textarea;
  }

  reloadValue() {
    this.setState(
      {
        value: this.props.value || '',
      },
      () => {
        const inputComp = this.inputRef.current;
        if (inputComp) {
          inputComp.reloadValue();
        }
      },
    );
  }

  constructor(props: IPureTextEditorProp) {
    super(props);

    this.state = { value: props.value || '', areaTopWhenScroll: 0 };
  }

  cancel = () => {
    const { value, onChange } = this.props;
    const newValue = this.inputRef.current?.value;
    if (newValue !== value) {
      onChange(newValue || '');
    }
  };

  handleInputBlur = (newValue: string | number) => {
    const { onClose, onChange, value } = this.props;
    let text = newValue;
    if (typeof value === 'number') {
      let str = `${text}`;
      if (str[str.length - 1] === '.') {
        str = str.substring(0, str.length - 1);
        text = parseFloat(str);
      }
    }
    if (newValue !== value) {
      onChange(text as string);
    }
    onClose();
  };

  handleTextAreaKeyDown = (e: React.KeyboardEvent) => {
    const { value, onClose, onChange } = this.props;
    if ((e.ctrlKey || e.metaKey) && e.keyCode === KeyCodeMap.VK_ENTER) {
      const newValue = (e.target as HTMLTextAreaElement).value;
      if (newValue !== value) {
        onChange(newValue);
      }
      onClose();
    }
    e.stopPropagation();
  };

  handleTextAreaInput = (value: string) => {
    this.setState({ value });
  };

  handleMouseWheel = (e: React.WheelEvent) => {
    e.stopPropagation();
  };

  handleInputChange = (str: string) => {
    const { valueType } = this.props;
    // 如果是数字输入时，忽略多余小数
    if (valueType === 'number') {
      return validateNumberText(str);
    }
    return str;
  };

  handleMouseDown = (e: React.MouseEvent) => {
    if (e.button === MouseButton.Right) {
      e.stopPropagation();
      if (this.props.hocRef?.current) {
        this.props.hocRef.current.isModal = true;
      }
    }
    this.props.onMouseDown && this.props.onMouseDown(e);
  };

  handleContextMenu = () => {
    if (this.props.hocRef?.current) {
      this.props.hocRef.current.isModal = false;
    }
  };

  handleEditorContextMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  render() {
    const { wrap, value, valueType, size, position, textAlign, fontSize, maxlength, onTyped } = this.props;
    const { value: text } = this.state;
    return (
      <div
        className="pure-text-editor"
        style={position}
        ref={this.props.forwardedRef}
        onContextMenu={this.handleEditorContextMenu}
        onWheel={this.handleMouseWheel}
      >
        {wrap && size && (
          <PureTextAreaEditor
            ref={this.inputRef as React.RefObject<PureTextAreaEditor>}
            width={size.width}
            height={size.height}
            value={value as string}
            onKeyDown={this.handleTextAreaKeyDown}
            onChange={this.handleTextAreaInput}
            autoFocus={true}
            autoSelectWhenFocus={true}
            onTyped={onTyped}
          />
        )}
        {!wrap && size && (
          <Input
            ref={this.inputRef as React.RefObject<Input>}
            autoFocus
            autoSelectWhenFocus
            submitWithBlur
            maxlength={maxlength}
            value={`${text}`}
            filterChar={valueType === 'number' ? NUMBER_FILTER_CHARS : undefined}
            width={size.width}
            style={{ height: size.height }}
            textAlign={textAlign}
            fontSize={fontSize}
            them={this.props.applyStyle ? 'no-border-padding' : 'normal'}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            onKeyUp={(e) => {
              e.stopPropagation();
            }}
            onBlur={this.handleInputBlur}
            onValidate={this.handleInputChange}
            onMouseDown={this.handleMouseDown}
            onContextMenu={this.handleContextMenu}
            onTyped={onTyped}
          />
        )}
      </div>
    );
  }
}

export default withAutoClose<IPureTextEditorProp>(PureTextEditor);
