import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

export interface IProgressBarProps {
  className?: string;
  colorTheme?: 'blue' | 'red';
  progress?: number;
  hiddenTips?: boolean;
  height?: number;
  progressTransition?: boolean;
  intervalTime?: number;
  onAnimationEnd?: () => void;
}

const ProgressBar: React.FC<IProgressBarProps> = (props: IProgressBarProps) => {
  const {
    className,
    progress,
    colorTheme,
    height,
    hiddenTips,
    progressTransition = true,
    onAnimationEnd,
    intervalTime = 500,
  } = props;

  const [, setStateUpdate] = React.useState(false);
  const theme = colorTheme || 'red';
  const animationInfo = React.useRef<{
    progressTransition: boolean;
    stop: boolean;
    progress: number;
    currentProgress: number;
    animateId?: NodeJS.Timeout;
    intervalTime: number;
    onAnimationEnd?: () => void;
  }>({
    progressTransition: progressTransition,
    stop: false,
    progress: 0,
    currentProgress: 0,
    animateId: undefined,
    intervalTime: 500,
  });
  const forceUpdate = React.useCallback(() => {
    setStateUpdate((v) => !v);
  }, []);

  animationInfo.current.progress = progress!;
  animationInfo.current.onAnimationEnd = onAnimationEnd;
  animationInfo.current.intervalTime = intervalTime;

  React.useLayoutEffect(() => {
    if (animationInfo.current.progress === 0) {
      animationInfo.current.stop = false;
      animationInfo.current.currentProgress = 0;
    }
    if (!animationInfo.current.progressTransition || animationInfo.current.stop) {
      return;
    }
    if (animationInfo.current.animateId) {
      clearTimeout(animationInfo.current.animateId);
      animationInfo.current.animateId = undefined;
    }

    let startTime = 0;
    const startValue = animationInfo.current.currentProgress;
    const endValue = animationInfo.current.progress;
    const duration = Math.ceil((endValue - startValue) / 10) * animationInfo.current.intervalTime;
    const ms = 1000 / 60;
    const animationHandle = () => {
      // 这里可能导致进度条不动情况，去掉
      // if (!startTime) {
      //   startTime = Date.now();
      // } else {
      const time = Date.now() - startTime;
      const p = Math.min(1, Math.max(0, time / duration));
      const currentValue = (1 - p) * startValue + p * endValue;
      animationInfo.current.currentProgress = Math.floor(currentValue);
      forceUpdate();
      if (p >= 1 && currentValue >= 100) {
        animationInfo.current.stop = true;
        animationInfo.current.onAnimationEnd?.();
      }
      if (p >= 1) {
        return;
      }
      // }
      animationInfo.current.animateId = setTimeout(animationHandle, ms);
    };
    animationInfo.current.animateId = setTimeout(animationHandle, ms);
    return () => {
      if (animationInfo.current.animateId) {
        clearTimeout(animationInfo.current.animateId);
        animationInfo.current.animateId = undefined;
      }
    };
  }, [progress, forceUpdate]);

  const percentageUploadProgress = `${animationInfo.current.currentProgress}%`;

  return (
    <div className={classnames('dsm-c-rp-progressbar', className, theme)}>
      <div className="progress-track" style={{ height }}>
        <div
          className="progress-track-rate"
          style={{
            width: percentageUploadProgress,
          }}
        ></div>
      </div>
      {!hiddenTips && (
        <i className="progress-tips" style={{ left: percentageUploadProgress }}>
          {animationInfo.current.currentProgress}
        </i>
      )}
    </div>
  );
};

ProgressBar.defaultProps = {
  progress: 0,
};

export default ProgressBar;
