import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// '扇形图'
export default {
  ...flowBase,
  name: i18n('resource.flow.sectorDiagram'),
  type: 'sectorDiagram',
  thumb: {
    spriteIconClass: SpriteThumb.SectorDiagram.className,
    dragPosition: SpriteThumb.SectorDiagram.position,
  },
  template: getShapeData(
    i18n('resource.flow.sectorDiagram'),
    {
      width: 60,
      lockedRatio: false,
      height: 45,
    },
    {
      data: [
        {
          point: {
            x: 0,
            y: 15,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 15,
            y: 45,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 30,
            y: 40,
          },
          handleIn: {
            x: -9,
            y: 0,
          },
          handleOut: {
            x: 9,
            y: -0,
          },
        },
        {
          point: {
            x: 45,
            y: 45,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 60,
            y: 15,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 30,
            y: 0,
          },
          handleIn: {
            x: 20,
            y: 0,
          },
          handleOut: {
            x: -20,
            y: 0,
          },
        },
      ],
      closed: true,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
