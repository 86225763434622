import { WorkerBase } from './WorkerBase';
import { ICommand } from '../types';
import PageSkipCommand from '../Commands/PageSkipCommand';
import CommandBase from '../Commands/CommandBase';

export default class SequenceWorker extends WorkerBase {
  protected start() {
    this.executeNext(this.nextCommand);
  }

  protected doFinishCommand(cmd: ICommand) {
    if (cmd instanceof PageSkipCommand) {
      this.finish();
      return;
    }
    if (cmd instanceof CommandBase) {
      cmd.startSubWorker();
    }
    this.doSkipToNextIndex();
    if (this.isFinish) {
      this.finish();
    } else if (this.isRunning) {
      this.executeNext(this.nextCommand);
    }
  }
}
