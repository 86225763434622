//面板大小限制
export const maxArtboardSize = 20000;
export const minArtboardSize = 5;
// 将画板的最大高度改为50,000 ，最大宽度不变
export const maxArtboardHeight = 50000;

//自动滚动每次循环移动的默认长度, 可用来调节移动速度
export const autoScrollStep = 10;

//鼠标样式
export const CursorTypesArr = [
  'nw-resize',
  'ne-resize',
  'se-resize',
  'sw-resize',
  'ns-resize',
  'ew-resize',
  'ns-resize',
  'ew-resize',
];

//面板内控制点的大小
// export const controlShowSize = 6;

//流程线连接点的拓展范围
export const maxControlSize = 16;
// export const minControlSize = 10;

export const outSpace = 50;
