import * as React from 'react';
import classNames from 'classnames';

import { Icon } from '@/dsm';

import { MobileTypeList, previewAndDownWatchTypeList, VehicleTypeList } from '@/helpers/shellHelper';
import { MobileType } from '@/fbs/rp/utils/preview';
import { IAppType } from '@/fbs/teamManagement/models/app';
import i18n from '@/i18n';

import Popup, { Placement, RefProps } from '../../Popup';

import './index.scss';

interface IEquipmentSelectProps {
  onSelect(id: MobileType): void;
  value?: MobileType;
  type?: string;
  isWatch?: boolean;
  isVehicle?: boolean;
  isMobile?: boolean;
}

const EquipmentSelect: React.FC<IEquipmentSelectProps> = (props) => {
  const popupRef = React.useRef<RefProps | null>(null);

  let type: string = '';
  const { isWatch, isVehicle, isMobile } = props;
  if (isWatch) {
    type = IAppType.Watch;
  } else if (isVehicle) {
    type = IAppType.Vehicle;
  } else if (isMobile) {
    type = IAppType.Phone;
  }

  const getList = () => {
    // const { type } = props;

    const listMap = {
      [IAppType.Phone]: MobileTypeList,
      [IAppType.Pad]: MobileTypeList,
      [IAppType.Watch]: previewAndDownWatchTypeList,
      [IAppType.Vehicle]: VehicleTypeList,
    };

    return type ? listMap[type as keyof typeof listMap] : MobileTypeList;
  };

  const handleSelect = (id: MobileType) => {
    props.onSelect(id);
    popupRef.current?.onClose();
  };

  const renderOverlay = () => {
    return (
      <div className="equipment-select-list">
        {getList().map((item) => {
          return (
            <div
              key={item.id}
              className={classNames(
                'equipment-select-item',
                item.id === props.value && 'equipment-select-item--active',
              )}
              onClick={() => handleSelect(item.id)}
            >
              {item.text}
            </div>
          );
        })}
      </div>
    );
  };

  const renderValue = () => {
    return getList().find((item) => item.id === props.value)?.text || i18n('general.none');
  };
  return (
    <Popup
      placement={Placement.Bottom}
      overlay={renderOverlay()}
      popupWidth={154}
      offset={[4, -4]}
      ref={popupRef}
      className="popu-equipment-select"
    >
      <div className="equipment-select">
        <div className="select-value">{renderValue()}</div>
        <Icon cls="tag_downarrow" />
      </div>
    </Popup>
  );
};

export default EquipmentSelect;
