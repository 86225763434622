/*\
|*|
|*|  :: cookies.ts ::
|*|
|*|  具有完全unicode支持的完整Cookie读写器框架。
|*|
|*|  https://developer.mozilla.org/en-US/docs/DOM/document.cookie
|*|
|*|  此框架是在GNU公共许可证版本3或更高版本下发布的
|*|  http://www.gnu.org/licenses/gpl-3.0-standalone.html
|*|
|*|  语法 Syntaxes:
|*|
|*|  * docCookies.setItem(name, value[, end[, path[, domain[, secure]]]])
|*|  * docCookies.getItem(name)
|*|  * docCookies.removeItem(name[, path], domain)
|*|  * docCookies.hasItem(name)
|*|  * docCookies.keys()
|*|
\*/

export default class DocCookies {
  static cookie = new DocCookies();

  constructor() {
    if (DocCookies.cookie) {
      throw new Error('单例不允许在其他地方实例化');
    }
  }

  getItem(sKey: string): string | null {
    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            '(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$',
          ),
          '$1',
        ),
      ) || null
    );
  }

  setItem(
    sKey: string,
    sValue: string,
    vEnd: Date = new Date(),
    sPath?: string,
    sDomain?: string,
    bSecure?: string,
  ): boolean {
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
      return false;
    }
    const sExpires = '; expires=' + vEnd.toUTCString();
    document.cookie =
      encodeURIComponent(sKey) +
      '=' +
      encodeURIComponent(sValue) +
      sExpires +
      (sDomain ? '; domain=' + sDomain : '') +
      (sPath ? '; path=' + sPath : '') +
      (bSecure ? '; secure' : '');
    return true;
  }

  removeItem(sKey: string, sPath?: string, sDomain?: string): boolean {
    if (!sKey || !this.hasItem(sKey)) {
      return false;
    }
    document.cookie =
      encodeURIComponent(sKey) +
      '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
      (sDomain ? '; domain=' + sDomain : '') +
      (sPath ? '; path=' + sPath : '');
    return true;
  }

  hasItem(sKey: string): boolean {
    return new RegExp('(?:^|;\\s*)' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=').test(
      document.cookie,
    );
  }

  keys(): string[] {
    var allKeys = document.cookie
      .replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, '')
      .split(/\s*(?:=[^;]*)?;\s*/);
    for (var nIdx = 0; nIdx < allKeys.length; nIdx++) {
      allKeys[nIdx] = decodeURIComponent(allKeys[nIdx]);
    }
    return allKeys;
  }
}
