import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

export interface ICustomLinkProps {
  href?: string;
  className?: string;
  disabled?: boolean;

  onClick?(e: React.MouseEvent<HTMLElement>): void;

  theme?: 'basic' | 'red' | 'blue' | 'hover-blue';
  target?: string;
  children?: any;
  stopPropagation?: boolean;
}

const CustomLink: React.FC<ICustomLinkProps> = (props: ICustomLinkProps) => {
  const { href, className, onClick, theme, target, children, disabled, stopPropagation } = props;
  const clsParams: {
    [cls: string]: boolean;
  } = {
    'link-a': theme === 'basic',
    'link-b': theme === 'red',
    'link-c': theme === 'blue',
    'link-d': theme === 'hover-blue',
    'link-disabled': disabled || false,
  };
  if (className) {
    clsParams[className] = true;
  }
  return href ? (
    <a
      className={classnames('dsm-c-link', className, {
        'dsm-c-link-basic': theme === 'basic',
        'dsm-c-link-red': theme === 'red',
        'dsm-c-link-blue': theme === 'blue',
        'dsm-c-link-hover-blue': theme === 'hover-blue',
        'link-disabled': disabled,
      })}
      href={href}
      target={target}
      onClick={(e) => stopPropagation && e.stopPropagation()}
    >
      {children}
    </a>
  ) : (
    <span className={classnames(clsParams)} onClick={onClick}>
      {children}
    </span>
  );
};

CustomLink.defaultProps = {
  theme: 'red',
  href: '',
  target: '_self',
  stopPropagation: true,
};

export default CustomLink;
