import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';

import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';
import { CFlowOctagon } from '../constants';

const basePosition = '(Math.min(sw,sh)*(15/60))';

// 八边形
export default {
  ...flowBase,
  name: i18n('resource.flow.octagon'),
  type: CFlowOctagon,
  thumb: {
    spriteIconClass: SpriteThumb.Octagon.className,
    dragPosition: SpriteThumb.Octagon.position,
  },
  template: getShapeData(
    i18n('resource.flow.octagon'),
    {
      width: 60,
      lockedRatio: false,
      height: 60,
    },
    {
      data: [
        {
          point: {
            x: 15,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 15,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 45,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 15,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 45,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 60,
            y: 45,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 60,
            y: 15,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 45,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
      closed: true,
      isTemplateShape: true,
      templateData: [
        {
          point: {
            x: basePosition,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: basePosition,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: `sh-${basePosition}`,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: basePosition,
            y: 'sh',
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: `sw-${basePosition}`,
            y: 'sh',
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 'sw',
            y: `sh-${basePosition}`,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 'sw',
            y: basePosition,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: `sw-${basePosition}`,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
