import * as React from 'react';

import { Icon } from '@/dsm';

import i18n from '@/i18n';

import ValueItem from '../ValueItem';

export default class ContentPanelValueItem extends ValueItem {
  handleLocateArtboard = () => {
    const { comp, coreEditor } = this.props;
    const artboardID = comp.value as string;
    const artboard = coreEditor.doc.getArtboardByID(artboardID);
    // 有可能关联的画板已被删除
    if (artboard) {
      const { uiManager } = this.context;
      uiManager.valueEditor?.close?.();
      uiManager.workSpace?.movePageToActiveFragmentPosition(artboard);
      coreEditor.setActiveArtboard(artboard);
    }
  };

  renderLocateArtboardButton() {
    const { invalid, selected } = this.props;
    return (
      selected && (
        <Icon
          className="locate-artboard-btn"
          cls="icon_SpringFrame_location_dt"
          tips={i18n('editor.locate')}
          onClick={this.handleLocateArtboard}
          disabled={invalid}
        />
      )
    );
  }

  // override
  renderOperations() {
    return <>{this.renderLocateArtboardButton()}</>;
  }
}
