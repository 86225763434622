export default {
  text2xml: (text: string, rootNodeName: string = ''): Node | null => {
    let value = text;
    const rootNode = rootNodeName.trim();
    if (rootNode) {
      value = `<${rootNode}>${text}</${rootNode}>`;
    }
    if (window.DOMParser) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(value, 'text/xml');
      const isError = xmlDoc.getElementsByTagName('parsererror').length > 0;
      if (isError) {
        return null;
      }
      return xmlDoc;
    } else if (window.ActiveXObject) {
      const active = new window.ActiveXObject('Microsoft.XMLDOM');
      active.async = false;
      active.loadXML(value);
      return active;
    }
    return null;
  },

  xml2string: (xml: Node) => {
    return new XMLSerializer().serializeToString(xml);
  },
};
