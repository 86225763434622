import { IconValue, ITreeDataItem } from '@/fbs/rp/models/value';
import { UIPanelComponent } from '@/editor/comps';
import ITree from '@/fbs/rp/models/properties/tree';
import { AvlTree } from '@tyriar/avl-tree';
import { IComponentData } from '@/fbs/rp/models/component';

export interface ITreeArrData extends ITreeDataItem {
  parentID: string;
  isParent: boolean;
  level: number;
  path: string;
  visible: boolean;
  checked: TreeCheckboxState;
  text?: string;
}

export enum TreeItemColumn {
  Expand,
  CheckBox,
  Node,
  Text,
}

export enum DropMode {
  None = 'none',
  Before = 'before',
  After = 'after',
  Child = 'child',
}

export const TreeItemAlias = {
  ExpandIcon: 'expandIcon',
  ExpandImg: 'expandImg',
  CheckBox: 'checkbox',
  CheckBoxIcon: 'checkboxIcon',
  NodeIcon: 'nodeIcon',
  NodeImg: 'nodeImg',
  NodeText: 'nodeText',
};

export enum TreeCheckboxState {
  UnChecked,
  Checked,
  Half,
}

export interface ITreeItem {
  data: ITreeDataItem;
  parent: ITreeItem | undefined;
  children?: ITreeItem[];
  checked?: TreeCheckboxState;
}

function getChecked(node: ITreeItem): TreeCheckboxState {
  const hasUnChecked = node.children?.find((child) => child.checked === TreeCheckboxState.UnChecked);
  const hasChecked = node.children?.find((child) => child.checked === TreeCheckboxState.Checked);
  const hasHalf = node.children?.find((child) => child.checked === TreeCheckboxState.Half);
  if (hasUnChecked && !hasChecked && !hasHalf) {
    return TreeCheckboxState.UnChecked;
  } else if (hasChecked && !hasUnChecked && !hasHalf) {
    return TreeCheckboxState.Checked;
  } else {
    return TreeCheckboxState.Half;
  }
}

function getAllChildInfo(
  arr: ITreeItem[],
  checked: TreeCheckboxState,
  changeCompInfo: { [key: string]: TreeCheckboxState },
) {
  arr.forEach((item) => {
    changeCompInfo[item.data.id] = checked;
    if (item.children?.length) {
      getAllChildInfo(item.children, checked, changeCompInfo);
    }
  });
}

export function getAdjustCompInfo(node: ITreeItem, changeCompInfo: { [key: string]: TreeCheckboxState } = {}) {
  const nodeChecked = node.checked ? TreeCheckboxState.UnChecked : TreeCheckboxState.Checked;
  changeCompInfo[node.data.id] = nodeChecked;
  node.checked = nodeChecked;
  let parent = node.parent;
  while (parent) {
    const checked = getChecked(parent);
    if (parent.checked !== checked) {
      changeCompInfo[parent.data.id] = checked;
    }
    parent = parent.parent;
  }

  if (node.children?.length) {
    getAllChildInfo(node.children, nodeChecked, changeCompInfo);
  }

  return changeCompInfo;
}

export function mapRelationWithComp(data: ITreeDataItem[], childComps: IComponentData[], parent?: ITreeItem) {
  return data.map((item) => {
    const comp = childComps.find((comp) => comp._id === item.id);
    const checked = comp?.components?.find((comp) => comp.alias === TreeItemAlias.CheckBox)?.selected;
    let newItem: ITreeItem = {
      data: item,
      parent,
      checked: checked ? TreeCheckboxState.Checked : TreeCheckboxState.UnChecked,
    };
    if (item.children?.length) {
      newItem.children = mapRelationWithComp(item.children, childComps, newItem);
      //
      let selectedChildrenCount = 0;
      let halfSelectedChildrenCount = 0;
      //收集子的选中情况
      newItem.children.filter((d) => {
        if (d.checked === TreeCheckboxState.Checked) {
          selectedChildrenCount++;
        } else if (d.checked === TreeCheckboxState.Half) {
          halfSelectedChildrenCount++;
        }
      });
      // 全选
      if (newItem.children.length === selectedChildrenCount && selectedChildrenCount > 0) {
        newItem.checked = TreeCheckboxState.Checked;
      } // 是否半选
      else if (
        newItem.children.length !== selectedChildrenCount &&
        (selectedChildrenCount > 0 || halfSelectedChildrenCount > 0)
      ) {
        newItem.checked = TreeCheckboxState.Half;
      } else {
        newItem.checked = TreeCheckboxState.UnChecked;
      }
    }
    return newItem;
  });
}

export function createAvlTreeWithTreeComp(
  relation: ITreeDataItem[],
  childComps: IComponentData[],
): {
  tree: AvlTree<string, ITreeItem>;
  treeRelation: ITreeItem[];
} {
  const tree = new AvlTree<string, ITreeItem>();
  const treeRelation = mapRelationWithComp(relation, childComps);
  treeRelation.forEach((item) => {
    addToAvlTree(tree, item);
  });
  return {
    tree,
    treeRelation,
  };
}

export function treeToArr(data: ITreeDataItem[], arr: ITreeArrData[] = [], level: number = 0, parent?: ITreeArrData) {
  for (let i = 0, len = data.length; i < len; i++) {
    const item = data[i];
    const arrItem: ITreeArrData = {
      ...item,
      parentID: parent?.id || 'ROOT',
      isParent: !!item.children?.length,
      level,
      path: parent ? `${parent.path},${parent.id}` : 'ROOT',
      visible: parent ? parent.visible && parent.expand : true,
      checked: TreeCheckboxState.UnChecked,
    };
    arr.push(arrItem);
    if (arrItem.children?.length) {
      treeToArr(arrItem.children, arr, level + 1, arrItem);
    }
  }
  return arr;
}

//去掉转数组结构时的数据项, 并按照index 排序
function shakeArrData(arr: ITreeArrData[]) {
  return arr
    .map((child: ITreeArrData) => {
      delete child.parentID;
      delete child.level;
      delete child.visible;
      delete child.isParent;
      delete child.path;
      delete child.checked;
      return child;
    })
    .sort((a, b) => a.index - b.index);
}

export function arrToTree(arr: ITreeArrData[]) {
  let cloneData = JSON.parse(JSON.stringify(arr));
  const result = cloneData.filter((father: ITreeArrData) => {
    let branchArr = cloneData.filter((child: ITreeArrData) => father.id == child.parentID);
    branchArr.length && (father.children = shakeArrData(branchArr));
    return father.parentID === 'ROOT';
  });
  return shakeArrData(result);
}

export type TreeNodeIcon = { type: 'IconValue' | 'string'; icon: IconValue | string };

//获取节点的图标
export function getNodeIcon(comp: UIPanelComponent): TreeNodeIcon {
  const nodeComp = comp.components.find((cm) => cm.column === 1);

  if (!nodeComp) return { type: 'string', icon: '' };

  const value = nodeComp?.value;
  if (typeof value === 'string') {
    return {
      type: 'string',
      icon: nodeComp?.value as string,
    };
  } else {
    return {
      type: 'IconValue',
      icon: nodeComp?.value as IconValue,
    };
  }
}

//展开 折叠
export function doExpand(arrTree: ITreeArrData[], node: ITreeArrData): ITreeDataItem[] {
  const expand = node.expand;
  const newArrTree = arrTree.map((item) => {
    if (item.id === node.id) {
      item.expand = !expand;
    }
    return item;
  });

  return arrToTree(newArrTree);
}

//切换选中项
export function doChangeSelected(arrTree: ITreeArrData[], node: ITreeArrData): ITreeDataItem[] {
  const newArrTree = arrTree.map((item) => {
    if (item.id === node.id) {
      item.selected = true;
    } else {
      item.selected = false;
    }
    return item;
  });

  return arrToTree(newArrTree);
}

export function diffrentWithProperty(orignProp: ITree, nowProp: ITree): string[] {
  const orignPropType = typeof orignProp;
  const nowPropType = typeof nowProp;
  let changeKeys: string[] = [];
  if (!orignPropType || !nowPropType || orignPropType !== nowPropType) return changeKeys;

  const originKeys: string[] = Object.keys(orignProp);
  changeKeys = originKeys.filter((k: string) => {
    const key = k as keyof ITree;
    const orignVal = orignProp[key];
    const nowVal = nowProp[key];
    if (JSON.stringify(orignVal) !== JSON.stringify(nowVal)) {
      return key;
    }
  });

  return changeKeys;
}

export function addToAvlTree(tree: AvlTree<string, ITreeItem>, item: ITreeItem) {
  tree.insert(item.data.id, item);
  if (item.children?.length) {
    item.children.forEach((child) => {
      addToAvlTree(tree, child);
    });
  }
}

export function createAvlTreeWithTreeData(
  relation: ITreeDataItem[],
): {
  tree: AvlTree<string, ITreeItem>;
  treeRelation: ITreeItem[];
} {
  const tree = new AvlTree<string, ITreeItem>();
  const treeRelation = mapRelationWithData(relation);
  treeRelation.forEach((item) => {
    addToAvlTree(tree, item);
  });
  return {
    tree,
    treeRelation,
  };
}
// 新增映射，数据项的选中情况
export function createAvlTreeWithTreeDataMapStatus(
  relation: ITreeDataItem[],
  childComps: IComponentData[],
): {
  tree: AvlTree<string, ITreeItem>;
  treeRelation: ITreeItem[];
} {
  const tree = new AvlTree<string, ITreeItem>();
  const treeRelation = mapRelationWithComp(relation, childComps);
  treeRelation.forEach((item) => {
    addToAvlTree(tree, item);
  });
  return {
    tree,
    treeRelation,
  };
}

export function mapRelationWithData(data: ITreeDataItem[], parent?: ITreeItem) {
  return data.map((item) => {
    let newItem: ITreeItem = {
      data: item,
      parent,
      checked: TreeCheckboxState.UnChecked,
    };
    if (item.children?.length) {
      newItem.children = mapRelationWithData(item.children, newItem);
    }
    return newItem;
  });
}

// 判断拖拽点是否为父
export function isParentDrag(dragNode: ITreeItem, dropNode: ITreeItem) {
  let parent = dropNode.parent;

  while (parent) {
    if (parent.data.id === dragNode.data.id) {
      return true;
    }
    parent = parent.parent;
  }

  return false;
}

export function insertToTreeCollaction(
  collaction: ITreeItem[],
  dragNode: ITreeItem,
  dropNode: ITreeItem,
  mode?: DropMode,
) {
  const dropParent = dropNode.parent;
  let index = dropNode.data.index;

  if (mode === DropMode.After) {
    index++;
  }
  collaction.forEach((child) => {
    if (child.data.index >= index) {
      child.data.index++;
    }
  });

  dragNode.data.index = index;
  dragNode.parent = dropParent;
  collaction.splice(index, 0, dragNode);

  if (dropParent) {
    dropParent?.data.children?.push(dragNode.data);
    sortData(dropParent);
  }
}

export function insertToTreeChild(dragNode: ITreeItem, dropNode: ITreeItem) {
  const children = dropNode.children;
  dragNode.data.index = children?.length || 0;
  dragNode.parent = dropNode;
  if (children?.length) {
    children.push(dragNode);
    dropNode.data.children?.push(dragNode.data);
  } else {
    dropNode.children = [dragNode];
    dropNode.data.children = [dragNode.data];
  }
}

export function insertToTreeSibling(treeData: ITreeItem[], dragNode: ITreeItem, dropNode: ITreeItem) {
  const dropIndex = dropNode.data.index;
  if (dropNode.parent) {
    const children = dragNode.parent?.children!;
    children.forEach((child) => {
      if (child.data.index > dropIndex) {
        child.data.index++;
      }
    });
    children.splice(dropIndex + 1, 0, dragNode);
    dragNode.parent!.data.children?.splice(dropIndex + 1, 0, dragNode.data);
  } else {
    treeData.forEach((item) => {
      if (item.data.index > dropIndex) {
        item.data.index++;
      }
    });
    treeData.splice(dropIndex + 1, 0, dragNode);
  }
}

export function insertToTreeFirst(collaction: ITreeItem[], dragNode: ITreeItem, dropParent?: ITreeItem) {
  collaction.forEach((child) => {
    child.data.index++;
  });
  dragNode.data.index = 0;
  dragNode.parent = dropParent;
  collaction.unshift(dragNode);
}

export function insertToTreeLast(collaction: ITreeItem[], dragNode: ITreeItem, dropParent?: ITreeItem) {
  dragNode.data.index = collaction.length;
  dragNode.parent = dropParent;
  collaction.push(dragNode);
}

export function deleteToTree(treeData: ITreeItem[], deleteNode: ITreeItem) {
  const deleteNodeParent = deleteNode.parent;
  const deleteNodeIndex = deleteNode.data.index;
  if (deleteNodeParent) {
    deleteNodeParent.children!.splice(deleteNodeIndex, 1);
    deleteNodeParent.data.children!.splice(deleteNodeIndex, 1);
    deleteNodeParent.children!.forEach((child) => {
      if (child.data.index > deleteNodeIndex) {
        child.data.index--;
      }
    });
    sortData(deleteNodeParent);
  } else {
    treeData.splice(deleteNodeIndex, 1);
    treeData.forEach((child) => {
      if (child.data.index > deleteNodeIndex) {
        child.data.index--;
      }
    });
  }
}

export function deleteToCollaction(collaction: ITreeItem[], deleteNode: ITreeItem) {
  const deleteNodeIndex = deleteNode.data.index;
  collaction.splice(deleteNodeIndex, 1);
  collaction.forEach((child) => {
    if (child.data.index > deleteNodeIndex) {
      child.data.index--;
    }
  });
}

function sortData(dropParent: ITreeItem) {
  dropParent.data.children!.sort((a, b) => {
    return a.index - b.index;
  });
}

/**
 * 若 dargIndex < dropIndex, 向下排序，
 *     则 dragIndex < index < dropIndex, index--,
 * 若 dragIndex > dropIndex, 向上排序
 *     则 dropIndex < index <= dragIndex, index++
 */
export function sameLevelSort(collaction: ITreeItem[], dragNode: ITreeItem, dropNode: ITreeItem, mode: DropMode) {
  const dropParent = dropNode.parent;
  let dragIndex = dragNode.data.index;
  let dropIndex = dropNode.data.index;

  if (dragIndex - dropIndex === 1 && mode === DropMode.After) return;
  if (dropIndex - dragIndex === 1 && mode === DropMode.Before) return;

  if (dragIndex < dropIndex) {
    collaction.forEach((child) => {
      const index = child.data.index;
      if (child.data.id === dragNode.data.id) {
        child.data.index = dropIndex;
      } else {
        if (index > dragIndex && index <= dropIndex) {
          child.data.index--;
        }
      }
    });
  } else {
    collaction.forEach((child) => {
      const index = child.data.index;
      if (child.data.id === dragNode.data.id) {
        child.data.index = dropIndex;
      } else {
        if (index < dragIndex && index >= dropIndex) {
          child.data.index++;
        }
      }
    });
  }

  if (dropParent) {
    sortData(dropParent);
  } else {
    collaction.sort((a, b) => {
      return a.data.index - b.data.index;
    });
  }
}

export function moveNode(treeData: ITreeItem[], dragNode: ITreeItem, dropNode: ITreeItem, mode: DropMode) {
  if (mode === DropMode.None) return;
  const dropParent = dropNode.parent;
  const dragParent = dragNode.parent;
  const dragCollaction: ITreeItem[] = dragParent?.children || treeData;
  const dropCollaction: ITreeItem[] = dropParent?.children || treeData;
  if (mode === DropMode.Child) {
    deleteToTree(dragCollaction, dragNode);
    insertToTreeChild(dragNode, dropNode);
  } else {
    // 父相同，则在同父级排序
    if (dragParent === dropParent) {
      sameLevelSort(dragCollaction, dragNode, dropNode, mode);
    } else {
      deleteToTree(treeData, dragNode);
      insertToTreeCollaction(dropCollaction, dragNode, dropNode, mode);
    }
  }
}

//需要提交relation的数据
export function parseTreeData(mapData: ITreeItem[]) {
  const data: ITreeDataItem[] = [];
  mapData.forEach((item) => {
    data.push(item.data);
  });
  return data;
}

/**
 * 向上移动，允许跳出层级
 * @param treeData
 * @param targetNode
 *
 * 策略： 1. 如果移动节点的index === 0, 往上一级的最后插入, 直到 parent = undefined, index = 0
 *       2.  0 <  index < children.length 在同级内排序移动
 */
export function keyUpMoveToTreeMix(treeData: ITreeItem[], targetNode: ITreeItem) {
  const targetIndex = targetNode.data.index;
  const targetParent = targetNode.parent;
  if (targetParent) {
    const children = targetParent.children!;
    if (targetIndex === 0) {
      moveNode(treeData, targetNode, targetParent, DropMode.Before);
    } else {
      const beforeSiblingNode = children[targetIndex - 1];
      sameLevelSort(children, targetNode, beforeSiblingNode, DropMode.Before);
    }
  } else {
    if (targetIndex === 0) {
      return;
    } else {
      const beforeSiblingNode = treeData[targetIndex - 1];
      sameLevelSort(treeData, targetNode, beforeSiblingNode, DropMode.Before);
    }
  }
}

/**
 * 向上移动，只支持同级移动
 * @param treeData
 * @param targetNode
 */
export function keyUpMoveToTree(treeData: ITreeItem[], targetNode: ITreeItem): boolean {
  const targetIndex = targetNode.data.index;
  const targetParent = targetNode.parent;
  const collaction = targetParent?.children || treeData;
  const beforeSiblingNode = collaction[targetIndex - 1];
  if (beforeSiblingNode) {
    sameLevelSort(collaction, targetNode, beforeSiblingNode, DropMode.Before);
    return true;
  }
  return false;
}

/**
 * 向下移动，只能同级移动
 * @param treeData
 * @param targetNode
 */
export function keyDownMoveToTree(treeData: ITreeItem[], targetNode: ITreeItem): boolean {
  const targetIndex = targetNode.data.index;
  const targetParent = targetNode.parent;
  const collaction = targetParent?.children || treeData;
  const afterSiblingNode = collaction[targetIndex + 1];
  if (afterSiblingNode) {
    sameLevelSort(collaction, targetNode, afterSiblingNode, DropMode.After);
    return true;
  }
  return false;
}

export function mapChildsIDByCompID(relation: ITreeDataItem[], mapIDs: { [key: string]: string }) {
  return relation.map((item) => {
    let newItem = {
      ...item,
    };
    if (mapIDs[item.id]) {
      newItem.id = mapIDs[item.id];
    }
    if (newItem.children?.length) {
      newItem.children = mapChildsIDByCompID(newItem.children, mapIDs);
    }
    return newItem;
  });
}

export function mapParentsIDByCompID(treeData: ITreeItem[], childID: string) {
  return treeData.forEach((item) => {
    let arr = [];
    if (item.data.id === childID) {
      arr.push(item.data.id);
    } else {
      item.children && mapParentsIDByCompID(item.children, childID);
    }
    return arr;
  });
}

export function getParentIDsByCompTree(tree: ITreeItem): string[] {
  let parentsIDs: string[] = [];
  parentsIDs.push(tree.data.id);
  if (tree.parent) {
    return [...parentsIDs, ...getParentIDsByCompTree(tree.parent)];
  }
  return parentsIDs;
}
