import {
  DateFormat,
  ImageCategory,
  TextCategory,
  ITextCategory,
  IImageCategory,
  TextAddressCategory,
  TextNumberCategory,
} from './type';
import { languageManager } from '@/i18n';
import * as AutofillImg01 from '@/assets/image/autofill-img-01.png';
import * as AutofillImg02 from '@/assets/image/autofill-img-02.png';
import * as AutofillImg03 from '@/assets/image/autofill-img-03.png';
import * as AutofillImg04 from '@/assets/image/autofill-img-04.png';
import * as AutofillImg05 from '@/assets/image/autofill-img-05.png';
import * as AutofillImg06 from '@/assets/image/autofill-img-06.png';
import * as AutofillImg07 from '@/assets/image/autofill-img-07.png';
import * as AutofillImg08 from '@/assets/image/autofill-img-08.png';
import * as AutofillImg09 from '@/assets/image/autofill-img-09.png';

/**
 * 日期模板
 * y: 年的数字表示
 * m: 月的数字表示
 * MM: 月的英文表示
 * d: 日的数字表示
 * w: 星期的英文表示
 * Y: 年的中文数字表示
 * M: 月的中文数字表示
 * D: 日的中文数字表示
 * W: 星期的中文表示
 * hh: 小时的数字表示
 * mm: 分钟的数字表示
 * ss: 秒的数字表示
 *
 */
export const DATE_TEMPLATE: { [key: string]: string } = {
  [DateFormat.YYMMDD]: '{y}/{m}/{d}',
  [DateFormat.YYMMDD_HHMMSS]: '{y}/{m}/{d} {hh}:{mm}:{ss}',
  [DateFormat.YY_MM_DD]: '{y}-{m}-{d}',
  [DateFormat.YY_MM_DD_HHMMSS]: '{y}{m}-{d} {hh}:{mm}:{ss}',
  [DateFormat.YY$MM$DD]: '{y}年{m}月{d}日',
  [DateFormat.YY$MM$DD_HH$MM$$SS]: '{y}年{m}月{d}日 {hh}时{mm}分{ss}秒',
  [DateFormat.YY_MM_DD_WW]: '{y}-{m}-{d} {W}',
  [DateFormat.YY$MM]: '{y}年{m}月',
  [DateFormat.MM$DD]: '{m}月{d}日',
  [DateFormat.YYMM]: '{y}/{m}',
  [DateFormat.YY_MM]: '{y}-{m}',
  [DateFormat.MMDD]: '{m}/{d}',
  [DateFormat.MM_DD]: '{m}-{d}',
  [DateFormat.ZH_YYMMDD]: '{Y}年{M}月{D}日',
  [DateFormat.ZH_MMDD]: '{M}月{D}日',
  [DateFormat.HHMMSS]: '{hh}时{mm}分{ss}秒',
  [DateFormat.HHMM]: '{hh}时{mm}分',
  [DateFormat.MMSS]: '{mm}分{ss}秒',
  //en
  [DateFormat.MMDDYY]: '{m}/{d}/{y}',
  [DateFormat.MM_DD_YY]: '{m}-{d}-{y}',
  [DateFormat.WW_MM_DD_YY]: '{w} {m}-{d}-{y}',
  [DateFormat.HH_MM_SS]: '{hh}:{mm}:{ss}',
  [DateFormat.HH_MM]: '{hh}:{mm}',
  [DateFormat.MM_SS]: '{mm}:{ss}',
};

/**
 * 中文数字
 */
export const ZH_NUMBER: { [key: string]: string } = {
  0: '〇',
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  7: '七',
  8: '八',
  9: '九',
  10: '十',
  20: '二十',
  30: '三十',
};

/**
 * 英文月份，从 1 开始
 */
export const EN_MONTH: { [key: string]: string } = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

/**
 * 英文星期，星期天为0
 */
export const EN_WEEK: { [key: string]: string } = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  0: 'Sunday',
};

/**
 * 中文星期，星期天为0
 */
export const ZH_WEEK: { [key: string]: string } = {
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  0: '日',
};

/**
 * 图片填充分类配置，用于界面显示
 * name 以@开头，后面为所使用字符串在18n中的树结构路径
 */
export const initImageCategory = (): IImageCategory[] => [
  { id: ImageCategory.misc, name: '@autoFill.image.misc', iconPath: AutofillImg01 },
  { id: ImageCategory.people, name: '@autoFill.image.people', iconPath: AutofillImg02 },
  { id: ImageCategory.office, name: '@autoFill.image.office', iconPath: AutofillImg03 },
  { id: ImageCategory.science, name: '@autoFill.image.science', iconPath: AutofillImg04 },
  { id: ImageCategory.scenery, name: '@autoFill.image.scenery', iconPath: AutofillImg05 },
  { id: ImageCategory.food, name: '@autoFill.image.food', iconPath: AutofillImg06 },
  { id: ImageCategory.edifice, name: '@autoFill.image.edifice', iconPath: AutofillImg07 },
  { id: ImageCategory.animal, name: '@autoFill.image.animal', iconPath: AutofillImg08 },
  { id: ImageCategory.clothing, name: '@autoFill.image.clothing', iconPath: AutofillImg09 },
];

const zhDateCategory = [
  { id: DateFormat.YY$MM$DD, name: '@autoFill.text.date.ymd' },
  { id: DateFormat.YY$MM, name: '@autoFill.text.date.ym' },
  { id: DateFormat.MM$DD, name: '@autoFill.text.date.md' },
  { id: DateFormat.YY_MM_DD, name: 'yyyy-mm-dd' },
  { id: DateFormat.YY_MM, name: 'yyyy-mm' },
  { id: DateFormat.MM_DD, name: 'mm-dd' },
  { id: DateFormat.YYMMDD, name: 'yyyy/mm/dd' },
  { id: DateFormat.YYMM, name: 'yyyy/mm' },
  { id: DateFormat.MMDD, name: 'mm/dd' },
];

const enDateCategory = [
  { id: DateFormat.MMDDYY, name: 'MM/DD/YYYY' },
  { id: DateFormat.WW_MM_DD_YY, name: 'Week MM-DD-YYYY' },
  { id: DateFormat.MMDD, name: 'MM/DD' },
  { id: DateFormat.MM_DD_YY, name: 'MM-DD-YYYY' },
  { id: DateFormat.MM_DD, name: 'MM-DD' },
];

const enTimeCategory = [
  { id: DateFormat.HH_MM_SS, name: 'hh:mm:ss' },
  { id: DateFormat.HH_MM, name: 'hh:mm' },
  { id: DateFormat.MM_SS, name: 'mm:ss' },
];

const zhTimeCategory = [
  { id: DateFormat.HHMMSS, name: '@autoFill.text.time.hms' },
  { id: DateFormat.HHMM, name: '@autoFill.text.time.hm' },
  { id: DateFormat.MMSS, name: '@autoFill.text.time.ms' },
  ...enTimeCategory,
];

/**
 * 文本填充分类配置，用于界面显示
 */
export const initTextCategory = (): ITextCategory[] => {
  console.log(languageManager.languageVersion);
  return [
    {
      id: TextCategory.name,
      name: '@autoFill.text.people.name',
      iconCode: 60291,
      subCategory: [
        { id: 'both', name: '@autoFill.text.people.both' },
        { id: 'male', name: '@autoFill.text.people.male' },
        { id: 'female', name: '@autoFill.text.people.female' },
      ],
    },
    {
      id: TextCategory.phone,
      name: '@autoFill.text.phone.name',
      iconCode: 60249,
      subCategory: [
        { id: 'mobile', name: '@autoFill.text.phone.mobile', style: { width: 126 } },
        { id: 'tel', name: '@autoFill.text.phone.tel', style: { width: 126, left: 44 } },
      ],
    },
    {
      id: TextCategory.number,
      name: '@autoFill.text.number.name',
      iconCode: 59243,
      subCategory: [
        { id: TextNumberCategory.tiny, name: '0-0.99' },
        { id: TextNumberCategory.small, name: '1-9' },
        { id: TextNumberCategory.middle, name: '10-99' },
        { id: TextNumberCategory.large, name: '100-999' },
        { id: TextNumberCategory.huge, name: '1000+' },
      ],
    },
    {
      id: TextCategory.date,
      name: '@autoFill.text.date.name',
      iconCode: 60493,
      subCategory: languageManager.isZHVersion ? zhDateCategory : enDateCategory,
    },
    {
      id: TextCategory.time,
      name: '@autoFill.text.time.name',
      iconCode: 60189,
      subCategory: languageManager.isZHVersion ? zhTimeCategory : enTimeCategory,
    },
    { id: TextCategory.email, name: '@autoFill.text.email', iconCode: 60318 },
    {
      id: TextCategory.address,
      name: '@autoFill.text.location.name',
      iconCode: 60127,
      subCategory: [
        { id: TextAddressCategory.country, name: '@autoFill.text.location.country' },
        { id: TextAddressCategory.province, name: '@autoFill.text.location.province' },
        { id: TextAddressCategory.city, name: '@autoFill.text.location.city' },
        { id: TextAddressCategory.nativePlace, name: '@autoFill.text.location.nativePlace', style: { width: 260 } },
      ],
    },
    { id: TextCategory.text, name: '@autoFill.text.content', iconCode: 59695 },
    {
      id: TextCategory.serial,
      name: '@autoFill.text.serial.name',
      iconCode: 59701,
      subCategory: [
        { id: 'asc', name: '@autoFill.text.serial.asc', style: { width: 126 } },
        { id: 'desc', name: '@autoFill.text.serial.desc', style: { width: 126, left: 44 } },
        { id: 'identity', name: '@autoFill.text.serial.identity', style: { width: 126, gridColumn: 1 } },
        { id: 'serial', name: '@autoFill.text.serial.serial', style: { width: 126, left: 44, gridColumn: 2 } },
      ],
    },
  ];
};

/**
 * 时间偏差配置，获取时间时，以当前时间为基准，前后一年
 */
export const TIME_OFFSET = 31536000000;
