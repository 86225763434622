import * as React from 'react';
import { MobileType } from '@/fbs/rp/utils/preview';
import { IAppWithNestedChildren } from '@/fbs/rp/models/app';

import { getSizeFromOrientation } from '@/helpers/artboardDimensionHelper';
import { IPreviewOption, isMobileAppType, isWatchAppType, isVehicleAppType } from '@helpers/previewHelper';
import DeviceShell from '@/components/Preview/Web/ViewPort/PreviewDevice/DeviceShell';
import { renderArtboard } from './renderArtboard';
import { UIFragment } from '@/editor/comps';
import { getShellInfo } from '@/helpers/shellHelper';
import {
  getAdptiveShellBounds,
  isLandscape,
  getShellOption,
  getAdptiveDeviceBounds,
  getShellBounds,
} from '../util/shellUtil';
import { renderDevicePortraitShellBody, renderDeviceLandscapeShellBody, renderDeviceShell } from './renderDeviceShell';

export interface IPreviewDeviceProp {
  app?: IAppWithNestedChildren;
  artboard: UIFragment;
  mobileType: MobileType;
}

export interface IPreviewDeviceState {
  isPressed: boolean;
}

/**
 * 自适应手机壳
 * 宽度按项目定义，有限调整
 * 高度按画板定义高度
 */
class AdaptivePreviewDevice extends React.Component<IPreviewDeviceProp, IPreviewDeviceState> {
  static defaultProps: Partial<IPreviewDeviceProp> = {};
  selfRef: React.RefObject<HTMLDivElement>;
  shell: React.RefObject<DeviceShell> = React.createRef();
  private option: IPreviewOption;

  constructor(props: IPreviewDeviceProp) {
    super(props);
    this.state = {
      isPressed: false,
    };
    this.selfRef = React.createRef();
    const { mobileType, app } = props;

    this.option = getShellOption(mobileType, app);
  }

  /**
   * 考虑主画板方向后的项目尺寸
   */
  get rotatedAppSize() {
    const { artboard, app } = this.props;
    const appSize = app?.size ?? { width: 100, height: 100 };
    return artboard ? getSizeFromOrientation(artboard.orientation, appSize) : appSize;
  }

  get shellStyle(): React.CSSProperties {
    const { mobileType, app, artboard } = this.props;
    const bounds = getAdptiveShellBounds({
      mobileType,
      app,
      artboard,
    });

    const deviceBoxStyle: React.CSSProperties = {
      ...bounds,
      left: '0',
      top: '0',
      position: 'absolute',
    };

    return deviceBoxStyle;
  }

  get isMobileType() {
    const appType = this.props.app?.appType ?? '';
    return isMobileAppType(appType) || isWatchAppType(appType) || isVehicleAppType(appType);
  }

  get shellOption() {
    const { mobileType, artboard, app } = this.props;
    const portraitOnly = isWatchAppType(app?.appType ?? '');
    return getShellInfo(mobileType, portraitOnly ? false : isLandscape(artboard.orientation));
  }

  get deviceStyle(): React.CSSProperties {
    return this.shellStyle;
  }

  get shouldShowShell() {
    return this.shellOption && this.isMobileType && !this.option.noBoundary;
  }

  renderShell() {
    const { artboard, app } = this.props;
    const portraitOnly = isWatchAppType(app?.appType ?? '');
    if (portraitOnly) {
      return this.renderPortraitShell();
    }

    return isLandscape(artboard?.orientation) ? this.renderLandscapeShell() : this.renderPortraitShell();
  }

  /**
   * 垂直外壳
   */
  renderPortraitShell() {
    if (!this.shellOption) {
      return null;
    }

    const { shellImage } = this.shellOption;
    const { mobileType, app, artboard } = this.props;

    // 手表
    // 等比缩放自适应
    const { appType } = app ?? {};
    if (appType && isWatchAppType(appType)) {
      const bounds = getShellBounds({
        mobileType,
        app,
        artboard,
      });

      const deviceBoxStyle: React.CSSProperties = {
        left: '0',
        top: '0',
        width: bounds.width,
        height: bounds.height,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
      };

      return (
        <div className="device-box portrait" style={deviceBoxStyle}>
          {renderDeviceShell(bounds, this.shellOption)}
        </div>
      );
    }
    const bounds = getAdptiveShellBounds({
      mobileType,
      app,
      artboard,
    });

    const shellWidth = bounds.width;

    const deviceBoxStyle: React.CSSProperties = {
      left: '0',
      top: '0',
      width: bounds.width,
      height: bounds.height,
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
    };
    const shellBody = renderDevicePortraitShellBody(bounds, this.shellOption);

    return (
      <div className="device-box portrait" style={deviceBoxStyle}>
        <img className="shell-img-top" alt="img" src={shellImage[0]} width={shellWidth} />
        {/* <img className="shell-img-body" alt="img" src={shellImage[1]} width={shellWidth} height={bounds.otherHeight} /> */}
        {shellBody}
        <img className="shell-img-bottom" alt="img" src={shellImage[2]} width={shellWidth} />
      </div>
    );
  }

  renderLandscapeShell() {
    if (!this.shellOption) {
      return null;
    }

    const { shellImage } = this.shellOption;
    const { mobileType, app, artboard } = this.props;

    const bounds = getAdptiveShellBounds({
      mobileType,
      app,
      artboard,
    });

    const shellHeight = bounds.height;

    const deviceBoxStyle: React.CSSProperties = {
      left: '0',
      top: '0',
      width: bounds.width,
      height: bounds.height,
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row',
    };

    const shellBody = renderDeviceLandscapeShellBody(bounds, this.shellOption);
    return (
      <div className="device-box landscape" style={deviceBoxStyle}>
        <img className="shell-img-top" alt="img" src={shellImage[0]} height={shellHeight} />
        {/* <img className="shell-img-body" alt="img" src={shellImage[1]} height={shellHeight} width={bounds.otherWidth} /> */}
        {shellBody}
        <img className="shell-img-bottom" alt="img" src={shellImage[2]} height={shellHeight} />
      </div>
    );
  }

  render() {
    const { shellStyle } = this;
    const { artboard, mobileType } = this.props;
    const showShell = this.shouldShowShell;
    const { app } = this.props;

    // 手机pad自适应
    // 手表 等比缩放
    const deviceBounds = getAdptiveDeviceBounds({
      app,
      artboard,
      mobileType,
    });

    const scaleBoxStyle: React.CSSProperties = {
      ...deviceBounds,
      overflow: 'hidden',
      position: 'absolute',
    };
    return (
      <div ref={this.selfRef} className="preview-device" style={shellStyle}>
        <div className="scale-box" style={scaleBoxStyle}>
          {renderArtboard(artboard)}
        </div>
        {showShell && this.renderShell()}
      </div>
    );
  }
}

export default AdaptivePreviewDevice;
