import { EventEmitter } from 'events';

export enum EventName {
  autofill = 'AUTOFILL',
  aiConsume = 'AI_CONSUME',
}

export class AutofillEvent {
  static _event = new EventEmitter();
  static subscribe(subcribe: () => void) {
    AutofillEvent._event.addListener(EventName.autofill, subcribe);
  }

  static dispatch() {
    AutofillEvent._event.emit(EventName.autofill);
  }

  static remove(subcribe: (...args: any[]) => void) {
    AutofillEvent._event.removeListener(EventName.autofill, subcribe);
  }
}

export class AIConsumeEvent {
  static _event = new EventEmitter();
  // static doMap = new Set<(...args:any[])=>void>();
  static subscribe(subcribe: (...args: any[]) => void) {
    AIConsumeEvent._event.addListener(EventName.aiConsume, subcribe);
  }

  static dispatch(...args: any[]) {
    AIConsumeEvent._event.emit(EventName.aiConsume, ...args);
  }

  static remove(subcribe: (...args: any[]) => void) {
    AIConsumeEvent._event.removeListener(EventName.aiConsume, subcribe);
  }
}
