import { INode, INodeWithParentID } from './model';

export function getAllParentNodeIDs(nodes: INode[], level: number, currentLevel: number = 1): string[] {
  const res: Set<string> = new Set<string>();
  nodes.forEach((node) => {
    if (!node.isLeaf) {
      res.add(node.id);
    }
    if (node.children) {
      res.add(node.id);
      if (level == -1 || currentLevel < level) {
        const subRes = getAllParentNodeIDs(node.children, level, currentLevel + 1);
        if (subRes.length > 0) {
          subRes.forEach((id) => res.add(id));
        }
      }
    }
  });
  return Array.from(res);
}

// 获取分组下的页面
export function getGroupPageNum(nodes: INode[]): string[] {
  const res: Set<string> = new Set<string>();
  nodes.forEach((node) => {
    if (node.isLeaf) {
      res.add(node.id);
    }
    if (node.children) {
      const subRes = getGroupPageNum(node.children);
      if (subRes.length > 0) {
        subRes.forEach((id) => res.add(id));
      }
    }
  });
  return Array.from(res);
}

const regExpQuote = function (str: string) {
  return str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
};

// 节点名字和搜索匹配修改显示
export function nodeMatchUpdate(name: string, searchKey: string, showGroupNum: boolean, groupNum: number) {
  const searchKeyQuote = regExpQuote(searchKey)
  const re = new RegExp(`${searchKeyQuote}`, 'g')
  if (re.test(name)) {
    name = name.replace(re, `<b class="searchKey-highlight" >${searchKey}</b>`);
  }
  return (showGroupNum && !!groupNum) ? name.concat(`<b class="group-num" >（${groupNum}）</b>`) : name;
}

// 节点名字和搜索匹配
export function isNodeMatch(node: INode, searchKey: string): boolean {
  return (
    node.name.toLowerCase().includes(searchKey.toLowerCase()) ||
    (node.pinyinName ? node.pinyinName.toLowerCase().includes(searchKey.toLowerCase()) : false)
  );
}

// 节点或者节点的子和搜索匹配
export function isNodeOrChildrenMatch(node: INode, searchKey: string): boolean {
  if (isNodeMatch(node, searchKey)) {
    return true;
  }
  if (!node.children || node.children.length === 0) {
    return false;
  }
  return node.children.some((node) => isNodeOrChildrenMatch(node, searchKey));
}

/**
 * 查询 node 的 parent 和该 node 在父中的位置
 */
export function findParentNodeByID(
  nodes: INode[],
  nodeID: string,
  parent: INode | null = null,
): {
  parent: INode | null;
  index: number;
} {
  for (let i = 0; i < nodes?.length; i++) {
    const node = nodes[i];
    if (node.id === nodeID) {
      return {
        parent,
        index: i,
      };
    }
    if (node.children) {
      const foundFromNodeChildren = findParentNodeByID(node.children, nodeID, node);
      if (foundFromNodeChildren.index !== -1) {
        return foundFromNodeChildren;
      }
    }
  }
  return {
    parent,
    index: -1,
  };
}

export function flattenChildren(nodes: INode[], parentID: string = ''): INodeWithParentID[] {
  if (nodes.length === 0) {
    return [];
  }
  const initialValue: INodeWithParentID[] = [];
  return nodes.reduce((flat, toFlatten) => {
    flat.push(
      Object.assign({}, toFlatten, {
        parentID,
      }),
    );
    if (toFlatten.children) {
      return flat.concat(flattenChildren(toFlatten.children, toFlatten.id));
    }
    return flat;
  }, initialValue);
}

// 判断某个节点的多选框状态checked：boolean，indeterminate（半选中）：boolean，disabled：boolean
export function getCheckboxState(
  node: INode,
  checkedKeys: string[],
): {
  checked: boolean;
  indeterminate: boolean;
  disabled: boolean;
} {
  if (node.isLeaf) {
    return {
      checked: checkedKeys.includes(node.id),
      indeterminate: false,
      disabled: false,
    };
  }

  let checked = false;
  let indeterminate = false;
  let disabled = !node.children || node.children.length <= 0;

  if (node.children && node.children.length > 0) {
    const allChildren = flattenChildren(node.children);
    const isLeafChildren = allChildren.filter((c) => c.isLeaf);
    const checkedChildren = allChildren.filter((c) => checkedKeys.includes(c.id));
    const isLeafCheckedChildren = checkedChildren.filter((c) => c.isLeaf);

    if (!isLeafChildren || isLeafChildren.length <= 0) {
      disabled = true;
    } else {
      checked = !!checkedChildren && checkedChildren.length > 0;
      indeterminate =
        isLeafCheckedChildren &&
        isLeafCheckedChildren.length > 0 &&
        isLeafCheckedChildren.length < isLeafChildren.length;
    }
  }

  return {
    checked,
    indeterminate,
    disabled,
  };
}

// 是否是兄弟节点
export function isSiblings(nodes: INodeWithParentID[], aNodeID: string, bNodeID: string): boolean {
  const aNode = nodes.find((node) => node.id === aNodeID);
  const bNode = nodes.find((node) => node.id === bNodeID);
  return (aNode && bNode && aNode.parentID === bNode.parentID) || false;
}
