import { deepClone } from 'fast-json-patch/lib/core';

import { measureTextSize } from '@/utils/textUtils';

import { IComponentData } from '@fbs/rp/models/component';
import { ITreeData } from '@fbs/rp/models/value';
import { BooleanProperty, NumberProperty } from '@fbs/rp/models/properties/base';
import { StyleHelper } from '@helpers/styleHelper';
import IFill from '@fbs/rp/models/properties/fill';
import {
  TreeItemColumn,
  diffrentWithProperty,
  TreeItemAlias,
  treeToArr,
  ITreeItem,
  createAvlTreeWithTreeData,
  insertToTreeChild,
  parseTreeData,
  deleteToTree,
  keyUpMoveToTree,
  keyDownMoveToTree,
  insertToTreeSibling,
  createAvlTreeWithTreeComp,
} from '@helpers/treeCompHelper';
import ITextFormatEx from '@/fbs/rp/models/properties/textFormat';
import ITree from '@fbs/rp/models/properties/tree';
import { ArtboardPatches, Ops, PagePatches } from '@fbs/rp/utils/patch';
import { PropertyValue } from '@fbs/rp/models/property';
import { defaultSelectIcon, newRelationData, MaxInsertLevel } from '@consts/defaultData/tree';
import { mergePatches } from '@helpers/patchHelper';
import { depthClone } from '@utils/globalUtils';
import { parseColorToString } from '@/utils/graphicsUtils';
import i18n from '@i18n';
import ITextFormat from '@/fbs/rp/models/properties/text';
import { IUICompConstructOptions } from '@/customTypes';
import { cloneComponents } from '../coreHelper';
import { ComponentChange, ContainerPatches } from './resizeHelper';
import { UIComponent, UIContainerComponent, UITreeItemComponent } from '.';

export default class UIVerticalMenuComponent extends UIContainerComponent {
  constructor(data: IComponentData, public parent?: UIContainerComponent, public options?: IUICompConstructOptions) {
    super(data, parent, options);
  }

  get isShowExpandIcon() {
    return !!(this.properties['showExpandIcon'] as BooleanProperty)?.value;
  }

  get isShowNodeIcon() {
    return !!(this.properties['showNodeIcon'] as BooleanProperty)?.value;
  }

  // eslint-disable-next-line no-unused-vars
  getPositionPatchesOfChildrenChanged(changes: ComponentChange[], includeSelf: boolean): ContainerPatches {
    return { patches: { do: {}, undo: {} } };
  }

  addComponents(
    components: IComponentData[],
    // eslint-disable-next-line no-unused-vars
    index: number = -1,
  ): {
    patches: PagePatches;
    newActiveGroup?: UIContainerComponent;
  } {
    const patches: ArtboardPatches = {
      do: {
        [this.id]: [Ops.addChildren('-1', components)],
      },
      undo: {
        [this.id]: [Ops.removeChildren(components.map((comp) => comp._id))],
      },
    };
    return {
      patches: {
        [this.ownerArtboardID]: patches,
      },
    };
  }

  createNewItemCompByNode(nodeID: string, newNodeID: string, isParent: boolean, index: number) {
    const itemComp = this.getItemCompById(nodeID);
    const cloneComp = deepClone(itemComp.$data);
    const cloneData = cloneComponents({
      componentsData: cloneComp.components || [],
      parent: itemComp,
      delta: { x: 0, y: 0 },
    });
    cloneData.forEach((item) => {
      if (item.alias === TreeItemAlias.NodeIcon) {
        item.value = { iconCode: 60433, fontName: 'lightIconFont' };
      }

      if (item.alias === TreeItemAlias.NodeText) {
        item.value = `${i18n('property.component.tree.itemText')} ${index + 1}`;
      }
    });
    cloneComp.components = cloneData;
    cloneComp._id = newNodeID;
    cloneComp.selected = false;
    return cloneComp;
  }

  modifyValueWhenPropertyChange(propertyName: string, newValue: PropertyValue): ArtboardPatches {
    const originProperty = this.properties[propertyName];
    const newProperty = newValue as ITree;
    const changeProperties = diffrentWithProperty(originProperty as ITree, newProperty as ITree);
    let patches: ArtboardPatches = { do: {}, undo: {} };

    if (propertyName === 'treeExpand') {
      // 修改所有的 展开折叠icon 的  value state.checked.value
      this.getExpandPatches(changeProperties, newProperty, patches);
    }

    const compColumnType = this.getCompColumnType(propertyName);

    if (changeProperties.includes('iconColor')) {
      this.getIconColorPatches(compColumnType, newProperty, patches);
    }

    if (changeProperties.includes('iconSize')) {
      this.getIconSizeColorPatches(compColumnType, newProperty, patches);
    }

    if (propertyName === 'textStyle') {
      this.getTextVersionPatches(patches);
    }

    if (propertyName === 'itemCheckedTextStyle') {
      this.getTextVersionPatchesWhenChecked(patches, newValue);
    }

    return patches;
  }

  getParentCompsByCompTree = (tree: ITreeItem): UIComponent[] => {
    const comps: UIComponent[] = this.components.filter((comp) => comp.id === tree.data.id);
    if (tree.parent) {
      return [...comps, ...this.getParentCompsByCompTree(tree.parent)];
    }
    return comps;
  };

  /**
   * 根据comp获取父级comp
   */
  private getParentComps = (comp: UIComponent) => {
    const childComps = this.components.map((comp) => comp.$data);
    const { relation } = this.value as ITreeData;
    const { tree } = createAvlTreeWithTreeComp(relation, childComps);
    const selectComp = tree.get(comp.id);
    return this.getParentCompsByCompTree(selectComp!);
  };

  /**
   * 选中样式变更，textSize修改
   * @param comp
   * @param patches
   * @param newValue
   */
  private getCheckedStyleUpdatedSizePatches = (
    comp: UIComponent,
    patches: ArtboardPatches,
    newValue: PropertyValue,
  ) => {
    const comps = this.getParentComps(comp);
    const defaultTextStyle = this.properties.itemCheckedTextStyle as ITextFormatEx;
    const checkedPatches: ArtboardPatches = { do: {}, undo: {} };
    const mergeStyle = { ...defaultTextStyle, ...newValue } as ITextFormatEx;
    comps.forEach((_comp) => {
      const treeItem = _comp as UITreeItemComponent;
      const textComp = treeItem.getComponentByAlias(TreeItemAlias.NodeText);
      if (textComp) {
        const { size: oldSize, id } = textComp;
        const style = StyleHelper.initCSSStyleParser({}).doGetTextStyle(mergeStyle);
        const { width, height } = measureTextSize(style, textComp.value as string, {});

        checkedPatches.do[id] = [Ops.replace('./size', { ...oldSize, width, height })];
        checkedPatches.undo[id] = [Ops.replace('./size', oldSize)];
      }
    });
    mergePatches(patches, checkedPatches);
  };

  /**
   * 更改选中样式
   * @param patches
   */
  private getTextVersionPatchesWhenChecked(patches: ArtboardPatches, newValue: PropertyValue) {
    this.components.forEach((comp) => {
      if (comp.selected) {
        this.getCheckedStyleUpdatedSizePatches(comp, patches, newValue);
        this.modifyTextVersionPatches(comp, patches);
      }
    });
  }

  private modifyTextVersionPatches(comp: UIComponent, patches: ArtboardPatches) {
    const updateChildVersionPatches = (comp as UITreeItemComponent).modifyTextCompVersion();
    mergePatches(patches, updateChildVersionPatches);
  }

  private getTextVersionPatches(patches: ArtboardPatches) {
    this.components.forEach((comp) => {
      this.modifyTextVersionPatches(comp, patches);
    });
  }

  private getIconSizeColorPatches(compColumnType: TreeItemColumn, newProperty: ITree, patches: ArtboardPatches) {
    this.components.forEach((comp) => {
      const compPatches = (comp as UITreeItemComponent).modifyCompSizePatches(compColumnType, {
        width: newProperty.iconSize,
        height: newProperty.iconSize,
      });
      mergePatches(patches, compPatches);
    });
  }

  private getIconColorPatches(compColumnType: TreeItemColumn, newProperty: ITree, patches: ArtboardPatches) {
    this.components.forEach((comp) => {
      const compPatches = (comp as UITreeItemComponent).modifyCompColorPatches(compColumnType, newProperty.iconColor);
      mergePatches(patches, compPatches);
    });
  }

  private getCompColumnType(propertyName: string) {
    let compColum = TreeItemColumn.Expand;
    if (propertyName === 'treeNode') {
      compColum = TreeItemColumn.Node;
    }
    return compColum;
  }

  private getExpandPatches(changeProperties: string[], newProperty: ITree, patches: ArtboardPatches) {
    if (changeProperties.includes('chooseExpandType')) {
      const newIconFont = defaultSelectIcon.find((item) => item.id === newProperty.chooseExpandType);
      if (newIconFont?.value && newIconFont.value.length === 2) {
        //修改内部组件
        this.components.forEach((comp) => {
          const itemComp = comp as UITreeItemComponent;
          const expandIcon = itemComp.getComponentByAlias(TreeItemAlias.ExpandIcon)!;
          patches.do[expandIcon.id] = [
            Ops.replace('/value', newIconFont.value[1]),
            Ops.replace('/states/checked/value', newIconFont.value[0]),
          ];
          patches.undo[expandIcon.id] = [
            Ops.replace('/value', expandIcon.value),
            Ops.replace('/states/checked/value', expandIcon.states.checked.value),
          ];
        });
        //修改value
        const { expandACollapseIcon, relation } = this.value as ITreeData;
        expandACollapseIcon.iconMode = 'font';
        expandACollapseIcon.expand.icon = newIconFont.value[0];
        expandACollapseIcon.collapse.icon = newIconFont.value[1];
        patches.do[this.id] = [Ops.replace('/value', { expandACollapseIcon, relation })];
        patches.undo[this.id] = [Ops.replace('/value', this.value)];
      }
    }
  }

  parseProperties() {
    const { properties, size, padding } = this;
    const cssParser = StyleHelper.initCSSStyleParser(properties);
    //缩进
    const intention = (properties['levelIndent'] as NumberProperty)?.value.value;
    //行高
    const lineHeight = (properties['itemLineHeight'] as NumberProperty)?.value.value;
    //选中文本样式
    const checkedTextStyle = cssParser.getTextStyleData(size, properties['itemCheckedTextStyle'] as ITextFormat).style;
    //选中icon颜色
    const checkedIconColor = parseColorToString(
      (properties['treeNode'] as ITree)?.checkedIconColor ?? (properties['itemCheckedTextStyle'] as ITextFormat).color!,
    );
    //选中背景色
    const checkedFill = StyleHelper.parserFill(properties['itemCheckedFill'] as IFill);
    //选中背景标识条
    // const checkedMarkerStrip = StyleHelper.parserFill(properties['itemCheckedMarkerStrip'] as IFill);
    //是否显示展开折叠
    const showExpandIcon = (properties['treeExpand'] as ITree).isShow || false;
    //是否显示子项图标
    const showNodeIcon = (properties['treeNode'] as ITree)?.isShow || false;
    //是否显示子项图标
    // const showSelectBox = (properties['treeCheckbox'] as ITree)?.isShow || false;

    return {
      fill: { ...cssParser.getFillStyle() },
      stroke: { ...cssParser.getStrokeStyle() },
      textStyle: { ...cssParser.getTextStyle() },
      lineHeight,
      intention,
      checkedTextStyle,
      checkedIconColor,
      checkedFill,
      // checkedMarkerStrip,
      showExpandIcon,
      showNodeIcon,
      // showSelectBox,
      size,
      padding,
    };
  }

  getItemCompById(id: string): UITreeItemComponent {
    const item = this.components.find((comp) => comp.id === id);
    return item as UITreeItemComponent;
  }

  treeValuePathes(newValue: ITreeData) {
    let patches: ArtboardPatches = { do: {}, undo: {} };
    patches.do[this.id] = [Ops.replace('/value', newValue)];
    patches.undo[this.id] = [Ops.replace('/value', this.value)];
    return patches;
  }

  addTreeItemComp(newValue: ITreeData, newCompDatas: IComponentData[]) {
    const treeValuePathes = this.treeValuePathes(newValue);
    const compPatches: ArtboardPatches = {
      do: {
        [this.id]: [Ops.addChildren('-1', newCompDatas)],
      },
      undo: {
        [this.id]: [Ops.removeChildren(newCompDatas.map((comp) => comp._id))],
      },
    };

    return mergePatches(treeValuePathes, compPatches);
  }

  deleteTreeItemComp(newValue: ITreeData, newCompDatas: IComponentData[]) {
    const treeValuePathes = this.treeValuePathes(newValue);
    const compPatches: ArtboardPatches = {
      do: {
        [this.id]: [Ops.removeChildren(newCompDatas.map((comp) => comp._id))],
      },
      undo: {
        [this.id]: [Ops.addChildren('-1', newCompDatas)],
      },
    };
    return mergePatches(treeValuePathes, compPatches);
  }

  // 新建节点
  addNewNodeToRoot(newID: string): ArtboardPatches {
    const { relation, expandACollapseIcon } = this.value as ITreeData;
    const index = relation.length;
    const newTreeData = {
      ...newRelationData,
      id: newID,
      index,
    };
    relation.push(newTreeData);
    const cloneNodeID = relation[0].id;
    const newItemCompData = this.createNewItemCompByNode(cloneNodeID, newID, true, index);
    return this.addTreeItemComp({ expandACollapseIcon, relation }, [newItemCompData]);
  }

  getNodeLevel(targetNode: ITreeItem) {
    let parent = targetNode.parent;
    let count = 1;
    while (parent) {
      count++;
      parent = parent.parent;
    }

    return count;
  }

  getChildLevel(targetNode: ITreeItem) {
    const arrTree = treeToArr([targetNode.data]);
    const paths = arrTree.map((item) => item.path.split(',').length);
    const maxLength = Math.max(...paths);
    return maxLength;
  }

  //插入子项
  insertNewNode(targetNodeID: string, newNodeID: string): ArtboardPatches {
    const { relation, expandACollapseIcon } = this.value as ITreeData;
    const { tree, treeRelation } = createAvlTreeWithTreeData(depthClone(relation));
    const targetNode = tree.get(targetNodeID)!;

    //判断父节点的层级， 大于设定层级则不进行添加
    const level = this.getNodeLevel(targetNode);
    if (level >= MaxInsertLevel) {
      return { do: {}, undo: {} };
    }

    const index = targetNode.children?.length || 0;

    const newTreeData = {
      ...newRelationData,
      id: newNodeID,
      index,
    };
    const insertNode: ITreeItem = {
      data: newTreeData,
      parent: targetNode,
    };

    insertToTreeChild(insertNode, targetNode);

    const newValue = parseTreeData(treeRelation);

    const newItemCompData = this.createNewItemCompByNode(targetNodeID, newNodeID, false, index);
    return this.addTreeItemComp({ expandACollapseIcon, relation: newValue }, [newItemCompData]);
  }

  // 同级选中添加
  insertNewNodeAsSibling(targetNodeID: string, newNodeID: string): ArtboardPatches {
    const { relation, expandACollapseIcon } = this.value as ITreeData;
    const { tree, treeRelation } = createAvlTreeWithTreeData(depthClone(relation));
    const targetNode = tree.get(targetNodeID)!;
    const length: number = targetNode.parent?.children?.length || relation.length;
    const index = targetNode.data.index + 1;
    const newTreeData = {
      ...targetNode.data,
      children: [],
      id: newNodeID,
      index,
    };

    const insertNode: ITreeItem = {
      data: newTreeData,
      parent: targetNode.parent,
    };

    insertToTreeSibling(treeRelation, insertNode, targetNode);

    const newValue = parseTreeData(treeRelation);
    const newItemCompData = this.createNewItemCompByNode(targetNodeID, newNodeID, !targetNode.parent, length);
    return this.addTreeItemComp({ expandACollapseIcon, relation: newValue }, [newItemCompData]);
  }

  //删除子项
  deleteNode(deleteNodeID: string): ArtboardPatches {
    const { relation, expandACollapseIcon } = this.value as ITreeData;
    const { tree, treeRelation } = createAvlTreeWithTreeData(depthClone(relation));
    const deleteNode = tree.get(deleteNodeID)!;

    if (!deleteNode.parent && relation.length === 1) return { do: {}, undo: {} };

    deleteToTree(treeRelation, deleteNode);

    const newValue = parseTreeData(treeRelation);
    const deleteCompDatas = this.getItemCompById(deleteNodeID).$data;

    return this.deleteTreeItemComp({ expandACollapseIcon, relation: newValue }, [deleteCompDatas]);
  }

  //展开折叠
  modifyExpandState(targetNodeID: string) {
    const { relation, expandACollapseIcon } = this.value as ITreeData;
    const { tree, treeRelation } = createAvlTreeWithTreeData(depthClone(relation));
    const targetNode = tree.get(targetNodeID)!;
    const expand = targetNode.data.expand;
    targetNode.data.expand = !expand;
    const newValue = parseTreeData(treeRelation);
    const valuePatches = this.treeValuePathes({ expandACollapseIcon, relation: newValue });

    let patches: ArtboardPatches = { do: {}, undo: {} };
    const expandComp = this.getItemCompById(targetNode.data.id).expandComp;
    patches.do[expandComp.id] = [Ops.replace('/selected', !expand)];
    patches.undo[expandComp.id] = [Ops.replace('/selected', expand)];

    return mergePatches(valuePatches, patches);
  }

  mapIDWithIndex(): { [key: string]: number } {
    const { relation } = this.value as ITreeData;
    const arrTree = treeToArr(relation);
    let newIndfo: { [key: string]: number } = {};
    arrTree.forEach((arr, index) => {
      newIndfo[arr.id] = index;
    });
    return newIndfo;
  }

  //根据自上而下的index ， 查找对应的id
  findTreeNode(index: number): string {
    const { relation } = this.value as ITreeData;
    const arrTree = treeToArr(relation);
    const target = arrTree[index];
    return target?.id;
  }

  findTreeNodeIndexById(id: string): number {
    const { relation } = this.value as ITreeData;
    const arrTree = treeToArr(relation);
    return arrTree.findIndex((arr) => arr.id === id);
  }

  //切换默认选中
  doDefaultChecked(id: string): ArtboardPatches {
    // 找出原来的选中， 修改对应itemComp 的选中
    let patches: ArtboardPatches = { do: {}, undo: {} };
    this.components.forEach((comp) => {
      if (comp.selected) {
        patches.do[comp.id] = [Ops.replace('/selected', false)];
        patches.undo[comp.id] = [Ops.replace('/selected', true)];
      } else {
        if (comp.id === id) {
          patches.do[comp.id] = [Ops.replace('/selected', true)];
          patches.undo[comp.id] = [Ops.replace('/selected', false)];
        }
      }

      //切换选中的组件都更新文本的版本号， 以刷新文字样式
      if (comp.selected || comp.id === id) {
        this.modifyTextVersionPatches(comp, patches);
      }
    });

    return patches;
  }

  //向上移动
  doMoveNodeUp(id: string): ArtboardPatches | undefined {
    const { relation, expandACollapseIcon } = this.value as ITreeData;
    const { tree, treeRelation } = createAvlTreeWithTreeData(depthClone(relation));
    const targetNode = tree.get(id)!;
    const canMove = keyUpMoveToTree(treeRelation, targetNode);
    if (canMove) {
      const newValue = parseTreeData(treeRelation);
      return this.treeValuePathes({ expandACollapseIcon, relation: newValue });
    }
  }

  //向下移动
  doMoveNodeDown(id: string): ArtboardPatches | undefined {
    const { relation, expandACollapseIcon } = this.value as ITreeData;
    const { tree, treeRelation } = createAvlTreeWithTreeData(depthClone(relation));
    const targetNode = tree.get(id)!;
    const canMove = keyDownMoveToTree(treeRelation, targetNode);
    if (canMove) {
      const newValue = parseTreeData(treeRelation);
      return this.treeValuePathes({ expandACollapseIcon, relation: newValue });
    }
  }
}
