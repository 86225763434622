import PropertyBase from './base';

export default interface IBorder extends PropertyBase {
  left?: boolean;
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
}

export const BorderPropertyName = 'border';
