import { blankImageUri } from './imageUtil';
import { isDialogClosed } from './dialogUtil';
import { scheduler } from '../task';

export class TriggerImageLoad {
  private imageLoadTimer: NodeJS.Timeout | null = null;
  constructor() {
    this.init();
  }

  init() {
    window.debug && console.time('imageExportTotalTimeUsed');
  }
  clear() {
    if (this.imageLoadTimer) {
      clearInterval(this.imageLoadTimer);
    }
  }
  end() {
    window.debug && console.timeEnd('imageExportTotalTimeUsed');
    this.clear();
  }
  start() {
    if (!scheduler._isPending) {
      this.imageLoadTimer && clearInterval(this.imageLoadTimer);
      return;
    }
    this.clear();
    let tryTimes = 0;
    // 一次3秒，
    const intervalTime = 3000;
    // 最多轮询3次  9s
    const maxTryTimes = 3;
    this.imageLoadTimer = setInterval(() => {
      tryTimes++;
      window.debug && console.log('tryTimes' + tryTimes);
      if ((isDialogClosed() || tryTimes > maxTryTimes) && this.imageLoadTimer) {
        window.debug && console.log('clearInterval');
        clearInterval(this.imageLoadTimer);
      }
      // 尝试加载空白图片
      const image = new Image();
      image.onload = () => {
        image.remove();
      };
      image.onerror = () => {};
      image.src = blankImageUri();
    }, intervalTime);
  }
}
