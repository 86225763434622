import * as React from 'react';
import { abs, min, max, round } from '@utils/globalUtils';
import { isMacOS } from '@utils/envUtils';
import { isControlKeyPressed } from '@utils/hotkeysUtils';
import { getPureText } from '@utils/textUtils';

import { FloatPanel, TextArea, Tree, TreeItemData, Link } from '@dsm';
import { ComponentTheme } from '@/dsm/common';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';

import { ISize } from '@fbs/common/models/common';
import i18n from '@/i18n';

import { UIComponent, UIContainerComponent } from '@editor/comps/index';
import ValueEditorType from '@consts/enums/valueEditorType';
import CoreEditor from '@editor/core';
import { PredefinedStates } from '@consts/state';
import { CImage, CVideo, CAudio, CIcon } from '@libs/constants';
import { isTextType, isShapeText, isRichText } from '@libs/helper';
import Component from '@libs/index.tsx';

import SelectorEditor from '../../SelectorEditor';

import './index.scss';

interface IProps {
  comp: UIComponent;
  point?: { left: number; top: number };
  coreEditor: CoreEditor;
  checked?: boolean;
  onOpenIconLibrariesPanel?: Function;
  setTargetComp: (comp: UIComponent | undefined) => void;
}

interface IState {
  editingComp?: UIComponent;
  selComp?: UIComponent;
  showFileSelectorDialog?: boolean;
  treeItems: TreeItemData<UIComponent>;
  position: { left: number; top: number };
  containerSize: { width: number; height: number };
}

export default class CustomValueItemEditor extends React.Component<IProps, IState> {
  compContent: React.RefObject<HTMLDivElement> = React.createRef();
  // scrollBars: React.RefObject<ScrollBars> = React.createRef();
  private contentSize: { width: number; height: number } = { width: 0, height: 0 };
  inputRef: React.RefObject<TextArea> = React.createRef();

  constructor(props: IProps) {
    super(props);
    const { size } = props.comp;
    this.state = {
      treeItems: this.parserCompTree(props.comp),
      position: { left: 0, top: 0 },
      containerSize: { width: size.width, height: size.height },
    };
  }

  componentDidMount() {
    if (this.compContent.current) {
      const {
        comp: { size },
      } = this.props;
      const { width, height } = this.compContent.current.getBoundingClientRect();
      this.contentSize = { width, height };
      let l = 20;
      let t = 20;
      if (width > size.width) {
        l = round((width - size.width) / 2);
      }
      if (height > size.height) {
        t = round((height - size.height) / 2);
      }
      this.setState({
        position: {
          left: l,
          top: t,
        },
      });
      this.compContent.current.addEventListener('wheel', this.handleWheel, { passive: false });
    }
  }

  componentWillUnmount() {
    this.compContent.current?.removeEventListener('wheel', this.handleWheel);
  }

  parserCompTree(comp: UIComponent) {
    const translate = (comp: UIComponent, parent?: TreeItemData<UIComponent>) => {
      const treeItem: TreeItemData<UIComponent> = {
        data: comp,
        selected: false,
        expand: true,
        isLeaf: !(comp instanceof UIContainerComponent),
        parent,
      };
      if (comp instanceof UIContainerComponent) {
        treeItem.children = comp.components.map((c) => translate(c, treeItem));
      }
      return treeItem;
    };
    return translate(comp);
  }

  doSelectComp = (comp: UIComponent) => {
    const { treeItems, selComp } = this.state;
    if (selComp === comp) {
      return;
    }
    const switchSelected = (treeItem: TreeItemData<UIComponent>) => {
      treeItem.selected = treeItem.data === comp;
      if (treeItem.children?.length) {
        treeItem.children.forEach(switchSelected);
      }
    };
    switchSelected(treeItems);
    this.props.setTargetComp(comp);
    this.setState({ selComp: comp });
  };

  handleComponentClick = (e: React.MouseEvent, comp: UIComponent) => {
    e.stopPropagation();
    const { selComp } = this.state;
    if (selComp !== comp) {
      this.doSelectComp(comp);
    }
  };

  handleComponentDoubleClick = (e: React.MouseEvent, comp: UIComponent) => {
    const { selComp, editingComp } = this.state;
    if (selComp !== comp || editingComp !== comp) {
      this.doSelectComp(comp);
    }
    if ([CImage, CVideo, CAudio].includes(comp.type)) {
      this.setState({ showFileSelectorDialog: true });
    } else if (comp.type === CIcon) {
      this.props.onOpenIconLibrariesPanel && this.props.onOpenIconLibrariesPanel();
    }
  };

  handleBgClick = () => {
    this.setState({ selComp: undefined, editingComp: undefined });
  };

  handleTextValueChanged = (comp: UIComponent, value: any, option: { wrap?: boolean; size?: ISize }) => {
    console.log(comp, value, option);
  };

  handleCloseFileSelect = () => {
    this.setState({ showFileSelectorDialog: false });
  };

  handleFileSelect = (url: string) => {
    this.props.coreEditor.setComponentValue(this.state.selComp!, url);
  };

  handleCompTreeItemClick = (e: React.MouseEvent, comp: UIComponent) => {
    this.doSelectComp(comp);
  };

  handleWheel = (e: WheelEvent) => {
    e.stopPropagation();
    if (isControlKeyPressed(e)) {
      e.preventDefault();
    }
    const {
      position: { top, left },
      containerSize,
    } = this.state;
    const { deltaX, deltaY, shiftKey } = e;
    const direction = deltaY > 0 ? -1 : 1;
    const step = min(abs(deltaY) || 30, 30);
    const offset = {
      x: shiftKey ? step * direction : 0,
      y: shiftKey ? 0 : step * direction,
    };
    if (isMacOS) {
      offset.x = 0 - deltaX;
      offset.y = 0 - deltaY;
    }
    let l = left + offset.x;
    let t = top + offset.y;

    const width = containerSize.width;
    const height = containerSize.height;
    if (offset.x < 0) {
      l = width < this.contentSize.width ? max(l, 20) : max(l, this.contentSize.width - width - 20);
    } else if (offset.x > 0) {
      l = width < this.contentSize.width ? min(l, this.contentSize.width - 20 - width) : min(l, 20);
    }

    if (offset.y < 0) {
      t = height < this.contentSize.height ? max(t, 20) : max(t, this.contentSize.height - height - 20);
    } else if (offset.y > 0) {
      t = height < this.contentSize.height ? min(t, this.contentSize.height - 20 - height) : min(t, 20);
    }
    this.setState({ position: { left: l, top: t } });
  };

  handlePanelClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  renderFileSelector() {
    const { showFileSelectorDialog, editingComp } = this.state;
    if (!showFileSelectorDialog || !editingComp) {
      return null;
    }
    const { type, size } = editingComp;
    let valueType: ValueEditorType | undefined = undefined;
    switch (type) {
      case CImage:
        valueType = ValueEditorType.Image;
        break;
      case CVideo:
        valueType = ValueEditorType.Video;
        break;
      case CAudio:
        valueType = ValueEditorType.Audio;
        break;
      default:
        break;
    }
    if (!valueType) {
      return null;
    }
    return (
      <SelectorEditor
        type={valueType}
        compSize={size}
        editor={this.props.coreEditor}
        onSelect={this.handleFileSelect}
        onCancel={this.handleCloseFileSelect}
      />
    );
  }

  renderComponent = (comp: UIComponent, offset?: { x: number; y: number }) => {
    const { selComp, editingComp } = this.state;
    const selected = selComp === comp || comp === editingComp;
    const forceHover = comp === selComp || comp === editingComp;
    const offsetX = offset ? offset.x : 0;
    const offsetY = offset ? offset.y : 0;
    return (
      <Component
        key={comp.id}
        comp={comp}
        scale={1}
        isPreview={false}
        revision="0"
        offsetX={offsetX}
        offsetY={offsetY}
        selected={selected}
        valueEditing={false}
        forceHover={forceHover}
        isParentActivated
        customValueEditing
        onClick={this.handleComponentClick}
        onDoubleClick={this.handleComponentDoubleClick}
        onValueEdited={this.handleTextValueChanged}
      >
        {comp instanceof UIContainerComponent &&
          comp.components.map((comp) => {
            return this.renderComponent(comp);
          })}
      </Component>
    );
  };

  renderComponentContent = () => {
    const {
      position: { left, top },
    } = this.state;
    const { comp } = this.props;
    const style = {
      transform: `translate(${left}px, ${top}px)`,
    };
    const {
      position: { x, y },
    } = comp;

    return (
      <div className="comp-content" style={style} key={comp.id}>
        {this.renderComponent(comp, { x: -x, y: -y })}
      </div>
    );
  };

  submitTextNewValue = (value: string) => {
    //更新组件的 value,并且重新渲染当前组件
    let comp = this.state.editingComp || this.state.selComp;
    if (comp) {
      if (this.props.checked) {
        this.props.coreEditor.switchState([{ comp, stateID: PredefinedStates.checked }]);
      } else {
        this.props.coreEditor.switchState([{ comp, stateID: PredefinedStates.normal }]);
      }
      const isValueChange = isTextType(comp.type) ? comp.value !== value : comp.text !== value;
      if (isValueChange) {
        this.props.coreEditor.setCompTextValue(comp, value, { wrap: true });
      }
    }
  };

  handleTextBlur = (e: React.FocusEvent) => {
    const target = e.currentTarget as HTMLTextAreaElement;
    const value = target.value;
    this.submitTextNewValue(value);
  };

  handleTextKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode !== KeyCodeMap.VK_ESCAPE) {
      e.stopPropagation();
    }
    if (e.keyCode === KeyCodeMap.VK_ENTER) {
      const target = e.currentTarget as HTMLTextAreaElement;
      const value = target.value;
      this.submitTextNewValue(value);
    }
  };

  renderCompValue() {
    const { selComp } = this.state;
    if (!selComp) {
      return null;
    }
    const { type, text, value } = selComp;
    let finalText = text;
    if (!text && typeof value === 'string') {
      finalText = value;
    }

    finalText = getPureText(finalText);
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        {(isTextType(type) || isShapeText(type) || isRichText(type)) && (
          <TextArea
            className="selection-group-item-text-value"
            ref={this.inputRef}
            autoFocus
            autoSelectWhenFocus
            value={finalText}
            onBlur={this.handleTextBlur}
            onKeyDown={this.handleTextKeyDown}
          />
        )}
        {type === CIcon && (
          <Link
            theme={'dark'}
            onClick={() => {
              this.props.onOpenIconLibrariesPanel && this.props.onOpenIconLibrariesPanel();
            }}
          >
            {i18n('selectionGroup.iconEditButton')}
          </Link>
        )}
        {type === CImage && (
          <Link
            theme={'dark'}
            onClick={() => {
              this.setState({ showFileSelectorDialog: true, editingComp: selComp });
            }}
          >
            {i18n('selectionGroup.imageEditButton')}
          </Link>
        )}
      </div>
    );
  }

  renderTreeItem = (item: UIComponent) => {
    return (
      <div>
        <label>{item.displayName}</label>
      </div>
    );
  };

  render() {
    const { treeItems } = this.state;

    return (
      <FloatPanel
        className="custom-item-value-editor"
        float={false}
        point={this.props.point}
        onClick={this.handlePanelClick}
      >
        <div className="custom-item-value-editor-content">
          <Tree
            className="custom-item-editor-tree"
            items={treeItems}
            onItemClick={this.handleCompTreeItemClick}
            itemHeight={30}
            itemRender={this.renderTreeItem}
            prefixIndent={10}
            theme={ComponentTheme.dark}
          />
          <div className="custom-item-comp-content">
            <div className="comp-editor-content" onClick={this.handleBgClick} ref={this.compContent}>
              {this.renderComponentContent()}
            </div>
            <div className="comp-editor-value">{this.renderCompValue()}</div>
          </div>
        </div>
        {this.renderFileSelector()}
      </FloatPanel>
    );
  }
}
