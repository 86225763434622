import * as React from 'react';

import MathUtils from '@utils/mathUtils';
import { transBlankChart } from '@utils/textUtils';

import { StyleHelper } from '@helpers/styleHelper';
import { UIContainerComponent } from '@editor/comps';

import { IComponentProps } from '../../types';
import Background from '../../basic/common/Background';

import './index.scss';

const Button: React.FC<IComponentProps> = (props: IComponentProps) => {
  const { comp } = props;
  const { size, opacity, properties } = comp;
  const text = (comp as UIContainerComponent).components[0];
  const { radius, stroke, textStyle: textProp, border } = properties;
  const cssParser = StyleHelper.initCSSStyleParser(properties);
  const style: React.CSSProperties = {
    ...cssParser.getFillStyle(size),
    ...cssParser.getRadiusStyle(size),
    ...cssParser.getShadowStyle(),
    opacity: StyleHelper.getOpacity(opacity),
    ...size,
  };

  // FIXME: 按钮状态不能控制子组件的尺寸位置，所以使用直接使用flex居中布局，
  const { /* position, size: textSize, */ value } = text;
  const txProp = textProp ? { textStyle: { ...textProp, fontSize: MathUtils.value(textProp.fontSize, 14) * 2 } } : {};
  const textStyle = {
    width: 'fix-content',
    height: 'fix-content',
    transformOrigin: 'center',
    // width: textSize.width * 2,
    // height: textSize.height * 2,
    // lineHeight: `${textSize.height * 2}px`,
    opacity: StyleHelper.getOpacity(text.opacity),
    ...StyleHelper.createCSSStyleParser(txProp).getTextStyle(),
  };

  return (
    <div className="lib-comp-button" style={style}>
      <Background size={size} properties={{ radius, stroke, border }} />
      <label className="lib-comp-button-text" style={textStyle}>
        {transBlankChart(value as string)}
      </label>
    </div>
  );
};

export default Button;
