export function parseUrlSearch(searchStr = window.location.search) {
  let query = searchStr;
  if (searchStr.startsWith('?')) {
    query = searchStr.substring(1);
  }
  const vars = query.split('&');
  const obj: { [key: string]: string } = {};

  vars.forEach((item) => {
    const arr = item.split('=');
    if (arr.length < 2) {
      return;
    }
    const key = arr[0];
    arr.shift();
    obj[key] = arr.join('');
  });
  return obj;
}
