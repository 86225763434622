import * as React from 'react';

import './index.scss';

const loadingGif = 'https://img02.mockplus.cn/image/2021-03-8/68ade02d-0222-8805-4a29-6ab0ffc12012.gif';

interface ILoadingProps {
  pastDelay?: number;
}

const Loading: React.FC<ILoadingProps> = (config: ILoadingProps) => {
  const { pastDelay } = config;
  // 组件加载时间时长超过设定的时间时才显示loading效果(默认200ms)
  if (pastDelay) {
    return (
      <div className="loading">
        <img src={loadingGif} alt="loading..." width={35} height={35} />
      </div>
    );
  }
  return null;
};

export default Loading;
