import * as React from 'react';
import * as ReactDom from 'react-dom';

import classnames from 'classnames';

import {
  itemVerticalSpace,
  menuItemTopOrBottomPadding,
  textAndIconSpace,
  textLeftSpace,
} from '@/consts/defaultData/menu';
import { getParentIDsByCompTree } from '@/helpers/treeCompHelper';
import { IHorizontalMenuItemProps } from '.';

import Background from '@/libs/basic/common/Background';

const MenuChildItem: React.FC<IHorizontalMenuItemProps> = (props) => {
  const scale = window.pageScale;

  const self = React.useRef<HTMLDivElement>(null);
  const [itemStyle, setItemStyle] = React.useState<React.CSSProperties>({});

  React.useEffect(() => {
    if (self.current) {
      setItemStyle(parsePositionStyle());
    }
  }, [scale]);

  const calcRealWidth = (width: number) => (width * scale) / props.defaultScale;

  const calcOriginWidth = (width: number) => (width * props.defaultScale) / scale;

  const parsePositionStyle = () => {
    const {
      level,
      index,
      parentIndex,
      itemIndex,
      size,
      horizontalContentRects,
      initTree,
      item,
      lineHeight,
      maxChildLength,
      floatPanelRects,
      _id,
    } = props;
    const contentRects = horizontalContentRects[0];
    // 将第一次渲染计算出的当前弹出框width数据存储，后续数据都从floatPanelRects获取
    let selfWidth = floatPanelRects[_id];
    if (!selfWidth) {
      selfWidth = calcOriginWidth(self.current?.getBoundingClientRect()!.width as number);
      floatPanelRects[_id] = selfWidth;
    }

    const parentIds = getParentIDsByCompTree(item).filter((id) => item.data.id !== id);
    // 前弹出层宽度总和
    const parentWidth = calcRealWidth(
      parentIds.reduce((prev, item) => {
        prev += floatPanelRects[item];
        return prev;
      }, 0),
    );

    const top =
      level > 0
        ? ((itemIndex - index) * lineHeight + size.height + itemVerticalSpace) * scale + contentRects.top
        : itemVerticalSpace * scale + contentRects.bottom;

    const itemWidth = (size.width / initTree.length) * scale;
    // 下拉框靠左对齐
    const firstLeft = itemWidth * index + contentRects.left;
    const left = level > 0 ? firstLeft + parentWidth : firstLeft;

    const positionStyle: React.CSSProperties = {
      top: top,
      left: left,
      opacity: 1,
    };
    const pageRects = document.getElementsByClassName('preview-page')[0]?.getClientRects()[0];
    const childLength = item.children?.length ?? 0;
    if (
      pageRects &&
      maxChildLength * lineHeight > pageRects.bottom - contentRects.bottom &&
      pageRects.bottom - contentRects.bottom < contentRects.top - pageRects.top
    ) {
      const parentTop = document.getElementById(`${item.parent?.data.id}`)?.getBoundingClientRect().top ?? 0;
      const top =
        level > 0
          ? parentTop - (childLength - parentIndex - 1) * lineHeight * scale
          : contentRects.top - (itemVerticalSpace + menuItemTopOrBottomPadding * 2 + childLength * lineHeight) * scale;
      positionStyle.top = top;
    }
    return positionStyle;
  };

  const renderChild = () => {
    const { comp, horizontalComp, _id, lineHeight, showMobileCursor } = props;
    const { properties, opacity } = horizontalComp;
    const transition = comp.getTransition();
    const isTransparent = opacity === 0;
    const defaultStyle = {
      transform: `scale(${scale || 1}, ${scale || 1})`,
      opacity: 0,
    };
    return (
      <div
        className={classnames('horizontal-menu-item-inner', 'component-popup-container', {
          mobile: showMobileCursor,
          disabled: horizontalComp.disabled,
        })}
        style={{ ...defaultStyle, ...itemStyle }}
        ref={self}
        id={_id}
      >
        <div className="horizontal-menu-item-child">
          {properties.fill && !properties.fill.disabled && !isTransparent && (
            <Background
              size={{
                width: textAndIconSpace + textLeftSpace,
                height: props.children.length * lineHeight + menuItemTopOrBottomPadding * 2,
              }}
              transition={transition}
              properties={{ fill: properties.fill, radius: properties.radius }}
              coverStyle={{ width: '100%' }}
            />
          )}
          {props.children}
        </div>
      </div>
    );
  };

  return ReactDom.createPortal(renderChild(), document.body);
};

export default MenuChildItem;
