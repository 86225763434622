import * as React from 'react';
import { uniqueId } from 'lodash';

import { PopupPanel } from '@/dsm';
import { ComponentTheme } from '@/dsm/common';

export interface RefProps {
  onClose(): void;
}

export enum Placement {
  Right = 'right', // 正右侧对齐 topright
  TopRight = 'topRight', // 弹出层右上角与元素右侧对齐
  Cover = 'cover', // 覆盖当前触发元素
  Bottom = 'bottom',
}

interface IPopupProps {
  children: React.ReactElement;
  overlay: React.ReactElement;
  visible?: boolean;
  placement?: Placement; //
  popupHeight?: number;
  popupWidth?: number;
  className?: string;
  offset?: [number, number]; // [右偏移， 上偏移]
  onPopupVisible?: (visible: boolean) => void; // popup的显示隐藏
}

const Popup = React.forwardRef<RefProps, IPopupProps>((props: IPopupProps, ref) => {
  const domRef = React.useRef<null | HTMLElement>(null);
  // const domClient = React.useRef<null | DOMRect>(null);
  const uniqueIdRef = React.useRef<string>(uniqueId());
  const [position, setPosition] = React.useState({ x: 0, y: 0 });
  const [visible, setVisible] = React.useState(!!props.visible);
  const isMouseDown = React.useRef(false);
  const isFirst = React.useRef(true);

  React.useImperativeHandle(ref, () => ({
    onClose() {
      setVisible(false);
    },
  }));

  const doSetPosition = () => {
    const popEle = document.querySelector(`.popup-${uniqueIdRef.current}`);
    const domEle = domRef.current;
    if (popEle && domEle) {
      const { width, height } = popEle.getBoundingClientRect();
      const domClientInfo = domEle.getBoundingClientRect();
      // TODO: 其他方向未使用，后续有需要添加
      // TODO: 当前需求暂不涉及到极端情况，弹出层可能超出视图情况暂不考虑
      const offsetX = props.offset?.[0] || 0;
      const offsetY = props.offset?.[1] || 0;
      switch (props.placement) {
        case Placement.TopRight: {
          setPosition({
            x: domClientInfo.x - width + domClientInfo.width + offsetX,
            y: domClientInfo.y - offsetY,
          });
          break;
        }
        case Placement.Right: {
          setPosition({
            x: domClientInfo.x + domClientInfo.width + offsetX,
            y: domClientInfo.y + height / 2 - offsetY,
          });
          break;
        }
        case Placement.Cover: {
          setPosition({
            x: domClientInfo.x,
            y: domClientInfo.y - height / 2 - offsetY,
          });
          break;
        }
        case Placement.Bottom: {
          setPosition({
            x: domClientInfo.x + offsetX,
            y: domClientInfo.y + domClientInfo.height - offsetY,
          });
          break;
        }
      }
    }
  };

  React.useEffect(() => {
    if (visible) doSetPosition();
    if (!isFirst.current) props.onPopupVisible?.(visible);
  }, [visible]);

  React.useEffect(() => {
    isFirst.current = false;
    window.addEventListener('resize', doSetPosition);
    return () => {
      return window.removeEventListener('resize', doSetPosition);
    };
  }, []);

  const handleMouseDown = () => {
    isMouseDown.current = true;
  };

  const handleClick = () => {
    setVisible(!visible);
    isMouseDown.current = false;
  };

  const handleClose = () => {
    // blur弹窗，关闭弹窗；再次点击按钮关闭弹窗操作交给click处理
    if (!isMouseDown.current) {
      setVisible(false);
    }
  };

  return (
    <>
      <span ref={domRef} onClick={handleClick} onMouseDown={handleMouseDown}>
        {props.children}
      </span>
      {visible && (
        <PopupPanel
          onClose={handleClose}
          theme={ComponentTheme.light}
          position={position}
          width={props.popupWidth}
          className={`popup-${uniqueIdRef.current}${props.className ? ' ' + props.className : ''}`}
          height={props.popupHeight}
          closeAllOnMount={false}
        >
          {props.overlay}
        </PopupPanel>
      )}
    </>
  );
});

export default Popup;
