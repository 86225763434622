import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { getShapeData, DefaultFlowShapeSize, flowBase, getShapePropertiesData } from './shapeDataFragment';

// '展示'
export default {
  ...flowBase,
  name: i18n('resource.flow.display'),
  type: 'display',
  thumb: {
    spriteIconClass: SpriteThumb.Display.className,
    dragPosition: SpriteThumb.Display.position,
  },
  template: getShapeData(i18n('resource.flow.display'), DefaultFlowShapeSize.size100_60, {
    data: [
      {
        point: {
          x: 86,
          y: 0,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 100,
          y: 30,
        },
        handleIn: {
          x: 0,
          y: -18,
        },
        handleOut: {
          x: 0,
          y: 18,
        },
      },
      {
        point: {
          x: 86,
          y: 60,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 25,
          y: 60,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 0,
          y: 30,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 25,
          y: 0,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
    ],
    closed: true,
  }),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
