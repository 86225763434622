import * as React from 'react';

import classNames from 'classnames';
import * as echarts from 'echarts';
import { isUndefined, isEqual, debounce, cloneDeep, round } from 'lodash';

import { measureTextSize } from '@/utils/textUtils';
import { color2hexString } from '@/utils/graphicsUtils';

import {
  StageColorList,
  GradeColorList,
  MinScaleWidth,
  ValueOffsetRatio,
  StartAngle,
  EndAngle,
  SplitDistance,
  RadiusRatio,
  SplitLengthRatio1,
  SplitLengthRatio2,
  GradePointerLengthRatio,
} from '@/consts/gaugeChart';
import { IComponentProps } from '@/libs/types';
import { StyleHelper } from '@/helpers/styleHelper';
import { IProperties } from '@/fbs/rp/models/property';
import { IComponentSize } from '@/fbs/rp/models/component';
import IGaugeChart, { PointType, ChartType } from '@/fbs/rp/models/properties/gaugeChart';

import './index.scss';

class GaugeChart extends React.Component<IComponentProps> {
  private chartInstance?: echarts.ECharts;
  private properties?: IProperties;
  private size?: IComponentSize;
  private opacity: number;
  private timer?: Timeout;

  private chartDomID = `gauge-chart-${this.props.comp.id}-${Date.now()}`;

  constructor(props: IComponentProps) {
    super(props);
    const { properties, size, opacity } = props.comp;
    this.properties = properties;
    this.size = size;
    this.opacity = opacity;
  }

  componentDidMount() {
    const { isPreview, comp } = this.props;
    const dom = document.getElementById(this.chartDomID);
    if (!dom) {
      return;
    }

    this.chartInstance = echarts.init(dom, undefined, {
      renderer: 'svg',
    });

    window.requestAnimationFrame(() => {
      this.renderChart(this.props);
    });

    // 数据波动
    if (isPreview) {
      const config = comp.properties.gaugeChart as IGaugeChart;
      if (config.showDataFluctuation && this.chartInstance) {
        this.timer = setInterval(() => {
          // 最大最小值间值小于5，保留两位小数，否则展示整数
          const precision = Math.abs(config.max - config.min) < 5 ? 2 : 0;
          const value = round(config.min + Math.random() * (config.max - config.min), precision);
          this.chartInstance?.setOption({
            series: [{ data: [{ value, detail: this.getTitleConfig() }] }],
          });
        }, 2000);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: IComponentProps) {
    const { properties, size, opacity } = nextProps.comp;
    if (!isEqual(this.properties, properties) || !isEqual(this.size, size) || !isEqual(this.opacity, opacity)) {
      this.debouncedRenderChart(nextProps);
      this.properties = cloneDeep(properties);
      this.size = cloneDeep(size);
      this.opacity = cloneDeep(opacity);
    }
  }

  componentWillUnmount() {
    this.chartInstance?.dispose();
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  debouncedRenderChart = debounce((nextProps) => {
    this.renderChart(nextProps);
  }, 0);

  getTitleConfig = () => {
    const properties = this.properties!;
    const config = properties.gaugeChart as IGaugeChart;
    const parser = StyleHelper.initCSSStyleParser({ textStyle: config.labelStyle });
    return {
      show: config.showLabel,
      offsetCenter: config.type === ChartType.Grade ? [0, 0] : undefined,
      ...parser.doGetTextStyle(),
    };
  };

  /**
   * 计算缩放值
   * @param size
   */
  calcScale = (size: IComponentSize) => {
    const min = Math.min(size.width, size.height);
    const scale = min >= MinScaleWidth ? 1 : round(min / MinScaleWidth, 2);
    return scale;
  };

  /**
   * 仪表盘半径
   * @param size
   */
  calcRadius = (size: IComponentSize) => {
    const radius = (Math.min(size.width, size.height) / 2) * RadiusRatio;
    return radius;
  };

  /**
   * 根据缺口角度计算开始与结束角度
   * 同时角度变化时，需要调整y轴偏移，维持视觉上的居中
   * @param angle
   */
  calcAngles = (config: IGaugeChart, size: IComponentSize) => {
    const angle = config.notchAngle;
    const baseAngle = Math.round(angle / 2);
    const radius = this.calcRadius(size);
    const radian = (Math.PI / 180) * baseAngle;
    const offsetY = radius - Math.cos(radian) * radius;
    let labelY = radius;
    if (config.type !== ChartType.Grade && config.showLabel) {
      labelY = radius - (radius * ValueOffsetRatio + (config.labelStyle.fontSize || 14) / 2);
    }

    return {
      startAngle: StartAngle - baseAngle,
      endAngle: EndAngle + baseAngle,
      center: ['50%', size.height / 2 + Math.min(offsetY, labelY) / 2],
    };
  };

  /**
   * 计算背景构成颜色
   * @param config
   */
  calcBgColor = (config: IGaugeChart) => {
    if (config.type === ChartType.Normal) {
      return [[1, color2hexString(config.backgroundColor)]];
    }

    const list =
      config.type === ChartType.Stage
        ? StageColorList[config.stageColorIndex || 0]
        : GradeColorList[config.gradeColorIndex || 0];
    const len = list.length;

    return list.map((item, index) => {
      return [round((index + 1) * (1 / len), 1), item];
    });
  };

  /**
   * 粗略计算刻度的最大宽度
   * @param config
   */
  calcMaxTickLen = (config: IGaugeChart) => {
    const { min, max, bTickSplitNumber: len } = config;
    const splitValue = (max - min) / len;

    let maxLen = `${min}`;
    let temp = min;
    for (let i = 1; i < len; i++) {
      temp = round(temp + i * splitValue, 2);
      if (`${temp}`.length > maxLen.length) {
        maxLen = `${temp}`;
      }
    }
    return measureTextSize({ fontSize: config.tickFontSize }, maxLen).width;
  };

  /**
   * 计算指针的长度，尽量维持指针不覆盖仪表盘上刻度
   * 即需计算刻度所占的最大宽度
   * @param config
   */
  calcPointer = (config: IGaugeChart, size: IComponentSize) => {
    const radius = this.calcRadius(size);
    const scale = this.calcScale(size);
    let width = config.arcWidth;
    if (config.showTick) {
      width += SplitDistance * scale; // Stage只需加上分割线的distance
      if (config.type === ChartType.Normal) {
        width += radius * SplitLengthRatio1; // 加上分割线的length
      } else if (config.type === ChartType.Grade) {
        width += radius * SplitLengthRatio2; // 加上分割线的length
      }
      if (config.showTickLabel) {
        width += this.calcMaxTickLen(config);
      }
    }

    // 仪表盘缩小小于为默认宽高的一半时，指针与刻度标签的距离固定为2，其余按15*缩放比处理
    const space = scale < 0.5 ? 2 : 15 * scale;
    const length = radius - width - space;
    return {
      length,
      offsetCenter: config.type === ChartType.Grade ? [0, -length + radius * GradePointerLengthRatio] : [0, 0],
    };
  };

  renderChart(props: IComponentProps, chart = this.chartInstance) {
    if (!chart) {
      return;
    }
    const { comp } = props;
    const { size, properties } = comp;
    const config = properties.gaugeChart as IGaugeChart;

    const scale = this.calcScale(size);

    // 刻度分割轴线
    const splitLine: Record<string, any> = {
      show: config.showTick,
    };

    // 刻度线
    const axisTick: Record<string, any> = {
      show: config.showTick && config.showSTick,
      splitNumber: config.sTickSplitNumber,
    };

    // 刻度
    const axisLabel: Record<string, any> = {
      show: config.showTick && config.showTickLabel,
      fontSize: config.tickFontSize,
      color: color2hexString(config.tickFontColor),

      // 刻度标签离刻度分割线的起始位置的距离
      // 分割线的distance距离加固定间距8,当前计算居于默认宽度为10时，其余宽度需要减去基准值
      distance: SplitDistance * scale + 8 + (config.arcWidth - 10),
      formatter: (value: number) => round(value, 2),
    };
    const pointer: Record<string, any> = {
      show: config.showPointer,
      ...this.calcPointer(config, size),
      width: 6 * scale,
      keepAspect: true,
      itemStyle: {
        color: color2hexString(config.pointerColor),
      },
    };

    if (config.type === ChartType.Normal) {
      splitLine.length = `${SplitLengthRatio1 * 100}%`;
      splitLine.distance = SplitDistance * scale;
      splitLine.lineStyle = {
        width: Math.max(3 * scale, 2),
      };

      // 小刻度默认长度为半径的4%
      axisTick.length = '4%';
      axisTick.distance = SplitDistance * scale;
    } else if (config.type === ChartType.Stage) {
      splitLine.length = config.arcWidth;
      splitLine.distance = -config.arcWidth;
      splitLine.lineStyle = {
        width: Math.max(2, 3 * scale),
        color: '#fff',
      };

      axisTick.length = Math.ceil(config.arcWidth / 4);
      axisTick.distance = -config.arcWidth;
      axisTick.lineStyle = {
        width: Math.max(1, 2 * scale),
        color: '#fff',
      };
    } else if (config.type === ChartType.Grade) {
      splitLine.distance = SplitDistance * scale;
      splitLine.length = `${SplitLengthRatio2 * 100}%`;
      splitLine.lineStyle = {
        width: Math.max(5 * scale, 2),
        color: '#000',
      };

      // 小刻度默认长度为半径的6%
      axisTick.length = '6%';
      axisTick.distance = SplitDistance * scale;
      axisTick.lineStyle = {
        color: '#000',
      };

      pointer.length = `${GradePointerLengthRatio * 100}%`;
      pointer.icon = 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z';
      // 指针默认宽度为半径的20
      pointer.width = 20 * scale;
    }

    const seriesData = {
      type: 'gauge',
      radius: `${RadiusRatio * 100}%`,
      ...this.calcAngles(config, size),
      min: config.min,
      max: config.max,
      splitNumber: config.bTickSplitNumber,
      detail: {
        formatter: `{value}${config.labelUnit}`,
      },
      axisLine: {
        roundCap: config.pointType === PointType.Arc,
        lineStyle: {
          width: config.arcWidth,
          color: this.calcBgColor(config),
        },
      },
      progress: {
        show: config.type === ChartType.Normal,
        roundCap: config.pointType === PointType.Arc && config.defaultValue > config.min,
        width: config.arcWidth,
        itemStyle: {
          color: color2hexString(config.progressColor),
        },
      },
      splitLine,
      axisTick,
      axisLabel,
      pointer,
      data: [
        {
          value: config.defaultValue,
          detail: this.getTitleConfig(),
        },
      ],
    };

    const chartOption = {
      series: [seriesData],
      animation: props.isPreview && !!properties.animation?.value,
    };

    chart.resize({
      width: size.width,
      height: size.height,
    });
    chart.clear();
    chart.setOption(chartOption, true);
  }

  render() {
    const { isPreview, comp } = this.props;
    const { properties, size, opacity } = comp;
    const parser = StyleHelper.initCSSStyleParser(properties);

    const boxStyle: React.CSSProperties = {
      ...parser.getFillStyle(),
      ...parser.getShadowStyle(),
      ...parser.getStrokeStyle(),
      ...parser.getRadiusStyle(size),
      opacity: isUndefined(opacity) ? 1 : opacity / 100,
      pointerEvents: isPreview ? 'auto' : 'none',
    };

    return (
      <div
        id={this.chartDomID}
        style={boxStyle}
        className={classNames('gauge-chart', { 'gauge-chart-preview': isPreview })}
      ></div>
    );
  }
}

export default GaugeChart;
