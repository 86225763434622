import * as React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';

import { stopBubbleWhenSortCut } from '@helpers/shortCutHelper';
import KeyCodeMap from '@/dsm2/constants/KeyCodeMap';

import ScrollBars from '../ScrollBars';

import './index.scss';

export interface ITextareaProps {
  autoFocus?: boolean;
  placeholder?: string;
  autoSelectWhenFocus?: boolean;
  autoCursorToEnd?: boolean;
  height?: number;
  readonly?: boolean;
  style?: React.CSSProperties;
  className?: string;
  value: string;
  maxLength?: number;
  disabled?: boolean;
  tabValue?: string; // 设定tab的值，则使用tab按键

  onKeyDown?: (e: React.KeyboardEvent) => void;
  onChange?: (value: string) => void;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
}

export interface ITextareaState {
  focus?: boolean;
  value: string;
  areaHeight: number;
}

export default class Textarea extends React.Component<ITextareaProps, ITextareaState> {
  private textareaDom: React.RefObject<HTMLTextAreaElement> = React.createRef();

  private _focused: boolean = false;

  constructor(props: ITextareaProps) {
    super(props);
    this.state = {
      value: props.value || '',
      areaHeight: 200,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: ITextareaProps) {
    this.setState({ value: nextProps.value || '' });
  }

  focus() {
    this.textareaDom.current?.focus();
  }

  select() {
    this.textareaDom.current?.select();
  }

  doIndent(tabValue: string) {
    const selection = document.getSelection();
    const text = selection?.toString();
    document.execCommand('insertText', false, text ? text : tabValue);
  }

  get value() {
    return this.state.value;
  }

  get focused(): boolean {
    return this._focused;
  }

  componentDidMount() {
    const { autoFocus, autoSelectWhenFocus, autoCursorToEnd } = this.props;
    if (autoFocus && this.textareaDom.current) {
      setTimeout(() => {
        this.textareaDom.current!.focus();
        if (autoSelectWhenFocus || autoCursorToEnd) {
          this.textareaDom.current!.select();
        }
        if (autoCursorToEnd) {
          const documentSelection = document.getSelection();
          const len = documentSelection?.toString().length || 0;
          this.textareaDom.current?.setSelectionRange(len, len);
        }
      }, 0);
    }
  }

  handleTextChange = () => {
    const { onChange } = this.props;
    const { value } = this.state;
    let newVal = this.textareaDom.current!.value;
    if (value !== newVal) {
      //文本框默认最后一个空换行符不存在
      this.setState({ value: newVal });
      onChange && onChange(newVal);
    }
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    const { onKeyDown, tabValue } = this.props;
    const { keyCode, shiftKey } = e;
    if (keyCode === KeyCodeMap.VK_TAB && tabValue) {
      e.preventDefault();
      !shiftKey && this.doIndent(tabValue);
      return;
    }

    if (e.keyCode === KeyCodeMap.VK_ENTER) {
      e.stopPropagation();
    }

    // @ts-ignore
    stopBubbleWhenSortCut(e);
    onKeyDown && onKeyDown(e);
  };

  handleBlur = (e: React.FocusEvent) => {
    const { onBlur } = this.props;
    this._focused = false;
    this.setState({ focus: false });
    onBlur && onBlur(e);
  };

  handleFocus = (e: React.FocusEvent) => {
    const { onFocus } = this.props;
    this._focused = true;
    this.setState({ focus: true });
    onFocus && onFocus(e);
  };

  private pressed: boolean = false;

  handleMouseDown = () => {
    this.pressed = true;
  };

  handleMouseUp = (e: React.MouseEvent) => {
    if (this.pressed) {
      e.stopPropagation();
    }
    this.pressed = false;
  };

  handleDragStart = (e: React.DragEvent) => {
    e.preventDefault();
  };

  render() {
    const { height, style, placeholder, className, maxLength, readonly, disabled } = this.props;
    const { focus } = this.state;
    const textareaHeight = isUndefined(height) ? 200 : height;
    return (
      <div
        className={classnames('dsm-c-rp-textarea', className, { disabled, focus })}
        draggable={false}
        style={{ height: textareaHeight }}
      >
        <ScrollBars>
          <div className="container">
            <pre className="hidden" style={{ minHeight: textareaHeight - 2, ...(style || {}) }}>
              {this.state.value}
            </pre>
            <textarea
              draggable={false}
              ref={this.textareaDom}
              className="textarea"
              style={{ ...(style || {}) }}
              value={this.state.value}
              readOnly={readonly}
              placeholder={placeholder}
              maxLength={maxLength}
              onChange={this.handleTextChange}
              onKeyDown={this.handleKeyDown}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onMouseUp={this.handleMouseUp}
              onDragStart={this.handleDragStart}
            />
          </div>
        </ScrollBars>
      </div>
    );
  }
}
