import PropertyBase from './base';
import { PureColor } from './color';

export const SelectListPropertyName = 'selectList';

interface ISelectList extends PropertyBase {
  bgColor: PureColor; // 列表背景
  lineHeight: number; // 行高
  selectedFontColor: PureColor; // 选中字体颜色
  selectedBgColor?: PureColor; // 选中背景
}

export default ISelectList;
