import * as React from 'react';

import { IProperties } from '@fbs/rp/models/property';
import { ISize } from '@fbs/common/models/common';

import { StyleHelper } from '@helpers/styleHelper';

interface IProps {
  size: ISize;
  properties: IProperties;
  left?: number;
  top?: number;
  transition?: string;
  zIndex?: number;
  coverStyle?: React.CSSProperties;
  children?: React.ReactElement;
}

const Background: React.FC<IProps> = (props: IProps) => {
  const { size, properties, zIndex, transition, left = 0, top = 0, coverStyle, children } = props;
  const parser = StyleHelper.initCSSStyleParser(properties);

  let style: React.CSSProperties = {
    ...props.size,
    ...parser.getFillStyle(size),
    ...parser.getStrokeStyle(),
    ...parser.getRadiusStyle(size, properties.border),
    left: left,
    top: top,
    position: 'absolute',
    boxSizing: 'border-box',
    zIndex: zIndex || 'auto',
    pointerEvents: 'none',
    transition,
    overflow: 'hidden',
  };
  if (coverStyle) {
    style = { ...style, ...coverStyle };
  }

  return (
    <div className="lib-comp-general-background" style={style}>
      {children}
    </div>
  );
};

export default Background;
