/**
 * 离线演示包的配置
 */
export const getOfflineDemoData = () => {
  if (!RP_CONFIGS.isOfflineDemo) {
    return undefined;
  }
  // // try{
  // const data = window.offlineDemoData;
  // if (!data) {
  //   return undefined;
  // }
  // // const data = JSON.parse(str);
  // if (Object.prototype.toString.call(data) !== '[object Object]') {
  //   return undefined;
  // }
  // return data /*  as IOfflineDemo */;
  // // }catch(e){
  // //   console.log(e);
  // // }
  // // return undefined;
  return {
    app: window.data ?? {},
    config: window.previewConfig ?? {},
  };
};
