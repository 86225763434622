import { languageManager } from '@/i18n';
import IAutoFill, {
  SexType,
  DateFormat,
  IAutoFillData,
  NumberType,
  TextCategory,
  TextNumberCategory,
  TextAddressCategory,
  TextSubCategory,
} from './type';
import { DATE_TEMPLATE, ZH_NUMBER, ZH_WEEK, EN_MONTH, EN_WEEK, TIME_OFFSET } from './consts';

export enum AutoFillType {
  text,
  image,
}

const phoneUtils = {
  getPhoneNumber(tel?: boolean) {
    let first = Math.floor(Math.random() * 10);
    let second = Math.floor(Math.random() * 1000).toString(10);
    let third = Math.floor(Math.random() * 10000).toString(10);

    while (second.length < 3) {
      second = `0${second}`;
    }
    while (third.length < 4) {
      third = `0${third}`;
    }
    if (tel) {
      first = Math.max(first, 2);
    }
    return `${first}${second}${third}`;
  },

  getPrefix(prefix: (string | number)[]) {
    return prefix[Math.floor(Math.random() * prefix.length)];
  },
};

const dateUtils = {
  getZHValue(value: number): string {
    if (value > 100) {
      const numArr = value.toString(10).split('');
      return numArr.map((i) => ZH_NUMBER[i]).join('');
    }
    if (value === 0) {
      return '';
    }

    if (value <= 10) {
      return ZH_NUMBER[value];
    }
    const digit = value % 10;
    const tensDigit = value - digit;
    const digitStr = digit === 0 ? '' : ZH_NUMBER[digit];
    return `${ZH_NUMBER[tensDigit]}${digitStr}`;
  },

  getWeek(value: number, mode: 'zh' | 'en'): string {
    if (mode === 'zh') {
      return `星期${ZH_WEEK[value]}`;
    }
    return EN_WEEK[value];
  },

  getMonth(value: number, mode: 'zh' | 'en') {
    if (mode === 'zh') {
      return this.getZHValue(value);
    }
    return EN_MONTH[value];
  },
};

const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';

export default class AutoFill implements IAutoFill {
  private _cn_data?: IAutoFillData;
  private _en_data?: IAutoFillData;
  private serialIndex: number = 0;
  private serialMax: number = 999;

  constructor() {
    import('./data/cn.json').then((res) => {
      this._cn_data = res as IAutoFillData;
    });
    import('./data/en.json').then((res) => {
      this._en_data = res as IAutoFillData;
    });
  }

  private get data() {
    const isZH = languageManager.isZHVersion;
    if (isZH) {
      return this._cn_data;
    } else {
      return this._en_data;
    }
  }

  private getName(sex: SexType = SexType.both): string {
    if (!this.data) {
      return '';
    }
    const { name: nameData } = this.data;
    const isZH = languageManager.isZHVersion;
    const joinStr = isZH ? '' : '|';
    const { family, female, male } = nameData;

    let lastNameStr: string;
    switch (sex) {
      case SexType.female:
        lastNameStr = female;
        break;
      case SexType.male:
        lastNameStr = male;
        break;
      default:
        lastNameStr = `${male}${joinStr}${female}`;
        break;
    }
    let lastName: string;
    if (isZH) {
      const len = lastNameStr.length / 2;
      const index = Math.floor(Math.random() * len);
      lastName = lastNameStr.substr(index * 2, 2);
    } else {
      const lastNameArr = lastNameStr.split(joinStr);
      lastName = this.getRandomValue(lastNameArr);
    }

    const familyArr = family.split(joinStr);

    const familyName = this.getRandomValue(familyArr, 3);

    if (isZH) {
      return `${familyName}${lastName}`;
    }
    return `${lastName} ${familyName}`;
  }

  private getRandomValue<T>(value: T[], frequency: number = 1): T | string {
    const _frequency = Math.max(frequency, 1);
    return value[Math.floor(Math.pow(Math.random(), _frequency) * value.length)] || '';
  }

  /**
   * 注入自己的图片分类库
   * @param category
   * @param imgs
   */
  injectImage(category: string, imgs: string[]) {
    if (!this.data) {
      return;
    }
    const dataImgIndex = this.data.image.findIndex((item) => item.category === category);
    const dataImg = this.data.image[dataImgIndex] || { category, items: [] };
    dataImg.items = dataImg.items.concat(imgs);
    if (dataImgIndex === -1) {
      this.data.image.push(dataImg);
    } else {
      this.data.image.splice(dataImgIndex, 1, dataImg);
    }
  }

  setSerialMax(num: number) {
    this.serialMax = num;
  }

  maleName() {
    return this.getName(SexType.male);
  }

  femaleName() {
    return this.getName(SexType.female);
  }

  bothName() {
    return this.getName();
  }

  getNickName(): string {
    if (!this.data) {
      return '';
    }
    return this.getRandomValue(this.data.nickName);
  }

  getMobilePhone(): string {
    if (!this.data) {
      return '';
    }
    return `${phoneUtils.getPrefix(this.data.phonePrefix)}${phoneUtils.getPhoneNumber()}`;
  }

  getTelPhone(): string {
    if (!this.data) {
      return '';
    }
    return `${phoneUtils.getPrefix(this.data.areaCode)}-${phoneUtils.getPhoneNumber(true)}`;
  }

  getEmail(hostName?: string): string {
    if (!this.data) {
      return '';
    }
    const { host, hostName: dataHostName } = this.data;
    let _hostName = hostName;
    if (hostName?.startsWith('@')) {
      _hostName = hostName.substr(1);
    }
    _hostName = _hostName || this.getRandomValue(host);
    const emailName = this.getRandomValue(`${dataHostName}`.split('|'));

    return `${emailName}@${_hostName}`;
  }

  getDate(format: DateFormat): string {
    const now = +new Date() - TIME_OFFSET;
    const time = now + Math.round(Math.random() * TIME_OFFSET * 2);
    const newDate = new Date(time);
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const fullMonth = `${month < 10 ? '0' : ''}${month}`;
    const date = newDate.getDate();
    const fullDate = `${date < 10 ? '0' : ''}${date}`;
    const day = newDate.getDay();
    const hours = newDate.getHours();
    const minutes = newDate.getMinutes();
    const seconds = newDate.getSeconds();
    const fullHours = `${hours < 10 ? '0' : ''}${hours}`;
    const fullMinutes = `${minutes < 10 ? '0' : ''}${minutes}`;
    const fullSeconds = `${seconds < 10 ? '0' : ''}${seconds}`;

    const zhWeek = dateUtils.getWeek(day, 'zh');
    const enWeek = dateUtils.getWeek(day, 'en');

    const enMount = dateUtils.getMonth(month, 'en');
    const zhMonth = dateUtils.getMonth(month, 'zh');

    const zhDate = dateUtils.getZHValue(date);
    const zhYear = dateUtils.getZHValue(year);

    const cfg = DATE_TEMPLATE[format];
    if (!cfg) {
      return '';
    }

    let result: string = cfg;
    const lst = [
      year, // y
      fullMonth, // m
      fullDate, // d
      zhWeek, //W
      zhYear, // Y
      zhMonth, // M
      zhDate, // D
      enMount, // MM
      enWeek, // w
      fullHours, // hh
      fullMinutes, // mm
      fullSeconds, // ss
    ];
    ['y', 'm', 'd', 'W', 'Y', 'M', 'D', 'MM', 'w', 'hh', 'mm', 'ss'].forEach((k, i) => {
      const _k = `{${k}}`;
      result = result.replace(_k, `${lst[i]}`);
    });

    return result;
  }

  getNumber(min: number, max: number, decimal?: number): string {
    const rang = max - min;
    const value = Math.random() * rang;
    if (max <= 1) {
      return (value + min).toFixed(2);
    }
    let valueStr: string;
    if (decimal && decimal !== 0) {
      valueStr = (min + value).toFixed(decimal);
    } else {
      valueStr = `${min + Math.floor(value)}`;
    }
    return valueStr;
  }

  getFormatNumber(type: NumberType, unit?: string): string {
    switch (type) {
      case NumberType.decimalNumber:
        return this.getNumber(10, 100, 2);
      case NumberType.largNumber:
        return this.getNumber(1000, 10000);
      case NumberType.percentNumber:
        return `${this.getNumber(1, 100)}%`;
      case NumberType.priceNumber:
        return `${languageManager.isZHVersion ? '￥' : '$'}${this.getNumber(0, 100, 2)}`;
      case NumberType.serialNumber:
        return this.getSerial(unit ?? '');
      case NumberType.zipCodeNomber:
        return this.getNumber(100000, 1000000);
      case NumberType.idCardNumber: {
        const area = this.getNumber(110000, 820000);
        const code = this.getNumber(100, 1000);
        const r = parseInt(this.getNumber(0, 15));
        const dateNum = Date.now() - Math.round(Math.random() * 1576800000000 /* 50 * 365 * 24 * 60 * 60 * 1000 */);
        const date = new Date(dateNum);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${area}${year}${month >= 10 ? '' : '0'}${month}${day >= 10 ? '' : '0'}${day}${code}${
          r >= 10 ? 'X' : r
        }`;
      }
      default:
        return '';
    }
  }

  getCity(): string {
    const data = this.data;
    if (!data) {
      return '';
    }
    const city: string[] = [];
    Object.keys(data.city).forEach((stateName) => {
      const state = data.city[stateName];
      if (Array.isArray(state)) {
        city.push(...state);
      } else {
        city.push(...Object.keys(state));
      }
    });
    return this.getRandomValue(city);
  }

  getCountry(): string {
    return this.data ? this.getRandomValue(this.data.country) : '';
  }

  getProvince(): string {
    return this.data ? this.getRandomValue(Object.keys(this.data.city)) : '';
  }

  getAddress(): string {
    const city = this.getNativePlace();
    // const road = this.getRandomValue(this.data.road);
    const num = (len: number) => {
      const l = Math.pow(10, len);
      return Math.floor(Math.random() * l);
    };

    const houseNum = `${num(2)}号${num(2)}栋${num(3)}室`;
    return `${city}${houseNum}`;
  }

  getNativePlace(): string {
    if (!this.data) {
      return '';
    }

    const data = this.data.city;
    const provinceNames = Object.keys(data);

    let provinceName = this.getRandomValue(provinceNames);
    let cityName = '';
    let districtName = '';
    let streetName = '';

    const provinceValue = data[provinceName];
    const cityNames = Array.isArray(provinceValue) ? provinceValue : Object.keys(provinceValue);
    cityName = this.getRandomValue(cityNames);

    if (!Array.isArray(provinceValue)) {
      const cityValue = provinceValue[cityName];
      const districtNames = Array.isArray(cityValue) ? cityValue : Object.keys(cityValue);
      districtName = this.getRandomValue(districtNames);

      if (!Array.isArray(cityValue)) {
        const districtValue = cityValue[districtName];
        const streetNames = Array.isArray(districtValue) ? districtValue : Object.keys(districtValue);
        streetName = this.getRandomValue(streetNames);
      }
    }

    return `${provinceName}${cityName}${districtName}${streetName}`;
  }

  getContent(): string {
    return this.data ? this.getRandomValue(this.data.content) : '';
  }

  getSerial(prefix: string): string {
    return `${prefix}${this.getDate(DateFormat.YYMMDD_HHMMSS).replace(/[\\/|:|\W]/g, '')}`;
  }

  getCharCode(len: number = 10): string {
    let codeStr = '';
    for (let i = 0; i < len; i++) {
      codeStr += `${CHARS[Math.floor(Math.random() * CHARS.length)]}`;
    }
    return codeStr;
  }

  getIdentity(): string {
    const coefficientArray = ['7', '9', '5', '8', '4', '2', '1', '6', '3', '7', '9', '5', '8', '4', '2', '0']; // 加权因子
    const lastNumberArray = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']; // 校验码
    const addressArray = [
      '4212',
      '5201',
      '2101',
      '3810',
      '4208',
      '4601',
      '5501',
      '2501',
      '5138',
      '5112',
      '3110',
      '2125',
    ];
    const address = addressArray[Math.floor(Math.random() * addressArray.length)]; // 住址
    // const birthday = "" + (Math.ceil(40 * Math.random()) + 1960) + "0" + (Math.ceil(9 * Math.random()) + 1) + (Math.ceil(20 * Math.random()) + 10); // 生日
    // let s = Math.floor(Math.random()*11).toString() + Math.floor(Math.random()*11).toString() + Math.floor(Math.random()*11).toString();
    const lastCode = ['', '', '']
      .map(() => coefficientArray[Math.floor(Math.random() * coefficientArray.length)])
      .join('');
    const s = lastNumberArray[Math.floor(Math.random() * lastNumberArray.length)];
    const id_no_String = address + '**********' + lastCode + s;
    return id_no_String;
  }

  getImage(category: string): string {
    if (!this.data) {
      return '';
    }

    const group = this.data.image.find((item) => item.category === category);
    if (group) {
      return this.getRandomValue(group.items);
    }
    return '';
  }

  /**
   * 开始执行填充 , 可灵活操作批处理填充
   * @param fillType   填充类型 - 图片或文本
   * @param category   填充类别 一
   * @param subCategory   填充类别二
   * @return (value?: string) => string  value=旧值 ,用于判断与新值是否一致,并重新获取值
   */
  start(
    fillType: AutoFillType,
    category: TextCategory,
    subCategory: TextSubCategory | string,
  ): (value?: string) => string {
    this.serialIndex = 0;
    if (AutoFillType.image === fillType) {
      return this.getImage.bind(this, category);
    }
    const fn = (value?: string): string => {
      let newVal = this.getTextValue(category, subCategory);

      const noAgainGet = category === TextCategory.serial; // 是否需要对比新旧值一致性 - 重新获取值
      if (value && !noAgainGet && newVal === value) {
        newVal = fn(value);
      }
      return newVal;
    };
    return fn;
  }

  getTextValue(category: TextCategory, subCategory?: TextSubCategory | string) {
    const index = this.serialIndex;
    const max = this.serialMax;

    switch (category) {
      case TextCategory.name:
        if (subCategory === 'male') {
          return this.maleName();
        } else if (subCategory === 'female') {
          return this.femaleName();
        }
        return this.bothName();
      case TextCategory.phone:
        if (subCategory === 'tel') {
          return this.getTelPhone();
        }
        return this.getMobilePhone();
      case TextCategory.email:
        return this.getEmail();
      case TextCategory.number: {
        if (TextNumberCategory.tiny === subCategory) {
          return this.getNumber(0, 1);
        }
        if (TextNumberCategory.small === subCategory) {
          return this.getNumber(1, 10);
        }
        if (TextNumberCategory.middle === subCategory) {
          return this.getNumber(10, 100);
        }
        if (TextNumberCategory.large === subCategory) {
          return this.getNumber(100, 1000);
        }
        if (TextNumberCategory.huge === subCategory) {
          return this.getNumber(1000, 100000);
        }
        return '';
      }
      case TextCategory.address:
        if (subCategory === TextAddressCategory.country) {
          return this.getCountry();
        } else if (subCategory === TextAddressCategory.province) {
          return this.getProvince();
        } else if (subCategory === TextAddressCategory.city) {
          return this.getCity();
        }
        return this.getAddress();
      case TextCategory.date:
      case TextCategory.time:
        return this.getDate(subCategory as DateFormat);
      case TextCategory.text:
        return this.getContent();
      case TextCategory.serial: {
        let serial = subCategory === 'desc' ? `${max - index}` : `${index + 1}`;
        switch (subCategory) {
          case 'desc':
            serial = `${max - index}`;
            break;
          case 'ramdom':
            serial = `${Math.round(Math.random() * max)}`;
            break;
          case 'serial':
            return this.getCharCode(12);
          case 'identity':
            return this.getIdentity();
          default:
            serial = `${index + 1}`;
            break;
        }
        while (serial.length < 2) {
          serial = `0${serial}`;
        }
        this.serialIndex++;
        if (this.serialIndex >= max) {
          this.serialIndex = 0;
        }
        return `${serial}`;
      }
      default:
        break;
    }
    return '';
  }
}

export { default as IAutoFill, TextCategory, ImageCategory } from './type';
export { initImageCategory, initTextCategory } from './consts';
