import * as asserts from 'assert';
import i18n from '@/i18n';
import { getNewID } from '@/helpers/idHelper';
import { IComponentData } from '@fbs/rp/models/component';
import { HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import { TextAlign, VerticalAlign as TextVerticalAlign } from '@/fbs/rp/models/properties/text';
import { FillType } from '@/fbs/rp/models/properties/fill';
import { PredefinedStates } from '@/consts/state';
import { LinePointType } from '@/fbs/rp/models/properties/line';
import IStroke, { StrokeLineCap, StrokeLineJoin, StrokePosition } from '@/fbs/rp/models/properties/stroke';
import IRadius from '@fbs/rp/models/properties/radius';
import IBorder from '@fbs/rp/models/properties/border';
import { InputModel } from '@/fbs/rp/models/properties/inputModel';
import IShadow from '@fbs/rp/models/properties/shadow';
import { DefaultStrokeColor, DefaultTextColor, TextShadowColor } from '@/consts/colors';
import { DefaultBorder } from '@/consts/border';
import { getEnumValue } from '@/utils/enumUtils';
import * as SystemColors from '@consts/colors';
import * as AntDesignColors from '@consts/colors/ant';
import { DefaultStrokeColor1 } from '@consts/colors';

import {
  CPureText,
  CText,
  CParagraph,
  CInput,
  CTextArea,
  CRect,
  CEllipse,
  CLine,
  CPath,
  CPolygon,
  CCompoundPath,
  CIcon,
  CImage,
  CGroup,
  CCanvasPanel,
  CContentPanelV2,
  CFrame,
  CStackPanel,
  CWrapPanel,
  CGridPanel,
  CContentPanel,
  CListLayoutPanel,
  CSelect,
  CSlider,
  CQRCode,
  CNumericStep,
  CArc,
  CSymbol,
} from './constants';

import { IComponentItem } from './types';

export const makeCommonComponent = (id: string, type: string, data: Partial<IComponentData>): IComponentData => {
  return Object.assign<IComponentData, Partial<IComponentData>, {}>(
    {
      _id: id,
      type: type,
      connectors: [],
      layout: {
        responsive: false,
        auto: true,
        fixedWidth: false,
        fixedHeight: false,
        horizontal: HorizontalAlign.Auto,
        vertical: VerticalAlign.Auto,
      },
      properties: {},
      interaction: {},
      size: {
        width: 0,
        height: 0,
      },
      position: {
        x: 0,
        y: 0,
      },
      v: 0,
      states: {},
    },
    data,
    {
      _id: id, // 这里强制覆盖，防止data中带id的情况
    },
  );
};

export function addTooltipsProperty<T extends (...args: any[]) => any>(
  makeFunc: T,
): (...funcArgs: Parameters<T>) => ReturnType<T> {
  return (...args: Parameters<T>): ReturnType<T> => {
    const result = makeFunc(...args);

    if (!result?.properties?.tooltips) {
      result.properties.tooltips = {
        name: i18n('property.propertyNames.tooltips'),
        prop: 'string',
        value: '',
      };
    }
    return result;
  };
}

/**
 * 文本类型
 * @type {string[]}
 */
export const TextTypes: string[] = [CPureText, CText, CParagraph, CInput, CTextArea];

export function isTextType(type: string) {
  return TextTypes.indexOf(type) !== -1;
}

export function isRichText(type: string) {
  return [CText, CParagraph, CRect, CEllipse, CLine, CPath, CPolygon].indexOf(type) !== -1;
}

export function isShapeText(type: string) {
  return [CRect, CEllipse, CLine, CPath, CPolygon, CCompoundPath].indexOf(type) !== -1;
}

export function isBasicComp(type?: string) {
  if (!type) {
    return false;
  }
  return [CRect, CEllipse, CLine, CPath, CPolygon].indexOf(type) !== -1;
}

// const dnsURL = 'https://img02.mockplus.cn';

// 获取 VPC 网络中的私有访问地址
export function getVPCPrivateURL(originalURL: string): string {
  // if (originalURL && originalURL.startsWith(dnsURL)) {
  //   return originalURL.replace(dnsURL, 'http://mockplus-static.oss-cn-hangzhou-internal.aliyuncs.com');
  // }
  return originalURL;
}

const ComponentNames: {
  [name in string]: string;
} = {
  Rect: CRect,
  Ellipse: CEllipse,
  Icon: CIcon,
  Image: CImage,
  Line: CLine,
  Path: CPath,
  Polygon: CPolygon,
  Text: CText,
  Paragraph: CParagraph,
  Input: CInput,
  Group: CGroup,
  CanvasPanel: CCanvasPanel,
  ContentPanelV2: CContentPanelV2,
  Frame: CFrame,
  StackPanel: CStackPanel,
  WrapPanel: CWrapPanel,
  GridPanel: CGridPanel,
  ContentPanel: CContentPanel,
  ListLayoutPanel: CListLayoutPanel,
  Select: CSelect,
  Range: CSlider,
  QRCode: CQRCode,
  NumericStep: CNumericStep,
  Arc: CArc,
  PureText: CPureText,
  Symbol: CSymbol,
};

const EnumConfig: { [key: string]: any } = {
  TextAlign: TextAlign,
  FillType: FillType,
  PredefinedStates: PredefinedStates,
  HorizontalAlign: HorizontalAlign,
  VerticalAlign: VerticalAlign,
  LinePointType: LinePointType,
  StrokeLineCap: StrokeLineCap,
  StrokeLineJoin: StrokeLineJoin,
  TextVerticalAlign: TextVerticalAlign,
  InputModel: InputModel,
  StrokePosition: StrokePosition,
};

export function getTextShadow() {
  return {
    disabled: true,
    hidden: false,
    x: 0,
    y: 3,
    blur: 6,
    color: TextShadowColor,
  };
}

export function getDefaultShadow(): IShadow {
  return {
    disabled: true,
    hidden: false,
    x: 0,
    y: 3,
    blur: 6,
    color: SystemColors.ShadowColor,
  };
}

function getValue(pattern: string): any {
  if (pattern === 'id') {
    return getNewID();
  } else if (pattern === 'defaultShadow') {
    return getDefaultShadow();
  } else if (pattern === 'textShadow') {
    return getTextShadow();
  } else if (pattern === 'defaultStrokeColor') {
    return DefaultStrokeColor;
  } else if (pattern === 'defaultTextColor') {
    return DefaultTextColor;
  } else if (pattern === 'defaultBorder') {
    return DefaultBorder;
  } else if (pattern.indexOf('.') > 0) {
    const parts = pattern.split('.');
    const [key, value] = parts;
    asserts.equal(parts.length, 2);
    if (EnumConfig[key]) {
      return getEnumValue(EnumConfig[key], value);
    }
    switch (key) {
      case 'SystemColors':
        // @ts-ignore
        return SystemColors[value];
      case 'AntDesignColors':
        // @ts-ignore
        return AntDesignColors[value];
      case 'C':
        return ComponentNames[value];
    }
  }
  return '';
}

export function getDefaultBorder(): IBorder {
  return {
    left: true,
    top: true,
    right: true,
    bottom: true,
  };
}

export function getDefaultStroke(): IStroke {
  return {
    thickness: 1,
    color: DefaultStrokeColor1,
    cap: StrokeLineCap.Butt,
    join: StrokeLineJoin.Miter,
    position: StrokePosition.inner,
    mode: 'custom',
    disabled: true,
  };
}

export function getDeafultRadius(): IRadius {
  return {
    topRight: 0,
    topLeft: 0,
    bottomLeft: 0,
    bottomRight: 0,
    isPercent: false,
    disabled: false,
  };
}

// 填充子项
export function fillItems(template: string, item: string): string {
  let result = template;
  // eslint-disable-next-line no-useless-escape
  const reg = /@@ITEM-([a-z\d\-\{\s\}\u4e00-\u9fa5|]*)/gi;
  // const reg = /@@ITEM-(.+)/gi;
  let match;
  while ((match = reg.exec(template)) !== null) {
    const params = match[1].split('|');

    const currentItem = item.replace(/{(\d+)}/g, (m, number) => {
      if (params[number] === undefined) {
        return m;
      }
      return params[number];
    });
    result = result.replace(match[0], currentItem);
  }
  return result;
}

// 编译模板字符串：替换其中的系统预设定义值
export function executeTemplate(template: string): IComponentData {
  let result = template;
  // eslint-disable-next-line no-useless-escape
  const reg = /@@[a-z\d\.\-\{\s\}]+/gi;
  const matches = template.match(reg);
  if (matches) {
    matches.forEach((match) => {
      const value = getValue(match.substr(2));
      if (value) {
        result = result.replace(match, JSON.stringify(value));
      } else {
        console.warn(`Not supported pattern ${match}.`);
      }
    });
  }
  try {
    const func = new Function(`return ${result};`);
    return func();
  } catch (e) {
    console.log(result);
    throw e;
  }
}

export function getDefaultDataFromTemplate(libItem: IComponentItem): IComponentData | undefined {
  let { template = '', item = '', isList } = libItem;
  if (!template) {
    return undefined;
  }

  // 这里必须trim下，原因待确认
  template = template.trim();
  if (isList && item) {
    template = fillItems(template, item);
  }

  return executeTemplate(template);
}
