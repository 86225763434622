import { measureTextSize } from '@utils/textUtils';
import { max } from '@utils/globalUtils';
import { toFixedPoint } from '@utils/boundsUtils';

import { MoveDelta } from '@fbs/common/models/resize';
import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { EventTypes } from '@fbs/rp/models/event';
import { IRange } from '@fbs/rp/models/properties/base';
import { PropertyName, PropertyValue } from '@fbs/rp/models/property';
import ITextFormatEx from '@/fbs/rp/models/properties/textFormat';

import i18n from '@i18n';

import { UIComponent, UIContainerComponent } from '@editor/comps';
import { ArtboardPatchesClass } from '@editor/patches/artboardPatches';
import { ComponentPatchesClass } from '@editor/patches/ComponentPatches';
import { getFinalPositionWhenMove } from '@editor/corePartial/helper';
import { ComponentChangeType, getNewPositionWhenCenter, getOffsetBySizeChange } from '@editor/comps/resizeHelper';

import { coverPatches } from '@helpers/patchHelper';
import { PredefinedStates } from '@consts/state';
import ValueEditorType from '@consts/enums/valueEditorType';
import { SpriteThumb } from '@consts/spriteIcons';
import { StyleHelper } from '@helpers/styleHelper';
import { getChangedValue } from '@/helpers/componentHelper';
import appOptions from '@/helpers/appOptions';
import { getDefaultDataFromTemplate } from '@/libs/helper';

import { IComponentItem } from '../../types';
import { CPureText } from '../../constants';
import { MoveMode, PropertyStructureAlign } from '../../enum';

const defaltValue = [
  `${i18n('resource.componentsText.itemText')} 1`,
  `${i18n('resource.componentsText.itemText')} 2`,
  `${i18n('resource.componentsText.itemText')} 3`,
];

const defaultTextSize = defaltValue.map((text) =>
  measureTextSize({ fontSize: 14, fontFamily: 'Microsoft YaHei' }, text),
);

/**
 * 移动子项
 * @description 用于高级编辑
 * @param {MoveDelta} delta
 * @param {UIContainerComponent} sealedComp
 * @param {UIComponent[]} children
 * @returns {ArtboardPatches | null}
 */
export function onChildMove(
  delta: MoveDelta,
  sealedComp: UIContainerComponent,
  children?: UIComponent[],
): ArtboardPatches | null {
  const patches = new ArtboardPatchesClass();

  const isMovingTextInCheckBox =
    children && children.length === 1 && (children[0].alias === 'pureText' || children[0].type === CPureText);
  if (isMovingTextInCheckBox) {
    const movingComp = children![0];
    const originPosition = movingComp.position;
    const parentOfMovingComp = movingComp.parent!;
    const newPosition = getFinalPositionWhenMove([movingComp], delta)[0].position;
    const path = parentOfMovingComp.getCurrentPropertiesPath('properties/layout');
    const compId = parentOfMovingComp.id;
    const horizontalDiff = newPosition.x - originPosition.x;
    const properties = parentOfMovingComp.properties;
    const newValue = {
      ...properties.layout,
      horizontalGap: max(0, properties.layout?.horizontalGap! + horizontalDiff),
    };
    const componentPatches = new ComponentPatchesClass().getAttrChangePatches(compId, path, {
      oldVal: properties,
      newVal: newValue,
    });
    const result = patches.getPatchesByCompChange(compId, componentPatches);

    const { id, currentStateID, states } = parentOfMovingComp;
    if (currentStateID && currentStateID !== PredefinedStates.normal && !states[currentStateID]) {
      const path = `./states/${currentStateID}`;
      result.do[id].unshift(Ops.add(path, { enabled: true, properties: {} }));
      result.undo[id].push(Ops.remove(path));
    }

    return result;
  }
  return null;
}

export function onPropertyUpdate(
  comp: UIComponent,
  propertyName: PropertyName,
  newValue: PropertyValue,
): ArtboardPatches | null {
  if (propertyName !== 'iconSize' && propertyName !== 'textStyle1') {
    return null;
  }
  const patches: ArtboardPatches = {
    do: {},
    undo: {},
  };
  const checkGroup = comp as UIContainerComponent;

  if (propertyName === 'iconSize') {
    const checkIndex = 0;
    const newIconSizeValue = (newValue.value as IRange).value;
    checkGroup.components.forEach((item) => {
      const iconWrapper = (item as UIContainerComponent).getComponentByAlias('iconWrapper');
      if (iconWrapper) {
        const wrapContainer = iconWrapper as UIContainerComponent;
        const wrapperSize = iconWrapper.size;
        const checkComp = wrapContainer.components[checkIndex];
        const newWrapperSize = { ...wrapperSize, width: newIconSizeValue, height: newIconSizeValue };
        const path = iconWrapper.getCurrentSizePath();
        patches.do[iconWrapper.id] = [Ops.replace(path, newWrapperSize)];
        patches.undo[iconWrapper.id] = [Ops.replace(path, wrapperSize)];
        if (checkComp) {
          const checkSize = checkComp.size;
          const ratio = {
            x: checkSize.width / wrapperSize.width,
            y: checkSize.height / wrapperSize.height,
          };
          const newCheckSize = {
            ...checkSize,
            width: newWrapperSize.width * ratio.x,
            height: newWrapperSize.height * ratio.y,
          };

          const { position, rotate, size: newCheckIconSize } = checkComp.getResizeMySelfResult(
            getOffsetBySizeChange(checkSize, newCheckSize),
          );

          const newIconPosition = getNewPositionWhenCenter(checkComp, position, newCheckIconSize, newWrapperSize, {
            isLayoutCenterAtHorizontal: true,
            isLayoutMiddleAtVertical: true,
          });

          const { patches: iconPatches } = wrapContainer.getPositionPatchesOfChildrenChanged(
            [
              {
                id: checkComp.id,
                type: ComponentChangeType.Edit,
                size: newCheckIconSize,
                rotate,
                position: toFixedPoint(newIconPosition, 1),
              },
            ],
            false,
          );
          coverPatches(patches, iconPatches);
        }
      }
    });
  } else if (propertyName === 'textStyle1') {
    // 文本样式修改，同步修改选中项的size
    const textStyle = newValue as ITextFormatEx;
    const defaultGroupTextStyle = checkGroup.properties.textStyle1 as ITextFormatEx;
    const diffTextStyle = getChangedValue(textStyle, defaultGroupTextStyle);
    checkGroup.components.forEach((item) => {
      const checkbox = item as UIContainerComponent;
      const textComp = checkbox.getComponentByAlias('pureText');
      if (textComp && item.selected) {
        const { size: oldSize, id } = textComp;
        const oldTextStyle = textComp.properties.textStyle;
        const style = StyleHelper.initCSSStyleParser({}).doGetTextStyle({ ...oldTextStyle, ...diffTextStyle });
        const { width, height } = measureTextSize(style, textComp.value as string, {});
        patches.do[id] = [Ops.replace('./size', { ...oldSize, width, height })];
        patches.undo[id] = [Ops.replace('./size', oldSize)];
      }
    });
  }

  return patches;
}

export const CheckBoxGroupCfg = {
  type: 'checkbox-group',
  name: i18n('resource.components.checkboxGroup'),
  thumb: {
    spriteIconClass: SpriteThumb.CheckBoxGroup.className,
    dragPosition: SpriteThumb.CheckBoxGroup.position,
  },
  isList: true,
  isTextComp: true,
  predefinedStates: [PredefinedStates.disabled],
  value: {
    type: ValueEditorType.ListItemPureTextValues,
  },
  constraint: {
    firstLevelContainer: {
      move: MoveMode.neither,
    },
    pureText: {
      move: MoveMode.horizontal,
    },
    iconWrapper: {
      move: MoveMode.neither,
    },
  },
  editor: {
    onChildMove,
    onPropertyUpdate,
  },
  interactions: {
    defaultEvent: EventTypes.checked,
  },
  syncPropertyToStateNormal: {
    structure: true,
  },
  property: {
    getExtensionDefaultProperties: (comp: UIComponent) => {
      const compData = comp.toJSON();
      const { iconSize, structure } = compData.properties;
      const typeName = compData.lib?.type ?? comp.type;
      if (iconSize && structure) {
        return null;
      }
      return {
        iconSize: iconSize || {
          name: i18n('property.propertyNames.iconSize'),
          prop: 'number',
          value: {
            value: 13,
            min: 10,
            max: 100,
          },
        },
        structure: structure ||
          appOptions.getStructureAlign(typeName) || {
            value: PropertyStructureAlign.TextRight,
            prop: 'enum',
            name: 'structure',
          },
      };
    },
  },
  item: `
  {
    type: @@C.StackPanel,
    alias: 'firstLevelContainer',
    size: {
      width: {3},
      height: ${Math.max(16, defaultTextSize[0].height)},
    },
    properties: {
      layout:{
        direction: 'horizontal',
        horizontalGap: 8,
        verticalAlign: 'middle',
        disabledSwitch: true,
        disabled: false,
      },
    },
    value: '',
    components: [
      {
        type: @@C.CanvasPanel,
        alias:'iconWrapper',
        size: {
          width: 13,
          height: 13,
        },
        position:{
          x: 0,
          y: 2,
        },
        layout:{
          vertical: 'auto',
          horizontal: 'left',
          fixedWidth: true,
          fixedHeight: true,
          auto: false,
          responsive: true,
        },
        properties: {
          stroke: {
            color: @@SystemColors.DefaultSelectColor,
            thickness: 1,
            disabled: false
          },
          fill:{
            type: 'solid',
            color: {r: 255, g: 255, b: 255, a: 1},
            disabled: false,
          },
        },
        states: {
          checked: {
            enabled: true,
            name: '${i18n('property.propertyNames.checked')}',
            properties:{
              fill:{
                type: @@FillType.solid,
                color: @@SystemColors.DefaultSelectColor,
                disabled: false,
              },
              stroke:{disabled:true}
            },
          },
        },        
        components: [
        
          {
            type: @@C.Path,
            position: {
              x: 3.5,
              y: 4.5,
            },
            size: {
              width: 6,
              height: 4,
            },
            layout:{
              vertical: 'auto',
              horizontal: 'left&right',
              fixedWidth: false,
              fixedHeight: false,
              auto: false,
              responsive: true,
            },
            properties: {
              stroke:{
                cap:"butt",
                color:{a:1,b:255,g:255,r:255},
                disabled:false,
                join:"miter",
                position:"center",
                thickness:2
              }             
            },
            value: {
              closed: false,
              data: [
                { handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 }, point: { x: 0, y: 1 } },
                { handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 }, point: { x: 2.5, y: 3.5 } },
                { handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 }, point: { x: 6, y: 0 } },
              ],
            },
            hidden: true,
            states: {
              checked: {
                enabled: true,
                name: '${i18n('property.propertyNames.checked')}',
                hidden: false,
                properties:{
                  stroke:{
                    cap:"butt",
                    color:{a:1,b:255,g:255,r:255},
                    disabled:false,
                    join:"miter",
                    position:"center",
                    thickness:2
                  }                
                },
              },
            },
          },
        ],
      },
      {
        type: @@C.PureText,
        alias:'pureText',
        autoSize: true,
        size: {
          width: {2},
          height: ${defaultTextSize[0].height},
        },
        position:{
          x: 26,
          y: 2,
        },
        layout:{
          vertical: 'middle',
          horizontal: 'left&right',
          fixedWidth: false,
          fixedHeight: true,
          auto: false,
          responsive: true,
        },
        value: '{0}',
        properties: {
          textStyle: {
            ref: '@properties.textStyle1',
          },
        },
        states: {
          checked: {
            name: '${i18n('property.propertyNames.checked')}',
            enabled: true,
            properties: {
              textStyle: {
                ref: '@properties.textStyle2',
              },
            },
          },
        },
      },
    ],
    selected: {1},
  }
  `,
  template: `
  {
    type: @@C.GridPanel,
    size: { 
      width: ${defaultTextSize.reduce((acc, curr) => {
        return acc + curr.width + 26;
      }, (defaultTextSize.length - 1) * 10)},
      height: ${Math.max(defaultTextSize[0].height, 16)}
    },
    layout:{
      vertical: 'middle',
      horizontal: 'left',
      fixedWidth: true,
      fixedHeight: true,
      auto: true,
      responsive: true,
    },
    properties: {
      cell:{
        columnCount:1
      },
      layout: {
        direction: 'horizontal',
        verticalAlign: 'middle',
        horizontalAlign: 'left',
        verticalGap: 10,
        horizontalGap: 10,
        disabledSwitch:true,
      },
      textStyle1: {
        prop: 'textStyle',
        name: '${i18n('property.propertyNames.defaultText')}',
        fontFamily: 'Microsoft YaHei',
        fontSize: 14,
        color: @@SystemColors.DefaultTextColor,
        disabled: false,
      },
      textStyle2: {
        prop: 'textStyle',
        name: '${i18n('property.propertyNames.checkedText')}',
        fontFamily: 'Microsoft YaHei',
        fontSize: 14,
        color: @@SystemColors.DefaultTextColor,
        disabled: false,
      },
      iconSize: {
        prop: 'number',
        name: '${i18n('property.propertyNames.iconSize')}',
        value: {
          value: 13,
          min: 10,
          max: 100
        }
      }
    },
    states:{
      disabled:{
        enabled: false,
        properties:{
          textStyle1: {
            prop: 'textStyle',
            fontSize: 14,
            color: @@SystemColors.DisabledTextColor,
          },
          textStyle2: {
            prop: 'textStyle',
            fontSize: 14,
            color: @@SystemColors.DisabledTextColor,
          },
        },
      },
    },
    value: '',
    components: [
      ${defaltValue
        .map(
          (text, index) =>
            `@@ITEM-${text}|${index === 0}|${defaultTextSize[index].width}|${defaultTextSize[index].width + 26},`,
        )
        .join('\n')}
    ],
    select: {
      target: 'child',
      enabled: true,
      maxCount: -1,
      minCount: 0,
      reversible: true,
      autoUnselect: false,
    },
    sealed: true,
  }
  `,
  itemArgs: [
    {
      type: 'string',
      value: 'Item',
    },
    {
      type: 'boolean',
      value: false,
    },
  ],
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
} as IComponentItem;

const defaultData = getDefaultDataFromTemplate(CheckBoxGroupCfg);
