import * as _ from 'lodash';

import * as GraphicsUtils from '@utils/graphicsUtils';
import { createRectStrokePath } from '@utils/graphicsUtils';
import xmlUtils from '@utils/xmlUtils';
import MathUtils from '@utils/mathUtils';

import { UIComponent } from '@/editor/comps';
import { IProperties } from '@fbs/rp/models/property';
import { default as IFill, FillType } from '@fbs/rp/models/properties/fill';
import { Color, IGradientColor, ILinearGradientColor, IRadialGradientColor } from '@fbs/rp/models/properties/color';
import { default as ITextFormat, TextAlign, TextTransform } from '@fbs/rp/models/properties/text';
import IRadius from '@fbs/rp/models/properties/radius';
import IStroke, {
  PresetDashModel,
  StrokeLineCap,
  StrokeLineJoin,
  StrokePosition,
} from '@fbs/rp/models/properties/stroke';
import ITextFormatEx from '@fbs/rp/models/properties/textFormat';
import IMultiText from '@fbs/rp/models/properties/multiText';
import IShadow from '@fbs/rp/models/properties/shadow';
import { ISize } from '@fbs/common/models/common';
import IBorder from '@fbs/rp/models/properties/border';

import { DefaultFontSize, FontBoxScale, getStyleFontFamily } from '@consts/fonts';
import { ShadowColor, TransparentColor } from '@consts/colors';
import { CCanvasPanel, CButton, CPath, CHotArea, CText, CLine, CRect, CEllipse, CPolygon } from '@libs/constants';

export namespace StyleHelper {
  type TextAlignValue = 'left' | 'center' | 'right' | 'justify';

  export const parserFill = (fill: IFill, size?: ISize) => {
    return _.memoize(() => {
      const { type, color, disabled } = fill;
      if (!disabled) {
        if (!type || type === FillType.solid) {
          return {
            background: GraphicsUtils.parseColorToString(color ? (color as Color) : TransparentColor),
          };
        } else {
          const gradient = color as IGradientColor;
          if (type === FillType.linear) {
            return {
              background: GraphicsUtils.parseLinearToString(gradient as ILinearGradientColor, size),
            };
          } else {
            return {
              background: GraphicsUtils.parseRadialToString(gradient as IRadialGradientColor),
            };
          }
        }
      }
      return {};
    })();
  };

  const doParserStroke = (stroke: IStroke) => {
    const { thickness, color, mode, dashArray, dashModel } = stroke;
    let borderStyle: 'solid' | 'dashed' | 'dotted' | undefined = undefined;
    switch (dashModel) {
      case PresetDashModel.shortDash:
      case PresetDashModel.dashDot:
      case PresetDashModel.dashDotDot:
      case PresetDashModel.longDash:
      case PresetDashModel.longDashDot:
      case PresetDashModel.middleDash:
        borderStyle = 'dashed';
        break;
      case PresetDashModel.dot:
        borderStyle = 'dotted';
        break;
      case PresetDashModel.solid:
        borderStyle = 'solid';
        break;
      default:
        mode !== 'custom' && (borderStyle = 'solid');
        break;
    }
    if (!borderStyle && mode === 'custom') {
      if (dashArray && dashArray.length) {
        const [d1] = dashArray;
        const d2 = dashArray.length > 1 ? dashArray[1] : d1;
        if (d1 !== 0 && d2 !== 0) {
          borderStyle = 'dashed';
          if (MathUtils.isMoreOrLess(d1, thickness || 1, 1) && MathUtils.isMoreOrLess(d2, thickness || 1, 1)) {
            borderStyle = 'dotted';
          }
        }
      } else {
        borderStyle = 'solid';
      }
    }
    return {
      borderStyle,
      borderColor: GraphicsUtils.parseColorToString(color || '#000'),
      borderWidth: thickness,
    };
  };

  export const parserStroke = (stroke: IStroke): React.CSSProperties => {
    return _.memoize(() => {
      const { disabled } = stroke;
      const style: React.CSSProperties = {};
      if (!disabled) {
        const { borderStyle, borderWidth, borderColor } = doParserStroke(stroke);
        style.borderWidth = borderWidth;
        style.borderColor = borderColor;
        style.borderStyle = borderStyle;
      }
      return style;
    })();
  };

  export const parserSeparator = (direction: 'right' | 'bottom', stroke?: IStroke) => {
    if (!stroke || stroke.disabled) {
      return {};
    }
    const { thickness } = stroke;
    if (thickness === 0) {
      return {};
    }
    const { borderColor, borderWidth, borderStyle } = doParserStroke(stroke);

    if (direction === 'bottom') {
      return {
        borderBottomColor: borderColor,
        borderBottomWidth: borderWidth,
        borderBottomStyle: borderStyle,
      };
    }
    return {
      borderRightColor: borderColor,
      borderRightWidth: borderWidth,
      borderRightStyle: borderStyle,
      borderLeftWidth: borderWidth,
      borderLeftColor: 'transparent',
    };
  };

  export const parseSVGStroke = (stroke: IStroke): ISVGStroke => {
    if (stroke && !stroke.disabled) {
      const { thickness, color, dashArray, cap, join, mode, dashModel } = stroke;
      const strokeWidth = _.isUndefined(thickness) ? 1 : thickness;
      const scale = strokeWidth;
      const isCustom = _.isUndefined(dashModel) || dashModel === PresetDashModel.unset || mode === 'custom';
      //
      let strokeDasharray: string | undefined = undefined;
      if (isCustom) {
        const arr: number[] = [];
        const length = _.isUndefined(dashArray) ? 0 : dashArray.length;
        if (length && dashArray) {
          if (length === 1) {
            const isZero = dashArray[0] === 0;
            if (!isZero) {
              arr.push(dashArray[0] * scale, dashArray[0] * scale);
            }
          } else {
            arr.push(...dashArray.map((item) => item * scale));
          }
        }
        strokeDasharray = arr.length ? arr.join(',') : undefined;
      } else {
        if (_.isUndefined(dashModel) || dashModel === PresetDashModel.solid) {
          strokeDasharray = undefined;
        } else if (dashModel === PresetDashModel.dot) {
          // strokeDasharray = presetDashModelConfig.find((item) => item.id === dashModel)?.text;
          strokeDasharray = `${strokeWidth},${strokeWidth}`;
        } else {
          // strokeDasharray = presetDashModelConfig.find((item) => item.id === PresetDashModel.shortDash)?.text;
          strokeDasharray = `${Math.max(strokeWidth * 2, 3)},${Math.max(strokeWidth, 2)}`;
        }
        // strokeDasharray = presetDashModelConfig.find((item) => item.id === dashModel)?.text;
      }
      const result: ISVGStroke = {
        stroke: GraphicsUtils.parseColorToString(color || '#000'),
        strokeWidth,
        strokeDasharray,
        strokeLinecap: cap || StrokeLineCap.Butt,
        strokeLinejoin: join || StrokeLineJoin.Miter,
        position: StrokePosition.inner,
        // position: position || StrokePosition.center,
      };
      if (result.strokeLinecap === StrokeLineCap.Butt) {
        if (strokeWidth % 2 === 1) {
          result.strokeDashoffset = -0.5;
        }
      }
      return result;
    }
    return {
      stroke: 'none',
      strokeWidth: 0,
    };
  };

  export const parserBoxShadow = (shadow?: IShadow) => {
    if (shadow) {
      const { x, y, blur, color, disabled } = shadow;
      if (!disabled) {
        return {
          boxShadow: `${x || 0}px ${y || 0}px ${blur || 0}px ${GraphicsUtils.parseColorToString(color!)}`,
        };
      }
    }
    return {};
  };

  export const parserDropShadow = (shadow?: IShadow) => {
    if (shadow) {
      const { x, y, blur, color, disabled } = shadow;
      if (!disabled) {
        return `drop-shadow(${x || 0}px ${y || 0}px ${blur || 0}px ${GraphicsUtils.parseColorToString(
          color || ShadowColor,
        )})`;
      }
    }
    return '';
  };

  export const clearComponentStyleWithRichTextValue = (style: React.CSSProperties, text: string) => {
    const xml = xmlUtils.text2xml(text, 'root');
    let hasTextNode = !xml;
    let hasBold = false;
    let hasItalic = false;
    let hasUnderline = false;
    let hasStrike = false;
    if (xml) {
      const childNodes = xml.getRootNode().firstChild!.childNodes;
      childNodes.forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE || node.nodeType === Node.COMMENT_NODE) {
          hasTextNode = true;
        } else {
          const el = node as Element;
          const styleAtt = el.getAttribute('style');
          if (styleAtt) {
            const styleAttributeValue = el.attributes.getNamedItem('style')?.value;
            !hasBold && (hasBold = styleAttributeValue?.includes('bold') || false);
            !hasItalic && (hasItalic = styleAttributeValue?.includes('italic') || false);
            !hasUnderline && (hasUnderline = styleAttributeValue?.includes('underline') || false);
            !hasStrike && (hasStrike = styleAttributeValue?.includes('line-through') || false);
          }
        }
      });
    }

    if (!hasTextNode) {
      const { textDecorationLine, textDecoration, fontWeight, fontStyle } = style;
      if (hasUnderline) {
        const match = /underline(;?)/;
        textDecoration && (style.textDecoration = textDecoration.toString().replace(match, ''));
        textDecorationLine && (style.textDecorationLine = textDecorationLine.toString().replace(match, ''));
      }
      if (hasStrike) {
        const match = /line-through(;?)/;
        textDecoration && (style.textDecoration = textDecoration.toString().replace(match, ''));
        textDecorationLine && (style.textDecorationLine = textDecorationLine.toString().replace(match, ''));
      }
      if (hasBold) {
        fontWeight && fontWeight.toString().includes('bold') && delete style.fontWeight;
      }
      if (hasItalic) {
        fontStyle && fontStyle.includes('italic') && delete style.fontStyle;
      }
    }
  };

  class CSSStyleParser {
    protected data?: IProperties;

    private static getTextAlign(align?: TextAlign, defaultValue: TextAlignValue = 'left'): TextAlignValue {
      return align || defaultValue;
    }

    doGetTextStyle(textStyle?: ITextFormat): React.CSSProperties {
      const style = textStyle || this.data?.textStyle;
      if (style) {
        const { fontStyle, fontSize, fontFamily, textAlign, color, letterSpace, transform } = style as ITextFormat;
        let underline;
        if (fontStyle) {
          if (fontStyle.underline && fontStyle.strike) {
            underline = 'underline line-through';
          } else if (fontStyle.underline) {
            underline = 'underline';
          } else if (fontStyle.strike) {
            underline = 'line-through';
          }
        }
        let textTransform: 'uppercase' | 'lowercase' | 'capitalize' | 'none' | undefined = 'none';
        switch (transform) {
          case TextTransform.lowerCase:
            textTransform = 'lowercase';
            break;
          case TextTransform.upperCase:
            textTransform = 'uppercase';
            break;
          case TextTransform.capitalize:
            textTransform = 'capitalize';
            break;
          default:
            textTransform = undefined;
            break;
        }
        const letterSpacing = letterSpace || undefined;
        const _textAlign = CSSStyleParser.getTextAlign(textAlign || TextAlign.left);
        return {
          fontSize,
          fontFamily: fontFamily && getStyleFontFamily(fontFamily),
          textAlign: _textAlign,
          color: GraphicsUtils.parseColorToString(color || '#000'),
          fontStyle: fontStyle && fontStyle.italic ? 'italic' : undefined,
          fontWeight: fontStyle && fontStyle.bold ? 'bold' : undefined,
          textDecorationLine: underline,
          letterSpacing,
          textTransform,
        };
      }
      return {};
    }

    getTextStyle(): React.CSSProperties {
      if (this.data) {
        const { textStyle, textFormat, multiText } = this.data;
        if (textFormat) {
          return this.getTextStyleEx(textFormat);
        } else {
          return {
            ...this.doGetTextStyle(textStyle),
            ...this.doGetMultiStyle(multiText, textStyle?.fontSize),
          };
        }
      }
      return {};
    }

    getTextStyleEx(textFormat?: ITextFormatEx): React.CSSProperties {
      if (textFormat) {
        const { isMulti } = textFormat;
        const baseStyle = this.doGetTextStyle(textFormat);
        const exStyle = isMulti ? this.doGetMultiStyle(textFormat, textFormat.fontSize) : {};
        return {
          ...baseStyle,
          ...exStyle,
        };
      }
      return {};
    }

    getTextStyleData(
      size: ISize,
      textFormat?: ITextFormatEx | (ITextFormat & { isMulti?: boolean }),
    ): { size: ISize; style: React.CSSProperties } {
      if (!textFormat) {
        return { size, style: {} };
      }
      const { fontSize: fs, isMulti } = textFormat;
      const fontSize = MathUtils.value(fs, DefaultFontSize) / FontBoxScale;
      const prop = { ...textFormat, fontSize };
      const style = isMulti ? this.getTextStyleEx(prop as ITextFormatEx) : this.doGetTextStyle(prop);
      style.lineHeight = `${size.height / FontBoxScale}px`;
      return {
        size: {
          width: size.width / FontBoxScale,
          height: size.height / FontBoxScale,
        },
        style,
      };
    }

    getShadowStyle(box?: boolean): React.CSSProperties {
      if (box) {
        return parserBoxShadow(this.data?.shadow);
      }
      return { filter: parserDropShadow(this.data?.shadow) };
    }

    getTextShadow(ratio?: number): React.CSSProperties {
      if (this.data && this.data.shadow) {
        const { x, y, blur, color, disabled } = this.data.shadow;
        if (!disabled) {
          const scale = ratio || 1;
          const [_x, _y, _blur] = [x, y, blur].map((v) => (v || 0) * scale);
          return {
            // textShadow: `${x || 0}px ${y || 0}px ${blur || 0}px ${GraphicsUtils.parseColorToString(color!)}`,
            // 文字阴影的模糊取n*0.5px
            filter: `drop-shadow(${_x}px ${_y}px ${_blur * 0.5}px ${GraphicsUtils.parseColorToString(color!)})`,
          };
        }
      }
      return {};
    }

    getFillStyle(size?: ISize): React.CSSProperties {
      if (this.data && this.data.fill && !this.data.fill.disabled) {
        return parserFill(this.data.fill, size);
      }
      return {};
    }

    getStrokeStyle(): React.CSSProperties {
      let style: React.CSSProperties = {};
      if (this.data) {
        if (this.data.stroke) {
          const { disabled } = this.data.stroke;
          if (!disabled) {
            style = parserStroke(this.data.stroke);

            if (this.data.border) {
              const { left, top, right, bottom } = this.data.border;
              const _borderStyle = style.borderStyle as 'solid' | 'dashed' | 'dotted' | 'none' | undefined;
              if (_borderStyle !== 'none') {
                style.borderLeftStyle = left ? _borderStyle : 'none';
                style.borderTopStyle = top ? _borderStyle : 'none';
                style.borderRightStyle = right ? _borderStyle : 'none';
                style.borderBottomStyle = bottom ? _borderStyle : 'none';
                /**
                 * 不删除borderStyle属性会导致控制台报错
                 * 场景：图片组件设置边框属性隐藏，勾选边框，修改边框类型报错
                 */
                delete style.borderStyle;
              }
            }
          }
        }
      }
      return style;
    }

    getRadiusStyle(size: { width: number; height: number }, border?: IBorder): React.CSSProperties {
      if (this.data && this.data.radius) {
        const { bottomLeft, topRight, topLeft, bottomRight, disabled, isPercent } = this.data.radius;
        const isAllZero = topLeft === 0 && topRight === 0 && bottomLeft === 0 && bottomRight === 0;
        if (!disabled && !isAllZero) {
          const { top, left, right, bottom } = border || { top: true, left: true, right: true, bottom: true };
          let offset = 0;
          if (this.data.stroke) {
            offset = (this.data.stroke.thickness || 0) / 2;
          }
          const maxSize = MathUtils.min(size.width, size.height);
          const maxRadius = maxSize / 2;
          let borderTopLeftRadius = topLeft;
          let borderTopRightRadius = topRight;
          let borderBottomLeftRadius = bottomLeft;
          let borderBottomRightRadius = bottomRight;
          if (isPercent) {
            if (topLeft) {
              borderTopLeftRadius = MathUtils.min((topLeft * maxRadius) / 100 + offset, maxRadius);
            }
            if (topRight) {
              borderTopRightRadius = MathUtils.min((topRight * maxRadius) / 100 + offset, maxRadius);
            }
            if (bottomLeft) {
              borderBottomLeftRadius = MathUtils.min((bottomLeft * maxRadius) / 100 + offset, maxRadius);
            }
            if (bottomRight) {
              borderBottomRightRadius = MathUtils.min((bottomRight * maxRadius) / 100 + offset, maxRadius);
            }
          } else {
            if (topLeft) {
              borderTopLeftRadius = MathUtils.min(topLeft + offset, maxRadius);
            }
            if (topRight) {
              borderTopRightRadius = MathUtils.min(topRight + offset, maxRadius);
            }
            if (bottomLeft) {
              borderBottomLeftRadius = MathUtils.min(bottomLeft + offset, maxRadius);
            }
            if (bottomRight) {
              borderBottomRightRadius = MathUtils.min(bottomRight + offset, maxRadius);
            }
          }
          if (this.data.border && !this.data.border.disabled) {
            const { top, left, right, bottom } = this.data.border;
            if (!left) {
              borderBottomLeftRadius = borderTopLeftRadius = 0;
            }
            if (!right) {
              borderBottomRightRadius = borderTopRightRadius = 0;
            }
            if (!top) {
              borderTopLeftRadius = borderTopRightRadius = 0;
            }
            if (!bottom) {
              borderBottomLeftRadius = borderBottomRightRadius = 0;
            }
          }
          return {
            borderTopLeftRadius: top && left ? borderTopLeftRadius : 0,
            borderTopRightRadius: top && right ? borderTopRightRadius : 0,
            borderBottomLeftRadius: bottom && left ? borderBottomLeftRadius : 0,
            borderBottomRightRadius: bottom && right ? borderBottomRightRadius : 0,
          };
        }
      }
      return {};
    }

    private doGetMultiStyle(multi?: IMultiText, fontSize?: number): React.CSSProperties {
      if (multi) {
        const { wrap, indent, vertical, lineHeight, lineHeightEx } = multi;
        const fs = fontSize || DefaultFontSize;
        let lineHeightValue = fs;
        if (!_.isUndefined(lineHeightEx)) {
          lineHeightValue = lineHeightEx;
        } else if (!_.isUndefined(lineHeight)) {
          lineHeightValue = fs + lineHeight;
        } else {
          lineHeightValue = Math.round(fs * 1.4);
        }
        // const lineHeightValue = wrap ? (!isUndefined(lineHeight) ? `${fs + lineHeight}px` : 'initial !important') : undefined;
        // const lineHeightValue = !isUndefined(lineHeight) ? `${fs + lineHeight}px` : undefined;
        return {
          whiteSpace: wrap ? 'pre-wrap' : 'nowrap',
          wordBreak: wrap ? 'break-word' : 'normal',
          textIndent: indent ? `1.2em` : undefined,
          lineHeight: `${lineHeightValue}px`,
          writingMode: vertical ? 'vertical-rl' : undefined,
        };
      }
      return {};
    }

    getMultiStyle(): React.CSSProperties {
      if (this.data && this.data.multiText && this.data.textStyle) {
        const { fontSize } = this.data.textStyle as ITextFormat;
        return this.doGetMultiStyle(this.data.multiText, fontSize);
      }
      return {};
    }

    getPaddingStyle(isTextPadding?: boolean): React.CSSProperties {
      if (!this.data) {
        return {};
      }
      const { padding, textFormat, textStyle } = this.data;
      if (!padding || padding.disabled) {
        return {};
      }
      let ratio = 1;
      if (isTextPadding) {
        const fontSize = (textStyle || textFormat)?.fontSize || 14;
        ratio = MathUtils.min(1, fontSize / 12);
      }
      const { left, top, right, bottom } = padding;
      return {
        paddingTop: (top || 0) / ratio,
        paddingRight: (right || 0) / ratio,
        paddingBottom: (bottom || 0) / ratio,
        paddingLeft: (left || 0) / ratio,
      };
    }

    // 往放大FontBoxScale倍的标签添加padding属性
    getPaddingStyleByFontScale(): React.CSSProperties {
      if (!this.data) {
        return {};
      }
      const { padding } = this.data;
      if (!padding || padding.disabled) {
        return {};
      }
      const { left, top, right, bottom } = padding;
      return {
        paddingTop: (top || 0) / FontBoxScale,
        paddingRight: (right || 0) / FontBoxScale,
        paddingBottom: (bottom || 0) / FontBoxScale,
        paddingLeft: (left || 0) / FontBoxScale,
      };
    }
  }

  class CSSStyleParserEx extends CSSStyleParser {
    init(data: IProperties) {
      this.data = data;
    }
  }

  export interface ISVGStroke {
    stroke: string;
    strokeWidth?: number;
    strokeDasharray?: string;
    strokeLinejoin?: StrokeLineJoin;
    strokeLinecap?: StrokeLineCap;
    position?: StrokePosition;
    strokeDashoffset?: number;
  }

  class SVGStyleParser {
    protected data?: IProperties;

    getStroke(): ISVGStroke {
      if (this.data && this.data.stroke && !this.data.stroke.disabled) {
        return parseSVGStroke(this.data.stroke);
      }
      return {
        stroke: 'none',
        strokeWidth: 0,
      };
    }

    getShadow(): string {
      return parserDropShadow(this.data?.shadow);
    }

    getFill(id: string): string {
      if (this.data && this.data.fill) {
        const { type, color, disabled } = this.data.fill;
        if (!disabled) {
          if (type === FillType.solid) {
            return GraphicsUtils.parseColorToString(color as Color);
          } else {
            return `url(#${id})`;
          }
        }
      }
      return 'none';
    }

    getStrokeEx(size: { width: number; height: number }): ISVGStroke & { strokePathData: string } {
      let strokePathData = '';
      if (this.data) {
        const { stroke, radius, border } = this.data;
        const radiusInfo = calculateRadius(size, radius);
        strokePathData = createRectStrokePath({
          size,
          radius: radiusInfo,
          stroke: stroke?.disabled ? undefined : stroke,
          borderModel: border?.disabled ? undefined : border,
        });
      }
      return {
        position: StrokePosition.inner,
        ...this.getStroke(),
        strokePathData,
      };
    }
  }

  class SVGStyleParserEx extends SVGStyleParser {
    init(data: IProperties) {
      this.data = data;
    }
  }

  let svgParser: SVGStyleParserEx;
  let cssParser: CSSStyleParserEx;

  export const initSVGStyleParser = (properties: IProperties): SVGStyleParser => {
    if (!svgParser) {
      svgParser = new SVGStyleParserEx();
    }
    svgParser.init(properties);
    return svgParser;
  };

  export const initCSSStyleParser = (properties: IProperties): CSSStyleParser => {
    if (!cssParser) {
      cssParser = new CSSStyleParserEx();
    }
    cssParser.init(properties);
    return cssParser;
  };

  export const createCSSStyleParser = (properties: IProperties): CSSStyleParser => {
    const result = new CSSStyleParserEx();
    result.init(properties);
    return result;
  };

  export const getOpacity = (opacity?: number) => {
    return _.isUndefined(opacity) ? 1 : opacity / 100;
  };

  export const getRotate = (rotate?: number): string | undefined => {
    if (!rotate) {
      return undefined;
    }
    return `rotate(${rotate}deg)`;
  };

  interface IRadiusValue {
    topLeft: number;
    topRight: number;
    bottomLeft: number;
    bottomRight: number;
  }

  export const calculateRadius = (size: ISize, radius?: IRadius): IRadiusValue => {
    const result: IRadiusValue = { topLeft: 0, topRight: 0, bottomLeft: 0, bottomRight: 0 };
    if (radius && !radius.disabled) {
      let { isPercent, topRight, topLeft, bottomLeft, bottomRight } = radius;
      const maxRadius = MathUtils.min(size.width, size.height) / 2;
      if (isPercent) {
        if (topRight) {
          topRight = MathUtils.round(MathUtils.min((topRight * maxRadius) / 100, maxRadius));
        }
        if (topLeft) {
          topLeft = MathUtils.round(MathUtils.min((topLeft * maxRadius) / 100, maxRadius));
        }
        if (bottomLeft) {
          bottomLeft = MathUtils.round(MathUtils.min((bottomLeft * maxRadius) / 100, maxRadius));
        }
        if (bottomRight) {
          bottomRight = MathUtils.round(MathUtils.min((bottomRight * maxRadius) / 100, maxRadius));
        }
      } else {
        if (topRight) {
          topRight = MathUtils.min(topRight, MathUtils.round(maxRadius));
        }
        if (topLeft) {
          topLeft = MathUtils.min(topLeft, MathUtils.round(maxRadius));
        }
        if (bottomLeft) {
          bottomLeft = MathUtils.min(bottomLeft, MathUtils.round(maxRadius));
        }
        if (bottomRight) {
          bottomRight = MathUtils.min(bottomRight, MathUtils.round(maxRadius));
        }
      }
      result.topRight = topRight || result.topRight;
      result.topLeft = topLeft || result.topLeft;
      result.bottomLeft = bottomLeft || result.bottomLeft;
      result.bottomRight = bottomRight || result.bottomRight;
    }
    return result;
  };

  export const AllowPasteComp = (comp: UIComponent[]): boolean => {
    // 按钮与热区特殊处理
    const resultArray = comp.map((item: UIComponent) => {
      if (item.type === CCanvasPanel && item.lib?.type === CButton) {
        return false;
      }
      if (item.type === CPath && item.lib?.type === CHotArea) {
        return true;
      }
      if ([CText, CPath, CLine, CRect, CEllipse, CPolygon].includes(item.type)) {
        return false;
      }
      return true;
    });
    return resultArray.includes(true);
  };

  // 根据组件类型判断富文本属值
  export const getRichTextKeyByCompType = (type: string): string => {
    if (CText === type) {
      return 'value';
    }
    if ([CPath, CLine, CRect, CEllipse, CPolygon].includes(type)) {
      return 'text';
    }
    return 'none';
  };
}
