import * as React from 'react';
import classnames from 'classnames';
import { isUndefined, isNumber, round } from 'lodash';

import { to12Precision } from '@utils/globalUtils';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';
import { stopBubbleWhenSortCut } from '@helpers/shortCutHelper';
import { ComponentTheme } from '../common';

import './index.scss';
export interface IInputNumberProp {
  value: number | undefined;
  step?: number;
  placeHolder?: string;
  max?: number;
  min?: number;
  width?: number | string;
  readOnly?: boolean;
  disabled?: boolean;
  // 是否在创建后立即自动获取到焦点
  autoFocus?: boolean;
  // 是否在得到焦点时自动选中所有内容
  autoSelectWhenFocus?: boolean;
  allowAutoFixMinValue?: boolean;
  allowEmptyValue?: boolean; // 允许空值
  them?: 'normal' | 'no-border';
  colorTheme?: ComponentTheme;
  className?: string;
  style?: React.CSSProperties;
  // 小数点长度
  decimalCount?: number;
  prefix?: string;
  suffix?: string;

  autoFixValue?: boolean;

  onChange?: (value: number) => void;
  onBlur?: (value: number, inputValue?: string) => void;
  onEnter?: (value: number) => void;
  onFocus?: (e: React.FocusEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  onTyped?: () => void;
  onEmptyValue?: (value: string) => void;
  onMouseDown?: React.MouseEventHandler;
}

export interface IInputNumberState {
  inputValue: string;
  isFocus: boolean;
  currentInputValue: string;
  maxLength?: number;
}

const numPattern = /^-?\d*\.?\d+$/;

class InputNumber extends React.PureComponent<IInputNumberProp, IInputNumberState> {
  public dom: React.RefObject<HTMLInputElement> = React.createRef();
  private isEscKey: boolean = false;
  // private isFocus: boolean = false;

  static defaultProps: Partial<IInputNumberProp> = {
    readOnly: false,
    disabled: false,
    decimalCount: 0,
    them: 'normal',
    step: 1,
    autoFocus: false,
    autoSelectWhenFocus: false,
    allowAutoFixMinValue: false,
  };

  private submitWheBlur: boolean = false;

  constructor(props: IInputNumberProp) {
    super(props);
    const value = props.value;
    const formatValue = isUndefined(value) ? '' : `${round(value, props.decimalCount || 0)}`;
    this.state = {
      inputValue: formatValue,
      currentInputValue: formatValue,
      isFocus: false,
      maxLength: this.calcMaxLen(props.max),
    };
  }

  getBoundingClientRect() {
    return this.dom.current!.getBoundingClientRect();
  }

  private calcMaxLen(max?: number) {
    if (isUndefined(max)) {
      return undefined;
    }
    return `${max}`.length;
  }

  UNSAFE_componentWillReceiveProps(newProps: IInputNumberProp) {
    let newInputValue = '';

    if (!isUndefined(newProps.value)) {
      newInputValue = `${round(newProps.value, newProps.decimalCount || 0)}`;
    }
    if (newProps.value !== this.props.value) {
      this.setState({
        inputValue: newInputValue,
        currentInputValue: newInputValue,
      });
    } else {
      // 失焦后的处理
      if (!this.state.isFocus || this.submitWheBlur) {
        this.setState({ inputValue: newInputValue, currentInputValue: newInputValue });
      }
    }
    if (newProps.max !== this.props.max) {
      this.setState({ maxLength: this.calcMaxLen(newProps.max) });
    }
    this.submitWheBlur = false;
  }

  reformZero = (numStr: string) => {
    return parseFloat(numStr) === 0 ? '0' : numStr;
  };

  isNumberKey = (keyCode: number) => {
    // 数字
    if (keyCode >= 48 && keyCode <= 57) return true;
    // 小数字键盘
    if (keyCode >= 96 && keyCode <= 105) return true;
    // Backspace, del, 左右方向键等
    const keyCodes = new Set([8, 9, 12, 13, 37, 38, 39, 40, 46, 108, 109, 110, 189, 190]);
    return keyCodes.has(keyCode);
  };

  keepDecimal(value: number, decimalCount: number) {
    if (value > 0) {
      return to12Precision(Math.floor(to12Precision(value * Math.pow(10, decimalCount))) / Math.pow(10, decimalCount));
    } else {
      return to12Precision(Math.ceil(to12Precision(value * Math.pow(10, decimalCount))) / Math.pow(10, decimalCount));
    }
  }

  componentDidMount() {
    this.resetFocus();
  }

  protected resetFocus() {
    const { autoFocus, autoSelectWhenFocus } = this.props;
    if (autoFocus) {
      setTimeout(() => {
        this.dom.current?.focus();
        if (autoSelectWhenFocus) {
          this.dom.current?.select();
        }
      }, 30);
    }
  }

  componentWillUnmount() {
    if (this.state.isFocus) {
      const value = this.dom.current!.value;
      const { value: propValue, onChange, onBlur, allowEmptyValue, onEmptyValue } = this.props;

      if (numPattern.test(value)) {
        const newValue = this.validateNumber(parseFloat(value));
        if (onChange && newValue !== undefined && propValue !== newValue) {
          onChange(newValue);
        }
        if (onBlur && newValue !== undefined) {
          onBlur(newValue);
        }
      } else {
        if (allowEmptyValue && onEmptyValue && value.trim() === '') {
          onEmptyValue('');
        }
      }
    }
  }

  /**
   * 处理数据符合规则
   * @private
   * @memberof InputNumber
   */
  private validateNumber = (value: number): number => {
    const { max, min, decimalCount } = this.props;
    let newData = value;
    if (isNumber(newData)) {
      newData = this.keepDecimal(newData, decimalCount || 0);
      if (isNumber(max)) {
        newData = Math.min(max, newData);
      }
      if (isNumber(min)) {
        newData = Math.max(min, newData);
      }
    }
    return newData;
  };

  private doApplyValueToState = (value: string) => {
    const { decimalCount, max, min, autoFixValue } = this.props;
    if (numPattern.test(value)) {
      let num = parseFloat(value);
      if (autoFixValue) {
        num = Math.min(max ?? 100, Math.max(num, min ?? 0));
      }
      if ((isUndefined(max) || num <= max) && (isUndefined(min) || num >= min)) {
        const v = this.validateNumber(num);
        const newValue = `${this.keepDecimal(v, decimalCount || 0)}`;
        this.setState(
          {
            inputValue: newValue,
          },
          () => {
            this.doNeedChange();
          },
        );
      } else {
        this.setState({ inputValue: value });
      }
    } else {
      this.setState({ inputValue: value });
    }
  };

  optimizeToFixed = (value: string) => {
    return value === '-0' ? '0' : value;
  };

  private handleInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    this.setState({
      currentInputValue: value,
    });
    this.doApplyValueToState(value);
  };

  private doNeedChange = (data?: number) => {
    const { onChange, value } = this.props;
    const newNumber = isNumber(data) ? data : parseFloat(this.state.inputValue);
    if (onChange && newNumber !== undefined && value !== newNumber) {
      onChange(newNumber);
    }
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode !== KeyCodeMap.VK_TAB) {
      e.stopPropagation();
    }
    // @ts-ignore
    stopBubbleWhenSortCut(e);
    const { inputValue } = this.state;
    const { value, decimalCount, min, allowAutoFixMinValue } = this.props;

    const keyCode = e.keyCode;
    if (!this.isNumberKey(keyCode) && !e.ctrlKey && !e.metaKey) {
      e.preventDefault();
    }
    if (decimalCount === 0 && (keyCode === KeyCodeMap.VK_DOT || keyCode === KeyCodeMap.VK_NUM_DOT)) {
      e.preventDefault();
    }
    const { onKeyDown, onEnter } = this.props;
    this.isEscKey = false;
    const isEnterPressed = keyCode === KeyCodeMap.VK_ENTER;
    const isEscPressed = keyCode === KeyCodeMap.VK_ESCAPE;
    const isStringNumber = numPattern.test(inputValue);

    if (allowAutoFixMinValue && inputValue === '' && onEnter) {
      this.setState(
        {
          inputValue: `${isNumber(min) && min > 0 ? min : 0}`,
        },
        () => {
          onEnter(parseInt(this.state.inputValue));
        },
      );
      return;
    }

    if (isEnterPressed) {
      //输入值是正确的number
      if (isStringNumber) {
        const newValue = this.validateNumber(parseFloat(inputValue));
        onEnter && onEnter(newValue);
      }
      // 如果输入值不是正确的number,value是组件本身的宽度，那么设置为正确的宽度
      else if (typeof value === 'number') {
        onEnter && onEnter(value);
      }
    } else if (isEscPressed) {
      this.isEscKey = true;
    }
    onKeyDown && onKeyDown(e);
    if (isEnterPressed || isEscPressed) {
      if (this.dom.current) {
        this.blur();
        return;
      }
    }
  };

  handleKeyUp = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e);
    }
  };

  private handleInput = () => {
    const { onTyped } = this.props;
    onTyped && onTyped();
  };

  private handleMouseDown = (e: React.MouseEvent) => {
    const { onMouseDown } = this.props;
    onMouseDown && onMouseDown(e);
  };

  handleFocus = (e: React.FocusEvent) => {
    const { onFocus, autoSelectWhenFocus } = this.props;
    const evt: React.FocusEvent = { ...e };
    onFocus && onFocus(evt);
    this.setState({ isFocus: true }, () => {
      if (autoSelectWhenFocus) {
        this.select();
      }
    });
  };

  handleBlur = (e: React.FocusEvent) => {
    const { value } = e.target as HTMLInputElement;
    const {
      value: propValue,
      decimalCount,
      min,
      allowAutoFixMinValue,
      onBlur,
      allowEmptyValue,
      onEmptyValue,
    } = this.props;
    this.setState({ isFocus: false });
    if (allowEmptyValue && onEmptyValue && value.trim() === '') {
      onEmptyValue('');
      // 当前输入比如为'--number时'，当前input value获取的值为''，且通过currentInputValue无法成功修改input value，
      // 手动通过target.value设置
      (e.target as HTMLInputElement).value = '';
      return;
    }
    if (!this.isEscKey) {
      if (allowAutoFixMinValue && this.state.inputValue === '') {
        const formatValue = `${isNumber(min) && min > 0 ? min : 0}`;
        this.setState(
          {
            inputValue: formatValue,
            currentInputValue: formatValue,
          },
          () => {
            onBlur && onBlur(parseInt(this.state.inputValue), value);
          },
        );
        return;
      }

      if (numPattern.test(value)) {
        const newValue = this.validateNumber(parseFloat(value));
        const formatValue = this.reformZero(newValue.toString(10));
        this.setState({
          inputValue: formatValue,
          currentInputValue: formatValue,
        });
        this.submitWheBlur = true;
        this.doNeedChange(newValue);

        onBlur && onBlur(newValue, value);
      } else if (typeof propValue === 'number') {
        const formatValue = `${this.keepDecimal(propValue, decimalCount || 0)}`;
        this.setState({
          inputValue: formatValue,
          currentInputValue: formatValue,
        });
        onBlur && onBlur(propValue, value);
      }
    } else if (typeof propValue === 'number') {
      const formatValue = `${this.keepDecimal(propValue, decimalCount || 0)}`;
      this.setState({
        inputValue: formatValue,
        currentInputValue: formatValue,
      });
      onBlur && onBlur(propValue, value);
    }
  };

  focus = () => {
    this.dom.current!.focus();
  };

  blur = () => {
    this.dom.current!.blur();
  };

  select = () => {
    document.getSelection()?.removeAllRanges(); // 解决二次失焦未全选的问题
    this.dom.current!.select();
  };

  render() {
    const { isFocus, maxLength, currentInputValue } = this.state;
    const {
      width,
      disabled,
      readOnly,
      className,
      style,
      placeHolder,
      step,
      them,
      min,
      max,
      prefix,
      suffix,
      colorTheme,
    } = this.props;
    return (
      <div
        className={classnames('dsm-c-rp-number-input', className, colorTheme, {
          readOnly: readOnly,
          disabled: disabled,
          focus: isFocus,
        })}
        style={{ ...(style || {}), width: width }}
        onClick={this.focus}
      >
        {prefix && <span className="input-prefix">{prefix}</span>}
        <input
          className={classnames({ 'no-border': them === 'no-border' })}
          title=""
          ref={this.dom}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeHolder || ''}
          type="number"
          min={min}
          max={max}
          maxLength={maxLength}
          step={step}
          value={currentInputValue}
          onChange={this.handleInputChanged}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          onInput={this.handleInput}
          onMouseDown={this.handleMouseDown}
        />
        {suffix && <span className="input-suffix">{suffix}</span>}
      </div>
    );
  }
}

export default InputNumber;
