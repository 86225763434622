import { IAppWithNestedChildren } from '@fbs/rp/models/app';
import * as app from '../app';
// import { getOfflineDemoData } from './data';
import { getApp } from './helper';

async function getAppByShareID(): Promise<IAppWithNestedChildren & { appID: string }> {
  return getApp();
  // return new Promise((resolve, reject) => {
  //   getAppByID()
  //     .then((app) => {
  //       console.log(app);
  //       resolve(app);
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //       // reject(e)
  //     });
  //   // debugger;
  //   // const appData = getOfflineDemoData()?.app;
  //   // if (appData?.shareID === shareID) {
  //   //   resolve(appData as any);
  //   // } else {
  //   //   reject();
  //   // }
  // });
}

export default Object.assign({}, app, { getAppByShareID });
