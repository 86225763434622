import { depthClone } from '@utils/globalUtils';
import { isMockRPD } from '@utils/envUtils';
import { revertActionType } from '@helpers/revertHelper';

import { parseNodeToTreeByState } from '@fbs/rp/utils/app';
import { PageSkipEffectType, IAnimation } from '@fbs/rp/models/interactions';
import { TheaterManager, CoreTheaterManager } from '@/managers/pageArtboardManager';

import Theater from '../../theater';
import { IPreviewState, PreviewAction, artboardList } from './actions';
import { ActionType } from '../types';

export interface ISkipHistoryItem {
  currentTheater: Theater;
  nextTheater: Theater;
  actionEffect: IAnimationEffect;
}

export interface IAnimationEffect {
  params: PageSkipEffectType;
  animation: IAnimation;
}

const initialState: IPreviewState = {
  appID: '',
  app: null,
  landmark: 0,
  allNodes: [],
  message: '',
  showMsg: false,
  msg: '',
  msgId: 0,
  isProjectExist: true,
  //query捕获到的linkId,
  queryLinkId: '',
  linkID: '',
  waterMark: '',
  popularizes: [],
};

export default function (state: IPreviewState = initialState, action: PreviewAction): IPreviewState {
  const pageSize = state.app?.size || { width: 0, height: 0 };
  switch (action.type) {
    case ActionType.Preview_GetApp: {
      const { app, nodes = [] } = action.payload;
      const { realNodes, pageFlatNodes } = parseNodeToTreeByState(
        app.children.filter((e) => !nodes.length || nodes.includes(e._id)),
      );
      Object.keys(pageFlatNodes).forEach((k) => {
        if (!pageFlatNodes[k].hidden) {
          TheaterManager.setArtboards(k, []);
        }
      });
      const states = {
        ...state,
        app: {
          ...app,
          appID: app.appID,
          children: realNodes,
          flatChildren: pageFlatNodes,
          tempChildren: [],
        },
        appID: app.appID,
        allNodes: app.children,
      };
      TheaterManager.setAppStates({ appID: states.appID, allNodes: states.allNodes, size: states.app.size });
      return states;
    }
    case ActionType.Preview_LoadArtBoard: {
      const { pageID, artboards } = action.payload;
      state.currentTheater?.termination();
      // 加载兼容 - 将请求的页面画板数据缓存到画板管理器中
      const theater = TheaterManager.build<CoreTheaterManager>({ _id: pageID }, artboards, {
        appID: state.appID,
        allNodes: state.allNodes,
        size: pageSize,
      });
      theater.doLoaded = true;
      delete theater.activeFragmentAction;

      return {
        ...state,
        currentTheater: theater,
        // skipHistory: [],
        // landmark: undefined,
      };
    }
    case ActionType.Preview_AfterSkipToPage: {
      const skipHistory = state.skipHistory;
      let landmark = state.landmark || 0;

      const nextTheater = state.nextTheater;
      const currentTheater = state.currentTheater;
      if (nextTheater) {
        nextTheater.resetWorkerManager();
        nextTheater.animationEffect = undefined;
        nextTheater.hasBack = !(!skipHistory || skipHistory.length <= landmark);
        nextTheater.hasNext = landmark > 0;
      }
      if (currentTheater) {
        currentTheater.resetWorkerManager();
        currentTheater.animationEffect = undefined;
        currentTheater.hasBack = !(!skipHistory || skipHistory.length <= landmark);
        currentTheater.hasNext = landmark > 0;
        currentTheater.termination();
      }

      const theater = nextTheater || currentTheater;
      theater!.doLoaded = true;

      return {
        ...state,
        currentTheater: theater,
        nextTheater: undefined,
        selectedPageID: nextTheater ? nextTheater.doc.pageID : state.selectedPageID,
      };
    }
    case ActionType.Preview_SelectNode: {
      const { nodeID, artBoards } = action.payload;
      const prevTheater = state.currentTheater;
      prevTheater?.termination();
      //重置放到页面切换后，微任务延迟执行
      Promise.resolve().then(() => prevTheater?.reset());
      //获取下一个页面
      const currentTheater = TheaterManager.build<CoreTheaterManager>({ _id: nodeID }, artBoards, {
        appID: state.appID,
        allNodes: state.allNodes,
        size: pageSize,
      });
      if (currentTheater) {
        currentTheater.resetWorkerManager();
        currentTheater.doLoaded = true;
      }

      return {
        ...state,
        currentTheater: currentTheater,
        nextTheater: undefined,
        selectedPageID: action.payload.nodeID,
      };
    }

    case ActionType.Preview_ReloadPage: {
      if (!state.currentTheater) {
        return state;
      }
      const artboards = [...state.currentTheater.doc.artboards, ...action.payload];
      const currentTheater = new Theater(
        state.appID,
        artboards,
        state.currentTheater?.pageID,
        state.allNodes,
        pageSize,
      );
      return {
        ...state,
        currentTheater,
      };
    }

    case ActionType.Preview_Exit: {
      return initialState;
    }
    // nextTheater&currentTheater同时存在
    case ActionType.Preview_beforeSkipToPage: {
      const {
        nextPageID,
        nextArtBoards,
        currentPageID,
        currentArtBoards,
        actionEffect,
      } = action.payload.pageActionParam;

      // 判断是否更新Theater
      const currentTheater = new Theater(state.appID, currentArtBoards, currentPageID, state.allNodes, pageSize);
      const nextTheater = new Theater(state.appID, nextArtBoards, nextPageID, state.allNodes, pageSize);
      nextTheater.termination();
      currentTheater.animationEffect = {
        animation: actionEffect.animation,
        params: actionEffect.params.effect,
      };
      nextTheater.animationEffect = {
        animation: actionEffect.animation,
        params: actionEffect.params.effect,
      };
      const newSkipHistory: ISkipHistoryItem[] = state.skipHistory || [];

      // 更新skipHistory
      let landmark = state.landmark || 0;
      if (landmark > 0) {
        newSkipHistory.splice(0, landmark);
      }
      const newSkip = {
        currentTheater: currentTheater,
        nextTheater: nextTheater,
        actionEffect: nextTheater.animationEffect,
      };
      // 阻止重复载入
      if (!(newSkipHistory.length > 0 && newSkip.nextTheater.doc.pageID == newSkipHistory[0].nextTheater.doc.pageID)) {
        // break;
        newSkipHistory.unshift(newSkip);
        landmark = 0;
      }
      delete currentTheater.activeFragmentAction;
      state.currentTheater?.termination();
      return {
        ...state,
        nextTheater: nextTheater,
        currentTheater: currentTheater,
        skipHistory: newSkipHistory,
        landmark,
      };
    }
    case ActionType.Preview_goBackward: {
      const { actionEffect: effect } = action.payload;
      const skipHistory = state.skipHistory;
      let landmark = state.landmark || 0;
      if (!skipHistory || landmark >= skipHistory.length || landmark < 0) {
        break;
      }
      const { currentTheater: current, nextTheater: next, actionEffect } = skipHistory[landmark];
      const currentTheater = new Theater(
        state.appID,
        artboardList[current.doc.pageID],
        current.doc.pageID,
        state.allNodes,
        pageSize,
      );
      const nextTheater = new Theater(
        state.appID,
        artboardList[next.doc.pageID],
        next.doc.pageID,
        state.allNodes,
        pageSize,
      );
      const newActionEffect = {
        animation: actionEffect.animation,
        params: revertActionType(actionEffect.params),
      };
      if (effect) {
        newActionEffect.animation = effect.animation;
        newActionEffect.params = effect.params.effect;
      }
      currentTheater.animationEffect = newActionEffect;
      nextTheater.animationEffect = newActionEffect;
      delete currentTheater.activeFragmentAction;
      state.currentTheater?.termination();
      return {
        ...state,
        landmark: landmark + 1,
        currentTheater: nextTheater,
        nextTheater: currentTheater,
      };
    }

    case ActionType.Preview_goForward: {
      const skipHistory = state.skipHistory;
      let landmark = state.landmark || 0;
      if (!skipHistory || landmark > skipHistory.length || landmark < 1) {
        break;
      }
      const { currentTheater: current, nextTheater: next, actionEffect } = skipHistory[landmark - 1];
      const currentTheater = new Theater(
        state.appID,
        artboardList[current.doc.pageID],
        current.doc.pageID,
        state.allNodes,
        pageSize,
      );
      const nextTheater = new Theater(
        state.appID,
        artboardList[next.doc.pageID],
        next.doc.pageID,
        state.allNodes,
        pageSize,
      );
      currentTheater.animationEffect = {
        animation: actionEffect.animation,
        params: actionEffect.params,
      };
      nextTheater.animationEffect = {
        animation: actionEffect.animation,
        params: actionEffect.params,
      };
      delete currentTheater.activeFragmentAction;
      state.currentTheater?.termination();
      return {
        ...state,
        landmark: landmark - 1,
        currentTheater,
        nextTheater,
      };
    }

    case ActionType.Preview_returnHome: {
      const { homeID, homeArtBoards } = action.payload;
      const currentTheater = new Theater(state.appID, homeArtBoards, homeID, state.allNodes, pageSize);
      const selectedPageID = homeID;
      currentTheater.hasBack = false;
      currentTheater.hasNext = false;
      currentTheater.doLoaded = true;
      delete currentTheater.activeFragmentAction;
      state.currentTheater?.termination();
      return {
        ...state,
        currentTheater,
        selectedPageID,
        landmark: 0,
        skipHistory: [],
      };
    }

    case ActionType.Preview_showFragment: {
      const { action: fragmentAction } = action.payload;
      const newCurrentTheater: Theater | undefined = state.currentTheater;
      if (newCurrentTheater) {
        // 如果辅画板当前处于叠加，现在做退出操作，则退出的动画效果与当前画板出现的效果一致！
        if (
          fragmentAction.isExit &&
          newCurrentTheater.activeFragmentAction &&
          newCurrentTheater.activeFragmentAction?.target === fragmentAction.target
        ) {
          const newAction = depthClone(fragmentAction);
          // 统一效果
          newAction.params = newCurrentTheater.activeFragmentAction.params;
          newCurrentTheater.activeFragmentAction = newAction;
        } else {
          newCurrentTheater.activeFragmentAction = fragmentAction;
        }
      }
      return {
        ...state,
        currentTheater: newCurrentTheater,
      };
    }

    case ActionType.Preview_showMessage: {
      const { message } = action.payload;
      return {
        ...state,
        showMsg: true,
        message,
      };
    }

    case ActionType.Preview_hideMessage: {
      return {
        ...state,
        showMsg: false,
        message: '',
      };
    }

    case ActionType.Preview_showMessageWithId: {
      const { message, id } = action.payload;
      return {
        ...state,
        msgId: id,
        msg: message,
      };
    }

    case ActionType.Preview_hideMessageWithId: {
      return {
        ...state,
        msg: '',
        msgId: action.payload,
      };
    }

    case ActionType.Preview_toLogIn: {
      let url = `${window.apis.loginDomain}/signin?next=${encodeURIComponent(location.href)}`;
      // FIXME: 飞书处理？
      // if(larkPC){
      // const code = encodeURIComponent(`pages/common/index?otherUrl=${url}`);
      // const larkAppId = i18n('lark.larkAppID');
      // url = `${larkAppLink}/client/mini_program/open?appId=${larkAppId}&mode=window&path=${code}`;
      // }
      if (isMockRPD) {
        window.open(url);
      } else {
        location.href = url;
      }
      break;
    }

    case ActionType.Preview_PwdEnabled:
      if (state.app) {
        return {
          ...state,
          app: {
            ...state.app,
            previewNeedPassword: action.payload,
          },
        };
      }
      break;
    case ActionType.Preview_ProjectExist:
      return {
        ...state,
        isProjectExist: action.payload,
      };
    case ActionType.Preview_setCurrLinkID:
      return {
        ...state,
        linkID: action.payload.linkID,
      };
    case ActionType.Preview_ShowWater:
      return {
        ...state,
        waterMark: action.payload,
      };
    case ActionType.Preview_SetIDocAppShareMsg:
      return {
        ...state,
        iDocAppShareMsg: action.payload,
      };
    case ActionType.Popularize_Loaded: {
      return {
        ...state,
        popularizes: action.payload,
      };
    }
    case ActionType.Preview_SetQueryLinkId: {
      return {
        ...state,
        queryLinkId: action.payload.linkID,
      };
    }
  }
  return state;
}
