import * as React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';

import { IComponentData } from '@fbs/rp/models/component';

import { getNewID } from '@helpers/idHelper';
import { UIComponent } from '@editor/comps';

import { makeCanvas } from '../Canvas';
import { makeCommonComponent } from '../../helper';
import { CWrapPanel as CGridPanel } from '../../constants';

interface IGridPanelProps {
  comp: UIComponent;
}

export function makeGridPanel(id: string): IComponentData {
  const components: IComponentData[] = [];
  while (components.length < 5) {
    components.push(
      makeCanvas(getNewID(), {
        size: {
          width: 100,
          height: 100,
        },
        properties: {
          container: {
            scroll: false,
            showScroll: false,
            hidden: true,
          },
          layout: {
            direction: 'horizontal',
          },
        },
      }),
    );
  }

  return makeCommonComponent(id, CGridPanel, {
    size: {
      width: 300,
      height: 100,
    },
    properties: {
      layout: {
        direction: 'horizontal',
        verticalAlign: 'top',
        horizontalAlign: 'left',
      },
    },
    components,
  });
}

class GridPanel extends React.Component<IGridPanelProps> {
  render() {
    const { properties, opacity, size } = this.props.comp;
    const { layout } = properties;

    const { direction, verticalAlign, horizontalAlign } = layout || {
      direction: 'vertical',
      verticalAlign: 'top',
      horizontalAlign: 'center',
    };
    const alpha = isUndefined(opacity) ? 1 : opacity / 100;

    let style: React.CSSProperties = {
      opacity: alpha,
      ...size,
    };

    return (
      <div
        className={classnames(`lib-comp-grid-panel ${verticalAlign} ${horizontalAlign}`, {
          vertical: direction === 'vertical',
          horizontal: direction === 'horizontal',
        })}
        style={style}
      >
        {this.props.children}
      </div>
    );
  }
}

export default GridPanel;
