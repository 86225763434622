import { IVisibleCommandParams } from '@fbs/rp/models/interactions';
import { ArtboardOperations, Ops } from '@fbs/rp/utils/patch';
import { EventTypes } from '@fbs/rp/models/event';

import CommandBase from './CommandBase';

export default class VisibleCommand extends CommandBase {
  private _visible = this.command.target.hidden;

  get afterEvent() {
    return this._visible ? EventTypes.afterVisible : EventTypes.afterHide;
  }

  cashOriginParams() {
    const { hidden, parent } = this.command.target;
    this.saveCacheOriginParams('visible', !hidden);
    this.saveCacheOriginParams('index', parent?.components.indexOf(this.command.target));
  }

  private initOperation(visible: boolean) {
    const { params, target } = this.command;
    const { forceFront } = params as IVisibleCommandParams;
    const { id, ownerArtboardID, parent } = target;
    const opt: ArtboardOperations = {
      [id]: [Ops.replace(this.getCurrentPath('hidden'), !visible), this.initAnimationOperation()],
    };
    if (forceFront) {
      const index = parent!.components.indexOf(target);
      const len = parent!.components.length;
      const parentID = parent!.id;
      if (visible) {
        opt[parentID] = [Ops.move(`./components/${len - 1}`, `/components/${index}`)];
        opt[id].push(Ops.replace('/_bringFront', true));
      } else {
        opt[parentID] = [Ops.move(`/components/${this.getCacheOriginParams('index')}`, `/components/${index}`)];
        opt[id].push(Ops.replace('/_bringFront', false));
      }
    }
    this._visible = visible;
    return {
      [ownerArtboardID]: opt,
    };
  }

  private getTargetVisible() {
    const { target, params } = this.command;
    const { state } = params as IVisibleCommandParams;
    if (state === 'hidden') {
      return false;
    } else if (state === 'show') {
      return true;
    }
    return !!target.hidden;
  }

  run() {
    this.patch(this.initOperation(this.getTargetVisible()));
    this.fulfilled = true;
  }

  revert() {
    this.patch(this.initOperation(this.getCacheOriginParams('visible')));
    this.fulfilled = true;
  }
}
