import { requestImageBlob } from '../image/fetchImage';
import { inliner } from './inliner';
import { mimeType } from '../util';
import { dataAsUrl, isDataUrl } from '../util/imageUtil';
import { blobToBase64 } from '../util/blobUtil';
import { getAndEncode } from '../util/httpUtil';

export const images = newImages();
export function inlineImages(node: Element): Promise<Element> {
  console.time('inlineImages');
  return images.inlineAll(node).then(function () {
    console.timeEnd('inlineImages');
    return node;
  });
}

export const inlineImage = (url: string): Promise<HTMLImageElement> => {
  // function inline(get?: any): Promise<any> {
  if (isDataUrl(url)) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.onerror = reject;
      img.src = url;
    });
  }
  return (
    Promise.resolve(url)
      // .then( getAndEncode)
      // .then((data) => {
      //   return dataAsUrl(data, mimeType(url));
      // })
      .then(requestImageBlob)
      .then(blobToBase64)
      .then((dataUrl) => {
        // console.log('images size:' + (dataUrl.length / 1024).toFixed(2) + 'kb');
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => {
            resolve(img);
          };
          img.onerror = reject;
          img.src = dataUrl;
        });
      })
  );
  // }
};

export function newImages() {
  return {
    inlineAll: inlineAll,
    impl: {
      newImage: newImage,
    },
  };
  function newImage(element: HTMLImageElement) {
    return {
      inline: inline,
    };

    function inline(get?: any): Promise<any> {
      if (isDataUrl(element.src)) return Promise.resolve();

      return Promise.resolve(element.src)
        .then(get || getAndEncode)
        .then((data) => {
          return dataAsUrl(data, mimeType(element.src));
        })
        .then((dataUrl) => {
          console.log('images size:' + (dataUrl.length / 1024).toFixed(2) + 'kb');
          return new Promise((resolve, reject) => {
            element.onload = resolve;
            element.onerror = reject;
            element.src = dataUrl;
          });
        });
    }
  }

  function inlineAll(node: Node): Promise<any> {
    if (!(node instanceof HTMLElement)) {
      // console.log(node);
      return Promise.resolve(node);
    }

    return inlineBackground(node as HTMLElement).then(() => {
      if (node instanceof HTMLImageElement) {
        return newImage(node).inline();
      }
      return Promise.all(
        Array.from(node.childNodes).map((child) => {
          return inlineAll(child);
        }),
      );
    });

    function inlineBackground(node: HTMLElement): Promise<HTMLElement> {
      let background = node.style.getPropertyValue('background');
      if (!background) return Promise.resolve(node);

      return inliner
        .inlineAll(background)
        .then((inlined) => {
          //  node.style.getPropertyPriority('background')
          node.style.setProperty('background', inlined);
        })
        .then(() => {
          return node;
        });
    }
  }
}
