// 激活画板相关
import { SessionInfo } from '@fbs/rp/models/io';

import { CContentPanel, CContentPanelV2 } from '@libs/constants';

import { UIArtboard, UIFragment, UIContainerComponent } from '../comps';
import Doc from '../document';
import CoreBase from './base';

function canActiveContainer(type: string): boolean {
  return ![CContentPanel, CContentPanelV2].includes(type);
}

/**
 * 页面中容器及画板激活状态管理
 */
class CoreActive extends CoreBase {
  public activeArtboard: UIFragment;

  protected deletingArtboard?: UIFragment;

  constructor(doc: Doc, appID: string, nodeID: string, session: SessionInfo) {
    super(doc, appID, nodeID, session);
    this.activeArtboard = doc.mainArtboard;
  }

  restoreSelectedCompState() {}
  /**
   * 设置激活的画板
   * @param {UIFragment} artboard
   * FIXME: 这里激活后为了更新页面，让被激活的画板显示效果不一样，进行了通知，其实可以不必通知，性能优化的时候再处理
   */
  setActiveArtboard(artboard: UIFragment) {
    if (this.activeArtboard === artboard) {
      return;
    }
    if (this.activeArtboard !== this.deletingArtboard) {
      this.restoreSelectedCompState();
    } else {
      this.deletingArtboard = undefined;
    }
    // 选中active，之前active画板也需要更新
    const updateArtboardIds = [this.activeArtboard.artboardID];
    this.activeArtboard = artboard;
    this.activeContainer = artboard;
    updateArtboardIds.push(artboard.artboardID);
    this.selectedComponents.clear();
    this.pushComponentSelectedClean();
    this.notifyUpdateListener(updateArtboardIds);
  }

  /**
   * 判断某个画板是否为激活状态
   * @param {UIArtboard} artboard
   * @returns {boolean}
   */
  isActiveArtboard(artboard: UIArtboard): boolean {
    return this.activeArtboard === artboard;
  }

  /**
   * 设置激活的容器
   * @param {UIContainerComponent} container
   */
  setActiveContainer(container: UIContainerComponent) {
    if (!container || this.activeContainer === container || !canActiveContainer(container.type)) {
      return;
    }
    // 锁定的组不能激活，主要发生在向下激活时
    if (container.locked) {
      return;
    }

    this.activeContainer.isActived = false;
    // 解决进入组后，上层组其它组件可能不显示问题
    this.activeArtboard.components.forEach((comp) => {
      comp.toJSON().v = comp.toJSON().v + Math.round(Math.random() * 100);
    });
    container.isActived = true;
    if (this.activeContainer !== container) {
      this.restoreSelectedCompState();
    }

    // 设置需要更新的画板
    const updateArtboardIds = [];
    if (this.activeArtboard.isArtboard) {
      updateArtboardIds.push((this.activeArtboard as UIFragment).artboardID);
    }
    if (container.isArtboard) {
      this.activeArtboard = container as UIFragment;
      updateArtboardIds.push((container as UIFragment).artboardID);
    }

    this.activeContainer = container;
    // 激活组改变后，清除选中
    this.selectedComponents.clear();
    this.notifyUpdateListener(updateArtboardIds);
  }

  setDeletingArtboard(artboard: UIFragment | undefined) {
    this.deletingArtboard = artboard;
  }
}

export default CoreActive;
