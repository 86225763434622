import { WorkerBase } from './WorkerBase';

export default class ParallelWorker extends WorkerBase {
  protected start() {
    const cmds = [...this.commands];
    if (this.needRevertWorker) {
      cmds.reverse();
    }
    cmds.forEach(this.executeNext.bind(this));
  }
}
