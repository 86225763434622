import * as React from 'react';
import { isUndefined } from 'lodash';
import classnames from 'classnames';

import { convertEventToHotKey, convertShortCutToHotKey } from '@utils/hotkeysUtils';
import { titleBarHeight, isMacOS, isSafari } from '@/utils/envUtils';
import { abs, min, max, round } from '@utils/globalUtils';

import { getShortCutKey } from '@/helpers/shortCutHelper';
import { MouseButtons } from '@/consts/enums/mouseButton';
import i18n from '@i18n';

import { PopupMenu, IMenuItem, Icon } from '..';
import InputNumber from '../InputNumber';

import './index.scss';

interface IZoomerProp {
  min?: number;
  max?: number;
  value: number;
  popupTop?: number;
  // step?: number;
  canSelect?: boolean;
  canInput?: boolean;
  presetMenuItem?: IZoomMenuItem[];
  customMenuItem?: IMenuItem[];
  className?: string;
  disabled?: boolean;
  onZoom: (value: number, isChangeNotByWheel: boolean) => void;
  onCustomZoom: (item: IMenuItem) => number;
  theme?: 'normal' | 'light';
  wheelContainer?: HTMLElement | null;
}

interface IZoomerState {
  editing: boolean;
  popup: boolean;
  value: number;
  tempValue: any;
  popupPosition?: { x: number; y: number };
}

interface IZoomMenuItem extends IMenuItem {
  value: number;
}

interface II18nText {
  scaleMin: string;
  scaleMax: string;
}

const defaultValue: IZoomMenuItem[] = [
  { id: 10, text: '10%', value: 10 },
  { id: 25, text: '25%', value: 25 },
  { id: 33, text: '33%', value: 33 },
  { id: 50, text: '50%', value: 50 },
  { id: 75, text: '75%', value: 75 },
  { id: 100, text: '100%', value: 100, shortCut: getShortCutKey('0', { ctrlKey: true }) },
  { id: 150, text: '150%', value: 150 },
  { id: 200, text: '200%', value: 200 },
  { id: 300, text: '300%', value: 300 },
  { id: 400, text: '400%', value: 400 },
];

const i18nText: II18nText = {
  scaleMin: `${i18n('general.scale.min')} ${'(' + getShortCutKey('-', { ctrlKey: true }) + ')'}`,
  scaleMax: `${i18n('general.scale.max')} ${'(' + getShortCutKey('+', { ctrlKey: true }) + ')'}`,
};

// ctrl + +/-缩放时的比例值
const QuickZoomValues: number[] = [
  10,
  25,
  50,
  75,
  100,
  150,
  200,
  300,
  400,
  500,
  600,
  700,
  800,
  1200,
  1600,
  3200,
  6400,
  12800,
  25600,
];

const ZOOM_STEP = 0.83;

let timerFuc = 0;

class Zoomer extends React.PureComponent<IZoomerProp, IZoomerState> {
  timeID: any = null;
  running: boolean = false;
  static defaultProps: Partial<IZoomerProp> = {
    className: '',
    min: 10,
    max: 400,
    // step: 1,
    value: 200,
    canInput: true,
    canSelect: true,
    theme: 'normal',
  };

  inputRef: React.RefObject<InputNumber> = React.createRef();
  selfRef: React.RefObject<HTMLDivElement> = React.createRef();

  private _presetMenuItem: IZoomMenuItem[];

  constructor(props: IZoomerProp) {
    super(props);
    this._presetMenuItem = props.presetMenuItem || defaultValue;
    this.state = {
      editing: false,
      popup: false,
      value: round(props.value || 200),
      tempValue: null,
    };
    this.validateShortCut();
  }

  componentDidMount() {
    window.addEventListener('wheel', this.handleWindowWheel, {
      passive: false,
    });
    window.addEventListener('keydown', this.handleWindowKeyDown, { capture: true });
  }

  UNSAFE_componentWillUpdate(newProps: IZoomerProp, newState: IZoomerState) {
    let validate = false;
    if (newProps.presetMenuItem !== this.props.presetMenuItem) {
      this._presetMenuItem = newProps.presetMenuItem || defaultValue;
      validate = true;
    }
    if (this.props.customMenuItem !== newProps.customMenuItem) {
      validate = true;
    }
    if (validate) {
      this.validateShortCut();
    }
    const { popup } = this.state;
    if (newState.popup !== popup) {
      if (newState.popup) {
        window.addEventListener('mousedown', this.handleWindowMouseDown);
      } else {
        window.removeEventListener('mousedown', this.handleWindowMouseDown);
      }
    }
  }

  componentDidUpdate() {
    this.inputRef.current?.focus();
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.handleWindowWheel);
    window.removeEventListener('keydown', this.handleWindowKeyDown, { capture: true });
    window.removeEventListener('mousedown', this.handleWindowMouseDown);
  }

  private validateShortCut() {
    const { customMenuItem } = this.props;
    if (customMenuItem) {
      const len = this._presetMenuItem.length;
      const customShortCut = customMenuItem.filter((item) => !!item.shortCut).map((item) => item.shortCut);
      if (customShortCut.length) {
        for (let i = 0; i < len; i++) {
          const item = this._presetMenuItem[i];
          if (item.shortCut && customShortCut.includes(item.shortCut)) {
            console.error('缩放快捷键重复');
            break;
          }
        }
      }
    }
  }

  handleWindowMouseDown = (e: MouseEvent) => {
    const { target } = e;
    if (this.props.disabled) {
      return;
    }
    let parent: HTMLElement = target as HTMLElement;
    const self = this.selfRef.current;
    while (parent !== self) {
      parent = parent.parentNode as HTMLElement;
      if (!parent) {
        break;
      }
    }
    if (parent === self) {
      return;
    }
    const { tempValue, value, editing } = this.state;
    if (editing) {
      this.doChange(this.validateValue(tempValue || value));
    }
    this.setState({ tempValue: null, editing: false, popup: false });
  };

  private lastWheelTimeStamp: number = 0;

  private calcRatio(wheelDeltaY: number) {
    // const _wheelDeltaY = Math.abs(wheelDeltaY);
    // if(!isMacOS){
    //   return wheelDeltaY > 0 ? -120 : 120;
    // } else {
    //   if(isSafari()){
    //     return wheelDeltaY > 0 ? -120 : 120;
    //   }
    //   return -wheelDeltaY;
    // }
    return wheelDeltaY > 0 ? -120 : 120;
  }

  handleWindowWheel = (e: WheelEvent) => {
    const { metaKey, ctrlKey, timeStamp, wheelDeltaY, deltaY } = e;
    if (metaKey || ctrlKey) {
      e.preventDefault();
    }
    if (timerFuc || e.cancelBubble) {
      return;
    }
    if (this.props.wheelContainer) {
      const { left, top, right, bottom } = this.props.wheelContainer.getBoundingClientRect();
      const { pageY, pageX } = e;
      if (left > pageX || right < pageX || top > pageY || bottom < pageY) {
        return;
      }
    }
    // 滚动速度
    let speed = 1;
    //FIXME Matt 20200914 妙控鼠标的速度限制
    // 滚轮事件触发时间间隔
    const step = isMacOS ? 40 : 32;
    if (timeStamp - this.lastWheelTimeStamp < step) {
      speed = min((timeStamp - this.lastWheelTimeStamp) / step, 1);
    }
    this.lastWheelTimeStamp = timeStamp;
    // 在mac上 双指是ctrlkey 加滚轮所有取消对mac的判断
    // FIXME 这里不可用 isControlKeyPressed
    if (ctrlKey || metaKey) {
      if (e.stopImmediatePropagation) {
        e.stopImmediatePropagation();
      }
      e.preventDefault();
      if (this.running) {
        this.running = false;
        return;
      }
      if (this.props.disabled) {
        return;
      }
      this.running = true;

      let wheelStep = isUndefined(deltaY) ? 120 : 100;
      // if (isFirefox()) {
      //   wheelStep = deltaMode === WheelEvent.DOM_DELTA_PIXEL ? 45 : 6;
      // }
      // let value = isFirefox() ? deltaY : isUndefined(deltaY) ? -wheelDeltaY : deltaY;
      // const mouseDelta = isFirefox() ? 90 : 120;
      // if (!isMacOS && Math.abs(value) < mouseDelta) {
      //   if (isFirefox()) {
      //     value = value * 4;
      //   } else {
      //     value = value * 10;
      //   }
      // }
      const value = this.calcRatio(isSafari() ? -deltaY : wheelDeltaY);
      const ratio = isMacOS ? 1 : abs(value) / wheelStep;
      if (window.requestAnimationFrame) {
        timerFuc = window.requestAnimationFrame(() => {
          this.doWheel(value, ratio, speed);
          timerFuc = 0;
        });
      } else {
        setTimeout(this.doWheel, 16, value, ratio, speed);
      }
    }
  };

  doWheel = (deltaY: number, ratio: number, speed: number) => {
    try {
      const direction = abs(deltaY) - deltaY === 0 ? -1 : 1;
      this.doChange(this.doCalculateValue(direction > 0 ? 'in' : 'out', ratio, speed), false);
    } finally {
      this.running = false;
    }
  };

  private doCalculateValue(zoomMode: 'in' | 'out', ratio: number = 1, speed: number = 1) {
    const stepRatio = ZOOM_STEP; // 0.93;
    const { value, max: _max } = this.props;
    // FIXME 触控板的速度很快，通过对缩放速率的限制，保证平滑的同时，缩放速度降下来
    const step = 1 - (1 - stepRatio) * speed * ratio;
    if (step <= 0) {
      return value;
    }
    let newValue = value * (zoomMode === 'in' ? 1 / step : step);
    if (newValue <= (_max ?? 1600)) {
      const mode = newValue % 100;
      const s = min(round(newValue / 100), 5);
      if (mode > 100 - 3 * s || mode < 3 * s) {
        newValue = newValue - mode + round(mode / 100) * 100;
      }
    }
    return round(newValue);
  }

  private findCustomItem(hotKey: string) {
    const { customMenuItem } = this.props;
    if (!customMenuItem || !customMenuItem.length) {
      return null;
    }

    return customMenuItem.find((item) => {
      if (item.shortCut) {
        const key = convertShortCutToHotKey(item.shortCut);
        return key === hotKey;
      }
      return false;
    });
  }

  private getHotKey = (e: KeyboardEvent) => {
    const hotKey = convertEventToHotKey(e);
    if (hotKey === 'ctrl+=') {
      return 'ctrl++';
    }
    if (hotKey === 'ctrl+_') {
      return 'ctrl+-';
    }
    return hotKey;
  };

  handleWindowKeyDown = (e: KeyboardEvent) => {
    const { value, disabled, onCustomZoom } = this.props;
    let newValue = value;
    if (disabled) {
      return;
    }
    const hotKey = this.getHotKey(e);
    const customZoomItem = this.findCustomItem(hotKey);
    if (customZoomItem && onCustomZoom) {
      newValue = onCustomZoom(customZoomItem);
      this.doChange(newValue, true);
      return;
    }
    if (hotKey === 'ctrl++') {
      e.preventDefault();
      newValue = this.doGetQuickZoomValue('in');
    } else if (hotKey === 'ctrl+-') {
      e.preventDefault();
      newValue = this.doGetQuickZoomValue('out');
    } else {
      const item = this._presetMenuItem.find((item) => {
        if (item.shortCut) {
          const k = convertShortCutToHotKey(item.shortCut);
          return k === hotKey;
        }
        return false;
      });
      if (item) {
        e.preventDefault();
        newValue = item.value;
      }
    }
    if (value !== newValue) {
      this.doChange(newValue, true);
    }
  };

  doGetQuickZoomValue(mode: 'in' | 'out') {
    const { value, max: _max, min: _min } = this.props;
    if ((mode === 'in' && value >= (_max || 100)) || (mode === 'out' && value <= (_min || 0))) {
      return value;
    }
    let index = QuickZoomValues.findIndex((v) => {
      return v >= value;
    });
    if (mode === 'out') {
      index -= 1;
    } else {
      if (QuickZoomValues.includes(value)) {
        index += 1;
      }
    }
    index = max(0, min(index, QuickZoomValues.length - 1));
    return QuickZoomValues[index];
  }

  handleZoomIn = () => {
    this.doChange(this.doGetQuickZoomValue('in'));
  };

  handleZoomOut = () => {
    this.doChange(this.doGetQuickZoomValue('out'));
  };

  validateValue = (value: number) => {
    const { min: _min, max: _max } = this.props;
    return max(_min || 0, min(value, _max || 100));
  };

  doChange = (value: number, isChangeNotByWheel = true) => {
    const v = this.validateValue(value);
    const { onZoom } = this.props;
    onZoom(v, isChangeNotByWheel);
    if (this.state.popup || this.state.editing) {
      this.setState({ popup: false, editing: false });
    }
  };

  handleInputChange = (value: number) => {
    if (this.state.tempValue !== value) {
      this.setState({ tempValue: value });
    }
  };

  handleInputBlur = () => {
    const { onZoom } = this.props;
    const { value, tempValue } = this.state;
    const v = this.validateValue(tempValue !== null ? tempValue : value);
    onZoom(v, true);
    this.setState({
      popup: false,
      editing: false,
      value: v,
      tempValue: null,
    });
  };

  handleMenuSelect = (item: IMenuItem) => {
    const { onZoom, onCustomZoom, customMenuItem } = this.props;
    let value: number;
    if (customMenuItem?.includes(item)) {
      value = onCustomZoom ? onCustomZoom(item) : this.props.value;
    } else {
      value = (item as IZoomMenuItem).value;
    }
    const v = this.validateValue(value);
    this.setState({ value: v, editing: false, popup: false, tempValue: null });
    onZoom(v, true);
  };

  doPopup(needPopup: boolean) {
    if (this.timeID) {
      clearTimeout(this.timeID);
      this.timeID = null;
    }
    if (!this.selfRef.current) {
      return;
    }

    const bounds = this.selfRef.current.firstElementChild!.getBoundingClientRect();
    const { value, canSelect, canInput, popupTop } = this.props;
    const { popup, editing } = this.state;
    const p: boolean = needPopup && (canSelect || false) && !popup;
    const spaceHeight = 4;
    const top = (popupTop ?? bounds.bottom + spaceHeight) + titleBarHeight;
    this.setState({
      value,
      popup: p,
      editing: (!needPopup || editing) && (canInput || false),
      popupPosition: needPopup ? { x: bounds.left, y: top } : undefined,
    });
  }

  handleDblClick = () => {
    if (this.props.disabled) {
      return;
    }
    if (this.props.canInput) {
      this.doPopup(false);
    }
  };

  handleMouseDown = (e: React.MouseEvent) => {
    if (this.props.disabled) {
      return;
    }
    const { popup, editing } = this.state;
    if (this.timeID) {
      clearTimeout(this.timeID);
    }
    if (e.buttons !== MouseButtons.Left) {
      return;
    }
    this.timeID = setTimeout(() => {
      if (!popup) {
        this.doPopup(true);
      }
      if (!editing) {
        this.setState({ editing: true });
      }
    }, 250);
  };

  renderInput() {
    const { editing, value, tempValue } = this.state;
    const v = tempValue !== null ? tempValue : round(value);
    if (editing) {
      return (
        <InputNumber
          ref={this.inputRef}
          autoFocus
          autoSelectWhenFocus
          value={v}
          suffix="%"
          onBlur={this.handleInputBlur}
          onChange={this.handleInputChange}
        />
      );
    }
    return null;
  }

  renderMenu() {
    const { popup } = this.state;
    const { customMenuItem, theme } = this.props;

    const menuData: IMenuItem[] = [...this._presetMenuItem];
    if (customMenuItem) {
      menuData.push({ text: '-', id: '' }, ...customMenuItem);
    }
    if (popup) {
      return (
        <PopupMenu
          width={100}
          className={classnames('zoom-drop-down-list', { 'light-drop': theme === 'light' })}
          position={this.state.popupPosition || { x: 0, y: 0 }}
          items={menuData}
          onItemClick={this.handleMenuSelect}
          onClose={() => {
            this.setState({ popup: false });
          }}
        />
      );
    }
    return null;
  }

  render() {
    const { value, className, min, max, disabled } = this.props;
    const { editing } = this.state;
    const disableL = value <= (min || 0);
    const disableR = value >= (max || 100);
    return (
      <div className={classnames(`dsm-c-rp-zoomer ${className}`)} ref={this.selfRef}>
        <div
          className={classnames('zoomer-content', { disableL: disableL || disabled, disableR: disableR || disabled })}
        >
          <div className="zoom-btn" onClick={this.handleZoomOut}>
            <Icon className="min-icon" cls="minus" size={16} disabled={disabled} tips={i18nText.scaleMin} />
          </div>
          <span
            className={classnames('zoomer-con', { disabled, mac: isMacOS, editing })}
            onMouseDown={this.handleMouseDown}
            onDoubleClick={this.handleDblClick}
          >
            {this.renderInput()}
            {!editing && round(value)}
          </span>
          <div className="zoom-btn" onClick={this.handleZoomIn}>
            <Icon className="max-icon" cls="plus" size={16} disabled={disabled} tips={i18nText.scaleMax} />
          </div>
          {this.renderMenu()}
        </div>
      </div>
    );
  }
}

export default Zoomer;
