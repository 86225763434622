import * as React from 'react';

import classNames from 'classnames';
import i18n, { languageManager } from '@/i18n';

import { DialogIsExportMrp } from '@/consts/dialog';
import { Dialog, Button, PageTree } from '@dsm';
import { ScrollView, SearchInput, Checkbox } from '@dsm2';
import { IAppF } from '@fbs/rp/models/app';

import { IResourceState } from '@/store/resource/reducers';

import { INode } from '@/dsm/PageTree/model';
import { getAllParentNodeIDs, flattenChildren, getGroupPageNum } from '@/dsm/PageTree/utils';
import { isNodeMatch } from '@/dsm/PageTree/utils';

import './index.scss';

interface IProps {
  app?: IAppF;
  resource?: IResourceState;
  nodeIDs: INode[];
  defaultExpandLevel?: number;
  allCheckedOption?: boolean;
  checkedKeys?: string[];
  checkedPageKeys?: string[];
  expandedKeys?: string[];
  dialogTitle: string;
  dialogTip: string;
  dialogSubmitButtonText?: string;
  dialogCloseButtonText?: string;
  dialogType?: 'isExportMrp' | 'isOfflineDemo' | string;
  disabled?: boolean;
  onClose?: () => void;
  onSubmit?: (checkedKeys: string[], checkedPageKeys: string[], expandedKeys: string[], selectedLibs?: boolean) => void;
}

interface IState {
  dialog?: boolean;
  selectedExportLibs?: boolean;
  checkedKeys?: string[];
  checkedPageKeys?: string[];
  selectedKeys?: string[];
  expandedKeys: string[];
  searchValue?: string;
  allSelectKeys?: boolean;
  nodesThatHasPage: INode[]; // 拥有页面的分组
  allSelectableNodeIDs: string[]; // 默认全选node的id
}

class PageTreeDialog extends React.Component<IProps, IState> {
  private expandIDsWhenSearch: string[] = [];

  constructor(props: IProps) {
    super(props);
    this.state = {
      checkedKeys: [],
      checkedPageKeys: [],
      selectedKeys: [],
      expandedKeys: [],
      searchValue: '',
      allSelectKeys: props.allCheckedOption,
      nodesThatHasPage: [],
      allSelectableNodeIDs: [],
    };
  }

  componentDidMount() {
    const { nodeIDs, defaultExpandLevel, allCheckedOption } = this.props;
    const sessionExportPage = sessionStorage.getItem('rp-exportPage');
    let { expandedKeys, allSelectKeys, selectedExportLibs } = this.state;
    let checkedNodeIDs: string[] | undefined = [];
    let checkedPageNodeIDs: string[] | undefined = [];

    if (defaultExpandLevel == -1) {
      expandedKeys = getAllParentNodeIDs(nodeIDs, -1);
    }
    const nodesThatHasPage = nodeIDs.filter((node) => {
      return getGroupPageNum([node]).length > 0;
    });
    const allSelectableNodeIDs = flattenChildren(nodesThatHasPage)
      .filter((node) => {
        if ((node.isLeaf || node.children!.length > 0) && !node.data.hidden) {
          return node;
        }
      })
      .map((item) => {
        return item.id;
      });
    //默认全选
    if (allCheckedOption) {
      checkedNodeIDs = allSelectableNodeIDs;
      checkedPageNodeIDs = getGroupPageNum(nodesThatHasPage!);
    }

    // 是否有缓存
    if (sessionExportPage) {
      const {
        checkedKeys,
        checkedPageKeys,
        expandedKeys: propsExpandedKeys,
        oldTreeStructure,
        selectedExportLibs: isSelectedExportLibs,
      } = JSON.parse(sessionExportPage);
      // 缓存页面树数量与当前页面树数量相才用缓存
      if (oldTreeStructure.length === checkedNodeIDs.length) {
        let isSameChecked = true;
        for (let item in checkedNodeIDs) {
          if (checkedNodeIDs[item] !== oldTreeStructure[item]) {
            isSameChecked = false;
          }
        }
        // 树结构不变，用缓存数组
        if (isSameChecked) {
          if (checkedKeys.length !== checkedNodeIDs!.length) {
            allSelectKeys = false;
          }
          checkedNodeIDs = checkedKeys;
          checkedPageNodeIDs = checkedPageKeys;
          expandedKeys = propsExpandedKeys;
        }
      }
      selectedExportLibs = isSelectedExportLibs || selectedExportLibs;
    }

    this.setState({
      expandedKeys,
      checkedKeys: checkedNodeIDs,
      checkedPageKeys: checkedPageNodeIDs,
      allSelectKeys,
      nodesThatHasPage,
      allSelectableNodeIDs,
      selectedExportLibs,
    });
  }

  //外部ref使用的方法，因为目前checkedKeys值只在onSubmie的时候回调，
  //其他时候都是用的自己的，props的checkedKeys是一个没使用的参数，恢复checkedKeys使用的sessionStorage缓存数据
  //checkedKeys等均为不可控数据
  //getDerivedStateFromProps之类的 都会出现问题,影响现有逻辑
  //这里问题很大，暂时改不动，加了这个方法，在外部需要同步内部数据的时候，手动调用一下
  public updateTreeCheck(
    checkedKeys: string[],
    checkedPageKeys: string[],
    expandedKeys: string[],
    allSelectKeys: boolean,
  ) {
    let newCheckedKeys: string[] | undefined = undefined;
    let newCheckedPageKeys: string[] | undefined = undefined;
    let newxpandedKeys: string[] | undefined = undefined;
    if (checkedKeys.length !== this.state.checkedKeys?.length) {
      newCheckedKeys = checkedKeys;
    }
    if (checkedPageKeys.length !== this.state.checkedPageKeys?.length) {
      newCheckedPageKeys = checkedPageKeys;
    }
    if (expandedKeys.length !== this.state.expandedKeys?.length) {
      newxpandedKeys = expandedKeys;
    }
    if (newCheckedKeys || newCheckedPageKeys || newxpandedKeys) {
      this.setState(
        {
          checkedKeys: newCheckedKeys || this.state.checkedKeys,
          checkedPageKeys: newxpandedKeys || this.state.checkedPageKeys,
          expandedKeys: newxpandedKeys || this.state.expandedKeys,
          allSelectKeys,
        },
        () => this.setExportPageSession(),
      );
    }
  }

  componentWillUnmount() {
    const { checkedKeys, checkedPageKeys, expandedKeys } = this.state;
    const { dialogCloseButtonText, onSubmit, dialogType } = this.props;
    if (dialogCloseButtonText) {
      return;
    }
    const isMrp = dialogType === DialogIsExportMrp;
    this.setExportPageSession();
    onSubmit && !isMrp && onSubmit(checkedKeys!, checkedPageKeys!, expandedKeys);
  }

  setExportPageSession = () => {
    const { checkedKeys, checkedPageKeys, expandedKeys, allSelectableNodeIDs, selectedExportLibs } = this.state;
    sessionStorage.setItem(
      'rp-exportPage',
      JSON.stringify({
        checkedKeys,
        checkedPageKeys,
        expandedKeys,
        oldTreeStructure: allSelectableNodeIDs,
        selectedExportLibs,
      }),
    );
  };

  get showPageNumber() {
    return this.props.app?.showRPPageNumber ?? false;
  }

  onChecked = (checkedKeys: string[], checkedPageKeys: string[]) => {
    if (checkedKeys.length === this.pageGroupNodeCount) {
      this.setState({ allSelectKeys: true });
    } else {
      this.setState({ allSelectKeys: false });
    }
    this.setState({ checkedKeys, checkedPageKeys });
  };

  onSelected = () => {
    // this.setState({ selectedKeys });
  };

  onExpand = (expandedKeys: string[]) => {
    this.setState({ expandedKeys });
  };

  // 当搜索时， 展开匹配的文件夹
  getExpandIDsWhenSearch = (nodes: INode[], searchVal: string): string[] => {
    const expandedIDs: string[] = [];
    const computedSearchExpand = (nodes: INode[], expandedIDs: string[], searchVal: string): boolean => {
      if (!searchVal) {
        return false;
      }
      let cursor: number = 0;
      let searchMatchChild = false;
      while (cursor < nodes.length) {
        const node = nodes[cursor];
        if (node.children?.length) {
          //自下向上计算
          const match = computedSearchExpand(node.children, expandedIDs, searchVal);
          searchMatchChild = searchMatchChild ? searchMatchChild : match;
          //如果子组件有匹配的结果
          if (match || isNodeMatch(node, searchVal)) {
            expandedIDs.push(node.id);
          }
        }
        //判断名称是否搜索匹配
        if (isNodeMatch(node, searchVal)) {
          searchMatchChild = true;
        }
        cursor++;
      }
      //子组件有匹配需要返回通知父组件
      return searchMatchChild;
    };
    computedSearchExpand(nodes, expandedIDs, searchVal);
    return expandedIDs;
  };

  onSearchInputChange = (value: string) => {
    const { nodeIDs } = this.props;
    this.expandIDsWhenSearch = this.getExpandIDsWhenSearch(nodeIDs, value);
    this.setState({ searchValue: value, expandedKeys: [...this.expandIDsWhenSearch] });
  };

  renderchoosePage() {
    return (
      <div className="page-tree-dialog">
        <Dialog
          title={this.props.dialogTitle}
          // eslint-disable-next-line react/no-children-prop
          children={this.renderPageTree()}
          onClose={this.props.onClose}
          closable
          backFade
        ></Dialog>
      </div>
    );
  }

  get pageGroupNodeCount() {
    const { allSelectableNodeIDs } = this.state;
    return allSelectableNodeIDs.length;
  }

  get allPageCount() {
    const { nodesThatHasPage } = this.state;
    return getGroupPageNum(nodesThatHasPage).length;
  }

  handleAllSelectChange = (newState: boolean) => {
    let { allSelectKeys, checkedKeys, checkedPageKeys } = this.state;
    const { allSelectableNodeIDs, nodesThatHasPage } = this.state;
    if (!allSelectKeys) {
      checkedPageKeys = getGroupPageNum(nodesThatHasPage);
      checkedKeys = allSelectableNodeIDs;
    } else {
      checkedPageKeys = [];
      checkedKeys = [];
    }
    this.setState({ allSelectKeys: newState, checkedKeys, checkedPageKeys });
  };

  handleSelectExportLibs = () => {
    this.setState((state) => {
      return { selectedExportLibs: !state.selectedExportLibs };
    });
  };

  handleSelectCurrentPage = () => {
    const checkedPageKeys: string[] = [];
    this.setState({ allSelectKeys: false, checkedPageKeys, checkedKeys: [] });
  };

  onStopSearch = () => {
    this.setState({ searchValue: '' });
  };

  handleSubmit = () => {
    const { checkedKeys, checkedPageKeys, expandedKeys, selectedExportLibs } = this.state;
    const { onSubmit } = this.props;
    if (onSubmit && checkedKeys && checkedPageKeys) {
      onSubmit(checkedKeys, checkedPageKeys, expandedKeys, selectedExportLibs);
    }
    this.setExportPageSession();
  };

  renderPageTree() {
    const { checkedKeys, expandedKeys, searchValue, checkedPageKeys, allSelectKeys, selectedExportLibs } = this.state;
    const { nodeIDs, resource, dialogType, dialogTip } = this.props;
    const hasCheckedKey = checkedKeys?.length! > 0;
    const { colors, components, typographies } = resource?.currentLib || {};
    const exportLibsDisabled = !colors?.length && !components?.length && !typographies?.length;
    const isMrp = dialogType === DialogIsExportMrp; // 判断是否为导出mrp的弹窗
    return (
      <>
        <div className="exportFile-title">
          <div className="exportFile-titleTip">{dialogTip}</div>
          <div
            className={classNames('exportFile-pageCount', {
              'exportFile-pageCount-en': languageManager.isEnLanguage,
            })}
          >
            <div className="exportFile-pageCount-selected">{i18n('file.selected')}</div>
            <span className="exportFile-pageCount-span">{checkedPageKeys?.length + '/' + this.allPageCount}</span>
          </div>
        </div>
        <div className="exportFile-content">
          <SearchInput
            placeholder={i18n('resource.searchPage')}
            width="100%"
            value={searchValue}
            onChange={this.onSearchInputChange}
            alwaysShowClose={!!searchValue}
            onStopSearch={this.onStopSearch}
          ></SearchInput>
          <ScrollView height={270} width={436}>
            <PageTree
              data={nodeIDs}
              selectedKeys={[]}
              cuttedKeys={[]}
              multiLevelSeletable={false}
              multiSelectedable={false}
              searchKey={searchValue}
              searchKeyHighlight={true}
              userPropsMatch={false}
              showCheckBox
              checkedKeys={checkedKeys}
              checkedPageKeys={checkedPageKeys}
              maxWidth={436}
              disableUncheckChildrenIfParentIsChildren={false}
              expandedKeys={expandedKeys!}
              onChecked={this.onChecked}
              onSelected={this.onSelected}
              onExpand={this.onExpand}
              onDragEnd={() => {}}
              needDebounce
            ></PageTree>
          </ScrollView>
        </div>
        <div className="exportFile-tail">
          <div className="exportFile-checkbox">
            <Checkbox
              text={i18n('general.selectAll')}
              checked={allSelectKeys}
              onChange={this.handleAllSelectChange}
            ></Checkbox>
          </div>

          {isMrp && (
            <div className="exportFile-checkbox resource-item">
              <Checkbox
                text={i18n('general.exportResourceLibs')}
                checked={selectedExportLibs}
                disabled={exportLibsDisabled}
                onChange={this.handleSelectExportLibs}
              ></Checkbox>
            </div>
          )}
          <div className="exportFile-buttonGroup">
            <Button
              className="exportFile-buttonGroup-submit"
              theme="dialog"
              disabled={!hasCheckedKey}
              activated
              onClick={this.handleSubmit}
            >
              {this.props.dialogSubmitButtonText || i18n('general.ok')}
            </Button>
            <Button theme="dialog" onClick={this.props.onClose}>
              {this.props.dialogCloseButtonText || i18n('general.cancel')}
            </Button>
          </div>
        </div>
      </>
    );
  }

  render() {
    return <>{this.renderchoosePage()}</>;
  }
}

export default PageTreeDialog;
