import * as React from 'react';

import { ITreeData, ITreeDataItem } from '@fbs/rp/models/value';
import { ITreeItem, mapRelationWithComp, createAvlTreeWithTreeComp } from '@helpers/treeCompHelper';
import { UIVerticalMenuComponent, UITreeItemComponent } from '@editor/comps';
import { IComponentProps } from '../../types';

import CanvasPanel from '../../containers/Canvas';
import VerticalMenuItem from './VerticalMenuItem';

import './index.scss';

interface IVerticalMenuState {
  isVerticalItemHover?: boolean;
  verticalMenuHoverItemID?: string;
}

export default class VerticalMenu extends React.Component<IComponentProps, IVerticalMenuState> {
  protected self: React.RefObject<HTMLDivElement> = React.createRef();
  private timer: number | undefined = undefined;
  private selectIDs: string[] = [];
  // 此对象为最初始的值，window.pageScale变更时注意换算
  private floatPanelRects: Record<string, number> = {};
  private defaultScale = window.pageScale;

  constructor(props: IComponentProps) {
    super(props);
    this.state = { isVerticalItemHover: false, verticalMenuHoverItemID: '' };
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleDisPlayItemChild, true);
    window.addEventListener('resize', this.handleDisPlayItemChild, true);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    document.removeEventListener('scroll', this.handleDisPlayItemChild, true);
    window.removeEventListener('resize', this.handleDisPlayItemChild, true);
  }

  handleDisPlayItemChild = () => {
    this.setState({ verticalMenuHoverItemID: '' });
  };

  private getCompFormNode = (node: ITreeDataItem) => {
    const { comp } = this.props;
    return (comp as UIVerticalMenuComponent).getItemCompById(node.id);
  };

  handleItemClick = (node: ITreeDataItem, e: React.MouseEvent) => {
    const { comp, isPreview } = this.props;
    if (isPreview && !comp.disabled) {
      const itemComp = this.getCompFormNode(node);
      if (itemComp) {
        const clickEvent = this.props.event?.onClick;
        clickEvent && clickEvent(e, itemComp);
      }
    }
  };

  handleItemMouseDown = (node: ITreeDataItem, e: React.MouseEvent) => {
    const { comp, isPreview } = this.props;
    if (isPreview && !comp.disabled) {
      const itemComp = this.getCompFormNode(node);
      if (itemComp) {
        const onMouseDown = this.props.event?.onMouseDown;
        onMouseDown && onMouseDown(e, itemComp);
      }
    }
  };

  handleItemExpandClick = (node: ITreeDataItem, e: React.MouseEvent) => {
    const { comp } = this.props;
    if (comp.disabled) {
      return;
    }
    const itemComp = this.getCompFormNode(node);
    const checkboxComp = itemComp.expandComp;
    if (checkboxComp) {
      const clickEvent = this.props.event?.onClick;
      clickEvent && clickEvent(e, checkboxComp);
    }
  };

  handleItemDoubleClick = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      const comp = this.getCompFormNode(node);
      const onDoubleClick = this.props.event?.onDoubleClick;
      comp && onDoubleClick && onDoubleClick(e, comp);
    }
  };

  handleItemMouseUp = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      const comp = this.getCompFormNode(node);
      const onMouseUp = this.props.event?.onMouseUp;
      comp && onMouseUp && onMouseUp(e, comp);
    }
  };

  handleItemMouseEnter = (node: ITreeDataItem, isHover: boolean, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      const comp = this.getCompFormNode(node);
      const onMouseEnter = this.props.event?.onMouseEnter;
      comp && onMouseEnter && onMouseEnter(e, comp);
      this.setState({ verticalMenuHoverItemID: node.id, isVerticalItemHover: isHover });
    }
  };

  handleItemMouseLeave = (node: ITreeDataItem, isHover: boolean, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      const comp = this.getCompFormNode(node);
      const onMouseLeave = this.props.event?.onMouseLeave;
      comp && onMouseLeave && onMouseLeave(e, comp);
      this.setState({ isVerticalItemHover: isHover });
    }
  };

  handleItemContext = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      const comp = this.getCompFormNode(node);
      const onContextMenu = this.props.event?.onContextMenu;
      comp && onContextMenu && onContextMenu(e, comp);
    }
  };

  handleMouseLeave = () => {
    this.timer = window.setTimeout(() => {
      const { isVerticalItemHover } = this.state;
      if (!isVerticalItemHover) {
        this.setState({ verticalMenuHoverItemID: '' });
      }
    }, 200);
  };

  renderItem = (item: ITreeItem, level: number, index: number) => {
    const { isPreview, comp, hotAreaVisibleModel, showMobileCursor } = this.props;
    const itemComp = (comp as UIVerticalMenuComponent).components.find((comp) => comp.id === item.data.id);
    if (!itemComp) return;
    const { verticalMenuHoverItemID } = this.state;
    const {
      lineHeight,
      intention,
      checkedFill,
      padding,
      showExpandIcon,
      showNodeIcon,
      size,
      checkedTextStyle,
      checkedIconColor,
    } = (comp as UIVerticalMenuComponent).parseProperties();
    const verticalContentRects = this.self.current?.getClientRects()!;
    let showInteractionBackGround = false;
    if (hotAreaVisibleModel === 'only-hover' && !!verticalMenuHoverItemID) {
      showInteractionBackGround = true;
    }
    if (hotAreaVisibleModel === 'always') {
      showInteractionBackGround = true;
    }
    return (
      <VerticalMenuItem
        key={item.data.id}
        _id={item.data.id}
        verticalMenuHoverItemID={verticalMenuHoverItemID}
        selectIDs={this.selectIDs}
        verticalContentRects={verticalContentRects}
        item={item}
        comp={itemComp as UITreeItemComponent}
        verticalComp={comp as UIVerticalMenuComponent}
        padding={padding}
        paddingStyle={this.getPaddingStyle()}
        lineHeight={lineHeight}
        level={level}
        size={size}
        index={index}
        showMobileCursor={showMobileCursor}
        checkedTextStyle={checkedTextStyle}
        checkedIconColor={checkedIconColor}
        intention={intention}
        checkedFill={checkedFill}
        showExpandIcon={showExpandIcon}
        showNodeIcon={showNodeIcon}
        isPreview={!!isPreview}
        showInteractionBackGround={showInteractionBackGround}
        onExpandClick={this.handleItemExpandClick}
        onItemClick={this.handleItemClick}
        onItemMouseDown={this.handleItemMouseDown}
        onItemMouseUp={this.handleItemMouseUp}
        onItemDoubleClick={this.handleItemDoubleClick}
        onItemMouseEnter={this.handleItemMouseEnter}
        onItemMouseLeave={this.handleItemMouseLeave}
        onItemContext={this.handleItemContext}
        floatPanelRects={this.floatPanelRects}
        defaultScale={this.defaultScale}
      >
        {item.children?.length && this.renderItems(item.children, level + 1, index)}
      </VerticalMenuItem>
    );
  };

  renderItems = (items: ITreeItem[], level: number, index: number) => {
    return items.map((item, itemIndex) => {
      return this.renderItem(item, level, index + itemIndex);
    });
  };

  getParentIDsByCompTree = (tree: ITreeItem): string[] => {
    let parentsIDs: string[] = [];
    parentsIDs.push(tree.data.id);
    if (tree.parent) {
      return [...parentsIDs, ...this.getParentIDsByCompTree(tree.parent)];
    }
    return parentsIDs;
  };

  renderVerticalMenu = () => {
    const { comp } = this.props;
    const childComps = (comp as UIVerticalMenuComponent).components.map((comp) => comp.$data);
    const { relation } = comp.value as ITreeData;
    const initTree = mapRelationWithComp(relation, childComps);
    const { tree } = createAvlTreeWithTreeComp(relation, childComps);
    const selectComp = childComps.filter((item) => {
      return item.selected === true;
    });
    const selectCompTree = selectComp.length && tree.get(selectComp[0]._id);
    if (selectCompTree) {
      this.selectIDs = this.getParentIDsByCompTree(selectCompTree);
    } else {
      this.selectIDs = [];
    }
    return initTree.map((item, index) => this.renderItem(item, 0, index));
  };

  private _paddingStyle: { paddingLeft: number; paddingRight: number; paddingTop: number; paddingBottom: number } = {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  };

  private getPaddingStyle = (): React.CSSProperties => {
    const { comp } = this.props;
    const {
      padding: { left, top, right, bottom },
    } = (comp as UIVerticalMenuComponent).parseProperties();
    const isChanged =
      left !== this._paddingStyle.paddingLeft ||
      right !== this._paddingStyle.paddingRight ||
      top !== this._paddingStyle.paddingTop ||
      bottom !== this._paddingStyle.paddingBottom;
    isChanged &&
      (this._paddingStyle = {
        paddingLeft: left,
        paddingBottom: bottom,
        paddingRight: right,
        paddingTop: top,
      });

    return this._paddingStyle;
  };

  render() {
    const { isPreview, comp } = this.props;
    const style = this.getPaddingStyle();
    return (
      <div className="lib-comp-vertical-menu" onMouseLeave={this.handleMouseLeave}>
        <CanvasPanel comp={comp} isPreview={isPreview}>
          <div className="lib-comp-vertical-menu-content" style={style} ref={this.self}>
            {this.renderVerticalMenu()}
          </div>
        </CanvasPanel>
      </div>
    );
  }
}
