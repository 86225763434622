import * as React from 'react';

import classnames from 'classnames';

import { parseColorToString } from '@utils/graphicsUtils';
import { transBlankChart } from '@utils/textUtils';

import { IconValue, ITreeDataItem } from '@fbs/rp/models/value';
import { IComponentSize } from '@/fbs/rp/models/component';
import { DefaultIconColor } from '@consts/colors';
import { textAndIconSpace, textLeftSpace } from '@/consts/defaultData/menu';
import { ITreeItem } from '@helpers/treeCompHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { hasInteraction } from '@helpers/interactionHelper';
import { UITreeItemComponent, UIVerticalMenuComponent } from '@editor/comps';

import MenuChildItem from './MenuChildItem';
import MenuText from './MenuText';

import './index.scss';

export interface IVerticalMenuItemProps {
  _id: string;
  item: ITreeItem;
  comp: UITreeItemComponent;
  verticalComp: UIVerticalMenuComponent;
  verticalMenuHoverItemID?: string;
  selectIDs?: string[];
  verticalContentRects: DOMRectList;
  padding: { top: number; bottom: number; left: number; right: number };
  lineHeight: number;
  level: number;
  index: number;
  size: IComponentSize;
  intention: number;
  checkedFill: React.CSSProperties;
  checkedTextStyle: React.CSSProperties;
  checkedIconColor: string;
  paddingStyle: React.CSSProperties;
  showExpandIcon: boolean;
  showNodeIcon: boolean;
  isPreview: boolean;
  floatPanelRects: Record<string, number>;
  defaultScale: number;
  showInteractionBackGround?: boolean;
  showMobileCursor?: boolean;
  children?: any;

  onExpandClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onCheckBoxClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseDown?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseUp?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemDoubleClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemContext?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseLeave?: (node: ITreeDataItem, isHover: boolean, e: React.MouseEvent) => void;
  onItemMouseEnter?: (node: ITreeDataItem, isHover: boolean, e: React.MouseEvent) => void;
}

let menuItemID: string;

const VerticalMenuItem: React.FC<IVerticalMenuItemProps> = (props: IVerticalMenuItemProps) => {
  const [isMainHover, setIsMainHover] = React.useState<boolean>(false);

  const handleItemClick = (e: React.MouseEvent) => {
    const { onItemClick, item, level } = props;
    if (item.children?.length) {
      if (!menuItemID) {
        return;
      }
      setIsMainHover(false);
      level === 0 && (menuItemID = '');
      return;
    }
    menuItemID = item.data.id;
    setIsMainHover(false);
    onItemClick && onItemClick(item.data, e);
  };

  const handleItemMouseDown = (e: React.MouseEvent) => {
    const { onItemMouseDown, item } = props;
    onItemMouseDown && onItemMouseDown(item.data, e);
  };

  const handleItemDoubleClick = (e: React.MouseEvent) => {
    const { onItemDoubleClick, item } = props;
    onItemDoubleClick && onItemDoubleClick(item.data, e);
  };

  const handleItemMouseUp = (e: React.MouseEvent) => {
    const { onItemMouseUp, item } = props;
    onItemMouseUp && onItemMouseUp(item.data, e);
  };

  const handleItemMouseEnter = (e: React.MouseEvent) => {
    const { onItemMouseEnter, item } = props;
    setIsMainHover(true);
    onItemMouseEnter && onItemMouseEnter(item.data, true, e);
  };

  const handleItemMouseLeave = (e: React.MouseEvent) => {
    const { onItemMouseLeave, item } = props;
    setIsMainHover(false);
    onItemMouseLeave && onItemMouseLeave(item.data, false, e);
  };

  const handleItemContext = (e: React.MouseEvent) => {
    const { onItemContext, item } = props;
    onItemContext && onItemContext(item.data, e);
  };

  const renderExtendIcon = () => {
    const { showExpandIcon, item, comp, checkedTextStyle } = props;

    if (!showExpandIcon) {
      return null;
    }
    const expandComp = comp.expandComp;
    if (!expandComp) {
      return null;
    }
    if (!item.children?.length) {
      return null;
    }
    const { size, value, properties } = expandComp;
    const icon = value as IconValue;
    const fontSize = Math.min(size.width, size.height);
    let color: string | undefined = parseColorToString(properties.icon?.color || DefaultIconColor);
    if (isMainHover) {
      color = checkedTextStyle.color;
    }
    return (
      <div
        className="comp-vertical-menu-expand"
        style={{
          width: size.width,
          minWidth: size.width,
          height: size.height,
          fontSize,
          fontFamily: icon.fontName,
          color,
          marginLeft: textAndIconSpace,
        }}
      >
        {String.fromCharCode(icon.iconCode)}
      </div>
    );
  };

  const renderNodeIcon = () => {
    const { comp, showNodeIcon, level, checkedTextStyle, selectIDs, checkedIconColor } = props;
    if (!showNodeIcon) {
      return;
    }

    if (level) {
      return;
    }

    const nodeComp = comp.nodeComp;
    if (!nodeComp) {
      return null;
    }
    const { size, value, properties } = nodeComp;
    const icon = value as IconValue;
    const fontSize = Math.min(size.width, size.height);
    let color: string | undefined = parseColorToString(properties.icon?.color || DefaultIconColor);
    if (isMainHover) {
      color = checkedTextStyle.color;
    }
    if (selectIDs && selectIDs.includes(comp.id)) {
      color = checkedIconColor;
    }
    return (
      <div
        className="comp-vertical-menu-node"
        style={{
          width: size.width,
          height: size.height,
          fontSize,
          fontFamily: icon.fontName,
          lineHeight: `${fontSize}px`,
          color,
        }}
      >
        {String.fromCharCode(icon.iconCode)}
      </div>
    );
  };

  const renderText = () => {
    const { comp, checkedTextStyle, selectIDs, level } = props;
    const { textComp } = comp;
    const { size: textSize, position, properties, value } = textComp;
    const parser = StyleHelper.createCSSStyleParser({});

    let { size: textStyleSize, style } = parser.getTextStyleData(textSize, properties.textStyle);
    if (selectIDs && selectIDs.includes(comp.id)) {
      style = { ...checkedTextStyle, lineHeight: style.lineHeight };
    }
    if (isMainHover) {
      style.color = checkedTextStyle.color;
    }
    style.width = undefined;

    const textStyle = {
      ...textStyleSize,
      ...style,
      top: position.y,
      maxWidth: '200%',
    };

    return (
      <MenuText
        value={transBlankChart(`${value}`)}
        textStyle={textStyle}
        defaultTextWidth={textSize.width}
        level={level}
      />
    );
  };

  const renderSelectBack = () => {
    const { comp, lineHeight, padding, checkedFill, level } = props;
    if (!comp.selected) {
      return null;
    }
    const backgroundStyle = {
      width: level
        ? `calc(100% + ${textAndIconSpace + textLeftSpace}px)`
        : `calc(100% + ${padding.left + padding.right}px)`,
      height: lineHeight,
      left: level ? -textLeftSpace : -padding.left,
      ...checkedFill,
    };
    return <div className="item-selected-background" style={backgroundStyle}></div>;
  };

  const renderInteractionBack = React.useMemo(() => {
    const { comp, lineHeight, padding, level, showInteractionBackGround } = props;
    if (!showInteractionBackGround || !hasInteraction(comp)) {
      return null;
    }
    const backgroundStyle = {
      width: level
        ? `calc(100% + ${textAndIconSpace + textLeftSpace}px)`
        : `calc(100% + ${padding.left + padding.right}px)`,
      left: level ? -textLeftSpace : -padding.left,
      height: lineHeight,
    };
    return <div className="item-interaction-background" style={backgroundStyle}></div>;
  }, [props.comp.interactions.length, props.showInteractionBackGround]);

  const renderChildItem = () => {
    return <MenuChildItem {...props} />;
  };

  const renderMain = () => {
    const { lineHeight, isPreview, comp, verticalMenuHoverItemID, _id, children, item } = props;
    return (
      <div
        className={classnames('item-main', {
          'item-interaction-flag': !isPreview && !item.children?.length && hasInteraction(comp),
        })}
        style={{ lineHeight: lineHeight, height: lineHeight }}
        onClick={handleItemClick}
        onMouseDown={handleItemMouseDown}
        onDoubleClick={handleItemDoubleClick}
        onMouseUp={handleItemMouseUp}
        onMouseEnter={handleItemMouseEnter}
        onMouseLeave={handleItemMouseLeave}
        onContextMenu={handleItemContext}
      >
        {renderSelectBack()}
        {renderInteractionBack}
        <div className="item-main-inner">
          <div className="item-main-inner-left">
            {renderNodeIcon()}
            {renderText()}
          </div>
          {renderExtendIcon()}
        </div>
        {(verticalMenuHoverItemID === _id || isMainHover) && children && renderChildItem()}
      </div>
    );
  };

  const render = () => {
    return (
      <div className={classnames('lib-comp-vertical-menu-item', { 'component-cursor-pointer': props.isPreview })}>
        {renderMain()}
      </div>
    );
  };
  return render();
};

export default React.memo(VerticalMenuItem);
