import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { getNewID } from '@/helpers/idHelper';

import { CStartOrEnd } from '../constants';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';
import { makeRect } from '../basic/Rect/config';

export const makeStartOrEnd = () => {
  const compData = makeRect(getNewID(), {
    name: i18n('resource.flow.start'),
    size: {
      width: 100,
      lockedRatio: false,
      height: 40,
    },
  });
  compData.properties.radius = {
    disabled: false,
    topLeft: 20,
    topRight: 20,
    bottomRight: 20,
    bottomLeft: 20,
    isPercent: false,
  };
  return compData;
};

// 开始/结束
export default {
  ...flowBase,
  name: i18n('resource.flow.start'),
  type: CStartOrEnd,
  thumb: {
    spriteIconClass: SpriteThumb.Start.className,
    dragPosition: SpriteThumb.Start.position,
  },
  template: getShapeData(
    i18n('resource.flow.start'),
    {
      width: 100,
      lockedRatio: false,
      height: 40,
    },
    {
      data: [
        {
          point: {
            x: 20,
            y: 0,
          },
          handleIn: {
            x: -12,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 80,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 12,
            y: 0,
          },
        },
        {
          point: {
            x: 100,
            y: 20,
          },
          handleIn: {
            x: 0,
            y: -12,
          },
          handleOut: {
            x: 0,
            y: 12,
          },
        },
        {
          point: {
            x: 80,
            y: 40,
          },
          handleIn: {
            x: 12,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 20,
            y: 40,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: -12,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 20,
          },
          handleIn: {
            x: 0,
            y: 12,
          },
          handleOut: {
            x: 0,
            y: -12,
          },
        },
      ],
      closed: true,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
