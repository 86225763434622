import * as React from 'react';
import ScrollView from '../ScrollView';
import classnames from 'classnames';
import Icon from '../Icon';
import i18n from '../../i18n';
import Scrollbars from 'react-custom-scrollbars';
import { isIE } from '../utils';

import './index.scss';

export interface IDialogWithFullScreenProps {
  show?: boolean;
  children?: any;
  showOk?: boolean;
  disableOk?: boolean;
  showCancel?: boolean;
  showClose?: boolean;
  okText?: string;
  cancelText?: string;
  clickModalToDismiss?: boolean;
  sureDelete?: boolean;
  escToClose?: boolean;
  contentOverflow?: 'auto' | 'hidden' | 'visible' | 'default';
  scrollView?: React.RefObject<Scrollbars>;
  onOk?(e: React.MouseEvent<HTMLButtonElement>): void;
  onCancel?(e: any): void;
}

class DialogWithFullScreen extends React.Component<IDialogWithFullScreenProps> {
  static defaultProps = {
    show: true,
    showOk: true,
    showCancel: false,
    disableOk: false,
    children: '',
    showClose: true,
    okText: i18n('Dialog.ok'),
    cancelText: i18n('Dialog.cancel'),
    clickModalToDismiss: false,
    sureDelete: false,
    contentOverflow: 'auto',
    escToClose: true,
  };

  componentDidMount(): void {
    if (this.props.escToClose) {
      window.addEventListener('keydown', this.onWindowKeyDown, true);
    }
  }

  componentWillUnmount(): void {
    if (this.props.escToClose) {
      window.removeEventListener('keydown', this.onWindowKeyDown, true);
    }
  }

  onWindowKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && this.props.onCancel) {
      this.props.onCancel(e);
    }
  };

  render() {
    let {
      showOk,
      showCancel,
      clickModalToDismiss,
      okText,
      disableOk,
      show,
      showClose,
      cancelText,
      onOk,
      onCancel,
      children,
      sureDelete,
      contentOverflow,
      scrollView,
    } = this.props;
    return (
      <div
        className={classnames({
          'dsm-dialog-full-screen': show,
          hide: !show,
          ie: isIE(),
        })}
      >
        <div className="dsm-c-bg" onClick={(e: any) => clickModalToDismiss && onCancel && onCancel(e)} />
        <div
          className={classnames('dsm-dialog-full-screen-pop')}
          style={{
            overflow: contentOverflow,
          }}
        >
          {showClose && <Icon cls="close close-icon" onClick={onCancel} size={24} />}
          {/* 内容区域 */}
          <div className={classnames('dsm-c-pop-content')}>{children}</div>
          {/* 底部按钮区域 */}
          <div className="dsm-c-button-wrap">
            {showOk && (
              <button
                className={classnames('dsm-c-buttons ok', {
                  'dsm-c-spc-button': !showCancel || !sureDelete,
                  'dsm-c-disable-button': disableOk,
                })}
                disabled={disableOk}
                onClick={onOk}
              >
                {okText || i18n('Dialog.ok')}
              </button>
            )}
            {showCancel && (
              <button
                className={classnames('dsm-c-buttons cancel', {
                  'dsm-c-spc-button': sureDelete,
                })}
                onClick={onCancel}
              >
                {cancelText}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DialogWithFullScreen;
