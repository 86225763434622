import PropertyBase from './base';
import { PureColor } from './color';

// 选中标识条;
export const MarkerStripPropertyName = 'markerStrip';

export enum MarkerStripPositionMode {
  Left,
  Right,
}

export default interface IMarkerStrip extends PropertyBase {
  color?: PureColor;
  positionMode: MarkerStripPositionMode;
  lengthRatio: number;
}
