import { cloneDeep } from 'lodash';
import {
  predefinedComponentStates,
  PredefinedStates,
  allTogglePredefinedStates,
  disabledState,
  containerPresetState,
} from '@consts/state';
import { UIComponent } from '@editor/comps';
import { getComponent } from '@libs/libs';
import { CFrame } from '@libs/constants';
import { includeEnum } from '@utils/enumUtils';
import { IComponentState } from '@/fbs/rp/models/component';

function getSupportStatesWithLib(lib: { id: string; type: string }) {
  const compData = getComponent(lib);
  if (compData) {
    const { predefinedStates } = compData;
    if (predefinedStates) {
      const result = predefinedComponentStates.filter((item) => predefinedStates.includes(item.type));
      if (!result.find((item) => item.type === PredefinedStates.disabled)) {
        result.push(disabledState);
      }
      return result;
    }
  }
  return undefined;
}
/**
 * 获取组件支持的预设状态
 * @param {UIComponent} comp
 * @returns {{type: string, name: string}[]}
 * @description 所有组件都会支持禁用状态，如果未配置，会自动在返回结果中添加
 */
export function getSupportStates(comp: UIComponent): { type: string; name: string }[] {
  // 复合组件
  if (comp.isSealed) {
    let result = [disabledState];
    // 通过配置的复合组件
    if (comp.lib) {
      result = getSupportStatesWithLib(comp.lib) || result;
    }
    return result;
  }
  // 容器组件
  if (comp.isContainer) {
    return comp.isSymbol ? [disabledState] : containerPresetState;
  }
  if (comp.type === CFrame) {
    return [];
  }
  if (comp.lib) {
    return getSupportStatesWithLib(comp.lib) || predefinedComponentStates;
  }
  // 基元组件
  return predefinedComponentStates;
}

function hasCustomStates(comp: UIComponent): boolean {
  return getCustomStates(comp).length > 0;
}

/**
 * 当前包含的状态是否支持状态交互
 */
export function allowedToggleStateCmd(comp: UIComponent): boolean {
  const hasCustomState = hasCustomStates(comp);
  const hasTogglePredefinedStates = Object.keys(comp.states).some((state) => allTogglePredefinedStates.includes(state));
  return hasCustomState || hasTogglePredefinedStates;
}

export function getCustomStates(comp: UIComponent): string[] {
  return Object.keys(comp.states).filter(
    (id) => !includeEnum(PredefinedStates, id) && (comp.states[id] as IComponentState)?.enabled !== false,
  );
}

// state共有状态属性覆盖包括共有properties属性(根据允许状态及属性)
export function getNewStateByAllowStateAndProperties(
  oldStates: {
    [name: string]: IComponentState;
  },
  newStates: {
    [name: string]: IComponentState;
  },
  allowState: { name: string; type: string; locked?: string }[],
  allowProperty: { name: string; type: string }[],
): { [name: string]: IComponentState } {
  const TEXT_STYLE = 'textStyle';
  const TEXT_FORMAT = 'textFormat';
  let result: {
    [name: string]: IComponentState;
  } = cloneDeep(newStates);
  const allowStateArray: Array<string> = [];
  const allowPropertyArray: Array<string> = [];
  allowState.forEach((item) => {
    allowStateArray.push(item.type);
  });
  allowProperty.forEach((item) => {
    allowPropertyArray.push(item.type);
  });

  result = { ...oldStates, ...result };
  // 删除不允许的state属性
  for (let stateName in result) {
    if (allowStateArray.indexOf(stateName) == -1) {
      delete result[stateName];
    }
  }
  // 删除不允许的property属性
  for (let stateName in result) {
    delete result[stateName].properties?.textFormat?.wrap;
    let itemProperties = result[stateName].properties;
    const oldProperties = oldStates[stateName]?.properties;
    itemProperties = { ...oldProperties, ...itemProperties };
    // textStyle与textFormat同时出现，覆盖掉
    if (itemProperties && itemProperties[TEXT_STYLE] && allowPropertyArray.indexOf(TEXT_FORMAT) !== -1) {
      const oldWrap = oldProperties?.textFormat?.wrap;
      if (oldProperties && oldProperties[TEXT_FORMAT]) {
        itemProperties[TEXT_FORMAT] = {
          ...oldProperties[TEXT_FORMAT],
          ...itemProperties[TEXT_STYLE],
          wrap: oldWrap ?? true,
        };
      } else {
        itemProperties[TEXT_FORMAT] = { ...itemProperties[TEXT_STYLE], wrap: oldWrap ?? true };
      }
      delete itemProperties[TEXT_STYLE];
    }
    if (itemProperties && itemProperties[TEXT_FORMAT] && allowPropertyArray.indexOf(TEXT_STYLE) !== -1) {
      delete itemProperties[TEXT_FORMAT]?.wrap;
      if (oldProperties && oldProperties[TEXT_STYLE]) {
        itemProperties[TEXT_STYLE] = { ...oldProperties[TEXT_STYLE], ...itemProperties[TEXT_FORMAT] };
      } else {
        itemProperties[TEXT_STYLE] = { ...itemProperties[TEXT_FORMAT] };
      }
      delete itemProperties[TEXT_FORMAT];
    }
    // 过滤properties的属性并合并
    for (let propertyName in itemProperties) {
      if (allowPropertyArray.indexOf(propertyName) == -1) {
        delete itemProperties[propertyName];
      } else {
        oldProperties && (itemProperties = { ...oldProperties, ...itemProperties });
      }
    }
    result[stateName].properties = itemProperties;
  }
  return result;
}
