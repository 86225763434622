import * as React from 'react';
import Icon from '../Icon';
import DialogWithInput from '../DialogWithInput';
import i18n from '../../i18n';
import './Tag.scss';

export interface ITagProp {
  value: string;
  editTitle: string;

  onRename(value: string): void;
  onDelete(): void;
}

class Tag extends React.Component<ITagProp, { isEditing: boolean; value: string; error: string }> {
  constructor(props: ITagProp) {
    super(props);
    this.state = {
      isEditing: false,
      value: props.value,
      error: '',
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(value: string) {
    if (value.length > parseInt(i18n('TagsManager.maxLength'), 10)) {
      this.setState({
        value,
        error: i18n('TagsManager.error'),
      });
    } else {
      this.setState({
        error: '',
        value,
      });
    }
  }

  onSubmit(value: string) {
    const { onRename } = this.props;
    onRename(value);
    this.setState({
      isEditing: false,
    });
  }

  render() {
    const { onDelete, editTitle } = this.props;
    const { isEditing, error } = this.state;

    return (
      <div className="dsm-c-tag">
        {this.props.value}
        <div className="actions">
          <span
            className="action"
            onClick={() => {
              this.setState({
                isEditing: true,
              });
            }}
          >
            <Icon cls="tree_edit" hidden solid tooltip={i18n('TagsManager.rename')} />
          </span>
          <span className="action" onClick={onDelete}>
            <Icon cls="demo_delete" hidden tooltip={i18n('TagsManager.delete')} />
          </span>
        </div>
        {isEditing && (
          <DialogWithInput
            showClose
            title={editTitle}
            value={this.props.value}
            onCancel={() => {
              this.setState({
                isEditing: false,
              });
            }}
            onChange={this.onChange}
            onOk={this.onSubmit}
            placeholder={i18n('TagsManager.placeholder', editTitle)}
            errorMsg={error}
          />
        )}
      </div>
    );
  }
}

export default Tag;
