import { ILibF, IResourceItem, ResourceType } from '@fbs/rp/models/ds/lib';
import IComponent, { IRelation } from '@fbs/rp/models/ds/component';
import IColor from '@fbs/rp/models/ds/color';
import ITypography from '@fbs/rp/models/ds/typography';
import { IDSGroup } from '@fbs/rp/models/ds/group';

import { ResourceAction } from './actions';
import { ActionType } from '../types';

enum ResourceState {
  access = 0, // 正常资源
  delete = 4, // 被删除的资源
}

export interface IResourceState {
  libs: ILibF[];
  currentLib?: ILibF;
  isFail?: boolean;
}

const initialState: IResourceState = {
  libs: [],
};

function replaceLib(libs: ILibF[], lib: ILibF) {
  const index = libs.findIndex((item) => item._id === lib._id);
  if (index !== -1) {
    libs.splice(index, 1, lib);
  }
  return [...libs];
}

/**
 * 将资源中被删除的资源单独抽离出来
 * @param lib
 */
function getLibraryWithRecyleComp(lib: ILibF) {
  const componentGroups = lib.components;
  const componentsRecycle = componentGroups.find((ite) => ite.state === ResourceState.delete)?.children || [];
  const components = componentGroups.filter((c) => c.state === ResourceState.access);
  return {
    ...lib,
    components,
    componentsRecycle,
  };
}

function getNewRecycleComponents(oldComps: IComponent[] | undefined, ...comps: IComponent[]) {
  return [...(oldComps || []), ...comps];
}

export default function (state = initialState, action: ResourceAction): IResourceState {
  const { currentLib, libs } = state;
  switch (action.type) {
    case ActionType.Lib_LoadLib:
      return {
        ...state,
        isFail: false,
        currentLib: getLibraryWithRecyleComp(action.payload),
      };
    case ActionType.Lib_LoadLibs:
      return {
        ...state,
        libs: action.payload.map((lib) => getLibraryWithRecyleComp(lib)),
        isFail: false,
      };
    case ActionType.Lib_LoadError:
      return {
        ...state,
        isFail: true,
      };
    case ActionType.Lib_UpdateLib: {
      const newData = { ...currentLib! };
      const { name, visibility } = action.payload;
      if (typeof name === 'string') {
        newData.name = name;
      }
      if (typeof visibility === 'string') {
        newData.visibility = visibility;
      }
      return {
        ...state,
        currentLib: newData,
        libs: replaceLib(libs, newData),
      };
    }
    case ActionType.Lib_AddLib:
      libs.push(action.payload);
      return {
        ...state,
        currentLib: action.payload,
        libs: [...libs],
      };
    case ActionType.Lib_SwitchLib: {
      const lib = libs.find((item) => item._id === action.payload.libID);
      return {
        ...state,
        currentLib: lib || currentLib,
      };
    }
    case ActionType.Lib_AddColor: {
      let newData: ILibF = { ...currentLib! };
      const newColors: IColor[] = [...(newData.colors || [])];
      if (Array.isArray(action.payload)) {
        newColors.unshift(...action.payload);
      } else {
        newColors.unshift(action.payload);
      }
      newData.colors = newColors;

      return {
        ...state,
        currentLib: newData,
        libs: replaceLib(libs, newData),
      };
    }
    case ActionType.Lib_AddComponent: {
      const comps = [...currentLib!.components];
      const groupIndex = comps.findIndex((g) => g._id === action.payload.groupID)!;
      if (groupIndex !== -1) {
        const group = { ...comps[groupIndex] };
        group.children = [action.payload, ...(group.children || [])] || [];
        comps.splice(groupIndex, 1, group);
      }
      currentLib!.components = comps;
      return {
        ...state,
        currentLib: { ...currentLib! },
        libs: replaceLib(libs, currentLib!),
      };
    }
    case ActionType.Lib_AddTypography: {
      let newData: ILibF = { ...currentLib! };
      newData.typographies = [action.payload, ...(newData.typographies || [])];
      return {
        ...state,
        currentLib: newData,
        libs: replaceLib(libs, newData),
      };
    }
    case ActionType.Lib_RemoveLibItem: {
      const { type, res } = action.payload;
      const resID = res._id;
      switch (type) {
        case ResourceType.component: {
          const compRes = res as IComponent;
          const groupID = compRes.groupID;
          const components = [...currentLib!.components];
          const groupIndex = components.findIndex((item) => item._id === groupID)!;
          const group = { ...components[groupIndex] };
          const children = [...(group.children || [])];
          const compIndex = children.findIndex((item) => item._id === resID);
          const deleteComponent = children.splice(compIndex, 1)[0];
          group.children = children;
          components.splice(groupIndex, 1, group);
          currentLib!.components = components;
          // FIXME 将老数据加入到组件回收站中
          deleteComponent.state = ResourceState.delete;
          currentLib!.componentsRecycle = getNewRecycleComponents(currentLib?.componentsRecycle, deleteComponent);
          break;
        }
        case ResourceType.typography: {
          const typographies = [...currentLib!.typographies];
          const idx = typographies.findIndex((t) => t._id === resID);
          typographies.splice(idx, 1);
          currentLib!.typographies = typographies;
          break;
        }
        case ResourceType.color: {
          const colors = [...currentLib!.colors];
          const idx = colors.findIndex((c) => c._id === resID);
          colors.splice(idx, 1);
          currentLib!.colors = colors;
          break;
        }
        default:
          break;
      }
      return {
        ...state,
        currentLib: { ...currentLib! },
        libs: replaceLib(libs, currentLib!),
      };
    }
    case ActionType.Lib_PatchColorItem: {
      const colors = [...currentLib!.colors];
      const index = colors.findIndex((item) => item._id === action.payload._id);
      colors.splice(index, 1, action.payload);
      currentLib!.colors = colors;
      return {
        ...state,
        currentLib: { ...currentLib! },
        libs: replaceLib(libs, { ...currentLib! }),
      };
    }
    case ActionType.Lib_PatchTypographyItem: {
      const typographies = [...currentLib!.typographies];
      const index = typographies.findIndex((item) => item._id === action.payload._id);
      typographies.splice(index, 1, action.payload);
      currentLib!.typographies = typographies;
      return {
        ...state,
        currentLib: { ...currentLib! },
        libs: replaceLib(libs, { ...currentLib! }),
      };
    }
    case ActionType.Lib_PatchComponentItem: {
      const { _id, libID, groupID } = action.payload;
      const lib = libs.find((item) => item._id === libID);
      if (lib) {
        const components = [...lib.components];
        const group = components.find((item) => item._id === groupID)!;
        group.children = group.children || [];
        const index = group.children.findIndex((item) => item._id === _id);
        // const index = components.findIndex(item => item._id === _id);
        // components.splice(index, 1, action.payload);
        group.children.splice(index, 1, action.payload);
        lib.components = components;
        if (currentLib && lib._id === currentLib._id) {
          currentLib.components = components;
        }
        return {
          ...state,
          currentLib: { ...currentLib! },
          libs: replaceLib(libs, lib),
        };
      }
      return state;
    }
    case ActionType.Lib_ResourceItemIndexChanged: {
      const { type, res, newIndex } = action.payload;
      let items: IResourceItem[] | undefined;
      const resID = res._id;
      switch (type) {
        case ResourceType.color:
          items = [...currentLib!.colors];
          break;
        case ResourceType.component: {
          const compRes = res as IComponent;
          const group = currentLib!.components.find((g) => g._id === compRes.groupID)!;
          items = [...(group.children || [])];
          break;
        }
        case ResourceType.typography:
          items = [...currentLib!.typographies];
          break;
      }
      if (items) {
        const oldIndex = items.findIndex((item) => item._id === resID);
        let index = newIndex;
        if (index > oldIndex) {
          index -= 1;
        }
        const res = items[oldIndex];
        items.splice(oldIndex, 1);
        items.splice(index, 0, res);
        items.forEach((item, i) => (item.index = i));

        switch (type) {
          case ResourceType.typography:
            currentLib!.typographies = items as ITypography[];
            break;
          case ResourceType.component: {
            const groupID = (res as IComponent).groupID;
            const comps = currentLib!.components;
            const groupIndex = comps.findIndex((g) => g._id === groupID)!;
            const group = comps[groupIndex];
            group.children = items as IComponent[];
            comps.splice(groupIndex, 1, group);
            currentLib!.components = [...comps];
            // currentLib!.components = items as IComponent[];
            break;
          }
          case ResourceType.color:
            currentLib!.colors = items as IColor[];
            break;
          default:
            break;
        }
      }
      return {
        ...state,
        currentLib: { ...currentLib! },
        libs: replaceLib(libs, { ...currentLib! }),
      };
    }
    case ActionType.Lib_AddGroup: {
      const lib = { ...currentLib! };
      lib.components = [...lib.components!, action.payload];
      return {
        ...state,
        libs: lib ? replaceLib(libs, lib) : libs,
        currentLib: lib,
      };
    }
    case ActionType.Lib_RenameGroup: {
      const lib = { ...currentLib! };
      const index = lib.components.findIndex((g) => g._id === action.payload._id);
      const group = { ...action.payload };
      if (index !== -1) {
        group.children = lib.components[index]?.children || [];
        lib.components.splice(index, 1, group);
      }
      lib.components = [...lib.components];
      return {
        ...state,
        libs: lib ? replaceLib(libs, lib) : libs,
        currentLib: lib,
      };
    }
    case ActionType.Lib_RemoveGroup: {
      const { groupID } = action.payload;
      const lib = { ...currentLib! };
      const index = lib.components.findIndex((item) => item._id === groupID);
      const components = [...lib.components.map((c) => ({ ...c }))];
      const deleteGroup = components.splice(index, 1)[0];
      lib.components = components;
      deleteGroup.children = deleteGroup.children.map((c) => ({ ...c, state: ResourceState.delete }));
      lib.componentsRecycle = getNewRecycleComponents(lib.componentsRecycle, ...deleteGroup.children);
      return {
        ...state,
        currentLib: lib,
        libs: replaceLib(libs, lib),
      };
    }
    case ActionType.Lib_MoveGroup: {
      const lib = { ...currentLib! };
      const groups = [...lib.components];
      const { groupID, index } = action.payload;
      const oldIndex = groups.findIndex((item) => item._id === groupID);
      const group = groups[oldIndex];
      groups.splice(oldIndex, 1);
      groups.splice(index, 0, group);
      lib.components = groups;

      return {
        ...state,
        currentLib: lib,
        libs: replaceLib(libs, lib),
      };
    }
    case ActionType.Lib_ResetGroup: {
      const { groupID, components } = action.payload;
      const first = components[0];
      const originalGroupId = first.groupID;
      const groups = [...currentLib!.components];
      const originalGroup = groups.find((g) => g._id === originalGroupId)!;
      const targetGroup = groups.find((g) => g._id === groupID)!;
      const len = originalGroup.children.length;
      for (let i = len - 1; i >= 0; i--) {
        const comp = originalGroup.children[i];
        if (components.includes(comp)) {
          originalGroup.children.splice(i, 1);
        }
      }
      originalGroup.children = [...originalGroup.children];
      components.forEach((comp) => {
        comp.groupID = groupID;
      });
      targetGroup.children = [...(targetGroup.children || []), ...components];
      currentLib!.components = groups;
      return {
        ...state,
        currentLib: { ...currentLib! },
        libs: replaceLib(libs, currentLib!),
      };
    }
    case ActionType.Lib_RemoveAfterMoveGroup: {
      const lib = currentLib!;
      const { removeGroupID, targetGroupID, comps } = action.payload;
      const newComponents = comps.map((c) => ({ ...c, groupID: targetGroupID }));
      lib.components.forEach((g) => {
        if (g._id === targetGroupID) {
          g.children = g.children?.concat(newComponents) || newComponents;
        }
      });
      const removeIndex = lib.components.map((g) => g._id).indexOf(removeGroupID);
      lib.components.splice(removeIndex, 1)[0];
      lib.components = [...lib.components];

      return {
        ...state,
        currentLib: lib,
        libs: replaceLib(libs, lib),
      };
    }
    case ActionType.Lib_RemoveComponents: {
      const { componentIDs } = action.payload;
      const lib = currentLib!;
      // 批量删除后 同时生成回收站组件和删除后的组件
      const { componentsRecycle, groups } = lib.components.reduce(
        (prev, group) => {
          const { newComps, deleteComps } = group.children.reduce(
            ({ newComps, deleteComps }, comp) => {
              const withInDeleteIDs = componentIDs.includes(comp._id);
              withInDeleteIDs ? deleteComps.push({ ...comp, state: ResourceState.delete }) : newComps.push(comp);
              return { newComps, deleteComps };
            },
            { deleteComps: [] as IComponent[], newComps: [] as IComponent[] },
          );
          group.children = newComps;
          prev.groups.push(group);
          prev.componentsRecycle.push(...deleteComps);
          return prev;
        },
        { groups: [] as IDSGroup[], componentsRecycle: [] as IComponent[] },
      );
      lib.components = groups;
      lib.componentsRecycle = getNewRecycleComponents(lib.componentsRecycle, ...componentsRecycle);
      return {
        ...state,
        currentLib: lib,
        libs: replaceLib(libs, lib),
      };
    }
    case ActionType.Lib_AddRelations: {
      const { libID, componentID, data } = action.payload;
      const libraries = updateRelationsFromLibs(componentID, {
        type: 'add',
        libs,
        libID,
        data,
      });
      return {
        ...state,
        libs: libraries,
      };
    }
    case ActionType.Lib_PatchRelations: {
      const { libID, componentID, data } = action.payload;
      const libraries = updateRelationsFromLibs(componentID, {
        data,
        libID,
        type: 'patch',
        libs,
      });
      return {
        ...state,
        libs: libraries,
      };
    }
    case ActionType.Lib_RemoveRelations: {
      const { libID, componentID, relationIDs } = action.payload;
      const libraries = updateRelationsFromLibs(componentID, {
        libID,
        removeIDs: relationIDs,
        libs,
        type: 'remove',
      });
      return {
        ...state,
        libs: libraries,
      };
    }
    default:
      return state;
  }
}

function updateRelationsFromLibs(
  componentID: string,
  options: {
    removeIDs?: string[];
    data?: IRelation[];
    libs: ILibF[];
    libID: string;
    type: 'add' | 'remove' | 'patch';
  },
) {
  const { data, removeIDs, libs, type, libID } = options;
  return libs.reduce((prev, lib) => {
    const { _id, components } = lib;
    if (libID === _id) {
      lib.components = updateRelationsFromGroups(componentID, {
        type,
        groups: components,
        removeIDs,
        data,
      });
    }
    prev.push(lib);
    return prev;
  }, [] as ILibF[]);
}
function updateRelationsFromGroups(
  componentID: string,
  options: {
    removeIDs?: string[];
    data?: IRelation[];
    groups: IDSGroup[];
    type: 'add' | 'remove' | 'patch';
  },
) {
  const { groups, type, removeIDs, data } = options;
  return groups.reduce((groups, group) => {
    switch (type) {
      case 'add':
        group.children = addRelationsFromComponents(data!, { componentID, components: group.children });
        break;
      case 'patch':
        group.children = patchRelationsFromComponents(data!, { components: group.children, componentID });
        break;
      case 'remove':
        group.children = removeRelationsFromComponents(removeIDs!, { components: group.children, componentID });
        break;
      default:
        break;
    }
    groups.push(group);
    return groups;
  }, [] as IDSGroup[]);
}

function addRelationsFromComponents(
  data: IRelation[],
  options: {
    components: IComponent[];
    componentID: string;
  },
) {
  const { componentID, components } = options;
  return components.reduce((comps, comp) => {
    const { relations, _id } = comp;
    if (_id === componentID) {
      comp.relations = [...(relations || []), ...data];
    }
    comps.push(comp);
    return comps;
  }, [] as IComponent[]);
}

function patchRelationsFromComponents(
  data: IRelation[],
  options: {
    components: IComponent[];
    componentID: string;
  },
) {
  const { componentID, components } = options;
  return components.reduce((comps, comp) => {
    const { relations, _id } = comp;
    if (_id === componentID) {
      comp.relations = relations?.reduce((rels, relation) => {
        const { _id } = relation;
        const newRelation = data.find((ite) => ite._id === _id);
        rels.push(newRelation || relation);
        return rels;
      }, [] as IRelation[]);
    }
    comps.push(comp);
    return comps;
  }, [] as IComponent[]);
}

function removeRelationsFromComponents(
  relationIDs: string[],
  options: {
    components: IComponent[];
    componentID: string;
  },
) {
  const { componentID, components } = options;
  return components.reduce((comps, comp) => {
    const { relations, _id } = comp;
    if (_id === componentID) {
      comp.relations = relations?.filter((rel) => !relationIDs.includes(rel._id));
    }
    comps.push(comp);
    return comps;
  }, [] as IComponent[]);
}
