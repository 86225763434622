import * as React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';

import { round } from '@utils/globalUtils';
import { ScrollBars } from '@dsm';

import { IComponentData } from '@fbs/rp/models/component';

import { UIComponent, UIContainerComponent } from '@editor/comps';
import { StyleHelper } from '@helpers/styleHelper';

import { makeCommonComponent } from '../../helper';
import { CListLayoutPanel } from '../../constants';
import Background from '../../basic/common/Background';

import './index.scss';

interface IListLayoutPanelProps {
  comp: UIComponent;

  [key: string]: any;
}

export function makeListLayoutPanel(id: string, data?: Partial<IComponentData>): IComponentData {
  if (data) {
    return makeCommonComponent(id, CListLayoutPanel, data);
  }
  return makeCommonComponent(id, CListLayoutPanel, {
    size: {
      width: 100,
      height: 300,
    },
    properties: {
      layout: {
        direction: 'vertical',
        verticalAlign: 'top',
        horizontalAlign: 'center',
        horizontalGap: 0,
        verticalGap: 0,
      },
      container: {
        scroll: true,
        showScroll: true,
      },
      cell: {
        rowHeight: 30,
        ratioWidth: true,
      },
    },
    components: [],
  });
}

export default class ListLayoutPanel extends React.Component<IListLayoutPanelProps> {
  renderChild() {
    const { comp } = this.props;
    const { layout, padding } = comp.properties;
    const { left, top, right, bottom } = padding || { left: 0, top: 0, right: 0, bottom: 0 };
    const { width, height } = comp.size;
    const { direction, verticalAlign, horizontalAlign } = layout || {
      direction: 'vertical',
      verticalAlign: 'top',
      horizontalAlign: 'center',
    };
    return (
      <div
        className={classnames('lib-comp-list-panel-content', direction, verticalAlign, horizontalAlign)}
        style={{
          width: width - (left || 0) - (right || 0),
          height: height - (top || 0) - (bottom || 0),
        }}
      >
        {this.props.children}
        {this.renderSeparator()}
      </div>
    );
  }

  renderPreview() {
    const { comp } = this.props;
    const { properties } = comp;
    const { layout, container } = properties;
    const { direction } = layout || {
      direction: 'vertical',
      verticalAlign: 'top',
      horizontalAlign: 'center',
    };

    let showScrollBar = false;
    let hiddenScrollBar = true;
    if (container) {
      showScrollBar = !!container.scroll;
      hiddenScrollBar = !container.showScroll;
    }
    const { left, top, right, bottom } = comp.padding;
    const style: React.CSSProperties = {
      left,
      top,
      right,
      bottom,
      position: 'absolute',
      boxSizing: 'border-box',
      overflow: 'hidden',
    };
    return (
      <div style={style}>
        {!showScrollBar && this.renderChild()}
        {showScrollBar && (
          <ScrollBars
            autoHide
            thumbClassName="lib-comp-list-scroll-thumb"
            hiddenVerticalScrollBar={hiddenScrollBar || direction !== 'vertical'}
            hiddenHorizontalScrollBar={hiddenScrollBar || direction !== 'horizontal'}
          >
            {this.renderChild()}
          </ScrollBars>
        )}
      </div>
    );
  }

  renderEditing() {
    const { properties } = this.props.comp;
    const { isContainerActiveContainer } = this.props;
    const { padding, layout } = properties;
    const style: React.CSSProperties = {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      overflow: isContainerActiveContainer ? 'visible' : 'hidden',
    };
    if (!isContainerActiveContainer && padding && !padding.disabled) {
      if (layout?.direction === 'vertical') {
        style.bottom = padding.bottom || 0;
      } else {
        style.right = padding.right || 0;
      }
    }
    return (
      <div style={style} className="lib-comp-list-panel-content">
        {this.props.children}
        {this.renderSeparator()}
      </div>
    );
  }

  renderSeparator() {
    const { comp, isPreview } = this.props;
    const { separator, layout, padding, separatorRatio } = comp.properties;
    const { components } = comp as UIContainerComponent;
    const { width, height } = comp.size;
    if (separator && !separator.disabled && !separator.prop) {
      const direction = layout?.direction || 'vertical';
      const isVertical = direction === 'vertical';
      const { left, top, right, bottom } = padding || { left: 0, top: 0, right: 0, bottom: 0 };
      const _left = isPreview ? 0 : left || 0;
      const _top = isPreview ? 0 : top || 0;
      const _right = isPreview ? 0 : right || 0;
      const _bottom = isPreview ? 0 : bottom || 0;
      const { strokeWidth, strokeLinejoin, strokeDasharray, strokeLinecap, stroke } = StyleHelper.parseSVGStroke(
        separator,
      );

      const ratio = separatorRatio?.value.value ?? 100;
      let dataStr: string[] = [];
      const len = components.length;
      const w = width - _right - _left;
      const h = height - _bottom - _top;
      const lineSize = round(((isVertical ? w : h) * ratio) / 100);
      const lineStart = ((isVertical ? w : h) - lineSize) / 2 + (isVertical ? _left : _top);
      for (let i = 0; i < len - 1; i++) {
        const comp = components[i];
        const {
          position: { x: cx, y: cy },
          size: { width: cw, height: ch },
        } = comp;
        const y = cy + ch - (((strokeWidth || 0) / 2) % 1);
        const x = cx + cw - (((strokeWidth || 0) / 2) % 1);
        const x1 = isVertical ? lineStart : x;
        const y1 = isVertical ? y : lineStart;
        const x2 = isVertical ? lineStart + lineSize : x;
        const y2 = isVertical ? y : lineStart + lineSize;
        dataStr.push(`M${x1} ${y1}, L${x2} ${y2}`);
      }
      const d = dataStr.join(' ');
      return (
        <svg
          className="lib-comp-list-separator-content"
          style={{
            zIndex: components.length,
            overflow: 'visible',
            width: '100%',
            height: '100%',
          }}
        >
          <path
            key={d}
            d={d}
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            strokeDasharray={strokeDasharray}
          />
        </svg>
      );
    }
    return null;
  }

  render() {
    const { comp, isPreview, isContainerActiveContainer } = this.props;
    const { properties, size, opacity } = comp;
    const { layout, stroke, fill, radius } = properties;
    const { direction, verticalAlign, horizontalAlign } = layout || {
      direction: 'vertical',
      verticalAlign: 'top',
      horizontalAlign: 'center',
    };
    const transition = comp.getTransition();
    const style: React.CSSProperties = {
      width: size.width,
      height: size.height,
      overflow: isContainerActiveContainer ? 'visible' : 'hidden',
      pointerEvents: isPreview && !comp.hidden && !comp.disabled ? 'all' : 'none',
      opacity: isUndefined(comp.opacity) ? 1 : comp.opacity / 100,
      transition,
      ...StyleHelper.initCSSStyleParser(properties).getRadiusStyle(size),
    };
    const isTransparent = opacity === 0;
    return (
      <div
        style={style}
        className={classnames(`lib-comp-list-layout-panel`, verticalAlign, horizontalAlign, {
          vertical: direction === 'vertical',
          horizontal: direction === 'horizontal',
        })}
      >
        {!properties.fill?.disabled && !isTransparent && (
          <Background transition={transition} size={size} properties={{ fill, radius }} />
        )}
        {!isPreview && this.renderEditing()}
        {isPreview && this.renderPreview()}
        {!properties.stroke?.disabled && !isTransparent && (
          <Background
            size={size}
            transition={transition}
            properties={{ stroke, radius }}
            zIndex={(comp as UIContainerComponent).components.length}
          />
        )}
      </div>
    );
  }
}
