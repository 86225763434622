import * as React from 'react';

import { max } from '@utils/globalUtils';
import { parseColorToString } from '@utils/graphicsUtils';

import { IBounds } from '@fbs/common/models/common';
import { InputModel } from '@fbs/rp/models/properties/inputModel';
import { ITableValue } from '@fbs/rp/models/table';
import { TextAlign } from '@fbs/rp/models/properties/text';
import { IComponentValue, ISnapshotValue } from '@fbs/rp/models/value';

import ValueEditorType from '@consts/enums/valueEditorType';
import { DefaultFontSize, FontBoxScale } from '@consts/fonts';

import { getTextCompInSealed } from '@helpers/componentHelper';
import { changeFocus } from '@helpers/documentHelper';
import { upgradeTextProperValue } from '@helpers/propertiesHelper';
import { StyleHelper } from '@helpers/styleHelper';

import { MouseButton } from '@consts/enums/mouseButton';

import {
  CTree,
  CNavigationMenu,
  CVerticalMenu,
  CHorizontalMenu,
  CCarouselChart,
  CQRCode,
  CTextArea,
  CInput,
  CPieChart,
  CDoughnutChart,
  CCollapse,
} from '@libs/constants';
import { getComponent, getComponentSupportValueEditorType, getComponentValueEditorInfo } from '@libs/libs';
import { getListItemsValue } from '@libs/valueEditHelper';
import {
  UIComponent,
  UIContainerComponent,
  UITreeComponent,
  UITableComponent,
  UINavigationMenuComponent,
  UIHorizontalMenuComponent,
  UIVerticalMenuComponent,
} from '@editor/comps';

import EditorContext from '@contexts/editor';

import MapChartEdit from '@/libs/basic/common/ChartEditor/MapChartEdit';
import RichTextEditor from '../../../../../libs/basic/common/RichTextEditor'; // './RichTextEditor';
import ChartEditor from '../../../../../libs/basic/common/ChartEditor';
import CombinationChartEdit from '../../../../../libs/basic/common/ChartEditor/CombinationChartEdit';
import BooleanEditor from './BooleanEditor';
import ItemValueEditor from './ItemValueEditor';
import HOCPureTextEditor, { PureTextEditor } from './PureTextEditor';
import SelectorEditor from './SelectorEditor';
import SnapshotEditor from './SnapshotEditor';
import TreeEditor from './TreeEditor';
import MenuEditor from './MenuEditor';
import CarouselChartEditor from './CarouselChartEditor';
import AudioVideoUrlEditor from './AudioVideoUrlEditor';

import './index.scss';

export interface IValueEditorProp {
  component: UIComponent;
  bounds: IBounds;
  textAlign?: TextAlign; //编辑中文本的位置
  fontSize?: number; //编辑中文本的大小
  onOpenIconLibrariesPanel?: Function;
  useInTable?: boolean;
  triggerCloseContainer?: HTMLElement;
  onTyped?: () => void;
  onChange: (value: IComponentValue) => void;
  onSelect: (url: string, imgSize?: { height: number; width: number }) => void;
  onSelectSnapshot: (updateInfo: ISnapshotValue, imgSize?: { height: number; width: number }, compID?: string) => void;
  onExit: () => void;
  onWaiting: (state: boolean) => void;
}

export interface IValueEditorState {}

class ValueEditor extends React.Component<IValueEditorProp, IValueEditorState> {
  static defaultProps: Partial<IValueEditorProp> = {
    textAlign: TextAlign.left,
  };
  static contextType = EditorContext;
  // context!: React.ContextType<typeof EditorContext>;

  selfRef: React.RefObject<HTMLDivElement>;
  richTextEditor: React.RefObject<RichTextEditor> = React.createRef();
  pureTextEditor: React.RefObject<any> = React.createRef();

  constructor(props: IValueEditorProp) {
    super(props);
    this.state = {};
    this.selfRef = React.createRef();
  }

  componentWillUnmount() {
    switch (this.valueType) {
      case ValueEditorType.ItemValue:
      case ValueEditorType.Tree:
      case ValueEditorType.Chart:
      case ValueEditorType.Menu:
        this.props.onExit();
        break;

      default:
        break;
    }
  }

  get valueType() {
    const { component } = this.props;
    let valueType: ValueEditorType | undefined;
    if (component.isContainer && component.lib) {
      const libData = getComponent(component.lib);
      if (libData) {
        if (libData.type === CTree || libData.type === CCollapse) {
          valueType = ValueEditorType.Tree;
        } else if (
          libData.type === CNavigationMenu ||
          libData.type === CVerticalMenu ||
          libData.type === CHorizontalMenu
        ) {
          valueType = ValueEditorType.Menu;
        } else if (libData.type === CCarouselChart) {
          valueType = ValueEditorType.CarouselChart;
        } else if (libData.isList) {
          valueType = ValueEditorType.ItemValue;
        } else if (libData.isTextComp) {
          if (libData.value) {
            valueType = libData.value.type;
          } else {
            valueType = ValueEditorType.PureText;
          }
        }
      }
      // if (libData) {
      //   if (libData.isList && libData.type !== 'tree') {
      //     valueType = ValueEditorType.ItemValue;
      //   } else if (libData.isTextComp) {
      //     if (libData.value) {
      //       valueType = libData.value.type;
      //     } else {
      //       valueType = ValueEditorType.PureText;
      //     }
      //   } else if (libData.type === 'tree') {
      //     valueType = ValueEditorType.Tree;
      //   }
      // }
    } else {
      valueType = getComponentSupportValueEditorType(component.type, component.lib);
    }
    return valueType;
  }

  get value() {
    const { component } = this.props;
    let value = component.value || component.text;
    if (component.isContainer && component.lib) {
      const libData = getComponent(component.lib);
      if (libData && libData.isTextComp && libData.value?.getValue) {
        value = libData.value.getValue(component);
      }
    }
    return value;
  }

  get focus() {
    const richFocus = !!this.richTextEditor.current?.focused;
    const pureFocus = !!(this.pureTextEditor.current?.domRef.current as HTMLDivElement | undefined)?.contains(
      document.activeElement,
    );
    return richFocus || pureFocus;
  }

  get inputDom(): HTMLDivElement | HTMLInputElement | HTMLTextAreaElement | undefined {
    const richInputDom = this.richTextEditor.current?.inputRef.current ?? undefined;
    const pureInputDom =
      (this.pureTextEditor.current?.wrappedRef?.current as PureTextEditor | undefined)?.inputDom ?? undefined;
    return richInputDom ?? pureInputDom;
  }
  // FIXME: 更新输入组件

  reloadInputValue = () => {
    const richEditor = this.richTextEditor.current;
    const pureEditor = this.pureTextEditor.current?.wrappedRef?.current as PureTextEditor | undefined;
    if (richEditor) {
      richEditor.reloadValue();
    }
    if (pureEditor) {
      pureEditor.reloadValue();
    }
  };

  doTextEditorExit = () => {
    const richEditor = this.richTextEditor.current;
    // FIXME: 纯文本提交
    const pureEditor = this.pureTextEditor.current;
    if (richEditor) {
      richEditor.cancel();
    }
    if (pureEditor) {
      changeFocus();
    }
  };

  handleRichEditorChange = (value: string) => {
    const { onChange, onExit } = this.props;
    onChange(value);
    onExit();
  };

  handleRichContextMenu = (e: React.MouseEvent | MouseEvent): void => {
    // 阻止表格右键菜单
    if (this.props.useInTable) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  handleRichMouseDown = (e: React.MouseEvent | MouseEvent): void => {
    // 阻止表格右键mouseDown
    if (e.button === MouseButton.Right && this.props.useInTable) {
      e.stopPropagation();
    }
  };

  handleRichMouseUp = (): void => {
    // 焦点还原
    this.richTextEditor.current?.focus();
  };

  renderRichTextEditor = (value: string) => {
    const {
      component,
      bounds: { left, top, width, height },
      useInTable,
      onTyped,
    } = this.props;
    const { properties } = component;
    const textFormatEx = upgradeTextProperValue(properties.textFormat);
    const fontSize = textFormatEx?.fontSize || 14;
    const lineHeightEx = textFormatEx?.lineHeightEx || 20;
    const parser = StyleHelper.initCSSStyleParser(properties);
    const style: React.CSSProperties = {
      ...parser.getTextStyle(),
    };
    style.fontSize = fontSize / FontBoxScale;
    if (lineHeightEx) {
      style.lineHeight = lineHeightEx / FontBoxScale + 'px';
    }
    const bgStyle: React.CSSProperties = {};
    if (component.parent instanceof UITableComponent) {
      const { row, column } = component.toJSON();
      const { value } = component.parent;
      const tableValue = value as ITableValue;
      const cell = tableValue.cells[row!][column!];
      const fill = cell?.style.fill;
      fill && (bgStyle.background = parseColorToString(fill));
    }

    return (
      <div
        className="value-editor rich-editor"
        style={{
          ...bgStyle,
          width: width * 2,
          minHeight: height * 2,
          left,
          top,
          transform: `scale(${FontBoxScale})`,
          transformOrigin: 'left top',
        }}
        onContextMenu={this.handleRichContextMenu}
        onMouseDown={this.handleRichMouseDown}
        onMouseUp={this.handleRichMouseUp}
      >
        <RichTextEditor
          ref={this.richTextEditor}
          ownerComp={component}
          align="left"
          onValueChanged={this.handleRichEditorChange}
          onSelectChanged={() => {}}
          style={style}
          className=""
          value={value}
          useInTable={useInTable}
          onTyped={onTyped}
        />
      </div>
    );
  };

  computedPureTextEditor = () => {
    const { component, bounds } = this.props;
    // 非容器组件时，需要通过纯文本编辑Value的情况
    if (!component.isContainer) {
      return {
        position: { left: bounds.left, top: bounds.top },
        size: { width: 400, height: 30 },
        fontSize: DefaultFontSize,
        textAlign: TextAlign.left,
      };
    }
    const comp = component as UIContainerComponent;
    //获取内部文本编辑框，因为是纯文本的组件，里面应该只有一个文本编辑框
    const innerText = comp.components.find((comp) => comp.type === 'text');

    //获取文本的fontSize
    const fontSize = comp.fontSize;

    //获取文本的对齐方式
    const textAlign = component.isSealed ? TextAlign.left : comp.textAlign;

    //计算文本框的大小
    const textSize = (innerText && innerText.size) || { width: 10, height: 10 };
    const width = component.isSealed ? 300 : textSize.width;
    const height = component.isSealed ? 30 : textSize.height;
    const size = { width, height };

    //计算文本的位置
    const textPosition = (innerText && innerText.position) || { x: 0, y: 0 };
    const left = textPosition.x < 0 ? bounds.left : bounds.left + textPosition.x;
    const top = textPosition.y < 0 ? bounds.top : bounds.top + (bounds.height - textSize.height) / 2;
    const position = { left: component.isSealed ? bounds.left : left, top: component.isSealed ? bounds.top : top };

    return { position, size, fontSize, textAlign };
  };

  renderPureTextEditor = () => {
    const { component, onTyped } = this.props;

    let comp = component;
    if (component.isSealed) {
      const textComp = getTextCompInSealed(component as UIContainerComponent);
      if (textComp) {
        comp = textComp;
      }
    }

    const { value, properties, type } = comp;
    const { multiText, inputModel } = properties;
    let v: string | number;
    let valueType: 'text' | 'number' | undefined = 'text';
    if (inputModel && inputModel.value === InputModel.numeric) {
      v = value as string;
      valueType = 'number';
    } else {
      if (typeof value === 'number') {
        v = value;
      } else {
        v = value as string;
      }
    }
    if (component.isSealed && !v && component.value) {
      v = component.value as string;
    }
    let maxlength = 300;
    if (type === CQRCode) {
      maxlength = 500;
    }

    const { position, size, fontSize, textAlign } = this.computedPureTextEditor();
    return (
      <HOCPureTextEditor
        ref={this.pureTextEditor}
        hocRef={this.pureTextEditor}
        position={position}
        size={size}
        textAlign={textAlign as TextAlign}
        fontSize={fontSize}
        maxlength={maxlength}
        applyStyle={comp.componentType === CTextArea || comp.componentType === CInput}
        value={v}
        valueType={valueType}
        wrap={multiText ? multiText.wrap : false}
        onChange={this.props.onChange}
        onClose={this.props.onExit}
        triggerCloseContainer={this.props.triggerCloseContainer}
        onTyped={onTyped}
        onMouseDown={(e) => e.stopPropagation()}
      />
    );
  };

  // FIXME: 这个部分的代码是为了让开关组件可以双击后，直接修改其选中状态而做的，代码有试验性质，所以实现方式，方案都还不成熟，也没有考虑跟目前机制的一致性
  renderBooleanEditor = () => {
    const { component, bounds } = this.props;
    const { coreEditor } = this.context;
    let value: boolean = false;
    const valueEditorInfo = getComponentValueEditorInfo(component.type, component.lib);
    if (valueEditorInfo && valueEditorInfo.getValue) {
      value = valueEditorInfo.getValue(component);
    }
    return (
      <BooleanEditor
        position={{
          left: bounds.left,
          top: bounds.top,
        }}
        size={{
          width: bounds.width,
          height: bounds.height,
        }}
        value={value as boolean}
        onClose={this.props.onExit}
        triggerCloseContainer={this.props.triggerCloseContainer}
        onChange={(newValue: boolean) => {
          if (coreEditor && valueEditorInfo && valueEditorInfo.setValue) {
            const patches = valueEditorInfo.setValue(component, newValue);
            if (patches) {
              coreEditor.update(patches);
            }
          }
        }}
      />
    );
  };

  renderListItemPureTextValuesEditor = () => {
    const { component, bounds } = this.props;
    const { coreEditor } = this.context;
    let value: string = getListItemsValue(component as UIContainerComponent);
    const minSize = 300;

    return (
      <HOCPureTextEditor
        position={{
          left: bounds.left,
          top: bounds.top,
        }}
        size={{
          width: max(bounds.width, minSize),
          height: max(bounds.height, minSize),
        }}
        value={value}
        wrap={true}
        onChange={(newValue: string) => {
          if (coreEditor) {
            coreEditor.setListItemsValue(component as UIContainerComponent, newValue.trim(), component.lib!);
          }
        }}
        onClose={this.props.onExit}
        triggerCloseContainer={this.props.triggerCloseContainer}
      />
    );
  };

  renderSelector = (type: ValueEditorType) => {
    const {
      onExit,
      onSelect,
      component: { size },
    } = this.props;
    return (
      <SelectorEditor
        type={type}
        onCancel={onExit}
        onSelect={onSelect}
        compSize={size}
        editor={this.context.coreEditor}
      />
    );
  };

  renderSnapshotEditor = () => {
    const { onExit, onSelectSnapshot, component, onWaiting } = this.props;
    return (
      <SnapshotEditor comp={component} onSelectSnapshot={onSelectSnapshot} onCancel={onExit} onWaiting={onWaiting} />
    );
  };

  renderItemValueEditor() {
    const { component, bounds, onOpenIconLibrariesPanel } = this.props;
    const { coreEditor } = this.context;
    return (
      <ItemValueEditor
        comp={component as UIContainerComponent}
        coreEditor={coreEditor!}
        sourceBounds={bounds}
        onOpenIconLibrariesPanel={onOpenIconLibrariesPanel}
        onClose={this.props.onExit}
      />
    );
  }

  renderTreeValueEditor = () => {
    const { component, bounds, onOpenIconLibrariesPanel } = this.props;
    const { coreEditor } = this.context;
    return (
      <TreeEditor
        comp={component as UITreeComponent}
        coreEditor={coreEditor!}
        bounds={bounds}
        onClose={this.props.onExit}
        onOpenIconLibrariesPanel={onOpenIconLibrariesPanel}
      />
    );
  };

  renderMenuValueEditor = () => {
    const { component, bounds, onOpenIconLibrariesPanel } = this.props;
    const { coreEditor } = this.context;
    return (
      <MenuEditor
        comp={component as UINavigationMenuComponent | UIHorizontalMenuComponent | UIVerticalMenuComponent}
        coreEditor={coreEditor!}
        bounds={bounds}
        onOpenIconLibrariesPanel={onOpenIconLibrariesPanel}
        onClose={this.props.onExit}
      />
    );
  };

  renderCarouselChartEditor = () => {
    const { component, bounds } = this.props;
    const { coreEditor } = this.context;
    return (
      <CarouselChartEditor
        comp={component as UIContainerComponent}
        coreEditor={coreEditor!}
        sourceBounds={bounds}
        onClose={this.props.onExit}
      />
    );
  };

  renderChartValueEditor = () => {
    const { component, bounds } = this.props;
    const { coreEditor } = this.context;
    const isPieAndDough = [CPieChart, CDoughnutChart].includes(component.type);
    return (
      <ChartEditor
        canInputNegative={!isPieAndDough}
        component={component}
        canAddColumn={!isPieAndDough}
        canAddRow={true}
        coreEditor={coreEditor!}
        bounds={bounds}
        onClose={this.props.onExit}
      />
    );
  };

  renderCombinationChartValueEditor = () => {
    const { component, bounds } = this.props;
    const { coreEditor } = this.context;
    const isPieAndDough = [CPieChart, CDoughnutChart].includes(component.type);
    return (
      <CombinationChartEdit
        canInputNegative={!isPieAndDough}
        component={component}
        canAddColumn={!isPieAndDough}
        canAddRow={true}
        coreEditor={coreEditor!}
        bounds={bounds}
        onClose={this.props.onExit}
      />
    );
  };

  renderMapChartValueEditor = () => {
    const { component, bounds } = this.props;
    const { coreEditor } = this.context;
    return (
      <MapChartEdit
        canInputNegative
        canAddColumn
        component={component}
        canAddRow={false}
        coreEditor={coreEditor!}
        bounds={bounds}
        onClose={this.props.onExit}
      />
    );
  };

  renderNothing = () => {
    const { onExit } = this.props;
    onExit();
    return null;
  };

  renderAudioVideoUrlEditor = (editType: ValueEditorType) => {
    const {
      onExit,
      onSelect,
      component: { size, value },
      bounds,
    } = this.props;

    return (
      <AudioVideoUrlEditor
        editType={editType === ValueEditorType.Audio ? 'audio' : 'video'}
        value={value as string}
        position={{ left: bounds.left, top: bounds.top }}
        onClose={onExit}
        onSelect={onSelect}
        size={size}
      ></AudioVideoUrlEditor>
    );
  };

  render() {
    const valueType = this.valueType;
    const value = this.value;
    switch (valueType) {
      case ValueEditorType.RichText:
        return this.renderRichTextEditor(value as string);
      case ValueEditorType.PureText:
        return this.renderPureTextEditor();
      case ValueEditorType.Bool:
        return this.renderBooleanEditor();
      case ValueEditorType.ListItemPureTextValues:
        return this.renderListItemPureTextValuesEditor();
      case ValueEditorType.Image:
        return this.renderSelector(valueType);
      case ValueEditorType.Snapshot:
        return this.renderSnapshotEditor();
      case ValueEditorType.Video:
        return RP_CONFIGS.isPrivateDeployment
          ? this.renderSelector(valueType)
          : this.renderAudioVideoUrlEditor(ValueEditorType.Video);
      case ValueEditorType.Svg:
        return this.renderSelector(valueType);
      case ValueEditorType.Audio:
        return RP_CONFIGS.isPrivateDeployment
          ? this.renderSelector(valueType)
          : this.renderAudioVideoUrlEditor(ValueEditorType.Audio);
      case ValueEditorType.ItemValue:
        return this.renderItemValueEditor();
      case ValueEditorType.Tree:
        return this.renderTreeValueEditor();
      case ValueEditorType.Chart:
        return this.renderChartValueEditor();
      case ValueEditorType.Menu:
        return this.renderMenuValueEditor();
      case ValueEditorType.CarouselChart:
        return this.renderCarouselChartEditor();
      case ValueEditorType.CombinationChart:
        return this.renderCombinationChartValueEditor();
      case ValueEditorType.MapChart:
        return this.renderMapChartValueEditor();
      default:
        return this.renderNothing();
    }
  }
}

export default ValueEditor;
