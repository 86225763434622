import { applyFillToStyle, parseColorToString } from '@utils/graphicsUtils';
import React from 'react';

import {
  FragmentPositionMode,
  IActionBase,
  IAnimation,
  ICommandActionParams,
  IEventHandle,
  IMoveCommandParams,
  IResizeCommandParams,
  IRotateCommandParams,
  IScaleCommandParams,
  IStateCommandParams,
  ISwitchContentParams,
  IVisibleCommandParams,
  IScrollCommandParams,
  PageSkipEffectType,
  TargetType,
  IFragmentAction,
  FragmentAnimationEffects,
} from '@fbs/rp/models/interactions';
import { CommandTypes, EventTypes, PageSkipEffects } from '@fbs/rp/models/event';
import { ITreeData } from '@/fbs/rp/models/value';
import { IComponentData } from '@/fbs/rp/models/component';
import { IPosition } from '@fbs/common/models/common';
import {
  CTableCell,
  CFrame,
  CAudio,
  CCompoundPath,
  CTable,
  CCarouselChart,
  CInput,
  CLine,
  CNavigationMenu,
  CVerticalMenu,
  CHorizontalMenu,
  CContentPanel,
  CContentPanelV2,
  CSnapshot,
  CSymbol,
  CTextArea,
} from '@libs/constants';
import { IPageArtboard } from '@/managers/pageArtboardManager';

import { FragmentPositionModes, EventNames } from '@consts/events';
import { GrayColor } from '@consts/colors';

import { UIComponent, UIFragment, UIArtboard, UISymbolComponent, UIContainerComponent } from '@editor/comps';
import CoreEditor from '@/editor/core';

import { allowedToggleStateCmd } from './stateHelper';
import { createAvlTreeWithTreeComp } from './treeCompHelper';

interface IPropery {
  start: string;
  end: string;
  animation?: IAnimation;
  scale?: number;
}

interface IActionParams {
  fragment: UIFragment;
  scale: number;
  deviceSize?: { width: number; height: number };
  keyframeStyle?: {
    top?: string | number;
    left?: string | number;
    right?: string | number;
    bottom?: string | number;
    transformX?: string | number;
    transformY?: string | number;
    transform?: string;
  };
}

const AllCompEvent = [
  EventTypes.click,
  EventTypes.doubleClick,
  EventTypes.contextMenu,
  EventTypes.mouseDown,
  EventTypes.mouseUp,
  EventTypes.mouseEnter,
  EventTypes.mouseLeave,
  EventTypes.afterMove,
  EventTypes.afterResize,
  EventTypes.afterZoom,
  EventTypes.afterRotate,
  EventTypes.afterVisible,
  EventTypes.afterHide,
];

const AllPageEvent = [
  EventTypes.click,
  EventTypes.doubleClick,
  EventTypes.contextMenu,
  EventTypes.mouseDown,
  EventTypes.mouseUp,
  EventTypes.loaded,
  EventTypes.swipeLeft,
  EventTypes.swipeRight,
  EventTypes.swipeUp,
  EventTypes.swipeDown,
];

const AllSpecialEvent = [
  EventTypes.loaded,
  EventTypes.checked,
  EventTypes.unChecked,
  EventTypes.toggleCheck,
  EventTypes.focus,
  EventTypes.blur,
];

const AllMouseEvent = [
  EventTypes.click,
  EventTypes.doubleClick,
  EventTypes.contextMenu,
  EventTypes.mouseDown,
  EventTypes.mouseUp,
  EventTypes.mouseEnter,
  EventTypes.mouseLeave,
];

const AllSwipeEvent = [EventTypes.swipeLeft, EventTypes.swipeRight, EventTypes.swipeUp, EventTypes.swipeDown];

const AllAfterEvent = [
  EventTypes.afterResize,
  EventTypes.afterMove,
  EventTypes.afterZoom,
  EventTypes.afterVisible,
  EventTypes.afterHide,
  EventTypes.afterRotate,
];

export const DefaultMoveCommandParam: IMoveCommandParams = {
  x: 0,
  y: 0,
  fromCurrent: true,
};

export const DefaultResizeCommandParam: IResizeCommandParams = {
  width: 0,
  height: 0,
  fromCurrent: true,
  transformOrigin: 'topLeft',
};

export const DefaultZoomCommandParam: IScaleCommandParams = {
  x: 1,
  y: 1,
  fromCurrent: true,
  transformOrigin: 'center',
};
export const DefaultRotateCommandParam: IRotateCommandParams = {
  rotate: 0,
  fromCurrent: true,
  transformOrigin: 'center',
};
export const DefaultVisibleCommandParam: IVisibleCommandParams = {
  state: 'toggle',
  forceFront: false,
};

export const DefaultStateCommandParam: IStateCommandParams = {
  stateID: 'normal',
};

export const DefaultContentCommandParam: ISwitchContentParams = {
  target: '@next',
  effect: 'none',
};

export const DefaultScrollCommandParam: IScrollCommandParams = {
  horizontal: false,
  vertical: true,
};

export const DefaultAnimation: IAnimation = {
  effect: 'linear',
  delay: 0,
  duration: 200,
};

export const DefaultCommandParam: { [cmdType: string]: ICommandActionParams } = {
  [CommandTypes.move]: DefaultMoveCommandParam,
  [CommandTypes.resize]: DefaultResizeCommandParam,
  [CommandTypes.rotation]: DefaultRotateCommandParam,
  [CommandTypes.zoom]: DefaultZoomCommandParam,
  [CommandTypes.toggleVisible]: DefaultVisibleCommandParam,
  [CommandTypes.toggleState]: DefaultStateCommandParam,
  [CommandTypes.switchContent]: DefaultContentCommandParam,
  [CommandTypes.scroll]: DefaultScrollCommandParam,
};

/**
 * 根据交互触发组件，判断触发方式
 * @param {boolean} isCompEvents
 * @param {UIComponent|undefined} comp
 */
export function getSupportEvents(isCompEvents: boolean, comp?: UIComponent): EventTypes[] {
  if (!isCompEvents) {
    if (comp instanceof UIFragment && !comp.isMain) {
      return [EventTypes.loaded];
    }
    return [...AllPageEvent];
  }
  if (comp?.type === CTableCell) {
    return [...AllMouseEvent, ...AllSwipeEvent];
  }
  if (comp?.type === CFrame || comp?.type === CAudio) {
    return [];
  }
  if (comp?.type !== CTableCell && [CCompoundPath, CTable].find((c) => c === comp?.parent?.type)) {
    return [];
  }
  if (!comp || comp.isConnector) {
    return [];
  }
  if (comp?.type === CContentPanelV2) {
    return [];
  }
  // 轮播图子项交互
  if (comp.nearestSealedComponent?.lib?.type === CCarouselChart) {
    return [EventTypes.click, EventTypes.doubleClick, EventTypes.contextMenu];
  }
  const result = [...AllCompEvent];
  const parentSelect = comp.parent?.select;
  if (!(parentSelect && parentSelect.target === 'child')) {
    result.push(...AllSwipeEvent);
  }
  if (comp.select) {
    if (comp.select.target === 'self') {
      result.push(EventTypes.checked);
      if (comp.select.reversible) {
        result.push(EventTypes.unChecked, EventTypes.toggleCheck);
      }
    } else {
      result.splice(0);
    }
  }

  if (comp.type === CInput || comp.type === CTextArea) {
    result.push(EventTypes.focus, EventTypes.blur);
  }
  if (comp.autoSize) {
    result.splice(result.indexOf(EventTypes.afterResize), 1);
  }
  if (comp.autoSize || comp.type === CLine) {
    result.splice(result.indexOf(EventTypes.afterResize), 1);
  }
  if (comp.type == CLine) {
    result.splice(result.indexOf(EventTypes.afterRotate), 1);
  }
  return result;
}

export function getEventsSeparatorInfo(
  isCompEvents: boolean,
  events: EventTypes[],
  comp?: UIComponent,
): {
  positions: number[];
  events: EventTypes[];
} {
  // 辅助画板只展示载入时无需分隔处理
  if (!isCompEvents && comp instanceof UIFragment && !comp.isMain) {
    return {
      events,
      positions: [],
    };
  }

  const positions: number[] = [];
  const specialEvents: EventTypes[] = [];
  const mouseEvents: EventTypes[] = [];
  const swipeEvents: EventTypes[] = [];
  const afterEvents: EventTypes[] = [];

  events.forEach((item) => {
    if (AllSpecialEvent.includes(item)) {
      specialEvents.push(item);
    } else if (AllMouseEvent.includes(item)) {
      mouseEvents.push(item);
    } else if (AllSwipeEvent.includes(item)) {
      swipeEvents.push(item);
    } else if (AllAfterEvent.includes(item)) {
      afterEvents.push(item);
    }
  });

  let length = 0;
  specialEvents.length > 0 && (length += specialEvents.length) && positions.push(length - 1);
  mouseEvents.length > 0 && (length += mouseEvents.length) && positions.push(length - 1);
  swipeEvents.length > 0 && (length += swipeEvents.length) && positions.push(length - 1);
  afterEvents.length > 0 && (length += afterEvents.length) && positions.push(length - 1);
  positions.pop();

  return {
    events: [...specialEvents, ...mouseEvents, ...swipeEvents, ...afterEvents],
    positions,
  };
}

export function getSupportEventsInfo(
  comp: UIComponent,
  separatorFlag?: string,
): { id: string; text: string; showSeparator?: boolean }[] {
  const isComp = comp && !comp.isArtboard;
  let events = getSupportEvents(isComp, comp);
  const interactionConfig = comp?.libData?.interactions;
  const fn = interactionConfig?.transformEventName;
  const separatorInfo = getEventsSeparatorInfo(isComp, events, comp);
  events = separatorInfo.events;
  const separatorPositions = separatorInfo.positions;

  const result: { id: string; text: string; showSeparator?: boolean }[] = [];

  events.forEach((event, index) => {
    const info = {
      id: event,
      text: EventNames[event],
      showSeparator: false,
    };
    if (fn) {
      const text = fn(event);
      text && (info.text = text);
    }
    const showSeparator = separatorPositions.includes(index);
    if (showSeparator) {
      info.showSeparator = true;
    }
    result.push(info);
    if (showSeparator && separatorFlag) {
      result.push({
        id: String(result.length),
        text: separatorFlag,
      });
    }
  });
  return result;
}

export function canSetInteraction(comp: UIComponent): boolean {
  return getSupportEvents(true, comp).length > 0;
}

// 导航菜单组件子项能否添加交互
export function canSetInteractionByMenuItemComp(comp: UIComponent | null, selectedItemComp?: UIComponent): boolean {
  if (!comp) {
    return true;
  }
  if (comp.type !== CNavigationMenu && comp.type !== CVerticalMenu && comp.type !== CHorizontalMenu) {
    return true;
  }
  const selectedItemId = selectedItemComp?.id;
  if (!selectedItemId) {
    return true;
  }
  const childComps: IComponentData[] = (comp as UIContainerComponent).components.map((comp) => comp.$data);
  const { relation } = comp.value as ITreeData;
  const { tree } = createAvlTreeWithTreeComp(relation, childComps);
  const selectCompTree = tree.get(selectedItemId);
  if (selectCompTree?.children) {
    return false;
  }
  return true;
}

export function canEditCompInteraction(
  component: UIComponent | UISymbolComponent | null,
  coreEditor: CoreEditor,
): boolean | null {
  return (
    !!coreEditor.selectedComponents.size &&
    component &&
    component.type !== CContentPanel &&
    component.type !== CContentPanelV2 &&
    component.type !== CSnapshot &&
    coreEditor.activeContainer.type !== CCompoundPath &&
    canSetInteraction(component)
  );
}

export function canEditArtboardInteraction(coreEditor: CoreEditor): boolean {
  return coreEditor.selectedComponents.size === 0 && coreEditor.activeContainer.type === 'artboard';
}

/**
 * 根据目标组件判断交互类型
 * @param comp
 */
export function getSupportCommands(comp?: UIComponent): CommandTypes[] {
  if (!comp) {
    return [];
  }
  const result: CommandTypes[] = [CommandTypes.move, CommandTypes.zoom];
  if (!comp.autoSize) {
    result.splice(1, 0, CommandTypes.resize);
  }
  if (comp.type !== CLine) {
    result.push(CommandTypes.rotation);
  }
  if ([CLine, CSymbol].includes(comp.type)) {
    result.splice(result.indexOf(CommandTypes.resize), 1);
  }
  result.push(CommandTypes.toggleVisible);
  if (allowedToggleStateCmd(comp)) {
    result.push(CommandTypes.toggleState);
  }
  if ((comp.type === CContentPanel || comp.type === CContentPanelV2) && (comp.value as string[])?.length) {
    result.push(CommandTypes.switchContent);
  }
  result.push(CommandTypes.scroll);

  return result;
}

/**
 *  询问是否有同类型的页面或画板交互事件
 * @memberof ActionList
 */
export const isPageOrFragmentActionTypeExited = async (
  handle: IEventHandle,
  actionType: TargetType,
): Promise<{
  isExisted: boolean;
  action?: IActionBase;
}> => {
  // 查找交互为页面或链接的
  const existedPageAction = handle?.actions?.find((item) => {
    const isJump = item.type === 'link' || item.type === 'page';
    return isJump && (actionType === 'link' || actionType === 'page');
  });
  // 查找交互为辅助画板的
  const existedFragmentAction =
    !existedPageAction &&
    handle?.actions?.find((item) => {
      return item.type === actionType && item.type === 'fragment';
    });
  const existedAction = existedPageAction || existedFragmentAction;
  if (existedAction) {
    return {
      isExisted: true,
      action: existedAction,
    };
  }
  return {
    isExisted: false,
  };
};

export const getRealMode = (mode: FragmentPositionMode | undefined, effect: PageSkipEffectType) => {
  let realMode = mode;
  if (!realMode) {
    switch (effect) {
      case PageSkipEffects.slideToBottom:
        realMode = FragmentPositionMode.Top;
        break;
      case PageSkipEffects.slideToLeft:
        realMode = FragmentPositionMode.Right;
        break;
      case PageSkipEffects.slideToRight:
        realMode = FragmentPositionMode.Left;
        break;
      case PageSkipEffects.slideToTop:
        realMode = FragmentPositionMode.Bottom;
        break;
      default:
        realMode = FragmentPositionMode.Center;
        break;
    }
  }
  return realMode;
};

/**
 *  解析辅画板交互画板位置
 */
export const getPositionFromFragmentAction = (
  artboard: UIArtboard,
  fragment: UIFragment | IPageArtboard,
  mode: FragmentPositionMode | undefined,
  position: IPosition | undefined,
  effect: PageSkipEffectType,
) => {
  const realMode = getRealMode(mode, effect);
  switch (realMode) {
    case FragmentPositionMode.Custom:
      return position || { x: 0, y: 0 };
    case FragmentPositionMode.Left:
      return {
        x: 0,
        y: artboard.size.height / 2 - fragment.size.height / 2,
      };
    case FragmentPositionMode.Right:
      return {
        x: artboard.size.width - fragment.size.width,
        y: artboard.size.height / 2 - fragment.size.height / 2,
      };
    case FragmentPositionMode.Top:
      return {
        x: artboard.size.width / 2 - fragment.size.width / 2,
        y: 0,
      };
    case FragmentPositionMode.Bottom:
      return {
        x: artboard.size.width / 2 - fragment.size.width / 2,
        y: artboard.size.height - fragment.size.height,
      };
    case FragmentPositionMode.Center:
      return {
        x: artboard.size.width / 2 - fragment.size.width / 2,
        y: artboard.size.height / 2 - fragment.size.height / 2,
      };
  }
};

export const getFragmentPositionTip = (
  mode: FragmentPositionMode | undefined,
  effect: PageSkipEffectType,
  position: IPosition,
) => {
  const realMode = getRealMode(mode, effect);
  switch (realMode) {
    case FragmentPositionMode.Custom:
      return `${position.x}, ${position.y}`;
    case FragmentPositionMode.Left:
      return FragmentPositionModes[realMode];
    case FragmentPositionMode.Right:
      return FragmentPositionModes[realMode];
    case FragmentPositionMode.Top:
      return FragmentPositionModes[realMode];
    case FragmentPositionMode.Bottom:
      return FragmentPositionModes[realMode];
    case FragmentPositionMode.Center:
      return FragmentPositionModes[realMode];
    default:
      return `${position.x}, ${position.y}`;
  }
};

export function hasInteraction(comp: UIComponent): boolean {
  const { interactions } = comp;
  return !!(interactions && Object.keys(interactions).length);
}

/**
 * 计算目标在项目设备中的绝对位置
 * @param mode
 * @param actionParams
 */
const calcTargetKeyframe = (mode: FragmentPositionMode, actionParams: IActionParams) => {
  const keyframeStyle: IActionParams['keyframeStyle'] = {};
  const { deviceSize, fragment: target, scale } = actionParams;
  switch (mode) {
    // 计算目标位置-在顶部且水平居中
    case FragmentPositionMode.Top:
      keyframeStyle.top = 0;
      keyframeStyle.left = '50%';
      keyframeStyle.transformX = '-50%';
      keyframeStyle.transformY = '0';
      keyframeStyle.transform = 'translate(-50%, 0)';
      break;
    // 计算目标位置-在底部且水平居中
    case FragmentPositionMode.Bottom:
      keyframeStyle.top = 0;
      keyframeStyle.left = '50%';
      keyframeStyle.transformX = '-50%'; // left与其相互协调
      keyframeStyle.transformY = deviceSize!.height - target.size.height * scale + 'px'; // top与其相互协调
      keyframeStyle.transform = `translate(-50%, ${keyframeStyle.transformY})`;
      break;
    // 计算目标位置-在左侧且垂直居中
    case FragmentPositionMode.Left:
      keyframeStyle.left = 0;
      keyframeStyle.top = '50%';
      keyframeStyle.transformX = 0;
      keyframeStyle.transformY = '-50%';
      keyframeStyle.transform = 'translate(0%, -50%)';
      break;
    // 计算目标位置-在右侧且垂直居中
    case FragmentPositionMode.Right:
      keyframeStyle.left = 0;
      keyframeStyle.top = '50%';
      keyframeStyle.transformX = deviceSize!.width - target.size.width * scale + 'px';
      keyframeStyle.transformY = '-50%';
      keyframeStyle.transform = `translate(${keyframeStyle.transformX}, -50%)`;
      break;
    // 在垂直水平居中
    case FragmentPositionMode.Center:
      keyframeStyle.left = '50%';
      keyframeStyle.top = '50%';
      keyframeStyle.transformY = '-50%';
      keyframeStyle.transformX = '-50%';
      keyframeStyle.transform = 'translate(-50%, -50%)';
      break;
    default:
      break;
  }
  return keyframeStyle;
};

/**
 * 获取动画类名
 * @param action
 * @param actionParams
 */
export const getClassNameAndStyleOfFragmentAction = (action: IFragmentAction, actionParams: IActionParams) => {
  const actionEffect = action.params.effect as FragmentAnimationEffects;

  const { fragment: target } = actionParams;
  const animationStyle: React.CSSProperties = {};
  const bgStyle: React.CSSProperties = {};
  const { animation, params, isExit } = action;
  const newAnimationEffect = params.effect as FragmentAnimationEffects;
  actionParams.keyframeStyle = { transform: '' };

  const fragmentStyle = calcTargetKeyframe(params.mode || FragmentPositionMode.Center, actionParams);
  actionParams.keyframeStyle = fragmentStyle;

  if (params.showBackground) {
    if (params.backgroundColor) {
      const { color, type } = params.backgroundColor;
      if (color && type) {
        applyFillToStyle({ color, type }, bgStyle);
      }
    } else {
      bgStyle.background = parseColorToString({ ...GrayColor, a: 0.5 });
    }
  }
  bgStyle.pointerEvents = 'none';
  if (params.showBackground) {
    bgStyle.pointerEvents = 'all';
  }

  const isCustom = ![
    FragmentAnimationEffects.slideToTop,
    FragmentAnimationEffects.slideToLeft,
    FragmentAnimationEffects.slideToRight,
    FragmentAnimationEffects.slideToBottom,
    FragmentAnimationEffects.fadeIn,
    FragmentAnimationEffects.zoomIn,
    FragmentAnimationEffects.spreadIn,
    FragmentAnimationEffects.slideToBottomRevert,
  ].includes(newAnimationEffect as FragmentAnimationEffects);
  // 判断是否使用动效且条件同时必须满足, 没有动效就不添加动效类名
  if (!isExit && !animation.duration && isCustom && !action.params.autoEffect) {
    return { keyframeName: '', bgStyle, animationStyle: { ...animationStyle, ...actionParams.keyframeStyle } };
  }

  const actionName = target && createClassNameAndFragmentAction(action, actionParams);

  // 获取上一次动画的结果并合并数据样式
  const prevAnimationStyle = getShowFragmentAnimateStyle(newAnimationEffect, actionParams);
  Object.assign(animationStyle, actionParams.keyframeStyle, prevAnimationStyle.endStyle);
  const revertActionName = getCloseFragmentAnimateStyle(action, actionParams, prevAnimationStyle.end);

  const key0 = isExit ? 'revert-' : ''; // 设置自动返回
  const key1 = 'fragment-';
  const keyframeName = actionName ? actionName : key0 + key1 + actionEffect;

  return { keyframeName: revertActionName || keyframeName, bgStyle, animationStyle };
};

/**
 * 创建动画的CSS样式
 * @param property
 * @param actname   保证动画的唯一性,同时同样的动效不能添加新增为其他名称,否则会导致该动画不能被取消。场景是在该动画未执行完是执行下一个动画
 */
const createFragmentKeyframes = (property: IPropery, actname: string = '') => {
  const prefixName = 'fragment-custom-action';
  const actionName = `--${prefixName}-${actname}`;
  let actionStyle = document.getElementById(`${prefixName}-style`);
  if (!actionStyle) {
    actionStyle = document.createElement('style');
    actionStyle.setAttribute('id', `${prefixName}-style`);
    document.head.appendChild(actionStyle);
  }

  // 当设备缩放了，则需清空样式，并重新生成。
  const dataScale = actionStyle.getAttribute(`data-scale`);
  if (dataScale !== String(property.scale)) {
    actionStyle.innerHTML = '';
    actionStyle.setAttribute(`data-${prefixName}`, '');
  }

  const dataName = actionStyle.getAttribute(`data-${prefixName}`) || '';
  // 不重复添加动效及类名,同样的
  if (dataName.indexOf(actionName) !== -1) {
    return actionName;
  }

  actionStyle.setAttribute('data-scale', String(property.scale));
  actionStyle.setAttribute(`data-${prefixName}`, `${dataName}_${actionName}`);
  const animation = property.animation;

  // 一个命令仅有一个辅助画板交互
  actionStyle!.innerHTML += `
  @keyframes ${actionName}{
    0% {
      ${property.start}
    }
    100% {
      ${property.end}
    }
  }
  @keyframes ${actionName}-reverse{
    0% {
      ${property.end}
    }
    100% {
      ${property.start}
    }
  }
  .${actionName}{
    -webkit-animation-name: ${actionName};
    animation-name: ${actionName};
    animation-duration: ${animation?.duration ?? 1}ms;
    animation-fill-mode:forwards;
    animation-timing-function: ${animation?.effect ?? ''};
  }
  .${actionName}-reverse{
    -webkit-animation-name: ${actionName}-reverse;
    animation-name: ${actionName}-reverse;
    animation-duration: ${animation?.duration ?? 1}ms;
    animation-fill-mode:forwards;
    animation-timing-function: ${animation?.effect ?? ''};
  }
  `;
  return actionName;
};

/**
 * 获取并生成关闭画板所需动画
 * @param action
 * @param actionParams
 * @param prevActionEndStyle
 */
const getCloseFragmentAnimateStyle = function (
  action: IFragmentAction,
  actionParams: IActionParams,
  prevActionEndStyle: string,
): string {
  const animation = action.animation;
  const actionEffect = action.params.effect as FragmentAnimationEffects;
  const deviceSize = actionParams.deviceSize!;
  const scale = actionParams.scale;
  const { width, height } = actionParams.fragment.size;
  switch (actionEffect) {
    // 顶部滑出
    case FragmentAnimationEffects.slideToTopRevert:
      return createFragmentKeyframes(
        {
          start: prevActionEndStyle,
          end: `top:${-height * scale}px;`,
          animation,
        },
        actionEffect,
      );
    // 右侧滑出
    case FragmentAnimationEffects.slideToRightRevert:
      return createFragmentKeyframes(
        {
          start: prevActionEndStyle,
          end: `left:${deviceSize.width + width * scale}px;`,
          animation,
        },
        actionEffect,
      );
    // 左侧滑出
    case FragmentAnimationEffects.slideToLeftRevert:
      return createFragmentKeyframes(
        {
          start: prevActionEndStyle,
          end: `left:${-width * scale - deviceSize.width}px;`,
          animation,
        },
        actionEffect,
      );
    // 底部滑出
    case FragmentAnimationEffects.slideToBottomRevert:
      return createFragmentKeyframes(
        {
          start: prevActionEndStyle,
          end: `top:${height * scale + deviceSize.height}px;`,
          animation,
        },
        actionEffect,
      );
    // 向内收缩
    case FragmentAnimationEffects.zoomInRevert:
      return createFragmentKeyframes(
        {
          start: prevActionEndStyle,
          end: `transform: scale3d(0, 0, 0);`,
          animation,
        },
        actionEffect,
      );
    // 翻出
    case FragmentAnimationEffects.spreadInRevert:
      return createFragmentKeyframes(
        {
          start: prevActionEndStyle,
          end: `transform: scale3d(0, 1, 1);`,
          animation,
        },
        actionEffect,
      );
    // 淡出
    case FragmentAnimationEffects.fadeInRevert:
      return createFragmentKeyframes(
        {
          start: prevActionEndStyle,
          end: `opacity: 0`,
          animation,
        },
        actionEffect,
      );
    default:
      break;
  }
  return '';
};

/**
 * 获取并生成显示画板所需动画
 * @param effect
 * @param params
 */
const getShowFragmentAnimateStyle = function (effect: string, params: IActionParams) {
  const deviceSize = params.deviceSize!;
  const keyframeStyle = params.keyframeStyle;
  const property = { start: '', end: '', startStyle: {}, endStyle: {} };
  switch (effect) {
    // 顶部滑入
    case FragmentAnimationEffects.slideToBottom:
      property.start = `
        top: 0;
        left: ${keyframeStyle?.left};
        -webkit-transform: translate(${keyframeStyle?.transformX}, -100%);
        transform: translate(${keyframeStyle?.transformX}, -100%);
      `;
      property.end = `
      top:${keyframeStyle?.top};
      left: ${keyframeStyle?.left};
      -webkit-transform: translate(${keyframeStyle?.transformX}, ${keyframeStyle?.transformY});
      transform: translate(${keyframeStyle?.transformX}, ${keyframeStyle?.transformY});
      `;
      property.endStyle = {
        left: keyframeStyle?.left,
        top: keyframeStyle?.top,
        transform: `translate(${keyframeStyle?.transformX}, ${keyframeStyle?.transformY})`,
      };
      break;
    // 左侧滑入
    case FragmentAnimationEffects.slideToRight:
      property.start = `
      top: ${keyframeStyle?.top};
      left: 0;
      -webkit-transform: translate(-100%, ${keyframeStyle?.transformY});
      transform: translate(-100%, ${keyframeStyle?.transformY});
      `;
      property.end = `
      top:${keyframeStyle?.top};
      left: ${keyframeStyle?.left};
      -webkit-transform: translate(${keyframeStyle?.transformX}, ${keyframeStyle?.transformY});
      transform: translate(${keyframeStyle?.transformX}, ${keyframeStyle?.transformY});
      `;
      property.endStyle = {
        left: keyframeStyle?.left,
        top: keyframeStyle?.top,
        transform: `translate(${keyframeStyle?.transformX}, ${keyframeStyle?.transformY})`,
      };
      break;
    // 右侧滑入
    case FragmentAnimationEffects.slideToLeft:
      property.start = `
      top: ${keyframeStyle?.top};
      left: 0;
      -webkit-transform: translate(${deviceSize.width}px, ${keyframeStyle?.transformY});
      transform: translate(${deviceSize.width}px, ${keyframeStyle?.transformY});
      `;
      property.end = `
      top:${keyframeStyle?.top};
      left: ${keyframeStyle?.left};
      -webkit-transform: translate(${keyframeStyle?.transformX}, ${keyframeStyle?.transformY});
      transform: translate(${keyframeStyle?.transformX}, ${keyframeStyle?.transformY});
      `;
      property.endStyle = {
        top: keyframeStyle?.top,
        left: keyframeStyle?.left,
        transform: `translate(${keyframeStyle?.transformX}, ${keyframeStyle?.transformY})`,
      };
      break;
    // 底部滑入
    case FragmentAnimationEffects.slideToTop:
      property.start = `
      top: 0;
      left: ${keyframeStyle?.left};
      -webkit-transform: translate(${keyframeStyle?.transformX}, ${deviceSize.height}px);
      transform: translate(${keyframeStyle?.transformX}, ${deviceSize.height}px);
      `;
      property.end = `
      top:${keyframeStyle?.top};
      left: ${keyframeStyle?.left};
      -webkit-transform: translate(${keyframeStyle?.transformX}, ${keyframeStyle?.transformY});
      transform: translate(${keyframeStyle?.transformX}, ${keyframeStyle?.transformY});
      `;
      property.endStyle = {
        top: keyframeStyle?.top,
        left: keyframeStyle?.left,
        transform: `translate(${keyframeStyle?.transformX}, ${keyframeStyle?.transformY})`,
      };
      break;
    // 淡入
    case FragmentAnimationEffects.fadeIn:
      property.start = `
      opacity: 0;
      `;
      property.end = `
      opacity: 1;
      `;
      break;
    // 放大弹出
    case FragmentAnimationEffects.zoomIn:
      property.start = `
      transform: ${keyframeStyle?.transform + ' scale(0, 0)'};
      `;
      property.end = `
      transform: ${keyframeStyle?.transform + ' scale(1, 1)'};
      `;
      break;
    // 翻入
    case FragmentAnimationEffects.spreadIn:
      property.start = `
      transform: ${keyframeStyle?.transform + ' scale(1, 0)'};
      `;
      property.end = `
      transform: ${keyframeStyle?.transform + ' scale(1, 1)'};
      `;
      break;
    default:
      break;
  }
  return property;
};

export const createClassNameAndFragmentAction = (action: IFragmentAction, params: IActionParams) => {
  const { scale, fragment } = params;
  const { mode = '' } = action.params;
  const effect = action.params.effect as FragmentAnimationEffects;
  const property: IPropery | undefined = {
    start: '',
    end: '',
    scale,
    animation: action.animation,
  };
  const animateStyle = getShowFragmentAnimateStyle(effect, params);
  property.start = animateStyle.start;
  property.end = animateStyle.end;

  return property ? createFragmentKeyframes(property, `${effect}_${mode}_${fragment.realID}`) : '';
};
