import { ILibF, ILibBase } from '@fbs/rp/models/ds/lib';
import IComponent from '@fbs/rp/models/ds/component';

import { put, get, patch, post } from '../helper';

// 添加库
export async function addLib(appID: string): Promise<ILibF> {
  return await put<ILibF>(`/ds/lib/${appID}`);
}

// 获取库
export async function getLib(appID: string, isExample: boolean = false): Promise<ILibF> {
  return await get<ILibF>(`/ds/lib/${appID}?isExample=${isExample}&rnd=${new Date().getTime()}`);
}

export async function getLibByLibID(libID: string): Promise<ILibF> {
  return await get<ILibF>(`/ds/lib/single/${libID}`);
}

// 获取项目内所有的设计资源库，包含别人共享的库
export async function getLibs(appID: string, isExample: boolean = false) {
  return await get<ILibF[]>(`/ds/lib/libs/${appID}?isExample=${isExample}&rnd=${Date.now()}`);
}

// 修改资源库
export async function updateLib(libID: string, data: ILibBase) {
  return await patch<ILibBase>(`/ds/lib/${libID}`, data);
}

export async function getUsedLibs(ids: string[]) {
  return await post<IComponent[]>(`/ds/component/get-by-ids`, { ids });
}

// 获取历史版本库
export async function getSnapshotLibs(snapshotID: string) {
  return await get<ILibF[]>(`/ds/lib/revision/${snapshotID}`);
}

// 获取历史版本库
export async function getSnapshotLibsByModuleID(moduleID: string) {
  return await get<ILibF[]>(`/ds/lib/revision-for-module/${moduleID}`);
}
