import * as React from 'react';

import { measureTextSize } from '@utils/textUtils';

import { stopBubbleWhenSortCut } from '@helpers/shortCutHelper';

import TextEditorBase, { ITextEditorProps, ITextEditorState } from './TextValueEditor';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';

interface IPureTextEditorProps extends ITextEditorProps {}

interface IPureTextEditorState extends ITextEditorState {
  width: number;
  height: number;
}

export default class PureTextEditor extends TextEditorBase<IPureTextEditorProps, IPureTextEditorState> {
  constructor(props: IPureTextEditorProps) {
    super(props);
    this.state = {
      value: props.value,
      ...measureTextSize(props.style, props.value, { isMultiText: false, wrap: false }),
    };
  }

  afterCreate() {
    if (this.dom.current) {
      const input = this.dom.current as HTMLInputElement;
      input.focus();
      input.select();
    }
  }

  get value(): string {
    if (this.dom.current) {
      return (this.dom.current as HTMLInputElement).value;
    }
    return '';
  }

  private handleInput = (e: React.SyntheticEvent) => {
    const dom = e.target as HTMLInputElement;
    const value = dom.value;
    const { width, height } = measureTextSize(this.props.style, value, { wrap: false, isMultiText: false });
    this.setState({ value: dom.value, width, height });
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    // @ts-ignore
    stopBubbleWhenSortCut(e);
    if ([KeyCodeMap.VK_ESCAPE, KeyCodeMap.VK_ENTER, KeyCodeMap.VK_TAB].includes(e.keyCode)) {
      this.doSubmit();
    }
  };

  handleMouseUp = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  render() {
    const { style } = this.props;
    const { value, width, height } = this.state;
    return (
      <input
        style={{
          ...style,
          width: width,
          height: height,
          outline: 'none',
          border: 'none',
          padding: 0,
          margin: 0,
          background: 'transparent',
        }}
        width={width}
        height={height}
        ref={this.dom as React.RefObject<HTMLInputElement>}
        value={value}
        onChange={this.handleInput}
        onKeyDown={this.handleKeyDown}
        onMouseUp={this.handleMouseUp}
        onContextMenu={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      />
    );
  }
}
