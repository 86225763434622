import * as React from 'react';
import classnames from 'classnames';
import Icon from '../Icon';

import './index.scss';

export type MessageProp = {
  theme?: 'message' | 'warning' | 'information';
  message?: string;
  onClose(): void;
  isZh: boolean;
};

class Message extends React.Component<MessageProp> {
  static defaultProps: Partial<MessageProp> = {
    theme: 'message',
    message: '',
    isZh: true,
  };

  timeoutID: any = null;

  constructor(props: MessageProp) {
    super(props);
    this.dismissMessage = this.dismissMessage.bind(this);
  }

  componentDidMount() {
    this.dismissMessage();
  }

  componentDidUpdate() {
    this.dismissMessage();
  }

  shouldComponentUpdate(newProps: MessageProp) {
    return newProps.message !== this.props.message;
  }

  dismissMessage() {
    clearTimeout(this.timeoutID);
    if (!this.props.message) {
      return;
    }
    const num = this.props.isZh ? 5 : 10;
    const time = Math.ceil(this.props.message.length / num);
    this.timeoutID = setTimeout(
      () => {
        this.props.onClose();
      },
      time > 5 ? 5 * 1e3 : time * 1e3,
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  render() {
    const { message, theme } = this.props;
    let color = '#65D505';
    let icon = 'tag_resolved';
    if (theme === 'warning') {
      color = '#fe4066';
      icon = 'tag_exclamation';
    }

    return (
      <div
        className={classnames('dsm-c-message', {
          hide: !message,
        })}
      >
        {theme === 'information' ? <span className="info-text">i</span> : <Icon cls={icon} size={24} color={color} />}
        <p className="text">{message}</p>
      </div>
    );
  }
}

export default Message;
