import * as React from 'react';
import * as ReactDom from 'react-dom';

import * as _ from 'lodash';
import classNames from 'classnames';

import { round } from '@utils/globalUtils';
import { parseColorToString } from '@utils/graphicsUtils';
import { transBlankChart } from '@utils/textUtils';

import { FontBoxScale } from '@/consts/fonts';
import { StyleHelper } from '@helpers/styleHelper';
import { UIComponent, UIContainerComponent } from '@editor/comps';
import { Color } from '@fbs/rp/models/properties/color';

import { IComponentProps, IListSealedComponentItemEvents } from '../../types';
import Background from '../../basic/common/Background';
import List from '../List';
import { renderExtendIcon } from './renderExtendIcon';

import './index.scss';

interface ISelectState {
  isHover?: boolean;
  dropDown?: boolean;
  popPosition?: { left: number; top: number };
  inputValue?: string;
}

const TEXT_TO_RIGHT_SPACE = 16; // 文本距组件右侧距离

class Select extends React.Component<IComponentProps, ISelectState> {
  private main: React.RefObject<HTMLDivElement> = React.createRef();
  private dropDown: React.RefObject<HTMLDivElement> = React.createRef();
  private inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  private needReCalcPopPosition: boolean = false;

  private itemEvents?: IListSealedComponentItemEvents;

  private pageScale = 1;
  timer: Timeout | undefined = undefined;
  constructor(props: IComponentProps) {
    super(props);
    this.state = { inputValue: '', isHover: false };
    this.pageScale = window.pageScale;
    if (props.isPreview) {
      this.itemEvents = {
        onItemClick: this.handleItemClick,
      };
    }
  }

  componentDidMount() {
    if (this.props.isPreview) {
      window.addEventListener('click', this.handleWindowClick, true);
      document.addEventListener('scroll', this.handleWinScroll, true);
      window.addEventListener('resize', this.handleWindowResize, true);
    }
  }

  componentWillUnmount() {
    if (this.props.isPreview) {
      window.removeEventListener('click', this.handleWindowClick, true);
      document.removeEventListener('scroll', this.handleWinScroll, true);
      window.removeEventListener('resize', this.handleWindowResize, true);
    }
    clearTimeout(this.timer);
  }

  componentDidUpdate(_prevProps: IComponentProps, prevState: ISelectState) {
    if (this.state.inputValue || this.needReCalcPopPosition) {
      setTimeout(() => {
        this.calcPopPosition();
      });
    }
    this.needReCalcPopPosition = false;

    if (prevState.dropDown !== this.state.dropDown) {
      const { comp, event } = this.props;
      if (this.state.dropDown) {
        event.onFocus?.(undefined, comp);
      } else {
        // 鼠标仍处于组件上，设置为hover状态
        if (this.state.isHover) {
          comp.$data._currentState = undefined;
          event.onMouseEnter?.(undefined, comp);
        } else {
          event.onBlur?.(undefined, comp);
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.props.isPreview && this.state.dropDown && this.pageScale !== window.pageScale) {
      this.needReCalcPopPosition = true;
    }
    this.pageScale = window.pageScale;
  }

  get dropdownList() {
    const { comp } = this.props;
    const group = comp as UIContainerComponent;
    const { components } = (group.getComponentByAlias('drop-down') || group.components[1]) as UIContainerComponent;

    const list = (group.getComponentByAlias('list') || components[0]) as UIContainerComponent;
    return list;
  }

  get filteredDropdownListItems() {
    const { inputValue } = this.state;
    const { components: listItems } = this.dropdownList;

    if (!inputValue) {
      return listItems;
    }

    return listItems.filter(
      (component) =>
        component.$data.components &&
        transBlankChart(component.$data.components[0].value).includes(transBlankChart(inputValue)),
    );
  }

  get dropdownListHeight() {
    let { position } = this.dropdownList;
    let newListItems = this.filteredDropdownListItems;

    let height = newListItems.reduce((acc, curr, i) => {
      if (i < 6) {
        acc += curr.size.height;
      }
      return acc;
    }, position.y * 2);
    // 这里的 30 是空结果占位文字的高度
    height = newListItems.length === 0 ? height + 30 : height;

    return height;
  }

  private calcPopPosition() {
    if (!this.main.current) {
      return;
    }
    const { left, top, bottom } = this.main.current.getBoundingClientRect();
    const { scale } = this.props;
    const showHeight = this.dropdownListHeight * window.pageScale ?? scale ?? 1;
    const { innerHeight } = window;
    let y = bottom;
    if (y + showHeight > innerHeight && top - showHeight > 0) {
      y = top - showHeight;
    }
    y = round(y);

    const { popPosition } = this.state;
    if (!popPosition || popPosition.left !== left || popPosition.top !== top) {
      this.setState({ popPosition: { left, top: y } });
    }
  }

  private handleWindowResize = (): void => {
    if (!this.state.dropDown) {
      return;
    }
    this.setState({ dropDown: false });
  };

  handleWindowClick = (e: MouseEvent) => {
    const { dropDown } = this.state;
    const { target } = e;
    const el = target as HTMLElement;
    if (this.dropDown.current?.contains(el) || this.main.current?.contains(el)) {
      return;
    }
    if (dropDown) {
      this.setState({ dropDown: false });
    }
  };

  handleWinScroll = (e: Event) => {
    if (!this.state.dropDown) {
      return;
    }
    if (this.dropDown.current?.contains(e.target as HTMLElement)) {
      return;
    }
    if (this.inputRef.current?.contains(e.target as HTMLElement)) {
      return;
    }
    this.setState({ dropDown: false, inputValue: '' });
    this.inputRef.current?.blur();
  };

  handleMainClick = () => {
    const {
      comp: { disabled },
    } = this.props;
    if (disabled) {
      return;
    }
    const { dropDown } = this.state;
    this.setState({ dropDown: !dropDown }, () => {
      !dropDown && this.inputRef.current?.focus();
    });
    this.calcPopPosition();
  };

  handleItemClick = (e: React.MouseEvent, comp: UIComponent) => {
    const { event } = this.props;
    if (event?.onClick) {
      event.onClick(e, comp);
    }
    this.inputRef.current?.focus();
    this.setState({ dropDown: false, inputValue: '' });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && !this.state.dropDown) {
      this.setState({ dropDown: true });
    }
    this.setState({ inputValue: event.target.value });
  };

  // 演示界面，链接区域的处理
  handleMouseEnter = () => {
    this.setState({ isHover: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHover: false });
  };

  handleBlur = () => {
    clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      this.setState({ inputValue: '' });
    }, 200);
  };

  renderList = () => {
    const { comp, hotAreaVisibleModel, showMobileCursor } = this.props;
    const { popPosition, isHover } = this.state;
    const scale = window.pageScale ?? 1;
    const group = comp as UIContainerComponent;
    const { size, properties, id } = (group.getComponentByAlias('drop-down') ||
      group.components[1]) as UIContainerComponent;

    const list = this.dropdownList;
    // TODO 去掉 deep clone
    const searchAfterList = _.cloneDeep(list);
    let { position } = searchAfterList;

    searchAfterList.components = this.filteredDropdownListItems;
    const height = this.dropdownListHeight;

    const parser = StyleHelper.createCSSStyleParser(properties);
    const borderWidth = properties.stroke && !properties.stroke.disabled ? properties.stroke.thickness || 1 : 0;
    const style = {
      ...popPosition,
      ...size,
      height,
      ...parser.getFillStyle(),
      ...parser.getShadowStyle(),
      ...parser.getStrokeStyle(),
      ...parser.getRadiusStyle(size),
      transform: `scale(${scale || 1}, ${scale || 1})`,
      paddingTop: position.y - borderWidth,
      paddingBottom: position.y - borderWidth,
      opacity: StyleHelper.getOpacity(comp.opacity),
    };
    return (
      <div
        ref={this.dropDown}
        id={id}
        className={classNames(
          'lib-comp-select-drop-down',
          'component-popup-container',
          { mobile: showMobileCursor },
          { always: hotAreaVisibleModel === 'only-hover' && isHover },
          hotAreaVisibleModel,
        )}
        style={style}
      >
        <List comp={list} searchAfterComp={searchAfterList} itemEvents={this.itemEvents} isPreview isDropDown />
      </div>
    );
  };

  renderDropDown() {
    if (!this.state.dropDown) {
      return null;
    }
    return ReactDom.createPortal(this.renderList(), document.body);
  }

  renderMain() {
    const { comp, isPreview, showInteract } = this.props;
    const { dropDown, inputValue } = this.state;
    const group = comp as UIContainerComponent;
    const selectBoxProperties = comp.properties;
    const { placeholder, placeHolderStyle, canSearch, padding } = selectBoxProperties;
    const main = (group.getComponentByAlias('main') || group.components[0]) as UIContainerComponent;
    const [icon, text] = main.components;
    const value = text.value || main.value;
    const { properties, size } = main;
    const { radius, border, fill } = properties;
    const parser = StyleHelper.createCSSStyleParser({ ...properties, padding });
    const { size: textSize, style: txStyle } = parser.getTextStyleData(size, properties.textStyle);
    const style = {
      ...size,
      ...parser.getShadowStyle(),
      opacity: StyleHelper.getOpacity(comp.opacity),
    };

    let paddingStyle;
    if (padding && !padding.disabled) {
      const _paddingStyle = parser.getPaddingStyleByFontScale();
      paddingStyle = {
        ..._paddingStyle,
        paddingRight: 0,
        // padding样式在nowrap样式下，右边距设置溢出边距，使用border样式实现padding效果
        borderRight: `${_paddingStyle.paddingRight}px solid transparent`,
        // 垂直方向居中
        lineHeight: textSize.height - ((padding?.top || 0) + (padding?.bottom || 0)) / FontBoxScale + 'px',
      };
    }

    const textStyle = {
      opacity: StyleHelper.getOpacity(text.opacity),
      ...txStyle,
      height: textSize.height,
      width: textSize.width - TEXT_TO_RIGHT_SPACE / FontBoxScale,
      ...paddingStyle,
    };
    if (!value && placeHolderStyle) {
      textStyle.color = parseColorToString(placeHolderStyle.value as Color);
    }
    if (canSearch?.value && value && dropDown) {
      textStyle.color = 'rgb(184,187,191)';
    }
    const _hasInteraction = !!(comp.$data.interaction && Object.keys(comp.$data.interaction).length);
    const needShowInteraction = !isPreview && _hasInteraction && false !== showInteract;
    const stroke = selectBoxProperties.stroke || selectBoxProperties.mainStroke;
    return (
      <div
        ref={this.main}
        className={classNames(
          'lib-comp-select-main',
          { 'component-cursor-pointer': isPreview },
          { 'item-interaction-flag': needShowInteraction },
        )}
        onClick={isPreview ? this.handleMainClick : undefined}
        style={style}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Background size={size} properties={{ fill, radius, border, stroke }} zIndex={-1} />
        <label className="lib-comp-select-main-value" style={textStyle}>
          {inputValue ? '' : transBlankChart((value || placeholder?.value) as string)}
        </label>
        {canSearch?.value && (
          <label className="lib-comp-select-main-value lib-comp-select-main-lable" style={textStyle}>
            <input
              className="lib-comp-select-main-input"
              ref={this.inputRef}
              value={inputValue}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
          </label>
        )}
        {/* <ArrowPathIcon comp={icon} className={icon.type === CPath ? 'select-arrow-icon' : ''} /> */}
        <Background size={size} properties={{ radius, border, stroke }} zIndex={1}>
          {renderExtendIcon(comp, icon)}
        </Background>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderMain()}
        {this.renderDropDown()}
      </>
    );
  }
}

export default Select;
