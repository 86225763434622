import { IAppWithNestedChildren } from '@/fbs/rp/models/app';
import IArtboard from '@/fbs/rp/models/artboard';
import { ILibF } from '@/fbs/rp/models/ds/lib';
import IComponent from '@/fbs/rp/models/ds/component';

function loadPageFile(filePath: string, id?: string) {
  return new Promise<File>((resolve) => {
    const header = document.head;
    if (!id) {
      const el = document.getElementById('page-source');
      if (el) {
        el.parentElement?.removeChild(el);
      }
    }
    const script = document.createElement('script');
    script.id = id ?? 'page-source';
    script.src = filePath;
    script.onload = () => {
      resolve();
    };
    header.appendChild(script);
  });
}

export const getApp = async () => {
  return new Promise<IAppWithNestedChildren & { appID: string }>((resolve) => {
    resolve(window.app);
  });
};

export const getAllArtboardsByNodeID = async (nodeID: string) => {
  const path = `./data/pages/${nodeID}.js`;
  await loadPageFile(path);
  // @ts-ignore
  return window[nodeID] as IArtboard[];
};

export const getOfflineLibs = async () => {
  return new Promise<ILibF[]>((resolve) => {
    resolve(window.libs);
  });
};

export async function getOfflineUsedLibs(ids: string[]): Promise<IComponent[]> {
  const usedLibs: IComponent[] = [];
  for (const lib of window.libs as ILibF[]) {
    for (const dsGroup of lib.components) {
      for (const libComponent of dsGroup.children) {
        if (ids.includes(libComponent._id)) {
          usedLibs.push(libComponent);
        }
      }
    }
  }
  return usedLibs;
}

export const getSnapshot = async () => {
  return new Promise<IAppPages>((resolve) => {
    resolve(window.snapshot);
  });
};
