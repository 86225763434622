import { depthClone } from '@utils/globalUtils';

import { IBoundsOffset } from '@fbs/common/models/common';
import IArtboard from '@fbs/rp/models/artboard';
import { IComponentData } from '@fbs/rp/models/component';
import { IBasicLayout } from '@fbs/rp/models/layout';
import { FillPropertyName, FillType } from '@fbs/rp/models/properties/fill';
import { PropertyName, PropertyValue } from '@fbs/rp/models/property';
import { ArtboardPatches, Ops, PagePatches } from '@fbs/rp/utils/patch';

import { coverPatches } from '@helpers/patchHelper';
import { resetConnectPatchWhenResize } from '@helpers/pathFinderHelper';

import { UIContainerComponent, UIComponent, UIContentPanelV2Component } from '@editor/comps';
import {
  ComponentChange,
  ComponentChangeType,
  ComponentResizeResult,
  ContainerPatches,
  ResizeOptions,
  updateEditComponentsPatches,
} from '@editor/comps/resizeHelper';

import BasicComponentLib from '@libs/basic';
import { CContentPanel, CCanvasPanel } from '@libs/constants';
import { IUICompConstructOptions } from '@/customTypes';

/**
 * 内容面板组件UI类
 */
export default class UIContentPanelComponent extends UIContainerComponent {
  public canMoveChildren = false;

  constructor(data: IComponentData, public parent?: UIContainerComponent, public options?: IUICompConstructOptions) {
    super(data, parent, options);
    const defaultProperties = BasicComponentLib.make(CContentPanel).properties;
    if (data.properties) {
      data.properties = {
        ...data.properties,
        container: { ...defaultProperties.container, ...data.properties.container },
      };
    }
  }

  getParentContent = () => {
    const result: string[] = [];
    let parent: UIContainerComponent | undefined = this;
    while (parent) {
      if (
        (parent.parent instanceof UIContentPanelV2Component && typeof parent.value === 'string') ||
        (parent.parent instanceof UIContentPanelComponent && typeof parent.value === 'string')
      ) {
        result.unshift(parent.value);
      }
      parent = parent.parent;
    }
    return result;
  };

  /**
   *  装载内容面板
   * @memberof UIContentPanelComponent
   */
  loadContentByPreview(artboardIDs: string[], findFun: (refCompID: string, fragmentID: string) => IArtboard) {
    const { components } = this;
    const parentInfo = this.getParentContent();
    components.forEach((comp) => {
      const panel = (comp as unknown) as UIContainerComponent;
      const fragmentID = comp.value as string;
      const loadedKey = 'loaded';
      if (Object.getOwnPropertyDescriptor(comp.toJSON(), loadedKey)) {
        (comp as UIContainerComponent).refreshComponents();
        return;
      }
      Object.defineProperty(comp.toJSON(), loadedKey, { value: true, writable: true });
      // FIXME Matt 2020-12-7 验证该辅助画板是否已被某个父引用
      const isUsed =
        parentInfo.findIndex((item) => {
          return item === fragmentID;
        }) !== -1;
      if (isUsed) {
        return;
      }

      const fragment = findFun(panel.id, fragmentID);

      // NOTE: 还是要克隆，不然辅助画板装载内容时会导致 data.components 的循环引用
      panel.toJSON().components = depthClone(fragment.components);

      let fill = fragment.background;
      if (!fill?.color) {
        fill = { color: { r: 255, g: 255, b: 255, a: 1 }, type: FillType.solid, disabled: false };
      }

      panel.toJSON().properties.fill = fill;
      panel.refreshComponents();
      this.filterContentPanelInComps(panel.components).forEach((comp) => {
        (comp as UIContentPanelComponent).loadContentByPreview([...artboardIDs, fragmentID], findFun);
      });
    });
  }

  /**
   *  遍历组件及组件的子组件，取出内容面板
   * @param {UIComponent[]} comps
   * @returns {UIContentPanelComponent[]}
   * @memberof UIContentPanelComponent
   */
  filterContentPanelInComps(comps: UIComponent[]): UIContentPanelComponent[] {
    const contentPanles: UIContentPanelComponent[] = [];
    const traverse = (comps: UIComponent[]) => {
      comps.forEach((comp) => {
        if (comp instanceof UIContentPanelV2Component || comp instanceof UIContentPanelComponent) {
          contentPanles.push(comp);
        } else if (comp instanceof UIContainerComponent && comp.components.length) {
          traverse(comp.components);
        }
      });
    };

    traverse(comps);
    return contentPanles;
  }

  refreshComponents() {
    if (this.isPreview) {
      this.toJSON().components?.forEach((comp) => {
        if (comp.components?.length) {
          for (let i = comp.components.length - 1; i >= 0; i--) {
            if (!comp.components[i]) {
              comp.components.splice(i, 1);
            }
          }
        }
      });
    }
    super.refreshComponents();
  }

  resizeHandler2(offset: IBoundsOffset, layout: IBasicLayout, options: ResizeOptions): ComponentResizeResult {
    const info = super.resizeHandler2(offset, layout, options);
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    const { position, size, rotate } = info;

    if (this.components.length > 0) {
      const newCompInfo = this.components.map((comp) => {
        return {
          id: comp.id,
          type: ComponentChangeType.Edit,
          position: {
            x: 0,
            y: 0,
          },
          size: info.size,
          rotate: comp.rotate,
        };
      });
      const { patches: childPatches } = this.getPositionPatchesOfChildrenChanged(newCompInfo);
      coverPatches(patches, childPatches);

      //流程线重置
      const connectPatch = resetConnectPatchWhenResize(this, this.components, newCompInfo);
      coverPatches(patches, connectPatch);
    }

    return {
      position,
      size,
      rotate,
      patches,
    };
  }

  addComponents(
    components: IComponentData[],
    index = -1,
  ): {
    patches: PagePatches;
    newActiveGroup?: UIContainerComponent;
  } {
    const fill = this.properties.fill;
    components.forEach((comp) => {
      comp.position = {
        x: 0,
        y: 0,
      };
      comp.size = { ...this.data.size };
      if (fill) {
        comp.properties.fill = depthClone(fill);
      }
    });
    return {
      patches: {
        [this.ownerArtboardID]: {
          do: {
            [this.id]: [Ops.addChildren(`${index}`, components)],
          },
          undo: {
            [this.id]: [Ops.removeChildren(components.map((comp) => comp._id))],
          },
        },
      },
    };
  }

  removeComponents(components: UIComponent[]): { patches: PagePatches; newActiveGroup?: UIContainerComponent } {
    const patches: ArtboardPatches = {
      do: {
        [this.id]: [Ops.removeChildren(components.map((comp) => comp.id))],
      },
      undo: {
        [this.id]: components.map((comp) =>
          Ops.addChildren(`${this.components.findIndex((c) => c === comp)}`, [comp.toJSON()]),
        ),
      },
    };
    return {
      patches: {
        [this.ownerArtboardID]: patches,
      },
    };
  }

  setProperty(propertyName: PropertyName, value: PropertyValue): ArtboardPatches {
    const patches = super.setProperty(propertyName, value);
    if (propertyName === FillPropertyName) {
      const { fill } = this.properties;
      this.components.forEach((comp) => {
        const p = comp.properties;
        if (p.fill) {
          patches.do[comp.id] = [Ops.replace('/properties/fill', value)];
          patches.undo[comp.id] = [Ops.replace('/properties/fill', fill)];
        } else {
          patches.do[comp.id] = [Ops.add('/properties/fill', value)];
          patches.undo[comp.id] = [Ops.remove('/properties/fill')];
        }
      });
    }
    return patches;
  }

  getPositionPatchesOfChildrenChanged(changes: ComponentChange[]): ContainerPatches {
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };

    // added, removed 让调用者处理
    // 处理 edit 的组件
    updateEditComponentsPatches(
      this.components,
      changes,
      {
        x: 0,
        y: 0,
      },
      patches,
    );
    return {
      patches,
    };
  }

  moveValue(oldIndex: number, newIndex: number): ArtboardPatches | null {
    const value = this.value;
    if (!value) {
      return null;
    }
    const values = [...(value as string[])];
    const currentIndex = newIndex > oldIndex ? newIndex - 1 : newIndex;
    if (currentIndex < 0 || currentIndex >= values.length || oldIndex < 0 || oldIndex >= values.length) {
      return null;
    }
    if (currentIndex === oldIndex) {
      return null;
    }
    const tx = values[oldIndex];
    values.splice(oldIndex, 1);
    values.splice(currentIndex, 0, tx);
    return {
      do: {
        [this.id]: [Ops.replace('./value', values)],
      },
      undo: {
        [this.id]: [Ops.replace('./value', value)],
      },
    };
  }

  /**
   * 设置内容面板引用页面
   * @param {string} refValue
   */
  setContentPanelRefValue(refValue: string, isSelected?: boolean) {
    let value: string[] = [...((this.value as string[]) ?? [])];
    if (value.length !== this.components.length) {
      value = this.components.map((c) => `${c.value}`);
    }

    if (value.indexOf(refValue) === -1) {
      value.push(refValue);
      const { patches } = this.addComponents([
        BasicComponentLib.make(CCanvasPanel, {
          components: [],
          properties: {
            container: {
              showScroll: true,
              scroll: true,
              disabled: false,
              hidden: true,
            },
          },
          value: refValue,
          selected: isSelected ? isSelected : value.length === 1,
        }),
      ]);

      const p = this.setValue(value);
      const ownerArtboardID = this.ownerArtboardID;

      Object.keys(p.do).forEach((id) => {
        if (patches[ownerArtboardID].do[id]) {
          patches[ownerArtboardID].do[id].push(...p.do[id]);
          patches[ownerArtboardID].undo[id].push(...p.undo[id]);
        } else {
          patches[ownerArtboardID].do[id] = p.do[id];
          patches[ownerArtboardID].undo[id] = p.undo[id];
        }
      });

      return patches;
    }
  }
}
