import * as React from 'react';
import * as ReactDOM from 'react-dom';
import classnames from 'classnames';

import './index.scss';

interface IToastEscProps {
  message: string;
  theme?: 'dark' | 'light';
  className?: string;
}

const ToastEscMessage = (props: IToastEscProps) => {
  const { message, className } = props;
  return ReactDOM.createPortal(
    <div className={classnames('global-esc-toast-message', className, 'toast-esc-y')}>
      <span className="global-esc-toast-message-text">{message}</span>
    </div>,
    document.body as HTMLDivElement,
  );
};

export default ToastEscMessage;
