import * as React from 'react';

import classnames from 'classnames';

import MathUtils from '@utils/mathUtils';

import { ISize } from '@fbs/common/models/common';
import { IGeneralSettings } from '@/fbs/rp/models/grid';

import { UIComponent } from '@/editor/comps';
import EditorContext from '@contexts/editor';
import appOptions from '@helpers/appOptions';

interface ICompSelectingProps {
  size: ISize;
  scale: number;
  isConnector: boolean;
  selecting: boolean;
  isRotate: boolean;
  forceHover: boolean;
  isSymbol: boolean;
  locked: boolean;
}

export function CompSelecting(props: ICompSelectingProps) {
  const { size, scale, isConnector, isRotate, forceHover, selecting, isSymbol, locked } = props;
  return (
    <div
      style={{
        width: size.width * scale,
        height: size.height * scale,
        transform: `scale(${1 / scale}) translate(${size.width < 1 ? 0.5 * scale : 0}px, ${
          size.height < 1 ? 0.5 * scale : 0
        }px)`,
      }}
      className={classnames({
        locked: locked,
        selecting: !isConnector,
        show: (selecting && !isRotate) || forceHover,
        horizontal: size.height <= 1,
        vertical: size.width <= 1,
        symbol: isSymbol,
      })}
    />
  );
}

export interface RefProps {
  updateView: () => void;
}

interface ISelectingComponentProps {
  type: string;
  size: { width: number; height: number };
  componentIsHovered: boolean;
  comp: UIComponent;
  targetHovered?: boolean;
  isPreview?: boolean;
  valueEditing?: boolean;
  forceHover?: boolean;
  getIsCompSelected?: () => boolean | undefined;
}

const SelectingComponent = React.forwardRef<RefProps, ISelectingComponentProps>(function selectingComponent(
  props: ISelectingComponentProps,
  ref,
) {
  const [updatedTimes, setUpdatedTimes] = React.useState(0);
  React.useImperativeHandle(ref, () => ({
    updateView() {
      setUpdatedTimes(updatedTimes + 1);
    },
  }));
  const context = React.useContext(EditorContext);
  const { componentIsHovered, comp, valueEditing, forceHover } = props;
  const { selecting } = comp.dynamicInfo || {};
  const isRotate = comp.dynamicInfo?.rotate !== undefined;
  const isSel = props.getIsCompSelected?.() || selecting;
  if (!(isSel && !isRotate) && !forceHover && !componentIsHovered) {
    return null;
  }
  const isDynamicResize = comp.dynamicInfo?.size && Object.keys(comp.dynamicInfo).length > 0;
  if (isDynamicResize || valueEditing) {
    return null;
  }
  const { userPreference } = context;
  const { showHiddenArea = true } = MathUtils.variable(
    userPreference?.generalSettings,
    {} as Partial<IGeneralSettings>,
  );
  const toShowSelectingByCompHiddenProperty = showHiddenArea ? true : !comp.hidden;
  if (toShowSelectingByCompHiddenProperty) {
    const scale = appOptions.scale || 1;
    return (
      <CompSelecting
        size={comp.size}
        locked={!!comp.locked}
        isConnector={comp.isConnector}
        selecting={!!isSel}
        isRotate={isRotate}
        forceHover={!!forceHover}
        scale={scale}
        isSymbol={comp.isSymbol}
      />
    );
  }
  return null;
});

export default SelectingComponent;
