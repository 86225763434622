import { depthClone } from '@utils/globalUtils';

import {
  IActionBase,
  IComponentAction,
  IMoveCommandParams,
  IResizeCommandParams,
  IScaleCommandParams,
  IRotateCommandParams,
  IVisibleCommandParams,
  IStateCommandParams,
  IFragmentAction,
  PageSkipEffectType,
} from '@fbs/rp/models/interactions';
import { CommandTypes } from '@/fbs/rp/models/event';

import { PredefinedStates } from '@consts/state';

import { UIComponent } from '@editor/comps';
/**
 * 获取对应自动还原数据
 * @param action
 * @param targetComp
 */
export function getRevertAction(
  action: IActionBase,
  targetComp?: UIComponent | null | undefined,
): IActionBase | undefined {
  if (action.type === 'component') {
    let componentAction = depthClone(action) as IComponentAction;
    switch (componentAction.command) {
      case CommandTypes.move: {
        return {
          ...componentAction,
          params: revertMoveActions(componentAction.params as IMoveCommandParams, targetComp),
        };
      }
      case CommandTypes.resize: {
        return {
          ...componentAction,
          params: revertResizeActions(componentAction.params as IResizeCommandParams, targetComp),
        };
      }
      case CommandTypes.zoom: {
        return {
          ...componentAction,
          params: revertScaleActions(componentAction.params as IScaleCommandParams, targetComp),
        };
      }
      case CommandTypes.rotation: {
        return {
          ...componentAction,
          params: revertRotateActions(componentAction.params as IRotateCommandParams, targetComp),
        };
      }
      case CommandTypes.toggleVisible: {
        return {
          ...componentAction,
          params: revertVisibleActions(componentAction.params as IVisibleCommandParams),
        };
      }
      case CommandTypes.toggleState: {
        return {
          ...componentAction,
          params: revertStateActions(componentAction.params as IStateCommandParams, targetComp),
        };
      }
      default:
        return componentAction;
    }
  } else if (action.type === 'fragment') {
    let fragmentAction = depthClone(action) as IFragmentAction;
    fragmentAction.isExit = !fragmentAction.isExit;
    return fragmentAction;
  }
  return;
}

/**
 * 获取移动自动还原数据
 * @param params
 * @param comp
 */
function revertMoveActions(params: IMoveCommandParams, comp: UIComponent | null | undefined): IMoveCommandParams {
  const newParam = depthClone(params);
  if (!comp) {
    return newParam;
  }
  if (newParam.fromCurrent) {
    newParam.x = -newParam.x;
    newParam.y = -newParam.y;
  } else {
    newParam.x = comp.position.x || 0;
    newParam.y = comp.position.y || 0;
  }
  return newParam;
}

/**
 * 获取尺寸变换自动还原数据
 * @param params
 * @param comp
 */
function revertResizeActions(params: IResizeCommandParams, comp: UIComponent | null | undefined): IResizeCommandParams {
  const newParam = depthClone(params);
  if (!comp) {
    return newParam;
  }
  if (params.fromCurrent) {
    newParam.width = -newParam.width;
    newParam.height = -newParam.height;
  } else {
    newParam.width = comp.size.width;
    newParam.height = comp.size.height;
  }

  return newParam;
}

/**
 * 获取旋转自动还原数据
 * @param params
 * @param comp
 */
function revertRotateActions(params: IRotateCommandParams, comp: UIComponent | null | undefined): IRotateCommandParams {
  const newParam = depthClone(params);
  if (!comp) {
    return newParam;
  }
  if (params.fromCurrent) {
    newParam.rotate = -params.rotate;
  } else {
    newParam.rotate = comp.rotate;
  }
  return newParam;
}

/**
 * 获取缩放自动还原数据
 * @param params
 * @param comp
 */
function revertScaleActions(params: IScaleCommandParams, comp: UIComponent | null | undefined): IScaleCommandParams {
  const newParam = depthClone(params);
  if (!comp) {
    return newParam;
  }
  if (params.fromCurrent) {
    newParam.x = params.x ? 1 / params.x : comp._scale.x || 1;
    newParam.y = params.y ? 1 / params.y : comp._scale.y || 1;
  } else {
    newParam.x = comp._scale.x || 1;
    newParam.y = comp._scale.y || 1;
  }
  return newParam;
}

/**
 * 获取显隐切换自动还原数据
 * @param params
 */
function revertVisibleActions(params: IVisibleCommandParams) {
  const newParam = depthClone(params);
  if (newParam.state == 'hidden') {
    newParam.state = 'show';
  } else if (newParam.state == 'show') {
    newParam.state = 'hidden';
  } else if (newParam.state == 'toggle') {
    newParam.state = 'toggle';
  }
  return newParam;
}

/**
 * 获取状态切换自动还原数据
 * @param params
 * @param comp
 */
function revertStateActions(params: IStateCommandParams, comp: UIComponent | null | undefined): IStateCommandParams {
  const newParam = depthClone(params);
  if (!comp) {
    return newParam;
  }
  if (!comp.currentStateID) {
    newParam.stateID = PredefinedStates.normal;
  } else {
    newParam.stateID = comp.currentStateID;
  }
  return newParam;
}

/**
 * 动画取反
 * @export
 * @param {PageSkipEffectType} actionType
 * @returns {PageSkipEffectType}
 */
export function revertActionType(actionType: PageSkipEffectType): PageSkipEffectType {
  switch (actionType) {
    case 'none':
      return 'none';
    case 'pushToLeft':
      return 'pushToRight';
    case 'pushToRight':
      return 'pushToLeft';
    case 'pushToTop':
      return 'pushToBottom';
    case 'pushToBottom':
      return 'pushToTop';
    case 'slideToLeft':
      return 'slideToRight';
    case 'slideToRight':
      return 'slideToLeft';
    case 'slideToTop':
      return 'slideToBottom';
    case 'slideToBottom':
      return 'slideToTop';
    case 'flipInLeft':
      return 'flipInRight';
    case 'flipInRight':
      return 'flipInLeft';
    case 'flipInTop':
      return 'flipInBottom';
    case 'flipInBottom':
      return 'flipInTop';
    case 'fadeIn':
      return 'fadeIn';
    case 'zoomIn':
      return 'zoomIn';
    default:
      return 'none';
  }
}
