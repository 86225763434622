import * as React from 'react';

import { CPureText, CIcon, CVerticalTabs } from '@libs/constants';
import { UIContainerComponent, UIComponent } from '@/editor/comps';
import { IComponentValue } from '@/fbs/rp/models/value';
import IVerticalTabsIconStyle from '@/fbs/rp/models/properties/verticalTabsIconStyle';
import ValueItem from '../ValueItem';

import './index.scss';

export default class ImageTextTabValueItem extends ValueItem {
  // override
  renderValue() {
    const { comp } = this.props;
    const itemComp = comp as UIContainerComponent;
    const textComp = itemComp.components.find((item) => item.type === CPureText) as UIComponent;
    const iconComp = itemComp.components.find((item) => item.type === CIcon) as UIComponent;

    const root = comp.parent;
    let showIcon = true;
    if (root && root.lib?.type === CVerticalTabs) {
      showIcon = (root.properties?.iconStyle as IVerticalTabsIconStyle)?.isShow ?? true;
    }

    return (
      <div className="image-text-tab-value-item-value">
        {showIcon && this.renderIconValue(iconComp.value as IComponentValue)}
        {this.renderStringValue(textComp.value as IComponentValue)}
      </div>
    );
  }
}
