import i18n from '@/i18n';
import { SpriteThumb } from '@/consts/spriteIcons';
import { DefaultDarkFillColor, DeepBlueColor, DefaultTextColor } from '@/consts/colors';
import { IComponentData } from '@/fbs/rp/models/component';
import { IProperties } from '@/fbs/rp/models/property';
import { GaugeChartPropertyName, ChartType, PointType } from '@/fbs/rp/models/properties/gaugeChart';
import { IComponentItem } from '@/libs/types';
import { makeCommonComponent } from '@/libs/helper';
import { getChartCommonProperties } from '../common';
import { CGaugeChart } from '../../constants';

function getDefaultProperties(): IProperties {
  const commonProperties = getChartCommonProperties();
  delete commonProperties.textStyle;

  return {
    ...commonProperties,
    gaugeChart: {
      prop: GaugeChartPropertyName,
      type: ChartType.Normal,
      backgroundColor: DefaultDarkFillColor,
      progressColor: DeepBlueColor,
      gradeColorIndex: 0,
      stageColorIndex: 0,
      notchAngle: 60,
      arcWidth: 10,
      pointType: PointType.Line,

      defaultValue: 50,
      min: 0,
      max: 100,

      showLabel: true,
      labelStyle: {
        fontFamily: 'Microsoft YaHei',
        fontSize: 26,
        color: DefaultTextColor,
      },
      labelUnit: '',

      showTick: true,
      showTickLabel: true,
      bTickSplitNumber: 10,
      tickFontSize: 14,
      tickFontColor: DefaultTextColor,
      showSTick: true,
      sTickSplitNumber: 5,

      showPointer: true,
      pointerColor: DeepBlueColor,

      showDataFluctuation: false,
    },
    animation: {
      prop: 'boolean',
      value: true,
      name: i18n('property.propertyNames.showAnimation'),
    },
  };
}

export const GaugeConfig: IComponentItem = {
  type: CGaugeChart,
  name: i18n('resource.components.gaugeChart'),
  thumb: {
    spriteIconClass: SpriteThumb.GaugeChart.className,
    dragPosition: SpriteThumb.GaugeChart.position,
  },
  getDefaultData() {
    return {
      properties: getDefaultProperties(),
    };
  },
};

export function makeGaugeChart(id: string): IComponentData {
  const commonProperties = getChartCommonProperties();
  delete commonProperties.textStyle;

  return makeCommonComponent(id, CGaugeChart, {
    size: {
      width: 400,
      height: 250,
    },
    properties: getDefaultProperties(),
  });
}
