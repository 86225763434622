import * as React from 'react';

import classnames from 'classnames';

import { StyleHelper } from '@helpers/styleHelper';
import { UIContainerComponent } from '@editor/comps';
import getOpacity = StyleHelper.getOpacity;

import { IComponentProps } from '../../types';

import './index.scss';

function parserStyle(container: UIContainerComponent) {
  const { shadow } = container.properties;
  const background = container.getComponentByAlias('roundedRect') || container.components[0];
  const button = container.getComponentByAlias('button') || container.components[1];
  const { size, properties } = background;
  const { size: buttonSize, position: buttonPosition, properties: buttonProperties } = button;
  const parser = StyleHelper.createCSSStyleParser({ ...properties, shadow });
  const btnParser = StyleHelper.createCSSStyleParser(buttonProperties);
  const switchStyle = {
    ...size,
    ...parser.getRadiusStyle(size),
    ...parser.getFillStyle(size),
    ...parser.getStrokeStyle(),
    ...parser.getShadowStyle(),
    opacity: getOpacity(container.opacity),
  };
  const buttonStyle = {
    left: buttonPosition.x,
    ...buttonSize,
    ...btnParser.getFillStyle(buttonSize),
    ...btnParser.getStrokeStyle(),
    borderRadius: '100%',
    opacity: StyleHelper.getOpacity(button.opacity),
  };
  return {
    switchStyle,
    buttonStyle,
  };
}

const Switch: React.FC<IComponentProps> = (props: IComponentProps) => {
  const { switchStyle, buttonStyle } = parserStyle(props.comp as UIContainerComponent);
  return (
    <div className={classnames('lib-comp-switch', { 'component-cursor-pointer': props.isPreview })} style={switchStyle}>
      <div className="lib-comp-switch-button" style={buttonStyle} />
    </div>
  );
};

export default Switch;
