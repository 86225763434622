import { UIComponent } from '@/editor/comps';
import { CImage } from '@/libs/constants';
import { parseExtension } from '../util';
import { getImageBlob } from '../image/fetchImage';
import { parseComponent } from './parseComponent';
import { makeSvgByGroup, svgToCanvasBlob } from '../svg';
import { IExportData } from '../type';
import { geComponenttName } from '../util/filenameUtil';
/**
 * 下载原图
 */
export const parseOriginImage = async (comp: UIComponent) => {
  if (comp.type === CImage) {
    const url = comp.$data.value;
    const extension = parseExtension(url);
    const name = comp.name + '.' + extension;
    const blob = await getImageBlob(url);

    return {
      blob,
      name,
    };
  }
  const data: IExportData = await parseComponent({ t: comp, isRoot: true });
  const name = geComponenttName(comp.toJSON()) + '.png';

  const size = data.size;
  const svgGroup = await makeSvgByGroup({ data: [data], size, scales: [1] });
  if (svgGroup && svgGroup.length) {
    const svg = svgGroup[0].svg;
    const blob = await svgToCanvasBlob(svg, name, 1);
    return {
      blob,
      name,
    };
  }
};
