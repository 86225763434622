import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { getNewID } from '@/helpers/idHelper';

import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';
import { CFlowCircle } from '../constants';
import { makeEllipse } from '../basic/Ellipse/config';

export const makeFlowCircle = () => ({
  ...makeEllipse(getNewID()),
  name: i18n('resource.flow.circle'),
});

// 圆
export default {
  ...flowBase,
  name: i18n('resource.flow.circle'),
  type: CFlowCircle,
  thumb: {
    spriteIconClass: SpriteThumb.Circle.className,
    dragPosition: SpriteThumb.Circle.position,
  },
  template: getShapeData(
    i18n('resource.flow.circle'),
    { width: 100, height: 100 },
    {
      data: [
        {
          point: {
            x: 50,
            y: 0,
          },
          handleIn: {
            x: -28,
            y: 0,
          },
          handleOut: {
            x: 28,
            y: 0,
          },
        },
        {
          point: {
            x: 100,
            y: 50,
          },
          handleIn: {
            x: 0,
            y: -28,
          },
          handleOut: {
            x: 0,
            y: 28,
          },
        },
        {
          point: {
            x: 50,
            y: 100,
          },
          handleIn: {
            x: 28,
            y: 0,
          },
          handleOut: {
            x: -28,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 50,
          },
          handleIn: {
            x: 0,
            y: 28,
          },
          handleOut: {
            x: 0,
            y: -28,
          },
        },
      ],
      closed: true,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
