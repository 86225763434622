import * as React from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';
import { round } from '@utils/globalUtils';
import { parseColorToString } from '@utils/graphicsUtils';
import { ISize, IPosition } from '@fbs/common/models/common';
import { Color } from '@fbs/rp/models/properties/color';
import { UIFragment } from '@editor/comps';
import { TransparentColor } from '@consts/colors';
import EditorContext from '@contexts/editor';
export interface IArtboardProp {
  appID: string;
  artboard: UIFragment;
  // coreEditor: CoreEditor;
  isActived: boolean; // 是否激活当前面板
  isSelected: boolean; // 是否选中当前面板
  clearResize: boolean;
  scaleFactor: number;
  artboardCreating: boolean;
  compsCreating: boolean;
  isZooming: boolean;
  showInteract: boolean;
  children: React.ReactNode;

  renderChildren(): React.ReactNode;
}

export interface IArtboardState {
  size: ISize;
  position: IPosition;
  showStroke?: boolean;
  // isResize: boolean;
  dragging?: boolean;
}

/**
 * 该画板
 * 只负责显示，
 * 不需要交互
 */
class SimpleArtboard extends React.Component<IArtboardProp, IArtboardState> {
  static defaultProps: Partial<IArtboardProp> = {};
  static contextType = EditorContext;
  // @ts-ignore
  context: React.ContextType<typeof EditorContext>;

  selfRef: React.RefObject<HTMLDivElement>;

  private _artboardID: string;

  constructor(props: IArtboardProp) {
    super(props);
    this.selfRef = React.createRef();
    this._artboardID = props.artboard.artboardID;
    const {
      artboard: { size, position },
    } = this.props;
    this.state = {
      size: size,
      position: position,
      // isResize: false,
    };
  }

  get artboardID() {
    return this._artboardID;
  }

  render() {
    const {
      artboard,
      isActived,
      isSelected,
      scaleFactor,
      // isZooming,
      renderChildren,
    } = this.props;
    const { position } = artboard;
    const { size } = this.state;
    const dragging = false;
    // 更正：Color类型是背景填充支持的样式格式，使用color2hexString做兼容
    const artboardBGColor = artboard.background.disabled ? TransparentColor : (artboard.background.color as Color);
    // const data = isZooming ? undefined : this.parseContextFragmentAction();

    let actionEditing = false;
    // if (data?.fragment.artboardID === artboard.artboardID) {
    //   actionEditing = true;
    // }
    const left = round(position.x * scaleFactor);
    const top = round(position.y * scaleFactor);
    const artboardStyle = _.memoize(
      (): React.CSSProperties => ({
        zIndex: dragging ? 20000 : undefined,
        // transform: dragging || isSelected  ? `translate3d(${left}px, ${top}px, 0)` : `translate(${left}px, ${top}px)`,
        transform: left || top ? `translate(${left}px, ${top}px)` : undefined,
      }),
    )();
    const contentStyle = _.memoize(
      (): React.CSSProperties => ({
        width: round(size.width * scaleFactor),
        height: round(size.height * scaleFactor),
        backgroundColor: actionEditing ? 'transparent' : parseColorToString(artboardBGColor) /*使用rgba值*/,
      }),
    )();
    const scaleStyle: React.CSSProperties = _.memoize(
      (): React.CSSProperties => ({
        transform: `scale(${scaleFactor}, ${scaleFactor})`,
        transformOrigin: '0 0',
        pointerEvents: isSelected ? 'none' : 'auto',
      }),
    )();

    return (
      <div
        key={artboard.artboardID}
        id={artboard.artboardID}
        data-id={artboard.artboardID}
        ref={this.selfRef}
        className={classnames('artboard', { 'active-artboard': isActived })}
        style={artboardStyle}
      >
        <div
          data-id={artboard.artboardID}
          // ref={this.props.getContentDom}
          className="content"
          style={contentStyle}
        >
          <div style={scaleStyle}>{renderChildren()}</div>
        </div>
      </div>
    );
  }
}

export default SimpleArtboard;
