import { getOfflineDemoData } from '@apis/offlineDemo/data';
import { EIDocDomain } from '@/consts/enums/url';
import { TeamType } from '@/consts/team';
import i18n, { languageManager } from '@/i18n';
import { TeamVersion, ITeams, freeProgramHasExpired, TeamManager } from '@/fbs/teamManagement';

/**
 * 获取地址栏中的参数
 */
export function getQueryString(key: string) {
  const searchString = window.location.search;
  const params = getParams(searchString);
  return params[key];
}

function getParams(search: string) {
  const queryString = search.split('?')[1];
  if (!queryString) {
    return {};
  }
  const params = queryString.split('&').reduce((prev, curr) => {
    const [key, value] = curr.split('=');
    if (key) {
      prev[key] = value;
    }
    return prev;
  }, {} as { [key: string]: string });

  return params;
}

export function parseUrlSearch(searchStr = window.location.search) {
  let query = searchStr;
  if (searchStr.startsWith('?')) {
    query = searchStr.substring(1);
  }
  const vars = query.split('&');
  const obj: { [key: string]: string } = {};

  vars.forEach((item) => {
    const arr = item.split('=');
    // eslint-disable-next-line no-magic-numbers
    if (arr.length < 2) {
      return;
    }
    const key = arr[0];
    arr.shift();
    obj[key] = arr.join('');
  });
  return obj;
}

export class Link {
  protocol = {
    http: 'http:',
    https: 'https:',
    ftp: 'ftp:',
    file: 'file:',
  };

  urlRegExp = /^(file|https?|ftp):\/\//;

  value: string;

  masterURL?: string;

  constructor(value?: string, private isIDocDomain?: boolean) {
    this.masterURL = value;
    this.value = this.getURL(value);
  }

  get origin() {
    // 私有部署离线情况
    if (RP_CONFIGS.isPrivateDeployment && RP_CONFIGS.isOfflineDemo) {
      const iDocDomain = (this.isIDocDomain && window.apis?.iDocDomain) || '';
      return `${getOfflineDemoData()?.config.origin}${iDocDomain}`;
    }

    if (this.isIDocDomain) {
      const fallbackDomain = languageManager.isZHVersion ? EIDocDomain.ZH : EIDocDomain.EN;
      return window.apis?.iDocDomain ?? fallbackDomain;
    }

    return getOfflineDemoData()?.config.origin || window.location.origin;
  }

  private getURL(value?: string) {
    if (!value) {
      return '';
    }
    value = value.replace(/^\//, `${this.origin}/`);
    // TODO Matt 2023-01-28 相对路径时
    if (value.startsWith('/')) {
      return value;
    }

    if (!this.urlRegExp.test(value)) {
      return `http://${value}`;
    }
    return value;
  }

  open(target?: string, features?: string, replace?: boolean) {
    window.open(this.value, target, features, replace);
  }
}

/**
 * 跳转升级页面
 * @param teamInfo
 */
export function openUpgradeUrl(teamInfo?: ITeams | null) {
  const isEN = languageManager.isEnVersion;
  if (teamInfo?.teamType === TeamType.Personal || window.featureFlagPersonalSpace) {
    window.open(i18n('application.upgradePersonalSpaceURL'), '_blank');
    return;
  }
  if (isEN) {
    window.open(i18n('application.upgradeTeamURL'), '_blank');
    return;
  }
  if (teamInfo?.license.version === TeamVersion.Free || teamInfo?.license.expired) {
    if (!freeProgramHasExpired(teamInfo?.rpDesignPayment, teamInfo?.rpDesignExpireTime)) {
      TeamManager.openCashierPage(teamInfo!, isEN);
      return;
    }
    TeamManager.openPricePage(teamInfo, isEN);
    return;
  }
  TeamManager.openCashierPage(teamInfo!, isEN);
}

/**
 * 获取价格页面的链接，在RP应用内打开 - 此处与RP项目编辑保持一致，如有修改请同步
 * @param teamInfo   团队信息
 * @param isExpansion   是否为 版本团队扩容
 */
export function getUpgradeShopUrl(teamInfo?: ITeams, isExpansion?: boolean): string {
  const isENVersion = languageManager.isEnVersion;
  if (!teamInfo) {
    return '';
  }
  return TeamManager.getCashierLink(teamInfo, isENVersion, isExpansion, teamInfo.teamType === TeamType.Personal);
}

/**
 * url分割符，用于拼接
 * @param url
 * @returns
 */
export const getUrlSplitChar = (url: string) => {
  if (url.indexOf('?') >= 0) {
    return '&';
  }
  return '?';
};
