import { cloneDeep, isNumber, isObject } from 'lodash';
import { isEmpty } from '@utils/boundsUtils';

/**
 * 生成一个8位的guid
 * @returns {string}
 */
interface IPoint {
  x: number;
  y: number;
}

export function shortGuid(): string {
  return new Date().getTime().toString(36);
}

/**
 * 获取两个数之间差值
 * @param {number} v1
 * @param {number} v2
 * @returns {number}
 */
export function distance(v1: number, v2: number): number {
  if (v1 > v2) {
    return v1 - v2;
  }
  return v2 - v1;
}

export interface INumberRange {
  min: number;
  max: number;
}

/**
 * 判断一个数据是否包含在某个范围内
 * @param {number} value
 * @param {INumberRange} range
 * @returns {boolean}
 */
export function between(value: number, range: INumberRange, includeRange?: boolean) {
  if (includeRange) {
    return value >= range.min && value <= range.max;
  }
  return value > range.min && value < range.max;
}

/**
 * 判断两个数据范围是否包含
 * @param {INumberRange} s
 * @param {INumberRange} t
 * @returns {boolean}
 */
export function betweenRange(s: INumberRange, t: INumberRange, includeRange?: boolean) {
  return (
    (between(t.min, s, includeRange) && between(t.max, s, includeRange)) ||
    (between(s.min, t, includeRange) && between(s.max, t, includeRange))
  );
}

export function intersectRange(s: INumberRange, t: INumberRange, includeRange?: boolean): boolean {
  return (
    between(s.min, t, includeRange) ||
    between(s.max, t, includeRange) ||
    between(t.max, s, includeRange) ||
    between(t.min, s, includeRange)
  );
}

export function equalRange(s: INumberRange, t: INumberRange): boolean {
  return sameNumber(s.min, t.min) && sameNumber(s.max, t.max);
}

/**
 * 判断一个数是否在某个区间内
 * @param {number} reference
 * @param {number} target
 * @param {number} offset
 * @returns {boolean}
 */
export function isMoreOrLess(reference: number, target: number, offset: number): boolean {
  return target >= reference - offset && target <= reference + offset;
}

/**
 * 四舍五入取整 解决Math函数性能低的问题，结果等同于Math.round
 * @param {number} value
 * @returns {number}
 */
export function round(value: number): number {
  const mod = value % 1;
  if (mod === 0) {
    return value;
  }
  let v = value;
  if (value > 0) {
    v = v + 0.5;
    return v - (v % 1);
  } else {
    v = 0 - v + 0.5;
    return 0 - (v - (v % 1));
  }
}

/**
 * 取绝对值，结果等同于Math.abs
 * @param {number} value
 * @returns {number}
 */
export function abs(value: number): number {
  return value > 0 ? value : 0 - value;
}

/**
 * 取下整数，结果等同于Math.floor
 * @param {number} value
 * @returns {number}
 */
export function floor(value: number): number {
  const mod = value % 1;
  if (mod === 0) {
    return value;
  }
  if (value > 0) {
    return value - mod;
  }
  return value - 1 - mod;
}

/**
 * 取上整数，结果等同于Math.ceil
 * @param {number} value
 * @returns {number}
 */
export function ceil(value: number): number {
  const mod = value % 1;
  if (mod === 0) {
    return value;
  }
  if (value > 0) {
    return value - mod + 1;
  }
  return value - mod;
}

/**
 * 取小，结果等同于Math.min
 * @param {number} vn
 * @returns {number}
 */
export function min(...vn: number[]): number {
  const values = Array.from(vn);
  if (!values.length) {
    return NaN;
  }
  let v = values.pop()!;
  while (values.length > 0) {
    const temp = values.pop()!;
    if (v > temp) {
      v = temp;
    }
  }
  return v;
}

/**
 * 取大，结果等同于Math.max
 * @param {number} vn
 * @returns {number}
 */
export function max(...vn: number[]): number {
  const values = Array.from(vn);
  if (!values.length) {
    return NaN;
  }
  let v = values.pop()!;
  while (values.length > 0) {
    const temp = values.pop()!;
    if (v < temp) {
      v = temp;
    }
  }
  return v;
}

/**
 * 深度克隆一个对象，忽略对象中的函数
 * @param {T} data 该对象不能是一个函数
 * @returns {T}
 */
export function depthClone<T>(data: T): T {
  // FIXME Matt lodash 的性能更好
  // lodash cloneDeep很慢
  // const result = JSON.stringify(data);
  // return result === undefined ? result : JSON.parse(result);
  return cloneDeep(data);
  // const type = typeof data;
  // if (type === 'string' || type === 'number' || type === 'boolean' || type === 'undefined' || data === null) {
  //   return data;
  // }
  // if (type === 'function') {
  //   throw new Error('data is a Function');
  // }
  // if (Array.isArray(data)) {
  //   // @ts-ignore
  //   return data.map((item) => depthClone(item));
  // } else {
  //   const keys = Object.keys(data);
  //   const result: Partial<T> = {};
  //   type PropName = keyof T;
  //   keys.forEach((key) => {
  //     const propName = key as PropName;
  //     const value = data[propName];
  //     if (typeof value !== 'function') {
  //       result[propName] = depthClone(value);
  //     }
  //   });
  //   return result as T;
  // }
}

/**
 * 克隆一个json, 可清除内部引用关系
 * @param {T} data A JavaScript value, usually an object or array, to be converted.
 * @returns {T}
 */
export function jsonClone<T>(data: T): T {
  return JSON.parse(JSON.stringify(data));
}

export function merge(source: any, target: any, fn?: (key: string, s: any, t: any) => any): any {
  try {
    if (source && target && typeof source === 'object' && typeof target === 'object') {
      if (Array.isArray(source)) {
        if (Array.isArray(target)) {
          return [...source, ...target];
        } else {
          return [...source, target];
        }
      } else if (Array.isArray(target)) {
        return [source, ...target];
      } else {
        const keys = new Set([...Object.keys(source), ...Object.keys(target)]);
        const result: { [key: string]: any } = {};

        keys.forEach((key) => {
          const sourceVal = source[key];
          const targetVal = target[key];
          const value = fn ? fn(key, sourceVal, targetVal) : undefined;
          if (typeof value !== 'undefined') {
            result[key] = value;
          } else {
            const sourceValueType = typeof sourceVal;
            const targetValueType = typeof targetVal;

            if (sourceValueType === 'object' && targetValueType === 'undefined') {
              result[key] = depthClone(sourceVal);
            } else {
              result[key] = sourceVal;
            }
            if (targetValueType !== 'undefined') {
              if (sourceValueType === 'undefined') {
                result[key] = depthClone(targetVal);
              } else if (
                sourceValueType === 'string' ||
                sourceValueType === 'number' ||
                sourceValueType === 'boolean'
              ) {
                result[key] = targetVal;
              } else if (sourceValueType === 'object' && typeof targetVal === 'object') {
                result[key] = merge(sourceVal, targetVal, fn);
              }
            }
          }
        });
        return result;
      }
    }
  } catch (e) {
    console.warn(`${source}, ${target} 不是对象，不能合并`);
  }
}

/**
 * 合并组件中data和states下的data
 * @param source 基础数据 data
 * @param target 合并数据  state data
 * @param fn
 */
export function mergeStateData<T, F>(
  source: T,
  target: F,
  fn?: (key: string, s: Record<string, any>, t: Record<string, any>) => any,
): any {
  try {
    const sourceType = getDataType(source);
    const targetType = getDataType(target);
    if (sourceType === DataType.Object && targetType === DataType.Object) {
      const keys = new Set([...Object.keys(source).concat(Object.keys(target))]);
      const result: Record<string, any> = {};

      for (let key of keys) {
        const sourceVal = source[key as keyof T];
        const targetVal = target[key as keyof F];
        const value = fn ? fn(key, sourceVal, targetVal) : undefined;
        if (typeof value !== 'undefined') {
          result[key] = value;
        } else {
          const sourceValueType = getDataType(sourceVal);
          const targetValueType = getDataType(targetVal);

          // 如果sourceVal和targetVal都为对象，递归处理，如果不是，都取targetVal
          if (sourceValueType === DataType.Object && targetValueType === DataType.Object) {
            result[key] = mergeStateData<T[keyof T], F[keyof F]>(sourceVal, targetVal, fn);
          } else {
            result[key] = targetVal ?? sourceVal;
          }
        }
      }
      return result;
    }
  } catch (e) {
    console.warn(`${source}, ${target} 不是对象，不能合并`);
  }
}

// valueToProxy方法所需的两个symbol
const proxyTargetSymbol = Symbol('proxyTarget');
const isFromValueToProxySymbol = Symbol('isFromValueToProxy');

// 将值转为proxy，console.warn收集更改信息的引发的操作
export function valueToProxy(value: any): any {
  if (!value || typeof value !== 'object') {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map((item) => valueToProxy(item));
  }

  // const proxyTarget: Record<string | symbol, any> = {
  //   [Symbol('sourceData')]: value,
  // };
  // for (let key in value) {
  //   proxyTarget[key] = 'this value acquired by proxy getter.';
  // }

  return new Proxy(value, {
    get: (target, prop) => {
      // 返回源数据
      if (prop === proxyTargetSymbol) {
        return target;
      }
      // 是否是valueToProxy返回的proxy
      if (prop === isFromValueToProxySymbol) {
        return true;
      }
      return valueToProxy(target[prop]);
    },
    // 阻止对数据的更改
    set: (target, prop: string, value) => {
      // console.error比较醒目，方便看到问题，上线前修改成console.warn
      console.error('不允许的属性更改', target, prop, value);
      // return false会导致页面异常跳到错误页
      return true;
    },
  });
}

/**
 * 获取valueToProxy生成的原始值
 * @param proxy
 */
export function getProxyTargetValue(proxy: any, shouldClone = true) {
  if (getDataType(proxy) === DataType.Object) {
    if (proxy[isFromValueToProxySymbol]) {
      return shouldClone ? cloneDeep(proxy[proxyTargetSymbol]) : proxy[proxyTargetSymbol];
    }
  }
  return proxy;
}

/**
 * 合并数据，只有当source某个对应的key值为undefined时，才使用target中对应的值
 * @param {T} source
 * @param {T} target
 * @param needDeepCompare 是否为深层次比较合并数据
 * @returns {T}
 */
export function simpleMerge<T>(source: T, target: T, needDeepCompare?: boolean): T {
  if (typeof source === 'object' && typeof target === 'object') {
    const keys = Object.keys(source);
    type KeyType = keyof T;
    const result = { ...source };
    keys.forEach((key: string) => {
      let value = source[key as KeyType];
      if (typeof value === 'undefined') {
        value = target[key as KeyType];
        result[key as KeyType] = value;
      } else if (needDeepCompare) {
        result[key as KeyType] = simpleMerge(value, target[key as KeyType], needDeepCompare);
      }
    });
    return result;
  }
  return source;
}

export const EPSILON = 0.00001;

/**
 * 判断两个数是否相等
 * @param {number} v1
 * @param {number} v2
 * @param {number} epsilon
 * @returns {boolean}
 */
export function sameNumber(v1: number, v2: number, epsilon?: number): boolean {
  const _epsilon = epsilon || EPSILON;
  return Math.abs(v1 - v2) <= _epsilon || v1 === v2;
}

export function notSameNumber(v1: number, v2: number): boolean {
  return !sameNumber(v1, v2);
}

export function isEqual0(v1: number): boolean {
  return sameNumber(v1, 0);
}

export function isNotEqual0(v1: number): boolean {
  return notSameNumber(v1, 0);
}

export function sameObject(o1: any, o2: any, depthCompare: boolean = true): boolean {
  if (depthCompare) {
    return JSON.stringify(o1) === JSON.stringify(o2);
  }
  return o1 === o2;
}

/**
 * 返回值的小数部分，如果没有小数，就返回undefined
 * @param value
 */
export function getDecimalPart(value: string | number): string | undefined {
  return (value + '').split('.')[1];
}

/**
 * 判断两个数组中是否包含相同的项，不区分顺序
 * @param arr
 * @param args
 * @returns {boolean}
 */
export function arrayEquals(arr: any[], args: any[]): boolean {
  return args.every((item) => arr.includes(item)) && arr.every((item) => args.includes(item));
}

/**
 * 标准坐标系下计算得到坐标
 * @param center 圆心
 * @param radius 半径
 * @param angle  逆时针的角度（弧度制）
 */
export function getPointOnCycle(center: IPoint, radius: number, angle: number) {
  // 极坐标下的圆的方程如下
  // x = r*cos(θ) + a
  // y = r*sin(θ) + b
  // a,b是圆心坐标
  return {
    x: center.x + radius * Math.cos(angle),
    y: center.y + radius * Math.sin(angle),
  };
}

/**
 * rp坐标系下按照圆的极坐标计算圆上任意点的坐标
 * @param center 圆心
 * @param radius 半径
 * @param angle  逆时针的角度（弧度制）
 */
export function getPointOnCycleInCurrentCoordination(center: IPoint, radius: number, angle: number) {
  //我们的坐标系是和标准坐标系关于X轴对称的
  const transformedCenter = {
    x: center.x,
    y: -center.y,
  };
  const point = getPointOnCycle(transformedCenter, radius, angle);
  return {
    x: point.x,
    y: -point.y,
  };
}

/**
 * 对比两个任意类型的变量是否相等(暂不支持Function)
 * @param data1
 * @param data2
 */
export const isEqualDate = (data1: any, data2: any) => {
  // 判断类型
  const typeA = getDataType(data1);
  const typeB = getDataType(data2);
  if (typeA !== typeB) {
    return false;
  } else if (typeA === DataType.Base) {
    return data1 === data2;
  } else if (typeA === DataType.Array) {
    if (data1.length !== data2.length) {
      return false;
    } else {
      // 循环遍历数组的值进行比较
      for (let i = 0; i < data1.length; i++) {
        if (!isEqualDate(data1[i], data2[i])) {
          return false;
        }
      }
      return true;
    }
  } else if (typeA === DataType.Object) {
    if (Object.keys(data1).length !== Object.keys(data2).length) {
      return false;
    }
    for (const o in data1) {
      if (!isEqualDate(data1[o], data2[o])) {
        return false;
      }
    }
    return true;
  } else {
    return typeA === DataType.Undefined || typeA === DataType.Null;
  }
};

export enum DataType {
  Array = 'Array',
  Object = 'Object',
  Function = 'Function',
  Undefined = 'Undefined',
  Null = 'Null',
  Base = 'Base',
  Error = 'Error',
}

/**
 * 获取变量的类型
 * @param {Object} data
 * @return DataType
 */
export const getDataType = (data: any): DataType => {
  const type = Object.prototype.toString.call(data);
  switch (type) {
    case '[object Array]':
      return DataType.Array;
    case '[object Object]':
      return DataType.Object;
    case '[object Function]':
      return DataType.Function;
    case '[object Undefined]':
      return DataType.Undefined;
    case '[object Null]':
      return DataType.Null;
    case '[object Number]':
    case '[object String]':
    case '[object Boolean]':
      return DataType.Base;
    default:
      return DataType.Error;
  }
};

/**
 * 获取变量的类型
 * @param {Object} data
 * @return boolean
 */
export const isBaseData = (data: any): boolean => {
  const type = Object.prototype.toString.call(data);
  switch (type) {
    case '[object Number]':
    case '[object String]':
    case '[object Boolean]':
      return true;
    default:
      return false;
  }
};

export function isEmptyObject(obj: Object) {
  const hasOwnProperty = Object.prototype.hasOwnProperty;
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
  return true;
}

/**
 * 移动数组中一个元素到另一个位置
 * @param arr
 * @param oldIndex
 * @param newIndex
 */
export function moveArrayItem<T>(arr: T[], oldIndex: number, newIndex: number): T[] {
  if (oldIndex === newIndex) {
    return arr;
  }
  const temps = [...arr];
  // @ts-ignore
  const t = temps.splice(oldIndex, 1, undefined)[0];
  temps.splice(newIndex, 0, t);
  return temps.filter((t) => !!t);
}

/**
 * 移动数组中一截元素到另一个位置
 */
export function moveArrayItems<T>(arr: T[], oldIndex: number, newIndex: number, length = 1): T[] {
  if (oldIndex === newIndex) {
    return arr;
  }
  const temps = depthClone(arr);
  // @ts-ignore
  const t = temps.splice(oldIndex, length);
  temps.splice(newIndex, 0, ...t);
  return temps;
}

/**
 * 数组删除某一项
 * @param {Array} arr
 * @param {*} item
 */
export function removeItemFromArray(arr: any[], item: any) {
  const index = arr.indexOf(item);
  if (index > -1) {
    arr.splice(index, 1);
  }
}

/**
 * 防抖
 * @param {Function} callback
 * @param {number} delay
 * @returns {() => void}
 */
export function debounce(callback: Function, delay: number) {
  let t: Timeout | undefined = undefined;
  return function () {
    clearTimeout(t);
    t = window.setTimeout(callback, delay);
  };
}

/**
 * 限流
 * @param {Function} callback
 * @param {number} duration
 * @returns {() => void}
 */
export function throttle(callback: Function, duration: number) {
  let lastTime = new Date().getTime();
  return function () {
    const now = new Date().getTime();
    if (now - lastTime > duration) {
      callback();
      lastTime = now;
    }
  };
}

export function validateNumberText(str: string, defaultValue: string = ''): string {
  if (!str) {
    return '';
  }
  const pattern = /^-?\d*\.?\d*$/;
  let result = str;
  const [c0] = str;
  if (c0 === '.') {
    result = `0${str}`;
  }

  if (c0 === '0') {
    if (str.length > 1) {
      const c1 = str[1];
      if (c1 !== '.') {
        result = '0';
      }
    }
  }
  let count = 0;
  for (let i = 0; i < result.length; i++) {
    if (result[i] === '.') {
      count += 1;
      if (count > 1) {
        result = result.substring(0, i);
        break;
      }
    }
  }
  if (pattern.test(result)) {
    return result;
  }
  return defaultValue;
}

/**
 * 用于之后的数字输入框的数学表达式计算
 * @param {string} str
 * @returns {number | undefined}
 * @description 仅支持 +-*\/×÷(), 不支持连+等运算，所有不支持的数据，返回undefined;
 */
export function calcValueFromText(str: string): number | undefined {
  // eslint-disable-next-line no-useless-escape
  const reg = /(\d.\+\-\*\/\(\))*/;
  const txt = str.replace(/\s/g, '').replace('×', '*').replace('÷', '/');
  if (reg.test(txt)) {
    const operator = '+-*/.';
    let frontBrackets = 0;
    let backBrackets = 0;
    const charts = txt.split('');
    const isTrue = charts.every((char, i) => {
      if (i > 0) {
        const pre = charts[i - 1];
        if (operator.indexOf(char) !== -1) {
          return operator.indexOf(pre) === -1;
        }
        return true;
      }
      return true;
    });
    charts.forEach((c) => {
      if (c === '(') {
        frontBrackets++;
      } else if (c === ')') {
        backBrackets++;
      }
    });

    if (!isTrue || frontBrackets !== backBrackets) {
      return undefined;
    }
    try {
      return new Function(`return ${txt}`)();
    } catch {
      return undefined;
    }
  }
  return undefined;
}

/**
 * 当前是否正在文本编辑中
 * @returns {boolean}
 */
export function isInputting(): boolean {
  const el = document.activeElement;
  if (el) {
    const dom = el as HTMLElement;
    const tagName = dom.tagName;
    //修改样式的时候，可能会错误的导致FontStyleHelper的textDom被错误识别为文本编辑状态
    //需要过滤掉FontStyleHelper添加的isContentEditable情况
    const isContentEditable = dom.isContentEditable && dom.id !== 'text-decoration-line-editor';
    return isContentEditable || ['INPUT', 'TEXTAREA'].includes(tagName);
  }
  return false;
}

/**
 * 计算数组中重复次数最多，及最少的内容
 * @param arr
 */
export function getCountNumInArray(arr: any[]) {
  const countObj: { [key: string]: number } = {};
  arr.forEach((item) => {
    const strItem = JSON.stringify(item);
    const originCount = countObj[strItem];
    if (originCount) {
      countObj[strItem] += Number(originCount);
    } else {
      countObj[strItem] = 1;
    }
  });

  const keyObj = Object.keys(countObj);
  let maxCount = 0;
  let minCount = 0;
  let maxKey = '';
  let minKey = '';
  keyObj.forEach((key, index) => {
    const count = countObj[key];
    if (index === 0) {
      minCount = count;
      minKey = key;
    }

    if (count > maxCount) {
      maxCount = count;
      maxKey = key;
    }

    if (count < minCount) {
      minCount = count;
      minKey = key;
    }
  });

  return {
    maxKey: JSON.parse(maxKey),
    maxCount,
    minKey: JSON.parse(minKey),
    minCount,
  };
}

export function isNotEmpty(arg: any): Boolean {
  return !isEmpty(arg);
}

export function replaceArrayItem(array: Array<any>, index: number, data?: any) {
  if (index >= 0 && index <= array.length - 1) {
    if (data) {
      array.splice(index, 1, data);
    } else {
      array.splice(index, 1);
    }
  }
  return array;
}

// export function mapFun<T>(callback: Function, t: T, ...params: any[]) {
//   type keyType = keyof T;
//   const result: { [key: string]: Function } = {};
//   Object.keys(t).forEach(key => {
//     const value = t[key as keyType];
//     result[key] = () => {
//       callback.call(null, arguments, value, params);
//     };
//   });
//   return result;
// }

/**
 * 展开一个树结构到一个一维级数
 * @param {T[]} array
 * @param {(item: T) => T[]} itemFun
 * @returns {T[]}
 */
export function flatArray<T>(array: T[], itemFun: (item: T) => T[] | null): T[] {
  const list: T[] = [];
  array.forEach((item) => {
    const childItems = itemFun(item);
    if (!childItems?.length) {
      list.push(item);
    } else {
      list.push(...flatArray(childItems, itemFun));
    }
  });
  return list;
}

/**
 * 返回某个范围内的值
 * @param value 原值
 * @param between.min 最小值
 * @param between.max 最大值
 */
export function betweenNumber(
  value: number,
  between: {
    min: number;
    max: number;
  },
) {
  return Math.min(between.max, Math.max(value, between.min));
}

/**
 * 将对象中的所有number类型的值缩放scale倍
 * @param obj
 * @param scale
 * @param options.min 最小值
 * @param options.max 最大值
 */
export function scaleObjectValue<T>(
  obj: { [key: string]: any },
  scale: number,
  options?: {
    min?: number; // 最小值
    max?: number; // 最大值
    filter?: (oldValue: number) => void; // 过滤器（过滤小数之类的）
  },
): T {
  return Object.keys(obj).reduce((prev, curr) => {
    let value = obj[curr];
    if (typeof value === 'number') {
      value *= scale;
      if (options?.min) {
        value = Math.max(value, options.min);
      }
      if (options?.max) {
        value = Math.min(value, options.max);
      }
      if (options?.filter) {
        value = options.filter(value);
      }
    }
    prev[curr] = value;
    return prev;
  }, {} as { [key: string]: any }) as T;
}

/**
 * 处理数字精度，如：55.999999999999986 -> 56
 * @param value 需要处理精度的数字.
 */
export function to12Precision(value: number): number {
  return Number(value.toPrecision(12));
}

/**
 * 自增字符串后缀编号
 * @param {string[]} source
 * @param {string} temp
 * @returns {string}
 */
export function autoSerialnumber(source: string[], temp: string) {
  let result = temp;
  if (source.length) {
    // eslint-disable-next-line no-useless-escape
    const regExp = new RegExp(`${temp}\d*`);
    const matchSize = source
      .filter((item) => {
        if (item.indexOf(temp) !== -1) {
          return regExp.test(item);
        }
        return false;
      })
      .map((item) => {
        const num = item.replace(temp, '').trim();
        return num ? parseInt(num, 10) : 0;
      })
      .sort((a, b) => {
        return a - b;
      });

    result = matchSize.length ? `${temp} ${matchSize[matchSize.length - 1] + 1}` : temp;
  }
  return result;
}

/**
 * 替换数组中的首个达到过滤规则的值
 * @param arr
 * @param findIndex 过滤规则
 * @param replace 新值
 */
export function replaceArray<T>(arr: T[], findIndex: (data: T) => boolean, replace: (data: T) => T): T[] {
  const newArr = [...arr];
  const index = newArr.findIndex(findIndex);
  if (index !== -1) {
    const newData = replace(newArr[index]);
    newArr.splice(index, 1, newData);
  }
  return newArr;
}

/**
 * 替換/填充数组中首个达到过滤规则的值
 * * 找不到就填充
 * @param arr
 * @param findIndex
 * @param replace
 */
export function replaceOrPushArray<T>(arr: T[], findIndex: (v: T) => boolean, replace: (v?: T) => T): T[] {
  const newArr = [...arr];
  const index = newArr.findIndex(findIndex);
  const newData = replace(newArr[index]);
  index !== -1 ? newArr.splice(index, 1, newData) : newArr.push(newData);
  return newArr;
}

/**
 * 替換/填充数组中首个达到过滤规则的值
 * * 找不到就填充
 * @param arr
 * @param findIndex
 * @param replace
 */
export function replaceOrUnshiftArray<T>(arr: T[], findIndex: (v: T) => boolean, replace: (v?: T) => T): T[] {
  const newArr = [...arr];
  const index = newArr.findIndex(findIndex);
  const newData = replace(newArr[index]);
  index !== -1 ? newArr.splice(index, 1, newData) : newArr.unshift(newData);
  return newArr;
}

/**
 * 将一维数组按照传入的num为单个二维数组的length来分割原始数组
 * @param arr
 * @param num
 */
export function transArrayLinearToDouble<T>(arr: T[], num: number): T[][] {
  const doubleArr: T[][] = [];
  let range = num;
  for (let i = 0; i < arr.length; i += num) {
    doubleArr.push(arr.slice(i, range));
    range += num;
  }

  return doubleArr;
}

/**
 * 字符串格式化
 * @param {string} text
 * @param {string} value
 * @return {string}
 */
export function formatString(text: string, ...value: string[]): string {
  return text.replace(/{(\d+)}/g, (match: string, index: number) => {
    const v = value[index];
    if (typeof v === 'undefined') {
      return match;
    }
    return v;
  });
}

/**
 * 时间戳转日期 天、小时
 * @param time // 时间戳
 */
export function countDownTime(time: number) {
  const nowTime = new Date().getTime();
  const timeDifference = (time - nowTime) / 1000;
  if (timeDifference <= 0) {
    return;
  }
  const d: string | number = parseInt(`${timeDifference / 60 / 60 / 24}`);
  let h: string | number = parseInt(`${(timeDifference / 60 / 60) % 24}`);
  h = h < 10 ? '0' + h : h;
  return { day: d, hour: h };
}

/**
 * 使用a标签打开链接 -- 处理腾讯会议中需要打开外部浏览器问题
 */
export function useLabelAOpenUrl(href: string, target: '_blank' | '_self' = '_blank') {
  const link = document.createElement('a');
  link.href = href;
  link.target = target;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

/**
 * 过期检查
 * @param beginTime 开始时间'YYYY/MM/DD hh:mm:ss'
 * @param days 持续天数
 */
export function isSomethingExpired(beginTime: string, days: number) {
  return new Date(beginTime).getTime() + days * 24 * 60 * 60 * 1000 < new Date().getTime();
}

export function nextFrameNoop() {
  return new Promise((resolve) => {
    requestAnimationFrame(() => resolve());
  });
}

export function memoLatest<T extends (...args: any) => any>(func: T, resolver?: (...args: Parameters<T>) => any) {
  if (typeof func != 'function' || (resolver && typeof resolver != 'function')) {
    throw new TypeError('Expected a function');
  }
  let cacheKey: any;
  let cacheValue: any;
  const memoized = (...args: Parameters<T>) => {
    const newCacheKey = resolver ? resolver(...args) : args[0];
    if (cacheKey !== newCacheKey) {
      cacheKey = newCacheKey;
      cacheValue = func();
    }

    return cacheValue;
  };
  return memoized;
}

/**
 * 数字转千分位展示
 * @param num
 */
export function toThousands(num: number) {
  const str = num.toString();
  const reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
  return str.replace(reg, '$1,');
}

/**
 * 四舍五入Object对象的number
 * @param value:IPosition|ISize
 */
export function roundNumberObject<T extends {}>(value: T): T {
  if (!isObject(value)) {
    return value;
  }
  const result = cloneDeep(value);
  type Tkey = keyof typeof value;
  Object.keys(value).forEach((key) => {
    const f = key as Tkey;
    const v = value[f];
    if (isNumber(v)) {
      result[f] = (round(v) as unknown) as T[Tkey];
    }
  });
  return result;
}
