import * as React from 'react';
import * as tinycolor from 'tinycolor2';
import classnames from 'classnames';
import { isString } from 'lodash';

import { IColorInfo } from './models';

import Saturation from './Saturation';
import Hue from './Hue';
import Alpha from './Alpha';
import EditableInput from './EditableInput';
import PresetColor from './PresetColor';
import { COLOR_PICKER_PRESET_COLOR } from '../../config';
import withAutoClose, { WithOnCloseProps } from '../../withAutoClose';

import './index.scss';

export interface IColorPickerState {
  color: IColorInfo;
}

interface IColorPickerProps extends WithOnCloseProps {
  color: string;
  cls: string;
  changeColor: Function;
  onSubmitColor: Function;
  forwardedRef?: React.RefObject<HTMLDivElement>;
  onChange(color: IColorInfo | string): void;
}

function colorToColorInfo(value: string): IColorInfo {
  const color = tinycolor(value);
  const hsv = color.toHsv();
  const hex = color.toHex();
  const rgba = color.toRgb();
  const alpha = color.getAlpha();
  let originalValue = hex;
  if (isString(value) && value.indexOf('#') !== -1) {
    originalValue = value.slice(1);
  }
  return {
    hsv,
    hex,
    rgba,
    alpha,
    originalValue: originalValue,
  };
}

class ColorPicker extends React.Component<IColorPickerProps, IColorPickerState> {
  static defaultProps: Partial<IColorPickerProps> = {
    color: '#FFFFFF',
  };

  constructor(props: IColorPickerProps) {
    super(props);
    this.state = {
      color: colorToColorInfo(props.color),
    };
  }

  componentDidMount(): void {
    window.addEventListener('keydown', this.onWindowKeyDown);
  }

  componentWillUnmount(): void {
    window.removeEventListener('keydown', this.onWindowKeyDown);
  }

  onWindowKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.props?.onClose();
      return;
    }

    if (e.key === 'Enter') {
      e.stopPropagation();
      this.props.onSubmitColor();
      return;
    }
  };

  onChangeColor = () => {
    this.props.changeColor(this.state.color);
  };

  // FIXME: fix any
  changeColor = (value: tinycolor.ColorFormats.HSVA | any) => {
    // 当s为0时，无法调整hue的值，所以在此判断一下
    if (value.s === 0) {
      value.s = 0.001;
    }

    if (value.v === 0) {
      value.v = 0.01;
    }

    const color = tinycolor(value);
    const rgbaStr = color.toRgbString();

    if (this.props.onChange) {
      this.props.onChange(rgbaStr);
    }
    this.setState(
      {
        color: colorToColorInfo(value),
      },
      () => {
        this.onChangeColor();
      },
    );
  };

  inputChange = (data: any) => {
    let value;
    let color;
    if (data.label === 'Hex') {
      color = tinycolor(data.value);
      value = data.value.indexOf('#') !== -1 ? data.value : `#${data.value}`;
    } else {
      value = {
        ...this.state.color.rgba,
        [data.label]: data.value,
      };
      color = tinycolor(value);
    }
    if (!color.isValid()) {
      return;
    }
    this.changeColor(value);
  };

  handlePresetColor = (e: any) => {
    this.changeColor(e.target.style.background);
  };

  render() {
    const rgba = this.state.color.rgba;

    const style = {
      background: tinycolor(this.state.color.rgba).toRgbString(),
    };
    const cls = classnames({
      'dsm-c-color-picker': true,
      [this.props.cls]: this.props.cls,
    });
    return (
      <div
        className={cls}
        draggable={true}
        onDragStart={(e) => {
          // 这里是为了在打开 colorpicker 时可以禁止掉拖拽整个 color picker,
          // 直接设置为 false 是不行的，必须要拦截这个事件
          e.stopPropagation();
          e.preventDefault();
          return false;
        }}
        ref={this.props.forwardedRef}
        onClick={(e) => e.stopPropagation()}
      >
        <Saturation color={this.state.color} changeColor={this.changeColor} />
        <div className="slider-control">
          <Hue color={this.state.color} changeColor={this.changeColor} />
          <Alpha color={this.state.color} changeColor={this.changeColor} />
          <div className="current-color-wrap">
            <div className="current-color" style={style} />
          </div>
        </div>
        <div className="editable-input">
          <div className="hex-input">
            <EditableInput value={this.state.color.originalValue} inputChange={this.inputChange} label="Hex" />
          </div>
          <div className="rgba-input">
            <EditableInput value={rgba.r.toString()} inputChange={this.inputChange} label="R" />
            <EditableInput value={rgba.g.toString()} inputChange={this.inputChange} label="G" />
            <EditableInput value={rgba.b.toString()} inputChange={this.inputChange} label="B" />
            <EditableInput value={(rgba.a * 100).toFixed(0)} inputChange={this.inputChange} label="A" />
          </div>
        </div>
        <div className="preset-colors" onClick={this.handlePresetColor}>
          {COLOR_PICKER_PRESET_COLOR.map((obj, index) => (
            <PresetColor key={`presetColor${index}`} color={obj.color} bdc={obj.bdc} />
          ))}
        </div>
      </div>
    );
  }
}

export default withAutoClose(ColorPicker);
