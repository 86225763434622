export default {
  VK_A: 65,
  VK_B: 66,
  VK_C: 67,
  VK_D: 68,
  VK_E: 69,
  VK_F: 70,
  VK_G: 71,
  VK_H: 72,
  VK_I: 73,
  VK_J: 74,
  VK_K: 75,
  VK_L: 76,
  VK_M: 77,
  VK_N: 78,
  VK_O: 79,
  VK_P: 80,
  VK_Q: 81,
  VK_R: 82,
  VK_S: 83,
  VK_T: 84,
  VK_U: 85,
  VK_V: 86,
  VK_W: 87,
  VK_X: 88,
  VK_Y: 89,
  VK_Z: 90,
  VK_0: 48,
  VK_1: 49,
  VK_2: 50,
  VK_3: 51,
  VK_4: 52,
  VK_5: 53,
  VK_6: 54,
  VK_7: 55,
  VK_8: 56,
  VK_9: 57,
  VK_NUM_0: 96,
  VK_NUM_1: 97,
  VK_NUM_2: 98,
  VK_NUM_3: 99,
  VK_NUM_4: 100,
  VK_NUM_5: 101,
  VK_NUM_6: 102,
  VK_NUM_7: 103,
  VK_NUM_8: 104,
  VK_NUM_9: 105,
  VK_NUM_STAR: 106,
  VK_NUM_PLUS: 107,
  VK_NUM_PLUS_FF: 61,
  VK_NUM_ENTER: 108,
  VK_NUM_SUBTRACT: 109,
  VK_NUM_SUBTRACT_FF: 173,
  VK_NUM_DOT: 110,
  VK_NUM_DIVIDE: 111,
  VK_F1: 112,
  VK_F2: 113,
  VK_F3: 114,
  VK_F4: 115,
  VK_F5: 116,
  VK_F6: 117,
  VK_F7: 118,
  VK_F8: 119,
  VK_F9: 120,
  VK_F10: 121,
  VK_F11: 122,
  VK_F12: 123,
  VK_BACKSPACE: 8,
  VK_TAB: 9,
  VK_CLEAR: 12,
  VK_ENTER: 13,
  VK_SHIFT: 16,
  VK_CTRL: 17,
  VK_ALT: 18,
  VK_CAPE_LOCK: 20,
  VK_ESCAPE: 27,
  VK_SPACE: 32,
  VK_PAGE_UP: 33,
  VK_PAGE_DOWN: 34,
  VK_END: 35,
  VK_HOME: 36,
  VK_LEFT: 37,
  VK_UP: 38,
  VK_RIGHT: 39,
  VK_DOWN: 40,
  VK_INSERT: 45,
  VK_DEL: 46,
  // 小键盘灯
  VK_NUM_LOCK: 144,
  // 分号
  VK_SEMICOLON: 186,
  // 等号
  VK_EQUAL: 187,
  // 逗号
  VK_COMMA: 188,
  // 减号
  VK_SUBTRACT: 189,
  // 点
  VK_DOT: 190,
  // 除号
  VK_DIVIDE: 191,
  // 波浪号
  VK_WAVE: 192,
  // 左方括号
  VK_LEFT_SQUARE_BRACKET: 219,
  // 反斜杠
  VK_SLASH: 220,
  // 右方括号
  VK_RIGHT_SQUARE_BRACKET: 221,
  // 引号
  VK_QUOTATION: 222,
};
