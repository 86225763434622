import * as React from 'react';
import * as Loadable from 'react-loadable';

import Loading from '../Common/Loading';

const LoadableComponent = Loadable({
  loader: () => {
    return import('./index');
  },
  loading() {
    return <Loading size="mini" theme="light" />;
  },
});

// eslint-disable-next-line react/display-name
export default (props: any) => {
  return <LoadableComponent {...props} />;
};
