/****************************************************************
 * 地图统计图表编辑器，继承普通图表编辑器
 ****************************************************************/
import * as React from 'react';

import { isEqual } from 'lodash';

import IMapChart, { MapType } from '@/fbs/rp/models/properties/mapChart';
import { PureColor } from '@/fbs/rp/models/properties/color';
import IFill from '@/fbs/rp/models/properties/fill';
import { IMapChartValue } from '@/fbs/rp/models/chart';

import { withAutoClose, IAutoCloseProps } from '@/dsm';
import { ChartEditor, IChartProps, IChartBaseProps, CellWidth } from '.';
import updatePreventClose from './updatePreventClose';

class MapChartEdit extends ChartEditor {
  private get colorType() {
    return (this.props.component.properties.mapChart as IMapChart).type;
  }

  // @override 区域文案不支持清除内容
  protected getCleanContentDisabled() {
    const { start } = this.state.selectedArea!;
    return start.column === 0;
  }

  // @override 修改颜色
  protected handleColorChange(row: number, fill: IFill) {
    // 单色类型，修改一个颜色，将会修改所有行的颜色
    const colorType = this.colorType;
    const color = fill.color as PureColor;
    const value = this.props.component.value as IMapChartValue;
    if (colorType === MapType.Polychrome) {
      this.handleSubmit({ ...value, color } as IMapChartValue);
    } else if (colorType === MapType.Monochrome) {
      const colors = [...value.colors!];
      const index = row - 1;
      if (!isEqual(colors[index], color)) {
        colors[index] = color;
        this.handleSubmit({ ...value, colors } as IMapChartValue);
      }
    }
  }

  // @override 地图组件第一列区域名称不可编辑
  isFirstSelected(row: number, column: number) {
    const { selectedArea } = this.state;
    if (!selectedArea) {
      return false;
    }
    const { start } = selectedArea;
    return column > 0 && row === start.row && column == start.column;
  }

  // @override
  renderYAxisHeader(name: string, color: PureColor | undefined, row: number, column: number) {
    // 热力图颜色不展示
    if (this.colorType === MapType.Heatmap) {
      return this.renderTd(this.renderValueViewer(name, row, column, false), {
        isHead: true,
        isSelected: this.isSelected(row, column),
        row,
        column,
        width: CellWidth,
        isFirstSelected: this.isFirstSelected(row, column),
      });
    }
    return super.renderYAxisHeader(name, color!, row, column);
  }

  // @override 表格内容
  protected renderContent() {
    const colorType = this.colorType;
    const { component } = this.props;
    const { dataSource, xAxis, colors, color } = component.value as IMapChartValue;

    const tableData = this.getTableDataSource();
    return (
      <>
        <thead>
          <tr className="chart-row">
            {this.renderTd(undefined, { isHead: true, row: 0, column: 0, isSelected: false, width: 90, isEmpty: true })}
            {xAxis!.map((name, i) => this.renderXAxisHeader(name, 0, i + 1))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((rows, i) => {
            const { name } = dataSource![i];
            let rgba;
            if (colorType === MapType.Monochrome) {
              rgba = colors![i];
            } else if (colorType === MapType.Polychrome) {
              rgba = color;
            }
            return (
              <tr key={i} className="chart-row">
                {this.renderYAxisHeader(name, rgba, i + 1, 0)}
                {rows.map((item) => {
                  const { value, rowIndex, columnIndex } = item;
                  return this.renderDataCell(value, rowIndex, columnIndex);
                })}
              </tr>
            );
          })}
        </tbody>
      </>
    );
  }
}

const PanelClass: React.ComponentClass<IChartProps & IAutoCloseProps> = withAutoClose<IChartProps>(MapChartEdit);

export default updatePreventClose<IChartBaseProps & IAutoCloseProps>(PanelClass);
