import { unstable_batchedUpdates } from 'react-dom';

import { EventEmitter } from 'events';

import Doc from '@editor/document';
import {
  UIComponent,
  UIConnectorComponent,
  UIContainerComponent,
  UIGroupComponent,
  UIPanelComponent,
} from '@editor/comps';
import { pushArtboardPatches, pushArtboardSelect } from '@/services/io';
import {
  ArtboardPatches,
  extractPageOperationsFromPagePatches,
  PageOperations,
  PagePatches,
  reversePagePatches,
} from '@fbs/rp/utils/patch';
import { SessionInfo, PageIOSelectType } from '@fbs/rp/models/io';
import { getCoopers } from '@helpers/sessionHelper';
import { CSnapshot, CCanvasPanel, CGroup, CCompoundPath, CText, CSymbol } from '@libs/constants';
import { getInnerOrAroundConnect } from '@/helpers/pathFinderHelper';
import { getCompAndItsChildren } from '@editor/comps/resizeHelper';
import CoreEditor, { EditorModel } from '@editor/core';
import AdvancedEditor from '@editor/advancedEditor';
import { UIFragment } from '@editor/comps';
import { CustomError } from '@fbs/common/models/error';
import RefactorRemarkManager from '@/managers/refactorRemarkManager';
import { IComponentData } from '@/fbs/rp/models/component';
import { simplifyAddChildrenPageOperations } from '@/helpers/patchHelper';

import { getCompMoveInfo } from '../coreHelper';
import { BaseCommand } from '../commands';

export interface IUndoRedoStackPatchTypeElement {
  activeContainer: UIContainerComponent;
  patches: PagePatches;
}

export type UndoRedoStackElement = IUndoRedoStackPatchTypeElement | BaseCommand;

class CoreMessage extends EventEmitter {
  SELECTED_CHANGE = 'selected-change';
}

/**
 * 组件编辑器基类，该类中包含一些编辑的基本信息
 */
export default abstract class CoreBase {
  public readonly message: CoreMessage = new CoreMessage();
  /**
   * 当前页面的文档对象
   */
  public doc: Doc;
  /**
   * 当前网络状态
   */
  private _socketOffline: boolean = false;

  /**
   * 当前项目ID
   */
  public readonly appID: string;
  /**
   * 当前页面ID
   */
  public readonly nodeID: string;
  /**
   * 最后更新时间
   * @type {number}
   */
  public updatedAt: number = Date.now();

  /**
   * 选中的组件
   * @type {Set<UIComponent>}
   */
  public readonly selectedComponents: Set<UIComponent> = new Set();

  /**
   * 选中的画板
   * @type {Set<UIFragment>}
   */
  public readonly selectedFragments: Set<UIFragment> = new Set();

  /**
   * 激活的容器
   */
  public activeContainer: UIContainerComponent;

  protected blockNoticeUpdate: boolean = false;

  // notifyUpdateListener 需要更新的画板集合
  public updateArtboardIds: Set<string> = new Set();

  // notifyUpdateListener blockNoticeUpdate 次数
  protected blockNoticeUpdateTimes: number = 0;

  // notifyUpdateListener blockNoticeUpdate ArtboardId
  protected blockNoticeUpdateArtboardIds: string[] = [];

  protected cannotPushUpdateArtboardIds: boolean = false;

  private _isExample: boolean = false;

  public onError?: (e: CustomError) => void;

  // ==========把所有编辑的验证代码移到这里来==========
  get socketOffline() {
    return this._socketOffline;
  }

  set socketOffline(value: boolean) {
    this._socketOffline = value;
  }

  get isExample() {
    return this._isExample;
  }

  set isExample(value: boolean) {
    this._isExample = value;
  }

  // 是否处于数据缓存状态
  get isDataCache() {
    return this.socketOffline; // || this.isExample;
  }

  public get editorModel(): EditorModel {
    return EditorModel.normal;
  }

  public get isSymbolEditor() {
    return this.editorModel === EditorModel.symbol;
  }

  //内容画板编辑
  public get isContentPanelEditor() {
    return this.editorModel === EditorModel.contentPanel;
  }

  /**
   * 高级编辑？
   */
  public get isAdvancedEditor() {
    return this.editorModel === EditorModel.theme;
  }

  public get isNormalEditor() {
    return this.editorModel === EditorModel.normal;
  }

  /**
   * 资源库中的普通资源编辑
   */
  public get isComponentEditor() {
    return this.editorModel === EditorModel.component;
  }

  /**
   * 快照组件或者包含快照组件的面板或者编组
   */
  public get isContainSnapshotEditor() {
    let comps = [...this.selectedComponents];

    if (comps.some((comp) => comp.type === CSnapshot)) {
      return true;
    }

    const panelOrGroupTypes = [CCanvasPanel, CGroup];
    const panelOrGroupComps = comps.filter((comp) => panelOrGroupTypes.includes(comp.type)) as (
      | UIPanelComponent
      | UIGroupComponent
    )[];
    const flattedComps = this.doFlattedComps(panelOrGroupComps);

    return flattedComps.some((comp) => {
      return comp.type === CSnapshot;
    });
  }

  private doFlattedComps(comps: (UIPanelComponent | UIGroupComponent)[]) {
    const panelOrGroupTypes = [CCanvasPanel, CGroup];
    let flattedArr: any[] = [];
    for (let i = 0; i < comps.length; i++) {
      const comp = comps[i];
      if (panelOrGroupTypes.includes(comp.type)) {
        if (Array.isArray(comp.components) && comp.components.length > 0) {
          flattedArr = flattedArr.concat(
            this.doFlattedComps(comp.components as (UIPanelComponent | UIGroupComponent)[]),
          );
        }
      } else {
        flattedArr = flattedArr.concat(comp);
      }
    }
    return flattedArr;
  }

  /**
   * 是否可以编组
   * @returns {boolean}
   */
  get canGroup(): boolean {
    if (this.activeContainer.isSealed) {
      return false;
    }
    if (this.activeContainer.type === CCompoundPath) {
      return false;
    }
    if (this.selectedComponents.size <= 1) {
      return false;
    }
    const size = this.selectedComponents.size;
    if (this.activeContainer.isGroup) {
      return size <= this.activeContainer.components.length;
    }
    return true;
  }

  /**
   * 是否可以解组
   * @returns {boolean}
   */
  get canUnGroup(): boolean {
    return this.isSelectedOneGroup;
  }

  /**
   * 是否可以旋转
   * @returns {boolean}
   */
  get canRotate(): boolean {
    if (this.isAdvancedEditor || this.isContainSnapshotEditor) {
      return false;
    }
    const comps = [...this.selectedComponents];
    const canRotateComps = comps.filter((comp) => {
      return comp.canRotate;
    });

    //遍历容器组件的内部没有连接线
    const connectors = canRotateComps.filter((comp) => {
      return comp.isContainer && (comp as UIContainerComponent).hasConnector();
    });

    return !!canRotateComps.length && !connectors.length;
  }

  /**
   * 是否可移动组件
   * @returns {boolean}
   */
  get canMove(): {
    horizontal: boolean;
    vertical: boolean;
  } {
    const comps = this.selectedComponentList;
    // 单个组件layout 固定居中
    if (comps.length === 1 && !this.isAdvancedEditor) {
      return {
        horizontal: !comps[0].isLayoutCenterAtHorizontal,
        vertical: !comps[0].isLayoutMiddleAtVertical,
      };
    }
    // 1.高级编辑时，选中一个画板所属直接子组件时，始终是允许任意移动的
    if (comps.length === 1 && this.isAdvancedEditor && this.activeContainer instanceof UIFragment) {
      return { horizontal: true, vertical: true };
    }
    // 2.如果是复合组件内部的组件，那么如果有专门配置为canMove属性的，那么首先应用。
    const originContainer =
      (this.isAdvancedEditor && ((this as unknown) as AdvancedEditor).originContainer) || undefined;

    return getCompMoveInfo(comps, originContainer);
  }

  /**
   * 是否可以翻转
   */
  get canFlip(): boolean {
    // 单选下，只有路径和线条可以翻转
    if (!this.hasSelectLockedComps) {
      if (this.hasOnlyOneSelectedComponents) {
        const comp = this.firstSelectedComponent;
        if (comp) {
          return comp.canFlip;
        }
      }
    }
    return false;
  }

  // 可以创建选项组
  get canMakeSelectionGroup(): boolean {
    let selectedComponentList1 = this.selectedComponentList;
    if (!this.hasOnlyOneSelectedComponents) {
      return false;
    }

    const isContainSealedComp = getCompAndItsChildren(selectedComponentList1).some((comp) => {
      // if (comp.lib?.type === CButton) {
      //   return false;
      // }
      return comp.isSealed || comp.isSymbol;
    });
    if (isContainSealedComp) {
      return false;
    }
    return this.firstSelectedComponent!.canMakeSelectionGroup;
  }

  // ==================================================
  /**
   * 界面更新通知回调函数
   */
  protected updateListener?: (artboardIds?: string[]) => void;
  /**
   * 撤销堆栈列表
   * @type {PagePatches[]}
   */
  protected undoStack: UndoRedoStackElement[] = [];

  /**
   * 恢复堆栈列表
   * @type {PagePatches[]}
   */
  protected redoStack: UndoRedoStackElement[] = [];

  protected session: SessionInfo;

  getCurrentSession() {
    return this.session;
  }

  setCurrentSession(session: SessionInfo) {
    this.session = session;
  }

  // 获取当前用户的 session id
  get currentSessionID(): string {
    return this.session.id;
  }

  get undoStackData(): UndoRedoStackElement[] {
    return this.undoStack;
  }

  // undo/redo 栈最大长度
  protected get undoRedoStackMaxLength(): number {
    return 1000;
  }

  private readonly othersSelectedComponents: {
    [name: string]: string[];
  } = {};

  protected constructor(doc: Doc, appID: string, nodeID: string, session: SessionInfo) {
    this.doc = doc;
    this.appID = appID;
    this.nodeID = nodeID;
    this.session = session;
    this.activeContainer = this.doc.mainArtboard;
  }

  public getOthersSelectedComponents() {
    return this.othersSelectedComponents;
  }

  public pushUndoStack(element: UndoRedoStackElement) {
    if (this.undoStack.length === this.undoRedoStackMaxLength) {
      this.undoStack.splice(0, 1);
    }
    this.undoStack.push(element);
  }

  public popUndoStack() {
    return this.undoStack.pop();
  }

  public clearUndoStack() {
    this.undoStack = [];
  }

  public pushRedoStack(element: UndoRedoStackElement) {
    if (this.redoStack.length === this.undoRedoStackMaxLength) {
      this.redoStack.splice(0, 1);
    }
    this.redoStack.push(element);
  }

  public popRedoStack() {
    return this.redoStack.pop();
  }

  public clearRedoStack() {
    this.redoStack = [];
  }

  /**
   * 当前选中的组件列表, 按在容器中的顺序排列
   * @returns {UIComponent[]}
   */
  public get selectedComponentList(): UIComponent[] {
    const orderComps = Array.from(this.selectedComponents).filter((comp) => comp.parent === this.activeContainer);

    // if (orderComps.length === 1 && orderComps[0].type === CTable) {
    //   const tableComp = orderComps[0] as UITableComponent;
    //   if (tableComp.selectedComps.length) {
    //     return [...tableComp.selectedComps];
    //   }
    // }
    return orderComps;
  }

  public get selectedTextComp(): UIComponent[] {
    return this.selectedComponentList.filter((comp) => comp.type === CText);
  }

  // 排除选中组件中的连接线
  public get selectedCompsExcludeConnector(): UIComponent[] {
    return this.selectedComponentList.filter((comp) => !comp.isConnector);
  }

  /**
   * 排除选中组件中的连接线和锁定的组件
   */
  public get selectedCompsExcludeConnectorAndLocked(): UIComponent[] {
    return this.selectedComponentList.filter((comp) => !comp.isConnector && !comp.locked);
  }

  // 与选中的组件相关的连接线(包含被选中的组件)
  public get selectedRoundConnctor(): UIConnectorComponent[] {
    const selectedCompsIDs = this.selectedComponentList.map((comp) => comp.id);
    const { innerConnectComps, roundConnectComps } = getInnerOrAroundConnect(this.activeContainer, selectedCompsIDs);
    return [...innerConnectComps, ...roundConnectComps];
  }

  public get selectedIDs(): string[] {
    return this.selectedComponentList.map((comp) => comp.id);
  }

  /**
   * 获取所有选中未被锁定的组件
   */
  public get allSelectedUnLockedComps(): UIComponent[] {
    return this.selectedComponentList.filter((comp) => !comp.locked);
  }

  /**
   * 获取所有选中被锁定的组件
   */
  public get allSelectedLockedComps(): UIComponent[] {
    return this.selectedComponentList.filter((comp) => comp.locked);
  }

  /**
   *选中组件中,是否存在锁定的组件
   */
  public get hasLockedComponent() {
    return this.allSelectedLockedComps.length > 0;
  }

  /**
   *选中组件中,是否存在未锁定的组件
   */
  public get hasUnLockedComponent() {
    return this.allSelectedUnLockedComps.length > 0;
  }

  /**
   *是否选中组件全是锁定的状态
   */
  public get allSelectedComponentAreLocked() {
    return this.selectedComponentList.length > 0 && this.selectedComponents.size === this.allSelectedLockedComps.length;
  }

  /**
   *是否选中组件全是未锁定的状态
   */
  public get allSelectedComponentAreUnLocked() {
    return (
      this.selectedComponentList.length > 0 && this.selectedComponents.size === this.allSelectedUnLockedComps.length
    );
  }

  /**
   * 是否选中的有组件
   * @returns {boolean}
   */
  protected get hasSelectedComponents(): boolean {
    return this.selectedComponents.size > 0;
  }

  /**
   * 是否只选中了一个组件
   * @returns {boolean}
   */
  public get hasOnlyOneSelectedComponents(): boolean {
    return this.selectedComponents.size === 1;
  }

  public get hasSelectLockedComps(): boolean {
    return this.selectedComponentList.filter((comp) => comp.locked).length > 0;
  }

  // 排除选中组件中的连接线后是否为空
  public get hasExcludeConnectorComps(): boolean {
    return this.selectedCompsExcludeConnector.length > 0;
  }

  /**
   * 获取选中的第一个组件
   * @returns {UIComponent | null}
   * @description 注意：选中组件没有任何的排序，所以这里取第一个并无什么特定的顺序，最好仅将此属性用于取选中的那一个组件
   */
  public get firstSelectedComponent(): UIComponent | null {
    if (this.selectedComponentList.length === 0) {
      return null;
    }
    return this.selectedComponentList[0];
  }

  /**
   * 选中了一个，而且是个 Group
   * @returns {boolean}
   */
  protected get isSelectedOneGroup(): boolean {
    if (this.hasOnlyOneSelectedComponents) {
      const comp = this.firstSelectedComponent!;
      return comp.isGroup;
    }
    return false;
  }

  protected doApplyOperations(pageOperations: PageOperations) {
    // // 栈的长度到达最大了，把栈底的元素删除一个
    // if (reverseStack.length === this.undoRedoStackMaxLength) {
    //   reverseStack.splice(0, 1);
    // }
    // const pagePatches = reversePagePatches(patches);
    // reverseStack.push(pagePatches);
    // const pageOperations = extractPageOperationsFromPagePatches(patches);
    // this.patch(pageOperations);
    // return pageOperations;
    // // // FIXME: zjq 前端合并差异数据，在此将数据缓存下来？this.patch？
    // // const newPatches: PageOperations = {};
    // // return newPatches;
    this.patch(pageOperations);
  }

  protected doParserPagePatchesToPageOperations(patches: PagePatches, reverseStack: Array<UndoRedoStackElement>) {
    // 栈的长度到达最大了，把栈底的元素删除一个
    if (reverseStack.length === this.undoRedoStackMaxLength) {
      reverseStack.splice(0, 1);
    }
    const pagePatches = reversePagePatches(patches);

    reverseStack.push({ patches: pagePatches, activeContainer: this.activeContainer });

    return extractPageOperationsFromPagePatches(patches);
    // // FIXME: zjq 前端合并差异数据，在此将数据缓存下来？this.patch？
    // const newPatches: PageOperations = {};
    // return newPatches;
  }

  /**
   * 应用操作
   * @param {PagePatches} patches
   * @param {Array<UndoRedoStackElement>} reverseStack
   */
  protected applyOperations(patches: PagePatches, reverseStack: Array<UndoRedoStackElement>) {
    // FIXME 这里需要性能优化
    const operations = this.doParserPagePatchesToPageOperations(patches, reverseStack);

    this.doApplyOperations(operations);

    // 在patch后、传输前精简
    simplifyAddChildrenPageOperations(operations);

    pushArtboardPatches({
      appID: this.appID,
      nodeID: this.nodeID,
      sessionID: this.session.id,
      patches: operations,
    }).catch((e) => {
      this.onError && this.onError(e);
    });
  }

  /**
   * 应用操作。
   *
   * NOTE: 重构 undo/redo 时添加，这里面不包含 undo/redo 处理，已被提炼到外部。
   * （这样命名是为了区分，等到全部迁移为新的 undo/redo 方式后会重命名）
   * @author Miao Tianyu
   * @param patches
   */
  protected new_applyOperations(patches: PagePatches) {
    const operations = extractPageOperationsFromPagePatches(patches);

    this.doApplyOperations(operations);

    // 在patch后、传输前精简
    simplifyAddChildrenPageOperations(operations);

    pushArtboardPatches({
      appID: this.appID,
      nodeID: this.nodeID,
      sessionID: this.session.id,
      patches: operations,
    }).catch((e) => {
      this.onError && this.onError(e);
    });
  }

  protected refreshRemarkManager(pageOperations: PageOperations) {
    // 判断是否是 remark 相关 patch
    const addRemarkCompIDs: string[] = [];
    const removeRemarkCompIDs: string[] = [];
    Object.entries(pageOperations).forEach(([, artboardOperations]) => {
      Object.entries(artboardOperations).forEach(([compID, operations]) => {
        if (compID === 'self') {
          return;
        }
        if (operations.some((op) => op.op === 'replace' && op.path.includes('/remark'))) {
          addRemarkCompIDs.push(compID);
        }
        if (operations.some((op) => op.op === 'remove' && op.path.includes('/remark'))) {
          removeRemarkCompIDs.push(compID);
        }
      });
    });

    // 更新 manager
    const remarkManager = RefactorRemarkManager.getInstance(this.doc)!;
    removeRemarkCompIDs.forEach((id) => {
      remarkManager.removeRemark(id);
    });
    addRemarkCompIDs.forEach((id) => {
      // TODO get comp perf
      remarkManager.updateRemark(id, this.doc.getComponentsByFilter((comp) => comp.realID === id)[0]);
    });
  }

  /**
   * 合并差异数据
   * @param {PageOperations} patches
   */
  patch(patches: PageOperations) {
    this.doc.patchArtboard(patches, () => {
      this.doc.updateAppDataManagerArtboardsByOperations();
      this.refreshRemarkManager(patches);
      this.notifyUpdateListener(Object.keys(patches));
    });
  }

  /**
   * 选中一些组件
   * @param {string[]} ids
   * @param {boolean} append
   * @param update
   */
  selectByIDs(ids: string[], append: boolean, update?: boolean) {
    if (!append) {
      this.selectedComponents.clear();
    }
    if (ids.length > 0) {
      this.activeContainer.components.forEach((comp) => {
        if (!comp.locked && ids.includes(comp.id)) {
          this.selectedComponents.add(comp);
        }
      });
    }
    this.pushArtboardSelectedInfo();
    this.message.emit(this.message.SELECTED_CHANGE);
    update && this.notifyUpdateListener([((this as unknown) as CoreEditor).activeArtboard?.artboardID]);
  }

  /**
   * symbol在被编辑时或退出时发送symbol关联的信息
   * @param symbolId
   */
  symbolEditingByIDs(symbolId?: string) {
    pushArtboardSelect({
      appID: this.appID,
      nodeID: this.nodeID,
      sessionID: this.session.id,
      ids: symbolId ? [symbolId] : [],
      type: PageIOSelectType.Symbol,
    });
  }

  /**
   * 推送清除组件选择状态
   */
  pushComponentSelectedClean() {
    pushArtboardSelect({
      appID: this.appID,
      nodeID: this.nodeID,
      sessionID: this.session.id,
      ids: [],
      type: PageIOSelectType.Component,
    });
  }

  /**
   * 推送组件占用信息，防止多人编辑出现冲突
   */
  pushArtboardSelectedInfo() {
    const selectedIDs: string[] = [];
    if (this.isEditingRoot) {
      this.selectedComponents.forEach((comp) => {
        selectedIDs.push(comp.id);
      });
    } else {
      let topGroup = this.activeContainer;
      while (topGroup.parent !== this.doc.mainArtboard && topGroup.parent) {
        topGroup = topGroup.parent;
      }
      selectedIDs.push(topGroup.id);
    }
    pushArtboardSelect({
      appID: this.appID,
      nodeID: this.nodeID,
      sessionID: this.session.id,
      ids: selectedIDs,
      type: PageIOSelectType.Component,
    });
  }

  /**
   * 是否是在根组中编辑
   * @returns {boolean}
   */
  protected get isEditingRoot(): boolean {
    return this.activeContainer.isArtboard;
  }

  /**
   * 是否是其他协作者操作的 page patch
   */
  private _otherCooperPagePatchFlag: boolean = false;

  get otherCooperPagePatchFlag(): boolean {
    return this._otherCooperPagePatchFlag;
  }

  set otherCooperPagePatchFlag(value: boolean) {
    this._otherCooperPagePatchFlag = value;
  }

  /**
   * 获取一个组件被谁选中了
   * @param {string} id
   * @returns {string}
   */
  selectedByWho(id: string): string {
    const sessionID = this.getComponentSelectedSessionID(id);
    if (!sessionID) {
      return '';
    }
    const coopers = getCoopers();
    const cooper = coopers.find((c: SessionInfo) => c.id === sessionID);

    // 是当前用户在另外一个 session 中选中了该组件
    // 确保数据类型是一致的
    if (cooper && this.session.userID.toString() === cooper.userID.toString()) {
      return '';
    }
    if (cooper) {
      //用户在团队的名称存在在nickName中,尝试使用nickName 不存在则使用name
      const name = cooper.nickName ? cooper.nickName : cooper.name;
      return name || cooper.id;
    }
    return '';
  }

  /**
   * 获取symbol被谁编辑了
   * @param {UIComponent} comp
   * @return  {string}
   */
  selectedSymbolByWho(comp: IComponentData): string {
    if (comp.type === CSymbol && comp.symbol) {
      const info = this.selectedByWho(comp.symbol?.masterID);
      return info;
    }
    return '';
  }

  // 获取组件被哪个 session id 所选中
  private getComponentSelectedSessionID(id: string): string {
    const sessionID = Object.keys(this.othersSelectedComponents).find((name) => {
      return this.othersSelectedComponents[name].includes(id);
    });
    return sessionID || '';
  }

  /**
   * 判断一个组件是否被其他人选中了
   * @param {string} id
   * @returns {boolean}
   */
  isSelectedByOthers(id: string): boolean {
    const selectedByWho = this.selectedByWho(id);
    return selectedByWho !== '';
  }

  /**
   * 判断一个symbol是否被其他人选中了
   * @param {IComponentData}  comp
   * @returns {boolean}
   */
  isSymbolSelectedByOthers(comp: IComponentData): boolean {
    const selectedByWho = this.selectedSymbolByWho(comp);
    return selectedByWho !== '';
  }

  /**
   * 更新其他人选中组件的信息
   * @param {string} sessionID
   * @param {string[]} ids
   */
  updateOtherSelectedComponents(sessionID: string, ids: string[]) {
    // 此处的作用是，在重新获取页面信息时，会获取页面上所有的选中信息，同样地会获取到本人的选中信息，但是本人的选中信息不能添加
    if (sessionID === this.session.id) {
      return;
    }
    this.othersSelectedComponents[sessionID] = ids;
    this.notifyUpdateListener();
    return this.othersSelectedComponents;
  }

  /**
   * 更新其他人编辑Symbol资源的信息，同updateOtherSelectedComponents，但是
   * 这个用来更新当前编辑器中的selectedComponents，处理跨页面更新的问题
   * @param {string} sessionID
   * @param {string[]} ids
   */
  updateOtherSymbolSelectedComponents(sessionID: string, ids: string[]) {
    if (sessionID === this.session.id) {
      return;
    }
    this.othersSelectedComponents[sessionID] = ids;
    return this.othersSelectedComponents;
  }

  /**
   * 同步其它人的选中信息
   * @param info
   */
  syncOtherSelectedComponents(info: { [name: string]: string[] }) {
    Object.keys(this.othersSelectedComponents).forEach((k) => {
      this.othersSelectedComponents[k] = [];
    });
    Object.keys(info).forEach((k) => {
      if (k === this.session.id) {
        return;
      }
      this.othersSelectedComponents[k] = info[k];
    });
    this.notifyUpdateListener();
  }

  /**
   * 更新单个画板
   * @param {string} artboardID
   * @param {ArtboardPatches} patches
   */
  updateSingleArtboard(artboardID: string, patches: ArtboardPatches) {
    this.update({
      [artboardID]: patches,
    });
  }

  /**
   * 更新
   * @param {PagePatches} patches
   */
  update(patches: PagePatches) {
    // 无有效修改内容，不执行
    if (!this.validatePatches(patches)) {
      return;
    }
    this.applyOperations(patches, this.undoStack);
    this.redoStack.length = 0;
  }

  /**
   * 更新。
   *
   * NOTE: 重构 undo/redo 时添加，这里面不包含 undo/redo 处理，已被提炼到外部。
   * （这样命名是为了区分，等到全部迁移为新的 undo/redo 方式后会重命名）
   * @author Miao Tianyu
   * @param {PagePatches} patches
   */
  new_update(patches: PagePatches) {
    // 无有效修改内容，不执行
    if (!this.validatePatches(patches)) {
      return;
    }
    this.new_applyOperations(patches);
  }

  validatePatches(patches: PagePatches) {
    const keys = Object.keys(patches);
    keys.forEach((key) => {
      if (!Object.keys(patches[key].do).length) {
        delete patches[key];
      }
    });
    return Object.keys(patches).length;
  }

  /**
   * 侦听更新事件
   * @param {() => void} fn
   */
  addUpdateListener(fn: (artboardIds?: string[]) => void) {
    this.updateListener = fn;
  }

  /**
   * 移除侦听更新事件
   */
  removeUpdateListener() {
    this.updateListener = undefined;
  }

  /**
   * 更新symbol的备注编号顺序问题
   */
  updateRemark(editor: CoreEditor, symbolData: IComponentData[] | undefined) {
    const refactorRemarkManager = RefactorRemarkManager.getInstance(editor.doc);
    symbolData?.forEach((item) => {
      item && refactorRemarkManager?.removeInvalidRemark(item);
    });
  }

  getWorkCompIds(comps: UIComponent[]): string[] {
    let ids: string[] = [];
    comps.forEach((comp) => {
      if (comp instanceof UIContainerComponent) {
        ids.push(...this.getWorkCompIds(comp.components));
      }
      // 当前容器组件ID也要push进去
      ids.push(comp.id);
    });
    return ids;
  }

  /**  更新symbol的备注编号顺序问题
   * TODO 这个方法比较暴力，在没有更好的方案之前使用该方法
   * 用 updateRemark更好，它只在需要的时候执行，但没办法解决切换页面时产生的问题
   */
  workUpdateRemark(editor: CoreEditor) {
    const refactorRemarkManager = RefactorRemarkManager.getInstance(editor.doc);
    const board = editor.doc.mainArtboard;
    const components = [...board.components];
    editor.doc.fragments.forEach((fragment) => {
      components.push(...fragment.components);
    });
    const ids = this.getWorkCompIds(components);
    ids.push(board.artboardID);
    if (ids.length) {
      refactorRemarkManager?.compToRemarkMap?.forEach((value, k) => {
        if (!ids.includes(k)) {
          refactorRemarkManager.removeRemark(k);
        }
      });
    }
  }

  // 清除updateArtboardIds
  clearUpdateArtboardIds() {
    this.updateArtboardIds.clear();
  }

  // 添加updateArtboardIds
  addUpdateArtboardIds(artboardIds: string[]) {
    artboardIds.forEach((id) => {
      this.updateArtboardIds.add(id);
    });
  }

  /**
   * 批量处理fn方法内的notifyUpdateListener，最终只执行一次notifyUpdateListener
   * fn内同步方法执行一次notifyUpdateListener，blockNoticeUpdateTimes++
   * 如果批量更新只更新对应的画板，需要将每次notifyUpdateListener的画板Id传入，只要其中一次不传入画板Id，都将更新全部画板
   * @param fn
   * @param shouldUpdateArtboard notifyUpdateListener是否执行将fn产生的Artboard Id
   * @param batchSetState batch fn函数内部setState
   */
  async batchSyncNoticeUpdate(fn: Function, shouldUpdateArtboard: boolean = true, batchSetState: boolean = true) {
    if (batchSetState) {
      unstable_batchedUpdates(() => {
        this.batchSyncNoticeUpdate(fn, shouldUpdateArtboard, false);
      });
    } else {
      this.blockNoticeUpdate = true;
      // Promise.resolve抹平fn传入async和普通函数的差异
      await Promise.resolve(fn());
      this.blockNoticeUpdate = false;
      // 批次执行次数大于0，执行notifyUpdateListener
      if (this.blockNoticeUpdateTimes > 0) {
        this.notifyUpdateListener(
          shouldUpdateArtboard && this.blockNoticeUpdateArtboardIds.length > 0
            ? [...this.blockNoticeUpdateArtboardIds]
            : undefined,
        );
      }
    }
  }

  /**
   * 通知更新，会触发页面重新渲染，以下情况时才能调用这个方法
   * 1. 有任何数据变动
   * 2. 选中组件有变化
   * @params artboardIds 需要更新的画板Ids
   */
  notifyUpdateListener(artboardIds?: string[]) {
    this.updatedAt = Date.now();

    if (this.blockNoticeUpdate) {
      this.blockNoticeUpdateTimes++;
      if (artboardIds && !this.cannotPushUpdateArtboardIds) {
        this.blockNoticeUpdateArtboardIds.push(...artboardIds);
      } else if (!this.cannotPushUpdateArtboardIds) {
        // 批量notifyUpdate过程中，某次不传入artboardIds，清空之前收集的画板id，防止批量处理时，部分artboard不更新
        this.cannotPushUpdateArtboardIds = true;
        this.blockNoticeUpdateArtboardIds.length = 0;
      }
      return;
    }

    if (this.updateListener) {
      this.updateListener(artboardIds);
      this.blockNoticeUpdateTimes = 0;
      this.blockNoticeUpdateArtboardIds.length = 0;
      this.cannotPushUpdateArtboardIds = false;
    }
  }

  private _cloneOffset = { x: 6, y: 6 };

  private _presetCloneOffset = { x: 6, y: 6 };

  set presetCloneOffset(value: { x: number; y: number } | undefined) {
    this._presetCloneOffset = value || { x: 6, y: 6 };
  }

  resetCloneOffset = (value?: { x: number; y: number }) => {
    if (value) {
      this._cloneOffset = { ...value };
    } else {
      this._cloneOffset = {
        ...this._presetCloneOffset,
      };
    }
  };

  get cloneOffset() {
    return this._cloneOffset;
  }

  /**
   * 是否能脱离面板
   * 1、是面板
   * 2、不是复合组件
   */
  public get canDetachPanel() {
    // 高级编辑中， 不允许修改复合组件结构
    if (this.isAdvancedEditor) {
      return false;
    }
    if (
      this.hasOnlyOneSelectedComponents &&
      this.firstSelectedComponent instanceof UIContainerComponent &&
      this.firstSelectedComponent.components.length === 0
    ) {
      return false;
    }
    return this.selectedComponentList.some((c) => c.type === CCanvasPanel && !c.isSealed);
  }

  public get isAllConnector() {
    let isAllConnector: boolean = true;
    this.selectedComponents.forEach((comp: UIComponent) => {
      if (comp.type !== 'connector') {
        isAllConnector = false;
      }
    });
    return isAllConnector;
  }

  public getCompsCanOrder(): {
    canFrontForward: boolean;
    canBackForward: boolean;
  } {
    let firstIndex: number = -1;
    let lastIndex: number = -1;
    let tempIndex: number = 0;

    //为选择的组件数组计算出index数组
    const components = this.activeContainer.components;
    let compsIdIndexObj: {
      [key: string]: number;
    } | null = {};
    components.forEach((item, index) => {
      compsIdIndexObj![item.$data._id] = index;
    });

    this.selectedComponents.forEach((selComp: UIComponent) => {
      tempIndex = compsIdIndexObj![selComp.$data._id];
      if (tempIndex < firstIndex || -1 === firstIndex) {
        firstIndex = tempIndex;
      }
      if (tempIndex > lastIndex || -1 === lastIndex) {
        lastIndex = tempIndex;
      }
    });
    compsIdIndexObj = null;

    let canFrontForward = false;
    let canBackForward = false;
    //检查是否能上移
    if (
      this.activeContainer.components.length - 1 === firstIndex ||
      lastIndex === this.activeContainer.components.length - 1
    ) {
      canFrontForward = lastIndex - firstIndex === this.selectedComponents.size - 1 ? false : true;
    } else {
      canFrontForward = true;
    }

    //检查是否能下移
    if (0 === firstIndex || lastIndex === 0) {
      canBackForward = lastIndex - firstIndex === this.selectedComponents.size - 1 ? false : true;
    } else {
      canBackForward = true;
    }

    return {
      canFrontForward,
      canBackForward,
    };
  }

  /**
   * 是否能转换成面板
   * 1、未被锁定
   * 2、普通编辑区域
   */
  public get canConvertToPanel() {
    return this.hasSelectedComponents && !this.isAdvancedEditor && !this.isAllConnector;
  }

  public get canFixContent(): boolean {
    return this.selectedComponentList.every((c) => c.canFixContent);
  }

  public get fixContent(): boolean {
    return this.selectedComponentList.every((c) => c.fixContent);
  }
}
