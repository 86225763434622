import i18n from '@/i18n';
import { IComponentItem } from '@/libs/types';
import { CImage } from '@/libs/constants';
import { SpriteThumb } from '@/consts/spriteIcons';
import { StrokeLineCap, StrokeLineJoin } from '@/fbs/rp/models/properties/stroke';
import appOptions from '@/helpers/appOptions';
import { BlurType } from '@/fbs/rp/models/properties/blur';
import { DefaultBorder } from '@/consts/border';
import * as SystemsColor from '@consts/colors';
import { addTooltipsProperty, makeCommonComponent, getDefaultShadow } from '@/libs/helper';
import { IComponentSize, IComponentData } from '@/fbs/rp/models/component';
import { updateImageValue } from '.';

export const imageConfig: IComponentItem = {
  type: CImage,
  name: i18n('resource.components.image'),
  shortCut: 'I',
  thumb: {
    spriteIconClass: SpriteThumb.Image.className,
    dragPosition: SpriteThumb.Image.position,
  },
  editor: {
    onValueUpdate: updateImageValue,
  },
  getDefaultData() {
    return {
      properties: {
        radius: {
          topRight: 0,
          topLeft: 0,
          bottomLeft: 0,
          bottomRight: 0,
          isPercent: false,
          disabled: false,
        },
        image: {
          fitMode: 'fit',
          showModel: 'placeholder',
        },
        blur: {
          disabled: true,
          type: BlurType.gaussian,
          blurValue: {
            aMount: 0,
          },
        },
        colorFilter: {
          disabled: true,
        },
        shadow: getDefaultShadow(),
        stroke: {
          disabled: true,
          thickness: 1,
          color: SystemsColor.DefaultStrokeColor,
          cap: StrokeLineCap.Round,
          join: StrokeLineJoin.Round,
        },
        border: DefaultBorder,
      },
    };
  },
};

export const makeImage = addTooltipsProperty(_makeImage);

function _makeImage(
  id: string,
  url: string = '',
  name: string = '',
  size: IComponentSize = {
    width: 100,
    height: 100,
  },
): IComponentData {
  const template: Partial<IComponentData> = {
    name,
    properties: {
      image: {
        fitMode: 'fit',
        showModel: appOptions.defaultNoneImageModel,
      },
    },
    states: {
      disabled: {
        enabled: false,
        opacity: 30,
      },
    },
    size,
    value: url,
  };
  return makeCommonComponent(id, CImage, template);
}
