import * as paper from 'paper';

/**
 * 返回一个初始化canvas环境的paper
 */
const newPaper = () => {
  let canvas = document.getElementById('paperCanvas') as HTMLCanvasElement;
  if (!canvas) {
    canvas = document.createElement('canvas');
    canvas.id = 'paperCanvas';
    canvas.style.position = 'fixed';
    canvas.style.top = '-100000px';
    canvas.style.left = '-100000px';
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    document.body.appendChild(canvas);
  }
  if (!window.paper) {
    window.paper = paper;
    window.paper.setup(canvas);
  }
  return window.paper;
};

export default newPaper();
