/**
 * 编辑操作验证
 */
import { isUndefined } from 'lodash';

import { sameNumber } from '@utils/globalUtils';

import { IComponentData } from '@fbs/rp/models/component';

import CoreEditor from '@editor/core';
import { Components, UIComponent, UIContainerComponent, UISymbolComponent } from '@editor/comps';
import { getMinMaxXY } from '@editor/comps/resizeHelper';

import { getComponent } from '@libs/libs';
import {
  CConnector,
  CCompoundPath,
  CSelectPanel,
  CContentPanel,
  CContentPanelV2,
  CSelect,
  CMultipleSelect,
  CTree,
  CNavigationMenu,
  CVerticalMenu,
  CHorizontalMenu,
  CTable,
  CCarouselChart,
  CSnapshot,
  CStackPanel,
  CWrapPanel,
  CListLayoutPanel,
  CGridPanel,
  CGroup,
  CTextArea,
  CPureText,
  CText,
  CParagraph,
  CInput,
  CRect,
  CEllipse,
  CPolygon,
  CLine,
  CPath,
  CSlider,
  CSymbol,
  CCollapse,
  CVerticalTabs,
} from '@libs/constants';
import { isRichText } from '@libs/helper';

type sustainDirection = 'left' | 'right' | 'bottom' | 'top';

interface IEditorValidate {
  allowRemark(comps: UIComponent[]): boolean;
  allowInteract(comps: UIComponent[]): boolean;

  allowThemeEditor(comp: UIComponent): boolean;

  allowRotate(comps: UIComponent[]): boolean;

  allowWidthChange(comps: UIComponent[]): boolean;

  allowHeightChange(comps: UIComponent[]): boolean;

  allowFlip(comps: UIComponent[]): boolean;

  allowMove(group: UIContainerComponent): boolean;

  allowAnchorsChange(group: UIContainerComponent): boolean;

  allowHidden(comps: UIComponent[]): boolean;

  allowSameGap(comps: UIComponent[]): boolean;

  allowSameSize(comps: UIComponent[]): boolean;

  allowAlign(comps: UIComponent[]): boolean;

  allowAutoFit(comps: UIComponent[]): boolean;

  allowTextEditor(comp: UIComponent): boolean;

  allowPathEditor(comp: UIComponent): boolean;

  allowCenterAlignInGroup(comp: UIComponent): boolean;

  allowChildrenAnchorsChange(group: IComponentData): boolean;

  allowChildrenStructureChange(
    group: UIContainerComponent,
    operationModel?: 'addFromLib' | 'paste' | 'remove',
  ): boolean;

  allowCreateSymbol(comps: UIComponent[]): boolean;

  allowNodeExpandWithData(core: CoreEditor, comp: UIContainerComponent): boolean;
}

class EditorValidate implements IEditorValidate {
  constructor() {}

  private _editor?: CoreEditor;

  set editor(value: CoreEditor) {
    this._editor = value;
  }

  private hasLockedComps = (comps: UIComponent[]) => {
    return comps.some((comp) => comp.locked);
  };

  private getCompSustainInfo = (comp: UIComponent, parentSize: { width: number; height: number }) => {
    const parent = comp.parent!;
    if (!parent.isGroup) {
      return [];
    }
    const { width, height } = parentSize;
    const { minX, minY, maxX, maxY } = getMinMaxXY(comp.getBoxPointsInParent());
    const compSustainInfo: Array<sustainDirection> = [];
    if (sameNumber(minX, 0)) {
      compSustainInfo.push('left');
    }
    if (sameNumber(minY, 0)) {
      compSustainInfo.push('top');
    }
    if (sameNumber(maxX, width)) {
      compSustainInfo.push('right');
    }
    if (sameNumber(maxY, height)) {
      compSustainInfo.push('bottom');
    }
    return compSustainInfo;
  };

  allowRemark(comps: UIComponent[]): boolean {
    if (this._editor?.isAdvancedEditor || comps.length > 1 || comps.length < 1) {
      return false;
    }
    if (comps.length === 1) {
      const comp = comps[0];
      if (!comp.isSealed) {
        return ![CConnector].includes(comp.type) && comp.parent?.type !== CCompoundPath;
      }
    }
    return true;
  }

  allowInteract(comps: UIComponent[]): boolean {
    if (this._editor?.isAdvancedEditor || comps.length > 1) {
      return false;
    }
    if (comps.length === 1) {
      const comp = comps[0];
      if (!comp.isSealed) {
        return ![CConnector].includes(comp.type) && comp.parent?.type !== CCompoundPath;
      }
    }
    return true;
  }

  allowThemeEditor(comp: UIComponent): boolean {
    if (this._editor?.isAdvancedEditor) {
      return false;
    }
    if (!comp.isSealed) {
      return false;
    }
    const { lib, type } = comp;
    if (type === CSelectPanel) {
      return true;
    }
    if (!lib) {
      return false;
    }

    return (
      ![
        CContentPanel,
        CContentPanelV2,
        CSelect,
        CMultipleSelect,
        CTree,
        CNavigationMenu,
        CVerticalMenu,
        CHorizontalMenu,
        CTable,
        CCarouselChart,
        CCollapse,
        CVerticalTabs,
      ].includes(type) && lib.type !== CVerticalTabs
    );
  }

  allowRotate(comps: UIComponent[]): boolean {
    if (comps.length > 1) return false;
    if (comps.some((c) => c.parent?.type === CTable)) return false;

    // 快照组件禁用旋转
    if (comps[0]?.type === CSnapshot) return false;

    const canRotateComps = comps.filter((comp) => {
      return comp.canRotate;
    });

    //遍历容器组件的内部没有连接线
    const connectors = canRotateComps.filter((comp) => {
      return comp.isContainer && (comp as UIContainerComponent).hasConnector();
    });

    return !!canRotateComps.length && !connectors.length;
  }

  allowWidthChange(comps: UIComponent[]): boolean {
    if (!this.hasLockedComps(comps) && comps.length === 1) {
      return comps[0].canChangeWidth;
    }
    return true;
  }

  allowHeightChange(comps: UIComponent[]): boolean {
    if (!this.hasLockedComps(comps) && comps.length === 1) {
      return comps[0].canChangeHeight;
    }
    return true;
  }

  allowFlip(comps: UIComponent[]): boolean {
    // 单选下，只有路径和线条可以翻转
    if (this.hasLockedComps(comps)) {
      return false;
    }
    return comps.every((comp) => comp.canFlip);
  }

  allowMove(group: UIContainerComponent): boolean {
    return [CStackPanel, CWrapPanel, CListLayoutPanel, CGridPanel, CTable].indexOf(group.type) === -1;
  }

  allowAnchorsChange(group: UIContainerComponent): boolean {
    return [CStackPanel, CWrapPanel, CGroup, CGridPanel].indexOf(group.type) === -1;
  }

  allowHidden(comps: UIComponent[]): boolean {
    if (comps[0].parent?.type === CCompoundPath) {
      return false;
    }
    if (this.hasLockedComps(comps)) {
      const group = comps[0].nearestSealedComponent;
      if (group?.lib) {
        const libData = getComponent(group.lib);
        if (libData && libData.isList) {
          return false;
        }
      }
      return true;
    }
    return true;
  }

  allowSameGap(comps: UIComponent[]): boolean {
    const parent = comps[0].parent!;
    if ([CWrapPanel, CStackPanel, CListLayoutPanel, CGridPanel, CTable].includes(parent.type)) {
      return false;
    }
    // 忽略流程线
    // 忽略锁定
    comps = comps.filter((comp: UIComponent) => {
      return !comp.isConnector && !comp.locked;
    });
    return comps.length > 2;
  }

  allowSameSize(comps: UIComponent[]): boolean {
    if (comps.some((c) => c.parent?.type === CTable)) return false;
    return comps.length > 1;
  }

  allowAlign(comps: UIComponent[]): boolean {
    const parent = comps[0].parent!;
    const isRotatedContainer = parent.rotate;
    if ([CWrapPanel, CStackPanel, CListLayoutPanel, CGridPanel, CTable].includes(parent.type) || isRotatedContainer) {
      return false;
    }
    // 忽略流程线
    comps = comps.filter((comp: UIComponent) => {
      return !comp.isConnector;
    });
    const hasLocked = false; //this.hasLockedComps(comps);
    if (!hasLocked) {
      if (comps.length === 1) {
        //单选模式下，除开下面的限制情况，其他的都是可以对齐的
        const comp = comps[0];
        if (!comp.parent) {
          return false;
        }
        if ((comp && comp.isLayoutCenterAtHorizontal) || (comp && comp.isLayoutMiddleAtVertical)) {
          return false;
        }
        if (comp && comp.parent!.isGroup) {
          return this.allowCenterAlignInGroup(comp);
        }
        return true;
      }
    }
    return comps.length > 1;
  }

  allowAutoFit(comps: UIComponent[]): boolean {
    if (comps.length > 1) {
      return false;
    }
    if (this.hasLockedComps(comps)) {
      return false;
    }
    if (comps.some((c) => c.parent?.type === CTable)) return false;
    return comps.every((comp) => {
      const support = isRichText(comp.type) || comp.type === CTextArea;
      if (support) {
        const isTextComp = comp.libData?.isTextComp;
        if (!isUndefined(isTextComp) && !isTextComp) {
          return false;
        }
      }
      return support;
    });
  }

  allowTextEditor(comp: UIComponent): boolean {
    if (this._editor?.isAdvancedEditor) {
      return false;
    }
    const { type, libData } = comp;
    if (libData) {
      const { isTextComp, isList } = libData;
      if (!isUndefined(isTextComp) && !isList) {
        return isTextComp;
      }
    }
    return [
      CPureText,
      CText,
      CParagraph,
      CInput,
      CTextArea,
      CRect,
      CEllipse,
      CPolygon,
      CLine,
      CPath,
      CCompoundPath,
    ].includes(type);
  }

  allowPathEditor(comp: UIComponent): boolean {
    if (comp.type !== CPath) {
      return false;
    }
    return true;
  }

  allowCenterAlignInGroup(comp: UIComponent): boolean {
    //上边界支撑，顶点的最小Y值为0，下边界支撑，顶点最大值为group.height
    //左边界支撑，顶点的最小X值为0，右边界支撑，顶点最大值为group.width
    //如果组件没有在边界上，或者移动该组件，边界上扔有其他兄弟支持即可居中对齐

    //1.先找组件所支撑的边界，如果没有，则返回true
    //2.如果组件在边界上，但是边界上还有其他人支撑，返回true
    const sustain: {
      left: Components;
      right: Components;
      top: Components;
      bottom: Components;
    } = {
      left: [],
      right: [],
      top: [],
      bottom: [],
    };
    const parent = comp.parent!;
    const parentSize = parent.size;
    const compSustainInfo = this.getCompSustainInfo(comp, parentSize);

    // 统计所有组件的所在边界情况，并放入sustain对象中
    parent.components.forEach((comp) => {
      const sustainInfo = this.getCompSustainInfo(comp, parentSize);
      sustainInfo.forEach((direction) => {
        if (direction) {
          sustain[direction].push(comp);
        }
      });
    });
    let isCompHasBrotherInBoundary = true;
    // 组件所在的边界数组的都必须有兄弟在，才能保证移动组件边界不被破坏
    if (compSustainInfo.length === 0) {
      return true;
    }
    compSustainInfo.forEach((direction) => {
      if (direction) {
        isCompHasBrotherInBoundary = isCompHasBrotherInBoundary && sustain[direction].length > 1;
      }
    });
    return isCompHasBrotherInBoundary;
  }

  allowChildrenAnchorsChange(group: IComponentData): boolean {
    return ![CGridPanel, CStackPanel, CWrapPanel, CListLayoutPanel, CCompoundPath, CTable].includes(group.type);
  }

  allowChildrenStructureChange(
    group: UIContainerComponent,
    operationModel?: 'addFromLib' | 'paste' | 'remove',
  ): boolean {
    const sealedComp = group.isSealed ? group : group.nearestSealedComponent;
    if (sealedComp) {
      if (sealedComp.lib) {
        const data = getComponent(sealedComp.lib);
        if (data) {
          if (!data.isList) {
            return false;
          } else {
            if (group !== sealedComp) {
              return false;
            } else {
              if (operationModel === 'addFromLib' || operationModel === 'paste') {
                return false;
              }
            }
          }
        }
      } else {
        if (sealedComp.type === CSlider) {
          if (group !== sealedComp) {
            return true;
          }
        }
        const ownerSealed = sealedComp.nearestSealedComponent;
        //针对下拉列表，特殊处理
        if (ownerSealed && ownerSealed.type === 'select') {
          //
          if (group.parent === ownerSealed) {
            if (operationModel === 'addFromLib' || operationModel === 'paste') {
              return false;
            }
          }
          if (group.parent === sealedComp) {
            if (operationModel === 'remove') {
              return true;
            }
          }
        }
        // 其它非模板类复合组件，直接不允许修改结构
        return false;
      }
    }
    return true;
  }

  allowCreateSymbol(comps: UIComponent[]): boolean {
    if (
      comps.some((c) => {
        return c.parent?.type === CTable || [CSymbol, CConnector].includes(c.type);
      }) ||
      comps.every((c) => CContentPanel === c.type || CContentPanelV2 === c.type)
    ) {
      return false;
    }
    const validateChildren = (comps: UIComponent[]): boolean => {
      let hasSymbol = false;
      if (comps.length) {
        for (let i = 0, c = comps.length; i < c; i++) {
          const comp = comps[i];
          if (comp instanceof UISymbolComponent) {
            hasSymbol = true;
            break;
          }
          if (comp instanceof UIContainerComponent) {
            hasSymbol = validateChildren(comp.components);
            if (hasSymbol) {
              break;
            }
          }
        }
      }

      return hasSymbol;
    };

    const validateParent = (comp: UIComponent): boolean => {
      if (comp instanceof UISymbolComponent) {
        return true;
      }
      if (comp.parent) {
        return validateParent(comp.parent);
      }
      return false;
    };
    if (!comps || !comps.length) {
      return false;
    }

    if (comps[0].nearestSealedComponent) {
      return false;
    }

    return comps.length > 0 && !validateParent(comps[0]) && !validateChildren(comps);
  }

  allowNodeExpandWithData(coreEditor: CoreEditor, comp: UIComponent) {
    if (coreEditor.isNormalEditor && (comp.isSymbol || comp.isSealed)) {
      return false;
    } else if (coreEditor.isSymbolEditor && comp.isSealed) {
      return false;
    } else if (coreEditor.isAdvancedEditor && comp.isSymbol) {
      return false;
    } else if (coreEditor.isContentPanelEditor && (comp.isSymbol || comp.isSealed)) {
      return false;
    }
    return true;
  }
}

const validate: IEditorValidate = new EditorValidate();

export function initValidate(editor: CoreEditor) {
  (validate as EditorValidate).editor = editor;
}

export default validate;
