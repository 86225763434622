import * as React from 'react';
import { IAppWithNestedChildren } from '@/fbs/rp/models/app';
import { MobileType } from '@/fbs/rp/utils/preview';
import Doc from '@/editor/document';
import { UIFragment } from '@/editor/comps';
import AdaptivePreviewDevice from './AdaptivePreviewDevice';

interface IRenderDeviceOptions {
  artboard: UIFragment;
  doc?: Doc;
  app?: IAppWithNestedChildren;
  mobileType?: MobileType;
}
export const renderDevice = (options: IRenderDeviceOptions) => {
  const { artboard, doc, app, mobileType } = options;
  if (!doc) {
    return null;
  }

  return <AdaptivePreviewDevice app={app} artboard={artboard} mobileType={mobileType} />;
};
