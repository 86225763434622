import { isEqual, mapValues, isNumber } from 'lodash';
import * as assert from 'assert';

import * as BoundsUtils from '@utils/boundsUtils';
import { initBoundsWithPositionAndSize, isEqualPoint } from '@utils/boundsUtils';
import { depthClone, isEqual0, isMoreOrLess, isNotEqual0, notSameNumber, round, sameNumber } from '@utils/globalUtils';
import { getOffsetByPoints } from '@utils/rotateUtils';
import { enumToArray } from '@utils/enumUtils';
import { measureTextSize } from '@utils/textUtils';

import { IBoundsOffset, IPoint, IPosition, ISize } from '@fbs/common/models/common';
import { ArtboardOperations, ArtboardPatches, Operation, Ops, ReplaceOperation } from '@fbs/rp/utils/patch';
import { HorizontalAlign, IBasicLayout, ILayout, VerticalAlign } from '@fbs/rp/models/layout';
import { IComponentValue, ILineValue } from '@fbs/rp/models/value';
import { IProperties } from '@fbs/rp/models/property';
import { IComponentData } from '@fbs/rp/models/component';
import { ITableValue } from '@/fbs/rp/models/table';

import SelectionFrameType, { SelectionPoints } from '@consts/enums/selectionBoxType';
import { MinSizeOfLine, MinSizeOfNormalComp, MinSizeOfPath, MinSizeOfStickNote } from '@consts/size';
import { TransformOriginType } from '@consts/types/component';
import { PredefinedStates } from '@consts/state';

import { dragResizePoint, getCenter, getNWPoint } from '@helpers/rotateHelper';
import { collectComponentsLayout } from '@helpers/responseLayoutHelper';
import { getBoundsInParent, mapVectorToTargetCoordinates, tansPointInArtBoardToGroup } from '@helpers/componentHelper';
import { coverPatches } from '@helpers/patchHelper';
import * as tableHelper from '@helpers/tableHelper';
import { StyleHelper } from '@helpers/styleHelper';

import { CText, CPureText, CCompoundPath, CTable, CLine, CPath, CStickNote } from '@libs/constants';

import { UIComponent, UICompoundPathComponent, UIContainerComponent, UISymbolComponent, UITableComponent } from '.';
import { makeUIComponent } from './factory';
import { getViewBoundsOfComponents } from '../bounds';

export enum ComponentChangeType {
  Edit = 'edit',
  Add = 'added',
  Removed = 'removed',
}

// 组件变化
export interface ComponentChange {
  id: string;
  type: ComponentChangeType;
  position: {
    x: number;
    y: number;
  };
  size: {
    width: number;
    height: number;
  };
  rotate: number;
  // value只有在修改value时才传
  value?: IComponentValue;
  specialSetting?: {
    isResizeSingleLine?: boolean;
  };
}

export interface ContainerPatches {
  patches: ArtboardPatches;
  position?: IPosition;
  size?: ISize;
  rotate?: number;
}

export interface ComponentResizeResult {
  patches?: ArtboardPatches;
  position: IPosition;
  size: ISize;
  rotate: number;
}

export interface ResizeOptions {
  container: {
    before: {
      position: IPosition;
      size: ISize;
    };
    after: {
      position: IPosition;
      size: ISize;
    };
    isResponsive: boolean;
    isResizeMySelf?: boolean;
  };
  shift: boolean;
  scale: {
    h: number;
    v: number;
  };
  isSwitchToShift?: boolean;
}

export interface InitialDataForResize {
  id: string;
  position: IPosition;
  size: ISize;
  sizePath: string;
  positionPath: string;
  value?: IComponentValue;
  valuePath?: string;
}

export interface DynamicInfoMap {
  [key: string]: DynamicEditInfo;
}

export interface LineInfoDuringResize {
  newPoints: IPoint[];
  dragIndex: SelectionPoints;
  dragStartOrEndPointInfo: {
    isDraggingStartPoint: boolean;
    isDraggingEndPoint: boolean;
  };
}
export enum EditType {
  move = 'move',
  resize = 'resize',
  rotate = 'rotate',
}
export interface DynamicEditInfo {
  editType?: EditType;
  position?: IPosition;
  size?: ISize;
  value?: IComponentValue;
  properties?: IProperties;
  rotate?: number;
  zIndex?: number;
  autoSize?: boolean;
  selecting?: boolean; //处于正在框选中
  opacity?: number;
}

export interface InitialResizeInfo {
  xDistributionRatio?: number;
  yDistributionRatio?: number;
  left?: number;
  right?: number;
}

export function updateEditComponentsPatches(
  components: UIComponent[], // 源组件
  changes: ComponentChange[], // 选中的组件
  diff: {
    x: number;
    y: number;
  },
  patches: ArtboardPatches,
) {
  const mapComps = new Map<string, UIComponent>();
  components.forEach((comp) => {
    mapComps.set(comp.id, comp);
  });
  changes
    .filter((comp) => comp.type === ComponentChangeType.Edit)
    .forEach((comp) => {
      const doOps: Operation[] = [];
      const undoOps: Operation[] = [];
      const { id, value, position, specialSetting, rotate, size } = comp;
      const originComp = mapComps.get(id);
      if (!originComp) {
        return;
      }

      const isSettingResizeSingleLine = specialSetting?.isResizeSingleLine;
      const { x: originPositionX, y: originPositionY } = originComp.position;
      const newPositionX = position.x - diff.x;
      const newPositionY = position.y - diff.y;
      const { size: originalSize, states, fixContent, autoSize } = originComp;
      const isSizeChange =
        notSameNumber(originalSize.width, size.width) || notSameNumber(originalSize.height, size.height);
      const newPosition = {
        x: notSameNumber(originPositionX, newPositionX) ? newPositionX : originPositionX,
        y: notSameNumber(originPositionY, newPositionY) ? newPositionY : originPositionY,
      };
      // 更新位置变化
      if (!isEqual(originComp.position, newPosition)) {
        const path = originComp.getCurrentPositionPath();
        doOps.push(Ops.replace(path, newPosition));
        undoOps.push(Ops.replace(path, originComp.position));
      }
      // 更新角度变化
      if (originComp.rotate !== rotate) {
        const path = originComp.getCurrentRotatePath();
        doOps.push(Ops.replace(path, rotate));
        undoOps.push(Ops.replace(path, originComp.rotate));
      }
      if (!originComp.isGroup) {
        if (isSizeChange) {
          const stateID = originComp.currentStateID;
          const forceUsedStatePath = [CText, CPureText].includes(originComp.type) && Boolean(stateID);
          const path = originComp.getCurrentSizePath(forceUsedStatePath);
          doOps.push(Ops.replace(`${path}`, { ...originalSize, ...size }));
          undoOps.push(Ops.replace(`${path}`, originalSize));
          if (stateID && stateID !== PredefinedStates.normal && !originComp.states[stateID]) {
            const path = `./states/${stateID}`;
            doOps.unshift(Ops.add(path, { properties: {}, disabled: false }));
            undoOps.push(Ops.remove(path));
          }

          if (fixContent && autoSize) {
            // 固定状态且自动大小开启时修改其他状态的尺寸
            Object.keys(states).forEach((stateID) => {
              const state = states[stateID];
              const textFormat = state.properties?.textFormat;
              if (stateID !== originComp.currentStateID) {
                const parser = StyleHelper.initCSSStyleParser(
                  (textFormat && state.properties) || originComp.$data.properties,
                );
                const newSize = measureTextSize(parser.getTextStyle(), `${comp.value || originComp.value}`, {
                  wrap: textFormat?.wrap,
                  isMultiText: true,
                });
                const oldSize = state.size || originalSize;
                if (!isEqual(newSize, oldSize)) {
                  if (!originComp.states[stateID]) {
                    doOps.push(Ops.add(`./states/${stateID}`, { size: newSize, disabled: false }));
                  } else {
                    doOps.push(Ops.add(`./states/${stateID}/size`, newSize));
                  }

                  undoOps.push(Ops.replace(`./states/${stateID}/size`, oldSize));
                }
              }
            });
          }
        }
      }
      const newBounds = initBoundsWithPositionAndSize(position, size);
      // 仅仅是单个resize线条
      const originCompType = originComp.type;
      if (isSettingResizeSingleLine) {
        if (value) {
          const originValue = originComp.value;
          const isValueChange = !isEqual(value, originValue);
          if (isValueChange) {
            let path;
            if (enumToArray(PredefinedStates).includes(originComp.currentStateID)) {
              path = '/value';
            } else {
              path = originComp.getCurrentPropertiesPath('/value');
            }

            doOps.push(Ops.replace(`${path}`, value));
            undoOps.push(Ops.replace(`${path}`, originValue));
          }
        }
      }
      //线条随组伸缩走下面
      if ((!isSettingResizeSingleLine && !originComp.isContainer) || [CCompoundPath, CTable].includes(originCompType)) {
        const compPatches = originComp.updateValueOrPropertiesWithBoundsChanged(newBounds);
        if (compPatches) {
          const originCompId = originComp.id;
          compPatches.do[originCompId].forEach((ops) => {
            doOps.push(ops);
          });
          compPatches.undo[originCompId].forEach((ops) => {
            undoOps.push(ops);
          });
        }
      }

      if (doOps.length > 0) {
        const oldDoPath = patches.do[id];
        const oldUndoPath = patches.undo[id];
        patches.do[id] = oldDoPath ? [...oldDoPath, ...doOps] : doOps;
        patches.undo[id] = oldUndoPath ? [...oldUndoPath, ...undoOps] : undoOps;
      }
      if (originCompType === CCompoundPath) {
        const oldSize = originComp.size;
        const scale = {
          x: newBounds.width / (oldSize.width || 1),
          y: newBounds.height / (oldSize.height || 1),
        };
        (originComp as UICompoundPathComponent).updateChildrenOfCompoundPathByZoom(scale, patches);
      }
      if (originCompType === CTable) {
        // 解析出newValue
        const newValue = (doOps.find((op) => /value$/.test(op.path)) as ReplaceOperation<ITableValue>)?.value;
        // 处理表格value及components
        if (newValue && (isSizeChange || comp.value)) {
          const tablePatches = (originComp as UITableComponent).updateTextCompSize(newValue);
          tablePatches && coverPatches(patches, tablePatches);
        }
      }
      // 高宽比变化，处理径向渐变
      const fillPatches = originComp.updateRadialGradientPatchesWithNewSize(newBounds);
      coverPatches(patches, fillPatches);
    });
}

/**
 * 便签条组件移动和resize，重置连接结束点位置
 * @param components
 * @param changes
 * @param patches
 */
export function updateStickNoteEndPoint(
  components: UIComponent[], // 源组件
  changes: ComponentChange[], // 选中的组件
  patches: ArtboardPatches,
) {
  const mapComps = new Map<string, UIComponent>();
  components.forEach((comp) => {
    mapComps.set(comp.id, comp);
  });
  changes.forEach((comp) => {
    const originComp = mapComps.get(comp.id);
    if (!originComp || originComp.lib?.type !== CStickNote) {
      return;
    }

    const { position } = comp;
    const { x: originPositionX, y: originPositionY } = originComp.position;
    const newPosition = {
      x: notSameNumber(originPositionX, position.x) ? position.x : originPositionX,
      y: notSameNumber(originPositionY, position.y) ? position.y : originPositionY,
    };

    const notePatches = originComp.libData?.editor?.specials?.onPositionChanged?.(originComp, newPosition, comp.size);
    notePatches && coverPatches(patches, notePatches);
  });
}

export function updateUnChangedComponentsPatches(
  unChangedComps: UIComponent[],
  diff: {
    x: number;
    y: number;
  },
  patches: ArtboardPatches,
) {
  unChangedComps.forEach((comp) => {
    if (comp.isConnector) {
      return;
    }
    const doOps: Operation[] = [];
    const undoOps: Operation[] = [];
    const originPosition = comp.position;
    const newPosition = {
      x: originPosition.x - diff.x,
      y: originPosition.y - diff.y,
    };
    const isParentPositionChanged = diff.x || diff.y;
    if (isParentPositionChanged) {
      const path = comp.getCurrentPositionPath();
      doOps.push(Ops.replace(path, newPosition));
      undoOps.push(Ops.replace(path, comp.position));
    }
    if (doOps.length > 0) {
      patches.do[comp.id] = doOps;
      patches.undo[comp.id] = undoOps;
    }
  });
}

export function updateSizeAsLockedRatio(size: ISize, newSize: ISize, isWidthHeightChangeSimultaneously: boolean) {
  if (!isWidthHeightChangeSimultaneously) {
    return newSize;
  } else {
    const widthChange = Math.abs(newSize.width - size.width);
    const heightChange = Math.abs(newSize.height - size.height);
    let isWidthChange = widthChange > 0.9;
    let isHeightChange = heightChange > 0.9;
    if (isWidthChange && isHeightChange) {
      //宽高同时变化的时候,如果某一侧的改变时来源于计算误差,那么一定会出现有一个非常小的情况
      const isHeightChangeMuchBigger = heightChange - widthChange > 3;
      if (isHeightChangeMuchBigger) {
        isWidthChange = false;
      } else {
        isHeightChange = false;
      }
    }
    const widthHeightRatio = size.width / size.height;
    //如果宽高同时改变的话，且不是按照原来的宽高比变化的，那么最终只响应宽的变化
    if (isWidthChange) {
      newSize.height = newSize.width / widthHeightRatio;
    } else if (isHeightChange) {
      newSize.width = newSize.height * widthHeightRatio;
    }
    return newSize;
  }
}

export function getNewPositionWhenCenter(
  comp: UIComponent | IComponentData,
  newPosition: IPosition,
  newSize: ISize,
  parentSize: ISize,
  setting?: {
    isLayoutMiddleAtVertical: boolean;
    isLayoutCenterAtHorizontal: boolean;
  },
): IPosition {
  const parentCenter = getCenter({ x: 0, y: 0 }, parentSize);
  const myPosition = getNWPoint(parentCenter, newSize, 0);
  let myNewPosition = {
    x: newPosition.x,
    y: newPosition.y,
  };
  const isLayoutMiddleAtVertical =
    (comp instanceof UIComponent && comp.isLayoutMiddleAtVertical) || setting?.isLayoutMiddleAtVertical;
  if (isLayoutMiddleAtVertical) {
    myNewPosition.y = myPosition.y;
  }

  let isLayoutCenterAtHorizontal =
    (comp instanceof UIComponent && comp.isLayoutCenterAtHorizontal) || setting?.isLayoutCenterAtHorizontal;
  if (isLayoutCenterAtHorizontal) {
    myNewPosition.x = myPosition.x;
  }

  return myNewPosition;
}

export function responsiveFixedWidthStrategy(
  position: IPosition,
  size: ISize,
  offset: IBoundsOffset,
  layout: IBasicLayout,
  options: ResizeOptions,
) {
  let newPosition = {
    x: position.x,
    y: position.y,
  };
  const { before, after } = options.container;

  switch (layout.horizontal) {
    case HorizontalAlign.Left:
      newPosition.x = position.x + offset.left;
      break;
    case HorizontalAlign.Right:
      newPosition.x = position.x + offset.right;
      break;
    case HorizontalAlign.LeftAndRight:
      // BUG!
      assert.ok(false, '组件不能既固定宽度，又两边都锚定.');
      break;
    case HorizontalAlign.Auto:
      if (isEqual0(before.size.width - size.width)) {
        newPosition.x = position.x + offset.left + offset.right;
      } else {
        newPosition.x =
          ((position.x - before.position.x) / (before.size.width - size.width)) * (after.size.width - size.width) +
          after.position.x;
      }
      break;
    case HorizontalAlign.Center:
      newPosition.x = after.position.x + after.size.width / 2 - size.width / 2;
      break;
    default:
      assert.ok(false, '不该有这种情况');
      break;
  }
  return newPosition;
}

export function responsiveFixedHeightStrategy(
  position: IPosition,
  size: ISize,
  offset: IBoundsOffset,
  layout: IBasicLayout,
  options: ResizeOptions,
) {
  let newPosition = {
    x: position.x,
    y: position.y,
  };
  const { before, after } = options.container;

  switch (layout.vertical) {
    case VerticalAlign.Top:
      newPosition.y = position.y + offset.top;
      break;
    case VerticalAlign.Bottom:
      newPosition.y = position.y + offset.bottom;
      break;
    case VerticalAlign.TopAndBottom:
      // BUG!
      assert.ok(false, '组件不能既固定宽度，又两边都锚定.');
      break;
    case VerticalAlign.Auto:
      if (isEqual0(position.y - before.position.y)) {
        newPosition.y = position.y + offset.top + offset.bottom;
      } else {
        newPosition.y =
          ((position.y - before.position.y) / (before.size.height - size.height)) * (after.size.height - size.height) +
          after.position.y;
      }
      break;
    case VerticalAlign.Middle:
      newPosition.y = after.position.y + after.size.height / 2 - size.height / 2;
      break;
    default:
      assert.ok(false, '不该有这种情况');
      break;
  }
  return newPosition;
}

/**
 * 通过调整偏移量不能超过宽或者高，来达到禁止反转的目的
 * @param dragIndex 拖动点
 * @param diff 偏移量
 * @param areaSize
 * @param minSize 允许的最小的大小
 * @param rotate
 */
export function getAdjustedDiffAvoidReverse(
  dragIndex: SelectionPoints,
  diff: {
    x: number;
    y: number;
  },
  areaSize: ISize,
  minSize: ISize,
  rotate: number,
) {
  // 每个点在四个方向上下左右时，所产生的diff使宽高的增减不同
  // 比如在拖曳点在右边，diff.x为正代表的宽度增加，拖拽点在左边时，diff.x为正代表宽度减小
  const isDragPointAtRight = [
    SelectionPoints.rightTop,
    SelectionPoints.rightBottom,
    SelectionPoints.rightMiddle,
  ].includes(dragIndex);
  const isDragPointAtLeft = [SelectionPoints.leftTop, SelectionPoints.leftBottom, SelectionPoints.leftMiddle].includes(
    dragIndex,
  );
  const isDragPointAtTop = [SelectionPoints.leftTop, SelectionPoints.topMiddle, SelectionPoints.rightTop].includes(
    dragIndex,
  );
  const isDragPointAtBottom = [
    SelectionPoints.rightBottom,
    SelectionPoints.bottomMiddle,
    SelectionPoints.leftBottom,
  ].includes(dragIndex);
  let diffRelativeToComp = mapVectorToTargetCoordinates({ x: diff.x, y: diff.y }, -rotate);
  const maxWidthChange = areaSize.width - minSize.width;
  const maxHeightChange = areaSize.height - minSize.height;
  if (isDragPointAtRight) {
    if (-diffRelativeToComp.x > maxWidthChange) {
      diffRelativeToComp.x = -maxWidthChange;
    }
  }

  if (isDragPointAtLeft) {
    if (diffRelativeToComp.x > maxWidthChange) {
      diffRelativeToComp.x = maxWidthChange;
    }
  }
  if (isDragPointAtTop) {
    if (diffRelativeToComp.y > maxHeightChange) {
      diffRelativeToComp.y = maxHeightChange;
    }
  }
  if (isDragPointAtBottom) {
    if (-diffRelativeToComp.y >= maxHeightChange) {
      diffRelativeToComp.y = -maxHeightChange;
    }
  }
  return mapVectorToTargetCoordinates(diffRelativeToComp, rotate);
}

export function getMinMaxXY(points: IPoint[]) {
  return points.reduce(
    (acc, curr) => {
      const minX = curr.x < acc.minX ? curr.x : acc.minX;
      const minY = curr.y < acc.minY ? curr.y : acc.minY;
      const maxX = curr.x > acc.maxX ? curr.x : acc.maxX;
      const maxY = curr.y > acc.maxY ? curr.y : acc.maxY;
      return {
        minX,
        minY,
        maxX,
        maxY,
      };
    },
    { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity },
  );
}

export function getOffsetBySizeChange(oldSize: ISize, newSize: ISize) {
  const { height: oldHeight, width: oldWidth } = oldSize;
  const { height: newHeight, width: newWidth } = newSize;
  return {
    left: 0,
    right: newWidth - oldWidth,
    top: 0,
    bottom: newHeight - oldHeight,
  };
}

/**
 * 根据所有的顶点计算所有的顶点+边点（8个点），并按照顶点为0-3，边点为4-7，顺时针放置
 */
export function getAllPointsByCornerPoint(peakPoints: IPoint[]) {
  const notPeakPoints = peakPoints.map((peakPoint, index) => {
    const nextPeakPoint = peakPoints[(index + 1) % 4];
    return {
      x: (nextPeakPoint.x + peakPoint.x) / 2,
      y: (nextPeakPoint.y + peakPoint.y) / 2,
    };
  });
  return peakPoints.concat(notPeakPoints);
}

export function getAllPointsByDraggingPoint(resizingInfo: {
  resizingCenterAnchorComp: {
    isVerticalMiddle: boolean;
    isHorizontalCenter: boolean;
  };
  rotateAngle: number;
  originalPoints: IPoint[];
  dragIndex: number;
  newPoint: IPoint;
  isWidthHeightBothChange: boolean;
}) {
  const {
    originalPoints,
    dragIndex,
    newPoint,
    isWidthHeightBothChange,
    resizingCenterAnchorComp,
    rotateAngle,
  } = resizingInfo;

  return dragResizePoint(
    originalPoints,
    dragIndex,
    newPoint,
    isWidthHeightBothChange,
    rotateAngle,
    resizingCenterAnchorComp,
  );
}

export function getMinSizeOfComp(comp: UIComponent): ISize {
  switch (comp.lib?.type || comp.type) {
    case CLine:
      return {
        width: MinSizeOfLine,
        height: MinSizeOfLine,
      };
    case CPath:
      return {
        width: MinSizeOfPath,
        height: MinSizeOfPath,
      };
    case CTable:
      return tableHelper.getTableMinSizeFromValue(comp.value as ITableValue);
    case CStickNote:
      return {
        width: MinSizeOfStickNote,
        height: MinSizeOfStickNote,
      };
    default:
      return {
        width: MinSizeOfNormalComp,
        height: MinSizeOfNormalComp,
      };
  }
}

export function isDragStartOrEndPoint(lineComp: UIComponent, originalPoints: IPoint[], dragIndex: number) {
  const { value, size } = lineComp;
  let { startPoint: originStartPoint, endPoint: originEndPoint } = value as ILineValue;
  originStartPoint = originStartPoint || { x: 0, y: 0 };
  originEndPoint = originEndPoint || { x: size.width, y: size.height };
  const originPointsInGroup = tansPointInArtBoardToGroup(originalPoints, lineComp.parent!);

  const originalPoint = originPointsInGroup[dragIndex];

  const { minX: originMinX, minY: originMinY } = getMinMaxXY(originPointsInGroup);
  const originPointInCompCoordination = {
    x: originalPoint.x - originMinX,
    y: originalPoint.y - originMinY,
  };
  const isDraggingStartPoint = isEqualPoint(originPointInCompCoordination, originStartPoint, 0.5);
  const isDraggingEndPoint = isEqualPoint(originPointInCompCoordination, originEndPoint, 0.5);
  return {
    isDraggingStartPoint,
    isDraggingEndPoint,
  };
}

export function getLineSelectionFrameType(lineComp: UIComponent) {
  const linePoints = lineComp.value as ILineValue;
  if (linePoints) {
    const size = lineComp.size;
    const { startPoint, endPoint } = linePoints;
    const _start = startPoint || { x: 0, y: 0 };
    const _end = endPoint || { x: size.width, y: size.height };
    const k = (_start.y - _end.y) / (_start.x - _end.x);
    if (k > 0) {
      return SelectionFrameType.leftTop_to_rightBottom;
    } else {
      return SelectionFrameType.leftBottom_to_rightTop;
    }
  }
  return SelectionFrameType.leftTop_to_rightBottom;
}

export function getCompAndChildrenInitialStatusForResize(comps: UIComponent[]) {
  let result: InitialDataForResize[] = [];
  comps.forEach((comp) => {
    result.push(getCompInitialStatusForResize(comp));
    if (comp instanceof UIContainerComponent && comp.components) {
      result = result.concat(getCompAndChildrenInitialStatusForResize(comp.components));
    }
  });
  return result;
}

export function getCompInitialStatusForResize(comp: UIComponent) {
  const { id, position, size } = comp;
  return {
    id,
    position,
    positionPath: comp.getCurrentPositionPath(),
    size,
    sizePath: comp.getCurrentSizePath(),
    value: comp.value,
    valuePath: [CCompoundPath, CPath].includes(comp.type) ? '/value' : comp.getCurrentPropertiesPath('/value'),
  };
}

export function extractDynamicInfoFromPatch(patch: ArtboardPatches, editType?: EditType) {
  return Object.entries(patch.do).reduce((acc, [id, operations]) => {
    let dynamicInfo: DynamicEditInfo = { editType };
    operations.forEach((operation) => {
      const value = (operation as ReplaceOperation<any>).value;
      if (operation.path.includes('size') && !dynamicInfo.size) {
        dynamicInfo.size = value as ISize;
      }
      if (operation.path.includes('position')) {
        dynamicInfo.position = value as IPosition;
      }
      if (operation.path.includes('value')) {
        dynamicInfo.value = value as IComponentValue;
      }
      if (operation.path.includes('rotate')) {
        dynamicInfo.rotate = value as number;
      }
      if (operation.path.includes('properties')) {
        const name = operation.path.replace('properties', '').replace(/\//g, '').replace('.', '').replace(/\//g, '');
        if (name) {
          dynamicInfo.properties = {
            [name]: value,
          };
        } else {
          dynamicInfo.properties = value;
        }
      }
      if (operation.path.includes('autoSize')) {
        dynamicInfo.autoSize = value;
      }
    });
    acc[id] = dynamicInfo;
    return acc;
  }, {} as { [key: string]: DynamicEditInfo });
}

export function getResizeSingLinePatch(
  lineComp: UIComponent,
  originalPoints: IPoint[],
  lineInfoDuringResize: LineInfoDuringResize,
) {
  const originLineComp = lineComp;
  const { startPoint: originStartPoint, endPoint: originEndPoint } = originLineComp.value as ILineValue;
  const { newPoints, dragIndex, dragStartOrEndPointInfo } = lineInfoDuringResize;
  const { isDraggingStartPoint } = dragStartOrEndPointInfo;
  const newPointsForCalculateStartAndEnd = tansPointInArtBoardToGroup(newPoints, lineComp.parent!);
  const originalPointsForCalculateStartAndEnd = tansPointInArtBoardToGroup(originalPoints, lineComp.parent!);
  const { minX, minY, maxX, maxY } = getMinMaxXY(newPointsForCalculateStartAndEnd);
  let newStart = originStartPoint;
  let newEnd = originEndPoint;
  const newHeight = maxY - minY;
  const newWidth = maxX - minX;
  const newSize = {
    height: newHeight,
    width: newWidth,
    lockedRatio: lineComp.lockedRatio,
  };

  const symmetryConfig: { [index: number]: number } = {
    0: 2,
    1: 3,
    2: 0,
    3: 1,
  };
  const dockIndex = symmetryConfig[dragIndex];
  const dockPoint = { ...originalPointsForCalculateStartAndEnd[dockIndex] };
  const newDockIndex = newPointsForCalculateStartAndEnd.findIndex(
    (pt) => isMoreOrLess(pt.x, dockPoint.x, 0.5) && isMoreOrLess(pt.y, dockPoint.y, 0.5),
  );
  const dynamicPoint = depthClone({ ...newPointsForCalculateStartAndEnd[symmetryConfig[newDockIndex]] });
  dockPoint.x -= minX;
  dockPoint.y -= minY;
  dynamicPoint.x -= minX;
  dynamicPoint.y -= minY;

  if (isMoreOrLess(dockPoint.x, dynamicPoint.x, 0.5)) {
    dynamicPoint.x = dockPoint.x;
  }
  if (isMoreOrLess(dockPoint.y, dynamicPoint.y, 0.5)) {
    dynamicPoint.y = dockPoint.y;
  }
  if (isDraggingStartPoint) {
    newStart = dynamicPoint;
    newEnd = dockPoint;
  } else {
    newStart = dockPoint;
    newEnd = dynamicPoint;
  }

  // FIXME Mxj 2020-10-19 以下算法使端点始终在0.5的倍数上
  const calcPoint = (pt: IPoint) => {
    if (pt.x % 1 > 0.7) {
      pt.x = Math.ceil(pt.x);
    } else if (pt.x % 1 < 0.3) {
      pt.x = Math.floor(pt.x);
    } else {
      pt.x = Math.floor(pt.x) + 0.5;
    }
    if (pt.y % 1 > 0.7) {
      pt.y = Math.ceil(pt.y);
    } else if (pt.y % 1 < 0.3) {
      pt.y = Math.floor(pt.y);
    } else {
      pt.y = Math.floor(pt.y) + 0.5;
    }
  };
  calcPoint(newStart);
  calcPoint(newEnd);

  const newValue: ILineValue = {
    startPoint: newStart,
    endPoint: newEnd,
  };
  const resizeOffsets = getOffsetByPoints(originalPointsForCalculateStartAndEnd, newPointsForCalculateStartAndEnd, 0);
  const position = lineComp.position;
  const newPosition = {
    x: round(position.x + resizeOffsets.left),
    y: round(position.y + resizeOffsets.top),
  };
  const newLine = {
    id: lineComp.id,
    type: ComponentChangeType.Edit,
    position: newPosition,
    size: newSize,
    rotate: lineComp.rotate,
    value: newValue,
    specialSetting: {
      isResizeSingleLine: true,
    },
  };
  const ContainerPatches = originLineComp.parent?.getPositionPatchesOfChildrenChanged([newLine], true);
  return ContainerPatches!.patches;
}

export function getCompAndItsChildren<T>(
  comps: T[],
  excludeSymbolChild?: boolean,
  excludeSealedCompChildren?: boolean,
  ignoreHiddenComp?: boolean,
) {
  let result: T[] = [];
  comps.forEach((comp) => {
    //@ts-ignore
    if (!comp.hidden || (comp.hidden && !ignoreHiddenComp)) {
      result.push(comp);
    }
    //@ts-ignore
    if (comp.hidden && ignoreHiddenComp) {
      return;
    }
    //@ts-ignore
    if (comp.components) {
      const isSymbol = comp instanceof UISymbolComponent && !comp.isGroup;
      if (excludeSymbolChild && isSymbol) return;
      if (excludeSealedCompChildren && ((comp as unknown) as UIContainerComponent).isSealed) return;
      //@ts-ignore
      result = result.concat(
        //@ts-ignore
        getCompAndItsChildren(comp.components, excludeSymbolChild, excludeSealedCompChildren, ignoreHiddenComp),
      );
    }
  });
  return result;
}

/**
 * 获取一个组下面的所有子组件,不包含容器,如果子里面有子组,那么子组不计算在内,但是子组内部的子仍然计算在内
 * @param comp
 */
export function getCompChildrenWithoutGroupContainer(comp: UIContainerComponent) {
  let result: UIComponent[] = [];
  comp.components.forEach((child) => {
    if (child.isGroup) {
      result = result.concat(getCompChildrenWithoutGroupContainer(child as UIContainerComponent));
    } else {
      result.push(child);
    }
  });
  return result;
}
export function getCompUpperComponents(comps: UIComponent[]) {
  if (!comps.length) {
    return [];
  }

  let result: UIComponent[] = [];
  const parent = comps[0].parent;
  if (parent && !parent.isArtboard) {
    result.push(parent);
    result = result.concat(parent.components.filter((comp) => !comps.includes(comp)));
    result = result.concat(getCompUpperComponents([parent]));
  }

  return result;
}

const shouldCompRoundBounds = (type: string) => {
  return ![CPath, CCompoundPath].includes(type);
};

// 如果是复合路径，修改其默认状态的属性size/position/value/rotate
export function getDoByCompDynamicInfo(comps: UIComponent[], needRoundedResult: boolean = true) {
  let doOperation: ArtboardOperations = {};
  let counted: { [key: string]: boolean } = {};
  getCompAndItsChildren(comps, true).forEach((comp) => {
    const ID = comp.id;
    let notCounted = !counted[ID];
    if (notCounted) {
      const position = comp.currentState.position || comp.toJSON().position;
      const size = comp.currentState.size || comp.toJSON().size;
      const value = comp.currentState.value || comp.toJSON().value;
      // resize的过程中 状态是不会切换的
      const properties = comp.properties;
      const {
        dynamicInfo: {
          position: dynamicPosition,
          size: dynamicSize,
          value: dynamicValue,
          properties: dynamicProperties,
          autoSize: dynamicAutoSize,
        },
      } = comp;
      if (dynamicPosition) {
        if (!isEqual(position, dynamicPosition)) {
          const path = comp.getCurrentPositionPath();
          if (comp.rotate && comp.rotate !== 0) {
            needRoundedResult = false;
          }
          const finalPosition =
            needRoundedResult && shouldCompRoundBounds(comp.type)
              ? {
                  x: round(dynamicPosition.x),
                  y: round(dynamicPosition.y),
                }
              : dynamicPosition;
          (doOperation[comp.id] || (doOperation[comp.id] = [])).push(Ops.replace(path, finalPosition));
        }
      }
      if (dynamicSize) {
        const isSizeChange =
          notSameNumber(size.width, dynamicSize!.width) || notSameNumber(size.height, dynamicSize!.height);
        if (isSizeChange) {
          const forceUsedStatePath = [CText, CPureText].includes(comp.type) && Boolean(comp.currentStateID);
          const path = comp.getCurrentSizePath(forceUsedStatePath);
          const finalSize =
            needRoundedResult && shouldCompRoundBounds(comp.type)
              ? mapValues(dynamicSize, (value) => (isNumber(value) ? round(value) : value))
              : dynamicSize;
          (doOperation[comp.id] || (doOperation[comp.id] = [])).push(
            Ops.replace(`${path}`, {
              //注意保留lockedRatio
              ...size,
              ...finalSize,
            }),
          );
          if (comp instanceof UISymbolComponent) {
            (doOperation[comp.id] || (doOperation[comp.id] = [])).push(
              Ops.replace('/sizeVersion', Math.min(comp.sizeVersion + 1, comp.symbolSizeVersion + 1)),
            );
          }
        }
      }
      if (dynamicValue && !comp.isRefValue()) {
        const isValueChange = !isEqual(dynamicValue, value);
        if (isValueChange) {
          const path =
            [CCompoundPath, CPath].includes(comp.type) || comp.type === CPath
              ? '/value'
              : comp.getCurrentPropertiesPath('/value');
          (doOperation[comp.id] || (doOperation[comp.id] = [])).push(Ops.replace(`${path}`, dynamicValue));
        }
      }
      if (dynamicProperties?.fill) {
        const isFillChange = !isEqual(dynamicProperties.fill, properties.fill);
        if (isFillChange) {
          const path = comp.getCurrentPropertiesPath('properties/fill');
          (doOperation[comp.id] || (doOperation[comp.id] = [])).push(Ops.replace(`${path}`, dynamicProperties.fill));
        }
      }
      if (dynamicProperties?.cell) {
        const originCell = (comp.currentState.properties || comp.toJSON().properties).cell;
        const isCellChange = !isEqual(dynamicProperties.cell, originCell);
        if (isCellChange) {
          const path = comp.getCurrentPropertiesPath('properties/cell');
          (doOperation[comp.id] || (doOperation[comp.id] = [])).push(Ops.replace(`${path}`, dynamicProperties.cell));
        }
      }

      if (dynamicAutoSize !== undefined) {
        const isValueChange = !isEqual(dynamicAutoSize, comp.$data.autoSize);
        if (isValueChange) {
          const path = './autoSize';
          (doOperation[comp.id] || (doOperation[comp.id] = [])).push(Ops.replace(`${path}`, dynamicAutoSize));
        }
      }
      counted[ID] = true;
    }
    if (doOperation[comp.id]?.length) {
      const stateID = comp.currentStateID;
      if (stateID && !comp.states[stateID]) {
        doOperation[comp.id].unshift(Ops.add(`/states/${stateID}`, { disabled: false, properties: {} }));
      }
    }
  });
  return doOperation;
}

export function getChildLayout(comp: UIContainerComponent) {
  const childLayoutMap = collectComponentsLayout(comp.components, BoundsUtils.createBoundsBySize(comp.size));
  comp.components.forEach((childComp) => {
    if (childComp instanceof UIContainerComponent) {
      const grandChildrenLayout = getChildLayout(childComp);
      childComp.components.forEach((grandChild) => {
        const grandChildLayout = grandChildrenLayout.get(grandChild);
        if (grandChildLayout) {
          childLayoutMap.set(grandChild, grandChildLayout);
        }
      });
    }
  });
  return childLayoutMap;
}

/**
 * resize开始时，计算所有Comp的layout
 * @param components
 */
export function getBackupAllCompsLayout(components: UIComponent[]) {
  const bounds = getViewBoundsOfComponents(components)!;
  // 1.先获取当前一层comps的layout
  let backupAllCompLayoutMap = collectComponentsLayout(components, bounds);
  const allComponents = getCompAndItsChildren(components);
  // 2.再获取components中是容器的组件的子在父中的layout
  components.forEach((comp) => {
    //当前选中组件是容器，继续获取子的layout
    if (comp instanceof UIContainerComponent) {
      // 先获取容器该层所有子组件的layout,再去获取子组件下面的子组件
      const currentLayerLayout = collectComponentsLayout(comp.components, BoundsUtils.createBoundsBySize(comp.size));
      addLayoutToBackup(backupAllCompLayoutMap, allComponents, currentLayerLayout);
      comp.components.forEach((son) => {
        // 3.递归的获取子的layout
        if (son.isContainer) {
          const layoutMap = getChildLayout(son as UIContainerComponent);
          // 将子的layout添加到结果中
          addLayoutToBackup(backupAllCompLayoutMap, allComponents, layoutMap);
        }
      });
    }
  });
  return backupAllCompLayoutMap;
}

export function addLayoutToBackup(
  backupAllCompLayoutMap: WeakMap<UIComponent, IBasicLayout>,
  allComponents: UIComponent[],
  layoutNeedToAdd: WeakMap<UIComponent, IBasicLayout>,
) {
  allComponents.forEach((comp) => {
    const compLayout = layoutNeedToAdd.get(comp);
    if (compLayout) {
      backupAllCompLayoutMap.set(comp, compLayout);
    }
  });
}

export function getSuchChild(
  parent: IComponentData,
  fn: (comp: IComponentData) => boolean,
  isRecursive: boolean = true,
) {
  const children = parent.components;
  let result: IComponentData[] = [];
  if (isRecursive) {
    children?.forEach((comp) => {
      if (comp.components?.length) {
        result = result.concat(getSuchChild(comp, fn, isRecursive));
      } else {
        if (fn(comp)) {
          result.push(comp);
        }
      }
    });
  } else {
    children?.forEach((comp) => {
      if (fn(comp)) {
        result.push(comp);
      }
    });
  }
  return result;
}

export function setCompDynamicInfo(comps: UIComponent[], dynamicInfoMap: DynamicInfoMap) {
  comps.forEach((comp) => {
    if (dynamicInfoMap[comp.id]) {
      //有些时候，只有部分的数据变化了，所以做了下面的处理
      comp.dynamicInfo = {
        ...{
          position: comp.position,
          size: comp.size,
          value: comp.currentState.value,
          autoSize: comp.autoSize,
          properties: comp.properties,
        },
        ...dynamicInfoMap[comp.id],
      };
    }
  });
}

export function setCompData(comps: UIComponent[], dynamicInfoMap: DynamicInfoMap) {
  comps.forEach((comp) => {
    const data = dynamicInfoMap[comp.id];
    if (data) {
      //有些时候，只有部分的数据变化了，所以做了下面的处理
      const { position, size, value } = data;
      const compData = comp.toJSON();
      position && (compData.position = position);
      size && (compData.size = size);
      value && (compData.value = value);
    }
  });
}

export function clearCompDynamicInfo(components: UIComponent[]) {
  components.forEach((comp) => {
    comp.dynamicInfo = {};
    comp.initialResizeInfo = {};
  });
}

export function getAdjustedZeroOffset(offset: IBoundsOffset) {
  return mapValues(offset, (value) => (sameNumber(value, 0) ? 0 : value));
}

export function getCompSizeChangeByOffSet(offset: IBoundsOffset, comp: UIComponent) {
  let widthChange;
  let heightChange;
  let myOffset = getAdjustedZeroOffset(offset);
  heightChange = -myOffset.top + myOffset.bottom;
  widthChange = -myOffset.left + myOffset.right;

  const { horizontal: isHorizontalCenter, vertical: isVerticalMiddle } = comp.centerLayoutInfo;

  if (isHorizontalCenter || (comp.rotate && isVerticalMiddle)) {
    widthChange = -myOffset.left + myOffset.right;
  }
  if (isVerticalMiddle || (comp.rotate && isHorizontalCenter)) {
    heightChange = -myOffset.top + myOffset.bottom;
  }
  return {
    heightChange,
    widthChange,
  };
}

export function getResizeResultByParentChange(
  comp: IComponentData,
  offset: IBoundsOffset,
  layout: IBasicLayout | ILayout,
  options: ResizeOptions,
): ComponentResizeResult {
  const size = comp.size;
  const originSize = comp.size;
  const position = comp.position;
  const uiComponent = makeUIComponent(comp);
  // 部分类型的组件不能改变大小
  let newPosition = {
    x: position.x,
    y: position.y,
  };
  let newSize = {
    width: size.width,
    height: size.height,
  };
  let isShift = options.shift;
  // 如果我是响应式的，那么我要沿用父容器的shift
  if (comp.layout.responsive) {
    isShift = options.shift;
  }
  // 如果子是非响应式的，那么默认就是shift模式
  else {
    isShift = true;
  }
  const { before, after, isResponsive } = options.container;
  // 抽取出来一个等宽高比的resize方法，然后这里调用
  if (isShift) {
    const h = options.scale.h;
    const v = options.scale.v;
    let newSize = {
      width: size.width * h,
      height: size.height * v,
    };
    //如果父响应式responsive是false的话，并且父shift拉动时，才同时改变我的宽高
    const isWidthHeightChangeSimultaneously =
      (!isResponsive && options.shift && !comp.layout.responsive) || !!size.lockedRatio;
    newSize = updateSizeAsLockedRatio(size, newSize, isWidthHeightChangeSimultaneously);
    newPosition = {
      x: after.position.x + (position.x - before.position.x) * h,
      y: after.position.y + (position.y - before.position.y) * v,
    };
    newSize = uiComponent.adjustZeroSize(newSize);
    uiComponent.updateSizeWhenResizeText(newSize, size);
    // 让固定宽高优先级增高，这样用户能够精确的控制，并且复合组件也更好用
    if (comp.layout.responsive && !comp.layout.auto) {
      if (layout.fixedHeight) {
        newSize.height = size.height;
        newPosition.y = responsiveFixedHeightStrategy(position, size, offset, layout, options).y;
      }
      if (layout.fixedWidth) {
        newSize.width = size.width;
        newPosition.x = responsiveFixedWidthStrategy(position, size, offset, layout, options).x;
      }
    }

    return {
      position: newPosition,
      // 如果父容器非响应式，那么父shift拉动非顶点时，会同时改变子的宽高
      size: newSize,
      rotate: comp.rotate || 0,
    };
  }
  const newRotate = uiComponent.rotate;
  let newLeftTopPoint = uiComponent.getBoxPointsInParent()[0];
  if (layout.fixedWidth) {
    switch (layout.horizontal) {
      case HorizontalAlign.Left:
        newPosition.x = position.x + offset.left;
        break;
      case HorizontalAlign.Right:
        newPosition.x = position.x + offset.right;
        break;
      case HorizontalAlign.LeftAndRight:
        // BUG!
        assert.ok(false, '组件不能既固定宽度，又两边都锚定.');
        break;
      case HorizontalAlign.Auto:
        if (isEqual0(before.size.width - size.width)) {
          newPosition.x = position.x + offset.left + offset.right;
        } else {
          //将组件在组中的初始分布的位置记录下来，在resize过程中保持此比例不变
          if (!uiComponent.initialResizeInfo.xDistributionRatio) {
            uiComponent.initialResizeInfo.xDistributionRatio =
              (position.x - before.position.x) / (before.size.width - size.width);
          }
          newPosition.x =
            uiComponent.initialResizeInfo.xDistributionRatio * (after.size.width - size.width) + after.position.x;
        }
        break;
      case HorizontalAlign.Center:
        newPosition.x = after.position.x + after.size.width / 2 - size.width / 2;
        break;
      default:
        assert.ok(false, '不该有这种情况');
        break;
    }
  } else {
    switch (layout.horizontal) {
      case HorizontalAlign.Auto:
        newPosition.x = after.position.x + (position.x - before.position.x) * options.scale.h;
        newSize.width = size.width * options.scale.h;
        newLeftTopPoint.x = getNWPoint(getCenter(newPosition, newSize, 0), newSize, comp.rotate || 0).x;
        break;
      case HorizontalAlign.Center:
        newSize.width = size.width * options.scale.h;
        newPosition.x = after.position.x + after.size.width / 2 - newSize.width / 2;
        break;
      case HorizontalAlign.Right: {
        newSize.width = size.width * options.scale.h;
        //要保持右边距不变
        const originRight = before.size.width - position.x - size.width;
        newPosition.x = after.size.width - newSize.width - originRight + offset.left;
        if (notSameNumber(offset.right, 0)) {
          newLeftTopPoint.x = getNWPoint(getCenter(newPosition, newSize, 0), newSize, comp.rotate || 0).x;
        }
        break;
      }
      // 只有左右锚定时，大小的变化才不是按照scale来的
      case HorizontalAlign.LeftAndRight: {
        newSize.width = Math.max(getMinSizeOfComp(uiComponent).width, size.width + offset.right - offset.left);
        const vector = mapVectorToTargetCoordinates({ x: offset.left, y: 0 }, uiComponent.rotate);
        if (uiComponent.rotate) {
          if (notSameNumber(offset.left, 0)) {
            newLeftTopPoint.x += vector.x;
            newLeftTopPoint.y += vector.y;
          }
        } else {
          if (!uiComponent.initialResizeInfo.right) {
            uiComponent.initialResizeInfo.right = before.size.width - position.x - size.width;
          }
          newPosition.x = position.x + offset.left;
          if (uiComponent.type === CPath && originSize.width === 0) {
            newSize.width = 0;
          }
        }
        break;
      }
      default:
        if (offset.left !== 0) {
          newPosition.x = position.x + offset.left;
        }
        if (offset.right - offset.left !== 0) {
          newSize.width = size.width * options.scale.h;
        }
        if (notSameNumber(offset.left, 0)) {
          newLeftTopPoint.x = getNWPoint(getCenter(newPosition, newSize, 0), newSize, uiComponent.rotate).x;
        }
    }
  }

  if (layout.fixedHeight) {
    switch (layout.vertical) {
      case VerticalAlign.Top:
        newPosition.y = position.y + offset.top;
        break;
      case VerticalAlign.Bottom:
        newPosition.y = position.y + offset.bottom;
        break;
      case VerticalAlign.TopAndBottom:
        // BUG!
        assert.ok(false, '组件不能既固定宽度，又两边都锚定.');
        break;
      case VerticalAlign.Auto:
        if (isEqual0(before.size.height - size.height)) {
          newPosition.y = position.y + offset.top + offset.bottom;
        } else {
          if (!uiComponent.initialResizeInfo.yDistributionRatio) {
            uiComponent.initialResizeInfo.yDistributionRatio =
              (position.y - before.position.y) / (before.size.height - size.height);
          }
          newPosition.y =
            uiComponent.initialResizeInfo.yDistributionRatio * (after.size.height - size.height) + after.position.y;
          newLeftTopPoint.y = getNWPoint(getCenter(newPosition, newSize, 0), newSize, uiComponent.rotate).y;
        }
        break;
      case VerticalAlign.Middle:
        newPosition.y = after.position.y + after.size.height / 2 - size.height / 2;
        break;
      default:
        assert.ok(false, '不该有这种情况');
        break;
    }
  } else {
    switch (layout.vertical) {
      case VerticalAlign.Auto:
        newPosition.y = after.position.y + (position.y - before.position.y) * options.scale.v;
        newSize.height = size.height * options.scale.v;
        newLeftTopPoint.y = getNWPoint(getCenter(newPosition, newSize, 0), newSize, uiComponent.rotate).y;
        break;
      case VerticalAlign.Middle:
        newPosition.y = after.position.y + after.size.height / 2 - newSize.height / 2;
        newSize.height = size.height * options.scale.v;
        break;
      case VerticalAlign.TopAndBottom: {
        newSize.height = Math.max(getMinSizeOfComp(uiComponent).height, size.height + offset.bottom - offset.top);
        const vector = mapVectorToTargetCoordinates({ x: 0, y: offset.top }, uiComponent.rotate);
        if (uiComponent.type === CPath && originSize.height === 0) {
          newSize.height = 0;
        }
        if (uiComponent.rotate) {
          if (offset.top) {
            newLeftTopPoint.y += vector.y;
            newLeftTopPoint.x += vector.x;
          }
        } else {
          newPosition.y = position.y + offset.top;
        }
        break;
      }
      case VerticalAlign.Bottom: {
        newSize.height = size.height * options.scale.v;
        //要保持下边距不变
        const originBottom = before.size.height - position.y - size.height;
        newPosition.y = after.size.height - newSize.height - originBottom + offset.top;
        newLeftTopPoint = getNWPoint(getCenter(newPosition, newSize, 0), newSize, uiComponent.rotate);
        break;
      }
      default:
        if (offset.top !== 0) {
          newPosition.y = position.y + offset.top;
        }
        if (offset.bottom - offset.top !== 0) {
          newSize.height = size.height * options.scale.v;
        }
        break;
    }
  }
  newSize = uiComponent.adjustZeroSize(newSize);
  uiComponent.updateSizeWhenResizeText(newSize, size);
  newSize = updateSizeAsLockedRatio(size, newSize, uiComponent.lockedRatio);
  const isSizeChange = notSameNumber(newSize.height, size.height) || notSameNumber(newSize.width, size.width);
  if (uiComponent.rotate && isSizeChange) {
    newPosition = getNWPoint(getCenter(newLeftTopPoint, newSize, uiComponent.rotate), newSize, 0);
  }
  return {
    position: {
      x: newPosition.x,
      y: newPosition.y,
    },
    size: newSize,
    rotate: newRotate,
  };
}

/**
 * 提供一个可以随意改变大小的layout
 */
export function getResizeMySelfLayout() {
  return {
    horizontal: HorizontalAlign.LeftAndRight,
    vertical: VerticalAlign.TopAndBottom,
    fixedHeight: false,
    fixedWidth: false,
  };
}

export function getResizeListLayout(
  container: UIContainerComponent,
  offset: IBoundsOffset,
  info: ComponentResizeResult,
  options = { shift: false },
) {
  const realOffset = offset;
  const patches = { do: {}, undo: {} };
  const coordinateOffset = container.getCoordinateOffset(info);
  const { layout: layoutProp, cell } = container.properties;
  const { left: paddingLeft, right: paddingRight } = container.padding;
  const { shift } = options;
  if (layoutProp!.direction === 'vertical') {
    if (cell!.ratioHeight) {
      const newComponentsInfo = container.components.map((comp, index) => {
        // 先计算子应该变化多少高度，然后转化为top or bottom 的offset
        let myOffset = depthClone(realOffset);
        let heightChange: number;
        const childCount = container.components.length;
        const allGap = (layoutProp?.verticalGap || 0) * (childCount - 1);
        const finalCompHeight = (info.size.height - allGap) / childCount;
        heightChange = finalCompHeight - comp.size.height;
        if (isNotEqual0(offset.bottom)) {
          myOffset.bottom = heightChange;
        }
        if (isNotEqual0(offset.top)) {
          myOffset.top = -heightChange;
        }
        //处理子的大小改变
        const newCompInfo = comp.resizeMySelf(myOffset, shift);

        if (newCompInfo.patches) {
          coverPatches(patches, newCompInfo.patches);
        }

        // 处理position的变化
        if (isNotEqual0(offset.top) || isNotEqual0(offset.bottom)) {
          newCompInfo.position = {
            x: comp.position.x,
            y: comp.position.y + heightChange * index + offset.top,
          };
        }
        return {
          id: comp.id,
          type: ComponentChangeType.Edit,
          position: {
            x: newCompInfo.position.x + coordinateOffset.x,
            y: newCompInfo.position.y + coordinateOffset.y,
          },
          size: newCompInfo.size,
          rotate: newCompInfo.rotate,
        };
      });
      // 所有子的改变要到这里来
      const res = container.getPositionPatchesOfChildrenChanged(newComponentsInfo, false);
      if (res.patches) {
        coverPatches(patches, res.patches);
      }
    } else {
      const newComponentsInfo = container.components.map((comp) => {
        const myOffset = {
          ...realOffset,
          top: 0,
          bottom: 0,
        };
        //处理子的大小改变

        const newCompInfo = comp.resizeMySelf(myOffset, shift);

        if (newCompInfo.patches) {
          coverPatches(patches, newCompInfo.patches);
        }
        // 只有上拉的时候position才会变化
        if (isNotEqual0(realOffset.top) || isNotEqual0(realOffset.left)) {
          newCompInfo.position = {
            x: comp.position.x + realOffset.left,
            y: comp.position.y + realOffset.top,
          };
        }
        const newPosition = {
          x: newCompInfo.position.x + coordinateOffset.x,
          y: newCompInfo.position.y + coordinateOffset.y,
        };
        return {
          id: comp.id,
          type: ComponentChangeType.Edit,
          position: newPosition,
          size: newCompInfo.size,
          rotate: newCompInfo.rotate,
        };
      });
      // 所有子的改变要到这里来
      const res = container.getPositionPatchesOfChildrenChanged(newComponentsInfo, false);
      if (res.patches) {
        coverPatches(patches, res.patches);
      }
    }
  } else {
    if (cell!.ratioWidth) {
      const preComp = {
        x: 0,
        width: paddingLeft,
      };
      let accumulateFractionalPart = 0;
      const childCount = container.components.length;
      const horizontalGap = (layoutProp && layoutProp.horizontalGap) || 0;
      const allGap = horizontalGap * (childCount - 1);
      const averageCompWidth = (info.size.width - allGap - paddingLeft - paddingRight) / childCount;
      const averageCompWidthFractionalPart = averageCompWidth % 1;
      const averageCompWidthIntegerPart = averageCompWidth - averageCompWidthFractionalPart;
      const newComponentsInfo = container.components.map((comp, index) => {
        let widthOffset = averageCompWidthIntegerPart - comp.size.width;
        accumulateFractionalPart += averageCompWidthFractionalPart;
        if (accumulateFractionalPart >= 1) {
          accumulateFractionalPart -= 1;
          widthOffset += 1;
        }
        // 先计算子应该变化多少宽，然后转化为left or right 的offset
        let myOffset = depthClone(realOffset);
        if (isNotEqual0(realOffset.right)) {
          myOffset.right = widthOffset;
        }
        if (isNotEqual0(realOffset.left)) {
          myOffset.left = -widthOffset;
        }
        //这里是当组件和别人一起在resize时，就仅用right来控制大小变化
        if (isNotEqual0(realOffset.left) && isNotEqual0(realOffset.right)) {
          myOffset.left = 0;
          myOffset.right = widthOffset;
        }
        //处理子的大小改变
        const { position, size, rotate, patches: childPatches } = comp.resizeMySelf(myOffset, shift);
        if (childPatches) {
          coverPatches(patches, childPatches);
        }
        position.x = preComp.x + preComp.width + (index === 0 ? 0 : horizontalGap);
        preComp.x = position.x;
        preComp.width = size.width;
        return {
          id: comp.id,
          type: ComponentChangeType.Edit,
          position: {
            x: position.x,
            y: position.y + coordinateOffset.y,
          },
          size,
          rotate,
        };
      });
      // 所有子的大小改变要到这里来
      const res = container.getPositionPatchesOfChildrenChanged(newComponentsInfo, false);
      if (res.patches) {
        coverPatches(patches, res.patches);
      }
    } else {
      const newComponentsInfo = container.components.map((comp) => {
        const myOffset = {
          left: 0,
          right: 0,
          top: offset.top,
          bottom: offset.bottom,
        };
        const newCompInfo = comp.resizeMySelf(myOffset, shift);
        if (newCompInfo.patches) {
          coverPatches(patches, newCompInfo.patches);
        }
        if (isNotEqual0(realOffset.left)) {
          newCompInfo.position = {
            x: newCompInfo.position.x + realOffset.left,
            y: newCompInfo.position.y,
          };
        }
        return {
          id: comp.id,
          type: ComponentChangeType.Edit,
          position: {
            x: newCompInfo.position.x + coordinateOffset.x,
            y: newCompInfo.position.y + coordinateOffset.y,
          },
          size: newCompInfo.size,
          rotate: newCompInfo.rotate,
        };
      });
      const res = container.getPositionPatchesOfChildrenChanged(newComponentsInfo, false);
      if (res.patches) {
        coverPatches(patches, res.patches);
      }
    }
  }
  return patches;
}

/**
 * angle为0，size代表区域的大小，angle不为0，代表单个组件，此时传入的大小变化为组件本身的大小变化。
 * @param originSize 原区域的大小
 * @param newSize 新区域的大小
 * @param fixPoint 固定点：例如左上角，中心点
 * @param comps 组件
 */
export function getOffsetByNewSizeAndFixPoint(
  originSize: ISize,
  newSize: ISize,
  fixPoint: TransformOriginType,
  comps: UIComponent[],
  isScale: boolean,
) {
  let newCompViewBoundsSize = newSize;
  let originCompViewBoundsSize = originSize;
  //单个组件，如果有旋转，我们获取到的是viewBounds的改变，这是基于组件本身的宽高变化而计算出的
  if (comps.length === 1 && isScale) {
    const comp = comps[0];
    const compBounds = comp.getViewBoundsInParent();
    originCompViewBoundsSize = { height: compBounds.height, width: compBounds.width };
    newCompViewBoundsSize = getBoundsInParent({ size: newSize, rotate: comp.rotate, position: comp.position });
  }
  const widthDiff = newCompViewBoundsSize.width - originCompViewBoundsSize.width;
  const heightDiff = newCompViewBoundsSize.height - originCompViewBoundsSize.height;

  const halfWidthForLeft = -Math.floor(widthDiff / 2);
  const halfWidthForRight = Math.ceil(widthDiff / 2);
  const halfHeightForTop = -Math.floor(heightDiff / 2);
  const halfHeightForBottom = Math.ceil(heightDiff / 2);
  const offsetMap = {
    [TransformOriginType.NW]: { left: 0, top: 0, right: widthDiff, bottom: heightDiff },
    [TransformOriginType.NE]: { left: -widthDiff, top: 0, right: 0, bottom: heightDiff },
    [TransformOriginType.SE]: { left: -widthDiff, top: -heightDiff, right: 0, bottom: 0 },
    [TransformOriginType.SW]: { left: 0, top: -heightDiff, right: widthDiff, bottom: 0 },
    [TransformOriginType.N]: { left: halfWidthForLeft, top: 0, right: halfWidthForRight, bottom: heightDiff },
    [TransformOriginType.E]: { left: -widthDiff, top: halfHeightForTop, right: 0, bottom: halfHeightForBottom },
    [TransformOriginType.S]: { left: halfWidthForLeft, top: -heightDiff, right: halfWidthForRight, bottom: 0 },
    [TransformOriginType.W]: { left: 0, top: halfHeightForTop, right: widthDiff, bottom: halfHeightForBottom },
    [TransformOriginType.MIDDLE]: {
      left: halfWidthForLeft,
      top: halfHeightForTop,
      right: halfWidthForRight,
      bottom: halfHeightForBottom,
    },
  };
  return offsetMap[fixPoint];
}

export function getLineValueByZoom(
  value: ILineValue,
  scale: {
    x: number;
    y: number;
  },
) {
  const { startPoint, endPoint } = value;

  const newStart = {
    x: startPoint.x * scale.x,
    y: startPoint.y * scale.y,
  };
  const newEnd = {
    x: endPoint.x * scale.x,
    y: endPoint.y * scale.y,
  };

  return {
    startPoint: newStart,
    endPoint: newEnd,
  };
}
