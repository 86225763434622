import IArtboard from '@fbs/rp/models/artboard';
import { IPosition, ISize } from '@fbs/common/models/common';
import { IUICompConstructOptions } from '@/customTypes';

import Doc from '../document';
import { UIFragment } from '.';

export default class UIArtboard extends UIFragment {
  public readonly artboardID: string;

  constructor(data: IArtboard, doc: Doc, public options?: IUICompConstructOptions) {
    super(data, doc, options);
    this.artboardID = data._id;
    this.dynamicInfo = {};
    this.isMain = true;
  }

  get position(): IPosition {
    return this.dynamicInfo.position || this.data.position;
  }

  get size(): ISize {
    return this.dynamicInfo.size || this.data.size;
  }
}
