enum SelectionFrameType {
  box,          // 全部八个选择点
  corner,       // 只有四个顶点
  leftTop_to_rightBottom,     // 只有左上，右下两个点
  leftBottom_to_rightTop,     // 只有左下，右上两个点
  none,
  leftMiddle_to_rightMiddle, // 只有左右两个点
  topMiddle_to_bottomMiddle,//  只有上下两个点
  control,      // 上下左右4个点
}

// 矩形的8个控制的分布
export enum SelectionPoints {
  leftTop,//西北
  rightTop,//东北
  rightBottom,//东南
  leftBottom,//西南
  topMiddle,//北
  rightMiddle,//东
  bottomMiddle,//南
  leftMiddle//西
}

const lineTypes = [SelectionFrameType.leftTop_to_rightBottom, SelectionFrameType.leftBottom_to_rightTop];

export function isLineSelection(type: SelectionFrameType) {
  return lineTypes.indexOf(type) !== -1;
}


export default SelectionFrameType;