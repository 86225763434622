import * as React from 'react';
import classnames from 'classnames';

import Icon from '../Icon';
import { isIE } from '../utils';

import './index.scss';

export interface IEmptyDialogProps {
  show?: boolean;
  width?: number | string;
  height?: number | string;
  contentMinHeight?: number;
  contentMaxHeight?: number;
  children?: any;
  showClose?: boolean;
  clickModalToDismiss?: boolean;
  escToClose?: boolean;
  contentOverflow?: 'auto' | 'hidden' | 'visible' | 'default';

  onCancel?(e: any): void;
}

interface IEmptyDialogState {
  closing: boolean;
}

class EmptyDialog extends React.Component<IEmptyDialogProps, IEmptyDialogState> {
  static defaultProps = {
    show: true,
    children: '',
    showClose: true,
    clickModalToDismiss: false,
    contentOverflow: 'auto',
    escToClose: true,
    contentMinHeight: 0,
    height: 200,
  };

  private dialogContent: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: IEmptyDialogProps) {
    super(props);
    this.state = {
      closing: false,
    };
  }

  componentDidMount(): void {
    if (this.props.escToClose) {
      window.addEventListener('keydown', this.onWindowKeyDown);
    }
  }

  componentWillUnmount(): void {
    if (this.props.escToClose) {
      window.removeEventListener('keydown', this.onWindowKeyDown);
    }
  }

  onWindowKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && this.props.onCancel) {
      this.handleClose(e);
    }
  };

  handleAnimationEnd = (e: any) => {
    const { onCancel } = this.props;
    const animateDom = this.dialogContent.current;
    onCancel && onCancel(e);
    animateDom?.removeEventListener('animationend', this.handleAnimationEnd);
  };

  handleClose = (e: any) => {
    const { onCancel } = this.props;
    const animateDom = this.dialogContent.current;
    animateDom?.addEventListener('animationend', this.handleAnimationEnd);
    this.setState({ closing: true }, () => {
      !animateDom && onCancel && onCancel(e);
    });
  };

  render() {
    let {
      clickModalToDismiss,
      show,
      showClose,
      children,
      contentOverflow,
      width,
      height,
      contentMinHeight,
      contentMaxHeight,
    } = this.props;

    const { closing } = this.state;

    return (
      <div
        className={classnames('', {
          'dsm-c-comp-empty-dialog': show,
          hide: !show,
          ie: isIE(),
        })}
      >
        <div
          className={classnames('dsm-c-bg', {
            closing,
          })}
          onClick={(e: any) => clickModalToDismiss && this.handleClose(e)}
        />
        <div className="dsm-c-pop-wrap">
          <div
            ref={this.dialogContent}
            className={classnames('dsm-c-pop', {
              closing: closing,
            })}
            style={{
              overflow: contentOverflow,
              width,
              height,
              minHeight: contentMinHeight,
              maxHeight: contentMaxHeight,
            }}
          >
            {showClose && <Icon cls="demo_close" onClick={this.handleClose} />}
            <div className={classnames('dsm-c-pop-content')}>{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmptyDialog;
