import { io, Socket } from 'socket.io-client';

import INode, {
  INodeAddPatch,
  INodeCopyPatch,
  INodeMovePatch,
  INodeRemovePatch,
  INodePatchPatch,
  INodeRecoverPatch,
  INodeHidePatch,
  INodeRemoveAllPatch,
  INodeClonePatch,
  IUngroupPatch,
  IGroupPatch,
} from '@fbs/rp/models/node';
import IArtboard, { IArtboardAddPatch } from '@fbs/rp/models/artboard';
import {
  PageIOSelect,
  IOEvent,
  IONotifyHandler,
  IOResponse,
  IOType,
  PageIOPatches,
  UserSelectedComponents,
  SessionInfo,
  OperationType,
  IOResponseCode,
  AppPatch,
} from '@fbs/rp/models/io';
import i18n from '@i18n';
import { isStartFromPreview } from '@helpers/previewHelper';
import { CustomError } from '@fbs/common/models/error';
import { INodeAddInfo } from '@/dispatchers/appDispatcher';
import { isDev } from '@/utils/envUtils';

import { appDataManager, IOHandlers } from './appDataManager';
import { heartbeat, isAfk } from './afkWatcher';
import { offlineDemoManager } from '../components/Application/ApplicationBar/OfflineDemo/offlineDemoManager';

// enum Status {
//   // 连接中
//   Connecting,
//   // 重连中
//   ReConnecting,
//   // 已连接
//   Connected,
//   // 主动断开，不用重连
//   Closed,
// }

let currentAppID = '';
let currentSessionID = '';

// 延时重连
let delayReconnectTimeout: Timeout = 0;

let callback: IONotifyHandler | null = null;

let firstConnection = true;

// // 当前socket状态
// let socketStatus = Status.Connecting;

// 是否主动断开连接
let manualDisconnected = false;

const isDebugMode = window.location.href.indexOf('debug=1') !== -1;
export const isExampleMode = window.location.href.indexOf('/example') !== -1;

// 自动重连的时间
const AutoReconnectDelay = 3e3;
// 自动重连的最长时间
const AutoReconnectDelayMax = 1e4;

const pathName = location.pathname;
// socket emit 超时时间 5分钟
const SOCKET_EMIT_TIMEOUT = 3e5;

/**
 * 获取项目ID，兼容私有部署
 */
const appID = (() => {
  let appID = '';
  const editorKey = '/editor/';
  let editorIndex = pathName.indexOf(editorKey);

  if (editorIndex !== -1) {
    let path = pathName.substring(editorIndex + editorKey.length);
    if (path.indexOf('?') !== -1) {
      path = path.substring(0, path.indexOf('?'));
    }
    if (path.indexOf('#') !== -1) {
      path = path.substring(0, path.indexOf('#'));
    }
    appID = path.split('/')[0];
  }
  return appID;
})();

/**
 * 客户端工作台
 */
const isMockRPWorkbench = pathName.includes('/login') || pathName.includes('/home');
const isFakeSocket = isStartFromPreview || isExampleMode || RP_CONFIGS.isOfflineDemo || isMockRPWorkbench;

let socket!: Socket;
initSocket();

function initSocket() {
  if (isFakeSocket) {
    console.log('fake socket mode');
    return;
  }

  socket = io(isDebugMode ? 'https://wss.mockplus.cn/rp' : window.apis.IO, {
    query: {
      token: window.apis.Token || window.desktopToken || '',
      appID: appID || '',
    },
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: AutoReconnectDelay,
    reconnectionDelayMax: AutoReconnectDelayMax,
    autoConnect: false,
    path: `${isDev ? '' : (RP_CONFIGS.isPrivateDeployment && window.apis.ioPathPrefix) || ''}/socket.io`,
    // reconnectionAttempts: 5,
  });
}

/***
 * 为socket，增加超时设置
 * @param {Socket} socket
 */
const useSocketTimeout = <S extends Socket, A = any>(time: number, callback: (res: IOResponse<A>) => void) => {
  let isExecute = false;
  const handleTimeout = () => {
    if (!isExecute) {
      isExecute = true;
      callback && callback({ code: IOResponseCode.SocketEmitTimeOut, message: 'socket timeout' });
    }
  };
  let timeId = setTimeout(handleTimeout, time);
  return function handleCallback(this: S, res: IOResponse<A>) {
    if (isExecute) {
      return;
    }
    isExecute = true;
    clearTimeout(timeId);
    callback && callback.call(this, res);
  };
};

/**
 * 手动重连
/**
 *
 *
 */
export const manualConnect = () => {
  if (!socket) {
    return;
  }

  clearTimeout(delayReconnectTimeout);
  if (socket.connected) {
    socket.disconnect();
  }
  // 处理io token可能丢失的问题
  const socketQuery = socket.io.opts.query;
  if (socketQuery && !socketQuery.token) {
    socket.id = '';
    socketQuery.token = window.apis.Token || window.desktopToken || '';
  }
  socket.connect();
};

let intervalID: Timeout | undefined;

/**
 * 向后台发送心跳，作为协同在线依据
 */
export function checkHeartbeat() {
  const time = Date.now();
  socket.emit(IOEvent.Heartbeat, {
    appID: currentAppID,
    sessionID: currentSessionID,
    time,
  });
}

/**
 * 延时重启
 */
const delayManualConnect = () => {
  clearTimeout(delayReconnectTimeout);
  delayReconnectTimeout = setTimeout(() => {
    // 重连间隔没有进入重连，就手动重连
    console.log('delay manual connecting');
    manualConnect();
  }, AutoReconnectDelay + AutoReconnectDelayMax);
};

/**
 * socket连接成功
 */
const handleSocketConnect = () => {
  if (socket.connected) {
    clearTimeout(delayReconnectTimeout);
    appDataManager.socketOffline = false;

    if (!firstConnection) {
      handleSocketReconnect();
    } else {
      firstConnection = false;
    }

    console.log('io connected');
  }

  clearInterval(intervalID);
  intervalID = setInterval(() => {
    if (!currentAppID || !currentSessionID) {
      return;
    }
    // 如果已经离线了
    if (isAfk()) {
      console.log('is afk');
      manualDisconnected = true;
      socket.disconnect();
      clearInterval(intervalID);
      return;
    }
    // 每分钟向后台发送心跳
    checkHeartbeat();
  }, 1e3 * 60);
};

/**
 * socket断开连接
 */
const handleSocketDisconnect = (reason: string) => {
  appDataManager.socketOffline = true;
  console.log(`disconnect because of ${reason}, ${new Date().toLocaleTimeString()}`);
  if (reason === 'io server disconnect') {
    console.log('%cIO server disconnect', 'color: red;');
    // the disconnection was initiated by the server, you need to reconnect manually
    manualConnect();
  }
  if (!manualDisconnected) {
    notifySocketStatusChange(false);
  }
};

/**
 * socket报错
 */
const handleSocketError = (error: Error) => {
  console.log(error);
};

/**
 * socket连接错误
 */
const handleSocketConnectError = (error: Error) => {
  console.log(error);
  if (!socket.connected) {
    if (!manualDisconnected) {
      notifySocketStatusChange(false);
      delayManualConnect();
    }
  }
};

/**
 * socket重连成功
 */
const handleSocketReconnect = () => {
  joinAppCooper(currentAppID, true)
    .then(() => {
      showMessage(i18n('alert.networkReconnectionSuccessful'));
      notifySocketStatusChange(true);
    })
    .catch((e) => {
      notifyIOError(e);
    });
};

/**
 * socket重连计数
 */
const handleSocketReconnectAttempt = (attempt: number) => {
  clearTimeout(delayReconnectTimeout);
  console.log(`reconnect ${attempt}`);
};

/**
 * 初始化监听器
 */
function initSocketListener() {
  if (!socket) {
    return;
  }

  socket.on('connect_error', handleSocketConnectError);
  socket.on('disconnect', handleSocketDisconnect);
  socket.on('connect', handleSocketConnect);

  socket.io.on('reconnect_attempt', handleSocketReconnectAttempt);
  socket.io.on('error', handleSocketError);
}

initSocketListener();

// 初次连接
socket && socket.connect();

function notifyIOError(e: CustomError, callback?: (e: CustomError) => void) {
  if (e.code === IOResponseCode.Unauthorized) {
    notifyLoginExpiredError(e);
  }
  callback && callback(e);
}

let messageID: number = 0;
// 显示信息
function showMessage(message: string, autoClear: boolean = true, fatal: boolean = false) {
  if (!callback) {
    return;
  }
  callback({
    type: IOType.Message,
    payload: {
      id: messageID++,
      message,
      time: new Date(),
      autoClear,
      fatal,
    },
  });
}

// 改变离线同步的状态
function toggleOfflineSave(isSync: boolean) {
  if (!callback) {
    return;
  }
  callback({
    type: IOType.OfflineSync,
    payload: {
      isSync,
    },
  });
}

// 修改socket网络状态
function notifySocketStatusChange(available: boolean) {
  if (!callback) {
    return;
  }
  // 如果是要网络不可用，等5秒钟，万一网络又成功重新连接上了呢，防止网络抖动导致问题
  if (!available) {
    setTimeout(() => {
      if (!callback) {
        return null;
      }
      // io 已经重新连接了
      if (socket.connected) {
        return;
      }

      // 如果提交缓存数据中再次断线，那么关闭提交的窗口，并重置相应状态
      toggleOfflineSave(false);
      isSavingOfflineData = false;

      callback({
        type: IOType.SocketStatus,
        payload: {
          type: 'socket-state-change',
          value: available,
        },
      });
    }, 5e3);
    return;
  }
  callback({
    type: IOType.SocketStatus,
    payload: {
      type: 'socket-state-change',
      value: available,
    },
  });
}

let networkTimeout = 0;

function notifyNetworkStatusChange(available: boolean) {
  if (!callback) {
    return;
  }
  if (!available) {
    networkTimeout = window.setTimeout(() => {
      if (!callback) {
        return;
      }
      callback({
        type: IOType.NetworkStatus,
        payload: {
          type: 'network-state-change',
          value: available,
        },
      });
    }, 5e3);
    return;
  }
  window.clearTimeout(networkTimeout);
  callback({
    type: IOType.NetworkStatus,
    payload: {
      type: 'network-state-change',
      value: available,
    },
  });
}

// 是否正在保存离线数据，保存过程中不允许再次保存
let isSavingOfflineData: boolean = false;

// 保存离线数据
// appID: 项目 ID
// isReconnect: 是否是重新连接
async function saveOfflineData(appID: string, isReconnect: boolean) {
  if (isSavingOfflineData || appDataManager.isExample) {
    return;
  }
  isSavingOfflineData = true;
  if (appDataManager.hasOperationToHandle()) {
    try {
      console.log(`----------- saving  -----------`);
      toggleOfflineSave(true);
      await appDataManager.waitForSyncOperations(isReconnect);
      // 如果同步失败，就走catch通道，永远不会重载页面
      console.log('----------- done -----------');
      if (!isReconnect) {
        isSavingOfflineData = false;
        setTimeout(() => {
          window.location.reload();
        }, 1e3);
        toggleOfflineSave(false);
        // 如果页面将要重载，不应该继续响应后续的操作
        return new Promise(() => {});
      }
    } catch (e) {
      console.log('----------- error -----------');
      notifyIOError(new CustomError((e as Error).message));
    }
    toggleOfflineSave(false);
  }
  isSavingOfflineData = false;
}

function notifyLoginExpiredError(e: CustomError) {
  callback?.({
    type: IOType.LoginExpired,
    payload: {
      code: e.code,
      message: e.message,
    },
  });
}

// 监听来自服务器的下发信息
export function listenPatches(cb: IONotifyHandler) {
  if (!socket) {
    return;
  }

  callback = cb;
  socket.on(IOEvent.PagePatch, (data: PageIOPatches) => {
    cb({
      type: IOType.PagePatches,
      payload: data,
    });
  });
  socket.on(IOEvent.PageSelect, (data: PageIOSelect) => {
    cb({
      type: IOType.PageSelect,
      payload: data,
    });
  });
  socket.on(IOEvent.NodeUpdate, (data: INode[]) => {
    cb({
      type: IOType.NodeUpdate,
      payload: data,
    });
  });
  socket.on(IOEvent.CoopersUpdate, (sessions: SessionInfo[]) => {
    cb({
      type: IOType.CoopersUpdate,
      payload: sessions,
    });
  });
  socket.on(IOEvent.Obituary, (data: { appID: string; sessionIDs: string[] }) => {
    cb({
      type: IOType.CoopersObituary,
      payload: data,
    });
  });
  socket.on(IOEvent.PageRollback, (data: { pageID: string; appID: string; userName: string; snapshotName: string }) => {
    cb({
      type: IOType.PageRollback,
      payload: data,
    });
  });
  socket.on(IOEvent.AppPatch, (data: any) => {
    cb({
      type: IOType.AppPatch,
      payload: data,
    });
  });
  socket.on(IOEvent.MultiSignIn, (data: { code: number; message?: string }) => {
    cb({
      type: IOType.MultiSignIn,
      payload: data,
    });
  });
  socket.on(
    IOEvent.LoseProjectPermission,
    (data: { message: { content: string; appID?: string; teamID?: string; code: number } }) => {
      cb({
        type: IOType.LoseProjectPermission,
        payload: data,
      });
    },
  );
  socket.on(IOEvent.SymbolUpdate, (data: { libID: string; userID: number; updatedBy: number }) => {
    cb({
      type: IOType.SymbolUpdate,
      payload: data,
    });
  });
  // 监听离线演示包后台准备完成
  socket.on(IOEvent.RPOfflineDemo, (data: { code: number; url?: string; v?: string; message?: string }) => {
    const { code, url, v, message } = data;

    if (!v) {
      return;
    }

    if (code === 0 && url) {
      offlineDemoManager.resolve(v, url);
    } else {
      offlineDemoManager.reject(v, message);
    }
  });
}

// 参与项目的协同编辑
export async function joinAppCooper(appID: string, isReconnect: boolean = false): Promise<SessionInfo> {
  const sessionID = appID === currentAppID ? currentSessionID : '';
  currentAppID = appID;
  exitCurrentAppCooper();
  return new Promise((resolve, reject) => {
    let failed: boolean = false;
    const timeout = setTimeout(() => {
      failed = true;
      reject(new Error(i18n('alert.failedToJoinProjectCollaboration')));
      notifySocketStatusChange(false);
    }, 15 * 1e3);
    heartbeat();
    socket.emit(
      IOEvent.JoinAppCooper,
      {
        appID,
        sessionID,
      },
      async (res: IOResponse<SessionInfo>) => {
        clearTimeout(timeout);
        if (res.code === 0 && res.payload) {
          currentSessionID = res.payload.id;
          // showMessage(i18n('alert.multiplayerCollaborativeSuccess'));
          // 说明上面的超时已经到了，需要重新设置状态
          if (failed) {
            failed = false;
            notifySocketStatusChange(true);
          }
          await saveOfflineData(appID, isReconnect);
          resolve(res.payload);
          return;
        }
        reject(new CustomError(res.message || '', res.code));
      },
    );
  });
}

// 退出当前项目的协同
export function exitCurrentAppCooper() {
  if (!currentAppID) {
    return;
  }
  heartbeat();
  socket.emit(
    IOEvent.ExitAppCooper,
    {
      appID: currentAppID,
      sessionID: currentSessionID,
    },
    () => {
      console.log(`leave ${currentAppID}`);
    },
  );
}

// 请求同步某个页面上的组件选中信息
export async function syncComponentsSelectOfNode(
  appID: string,
  nodeID: string,
): Promise<IOResponse<UserSelectedComponents>> {
  return new Promise<IOResponse<UserSelectedComponents>>((resolve, reject) => {
    heartbeat();
    socket.emit(
      IOEvent.PageSelectSync,
      {
        appID,
        nodeID,
      },
      (res: IOResponse<UserSelectedComponents>) => {
        if (res.code === 0) {
          resolve(res);
          return;
        }
        reject(new CustomError(res.message || '', res.code));
      },
    );
  });
}

// push artboard patches
export function pushArtboardPatches(data: PageIOPatches) {
  return appDataManager
    .handleOperation({
      type: OperationType.PushArtboardPatches,
      data,
    })
    .catch((e) => {
      notifyIOError(e);
    });
}

// 推送画板的选中信息
export function pushArtboardSelect(data: PageIOSelect) {
  if (!socket) {
    return;
  }
  heartbeat();
  socket.emit(IOEvent.PageSelect, data, () => {});
}

// TODO: zjq 新增页面节点
export async function pushNodeAddPatch(data: INodeAddPatch): Promise<INode> {
  return appDataManager.handleOperation({
    type: OperationType.AddNode,
    data,
  }) as Promise<INode>;
}

// TODO: zjq 页面节点位置拖拽
export async function pushNodeMovePatch(movePatch: INodeMovePatch): Promise<INode[]> {
  return new Promise<INode[]>((resolve, reject) => {
    if (!socket.connected) {
      reject(new Error(i18n('alert.unableToMoveNode')));
      return;
    }
    heartbeat();
    socket.emit(IOEvent.NodeMove, movePatch, (res: IOResponse<INode[]>) => {
      if (res.code === 0) {
        resolve(res.payload!);
        return;
      }
      notifyIOError(new CustomError(res.message || '', res.code), reject);
    });
  });
}
// 页面树编组
export async function pushNodeGroupPatch(nodeGroupPatch: INodeAddInfo, selectedIDs: string[]): Promise<IGroupPatch> {
  return new Promise<IGroupPatch>((resolve, reject) => {
    if (!socket.connected) {
      reject(new Error(i18n('alert.unableToGroupNode')));
      return;
    }
    heartbeat();
    socket.emit(IOEvent.NodeGroup, nodeGroupPatch, selectedIDs, (res: IOResponse<IGroupPatch>) => {
      if (res.code === 0) {
        resolve(res.payload!);
        return;
      }
      notifyIOError(new CustomError(res.message || '', res.code), reject);
    });
  });
}

// 页面树解组
export async function pushNodeUnGroupPatch(info: { appID: string; nodeID: string }): Promise<IUngroupPatch> {
  return new Promise<IUngroupPatch>((resolve, reject) => {
    if (!socket.connected) {
      reject(new Error(i18n('alert.unableToUnGroupNode')));
      return;
    }
    heartbeat();
    socket.emit(IOEvent.NodeUnGroup, info, (res: IOResponse<IUngroupPatch>) => {
      if (res.code === 0) {
        resolve(res.payload!);
        return;
      }
      notifyIOError(new CustomError(res.message || '', res.code), reject);
    });
  });
}

export async function pushNodeCopyPatch(copyPatch: INodeCopyPatch): Promise<INodeClonePatch> {
  return new Promise<INodeClonePatch>((resolve, reject) => {
    if (!socket.connected) {
      reject(new Error(i18n('alert.unableToCopyNode')));
      return;
    }
    heartbeat();
    socket.emit(IOEvent.NodeCopy, copyPatch, (res: IOResponse<INodeClonePatch>) => {
      if (res.code === 0) {
        resolve(res.payload!);
        return;
      }
      notifyIOError(new CustomError(res.message || '', res.code), reject);
    });
  });
}

export async function pushNodeRemovePatch(removePatch: INodeRemovePatch): Promise<INode[]> {
  return new Promise<INode[]>((resolve, reject) => {
    if (!socket.connected) {
      reject(new Error(i18n('alert.unableToRemoveNode')));
      return;
    }
    heartbeat();
    socket.emit(IOEvent.NodeRemove, removePatch, (res: IOResponse<INode[]>) => {
      if (res.code === 0) {
        resolve(res.payload!);
        return;
      }
      notifyIOError(new CustomError(res.message || '', res.code), reject);
    });
  });
}

export async function pushNodePatchPatch(patchPatch: INodePatchPatch): Promise<INode> {
  return new Promise<INode>((resolve, reject) => {
    if (!socket.connected) {
      reject(new Error(i18n('alert.unableToEditNode')));
      return;
    }
    heartbeat();
    socket.emit(IOEvent.NodePatch, patchPatch, (res: IOResponse<INode>) => {
      if (res.code === 0) {
        resolve(res.payload!);
        return;
      }
      notifyIOError(new CustomError(res.message || '', res.code), reject);
    });
  });
}

export async function pushRecoverNodePatch(patchPatch: INodeRecoverPatch): Promise<INode[]> {
  return new Promise<INode[]>((resolve, reject) => {
    if (!socket.connected) {
      reject(new Error(i18n('alert.unableToEditNode')));
      return;
    }
    heartbeat();
    socket.emit(IOEvent.NodeRecover, patchPatch, (res: IOResponse<INode[]>) => {
      if (res.code === 0) {
        resolve(res.payload!);
        return;
      }
      notifyIOError(new CustomError(res.message || '', res.code), reject);
    });
  });
}

export async function pushNodeRemovePermanentlyPatch(removePatch: INodeRemovePatch): Promise<INode[]> {
  return new Promise<INode[]>((resolve, reject) => {
    if (!socket.connected) {
      reject(new Error(i18n('alert.unableToRemoveNode')));
      return;
    }
    heartbeat();
    socket.emit(IOEvent.NodeRemovePermanently, removePatch, (res: IOResponse<INode[]>) => {
      if (res.code === 0) {
        resolve(res.payload!);
        return;
      }
      notifyIOError(new CustomError(res.message || '', res.code), reject);
    });
  });
}

export async function pushNodeRemoveAllPermanentlyPatch(removePatch: INodeRemoveAllPatch): Promise<INode[]> {
  return new Promise<INode[]>((resolve, reject) => {
    if (!socket.connected) {
      reject(new Error(i18n('alert.unableToRemoveNode')));
      return;
    }
    heartbeat();
    socket.emit(IOEvent.NodeRemoveAllPermanently, removePatch, (res: IOResponse<INode[]>) => {
      if (res.code === 0) {
        resolve(res.payload!);
        return;
      }
      notifyIOError(new CustomError(res.message || '', res.code), reject);
    });
  });
}

export async function pushHidePageNode(hidePatch: INodeHidePatch) {
  return new Promise<INode[]>((resolve, reject) => {
    if (!socket.connected) {
      reject(new Error(i18n('alert.unableToHideNode')));
      return;
    }
    heartbeat();
    socket.emit(IOEvent.NodeHidden, hidePatch, (res: IOResponse<INode[]>) => {
      if (res.code === 0) {
        resolve(res.payload!);
        return;
      }
      notifyIOError(new CustomError(res.message || '', res.code), reject);
    });
  });
}

// 这些方法暂时写在这里，是提供给 offline manager 使用的
export const ioHandlers: IOHandlers = {
  // 添加画板（辅助画板）
  addArtboard: (data: IArtboardAddPatch): Promise<IArtboard> => {
    return new Promise<IArtboard>((resolve, reject) => {
      heartbeat();
      socket.emit(
        IOEvent.ArtboardAdd,
        data,
        useSocketTimeout(SOCKET_EMIT_TIMEOUT, (res: IOResponse<IArtboard>) => {
          if (res.code === 0) {
            resolve(res.payload!);
            return;
          }
          notifyIOError(new CustomError(res.message || '', res.code), reject);
        }),
      );
    });
  },
  pushNodeAddPatch: (data: INodeAddPatch): Promise<INode> => {
    return new Promise<INode>((resolve, reject) => {
      heartbeat();
      socket.emit(
        IOEvent.NodeAdd,
        data,
        useSocketTimeout(SOCKET_EMIT_TIMEOUT, (res: IOResponse<INode>) => {
          if (res.code === 0) {
            resolve(res.payload!);
            return;
          }
          notifyIOError(new CustomError(res.message || '', res.code), reject);
        }),
      );
    });
  },
  pushPagePatch: (
    data: PageIOPatches,
  ): Promise<{
    code: number;
    message?: string;
  }> => {
    return new Promise((resolve, reject) => {
      heartbeat();
      socket.emit(
        IOEvent.PagePatch,
        data,
        useSocketTimeout(SOCKET_EMIT_TIMEOUT, (res: { code: number; message?: string }) => {
          if (res.code === IOResponseCode.Success) {
            resolve(res);
            return;
          }
          notifyIOError(new CustomError(res.message || '', res.code), reject);
        }),
      );
    });
  },
};

// 添加画板（辅助画板）
export function addArtboard(data: IArtboardAddPatch): Promise<IArtboard> {
  return appDataManager
    .handleOperation({
      type: OperationType.AddArtboard,
      data,
    })
    .catch((e) => {
      notifyIOError(e);
    }) as Promise<IArtboard>;
}

// 编辑节点
// FIXME: 这个要不的，直接取 currentAppID, currentSessionID
export function editNode(nodeID: string) {
  heartbeat();
  socket.emit(IOEvent.NodeEdit, {
    appID: currentAppID,
    sessionID: currentSessionID,
    nodeID,
  });
}

// 判断节点是否可以被删除
export async function canDeleteNode(appID: string, nodeID: string): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    if (!socket.connected) {
      reject(new Error(i18n('alert.unableToRemoveNode')));
      return;
    }
    heartbeat();
    socket.emit(
      IOEvent.DetectNodeCanDelete,
      {
        appID,
        sessionID: currentSessionID,
        nodeID,
      },
      (res: IOResponse<boolean>) => {
        if (res.code === 0) {
          resolve(res.payload!);
          return;
        }
        notifyIOError(new CustomError(res.message || '', res.code), reject);
      },
    );
  });
}

// 更改项目尺寸
export async function changeProjectSize(appID: string, device: AppPatch['device']): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    if (!socket.connected) {
      // todo
      reject(new Error(i18n('alert.unableToEditNode')));
      return;
    }
    heartbeat();
    socket.emit(
      IOEvent.AppPatch,
      {
        appID,
        sessionID: currentSessionID,
        device,
      },
      (res: IOResponse<boolean>) => {
        if (res.code === 0) {
          resolve(res.payload!);
          return;
        }
        notifyIOError(new CustomError(res.message || '', res.code), reject);
      },
    );
  });
}

window.addEventListener('offline', function () {
  appDataManager.socketOffline = true;
  if (manualDisconnected) {
    return;
  }
  manualConnect();
  notifyNetworkStatusChange(false);
  notifySocketStatusChange(false);
});

window.addEventListener('online', function () {
  if (manualDisconnected) {
    return;
  }
  notifyNetworkStatusChange(true);
  delayManualConnect();
});
