import PropertyBase from './base';
import { IBounds } from '../../../common/models/common';

export default interface IImg extends PropertyBase {
  fitMode?: fitModeType;
  clipBounds?: IBounds;
  showModel: ShowModel;
}

export type fitModeType = 'fit' | 'original' | 'stretch';

export type ShowModel = 'empty' | 'placeholder';

export const ImgPropertyName = 'image';
