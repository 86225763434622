/**
 * 交互收集器
 *
 * collect()  收集数据
 * clear()  清空数据
 */

class CollectActions {
  protected collecting: boolean = false;
  protected interactions = new Set<[any, any]>();

  collect<T, S>(trigger: T, type: S): Promise<Set<[T, S]>> | undefined {
    this.interactions.add([trigger, type]);
    if (this.collecting) {
      return;
    }
    this.collecting = true;
    return new Promise((resolve) => {
      setTimeout(() => {
        this.collecting = false;
        resolve(this.interactions);
      }, 20);
    });
  }

  clear() {
    this.interactions.clear();
  }
}

export default new CollectActions();
