import { measureTextSize } from '@utils/textUtils';

import { IComponentData } from '@fbs/rp/models/component';
import { ISize } from '@fbs/common/models/common';
import { ArtboardPatches } from '@fbs/rp/utils/patch';

import i18n from '@i18n';
import { isMobileAppType } from '@/helpers/previewHelper';
import { UIContainerComponent } from '@editor/comps';

import { PredefinedStates } from '@consts/state';
import ValueEditorType from '@consts/enums/valueEditorType';
import { SpriteThumb } from '@/consts/spriteIcons';
import { getDefaultDataFromTemplate } from '@/libs/helper';

import { IComponentItem } from '../../types';
import { SizeMode, MoveMode } from '../../enum';

const defaultButtonText = i18n('general.ok');

const { width, height } = measureTextSize(
  {
    fontSize: 14,
    fontFamily: 'Microsoft YaHei',
  },
  defaultButtonText,
);

const template = () => {
  return `
    {
      type: @@C.CanvasPanel,
      size: { width: 120, height: 48 },
      layout:{
        vertical: @@VerticalAlign.Middle,
        horizontal: @@HorizontalAlign.Center,
        auto: true,
        responsive: true,
        fixedWidth: false,
        fixedHeight: false,
      },
      disabled: false,
      properties: {
        textStyle: {
          textAlign: @@TextAlign.center,
          color: @@SystemColors.DefaultTextColor,
          fontSize: 14,
          fontFamily: 'Microsoft YaHei',
          fontStyle: { underline: false, bold: false, strike: false, italic: false },
          disabled: false,
        },
        fill: {
          type: @@FillType.solid,
          color: @@SystemColors.DefaultWhiteFillColor,
          disabled: false,
        },
        stroke: {
          thickness: 1,
          color: @@SystemColors.DefaultStrokeColor1,
          disabled: false,
        },
        border: @@defaultBorder,
        radius: {
          topLeft: 4,
          topRight: 4,
          bottomRight: 4,
          bottomLeft: 4,
          isPercent: false,
          disabled: false,
        },
        shadow: {
          disabled: true,
          x: 0,y:3,blur: 6,color: @@SystemColors.ShadowColor,
        },
      },
      states: {
        @@PredefinedStates.hover: {
          enabled: true,
          properties: {
            stroke: {
              color: @@SystemColors.HoverStrokeColor,
            },
          },
        },
        @@PredefinedStates.pressed: {
          enabled: true,
          properties: {
            stroke: {
              color: @@SystemColors.PressedStrokeColor,
            },
            textStyle: {
              color: @@SystemColors.PressedStrokeColor,
            },
          },
        },
        @@PredefinedStates.disabled: {
          enabled: true,
          properties: {
            fill: {
              type: @@FillType.solid,
              color: @@SystemColors.DisabledWhiteFillColor,
            },
            stroke:{
              color: @@SystemColors.DisabledStrokeColor,
            },
            textStyle: {
              color: @@SystemColors.DisabledTextColor,
              textAlign: @@TextAlign.center,
            },
          },
        },
      },
      value: '${defaultButtonText}',
      components: [
        {
          type: @@C.PureText,
          alias: 'text',
          autoSize: true,
          size:{width: ${width},height: ${height}},
          position:{x: ${(120 - width) / 2}, y: ${(48 - height) / 2}},
          layout:{
            vertical: @@VerticalAlign.Middle,
            horizontal: @@HorizontalAlign.Center,
            auto: false,
            responsive: true,
            fixedWidth: true,
            fixedHeight: true,
          },
          properties: {
            textStyle: { ref: '@properties.textStyle' },
          },
          value: '@value',
          interactions: {},
        },
      ],
      sealed: true,
    }
  `;
};

/**
 * 初始化按钮数据
 * @param {string} appType
 * @param {IComponentData} data
 * @param {UIContainerComponent} container
 * @param {ISize} defaultSize
 */
function initButtonData(appType: string, data: IComponentData, container?: UIContainerComponent, defaultSize?: ISize) {
  const size =
    defaultSize ||
    (isMobileAppType(appType)
      ? { width: 120, height: 48 }
      : {
          width: 70,
          height: 30,
        });
  const { width, height } = size;
  const [text] = data.components!;
  data.size = { ...data.size, width, height };
  const { width: tw, height: th } = text.size;
  text.position = {
    x: Math.round((width - tw) / 2),
    y: Math.round((height - th) / 2),
  };
}

export const ButtonCfg: IComponentItem = {
  // 隐藏，被rectbutton代替
  hidden: true,
  type: 'button',
  lib: 'common',
  isTextComp: true,
  name: i18n('resource.components.button'),
  shortCut: 'B',
  thumb: {
    spriteIconClass: SpriteThumb.Button.className,
    dragPosition: SpriteThumb.Button.position,
  },
  predefinedStates: [
    PredefinedStates.hover,
    PredefinedStates.pressed,
    PredefinedStates.checked,
    PredefinedStates.focus,
    PredefinedStates.disabled,
  ],
  value: {
    type: ValueEditorType.PureText,
  },
  constraint: {
    text: {
      resize: SizeMode.none,
      move: MoveMode.neither,
      responsiveLayout: {
        hidden: true,
      },
    },
  },
  preview: {},
  editor: {
    onResize: (): ArtboardPatches | null => {
      return null;
    },
  },
  initialization: initButtonData,
  template: template(),
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
};

const defaultData = getDefaultDataFromTemplate(ButtonCfg);
