import { isUndefined } from 'util';
import { get, cloneDeep, isObject, uniq } from 'lodash';

import { isEqualDate, isBaseData, round } from '@utils/globalUtils';

import { IPosition } from '@fbs/idoc/models/common';
import { IProperties, PropertyValue } from '@fbs/rp/models/property';
import { FillPropertyName } from '@fbs/rp/models/properties/fill';
import { ShadowPropertyName } from '@fbs/rp/models/properties/shadow';
import IRadius, { RadiusPropertyName } from '@fbs/rp/models/properties/radius';
import { StrokePropertyName } from '@fbs/rp/models/properties/stroke';
import ITextFormat, { TextPropertyName, FontStyle } from '@fbs/rp/models/properties/text';
import { IComponentData } from '@fbs/rp/models/component';
import { IPathValue } from '@fbs/rp/models/value';
import { ILibF } from '@fbs/rp/models/ds/lib';
import { TextFormatExPropertyName } from '@fbs/rp/models/properties/textFormat';
import { NEED_DEEP_COMPARE_PROPS } from '@/consts/component';
import { UIComponent, UIFragment } from '@editor/comps';
import { getMinSizeOfComp } from '@editor/comps/resizeHelper';

import { CRect, CEllipse, CPolygon, CPath, CCompoundPath } from '@libs/constants';

import { shapeProps, textProps } from './propertiesHelper';
import { isClosedPathWithArea } from './pathHelper';
import { getComponentByID } from './resourceHelper';

interface IPropName {
  name: string;
  type: string;
  locked?: boolean | undefined;
}

// 无需往下对比的属性
const SHOULD_NOT_DEEP_COMPARE_KEYS = ['color', 'backgroundColor', 'progressColor', 'tickFontColor', 'pointerColor'];

export const getArtboardsIntegratedPosition = (artboards: UIFragment[]): IPosition => {
  const boundsList = artboards.map((artboard) => {
    return artboard.getViewBoundsInPage();
  });
  const minTop = boundsList.reduce((result, cur) => Math.min(result, cur.top), Number.POSITIVE_INFINITY);
  const minLeft = boundsList.reduce((result, cur) => Math.min(result, cur.left), Number.POSITIVE_INFINITY);
  // 精度0.5
  return { x: round(minLeft * 2) / 2, y: round(minTop * 2) / 2 };
};

export const getIntegratedPosition = (comps: UIComponent[]): IPosition => {
  const boundsList = comps.map((comp) => {
    return comp.getViewBoundsInParent();
  });
  const minTop = boundsList.reduce((result, cur) => Math.min(result, cur.top), Number.POSITIVE_INFINITY);
  const minLeft = boundsList.reduce((result, cur) => Math.min(result, cur.left), Number.POSITIVE_INFINITY);
  // 精度0.5
  return { x: round(minLeft * 2) / 2, y: round(minTop * 2) / 2 };
};

export const getCompDataIntegratedPosition = (comps: IComponentData[]): IPosition => {
  const boundsList = comps.map((comp) => {
    return comp.position;
  });
  const minY = boundsList.reduce((result, cur) => Math.min(result, cur.y), Number.POSITIVE_INFINITY);
  const minX = boundsList.reduce((result, cur) => Math.min(result, cur.x), Number.POSITIVE_INFINITY);
  return { x: minX, y: minY };
};

export const getIntegratedMinSize = (comps: UIComponent[]) => {
  const compWidth: number[] = [];
  const compHeight: number[] = [];
  comps.forEach((comp) => {
    const { width, height } = getMinSizeOfComp(comp);
    compWidth.push(width);
    compHeight.push(height);
  });
  return {
    width: Math.min(...compWidth),
    height: Math.min(...compHeight),
  };
};

export const getIntegratedCompSize = (
  comps: UIComponent[],
): {
  height: number | undefined;
  width: number | undefined;
  lockedRatio: boolean;
} => {
  let variableSizeComp = comps.filter((comp) => {
    return !comp.autoSize;
  });

  if (variableSizeComp.length === 0) {
    variableSizeComp = comps;
  }

  const firstVariableSize = variableSizeComp[0]?.size;
  const widthArr = [];
  const heightArr = [];
  const lockArr = [];
  variableSizeComp.forEach((comp) => {
    comp.size.width === firstVariableSize?.width && widthArr.push(comp);
    comp.size.height === firstVariableSize?.height && heightArr.push(comp);
    comp.size.lockedRatio === true && lockArr.push(comp);
  });

  const isSameWidth = widthArr.length === variableSizeComp.length;
  const isSameHeight = heightArr.length === variableSizeComp.length;
  const isSameLock = lockArr.length === variableSizeComp.length;

  return {
    width: isSameWidth ? firstVariableSize?.width : undefined,
    height: isSameHeight ? firstVariableSize?.height : undefined,
    lockedRatio: isSameLock,
  };
};

export const getIntegratedRotate = (comps: UIComponent[]): number | undefined => {
  const variableRotateComp = comps.filter((comp) => {
    return comp.canRotate;
  });

  const isSameRotate =
    variableRotateComp.filter((comp) => {
      return comp.rotate === variableRotateComp[0]?.rotate;
    }).length === variableRotateComp.length;
  const rotate = variableRotateComp.length ? variableRotateComp[0]?.rotate : comps[0]?.rotate;
  return isSameRotate ? rotate : undefined;
};

export const getIntegratedProperties = (
  comps: UIComponent[],
  propertyNames: Array<keyof IProperties>,
): IProperties | undefined => {
  if (propertyNames.length === 0) {
    return undefined;
  }
  if (comps.length === 1) {
    const result = comps[0].properties;
    Object.keys(result).forEach((key) => {
      if (!result[key]) {
        result[key] = {};
      } else if (key === RadiusPropertyName) {
        result[key] = getRealPropertyValue(result, key, comps[0]);
      }
    });
    return result;
  }
  const first = comps[0];
  const properties: IProperties = {};
  const props = first.properties;
  const propertiesMap = new Map<string, IProperties>();
  propertyNames.forEach((key) => {
    let prop = props[key];
    if (!isUndefined(prop) && !prop) {
      prop = {};
    }
    if (prop) {
      const propertyType = prop.prop || key;
      if (!propertiesMap.size) {
        comps.forEach((comp) => {
          propertiesMap.set(comp.id, comp.properties);
        });
      }
      properties[key] = getIntegratedProperty(comps, key, propertyType, propertiesMap);
    }
  });
  return properties;
};

/**
 * 深层比较属性不同，设置undefined
 * TODO: 比较到color属性时不继续往下比较，其他类似属性自行添加判断
 * @param originData 原属性数据
 * @param diffData 对比属性数据
 * @param resData 结果属性数据
 * @param compareKey 比较key值
 */
const setDiffByDeepCompare = (
  originData: IProperties,
  diffData: IProperties,
  resData: IProperties,
  compareKey: string,
) => {
  if (
    originData[compareKey] &&
    typeof originData[compareKey] === 'object' &&
    !SHOULD_NOT_DEEP_COMPARE_KEYS.includes(compareKey)
  ) {
    const _keys = uniq(
      Object.keys(originData[compareKey] as object).concat(Object.keys(diffData[compareKey] as object)),
    );

    _keys.forEach((key) => {
      if (!isEqualDate(get(originData, `${compareKey}.${key}`), get(diffData, `${compareKey}.${key}`))) {
        if (!resData[compareKey]) {
          resData[compareKey] = {};
        }
        setDiffByDeepCompare(
          originData[compareKey] as IProperties,
          diffData[compareKey] as IProperties,
          resData[compareKey] as IProperties,
          key,
        );
      }
    });
  } else {
    resData[compareKey] = undefined;
  }
};

const getRealPropertyValue = (properties: IProperties, key: keyof IProperties, comp: UIComponent) => {
  const prop = properties[key];
  if (prop?.name ?? key === RadiusPropertyName) {
    const radius = prop as IRadius;
    if (radius.isPercent) {
      const {
        size: { width, height },
      } = comp;
      const maxValue = Math.min(width, height) / 2;
      return {
        ...radius,
        isPercent: false,
        topLeft: Math.round(((radius.topLeft ?? 0) * maxValue) / 100),
        topRight: Math.round(((radius.topRight ?? 0) * maxValue) / 100),
        bottomLeft: Math.round(((radius.bottomLeft ?? 0) * maxValue) / 100),
        bottomRight: Math.round(((radius.bottomRight ?? 0) * maxValue) / 100),
      };
    }
  }
  return prop;
};

const getIntegratedProperty = (
  comps: UIComponent[],
  key: keyof IProperties,
  propertyType: keyof IProperties,
  propertiesMap: Map<string, IProperties>,
): PropertyValue | undefined => {
  let property: PropertyValue | undefined = undefined;

  const enabledProps = [FillPropertyName, ShadowPropertyName, RadiusPropertyName, StrokePropertyName];

  let enabledComps = comps;
  if (enabledProps.includes(`${propertyType}`)) {
    enabledComps = comps.filter((comp) => {
      return propertiesMap.get(comp.id)![key]?.disabled !== true;
    });
  }

  const enabledCompsCount = enabledComps.length;

  if (!enabledComps.length) {
    enabledComps = comps;
  }

  const firstCompProperty = getRealPropertyValue(enabledComps[0].properties, key, enabledComps[0]); // enabledComps[0].properties[key];

  if (isUndefined(firstCompProperty)) {
    return undefined;
  }
  if (Object.prototype.toString.call(firstCompProperty) === '[object Object]') {
    property = { ...firstCompProperty };

    const keys = Object.keys(firstCompProperty);

    keys.forEach((_key) => {
      const item = _key as keyof PropertyValue;
      const baseProperty = firstCompProperty[item];
      if (property) {
        Reflect.set(property, item, isObject(baseProperty) ? cloneDeep(baseProperty) : baseProperty);
      }
      enabledComps.forEach((comp) => {
        // const prop = propertiesMap.get(comp.id)![key];
        const prop = getRealPropertyValue(propertiesMap.get(comp.id)!, key, comp);
        if (prop && !isEqualDate(prop[item], firstCompProperty[item])) {
          // 对需要深度对比的属性深层次对比
          if (NEED_DEEP_COMPARE_PROPS.includes(propertyType as string)) {
            setDiffByDeepCompare(prop as IProperties, firstCompProperty as IProperties, property as IProperties, item);
          } else {
            // @ts-ignore
            property[item] = undefined;
          }
        }
      });
    });
  } else if (isBaseData(firstCompProperty)) {
    const isTheSame =
      enabledComps.filter((item) => {
        return item.properties[key] === firstCompProperty;
      }).length === enabledComps.length;
    if (isTheSame) {
      property = enabledComps[0].properties[key];
    } else {
      property = undefined;
    }
  }

  if (enabledProps.includes(`${propertyType}`)) {
    if (enabledCompsCount === comps.length) {
      property!.disabled = false;
    } else if (enabledCompsCount === 0) {
      property!.disabled = true;
    } else {
      property!.disabled = undefined;
    }
  }

  if (propertyType === TextPropertyName) {
    let newFontStyle = { ...(firstCompProperty as ITextFormat).fontStyle } as FontStyle | undefined;
    const fontStyleKeys: Array<keyof FontStyle> = ['bold', 'italic', 'underline', 'strike'];
    enabledComps.forEach((comp) => {
      const fontStyle = (propertiesMap.get(comp.id)![key] as ITextFormat).fontStyle;
      fontStyleKeys.forEach((i) => {
        if (!fontStyle || !newFontStyle) {
          newFontStyle = undefined;
        } else if (newFontStyle[i] !== fontStyle[i]) {
          newFontStyle[i] = undefined;
        }
      });
    });
    property = { ...(property as ITextFormat), fontStyle: newFontStyle };
  }

  return property;
};

export const getIntegratedOpacity = (comps: UIComponent[]): number | undefined => {
  const isSameOpacity =
    comps.filter((comp) => {
      return comp.opacity === (comps[0] && comps[0].opacity);
    }).length === comps.length;

  return isSameOpacity ? comps[0] && comps[0].opacity : undefined;
};

export const hasTypeInComps = (comps: UIComponent[], type: string): boolean => {
  return comps.some((comp) => {
    return comp.type === type;
  });
};

export const getIntegratedPropertiesGroups = (supportPropNames: IPropName[]) => {
  const shapeGroup: IPropName[] = [];
  const textGroup: IPropName[] = [];
  const extendedGroup: IPropName[] = [];

  const tempTextGroup: IPropName[] = [];

  supportPropNames.forEach((item) => {
    if (item.type === TextFormatExPropertyName || item.type === TextPropertyName) {
      tempTextGroup.push(item);
    }
    if (shapeProps.includes(item.name)) {
      shapeGroup.push(item);
    } else if (textProps.includes(item.name)) {
      textGroup.push(item);
    } else {
      extendedGroup.push(item);
    }
  });
  // FIXME Matt 2021-1-21 重新整理文本属性，如果文本分类中不包含文本属性时，把扩展中的最接近默认文本属性的移到文本分类中
  if (
    !textGroup.find((item) => !item.name || item.name === TextFormatExPropertyName || item.name === TextPropertyName)
  ) {
    // titleTextStyle/contentTextStyle属于折叠面板的文本属性，不跟随默认情况
    const textFormats = tempTextGroup.filter(
      (item) =>
        (item.type === TextFormatExPropertyName || item.type === TextPropertyName) &&
        !['titleTextStyle', 'contentTextStyle'].includes(item.name),
    );
    if (textFormats.length) {
      let defaultTextStyle = textFormats.find((item) => item.name === item.type);
      if (!defaultTextStyle) {
        textFormats.sort((a, b) => {
          if (a.name < b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
        defaultTextStyle = textFormats[0];
      }
      const index = extendedGroup.indexOf(defaultTextStyle);
      if (index !== -1) {
        extendedGroup.splice(index, 1);
      }
      textGroup.push(defaultTextStyle);
    }
  }

  return { shapeGroup, textGroup, extendedGroup };
};

/**
 * 是否可以修改描边位置
 * 存在非闭合的描边皆不能设置位置
 * FIXME: 持续补充
 */
export const couldStrokePositionEdite = (comps: UIComponent[]) => {
  if (comps.find((comp) => ![CRect, CEllipse, CPolygon, CPath, CCompoundPath].includes(comp.type))) {
    return false;
  }
  const paths = comps.filter((c) => c.type === CPath);
  if (paths.find((p) => !isClosedPathWithArea(p.value as IPathValue))) {
    return false;
  }
  // const rects = comps.filter((c) => c.type === CRect);
  // if (rects.find((r) => isUnclosedBorder(r.properties.border))) {
  //   return false;
  // }
  const compoundPaths = comps.filter((c) => c.type === CCompoundPath);
  return !compoundPaths.find((p) => (p.value as IPathValue[]).find((v) => !isClosedPathWithArea(v)));
};

export function getSymbolInfoFormComps(comps: UIComponent[]): { libID: string; masterID: string } | undefined {
  const symbolInfos = comps.reduce((prev, comp) => {
    const { isSymbol, symbolInfo } = comp;
    const noRepeat = prev.every((ite) => ite.masterID !== symbolInfo?.masterID);
    isSymbol && symbolInfo && noRepeat && prev.push(symbolInfo);
    return prev;
  }, [] as { libID: string; masterID: string }[]);
  // 目前只处理单个symbol
  if (symbolInfos.length === 1) {
    return symbolInfos[0];
  }
  return undefined;
}

export function getSymbolDataFormComps(comps: UIComponent[], libs: ILibF[]) {
  const symbolInfo = getSymbolInfoFormComps(comps);
  if (!symbolInfo) {
    return;
  }
  const { masterID, libID } = symbolInfo;
  return getComponentByID(masterID, { libID, libraries: libs });
}

/**
 * 处理输入框反选文本时，鼠标有transform:translate变换的图层时，会取消选中
 * 这是一个浏览器引擎机制问题(有些浏览器可以，有些不可以)
 */
export const doInputInvertSelection = (e: React.MouseEvent) => {
  const target = e.target as Node;
  const nodeName = target.nodeName.toLowerCase();
  if (nodeName !== 'input' && nodeName !== 'textarea') {
    return;
  }
  const startX = e.pageX;
  const startY = e.pageY;
  let canDrag = false;
  let maskDom: HTMLDivElement | null = null;
  let unmount = false;
  const handleMove = (e: MouseEvent) => {
    const x = e.pageX - startX;
    const y = e.pageY - startY;
    if (!canDrag && Math.abs(x) + Math.abs(y) > 5) {
      canDrag = true;
      const rect = (target as HTMLElement).getBoundingClientRect();
      maskDom = document.createElement('div');
      maskDom.style.position = 'absolute';
      maskDom.style.right = window.innerWidth - rect.left + 'px';
      maskDom.style.top = '0';
      maskDom.style.width = '100%';
      maskDom.style.height = '100%';
      maskDom.style.zIndex = '100000';
      document.body.appendChild(maskDom);
    }
  };
  const handleUp = () => {
    if (unmount) {
      return;
    }
    unmount = true;
    canDrag = false;
    maskDom && maskDom.parentNode?.removeChild(maskDom);
    maskDom = null;
    document.removeEventListener('mousemove', handleMove, false);
    document.removeEventListener('mouseup', handleUp, false);
    document.removeEventListener('dragend', handleUp, false);
  };
  document.addEventListener('mousemove', handleMove, false);
  document.addEventListener('mouseup', handleUp, false);
  document.addEventListener('dragend', handleUp, false);
};
