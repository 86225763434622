import { IconValue } from '@/fbs/rp/models/value';
import { defaultExpandIcon } from '@consts/defaultData/tree';
import { TreeItemColumn, TreeItemAlias } from '@helpers/treeCompHelper';
import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { CIcon, CImage } from '@libs/constants';
import { makeIcon } from '@libs/basic/Icon';
import { makeImage } from '@/libs/basic/Image/config';
import { getNewID } from '@helpers/idHelper';
import { PureColor } from '@fbs/rp/models/properties/color';
import { ISize } from '@utils/boundsUtils';
import { measureTextSize } from '@utils/textUtils';

import { UIPanelComponent, UIComponent, UIContainerComponent } from '.';

export type TreeNodeIcon = { type: 'icon' | 'image'; icon: IconValue | string };

export class UITreeItemComponent extends UIPanelComponent {
  private getIcon(comp: UIComponent | undefined): TreeNodeIcon {
    if (!comp) return { type: 'image', icon: '' };
    if (comp.type === 'image') {
      return {
        type: 'image',
        icon: comp?.value as string,
      };
    } else {
      return {
        type: 'icon',
        icon: comp?.value as IconValue,
      };
    }
  }

  get isCheckedBox(): boolean {
    return this.checkBoxComp.selected;
  }

  get expandComp(): UIComponent {
    return this.getComponentByAlias(TreeItemAlias.ExpandIcon)!;
  }

  get checkBoxComp(): UIComponent {
    return this.getComponentByAlias(TreeItemAlias.CheckBox)!;
  }

  get nodeComp(): UIComponent {
    return this.getComponentByAlias(TreeItemAlias.NodeIcon)!;
  }

  get textComp(): UIComponent {
    return this.getComponentByAlias(TreeItemAlias.NodeText)!;
  }

  getExpandIcon(): TreeNodeIcon {
    const expandComp = this.components.find((cm) => cm.column === TreeItemColumn.Expand);
    return this.getIcon(expandComp);
  }

  getNodeIcon(): TreeNodeIcon {
    const nodeComp = this.components.find((cm) => cm.column === TreeItemColumn.Node);
    return this.getIcon(nodeComp);
  }

  getText(): string {
    const textComp = this.components.find((cm) => cm.column === TreeItemColumn.Text);
    return textComp ? (textComp.value as string) : '';
  }

  modifyCompValuePathes(column: TreeItemColumn, expand: boolean, newValue: IconValue | string): ArtboardPatches {
    let patches: ArtboardPatches = { do: {}, undo: {} };
    const modifyComp = this.components.find((cm) => cm.column === column);
    if (modifyComp) {
      //修改icon
      if (
        (modifyComp?.type === CIcon && typeof newValue !== 'string') ||
        (modifyComp?.type === CImage && typeof newValue === 'string')
      ) {
        patches.do[modifyComp.id] = [Ops.replace('/value', newValue)];
        patches.undo[modifyComp.id] = [Ops.replace('/value', modifyComp.value)];
      } else {
        patches.do[this.id] = [Ops.removeChildren([modifyComp?.id || ''])];
        patches.undo[this.id] = [Ops.addChildren(`${modifyComp.column}`, [modifyComp.$data])];
        const newId = getNewID();
        //icon 转 image
        if (typeof newValue === 'string') {
          patches.do[this.id] = [Ops.addChildren(`${modifyComp.column}`, [makeImage(newId)])];
          patches.undo[this.id] = [Ops.removeChildren([newId])];
        } else {
          // image 转 icon
          const newIconCode = expand ? defaultExpandIcon.expand : defaultExpandIcon.collapse;
          patches.do[this.id] = [Ops.addChildren(`${modifyComp.column}`, [makeIcon(newId, { value: newIconCode })])];
          patches.undo[this.id] = [Ops.removeChildren([newId])];
        }
      }
    }
    return patches;
  }

  modifyCompColorPatches(column: TreeItemColumn, newValue: PureColor) {
    let patches: ArtboardPatches = { do: {}, undo: {} };
    let modifyComp = this.components.find((cm) => cm.column === column);
    if (column === TreeItemColumn.CheckBox) {
      modifyComp = (modifyComp as UIContainerComponent).getComponentByAlias(TreeItemAlias.CheckBoxIcon)!;
    }
    if (modifyComp && modifyComp.type === CIcon) {
      patches.do[modifyComp.id] = [Ops.replace('/properties/icon', { color: newValue })];
      patches.undo[modifyComp.id] = [Ops.replace('/properties/icon', modifyComp.properties.icon)];
    }
    return patches;
  }

  modifyCompSizePatches(column: TreeItemColumn, newValue: ISize) {
    let patches: ArtboardPatches = { do: {}, undo: {} };
    const modifyComp = this.components.find((cm) => cm.column === column);
    if (modifyComp) {
      patches.do[modifyComp.id] = [Ops.replace('/size', newValue)];
      patches.undo[modifyComp.id] = [Ops.replace('/size', modifyComp.size)];
      if (column === TreeItemColumn.CheckBox) {
        const checkIcon = (modifyComp as UIContainerComponent).getComponentByAlias(TreeItemAlias.CheckBoxIcon)!;
        patches.do[checkIcon.id] = [Ops.replace('/size', newValue)];
        patches.undo[checkIcon.id] = [Ops.replace('/size', checkIcon.size)];
      }
    }
    return patches;
  }

  modifyTextPatches(newText: string) {
    let patches: ArtboardPatches = { do: {}, undo: {} };
    const textComp = this.textComp;
    const { width, height } = measureTextSize(textComp.properties.textStyle!, newText);
    if (textComp && newText !== textComp.value) {
      patches.do[textComp.id] = [Ops.replace('/value', newText), Ops.replace('/size', { width, height })];
      patches.undo[textComp.id] = [Ops.replace('/value', textComp.value), Ops.replace('/size', textComp.size)];
    }
    return patches;
  }

  modifyTextCompVersion() {
    let patches: ArtboardPatches = { do: {}, undo: {} };
    const textComp = this.textComp;
    const originVersion = textComp.$data.v;
    const newVersion = originVersion + 1;
    patches.do[textComp.id] = [Ops.replace('/v', newVersion)];
    patches.undo[textComp.id] = [Ops.replace('/v', originVersion)];
    return patches;
  }
}
