import * as React from 'react';
import classnames from 'classnames';

import Icon from '../../../Icon';
import Checkbox from '../../../Checkbox';
import CheckItem from '../CheckItem';

import { IGroupProps, IItemProps } from '../../../../models';
import { CheckType } from '../../../../constants/TreeTransfer';
import i18n from '../../../../i18n';

import './index.scss';

interface ICheckGroupProps {
  data: IGroupProps;
  showAddGroup: boolean;
  addGroupTooltip?: string;
  checkedItems: string[];
  checkedGroups: string[];
  hideGroupCheck: boolean;
  maxCount: number;

  onCheckItem(ids: string[]): void;
  onCheckGroup?(ids: string[]): void;
  onCheckDisable?(id: string): void;
}

const CheckGroup: React.FC<ICheckGroupProps> = (props: ICheckGroupProps) => {
  const {
    data,
    showAddGroup,
    addGroupTooltip,
    checkedItems,
    checkedGroups,
    hideGroupCheck,
    maxCount,
    onCheckItem,
    onCheckGroup,
    onCheckDisable,
  } = props;
  const { name, children, id } = data;

  const [expand, setExpand] = React.useState(data.expand);
  const [showTips, setShowTips] = React.useState(false);

  const getGroupCheckType = (): CheckType => {
    let indeterminate = children.some((item) => checkedItems.includes(item.id));
    let all = children.every((item) => checkedItems.includes(item.id)) && indeterminate;

    if (all) {
      return CheckType.All;
    }
    if (indeterminate) {
      return CheckType.Indeterminate;
    }

    return CheckType.None;
  };

  const handleCheckGroup = (currentCheckType: CheckType) => {
    const childrenIds: string[] = children.map((item) => item.id);
    const checkedExcludeChildren = checkedItems.filter((item) => !childrenIds.includes(item));

    switch (currentCheckType) {
      case CheckType.All:
        const childrenDisabledArr = children.filter((item) => item.disabled);
        const disableIDs = childrenDisabledArr.map((item) => item.id);
        if (!!disableIDs.length) {
          const checkedDisabled = disableIDs.filter((item) => checkedItems.includes(item));
          onCheckItem([...new Set([...checkedDisabled, ...checkedExcludeChildren])]);
          return;
        }
        onCheckItem(checkedExcludeChildren);
        break;
      case CheckType.Indeterminate:
      case CheckType.None: {
        onCheckItem([...checkedExcludeChildren, ...childrenIds]);
        break;
      }
      default:
        break;
    }
  };

  const renderGroup = () => {
    const checkType: CheckType = getGroupCheckType();
    return (
      <div className="check-group-box">
        <span className="expand-container">
          <Icon cls={expand ? 'tag_downarrow' : 'tag_rightarrow'} onClick={(e) => setExpand(!expand)} />
        </span>
        {!hideGroupCheck && (
          <Checkbox
            checked={checkType !== CheckType.None}
            indeterminate={checkType === CheckType.Indeterminate}
            disabled={children.length === 0}
            onChange={() => handleCheckGroup(checkType)}
          />
        )}
        <span
          className={classnames('group-title-wrapper', {
            'can-add-group': showAddGroup,
          })}
        >
          {<span className="group-name">{name}</span>}
          {children && <span className="group-child-count">({children.length})</span>}
        </span>
        {showAddGroup && id !== 'single-group' && (
          <span className="check-group-add">
            <Icon
              cls={'layer_plus'}
              size={14}
              clickArea={{ width: 16, height: 16 }}
              tooltip={addGroupTooltip || ''}
              tooltipAlign="right"
              onClick={() => !checkedGroups.includes(id) && onCheckGroup && onCheckGroup([...checkedGroups, id])}
            />
          </span>
        )}
      </div>
    );
  };

  const getItemChecked = (data: IItemProps): boolean => {
    if (data.isGroup) {
      return checkedGroups.includes(data.id);
    }
    return checkedItems.includes(data.id);
  };

  const renderChildren = () => {
    return (
      <div className="check-items">
        {children.map((item: IItemProps) => (
          <CheckItem
            key={item.id}
            data={item}
            checked={getItemChecked(item)}
            onCheck={(id: string) => {
              if (checkedItems.includes(id)) {
                onCheckItem(checkedItems.filter((item) => item != id));
              } else {
                if (hideGroupCheck && checkedItems.length + checkedGroups.length >= maxCount) {
                  setShowTips(true);
                  setTimeout(() => {
                    setShowTips(false);
                  }, 2000);
                  return;
                }
                onCheckItem([...checkedItems, id]);
              }
            }}
            onCheckDisable={onCheckDisable}
          />
        ))}
      </div>
    );
  };

  const renderReachMaxTips = () => {
    return (
      <div className={'reach-max-tip'}>
        <Icon cls="tag_exclamation" color="#F8AF1D" size={16} />
        <span>{i18n('checkSelect.maximumOfPeople')}</span>
      </div>
    );
  };

  return (
    <div className="check-group">
      {renderGroup()}
      {expand && !!children.length && renderChildren()}
      {showTips && renderReachMaxTips()}
    </div>
  );
};

export default CheckGroup;
