import { saveAs } from 'file-saver';
import { UIArtboard, UIFragment, UIComponent } from '@/editor/comps';
import { svgToDataUri } from '../util';
import { parseExportData } from '../parser';
import { makeSvgByGroup, svgToCanvasBlob, svgToCanvasDataURL } from '../svg';
import { IExportData, ISaveFileOptions, ISize, IExportSvgOptions } from '../type';
import { getScales, makeFilename } from '../util/filenameUtil';
import { selectComponentFilter } from '../util/filterUtil';
import { httpCach } from '../util/httpCachUtil';
import { parseOriginImage } from '../parser/parseOriginImage';
import { ExportFileFormat } from '@/fbs/rp/models/component';
import { isDialogClosed } from '../util/dialogUtil';
import { TriggerImageLoad } from '../util/triggerImageLoad';
import { PackImages } from './packImages';
import { globalStatistics } from '../util/statisticsUtil';
import { createPdfByImage } from '../pdf';

/**
 * 导出svg图片
 * @param json
 * @param data
 * @param outputType
 * @param exportType
 */
export const exportSvgDataUri = async (options: IExportSvgOptions): Promise<string> => {
  const { components, doc, exportFormats, scale } = options;
  if (!exportFormats) {
    return '';
  }
  // 显示 隐藏区域
  const renderBlankFilter = () => {
    return false;
  };
  const data: IExportData[] = await parseExportData({ components, doc, renderBlankFilter });
  const size: ISize = data[0].size;
  const svgGroup = await makeSvgByGroup({ data, size, scales: [scale] });
  if (svgGroup) {
    const svg = svgGroup[0].svg;
    return svgToDataUri(svg);
  }
  return '';
};

/**
 * 下载原图
 * @param components
 */
export const downloadOriginImage = async (options: ISaveFileOptions): Promise<void> => {
  const { components, doc, app, onProgress } = options;
  if (components && components.length === 1) {
    const { blob, name } = (await parseOriginImage(components[0])) ?? {};
    if (blob) {
      onProgress && onProgress(100);
      saveAs(blob, name);
    }
  } else if (components.length > 1) {
    const exportFormats = [{ fileFormat: ExportFileFormat.Png }];
    const packImages = new PackImages({ components, doc, app, exportFormats });
    const processor = components.map(async (t) => {
      const { blob, name } = (await parseOriginImage(t)) ?? {};
      if (blob && name) {
        packImages.addBlob(name, blob);
      }
    });
    await Promise.all(processor);
    onProgress && onProgress(100);
    // 保持文件
    packImages.saveAs();
  }

  // return components.length;
  // return
};

/**
 * 保存单个文件
 */
export const saveSingleFile = async (options: ISaveFileOptions): Promise<number> => {
  const { components, doc, app, exportFormats, renderBlankFilter, mobileType } = options;
  if (!exportFormats) {
    return 0;
  }
  // 开始统计
  globalStatistics.begin();
  const { namingScheme, fileFormat } = exportFormats[0];
  const data: IExportData[] = await parseExportData({ components, doc, app, renderBlankFilter, mobileType });
  const size: ISize = data[0].size;

  const scales = getScales(exportFormats);
  const svgGroup = await makeSvgByGroup({ data, size, scales });
  // 清空缓存
  httpCach.clear();
  if (svgGroup) {
    const scale = scales[0];
    const json = components.map((t) => t.toJSON());
    const name = makeFilename({ t: json[0], scale, namingScheme });
    const svg = svgGroup[0].svg;
    try {
      switch (fileFormat) {
        case ExportFileFormat.SVG:
          saveAs(svgToDataUri(svg), `${name}.${fileFormat}`);
          break;
        case ExportFileFormat.Pdf:
          {
            const dataUrl = await svgToCanvasDataURL(svg, name, scale);
            const pdfname = `${name}.${fileFormat}`;
            if (dataUrl) {
              createPdfByImage({
                images: [
                  {
                    value: dataUrl,
                    size: size,
                    type: ExportFileFormat.Png,
                  },
                ],
                name: pdfname,
              }).then(() => {
                window.debug && console.log('createPdfByImage');
              });
            }
          }
          break;
        default: {
          const newBlob = await svgToCanvasBlob(svg, name, scale);
          if (newBlob) {
            saveAs(newBlob, `${name}.${fileFormat}`);
          }
        }
      }

      // 统计
      globalStatistics.report();
      return 1;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  return 0;
};

/**
 * 保存zip文件
 */
export const saveFiles = async (options: ISaveFileOptions): Promise<number> => {
  const { components, doc, app, exportFormats, isOutputAllPages, renderBlankFilter, mobileType } = options;
  if (!exportFormats) {
    return 0;
  }
  // 开始统计
  globalStatistics.begin();

  // 多倍率导出
  const scales: number[] = getScales(exportFormats);
  const packImages = new PackImages({
    components,
    doc,
    app,
    exportFormats,
    isOutputAllPages,
  });
  const errorMessageList: string[] = [];

  const doComponentItem = async (t: UIArtboard | UIFragment | UIComponent): Promise<void> => {
    const data: IExportData[] = await parseExportData({ components: [t], doc, app, renderBlankFilter, mobileType });
    const json = t.toJSON();
    const size = data[0].size;
    const svgGroup = await makeSvgByGroup({ data, size, scales });

    const doSvgItem = async (
      {
        svg,
        scale,
      }: {
        svg: string;
        scale: number;
      },
      index: number,
    ) => {
      if (isDialogClosed()) {
        return Promise.reject('DialogClosed');
      }

      try {
        await packImages.addSvg({
          t: json,
          index,
          scale,
          svg,
          size,
        });
      } catch (e) {
        // 收集错误信息
        errorMessageList.push(e as string);
      }
    };
    if (svgGroup) {
      await Promise.all(svgGroup.map(doSvgItem));
    }
  };

  const onCompleate = () => {
    if (isDialogClosed()) {
      return Promise.reject('DialogClosed');
    }
    // 异常信息处理
    const errorMessage = errorMessageList.join('\n');
    if (errorMessageList.length === components.length) {
      //  全部错误抛出
      return Promise.reject(errorMessage);
    } else if (errorMessage) {
      // 部分错误，只打印。其余图片正常保持
      console.error(errorMessage);
    }

    // 保持zip文件
    packImages.saveAs();
    // 清空缓存
    httpCach.clear();
    globalStatistics.report();
  };

  // 隐藏、热区、透明度过滤掉不要导出
  const exported = components.filter(selectComponentFilter);
  let count = 0;
  let exportedCount = exported.length;
  window.debug && console.log('exportedCount' + exportedCount);
  // 触发下载，某些场景下用
  const tirgger = new TriggerImageLoad();

  await Promise.all(
    exported.map(doComponentItem).map((e) =>
      e
        .then(() => {
          if (isDialogClosed()) {
            tirgger.clear();
            return Promise.reject('DialogClosed');
          }
          if (count > 0) console.timeEnd('exportProgress' + count);
          count++;
          console.time('exportProgress' + count);
          window.debug && console.log('exportProgress:' + count + '/' + exportedCount);

          if (count === exportedCount) {
            tirgger.end();
          } else {
            tirgger.start();
          }
          return Promise.resolve(count);
        })
        .catch((e) => {
          console.log(e);
        }),
    ),
  );

  onCompleate();
  return exportedCount;
};
