import * as React from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';

import { transBlankChart } from '@utils/textUtils';

import { UIContainerComponent } from '@editor/comps';

import { StyleHelper } from '@helpers/styleHelper';
import { hasInteraction } from '@helpers/interactionHelper';
import { calcColumnMaxWidth, mergeProperties } from '@/helpers/propertiesHelper';
import { PropertyStructureAlign } from '@/libs/enum';

import { IListCompositeProps } from '../../types';
import { getListEvents } from '../helps';

import './index.scss';
import '../general.scss';

function parserItemStyle(container: UIContainerComponent) {
  const box = (container.getComponentByAlias('iconWrapper') || container.components[0]) as UIContainerComponent;
  const position = box.position;
  const border = container.getComponentByAlias('outerEllipse') || box.components[0];
  const text = container.getComponentByAlias('pureText') || container.components[1];
  const icon = box.getComponentByAlias('innerEllipse') || box.components[1];
  const { size: boxSize, properties: boxProperties } = border;
  const { size: iconSize, position: iconPosition, properties: iconProperties } = icon;
  const { size: textSize, position: textPosition, properties: textProperties, value: label } = text;
  const boxParser = StyleHelper.createCSSStyleParser(boxProperties);
  const textParser = StyleHelper.createCSSStyleParser(textProperties);
  const iconParser = StyleHelper.createCSSStyleParser(iconProperties);
  const { size: txSize, style } = textParser.getTextStyleData(textSize, textProperties.textStyle);
  const boxStyle = {
    transform: `translate(${position.x}, ${position.y}px)`,
    ...boxSize,
    ...boxParser.getStrokeStyle(),
    ...boxParser.getRadiusStyle(boxSize),
    ...boxParser.getFillStyle(boxSize),
    opacity: StyleHelper.getOpacity(border.opacity),
  };
  const iconStyle = {
    transform: `translate(${iconPosition.x + position.x}px, ${iconPosition.y + position.y}px)`,
    ...iconSize,
    ...iconParser.getFillStyle(iconSize),
    ...iconParser.getStrokeStyle(),
    opacity: StyleHelper.getOpacity(icon.opacity),
  };
  const textStyle = {
    left: textPosition.x,
    top: textPosition.y,
    ...txSize,
    ...style,
    opacity: StyleHelper.getOpacity(text.opacity),
  };
  return {
    boxStyle,
    iconStyle,
    textStyle,
    text: label,
  };
}

const RadioButtonGroup: React.FC<IListCompositeProps> = (props: IListCompositeProps) => {
  const { comp, isPreview, itemEvents, showInteract } = props;
  const container = comp as UIContainerComponent;
  const { opacity, components } = container;

  const parserStyle = _.memoize(parserItemStyle);
  const events = isPreview ? _.memoize(getListEvents)(components, itemEvents) : {};

  const containerProperties = container.toJSON().properties;
  const count = containerProperties.cell?.columnCount ?? 1;
  const columnMaxWidth = calcColumnMaxWidth(count, components);
  const containerMergeProperties = mergeProperties(container.properties, containerProperties);
  const structureAtLeft = containerMergeProperties.structure?.value === PropertyStructureAlign.TextLeft;
  return (
    <div
      className={classnames('lib-comp-radio-group', { preview: isPreview })}
      style={{ opacity: StyleHelper.getOpacity(opacity) }}
    >
      {components.map((comp, i) => {
        const item = comp as UIContainerComponent;
        const radioComp = item.getComponentByAlias('iconWrapper') || item.components[0];
        const { size: itemSize, position: itemPosition, id } = comp;
        const itemBounds = {
          left: itemPosition.x,
          top: itemPosition.y,
          ...itemSize,
          opacity: StyleHelper.getOpacity(comp.opacity),
          width: columnMaxWidth[i % count],
        };
        const { iconStyle, textStyle, text, boxStyle } = parserStyle(item);
        const radioStyle: React.CSSProperties = {
          height: itemSize.height,
          width: boxStyle.width,
          opacity: StyleHelper.getOpacity(radioComp.opacity),
        };
        const _hasInteraction = hasInteraction(comp);
        const needShowInteraction = !isPreview && _hasInteraction && false !== showInteract;
        if (structureAtLeft) {
          radioStyle.left = undefined;
          radioStyle.right = 0;
          textStyle.left = 0;
          textStyle.right = undefined;
        }
        return (
          <div
            key={id}
            className={classnames('lib-comp-radio-item', {
              'item-interaction-flag': needShowInteraction,
              'item-hot-area': isPreview && _hasInteraction,
              'component-cursor-pointer': isPreview,
            })}
            style={itemBounds}
            {...(events[comp.id] || {})}
          >
            <div className="lib-comp-radio-item-box" style={radioStyle}>
              <div className="lib-comp-radio-item-border" style={boxStyle} />
              <div className="lib-comp-radio-item-icon" style={iconStyle} />
            </div>
            <label className="lib-comp-radio-item-value" style={textStyle}>
              {transBlankChart(text as string)}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default RadioButtonGroup;
