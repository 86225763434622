import { measureTextSize } from '@utils/textUtils';
import { roundNumberObject } from '@utils/globalUtils';

import { IComponentData } from '@fbs/rp/models/component';
import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { ISize } from '@fbs/common/models/common';
import ITextFormatEx from '@fbs/rp/models/properties/textFormat';
import { PropertyValue } from '@fbs/rp/models/property';

import i18n from '@i18n';

import { UIComponent, UIContainerComponent, UIListLayoutSealedComponent } from '@editor/comps';
import { ArtboardPatchesClass } from '@editor/patches/artboardPatches';
import { StyleHelper } from '@helpers/styleHelper';
import { isMobileAppType } from '@/helpers/previewHelper';

import { PredefinedStates } from '@consts/state';
import ValueEditorType from '@consts/enums/valueEditorType';
import { SpriteThumb } from '@/consts/spriteIcons';
import { getDefaultDataFromTemplate } from '@/libs/helper';

import { IComponentItem } from '../../types';
import { CPureText } from '../../constants';
import { SizeMode } from '../../enum';

import initCSSStyleParser = StyleHelper.initCSSStyleParser;

function initListData(appType: string, data: IComponentData) {
  const itemHeight = isMobileAppType(appType) ? 48 : 30;
  let y = 0;
  data.components!.forEach((comp) => {
    comp.size = { ...comp.size, height: itemHeight };
    comp.position = { ...comp.position, y };
    y += itemHeight;
    const [text] = comp.components!;
    text.size.height = itemHeight - 4;
    text.position.y = Math.round((itemHeight - text.size.height) / 2);
  });
  data.properties.cell!.rowHeight = itemHeight;
}

function onResize(container: UIComponent, newSize: ISize): ArtboardPatches | null {
  const group = container as UIContainerComponent;

  const patches: ArtboardPatches = {
    do: {},
    undo: {},
  };
  group.components.forEach((comp) => {
    const item = comp as UIContainerComponent;
    const textComp = item.getFirstChildComponentByType(CPureText);
    if (textComp) {
      const { size, position } = textComp;
      const textWidth = Math.max(0, newSize.width - position.x * 2);
      patches.do[textComp.id] = [
        Ops.replace('./size', roundNumberObject({ ...size, width: textWidth })),
        Ops.replace('./position', { ...roundNumberObject(position), left: (newSize.width - textWidth) / 2 }),
      ];
      patches.undo[textComp.id] = [Ops.replace('./size', size), Ops.replace('./position', position)];
    }
  });
  return patches;
}

function onPropertyUpdate(container: UIComponent, propertyName: string, value: any): ArtboardPatches | null {
  if (propertyName === 'textStyle') {
    const group = container as UIContainerComponent;
    const itemHeight = container.properties.cell!.rowHeight!;
    const style = initCSSStyleParser({}).getTextStyleEx(value as ITextFormatEx);
    const textHeight = measureTextSize(style, 'lp').height;
    const patches: ArtboardPatches = { do: {}, undo: {} };
    group.components.forEach((item) => {
      const text = (item as UIContainerComponent).components[0];
      const { size, position } = text;
      patches.do[text.id] = [
        Ops.replace('./size', roundNumberObject({ ...size, height: textHeight })),
        Ops.replace('./position', roundNumberObject({ ...position, y: Math.round((itemHeight - textHeight) / 2) })),
      ];
      patches.undo[text.id] = [Ops.replace('./size', size), Ops.replace('./position', position)];
    });
    return patches;
  }
  return null;
}

function onResizeChildren(
  container: UIContainerComponent,
  newSize: ISize,
  children: UIComponent[],
): ArtboardPatches | null {
  const patches = new ArtboardPatchesClass();
  const firstChild = children[0];
  if (firstChild.size.height !== newSize.height) {
    const value: PropertyValue = { rowHeight: newSize.height, ratioHeight: !!container.properties.cell?.rowHeight };
    patches.coverPatches((container as UIListLayoutSealedComponent).getPatchesWhenSetCellProperty(value));
  }
  return patches;
}

export const ListDefaultValue = [
  `${i18n('resource.componentsText.listItemText')} 1`,
  `${i18n('resource.componentsText.listItemText')} 2`,
  `${i18n('resource.componentsText.listItemText')} 3`,
];

export const ListCfg: IComponentItem = {
  type: 'list',
  name: i18n('resource.components.list'),
  thumb: {
    spriteIconClass: SpriteThumb.List.className,
    dragPosition: SpriteThumb.List.position,
  },
  isList: true,
  isTextComp: true, // 数据填充文本-判断需要
  predefinedStates: [PredefinedStates.disabled],
  value: {
    type: ValueEditorType.ListItemPureTextValues,
  },
  initialization: initListData,
  editor: {
    onResize,
    onResizeChildren,
    onPropertyUpdate,
  },
  constraint: {
    text: {
      resize: SizeMode.none,
    },
  },
  item: `
  {
    type: @@C.CanvasPanel,
    size: {
      width: 300,
      height: 48,
    },
    position:{
      x: 0,
      y: {2}
    },
    properties:{
      fill: {
        ref: '@properties.fill',
      },
    },
    states: {
      checked: {
        enabled: true,
        name: '${i18n('property.propertyNames.checked')}',
        properties: {
          fill: {
            ref: '@properties.fill2',
          },
        },
      },
    },
    components: [
      {
        type: @@C.PureText,
        alias: 'text',
        autoSize: false,
        position: {
          x: 16,
          y: 2,
        },
        size: {
          width: 268,
          height: 44,
        },
        layout:{
          vertical: 'middle',
          horizontal: 'auto',
          fixedWidth: false,
          fixedHeight: false,
          auto: false,
          responsive: true,
        },
        properties:{
          textStyle:{
            ref:'@properties.textStyle',
          },
        },
        states:{
          checked:{
            enabled: true,
            properties:{
              textStyle:{
                ref:'@properties.selectTextStyle'
              }
            },
          },
        },
        value: '{0}',
      },
    ],
    selected: {1},
  }
  `,
  itemArgs: [
    {
      type: 'string',
      value: i18n('resource.componentsText.newItem'),
    },
    {
      type: 'boolean',
      value: false,
    },
    {
      type: 'number',
      value: 0,
    },
  ],
  template: `
  {
    type: @@C.ListLayoutPanel,
    size: {
      width: 300,
      height: 200,
    },
    layout:{
      vertical: 'middle',
      horizontal: 'left',
      fixedWidth: true,
      fixedHeight: true,
      auto: true,
      responsive: true,
    },
    properties: {
      container: {
        scroll: true,
        showScroll: true,
        disabled: false,
      },
      separator:{
        thickness: 1,
        disabled: false,
        color: @@SystemColors.DefaultStrokeColor,
      },
      cell:{
        name: '${i18n('property.propertyNames.lineHeight')}',
        rowHeight: 48,
        ratioHeight: false,
      },
      layout:{
        direction:'vertical',
        verticalGap:0,
      },
      padding:{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        disabled:false
      },
      fill: {
        name: '${i18n('property.propertyNames.bgcolor')}',
        type: @@FillType.solid,
        color: @@SystemColors.DefaultWhiteFillColor,
        disabled: false,
      },
      fill2: {
        name: '${i18n('property.propertyNames.checkedColor')}',
        prop: 'fill',
        type: @@FillType.solid,
        color: @@SystemColors.DisabledDarkFillColor,
        disabled: false,
      },
      stroke:{
        thickness: 1,
        color: @@SystemColors.DefaultStrokeColor1,
        disabled: false,
      },
      border: @@defaultBorder,
      textStyle:{
        textAlign: @@TextAlign.left,
        fontSize: 14,
        color: @@SystemColors.DefaultTextColor,
        fontFamily: 'Microsoft YaHei',
        fontStyle: { underline: false, bold: false, strike: false, italic: false },
        disabled: false,
      },
      selectTextStyle:{
        prop: 'textStyle',
        name: '${i18n('property.propertyNames.checkedText')}',
        textAlign: @@TextAlign.left,
        fontSize: 14,
        color: @@SystemColors.DefaultTextColor,
        fontFamily: 'Microsoft YaHei',
        fontStyle: { underline: false, bold: false, strike: false, italic: false },
        disabled: false,
      },
    },
    states:{
      disabled:{
        enabled: false,
        opacity: 30,
        properties:{
          
        },
      },
    },
    components: [
      @@ITEM-${i18n('resource.componentsText.listItemText')} 1|true|0,
      @@ITEM-${i18n('resource.componentsText.listItemText')} 2|false|48,
      @@ITEM-${i18n('resource.componentsText.listItemText')} 3|false|96,
    ],
    value: '',
    select: {
      enabled: true,
      target: 'child',
      maxCount: 1,
      minCount: 1,
      reversible: false,
      autoUnselect: true,
    },
    sealed: true,
  }
  `,
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
};

const defaultData = getDefaultDataFromTemplate(ListCfg);
