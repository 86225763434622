import * as React from 'react';

import { CheckBox } from '@/dsm';

import i18n from '@/i18n';
import { IPreviewOption, isMobileAppType, isWatchAppType, isVehicleAppType } from '@/helpers/previewHelper';
import { MobileType } from '@/fbs/rp/utils/preview';

import EquipmentSelect from './EquipmentSelect';

import './index.scss';

interface IMoreDialogProps {
  appType: string;
  option?: IPreviewOption;
  onOptionChange(option: IPreviewOption): void;
}

const MoreDialog: React.FC<IMoreDialogProps> = ({ option = {}, onOptionChange, appType }) => {
  const domRef = React.useRef<HTMLDivElement>(null);

  const handleOptionChange = (value: boolean | MobileType, key: keyof IPreviewOption) => {
    onOptionChange({ [key]: value });
  };
  const isMobile = isMobileAppType(appType);
  const isWatch = isWatchAppType(appType);
  const isVehicle = isVehicleAppType(appType);

  return (
    <div className="more-dialog" ref={domRef}>
      {(isMobile || isWatch || isVehicle) && (
        <div className="more-dialog-equipment">
          {i18n('preview.mobileType')}
          <EquipmentSelect
            onSelect={(value) => handleOptionChange(value, 'mobileType')}
            value={option?.mobileType}
            isMobile={isMobile}
            isWatch={isWatch}
            isVehicle={isVehicle}
          />
        </div>
      )}

      <div className="option-check">
        <CheckBox
          theme="light"
          checked={!!option?.alwaysShowLinkArea}
          onChange={(value) => handleOptionChange(value, 'alwaysShowLinkArea')}
          text={i18n('preview.alwaysShowLinkArea')}
        />
      </div>
      <div className="option-check">
        <CheckBox
          theme="light"
          checked={!!option?.showLinkAreaWhenHovered}
          onChange={(value) => handleOptionChange(value, 'showLinkAreaWhenHovered')}
          text={i18n('preview.showLinkAreaWhenHovered')}
        />
      </div>
      <div className="option-check">
        <CheckBox
          theme="light"
          checked={!!option?.noBoundary}
          onChange={(value) => handleOptionChange(value, 'noBoundary')}
          text={i18n('preview.noBoundary')}
        />
      </div>
      <div className="option-check">
        <CheckBox
          theme="light"
          checked={!!option?.showRemarkTag}
          onChange={(value) => handleOptionChange(value, 'showRemarkTag')}
          text={i18n('preview.showRemarkTag')}
        />
      </div>
      <div className="option-check">
        <CheckBox
          theme="light"
          checked={!option?.showNavigationBar}
          onChange={(value) => handleOptionChange(!value, 'showNavigationBar')}
          text={i18n('preview.autoHideNavPanel')}
        />
      </div>
    </div>
  );
};

export default MoreDialog;
