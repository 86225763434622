import { Dispatch } from 'redux';

import * as shortid from 'shortid';
import { pick } from 'lodash';

import { loadFromCache, saveToCache } from '@utils/cacheUtils';
import { getWaterMarkContent } from '@/utils/waterMarkerUtils';

import { IAppF } from '@fbs/rp/models/app';
import { IAppF as IRPAppF, ErrorCode, CustomError } from '@fbs/teamManagement';
import INode, { INodeAddPatch, INodeWithChildren, NodeType, INodeClonePatch } from '@fbs/rp/models/node';
import IArtboard from '@fbs/rp/models/artboard';
import {
  findNodeByID,
  getAllPageCount,
  getFirstPageNodeOfTree,
  NodeState,
  parseNodeToTreeByState,
} from '@fbs/rp/utils/app';
import { ArtboardComponentSelect, IONotify, SessionInfo, AppPatch, PageIOSelect } from '@fbs/rp/models/io';
// import { ITeamGroup } from '@fbs/idoc/models/teamGroup';
import { IAppInfo } from '@/fbs/idoc/models/app';
import { IAppCreateReq, IUserInfo, ITeams } from '@fbs/teamManagement';
import { IIdocApp } from '@/fbs/teamManagement/models/app';

import apis from '@apis';
import { setAxiosMethodProxyInterceptor } from '@/apis/helper';
import i18n, { languageManager } from '@i18n';

import CoreEditor from '@editor/core';

import { getDefaultSession } from '@helpers/sessionHelper';
import { getNewNodePath } from '@helpers/appHelper';

import { INodeRemoveInfo, INodeAddInfo } from '@dispatchers/appDispatcher';
import { appDataManager } from '@/services/appDataManager';
import * as io from '@/services/io';
import snapshotManager from '@/managers/snapshotManager';
import { EditorManager, IPageArtboard } from '@/managers/pageArtboardManager';
import { IPopularize } from '@/fbs/teamManagement/utils/popularize';
import { pageTreeUndoList } from '@/components/Application/LeftPanel/ProjectPanel/utils';
import { CCResponseLicense } from '@/helpers/privateLimitedTypes';
import { isExample } from '@/utils/envUtils';

import {
  ActionsUnion,
  ActionType,
  createAction,
  noPermission,
  appIsNotExits,
  noSignIn,
  DialogType,
  ToastType,
} from '../types';
import { IMainState } from '../webTypes';
import { Actions as GlobalActions, ThunkActions as GlobalThunkActions } from '../global/actions';
import { ThunkActions as ResourceThunkActions } from '../resource/actions';
import { getTeamAIBill, ITeamAIInfo } from '@/apis/app';
import mockAI from '@/utils/MockAi';
import { UIArtboard } from '@/editor/comps';
import { fragmentToBlob } from '@/helpers/imageExport/export';
import { getOrientationFromSize } from '@/helpers/artboardDimensionHelper';

// import { AppTypes } from '@fbs/teamManagement/models/app';

export const Actions = {
  getApp: (
    realNodes: INodeWithChildren[],
    tempDeleteNodes: INodeWithChildren[],
    pageFlatNodes: { [key: string]: INode },
    app: IAppF,
    selectedID?: string,
    createTime?: string | Date,
  ) =>
    createAction(ActionType.App_GetApp, {
      realNodes,
      tempDeleteNodes,
      pageFlatNodes,
      app,
      selectedID,
      createTime,
    }),
  getUserInfo: (userInfo: IUserInfo) => createAction(ActionType.User_GetUserInfo, userInfo),
  getSession: (session: SessionInfo) => createAction(ActionType.User_GetSession, session),
  getTeamInfo: (teamInfo: ITeams | null) => createAction(ActionType.Team_GetTeamInfo, teamInfo),
  getTeamAIInfo: (teamAIInfo: ITeamAIInfo) => createAction(ActionType.Team_AIInfo, teamAIInfo),
  getPDLicense: (pdLicense: CCResponseLicense) => createAction(ActionType.PD_GetLicense, pdLicense),
  // getAppCountInTeam: (count: number) => createAction(ActionType.Team_GetTeamAppCount, count),
  patchTeamInfo: (team: Partial<ITeams>) => createAction(ActionType.Team_Patch, team),
  updateUserInfo: (userInfo: IUserInfo) => createAction(ActionType.User_GetUserInfo, userInfo),
  addNode: (node: INode) => createAction(ActionType.App_AddNode, node),
  nodeGroup: (nodes: INode[]) => createAction(ActionType.App_NodeGroup, nodes),
  insertNode: (node: INode) => createAction(ActionType.App_InsertNode, node),
  cloneNode: (nodes: INodeClonePatch) => createAction(ActionType.App_CloneNode, nodes),
  updateNodes: (nodes: INode[]) => createAction(ActionType.App_UpdateNodes, nodes),
  patchNode: (node: INode) => createAction(ActionType.App_PatchNode, node),
  recoverNode: (nodes: INode[]) => createAction(ActionType.App_UpdateNodes, nodes),

  selectNode: (nodeID: string, isTrashPage = false) => createAction(ActionType.App_SelectNode, { nodeID, isTrashPage }),
  loadArtboards: (pageID: string, artboards: IArtboard[], forceUpdate: boolean) =>
    createAction(ActionType.App_LoadArtboards, {
      pageID,
      artboards,
      forceUpdate,
    }),
  ioUpdate: (data: IONotify) => createAction(ActionType.IO_UPDATE, data),
  updateCoopers: (coopers: SessionInfo[]) => createAction(ActionType.App_UpdateCoopers, coopers),
  killCoopers: (data: { appID: string; sessionIDs: string[] }) => createAction(ActionType.App_KillCoopers, data),
  updateArtboardSelect: (info: ArtboardComponentSelect) => createAction(ActionType.App_ArtboardSelectSync, info),
  treeUpdateSelectedIDs: (ids: string[], isTrashPage?: boolean) =>
    createAction(ActionType.App_Tree_UpdateSelectedIDs, { ids, isTrashPage }),
  treeUpdateExpandedIDs: (ids: string[]) => createAction(ActionType.App_Tree_UpdateExpandedIDs, ids),
  treeUpdateSearchExpandedIDs: (ids: string[]) => createAction(ActionType.App_Tree_UpdateSearchExpandedIDs, ids),
  treeHidePage: (nodes: INode[]) => createAction(ActionType.App_Tree_HidePage, nodes),

  updateTreeTrashSelectedIDs: (ids: string[], isTrashPage: boolean) =>
    createAction(ActionType.App_Tree_UpdateTrashSelectedIDs, { ids, isTrashPage }),
  updateDownloadResult: (res: {
    status: 'init' | 'request' | 'done' | 'error';
    message: string;
    URL?: string;
    taskID: number;
  }) => createAction(ActionType.App_DownloadArtboard, res),
  revertRevisions: (pageID: string, artboards: IArtboard[]) =>
    createAction(ActionType.Page_Revisions_revert, {
      pageID,
      artboards,
    }),

  refresh: () => createAction(ActionType.APP_RefreshPage),
  changeProjectSize: (appID: string, device: AppPatch['device']) =>
    createAction(ActionType.App_ChangeProjectSize, device),
  patchAppInfo: (data: Partial<IAppF | IRPAppF>) => createAction(ActionType.App_Patch_Info, data),
  // loadDepartments: (data: ITeamGroup[]) => createAction(ActionType.Department_Load_List, data),
  showWater: (value: boolean) => createAction(ActionType.App_ShowWater, value),
  noPermission: (state: number) => createAction(ActionType.App_IsNotExist, state),
  notSignIn: () => createAction(ActionType.User_NotSignIn),
  quickNextPage: (mode: 'up' | 'down' | 'back') => createAction(ActionType.App_Tree_Quick_Select_Next_Node, mode),
  recallCancellation: () => createAction(ActionType.User_RecallCancellation),
  moveApp: (appID: string, targetTeamID: string, targetAppSetID: string) =>
    createAction(ActionType.App_MoveAPP, { appID, targetTeamID, targetAppSetID }),
  getPopularizes: (data: IPopularize[]) => createAction(ActionType.Popularize_Loaded, data),
  refreshIdocApp: (idocApps: IIdocApp[]) => createAction(ActionType.App_RefreshIdocApp, idocApps),
  coopersSelctedIds: (data: PageIOSelect) => createAction(ActionType.App_CoopersSelectedIds, data),
  changeSwitchPageStatus: (data: boolean) => createAction(ActionType.App_Tree_In_Switch_Page, data),
};

const canceledTasks: number[] = [];
let taskID: number = 0;

let delayTimer: Timeout = 0;

// 所有页面查找替换文字时，构建coreEditor很耗时，需要全缓存，不然获取缓存时有问题，所以替换：LRUMap -> Map，
// 内存占用：120 个页面的情况下内存没有异常增长。
// ======== 变更调整 - 请使用EditorManager存取 liu ========
export const CoreEditors: Map<string, CoreEditor> = new Map();

const thunkGetSession = async (appID: string, dispatch: Dispatch) => {
  let session: SessionInfo = getDefaultSession();
  try {
    session = await io.joinAppCooper(appID);
  } catch (e) {
    console.log(e);
  }
  dispatch(Actions.getSession(session));
};

const getAppPageNum = (nodes: INode[]) => {
  const { realNodes } = parseNodeToTreeByState(nodes);
  return getAllPageCount(realNodes);
};

export const ThunkActions = {
  // 获取项目
  thunkGetApp: (id: string, openPage?: string, isExample?: boolean) => async (
    dispatch: Dispatch,
    getState: () => IMainState,
  ) => {
    try {
      const state = getState();
      if (!state.app.userInfo) {
        await ThunkActions.thunkGetUserInfo(isExample)(dispatch);
      }
      if (RP_CONFIGS.isPrivateDeployment) {
        await ThunkActions.thunkGetPDLicense()(dispatch);
      }
      const app: IAppF = await apis.app.getAppByID(id, isExample);

      // TODO 例子项目所有加载的数据缓存到本地

      // 先进行初始化操作， 如果连接成功会进行离线数据的提交
      await appDataManager.init(app._id, 0, io.ioHandlers);
      appDataManager.isExample = !!isExample;

      if (!isExample) {
        // 更新RP最近编辑时间
        apis.app.accessApp(id, app.teamID);
        await thunkGetSession(id, dispatch);
        // 非例子项目时这里查询团队
        await ThunkActions.thunkGetTeamInfo(app.teamID)(dispatch);
        // 推送离线页面数据到当前项目页面集合中
        appDataManager.pushOfflinePageDataToApp(app);
      }

      const { realNodes, tempDeleteNodes, pageFlatNodes } = parseNodeToTreeByState(app.children);
      const pageCount = getAllPageCount(realNodes);
      appDataManager.appPageNum = pageCount;

      // 处理一下数据结构， 包含子节点
      Object.values(pageFlatNodes).forEach((v) => {
        const artboards: IPageArtboard[] =
          v.artboards?.map((item) => {
            return {
              ...item,
              type: 'fragment',
              size: item.size ?? app.size,
              node: v,
              pageId: v._id,
            };
          }) ?? [];
        EditorManager.setArtboards(v._id, artboards);
      });

      // 如果是例子项目，刷新后的缓存清除
      isExample && appDataManager.clearOperation();

      const createTime = app.createdAt;
      dispatch(Actions.getApp(realNodes, tempDeleteNodes, pageFlatNodes, app, openPage, createTime));

      const cache = loadFromCache('lastOpenPage', {});
      let node: INode | null | undefined;
      const pageID = openPage || cache[app._id];
      if (pageID) {
        node = findNodeByID(realNodes, pageID);
      }
      if (!node || node.type === 'folder') {
        node = getFirstPageNodeOfTree(realNodes);
      }

      // 先加载资源库，在加载渲染页面数据，使得资源组件能直接呈现最新数据
      dispatch(GlobalActions.waiting(true));
      await ResourceThunkActions.thunkLoadLib(id, isExample)(dispatch);
      dispatch(GlobalActions.waiting(false));

      if (node) {
        ThunkActions.thunkLoadPage(
          node.appID,
          node._id,
          node.state === NodeState.Deleted,
          isExample,
        )(dispatch, getState);
      }
    } catch (e) {
      // 项目不存在或无权限
      if (appIsNotExits(e.code) || noPermission(e.code)) {
        dispatch(Actions.noPermission(e.code));
      } else if (noSignIn(e.code)) {
        dispatch(Actions.notSignIn());
      } else {
        GlobalThunkActions.thunkErrorMessage(e as CustomError)(dispatch);
      }
    }
  },

  thunkGetUserInfo: (isExample?: boolean) => async (dispatch: Dispatch) => {
    let user: IUserInfo | undefined = undefined;
    try {
      user = await apis.user.getUserInfo();
      // 私有部署定期修改密码
      if ((RP_CONFIGS.isPrivateDeployment || RP_CONFIGS.isHuaWei) && user.resetPassword) {
        window.location.href = `${window.apis.iDocDomain}/modifyPassword`;
      }
      dispatch(Actions.getUserInfo(user));
      GlobalThunkActions.setLogOffTeamPageTip(user.unsubscribeTime ?? 0)(dispatch);
    } catch (e) {
      const errorCode = (e as CustomError).code;
      const teamPermissionRoute = `/permission/team?ps=${errorCode}&redirect=${encodeURIComponent(location.href)}`;
      if ([ErrorCode.PDLicenseExpired, ErrorCode.PDLicenseNoRP, ErrorCode.PDTeamExpired].includes(errorCode)) {
        window.location.href = `${window.apis.rpDomain}${teamPermissionRoute}`;
        return;
      }
      // 非例子项目时查询用户报错要抛出，否则不用管
      if (!isExample) {
        throw e;
      }
      //   GlobalThunkActions.thunkErrorMessage(e)(dispatch);
    }
    // 例子项目时，在这里查询团队
    if (isExample && user) {
      user.defaultIDocTeamID && ThunkActions.thunkGetTeamInfo(user.defaultIDocTeamID)(dispatch);
    }
  },
  // 新增节点
  thunkAddNode: (data: INodeAddPatch) => async (dispatch: Dispatch) => {
    io.pushNodeAddPatch(data)
      .then((node) => {
        if (data.index === -1) {
          // 如果是删除所有之后新增的节点，需要修改nextNodeId
          try {
            if (pageTreeUndoList[pageTreeUndoList.length - 1]) {
              pageTreeUndoList[pageTreeUndoList.length - 1].data.remove.nextNodeId = node._id;
            }
          } catch (error) {
            window.debug && console.log(error);
          }
          dispatch(Actions.addNode(node));
        } else {
          dispatch(Actions.insertNode(node));
        }
      })
      .catch((e) => {
        GlobalThunkActions.thunkErrorMessage(e, true, undefined, false)(dispatch);
      });
  },
  // 移动节点
  thunkMoveNode: (appID: string, nodeIDs: string[], toPath: string | string[], index: number | number[]) => async (
    dispatch: Dispatch,
  ) => {
    try {
      const res = await io.pushNodeMovePatch({
        appID,
        nodeIDs,
        toPath,
        index,
      });
      dispatch(Actions.updateNodes(res));
    } catch (e) {
      GlobalThunkActions.thunkErrorMessage(e as CustomError, true, undefined, false)(dispatch);
    }
  },
  // 节点编组
  thunkNodeGroup: (data: INodeAddInfo) => async (dispatch: Dispatch) => {
    dispatch(GlobalActions.waiting(true));
    try {
      const res = await io.pushNodeGroupPatch(data, data.selectedIDs);
      dispatch(Actions.updateNodes(res.nodes));
      dispatch(Actions.treeUpdateSelectedIDs([res.newNodeID]));
      dispatch(GlobalActions.waiting(false));
    } catch (e) {
      GlobalThunkActions.thunkErrorMessage(e as CustomError, true, undefined, false)(dispatch);
      dispatch(GlobalActions.waiting(false));
    }
  },

  // 节点解组
  thunkNodeUnGroup: (appID: string, selectedIDs: string) => async (dispatch: Dispatch) => {
    dispatch(GlobalActions.waiting(true));
    try {
      const res = await io.pushNodeUnGroupPatch({ appID, nodeID: selectedIDs });
      dispatch(Actions.updateNodes(res.nodes));
      dispatch(GlobalActions.waiting(false));
      // dispatch(Actions.treeUpdateSelectedIDs(res.nodeIDs));
    } catch (e) {
      GlobalThunkActions.thunkErrorMessage(e as CustomError, true, undefined, false)(dispatch);
      dispatch(GlobalActions.waiting(false));
    }
  },
  // 复制节点
  thunkCopyNode: (
    appID: string,
    nodeIDs: string[],
    toPath: string,
    index: number,
    sourceAppID: string,
    withChildren: boolean,
  ) => async (dispatch: Dispatch) => {
    dispatch(GlobalActions.waiting(true));
    try {
      const res = await io.pushNodeCopyPatch({
        appID,
        nodeIDs,
        toPath,
        index,
        sourceAppID,
        withChildren,
      });
      appDataManager.appPageNum += nodeIDs.length;
      dispatch(Actions.updateNodes(res.nodes));
      dispatch(Actions.cloneNode({ nodes: res.nodes, nodeIDs: res.nodeIDs }));
      dispatch(GlobalActions.waiting(false));
    } catch (e) {
      GlobalThunkActions.thunkErrorMessage(e as CustomError, true, undefined, false)(dispatch);
      dispatch(GlobalActions.waiting(false));
    }
  },
  // 删除节点
  thunkRemoveNode: (appID: string, data: INodeRemoveInfo) => async (dispatch: Dispatch, getState: () => IMainState) => {
    const { size, userID, nodeIDs, nextNodeId } = data;
    dispatch(GlobalActions.waiting(true));
    try {
      const res = await io.pushNodeRemovePatch({
        appID,
        nodeIDs,
      });
      dispatch(Actions.updateNodes(res));
      dispatch(GlobalActions.waiting(false));
      //选中与当前所有删除的节点的临近下一个页面节点
      if (res.length && nextNodeId) {
        appDataManager.appPageNum = getAppPageNum(res);
        ThunkActions.thunkLoadPage(appID, nextNodeId, false)(dispatch, getState);
        dispatch(Actions.treeUpdateSelectedIDs([nextNodeId]));
      } else {
        const info = {
          _id: shortid.generate(),
          artboardID: shortid.generate(),
          artboardName: i18n('project.mainName'),
          appID,
          name: i18n('project.defaultPageName'),
          size,
          orientation: getOrientationFromSize(size),
          userID,
          type: NodeType.Page,
          parentID: '',
          index: -1,
          path: getNewNodePath(data.nodes, ''),
        };
        //删除掉所有页面后，默认创建一个新的页面
        ThunkActions.thunkAddNode(info)(dispatch);
      }
    } catch (e) {
      GlobalThunkActions.thunkErrorMessage(e as CustomError, true, undefined, false)(dispatch);
      dispatch(GlobalActions.waiting(false));
    }
  },
  // 修改节点信息
  thunkPatchNode: (appID: string, nodeID: string, name: string) => async (dispatch: Dispatch) => {
    try {
      const res = await io.pushNodePatchPatch({
        appID,
        nodeID,
        name,
      });
      dispatch(Actions.patchNode(res));
    } catch (e) {
      GlobalThunkActions.thunkErrorMessage(e as CustomError, true, undefined, false)(dispatch);
    }
  },
  // 恢复节点
  thunkRecoverNode: (
    appID: string,
    nodeIDs: string[],
    targetParent?: string,
    targetIndex?: number,
    nextPageNodeId?: string,
    isTrashPage?: boolean,
  ) => async (dispatch: Dispatch) => {
    try {
      dispatch(GlobalActions.waiting(true));
      const res = await io.pushRecoverNodePatch({
        appID,
        nodeIDs,
        targetParent,
        targetIndex,
      });
      dispatch(Actions.recoverNode(res));
      dispatch(GlobalActions.waiting(false));
      if (nextPageNodeId) {
        dispatch(Actions.treeUpdateSelectedIDs([nodeIDs[0]], isTrashPage));
      }
    } catch (e) {
      GlobalThunkActions.thunkErrorMessage(e as CustomError, true, undefined, false)(dispatch);
      dispatch(GlobalActions.waiting(false));
    }
  },

  // 永久删除节点
  thunkRemoveNodePermanently: (appID: string, nodeIDs: string[], nextNodeId?: string, isTrashPage?: boolean) => async (
    dispatch: Dispatch,
    getState: () => IMainState,
  ) => {
    try {
      const res = await io.pushNodeRemovePermanentlyPatch({
        appID,
        nodeIDs,
      });
      dispatch(Actions.updateNodes(res));

      //选中与当前所有删除的节点的临近下一个页面节点
      if (res.length && nextNodeId) {
        ThunkActions.thunkLoadPage(appID, nextNodeId, !!isTrashPage)(dispatch, getState);
      }
    } catch (e) {
      GlobalThunkActions.thunkErrorMessage(e as CustomError, true, undefined, false)(dispatch);
    }
  },

  // 永久删除回收站所有节点
  thunkRemoveAllNodePermanently: (appID: string) => async (dispatch: Dispatch) => {
    try {
      const res = await io.pushNodeRemoveAllPermanentlyPatch({
        appID,
      });
      dispatch(Actions.updateNodes(res));
    } catch (e) {
      GlobalThunkActions.thunkErrorMessage(e as CustomError, true, undefined, false)(dispatch);
    }
  },

  // 载入页面
  thunkLoadPage: (appID: string, pageID: string, isTrashPage: boolean, isExample = false) => async (
    dispatch: Dispatch,
    getState: () => IMainState,
  ) => {
    try {
      const has = EditorManager.getCoreEditor(pageID);
      // if(!has) {
      dispatch(GlobalActions.waiting(true));
      // }
      dispatch(Actions.selectNode(pageID, isTrashPage));

      saveToCache('lastOpenPage', { [appID]: pageID });
      if (!has) {
        const artboards = await apis.artboard.getAllArtboardsByNodeID(pageID, undefined, isExample);
        dispatch(Actions.loadArtboards(pageID, artboards, isExample));
        // 加载完core后，把数据推送到doc里面;
        appDataManager.pushOperationToDocument(getState().app.coreEditor!, pageID);
      }
      dispatch(GlobalActions.waiting(false));
      !isExample && ThunkActions.thunkUpdateSelectedNode(appID, pageID);
    } catch (e) {
      if (!isExample && e.code !== 'offline-error') {
        GlobalThunkActions.thunkErrorMessage(e as CustomError, true, undefined, false)(dispatch);
      }
    }
  },
  thunkUpdateSelectedNode: (appID: string, pageID: string) => async (dispatch: Dispatch) => {
    try {
      const info = await io.syncComponentsSelectOfNode(appID, pageID);
      io.editNode(pageID);
      if (info.code === 0 && info.payload) {
        dispatch(
          Actions.updateArtboardSelect({
            appID: appID,
            nodeID: pageID,
            selected: info.payload,
          }),
        );
      }
    } catch (e) {
      GlobalThunkActions.thunkErrorMessage(e as CustomError, true, undefined, false)(dispatch);
    }
  },
  /**
   * 隐藏页面，演示及IDOC 单页模式不显示
   *
   * @param appID
   * @param pageIDs
   * @param hidden
   */
  thunkHiddenPage: (appID: string, pageIDs: string[], hidden: boolean) => async (dispatch: Dispatch) => {
    try {
      const res = await io.pushHidePageNode({
        appID,
        nodeIDs: pageIDs,
        hidden: hidden,
      });
      dispatch(Actions.treeHidePage(res));
    } catch (e) {
      GlobalThunkActions.thunkErrorMessage(e as CustomError, true, undefined, false)(dispatch);
    }
  },

  thunkReloadPage: (appID: string, pageID: string) => async (dispatch: Dispatch) => {
    try {
      const artboards = await apis.artboard.getAllArtboardsByNodeID(pageID);
      dispatch(Actions.loadArtboards(pageID, artboards, true));
      const info = await io.syncComponentsSelectOfNode(appID, pageID);
      io.editNode(pageID);
      if (info.code === 0 && info.payload) {
        dispatch(
          Actions.updateArtboardSelect({
            appID: appID,
            nodeID: pageID,
            selected: info.payload,
          }),
        );
      }
    } catch (e) {
      if (e.code !== 'offline-error') {
        GlobalThunkActions.thunkErrorMessage(e as CustomError, true, undefined, false)(dispatch);
      }
    }
  },

  delayInfoRequest: (taskID: string, currentTaskID: number) => async (dispatch: Dispatch) => {
    apis.artboard.downloaded(taskID).then((res) => {
      const taskId = res.taskID;
      if (taskId) {
        delayTimer = setTimeout(() => {
          ThunkActions.delayInfoRequest(taskId, currentTaskID)(dispatch);
        }, 5000);
      } else {
        clearTimeout(delayTimer);
        dispatch(
          Actions.updateDownloadResult({
            status: 'done',
            message: i18n('workspace.artboard.downloadedMessage'),
            URL: res.URL,
            taskID: currentTaskID,
          }),
        );
      }
    });
  },

  thunkDownloadArtboard: (appID: string, artboardID: string, scale?: number) => async (
    dispatch: Dispatch,
    getState: Function,
  ) => {
    const currentTaskID = taskID++;
    dispatch(
      Actions.updateDownloadResult({
        status: 'request',
        message: i18n('workspace.artboard.downloadingMessage'),
        taskID: currentTaskID,
      }),
    );
    let wartMark = '';
    const {
      app: { userInfo, showWater },
    } = getState() as IMainState;
    if (RP_CONFIGS.isPrivateDeployment && showWater) {
      wartMark = getWaterMarkContent(userInfo?.email);
    }
    apis.artboard
      .download(appID, artboardID, wartMark, scale)
      .then((res) => {
        if (canceledTasks.includes(currentTaskID)) {
          return;
        }
        if (res.taskID) {
          ThunkActions.delayInfoRequest(res.taskID, currentTaskID)(dispatch);
        } else {
          dispatch(
            Actions.updateDownloadResult({
              status: 'done',
              message: i18n('workspace.artboard.downloadedMessage'),
              URL: res.URL,
              taskID: currentTaskID,
            }),
          );
        }
      })
      .catch((e) => {
        if (canceledTasks.includes(currentTaskID)) {
          return;
        }
        if (e.code === 202) {
          GlobalThunkActions.thunkErrorMessage(e)(dispatch);
          return;
        }
        dispatch(
          Actions.updateDownloadResult({
            status: 'error',
            message: i18n('workspace.artboard.downloadErrorMessage') + e.message,
            taskID: currentTaskID,
          }),
        );
      });
  },

  thunkCancelDownload: (taskID: number) => async (dispatch: Dispatch) => {
    canceledTasks.push(taskID);

    clearTimeout(delayTimer);

    dispatch(
      Actions.updateDownloadResult({
        status: 'init',
        message: '',
        taskID: -1,
      }),
    );
  },

  thunkLoadRevisionPageData: (appID: string, pageID: string) => async (dispatch: Dispatch) => {
    try {
      const artboards = await apis.artboard.getAllArtboardsByNodeID(pageID);
      dispatch(Actions.revertRevisions(pageID, artboards));

      const info = await io.syncComponentsSelectOfNode(appID, pageID);
      io.editNode(pageID);
      if (info.code === 0 && info.payload) {
        dispatch(
          Actions.updateArtboardSelect({
            appID: appID,
            nodeID: pageID,
            selected: info.payload,
          }),
        );
      }
    } catch (e) {
      if (e.code !== 'offline-error') {
        GlobalThunkActions.thunkErrorMessage(e as CustomError, true, undefined, false)(dispatch);
      }
    }
  },

  // 修改项目尺寸
  thunkChangeProjectSizeData: (appID: string, device: AppPatch['device']) => async (dispatch: Dispatch) => {
    try {
      await io.changeProjectSize(appID, device);
      dispatch(Actions.changeProjectSize(appID, device));
    } catch (e) {
      GlobalThunkActions.thunkErrorMessage(e as CustomError, true, undefined, false)(dispatch);
    }
  },
  patchTeamInfo: (team: Partial<ITeams>) => (dispatch: Dispatch) => {
    dispatch(Actions.patchTeamInfo(team));
  },
  patchAppInfo: (app: Partial<IAppF>) => (dispatch: Dispatch) => {
    dispatch(Actions.patchAppInfo(app));
  },

  // loadDepartments: (teamID: string) => (dispatch: Dispatch) => {
  //   apis.team
  //     .getTeamGroups(teamID)
  //     .then((res) => {
  //       dispatch(Actions.loadDepartments(res));
  //     })
  //     .catch((e) => GlobalThunkActions.thunkToast(e.message)(dispatch));
  // },

  createApp: (data: IAppCreateReq, fn?: (data: IAppInfo) => void) => (dispatch: Dispatch) => {
    apis.app
      .addApp(data)
      .then((res) => {
        const app = res as IAppInfo;
        fn && fn(app);
        ThunkActions.thunkGetTeamInfo(data.teamID)(dispatch);
      })
      .catch((e) => {
        if (e.code === ErrorCode.TeamAppLimit) {
          GlobalThunkActions.showDialog(DialogType.fullApp)(dispatch);
          return;
        }
        if (RP_CONFIGS.isPrivateDeployment || languageManager.isEnVersion) {
          GlobalThunkActions.thunkErrorMessage(e, true, undefined, false)(dispatch);
          return;
        }
        // addRPApp(formData)
        //   .then((res) => {
        //     fn && fn(res);
        //   })
        //   .catch(() => {
        //     GlobalThunkActions.thunkErrorMessage(e, true, undefined,false)(dispatch);
        //   });
      });
  },
  editAppName: (appId: string, name: string) => (dispatch: Dispatch) => {
    apis.app
      .editAppName(appId, {
        name,
      })
      .then((res) => {
        dispatch(Actions.patchAppInfo({ name: res.name }));
      })
      .catch((err) => {
        window.debug && console.log(err);
        err.code === 202 && GlobalThunkActions.thunkErrorMessage(err)(dispatch);
      });
  },
  editAppInfo: (appId: string, data: Partial<IAppF>) => (dispatch: Dispatch) => {
    apis.app
      .editAppInfo(appId, data)
      .then((res) => {
        dispatch(Actions.patchAppInfo(pick(res, Object.keys(data))));
      })
      .catch((err) => {
        window.debug && console.log(err);
        err.code === 202 && GlobalThunkActions.thunkErrorMessage(err)(dispatch);
      });
  },

  getSnapshotAllPages: (appID: string) => {
    snapshotManager
      .loadAllUsedResource(appID)
      .then(() => {})
      .catch((err) => {
        window.debug && console.log(err);
      });
  },

  thunkGetTeamInfo: (teamID: string) => async (dispatch: Dispatch) => {
    const teamInfo = await apis.team.getTeamInfo(teamID);

    // 获取团队的AI账户明细 - 如果这个接口错误不影响项目加载
    try {
      const teamBill = await getTeamAIBill(teamInfo.id);
      if (teamBill) {
        mockAI.usableMoney = Math.round(teamBill?.points ?? 0); // 根据策略后端预留小数位，前端处理为整数
        mockAI.enable = teamBill.state === 0;
        dispatch(Actions.getTeamAIInfo(teamBill));
      }
    } catch (error) {
      window.debug && console.error(error);
    }

    dispatch(Actions.getTeamInfo(teamInfo));
  },
  thunkGetPDLicense: () => async (dispatch: Dispatch) => {
    const pdLicense = await apis.app.getPrivateLicenseInfo();
    dispatch(Actions.getPDLicense(pdLicense));
  },
  thunkCheckWater: () => async (dispatch: Dispatch) => {
    apis.team.getPDConfigs().then((res) => {
      // side effect
      if (res.methodProxy) {
        setAxiosMethodProxyInterceptor();
      }

      if (!isExample) {
        dispatch(Actions.showWater(res.isOpenWatermark));
        dispatch(Actions.patchTeamInfo({ enableMemberExportRpDt: !!res.enableMemberExportRpDt }));
      }
    });
  },
  // 撤回团队注销
  recallCancellation: () => async (dispatch: Dispatch) => {
    apis.user
      .cancelUnsubscribe()
      .then(() => {
        GlobalThunkActions.showToast(ToastType.Success, i18n('message.recallSuccess'))(dispatch);
        dispatch(Actions.recallCancellation());
      })
      .catch((e) => {
        GlobalThunkActions.thunkErrorMessage(e, true, undefined, false)(dispatch);
      });
  },
  moveApp: (appID: string, targetTeamID: string, targetAppSetID: string) => async (dispatch: Dispatch) => {
    await apis.app
      .moveApp(appID, targetTeamID, targetAppSetID)
      .then(() => {
        ThunkActions.thunkGetTeamInfo(targetTeamID)(dispatch);
        dispatch(Actions.moveApp(appID, targetTeamID, targetAppSetID));
        GlobalThunkActions.showDialog(DialogType.none)(dispatch);
        GlobalThunkActions.showToast(ToastType.Success, i18n('personalSpace.moveSuccessful'))(dispatch);
      })
      .catch((e) => {
        if (e.code === ErrorCode.TeamAppLimit) {
          GlobalThunkActions.showDialog(DialogType.fullProject)(dispatch);
          return;
        }
        GlobalThunkActions.thunkErrorMessage(e, true, undefined, false)(dispatch);
      });
  },
  getPopularizes: () => async (dispatch: Dispatch) => {
    const result = await apis.activity.getPopularizes();
    dispatch(Actions.getPopularizes(result));
  },
  refreshIdocApp: (idocApps: IIdocApp[]) => async (dispatch: Dispatch) => {
    dispatch(Actions.refreshIdocApp(idocApps));
  },
  applyArtboardToAppCover: (appID: string, artboard: UIArtboard) => (dispatch: Dispatch) => {
    const { artboardID } = artboard;
    fragmentToBlob(artboard).then((blob) => {
      const file = new File([blob], `${artboardID}.png`, { type: 'image/png' });
      apis.app.updateAppCover(appID, file).then(({ URL }) => {
        try {
          if (window.BroadcastChannel) {
            const msg = new BroadcastChannel('apply cover');
            msg.postMessage({ appID, cover: URL });
            msg.close();
          }
        } catch {
          // 系统不支持这个对象，不作处理
        } finally {
          GlobalThunkActions.showToast(ToastType.Success, i18n('workspace.artboard.applyCoverSuccess'))(dispatch);
        }
      });
    });
  },
};

export type AppAction = ActionsUnion<typeof Actions>;
