import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { convertEventToHotKey, convertShortCutToHotKey } from '@/utils/hotkeysUtils';
import { isMockRPD, isShareFrame, isLarkPC } from '@/utils/envUtils';

import KeyCodeMap from '@/dsm2/constants/KeyCodeMap';
import Routes from '@consts/router';
import i18n from '@/i18n';
import { IPreviewOption, isStandalone } from '@/helpers/previewHelper';
import Theater from '@/theater';
import { IMainState } from '@/store/webTypes';
import { larkAppLink, larkPC } from '@/helpers/larkHelper';
import { openFullScreen, exitFullScreen } from '@/helpers/fullscreenHelper';
import { copy } from '@/helpers/clipboardHelper';
import { getShortCutKey } from '@/helpers/shortCutHelper';
import { ControlPanelState } from '@/fbs/rp/models/preview';
import { INodeWithChildren } from '@/fbs/rp/models/node';
import { IUserInfo } from '@/fbs/idoc/models/user';
import { IAppWithNestedChildren } from '@/fbs/rp/models/app';
import { isInWeMeet } from '@wemeet/src/helper/weMeet';
import { isAllPagesHidden } from '@/dsm/PageTree/utils';
import { getUrlSplitChar, getQueryString } from '@/helpers/urlHelper';

import ShareSelect from '@wemeet/src/components/ShareSelect';
import { Icon, IListItem, Select, Zoomer } from '@/dsm';
import MoreDialog from './MoreDialog';
import ShareRunDialog from '../NavigationBar/ShareDialog';
import Popup, { Placement } from './Popup';
import Transition from '../Transition';
import ConfirmDialog from '../../../Common/ConfirmDialog';
import ShareChangeTipDialog from '../NavigationBar/ShareChangeTipDialog';
import appOptions from '@/helpers/appOptions';

import './index.scss';

interface INavigationPanelProps {
  onControllerPanelCollapseChange(e: React.MouseEvent | KeyboardEvent, state: ControlPanelState): void;
  controlPanelState: ControlPanelState;
  onRemarkPanelCollapseChange(state: ControlPanelState, e: React.MouseEvent | KeyboardEvent): void;
  remarkPanelState: ControlPanelState;
  app: IAppWithNestedChildren;
  appID: string;
  realAppID: string;
  pageID: string;

  parentOffsetLeft: number;
  parentOffsetX: number;
  linkID?: string;
  nextLinkID?: string;
  option?: IPreviewOption;
  page?: Theater;
  appType: string;
  scale?: number;
  userInfo?: IUserInfo;
  shouldHideNavPanel?: boolean;
  isStandalone?: boolean;
  isFullScreen?: boolean;
  onMsgPopup(msg: string, time?: number | undefined): void;
  onOptionChange(option: IPreviewOption): void;
  onScaleChange(value: IListItem): void;
  onChangeScale(value: number, isChangeNotByWheel?: boolean): void;
  onFullScreen: (isFullscreen: boolean) => void;
  onPageSelect: (node: INodeWithChildren) => void;
}

interface IconItem {
  icon: string;
  tipKey?: string;
  activeTipKey?: string;
  isHideTipWithActive?: boolean;
}

const ScaleList = [
  { id: 1, text: '50%', scale: 50 },
  { id: 2, text: '100%', shortCut: getShortCutKey('0', { ctrlKey: true }), scale: 100 },
  { id: 3, text: '200%', showSeparator: true, scale: 200 },
  { id: 4, text: i18n('preview.fullWidth'), shortCut: getShortCutKey('1', { ctrlKey: true }), isFullWidth: true },
  { id: 5, text: i18n('preview.fullScreen'), shortCut: getShortCutKey('2', { ctrlKey: true }), isFullScreen: true },
];

const FullScreenShortCutKey = getShortCutKey('f11', { ctrlKey: true }); // 全屏快捷键
const LeftPanelShortCutKey = getShortCutKey('1', { ctrlKey: true, altKey: true });
const RightPanelShortCutKey = getShortCutKey('2', { ctrlKey: true, altKey: true });

// 在mac下，convertShortCutToHotKey返回的顺序为alt+ctrl,此处列举具体值在下文判断
const LeftPanelShortCuteyWithKeydown = ['ctrl+alt+1', 'ctrl+alt+¡']; // 左侧面板隐藏显示快捷键
const RightPanelShortCutWithKeydown = ['ctrl+alt+2', 'ctrl+alt+™']; // 右侧面板隐藏显示快捷键

const IconList = {
  more: { icon: 'icon_more' }, // 更多
  refresh: { icon: 'icon_refresh', tipKey: i18n('general.refresh') }, // 刷新
  fullScreen: {
    icon: 'icon_demonstration_Fullscreen_rp',
    tipKey: `${i18n('general.enterFullScreen')}（${FullScreenShortCutKey}）`,
  }, // 全屏
  leftPanel: {
    icon: 'icon_demonstration_LeftColumn_rp',
    activeTipKey: `${i18n('preview.hidePagePanel')}（${LeftPanelShortCutKey}）`,
    tipKey: `${i18n('preview.showPagePanel')}（${LeftPanelShortCutKey}）`,
  }, // 左侧面板
  rightPanel: {
    icon: 'icon_demonstration_RightColumn_rp',
    activeTipKey: `${i18n('preview.hideRemarkPanel')}（${RightPanelShortCutKey}）`,
    tipKey: `${i18n('preview.showRemarkPanel')}（${RightPanelShortCutKey}）`,
  }, // 右侧面板
  import: { icon: 'import', tipKey: i18n('preview.exit') },
  share: { icon: 'demo_share', tipKey: i18n('preview.shareUrl') },
};

const isOfflineDemo = !!RP_CONFIGS.isOfflineDemo;
const MouseOverPX = 24; // 触发导航bar的高度
const MouseOutPX = 56;

const NavigationPanel: React.FC<INavigationPanelProps> = (props) => {
  const isControlPanelExpand = props.controlPanelState === ControlPanelState.Expand;
  const isRemarkPanelExpand = props.remarkPanelState === ControlPanelState.Expand;
  const isAutoHideNavPanel = !props.option?.showNavigationBar;

  const [shareVisible, setShareVisible] = React.useState(false);
  const [showShareChangeTip, setShowShareChangeTip] = React.useState(appOptions.showShareChangeTip);
  const [isPageAllEmpty, setIsPageAllEmpty] = React.useState(false);
  const [txMeetingShareVisible, setTxMeetingShareVisible] = React.useState(false);
  const [scale, setScale] = React.useState<undefined | number>();
  const [panelVisible, setPanelVisible] = React.useState(!isAutoHideNavPanel);
  const [moreVisible, setMoreVisible] = React.useState(false);
  const [showAllPageHasHideDialog, setShowAllPageHasHideDialog] = React.useState(false);
  const [checkedProjectKeys, setCheckedProjectKeys] = React.useState([] as string[]);
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  const barRef = React.useRef<HTMLDivElement | null>(null);
  const tempRef = React.useRef({
    parentOffsetLeft: props.parentOffsetLeft,
    parentOffsetX: props.parentOffsetX,
    isAutoHideNavPanel,
    panelVisible: !isAutoHideNavPanel,
    showBarTip: false,
    moreVisible: false,
    shareVisible: false,
    scaleVisible: false,
    isControlPanelExpand,
    isRemarkPanelExpand,
    isFullScreen: props.isFullScreen,
    showShareChangeTip: appOptions.showShareChangeTip,
  });

  const { currentLinkId, linkID, nextLinkID } = useSelector(({ share, preview }: IMainState) => ({
    currentLinkId: preview.queryLinkId,
    linkID: share.linkID,
    nextLinkID: share.nextLinkID,
  }));

  React.useEffect(() => {
    window.debug && console.log(window.history);
    window.addEventListener('keydown', handleWindowKeyDown, { capture: true });
    window.addEventListener('mousemove', handleWindowMousemove);
    return () => {
      window.removeEventListener('keydown', handleWindowKeyDown, { capture: true });
      window.removeEventListener('mousemove', handleWindowMousemove);
    };
  }, []);

  React.useEffect(() => {
    const isAllEmpty = isAllPagesHidden(props.app.children);
    setIsPageAllEmpty(isAllEmpty);
  }, [props.app]);

  React.useEffect(() => {
    tempRef.current.isControlPanelExpand = isControlPanelExpand;
    tempRef.current.isRemarkPanelExpand = isRemarkPanelExpand;
    tempRef.current.isFullScreen = props.isFullScreen;
  }, [isControlPanelExpand, isRemarkPanelExpand, props.isFullScreen]);

  React.useEffect(() => {
    const option = props.option;
    const selectItem = ScaleList.find(
      (v) =>
        (v.isFullWidth && option?.fullWidth) ||
        (v.isFullScreen && option?.autoScreen) ||
        (v.scale === props.scale && !option?.fullWidth && !option?.autoScreen),
    );
    setScale(selectItem?.id);
  }, [props.scale, props.option?.fullWidth, props.option?.autoScreen]);

  React.useEffect(() => {
    tempRef.current.isAutoHideNavPanel = isAutoHideNavPanel;
    if (isAutoHideNavPanel) {
      setMoreVisible(false);
      tempRef.current.moreVisible = false;
      setPanelVisible(false);
      tempRef.current.panelVisible = false;
    }
  }, [props.option?.showNavigationBar]);

  React.useEffect(() => {
    tempRef.current.parentOffsetLeft = props.parentOffsetLeft;
    tempRef.current.parentOffsetX = props.parentOffsetX;
  }, [props.parentOffsetLeft, props.parentOffsetX]);

  const handleWindowKeyDown = (e: KeyboardEvent) => {
    // 监听快捷键
    const hotKey = convertEventToHotKey(e);
    // 缩放快捷键
    const scaleItem = ScaleList.find((item) => {
      if (item.shortCut) {
        const k = convertShortCutToHotKey(item.shortCut);
        return k === hotKey;
      }
      return false;
    });
    if (scaleItem) {
      handleScaleChange(scaleItem);
    } else if (hotKey === convertShortCutToHotKey(FullScreenShortCutKey)) {
      // 全屏快捷键
      handleFullscreen();
    } else if (e.keyCode === KeyCodeMap.VK_ESCAPE && tempRef.current.isFullScreen) {
      // esc退出全屏
      handleFullscreen();
    } else if (LeftPanelShortCuteyWithKeydown.includes(hotKey) && !tempRef.current.isFullScreen) {
      // 左侧面板
      handleControllerPanelCollapseChange(e);
    } else if (RightPanelShortCutWithKeydown.includes(hotKey) && !tempRef.current.isFullScreen) {
      // 右侧面板
      handleRemarkPanelCollapseChange(e);
    }
  };

  const handleWindowMousemove = (e: MouseEvent) => {
    if (!tempRef.current.isAutoHideNavPanel || tempRef.current.showBarTip) {
      return;
    }
    const { pageX, pageY } = e;
    const parentInfo = tempRef.current;
    const bottom = window.innerHeight - pageY;
    const hoverPx = tempRef.current.panelVisible ? MouseOutPX : MouseOverPX;
    const isBottomHovered = bottom <= hoverPx && bottom > 0;
    const visible =
      isBottomHovered && pageX - parentInfo.parentOffsetLeft >= 0 && parentInfo.parentOffsetX - pageX >= 0;
    if (
      tempRef.current.panelVisible &&
      (tempRef.current.moreVisible || tempRef.current.shareVisible || tempRef.current.scaleVisible)
    ) {
      return;
    }
    if (visible !== tempRef.current.panelVisible) {
      setPanelVisible(visible);
      tempRef.current.panelVisible = visible;
    }
  };

  const handleRefresh = (): void => {
    window.location.reload();
  };

  const handleBack = () => {
    window.history.back();
    setCurrentIndex(currentIndex - 1);
  };

  const handleForward = () => {
    window.history.forward();
    setCurrentIndex(currentIndex + 1);
  };

  const renderHistory = () => {
    if (!isMockRPD && !isLarkPC) {
      return null;
    }
    const noneState = !history.state || JSON.stringify(history.state) === '{}';
    const noneHistory = history.length <= 1 || noneState;
    const latest = currentIndex === 0;
    return (
      <>
        <div className={`dsm-tooltip preview-navigation-back ${noneHistory ? 'disabled' : ''}`}>
          <Icon
            cls="demo_leftarrow"
            onClick={noneHistory ? undefined : handleBack}
            size={16}
            tips={i18n('preview.backward')}
          />
        </div>
        <div className={`dsm-tooltip preview-navigation-forward ${latest ? 'disabled' : ''}`}>
          <Icon
            cls="demo_rightarrow"
            onClick={latest ? undefined : handleForward}
            size={16}
            tips={i18n('preview.forward')}
          />
        </div>
      </>
    );
  };

  //   const handleExit = (): void => {
  //     if (larkPC) {
  //       window.history.back();
  //     } else {
  //       window.close();
  //     }
  //   };

  const handleControllerPanelCollapseChange = (e: React.MouseEvent | KeyboardEvent) => {
    const state = tempRef.current.isControlPanelExpand ? ControlPanelState.Hide : ControlPanelState.Expand;
    props.onControllerPanelCollapseChange(e, state);
  };

  const handleRemarkPanelCollapseChange = (e: React.MouseEvent | KeyboardEvent) => {
    const state = tempRef.current.isRemarkPanelExpand ? ControlPanelState.Hide : ControlPanelState.Expand;
    props.onRemarkPanelCollapseChange(state, e);
  };

  const handleScaleChange = (value: IListItem) => {
    if (value.scale) {
      props.onOptionChange({ fullWidth: false, autoScreen: false, scale: value.scale as number });
      props.onChangeScale(value.scale as number, true);
    } else if (value.isFullWidth) {
      // 适应宽度
      props.onOptionChange({ fullWidth: true, autoScreen: false });
    } else if (value.isFullScreen) {
      // 适应屏幕
      props.onOptionChange({ fullWidth: false, autoScreen: true });
    }
  };

  const handleZoomChange = (value: number, isChangeNotByWheel?: boolean) => {
    props.onChangeScale(value, !isChangeNotByWheel);
    props.onOptionChange({ fullWidth: false, autoScreen: false });
  };

  const getRunUrl = () => {
    const { appID, pageID, page } = props;
    // 当前页分享链接
    let currentPageID = page?.doc.pageID || pageID;
    // 匹配处理当前页，如果当前分享链接不在选择的项目中，默认取第一个
    if (checkedProjectKeys.length && !checkedProjectKeys.includes(currentPageID)) {
      currentPageID = checkedProjectKeys[0];
    }
    let currLinkID = '';
    if (nextLinkID) {
      currLinkID = nextLinkID + '/';
    } else {
      currLinkID = linkID ? linkID + '/' : '';
    }
    let url = `${window.location.origin}${Routes.routePrefix}/run/${appID}/${currLinkID}${currentPageID}`;
    return url;
  };

  const handleSelectProjectPage = (checkedKeys: string[]) => {
    setCheckedProjectKeys(checkedKeys);
  };

  const handleCopyPreviewURL = (url: string, pswd: string, isEnablePass: boolean) => {
    const { onMsgPopup } = props;
    let result = '';
    // 飞书客户端
    if (larkPC) {
      const code = encodeURIComponent(`pages/common/index?otherUrl=${url}&isPreview=true`);
      const larkAppId = i18n('lark.larkAppID');
      result = `${i18n('lark.previewPrompt')}\r
      ${i18n('lark.openInLark')}${larkAppLink}/client/mini_program/open?appId=${larkAppId}&mode=window&path=${code}\r
      ${i18n('lark.openInBrowser')}${url}`;
      result = `${result}${getUrlSplitChar(url)} ${i18n('preview.shareProjectName', props.app.name)}`;
      if (isEnablePass) {
        result += `\r${i18n('preview.pwdText', pswd)}`;
      }
    } else {
      const linkText = `${url}${getUrlSplitChar(url)} ${i18n('preview.shareProjectName', props.app.name)}`;
      const pawdText = i18n('preview.pwdText', pswd);
      result = linkText + (isEnablePass ? `，${pawdText}` : '');
    }
    const flag = copy(result);
    if (flag) {
      onMsgPopup(i18n('share.copySuccess'), 1000);
    }
  };

  const handleNavigationBarMouseOut = () => {
    if (!tempRef.current.isAutoHideNavPanel) {
      return;
    }
    if (!tempRef.current.shareVisible && !tempRef.current.moreVisible && !tempRef.current.scaleVisible) {
      setPanelVisible(false);
      tempRef.current.panelVisible = false;
    }
  };

  const handleFullscreen = () => {
    const newValue = !tempRef.current.isFullScreen;
    if (newValue) {
      openFullScreen();
    } else {
      exitFullScreen();
    }
    props.onFullScreen(newValue);
  };

  const handleMorePopupChange = (visible: boolean) => {
    setMoreVisible(visible);
    tempRef.current.moreVisible = visible;
  };

  const handleSharePopupChange = (visible: boolean) => {
    //全部页面隐藏需要提示
    if (isPageAllEmpty) {
      return setShowAllPageHasHideDialog(true);
    }
    setShareVisible(visible);
    tempRef.current.shareVisible = visible;
  };

  const handleTXMeetingPopupChange = () => {
    setTxMeetingShareVisible(true);
  };

  const handleScaleDropDownVisible = (visible: boolean) => {
    tempRef.current.scaleVisible = visible;
  };

  const renderScaleValue = (item: IListItem | null) => {
    if (scale && item) return <span>{item.text}</span>;
    return <span>{`${Math.round(props.scale || 0)}%`}</span>;
  };

  const renderIconOperate = (
    key: keyof typeof IconList,
    isActive?: boolean,
    clickFun?: React.MouseEventHandler<HTMLSpanElement>,
    needHoverChangeColor = true,
  ) => {
    const item = IconList[key] as IconItem;
    const icon = item.icon;
    const tip = isActive ? item.activeTipKey : item.tipKey;
    return (
      <div className="navigation-pannel-icon-container">
        <span
          className={classNames(
            'navigation-pannel-icon',
            isActive && 'navigation-pannel-icon--active',
            needHoverChangeColor && 'navigation-pannel-icon--hover',
          )}
        >
          <Icon cls={icon} size={16} tips={tip} className="icon-inner" onClick={clickFun} />
          {key === 'share' && isInWeMeet() && txMeetingShareVisible && (
            <ShareSelect
              shareMock={() => {
                handleSharePopupChange(true);
              }}
              onClose={() => {
                setTxMeetingShareVisible(false);
              }}
              getInvitationLink={async () => {
                return getRunUrl();
              }}
            />
          )}
        </span>
      </div>
    );
  };

  const renderAllPageHasHideDialog = () => {
    if (!showAllPageHasHideDialog) {
      return null;
    }
    return (
      <ConfirmDialog
        onClose={() => setShowAllPageHasHideDialog(false)}
        onSubmit={() => setShowAllPageHasHideDialog(false)}
        title={i18n('tips.hideAllPageTip')}
        submitText={i18n('general.gotIt')}
        className="application-all-page-hide-dialog"
      ></ConfirmDialog>
    );
  };

  // more按钮
  const renderMoreBtn = () => {
    return (
      <Popup
        placement={Placement.TopRight}
        overlay={<MoreDialog option={props.option} onOptionChange={props.onOptionChange} appType={props.appType} />}
        popupWidth={280}
        offset={[4, 8]}
        onPopupVisible={handleMorePopupChange}
        visible={moreVisible}
      >
        {renderIconOperate('more', moreVisible)}
      </Popup>
    );
  };

  const renderAreaBar = () => {
    return (
      <div className="area-bars">
        {renderHistory()}
        {isMockRPD && renderIconOperate('refresh', false, handleRefresh)}
        {/** iframe嵌套不显示全屏按钮 */}
        {window.top === window.self && renderIconOperate('fullScreen', false, handleFullscreen)}
        {renderIconOperate('leftPanel', isControlPanelExpand, handleControllerPanelCollapseChange, false)}
        {renderIconOperate('rightPanel', isRemarkPanelExpand, handleRemarkPanelCollapseChange, false)}
        {/* cckl-2579:飞书中的RP演示界面去掉导航栏中的退出按钮 */}
        {/* {larkPC && !isShareFrame && renderIconOperate('import', false, handleExit)} */}
      </div>
    );
  };

  /**
   * 分享策略变更提示弹窗
   * @returns
   */
  const renderShareChangeTipDialog = () => {
    const showTip = appOptions.showShareChangeTip;
    if (!showTip || !getQueryString('as') || isMockRPD) {
      return null;
    }

    return (
      <ShareChangeTipDialog
        onClose={() => {
          appOptions.showShareChangeTip = false;
          setShowShareChangeTip(false);
          tempRef.current.showShareChangeTip = false;
        }}
      ></ShareChangeTipDialog>
    );
  };

  const renderPanel = () => {
    return (
      <div className="preview-navigation-bar" onMouseLeave={handleNavigationBarMouseOut} ref={barRef}>
        <Select
          className="navigation-bar-select"
          theme="light"
          data={ScaleList}
          placement="left"
          itemRender={(item) => (
            <div className="popup-item">
              <span>{item.text}</span>
              {item.shortCut}
            </div>
          )}
          dropDownClassName="preview-navigation-scale-popup"
          maxRowCount={8}
          selectedIndex={ScaleList.findIndex((item) => item.id === scale)}
          onSelect={handleScaleChange}
          render={renderScaleValue}
          onDropDownVisible={handleScaleDropDownVisible}
        />
        <div className="divider" key="divider1" />
        {renderAreaBar()}
        <div className="divider" key="divider2" />
        {props.userInfo?.id &&
          !isOfflineDemo &&
          !isShareFrame &&
          //二次打开分享的页面,不允许再次分享CCKL-2162
          !currentLinkId &&
          renderIconOperate(
            'share',
            shareVisible,
            isInWeMeet() ? handleTXMeetingPopupChange : () => handleSharePopupChange(true),
          )}
        {renderMoreBtn()}
      </div>
    );
  };

  return (
    <div className="preview-navigation-panel">
      {!props.shouldHideNavPanel && (
        <Transition
          visible={!isAutoHideNavPanel || panelVisible}
          enterClassName="preview-navigation-bar--enter"
          leaveClassName="preview-navigation-bar--leave"
        >
          {renderPanel()}
        </Transition>
      )}
      {shareVisible && (
        <ShareRunDialog
          app={props.app}
          appID={props.appID}
          currentPageID={props.pageID}
          runUrl={getRunUrl()}
          closeShareRun={() => handleSharePopupChange(false)}
          onCopyPreviewURL={handleCopyPreviewURL}
          onSelectProjectPage={handleSelectProjectPage}
          option={props.option}
          controlPanelState={props.controlPanelState}
          remarkPanelState={props.remarkPanelState}
          onMsgPopup={props.onMsgPopup}
        />
      )}
      {!isShareFrame && !isStandalone && showShareChangeTip && renderShareChangeTipDialog()}
      <div className="hide-zoomer">
        <Zoomer theme="light" value={props.scale} canInput={false} onZoom={handleZoomChange} />
      </div>
      {renderAllPageHasHideDialog()}
    </div>
  );
};

export default React.memo(NavigationPanel);
