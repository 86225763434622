export interface IItemProps {
  id: string;
  name?: string;
  pinyinName?: string;
  email: string;
  imageURL?: string;
  disabled?: boolean;
  isGroup?: boolean;
  thirdPartyUUID?: string;
}

export interface IGroupProps {
  id: string;
  name: string;
  children: IItemProps[];
  expand?: boolean;
}

export type UITheme = 'blue' | 'red';

export {
  ITableColumn,
  IColumnType,
  ITableData,
  ITableColumnAlign,
  IPoint,
  TableSelection,
  ITableSort,
  ITableSortOrder,
  ISortIcon,
  ICompareRefs,
} from './table';

export { IPosition } from './style';

export { IPager, IPagerSelectAction } from './pager';
