// 元素节点，属性将返回 1。
// 属性节点，属性将返回 2。
// 文本节点，属性将返回 3。

import { hasTextContent } from './copyStyleItemRuler';
import { hasInputValue } from './replacer';

// 注释节点，属性将返回 8。
/**
 * filter作用在源节点上，不能修改，只能过滤
 * @param node
 * @returns
 */
export const filter = (node: Element) => {
  if (!node) {
    return false;
  }
  if (node.nodeType === 8 || node.nodeType === 2) {
    return false;
  }
  // 文本
  if (node.nodeType === 3) {
    return true;
  }

  // 输入框
  // fixme: 转文字
  if (/input|textarea/i.test(node.tagName) && !hasInputValue(node)) {
    return false;
  }

  const className = node.className;
  // 输入框占位
  if (/input-placeholder|placeholder-text/.test(className) && !hasTextContent(node)) {
    return false;
  }

  // 选中框 selecting show 图标
  if (/icon-background|selecting show|no-tracker/.test(className)) {
    return false;
  }

  return true;
};
