import * as React from 'react';
import { MouseButtons } from '@/consts/enums/mouseButton';
import { isInputting } from '@utils/globalUtils';

export interface IAutoCloseComponentProps {
  forwardedRef?: React.RefObject<HTMLDivElement>;
}

export interface IAutoCloseProps {
  onClose: Function;
  triggerCloseContainer?: HTMLElement;
}

export interface IPopupComponent extends React.Component<{ onClose: Function } & any> {
  readonly isModal: boolean;
  preventClose?: boolean;
}

export namespace PopupManager {
  class Manager {
    private popupPanel: IPopupComponent[] = [];

    private _locked: boolean = false;

    private _canClose: boolean = true;

    locked() {
      this._locked = true;
    }

    /**
     * 关闭最上层一个面板，不考虑是否非模态
     */
    closeLastPopup() {
      if (isInputting()) {
        return;
      }
      if (this._locked) {
        this._locked = false;
        return;
      }
      let popup: IPopupComponent | undefined = undefined;
      for (let i = this.count - 1; i >= 0; i--) {
        if (!this.popupPanel[i].preventClose) {
          popup = this.popupPanel[i];
        }
      }

      if (popup && popup.props.onClose) {
        popup.props.onClose();
      }
    }

    /**
     * 关闭所有弹出面板
     */
    closeAll() {
      this.popupPanel.forEach((pop) => {
        if (!pop.preventClose && pop.props.onClose) {
          pop.props.onClose();
        }
      });
    }

    /**
     * 关闭所有非模态面板
     */
    closeAllUnModal() {
      const panels = this.popupPanel.filter((pop) => !pop.isModal && !pop.preventClose);
      while (panels.length) {
        const panel = panels.pop();
        if (panel && panel.props.onClose) {
          panel.props.onClose();
        }
      }
    }

    /**
     * 获取最上层的模态面板
     * @returns {IPopupComponent|null}
     */
    getLastModal(): IPopupComponent | null {
      for (let i = this.popupPanel.length - 1; i >= 0; i--) {
        if (this.popupPanel[i].isModal) {
          return this.popupPanel[i];
        }
      }
      return null;
    }

    getLastPanel() {
      const len = this.popupPanel.length;
      if (len) {
        return this.popupPanel[len - 1];
      }
      return null;
    }

    isLast(panel: IPopupComponent) {
      const last = this.popupPanel[this.count - 1];
      return panel === last;
    }

    get count(): number {
      return this.popupPanel.length;
    }

    get modalCount(): number {
      return this.popupPanel.filter((p) => p.isModal).length;
    }

    get canClose(): boolean {
      return this._canClose;
    }

    set canClose(canClose: boolean) {
      this._canClose = canClose;
    }

    addPopup(popup: IPopupComponent) {
      if (!this.popupPanel.includes(popup)) {
        this.popupPanel.push(popup);
      }
    }

    remove(popup: IPopupComponent) {
      const index = this.popupPanel.indexOf(popup);
      if (index !== -1) {
        this.popupPanel.splice(index, 1);
      }
    }
  }

  export const manager = new Manager();
}

/**
 * 自动关闭高阶组件，传入的WrappedComponent的属性必须从IAutoCloseComponentProps继承
 * @param  WrappedComponent
 * @returns {Component}
 */
export default function withAutoClose<P extends IAutoCloseComponentProps>(WrappedComponent: React.ComponentClass<P>) {
  return class Inner extends React.Component<P & IAutoCloseProps> implements IPopupComponent {
    private readonly domRef: React.RefObject<HTMLElement>;
    private readonly wrappedRef: React.RefObject<any>;
    private timeID?: Timeout;

    constructor(props: IAutoCloseProps & P) {
      super(props);
      this.domRef = React.createRef();
      this.wrappedRef = React.createRef();
    }

    readonly isModal: boolean = false;

    componentDidMount(): void {
      // 不要立即开始监听事件，会造成打开后，立即就消失了
      PopupManager.manager.addPopup(this);
      setTimeout(() => {
        window.addEventListener('mousedown', this.onWindowMouseDown, { capture: true });
        window.addEventListener('keydown', this.onWindowKeyDown, false);
      }, 1);
    }

    componentWillUnmount(): void {
      window.removeEventListener('mousedown', this.onWindowMouseDown, { capture: true });
      window.removeEventListener('keydown', this.onWindowKeyDown, false);
      PopupManager.manager.remove(this);
      window.clearTimeout(this.timeID);
    }

    private isLast?: boolean;

    doClose = () => {
      const panel = this as IPopupComponent;
      const preventClose = panel.preventClose;
      if (this.props.onClose && this.isLast && !preventClose) {
        this.props.onClose();
      }
    };

    onWindowKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        this.timeID = window.setTimeout(this.doClose, 0);
      }
    };

    onWindowMouseDown = (e: MouseEvent) => {
      try {
        // e.button !== 2 解决firefox打开直接关闭的问题
        if (this.props.triggerCloseContainer) {
          const bounds = this.props.triggerCloseContainer.getBoundingClientRect();
          if (bounds.left > e.pageX || bounds.right < e.pageX || bounds.top > e.pageY || bounds.bottom < e.pageY) {
            return;
          }
        }
        if (this.domRef.current && e.buttons === MouseButtons.Left) {
          // 这里采用异步关闭的方式，为了跟打开菜单的地方避免冲突
          // 如果先关闭了，会造成打开菜单的点击以为菜单没有打开，又重新打开
          if (!this.domRef.current.contains(e.target as HTMLElement)) {
            this.isLast = PopupManager.manager.isLast(this);
            if (this.isLast && !(this as IPopupComponent).preventClose) {
              this.timeID = window.setTimeout(() => {
                PopupManager.manager.canClose && this.doClose();
              }, 20);
            }
          } else {
            this.isLast = true;
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    render() {
      return <WrappedComponent {...this.props} forwardedRef={this.domRef} ref={this.wrappedRef} />;
    }
  };
}
