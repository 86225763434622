import { getNewID } from '@helpers/idHelper';
import { ITreeDataItem, TreeIconMode } from '@fbs/rp/models/value';
import { DefaultCollapseIconType } from '@fbs/rp/models/properties/tree';

import i18n from '@i18n';

//默认行高
export const defaultLineHeight: number = 30;
//默认缩进
export const defaultIndent: number = 16;
//文本与折叠图标的间距
export const textAndIconSpace: number = 16;
//文本左间距
export const textLeftSpace: number = 12;
//水平导航菜单子与父的垂直间距
export const itemVerticalSpace: number = 8;
//导航菜单子与父的水平间距
export const itemHorizontalSpace: number = 4;
//标识条大小
export const markerStripSize: number = 4;
//水平垂直导航菜单item上下内边距
export const menuItemTopOrBottomPadding: number = 6;
// //占位矩形的宽度
// export const placeHolderWidth = 1;
// //图形之间的间距
// export const iconSpace = 5;
// //展开折叠的图标大小
// export const expandSize = 10;

// 默认插入最大层级
export const MaxInsertLevel = 5;

//====默认展开折叠图标ICon=====
export const defaultSelectIcon = [
  {
    id: DefaultCollapseIconType.Arrow,
    text: i18n('property.component.tree.arrow'),
    value: [
      { iconCode: 60570, fontName: 'boldIconFont' },
      { iconCode: 60571, fontName: 'boldIconFont' },
    ],
  },
  {
    id: DefaultCollapseIconType.Plus,
    text: i18n('property.component.tree.plus'),
    value: [
      { iconCode: 60567, fontName: 'boldIconFont' },
      { iconCode: 60568, fontName: 'boldIconFont' },
    ],
  },
  {
    id: DefaultCollapseIconType.Triangle,
    text: i18n('property.component.tree.triangle'),
    value: [
      { iconCode: 60569, fontName: 'boldIconFont' },
      { iconCode: 60566, fontName: 'boldIconFont' },
    ],
  },
];

export const defaultExpandIcon = {
  id: DefaultCollapseIconType.Arrow,
  iconMode: 'font',
  expand: {
    icon: { iconCode: 60570, fontName: 'boldIconFont' },
  },
  collapse: {
    icon: { iconCode: 60571, fontName: 'boldIconFont' },
  },
};

export const getDefaultNavigationMenuData = (): ITreeDataItem[] => [
  {
    id: getNewID(),
    index: 0,
    expand: true,
    nodeIcon: {
      iconMode: 'font',
      expand: {
        icon: { iconCode: 60321, fontName: 'lightIconFont' },
      },
      collapse: {
        icon: { iconCode: 60472, fontName: 'lightIconFont' },
      },
    },
    children: [
      {
        id: getNewID(),
        index: 0,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
      {
        id: getNewID(),
        index: 1,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
      {
        id: getNewID(),
        index: 2,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
    ],
  },
  {
    id: getNewID(),
    index: 1,
    expand: false,
    nodeIcon: {
      iconMode: 'font',
      expand: {
        icon: { iconCode: 60291, fontName: 'lightIconFont' },
      },
      collapse: {
        icon: { iconCode: 60472, fontName: 'lightIconFont' },
      },
    },
    children: [
      {
        id: getNewID(),
        index: 0,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
      {
        id: getNewID(),
        index: 1,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
      {
        id: getNewID(),
        index: 2,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
    ],
  },
  {
    id: getNewID(),
    index: 2,
    expand: false,
    nodeIcon: {
      iconMode: 'font',
      expand: {
        icon: { iconCode: 60639, fontName: 'lightIconFont' },
      },
      collapse: {
        icon: { iconCode: 60472, fontName: 'lightIconFont' },
      },
    },
    children: [
      {
        id: getNewID(),
        index: 0,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
      {
        id: getNewID(),
        index: 1,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
      {
        id: getNewID(),
        index: 2,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
    ],
  },
  {
    id: getNewID(),
    index: 3,
    expand: true,
    nodeIcon: {
      iconMode: 'font',
      expand: {
        icon: { iconCode: 60710, fontName: 'lightIconFont' },
      },
      collapse: {
        icon: { iconCode: 60472, fontName: 'lightIconFont' },
      },
    },
    children: [],
  },
];

export const getDefaultVerticalMenuData = (): ITreeDataItem[] => [
  {
    id: getNewID(),
    index: 0,
    expand: true,
    nodeIcon: {
      iconMode: 'font',
      expand: {
        icon: { iconCode: 60321, fontName: 'lightIconFont' },
      },
      collapse: {
        icon: { iconCode: 60472, fontName: 'lightIconFont' },
      },
    },
    children: [
      {
        id: getNewID(),
        index: 0,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
      {
        id: getNewID(),
        index: 1,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
      {
        id: getNewID(),
        index: 2,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
    ],
  },
  {
    id: getNewID(),
    index: 1,
    expand: false,
    nodeIcon: {
      iconMode: 'font',
      expand: {
        icon: { iconCode: 60291, fontName: 'lightIconFont' },
      },
      collapse: {
        icon: { iconCode: 60472, fontName: 'lightIconFont' },
      },
    },
    children: [
      {
        id: getNewID(),
        index: 0,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
      {
        id: getNewID(),
        index: 1,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
      {
        id: getNewID(),
        index: 2,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
    ],
  },
  {
    id: getNewID(),
    index: 2,
    expand: false,
    nodeIcon: {
      iconMode: 'font',
      expand: {
        icon: { iconCode: 60639, fontName: 'lightIconFont' },
      },
      collapse: {
        icon: { iconCode: 60472, fontName: 'lightIconFont' },
      },
    },
    children: [
      {
        id: getNewID(),
        index: 0,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
      {
        id: getNewID(),
        index: 1,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
      {
        id: getNewID(),
        index: 2,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
    ],
  },
  {
    id: getNewID(),
    index: 3,
    expand: true,
    nodeIcon: {
      iconMode: 'font',
      expand: {
        icon: { iconCode: 60710, fontName: 'lightIconFont' },
      },
      collapse: {
        icon: { iconCode: 60472, fontName: 'lightIconFont' },
      },
    },
    children: [],
  },
];

export const getDefaultHorizontalMenuData = (): ITreeDataItem[] => [
  {
    id: getNewID(),
    index: 0,
    expand: true,
    nodeIcon: {
      iconMode: 'font',
      expand: {
        icon: { iconCode: 60321, fontName: 'lightIconFont' },
      },
      collapse: {
        icon: { iconCode: 60472, fontName: 'lightIconFont' },
      },
    },
    children: [
      {
        id: getNewID(),
        index: 0,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
        children: [
          {
            id: getNewID(),
            index: 0,
            expand: true,
            nodeIcon: {
              iconMode: 'font',
              expand: {
                icon: { iconCode: 60433, fontName: 'lightIconFont' },
              },
              collapse: {
                icon: { iconCode: 60472, fontName: 'lightIconFont' },
              },
            },
          },
          {
            id: getNewID(),
            index: 1,
            expand: true,
            nodeIcon: {
              iconMode: 'font',
              expand: {
                icon: { iconCode: 60433, fontName: 'lightIconFont' },
              },
              collapse: {
                icon: { iconCode: 60472, fontName: 'lightIconFont' },
              },
            },
          },
          {
            id: getNewID(),
            index: 2,
            expand: true,
            nodeIcon: {
              iconMode: 'font',
              expand: {
                icon: { iconCode: 60433, fontName: 'lightIconFont' },
              },
              collapse: {
                icon: { iconCode: 60472, fontName: 'lightIconFont' },
              },
            },
          },
        ],
      },
      {
        id: getNewID(),
        index: 1,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
      {
        id: getNewID(),
        index: 2,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
    ],
  },
  {
    id: getNewID(),
    index: 1,
    expand: false,
    nodeIcon: {
      iconMode: 'font',
      expand: {
        icon: { iconCode: 60291, fontName: 'lightIconFont' },
      },
      collapse: {
        icon: { iconCode: 60472, fontName: 'lightIconFont' },
      },
    },
    children: [
      {
        id: getNewID(),
        index: 0,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
      {
        id: getNewID(),
        index: 1,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
      {
        id: getNewID(),
        index: 2,
        expand: true,
        nodeIcon: {
          iconMode: 'font',
          expand: {
            icon: { iconCode: 60433, fontName: 'lightIconFont' },
          },
          collapse: {
            icon: { iconCode: 60472, fontName: 'lightIconFont' },
          },
        },
      },
    ],
  },
  {
    id: getNewID(),
    index: 2,
    expand: true,
    nodeIcon: {
      iconMode: 'font',
      expand: {
        icon: { iconCode: 60639, fontName: 'lightIconFont' },
      },
      collapse: {
        icon: { iconCode: 60472, fontName: 'lightIconFont' },
      },
    },
    children: [],
  },
];

export const newRelationData = {
  id: '',
  index: 0,
  expand: true,
  nodeIcon: {
    iconMode: 'font' as TreeIconMode,
    expand: {
      icon: { iconCode: 60433, fontName: 'lightIconFont' },
    },
    collapse: {
      icon: { iconCode: 60472, fontName: 'lightIconFont' },
    },
  },
};
