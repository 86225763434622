import * as React from 'react';

import { withAutoClose, IAutoCloseComponentProps } from '@dsm';

import i18n from '@i18n';

import './index.scss';

interface IBooleanEditorProps extends IAutoCloseComponentProps {
  value: boolean;
  position: { left: number; top: number };
  size: { width: number; height: number };
  onClose(): void;
  onChange: (value: boolean) => void;
}

class BooleanEditor extends React.Component<IBooleanEditorProps> {
  onClick = () => {
    this.props.onChange(!this.props.value);
  };
  render() {
    const style: React.CSSProperties = Object.assign({}, this.props.position, this.props.size);
    return (
      <div ref={this.props.forwardedRef} onClick={this.onClick} className="boolean-editor" style={style}>
        <span className="tips">{i18n('tips.clickCompnentToSwitchState')}</span>
      </div>
    );
  }
}

export default withAutoClose<IBooleanEditorProps>(BooleanEditor);
