import * as React from 'react';
import * as ReactDom from 'react-dom';

import Header from './Header';
import Main from './Main';
import EmptyDialog from '../EmptyDialog';

import { IRPFreeProgramInvitationRecord } from '../../models/rpFreeProgram';

import './index.scss';

interface IRPFreeProgramProps {
  children?: React.ReactChild;
  // 已经邀请了的成员数量
  invitedCount: number;
  // 是否已经领取了无限包
  hasGotPackage: boolean;
  // 邀请链接的code
  inviteCode: string;
  // 团队名称
  teamName: string;
  role?: string;
  // 是否是协同人 协同人不可邀请成员，需弹窗提示
  isRoleGuest: boolean;
  // 所有的邀请记录
  allInvitation: IRPFreeProgramInvitationRecord[];
  // 是否是新的rp活动策略（默认只送一个月）
  isNewRpActivity?: boolean;
  // 到期时间（如果没有领过，就没有到期时间）
  expireTime?: Date;

  domain?: string;

  onCancel?(): void;
  getInviteLink(): void;
  sendInvitation(emails: string[], token: string): void;
  // 查看所有邀请记录
  viewAllInvitation(): void;
  // 返回活动主页（刷新活动领取进度）
  onBackMain(): void;
  // 获取客服二维码
  getCustomerServiceQR(cb: Function): void;
  // 保存当前客服二维码
  setCustomerServiceQR(url: string): void;
  // 获取最近获取到终身无限设计包的十个团队
  getReceivedForeverRecord(cb: Function): void;
}

const RPFreeProgram: React.FC<IRPFreeProgramProps> = (props: IRPFreeProgramProps) => {
  const {
    children,
    invitedCount,
    hasGotPackage,
    inviteCode,
    teamName,
    isRoleGuest,
    allInvitation,
    isNewRpActivity,
    expireTime,
    onCancel,
    getInviteLink,
    sendInvitation,
    viewAllInvitation,
    onBackMain,
    getCustomerServiceQR,
    setCustomerServiceQR,
    getReceivedForeverRecord,
  } = props;

  const renderProgramDialog = () => {
    return ReactDom.createPortal(
      <div className="rp-free-dialog-wrapper">
        <EmptyDialog contentOverflow="hidden" width={720} height={600} onCancel={onCancel}>
          <div className="rp-free-dialog-content">
            <Header />
            <Main
              invitedCount={invitedCount}
              hasGotPackage={hasGotPackage}
              inviteCode={inviteCode}
              teamName={teamName}
              isRoleGuest={isRoleGuest}
              allInvitation={allInvitation}
              isNewRpActivity={!!isNewRpActivity}
              expireTime={expireTime}
              domain={props.domain}
              role={props.role}
              getInviteLink={getInviteLink}
              sendInvitation={sendInvitation}
              viewAllInvitation={viewAllInvitation}
              onBackMain={onBackMain}
              getCustomerServiceQR={getCustomerServiceQR}
              setCustomerServiceQR={setCustomerServiceQR}
              getReceivedForeverRecord={getReceivedForeverRecord}
            />
          </div>
        </EmptyDialog>
      </div>,
      document.body,
    );
  };

  if (children) {
    return (
      <div className="rp-free-program-wrapper">
        {children}
        {renderProgramDialog()}
      </div>
    );
  }

  return renderProgramDialog();
};

RPFreeProgram.defaultProps = {
  invitedCount: 0,
  hasGotPackage: false,
  isRoleGuest: false,
};

export default RPFreeProgram;
