import { uniqueId } from 'lodash';
import { ISize } from '@/utils/boundsUtils';
import i18n from '@/i18n';
import { fetchImageBlob } from '../image/fetchImage';
import { isDataUrl, getImageSize } from '../util/imageUtil';
import { blobToBase64 } from '../util/blobUtil';
import { ICompInfo } from '../type';
import { exceptionCollector } from '../exception';

const reg = /background[^<>:]*?:url\(['"]?([^'"]*?)['"]?\)|<img[^<>]*?src=['"]([^'"]*?)['"][^<]*?>|<image[^<>]*?href="([^'"]*?)"[^<]*?>/gi;
// 匹配小括号的个数
const NumberOfMatchParentheses = 3;

export const handleComponentImage = async (html: string, compInfo?: ICompInfo): Promise<string> => {
  return await replaceImages(html, reg, compInfo);
};

const addSet = (set: Set<string>, a: string, ms: string[]) => {
  ms.slice(0, NumberOfMatchParentheses)
    .filter((t) => !!t)
    .forEach((m) => {
      if (isDataUrl(m)) {
        return a;
      }
      set.add(m);
    });
};

interface imageItem {
  uri: string;
  url: string;
  size?: ISize;
}
type SizeProps = 'width' | 'height';
const replaceUri = (images: imageItem[], a: string, ms: string[]) => {
  return ms
    .slice(0, NumberOfMatchParentheses)
    .filter((t) => !!t)
    .map((m) => {
      if (isDataUrl(m)) {
        return a;
      }
      const info = images.find((t) => t.url === m) ?? {
        uri: '',
        size: null,
      };
      const { uri, size } = info;
      if (size) {
        a = replaceSize(a, size);
      }
      return a.replace(m, uri);
    })
    .join('');
};
/**
 * 替换图片大小
 * @param str
 * @param size
 */
const replaceSize = (str: string, size: ISize): string => {
  return str.replace(/\$\{(.*?)\}/g, (a: string, key: string) => {
    return ['width', 'height'].includes(key) ? String(size[key as SizeProps] ?? '') : a;
  });
};

const replaceImages = async (html: string, reg: RegExp, compInfo?: ICompInfo): Promise<string> => {
  const set = new Set<string>();
  let hasError = false;
  html.replace(reg, (a, ...ms) => {
    addSet(set, a, ms);
    return '';
  });
  const processer = Array.from(set).map(async (t) => {
    // fixme: 默认图片地址出现跨域 ，临时方案
    let url = t;
    if (t.indexOf('assets.mockplus.cn') > 0) {
      url = t + '?uid=' + uniqueId();
    }
    try {
      const blob = await fetchImageBlob(url);
      const uri = await blobToBase64(blob);
      // 获取图片大小
      const size = await getImageSize(uri);
      return {
        uri,
        url: t,
        size,
      };
    } catch (e) {
      // 收集异常信息
      let msg = `${compInfo?.name ?? i18n('workspace.artboard.downloadImageError')}`;
      if (compInfo?.pageName) {
        msg = `${compInfo?.pageName} -` + msg;
      }
      // 组件内多张图片，只提示1条
      if (!hasError) {
        exceptionCollector.add(msg);
        hasError = true;
      }

      return {
        uri: '',
        url: t,
      };
    }
  });
  const images = await Promise.all(processer);
  const result = html.replace(reg, (a, ...ms) => {
    return replaceUri(images, a, ms);
  });

  return result;
};
