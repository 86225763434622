import * as React from 'react';

import classNames from 'classnames';

import i18n from '@/i18n';

import { Direction } from '@/consts/enums/direction';

import { Icon } from '@/dsm';

import { IAreaStyle } from '../..';

interface ICropBoxProps {
  isSpaceKey?: boolean;
  imageBounds?: IAreaStyle;
  showCropArea?: boolean;
  cropBounds?: IAreaStyle;
  cropRef: React.RefObject<HTMLDivElement>;
  viewPortSize: { width: number; height: number };

  onDoCalcPercent?: (num: number) => string;
  onCropAreaMouseDown?: (e: React.MouseEvent) => void;
  onDragPointsMouseDown?: (type: Direction, e: React.MouseEvent) => void;
  onResetCropArea?: () => void;
}

export default React.memo(function CropBox(props: ICropBoxProps) {
  const {
    showCropArea,
    cropBounds,
    isSpaceKey,
    cropRef,
    imageBounds,
    viewPortSize,
    onDoCalcPercent,
    onCropAreaMouseDown,
    onResetCropArea,
    onDragPointsMouseDown,
  } = props;
  if (!showCropArea) {
    return null;
  }
  const { width, height, left, top } = cropBounds!;

  const cropBoundsPercent = {
    width: onDoCalcPercent && onDoCalcPercent(width),
    height: onDoCalcPercent && onDoCalcPercent(height),
    left: onDoCalcPercent && onDoCalcPercent(left),
    top: onDoCalcPercent && onDoCalcPercent(top),
  };

  const isOut = (width + left) * (imageBounds?.width || 0) + (imageBounds?.left || 0) > (viewPortSize?.width || 0) - 30;

  return (
    <div
      className={classNames('crop-area', { 'drag-move': isSpaceKey })}
      ref={cropRef}
      style={cropBoundsPercent}
      onMouseDown={onCropAreaMouseDown}
    >
      {[Direction.WN, Direction.EN, Direction.ES, Direction.WS].map((key) => {
        return (
          <span
            key={key}
            className={classNames('crop-controller-point', key)}
            onMouseDown={(e) => {
              onDragPointsMouseDown && onDragPointsMouseDown(key, e);
            }}
          />
        );
      })}
      {!isSpaceKey && (
        <div className={classNames('crop-area-confirm', { out: isOut })} onClick={onResetCropArea}>
          <Icon cls="demo_delete" tips={i18n('resource.componentsText.resetCropArea')} theme="button" />
        </div>
      )}
    </div>
  );
});
