import * as React from 'react';
import * as _ from 'lodash';
import classNames from 'classnames';

import { parseColorToString } from '@utils/graphicsUtils';
import { depthClone, isEqualDate } from '@utils/globalUtils';

import { ITableValue, ITableCell, CellTypes, CellBorder } from '@fbs/rp/models/table';
import IStroke, { PresetDashModel } from '@fbs/rp/models/properties/stroke';
import ITableLine from '@/fbs/rp/models/properties/table';

import { StyleHelper } from '@helpers/styleHelper';
import * as tableHelper from '@helpers/tableHelper';
import { ICellPosition } from '@helpers/tableHelper';
import { hasInteraction } from '@helpers/interactionHelper';
import AppOptions from '@helpers/appOptions';

import { UITableComponent } from '@editor/comps';
import { UIComponent, UIContainerComponent } from '@editor/comps';

import { DefalutTableStroke, DefaultCellPadding } from '@consts/defaultData/table';

import { IComponentProps } from '../../types';
import Component from '../../../libs';

import TableEditor from '../../basic/common/TableEditor';

import './index.scss';

interface ITableState {
  value: ITableValue;
  hidden?: boolean;
}

export default class Table extends React.Component<IComponentProps, ITableState> {
  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      value: props.comp.value as ITableValue,
    };
  }

  UNSAFE_componentWillReceiveProps(props: IComponentProps) {
    const value = props.comp.value as ITableValue;
    if (!isEqualDate(this.state.value, props.comp.value)) {
      this.setState({
        value,
      });
    }
  }

  /**
   * 隐藏表格
   */
  handleTableHiden = (value: boolean) => {
    if (this.state.hidden === value) {
      return;
    }
    this.setState({ hidden: value });
  };

  parserBorder = (tableBorder: IStroke) => {
    const { thickness, color, disabled } = tableBorder;
    if (disabled || !color) return 'none';
    return `${_.isUndefined(thickness) ? 1 : thickness}px ${PresetDashModel.solid} ${parseColorToString(color)}`;
  };

  parserBorderStyle = (cellBorder: CellBorder) => {
    const { left, right, top, bottom } = cellBorder;
    const border: React.CSSProperties = {};
    if (left.disabled) {
      border.borderLeft = 'none';
    } else {
      border.borderLeft = this.parserBorder(left);
    }
    if (right.disabled) {
      border.borderRight = 'none';
    } else {
      border.borderRight = this.parserBorder(right);
    }
    if (top.disabled) {
      border.borderTop = 'none';
    } else {
      border.borderTop = this.parserBorder(top);
    }
    if (bottom.disabled) {
      border.borderBottom = 'none';
    } else {
      border.borderBottom = this.parserBorder(bottom);
    }
    return border;
  };

  /**
   * 单元格合并情况边框处理
   */
  parserCellBorder = (position: ICellPosition, tableLine?: ITableLine) => {
    const { value } = this.state;
    const cell = tableHelper.findCell(value, position);
    if (!cell) return {};
    const borders = tableHelper.parserMergeBorder(value, cell, position, tableLine);
    return this.parserBorderStyle(borders);
  };

  renderCellContent(cellData: ITableCell, row: number, column: number) {
    const { type } = cellData;

    switch (type) {
      case CellTypes.Text:
      case CellTypes.CheckBox:
      case CellTypes.Radio:
      case CellTypes.Button: {
        const comp = (this.props.comp as UITableComponent).components.find(
          (comp) => comp.row === row && comp.column === column,
        );
        if (comp) {
          return this.renderIndicator(comp);
        } else {
          return null;
        }
      }
      default:
        break;
    }
  }

  renderChildComponent = (comp: UIComponent) => {
    if (comp.isContainer) {
      return (comp as UIContainerComponent).components.map((comp) => {
        return this.renderComponent(comp);
      });
    }
    return null;
  };

  renderComponent = (comp: UIComponent, scale: number = 1) => {
    const { event } = this.props;
    return (
      <Component
        key={comp.id}
        comp={comp}
        revision={comp.chainedVersion()}
        scale={scale}
        isPreview={this.props.isPreview}
        showInteract={false}
        {...event}
      >
        {this.renderChildComponent(comp)}
      </Component>
    );
  };

  renderIndicator(comp: UIComponent) {
    const { width, height } = comp.size;
    return (
      <div className="component-indicator">
        <div
          style={{
            width,
            height,
          }}
        >
          {this.renderComponent(comp)}
        </div>
      </div>
    );
  }

  // renderTrContent(cellData: ITableColumn) {
  //   const { type, data } = cellData;
  //   switch (type) {
  //     case CellTypes.Text:
  //       return data.text;
  //     default:
  //       break;
  //   }
  // }

  renderRows = () => {
    const { value } = this.state;
    return value.rows.map((item, i) => (
      <tr
        key={i}
        style={{
          height: item.height,
          overflow: 'hidden',
        }}
      >
        {this.renderCells(i)}
      </tr>
    ));
  };

  renderCells = (index: number) => {
    const {
      value: { cells, rows, columns },
    } = this.state;
    if (!cells[index]) return null;
    const { isPreview, showInteract, comp } = this.props;
    const tableComp = comp as UITableComponent;
    return cells[index].map((item, i) => {
      if (item.mergedBy) return null;
      const background = parseColorToString(item.style.fill || '#fff');
      const cellWidth = columns.slice(i, i + item.mergeAcross + 1).reduce((sum, c) => sum + c.width, 0);

      const tableCellComp = tableComp.getTableCellComp(index, i);
      let needShowInteraction = false;
      if (tableCellComp) {
        needShowInteraction = !isPreview && hasInteraction(tableCellComp) && false !== showInteract;
      }
      const showHotArea = isPreview && tableCellComp && hasInteraction(tableCellComp) && !comp.disabled;
      const cellInteractionEvents =
        isPreview && tableCellComp
          ? {
              onClick: (e: React.MouseEvent) => {
                this.props.event.onClick?.(e, tableCellComp);
              },
              onMouseDown: (e: React.MouseEvent) => {
                this.props.event.onMouseDown?.(e, tableCellComp);
              },
              onMouseUp: (e: React.MouseEvent) => {
                this.props.event.onMouseUp?.(e, tableCellComp);
              },
              onDoubleClick: (e: React.MouseEvent) => {
                this.props.event.onDoubleClick?.(e, tableCellComp);
              },
              onContextMenu: (e: React.MouseEvent) => {
                this.props.event.onContextMenu?.(e, tableCellComp);
              },
              onMouseEnter: (e: React.MouseEvent) => {
                this.props.event.onMouseEnter?.(e, tableCellComp);
              },
              onMouseLeave: (e: React.MouseEvent) => {
                this.props.event.onMouseLeave?.(e, tableCellComp);
              },
              onTouchStart: (e: React.TouchEvent) => {
                this.props.event.onTouchStart?.(e, tableCellComp);
              },
              onTouchEnd: (e: React.TouchEvent) => {
                this.props.event.onTouchEnd?.(e, tableCellComp);
              },
            }
          : {};
      return (
        <td
          style={{
            // size
            height: rows[index].height,
            // minWidth: cellWidth,
            width: cellWidth,
            background,
          }}
          key={i}
          colSpan={item.mergeAcross + 1}
          rowSpan={item.mergeDown + 1}
          className={`table-col-index-${i}`}
        >
          <div
            className={classNames('cell', {
              'item-interaction-flag': needShowInteraction,
            })}
            {...cellInteractionEvents}
            style={{
              justifyContent: tableHelper.textAlign2Flex(item.style.textAlign),
              alignItems: tableHelper.verticalAlign2Flex(item.style.verticalAlign),
              top: `${DefaultCellPadding}px`,
              left: `${DefaultCellPadding}px`,
              width: `calc(100% - ${DefaultCellPadding * 2}px)`,
              height: `calc(100% - ${DefaultCellPadding * 2}px)`,
              cursor: showHotArea ? 'pointer' : 'default',
            }}
          >
            {this.renderCellContent(item, index, i)}
            {showHotArea && <div className="hot-area"></div>}
          </div>
        </td>
      );
    });
  };

  renderThead = () => {
    const {
      value: { showHeader, columns, headerHeight },
    } = this.state;
    if (!showHeader) return null;
    return (
      <thead>
        <tr
          style={{
            height: headerHeight,
          }}
        >
          {columns.map((item, i) => {
            if (item.mergedBy) {
              return null;
            } else {
              const background = parseColorToString(item.style.fill || '#fff');
              return (
                <th
                  key={i}
                  colSpan={item.mergeAcross + 1}
                  rowSpan={item.mergeDown + 1}
                  style={{
                    height: headerHeight,
                    width: item.width,
                    background,
                  }}
                >
                  <div
                    className="cell"
                    style={{
                      justifyContent: tableHelper.textAlign2Flex(item.style.textAlign),
                      alignItems: tableHelper.verticalAlign2Flex(item.style.verticalAlign),
                      top: `${DefaultCellPadding}px`,
                      left: `${DefaultCellPadding}px`,
                      width: `calc(100% - ${DefaultCellPadding * 2}px)`,
                      height: `calc(100% - ${DefaultCellPadding * 2}px)`,
                    }}
                  >
                    {this.renderCellContent(item, -1, i)}
                  </div>
                </th>
              );
            }
          })}
        </tr>
      </thead>
    );
  };

  renderInnerBorder = () => {
    const { showHeader, headerHeight, columns, rows } = this.state.value;
    const tableLine = this.props.comp.properties?.tableLine;
    return (
      <table className="inner-table-stroke">
        <tbody>
          {showHeader && (
            <tr
              style={{
                height: headerHeight,
              }}
            >
              {columns.map((item, i) => {
                return (
                  <td
                    key={i}
                    style={{
                      width: item.width,
                      ...this.parserCellBorder({ row: -1, column: i }, tableLine),
                      // 四周不渲染
                      ...(i === 0 ? { borderLeft: 'none' } : {}),
                      ...(i === columns.length - 1 ? { borderRight: 'none' } : {}),
                      borderTop: 'none',
                    }}
                  />
                );
              })}
            </tr>
          )}
          {rows.map((row, index) => {
            return (
              <tr
                key={index}
                style={{
                  height: row.height,
                }}
              >
                {columns.map((item, i) => {
                  return (
                    <td
                      key={i}
                      style={{
                        width: item.width,
                        ...this.parserCellBorder({ row: index, column: i }, tableLine),
                        // 四周不渲染
                        ...(i === 0 ? { borderLeft: 'none' } : {}),
                        ...(i === columns.length - 1 ? { borderRight: 'none' } : {}),
                        ...(index === 0 && !showHeader ? { borderTop: 'none' } : {}),
                        ...(index === rows.length - 1 ? { borderBottom: 'none' } : {}),
                      }}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  render() {
    const { comp, isPreview, valueEditing, onTableValueChanged, onlySelected, selected } = this.props;
    const { properties } = comp;
    const { stroke } = properties;
    const thickness = stroke?.thickness;
    const strokeWidth = stroke?.disabled ? 0 : valueEditing ? 1 : _.isUndefined(thickness) ? 1 : thickness;
    const copyStroke = depthClone(stroke || DefalutTableStroke);
    copyStroke.thickness = strokeWidth;
    const { value, hidden } = this.state;
    const { width, height } = tableHelper.getTableSizeFromValue(value);
    const styleParser = StyleHelper.initCSSStyleParser(properties);
    // 缩放后，filter导致文本模糊，用box-shadow实现
    const shadow = styleParser.getShadowStyle(true);
    const scaleValue = AppOptions.scale;
    const scale = _.isNumber(scaleValue) ? scaleValue : 1;

    // const fill = svgParser.getFill(`${type}-fill-${id}`);
    const style: React.CSSProperties = {
      width,
      height,
      opacity: _.isUndefined(comp.opacity) ? 1 : comp.opacity / 100,
      transition: comp.getTransition(),
    };
    return (
      <div className="lib-comp-table" style={style}>
        {!hidden && (
          <>
            {/* <Fill
              size={comp.size}
              comp={comp}
              fill={fill}
              transition={comp.getTransition()}
            /> */}
            <table
              style={{
                width,
                height,
                ...shadow,
              }}
              className="table"
              cellPadding={0}
              cellSpacing={0}
              unselectable="on"
            >
              <colgroup>
                {value &&
                  value.columns.map((col, index) => {
                    return <col style={{ width: col.width + 'px' }} key={index} />;
                  })}
              </colgroup>
              {this.renderThead()}
              <tbody>{this.renderRows()}</tbody>
            </table>
            {this.renderInnerBorder()}
            {
              <div
                style={{
                  border: this.parserBorder(copyStroke),
                  top: 0,
                  left: 0,
                  width,
                  height,
                  boxSizing: 'border-box',
                }}
                className="outer-border"
              />
            }
          </>
        )}
        {!isPreview && onlySelected && selected && (
          <TableEditor
            compStyle={this.props.compStyle}
            value={depthClone(this.state.value)}
            scale={scale || 1}
            comp={comp as UITableComponent}
            doTablehidden={this.handleTableHiden}
            onChangesSubmit={onTableValueChanged}
          />
        )}
      </div>
    );
  }
}
