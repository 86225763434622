import * as React from 'react';
import * as ReactDom from 'react-dom';

import * as _ from 'lodash';

import { Icon, ScrollBars } from '@/dsm';

import KeyCodeMap from '@/dsm2/constants/KeyCodeMap';
import { isControlKeyPressed } from '@/utils/hotkeysUtils';
import { IBounds } from '@/fbs/rp/models/value';

import './index.scss';

interface ISnapshotPreviewProps {
  url?: string;
  hasCropped?: boolean;
  cropAreaInfo?: IBounds;
  imageStyles: React.CSSProperties;
  onClosePreview: (e: React.MouseEvent | KeyboardEvent) => void;
}

interface ISnapshotPreviewState {
  scale: number;
}

export default class SnapshotPreview extends React.Component<ISnapshotPreviewProps, ISnapshotPreviewState> {
  readonly maxScaleNum: number = 4;
  readonly minScaleNum: number = 0.2;
  private scale: number = 1;

  private maskRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: ISnapshotPreviewProps) {
    super(props);
    this.state = {
      scale: 1,
    };
  }

  componentDidMount() {
    const { height: maskHeight } = this.maskRef?.current?.getBoundingClientRect()!;
    const { height: imgWrapperHeight } = this.props.cropAreaInfo!;

    if (imgWrapperHeight) {
      this.scale = _.clamp(maskHeight / imgWrapperHeight, this.minScaleNum, this.maxScaleNum);
      this.setState({
        scale: this.scale,
      });
    }

    document.addEventListener('wheel', this.handlePreviewScroll, { passive: false });
    document.addEventListener('keyup', this.handleKeyUp, { passive: false });
  }

  componentWillUnmount() {
    document.removeEventListener('wheel', this.handlePreviewScroll);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  handlePreviewScroll = (e: WheelEvent) => {
    if (!isControlKeyPressed(e)) {
      return;
    }

    e.wheelDeltaY > 0 ? (this.scale += 0.1) : (this.scale -= 0.1);
    this.scale = _.clamp(this.scale, this.minScaleNum, this.maxScaleNum);

    this.setState({
      scale: this.scale,
    });
  };

  handleKeyUp = (e: KeyboardEvent) => {
    if (e.keyCode === KeyCodeMap.VK_ESCAPE) {
      this.props.onClosePreview(e);
    }
  };

  handleClick = (e: React.MouseEvent) => {
    this.props.onClosePreview(e);
  };

  render() {
    const { imageStyles, hasCropped, url } = this.props;
    const { scale } = this.state;

    return ReactDom.createPortal(
      <div className="snapshot-preview-mask" ref={this.maskRef}>
        <ScrollBars autoHide={false} theme="dark" thumbTheme="large">
          <Icon cls="icon_Close" className="close-btn" onClick={this.handleClick} />

          {hasCropped ? (
            <div className="preview-img" style={{ ...imageStyles, transform: `scale(${scale})` }} />
          ) : (
            <img className="preview-img" src={url} style={{ transform: `scale(${scale})` }} />
          )}
        </ScrollBars>
      </div>,
      document.body,
    );
  }
}
