// 响应式布局/自动锚定相关
import { round } from '@utils/globalUtils';
import { ISize } from '@utils/boundsUtils';

import { HorizontalAlign, IBasicLayout, VerticalAlign } from '@fbs/rp/models/layout';
import { IBounds, IPosition } from '@fbs/common/models/common';
import { IComponentData } from '@fbs/rp/models/component';

import { UIComponent } from '@editor/comps';

import { getBoundsInParent } from './componentHelper';

export function getAutoLayout(comp: IDataForCalculateLayout, bounds: IBounds): IBasicLayout {
  //  以百分之15的比例，来确定临界值
  const thresholdRatio = 0.15;
  const leftRightThreshold = Math.max(5, bounds!.width * thresholdRatio);
  const topBottomThreshold = Math.max(5, bounds!.height * thresholdRatio);

  // 计算子组件到所有子组件形成的新边界的距离
  const { position, size, rotate } = comp;
  const compViewBound = getBoundsInParent({ position, size, rotate });
  const leftInCurrCompUnion = round(compViewBound.left - bounds!.left);
  const RightInCurrCompUnion = round(bounds!.width - leftInCurrCompUnion - compViewBound.width);
  const topInCurrCompUnion = round(compViewBound.top! - bounds!.top);
  const bottomInCurrCompUnion = round(bounds!.height - topInCurrCompUnion - compViewBound.height);

  //根据距离边界的距离是否小于临界值来决定锚定方式
  const isLeftAnchored = leftInCurrCompUnion <= leftRightThreshold;
  const isRightAnchored = RightInCurrCompUnion <= leftRightThreshold;
  const isTopAnchored = topInCurrCompUnion <= topBottomThreshold;
  const isBottomAnchored = bottomInCurrCompUnion <= topBottomThreshold;

  let horizontal: HorizontalAlign = HorizontalAlign.Left;
  if (isLeftAnchored) {
    horizontal = HorizontalAlign.Left;
  }
  if (isRightAnchored) {
    horizontal = HorizontalAlign.Right;
  }
  if (isLeftAnchored && isRightAnchored) {
    horizontal = HorizontalAlign.LeftAndRight;
  }
  if (!isLeftAnchored && !isRightAnchored) {
    horizontal = HorizontalAlign.Auto;
  }

  let vertical: VerticalAlign = VerticalAlign.Top;
  if (isTopAnchored) {
    vertical = VerticalAlign.Top;
  }
  if (isBottomAnchored) {
    vertical = VerticalAlign.Bottom;
  }
  if (isTopAnchored && isBottomAnchored) {
    vertical = VerticalAlign.TopAndBottom;
  }
  if (!isTopAnchored && !isBottomAnchored) {
    vertical = VerticalAlign.Auto;
  }

  // 根据锚定方式判断是否固定宽高
  const fixedWidth = isLeftAnchored && isRightAnchored ? false : true;
  const fixedHeight = isTopAnchored && isBottomAnchored ? false : true;

  return {
    horizontal,
    vertical,
    fixedWidth,
    fixedHeight,
  };
}

/**
 * 获取一组组件的智能锚定值与宽高固定值
 *  左右临界值0.15 * 新组的宽；上下临界值：0.15 * 新组的高
 *  组件到新组边界的距离小于临界值，视为该方向锚定
 *  居中錨定是兩側距離相同時，則該方向居中
 *  最终得到的锚定结果是上下左右四个方向结果的并集
 */
export function getSmartLayout(components: UIComponent[], bounds: IBounds): WeakMap<UIComponent, IBasicLayout> {
  const map = new WeakMap<UIComponent, IBasicLayout>();
  if (components.length === 0) {
    return map;
  }

  components.forEach((comp) => {
    const { position, size, rotate } = comp;
    const dataForCalculateLayout: IDataForCalculateLayout = { position, size, rotate };
    const layout = getAutoLayout(dataForCalculateLayout, bounds);
    map.set(comp, layout);
  });
  return map;
}

export interface IDataForCalculateLayout {
  position: IPosition;
  size: ISize;
  rotate: number;
}

export function getSmartLayoutByCompData(components: IComponentData[], bounds: IBounds): Map<string, IBasicLayout> {
  const map = new Map<string, IBasicLayout>();
  if (components.length === 0) {
    return map;
  }
  components.forEach((comp) => {
    const { position, size, rotate } = comp;
    const dataForCalculateLayout: IDataForCalculateLayout = { position, size, rotate: rotate || 0 };
    const layout = getAutoLayout(dataForCalculateLayout, bounds);
    map.set(comp._id, layout);
  });
  return map;
}

export function getCompsLayoutByData(compsData: IComponentData[], containerBounds: IBounds) {
  const map = getSmartLayoutByCompData(compsData, containerBounds);

  // 如果组件不是自动，那么就获取其手动数据
  compsData.forEach((comp) => {
    const layout = comp.layout;
    const { vertical, horizontal, fixedHeight, fixedWidth, auto } = layout;
    if (auto) {
      return;
    }
    map.set(comp._id, { fixedHeight, fixedWidth, horizontal, vertical });
  });
  return map;
}

export function collectComponentsLayout(
  components: UIComponent[],
  boundsForChildren: IBounds,
): WeakMap<UIComponent, IBasicLayout> {
  const map = getSmartLayout(components, boundsForChildren);
  components.forEach((comp) => {
    const layout = comp.layout;
    if (layout.auto) {
      return;
    }
    map.set(comp, {
      fixedHeight: layout.fixedHeight,
      fixedWidth: layout.fixedWidth,
      horizontal: layout.horizontal,
      vertical: layout.vertical,
    });
  });
  return map;
}
