import { IRotateCommandParams } from '@fbs/rp/models/interactions';
import { Ops } from '@fbs/rp/utils/patch';
import { EventTypes } from '@fbs/rp/models/event';

import CommandBase from './CommandBase';

export default class RotationCommand extends CommandBase {
  public get afterEvent() {
    return EventTypes.afterRotate;
  }
  protected cashOriginParams() {
    this.saveCacheOriginParams('rotate', this.command.target.rotate || 0);
  }

  private initOperation = (rotate: number) => {
    const { target } = this.command;
    const { id, ownerArtboardID } = target;
    return {
      [ownerArtboardID]: {
        [id]: [Ops.replace(this.getCurrentPath('rotate'), rotate), this.initAnimationOperation()],
      },
    };
  };

  private getTargetRotate = (revert: boolean) => {
    const { target, params } = this.command;
    const { fromCurrent, rotate } = params as IRotateCommandParams;
    const { rotate: targetRotate } = target;
    if (!fromCurrent) {
      return revert ? this.getCacheOriginParams('rotate') : rotate;
    }
    return revert ? targetRotate - rotate : targetRotate + rotate;
  };

  run = () => {
    this.patch(this.initOperation(this.getTargetRotate(false)));
    this.fulfilled = true;
  };

  revert = () => {
    this.patch(this.initOperation(this.getTargetRotate(true)));
    this.fulfilled = true;
  };
}
