// 可展示区域list
export const MAP_REGION_LIST = [
  { id: '100000', text: '中国' },
  { id: '110000', text: '北京' },
  { id: '120000', text: '天津' },
  { id: '130000', text: '河北' },
  { id: '140000', text: '山西' },
  { id: '150000', text: '内蒙古' },
  { id: '210000', text: '辽宁' },
  { id: '220000', text: '吉林' },
  { id: '230000', text: '黑龙江' },
  { id: '310000', text: '上海' },
  { id: '320000', text: '江苏' },
  { id: '330000', text: '浙江' },
  { id: '340000', text: '安徽' },
  { id: '350000', text: '福建' },
  { id: '360000', text: '江西' },
  { id: '370000', text: '山东' },
  { id: '410000', text: '河南' },
  { id: '420000', text: '湖北' },
  { id: '430000', text: '湖南' },
  { id: '440000', text: '广东' },
  { id: '450000', text: '广西' },
  { id: '460000', text: '海南' },
  { id: '500000', text: '重庆' },
  { id: '510000', text: '四川' },
  { id: '520000', text: '贵州' },
  { id: '530000', text: '云南' },
  { id: '540000', text: '西藏' },
  { id: '610000', text: '陕西' },
  { id: '620000', text: '甘肃' },
  { id: '630000', text: '青海' },
  { id: '640000', text: '宁夏' },
  { id: '650000', text: '新疆' },
  { id: '710000', text: '台湾' },
  { id: '810000', text: '香港' },
  { id: '820000', text: '澳门' },
];

// 地图组件初始valued对应的name
export const DEFAULT_VALUE_NAMES = [
  '北京',
  '天津',
  '河北',
  '山西',
  '内蒙古',
  '辽宁',
  '吉林',
  '黑龙江',
  '上海',
  '江苏',
  '浙江',
  '安徽',
  '福建',
  '江西',
  '山东',
  '河南',
  '湖北',
  '湖南',
  '广东',
  '广西',
  '海南',
  '重庆',
  '四川',
  '贵州',
  '云南',
  '西藏',
  '陕西',
  '甘肃',
  '青海',
  '宁夏',
  '新疆',
  '台湾',
  '香港',
  '澳门',
];

// 地图多色默认颜色
export const DEFAULT_MAP_COLORS = [
  { r: 252, g: 252, b: 202, a: 1 }, // #FCFCCA
  { r: 252, g: 204, b: 255, a: 1 }, // #FFCCFF
  { r: 217, g: 229, b: 255, a: 1 }, // #D9E5FF
  { r: 217, g: 255, b: 217, a: 1 }, // #D9FFD9
  { r: 255, g: 229, b: 204, a: 1 }, // #FFE5CC
  { r: 255, g: 204, b: 204, a: 1 }, // #FFCCCC
  { r: 220, g: 204, b: 255, a: 1 }, // #DCCCFF
  { r: 169, g: 241, b: 223, a: 1 }, // #A9F1E9
  { r: 220, g: 241, b: 160, a: 1 }, // #DCF1A0
  { r: 209, g: 244, b: 255, a: 1 }, // #D1F4FF
];

/**
 * 默认数据生成方式
 */
// function getRandom () {
//   const min = 2000;
//   const max = 7000;
//   const len = 40;
//   const res = [];
//   for(let i = 0; i < len; i++) {
//     const value = Math.round(2000 + Math.random() * (max - min));
//     res.push(value);
//   }
//   console.log(res);
// }
export const DEFAULT_MAP_VALUES = [
  5320,
  3523,
  5759,
  6234,
  4423,
  5256,
  5692,
  6821,
  5563,
  3352,
  4461,
  6134,
  5531,
  2043,
  5486,
  5756,
  2623,
  2691,
  2617,
  2676,
  5174,
  4074,
  2517,
  2424,
  5435,
  2408,
  4729,
  5921,
  3979,
  6029,
  3896,
  5088,
  5278,
  6484,
  5307,
  4063,
  2027,
  3865,
  5976,
  5775,
];
