import { IComponentData } from '@/fbs/rp/models/component';
import { makePieChart } from '@libs/ChartComponents/SeriesChartTwo/PieChart/config';
import { IUICompConstructOptions } from '@/customTypes';
import { UIComponent, UIContainerComponent } from '.';

export default class UIChartPieComponent extends UIComponent {
  constructor(data: IComponentData, parent?: UIContainerComponent, options?: IUICompConstructOptions) {
    const defaultData = makePieChart(data._id);
    const { dataLabel, legendPosition, pieDataLabel, pieLegendPosition } = data.properties;

    const adaptedProperties = {
      ...defaultData.properties,
      ...data.properties,
      pieDataLabel: pieDataLabel ?? { ...dataLabel },
      pieLegendPosition: pieLegendPosition ?? { ...legendPosition },
    };
    delete adaptedProperties.dataLabel;
    delete adaptedProperties.legendPosition;

    // 必须修改原数据的方式，保证修改和读取同源
    if (data.properties.dataLabel || data.properties.legendPosition) {
      data.properties = adaptedProperties;
    }

    super(data, parent, options);
  }
}
