import { AppTypes, IAppInfo } from '@fbs/idoc/models/app';
import INode, { INodeWithChildren } from '@fbs/rp/models/node';
import { changeTreeToArray, findNodeByID } from '@fbs/rp/utils/app';
import { ISize, Orientation } from '@/fbs/common/models/common';
import { IAppWithNestedChildren } from '@/fbs/rp/models/app';
import { IAppsOfTeam, MemberPermission, Role } from '@fbs/teamManagement';
import { isMobileAppType } from './previewHelper';
import { DeviceInfoRecord, ProjectType } from '@/consts/deviceInfo';

export function isPageExisting(allNodes: INode[], pageID: string) {
  const index = allNodes.findIndex((item) => {
    return item._id === pageID;
  });
  return index !== -1;
}

/**
 * 将返回的节点加入到整理过的节点
 *
 * @export
 * @param {INodeWithChildren} addNode
 * @param {string} type
 * @param {INodeWithChildren[]} nodes
 * @returns
 */
export function addNodeToTree(addNode: INodeWithChildren, type: string, nodes: INodeWithChildren[]) {
  const path = addNode.path.split(',');
  if (path.length === 1 && path[0] === 'ROOT') {
    if (type === 'add') {
      return [...nodes, addNode];
    } else {
      const allNodes = nodes;
      allNodes.map((node) => {
        if (node.index > addNode.index - 1) {
          return node.index++;
        }
      });
      allNodes.splice(addNode.index, 0, addNode);
      return allNodes;
    }
  }
  return nodes.map((node) => {
    const index = path.findIndex((val) => val === node._id);
    if (index === path.length - 1) {
      //到最后路径层
      addNode.parent = node;
      if (node?.hidden) {
        addNode.hidden = true;
      }
      if (type === 'add') {
        addNode.index = node.children.length; //在尾部添加
        node.children.push(addNode);
      } else {
        //插入
        node.children.map((node) => {
          if (node.index > addNode.index - 1) {
            return node.index++;
          }
        });
        node.children.splice(addNode.index, 0, addNode);
      }
      node._collapse = false; //展开树结构
    } else if (index === -1) {
      //没有找到
      return node;
    } else {
      //接着向内找
      addNodeToTree(addNode, type, node.children);
    }
    return node;
  });
}

/**
 * 获取新建node节点的path
 */
export function getNewNodePath(nodes: INode[], parentID?: string) {
  const basePath = 'ROOT';
  if (!parentID) {
    return basePath;
  }
  const nodeList: INodeWithChildren[] = [];
  changeTreeToArray(nodes as INodeWithChildren[], nodeList);
  const parentNode = nodeList.find((n) => n._id === parentID);
  if (!parentNode) {
    return basePath;
  }
  return `${parentNode.path},${parentID}`;
}

/**
 * 递归查看是否有这个成员
 * @param app
 * @param userID
 * @param times
 */
function canReadApp(app: IAppsOfTeam, userID: number, times: number = 0): boolean {
  if (times > 200) {
    console.log('[can read app] timeout');
    return false;
  }
  times++;
  const { members, userID: appUserID, children, visibility } = app;
  if (members?.includes(userID) || userID === appUserID || visibility !== 'private') {
    return true;
  }
  return !!children?.some((a) => canReadApp(a, userID, times));
}

/**
 * 过滤出RP项目 初始RP 后来RP 项目集
 * @param item 项目信息
 * @param options
 */
function filterApp(
  item: IAppsOfTeam,
  options: {
    // module: 'rp' | 'all';
    userID: number;
    superAdminViewAllApp?: boolean;
    roleInTeam?: string;
  },
) {
  const { /*module, */ userID, superAdminViewAllApp, roleInTeam } = options;
  // const { usedModule, defaultModule, isAppSet } = item;
  // const inModule = module === 'all' || usedModule?.rp || defaultModule === 'rp' || isAppSet;
  // 不限制成员 交给后端判断
  const canShowApp =
    (MemberPermission.isSuperAdmin(roleInTeam as Role) && superAdminViewAllApp) || canReadApp(item, userID) || true;
  return /*inModule && */ canShowApp;
}

/**
 * 将树结构的数组转成一维数组
 * @param data 树形app
 * @param options
 */
export function transAppTreeOfList(
  data: IAppsOfTeam[],
  options: {
    userID: number;
    superAdminViewAllApp?: boolean;
    roleInTeam?: string;
  },
) {
  const apps = data.filter((item) => filterApp(item, options));
  const result = [...apps];
  apps.forEach((item) => {
    item.children?.length && result.push(...transAppTreeOfList(item.children, options));
  });
  return result;

  //
  // let oldData = [...data];
  // const newData = [];
  // while (oldData.length) {
  //   const app = { ...oldData[0] };
  //   oldData.shift();
  //   if (!filterApp(app, options)) {
  //     continue;
  //   }
  //   if (app.children?.length) {
  //     oldData = [...oldData, ...app.children];
  //     // delete app.children;
  //   }
  //   newData.push({ ...app });
  // }
  // return newData;
}

/**
 * 将一维数组转树结构
 * @param data 一维数组
 * @param parent 转入项目集
 */
export function transAppListOfTree(data: IAppsOfTeam[], parent?: IAppsOfTeam) {
  const newData = [...data.map((app) => ({ ...app, children: [] }))];
  const appSetID = parent?._id || '';
  return newData.reduce((prev: IAppsOfTeam[], curr: IAppsOfTeam) => {
    if (curr.appSetID === appSetID && isAppSet(curr)) {
      curr.children = [...transAppListOfTree(data, curr)];
      // curr.parent = parent;
      prev = [...prev, curr];
    }
    return prev;
  }, []);
}

/**
 * 搜索项目或项目集中是否有某个名称的项目
 * @param appAppInfo
 * @param search
 */
export function searchNameOfApp(appAppInfo: IAppInfo, search: string): boolean {
  const { name, children } = appAppInfo;
  if (name.includes(search)) {
    return true;
  }
  return !!children?.some((app) => searchNameOfApp(app, search));
}


/**
 * 获取所有未删除页面的 id 的一维数组
 * @param nodes
 */
export function getAllPageNodeIDs(nodes: IAppWithNestedChildren['children']): string[] {
  // @ts-ignore
  return nodes.flatMap((node) => {
    if (node.type === 'page') {
      return [node._id].concat(getAllPageNodeIDs(node.children));
    }

    return getAllPageNodeIDs(node.children);
  });
}

export function isAppSet(app: IAppsOfTeam) {
  return app.type === AppTypes.AppSet;
}
/**
 * NOTE: 会修改原数组
 * @param nodeIDs
 * @param nodes
 */
export function sortNodeIDsByNodeIndex(nodeIDs: string[], nodes: INodeWithChildren[]): string[] {
  return nodeIDs.sort((a, b) => {
    const aNode = findNodeByID(nodes, a);
    const bNode = findNodeByID(nodes, b);
    if (!aNode || !bNode) {
      return -1;
    }
    return aNode.index - bNode.index;
  });
}

/**
 * 计算项目的方向
 * @param appSize
 * @param appType
 * @returns Orientation|undefined
 */
export function getAppOrientation(appSize: ISize, appType: string): Orientation | undefined {
  const equalSize = (size1: ISize, size2: ISize) => {
    return size1.width === size2.width && size1.height === size2.height;
  };

  let appOrientation: Orientation | undefined = undefined;

  if (appType && isMobileAppType(appType)) {
    const deviceSize = DeviceInfoRecord[appType as ProjectType];

    if (deviceSize.landscape.find((item) => equalSize(item.size, appSize))) {
      appOrientation = 'landscape';
    } else if (deviceSize.portrait.find((item) => equalSize(item.size, appSize))) {
      appOrientation = 'portrait';
    }
  }
  return appOrientation;
}
