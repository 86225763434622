import { AvlTree } from '@tyriar/avl-tree';
import * as _ from 'lodash';
import * as shortid from 'shortid';

import IArtboard, { IArtboardMetaPatch } from '@fbs/rp/models/artboard';
import { PageOperations, patchArtboardByAVLTree } from '@fbs/rp/utils/patch';
import { IComponentData } from '@fbs/rp/models/component';
import { updateNodeAVLTree, removeComponentNodeByID } from '@fbs/rp/utils/avlTree';
import { getArtboardMetaPatch } from '@fbs/rp/utils/artboard';
import { HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';

import apis from '@apis';
import { CContentPanel, CContentPanelV2 } from '@libs/constants';
import RefactorRemarkManager from '@managers/refactorRemarkManager';
import { IUICompConstructOptions } from '@/customTypes';
import { getOrientationFromSize } from '@/helpers/artboardDimensionHelper';

import { UIArtboard, UIFragment, UIComponent, UIContainerComponent } from './comps';
import { appDataManager } from '../services/appDataManager';

/**
 * 页面文档对象
 */
export default class Doc {
  protected mainArtboardData: IArtboard;
  protected readonly fragmentsData: IArtboard[];
  public readonly _id: string = shortid.generate();

  private readonly tree: AvlTree<string, IComponentData>;

  //添加artboard监听
  private onceLoadArtboardListeners: Function[] = [];

  /**
   * 主画板，目前也就这一个画板
   */
  public mainArtboard: UIArtboard;

  /**
   * 辅助画板，即 fragment，完整数据(未过滤的)
   */
  protected readonly _fragments: UIFragment[];

  public get fragments(): UIFragment[] {
    return this._fragments;
  }

  /**
   * 当前 editor doc 上，工作区可见的用户可编辑的辅助画板，排除动态面板关联的画板
   */
  public get visibleFragments(): UIFragment[] {
    return this._fragments.filter((fragment) => !fragment.ownerContentPanelID);
  }

  /**
   * 获取页面画板数据
   * @returns {IArtboard[]}
   */
  public get artboards(): IArtboard[] {
    return [{ ...this.mainArtboardData, type: 'main' }, ...this.fragmentsData];
  }

  /**
   * 获取页面面板的组件
   */
  public get artboardsFragments(): UIFragment[] {
    return [this.mainArtboard, ...this.fragments];
  }

  public get visibleArtboardsFragments(): UIFragment[] {
    return [this.mainArtboard, ...this.visibleFragments];
  }

  public get realAppID() {
    if (this.mainArtboardData) {
      return this.mainArtboardData.appID;
    }
    return '';
  }

  public get isPreview(): boolean {
    return !!this.options?.isPreview;
  }

  constructor(
    public readonly appID: string,
    public readonly pageID: string,
    artboards: IArtboard[],
    public options?: IUICompConstructOptions,
  ) {
    this.fixCurrentState(artboards);
    const mainArtboardData = options?.isContentPanelEditor
      ? artboards.shift()
      : artboards.find((artboard) => artboard.type === 'main');
    if (!mainArtboardData) {
      throw new Error(`Cannot find main artboard data of ${pageID}`);
    }
    // 主画板需要，初始化 orientation
    let orientation = mainArtboardData.orientation;
    if (!orientation) {
      orientation = getOrientationFromSize(mainArtboardData.size);
    }
    this.mainArtboardData = Object.assign({}, mainArtboardData, {
      id: mainArtboardData._id,
      type: 'artboard',
      size: mainArtboardData.size,
      orientation,
      layout: {
        responsive: mainArtboardData.responsive,
        auto: true,
        horizontal: HorizontalAlign.Left,
        vertical: VerticalAlign.Top,
        fixedWidth: true,
        fixedHeight: true,
      },
    });

    this.mainArtboard = new UIArtboard(this.mainArtboardData, this, options);
    this.tree = new AvlTree<string, IComponentData>((a: string, b: string) => {
      return a.localeCompare(b);
    });

    if (mainArtboardData.components) {
      updateNodeAVLTree(this.tree, mainArtboardData.components);
    }
    this.fragmentsData = artboards
      .filter((artboard) => artboard.type !== 'main')
      .map((data) =>
        Object.assign({}, data, {
          id: data._id,
          type: 'artboard',
          size: data.size,
          layout: {
            responsive: data.responsive,
            auto: true,
            horizontal: HorizontalAlign.Left,
            vertical: VerticalAlign.Top,
            fixedWidth: true,
            fixedHeight: true,
          },
        }),
      );
    this._fragments = this.fragmentsData.map((data) => {
      if (data.components) {
        updateNodeAVLTree(this.tree, data.components);
      }
      return new UIFragment(data, this, options);
    });

    // 初始化注释面板数据
    RefactorRemarkManager.create(this);
  }

  //添加加载artboard监听
  public addOnceLoadArtboardListeners(fn: Function) {
    this.onceLoadArtboardListeners.push(fn);
  }

  //触发加载artboard监听
  public emitOnceLoadArtboardListeners() {
    this.onceLoadArtboardListeners.forEach((fn) => fn());
    this.onceLoadArtboardListeners = [];
  }

  /**
   * 修正一些非法保存的数据
   * @param {IArtboard[]} artboards
   */
  private fixCurrentState(artboards: IArtboard[]) {
    const fixed = (comp: IComponentData) => {
      if (comp) {
        comp._currentState = undefined;
        comp.components?.forEach(fixed);
      }
    };
    artboards.forEach((item) => {
      if (!item) {
        return false;
      }
      item.components?.forEach(fixed);
    });
  }

  // 目前是动态面板 editor 在用
  switchMainArtboard(newMainArtboard: UIFragment) {
    if (newMainArtboard === this.mainArtboard) {
      return;
    }

    // 处理主画板相关
    const oldMainArtboard = this.mainArtboard;
    oldMainArtboard.isMain = false;
    newMainArtboard.isMain = true;
    this.mainArtboard = newMainArtboard;
    this.mainArtboardData = newMainArtboard.$data;

    // 处理辅助画板相关
    const index = this._fragments.findIndex((item) => {
      return item.artboardID === newMainArtboard.artboardID;
    });
    const index2 = this.fragmentsData.findIndex((item) => {
      return item._id === newMainArtboard.artboardID;
    });
    this._fragments.splice(index, 1, oldMainArtboard);
    this.fragmentsData.splice(index2, 1, oldMainArtboard.$data);
  }

  public addArtboard(artboard: IArtboard): UIFragment {
    const fragmentData = Object.assign({}, artboard, {
      id: artboard._id,
      type: 'artboard',
      size: artboard.size,
      layout: {
        responsive: artboard.responsive,
        auto: true,
        horizontal: HorizontalAlign.Left,
        vertical: VerticalAlign.Top,
        fixedWidth: true,
        fixedHeight: true,
      },
    });
    this.fragmentsData.push(fragmentData);

    const fragment = new UIFragment(fragmentData, this, this.options);
    if (artboard.components) {
      updateNodeAVLTree(this.tree, artboard.components);
    }
    this._fragments.push(fragment);

    RefactorRemarkManager.getInstance(this)!.retrieveChildrenRemark(fragment.components);
    return fragment;
  }

  /**
   * 保留type
   * 与addArtboard区别，是不要重置type
   * @param artboard
   */
  public addArtboardKeepType(artboard: IArtboard): UIFragment {
    const fragmentData = Object.assign({}, artboard, {
      id: artboard._id,
      size: artboard.size,
      layout: {
        responsive: artboard.responsive,
        auto: true,
        horizontal: HorizontalAlign.Left,
        vertical: VerticalAlign.Top,
        fixedWidth: true,
        fixedHeight: true,
      },
    });
    this.fragmentsData.push(fragmentData);

    const fragment = new UIFragment(fragmentData, this, this.options);
    if (artboard.components) {
      updateNodeAVLTree(this.tree, artboard.components);
    }
    this._fragments.push(fragment);

    RefactorRemarkManager.getInstance(this)!.retrieveChildrenRemark(fragment.components);
    return fragment;
  }

  /**
   * 删除一个辅助画板
   * @param {string} artboardID
   */
  removeFragment(artboardID: string) {
    const index = this._fragments.findIndex((item) => {
      return item.artboardID === artboardID;
    });
    const index2 = this.fragmentsData.findIndex((item) => {
      return item._id === artboardID;
    });
    if (index2 !== -1) {
      this._fragments[index].components.forEach((comp) => {
        removeComponentNodeByID(this.tree, comp.id);
      });

      RefactorRemarkManager.getInstance(this)!.removeChildrenRemark(this._fragments[index].components);

      this._fragments.splice(index, 1);
      this.fragmentsData.splice(index2, 1);
    }
  }

  private patchSingleArtboardMeta = (artboard: UIArtboard, artboardData: IArtboard, meta: IArtboardMetaPatch) => {
    if (!_.isUndefined(meta.name)) {
      artboardData.name = meta.name;
      artboard.$data.name = meta.name;
    }

    if (!_.isUndefined(meta.size)) {
      artboardData.size = meta.size;
      artboard.$data.size = meta.size;
    }

    // 与size分开管理
    if (!_.isUndefined(meta.orientation)) {
      artboardData.orientation = meta.orientation;
      artboard.$data.orientation = meta.orientation;
    }

    if (!_.isUndefined(meta.position)) {
      artboardData.position = meta.position;
      artboard.$data.position = meta.position;
    }

    if (!_.isUndefined(meta.interaction)) {
      artboardData.interaction = meta.interaction;
      artboard.$data.interaction = meta.interaction;
    }
    if (!_.isUndefined(meta.responsive)) {
      artboardData.layout.responsive = meta.responsive;
      artboard.$data.layout.responsive = meta.responsive;
      artboardData.responsive = meta.responsive;
      artboard.$data.responsive = meta.responsive;
    }
    if (!_.isUndefined(meta.background)) {
      artboardData.background = meta.background;
      artboard.$data.background = meta.background;
    }
    if (!_.isUndefined(meta.guides)) {
      artboardData.guides = meta.guides;
      artboard.$data.guides = meta.guides;
    }
    if (!_.isUndefined(meta.remark)) {
      if (meta.remark) {
        artboardData.remark = meta.remark;
        artboard.$data.remark = meta.remark;
      } else {
        artboardData.remark = undefined;
        artboard.$data.remark = undefined;
      }
    }
  };

  /**
   * patches合并数据后，更新AppDataManager里的artboards
   * 因为有可能有画板删除，所以整体更新
   */
  updateAppDataManagerArtboardsByOperations() {
    const nodeID = this.mainArtboardData.nodeID;
    appDataManager.updateArtboardWithNodeID(nodeID, this.artboards);
  }

  /**
   * 合并修改后的数据到画板
   * @param {PageOperations} operations
   * @param {Function} onFinish
   */
  patchArtboard(operations: PageOperations, onFinish: Function) {
    const addNodes: IComponentData[] = [];
    let hasAsync: boolean = false;
    for (let artboardID in operations) {
      const patches = operations[artboardID];
      // 支持批量处理画板
      if (artboardID === 'ROOT') {
        // NOTE：remove、add 操作不会同时存在
        const op = patches.self[0];
        if (op.op === 'remove') {
          patches.self.forEach((op) => {
            this.removeFragment(op.path);
          });
        } else if (op.op === 'add') {
          hasAsync = true;
          if (patches.self.length > 1) {
            this.batchLoadArtboard(
              patches.self.map((op) => op.path),
              onFinish,
            );
          } else {
            this.loadArtboard(op.path, onFinish);
          }
        }
        continue;
      }

      if (!artboardID || artboardID === this.mainArtboard.artboardID) {
        // 是对 Artboard 元数据的修改
        if (patches.self) {
          const meta = getArtboardMetaPatch(patches.self);
          this.patchSingleArtboardMeta(this.mainArtboard, this.mainArtboardData, meta);
        }
        const nodes = patchArtboardByAVLTree(this.mainArtboardData, this.tree, patches);
        addNodes.push(...nodes);

        !this.isPreview && this.mainArtboard.refreshComponents();
      } else {
        const fragmentIndex = this.fragmentsData.findIndex((data) => data._id === artboardID);
        // assert.ok(fragmentIndex !== -1, '必须能找到 artboard.');
        if (fragmentIndex !== -1) {
          // 是对 Fragment 的元数据的修改
          if (patches.self) {
            const meta = getArtboardMetaPatch(patches.self);
            this.patchSingleArtboardMeta(this._fragments[fragmentIndex], this.fragmentsData[fragmentIndex], meta);
          }
          const nodes = patchArtboardByAVLTree(this.fragmentsData[fragmentIndex], this.tree, patches);
          addNodes.push(...nodes);
          !this.isPreview && this._fragments[fragmentIndex].refreshComponents();
        } else {
          console.warn(`Cannot find fragment ${artboardID}.`);
        }
      }
    }
    addNodes.forEach((comp) => {
      if (!this.tree.contains(comp._id)) {
        this.tree.insert(comp._id, comp);
      }
    });
    if (!hasAsync) {
      onFinish();
    }
  }

  /**
   * 根据组件ID获取组件数据
   * @param {string} id
   * @returns {IComponentData | null}
   */
  getComponentByID(id: string): IComponentData | null {
    const comp = this.tree.get(id);
    if (comp) {
      return comp;
    }
    return null;
  }

  getComponentsByFilter(filter: (comp: UIComponent) => boolean) {
    const results: UIComponent[] = [];
    const queryComps = (comps: UIComponent[]) => {
      comps.forEach((item) => {
        if (filter(item)) {
          results.push(item);
        }
        if (item.isContainer && item.type !== CContentPanel && item.type !== CContentPanelV2) {
          queryComps((item as UIContainerComponent).components);
        }
      });
    };
    queryComps(this.mainArtboard.components);
    this.fragments.forEach((fragment) => {
      queryComps(fragment.components);
    });
    return results;
  }

  private getFragmentByID(id: string) {
    return this.fragments.find((item) => item.realID === id);
  }

  getArtboardByID(id: string) {
    return this.artboardsFragments.find((item) => item.realID === id);
  }

  /**
   * 根据ID获取画板名称
   * @param {string} id
   * @returns {string}
   */
  getFragmentNameByID(id: string): string {
    const fragment = this.getFragmentByID(id);
    if (fragment) {
      return fragment.name || id;
    }
    return '';
  }

  /**
   * 根据ID获取画板数据
   * @param {string} id
   * @returns {Partial<IComponentData>}
   */
  getFragmentData(id: string): IComponentData | undefined {
    const fragment = this.getFragmentByID(id);
    if (fragment) {
      return fragment.toJSON();
    }
    return undefined;
  }

  /**
   * 载入画板数据，如果是离线的画板到
   */
  loadArtboard(artboardID: string, onFinish: Function) {
    this.addOnceLoadArtboardListeners(onFinish);
    apis.artboard.getArtboardByID(artboardID).then((artboard) => {
      this.addArtboard(artboard);
      this.emitOnceLoadArtboardListeners();
    });
  }

  batchLoadArtboard(artboardIDs: string[], onFinish: Function) {
    this.addOnceLoadArtboardListeners(onFinish);
    Promise.all(artboardIDs.map((artboardID) => apis.artboard.getArtboardByID(artboardID))).then((artboards) => {
      artboards.forEach((artboard) => {
        this.addArtboard(artboard);
      });
      this.emitOnceLoadArtboardListeners();
    });
  }
}
