export enum UserStates {
  STATE_NORMAL = 0,
  STATE_DELETED = 4,
}
// v1/user/info GET 获取用户信息
export interface IUserInfo {
  id: number;
  email: string;
  name: string;
  avatar: string;
  addTime: Date;
  defaultIDocTeamID: string;
  defaultRpTeamID: string;
  phone: string;
  department?: string;
  phoneVerified: number;
  wxVerified: number;
  emailBinded: string;
  idocCertificate: string;
  isMockplusProUser?: boolean;
  hasUserGotCsdGift?: boolean;
  role?: number;
  thirdPartyUUID?: string;
  isSystemAdmin?: boolean;

  position?: string;
  userType?: string;
  //华为试用过期时间
  expirationTime?: Date;
  token?: string;
  //是否重置密码
  resetPassword?: boolean;
  openResetPassword?: boolean;
  resetPasswordTime?: number;
  //注销时间
  unsubscribeTime?: number;
  //是否付费用户
  license?: string;
  // 飞书ID
  larkOpenID?: string;
  larkUnionID?: string;
  larkUserID?: string;
  //用户状态
  userState?: UserStates;
}

export interface ITeamUserInfo {
  id: string;
  email: string;
}

// v1/user/signin POST 登录
export interface ISignInfo {
  user: IUserInfo;
  token: string;
}

export interface IPatchUserResult {
  name?: string;
  avatar?: string;
  defaultIDocTeamID?: string;
  showTips?: boolean;
  position?: string;
}

export interface IQRCodeInfo {
  ticket: string;
  QRCodeURL: string;
}

export interface IBindQRCodeInfo {
  QRCodeURL: string;
}

export interface IWxBindInfo {
  wxCode: number;
  msg: string;
}

// v1/user/ossToken GET 获取ossToken
export interface IOssTokenInfo {
  endpoint?: string;
  bucket: string;
  accessKeyId: string;
  accessKeySecret: string;
  stsToken: string;
}

export interface IAddMemberByEmailStatus {
  status: boolean;
}

/**
 * 私有部署管理后台 获取管理员信息
 */
export interface IPdAdminUserInfoF extends IUserInfo {
  authority: string; //管理员拥有的权限 超管为空字符
}

/**
 * 用户重置密码返回信息
 */
export interface IUnPassword {
  resetPasswordTime: number;
  unPassword: {
    state: number; //默认0,1开启定期修改密码
    cycle?: number; //周期  1 2 3 6
    num?: number; //密码重复次数
    begin?: number; //开启时间
  };
}

/**
 * 用户注销检测信息
 */
export interface IUnsubscribeCheckInfo {
  license?: string;
  statistics?: {
    ccTeam: number; //CC团队数量
    ccApp: number; //CC项目数量
    rpTeam: number; //RP团队数量
    rpApp: number; //RP项目数量
    dtTeam: number; //DT团队数量
    dtApp: number; //DT项目数量
    // dsTeam:number,        //DS团队数量
    // dsApp:number,         //DS项目数量
  };
}

/**
 * 私有部署管理后台 获取管理员信息
 */
export interface IPdAdminUserInfoF extends IUserInfo {
  authority: string; //管理员拥有的权限 超管为空字符
}
