import * as React from 'react';
import classnames from 'classnames';

import { transBlankChart } from '@utils/textUtils';
import { parseColorToString } from '@/utils/graphicsUtils';

import { hasInteraction } from '@helpers/interactionHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { UIContainerComponent, UIComponent } from '@editor/comps';

import { IRange } from '@fbs/rp/models/properties/base';
import { IconValue } from '@/fbs/rp/models/value';
import IVerticalTabsIconStyle, { IconPositionMode } from '@/fbs/rp/models/properties/verticalTabsIconStyle';
import IMarkerStrip, { MarkerStripPositionMode } from '@/fbs/rp/models/properties/makerstrip';
import { getListEvents } from '../helps';
import { IListCompositeProps } from '../../types';

import '../general.scss';
import './index.scss';

const VerticalTabs: React.FC<IListCompositeProps> = (props: IListCompositeProps) => {
  const { comp, itemEvents, isPreview, showInteract } = props;
  const container = comp as UIContainerComponent;
  const {
    size,
    opacity,
    components,
    properties: { separator, separatorRatio, iconStyle, fillChecked },
  } = container;
  const showSeparator = separator ? !separator.disabled : false;

  const parser = StyleHelper.initCSSStyleParser(comp.properties);
  const style = {
    ...size,
    ...parser.getShadowStyle(),
    ...parser.getStrokeStyle(),
    ...parser.getRadiusStyle(size),
    opacity: StyleHelper.getOpacity(opacity),
  };

  // 分割线样式
  const calcSeparatorStyle = () => {
    if (!showSeparator) {
      return undefined;
    }
    return {
      ...StyleHelper.parserSeparator('bottom', separator),
      height: 0,
      width: `${separatorRatio ? (separatorRatio.value as IRange).value : 100}%`,
    };
  };

  const renderFontIconComp = (iconComp: UIComponent) => {
    const iconInfo = iconStyle as IVerticalTabsIconStyle;
    if (!iconComp) {
      return null;
    }
    const { value } = iconComp; //position

    const icon = value as IconValue;
    const fontSize = iconInfo.iconSize;
    const isShow = iconInfo.isShow;

    if (!isShow) {
      return null;
    }
    const color = iconComp.properties.icon?.color || '#000000';
    return (
      <div
        className="item-font-icon"
        style={{
          width: fontSize,
          height: fontSize,
          fontSize: fontSize,
          fontFamily: icon.fontName,
          lineHeight: `${fontSize}px`,
          color: parseColorToString(color),
        }}
      >
        {String.fromCharCode(icon.iconCode)}
      </div>
    );
  };

  const renderTextComp = (textComp: UIComponent, iconPositionMode: IconPositionMode) => {
    const {
      opacity,
      size,
      properties: { textStyle },
    } = textComp;
    const itemParser = StyleHelper.initCSSStyleParser({ textStyle: textStyle });
    const { style } = itemParser.getTextStyleData(size, textStyle);
    const textStyles = {
      ...style,
      opacity: StyleHelper.getOpacity(opacity),
      lineHeight: 1,
      height: iconPositionMode === IconPositionMode.Top ? size.height : undefined,
    };
    return (
      <div className="item-text" style={textStyles}>
        {transBlankChart(textComp?.value as string)}
      </div>
    );
  };

  const renderSeparator = (index: number) => {
    if (showSeparator && index < components.length - 1) {
      return (
        <div className="item-separator-wrapper">
          <div className="item-separator" style={calcSeparatorStyle()} />
        </div>
      );
    }
    return null;
  };

  const renderRectComp = (rectComp: UIComponent, lengthRatio: number) => {
    if (!rectComp) {
      return null;
    }
    const parser = StyleHelper.createCSSStyleParser(rectComp.properties);
    const selectorSize = rectComp.size;
    const selectorStyle = {
      opacity: StyleHelper.getOpacity(rectComp.opacity),
      ...selectorSize,
      ...parser.getFillStyle(),
      ...parser.getRadiusStyle(selectorSize),
      ...parser.getStrokeStyle(),
      height: `${lengthRatio ?? 80}%`,
    };

    return <div className="item-selected-indicator" style={selectorStyle} />;
  };

  const events = isPreview ? getListEvents(components, itemEvents) : {};

  return (
    <div className={classnames('lib-comp-vertical-tabs', { preview: isPreview })} style={style}>
      {components &&
        components.map((comp, i) => {
          const _hasInteraction = hasInteraction(comp);
          const needShowInteraction = !isPreview && _hasInteraction && !!showInteract;
          const item = comp as UIContainerComponent;
          const iconComp = item.getComponentByAlias('fontIcon') || item.components[0];
          const textComp = item.getComponentByAlias('text') || item.components[1];
          const rectComp = item.getComponentByAlias('indicator') || item.components[2];

          const {
            properties: { fill, stroke },
            size,
          } = item;
          const itemParser = StyleHelper.initCSSStyleParser({ fill, stroke });
          const itemStyle = {
            ...size,
            ...itemParser.getFillStyle(),
            opacity: StyleHelper.getOpacity(comp.opacity),
          };
          const iconInfo = iconStyle as IVerticalTabsIconStyle;
          const { iconPositionMode } = iconInfo;
          const { positionMode, lengthRatio } = fillChecked as IMarkerStrip;
          return (
            <div
              className={classnames('item', {
                'item-interaction-flag': needShowInteraction,
                'item-hot-area': isPreview && _hasInteraction,
                'component-cursor-pointer': isPreview,
                'markerstrip-left': positionMode === MarkerStripPositionMode.Left,
                'markerstrip-right': positionMode === MarkerStripPositionMode.Right,
                'icon-position-top': iconPositionMode === IconPositionMode.Top,
              })}
              key={comp.id}
              style={itemStyle}
              {...(events[comp.id] || {})}
            >
              {renderFontIconComp(iconComp)}
              {renderTextComp(textComp, iconPositionMode)}

              {renderSeparator(i)}
              {renderRectComp(rectComp, lengthRatio)}
            </div>
          );
        })}
    </div>
  );
};

export default VerticalTabs;
