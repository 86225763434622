import { IAppsOfTeam, AppTypes, Visibility } from '../models/app';
import { Role, ITeamMember } from '../models/member';
import { IUserInfo } from '../../idoc/models/user';
import { ITeams } from '../../idoc/models/team';
import { TeamVersion, RPTeamPayment } from '../models/team';

/**
 * </doc/permission.md>
 */

export enum EMemberFeature {
  CreateApp, //创建项目或项目集
  CheckPreviewApp, // 查看演示链接
  EditApp, // 编辑项目
  CloneApp, //克隆项目
  DeleteApp, //删除项目
  ClearApp, // 清除回收站项目
  RestoreApp, // 恢复项目
  RenameApp, // 重命名项目
  MoveApp, // 移动项目
  CrossTeamMoveApp, // 跨团队移动项目
  AppMemberManager, // 项目成员管理
  permissionManager, // 权限管理
  InviteMembers, // 邀请成员
  ShareApp, // 分享项目
  PublishedApp, // 发布项目
  HandOverApp, // 移交项目
  EncryptDemoLink, // 加密演示链接
}

type IApp = Pick<IAppsOfTeam, 'userID' | 'members' | 'visibility' | 'type' | 'groups'>;
type IMember = Pick<IUserInfo, 'id'> & Pick<ITeamMember, 'role'> & { teamGroup?: number };

const VERSION_CC = 'version_cc'; // 标识-获取CC那边的价格链接
const VERSION_RP = 'version_rp'; // 标识-获取RP那边的价格链接

interface IPermissionConfig {
  isDT: boolean;
  isPrivateDeployment: boolean;
  isEnVersion: boolean;
}

interface IPlatformPowerRoles {
  zh: Role[];
  en: Role[];
  pd: Role[];
}

interface IPowerRoles {
  rp: IPlatformPowerRoles;
  dt: IPlatformPowerRoles;
}

interface IRolesInTeam {
  editor: IPowerRoles;
  viewer: IPowerRoles;
}

interface IFunctional {
  canUse: boolean;
  limit?: number;
}

export class MemberPermission {
  private static config: IPermissionConfig = {
    isDT: false,
    isEnVersion: false,
    isPrivateDeployment: false,
  };

  static setConfig(config: Partial<IPermissionConfig>): void {
    this.config = { ...this.config, ...config };
  }

  private static rolesInTeam: Readonly<IRolesInTeam> = {
    editor: {
      rp: {
        zh: [Role.SuperAdmin, Role.Admin, Role.Member],
        en: [Role.SuperAdmin, Role.Admin, Role.Member],
        pd: [Role.SuperAdmin, Role.Admin, Role.Member],
      },
      dt: {
        zh: [Role.SuperAdmin, Role.Admin, Role.Member, Role.Guest, Role.Observer],
        en: [Role.SuperAdmin, Role.Admin, Role.Member, Role.Guest, Role.Observer],
        pd: [Role.SuperAdmin, Role.Admin, Role.Member],
      },
    },
    viewer: {
      rp: {
        zh: [Role.Guest, Role.Observer],
        en: [Role.Guest, Role.Observer],
        pd: [Role.Guest],
      },
      dt: {
        zh: [],
        en: [],
        pd: [Role.Guest],
      },
    },
  };
  // 管理权限
  private static adminRoles: Role[] = [Role.SuperAdmin, Role.Admin];

  /**
   * 是否是超管
   */
  static isSuperAdmin(role: Role): boolean {
    return role === Role.SuperAdmin;
  }

  /**
   * 是否是管理员
   */
  static isAdmin(role: Role): boolean {
    return this.adminRoles.includes(role);
  }

  /**
   * 是否是编辑者
   * @param role
   */
  static isEditor(role: Role): boolean {
    return this.getRoles('editor').includes(role);
  }

  /**
   * 是否是查看者
   */
  static isViewer(role: Role): boolean {
    return this.getRoles('viewer').includes(role);
  }

  static canAddApp(role: Role): boolean {
    return this.isEditor(role);
  }

  /**
   * 邀请新成员
   */
  static canInvite(role: Role): boolean {
    return this.isAdmin(role);
  }

  private static getRoles(power: 'editor' | 'viewer'): Role[] {
    const { isDT, isEnVersion, isPrivateDeployment } = this.config;
    const platformKey = isDT ? 'dt' : 'rp';
    const versionKey = isPrivateDeployment ? 'pd' : isEnVersion ? 'en' : 'zh';
    return this.rolesInTeam[power][platformKey][versionKey];
  }

  static get isDT() {
    return this.config.isDT;
  }

  static get isEnVersion(): boolean {
    return this.config.isEnVersion;
  }

  static get isPrivateDeployment(): boolean {
    return this.config.isPrivateDeployment;
  }

  private static isAppSet(app: IApp): boolean {
    return app.type === AppTypes.AppSet;
  }

  /**
   * 项目所有者
   */
  static isOwner(member: IMember, app: IApp): boolean {
    return app.userID === member.id;
  }

  /**
   * 可见的 - 用于预览
   */
  private static hasVisbility(member: IMember, app: IApp): boolean {
    const { members = [], groups = [], visibility } = app;
    const { id: userID, teamGroup = 0 } = member;
    if (this.isAppSet(app)) {
      return members.includes(userID);
    }
    return visibility !== Visibility.Private || members.includes(userID) || groups.includes(teamGroup);
  }

  /**
   * 项目（集）能被看到
   * 勾选团队管理中”项目管理“选项时，超管可见团队所有项目；否则只可见自己有权限的项目
   */
  static canView(member: IMember, app: IApp): boolean {
    return this.hasPermission(EMemberFeature.CheckPreviewApp, member, app);
  }

  /**
   * 是否能进入编辑界面
   */
  static canEdit(member: IMember, app: IApp): boolean {
    return this.hasPermission(EMemberFeature.EditApp, member, app);
  }

  static canClone(member: IMember, app: IApp): boolean {
    return this.hasPermission(EMemberFeature.CloneApp, member, app);
  }

  /**
   * 删除项目（集）
   */
  static canDelete(member: IMember, app: IApp): boolean {
    return this.hasPermission(EMemberFeature.DeleteApp, member, app);
  }

  /**
   * 清除回收站项目
   */
  static canClear(member: IMember, app: IApp): boolean {
    return this.hasPermission(EMemberFeature.ClearApp, member, app);
  }

  static canRestore(member: IMember, app: IApp): boolean {
    return this.hasPermission(EMemberFeature.RestoreApp, member, app);
  }

  /**
   * 重命名项目（集）
   */
  static canRename(member: IMember, app: IApp): boolean {
    return this.hasPermission(EMemberFeature.RenameApp, member, app);
  }

  /**
   * 移动项目
   * @param crossTeam 是否跨团队
   */
  static canMove(member: IMember, app: IApp, crossTeam = false): boolean {
    return this.hasPermission(crossTeam ? EMemberFeature.CrossTeamMoveApp : EMemberFeature.MoveApp, member, app);
  }

  /**
   * 管理成员
   * 团队内
   */
  static canManageMember(member: IMember, app: IApp): boolean {
    return this.hasPermission(EMemberFeature.AppMemberManager, member, app);
  }

  static canShare(member: IMember, app: IApp): boolean {
    return this.hasPermission(EMemberFeature.ShareApp, member, app);
  }

  static canPublish(member: IMember, app: IApp): boolean {
    return this.hasPermission(EMemberFeature.PublishedApp, member, app);
  }

  static canEncryptDemoLink(member: IMember, app: IApp): boolean {
    return this.hasPermission(EMemberFeature.EncryptDemoLink, member, app);
  }

  /**
   * 能否编辑分享演示链接的项目页面
   */
  static canEditShareProjectPage(role:Role):boolean{
    return [Role.Admin,Role.Member,Role.SuperAdmin].includes(role)
  }

  static hasPermission(feature: EMemberFeature, member: IMember, app?: IApp): boolean {
    const { role } = member;
    if (this.isSuperAdmin(role)) {
      return true;
    }

    switch (feature) {
      case EMemberFeature.CreateApp:
        return MemberPermission.canAddApp(member.role);
      case EMemberFeature.CheckPreviewApp:
        // 勾选团队管理中”项目管理“选项时，超管可见团队所有项目；否则只可见自己有权限的项目
        return this.hasVisbility(member, app!);
      case EMemberFeature.EditApp:
      case EMemberFeature.CloneApp:
      case EMemberFeature.RenameApp:
      case EMemberFeature.MoveApp:
      case EMemberFeature.PublishedApp:
        return MemberPermission.isEditor(member.role) && this.hasVisbility(member, app!);
      case EMemberFeature.HandOverApp:
      case EMemberFeature.DeleteApp:
      case EMemberFeature.ClearApp:
      case EMemberFeature.RestoreApp:
      case EMemberFeature.EncryptDemoLink:
      case EMemberFeature.AppMemberManager: {
        if (MemberPermission.isAdmin(role)) {
          return this.hasVisbility(member, app!);
        }
        return this.isOwner(member, app!) && MemberPermission.isEditor(member.role);
      }
      case EMemberFeature.ShareApp:
        return this.hasVisbility(member, app!);
      case EMemberFeature.CrossTeamMoveApp:
        return false;
      case EMemberFeature.InviteMembers:
        return MemberPermission.canInvite(role);
      default:
        break;
    }
    return false;
  }

  static getStatus(
    feature: EMemberFeature,
    member: IMember,
    app?: IApp,
  ): 'disabled' | 'hidden' | 'enabled' | undefined {
    if (this.hasPermission(feature, member, app)) {
      return 'enabled';
    }
    switch (feature) {
      case EMemberFeature.CreateApp:
      case EMemberFeature.MoveApp:
      case EMemberFeature.CrossTeamMoveApp:
      case EMemberFeature.InviteMembers:
        return 'hidden';
      case EMemberFeature.CloneApp:
      case EMemberFeature.DeleteApp:
      case EMemberFeature.ClearApp:
      case EMemberFeature.RestoreApp:
      case EMemberFeature.RenameApp:
      case EMemberFeature.AppMemberManager:
      case EMemberFeature.EncryptDemoLink:
        return 'disabled';
      default:
        break;
    }
  }

  constructor() {}
}

export class TeamManager {
  // 收银源地址
  private static getBuyOrigun(isEn: boolean): string {
    if (isEn) {
      return 'https://www.mockplus.com';
    } else {
      return 'https://www.mockplus.cn';
    }
  }

  private static getPricePath(isEn: boolean): string {
    return isEn ? '/pricing/mockplus-rp' : '/buy/rp';
  }
  
  /**
   * 获取价格页面跳转链接
   * @param team 
   * @param isEn 
   * @returns 
   */
  public static getPriceLink(team?: ITeams, isEn: boolean = false): string {
    const origin = this.getBuyOrigun(isEn);
    const path = this.getPricePath(isEn);

    if(!team){
      return isEn 
        ? `${origin}${path}?license=ultimate&payment=annual` 
        : `${origin}${path}`;
    }
    
    let query = '';
    if (!isEn) {
      query = `?license=${TeamVersion.StartupRP}&payment=${RPTeamPayment.Annual}&teamID=${team.id}`;
    }
    return `${origin}${path}${query}`;
  }

  /**
   * 获取个人空间升级跳转链接
   * @param isEn 
   * @param isPayment  是否付费
   * @param isExpired 是否过期，付费版本且过期需要跳转至价格版本页面
   * @returns 
   */
  public static getPersonalSpaceUpgradeLink(isEn: boolean, isPayment?:boolean, isExpired?:boolean): string {
    const origin = this.getBuyOrigun(isEn);
    const path = isPayment && !isExpired ? '/buy/rp-order' : this.getPricePath(isEn);
    if (isEn) {
      const enPpath = this.getPricePath(isEn);
      return `${origin}${enPpath}?license=prorp&payment=annual`;
    }
    return `${origin}${path}?license=prorp`;
  }

  /**
   * 打开价格页
   * @param isEn 是否是英文版
   */
  public static openPricePage(team: ITeams, isEn: boolean): void {
    useLabelAOpenUrl(this.getPriceLink(team, isEn));
  }

  /**
   * 打开收银台
   * @param team 团队信息
   * @param isEn 是否是英文版
   * @param isExpansion 是否是扩容
   */
  public static openCashierPage(team: ITeams, isEn: boolean, isExpansion?: boolean): void {
    useLabelAOpenUrl(this.getCashierLink(team, isEn, isExpansion));
  }

  public static getCashierLink(team: ITeams, isEn: boolean, isExpansion?: boolean, isPerson?:boolean): string {
    return isExpansion ? this.getExpansoinUrl(team, isEn) : this.getUpgradeUrl(team, isEn, isPerson);
  }

  private static getCashierPath(version: TeamVersion | string, isEn: boolean): string {
    if (isEn) {
      return '/buy/mockplus-rp-design-order';
    }
    switch (version) {
      case TeamVersion.EntRP:
      case TeamVersion.SuperEnt:
      case TeamVersion.StartupRP:
      case TeamVersion.Ent:
      case VERSION_RP: // 尽可能避免与旧逻辑产生冲突 
        return '/buy/rp-order';
      case TeamVersion.Startup:
      case TeamVersion.entCC:
      case TeamVersion.startupCC:
      case VERSION_CC: // 判断需要跳转CC那边的
        return '/buy/order';
    }
    throw new Error('no version path');
  }

  private static getCashierQuery(teamID: string, version: TeamVersion, isEn: boolean): string {
    // 英文版固定升级版本
    const versionQuery = isEn ? TeamVersion.Ultimate : version;
    const payment = isEn ? RPTeamPayment.Monthly : RPTeamPayment.Annual;
    return `?license=${versionQuery}&payment=${payment}&teamID=${teamID}`;
  }

  /**
   * 团队升级
   * @param isEnVersion 是否是海外版
   * @param team? 团队信息
   */
  private static getUpgradeUrl(team: ITeams, isEnVersion: boolean, isPerson?:boolean): string {
    const { id, license, rpDesignPayment } = team;
    const { version } = license;
    const origin = this.getBuyOrigun(isEnVersion);

    if(isPerson){
      const isExpired = license.expired || new Date(license.expireTime).getTime() < new Date().getTime();
      return this.getPersonalSpaceUpgradeLink(isEnVersion, TeamManager.isRPPayment(team), isExpired)
    }

    // FIXME 特殊策略：https://rp.mockplus.cn/run/XjkJUZP2tLBY/HC4DdgJ8n?cps=hide&rps=hide&nav=1&ha=0&la=0&fc=0&out=1&rt=1
    // 免费计划为RP创业版且是永久的 - 特殊处理
    if (isPerpetual(rpDesignPayment)) {
      return this.getPriceLink(team, isEnVersion)
    }

    if(TeamManager.isOutsideBuy(team)){
      const path = this.getCashierPath(VERSION_CC, false);
      const query = this.getCashierQuery(id, team.license.version as TeamVersion, false);
      return `${origin}${path}${query}`;
    }

    // 捕获到版本链接获取异常，则跳转到价格页面
    try {
      // 新业务逻辑 - 未命中旧逻辑的情况下使用新业务逻辑
      // 当用户版本不是免费版，跳转到RP收银台
      if(!this.isRPFree(team)){
        const v = version as TeamVersion;
        const path = this.getCashierPath(VERSION_RP, isEnVersion);
        const query = this.getCashierQuery(id, v, isEnVersion);
        return `${origin}${path}${query}`;
      }
    } catch (error) { 
      console.log('no version path');
    }
    return this.getPriceLink(team, isEnVersion);
  }

  /**
   * 团队扩容
   * @param team 团队信息
   * @param isEnVersion 是否是海外版
   */
  private static getExpansoinUrl(team: ITeams, isEnVersion: boolean): string {
    const { id, license } = team;
    let version = license.version as TeamVersion;
    // 免费计划为RP创业版
    if (isPerpetual(team.rpDesiginPayment)) {
      version = TeamVersion.StartupRP;
    }
    const origin = this.getBuyOrigun(isEnVersion);
    const path = this.getCashierPath(version, isEnVersion);
    const query = this.getCashierQuery(id, version, isEnVersion);
    return `${origin}${path}${query}`;
  }

  public static getTeamExpireTime(team:ITeams, isEN:boolean = false):string {
    const isPayment = this.isRPPayment(team); // 团队付费版
    const isPaymentExpired =!isPayment || new Date().getTime() > new Date(team?.license?.expireTime).getTime();  //非付费或付费版本过期
    const expiredTime = isPaymentExpired && !this.isPerpetual(team) ? team.rpDesignExpireTime : team?.license?.expireTime;
    const localDate = new Date(expiredTime || '');
    //防止Invalid Date
    if(Number.isNaN(localDate.getTime())){
      return ''
    }
    // 时间格式本地化
    const M = localDate.getMonth() + 1;
    const D = localDate.getDate();
    const h = localDate.getHours();
    const m = localDate.getMinutes();
    const s = localDate.getSeconds();
    const toStr = function(v:number){
      return v < 10 ? '0' + v : v;
    }
    const decorate = isEN ? '/' : '/'; // 如果有中英版符号需求区分 - 暂无该区分
    return `${localDate.getFullYear()}${decorate}${toStr(M)}${decorate}${toStr(D)} ${toStr(h)}:${toStr(m)}:${toStr(s)}`;
  }

  /**
   * 是否永久使用权，付费过期判定为永久使用权，中英文都可使用
   */
  public static isPerpetual(team: ITeams):boolean {
    if(team?.license?.version === TeamVersion.Prorp){
      return isPerpetual(team.license?.payment);
    }else{
      const isEntPerpetual = team.license?.version === TeamVersion.Ent && isPerpetual(team.license?.payment);
      const freePerpetual = (!this.isRPPayment(team) || team.license.expired) && isPerpetual(team.rpDesignPayment)
      return isEntPerpetual || freePerpetual;
    }
  }
  
  /**
   * 没有升级空间的产品版本
   * 获得终身使用权限 - 包含个人和原型设计终身特权，中英文都可使用
   */
  public static notUpgrade(team: ITeams):boolean {
    const perpetual = isPerpetual(team.license?.payment);
    if(team?.license?.version === TeamVersion.Prorp){
      return perpetual || !this.isAnnual(team);
    }else{
      const isEntPerpetual = team.license?.version === TeamVersion.Ent && perpetual; // 原型设计终身特权
      return isEntPerpetual;
    }
  }

  /**
   * 是否获得了  个人空间包年 否则 个人终身
   */
  public static isAnnual(team: ITeams):boolean {
    return team.license.payment === RPTeamPayment.Annual;
  }


  /**
   * 是否为RP付费版本 - 包含英文版
   * @param team 
   */
  public static isRPPayment(team: ITeams){
    const isPersonAnnual = TeamVersion.Prorp === team.license.version && this.isAnnual(team); // 个人包年
    return isPersonAnnual || [
      TeamVersion.Ent,
      TeamVersion.EntRP,
      TeamVersion.Startup,
      TeamVersion.StartupRP,
      TeamVersion.SuperEnt,
      TeamVersion.Ultimate, // 英文版的
    ].includes(team.license.version as TeamVersion);
  }

  /**
   * RP免费版 - 包含以下
    1、中文版
      1）CC企业版
      2）CC创业版
      3）免费版
      4）RP创业版（活动 - 无限包过期）
    2、英文版
      1）Pro版
      2）Basic版
      3）PS-Free版
    3  所有过期版本
   * @param team 
   * @returns 
   */
  public static isRPFree(team:ITeams){
    const isPayment = this.isRPPayment(team);
    return (!isPayment || team.license.expired) && freeProgramHasExpired(team.rpDesignPayment, team.rpDesignExpireTime);
  }

  public static isCCVersion(team:ITeams){
    if(team.license.version === TeamVersion.startupCC || team.license.version === TeamVersion.entCC){
      return true;
    }
    return false;
  }

  /**
   * 是否外部打开链接购买， 或者以下版本及未过期的情况 
   * @param team 
   * @return  true 跳转到CC价格版本页，否则跳转RP价格版本页
   */
  public static isOutsideBuy(team:ITeams):boolean{
    const isCCbuy = [
      TeamVersion.entCC, 
      TeamVersion.startupCC, 
      TeamVersion.Ent, 
      TeamVersion.Startup
    ].includes(team.license.version as TeamVersion);
    const isExpired = team.license.expired || new Date(team.license.expireTime).getTime() < new Date().getTime();
    if(isCCbuy && !isExpired){
      return true;
    }
    return false;
  }
}

/**
 * 是否是免费版
 * 1.中文免费
 * 2.英文免费
 * 3.过期
 * @param team
 */
export function isFreeTeam(team?: ITeams | null): boolean {
  if (!team) {
    // 没有团队默认为免费团队
    return true;
  }
  const {
    license: { version, expired },
  } = team;
  return ['free', 'enFree'].includes(version) || expired;
}

// RP价格策略功能
export const FUNCTIONAL_FEATURES = {
  CreateProject: 'CreateProject', // 创建项目
  CreatePage: 'CreatePage', //创建页面
  HistoricalVersion: 'HistoricalVersion', // 历史版本
  ExportPicture: 'ExportPicture', // 导出图片
  ExportMRPFile: 'ExportMRPFile', // 导出MRP文件
  ExportOffline: 'ExportOffline', // 导出离线演示包
  ExportArtboard: 'ExportArtboard', // 导出画板
  SharingResource: 'SharingResource', // 设计资源分享
};

export enum LangVersion {
  CN = 'zh-cn',
  EN = 'en-us',
}

interface IFeatureLimitTable {
  [key: string]: { [key: string]: { [key: string]: number } };
}

// RP功能限制表
export const FEATURE_LIMIT_TABLE: IFeatureLimitTable = {
  [LangVersion.CN]: {
    [FUNCTIONAL_FEATURES.CreateProject]: {
      [TeamVersion.Free]: 3,
      [TeamVersion.startupCC]: 3,
      [TeamVersion.entCC]: 3,
      [TeamVersion.Startup]: Infinity,
      [TeamVersion.Ent]: Infinity,
      [TeamVersion.StartupRP]: 100,
      [TeamVersion.EntRP]: Infinity,
      [TeamVersion.SuperEnt]: Infinity,
    },
    [FUNCTIONAL_FEATURES.CreatePage]: {
      [TeamVersion.Free]: 15,
      [TeamVersion.startupCC]: 15,
      [TeamVersion.entCC]: 15,
      [TeamVersion.Startup]: Infinity,
      [TeamVersion.Ent]: Infinity,
      [TeamVersion.StartupRP]: Infinity,
      [TeamVersion.EntRP]: Infinity,
      [TeamVersion.SuperEnt]: Infinity,
    },
    [FUNCTIONAL_FEATURES.HistoricalVersion]: {
      [TeamVersion.Free]: 7,
      [TeamVersion.startupCC]: 7,
      [TeamVersion.entCC]: 7,
      [TeamVersion.Startup]: Infinity,
      [TeamVersion.Ent]: Infinity,
      [TeamVersion.StartupRP]: Infinity,
      [TeamVersion.EntRP]: Infinity,
      [TeamVersion.SuperEnt]: Infinity,
    },
    [FUNCTIONAL_FEATURES.ExportPicture]: {
      [TeamVersion.Free]: 0,
      [TeamVersion.startupCC]: 0,
      [TeamVersion.entCC]: 0,
      [TeamVersion.Startup]: Infinity,
      [TeamVersion.Ent]: Infinity,
      [TeamVersion.StartupRP]: Infinity,
      [TeamVersion.EntRP]: Infinity,
      [TeamVersion.SuperEnt]: Infinity,
    },
    [FUNCTIONAL_FEATURES.ExportMRPFile]: {
      [TeamVersion.Free]: 0,
      [TeamVersion.startupCC]: 0,
      [TeamVersion.entCC]: 0,
      [TeamVersion.Startup]: Infinity,
      [TeamVersion.Ent]: Infinity,
      [TeamVersion.StartupRP]: Infinity,
      [TeamVersion.EntRP]: Infinity,
      [TeamVersion.SuperEnt]: Infinity,
    },
    [FUNCTIONAL_FEATURES.ExportOffline]: {
      [TeamVersion.Free]: 0,
      [TeamVersion.startupCC]: 0,
      [TeamVersion.entCC]: 0,
      [TeamVersion.Startup]: Infinity,
      [TeamVersion.Ent]: Infinity,
      [TeamVersion.StartupRP]: Infinity,
      [TeamVersion.EntRP]: Infinity,
      [TeamVersion.SuperEnt]: Infinity,
    },
    [FUNCTIONAL_FEATURES.ExportArtboard]: {
      [TeamVersion.Free]: 0,
      [TeamVersion.startupCC]: 0,
      [TeamVersion.entCC]: 0,
      [TeamVersion.Startup]: Infinity,
      [TeamVersion.Ent]: Infinity,
      [TeamVersion.StartupRP]: Infinity,
      [TeamVersion.EntRP]: Infinity,
      [TeamVersion.SuperEnt]: Infinity,
    },
    [FUNCTIONAL_FEATURES.SharingResource]: {
      [TeamVersion.Free]: 0,
      [TeamVersion.startupCC]: 0,
      [TeamVersion.entCC]: 0,
      [TeamVersion.Startup]: Infinity,
      [TeamVersion.Ent]: Infinity,
      [TeamVersion.StartupRP]: Infinity,
      [TeamVersion.EntRP]: Infinity,
      [TeamVersion.SuperEnt]: Infinity,
    },
  },
  [LangVersion.EN]: {
    [FUNCTIONAL_FEATURES.CreateProject]: {
      [TeamVersion.Free]: 3,
      [TeamVersion.Pro]: 3,
      [TeamVersion.Prorp]: Infinity,
      [TeamVersion.Ultimate]: Infinity,
    },
    [FUNCTIONAL_FEATURES.CreatePage]: {
      [TeamVersion.Free]: 10,
      [TeamVersion.Pro]: 10,
      [TeamVersion.Prorp]: Infinity,
      [TeamVersion.Ultimate]: Infinity,
    },
    [FUNCTIONAL_FEATURES.HistoricalVersion]: {
      [TeamVersion.Free]: 7,
      [TeamVersion.Pro]: 7,
      [TeamVersion.Prorp]: Infinity,
      [TeamVersion.Ultimate]: Infinity,
    },
    [FUNCTIONAL_FEATURES.ExportPicture]: {
      [TeamVersion.Free]: 0,
      [TeamVersion.Pro]: Infinity,
      [TeamVersion.Prorp]: Infinity,
      [TeamVersion.Ultimate]: Infinity,
    },
    [FUNCTIONAL_FEATURES.ExportMRPFile]: {
      [TeamVersion.Free]: 0,
      [TeamVersion.Pro]: 0,
      [TeamVersion.Prorp]: Infinity,
      [TeamVersion.Ultimate]: Infinity,
    },
    [FUNCTIONAL_FEATURES.ExportArtboard]: {
      [TeamVersion.Free]: Infinity,
      [TeamVersion.Pro]: Infinity,
      [TeamVersion.Prorp]: Infinity,
      [TeamVersion.Ultimate]: Infinity,
    },
    [FUNCTIONAL_FEATURES.ExportOffline]: {
      [TeamVersion.Free]: 0,
      [TeamVersion.Pro]: 0,
      [TeamVersion.Prorp]: Infinity,
      [TeamVersion.Ultimate]: Infinity,
    },
    [FUNCTIONAL_FEATURES.SharingResource]: {
      [TeamVersion.Free]: 0,
      [TeamVersion.Pro]: Infinity,
      [TeamVersion.Prorp]: Infinity,
      [TeamVersion.Ultimate]: Infinity,
    },
  },
  [TeamVersion.Prorp]: { // 仅针对个人空间
    [FUNCTIONAL_FEATURES.CreateProject]: {
      [RPTeamPayment.Free]: 3,
      [RPTeamPayment.Annual]: Infinity,
      [RPTeamPayment.Perpetual]: Infinity,
    },
    [FUNCTIONAL_FEATURES.CreatePage]: {
      [RPTeamPayment.Free]: 15,
      [RPTeamPayment.Annual]: Infinity,
      [RPTeamPayment.Perpetual]: Infinity,
    },
    [FUNCTIONAL_FEATURES.HistoricalVersion]: {
      [RPTeamPayment.Free]: 7,
      [RPTeamPayment.Annual]: Infinity,
      [RPTeamPayment.Perpetual]: Infinity,
    },
    [FUNCTIONAL_FEATURES.ExportPicture]: {
      [RPTeamPayment.Free]: 0,
      [RPTeamPayment.Annual]: Infinity,
      [RPTeamPayment.Perpetual]: Infinity,
    },
    [FUNCTIONAL_FEATURES.ExportMRPFile]: {
      [RPTeamPayment.Free]: Infinity,
      [RPTeamPayment.Annual]: Infinity,
      [RPTeamPayment.Perpetual]: Infinity,
    },
    [FUNCTIONAL_FEATURES.ExportArtboard]: {
      [RPTeamPayment.Free]: 0,
      [RPTeamPayment.Annual]: Infinity,
      [RPTeamPayment.Perpetual]: Infinity,
    },
    [FUNCTIONAL_FEATURES.ExportOffline]: {
      [RPTeamPayment.Free]: 0,
      [RPTeamPayment.Annual]: Infinity,
      [RPTeamPayment.Perpetual]: Infinity,
    },
    [FUNCTIONAL_FEATURES.SharingResource]: {
      [RPTeamPayment.Free]: 0,
      [RPTeamPayment.Annual]: Infinity,
      [RPTeamPayment.Perpetual]: Infinity,
    },
  },
};

/**
 * 是否为永久使用
 * @param rpDesignPayment 
 * @returns 
 */
export function isPerpetual(rpDesignPayment?: string) {
  return rpDesignPayment === RPTeamPayment.Perpetual;
}

/**
 * 无限设计包(RP免费计划获取的创业版) 判断权限是否过期
 */
export function freeProgramHasExpired(rpDesignPayment?: string, rpDesignExpireTime?: Date) {
  if (isPerpetual(rpDesignPayment)) {
    return false;
  }
  if (rpDesignPayment === RPTeamPayment.TimeLimit) {
    return rpDesignExpireTime ? new Date() > new Date(rpDesignExpireTime || 0) : false;
  }
  return true;
}

/**
 * 当前为rp创业版，不区分灰色的还是蓝色图标的
 */
export function isStartupRP(team: ITeams, isEN?: boolean) {
  const { license, rpDesignExpireTime, rpDesignPayment } = team;
  const { version, expired } = license;

  if (expired) {
    // 版本过期，但无限设计包未过期
    if (!freeProgramHasExpired(rpDesignPayment, rpDesignExpireTime)) {
      return true;
    }
    return false;
  }
  // 无限设计包没过期等同于RP新版创业
  if (!isEN && version === TeamVersion.Free && !freeProgramHasExpired(rpDesignPayment, rpDesignExpireTime)) {
    return true;
  }

  return version === TeamVersion.StartupRP;
}

/**
 * RP价格策略功能限制数量
 * 返回结果：数字代表有限制，0代表不支持该功能，Infinity无限制
 */
export function getFeatureLimit(feature: string, team?: ITeams | null, isEN?: boolean): number {
  if (!team) {
    return 0;
  }
  const { license, rpDesignExpireTime, rpDesignPayment } = team;
  const { version, expired, expireTime } = license;
  const lang = isEN ? LangVersion.EN : LangVersion.CN;

  // 个人空间
  if(license.version === TeamVersion.Prorp){
    // 个人空间过期
    if(expired || new Date().getTime() > new Date(expireTime).getTime()){
      return FEATURE_LIMIT_TABLE[TeamVersion.Prorp][feature][TeamVersion.EnFree];
    }
    return FEATURE_LIMIT_TABLE[TeamVersion.Prorp][feature][license.payment];
  }

  if (expired) {
    // 版本过期，但无限设计包未过期
    if (!freeProgramHasExpired(rpDesignPayment, rpDesignExpireTime)) {
      return FEATURE_LIMIT_TABLE[lang][feature][TeamVersion.StartupRP];
    }
    return FEATURE_LIMIT_TABLE[lang][feature][TeamVersion.Free];
  }
  // 无限设计包没过期等同于RP新版创业
  if (!isEN && version === TeamVersion.Free && !freeProgramHasExpired(rpDesignPayment, rpDesignExpireTime)) {
    return FEATURE_LIMIT_TABLE[lang][feature][TeamVersion.StartupRP];
  }
  return FEATURE_LIMIT_TABLE[lang][feature][version];
}

/**
 * 使用a标签打开链接 -- 处理腾讯会议中需要打开外部浏览器问题
 * @param href 链接
 * @param target 打开方式
 */
export function useLabelAOpenUrl(href: string, target: '_blank' | '_self' = '_blank') {
  const link = document.createElement('a');
  link.href = href;
  link.target = target;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

/**
 * 检测当前团队是否是付费团队
 * @param team 团队信息
 * @param isEN
 */
function checkIsPayTeam(team?: ITeams, isEN = false) {
  if (!team) {
    return false;
  }

  const { version, expired } = team.license;

  if (expired) {
    return false;
  }

  // 个人空间
  if (team.teamType === 'personal') {
    return version === TeamVersion.Prorp;
  }

  // 英文团队版
  if (isEN) {
    return ![TeamVersion.Free, TeamVersion.Pro].includes(version as TeamVersion);
  }

  // 中文团队版
  return ![TeamVersion.Free, TeamVersion.startupCC, TeamVersion.entCC].includes(version as TeamVersion);
}

/**
 * 比较时间
 */
function timeSortCompare(a: string, b: string) {
  const timeA = new Date(a).getTime();
  const timeB = new Date(b).getTime();
  return timeA > timeB;
}

/**
 * 从所有团队中选取付费到期时间最久的团队
 * @param teams 
 * @param isEN
 */
export function selectUpgradePayTeam(teams: ITeams[], isEN?: boolean): ITeams | undefined {
  let res;
  for (let i = 0; i < teams.length; i++) {
    const team = teams[i];
    if (!checkIsPayTeam(team, isEN)) {
      continue;
    }
    if (team.teamType === 'personal') {
      // 个人空间终生版
      if (team.license.payment === RPTeamPayment.Perpetual) {
        return team;
      }
    }
    if (!res) {
      res = team;
    } else if (timeSortCompare(team.license.expireTime as unknown as string, res.license.expireTime as unknown as string)) {
      res = team;
    }
  }
  return res;
}

/**
 * 判断当前团队是否为非免费团队
 * @param teams 所以团队信息
 * @param teamId 当前团队信息
 * @param isEN 当前中英文版本
 */
export function checkUpgradeSuc(teams: ITeams[], teamId: string, isEN = false): boolean {
  const item = teams.find((team) => team.id === teamId);
  return checkIsPayTeam(item, isEN);
}