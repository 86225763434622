import * as React from 'react';
// import * as moment from 'moment';

import i18n from '../../../i18n';
import { rpFreeProgressInviteCount } from '../../../constants/rpFreeProgram';

import './index.scss';

import * as RPTitlePicture from '../../../assets/images/rp_free_program_title.png';

const Header: React.FC = () => {
  return (
    <div className="rp-free-dialog-header">
      <div className="title">
        <img src={RPTitlePicture} alt="" width={'100%'} height={'100%'} />
      </div>
      <div className="desc">{i18n('rpActivity.inviteToGetFree', rpFreeProgressInviteCount)}</div>
      <div className="time">{i18n('rpActivity.inviteTimeStr')}</div>
      <div className="sub-desc">
        {/*{i18n(*/}
        {/*'rpActivity.deadline',*/}
        {/*moment(rpFreeDeadline).format(window.LANG === MockPlusLang.EN ? 'YYYY.MM.DD' : `YYYY年MM月DD日`),*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default Header;
