import { IComponentData } from '@/fbs/rp/models/component';
import { IChartValue } from '@/fbs/rp/models/chart';
import { makeCommonComponent } from '@/libs/helper';

import { IComponentItem } from '../../../types';
import { CCombinationChart } from '../../../constants';
import { getChartConfig, makeChartData, getSeriesChartOneDefaultData } from '../common';

export const CombinationChartConfig: IComponentItem = getChartConfig(CCombinationChart);

export function makeCombinationChart(id: string): IComponentData {
  return makeChartData(id, CCombinationChart);
}

export function makeAICombinationChart(id: string, value: IChartValue): IComponentData {
  return makeCommonComponent(id, CCombinationChart, {
    ...getSeriesChartOneDefaultData(CCombinationChart),
    size: {
      width: 400,
      height: 250,
    },
    lib: {
      id: 'chart',
      type: CCombinationChart,
    },
    value: value,
  });
}
