import * as React from 'react';
import * as ReactDOM from 'react-dom';
import classnames from 'classnames';

import { Icon } from '@dsm';

import './index.scss';

export type ToastType = '' | 'success' | 'error' | 'warning' | 'info';

interface IToastMessageProps {
  message: string;
  iconPosition?: 'left' | 'top';
  type?: ToastType;
  theme?: 'dark' | 'light';
  className?: string;
}

const ToastMessage = (props: IToastMessageProps) => {
  const getMessageIconName = (type?: ToastType) => {
    switch (type) {
      case 'success':
        return 'icon_tag_resolved';
      default:
        return 'tag_exclamation';
    }
  };
  const { type, message, iconPosition, className, theme } = props;
  return ReactDOM.createPortal(
    <div
      className={classnames(
        'global-toast-message',
        className,
        type,
        `icon-${iconPosition || 'left'}`,
        `theme-${theme || 'light'}`,
      )}
    >
      {type && <Icon size={14} className="global-toast-message-icon" cls={getMessageIconName(type)} theme="tag" />}
      <span className="global-toast-message-text">{message}</span>
    </div>,
    document.getElementById('notice-container') as HTMLDivElement,
  );
};

export default ToastMessage;
