import PropertyBase from './base';

export default interface IMultiText extends PropertyBase {
  lineHeight?: number;
  lineHeightEx?: number;
  wrap: boolean;
  indent?: boolean;
  vertical?: boolean;
}

export const MultiTextPropertyName = 'multiText';
