import * as _ from 'lodash';

import { ETextBehaviour } from '@fbs/rp/models/component';

import { UIComponent, IFindResult } from '.';
import { PropertyName, PropertyValue } from '@fbs/rp/models/property';
import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { measureTextSize } from '@utils/textUtils';
import { TextAlign, VerticalAlign } from '@fbs/rp/models/properties/text';
import { IBoundsOffset, ISize } from '@fbs/common/models/common';
import IStroke from '@fbs/rp/models/properties/stroke';
import ITextFormatEx from '@fbs/rp/models/properties/textFormat';
import {
  ComponentChange,
  ComponentChangeType,
  ComponentResizeResult,
  getNewPositionWhenCenter,
  ResizeOptions,
} from '@editor/comps/resizeHelper';
import { mergePatches } from '@helpers/patchHelper';
import SelectionFrameType from '@consts/enums/selectionBoxType';
import { CLine } from '@libs/constants';

export default class UITextComponent extends UIComponent {
  private adjustSize(textBehaviour: ETextBehaviour): ArtboardPatches {
    const { id } = this;
    const autoSize = textBehaviour !== ETextBehaviour.Both;
    const patches: ArtboardPatches = {
      do: {
        [id]: [Ops.replace('/textBehaviour', textBehaviour), Ops.replace('/autoSize', autoSize)],
      },
      undo: {
        [id]: [Ops.replace('/textBehaviour', this.textBehaviour), Ops.replace('/autoSize', this.autoSize)],
      },
    };
    // 1、从宽或高变为自由
    if (textBehaviour === ETextBehaviour.Both || this.textBehaviour === ETextBehaviour.Width) {
      return patches;
    }
    const size = this.size;
    const position = this.position;
    const { textFormat } = this.properties;
    const text = this.value as string;
    const style = this.getStyleToCalculateSize({ textFormat });
    const isWrap = textBehaviour !== ETextBehaviour.Width;
    const { width, height } = measureTextSize(style, text, {
      wrap: isWrap,
      defaultWidth: textBehaviour !== ETextBehaviour.Width ? size.width : undefined,
      isMultiText: true,
      isRich: true, // 保证文本组件设置自动高度时，识别空格空间准确计算高度
    });
    const offset = { x: width - size.width, y: height - size.height };
    const newPosition = { ...position };
    const align = textFormat?.textAlign || TextAlign.left;
    const vertical = textFormat?.verticalAlign || VerticalAlign.top;
    switch (align) {
      case TextAlign.center:
        newPosition.x -= offset.x / 2;
        break;
      case TextAlign.right:
        newPosition.x -= offset.x;
        break;
      default:
        break;
    }
    switch (vertical) {
      case VerticalAlign.middle:
        newPosition.y -= offset.y / 2;
        break;
      case VerticalAlign.bottom:
        newPosition.y -= offset.y;
        break;
      default:
        break;
    }

    const centerPosition = getNewPositionWhenCenter(this, newPosition, { width, height }, this.parentSize);
    const compChanges: ComponentChange[] = [
      {
        id,
        type: ComponentChangeType.Edit,
        position: centerPosition,
        size: { width, height },
        rotate: this.rotate || 0,
      },
    ];
    const { patches: boundsPatches } = this.parent!.getPositionPatchesOfChildrenChanged(compChanges, true);
    if (boundsPatches) {
      mergePatches(patches, boundsPatches);
    }
    const path = this.getCurrentPropertiesPath('/properties/textFormat');
    patches.do[id].push(Ops.replace(path, { ...textFormat, wrap: true }));
    patches.undo[id].push(Ops.replace(path, textFormat));

    return patches;
  }

  get selectFrameType() {
    if (this.type === CLine) {
      return super.selectFrameType;
    }
    switch (this.textBehaviour) {
      case ETextBehaviour.Width:
        return SelectionFrameType.leftMiddle_to_rightMiddle;
      default:
        return this.size.lockedRatio ? SelectionFrameType.corner : SelectionFrameType.box;
    }
  }

  get textBehaviour(): ETextBehaviour {
    const value = this.data.textBehaviour;
    if (this.dynamicInfo?.size) {
      return value === ETextBehaviour.Width ? ETextBehaviour.Height : ETextBehaviour.Both;
    }
    if (_.isUndefined(value)) {
      if (this.autoSize) {
        return ETextBehaviour.Width;
      }
      return ETextBehaviour.Both;
    }
    return value;
  }

  setProperty(
    propertyName: PropertyName,
    newValue: PropertyValue,
    ignoreUpdateValue?: boolean,
    childPropName?: string,
  ): ArtboardPatches {
    if (propertyName === 'textBehaviour') {
      return this.adjustSize(newValue.value as ETextBehaviour);
    }
    return super.setProperty(propertyName, newValue, ignoreUpdateValue, childPropName);
  }

  setTextHeightToPatch = (text: string, width?: number): ArtboardPatches => {
    const textFormat = this.properties.textFormat;
    const defaultWidth = Math.max(width || 300, this.size.width);
    const style = this.getStyleToCalculateSize({ textFormat });
    const { height: newHeight } = measureTextSize(style, text, { isMultiText: true, wrap: true, defaultWidth });
    return {
      do: {
        [this.id]: [
          Ops.replace('/textBehaviour', 'height'),
          Ops.replace('./size', { width: defaultWidth, height: newHeight }),
        ],
      },
      undo: {
        [this.id]: [Ops.replace('/textBehaviour', this.textBehaviour)],
      },
    };
  };

  getResizeMySelfResult(offset: IBoundsOffset, resizeOptions?: ResizeOptions): ComponentResizeResult {
    const { id, textBehaviour } = this;
    const result = super.getResizeMySelfResult(offset, resizeOptions);
    const path = '/textBehaviour';
    let targetTextBehaviour: ETextBehaviour = textBehaviour;
    if (textBehaviour === ETextBehaviour.Width) {
      targetTextBehaviour = ETextBehaviour.Height;
    } else if (textBehaviour === ETextBehaviour.Height) {
      if (offset.top || offset.bottom) {
        targetTextBehaviour = ETextBehaviour.Both;
      }
    }
    if (targetTextBehaviour !== textBehaviour) {
      result.patches = {
        do: {
          [id]: [Ops.replace(path, targetTextBehaviour), Ops.replace('/size', result.size)],
        },
        undo: {
          [id]: [Ops.replace(path, textBehaviour), Ops.replace('/size', this.toJSON().size)],
        },
      };
    }

    return result;
  }

  updateSizeWhenResizeText(newSize: ISize, oldSize: ISize) {
    let textBehaviour: ETextBehaviour = this.textBehaviour;
    if (textBehaviour === ETextBehaviour.Width) {
      textBehaviour = ETextBehaviour.Height;
    } else if (textBehaviour === ETextBehaviour.Height) {
      if (newSize.height !== oldSize.height) {
        textBehaviour = ETextBehaviour.Both;
      }
    }
    if (textBehaviour !== ETextBehaviour.Both) {
      const defaultWidth = newSize.width; // textBehaviour === ETextBehaviour.Height ? oldSize.width: undefined;
      const textFormat = this.properties.textFormat;
      const text = this.value as string;
      const style = this.getStyleToCalculateSize({ textFormat });
      const { height } = measureTextSize(style, text, { isMultiText: true, wrap: true, defaultWidth });
      newSize.height = height;
    }

    // window.debug && console.log('覆盖的方法，仅在开发时打印', newSize, oldSize);
  }

  updateTextBehaviourWhenSizeChange(newSize: ISize, oldSize: ISize): ArtboardPatches | null {
    const textBehaviour = this.textBehaviour;
    const { id } = this;
    const path = '/textBehaviour';
    switch (textBehaviour) {
      case ETextBehaviour.Both:
        return null;
      case ETextBehaviour.Width:
        return {
          do: {
            [id]: [Ops.replace(path, ETextBehaviour.Height)],
          },
          undo: {
            [id]: [Ops.replace(path, textBehaviour)],
          },
        };
      case ETextBehaviour.Height: {
        if (newSize.height !== oldSize.height) {
          return {
            do: {
              [id]: [Ops.replace(path, ETextBehaviour.Both)],
            },
            undo: {
              [id]: [Ops.replace(path, textBehaviour)],
            },
          };
        } else {
          return null;
        }
      }
    }
    return null;
  }

  updateBoundsWithTextChange(
    newText: string,
    option: { stroke?: IStroke; textFormat?: ITextFormatEx; autoFill?: boolean; defaultWidth?: number },
  ): ArtboardPatches {
    const patches = super.updateBoundsWithTextChange(newText, option);
    if (option.autoFill) {
      return patches;
    }
    const textBehaviour = this.textBehaviour;
    if (textBehaviour !== ETextBehaviour.Both) {
      const { textFormat, stroke } = option;
      const style = this.getStyleToCalculateSize({ textFormat, stroke });
      const size = this.size;
      const isVertical = this.properties.textFormat?.vertical;
      const defaultWidth = textBehaviour !== ETextBehaviour.Width ? (isVertical ? size.height : size.width) : undefined;
      const { width, height } = measureTextSize(style, newText, {
        defaultWidth: textFormat!.vertical ? undefined : defaultWidth,
        defaultHeight: textFormat!.vertical ? defaultWidth : undefined,
        isMultiText: true,
        wrap: textBehaviour !== ETextBehaviour.Width,
      });
      if (!patches.do[this.id]) {
        patches.do[this.id] = [];
      }
      if (!patches.undo[this.id]) {
        patches.undo[this.id] = [];
      }
      patches.do[this.id].push(Ops.replace('/size', { ...size, width, height }));
      patches.undo[this.id].push(Ops.replace('/size', size));
    } else {
      if (patches.do[this.id]) {
        patches.do[this.id] = patches.do[this.id].filter((item) => !item.path.includes('/size'));
      }
      if (patches.undo[this.id]) {
        patches.undo[this.id] = patches.undo[this.id].filter((item) => !item.path.includes('/size'));
      }
    }

    return patches;
  }

  public matchText(text: string, pageID: string): IFindResult[] {
    const currentRichTextValue = this.value as string;
    return this.doMatchRichText(currentRichTextValue, text, pageID);
  }

  public replaceText(matchInfo: IFindResult['match'], text: string, newText: string): ArtboardPatches {
    const currentRichTextValue = this.value as string;
    const newValue = this.doReplaceRichText(currentRichTextValue, matchInfo, text, newText);
    const patches = this.setValue(newValue);
    window.debug && console.log('replaceText patches: ', patches);
    return patches;
  }

  public replaceAllText(matchInfo: IFindResult['match'], text: string, newText: string): ArtboardPatches {
    const currentRichTextValue = this.value as string;
    const newValue = this.doReplaceAllRichText(currentRichTextValue, matchInfo, text, newText);
    const patches = this.setValue(newValue);
    window.debug && console.log('replaceText patches: ', patches);
    return patches;
  }
}
