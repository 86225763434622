import * as React from 'react';
import classnames from 'classnames';

import Tooltip from '../Tooltip';
import './index.scss';

import IconAxure from './svgs/20_doc_axure.svg';

import IconDoc from './svgs/20_doc_doc.svg';
import IconPRD from './svgs/20_doc_prd.svg';
import IconAi from './svgs/20_app_ai.svg';
import IconExcel from './svgs/20_doc_excel.svg';
import IconJustinmind from './svgs/20_doc_justinmind.svg';
import IconMockplus from './svgs/20_doc_mockplus.svg';
import IconPdf from './svgs/20_doc_pdf.svg';
import IconPic from './svgs/20_doc_pic.svg';
import IconPpt from './svgs/20_doc_ppt.svg';
import IconPS from './svgs/20_doc_PS.svg';
import IconTxt from './svgs/20_doc_txt.svg';
import IconWord from './svgs/20_doc_word.svg';
import IconXD from './svgs/20_doc_XD.svg';
import IconZip from './svgs/20_doc_zip.svg';
import IconAddfilePic from './svgs/80_addfile_pic.svg';
import IconAddfilePS from './svgs/80_addfile_PS.svg';
import IconAddfileSketch from './svgs/80_addfile_sketch.svg';
import IconAddfileXD from './svgs/80_addfile_XD.svg';

import IconAddfilePS30 from './svgs/30_addfile_PS.svg';
import IconAddfileSketch30 from './svgs/30_addfile_Sketch.svg';
import IconAddfileXD30 from './svgs/30_addfile_XD.svg';
import IconAddfileAxure30 from './svgs/30_addfile_Axure.svg';
import IconAddfileSketchGray from './svgs/30_addfile_sketch_gray.svg';
import IconAddfilePSGray from './svgs/30_addfile_PS_gray.svg';
import IconAddfileXDGray from './svgs/30_addfile_XD_gray.svg';
import IconAddfileAxureGray from './svgs/30_addfile_Axure_gray.svg';
import IconAddfileFigma30 from './svgs/30_addfile_Figma.svg';

import IconIDoc from './svgs/20_doc_idoc.svg';
import IconConfluence from './svgs/20_doc_confluence.svg';
import IconAppAxure from './svgs/20_app_axure.svg';
import IconAppMockplus from './svgs/20_app_mockplus.svg';

const AllColorfulIcons: {
  [name: string]: string;
} = {
  doc_axure: IconAxure,
  doc_ai: IconAi,
  doc_doc: IconDoc,
  doc_excel: IconExcel,
  doc_justinmind: IconJustinmind,
  doc_mockplus: IconMockplus,
  doc_pdf: IconPdf,
  doc_pic: IconPic,
  doc_ppt: IconPpt,
  doc_PS: IconPS,
  doc_txt: IconTxt,
  doc_word: IconWord,
  doc_XD: IconXD,
  doc_zip: IconZip,
  doc_idoc: IconIDoc,
  doc_confluence: IconConfluence,
  doc_prd: IconPRD,
  addfile_pic: IconAddfilePic,
  addfile_PS: IconAddfilePS,
  addfile_Sketch: IconAddfileSketch,
  addfile_XD: IconAddfileXD,

  addfile_PS_30: IconAddfilePS30,
  addfile_Sketch_30: IconAddfileSketch30,
  addfile_XD_30: IconAddfileXD30,
  addfile_Axure_30: IconAddfileAxure30,
  addfile_Sketch_gray: IconAddfileSketchGray,
  addfile_PS_gray: IconAddfilePSGray,
  addfile_XD_gray: IconAddfileXDGray,
  addfile_Axure_gray: IconAddfileAxureGray,
  addfile_Figma: IconAddfileFigma30,

  app_axure: IconAppAxure,
  app_mockplus: IconAppMockplus,
};

export interface IImgIconProps {
  cls: string;
  disabled?: boolean;
  width?: number | string;
  height?: number | string;
  tooltip?: string;
  className?: string;
  onClick?: React.MouseEventHandler;
}

const ImgIcon: React.FC<IImgIconProps> = (props: IImgIconProps) => {
  const { cls, tooltip, width, height, className, onClick, disabled } = props;
  const iconComp: any = (
    <img
      className={classnames(className, {
        disabled,
      })}
      width={width}
      height={height}
      src={AllColorfulIcons[cls]}
      alt={tooltip}
      onClick={onClick}
    />
  );

  if (tooltip) {
    return (
      <Tooltip text={tooltip} theme="small">
        {iconComp}
      </Tooltip>
    );
  }
  return iconComp;
};

ImgIcon.defaultProps = {
  cls: 'doc_axure',
  width: 20,
  height: 20,
  tooltip: '',
};

export default ImgIcon;
