import { MessageAction } from './actions';
import { ActionType } from '../types';

export interface IMessageState {
  appUnReadCount: number; // 普通未读消息
  appUnReadCountSender: number; // 关于我的未读消息
  memberJoinAppNum: number; // 项目申请数量
  systemUnReadCount: number;
  teamUnReadCount: number;
}

const initState: IMessageState = {
  appUnReadCount: 0,
  appUnReadCountSender: 0,
  memberJoinAppNum: 0,
  systemUnReadCount: 0,
  teamUnReadCount: 0,
};

export default (state: IMessageState = initState, action: MessageAction): IMessageState => {
  const { appUnReadCount, appUnReadCountSender, memberJoinAppNum } = state;
  switch (action.type) {
    case ActionType.Message_Get_UnreadCount: {
      return action.payload;
    }
    case ActionType.Message_Read_App: {
      return {
        ...state,
        appUnReadCount: Math.max(0, appUnReadCount - 1),
      };
    }
    case ActionType.Message_Read_App_About_Me: {
      return {
        ...state,
        appUnReadCountSender: Math.max(0, appUnReadCountSender - 1),
      };
    }
    case ActionType.Message_Resolve_Join: {
      return {
        ...state,
        memberJoinAppNum: Math.max(0, memberJoinAppNum - 1),
      };
    }
    case ActionType.Message_Read_All: {
      return {
        ...state,
        appUnReadCount: 0,
      };
    }
    case ActionType.Message_Read_All_About_Me: {
      return {
        ...state,
        appUnReadCountSender: 0,
      };
    }
    default:
      break;
  }
  return state;
};
