// 逻辑代码迁移完成后，将 UIGroupComponent 干掉！！！
import { depthClone, min, removeItemFromArray, round, sameNumber, isEqual0 } from '@utils/globalUtils';
import * as BoundsUtils from '@utils/boundsUtils';

import { ArtboardPatches, Ops, PagePatches } from '@fbs/rp/utils/patch';
import { IComponentData, IComponentSize } from '@fbs/rp/models/component';
import { MoveDelta } from '@fbs/common/models/resize';
import { IBasicLayout } from '@fbs/rp/models/layout';
import { IBoundsOffset, IPosition, ISize, IBounds } from '@fbs/common/models/common';
import { PropertyName } from '@/fbs/rp/models/properties/base';
import { IProperties, PropertyValue } from '@/fbs/rp/models/property';
import { FillPropertyName } from '@/fbs/rp/models/properties/fill';
import { StrokePropertyName } from '@/fbs/rp/models/properties/stroke';
import { ShadowPropertyName } from '@/fbs/rp/models/properties/shadow';
import { RadiusPropertyName } from '@/fbs/rp/models/properties/radius';
import { TextPropertyName } from '@/fbs/rp/models/properties/text';
import { TextFormatExPropertyName } from '@/fbs/rp/models/properties/textFormat';

import { isPredefinedState } from '@consts/state';

import { getBoundsInParent, mapVectorToTargetCoordinates } from '@helpers/componentHelper';
import { coverPatches, mergePatches } from '@helpers/patchHelper';
import { collectComponentsLayout } from '@helpers/responseLayoutHelper';
import {
  getInnerOrAroundConnect,
  removeConnectWhenDelete,
  resetConnectPatchWhenResize,
  updateConnectComponentPatches,
  updateStickNoteConnectCompPatches,
} from '@helpers/pathFinderHelper';
import { getCenter, getNWPoint } from '@helpers/rotateHelper';
import { getNewID } from '@/helpers/idHelper';
import { getPositionPatchesWhenFlip, IFlipModel } from '@helpers/flipHelper';
import { CGroup } from '@libs/constants';

import {
  ComponentChange,
  ComponentChangeType,
  ComponentResizeResult,
  ContainerPatches,
  getAdjustedZeroOffset,
  getNewPositionWhenCenter,
  ResizeOptions,
  updateEditComponentsPatches,
  updateUnChangedComponentsPatches,
  updateStickNoteEndPoint,
} from './resizeHelper';

import { getViewBoundsOfComponents } from '../bounds';
import { UIComponent, UIContainerComponent } from '.';

/**
 * 可以支持跨组应用到子上的属性
 */
const AllowApplyToChildrenProperties = [
  FillPropertyName,
  StrokePropertyName,
  ShadowPropertyName,
  RadiusPropertyName,
  TextPropertyName,
  TextFormatExPropertyName,
];

function getCompEditData(newAddedComponent: IComponentData) {
  const { _id: id, position, size, rotate, value } = newAddedComponent;
  return {
    id,
    type: ComponentChangeType.Add,
    position,
    size,
    value,
    rotate: rotate || 0,
  };
}

export default class UIGroupComponent extends UIContainerComponent {
  private cacheSize?: ISize;

  public get isGroup(): boolean {
    return true;
  }

  private _boundsBeforeEdit?: IBounds;

  /**
   * 由于组的特殊性，在组内进行编辑操作时，过程中固定组的尺寸
   */
  public fixedBounds(val: boolean): void {
    const bounds = this.getRotateCenterBounds();
    this._boundsBeforeEdit = val ? { ...bounds } : undefined;
  }

  public getGroupMaskBounds() {
    return this._boundsBeforeEdit ?? this.getRotateCenterBounds();
  }

  public getChildrenBounds() {
    const selfSize = this.selfSize;
    const childrenBounds = this.components
      .filter((c) => !c.isConnector)
      .map((comp) => {
        // const {  rotate } = comp.toJSON();
        const { position, size, rotate } = comp;
        return getBoundsInParent({ position, size, rotate: rotate || 0 });
      });

    return childrenBounds.length ? BoundsUtils.union(...childrenBounds) : BoundsUtils.createBoundsBySize(selfSize);
  }

  private doGetSize() {
    const selfSize = this.selfSize;
    const componentBounds = this.getChildrenBounds();
    return {
      width: round(componentBounds.width),
      height: round(componentBounds.height),
      lockedRatio: selfSize.lockedRatio,
    };
  }

  clearCacheSizeWithPreview() {
    this.cacheSize = undefined;
    this.clearChildGroupCacheSizeWithPreview();
  }

  clearChildGroupCacheSizeWithPreview() {
    this.components.forEach((comp) => {
      if (comp instanceof UIGroupComponent) {
        comp.clearCacheSizeWithPreview();
      }
    });
  }

  // 获取 size
  // Group 获取 size 需要根据子组件来计算
  get size(): ISize {
    // TODO Matt 2021-05-26
    // 演示时组的尺寸计算优化
    if (this.isPreview) {
      // 演示时通过resize命令写入的数据
      const { realSize } = this.toJSON() as IComponentData & { realSize?: ISize };
      if (realSize) {
        this.clearCacheSizeWithPreview();
        return realSize;
      }
      // 未被resize命令修改过的情况下，只获取一次根据子计算的结果
      if (!this.cacheSize) {
        this.cacheSize = this.doGetSize();
      }
      return this.cacheSize;
    } else {
      return this.doGetSize();
    }
  }

  // 获取 size
  // Group 获取 自身size, 在不需要计算子组件边界的场景使用
  get selfSize(): IComponentSize {
    return super.size;
  }

  /**
   * 调整组的坐标，禁止外部调用，仅用于组内部代码递归调用
   * @param {number} x 水平偏移量
   * @param {number} y 垂直偏移量
   * @returns {ArtboardPatches}
   */
  adjustSelfPosition = ({ x, y }: MoveDelta): ArtboardPatches => {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    /*
      1、坐标的变化
          如果新坐标为正时，修改自身坐标
          如果新坐标为负时，修改父中子的坐标，递归更新父的坐标
      3、如果父不是常规组，不再递归
     */
    const parent = this.parent!;
    const oldPosition = this.position;
    if (x !== 0 || y !== 0) {
      const delta = { x, y };
      const left = oldPosition.x + x;
      const top = oldPosition.y + y;
      // 针对组的父的偏移量
      const parentDelta = {
        x: 0,
        y: 0,
      };
      if (parent.isGroup) {
        // 如果父也是一个组的情况下，修正可偏移量
        // FIXME Matt 这里要考虑调整一下，如果是删除组件，可能父的坐标不是往左上扩张，而是往右下收缩
        const otherComps = parent.components.filter((comp) => comp !== this);
        let { left: parentLeft, top: parentTop } = getViewBoundsOfComponents(otherComps) || {
          left: oldPosition.x,
          top: oldPosition.y,
        };

        if (left < 0) {
          delta.x = -oldPosition.x;
          parentDelta.x = left;
        } else {
          // 判断父需要的水平偏移量，此时可能往右收缩，可能不收缩
          parentDelta.x = min(parentLeft, left);
          if (parentDelta.x < 0) {
            delta.x = 0;
          }
        }
        if (top < 0) {
          delta.y = -oldPosition.y;
          parentDelta.y = top;
        } else {
          // 判断父需要的垂直偏移量，此时可能往下收缩，可能不收缩
          parentDelta.y = min(parentTop, top);
          if (parentDelta.y < 0) {
            delta.y = 0;
          }
        }
      }
      if (delta.x !== 0 || delta.y !== 0) {
        // 移动自己
        const { do: selfDo, undo: selfUndo } = this.move({
          left: delta.x,
          top: delta.y,
          right: -delta.x,
          bottom: -delta.y,
        });
        patches.do[this.id] = selfDo;
        patches.undo[this.id] = selfUndo;
      }

      // 如果父是一个常规组，需要校正父中的其它子组件坐标
      if (parent.isGroup) {
        // 校正父
        if (parentDelta.x !== 0 || parentDelta.y !== 0) {
          const others = parent.components.filter((comp) => comp !== this);
          others.forEach((comp) => {
            const { do: childDo, undo: childUndo } = comp.move({
              left: -parentDelta.x,
              top: -parentDelta.y,
              right: parentDelta.x,
              bottom: parentDelta.y,
            });
            patches.do[comp.id] = childDo;
            patches.undo[comp.id] = childUndo;
          });
          const { do: parentDo, undo: parentUndo } = (this.parent as UIGroupComponent).adjustSelfPosition(parentDelta);
          Object.keys(parentDo).forEach((id) => {
            patches.do[id] = parentDo[id];
            patches.undo[id] = parentUndo[id];
          });
        }
      }
    }
    return patches;
  };

  addComponents(
    newAddedComponents: IComponentData[],
    index = -1,
  ): {
    patches: PagePatches;
    newActiveGroup?: UIContainerComponent;
  } {
    const patches: ArtboardPatches = {
      do: {
        [this.id]: [Ops.addChildren(`${index}`, newAddedComponents)],
      },
      undo: {
        [this.id]: [Ops.removeChildren(newAddedComponents.map((comp) => comp._id))],
      },
    };
    const data = newAddedComponents.map((newAddedComponent) => {
      return getCompEditData(newAddedComponent);
    });
    const res = this.getPositionPatchesOfChildrenChanged(data, true);
    coverPatches(patches, res.patches);
    return {
      patches: {
        [this.ownerArtboardID]: patches,
      },
    };
  }

  flipHandler(flipModel: IFlipModel, value: boolean): ArtboardPatches {
    const groupPatches = super.flipHandler(flipModel, value);
    const childrenBounds = getViewBoundsOfComponents(this.components!)!;
    this.components.forEach((comp) => {
      const path = flipModel === IFlipModel.Horizontal ? 'horizontal' : 'vertical';
      const childValuePatches = comp.flipHandler(flipModel, !(comp.flip && comp.flip[path]));
      const childPositionPatches = getPositionPatchesWhenFlip(comp, flipModel, value, childrenBounds);
      [childValuePatches, childPositionPatches].forEach((newPatches) => {
        coverPatches(groupPatches, newPatches);
      });
    });
    return groupPatches;
  }

  replaceComponents(
    newAddedComponents: IComponentData[],
    removingComponents: UIComponent[],
  ): {
    patches: PagePatches;
    newActiveGroup?: UIContainerComponent;
  } {
    const index = this.components.findIndex((c) => c.id === removingComponents[0]?.id);

    // do 和 undo 都先删除后添加
    const patches: ArtboardPatches = {
      do: {
        [this.id]: [
          Ops.removeChildren(removingComponents.map((comp) => comp.id)),
          Ops.addChildren(`${index}`, newAddedComponents),
        ],
      },
      undo: {
        [this.id]: [
          Ops.removeChildren(newAddedComponents.map((comp) => comp._id)),
          ...removingComponents.map((comp) =>
            Ops.addChildren(`${this.components.findIndex((c) => c === comp)}`, [comp.toJSON()]),
          ),
        ],
      },
    };

    const data = newAddedComponents
      .map((newAddedComponent) => {
        const { _id: id, position, size, rotate } = newAddedComponent;
        return {
          id,
          type: ComponentChangeType.Add,
          position,
          size,
          rotate: rotate || 0,
        };
      })
      .concat(
        removingComponents.map((removingComp) => {
          const { id, position, size, rotate } = removingComp;
          return {
            id,
            type: ComponentChangeType.Removed,
            position,
            size,
            rotate: rotate || 0,
          };
        }),
      );
    const res = this.getPositionPatchesOfChildrenChanged(data, true);
    mergePatches(patches, res.patches);

    return {
      patches: {
        [this.ownerArtboardID]: patches,
      },
    };
  }

  // 删除到只剩一个组件时调用
  private doRemoveOfOnlyLastOne(removingComponents: UIComponent[]) {
    const groupParent = this.parent!;
    const indexAtParent = groupParent.components.findIndex((comp) => comp === this);
    const group = groupParent.components[indexAtParent];
    const lastComponent = this.components
      .filter((comp) => !comp.isConnector)
      .filter((comp) => !removingComponents.map((removingComp) => removingComp.id).includes(comp.id))
      .shift()!;
    //这里不要直接去是修改lastComponent，因为redo那里要重新添加组，直接修改会因为对象是引用类型的而产生bug
    const lastComponentCopy = depthClone(lastComponent.toJSON());
    // 克隆的值里面不包含旋转角度
    lastComponentCopy.rotate = lastComponent.rotate;
    lastComponentCopy.position = lastComponent.getPositionWithoutParent();
    lastComponentCopy.rotate += lastComponent.parent!.rotate;

    const changedCompsDataForUpperContainer = [
      {
        id: lastComponentCopy._id,
        type: ComponentChangeType.Add,
        rotate: lastComponentCopy.rotate,
        position: lastComponentCopy.position,
        size: lastComponentCopy.size,
      },
      {
        id: this.id,
        type: ComponentChangeType.Removed,
        rotate: this.rotate,
        position: this.position,
        size: this.size,
      },
    ];
    const artBoardPatches = {
      do: {
        [groupParent.id]: [Ops.removeChildren([this.id]), Ops.addChildren(`${indexAtParent}`, [lastComponentCopy])],
      },
      undo: {
        [groupParent.id]: [
          Ops.removeChildren([lastComponent.id]),
          Ops.addChildren(`${indexAtParent}`, [this.toJSON()]),
        ],
      },
    };
    //删除group相关的该删除的连接线
    const connectPatches = removeConnectWhenDelete(groupParent, [group]);
    mergePatches(artBoardPatches, connectPatches);
    if (!groupParent.isArtboard) {
      const res = groupParent.getPositionPatchesOfChildrenChanged(changedCompsDataForUpperContainer, true);
      coverPatches(artBoardPatches, res.patches);
    }
    const patches: PagePatches = {
      [this.ownerArtboardID]: artBoardPatches,
    };
    return {
      patches,
      newActiveGroup: groupParent,
    };
  }

  // 删除后至少还剩两个组件时调用
  private doNormalRemove(removingComponents: UIComponent[]) {
    const patches: ArtboardPatches = {
      do: {
        [this.id]: [Ops.removeChildren(removingComponents.map((comp) => comp.id))],
      },
      undo: {
        [this.id]: removingComponents.map((comp) =>
          Ops.addChildren(`${this.components.findIndex((c) => c === comp)}`, [comp.toJSON()]),
        ),
      },
    };
    const data = removingComponents
      .filter((comp) => !comp.isConnector)
      .map((removingComponent) => {
        const { id, position, size, rotate } = removingComponent;
        return {
          id,
          type: ComponentChangeType.Removed,
          position,
          size,
          rotate,
        };
      });
    const res = this.getPositionPatchesOfChildrenChanged(data, true);
    coverPatches(patches, res.patches);
    return {
      patches: {
        [this.ownerArtboardID]: patches,
      },
    };
  }

  // 从 Group 中删除一批组件
  removeComponents(removingComponents: UIComponent[]): { patches: PagePatches; newActiveGroup?: UIContainerComponent } {
    let data: { patches: PagePatches; newActiveGroup?: UIContainerComponent };
    const normalComponents = removingComponents.filter((comp) => !comp.isConnector);
    const selectedIds = removingComponents.map((comp) => comp.id);
    //完全关联的删除， 关联且独立的删除
    const { innerConnectComps, aloneConnectComps } = getInnerOrAroundConnect(this, selectedIds);
    removingComponents = [...normalComponents, ...innerConnectComps, ...aloneConnectComps];
    // 所有子组件都被删除，等同于从父中删除自己
    if (removingComponents.length === this.components.length) {
      return this.parent!.removeComponents([this]);
    }

    // 只剩下一个组件，需要解除 Group
    if (this.components.length - removingComponents.length === 1) {
      //如果最后一个剩下的是连接线，那么直接删除group
      const lastComponent = this.components
        .filter((comp) => !removingComponents.map((removingComp) => removingComp.id).includes(comp.id))
        .shift()!;
      if (lastComponent.isConnector) {
        return this.parent!.removeComponents([this]);
      }
      data = this.doRemoveOfOnlyLastOne(removingComponents);
    } else {
      data = this.doNormalRemove(removingComponents);
      const isRemovingSingleComp = removingComponents.length === 1;
      const singleComponentIsGroup = removingComponents[0].isGroup;
      const isRemovingActiveGroup =
        isRemovingSingleComp && singleComponentIsGroup && (removingComponents[0] as UIGroupComponent).isActived;
      if (isRemovingActiveGroup) {
        data.newActiveGroup = removingComponents[0].parent;
      }
    }
    return data;
  }

  // 扩张 Group 的位置，会触发子组件的相对定位变化，但不会往下深入了，需要向上检查
  // extendPosition(offset: { x: number; y: number }) {
  //   const currentPosition = this.position;
  // }

  // 子的改变引发的位置改变
  // 改变分三种：新增、删除、修改
  //   新增：组件多出来（不考虑新增组件的二次移动，也即是组件新增后是在新容器的该位置，不论新容器是否因本次子的修改而发生变化）
  //   删除：组件被移除
  //   修改：组件位置或者尺寸发生变化
  // @param includeSelf 是否包含自身的修改：这个组件本身不处理自身的修改，只关心子，当传 true 时，自动帮助父组件调用，并将自身的修改合并到 patches 中
  getPositionPatchesOfChildrenChanged(changes: ComponentChange[], includeSelf: boolean = false): ContainerPatches {
    // 计算出因为这些影响导致的前后该容器的区域变化，总体 x 偏移多少，y 偏移多少
    // 移动所有剩下的子的 position，注意不修改本组件，本组件由父来决定是否修改
    // 向上遍历，让父决定它的子如何移动
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    // 1. 获取 this.size 确定当前组件的区域，位置 (0,0)
    // 2. 根据 change 结合 this.components 计算新的组件的区域 newBounds
    // 3. 计算出偏移 x, y
    // 4. 根据 x, y 偏移不在 change 之中的组件，注意：不是要移动这些组件，而是要让他们因为这个改变，在视觉上仍然能保持在原来的位置上，例如 x = -50，则给剩下的组件 +50
    // 5. added, removed 的组件由调用者自行处理
    // 6. edit 的组件，要根据 x, y 重新确定最终实际修改为多少
    // 7. 调用父的 getPositionPatchesOfChildrenChanged chang 为当前容器，type 为 edit，参数通过 newBounds.size, this.position + (x,y) 计算得来
    // 新的Group边界 = (其余未改变的comp)的边界 + （changedCompBounds - removed comp）边界
    const changedCompBounds = changes
      .filter((changedComp) => changedComp.type !== ComponentChangeType.Removed)
      .map((changedCompData) => {
        const { size, position, rotate } = changedCompData;
        return getBoundsInParent({ size, position, rotate });
      });
    const changeCompsIDArr = changes.map((comp) => comp.id);
    const originUnchangedComp = this.components.filter(
      (comp) => !changeCompsIDArr.includes(comp.id) && !comp.isConnector,
    );
    const originUnchangedCompBounds = originUnchangedComp.map((comp) => comp.getViewBoundsInParent());
    const totalCompNewBounds = BoundsUtils.union(...changedCompBounds.concat(originUnchangedCompBounds));

    let xDiff = totalCompNewBounds.left;
    let yDiff = totalCompNewBounds.top;

    const newSize = {
      width: totalCompNewBounds.width,
      height: totalCompNewBounds.height,
    };
    // 如果变化的组件处于自定义状态，那么不能影响组
    if (changes.length === 1) {
      const changedComp = changes[0];
      if (changedComp.type === ComponentChangeType.Edit) {
        const originComp = this.components.find((comp) => {
          return changes.find((changeComp) => changeComp.id === comp.id);
        })!;
        const isCompInSpecialStatus = originComp?.currentStateID && !isPredefinedState(originComp.currentStateID);
        if (isCompInSpecialStatus) {
          xDiff = 0;
          yDiff = 0;
        }
      }
    }
    //对于edit的组件的处理
    // 先修改变化的子到新的position和size,如果父的position有变化了，需要修正position
    updateEditComponentsPatches(
      this.components,
      changes,
      {
        x: xDiff,
        y: yDiff,
      },
      patches,
    );

    // 对于added组件的处理
    changes
      .filter((changedComp) => changedComp.type === ComponentChangeType.Add)
      .forEach((addingComp) => {
        // 因为addingComp.position 和 最终 patch [this.id]: [Ops.addChildren('-1', newAddedComponents)] 中的newAddedComponents.position
        // 都是同一个position(指针相同)，所以在这里直接修改，而不要用undo,redo patch
        addingComp.position.x = xDiff ? addingComp.position.x - xDiff : addingComp.position.x;
        addingComp.position.y = yDiff ? addingComp.position.y - yDiff : addingComp.position.y;
      });

    // 如果当前组的position有变化，需要修正所有非流程线子组件的position
    updateUnChangedComponentsPatches(
      originUnchangedComp,
      {
        x: xDiff,
        y: yDiff,
      },
      patches,
    );

    // 如果当前组的position有变化，需要修正所有流程线组件的点或path , 2端关联或 其他的，修正
    // 有无变化都该: 如果是一端关联的线，重置
    updateConnectComponentPatches(
      this,
      changes,
      patches,
      {
        x: xDiff,
        y: yDiff,
      },
      true,
    );

    // 便签条组件移动和resize，重置连接结束点位置
    updateStickNoteEndPoint(this.components, changes, patches);

    // 便签条连接线
    updateStickNoteConnectCompPatches(this, changes, patches);

    //这里其实是偏移量
    let newDiff = {
      x: xDiff,
      y: yDiff,
    };
    const roundedNewSize = {
      width: round(newSize.width),
      height: round(newSize.height),
    };
    const leftTopPoint = this.getBoxPointsInParent()[0];
    const vector = mapVectorToTargetCoordinates(newDiff, this.rotate);
    let newLeftTopPoint = {
      x: leftTopPoint.x + vector.x,
      y: leftTopPoint.y + vector.y,
    };
    const newCenter = getCenter(newLeftTopPoint, roundedNewSize, this.rotate);
    let newRealPosition = getNWPoint(newCenter, roundedNewSize, 0);
    newRealPosition = getNewPositionWhenCenter(this, newRealPosition, roundedNewSize, this.parent!.size);
    newDiff = {
      x: newRealPosition.x - this.position.x,
      y: newRealPosition.y - this.position.y,
    };
    //xDiff,yDiff是以组为坐标系，需要将其转化为以组的父为坐标系的diff，然后再与组的坐标一起处理
    if (includeSelf && this.parent) {
      const parentChange = this.parent.getPositionPatchesOfChildrenChanged(
        [
          {
            id: this.id,
            type: ComponentChangeType.Edit,
            position: newRealPosition,
            size: newSize,
            rotate: this.rotate,
          },
        ],
        true,
      );
      if (parentChange.patches) {
        coverPatches(patches, parentChange.patches);
      }
    }

    return {
      patches,
      position: newDiff,
      size: newSize,
      rotate: this.rotate,
    };
  }

  getNewChildInfoAndUpdatePatches(
    patches: ArtboardPatches,
    realOffset: IBoundsOffset,
    layoutMap: WeakMap<UIComponent, IBasicLayout>,
    childrenResizeOptions: ResizeOptions,
  ) {
    return this.components
      .filter((comp) => !comp.isConnector)
      .map((comp) => {
        const newCompInfo = comp.resizeHandler2(realOffset, layoutMap.get(comp)!, childrenResizeOptions);
        if (newCompInfo.patches) {
          coverPatches(patches, newCompInfo.patches);
        }
        return {
          id: comp.id,
          type: ComponentChangeType.Edit,
          position: {
            x: newCompInfo.position.x,
            y: newCompInfo.position.y,
          },
          size: newCompInfo.size,
          rotate: newCompInfo.rotate,
        };
      });
  }

  getRealChildrenResizeOptions(originChildrenResizeOptions: ResizeOptions, childResizeResult: ContainerPatches) {
    const newOptions = depthClone(originChildrenResizeOptions);
    const { size: sizeDeterminedByChild, position: positionChange } = childResizeResult;
    // 如果上面计算出来的组的size没有变化，就说明上面的options.container传递的有问题
    if (sameNumber(sizeDeterminedByChild!.height, this.size.height)) {
      newOptions.container.after.size.height = this.size.height;
    }
    if (sameNumber(sizeDeterminedByChild!.width, this.size.width)) {
      newOptions.container.after.size.width = this.size.width;
    }
    if (sameNumber(positionChange!.x, 0)) {
      newOptions.container.after.position.x = 0;
    }
    if (sameNumber(positionChange!.y, 0)) {
      newOptions.container.after.position.y = 0;
    }
    return newOptions;
  }

  resizeHandler2(
    offset: IBoundsOffset,
    layout: IBasicLayout,
    options: ResizeOptions,
    backupAllCompsLayout?: WeakMap<UIComponent, IBasicLayout>,
  ): ComponentResizeResult {
    const { position, size } = this;
    const myOptions = depthClone(options);

    const info = super.resizeHandler2(getAdjustedZeroOffset(offset), layout, myOptions);
    const realOffset = getAdjustedZeroOffset(this.getRealOffsetForChild(offset, info));
    // 如果你仅仅是位置移动了，大小没有发生改变，那么就不需要对子组件做处理
    const isOnlyPositionChange =
      sameNumber(realOffset.right, realOffset.left) && sameNumber(realOffset.top, realOffset.bottom);
    if (isOnlyPositionChange) {
      return {
        position: info.position,
        size: info.size,
        rotate: this.rotate,
      };
    }

    const layoutMap =
      backupAllCompsLayout ||
      collectComponentsLayout(this.componentsExceptConnector, BoundsUtils.createBoundsBySize(this.size));
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };

    const childrenResizeOptions = {
      container: {
        before: {
          position: {
            x: 0,
            y: 0,
          },
          size,
        },
        // 对于旋转的组件，需要传递给子的是左上角顶点的变化
        after: {
          position: this.getLeftTopChangeForChildren(info),
          size: info.size,
        },
        isResponsive: this.layout.responsive,
      },
      shift: options.shift,
      scale: {
        h: info.size.width / size.width,
        v: info.size.height / size.height,
      },
    };
    const newComponentsInfo = this.getNewChildInfoAndUpdatePatches(
      patches,
      realOffset,
      layoutMap,
      childrenResizeOptions,
    );
    let res = this.getPositionPatchesOfChildrenChanged(newComponentsInfo);
    // 如果上面计算res出来的组的size没有变化，就说明上面的options.container传递的有问题,要重新计算
    // // 旧代码
    // const newChildResizeOptions = this.getRealChildrenResizeOptions(childrenResizeOptions, res);
    // res = this.getPositionPatchesOfChildrenChanged(
    //   this.getNewChildInfoAndUpdatePatches(patches, realOffset, layoutMap, newChildResizeOptions),
    // );
    // 不太理解以上为什么需要重新计算，在180°第二次计算有问题，现将第二次计算放到if里执行
    if (!res.size || (isEqual0(res.size.width) && isEqual0(res.size.height))) {
      const newChildResizeOptions = this.getRealChildrenResizeOptions(childrenResizeOptions, res);
      const newChildInfo = this.getNewChildInfoAndUpdatePatches(patches, realOffset, layoutMap, newChildResizeOptions);
      res = this.getPositionPatchesOfChildrenChanged(newChildInfo);
    }

    if (res.patches) {
      coverPatches(patches, res.patches);
    }
    //流程线重置
    const connectPatch = resetConnectPatchWhenResize(this, this.components, newComponentsInfo);
    coverPatches(patches, connectPatch);
    // 组件大小变化，影响了子的大小位置，那么父的位置应该由子来决定
    return {
      position: {
        x: position.x + res.position!.x,
        y: position.y + res.position!.y,
      },
      size: res.size!,
      rotate: res.rotate!,
      patches,
    };
  }

  /**
   * 由复合路径的修改，需要向上更新复合路径的组同时修正此组内所有组件的position
   * @memberof UIGroupComponent
   */
  refreshPatchesWithNewChildren = (components: IComponentData[], patches: ArtboardPatches = { do: {}, undo: {} }) => {
    const parent = this.parent!;
    const isChildOfGroup = this.parent?.type === CGroup;

    if (components.length === 1) {
      // 解除this组，将组的内容释放到更外层；
      const lastComponentData = depthClone(components[0]);
      lastComponentData._id = getNewID();
      lastComponentData.position = new UIComponent(lastComponentData, this).getPositionWithoutParent();
      lastComponentData.rotate = (lastComponentData.rotate || 0) + this.rotate;

      const removePatches = this.removeSelfPatches();
      mergePatches(patches, removePatches);

      const addPatches: ArtboardPatches = {
        do: { [parent.id]: [Ops.addChildren(`${-1}`, [lastComponentData])] },
        undo: { [parent.id]: [Ops.removeChildren([lastComponentData._id])] },
      };
      mergePatches(patches, addPatches);
      if (isChildOfGroup) {
        const newComps = this.parent!.components.map((item) => depthClone(item.toJSON()));
        removeItemFromArray(
          newComps,
          newComps.find((comp) => comp._id === this.id),
        );
        newComps.push(lastComponentData);
        (this.parent! as UIGroupComponent).refreshPatchesWithNewChildren(newComps, patches);
      }
      return;
    }

    const unionBounds = BoundsUtils.union(
      ...components.map((comp) => {
        const { size, rotate, position } = comp;
        return getBoundsInParent({ size, rotate: rotate || 0, position });
      }),
    );
    const sizePath = this.getCurrentSizePath();
    const positionPath = this.getCurrentPositionPath();
    const vector = mapVectorToTargetCoordinates(
      {
        x: unionBounds.left,
        y: unionBounds.top,
      },
      this.rotate,
    );
    const originLeftTopPoint = this.getBoxPointsInParent()[0];
    const newLeftTopPoint = {
      x: originLeftTopPoint.x + vector.x,
      y: originLeftTopPoint.y + vector.y,
    };
    const newSize = { ...this.size, width: unionBounds.width, height: unionBounds.height };
    const newCenter = getCenter(newLeftTopPoint, newSize, this.rotate);
    const newPosition = getNWPoint(newCenter, newSize, 0);

    const groupPatches: ArtboardPatches = {
      do: {
        [this.id]: [Ops.replace(sizePath, newSize), Ops.replace(positionPath, newPosition)],
      },
      undo: {
        [this.id]: [Ops.replace(sizePath, this.size), Ops.replace(positionPath, this.position)],
      },
    };
    mergePatches(patches, groupPatches);
    if (unionBounds.left || unionBounds.top) {
      const childrenPatches: ArtboardPatches = { do: {}, undo: {} };
      components.forEach((compData) => {
        const currentComp = this.components.find((i) => i.id === compData._id);
        if (currentComp) {
          const positionPath = currentComp.getCurrentPositionPath();
          const newPosition: IPosition = {
            x: compData.position.x - unionBounds.left,
            y: compData.position.y - unionBounds.top,
          };
          childrenPatches.do[currentComp.id] = [Ops.replace(positionPath, newPosition)];
          childrenPatches.undo[currentComp.id] = [Ops.replace(positionPath, currentComp.position)];
        }
      });
      mergePatches(patches, childrenPatches);
    }

    if (isChildOfGroup) {
      const newComps = depthClone(
        parent.components.map((comp) => {
          const compData = depthClone(comp.toJSON());
          compData.position = comp.position;
          compData.size = comp.size;
          compData.rotate = comp.rotate;
          compData.value = comp.value;
          return compData;
        }),
      );
      const currentComp = newComps.find((i) => i._id === this.id);
      if (currentComp) {
        currentComp.size = newSize;
        currentComp.position = newPosition;
      }
      (parent as UIGroupComponent).refreshPatchesWithNewChildren(newComps, patches);
    }
  };

  changingProperty(name: PropertyName, value: PropertyValue) {
    this.components.forEach((comp) => {
      comp.changingProperty(name, value);
    });
  }

  setProperty(
    propertyName: PropertyName,
    newValue: PropertyValue,
    ignoreUpdateValue?: boolean,
    childPropName?: string,
  ): ArtboardPatches {
    if (!AllowApplyToChildrenProperties.includes(propertyName)) {
      return super.setProperty(propertyName, newValue, ignoreUpdateValue, childPropName);
    }

    const patches = { do: {}, undo: {} };
    const components = this.components;
    components.forEach((comp) => {
      let newPropValue = newValue;
      let propName = propertyName;
      const { properties } = comp;

      // 'textStyle' to 'textFormat'
      if (propertyName === TextPropertyName && !comp.isGroup) {
        if (!properties[TextPropertyName] && properties[TextFormatExPropertyName]) {
          newPropValue = { ...properties[TextFormatExPropertyName], ...newPropValue };
          propName = TextFormatExPropertyName;
        }
      }

      // 处理子组件 new value 的修改粒度
      if (childPropName) {
        newPropValue = { ...properties[propName], [childPropName]: newPropValue[childPropName as keyof PropertyValue] };
      }

      coverPatches(patches, comp.setProperty(propName, newPropValue, ignoreUpdateValue, childPropName));
    });

    return patches;
  }

  getChildProperties(): IProperties {
    const { components } = this;
    const baseProperties: IProperties = {};
    const len = components.length;
    // TODO MengXiaoJiang 2022-11-23 最近有出现空symbol脱离产生空组的情况
    if (!len) {
      return baseProperties;
    }

    const propertiesCount: { [key: string]: number } = {};
    let i = 0;

    let firstProperties: IProperties;

    for (; i < len; i++) {
      const { properties } = components[i];
      i === 0 && (firstProperties = properties);
      Object.keys(properties).forEach((key) => {
        if (AllowApplyToChildrenProperties.includes(key)) {
          propertiesCount[key] = (propertiesCount[key] || 0) + 1;
        }
      });
    }

    Object.keys(propertiesCount).forEach((key) => {
      if (propertiesCount[key] === len) {
        baseProperties[key] = firstProperties[key];
      }
    });

    if (!baseProperties[TextFormatExPropertyName] && !baseProperties[TextPropertyName]) {
      if ((propertiesCount[TextFormatExPropertyName] || 0) + (propertiesCount[TextPropertyName] || 0) >= len) {
        const textAttribute = firstProperties![TextFormatExPropertyName] || firstProperties![TextPropertyName];
        baseProperties[TextPropertyName] = { ...(textAttribute || {}) };
      }
    }
    return baseProperties;
  }

  get properties() {
    const _properties = super.properties;
    if (this.isActived || this.isPreview) {
      return _properties;
    }
    const result = this.getChildProperties();
    Object.keys(_properties).forEach((key) => {
      if (!AllowApplyToChildrenProperties.includes(key)) {
        result[key] = _properties[key];
      }
    });
    return result;
  }

  switchState(stateID?: string) {
    const result = super.switchState(stateID);
    this.components.forEach((comp) => {
      const ops = comp.switchState(stateID);
      Object.keys(ops).forEach((id) => {
        result[id] = ops[id];
      });
    });
    return result;
  }
}
