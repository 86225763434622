import * as React from 'react';

import * as echarts from 'echarts';
import classnames from 'classnames';
import { cloneDeep, debounce, isEqual, isUndefined, max, get as lodashGet } from 'lodash';

import { rgba2hex, PureColor, RGBA2HEX } from '@/utils/graphicsUtils';
import { ISize } from '@/utils/boundsUtils';

import { IProperties } from '@/fbs/rp/models/property';
import { IComponentValue } from '@/fbs/rp/models/value';
import { IComponentSize } from '@/fbs/rp/models/component';
import { IChartValue } from '@/fbs/rp/models/chart';
import {
  EChartType_basic,
  ELegendPosition,
  IChartDataLabel,
  IChartAxis,
  IChartBarWidth,
  IChartSmooth,
  IChartPopup,
} from '@/fbs/rp/models/properties/chart';
import { PresetDashModel } from '@/fbs/rp/models/properties/stroke';
import { StyleHelper } from '@/helpers/styleHelper';
import { parseDashModel } from '@/helpers/propertiesHelper';
import { CCombinationChart } from '@/libs/constants';

import { getLegendSize, LegendItemGap, LegendItemIconWidth, LegendItemIconHeight } from './common';
import { IComponentProps } from '../../types';
import { DefaultChartColorList, setChartsTooltip } from '../common';

import './index.scss';

interface IBarChartState {
  boxStyle: React.CSSProperties;
}

interface IChartItemValue {
  value: number[];
  name: string;
}
interface IChartLabelValue {
  value: number;
  label?: { show: boolean; position: string; textStyle: React.CSSProperties; [key: string]: any };
}
interface IChartDataType {
  name: string;
  data: (number | IChartItemValue | IChartLabelValue)[];
  color: string;
  type: string;
  barGap: string;
  label?: { show: boolean; position: string; textStyle: React.CSSProperties; [key: string]: any };
  smooth?: boolean;
  areaStyle?: {};
  stack?: string;
  lineStyle?: {
    width: number;
    type: string;
  };
  barWidth?: number;
  yAxisIndex?: number;
}

interface CalcOrientLegendSizeOptions {
  list: string[]; // 图例item名列表
  max: number; // 最大宽度/高度
  itemGap: number; // 每个item间的间距
  getBaseItemX: (itemSize: ISize) => number; // 每个item X轴大小的计算
  getBaseItemY: (itemSize: ISize) => number; // 每个item Y轴大小的计算
}

export default class NormalChart extends React.Component<IComponentProps, IBarChartState> {
  properties?: IProperties;
  value?: IComponentValue;
  size?: IComponentSize;
  chartInstance?: echarts.ECharts;
  opacity?: number;
  constructor(props: IComponentProps) {
    super(props);
    const { properties, size, opacity } = props.comp;
    const parser = StyleHelper.initCSSStyleParser(properties);
    const boxStyle = {
      ...parser.getFillStyle(),
      ...parser.getShadowStyle(),
      ...parser.getStrokeStyle(),
      ...parser.getRadiusStyle(size),
      opacity: isUndefined(opacity) ? 1 : opacity / 100,
    };
    this.state = {
      boxStyle,
    };
  }

  chartDomID = `pie-chart-${this.props.comp.id}-${Date.now()}`;

  componentDidMount() {
    const dom = document.getElementById(this.chartDomID);
    if (!dom) {
      return;
    }

    this.chartInstance = echarts.init(dom, undefined, {
      renderer: 'svg',
    });
    window.requestAnimationFrame(() => {
      this.renderBarChartChart(this.props);
    });
  }

  componentWillUnmount() {
    const { chartInstance: chart } = this;
    if (chart) chart.dispose();
  }

  debouncedRenderBarChart = debounce((nextProps) => {
    const { properties, size, opacity } = nextProps.comp;
    const parser = StyleHelper.initCSSStyleParser(properties);
    const boxStyle = {
      ...parser.getFillStyle(),
      ...parser.getShadowStyle(),
      ...parser.getStrokeStyle(),
      ...parser.getRadiusStyle(size),
      opacity: isUndefined(opacity) ? 1 : opacity / 100,
      width: size.width,
      height: size.height,
    };
    this.setState({ boxStyle });
    this.renderBarChartChart(nextProps);
  }, 0);

  UNSAFE_componentWillReceiveProps(nextProps: IComponentProps) {
    const { properties, size, value, opacity } = nextProps.comp;
    if (
      !isEqual(this.properties, properties) ||
      !isEqual(this.size, size) ||
      !isEqual(this.value, value) ||
      !isEqual(this.opacity, opacity)
    ) {
      this.debouncedRenderBarChart(nextProps);
      this.properties = cloneDeep(properties);
      this.size = cloneDeep(size);
      this.value = cloneDeep(value);
      this.opacity = cloneDeep(opacity);
    }
  }

  /**
   *
   * @param type
   * @param dataType 组合图表中，展示数据图表类型
   */
  getRenderChartType = (type: EChartType_basic, dataType?: EChartType_basic) => {
    if (
      type === EChartType_basic.BarChartHorizontal ||
      type === EChartType_basic.Bar ||
      dataType === EChartType_basic.Bar
    ) {
      return 'bar';
    } else if (type === EChartType_basic.Radar) {
      return 'radar';
    } else if (type === EChartType_basic.Scatter) {
      return 'scatter';
    } else {
      return 'line';
    }
  };

  getShowAxis = (type: EChartType_basic, axisDisable: boolean) => {
    if (type === EChartType_basic.Radar || axisDisable) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * 注意在导出图片使用该方法，修改时需要注意
   * @param nextProps
   * @param chart
   */
  renderBarChartChart = (nextProps: IComponentProps, chart = this.chartInstance) => {
    const { properties, value, size } = nextProps.comp;
    if (!chart) {
      return;
    }
    const { legendPosition, textStyle, stroke, border, type, isStack, animation, barWidth } = properties;
    const isCombinationChart = nextProps.comp.type === CCombinationChart;
    const smoothData = properties.isSmooth as IChartSmooth;
    const isSmooth = !smoothData?.disabled;
    const lineType = smoothData?.value as PresetDashModel;

    const axis = properties.axis as IChartAxis;
    const showAxis = !axis.disabled;
    const axisStartValue = axis.yAxisMin;
    const axisGapValue = axis.yAxisGap;
    const labelRotate = axis.labelRotate || 0;

    const scaleMark = !!axis.scaleMark;

    const dataLabel = properties.dataLabel as IChartDataLabel;
    const labelUnit = dataLabel.unit || '';
    const showDataLabel = !dataLabel.disabled;
    const formatterStr = `{c}${labelUnit}`;

    const { disabled: legendDisabled, value: legendValue, gap: legendAndChartSpace } = legendPosition!;
    const legendTop = legendValue === ELegendPosition.Top;
    const legendBottom = legendValue === ELegendPosition.Bottom;
    const legendRight = legendValue === ELegendPosition.Right;
    const legendLeft = legendValue === ELegendPosition.Left;
    const isVertical = legendTop || legendBottom;

    const { top: bTop, left: bLeft, right: bRight, bottom: bBottom } = border!;
    const { thickness, disabled } = stroke!;
    const borderSize = disabled ? 0 : thickness;
    const b_top = bTop ? borderSize : 0;
    const b_left = bLeft ? borderSize : 0;
    const b_right = bRight ? borderSize : 0;
    const b_bottom = bBottom ? borderSize : 0;

    const selectedStyle = {
      fontFamily: textStyle?.fontFamily,
      fontSize: textStyle?.fontSize,
      color: RGBA2HEX(textStyle?.color as PureColor),
    };

    const { xAxis, dataSource } = value as IChartValue;
    const { width, height } = size;

    const chartSeries: IChartDataType[] = [];
    const TooltipList: string[] = [];
    const radarNameList: { name: string; max?: number }[] = [];
    const isBarChartHorizontal = type?.value === EChartType_basic.BarChartHorizontal;
    let chartXAxis;
    let chartYAxis;
    let areaChartSeries: IChartDataType[] = [];
    let radarChartSeries: IChartDataType[] = [];
    dataSource.forEach((item, i) => {
      const { name, data, color } = item;
      if (data.length === 0 || !name) {
        return;
      }
      const hex = rgba2hex(color ?? DefaultChartColorList[i % DefaultChartColorList.length]);
      const dataType = this.getRenderChartType(type?.value as EChartType_basic, item?.type);
      chartSeries.push({
        type: dataType,
        yAxisIndex: item?.type === EChartType_basic.Line ? 1 : 0,
        stack: !isStack?.hidden && isStack?.value ? dataType : '',
        barGap: '0%',
        name: name,
        data: data.map<IChartLabelValue>((value) => {
          const labelYDirection = isBarChartHorizontal ? 'right' : 'top';
          const labelXDirection = isBarChartHorizontal ? 'left' : 'bottom';
          return {
            value: value as number,
            label: {
              show: showDataLabel,
              position: (value as number) >= 0 ? labelYDirection : labelXDirection,
              textStyle: selectedStyle,
              formatter: formatterStr,
            },
          };
        }),
        color: hex,
        smooth: !smoothData?.hidden && isSmooth,
        lineStyle: {
          width: smoothData.strokeWidth || 2,
          type: parseDashModel(lineType),
        },
        barWidth: (barWidth as IChartBarWidth)?.value || undefined,
      });
      TooltipList.push(name);
    });
    xAxis.map((item) => {
      radarNameList.push({
        name: item,
        ...selectedStyle,
      });
    });
    if (type?.value === EChartType_basic.Area) {
      areaChartSeries = chartSeries.map((item) => {
        return {
          ...item,
          areaStyle: {},
        };
      });
    } else if (type?.value === EChartType_basic.Radar) {
      let maxValue = 0;
      radarChartSeries = chartSeries.map((item) => {
        let firstValue = lodashGet(item.data, '0', 0);
        let valueData =
          firstValue !== null && typeof firstValue === 'number' ? item.data : item.data.map((d: any) => d.value);
        maxValue = Math.max(max(valueData) as number, maxValue);
        return {
          ...item,
          label: {
            show: showDataLabel,
            verticalAlign: 'middle',
            position: 'right',
            distance: 2,
            textStyle: selectedStyle,
            formatter: formatterStr,
          },
          data: [{ value: valueData as number[], name: item.name }],
          name: '',
        };
      });
      if (maxValue > 0) {
        radarNameList.forEach((item) => {
          item.max = maxValue;
        });
      }
    }

    chart.resize({
      width: width - b_right! - b_left!,
      height: height - b_top! - b_bottom!,
    });

    const grid: Record<string, any> = {
      containLabel: showAxis,
      bottom: legendBottom && !legendDisabled ? '10%' : 20,
      left: legendLeft && !legendDisabled ? '20%' : 10,
      right: legendRight && !legendDisabled ? '20%' : '5%',
      top: '10%',
      width: 'auto',
      height: 'auto',
      itemGap: LegendItemGap,
      itemWidth: LegendItemIconWidth,
      itemHeight: LegendItemIconHeight,
    };
    const legend: Record<string, any> = {
      data: TooltipList,
      textStyle: selectedStyle,
      show: !legendDisabled,
      x: isVertical ? 'center' : legendValue,
      y: !isVertical ? 'center' : legendValue,
      orient: isVertical ? 'horizontal' : 'vertical',
    };

    if (!legendDisabled && legendAndChartSpace !== undefined && legendAndChartSpace !== '') {
      const legendSize = getLegendSize(nextProps.comp);
      if (isVertical) {
        legend.x = 'center';
        legend.y = legendValue;
        if (legendBottom) {
          grid.bottom = legendAndChartSpace + legendSize.height;
        } else {
          grid.top = legendAndChartSpace + legendSize.height;
        }
      } else {
        legend.y = 'center';
        legend.x = legendValue;
        if (legendLeft) {
          grid.left = legendSize.width + legendAndChartSpace;
        } else {
          grid.right = legendSize.width + legendAndChartSpace;
        }
      }
    }

    chartXAxis = {
      type: 'category',
      data: xAxis,
      show: this.getShowAxis(type?.value as EChartType_basic, !showAxis),
      splitLine: { lineStyle: { type: 'dashed' } },
      axisTick: { show: false },
      axisLabel: { textStyle: selectedStyle, rotate: labelRotate },
    };

    chartYAxis = {
      type: 'value',
      show: this.getShowAxis(type?.value as EChartType_basic, !showAxis),
      splitLine: { show: scaleMark, lineStyle: { type: 'dashed' } },
      axisLabel: {
        textStyle: selectedStyle,
      },
      min: axisStartValue === '' ? undefined : axisStartValue,
      interval: axisGapValue || undefined,
    };
    if (isBarChartHorizontal) {
      const temp = chartXAxis;
      chartXAxis = chartYAxis;
      chartYAxis = temp;
    }
    // 混合图表双坐标轴
    if (isCombinationChart) {
      chartYAxis = [
        chartYAxis,
        {
          ...chartYAxis,
          min: axis.lineYAxisMin === '' ? undefined : axis.lineYAxisMin,
          interval: axis.lineYAxisGap || undefined,
        },
      ];
    }

    const chartOption: Record<string, any> = {
      grid,
      legend,
      xAxis: chartXAxis,
      yAxis: chartYAxis,
      radar: {
        indicator: type?.value === EChartType_basic.Radar ? radarNameList : [],
        radius: '60%',
        axisName: { ...selectedStyle },
      },
      series:
        type?.value === EChartType_basic.Area
          ? areaChartSeries
          : type?.value === EChartType_basic.Radar
          ? radarChartSeries
          : chartSeries,
      animation: nextProps.isPreview && !!animation?.value,
    };

    //悬浮窗口
    const popUpconfig = properties.popup as IChartPopup;
    chartOption.tooltip = setChartsTooltip(popUpconfig);

    chart.clear();
    chart.setOption(chartOption, true);
  };

  render() {
    const { isPreview } = this.props;
    return (
      <div
        id={this.chartDomID}
        style={{ ...this.state.boxStyle, pointerEvents: isPreview ? 'auto' : 'none' }}
        className={classnames('bar-chart', { 'bar-chart-preview': isPreview })}
      ></div>
    );
  }
}
