import * as React from 'react';
import { UIComponent, UIHorizontalMenuComponent, UITreeItemComponent } from '@/editor/comps';
import { IComponentData } from '@/fbs/rp/models/component';
import { ITreeData } from '@/fbs/rp/models/value';
import {
  ITreeItem,
  createAvlTreeWithTreeComp,
  getParentIDsByCompTree,
  mapRelationWithComp,
} from '@/helpers/treeCompHelper';
// import HorizontalMenuItem from "@/libs/compoundComponents/HorizontalMenu/HorizontalMenuItem";
import CanvasPanel from '@/libs/containers/Canvas';

import { renderHorizontalMenuItem } from './renderHorizontalMenuItem';
import { renderComponentWrapper } from './renderComponentWrapper';

let _paddingStyle: { paddingLeft: number; paddingRight: number; paddingTop: number; paddingBottom: number } = {
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
};
const getPaddingStyle = (comp: UIComponent): React.CSSProperties => {
  const {
    padding: { left, top, right, bottom },
  } = (comp as UIHorizontalMenuComponent).parseProperties();
  const isChanged =
    left !== _paddingStyle.paddingLeft ||
    right !== _paddingStyle.paddingRight ||
    top !== _paddingStyle.paddingTop ||
    bottom !== _paddingStyle.paddingBottom;
  isChanged &&
    (_paddingStyle = {
      paddingLeft: left,
      paddingBottom: bottom,
      paddingRight: right,
      paddingTop: top,
    });

  return _paddingStyle;
};
// const renderItems = (comp: UIComponent, items: ITreeItem[], level: number, itemIndex: number, index: number) => {
//     return items.map((item, itemsIndex) => {
//         return renderItem(comp, item, level, itemIndex + itemsIndex, itemsIndex, index);
//     });
// };
interface renderItemProps {
  comp: UIComponent;
  maxChildLength: number;
  selectedIDs: string[];
  initTree: ITreeItem[];
}

const renderItem = (
  props: renderItemProps,
  item: ITreeItem,
  level: number,
  itemIndex: number,
  parentIndex: number,
  index: number,
) => {
  const { comp, maxChildLength, selectedIDs, initTree } = props;
  // const { isPreview, comp, hotAreaVisibleModel, showMobileCursor } = this.props;
  const isPreview = true;
  const itemComp = (comp as UIHorizontalMenuComponent).components.find((comp) => comp.id === item.data.id);
  if (!itemComp) return;
  // const { horizontalMenuHoverItemID } = this.state;
  const {
    lineHeight,
    intention,
    checkedFill,
    checkedMarkerStrip,
    padding,
    showExpandIcon,
    showNodeIcon,
    size,
    checkedTextStyle,
    checkedIconColor,
  } = (comp as UIHorizontalMenuComponent).parseProperties();
  // const horizontalContentRects = this.horizontalContentRef.current?.getClientRects();
  let showInteractionBackGround = false;
  // if (hotAreaVisibleModel === 'only-hover' && !!horizontalMenuHoverItemID) {
  //   showInteractionBackGround = true;
  // }
  // if (hotAreaVisibleModel === 'always') {
  //   showInteractionBackGround = true;
  // }

  return renderHorizontalMenuItem({
    // key:item.data.id,
    _id: item.data.id,
    // horizontalMenuHoverItemID,
    // horizontalContentRects:horizontalContentRects!,
    item,
    itemIndex,
    initTree,
    comp: itemComp as UITreeItemComponent,
    horizontalComp: comp as UIHorizontalMenuComponent,
    padding,
    paddingStyle: getPaddingStyle(comp),
    lineHeight,
    level,
    size,
    selectedIDs,
    index,
    parentIndex,
    maxChildLength,
    checkedTextStyle,
    checkedIconColor,
    intention,
    checkedFill,
    checkedMarkerStrip,
    showExpandIcon,
    showNodeIcon,
    showInteractionBackGround,
    isPreview,
    // floatPanelRects={this.floatPanelRects}
    // defaultScale={this.defaultScale}
    // showMobileCursor
  });
};

const renderHorizontalMenu = (comp: UIComponent) => {
  const childComps: IComponentData[] = (comp as UIHorizontalMenuComponent).components.map((comp) => comp.$data);
  const { relation } = comp.value as ITreeData;
  const initTree = mapRelationWithComp(relation, childComps);
  const { tree } = createAvlTreeWithTreeComp(relation, childComps);
  const maxChildLength = initTree
    .map((item) => {
      return item.children?.length ?? 0;
    })
    .reduce((prev, curr) => {
      return prev > curr ? prev : curr;
    });
  const selectComp = childComps.filter((item) => {
    return item.selected === true;
  });
  const selectCompTree = selectComp.length && tree.get(selectComp[0]._id);
  let selectedIDs: string[];
  if (selectCompTree) {
    selectedIDs = getParentIDsByCompTree(selectCompTree);
  } else {
    selectedIDs = [];
  }
  return initTree.map((item, index) =>
    renderItem({ comp, maxChildLength, selectedIDs, initTree }, item, 0, index, index, index),
  );
};

export const renderHorizontalMenuWrapper = (comp: UIComponent) => {
  const isPreview = true;
  const hm = (
    <div className="lib-comp-horizontal-menu" key={'horizontal-menu' + comp.id}>
      <CanvasPanel comp={comp} isPreview={isPreview}>
        <div className="lib-comp-horizontal-menu-content">{renderHorizontalMenu(comp)}</div>
      </CanvasPanel>
    </div>
  );

  return renderComponentWrapper({ comp, scale: 1, isPreview }, hm);
};
