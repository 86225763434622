import { intersection } from 'lodash';
// import { LRUMap } from 'lru_map';
import { getAppCache, loadFromCache, setAppCache } from '@utils/cacheUtils';

import {
  changeTreeToArray,
  findAllAncestors,
  findNodeByID,
  getFirstPageNode,
  parseNodesToTree,
  parseNodeToTreeByState,
  renameNodeOfTree,
} from '@fbs/rp/utils/app';
import { IAppWithNestedChildren } from '@fbs/rp/models/app';
import { IOType, SessionInfo, PageIOSelect } from '@fbs/rp/models/io';
import INode, { INodeWithChildren } from '@fbs/rp/models/node';
import { IComponentData } from '@fbs/rp/models/component';
import IArtboard from '@fbs/rp/models/artboard';
import { ITeams, RoleInTeam } from '@fbs/idoc/models/team';
import { IUserInfo } from '@fbs/idoc/models/user';
import { ITeamGroup } from '@fbs/idoc/models/teamGroup';
import { IPopularize } from '@/fbs/teamManagement/utils/popularize';

import isDebugMode from '@consts/debug';
import { TeamType } from '@consts/team';

import CoreEditor from '@editor/core';
import { EditorManager } from '@/managers/pageArtboardManager';

import { setSeed } from '@helpers/idHelper';
import { getDefaultSession, setCoopers } from '@helpers/sessionHelper';
import { addNodeToTree } from '@helpers/appHelper';
import { CCResponseLicense } from '@/helpers/privateLimitedTypes';

import { ActionType } from '../types';
import { RootAction } from '../webTypes';
import { ITeamAIInfo } from '@/apis/app';

export interface IAppState {
  appID: string;
  app: IAppWithNestedChildren | null;
  selectedNodeID: string;
  coreEditor?: CoreEditor;
  currentSession: SessionInfo;
  coopers: SessionInfo[];
  selectedIDs: string[];
  trashSelectedNodIDs: string[];
  expandedIDs: string[];
  downloadArtboard: {
    status: 'init' | 'request' | 'done' | 'error';
    message: string;
    URL?: string;
    taskID: number;
  };
  teamInfo: ITeams | null;
  // appCountInTeam: number;
  userInfo?: IUserInfo;
  isShowTrashPage?: boolean;
  createTime?: string | Date;
  departments: ITeamGroup[];
  showWater?: boolean;
  // 项目不存在或无权限
  noPermissionState?: number;
  notSignIn?: boolean;
  lastSelectedNodeID?: string;
  searchExpandedIDs: string[]; // 页面在搜索状态时的展开ids
  popularizes: IPopularize[];
  coopersSelected: PageIOSelect[];
  inSwitchPage: boolean; // 当前页面是否正在切换中，记录切换状态，避免页面在切换过程中多次更新
  teamAIBill?: ITeamAIInfo;
  pdLicense?: CCResponseLicense;
}

const initialState: IAppState = {
  appID: '',
  app: null,
  teamInfo: null,
  // appCountInTeam: 0,
  selectedNodeID: '',
  currentSession: getDefaultSession(),
  coopers: [],
  selectedIDs: [],
  trashSelectedNodIDs: [],
  expandedIDs: [],
  downloadArtboard: {
    status: 'init',
    message: '',
    taskID: -1,
  },
  departments: [],
  searchExpandedIDs: [],
  popularizes: [],
  coopersSelected: [],
  inSwitchPage: false,
};

const ExpandedIDsCacheKey = 'expanded-ids';

function clearInvalidateData(artboards: IArtboard[]) {
  const doClear = (comp: IComponentData) => {
    comp.components = comp.components?.filter((c) => {
      if (!c) {
        if (isDebugMode) {
          console.warn('检测到组件数据丢失，所在容器：', comp.type, comp._id);
        }
      }
      return !!c;
    });
  };
  artboards.forEach(doClear);
}

function getNextNode(nodes: INodeWithChildren[], currentID: string, direction: -1 | 1) {
  const allNodes: INodeWithChildren[] = [];
  changeTreeToArray(nodes, allNodes);
  let currentIndex = allNodes.findIndex((node) => node._id === currentID);
  if (currentIndex === -1) {
    return null;
  }
  currentIndex += direction;
  for (
    ;
    direction > 0 ? currentIndex < allNodes.length : currentIndex >= 0;
    direction > 0 ? currentIndex++ : currentIndex--
  ) {
    const node = allNodes[currentIndex];
    if (node.type === 'page') {
      return node._id;
    }
  }
  return null;
}

export default function (state = initialState, action: RootAction): IAppState {
  switch (action.type) {
    // 获取用户信息
    case ActionType.User_GetUserInfo: {
      return {
        ...state,
        notSignIn: false,
        userInfo: action.payload,
      };
    }
    // 获取session
    case ActionType.User_GetSession: {
      const session = action.payload;
      setSeed(session.id);
      return {
        ...state,
        currentSession: session,
      };
    }
    // 获取团队信息
    case ActionType.Team_GetTeamInfo: {
      window.featureFlagPersonalSpace = false;
      if (action.payload?.teamType === TeamType.Personal) {
        window.featureFlagPersonalSpace = true;
      }
      const teamInfo = state.teamInfo ? { ...state.teamInfo, ...action.payload } : action.payload;
      // 如果是系统管理员，则其拥有与超管一样的权限
      if (state.userInfo?.isSystemAdmin && teamInfo) {
        teamInfo.role = RoleInTeam.SuperAdmin;
        teamInfo.roleInTeam = RoleInTeam.SuperAdmin;
      }
      return {
        ...state,
        teamInfo,
      };
    }
    // 合并团队信息
    case ActionType.Team_Patch: {
      return {
        ...state,
        teamInfo: { ...state.teamInfo!, ...action.payload },
      };
    }
    case ActionType.App_CoopersSelectedIds: {
      const { sessionID } = action.payload;
      const i = state.coopersSelected.findIndex((item) => item.sessionID === sessionID);
      if (i === -1) {
        state.coopersSelected.push(action.payload);
      } else {
        state.coopersSelected.splice(i, 1, action.payload);
      }
      return state;
    }
    // 获取项目数据
    case ActionType.App_GetApp: {
      const { realNodes, tempDeleteNodes, pageFlatNodes, app, createTime } = action.payload;
      let pageNode: INode | null = null;
      let selectedID = action.payload.selectedID;
      if (!selectedID) {
        const cache = loadFromCache('lastOpenPage');
        if (cache) {
          selectedID = cache[app._id];
        }
      } else {
        const node = findNodeByID(app.children as INodeWithChildren[], selectedID);
        if (!node || node.type === 'folder') {
          selectedID = undefined;
        }
      }

      if (!selectedID) {
        pageNode = getFirstPageNode(realNodes);
        if (pageNode) {
          selectedID = pageNode._id;
        }
      }
      // 加载新项目后，清理所有的 core editors
      // EditorManager.clear();

      let expandedIDs = getAppCache<string[]>(app._id, ExpandedIDsCacheKey) || [];
      if (selectedID) {
        const node = findNodeByID(realNodes, selectedID);
        if (node) {
          const ancestors = findAllAncestors(node);
          ancestors.forEach((id) => {
            if (!expandedIDs.includes(id)) {
              expandedIDs.push(id);
            }
          });
        }
      }

      return Object.assign({}, state, {
        appID: app._id,
        app: Object.assign({}, app, {
          children: realNodes,
          tempChildren: tempDeleteNodes,
          flatChildren: pageFlatNodes,
          // idocApps: [{ id: '1', name: '213', pubAt: new Date() },{ id: '2222', name: '23333333333333333333333333333333333', pubAt: new Date()}],
        }),
        selectedNodeID: selectedID || '',
        selectedIDs: selectedID ? [selectedID] : [],
        expandedIDs,
        coreEditor: null,
        createTime,
        appIsNotExists: false,
        noPermissionState: undefined,
        lastSelectedNodeID: undefined,
      });
    }
    // 获取团队AI的账户数据
    case ActionType.Team_AIInfo: {
      state.teamAIBill = action.payload;
      return state;
    }
    // 添加节点（页面或分组）
    case ActionType.App_AddNode: {
      if (!state.app) {
        return state;
      }
      const { _id } = action.payload;
      const addNode = parseNodesToTree([action.payload], true)[0];
      //根据选择的节点，修改树结构
      const newNodes = addNodeToTree(addNode, 'add', [...state.app.children]);
      // const selectedNodeID = type === 'page' ? _id : state.selectedNodeID;
      return Object.assign({}, state, {
        app: Object.assign({}, state.app, {
          children: [...newNodes],
          flatChildren: { ...state.app.flatChildren, [_id]: action.payload },
        }),
        selectedNodeID: _id,
        selectedIDs: [_id],
        lastSelectedNodeID: state.selectedNodeID,
      });
    }
    // 插入节点
    case ActionType.App_InsertNode: {
      if (!state.app) {
        return state;
      }
      const { _id } = action.payload;
      const insertNode = parseNodesToTree([action.payload], true)[0];
      //根据选择的节点，修改树结构
      const newNodes = addNodeToTree(insertNode, 'insert', [...state.app.children]);
      return Object.assign({}, state, {
        app: Object.assign({}, state.app, {
          children: [...newNodes],
          flatChildren: { ...state.app.flatChildren, [_id]: action.payload },
        }),
        selectedNodeID: _id,
        selectedIDs: [_id],
        lastSelectedNodeID: state.selectedNodeID,
      });
    }
    // 更新节点结构
    case ActionType.App_UpdateNodes: {
      const { realNodes, tempDeleteNodes, pageFlatNodes } = parseNodeToTreeByState(action.payload);
      return Object.assign({}, state, {
        app: Object.assign({}, state.app, {
          children: realNodes,
          tempChildren: tempDeleteNodes,
          flatChildren: pageFlatNodes,
        }),
      });
    }
    // 修改节点
    case ActionType.App_PatchNode: {
      const { name, _id: nodeID } = action.payload;
      if (!state.app) {
        return state;
      }
      return Object.assign({}, state, {
        app: Object.assign({}, state.app, {
          children: renameNodeOfTree(state.app.children, nodeID, name),
          flatChildren: { ...state.app.flatChildren, [nodeID]: action.payload },
        }),
      });
    }
    // 选择节点
    case ActionType.App_SelectNode: {
      const { nodeID, isTrashPage } = action.payload;
      const isShowTrashPage = !!isTrashPage;
      const editor = EditorManager.getCoreEditor(nodeID);
      if (editor) {
        editor.isDeletedNode = isTrashPage;
      }
      if (nodeID === state.selectedNodeID && isShowTrashPage === state.isShowTrashPage) {
        return {
          ...state,
          coreEditor: editor,
        };
      }
      return Object.assign({}, state, {
        selectedNodeID: nodeID,
        trashSelectedNodIDs: isShowTrashPage ? [nodeID] : [],
        selectedIDs: isShowTrashPage ? [] : [nodeID],
        coreEditor: editor,
        isShowTrashPage,
        lastSelectedNodeID: state.selectedNodeID,
      });
    }
    // 载入页面画板数据
    case ActionType.App_LoadArtboards: {
      const { pageID, artboards, forceUpdate } = action.payload;
      clearInvalidateData(artboards);
      const childrenNodes = Object.values(state.app?.flatChildren ?? {});
      const pageNode = childrenNodes.find((v) => v._id === pageID) || { _id: pageID };
      let coreEditor: CoreEditor = EditorManager.build(pageNode, artboards, state) as CoreEditor;
      if (forceUpdate) {
        coreEditor = EditorManager.rebuild(pageNode, artboards, state) as CoreEditor;
      }
      coreEditor.appType = state.app!.appType;
      if (forceUpdate) {
        // 强制更新时，当更新的是当前页面时
        if (state.selectedNodeID === pageID) {
          return {
            ...state,
            coreEditor,
          };
        } else {
          return state;
        }
      }
      return {
        ...state,
        coreEditor,
      };
    }
    // 页面回滚
    case ActionType.Page_Revisions_revert: {
      clearInvalidateData(action.payload.artboards);
      const pageID = action.payload.pageID;
      const childrenNodes = Object.values(state.app?.flatChildren ?? {});
      const pageNode = childrenNodes.find((v) => v._id === pageID) || { _id: pageID };
      const coreEditor = EditorManager.build(pageNode, action.payload.artboards, state);
      coreEditor.appType = state.app!.appType;
      return {
        ...state,
        coreEditor,
      };
    }
    case ActionType.App_ArtboardSelectSync: {
      return state;
    }
    case ActionType.App_CloneNode: {
      return {
        ...state,
        app: Object.assign({}, state.app, {
          cloneIds: action.payload.nodeIDs,
        }),
      };
    }
    case ActionType.App_Tree_UpdateExpandedIDs: {
      setAppCache(state.appID, ExpandedIDsCacheKey, action.payload);
      return Object.assign({}, state, {
        expandedIDs: action.payload,
      });
    }
    case ActionType.App_Tree_UpdateSelectedIDs: {
      const { ids, isTrashPage } = action.payload;
      return Object.assign({}, state, {
        selectedIDs: ids,
        trashSelectedNodIDs: [],
        isShowTrashPage: isTrashPage,
      });
    }
    case ActionType.App_Tree_UpdateSearchExpandedIDs: {
      return Object.assign({}, state, {
        searchExpandedIDs: action.payload,
      });
    }
    case ActionType.App_Tree_UpdateTrashSelectedIDs: {
      const { ids, isTrashPage } = action.payload;
      return Object.assign({}, state, {
        trashSelectedNodIDs: ids,
        selectedIDs: [],
        isShowTrashPage: isTrashPage,
      });
    }
    case ActionType.App_Tree_HidePage: {
      const { realNodes, tempDeleteNodes, pageFlatNodes } = parseNodeToTreeByState(action.payload);
      return Object.assign({}, state, {
        app: Object.assign({}, state.app, {
          children: realNodes,
          tempChildren: tempDeleteNodes,
          flatChildren: pageFlatNodes,
        }),
      });
    }
    case ActionType.App_UpdateCoopers: {
      setCoopers(action.payload);
      const userSession = action.payload.find((item) => item.userID === state.userInfo?.id);
      userSession && state.coreEditor?.setCurrentSession(userSession);
      return Object.assign({}, state, {
        coopers: action.payload,
      });
    }
    case ActionType.App_KillCoopers: {
      const { sessionIDs } = action.payload;
      const newCoopers = state.coopers.filter((c) => !sessionIDs.includes(c.id));
      setCoopers(newCoopers);
      return Object.assign({}, state, {
        coopers: newCoopers,
      });
    }
    // FIXME: 这并不是一个好的方式，因为明显地，这里没有返回新状态
    case ActionType.IO_UPDATE: {
      const { type, payload } = action.payload;
      switch (type) {
        case IOType.PagePatches: {
          return state;
        }
        case IOType.PageSelect: {
          return state;
        }
        case IOType.NodeUpdate: {
          const nodes = payload as INode[];
          const { realNodes, tempDeleteNodes, pageFlatNodes } = parseNodeToTreeByState(nodes);
          let selectedNodeID = state.selectedNodeID;
          if (state.app) {
            const currentSelectedNodeIsExisted = nodes.find((node) => node._id === state.selectedNodeID);
            const trashNodeIDs = tempDeleteNodes.map((node) => node._id);
            const paths = currentSelectedNodeIsExisted?.path.split(',') || [];
            if (
              !currentSelectedNodeIsExisted ||
              currentSelectedNodeIsExisted.state === 3 ||
              intersection(trashNodeIDs, paths).length
            ) {
              const firstPageNode = getFirstPageNode(realNodes);
              if (firstPageNode) {
                selectedNodeID = firstPageNode._id;
              } else {
                selectedNodeID = '';
              }
            }
          }
          return Object.assign({}, state, {
            app: Object.assign({}, state.app, {
              children: realNodes,
              tempChildren: tempDeleteNodes,
              flatChildren: pageFlatNodes,
            }),
            selectedNodeID,
            isShowTrashPage: false,
            selectedIDs: [selectedNodeID],
            trashSelectedNodIDs: [],
          });
        }
      }
      return state;
    }
    case ActionType.App_DownloadArtboard:
      return Object.assign({}, state, { downloadArtboard: action.payload });
    case ActionType.APP_RefreshPage: {
      const { coreEditor } = state;
      if (coreEditor) {
        return {
          ...state,
        };
      }
      return state;
    }
    // 修改项目尺寸
    case ActionType.App_ChangeProjectSize: {
      const { appType, size, orientation } = action.payload;
      if (appType) {
        return Object.assign({}, state, {
          app: Object.assign({}, state.app, {
            appType,
            size,
            orientation,
          }),
        });
      }
      return state;
    }
    case ActionType.App_Patch_Info: {
      return Object.assign({}, state, {
        appID: action.payload._id || state.appID,
        app: Object.assign({}, state.app, action.payload),
      });
    }
    // case ActionType.Department_Load_List: {
    //   return {
    //     ...state,
    //     departments: action.payload,
    //   };
    // }
    case ActionType.App_ShowWater:
      return {
        ...state,
        showWater: action.payload,
      };
    case ActionType.App_IsNotExist: {
      return {
        ...state,
        noPermissionState: action.payload,
      };
    }
    case ActionType.User_NotSignIn:
      return {
        ...state,
        notSignIn: true,
      };
    case ActionType.App_Tree_Quick_Select_Next_Node: {
      const selectedNodeID = state.lastSelectedNodeID;
      if (state.app?.tempChildren.find((item) => item._id === selectedNodeID)) {
        return state;
      }
      if (action.payload === 'back') {
        if (selectedNodeID && selectedNodeID !== state.selectedNodeID) {
          return {
            ...state,
            selectedNodeID,
            selectedIDs: [selectedNodeID],
            lastSelectedNodeID: state.selectedNodeID,
            inSwitchPage: true,
          };
        }
      } else {
        const nextID = getNextNode(state.app!.children, state.selectedNodeID, action.payload === 'up' ? -1 : 1);
        if (nextID) {
          return {
            ...state,
            selectedNodeID: nextID,
            selectedIDs: [nextID],
            lastSelectedNodeID: state.selectedNodeID,
            inSwitchPage: true,
          };
        }
      }
      return state;
    }
    case ActionType.User_RecallCancellation: {
      const userInfo = { ...state.userInfo! };
      userInfo.unsubscribeTime = 0;
      return {
        ...state,
        userInfo,
      };
    }
    case ActionType.App_MoveAPP: {
      const { targetTeamID } = action.payload;
      return {
        ...state,
        app: Object.assign({}, state.app, {
          teamID: targetTeamID,
        }),
      };
    }
    case ActionType.Popularize_Loaded: {
      return {
        ...state,
        popularizes: action.payload,
      };
    }
    case ActionType.App_RefreshIdocApp: {
      return {
        ...state,
        app: Object.assign({}, state.app, {
          idocApps: action.payload,
        }),
      };
    }
    case ActionType.App_Tree_In_Switch_Page: {
      return {
        ...state,
        inSwitchPage: action.payload,
      };
    }
    case ActionType.PD_GetLicense: {
      return {
        ...state,
        pdLicense: action.payload,
      };
    }
    default:
      return state;
  }
}
