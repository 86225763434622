// import * as React from 'react';
import { isEqual } from 'lodash';

import { depthClone } from '@utils/globalUtils';
import * as pathHelper from '@helpers/pathHelper';
import { IPathValue, IPathPoint, ILineValue } from '@fbs/rp/models/value';
import IShadow from '@fbs/rp/models/properties/shadow';
import { ISize } from '@fbs/common/models/common';
import IStroke, { StrokePosition } from '@fbs/rp/models/properties/stroke';
import IFill from '@fbs/rp/models/properties/fill';
import IBorder from '@/fbs/rp/models/properties/border';
import IRadius from '@/fbs/rp/models/properties/radius';
import { DefaultBorder } from '@consts/border';
import { DefaultRadius } from '@consts/radius';
import { CRect, CEllipse, CLine, CPolygon, CPath, CCompoundPath } from '@libs/constants';
import { PolygonData } from '@/utils/graphicsUtils';
import { IProperties } from '@fbs/rp/models/property';
import { IComponentValue } from '@fbs/rp/models/value';
import { IComponentData } from '@fbs/rp/models/component';

import { StyleHelper } from './styleHelper';

export interface ISvgValue {
  data: IPathValue[];
  size: ISize;
  shadow?: IShadow;
  stroke?: IStroke;
  fill?: IFill;
}

export const componentToSvgValue = (compData: IComponentData): ISvgValue | undefined => {
  const config: { [key: string]: (size: ISize, properties: IProperties, value?: IComponentValue) => ISvgValue } = {
    [CRect]: rectToSvgValue,
    [CEllipse]: ellipseToSvgValue,
    [CLine]: lineToSvgValue,
    [CPolygon]: polygonToSvgValue,
    [CPath]: pathToSvgValue,
    [CCompoundPath]: compoundPathToSvgValue,
  };
  const fn = config[compData.type];
  if (!fn) return undefined;
  const { size, properties, value } = compData;
  return fn(size, properties, value);
};

// 半径0.5正圆
const EllipseBasePathValu: IPathValue = {
  closed: true,
  data: [
    { point: { x: 0, y: 0.5 }, handleIn: { x: 0, y: 0.2761423749153968 }, handleOut: { x: 0, y: -0.2761423749153968 } },
    { point: { x: 0.5, y: 0 }, handleIn: { x: -0.2761423749153968, y: 0 }, handleOut: { x: 0.2761423749153968, y: 0 } },
    { point: { x: 1, y: 0.5 }, handleIn: { x: 0, y: -0.2761423749153968 }, handleOut: { x: 0, y: 0.2761423749153968 } },
    { point: { x: 0.5, y: 1 }, handleIn: { x: 0.2761423749153968, y: 0 }, handleOut: { x: -0.2761423749153968, y: 0 } },
  ],
};

/**
 * 单路径
 */
const pathToSvgValue = (size: ISize, properties: IProperties, value?: IComponentValue) => {
  const { shadow, stroke, fill } = properties;
  return {
    data: [value] as IPathValue[],
    size,
    shadow,
    stroke,
    fill,
  };
};

/**
 * 复合路径
 */
const compoundPathToSvgValue = (size: ISize, properties: IProperties, value?: IComponentValue) => {
  const { shadow, stroke, fill } = properties;
  return {
    data: value as IPathValue[],
    size,
    shadow,
    stroke,
    fill,
  };
};

/**
 * 圆
 */
export const ellipseToSvgValue = (size: ISize, properties: IProperties): ISvgValue => {
  const newDate = pathHelper.scalePath(EllipseBasePathValu, { x: size.width, y: size.height });
  const newStroke = depthClone(properties.stroke);
  if (newStroke) {
    newStroke.position = newStroke.position || StrokePosition.inner;
  }
  return {
    data: [newDate],
    size: depthClone(size),
    shadow: depthClone(properties.shadow),
    stroke: newStroke,
    fill: depthClone(properties.fill),
  };
};

/**
 * 构造矩形pathValue
 */
export const formRectPathValue = (
  size: ISize,
  border: IBorder = DefaultBorder,
  radius: IRadius = DefaultRadius,
): IPathValue[] => {
  const { width, height } = size;
  const strokeRadius = StyleHelper.calculateRadius(
    {
      width: size.width,
      height: size.height,
    },
    radius,
  );

  const p1: IPathPoint = {
    point: { x: 0, y: 0 },
    handleIn: { x: 0, y: 0 },
    handleOut: { x: 0, y: 0 },
    radius: strokeRadius.topLeft,
  };

  const p2: IPathPoint = {
    point: { x: width, y: 0 },
    handleIn: { x: 0, y: 0 },
    handleOut: { x: 0, y: 0 },
    radius: strokeRadius.topRight,
  };

  const p3: IPathPoint = {
    point: { x: width, y: height },
    handleIn: { x: 0, y: 0 },
    handleOut: { x: 0, y: 0 },
    radius: strokeRadius.bottomRight,
  };

  const p4: IPathPoint = {
    point: { x: 0, y: height },
    handleIn: { x: 0, y: 0 },
    handleOut: { x: 0, y: 0 },
    radius: strokeRadius.bottomLeft,
  };

  const borders = [
    {
      start: p1,
      end: p2,
      visible: !!border.top,
    },
    {
      start: p2,
      end: p3,
      visible: !!border.right,
    },
    {
      start: p3,
      end: p4,
      visible: !!border.bottom,
    },
    {
      start: p4,
      end: p1,
      visible: !!border.left,
    },
  ];

  const groups: IPathPoint[][] = [[]];
  borders.forEach((item, i) => {
    if (item.visible) {
      groups[groups.length - 1].push(item.start);
      if (i === borders.length - 1) {
        groups[groups.length - 1].push(item.end);
      }
    } else {
      groups[groups.length - 1].push(item.start);
      if (groups[groups.length - 1].length) {
        groups.push([]);
      }
    }
  });

  const newGroups = groups.filter((g) => g.length > 1);
  if (newGroups.length > 1) {
    const firstItem = newGroups[0];
    const firstStartP = firstItem[0];
    const lastItem = newGroups[newGroups.length - 1];
    const lastEndP = lastItem[lastItem.length - 1];
    if (isEqual(firstStartP, lastEndP)) {
      lastItem.pop();
      newGroups[0] = lastItem.concat(firstItem);
      newGroups.pop();
    }
  }

  let closed = false;
  newGroups.forEach((g, i) => {
    if (g.length > 1) {
      const firstP = g[0];
      const lastP = g[g.length - 1];
      if (isEqual(firstP, lastP)) {
        g.pop();
        if (i === 0) {
          closed = true;
        }
      }
    }
  });

  return newGroups.map((group) => {
    return {
      data: group,
      closed,
    };
  });
};

/**
 * 矩形
 */
export const rectToSvgValue = (size: ISize, properties: IProperties): ISvgValue => {
  const data: IPathValue[] = formRectPathValue(size, properties.border, properties.radius);
  const newStroke = depthClone(properties.stroke);
  if (newStroke) {
    newStroke.position = newStroke.position || StrokePosition.inner;
  }
  return {
    data,
    size: depthClone(size),
    shadow: depthClone(properties.shadow),
    stroke: newStroke,
    fill: depthClone(properties.fill),
  };
};

/**
 * 多边形
 */
const polygonToSvgValue = (size: ISize, properties: IProperties): ISvgValue => {
  const polygonData = new PolygonData(size, properties.polygon?.sideCount);
  const points = polygonData.toPolygonPoints(0);
  const pathData: IPathPoint[] = points.map((p) => {
    return {
      point: p,
      handleIn: { x: 0, y: 0 },
      handleOut: { x: 0, y: 0 },
    };
  });

  const newStroke = depthClone(properties.stroke);
  if (newStroke) {
    newStroke.position = newStroke.position || StrokePosition.inner;
  }

  return {
    data: [
      {
        data: pathData,
        closed: true,
      },
    ],
    size: depthClone(size),
    shadow: depthClone(properties.shadow),
    stroke: newStroke,
    fill: depthClone(properties.fill),
  };
};

/**
 * FIXME: 箭头问题
 */
const lineToSvgValue = (size: ISize, properties: IProperties, value?: IComponentValue): ISvgValue => {
  const lineValue = value as ILineValue;
  const data: IPathValue[] = [
    {
      data: [
        {
          point: lineValue.startPoint,
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
        },
        {
          point: lineValue.endPoint,
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
        },
      ],
      closed: false,
    },
  ];
  const newStroke = depthClone(properties.stroke);
  if (newStroke) {
    newStroke.position = StrokePosition.center;
  }

  return {
    data,
    size: depthClone(size),
    shadow: depthClone(properties.shadow),
    stroke: newStroke,
    fill: depthClone(properties.fill),
  };
};
