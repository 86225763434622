import * as React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';

import { ISize } from '@fbs/common/models/common';
import { IComponentData } from '@fbs/rp/models/component';
import { SpriteThumb, WaitingImageBgTransparency } from '@/consts/spriteIcons';
import { IProperties } from '@/fbs/rp/models/property';
import { IComponentValue } from '@/fbs/rp/models/value';
import i18n from '@i18n';
import { isStandalonePreview } from '@/helpers/previewHelper';
import {
  ThirdPartPlatform,
  FILE_EXTENSIONS,
  VALID_FILE_TYPE,
  THIRD_PART_PLATFORM,
  getThirdPartPlatformByUrl,
} from '@utils/videoUtils';
import { makeCommonComponent } from '../../helper';
import { IComponentProps, IComponentItem } from '../../types';
import { CVideo } from '../../constants';
import EditVideoPlay from './EditVideoPlay';

import './index.scss';

enum FILE_STATUS {
  idle = 'idle',
  invalid = 'invalid',
  valid = 'valid',
  loading = 'loading',
}

export const VideoConfig: IComponentItem = {
  type: CVideo,
  name: i18n('resource.components.video'),
  thumb: {
    spriteIconClass: SpriteThumb.Video.className,
    dragPosition: SpriteThumb.Video.position,
  },
  getDefaultData() {
    return {
      properties: {
        controls: {
          name: i18n('property.propertyNames.controls'),
          prop: 'boolean',
          value: true,
        },
        autoPlay: {
          name: i18n('property.propertyNames.autoPlay'),
          prop: 'boolean',
          value: false,
        },
        loopPlay: {
          name: i18n('property.propertyNames.loopPlay'),
          prop: 'boolean',
          value: false,
        },
      },
    };
  },
};

export function makeVideo(
  id: string,
  URL: string = '',
  name: string = '',
  size: ISize = {
    width: 300,
    height: 300,
  },
): IComponentData {
  return makeCommonComponent(id, CVideo, {
    ...VideoConfig.getDefaultData?.(),
    name,
    size: {
      width: size.width,
      height: size.height,
      lockedRatio: true,
    },
    value: URL,
  });
}

interface IVideoState {
  value: string;
  fileStatus: FILE_STATUS;
}

export default class Video extends React.Component<IComponentProps, IVideoState> {
  private videoDom: React.RefObject<HTMLVideoElement> = React.createRef();
  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      value: props.comp.value as string,
      fileStatus: FILE_STATUS.idle,
    };
    this.param = this.getParams(props, this.state);
  }

  private param: {
    size: ISize;
    properties: IProperties;
    value?: IComponentValue;
    opacity: number;
    isPreview?: boolean;
    transition: string;
    version: string;
  } & { [key: string]: any };

  private getParams(props: IComponentProps, state: IVideoState) {
    const {
      comp: { size, properties, value, opacity, version },
      isPreview,
    } = props;
    const transition = props.comp.getTransition();
    return {
      size: _.cloneDeep(size),
      properties: _.cloneDeep(properties),
      value: _.cloneDeep(value),
      opacity,
      isPreview,
      transition,
      version,
      fileStatus: state.fileStatus,
    };
  }

  shouldComponentUpdate(nextProps: IComponentProps, nextState: IVideoState) {
    const newParam: { [key: string]: any } = this.getParams(nextProps, nextState);

    let flag = false;
    Object.keys(this.param).forEach((key) => {
      if (!_.isEqual(newParam[key], this.param[key])) {
        flag = true;
        this.param[key] = _.cloneDeep(newParam[key]);
      }
    });
    if (this.state.value !== nextState.value) {
      flag = true;
    }
    return flag;
  }

  /**
   * 检查是否合法文件
   */
  checkUrlValid(url: string) {
    if (typeof url !== 'string') {
      return false;
    }
    if (!FILE_EXTENSIONS.some((extension) => url.toLowerCase().endsWith(extension))) {
      return false;
    }
    return true;
  }

  /**
   * 根据路径获取所属平台
   */
  getThirdPartPlatformByUrl(url: string) {
    return getThirdPartPlatformByUrl(url);
  }

  checkFileValid(url: string) {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      let isExecute = false;
      const handleError = () => {
        if (isExecute) {
          return;
        }
        isExecute = true;
        reject();
      };
      const handleSuccess = () => {
        if (isExecute) {
          return;
        }
        isExecute = true;
        resolve(true);
      };
      VALID_FILE_TYPE.forEach((type) => {
        const source = document.createElement('source');
        source.type = type;
        source.src = url;
        source.onerror = source.onabort = () => {
          source.onerror = source.onabort = null;
          handleError();
        };
        video.appendChild(source);
      });
      video.preload = 'none';
      video.autoplay = false;
      video.oncanplay = () => {
        video.oncanplay = null;
        handleSuccess();
      };
      video.onloadeddata = () => {
        video.onloadeddata = null;
        handleSuccess();
      };
      video.onabort = video.onerror = () => {
        video.onabort = video.onerror = null;
        handleError();
      };
      video.load();
    });
  }

  checkVideoValid(url: string, callback?: () => void) {
    if (url === '') {
      this.setState({ value: url, fileStatus: FILE_STATUS.idle });
      return;
    }

    const isValid = this.checkUrlValid(url);
    if (isValid) {
      this.setState({ value: url, fileStatus: FILE_STATUS.loading });
      this.checkFileValid(url)
        .then(() => {
          this.setState({ value: url, fileStatus: FILE_STATUS.valid }, () => {
            callback && callback();
          });
        })
        .catch(() => {
          this.setState({ value: url, fileStatus: FILE_STATUS.invalid });
        });
    } else {
      const item = this.getThirdPartPlatformByUrl(url);
      // 如果路径是第三方平台，也是有效路径
      if (item) {
        this.setState({ value: url, fileStatus: FILE_STATUS.valid }, () => {
          callback && callback();
        });
        return;
      }
      this.setState({ value: url, fileStatus: FILE_STATUS.invalid });
    }
  }

  loadVideoMateData() {
    if (isStandalonePreview || !this.props.isStandalone) {
      this.videoDom.current?.load();
    }
  }

  componentDidUpdate() {
    const value = this.props.comp.value;
    if (this.props.isPreview) {
      if (this.state.value !== value) {
        this.setState({ value: value as string }, () => {
          this.loadVideoMateData();
        });
      }
    } else if (RP_CONFIGS.isPrivateDeployment) {
      if (this.state.value !== value) {
        this.setState({ value: value as string }, () => {
          this.loadVideoMateData();
        });
      }
    } else {
      if (this.state.value !== value) {
        this.checkVideoValid(value as string, () => {
          this.loadVideoMateData();
        });
      }
    }
  }

  componentDidMount() {
    const videoDom = this.videoDom.current;
    const { comp, isPreview } = this.props;
    const { properties } = comp;
    const controls = !!(properties.controls && properties.controls.value);
    const canControl = !!isPreview && controls;
    if (videoDom) {
      // https://devops.aliyun.com/projex/project/27c85c8d7d9bd291e75408ca92/sprint/7e9753dd92ada6335ef2a6d8d1#activeTab=Workitem&openWorkitemIdentifier=aff5d1ad8ae543a6264120a2ba&viewIdentifier=e23185c964cbf9606c3dca943a
      // 分析初步原因可能是dom渲染不完整就出现控制条导致样式不完整。尝试改成这样
      videoDom.addEventListener('canplay', () => {
        videoDom.controls = canControl;
      });
    }

    if (this.props.isPreview) {
      this.loadVideoMateData();
    } else if (!RP_CONFIGS.isPrivateDeployment) {
      this.checkVideoValid(this.state.value, () => {
        this.loadVideoMateData();
      });
    }
  }

  renderPublicEditContent() {
    const fileStatus = this.state.fileStatus;
    if (fileStatus === FILE_STATUS.invalid) {
      return (
        <div className="invalid-video-url">
          <div>{i18n('tips.invalidVideoUrl')}</div>
        </div>
      );
    } else if (!this.state.value) {
      return (
        <div className="default-view">
          <div className="lib-comp-video-nothing">{i18n('tips.doubleClickUploadVideo')}</div>
        </div>
      );
    } else if (fileStatus === FILE_STATUS.loading) {
      return (
        <div className="loading-view">
          <img src={WaitingImageBgTransparency} />
          <label>{i18n('general.loading')}</label>
        </div>
      );
    }
  }

  /**
   * 渲染公有版本（支持url）
   * **/
  renderPublic() {
    const { fileStatus, value } = this.state;
    const { comp, isPreview, isStandalone } = this.props;
    const { properties, disabled, size } = comp;
    const controls = !!(properties.controls && properties.controls.value);
    const autoPlay = !!(properties.autoPlay && properties.autoPlay.value);
    const loopPlay = !!(properties.loopPlay && properties.loopPlay.value);
    const style: React.CSSProperties = { ...size };
    style.transition = comp.getTransition();
    style.opacity = _.isUndefined(comp.opacity) ? 1 : comp.opacity / 100;

    const videoFileValid = fileStatus === FILE_STATUS.valid;

    const canAutoPlay = isPreview && autoPlay && !disabled;
    const canLoop = isPreview && loopPlay && !disabled;
    const canControl = isPreview && controls;

    const renderNativeVideo = () => {
      return (
        <video
          className="compatible"
          autoPlay={canAutoPlay}
          loop={canLoop}
          playsInline
          x5-video-player-type="h5-page"
          ref={this.videoDom}
          preload={isStandalone ? 'none' : 'metadata'}
        >
          {VALID_FILE_TYPE.map((type) => {
            return <source key={type} src={value as string} type={type} />;
          })}
        </video>
      );
    };
    const renderThirdPartVideo = (url: string, thirdPartInfo: ThirdPartPlatform) => {
      const { src, iframeConfig = {} } = thirdPartInfo.getPlayConfig(url, {
        controls: !!canControl,
      });
      return (
        <iframe
          src={src}
          width="100%"
          height="100%"
          scrolling="no"
          frameBorder={0}
          allowFullScreen
          {...iframeConfig}
        ></iframe>
      );
    };
    const renderVideo = () => {
      try {
        if (isStandalone && !isStandalonePreview) {
          return <EditVideoPlay controls={controls} text=""></EditVideoPlay>;
        }
        if (this.checkUrlValid(value)) {
          return renderNativeVideo();
        } else {
          const urlInfo = new URL(value);
          const thirdPartInfo = THIRD_PART_PLATFORM.find((d) => urlInfo.hostname.indexOf(d.name + '.') !== -1);
          if (thirdPartInfo) {
            return renderThirdPartVideo(value, thirdPartInfo);
          }
        }
      } catch (e) {
        console.warn(e);
      }
      return renderNativeVideo();
    };
    return (
      <div
        className={classnames('lib-comp-video', {
          'lib-comp-invalid': fileStatus === FILE_STATUS.invalid,
          empty: !value,
        })}
        style={style}
      >
        {!isPreview && this.renderPublicEditContent()}
        {!isPreview && videoFileValid ? (
          <EditVideoPlay controls={controls} text={i18n('tips.videoEditPlayText')}></EditVideoPlay>
        ) : null}
        {isPreview && value && renderVideo()}
      </div>
    );
  }

  /**
   *渲染私有版本(支持上传)
   **/
  renderPrivate() {
    const { value } = this.state;
    const { comp, isPreview, isStandalone } = this.props;
    const { properties, disabled, size } = comp;
    const controls = !!(properties.controls && properties.controls.value);
    const autoPlay = !!(properties.autoPlay && properties.autoPlay.value);
    const loopPlay = !!(properties.loopPlay && properties.loopPlay.value);
    const style: React.CSSProperties = { ...size };
    style.transition = comp.getTransition();
    style.opacity = _.isUndefined(comp.opacity) ? 1 : comp.opacity / 100;
    return (
      <div className={classnames('lib-comp-video', { empty: !value })} style={style}>
        {!value && !isPreview && (
          <div className="default-view">
            <p className="lib-comp-video-nothing">{i18n('tips.doubleClickUploadVideo')}</p>
          </div>
        )}
        {value && (
          <video
            className="compatible"
            autoPlay={isPreview && autoPlay && !disabled}
            loop={isPreview && loopPlay && !disabled}
            controls={isPreview && controls}
            playsInline
            x5-video-player-type="h5-page"
            ref={this.videoDom}
            preload={isStandalone ? 'none' : 'metadata'}
          >
            <source src={comp.value as string} type="video/mp4" />
          </video>
        )}
      </div>
    );
  }
  render() {
    return RP_CONFIGS.isPrivateDeployment ? this.renderPrivate() : this.renderPublic();
  }
}
