import { isUndefined } from 'lodash';

// 集中处理由轮播产生的timer
// 每次循环就清理一次
class CarouselManager {
  // 避免做个内容面板互相影响
  private timerMap: {
    [id: string]: number | undefined;
  } = {};
  //  避免其他组件针对不同内容面板操作的互相影响
  private passiveValueMap: {
    [id: string]: {
      isInit: boolean;
      index: number;
    };
  } = {};

  setTimers(id: string, timer: number) {
    this.timerMap[id] = timer;
  }

  clearCustomTimeout = () => {
    for (const id in this.timerMap) {
      this.clearSingleCustomTimeout(id);
    }
  };

  clearSingleCustomTimeout = (id: string) => {
    window.clearTimeout(this.timerMap[id]);
    this.timerMap[id] = undefined;
  };

  setPassiveValue(
    id: string,
    value: {
      isInit?: boolean;
      index?: number;
    },
  ) {
    this.passiveValueMap[id] = {
      ...this.passiveValueMap[id],
      ...value,
    };
  }

  getPassiveValue(
    id: string,
  ): {
    isInit: boolean;
    index: number;
  } {
    if (!this.passiveValueMap[id]) {
      return {
        isInit: false,
        index: -1,
      };
    }
    return this.passiveValueMap[id];
  }

  resetPassiveValue(id: string) {
    this.passiveValueMap[id] = {
      ...this.passiveValueMap[id],
      index: -1,
    };
  }

  resetAllPassiveValue() {
    for (const id in this.passiveValueMap) {
      this.passiveValueMap[id] = {
        isInit: false,
        index: -1,
      };
    }
  }

  isTargetExist = (id: string): boolean => {
    return !isUndefined(this.timerMap[id]);
  };
}

const carouselManager = new CarouselManager();

export default carouselManager;
