import * as React from 'react';
import * as _ from 'lodash';
import { transBlankChart } from '@utils/textUtils';
import { IComponentProps } from '../../types';
import { StyleHelper } from '@helpers/styleHelper';
import PureTextEditor from '../common/PureTextEditor';
import { FontBoxScale } from '@consts/fonts';

import './index.scss';
import { ISize } from '@/fbs/common/models/common';
import { IProperties } from '@/fbs/rp/models/property';
import { IComponentValue } from '@/fbs/rp/models/value';

export default class PureTextComponent extends React.Component<IComponentProps> {
  constructor(props: IComponentProps) {
    super(props);
    this.param = this.getParams(props);
  }

  private param: {
    size: ISize;
    properties: IProperties;
    value?: IComponentValue;
    opacity: number;
    transition: string;
    autoSize: boolean;
    version: string;
  } & { [key: string]: any };

  private getParams(props: IComponentProps) {
    const {
      comp: { size, properties, value, opacity, autoSize, version },
    } = props;
    const transition = props.comp.getTransition();
    return {
      size: _.cloneDeep(size),
      properties: _.cloneDeep(properties),
      value: _.cloneDeep(value),
      opacity,
      transition,
      autoSize,
      version,
    };
  }

  shouldComponentUpdate(nextProps: IComponentProps) {
    const newParam: { [key: string]: any } = this.getParams(nextProps);

    let flag = false;
    Object.keys(this.param).forEach((key) => {
      if (!_.isEqual(newParam[key], this.param[key])) {
        flag = true;
        this.param[key] = _.cloneDeep(newParam[key]);
      }
    });

    return flag;
  }

  doSubmit = (value: string) => {
    const { onValueEdited, comp } = this.props;
    const selection = document.getSelection();
    if (selection) {
      selection.empty();
    }
    onValueEdited && onValueEdited(comp, value);
  };

  renderEditor(style: React.CSSProperties) {
    const { comp } = this.props;
    const { value } = comp;
    return (
      <PureTextEditor
        value={value as string}
        className=""
        style={{
          ...style,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        onChange={this.doSubmit}
      />
    );
  }

  render() {
    const { comp, valueEditing } = this.props;
    const { properties, size, value, opacity } = comp;
    const text = transBlankChart(value as string);
    if (!text) {
      return null;
    }
    const { textFormat, textStyle } = properties;
    const cssParser = StyleHelper.initCSSStyleParser(properties);
    const style: React.CSSProperties = {
      ...cssParser.getTextStyle(),
      ...cssParser.getTextShadow(),
      lineHeight: `${size.height}px`,
      opacity: _.isUndefined(opacity) ? 1 : opacity / 100,
    };
    const fontSize = (textFormat || textStyle)?.fontSize || 14;
    const letterSpace = (textFormat || textStyle)?.letterSpace || 0;

    style.fontSize = fontSize / FontBoxScale;
    style.lineHeight = size.height / FontBoxScale + 'px';
    style.letterSpacing = letterSpace / FontBoxScale;
    style.transform = 'scale(' + FontBoxScale + ')';
    style.transformOrigin = '0 0';
    style.width = size.width / FontBoxScale;
    style.height = size.height / FontBoxScale;
    if (valueEditing) {
      return this.renderEditor(style);
    }
    // FIXME 自动大小时，保证能全部显示，可能存在跨系统时，位置略有偏差，待验证
    const autoSize = comp.toJSON().autoSize;
    if (autoSize || _.isUndefined(autoSize)) {
      style.width = 'fit-content';
      // style.minWidth = '100%';
      style.minWidth = 100 / FontBoxScale + '%';
    }
    return (
      <div className="lib-comp-pure-text" style={style}>
        <label>{text}</label>
      </div>
    );
  }
}
