import { IPageArtboardRevisions, IPageRevision } from '@fbs/rp/models/revisions';
import IArtboard from '@fbs/rp/models/artboard';

import { PageRevisions } from '@/store/revisions/actions';
import { ActionType } from '@/store/types';

export interface IPageRevisionsState {
  revisions?: IPageArtboardRevisions;
  artboards: {
    [key: string]: IArtboard;
  };
  artboardFragments: IArtboard[];
  waiting?: boolean;
}

const initState: IPageRevisionsState = {
  artboards: {},
  artboardFragments: [],
  waiting: false,
};

export default function (state = initState, action: PageRevisions): IPageRevisionsState {
  switch (action.type) {
    // 分页
    case ActionType.Page_Revisions_Load: {
      const reversion = state.revisions?.revisions || [];
      reversion.push(...action.payload.revisions);
      return {
        ...state,
        revisions: {
          ...action.payload,
          revisions: reversion,
        },
        artboards: state.artboards || {},
      };
    }
    case ActionType.Page_Revisions_loadArtboard: {
      const artboards = { ...state.artboards };
      if (!action.payload) {
        return { ...state, artboards: {} };
      }
      const { _id } = action.payload;
      artboards[_id] = action.payload;
      return {
        ...state,
        artboards,
      };
    }
    case ActionType.Page_Revisions_loadArtboardFragments: {
      if (!action.payload) {
        return { ...state, artboardFragments: [] };
      }
      const artboardFragments = action.payload;
      return {
        ...state,
        artboardFragments,
      };
    }
    case ActionType.Page_Revision_Switch:
      return {
        ...state,
        artboards: {},
      };
    case ActionType.Page_Revision_Waring:
      return {
        ...state,
        waiting: action.payload,
      };
    case ActionType.Page_Version_create: {
      let revisions = state.revisions;
      if (!revisions) {
        revisions = action.payload;
      }
      const newRevisions: IPageRevision[] = action.payload.revisions.concat(state.revisions?.revisions ?? []);
      return {
        ...state,
        revisions: {
          ...revisions,
          revisions: newRevisions,
        },
      };
    }
    case ActionType.Page_Version_Delete: {
      if (!state.revisions) {
        return state;
      }
      const newRevision = state.revisions.revisions.filter((item) => item.snapshotID !== action.payload);
      return {
        ...state,
        revisions: { ...state.revisions, revisions: newRevision },
      };
    }
    case ActionType.Page_Version_rename: {
      const revisions = state.revisions;
      if (!revisions) {
        return state;
      }
      const { snapshotID, name } = action.payload;
      const newRevisions = revisions?.revisions.map((item) => {
        if (snapshotID !== item.snapshotID) {
          return item;
        }
        return {
          ...item,
          snapshotName: name,
        };
      });
      return {
        ...state,
        revisions: {
          ...revisions,
          revisions: newRevisions,
        },
      };
    }
    case ActionType.Page_Version_clear: {
      return {
        ...state,
        revisions: undefined,
      };
    }
    default:
      return state;
  }
}
