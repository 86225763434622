import { inliner } from '../domToImage/inliner';
import { searchByReg } from '../util/replaceUtil';
import { styleWrapper } from '../util/styleUtil';
import { globalStatistics } from '../util/statisticsUtil';
/**
 * 根据页面内容获取web字体
 * @param html
 */
export const getStyleWebFonts = async (html: string): Promise<string> => {
  const list = getFontFamilyFromHtml(html);
  const processer = list.map(async (t) => {
    const webfont = await getWebFont(t);
    return styleWrapper(webfont);
  });
  const result = await Promise.all(processer);
  return result.join('\n');
};

/**
 * 这里 mockplus_rp，不应该出现在组件里
 * 不规范组件有
 * multipleSelect
 * datePicker
 * tree
 * time-picker的字体定义在class中
 * @param html
 */
export const getFontFamilyFromHtml = (html: string): string[] => {
  const list: string[] = [];
  list.push('<[^<>]*?font-family:([^<>]*?);[^<>]*?>');
  list.push('class="[^<>]*?(mockplus_rp)[^<>]*?');
  list.push('{[^{}]*?font-family:\\s*?(boldIconFont|lightIconFont);[^{}]*?}');
  list.push('{[^{}]*?font-family:\\s*?(mockplus_rp);[^{}]*?}');

  const doMatchString = (str: string): string[] => {
    const names = pickFontFamily(str);
    if (names) {
      return names;
      //[name];
      // if (isChrome() || isSafari()) { //  || isFireFox
      //   return [name];
      // } else if (/mockplus_rp|IconFont/i.test(name)) {
      //   //boldIconFont
      //   // tofix：fireforx不支持特殊字体下载
      //   // 比如：以下字体与其他字体一起时 ，会出现 DOMException: The URI is malformed,
      //   // taibeihei 台北黑
      //   // Source Han Serif SC思源宋
      //   // YuanQuanYuan 源泉圆体
      //   return [name];
      // }
    }
    return [];
  };
  const set = searchByReg(html, list, doMatchString);
  const result = Array.from(set);
  // window.debug && console.log(result);
  // 统计
  globalStatistics.addFont('', result);
  return result;
};

export const pickFontFamily = (family: string): string[] => {
  if (/unset|inherit|&quot|-apple-system/.test(family)) {
    return [];
  }
  const names = family.split(',');
  const stack: string[] = [];
  names.forEach((t) => {
    if (t) {
      const name = t.trim();
      stack.push(name.replace(/"|!important/g, '').trim());
      // if (/^[a-zA-Z]+?$/.test(name)) {
      //   // 英文且无空格 优先
      //   stack.unshift(name)
      // } else {
      //   // 其他中文 或 因为有空格 不要
      //   // stack.push(name.replace(/"|!important/g, '').trim())
      // }
    }
  });
  // 只选一个
  return stack.slice(0, 1);
};

/**
 * 根据字体名称获取web字体
 * @param fontFamily
 */
export const getWebFont = async (fontFamily: string): Promise<string> => {
  try {
    const rules = getWebFontRules(fontFamily);
    const processer = rules.map((t) => {
      return resolve(t);
    });
    const all = await Promise.all(processer);
    return all.join('\n');
  } catch (e) {
    console.error(e);
    return '';
  }
};

/**
 * 过滤字体
 * @param styleSheets
 * @returns
 */
export function getWebFontRules(fontFamily: string): CSSFontFaceRule[] {
  const cssRules: CSSFontFaceRule[] = [];
  // 名字相同的只取一个
  const fontFamilySet = new Set();
  const styleSheets = Array.from(document.styleSheets);
  styleSheets.forEach((sheet: CSSStyleSheet) => {
    try {
      Array.from(sheet.cssRules).forEach((rule: CSSRule) => {
        if (rule instanceof CSSFontFaceRule) {
          const style = rule.style;
          //  直接获取style.fontFamily不兼容
          const value = style.getPropertyValue('font-family');
          if (!fontFamilySet.has(fontFamily) && value.includes(fontFamily)) {
            cssRules.push(rule);
            fontFamilySet.add(fontFamily);
          }
        }
      });
    } catch (e) {
      // console.log('Error while reading CSS rules from ' + sheet.href, e.toString());
    }
  });
  return cssRules;
}

function resolve(webFontRule: CSSFontFaceRule): Promise<string> {
  let baseUrl = (webFontRule.parentStyleSheet || {}).href ?? undefined;
  // 无baseUrl表示本地已经下载
  // @font-face { font-family: "mockplus_rp"; src: url("/44a64f18d4e6616fa10ca1393257b9b8.woff2") format("woff2"), url("/13196b77e2a1adab3f8c757cc805635c.woff") format("woff"), url("/80a52bbdb02f293f82b8c0592a768d96.ttf") format("truetype"); } undefined
  return inliner.inlineAll(webFontRule.cssText, baseUrl);
}

export const getCssStyleSheet = () => {
  const styleSheets = Array.from(document.styleSheets);
  window.debug && console.log(styleSheets);
  styleSheets.forEach((sheet: CSSStyleSheet) => {
    console.log(sheet.type);
  });
};
