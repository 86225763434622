/**
 * 不可见
 * @param el
 * @param parent
 */
export const isElementInvisible = (el: Element, parent: Element) => {
  //获取元素可视区域
  const rect = el.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();
  return rect.bottom <= parentRect.top || rect.top >= parentRect.bottom;
};

export const scrollCheckedTreeNodeIntoView = () => {
  // 选中页选中滚动到可视区 ,只选一个的情况
  setTimeout(() => {
    const item = document.querySelectorAll('.tree-node-rp .checkbox-checked');
    const parent = document.querySelector('.dsm-c-rp-dialog .dsm-c-scrollview');
    if (item && item.length === 1 && parent) {
      if (isElementInvisible(item[0], parent)) {
        item[0].scrollIntoView();
      }
    }
  }, 500);
};
