import { adjustComponentStyle } from '../domToImage/adjustComponentStyle';
import { copyShapeInfo, copyStyleItemRuler, copyTextInfo, hasTextContent } from './copyStyleItemRuler';
import { ICopyStyleRuler, ICopyStyleRulerOptions } from '../type';

export const copyStyleRuler: ICopyStyleRuler = (options: ICopyStyleRulerOptions): void => {
  const { isDiectTextNode, source, target, node, isRoot } = options;
  if (isDiectTextNode) {
    // 文本样式
    copyTextInfo(source, target);
    // return;
  } else if (hasTextContent(node)) {
    // 非直接文本节点，不需要样式  要位置

    copyShapeInfo(source, target);
    // return;
  } else {
    copyProperties(source, target, node, !!isDiectTextNode);
    // 结合具体业务场景,调整组件样式
    adjustComponentStyle(node, target);
  }

  // 跟节点 去掉位置信息
  if (isRoot) {
    target.removeProperty('transform');
  }

  return;
};

function copyProperties(
  source: CSSStyleDeclaration,
  target: CSSStyleDeclaration,
  original: Element,
  isDiectTextNode: boolean,
) {
  const node = original as HTMLElement;

  // 其他通用节点
  Array.from(source).forEach((name: string) => {
    // 属性继承策略
    const value = source.getPropertyPriority(name) || source.getPropertyValue(name);
    if (
      copyStyleItemRuler({
        name,
        value,
        node,
        isDiectTextNode,
        source,
      })
    ) {
      target.setProperty(name, value);
    }
  });
}
