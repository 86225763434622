import { IAppF, IAppWithNestedChildren } from '@fbs/rp/models/app';
import { IPageArtboardRevisions } from '@fbs/rp/models/revisions';
import IArtboard from '@fbs/rp/models/artboard';
import { OffLineOperation } from '@fbs/rp/models/io';
import { IAppInfo } from '@fbs/idoc/models/app';
import { IAppCreateReq, IAppsOfTeam, RoleInTeam } from '@fbs/teamManagement';
import { CCResponseLicense } from '@helpers/privateLimitedTypes';

import { put, get, patch, axDelete, post, cancelToken } from './helper';
import { ResponseWithCancel } from '@/consts/api';
import { IElement, IPrototypeResponse, TImageSize } from 'mock-ai-sdk';

export async function addApp(data: IAppCreateReq): Promise<IAppsOfTeam> {
  return await put<IAppsOfTeam>(`/app`, data);
}

export async function getAppByID(id: string, isExample: boolean = false): Promise<IAppF> {
  return await get<IAppF>(`/app/${id}?includeRemoved=true&isExample=${isExample}&rnd=${new Date().getTime()}`);
}

export interface IIDocShareMsg {
  joined: boolean;
  role: RoleInTeam;
  shareMsg: { allowShare: boolean };
}

export enum AIActionType {
  NLToProto = 'NLToProto',
  NLToImage = 'NLToImage',
  NLToTxt = 'NLToTxt',
  NLToTranslate = 'NLToTranslate',
  NLToTable = 'NLToTable',
  NLToChart = 'NLToChart',
}

export enum TranslateLanguage {
  English = 'English',
  Chinese = 'Chinese',
  French = 'French',
  Russian = 'Russian',
  Arabic = 'Arabic',
  Spanish = 'Spanish',
}

export interface ITeamAIInfo {
  points: number;
  state: 0 | 3 | 4;
}

export async function getIDocAppShareMsg(iDocAppID: string): Promise<IIDocShareMsg> {
  return await get<IIDocShareMsg>(`/iDocApp/share/message/${iDocAppID}`);
}

export async function getAppShareMsg(AppID: string): Promise<IIDocShareMsg> {
  return await get<IIDocShareMsg>(`/app/preview/share/message/${AppID}`);
}

// TODO  拆分后涉及到查询最新数据和历史版本数据，固定只能使用shareID
export async function getAppByShareID(
  shareID: string,
  prototypeID?: string,
): Promise<IAppWithNestedChildren & { appID: string }> {
  return await get<IAppWithNestedChildren & { appID: string }>(
    `/app/preview/${shareID}?prototypeID=${prototypeID || ''}&rnd=${new Date().getTime()}`,
  );
}

export async function editAppName(appID: string, data: { name: string }) {
  return await patch<IAppF>(`/app/${appID}`, data);
}

export async function editAppInfo(appID: string, data: Partial<IAppF>) {
  return await patch<IAppF>(`/app/${appID}`, data);
}

export async function updateAppCover(appID: string, img: File) {
  const formData = new FormData();
  formData.append('image', img);
  return await post<{ URL: string }>(`/app/${appID}/cover`, formData);
}

export async function deleteAppByID(appID: string) {
  return await axDelete(`/app/${appID}`);
}

export async function getPageRevisions(
  appID: string,
  pageID: string,
  lastSnapshotID: string,
  pageSize: number,
  hasAuto: boolean,
) {
  return await get<IPageArtboardRevisions>(
    `/time-machine/${appID}/node-revisions/${pageID}?lastSnapshotID=${lastSnapshotID}&pageSize=${pageSize}&includesAuto=${hasAuto}`,
  );
}

export async function getArtboardDataByRevision({
  appID,
  pageID,
  snapShortID,
  artboardID,
}: {
  appID: string;
  pageID: string;
  snapShortID: string;
  artboardID: string;
}) {
  return await get<IArtboard>(`/time-machine/${appID}/node-artboard/${pageID}/${snapShortID}/${artboardID}`);
}

export async function reversionPageRevision(appID: string, pageID: string, snapShortID: string) {
  return await put<any>(`/time-machine/${appID}/node-roolback/${pageID}/${snapShortID}`);
}

export async function savePageVersion(appID: string, pageID: string, name: string) {
  return await put<any>(`/time-machine/${appID}/node-create/${pageID}`, { name });
}

export async function deletePageVersion(snapshotID: string) {
  return await axDelete(`/time-machine/${snapshotID}/node-delete`);
}

export async function renamePageVersion(snapshotID: string, name: string) {
  return await patch(`/time-machine/${snapshotID}/node-rename`, { name });
}

export async function saveExample(
  appID: string,
  selectedPageID: string,
  operations: Readonly<OffLineOperation[]>,
  teamID?: string,
  appSetID?: string,
) {
  return await put<{ app: IAppInfo; pageID: string }>(`/app/cloneExampleAppToUser`, {
    appID,
    pageID: selectedPageID,
    operations,
    teamID,
    appSetID,
  });
}

/**
 * 某个项目进入RP编辑 刷新某个项目最近更新时间
 * @param appID
 * @param teamID
 */
export function accessApp(appID: string, teamID: string) {
  return post(`/app/access/${appID}`, { teamID });
}

/**
 * 保存当前未保存的操作
 * @param appID
 * @param offlineOperations
 */
export function saveAppPatches(appID: string, offlineOperations: OffLineOperation[]) {
  return post<{
    isSaved: boolean;
  }>(`/io/saveAppPatches/${appID}`, { appPatches: offlineOperations });
}

/*
 * 导入MRP项目文件
 */
export function importRPAppFile(teamID: string, file: File, delayID: string) {
  const postData = new FormData();
  postData.append('zip', file);
  // postData.append('delayID', delayID);
  return post<{
    appID: string;
    app: IAppInfo;
  }>(`/app/importRPAppZip/${teamID}?delayID=${delayID}`, postData);
}

/*
 * 导入MRP项目文件
 */
export function importMRPAppFile(teamID: string, appID: string, file: File, delayID: string) {
  const postData = new FormData();
  postData.append('zip', file);
  postData.append('delayID', delayID);
  return post<{
    appID: string;
    app: IAppInfo;
  }>(`/app/importMrpZip/${teamID}/${appID}?delayID=${delayID}`, postData);
}

/**
 * 移动项目
 */
export function moveApp(appID: string, targetTeamID: string, targetAppSetID: string) {
  return patch(`/app/appSet/${targetTeamID}/${targetAppSetID}/add/${appID}`, {});
}

/*
 * 获取RP项目数量限制信息
 */
export function getPrivateProjectNumLimitInfo<T>(): Promise<T> {
  return get<T>(`/app/limited`);
}

/*
 * 获取CC私有价格策略license信息
 */
export function getPrivateLicenseInfo(): Promise<CCResponseLicense> {
  return get(`/software/license`);
}

/*
 * 获取远程数据填充图片数据
 */
export function getAutofillImages(): Promise<{ autofills: { type: string; url: string }[] }> {
  return get(`/autofill/imageUrls`);
}

export function useAIBill(actioType: AIActionType, teamID: string): Promise<ITeamAIInfo> {
  return post(`/mockai/aiBillLog/${actioType}`, { teamID });
}

export async function nlToPrototype(
  prompt: string,
  teamID: string,
): Promise<ResponseWithCancel<IPrototypeResponse<keyof IElement>>> {
  const { token, cancel } = await cancelToken();
  return { api: post(`/mockai/nlToPrototype`, { prompt, teamID }, { cancelToken: token }), cancel };
}

export async function nlToImage(
  prompt: string,
  teamID: string,
  size: TImageSize = '256x256',
  limit: number = 1,
): Promise<ResponseWithCancel<string[]>> {
  const { token, cancel } = await cancelToken();
  return {
    api: post(`/mockai/nlToImage`, { prompt, teamID, size, total: limit }, { cancelToken: token }),
    cancel,
  };
}

export async function nlToTxt(
  prompt: string,
  teamID: string,
  limit: number = 1,
): Promise<ResponseWithCancel<string[]>> {
  const { token, cancel } = await cancelToken();
  return { api: post(`/mockai/nlToTxt`, { prompt, teamID, size: limit }, { cancelToken: token }), cancel };
}

export async function nlToTable(prompt: string, teamID: string): Promise<ResponseWithCancel<string[]>> {
  const { token, cancel } = await cancelToken();
  return { api: post(`/mockai/nlToTable`, { prompt, teamID }, { cancelToken: token }), cancel };
}

export async function nlToChart(prompt: string, teamID: string): Promise<ResponseWithCancel<string[]>> {
  const { token, cancel } = await cancelToken();
  return { api: post(`/mockai/nlToChart`, { prompt, teamID }, { cancelToken: token }), cancel };
}

export async function nlToTranslate(
  prompt: string[],
  teamID: string,
  language: TranslateLanguage,
): Promise<ResponseWithCancel<string[]>> {
  const { token, cancel } = await cancelToken();
  return { api: post(`/mockai/nlToTranslate`, { prompt, teamID, language }, { cancelToken: token }), cancel };
}

export function getTeamAIBill(teamID: string): Promise<ITeamAIInfo | undefined> {
  return get(`/mockai/aiaccount/team/${teamID}`);
}
