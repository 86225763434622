import * as React from 'react';
import { ISize } from '@/fbs/common/models/common';
import { StrokePosition } from '@fbs/rp/models/properties/stroke';
import { StyleHelper } from '@/helpers/styleHelper';

interface StrokeOpt {
  id: string;
  data: string;
  maskData?: string;
  size: ISize;
  scale: number;
  strokePosition: StrokePosition;
  stroke: StyleHelper.ISVGStroke;
  offset?: { x: number; y: number };
  showMark?: boolean;
  transition?: string;
}

export const renderMaskStroke = (opt: StrokeOpt) => {
  const { id, data, size, stroke, strokePosition, scale, offset: optOffset, maskData, showMark, transition } = opt;
  const offsetX = optOffset?.x || 0;
  const offsetY = optOffset?.y || 0;
  const strokePathId = `stroke-path-${id}`;
  const maskPathId = `mask-path-${id}`;
  const clipPathId = `clip-path-${id}`;
  const strokeMaskId = `stroke-mask-${id}`;
  const offset = 100 * (stroke.strokeWidth || 0);
  const transitionStyle = { transition, transformOrigin: 'left top' };
  switch (strokePosition) {
    case StrokePosition.center: {
      return (
        <path
          d={data}
          {...stroke}
          shapeRendering="geometricPrecision"
          fill={'transparent'}
          style={transitionStyle}
          markerStart={showMark ? `url('#${id}-start-marker')` : undefined}
          markerEnd={showMark ? `url('#${id}-end-marker')` : undefined}
        />
      );
    }
    case StrokePosition.outer: {
      return (
        <>
          <defs>
            <path id={strokePathId} fillRule="evenodd" d={data} style={transitionStyle} />
            {!!maskData && <path id={maskPathId} fillRule="evenodd" d={maskData} />}
            <mask
              id={strokeMaskId}
              x={-offset}
              y={-offset}
              height={size.height + offset * 2}
              width={size.width + offset * 2}
              maskContentUnits="userSpaceOnUse"
              maskUnits="objectBoundingBox"
            >
              <rect
                width={(size.width + offset) * scale}
                height={(size.height + offset) * scale}
                x={offsetX + -0.5 * offset * scale}
                y={offsetY + -0.5 * offset * scale}
                fill="white"
              />
              <use fill="black" href={`#${maskData ? maskPathId : strokePathId}`} />
            </mask>
          </defs>
          <use
            fillOpacity="0"
            fill="none"
            {...stroke}
            strokeWidth={(stroke.strokeWidth || 0) * 2}
            mask={`url(#${strokeMaskId})`}
            href={`#${strokePathId}`}
          />
        </>
      );
    }
    case StrokePosition.inner:
    default: {
      return (
        <>
          <defs>
            <path id={strokePathId} fillRule="evenodd" d={data} style={transitionStyle} />
            {!!maskData && <path id={maskPathId} fillRule="evenodd" d={maskData} />}
            <clipPath id={clipPathId}>
              <use href={`#${maskData ? maskPathId : strokePathId}`} />
            </clipPath>
          </defs>
          <use
            fillOpacity="0"
            fill="none"
            {...stroke}
            strokeWidth={(stroke.strokeWidth || 0) * 2}
            clipPath={`url(#${clipPathId})`}
            href={`#${strokePathId}`}
          />
        </>
      );
    }
  }
};
