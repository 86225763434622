import { unstable_batchedUpdates } from 'react-dom';
import Doc from '@editor/document';
import CoreGroup from '@editor/corePartial/group';
import { SessionInfo } from '@fbs/rp/models/io';
import { ArtboardPatches, ComponentOperations, Ops } from '@fbs/rp/utils/patch';
import { mergePatches, coverPatches, getEmptyArtboardPatchesOfArtboardSelf } from '@/helpers/patchHelper';
import { IComponentData } from '@fbs/rp/models/component';
import { BaseCommand } from '../commands';
import { UIContainerComponent } from '../comps';
import { IUndoRedoStackPatchTypeElement } from './base';
import { CContentPanelV2 } from '../../libs/constants';

/**
 * 整个页面文档操作部分
 */
export default abstract class CoreDoc extends CoreGroup {
  protected constructor(doc: Doc, appID: string, nodeID: string, session: SessionInfo) {
    super(doc, appID, nodeID, session);
  }

  /**
   * 是否可以撤销
   * @returns {boolean}
   */
  get canUndo(): boolean {
    return this.undoStack.length > 0;
  }

  /**
   * 是否可以恢复
   * @returns {boolean}
   */
  get canRedo(): boolean {
    return this.redoStack.length > 0;
  }

  //修正patch,检查操作对象是否是新的内容画板，如果是，需要修改patch添加关联画板
  checkoutPatchIsContentPanelv2(undoStackElement: IUndoRedoStackPatchTypeElement): IUndoRedoStackPatchTypeElement {
    const { patches, activeContainer } = undoStackElement;
    Object.keys(patches).forEach((artboardId: string) => {
      const patch = patches[artboardId] as ArtboardPatches;
      //do存在remove-children，对应的undo肯定存在add-children
      Object.keys(patch.undo).forEach((componentID: string) => {
        ((patch.undo[componentID] as unknown) as ComponentOperations).forEach(({ op, value }: any) => {
          if (op !== 'add-children' || !value) {
            return false;
          }
          value.forEach((comp: IComponentData, index: number) => {
            if (comp.type == CContentPanelV2) {
              //删除关联画板，和替换数据
              const compData = this.doc.getComponentByID(comp._id);
              if (!compData || !compData.value?.length) {
                return;
              }
              //现有数据替换undo数据
              value[index] = compData;
              //删除关联画板
              const relationPatch = compData.value.reduce(
                (prevPatches: ArtboardPatches, artboardID: string) => {
                  return mergePatches(prevPatches, {
                    do: {
                      self: [Ops.remove(artboardID)],
                    },
                    undo: {
                      self: [Ops.add(artboardID, '')],
                    },
                  });
                },
                { do: {}, undo: {} },
              );
              //覆盖
              patches['ROOT'] = coverPatches(patches['ROOT'] || getEmptyArtboardPatchesOfArtboardSelf(), relationPatch);
            }
          });
        });
      });
    });
    return {
      patches,
      activeContainer,
    };
  }

  /**
   * undo操作
   * @returns {boolean}
   * FIXME: undo/redo 之后，应该把影响的组件选中，参看 XD 的策略，目前我们没有进入与退出组的undo步骤，要保持选中的话，需要先解决这个问题
   */
  undo(): boolean {
    if (!this.canUndo) {
      return false;
    }
    let undoStackElement = this.undoStack.pop();
    if (!undoStackElement) {
      return false;
    }

    if (undoStackElement instanceof BaseCommand) {
      this.undoCommand(undoStackElement);
    } else {
      undoStackElement = this.checkoutPatchIsContentPanelv2(undoStackElement);
      this.undoPatches(undoStackElement);
    }

    this.invertListener && this.invertListener();

    return true;
  }

  /**
   * 原来的 undo 方式
   * @param patches
   */
  private undoPatches({ patches, activeContainer }: IUndoRedoStackPatchTypeElement) {
    unstable_batchedUpdates(() => {
      this.applyOperations(patches, this.redoStack);
      this.refreshSelectedComponents();
      activeContainer = this.getFreshActiveContainerToSet(activeContainer);
      this.setActiveContainer(activeContainer);
    });
  }

  /**
   * 新的 undo 方式，后续会逐渐迁移到这种方式
   * @param command
   */
  private undoCommand(command: BaseCommand) {
    this.batchSyncNoticeUpdate(() => {
      command.undo();
    });
  }

  /**
   * 恢复操作
   * @returns {boolean}
   * FIXME: undo/redo 之后，应该把影响的组件选中，参看 XD 的策略
   */
  redo(): boolean {
    if (!this.canRedo) {
      return false;
    }
    const redoStackElement = this.redoStack.pop();
    if (!redoStackElement) {
      return false;
    }
    if (redoStackElement instanceof BaseCommand) {
      this.redoCommand(redoStackElement);
    } else {
      this.redoPatches(redoStackElement);
    }

    this.invertListener && this.invertListener();
    return true;
  }

  /**
   * 原来的 redo 方式
   * @param patches
   */
  private redoPatches({ patches, activeContainer }: IUndoRedoStackPatchTypeElement) {
    this.applyOperations(patches, this.undoStack);
    this.refreshSelectedComponents();
    const freshActiveContainer = this.getFreshActiveContainerToSet(activeContainer);
    this.setActiveContainer(freshActiveContainer);
  }

  /**
   * 新的 redo 方式，后续会逐渐迁移到这种方式
   * @param command
   */
  private redoCommand(command: BaseCommand) {
    this.batchSyncNoticeUpdate(() => {
      command.redo();
    });
  }

  /**
   * 获取新鲜的UIComponent对象，场景：增加画板的 redo，画板ID没变，但是UIFragment对象会重新构建，
   * 所以要通过ID重新获取UIComponent对象
   * @param oldActiveContainer
   */
  private getFreshActiveContainerToSet(oldActiveContainer: UIContainerComponent) {
    let result: UIContainerComponent;

    if (oldActiveContainer.isArtboard) {
      result = this.doc.getArtboardByID(oldActiveContainer.realID)!;
    } else {
      result = this.doc.getComponentsByFilter(
        (comp) => comp.realID === oldActiveContainer.realID,
      )[0] as UIContainerComponent;
    }

    return result;
  }

  invertListener?: Function;
}
