import { IComponentData } from '@/fbs/rp/models/component';
import { IPosition, IBounds } from '@/fbs/common/models/common';

import { isIntersect } from '@/utils/boundsUtils';

import { ArtboardPositionCalculator } from './ArtboardPositionCalculator';

export class SecondQuadrantArtboardPositionCalculator extends ArtboardPositionCalculator {
  /**
   * 获取处于当前象限区域的所有画板
   */
  getArtboardsInTheArea(): IComponentData[] {
    const { x: originPointX, y: originPointY } = this.originPointPosition;
    return this.artboards.filter((fragment) => {
      return fragment.position.x < originPointX && fragment.position.y < originPointY;
    });
  }

  /**
   * 获取处于当前象限区域y轴方向的边缘的画板
   * @param artboards
   */
  getArtboardInTheAreaEdge(artboards: IComponentData[]): IComponentData {
    let result: IComponentData = artboards[0];
    artboards.forEach((artboard) => {
      const top = artboard.position.y;
      if (top <= result.position.y) {
        result = artboard;
      }
    });
    return result;
  }

  /**
   * 根据处于当前象限区域y轴方向的边缘的画板的位置，来调整新画板位置到边缘画板左侧。
   * @param result
   * @param artboardInTheAreaEdge
   */
  doAdjustResultAccordingToArtboardInTheAreaEdge(result: IPosition, artboardInTheAreaEdge: IComponentData) {
    result.x = artboardInTheAreaEdge.position.x - this.artboardData.size.width - 30;
    result.y = artboardInTheAreaEdge.position.y;
  }

  /**
   * 如果新画板位置所占空间与被比较的画板所占空间重叠，则调整新画板位置到被比较画板右侧。
   * @param result
   * @param boundsOfResultPosition
   * @param boundsToCompare
   * @returns 是否调整了 result
   */
  doAdjustResultIfIntersect(result: IPosition, boundsOfResultPosition: IBounds, boundsToCompare: IBounds): boolean {
    if (isIntersect(boundsOfResultPosition, boundsToCompare)) {
      result.x = boundsToCompare.left - this.artboardData.size.width - 30;
      return true;
    }
    return false;
  }

  /**
   * 如果新画板位置超过阈值，则调整新画板位置到区域边缘的画板下方，贴近y轴的位置。
   * @param result
   * @param artboardInTheAreaEdge
   * @returns 是否调整了 result
   */
  doAdjustResultIfExceedThresholdAccordingToArtboardInTheAreaEdge(
    result: IPosition,
    artboardInTheAreaEdge: IComponentData,
  ): boolean {
    const { x: originPointX } = this.originPointPosition;
    const threshold = this.positionCalcThreshold;
    if (Math.abs(result.x - originPointX) - this.artboardData.size.width > threshold) {
      result.x = originPointX - this.artboardData.size.width - 30;
      result.y = artboardInTheAreaEdge.position.y - artboardInTheAreaEdge.size.height - 60;
      return true;
    }
    return false;
  }
}
