import { isUndefined } from 'lodash';
import { IAppF, IPrototypeInfo, ICreateAppInfo, IApp } from '@fbs/idoc/models/app';

import { get, put } from './helper';

/**
 * 查询协作项目
 * @param {string} teamID 团队ID
 * @param {string} [type] 外链时区分axure
 * @param {string} [classify]  classify 的参数 , 不传查所有， 传空字符串表示所有设计稿， mockplus 表示摹客设计稿
 * @return {{ apps: IAppF[] }}
 */
export async function getIDocAppSet(teamID: string, type?: string, classify?: string) {
  return get<{ apps: IAppF[] }>(
    `/app/allAppAndAppSet/${teamID}?needArchivedApp=false&source=all&needAppSet=true${
      isUndefined(type) ? '' : `&type=${type}`
    }${isUndefined(classify) ? '' : '&classify=' + classify}&rnd=${new Date().getTime()}`,
  );
}

export async function getPrototypeApps(appID: string) {
  return get<IPrototypeInfo[]>(`/app/prototype/all/${appID}`);
}

export interface ICreatePrototypePayload {
  name: string;
  shareID: string;
}

/**
 * 新增的创建新原型稿的接口
 * @param idocAppID
 */
export async function createNewPrototypeApp(idocAppID: string, payload: ICreatePrototypePayload) {
  return put<IApp>(`/app/prototype/all/rp/${idocAppID}`, payload);
}

export async function createNewIDocApp(app: Partial<ICreateAppInfo>) {
  const idocApp = {
    ...app,
    defaultModule: 'prototype',
    usedModule: {
      design: false,
      prototypeDesign: true,
      rp: false,
    },
  };
  return put<IAppF>(`/iDocApp`, idocApp);
}

export async function publish(appID: string, iDocID: string, nodeIDs?: string[], prototypeID?: string) {
  return put<{ taskID: string }>(`/publish/${appID}/${iDocID}${prototypeID ? `/${prototypeID}` : ''}`, { nodeIDs });
}

/**
 * 发布进度查询
 * @param taskID
 */
export async function publishDelayInfo<T>(taskID: string): Promise<T> {
  return get<T>(`/publish/delayInfo/${taskID}`);
}

export interface IPrototype {
  _id: string;
  name: string;
  previewURL: string;
  pinyinName: string;
  isGroup: boolean;
  children: IPrototype[];
}

/**
 * 获取指定原型下页面信息
 */
export async function getPrototype(appID: string, prototypeID: string): Promise<IPrototype[]> {
  return get<IPrototype[]>(`/app/redirect/prototype/${appID}/${prototypeID}`);
}

/**
 * 查看CC原型稿接口防卫
 * @param appID CC项目ID
 */
export async function getCCPrototypeDefense(appID: string) {
  return get<boolean>(`/iDocApp/${appID}/exist`);
}
