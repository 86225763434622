import * as React from 'react';

import { ITableColumn, TableSelection } from '../../../models';

interface ITableColProps {
  columns: ITableColumn[];
  rowSelectionType: TableSelection;
}

const TableCol: React.FC<ITableColProps> = ({ columns, rowSelectionType }: ITableColProps) => {
  const renderSelection = () => {
    switch (rowSelectionType) {
      case TableSelection.Checkbox:
        return <col key="checkbox" width={33} style={{ minWidth: 33 }} />;
      case TableSelection.None:
        return null;
      default:
        break;
    }
  };
  return (
    <colgroup>
      {renderSelection()}
      {columns.map((col) => (
        <col key={col.id} width={col.width} style={{ minWidth: col.minWidth }} />
      ))}
    </colgroup>
  );
};

export default TableCol;
