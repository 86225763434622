import * as React from 'react';

import { IPoint } from '../../../models';

import './index.scss';

interface IPopContentProps {
  content: React.ReactNode;
  minWidth?: string | number;
  pos: IPoint;
}

const PopContent: React.FC<IPopContentProps> = (props: IPopContentProps) => {
  const { content, minWidth, pos } = props;
  return (
    <div className="dsm-c-popover-fix-content" style={{ minWidth, left: pos.x, top: pos.y }}>
      <div className="dsm-c-popover-fix-content-arrow" />
      {content}
      <div className="dsm-c-popover-fix-content-mask" />
    </div>
  );
};

export default PopContent;
