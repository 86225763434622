import { FillType } from '@fbs/rp/models/properties/fill';

import i18n from '@i18n';

import { SpriteThumb } from '@consts/spriteIcons';

import { makeCommonComponent, getDefaultShadow } from '../../helper';
import { IComponentItem } from '../../types';
import { CKeyboard } from '../../constants';

function getValueNames(key: string): { value: string; name: string }[] | undefined {
  if (key === 'keyboard') {
    return [
      { value: 'letter', name: i18n('property.component.keyboard.letter') },
      { value: 'numeric', name: i18n('property.component.keyboard.numeric') },
      { value: 'symbol', name: i18n('property.component.keyboard.symbol') },
    ];
  }
  return undefined;
}

export const KeyboardConfig: IComponentItem = {
  type: CKeyboard,
  thumb: {
    spriteIconClass: SpriteThumb.Keyboard.className,
    dragPosition: SpriteThumb.Keyboard.position,
  },
  name: i18n('resource.components.keyboard'),
  property: {
    getValueNames,
  },
  initialization: (appType, comp, container) => {
    if (container?.isArtboard && (appType === 'phone' || appType === 'pad')) {
      const { height } = comp.size;
      const { height: parentHeight, width: parentWidth } = container.size;
      comp.position.y = parentHeight - height;
      comp.position.x = 0;
      comp.size.width = parentWidth;
    }
  },
  getDefaultData() {
    return {
      properties: {
        keyboard: {
          prop: 'enum',
          name: i18n('property.propertyNames.keyboard'),
          value: 'letter',
          disabled: false,
        },
        fill: {
          name: i18n('property.propertyNames.bgcolor'),
          type: FillType.solid,
          color: { r: 221, g: 221, b: 221, a: 1 },
          disabled: true,
        },
        buttonColor: {
          prop: 'fill',
          name: i18n('property.propertyNames.buttonColor'),
          type: FillType.solid,
          color: { r: 255, g: 255, b: 255, a: 1 },
          disabled: false,
        },
        featureButtonColor: {
          prop: 'fill',
          name: i18n('property.propertyNames.featureButtonColor'),
          type: FillType.solid,
          color: { r: 250, g: 250, b: 250, a: 1 },
          disabled: false,
        },
        forgetColor: {
          prop: 'color',
          name: i18n('property.propertyNames.fontColor'),
          value: { r: 0, g: 0, b: 0, a: 1 },
          disabled: false,
        },
        shadow: getDefaultShadow(),
      },
    };
  },
};

export const initialKeyboardSize = { width: 375, height: 200 };

export function makeKeyboard(id: string) {
  return makeCommonComponent(id, CKeyboard, {
    ...KeyboardConfig.getDefaultData?.(),
    size: initialKeyboardSize,
  });
}
