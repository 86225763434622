import { memoize } from 'lodash';

import { PolygonData } from '@utils/graphicsUtils';
import { depthClone } from '@utils/globalUtils';

import { mergeRadiusForPathValue } from '@/helpers/pathHelper';
import {
  IPathValue,
  ILineValue,
  ITemplatePathPoint,
  ITemplatePoint,
  IPathPoint,
  IComponentValue,
} from '@fbs/rp/models/value';
import { ArtboardPatches } from '@/fbs/rp/utils/patch';
import { CPath, CRect, CEllipse, CPolygon, CLine } from '@libs/constants';

import { UITextComponent, IFindResult } from '.';

export default class UIShapeComponent extends UITextComponent {
  private getTemplateValue() {
    const { width, height } = this.size;
    const value = super.value as IPathValue;
    const templateData = value.templateData;
    if (!templateData) {
      return super.value;
    }
    const shouldEvalKeys = ['handleIn', 'handleOut', 'point'];
    const pathData = templateData.map((item) => {
      const res = depthClone(item);
      shouldEvalKeys.forEach((key) => {
        const evalKey = key as keyof Pick<ITemplatePathPoint, 'handleIn' | 'handleOut' | 'point'>;
        const itemData = item[evalKey] as ITemplatePoint;
        Object.keys(itemData).forEach((_key) => {
          const pointKey = _key as keyof ITemplatePoint;
          const pointValue = itemData[pointKey];
          if (typeof pointValue === 'string') {
            const replaceStr = pointValue.replace(/sw/g, `${width}`).replace(/sh/g, `${height}`);
            res[evalKey][pointKey] = eval(replaceStr);
          }
        });
      });
      return res;
    });
    return {
      ...value,
      data: pathData as IPathPoint[],
    };
  }

  get isTemplateShape() {
    const value = super.value as IPathValue;
    return !!value?.isTemplateShape;
  }

  getMemoizeTemplateValue = memoize(
    this.getTemplateValue,
    () => `${this.size.width}-${this.size.height}-${this.version}`,
  );

  get value(): IComponentValue | undefined {
    if (this.isTemplateShape) {
      return this.getMemoizeTemplateValue();
    }
    return super.value;
  }

  private getPath() {
    return this.value as IPathValue;
  }

  private getLine() {
    const { startPoint, endPoint } = this.value as ILineValue;
    return {
      closed: false,
      data: [
        {
          point: { ...startPoint },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
        },
        {
          point: { ...endPoint },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
        },
      ],
    };
  }

  private getRect(): IPathValue {
    const { radius } = this.properties;
    const { width, height } = this.size;
    const topLeft = radius?.disabled ? 0 : radius?.topLeft || 0;
    const topRight = radius?.disabled ? 0 : radius?.topRight || 0;
    const bottomLeft = radius?.disabled ? 0 : radius?.bottomLeft || 0;
    const bottomRight = radius?.disabled ? 0 : radius?.bottomRight || 0;
    const data = {
      closed: true,
      data: [
        {
          point: { x: 0, y: 0 },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
          radius: topLeft,
        },
        {
          point: { x: width, y: 0 },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
          radius: topRight,
        },
        {
          point: { x: width, y: height },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
          radius: bottomRight,
        },
        {
          point: { x: 0, y: height },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
          radius: bottomLeft,
        },
      ],
    };
    // 返回处理圆角后的数据
    return mergeRadiusForPathValue(data);
  }

  private getEllipse() {
    //tan(Math.PI/8)*4/3*R
    const { width, height } = this.size;
    const center = width / 2;
    const middle = height / 2;
    const ratio = (Math.tan(Math.PI / 8) * 4) / 3;
    const rx = ratio * center;
    const ry = ratio * middle;
    return {
      closed: true,
      data: [
        {
          point: { x: center, y: 0 },
          handleIn: { x: -rx, y: 0 },
          handleOut: { x: rx, y: 0 },
        },
        {
          point: { x: width, y: middle },
          handleIn: { x: 0, y: -ry },
          handleOut: { x: 0, y: ry },
        },
        {
          point: { x: center, y: height },
          handleIn: { x: rx, y: 0 },
          handleOut: { x: -rx, y: 0 },
        },
        {
          point: { x: 0, y: middle },
          handleIn: { x: 0, y: ry },
          handleOut: { x: 0, y: -ry },
        },
      ],
    };
  }

  private getPolygon() {
    const { polygon } = this.properties;
    const slideCount = polygon?.sideCount ?? 3;
    return {
      closed: true,
      data: new PolygonData(this.size, slideCount).toPolygonPoints(0).map((point) => ({
        point,
        handleIn: { x: 0, y: 0 },
        handleOut: { x: 0, y: 0 },
      })),
    };
  }

  get path(): IPathValue | null {
    switch (this.type) {
      case CPath:
        return this.getPath();
      case CRect:
        return this.getRect();
      case CEllipse:
        return this.getEllipse();
      case CPolygon:
        return this.getPolygon();
      case CLine:
        return this.getLine();
      default:
        return null;
    }
  }

  get allowPathEditor(): boolean {
    return true;
  }

  public matchText(text: string, pageID: string): IFindResult[] {
    const currentRichTextValue = this.text;
    return this.doMatchRichText(currentRichTextValue, text, pageID);
  }

  public replaceText(matchInfo: IFindResult['match'], text: string, newText: string): ArtboardPatches {
    const currentRichTextValue = this.text;
    const newValue = this.doReplaceRichText(currentRichTextValue, matchInfo, text, newText);
    const patches = this.setText(newValue);
    window.debug && console.log('replaceText patches: ', patches);
    return patches;
  }

  public replaceAllText(matchInfo: IFindResult['match'], text: string, newText: string): ArtboardPatches {
    const currentRichTextValue = this.text;
    const newValue = this.doReplaceAllRichText(currentRichTextValue, matchInfo, text, newText);
    const patches = this.setText(newValue);
    window.debug && console.log('replaceText patches: ', patches);
    return patches;
  }
}
