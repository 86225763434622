import { IMemberJoinAppF } from '@fbs/idoc/models/memberJoinApp';
import { IAllMessageInfo } from '@fbs/idoc/models/message';
import { get, patch } from './helper';

/**
 * 获取项目消息列表
 * @param page 从 1 开始
 * @param type
 */
export function getMessageList(page: number, type?: string) {
  return get<IAllMessageInfo>(`/message/list?page=${page}&type=${type}`);
}

/**
 * 获取项目加请列表
 * @param page 从 0 开始
 */
export function getMemberJoinAppList(page: number) {
  return get<IMemberJoinAppF[]>(`/memberJoinApp/list?page=${page}`);
}

/**
 * 获取与我相关消息列表
 * @param page 从 1 开始
 */
export function getSenderMessageList(page: number) {
  return get<IAllMessageInfo>(`/message/list/sender?page=${page}`);
}

/**
 * 标记消息为已读
 * @param msgID
 */
export function changeReadFlagMessage(msgID: number) {
  return patch(`/message/changeReadFlag/${msgID}`, {});
}

/**
 * 同意项目申请
 * @param teamID
 * @param applyID
 */
export function agreeJoinAppApply(teamID: string, applyID: string, role: string) {
  return patch(`/memberJoinApp/agree/${teamID}/${applyID}`, { role });
}

/**
 * 拒绝项目申请
 * @param teamID
 * @param applyID
 */
export function refuseJoinAppApply(teamID: string, applyID: string) {
  return patch(`/memberJoinApp/refuse/${teamID}/${applyID}`, {});
}

/**
 * 消息全部已读
 */
export function readAllMessage() {
  return patch(`/message/changeAllRead`, {});
}

/**
 * 与我相关消息一件已读
 */
export function readAllMessageAboutMe() {
  return patch(`/message/changeAllRead/sender`, {});
}

/**
 * 获取未读数据条数
 */
export async function loadUnreadNum() {
  return get(`/message/unReadCount`);
}

/**
 * 获取未读消息条数
 * @param teamID
 */
export async function loadMessageUnreadCount(teamID: string) {
  return get<{
    appUnReadCount: number; // 普通未读消息
    appUnReadCountSender: number; // 关于我的未读消息
    memberJoinAppNum: number; // 项目申请数量
    systemUnReadCount: number;
    teamUnReadCount: number;
  }>(`/team/message/${teamID}`);
}
