import * as React from 'react';
import classnames from 'classnames';

import EditorContext from '@contexts/editor';

import Node from './Node';
import { INode, DropMode, ContextCommand } from './model';
import {
  getAllParentNodeIDs,
  isNodeOrChildrenMatch,
  findParentNodeByID,
  flattenChildren,
  getCheckboxState,
  isNodeMatch,
  isSiblings,
  getGroupPageNum,
} from './utils';
import { isCtrlKey, isMacOS } from '../utils';
import { NodeMoveDirectionType } from './model';
import i18n from '../../dsm2/i18n';
import { INodeWithChildren } from '@/fbs/rp/models/node';

const noSearchResult = require('./img/no_search_result.png');
const noSearchResultBlack = require('./img/no_search_result_black.png');
import './index.scss';

interface ITreeProps {
  // 主题
  theme?: 'white' | 'dark' | 'spec-dark';
  maxWidth?: number;

  data: INode[];
  nodes: INodeWithChildren[];

  searchKey?: string;
  searchKeyHighlight?: boolean;

  cuttedKeys: string[];

  // 显示自定义图标
  showCustomIcon?: boolean;

  // leaf 是否可以有子
  canLeafHasChildren: boolean;

  // 是否正在查找交互目标，当正在查找时，页面节点在鼠标 hover 时显示边框
  isFindingInteractionTarget?: boolean;

  // 默认展开的层级 -1 表示所有, 0 表示不做任何自动展开处理
  defaultExpandLevel?: number;

  // 是否显示 checkbox
  showCheckBox?: boolean;
  highlightChecked?: boolean;
  // 当父级被选中时，禁止子级的取消选中
  disableUncheckChildrenIfParentIsChildren?: boolean;
  // 选中的 keys
  checkedKeys?: string[];
  onChecked?(checkedKeys: string[]): void;

  // 是否支持多选
  multiSelectedable: boolean;
  // 是否支持跨级多选
  multiLevelSeletable?: boolean;
  selectedKeys: string[];
  onNodeMove(direction: NodeMoveDirectionType): void;
  onSelected(selectedKeys: string[]): void;

  // 是否可以拖拽
  draggable?: boolean;
  //是否支持平级拖动
  canPeersDrag?: boolean;
  // 是否只支持平级拖动
  justCanPeersDrag?: boolean;
  // 自定义拖拽源类型
  customDragType?: symbol[];
  // 仅支持自定义拖拽类型，屏蔽默认的treeNode类型间的拖动
  justAcceptCustomDragType?: boolean;
  isNotShowCommentsIcon?: boolean;
  // 拖拽结束
  onDragEnd(sourceNode: string, targetNode: string, mode: DropMode): void;

  // 展开的节点
  expandedKeys: string[];
  onExpand(
    expandedKeys: string[],
    info?: {
      expanded: boolean;
      nodeID: string;
    },
  ): void;

  // 选中当前节点，并且展开右键菜单
  onContextMenu(info: { nodeID: string; event: React.MouseEvent }): void;

  onNodeMouseDown?(e: React.MouseEvent, node: INode): void;
  onNodeMouseUp?(e: React.MouseEvent, node: INode): void;
  onNodeMouseEnter?(e: React.MouseEvent, node: INode): void;
  onNodeMouseLeave?(e: React.MouseEvent, node: INode): void;

  // 执行命令
  onExecuteShortcutCommand?(command: ContextCommand, node: INode): void;
  onClickSuffixIcon?(e: React.MouseEvent, id: string): void;
  onClickOptionsIcon?(e: React.MouseEvent, id: string): void;
  onDoubleClick?(id: string): void;
  getPreSearchKey?(): void;

  // 是否支持重命名
  renamable?: boolean;
  //重命名
  onRename?(name: string, node: INode): void;
  renameID?: string;
  renameMaxLength?: number;

  onClickControlIcon?(e: React.MouseEvent, node: INode): void;
  // 是否显示分组下面子数量
  showGroupNum?: boolean;

  //交互高亮显示节点
  interactionHighlightKey?: string[];

  // hover仅显示背景色
  hoverShowBg?: boolean;

  tip?(e: React.MouseEvent, text?: string): void;
  userPropsMatch: boolean;

  // 隐藏默认的title
  hideDefaultTitle?: boolean;

  propsMatchFun?(node: INode, searchKey?: string): void;

  // 当前item被focus时，才可以被重命名
  canEditWithFocus?: boolean;

  // 是否在进行搜索输入
  isSearchInputting?: boolean;

  needDebounce?: boolean;
}

interface ITreeState {
  isSelectedMultiLevel: boolean;
  renameID?: string;
  preNodeId: string;
  preNodeType: string;
}

/**
 * InnerTree为dsm2中Tree组件，copy过来修改代码避免影响其他依赖于dsm2中Tree组件功能
 */
class Tree extends React.Component<ITreeProps, ITreeState> {
  static defaultProps = {
    theme: 'white',
    defaultExpandLevel: -1,

    selected: false,
    handleExpand: false,
    draggable: false,
    canLeafHasChildren: true,
    multiLevelSeletable: false,
    canPeersDrag: true,
    showGroupNum: false,
    hoverShowBg: false,
    justCanPeersDrag: false,
    justAcceptCustomDragType: false,
  };
  static contextType = EditorContext;
  // @ts-ignore
  context: React.ContextType<typeof EditorContext>;

  renameTimeout: number = 0;

  treeRef: React.RefObject<HTMLDivElement>;

  constructor(props: ITreeProps) {
    super(props);
    this.treeRef = React.createRef();
    this.state = {
      isSelectedMultiLevel: false,
      renameID: '',
      preNodeId: '',
      preNodeType: '',
    };
  }

  componentDidMount() {
    // 不需要展开任何层级
    if (this.props.defaultExpandLevel === 0) {
      return;
    }
    const nodes = getAllParentNodeIDs(this.props.data, this.props.defaultExpandLevel || -1);
    if (nodes.length > 0) {
      this.props.onExpand(nodes);
    }
  }

  componentDidUpdate(preProps: ITreeProps) {
    // 外部热键重命名
    const { renameID } = this.props;
    if (renameID !== preProps.renameID) {
      this.setState({
        renameID,
      });
    }
  }

  static getDerivedStateFromProps(props: ITreeProps) {
    const selected = props.selectedKeys;
    const parents = new Set<string>();
    selected.forEach((keyID) => {
      const parentInfo = findParentNodeByID(props.data, keyID);
      if (parentInfo.parent) {
        parents.add(parentInfo.parent.id);
      } else {
        parents.add('ROOT');
      }
    });
    return {
      isSelectedMultiLevel: parents.size > 1,
    };
  }

  // 获取所有选中节点以及子节点
  private static getAllSelectedTreeNodes(data: INode[], ids: string[]): string[] {
    const res: string[] = [];
    data.forEach((node) => {
      if (ids.includes(node.id)) {
        res.push(node.id);
      }
      if (node.children && node.children.length > 0) {
        const subRes = this.getAllSelectedTreeNodes(node.children, ids);
        res.push(...subRes);
      }
    });
    return res;
  }

  // 返回子节点
  private getChildrenNodes(data: INode[]): string[] {
    const res: string[] = [];
    data.forEach((node) => {
      res.push(node.id);
      if (node.children && node.children.length > 0) {
        const subRes = this.getChildrenNodes(node.children);
        res.push(...subRes);
      }
    });
    return res;
  }

  // 返回有子节点的节点id
  private getHasChildrenNodes(data: INode[]): string[] {
    const res: string[] = [];
    data.forEach((node) => {
      if (node.children && node.children.length > 0) {
        res.push(node.id);
        const subRes = this.getHasChildrenNodes(node.children);
        res.push(...subRes);
      }
    });
    return res;
  }

  // 获取该节点下的所有子节点
  private getChildrenNodesById(data: INode[], id: string): string[] {
    const res: string[] = [];
    data.forEach((node) => {
      if (id === node.id) {
        if (node.children && node.children.length > 0) {
          const subRes = this.getChildrenNodes(node.children);
          if (subRes.length > 0) {
            res.push(...subRes);
          }
        }
      } else {
        if (node.children && node.children.length > 0) {
          const subRes = this.getChildrenNodesById(node.children, id);
          if (subRes.length > 0) {
            res.push(...subRes);
          }
        }
      }
    });
    return res;
  }

  onExpand = (id: string, expand: boolean, expandAll: boolean) => {
    // ctrl+点击 一键展开或收起所有
    let expandedKeys = [...this.props.expandedKeys];
    let currentKey = id;
    if (expand) {
      if (expandAll) {
        let hasChildrenNodeIDArr: string[] = [];
        const toRenderNodes = this.props.data.filter((item) => {
          return !this.props.searchKey || isNodeOrChildrenMatch(item, this.props.searchKey);
        });
        if (toRenderNodes.length > 0) {
          hasChildrenNodeIDArr = this.getHasChildrenNodes(toRenderNodes);
        }
        expandedKeys = [...hasChildrenNodeIDArr];
      } else {
        expandedKeys.push(id);
      }
    } else {
      if (expandAll) {
        expandedKeys = [];
      } else {
        expandedKeys.splice(expandedKeys.indexOf(id), 1);
      }
    }
    if (this.props.selectedKeys.length) {
      currentKey = this.props.selectedKeys[0];
    }
    this.props.onExpand(expandedKeys, {
      nodeID: currentKey,
      expanded: expand,
    });
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>, node: INode) => {
    const { renamable, onExecuteShortcutCommand, onNodeMove } = this.props;
    if (!onExecuteShortcutCommand) {
      return;
    }
    const isCtrl = isCtrlKey(e);
    // 对快键键的阻止冒泡移到到当前节点需要响应的快捷键
    // e.stopPropagation();
    if (!isCtrl) {
      switch (e.key) {
        case 'Backspace':
        case 'Delete':
          e.stopPropagation();
          e.preventDefault();
          onExecuteShortcutCommand(ContextCommand.Delete, node);
          break;
        case 'F2':
          if (!isMacOS && renamable) {
            e.stopPropagation();
            onExecuteShortcutCommand(ContextCommand.Rename, node);
            this.setState({ renameID: node.id });
          }
          e.preventDefault();
          break;
        case 'Enter':
          if (isMacOS && renamable) {
            e.stopPropagation();
            onExecuteShortcutCommand(ContextCommand.Rename, node);
            this.setState({ renameID: node.id });
          }
          e.preventDefault();
          break;
      }
    } else {
      switch (e.key) {
        case 'f':
          onExecuteShortcutCommand(ContextCommand.Find, node);
          this.props.getPreSearchKey && this.props.getPreSearchKey();
          e.stopPropagation();
          e.preventDefault();
          break;
        case 'c':
          onExecuteShortcutCommand(ContextCommand.Copy, node);
          e.stopPropagation();
          e.preventDefault();
          break;
        case 'x':
          onExecuteShortcutCommand(ContextCommand.Cut, node);
          e.stopPropagation();
          e.preventDefault();
          break;
        case 'v':
          onExecuteShortcutCommand(ContextCommand.Paste, node);
          e.stopPropagation();
          e.preventDefault();
          break;
        case 'd':
          onExecuteShortcutCommand(ContextCommand.Clone, node);
          e.stopPropagation();
          e.preventDefault();
          break;
        case 'Enter':
          e.stopPropagation();
          e.preventDefault();
          if (e.shiftKey) {
            onExecuteShortcutCommand(ContextCommand.AddFolder, node);
          } else {
            onExecuteShortcutCommand(ContextCommand.AddPage, node);
          }
          break;
        case 'ArrowUp': {
          onNodeMove(NodeMoveDirectionType.ArrowUp);
          e.stopPropagation();
          e.preventDefault();
          break;
        }
        case 'ArrowRight': {
          onNodeMove(NodeMoveDirectionType.ArrowRight);
          e.stopPropagation();
          e.preventDefault();
          break;
        }
        case 'ArrowDown': {
          onNodeMove(NodeMoveDirectionType.ArrowDown);
          e.stopPropagation();
          e.preventDefault();
          break;
        }
        case 'ArrowLeft': {
          onNodeMove(NodeMoveDirectionType.ArrowLeft);
          e.stopPropagation();
          e.preventDefault();
          break;
        }
      }
    }
  };

  handleNodeClass = (item: INode) => {
    const type = item.data.type;
    const selectId = this.props.selectedKeys[0];
    if (type === 'folder') {
      if (selectId === item.id || this.state.preNodeType === type) {
        this.setState((pre) => {
          pre.preNodeId;
        });
      } else {
        this.setState({ preNodeId: selectId });
      }
      this.setState({ preNodeType: 'folder' });
    } else {
      this.setState({ preNodeId: '' });
      this.setState({ preNodeType: '' });
    }
  };

  renderNodes(
    nodes: INode[],
    parsentIsChecked: boolean,
    offset: number = 16,
    parentNode?: INode,
    isShowALLChildren?: boolean,
  ): React.ReactNode {
    const {
      showCustomIcon,
      customDragType,
      justAcceptCustomDragType,
      userPropsMatch,
      tip,
      searchKey,
      propsMatchFun,
      expandedKeys,
      selectedKeys,
      checkedKeys,
      isNotShowCommentsIcon,
      interactionHighlightKey,
      draggable,
      maxWidth,
      searchKeyHighlight,
      needDebounce,
    } = this.props;
    //是否展示全部子节点
    if (!nodes || nodes.length === 0) {
      return null;
    }
    //是否存在匹配搜索的父节点，并且显示全部匹配搜索的子节点
    const matchIsShowAllChildrenNode =
      isShowALLChildren /*递归过程节约计算*/ || (parentNode && !!searchKey && isNodeMatch(parentNode, searchKey));
    //如果存在matchIsShowAllChildrenNode那么该节点的全部下级节点都必须要显示了,isShowALLChildrenNode将向下递归传递
    const isShowALLChildrenFlag = isShowALLChildren || matchIsShowAllChildrenNode;

    let toRenderNodes = nodes;
    // 如果有userPropsMatch使用父的搜索
    if (!userPropsMatch) {
      toRenderNodes = nodes.filter((item) => {
        //这里有个很耗时的递归查找过程，先加一个isShowALLChildrenFlag，计算减枝
        return !searchKey || isNodeOrChildrenMatch(item, searchKey, isShowALLChildrenFlag);
      });
    } else {
      toRenderNodes = nodes.filter((item) => {
        return !searchKey || (propsMatchFun && propsMatchFun(item, searchKey));
      });
    }

    if (toRenderNodes.length === 0) {
      return null;
    }

    // 子节点是否被选中
    const isChildrenSlected = (item: INode): boolean => {
      return !!item.children?.find((t) => {
        return selectedKeys.includes(t.id) || isChildrenSlected(t);
      });
    };

    return (
      <ul>
        {toRenderNodes.map((item) => {
          const expanedItem = expandedKeys.includes(item.id);
          let selected = selectedKeys.includes(item.id);

          // 折叠时，判断是否存在子节点选中情况
          if (!expanedItem && !selected) {
            selected = isChildrenSlected(item);
          }

          const { checked, indeterminate, disabled: dis } = checkedKeys
            ? getCheckboxState(item, checkedKeys)
            : { checked: false, indeterminate: false, disabled: false };
          const inputWidth = this.treeRef.current?.clientWidth;
          const renameID = this.state.renameID;
          if (item.children && item.children.length > 0) {
            const leafArr = getGroupPageNum(item.children);
            item.groupNum = leafArr.length;
          } else {
            item.groupNum = 0;
          }
          const disabled = Object.prototype.hasOwnProperty.call(item, 'disabled') ? item.disabled : dis;
          //编辑模式下，其他页面禁止
          const isContentPanelEditor = this.context.coreEditor?.isContentPanelEditor;
          const nodeDisabled = isContentPanelEditor ? !selected : false;
          return (
            <Node
              key={item.id}
              theme={this.props.theme}
              data={item}
              offset={offset}
              selectNodesCount={selectedKeys.length}
              needFocusNodeId={selectedKeys[selectedKeys.length - 1]}
              isNotShowCommentsIcon={isNotShowCommentsIcon}
              isContentPanelEditor={isContentPanelEditor}
              showPreNodeheightLight={item.id === this.state.preNodeId}
              interactionHighlight={!!interactionHighlightKey && interactionHighlightKey.includes(item.id)}
              // FIXME Mxj 这个属性做什么用的呢？没有在props中补充声明？
              needDebounce={needDebounce}
              inputWidth={inputWidth || maxWidth || 200}
              isEdit={renameID === item.id}
              expanded={expanedItem}
              canPeersDrag={!!this.props.canPeersDrag}
              justCanPeersDrag={!!this.props.justCanPeersDrag}
              draggable={!renameID && !this.state.isSelectedMultiLevel && !!draggable}
              customDragType={customDragType || []}
              justAcceptCustomDragType={!!justAcceptCustomDragType}
              onExpand={(expandAll: boolean = false) => {
                clearTimeout(this.renameTimeout);
                this.onExpand(item.id, !expanedItem, expandAll);
              }}
              showCustomIcon={showCustomIcon}
              selected={selected}
              hoverShowBg={this.props.hoverShowBg}
              cutted={this.props.cuttedKeys.includes(item.id)}
              isSearchInputting={this.props.isSearchInputting}
              searchKey={this.props.searchKey}
              searchKeyHighlight={!!searchKeyHighlight}
              showCheckBox={this.props.showCheckBox || false}
              showGroupNum
              canLeafHasChildren={this.props.canLeafHasChildren}
              checked={checked}
              highlightChecked={this.props.highlightChecked || false}
              indeterminate={indeterminate}
              disabledCheckbox={
                disabled || (this.props.disableUncheckChildrenIfParentIsChildren ? parsentIsChecked : false)
              }
              nodeDisabled={nodeDisabled}
              hideDefaultTitle={!!this.props.hideDefaultTitle}
              onClickSuffixIcon={this.props.onClickSuffixIcon}
              onClickOptionsIcon={this.props.onClickOptionsIcon}
              onCheck={(checked) => {
                const checkedKeys = this.props.checkedKeys;
                if (!checkedKeys || !this.props.onChecked || !this.props.showCheckBox) {
                  return;
                }

                if (checked) {
                  if (item.isLeaf) {
                    checkedKeys.push(item.id);
                  } else {
                    if (item.children && item.children.length > 0) {
                      const allChildren = flattenChildren(item.children);
                      allChildren.forEach((c) => {
                        if (!checkedKeys.includes(c.id) && c.isLeaf) {
                          checkedKeys.push(c.id);
                        }
                      });
                    }
                  }
                } else {
                  if (checkedKeys.includes(item.id)) {
                    checkedKeys.splice(checkedKeys.indexOf(item.id), 1);
                  }

                  if (item.children && item.children.length > 0) {
                    const allChildren = flattenChildren(item.children);
                    allChildren.forEach((c) => {
                      if (checkedKeys.includes(c.id)) {
                        checkedKeys.splice(checkedKeys.indexOf(c.id), 1);
                      }
                    });
                  }
                }
                this.props.onChecked(checkedKeys);
              }}
              onKeyDown={(e) => {
                clearTimeout(this.renameTimeout);
                this.onKeyDown(e, item);
              }}
              onContextMenu={(e) => {
                clearTimeout(this.renameTimeout);
                this.props.onContextMenu({
                  nodeID: item.id,
                  event: e,
                });
              }}
              onDblClickNode={(id) => {
                clearTimeout(this.renameTimeout);
                if (this.props.onDoubleClick) {
                  this.props.onDoubleClick(id);
                } else {
                  this.onExpand(id, !expanedItem, false);
                }
              }}
              onClick={(e: React.MouseEvent) => {
                if (this.props.multiSelectedable && isCtrlKey(e) && this.props.selectedKeys.length > 0) {
                  const selectedKeys = [...this.props.selectedKeys];
                  // 当前节点已经被选中了，并且选中的节点多于 1 个，取消其选中
                  if (selected) {
                    if (selectedKeys.length > 1) {
                      selectedKeys.splice(selectedKeys.indexOf(item.id), 1);
                    } else {
                      return;
                    }
                  } else if (this.props.multiLevelSeletable || selectedKeys.length === 0) {
                    selectedKeys.push(item.id);
                  } else {
                    const allChildren = flattenChildren(this.props.data);
                    if (!isSiblings(allChildren, selectedKeys[0], item.id)) {
                      return;
                    }
                    selectedKeys.push(item.id);
                  }
                  this.props.onSelected(selectedKeys);
                  return;
                } else if (this.props.multiSelectedable && e.shiftKey && this.props.selectedKeys.length > 0) {
                  const firstOne = this.props.selectedKeys[0];
                  const allChildren = flattenChildren(this.props.data);
                  const firstOneIndex = allChildren.findIndex((node) => node.id === firstOne);
                  const currentIndex = allChildren.findIndex((node) => node.id === item.id);
                  const nodesBetweenThem = allChildren
                    .filter(
                      (node, idx) =>
                        idx >= Math.min(firstOneIndex, currentIndex) && idx <= Math.max(firstOneIndex, currentIndex),
                    )
                    .map((node) => node.id);

                  // 从下往上选，反序一下，保持 firstOne 始终是最开始被选中的那一个节点
                  if (currentIndex < firstOneIndex) {
                    nodesBetweenThem.reverse();
                  }
                  if (this.props.multiLevelSeletable) {
                    this.props.onSelected(nodesBetweenThem);
                    return;
                  }
                  const nodesToSelect = nodesBetweenThem.filter((nodeID) => isSiblings(allChildren, firstOne, nodeID));
                  this.props.onSelected(nodesToSelect);
                  return;
                }
                this.handleNodeClass(item);
                this.props.onSelected([item.id]);
              }}
              onDragBegin={() => {
                clearTimeout(this.renameTimeout);
              }}
              onDragEnd={this.props.onDragEnd}
              onRename={(name: string, node: INode) => {
                this.setState({ renameID: '' });
                if (this.props.onRename) {
                  this.props.onRename(name, node);
                }
              }}
              renameMaxLength={this.props.renameMaxLength}
              onMouseUp={(e) => {
                if (this.props.onNodeMouseUp) {
                  this.props.onNodeMouseUp(e, item);
                }
              }}
              onMouseDown={(e) => {
                clearTimeout(this.renameTimeout);
                // canEditWithFocus条件下，只有当前页面处于active状态且activeElement是当前事件触发节点的子元素或者当前元素才可以重新编辑
                if (
                  this.props.canEditWithFocus &&
                  !(
                    document.hasFocus() &&
                    document.activeElement &&
                    e.currentTarget &&
                    e.currentTarget.contains(document.activeElement)
                  )
                ) {
                  return;
                }
                if (selected && this.props.renamable) {
                  this.renameTimeout = window.setTimeout(() => {
                    this.setState({ renameID: item.id });
                  }, 6e2);
                }
                if (this.props.onNodeMouseDown) {
                  this.props.onNodeMouseDown(e, item);
                }
              }}
              onMouseEnter={(e) => {
                if (this.props.onNodeMouseEnter) {
                  this.props.onNodeMouseEnter(e, item);
                }
              }}
              onMouseLeave={(e) => {
                if (this.props.onNodeMouseLeave) {
                  this.props.onNodeMouseLeave(e, item);
                }
              }}
              canDropIn={(source: string, target: string): boolean => {
                if (renameID) {
                  return false;
                }

                // 判断 source 是否是 target 的父级
                const data = this.props.data;
                if (data.length <= 0) {
                  return true;
                }

                // 禁止多选被放入
                if (this.props.selectedKeys.length > 1 && this.props.selectedKeys.includes(target)) {
                  return false;
                }

                // 如果只支持平级拖动
                if (this.props.justCanPeersDrag) {
                  const allChildren = flattenChildren(this.props.data);

                  return isSiblings(allChildren, source, target);
                }

                // 如果要拖拽的节点是被选中的，应该判断所有的节点
                if (this.props.selectedKeys.includes(source)) {
                  let childrenNodes: string[] = [];
                  this.props.selectedKeys.forEach((item) => {
                    childrenNodes = childrenNodes.concat(this.getChildrenNodesById(data, item));
                  });
                  return !childrenNodes.includes(target);
                }

                const childrenNodes = this.getChildrenNodesById(data, source);
                return !childrenNodes.includes(target);
              }}
              tip={tip}
            >
              {item.children &&
                item.children.length > 0 &&
                expanedItem &&
                this.renderNodes(item.children, checked, offset + 16, item, isShowALLChildrenFlag)}
            </Node>
          );
        })}
      </ul>
    );
  }

  render() {
    let isSearchEmpty: boolean = false;
    const searchKey = this.props.searchKey;
    if (searchKey) {
      const allChildren = flattenChildren(this.props.data);
      isSearchEmpty = allChildren.every((node) => !isNodeMatch(node, searchKey));
    }
    return (
      <div
        ref={this.treeRef}
        className={classnames('dsm-c-tree', {
          'dark-tree': this.props.theme && ['spec-dark', 'dark'].includes(this.props.theme),
          'spec-dark-tree': this.props.theme === 'spec-dark',
          'is-finding-interaction-target': this.props.isFindingInteractionTarget,
          'max-width-setted': this.props.maxWidth !== undefined,
        })}
        style={{
          width: this.props.maxWidth,
        }}
      >
        {isSearchEmpty && (
          <div className="search-empty">
            <img
              width={60}
              src={this.props.theme === 'white' ? noSearchResult : noSearchResultBlack}
              alt="No results found"
            />
            <p>{i18n('tree.emptySearch')}</p>
          </div>
        )}
        {!isSearchEmpty && this.renderNodes(this.props.data, false)}
      </div>
    );
  }
}

export default Tree;
