import i18n from '@/i18n';
import { IComponentItem } from '@/libs/types';
import { CTextArea } from '@/libs/constants';
import { SpriteThumb } from '@/consts/spriteIcons';
import * as SystemsColor from '@consts/colors';
import { FillType } from '@/fbs/rp/models/properties/fill';
import { StrokeLineCap, StrokeLineJoin } from '@/fbs/rp/models/properties/stroke';
import { TextAlign } from '@/fbs/rp/models/properties/text';
import { IComponentData } from '@/fbs/rp/models/component';
import { makeCommonComponent, getDefaultShadow } from '@/libs/helper';
import { PredefinedStates } from '@/consts/state';
import { DefaultBorder } from '@/consts/border';
import { DefaultRadius } from '@/consts/radius';

export const textAreaConfig: IComponentItem = {
  type: CTextArea,
  name: i18n('resource.components.textarea'),
  thumb: {
    spriteIconClass: SpriteThumb.Textarea.className,
    dragPosition: SpriteThumb.Textarea.position,
  },
  getDefaultData() {
    return {
      properties: {
        textStyle: {
          fontFamily: 'Microsoft YaHei',
          fontStyle: { underline: false, bold: false, strike: false, italic: false },
          fontSize: 14,
          color: SystemsColor.DefaultTextColor,
          textAlign: TextAlign.left,
        },
        multiText: {
          indent: false,
          wrap: true,
        },
        fill: {
          type: FillType.solid,
          color: SystemsColor.DefaultWhiteFillColor,
          disabled: false,
        },
        stroke: {
          thickness: 1,
          color: SystemsColor.DefaultStrokeColor1,
          cap: StrokeLineCap.Round,
          join: StrokeLineJoin.Round,
          disabled: false,
        },
        border: DefaultBorder,
        radius: DefaultRadius,
        shadow: getDefaultShadow(),
        placeholder: {
          prop: 'string',
          name: i18n('property.propertyNames.placeholder'),
          value: '',
        },
        placeholderStyle: {
          prop: 'color',
          name: i18n('property.propertyNames.placeholderColor'),
          value: SystemsColor.GrayColor,
        },
        padding: {
          disabled: false,
          top: 2,
          left: 8,
          right: 4,
          bottom: 2,
        },
      },
    };
  },
};

export function makeTextArea(id: string): IComponentData {
  return makeCommonComponent(id, CTextArea, {
    size: {
      width: 300,
      height: 120,
    },
    value: '',
    properties: {},
    states: {
      [PredefinedStates.disabled]: {
        enabled: true,
        properties: {
          stroke: {
            color: SystemsColor.DisabledStrokeColor,
          },
          textStyle: {
            color: SystemsColor.DisabledTextColor,
          },
        },
      },
      [PredefinedStates.focus]: {
        enabled: true,
        properties: {
          stroke: {
            thickness: 2,
            color: SystemsColor.FocusStrokeColor,
          },
        },
      },
      [PredefinedStates.hover]: {
        enabled: true,
        properties: {
          stroke: {
            color: SystemsColor.HoverStrokeColor,
          },
        },
      },
    },
  });
}
