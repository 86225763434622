import * as React from 'react';
import * as ReactDOM from 'react-dom';
import classnames from 'classnames';
import { isEqual } from 'lodash';

import KeyCodeMap from '@dsm2/constants/KeyCodeMap';

import './index.scss';

interface IProps {
  className?: string;
  vertical?: boolean;
  textAlign?: string;
  rotate: number;
  color?: string;
}

interface IState {
  caretStyle?: React.CSSProperties;
}

class CustomCaret extends React.PureComponent<IProps, IState> {
  pi: number = 0;
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { rotate, vertical } = this.props;
    this.pi = (rotate * Math.PI) / 180 + (vertical ? 90 : 0);
    // this.doCustomCaret();
    window.addEventListener('keydown', this.handleWindowKeyDown);
    window.addEventListener('keyup', this.handleWindowKeyUp);
    window.addEventListener('wheel', this.handleWheel);
    window.addEventListener('mousedown', this.handleMouseDown);
  }

  componentWillUnmount() {
    this.doClearCustomCaret();
    window.removeEventListener('keydown', this.handleWindowKeyDown);
    window.removeEventListener('keyup', this.handleWindowKeyUp);
    window.removeEventListener('wheel', this.handleWheel);
    window.removeEventListener('mousedown', this.handleMouseDown);
  }

  handleWheel = () => {
    this.doCustomCaret();
  };

  handleMouseDown = () => {
    setTimeout(() => {
      this.doCustomCaret();
    }, 0);
  };

  handleWindowKeyUp = () => {
    this.doCustomCaret();
  };

  handleWindowKeyDown = (e: KeyboardEvent) => {
    e.keyCode === KeyCodeMap.VK_CTRL ? this.doClearCustomCaret() : setTimeout(() => this.doCustomCaret());
  };

  doClearCustomCaret() {
    this.state.caretStyle && this.setState({ caretStyle: undefined });
  }

  // 计算自定义光标位置
  doCustomCaret() {
    const selection = document.getSelection();
    // 未处于编辑区、无dom、失焦 不显示焦点
    if (!selection?.rangeCount) {
      this.doClearCustomCaret();
      return;
    }
    const range = selection.getRangeAt(0);
    // 有选中文字 不显示焦点
    if (!range.collapsed) {
      this.doClearCustomCaret(); //BUG：输入过快导致折叠
      return;
    }
    const { textAlign, rotate, color, vertical } = this.props;
    const container =
      range.commonAncestorContainer.nodeType === 1
        ? (range.commonAncestorContainer as HTMLElement)
        : range.commonAncestorContainer.parentElement;
    ////////////////////
    if (!container) {
      return;
    }
    const { style } = container;
    // 解决：换行时无法获取焦点位置
    const span = document.createElement('span');
    range.insertNode(span);
    range.collapse();
    span.setAttribute('style', `background: red;`);
    span.innerText = '0';
    const bounds = span.getBoundingClientRect();
    let { x, y, width, height } = bounds;
    span.remove();
    // 当旋转弧度
    if (Math.sin(this.pi) > Math.sin(Math.PI / 4) || Math.sin(this.pi) < Math.sin((Math.PI * 5) / 4)) {
      [width, height] = [height, width];
    }
    // [x, y, width, height] = this.doResetPositionWithRotate({ x, y, width, height });
    [x, y] = this.doResetPositionWithTextAlign(style.textAlign || textAlign, { x, y, width, height });
    ////////
    const { caretStyle: defaultStyle } = this.state;
    const caretStyle: React.CSSProperties = {
      transform: `rotateZ(${rotate}deg)`,
      left: x,
      top: y,
      height: vertical ? 2 : height,
      width: vertical ? width : 2,
      // width,
      // height,
      backgroundColor: style.color || color,
    };
    !isEqual(defaultStyle, caretStyle) && this.setState({ caretStyle });
  }

  doResetPositionWithTextAlign(
    textAlign: string | undefined,
    bounds: {
      x: number;
      y: number;
      width: number;
      height: number;
    },
  ) {
    const { x, y, width } = bounds;
    switch (textAlign) {
      case 'right':
        return [x + width, y];
      case 'center':
        return [x + width / 2, y];
      default:
        break;
    }
    return [x, y];
  }

  doResetPositionWithRotate(bounds: { x: number; y: number; width: number; height: number }) {
    // w2 * cos(pi) + h2 * sin(pi) = w;
    // h2 * cos(pi) + w2 * sin(pi) = h;
    const { x, y, width, height } = bounds;
    const cos = Math.cos(this.pi);
    const sin = Math.sin(this.pi);
    const newHeight = (height * cos - width * sin) / ((cos + sin) * (cos - sin));
    return [x + width / 2, y + height / 2, newHeight, (width - newHeight * Math.sin(this.pi)) / Math.cos(this.pi)];
  }

  render() {
    const { caretStyle } = this.state;
    if (!caretStyle) {
      return null;
    }
    const backgroundColor = caretStyle.backgroundColor || this.props.color;
    return ReactDOM.createPortal(
      <span
        className={classnames(this.props.className, 'rich-text-editor-custom-caret')}
        style={{ ...caretStyle, backgroundColor }}
      />,
      document.body,
    );
  }
}

export default CustomCaret;
