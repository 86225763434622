import * as React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';
import { StyleHelper } from '@/helpers/styleHelper';
import { UIComponent } from '@/editor/comps';
import { parseChart } from '../parser/parseChart';
import { renderComponentWrapper } from './renderComponentWrapper';

export const renderChartWrapper = (comp: UIComponent): JSX.Element => {
  const { id, properties, size, opacity } = comp;
  const parser = StyleHelper.initCSSStyleParser(properties);
  const boxStyle = {
    ...parser.getFillStyle(),
    ...parser.getShadowStyle(),
    ...parser.getStrokeStyle(),
    ...parser.getRadiusStyle(size),
    opacity: isUndefined(opacity) ? 1 : opacity / 100,
    width: size.width,
    height: size.height,
  };
  const isPreview = false;
  const html = parseChart(comp);
  const scale = 1;

  const content = (
    <div
      key={id}
      id={id}
      style={{ ...boxStyle, pointerEvents: isPreview ? 'auto' : 'none', overflow: 'hidden' }}
      className={classnames('component', `component-${comp.type}`)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );

  return renderComponentWrapper({ comp, scale }, content);
};
