import { getTowPointDis, rotatePoint } from '@utils/rotateUtils';
import { depthClone, getPointOnCycleInCurrentCoordination, isEqualDate } from '@utils/globalUtils';

import { IPoint } from '@fbs/common/models/common';
import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';

import { default as SelectionFrameType, SelectionPoints } from '@consts/enums/selectionBoxType';
import { UIComponent } from '@editor/comps';
import { CCompoundPath, CPath, CText, CPureText, CAudio, CStackPanel, CIcon } from '@libs/constants';

import { getNoRotateRect, getOppositePoint, getSizeByPoints } from './rotateHelper';

export interface ISizeLimiter {
  applySizeLimit(newPoints: IPoint[], selPoint: SelectionPoints): IPoint;
}

export class RatioSizeLimiter implements ISizeLimiter {
  constructor(private oldPoints: IPoint[], private rotate: number, private ratio: number) {}

  setOldPoints(oldPoints: IPoint[]) {
    this.oldPoints = oldPoints;
  }

  getOldPoints() {
    return this.oldPoints;
  }

  applySizeLimit(newPoints: IPoint[], selectedPoint: SelectionPoints): IPoint {
    let oldSize = getSizeByPoints(this.oldPoints);
    let newSize = getSizeByPoints(newPoints);

    const widthOff = newSize.width - oldSize.width;
    const heightOff = newSize.height - oldSize.height;

    const maxOffset = Math.max(widthOff, heightOff * this.ratio);
    const offsetX = maxOffset;
    const offsetY = maxOffset / this.ratio;

    const angle = (this.rotate * 2 * Math.PI) / 360;
    const dy1 = (offsetX / 2) * Math.sin(angle);
    const dx1 = (offsetX / 2) * Math.cos(angle);

    const dy2 = (offsetY / 2) * Math.cos(angle);
    const dx2 = (offsetY / 2) * Math.sin(angle);

    let oldBounds = getNoRotateRect(this.oldPoints);
    const center = {
      x: oldBounds.left + oldBounds.width / 2,
      y: oldBounds.top + oldBounds.height / 2,
    };
    if (selectedPoint === 0 || selectedPoint === 2) {
      const dx = dx1 - dx2;
      const dy = dy1 + dy2;
      if (selectedPoint === 0) {
        center.x = center.x - dx;
        center.y = center.y - dy;
      } else {
        center.x = center.x + dx;
        center.y = center.y + dy;
      }
    }
    if (selectedPoint === 1 || selectedPoint === 3) {
      const dx = dx1 + dx2;
      const dy = -dy1 + dy2;
      if (selectedPoint === 1) {
        center.x = center.x + dx;
        center.y = center.y - dy;
      } else {
        center.x = center.x - dx;
        center.y = center.y + dy;
      }
    }

    const fixedPoint = getOppositePoint(this.oldPoints, selectedPoint);
    return {
      x: center.x * 2 - fixedPoint.x,
      y: center.y * 2 - fixedPoint.y,
    };
  }
}

export class AngleLineSizeLimiter implements ISizeLimiter {
  //1.水平线条锁定宽高比，只能左右拉动
  //2.垂直线条锁定宽高比，只能上下拉动
  //3.非上述情况下按住shift，可以按照8条45度分割平面的线移动
  constructor(private lineComp: UIComponent, private oldPoint: IPoint[]) {}

  applySizeLimit(newPoints: IPoint[], dragIndex: SelectionPoints): IPoint {
    const isHorizontalLine = this.lineComp.size.height === 0;
    const isVerticalLine = this.lineComp.size.width === 0;
    const { lockedRatio } = this.lineComp.size;
    const originPoint = this.oldPoint[dragIndex];
    const newPoint = depthClone(newPoints[dragIndex]);
    if (isHorizontalLine && lockedRatio) {
      newPoint.y = originPoint.y;
      return newPoint;
    } else if (isVerticalLine && lockedRatio) {
      newPoint.x = originPoint.x;
      return newPoint;
    } else {
      // 1、获取固定点
      const fixedPoint = getOppositePoint(newPoints, dragIndex);
      return computedLinePointWithShift(fixedPoint, newPoint);
    }
  }
}

export function computedLinePointWithShift(fixedPoint: IPoint, newPoint: IPoint) {
  // 2、判断靠齐角度
  const xrad = -Math.atan2(newPoint.y - fixedPoint.y, newPoint.x - fixedPoint.x);
  let angle = ((xrad / Math.PI) * 180 + 360) % 360;
  // 3、计算两点间距离
  let step = Math.floor(angle / 45);
  const mod = angle % 45;
  if (mod >= 22.5) {
    step += 1;
  }
  const currentAngle = ((step * 45) / 180) * Math.PI;

  // 4、计算两点之间距离
  const distance = getTowPointDis(newPoint, fixedPoint);

  return getPointOnCycleInCurrentCoordination(fixedPoint, distance, currentAngle);
}

export function getPatchesFromDynamicInfo(components: UIComponent[]): ArtboardPatches {
  const newPatches: ArtboardPatches = { do: {}, undo: {} };
  components.forEach((comp) => {
    const compData = depthClone(comp.toJSON());
    const positionPath = [CCompoundPath, CPath].includes(comp.type) ? '/position' : comp.getCurrentPositionPath();
    const forceUsedStatePath = [CText, CPureText].includes(comp.type) && Boolean(comp.currentStateID);
    const sizePath = [CCompoundPath, CPath].includes(comp.type) ? '/size' : comp.getCurrentSizePath(forceUsedStatePath);
    const valuePath = [CCompoundPath, CPath].includes(comp.type) ? '/value' : comp.getCurrentPropertiesPath('/value');
    const rotatePath = [CCompoundPath, CPath].includes(comp.type) ? '/rotate' : comp.getCurrentRotatePath();
    const fillPath = comp.getCurrentPropertiesPath('properties/fill');
    const newPosition = depthClone(comp.position);
    const newSize = depthClone(comp.size);
    const newValue = depthClone(comp.value);
    const newRotate = depthClone(comp.rotate);
    const stateID = comp.currentStateID;
    let properties = comp.toJSON().properties;
    let oldPosition = compData.position;
    if (stateID && comp.hasState(stateID)) {
      properties = comp.states[stateID].properties || properties;
      oldPosition = comp.states[stateID].position || oldPosition;
    }
    const newFill = depthClone(properties.fill);
    const newAutoSize = comp.dynamicInfo && comp.dynamicInfo.autoSize;
    if (!isEqualDate(newPosition, oldPosition)) {
      newPatches.do[comp.id] = [...(newPatches.do[comp.id] || []), Ops.replace(positionPath, newPosition)];
      newPatches.undo[comp.id] = [...(newPatches.undo[comp.id] || []), Ops.replace(positionPath, oldPosition)];
    }
    if (!isEqualDate(newSize, compData.size)) {
      newPatches.do[comp.id] = [...(newPatches.do[comp.id] || []), Ops.replace(sizePath, newSize)];
      newPatches.undo[comp.id] = [...(newPatches.undo[comp.id] || []), Ops.replace(sizePath, compData.size)];
    }
    if (!isEqualDate(newRotate, compData.rotate)) {
      newPatches.do[comp.id] = [...(newPatches.do[comp.id] || []), Ops.replace(rotatePath, newRotate)];
      newPatches.undo[comp.id] = [...(newPatches.undo[comp.id] || []), Ops.replace(rotatePath, compData.rotate)];
    }
    const isRefValue = compData.value === '@value';
    const oldValue = stateID ? compData.states[stateID]?.value || compData.value : compData.value;
    if (!isRefValue && !isEqualDate(newValue, oldValue)) {
      newPatches.do[comp.id] = [...(newPatches.do[comp.id] || []), Ops.replace(valuePath, newValue)];
      newPatches.undo[comp.id] = [...(newPatches.undo[comp.id] || []), Ops.replace(valuePath, oldValue)];
    }
    const oldProperties = stateID ? compData.states[stateID]?.properties || compData.properties : compData.properties;
    if (!isEqualDate(newFill, oldProperties.fill)) {
      newPatches.do[comp.id] = [...(newPatches.do[comp.id] || []), Ops.replace(fillPath, newFill)];
      newPatches.undo[comp.id] = [...(newPatches.undo[comp.id] || []), Ops.replace(fillPath, oldProperties.fill)];
    }
    const originAutoSize = !!comp.autoSize;
    if (newAutoSize !== undefined && originAutoSize !== newAutoSize) {
      newPatches.do[comp.id] = [...(newPatches.do[comp.id] || []), Ops.replace('./autoSize', newAutoSize)];
      newPatches.undo[comp.id] = [...(newPatches.undo[comp.id] || []), Ops.replace('./autoSize', compData.autoSize)];
    }
  });
  return newPatches;
}

// 四点向外扩展距离
export function centerResizePoints(points: IPoint[], rotate: number, diff: number) {
  if (points.length !== 4) return;
  const offsets = [
    { x: -diff, y: -diff },
    { x: diff, y: -diff },
    { x: diff, y: diff },
    { x: -diff, y: diff },
  ];
  points.forEach((p, i) => {
    points[i] = getNewPointByOffset(p, rotate, offsets[i]);
  });
}

/**
 *
 * @param {IPoint} point 点原坐标
 * @param {{x: number, y: number}} offset 点 对于所在矩形在旋转之前xy的偏移
 * @param {number} rotate 所在矩形旋转的角度
 */
function getNewPointByOffset(point: IPoint, rotate: number, offset: { x: number; y: number }) {
  const vector = rotatePoint(offset, rotate);
  return {
    x: point.x + vector.x,
    y: point.y + vector.y,
  };
}

export const ComponentSizeMode: { [type: string]: SelectionFrameType } = {
  // [CText]: SelectionFrameType.none,
  [CAudio]: SelectionFrameType.leftMiddle_to_rightMiddle,
  [CStackPanel]: SelectionFrameType.none,
  [CIcon]: SelectionFrameType.corner,
  // [Comp_ProgressBar]: SelectionFrameType.w_to_e,
  // [Comp_Slider]: SelectionFrameType.w_to_e,
};
