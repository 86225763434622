import Dexie from 'dexie';

/**
 * 捕获indexedDB方法执行产生的错误
 * 直接调用store.dispatch会产生循环调用的问题
 * 使用自定义事件indexedDBError，在此次捕获到异常触发，在主页面didMount时监听
 * @param fn
 */
export async function executeIndexDBFunc<T>(fn: Function): Promise<T | undefined> {
  try {
    return await fn();
  } catch (error) {
    console.log(error);
    window.dispatchEvent(new CustomEvent('indexedDBError'));
  }
}

export default class IndexedDBBase {
  private _table!: Dexie.Table<{ key: string; value: string }, string>;
  private _db!: Dexie;
  private _isHandleCloseEvent: boolean = false;
  private _dbName: string;

  constructor(name: string) {
    this._isHandleCloseEvent = false;
    this._dbName = name;
    this.doOpenDB();
    this._isHandleCloseEvent = true;
  }

  public destroy(): void {
    this._isHandleCloseEvent = false;
    this._db.close();
  }

  private doOpenDB(): void {
    this._db = new Dexie(this._dbName);

    /**
     * 注意，这里不能加value,否则会造成将value变为indexDB的索引问题
     */
    this._db.version(1).stores({
      global: '&key',
    });

    this._table = this._db.table('global');

    this._db.on.close.subscribe(() => {
      if (this._isHandleCloseEvent) {
        this.doOpenDB();
      }
    });
  }

  public async hasKey(key: string): Promise<boolean> {
    const res = await executeIndexDBFunc<boolean>(async () => {
      const res = await this.getValue(key);
      return res != undefined;
    });
    return res || false;
  }

  public async getValue(key: string): Promise<string | undefined> {
    return await executeIndexDBFunc(async () => {
      const res = await this._table.get(key);
      return res?.value;
    });
  }

  public async saveValue(key: string, value: string): Promise<void> {
    await executeIndexDBFunc(async () => {
      await this._table.put({ value, key });
    });
  }

  public async deleteKey(key: string): Promise<void> {
    await executeIndexDBFunc(async () => {
      await this._table.delete(key);
    });
  }

  public async getAllKeys(): Promise<string[]> {
    const res = await executeIndexDBFunc<string[]>(async () => {
      const keys: Set<string> = new Set<string>();
      await this._table.each((data) => {
        keys.add(data.key);
      });

      return Array.from(keys);
    });
    return res || [];
  }

  public async getValuesByKeyPrefix(keyPrefix: string): Promise<{ key: string; value: string }[]> {
    const res = await executeIndexDBFunc<{ key: string; value: string }[]>(async () => {
      return await this._table.where('key').startsWith(keyPrefix).toArray();
    });
    return res || [];
  }
}
