import * as WestAEast from '@assets/cursors/arrowWE.png';
import * as NorthASouth from '@assets/cursors/arrowNS.png';
import * as NorthEastOrWestSouth from '@assets/cursors/arrowNE.png';
import * as SouthEastOrNorthWest from '@assets/cursors/arrowNW.png';
import * as Rotate from '@assets/cursors/rotate.png';
import * as AddArtboard from '@assets/cursors/artboard.png';

import * as DrawRect from '@assets/cursors/drawRect.png';
import * as DrawCircle from '@assets/cursors/drawCircle.png';
import * as DrawLine from '@assets/cursors/drawLine.png';
import * as DrawText from '@assets/cursors/drawText.png';
import * as DrawImg from '@assets/cursors/drawImg.png';
import * as DrawBtn from '@assets/cursors/drawBtn.png';
import * as DrawDefault from '@assets/cursors/drawDefault.png';

import * as Pen from '@assets/cursors/pen.png';
import * as PenAdd from '@assets/cursors/penAdd.png';
import * as PenMove from '@assets/cursors/penMove.png';
import * as PenClose from '@assets/cursors/penClose.png';
import * as Pencil from '@assets/cursors/pencil.png';
import * as Shears1 from '@assets/cursors/Shears1.png';
import * as Shears2 from '@assets/cursors/Shears2.png';

import * as Drag from '@assets/cursors/drag.png';
import * as Drop from '@assets/cursors/drop.png';

//cursor png
export const Cursors = {
  WestAEast,
  NorthASouth,
  NorthEastOrWestSouth,
  SouthEastOrNorthWest,
  Rotate,
  AddArtboard,
  DrawRect,
  DrawCircle,
  DrawLine,
  DrawText,
  DrawImg,
  DrawBtn,
  DrawDefault,
  Pen,
  PenAdd,
  PenMove,
  PenClose,
  Pencil,
  Shears1,
  Shears2,
  Drag,
  Drop,
};
