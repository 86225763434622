import * as React from 'react';
import Radio from './Radio';

import './index.scss';

export interface IRadioItemProp {
  id: string | number;
  text: string;
  disabled?: boolean;
}

export interface IRadioButtonProp {
  data: Array<IRadioItemProp>;
  selected: number | string;

  onChange?(id: number | string): void;
}

class RadioButton extends React.Component<IRadioButtonProp, { selected: number | string }> {
  constructor(props: IRadioButtonProp) {
    super(props);
    this.state = {
      selected: props.selected,
    };
  }

  onItemClicked(id: number | string) {
    this.setState(
      {
        selected: id,
      },
      () => this.props.onChange && this.props.onChange(id),
    );
  }

  render() {
    return (
      <div className="dsm-c-radio-button">
        {this.props.data.map((item) => (
          <Radio
            key={item.id}
            text={item.text}
            checked={item.id === this.state.selected}
            disabled={item.disabled}
            onClick={this.onItemClicked.bind(this, item.id)}
          />
        ))}
      </div>
    );
  }
}

export default RadioButton;
