import * as tinycolor from 'tinycolor2';

export interface IColorInfo {
  hsv: tinycolor.ColorFormats.HSVA;
  hex: string;
  rgba: tinycolor.ColorFormats.RGBA;
  alpha: number;
  originalValue: string;
}

export function checkRange(value: number, [min, max]: Array<number>): number {
  if (min > max) {
    [max, min] = [min, max];
  }
  return Math.max(Math.min(value, max), min);
}
