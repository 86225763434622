import PropertyBase from './base';
import { PureColor } from './color';
import { DefaultCollapseIconType } from './tree';

export enum IconPosition {
  Left,
  Right,
}

export interface ICollapseExpand extends PropertyBase {
  iconSize: number;
  chooseExpandType: DefaultCollapseIconType;
  iconColor: PureColor;
  iconPosition: IconPosition;
}

export const CollapsePropertyName = 'collapse';