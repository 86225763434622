import { Dispatch } from 'redux';

import apis from '@/apis';
import * as exampleApis from '@apis/example';

import { IExampleInfo } from '@fbs/rp/models/example';
import { ITeams } from '@fbs/idoc/models/team';
import { IAppsOfTeam } from '@fbs/teamManagement';
import { AppTypes } from '@fbs/idoc/models/app';

import { TeamType } from '@/consts/team';

import { Global, GlobalThunkActions } from '../desktopActions';
import { ActionsUnion, ActionType, createAction } from '../types';
import { ThunkActions as GlobalActions } from '../global/actions';

function loadExamples(
  pageIndex: number,
  pageSize: number,
  type: 'search' | 'category' | 'like' | 'all',
  value: string,
) {
  switch (type) {
    case 'all':
      return exampleApis.getAllExampleApp(pageIndex, pageSize);
    case 'category':
      return exampleApis.getCategoryExampleApp(pageIndex, pageSize, value);
    case 'like':
      return exampleApis.getLikeExampleApp(pageIndex, pageSize);
    case 'search':
      return exampleApis.getSearchExampleApp(pageIndex, pageSize, value);
  }
}

export const Actions = {
  loadExamples: (data: IExampleInfo) => createAction(ActionType.Example_Load_All, data),
  pageDownExampleApp: (data: IExampleInfo) => createAction(ActionType.Example_Page_Down, data),
  setAllTeams: (teams: ITeams[]) => createAction(ActionType.Example_SetAllTeams, teams),
  getAppSetsbyTeam: (appSets: IAppsOfTeam[]) => createAction(ActionType.Example_GetAppSetsByTeam, appSets),
  setDefaultTeam: (team: ITeams) => createAction(ActionType.Example_SetDefaultTeam, team),
  createTeam: (name: string) => createAction(ActionType.Example_CreateTeam, name),
};

export const ThunkActions = {
  loadExampleApps: (
    pageIndex: number,
    pageSize: number,
    type: 'search' | 'category' | 'like' | 'all',
    value: string,
  ) => async (dispatch: Dispatch) => {
    dispatch(Global.waiting(true));
    loadExamples(pageIndex, pageSize, type, value)
      ?.then((res) => {
        dispatch(Global.waiting(false));
        dispatch(Actions.loadExamples(res));
      })
      .catch((e) => GlobalThunkActions.thunkErrorMessage(e)(dispatch));
  },
  pageDownExampleApp: (
    pageIndex: number,
    pageSize: number,
    type: 'search' | 'category' | 'like' | 'all',
    value: string,
  ) => async (dispatch: Dispatch) => {
    dispatch(Global.waiting(true));
    loadExamples(pageIndex, pageSize, type, value)
      ?.then((res) => {
        dispatch(Global.waiting(false));
        dispatch(Actions.pageDownExampleApp(res));
      })
      .catch((e) => GlobalThunkActions.thunkErrorMessage(e)(dispatch));
  },
  setAllTeams: (teams: ITeams[]) => async (dispatch: Dispatch) => {
    dispatch(Actions.setAllTeams(teams));
  },
  getAppSetsbyTeam: (teamID: string) => async (dispatch: Dispatch) => {
    await apis.team
      .getAllApps(teamID)
      .then((app) => {
        const appSets = app.apps.filter((app) => {
          return app.type === AppTypes.AppSet;
        });
        dispatch(Actions.getAppSetsbyTeam(appSets));
      })
      .catch((e) => GlobalActions.thunkError(e.message)(dispatch));
  },
  setDefaultTeam: (team: ITeams) => async (dispatch: Dispatch) => {
    dispatch(Actions.setDefaultTeam(team));
  },
  createTeam: (name: string) => async (dispatch: Dispatch) => {
    await apis.team.createTeam(name).then();
    const teams = await apis.team.getAllTeams(!RP_CONFIGS.isPrivateDeployment);
    dispatch(Actions.setAllTeams(teams));
    let defaultTeam = teams[0];
    if (!RP_CONFIGS.isPrivateDeployment) {
      defaultTeam =
        teams.find((team) => {
          return team.teamType !== TeamType.Personal;
        }) ?? teams[0];
    }
    dispatch(Actions.setDefaultTeam(defaultTeam));
  },
};

export type ExampleActions = ActionsUnion<typeof Actions>;
