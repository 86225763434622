import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { PageOrientation } from 'pdfmake/interfaces';

import { ISize } from '../type';
import { ICreatePdfImageOptins } from './type';

const getMaxSize = (sizes: ISize[]) => {
  return sizes.reduce(
    (a, b) => {
      const width = Math.max(a.width, b.width);
      const height = Math.max(a.height, b.height);
      return {
        width,
        height,
      };
    },
    {
      width: 0,
      height: 0,
    },
  );
};

/**
 *
 * pdf api ，两个库pdfmake与jspdf改造成一致，方便切换
 * @param options
 * @returns
 */
export function createPdfByImage(options: ICreatePdfImageOptins): Promise<void> {
  const { images, name } = options;
  const { width, height } = getMaxSize(images.map((t) => t.size));
  const pageMargins = 0;
  const pageSize = { width: width + 2 * pageMargins, height: height + 2 * pageMargins };
  const content = images.map((t) => {
    // const fit: [number, number] = [t.size.width, t.size.height]
    return {
      image: t.value,
      width,
      height,
      // fit,
      cover: {
        ...t.size,
      },
    };
  });

  const docDefinition = {
    pageMargins,
    content,
    pageBreak: 'before',
    pageSize,
    pageOrientation: (width >= height ? 'landscape' : 'portrait') as PageOrientation,
    info: {
      title: name ?? 'mockplus-rp',
      author: 'mockplus.cn',
      keywords: 'rp',
    },
  };
  const pdf = pdfMake.createPdf(docDefinition, undefined, undefined, pdfFonts.pdfMake.vfs);
  return new Promise((resolve) => {
    pdf.download(name, () => {
      resolve();
    });
    // pdf.getBlob((blob) => {
    //   window.debug && console.log('pdf.getBlob');
    //   // saveAs(blob, name);
    //   // onFinish(onSuccess, onError);
    //   resolve();
    // });
  });
}
