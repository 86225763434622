import { ITeams } from '@fbs/idoc/models/team';
import { languageManager } from '@i18n';
import { RPTeamPayment } from '@/fbs/teamManagement/models/team';
import { isFreeTeam } from '@/fbs/teamManagement';

/**
 * 是否未参加过RP无限包活动
 * @param payment
 */
export function isNotInvolvedRPFreeActivity(payment?: string) {
  return payment === RPTeamPayment.Free || !payment;
}

/**
 * 是否能参与无限设计包活动
 * 1.活动进行中
 * 2.免费团队
 * 3.未获得永久
 * 4.非私有部署
 * 5.中文版
 * @param team 团队信息
 */
export function canFreeProgram(team?: ITeams | null): boolean {
  if (window.debug) return true;
  return (
    !!team &&
    !freeProgramHasEnded() &&
    isFreeTeam(team) &&
    !RP_CONFIGS.isPrivateDeployment &&
    team?.rpDesignPayment !== RPTeamPayment.Perpetual &&
    languageManager.isZHVersion
  );
}

/**
 * 无限设计包活动是否结束了
 */
export function freeProgramHasEnded() {
  // FIXME 无限设计包取消限制
  return false;
  // const endTime = new Date('2021-12-31 23:59:59');
  // return new Date() > endTime;
}

/**
 * 是否获得了永久无限设计包
 * @param rpDesignPayment
 */
export function isPerpetual(rpDesignPayment?: string) {
  return rpDesignPayment === RPTeamPayment.Perpetual;
}

/**
 * 无限设计包权限过期
 */
export function freeProgramHasExpired(payment?: string, rpDesignExpireTime?: Date) {
  if (isPerpetual(payment)) {
    return false;
  }
  if (payment === RPTeamPayment.TimeLimit) {
    return rpDesignExpireTime ? new Date() > new Date(rpDesignExpireTime || 0) : false;
  }
  return true;
}

/**
 * 某团队是否能否使用离线演示包功能（IDoc也在使用）
 * | 不是免费团队
 * | 无限设计包未过期
 * @param team
 */
export function canUseOfflineDemo(team: ITeams) {
  return (
    !isFreeTeam(team) ||
    !freeProgramHasExpired(team?.rpDesignPayment, team?.rpDesignExpireTime) ||
    RP_CONFIGS.isPrivateDeployment
  );
}

/**
 * 某团队是否能否使用无限页面功能
 * | 不是免费团队
 * | 无限设计包未过期
 * @param team
 */
export function canUseUnlimitedPages(team: ITeams) {
  return (
    !isFreeTeam(team) ||
    !freeProgramHasExpired(team?.rpDesignPayment, team?.rpDesignExpireTime) ||
    RP_CONFIGS.isPrivateDeployment
  );
}

/**
 * 某团队是否能使用导出MRP文件的功能
 * | 不是免费团队
 * | 无限设计包未过期
 * @param team
 */
export function canUseExportMRPFile(team: ITeams) {
  return (
    !isFreeTeam(team) ||
    !freeProgramHasExpired(team?.rpDesignPayment, team?.rpDesignExpireTime) ||
    RP_CONFIGS.isPrivateDeployment
  );
}

/**
 * 某团队是否能使用导出图片
 * | 不是免费团队
 * | 无限设计包未过期
 * @param team
 */
export function canUseExportPicture(team: ITeams) {
  return (
    !isFreeTeam(team) ||
    !freeProgramHasExpired(team?.rpDesignPayment, team?.rpDesignExpireTime) ||
    RP_CONFIGS.isPrivateDeployment
  );
}

/**
 * 某团队是否能使用设计资源共享
 * | 不是免费团队
 * | 无限设计包未过期
 * @param team
 */
export function canUseDesignResources(team: ITeams) {
  return (
    !isFreeTeam(team) ||
    !freeProgramHasExpired(team?.rpDesignPayment, team?.rpDesignExpireTime) ||
    RP_CONFIGS.isPrivateDeployment
  );
}

export function isElectron() {
  // Renderer process
  if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
    return true;
  }

  // Main process
  if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (
    typeof navigator === 'object' &&
    typeof navigator.userAgent === 'string' &&
    navigator.userAgent.indexOf('Electron') >= 0
  ) {
    return true;
  }

  return false;
}
