import { AppClassify } from '../../idoc/models/app';
import { ISize, Orientation } from '../../common/models/common';
import { Base } from './base';

export enum AppTypes {
  RP = 'rp',
  AppSet = 'app-set',
}

// App 类型
export enum IAppType {
  Phone = 'phone',
  Web = 'web',
  Pad = 'pad',
  Custom = 'custom',
  Watch = 'watch',
  Vehicle = 'vehicle',
}

export enum Visibility {
  Private = 'private', // 私有的
  Internal = 'internal', // 公开：团队项目
  Public = 'public', // 跨团队公开，私有部署
}

// 项目状态
export enum AppState {
  DEFAULT = 0, // 正常
  RECYCLE = 3, // 回收站
  DELETE = 4, // 已删除
}
/**
 * 创建项目集请求参数
 */
export interface IAppSetCreateReq {
  name: string;
  teamID: string;
  appSetID?: string;
}

export enum AppDeviceType {
  Phone = 'phone',
  Web = 'web',
}

export function isAppSet(appType: AppTypes): boolean {
  return [AppTypes.AppSet].includes(appType);
}

/**
 * 创建项目
 */
export interface IAppCreateReq extends IAppSetCreateReq {
  /** 子项目使用 */
  // type: AppTypes;
  /** 子项目使用 end */
  // 项目类型 私有项目 共有项目
  visibility?: Visibility;
  appDeviceType?: IAppType;
  size?: ISize;
  orientation?: Orientation;
  idocAppID?: string;
}

export interface IApp extends Base {
  name: string;
  cover?: string;
  colorVal?: string;
  canvasBgColor?: string;
  linkColor?: string;
  stateRotationTime?: number;
  carouselEffect?: string;
  // 项目类型： phone | web
  // appType: string;
  teamID: string;
  appSetID: string;
  isExample: boolean;
  //index: number;
  homepage?: string;
  children?: Array<any>;
  recycle?: Array<any>;
  // 团队中的成员
  members: Array<number>;
  groups: Array<number>;
  // 被邀请加入的成员
  invitees: Array<number>;
  showControllerPanel?: boolean;
  showToolbar?: boolean;
  alwaysShowLinkArea?: boolean;
  showLinkAreaWhenHovered?: boolean;
  shareID: string;
  isArchived?: boolean;
  autoScreen?: boolean;
  // 为了兼容idoc预留字段，方便关联查询
  ownerAppID?: string;
  // 归档
  archivedAt?: Date;
  archivedBy?: number;
  // 客户端文件的 hash
  pinMode: string;
  // 是否允许非管理员分享项目
  allowShare: boolean;
  // 是否开启页面编号
  showRPPageNumber?: boolean;
  device: {
    name: string;
    size: {
      width: number;
      height: number;
    };
  };
  type: AppTypes;
  size: {
    height: number;
    width: number;
  };
  dataURL: string;
  imageURL: string;
  hmsr?: string;
  visibility: Visibility;
  canMoveAppInPathNode?: boolean;
  isStar?: boolean;
  accessedAt?: string | Date;
  rpAccessedAt?: Date;
  // 是否开启演示带密码
  previewNeedPassword?: boolean;
  libs?: string[];
  latestIdocAppID?: string;
  latestPrototypeID?: string;
  // 发布记录
  idocApps?: IIdocApp[];
  classify?: AppClassify;
  pinyinName?: string;
}

export interface IIdocApp {
  _id: string;
  id: string;
  prototypeID: string;
  prototypeName: string;
  name: string;
  pubAt: Date | string;
}

export interface IAppF extends IApp {
  _id: string;
}

// TODO: IAppInfo的字段定义是否准确，是否有需要删除的?
export interface IAppInfo extends IAppF {
  createUserName?: string;
  archivedByName?: string;
  createUserIcon?: string;
  pinyinName?: string;
  isStar?: boolean;
  groupCount?: number;
  pageCount?: number;
  userName?: string;
  memberCount?: number;
  larkToken?: string;
  isAppSet?: boolean;
  storyboard?: {
    canvasBgColor?: string;
    linkColor?: string;
    stateRotationTime?: number;
    carouselEffect?: string;
  };
  author?: {
    userID: number;
    name?: string;
    avatar?: string;
  };
  hasAuth?: boolean;
}

export interface IAppBasicInfo {
  _id: string;
  name: string;
  teamID: string;
  userID: number;
  cover?: string;
  members?: Array<number>;
  appSetID: string;
  groups?: Array<number>;
  isAppSet?: boolean;
}

// 获取团队所有项目时每个项目的信息
export interface IAppsOfTeam extends IAppBasicInfo {
  // 访问时间
  accessedAt?: string | Date;
  // 是否支持分享
  allowShare: boolean;
  // 项目封面
  cover: string;
  // 创建人名称
  createUserName: string;
  // 创建时间
  createdAt: string;
  // 项目设备类型及尺寸
  device?: {
    size: {
      width: number;
      height: number;
    };
    name: string;
  };
  // 是否归档
  isArchived: boolean;
  // 是否例子项目
  isExample: boolean;
  // 项目名称
  name: string;
  // 拼音名称
  pinyinName: string;
  // 项目分享ID
  shareID: string;
  // 状态： 正常|删除在回收站|彻底删除
  state: number;
  // 索引位置，用于拖拽排序
  index: number;
  //团队ID
  teamID: string;
  // 类型： 项目|项目集
  type: AppTypes;
  // 最近更新时间
  updatedAt: string;
  // 最近更新人ID
  updatedBy: number;
  // 创建人ID
  userID: number;
  // 团队公开状态
  visibility: Visibility;
  _id: string;
  // 是否收藏状态
  isStar: boolean;
  children?: IAppsOfTeam[];
  // 归档时间
  archivedAt?: Date;
  // 归档人ID
  archivedBy?: number;
  // 归档人名称
  archivedByName?: string;
  // 分享演示密码开启关闭
  previewNeedPassword?: boolean;
  // 发布记录
  idocApps?: IIdocApp[];
}

export interface IAppAppSetF extends IAppBasicInfo {
  accessedAt: string;
  allowShare: boolean;
  cover: string;
  createUserName: string;
  createdAt: string;
  isArchived: boolean;
  name: string;
  pinyinName: string;
  // source: string;
  index: number;
  // tags: string[];
  teamID: string;
  type: AppTypes;
  updatedAt: string;
  updatedBy: number;
  userID: number;
  visibility: Visibility;
  _id: string;
  isStar: boolean;
  children: IAppsOfTeam[];
  archivedAt?: Date;
  archivedBy?: number;
  rpAccessedAt?: Date;
}
export interface IAppsOfRecentEdit extends IAppBasicInfo {
  children?: IAppBasicInfo;
  type?: string;
  visibility?: string;
}
export interface IAllAppAppSetF {
  apps: IAppAppSetF[];
}

// 是否是普通的项目，非子项目，非项目集
export function isRegularApp(appType: AppTypes): boolean {
  return [AppTypes.RP].includes(appType);
}

export interface ICloneAppSlimInfoF extends IAppAppSetF {
  //原项目id
  originAppID?: string;
}
