export abstract class BaseCommand {
  /**
   * 为 undefined 时，说明 action 中没有执行有效的操作
   */
  actionInfo: any;
  abstract action(): any;
  abstract execute(): any;
  abstract undo(): any;
  abstract redo(): any;
}
