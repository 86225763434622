import IArtboard, { IArtboardAddPatch } from '@fbs/rp/models/artboard';
import INode, { NodeType, INodeAddPatch } from '@fbs/rp/models/node';
import { OffLineOperation, OperationType, PageIOPatches } from '@fbs/rp/models/io';
import { ErrorCode } from '@/fbs/idoc/consts/ErrorCode';
import { IAppF } from '@/fbs/rp/models/app';
import { Operation } from '@/fbs/rp/utils/patch';

import { saveAppPatches } from '@apis/app';

import CoreEditor from '@/editor/core';

import { createArtboard, ICreateArtboardInfo } from '@editor/comps/factory';
import { generateRandomStr } from '@/helpers/idHelper';
import { mergePageOperations } from '@/helpers/patchHelper';

import { IAppDataModel } from './types/appData.type';
import { AppDataModel } from './models/appData.model';
import { SyncLocalStorage } from './dataLegacy/syncLocalStorage';
import store from '@/store';
import { GlobalThunkActions } from '@/store/actions';
import { CustomError } from '@/fbs/common/models/error';
import { getOrientationFromSize } from '@/helpers/artboardDimensionHelper';

export type IOHandlers = {
  addArtboard: (data: IArtboardAddPatch) => Promise<IArtboard>;
  pushNodeAddPatch: (data: INodeAddPatch) => Promise<INode>;
  pushPagePatch: (
    data: PageIOPatches,
  ) => Promise<{
    code: number;
    message?: string;
  }>;
};

// socket推送时，后台响应错误
export class SocketResponseError extends CustomError {
  constructor(public type: OperationType, e: CustomError) {
    super(e.message, e.code);
  }
}

// 离线项目数据管理器
class AppDataManager {
  // 缓存画板数据
  private _artboards: IArtboard[];
  // 缓存删除的画板，用于处理：删除画板再 undo 不成功的问题
  private _artboardRecycleBin: IArtboard[];
  // 保存所有的离线操作
  private _operations: OffLineOperation[];
  // 正在处理的队列
  private _appID: string;
  private _socketOffline: boolean;
  private _isSync: boolean;
  private ioFuncs: IOHandlers | null;
  private _isExample: boolean = false;
  private _appPageNum: number;
  private _isManualSaving: boolean;

  private _deviceID: string = this.getDeviceID();
  /**
   * 用于保存每个tab中对于数据的一个标识
   */
  private _patchIndex: number = 1;

  private _dataModel: IAppDataModel = new AppDataModel();

  // 页面首次同步离线数据，出错时，待推送到页面的数据
  private pendingPushOperations: OffLineOperation[] = [];
  constructor() {
    this.ioFuncs = null;
    this._artboards = [];
    this._artboardRecycleBin = [];
    this._appID = '';
    this._operations = [];
    this._socketOffline = false;
    this._isSync = false;
    this._isExample = false;
    this._appPageNum = 0;
    this._isManualSaving = false;
  }
  public setAppDataModel(appDataModel: IAppDataModel): void {
    this._dataModel = appDataModel;
  }

  private getDeviceID(): string {
    let value = localStorage.getItem('RP-IO-deviceId');
    if (!value) {
      value = generateRandomStr();
      localStorage.setItem('RP-IO-deviceId', value);
    }
    return value;
  }
  get socketOffline(): boolean {
    return this._socketOffline;
  }

  set socketOffline(value: boolean) {
    if (value) {
      console.log('%cOFFLINE', 'color: red;');
    } else {
      console.log('%cONLINE', 'color: green;');
    }
    this._socketOffline = value;
  }

  get isSync(): boolean {
    return this._isSync;
  }

  set isSync(value: boolean) {
    this._isSync = value;
  }

  get isExample(): boolean {
    return this._isExample;
  }

  set isExample(value: boolean) {
    this._isExample = value;
    value && (this._socketOffline = false);
  }

  get allOperations(): Readonly<OffLineOperation[]> {
    return this._operations;
  }

  get appPageNum(): number {
    return this._appPageNum;
  }

  set appPageNum(value: number) {
    this._appPageNum = value;
  }

  get isManualSaving(): boolean {
    return this._isManualSaving;
  }

  set isManualSaving(value: boolean) {
    this._isManualSaving = value;
  }

  public async init(appID: string, appPageNum: number, ioFuncs: IOHandlers): Promise<void> {
    if (this._appID && this._appID !== appID) {
      throw new Error(`Has been set app id to ${this._appID}.`);
    }
    this._appID = appID;
    this.ioFuncs = ioFuncs;
    this._appPageNum = appPageNum;

    await this.loadOperations();
  }

  private async loadOperations(): Promise<void> {
    //执行老数据同步
    await new SyncLocalStorage().sync();

    const operations: OffLineOperation[] = await this._dataModel.getCaches(this._appID);

    /**
     * 对数据进行分组排序
     */
    operations.sort((a, b) => {
      const maskValue = a.randomMask!.localeCompare(b.randomMask!);
      return maskValue != 0 ? maskValue : Number(a.patchIndex) - Number(b.patchIndex);
    });

    this._operations = operations;

    window.debug &&
      console.info(
        'load save this._operations = ',
        this._operations.map((item) => item.patchesID),
      );
  }

  private async saveOperation(patches: OffLineOperation): Promise<void> {
    //测试时使用
    // console.log('test', `storageID`, `offline-operations-${this._appID}`, JSON.stringify(patches));
    await this._dataModel.saveCache(this._appID, patches.patchesID!, JSON.stringify(patches));
  }

  private ensureInitd() {
    if (!this._appID) {
      throw new Error(`No App ID is initd.`);
    }
  }

  // 添加节点
  private sendAddNodeOperation(data: INodeAddPatch): INode {
    this.ensureInitd();
    if (data.type === NodeType.Page) {
      const size = data.size || {
        width: 100,
        height: 100,
      };
      const artboardInfo: ICreateArtboardInfo = {
        id: data.artboardID,
        appID: data.appID,
        nodeID: data._id,
        name: data.artboardName,
        position: {
          x: 0,
          y: 0,
        },
        size,
        orientation: getOrientationFromSize(size),
        userID: data.userID,
        type: 'main',
      };
      const artboardData = createArtboard(artboardInfo);
      this.pushArtboard([artboardData]);
    }
    return {
      appID: data.appID,
      name: data.name,
      type: data.type,
      artboardID: data.artboardID,
      path: data.path || 'ROOT',
      index: data.index,
      state: 0,
      userID: data.userID,
      updatedBy: data.userID,
      position: {
        x: 0,
        y: 0,
      },
      size: data.size,
      // ~~~~~
      color: '',
      createdAt: new Date(),
      hidden: false,
      icon: '',
      imageURL: '',
      links: [],
      updatedAt: new Date(),
      // __v: 0,
      _id: data._id,
    };
  }

  // 获取缓存的画板数据
  async getAllArtboardsByNodeID(nodeID: string): Promise<IArtboard[]> {
    return new Promise((resolve, reject) => {
      const artboards = this._artboards.filter((art) => art.nodeID === nodeID);
      if (artboards.length > 0) {
        resolve(artboards);
        return;
      }
      const error = new Error(/* i18n('alert.offlineFailedToGetData') */);
      // @ts-ignore
      error.code = 'offline-error';
      reject(error);
    });
  }

  private sendAddArtboardOperation(data: IArtboardAddPatch): IArtboard {
    const info: ICreateArtboardInfo = {
      id: data._id,
      appID: data.appID,
      nodeID: data.nodeID,
      name: data.name,
      position: data.position,
      size: data.size,
      orientation: data.orientation,
      ownerID: data.ownerID,
      userID: -1,
      type: 'fragment',
    };
    const artboard = createArtboard(info);
    this.pushArtboard([artboard]);
    return artboard;
  }

  pushArtboard(artboards: IArtboard[]) {
    artboards.forEach((item) => {
      const index = this._artboards.findIndex((a) => a._id === item._id && a.nodeID === item.nodeID);
      if (index !== -1) {
        this._artboards.splice(index, 1, item);
      } else {
        this._artboards.push(item);
      }
    });
  }

  /**
   * 将_artboards对应nodeID的全部更新
   */
  updateArtboardWithNodeID(nodeID: string, artboards: IArtboard[]) {
    this._artboards
      .filter((item) => item.nodeID === nodeID)
      .forEach((item) => {
        const { nodeID, _id } = item;
        // 在新的同 nodeID 的 artboards 里没找到 item，说明此 item 被删了，
        // 删除item，并添加到 _artboardsRecycleBin
        const notFindItemInNewArtboardsData = !artboards.find((artboard) => artboard._id === _id);
        if (notFindItemInNewArtboardsData) {
          const removedArtboards = this.removeArtboard(nodeID, _id);
          this.recycleArtboards(removedArtboards);
        }
      });
    // 处理替换的，新增的画板
    this.pushArtboard(artboards);
  }

  private removeArtboard(nodeID: string, artboardID: string) {
    const index = this._artboards.findIndex((a) => a._id === artboardID && a.nodeID === nodeID);
    if (index !== -1) {
      return this._artboards.splice(index, 1);
    }
  }

  private recycleArtboards(removedArtboards: IArtboard[] | undefined) {
    if (removedArtboards) {
      this._artboardRecycleBin.push(...removedArtboards);
    }
  }

  public async getArtboardByID(artboardID: string): Promise<IArtboard> {
    return new Promise((resolve, reject) => {
      const artboard =
        this._artboards.find((art) => art._id === artboardID) ??
        this._artboardRecycleBin.find((artboard) => artboard._id === artboardID); // 有可能是删除的 undo，再从画板回收站里找

      if (artboard) {
        resolve(artboard);
        return;
      }

      reject(new Error(/* i18n('alert.offlineFailedToGetData') */));
    });
  }

  hasOperationToHandle(): boolean {
    return this._operations.length !== 0;
  }

  public async handleOperation(operation: OffLineOperation): Promise<INode | IArtboard | void> {
    this.ensureInitd();
    const newData: OffLineOperation = {
      ...operation,
      randomMask: Date.now().toString(36),
      patchIndex: this._patchIndex++,
      patchesID: generateRandomStr(),
      deviceID: this._deviceID,
    };

    this._operations.push(newData);
    if (!this.isExample) {
      await this.saveOperation(newData);
      /**
       * 这里加上await，因为要捕捉 409 页面数量超限错误
       */
      await this.silentHandleOperations();
    }

    return this.handleOtherOperationResult(operation);
  }

  private handleOtherOperationResult(operation: OffLineOperation): INode | IArtboard | void {
    switch (operation.type) {
      case OperationType.AddArtboard:
        return this.sendAddArtboardOperation(operation.data);
      case OperationType.AddNode:
        ++this._appPageNum;
        return this.sendAddNodeOperation(operation.data);
      case OperationType.PushArtboardPatches:
        return;
      default:
        throw new Error(`invalid operation: ${operation}`);
    }
  }

  private async silentHandleOperations(): Promise<void> {
    if (this.socketOffline || this.isSync || this._operations.length === 0 || this.isExample) {
      return;
    }

    this.isSync = true;
    let isError = false;
    try {
      await this.handleFirstOperation();
    } catch (e) {
      console.warn(e);
      isError = true;

      // 409 页面数量限制要抛出去，不能静默
      if (e.code === ErrorCode.TeamAppPageLimit) {
        throw e;
      }
    } finally {
      this.isSync = false;
    }

    if (!isError && this._operations.length > 0) {
      await this.silentHandleOperations();
    }
  }

  private async handleFirstOperation(): Promise<void> {
    this.ensureInitd();

    const operation = this._operations[0];
    if (!operation) {
      return;
    }

    const removeOperation = async (operation: OffLineOperation) => {
      await this._dataModel.removeCache(this._appID, operation.patchesID!);
      const tmpOperation = this._operations.shift();
      if (tmpOperation != operation) {
        console.error('this.operations.shift() error.');
      }
    };

    switch (operation.type) {
      case OperationType.AddArtboard:
        try {
          await this.ioFuncs!.addArtboard(operation.data);
          await removeOperation(operation);
        } catch (e) {
          // 重复写入同一个画板 ID 的数据，忽略该操作
          if (e.message.startsWith('E11000')) {
            await removeOperation(operation);
          } else {
            throw new SocketResponseError(OperationType.AddArtboard, e);
          }
        }
        break;
      case OperationType.AddNode:
        try {
          await this.ioFuncs!.pushNodeAddPatch(operation.data);
          await removeOperation(operation);
        } catch (e) {
          if (e.message.startsWith('E11000')) {
            await removeOperation(operation);
          } else {
            if (e.code === ErrorCode.TeamAppPageLimit) {
              await removeOperation(operation);
            }

            throw new SocketResponseError(OperationType.AddNode, e);
          }
        }
        break;
      case OperationType.PushArtboardPatches:
        try {
          await this.ioFuncs!.pushPagePatch({
            ...operation.data,
            patchesID: operation.patchesID,
            deviceID: operation.deviceID,
          });
          await removeOperation(operation);
        } catch (e) {
          throw new SocketResponseError(OperationType.PushArtboardPatches, e);
        }
        break;
      default:
        throw new Error('not supported!');
    }
  }

  /**
   * 将当前离线数据的页面，添加到当前项目中
   * @memberof AppDataManager
   */
  public pushOfflinePageDataToApp(app: IAppF) {
    if (!this.pendingPushOperations.length) {
      return;
    }
    const pendingPushOperations: Array<OffLineOperation | null> = this.pendingPushOperations;
    try {
      for (let i = 0, len = pendingPushOperations.length; i < len; i++) {
        const operation = pendingPushOperations[i];
        if (operation?.type === OperationType.AddNode && operation.data.appID === app._id) {
          const newNode = this.sendAddNodeOperation(operation.data);
          const siblingPages = app.children.filter((d) => d.path === newNode.path && d.state === 0);
          if (siblingPages.length && newNode.index < siblingPages.length) {
            const siblingPage = siblingPages[newNode.index];
            const newIndex = app.children.findIndex((p) => p._id === siblingPage._id);
            app.children.splice(newIndex, 0, newNode);
          } else {
            app.children.push(newNode);
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.pendingPushOperations = pendingPushOperations.filter(Boolean) as OffLineOperation[];
    }
  }

  /**
   * 将离线操作到文档中，更新前端组件
   * @param {CoreEditor} coreEditor
   * @param {string} pageID
   * @memberof AppDataManager
   */
  public pushOperationToDocument(coreEditor: CoreEditor, pageID: string) {
    if (!this.pendingPushOperations.length) {
      return;
    }
    let pendingPushOperations: Array<OffLineOperation | null> = this.pendingPushOperations.slice();
    const pageOperations = {};
    try {
      // 先处理添加辅助画板,到离线区
      for (let i = 0, len = pendingPushOperations.length; i < len; i++) {
        const operation = pendingPushOperations[i] as OffLineOperation;
        if (
          operation.type === OperationType.AddArtboard &&
          operation.data.appID === this._appID &&
          operation.data.nodeID === pageID
        ) {
          const newArtboardData = this.sendAddArtboardOperation(operation.data);
          coreEditor.doc.addArtboard(newArtboardData);
          // 将后续的Patches添加ROOT
          pendingPushOperations[i] = null;
        }
      }
      pendingPushOperations = pendingPushOperations.filter(Boolean) as OffLineOperation[];
      // 再处理画板的patches
      for (let i = 0, len = pendingPushOperations.length; i < len; i++) {
        const operation = pendingPushOperations[i] as OffLineOperation;

        if (
          operation.type === OperationType.PushArtboardPatches &&
          operation.data.appID === this._appID &&
          operation.data.nodeID === pageID
        ) {
          const patches = { ...operation.data.patches };
          //  过滤掉添加辅助画板的操作，添加的操作在上面已处理过
          if (patches.ROOT && Array.isArray(patches.ROOT.self)) {
            patches.ROOT.self = patches.ROOT.self.filter((operation: Operation) => operation.op !== 'add');
            if (!patches.ROOT.self.length) {
              delete patches.ROOT;
            }
          }
          mergePageOperations(pageOperations, patches);
          pendingPushOperations[i] = null;
        }
      }
      coreEditor.patch(pageOperations);
    } catch (e) {
      console.error(e);
    } finally {
      this.pendingPushOperations = pendingPushOperations.filter(Boolean) as OffLineOperation[];
    }
  }
  public socketSyncError = false;
  // 等待将离线操作同步到云端
  public async waitForSyncOperations(isReconnect: boolean) {
    try {
      // const isExistSync=!!this._operations.length;
      while (this._operations.length !== 0) {
        (this._operations[0].data as PageIOPatches).patchOffline = true; // 如果是离线数据提交，就标识为离线
        await this.handleFirstOperation();
      }
      this.socketSyncError = false;
      if (!store.getState().global.socketAvailable) {
        store.dispatch<any>(GlobalThunkActions.thunkSocketStateChange(true));
      }
    } catch (e) {
      // 如果页面第一次加载，socket同步离线数据失败，将当前离线数据保存到待推送区
      // 等待页面加载时，将页面待推送数据，自动更新到页面
      if (!isReconnect && e instanceof SocketResponseError) {
        // 将提交失败的数据，转移到待推送页面显示
        this.pendingPushOperations = this._operations.slice();
        this.socketSyncError = true;
        if (store.getState().global.socketAvailable) {
          store.dispatch<any>(GlobalThunkActions.thunkSocketStateChange(false));
        }
      }
      throw e;
    }
  }

  public clearOperation() {
    // TODO: marsh 在清理所有操作过程中，暂时先将数据进行屏蔽操作
    this._operations.forEach(async (value) => {
      await this._dataModel.removeCache(this._appID, value.patchesID!);
    });

    this._operations = [];
  }

  public saveAppPatches(
    appID: string,
  ): Promise<{
    isSaved: boolean;
  }> {
    const newOperations = this._operations.map((item) => {
      if (item.type === OperationType.PushArtboardPatches) {
        return {
          ...item,
          data: {
            ...item.data,
            deviceID: item.deviceID,
            patchesID: item.patchesID,
          },
        };
      }
      return item;
    });
    return saveAppPatches(appID, newOperations);
  }
}

export const appDataManager = new AppDataManager();
