import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// const basePosition = '(sh>=60?20:sh*(20/60))';
const basePosition = '(Math.min(sw,sh)*(20/60))';

// 上下箭头
export default {
  ...flowBase,
  name: i18n('resource.flow.verticalDoubleArrow'),
  type: 'verticalDoubleArrow',
  thumb: {
    spriteIconClass: SpriteThumb.VerticalDoubleArrow.className,
    dragPosition: SpriteThumb.VerticalDoubleArrow.position,
  },
  template: getShapeData(
    i18n('resource.flow.verticalDoubleArrow'),
    {
      width: 40,
      lockedRatio: false,
      height: 60,
    },
    {
      data: [
        {
          point: {
            x: 12,
            y: 20,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 12,
            y: 40,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 40,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 20,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 40,
            y: 40,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 28,
            y: 40,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 28,
            y: 20,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 40,
            y: 20,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 20,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 20,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
      closed: true,
      isTemplateShape: true,
      templateData: [
        {
          point: {
            x: 'sw*0.3',
            y: basePosition,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 'sw*0.3',
            y: `sh-${basePosition}`,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: `sh-${basePosition}`,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 'sw/2',
            y: 'sh',
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 'sw',
            y: `sh-${basePosition}`,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 'sw*0.7',
            y: `sh-${basePosition}`,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 'sw*0.7',
            y: basePosition,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 'sw',
            y: basePosition,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 'sw/2',
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: basePosition,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
