/**
 * 简单实现element显示隐藏的动画效果
 */
import * as React from 'react';
import classNames from 'classnames';

interface TransitionProps {
  children: React.ReactElement;
  visible: boolean;
  canRunAnimation?: boolean; // 是否可执行动画
  duration?: number; // 过渡效果执行时间
  enterClassName: string; // 进入className
  leaveClassName: string; // 离开className
}

const defaultDuration = 150;

const Transition: React.FC<TransitionProps> = (props) => {
  const [isShow, setIsShow] = React.useState(props.visible);
  const [curClassName, setCurClassName] = React.useState(props.visible ? props.leaveClassName : props.enterClassName);
  const timerRef = React.useRef<Timeout | null>(null);

  const setTimer = (cb: Function, time: number) => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      cb();
    }, time);
  };

  React.useEffect(() => {
    if (props.visible) {
      // 从隐藏到展示，先设置展示，dom加载完添加enterClassName
      setIsShow(true);
      setTimer(() => setCurClassName(props.enterClassName), 5);
    } else {
      // 展示到隐藏，动画执行完成后，设置移除
      setCurClassName(props.leaveClassName);
      setTimer(() => setIsShow(false), props.duration || defaultDuration);
    }
  }, [props.visible]);

  if (props.canRunAnimation === false) return props.children;

  return isShow
    ? React.cloneElement(props.children, {
        className: classNames(props.children?.props?.className, curClassName),
      })
    : null;
};

export default Transition;
