import * as React from 'react';
import classnames from 'classnames';

import { ITableColumn, ITableColumnAlign, ITableSortOrder, TableSelection } from '../../../models';
import { TableSortOrder } from '../../../constants';

import TableStepper from './TableStepper';

interface ITableHeaderProps {
  columns: ITableColumn[];
  rowSelectionType: TableSelection;
  cellStyle: React.CSSProperties;
  onSort?(dataKey: string, sort: ITableSortOrder): void;
}

const TableHeader: React.FC<ITableHeaderProps> = ({
  columns,
  rowSelectionType,
  cellStyle,
  onSort,
}: ITableHeaderProps) => {
  const [sortIndex, setSortIndex] = React.useState('');
  const [sortOrder, setSortOrder] = React.useState<TableSortOrder>(TableSortOrder.NONE);

  const onClick = (column: ITableColumn) => {
    if (!column.sort) {
      return;
    }
    if (sortIndex !== column.dataIndex) {
      setSortIndex(column.dataIndex);
      setSortOrder(TableSortOrder.ASC);
      onSort && onSort(column.dataIndex, TableSortOrder.ASC);
      return;
    }
    const order = getSortOrder();
    setSortIndex(column.dataIndex);
    setSortOrder(order);
    onSort && onSort(column.dataIndex, order);
  };

  const getSortOrder = (): TableSortOrder => {
    switch (sortOrder) {
      case TableSortOrder.ASC:
        return TableSortOrder.DESC;
      case TableSortOrder.DESC:
        return TableSortOrder.NONE;
      case TableSortOrder.NONE:
        return TableSortOrder.ASC;
      default:
        throw new Error('sort order error.');
    }
  };

  const renderSelection = (maxRow: number, rowIndx: number) => {
    if (rowIndx > 0 || rowSelectionType === TableSelection.None) {
      return null;
    }
    switch (rowSelectionType) {
      case TableSelection.Checkbox:
        return <th key="checkbox" rowSpan={maxRow} className={`c-table-column-${ITableColumnAlign.Center}`}></th>;
      default:
        break;
    }
  };

  const getMergeTitleByIndex = (column: ITableColumn, rowIndex: number) => {
    return column.parentText && column.parentText[rowIndex] ? column.parentText[rowIndex] : rowIndex > 0 && column.text;
  };

  const renderHeader = (maxRow = 1, rowIndex = 0) => {
    return columns.map((col, index) => {
      if (col.parentText && col.parentText.length) {
        // 渲染合并头 合并头为第一行空字符时不作处理
        const currentText = getMergeTitleByIndex(col, rowIndex);
        // 前一行数据
        const preText = index > 0 && getMergeTitleByIndex(columns[index - 1], rowIndex);
        // 需要合并长度 colSpan = 1 则为渲染正常头部
        const colSpan = columns
          .slice(index, columns.length)
          .filter((column) => column.parentText && column.parentText[rowIndex] === currentText).length;
        // 相同数据即需要合并的头部 不渲染需要合并的列
        if (preText === currentText) {
          return null;
        }
        const siblings = columns.filter(
          (item) => item?.parentText && col?.parentText && item.parentText[0] === col.parentText[0],
        );

        return (
          <th
            key={col.id}
            colSpan={colSpan}
            className={classnames(`c-table-column-${col.align || ITableColumnAlign.Left}`, {
              'c-table-column-first': index === 0 && rowSelectionType !== TableSelection.None,
              'c-table-column-merge': colSpan > 1,
              'c-table-column-sortable': colSpan <= 1 && col.sort,
              'c-table-column-merge-child-first': siblings.indexOf(col) === 0,
              'c-table-column-merge-child-last': siblings.indexOf(col) === siblings.length - 1,
            })}
            style={{ ...cellStyle, ...col.style, ...col.thStyle }}
            onClick={() => colSpan <= 1 && onClick(col)}
          >
            <div className="c-table-column-item">
              <div className="c-table-column-item-text">{currentText}</div>
              {col.sort && currentText === col.text && (
                <TableStepper order={sortIndex === col.dataIndex ? sortOrder : TableSortOrder.NONE} />
              )}
            </div>
          </th>
        );
      } else if (rowIndex === 0) {
        if (col.filterIcon) {
          return (
            <th
              key={col.id}
              rowSpan={maxRow}
              className={classnames(`c-table-column-${col.align || ITableColumnAlign.Left}`, {
                'c-table-column-first': index === 0 && rowSelectionType !== TableSelection.None,
                'c-table-column-sortable': col.sort,
              })}
              style={{ ...cellStyle, ...col.style, ...col.thStyle }}
              // onClick={() => onClick(col)}
            >
              {col.filterIcon}
            </th>
          );
        }
        // 正常渲染
        return (
          <th
            key={col.id}
            rowSpan={maxRow}
            className={classnames(`c-table-column-${col.align || ITableColumnAlign.Left}`, {
              'c-table-column-first': index === 0 && rowSelectionType !== TableSelection.None,
              'c-table-column-sortable': col.sort,
            })}
            style={{ ...cellStyle, ...col.style, ...col.thStyle }}
            onClick={() => onClick(col)}
          >
            <div className="c-table-column-item">
              <div className="c-table-column-item-text">{col.text}</div>
              {col.sort && (
                <TableStepper
                  order={sortIndex === col.dataIndex ? sortOrder : TableSortOrder.NONE}
                  sortIcon={col.sortIcon}
                />
              )}
            </div>
          </th>
        );
      }
    });
  };

  const maxRow = Math.max(...columns.map((column) => column.parentText?.length || 0)) + 1;
  return (
    <thead className={'c-table-thead'}>
      {new Array(maxRow).fill(1).map((_, index) => (
        <tr key={`c-table-header-row-${index}`}>
          {renderSelection(maxRow, index)}
          {renderHeader(maxRow, index)}
        </tr>
      ))}
    </thead>
  );
};

export default TableHeader;
