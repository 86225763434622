import CoreSelect from '@editor/corePartial/select';
import { ArtboardOperations, ArtboardPatches, ComponentPatches } from '@fbs/rp/utils/patch';
import { getNewID } from '@helpers/idHelper';
import { UIComponent } from '../comps';
import { PredefinedStates } from '@consts/state';

interface ISwitchStates {
  comp: UIComponent;
  stateID?: string;
}

/**
 * 组件状态管理部分
 */
export default abstract class CoreState extends CoreSelect {
  /**
   * 添加自定义状态
   * @param {string} stateID
   * @param {string} stateName 状态名称
   */
  addState(stateID: string, stateName: string) {
    if (!this.hasOnlyOneSelectedComponents || this.hasSelectLockedComps) {
      return;
    }
    const comp = this.firstSelectedComponent!;
    const op = comp.addState(stateID, stateName);
    if (op) {
      const patches: ArtboardPatches = {
        do: {
          [comp.id]: op.do,
        },
        undo: {
          [comp.id]: op.undo,
        },
      };
      this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
      this.switchState([{ comp, stateID }]);
    }
  }

  /**
   * 删除自定义状态
   * @param {string} stateID 要删除的状态ID
   * @param {string} autoSelectStateID 删除后，自动选择的下一个状态的ID，可以为空
   */
  removeState(stateID: string, autoSelectStateID?: string) {
    if (!this.hasOnlyOneSelectedComponents || this.hasSelectLockedComps) {
      return;
    }
    const comp = this.firstSelectedComponent!;
    let op: ComponentPatches | undefined;
    op = comp.removeState(stateID);
    if (op) {
      const patches: ArtboardPatches = {
        do: {
          [comp.id]: op.do,
        },
        undo: {
          [comp.id]: op.undo,
        },
      };
      this.switchState([{ comp, stateID: autoSelectStateID }]);
      this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
    }
  }

  restoreSelectedCompState() {
    const artboard = this.doc.artboards.find((fragment) => fragment._id === this.activeArtboard.artboardID);
    if (!artboard) {
      return;
    }
    const states = this.selectedComponentList.map((comp) => {
      let stateID: string | undefined = undefined;
      if (comp.selected) {
        stateID = PredefinedStates.checked;
      } else if (comp.disabled) {
        stateID = PredefinedStates.disabled;
      }
      return {
        comp,
        stateID,
      };
    });
    this.switchState(states);
  }
  /**
   * 状态切换，用于查看组件某个状态的外观
   * @param {ISwitchStates} states 目标状态ID
   */
  switchState(states: ISwitchStates[]) {
    if (!states.length) {
      return;
    }
    const patches: ArtboardOperations = states.reduce((prev: ArtboardOperations, curr: ISwitchStates) => {
      const { comp, stateID } = curr;
      const op = comp.switchState(stateID);
      return {
        ...prev,
        ...op,
        // [comp.id]: op,
      };
    }, {});
    this.patch({ [this.activeArtboard.artboardID]: patches });
  }

  get currentStateID(): string {
    const comp = this.firstSelectedComponent;
    if (comp) {
      return comp.currentStateID || '';
    }
    return '';
  }

  /**
   * 修改状态名称
   * @param {string} stateID 要修改的状态的ID
   * @param {string} newName 新名称
   */
  modifyStateName(stateID: string, newName: string) {
    if (this.hasOnlyOneSelectedComponents && !this.hasSelectLockedComps) {
      const comp = this.firstSelectedComponent!;
      const patches = comp.modifyStateName(stateID, newName);
      this.updateSingleArtboard(this.activeArtboard.artboardID, {
        do: {
          [comp.id]: patches.do,
        },
        undo: {
          [comp.id]: patches.undo,
        },
      });
    }
  }

  // 切换状态启用状态
  changeStateEnabled(stateID: string, enabled: boolean) {
    const comps = this.selectedCompsExcludeConnector;
    const patches = comps.reduce(
      (prev: ArtboardPatches, comp: UIComponent) => {
        const patch = comp.changeStateEnabled(stateID, enabled);
        if (patch?.do.length) {
          prev.do[comp.id] = patch.do;
        }
        if (patch?.undo.length) {
          prev.undo[comp.id] = patch.undo;
        }
        return prev;
      },
      {
        do: {},
        undo: {},
      },
    );
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  /**
   * 克隆状态
   * @param {string} stateID
   * @param {string|undefined} newStateID
   */
  cloneState(stateID: string, newStateID?: string) {
    if (!this.hasOnlyOneSelectedComponents || this.hasSelectLockedComps) {
      return;
    }
    const comp = this.firstSelectedComponent!;
    const newID = newStateID || getNewID();
    const patch = comp.cloneState(stateID, newID);
    this.updateSingleArtboard(this.activeArtboard.artboardID, {
      do: {
        [comp.id]: patch.do,
      },
      undo: {
        [comp.id]: patch.undo,
      },
    });
    this.switchState([{ comp, stateID: newID }]);
  }

  /**
   * 设置默认状态
   * @param {string} stateID
   */
  resetDefaultState(stateID?: string) {
    const comps = this.selectedCompsExcludeConnector;
    const patches: ArtboardPatches = comps.reduce(
      (prev: ArtboardPatches, comp: UIComponent) => {
        const patch = comp.setDefaultState(stateID);
        if (patch.do.length) {
          prev.do[comp.id] = patch.do;
          prev.undo[comp.id] = patch.undo;
        }
        return prev;
      },
      {
        do: {},
        undo: {},
      },
    );
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }
}
