import { ITableValue, CellTypes } from '@fbs/rp/models/table';
import { ICellData } from '@fbs/rp/models/table';

import { DefalutCellStyle } from '@consts/defaultData/table';

const defaultCellData = (type: CellTypes, text: string = ''): ICellData => {
  return {
    text,
  };
};

export const DefalutTableValue: ITableValue = {
  showHeader: false,
  headerHeight: 30,
  columns: [
    {
      type: CellTypes.Text,
      style: DefalutCellStyle,
      width: 80,
      data: { text: '' },
      dataSource: [],
      mergeAcross: 0,
      mergeDown: 0,
    },
    {
      type: CellTypes.Text,
      style: DefalutCellStyle,
      width: 80,
      data: { text: '' },
      dataSource: [],
      mergeAcross: 0,
      mergeDown: 0,
    },
    {
      type: CellTypes.Text,
      style: DefalutCellStyle,
      width: 80,
      data: { text: '' },
      dataSource: [],
      mergeAcross: 0,
      mergeDown: 0,
    },
    {
      type: CellTypes.Text,
      style: DefalutCellStyle,
      width: 80,
      data: { text: '' },
      dataSource: [],
      mergeAcross: 0,
      mergeDown: 0,
    },
  ],
  rows: [
    {
      height: 30,
      selected: false,
    },
    {
      height: 30,
      selected: false,
    },
    {
      height: 30,
      selected: false,
    },
    {
      height: 30,
      selected: false,
    },
  ],
  cells: [
    [
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergedBy: undefined,
        mergeAcross: 0,
        mergeDown: 0,
      },
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergeAcross: 0,
        mergeDown: 0,
      },
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergeAcross: 0,
        mergeDown: 0,
      },
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergeAcross: 0,
        mergeDown: 0,
      },
    ],
    [
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergeAcross: 0,
        mergeDown: 0,
      },
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergeAcross: 0,
        mergeDown: 0,
      },
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergeAcross: 0,
        mergeDown: 0,
      },
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergeAcross: 0,
        mergeDown: 0,
      },
    ],
    [
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergeAcross: 0,
        mergeDown: 0,
      },
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergeAcross: 0,
        mergeDown: 0,
      },
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergeAcross: 0,
        mergeDown: 0,
      },
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergeAcross: 0,
        mergeDown: 0,
      },
    ],
    [
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergeAcross: 0,
        mergeDown: 0,
      },
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergeAcross: 0,
        mergeDown: 0,
      },
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergeAcross: 0,
        mergeDown: 0,
      },
      {
        type: CellTypes.Text,
        data: defaultCellData(CellTypes.Text, ''),
        dataSource: [],
        style: DefalutCellStyle,
        mergeAcross: 0,
        mergeDown: 0,
      },
    ],
  ],
};

export const defaultCol = {
  type: CellTypes.Text,
  style: DefalutCellStyle,
  width: 80,
  data: { text: '' },
  dataSource: [],
  mergeAcross: 0,
  mergeDown: 0,
};

export const defaultRow = {
  height: 30,
  selected: false,
};

export const defaultCell = {
  type: CellTypes.Text,
  data: defaultCellData(CellTypes.Text, ''),
  dataSource: [],
  style: DefalutCellStyle,
  mergedBy: undefined,
  mergeAcross: 0,
  mergeDown: 0,
};

export const defaultCellTextProperties = {
  radius: {
    topRight: 0,
    topLeft: 0,
    bottomLeft: 0,
    bottomRight: 0,
    isPercent: false,
    disabled: false,
  },
  fill: {
    type: 'solid',
    color: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
    disabled: true,
  },
  stroke: {
    disabled: true,
    thickness: 1,
    color: {
      r: 119,
      g: 119,
      b: 119,
      a: 1,
    },
    cap: 'round',
    join: 'round',
  },
  border: {
    left: true,
    right: true,
    top: true,
    bottom: true,
  },
  shadow: {
    disabled: true,
    hidden: false,
    x: 0,
    y: 3,
    blur: 6,
    color: {
      r: 0,
      g: 0,
      b: 0,
      a: 0.15,
    },
  },
  textFormat: {
    fontFamily: 'Microsoft YaHei',
    fontStyle: {
      underline: false,
      bold: false,
      strike: false,
      italic: false,
    },
    fontSize: 14,
    color: {
      r: 51,
      g: 51,
      b: 51,
      a: 1,
    },
    textAlign: 'left',
    wrap: true,
    isMulti: true,
    verticalAlign: 'middle',
    lineHeightEx: 20,
  },
  tooltips: {
    name: '提示文字',
    prop: 'string',
    value: '',
  },
};
