import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { getNewID } from '@/helpers/idHelper';

import { CRoundRectangle } from '../constants';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';
import { makeRect } from '../basic/Rect/config';

export const makeRoundRectangle = () => {
  const compData = makeRect(getNewID(), {
    name: i18n('resource.flow.roundRectangle'),
    size: {
      width: 100,
      lockedRatio: false,
      height: 60,
    },
  });
  compData.properties.radius = {
    disabled: false,
    topLeft: 5,
    topRight: 5,
    bottomRight: 5,
    bottomLeft: 5,
    isPercent: false,
  };

  return compData;
};

// 圆角矩形
export default {
  ...flowBase,
  name: i18n('resource.flow.roundRectangle'),
  type: CRoundRectangle,
  thumb: {
    spriteIconClass: SpriteThumb.RoundRectangle.className,
    dragPosition: SpriteThumb.RoundRectangle.position,
  },
  template: getShapeData(
    i18n('resource.flow.roundRectangle'),
    {
      width: 100,
      lockedRatio: false,
      height: 60,
    },
    {
      data: [
        {
          point: { x: 0, y: 0 },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
          radius: 5,
        },
        {
          point: { x: 100, y: 0 },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
          radius: 5,
        },
        {
          point: { x: 100, y: 60 },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
          radius: 5,
        },
        {
          point: { x: 0, y: 60 },
          handleIn: { x: 0, y: 0 },
          handleOut: { x: 0, y: 0 },
          radius: 5,
        },
      ],
      closed: true,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
