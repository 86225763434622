import { Matrix } from '@/utils/matrixUtils';
import { offsetPoint } from '@/utils/boundsUtils';
import { IMakeTransformOptions, ISize } from '../type';
import { calcCenter, getCenterOffsetInViewportAfterRotate } from '../util/boundsUtil';

export const toSVGTransform = (matrix?: Matrix): string => {
  // SVGMatrix
  // [a c e]
  // [b d f]
  if (matrix) {
    const { m11, m12, m21, m22, m31, m32 } = matrix;
    return `transform ="matrix(${[m11, m12, m21, m22, m31, m32].join(' ')})"`;
  }
  return '';
};

export const makeTransform = (options: IMakeTransformOptions): string => {
  const { rotate, size, isRoot, outerBounds } = options;
  const result = [];

  const { x, y } = calcPosition(options);
  if (x || y) {
    result.push(`translate(${x}, ${y})`);
  }

  // 旋转
  if (rotate) {
    const { width = 0, height = 0 } = size ?? {};
    if (isRoot && outerBounds) {
      const rotateOffset = calcRotateOffset({ ...options, position: { x, y } });

      result.push(`rotate(${rotateOffset?.rotate})`);
      // 偏移
      result.push(`translate(${rotateOffset?.offset})`);
    } else {
      // 编组中
      const newCenter = {
        x: width / 2,
        y: height / 2,
      };
      result.push(`rotate(${rotate},${newCenter.x},${newCenter.y})`);
    }
  }

  return result.length ? `transform ="${result.join(' ')}"` : '';
};

/**
 * 计算位置
 * @param options
 */
const calcPosition = (options: IMakeTransformOptions) => {
  const { position, isRoot, offset } = options;

  let { x = 0, y = 0 } = position ?? {};
  if ((position && !isRoot) || offset) {
    if (offset) {
      x += offset.x;
      y += offset.y;
    }

    if (x || y) {
      return {
        x: Math.ceil(x),
        y: Math.ceil(y),
      };
    }
  }
  return {
    x: 0,
    y: 0,
  };
};

// 旋转偏移
const calcRotateOffset = (options: IMakeTransformOptions) => {
  const { position, rotate, size, outerBounds } = options;
  const { width = 0, height = 0 } = size ?? {};
  if (outerBounds) {
    //isRoot &&
    // 作为根目录
    const { x = 0, y = 0 } = position ?? {};
    const center = calcCenter({
      x,
      y,
      width,
      height,
    });

    const { width: newWidth = 0, height: newHeight = 0 } = outerBounds;
    const newCenter = {
      x: newWidth / 2,
      y: newHeight / 2,
    };
    return {
      rotate: [rotate, newCenter.x, newCenter.y],
      offset: [newCenter.x - center.x, newCenter.y - center.y],
    };
  }
};

export const calcForeignObjectOffsetPosition = (t: { size: ISize; rotate?: number }) => {
  let position = { x: 0, y: 0 };
  const { size, rotate } = t;
  if (rotate) {
    // 旋转后中心偏移
    const offset = getCenterOffsetInViewportAfterRotate(size, rotate);
    position = offsetPoint(offset, position);
  }
  return position;
};

// 位置信息，需要加回旋转偏移量
export const makeforeignObjectTransform = (options: IMakeTransformOptions) => {
  const { rotate, size } = options;
  const result = [];

  let { x, y } = calcPosition(options);

  if (size && rotate) {
    const rotateOffset = calcForeignObjectOffsetPosition({ rotate, size });
    const { x: offsetX, y: offsetY } = rotateOffset;
    x -= offsetX;
    y -= offsetY;
  }

  if (x || y) {
    result.push(`translate(${x}, ${y})`);
  }

  return result.length ? `transform ="${result.join(' ')}"` : '';
};
