import * as appSetApis from '@apis/appSet';
import { accessApp, addApp } from '@apis/app';
import { getAllApps, getRecycle } from '@apis/team';

import { desktopServer } from '@/services/desktop';

import { ActionsUnion, ActionType, createAction } from '../types';
import { IDesktopMainState } from '../desktopTypes';
import { Global, GlobalThunkActions } from '../desktopActions';
import { IOrder } from './reducers';
import { IAppCreateReq, IAppSetCreateReq, IAppsOfTeam, ErrorCode, RoleInTeam } from '@fbs/teamManagement';

export const Actions = {
  loadAppSet: (apps: IAppsOfTeam[], userID: number, superAdminViewAllApp?: boolean, roleInTeam?: RoleInTeam) =>
    createAction(ActionType.AppSet_All, { apps, userID, module, superAdminViewAllApp, roleInTeam }),
  loadRecycle: (apps: IAppsOfTeam[]) => createAction(ActionType.AppSet_Recycle, apps),
  restoreApp: (appID: string) => createAction(ActionType.AppSet_Restore, appID),
  clearAppFromRecycle: (appID: string) => createAction(ActionType.AppSet_ClearFromRecycle, appID),
  createAppSet: (appSet: IAppsOfTeam) => createAction(ActionType.AppSet_AddAppSet, appSet),
  createApp: (app: IAppsOfTeam) => createAction(ActionType.AppSet_AddApp, app),
  removeAppSet: (appSetID: string) => createAction(ActionType.AppSet_RemoveAppSet, appSetID),
  removeApp: (appID: string) => createAction(ActionType.AppSet_RemoveApp, appID),
  moveApp: (appSetID: string, appID: string) => createAction(ActionType.AppSet_MoveApp, { appSetID, appID }),
  star: (appID: string) => createAction(ActionType.AppSet_Star, appID),
  patchOrder: (order: IOrder) => createAction(ActionType.AppSet_PatchOrder, order),
  clearApps: () => createAction(ActionType.AppSet_Clear_Apps),
  patchApp: (appID: string, data: Partial<IAppsOfTeam>) =>
    createAction(ActionType.AppSet_Patch, {
      appID,
      data,
    }),
  insertRecent: (appID: string) => createAction(ActionType.AppSet_InsertRecent, appID),
};

export const ThunkActions = {
  loadAllApps: (teamID?: string, fn?: (apps: IAppsOfTeam[]) => void) => async (
    dispatch: Function,
    getState: () => IDesktopMainState,
  ) => {
    dispatch(Global.waiting(true));
    const { userInfo, currentTeam } = getState().user;
    if (!userInfo) {
      window.debug && console.log('[user] loading error');
      return;
    }
    const userID = userInfo!.id;
    const superAdminViewAllApp = !!currentTeam?.superAdminViewAllApp;
    const currentTeamID = currentTeam?.id || '';
    const roleInTeam = currentTeam?.roleInTeam as RoleInTeam;

    getAllApps(teamID || currentTeamID)
      .then(({ apps }) => {
        fn && fn(apps);
        dispatch(Actions.loadAppSet(apps, userID, superAdminViewAllApp, roleInTeam));
        dispatch(Global.waiting(false));
      })
      .catch((e) => {
        dispatch(GlobalThunkActions.thunkErrorMessage(e));
      });

    // appSetApis
    //   .getAllAppSet(teamID || currentTeamID)
    //   .then(({ apps }: { apps: IAppInfo[] }) => {
    //     console.log(apps);
    //     fn && fn(apps);
    //     dispatch(Actions.loadAppSet(apps, userID, module, superAdminViewAllApp, roleInTeam));
    //     dispatch(Global.waiting(false));
    //   })
    //   .catch((e) => {
    //     dispatch(GlobalThunkActions.thunkErrorMessage(e));
    //     dispatch(Actions.loadAppSet([], userID, module, superAdminViewAllApp, roleInTeam));
    //   });
  },

  loadRecycle: (teamID: string) => async (dispatch: Function) => {
    getRecycle(teamID).then((apps) => {
      dispatch(Actions.loadRecycle(apps));
    });
  },

  createAppSet: (data: IAppSetCreateReq, fn?: (appSet?: IAppsOfTeam) => void) => async (dispatch: Function) => {
    appSetApis
      .addAppSet(data)
      .then((appSet) => {
        fn && fn(appSet);
        dispatch(Actions.createAppSet(appSet));
      })
      .catch((e) => {
        dispatch(GlobalThunkActions.thunkErrorMessage(e));
        fn && fn();
      });
  },

  createApp: (data: IAppCreateReq, fn?: (data?: IAppsOfTeam) => void) => async (dispatch: Function) => {
    addApp(data)
      .then((app) => {
        desktopServer.openApp(app._id);
        const _app = app as IAppsOfTeam;
        dispatch(Actions.createApp(_app));
        fn && fn(_app);
      })
      .catch((e) => {
        dispatch(GlobalThunkActions.thunkErrorMessage(e));
      });
  },

  moveApp: (teamID: string, appSetID: string, appID: string) => async (dispatch: Function) => {
    dispatch(Global.waiting(true));
    appSetApis
      .moveAppToAppSet(teamID, appSetID || 'root', appID)
      .then(() => {
        dispatch(Global.waiting(false));
        dispatch(Actions.moveApp(appSetID, appID));
      })
      .catch((e) => dispatch(GlobalThunkActions.thunkErrorMessage(e)));
  },

  removeApp: (teamID: string, appID: string) => async (dispatch: Function) => {
    dispatch(Global.waiting(true));
    try {
      await appSetApis.removeApp(teamID, appID);
      dispatch(Actions.removeApp(appID));
    } finally {
      dispatch(Global.waiting(false));
    }
  },

  removeAppSet: (teamID: string, appSetID: string) => async (dispatch: Function) => {
    dispatch(Global.waiting(true));
    appSetApis
      .removeAppSet(teamID, appSetID)
      .then(() => {
        dispatch(Actions.removeAppSet(appSetID));
        dispatch(Global.waiting(false));
        // dispatch(GlobalThunkActions.thunkToast('已删除'));
      })
      .catch((e) => dispatch(GlobalThunkActions.thunkErrorMessage(e)));
  },

  rename: (appID: string, name: string) => async (dispatch: Function) => {
    dispatch(Global.waiting(true));
    appSetApis
      .renameAppSet(appID, name)
      .then(() => {
        dispatch(Global.waiting(false));
        dispatch(Actions.patchApp(appID, { name }));
      })
      .catch((e) => dispatch(GlobalThunkActions.thunkErrorMessage(e)));
  },

  restoreApp: (appID: string) => async (dispatch: Function) => {
    appSetApis
      .restoreApp(appID)
      .then(() => {
        dispatch(Actions.restoreApp(appID));
        // dispatch(GlobalThunkActions.thunkToast('已恢复'));
      })
      .catch((e) => dispatch(GlobalThunkActions.thunkErrorMessage(e)));
  },

  clearAppFromRecycle: (teamID: string, appID: string) => async (dispatch: Function) => {
    appSetApis
      .clearAppFromRecycle(teamID, appID)
      .then(() => {
        dispatch(Actions.clearAppFromRecycle(appID));
        // dispatch(GlobalThunkActions.thunkToast('已清除'));
      })
      .catch((e) => dispatch(GlobalThunkActions.thunkErrorMessage(e)));
  },

  starApp: (appID: string) => async (dispatch: Function) => {
    dispatch(Global.waiting(true));
    appSetApis
      .starAppInfo(appID)
      .then(() => {
        dispatch(Global.waiting(false));
        dispatch(Actions.star(appID));
      })
      .catch((e) => dispatch(GlobalThunkActions.thunkErrorMessage(e)));
  },

  unStarApp: (appID: string) => async (dispatch: Function) => {
    dispatch(Global.waiting(true));
    appSetApis
      .unStarAppInfo(appID)
      .then(() => {
        dispatch(Global.waiting(false));
        dispatch(Actions.star(appID));
      })
      .catch((e) => dispatch(GlobalThunkActions.thunkErrorMessage(e)));
  },

  cloneApp: (appID: string) => async (dispatch: Function) => {
    dispatch(Global.waiting(true));
    let timeID: number | undefined = undefined;

    const checkProgress = (taskID: string) => {
      appSetApis
        .getClonedAppInfoByTaskID(taskID)
        .then((appInfo) => {
          accessApp(appInfo._id, appInfo.teamID);
          dispatch(Actions.createApp(appInfo));
        })
        .catch((e) => {
          if (e.code === ErrorCode.TaskInProgress) {
            timeID = window.setTimeout(() => {
              checkProgress(taskID);
            }, 1e3);
          } else {
            window.clearTimeout(timeID);
            dispatch(GlobalThunkActions.thunkErrorMessage(e));
            dispatch(Global.waiting(false));
          }
        });
    };

    try {
      const res = await appSetApis.cloneAppInfo(appID);
      checkProgress(res.taskID);
      return res;
    } catch (e) {
      dispatch(GlobalThunkActions.thunkErrorMessage(e));
      throw e;
    } finally {
      dispatch(Global.waiting(false));
    }
  },

  patchOrder: (order: IOrder) => async (dispatch: Function) => {
    dispatch(Actions.patchOrder(order));
  },

  createDesktopApp: (app: IAppsOfTeam) => (dispatch: Function) => {
    dispatch(Actions.createApp(app));
  },

  clearApps: () => (dispatch: Function) => {
    dispatch(Actions.clearApps());
  },

  patchDesktopApp: (appID: string, data: Partial<IAppsOfTeam>) => (dispatch: Function) => {
    dispatch(Actions.patchApp(appID, data));
  },

  insertRecent: (appID: string) => (dispatch: Function) => {
    dispatch(Actions.insertRecent(appID));
  },
};

export type AppSetActions = ActionsUnion<typeof Actions>;
