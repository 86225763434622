import * as React from 'react';
import classnames from 'classnames';

import { IconValue } from '@/fbs/rp/models/value';

import './index.scss';

interface IconNodeProps {
  selected: boolean;
  compId: string;
  iconValue: IconValue;
  className?: string;
  style?: React.CSSProperties;
  onFontIconClick?: (e: React.MouseEvent, id: string) => void;
}

const IconNode: React.FC<IconNodeProps> = (props) => {
  const { iconCode, fontName } = props.iconValue;

  const handleClickChangeIcon = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onFontIconClick?.(e, props.compId);
  };

  return (
    <span
      className={classnames('component-edit-item-font-icon', props.className, { selected: props.selected })}
      style={{ fontFamily: fontName, ...props.style }}
      onClick={handleClickChangeIcon}
    >
      {String.fromCharCode(iconCode)}
    </span>
  );
};

export default IconNode;
