import * as React from 'react';
import classNames from 'classnames';
import { isNumber, isUndefined, set } from 'lodash';

import { depthClone } from '@utils/globalUtils';
import { isControlKeyPressed } from '@utils/hotkeysUtils';
import { measureTextSize, transBlankChart } from '@utils/textUtils';
import { dragDelegate } from '@/utils/mouseUtils';

import { IAutoCloseProps, IPopupComponent } from '@/dsm/withAutoClose';
import { FloatPanel, IAutoCloseComponentProps, Icon, ScrollBars, withAutoClose } from '@dsm';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';

import { IBounds } from '@fbs/common/models/common';
import { IComponentData } from '@fbs/rp/models/component';
import ITextFormat from '@fbs/rp/models/properties/text';
import { IComponentValue } from '@fbs/rp/models/value';

import { resetID, resetInteractionTargetID, resetValueMap } from '@helpers/componentHelper';
import { getShortCutKey } from '@helpers/shortCutHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { mergePatches } from '@/helpers/patchHelper';

import CoreEditor from '@editor/core';
import CPEditorDoc from '@editor/contentPanelEditorDocument';
import { UIComponent, UIContainerComponent, UISelectPanelComponent, UIContentPanelComponent } from '@editor/comps';
import { ArtboardPatchesClass } from '@editor/patches/artboardPatches';

import i18n from '@i18n';

import { getComponent, makeComponentItem } from '@libs/libs';
import { isTextType, isRichText } from '@libs/helper';
import {
  CScore,
  CImageTextTabs,
  CContentPanel,
  CContentPanelV2,
  CIcon,
  CSelectPanel,
  CSelect,
  CMultipleSelect,
  CListLayoutPanel,
  CImage,
  CVerticalTabs,
} from '@libs/constants';
import { PredefinedStates } from '@consts/state';

import EditorContext from '@contexts/editor';

import { IValueEditor, ValueEditorValueType } from '@/customTypes';

import ValueItem from './ValueItem';
import ContentPanelValueItem from './ContentPanelValueItem';
import ImageTextTabValueItem from './ImageTextTabValueItem';
import withReplaceIcon, { InjectedReplaceIconProps } from '../withReplaceIcon';

import './index.scss';

interface IItemValueEditorBaseProps extends IAutoCloseComponentProps {
  comp: UIContainerComponent;
  coreEditor: CoreEditor;
  sourceBounds: IBounds;
  onOpenIconLibrariesPanel?: Function;
}

interface IItemValueEditorProps extends IItemValueEditorBaseProps, IAutoCloseProps {
  onMouseDown: React.MouseEventHandler;
  onMouseUp: React.MouseEventHandler;
}

interface IItemValueEditorState {
  editIndex?: number;
  selectedIndex?: number;
  position: {
    left: number;
    top: number;
  };
}

enum DeleteType {
  FirstDelete = 'firstDelete', // 第一次直接通过点击删除按钮或者delete键删除，deleteFlag赋为firstDelete
  HasDelete = 'hasDelete', // 清空值后触发了删除，deleteFlag赋为hasDelete,再次点击删除按钮时，deleteFlag赋为NULL
  Null = '', // deleteFlag 为NUll时候不清空
}

class ItemValueEditor extends React.Component<IItemValueEditorProps & InjectedReplaceIconProps, IItemValueEditorState>
  implements IValueEditor {
  static contextType = EditorContext;
  // @ts-ignore
  context: React.ContextType<typeof EditorContext>;
  private valueComps: (UIComponent | undefined)[] = [];
  targetComp: UIComponent | undefined = undefined;
  private _valueType: ValueEditorValueType;
  private contentDom: React.RefObject<HTMLDivElement> = React.createRef();
  private containerComp?: UIContainerComponent;
  private scrollBar: React.RefObject<ScrollBars> = React.createRef();
  private isScoreComp: boolean = false;
  private isImageTextTabsComp: boolean = false; // 是否为图文选项卡

  constructor(props: IItemValueEditorProps & InjectedReplaceIconProps) {
    super(props);
    this.isScoreComp = props.comp.lib?.type === CScore;
    this.isImageTextTabsComp = [CImageTextTabs, CVerticalTabs].includes(props.comp.lib?.type ?? '');
    this.flatComps(props.comp);
    this._valueType = this.getValueType();
    const selectedIndex = this.valueComps.length > 0 ? 0 : undefined;
    this.state = {
      position: {
        left: props.sourceBounds.left,
        top: props.sourceBounds.top,
      },
      selectedIndex,
    };
  }

  get valueType() {
    return this._valueType;
  }

  get hasValueComps() {
    return this.valueComps.length > 0;
  }

  get canReplaceIcon() {
    return this.isScoreComp || this.isImageTextTabsComp;
  }

  private get ValueItemComponent(): typeof ValueItem {
    const { comp } = this.props;
    let type = comp.type;
    if (this.isImageTextTabsComp) {
      type = comp.lib?.type as string;
    }
    switch (type) {
      case CContentPanel:
      case CContentPanelV2:
        return ContentPanelValueItem;
      case CImageTextTabs:
      case CVerticalTabs:
        return ImageTextTabValueItem;
      default:
        return ValueItem;
    }
  }

  componentDidMount() {
    if (this.contentDom.current) {
      this.contentDom.current.focus();
      this.adjustPosition();
      this.context.uiManager.valueEditor = this;
      if (
        this.valueComps.length &&
        [ValueEditorValueType.StringType, ValueEditorValueType.IconType].includes(this._valueType)
      ) {
        const { selectedIndex } = this.state;
        if (!isUndefined(selectedIndex)) {
          this.doSetCompSelectItem(selectedIndex);
        }
        this.setState({ editIndex: selectedIndex });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: IItemValueEditorProps) {
    const comp = nextProps.comp;
    this.flatComps(comp);
    // 处理因undo导致列表与选中索引不匹配，报错
    const { selectedIndex } = this.state;
    // valueComps 渲染列表项的长度
    if (typeof selectedIndex === 'number' && selectedIndex >= this.valueComps.length) {
      this.setState({ selectedIndex: this.valueComps.length - 1 });
    }
  }

  componentWillUnmount() {
    this.context.actionFinderManager.updateSource(undefined);
    clearTimeout(this.removeTimer);
    this.context.uiManager.valueEditor = undefined;
    this.props.comp.selectedItem = undefined;
    this.context.uiManager.interactionPanel?.refresh();
    this.context.uiManager.updatePropertiesPanel();
    this.context.uiManager.projectTree?.refresh();
  }

  setValue(value: IComponentValue) {
    const { selectedIndex } = this.state;
    if (this.containerComp instanceof UISelectPanelComponent && this.targetComp) {
      this.props.coreEditor.setComponentValue(this.targetComp, value);
    }
    if (isNumber(selectedIndex) && selectedIndex !== -1) {
      const comp = this.valueComps[selectedIndex];
      if (comp) {
        this.props.coreEditor.setComponentValue(comp, value);
      }
    }
  }

  // 给外部调用
  close() {
    this.props.onClose();
  }

  // 外部调用修改图标方法
  public replaceIconValue(value: IComponentValue) {
    const { selectedNodeIcons } = this.props;
    if (selectedNodeIcons.length > 0) {
      const patches = selectedNodeIcons.reduce(
        (prev, id) => {
          const itemComp = this.valueComps.find((item) => item!.id === id) as UIContainerComponent;
          if (itemComp) {
            // 图文选项卡
            if (this.isImageTextTabsComp) {
              const iconComp = itemComp.components.find((item) => item.type === CIcon) as UIComponent;
              const itemPatches = iconComp.setValue(value);
              mergePatches(prev, itemPatches);
            } else if (this.isScoreComp) {
              // 评分组件
              const itemPatches = itemComp.setValue(value);
              mergePatches(prev, itemPatches);
            }
          }
          return prev;
        },
        { do: {}, undo: {} },
      );
      const { coreEditor } = this.props;
      coreEditor.updateSingleArtboard(coreEditor.activeArtboard.artboardID, patches);
    }
  }

  private adjustPosition() {
    if (this.props.forwardedRef) {
      const panel = this.props.forwardedRef.current;
      if (panel) {
        const { width, height, right, bottom } = panel.getBoundingClientRect();
        let { left, top } = this.state.position;
        const winHeight = window.innerHeight;
        const winWidth = window.innerWidth;
        if (bottom > winHeight) {
          top = Math.round(top - height);
        }
        if (right > winWidth) {
          left = Math.round(winWidth - width);
        }
        if (left !== this.state.position.left || top !== this.state.position.top) {
          this.setState({ position: { left, top } });
        }
      }
    }
  }

  // 获取icon对应的ids
  getIconIdList = () => this.valueComps.map((item) => item!.id);

  private flatComps(comp: UIContainerComponent) {
    this.valueComps = [];
    this.containerComp = comp;
    const { type } = comp;
    if ([CContentPanel, CContentPanelV2, CSelectPanel].includes(type) || this.isImageTextTabsComp) {
      this.valueComps.push(...comp.components);
      this.props.setAllIconIds(this.getIconIdList());
      return;
    }
    if (!comp.isSealed) {
      return;
    }
    if ([CSelect, CMultipleSelect].includes(type)) {
      const dropDown = this.containerComp.getComponentByAlias('list', true);
      if (dropDown) {
        this.containerComp = dropDown as UIContainerComponent;
      } else {
        return;
      }
    }
    // FIXME Matt 缺少对内容面板的支持，需要补充
    const itemComps: UIComponent[][] = [];
    const doFlat = (group: UIContainerComponent, comps: UIComponent[]) => {
      comps.push(...group.components);
      group.components.forEach((comp) => {
        if (comp.isContainer) {
          doFlat(comp as UIContainerComponent, comps);
        }
      });
    };
    this.containerComp.components.forEach((comp) => {
      const items: UIComponent[] = [];
      itemComps.push(items);
      items.push(comp);
      if (comp.isContainer) {
        doFlat(comp as UIContainerComponent, items);
      }
    });

    itemComps.forEach((items) => {
      let textComp: UIComponent | undefined = undefined;
      let iconComp: UIComponent | undefined = undefined;
      let imgComp: UIComponent | undefined = undefined;
      items.forEach((comp) => {
        if (!textComp && isTextType(comp.type)) {
          textComp = comp;
        }
        if (!iconComp && comp.type === CIcon) {
          iconComp = comp;
        }
        if (!imgComp && comp.type === CImage) {
          imgComp = comp;
        }
      });
      const comp = textComp || iconComp || imgComp;
      this.valueComps.push(comp);
    });
    this.props.setAllIconIds(this.getIconIdList());
  }

  private getValueType(): ValueEditorValueType {
    if (this.valueComps.length) {
      const comp = this.valueComps[0];
      if (this.props.comp.type === CSelectPanel) {
        return ValueEditorValueType.CustomType;
      }
      if (comp) {
        const { type } = comp;
        if (
          isTextType(type) ||
          this.props.comp.type === CContentPanel ||
          this.props.comp.type === CContentPanelV2 ||
          this.isImageTextTabsComp
        ) {
          return ValueEditorValueType.StringType;
        } else if (CIcon === type) {
          return ValueEditorValueType.IconType;
        } else if (CImage === type) {
          return ValueEditorValueType.ImageType;
        }
      }
    }
    return ValueEditorValueType.OtherType;
  }

  private removeTimer?: Timeout;

  private deleteFlag?: DeleteType;

  handleValueChanged = (index: number, value: IComponentValue) => {
    // 值允许空格
    if (typeof value === 'string') {
      if (!value) {
        if (this.removeTimer) {
          return;
        }
        this.removeTimer = window.setTimeout(() => {
          clearTimeout(this.removeTimer);
          this.removeTimer = undefined;
          // 如果清空了值触发删除后，再点击删除按钮不执行二次删除
          this.deleteFlag = DeleteType.HasDelete;
          this.doRemoveItem(index);
        }, 0);
        return;
      }
    }
    this.doChangeItemValue(index, value);
    this.setState({ editIndex: undefined });
    if (this.contentDom.current) {
      this.contentDom.current!.focus();
    }
  };

  handleEditNext = (index: number, offset: number, canAdd?: boolean) => {
    setTimeout(() => {
      const next = index + offset;
      if (next === this.containerComp!.components.length) {
        if (canAdd) {
          this.doAppendItem();
        }
      } else {
        this.setState({ editIndex: next, selectedIndex: next }, () => {
          this.doSetCompSelectItem(next);
          this.doScroll();
        });
      }
    }, 0);
  };

  handleAppendItem = () => {
    this.doAppendItem();
  };

  handleRemoveItem = () => {
    // 如果清空了值触发删除后，再点击删除按钮不执行二次删除
    this.deleteFlag = this.deleteFlag === DeleteType.HasDelete ? DeleteType.Null : DeleteType.FirstDelete;
    this.doRemoveItem(this.state.selectedIndex!);
  };

  handleMouseEnterItem = (index: number) => {
    const { comp } = this.props;
    let targetComponent = comp.components[index];
    //下拉多选需要特殊处理一下,他的子节点在深的一层
    if (comp.type === CMultipleSelect) {
      const listLayoutPanelParent = (comp.components as UIContainerComponent[]).find((item) => {
        return item.components.length === 1 && item.components[0].type === CListLayoutPanel;
      }) as UIContainerComponent;
      if (listLayoutPanelParent) {
        targetComponent = (listLayoutPanelParent.components[0] as UIContainerComponent).components[index];
      }
    }
    this.context.uiManager.projectTree?.refresh(targetComponent);
  };

  handleMoveItemIndex = (oldIndex: number, newIndex: number) => {
    this.doMoveItemIndex(oldIndex, newIndex);
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    // ctrl + enter 添加项
    if (isControlKeyPressed(e) && e.keyCode === KeyCodeMap.VK_ENTER) {
      e.stopPropagation();
      this.doAppendItem();
      return;
    }

    let comp = this.props.comp;
    let canNotRemove = false;
    if (comp instanceof UISelectPanelComponent && !comp.canRemoveChildren) {
      canNotRemove = true;
    }

    const { selectedIndex, editIndex } = this.state;

    if (selectedIndex === undefined) {
      return;
    }
    // 在编辑项的名字
    if (editIndex !== undefined) {
      return;
    }

    if (!isControlKeyPressed(e) && e.keyCode === KeyCodeMap.VK_ENTER) {
      e.stopPropagation();
      this.setState({ editIndex: selectedIndex });
      return;
    }
    if (e.keyCode === KeyCodeMap.VK_DEL || e.keyCode === KeyCodeMap.VK_BACKSPACE) {
      e.stopPropagation();
      if (!canNotRemove) {
        this.deleteFlag = DeleteType.FirstDelete;
        this.doRemoveItem(selectedIndex);
      }
      return;
    }
    if ([KeyCodeMap.VK_UP, KeyCodeMap.VK_DOWN, KeyCodeMap.VK_LEFT, KeyCodeMap.VK_RIGHT].includes(e.keyCode)) {
      e.stopPropagation();
    }

    if (isControlKeyPressed(e)) {
      if (e.keyCode == KeyCodeMap.VK_UP) {
        if (selectedIndex === 0) {
          return;
        }
        this.doMoveItemIndex(selectedIndex, selectedIndex - 1);
      } else if (e.keyCode == KeyCodeMap.VK_DOWN) {
        if (selectedIndex === this.valueComps.length - 1) {
          return;
        }
        this.doMoveItemIndex(selectedIndex, selectedIndex + 2);
      }
    } else if (!isControlKeyPressed(e)) {
      if (e.keyCode === KeyCodeMap.VK_UP) {
        if (selectedIndex !== 0) {
          this.setState({ selectedIndex: selectedIndex - 1 }, () => {
            this.doScroll();
            this.doSetCompSelectItem(this.state.selectedIndex);
          });
        }
      } else if (e.keyCode === KeyCodeMap.VK_DOWN) {
        if (selectedIndex !== this.valueComps.length - 1) {
          this.setState({ selectedIndex: selectedIndex + 1 }, () => {
            this.doScroll();
            this.doSetCompSelectItem(this.state.selectedIndex);
          });
        }
      }
    }
  };

  handleItemClick = (index: number) => {
    this.setState({ selectedIndex: index }, () => {
      this.doSetCompSelectItem(this.state.selectedIndex);
    });
    if (!isUndefined(this.state.editIndex) && this.state.editIndex !== -1) {
      this.setState({ editIndex: undefined });
    }
  };

  handleExitItemValueEditor = (index: number) => {
    if (index === this.state.editIndex) {
      this.setState({ editIndex: undefined });
    }
  };

  handleItemChecked = (index: number, checked: boolean) => {
    const { coreEditor, comp } = this.props;
    const item = this.containerComp!.components[index];
    if (this.isScoreComp) {
      let selectedComps: UIComponent[] = [];
      let unSelectedComps: UIComponent[] = [];
      if (checked) {
        selectedComps = comp.components.slice(0, index + 1);
        unSelectedComps = comp.components.slice(index + 1);
      } else {
        unSelectedComps = comp.components.slice(index);
      }
      const patches = new ArtboardPatchesClass();
      selectedComps &&
        selectedComps.forEach((comp) => {
          patches.coverPatches(coreEditor.getChangeCompGeneralPropertiesPatches(comp, 'selected', true));
        });
      unSelectedComps &&
        unSelectedComps.forEach((comp) => {
          patches.coverPatches(coreEditor.getChangeCompGeneralPropertiesPatches(comp, 'selected', false));
        });
      coreEditor.updateSingleArtboard(comp.ownerArtboardID, patches);
    } else {
      coreEditor.setComponentGeneralProperties(item, 'selected', checked);
    }
  };

  handleMoveItem = (model: 'up' | 'down') => {
    const { selectedIndex } = this.state;
    if (!isUndefined(selectedIndex)) {
      let newIndex = selectedIndex;
      if (model === 'up') {
        newIndex = Math.max(0, selectedIndex - 1);
      } else {
        newIndex = Math.min(this.valueComps.length, selectedIndex + 2);
      }
      if (selectedIndex !== newIndex) {
        this.doMoveItemIndex(selectedIndex, newIndex);
      }
    }
  };

  handleInteractionDragger = (e: React.MouseEvent, index: number) => {
    const comp = this.containerComp?.components[index];
    if (comp) {
      this.handleItemClick(index);
      this.context.uiManager.workSpace?.page?.startFindInteractionTarget(e, comp);
    }
  };

  // 拖拽
  handleMouseDown = (e: React.MouseEvent) => {
    const panelDom = this.props.forwardedRef?.current;
    if (!panelDom) return;
    const maxLeft = window.innerWidth - panelDom!.clientWidth;
    const maxTop = window.innerHeight - panelDom!.clientHeight;
    const { position } = this.state;
    const startPoint = { left: position.left, top: position.top };
    // this.props.onMouseUp();
    const onMoving = (e: MouseEvent, delta: { x: number; y: number }) => {
      const newPosition = {
        left: startPoint.left + delta.x,
        top: startPoint.top + delta.y,
      };
      if (newPosition.left < 0) {
        newPosition.left = 0;
      } else if (newPosition.left > maxLeft) {
        newPosition.left = maxLeft;
      }
      if (newPosition.top < 0) {
        newPosition.top = 0;
      } else if (newPosition.top > maxTop) {
        newPosition.top = maxTop;
      }
      this.setState({ position: newPosition });
    };

    const onMoveEnd = () => {
      this.props.onMouseUp(e);
    };

    dragDelegate(onMoving, onMoveEnd);
  };

  private doScroll() {
    const { selectedIndex } = this.state;
    if (this.scrollBar.current && selectedIndex !== undefined) {
      const scrollBar = this.scrollBar.current;
      // 已滚动距离
      const top = scrollBar.getScrollTop();
      const selTop = 30 * selectedIndex;
      const selBottom = selTop + 30;
      // 视口范围
      const viewHeight = scrollBar.getClientHeight();
      if (selTop - top >= viewHeight) {
        scrollBar.scrollTop(selBottom - viewHeight + top);
      } else if (selBottom - top <= 0) {
        scrollBar.scrollTop(selTop);
      }
    }
  }

  private doSetCompSelectItem(index?: number) {
    const { comp } = this.props;
    if (isUndefined(index)) {
      comp.selectedItem = undefined;
    } else {
      if ([CSelect, CMultipleSelect].includes(comp.type)) {
        const list = comp.getComponentByAlias('list', true);
        if (list) {
          comp.selectedItem = (list as UIContainerComponent).components[index];
        }
      } else {
        comp.selectedItem = comp.components[index];
      }
    }
    this.context.uiManager.interactionPanel?.refresh();
    this.context.uiManager.updatePropertiesPanel();
  }

  private doRemoveItem = (index: number) => {
    if (this.deleteFlag === DeleteType.Null) {
      return;
    }
    const { coreEditor, selectedNodeIcons, setSelectedIconIds } = this.props;
    const comp = this.containerComp!;
    const item = comp.components[index]!;
    const nextIndex: number | undefined = Math.max(0, index - 1);

    const newSelectedIcons = selectedNodeIcons.filter((node) => node !== item.id);
    setSelectedIconIds(newSelectedIcons);

    this.setState({ selectedIndex: nextIndex, editIndex: undefined }, () => {
      this.doScroll();
      this.doSetCompSelectItem(nextIndex);
    });
    if (comp.type === CContentPanel || comp.type === CContentPanelV2) {
      coreEditor.removeContentPanelItem(comp, item.value as string);
    } else {
      if (comp.components.length > 1) {
        coreEditor.removeSealedChildComponents([item], comp);
      }
    }
    this.focus();
  };

  private doMoveItemIndex = (oldIndex: number, newIndex: number) => {
    const { coreEditor } = this.props;
    const comp = this.containerComp!;
    coreEditor.moveChildOrder(oldIndex, newIndex, comp);
    if (newIndex < oldIndex) {
      this.setState({ selectedIndex: newIndex }, () => {
        this.doScroll();
        this.doSetCompSelectItem(this.state.selectedIndex);
      });
    } else if (newIndex > oldIndex) {
      this.setState({ selectedIndex: newIndex - 1 }, () => {
        this.doScroll();
        this.doSetCompSelectItem(this.state.selectedIndex);
      });
    } else {
      // FIXME 提示不能删除最行一项
    }
  };

  private getTextCompData(data: IComponentData): IComponentData | null {
    if (isTextType(data.type)) {
      return data;
    }
    const find = (comps?: IComponentData[]): IComponentData | null => {
      if (!comps) {
        return null;
      }
      let comp: IComponentData | null = null;
      for (let i = 0; i < comps.length; i++) {
        if (isTextType(comps[i].type)) {
          comp = comps[i];
          break;
        }
      }
      if (!comp) {
        for (let i = 0; i < comps.length; i++) {
          if (comps[i].components) {
            comp = find(comps[i].components);
          }
          if (comp) {
            break;
          }
        }
      }
      return comp;
    };

    return find(data.components);
  }

  private resetItemValue(data: IComponentData) {
    this.calcAppendItemState(data);
    if (this._valueType === ValueEditorValueType.StringType) {
      const textComp = this.getTextCompData(data);
      if (textComp) {
        let num: string = '';
        let value = textComp.value as string;
        while (value && value.length && /\d/.test(value[value.length - 1])) {
          num = `${value[value.length - 1]}${num}`;
          value = value.substring(0, value.length - 1);
        }
        let count: number;
        if (num) {
          count = parseInt(num.trim(), 10) + 1;
        } else {
          count = 1;
        }
        let tempValue = `${value}${count}`;
        const properties = depthClone(textComp.properties);
        const ownerProperties = this.props.comp.properties;
        if (properties.textStyle?.ref) {
          const key = properties.textStyle.ref!.replace('@properties.', '');
          properties.textStyle = ownerProperties[key] as ITextFormat;
        }
        while (this.valueComps.find((c) => (c ? c.value === tempValue : false))) {
          count++;
          tempValue = `${value}${count}`;
        }
        textComp.value = transBlankChart(tempValue);
        if (textComp.autoSize) {
          const cssParser = StyleHelper.initCSSStyleParser(properties);
          textComp.size.width = measureTextSize({ ...cssParser.getTextStyle() }, tempValue, {
            isRich: isRichText(textComp.type),
          }).width;
        }
      }
    }
  }

  private calcAppendItemState(itemData: IComponentData) {
    const container = this.containerComp!;
    const { select } = container;
    if (select?.target === 'child') {
      const { minCount, maxCount } = select;
      const { components } = container;
      const selectCount = components.reduce((acc, curr) => {
        return acc + (curr.selected ? 1 : 0);
      }, 0);
      if (selectCount >= maxCount) {
        itemData.selected = false;
        if (itemData.states && itemData.states[PredefinedStates.checked]) {
          itemData.states[PredefinedStates.checked].enabled = false;
        }
        if (itemData._currentState === PredefinedStates.checked) {
          itemData._currentState = '';
        }
      } else if (selectCount < minCount) {
        if (!itemData.selected) {
          itemData.selected = true;
        }
        if (!itemData.states) {
          itemData.states = {};
        }
        if (!itemData.states[PredefinedStates.checked]) {
          itemData.states[PredefinedStates.checked] = {
            enabled: true,
            properties: {},
          };
        }
      }
    }
  }

  private doAppendItem() {
    const { coreEditor } = this.props;
    const comp = this.containerComp!;

    if (comp.type === CContentPanel) {
      coreEditor.addFragmentFromContentPanel(comp as UIContentPanelComponent).then(() => {
        // 处理 item 列表选中项
        const indexOfAddedItem = comp.components.length - 1;
        if (this.state.selectedIndex !== indexOfAddedItem) {
          this.setState({ selectedIndex: indexOfAddedItem }, () => {
            this.doScroll();
            this.doSetCompSelectItem(this.state.selectedIndex);
          });
        }
      });

      return;
    }

    let itemData: IComponentData | undefined;
    // 优先从兄弟节点创建，可以保证新节点的各项信息符合组件当前实际布局情况
    if (comp.components.length) {
      itemData = depthClone(comp.components[comp.components.length - 1].toJSON());
    } else if (comp.lib) {
      const data = getComponent(comp.lib);
      if (data) {
        itemData = makeComponentItem(comp.lib.id, comp.lib.type, '');
      }
    }
    if (itemData) {
      this.resetItemValue(itemData);
      const idMap = resetID([itemData]);
      resetInteractionTargetID([itemData], idMap);
      resetValueMap([itemData], idMap);
      const editIndex = comp.components.length;
      if (this._valueType === ValueEditorValueType.StringType) {
        if (this.state.editIndex !== editIndex) {
          this.setState({ editIndex });
        }
      }
      coreEditor.appendChildren([itemData], comp);
      if (this.state.selectedIndex !== editIndex) {
        this.setState({ selectedIndex: editIndex }, () => {
          this.doScroll();
          this.doSetCompSelectItem(this.state.selectedIndex);
        });
      }
    }
  }

  private doChangeItemValue(index: number, value: IComponentValue) {
    const { coreEditor, comp: containerComp } = this.props;
    const comp = this.valueComps[index];
    if (this.isImageTextTabsComp) {
      const textComp = (comp as UIContainerComponent).getComponentByAlias('text');
      const patches = textComp!.setValue(value);
      const otherPatches = containerComp.libData?.editor?.specials?.onItemValueChanged?.(
        containerComp,
        textComp,
        value,
      );
      mergePatches(patches, otherPatches);
      coreEditor.updateSingleArtboard(coreEditor.activeArtboard.artboardID, patches);
      return;
    }
    if (comp) {
      // 值允许空格
      if (isTextType(comp.type)) {
        if ((value as string) === '') {
          return;
        }
      }
      if (value !== comp.value) {
        let option = undefined;
        if (this.props.comp.type === CSelect) {
          option = {
            size: comp.size,
          };
        }
        coreEditor.setComponentValue(comp, value, option);
      }
    }
  }

  private getValue = (comp: UIComponent): { value: IComponentValue; invalid: boolean } => {
    const { comp: group, coreEditor } = this.props;
    let invalid = false;
    if (group.type === CContentPanel || group.type === CContentPanelV2) {
      let name =
        coreEditor.doc.getFragmentNameByID(comp.value as string) ||
        (coreEditor.doc as CPEditorDoc).contentPanelOwnerDoc?.getFragmentNameByID(comp.value as string);
      if (name === '') {
        name = i18n('property.interaction.invalidFragment');
        invalid = true;
      }
      return { value: name, invalid };
    } else {
      return { value: comp.value as string, invalid };
    }
  };

  focus() {
    this.contentDom.current?.focus();
  }

  private handleAfterDrag = (e: React.DragEvent) => {
    e.stopPropagation();
    this.props.onMouseUp && this.props.onMouseUp(e);
  };

  renderToolBar() {
    const { comp } = this.props;
    const { selectedIndex } = this.state;
    const isDisableAdd = comp instanceof UISelectPanelComponent && !comp.canAddChildren;
    let isDisableRemove =
      !this.hasValueComps ||
      isUndefined(selectedIndex) ||
      selectedIndex === -1 ||
      (comp instanceof UISelectPanelComponent && !comp.canRemoveChildren);
    return (
      <div className="value-item-editor-toolbar" onMouseDown={this.handleMouseDown}>
        <Icon
          tips={`${i18n('editor.append')} (${getShortCutKey('Enter', { ctrlKey: true })})`}
          cls="layer_plus"
          disabled={isDisableAdd}
          onClick={this.handleAppendItem}
        />
        <div className="right-opertation">
          <Icon
            tips={`${i18n('editor.up')} (${getShortCutKey('↑', { ctrlKey: true })})`}
            cls="icon_arrow_up"
            disabled={isUndefined(selectedIndex) || selectedIndex < 1}
            onClick={this.handleMoveItem.bind(this, 'up')}
          />
          <Icon
            tips={`${i18n('editor.down')} (${getShortCutKey('↓', { ctrlKey: true })})`}
            cls="icon_arrow_down"
            disabled={isUndefined(selectedIndex) || selectedIndex === -1 || selectedIndex >= this.valueComps.length - 1}
            onClick={this.handleMoveItem.bind(this, 'down')}
          />
          <Icon
            tips={`${i18n('general.delete')} (${getShortCutKey('Delete')})`}
            cls="demo_delete"
            disabled={isDisableRemove}
            onClick={this.handleRemoveItem}
          />
        </div>
      </div>
    );
  }

  renderItemValueList() {
    const { editIndex, selectedIndex } = this.state;
    const { comp, onOpenIconLibrariesPanel, selectedNodeIcons, onFontIconClick } = this.props;

    let { components } = comp;
    let { select } = comp;
    if ([CSelect, CMultipleSelect].includes(comp.type)) {
      components = ((components.find((c) => c.alias === 'drop-down') as UIContainerComponent | undefined)
        ?.components[0] as UIContainerComponent).components;
      select = comp.getComponentByAlias('list', true)?.select;
    }

    const compType = comp.lib?.type || comp.type;
    const moveable = ![CContentPanel, CContentPanelV2, CScore].includes(compType);

    let isMultiSelect = false;
    if (select?.target === 'child') {
      isMultiSelect = select.maxCount === -1 || isUndefined(select.maxCount);
    }

    let hideDragger = false;
    let atLeastOne = false;
    let disabledValueChange = false;
    if (comp.type === CContentPanel || comp.type === CContentPanelV2) {
      disabledValueChange = true;
      atLeastOne = true;
      hideDragger = true;
    }

    const height = 30 * Math.min(this.valueComps.length, 6);

    const ValueItemComponent = this.ValueItemComponent;
    return (
      <div className="item-value-list" style={{ height }}>
        <ScrollBars ref={this.scrollBar} hiddenHorizontalScrollBar>
          {this.valueComps.map((comp, index) => {
            const { value, invalid } = this.getValue(comp!);
            const itemComp = components[index];
            const hasInteraction = Object.keys(itemComp.interactions || {}).length > 0;
            const iconSelected = selectedNodeIcons.includes(itemComp.id);
            return (
              <ValueItemComponent
                key={comp!.id}
                coreEditor={this.props.coreEditor}
                comp={comp!}
                value={value}
                type={this._valueType}
                index={index}
                hasInteraction={hasInteraction}
                checked={this.containerComp!.components[index].selected}
                isMultiSelect={isMultiSelect}
                atLeastOne={atLeastOne}
                hideDragger={hideDragger}
                disabledValueChange={disabledValueChange}
                selected={selectedIndex === index}
                autoStartEditor={editIndex === index}
                moveable={moveable}
                invalid={invalid}
                onMouseEnter={this.handleMouseEnterItem}
                onMoveItem={this.handleMoveItemIndex}
                onChangeValue={this.handleValueChanged}
                onEditNext={this.handleEditNext}
                onClick={this.handleItemClick}
                onChecked={this.handleItemChecked}
                setTargetComp={this.setTargetComp}
                onInteractionDragger={this.handleInteractionDragger}
                onOpenIconLibrariesPanel={onOpenIconLibrariesPanel}
                onExitEditor={this.handleExitItemValueEditor}
                onFontIconClick={onFontIconClick}
                iconSelected={iconSelected}
              />
            );
          })}
        </ScrollBars>
      </div>
    );
  }

  renderEmptyContent() {
    if (this.hasValueComps) {
      return null;
    }

    const { comp } = this.props;
    if (comp.type === CContentPanel || comp.type === CContentPanelV2) {
      return <p className="empty-content-tips">{i18n('tips.contentPanelValueEditorTips')}</p>;
    }

    return null;
  }

  render() {
    const { position } = this.state;
    const { onMouseUp, onMouseDown } = this.props;
    return (
      <FloatPanel
        customRef={this.props.forwardedRef}
        className={classNames('editor-item-value-editor', this.props.floatPanelClassName)}
        style={position}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        <div
          ref={this.contentDom}
          className="item-value-editor-content"
          tabIndex={-1}
          onKeyDown={this.handleKeyDown}
          onDragEnd={this.handleAfterDrag}
        >
          {this.renderToolBar()}
          {this.renderItemValueList()}
          {this.renderEmptyContent()}
        </div>
      </FloatPanel>
    );
  }

  private setTargetComp = (value: UIComponent | undefined) => {
    set(this, 'targetComp', value);
  };
}

const ItemValueEditorWithNodeReplace: React.ComponentClass<IItemValueEditorProps> = withReplaceIcon<
  IItemValueEditorProps
>(ItemValueEditor);

const PanelClass: React.ComponentClass<IItemValueEditorProps & IAutoCloseProps> = withAutoClose<IItemValueEditorProps>(
  ItemValueEditorWithNodeReplace,
);

export default class C extends React.Component<IItemValueEditorBaseProps & IAutoCloseProps> {
  static contextType = EditorContext;
  private timeID?: Timeout = undefined;

  componentDidMount() {
    this.context.uiManager.listItemValueEditorPanel = this;
  }

  componentWillUnmount() {
    this.context.uiManager.listItemValueEditorPanel = undefined;
    clearTimeout(this.timeID);
  }

  get preventClose(): boolean {
    if (this.panel.current) {
      return !!(this.panel.current as IPopupComponent).preventClose;
    }
    return false;
  }

  set preventClose(value: boolean) {
    if (this.panel.current) {
      (this.panel.current as IPopupComponent).preventClose = value;
    }
  }

  handleMouseDown = () => {
    clearTimeout(this.timeID);
    if (this.panel.current) {
      (this.panel.current as IPopupComponent).preventClose = true;
    }
  };

  handleMouseUp = () => {
    this.timeID = window.setTimeout(() => {
      if (this.panel.current) {
        (this.panel.current as IPopupComponent).preventClose = false;
      }
    });
  };

  // @ts-ignore
  panel: React.RefObject<PanelClass> = React.createRef();

  render() {
    return (
      <PanelClass ref={this.panel} {...this.props} onMouseUp={this.handleMouseUp} onMouseDown={this.handleMouseDown} />
    );
  }
}
