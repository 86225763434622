import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// 文档
export default {
  ...flowBase,
  name: i18n('resource.flow.document'),
  type: 'document',
  thumb: {
    spriteIconClass: SpriteThumb.Document.className,
    dragPosition: SpriteThumb.Document.position,
  },
  template: getShapeData(
    i18n('resource.flow.document'),
    { width: 100, height: 57 },
    {
      data: [
        {
          point: {
            x: 100,
            y: 50,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 50,
            y: 50,
          },
          handleIn: {
            x: 26,
            y: -15,
          },
          handleOut: {
            x: -26,
            y: 15,
          },
        },
        {
          point: {
            x: 0,
            y: 50,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 100,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
      closed: true,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
