import { IPrototypeResponse, IElement, TImageSize } from 'mock-ai-sdk';
import { nlToPrototype, nlToImage, nlToTxt, nlToTranslate, TranslateLanguage, nlToTable, nlToChart } from '@/apis/app';
// import { getTemplateData } from './MockAi_mock';

export const enum AIType {
  Translate = 'translate',
  Property = 'property',
  Content = 'content',
  Image = 'image',
  Chart = 'chart',
  Table = 'table',
  None = 'none',
}

/**
 * 图表类型
 */
export enum EChartType {
  /** 柱状图 */
  ColumnChart = 'column-chart',
  /** 条形图 */
  BarChart = 'bar-chart',
  /** 折线图 */
  Line = 'line-chart',
  /** 环形图 */
  Circle = 'circle-chart',
  /** 饼状图 */
  Pie = 'pie-chart',
  /** 柱折组合图 */
  LineBartChart = 'line-bar-chart',
  /** 散点图 */
  Point = 'point-chart',
  /** 雷达图 */
  Radar = 'radar-chart',
  /** 面积图 */
  Area = 'area-chart',
}

export enum AICreateStatus {
  Normal = 'normal',
  Creating = 'creating',
  Success = 'success',
  Failed = 'failed',
}

export enum GenerateImageType {
  Placeholder = 'placeholder',
  Grey = 'grey',
  Colorful = 'colorful',
}

export enum AIErorrCode {
  Erorr3100 = '3100', // 所剩点数不足以扣点生成
  Erorr3101 = '3101', // 生成的数据无法构成 组件数据
  Erorr3102 = '3102', // 文本生成失败，本次操作不消耗点数，请重试
}

export interface IPropertyLayout {
  width?: number;
  height?: number;
  imageCategory?: string;
}

export type ResponseTable = Array<string[]>;
export interface IResponseChart {
  column: string[];
  data: string[];
  type: EChartType;
}

type AIResultData = IPrototypeResponse<keyof IElement> | string[] | string | ResponseTable | IResponseChart;

interface IAICreatePramsBase {
  size?: TImageSize;
  limit?: number;
  useImage?: boolean;
  imageMode?: GenerateImageType;
}

interface IAICreatePrams extends IAICreatePramsBase {
  text: string;
  teamID: string;
}

// interface IAITranslatePrams {
//   text: string[];
//   language?: TranslateLanguage;
// }

type PrevFN = null | (() => void);

class MockAi {
  /** AI是否已开通可用状态 */
  enable = false;
  _usableMoney: number = 0;
  usableMoneyUnit: string = 'pt';
  // data: AIResultData;

  imagesMap = new Map<string, string[]>();
  images: string[] = []; // 本地图片库存
  icons: { [k: string]: any[] } = {}; // 本地图标库

  isCreated: boolean = false;
  // mockAI: MockAI;
  currentParams: IAICreatePrams | undefined;
  private responseMap: { [id: string]: AIResultData } = {};
  private templateConfig = new Map<string, <T>(data: any, layout?: IPropertyLayout) => T>();
  private timeout: number = 500; // AI生成超时 秒
  private _page: any;

  // 上一次的请求
  private prevApiCancel: PrevFN = () => {};
  private _done: (data?: any) => void;

  get usableMoney(): number {
    return this._usableMoney;
  }

  set usableMoney(val: number) {
    this._usableMoney = Math.max(val, 0);
  }

  get prevCancel() {
    return this.prevApiCancel;
  }

  set preCancel(val: () => void) {
    this.prevApiCancel?.();
    this.prevApiCancel = null;
    this.prevApiCancel = val;
  }

  public cancel() {
    this.prevApiCancel?.();
  }

  constructor() {
    this._done = () => {};
    // Object.assign(this.config, config || {});
  }

  private async doPrototype(currentParams: IAICreatePrams): Promise<IPrototypeResponse<keyof IElement>> {
    const { teamID, text } = currentParams;
    const { api, cancel } = await nlToPrototype(text, teamID);
    this.preCancel = cancel;
    return api;
  }

  private async doImage(currentParams: IAICreatePrams): Promise<string[]> {
    const { text, size, teamID, limit: num = 1 } = currentParams;
    const { api, cancel } = await nlToImage(text, teamID, size, num);
    this.preCancel = cancel;
    return api;
  }

  private async doText(currentParams: IAICreatePrams): Promise<string[]> {
    const { text, teamID, limit = 1 } = currentParams;
    const { api, cancel } = await nlToTxt(text, teamID, limit);
    this.preCancel = cancel;
    return api;
  }

  private async doTable(currentParams: IAICreatePrams): Promise<string[]> {
    const { text, teamID } = currentParams;
    const { api, cancel } = await nlToTable(text, teamID);
    this.preCancel = cancel;
    return api;
  }

  private async doCharts(currentParams: IAICreatePrams): Promise<string[]> {
    const { text, teamID } = currentParams;
    const { api, cancel } = await nlToChart(text, teamID);
    this.preCancel = cancel;
    return api;
  }

  private async doTranslate(
    text: string[],
    teamID: string,
    language: TranslateLanguage = TranslateLanguage.English,
  ): Promise<string[]> {
    const { api, cancel } = await nlToTranslate(text, teamID, language);
    this.preCancel = cancel;
    return api;
  }

  setPage<T>(data: T) {
    this._page = data;
  }

  getPage<T>(): T | undefined {
    return this._page as T;
  }

  /**
   * 设置AI关联模板配置
   * @param k
   * @param v
   */
  setTemplateConfig<T = AIType.Property>(
    t: AIType,
    k: string,
    v: (
      data: T extends AIType.Property
        ? IElement
        : T extends AIType.Table
        ? ResponseTable
        : T extends AIType.Chart
        ? IResponseChart
        : string,
      layout?: IPropertyLayout,
    ) => any,
  ) {
    this.templateConfig.set(`[${t}]${k}`, v as any);
  }

  async translate(
    prompt: string[],
    teamID: string,
    language: TranslateLanguage = TranslateLanguage.English,
  ): Promise<string[]> {
    const tid = setTimeout(() => {
      throw 'timeout';
    }, this.timeout * 600);

    this.responseMap[AIType.Translate] = [];

    try {
      this.responseMap[AIType.Translate] = await this.doTranslate(prompt, teamID, language);
    } finally {
      clearTimeout(tid);
    }
    return this.responseMap[AIType.Translate] as string[];
  }

  /**
   * AI对话内容
   */
  prompt(type: AIType.Image | AIType.Content, params: IAICreatePrams): Promise<string[]>;
  prompt(type: AIType.Property, params: IAICreatePrams): Promise<IPrototypeResponse<keyof IElement>>;
  prompt(type: AIType.Table, params: IAICreatePrams): Promise<ResponseTable>;
  prompt(type: AIType.Chart, params: IAICreatePrams): Promise<IResponseChart | undefined>;
  async prompt(type: AIType, params: IAICreatePrams) {
    const { text } = params;
    if (!text) {
      return;
    }

    this.currentParams = params;

    //..
    // return { elements: ['1'] };

    const tid = setTimeout(() => {
      throw 'timeout';
    }, this.timeout * 600);

    try {
      if (type === AIType.Property) {
        this.responseMap[type] = await this.doPrototype(this.currentParams);
      }
      if (type === AIType.Image) {
        this.responseMap[type] = await this.doImage(this.currentParams);
      }
      if (type === AIType.Content) {
        this.responseMap[type] = await this.doText(this.currentParams);
      }
      if (type === AIType.Table) {
        this.responseMap[type] = await this.doTable(this.currentParams);
      }
      if (type === AIType.Chart) {
        this.responseMap[type] = await this.doCharts(this.currentParams);
      }
    } catch (error) {
      clearTimeout(tid);
      throw error;
    }
    clearTimeout(tid);
    return this.responseMap[type];
  }

  allowCreate(): boolean {
    // 可用余额无法继续生成
    if (mockAI.usableMoney < 10) {
      return false;
    }
    return true;
  }

  /**
   * 生成RP组件
   */
  createRPComponents<T>(t: AIType): T[] {
    const compData: T[] = [];
    // 策略模式
    const getTypeData = {
      // 生成原型
      [AIType.Property]: () => {
        const data = this.responseMap[t] as IPrototypeResponse<keyof IElement>;
        data?.elements?.forEach((ele) => {
          const resdata = mockAI.templateConfig.get(`[${t}]${ele.elementName}`)?.<T>(
            {
              [ele.elementName]: ele,
            },
            {
              width: data.layout.width,
              height: data.layout.height,
              imageCategory: data.layout.type,
            },
          );
          resdata && compData.push(resdata);
        });
      },
      // 生成图片
      [AIType.Image]: () => {
        const data = (this.responseMap[t] as string[]).map((v) => {
          if (v.startsWith('http')) {
            return v;
          } else {
            const i = Math.floor(Math.random() * this.images.length);
            return this.images[i];
          }
        });
        data.forEach((v) => {
          const res = mockAI.templateConfig.get(`[${t}]`)?.<T>(v);
          res && compData.push(res);
        });
      },
      // 生成文本内容
      [AIType.Content]: () => {
        const data = (this.responseMap[t] instanceof Array ? this.responseMap[t] : []) as string[];
        data.forEach((v) => {
          const res = mockAI.templateConfig.get(`[${t}]`)?.<T>(v);
          res && compData.push(res);
        });
      },
      // 生成表格
      [AIType.Table]: () => {
        const data = (this.responseMap[t] instanceof Array ? this.responseMap[t] : []) as string[];
        const res = mockAI.templateConfig.get(`[${t}]`)?.<T>(data);
        res && compData.push(res);
      },
      // 生成图表
      [AIType.Chart]: () => {
        const data = this.responseMap[t];
        const res = mockAI.templateConfig.get(`[${t}]`)?.<T>(data);
        res && compData.push(res);
      },
    };

    getTypeData[t as keyof typeof getTypeData]?.();

    return compData;
  }

  /**
   * 生成 图标代码
   */
  makeIconCode(category: string = ''): number {
    const iconKeys = Object.keys(this.icons);
    const keyLength = Math.floor(Math.random() * iconKeys.length);
    const iconType = iconKeys[keyLength];
    const values = this.icons[category] || this.icons[iconType];
    const valLength = Math.floor(Math.random() * values.length);
    const iconCode = values[valLength].iconCode;
    window.debug && console.log('get IconCode', category, `number: ${valLength}`, iconCode);
    return iconCode;
  }

  getLocalImage(category: string = '') {
    const images = this.imagesMap.get(category) || this.images;
    const i = Math.floor(Math.random() * images.length);
    return images[i];
  }

  getRandom(min: number = 0, max: number = 100) {
    return min + Math.floor(Math.random() * (min > max ? min + 1 : max + 1));
  }

  execute(done: (params: any) => void) {
    this.isCreated = false;
    this._done = done;
  }

  done<T = any>(core: T) {
    this.isCreated = true;
    this._done?.(core);
  }
}

const mockAI = new MockAi();

export default mockAI;
