import IArtboard from '@fbs/rp/models/artboard';
import * as artboard from '../artboard';
// import { getOfflineDemoData } from './data';
import { getAllArtboardsByNodeID } from './helper';

/**
 * 获取页面所有画板(离线演示包)
 */
async function getAllPreviewArtboardsByNodeID(nodeID: string): Promise<IArtboard[]> {
  return getAllArtboardsByNodeID(nodeID);

  // return new Promise((resolve, reject) => {
  //   const allArtboards = getOfflineDemoData()?.artboard;
  //   if (allArtboards) {
  //     const artboards = allArtboards[nodeID];
  //     resolve(artboards);
  //   } else {
  //     reject();
  //   }
  // });
}

export default {
  ...artboard,
  getAllPreviewArtboardsByNodeID,
};
