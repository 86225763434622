import * as React from 'react';
import classnames from 'classnames';

import i18n from '@/i18n';

import { Icon } from '@/dsm';

import { getDateByYearMonth, getDateFormat, getCurrentYearMonth } from '../utils';
import { DeviceType } from '../config';
import { IDateInfo } from '..';
import { EDatePickerType } from '@/fbs/rp/models/properties/common';

import DateComponent from '../DateComponent';

import './index.scss';

interface IDatePickerComponentProps {
  dateFormat: any;
  datePickerWidth: number;
  dateType: EDatePickerType;
  selectedInfo?: IDateInfo;
  startDateInfo?: IDateInfo;
  endDateInfo?: IDateInfo;
  deviceType?: DeviceType;
  isPreview?: boolean;
  onSelectDate: (date?: IDateInfo) => void;
  onSelectedRangeDate?: (startDate: IDateInfo, endDate?: IDateInfo) => void;
  onClearDateInfo: () => void;
}
interface IDatePickerComponentState {
  month: number;
  year: number;
  currentDate: Date;
}
const MONTH_NUMBER = 12;
const MINWIDTH_NORMAL = 300;
const MINWIDTH_RANGE = 600;
export default class WebDatePicker extends React.Component<IDatePickerComponentProps, IDatePickerComponentState> {
  constructor(props: IDatePickerComponentProps) {
    super(props);
    this.state = {
      month: 0,
      year: 0,
      currentDate: new Date(),
    };
  }

  componentDidMount() {
    const { selectedInfo, startDateInfo } = this.props;

    if (selectedInfo || startDateInfo) {
      const date = new Date();
      date.setFullYear(selectedInfo ? selectedInfo.year : startDateInfo!.year);
      date.setMonth(selectedInfo ? selectedInfo.month - 1 : startDateInfo!.month - 1);
      this.setState({ year: getCurrentYearMonth(date).year, month: getCurrentYearMonth(date).month });
    } else {
      this.setState({
        year: getCurrentYearMonth(this.state.currentDate).year,
        month: getCurrentYearMonth(this.state.currentDate).month,
      });
    }
  }

  getDateRangeInfo = () => {
    const { startDateInfo, endDateInfo } = this.props;
    if (startDateInfo && !endDateInfo) {
      return [getDateByYearMonth(startDateInfo.year, startDateInfo.month, startDateInfo.date).getTime()];
    } else if (startDateInfo && endDateInfo) {
      return [
        getDateByYearMonth(startDateInfo.year, startDateInfo.month, startDateInfo.date).getTime(),
        getDateByYearMonth(endDateInfo.year, endDateInfo.month, endDateInfo.date).getTime(),
      ];
    } else {
      return [];
    }
  };

  handleMonthChange(monthChange: number) {
    const { month, year } = this.state;
    const monthAfter = month + monthChange;
    const date = getDateByYearMonth(year, monthAfter);
    this.setState({
      year: getCurrentYearMonth(date).year,
      month: getCurrentYearMonth(date).month,
    });
  }

  handleYearChange(yearChange: number) {
    const { month, year } = this.state;
    const AfterYear = year + yearChange;
    const date = getDateByYearMonth(AfterYear, month);
    this.setState({
      year: getCurrentYearMonth(date).year,
      month: getCurrentYearMonth(date).month,
    });
  }

  handleClearSelected = () => {
    this.props.onClearDateInfo();
    this.setState({
      year: getCurrentYearMonth(this.state.currentDate).year,
      month: getCurrentYearMonth(this.state.currentDate).month,
    });
  };

  handleSelectDate = (dateInfo: IDateInfo) => {
    const { year, month, date } = dateInfo;
    let dateRangeInfo: number[] = this.getDateRangeInfo();
    const dateTime = getDateByYearMonth(year, month, date).getTime();
    switch (this.props.dateType) {
      case EDatePickerType.Normal:
        this.props.onSelectDate(dateInfo);
        this.props.onSelectedRangeDate && this.props.onSelectedRangeDate(dateInfo, undefined);
        break;
      case EDatePickerType.Range:
        this.props.onSelectDate(undefined);
        if (dateRangeInfo.length === 0) {
          dateRangeInfo.push(dateTime);
        } else if (dateRangeInfo.length === 1) {
          if (dateTime <= dateRangeInfo[0]) {
            dateRangeInfo = [];
            dateRangeInfo.push(dateTime);
          } else {
            dateRangeInfo.push(dateTime);
          }
        } else {
          dateRangeInfo = [];
          dateRangeInfo.push(dateTime);
        }
        this.props.onSelectedRangeDate &&
          this.props.onSelectedRangeDate(
            getCurrentYearMonth(new Date(Math.min(...dateRangeInfo))),
            dateRangeInfo.length === 1 ? undefined : getCurrentYearMonth(new Date(Math.max(...dateRangeInfo))),
          );
        break;
      default:
        break;
    }
  };

  private renderDate = (index: 0 | 1, dateType: EDatePickerType) => {
    const { selectedInfo, startDateInfo, endDateInfo, dateFormat } = this.props;
    switch (dateType) {
      case EDatePickerType.Normal:
        if (!selectedInfo) {
          return <div className="head-input-placeholder">{i18n('resource.components.datePickerInfo.optionDate')}</div>;
        } else {
          return <div className="head-input-value">{getDateFormat(dateFormat, selectedInfo)}</div>;
        }
      case EDatePickerType.Range:
        if (index === 0) {
          return startDateInfo ? (
            <div className="head-input-value">{getDateFormat(dateFormat, startDateInfo)}</div>
          ) : (
            <div className="head-input-placeholder">{i18n('resource.components.datePickerInfo.startDate')}</div>
          );
        } else {
          return endDateInfo ? (
            <div className="head-input-value">{getDateFormat(dateFormat, endDateInfo)}</div>
          ) : (
            <div className="head-input-placeholder">{i18n('resource.components.datePickerInfo.endDate')}</div>
          );
        }
      default:
        break;
    }
  };

  renderDatePickerWithDateType() {
    const { month, year } = this.state;
    const {
      selectedInfo,
      isPreview,
      dateType,
      startDateInfo,
      endDateInfo,
      deviceType,
      datePickerWidth,
      dateFormat,
    } = this.props;
    const minWidth = {
      [EDatePickerType.Normal]: MINWIDTH_NORMAL,
      [EDatePickerType.Range]: MINWIDTH_RANGE,
    };

    return (
      <div
        style={{
          width: datePickerWidth > minWidth[dateType] && isPreview ? datePickerWidth : '',
          minWidth: minWidth[dateType],
        }}
        className={classnames('date-picker-content-web', {
          'date-picker-content-range': dateType === EDatePickerType.Range,
        })}
      >
        <div className="head-input">
          <div className="head-input-date-info">
            {this.renderDate(0, dateType)}
            {dateType === EDatePickerType.Range && <> ~ {this.renderDate(1, dateType)}</>}
          </div>

          {(startDateInfo || selectedInfo) && (
            <Icon className="head-clear" cls="icon_assembly_eliminate_rp" onClick={this.handleClearSelected} />
          )}
        </div>
        <div className="content-details">
          <div className="head-select">
            <div>
              <Icon
                className="month-forward"
                cls="icon_assembly_year_rp"
                onClick={this.handleYearChange.bind(this, -1)}
              />
              <Icon
                className="year-forward"
                cls="icon_assembly_month_rp"
                onClick={this.handleMonthChange.bind(this, -1)}
              />
            </div>

            <span className={classnames({ 'forward-date-range': dateType === EDatePickerType.Range })}>
              {getDateFormat(dateFormat, { year: year, month: month, date: 0 }, false, true)}
            </span>
            {dateType === EDatePickerType.Range && (
              <span className={classnames({ 'next-date-range': dateType === EDatePickerType.Range })}>
                {getDateFormat(
                  dateFormat,
                  {
                    year: month === MONTH_NUMBER ? year + 1 : year,
                    month: month === MONTH_NUMBER ? 1 : month + 1,
                    date: 0,
                  },
                  false,
                  true,
                )}
              </span>
            )}
            <div>
              <Icon className="year-next" cls="icon_assembly_month_rp" onClick={this.handleMonthChange.bind(this, 1)} />
              <Icon className="month-next" cls="icon_assembly_year_rp" onClick={this.handleYearChange.bind(this, 1)} />
            </div>
          </div>
          <div className={classnames({ 'date-component-group': dateType === EDatePickerType.Range })}>
            <DateComponent
              deviceType={deviceType}
              showWeek
              dateInfo={{ year, month }}
              dateType={dateType}
              onSelectDate={this.handleSelectDate}
              selectedInfo={selectedInfo}
              startDateInfo={startDateInfo}
              endDateInfo={endDateInfo}
              isPreview={isPreview}
            />
            {dateType === EDatePickerType.Range && <div className="date-component-group-separator"></div>}
            {dateType === EDatePickerType.Range && (
              <DateComponent
                deviceType={deviceType}
                showWeek
                dateInfo={{
                  year: month === MONTH_NUMBER ? year + 1 : year,
                  month: month === MONTH_NUMBER ? 1 : month + 1,
                }}
                dateType={dateType}
                onSelectDate={this.handleSelectDate}
                selectedInfo={selectedInfo}
                startDateInfo={startDateInfo}
                endDateInfo={endDateInfo}
                isPreview={isPreview}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
  render() {
    return this.renderDatePickerWithDateType();
  }
}
