import { isMockRPD, isMacOS } from '@utils/envUtils';

import { IAppInfo } from '@fbs/idoc/models/app';
import Routers from '@consts/router';
import DocCookies from '@/helpers/cookie';
// import { getFirstFrameFromVideoURL } from '@helpers/imageHelper';

const isDev = process.env.NODE_ENV === 'development';

const macTitleHeight = 24;
const winTitleHeight = 40;
const browserTitleHeight = 0;

const desktopTitleHeight = isMacOS ? macTitleHeight : winTitleHeight;
export const TITLE_HEIGHT = isMockRPD ? desktopTitleHeight : browserTitleHeight;

export enum DesktopChannelType {
  closeWindow = 'window-close',
  createEditorWindow = 'window-create-editor',
  minimizeWindow = 'window-minimize',
  maximizeWindow = 'window-maximize',
  unmaximizeWindow = 'window-unmaximize',
  setWindowPosition = 'window-position',
  setWindowSize = 'window-size',
  showMainWindow = 'window-main-show',
  openWebDevTools = 'web-open-dev-tools',
  login = 'login',
  logout = 'logout',
  reload = 'reload',
  message = 'message',
  createApp = 'create-app',
  loading = 'loading',
  loaded = 'loaded',
  readyToShow = 'ready-to-show',
  getLocalFonts = 'get-local-fonts',
  checkMaximize = 'checkMaximize',
  enterFullScreen = 'enter-full-screen',
  leaveFullScreen = 'leave-full-screen',
}

export enum DesktopEventType {
  maximize = 'maximize',
  unmaximize = 'unmaximize',
  reload = 'reload',
  updateCheck = 'update-check',
  updateProgress = 'update-progress',
  login = 'login',
  logout = 'logout',
  message = 'message',
  createApp = 'create-app',
  targetWeb = 'target-web',
  allWeb = 'all-web',
  getLocalFonts = 'get-local-fonts',
  enterFullScreen = 'enter-full-screen',
  leaveFullScreen = 'leave-full-screen',
}

export enum DesktopInvokeChannel {
  getCaptureImage = 'get-capture-image',
  getToken = 'get-token',
  setToken = 'set-token',
  getOrigin = 'get-origin',
  checkFullScreen = 'check-full-screen',
}

export enum DesktopMessageTarget {
  targetWeb = 'target-web', // 发送消息到某个url下的窗口
  allWeb = 'all-web', // 发送消息到所有窗口
}

export enum DesktopMessageType {
  patchTeamInfo = 'patchTeamInfo', // 切换团队
  patchAppInfo = 'patchAppInfo', // 修改项目信息
}

export interface IDesktopMessage {
  type: DesktopMessageType;
  data: any;
}

/**
 * 文件信息
 */
interface IFile {
  sha512: string;
  size: number;
  url: string;
}

/**
 * 发布内容
 */
interface IPublishInfo {
  files: IFile[];
  path: string;
  releaseDate: Date;
  sha512: string;
  version: string;
}

/**
 * 更新检查
 */
export interface IUpdateCheck {
  version: string;
  published: IPublishInfo;
}

/**
 * 下载进度
 */
export interface IUpdateProgress {
  total: number; // 总量
  delta: number;
  transferred: number;
  percent: number; // 当前进度
  bytesPerSecond: number; // 速度(b/s)
}

export interface IIpcRenderer {
  send: (channel: DesktopChannelType, ...arg: any) => void;
  invoke: (channel: DesktopInvokeChannel, ...args: any) => Promise<any>;
  addListener: (event: DesktopEventType, listener: (...arg: any) => void) => void;
  removeListener: (event: DesktopEventType, listener: (...arg: any) => void) => void;
}

class DesktopServer {
  // private origin = isDev ? `http://${location.hostname}:3001` : location.origin;
  private get origin(): string {
    if (isDev) {
      return `http://${location.hostname}:3001`;
    }
    return location.origin;
  }
  // private electron?: IElectron;
  private ipcRenderer?: IIpcRenderer;

  constructor() {
    this.ipcRenderer = isMockRPD ? window.ipcRenderer : undefined;
    // 兼容旧版本 未销毁require
    if (isMockRPD && window.require && !this.ipcRenderer) {
      this.ipcRenderer = window.require('electron').ipcRenderer;
    }
  }

  openApp(appID: string) {
    const url = this.origin + Routers.routePrefix + `/editor/${appID}`;
    this.ipcRenderer?.send(DesktopChannelType.createEditorWindow, url);
    if (!isMockRPD) {
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  openPreview = (shareID: string, pageID?: string) => {
    const url = this.origin + Routers.routePrefix + `/run/${shareID}` + (pageID ? `/${pageID}` : '') + '?as=1';
    this.ipcRenderer?.send(DesktopChannelType.createEditorWindow, url);
    if (!isMockRPD) {
      window.open(url);
    }
  };

  openExample = (appID: string) => {
    const url = this.origin + `/example/${appID}`;
    this.ipcRenderer?.send(DesktopChannelType.createEditorWindow, url);
    if (!isMockRPD) {
      window.location.href = url;
    }
  };

  minimizeWindow() {
    this.ipcRenderer?.send(DesktopChannelType.minimizeWindow);
  }

  maximizeWindow() {
    this.ipcRenderer?.send(DesktopChannelType.maximizeWindow);
  }

  unmaximizeWindow() {
    this.ipcRenderer?.send(DesktopChannelType.unmaximizeWindow);
  }

  closeWindow() {
    this.ipcRenderer?.send(DesktopChannelType.closeWindow);
  }

  showHomepage() {
    this.ipcRenderer?.send(DesktopChannelType.showMainWindow);
    if (!isMockRPD) {
      location.href = `${this.origin}${Routers.PC_HOME}`;
    }
  }

  setPosition(offsetX: number, offsetY: number, animate?: boolean) {
    this.ipcRenderer?.send(DesktopChannelType.setWindowPosition, Math.floor(offsetX), Math.floor(offsetY), animate);
  }

  setSize(width: number, height: number, center?: boolean) {
    this.ipcRenderer?.send(DesktopChannelType.setWindowSize, Math.floor(width), Math.floor(height), center);
  }

  login(token?: string) {
    this.ipcRenderer?.send(DesktopChannelType.login, token);
  }

  logout() {
    DocCookies.cookie.removeItem('ds.sid.sig');
    DocCookies.cookie.removeItem('ds.sid');
    this.ipcRenderer?.send(DesktopChannelType.logout);
  }

  createApp(data: IAppInfo) {
    this.ipcRenderer?.send(DesktopChannelType.createApp, data);
  }

  postMessage(url: string, data: IDesktopMessage) {
    this.ipcRenderer?.send(DesktopChannelType.message, DesktopMessageTarget.targetWeb, {
      url,
      data,
    });
  }

  postAllMessage(data: IDesktopMessage) {
    this.ipcRenderer?.send(DesktopChannelType.message, DesktopMessageTarget.targetWeb, data);
  }

  /**
   * 获取当前截图
   */
  async getCaptureImage(): Promise<string> {
    const size = {
      width: Math.floor(innerWidth * window.devicePixelRatio),
      height: Math.floor(innerHeight * window.devicePixelRatio),
    };
    const result = await this.ipcRenderer?.invoke(DesktopInvokeChannel.getCaptureImage, size.width, size.height);
    return result;
  }

  async getToken(): Promise<string> {
    return await this.ipcRenderer?.invoke(DesktopInvokeChannel.getToken);
  }

  /**
   * 获取本地字体
   */
  async getLocalFonts(): Promise<string[]> {
    this.ipcRenderer?.send(DesktopChannelType.getLocalFonts);
    return new Promise((resolve, reject) => {
      this.ipcRenderer?.addListener(DesktopEventType.getLocalFonts, (e, res) => {
        return res ? resolve(res) : reject();
      });
    });
  }

  doLoading() {
    this.ipcRenderer?.send(DesktopChannelType.loading);
  }

  doLoaded() {
    this.ipcRenderer?.send(DesktopChannelType.loaded);
  }

  /**
   * 告诉桌面端当前页面已经加载完毕了
   */
  readyToShow() {
    this.ipcRenderer?.send(DesktopChannelType.readyToShow);
  }

  /**
   * 提交当前是否是最大化状态检查
   */
  checkMaximize() {
    this.ipcRenderer?.send(DesktopChannelType.checkMaximize);
  }

  /**
   * 进入全屏
   */
  enterFullScreen() {
    this.ipcRenderer?.send(DesktopChannelType.enterFullScreen);
    if (!isMockRPD) {
      document.body.requestFullscreen();
    }
  }

  /**
   * 退出全屏
   */
  leaveFullScreen() {
    this.ipcRenderer?.send(DesktopChannelType.leaveFullScreen);
    if (!isMockRPD) {
      document.exitFullscreen();
    }
  }
}

export const desktopServer = new DesktopServer();

export default DesktopServer;
