/**
 * @desc 公有版本中音频与视频组件双击编辑URL值
 * @author FanYongLong
 */

import * as React from 'react';

import { withAutoClose, IAutoCloseComponentProps, IPopupComponent, Icon } from '@dsm';
import i18n from '@i18n';

import './index.scss';

interface IAudioVideoUrlEditorProps extends IAutoCloseComponentProps {
  value: string | undefined;
  editType: 'audio' | 'video';
  position: { left: number; top: number };
  size: { width: number; height: number };
  onClose(): void;
  onSelect?: (url: string, size?: { height: number; width: number }, fileName?: string) => void;
}
interface IAudioVideoUrlEditorState {
  value: string;
}

class AudioVideoUrlEditorWrap extends React.Component<IAutoCloseComponentProps> {
  render() {
    return this.props.children;
  }
}
const AutoCloseAudioVideoUrlEditor = withAutoClose(AudioVideoUrlEditorWrap);
class AudioVideoUrlEditor extends React.Component<IAudioVideoUrlEditorProps, IAudioVideoUrlEditorState> {
  private closeRef: IPopupComponent | null = null;
  private inputRef = React.createRef<HTMLInputElement>();
  private _isComposition = false;
  private _isExit = false;
  constructor(props: IAudioVideoUrlEditorProps) {
    super(props);
    this.state = {
      value: props.value ?? '',
    };
  }

  componentDidMount() {
    this.inputRef.current?.focus();
    this.inputRef.current?.select();
  }

  componentWillUnmount() {
    if (document.activeElement === this.inputRef.current) {
      this.handleBlur();
    }
  }
  handleRef = (current: IPopupComponent | null) => {
    this.closeRef = current as IPopupComponent;
    if (this.closeRef) {
      this.closeRef.preventClose = true;
    }
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    if (!this._isComposition && e.key.toLowerCase() === 'enter') {
      this.handleBlur();
    }
  };

  handleCompositionStart = () => {
    this._isComposition = true;
  };

  handleCompositionEnd = () => {
    this._isComposition = false;
  };

  handleClose = () => {
    //this.props.onClose();
  };

  handleChange = (e: React.FormEvent) => {
    const target = e.target as HTMLInputElement;
    this._isComposition = (e.nativeEvent as InputEvent).isComposing;
    this.setState({ value: target.value.trim() });
  };

  handleBlur = () => {
    if (this._isExit) {
      return;
    }
    this._isExit = true;
    this.closeRef!.preventClose = false;
    let value = this.state.value;
    if (value !== '' && !value.toLowerCase().startsWith('https://')) {
      value = 'https://' + value.replace(/^\w*:\/\//, '');
    }
    if (this.props.onSelect) {
      this.props.onSelect(value, this.props.size);
    }
    this.props.onClose();
  };

  handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleRedirectHelpPage = (e: React.MouseEvent) => {
    const openUrl = this.props.editType === 'audio' ? i18n('tips.audioEditHelpUrl') : i18n('tips.videoEditHelpUrl');
    window.open(openUrl, '_blank');
    e.preventDefault();
    e.stopPropagation();
  };

  handleRedirectTutorialPage = () => {
    window.open(i18n('tips.audioVideoEditTutorialUrl'), '_blank');
  };

  handleCloseGuideDialog = () => {
    this.forceUpdate(() => {
      this.inputRef.current?.focus();
      this.inputRef.current?.select();
    });
  };

  render() {
    const { position, editType } = this.props;
    const style: React.CSSProperties = Object.assign({}, position);
    const placeholder = editType === 'audio' ? i18n('tips.audioUrlPlaceholder') : i18n('tips.videoUrlPlaceholder');

    return (
      <AutoCloseAudioVideoUrlEditor ref={this.handleRef} onClose={this.handleClose}>
        <div
          onContextMenu={this.handleContextMenu}
          ref={this.props.forwardedRef}
          className="audio-video-url-editor"
          style={style}
        >
          <input
            ref={this.inputRef}
            type="text"
            placeholder={placeholder}
            value={this.state.value}
            className="audio-video-input"
            onCompositionStart={this.handleCompositionStart}
            onCompositionEnd={this.handleCompositionEnd}
            onKeyDown={this.handleKeyDown}
            onBlur={this.handleBlur}
            onInput={this.handleChange}
            onChange={this.handleChange}
          ></input>
          <div className="audio-video-input-suffix">
            <Icon
              cls="icon_workbench_doubt_dt"
              onMouseDown={this.handleRedirectHelpPage}
              tips={i18n('extend.tutorial')}
            ></Icon>
          </div>
        </div>
      </AutoCloseAudioVideoUrlEditor>
    );
  }
}

export default AudioVideoUrlEditor;
