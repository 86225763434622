import * as React from 'react';
import * as tinycolor from 'tinycolor2';
import { throttle } from 'lodash';
import { IColorInfo, checkRange } from './models';

interface ISaturationProps {
  color: IColorInfo;

  changeColor(color: tinycolor.ColorFormats.HSVA): void;
}

class Saturation extends React.Component<ISaturationProps> {
  container: HTMLElement | null;

  constructor(props: ISaturationProps) {
    super(props);
    this.container = null;
    this.handleChange = throttle(this.handleChange.bind(this), 20);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.unbindEventListeners = this.unbindEventListeners.bind(this);
  }

  handleMouseDown() {
    window.addEventListener('mousemove', this.handleChange);
    window.addEventListener('mouseup', this.handleChange);
    window.addEventListener('mouseup', this.unbindEventListeners);
  }

  unbindEventListeners() {
    window.removeEventListener('mousemove', this.handleChange);
    window.removeEventListener('mouseup', this.handleChange);
    window.removeEventListener('mouseup', this.unbindEventListeners);
  }

  handleChange(e: MouseEvent) {
    const { container } = this;
    if (!container) {
      return;
    }
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    const rect = container.getBoundingClientRect();
    const offsetX = rect.left + (window.scrollX || window.pageXOffset);
    const offsetY = rect.top + (window.scrollY || window.pageYOffset);
    let left = e.pageX - offsetX;
    let top = e.pageY - offsetY;

    left = checkRange(left, [0, containerWidth]);
    top = checkRange(top, [0, containerHeight]);

    const saturation = left / containerWidth;
    let bright = -(top / containerHeight) + 1;

    bright = checkRange(bright, [0, 1]);

    this.changeColor({
      h: this.props.color.hsv.h,
      s: saturation,
      v: bright,
      a: this.props.color.hsv.a,
    });
  }

  changeColor(param: tinycolor.ColorFormats.HSVA) {
    this.props.changeColor(param);
  }

  render() {
    const left = `${this.props.color.hsv.s * 100}%`;
    const top = `${(1 - this.props.color.hsv.v) * 100}%`;
    const pointerStyle = {
      left,
      top,
    };
    const style = {
      background: `hsl(${this.props.color.hsv.h}, 100%, 50%)`,
    };
    return (
      <div
        className="saturation-panel"
        ref={(div) => (this.container = div)}
        style={style}
        onMouseDown={this.handleMouseDown}
      >
        <div className="saturation-white" />
        <div className="saturation-black" />
        <div className="saturation-pointer" style={pointerStyle}>
          <div className="saturation-circle" />
        </div>
      </div>
    );
  }
}

export default Saturation;
