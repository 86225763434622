import { TimeFormatType } from '../constants';
import { CSSProperties, ReactNode } from 'react';

export interface ITableColumn {
  id: string | number;
  type?: IColumnType;
  parentText?: string[];
  text: string;
  width: number | string;
  minWidth?: number | string;
  // 列标识
  dataIndex: string;
  align?: ITableColumnAlign;
  // type为time的时候才有效
  timeFormat?: TimeFormatType | string;
  showOnHover?: boolean;
  sort?: boolean; // 启用排序
  searchHighlight?: boolean;
  style?: CSSProperties;
  thStyle?: CSSProperties;
  icon?: string; //操作列的icon
  sortIcon?: ISortIcon; //排序自定义icon
  filterIcon?: ReactNode; //筛选的icon
}

export enum ITableColumnAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

// 单元格数据
export type ICellData = {
  text: string;
};

// 单元格
export type ITableCell = {
  type?: string;
  data: ICellData;
  dataSource: string[];
};

export interface ITableRow {
  id: string;
}

export enum IColumnType {
  Operate = 'operate',
  Order = 'order',
  Time = 'time',
  Role = 'role',
}

export type ITableData = any;

export type IPoint = {
  x: number;
  y: number;
};

export enum TableSelection {
  None = 'none',
  Checkbox = 'checkbox',
  // Radio = 'radio',
}

export interface ITableSort {
  key: string;
  sort: ITableSortOrder;
}

export type ITableSortOrder = 'ASC' | 'DESC' | 'NONE';

export interface ISortIcon {
  Asc?: string;
  Desc?: string;
  None?: string;
}

export interface ICompareRefs {
  contentRef: React.RefObject<HTMLElement>;
  compareRef: React.RefObject<HTMLElement>;
}
