import * as React from 'react';

import { Color, getDeviceRatio, rgb2Hex } from '@utils/graphicsUtils';
import { isMockRPD } from '@/utils/envUtils';

import i18n from '@i18n';

import { sendMessageToExtensions, extensionsType, extensionError } from '@/services/chromeExt';
import { MouseButtons } from '@consts/enums/mouseButton';
import { desktopServer } from '@/services/desktop';
import { spreadImageData } from '@/helpers/imageHelper';

import Dialog from '../../Dialog';
import Button from '../../Button';

import './index.scss';

export interface IColorExtractorProps {
  onClick?: (rgba: Color) => void;
  onCancel?: () => void;
  onDownloadExtension: () => void;
  x: number;
  y: number;
}

export interface IColorExtractorState {
  clientX: number;
  clientY: number;
  rgba: number[];
  bg?: string;
  showDialog: boolean;
  errorType?: string;
}

enum keyDownType {
  enter = 13,
  left = 37,
  up = 38,
  right = 39,
  down = 40,
  esc = 27,
}

const RATIO = 10;
const pixelCount = 19;

const pixelBoxSize = pixelCount * RATIO;

class ColorExtractor extends React.Component<IColorExtractorProps, IColorExtractorState> {
  collectorRef: React.RefObject<HTMLCanvasElement> = React.createRef();

  canvasElement: HTMLCanvasElement;

  backgroundCTX: CanvasRenderingContext2D | null;

  constructor(props: IColorExtractorProps) {
    super(props);

    this.state = {
      clientX: -pixelBoxSize,
      clientY: -pixelBoxSize,
      rgba: [255, 255, 255, 1],
      showDialog: false,
    };
    this.canvasElement = document.createElement('canvas');
    this.backgroundCTX = this.canvasElement.getContext('2d');
  }

  hasBackground = false;

  componentDidMount() {
    this.loadCaptureImage();
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('keydown', this.handleKeydown);
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mousedown', this.handleMouseDown, { capture: true });
    window.addEventListener('wheel', this.handleWheel, { capture: true, passive: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mousedown', this.handleMouseDown, { capture: true });
    window.removeEventListener('wheel', this.handleWheel, { capture: true });
    this.backgroundCTX = null;
    this.canvasElement.remove();
  }

  get hasCurrent() {
    return !!this.collectorRef.current;
  }

  loadCaptureImage = () => {
    const getCaptureImage = isMockRPD
      ? desktopServer.getCaptureImage()
      : sendMessageToExtensions<string>(extensionsType.captured);
    getCaptureImage
      .then((imgURL) => {
        const image = new Image();
        image.src = imgURL;
        image.onload = () => {
          if (!this.backgroundCTX) {
            return false;
          }
          const { x, y } = this.props;
          const ratio = getDeviceRatio(this.backgroundCTX);
          this.backgroundCTX.scale(ratio, ratio);
          this.canvasElement.width = image.width;
          this.canvasElement.height = image.height;
          this.backgroundCTX.drawImage(image, 0, 0);
          this.hasBackground = true;
          this.hasCurrent &&
            this.setState(
              {
                bg: imgURL,
                clientX: x,
                clientY: y,
              },
              () => {
                this.doCollectorData(x, y);
              },
            );
        };
      })
      .catch((errorType) => {
        console.error('[rp-color-picker][capture-image]: ', errorType);
        this.hasCurrent && this.setState({ showDialog: true, errorType: errorType });
      });
  };

  handleWindowResize = () => {
    if (this.hasCurrent) {
      this.onCancel();
    }
  };

  onCancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
  };

  onSubmit() {
    const { onClick } = this.props;
    const { rgba, showDialog } = this.state;
    if (showDialog) {
      return false;
    }
    const _rgba = { r: rgba[0], g: rgba[1], b: rgba[2], a: rgba[3] };
    onClick && onClick(_rgba);
  }

  drawCollector(imageData: ImageData) {
    window.requestAnimationFrame(() => {
      const ctx = this.collectorRef.current?.getContext('2d');
      if (!ctx) {
        return;
      }
      ctx.putImageData(spreadImageData(imageData, RATIO), 0, 0);
    });
  }

  doCollectorData(x: number, y: number) {
    if (this.backgroundCTX) {
      const ratio = getDeviceRatio(this.backgroundCTX);
      const rgbaData = this.backgroundCTX.getImageData(
        x * ratio - (pixelCount - 1) / 2,
        y * ratio - (pixelCount - 1) / 2,
        pixelCount * ratio,
        pixelCount * ratio,
      );
      const center = this.backgroundCTX.getImageData(x * ratio, y * ratio, 1, 1);
      const [r, g, b, a] = center.data;
      this.drawCollector(rgbaData);

      this.setState({
        clientX: x,
        clientY: y,
        rgba: [r, g, b, a / 255],
      });
    }
  }

  handleWheel = (e: MouseWheelEvent) => {
    e.cancelBubble = true;
    e.preventDefault();
  };

  handleMouseMove = (e: MouseEvent) => {
    if (this.hasBackground) {
      this.doCollectorData(e.clientX, e.clientY);
    }
  };
  handleKeydown = (e: KeyboardEvent) => {
    if (this.hasCurrent) {
      e.preventDefault();
      e.stopPropagation();
      // const { clientX, clientY } = this.state;
      switch (e.keyCode) {
        // case keyDownType.enter:
        //   this.onSubmit();
        //   break;
        case keyDownType.esc:
          this.onCancel();
          break;
        // case keyDownType.left:
        //   this.doCollectorData(clientX - 1, clientY);
        //   break;
        // case keyDownType.up:
        //   this.doCollectorData(clientX, clientY - 1);
        //   break;
        // case keyDownType.right:
        //   this.doCollectorData(clientX + 1, clientY);
        //   break;
        // case keyDownType.down:
        //   this.doCollectorData(clientX, clientY + 1);
        //   break;
        default:
          break;
      }
    }
  };
  handleMouseDown = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.buttons !== MouseButtons.Left) {
      return;
    }
    this.onSubmit();
  };

  handleOpenTutorial = () => {
    window.open(i18n('extend.chrome.tutorialURL'), '_blank');
  };

  renderSharp() {
    const [r, g, b] = [...this.state.rgba];
    const sharp = rgb2Hex({ r, g, b });
    return <span className="dsm-c-rp-color-collector-content-text-sharp">#{sharp}</span>;
  }

  renderDialog() {
    const { onDownloadExtension } = this.props;
    const title =
      this.state.errorType === extensionError.version
        ? i18n('extend.version')
        : i18n('extend.chrome.noExtend.colorExtractor');
    return (
      <Dialog onClose={this.onCancel} backFade>
        <p>{title}</p>
        <div className="dsm-c-rp-color-collector-alert-button-group footer-without-separator">
          <Button theme="dialog" activated onClick={onDownloadExtension}>
            {i18n('general.download')}
          </Button>
          <Button theme="dialog" activated onClick={this.handleOpenTutorial}>
            {i18n('extend.tutorial')}
          </Button>
          <Button theme="dialog" onClick={this.onCancel}>
            {i18n('general.cancel')}
          </Button>
        </div>
      </Dialog>
    );
  }

  render() {
    const { clientX: left, clientY: top, showDialog, bg } = this.state;
    const style = {
      top,
      left,
      width: pixelBoxSize,
      height: pixelBoxSize,
    };

    return showDialog ? (
      this.renderDialog()
    ) : (
      <div className="dsm-c-rp-color-collector" style={{ backgroundImage: `url(${bg})` }}>
        <div className="dsm-c-rp-color-collector-content" style={style}>
          <canvas width={pixelBoxSize} height={pixelBoxSize} ref={this.collectorRef} />
          <div className="dsm-c-rp-color-collector-content-text">{this.renderSharp()}</div>
        </div>
      </div>
    );
  }
}

export default ColorExtractor;
