import * as React from 'react';
import classnames from 'classnames';
import { isNumber } from 'lodash';

import Icon from '../Icon';

import './index.scss';

const themeToClassNameMap: { [name: string]: string } = {
  border: 'dsm-c-rp-button-border',
  greyBorder: 'dsm-c-rp-button-grey-border',
  icon: 'dsm-c-rp-button-icon',
  onlyText: 'dsm-c-rp-button-only-text',
  dialog: 'dsm-c-rp-button-dialog',
};

const DefaultWidth = 60;
const DefaultHeight = 36;
const DefaultRadius = 4;

export interface IButtonProps {
  width?: number | string;
  height?: number | string;
  theme?: 'basic' | 'border' | 'greyBorder' | 'icon' | 'onlyText' | 'dialog';
  shape?: 'round';
  className?: string;
  activated?: boolean;
  icon?: string;
  disabled?: boolean;
  children?: any | string;
  onClick?: React.MouseEventHandler;
  onMouseDon?: React.MouseEventHandler;
  size?: number;
}

const Button: React.FC<IButtonProps> = (props: IButtonProps) => {
  const { theme, icon, disabled, onClick, children, width, height, className, activated, size, shape } = props;
  const themeClassName = theme ? themeToClassNameMap[theme] || '' : '';

  const borderRadius = shape === 'round' ? (isNumber(height) ? height : DefaultHeight) / 2 : DefaultRadius;
  return (
    <button
      className={classnames('dsm-c-rp-button', themeClassName, className, shape, {
        activated: activated,
        'font-bold': theme === 'onlyText',
      })}
      disabled={disabled}
      onClick={onClick}
      // onMouseDown={(e) => e.stopPropagation()}
      style={{
        width,
        height,
        borderRadius,
      }}
    >
      {theme === 'icon' && <Icon cls={icon} size={size || 16} />}
      {children}
    </button>
  );
};

Button.defaultProps = {
  theme: 'basic',
  icon: '',
  disabled: false,
  width: DefaultWidth,
  height: DefaultHeight,
};

export default Button;
