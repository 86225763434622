import * as React from 'react';
import { stopBubbleWhenSortCut } from '@helpers/shortCutHelper';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';

export interface ITextAreaEditorProps {
  value: string;
  wrap?: boolean;
  style: React.CSSProperties;
  className: string;
  ref: React.MutableRefObject<HTMLDivElement | null>;
  onChange: (value: string) => void;
  onInput?: (e: React.FormEvent) => void;
  onMouseUp?: () => void;
  onMouseDown?: () => void;
  onDragEnd?: () => void;
}

interface ITextAreaEditorState {
  value: string;
}

export default class TextAreaEditor extends React.Component<ITextAreaEditorProps, ITextAreaEditorState> {
  private dom: React.RefObject<HTMLTextAreaElement> = React.createRef();

  constructor(props: ITextAreaEditorProps) {
    super(props);
    this.state = { value: props.value };
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.handleWindowMouseDown, true);
    window.addEventListener('mouseup', this.handleWindowMouseUp, true);
    document.onvisibilitychange = this.handlePageVisibleChanged;
    this.dom.current && this.autoTextAreaHeight(this.dom.current);
    this.dom.current!.focus();
    this.dom.current!.select();
  }

  UNSAFE_componentWillReceiveProps(nextProps: ITextAreaEditorProps) {
    if (this.props.style !== nextProps.style) {
      this.dom.current && this.autoTextAreaHeight(this.dom.current);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleWindowMouseDown, true);
    window.removeEventListener('mouseup', this.handleWindowMouseUp, true);
    document.onvisibilitychange = null;
  }

  handlePageVisibleChanged = () => {
    if (document.visibilityState === 'hidden') {
      this.doSubmit();
    }
  };

  handleWindowMouseUp = () => {
    this.props.onMouseUp?.();
  };

  handleWindowMouseDown = (e: MouseEvent) => {
    const self = this.dom.current!;
    const { target } = e;
    if (self.contains(target as HTMLElement)) {
      return;
    }
    let dom = document.querySelector('.right-panel');
    if (dom && dom.contains(e.target as HTMLElement)) {
      return;
    }
    dom = document.querySelector('.popup-with-body');
    if (dom && dom.contains(e.target as HTMLElement)) {
      return;
    }
    this.doSubmit();
  };

  handleMouseUp = (e: React.MouseEvent) => {
    this.props.onMouseUp?.();
    e.stopPropagation();
  };

  handleDragEnd = () => {
    this.props.onMouseUp?.();
  };

  handleMouseDown = () => {
    this.props.onMouseDown?.();
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    // @ts-ignore
    stopBubbleWhenSortCut(e);
    if (e.keyCode === KeyCodeMap.VK_ESCAPE) {
      this.doSubmit();
    }
  };

  handleInputChange = (e: React.FormEvent) => {
    const dom = e.target as HTMLTextAreaElement;
    this.autoTextAreaHeight(dom);
    const value = (e.target as HTMLInputElement).value;
    this.props.onInput && this.props.onInput(e);
    this.setState({ value });
  };

  autoTextAreaHeight = (dom: HTMLTextAreaElement) => {
    let scrollHeight = dom.scrollHeight;
    scrollHeight = Math.max(scrollHeight, dom.parentElement!.offsetHeight);
    dom.style.height = dom.scrollTop + scrollHeight + 'px';
  };

  doSubmit = () => {
    const { value } = this.state;
    const { onChange } = this.props;
    onChange(value);
  };

  handleWheel = (e: React.WheelEvent) => {
    e.stopPropagation();
    // e.preventDefault();
  };

  render() {
    const { value } = this.state;
    const { style, className } = this.props;
    return (
      <textarea
        ref={this.dom}
        style={style}
        maxLength={10000}
        className={className}
        defaultValue={value}
        onKeyDown={this.handleKeyDown}
        onInput={this.handleInputChange}
        onWheel={this.handleWheel}
        onMouseUp={this.handleMouseUp}
        onMouseDown={this.handleMouseDown}
        onDragEnd={this.handleDragEnd}
        onContextMenu={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      />
    );
  }
}
