import { assign, cloneDeep, get, remove, uniq, isEqual, set } from 'lodash';

import { IComponentData, IComponentState } from '@fbs/rp/models/component';
import { UIComponent, UIContainerComponent, UIListLayoutSealedComponent, UISelectPanelComponent } from '@editor/comps';
import { getComponent } from '@libs/libs';
import { ArtboardPatches, ComponentOperations, ComponentPatches, Operation, Ops } from '@fbs/rp/utils/patch';
import { IProperties, PropertyValue } from '@fbs/rp/models/property';
import { StyleHelper } from '@helpers/styleHelper';
import { measureTextSize } from '@utils/textUtils';
import { flatChild, FlatCompListItem } from '@editor/advanceEditorHelper';
import {
  isSameProperty,
  updatePatchesBaseOnNewStateInTempState,
} from '@editor/corePartial/corePartialHelper/styleHelper';

import { isNotEmpty } from '@utils/globalUtils';
import { ComponentPatchesClass, doAddOperationByNewValue } from '@editor/patches/ComponentPatches';
import { coverPatches, mergePatches } from '@helpers/patchHelper';
import { ArtboardPatchesClass } from '@editor/patches/artboardPatches';
import { ISize } from '@fbs/common/models/common';
import { isPredefinedState } from '@consts/state';
import * as BoundsUtils from '@utils/boundsUtils';
import { getBoundsInParent } from '@helpers/componentHelper';
import { CSelectPanel, CSelect, CText, CPureText, CParagraph, CPath, CImageTextTabs } from '@libs/constants';

import CoreInteraction from './interaction';

function mergeStateKeys(state1: Object, State2: Object) {
  return uniq(Object.keys(state1).concat(Object.keys(State2)));
}

/**
 *
 * @param isList
 * @param comp
 * @param parent
 */
function getFlatChildren(isList: boolean, comp: IComponentData, parent: IComponentData) {
  const itemComps: FlatCompListItem[] = [];
  if (isList) {
    flatChild(comp, itemComps, parent);
  } else {
    itemComps.push({ comp });
    comp.components?.forEach((c) => flatChild(c, itemComps, comp));
  }
  return itemComps;
}

function isListComponent(comp: UIComponent) {
  const { type, lib } = comp;
  return type === CSelectPanel || Boolean(lib && getComponent(lib)?.isList);
}

/**
 * 根据是否是列表类型的组件,来获取数据,
 * 如果是列表型组件,则获取所有的列表项
 * 如果不是,则返回容器组件的数据
 * @param containerComp
 */
function getContainerOrListChild(containerComp: UIComponent) {
  const isList: boolean = isListComponent(containerComp);
  const isSelect = containerComp.type === CSelect;
  const children: IComponentData[] = [];
  if (isList && !isSelect) {
    children.push(...(containerComp as UIContainerComponent).toJSON().components!);
  } else if (isList && isSelect) {
    let head = get(containerComp, 'components[0]');
    let items = get(containerComp, 'components[1].components[0].components');
    children.push(head, ...items);
  } else {
    children.push(containerComp.toJSON());
  }
  return children;
}

/**
 * 获取组件的 value 数据,如果是引用数据,那么就获取容器中的数据
 * @param comp 组件
 * @param containerComp 容器
 */
function getCompRealValue(comp: IComponentData, containerComp: UIComponent | null) {
  let value = comp.value as string;
  if (value === '@value') {
    value = containerComp!.value as string;
  }
  return value;
}

function getSizeByValue(propertiesInTemplate: IProperties, value: string) {
  const textStyle = StyleHelper.initCSSStyleParser(propertiesInTemplate).getTextStyle();
  const { width, height } = measureTextSize(textStyle, value, {
    isMultiText: false,
    wrap: false,
  });
  return { width, height };
}

function updateCompPatchesWhenPropertiesChange(
  propertiesChange: { new: PropertyValue; old: PropertyValue },
  path: string,
  patches: ComponentPatchesClass,
) {
  const { new: newProperty, old } = propertiesChange;
  const isPropertiesChanged = !isSameProperty(old, newProperty);
  if (isPropertiesChanged) {
    const oldPlusNewProperties = assign(cloneDeep(old), newProperty);
    patches.doReplaceOperationByPath(path, old, oldPlusNewProperties);
  }
}

function getNewValueWhenRef(newProperty: PropertyValue, contianerValue: PropertyValue): PropertyValue {
  return {
    ...newProperty,
    ref: undefined,
    prop: contianerValue.prop,
    name: contianerValue.name,
  };
}

const doBuildPropertiesOperation = (
  oldProperties: IProperties,
  newProperties: IProperties,
  containerComp: UIContainerComponent,
  patches: ComponentPatchesClass,
  sealedPathes: ComponentPatchesClass,
  prefixPath: string = '.',
) => {
  const keysInOldProperties = Object.keys(oldProperties);
  const keysInNewProperties = Object.keys(newProperties);
  keysInNewProperties.forEach((keyInNewProperties) => {
    if (keysInOldProperties.indexOf(keyInNewProperties) === -1) {
      keysInOldProperties.push(keyInNewProperties);
    }
  });
  keysInOldProperties.forEach((keyInOldProperty) => {
    const oldProperty = oldProperties[keyInOldProperty];
    const newProperty = newProperties[keyInOldProperty];
    const path = `${prefixPath}/properties/${keyInOldProperty}`;
    const propertiesChange = {
      old: oldProperty!,
      new: newProperty!,
    };
    //属性没有冲突
    if (!oldProperty) {
      patches.doAddOperation(path, newProperty);
      return;
    }

    const refPropertyInOld = oldProperty?.ref;
    const isRefProperty = Boolean(refPropertyInOld);
    // 非ref属性的处理
    if (!(newProperty && isRefProperty)) {
      updateCompPatchesWhenPropertiesChange(propertiesChange, path, patches);
    }
    // ref属性的处理
    if (newProperty && isRefProperty) {
      if (!(newProperty!.ref && Object.keys(newProperty!).length === 1)) {
        const key = refPropertyInOld!.replace('@properties.', '');
        const oldValue = get(containerComp.properties, key)!;
        const newValue = getNewValueWhenRef(newProperty, oldValue);

        // 处理数据精简之后，properties内嵌套的情况
        const parts = key.split('.');
        if (parts.length > 1) {
          const firstLevelKey = parts[0];
          const oldFirstLevelProp = containerComp.properties[firstLevelKey] as PropertyValue;
          const newFirstLevelProp = cloneDeep(oldFirstLevelProp) as object;
          parts.shift();
          set(newFirstLevelProp, parts, newValue);
          sealedPathes.mergeNewValueToPatches(`./properties/${firstLevelKey}`, oldFirstLevelProp, newFirstLevelProp);
        } else {
          const replacePath = key.replace(/\./g, '/'); // 处理properties内嵌套的情况
          sealedPathes.mergeNewValueToPatches(`./properties/${replacePath}`, oldValue, newValue);
        }
      }
    }
  });
};

function unNamedTodo(tempState: any, containerProperties: IProperties) {
  const { properties: tempProperties, ...other } = tempState;
  const newData: IComponentState = { ...other, properties: {}, enabled: true };
  const refProp: IProperties = {};
  if (tempProperties) {
    Object.keys(tempProperties).forEach((key) => {
      // 默认属性是引用属性时
      const containerProperty = containerProperties[key]!;
      if (containerProperty?.ref) {
        refProp[key] = {
          ...tempProperties[key],
          ref: undefined,
          prop: containerProperty.prop,
          name: containerProperty.name,
        };
        newData.properties![key] = { ref: `@properties/${key}` };
      } else {
        newData.properties![key] = { ...tempProperties[key], ref: undefined };
      }
    });
  }
  return { newData, refProp };
}

function updatePatchesWhenModifyExsitedStateWhichCompIsNotList(
  stateKey: any,
  containerState: any,
  tempState: any,
  patches: ComponentPatchesClass,
  compType: string,
) {
  // opacity,hidden属性例外
  patches.doReplaceOperationByPath(`./states/${stateKey}/opacity`, containerState.opacity, tempState.opacity);
  patches.doReplaceOperationByPath(`./states/${stateKey}/hidden`, containerState.hidden, tempState.hidden);
  // 非列表类型组件
  patches.doReplaceOperationByPath(`./states/${stateKey}/position`, containerState.position, tempState.position);
  patches.doReplaceOperationByPath(`./states/${stateKey}/rotate`, containerState.rotate, tempState.rotate);
  // FIXME Matt 如果是文本类组件，这里需要根据情况进行尺寸测量应用，而不是直接使用模板中的尺寸
  if (![CText, CPureText, CParagraph].includes(compType)) {
    patches.doReplaceOperationByPath(`./states/${stateKey}/size`, containerState.size, tempState.size);
  }
}

function getChildOfTemplateData(templates: IComponentData[]) {
  const childrenInTemplateData: FlatCompListItem[] = [];
  templates.forEach((template) => {
    flatChild(template, childrenInTemplateData);
  });
  return childrenInTemplateData;
}

/**
 * 直接替换掉artboard patches 中的对应 ID 的 ComponentPatches.ts
 * @param artboardPatches
 * @param patches
 * @param itemCompId
 */
function replaceComponentPatchesByCompId(
  artboardPatches: ArtboardPatches,
  patches: ComponentPatches,
  itemCompId: string,
) {
  if (patches.do.length) {
    artboardPatches.do[itemCompId] = patches.do;
    artboardPatches.undo[itemCompId] = patches.undo;
  }
}

function isPatchesHasSpecificCompID(artboardPatches: ArtboardPatches, id: string) {
  return artboardPatches.do[id];
}

function isOperationsHasSpecificPath(Operations: ComponentOperations, path: string) {
  return Operations.find((operation) => operation.path === path);
}

function addCompPatchesToArtboadPatches(
  artboardPatches: ArtboardPatches,
  sealedCompPatches: ComponentPatchesClass,
  containerID: string,
) {
  if (!sealedCompPatches.do.length) {
    return;
  }
  // 存在重复的 Comp 修改
  if (isPatchesHasSpecificCompID(artboardPatches, containerID)) {
    const ownerDo = artboardPatches.do[containerID];
    const ownerUndo = artboardPatches.undo[containerID];
    sealedCompPatches.do.forEach((doOp) => {
      if (!isOperationsHasSpecificPath(ownerDo, doOp.path)) {
        ownerDo.push(doOp);
      }
    });
    sealedCompPatches.undo.forEach((undoOp) => {
      if (!isOperationsHasSpecificPath(ownerUndo, undoOp.path)) {
        ownerUndo.push(undoOp);
      }
    });
  } else {
    replaceComponentPatchesByCompId(artboardPatches, sealedCompPatches, containerID);
  }
}

export default class CoreStyle extends CoreInteraction {
  private isListSealedComp: boolean = false;

  /**
   *  比较数据变化,来得到数据更改的 patches
   * @param comp
   * @param template
   * @param sealedPathes
   * @param isparentequal
   * @param isItem
   */
  private doBuilterOptWithTemplate(
    comp: IComponentData,
    template: IComponentData,
    sealedPathes: ComponentPatchesClass,
    isparentequal: boolean,
    isItem: boolean = false,
  ) {
    //1.先处理默认状态的数据变化
    const patches = new ComponentPatchesClass();
    if (comp.type === CPath) {
      const propertyName = 'value';
      patches.doReplaceOperationByPath(`./${propertyName}`, get(comp, propertyName), get(template, propertyName));
    }
    ['name', 'hidden', 'opacity', 'size'].forEach((propertyName) => {
      patches.doReplaceOperationByPath(`./${propertyName}`, get(comp, propertyName), get(template, propertyName));
    });
    const containerComp = this.firstSelectedComponent;
    const islistSealedComp = this.isListSealedComp;
    // 这里是处理特殊情况
    if (!(islistSealedComp && isparentequal)) {
      const { position } = comp;
      patches.doReplaceOperationByPath('./position', position, template.position);
    }
    if (!isItem) {
      patches.doReplaceOperationByPath('./layout', comp.layout, template.layout);
    }
    if (!isItem && comp.type === CPureText) {
      const newSize = getSizeByValue(template.properties, getCompRealValue(comp, containerComp));
      // FIXME: 前面已将模板的size的塞入每项size, 每项文字大小不同，此处模板的size并不一定和每项的size相等，此时是否应该是template.size和newSize比较？？
      patches.doReplaceOperationByPath('./size', comp.size, { ...comp.size, ...{ newSize } });
    }

    // 特殊情况处理完毕,处理 properties 变化
    doBuildPropertiesOperation(
      comp.properties,
      template.properties,
      containerComp as UIContainerComponent,
      patches,
      sealedPathes,
    );
    // 2.再处理其他特殊状态的数据变化
    this.updateCompatchesByDataInStates(comp, template, patches, sealedPathes, isItem);

    return patches.value;
  }

  /**
   * 比较状态中的数据,更新patches
   * @param comp
   * @param template
   * @param patches
   * @param sealedPathes
   * @param isItem
   */
  private updateCompatchesByDataInStates(
    comp: IComponentData,
    template: IComponentData,
    patches: ComponentPatchesClass,
    sealedPathes: ComponentPatchesClass,
    isItem: boolean,
  ) {
    const containerComp = this.firstSelectedComponent;
    const compProperties = comp.properties;

    const compOriginStates = comp.states;
    // 处理状态数据
    if (compOriginStates && template.states) {
      const allStateKeys = mergeStateKeys(compOriginStates, template.states);

      remove(allStateKeys, (value) => value === 'normal');

      const isList = containerComp!.libData?.isList;
      allStateKeys.forEach((stateKey) => {
        const containerState = compOriginStates[stateKey];
        const tempState = template.states[stateKey];
        if (!tempState) {
          return;
        }
        // 如果是编辑之前没有的状态
        const isAddNewStateWhenStyleEditing = Boolean(!containerState);
        if (isAddNewStateWhenStyleEditing && isList) {
          updatePatchesBaseOnNewStateInTempState(tempState, stateKey, patches);
        }
        if (isAddNewStateWhenStyleEditing && !isList) {
          // 不包含时，添加
          const { newData, refProp } = unNamedTodo(tempState, compProperties);
          doAddOperationByNewValue(`./states/${stateKey}`, newData, patches);

          const keys = Object.keys(refProp);
          if (!keys.length || !tempState) {
            return;
          }

          const ownerStates = containerComp!.states;
          const isStateNotExistedInContainer = !ownerStates[stateKey];
          //如果高级编辑增加了原来没有的状态
          if (isStateNotExistedInContainer) {
            const path = `./states/${stateKey}`;
            if (!sealedPathes.isPathExistedInPatches(path)) {
              sealedPathes.addOperation({
                do: Ops.add(path, { properties: {}, enabled: true }),
                undo: Ops.remove(path),
              });
            }
          }

          // 默认处理
          keys.forEach((key) => {
            const path = `./states/${key}/properties/${key}`;
            if (!sealedPathes.isPathExistedInPatches(path)) {
              sealedPathes.addOperation({ do: Ops.add(path, refProp[key]), undo: Ops.remove(path) });
            }
          });
        }

        // 2.如果高级编辑的状态是原来组件已经有的状态

        const compType = comp.type;
        // 非列表型组件
        if (containerState && !isItem) {
          updatePatchesWhenModifyExsitedStateWhichCompIsNotList(stateKey, containerState, tempState, patches, compType);
        }
        //列表型组件
        if (containerState) {
          // 这里要补充判断，如果原始状态中不包含某个属性时，且新状态中的属性与原始属性相同时，则不应用数据到状态中
          // opacity,hidden属性例外
          patches.doReplaceOperationByPath(`./states/${stateKey}/opacity`, containerState.opacity, tempState.opacity);
          patches.doReplaceOperationByPath(`./states/${stateKey}/hidden`, containerState.hidden, tempState.hidden);

          type PropName = keyof IProperties;
          const containerStateProperties = containerState.properties;
          const newStateProperties = tempState.properties;
          if (!newStateProperties) {
            return;
          }
          Object.keys(newStateProperties).forEach((properyKeyInNewState) => {
            const propName = properyKeyInNewState as PropName;
            const newPropertyInNewState = newStateProperties[propName]!;
            const originalStateProperty = containerStateProperties && containerStateProperties[propName];
            const originalProp = compProperties[propName]!;

            if (originalStateProperty) {
              // 原状态中有对应属性，当不相同时，更新该状态中属性
              if (originalStateProperty.ref) {
                const sealedKey = originalStateProperty.ref.replace('@properties.', '');
                const sealedProp = get(containerComp!.properties, sealedKey)!;
                const path1 = `./properties/${sealedKey.replace(/\./g, '/')}`;
                if (!sealedPathes.isPathExistedInPatches(path1) && !isSameProperty(sealedProp, newPropertyInNewState)) {
                  const newProperty = {
                    ...sealedProp,
                    ...newPropertyInNewState,
                    prop: sealedProp.prop,
                    name: sealedProp.name,
                  };
                  // 处理数据精简之后，properties内嵌套的情况
                  const parts = sealedKey.split('.');
                  if (parts.length > 1) {
                    const firstLevelKey = parts[0];
                    const oldFirstLevelProp = containerComp!.properties[firstLevelKey] as PropertyValue;
                    const newFirstLevelProp = cloneDeep(oldFirstLevelProp) as object;
                    parts.shift();
                    set(newFirstLevelProp, parts, newProperty);
                    const path = `./properties/${firstLevelKey}`;
                    sealedPathes.addOperation({
                      do: Ops.replace(path, newFirstLevelProp),
                      undo: Ops.replace(path, oldFirstLevelProp),
                    });
                  } else {
                    sealedPathes.addOperation({
                      do: Ops.replace(path1, newProperty),
                      undo: Ops.replace(path1, sealedProp),
                    });
                  }
                }
              }
              if (!originalStateProperty.ref && !isSameProperty(originalStateProperty, newPropertyInNewState)) {
                patches.doReplaceOperationByPath(
                  `./states/${stateKey}/properties/${propName}`,
                  originalStateProperty,
                  newPropertyInNewState,
                );
              }
            } else {
              // 原状态中不包含对应属性时，和默认属性比较，当不同时，在对应状态中添加属性
              if (!isSameProperty(originalProp, newPropertyInNewState)) {
                if (!containerStateProperties) {
                  doAddOperationByNewValue(
                    `./states/${stateKey}/properties`,
                    { [propName]: newPropertyInNewState },
                    patches,
                  );
                } else {
                  // 添加对应属性时，应该将默认值内的ref去掉，否则展示时，样式仍指向ref引用的属性，而未使用设置的属性
                  Reflect.deleteProperty(newPropertyInNewState, 'ref');
                  patches.doReplaceOperationByPath(
                    `./states/${stateKey}/properties/${propName}`,
                    originalStateProperty,
                    newPropertyInNewState,
                  );
                }
              }
            }
          });
        }
      });
    }
  }

  /**
   *
   * @param template 高级编辑中被编辑的组件数据
   * @param originContainer 进入高级编辑前被选中的组件
   */
  applyStyle(template: IComponentData[], originContainer?: UIContainerComponent) {
    this.isListSealedComp = false;
    // FIXME Matt  应用风格
    const containerComp = this.firstSelectedComponent;
    if (!containerComp?.isSealed) {
      return;
    }
    this.isListSealedComp = isListComponent(containerComp);
    const artboardPatches = this.getArtBoardPatchesWhenStyleEditEnd(containerComp, template);

    //6.如果是列表组件,那么子宽度缩小了,父的宽度应该跟随变化
    if (originContainer && originContainer instanceof UIListLayoutSealedComponent) {
      const listItemSize = template[0].size;
      const direction = originContainer.properties.layout?.direction || '';
      if (direction === 'vertical') {
        this.updateVerticalListContainerAndSiblings(originContainer, listItemSize, artboardPatches);
      }
      if (direction === 'horizontal') {
        this.updateHorizontalListContainerAndSiblings(originContainer, listItemSize, artboardPatches);
      }
    }
    // 7.针对下拉框的特殊处理
    if (originContainer && originContainer.type === CSelect) {
      // 头部的变化,也一起影响下拉框外壳的大小,弹出菜单的位置也要跟着下移
      const newSize = template[0].size;
      if (!isEqual(newSize, originContainer.size)) {
        coverPatches(
          artboardPatches,
          new ArtboardPatchesClass().getAttrChangePatches(originContainer.id, originContainer.getCurrentSizePath(), {
            oldVal: originContainer.size,
            newVal: newSize,
          }),
        );
      }
    }
    //响应选项组子项的大小改变
    if (containerComp instanceof UISelectPanelComponent) {
      const originCell = containerComp.properties.cell!;
      let itemSize: ISize;
      if (template[0] && template[0].type === 'group') {
        const children = template[0].components!;
        const { height, width } = BoundsUtils.union(
          ...children.map((comp) => {
            const { size, rotate, position } = comp;
            return getBoundsInParent({ size, rotate: rotate || 0, position });
          }),
        );
        itemSize = { height, width };
      } else {
        itemSize = template[0].size;
      }
      const newCell = {
        ...originCell,
        rowHeight: itemSize.height,
        columnWidth: itemSize.width,
      };
      coverPatches(artboardPatches, containerComp.setProperty('cell', newCell));
    }

    // 应用状态到选项组各项
    if (containerComp.type === CSelectPanel) {
      const templateData = template[0];
      const states = templateData.states;
      const presetStates = Object.keys(states).filter((key) => isPredefinedState(key));
      if (presetStates.length) {
        containerComp.toJSON().components?.forEach((comp) => {
          const { _id: id } = comp;
          presetStates.forEach((k) => {
            // const state = templateData.states[k]!;
            const opts = artboardPatches.do[id];
            const undoOps = artboardPatches.undo[id];
            let op: Operation | undefined = undefined;
            let unOp: Operation | undefined = undefined;
            if (!comp.states[k]) {
              const path = `./states/${k}`;
              // 如果没有，补充状态到每个item
              op = Ops.add(path, { enabled: true });
              unOp = Ops.remove(path);
            } else if (!comp.states[k].enabled) {
              // 如果没激活对应状态，激活该状态
              const path = `./states/${k}/enabled`;
              // 如果没有，补充状态到每个item
              op = Ops.replace(path, true);
              unOp = Ops.replace(path, false);
            }
            if (op && unOp) {
              if (opts) {
                opts.push(op);
                undoOps.push(unOp);
              } else {
                artboardPatches.do[id] = [op];
                artboardPatches.undo[id] = [unOp];
              }
            }
          });
        });
      }
    }

    // 图文选项卡，将不同状态下position和size变更运用到每个子项
    if (containerComp.libData?.type === CImageTextTabs) {
      containerComp?.libData?.editor?.specials?.onPatchesFixByApplyAdvanceEdit?.(
        containerComp,
        template[0],
        artboardPatches,
      );
    }

    //应用patches
    if (isNotEmpty(artboardPatches)) {
      this.updateSingleArtboard(containerComp.ownerArtboardID, artboardPatches);
    }
  }

  /**
   * 更新容器的大小和兄弟组件的位置和大小
   * @param originContainer
   * @param listItemSize
   * @param artboardPatches
   * @private
   */
  private updateVerticalListContainerAndSiblings = (
    originContainer: UIContainerComponent,
    listItemSize: ISize,
    artboardPatches: ArtboardPatches,
  ) => {
    const compId = originContainer.id;
    const path = originContainer.getCurrentSizePath();
    const originSealContainerSize = originContainer.size;

    //改变某项的宽度,容器的宽度也要跟随变化
    const valueChange = {
      oldVal: originSealContainerSize,
      newVal: { height: originSealContainerSize.height, width: listItemSize.width },
    };
    const componentPatches = new ComponentPatchesClass().getAttrChangePatches(compId, path, valueChange);
    coverPatches(artboardPatches, new ArtboardPatchesClass().getPatchesByCompChange(compId, componentPatches));

    //改变某项的高度,相当于改变行高
    const newCellValue = { rowHeight: listItemSize.height, ratioHeight: originContainer.properties.cell?.ratioHeight };
    const rowHeightPatches = (originContainer as UIListLayoutSealedComponent).getPatchesWhenSetCellProperty(
      newCellValue,
    );
    coverPatches(artboardPatches, rowHeightPatches);
  };

  /**
   * 改变父组件的大小和兄弟组件的位置
   * @param originContainer
   * @param listItemSize
   * @param artboardPatches
   * @private
   */
  private updateHorizontalListContainerAndSiblings(
    originContainer: UIContainerComponent,
    listItemSize: ISize,
    artboardPatches: ArtboardPatches,
  ) {
    const sealedContainerID = originContainer.id;
    const path = originContainer.getCurrentSizePath();
    const originSealContainerSize = originContainer.size;

    //改变某项的宽度,相当于改变列宽
    //fixme 需要增加列宽属性的改变

    // 改变某项的高度/宽度,容器的高度/宽度也要跟随变化
    let valueChange = {
      oldVal: originSealContainerSize,
      newVal: { width: originContainer.components.length * listItemSize.width, height: listItemSize.height },
    };
    coverPatches(
      artboardPatches,
      new ArtboardPatchesClass().getAttrChangePatches(sealedContainerID, path, valueChange),
    );
    originContainer.components.forEach((child, index) => {
      let position = child.position;
      const newPosition = { x: index * listItemSize.width, y: position.y };
      let childId = child.id;
      coverPatches(
        artboardPatches,
        new ArtboardPatchesClass().getAttrChangePatches(childId, child.getCurrentPositionPath(), {
          oldVal: position,
          newVal: newPosition,
        }),
      );
    });
  }

  private getArtBoardPatchesWhenStyleEditEnd(containerComp: UIComponent, template: IComponentData[]) {
    const parent = containerComp.toJSON();
    // 1.先获取操作数据,如果是列表型组件,那么就是获取到列表项,如果非列表数据,就是直接对container做操作
    const containerOrListChild = getContainerOrListChild(containerComp);

    // 2.获取template子组件的一维的组件树
    const childrenInTemplateData = getChildOfTemplateData(template);
    const artboardPatches: ArtboardPatches = { do: {}, undo: {} };
    const sealedComponentPatches = new ComponentPatchesClass();
    const componentPatchesArr: [ComponentPatches, string][] = [];
    containerOrListChild?.forEach((containerOrListItem) => {
      // 3.获取源数据的一维组件树
      getFlatChildren(this.isListSealedComp, containerOrListItem, parent).forEach((item, i) => {
        const itemInOriginContainer = item.comp;
        const itemCompId = get(itemInOriginContainer, '_id') || get(itemInOriginContainer, 'id');
        const itemParent = item.parent!;
        const itemInTemplate = childrenInTemplateData[i].comp;
        //4.根据原始数据和高级编辑数据的不同,得到ComponentPatches
        const patches = this.doBuilterOptWithTemplate(
          itemInOriginContainer,
          itemInTemplate,
          sealedComponentPatches,
          itemParent === containerComp!.toJSON(),
          itemCompId === containerOrListItem._id,
        );
        componentPatchesArr.push([patches, itemCompId]);
      });
    });

    componentPatchesArr.forEach(([patches, itemCompId]) => {
      replaceComponentPatchesByCompId(artboardPatches, patches, itemCompId);
    });
    //5.将复合组件的修改添加到artboardPatches中
    addCompPatchesToArtboadPatches(artboardPatches, sealedComponentPatches, containerComp.id);
    return artboardPatches;
  }

  /**
   * 改变粘贴组件样式
   * @param patchInfo
   * @param artboardID
   */
  updateCompStyle(patchInfo: { id: string; oldData: UIComponent; newData: IComponentData }[], artboardID: string) {
    const getNewStylePatch = (info: { id: string; oldData: UIComponent; newData: IComponentData }) => {
      const { id, oldData, newData } = info;
      const { properties, opacity, states, textBehaviour } = newData;
      const patch: ArtboardPatches[] = [];
      const TEXT_FORMAT = 'textFormat';
      const TEXT_STYLE = 'textStyle';

      for (let item in properties) {
        const patchProperties = this.doSetProperty([oldData], item, properties[item]!);
        patch.push(patchProperties);
      }

      // 文本组件的特殊处理
      if (
        StyleHelper.getRichTextKeyByCompType(newData.type) !== 'none' &&
        StyleHelper.getRichTextKeyByCompType(oldData.type) === 'value'
      ) {
        // 同文本 textBehaviour 单独处理
        if (textBehaviour) {
          const patchTextBehaviour = this.doSetProperty([oldData], 'textBehaviour', { value: textBehaviour });
          patch.unshift(patchTextBehaviour);
        }

        // 新旧文本属性兼容
        const textProperties = properties[TEXT_FORMAT] ?? properties[TEXT_STYLE]!;

        // 改变fontSize会影响size，避免覆盖size，放入path数组末尾
        const patchFontSize = this.doSetProperty([oldData], TEXT_FORMAT, textProperties, 'fontSize');
        patch.push(patchFontSize);

        // 重置富文本赋予新属性
        const patchValue = this.doSetRichValue([oldData], textProperties, 'value');
        patch.push(patchValue);
      }

      // 形状组件富文本处理
      if (
        StyleHelper.getRichTextKeyByCompType(newData.type) !== 'none' &&
        StyleHelper.getRichTextKeyByCompType(oldData.type) === 'text'
      ) {
        // 新旧文本属性兼容
        const textProperties = properties[TEXT_FORMAT] ?? properties[TEXT_STYLE]!;

        // 重置富文本赋予新属性
        const patchText = this.doSetRichValue([oldData], textProperties, 'text');
        patch.push(patchText);
      }

      patch.unshift(
        {
          do: {
            [id]: [Ops.replace('/states', states)],
          },
          undo: {
            [id]: [Ops.replace('/states', oldData.states)],
          },
        },
        {
          do: {
            [id]: [Ops.replace('/opacity', opacity ?? 100)],
          },
          undo: {
            [id]: [Ops.replace('/opacity', oldData.opacity ?? 100)],
          },
        },
      );
      return patch.reduce(
        (pre, next) => {
          return mergePatches(pre, next);
        },
        { do: {}, undo: {} },
      );
    };
    const getCompStylePatch = () => {
      return patchInfo.reduce(
        (prevPatch, nextPatch) => {
          return mergePatches(prevPatch, getNewStylePatch(nextPatch));
        },
        {
          do: {},
          undo: {},
        },
      );
    };
    patchInfo.length == 1
      ? this.updateSingleArtboard(artboardID, getCompStylePatch())
      : this.update({ [artboardID]: getCompStylePatch() });
  }
}
