import { INode, INodeWithParentID } from './model';
import { transBlankChart } from '@/utils/textUtils';
import { INodeWithChildren, NodeType } from '@fbs/rp/models/node';

export const isMacOS = window.navigator.userAgent.indexOf('Macintosh') !== -1;

export const isCtrlKey = (e: { ctrlKey: boolean; metaKey: boolean }) => {
  return e.ctrlKey || e.metaKey;
};

export function getAllParentNodeIDs(nodes: INode[], level: number, currentLevel: number = 1): string[] {
  const res: Set<string> = new Set<string>();
  nodes.forEach((node) => {
    if (!node.isLeaf) {
      res.add(node.id);
    }
    if (node.children) {
      res.add(node.id);
      if (level == -1 || currentLevel < level) {
        const subRes = getAllParentNodeIDs(node.children, level, currentLevel + 1);
        if (subRes.length > 0) {
          subRes.forEach((id) => res.add(id));
        }
      }
    }
  });
  return Array.from(res);
}

export const getParentNodeIDs = (nodes: INode[], ids: string[]) => {
  const res: Set<string> = new Set<string>();
  nodes.forEach((node) => {
    if (isNodeOrChildrenMatchIDS(node, ids) && node.children && node.children.length > 0) {
      //!node.isLeaf &&
      res.add(node.id);
    }
    if (node.children && node.children.length) {
      const subRes = getParentNodeIDs(node.children, ids);
      if (subRes.length > 0) {
        subRes.forEach((id) => res.add(id));
      }
    }
  });
  return Array.from(res);
};

// 节点或者节点的子和搜索匹配
export function isNodeOrChildrenMatchIDS(node: INode, ids: string[]): boolean {
  if (ids.includes(node.id)) {
    return true;
  }
  if (!node.children || node.children.length === 0) {
    return false;
  }
  return node.children.some((node) => isNodeOrChildrenMatchIDS(node, ids));
}

// 获取分组下的页面
export function getGroupPageNum(nodes: INode[], ignoreHidden: boolean = false): string[] {
  const res: Set<string> = new Set<string>();
  nodes.forEach((node) => {
    if (ignoreHidden || !node.data?.hidden) {
      if (node.isLeaf) {
        res.add(node.id);
      }
      if (node.children) {
        const subRes = getGroupPageNum(node.children, ignoreHidden);
        if (subRes.length > 0) {
          subRes.forEach((id) => res.add(id));
        }
      }
    }
  });
  return Array.from(res);
}

// 节点名字和搜索匹配
export function isNodeMatch(node: INode, searchKey: string): boolean {
  return (
    node.name.toLowerCase().includes(transBlankChart(searchKey.toLowerCase())) ||
    (node.pinyinName ? node.pinyinName.toLowerCase().includes(searchKey.toLowerCase()) : false)
  );
}

// 节点或者节点的子和搜索匹配
export function isNodeOrChildrenMatch(node: INode, searchKey: string, isShowNode?: boolean /*是否强制显示*/): boolean {
  if (isShowNode || isNodeMatch(node, searchKey)) {
    return true;
  }
  if (!node.children || node.children.length === 0) {
    return false;
  }
  return node.children.some((node) => isNodeOrChildrenMatch(node, searchKey));
}

/**
 * 查询 node 的 parent 和该 node 在父中的位置
 */
export function findParentNodeByID(
  nodes: INode[],
  nodeID: string,
  parent: INode | null = null,
): {
  parent: INode | null;
  index: number;
} {
  for (let i = 0; i < nodes?.length; i++) {
    const node = nodes[i];
    if (node.id === nodeID) {
      return {
        parent,
        index: i,
      };
    }
    if (node.children) {
      const foundFromNodeChildren = findParentNodeByID(node.children, nodeID, node);
      if (foundFromNodeChildren.index !== -1) {
        return foundFromNodeChildren;
      }
    }
  }
  return {
    parent,
    index: -1,
  };
}

export function flattenChildren(
  nodes: INode[],
  parentID: string = '',
  ignoreHidden: boolean = false,
): INodeWithParentID[] {
  if (nodes.length === 0) {
    return [];
  }

  const initialValue: INodeWithParentID[] = [];
  return nodes.reduce((flat, toFlatten) => {
    if (ignoreHidden || !toFlatten.data?.hidden) {
      flat.push(
        Object.assign({}, toFlatten, {
          parentID,
        }),
      );
    }
    if (toFlatten.children) {
      return flat.concat(flattenChildren(toFlatten.children, toFlatten.id, ignoreHidden));
    }
    return flat;
  }, initialValue);
}

// 判断节点是否为空，子节点隐藏也算空节点
export function nodeChildrenIsEmpty(node: INode): boolean {
  if (node.isLeaf && !node.data?.hidden) {
    return false;
  }
  if (!node.children || !node.children.length) {
    return true;
  }
  return node.children.every((child) => {
    if (child.data?.hidden) {
      return true;
    }
    return !node.children || (node.children.length && node.children.every((e) => nodeChildrenIsEmpty(e)));
  });
}

// 判断某个节点的多选框状态checked：boolean，indeterminate（半选中）：boolean，disabled：boolean
export function getCheckboxState(
  node: INode,
  checkedKeys: string[],
): {
  checked: boolean;
  indeterminate: boolean;
  disabled: boolean;
} {
  if (node.isLeaf) {
    return {
      checked: checkedKeys.includes(node.id),
      indeterminate: false,
      disabled: node.data?.hidden,
    };
  }

  let checked = false;
  let indeterminate = false;
  let disabled = node.data?.hidden;

  if (node.children && node.children.length > 0) {
    const allChildren = flattenChildren(node.children);
    const checkedChildren = allChildren.filter((c) => {
      return checkedKeys.includes(c.id) && !c.data?.hidden;
    });
    const isLeafChildren = allChildren.filter((c) => {
      return c.isLeaf && !c.data?.hidden;
    });
    checked = !!checkedChildren && checkedChildren.length > 0 && checkedChildren.length === allChildren.length;
    indeterminate = !!checkedChildren && checkedChildren.length > 0 && checkedChildren.length < allChildren.length;
    disabled = node.data?.hidden || isLeafChildren.length <= 0;
  } else {
    checked = checkedKeys.includes(node.id);
    disabled = true;
  }

  return {
    checked,
    indeterminate,
    disabled,
  };
}

// 是否是兄弟节点
export function isSiblings(nodes: INodeWithParentID[], aNodeID: string, bNodeID: string): boolean {
  const aNode = nodes.find((node) => node.id === aNodeID);
  const bNode = nodes.find((node) => node.id === bNodeID);
  return (aNode && bNode && aNode.parentID === bNode.parentID) || false;
}

// 检索checkedKeys 生成 checkPageKey
export function getCheckPageKeys(checkedKeys: string[], nodes: INode[]) {
  const newCheckedKeys = [...checkedKeys];
  const nodesThatHasPage = nodes.filter((node) => {
    return getGroupPageNum([node]).length > 0;
  });
  const allFlatChildren = flattenChildren(nodesThatHasPage);
  // 选中页面
  const newCheckedPageKeys: string[] = [];
  allFlatChildren.map((flatChild) => {
    if (newCheckedKeys.includes(flatChild.id) && flatChild.isLeaf) {
      newCheckedPageKeys.push(flatChild.id);
    }
  });
  return newCheckedPageKeys;
}

//是否全部页面隐藏了
export const isAllPagesHidden = (nodes: INodeWithChildren[]): boolean => {
  let isAllHidden = true;
  for (const node of nodes) {
    if (node.type === NodeType.Page && !node.hidden) {
      isAllHidden = false;
      break;
    }
    if (node.type === NodeType.Folder && !node.hidden && node.children?.length) {
      isAllHidden = isAllPagesHidden(node.children);
      if (!isAllHidden) {
        break;
      }
    }
  }
  return isAllHidden;
};
