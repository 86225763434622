import * as React from 'react';
import classnames from 'classnames';

import Icon from '../../components/Icon';
import Dialog from '../../components/Dialog';
import ScrollView from '../../components/ScrollView';
import Shortcut from '../../components/Shortcut';
import { getMacCmdOrCtrl } from '../../components/utils';
import i18n from '../../i18n';

const lang = window.LANG || 'zh-cn';
const isEn = lang === 'en-us';

import './index.scss';

interface IShortcutsDialogProps {
  product: 'idoc' | 'rp';
  onClose: () => void;
}

interface IShortcutsDialogState {
  data: IShortCutGroup[];
}

interface IShortCutItem {
  name: string;
  hidden?: boolean;
  shortCut: {
    win: string;
    mac?: string;
  };
}

interface IShortCutGroup {
  expand?: boolean;
  hidden?: boolean;
  name: string;
  items: IShortCutItem[];
}

const isDT: boolean = window.isDT;

const RPShortCut: IShortCutGroup[] = [
  {
    name: i18n('shortCutKey.basicOperation'),
    items: [
      {
        name: i18n('shortCutKey.undoBtn'),
        shortCut: {
          win: 'Ctrl + Z',
          mac: '⌘Z',
        },
      },
      {
        name: i18n('shortCutKey.redoBtn'),
        shortCut: {
          win: 'Ctrl + Shift + Z',
          mac: '⇧⌘Z',
        },
      },
      {
        name: i18n('shortCutKey.save'),
        shortCut: {
          win: 'Ctrl + S',
          mac: '⌘S',
        },
      },
      {
        name: i18n('shortCutKey.delete'),
        shortCut: {
          win: 'Delete/Backspace',
          mac: '⌫',
        },
      },
      {
        name: i18n('shortCutKey.copy'),
        shortCut: {
          win: 'Ctrl + C',
          mac: '⌘C',
        },
      },
      {
        name: i18n('shortCutKey.cut'),
        shortCut: {
          win: 'Ctrl + X',
          mac: '⌘X',
        },
      },
      {
        name: i18n('shortCutKey.paste'),
        shortCut: {
          win: 'Ctrl + V',
          mac: '⌘V',
        },
      },
      {
        name: i18n('shortCutKey.multiSelect'),
        shortCut: {
          win: `Shift + ${i18n('shortCutKey.click')}`,
          mac: `⇧${i18n('shortCutKey.click')}`,
        },
      },
      {
        name: i18n('shortCutKey.selectAll'),
        shortCut: {
          win: 'Ctrl + A',
          mac: '⌘A',
        },
      },
      {
        name: i18n('shortCutKey.unSelect'),
        shortCut: {
          win: 'Ctrl + Shift + A',
          mac: '⇧⌘A',
        },
      },
      {
        name: i18n('shortCutKey.addRegion'),
        shortCut: {
          win: `Shift + ${i18n('shortCutKey.selectRegion')}`,
          mac: `⇧${i18n('shortCutKey.selectRegion')}`,
        },
      },
      {
        name: i18n('shortCutKey.containSelect'),
        shortCut: {
          win: `Ctrl + ${i18n('shortCutKey.selectRegion')}`,
          mac: `⌘${i18n('shortCutKey.selectRegion')}`,
        },
      },
      {
        name: i18n('shortCutKey.selectDown'),
        shortCut: {
          win: `Ctrl + ${i18n('shortCutKey.click')}`,
          mac: `⌘${i18n('shortCutKey.click')}`,
        },
      },
      {
        name: i18n('shortCutKey.lock'),
        shortCut: {
          win: 'Ctrl + L',
          mac: '⌘L',
        },
      },
      {
        name: i18n('shortCutKey.unlock'),
        shortCut: {
          win: 'Ctrl + Shift + L',
          mac: '⇧⌘L',
        },
      },
      {
        name: i18n('shortCutKey.componentClone'),
        shortCut: {
          win: `Ctrl + D / Alt + ${i18n('shortCutKey.drag')}`,
          mac: `⌘D / ⌥${i18n('shortCutKey.drag')}`,
        },
      },
      {
        name: i18n('shortCutKey.mergeOrdissolve'),
        shortCut: {
          win: 'Ctrl + G',
          mac: '⌘G',
        },
      },
      {
        name: i18n('shortCutKey.hidden'),
        shortCut: {
          win: 'Ctrl + H',
          mac: '⌘H',
        },
      },
      {
        name: i18n('shortCutKey.dragWorkspace'),
        shortCut: {
          win: `Space + ${i18n('shortCutKey.drag')}`,
          mac: `Space + ${i18n('shortCutKey.drag')}`,
        },
      },
      {
        name: i18n('shortCutKey.levelRoll'),
        shortCut: {
          win: `Shift + ${i18n('shortCutKey.mouseWheel')}`,
          mac: `⇧${i18n('shortCutKey.mouseWheel')}`,
        },
      },
      {
        name: i18n('shortCutKey.zoomIn'),
        shortCut: {
          win: `Ctrl + + / Ctrl + ${i18n('shortCutKey.mouseWheelUp')}`,
          mac: `⌘+ / ⌘${i18n('shortCutKey.mouseWheelUp')}`,
        },
      },
      {
        name: i18n('shortCutKey.zoomOut'),
        shortCut: {
          win: `Ctrl + - / Ctrl + ${i18n('shortCutKey.mouseWheelDown')}`,
          mac: `⌘- / ⌘${i18n('shortCutKey.mouseWheelDown')}`,
        },
      },
      {
        name: i18n('shortCutKey.zoomToOne'),
        shortCut: {
          win: 'Ctrl + 0',
          mac: `⌘0`,
        },
      },
      {
        name: i18n('shortCutKey.zoomToCanvas'),
        shortCut: {
          win: 'Ctrl + 1',
          mac: `⌘1`,
        },
      },
      {
        name: i18n('shortCutKey.zoomToSelection'),
        shortCut: {
          win: 'Ctrl + 2',
          mac: `⌘2`,
        },
      },
      {
        name: i18n('shortCutKey.zoomToArtboard'),
        shortCut: {
          win: 'Ctrl + 4',
          mac: `⌘4`,
        },
      },
      {
        name: i18n('shortCutKey.demonstrate'),
        shortCut: {
          win: 'Ctrl + P',
          mac: '⌘P',
        },
      },
      {
        name: i18n('shortCutKey.verticalClone'),
        hidden: true,
        shortCut: {
          win: `Alt + Shift + ${i18n('shortCutKey.dragComponent')}`,
          mac: `⇧⌥${i18n('shortCutKey.dragComponent')}`,
        },
      },
    ],
  },
  {
    name: i18n('shortCutKey.addElement'),
    items: [
      {
        name: i18n('shortCutKey.texts'),
        shortCut: {
          win: 'T',
          mac: 'T',
        },
      },
      {
        name: i18n('shortCutKey.rectangle'),
        shortCut: {
          win: 'R',
          mac: 'R',
        },
      },
      {
        name: i18n('shortCutKey.line'),
        shortCut: {
          win: 'L',
          mac: 'L',
        },
      },
      {
        name: i18n('shortCutKey.oval'),
        shortCut: {
          win: 'O',
          mac: 'O',
        },
      },
      {
        name: i18n('shortCutKey.button'),
        hidden: isDT,
        shortCut: {
          win: 'B',
          mac: 'B',
        },
      },
      {
        name: i18n('shortCutKey.image'),
        shortCut: {
          win: 'I',
          mac: 'I',
        },
      },
      {
        name: i18n('shortCutKey.artboard'),
        shortCut: {
          win: 'A',
          mac: 'A',
        },
      },
      {
        name: i18n('shortCutKey.pen'),
        shortCut: {
          win: 'V',
          mac: 'V',
        },
      },
      {
        name: i18n('shortCutKey.pencil'),
        shortCut: {
          win: 'Shfit + P',
          mac: '⇧P',
        },
      },
      {
        name: i18n('shortCutKey.arrow'),
        hidden: isDT,
        shortCut: {
          win: 'W',
          mac: 'W',
        },
      },
      {
        name: i18n('shortCutKey.stickyNote'),
        hidden: isDT,
        shortCut: {
          win: 'S',
          mac: 'S',
        },
      },
      {
        name: i18n('shortCutKey.circleMarker'),
        hidden: isDT,
        shortCut: {
          win: 'C',
          mac: 'C',
        },
      },
      {
        name: i18n('shortCutKey.dropMarker'),
        hidden: isDT,
        shortCut: {
          win: 'D',
          mac: 'D',
        },
      },
    ],
  },
  {
    name: i18n('shortCutKey.attributeSetting'),
    items: [
      {
        name: i18n('shortCutKey.componentTransparent'),
        shortCut: {
          win: i18n('shortCutKey.numberKey'),
        },
      },
      {
        name: i18n('shortCutKey.componentHeight'),
        hidden: true,
        shortCut: {
          win: 'Alt ＋ Up/Down',
          mac: '⌥Up/Down',
        },
      },
      {
        name: i18n('shortCutKey.componentWidth'),
        hidden: true,
        shortCut: {
          win: 'Alt + Left/Right',
          mac: '⌥Left/Right',
        },
      },
      {
        name: i18n('shortCutKey.addFontsize'),
        shortCut: {
          win: 'Ctrl + Alt + +',
          mac: '⌘⌥+',
        },
      },
      {
        name: i18n('shortCutKey.decreaseFontsize'),
        shortCut: {
          win: 'Ctrl + Alt + -',
          mac: '⌘⌥-',
        },
      },
      {
        name: i18n('shortCutKey.bold'),
        shortCut: {
          win: 'Ctrl + B',
          mac: '⌘B',
        },
      },
      {
        name: i18n('shortCutKey.italic'),
        shortCut: {
          win: 'Ctrl + I',
          mac: '⌘I',
        },
      },
      {
        name: i18n('shortCutKey.underline'),
        shortCut: {
          win: 'Ctrl + U',
          mac: '⌘U',
        },
      },
      // {
      //   name: i18n('shortCutKey.autoSize'),
      //   shortCut: {
      //     win: 'Ctrl + Enter',
      //     mac: '⌘↩',
      //   },
      // },
      {
        name: i18n('shortCutKey.copyProperties'),
        shortCut: {
          win: 'Ctrl + Alt + C',
          mac: '⌘⌥C',
        },
      },
      {
        name: i18n('shortCutKey.pasteProperties'),
        shortCut: {
          win: 'Ctrl + Alt + V',
          mac: '⌘⌥V',
        },
      },
    ],
  },
  {
    name: i18n('shortCutKey.layoutSetting'),
    items: [
      {
        name: i18n('shortCutKey.componentMove'),
        shortCut: {
          win: 'Up/Down/Left/Right',
        },
      },
      {
        name: i18n('shortCutKey.componentMoveFast'),
        shortCut: {
          win: 'Shift + Up/Down/Left/Right',
          mac: '⇧Up/Down/Left/Right',
        },
      },
      {
        name: i18n('shortCutKey.verticalMove'),
        hidden: true,
        shortCut: {
          win: `Shift + ${i18n('shortCutKey.dragComponent')}`,
          mac: `⇧${i18n('shortCutKey.dragComponent')}`,
        },
      },
      {
        name: i18n('shortCutKey.forwardTier'),
        shortCut: {
          win: 'Ctrl + ]',
          mac: '⌘]',
        },
      },
      {
        name: i18n('shortCutKey.abackTier'),
        shortCut: {
          win: 'Ctrl + [',
          mac: '⌘[',
        },
      },
      {
        name: i18n('shortCutKey.toTop'),
        shortCut: {
          win: 'Ctrl + Alt + ]',
          mac: '⌘⌥]',
        },
      },
      {
        name: i18n('shortCutKey.toBottom'),
        shortCut: {
          win: 'Ctrl + Alt + [',
          mac: '⌘⌥[',
        },
      },
      {
        name: i18n('shortCutKey.boardInterval'),
        shortCut: {
          win: `Alt + ${i18n('shortCutKey.MouseOverComponent')}`,
          mac: `⌥${i18n('shortCutKey.MouseOverComponent')}`,
        },
      },
      {
        name: i18n('shortCutKey.justifyLeft'),
        shortCut: {
          win: 'Alt + A',
          mac: '⌥A',
        },
      },
      {
        name: i18n('shortCutKey.justifyRight'),
        shortCut: {
          win: 'Alt + D',
          mac: '⌥D',
        },
      },
      {
        name: i18n('shortCutKey.justifyTop'),
        shortCut: {
          win: 'Alt + W',
          mac: '⌥W',
        },
      },
      {
        name: i18n('shortCutKey.justifyBottom'),
        shortCut: {
          win: 'Alt + S',
          mac: '⌥S',
        },
      },
      {
        name: i18n('shortCutKey.justifyCenter'),
        shortCut: {
          win: 'Alt + H',
          mac: '⌥H',
        },
      },
      {
        name: i18n('shortCutKey.justifyVertical'),
        shortCut: {
          win: 'Alt + V',
          mac: '⌥V',
        },
      },
      {
        name: i18n('shortCutKey.levelIsometry'),
        shortCut: {
          win: 'Ctrl + Shift + H',
          mac: '⇧⌘H',
        },
      },
      {
        name: i18n('shortCutKey.verticalIsometry'),
        shortCut: {
          win: 'Ctrl + Shift + U',
          mac: '⇧⌘U',
        },
      },
    ],
  },
  {
    name: i18n('shortCutKey.projectSetting'),
    hidden: true,
    items: [
      {
        name: i18n('shortCutKey.pageUp'),
        hidden: true,
        shortCut: {
          win: 'Ctrl + ]',
          mac: '⌘]',
        },
      },
      {
        name: i18n('shortCutKey.pageDown'),
        hidden: true,
        shortCut: {
          win: 'Ctrl + [',
          mac: '⌘[',
        },
      },
      {
        name: i18n('shortCutKey.recentPage'),
        hidden: true,
        shortCut: {
          win: 'Ctrl + M',
          mac: '⌘M',
        },
      },
      {
        name: i18n('shortCutKey.prePage'),
        hidden: true,
        shortCut: {
          win: 'Ctrl + <',
          mac: '⌘<',
        },
      },
      {
        name: i18n('shortCutKey.nextPage'),
        hidden: true,
        shortCut: {
          win: 'Ctrl + >',
          mac: '⌘>',
        },
      },
    ],
  },
  {
    name: i18n('shortCutKey.interfaceSetting'),
    items: [
      {
        name: i18n('shortCutKey.unfoldLeftBoard'),
        hidden: true,
        shortCut: {
          win: 'Ctrl + Alt + Left',
          mac: ' ⌥⌘Left',
        },
      },
      {
        name: i18n('shortCutKey.unfoldRightBoard'),
        hidden: true,
        shortCut: {
          win: 'Ctrl + Alt + Right',
          mac: '⌥⌘Right',
        },
      },
      {
        name: i18n('shortCutKey.preview'),
        hidden: true,
        shortCut: {
          win: 'Ctrl + F',
          mac: '⌘F',
        },
      },
      {
        name: i18n('shortCutKey.locateMainBoard'),
        hidden: true,
        shortCut: {
          win: 'Ctrl + 0',
          mac: '⌘0',
        },
      },
      {
        name: i18n('shortCutKey.rename'),
        shortCut: {
          win: 'F2',
          mac: 'Enter',
        },
      },
      {
        name: i18n('shortCutKey.newPage'),
        shortCut: {
          win: 'Ctrl + Enter',
          mac: '⌘Enter',
        },
      },
      {
        name: i18n('shortCutKey.actionName'),
        shortCut: {
          win: 'Ctrl + Shift + Enter',
          mac: '⇧⌘Enter',
        },
      },

      {
        name: i18n('shortCutKey.prePage'),
        shortCut: {
          win: 'Alt + Up',
          mac: '⌥Up',
        },
      },

      {
        name: i18n('shortCutKey.nextPage'),
        shortCut: {
          win: 'Alt + Down',
          mac: '⌥Down',
        },
      },

      {
        name: i18n('shortCutKey.lastOpenPage'),
        shortCut: {
          win: 'Alt + Left',
          mac: '⌥Left',
        },
      },
      {
        name: i18n('shortCutKey.savePageVersion'),
        shortCut: {
          win: 'Ctrl + Shift + S',
          mac: '⇧⌘S',
        },
      },
    ],
  },
  {
    name: i18n('shortCutKey.InterfaceView'),
    items: [
      {
        name: i18n('shortCutKey.openTree'),
        hidden: true,
        shortCut: {
          win: 'Alt + 1',
          mac: '⌥1',
        },
      },
      {
        name: i18n('shortCutKey.openLayer'),
        hidden: true,
        shortCut: {
          win: 'Alt + 2',
          mac: '⌥2',
        },
      },
      {
        name: i18n('shortCutKey.openComponent'),
        hidden: true,
        shortCut: {
          win: 'Alt + 3',
          mac: '⌥3',
        },
      },
      {
        name: i18n('shortCutKey.openIcons'),
        hidden: true,
        shortCut: {
          win: 'Alt + 4',
          mac: '⌥4',
        },
      },
      {
        name: i18n('shortCutKey.openResource'),
        hidden: true,
        shortCut: {
          win: 'Alt + 5',
          mac: '⌥5',
        },
      },
      {
        name: i18n('shortCutKey.switchTabs'),
        hidden: true,
        shortCut: {
          win: 'Alt + 1~5',
          mac: '⌥1~5',
        },
      },
      {
        name: i18n('shortCutKey.showHideGrid'),
        shortCut: {
          win: 'Alt + G',
          mac: '⌥G',
        },
      },
      {
        name: i18n('shortCutKey.showHideLayout'),
        shortCut: {
          win: 'Alt + L',
          mac: '⌥L',
        },
      },
      {
        name: i18n('shortCutKey.showRuler'),
        shortCut: {
          win: 'Alt + R',
          mac: '⌥R',
        },
      },
      {
        name: i18n('shortCutKey.showGuides'),
        shortCut: {
          win: 'Alt + T',
          mac: '⌥T',
        },
      },
      {
        name: i18n('shortCutKey.notes'),
        hidden: isDT,
        shortCut: {
          win: 'Ctrl + M',
          mac: '⌘M',
        },
      },
      {
        name: i18n('shortCutKey.findReplaceText'),
        shortCut: {
          win: 'Ctrl + F',
          mac: '⌘F',
        },
      },
      {
        name: i18n('shortCutKey.showHideLeftPanel'),
        shortCut: {
          win: 'Ctrl + Alt + 1',
          mac: '⌘⌥1',
        },
      },
      {
        name: i18n('shortCutKey.showHideRightPanel'),
        shortCut: {
          win: 'Ctrl + Alt + 2',
          mac: '⌘⌥2',
        },
      },
      {
        name: i18n('shortCutKey.fullScreen'),
        shortCut: {
          win: 'Ctrl + F11',
          mac: '⌘F11',
        },
      },
      {
        name: i18n('shortCutKey.flowChart'),
        shortCut: {
          win: 'Alt + C',
          mac: '⌥C',
        },
      },
    ],
  },
];
const IDocShortCut: IShortCutGroup[] = [
  {
    name: i18n('shortCutKey.projectTree'),
    items: [
      {
        name: i18n('shortCutKey.rename'),
        shortCut: {
          win: 'F2',
          mac: 'Enter',
        },
      },
      {
        name: i18n('shortCutKey.delete'),
        shortCut: {
          win: 'Delete/Backspace',
          mac: '⌫',
        },
      },
      {
        name: i18n('shortCutKey.copy'),
        shortCut: {
          win: 'Ctrl + C',
          mac: '⌘C',
        },
      },
      {
        name: i18n('shortCutKey.cut'),
        shortCut: {
          win: 'Ctrl + X',
          mac: '⌘X',
        },
      },
      {
        name: i18n('shortCutKey.paste'),
        shortCut: {
          win: 'Ctrl + V',
          mac: '⌘V',
        },
      },
    ],
  },
  {
    name: i18n('shortCutKey.canvas'),
    items: [
      {
        name: i18n('shortCutKey.drag'),
        shortCut: {
          win: i18n('shortCutKey.leftMouseButton'),
        },
      },
      {
        name: i18n('shortCutKey.zoom'),
        shortCut: {
          win: `Ctrl + ${i18n('shortCutKey.mouseWheel')}`,
          mac: `⌘${i18n('shortCutKey.mouseWheel')}`,
        },
      },
      {
        name: i18n('shortCutKey.zoomOut'),
        shortCut: {
          win: 'Ctrl + +',
          mac: '⌘+',
        },
      },
      {
        name: i18n('shortCutKey.zoomIn'),
        shortCut: {
          win: 'Ctrl + -',
          mac: '⌘-',
        },
      },
      {
        name: i18n('shortCutKey.zoomToOne'),
        shortCut: {
          win: 'Ctrl + 0',
          mac: `${getMacCmdOrCtrl()}0`,
        },
      },
      {
        name: i18n('shortCutKey.selectAll'),
        shortCut: {
          win: 'Ctrl + A',
          mac: '⌘A',
        },
      },
    ],
  },
  {
    name: i18n('shortCutKey.storyboard'),
    items: [
      {
        name: i18n('shortCutKey.overview'),
        shortCut: {
          win: 'Ctrl + 0',
          mac: '⌘0',
        },
      },
      {
        name: i18n('shortCutKey.map'),
        shortCut: {
          win: 'M',
          mac: 'M',
        },
      },
      {
        name: i18n('shortCutKey.preview'),
        shortCut: {
          win: 'P',
          mac: 'P',
        },
      },
      {
        name: i18n('shortCutKey.lineDescription'),
        shortCut: {
          win: 'S',
          mac: 'S',
        },
      },
      {
        name: i18n('shortCutKey.multiSelect'),
        shortCut: {
          win: `Shift/Ctrl + ${i18n('shortCutKey.leftKey')}`,
          mac: `⇧/⌘${i18n('shortCutKey.leftKey')}`,
        },
      },
      {
        name: i18n('shortCutKey.undoBtn'),
        shortCut: {
          win: 'Ctrl + Z',
          mac: '⌘Z',
        },
      },
      {
        name: i18n('shortCutKey.redoBtn'),
        shortCut: {
          win: 'Ctrl + Shift + Z',
          mac: '⇧⌘Z',
        },
      },
      {
        name: i18n('shortCutKey.focus'),
        shortCut: {
          win: 'F',
          mac: 'F',
        },
      },
    ],
  },
  {
    name: i18n('shortCutKey.page'),
    items: [
      {
        name: i18n('shortCutKey.rename'),
        shortCut: {
          win: 'F2',
          mac: 'Enter',
        },
      },
      {
        name: i18n('shortCutKey.copy'),
        shortCut: {
          win: 'Ctrl + C',
          mac: '⌘C',
        },
      },
      {
        name: i18n('shortCutKey.paste'),
        shortCut: {
          win: 'Ctrl + V',
          mac: '⌘V',
        },
      },
    ],
  },
  {
    name: i18n('shortCutKey.commentMode'),
    items: [
      {
        name: i18n('shortCutKey.select'),
        shortCut: {
          win: 'V',
          mac: 'V',
        },
      },
      {
        name: i18n('shortCutKey.pin'),
        shortCut: {
          win: 'D',
          mac: 'D',
        },
      },
      {
        name: i18n('shortCutKey.rectangle'),
        shortCut: {
          win: 'R',
          mac: 'R',
        },
      },
      {
        name: i18n('shortCutKey.circle'),
        shortCut: {
          win: 'C',
          mac: 'C',
        },
      },
      {
        name: i18n('shortCutKey.straightLine'),
        shortCut: {
          win: 'S',
          mac: 'S',
        },
      },
      {
        name: i18n('shortCutKey.arrow'),
        shortCut: {
          win: 'A',
          mac: 'A',
        },
      },
      {
        name: i18n('shortCutKey.multiSelect'),
        shortCut: {
          win: `Ctrl + ${i18n('shortCutKey.leftKey')}`,
          mac: `⌘${i18n('shortCutKey.leftKey')}`,
        },
      },
      {
        name: i18n('shortCutKey.cut'),
        shortCut: {
          win: 'Ctrl + X',
          mac: '⌘X',
        },
      },
      {
        name: i18n('shortCutKey.selectAll'),
        shortCut: {
          win: 'Ctrl + A',
          mac: '⌘A',
        },
      },
      {
        name: i18n('shortCutKey.prePage'),
        shortCut: {
          win: i18n('shortCutKey.left'),
        },
      },
      {
        name: i18n('shortCutKey.nextPage'),
        shortCut: {
          win: i18n('shortCutKey.right'),
        },
      },
      {
        name: i18n('shortCutKey.undoBtn'),
        shortCut: {
          win: 'Ctrl + Z',
          mac: '⌘Z',
        },
      },
      {
        name: i18n('shortCutKey.redoBtn'),
        shortCut: {
          win: 'Ctrl + Y',
          mac: '⌘Y',
        },
      },
    ],
  },
  {
    name: i18n('shortCutKey.reviewMode'),
    items: [
      {
        name: i18n('shortCutKey.select'),
        shortCut: {
          win: 'V',
        },
      },
      {
        name: i18n('shortCutKey.text'),
        shortCut: {
          win: 'T',
        },
      },
      {
        name: i18n('shortCutKey.multiSelect'),
        shortCut: {
          win: `Ctrl + ${i18n('shortCutKey.leftKey')}`,
          mac: `⌘${i18n('shortCutKey.leftKey')}`,
        },
      },
      {
        name: i18n('shortCutKey.cut'),
        shortCut: {
          win: 'Ctrl + X',
          mac: '⌘X',
        },
      },
      {
        name: i18n('shortCutKey.selectAll'),
        shortCut: {
          win: 'Ctrl + A',
          mac: '⌘A',
        },
      },
      {
        name: i18n('shortCutKey.prePage'),
        shortCut: {
          win: i18n('shortCutKey.left'),
        },
      },
      {
        name: i18n('shortCutKey.nextPage'),
        shortCut: {
          win: i18n('shortCutKey.right'),
        },
      },
      {
        name: i18n('shortCutKey.undoBtn'),
        shortCut: {
          win: 'Ctrl + Z',
          mac: '⌘Z',
        },
      },
      {
        name: i18n('shortCutKey.redoBtn'),
        shortCut: {
          win: 'Ctrl + Y',
          mac: '⌘Y',
        },
      },
    ],
  },
  {
    name: i18n('shortCutKey.developMode'),
    items: [
      {
        name: i18n('shortCutKey.magnifier'),
        shortCut: {
          win: 'Z',
          mac: 'Z',
        },
      },
      {
        name: i18n('shortCutKey.multiSelect'),
        shortCut: {
          win: `Shift/Ctrl + ${i18n('shortCutKey.leftKey')}`,
          mac: `⇧/⌘${i18n('shortCutKey.leftKey')}`,
        },
      },
      {
        name: i18n('shortCutKey.imgLayers'),
        shortCut: {
          win: i18n('shortCutKey.doubleClick'),
        },
      },
      {
        name: i18n('shortCutKey.percentageSpecs'),
        shortCut: {
          win: 'Alt',
          mac: '⌥',
        },
      },
      {
        name: i18n('shortCutKey.prePage'),
        shortCut: {
          win: i18n('shortCutKey.left'),
        },
      },
      {
        name: i18n('shortCutKey.nextPage'),
        shortCut: {
          win: i18n('shortCutKey.right'),
        },
      },
    ],
  },
  {
    name: i18n('shortCutKey.prototype'),
    items: [
      {
        name: i18n('shortCutKey.createrHotArea'),
        shortCut: {
          win: i18n('shortCutKey.doubleClick'),
        },
      },
      {
        name: i18n('shortCutKey.copyLink'),
        shortCut: {
          win: `Alt + ${i18n('shortCutKey.drag')}`,
          mac: `⌥ ${i18n('shortCutKey.drag')}`,
        },
      },
      {
        name: i18n('shortCutKey.copy'),
        shortCut: {
          win: 'Ctrl + C',
          mac: '⌘C',
        },
      },
      {
        name: i18n('shortCutKey.paste'),
        shortCut: {
          win: 'Ctrl + X',
          mac: '⌘X',
        },
      },
      {
        name: i18n('shortCutKey.undoBtn'),
        shortCut: {
          win: 'Ctrl + Z',
          mac: '⌘Z',
        },
      },
      {
        name: i18n('shortCutKey.redoBtn'),
        shortCut: {
          win: 'Ctrl + Y',
          mac: '⌘Y',
        },
      },
    ],
  },
  {
    name: i18n('shortCutKey.prdDocTree'),
    items: [
      {
        name: i18n('shortCutKey.rename'),
        shortCut: {
          win: i18n('shortCutKey.doubleClick'),
        },
      },
      {
        name: i18n('shortCutKey.delete'),
        shortCut: {
          win: 'Delete/Backspace',
          mac: '⌫',
        },
      },
      {
        name: i18n('shortCutKey.copy'),
        shortCut: {
          win: 'Ctrl + C',
          mac: '⌘C',
        },
      },
      {
        name: i18n('shortCutKey.cut'),
        shortCut: {
          win: 'Ctrl + X',
          mac: '⌘X',
        },
      },
      {
        name: i18n('shortCutKey.paste'),
        shortCut: {
          win: 'Ctrl + V',
          mac: '⌘V',
        },
      },
    ],
  },
];

interface IRPShortcutContentProp {
  className?: string;
}

/**
 * RP快捷键列表部分
 */
export class RPShortCutsContent extends React.Component<IRPShortcutContentProp, IShortcutsDialogState> {
  private cache_key = 'rp_keyData';

  constructor(props: IRPShortcutContentProp) {
    super(props);

    const keyData = localStorage.getItem(this.cache_key);
    // FIXME Mxj 这里直接使用缓存中的数据，会导致快捷键有调整时不能显示正确的内容
    const expandMap: { [key: string]: boolean } = {};
    if (keyData) {
      const data = JSON.parse(keyData) as IShortCutGroup[];
      data.forEach((item) => {
        expandMap[item.name] = !!item.expand;
      });
    }
    const data = [...RPShortCut];
    data.forEach((item: IShortCutGroup) => {
      item.expand = expandMap[item.name] === undefined ? true : expandMap[item.name];
    });
    this.state = {
      data,
    };
  }

  onHandleClick(name: string) {
    const newData = [...this.state.data];
    if (name) {
      newData.forEach((item) => {
        if (item.name === name) {
          item.expand = !item.expand;
        }
      });
      localStorage.setItem(this.cache_key, JSON.stringify(newData));
      this.setState({
        data: newData,
      });
    }
  }

  render() {
    const { data } = this.state;
    return (
      <div className={classnames(isEn ? 'shortcut-dialog-content-en' : 'shortcut-dialog-content-zh')}>
        <ScrollView autoHeightMin={200}>
          <ul>
            {data
              .filter((item) => !item.hidden)
              .map((item) => {
                let liH = 0;
                item.items.forEach((c) => (c.hidden ? liH : (liH += 43)));
                return (
                  <li key={item.name}>
                    <div className="shortcut-title" onClick={this.onHandleClick.bind(this, item.name)}>
                      <span>{item.name}</span>
                      <span className={item.expand ? 'icondown' : 'iconright'}>
                        <Icon cls={'tag_rightarrow'} solid />
                      </span>
                    </div>

                    <ul className="shortcut-child-box" style={{ height: item.expand ? liH : 0 }}>
                      {item.items
                        .filter((child) => !child.hidden)
                        .map((child) => {
                          return (
                            <li key={child.name}>
                              <span>{child.name}</span>
                              <span>
                                <Shortcut shortCut={child.shortCut.win} macOSShortCut={child.shortCut.mac} />
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                  </li>
                );
              })}
          </ul>
        </ScrollView>
      </div>
    );
  }
}

class ShortcutsDialog extends React.Component<IShortcutsDialogProps, IShortcutsDialogState> {
  constructor(props: IShortcutsDialogProps) {
    super(props);
    const keyData = localStorage.getItem(`${props.product}_keyData`);
    // FIXME Mxj 这里直接使用缓存中的数据，会导致快捷键有调整时不能显示正确的内容
    const expandMap: { [key: string]: boolean } = {};
    if (keyData) {
      const data = JSON.parse(keyData) as IShortCutGroup[];
      data.forEach((item) => {
        expandMap[item.name] = !!item.expand;
      });
    }
    const data = props.product === 'rp' ? [...RPShortCut] : [...IDocShortCut];
    data.forEach((item: IShortCutGroup) => {
      item.expand = expandMap[item.name] === undefined ? true : expandMap[item.name];
    });
    this.state = {
      data,
    };
  }

  onHandleClick(name: string) {
    const newData = [...this.state.data];
    if (name) {
      newData.forEach((item) => {
        if (item.name === name) {
          item.expand = !item.expand;
        }
      });
      localStorage.setItem(`${this.props.product}_keyData`, JSON.stringify(newData));
      this.setState({
        data: newData,
      });
    }
  }

  render() {
    const { data } = this.state;
    return (
      <div className={classnames('shortcut-dialog-module', isEn ? 'shortcut-dialog-en' : 'shortcut-dialog')}>
        <Dialog
          onCancel={(e) => {
            e.stopPropagation();
            this.props.onClose();
          }}
          contentMinHeight={320}
          title={i18n('shortCutKey.shortCutKey')}
          contentOverflow="hidden"
          showCancel={false}
          showOk={false}
        >
          <div className={isEn ? 'shortcut-dialog-content-en' : 'shortcut-dialog-content-zh'}>
            <ScrollView autoHeight autoHeightMin={200} autoHeightMax={488} height="66vh">
              <ul>
                {data.map((item) => {
                  if (item.hidden) {
                    return;
                  }
                  let liH = 0;
                  item.items.forEach((c) => (c.hidden ? liH : (liH += 43)));
                  return (
                    <li key={item.name}>
                      <div className="shortcut-title" onClick={this.onHandleClick.bind(this, item.name)}>
                        <span>{item.name}</span>
                        <span className={item.expand ? 'icondown' : 'iconright'}>
                          <Icon cls={'tag_rightarrow'} solid />
                        </span>
                      </div>

                      <ul className="shortcut-child-box" style={{ height: item.expand ? liH : 0 }}>
                        {item.items.map((child) => {
                          if (child.hidden) {
                            return;
                          }
                          return (
                            <li key={child.name}>
                              <span>{child.name}</span>
                              <span>
                                <Shortcut shortCut={child.shortCut.win} macOSShortCut={child.shortCut.mac} />
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </ScrollView>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default ShortcutsDialog;
