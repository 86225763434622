import ITypography, { ITypographyValue } from '@fbs/rp/models/ds/typography';
import { put, axDelete, patch, post } from '../helper';

export async function addTypography(libID: string, typography: ITypographyValue): Promise<ITypography> {
  return await put<ITypography>(`/ds/typography/${libID}`, {
    typography,
  });
}

export async function removeTypography(id: string): Promise<void> {
  await axDelete(`/ds/typography/${id}`);
}

export async function patchTypography(id: string, data: Partial<ITypography>): Promise<ITypography> {
  return await patch(`/ds/typography/${id}`, data);
}

export async function moveTypography(libID: string, resID: string, index: number): Promise<ITypography> {
  return await post<ITypography>(`/ds/typography/${libID}/move/${resID}`, { index });
}
