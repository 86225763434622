import { IPosition } from '@/fbs/idoc/models/common';
import { ISize } from '../type';
import { isSafari } from '@/utils/envUtils';

function mimes(): any {
  /*
   * Only WOFF and EOT mime types for fonts are 'real'
   * see http://www.iana.org/assignments/media-types/media-types.xhtml
   */
  let WOFF = 'application/font-woff';
  let JPEG = 'image/jpeg';
  return {
    woff: WOFF,
    woff2: WOFF,
    ttf: 'application/font-truetype',
    otf: 'application/font-opentype',
    eot: 'application/vnd.ms-fontobject',
    png: 'image/png',
    jpg: JPEG,
    jpeg: JPEG,
    gif: 'image/gif',
    tiff: 'image/tiff',
    svg: 'image/svg+xml',
  };
}

/**
 * 扩展名
 */
export function parseExtension(url: string): string {
  let match = /\.([^\\.\\/]*?)$/g.exec(url);
  if (match) {
    return match[1];
  }
  return '';
}

export function mimeType(url: string) {
  let extension = parseExtension(url).toLowerCase();
  return mimes()[extension] || '';
}

function toBlob(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise(function (resolve) {
    let binaryString = window.atob(canvas.toDataURL().split(',')[1]);
    let length = binaryString.length;
    let binaryArray = new Uint8Array(length);

    for (let i = 0; i < length; i++) binaryArray[i] = binaryString.charCodeAt(i);

    resolve(
      new Blob([binaryArray], {
        type: 'image/png',
      }),
    );
  });
}

export function canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
  if (canvas.toBlob) {
    console.time('canvasToBlob');
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        console.timeEnd('canvasToBlob');
        blob && resolve(blob);
      });
    });
  }

  return toBlob(canvas);
}

export function resolveUrl(url: string, baseUrl: string) {
  let doc = document.implementation.createHTMLDocument();
  let base = doc.createElement('base');
  doc.head.appendChild(base);
  let a = doc.createElement('a');
  doc.body.appendChild(a);
  base.href = baseUrl;
  a.href = url;
  return a.href;
}

export function uid() {
  let index = 0;

  return function () {
    return 'u' + fourRandomChars() + index++;

    function fourRandomChars() {
      /* see http://stackoverflow.com/a/6248722/2519373 */
      return ('0000' + ((Math.random() * Math.pow(36, 4)) << 0).toString(36)).slice(-4);
    }
  };
}

export function escapeString(string: string) {
  return string.replace(/([.*+?^${}()|\\[\]\\/\\])/g, '\\$1');
}

export function delay(ms: number) {
  return function (arg: any) {
    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve(arg);
      }, ms);
    });
  };
}

export function escapeXhtml(str: string): string {
  // .replace(/%/g, '%25')
  return str.replace(/#/g, '%23').replace(/\n/g, '%0A').replace(/<br>/gi, '<br/>'); //;
}

/**
 * 此方法代替上面的 width heihgt
 * @param node
 * @returns
 */
function bounds(node: Element): DOMRect {
  const rect: DOMRect = node.getBoundingClientRect();
  return rect;
}

export function getSize(options: Partial<ISize>, node: Element): { width: number; height: number } {
  let { width, height } = options || {};
  if (!(width && height)) {
    // 默认size
    const b = bounds(node);
    if (!width) {
      width = b.width;
    }
    if (!height) {
      height = b.height;
    }
  }
  return {
    width,
    height,
  };
}

export function closest(node: Element, className: string): Element | null {
  let parent = node;
  while (parent) {
    if (parent.classList.contains(className)) {
      return parent;
    }
    parent = node.parentNode as Element;
  }
  return null;
}

// 下载图像
export const saveBlob = (blob: Blob, fileName: string) => {
  // const a = document.createElement('a');
  // a.style.display = 'none';
  // const url = window.URL.createObjectURL(blob);
  // a.href = url;
  // a.download = fileName;
  // a.click();

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a'); // 生成一个a元素
  const e = new MouseEvent('click'); // 创建一个单击事件
  a.download = fileName; // 设置图片名称
  a.href = url; // 将生成的URL设置为a.href属性
  a.dispatchEvent(e); // 触发a的单击事件
};

export const dataURLtoBlob = (dataurl: string): Blob | undefined => {
  const arr: string[] = dataurl.split(',');
  if (!arr) {
    return;
  }
  if (arr[0]) {
    const matcharr = arr[0].match(/:(.*?);/);
    if (matcharr) {
      const mime = matcharr[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    }
  }
};

/**
 * 下载文本
 * @param url
 * @param fileName
 */
export const savePlainText = (text: string, fileName: string) => {
  var pom = document.createElement('a');
  pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  pom.setAttribute('download', fileName);
  if (document.createEvent) {
    var event = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    pom.dispatchEvent(event);
  } else {
    pom.click();
  }
};

export const getComponentByIds = (ids: string[]): (Element | null)[] => {
  return ids.map((t) => getComponentById(t)).filter((t) => t instanceof Element);
};

export const getComponentById = (id: string): Element | null => {
  return document.querySelector(`div[id="${id}"]`);
};

/**
 * canvas绘制svg图片
 * @param image
 * @param size
 */
export const imageToCanvas = (image: HTMLImageElement, size?: ISize): Promise<HTMLCanvasElement> => {
  return new Promise((resolve) => {
    const { width = 100, height = 100 } = size ?? {};
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(image as CanvasImageSource, 0, 0);
      if (isSafari()) {
        // fixme:safari图片下载第一次会空白,第二次正常
        if (isCanvasBlank(canvas)) {
          window.debug && console.error('Canvas in safari not load image correct, try again');
        }
      }
    }
    // 清理内存
    image.remove();

    resolve(canvas);
  });

  // return canvas;
};

export function isCanvasBlank(canvas: HTMLCanvasElement): boolean {
  var blank = document.createElement('canvas');
  blank.width = canvas.width;
  blank.height = canvas.height;
  var ctx = blank.getContext('2d');
  if (ctx) {
    // 透明
    // console.log(canvas.toDataURL().length, blank.toDataURL().length);
    // ctx.fillStyle = '#FFFFFF';
    // ctx.fillRect(0, 0, blank.width, blank.height);
    return canvas.toDataURL().length === blank.toDataURL().length;
  }
  return false;
}

/**
 * svg转成datauri
 * encodeURI(svg)   escapeXhtml(svg);
 * @param svg
 */
export function svgToDataUri(svg: string): string {
  // fixme: fixfox下某些组合字体会出现编码错误  The URI is malformed
  return 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg);
  // return 'data:text/plain;charset=utf-8,' + encodeURIComponent(svg);
}

// tofix
// Safari 6.0不喜欢<use>标签内的结束斜杠
// svg.replace(/\/>/g,'>')

export function svgToDataUriInSafari(svg: string): string {
  // const div = document.createElement('div')
  // div.innerHTML = svg
  // if (div.firstChild) {
  // get svg data
  // var xml = new XMLSerializer().serializeToString(div.firstChild);

  // make it base64 btoa(unescape(encodeURIComponent(res.svg)
  var svg64 = btoa(unescape(encodeURIComponent(svg))); // btoa(escapeXhtml(xml))  //
  var b64Start = 'data:image/svg+xml;base64,';

  // prepend a "header"
  var image64 = b64Start + svg64;

  // set it as the source of the img element
  // img.src = image64;
  return image64;
  // }
  // return ''
}

export const makeElementByString = (html: string): Element => {
  const body = document.createElement('body');
  body.innerHTML = html;
  return body;
};

export const pushData = <T>(result: T[], data: T | T[]): void => {
  if (Array.isArray(data)) {
    result.push(...data);
  } else {
    result.push(data);
  }
};

export const unshiftData = <T>(result: T[], data: T | T[]): void => {
  if (Array.isArray(data)) {
    result.unshift(...data);
  } else {
    result.unshift(data);
  }
};

export const equalSize = (a: ISize, b: ISize) => {
  return a.width === b.width && a.height === b.height;
};
/**
 * 角度转弧度
 * @param {number} angle
 * @returns {number}
 */
export function degToRad(angle: number) {
  return angle * (Math.PI / 180);
}

export const oppositePoint = (p: IPosition) => {
  const { x, y } = p;
  return {
    x: -x,
    y: -y,
  };
};

// 反向偏移
export const offseOpposite = (position: IPosition, offset: IPosition) => {
  return {
    x: position.x - offset.x,
    y: position.y - offset.y,
  };
};

/**
 * 字符串长度排序
 * @param arr
 */
export const sortByLength = (arr: string[]) => {
  return arr.sort((a, b) => {
    if (a.length > b.length) {
      return -1;
    } else if (a.length < b.length) {
      return 1;
    }
    return 0;
  });
};
