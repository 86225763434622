import * as React from 'react';

export interface DefaultProps {
  showSelectionTextRange?: boolean;
  onMouseDown?: Function;
  onMouseUp?: Function;
  onDragEnd?: Function;
}

const WithSelectTextRange = <P extends DefaultProps>(WrappedComponent: React.ComponentType<P>) => {
  const SelectTextRange = React.forwardRef<typeof WrappedComponent, P>(function SelectTextRange(props, ref) {
    const [showSelectionTextRange, setShowSelectionTextRange] = React.useState(false);
    const handleMouseDown = () => {
      setShowSelectionTextRange(true);
    };

    const handleMouseUp = () => {
      setShowSelectionTextRange(false);
    };
    const textRangeStyle = React.useMemo((): React.CSSProperties => {
      return { display: showSelectionTextRange ? 'block' : 'none' };
    }, [showSelectionTextRange]);

    return (
      <>
        <div className="selection-text-range" style={textRangeStyle}></div>
        <WrappedComponent
          {...props}
          ref={ref}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onDragEnd={handleMouseUp}
        />
      </>
    );
  });

  return SelectTextRange;
};

export default WithSelectTextRange;
