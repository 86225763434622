import { min } from '@utils/globalUtils';

import { IBounds } from '@fbs/common/models/common';

import { isMobileDevice } from './previewHelper';

export function getDomByAlias(parent: HTMLElement, alias: string): HTMLElement | undefined {
  let result: HTMLElement | undefined;
  for (let i = 0, c = parent.childElementCount; i < c; i++) {
    const dom = parent.children[i] as HTMLElement;
    if (dom.dataset['alias'] === alias) {
      result = dom;
      break;
    }
  }

  if (!result) {
    for (let i = 0, c = parent.childElementCount; i < c; i++) {
      const dom = parent.children[i] as HTMLElement;
      result = getDomByAlias(dom, alias);
      if (result) {
        break;
      }
    }
  }
  return result;
}

/**
 * 查找父级dom
 */
export function getParentDomByFilter(el: HTMLElement, filter: (e: HTMLElement) => boolean): HTMLElement | null {
  const parent = el.parentElement;
  if (!parent) {
    return parent;
  }
  if (filter(parent)) {
    return parent;
  } else {
    return getParentDomByFilter(parent, filter);
  }
}

/**
 * 获取滚动到目标所需的offset
 */
export function getOffsetScrollToTarget(
  scrollBounds: IBounds,
  targetBounds: IBounds,
  scrollOpt: {
    scrollHeight: number;
    scrollWidth: number;
    scrollLeft: number;
    scrollTop: number;
  },
): { x: number; y: number } {
  const { scrollHeight, scrollWidth, scrollLeft, scrollTop } = scrollOpt;
  const scrollBarWidth = 17;
  const targetTop = targetBounds.top + scrollTop - scrollBounds.top;
  const isMobile = isMobileDevice();
  const maxTop = scrollHeight - scrollBounds.height + (!isMobile ? scrollBarWidth : 0);
  const targetMaxTop = min(maxTop, targetTop);
  const targetLeft = targetBounds.left + scrollLeft - scrollBounds.left;
  const maxLeft = scrollWidth - scrollBounds.width + (!isMobile ? scrollBarWidth : 0);
  const targetMaxLeft = min(maxLeft, targetLeft);
  return {
    x: targetMaxLeft - scrollLeft,
    y: targetMaxTop - scrollTop,
  };
}

/**
 * 转移焦点
 */
export function changeFocus() {
  const input = document.getElementById('focus-input');
  if (input) {
    input.focus();
  } else {
    const input = document.createElement('input');
    input.id = 'focus-input';
    input.style.position = 'fixed';
    input.style.top = '-10000px';
    input.style.left = '-10000px';
    document.body.append(input);
    input.focus();
  }
}
