import * as React from 'react';
import classnames from 'classnames';
import Tooltip from '../Tooltip';

import './index.scss';
import './font_1475460_nrd5bd5wvpf/iconfont.css';

export interface IIconProp {
  tips?: string;
  autoHideTips?: boolean;
  className?: string;
  style?: React.CSSProperties;
  selected?: boolean;
  disabled?: boolean;
  isInPopup?: boolean;
  size?: number;
  // 字体图标
  cls?: string;
  // 位图图标
  url?: string;

  customFontIcon?: { iconCode: number; fontName: string };

  theme?: 'button' | 'hot-button' | 'tag';
  onClick?: React.MouseEventHandler;
  onMouseDown?: React.MouseEventHandler;
  onMouseEnter?: React.MouseEventHandler;
  onMouseOver?: React.MouseEventHandler;
}

export interface IIconState {}

class Icon extends React.PureComponent<IIconProp, IIconState> {
  static defaultProps: Partial<IIconProp> = {
    size: 16,
    theme: 'button',
  };

  selfRef: React.RefObject<HTMLDivElement>;

  constructor(props: IIconProp) {
    super(props);
    this.state = {};
    this.selfRef = React.createRef();
  }

  renderContent = () => {
    const { selected, cls, style, size, disabled, url, theme, customFontIcon } = this.props;
    const { onMouseDown, onClick, onMouseOver, onMouseEnter } = this.props;
    const styles: React.CSSProperties = style || {};
    const isImgIcon = !!url;
    const isFontIcon = !isImgIcon && !customFontIcon;
    const iconSize = size || 16;
    const isCustomIcon = !!customFontIcon && !isFontIcon && !isImgIcon;
    if (isFontIcon || isCustomIcon) {
      styles.fontSize = iconSize;
      styles.height = iconSize + 2;
      styles.width = iconSize + 2;
      styles.lineHeight = `${iconSize}px`;
    }

    return (
      <div
        style={styles}
        className={classnames(`dsm-c-rp-icon`, {
          selected: selected && theme === 'button' && !disabled,
          disabled: disabled,
          'button-theme': theme === 'button' || theme === 'hot-button',
          'hot-button-theme': theme === 'hot-button',
          'img-icon': isImgIcon,
          'font-icon': isFontIcon,
        })}
        onClick={!disabled ? onClick : undefined}
        onMouseDown={!disabled ? onMouseDown : undefined}
        onMouseEnter={!disabled ? onMouseEnter : undefined}
        onMouseOver={!disabled ? onMouseOver : undefined}
      >
        <div className="icon-background" />
        {isImgIcon && <img src={url} width={iconSize} height={iconSize} draggable={false} />}
        {isFontIcon && <i style={{ fontSize: size }} className={`mockplus_rp mockplus_rp_${cls || 'icon_normal'}`} />}
        {isCustomIcon && (
          <label style={{ fontSize: size, fontFamily: customFontIcon!.fontName }}>
            {String.fromCharCode(customFontIcon!.iconCode)}
          </label>
        )}
      </div>
    );
  };

  render() {
    const { tips, className, isInPopup, autoHideTips } = this.props;
    const cls = `dsm-c-rp-icon-tooltip ${className || ''}`;
    if (tips) {
      return (
        <Tooltip text={tips} ownerIsDialog={isInPopup} className={cls} autoHide={autoHideTips ?? true}>
          {this.renderContent()}
        </Tooltip>
      );
    }
    return <div className={cls}>{this.renderContent()}</div>;
  }
}

export default Icon;
