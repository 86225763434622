import * as React from 'react';

import { min } from '@utils/globalUtils';
import { parseColorToString } from '@utils/graphicsUtils';

import { IconValue, IPathValue } from '@fbs/rp/models/value';
import { UIComponent } from '@editor/comps';
import { transformPathDataToPath } from '@helpers/pathHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { DefaultIconColor } from '@consts/colors';

import { renderClipFill } from '../../basic/common/GradientFragment';

interface IProps {
  comp: UIComponent;
  className?: string;
  /*
    ArrowPathIcon,会存在UIComponent.resizeHandler2 计算中导致错误的position
    因为UIComponent.resizeHandler2的影响范围太广，这里添加个动态函数,来根据组件情况，修正错误的positions数据
  */
  onComputerStyle?: (style: React.CSSProperties) => React.CSSProperties;
}

const ArrowPathIcon: React.FC<IProps> = (props: IProps) => {
  const { comp, className, onComputerStyle } = props;
  const { type, value, size, position, rotate, properties, id, opacity } = comp;
  const transition = comp.getTransition();
  const options = {
    id,
    type,
    size,
    fill: properties.fill,
    scale: 1,
    transition,
  };
  const isPath = type === 'path';
  const d = isPath ? transformPathDataToPath(value as IPathValue) : String.fromCharCode((value as IconValue).iconCode);
  let arrowStyle: React.CSSProperties = {
    ...size,
    top: position.y,
    left: position.x,
    transform: StyleHelper.getRotate(rotate),
    position: 'absolute',
    overflow: 'visible',
    opacity: StyleHelper.getOpacity(opacity),
  };

  if (!isPath) {
    arrowStyle.fontSize = min(size.width, size.height);
    arrowStyle.lineHeight = `${size.height}px`;
    arrowStyle.fontFamily = (value as IconValue).fontName;
    arrowStyle.color = parseColorToString(properties.icon?.color || DefaultIconColor);
    if (size.height < 12) {
      arrowStyle.transformOrigin = 'center';
      arrowStyle.transform = `scale(${size.height / 12}) ${arrowStyle.transform || ''}`;
    }
    arrowStyle.top = '50%';
    arrowStyle.right = (20 - size.width) / 2;
    arrowStyle.left = 'unset';
    arrowStyle.transform = `translateY(-50%) ${arrowStyle.transform}`;
    //如果存在计算样式，使用前修正样式
    if (onComputerStyle) {
      arrowStyle = onComputerStyle(arrowStyle);
    }
    return (
      <label className={className} style={arrowStyle}>
        {d}
      </label>
    );
  }

  const parser = StyleHelper.initSVGStyleParser(properties);
  const { strokeDasharray, stroke, strokeWidth, strokeLinecap, strokeLinejoin } = StyleHelper.initSVGStyleParser(
    properties,
  ).getStroke();
  //如果存在计算样式，使用前修正样式
  if (onComputerStyle) {
    arrowStyle = onComputerStyle(arrowStyle);
  }
  return (
    <svg className={className} style={arrowStyle}>
      {renderClipFill(
        options,
        <path
          d={d}
          fill={parser.getFill(`${type}-fill-${id}`)}
          strokeWidth={strokeWidth}
          stroke={stroke}
          strokeDasharray={strokeDasharray}
          strokeLinejoin={strokeLinejoin}
          strokeLinecap={strokeLinecap}
          style={{ transition }}
        />,
      )}
      <path
        d={d}
        fill="none"
        strokeWidth={strokeWidth}
        stroke={stroke}
        strokeDasharray={strokeDasharray}
        strokeLinejoin={strokeLinejoin}
        strokeLinecap={strokeLinecap}
        style={{ transition }}
      />
    </svg>
  );
};

export default ArrowPathIcon;
