import * as React from 'react';

import * as ReactDnD from 'react-dnd';
import * as dnd from '@consts/dnd';
import classnames from 'classnames';

import { DropMode, ITreeItem } from '@/helpers/treeCompHelper';

import { Icon } from '@/dsm';
import { DragTreeItem, dragSpec, dragCollect, dropCollect, IDropTargetProp, IDragTreeItemProps, dropSpec } from '.';

import './index.scss';

class CollapseItem extends DragTreeItem {
  renderMain() {
    const { itemComp, item, onItemClick, isOver, selected, level } = this.props;
    const { dropMode } = this.state;
    const text = itemComp.getText();
    const listWidth = 170;
    const hasInteraction = Object.keys(itemComp.interactions).length > 0;
    return (
      <div
        className={classnames('tree-editor-drag-item', {
          [`tree-node-drop-at-${dropMode}`]: isOver && dropMode !== DropMode.None,
          interaction: hasInteraction,
        })}
        onClick={onItemClick?.bind(this, item.data.id)}
      >
        <div
          className={classnames('list-tree-item', { selected })}
          style={{ width: 'auto', maxWidth: listWidth - 16 * (level - 1) }}
        >
          <div className="item-icon" onClick={this.handleExpandclick}>
            {item.children?.length && <Icon size={16} cls={item.data.expand ? 'tag_downarrow' : 'Right'} />}
          </div>
          {this.renderText(text)}
        </div>
      </div>
    );
  }

  render() {
    const { children, selected, level } = this.props;
    const { connectDragSource, connectDropTarget } = this.props;
    const intention = 16;
    const indent = 12; //缩进
    const paddingLeft = level === 0 ? indent : intention;
    const bgIntention = level ? 14 : 0;

    return connectDropTarget(
      connectDragSource(
        <li className="list-tree-li collapse-li" ref={this.dragDom}>
          <div className="list-tree-action">{this.renderDragger()}</div>
          <div
            className={classnames('list-tree-bg', { selected: selected })}
            style={{ marginLeft: -bgIntention, width: `calc(100% + ${bgIntention}px)` }}
          />
          <div style={{ paddingLeft }}>
            {this.renderMain()}
            <div className="tree-editor-children">
              <ul>{children}</ul>
            </div>
          </div>
        </li>,
      ),
    );
  }
}

const foldPanelDropSpec = {
  ...dropSpec,
  hover: (props: IDropTargetProp, monitor: ReactDnD.DropTargetMonitor, component: any) => {
    if (!monitor.canDrop()) {
      return;
    }

    if (!monitor.isOver()) {
      return;
    }

    const intention = 16;
    const dragDomBounds = component.dragDom.current?.getBoundingClientRect();
    const hoverOffset = monitor.getClientOffset();
    const siblingLeft = intention + 32;
    const isDragInner = dragDomBounds && hoverOffset && hoverOffset.x > dragDomBounds.left + siblingLeft;

    const dragItem: ITreeItem = monitor.getItem().data;
    const dropItem: ITreeItem = (props as IDragTreeItemProps).item;

    // 放置点是子节点，只能修改位置，不能往子节点添加更深级节点
    if (dropItem.parent && isDragInner) {
      return;
    }
    // 一级节点包含子节点，不能做为另一个一级节点的子节点
    if (!dragItem.parent && dragItem.children?.length && !dropItem.parent && isDragInner) {
      return;
    }

    if (isDragInner) {
      component.setState({ dropMode: DropMode.Child });
      return;
    }
    if (dragDomBounds && hoverOffset && hoverOffset.y < dragDomBounds.top + 30 / 2) {
      component.setState({ dropMode: DropMode.Before });
    } else {
      component.setState({ dropMode: DropMode.After });
    }
  },
};

const DragTarget = ReactDnD.DragSource(dnd.COMP_TREE_NODE, dragSpec, dragCollect)(CollapseItem);
export default ReactDnD.DropTarget([dnd.COMP_TREE_NODE], foldPanelDropSpec, dropCollect)(DragTarget);
