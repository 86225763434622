type TObserveKey<T extends object> = keyof T | 'default';
type TObserveFunc = () => void;

export class CustomObservableObject<T extends object> {
  public value: T;
  private observers: {
    [key in TObserveKey<T>]?: TObserveFunc[];
  } = {};

  constructor(observeObject: T) {
    this.value = new Proxy(observeObject, {
      set: (obj: T, prop: keyof T, val: unknown) => {
        // 内容未发生改变时无需处理更新
        if (obj[prop] !== val) {
          Reflect.set(obj, prop, val);
          this.notify(prop);
        }
        return Reflect.set(obj, prop, val);
      },
      get(obj: T, prop: keyof T) {
        return Reflect.get(obj, prop);
      },
    });
  }

  private notify(prop: keyof T) {
    this.observers[prop]?.forEach((observer: TObserveFunc) => observer());
    this.observers.default?.forEach((observer: TObserveFunc) => observer());
  }

  subscribe(f: TObserveFunc, type: TObserveKey<T> = 'default') {
    this.observers[type] ? this.observers[type]?.push(f) : (this.observers[type] = [f]);
  }

  unsubscribe(f: TObserveFunc, type: TObserveKey<T> = 'default') {
    this.observers[type] = this.observers[type]?.filter((subscriber) => subscriber !== f);
  }
}
