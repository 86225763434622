import * as React from 'react';

import * as _ from 'lodash';
import classnames from 'classnames';

import { transBlankChart } from '@utils/textUtils';

import { ISize } from '@fbs/common/models/common';

import { UIContainerComponent } from '@editor/comps';
import { StyleHelper } from '@helpers/styleHelper';
import { PropertyStructureAlign } from '@/libs/enum';
import { mergeProperties } from '@/helpers/propertiesHelper';

import { IComponentProps } from '../../types';

import './index.scss';

interface IState {
  checked: boolean;
  text: string;
  textStyle: React.CSSProperties;
  checkViewerStyle: React.CSSProperties;
  boxStyle: React.CSSProperties;
  opacity: number;
  boxSize: ISize;
}

function parserState(props: IComponentProps): IState {
  const { comp } = props;
  const group = comp as UIContainerComponent;
  const { opacity } = group;
  const checker = (group.getComponentByAlias('icon') || group.components[0]) as UIContainerComponent;
  const checkerIcon = checker.getComponentByAlias('innerCircle') || checker.components[0];
  const text = group.getComponentByAlias('generalText') || group.components[1];
  const { properties: boxProperties, size: boxSize } = checker!;
  const { properties: iconProperties, size: iconSize, hidden } = checkerIcon!;

  const boxStyleParser = StyleHelper.createCSSStyleParser(boxProperties);

  const checkerStyleParser = StyleHelper.createCSSStyleParser(iconProperties);

  let textStyle = {};
  let label = '';
  if (text) {
    const { value, properties: textProps, size: textSize, position: textPosition } = text;
    const textStyleParser = StyleHelper.createCSSStyleParser(textProps);
    const { size, style } = textStyleParser.getTextStyleData(textSize, textProps.textStyle);
    textStyle = {
      left: textPosition.x,
      top: textPosition.y,
      ...size,
      ...style,
      opacity: StyleHelper.getOpacity(text?.opacity),
    };
    label = value as string;
  }

  return {
    opacity: StyleHelper.getOpacity(opacity),
    checked: group.selected || !hidden,
    text: transBlankChart(`${label}`),
    textStyle,
    boxSize,
    checkViewerStyle: {
      transform: `translate(${(boxSize.width - iconSize.width) / 2}px, ${(boxSize.height - iconSize.height) / 2}px)`,
      ...iconSize,
      ...checkerStyleParser.getFillStyle(iconSize),
      ...checkerStyleParser.getStrokeStyle(),
      ...checkerStyleParser.getRadiusStyle(iconSize),
      opacity: StyleHelper.getOpacity(checkerIcon?.opacity),
    },
    boxStyle: {
      boxSizing: 'border-box',
      ...boxSize,
      ...boxStyleParser.getRadiusStyle(boxSize),
      ...boxStyleParser.getStrokeStyle(),
      ...boxStyleParser.getFillStyle(boxSize),
      ...boxStyleParser.getShadowStyle(),
      opacity: StyleHelper.getOpacity(checker.opacity),
    },
  };
}

const Radio: React.FC<IComponentProps> = (props) => {
  const { checked, text, textStyle, checkViewerStyle, boxStyle, opacity, boxSize } = _.memoize(parserState)(props);
  const { comp } = props;
  const compData = comp.toJSON();
  const compMergeProperties = mergeProperties(comp.properties, compData.properties);

  const checkStyle: React.CSSProperties = { ...boxSize };
  if (compMergeProperties.structure?.value === PropertyStructureAlign.TextLeft) {
    textStyle.left = 0;
    textStyle.right = undefined;
    checkStyle.left = undefined;
    checkStyle.right = 0;
  }
  return (
    <div className={classnames('lib-comp-radio', { 'component-cursor-pointer': props.isPreview })} style={{ opacity }}>
      <div className="lib-comp-checker-box" style={checkStyle}>
        <div className="lib-comp-checker-border" style={boxStyle} />
        {checked && <div className="lib-comp-checker-icon" style={checkViewerStyle} />}
      </div>
      {text && (
        <label className="lib-comp-checker-text" style={textStyle}>
          {text}
        </label>
      )}
    </div>
  );
};

export default Radio;
