import * as React from 'react';
import { isUndefined } from 'lodash';
import { StyleHelper } from '@helpers/styleHelper';
import { FillType } from '@fbs/rp/models/properties/fill';
import { IRange } from '@fbs/rp/models/properties/base';
import { DefaultStrokeColor, DefaultWhiteFillColor } from '@consts/colors';
import i18n from '@i18n';

import { IComponentProps } from '../../types';
import { CArc } from '../../constants';
import { makeCommonComponent, getDefaultShadow } from '../../helper';

export function makeArc(id: string) {
  return makeCommonComponent(id, CArc, {
    properties: {
      angle: {
        prop: 'number',
        name: i18n('property.propertyNames.angle'),
        value: {
          min: 0,
          max: 100,
          value: 25,
        },
      },
      fill: {
        type: FillType.solid,
        color: DefaultWhiteFillColor,
        disabled: true,
      },
      stroke: {
        thickness: 1,
        color: DefaultStrokeColor,
      },
      close: {
        prop: 'boolean',
        name: i18n('property.propertyNames.close'),
        value: false,
      },
      shadow: getDefaultShadow(),
    },
  });
}

export default class Arc extends React.Component<IComponentProps> {
  render() {
    const { comp } = this.props;
    const opacity = isUndefined(comp.opacity) ? 1 : comp.opacity / 100;
    if (!opacity) {
      return null;
    }
    const {
      size: { width, height },
      properties,
      type,
      id,
    } = comp;

    const center = {
      x: width / 2,
      y: height / 2,
    };

    const { angle, close } = properties;
    const parser = StyleHelper.initSVGStyleParser(properties);
    const { strokeWidth, strokeDasharray, stroke, strokeLinecap } = parser.getStroke();
    const fill = parser.getFill(`${type}-fill-${id}`);
    const shadow = parser.getShadow();
    const thickness = stroke === 'none' ? 0 : strokeWidth || 0;
    const radius = (Math.min(width, height) - thickness) / 2;
    const range = angle!.value as IRange;
    let value = range.value;
    value = Math.max(0, Math.min(value, 100));
    value = 3.6 * value - 90;
    const rad = (value / 180) * Math.PI;
    const xr = center.x + radius * Math.cos(rad);
    const yr = center.y + radius * Math.sin(rad);
    const largeArcFlag = value < 90 ? 0 : 1;
    let data = '';
    if (value + 90 === 360) {
      data = `M${center.x} ${center.y - radius} A${radius} ${radius} ${radius} 0 1 ${center.x + radius} ${
        center.y
      }  A${radius} ${radius} ${radius} 0 1 ${center.x} ${center.y + radius} A${radius} ${radius} ${radius} 0 1 ${
        center.x - radius
      } ${center.y} A${radius} ${radius} ${radius} 0 1 ${center.x} ${center.y - radius}`;
    } else {
      data = `M${center.x} ${center.y - radius} A${radius} ${radius} ${radius} ${largeArcFlag} 1 ${xr} ${yr}`;
      if (close && close!.value) {
        data = `${data} L${center.x} ${center.y}Z`;
      }
    }

    return (
      <svg
        className="lib-comp-arc"
        style={{
          transition: comp.getTransition(),
          width,
          height,
          left: 0,
          top: 0,
          position: 'absolute',
          filter: shadow,
        }}
        width={width}
        height={height}
      >
        <path
          d={data}
          fill={fill}
          fillOpacity={opacity}
          strokeOpacity={opacity}
          stroke={stroke}
          strokeDasharray={strokeDasharray}
          strokeWidth={strokeWidth ? Math.min(strokeWidth, Math.min(width, height) / 2) : strokeWidth}
          strokeLinecap={strokeLinecap || 'round'}
        />
      </svg>
    );
  }
}
