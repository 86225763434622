import * as React from 'react';
import { renderComponentWrapper } from './renderComponentWrapper';
import { UIComponent } from '@/editor/comps';
export const renderHidden = (comp: UIComponent) => {
  const content = (
    <div className="hidden-layer"></div>
    // <div
    //   className={classnames({
    //     'hidden-layer': MathUtils.variable(this.context.userPreference?.generalSettings.showHiddenArea, true),
    //   })}
    // />
  );

  return renderComponentWrapper({ comp, scale: 1 }, content);
};
