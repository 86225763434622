import i18n from '@i18n';

interface IPredefinedComponentStateName {
  type: string;
  name: string;
}

export enum PredefinedStates {
  normal = 'normal',
  hover = 'hover',
  pressed = 'pressed',
  toggleCheck = 'toggleCheck',
  checked = 'checked',
  unchecked = 'unchecked',
  focus = 'focus',
  blur = 'blur',
  disabled = 'disabled',
}

// 支持状态交互的预置状态
export const allTogglePredefinedStates: Array<string> = [PredefinedStates.disabled, PredefinedStates.checked];

export const disabledState: IPredefinedComponentStateName = {
  type: PredefinedStates.disabled,
  name: i18n('state.disabled'),
};

export const containerPresetState: IPredefinedComponentStateName[] = [
  { type: PredefinedStates.checked, name: i18n('state.checked') },
  disabledState,
  { type: PredefinedStates.hover, name: i18n('state.hover') },
  { type: PredefinedStates.pressed, name: i18n('state.pressed') },
];

export const predefinedComponentStates: IPredefinedComponentStateName[] = [
  { type: PredefinedStates.checked, name: i18n('state.checked') },
  disabledState,
  { type: PredefinedStates.hover, name: i18n('state.hover') },
  { type: PredefinedStates.pressed, name: i18n('state.pressed') },
  { type: PredefinedStates.focus, name: i18n('state.focus') },
];

export function getPredefinedStateName(id: string): string {
  const stateName = predefinedComponentStates.find((item) => item.type === id);
  if (stateName) {
    return stateName.name;
  }
  return '';
}

export function isPredefinedState(id: string): boolean {
  return ([
    PredefinedStates.normal,
    PredefinedStates.hover,
    PredefinedStates.pressed,
    PredefinedStates.checked,
    PredefinedStates.disabled,
    PredefinedStates.focus,
  ] as string[]).includes(id);
}

export function sortPredefinedStates(states: string[]) {
  const tempList: string[] = [];
  predefinedComponentStates.forEach((item) => {
    if (states.includes(item.type)) {
      tempList.push(item.type);
    }
  });
  if (states.indexOf(PredefinedStates.normal) !== -1) {
    tempList.unshift(PredefinedStates.normal);
  }
  states.splice(0, states.length, ...tempList);
}
