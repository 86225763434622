import * as React from 'react';

import { ICompareRefs, IPoint, ITableColumn, ITableData, ITableSortOrder, TableSelection } from '../../../models';

import TableCol from '../TableCol';
import TableHeader from '../TableHeader';
import TableBody from '../TableBody';

import './index.scss';

interface IStickyContentProps {
  theme: 'red' | 'blue';
  tableRef: React.RefObject<HTMLTableElement>;
  columns: ITableColumn[];
  dataSource: ITableData[];
  cellStyle?: React.CSSProperties;
  rowSelectionType?: TableSelection;
  activeMenuRow: ITableData;
  checked: (string | number)[];
  searchKey: string;
  disableSelectionRows: (string | number)[];
  onSort?(dataKey: string, sort: ITableSortOrder): void;
  onFormat?(data: ITableData, column: ITableColumn, ref?: ICompareRefs): React.ReactChild;
  handleClickRow(data: ITableData): void;
  handleContextRow(data: ITableData, mousePoint: IPoint, fromOperateBtn?: boolean): void;
  handleCheckedRow(checked: string[] | number[]): void;
  setTip(text: string, style: React.CSSProperties | null): void;
}

const StickyContent: React.FC<IStickyContentProps> = (props: IStickyContentProps) => {
  const {
    tableRef,
    columns,
    dataSource,
    cellStyle,
    rowSelectionType,
    activeMenuRow,
    checked,
    searchKey,
    theme,
    disableSelectionRows,
    onSort,
    onFormat,
    handleClickRow,
    handleContextRow,
    handleCheckedRow,
    setTip,
  } = props;
  return (
    <div className="c-table-sticky">
      <table ref={tableRef}>
        <TableCol columns={columns} rowSelectionType={rowSelectionType || TableSelection.None} />
        <TableHeader
          columns={columns}
          rowSelectionType={rowSelectionType || TableSelection.None}
          cellStyle={cellStyle || {}}
          onSort={onSort}
        />
      </table>

      <table ref={tableRef}>
        <TableCol columns={columns} rowSelectionType={rowSelectionType || TableSelection.None} />
        <TableBody
          theme={theme}
          dataSource={dataSource}
          columns={columns}
          rowSelectionType={rowSelectionType || TableSelection.None}
          activeMenuRow={activeMenuRow}
          checked={checked}
          cellStyle={cellStyle}
          searchKey={searchKey}
          disableSelectionRows={disableSelectionRows}
          onFormat={onFormat}
          handleClickRow={handleClickRow}
          handleContextRow={handleContextRow}
          setChecked={handleCheckedRow}
          setTip={setTip}
        />
      </table>
    </div>
  );
};

export default StickyContent;
