import apis from '@apis';

class SnapshotManager {
  private allPages: { [pageID: string]: string } = {};

  public async loadAllUsedResource(appID: string) {
    const appPages = await apis.team.getSnapshotAllPages(appID);
    Object.values(appPages).forEach((pages) => {
      pages.forEach(({ pageID, imageURL }) => {
        if (imageURL) {
          this.allPages[pageID] = imageURL;
        }
      });
    });
    return this.allPages;
  }

  public getURLByPageID(pageID?: string): string | undefined {
    if (!pageID) {
      return;
    }
    return this.allPages[pageID];
  }

  public setURLByPageID(pageID: string, imageURL: string) {
    this.allPages[pageID] = imageURL;
  }
}

const snapshotManager = new SnapshotManager();
export default snapshotManager;
