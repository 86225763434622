import * as React from 'react';
import classnames from 'classnames';

import { IProps } from './helper';
import { Icon } from '..';

import './index.scss';

export interface ITreeItemProp<T> {
  props: IProps<T>;
  level: number;
  marked?: boolean;
  indent?: number;
  height?: number;
  nodeId?: string;
  disabled?: boolean;
  isClickFolderOnExpand?: boolean;
  draggable?: boolean;
  render: (data: T, collapse?: boolean) => React.ReactNode;
  onClick?: (e: React.MouseEvent, data: T) => void;
  onDoubleClick?: (e: React.MouseEvent, data: T) => void;
  onItemExpandChanged?: (item: T, expand: boolean) => void;
  onDragStart?: (e: React.DragEvent, data: T) => void;
  onDrop?: (e: React.DragEvent, data: T) => void;
  onDragOver?: (e: React.DragEvent, data: T) => void;
  onDragEnter?: (e: React.DragEvent, data: T) => void;
  onDragEnd?: (e: React.DragEvent, data: T) => void;
  onContextMenu?: (e: React.MouseEvent, data: IProps<T>) => void;
  onMouseEnter?: (e: React.MouseEvent, data: IProps<T>) => void;
  onMouseLeave?: (e: React.MouseEvent, data: IProps<T>) => void;
  onMouseUp?(e: React.MouseEvent, data: IProps<T>): void;
  onMainContentMouseEnter?: (comp: T) => void;
  onMainContentMouseLeave?: (comp: T) => void;
  onCanBeInteractionTarget?(data: IProps<T>): boolean;
}

export interface ITreeContext<T> {
  selectedNodes: TreeItem<T>[];
  allNodes: TreeItem<T>[];
}

export const TreeContext = React.createContext({} as ITreeContext<any>);

export interface ITreeItemState {
  expand: boolean | undefined;
}

class TreeItem<T> extends React.Component<ITreeItemProp<T>, ITreeItemState> {
  static contextType = TreeContext;
  selfRef: React.RefObject<HTMLDivElement>;

  constructor(props: ITreeItemProp<T>) {
    super(props);
    this.state = {
      expand: props.props.expand,
    };
    this.selfRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.props.selected) {
      this.context?.selectedNodes.push(this);
    }
    this.context?.allNodes.push(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: ITreeItemProp<T>) {
    // 因为新的 hover 实现，hover 时会将 expand 设置为 true。
    // 内部展开状态和 item.expand 保持同步或只维护一个内部 state 即可。
    if (nextProps.props.expand !== this.state.expand || nextProps.props.expand !== this.props.props.expand) {
      this.setState({ expand: nextProps.props.expand }, () => {
        if (this.props.onItemExpandChanged) {
          this.props.onItemExpandChanged(this.props.props.data, !!this.state.expand);
        }
      });
    }

    if (nextProps.props.selected) {
      this.context?.selectedNodes.push(this);
    }
    this.context?.allNodes.push(this);
  }

  get clientBounds() {
    return this.selfRef.current?.getBoundingClientRect();
  }

  get data(): T {
    return this.props.props.data;
  }

  get expandItemCount(): number {
    const { props } = this.props;
    let count = 0;
    const doCalc = (nodes: IProps<T>[]) => {
      count += nodes.length;
      nodes.forEach((n) => {
        if (n.children) {
          doCalc(n.children);
        }
      });
    };
    if (props.children) {
      doCalc(props.children);
    }
    return count;
  }

  onClick = (e: React.MouseEvent) => {
    const { props, onClick } = this.props;
    onClick && onClick(e, props.data);
  };

  onDoubleClick = (e: React.MouseEvent) => {
    const {
      props: { data },
      onDoubleClick,
    } = this.props;
    onDoubleClick && onDoubleClick(e, data);
  };

  onDragStart = (e: React.DragEvent) => {
    e.stopPropagation();
    const { props, onDragStart } = this.props;
    onDragStart && onDragStart(e, props.data);
  };

  onDragOver = (e: React.DragEvent) => {
    e.stopPropagation();
    const { props, onDragOver } = this.props;
    onDragOver && onDragOver(e, props.data);
  };

  onDragEnter = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const { props, onDragEnter } = this.props;
    onDragEnter && onDragEnter(e, props.data);
  };

  onDragEnd = (e: React.DragEvent) => {
    e.stopPropagation();
    const { props, onDragEnd } = this.props;
    onDragEnd && onDragEnd(e, props.data);
  };

  onDrop = (e: React.DragEvent) => {
    e.stopPropagation();
    const { props, onDrop } = this.props;
    onDrop && onDrop(e, props.data);
  };

  private isContainerNode(node: IProps<T>) {
    let parent = node.parent;
    const { props } = this.props;
    while (parent) {
      if (parent === props) {
        return true;
      }
      parent = parent.parent;
    }
    return false;
  }

  private removeCollapseNodesFromSelected() {
    if (this.context?.selectedNodes) {
      this.context.selectedNodes.reverse().forEach((item: any, i: number) => {
        if (this.isContainerNode(item.props)) {
          this.context.selectedNodes.splice(i, 1);
        }
      });
    }
  }

  onExpand = () => {
    if (this.state.expand) {
      this.removeCollapseNodesFromSelected();
    }
    this.setState({ expand: !this.state.expand }, () => {
      if (this.props.onItemExpandChanged) {
        this.props.onItemExpandChanged(this.props.props.data, !!this.state.expand);
      }
    });
  };

  handleMouseEnter = (e: React.MouseEvent) => {
    const { onMouseEnter, props } = this.props;
    onMouseEnter && onMouseEnter(e, props);
  };

  handleMouseLeave = (e: React.MouseEvent) => {
    const { onMouseLeave, props } = this.props;
    onMouseLeave && onMouseLeave(e, props);
  };

  handleMouseUp = (e: React.MouseEvent) => {
    const { onMouseUp, props } = this.props;
    onMouseUp && onMouseUp(e, props);
  };

  onContextMenu = (e: React.MouseEvent) => {
    const { props, onContextMenu } = this.props;
    onContextMenu && onContextMenu(e, props);
  };

  renderChildren() {
    const {
      props,
      render,
      draggable,
      onClick,
      onDragStart,
      onDrop,
      onDragOver,
      onDragEnd,
      onDoubleClick,
      onContextMenu,
      level,
      indent,
      onItemExpandChanged,
      onMouseEnter,
      onMouseLeave,
      onMouseUp,
      height,
      onCanBeInteractionTarget,
      onMainContentMouseEnter,
      onMainContentMouseLeave,
      isClickFolderOnExpand,
    } = this.props;
    const { children } = props;
    const { expand } = this.state;
    if (children && children.length && expand) {
      const newLevel = (level || 0) + 1;
      return (
        <div className="children-content">
          {children.map((item: any, i) => {
            return (
              <TreeItem
                level={newLevel}
                isClickFolderOnExpand={isClickFolderOnExpand}
                marked={item.marked}
                indent={indent}
                key={`${i}`}
                props={item}
                height={height}
                render={render}
                draggable={draggable ? draggable : item.data.type !== 'connector'}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                onItemExpandChanged={onItemExpandChanged}
                onDrop={onDrop}
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDragEnd={onDragEnd}
                onContextMenu={onContextMenu}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseUp={onMouseUp}
                onCanBeInteractionTarget={onCanBeInteractionTarget}
                onMainContentMouseEnter={onMainContentMouseEnter}
                onMainContentMouseLeave={onMainContentMouseLeave}
              />
            );
          })}
        </div>
      );
    }
  }

  handleMainContentMouseEnter = () => {
    const { props, onMainContentMouseEnter } = this.props;
    onMainContentMouseEnter && onMainContentMouseEnter(props.data);
  };

  handleMainContentMouseLeave = () => {
    const { props, onMainContentMouseLeave } = this.props;
    onMainContentMouseLeave && onMainContentMouseLeave(props.data);
  };

  renderSelf() {
    const { render, props, draggable, level, marked, indent, height } = this.props;
    const { expand } = this.state;
    const paddingLeft = (indent || 0) + level * 16;
    const style: React.CSSProperties = {
      paddingLeft: `${paddingLeft}px`,
      minWidth: `calc(100% - ${paddingLeft + 4}px)`, // 加上边框的高度
    };
    if (height) {
      style.height = height;
      style.lineHeight = `${height}px`;
    }
    return (
      <div
        className={classnames('main-content', { selected: props.selected, marked })}
        style={style}
        onContextMenu={this.onContextMenu}
        ref={this.selfRef}
        onDragEnter={this.onDragEnter}
        onMouseEnter={this.handleMainContentMouseEnter}
        onMouseLeave={this.handleMainContentMouseLeave}
      >
        <div className={classnames('button', { leaf: props.isLeaf, editing: props.editing })} onClick={this.onExpand}>
          <Icon
            className={classnames('arrow', { expand: expand, collapse: !expand })}
            size={16}
            theme="tag"
            cls="tag_downarrow"
          />
        </div>
        <div
          className="title"
          onClick={this.onClick}
          onDoubleClick={this.onDoubleClick}
          draggable={draggable}
          onDrop={this.onDrop}
          onDragStart={this.onDragStart}
          onDragOver={this.onDragOver}
          onDragEnd={this.onDragEnd}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onMouseUp={this.handleMouseUp}
        >
          {render(props.data, !expand)}
        </div>
      </div>
    );
  }

  private handleOpenFolder = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (this.props.isClickFolderOnExpand) {
      this.onExpand();
    }
  };

  render() {
    const { onCanBeInteractionTarget, props, draggable } = this.props;
    return (
      <div
        className={classnames('tree-item', {
          'can-be-interaction-target-node': onCanBeInteractionTarget && onCanBeInteractionTarget(props),
        })}
        draggable={draggable}
        onDragStart={this.onDragStart}
        onDragOver={this.onDragOver}
        onDragEnd={this.onDragEnd}
        onClick={this.handleOpenFolder}
      >
        {this.renderSelf()}
        {this.renderChildren()}
      </div>
    );
  }
}

export default TreeItem;
