import { IPosition } from '@/fbs/common/models/common';
import { Color } from '@/fbs/rp/models/properties/color';
import { IComponentData, IExportItemData, NamingScheme } from '@/fbs/rp/models/component';
import { IAppWithNestedChildren } from '@/fbs/rp/models/app';
import IFill from '@/fbs/rp/models/properties/fill';
import { MobileType } from '@/fbs/rp/utils/preview';
import Doc from '@/editor/document';
import { UIArtboard, UIComponent, UIFragment } from '@/editor/comps';
import { Matrix } from '@/utils/matrixUtils';
import CoreEditor from '@/editor/core';
import { ISvgValue } from '../exportSvgHelper';
import { IExportFile } from './export/packImages';

export type Filter = (node: Element) => boolean;

export type Replacer = (node: HTMLElement, original: Element) => Node;
export interface ICopyStyleRulerOptions {
  node: Element;
  isDiectTextNode?: boolean;
  source: CSSStyleDeclaration;
  target: CSSStyleDeclaration;
  isRoot?: boolean;
}

export interface ICopyStyleRuler {
  (options: ICopyStyleRulerOptions): void;
}

export interface IToImageOptions {
  filter: Filter;
  replacer: Replacer;
  copyStyleRuler: ICopyStyleRuler;
  bgcolor?: string | undefined;
  width?: number | undefined;
  height?: number | undefined;
}

export interface ICloneNodeOptions {
  node: Element;
  isRoot?: boolean;
  filter?: Filter;
  replacer?: Replacer;
  copyStyleRuler?: ICopyStyleRuler;
}

export interface ICloneChildrenOptions {
  node: Element;
  clone: Node;
  filter?: Filter;
  replacer?: Replacer;
  copyStyleRuler?: ICopyStyleRuler;
}

export interface ISize {
  width: number;
  height: number;
}

export interface ICopyStyleRuleOptions {
  name: string;
  value?: string;
  node?: HTMLElement;
  isDiectTextNode?: boolean;
  source: CSSStyleDeclaration;
  target?: CSSStyleDeclaration;
}

// 元素节点，属性将返回 1。
// 属性节点，属性将返回 2。
// 文本节点，属性将返回 3。
// 注释节点，属性将返回 8。
// export enum NodeType {
//   text='3'

// }

export enum PropValue {
  normal = 'normal',
  auto = 'auto',
  hidden = 'hidden',
  none = 'none',
  scroll = 'scroll',
}

export interface IHtmlContent {
  content: string;
  style?: string;
  webfont?: string;
}

export interface IExportData {
  html: IHtmlContent | undefined; //string;
  size: ISize;
  id?: string;
  type: string;
  name?: string;

  url?: string;
  blob?: Blob;

  position?: IPosition;
  rotate?: number;
  innerBounds?: IBounds;
  outerBounds?: IBounds;
  offset?: IPosition;

  extension?: string;
  filename?: string;
  style?: IStyle;
  pathData?: string;
  svgValue?: ISvgValue;

  text?: string;
  textStyle?: ITextStyle;
  matrix?: Matrix;
  isRoot?: boolean;
  zIndex?: number;
}

export interface IPlainComponentData extends IComponentData {
  isRoot?: boolean;
  refData?: IComponentData;
  matrix?: Matrix;
}

export interface IOffsetBounds {
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
}
export interface IStyle {
  // 模糊
  shadow?: string;
  // 颜色过滤
  colorFilter?: string;

  filter?: string;
  boxShadow?: string;
  opacity?: number;

  borderWidth?: number;
  borderColor?: string;
  borderRadius?: number;
  borderTopLeftRadius?: number;
  borderTopRightRadius?: number;
  borderBottomLeftRadius?: number;
  borderBottomRightRadius?: number;

  background?: string;
  fill?: IFill;
}

export interface IBounds {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface IBorderRaidus {
  borderTopLeftRadius?: number;
  borderTopRightRadius?: number;
  borderBottomLeftRadius?: number;
  borderBottomRightRadius?: number;
}

export interface ITextStyle {
  [x: string]: string | number | undefined | Color;
}

interface IMakeSvgOptions {
  size?: ISize;
  isRoot?: boolean;
  position?: IPosition;
  rotate?: number;
  outerBounds?: IBounds;
  offset?: IPosition;
}
export interface IMakeSvgTemplateOptions extends IMakeSvgOptions {
  id?: string;
  type?: string;
  patternUri?: string;
  pathData?: string;
  style?: IStyle;
  svgValue?: ISvgValue;
  html?: string;
  text?: string;
  textStyle?: ITextStyle;
  innerBounds?: IBounds;
  matrix?: Matrix;
}

export interface IMakeSvgByBlobOptions {
  id: string;
  blob: Blob;
  size: ISize;
  style?: IStyle;
}

export interface IMakeSvgByPathOptions {
  id: string;
  pathData: string;
  size: ISize;
  style?: IStyle;
}

export interface IRectOptions {
  type?: string;
  rx: string;
  style: string;
  stroke: string;
  strokeWidth: number;
  fillId: string;
  text?: string;
  textStyle?: ITextStyle;
  isRoot?: boolean;
  position?: IPosition;
  rotate?: number;
  size?: ISize;
  outerBounds?: IBounds;
  matrix?: Matrix;
}

export interface IPathOptions {
  stroke: string;
  strokeWidth: number;
  fill?: string;
  style?: string;
  isRoot?: boolean;
  position?: IPosition;
  rotate?: number;
  size?: ISize;
  outerBounds?: IBounds;
  offset?: IPosition;
  matrix?: Matrix;
}

export interface IMakeTransformOptions {
  position?: IPosition;
  rotate?: number;
  isRoot?: boolean;
  size?: ISize;
  outerBounds?: IBounds;
  offset?: IPosition;
}

export interface ITextOptions {
  fontFamily?: string;
  fontSize?: number;
  color?: Color;
  size?: ISize;
}

export interface IMakeSvgByHtmlOptions extends IMakeSvgOptions {
  html: IHtmlContent; // string;
}

export interface IMakeSvgByElementOptions extends IMakeSvgOptions {
  node: Element;
  webfont?: string;
  style?: string;
}

export interface IMakeSvgContentOptions {
  content: string;
  size: ISize;
  rotate?: number;
  scale?: number;
}

export interface IMakePatternOptions {
  uri: string | undefined;
  id: string;
  size: ISize;
  filter: string;
}

export interface IComponentDataFilter {
  (t: IComponentData): boolean;
}

export interface IParseGroupOptions {
  t: IComponentData;
  isRoot: boolean;
  refData?: IComponentData;
  doc?: Doc;
  filter?: IComponentDataFilter;
  matrix?: Matrix;
}

// 渲染成空白过滤器
export interface IRenderBlankFilter {
  (comp: UIComponent): boolean;
}

/**
 * 导出参数
 */
interface IExportOptions {
  app?: IAppWithNestedChildren;
  doc?: Doc;
  // 导出格式，可多选倍率
  exportFormats?: IExportItemData[];
  renderBlankFilter?: IRenderBlankFilter;
  // 手机壳
  mobileType?: MobileType;
  exportMainArtboardsOnly?: boolean;
  onFinished?: (files: IExportFile[]) => void; //exportedCount: number
  onProgress?: (progress: number, e?: string[]) => void;
}

/**
 * 导出页面参数
 */
export interface IExportPageOptions extends IExportOptions {
  coreEditor?: CoreEditor;
  checkedPages?: string[];
}

enum ExportType {
  SinglePage,
  MultiPages,
  SelectedComponents,
}

/**
 * 导出组件参数
 */
export interface IExportComponentsOptions extends IExportOptions {
  components: (UIArtboard | UIFragment | UIComponent)[];
  // 导出全部页，压缩包名字用项目名
  isOutputAllPages?: boolean;
  exportType?: ExportType;
  // 画板顺序
  ids?: string[];
}

export interface IExportSvgOptions extends IExportOptions {
  components: (UIArtboard | UIFragment | UIComponent)[];
  scale: number;
}

export interface IExportArboardOptions extends IExportOptions {
  fragments: (UIArtboard | UIFragment)[];
  coreEditor?: CoreEditor;
}

export enum OuputType {
  File = 'file',
  Blob = 'blob',
}

export interface ISaveFileOptions extends IExportOptions {
  components: (UIArtboard | UIFragment | UIComponent)[];
  coreEditor?: CoreEditor;
  // 导出全部页，压缩包名字用项目名
  isOutputAllPages?: boolean;
  ids?: string[];
  ouputType?: OuputType; //默认保存为文件格式 file
}

export interface IParseExportDataOptions {
  components: (UIArtboard | UIFragment | UIComponent)[];
  doc?: Doc;
  app?: IAppWithNestedChildren;
  renderBlankFilter?: IRenderBlankFilter;
  // 手机壳
  mobileType?: MobileType;
}

export interface IAddZipFileOptions {
  t: IComponentData;
  index: number;
  scale: number;
  svg: string;
  size?: ISize;
  id?: string;
}
export interface IParseComponentProcessorOptions {
  total: number;
  onSuccess: (options: IAddZipFileOptions) => Promise<void>;
  scales: number[];
  doc?: Doc;
  app?: IAppWithNestedChildren;
  renderBlankFilter?: IRenderBlankFilter;
  // 手机壳
  mobileType?: MobileType;
}

export interface IParseComponentOptions {
  t: UIComponent;
  isRoot: boolean;
  refData?: UIComponent;
  doc?: Doc;
  zIndex?: number;
  renderBlankFilter?: IRenderBlankFilter;
}

export interface IMakeZipItemFilenameOptions {
  t: IComponentData;
  index?: number;
  app?: IAppWithNestedChildren;
  scale?: number;
  namingScheme?: NamingScheme;
  isOutputAllPages?: boolean;
}

export interface IMakeSvgByGroup {
  data: IExportData[];
  size: ISize;
  rotate?: number;
  scales: number[];
}

export interface ICompInfo {
  name?: string | null;
  type?: String;
  pageName?: string;
}
