import * as React from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';

import { transBlankChart } from '@utils/textUtils';
import { min } from '@utils/globalUtils';
import { parseColorToString } from '@utils/graphicsUtils';

import { IconValue, IPathValue } from '@fbs/rp/models/value';

import { UIContainerComponent } from '@editor/comps';
import { StyleHelper } from '@helpers/styleHelper';
import { scalePath, transformPathDataToPath } from '@helpers/pathHelper';
import { hasInteraction } from '@helpers/interactionHelper';
import { DefaultIconColor } from '@consts/colors';
import { calcColumnMaxWidth, mergeProperties } from '@/helpers/propertiesHelper';
import { PropertyStructureAlign } from '@/libs/enum';
import { IListCompositeProps } from '../../types';
import { getListEvents } from '../helps';

import './index.scss';
import '../general.scss';

function parserItemStyle(container: UIContainerComponent) {
  const box = (container.getComponentByAlias('iconWrapper') || container.components[0]) as UIContainerComponent;
  const text = container.getComponentByAlias('pureText') || container.components[1];
  const icon = box.getComponentByAlias('icon') || box.components[0];
  const { size: boxSize, properties: boxProperties } = box;
  const { type: iconType, size: iconSize, position: iconPosition, properties: iconProperties, value } = icon;
  const { size: textSize, position: textPosition, properties: textProperties, value: label } = text;
  const boxParser = StyleHelper.createCSSStyleParser(boxProperties);
  const textParser = StyleHelper.createCSSStyleParser(textProperties);
  const svgParser = StyleHelper.initSVGStyleParser(iconProperties);
  const boxStyle = {
    ...boxSize,
    ...boxParser.getRadiusStyle(boxSize),
    ...boxParser.getStrokeStyle(),
    ...boxParser.getRadiusStyle(boxSize),
    ...boxParser.getFillStyle(boxSize),
    opacity: StyleHelper.getOpacity(box.opacity),
  };
  const iconStroke = iconType === 'path' ? svgParser.getStroke() : undefined;
  const iconStyle: React.CSSProperties = {
    left: iconPosition.x,
    top: iconPosition.y,
    ...iconSize,
    opacity: StyleHelper.getOpacity(icon.opacity),
  };
  const { size: txSize, style } = textParser.getTextStyleData(textSize, textProperties.textStyle);
  const textStyle = {
    left: textPosition.x,
    ...txSize,
    ...style,
    opacity: StyleHelper.getOpacity(text.opacity),
  };
  let iconData = iconType === 'path' ? transformPathDataToPath(scalePath(value as IPathValue, { x: 1, y: 1 })) : '';
  if (iconType === 'icon') {
    iconData = String.fromCharCode((value as IconValue).iconCode);
    iconStyle.fontFamily = (value as IconValue).fontName;
    iconStyle.color = parseColorToString(iconProperties.icon?.color || DefaultIconColor);
    iconStyle.fontSize = min(iconSize.width, iconSize.height);
    iconStyle.lineHeight = `${iconSize.height}px`;
    if (iconSize.height < 12) {
      iconStyle.transformOrigin = '0 0';
      iconStyle.transform = `scale(${iconSize.height / 12}) ${iconStyle.transform || ''}`;
    }
  }
  return {
    boxStyle,
    iconStyle,
    textStyle,
    iconData,
    text: transBlankChart(label as string),
    iconStroke,
  };
}

const CheckBoxGroup: React.FC<IListCompositeProps> = (props: IListCompositeProps) => {
  const { comp, isPreview, itemEvents, showInteract } = props;
  const container = comp as UIContainerComponent;
  const { opacity, components } = container;
  const containerProperties = container.toJSON().properties;

  const parserStyle = _.memoize(parserItemStyle);

  const events = isPreview ? _.memoize(getListEvents)(components, itemEvents) : {};

  const count = containerProperties.cell?.columnCount ?? 1;
  const columnMaxWidth = calcColumnMaxWidth(count, components);

  return (
    <div
      className={classnames('lib-comp-checkbox-group', { preview: isPreview })}
      style={{ opacity: StyleHelper.getOpacity(opacity) }}
    >
      {components.map((comp, i) => {
        if (!comp) {
          console.log('null comp', comp);
          return;
        }
        const { size: itemSize, position: itemPosition, selected, id } = comp;
        const itemBounds = {
          left: itemPosition.x,
          top: itemPosition.y,
          ...itemSize,
          width: columnMaxWidth[i % count],
        };
        const { iconStyle, iconStroke, iconData, textStyle, text, boxStyle } = parserStyle(
          comp as UIContainerComponent,
        );

        const event = events[id];
        const _hasInteraction = hasInteraction(comp);
        const needShowInteraction = !isPreview && _hasInteraction && false !== showInteract;
        const containerMergeProperties = mergeProperties(container.properties, containerProperties);
        if (containerMergeProperties.structure?.value === PropertyStructureAlign.TextLeft) {
          boxStyle.left = undefined;
          boxStyle.right = 0;
          textStyle.left = 0;
          textStyle.right = undefined;
        }
        return (
          <div
            key={id}
            className={classnames('lib-comp-checkbox-item', {
              'item-interaction-flag': needShowInteraction,
              'item-hot-area': isPreview && _hasInteraction,
              'component-cursor-pointer': isPreview,
            })}
            style={itemBounds}
            {...(event || {})}
          >
            <div className="lib-comp-checkbox-item-box" style={boxStyle}>
              {selected && iconStroke && (
                <svg className="lib-comp-checkbox-item-icon" style={iconStyle}>
                  <path d={iconData} {...iconStroke} fill="none" />
                </svg>
              )}
              {selected && !iconStroke && (
                <label className="lib-comp-checkbox-item-icon" style={iconStyle}>
                  {iconData}
                </label>
              )}
            </div>
            <label className="lib-comp-checkbox-item-value" style={textStyle}>
              {text}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default CheckBoxGroup;
