import * as ArrowHollowIcon from '@assets/image/endpoint_arrow_hollow_thin.png';
import * as ArrowSolidIcon from '@assets/image/endpoint_arrow_solid_thin.png';
import * as CubeHollowIcon from '@assets/image/endpoint_cube_hollow_thin.png';
import * as CubeSolidIcon from '@assets/image/endpoint_cube_solid_thin.png';
import * as DiamondHollowIcon from '@assets/image/endpoint_diamond_hollow_thin.png';
import * as DiamondSolidIcon from '@assets/image/endpoint_diamond_solid_thin.png';
import * as DotHollowIcon from '@assets/image/endpoint_dot_hollow_thin.png';
import * as DotSolidIcon from '@assets/image/endpoint_dot_solid_thin.png';
import * as TriangleHollowIcon from '@assets/image/endpoint_triangle_hollow_thin.png';
import * as TriangleSolidIcon from '@assets/image/endpoint_triangle_solid_thin.png';
import * as PerpendicularIcon from '@assets/image/endpoint_perpendicular_thin.png';

import * as DefaultImageIcon from '@assets/image/defaultImage.png';
import * as PlaceHolderImageIcon from '@assets/image/placeholderImage.png';
import * as EmptyProject from '@assets/image/emptyProject.png';
import * as ResourceLoadFail from '@assets/image/resource_load_fail.png';
import * as LoadingImage from '@assets/image/loading_v2.gif';
import * as FramePlaceholderImage from '@assets/image/Frame.png';

export const ImageIcons = {
  ArrowHollowIcon,
  ArrowSolidIcon,
  CubeHollowIcon,
  CubeSolidIcon,
  DiamondHollowIcon,
  DiamondSolidIcon,
  DotHollowIcon,
  DotSolidIcon,
  TriangleHollowIcon,
  TriangleSolidIcon,
  PerpendicularIcon,
  PlaceHolderImageIcon,
  DefaultImageIcon,
};

export const OtherRes = {
  EmptyProject,
  ResourceLoadFail,
  LoadingImage,
  FramePlaceholderImage,
};
