import { Dispatch } from 'redux';
import { batch } from 'react-redux';
import { uniq, uniqWith } from 'lodash';

import { IMemberInfo } from '@fbs/idoc/models/member';
import { IAppMembersInfo } from '@fbs/idoc/models/app';

import i18n from '@i18n';

import * as memberApi from '@apis/member';

import { ActionsUnion, ActionType, createAction, ToastType } from '../types';
import { AppThunkActions, GlobalThunkActions, Global } from '../actions';

export const Actions = {
  loadAllMembers: (data: IMemberInfo[]) => createAction(ActionType.Member_Load_All, data),
  loadAppMembers: (members: IAppMembersInfo[], parents: IAppMembersInfo[], groups: number[], parentGroups: number[]) =>
    createAction(ActionType.Member_Load_App_Members, {
      members,
      parents,
      groups,
      parentGroups,
    }),
  updateAppMembers: (data: IAppMembersInfo[]) => createAction(ActionType.Member_Update_App_Members, data),
  updateAppGroups: (data: number[]) => createAction(ActionType.Member_Update_App_Groups, data),
  loadInvitation: (data: { invitationID: string; role: string }) => createAction(ActionType.Invitation_Load_Link, data),
  patchInvitation: (data: { invitationID?: string; role?: string }) =>
    createAction(ActionType.Invitation_Patch_Link, data),
  changeInvitationGroup: (groupID: number) => createAction(ActionType.Invitation_Patch_Group, groupID),
};

export const ThunkActions = {
  loadAllMembers: (teamID: string) => (dispatch: Dispatch) => {
    dispatch(Global.waiting(true));
    memberApi
      .getTeamMember(teamID)
      .then((res) => {
        dispatch(Actions.loadAllMembers(res));
        dispatch(Global.waiting(false));
      })
      .catch((e) => {
        GlobalThunkActions.thunkErrorMessage(e)(dispatch);
        dispatch(Global.waiting(false));
      });
  },
  loadAppMembers: (appID: string) => (dispatch: Dispatch) => {
    dispatch(Global.waiting(true));
    memberApi
      .getAppMember(appID)
      .then(({ appMembers, parentMembers, groups, parentGroups }) => {
        dispatch(Global.waiting(false));
        dispatch(
          Actions.loadAppMembers(
            uniqWith(appMembers.concat(parentMembers), (a, b) => a.id === b.id),
            parentMembers,
            uniq(groups?.concat(parentGroups) || parentGroups),
            parentGroups,
          ),
        );
      })
      .catch((e) => {
        GlobalThunkActions.thunkErrorMessage(e)(dispatch);
        dispatch(Global.waiting(false));
      });
  },
  updateAppAuthorize: (appID: string, members: number[], groupIDs: number[]) => (dispatch: Dispatch) => {
    Promise.all([memberApi.updateAppMembers(appID, members), memberApi.updateAppGroups(appID, groupIDs)])
      .then(([membersInfo, groupsInfo]) => {
        batch(() => {
          dispatch(Actions.updateAppMembers(membersInfo));
          dispatch(Actions.updateAppGroups(groupsInfo.groupIDs));
        });
      })
      .catch((e) => GlobalThunkActions.thunkErrorMessage(e)(dispatch));
  },
  changeAppType: (appID: string, visibility: 'private' | 'internal') => (dispatch: Dispatch) => {
    memberApi
      .modifyAppCompetence(appID, visibility)
      .then(() => {
        AppThunkActions.patchAppInfo({ visibility })(dispatch);
      })
      .catch((e) => GlobalThunkActions.thunkErrorMessage(e)(dispatch));
  },
  loadInvitation: (teamID: string, appID: string, role: string, timeLimit?: string) => async (dispatch: Dispatch) => {
    try {
      const res = await memberApi.getAppInvitationLink(teamID, appID, role, timeLimit);
      dispatch(Actions.loadInvitation(res));
      return res;
    } catch (err) {
      GlobalThunkActions.thunkErrorMessage(err)(dispatch);
      throw new Error(err);
    }
  },
  changeInvitationTerm: (invitationID: string, timeLimit: string) => (dispatch: Dispatch) => {
    memberApi
      .changeInvitationLink(invitationID, { timeLimit })
      .catch((e) => GlobalThunkActions.thunkErrorMessage(e)(dispatch));
  },
  changeInvitationRole: (invitationID: string, role: string) => (dispatch: Dispatch) => {
    memberApi
      .changeInvitationLink(invitationID, { role })
      .then(() => {
        dispatch(Actions.patchInvitation({ role }));
      })
      .catch((e) => GlobalThunkActions.thunkErrorMessage(e)(dispatch));
  },
  changeInvitationGroup: (invitationID: string, groupID: number) => (dispatch: Dispatch) => {
    memberApi
      .changeInvitationLink(invitationID, { groupID })
      .then(() => {
        dispatch(Actions.changeInvitationGroup(groupID));
      })
      .catch((e) => GlobalThunkActions.thunkErrorMessage(e)(dispatch));
  },
  resetInvitationLink: (option: { teamID: string; appID: string; role: string; timeLimit?: string }) => (
    dispatch: Dispatch,
  ) => {
    const { teamID, appID, role, timeLimit } = option;
    memberApi
      .resetInvitationLink(teamID)
      .then(() => {
        GlobalThunkActions.showToast(ToastType.Success, i18n('preview.resetLinkSuccess'))(dispatch);
        ThunkActions.loadInvitation(teamID, appID, role, timeLimit)(dispatch);
      })
      .catch((e) => GlobalThunkActions.thunkErrorMessage(e)(dispatch));
  },
};

export type MemberAction = ActionsUnion<typeof Actions>;
