import { AppTypes } from '../../idoc/models/app';

// 返回 team类型
export interface IEmbedTeam {
  id: string;
  name: string;
}

// 返回 app类型
export interface IEmbedApp {
  _id: string;
  name: string;
  // 是项目还是项目集 App为项目 AppSet为项目集即文件夹
  type: AppTypes.App | AppTypes.AppSet;
  children?: IEmbedApp[];
}

// 返回 页面类型
export interface IEmbedPage {
  id: string;
  // 为 true 表示文件夹，false 为普通文件，普通文件和文件夹可任意嵌套
  isGroup: boolean;
  name: string;
  imageURL?: string;
  isDeleted?: string;
  size?: {
    width: number;
    height: number;
  };
  children?: IEmbedPage[];
}

//模块类型
export enum ModuleType {
  Design = 'design', //设计稿
}

// 模块类型数组，用于遍历
export const ModuleTypeArray = ['design'];

// 返回页面列表全集, id 类型为 ModuleType
export interface IAppPages {
  [id: string]: IAppPage[];
}

// 返回页面列表
export interface IAppPage {
  _id: string;
  moduleType: ModuleType;
  pageID: string;
  imageURL?: string;
  size?: {
    width: number;
    height: number;
  };
}
