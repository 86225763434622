import * as React from 'react';
import * as tinycolor from 'tinycolor2';

import { dragDelegate } from '@utils/mouseUtils';
import { parseColorToString } from '@utils/graphicsUtils';

import './index.scss';

export interface IAlphaProp {
  color: tinycolor.ColorFormats.RGB;
  alpha: number;
  disabled?: boolean;
  onChange: (alpha: number, isChanging?: boolean) => void;
}

export interface IAlphaState {
  alpha: number;
}

class Alpha extends React.Component<IAlphaProp, IAlphaState> {
  static defaultProps: Partial<IAlphaProp> = {};

  selfRef: React.RefObject<HTMLDivElement>;

  constructor(props: IAlphaProp) {
    super(props);
    this.state = {
      alpha: props.alpha,
    };
    this.selfRef = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(newProps: IAlphaProp) {
    if (newProps.alpha !== this.state.alpha) {
      this.setState({ alpha: newProps.alpha });
    }
  }

  onPointMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    const dom = e.target as HTMLElement;
    const parent = dom.parentElement;
    if (parent) {
      const w = parent.offsetWidth;
      const l = dom.offsetLeft;
      const { onChange } = this.props;
      const newLeft = (Math.max(0, Math.min(w, l)) * 100) / w;
      this.setState({ alpha: newLeft }, () => {
        onChange && onChange(newLeft, true);
      });
      dragDelegate(
        (e: MouseEvent, delta: { x: number; y: number }) => {
          e.preventDefault();
          const newLeft = (Math.max(0, Math.min(w, l + delta.x)) * 100) / w;
          this.setState({ alpha: newLeft }, () => {
            onChange && onChange(newLeft, true);
          });
        },
        (e: MouseEvent, delta: { x: number; y: number }) => {
          const newLeft = (Math.max(0, Math.min(w, l + delta.x)) * 100) / w;
          this.setState({ alpha: newLeft }, () => {
            onChange && onChange(newLeft);
          });
        },
      );
    }
  };

  onAlphaMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { onChange } = this.props;
    const { offsetX } = e.nativeEvent;
    const w = (e.target as HTMLElement).offsetWidth;
    const alpha = (offsetX * 100) / w;
    this.setState({ alpha }, () => {
      onChange && onChange(alpha, true);
    });
    dragDelegate(
      (e: MouseEvent, delta: { x: number; y: number }) => {
        e.preventDefault();
        const newLeft = (Math.max(0, Math.min(w, offsetX + delta.x)) * 100) / w;
        this.setState({ alpha: newLeft }, () => {
          onChange && onChange(newLeft, true);
        });
      },
      (e: MouseEvent, delta: { x: number; y: number }) => {
        const newLeft = (Math.max(0, Math.min(w, offsetX + delta.x)) * 100) / w;
        this.setState({ alpha: newLeft }, () => {
          onChange && onChange(newLeft);
        });
      },
    );
  };

  render() {
    const { color, disabled } = this.props;
    const { alpha } = this.state;
    const style: React.CSSProperties = {};
    const c = `rgba(${color.r},${color.g},${color.b})`;
    const t = `rgba(${color.r},${color.g},${color.b}, 0)`;
    style.background = `linear-gradient(to left,${c}, ${t})`;
    return (
      <div className="dsm-c-color-alpha" onMouseDown={disabled ? undefined : this.onAlphaMouseDown}>
        <div className="bg" />
        <div style={style} className="color" />
        <div
          style={{ left: `${alpha}%`, background: parseColorToString({ ...color, a: alpha / 100 }) }}
          className="point"
          onMouseDown={disabled ? undefined : this.onPointMouseDown}
        />
      </div>
    );
  }
}

export default Alpha;
