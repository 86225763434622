import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

export interface ICustomLinkProps {
  href?: string;
  className?: string;
  disabled?: boolean;

  onClick?(e: React.MouseEvent<HTMLElement>): void;

  theme?: 'basic' | 'red' | 'dark';
  target?: string;
  children?: any;
}

const CustomLink: React.FC<ICustomLinkProps> = (props: ICustomLinkProps) => {
  const { href, className, onClick, theme, target, children, disabled } = props;
  const cls = classnames('dsm-c-rp-link', className, {
    'dsm-c-rp-link-basic': theme === 'basic',
    'dsm-c-rp-link-red': theme === 'red',
    'dsm-c-rp-link-dark': theme === 'dark',
    'link-disabled': disabled,
  });
  return href ? (
    <a className={cls} href={href} target={target}>
      {children}
    </a>
  ) : (
    <span className={cls} onClick={onClick}>
      {children}
    </span>
  );
};

CustomLink.defaultProps = {
  theme: 'red',
  href: '',
  target: '_self',
};

export default CustomLink;
