import { IComponentData } from '@fbs/rp/models/component';
import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { ISize } from '@fbs/common/models/common';
import { scalePath } from '@helpers/pathHelper';
import { IPathValue } from '@fbs/rp/models/value';
import { UIContainerComponent } from '@editor/comps';
import ValueEditorType from '@consts/enums/valueEditorType';
import { getDefaultDataFromTemplate } from '@/libs/helper';

import { IComponentItem } from '../../types';
import { SizeMode } from '../../enum';

export const HotAreaCfg: IComponentItem = {
  type: 'hot-area',
  name: `${i18n('resource.components.hotArea')}`,
  predefinedStates: [],
  sizeMode: SizeMode.custom,
  isTextComp: false,
  shortCut: 'H',
  thumb: {
    spriteIconClass: SpriteThumb.Hotspot.className,
    dragPosition: SpriteThumb.Hotspot.position,
  },
  preview: {
    init: (data: IComponentData) => {
      const { fill, stroke } = data.properties;
      if (fill) {
        fill.disabled = true;
      }
      if (stroke) {
        stroke.disabled = true;
      }
    },
  },
  value: {
    type: ValueEditorType.None,
  },
  initialization: (appType: string, data: IComponentData, container?: UIContainerComponent, size?: ISize) => {
    const {
      size: { width, height },
    } = data;
    if (size) {
      const scale = {
        x: size.width / width,
        y: size.height / height,
      };
      const value = data.value as IPathValue;
      data.value = scalePath(value, scale);
    }
  },
  template: `{
    type: @@C.Path,
    size: { width: 100, height: 100 },
    properties: {
      fill: {
        hidden: true,
        disabled: false,
        type: @@FillType.solid,
        color: { r: 254, g: 64, b: 102, a: .1 },
      },
      stroke: {
        disabled: false,
        hidden: true,
        thickness: 1,
        position: 'inner',
        color: { r: 254, g: 64, b: 102, a: .6 },
      },
    },
    value: {
      data: [{
        point: { x: 0, y: 0 },
        handleIn: { x: 0, y: 0 },
        handleOut: { x: 0, y: 0 },
      }, {
        point: { x: 100, y: 0 },
        handleIn: { x: 0, y: 0 },
        handleOut: { x: 0, y: 0 },
      }, {
        point: { x: 100, y: 100 },
        handleIn: { x: 0, y: 0 },
        handleOut: { x: 0, y: 0 },
      }, {
        point: { x: 0, y: 100 },
        handleIn: { x: 0, y: 0 },
        handleOut: { x: 0, y: 0 },
      }],
      closed: true,
    },
  }`,
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
};

const defaultData = getDefaultDataFromTemplate(HotAreaCfg);
