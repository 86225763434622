import * as React from 'react';
import classnames from 'classnames';

import { UITheme } from '../../models';
import { IPagerSelectAction } from '../../models/pager';

import Total from './Total';
import PaginationContainer from './PaginationContainer';

import './index.scss';

interface IPaginationProps {
  theme: UITheme;
  total: number;
  pageIndex: number;
  pageSize: number;
  showTotal?: boolean | React.ReactNode;
  totalText?: string;
  showTotalSelect?: boolean;
  selectActionText?: string;
  checked?: (string | number)[];
  currentPageCount?: number;
  selectActions?: IPagerSelectAction[];
  showActionsAsMenu?: boolean;
  checkAllCount?: number;
  showPageIndex?: boolean;
  showQuickJumper?: boolean;
  showSizeChanger?: boolean;

  onChange?(pageNumber: number, pageSize?: number): void;
  onChangeSize?(pageSize: number, pageNumber: number): void;
  onCheckAll?(isChecked: boolean): void;
  onCheckAction?(id?: string | number): void;
}

const Pagination: React.FC<IPaginationProps> = (props: IPaginationProps) => {
  const {
    theme,
    totalText,
    total,
    showTotal,
    showTotalSelect,
    checked,
    currentPageCount,
    selectActions,
    selectActionText,
    showActionsAsMenu,
    checkAllCount,
    pageIndex,
    pageSize,
    showPageIndex,
    showQuickJumper,
    showSizeChanger,
    onChange,
    onChangeSize,
    onCheckAll,
    onCheckAction,
  } = props;

  const containerRef = React.useRef<HTMLUListElement>(null);

  return (
    <div className={classnames('dsm-c-pagination', theme)}>
      <Total
        text={totalText}
        total={total}
        theme={theme}
        showTotal={showTotal}
        showSelect={showTotalSelect}
        checked={checked}
        currentPageCount={currentPageCount}
        selectActions={selectActions}
        selectActionText={selectActionText}
        showActionsAsMenu={showActionsAsMenu}
        checkAllCount={checkAllCount}
        containerRef={containerRef}
        onCheckAll={onCheckAll}
        onCheckAction={onCheckAction}
      />
      <PaginationContainer
        theme={theme}
        pageIndex={pageIndex}
        pageSize={pageSize}
        total={total}
        showPageIndex={!!showPageIndex}
        showQuickJumper={!!showQuickJumper}
        showSizeChanger={!!showSizeChanger}
        containerRef={containerRef}
        onChange={onChange}
        onChangeSize={onChangeSize}
      />
    </div>
  );
};

Pagination.defaultProps = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  totalText: '',
  showTotal: true,
  showTotalSelect: false,
  selectActionText: '',
  selectActions: [],
  checked: [],
  currentPageCount: 0,
  showPageIndex: true,
  showSizeChanger: true,
  showQuickJumper: true,
};

export default Pagination;
