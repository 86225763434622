import * as React from 'react';
import { defaultSelectIcon } from '@/consts/defaultData/menu';
import { UIComponent } from '@/editor/comps';
import ITree from '@/fbs/rp/models/properties/tree';
import { DefaultIconColor } from '@/consts/colors';
import { parseColorToString } from '@/utils/graphicsUtils';
import { CIcon } from '@/libs/constants';
import { StyleHelper } from '@/helpers/styleHelper';

/**
 * 自定义按钮
 * @param comp
 * @returns
 */
export const renderExtendIcon = (comp: UIComponent, icon: UIComponent) => {
  const { properties } = comp;
  const { fill } = properties;
  const treeExpand = properties['treeExpand'] as ITree;
  if (treeExpand) {
    const { isShow, chooseExpandType, iconSize, iconColor } = treeExpand;
    if (!isShow) {
      return <></>;
    }
    const selectIcon = defaultSelectIcon.find((item) => item.id === chooseExpandType);
    if (selectIcon && selectIcon.value && selectIcon.value.length) {
      // 兼容老数据
      const item = icon.type === CIcon ? icon.$data.value : selectIcon.value[0];
      return (
        <div
          style={{
            fontSize: iconSize + 'px',
            fontFamily: item.fontName,
            color: parseColorToString(iconColor || DefaultIconColor),
            position: 'absolute',
            right: '4px',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: fill ? StyleHelper.parserFill(fill).background : '#ffffff',
          }}
          className={item.iconCode}
        >
          {String.fromCharCode(item.iconCode)}
        </div>
      );
    }
  }

  return <></>;
};
