import * as React from 'react';
import classnames from 'classnames';

import { dragDelegate } from '@utils/mouseUtils';
import {
  applyFillToStyle,
  calcColorStopByInsertPoint,
  FillType,
  fullColorStops,
  IColorStop,
  IFill,
  IGradient,
  ILinearGradient,
  IRadialGradient,
  parseColorToString,
} from '@utils/graphicsUtils';
import { IRadialGradientColor } from '@fbs/rp/models/properties/color';
import { depthClone, isEqualDate, isInputting } from '@utils/globalUtils';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';

import Icon from '../../Icon';
import GradientDirection from './GradientDirection';
import GradientPosition from './GradientPosition';

import i18n from '@i18n';

import './index.scss';

export interface IGradientProp {
  color: IFill;
  disabled?: boolean;
  selectedIndex: number;
  onSelect: (index: number) => void;
  onColorStopChanged: (colorStops: Array<IColorStop>, index: number) => void;
  onChanged: (color: IFill) => void;
  onChanging: (color: IFill) => void;
  onDirectionChange: (direction: { x1: number; y1: number; x2: number; y2: number }) => void;
  onPositionChange: (position: {
    from: { x: number; y: number; r: number };
    to: { x: number; y: number; r: number };
  }) => void;
}

export interface IGradientState {
  colorStops: Array<IColorStop>;
  showGradientPanel: boolean;
  selectedIndex: number;
  point?: { left: number; top: number };
}

const defaultDiractionData = { x1: 0, x2: 0, y1: 0, y2: 1 };
const defaultFromData = { x: 0.5, y: 0.5, r: 0 };
const defaultToData = { x: 0.5, y: 0.5, r: 0.5 };

class Gradient extends React.Component<IGradientProp, IGradientState> {
  static defaultProps: Partial<IGradientProp> = {};

  selfRef: React.RefObject<HTMLDivElement>;
  pointDom: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: IGradientProp) {
    super(props);
    this.state = {
      colorStops: depthClone((props.color.color as IGradient).colorStops),
      selectedIndex: props.selectedIndex,
      showGradientPanel: false,
    };
    this.selfRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onWindowKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onWindowKeyDown);
  }

  UNSAFE_componentWillReceiveProps(newProps: IGradientProp) {
    const { color, selectedIndex } = newProps;
    if (color.type !== FillType.solid) {
      const gradient = color.color as IGradient;
      if (!isEqualDate(gradient.colorStops, this.state.colorStops)) {
        this.setState({ colorStops: depthClone(gradient.colorStops) });
      }
    }
    if (this.state.selectedIndex !== selectedIndex) {
      this.setState({ selectedIndex });
    }
  }

  onWindowKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === KeyCodeMap.VK_DEL || e.keyCode === KeyCodeMap.VK_BACKSPACE) {
      if (!isInputting()) {
        const { selectedIndex, colorStops } = this.state;
        if (selectedIndex > 0 && selectedIndex < colorStops.length - 1) {
          this.doRemovePoint(colorStops, selectedIndex);
        }
      }
    }
  };

  onInsertPoint = (e: React.MouseEvent) => {
    const dom = e.target as HTMLElement;
    e.stopPropagation();
    const { offsetX } = e.nativeEvent;
    const pt: number = parseFloat(((offsetX * 100) / dom.offsetWidth).toFixed(2));
    const gradient = this.props.color.color as IGradient;
    const colors = fullColorStops(gradient.colorStops);
    let index = 1;
    for (let i = colors.length - 1; i >= 0; i--) {
      if ((colors[i].point as number) < pt) {
        index = i + 1;
        break;
      }
    }
    const previousColor = colors[index - 1];
    const nextColor = colors[index];

    colors.splice(index, 0, calcColorStopByInsertPoint(previousColor, nextColor, pt));
    this.setState({ colorStops: colors });
    const { onColorStopChanged } = this.props;
    onColorStopChanged &&
      onColorStopChanged(
        colors.map((item) => ({ color: item.color, point: item.point })),
        index,
      );
  };

  doRemovePoint = (colorStops: Array<IColorStop>, index: number) => {
    if (index === 0 || index === colorStops.length - 1) {
      return;
    }
    const newData = [...colorStops];
    newData.splice(index, 1);
    const { onColorStopChanged } = this.props;
    onColorStopChanged && onColorStopChanged(newData, index);
  };

  doChangePoint = (colorStops: Array<IColorStop>, index: number, newPoint: number) => {
    const pt = parseFloat(newPoint.toFixed(2));
    const v = colorStops[index];
    v.point = pt;
    const arr = fullColorStops(colorStops);
    arr.splice(index, 1);
    let newIndex = index;
    for (let i = arr.length - 1; i >= 0; i--) {
      if ((arr[i].point as number) <= pt) {
        newIndex = i + 1;
        break;
      }
    }
    arr.splice(newIndex, 0, { ...v, index: newIndex });
    const { color } = this.props;
    const newColor = depthClone(color);
    (newColor.color as IGradient).colorStops = arr.map((item) => {
      delete item.index;
      return item;
    });

    this.setState({ colorStops: arr, selectedIndex: newIndex });
    this.props.onChanging(newColor);
  };

  doSimulatiionRemovePoint = (colorStops: IColorStop[], index: number) => {
    const newColorStops = [...colorStops];
    newColorStops.splice(index, 1);
    const { color, onChanging } = this.props;
    const gradient = color.color as IGradient;
    const newFill: IFill = {
      type: color.type,
      color: {
        ...gradient,
        colorStops: newColorStops,
      },
    };
    this.setState({ colorStops: newColorStops, selectedIndex: -1 });
    onChanging(newFill);
  };

  onPointMouseDown = (e: React.MouseEvent, index: number) => {
    e.stopPropagation();
    if (this.props.disabled) {
      return;
    }
    if (index === 0 || index === this.state.colorStops.length - 1) {
      return;
    }
    const dom = e.target as HTMLElement;
    const { offsetHeight } = dom;
    const colorStops = [...this.state.colorStops];
    const OFFSET = 5;
    const pt = dom.offsetLeft;
    const parentWidth = dom.parentElement ? dom.parentElement.offsetWidth : 100;
    dragDelegate(
      (e: MouseEvent, delta: { x: number; y: number }) => {
        const newPt = Math.max(0, Math.min(((pt + delta.x) * 100) / parentWidth, 100));
        if (Math.abs(delta.y) > offsetHeight + OFFSET) {
          // 模拟删除
          this.doSimulatiionRemovePoint(colorStops, index);
        } else {
          this.doChangePoint(colorStops, index, newPt);
        }
      },
      (e: MouseEvent, delta: { x: number; y: number }) => {
        const newColorStops = depthClone(colorStops);
        if (Math.abs(delta.y) > offsetHeight + OFFSET) {
          // 向上移动距离大于本身高度时，作删除处理
          this.doRemovePoint(colorStops, index);
        } else if (delta.x) {
          this.props.onColorStopChanged && this.props.onColorStopChanged(newColorStops, index);
        }
      },
    );
  };

  handleRevertPointsClick = () => {
    const { onColorStopChanged } = this.props;
    const { colorStops } = this.state;
    const revertColorStops = [...colorStops].reverse().map((item) => {
      let point = item.point;
      if (typeof item.point === 'number') {
        point = 100 - item.point;
      }
      return { ...item, point };
    });
    onColorStopChanged && onColorStopChanged(revertColorStops, 0);
  };

  onDirectionChange = (direction: { x1: number; y1: number; x2: number; y2: number }) => {
    const color = this.props.color.color as IRadialGradientColor;
    this.props.onChanged({ ...this.props.color, color: { ...color, direction: direction } });
  };

  onPositionChange = (position: {
    from: { x: number; y: number; r: number };
    to: { x: number; y: number; r: number };
  }) => {
    const color = this.props.color.color as IRadialGradientColor;
    this.props.onChanged({ ...this.props.color, color: { ...color, from: position.from, to: position.to } });
  };

  renderPoint = () => {
    const { onSelect } = this.props;
    const { colorStops, selectedIndex } = this.state;
    const colors = fullColorStops(depthClone(colorStops));
    colors.unshift(colors.pop()!);
    return colors.map((color, index) => {
      return (
        <div
          key={`${index}`}
          className={classnames('point-item', { selected: color.index === selectedIndex })}
          style={{
            background: color.index === selectedIndex ? parseColorToString(color.color) : '#fff',
            borderColor: color.index === selectedIndex ? '#fff' : 'rgba(0,0,0,.5)',
            left: `${color.point}%`,
          }}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
          }}
          onMouseDown={(e: React.MouseEvent) => {
            e.stopPropagation();
            onSelect(color.index);
            this.onPointMouseDown(e, color.index);
          }}
        />
      );
    });
  };

  render() {
    const { color, disabled } = this.props;
    const gradient = color.color as IGradient;
    const linear: ILinearGradient = { ...gradient, direction: { x1: 0, x2: 1, y1: 0, y2: 0 } };
    const style: React.CSSProperties = {};
    applyFillToStyle({ type: FillType.linear, color: linear }, style);
    return (
      <div className="dsm-c-rp-color-general-gradient">
        <div className="gradient-value">
          <div className="point" onMouseDown={disabled ? undefined : this.onInsertPoint} ref={this.pointDom}>
            {this.renderPoint()}
          </div>
          <div className="gradient-content">
            <div className="gradient-background" />
            <div className="gradient-color" style={style} />
          </div>
        </div>
        <div className="gradient-revert">
          <Icon
            cls="icon_symmetry"
            disabled={disabled}
            tips={color.type === FillType.linear ? i18n('tips.revertLinear') : i18n('tips.revertRadial')}
            onClick={disabled ? undefined : this.handleRevertPointsClick}
          />
        </div>
        {color.type === FillType.linear && (
          <GradientDirection
            direction={(color.color as ILinearGradient).direction || defaultDiractionData}
            onChange={disabled ? undefined : this.onDirectionChange}
          />
        )}
        {color.type === FillType.radial && (
          <GradientPosition
            position={{
              from: (color.color as IRadialGradient).from || defaultFromData,
              to: (color.color as IRadialGradient).to || defaultToData,
            }}
            onChange={disabled ? undefined : this.onPositionChange}
          />
        )}
      </div>
    );
  }
}

export default Gradient;
