import { max } from '@utils/globalUtils';
import { PredefinedStates } from '@consts/state';
import ValueEditorType from '@consts/enums/valueEditorType';
import i18n from '@i18n';

import { measureTextSize } from '@utils/textUtils';

import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { IRange } from '@fbs/rp/models/properties/base';
import { PropertyValue } from '@fbs/rp/models/property';
import { EventTypes } from '@fbs/rp/models/event';
import { MoveDelta } from '@fbs/common/models/resize';

import { UIComponent, UIContainerComponent } from '@editor/comps';
import { getTextCompInSealed } from '@helpers/componentHelper';
import appOptions from '@/helpers/appOptions';
import { SpriteThumb } from '@consts/spriteIcons';
import { getDefaultDataFromTemplate } from '@/libs/helper';

import { IComponentItem } from '../../types';
import { CPureText } from '../../constants';
import { SizeMode, MoveMode, PropertyStructureAlign } from '../../enum';
import { compoundStructureList } from '../lib';

export function onChildMove(
  delta: MoveDelta,
  checkboxComp: UIContainerComponent,
  child?: UIComponent[],
): ArtboardPatches | null {
  const textComp = getTextCompInSealed(checkboxComp)!;
  const properties = checkboxComp.properties;
  const originGap = properties.layout?.horizontalGap!;
  const first = child?.length ? child[0] : undefined;
  if (first?.type === CPureText) {
    const originPosition = textComp.position;
    const horizontalDiff = first.position.x - originPosition.x;
    const path = checkboxComp.getCurrentPropertiesPath('properties/layout');
    const newGap = max(0, originGap + horizontalDiff);
    const newValue = { ...properties.layout, horizontalGap: newGap };
    return {
      do: {
        [checkboxComp.id]: [Ops.replace(path, newValue)],
      },
      undo: {
        [checkboxComp.id]: [Ops.replace(path, properties.layout)],
      },
    };
  }
  return null;
}

const { width, height } = measureTextSize(
  {
    fontSize: 14,
    fontFamily: 'Microsoft YaHei',
  },
  i18n('resource.components.radio'),
);

const defaultHeight = Math.max(16, height);

const onPropertyUpdate = (comp: UIComponent, propertyName: string, value: PropertyValue): ArtboardPatches | null => {
  if (propertyName === 'iconSize') {
    const group = comp as UIContainerComponent;
    const icon = group.getComponentByAlias('icon', true) || group.components[0];
    if (icon) {
      const [first] = (icon as UIContainerComponent).components;
      const { size, id, position } = icon;
      const { size: parentSize } = comp;
      const range = value.value as IRange;
      const maxValue = range.value; //Math.min(parentSize.height, range.value);
      const y = (parentSize.height - maxValue) / 2;
      const _iconSize = Math.round((10 / 16) * maxValue);
      const { id: iconID, size: iconSize, position: iconPosition } = first;
      const left = (maxValue - _iconSize) / 2;
      const propertyPath = comp.getCurrentPropertiesPath('/properties/iconSize');
      const sizePath = comp.getCurrentSizePath();
      const positionPath = comp.getCurrentPositionPath();
      return {
        do: {
          [id]: [
            Ops.replace(sizePath, { ...size, width: maxValue, height: maxValue }),
            Ops.replace(`${positionPath}/y`, y),
          ],
          [iconID]: [
            Ops.replace(sizePath, { ...iconSize, width: _iconSize, height: _iconSize }),
            Ops.replace(positionPath, { x: left, y: left }),
          ],
          [comp.id]: [Ops.replace(propertyPath, { ...value, value: { ...range, value: maxValue } })],
        },
        undo: {
          [id]: [Ops.replace(sizePath, size), Ops.replace(`${positionPath}/y`, position.y)],
          [iconID]: [Ops.replace(sizePath, iconSize), Ops.replace(positionPath, iconPosition)],
          [comp.id]: [Ops.replace(propertyPath, comp.properties['iconSize'])],
        },
      };
    }
  }
  return null;
};

export const RadioCfg: IComponentItem = {
  type: 'radio',
  name: i18n('resource.components.radio'),
  isTextComp: true,
  thumb: {
    spriteIconClass: SpriteThumb.Radio.className,
    dragPosition: SpriteThumb.Radio.position,
  },
  sizeMode: SizeMode.none,
  predefinedStates: [PredefinedStates.checked, PredefinedStates.disabled],
  editor: {
    onPropertyUpdate,
    onChildMove,
  },
  interactions: {
    defaultEvent: EventTypes.checked,
  },
  value: {
    type: ValueEditorType.PureText,
  },
  constraint: {
    generalText: {
      move: MoveMode.horizontal,
    },
    icon: {
      move: MoveMode.neither,
    },
    innerCircle: {
      resize: SizeMode.none,
    },
  },
  syncPropertyToStateNormal: {
    structure: true,
  },
  property: {
    getValueNames() {
      return compoundStructureList;
    },
    getExtensionDefaultProperties(comp: UIComponent) {
      const compData = comp.toJSON();
      const typeName = compData.lib?.type ?? compData.type;
      // 兼容旧数据
      if (compData.properties.structure) {
        return null;
      }
      return {
        structure: appOptions.getStructureAlign(typeName) || {
          value: PropertyStructureAlign.TextRight,
          prop: 'enum',
          name: 'structure',
        },
      };
    },
  },
  template: `
  {
    type: @@C.StackPanel,
    size: {
      width: ${width + 24},
      height: ${defaultHeight},
    },
    layout:{
      vertical: 'middle',
      horizontal: 'left',
      fixedWidth: true,
      fixedHeight: true,
      auto: true,
      responsive: true,
    },
    properties: {
      textStyle: {
        textAlign: @@TextAlign.left,
        color: @@SystemColors.DefaultTextColor,
        fontSize: 14,
        fontFamily: 'Microsoft YaHei',
        fontStyle: { underline: false, bold: false, strike: false, italic: false },
        disabled: false,
      },
      layout:{
        direction: 'horizontal',
        verticalAlign: 'middle',
        horizontalGap: 10,
        disabledSwitch: true,
        disabled: false,
      },
      iconSize: {
        prop: 'number',
        name: '${i18n('property.propertyNames.iconSize')}',
        value: {
          min: 10,
          max: 100,
          value: 13
        },
      }      
    },
    value: '${i18n('resource.components.radio')}',
    states:{
      checked:{
        enabled: true,
        properties:{},
      },
      disabled:{
        enabled: true,
        opacity: 30,
        properties:{},
      },
    },
    components: [
      {
        type: @@C.CanvasPanel,
        alias: 'icon',
        size: {
          width: 13,
          height: 13,
        },
        position:{
          x: 0,
          y: ${(defaultHeight - 13) / 2},
        },
        layout:{
          vertical: 'middle',
          horizontal: 'left',
          fixedWidth: false,
          fixedHeight: false,
          auto: false,
          responsive: true,
        },
        properties:{
          fill:{
            type: 'solid',
            color: {r: 255, g: 255, b: 255, a: 1},
            disabled: false,
          },
          stroke: {
            color: "#AAAAAA",
            thickness: 1,
            disabled: false,
          },
          radius:{
            topLeft: 100,
            topRight: 100,
            bottomLeft: 100,
            bottomRight: 100,
            isPercent: true,
            hidden: true,
          },
        },
        components: [
          {
            type: @@C.Rect,
            alias:'innerCircle',
            position: {
              x: 3,
              y: 3,
            },
            size: {
              width: 7,
              height: 7,
            },
            layout:{
              vertical: 'middle',
              horizontal: 'center',
              fixedWidth: false,
              fixedHeight: false,
              auto: false,
              responsive: true,
            },
            properties: {
              fill: {
                disabled: false,
                type: 'solid',
                color: @@SystemColors.DefaultSelectColor,
              },
              radius:{
                topLeft: 100,
                topRight: 100,
                bottomLeft: 100,
                bottomRight: 100,
                isPercent: true,
                hidden: true,
              },
            },
            value: {
              iconCode: 59977,
              fontName: 'boldIconFont',
            },
            hidden: true,
            states: {
              checked: {
                enabled: true,
                name: '${i18n('resource.components.radio')}',
                hidden: false,
              },
            },
          },
        ],
      },
      {
        type: @@C.PureText,
        alias: 'generalText',
        autoSize: true,
        size: {
          width: ${width},
          height: ${height},
        },
        position:{
          x: 24,
          y: ${Math.floor((defaultHeight - height) / 2)},
        },
        layout:{
          vertical: 'middle',
          horizontal: 'left&right',
          fixedWidth: false,
          fixedHeight: true,
          auto: false,
          responsive: true,
        },
        value: '@value',
        properties: {
          textStyle: {
            ref: '@properties.textStyle',
          },
        },
      },
    ],
    select: {
      target: 'self',
      enabled: true,
      reversible: false,
    },
    sealed: true,
  }
  `,
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
};

const defaultData = getDefaultDataFromTemplate(RadioCfg);
