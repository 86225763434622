import * as React from 'react';
import { isUndefined } from 'lodash';
import classnames from 'classnames';

import { ListRowProps } from 'react-virtualized/dist/es/List';
import { ComponentTheme } from '../common';
import { DropMode } from '../PageTree/model';
import { IProps, TreeItemData } from './helper';

import { AutoSizer, List } from 'react-virtualized';
import VirtualTreeItem from './VirtualTreeItem';

import 'react-virtualized/styles.css';
import './index.scss';

export interface ITreeProp<T> {
  items: IProps<T>[];
  theme: ComponentTheme;
  itemHeight?: number;
  isClickFolderOnExpand?: boolean;
  prefixIndent?: number;
  className?: string;
  disabled?: boolean;
  canShowHighlightBorder?: boolean;
  // 拖拽相关属性
  allowDrag?: boolean;
  dropMode?: DropMode;
  isOver?: boolean;
  overNodeIndex?: number;
  dropModeStyle?: React.CSSProperties;
  // 更新列表相关属性
  updateExpand?: number;
  editing?: boolean;
  inDragging?: boolean; // 在拖拽过程中
  otherUpdateTreeDataTimes?: number; // 预留更新列表数据
  otherUpdateTreeUITimes?: number; // 预留更新列表样式的属性
  // 更新列表中的行相关属性
  needUpdateWhenMouseEnterOrLeave?: boolean | ((item: T) => boolean);

  itemRender: (item: T, collapse?: boolean) => React.ReactNode;
  onItemClick?: (e: React.MouseEvent, item: T) => void;
  onItemDoubleClick?: (e: React.MouseEvent, item: T) => void;
  onItemExpandChanged?: (item: T, expand: boolean) => void;
  onItemExpandAllChanged?: (expand: boolean) => void;
  onItemContextMenu?: (e: React.MouseEvent, data: TreeItemData<T>) => void;
  onDragStart?: (e: DragEvent, item: T) => void;
  onDrag?: (e: DragEvent) => void;
  onDragEnd?: (e: DragEvent, item: T) => void;
  onDrop?: (e: React.DragEvent, item: T) => void;
  onDragOver?: (e: React.DragEvent, item: IProps<T>, itemIndex: number) => void;
  onDragEnter?: (e: React.DragEvent, item: T) => void;
  onItemMouseEnter?: (e: React.MouseEvent, data: TreeItemData<T>) => void;
  onItemMouseUp?: (e: React.MouseEvent, data: TreeItemData<T>) => void;
  onItemMouseLeave?: (e: React.MouseEvent, data: TreeItemData<T>) => void;
  onItemShowHighlightBorder?(data: TreeItemData<T>): boolean;
  getNodeLevel?(node: IProps<T>): number;
  getNodeId?(node: T): string;
  getNodeIsAllowDrag?(node: T): boolean;
}

export interface ITreeState {}

const DEFAULT_TREE_ITEM_HEIGHT = 30;
const DEFAULT_PRE_RENDER_ROWS = 20;
const DEFAULT_PREFIX_INDENT = 20;

class VirtualTree<T> extends React.PureComponent<ITreeProp<T>, ITreeState> {
  public self: React.RefObject<HTMLDivElement> = React.createRef();
  public virtualList: React.RefObject<List> = React.createRef();
  static defaultProps: Partial<ITreeProp<any>> = {
    theme: ComponentTheme.dark,
  };

  private cacheList: IProps<T>[] | null = null;

  constructor(props: ITreeProp<T>) {
    super(props);
    this.state = {};
  }

  /***
   * 数据扁平化处理
   * @returns {IProps<T>[]}
   * */
  private get _list(): IProps<T>[] {
    const items = this.props.items as IProps<T>[];
    const result = [];
    const stack = Array.from(items);
    while (stack.length) {
      const node = stack.shift();
      if (!node) {
        break;
      }
      result.push(node);
      const children = node.children;
      if (node.expand && children && children.length) {
        stack.unshift(...children);
      }
    }

    return result;
  }

  private get list(): IProps<T>[] {
    if (!this.cacheList || !this.cacheList.length) {
      this.cacheList = this._list;
    }
    return this.cacheList;
  }

  /**
   * 更新list
   * */
  private updateCacheList(nextProps: Readonly<ITreeProp<T>>): void {
    // 将需要更新的值都放在这里
    const { items, editing, updateExpand, otherUpdateTreeDataTimes } = this.props;

    const updateProps: Partial<ITreeProp<T>> = {
      items,
      updateExpand,
      editing,
      otherUpdateTreeDataTimes,
    };

    for (const key in updateProps) {
      const k: keyof Partial<ITreeProp<T>> = key as keyof Partial<ITreeProp<T>>;
      if (updateProps[k] !== nextProps[k]) {
        this.cacheList = null;
        break;
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<ITreeProp<T>>) {
    this.updateCacheList(nextProps);
  }

  scrollToNode = (node: T) => {
    const { getNodeId } = this.props;
    const index = this.list.findIndex((item) => getNodeId?.(item.data) === getNodeId?.(node));
    if (index > -1) {
      this.virtualList.current?.scrollToRow(index);
    }
  };

  getBoundingClientRect = () => {
    return this.self.current?.getBoundingClientRect();
  };

  getRowHeight = ({ index }: { index: number }) => {
    // 底边留出4个边距
    if (index === this.list.length) {
      return 4;
    }
    return this.props.itemHeight || DEFAULT_TREE_ITEM_HEIGHT;
  };

  renderItem = ({ index, style }: ListRowProps) => {
    if (index === this.list.length) {
      // 底边留出4个边距的底边与原样式保持一致
      return <div style={style} key="blank"></div>;
    }

    const {
      disabled,
      itemRender,
      itemHeight,
      onItemClick,
      onItemContextMenu,
      onDrop,
      onDragStart,
      onDragOver,
      onDragEnd,
      prefixIndent,
      onItemExpandChanged,
      onItemExpandAllChanged,
      onItemMouseEnter,
      onItemMouseLeave,
      onItemDoubleClick,
      onItemMouseUp,
      onItemShowHighlightBorder,
      isClickFolderOnExpand,
      getNodeLevel,
      getNodeId,
      getNodeIsAllowDrag,
      dropMode,
      isOver,
      dropModeStyle,
      overNodeIndex,
      allowDrag: itemAllowDrag,
      inDragging,
      onDrag,
      needUpdateWhenMouseEnterOrLeave,
    } = this.props;

    const item = (this.list as IProps<T>[])[index];
    const indent = isUndefined(prefixIndent) ? DEFAULT_PREFIX_INDENT : prefixIndent;
    const allowDrag = getNodeIsAllowDrag?.(item.data) || itemAllowDrag;
    const id = getNodeId?.(item.data);

    return (
      // 注意：需要使用style用于定位或自定义style进行定位，否则滚动会有问题
      <div key={id} style={style}>
        <VirtualTreeItem
          marked={item.marked}
          isClickFolderOnExpand={isClickFolderOnExpand}
          indent={indent}
          props={item}
          disabled={disabled}
          height={itemHeight}
          draggable={allowDrag}
          itemIndex={index}
          inDragging={inDragging}
          needUpdateWhenMouseEnterOrLeave={needUpdateWhenMouseEnterOrLeave}
          render={itemRender}
          parentNode={item.parent ? getNodeId?.(item.parent.data) : ''}
          getNodeLevel={getNodeLevel}
          onClick={onItemClick}
          onDoubleClick={onItemDoubleClick}
          onItemExpandChanged={onItemExpandChanged}
          onItemExpandAllChanged={onItemExpandAllChanged}
          onDrop={onDrop}
          onDragStart={onDragStart}
          onDrag={onDrag}
          onDragOver={onDragOver}
          onDragEnd={onDragEnd}
          onContextMenu={onItemContextMenu}
          onMouseEnter={onItemMouseEnter}
          onMouseLeave={onItemMouseLeave}
          onMouseUp={onItemMouseUp}
          onItemShowHighlightBorder={onItemShowHighlightBorder}
        />
        {dropMode && dropMode !== DropMode.None && overNodeIndex === index && (
          <div
            className={classnames('dropMoveBox flat-tree-dropMoveBox', {
              isOver,
            })}
            style={{
              ...dropModeStyle,
              pointerEvents: 'none',
            }}
          ></div>
        )}
      </div>
    );
  };

  render() {
    const {
      className,
      theme,
      canShowHighlightBorder,
      dropMode,
      dropModeStyle,
      isOver,
      items,
      updateExpand,
      editing,
      overNodeIndex,
      inDragging,
      otherUpdateTreeDataTimes,
      otherUpdateTreeUITimes,
    } = this.props;

    return (
      <div
        className={classnames('dsm-c-rp-tree', className, theme, {
          'can-show-highlight-border': canShowHighlightBorder,
        })}
        ref={this.self}
      >
        <div className={classnames('tree-content tree-content-flat')} style={{ position: 'relative', height: '100%' }}>
          <AutoSizer style={{ width: '100%' }}>
            {({ width, height }) => {
              return (
                <List
                  ref={this.virtualList}
                  className={classnames('rp-auto-size-list')}
                  width={width}
                  height={height}
                  overscanRowCount={DEFAULT_PRE_RENDER_ROWS}
                  rowCount={this.list.length + 1}
                  rowHeight={this.getRowHeight}
                  rowRenderer={this.renderItem}
                  // 注意： 需要更新列表数据或样式的相关值，都需要传入
                  data={items}
                  updateExpand={updateExpand}
                  editing={editing}
                  overNodeIndex={overNodeIndex}
                  dropMode={dropMode}
                  isOver={isOver}
                  dropLineTop={dropModeStyle?.top}
                  inDragging={inDragging}
                  otherUpdateTreeDataTimes={otherUpdateTreeDataTimes}
                  otherUpdateTreeUITimes={otherUpdateTreeUITimes}
                />
              );
            }}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

export default VirtualTree;
