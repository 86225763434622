import { IMemberInfo } from '@fbs/idoc/models/member';
import { IAppMembersInfo } from '@fbs/idoc/models/app';
import { Role } from '@/fbs/teamManagement';

import { MemberAction } from './actions';
import { ActionType } from '../types';

export interface IMemberState {
  members: IMemberInfo[];
  membersFromApp: IAppMembersInfo[];
  parentMembersFromApp: IAppMembersInfo[];
  invitation: {
    invitationID: string;
    role: string;
  };
  groupID: number;
  groupIDs: number[];
  parentGroupIDs: number[];
}

const initState: IMemberState = {
  members: [],
  membersFromApp: [],
  parentMembersFromApp: [],
  invitation: {
    invitationID: '',
    role: Role.Member,
  },
  groupID: 0,
  groupIDs: [],
  parentGroupIDs: [],
};

function getMemberState(state: IMemberState = initState, action: MemberAction): IMemberState {
  switch (action.type) {
    case ActionType.Member_Load_All: {
      return {
        ...state,
        members: action.payload,
      };
    }
    case ActionType.Member_Load_App_Members: {
      return {
        ...state,
        membersFromApp: action.payload.members,
        parentMembersFromApp: action.payload.parents,
        groupIDs: action.payload.groups,
        parentGroupIDs: action.payload.parentGroups,
      };
    }
    case ActionType.Member_Update_App_Members: {
      return {
        ...state,
        membersFromApp: action.payload,
      };
    }
    case ActionType.Member_Update_App_Groups: {
      return {
        ...state,
        groupIDs: action.payload,
      };
    }
    case ActionType.Invitation_Load_Link: {
      return {
        ...state,
        invitation: action.payload,
      };
    }
    case ActionType.Invitation_Patch_Link: {
      return {
        ...state,
        invitation: Object.assign({}, state.invitation, action.payload),
      };
    }
    case ActionType.Invitation_Patch_Group: {
      return {
        ...state,
        groupID: action.payload,
      };
    }
    default:
      break;
  }
  return state;
}

export default getMemberState;
