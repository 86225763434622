import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// 组件宽度小于5，x轴压缩
// const positionX = 'sw>=45?20:sw*(20/45)';
const positionX = 'Math.min(sw,sh)*(20/45)';

// 左箭头
export default {
  ...flowBase,
  name: i18n('resource.flow.leftArrow'),
  type: 'leftArrow',
  thumb: {
    spriteIconClass: SpriteThumb.LeftArrow.className,
    dragPosition: SpriteThumb.LeftArrow.position,
  },
  template: getShapeData(
    i18n('resource.flow.leftArrow'),
    {
      width: 60,
      lockedRatio: false,
      height: 45,
    },
    {
      data: [
        { point: { x: 60, y: 15 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 60, y: 30 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 20, y: 30 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 20, y: 45 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 0, y: 22.5 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 20, y: 0 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 20, y: 15 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      ],
      closed: true,
      isTemplateShape: true,
      templateData: [
        { point: { x: 'sw', y: 'sh/3' }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 'sw', y: 'sh/3*2' }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: positionX, y: 'sh/3*2' }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: positionX, y: 'sh' }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 0, y: 'sh/2' }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: positionX, y: 0 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: positionX, y: 'sh/3' }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      ],
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
