import PropertyBase from './base';
import { Color } from './color';

export enum TextAlign {
  left = 'left',
  center = 'center',
  right = 'right',
  justify = 'justify',
}

export enum TextTransform {
  none = 'none',
  upperCase = 'upperCase',
  lowerCase = 'lowerCase',
  capitalize = 'capitalize',
}

export enum VerticalAlign {
  top = 'top',
  middle = 'middle',
  bottom = 'bottom'
}

export type FontStyle = {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strike?: boolean;
};

/**
 * 文本样式属性，不要在该属性中考虑垂直方向的定位，暂不考虑行高部分
 */
export default interface ITextFormat extends PropertyBase {
  fontFamily?: string;
  fontSize?: number;
  fontStyle?: FontStyle;
  color?: Color;
  textAlign?: TextAlign;
  verticalAlign?: VerticalAlign;
  letterSpace?: number;
  transform?: TextTransform;
  listType?: 'order' | 'unOrder';
}

export type FontStyleName = 'bold' | 'italic' | 'underline' | 'strike';

export const TextPropertyName = 'textStyle';
export const ChartTextPropertyName = 'chart-textStyle';

