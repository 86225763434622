import * as React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';

import MathUtils from '@utils/mathUtils';
import { measureTextSize } from '@utils/textUtils';
import { rgba2hex } from '@/utils/graphicsUtils';

import { IComponentValue, ILineValue } from '@fbs/rp/models/value';
import ILine, { LinePointType } from '@fbs/rp/models/properties/line';
import { IPoint, ISize } from '@fbs/common/models/common';
import { StrokeLineCap } from '@fbs/rp/models/properties/stroke';
import { IProperties } from '@/fbs/rp/models/property';

import { StyleHelper } from '@helpers/styleHelper';

import general from '@consts/general';
import { FontBoxScale } from '@consts/fonts';
import { GrayColor } from '@/consts/colors';
import appOptions from '@helpers/appOptions';

import { IComponentProps } from '../../types';
import { CCompoundPath } from '../../constants';
import ShapeTextBase, { IShapeTextCompState } from '../common/ShapeTextBase';
import { renderMarker } from '../common/MarkerFragment';

import './index.scss';

export default class Line extends ShapeTextBase<IComponentProps, IShapeTextCompState> {
  private isVertLine: boolean = false;
  private isHoriLine: boolean = false;

  private diff = 0;

  constructor(props: IComponentProps) {
    super(props);
    this.state = this.doParsePropertiesToStyle(props);
    this.calcDiff(props);
    this.param = this.getParams(props);
  }

  private param: {
    size: ISize;
    properties: IProperties;
    value?: IComponentValue;
    opacity: number;
    isPreview?: boolean;
    globalScale: number;
    transition: string;
    isChildOfPath: boolean;
    valueEditing?: boolean;
    text: string;
    version: string;
    selected: boolean;
  } & { [key: string]: any };

  private getParams = (props: IComponentProps) => {
    const {
      comp: { size, properties, value, opacity, parent, text, version },
      isPreview,
      globalScale,
      valueEditing,
      selected,
    } = props;
    const transition = props.comp.getTransition();
    const isChildOfPath = parent?.type === CCompoundPath;

    return {
      size: _.cloneDeep(size),
      properties: _.cloneDeep(properties),
      value: _.cloneDeep(value),
      opacity,
      isPreview,
      globalScale,
      transition,
      isChildOfPath,
      valueEditing,
      selected,
      text,
      version,
    };
  };

  shouldComponentUpdate(nextProps: IComponentProps) {
    const newParam: { [key: string]: any } = this.getParams(nextProps);

    let flag = false;
    Object.keys(this.param).forEach((key) => {
      if (!_.isEqual(newParam[key], this.param[key])) {
        flag = true;
        this.param[key] = _.cloneDeep(newParam[key]);
      }
    });
    return flag;
  }

  UNSAFE_componentWillReceiveProps(nextProps: IComponentProps) {
    this.setState({ ...this.doParsePropertiesToStyle(nextProps) });
    this.calcDiff(nextProps);
  }

  private calcDiff(props: IComponentProps) {
    const { comp } = props;
    const { size, value } = comp;
    const lineValue = value as ILineValue;
    let startPoint = { x: 0, y: 0 };
    let endPoint = { x: size.width, y: size.height };
    if (lineValue && typeof lineValue === 'object') {
      startPoint = lineValue.startPoint;
      endPoint = lineValue.endPoint;
    }
    this.isVertLine = MathUtils.equal(startPoint.x, endPoint.x);
    this.isHoriLine = MathUtils.equal(startPoint.y, endPoint.y);
    const scale = appOptions.scale;
    if (scale >= 2) {
      this.diff = -0.5;
    } else {
      this.diff = 0;
    }
  }

  doParsePropertiesToStyle = (props: IComponentProps): IShapeTextCompState => {
    const { properties, text, value, size } = props.comp;
    const cssParser = StyleHelper.initCSSStyleParser(properties);
    const _style = cssParser.getTextStyle();
    const textStyle = {
      ..._style,
      ...cssParser.getMultiStyle(),
    };
    let defaultWidth;
    const lineValue = value as ILineValue;
    let startPoint = { x: 0, y: 0 };
    let endPoint = { x: size.width, y: size.height };
    if (lineValue && typeof lineValue === 'object') {
      startPoint = lineValue.startPoint || startPoint;
      endPoint = lineValue.endPoint || endPoint;
    }
    if (MathUtils.isMoreOrLess(startPoint.y, endPoint.y, 30)) {
      defaultWidth = MathUtils.abs(startPoint.x - endPoint.x) - 20;
    } else {
      defaultWidth = MathUtils.max(MathUtils.abs(startPoint.x - endPoint.x) - 20, 120);
    }
    let contentWidth = 0;
    let height = 0;
    let width = 0;
    if (text) {
      contentWidth = measureTextSize(textStyle, text, { wrap: false, isMultiText: false, isRich: true }).width;
      const size = measureTextSize(textStyle, text, {
        wrap: true,
        isMultiText: true,
        defaultWidth,
        isRich: true,
      });
      width = size.width;
      height = size.height;
    }

    return {
      textStyle: {
        ..._style,
        ...cssParser.getTextShadow(1 / FontBoxScale),
        width: text ? MathUtils.min(width, contentWidth) : defaultWidth,
        height,
        minWidth: MathUtils.min(120, contentWidth + 2) / FontBoxScale,
      },
    };
  };

  calcPointOffset = (line: ILine, lineWidth: number, angle: number): { start: IPoint; end: IPoint } => {
    const { startPointType, endPointType } = line;
    let lw = lineWidth;
    if (startPointType === LinePointType.solidArrow) {
      lw = lineWidth * 1.5;
    } else if (startPointType === LinePointType.hollowDot || startPointType === LinePointType.hollowCube) {
      lw = 2 * (lineWidth + 1);
    }
    const start = {
      x: lw * MathUtils.cos(((2 * Math.PI) / 360) * angle),
      y: lw * MathUtils.sin(((2 * Math.PI) / 360) * angle),
    };

    lw = lineWidth;
    if (endPointType === LinePointType.solidArrow) {
      lw = lineWidth * 1.6;
    } else if (endPointType === LinePointType.hollowCube || endPointType === LinePointType.hollowDot) {
      lw = 2 * (lineWidth + 1);
    }
    const end = {
      x: lw * MathUtils.cos(((2 * Math.PI) / 360) * angle),
      y: lw * MathUtils.sin(((2 * Math.PI) / 360) * angle),
    };
    return { start, end };
  };
  getArrowOffsetPosition = (arrowType: string, width: number, angle: number) => {
    //  每个端点得大小不一致，所以基数有变化
    const hollowTriangleWidth = 8;
    const otherArrowWidth = 4;
    const defaultWidth = 6;
    const cos = Math.cos((angle / 180) * Math.PI);
    const sin = Math.sin((angle / 180) * Math.PI);
    let linetWidth = width;
    let ax = 0;
    let ay = 0;
    switch (arrowType) {
      case 'hollowTriangle':
        linetWidth *= hollowTriangleWidth;
        break;
      case 'hollowDot':
      case 'hollowCube':
        linetWidth === 1 ? (linetWidth *= otherArrowWidth) : (linetWidth = linetWidth * defaultWidth - 2);
        break;
      case 'hollowDiamond':
        linetWidth *= defaultWidth;
        break;
    }
    ax = cos * linetWidth;
    ay = sin * linetWidth;
    return { ax, ay };
  };
  get isChildOfPath() {
    return this.props.comp.parent?.type === CCompoundPath;
  }

  render() {
    const { comp, isParentActivated, isPreview, globalScale } = this.props;
    const { properties, size, value, text, id } = comp;
    const lineValue = value as ILineValue;
    let startPoint = { x: 0, y: 0 };
    let endPoint = { x: size.width, y: size.height };
    if (value && typeof value === 'object') {
      startPoint = lineValue.startPoint;
      endPoint = lineValue.endPoint;
    }
    const angle = (Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x) * (180 / Math.PI) + 360) % 360;

    const vertical = startPoint.x === endPoint.x;
    const horizontal = startPoint.y === endPoint.y;

    const parser = StyleHelper.initSVGStyleParser(properties);
    const filter = parser.getShadow();
    const strokeInfo = parser.getStroke();
    const isChildOfPath = this.isChildOfPath;
    if (isChildOfPath) {
      strokeInfo.strokeWidth = 1;
      strokeInfo.stroke = rgba2hex(GrayColor);
      strokeInfo.strokeDasharray = undefined;
    }
    const { strokeWidth } = strokeInfo;
    const { line, shadow } = properties;
    const showStart = line ? line.startArrow : false;
    const showEnd = line ? line.endArrow : false;
    const startType = line?.startPointType || LinePointType.solidArrow;
    const endType = line?.endPointType || LinePointType.solidArrow;
    const lineWidth = strokeWidth || 1;
    const pointOffset = this.calcPointOffset(line!, lineWidth, angle);
    const { width, height } = { width: MathUtils.max(size.width, 1), height: MathUtils.max(size.height, 1) };
    let startOffsetX = 0;
    let startOffsetY = 0;
    let endOffsetX = -startOffsetX;
    let endOffsetY = -startOffsetY;

    if (this.isVertLine) {
      startOffsetX = endOffsetX = (lineWidth % 2) / 2;
    }

    if (this.isHoriLine) {
      startOffsetY = endOffsetY = (lineWidth % 2) / 2;
    }
    let senseWidth = general.PathSenseWidth;
    const scale = appOptions.scale;
    if (scale > 1) {
      senseWidth = MathUtils.max(1, MathUtils.round(senseWidth / scale));
    }

    const pointerEvents = 'none';
    const opacity = _.isUndefined(comp.opacity) ? 1 : comp.opacity / 100;

    let x1 = startPoint.x;
    let x2 = endPoint.x;
    let y1 = startPoint.y;
    let y2 = endPoint.y;
    const needOffsetStart = showStart && [LinePointType.hollowCube, LinePointType.hollowDot].includes(startType);
    const needOffsetEnd = showEnd && [LinePointType.hollowCube, LinePointType.hollowDot].includes(endType);
    x1 += startOffsetX;
    x2 += endOffsetX;
    y1 += startOffsetY;
    y2 += endOffsetY;
    if (this.isVertLine) {
      x1 += this.diff;
      x2 += this.diff;
    }
    if (this.isHoriLine) {
      y1 += this.diff;
      y2 += this.diff;
    }
    const diff = 1;
    if (!MathUtils.equal(y2, y1, diff) && y2 > y1) {
      y2 = MathUtils.floor(y2);
    } else if (!MathUtils.equal(y2, y1, diff) && y2 < y1) {
      y1 = MathUtils.floor(y1);
    }
    if (!MathUtils.equal(x1, x2, diff) && x2 > x1) {
      x2 = MathUtils.floor(x2);
    } else if (!MathUtils.equal(x1, x2, diff) && x2 < x1) {
      x1 = MathUtils.floor(x1);
    }

    if (needOffsetStart) {
      x1 += pointOffset.start.x;
      y1 += pointOffset.start.y;
    }
    if (needOffsetEnd) {
      x2 -= pointOffset.end.x;
      y2 -= pointOffset.end.y;
    }
    if (properties.stroke?.cap !== StrokeLineCap.Butt) {
      if (showStart && [LinePointType.hollowArrow, LinePointType.solidArrow].includes(startType)) {
        x1 += pointOffset.start.x;
        y1 += pointOffset.start.y;
      }
      if (showEnd && [LinePointType.hollowArrow, LinePointType.solidArrow].includes(endType)) {
        x2 -= pointOffset.end.x;
        y2 -= pointOffset.end.y;
      }
    }
    const svgStyle: React.CSSProperties = {
      overflow: 'visible',
      filter: shadow && shadow.disabled ? undefined : filter,
      transition: comp.getTransition(),
      pointerEvents: 'none',
      opacity,
    };

    if (isPreview) {
      Object.assign(svgStyle, {
        width: vertical ? 1 : '100%',
        height: horizontal ? 1 : '100%',
      });
    }
    const markerConfig = {
      line,
      strokeInfo,
      compID: id,
      scale: MathUtils.value(globalScale, 1),
    };

    const showStartArrow = !line?.disabled && !!line?.startArrow;
    const showEndArrow = !line?.disabled && !!line?.endArrow;
    // 针对透明箭头做处理
    const dealArrowList = ['hollowTriangle', 'hollowDot', 'hollowCube', 'hollowDiamond'];
    const startArrow = markerConfig?.line?.startPointType!;
    const endArrow = markerConfig?.line?.endPointType!;
    // 如果【开始】端点存在，并且是透明端点
    if (showStartArrow && dealArrowList.includes(startArrow)) {
      const { ax, ay } = this.getArrowOffsetPosition(startArrow, strokeWidth!, angle);
      x1 = x1 + ax;
      y1 = y1 + ay;
    }
    // 如果【结束】端点存在，并且是透明端点
    if (showEndArrow && dealArrowList.includes(endArrow)) {
      const { ax, ay } = this.getArrowOffsetPosition(endArrow, strokeWidth!, angle);
      x2 = x2 - ax;
      y2 = y2 - ay;
    }

    return (
      <div className="lib-comp-line" style={{ width, height }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          className={classnames('', { vertical, horizontal })}
          width={width}
          height={height}
          style={svgStyle}
          shapeRendering="geometricPrecision"
        >
          <line
            className={classnames('line-shower', { hover: this.props.hover, active: isParentActivated })}
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            {...strokeInfo}
            strokeDashoffset={strokeInfo.strokeDashoffset && !isChildOfPath ? strokeInfo.strokeDashoffset + 0.5 : 0}
            style={{
              transition: comp.getTransition(),
              pointerEvents,
            }}
            markerStart={showStartArrow ? `url('#${id}-start-marker')` : undefined}
            markerEnd={showEndArrow ? `url('#${id}-end-marker')` : undefined}
            shapeRendering="geometricPrecision"
          />
          {!isChildOfPath && renderMarker(markerConfig)}
          <line
            className={classnames('line-induction', { active: isParentActivated })}
            style={{
              pointerEvents,
              strokeWidth: senseWidth,
            }}
            x1={startPoint.x + (vertical ? 0.5 : 0)}
            y1={startPoint.y + (horizontal ? 0.5 : 0)}
            x2={endPoint.x + (vertical ? 0.5 : 0)}
            y2={endPoint.y + (horizontal ? 0.5 : 0)}
            shapeRendering="geometricPrecision"
          />
        </svg>
        {!isChildOfPath && this.renderTextFragment(text, properties, opacity)}
      </div>
    );
  }
}
