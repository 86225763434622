import * as React from 'react';
import classnames from 'classnames';
import * as moment from 'moment';

import { IColumnType, IPoint, ITableColumn, ITableColumnAlign, ITableData, ICompareRefs } from '../../../../../models';
import { RoleList, styleColor, styleSpace, TimeFormatType } from '../../../../../constants';

import Icon from '../../../../Icon';
import MatchStrSpan from '../../../../MatchStrSpan';

import './index.scss';

interface ITableCellProps {
  col: ITableColumn;
  data: ITableData;
  cellStyle?: React.CSSProperties;
  isFirstColumn: boolean;
  order: number;
  isHoverOnRow: boolean;
  activeMenu: boolean;
  searchKey: string;
  theme: 'blue' | 'red';

  onFormat?(data: ITableData, column: ITableColumn, ref?: ICompareRefs): React.ReactChild;
  onContext(data: ITableData, position: IPoint, fromOperateBtn?: boolean): void;
  setTip(text: string, style: React.CSSProperties | null): void;
}

const TableCell: React.FC<ITableCellProps> = (props: ITableCellProps) => {
  const {
    col,
    data,
    cellStyle,
    isFirstColumn,
    order,
    isHoverOnRow,
    activeMenu,
    searchKey,
    theme,
    onFormat,
    onContext,
    setTip,
  } = props;

  const compareRef = React.useRef<HTMLSpanElement>(null);
  const contentRef = React.useRef<HTMLSpanElement>(null);

  const onContextMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onMouseEnterComputeTip = (e: React.MouseEvent) => {
    {
      const td = e.currentTarget.getBoundingClientRect();
      const rect = contentRef.current?.getBoundingClientRect();
      const target = compareRef.current?.getBoundingClientRect();
      if (rect && target) {
        if (target.width > rect.width) {
          setTip(contentRef.current!.innerText, {
            display: 'block',
            left: rect.x,
            top: td.y + td.height,
            maxWidth: window.innerWidth - td.x - styleSpace.space48,
          });
        }
      }
    }
  };
  const onMouseLeaveComputeTip = () => {
    setTip('', { display: 'none' });
  };

  const getTimeFormatCol = () => {
    if (data[col.dataIndex]) {
      if (col.timeFormat && col.timeFormat === TimeFormatType.format_from_now) {
        return moment(data[col.dataIndex]).fromNow();
      }
      return moment(data[col.dataIndex]).format(col.timeFormat || TimeFormatType.format_YYYY_MM_DD);
    }
    return '';
  };

  // 扩展的格式化
  if (onFormat && onFormat(data, col)) {
    return (
      <td
        className={classnames(`c-table-column c-table-column-${col.align || ITableColumnAlign.Left}`)}
        style={{ ...cellStyle, ...col.style }}
        onMouseEnter={onMouseEnterComputeTip}
        onMouseLeave={onMouseLeaveComputeTip}
        onMouseMove={onMouseEnterComputeTip}
      >
        {!col.showOnHover
          ? onFormat(data, col, { contentRef: contentRef, compareRef: compareRef })
          : isHoverOnRow && onFormat(data, col, { contentRef: contentRef, compareRef: compareRef })}
      </td>
    );
  }

  switch (col.type) {
    case IColumnType.Role:
      return (
        <td
          className={classnames(`c-table-column c-table-column-${col.align || ITableColumnAlign.Left}`, {
            'c-table-column-first': isFirstColumn,
          })}
          style={cellStyle}
          onMouseEnter={onMouseEnterComputeTip}
          onMouseLeave={onMouseLeaveComputeTip}
          onMouseMove={onMouseEnterComputeTip}
        >
          <span className="c-table-td-value" ref={contentRef}>
            {RoleList.find((role) => role.key === data[col.dataIndex])?.value || data[col.dataIndex]}
          </span>
          <span className="compare" ref={compareRef}>
            {RoleList.find((role) => role.key === data[col.dataIndex])?.value || data[col.dataIndex]}
          </span>
        </td>
      );
    case IColumnType.Order:
      return (
        <td
          className={classnames(`c-table-column c-table-column-${col.align || ITableColumnAlign.Left}`, {
            'c-table-column-first': isFirstColumn,
          })}
          style={cellStyle}
        >
          {order}
        </td>
      );
    case IColumnType.Time:
      return (
        <td
          className={`c-table-column c-table-column-${col.align || ITableColumnAlign.Left}`}
          style={cellStyle}
          onMouseEnter={onMouseEnterComputeTip}
          onMouseLeave={onMouseLeaveComputeTip}
          onMouseMove={onMouseEnterComputeTip}
        >
          <span className="c-table-td-value" ref={contentRef}>
            {getTimeFormatCol()}
          </span>
          <span className="compare" ref={compareRef}>
            {getTimeFormatCol()}
          </span>
        </td>
      );
    case IColumnType.Operate:
      return (
        <td
          className={`c-table-column operate c-table-column-${col.align || ITableColumnAlign.Left}`}
          style={cellStyle}
        >
          <Icon
            hidden={!isHoverOnRow && !activeMenu}
            cls={`${col.icon || 'ps_more'} more-btn`}
            color={styleColor.colorIconGrey}
            clickArea={{ width: styleSpace.space24, height: styleSpace.space24 }}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              const bounds = e.currentTarget.getBoundingClientRect();
              onContext(data, { x: bounds.left + bounds.width, y: bounds.top + bounds.height }, true);
            }}
            onContextMenu={onContextMenu}
          />
        </td>
      );
    default:
      return (
        <td
          className={classnames(`c-table-column c-table-column-${col.align || ITableColumnAlign.Left}`, {
            'c-table-column-first': isFirstColumn,
          })}
          style={cellStyle}
          onMouseEnter={onMouseEnterComputeTip}
          onMouseLeave={onMouseLeaveComputeTip}
          onMouseMove={onMouseEnterComputeTip}
        >
          <span className="c-table-td-value" ref={contentRef}>
            {col.searchHighlight && searchKey ? (
              <MatchStrSpan
                theme={theme === 'red' ? undefined : theme}
                str={`${data[col.dataIndex] || ''}`}
                targetStr={searchKey}
              />
            ) : (
              data[col.dataIndex]
            )}
          </span>
          <span className="compare" ref={compareRef}>
            {data[col.dataIndex]}
          </span>
        </td>
      );
  }
};

export default TableCell;
