import axios, { AxiosRequestConfig, CancelToken } from 'axios';

import { CustomErrorWithPayload } from '@fbs/common/models/error';
import { isDev } from '@utils/envUtils';

export const idocBaseUrl = isDev ? 'http://192.168.0.152:3007/v1' : `${window.apis?.iDocDomain}/api/v1`;

function fitConfig(config?: AxiosRequestConfig): AxiosRequestConfig | undefined {
  if (config) {
    if (config.withCredentials === undefined) {
      return { ...config, withCredentials: true };
    }
    // return config;
  }
  return config;
  // return {
  //   withCredentials: true
  // }
}

/**
 * 用于适配建行请求方式变更
 */
export function setAxiosMethodProxyInterceptor() {
  axios.interceptors.request.use(function (config) {
    // record origin method
    config.headers['OriginMethod'] = config.method;

    // transform method
    let method = config.method;
    if (method && /put|patch|delete/.test(method)) {
      method = 'post';
    }
    // if (method && /head|options/.test(method)) {
    //   method = 'get';
    // }
    config.method = method;

    return config;
  });
}

export function cancelToken(): Promise<{ cancel: () => void; token?: CancelToken }> {
  return new Promise<{ cancel: () => void; token?: CancelToken }>((resolve, reject) => {
    try {
      const response: { cancel: () => void; token?: CancelToken } = { cancel() {} };
      const token = new axios.CancelToken((cb) => {
        response.cancel = cb ?? (() => {});
      });
      response.token = token;
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export async function put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
  const res = await axios.put<{
    code: number;
    payload: T;
    message: string;
  }>(url, data, fitConfig(config));
  if (res.data.code === 0) {
    return res.data.payload;
  }
  window.debug && res.data.code === 202 && console.log('put', url);
  throw new CustomErrorWithPayload(res.data.message, res.data.code, res.data.payload);
}

export async function post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
  const res = await axios.post<{
    code: number;
    payload: T;
    message: string;
  }>(url, data, fitConfig(config));
  if (res.data.code === 0) {
    return res.data.payload;
  }
  window.debug && res.data.code === 202 && console.log('post', url);
  throw new CustomErrorWithPayload(res.data.message, res.data.code, res.data.payload);
}

export async function get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
  const res = await axios.get<{
    code: number;
    payload: T;
    message: string;
  }>(url, fitConfig(config));
  if (res.data.code === 0) {
    return res.data.payload;
  }
  window.debug && res.data.code === 202 && console.log('get', url);
  throw new CustomErrorWithPayload(res.data.message, res.data.code, res.data.payload);
}

export async function patch<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
  const res = await axios.patch<{
    code: number;
    payload: T;
    message: string;
  }>(url, data, fitConfig(config));
  if (res.data.code === 0) {
    return res.data.payload;
  }
  window.debug && res.data.code === 202 && console.log('patch', url);
  throw new CustomErrorWithPayload(res.data.message, res.data.code, res.data.payload);
}

export async function axDelete(url: string, config?: AxiosRequestConfig): Promise<any> {
  const res = await axios.delete(url, fitConfig(config));
  if (res.data.code === 0) {
    return res.data.payload;
  }
  window.debug && res.data.code === 202 && console.log('delete', url);
  throw new CustomErrorWithPayload(res.data.message, res.data.code, res.data.payload);
}
