import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// 注释左
export default {
  ...flowBase,
  name: i18n('resource.flow.leftBracket'),
  type: 'leftBracket',
  thumb: {
    spriteIconClass: SpriteThumb.LeftBracket.className,
    dragPosition: SpriteThumb.LeftBracket.position,
  },
  template: getShapeData(
    i18n('resource.flow.leftBracket'),
    {
      width: 10,
      lockedRatio: false,
      height: 60,
    },
    {
      data: [
        {
          point: {
            x: 10,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 10,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
      closed: false,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: false, closed: false }),
    };
  },
};
