import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// '条带'
export default {
  ...flowBase,
  name: i18n('resource.flow.punchedTape'),
  type: 'punchedTape',
  thumb: {
    spriteIconClass: SpriteThumb.PunchedTape.className,
    dragPosition: SpriteThumb.PunchedTape.position,
  },
  template: getShapeData(
    i18n('resource.flow.punchedTape'),
    {
      width: 100,
      height: 60,
    },
    {
      data: [
        {
          point: {
            x: 100,
            y: 50,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: -17,
            y: -13,
          },
        },
        {
          point: {
            x: 50,
            y: 50,
          },
          handleIn: {
            x: 17,
            y: -13,
          },
          handleOut: {
            x: -17,
            y: 13,
          },
        },
        {
          point: {
            x: 0,
            y: 50,
          },
          handleIn: {
            x: 17,
            y: 13,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 10,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 50,
            y: 10,
          },
          handleIn: {
            x: -17,
            y: 13,
          },
          handleOut: {
            x: 17,
            y: -13,
          },
        },
        {
          point: {
            x: 100,
            y: 10,
          },
          handleIn: {
            x: -17,
            y: -13,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
      closed: true,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
