import i18n from '@/i18n';
import { IDateInfo } from '.';
import { DatePickerConfig, DeviceType } from './config';

//星期
export const WEEK_NAMES = [
  i18n('resource.components.datePickerInfo.Sun'),
  i18n('resource.components.datePickerInfo.Mon'),
  i18n('resource.components.datePickerInfo.Tues'),
  i18n('resource.components.datePickerInfo.Wed'),
  i18n('resource.components.datePickerInfo.Thur'),
  i18n('resource.components.datePickerInfo.Fri'),
  i18n('resource.components.datePickerInfo.Sat'),
];
export const EN_WEEK_NAMES = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

//年月相关参数
export const LINE = ['1', '2', '3', '4', '5', '6'];
export const LINES = ['1', '2', '3', '4', '5', '6', '7'];
export const LEAP_YEAR = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const COMMON_YEAR = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

//获取月份
export const getMonth = (date: Date): number => {
  return date.getMonth() + 1;
};

//获取年份
export const getFullYear = (date: Date): number => {
  return date.getFullYear();
};
//获取日
export const getDate = (date: Date): number => {
  return date.getDate();
};

//获取年月日
export const getCurrentYearMonth = (currentDate: Date) => {
  const month = getMonth(currentDate);
  const year = getFullYear(currentDate);
  const date = getDate(currentDate);
  return { month, year, date };
};

//获取已知年份月份的天数
export const getCurrentMonthDays = (month: number, year: number): number => {
  const monthDays =
    (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? LEAP_YEAR[month - 1] : COMMON_YEAR[month - 1];
  return monthDays;
};

/**
 * 根据此月天数及此月第一天是星期几 以及行列数算出此位置的日期
 * @param line 行数
 * @param column 每行第几个
 * @param year
 * @param month
 * @returns
 */
export const getDayText = (line: number, column: number, year: number, month: number): number | null => {
  const monthDays = getCurrentMonthDays(month, year);
  const weekDay = getWeeksByFirstDay(year, month);
  const number = line * 7 + column - weekDay + 1;
  if (number <= 0 || number > monthDays) {
    return null;
  }

  return number;
};

//根据年月获取date
export const getDateByYearMonth = (year: number, month: number, date?: number): Date => {
  const currentDate = new Date();
  currentDate.setFullYear(year);
  currentDate.setDate(date ?? 1);
  // 月份设置是加减当前月份的天数，不能直接设置月份，用当前时间月份的减去差值
  const diff = month - getMonth(currentDate);
  currentDate.setMonth(currentDate.getMonth() + diff);
  return currentDate;
};

//获取每月第一天是星期几
export const getWeeksByFirstDay = (year: number, month: number): number => {
  const date = getDateByYearMonth(year, month);
  return (date as Date).getDay();
};

/**
 * 日期格式（YYYY-MM-DD YYYY/MM/DD YYYY年MM月DD日）
 * @param dateFormatValue 格式
 * @param date
 * @param showSelectedYear 是否显示年份
 * @param hiddenDate 隐藏月份
 * @param deviceType 设备类型
 * @returns
 */
export const getDateFormat = (
  dateFormatValue: any,
  date?: IDateInfo,
  showSelectedYear?: boolean,
  hiddenDate?: boolean,
  deviceType?: DeviceType,
): string | undefined => {
  window.debug && console.log(deviceType);
  // 原逻辑中网页 不隐藏年份，属性中默认无次字段，
  // 现去掉该逻辑

  if (!date) {
    return;
  }
  // 补0
  const zerofill = (unm: number): string => {
    if (unm < 10) {
      return '0' + unm;
    } else {
      return '' + unm;
    }
  };

  if (DatePickerConfig.property?.getValueNames) {
    const valueNames = DatePickerConfig.property.getValueNames('dateFormat');
    if (valueNames) {
      const item = valueNames.find((t: { value: any; name: string }) => t.value === dateFormatValue) ?? valueNames[0];

      if (item) {
        let result = item.name;
        if (showSelectedYear) {
          result = result.replace(/[\D]?yyyy[\D]?/i, '');
        }
        if (hiddenDate) {
          result = result.replace(/[\\.\\/-]?dd[\D]?/i, '');
        }
        return result
          .replace(/yyyy/i, '' + date.year)
          .replace(/mm/i, zerofill(date.month))
          .replace(/dd/i, zerofill(date.date));
      }
    }
  }

  // switch (dateFormatValue) {
  //   case DateFormat.Normal:
  //     //YYYY-MM-DD
  //     if (languageManager.isEnVersion) {
  //       return (
  //         getNumber(date.month) +
  //         (hiddenDate ? '' : '-' + getNumber(date.date)) +
  //         (!showSelectedYear || deviceType === DeviceType.Web ? '-' + date.year : '')
  //       );
  //     } else {
  //       return (
  //         (!showSelectedYear || deviceType === DeviceType.Web ? date.year + '-' : '') +
  //         getNumber(date.month) +
  //         (!hiddenDate ? '-' + getNumber(date.date) : '')
  //       );
  //     }
  //   case DateFormat.SecondStyle:
  //     //YYYY/MM/DD
  //     if (languageManager.isEnVersion) {
  //       return (
  //         getNumber(date.month) +
  //         (hiddenDate ? '' : '/' + getNumber(date.date)) +
  //         (!showSelectedYear || deviceType === DeviceType.Web ? '/' + date.year : '')
  //       );
  //     } else {
  //       return (
  //         (!showSelectedYear || deviceType === DeviceType.Web ? date.year + '/' : '') +
  //         getNumber(date.month) +
  //         (!hiddenDate ? '/' + getNumber(date.date) : '')
  //       );
  //     }
  //   case DateFormat.ThirdStyle:
  //     //YYYY年MM月DD日
  //     return (
  //       (!showSelectedYear || deviceType === DeviceType.Web
  //         ? date.year + i18n('resource.components.datePickerInfo.year')
  //         : '') +
  //       getNumber(date.month) +
  //       i18n('resource.components.datePickerInfo.month') +
  //       (!hiddenDate ? getNumber(date.date) + i18n('resource.components.datePickerInfo.date') : '')
  //     );
  //   default:
  //     break;
  // }
};
