import { isMockRPD, isIframe } from '@/utils/envUtils';

import { ILinkAction } from '@fbs/rp/models/interactions';

import { Link } from '@helpers/urlHelper';
import store from '@/store';
import { GlobalThunkActions } from '@/store/actions';
import { ToastType } from '@/store/types';
import i18n from '@/i18n';

import { CommandFinishEventHandle, IWorker, ICommand } from '../types';

export default class LinkCommand implements ICommand {
  public onFinish?: CommandFinishEventHandle;

  public readonly _worker: IWorker;
  protected action: ILinkAction;

  constructor(worker: IWorker, action: ILinkAction) {
    this._worker = worker;
    this.action = action;
  }

  termination() {}

  execute = () => {
    const {
      params: { url, openWithSelf, type },
    } = this.action;

    const link = new Link(url, true);
    const model = openWithSelf && !isMockRPD ? '_self' : '_blank';

    const canOpenLink = RP_CONFIGS.isOfflineDemo || RP_CONFIGS.isPrivateDeployment || type === 'axure' || !isIframe;
    if (canOpenLink) {
      link.open(model);
    } else {
      GlobalThunkActions.showToast(ToastType.Info, i18n('alert.denyOpenExternalLink'))(store.dispatch);
    }

    this.onFinish && this.onFinish(this);
  };
}
