import * as _ from 'lodash';
import i18n from '@i18n';

import { enumToArray } from '@/utils/enumUtils';

import { FillType } from '@/fbs/rp/models/properties/fill';
import { PresetDashModel } from '@/fbs/rp/models/properties/stroke';
import { TextAlign } from '@/fbs/rp/models/properties/text';
import { ETimeFormat, ETimePickerType } from '@fbs/rp/models/properties/common';

import { SpriteThumb } from '@consts/spriteIcons';
import * as SystemsColor from '@consts/colors';
import { DefaultStrokeColor, DefaultTextColor, DefaultWhiteFillColor, DefaultStrokeColor1 } from '@consts/colors';

import { PredefinedStates } from '@consts/state';
import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';

import { makeCommonComponent, getDefaultShadow } from '../../helper';
import { IComponentItem } from '../../types';
import { CTimePicker } from '../../constants';
import { SizeMode } from '../../enum';

export const TimePickerConfig: IComponentItem = {
  type: CTimePicker,
  thumb: {
    spriteIconClass: SpriteThumb.TimePicker.className,
    dragPosition: SpriteThumb.TimePicker.position,
  },
  name: i18n('resource.components.timePicker'),
  sizeMode: SizeMode.custom,
  predefinedStates: [PredefinedStates.disabled, PredefinedStates.hover, PredefinedStates.pressed],
  property: {
    disabledFlip: true,
    getValueNames: (key: string) => {
      if (key === 'model') {
        return enumToArray(ETimePickerType).map((item) => ({
          value: item,
          name:
            item === ETimePickerType.Normal
              ? i18n('property.component.timePickerModelNormal')
              : i18n('property.component.timePickerModelRange'),
        }));
      }
      if (key === 'format') {
        return enumToArray(ETimeFormat).map((item) => {
          return {
            value: item,
            name: item.toLowerCase(),
          };
        });
      }
      return undefined;
    },
  },
  editor: {
    onPropertyUpdate: (comp, propertyName, newValue): ArtboardPatches | null => {
      if (propertyName !== 'iconColor') {
        return null;
      }
      const {
        id,
        properties: { iconColor, iconSize },
      } = comp;

      const result: ArtboardPatches = {
        do: {
          [id]: [],
        },
        undo: {
          [id]: [],
        },
      };
      let disabledChange = newValue.disabled !== iconColor?.disabled;
      let hidden = newValue?.disabled;
      if (!_.isEqual(newValue.value, iconColor?.value) && iconColor?.disabled) {
        const path = comp.getCurrentPropertiesPath('/properties/iconColor/disabled');
        result.do[id].push(Ops.replace(path, false));
        result.undo[id].push(Ops.replace(path, true));
        disabledChange = true;
        hidden = false;
      }
      if (disabledChange) {
        const path = comp.getCurrentPropertiesPath('/properties/iconSize/hidden');
        result.do[id].push(Ops.replace(path, hidden));
        result.undo[id].push(Ops.replace(path, iconSize?.hidden));
      }

      return result;
    },
  },
  getDefaultData() {
    return {
      properties: {
        fill: {
          type: FillType.solid,
          color: DefaultWhiteFillColor,
          disabled: false,
        },
        stroke: {
          disabled: false,
          thickness: 1,
          dashModel: PresetDashModel.solid,
          color: DefaultStrokeColor1,
        },
        radius: {
          disabled: false,
          topLeft: 2,
          topRight: 2,
          bottomLeft: 2,
          bottomRight: 2,
          isPercent: false,
        },
        border: {
          disabled: true,
          top: true,
          left: true,
          right: true,
          bottom: true,
        },
        textStyle: {
          textAlign: TextAlign.left,
          color: DefaultTextColor,
          fontSize: 14,
          fontFamily: 'Microsoft YaHei',
          fontStyle: { underline: false, bold: false, strike: false, italic: false },
          disabled: false,
        },
        shadow: getDefaultShadow(),
        iconColor: {
          prop: 'color',
          name: i18n('property.propertyNames.iconColor'),
          value: DefaultTextColor,
          disabled: false,
          allowDisabled: true,
        },
        iconSize: {
          prop: 'number',
          name: i18n('property.propertyNames.iconSize'),
          value: {
            min: 10,
            max: 100,
            step: 1,
            value: 14,
          },
        },
        placehoder: {
          name: i18n('property.propertyNames.placeholder'),
          prop: 'string',
          value: i18n('resource.componentsText.timePickerTips'),
        },
        placeholderColor: {
          name: i18n('property.propertyNames.placeholderColor'),
          prop: 'color',
          value: SystemsColor.GrayColor,
        },
        model: {
          name: i18n('property.propertyNames.timePickerType'),
          prop: 'enum',
          value: ETimePickerType.Normal,
        },
        format: {
          name: i18n('property.propertyNames.timeFormat'),
          prop: 'enum',
          value: ETimeFormat.HH_mm,
        },
        padding: {
          disabled: false,
          top: 0,
          left: 6,
          right: 6,
          bottom: 0,
        },
      },
    };
  },
};

export function makeTimePicker(id: string) {
  return makeCommonComponent(id, CTimePicker, {
    ...TimePickerConfig.getDefaultData?.(),
    size: { width: 150, height: 30, lockedRatio: false },
    text: '',
    states: {
      [PredefinedStates.disabled]: {
        enabled: false,
        properties: {
          textStyle: {
            color: { ...DefaultTextColor, a: 0.3 },
          },
          stroke: {
            color: { ...DefaultStrokeColor, a: 0.3 },
          },
        },
      },
    },
  });
}
