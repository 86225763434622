import * as React from 'react';
import classnames from 'classnames';

import Icon from '../../../Icon';
import Checkbox from '../../../Checkbox';
import CheckItem from '../CheckItem';

import { CheckType, TreeTransferKeys } from '../../../../constants/TreeTransfer';
import { IItemProps } from '../../../../models';
import i18n from '../../../../i18n';

import './index.scss';

interface ILastGroupProps {
  checkedItems: string[];
  checkedGroups: string[];
  lastKey?: TreeTransferKeys;
  lastItems: IItemProps[];
  lastGroups: IItemProps[];
  hideGroupCheck: boolean;
  maxCount: number;

  onCheckItem(ids: string[]): void;
  onCheckGroup?(ids: string[]): void;
  onCheckDisable?(id: string): void;
}

const LastGroup: React.FC<ILastGroupProps> = (props: ILastGroupProps) => {
  const {
    checkedItems,
    checkedGroups,
    lastItems,
    lastGroups,
    hideGroupCheck,
    maxCount,
    onCheckItem,
    onCheckGroup,
    onCheckDisable,
  } = props;
  const [expand, setExpand] = React.useState(false);
  const [showTips, setShowTips] = React.useState(false);
  const lastCount = lastItems.length + lastGroups.length;

  const getGroupCheckType = (): CheckType => {
    let lastItemChecked = lastItems?.some((item) => checkedItems.includes(item.id));
    let lastGroupChecked = lastGroups?.some((item) => checkedGroups.includes(item.id));
    let indeterminate = lastItemChecked || lastGroupChecked;

    let lastItemAll = lastItems?.every((item) => checkedItems.includes(item.id));
    let lastGroupAll = lastGroups?.every((item) => checkedGroups.includes(item.id));
    let all = lastItemAll && lastGroupAll;

    if (all) {
      return CheckType.All;
    }

    if (indeterminate) {
      return CheckType.Indeterminate;
    }
    return CheckType.None;
  };

  const handleCheckGroup = (currentCheckType: CheckType) => {
    const lastItemIds = lastItems?.map((item) => item.id) || [];
    const checkedItemExcludeLast = checkedItems.filter((item) => !lastItemIds.includes(item));

    const lastGroupIds = lastGroups?.map((item) => item.id) || [];
    const checkedGroupExcludeLast = checkedGroups.filter((item) => !lastGroupIds.includes(item));
    switch (currentCheckType) {
      case CheckType.All:
        const lastItemDisabledArr = lastItems?.filter((item) => item.disabled) || [];
        const lastItemDisabledIDs = lastItemDisabledArr.map((item) => item.id);

        if (!!lastItemDisabledIDs.length) {
          const checkedItemDisabled = lastItemDisabledIDs.filter((item) => checkedItems.includes(item));
          onCheckItem([...new Set([...checkedItemDisabled, ...checkedItemExcludeLast])]);
        } else {
          onCheckItem(checkedItemExcludeLast);
        }

        const lastGroupDisabledArr = lastGroups?.filter((item) => item.disabled) || [];
        const lastGroupDisabledIDs = lastGroupDisabledArr.map((item) => item.id);

        if (!!lastGroupDisabledIDs.length) {
          const checkedGroupDisabled = lastGroupDisabledIDs.filter((item) => checkedGroups.includes(item));
          onCheckGroup && onCheckGroup([...new Set([...checkedGroupDisabled, ...checkedGroupExcludeLast])]);
        } else {
          onCheckGroup && onCheckGroup(checkedGroupExcludeLast);
        }
        break;
      case CheckType.Indeterminate:
      case CheckType.None: {
        onCheckItem([...checkedItemExcludeLast, ...lastItemIds]);
        onCheckGroup && onCheckGroup([...checkedGroupExcludeLast, ...lastGroupIds]);
        break;
      }
      default:
        break;
    }
  };

  const renderGroup = () => {
    const checkType: CheckType = getGroupCheckType();
    return (
      <div className="check-group-box">
        <span className="expand-container">
          <Icon cls={expand ? 'tag_downarrow' : 'tag_rightarrow'} onClick={(e) => setExpand(!expand)} />
        </span>
        {!hideGroupCheck && (
          <Checkbox
            checked={checkType !== CheckType.None}
            indeterminate={checkType === CheckType.Indeterminate}
            disabled={!lastCount}
            onChange={() => handleCheckGroup(checkType)}
          />
        )}
        <span className={classnames('group-title-wrapper')}>
          <span className="group-name">{i18n('checkSelect.last')}</span>
          {!!lastCount && <span className="group-child-count">({lastCount})</span>}
        </span>
      </div>
    );
  };

  const getItemChecked = (data: IItemProps): boolean => {
    if (data.isGroup) {
      return checkedGroups.includes(data.id);
    }
    return checkedItems.includes(data.id);
  };

  const renderLast = () => {
    return (
      <div className="check-items">
        {lastGroups.map((item: IItemProps) => (
          <CheckItem
            key={item.id}
            data={item}
            checked={getItemChecked(item)}
            onCheck={(id: string) => {
              if (checkedGroups.includes(id)) {
                onCheckGroup && onCheckGroup(checkedGroups.filter((item) => item !== id));
              } else {
                if (hideGroupCheck && checkedItems.length + checkedGroups.length >= maxCount) {
                  setShowTips(true);
                  setTimeout(() => {
                    setShowTips(false);
                  }, 2000);
                  return;
                }
                onCheckGroup && onCheckGroup([...checkedGroups, id]);
              }
            }}
            onCheckDisable={onCheckDisable}
          />
        ))}
        {lastItems.map((item: IItemProps) => (
          <CheckItem
            key={item.id}
            data={item}
            checked={getItemChecked(item)}
            onCheck={(id: string) => {
              if (checkedItems.includes(id)) {
                onCheckItem(checkedItems.filter((item) => item != id));
              } else {
                if (hideGroupCheck && checkedItems.length + checkedGroups.length >= maxCount) {
                  setShowTips(true);
                  setTimeout(() => {
                    setShowTips(false);
                  }, 2000);
                  return;
                }
                onCheckItem([...checkedItems, id]);
              }
            }}
            onCheckDisable={onCheckDisable}
          />
        ))}
      </div>
    );
  };

  const renderReachMaxTips = () => {
    return (
      <div className={'reach-max-tip'}>
        <Icon cls="tag_exclamation" color="#F8AF1D" size={16} />
        <span>{i18n('checkSelect.maximumOfPeople')}</span>
      </div>
    );
  };

  return (
    <div className="check-group">
      {renderGroup()}
      {expand && !!lastCount && renderLast()}
      {showTips && renderReachMaxTips()}
    </div>
  );
};

export default LastGroup;
