export interface ISize {
  width: number;
  height: number;
}

export type SizeKey = 'width' | 'height';

export interface IPosition {
  x: number;
  y: number;
}

export interface IRect {
  left: number;
  top: number;
  width: number;
  height: number;
}

/**
 * 坐标区域
 * {
 *  left: number,
 *  top: number,
 *  width: number, 表示距离父左边界的距离
 *  height: number, 表示距离父顶边界的距离
 *  right: number = left + width,
 *  bottom: number = top + height,
 * }
 */
export interface IBounds {
  left: number;
  top: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
}

export interface IMarginBounds {
  left: number;
  top: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
}

/**
 * 坐标系中的点
 * 目前坐标原点均为父组件左上角
 */
export interface IPoint {
  x: number;
  y: number;
}

/**
 * 描述一条直线，k为斜率
 * 当斜率不为无穷大时，b为与y轴交点的y坐标
 * 当斜率为无穷大时，b为与x轴交点的x坐标
 */
export interface ILine {
  k: number;
  b: number;
}

/**
 * 矩形四个方向的偏移值
 */
export interface IBoundsOffset {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

export function initEmptyBoundsOffset() {
  return { left: 0, top: 0, right: 0, bottom: 0 };
}

export function identityBoundsOffset() {
  return {
    left: Number.POSITIVE_INFINITY,
    top: Number.POSITIVE_INFINITY,
    right: Number.NEGATIVE_INFINITY,
    bottom: Number.NEGATIVE_INFINITY,
  };
}

export interface ISizeOffset {
  width: number;
  height: number;
}

export interface IBoundsOffsetRotate {
  left: number;
  top: number;
  right: number;
  bottom: number;
  rotate: number;
}

export type Orientation = 'portrait' | 'landscape';

/**
 * 翻转尺寸
 * @param size
 */
export function revertSize(size: ISize): ISize {
  return { width: size.height, height: size.width };
}
