import * as React from 'react';

interface IProps {
  renderUI?(): React.ReactNode;
  shouldUpdate?(): boolean;
  [propName: string]: any;
}

class CacheComponentWrapper extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  shouldComponentUpdate() {
    const { shouldUpdate } = this.props;
    return shouldUpdate ? shouldUpdate() : true;
  }

  render() {
    return this.props.renderUI?.() || null;
  }
}

export default CacheComponentWrapper;
