import * as React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';

import { parseColorToString } from '@utils/graphicsUtils';

import { IconValue, ITreeDataItem } from '@fbs/rp/models/value';

import { DefaultIconColor } from '@consts/colors';

import { ITreeItem, TreeCheckboxState } from '@helpers/treeCompHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { transBlankChart } from '@utils/textUtils';
import { hasInteraction } from '@helpers/interactionHelper';

import { UIContainerComponent, UITreeItemComponent } from '@editor/comps';

import './index.scss';

export interface ITreeItemProps {
  item: ITreeItem;
  comp: UITreeItemComponent;
  paddingLeft: number;
  paddingRight: number;
  lineHeight: number;
  level: number;
  intention: number;
  checkedFill: React.CSSProperties;
  showExpandIcon: boolean;
  showNodeIcon: boolean;
  checkedIconColor: string;
  showSelectBox: boolean;
  isPreview: boolean;
  horizontalPaddingStyle?: React.CSSProperties;
  children?: any;
  showInteract: boolean;

  onExpandClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onCheckBoxClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseDown?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseUp?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemDoubleClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemContext?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseLeave?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseEnter?: (node: ITreeDataItem, e: React.MouseEvent) => void;
}

const TreeItem: React.FC<ITreeItemProps> = (props: ITreeItemProps) => {
  const handleExpandClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { onExpandClick, showExpandIcon } = props;
    if (!showExpandIcon) {
      return;
    }
    const node = props.item.data;
    onExpandClick && onExpandClick(node, e);
  };

  const handleCheckboxClick = (e: React.MouseEvent) => {
    const { onCheckBoxClick, showSelectBox, item } = props;
    if (!showSelectBox) {
      return;
    }
    onCheckBoxClick && onCheckBoxClick(item.data, e);
  };

  const handleItemClick = (e: React.MouseEvent) => {
    const { onItemClick, item } = props;
    onItemClick && onItemClick(item.data, e);
  };

  const handleItemMouseDown = (e: React.MouseEvent) => {
    const { onItemMouseDown, item } = props;
    onItemMouseDown && onItemMouseDown(item.data, e);
  };

  const handleItemDoubleClick = (e: React.MouseEvent) => {
    const { onItemDoubleClick, item } = props;
    onItemDoubleClick && onItemDoubleClick(item.data, e);
  };

  const handleItemMouseUp = (e: React.MouseEvent) => {
    const { onItemMouseUp, item } = props;
    onItemMouseUp && onItemMouseUp(item.data, e);
  };

  const handleItemMouseEnter = (e: React.MouseEvent) => {
    const { onItemMouseEnter, item } = props;
    onItemMouseEnter && onItemMouseEnter(item.data, e);
  };

  const handleItemMouseLeave = (e: React.MouseEvent) => {
    const { onItemMouseLeave, item } = props;
    onItemMouseLeave && onItemMouseLeave(item.data, e);
  };

  const handleItemContext = (e: React.MouseEvent) => {
    const { onItemContext, item } = props;
    onItemContext && onItemContext(item.data, e);
  };

  const renderExtendIcon = () => {
    const { showExpandIcon, item, comp } = props;

    if (!showExpandIcon) {
      return null;
    }
    const expandComp = comp.expandComp;
    if (!expandComp) {
      return null;
    }
    const { size, value, properties } = expandComp;
    const icon = value as IconValue;
    const fontSize = Math.min(size.width, size.height);
    return (
      <div
        className="comp-tree-expand"
        style={{
          width: size.width,
          minWidth: size.width,
          height: size.height,
          fontSize,
          fontFamily: icon.fontName,
          lineHeight: `${fontSize}px`,
          color: parseColorToString(properties.icon?.color || DefaultIconColor),
        }}
        onClick={handleExpandClick}
      >
        {expandComp && item.children?.length && String.fromCharCode(icon.iconCode)}
      </div>
    );
  };

  const renderCheckBox = () => {
    const { showSelectBox, comp, item } = props;
    const checkComp = comp.checkBoxComp;

    if (!showSelectBox) {
      return null;
    }

    const checkboxIcon = (checkComp as UIContainerComponent).components[0];
    //--Fixed：用组件切换状态表示： 全选  半选 不选
    const { size } = checkComp;
    // const checked=item.checked
    // const iconValue = checkboxIcon.value as IconValue;
    const {
      size: { width, height },
      properties: { icon },
    } = checkboxIcon;
    //  {checked===TreeCheckboxState.Checked ? String.fromCharCode(iconValue.iconCode):checked===TreeCheckboxState.Half?String.fromCharCode(60035):null}
    return (
      <div
        className={classnames('comp-tree-checkbox', {
          'icon mockplus_rp mockplus_rp_icon_checkBox_Select_rp': item.checked === TreeCheckboxState.Checked,
          'icon mockplus_rp mockplus_rp_icon_checkBox_SemiSelection_rp': item.checked === TreeCheckboxState.Half,
          'icon mockplus_rp mockplus_rp_icon_checkBox_cancel_rp': item.checked === TreeCheckboxState.UnChecked,
        })}
        style={{
          width: size.width,
          height: size.height,
          minWidth: size.width,
          minHeight: size.height,
          // ...StyleHelper.createCSSStyleParser({ stroke }).getStrokeStyle(),
          color: parseColorToString(icon?.color || DefaultIconColor),
          // fontFamily: iconValue.fontName,
          lineHeight: `${size.height}px`,
          fontSize: Math.min(width, height),
        }}
        onClick={handleCheckboxClick}
      ></div>
    );
  };

  const renderNodeIcon = () => {
    const { comp, showNodeIcon, checkedIconColor } = props;
    if (!showNodeIcon) {
      return;
    }

    const { selected } = comp;
    const nodeComp = comp.nodeComp;
    if (!nodeComp) {
      return null;
    }
    const { size, value, properties } = nodeComp;
    const icon = value as IconValue;
    const fontSize = Math.min(size.width, size.height);
    let color = parseColorToString(properties.icon?.color || DefaultIconColor);
    if (selected) {
      color = checkedIconColor;
    }
    return (
      <div
        className="comp-tree-node"
        style={{
          width: size.width,
          height: size.height,
          fontSize,
          fontFamily: icon.fontName,
          lineHeight: `${fontSize}px`,
          color,
        }}
      >
        {String.fromCharCode(icon.iconCode)}
      </div>
    );
  };

  const renderText = () => {
    const { comp, paddingRight } = props;
    const textComp = comp.textComp;
    const { size: textSize, position, properties, value } = textComp;
    const parser = StyleHelper.createCSSStyleParser({});
    const style = _.memoize(() => {
      const { size, style } = parser.getTextStyleData(textSize, properties.textStyle);
      return {
        ...size,
        ...style,
        top: position.y,
        marginRight: paddingRight,
      };
    })();

    return (
      <div className="comp-tree-text" style={style}>
        {transBlankChart(`${value}`)}
      </div>
    );
  };

  const renderSelectBack = () => {
    const { comp, lineHeight, paddingRight, paddingLeft, checkedFill } = props;
    if (!comp.selected) {
      return null;
    }
    return (
      <div
        className="item-selected-background"
        style={{
          width: `calc(100% + ${paddingLeft + paddingRight}px)`,
          height: lineHeight,
          left: -paddingLeft,
          ...checkedFill,
        }}
      />
    );
  };

  const renderMain = () => {
    const { lineHeight, intention, level, isPreview, comp, showInteract, horizontalPaddingStyle } = props;
    const existInteraction = hasInteraction(comp);
    return (
      <div
        className={classnames('item-main', {
          'item-interaction-flag': !isPreview && existInteraction && showInteract,
          'item-hot-area': isPreview && existInteraction,
          'component-cursor-pointer': isPreview,
        })}
        style={{ lineHeight: `${lineHeight}px`, height: lineHeight, ...horizontalPaddingStyle }}
        onClick={handleItemClick}
        onMouseDown={handleItemMouseDown}
        onDoubleClick={handleItemDoubleClick}
        onMouseUp={handleItemMouseUp}
        onMouseEnter={handleItemMouseEnter}
        onMouseLeave={handleItemMouseLeave}
        onContextMenu={handleItemContext}
      >
        {renderSelectBack()}
        <div className="item-main-inner" style={{ marginLeft: intention * level }}>
          {renderExtendIcon()}
          {renderCheckBox()}
          {renderNodeIcon()}
          {renderText()}
        </div>
      </div>
    );
  };

  const render = () => {
    return (
      <div className="lib-comp-tree-item">
        {renderMain()}
        <div className="item-child">{props.children}</div>
      </div>
    );
  };
  return render();
};

export default React.memo(TreeItem);
