import { HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import { TextAlign } from '@fbs/rp/models/properties/text';
import { DefaultCollapseIconType } from '@fbs/rp/models/properties/tree';
import { IComponentData } from '@fbs/rp/models/component';
import { PropertyValue } from '@fbs/rp/models/property';
import { ArtboardOperations, ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { ITreeData } from '@fbs/rp/models/value';
import { FillType } from '@fbs/rp/models/properties/fill';

import i18n from '@i18n';

import { UIComponent, UIHorizontalMenuComponent } from '@editor/comps';
import { defaultExpandIcon, defaultLineHeight, getDefaultHorizontalMenuData } from '@/consts/defaultData/menu';
import { SpriteThumb } from '@consts/spriteIcons';
import * as SystemsColor from '@consts/colors';

import {
  ITreeArrData,
  mapChildsIDByCompID,
  TreeItemAlias,
  TreeItemColumn,
  treeToArr,
  arrToTree,
} from '@helpers/treeCompHelper';
import { getNewID } from '@helpers/idHelper';

import { IComponentItem } from '../../types';
import { CHorizontalMenu, CTreeItem, CPureText } from '../../constants';
import { makeCommonComponent } from '../../helper';
import { makeIcon } from '../../basic/Icon';

function onHorizontalMenuPropertyUpdate(
  container: UIComponent,
  propertyName: string,
  newValue: PropertyValue,
): ArtboardPatches {
  //切换默认的图标样式，需要更新所有的compItem 的 column === TreeItemColumn.Expand 的值 及修正treeValue 中的数据
  //修改图标颜色，需要修改每项compItem 相应 column 的 property: { icon: {}}
  //修改图标尺寸，需要修改每项compItem 相应 column 的 size

  return (container as UIHorizontalMenuComponent).modifyValueWhenPropertyChange(propertyName, newValue);
}

function onHorizontalMenuClone(comp: IComponentData, idMaps: { [key: string]: string }) {
  const { expandACollapseIcon, relation } = comp.value as ITreeData;
  const newRelation = mapChildsIDByCompID(relation, idMaps);
  comp.value = { expandACollapseIcon, relation: newRelation };
}

export const HorizontalMenuConfig: IComponentItem = {
  type: CHorizontalMenu,
  isList: true,
  isTextComp: true, // 数据填充文本-判断需要
  thumb: {
    spriteIconClass: SpriteThumb.HorizontalMenu.className,
    dragPosition: SpriteThumb.HorizontalMenu.position,
  },
  name: i18n('resource.components.horizontalMenu'),
  editor: {
    onPropertyUpdate: onHorizontalMenuPropertyUpdate,
    onClone: onHorizontalMenuClone,
    specials: {
      makeHorizontalMenuItem,
    },
  },
  preview: {
    onTriggerState: (
      container: UIComponent,
      triggerComp: UIComponent,
      stateName: string,
      value: any,
    ): ArtboardOperations | null => {
      const options: ArtboardOperations = {};
      const treeComp = container as UIHorizontalMenuComponent;

      // 查找value 中的realation 中的数据， 修改triggerComp 对应的item 的expand 设置
      const { relation } = treeComp.value as ITreeData;
      const arrTree = treeToArr(relation);
      const expandCompId = triggerComp.parent?.id || '';
      if (value === TreeItemColumn.Expand) {
        const expand = stateName === 'checked';
        const newArrTree = arrTree.map((item) => {
          if (item.id === expandCompId) {
            item.expand = expand;
          }
          return item;
        });
        const newTreeData = arrToTree(newArrTree);
        options[container.id] = [Ops.replace('/value', { relation: newTreeData })];
      }
      return options;
    },
  },
  getDefaultData() {
    return {
      properties: {
        container: {
          scroll: true,
          showScroll: true,
          hidden: true,
        },
        fill: {
          disabled: false,
          type: FillType.solid,
          color: {
            r: 255,
            g: 255,
            b: 255,
            a: 1,
          },
        },
        textStyle: {
          color: SystemsColor.DefaultTextColor,
          fontFamily: 'Microsoft YaHei',
          fontStyle: { underline: false, bold: false, strike: false, italic: false },
          fontSize: 14,
          textAlign: TextAlign.left,
        },
        itemLineHeight: {
          name: i18n('property.propertyNames.lineHeight'),
          prop: 'number',
          value: {
            value: defaultLineHeight,
          },
        },
        itemCheckedFill: {
          name: i18n('property.propertyNames.checkedBgcolor'),
          prop: 'fill',
          type: FillType.solid,
          color: SystemsColor.LightGrayColor,
          disabled: false,
        },
        itemCheckedMarkerStrip: {
          name: i18n('property.propertyNames.markerStrip'),
          prop: 'fill',
          type: FillType.solid,
          color: SystemsColor.MarkerStripColor,
          disabled: false,
        },
        itemCheckedTextStyle: {
          name: i18n('property.propertyNames.checkedText'),
          prop: 'textStyle',
          color: SystemsColor.DefaultSelectColor,
          fontFamily: 'Microsoft YaHei',
          fontStyle: { underline: false, bold: false, strike: false, italic: false },
          fontSize: 14,
          textAlign: TextAlign.left,
        },
        treeExpand: {
          name: i18n('property.component.tree.treeExpand'),
          prop: 'tree',
          isShow: true,
          chooseExpandType: DefaultCollapseIconType.Arrow,
          iconColor: SystemsColor.DefaultSelectColor,
          iconSize: 12,
        },
        treeNode: {
          name: i18n('property.component.tree.treeNode'),
          prop: 'tree',
          isShow: true,
          iconColor: SystemsColor.DefaultSelectColor,
          iconSize: 12,
          checkedIconColor: SystemsColor.DefaultSelectColor,
        },
      },
    };
  },
};

export function makeHorizontalMenu(id: string) {
  const treeItemData = getDefaultHorizontalMenuData();
  return makeCommonComponent(id, CHorizontalMenu, {
    ...HorizontalMenuConfig.getDefaultData?.(),
    size: { width: 270, height: 30 },
    sealed: true,
    layout: {
      vertical: VerticalAlign.TopAndBottom,
      horizontal: HorizontalAlign.Right,
      fixedWidth: true,
      fixedHeight: false,
      auto: true,
      responsive: false,
    },
    value: {
      expandACollapseIcon: defaultExpandIcon,
      relation: treeItemData,
    },
    components: [
      ...treeToArr(treeItemData).map((item, index) => {
        return makeHorizontalMenuItem(item, index);
      }),
    ],
    select: {
      target: 'child',
      minCount: 1,
      maxCount: 1,
      autoUnselect: true,
      reversible: true,
      enabled: true,
    },
  });
}

/**
 * 构建的一个IComponentData
 * @param item
 * @param index
 * @param unSelected
 */
export function makeHorizontalMenuItem(item: ITreeArrData, index: number, unSelected?: boolean) {
  return makeCommonComponent(item.id, CTreeItem, {
    size: { width: 90, height: defaultLineHeight },
    position: { x: 0, y: 0 },
    selected: index === 1 && unSelected,
    components: [
      makeIcon(getNewID(), {
        alias: TreeItemAlias.ExpandIcon,
        column: TreeItemColumn.Expand,
        size: { width: 12, height: 12 },
        value: defaultExpandIcon.collapse.icon,
        selected: true,
        properties: {
          icon: {
            color: SystemsColor.DefaultTextColor,
          },
        },
        states: {
          checked: {
            enabled: true,
            properties: {},
            value: defaultExpandIcon.collapse.icon,
          },
        },
        select: {
          target: 'self',
          enabled: true,
          reversible: true,
        },
      }),
      makeIcon(getNewID(), {
        alias: TreeItemAlias.NodeIcon,
        column: TreeItemColumn.Node,
        size: { width: 12, height: 12 },
        value: item.nodeIcon.expand.icon,
      }),
      makeCommonComponent(getNewID(), CPureText, {
        alias: TreeItemAlias.NodeText,
        size: { width: 90, height: 30 },
        column: TreeItemColumn.Text,
        value: `${i18n('property.component.tree.itemText')} ${item.index + 1}`,
        properties: {
          textStyle: { ref: '@properties.textStyle' },
        },
        states: {
          checked: {
            enabled: true,
            properties: {
              textStyle: { ref: '@properties.itemCheckedTextStyle' },
            },
          },
        },
      }),
    ],
  });
}
