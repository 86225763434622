import * as assert from 'assert';
import { mapValues } from 'lodash';

import { distance, round, sameNumber } from './globalUtils';
import { getBoundsWithPoints } from './boundsUtils';

interface IMoveDelta {
  x: number;
  y: number;
}

interface IPoint {
  x: number;
  y: number;
}

export interface ILine {
  k: number;
  b: number;
  isPoint: boolean;
}

interface IBounds {
  left: number;
  top: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
}

interface IBoundsOffset {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

/**
 * 以左上角为起点，依次获取四条边
 * @param {IPoint[]} points
 * @returns {ILine[]}
 */
export function getLinesByPoints(points: IPoint[]): ILine[] {
  const lines: ILine[] = [];

  let fromPoint = points[0];
  let nextPoint: IPoint;
  points.forEach((point, index) => {
    const nextIndex = index + 1 === points.length ? 0 : index + 1;
    nextPoint = points[nextIndex];
    lines.push(getLineByPoint(fromPoint, nextPoint));
    fromPoint = nextPoint;
  });

  return lines;
}

/**
 * 从上边开始，依次计算出4个交点
 * @param {ILine[]} lines
 * @returns {IPoint[]}
 */
export function getPointsByLines(lines: ILine[]): IPoint[] {
  const points: IPoint[] = [];

  let fromLine = lines[lines.length - 1];
  lines.forEach((line) => {
    points.push(getPointByLine(fromLine, line));
    fromLine = line;
  });

  return points;
}

/**
 * 根据给定的两点计算出一条直线
 * @param {IPoint} point1
 * @param {IPoint} point2
 * @returns {ILine}
 */
export function getLineByPoint(point1: IPoint, point2: IPoint): ILine {
  const result: ILine = {
    k: 0,
    b: 0,
    isPoint: false,
  };
  // 两点x相等则为垂直线，特殊处理下
  if (Math.round(point1.x - point2.x) === 0) {
    result.k = Infinity;
    result.b = point1.x;
  } else {
    result.k = (point1.y - point2.y) / (point1.x - point2.x);
    result.b = point1.y - result.k * point1.x;
  }
  // 如果x,y都相等,代表是一个点 而不是直线
  result.isPoint = sameNumber(point1.x, point2.x) && sameNumber(point2.y, point1.y);

  return result;
}

/**
 * 根据给定的两条边计算出交点
 * @param {ILine} line1
 * @param {ILine} line2
 * @returns {IPoint}
 */
export function getPointByLine(line1: ILine, line2: ILine): IPoint {
  assert.ok(line1.k !== line2.k, '平行线无交点');

  let x: number;
  let y: number;
  if (line1.k === Infinity) {
    x = line1.b;
    y = line2.k * x + line2.b;
  } else if (line2.k === Infinity) {
    x = line2.b;
    y = line1.k * x + line1.b;
  } else {
    x = (line2.b - line1.b) / (line1.k - line2.k);
    y = line1.k * x + line1.b;
  }

  return { x, y };
}

/**
 * 获取两点之间的距离
 * @param {IPoint} point1
 * @param {IPoint} point2
 * @returns {number}
 */
export function getTowPointDis(point1: IPoint, point2: IPoint): number {
  return Math.hypot(point1.x - point2.x, point1.y - point2.y);
}

/**
 * 根据给定的坐标和角度，计算坐标绕圆心逆时针旋转rotate度后的坐标
 * @param {number} x
 * @param {number} y
 * @param {number} rotate
 * @returns {{x: number, y: number}}
 */

export function getRotateOffset(x: number, y: number, rotate: number) {
  return {
    x: Math.hypot(y, x) * Math.cos((((Math.atan2(-y, x) * 180) / Math.PI + rotate) * Math.PI) / 180),
    y: -Math.hypot(y, x) * Math.sin((((Math.atan2(-y, x) * 180) / Math.PI + rotate) * Math.PI) / 180),
  };
}

/**
 * 平移直线，使其经过给定点
 * @param {ILine} line
 * @param {IPoint} point
 * @returns {ILine}
 */
export function moveLineToPoint(line: ILine, point: IPoint): ILine {
  let b: number;
  if (line.k === Infinity) {
    b = point.x;
  } else {
    b = point.y - line.k * point.x;
  }
  return {
    ...line,
    k: line.k,
    b,
  };
}

/**
 * @description: 海伦公式计算三角形面积，参数长度为3,顺时针
 * @param {IPoint[]} points
 * @return:
 */
export function getTriangleArea(points: IPoint[]): number {
  const line1 = getTowPointDis(points[0], points[1]);
  const line2 = getTowPointDis(points[1], points[2]);
  const line3 = getTowPointDis(points[2], points[0]);
  const perimeter = line1 + line2 + line3;
  return Math.sqrt(0.5 * perimeter * (0.5 * perimeter - line1) * (0.5 * perimeter - line2) * (0.5 * perimeter - line3));
}

/**
 * @description: 海伦公式计算凸四边形面积，参数长度为4，顺时针
 * @param {IPoint[]} points
 * @return:
 */
export function getQuadrilateralArea(points: IPoint[]): number {
  const triangleArea1 = getTriangleArea([points[0], points[1], points[2]]);
  const triangleArea2 = getTriangleArea([points[2], points[3], points[0]]);
  return triangleArea1 + triangleArea2;
}

/**
 * @description: 点是否在凸四边形区域内，参数长度为4，顺时针
 * @param {IPoint[]} points
 * @param {IPoint} point
 * @return:
 */
export function isPointInArea(points: IPoint[] | undefined, point: IPoint): boolean {
  if (points instanceof Array && points.length === 4) {
    const triangleArea1 = getTriangleArea([points[0], points[1], point]);
    const triangleArea2 = getTriangleArea([points[1], points[2], point]);
    const triangleArea3 = getTriangleArea([points[2], points[3], point]);
    const triangleArea4 = getTriangleArea([points[3], points[0], point]);
    const quadrilateralArea = getQuadrilateralArea(points);
    if (quadrilateralArea === 0) {
      return false;
    }
    return distance(quadrilateralArea, triangleArea1 + triangleArea2 + triangleArea3 + triangleArea4) <= 1;
  } else {
    return false;
  }
}

/**
 * 计算给定点旋转rotate度后的新点
 * @param {IPoint} point
 * @param {number} rotate
 * @returns {IPoint}
 */
export function rotatePoint(point: IPoint, rotate: number): IPoint {
  if (rotate) {
    // 计算弧度
    const angle = (rotate * 2 * Math.PI) / 360;

    // fixme 考虑垂直或水平情况的处理
    const newX = point.x * Math.cos(angle) - point.y * Math.sin(angle);
    const newY = point.x * Math.sin(angle) + point.y * Math.cos(angle);

    return { x: newX, y: newY };
  }
  return point;
}

/**
 *
 */
export function getDeltaForOrderRotate(
  width: number,
  height: number,
  lastRotate: number,
  rotate: number,
): IBoundsOffset {
  width = width / 2;
  height = height / 2;
  const L = Math.hypot(width, height); //斜边
  const originRotate = 180 * (Math.atan2(-height, width) / Math.PI);
  const firstRotate = ((originRotate - lastRotate - rotate) / 180) * Math.PI;
  const secondRotate = ((-originRotate - lastRotate - rotate) / 180) * Math.PI;
  const lastFirstRotate = ((originRotate - lastRotate) / 180) * Math.PI;
  const lastSecondRotate = ((-originRotate - lastRotate) / 180) * Math.PI;
  const rotateY = Math.max(Math.abs(Math.sin(firstRotate) * L), Math.abs(Math.sin(secondRotate) * L));
  const rotateX = Math.max(Math.abs(Math.cos(firstRotate) * L), Math.abs(Math.cos(secondRotate) * L));
  const lastRotateY = Math.max(Math.abs(Math.sin(lastFirstRotate) * L), Math.abs(Math.sin(lastSecondRotate) * L));
  const lastRotateX = Math.max(Math.abs(Math.cos(lastFirstRotate) * L), Math.abs(Math.cos(lastSecondRotate) * L));
  //获得前一个状态的旋转角度， 计算出 这次和上次 的实际 旋转后的宽度,相减。
  //得到的值则是角度变化后偏移量的变化，在通过原偏移量 减去偏移量的变化
  const offset = {
    left: lastRotateX - rotateX,
    top: lastRotateY - rotateY,
    right: rotateX - lastRotateX,
    bottom: rotateY - lastRotateY,
  };
  return offset;
}

function getAdjustedZeroOffset(offset: IBoundsOffset) {
  return mapValues(offset, (value) => (sameNumber(value, 0) ? 0 : value));
}

/**
 * 根据起始点，和结束点以及旋转角度，计算真实的偏移量
 * @param {IPoint[]} oldPoints
 * @param {IPoint[]} newPoints
 * @param {number} rotate
 * @returns {IBoundsOffset}
 */
export function getOffsetByPoints(
  oldPoints: IPoint[],
  newPoints: IPoint[],
  rotate: number,
  needRounded = true,
): IBoundsOffset {
  const originOldOffset = oldPoints.map((item) => getRotateOffset(item.x, item.y, rotate));
  const originNewOffset = newPoints.map((item) => getRotateOffset(item.x, item.y, rotate));
  const {
    left: originOldLeft,
    top: originOldTop,
    right: originOldRight,
    bottom: originOldBottom,
  } = getBoundsWithPoints(originOldOffset);

  const {
    left: originNewLeft,
    top: originNewTop,
    right: originNewRight,
    bottom: originNewBottom,
  } = getBoundsWithPoints(originNewOffset);

  return getAdjustedZeroOffset({
    left: needRounded ? round(originNewLeft - originOldLeft) : originNewLeft - originOldLeft,
    top: needRounded ? round(originNewTop - originOldTop) : originNewTop - originOldTop,
    right: needRounded ? round(originNewRight - originOldRight) : originNewRight - originOldRight,
    bottom: needRounded ? round(originNewBottom - originOldBottom) : originNewBottom - originOldBottom,
  });
}

/**
 * 计算点被平移后的新点
 * @param {IPoint[]} points
 * @param {MoveDelta} delta
 * @returns {IPoint[]}
 */
export function offsetPoints(points: IPoint[], delta: IMoveDelta): IPoint[] {
  return points.map((point) => offsetPoint(point, delta));
}

export function offsetPoint(point: IPoint, delta: IMoveDelta): IPoint {
  return {
    x: point.x + delta.x,
    y: point.y + delta.y,
  };
}

/**
 * 根据给定点计算在坐标系中的正矩形
 * @param {IPoint[]} points
 * @param needRoundedResult
 * @returns {IBounds}
 */
export function getBoundingOfPoints(points: IPoint[], needRoundedResult = true): IBounds {
  let leftSide = Number.POSITIVE_INFINITY;
  let topSide = Number.POSITIVE_INFINITY;
  let rightSide = Number.NEGATIVE_INFINITY;
  let bottomSide = Number.NEGATIVE_INFINITY;

  points.forEach((point) => {
    if (point.x < leftSide) {
      leftSide = point.x;
    }
    if (point.x > rightSide) {
      rightSide = point.x;
    }
    if (point.y < topSide) {
      topSide = point.y;
    }
    if (point.y > bottomSide) {
      bottomSide = point.y;
    }
  });
  const left = leftSide;
  const top = topSide;
  const width = rightSide - leftSide;
  const height = bottomSide - topSide;
  const unRoundedResult: IBounds = {
    left,
    top,
    width,
    height,
    right: left + width,
    bottom: top + height,
  };
  return needRoundedResult ? mapValues(unRoundedResult, (value) => round(value)) : unRoundedResult;
}

/**
 * 获取所有给定点的中心点
 * @param {IPoint[]} points
 * @returns {IPoint}
 */
export function getCenterPoint(points: IPoint[]): IPoint {
  let sumX: number = 0;
  let sumY: number = 0;
  points.forEach((point) => {
    sumX = sumX + point.x;
    sumY = sumY + point.y;
  });
  return {
    x: sumX / points.length,
    y: sumY / points.length,
  };
}

export function getAngleByPoint(center: IPoint, pt: IPoint): number {
  const x = pt.x - center.x;
  const y = pt.y - center.y;
  const l = Math.hypot(x, y);
  const rad = Math.asin(y / l);
  let angle = 180 / (Math.PI / rad);
  if (pt.x < center.x) {
    angle = 180 - angle;
  }
  angle = Math.round((angle + 360) % 360);
  return angle;
}

/**
 * 过点求线段所在直线上垂足坐标
 * @param {IPoint} pt 线段外一点
 * @param {IPoint} p1 线段起点
 * @param {IPoint} p2 线段终点
 * @returns
 */
export function getFootOfPerpendicular(pt: IPoint, p1: IPoint, p2: IPoint) {
  if (sameNumber(p1.x, p2.x)) {
    return { x: p1.x, y: pt.y };
  }
  if (sameNumber(p1.y, p2.y)) {
    return { x: pt.x, y: p1.y };
  }
  const k1 = (p2.y - p1.y) / (p2.x - p1.x);
  const k2 = -1 / k1;
  const line1 = moveLineToPoint({ k: k1, b: 0, isPoint: false }, p1);
  const line2 = moveLineToPoint({ k: k2, b: 0, isPoint: false }, pt);
  return getPointByLine(line1, line2);
}

function degreeToRadian(degree: number) {
  // 1° 对应的弧度值
  const radianPerDegree = Math.PI / 180;
  return degree * radianPerDegree;
}

/**
 * 绕某一点旋转一条直线
 * PS: 窗口坐标系在数学坐标系中的对应结果，直线的表示法：斜截式 y = kx + b
 * 详见：https://baike.baidu.com/item/%E7%9B%B4%E7%BA%BF%E6%96%B9%E7%A8%8B/901132#12
 * @param line
 * @param rotatingCenter
 * @param rotateDegree
 */
export function rotateLine(line: ILine, rotatingCenter: IPoint, rotateDegree: number): ILine | undefined {
  const { k, b, isPoint } = line;
  if (isPoint) {
    return;
  }

  const { x: x0, y: y0 } = rotatingCenter;
  const alpha = degreeToRadian(rotateDegree);
  const { sin, cos, tan } = Math;

  if (k === 0) {
    return {
      k: tan(alpha),
      b: (-x0 * sin(alpha) + y0 * (cos(alpha - 1) + b)) / cos(alpha),
      isPoint: false,
    };
  }

  if (k === Infinity) {
    return {
      k: -1 / tan(alpha),
      b: (x0 * cos(alpha) + y0 * sin(alpha) + b - x0) / sin(alpha),
      isPoint: false,
    };
  }

  return {
    k: (sin(alpha) + k * cos(alpha)) / (cos(alpha) - k * sin(alpha)),
    b:
      (x0 * (k - sin(alpha) - k * cos(alpha)) + y0 * (cos(alpha) - 1 - k * sin(alpha)) + b) /
      (cos(alpha) - k * sin(alpha)),
    isPoint: false,
  };
}
