import ITextFormatEx from '@/fbs/rp/models/properties/textFormat';
import { FontBoxScale } from '@consts/fonts';
import { ISize } from '@/utils/boundsUtils';
import { makeCssText, makeFitStyle, makeFlexStyle } from '../util/styleUtil';
import { fetchWebFont } from '../domToImage/fontFaces';
import { escapeXhtml } from '../util';
import { IBounds, ITextStyle } from '../type';

export const makeForeignObject = (html?: string, size?: ISize, transform?: string, textStyle?: ITextStyle): string => {
  if (!html) {
    return '';
  }
  const { width, height } = size ?? {};
  const fitStyle = makeFitStyle();
  const flexStyle = makeFlexStyle(textStyle);
  return `<foreignObject x="0" y="0" width="${width ?? '100%'}" height="${height ?? '100%'}" ${transform}> 
  <body xmlns="http://www.w3.org/1999/xhtml" style="${fitStyle}${flexStyle}">
  ${escapeXhtml(html)}
</body>
</foreignObject>`;
};

export const makeTextForeignObject = async (
  text?: string,
  textStyle?: ITextStyle,
  bounds?: IBounds,
  transform?: string,
): Promise<string> => {
  if (!text) {
    return '';
  }

  const cssText = makeCssText(textStyle);
  const style = cssText ? ` style="${cssText}"` : '';

  const fontFamily = textStyle?.fontFamily as string;
  const webFont = await fetchWebFont(fontFamily);
  const styleNode = webFont ? `<style>${webFont}</style>` : '';

  const html = `<div${style}>${text}</div>${styleNode}`;
  const size = bounds as ISize;
  return makeForeignObject(html, size, transform, textStyle);
};

export const makeTextStyle = (textFormat?: ITextFormatEx): string => {
  if (!textFormat) {
    return '';
  }
  const vertical = textFormat.vertical;
  const verticalAlignModel = textFormat?.verticalAlign || 'middle';
  const style: any = {};
  const fontSize = textFormat.fontSize || 14;
  const letterSpace = textFormat.letterSpace || 0;
  const lineHeightEx = textFormat?.lineHeightEx || 20;
  const verticalAligns = ['top', 'center', 'bottom'];
  let verticalAlign: string = verticalAlignModel;
  if (style) {
    style.fontSize = fontSize / FontBoxScale;
    style.lineHeight = lineHeightEx / FontBoxScale + 'px';
    style.letterSpacing = letterSpace / FontBoxScale;
    style.transform = 'scale(' + FontBoxScale + ')';
    style.left = 0;
    if (!verticalAligns.includes(verticalAlign)) {
      verticalAlign = 'center';
    }
    if (vertical) {
      style.transformOrigin = 'left top';
      switch (verticalAlign) {
        case 'top':
          style.right = 0;
          style.left = undefined;
          style.transformOrigin = 'right top';
          break;
        case 'center':
          style.transform += ' translateX(-50%)';
          break;
        default:
          break;
      }
      style.top = '50%';
      style.transform += ' translateY(-50%)';
    } else {
      style.height = undefined;
      style.transformOrigin = 'left ' + verticalAlign;
      style.left = '50%';
    }
  }
  return style;
};
