import { uniq } from 'lodash';
import { isEqualDate } from '@utils/globalUtils';
import { IMeasureReturnType, measureTextSize } from '@/utils/textUtils';

import i18n from '@/i18n';
import ValueEditorType from '@/consts/enums/valueEditorType';
import {
  EChartType_basic,
  ELegendPosition,
  ILegendPosition,
  ChartLegendPositionPropertyName,
  ChartDataLabelPropertyName,
  ChartAxisPropertyName,
  ChartSmoothPropertyName,
  ChartBarWidthPropertyName,
} from '@/fbs/rp/models/properties/chart';
import { PresetDashModel } from '@/fbs/rp/models/properties/stroke';

import { CCombinationChart } from '@/libs/constants';
import { UIComponent } from '@/editor/comps';
import { PropertyValue } from '@/fbs/rp/models/property';
import { ArtboardPatches, Ops } from '@/fbs/rp/utils/patch';
import { IComponentItem } from '@libs/types';
import { CBarChart, CBarChartHorizontal, CLineChart, CAreaChart, CRadarChart, CScatterChart } from '@libs/constants';
import { IComponentData } from '@/fbs/rp/models/component';
import { IChartValue } from '@/fbs/rp/models/chart';
import ITextFormat from '@/fbs/rp/models/properties/text';
import { IComponentValue } from '@/fbs/rp/models/value';
import { ISize } from '@/fbs/common/models/common';
import { StyleHelper } from '@/helpers/styleHelper';
import { getChartCommonProperties, getChartConfigPartial, getCharPopupDefault } from '../common';
import { makeCommonComponent } from '../../helper';

export const basicChartTypeList = [
  { value: EChartType_basic.Bar, name: i18n('property.component.barChart') },
  { value: EChartType_basic.BarChartHorizontal, name: i18n('property.component.barChartHorizontal') },
  { value: EChartType_basic.Line, name: i18n('property.component.lineChart') },
  { value: EChartType_basic.Area, name: i18n('property.component.areaChart') },
  { value: EChartType_basic.Radar, name: i18n('property.component.radarChart') },
  { value: EChartType_basic.Scatter, name: i18n('property.component.scatter') },
];

export const LegendItemIconWidth = 25;
export const LegendItemIconHeight = 14;
export const LegendPadding = 5; // 图例内边距
export const LegendItemGap = 10;
const BarItemWidth = 30; // bar图表item的宽度
const LineItemWidth = 31; // line图表item的宽度
const ScatterItemWidth = 24.5; // scatter图表item的宽度

export function getSizePatches(comp: UIComponent, newSize?: ISize) {
  if (!newSize) {
    return null;
  }
  const path = comp.getCurrentPath('size');
  const patches: ArtboardPatches = { do: {}, undo: {} };
  patches.do[comp.id] = [Ops.replace(path, newSize)];
  patches.undo[comp.id] = [Ops.replace(path, comp.size)];
  return patches;
}

function getIconBetweenText(type: EChartType_basic, dataType?: EChartType_basic) {
  switch (dataType || type) {
    case EChartType_basic.Line:
    case EChartType_basic.Area: {
      return LineItemWidth;
    }
    case EChartType_basic.Scatter: {
      return ScatterItemWidth;
    }
    default: {
      return BarItemWidth;
    }
  }
}

type ChartType =
  | typeof CBarChart
  | typeof CBarChartHorizontal
  | typeof CLineChart
  | typeof CAreaChart
  | typeof CRadarChart
  | typeof CScatterChart
  | typeof CCombinationChart;

/**
 * 获取配置数据
 * @param {ChartType} type
 * @return {IComponentItem}
 */
export const getChartConfig = (type: ChartType, typeValue?: EChartType_basic): IComponentItem => {
  return {
    type,
    ...getChartConfigPartial(type),
    property: {
      getValueNames: (key: string): { name: string; value: any }[] | undefined => {
        if (key === 'type') {
          return basicChartTypeList;
        }
      },
    },
    editor: {
      onPropertyUpdate: onPropertyChange,
      onResize,
      onValueUpdate,
    },
    value: {
      type: type === CCombinationChart ? ValueEditorType.CombinationChart : ValueEditorType.Chart,
    },
    getDefaultData() {
      return getSeriesChartOneDefaultData(type, typeValue);
    },
  };
};

export function getSeriesChartOneDefaultData(type: ChartType, typeValue?: EChartType_basic): Partial<IComponentData> {
  return {
    properties: {
      ...getChartCommonProperties(),
      isStack: {
        prop: 'boolean',
        value: false,
        name: i18n('property.propertyNames.stack'),
        disabled: false,
        allowDisabled: true,
        hidden: [CScatterChart, CRadarChart].includes(type),
      },
      isSmooth: {
        prop: ChartSmoothPropertyName,
        value: PresetDashModel.solid,
        disabled: true,
        hidden: ![CLineChart, CAreaChart, CCombinationChart].includes(type),
      },
      dataLabel: {
        prop: ChartDataLabelPropertyName,
        disabled: true,
        unit: '',
      },
      axis: {
        prop: ChartAxisPropertyName,
        disabled: false,
        yAxisGap: '',
        yAxisMin: '',
        labelRotate: 0,
        hidden: [CRadarChart].includes(type),
        isCombinationAxis: type === CCombinationChart,
        lineYAxisGap: type === CCombinationChart ? '' : undefined,
        lineYAxisMin: type === CCombinationChart ? '' : undefined,
        scaleMark: true,
      },
      legendPosition: {
        prop: ChartLegendPositionPropertyName,
        disabled: false,
        value: ELegendPosition.Bottom,
        gap: '',
        gapHidden: [CRadarChart].includes(type),
      },
      type: {
        prop: 'enum',
        name: i18n('property.propertyNames.type'),
        value: typeValue,
        hidden: [CCombinationChart].includes(type),
      },
      barWidth: {
        prop: ChartBarWidthPropertyName,
        value: '',
        hidden: ![CBarChart, CBarChartHorizontal, CCombinationChart].includes(type),
      },
      animation: {
        prop: 'boolean',
        value: true,
        name: i18n('property.propertyNames.showAnimation'),
      },
      popup: getCharPopupDefault(),
    },
  };
}

/**
 * 创建图表数据
 * @param {string} id
 * @param {ChartType} type
 * @param typeValue
 * @return {IComponentData}
 */
export const makeChartData = (id: string, type: ChartType, typeValue?: EChartType_basic): IComponentData => {
  return makeCommonComponent(id, type, {
    ...getSeriesChartOneDefaultData(type, typeValue),
    size: {
      width: 400,
      height: 250,
    },
    value: {
      ...BasicChartValue(type),
    } as IChartValue,
  });
};

interface CalcOrientLegendSizeOptions {
  list: string[]; // 图例item名列表
  max: number; // 最大宽度/高度
  itemGap: number; // 每个item间的间距
  getBaseItemX: (itemSize: ISize, name: string) => number; // 每个item X轴大小的计算
  getBaseItemY: (itemSize: ISize, name: string) => number; // 每个item Y轴大小的计算
}

/**
 * list依次沿着x轴排列，超过最大值进行换行，最终计算出整个图例所占的宽高
 * @param style 样式
 * @param {CalcOrientLegendSizeOptions} options
 */
function getOrientLegendSize(style: Record<string, any>, options: CalcOrientLegendSizeOptions) {
  const { list: originalList, max, itemGap } = options;
  const list = uniq(originalList);

  let x = 0;
  let y = 0;
  let tempX = 0;
  let rowMax = 0;
  let rowIndex = 0;
  for (let i = 0; i < list.length; i++) {
    const itemSize = measureTextSize(style, list[i], { returnType: IMeasureReturnType.Exact });
    const baseX = options.getBaseItemX(itemSize, list[i]) + itemGap;
    const baseY = options.getBaseItemY(itemSize, list[i]) + itemGap;
    tempX += baseX;
    rowIndex++;
    // 超过最大高度，此时换列
    if (max <= tempX - itemGap) {
      // 当前第一项已经超出最大值
      if (rowIndex === 1) {
        x = Math.max(x, baseX);
        tempX = 0;
        rowIndex = 0;
        y += baseY;
        continue;
      } else {
        x = Math.max(x, tempX - baseX);
        tempX = baseX;
        rowIndex = 1;
        y += rowMax;
      }
      rowMax = 0;
    }

    rowMax = Math.max(rowMax, baseY);
    if (i === list.length - 1) {
      // 最后一项未触发换列或换行
      y += rowMax;
      x = Math.max(tempX, x);
    }
  }

  return { x, y };
}

interface ChangeOptions {
  position?: ELegendPosition;
  textStyle?: ITextFormat;
  list?: string[];
  size?: ISize;
}

/**
 * 获取图例占据x轴或y轴的位置
 * @param comp
 * @param change
 */
export function getLegendSize(comp: UIComponent, change?: ChangeOptions) {
  const { properties } = comp;
  const value = comp.value as IChartValue;
  const compType = properties.type?.value as EChartType_basic;
  const legendPosition = (properties.legendPosition || properties.pieLegendPosition) as ILegendPosition;
  const position = change?.position || legendPosition.value;
  const isCombinationChart = comp.type === CCombinationChart;

  let list = value.dataSource.map((item) => item.name);
  let textStyle = properties.textStyle;
  let size = comp.size;
  if (change) {
    Object.keys(change).forEach((key) => {
      if (key === 'textStyle') {
        textStyle = change[key]!;
      } else if (key === 'list') {
        list = change[key]!;
      } else if (key === 'size') {
        size = change[key]!;
      }
    });
  }

  function calcLegendItemWidth(itemSize: ISize, name: string) {
    if (isCombinationChart) {
      const item = value.dataSource.find((v) => v.name === name)!;
      return itemSize.width + getIconBetweenText(compType, item?.type);
    }
    return itemSize.width + getIconBetweenText(compType);
  }

  const isVertical = position === ELegendPosition.Top || position === ELegendPosition.Bottom;
  const parser = StyleHelper.initCSSStyleParser({ textStyle });
  const style = parser.getTextStyle();
  style.lineHeight = style.fontSize;

  const options = { list, itemGap: LegendItemGap } as CalcOrientLegendSizeOptions;
  if (isVertical) {
    options.max = size.width - LegendPadding * 2;
    options.getBaseItemX = calcLegendItemWidth;
    options.getBaseItemY = (itemSize: ISize) => Math.max(itemSize.height, LegendItemIconHeight);

    const { x: width, y: height } = getOrientLegendSize(style, options);
    return { width, height };
  }

  options.max = size.height - LegendPadding * 2;
  options.getBaseItemX = (itemSize: ISize) => Math.max(itemSize.height, LegendItemIconHeight);
  options.getBaseItemY = calcLegendItemWidth;

  const { x: height, y: width } = getOrientLegendSize(style, options);
  return { width, height };
}

/**
 * 图例间距变更生成patches
 * 间距变化，对整个组件size进行变更
 * @param comp
 * @param propValue
 */
function legendPropertyChange(comp: UIComponent, propValue: ILegendPosition): ArtboardPatches | null {
  const { size: oldSize, properties } = comp;
  const legendPosition = properties.legendPosition!;
  const oldDic = legendPosition.value;
  const newSpace = propValue.gap;

  let oldSpace = legendPosition.gap;
  // 间距发生变化， 从自动间距设置成固定间距，先计算自动间距的值
  if (newSpace !== undefined && newSpace !== '' && oldSpace !== newSpace) {
    const newSize = { ...oldSize };

    const legendSize = oldSpace === undefined || oldSpace === '' ? getLegendSize(comp) : undefined;
    // 垂直方向变更间距，高度增加
    if (oldDic === ELegendPosition.Bottom || oldDic === ELegendPosition.Top) {
      // 0.1是底部默认占用10%
      oldSpace = oldSpace ?? oldSize.height * 0.1 - (legendSize?.height || 0);
      const diff = newSpace - (oldSpace || 0);
      newSize.height = oldSize.height + diff;
    } else {
      // 水平方向变更间距，宽度增加， left默认占用20%
      oldSpace = oldSpace ?? oldSize.width * 0.2 - (legendSize?.width || 0);
      const diff = newSpace - (oldSpace || 0);
      newSize.width = oldSize.width + diff;
    }
    return getSizePatches(comp, newSize);
  }
  return null;
}

/**
 * legend size发生变化，生成patches
 * @param comp
 * @param change
 * @param newSize
 * @param multiple diff 放大倍数，饼图和环图使用2倍
 */
export function getPatchesByLegendSizeChanged(comp: UIComponent, change: ChangeOptions, newSize?: ISize, multiple = 1) {
  const { size: oldSize, properties } = comp;
  const legendPosition = (properties.legendPosition! || properties.pieLegendPosition!) as ILegendPosition;
  const space = legendPosition.gap;
  // 间距为自动，size不发生变更
  if (space === undefined || space === '') {
    return null;
  }

  const dic = legendPosition.value;
  const isVertical = dic === ELegendPosition.Top || dic === ELegendPosition.Bottom;
  const baseSize = newSize || oldSize;
  const patches: ArtboardPatches = { do: {}, undo: {} };

  const oldLegendSize = getLegendSize(comp);
  const newLegendSize = getLegendSize(comp, change);

  let patchSize;
  if (isVertical && oldLegendSize.height !== newLegendSize.height) {
    const diff = newLegendSize.height - oldLegendSize.height;
    patchSize = { ...baseSize, height: baseSize.height + diff * multiple };
  } else if (!isVertical && oldLegendSize.width !== newLegendSize.width) {
    const diff = newLegendSize.width - oldLegendSize.width;
    patchSize = { ...baseSize, width: baseSize.width + diff * multiple };
  }
  if (patchSize) {
    const path = comp.getCurrentPath('size');
    patches.do[comp.id] = [Ops.replace(path, patchSize)];
    patches.undo[comp.id] = [Ops.replace(path, oldSize)];
    return patches;
  }
  return null;
}

export function onPropertyChange(
  comp: UIComponent,
  propertyName: string,
  propValue: PropertyValue,
): ArtboardPatches | null {
  if (propertyName === 'type') {
    return {
      do: {
        [comp.id]: [
          Ops.replace(
            '/properties/isSmooth/hidden',
            ![EChartType_basic.Area, EChartType_basic.Line].includes(propValue.value as EChartType_basic),
          ),
          Ops.replace('/properties/axis/hidden', propValue.value === EChartType_basic.Radar),
          Ops.replace(
            '/properties/isStack/hidden',
            ![
              EChartType_basic.BarChartHorizontal,
              EChartType_basic.Bar,
              EChartType_basic.Line,
              EChartType_basic.Area,
            ].includes(propValue.value as EChartType_basic),
          ),
          Ops.replace(
            '/properties/barWidth/hidden',
            ![EChartType_basic.BarChartHorizontal, EChartType_basic.Bar].includes(propValue.value as EChartType_basic),
          ),
          Ops.replace(
            '/properties/legendPosition/gapHidden',
            [EChartType_basic.Radar].includes(propValue.value as EChartType_basic),
          ),
        ],
      },
      undo: {
        [comp.id]: [
          Ops.replace(
            '/properties/isSmooth/hidden',
            [EChartType_basic.Area, EChartType_basic.Line].includes(propValue.value as EChartType_basic),
          ),
          Ops.replace('/properties/axis/hidden', propValue.value !== EChartType_basic.Radar),
          Ops.replace(
            '/properties/isStack/hidden',
            [
              EChartType_basic.BarChartHorizontal,
              EChartType_basic.Bar,
              EChartType_basic.Line,
              EChartType_basic.Area,
            ].includes(propValue.value as EChartType_basic),
          ),
          Ops.replace(
            '/properties/barWidth/hidden',
            [EChartType_basic.BarChartHorizontal, EChartType_basic.Bar].includes(propValue.value as EChartType_basic),
          ),
          Ops.replace(
            '/properties/legendPosition/gapHidden',
            ![EChartType_basic.Radar].includes(propValue.value as EChartType_basic),
          ),
        ],
      },
    };
  }
  if (propertyName === 'legendPosition') {
    return legendPropertyChange(comp, propValue as ILegendPosition);
  }
  if (propertyName === 'textStyle') {
    return getPatchesByLegendSizeChanged(comp, { textStyle: propValue as ITextFormat });
  }
  return null;
}

/**
 * resize时，如果图例的发生换行或者换列，整体宽高需要加上图例行列变化产生的size
 * @param container
 * @param newSize
 */
export function onResize(container: UIComponent, newSize: ISize) {
  const { size: oldSize } = container;
  if (newSize.width === oldSize.width && newSize.height === oldSize.height) {
    return null;
  }
  return getPatchesByLegendSizeChanged(container, { size: newSize }, newSize);
}

export function onValueUpdate(comp: UIComponent, newValue: IComponentValue, multiple = 1) {
  const value = comp.value as IChartValue;
  const oldList = value.dataSource.map((item) => item.name);
  const list = (newValue as IChartValue).dataSource.map((item) => item.name);
  if (!isEqualDate(oldList, list)) {
    return getPatchesByLegendSizeChanged(comp, { list }, undefined, multiple);
  }
  return null;
}

export const BasicChartValue = (type: string = '') => {
  let dataSource;
  if (type == CRadarChart) {
    dataSource = [
      {
        name: i18n('resource.components.data_one'),
        data: [80, 100, 100, 80, 100, 100],
      },
      {
        name: i18n('resource.components.data_two'),
        data: [100, 75, 80, 100, 80, 75],
      },
    ];
  } else if (type === CCombinationChart) {
    dataSource = [
      {
        type: EChartType_basic.Bar,
        name: i18n('resource.components.data_one'),
        data: [10, 20, 30, 40, 50, 60],
      },
      {
        type: EChartType_basic.Bar,
        name: i18n('resource.components.data_two'),
        data: [20, 25, 50, 45, 30, 100],
      },
      {
        color: { r: 254, g: 168, b: 68, a: 1 },
        type: EChartType_basic.Line,
        name: i18n('resource.components.data_three'),
        data: [30, 45, 80, 85, 80, 160],
      },
    ];
  } else {
    dataSource = [
      {
        name: i18n('resource.components.data_one'),
        data: [10, 20, 30, 40, 50, 60],
      },
      {
        name: i18n('resource.components.data_two'),
        data: [20, 25, 50, 45, 30, 100],
      },
    ];
  }
  return {
    xAxis: [
      i18n('resource.components.Jan'),
      i18n('resource.components.Fed'),
      i18n('resource.components.Mar'),
      i18n('resource.components.Apr'),
      i18n('resource.components.May'),
      i18n('resource.components.Jun'),
    ],
    yAxis: [],
    dataSource: dataSource,
  };
};
