import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { IExportItemData, ExportFileFormat, NamingScheme } from '@/fbs/rp/models/component';
import { IAppWithNestedChildren } from '@/fbs/rp/models/app';
import UIArtboard from '@/editor/comps/UIArtboard';
import { UIFragment, UIComponent } from '@/editor/comps';
import Doc from '@/editor/document';
import { makeZipItemFilename, makeZipFilename, makeFilenameByScheme } from '../util/filenameUtil';
import { svgToCanvasBlob, svgToCanvasDataURL } from '../svg';
import { createPdfByImage } from '../pdf/jspdf';
import { ISize } from '@/utils/boundsUtils';
import { svgToDataUri } from '../util';
import { getSvgSize } from '../svg/makeSvgTemplate';
import { groupBy } from '../util/arrayUtil';
import { IAddZipFileOptions } from '../type';
import { exceptionCollector } from '../exception';

interface IPackImagesOptions {
  components: (UIArtboard | UIFragment | UIComponent)[];
  exportFormats?: IExportItemData[];
  isOutputAllPages?: boolean;
  app?: IAppWithNestedChildren;
  doc?: Doc;
  ids?: string[];
}
export interface IExportFile {
  name: string;
  content: string | Blob;
  fileFormat?: ExportFileFormat;
  size?: ISize;
  scale?: number;
  namingScheme?: NamingScheme;
  id?: string;
  index?: number;
}
/**
 * 图片打包
 */
export class PackImages {
  private components: (UIArtboard | UIFragment | UIComponent)[];
  private isOutputAllPages?: boolean;
  private app?: IAppWithNestedChildren;
  private doc?: Doc;
  private exportFormats?: IExportItemData[];
  public files: IExportFile[] = [];
  private ids?: string[];
  constructor(options: IPackImagesOptions) {
    const { components, isOutputAllPages, app, doc, exportFormats, ids } = options;
    this.components = components;
    this.isOutputAllPages = isOutputAllPages;
    this.app = app;
    this.doc = doc;
    this.exportFormats = exportFormats;
    this.ids = ids;
  }

  async addSvg(options: IAddZipFileOptions) {
    const { t, index, scale, svg, size, id } = options;
    const { exportFormats, isOutputAllPages, app } = this;
    const fileFormat = exportFormats ? exportFormats[index].fileFormat : ExportFileFormat.Png;
    const namingScheme = exportFormats ? exportFormats[index].namingScheme : NamingScheme.Suffix; //  0;
    const name = makeZipItemFilename({ t, index, app, scale, namingScheme, isOutputAllPages });
    try {
      let content: string | Blob;
      switch (fileFormat) {
        case ExportFileFormat.SVG:
          content = svg;
          break;
        case ExportFileFormat.Pdf:
          content = await svgToCanvasDataURL(svg, name, scale);
          break;
        default:
          content = await svgToCanvasBlob(svg, name, scale);
      }

      this.files.push({
        name: `${name}.${fileFormat}`,
        content,
        fileFormat,
        size: size ? getSvgSize(size, scale) : size,
        scale,
        namingScheme,
        id,
        index: this.ids?.findIndex((t) => t === id),
      });
    } catch (e) {
      window.debug && console.error(e);
      // 异常信息收集
      exceptionCollector.add(name);
    }
  }
  addBlob(name: string, blob: Blob) {
    this.files.push({
      name,
      content: blob,
    });
  }

  /**
   * 保存文件
   * 带上异常处理函数，以备不时之需
   * @param onError
   */
  saveAs(onSuccess?: () => void, onError?: (e: string[]) => void) {
    const { exportFormats } = this;
    if (window.debug) {
      if (this.files.length === 0) {
        window.debug && console.log('未添加文件');
        if (exceptionCollector.length) {
          onError && onError(exceptionCollector.value);
        }
      }
    }

    // pdf导出
    if (exportFormats?.some((t) => t.fileFormat === ExportFileFormat.Pdf)) {
      this.saveASPdf(onSuccess, onError);
    }

    // 导出zip
    if (exportFormats?.some((t) => t.fileFormat !== ExportFileFormat.Pdf)) {
      this.saveAsZip(onSuccess, onError);
    }

    // 导出blob
  }
  getZipName(files: IExportFile[]) {
    const saveAsName =
      files.length === 1 ? files[0].name : `${makeZipFilename(this.doc, this.app, this.isOutputAllPages)}.zip`;
    return saveAsName;
  }
  getPdfName(files: IExportFile[]) {
    if (files.length === 0) {
      return '';
    }
    const { scale, name, namingScheme } = files[0];
    if (files.length === 1) {
      return scale === 1 ? name.replace('@1x', '') : name;
    }
    const packname = makeZipFilename(this.doc, this.app, this.isOutputAllPages);
    // 策略：pdf1倍率不要前后缀
    const pdfname =
      scale === 1
        ? packname
        : makeFilenameByScheme({
            name: packname,
            scale,
            namingScheme,
          });
    return `${pdfname}.${ExportFileFormat.Pdf}`;
  }
  saveAsZip(onSuccess?: () => void, onError?: (e: string[]) => void) {
    const zip = new JSZip();
    const images = this.files.filter((t) => t.fileFormat !== ExportFileFormat.Pdf);
    window.debug && console.log('saveAsZip');
    if (images.length > 1) {
      // 打包
      images.forEach((t) => {
        zip.file(t.name, t.content);
      });
      // 保持zip文件
      zip.generateAsync({ type: 'blob' }).then((blob: Blob) => {
        saveAs(blob, this.getZipName(images));
        onFinish(onSuccess, onError);
      });
    } else if (images.length === 1) {
      // 单独文件
      const { content, name } = images[0];
      if (content instanceof Blob) {
        saveAs(content, name);
      } else {
        saveAs(svgToDataUri(content), name);
      }
      onFinish(onSuccess, onError);
    }
  }

  /**
   * 独立pdf文件
   */
  saveASPdf(onSuccess?: () => void, onError?: (e: string[]) => void) {
    const images = this.files
      .filter((t) => t.fileFormat === ExportFileFormat.Pdf)
      .sort((a, b) => {
        if (a.index === undefined || b.index === undefined) {
          return 0;
        }
        if (a.index > b.index) {
          return 1;
        } else if (a.index < b.index) {
          return -1;
        }
        return 0;
      });
    window.debug && console.log('saveASPdf');
    console.log(this.ids, images);
    const result = groupBy(images, (t) => t.scale + '');
    Object.values(result).forEach((item) => {
      const pdfname = this.getPdfName(item);
      const options = {
        images: item.map((t) => {
          return {
            value: t.content as string,
            size: t.size ?? {
              width: 100,
              height: 100,
            },
            type: ExportFileFormat.Png,
            scale: t.scale,
            name: t.name,
          };
        }),
        name: pdfname,
      };
      createPdfByImage(options).then(() => {
        onFinish(onSuccess, onError);
      });
    });
  }
}

/**
 * 完成调用
 * @param onSuccess
 * @param onError
 */
const onFinish = (onSuccess?: () => void, onError?: (e: string[]) => void) => {
  // 异常信息
  if (exceptionCollector.length) {
    onError && onError(exceptionCollector.value);
  } else {
    onSuccess && onSuccess();
  }
};
