import * as React from 'react';
import classnames from 'classnames';

import { ITableColumn, ITableData, ITableColumnAlign, IPoint, TableSelection, ICompareRefs } from '../../../../models';

import Checkbox from '../../../Checkbox';

import './index.scss';
import TableCell from './TableCell';

interface ITableRowProps {
  theme: 'red' | 'blue';
  columns: ITableColumn[];
  data: ITableData;
  order: number;
  activeMenu: boolean;
  rowSelectionType: TableSelection;
  checked: (string | number)[];
  cellStyle?: React.CSSProperties;
  searchKey: string;
  disableSelectionRows: (string | number)[];
  onFormat?(data: ITableData, column: ITableColumn, ref?: ICompareRefs): React.ReactChild;
  onClick(data: ITableData): void;
  onContext(data: ITableData, position: IPoint, fromOperateBtn?: boolean): void;
  setChecked(checked: (string | number)[]): void;
  setTip(text: string, style: React.CSSProperties | null): void;
}

const TableRow: React.FC<ITableRowProps> = (props: ITableRowProps) => {
  const {
    theme,
    columns,
    data,
    order,
    activeMenu,
    rowSelectionType,
    checked,
    cellStyle,
    searchKey,
    disableSelectionRows,
    onClick,
    onContext,
    setChecked,
    onFormat,
    setTip,
  } = props;

  const [isHoverOnRow, setIsHoverOnRow] = React.useState(false);
  const trRef = React.useRef<HTMLTableRowElement>(null);

  const onContextMenu = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    onContext(data, { x: e.pageX, y: e.pageY });
  };

  const onCheck = (isCheck: boolean) => {
    if (isCheck) {
      setChecked([...checked, data.id]);
    } else {
      const newChecked = checked.filter((item) => item !== data.id);
      setChecked([...newChecked]);
    }
  };

  const renderSelection = (rowData: ITableData) => {
    switch (rowSelectionType) {
      case TableSelection.Checkbox: {
        const isRowChecked = checked.includes(data.id);
        return (
          <td
            key={'checkbox'}
            className={`selection c-table-column-${ITableColumnAlign.Center}`}
            onClick={(e) => e.stopPropagation()}
          >
            {!disableSelectionRows.includes(rowData.id) && (isHoverOnRow || activeMenu || checked.length > 0) && (
              <Checkbox width={13} checked={isRowChecked} onChange={onCheck} />
            )}
          </td>
        );
      }
      case TableSelection.None:
        return null;
      default:
        break;
    }
  };

  return (
    <tr
      ref={trRef}
      className={classnames('c-table-tr', {
        hover: isHoverOnRow || activeMenu,
      })}
      onMouseEnter={() => setIsHoverOnRow(true)}
      onMouseLeave={() => setIsHoverOnRow(false)}
      onMouseOver={() => !isHoverOnRow && setIsHoverOnRow(true)}
      onClick={() => onClick(data)}
      onContextMenu={(e: React.MouseEvent<HTMLTableRowElement>) => onContextMenu(e)}
    >
      {renderSelection(data)}
      {columns.map((col, index) => (
        <TableCell
          col={col}
          data={data}
          cellStyle={cellStyle}
          isFirstColumn={index === 0 && rowSelectionType !== TableSelection.None}
          order={order}
          isHoverOnRow={isHoverOnRow}
          activeMenu={activeMenu}
          key={col.id}
          searchKey={searchKey}
          theme={theme}
          onFormat={onFormat}
          onContext={onContext}
          setTip={setTip}
        />
      ))}
    </tr>
  );
};

export default TableRow;
