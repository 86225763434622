import { PureColor } from '@fbs/rp/models/properties/color';
import IColor from '@fbs/rp/models/ds/color';

import { put, axDelete, patch, post } from '../helper';

export async function addColor(libID: string, color: PureColor): Promise<IColor> {
  return await put<IColor>(`/ds/color/${libID}`, {
    type: 'pure',
    color,
  });
}

export async function removeColor(id: string): Promise<void> {
  await axDelete(`/ds/color/${id}`);
}

export async function patchColor(id: string, color: Partial<IColor>): Promise<IColor> {
  return await patch(`/ds/color/${id}`, color);
}

export async function moveColor(libID: string, resID: string, index: number): Promise<IColor> {
  return await post<IColor>(`/ds/color/${libID}/move/${resID}`, { index });
}
