import * as React from 'react';
import i18n from '../../../i18n';
import Scrollbars from 'react-custom-scrollbars';

import { IItemProps, IGroupProps } from '../../../models';
import SearchInput from '../../SearchInput';
import CheckGroup from './CheckGroup';
import ScrollView from '../../ScrollView';
import Checkbox from '../../Checkbox';
import SearchList from './SearchList';

import './index.scss';

interface ICheckListProps {
  autoFocus?: boolean;
  searchPlaceholder?: string;
  groups?: IGroupProps[];
  singles?: IItemProps[];
  checked: string[];
  hideGroupCheck?: boolean;
  canCustomStatus?: boolean;
  itemCount: number;
  allItems: IItemProps[];
  enableEmailSearch?: boolean;
  showAddGroup: boolean;
  addGroupTooltip: string;
  enableThirdPartyUUIDSearch?: boolean;

  onCheck(ids: string[]): void;
  onCheckDisable?(id: string): void;
  onSearch?(search?: string): void;
  scrollBottom?(): void;
}

// TODO MXJ 这个文件中加的ts-ignore，由平台组根据实际情况判断是怎么回事，目前不清楚具体使用场景，不好擅作修改，仅为解决ts编译报错
const CheckList: React.FC<ICheckListProps> = (props: ICheckListProps) => {
  const {
    searchPlaceholder,
    groups,
    singles,
    checked,
    hideGroupCheck,
    canCustomStatus,
    itemCount,
    allItems,
    enableEmailSearch,
    showAddGroup,
    addGroupTooltip,
    autoFocus,
    enableThirdPartyUUIDSearch,
    onCheck,
    onSearch,
    onCheckDisable,
    scrollBottom,
  } = props;

  const [searchKey, setSearchKey] = React.useState('');

  let singleGroup: undefined | IGroupProps = undefined;
  let scrollRef: React.RefObject<Scrollbars> = React.createRef();

  React.useEffect(() => {
    onSearch && onSearch(searchKey);
  }, [searchKey]);

  if (singles && singles.length > 0) {
    singleGroup = {
      id: 'single-group',
      name: i18n('checkSelect.unclassified'),
      expand: false,
      children: singles,
    };
  }

  const handleScroll = () => {
    if (scrollRef) {
      // @ts-ignore
      const ScrollTop = scrollRef.view.scrollTop;
      // @ts-ignore
      const ScrollHeight = scrollRef.view.scrollHeight;
      // @ts-ignore
      scrollRef.scrollTop(ScrollTop || ScrollHeight);
    }
  };

  const setScrollbarRef = (scroll: Scrollbars) => {
    // @ts-ignore
    scrollRef = scroll;
  };

  const checkedItem = checked.filter((item) => allItems.some((allItem) => allItem.id === item));

  return (
    <div className="c-check-list-box">
      <div className="search-box">
        <SearchInput
          showClose
          autoFocus={autoFocus}
          placeholder={searchPlaceholder || ''}
          width="100%"
          value={searchKey}
          onChange={setSearchKey}
          onStopSearch={() => setSearchKey('')}
        />
      </div>
      {searchKey ? (
        <SearchList
          searchKey={searchKey}
          allItems={allItems}
          checkIds={checked}
          enableEmailSearch={!!enableEmailSearch}
          enableThirdPartyUUIDSearch={!!enableThirdPartyUUIDSearch}
          hideGroupCheck={hideGroupCheck}
          canCustomStatus={canCustomStatus}
          onCheck={onCheck}
          scrollBottom={scrollBottom}
        />
      ) : (
        <div className="check-container">
          {!hideGroupCheck && canCustomStatus && (
            <div className="check-all">
              <Checkbox
                checked={checkedItem.length !== 0}
                indeterminate={checkedItem.length !== itemCount}
                onChange={() => {
                  if (checkedItem.length === itemCount) {
                    // @ts-ignore
                    const disabled = allItems.filter((item) => item.disabled)?.map((item) => item.id);
                    onCheck([...disabled]);
                  } else {
                    onCheck(allItems.map((item: IItemProps) => item.id));
                  }
                }}
              />
              <span>{i18n('checkSelect.selectAll')}</span>
            </div>
          )}
          <div className="check-list-box">
            <ScrollView
              height={hideGroupCheck || !canCustomStatus ? `calc(100% - 44px)` : `calc(100% - 74px)`}
              // @ts-ignore
              setRef={(scroll: Scrollbars) => {
                // @ts-ignore
                scrollRef = scroll;
              }}
              onScroll={() => {
                // FIXME: ZLG 暂时让滚动条始终有1px的scrollTop，解决scrollTop为0收起分组时，滚动条高度不能自适应问题
                if (scrollRef) {
                  // @ts-ignore
                  const ScrollTop = scrollRef.view.scrollTop;
                  // @ts-ignore
                  !ScrollTop && scrollRef.scrollTop(1);
                  // @ts-ignore
                  const rect = scrollRef.view.getBoundingClientRect();
                  // @ts-ignore
                  const bottom = rect.height + ScrollTop >= scrollRef.getScrollHeight();
                  if (bottom && scrollBottom) {
                    scrollBottom();
                  }
                }
              }}
            >
              {groups &&
                groups.map((group: IGroupProps, index: number) => {
                  const { id, children } = group;
                  if (children && children.length === 0 && !showAddGroup) {
                    return null;
                  }
                  return (
                    <CheckGroup
                      key={id}
                      canCustomStatus={canCustomStatus}
                      group={group}
                      checkIds={checked}
                      hideGroupCheck={hideGroupCheck}
                      showAddGroup={showAddGroup}
                      addGroupTooltip={addGroupTooltip}
                      onCheck={onCheck}
                      handleScroll={handleScroll}
                      onCheckDisable={onCheckDisable}
                    />
                  );
                })}
              {singleGroup && (
                <CheckGroup
                  group={singleGroup}
                  canCustomStatus={canCustomStatus}
                  checkIds={checked}
                  hideGroupCheck={hideGroupCheck}
                  onCheck={onCheck}
                  handleScroll={handleScroll}
                  onCheckDisable={onCheckDisable}
                />
              )}
            </ScrollView>
          </div>
        </div>
      )}
    </div>
  );
};

CheckList.defaultProps = {
  canCustomStatus: true,
};

export default CheckList;
