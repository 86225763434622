// import { IAppF } from '@fbs/idoc/models/app';
import { ModuleType, IEmbedTeam, IEmbedPage, IEmbedApp, IAppPages } from '@fbs/rp/models/embedDesignDraft';
// import { IAppBasicInfo } from '@fbs/rp/models/app';
import { IAppsOfTeam, ILicense, ITeams, IAppsOfRecentEdit } from '@fbs/teamManagement';
import { PDSetting } from '@fbs/idoc/models/PDSetting';
import { isDev } from '@utils/envUtils';

import { get, idocBaseUrl, patch, put } from './helper';

/**
 * 获取团队中所有项目
 * @param {string} teamID
 * @returns {Promise<IAppsOfTeam[]>}
 */
export async function getAllApps(teamID: string): Promise<{ apps: IAppsOfTeam[] }> {
  // return await get<IAppBasicInfo[]>(`/team/apps/${teamID}?rnd=${new Date().getTime()}`);
  return await get<{ apps: IAppsOfTeam[] }>(`/app/all/${teamID}`);
}

/**
 * 获取返回按钮下拉菜单中的中最近编辑的项目
 * @param {string} teamID.
 * @param {number} limit. Defaultvalue :8
 * @returns {Promise<IAppsOfRecentEdit[]>}
 */

export async function getRecentEditeProjects(teamID: string, limit: number = 9): Promise<IAppsOfRecentEdit[]> {
  return await get<IAppsOfRecentEdit[]>(`/app/recent/${teamID}?limit=${limit}`);
}

/**
 * 获取团队信息
 * @param {string} teamID
 * @returns {Promise<ITeams[]>}
 */
export async function getTeamInfo(teamID: string): Promise<ITeams> {
  return get<ITeams>(`/team/${teamID}?isNeedGuestTeams=true&rnd=${new Date().getTime()}`);
}

/**
 * 获取所有团队
 * @returns {Promise<Array<ITeams[]>>}
 */
export async function getAllTeams(isNeedPersonalTeam?: boolean): Promise<ITeams[]> {
  return get<ITeams[]>(
    `/team/all?isNeedGuestTeams=true&rnd=${new Date().getTime()}&isNeedPersonalTeams=${
      isNeedPersonalTeam ? isNeedPersonalTeam : ''
    }`,
  );
  // return get<ITeams[]>(`/team?isNeedGuestTeams=true&rnd=${new Date().getTime()}`);
}

/**
 * 获取回收站的项目
 * @param teamID
 */
export async function getRecycle(teamID: string): Promise<IAppsOfTeam[]> {
  return get<IAppsOfTeam[]>(`/app/recycle/${teamID}`);
}

/**
 * 切换团队
 * @param teamID
 */
export async function changeTeam(teamID: string) {
  return patch(`/user/patch`, { defaultIDocTeamID: teamID });
}

/**
 * 创建团队
 * @param name
 */
export async function createTeam(name: string): Promise<{ id: string }> {
  return put(`${window.location.origin}/api/v1/team`, { name });
}

// /**
//  * 获取团队部门列表
//  * @param teamID
//  */
// export function getTeamGroups(teamID: string) {
//   return get<ITeamGroup[]>(`${idocBaseUrl}/teamGroup/${teamID}`);
// }

/**
 * 快照组件中获取团队
 */
export function getSnapshotTeams() {
  return get<IEmbedTeam[]>(`/designDraftEmbed/teams`);
}

/**
 * 快照组件中获取项目和项目集
 * @param teamID
 */
export function getSnapshotProjects(teamID: string) {
  return get<IEmbedApp[]>(`/designDraftEmbed/apps/${teamID}`);
}

/**
 * 快照组件中获取页面
 * @param appID
 * @param moduleType
 */
export function getSnapshotPage(appID: string, moduleType: string = ModuleType.Design) {
  return get<IEmbedPage[]>(`/designDraftEmbed/pages/${appID}/${moduleType}`);
}

/**
 * 快照组件中添加新页面
 * @param appID
 * @param moduleType
 * @param pageID
 */
export function addNewSnapshotPage(appID: string, pageID: string, moduleType: string = ModuleType.Design) {
  return put(`/designDraftEmbed/page/${appID}/${moduleType}/${pageID}`);
}

/**
 * 项目中中获取存储的快照页面资源
 * @param appID
 */
export function getSnapshotAllPages(appID: string) {
  return get<IAppPages>(`/designDraftEmbed/allPages/${appID}`);
}

export function getPDConfigs() {
  return get<PDSetting>('/pdConfig');
}

export async function getLicense() {
  const result = await get<{ license: ILicense }>(
    `${isDev ? idocBaseUrl : `${location.origin}/api/v1`}/software/license`,
  );
  return result.license;
}

/**
 * 创建个人空间
 */
export async function createPersonalSpace() {
  return await put<{ team: ITeams }>(`${idocBaseUrl}/team`, { type: 'personal' });
}
