import * as React from 'react';
import classnames from 'classnames';

import { isMockRPD } from '@utils/envUtils';

interface IProps {
  size?: 'default' | 'mini';
  isLoading?: boolean;
  pastDelay?: boolean;
  timedOut?: boolean;
  error?: any;
  retry?: () => void;
  theme?: 'dark' | 'light' | string;
}

const Loading = (props: IProps) => {
  const { size, theme } = props;
  if (location.pathname.includes('standalone')) {
    document.body.style.backgroundColor = 'transparent';
  }
  return (
    <div
      className={classnames('global-app-loading', size || 'default', `global-app-loading-${theme || 'dark'}`, {
        web: !isMockRPD,
      })}
    >
      <div className="app-global-loading"></div>
    </div>
  );
};

export default Loading;
