import PropertyBase from './base';
import { Color } from './color';

export const IconPropertyName = 'icon';

interface IIcon extends PropertyBase {
  color?: Color;
  fontWeightHidden?: boolean; // 隐藏图标粗细设置
}

export default IIcon;

