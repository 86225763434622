import PropertyBase from './base';
import IFill from './fill';

export enum IndicatorType {
  Circle = 'circle',
  Rect = 'rect',
}

export enum EffectType {
  Push = 'push',
  Slide = 'slide',
  Fade = 'fade',
}

export const CarouselChartPropertyName = 'carouselChart';

interface ICarouselChart extends PropertyBase {
  showAutoplay: boolean; // 自动播放
  indicator: {
    // 指示器
    showIndicator: boolean; // 显示指示器
    indicatorType: IndicatorType; // 指示器类型
    defaultColor: IFill; // 指示器颜色
    activeColor: IFill; //选中颜色
  };
  showPageTurnBtn: boolean; // 显示翻页按钮
  effect: EffectType; // 切换效果
  playInterval: number; // 间隔时间
  playSpeed: number; // 动画时长
}

export default ICarouselChart;
