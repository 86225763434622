/* eslint-disable @typescript-eslint/no-unused-vars */
import { IComponentData } from '@fbs/rp/models/component';
import { IBasicLayout, HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import { ArtboardPatches, Ops, PagePatches } from '@fbs/rp/utils/patch';
import * as BoundsUtils from '@utils/boundsUtils';
import { depthClone, sameNumber } from '@utils/globalUtils';

import { IBoundsOffset } from '@fbs/common/models/common';
import { collectComponentsLayout } from '@helpers/responseLayoutHelper';
import { coverPatches } from '@helpers/patchHelper';
import { updateConnectComponentPatches, updateStickNoteConnectCompPatches } from '@helpers/pathFinderHelper';
import BasicComponentLib from '@libs/basic';
import { CCanvasPanel, CSymbol, CLine } from '@libs/constants';
import { IUICompConstructOptions } from '@/customTypes';

import {
  ComponentChange,
  ContainerPatches,
  ComponentChangeType,
  updateEditComponentsPatches,
  ComponentResizeResult,
  ResizeOptions,
  getNewPositionWhenCenter,
  getAdjustedZeroOffset,
  updateStickNoteEndPoint,
} from './resizeHelper';
import { UIComponent, UIContainerComponent } from '.';

/**
 * 面板组件UI类
 */
export default class UIPanelComponent extends UIContainerComponent {
  constructor(data: IComponentData, public parent?: UIContainerComponent, public options?: IUICompConstructOptions) {
    super(data, parent, options);
    if (
      !this.options?.isInAdvanceEditor &&
      data.properties &&
      data.type === CCanvasPanel &&
      !(this.isSealed || this.nearestSealedComponent)
    ) {
      const defaultProperties = BasicComponentLib.make(CCanvasPanel).properties;
      data.properties = {
        ...data.properties,
        container: Object.assign(defaultProperties.container!, data.properties.container || {}),
      };
    }
  }

  public addComponents(
    components: IComponentData[],
    index = -1,
  ): {
    patches: PagePatches;
    newActiveGroup?: UIContainerComponent;
  } {
    const patches: PagePatches = {
      [this.ownerArtboardID]: {
        do: {
          [this.id]: [Ops.addChildren(`${index}`, components)],
        },
        undo: {
          [this.id]: [Ops.removeChildren(components.map((comp) => comp._id))],
        },
      },
    };
    return { patches };
  }

  // 子的改变引发的位置改变
  getPositionPatchesOfChildrenChanged(changes: ComponentChange[]): ContainerPatches {
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    // added, removed 让调用者处理
    // 处理 edit 的组件
    updateEditComponentsPatches(
      this.components,
      changes,
      {
        x: 0,
        y: 0,
      },
      patches,
    );
    //面板内移动需要重置相关连接线的path, point
    updateConnectComponentPatches(this, changes, patches);

    // 便签条组件移动和resize，重置连接结束点位置
    updateStickNoteEndPoint(this.components, changes, patches);

    // 便签条关联组件变化，同步修改便签条信息
    updateStickNoteConnectCompPatches(this, changes, patches);
    return {
      patches,
    };
  }

  public resizeHandler2(
    offset: IBoundsOffset,
    layout: IBasicLayout,
    options: ResizeOptions,
    backupAllCompsLayout?: WeakMap<UIComponent, IBasicLayout>,
  ): ComponentResizeResult {
    const { size } = this;
    const myOptions = depthClone(options);
    const info = super.resizeHandler2(getAdjustedZeroOffset(offset), layout, myOptions);
    const realOffset = this.getRealOffsetForChild(offset, info);
    // 如果容器没有发生 resize，子组件就不用做任何修改了
    const isOnlyPositionChange =
      sameNumber(realOffset.right, realOffset.left) && sameNumber(realOffset.top, realOffset.bottom);

    if (isOnlyPositionChange) {
      return {
        position: info.position,
        size: info.size,
        rotate: this.rotate,
      };
    }
    const layoutMap =
      backupAllCompsLayout ||
      collectComponentsLayout(this.componentsExceptConnector, BoundsUtils.createBoundsBySize(this.size));

    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    // 坐标系偏移，在计算 panel 中组件偏移时需要计算进去
    const coordinateOffset = this.getCoordinateOffset(info);
    const childrenResizeOptions = {
      container: {
        before: {
          position: {
            x: 0,
            y: 0,
          },
          size,
        },
        after: {
          position: this.getLeftTopChangeForChildren(info),
          size: info.size,
        },
        isResponsive: this.layout.responsive,
      },
      shift: options.shift,
      scale: {
        h: info.size.width / size.width,
        v: info.size.height / size.height,
      },
    };
    const isInSealedComp = this.isInSuchParent((comp) => comp.isSealed);
    // 只有在复合组件中或者画板打开了响应式 才影响子
    const isNeedChangeChildren =
      this.components.length > 0 && (isInSealedComp || this.layout.responsive || this.isSealed);
    if (isNeedChangeChildren) {
      const newComponentsInfo = this.components
        .filter((comp) => !comp.isConnector)
        .map((comp) => {
          const newCompInfo = comp.resizeHandler2(realOffset, layoutMap.get(comp)!, childrenResizeOptions);
          if (newCompInfo.patches) {
            coverPatches(patches, newCompInfo.patches);
          }
          let newPosition = {
            x: newCompInfo.position.x + coordinateOffset.x,
            y: newCompInfo.position.y + coordinateOffset.y,
          };
          // 如果组件是居中的话 要重置
          const layout = layoutMap.get(comp)!;
          let setting;
          if (this.components.length === 1 && this.type === CSymbol && comp.type === CLine) {
            setting = {
              isLayoutMiddleAtVertical: layout.vertical === VerticalAlign.Middle,
              isLayoutCenterAtHorizontal: layout.horizontal === HorizontalAlign.Center,
            };
          }
          newPosition = getNewPositionWhenCenter(comp, newPosition, newCompInfo.size, info.size, setting);
          return {
            id: comp.id,
            type: ComponentChangeType.Edit,
            position: newPosition,
            size: newCompInfo.size,
            rotate: newCompInfo.rotate,
          };
        });
      const res = this.getPositionPatchesOfChildrenChanged(newComponentsInfo);
      if (res.patches) {
        coverPatches(patches, res.patches);
      }
    }
    return {
      position: info.position,
      size: info.size,
      rotate: this.rotate,
      patches,
    };
  }
}
