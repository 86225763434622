import { LRUMap } from 'lru_map';

import { IBounds } from '@fbs/common/models/common';

class CachedBounds {
  private _map: LRUMap<string, IBounds>;
  private _limit: number = 1000;
  constructor() {
    this._map = new LRUMap(this._limit);
  }
  clear() {
    this._map.clear();
  }

  get(id: string) {
    return this._map.get(id);
  }

  set(compId: string, compBounds: IBounds) {
    this._map.set(compId, compBounds);
  }
}
const cachedBounds = new CachedBounds();

export default cachedBounds;
