import * as React from 'react';
import ScrollView from '../../ScrollView';

import { IItemProps } from '../../../models';
import Scrollbars from 'react-custom-scrollbars';
import CheckItem from './CheckItem';

import i18n from '../../../i18n';

import './SearchList.scss';

interface ISearchListProps {
  searchKey: string;
  allItems: IItemProps[];
  checkIds: string[];
  enableEmailSearch: boolean;
  hideGroupCheck?: boolean;
  canCustomStatus?: boolean;
  enableThirdPartyUUIDSearch: boolean;

  scrollBottom?(): void;
  onCheck(ids: string[]): void;
}

const SearchList: React.FC<ISearchListProps> = (props: ISearchListProps) => {
  let scrollRef: React.RefObject<Scrollbars> = React.createRef();

  const {
    searchKey,
    allItems,
    checkIds,
    enableEmailSearch,
    hideGroupCheck,
    canCustomStatus,
    enableThirdPartyUUIDSearch,
    onCheck,
    scrollBottom,
  } = props;
  const lowerSearchKey = searchKey.trim().toLocaleLowerCase();
  const searchItems = allItems.filter((item: IItemProps) => {
    if (enableEmailSearch) {
      return (
        (item.name && item.name.toLocaleLowerCase().includes(lowerSearchKey)) ||
        // @ts-ignore
        (item.pinyinName && item.pinyinName.toLocaleLowerCase().includes(lowerSearchKey)) ||
        (item.email && item.email.toLocaleLowerCase().includes(lowerSearchKey))
      );
    }
    if (enableThirdPartyUUIDSearch) {
      return (
        (item.name && item.name.toLocaleLowerCase().includes(lowerSearchKey)) ||
        // @ts-ignore
        (item.pinyinName && item.pinyinName.toLocaleLowerCase().includes(lowerSearchKey)) ||
        (item.thirdPartyUUID && item.thirdPartyUUID.toLocaleLowerCase().includes(lowerSearchKey))
      );
    }
    return (
      (item.name && item.name.toLocaleLowerCase().includes(lowerSearchKey)) ||
      // @ts-ignore
      (item.pinyinName && item.pinyinName.toLocaleLowerCase().includes(lowerSearchKey))
    );
  });

  return (
    <div className="search-wrap">
      {searchItems.length > 0 ? (
        <ScrollView
          height={hideGroupCheck || !canCustomStatus ? `calc(100% - 44px)` : `calc(100% - 74px)`}
          // @ts-ignore
          setRef={(scroll: Scrollbars) => {
            // @ts-ignore
            scrollRef = scroll;
          }}
          onScroll={() => {
            // FIXME: ZLG 暂时让滚动条始终有1px的scrollTop，解决scrollTop为0收起分组时，滚动条高度不能自适应问题
            if (scrollRef) {
              // @ts-ignore
              const ScrollTop = scrollRef.view.scrollTop;
              // @ts-ignore
              !ScrollTop && scrollRef.scrollTop(1);
              // @ts-ignore
              const rect = scrollRef.view.getBoundingClientRect();
              // @ts-ignore
              const bottom = rect.height + ScrollTop >= scrollRef.getScrollHeight();
              if (bottom && scrollBottom) {
                scrollBottom();
              }
            }
          }}
        >
          {searchItems.map((item: IItemProps) => (
            <CheckItem
              item={item}
              showEmail={!!searchKey && enableEmailSearch}
              showThirdPartyUUID={!!searchKey && enableThirdPartyUUIDSearch}
              checked={checkIds.includes(item.id)}
              onCheck={(id: string) => {
                if (checkIds.includes(id)) {
                  onCheck(checkIds.filter((checked) => checked !== id));
                } else {
                  onCheck([...checkIds, id]);
                }
              }}
            />
          ))}
        </ScrollView>
      ) : (
        <div className="no-result">{i18n('checkSelect.hasNotFound')}</div>
      )}
    </div>
  );
};

export default SearchList;
