import { isUndefined } from 'lodash';

import { loadFromCache, saveToCache, removeToCache } from '@utils/cacheUtils';
import mathUtils from '@utils/mathUtils';
import { IFill } from '@utils/graphicsUtils';

import {
  CommandType,
  IComponentAction,
  IFragmentAction,
  IPageAction,
  ILinkAction,
  PageSkipEffectType,
} from '@fbs/rp/models/interactions';
import { IPosition } from '@/fbs/common/models/common';
import { IComponentData } from '@fbs/rp/models/component';
import { ITableCell } from '@fbs/rp/models/table';
import { MobileType } from '@fbs/rp/utils/preview';
import { ISelectedParams } from '@/fbs/rp/models/value';
import { ControlPanelState } from '@/fbs/rp/models/preview';
import { IAppType } from '@fbs/teamManagement';
import INoteConnectedLine from '@/fbs/rp/models/properties/noteConnectedLine';

import { IComponentItem } from '@libs/types';
import { IconType } from '@libs/enum';
import { COMP_LIB_TAB_ITEMS } from '@/consts/enums/leftPanelType';

interface IPublishCacheData {
  cooperationID: string;
  prototypeID: string;
  taskID?: string;
  progress?: number;
}

interface IPublishCache {
  [appID: string]: IPublishCacheData;
}

interface ICreateApp {
  appType: IAppType;
  customSize: {
    width: number;
    height: number;
  };
  /**
   * 选中索引
   */
  selected: {
    pad: number;
    phone: number;
    web: number;
    watch: number;
    vehicle: number;
  };
}

const defaultCreateApp = {
  appType: 'web',
  customSize: {
    width: 1000,
    height: 1000,
  },
  selected: {
    pad: 0,
    phone: 0,
    web: 0,
    watch: 0,
    vehicle: 0,
  },
};

interface IPreviewOption {
  showLinkAreaWhenHovered?: boolean;
  alwaysShowLinkArea?: boolean;
  autoScreen?: boolean;
  mobileType?: MobileType;
  noBoundary?: boolean;
}

interface IShareOption extends IPreviewOption {
  controlPanelState?: ControlPanelState;
  remarkPanelState?: ControlPanelState;
}

interface IPreviewPanel {
  controlPanelState?: boolean;
  remarkPanelState?: boolean;
}

interface ITableClipboardData {
  cells: ITableCell[][];
  rowHeights?: (number | undefined)[];
  colWidths?: (number | undefined)[];
  components?: IComponentData[];
  cellComponents?: IComponentData[];
}

enum SnapshotSize {
  half = 0.5,
  one = 1,
  two = 2,
  fitComp = 'fitComp',
}

interface ISearchCache {
  component?: string;
  componentLib?: string;
  icon?: string;
  resource?: string;
  iconType?: string;
  layer?: string;
  page?: string;
}

interface ISelectGroupOption {
  libID: string;
  groupID?: string;
}

interface ISelectGroupsOption {
  libID: string;
  groupIDs?: string[];
}

interface ILibGroupsOption {
  [key: string]: string[] | undefined;
}

interface IPreviewNavbarOpt {
  id: string;
  position: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  collapsed: boolean;
}

export interface WorkspaceCatchApp {
  appId: string;
  pages: WorkspaceCatchPage[];
}

export interface WorkspaceCatchPage {
  pageId: string;
  position: IPosition;
  // 0 ~ 100
  scaleFactor: number;
}

interface ICommonObject<T> {
  [key: string]: T;
}

const OriginScale = 100;
const DefaultAxis = 0;
const DefaultPosition = { x: DefaultAxis, y: DefaultAxis };

const MAX_WORKSPACE_CATCH_LENGTH = 2;
const ALL_SETS = 'root';

/**
 * 应用全局设置管理
 */
class AppOptions {
  private cache: { [key: string]: any };
  private memoryCache: { [key: string]: any };

  private searchCache: ISearchCache = {};

  private _previewScale: number = 1;

  constructor() {
    this.cache = {};
    this.memoryCache = {};
    // 删除旧的发布功能变更弹窗显示控制字段缓存
    this.removeValue('showPublishChangeTip');
  }

  private getValue(key: string, defaultValue?: any): any {
    let value = this.cache[key];
    if (isUndefined(value)) {
      value = loadFromCache(key, defaultValue);
    }
    this.cache[key] = value;
    return value;
  }

  private saveValue(key: string, value: any) {
    new Promise(() => {
      saveToCache(key, value);
    });
    this.cache[key] = value;
  }

  private removeValue(key: string): void {
    removeToCache(key);
    delete this.cache[key];
  }

  get appID(): string {
    return this.cache['currentAppID'] ?? '';
  }

  set appID(id: string) {
    this.cache['currentAppID'] = id;
  }

  get pageID(): string {
    return this.cache['currentPageID'] ?? '';
  }

  set pageID(id: string) {
    this.cache['currentPageID'] = id;
  }

  get workspaceCatch(): WorkspaceCatchApp[] {
    return this.getValue('WORKSPACE', []);
  }

  set workspaceCatch(appCatch: WorkspaceCatchApp[]) {
    this.saveValue('WORKSPACE', appCatch);
  }

  private getCurrentAppWorkspaceCatch(): WorkspaceCatchPage | undefined {
    const { appID, pageID } = this;
    return this.getWorkspaceCatch(appID, pageID);
  }

  public getWorkspaceCatch(appID: string, pageID: string): WorkspaceCatchPage | undefined {
    return this.workspaceCatch.find((item) => item.appId === appID)?.pages.find((item) => item.pageId === pageID);
  }

  public savePageWorkspaceCatch(
    appId: string,
    pageId: string,
    data: {
      scaleFactor?: number;
      position?: IPosition;
    },
  ) {
    const { scaleFactor, position } = data;

    if (!scaleFactor && !position) return;

    const newScaleFactor = scaleFactor ?? OriginScale;
    const newPosition = position ?? DefaultPosition;

    const catchCopy = this.workspaceCatch;

    const app = catchCopy.find((item) => item.appId === appId);
    if (!app) {
      if (catchCopy.length >= MAX_WORKSPACE_CATCH_LENGTH) {
        catchCopy.shift();
      }
      catchCopy.push({
        appId,
        pages: [{ pageId, scaleFactor: newScaleFactor, position: newPosition }],
      });
      return (this.workspaceCatch = catchCopy);
    }

    const page = app?.pages.find((item) => item.pageId === pageId);
    if (!page) {
      app?.pages.push({ pageId, scaleFactor: newScaleFactor, position: newPosition });
      return (this.workspaceCatch = catchCopy);
    }

    scaleFactor && (page.scaleFactor = scaleFactor);
    position && (page.position = position);
    return (this.workspaceCatch = catchCopy);
  }

  // 0 ~ 100
  get scaleValue(): number {
    return this.getCurrentAppWorkspaceCatch()?.scaleFactor ?? OriginScale;
  }

  set scaleValue(value: number) {
    const { appID: appId, pageID: pageId } = this;
    if (!appId || !pageId) return;
    this.savePageWorkspaceCatch(appId, pageId, { scaleFactor: value });
  }

  get pagePosition(): IPosition {
    return this.getCurrentAppWorkspaceCatch()?.position ?? DefaultPosition;
  }

  set pagePosition(value: IPosition) {
    const { appID: appId, pageID: pageId } = this;
    if (!appId || !pageId) return;
    this.savePageWorkspaceCatch(appId, pageId, { position: value });
  }

  get snapshotSelectedInfo() {
    return this.getValue('snapshotSelectedInfo', {});
  }

  set snapshotSelectedInfo(value: ISelectedParams) {
    this.saveValue('snapshotSelectedInfo', value);
  }

  get snapshotSizeSelectedInfo(): SnapshotSize {
    return this.getValue('snapshotSizeSelectedInfo', SnapshotSize.one);
  }

  set snapshotSizeSelectedInfo(value: SnapshotSize) {
    this.saveValue('snapshotSizeSelectedInfo', value);
  }

  get navigationMapVisible(): boolean {
    return this.getValue('showNavigation', false);
  }

  set navigationMapVisible(value: boolean) {
    this.saveValue('showNavigation', value);
  }

  get leftPanelType(): number {
    return this.getValue('leftPanelType', 2);
  }

  set leftPanelType(value: number) {
    this.saveValue('leftPanelType', value);
  }

  get leftPanelWidth(): number {
    return this.getValue('leftPanelWidth', 240);
  }

  set leftPanelWidth(value: number) {
    this.saveValue('leftPanelWidth', value);
  }

  get leftPanelActiveTabs(): {
    above?: number;
    below?: number;
  } {
    return this.getValue('leftPanelActiveTabs', {
      above: 0,
      below: 0,
    });
  }

  set leftPanelActiveTabs(tabs: { above?: number; below?: number }) {
    this.saveValue('leftPanelActiveTabs', {
      ...this.leftPanelActiveTabs,
      ...tabs,
    });
  }

  get leftPanelDragHeight(): number {
    // 上方面板的高度比率
    return this.getValue('leftPanelDragHeight');
  }

  set leftPanelDragHeight(value: number) {
    this.saveValue('leftPanelDragHeight', value);
  }

  get leftPanelDragDefaultPxHeight(): number {
    // 左侧上方面板默认像素高度
    return 240;
  }

  get previewControllerPanelWidth(): number {
    return this.getValue('previewControllerPanelWidth', 280);
  }

  set previewControllerPanelWidth(value: number) {
    this.saveValue('previewControllerPanelWidth', value);
  }

  get rightPanelVisible() {
    return this.getValue('rightPanelVisible', true);
  }

  set rightPanelVisible(value: boolean) {
    this.saveValue('rightPanelVisible', value);
  }

  getIconLibrariesCollapse(defaultValue: string[]): string[] {
    return this.getValue('iconLibrariesCollapseList', defaultValue);
  }

  set iconLibrariesCollapse(value: string[]) {
    this.saveValue('iconLibrariesCollapseList', value);
  }

  get iconType() {
    return this.getValue('iconType', IconType.light);
  }

  set iconType(type: IconType) {
    this.saveValue('iconType', type);
  }

  get tempIconCode() {
    return this.getValue('tempIconCode', '');
  }

  set tempIconCode(code: string) {
    this.saveValue('tempIconCode', code);
  }

  get componentLibrariesCollapse() {
    return this.getValue('compLibCollapseList', []);
  }

  set componentLibrariesCollapse(value: string[]) {
    this.saveValue('compLibCollapseList', value);
  }

  get propertyPanelSelected() {
    return this.getValue('propertyPanelSelected');
  }

  set propertyPanelSelected(value: any) {
    this.saveValue('propertyPanelSelected', value);
  }

  get propertyItemCollapse(): { [key: string]: boolean } {
    return this.getValue('property-item-collapse', {});
  }

  set propertyItemCollapse(value: { [key: string]: boolean }) {
    this.saveValue('property-item-collapse', value);
  }

  get statePanelVisible(): boolean {
    return this.getValue('statePanelVisible', true);
  }

  set statePanelVisible(value: boolean) {
    this.saveValue('statePanelVisible', value);
  }

  get artboardPanelVisible(): boolean {
    return this.getValue('artboardPanelVisible', false);
  }

  set artboardPanelVisible(value: boolean) {
    this.saveValue('artboardPanelVisible', value);
  }

  getStatePanelPoint(defaultValue: { left: number; top: number }): { left: number; top: number } {
    return this.getValue('state-panel-point', defaultValue);
  }

  set statePanelPoint(value: { left: number; top: number }) {
    this.saveValue('state-panel-point', value);
  }

  getArtpanelPanelPoint(defaultValue: { left: number; top: number }): { left: number; top: number } {
    return this.getValue('artboard-panel-point', defaultValue);
  }

  set artboardPanelPoint(value: { left: number; top: number }) {
    this.saveValue('artboard-panel-point', value);
  }

  getFindReplaceToolPanelPoint(defaultValue: { left: number; top: number }): { left: number; top: number } {
    return this.getValue('find-replace-tool-panel-point', defaultValue);
  }

  set findReplaceToolPanelPoint(value: { left: number; top: number }) {
    this.saveValue('find-replace-tool-panel-point', value);
  }

  get resourceGroupCollapse(): string[] {
    return this.getValue('resourceGroupCollpase', []);
  }

  set resourceGroupCollapse(value: string[]) {
    this.saveValue('resourceGroupCollpase', value);
  }

  get resourceLibrariesLayout(): 'list' | 'grid' {
    return this.getValue('resourceLibrariesLayout', 'list');
  }

  set resourceLibrariesLayout(value: 'list' | 'grid') {
    this.saveValue('resourceLibrariesLayout', value);
  }

  set fileUploadOption(value: { fitComp: boolean; holdRetio: boolean }) {
    this.saveValue('fileUploadOption', value);
  }

  get fileUploadOption(): {
    fitComp: boolean;
    holdRetio: boolean;
  } {
    return this.getValue('fileUploadOption', { fitComp: true, holdRetio: true });
  }

  // 演示设置
  set previewOption(value: { appID: string; option: IPreviewOption }[]) {
    this.saveValue('previewOption', value);
  }

  get previewOption(): { appID: string; option: IPreviewOption }[] {
    return this.getValue('previewOption', []);
  }

  set previewPanel(value: { appID: string; option: IPreviewPanel }[]) {
    this.saveValue('previewPanel', value);
  }

  get previewPanel(): { appID: string; option: IPreviewPanel }[] {
    return this.getValue('previewPanel', []);
  }

  // 演示分享设置
  set shareOption(value: { appID: string; option: IShareOption }[]) {
    this.saveValue('shareOptions', value);
  }

  get shareOption(): { appID: string; option: IShareOption }[] {
    return this.getValue('shareOptions', []);
  }

  // 缓存设置的交互
  set prevCompActionCommandType(commandType: CommandType | undefined) {
    this.saveValue('prevCompActionCommandType', commandType);
  }

  get prevCompActionCommandType(): CommandType | undefined {
    return this.getValue('prevCompActionCommandType');
  }

  set prevCompActions(value: { [type: string]: IComponentAction } | undefined) {
    this.saveValue('prevCompAction', value);
  }

  get prevCompActions(): { [type: string]: IComponentAction } | undefined {
    return this.getValue('prevCompAction');
  }

  set prevPageAction(value: IPageAction | undefined) {
    this.saveValue('prevPageAction', value);
  }

  get prevPageAction(): IPageAction | undefined {
    return this.getValue('prevPageAction');
  }

  set prevLinkAction(value: ILinkAction | undefined) {
    this.saveValue('prevLinkAction', value);
  }

  get prevLinkAction(): ILinkAction | undefined {
    return this.getValue('prevLinkAction');
  }

  set prevFragmentAction(value: IFragmentAction | undefined) {
    this.saveValue('prevFragmentAction', value);
  }

  get prevFragmentAction(): IFragmentAction | undefined {
    return this.getValue('prevFragmentAction');
  }

  set prevFragmentCustomEffect(value: PageSkipEffectType | undefined) {
    this.saveValue('prevFragmentCustomEffect', value);
  }

  get prevFragmentCustomEffect(): PageSkipEffectType | undefined {
    return this.getValue('prevFragmentCustomEffect');
  }

  set prevFragmentCenterEffect(value: PageSkipEffectType | undefined) {
    this.saveValue('prevFragmentCenterEffect', value);
  }

  get prevFragmentCenterEffect(): PageSkipEffectType | undefined {
    return this.getValue('prevFragmentCenterEffect');
  }

  getLastSearchValue(type: keyof ISearchCache): string {
    return this.searchCache[type] || '';
  }

  cacheLastSearchValue(type: keyof ISearchCache, value: string) {
    this.searchCache[type] = value;
  }

  get showProjectTreeSearchBox(): boolean {
    return this.getValue('showProjectTreeSearchBox', false);
  }

  set showProjectTreeSearchBox(isShow: boolean) {
    this.saveValue('showProjectTreeSearchBox', isShow);
  }

  // 是否跳过添加组为交互目标的教学弹窗
  get skipSelectingGroupTeaching(): boolean {
    return !!this.getValue('skipSelectingGroupTeaching');
  }

  set skipSelectingGroupTeaching(value: boolean) {
    this.saveValue('skipSelectingGroupTeaching', value);
  }

  // 正在使用的设计资源库ID
  get lastUsedDesignRepositoryID(): string {
    return this.getValue('lastUsedDesignRepositoryID');
  }

  set lastUsedDesignRepositoryID(value: string) {
    this.saveValue('lastUsedDesignRepositoryID', value);
  }

  getLastSelectComponentGroup(libID: string): string | undefined {
    const groups: { [key: string]: string } = this.getValue('lastSelectCompResGroup', {});
    return groups[libID];
  }

  setLastSelectComponentGroup(selectGroupOption: ISelectGroupOption) {
    const { libID, groupID } = selectGroupOption;
    let groups = this.getValue('lastSelectCompResGroup', {}) as { [key: string]: string | undefined };
    groups = {
      ...groups,
      [libID]: groupID,
    };
    this.saveValue('lastSelectCompResGroup', groups);
  }

  get syncComponentResourceByAdd(): boolean {
    return this.getValue('syncComponentResourceByAdd', false);
  }

  set syncComponentResourceByAdd(value: boolean) {
    this.saveValue('syncComponentResourceByAdd', value);
  }

  private get selectedResourcesWithGroupID(): ILibGroupsOption {
    return this.getValue('selectedResourcesWithGroupID', {}) as ILibGroupsOption;
  }

  getSelectResourceGroupIDs(libID: string): string[] | undefined {
    return this.selectedResourcesWithGroupID[libID];
  }

  setSelectResourceGroupIDs(selectGroupsOption: ISelectGroupsOption) {
    const { libID, groupIDs } = selectGroupsOption;
    this.saveValue('selectedResourcesWithGroupID', {
      ...this.selectedResourcesWithGroupID,
      [libID]: groupIDs,
    });
  }

  get minimalist(): boolean {
    return this.getValue('minimalist', true);
  }

  set minimalist(value: boolean) {
    this.saveValue('minimalist', value);
  }

  get previewControllPanelWidth(): number {
    return this.getValue('previewControllPanelWidth', 280);
  }

  set previewControllPanelWidth(value: number) {
    this.saveValue('previewControllPanelWidth', value);
  }

  get showPageNumber(): boolean {
    return this.getValue('showPageNumber', false);
  }

  set showPageNumber(value: boolean) {
    this.saveValue('showPageNumber', value);
  }

  get showTrash() {
    return this.getValue('showTrash', false);
  }

  set showTrash(value: boolean) {
    this.saveValue('showTrash', value);
  }

  get previewScale(): number {
    const value = parseInt(this.getValue('previewScale', 100));
    return mathUtils.max(0, isNaN(value) ? 1 : value);
  }

  set previewScale(value: number) {
    this.saveValue('previewScale', value);
  }

  // 0 ~ 1
  get scale(): number {
    return this.scaleValue / OriginScale;
  }

  get defaultNoneImageModel(): 'empty' | 'placeholder' {
    return this.getValue('imageStyle', 'placeholder');
  }

  set defaultNoneImageModel(value: 'empty' | 'placeholder') {
    this.saveValue('imageStyle', value);
  }

  /**
   * 最近打开的项目列表
   * @returns {string[]}
   */
  get recentOpenProjects(): string[] {
    return this.getValue('recentOpenProjects', []);
  }

  addRecentOpenProject(projectID: string) {
    const recentOpenProjects = this.recentOpenProjects;
    if (recentOpenProjects.indexOf(projectID) === -1) {
      this.saveValue('recentOpenProjects', [...recentOpenProjects, projectID]);
    }
  }

  get strokeDashMode(): 'preset' | 'custom' {
    return this.getValue('strokeDashMode', 'custom');
  }

  set strokeDashMode(value: 'preset' | 'custom') {
    this.saveValue('strokeDashMode', value);
  }

  get previewNavbarOpt(): IPreviewNavbarOpt[] {
    return this.getValue('previewNavbarOpt', []);
  }

  set previewNavbarOpt(value: IPreviewNavbarOpt[]) {
    this.saveValue('previewNavbarOpt', value);
  }

  get lastSelectedTeamIDs(): { [key: number]: string | undefined } {
    return this.getValue('lastSelectedTeamIDs', {});
  }

  set lastSelectedTeamIDs(value: { [key: number]: string | undefined }) {
    this.saveValue('lastSelectedTeamIDs', value);
  }

  getLastSelectedTeamIDByUserID(userID: number) {
    return this.lastSelectedTeamIDs[userID];
  }

  setLastSelectedTeamIDByUserID(userID: number, value?: string) {
    this.lastSelectedTeamIDs = {
      ...this.lastSelectedTeamIDs,
      [userID]: value,
    };
  }

  get lastSelectedDesktopPanel() {
    return this.getValue('lastSelectedDesktopPanel');
  }

  set lastSelectedDesktopPanel(value: string) {
    this.saveValue('lastSelectedDesktopPanel', value);
  }

  get needScaleRadius(): boolean {
    return this.getValue('needScaleRadius', false);
  }

  set needScaleRadius(value: boolean) {
    this.saveValue('needScaleRadius', value);
  }

  get needScaleShadow(): boolean {
    return this.getValue('needScaleShadow', false);
  }

  set needScaleShadow(value: boolean) {
    this.saveValue('needScaleShadow', value);
  }

  set lastIconSize(value: number) {
    this.cache['lastIconSize'] = value;
  }

  get lastIconSize(): number {
    return this.cache['lastIconSize'] || 24;
  }

  get ruleVisible() {
    return this.getValue('showRuler', true);
  }

  set ruleVisible(value: boolean) {
    this.saveValue('showRuler', value);
  }

  get showAllGuides() {
    return this.getValue('showAllGuides', true);
  }

  set showAllGuides(value: boolean) {
    this.saveValue('showAllGuides', value);
  }

  get showActiveFlag() {
    return this.getValue('showActiveFlag', true);
  }

  set showActiveFlag(value: boolean) {
    this.saveValue('showActiveFlag', value);
  }

  get showRemarkNum() {
    return this.getValue('showRemarkNum', true);
  }

  set showRemarkNum(value: boolean) {
    this.saveValue('showRemarkNum', value);
  }

  get appModule(): 'rp' | 'all' {
    return this.getValue('appModule', 'rp');
  }

  set appModule(value: 'rp' | 'all') {
    this.saveValue('appModule', value);
  }

  get appOrderType(): 'createdAt' | 'accessedAt' {
    return this.getValue('appOrderType', 'accessedAt');
  }

  set appOrderType(value: 'createdAt' | 'accessedAt') {
    this.saveValue('appOrderType', value);
  }

  get appOrderValue(): 'desc' | 'asc' | undefined {
    return this.getValue('appOrderValue');
  }

  set appOrderValue(value: 'desc' | 'asc' | undefined) {
    this.saveValue('appOrderValue', value);
  }

  get shareAndInvite(): boolean {
    return this.getValue('shareAndInvite', true);
  }

  set shareAndInvite(value: boolean) {
    this.saveValue('shareAndInvite', value);
  }

  get lastEmailForLogin(): string | undefined {
    return this.getValue('lastEmailForLogin');
  }

  set lastEmailForLogin(value: string | undefined) {
    this.saveValue('lastEmailForLogin', value);
  }

  /**
   * 缓存格式同 Idoc
   */
  get createApp(): ICreateApp {
    return this.getValue('rp_project_size', defaultCreateApp);
  }

  set createApp(value: ICreateApp) {
    this.saveValue('rp_project_size', value);
  }

  set createAppCustomSize(size: { width: number; height: number }) {
    this.createApp = {
      ...this.createApp,
      customSize: size,
    };
  }

  set createAppType(value: IAppType) {
    this.createApp = {
      ...this.createApp,
      appType: value,
    };
  }

  setCreateAppDevice(appType: IAppType, index: number) {
    this.createApp = {
      ...this.createApp,
      appType,
      selected: {
        ...this.createApp.selected,
        [appType]: index,
      },
    };
  }

  get viewedFreeProgram(): ICommonObject<boolean> {
    return this.getValue('viewedFreeProgram', {});
  }

  getViewedFreeProgram(teamID: string): boolean {
    return this.viewedFreeProgram[teamID] || false;
  }

  setViewedFreeProgram(teamID: string, value: boolean) {
    this.saveValue('viewedFreeProgram', {
      ...this.viewedFreeProgram,
      [teamID]: value,
    });
  }

  get lastSelectedIframeLinkModule(): string {
    return this.getValue('lastSelectedIframeLinkModule', 'rp-app');
  }

  set lastSelectedIframeLinkModule(value: string) {
    this.saveValue('lastSelectedIframeLinkModule', value);
  }

  get lastSelectedIframeLinkType(): number {
    return this.getValue('lastSelectedIframeLinkType', 0);
  }

  set lastSelectedIframeLinkType(value: number) {
    this.saveValue('lastSelectedIframeLinkType', value);
  }

  get playFromCurrent(): boolean {
    return this.getValue('defaultPlayFrom', true);
  }

  set playFromCurrent(value: boolean) {
    this.saveValue('defaultPlayFrom', value);
  }

  get downloadArtboardRatio(): number {
    return this.getValue('downloadArtboardRatio', 1);
  }
  set downloadArtboardRatio(value: number) {
    this.saveValue('downloadArtboardRatio', value);
  }

  get lastSelectedAppSetIDs(): ICommonObject<string> {
    return this.getValue('lastSelectedAppSetIDs', {});
  }

  set lastSelectedAppSetIDs(value: ICommonObject<string>) {
    this.saveValue('lastSelectedAppSetIDs', value);
  }

  getAppSetIDByTeamID(teamID: string): string {
    return this.lastSelectedAppSetIDs[teamID] || '';
  }

  setAppSetIDByTeamID(teamID: string, value: string): void {
    this.lastSelectedAppSetIDs = Object.assign({}, this.lastSelectedAppSetIDs, { [teamID]: value });
  }

  get favoriteColors(): IFill[] {
    return this.getValue('favorite-color', []);
  }

  set favoriteColors(value: IFill[]) {
    this.saveValue('favorite-color', value);
  }

  get shapeSelectorPosition(): { left: number; top: number } {
    return loadFromCache('shapeSelectorPosition', { left: 150, top: 120 });
  }

  set shapeSelectorPosition(point: { left: number; top: number }) {
    this.saveValue('shapeSelectorPosition', point);
  }

  get selectedShape(): IComponentItem[] {
    return loadFromCache('selectedShape');
  }

  set selectedShape(compArr: IComponentItem[]) {
    this.saveValue('selectedShape', compArr);
  }

  get stopServerDialog(): boolean {
    return loadFromCache('stopServerDialog');
  }

  set stopServerDialog(value: boolean) {
    this.saveValue('stopServerDialog', value);
  }

  // 更新内容弹窗
  get newFeaturePopup(): { [key: string]: boolean } {
    return loadFromCache('newFeaturePopup', {});
  }

  set newFeaturePopup(value: { [key: string]: boolean }) {
    const newValue = {
      ...this.getValue('newFeaturePopup', {}),
      ...value,
    };
    this.saveValue('newFeaturePopup', newValue);
  }

  /**
   * 发布任务ID
   * @param appID
   */
  public getPublishTaskID(appID: string): string | undefined {
    return this.publishCache[appID]?.taskID;
  }

  /**
   * 发布进度
   * @param appID
   */
  getPublishTaskProgress(appID: string): number {
    return this.publishCache[appID]?.progress || 0;
  }

  /*
   * 发布项目最近选择的协作项目及原型项目
   */
  get publishCache(): IPublishCache {
    return this.getValue('publishCache', { cooperationID: '', prototypeID: '' });
  }

  /**
   * 缓存发布项目时的最近选择
   * @param {string} appID
   * @param {{cooperationID: string, prototypeID: string}} option
   */
  cachePublishApp(appID: string, option: Partial<IPublishCacheData> | undefined): void {
    const _cache = this.publishCache;
    if (!option) {
      delete _cache[appID];
    } else {
      _cache[appID] = { ...(_cache[appID] || {}), ...option };
    }
    this.saveValue('publishCache', _cache);
  }

  getNoLongerShowChromeTips(): boolean {
    return loadFromCache('noLongerShowChromeTips') || false;
  }

  setNoLongerShowChromeTips(value: boolean) {
    this.saveValue('noLongerShowChromeTips', value);
  }

  getStructureAlign(type: string): { [prop: string]: any } {
    return loadFromCache(`structureAlign${type}`);
  }

  setStructureAlign(type: string, value: { [prop: string]: any }) {
    this.saveValue(`structureAlign${type}`, value);
  }

  /**
   * @deprecated 模板征集活动已结束
   */
  get tempCollectionVisible(): boolean {
    return this.getValue('tempCollectionVisible', true);
  }

  set tempCollectionVisible(value: boolean) {
    this.saveValue('tempCollectionVisible', value);
  }

  /**
   * 演示界面推广
   * 是否被手动隐藏过
   */
  get hiddenPopularizes(): { [key: string]: boolean } {
    return this.getValue('hiddenPopularizes', {});
  }

  set hiddenPopularizes(value: { [key: string]: boolean }) {
    this.saveValue('hiddenPopularizes', { ...this.hiddenPopularizes, ...value });
  }

  get expandIDs(): string[] {
    return this.getValue('expandIDs', [ALL_SETS]);
  }

  set expandIDs(ids: string[]) {
    this.saveValue('expandIDs', ids);
  }

  // 评分组件/树组件/导航组件 图标替换引导提示
  get showIconReplaceTip(): boolean {
    return this.getValue('showIconReplaceTip', true);
  }

  set showIconReplaceTip(value: boolean) {
    this.saveValue('showIconReplaceTip', value);
  }

  get showPublishChangeTip(): boolean {
    return this.getValue('showPublishChangeTipV2', true);
  }

  set showPublishChangeTip(value: boolean) {
    this.saveValue('showPublishChangeTipV2', value);
  }

  // 分享策略修改提示
  get showShareChangeTip(): boolean {
    return this.getValue('showShareChangeTip', true);
  }

  set showShareChangeTip(value: boolean) {
    this.saveValue('showShareChangeTip', value);
  }

  // 离线演示包缓存
  get offlinePreviewOptions() {
    return this.getValue('offlinePreviewOptions');
  }
  set offlinePreviewOptions(value: IShareOption) {
    this.saveValue('offlinePreviewOptions', value);
  }

  //当前搜索的组件库的缓存
  getLastComponentLibraiesId() {
    return this.getValue('lastComponentLibraiesId');
  }
  setLastComponentLibraiesId(id: string) {
    this.saveValue('lastComponentLibraiesId', id);
  }

  //当前选择的组件库的缓存
  get switchComponentLib() {
    return this.getValue('switchComponentLib');
  }
  set switchComponentLib(id: string) {
    this.saveValue('switchComponentLib', id);
  }

  //三方组件库指引
  get componentShowGuidance() {
    return this.getValue('componentShowGuidance');
  }
  set componentShowGuidance(value: boolean) {
    this.saveValue('componentShowGuidance', value);
  }

  // 组件库 '预设', '更多' tab
  get compLibActiveTab() {
    return this.getValue('compLibActiveTab', COMP_LIB_TAB_ITEMS.preset);
  }
  set compLibActiveTab(value: number) {
    this.saveValue('compLibActiveTab', value);
  }

  // 小摹AI弹窗位置
  get aiPanelPoint() {
    return this.getValue('ai_PanelPoint');
  }
  set aiPanelPoint(value: { left: number; top: number }) {
    this.saveValue('ai_PanelPoint', value);
  }

  // 自动填充位置
  get autoFillPanelPosition() {
    return this.getValue('autoFillPanelPosition');
  }

  set autoFillPanelPosition(value: IPosition) {
    this.saveValue('autoFillPanelPosition', value);
  }

  //新画板组件库指引
  get newContentPanelShowGuidance() {
    return this.getValue('newContentPanelShowGuidance');
  }
  set newContentPanelShowGuidance(value: boolean) {
    this.saveValue('newContentPanelShowGuidance', value);
  }

  // 便签条连接线属性
  get noteConnectedLineProperty() {
    return this.getValue('noteConnectedLineProperty');
  }

  set noteConnectedLineProperty(value: INoteConnectedLine) {
    this.saveValue('noteConnectedLineProperty', value);
  }

  set AIHistoryInput(value: { [k: string]: string[] }) {
    this.saveValue('aiHistoryInput', value);
  }

  get AIHistoryInput(): { [k: string]: string[] } {
    return this.getValue('aiHistoryInput') || {};
  }

  //缩放缓存
  getScaleCache(key: string, defaultValue?: any) {
    let value = this.memoryCache[key];
    if (isUndefined(value) && defaultValue) {
      this.setScaleCache(key, defaultValue);
      value = defaultValue;
    }
    return value;
  }
  setScaleCache(key: string, value: any) {
    this.memoryCache[key] = value;
  }
}

const appOptions = new AppOptions();

export default appOptions;
