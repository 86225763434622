import * as React from 'react';
import classnames from 'classnames';

import Tooltip from '../Tooltip';

import './font_1700635_1l15qpcyxu6/iconfont.css';
import './index.scss';

export interface IconPluginProps {
  cls?: string;
  color?: string;
  tooltip?: string;
  toolTipDirection?: 'up' | 'down';
  size?: number | string;
  solid?: boolean;
  disabled?: boolean;
  tooltipAlign?: 'left' | 'center' | 'right';
  onClick?: React.MouseEventHandler;
  disableHover?: boolean; // hover的时候图标颜色不变,鼠标形状变为默认的
  disableHoverColor?: boolean; // hover的时候图标颜色不变，鼠标形状还是手型
  clickArea?: { width: number; height: number };
}

const IconPlugin: React.FC<IconPluginProps> = (props: IconPluginProps) => {
  const {
    cls,
    color,
    tooltip,
    toolTipDirection,
    size,
    onClick,
    solid,
    disabled,
    tooltipAlign,
    disableHover,
    disableHoverColor,
    clickArea,
  } = props;
  const iconPluginComp: any = (
    <i
      className={classnames('dsm-c-icon-plugin', 'iconfont_plug', `icon-plug-_${cls}`, {
        'dsm-c-icon-plugin-solid': solid,
        'dsm-c-icon-plugin-disabled': disabled,
        'dsm-c-icon-plugin-solid-disabled': disabled && solid,
        'dsm-c-icon-plugin-solid-disableHover': disableHover,
        'dsm-c-icon-plugin-solid-disableHoverColor': disableHoverColor,
        'dsm-c-icon-plugin-orea': !!clickArea,
      })}
      style={{
        fontSize: size,
        width: clickArea ? clickArea.width : 24,
        height: clickArea ? clickArea.height : 24,
        lineHeight: clickArea ? clickArea.height + 'px' : '24px',
        color,
      }}
      onClick={!disabled ? onClick : undefined}
    />
  );
  if (tooltip) {
    return (
      <Tooltip text={tooltip} align={tooltipAlign} theme="small" direction={toolTipDirection}>
        {iconPluginComp}
      </Tooltip>
    );
  }
  return iconPluginComp;
};

IconPlugin.defaultProps = {
  solid: false,
  cls: 'tag_peace',
  size: 16,
  tooltip: '',
  color: '',
  toolTipDirection: 'down',
  tooltipAlign: 'center',
  disabled: false,
  disableHover: false,
  disableHoverColor: false,
};

export default IconPlugin;
