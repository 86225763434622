import { globalStatistics } from './statisticsUtil';

interface IReg {
  inlineStyle?: string[];
  inDiv?: string[];
  inStyle?: string[];
  inClass?: string[];
  // inStyleContent?: string[];
}
const getReg = (reg: IReg) => {
  // 行内样式
  const regInlineStyle = 'style="[^<]*?($1)[^<]*?"';
  // div属性
  const regInDiv = `<div[^<]*?($1)[^<]*?>`;
  // css样式
  const regInStyle = '{[^<>{}]*?($1)[^<>{}]*?}';

  const list: string[] = [];
  const { inlineStyle, inDiv, inStyle } = reg;

  inlineStyle &&
    inlineStyle.forEach((t) => {
      list.push(regInlineStyle.replace('$1', t));
    });

  inDiv &&
    inDiv.forEach((t) => {
      list.push(regInDiv.replace('$1', t));
    });

  // inStyleContent &&
  //   inStyleContent.forEach((t) => {
  //     list.push(regInStyleContent.replace('$1', t));
  //   });

  if (inStyle) {
    inStyle.forEach((t) => {
      list.push(regInStyle.replace('$1', t));
    });
    // 空样式
    list.push('(\\.[^{}]*?{\\s*?})');
  }

  return {
    reg: new RegExp(list.join('|'), 'gi'),
    // 匹配小括号的个数
    parentheses: list.length,
  };
};

const getReplaceToken = (ms: string[], parentheses: number) => {
  const r = ms.slice(0, parentheses).filter((t) => !!t);
  if (r.length > 1) console.log(r);

  return r.join('');
};

/**
 * 替换为空
 * @param html
 * @param reglist
 * @returns
 */
export const replaceHtml = (html: string, reglist: IReg, repeatCount?: number): string => {
  const { reg, parentheses } = getReg(reglist);
  const removed: string[] = [];
  const replace = (html: string) => {
    return html.replace(reg, (a, ...ms) => {
      const token = getReplaceToken(ms, parentheses);
      removed.push(token);
      const replaceMement = a.replace(token, '');
      return replaceMement;
    });
  };

  let len = removed.length;
  let result = replace(html);
  // 重复 ，最多5次
  let i = repeatCount ?? 5;
  while (removed.length > len && i--) {
    // console.log(removed.length - len);
    len = removed.length;
    result = replace(result);
  }

  // window.debug && console.log('simplifyHtml:' + removed.join('').length / 1024 + 'kb '); //+ removed.join('|')
  globalStatistics.addReduce('simplifyHtml', removed.join('').length);
  return result;
};

export const searchByReg = (html: string, reglist: string[], handleMatchToken?: (str: string) => string[]) => {
  const reg = new RegExp(reglist.join('|'), 'gi');
  const parentheses = reglist.length;
  const set = new Set<string>();
  html.replace(reg, (a, ...ms) => {
    const token = getReplaceToken(ms, parentheses);
    const names = handleMatchToken ? handleMatchToken(token) : [token];
    if (names && names.length) {
      names.forEach((name) => name && set.add(name));
    }
    return '';
  });
  return set;
};

export const replaceHtmlByReg = (html: string, regStr: string, replaceStr: string) => {
  const reg = new RegExp(regStr, 'gi');
  return html.replace(reg, (a, m) => {
    const replaceMement = a.replace(m, replaceStr);
    return replaceMement;
  });
};
