import PropertyBase from './base';
import IIcon from './icon';

export const ImageTextTabsIconStylePropertyName = 'imageTextTabsIconStyle';

interface IImageTextTabsIconStyle extends PropertyBase {
  iconSize: number,
  iconDefaultStyle: IIcon,
  iconSelectedStyle: IIcon,
}

export default IImageTextTabsIconStyle;
