import * as React from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';

import { parseColorToString } from '@utils/graphicsUtils';

import { PureColor } from '@fbs/rp/models/properties/color';
import IFill from '@fbs/rp/models/properties/fill';
import { IProperties } from '@/fbs/rp/models/property';
import { ISize } from '@/fbs/common/models/common';

import { StyleHelper } from '@helpers/styleHelper';

import { IComponentProps } from '../../types';
import { initialKeyboardSize } from './config';

import './index.scss';

const originBorderRadiusValue = 4;

export default class Keyboard extends React.Component<IComponentProps, { lineHeight: number }> {
  constructor(props: IComponentProps) {
    super(props);
    const type = props.comp.properties.keyboard!.value;
    const space = type === 'numeric' ? 20 : 40;
    this.state = { lineHeight: (props.comp.size.height - space) / 4 };
    this.param = this.getParams(props);
  }

  private param: {
    size: ISize;
    properties: IProperties;
    opacity: number;
    isPreview?: boolean;
    globalScale: number;
  } & { [key: string]: any };

  private getParams = (props: IComponentProps) => {
    const {
      comp: { size, properties, value, opacity },
      isPreview,
      globalScale,
    } = props;
    return {
      size: _.cloneDeep(size),
      properties: _.cloneDeep(properties),
      value: _.cloneDeep(value),
      opacity,
      isPreview,
      globalScale,
    };
  };

  shouldComponentUpdate(nextProps: IComponentProps) {
    const newParam: { [key: string]: any } = this.getParams(nextProps);

    let flag = false;
    Object.keys(this.param).forEach((key) => {
      if (!_.isEqual(newParam[key], this.param[key])) {
        flag = true;
        this.param[key] = _.cloneDeep(newParam[key]);
      }
    });

    return flag;
  }

  private getCurrentBorderRadius() {
    const { height, width } = this.props.comp.size;
    const originRatio = originBorderRadiusValue / (initialKeyboardSize.width * initialKeyboardSize.height);
    return Math.min(originBorderRadiusValue, height * width * originRatio);
  }

  UNSAFE_componentWillReceiveProps(props: IComponentProps) {
    const type = props.comp.properties.keyboard!.value;
    const space = type === 'numeric' ? 30 : 40;
    this.setState({
      lineHeight: (props.comp.size.height - space) / 4,
    });
  }

  parserColors = (): {
    background?: string;
    color?: string;
    filter?: string;
    button: { feature?: string; normal?: string };
  } => {
    const { comp } = this.props;
    const { fill, buttonColor, forgetColor, featureButtonColor } = comp.properties;
    const { background } = StyleHelper.parserFill(fill!);
    const { background: normal } = StyleHelper.parserFill(buttonColor as IFill);
    const { background: feature } = StyleHelper.parserFill(featureButtonColor as IFill);
    const color = parseColorToString(forgetColor!.value as PureColor);
    const parser = StyleHelper.initCSSStyleParser(comp.properties);
    return {
      background,
      color,
      filter: parser.getShadowStyle().filter,
      button: {
        normal,
        feature,
      },
    };
  };

  renderButton(key: string, className: string, background: { normal?: string; feature?: string }) {
    let color = background.normal;
    if (className.includes('fun-button')) {
      color = background.feature;
    }
    return (
      <span
        key={key}
        style={{ background: color, borderRadius: `${this.getCurrentBorderRadius()}px` }}
        className={className}
      >
        {key}
      </span>
    );
  }

  renderSymbolKeyboard() {
    const { comp } = this.props;
    const { background, color, button, filter } = this.parserColors();
    return (
      <div
        className="lib-comp-keyboard lib-comp-keyboard-symbol"
        style={{
          lineHeight: `${this.state.lineHeight}px`,
          background,
          color,
          filter,
          opacity: StyleHelper.getOpacity(comp.opacity),
          ...comp.size,
        }}
      >
        <div>
          {'1234567890'.split('').map((letter) => {
            return this.renderButton(letter, 'keyboard-button', button);
          })}
        </div>
        <div>
          {'-/:;()$&@"'.split('').map((letter) => {
            return this.renderButton(letter, 'keyboard-button', button);
          })}
        </div>
        <div>
          {this.renderButton('#+=', 'keyboard-button fun-button', button)}
          {'.,?!`'.split('').map((letter) => {
            return this.renderButton(letter, 'keyboard-button', button);
          })}
          {this.renderButton(String.fromCharCode(60054), 'keyboard-button icon-button fun-button', button)}
        </div>
        <div>
          {this.renderButton('123', 'keyboard-button fun-button', button)}
          {this.renderButton(String.fromCharCode(60171), 'keyboard-button icon-button fun-button', button)}
          {this.renderButton('space', 'keyboard-button', button)}
          {this.renderButton(String.fromCharCode(59870), 'keyboard-button icon-button fun-button', button)}
        </div>
      </div>
    );
  }

  renderNumberKeyboard() {
    const { comp } = this.props;
    const { background, color, button, filter } = this.parserColors();
    return (
      <div
        className="lib-comp-keyboard lib-comp-keyboard-number"
        style={{
          lineHeight: `${this.state.lineHeight}px`,
          background,
          color,
          filter,
          opacity: StyleHelper.getOpacity(comp.opacity),
          ...comp.size,
        }}
      >
        <div
          style={{
            lineHeight: `${this.state.lineHeight * 0.75 + 2}px`,
          }}
        >
          {['+', '-', '*', '/', '@'].map((key) => this.renderButton(key, 'keyboard-button', button))}
        </div>
        <div>{['1', '4', '7', ','].map((key) => this.renderButton(key, 'keyboard-button', button))}</div>
        <div>{['2', '5', '8', '0'].map((key) => this.renderButton(key, 'keyboard-button', button))}</div>
        <div>{['3', '6', '9', '.'].map((key) => this.renderButton(key, 'keyboard-button', button))}</div>
        <div>
          {this.renderButton(String.fromCharCode(60054), 'keyboard-button icon-button fun-button', button)}
          {this.renderButton('space', 'keyboard-button fun-button', button)}
          {this.renderButton('return', 'keyboard-button fun-button', button)}
          {this.renderButton(String.fromCharCode(59870), 'keyboard-button icon-button fun-button', button)}
        </div>
      </div>
    );
  }

  renderLetterKeyboard() {
    const { background, color, button, filter } = this.parserColors();
    const { comp } = this.props;
    return (
      <div
        className={classnames('lib-comp-keyboard lib-comp-keyboard-letter', {
          'min-size': comp.size.width < 300 || comp.size.height < 150,
        })}
        style={{
          lineHeight: `${this.state.lineHeight}px`,
          background,
          color,
          filter,
          opacity: StyleHelper.getOpacity(comp.opacity),
          ...comp.size,
        }}
      >
        <div>
          {'QWERTYUIOP'.split('').map((letter) => {
            return this.renderButton(letter, 'keyboard-button', button);
          })}
        </div>
        <div>
          {'ASDFGHJKL'.split('').map((letter) => {
            return this.renderButton(letter, 'keyboard-button', button);
          })}
        </div>
        <div>
          {this.renderButton(String.fromCharCode(59885), 'keyboard-button icon-button fun-button', button)}
          {'ZXCVBNM'.split('').map((letter) => {
            return this.renderButton(letter, 'keyboard-button', button);
          })}
          {this.renderButton(String.fromCharCode(60054), 'keyboard-button icon-button fun-button', button)}
        </div>
        <div>
          {this.renderButton('123', 'keyboard-button fun-button', button)}
          {this.renderButton(String.fromCharCode(60171), 'keyboard-button icon-button fun-button', button)}
          {this.renderButton('space', 'keyboard-button', button)}
          {this.renderButton('return', 'keyboard-button fun-button', button)}
        </div>
      </div>
    );
  }

  render() {
    const { comp } = this.props;
    const { keyboard } = comp.properties;
    if (keyboard!.value === 'numeric') {
      return this.renderNumberKeyboard();
    } else if (keyboard!.value === 'symbol') {
      return this.renderSymbolKeyboard();
    }
    return this.renderLetterKeyboard();
  }
}
