import * as React from 'react';

import CheckBox from '../CheckBox';

import './index.scss';

interface ICheckboxModel {
  id: number | string;
  checked: boolean;
  text: string;
  disabled?: boolean;
}

type CheckChangeHandle = (id: string | number, checked: boolean) => void;

export interface ICheckboxGroupProps {
  data: ICheckboxModel[];
  theme?: 'normal' | 'light';
  onChange: (item: ICheckboxModel) => void;
}

// TODO: 子项的布局方式需要再处理一下，要根据我们的应用场景来考虑
class CheckboxGroup extends React.Component<ICheckboxGroupProps> {
  private itemChangeHandle: { [key: string]: (checked: boolean) => void } = {};

  constructor(props: ICheckboxGroupProps) {
    super(props);
    this.doInitItemChangeHandle(props.data);
  }

  UNSAFE_componentWillReceiveProps(nextProps: ICheckboxGroupProps) {
    this.doInitItemChangeHandle(nextProps.data);
  }

  private doInitItemChangeHandle(data: ICheckboxModel[]) {
    data.forEach(({ id }) => {
      if (!this.itemChangeHandle[id]) {
        this.itemChangeHandle[id] = (checked: boolean) => {
          const checkID = id;
          this.handleItemChange(checkID, checked);
        };
      }
    });
  }

  private handleItemChange = (id: string | number, checked: boolean) => {
    const { onChange, data } = this.props;
    if (onChange) {
      const selItem = data.find((item) => item.id === id);
      selItem && onChange({ ...selItem, checked });
    }
  };

  onItemChange(id: string | number, checked: boolean) {
    const { onChange, data } = this.props;
    if (onChange) {
      const selItem = data.find((item) => item.id === id);
      selItem && onChange({ ...selItem, checked });
    }
  }

  render() {
    return (
      <div className="dsm-c-rp-checkbox-group">
        {this.props.data.map((data) => (
          <CheckBox
            key={`${data.id}`}
            checked={data.checked}
            text={data.text}
            theme={this.props.theme}
            onChange={this.itemChangeHandle[data.id]}
            disabled={data.disabled}
          />
        ))}
      </div>
    );
  }
}

export default CheckboxGroup;
