import { PureColor } from './color';
import { ImageTextTabsIconStylePropertyName } from './imageTextTabsIconStyle';
import { CarouselChartPropertyName } from './carouselChart';
import { NoteConnectedLinePropertyName } from './noteConnectedLine';
import {
  ChartLegendPositionPropertyName,
  ChartDataLabelPropertyName,
  ChartAxisPropertyName,
  ChartSmoothPropertyName,
  ChartBarWidthPropertyName,
} from './chart';
import { GaugeChartPropertyName } from './gaugeChart';
import { MapChartPropertyName } from './mapChart';
import { CollapsePropertyName } from './collapse';
import { SelectListPropertyName } from './selectList';
import { VerticalTabsIconStylePropertyName } from './verticalTabsIconStyle';
import { MarkerStripPropertyName } from './makerstrip';

// 这里的字符串名称要与properties中的属性定义名称一致
export const PropFill = 'fill';
export const PropStroke = 'stroke';
export const PropRadius = 'radius';
export const PropTextStyle = 'textStyle';
export const PropMultiText = 'multiText';
export const PropPolygon = 'polygon';
export const PropShadow = 'shadow';
export const PropBorder = 'border';
export const PropLayout = 'layout';
export const PropImage = 'image';
export const PropContainer = 'container';
export const PropLine = 'line';
// TODO: 必要性?
export const PropIcon = 'icon';
export const PropKeyboard = 'keyboard';
export const PropInputModel = 'inputModel';
export const PropPadding = 'padding';
export const PropTextFormat = 'textFormat';
export const PropBlur = 'blur';
export const PropTree = 'tree';
export const PropIconEx = 'iconEx';
export const PropPlaceHolderEx = 'placeHolderEx';
export const PropEnum = 'enum';

// 占位文字
export const PropPlaceHolder = 'placeholder';
// 占位文字样式
export const PropPlaceHolderStyle = 'placeHolderStyle';
// 图标颜色
export const PropIconColor = 'iconColor';

export type SimplePropertyName = 'number' | 'boolean' | 'color' | 'string' | 'enum';

export type PropertyName =
  | typeof PropFill
  | typeof PropStroke
  | typeof PropRadius
  | typeof PropTextStyle
  | typeof PropMultiText
  | typeof PropPolygon
  | typeof PropShadow
  | typeof PropBorder
  | typeof PropBorder
  | typeof PropLayout
  | typeof PropImage
  | typeof PropContainer
  | typeof PropLine
  | typeof PropIcon
  | typeof PropKeyboard
  | typeof PropInputModel
  | typeof PropPadding
  | typeof PropTextFormat
  | typeof PropBlur
  | typeof PropTree
  | typeof PropIconEx
  | typeof PropPlaceHolderEx
  | typeof PropEnum
  | typeof ImageTextTabsIconStylePropertyName
  | typeof VerticalTabsIconStylePropertyName
  | typeof MarkerStripPropertyName
  | typeof CarouselChartPropertyName
  | typeof PropPlaceHolder
  | typeof PropPlaceHolderStyle
  | typeof PropIconColor
  | typeof ChartLegendPositionPropertyName
  | typeof ChartDataLabelPropertyName
  | typeof ChartAxisPropertyName
  | typeof ChartSmoothPropertyName
  | typeof ChartBarWidthPropertyName
  | typeof NoteConnectedLinePropertyName
  | typeof GaugeChartPropertyName
  | typeof MapChartPropertyName
  | typeof CollapsePropertyName
  | typeof SelectListPropertyName
  | SimplePropertyName;

interface PropertyBase {
  // 属性显示名字
  name?: string;
  // 是否启用该属性
  disabled?: boolean;
  // 是否对用户隐藏
  hidden?: boolean;
  // 属性类型
  prop?: PropertyName;
  ref?: string;
  // 集中特殊类型使用 number boolean color customEnum
  value?: boolean | IRange | PureColor | string;
}
export interface SimpleProperty extends PropertyBase {
  prop: SimplePropertyName;
  allowDisabled?: boolean;
}

export interface IRange {
  value: number;
  min?: number;
  max?: number;
  step?: number;
  unit?: string;
}

export interface NumberProperty extends PropertyBase {
  type: 'number';
  value: {
    value: number;
    unit?: string;
  };
}

export interface BooleanProperty extends PropertyBase {
  type: 'boolean';
  value: true;
}

export default PropertyBase;
