import * as React from 'react';
import * as ReactDom from 'react-dom';

import ResizeObserver from 'resize-observer-polyfill';
import classNames from 'classnames';

import * as GraphicsUtils from '@utils/graphicsUtils';
import { round } from '@utils/globalUtils';
import { parseColorToString } from '@utils/graphicsUtils';
import { transBlankChart } from '@/utils/textUtils';

import { ICON_PADDING_RIGHT } from '@/consts/defaultData/multipleSelect';
import { FontBoxScale } from '@/consts/fonts';
import { StyleHelper } from '@helpers/styleHelper';
import { Color } from '@fbs/rp/models/properties/color';
import ISelectList from '@/fbs/rp/models/properties/selectList';
import { UIComponent, UIContainerComponent, UIMultipleSelectPanelComponent } from '@editor/comps';

import { IComponentProps } from '../../types';

import { Icon } from '@/dsm';
import List from '../List';
import Background from '../../basic/common/Background';

import './index.scss';

const LABEL_ICON_SCALE = 0.8;

const MultipleSelect: React.FC<IComponentProps> = (props) => {
  const group = props.comp as UIMultipleSelectPanelComponent;
  const groupProperties = group.properties;
  const canSearch = groupProperties.canSearch?.value;
  const isPreview = props.isPreview;
  const scale = window.pageScale ?? props.scale ?? 1;
  const baseFontSize = groupProperties.textStyle?.fontSize ?? 14;
  const selectListProps = groupProperties.selectList as ISelectList;
  const { mainComp, listComp } = group;
  const dropDownList = listComp.components as UIContainerComponent[];
  const valueStrings = group.value as string;
  const [iconComp, mainValueComp] = mainComp.components;

  const mainDomRef = React.useRef<HTMLDivElement>(null);
  const dropDownRef = React.useRef<HTMLDivElement>(null);
  const mirrorDomRef = React.useRef<HTMLDivElement>(null);
  const valueDomRef = React.useRef<HTMLDivElement>(null);
  const inputDomRef = React.useRef<HTMLInputElement>(null);
  const tempRef = React.useRef({
    dropDownVis: false,
    isSearch: false,
    initialGroupHeight: group.size.height, // 多选组件初始高度
    isFirstEffect: true,
  });

  const [dropDownVis, setDropDownVis] = React.useState(false);
  const [popPosition, setPopPosition] = React.useState<{ left: number; top: number }>();
  const [isSearch, setIsSearch] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [inpWidth, setInpWidth] = React.useState(baseFontSize / FontBoxScale / scale);
  const [forceUpdate, setForceUpdate] = React.useState(true);
  const [isHover, setIsHover] = React.useState(false);

  const valueComps = React.useMemo(() => group.valueComps, [valueStrings]);

  // 搜索过滤后的下拉component
  const filteredDropDownComp = React.useMemo(() => {
    const compData = listComp.toJSON();
    const data = { ...compData, components: [] };
    const newComp = new UIComponent(data, listComp.parent, listComp.options) as UIContainerComponent;
    newComp.components = dropDownList.filter((item) => {
      const textComp = item.getComponentByAlias('text') || item.components[0];
      return transBlankChart((textComp.value || '') as string).includes(transBlankChart(search));
    });
    return newComp;
  }, [search, dropDownList]);

  const doCalcPopPosition = () => {
    const mainDom = mainDomRef.current;
    const dropDownDom = dropDownRef.current;
    if (!mainDom || !dropDownDom) return;
    const { left, top, bottom } = mainDom.getBoundingClientRect();
    const showHeight = dropDownDom.getBoundingClientRect().height;
    const { innerHeight } = window;

    let y = bottom;
    if (y + showHeight > innerHeight && top - showHeight > 0) {
      y = top - showHeight;
    }
    y = round(y);
    if (!popPosition || popPosition?.left !== left || popPosition?.top !== top) {
      setPopPosition({ left, top: y });
    }
  };

  // 计算input宽度
  const doCalcInputWidth = () => {
    const mirrorDom = mirrorDomRef.current;
    if (mirrorDom) {
      const width = Math.max(mirrorDom.getBoundingClientRect().width || 0, baseFontSize);
      setInpWidth(width / FontBoxScale / scale);
    }
  };

  // 重置组件高度
  const doSetCompsHeight = () => {
    if (tempRef.current.isFirstEffect || !isPreview) return;
    const valueDom = valueDomRef.current;
    if (valueDom) {
      const height = valueDom.clientHeight * FontBoxScale;
      const paddingPx = group.getVerticalPadding();
      const groupHeight = height + paddingPx;
      let realHeight;
      if (!valueStrings && !isSearch) {
        // 未选中值且不处于搜索中状态，使用默认高度
        realHeight = tempRef.current.initialGroupHeight;
      } else {
        realHeight = Math.max(tempRef.current.initialGroupHeight, groupHeight);
      }
      group.$data.size = { ...group.size, height: realHeight };
      mainComp.$data.size = { ...mainComp.size, height: realHeight };
      mainValueComp.$data.size = { ...mainValueComp.size, height: realHeight - paddingPx };
      setForceUpdate(!forceUpdate);
    }
  };

  const handleWindowClick = (e: MouseEvent) => {
    const el = e.target as HTMLElement;
    if (mainDomRef.current?.contains(el) || dropDownRef.current?.contains(el)) return;
    if (tempRef.current.dropDownVis) setDropDownVis(false);
    if (tempRef.current.isSearch) {
      tempRef.current.isSearch = false;
      setIsSearch(false);
      setSearch('');
      setInpWidth(baseFontSize / FontBoxScale / scale);
    }
  };

  const handleDocScroll = (e: Event) => {
    if (!tempRef.current.dropDownVis) return;
    if (dropDownRef.current?.contains(e.target as HTMLElement)) return;
    // input输入框输入是造成的滚动不关闭
    if (inputDomRef.current?.contains(e.target as HTMLElement)) return;
    // mainDom产生的滚动不关闭
    if (mainDomRef.current?.contains(e.target as HTMLElement)) return;
    setDropDownVis(false);
  };

  const handleWindowResize = () => {
    if (!tempRef.current.dropDownVis) return;
    setDropDownVis(false);
  };

  React.useEffect(() => {
    setInpWidth(baseFontSize / FontBoxScale / scale);
  }, [scale, baseFontSize]);

  React.useEffect(() => {
    doCalcPopPosition();
  }, [filteredDropDownComp.components.length]);

  React.useEffect(() => {
    tempRef.current.dropDownVis = dropDownVis;
    if (dropDownVis) {
      doCalcPopPosition();
      props.event && props.event.onFocus?.(undefined, group);
    } else {
      setPopPosition(undefined);
      // 搜索框未失去焦点，不执行onBlur
      if (inputDomRef.current !== document.activeElement) {
        // 鼠标仍处于组件上，设置为hover状态
        if (isHover) {
          group.$data._currentState = undefined;
          props.event && props.event.onMouseEnter?.(undefined, group);
        } else {
          props.event && props.event.onBlur?.(undefined, group);
        }
      }
    }
  }, [dropDownVis]);

  React.useEffect(() => {
    if (tempRef.current.isFirstEffect) {
      return;
    }
    doCalcInputWidth(); // 计算input的宽
  }, [search]);

  React.useEffect(() => {
    doSetCompsHeight();
  }, [valueStrings, isSearch, inpWidth]);

  React.useEffect(() => {
    tempRef.current.isFirstEffect = false;
    let resizeObserver: ResizeObserver;
    if (isPreview) {
      window.addEventListener('click', handleWindowClick, true);
      document.addEventListener('scroll', handleDocScroll, true);
      window.addEventListener('resize', handleWindowResize, true);
      if (mainDomRef.current) {
        resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (entry.target.className === mainDomRef.current?.className) {
              doCalcPopPosition();
            }
          }
        });
        resizeObserver.observe(mainDomRef.current);
      }
    }
    return () => {
      tempRef.current.isFirstEffect = true;
      if (isPreview) {
        window.removeEventListener('click', handleWindowClick, true);
        document.removeEventListener('scroll', handleDocScroll, true);
        window.removeEventListener('resize', handleWindowResize, true);
      }
      if (resizeObserver) resizeObserver.disconnect();
    };
  }, []);

  const doSetInputFocus = () => {
    if (canSearch && inputDomRef.current) inputDomRef.current?.focus();
  };

  const handleMainClick = () => {
    if (group.disabled) return;
    setDropDownVis(!dropDownVis);
    tempRef.current.dropDownVis = !dropDownVis;
    if (isPreview && canSearch && !isSearch) {
      setIsSearch(true);
      tempRef.current.isSearch = true;
    }
    doSetInputFocus();
  };

  const handleItemClick = (e: React.MouseEvent, comp: UIComponent) => {
    props.event?.onClick?.(e, comp);
    doSetInputFocus();
  };

  const handleDeleteItem = (e: React.MouseEvent, comp: UIContainerComponent) => {
    if (group.disabled) return;
    e.stopPropagation();
    props.event?.onClick?.(e, comp, false);
    doSetInputFocus();
  };

  const handleAllClear = (e: React.MouseEvent) => {
    if (group.disabled) return;
    e.stopPropagation();
    valueComps.forEach((comp) => {
      props.event?.onClick?.(e, comp, false);
    });
    doSetInputFocus();
  };

  // 搜索输入
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    if (!dropDownVis) setDropDownVis(true);
  };

  // 演示界面，链接区域的处理
  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const renderValueItem = (comp: UIContainerComponent) => {
    const textComp = comp.getComponentByAlias('text') || comp.components[0];
    const deleteIconComp = comp.components[1];
    const fillStyle = StyleHelper.initCSSStyleParser(textComp.properties).getFillStyle();
    const { textStyle: iconTextStyle } = deleteIconComp.properties;
    const iconColor = GraphicsUtils.parseColorToString(iconTextStyle?.color || '#000');
    return (
      <div key={comp.id} className="select-value-item" style={{ ...fillStyle }}>
        <span className="select-value-item--text">{transBlankChart(textComp.value as string)}</span>
        <Icon
          cls={deleteIconComp.value as string}
          size={iconTextStyle?.fontSize}
          onClick={(e) => handleDeleteItem(e, comp)}
          style={{ color: iconColor }}
        />
      </div>
    );
  };

  const renderValue = (placeholder?: string) => {
    return (
      <div className="select-value-wrap" ref={valueDomRef}>
        {valueComps.length > 0 && valueComps.map((item) => renderValueItem(item))}
        {canSearch && isSearch && (
          <div className="search-input-wrap placeholder select-value-item" style={{ width: inpWidth }}>
            <input
              ref={inputDomRef}
              className="search-input"
              onChange={handleInputChange}
              value={search}
              autoFocus
              placeholder={valueComps.length === 0 ? placeholder : undefined}
            />
            <div className="search-input-mirror" ref={mirrorDomRef}>
              {search}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderMain = () => {
    const { isPreview, showInteract, comp } = props;
    const { placeholder, placeHolderStyle, stroke, radius, padding } = groupProperties;
    const { properties, size } = mainComp;
    const { fill, border } = properties;
    const parser = StyleHelper.createCSSStyleParser({ ...properties, padding });
    const { style: txStyle } = parser.getTextStyleData(mainValueComp.size, properties.textStyle);
    const style = {
      ...size,
      ...parser.getShadowStyle(),
      opacity: StyleHelper.getOpacity(group.opacity),
      ...parser.getRadiusStyle(size),
    };
    const textStyle = {
      opacity: StyleHelper.getOpacity(mainValueComp.opacity),
      width: (size.width - ICON_PADDING_RIGHT) / FontBoxScale,
      ...parser.getPaddingStyleByFontScale(),
      ...txStyle,
    };
    if (valueComps.length === 0 && placeHolderStyle) {
      textStyle.color = parseColorToString(placeHolderStyle.value as Color);
    }
    // 处理letterSpacing缩放
    if (typeof textStyle.letterSpacing === 'number') {
      textStyle.letterSpacing = textStyle.letterSpacing / FontBoxScale;
    }

    const { textStyle: iconTextStyle } = iconComp.properties;
    const fillStyle = StyleHelper.initCSSStyleParser(iconComp.properties).getFillStyle();
    const iconColor = GraphicsUtils.parseColorToString(iconTextStyle?.color || '#000');
    const placeholderText = transBlankChart(placeholder?.value as string);
    const _hasInteraction = !!(comp.$data.interaction && Object.keys(comp.$data.interaction).length);
    const needShowInteraction = !isPreview && _hasInteraction && false !== showInteract;
    return (
      <div
        className={classNames('lib-comp-multiple-select-main-wrapper', {
          'item-interaction-flag': needShowInteraction,
        })}
      >
        <div
          ref={mainDomRef}
          className={classNames('lib-comp-multiple-select-main', { 'component-cursor-pointer': isPreview })}
          onClick={isPreview ? handleMainClick : undefined}
          style={style}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {fill && !fill.disabled && <Background size={size} properties={{ fill }} />}
          <div className="select-main-value" style={textStyle}>
            {renderValue(placeholderText)}
          </div>
          {placeholderText && valueComps.length === 0 && !isSearch && (
            <div className="placeholder-content" style={textStyle}>
              <span>{placeholderText}</span>
            </div>
          )}
          {valueComps.length > 0 && (
            <div
              className={classNames('clear-icon-wrap', { isPreview })}
              onClick={handleAllClear}
              style={{
                left: iconComp.position.x,
                width: iconComp.size.width,
                height: iconComp.size.height,
                ...fillStyle,
              }}
            >
              <Icon
                cls={iconComp.value as string}
                size={iconTextStyle?.fontSize}
                className="clear-icon"
                style={{ color: iconColor }}
              />
            </div>
          )}
          {stroke && !stroke.disabled && <Background size={size} properties={{ stroke, radius, border }} />}
        </div>
      </div>
    );
  };

  const renderLabelIcon = (comp: UIContainerComponent, fontSize: number) => {
    const iconComp = comp.components[2];
    if (iconComp) {
      const { textStyle: iconTextStyle } = iconComp.properties;
      const iconColor = GraphicsUtils.parseColorToString(iconTextStyle?.color || '#000');
      return <Icon cls={iconComp.value as string} size={fontSize} style={{ color: iconColor }} />;
    }
  };

  const renderLabelText = (labelTextComp: UIComponent) => {
    const listItemComp = labelTextComp.parent as UIContainerComponent;
    const selected = listItemComp.selected;
    const fontSize = labelTextComp.properties.textStyle?.fontSize! / FontBoxScale;
    const fontColor = selected ? GraphicsUtils.parseColorToString(selectListProps.selectedFontColor) : undefined;
    return (
      <>
        <span className="drop-down-item--text" style={{ color: fontColor }}>
          {transBlankChart(labelTextComp.value as string)}
        </span>
        {selected && renderLabelIcon(listItemComp, fontSize * LABEL_ICON_SCALE)}
      </>
    );
  };

  const renderList = () => {
    const dropDownComp = (group.getComponentByAlias('drop-down') || group.components[1]) as UIContainerComponent;
    const { size, properties, id } = dropDownComp;
    const { position, components } = filteredDropDownComp;

    const parser = StyleHelper.createCSSStyleParser(properties);
    const borderWidth = properties.stroke && !properties.stroke.disabled ? properties.stroke.thickness || 1 : 0;

    let height = position.y / FontBoxScale;
    if (components.length === 0) {
      height += 30;
    } else {
      height = components.reduce((acc, curr, i) => {
        if (i < 6) acc += curr.size.height;
        return acc;
      }, height);
    }

    const style = {
      ...popPosition,
      ...size,
      height,
      ...parser.getFillStyle(),
      ...parser.getShadowStyle(),
      ...parser.getStrokeStyle(),
      ...parser.getRadiusStyle(size),
      transform: `scale(${scale || 1}, ${scale || 1})`,
      paddingTop: position.y - borderWidth,
      paddingBottom: position.y - borderWidth,
      opacity: StyleHelper.getOpacity(props.comp.opacity),
    };

    return (
      <div
        ref={dropDownRef}
        id={id}
        className={classNames(
          'lib-comp-multiple-select-drop-down',
          'component-popup-container',
          { mobile: props.showMobileCursor },
          { always: props.hotAreaVisibleModel === 'only-hover' && isHover },
          props.hotAreaVisibleModel,
        )}
        style={style}
      >
        <List
          isPreview
          isDropDown
          searchAfterComp={filteredDropDownComp}
          comp={filteredDropDownComp}
          itemEvents={{ onItemClick: handleItemClick }}
          renderLabelText={renderLabelText}
        />
      </div>
    );
  };

  const renderDropDown = () => {
    if (!dropDownVis) return null;
    return ReactDom.createPortal(renderList(), document.body);
  };

  return (
    <>
      {renderMain()}
      {renderDropDown()}
    </>
  );
};

export default React.memo(MultipleSelect);
