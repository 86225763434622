import { isEqual, isUndefined } from 'lodash';

import { depthClone, min, sameNumber } from '@utils/globalUtils';

import { IPathPoint, IPathValue } from '@fbs/rp/models/value';
import { CompoundOperation } from '@fbs/rp/models/properties/path';
import { IComponentData } from '@fbs/rp/models/component';
import { HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import { IPoint, IPosition, ISize } from '@fbs/common/models/common';
import { StrokePosition, StrokePropertyName } from '@fbs/rp/models/properties/stroke';
import { FillPropertyName } from '@fbs/rp/models/properties/fill';
import { LinePropertyName } from '@fbs/rp/models/properties/line';
import { TextFormatExPropertyName } from '@/fbs/rp/models/properties/textFormat';
import { PaddingPropertyName } from '@/fbs/rp/models/properties/padding';
import { ShadowPropertyName } from '@fbs/rp/models/properties/shadow';
import IBorder from '@fbs/rp/models/properties/border';
import { IProperties } from '@fbs/rp/models/property';

import { CCompoundPath } from '@libs/constants';
import { getDefaultComponentName } from '@libs/libs';
import { makeCommonComponent } from '@libs/helper';
import { ISegment, SegType } from '@customTypes/path';

import paper from './paperHelper';
import { getNewID } from './idHelper';
import { componentToSvgValue } from './exportSvgHelper';

// 改点是否满足圆角条件
export const isSegWithRadius = (seg: ISegment) => {
  const { previous, next, radius, handleIn, handleOut } = seg;
  if (!radius) {
    return false;
  }
  if (handleOut && !handleOut.equals(new paper.Point(0, 0))) {
    return false;
  }
  if (handleIn && !handleIn.equals(new paper.Point(0, 0))) {
    return false;
  }
  if (!previous) {
    return false;
  }
  if (previous.handleOut && !previous.handleOut.equals(new paper.Point(0, 0))) {
    return false;
  }
  if (!next) {
    return false;
  }
  if (next.handleIn && !next.handleIn.equals(new paper.Point(0, 0))) {
    return false;
  }
  return true;
};

export const calcaulateMaxRadius = (seg: paper.Segment, scale = 1) => {
  const { previous, next, point } = seg;
  if (!previous?.point || !next?.point || !point) {
    return 0;
  }
  const prevVector = previous.point.subtract(point);
  const nextVector = next.point.subtract(point);

  let maxDistance1 = prevVector.getDistance(new paper.Point(0, 0)) / scale;
  let maxDistance2 = nextVector.getDistance(new paper.Point(0, 0)) / scale;

  const maxDistance = min(maxDistance1, maxDistance2);

  const angleInRadians = Math.PI - nextVector.getAngleInRadians(prevVector);
  if ([0, Math.PI, -Math.PI].includes(angleInRadians)) {
    return 0;
  }
  return Math.ceil(Math.floor((maxDistance / Math.tan(angleInRadians / 2)) * 100) / 100);
};

// 给原始路径设置上圆角
const setRadius = (curSeg: paper.Segment, curPath: paper.Path) => {
  const { previous, next } = curSeg;
  if (!isSegWithRadius(curSeg)) {
    return false;
  }
  if (curSeg.previous?.point && curSeg.next?.point) {
    const prevVector = curSeg.previous.point.subtract(curSeg.point!);
    const nextVector = curSeg.next.point.subtract(curSeg.point!);

    let maxDistance1 = prevVector.getDistance(new paper.Point(0, 0));
    if ((previous as ISegment).radius && !(previous.handleOut && !previous.handleOut.equals(new paper.Point(0, 0)))) {
      maxDistance1 = maxDistance1 / 2;
    }
    let maxDistance2 = nextVector.getDistance(new paper.Point(0, 0));
    if ((next as ISegment).radius && !(next.handleIn && !next.handleIn.equals(new paper.Point(0, 0)))) {
      maxDistance2 = maxDistance2 / 2;
    }

    const angleInRadians = Math.PI - nextVector.getAngleInRadians(prevVector);
    if ([0, Math.PI, -Math.PI].includes(angleInRadians)) {
      (curSeg as ISegment).radius = undefined;
      return;
    }

    const radius = (curSeg as ISegment).radius || 0;
    const distance = Math.min(radius * Math.tan(angleInRadians / 2), maxDistance1, maxDistance2);
    const realRadius = distance / Math.tan(angleInRadians / 2);

    const k = Math.tan(angleInRadians / 4) * (4 / 3);
    const handleSize = k * realRadius;

    const newPoint = curSeg.point!.add(new paper.Point(distance, 0));
    const newHandle = new paper.Point(handleSize, 0);

    const seg1 = new paper.Segment(
      newPoint.clone().rotate(prevVector.angle || 0, curSeg.point!),
      undefined,
      newHandle.clone().rotate((prevVector.angle || 0) - 180, new paper.Point(0, 0)),
    );
    (seg1 as ISegment).hidden = true;

    const seg2 = new paper.Segment(
      newPoint.clone().rotate(nextVector.angle || 0, curSeg.point!),
      newHandle.clone().rotate((nextVector.angle || 0) - 180, new paper.Point(0, 0)),
      undefined,
    );
    (seg2 as ISegment).hidden = true;

    const index = curSeg.index;
    curPath.removeSegment(index);
    curPath.insertSegments(index, [seg1, seg2]);
  }
};

/**
 * 构建路径数据d， 移除偏移处理
 * @param pathData
 * @returns {string}
 */
export const transformPathDataToPath = (pathData: IPathValue): string => {
  let result = '';

  const pathItem = pathValueToPathWithRadius(pathData) as paper.Path;
  const data = pathItem.segments?.map(segmentToData);
  if (data) {
    const newPathItem = pathValueToPath({
      data,
      closed: pathData.closed,
    }) as paper.Path;
    result = newPathItem.pathData || '';
  }

  return result;
};

// 圆角不在这个方法中缩放
export const scalePath = (pathData: IPathValue, scale: { x: number; y: number }, zoomRadius?: boolean) => {
  const data = pathData.data.map((pathPoint) => {
    const { point, handleIn, handleOut, radius } = pathPoint;
    let scaleRadius = radius;
    if (radius && zoomRadius) {
      scaleRadius = radius * min(scale.x, scale.y);
    }
    return {
      point: {
        x: point.x * scale.x,
        y: point.y * scale.y,
      },
      handleIn: {
        x: handleIn.x * scale.x,
        y: handleIn.y * scale.y,
      },
      handleOut: {
        x: handleOut.x * scale.x,
        y: handleOut.y * scale.y,
      },
      radius: scaleRadius,
    };
  });
  return {
    closed: pathData.closed,
    data,
  };
};

export const inflatPath = (
  pathData: IPathValue,
  diff: { x: number; y: number } | number,
  oldSize: { width: number; height: number },
): IPathValue => {
  const diffX = typeof diff === 'number' ? diff : diff.x;
  const diffY = typeof diff === 'number' ? diff : diff.y;
  const { width, height } = oldSize;
  const scale = {
    x: (width - diffX) / width,
    y: (height - diffY) / height,
  };
  const result = scalePath(pathData, scale, true);
  result.data.forEach((item) => {
    item.point.x += diffX / 2;
    item.point.y += diffY / 2;
  });
  return result;
};

/**
 * IPathValue 转 paper.path
 */
const pathValueToPath = (pathValue: IPathValue): paper.PathItem => {
  let canvas = document.getElementById('paperCanvas') as HTMLCanvasElement;
  if (!canvas) {
    canvas = document.createElement('canvas');
    canvas.id = 'paperCanvas';
  }
  // paper.setup(canvas!);
  const path = new paper.Path(
    pathValue.data.map((p) => {
      const seg = new paper.Segment(
        new paper.Point(p.point),
        new paper.Point(p.handleIn),
        new paper.Point(p.handleOut),
      );
      (seg as ISegment).radius = p.radius;
      return seg;
    }),
  );
  path.closed = !!pathValue.closed;

  return path;
};

/**
 * IPathValue 转 paper.path，并处理圆角
 */
const pathValueToPathWithRadius = (pathValue: IPathValue): paper.PathItem => {
  const pathData = pathValueToPath(pathValue) as paper.Path;
  // 处理圆角, 生成新的路径数据
  while (pathData.segments?.find((seg) => isSegWithRadius(seg as ISegment))) {
    const curSeg = pathData.segments?.find((seg) => isSegWithRadius(seg as ISegment))!;
    setRadius(curSeg, pathData);
  }
  return pathData;
};

/**
 * 缩放pathValue, 不缩放圆角
 * @param {IPathValue} pathValue
 * @param {{ x: number, y: number }} scale
 * @returns {IPathValue}
 */
export const onPathValueZoom = (pathValue: IPathValue, scale: { x: number; y: number }): IPathValue => {
  if (scale.x === 1 && scale.y === 1) {
    return pathValue;
  }

  const scalePoint = (pt: IPoint): IPoint => {
    return {
      x: pt.x * scale.x,
      y: pt.y * scale.y,
    };
  };

  const newData = pathValue.data.map((pt) => {
    const { point, handleOut, handleIn, radius } = pt;
    return {
      point: scalePoint(point),
      handleIn: scalePoint(handleIn),
      handleOut: scalePoint(handleOut),
      radius,
    };
  });
  return {
    ...pathValue,
    data: newData,
  };
};

/**
 * 通过pathdata创建pathItem
 * @param {string} pathData
 * @returns {PathItem}
 */
const pathDataToPathItem = (pathData: string): paper.PathItem => {
  // const canvas = document.createElement('canvas');
  // paper.setup(canvas);
  return paper.PathItem.create(pathData);
};

export const getPathItemOfComp = (comp: IComponentData, basePosition?: IPosition): paper.PathItem => {
  let compPathItem: paper.PathItem;

  const svgValue = componentToSvgValue(comp);
  if (!svgValue) {
    return new paper.Path();
  }
  const { data } = svgValue;
  let pathData = '';
  data.forEach((pathValue) => {
    pathData += transformPathDataToPath(pathValue) + ' ';
  });
  compPathItem = pathDataToPathItem(pathData);
  // if (comp.type === CCompoundPath) {
  //   let pathData = '';
  //   (comp.value as IPathValue[]).forEach((pathValue) => {
  //     pathData += transformPathDataToPath(pathValue) + ' ';
  //   });
  //   compPathItem = pathDataToPathItem(pathData);
  // } else {
  //   const pathData = transformPathDataToPath(comp.value as IPathValue);
  //   compPathItem = pathDataToPathItem(pathData);
  // }
  compPathItem.rotate(comp.rotate || 0);
  basePosition &&
    compPathItem.translate(new paper.Point(comp.position.x - basePosition.x, comp.position.y - basePosition.y));
  return compPathItem;
};

export const doCompoundConfig = (
  path1: paper.PathItem,
  path2: paper.PathItem,
  compoundModel: CompoundOperation,
): paper.PathItem => {
  switch (compoundModel) {
    case CompoundOperation.Exclude:
      return path1.exclude(path2);
    case CompoundOperation.Intersect:
      return path1.intersect(path2);
    case CompoundOperation.Subtract:
      return path1.subtract(path2);
    case CompoundOperation.Unite:
      return path1.unite(path2);
    default:
      return path1.unite(path2);
  }
};

export const segmentToData = (segment: ISegment) => {
  const { point, handleOut, handleIn } = segment;
  return {
    point: { x: point?.x || 0, y: point?.y || 0 },
    handleIn: { x: handleIn?.x || 0, y: handleIn?.y || 0 },
    handleOut: { x: handleOut?.x || 0, y: handleOut?.y || 0 },
  };
};

export const compoundPath = (comps: IComponentData[], compoundModel: CompoundOperation) => {
  // 需要考虑路径本身的旋转产生的影响
  if (comps.length < 2) {
    return;
  }
  const mainPathComp = comps[0];
  const otherPathComps = comps.slice(1, comps.length);
  const basePosition = comps
    .map((comp) => {
      return comp.position;
    })
    .reduce((minPosition, currentPosition) => {
      return {
        x: min(minPosition.x, currentPosition.x),
        y: min(minPosition.y, currentPosition.y),
      };
    });

  let mainPath: paper.PathItem = getPathItemOfComp(mainPathComp, basePosition);
  mainPath.closePath();
  let otherPaths: paper.PathItem[] = [];

  otherPathComps.forEach((comp) => {
    const path = getPathItemOfComp(comp, basePosition);
    path.closePath();
    otherPaths.push(path);
  });

  // 处理直线路径, 联合、插集方式直接加到复合路径中时
  const linePaths = ([mainPath, ...otherPaths] as paper.Path[]).filter((path) => sameNumber(Math.abs(path.area), 0));
  const lineBounds = linePaths.map((path) => path.bounds).filter((a) => a !== null) as paper.Rectangle[];
  const uniteBound = lineBounds.length
    ? lineBounds.reduce((a, b) => {
        return a.unite(b);
      })
    : undefined;

  const compoundPathItem = otherPaths.reduce((prePathItem, currentPathItem) => {
    return doCompoundConfig(prePathItem, currentPathItem, compoundModel);
  }, mainPath);
  const { className, bounds: compoundBounds, closed, children } = compoundPathItem as paper.Path;

  let bounds = compoundBounds;
  if ([CompoundOperation.Unite, CompoundOperation.Exclude].includes(compoundModel) && uniteBound) {
    if (bounds) {
      bounds = bounds.unite(uniteBound);
    } else {
      bounds = uniteBound;
    }
  }

  if (!bounds) {
    return;
  }

  if (!bounds?.width || !bounds?.height) {
    return;
  }

  if (bounds.width < 1 || bounds.height < 1) {
    return;
  }

  compoundPathItem.translate(new paper.Point(-(bounds.left || 0), -(bounds.top || 0)));
  const value: IPathValue[] = [];
  if (className === 'Path') {
    const pathValue: IPathValue = { data: [], closed: false };
    pathValue.data = (compoundPathItem as paper.Path).segments?.map(segmentToData) || [];
    pathValue.closed = !!closed;
    value.push(pathValue);
  } else if (className === 'CompoundPath') {
    value.push(
      ...(children?.map((item) => {
        return {
          data: (item as paper.Path).segments?.map(segmentToData) || [],
          closed: !!(item as paper.Path).closed,
        };
      }) || []),
    );
  }

  // 载入线条
  if ([CompoundOperation.Unite, CompoundOperation.Exclude].includes(compoundModel)) {
    linePaths.forEach((item) => {
      item.translate(new paper.Point(-(bounds?.left || 0), -(bounds?.top || 0)));
      const { className, children } = item;
      if (className === 'Path') {
        const pathValue: IPathValue = { data: [], closed: false };
        pathValue.data = (item as paper.Path).segments?.map(segmentToData) || [];
        pathValue.closed = false;
        value.push(pathValue);
      } else if (className === 'CompoundPath') {
        value.push(
          ...(children?.map((item) => {
            return {
              data: (item as paper.Path).segments?.map(segmentToData) || [],
              closed: false,
            };
          }) || []),
        );
      }
    });
  }

  if (!value.length) {
    return;
  }

  const components = comps.map((comp) => {
    const compData = depthClone(comp);
    compData.position = {
      x: compData.position.x - basePosition.x - (bounds?.left || 0),
      y: compData.position.y - basePosition.y - (bounds?.top || 0),
    };
    compData.layout = {
      responsive: true,
      auto: false,
      horizontal: HorizontalAlign.LeftAndRight,
      vertical: VerticalAlign.TopAndBottom,
      fixedHeight: false,
      fixedWidth: false,
    };
    compData._currentState = undefined;
    compData.selected = false;
    compData.disabled = false;
    return compData;
  });

  // 去除多余属性
  const properties = depthClone(comps[0].properties);
  Object.keys(properties)
    .filter((key) => !supportedCompoundPathProperties.includes(key))
    .forEach((key) => delete properties[key]);

  const unclosed = value.find((v) => !v.closed);
  if (properties.stroke && unclosed) {
    properties.stroke.position = StrokePosition.center;
  }

  const data: IComponentData = makeCommonComponent(getNewID(), CCompoundPath, {
    name: getDefaultComponentName(CCompoundPath),
    size: { width: bounds.width, height: bounds.height },
    position: {
      x: basePosition.x + (bounds.left || 0),
      y: basePosition.y + (bounds.top || 0),
    },
    opacity: isUndefined(comps[0].opacity) ? 100 : comps[0].opacity,
    properties: {
      ...properties,
      compoundModel: {
        prop: 'enum',
        componentOperation: compoundModel,
        disabled: false,
        hidden: true,
      },
    },
    components,
    value,
  });
  delete data.properties.padding;
  return data;
};

// 复合路径支持的属性
const supportedCompoundPathProperties = [FillPropertyName, StrokePropertyName, ShadowPropertyName];

// 路径支持的属性
export const supportedPathProperties = [
  FillPropertyName,
  StrokePropertyName,
  ShadowPropertyName,
  LinePropertyName,
  TextFormatExPropertyName,
  PaddingPropertyName,
];

/**
 * 多路径锚点类型
 */
export const getSegsType = (segs: ISegment[]): SegType | undefined => {
  if (!segs.length) return undefined;
  let oldType = SegType.Straight;
  for (let i = 0; i < segs.length; i++) {
    const type = getSegType(segs[i]);
    if (type !== oldType && i !== 0) {
      return undefined;
    }
    oldType = type;
  }
  return oldType;
};

// 是否允许编辑圆角
export function isAllowedSegRadiusEdit(seg: ISegment): boolean {
  const segType = getSegsType([seg]);
  // 非Straight不可编辑圆角
  if (segType !== SegType.Straight) {
    return false;
  }
  const { previous, next } = seg;
  // 邻点的handlein handleout不为零
  if (previous?.handleOut?.length || next?.handleIn?.length) {
    return false;
  }
  return true;
}

/**
 * 单个路径锚点类型
 */
export const getSegType = (seg: ISegment): SegType => {
  const { type, handleIn, handleOut } = seg;
  if (type) {
    return type;
  }
  if (seg.isSmooth()) {
    // 共线且臂长非零
    if (sameNumber(handleIn?.length || 0, handleOut?.length || 0)) {
      // 对称
      return SegType.Mirrored;
    } else {
      // 非对称
      return SegType.Asymmetric;
    }
  } else {
    if (sameNumber(handleIn?.length || 0, 0) && sameNumber(handleOut?.length || 0, 0)) {
      // 直线角
      return SegType.Straight;
    } else {
      // 分离
      return SegType.Disconnected;
    }
  }
};

/**
 * 根据当前handlein handleout 更新segtype；
 */
export const updateSegType = (seg: ISegment) => {
  const newType = getSegType(seg.clone());
  switch (newType) {
    case SegType.Straight:
    case SegType.Disconnected:
      seg.type = newType;
      break;
    case SegType.Mirrored:
      if (seg.type !== SegType.Disconnected && seg.type !== SegType.Asymmetric) seg.type = newType;
      break;
    case SegType.Asymmetric:
      if (seg.type !== SegType.Disconnected) seg.type = newType;
      break;
    default:
      break;
  }
};

/**
 * 拖动handle时根据当前segtype获取另一个handle数值
 */
export const getAnotherPointBySegType = (segType: SegType, point: paper.Point, otherPoint: paper.Point | null) => {
  switch (segType) {
    // 对称
    case SegType.Straight:
    case SegType.Mirrored:
      return new paper.Point(-point.x, -point.y);

    // 共线
    case SegType.Asymmetric: {
      if (!otherPoint) return otherPoint;
      const angle = point.angle;
      const newPoint = new paper.Point(otherPoint.length || 0, 0);
      return newPoint.rotate((angle || 0) + 180, new paper.Point(0, 0));
    }

    // 保持
    case SegType.Disconnected:
    default:
      return otherPoint;
  }
};

/**
 * 判断一条路径是否是有面积的闭合路径，即是否适用描边位置
 */
export const isClosedPathWithArea = (pathValue: IPathValue) => {
  const { data, closed } = pathValue;
  // 非闭合
  if (!closed) {
    return false;
  }
  // 一个路径点
  if (data.length < 2) {
    return false;
  }
  const firstSeg = data[0];
  const firstP = firstSeg.point;
  const nextSeg = data.find((seg) => !isEqual(firstP, seg.point));
  let k: number | undefined;
  if (nextSeg) {
    const nextP = nextSeg.point;
    k = (nextP.y - firstP.y) / (nextP.x - firstP.x);
  }
  // 共线且没有曲度的闭合路径
  if (!isUndefined(k) && !data.some((seg) => !isNoHandlePoint(seg) || !isCollinear(seg, firstSeg, k!))) {
    return false;
  }
  return true;
};

/**
 * 是否有handlein/handleout
 */
const isNoHandlePoint = (p: IPathPoint) => {
  return isEqual(p.handleIn, { x: 0, y: 0 }) || isEqual(p.handleOut, { x: 0, y: 0 });
};

const isCollinear = (seg: IPathPoint, refSeg: IPathPoint, k: number) => {
  const { point: p } = seg;
  const { point: refP } = refSeg;
  if (isEqual(p, refP)) {
    return true;
  }
  const k1 = (p.y - refP.y) / (p.x - refP.x);
  if (sameNumber(k1, k) || (Math.abs(k1) === Infinity && Math.abs(k) === Infinity)) {
    return true;
  }
  return false;
};

export const translateMaskDataOfRect = (data: string, border: IBorder, size: ISize, strokeWidth: number) => {
  const path = new paper.Path(data);
  if (!strokeWidth) {
    return data;
  }
  const { left, right, top, bottom } = border;
  const center = new paper.Point(0, 0);
  if (!!left && !right) {
    path.scale(strokeWidth / size.width + 1, 1, center);
  }
  if (!left && !!right) {
    path.scale(strokeWidth / size.width + 1, 1, center);
    path.translate(new paper.Point(-strokeWidth, 0));
  }
  if (!!top && !bottom) {
    path.scale(1, strokeWidth / size.height + 1, center);
  }
  if (!top && !!bottom) {
    path.scale(1, strokeWidth / size.height + 1, center);
    path.translate(new paper.Point(0, -strokeWidth));
  }

  return path.pathData || data;
};

// 渲染路径组件时缩放阴影和描边
export function getNewPathPropertiesByScalingTheStrokeAndShadow(properties: IProperties, globalScale: number) {
  const newProperties = depthClone(properties);
  const { stroke, shadow } = newProperties;
  // 缩放描边
  if (stroke) {
    stroke.dashArray && (stroke.dashArray = stroke.dashArray.map((i) => i * globalScale));
    stroke.thickness && (stroke.thickness *= globalScale);
  }
  // 缩放阴影
  if (shadow) {
    shadow.blur && (shadow.blur *= globalScale);
    shadow.x && (shadow.x *= globalScale);
    shadow.y && (shadow.y *= globalScale);
  }
  return newProperties;
}

// 将旋转路径组件修正为未旋转的，获取新的value,size,position
export function resetPathValueRotate(pathValue: IPathValue, rotate: number, position: IPosition) {
  const pathData = pathValueToPathWithRadius(pathValue) as paper.Path;
  pathData.rotate(rotate);
  const delta = {
    x: pathData.bounds?.x || 0,
    y: pathData.bounds?.y || 0,
  };
  const newSize = {
    width: pathData.bounds?.width || 0,
    height: pathData.bounds?.height || 0,
  };
  const newPosition = {
    x: position.x + delta.x,
    y: position.y + delta.y,
  };
  pathData.translate(new paper.Point({ x: -delta.x, y: -delta.y }));
  const newValue: IPathValue = {
    data: pathData.segments?.map((s) => segmentToData(s)) ?? [],
    closed: !!pathData.closed,
  };
  return {
    newValue,
    newSize,
    newPosition,
  };
}

/**
 * 处理圆角，将包含圆角的pathValue处理为可用的pathValue
 */
export function mergeRadiusForPathValue(pathValue: IPathValue) {
  const pathData = pathValueToPathWithRadius(pathValue) as paper.Path;
  return {
    data: pathData.segments?.map((s) => segmentToData(s)) ?? [],
    closed: !!pathData.closed,
  };
}

/**
 * 计算由两个点连接成的折线，最多有三段线构成
 * @param start
 * @param end
 * @param direction
 */
export function calcPathByTwoPoints(
  start: IPoint,
  end: IPoint,
  direction: 'left' | 'right' | 'top' | 'bottom',
): number[] {
  const isVertical = ['top', 'bottom'].includes(direction);
  const width = end.x - start.x;
  const height = end.y - start.y;

  const path = [start.x, start.y];
  // 当前为垂直方向的拐点
  if (isVertical) {
    const inflectionPointY = start.y + Math.floor(height / 2);
    path.push(start.x, inflectionPointY);
    path.push(end.x, inflectionPointY);
  } else {
    const inflectionPointX = start.x + Math.floor(width / 2);
    path.push(inflectionPointX, start.y);
    path.push(inflectionPointX, end.y);
  }
  path.push(end.x, end.y);
  return path;
}
