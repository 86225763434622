import * as jsonpatch from 'fast-json-patch';
import { AvlTree } from '@tyriar/avl-tree';

import { IComponentData } from '../models/component';
import { addComponentNode, removeComponentNodeByID } from './avlTree';

// 针对单个组件的操作
export type ComponentOperations = Operation[];

// 针对画板的操作
export type ArtboardOperations = {
  // 当 componentID 为 self 时表示是针对画板自身的操作
  [componentID: string]: ComponentOperations;
};

// 针对页面的操作
export interface PageOperations {
  [artboardID: string]: ArtboardOperations;
}

// 针对单个组件的 patch
export type ComponentPatches = {
  do: ComponentOperations;
  undo: ComponentOperations;
};

// 针对画板的 patch
export interface ArtboardPatches {
  do: ArtboardOperations;
  undo: ArtboardOperations;
}

// 针对页面的 patch
export interface PagePatches {
  [artboardID: string]: ArtboardPatches;
}

export interface BaseOperation {
  path: string;
}

export declare type Operation =
  | AddOperation<any>
  | RemoveOperation
  | ReplaceOperation<any>
  | MoveOperation
  | CopyOperation
  | AddChildrenOperation
  | RemoveChildrenOperation;

export interface AddOperation<T> extends BaseOperation {
  op: 'add';
  value: T;
}

export interface RemoveOperation extends BaseOperation {
  op: 'remove';
}

export interface ReplaceOperation<T> extends BaseOperation {
  op: 'replace';
  value: T;
}

export interface MoveOperation extends BaseOperation {
  op: 'move';
  from: string;
}

export interface CopyOperation extends BaseOperation {
  op: 'copy';
  from: string;
}

export interface RemoveChildrenOperation extends BaseOperation {
  op: 'remove-children';
  // 待删除组件的 id
  value: string[];
}

export interface AddChildrenOperation extends BaseOperation {
  op: 'add-children';
  // 待添加组件的位置
  value: IComponentData[];
}

function addPrefixZeroify(num: number) {
  if (num < 10) {
    return `0${num}`;
  }
  return num.toString();
}

/*
 *返回用户可读的日期+时间
 */
const readableDateTime = (datetime: Date) => {
  const datePart = `${datetime.getFullYear()}-${addPrefixZeroify(1 + datetime.getMonth())}-${addPrefixZeroify(
    datetime.getDate(),
  )}`;
  const timePart = `${addPrefixZeroify(datetime.getHours())}:${addPrefixZeroify(
    datetime.getMinutes(),
  )}:${addPrefixZeroify(datetime.getSeconds())}`;
  return `${datePart} ${timePart}`;
};

function logPatchWarnInfo(msg: string) {
  console.warn(`${readableDateTime(new Date())} ${msg}`);
}

/**
 * 应用操作
 * FIXME: 在 json-patch 中，如果某个操作应用失败，应该全部失败，不应用，目前这里没处理
 */
export function applyOperations(data: IComponentData, operations: Operation[]) {
  if (operations.length === 0) {
    return;
  }
  // 这个是用来更新版本号的
  operations.push(Ops.replace('/v', (data.v || 0) + 1));
  for (let operation of operations) {
    if (data.components && data.components.some(v => !v)) {
      logPatchWarnInfo(`检测到 null 组件，自动修复。`);
      data.components = data.components.filter(v => v);
    }
    if (Ops.isRemoveChildrenOperation(operation)) {
      if (data.components) {
        data.components = data.components.filter(
          (comp: IComponentData) => comp && !(<RemoveChildrenOperation>operation).value.includes(comp._id),
        );
      }
      continue;
    }
    if (Ops.isAddChildrenOperation(operation)) {
      if (!operation.value || operation.value.length === 0 || operation.value.some(v => !v)) {
        logPatchWarnInfo(`Abort Patch! 检测到 add operation 中的 value 有空值，数据：${JSON.stringify(operation)}`);
        continue;
      }
      if (!data.components) {
        data.components = [];
      }
      if (
        operation.value.some(newComp => {
          const duplicate = data.components!.find(comp => comp._id === newComp._id);
          if (duplicate) {
            return true;
          }
          return false;
        })
      ) {
        logPatchWarnInfo(`Duplicate Add Detected! 检测到向同一个组件添加重复的组件，放弃执行。`);
        continue;
      }
      const position = parseInt(operation.path, 10);
      const indexToInsert = position < 0 || position > data.components.length ? data.components.length : position;
      data.components.splice(indexToInsert, 0, ...operation.value);
      continue;
    }
    jsonpatch.applyOperation(data, operation);
  }
}

/**
 * 应用操作，并且将更改应用到 tree 中
 * @param data
 * @param operations
 */
export function applyOperationsAndApplyToAvlTree(
  data: IComponentData,
  operations: Operation[],
  tree: AvlTree<string, IComponentData>,
) {
  if (operations.length === 0) {
    return;
  }
  if (data.type !== 'artboard') {
    // 这个是用来更新版本号的
    operations.push(Ops.replace('/v', data.v + 1));
  }
  const addNodes: IComponentData[] = [];
  for (let operation of operations) {
    if (Ops.isRemoveChildrenOperation(operation)) {
      if (data.components) {
        operation.value.forEach(id => removeComponentNodeByID(tree, id));
        data.components = data.components.filter(
          (comp: IComponentData) => comp && !(<RemoveChildrenOperation>operation).value.includes(comp._id),
        );
      }
      continue;
    }
    if (Ops.isAddChildrenOperation(operation)) {
      if (!data.components) {
        data.components = [];
      }
      operation.value.forEach(item => addComponentNode(tree, item));
      addNodes.push(...operation.value);
      const position = parseInt(operation.path, 10);
      const indexToInsert = position < 0 || position > data.components.length ? data.components.length : position;
      data.components.splice(indexToInsert, 0, ...operation.value);
      continue;
    }
    jsonpatch.applyOperation(data, operation);
  }
  return addNodes;
}

export function findAndPatch(data: IComponentData, id: string, operations: Operation[]): boolean {
  if (data._id === id) {
    applyOperations(data, operations);
    return true;
  }
  if (data.components && data.components.length > 0) {
    const handleRes = data.components.map(comp => findAndPatch(comp, id, operations));
    return handleRes.some(r => r);
  }
  return false;
}

export function patchArtboard(data: IComponentData, componentPatches: ArtboardOperations) {
  for (let id in componentPatches) {
    const patches = componentPatches[id];
    if (id === 'self') {
      continue;
    }
    if (id === 'ROOT') {
      applyOperations(data, patches);
      continue;
    }
    let applySucceed: boolean = false;
    if (data.components && data.components.length > 0) {
      const handleRes = data.components.map(comp =>
        // FIXME: 这里 components 是有可能为空的（数据出错了导致的）
        comp ? findAndPatch(comp, id, patches) : null,
      );
      applySucceed = handleRes.some(r => r);
    }
    if (!applySucceed) {
      logPatchWarnInfo(`对 ${id} 的操作 ${JSON.stringify(patches)} 失败。`);
    }
  }
}

export function patchArtboardByAVLTree(
  data: IComponentData,
  tree: AvlTree<string, IComponentData>,
  componentPatches: ArtboardOperations,
): IComponentData[] {
  const addNodes: IComponentData[] = [];
  for (let id in componentPatches) {
    const patches = componentPatches[id];
    if (id === 'self') {
      continue;
    }
    if (patches.length === 0) {
      logPatchWarnInfo(`检测到空操作：${id} @ ${JSON.stringify(componentPatches)}`);
    }
    if (id === 'ROOT') {
      const nodes = applyOperationsAndApplyToAvlTree(data, patches, tree);
      if (nodes) {
        addNodes.push(...nodes);
      }
      continue;
    }
    let applySucceed: boolean = false;
    const node = tree.get(id);
    if (node) {
      const nodes = applyOperationsAndApplyToAvlTree(node, patches, tree);
      if (nodes) {
        addNodes.push(...nodes);
      }
      applySucceed = true;
    }
    if (!applySucceed) {
      logPatchWarnInfo(`[avl] 对 ${id} 的操作 ${JSON.stringify(patches)} 失败。`);
    }
  }
  return addNodes;
}

// 反转 page patches
export function reversePagePatches(patches: PagePatches): PagePatches {
  const newPatches: PagePatches = {};
  for (let artboardID in patches) {
    newPatches[artboardID] = {
      do: patches[artboardID].undo,
      undo: patches[artboardID].do,
    };
  }
  return newPatches;
}

export function extractPageOperationsFromPagePatches(patches: PagePatches): PageOperations {
  const operations: PageOperations = {};
  for (let artboardID in patches) {
    operations[artboardID] = patches[artboardID].do;
  }
  return operations;
}

export namespace Ops {
  export function add<T>(path: string, value: T): AddOperation<T> {
    return {
      op: 'add',
      path,
      value,
    };
  }

  export function remove(path: string): RemoveOperation {
    return {
      op: 'remove',
      path,
    };
  }

  export function replace<T>(path: string, value: T): ReplaceOperation<T> {
    return {
      op: 'replace',
      path,
      value,
    };
  }

  export function move(path: string, from: string): MoveOperation {
    return {
      op: 'move',
      path,
      from,
    };
  }

  export function copy(path: string, from: string): CopyOperation {
    return {
      op: 'copy',
      path,
      from,
    };
  }

  export function addChildren(path: string, value: IComponentData[]): AddChildrenOperation {
    return {
      op: 'add-children',
      path,
      value,
    };
  }

  export function removeChildren(value: string[]): RemoveChildrenOperation {
    return {
      op: 'remove-children',
      path: '-',
      value,
    };
  }

  export function isRemoveChildrenOperation(op: Operation): op is RemoveChildrenOperation {
    return !Array.isArray(op) && op.op === 'remove-children';
  }

  export function isAddChildrenOperation(op: Operation): op is AddChildrenOperation {
    return !Array.isArray(op) && op.op === 'add-children';
  }
}
