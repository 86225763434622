export enum RPFreeProgramProgressNode {
  Initial = 'one-year',
  First = 'one',
  Second = 'two',
  Third = 'three',
  Fourth = 'four',
  Last = 'forever',
}
export interface IRPFreeProgressNode {
  id: RPFreeProgramProgressNode;
  text: string;
  active: boolean;
  count: number;
  value: string;
}

export enum RPFreeProgramPageType {
  Basic = 'basic',
  Invitation = 'invitation',
  InvitationRecord = 'invitation-record',
  ReceiveForever = 'receive-forever',
}

export interface IRPFreeProgramInvitationRecord {
  id: number;
  userID: number;
  invitationUserID: number;
  state: number;
  teamID: string;
  createdAt: string;
  updatedAt: string;
  invitationUsername: string;
  username: string;
}
