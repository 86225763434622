import { IComponentData } from '@/fbs/rp/models/component';
import { EChartType_basic } from '@/fbs/rp/models/properties/chart';
import { IChartValue } from '@/fbs/rp/models/chart';
import { makeCommonComponent } from '@/libs/helper';

import { IComponentItem } from '../../../types';
import { CScatterChart } from '../../../constants';
import { getChartConfig, makeChartData, getSeriesChartOneDefaultData } from '../common';

export const ScatterChartConfig: IComponentItem = getChartConfig(CScatterChart, EChartType_basic.Scatter);

export function makeScatterChart(id: string): IComponentData {
  return makeChartData(id, CScatterChart, EChartType_basic.Scatter);
}

export function makeAIScatterChart(id: string, value: IChartValue): IComponentData {
  return makeCommonComponent(id, CScatterChart, {
    ...getSeriesChartOneDefaultData(CScatterChart, EChartType_basic.Scatter),
    size: {
      width: 400,
      height: 250,
    },
    lib: {
      id: 'chart',
      type: CScatterChart,
    },
    value: value,
  });
}
