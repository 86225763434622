import * as React from 'react';

import PopContent from './PopContent';

import './index.scss';

interface IPopoverProps {
  children?: React.ReactChild;
  content: React.ReactNode;
  isShow: boolean;
  minWidth?: number | string;
}

const Popover: React.FC<IPopoverProps> = (props: IPopoverProps) => {
  const { children, content, isShow, minWidth } = props;

  return (
    <div className="dsm-c-popover">
      {children}
      {isShow && <PopContent content={content} minWidth={minWidth} />}
    </div>
  );
};

export default Popover;
