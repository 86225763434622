import { IComponentData } from '@fbs/rp/models/component';
import { ArtboardPatches, Ops, PagePatches } from '@fbs/rp/utils/patch';
import { UIComponent, UIContainerComponent, UIPanelComponent } from '.';
import { PropertyName, PropertyValue } from '@fbs/rp/models/property';
import ICell from '@fbs/rp/models/properties/cell';
import { SelectionGroupSetting } from '@/components/Application/WorkContainer/Workspace/SelectionGroupDialog';
import {
  flattComps,
  getTwoDimensionalArray,
  setDefaultStatusOfSelectionGroupChild,
  updateChildrenPositionByGrid,
} from '@helpers/componentHelper';
import { cloneDeep, defaultTo, flatten, get, isEqual } from 'lodash';
import { ComponentResizeResult, extractDynamicInfoFromPatch, ResizeOptions } from '@editor/comps/resizeHelper';
import { getNewID } from '@helpers/idHelper';
import { makeSelectPanel } from '../../libs/containers/SelectPanel';
import { ArtboardPatchesClass } from '@editor/patches/artboardPatches';
import { coverPatches } from '@helpers/patchHelper';
import { IBoundsOffset, IPoint } from '@fbs/common/models/common';
import { HorizontalAlign, IBasicLayout, VerticalAlign } from '@fbs/rp/models/layout';
import { round } from '@utils/globalUtils';
import { PredefinedStates } from '@consts/state';
import i18n from '@/i18n';

export default class UISelectPanelComponent extends UIPanelComponent {
  /**
   *
   * @type {boolean}
   * @override UIContainerComponent.canMoveChildren
   */
  public canMoveChildren = false;

  get canAddChildren(): boolean {
    const properties = this.properties;
    let rowRount = properties.cell?.rowCount || 0;
    let columnCount = properties.cell?.columnCount || 0;
    if (rowRount > 1 && columnCount > 1) {
      return false;
    }
    return !(rowRount === 1 && columnCount === 1);
  }

  get canRemoveChildren(): boolean {
    if (this.components.length === 0) {
      return false;
    }
    const properties = this.properties;
    let rowRount = properties.cell?.rowCount || 0;
    let columnCount = properties.cell?.columnCount || 0;
    if (rowRount === 1 && columnCount > 2) {
      return true;
    }
    return columnCount === 1 && rowRount > 2;
  }

  get setting(): {
    rowCount: number;
    columnCount: number;
    rowHeight: number;
    columnWidth: number;
    rowGap: number;
    columnGap: number;
  } {
    const rowCount = defaultTo(get(this, 'properties.cell.rowCount'), 1);
    const columnCount = defaultTo(get(this, 'properties.cell.columnCount'), 1);
    const rowHeight = defaultTo(get(this, 'properties.cell.rowHeight'), 1);
    const columnWidth = defaultTo(get(this, 'properties.cell.columnWidth'), 1);
    const rowGap = defaultTo(get(this, 'properties.cell.rowGap'), 1);
    const columnGap = defaultTo(get(this, 'properties.cell.columnGap'), 1);
    return { rowCount, columnCount, rowHeight, columnWidth, rowGap, columnGap };
  }

  /**
   * 组件创建之后
   * @override UIContainerComponent.doAfterCreate
   */
  doAfterCreate() {
    // reArrangeSelectPanel(this.data);
  }

  /**
   * 子元素更新之前
   */
  doRefreshBefore() {
    // reArrangeSelectPanel(this.data);
  }

  resizeHandler2(
    offset: IBoundsOffset,
    layout: IBasicLayout,
    options: ResizeOptions,
    backupAllCompsLayout?: WeakMap<UIComponent, IBasicLayout>,
  ): ComponentResizeResult {
    const patches = super.resizeHandler2(offset, layout, options, backupAllCompsLayout);
    const firstChild = this.components[0];
    const { rowCount, columnCount, rowGap, columnGap } = this.setting;
    const secondChildInHorizontal = columnCount > 1 && this.components[1];
    const secondChildInVertical = rowCount > 1 && this.components[columnCount];
    let patches1 = patches.patches;
    const result = new ArtboardPatchesClass();
    if (patches1) {
      coverPatches(result, patches1);
      let newCellAttr = cloneDeep(this.properties.cell!);
      const dynamicEditInfo = extractDynamicInfoFromPatch(patches1);
      const newItemSize = dynamicEditInfo[firstChild.id]?.size;
      //调整itemSize
      let size = dynamicEditInfo[firstChild.id]?.size || firstChild.size;
      let position = dynamicEditInfo[firstChild.id]?.position || firstChild.position;
      if (newItemSize && !isEqual(newItemSize, firstChild.size)) {
        newCellAttr.rowHeight = newItemSize.height;
        newCellAttr.columnWidth = newItemSize.width;
      }

      //调整列间距
      if (secondChildInHorizontal) {
        const newSecondChildPosition = get(dynamicEditInfo[secondChildInHorizontal.id], 'position');
        if (newSecondChildPosition) {
          const newColumnGap = newSecondChildPosition.x - position.x - size.width;
          if (newColumnGap !== columnGap) {
            newCellAttr.columnGap = round(newColumnGap);
          }
        }
      }
      //调整行间距
      if (secondChildInVertical) {
        const newSecondChildPosition = get(dynamicEditInfo[secondChildInVertical.id], 'position');
        if (newSecondChildPosition) {
          const newRowGap = newSecondChildPosition.y - position.y - size.height;
          if (newRowGap !== rowGap) {
            newCellAttr.rowGap = round(newRowGap);
          }
        }
      }

      const newSize = getNewSizeOfSelectionGroup(
        { width: newCellAttr.columnWidth || 1, height: newCellAttr.rowHeight || 1 },
        { ...this.setting, ...newCellAttr },
      );
      patches.size = newSize;

      coverPatches(
        result,
        this.setProperty('cell', {
          ...this.setting,
          ...newCellAttr,
        }),
      );
    }
    //根据大小的变化 修改itemSize,rowHeight,columnWidth,gap
    return {
      ...patches,
      patches: result,
    };
  }

  addComponents(
    components: IComponentData[],
    index: number = -1,
  ): { patches: PagePatches; newActiveGroup?: UIContainerComponent } {
    let result = super.addComponents(components, index);
    const { rowCount, columnCount, rowHeight, columnWidth, rowGap, columnGap } = this.setting;

    const isHorizontal = rowCount === 1 && columnCount > 1;
    const isVertical = (rowCount || 0) > 1 && columnCount === 1;
    const currentChild = this.components;

    //纠正新增子项的位置
    let lastComp = currentChild[currentChild.length - 1];
    let prevPosition = isHorizontal ? lastComp.position.x : lastComp.position.y;
    let prevSize = isHorizontal ? lastComp.size.width : lastComp.size.height;
    let gap = isHorizontal ? columnGap || 0 : rowGap || 0;
    components.forEach((newComp) => {
      const newPosition = prevPosition + prevSize + gap;
      isHorizontal && (newComp.position.x = newPosition);
      isVertical && (newComp.position.y = newPosition);
      prevPosition = newPosition;
      prevSize = isHorizontal ? newComp.size.width : newComp.size.height;
    });
    const newRowCount = isHorizontal ? rowCount : rowCount + 1;
    const newColumnCount = isHorizontal ? columnCount + 1 : columnCount;
    //修正 setting
    const cellPatches = new ArtboardPatchesClass().getAttrChangePatches(this.id, '/properties/cell', {
      oldVal: this.properties.cell,
      newVal: { ...this.properties.cell, rowCount: newRowCount, columnCount: newColumnCount },
    });
    //纠正容器的大小
    coverPatches(result.patches[this.ownerArtboardID], cellPatches);

    const containerSizePatches = this.getSizePatches(
      { columnCount: newColumnCount, rowCount: newRowCount, rowGap, columnGap },
      { width: columnWidth, height: rowHeight },
    );

    coverPatches(result.patches[this.ownerArtboardID], containerSizePatches);
    return result;
  }

  removeComponents(components: UIComponent[]): { patches: PagePatches; newActiveGroup?: UIContainerComponent } {
    const { rowCount, columnCount, rowHeight, columnWidth, rowGap, columnGap } = this.setting;
    let result = super.removeComponents(components);
    const isHorizontal = rowCount === 1 && (columnCount || 0) > 1;
    const isVertical = (rowCount || 0) > 1 && columnCount === 1;

    const newRowCount = isHorizontal ? rowCount : rowCount - 1;
    const newColumnCount = isHorizontal ? columnCount - 1 : columnCount;

    const remainChildren = this.components.filter(
      (comp) =>
        !components.find((removingComp) => {
          return comp.id === removingComp.id;
        }),
    );
    let fisrtComp = remainChildren[0];
    let prevPosition = isHorizontal ? fisrtComp.position.x : fisrtComp.position.y;
    let prevSize = isHorizontal ? fisrtComp.size.width : fisrtComp.size.height;
    let gap = isHorizontal ? columnGap || 0 : rowGap || 0;
    remainChildren.forEach((remainComp, index) => {
      const newPositionZ = index === 0 ? 0 : prevPosition + prevSize + gap;
      const newPosition = cloneDeep(remainComp.position);
      isHorizontal && (newPosition.x = newPositionZ);
      isVertical && (newPosition.y = newPositionZ);
      prevPosition = newPositionZ;
      prevSize = isHorizontal ? remainComp.size.width : remainComp.size.height;
      coverPatches(
        result.patches[this.ownerArtboardID],
        new ArtboardPatchesClass().getAttrChangePatches(remainComp.id, remainComp.getCurrentPositionPath(), {
          oldVal: remainComp.position,
          newVal: newPosition,
        }),
      );
    });
    //修正 setting
    const cellPatches = new ArtboardPatchesClass().getAttrChangePatches(this.id, '/properties/cell', {
      oldVal: this.properties.cell,
      newVal: { ...this.properties.cell, rowCount: newRowCount, columnCount: newColumnCount },
    });
    //纠正容器的大小
    coverPatches(result.patches[this.ownerArtboardID], cellPatches);

    const containerSizePatches = this.getSizePatches(
      { columnCount: newColumnCount, rowCount: newRowCount, rowGap, columnGap },
      { width: columnWidth, height: rowHeight },
    );
    coverPatches(result.patches[this.ownerArtboardID], containerSizePatches);
    return result;
  }

  private getSizePatches(
    setting: { columnGap: number; rowGap: number; rowCount: number; columnCount: number },
    itemSize: { width: number; height: number },
  ) {
    return new ArtboardPatchesClass().getAttrChangePatches(this.id, this.getCurrentSizePath(), {
      oldVal: this.size,
      newVal: getNewSizeOfSelectionGroup(itemSize, setting),
    });
  }

  // eslint-disable-next-line no-unused-vars
  moveChildren(components: UIComponent[]): PagePatches {
    return {
      [this.ownerArtboardID]: {
        do: {},
        undo: {},
      },
    };
  }

  setProperty(propertyName: PropertyName, newValue: PropertyValue, ignoreUpdateValue?: boolean): ArtboardPatches {
    const {
      columnGap: originColumnGap,
      rowGap: originRowGap,
      rowCount: originRowCount,
      columnCount: originColumnCount,
      rowHeight: originRowHeight,
      columnWidth: originColumnWidth,
    } = this.properties.cell as ICell;
    const {
      columnGap: newColumnGap,
      rowGap: newRowGap,
      columnCount: newColumnCount,
      rowCount: newRowCount,
      rowHeight: newRowHeight,
      columnWidth: newColumnWidth,
    } = newValue as ICell;
    let patches = super.setProperty(propertyName, newValue, ignoreUpdateValue);
    if (propertyName === 'cell') {
      let isRowCountChange = newRowCount !== undefined && newRowCount !== originRowCount;
      let isColumnCountChange = newColumnCount !== undefined && newColumnCount !== originColumnCount;
      let isNeedReCreate = isRowCountChange || isColumnCountChange;
      let newSetting = {
        rowGap: newRowGap || originRowGap || 0,
        columnGap: newColumnGap || originColumnGap || 0,
        columnCount: newColumnCount || originColumnCount || 3,
        rowCount: newRowCount || originRowCount || 1,
      };
      if (isNeedReCreate) {
        const p = this.reCreateChildren(this.components[0].toJSON(), newSetting);
        coverPatches(patches, p);
      } else {
        let isNeedLayout =
          (newColumnGap !== undefined && newColumnGap !== originColumnGap) ||
          (newRowGap !== undefined && newRowGap !== originRowGap) ||
          newRowHeight !== originRowHeight ||
          newColumnWidth !== originColumnWidth;
        if (isNeedLayout) {
          const itemWidth = newColumnWidth || originColumnWidth || 1;
          const itemHeight = newRowHeight || originRowHeight || 1;
          this.components.forEach((comp, index) => {
            const rowIndex = Math.floor(index / (originColumnCount || 1));
            const columnIndex = index % (originColumnCount || 1);
            const newPositionX = columnIndex * (itemWidth + (newColumnGap || 0));
            const newPositionY = rowIndex * (itemHeight + (newRowGap || 0));
            let p = new ArtboardPatchesClass().getAttrChangePatches(comp.id, comp.getCurrentPositionPath(), {
              oldVal: comp.position,
              newVal: {
                x: newPositionX,
                y: newPositionY,
              },
            });
            coverPatches(patches, p);
            if (newRowHeight !== originRowHeight || newColumnWidth !== originColumnWidth) {
              let sizeP = new ArtboardPatchesClass().getAttrChangePatches(comp.id, comp.getCurrentSizePath(), {
                oldVal: comp.size,
                newVal: { height: itemHeight, width: itemWidth },
              });
              coverPatches(patches, sizeP);
            }
          });
          const newSize = getNewSizeOfSelectionGroup({ height: itemHeight, width: itemWidth }, newSetting);
          coverPatches(
            patches,
            new ArtboardPatchesClass().getAttrChangePatches(this.id, this.getCurrentSizePath(), {
              oldVal: this.size,
              newVal: newSize,
            }),
          );
        }
      }
    }
    return patches;
  }

  private reCreateChildren(item: IComponentData, newSetting: SelectionGroupSetting) {
    item.selected = false;
    const addedCompData: IComponentData[] = getNewChildOfSG(newSetting, item);
    const patches = new ArtboardPatchesClass();
    const { patches: addPatches } = super.addComponents(addedCompData);
    //获取删除老组件的 patch
    const { patches: removePatch } = super.removeComponents(this.components);
    const artboardID = this.ownerArtboardID;
    const sizePatches = new ArtboardPatchesClass().getAttrChangePatches(this.id, this.getCurrentSizePath(), {
      oldVal: this.size,
      newVal: getNewSizeOfSelectionGroup(item.size, newSetting),
    });
    [addPatches, removePatch].forEach((p) => coverPatches(patches, p[artboardID]));
    coverPatches(patches, sizePatches);
    return patches;
  }

  switchSelectState(selComp: UIComponent, selected: boolean) {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    const comps = selected ? this.components : [selComp];
    comps.forEach((comp) => {
      const itemComps = flattComps(comp);
      let stateID = '';
      if (selComp === comp && selected) {
        stateID = PredefinedStates.checked;
      }
      itemComps.forEach((c) => {
        patches.do[c.id] = [Ops.replace('./_currentState', stateID)];
        patches.undo[c.id] = [Ops.replace('./_currentState', c.toJSON()._currentState)];
      });
    });

    return patches;
  }
}

export function getNewChildOfSG(selectionGroupSetting: SelectionGroupSetting, dataOfSelectedComp: IComponentData) {
  const { rowCount, rowGap, columnCount, columnGap } = selectionGroupSetting;
  const comps = getTwoDimensionalArray(rowCount, columnCount, dataOfSelectedComp);
  // 1.根据二维数组的位置来定位
  // 2.并展开成一维数组
  // 3.重命名
  // 4.所有子组件(递归)设置新 ID
  // 5.所有子项设置预设状态
  const positionedComps = flatten(updateChildrenPositionByGrid(comps, rowGap, columnGap)).map((child) => {
    const clonedChild = cloneDeep(child);
    clonedChild.layout.responsive = true;
    delete clonedChild._currentState;
    return clonedChild;
  });
  const resetProperty = (comp: IComponentData) => {
    comp.autoSize = false;
    comp.components?.map(resetProperty);
  };
  positionedComps.map(resetProperty);
  setDefaultStatusOfSelectionGroupChild(positionedComps, PredefinedStates.checked);

  return positionedComps;
}

export function getNewSizeOfSelectionGroup(
  itemSize: { width: number; height: number },
  selectionGroupSetting: SelectionGroupSetting,
) {
  let width =
    itemSize.width * selectionGroupSetting.columnCount +
    selectionGroupSetting.columnGap * (selectionGroupSetting.columnCount - 1);
  let height =
    itemSize.height * selectionGroupSetting.rowCount +
    selectionGroupSetting.rowGap * (selectionGroupSetting.rowCount - 1);
  return { width, height };
}

/**
 *
 * @param item 第一级的子项源
 * @param selectionGroupSetting
 * @param position
 */
export function getNewCreatedSGData(
  item: IComponentData,
  selectionGroupSetting: SelectionGroupSetting,
  position: IPoint,
) {
  //拼装新增的选择框组件的数据
  item.selected = false;

  const itemSize = { width: item.size.width, height: item.size.height };

  let { width, height } = getNewSizeOfSelectionGroup(itemSize, selectionGroupSetting);

  const positionedComps = getNewChildOfSG(selectionGroupSetting, item);
  const data: Partial<IComponentData> = {
    name: i18n('resource.components.selectPanel'),
    position: position,
    size: {
      width: width,
      height: height,
    },
    layout: {
      horizontal: HorizontalAlign.LeftAndRight,
      vertical: VerticalAlign.TopAndBottom,
      auto: true,
      responsive: true,
      fixedHeight: false,
      fixedWidth: false,
    },
    properties: {
      layout: {
        direction: 'horizontal',
        verticalAlign: 'top',
        horizontalAlign: 'center',
        horizontalGap: 0,
        verticalGap: 0,
        hidden: true,
      },
      cell: {
        columnCount: selectionGroupSetting.columnCount,
        rowCount: selectionGroupSetting.rowCount,
        rowGap: selectionGroupSetting.rowGap,
        columnGap: selectionGroupSetting.columnGap,
        rowHeight: item.size.height,
        columnWidth: item.size.width,
      },
    },
    components: positionedComps,
    sealed: true,
    select: {
      enabled: true,
      target: 'child',
      maxCount: 1,
      minCount: 1,
      reversible: false,
      autoUnselect: true,
    },
  };

  return makeSelectPanel(getNewID(), data);
}
