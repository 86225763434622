import * as React from 'react';
import classnames from 'classnames';

import i18n from '@i18n';

import { IComponentData } from '@fbs/rp/models/component';
import { HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import { SpriteThumb } from '@consts/spriteIcons';
import { StyleHelper } from '@/helpers/styleHelper';
import { ISize } from '@fbs/common/models/common';

import { PredefinedStates } from '@consts/state';
import { OtherRes } from '@consts/resources';

import { IComponentItem, IComponentProps } from '../../types';
import { makeCommonComponent } from '../../helper';
import { CFrame } from '../../constants';

import './index.scss';

export function makeFrame(id: string): IComponentData {
  return makeCommonComponent(id, CFrame, {
    size: {
      width: 400,
      height: 225,
    },
    layout: {
      responsive: false,
      auto: true,
      fixedWidth: false,
      fixedHeight: false,
      horizontal: HorizontalAlign.Auto,
      vertical: VerticalAlign.Auto,
    },
    properties: {},
    value: '',
  });
}

export const FrameConfig: IComponentItem = {
  type: CFrame,
  name: i18n('resource.components.frame'),
  thumb: {
    spriteIconClass: SpriteThumb.Frame.className,
    dragPosition: SpriteThumb.Frame.position,
  },
  predefinedStates: [PredefinedStates.disabled],
};

const checkInQqDocs = () => {
  const qqDocsUrl = 'docs.qq.com';
  let parenturl = '';
  try {
    parenturl = window.parent ? window.parent.location.href : '';
  } catch (e) {
    window.debug && console.log(e);
  }

  return !![document.referrer, location.href, parenturl].find((t) => t.indexOf(qqDocsUrl) >= 0);
};

export default class Frame extends React.Component<
  IComponentProps,
  { illegal?: boolean; url?: string; inQqDocs?: boolean }
> {
  private frame: React.RefObject<HTMLIFrameElement> = React.createRef();

  constructor(props: IComponentProps) {
    super(props);

    if (checkInQqDocs()) {
      this.state = {
        inQqDocs: true,
      };
    } else {
      this.state = {};
    }
  }

  static getDerivedStateFromError() {
    return { illegal: true };
  }

  private isLoop(url: string) {
    const urls: string[] = window.location.host ? [window.location.host] : [];
    let win: any = window;

    try {
      while (win !== win.parent) {
        win = win.parent;
        if (win.location?.host) {
          urls.push(win.location.host);
        } else {
          break;
        }
      }
    } catch {
      window.debug && console.log('Frame Error');
    }
    if (urls.length > 2) {
      return true;
    }
    return urls.some((item) => url.includes(item));
  }

  private handleError = (e: React.SyntheticEvent) => {
    window.debug && console.log(e);
  };

  private renderIFrame(size: ISize, opacity: number, url: string) {
    let src = url;
    if (src.indexOf('control=false') === -1) {
      if (src.indexOf('?') === -1) {
        src = `${url}?control=false`;
      } else {
        src = `${url}&control=false`;
      }
    }
    return (
      <iframe
        style={{ ...size, opacity }}
        className="lib-comp-frame-container"
        src={src}
        allow="*"
        ref={this.frame}
        name={url}
        sandbox="allow-same-origin allow-scripts"
        referrerPolicy="same-origin"
        onError={this.handleError}
      />
    );
  }

  private renderSimulation(size: ISize, opacity: number, url: string) {
    const { isPreview, comp } = this.props;
    const { illegal, inQqDocs } = this.state;
    return (
      <div className={classnames('lib-comp-frame', { preview: isPreview })} style={{ ...size, opacity }}>
        <div className="lib-comp-frame-title">
          <i className="lib-comp-frame-button" />
          <div className="lib-comp-frame-url">
            {isPreview && (this.isLoop(url) ? '' : url)}
            {!isPreview && (url || i18n('resource.componentsText.frameTips'))}
          </div>
        </div>
        <div className={classnames('lib-comp-frame-content', { illegal })}>
          {isPreview && inQqDocs && this.renderInQqDocs()}

          {!isPreview || (isPreview && !illegal && !inQqDocs && <img src={OtherRes.FramePlaceholderImage} />)}
          {isPreview && illegal && (
            <div className="illegal-content">
              <div>File Not Found</div>
              <div>{`url:/${comp.value}`}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
  /**
   * 腾讯文档中
   */
  renderInQqDocs() {
    return (
      <div className="in-qqdocs">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M24.987 21.4821L26.666 20.1231C30.77 16.7991 31.403 10.7781 28.08 6.67407C24.757 2.57007 18.735 1.93607 14.631 5.26007L13.021 6.56407L9.585 2.28007C9.4151 2.07811 9.17225 1.95151 8.90939 1.92785C8.64653 1.9042 8.38498 1.98542 8.18176 2.15381C7.97853 2.32219 7.85011 2.56409 7.82449 2.82677C7.79888 3.08944 7.87814 3.35159 8.045 3.55607L28.058 28.5061C28.2279 28.708 28.4707 28.8346 28.7336 28.8583C28.9965 28.8819 29.258 28.8007 29.4612 28.6323C29.6645 28.4639 29.7929 28.222 29.8185 27.9594C29.8441 27.6967 29.7649 27.4345 29.598 27.2301L24.987 21.4821ZM16.204 7.20307C16.9248 6.61941 17.7534 6.18343 18.6427 5.92002C19.5319 5.65661 20.4644 5.57093 21.3867 5.66788C22.3091 5.76482 23.2033 6.04249 24.0184 6.48503C24.8334 6.92757 25.5533 7.52631 26.137 8.24707C26.7207 8.96783 27.1566 9.79649 27.42 10.6857C27.6835 11.575 27.7691 12.5074 27.6722 13.4298C27.5752 14.3522 27.2976 15.2464 26.855 16.0614C26.4125 16.8765 25.8138 17.5964 25.093 18.1801L23.423 19.5321L19.713 14.9071L22.365 12.6981L20.764 10.7771L18.149 12.9571L14.585 8.51407L16.204 7.20307ZM15.762 25.7361C15.0412 26.3197 14.2126 26.7557 13.3233 27.0191C12.4341 27.2825 11.5016 27.3682 10.5793 27.2713C9.65691 27.1743 8.76268 26.8966 7.94762 26.4541C7.13257 26.0116 6.41266 25.4128 5.829 24.6921C5.24534 23.9713 4.80936 23.1426 4.54595 22.2534C4.28254 21.3641 4.19686 20.4317 4.29381 19.5093C4.39075 18.587 4.66842 17.6927 5.11096 16.8777C5.5535 16.0626 6.15224 15.3427 6.873 14.7591L10.341 11.9511L8.768 10.0081L5.3 12.8161C1.196 16.1391 0.562998 22.1611 3.886 26.2651C7.21 30.3691 13.231 31.0021 17.335 27.6791L20.62 25.0191L19.047 23.0761L15.762 25.7361Z"
            fill="#999999"
          />
          <path d="M13.96 16.4473L15.56 18.3673L11.627 21.6443L10.027 19.7243L13.96 16.4473Z" fill="#999999" />
        </svg>
        <span className="label">{i18n('resource.componentsText.frameInQqDocsTips')}</span>
      </div>
    );
  }

  render() {
    const { comp, isPreview } = this.props;
    const { size, value } = comp;
    const opacity = StyleHelper.getOpacity(comp.opacity);
    const _url = value as string;
    let src: string = _url;
    let isEmpty = false;
    if (_url.replace(/(http:\/\/)|(https:\/\/)/, '').length === 0) {
      isEmpty = true;
    }
    if (src && !src.startsWith('http://') && !src.startsWith('https://')) {
      src = `https://${src}`;
    }

    if (isPreview) {
      const { illegal, inQqDocs } = this.state;

      if (!isEmpty && !this.isLoop(_url) && !illegal && !inQqDocs) {
        return this.renderIFrame(size, opacity, src);
      } else {
        return this.renderSimulation(size, opacity, src);
      }
    } else {
      return this.renderSimulation(size, opacity, src);
    }
  }
}
