const FIELDS_POSITION = ['position', 'top', 'bottom', 'left', 'right', 'z-index'];
// fields += 'inset-block-start','inset-block-end','inset-inline-start','inset-inline-end','inline-size','';

const FIELDS_TRANSFORM = ['transform', 'transform-origin', 'transform-style'];

const FIELDS_LAYOUT = [
  'display',
  'justify-content',
  'align-items',
  'align-content',
  '-webkit-box-pack',
  'flex-wrap',
  'flex-direction',
  'flex-grow',
  'flex-shrink',
  'flex-basis',
  'row-gap',
  'column-gap',
];

const FIELDS_OVERFLOW = ['overflow-x', 'overflow-y'];

const FIELDS_PADDING = [
  'padding-top',
  'padding-right',
  'padding-bottom',
  'padding-left',
  'padding-inline-start',
  'padding-inline-end',
  'box-sizing',
];

const FIELDS_MARGIN = [
  'margin-top',
  'margin-right',
  'margin-bottom',
  'margin-left',
  'margin-inline-start',
  'margin-inline-end',
];

const FIELDS_SIZE = ['width', 'height'];

const FIELDS_POSITION_INFO = FIELDS_POSITION.concat(FIELDS_TRANSFORM)
  .concat(FIELDS_LAYOUT)
  .concat(FIELDS_OVERFLOW)
  .concat(FIELDS_PADDING)
  .concat(FIELDS_MARGIN)
  .concat(FIELDS_SIZE);

const FIELDS_BORDER_RADIUS = [
  'border-top-left-radius',
  'border-top-right-radius',
  'border-bottom-right-radius',
  'border-bottom-left-radius',
  'border-end-end-radius',
  'border-end-start-radius',
];
const FIELDS_BORDER_STYLE = ['border-top-style', 'border-right-style', 'border-bottom-style', 'border-left-style'];
const FIELDS_BORDER_WIDTH = ['border-top-width', 'border-right-width', 'border-bottom-width', 'border-left-width'];
const FIELDS_BORDER_COLOR = ['border-top-color', 'border-right-color', 'border-bottom-color', 'border-left-color'];
const FIELDS_BORDER_COLLAPS = ['border-collapse'];

const FIELDS_BORDER = FIELDS_BORDER_COLLAPS.concat(FIELDS_BORDER_COLOR)
  .concat(FIELDS_BORDER_WIDTH)
  .concat(FIELDS_BORDER_STYLE)
  .concat(FIELDS_BORDER_RADIUS);

const FIELDS_BACKGROUND = [
  'background-image',
  'background-position-x',
  'background-position-y',
  'background-size',
  'background-repeat-x',
  'background-repeat-y',
  'background-attachment',
  'background-origin',
  'background-clip',
  'background-color',
];

const FIELDS_FONT = ['font-size', 'line-height', 'font-family', 'white-space', 'letter-spacing', 'word-break', 'color'];

export const FIELDS_SHAPE_INFO = FIELDS_POSITION_INFO.concat(FIELDS_BORDER).concat(FIELDS_BACKGROUND);
export const FIELDS_TEXT_INFO = FIELDS_POSITION_INFO.concat(FIELDS_FONT);
