import * as React from 'react';

export interface ITextEditorProps {
  value: string;
  style: React.CSSProperties;
  className: string;
  onChange: (value: string) => void;
}

export interface ITextEditorState {
  value: string;
}

/**
 * 所有文本编辑器的基类组件，暂时这块没调整完全
 */
export default abstract class TextValueEditorBase<
  P extends ITextEditorProps,
  S extends ITextEditorState
> extends React.Component<P, S> {
  protected dom: React.RefObject<HTMLElement> = React.createRef();

  componentDidMount() {
    window.addEventListener('mousedown', this.handleWindowMouseDown);
    document.onvisibilitychange = this.handlePageVisibleChanged;
    this.afterCreate();
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleWindowMouseDown);
    document.onvisibilitychange = null;
  }

  get value(): string {
    return '';
  }

  handlePageVisibleChanged = () => {
    if (document.visibilityState === 'hidden') {
      this.doSubmit();
    }
  };

  protected abstract afterCreate(): void;

  private handleWindowMouseDown = (e: MouseEvent) => {
    const self = this.dom.current!;
    if (self.contains(e.target as HTMLElement)) {
      return;
    }
    let dom = document.querySelector('.right-panel');
    if (dom && dom.contains(e.target as HTMLElement)) {
      return;
    }
    dom = document.querySelector('.popup-with-body');
    if (dom && dom.contains(e.target as HTMLElement)) {
      return;
    }
    this.doSubmit();
  };

  protected doSubmit() {
    const { onChange } = this.props;
    const { value } = this.state;
    onChange && onChange(value);
  }
}
