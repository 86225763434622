import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { getShapeData, DefaultFlowShapeSize, flowBase, getShapePropertiesData } from './shapeDataFragment';

// 矩形
export default {
  ...flowBase,
  name: i18n('resource.flow.dataBase'),
  type: 'dataBase',
  thumb: {
    spriteIconClass: SpriteThumb.Database.className,
    dragPosition: SpriteThumb.Database.position,
  },
  template: getShapeData(i18n('resource.flow.dataBase'), DefaultFlowShapeSize.size60_80, {
    data: [
      {
        point: {
          x: 0,
          y: 12,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 0,
          y: 68,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 7.2,
        },
      },
      {
        point: {
          x: 30,
          y: 80,
        },
        handleIn: {
          x: -18,
          y: 0,
        },
        handleOut: {
          x: 18,
          y: 0,
        },
      },
      {
        point: {
          x: 60,
          y: 68,
        },
        handleIn: {
          x: 0,
          y: 7.2,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 60,
          y: 12,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: -7.2,
        },
      },
      {
        point: {
          x: 30,
          y: 0,
        },
        handleIn: {
          x: 18,
          y: 0,
        },
        handleOut: {
          x: -18,
          y: 0,
        },
      },
      {
        point: {
          x: 0,
          y: 12,
        },
        handleIn: {
          x: 0,
          y: -7.2,
        },
        handleOut: {
          x: 0,
          y: 7.2,
        },
      },
      {
        point: {
          x: 30,
          y: 24,
        },
        handleIn: {
          x: -18,
          y: 0,
        },
        handleOut: {
          x: 18,
          y: 0,
        },
      },
      {
        point: {
          x: 60,
          y: 12,
        },
        handleIn: {
          x: 0,
          y: 7.2,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
    ],
    closed: false,
  }),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: false }),
    };
  },
};
