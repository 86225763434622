import * as React from 'react';
import classnames from 'classnames';

import './Radio.scss';

export interface IRadioProp {
  checked: boolean;
  text?: string;
  disabled?: boolean;
  them?: 'light' | 'dark';

  onClick?(): void;
}

// eslint-disable-next-line react/prop-types
const Radio: React.FC<IRadioProp> = ({ checked, text, onClick, disabled, them }) => (
  <div
    className={classnames('dsm-c-rp-radio', `them-${them ?? 'light'}`, {
      'c-radio-disabled': disabled,
    })}
    onClick={disabled ? undefined : onClick}
  >
    <span
      className={classnames('radio-viewer', {
        'radio-checked': checked,
      })}
    />
    {text && <span className="radio-text">{text}</span>}
  </div>
);

export default Radio;
