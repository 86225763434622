import Doc from '@/editor/document';
import { UIFragment, UIArtboard } from '@/editor/comps';
import IArtboard from '@/fbs/rp/models/artboard';
import { MobileType } from '@/fbs/rp/utils/preview';
import { IAppWithNestedChildren } from '@/fbs/rp/models/app';
import { getVisibleComponents, updateContentPanel } from '../util/artboardUtil';
import { renderArtboardToHtml } from '../render/renderArtboard';
import { useShell, getAdptiveShellSize } from '../util/shellUtil';
import { IExportData } from '../type';

export const parseArtboard = async (
  fragments: UIFragment[],
  doc: Doc,
  app?: IAppWithNestedChildren,
  mobileType?: MobileType,
): Promise<IExportData[]> => {
  const processor = fragments.map(async (t) => {
    const position = { x: 0, y: 0 };
    const json = t.toJSON() as IArtboard;
    const components = getVisibleComponents(json);
    const data = Object.assign({}, json, { position, name: json.name ?? '', components });
    const newArtboard = new UIArtboard(data, doc);
    // 内容面板数据
    doc && updateContentPanel(newArtboard, doc);
    window.debug && console.log(newArtboard);
    const html = await renderArtboardToHtml(newArtboard, doc, app, mobileType);

    // 手机壳大小
    const size = useShell(mobileType)
      ? getAdptiveShellSize({
          app,
          mobileType,
          artboard: newArtboard,
        }) ?? t.size
      : t.size;

    return {
      html,
      type: t.type,
      size,
    };
  });
  const result = await Promise.all(processor);
  return result;
};
