import { ThunkDispatch } from 'redux-thunk';

import INode from '@fbs/rp/models/node';
import { IPageAction, IFragmentAction } from '@fbs/rp/models/interactions';
import IArtboard from '@/fbs/rp/models/artboard';

import { ThunkActions } from '../store/preview/actions';
import { Actions as GlobalActions } from '../store/global/actions';
import { ThunkActions as ThunkPreferenceActions } from '../store/preference/actions';
import { ToastType } from '../store/types';
// import snapshotManager from '@/managers/snapshotManager';

export interface IPreviewDispatcher {
  loadApp: (openPage?: string) => void;
  loadPage: (node: INode) => void;
  selectPage: (nodeID: string) => void;
  exit: () => void;
  afterSkip: () => void;
  beforeSkipToPage: (nextPage: INode, currentPage: INode, actionEffect: IPageAction) => void;
  goBackward: (actionEffect: IPageAction | undefined) => void;
  goForward: () => void;
  goHome: (homeID: string, artboardID: string) => void;
  showFragment: (action: IFragmentAction) => void;
  showMessage: (message: string, time?: number) => void;
  showMessageWithId: (message: string, time: number, id: number) => void;
  checkWaterMark: () => void;
  checkPersonalSpaceUserPreference: () => void;
  loadPopularizes(): void;
  closeToast: (type: ToastType) => void;
}

export const previewStoreDispatch: {
  dispatch?: ThunkDispatch<any, null, any>;
  pushArtboardsReload: (artboards: IArtboard[]) => void;
} = {
  // 重刷theater实例
  pushArtboardsReload: (artboards: IArtboard[]) => {
    previewStoreDispatch.dispatch && ThunkActions.pushArtboardsReload(artboards)(previewStoreDispatch.dispatch);
  },
};

export default function (
  shareID: string,
  prototypeID: string | undefined,
  iDocAppID: string | undefined,
  linkId: string | undefined,
  dispatch: ThunkDispatch<any, null, any>,
): IPreviewDispatcher {
  previewStoreDispatch.dispatch = dispatch;
  return {
    // 载入项目数据
    loadApp: (openPage?: string) => {
      dispatch(ThunkActions.getApp(shareID, prototypeID, iDocAppID, openPage, linkId));
    },
    // 载入单个页面数据
    loadPage: (node: INode) => {
      dispatch(ThunkActions.loadPage(node, prototypeID));
    },
    // 选中项目的页面节点
    selectPage: (nodeID: string) => {
      dispatch(ThunkActions.selectPageNode(nodeID, prototypeID));
    },

    exit: () => {
      dispatch(ThunkActions.exit());
    },
    afterSkip: () => {
      dispatch(ThunkActions.afterPageSkip());
    },
    beforeSkipToPage: (nextPage, currentPage, actionEffect) => {
      dispatch(ThunkActions.beforeSkipToPage(nextPage, currentPage, actionEffect));
    },
    goBackward: (actionEffect) => {
      dispatch(ThunkActions.goBackward(actionEffect));
    },
    goForward: () => {
      dispatch(ThunkActions.goForward());
    },
    goHome: (homeID: string) => {
      dispatch(ThunkActions.goHome(homeID, prototypeID));
    },
    showFragment: (action: IFragmentAction) => {
      dispatch(ThunkActions.showFragment(action));
    },

    showMessage: (message: string, time = 1000) => {
      dispatch(ThunkActions.showMessage(message, time));
    },

    showMessageWithId: (message: string, time: number, id: number) => {
      dispatch(ThunkActions.showMessageWithId(message, time, id));
    },

    checkWaterMark: () => {
      dispatch(ThunkActions.checkWaterMark());
    },
    // 英文版个人空间邀请链接进入RP设置个人偏好
    checkPersonalSpaceUserPreference: () => {
      dispatch(ThunkPreferenceActions.thunkCheckPersonalSpaceUserPreference());
    },
    loadPopularizes(): void {
      dispatch(ThunkActions.getPopularizes());
    },
    closeToast: (type: ToastType) => {
      dispatch(GlobalActions.closeToast(type));
    },
  };
}
