import * as React from 'react';

import { ISize } from '@utils/boundsUtils';
import Theater from '../../../../theater';

import PageShell from './PageShell';
import { Orientation } from '@fbs/common/models/common';

import './index.scss';

export interface IMobileViewPortProp {
  page?: Theater;
  advancePage?: Theater;
  appSize: ISize;
  orientation?: Orientation;
}

// export interface IMobileViewPortState {
//   isLandscape: boolean;
// }

class MobileViewPort extends React.Component<IMobileViewPortProp> {
  static defaultProps: Partial<IMobileViewPortProp> = {};

  selfRef: React.RefObject<HTMLDivElement>;
  private pageShell: React.RefObject<PageShell>;

  private timeOutEvent: Timeout = 0;

  constructor(props: IMobileViewPortProp) {
    super(props);
    // this.state = {
    //   isLandscape: false,
    // };
    this.selfRef = React.createRef();
    this.pageShell = React.createRef();
  }

  /**
   * 获取整体缩放
   */
  get globalScale() {
    return this.pageShell.current?.globalScale || 1;
  }

  handleTouchStart = () => {
    this.timeOutEvent = window.setTimeout(this.onMobileToggleTree, 500);
    return false;
  };

  onMobileToggleTree = () => {
    if (window.parent) {
      window.parent.postMessage(
        {
          toggleTree: true,
        },
        '*',
      );
    }
  };

  handleTouchMove = () => {
    clearTimeout(this.timeOutEvent);
    return false;
  };

  handleTouchEnd = () => {
    clearTimeout(this.timeOutEvent);
    this.timeOutEvent = 0;
  };

  handleEndFragment = (e: React.MouseEvent) => {
    e.stopPropagation();
    const el = e.target as HTMLElement;
    const { page } = this.props;
    if (
      el.parentElement &&
      el.parentElement.classList.contains('scroll-box') &&
      page &&
      page.activeFragmentAction &&
      !page.activeFragmentAction.isExit &&
      page.onEndFragmentAction
    ) {
      page.onEndFragmentAction(page.activeFragmentAction);
    }
  };

  render() {
    const { page, advancePage, appSize, orientation } = this.props;
    const viewPortSize = {
      height: window.innerHeight,
      width: window.innerWidth,
    };
    return (
      <div
        className="preview-mobile-view-port"
        onClick={this.handleEndFragment}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
      >
        {page && (
          <PageShell
            ref={this.pageShell}
            viewPortSize={viewPortSize}
            appSize={appSize}
            page={page}
            pageType="current"
            orientation={orientation}
          />
        )}
        {advancePage && (
          <PageShell
            viewPortSize={viewPortSize}
            appSize={appSize}
            page={advancePage}
            orientation={orientation}
            pageType="next"
          />
        )}
      </div>
    );
  }
}

export default MobileViewPort;
