import PropertyBase from './base';
import { Color } from './color';

/**
 * 拐角类型
 */
export enum StrokeLineJoin {
  Miter = 'miter',// 尖角
  Round = 'round',// 圆角
  Bevel = 'bevel', // 斜角
}

/**
 * 端点类型
 */
export enum StrokeLineCap {
  Butt = 'butt',// 平头
  Round = 'round',//圆头
  Square = 'square',//方头
}

export enum PresetDashModel {
  solid = 'solid',
  longDash = 'long-dash',
  middleDash = 'middle-dash',
  shortDash = 'short-dash',
  dashDot = 'dash-dot',
  dashDotDot = 'dash-dot-dot',
  longDashDot = 'long-dash-dot',
  dot = 'dot',
  unset = 'unset',
}

/**
 * 描边位置，仅限于封闭的路径
 */
export enum StrokePosition {
  inner = 'inner',//内描边
  center = 'center',// 居中描边
  outer = 'outer',// 外描边
}

export default interface IStroke extends PropertyBase {
  color?: Color;
  thickness?: number;
  dashArray?: number[];
  cap?: StrokeLineCap;
  join?: StrokeLineJoin;
  position?: StrokePosition;
  dashModel?: PresetDashModel;
  mode?: 'preset' | 'custom'
}

export const StrokePropertyName = 'stroke';

export const SeparatorPropertyName = 'separator';
