import { compressHtml, stripInvalidChar, compressStyle } from './compressHtml';
import { getClassNameFromRp, getComponentStyle, abbrClassName, abbrStyleName } from './htmlStyle';
import { handleComponentImage } from './componentImage';
import { getStyleWebFonts } from './webfont';
import { IHtmlContent, ICompInfo } from '../type';
import { replaceRealChartDom } from './componentMapChart';

interface IAssembleResourceOptions {
  compressStyle?: boolean;
  abbrStyleName?: boolean;
  compInfo?: ICompInfo;
}
/**
 * 装配资源，
 * 图片，字体
 * 简化，去特殊字符
 * @param html
 */
export const assembleResource = async (html: string, options: IAssembleResourceOptions): Promise<IHtmlContent> => {
  // 简化
  html = compressHtml(html);
  // 去掉xml无效字符
  html = stripInvalidChar(html);
  const classNames = getClassNameFromRp(html);
  let style = getComponentStyle(html, classNames);
  if (options.compressStyle) {
    style = compressStyle(style);
  }

  // css名称缩写
  if (options.abbrStyleName) {
    html = abbrClassName(html, classNames);
    style = abbrStyleName(style, classNames);
  }

  // 地图组件
  let content = await replaceRealChartDom(html, options.compInfo);
  // 图片
  content = await handleComponentImage(content, options.compInfo);
  // 字体
  const webfont = await getStyleWebFonts(style + html);
  // 地图组件

  return {
    style,
    content,
    webfont,
  };
};
