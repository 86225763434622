import * as React from 'react';

import ScrollView from '../../../ScrollView';
import CheckItem from '../CheckItem';
import Icon from '../../../Icon';

import { IItemProps } from '../../../../models';
import i18n from '../../../../i18n';

import './index.scss';

interface ISearchListProps {
  items: IItemProps[];
  checkedItems: string[];
  checkedGroups: string[];
  searchKey: string;
  enableEmailSearch?: boolean;
  enableThirdPartyUUIDSearch?: boolean;
  hideGroupCheck: boolean;
  maxCount: number;

  onCheckItem?(ids: string[]): void;
  onCheckGroup?(ids: string[]): void;
  onCheckDisable?(id: string): void;
}

const SearchList: React.FC<ISearchListProps> = (props: ISearchListProps) => {
  const {
    items,
    checkedItems,
    checkedGroups,
    searchKey,
    enableEmailSearch,
    enableThirdPartyUUIDSearch,
    hideGroupCheck,
    maxCount,
    onCheckItem,
    onCheckGroup,
    onCheckDisable,
  } = props;

  const [showTips, setShowTips] = React.useState(false);

  const handleCheckGroup = (id: string) => {
    if (checkedGroups.includes(id)) {
      onCheckGroup && onCheckGroup(checkedGroups.filter((item) => item !== id));
    } else {
      onCheckGroup && onCheckGroup([...checkedGroups, id]);
    }
  };

  const handleCheckItem = (id: string) => {
    if (checkedItems.includes(id)) {
      onCheckItem && onCheckItem(checkedItems.filter((item) => item != id));
    } else {
      onCheckItem && onCheckItem([...checkedItems, id]);
    }
  };

  const handleCheck = (item: IItemProps, id: string) => {
    if (item.isGroup) {
      handleCheckGroup(id);
      return;
    }
    if (checkedItems.includes(id)) {
      handleCheckItem(id);
      return;
    }
    if (hideGroupCheck && checkedItems.length + checkedGroups.length >= maxCount) {
      setShowTips(true);
      setTimeout(() => {
        setShowTips(false);
      }, 2000);
      return;
    }
    handleCheckItem(id);
  };

  const getItemChecked = (data: IItemProps): boolean => {
    if (data.isGroup) {
      return checkedGroups.includes(data.id);
    }
    return checkedItems.includes(data.id);
  };

  const getSearchResult = () => {
    const lowerSearchKey = searchKey.trim().toLocaleLowerCase();
    return items.filter((item: IItemProps) => {
      const isNameMatch =
        (item.name && item.name.toLocaleLowerCase().includes(lowerSearchKey)) ||
        (item.pinyinName && item.pinyinName.toLocaleLowerCase().includes(lowerSearchKey));
      const isEmailMatch = item.email && item.email.toLocaleLowerCase().includes(lowerSearchKey);
      if (enableEmailSearch) {
        return isNameMatch || isEmailMatch;
      }

      if (enableThirdPartyUUIDSearch) {
        const isUUIDMatch = item.thirdPartyUUID && item.thirdPartyUUID.toLocaleLowerCase().includes(lowerSearchKey);
        return isNameMatch || isUUIDMatch;
      }
      return isNameMatch;
    });
  };

  const searchResult = getSearchResult();

  const renderSearchList = () => {
    return (
      <ScrollView height={'calc(100% - 74px'}>
        {searchResult.map((item: IItemProps) => (
          <CheckItem
            data={item}
            showEmail={!!searchKey && enableEmailSearch}
            showThirdPartyUUID={!!searchKey && enableThirdPartyUUIDSearch}
            checked={getItemChecked(item)}
            onCheck={(id: string) => handleCheck(item, id)}
            onCheckDisable={onCheckDisable}
          />
        ))}
      </ScrollView>
    );
  };

  const renderReachMaxTips = () => {
    return (
      <div className={'reach-max-tip'}>
        <Icon cls="tag_exclamation" color="#F8AF1D" size={16} />
        <span>{i18n('checkSelect.maximumOfPeople')}</span>
      </div>
    );
  };

  return (
    <div className="search-wrap">
      {searchResult.length > 0 ? (
        renderSearchList()
      ) : (
        <div className="no-result">{i18n('checkSelect.hasNotFound')}</div>
      )}

      {showTips && renderReachMaxTips()}
    </div>
  );
};

SearchList.defaultProps = {
  enableEmailSearch: true,
};
export default SearchList;
