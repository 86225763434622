import { round } from '@utils/globalUtils';

export class RPPoint {
  private _x: number;
  private _y: number;
  static defaultX: 0;
  static defaultY: 0;
  constructor(x: number, y: number) {
    this._x = x;
    this._y = y;
  }
  static getVector(start: RPPoint, end: RPPoint) {
    return {
      x: end.x - start.x,
      y: end.y - start.y,
    };
  }
  static scaleVector(vector: { x: number; y: number }, scale: number) {
    return {
      x: round(vector.x * scale),
      y: round(vector.y * scale),
    };
  }
  get x() {
    return this._x;
  }
  get y() {
    return this._y;
  }
  round() {
    return Point({
      x: round(this._x),
      y: round(this._y),
    });
  }
}

export function Point(point: { x: number; y: number }) {
  return new RPPoint(point.x, point.y);
}
