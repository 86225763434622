import * as React from 'react';
import { Link } from 'react-router-dom';

import Icon from '../Icon';

import './index.scss';

interface IIconLinkProps {
  to: string;
  className?: string;
  cls: string;
  tips?: string;
}

class IconLink extends React.Component<IIconLinkProps> {

  render() {
    const { className, cls, to, tips } = this.props;
    return (
      <Link className={`dsm-c-rp-icon-link ${className || ''}`} to={to}>
        <Icon className="c-icon" tips={tips} cls={cls}/>
      </Link>
    );
  }
}

export default IconLink;