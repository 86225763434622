import { notSameNumber } from '@utils/globalUtils';
import { roundNumberObject } from '@utils/globalUtils';

import { ArtboardOperations, ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { HorizontalAlign } from '@fbs/rp/models/layout';
import { IPosition, ISize } from '@/fbs/common/models/common';
import { EventTypes } from '@fbs/rp/models/event';

import { coverPatches } from '@helpers/patchHelper';
import { getCenter, getNWPoint } from '@/helpers/rotateHelper';
import i18n from '@i18n';

import { UIComponent, UIContainerComponent } from '@editor/comps';
import { ArtboardPatchesClass } from '@editor/patches/artboardPatches';

import { PredefinedStates } from '@consts/state';
import ValueEditorType from '@consts/enums/valueEditorType';
import { SpriteThumb } from '@consts/spriteIcons';
import { getDefaultDataFromTemplate } from '@/libs/helper';

import { IComponentItem } from '../../types';
import { CEllipse } from '../../constants';
import { MoveMode } from '../../enum';

/**
 * 演示时状态自触发
 * @param {UIComponent} comp
 * @param {UIComponent} triggerComp
 * @param {string} stateName
 * @returns {ArtboardOperations | null}
 */
function onTriggerState(comp: UIComponent, triggerComp: UIComponent, stateName: string): ArtboardOperations | null {
  if (stateName === 'checked' || stateName === 'unchecked') {
    const group = (comp as unknown) as UIContainerComponent;
    const button = group.getComponentByAlias('button', true) || group.components[1];
    const options: ArtboardOperations = {};
    const { position, size: btnSize } = button;
    const { size } = comp;
    const newPosition = { ...position };
    if (stateName === 'checked') {
      newPosition.x = size.width - position.x - btnSize.width;
    } else {
      newPosition.x = size.width - (position.x + btnSize.width);
    }
    options[button.id] = [Ops.replace('/position', roundNumberObject(newPosition))];
    return options;
  }
  return null;
}

function onChildSelectedChange(group: UIComponent, comp: UIComponent, selected: boolean) {
  const patches: ArtboardPatches = { do: {}, undo: {} };
  ((group as unknown) as UIContainerComponent).components
    .filter((comp) => comp.type === 'ellipse')
    .forEach((ellipseComp) => {
      //根据selected的状态，来决定开关在组件的左边，还是右边
      const paddingLeftOrRight = 2;
      const rightPositionX = group.size.width - ellipseComp.size.width - paddingLeftOrRight;
      const newPositionX = selected ? rightPositionX : paddingLeftOrRight;
      const positionPatches = ellipseComp.getPositionPatches({ ...ellipseComp.position, x: newPositionX });
      coverPatches(patches, positionPatches);

      // 根据开关组件打开还是关闭，确定开关组件的layout（关闭-》左锚定，打开-》右锚定）
      const newHorizontalLayout = selected ? HorizontalAlign.Right : HorizontalAlign.Left;
      const layoutPatches = ellipseComp.getLayoutChangePatches({
        ...ellipseComp.layout,
        horizontal: newHorizontalLayout,
      });
      coverPatches(patches, layoutPatches);
    });
  return patches;
}

function onResize(container: UIComponent, newSize: ISize) {
  const patch: ArtboardPatches = {
    do: {},
    undo: {},
  };
  const group = container as UIContainerComponent;
  const circle = group.getSuchChild((comp: UIComponent) => {
    return comp.type === CEllipse;
  })[0];
  const id = circle.id;
  if (circle) {
    const isHeightChange = notSameNumber(newSize.height, container.size.height);
    const isWidthChange = notSameNumber(newSize.width, container.size.width);
    if (isWidthChange && !isHeightChange) {
      // 如果仅改变宽度，圆的大小不发生变化
      patch.do[id] = [];
      patch.undo[id] = [];
    }
    const size = circle.size;
    const widthHeightRatio = size.width / size.height;
    const circleNewHeight = size.height * (newSize.height / container.size.height);
    const circleNewWidth = circleNewHeight * widthHeightRatio;
    const circleNewSize = { height: circleNewHeight, width: circleNewWidth };
    if (container.selected) {
      let circleNewPosition: IPosition | undefined;

      if (isWidthChange && !isHeightChange) {
        circleNewPosition = { ...circle.position, x: circle.position.x + newSize.width - container.size.width };
      } else if (isHeightChange) {
        const originRight = container.size.width - circle.position.x - circle.size.width;
        const newPositionX = newSize.width - circleNewWidth - originRight;
        circleNewPosition = { x: newPositionX, y: circle.position.y };
      }

      if (circleNewPosition) {
        coverPatches(
          patch,
          new ArtboardPatchesClass().getAttrChangePatches(circle.id, circle.getCurrentPositionPath(), {
            oldVal: circle.position,
            newVal: circleNewPosition,
          }),
        );
      }
    } else {
      if (isWidthChange && isHeightChange) {
        //如果宽高同时改变的话，且不是按照原来的宽高比变化的，那么最终只响应高的变化
        const path = circle.getCurrentSizePath();
        const parentCenter = getCenter({ x: 0, y: 0 }, newSize);
        patch.do[id] = [Ops.replace(`${path}`, { ...circle.size, ...circleNewSize })];
        patch.undo[id] = [Ops.replace(`${path}`, size)];
        // 调整位置
        const myPosition = getNWPoint(parentCenter, circleNewSize, 0);
        const newPosition = { ...circle.position, y: myPosition.y };
        coverPatches(patch, circle.getPositionPatches(newPosition));
      }
    }
    coverPatches(
      patch,
      new ArtboardPatchesClass().getAttrChangePatches(circle.id, circle.getCurrentSizePath(), {
        oldVal: circle.size,
        newVal: circleNewSize,
      }),
    );
  }

  return patch;
}

export const SwitchCfg: IComponentItem = {
  type: 'switch',
  name: i18n('resource.components.switch'),
  thumb: {
    spriteIconClass: SpriteThumb.Switch.className,
    dragPosition: SpriteThumb.Switch.position,
  },
  predefinedStates: [PredefinedStates.checked, PredefinedStates.disabled],
  value: {
    type: ValueEditorType.Bool,
    getValue: (comp: UIComponent): boolean => {
      return comp.selected;
    },
    setValue: (comp: UIComponent, selected: boolean): ArtboardPatches => {
      const doOps = [];
      const undoOps = [];
      // FIXME: 没有处理选中状态跟其它状态的冲突问题，而且这里是否需要处理，还要仔细考虑
      if (selected) {
        doOps.push(Ops.replace('/selected', true));
        undoOps.push(Ops.replace('/selected', false));
      } else {
        doOps.push(Ops.replace('/selected', false));
        undoOps.push(Ops.replace('/selected', true));
      }

      return {
        do: {
          [comp.id]: doOps,
        },
        undo: {
          [comp.id]: undoOps,
        },
      };
    },
  },
  constraint: {
    roundedRect: {
      move: MoveMode.neither,
    },
  },
  template: `
  {
    type: @@C.CanvasPanel,
    size: { width: 50, height: 30,lockedRatio:false },
    layout:{
      vertical: 'middle',
      horizontal: 'left',
      fixedWidth: true,
      fixedHeight: true,
      auto: true,
      responsive: true,
    },
    properties: {
      background: {
        prop:'fill',
        name: '${i18n('property.propertyNames.bgcolor')}',
        type: @@FillType.solid,
        color: @@SystemColors.DisabledDarkFillColor,
        disabled: false,
      },
      buttonColor: {
        name: '${i18n('property.propertyNames.buttonColor')}',
        prop: 'fill',
        type: @@FillType.solid,
        color: @@SystemColors.WhiteColor,
        disabled: false,
      },
      radius: {
        prop: 'radius',
        topLeft: 100,
        topRight: 100,
        bottomRight: 100,
        bottomLeft: 100,
        isPercent: true,
        disabled: false,
        hidden:true,
      },
      backgroundStroke: {
        prop:'stroke',
        thickness: 1,
        color: @@SystemColors.BlackColor,
        disabled: true,
      },
      shadow:{
        disabled: true,
        prop:'shadow',
        x: 0,
        y: 3,
        blur: 6,
        color: @@SystemColors.ShadowColor,
      },
    },
    value: '',
    components: [
      { 
        type: @@C.Rect,
        alias:'roundedRect',
        size:{width: 50, height: 30},
        layout:{
          horizontal: @@HorizontalAlign.LeftAndRight,
          vertical: @@VerticalAlign.TopAndBottom,
          fixedWidth: false,
          fixedHeight: false,
          auto: true,
          responsive: true,
        },
        properties: {
          radius: { ref: '@properties.radius' },
          fill: { ref: '@properties.background', },
          stroke: { ref: '@properties.backgroundStroke' },
        },
      },
      {
        type: @@C.Ellipse,
        alias: 'button',
        position:{
          x: 2,
          y:2,
        },
        layout:{
          horizontal: @@HorizontalAlign.Left,
          vertical: @@VerticalAlign.Middle,
          fixedWidth: false,
          fixedHeight: false,
          auto: false,
          responsive: true,
        },
        size: { width:26, height: 26, lockedRatio: true },
        properties: {
          fill: { ref: '@properties.buttonColor' },
          stroke: { ref: '@properties.backgroundStroke' },
        },
      },
    ],
    states: {
      checked: {
        enabled: false,
        name: '${i18n('property.propertyNames.checked')}',
        properties: {
          background: {
            props: 'fill',
            type: @@FillType.solid,
            color: '#009DFF',
          },
        },
      },
    },
    select: {
      target: 'self',
      enabled: true,
      reversible: true,
    },
    selected: false,
    sealed: true,
  }
  `,
  editor: {
    onChildSelectedChange,
    onResize,
  },
  preview: {
    onTriggerState,
  },
  interactions: {
    transformEventName: (eventName: string) => {
      if (eventName === EventTypes.checked) {
        return i18n('property.interaction.triggerconditions.on');
      } else if (eventName === EventTypes.unChecked) {
        return i18n('property.interaction.triggerconditions.off');
      } else if (eventName === EventTypes.toggleCheck) {
        return i18n('property.interaction.triggerconditions.toggle');
      }
      return '';
    },
    sort: (events) => {
      const temp: { id: string; text: string }[] = [];

      const result = events.filter((item) => {
        const type = item.id as EventTypes;
        if ([EventTypes.checked, EventTypes.unChecked, EventTypes.toggleCheck].includes(type)) {
          temp.push(item);
          return false;
        }
        return true;
      });
      result.unshift(...temp);
      return result;
    },
    defaultEvent: EventTypes.checked,
  },
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
};

const defaultData = getDefaultDataFromTemplate(SwitchCfg);
