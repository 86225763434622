import * as React from 'react';
import classNames from 'classnames';

import { getShortCutKey } from '@/helpers/shortCutHelper';
import { TabDefaultValue } from '@/consts/defaultData/input';

import { IRemark } from '@fbs/rp/models/component';
import { UIComponent } from '@/editor/comps';
import { Icon, Input, TextArea } from '@/dsm';

import i18n from '@/i18n';

import './index.scss';

interface IRemarkItem {
  targetName: string;
  targetId: string;
  content?: IRemark;
  showMultiRemarks?: boolean;
  isPreview?: boolean;
  isOnlyArtboardRemark?: boolean;
  onChange?: (value?: string, target?: UIComponent) => void;
  onRename?: (value: string) => void;
  onClick?: () => void;
}

const RemarkItem: React.FC<IRemarkItem> = (props: IRemarkItem) => {
  const {
    content,
    targetName,
    targetId,
    showMultiRemarks,
    isPreview,
    isOnlyArtboardRemark,
    onChange,
    onRename,
    onClick,
  } = props;

  const [focused, setFocused] = React.useState(false);

  // 兼容旧数据问题
  // const newContent: IRemarkInfo = typeof content === 'string' ? { value: content, time: 0 } : content || { value: '', time: 0 };

  const handleTextAreaBlur = React.useCallback(
    (e: any) => {
      const value = e.target.value;
      // 内容没有发生变化则无需更新
      if (content?.value === value) {
        return false;
      }
      setFocused(false);
      onChange && onChange(value);
    },
    [targetId, onChange],
  );

  const handleInputBLur = React.useCallback(
    (value: string) => {
      setFocused(false);
      if (targetName === value) {
        return;
      }
      onRename && onRename(value);
    },
    [onRename],
  );

  const handleInputFocus = React.useCallback(() => {
    setFocused(true);
    onClick?.();
  }, []);

  const handleClear = React.useCallback(() => {
    setFocused(false);
    onChange && onChange(undefined);
  }, [onChange, targetId]);

  const isArtboard = targetId === 'ROOT';
  return (
    <div
      className={classNames({
        'remark-item': !isPreview,
        'remark-item-preview': isPreview,
      })}
    >
      <div className={classNames('remark-item-title', { focus: focused })}>
        {isPreview || isArtboard ? (
          <p
            className={classNames([
              'name',
              'page-name',
              {
                'page-name-preview': isPreview && isArtboard,
              },
            ])}
          >
            {isArtboard ? i18n('property.remark.pageTitle') : targetName}
          </p>
        ) : (
          <Input
            className="name"
            value={isArtboard ? i18n('property.remark.pageTitle') : targetName}
            onBlur={handleInputBLur}
            onFocus={handleInputFocus}
            autoSelectWhenFocus={true}
          />
        )}
        {!focused && content && content.value && !isPreview && (
          <Icon
            cls="icon_remarks_cempty_RP"
            className="remark-del-button"
            onClick={handleClear}
            tips={`${i18n('property.remark.clear')} (${getShortCutKey('delete')})`}
          />
        )}
      </div>
      {isPreview ? (
        <p className="panel-text">{content ? content.value : ''}</p>
      ) : (
        <TextArea
          className="text"
          style={{
            lineHeight: '20px',
          }}
          tabValue={TabDefaultValue}
          value={content ? content.value : ''}
          height={isOnlyArtboardRemark ? 500 : showMultiRemarks ? 96 : 500}
          onBlur={handleTextAreaBlur}
        />
      )}
    </div>
  );
};

RemarkItem.defaultProps = {
  showMultiRemarks: false,
  isPreview: false,
};

export default React.memo(RemarkItem);
