import * as React from 'react';
import classnames from 'classnames';

import Icon from '../Icon';

import './index.scss';

interface IIconLabelProps {
  icon: any;
  text: string;
  disabled?: boolean;
  onClick: () => void;
}


class IconLabel extends React.Component<IIconLabelProps> {

  render() {
    const { icon, text, disabled, onClick } = this.props;
    return (
      <div
        className={classnames('dsm-c-rp-icon-label', { disabled })}
        onClick={onClick}
      >
        <Icon className="icon" size={16} cls={icon}/>
        <span>{text}</span>
      </div>
    );
  }
}

export default IconLabel;