import * as _ from 'lodash';

import { Ops, PageOperations } from '@fbs/rp/utils/patch';
import { EventTypes } from '@fbs/rp/models/event';

import Doc from '@editor/document';
import { UIComponent } from '@editor/comps';

import { PredefinedStates } from '@consts/state';

import { ICommandArguments, IWorker, IComponentCommand, CommandFinishEventHandle, IWorkerManager } from '../types';

export default abstract class CommandBase implements IComponentCommand {
  onFinish?: CommandFinishEventHandle;
  public readonly _worker: IWorker;
  protected command: ICommandArguments;
  protected originParams: { [key: string]: any } = {};
  protected doc: Doc;
  private delayTime?: Timeout;

  private _isTermination = false;
  /**
   * 是否成功执行（可触发xx后）
   */
  protected fulfilled: boolean = false;
  workManager?: IWorkerManager;

  constructor(worker: IWorker, command: ICommandArguments) {
    this._worker = worker;
    this.doc = worker.doc;
    this.command = command;
  }

  protected get isTermination() {
    return this._isTermination;
  }

  protected getCacheOriginParams = (key: string): any => {
    return this.originParams[key];
  };

  termination() {
    if (this.delayTime) {
      window.clearTimeout(this.delayTime);
    }
    this._isTermination = true;
  }

  protected saveCacheOriginParams = (key: string, value: any) => {
    this.originParams[key] = value;
  };

  protected initAnimationOperation = () => {
    const { animate } = this.command;

    return Ops.replace('./_animation', {
      timing: animate.effect,
      delay: animate.delay,
      duration: animate.duration,
      animationIterationCount: animate.loop ? 'infinite' : 1,
    });
  };

  public refreshSelf = () => {
    const docTree = this._worker.docTree;
    const target = docTree.getElementInNodeValue(this.command.target.id);
    target && (this.command.target = target);
  };

  protected abstract cashOriginParams(): void;

  protected abstract run(): void;
  protected abstract revert(): void;

  protected abstract revert(): void;

  protected delay = (fn: Function, timeOut: number) => {
    this.delayTime = window.setTimeout(() => {
      fn();
    }, timeOut);
  };

  protected cleanAnimation = () => {
    const {
      target: { id, ownerArtboardID, _animation },
    } = this.command;
    if (_animation) {
      this.patch({
        [ownerArtboardID]: {
          [id]: [Ops.replace('/_animation', undefined)],
        },
      });
    }
  };

  protected finish = () => {
    this.cleanAnimation();
    this.onFinish && this.onFinish(this);
  };

  protected get canRevert() {
    return !this.command.params.disabledAutoRevert;
  }

  protected patch = (operation: PageOperations) => {
    this.workManager?.patch(operation);
  };

  execute(revert?: boolean) {
    if ((revert && !this.canRevert) || this._isTermination) {
      return;
    }
    !revert && this.cashOriginParams();
    const fn = (revert ? this.revert : this.run).bind(this);
    this.delay(() => {
      fn();
      !this._isTermination && this.delay(this.finish, this.command.animate.duration);
    }, this.command.animate.delay || 0);
  }

  startSubWorker() {
    if (
      !this.afterEvent ||
      !this.afterTrigger ||
      !this.workManager?.startWorker ||
      !this.fulfilled ||
      this._isTermination
    ) {
      return;
    }
    this.fulfilled = false;
    this.workManager?.startWorker(this.afterTrigger, this.afterEvent);
  }

  get afterTrigger(): UIComponent | null {
    return this.command.target;
  }

  /**
   * @param {string} 'a/b/c'
   */
  protected getCurrentPath(key: string) {
    const comp = this.command.target;
    const { currentStateID } = comp;
    const stateData = comp.states[currentStateID ?? ''];
    if (!currentStateID || currentStateID === PredefinedStates.normal || !stateData) {
      return `/${key}`;
    }
    const pathArray = key.split('/');
    let flag = !_.isUndefined(stateData);
    let data: { [key: string]: any } = stateData;
    for (let i = 0; i < pathArray.length; i++) {
      if (!flag) {
        break;
      }
      if (!_.isObject(data)) {
        flag = false;
        break;
      }
      // @ts-ignore
      data = data[pathArray[i]];
      if (_.isUndefined(data)) {
        flag = false;
        break;
      }
    }
    return flag ? `/states/${currentStateID}/${key}` : `/${key}`;
  }

  public get afterEvent(): EventTypes | undefined {
    return undefined;
  }
}
