let id = 0;

let seed: string = getRandomSeed();
const timeSpan = Date.now().toString(36);
export function getRandomSeed(): string {
  return Math.round(Math.random() * 1000000).toString(36);
}

export function setSeed(newSeed: string) {
  seed = newSeed + '-' + timeSpan;
}

/**
 * 生成新 id
 * 分布式无冲突的 id 生成算法：
 *  seed: 是从服务器根据每个项目获取到的一个自增 id，
 *  每次开启一个项目新的编辑的时候候都会自增一次，
 *  本地维护一个自增的数字，每次需要 id 时自增
 */
export function getNewID() {
  id++;
  return `${seed}-${id.toString(32)}`;
}

export function GUID() {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
}

/**
 * 生成长度为6的随机字符串
 */
export function generateRandomStr() {
  return Math.random().toString(36).slice(-6);
}
