/**
 * 填充类型
 */
import * as _ from 'lodash';
import * as tinycolor from 'tinycolor2';
import { depthClone, min, max } from './globalUtils';
import { isSafari } from '@/dsm/utils';
import { getPointAngle, rotatePoint } from './boundsUtils';
import { getBoundsOfRotatedRectangle } from '@/helpers/rotateHelper';
import { getFootOfPerpendicular, getTowPointDis } from './rotateUtils';

export enum FillType {
  solid = 'solid',
  linear = 'linear',
  radial = 'radial',
}

/**
 * 边线类型
 */
export enum DahModel {
  Solid,
  Dashed,
  Dotted,
  DashDot,
  DashDotDot,
}

/**
 * 纯色数据类型 如：#fff或{r,b,b,a}
 */
export interface PureColor {
  r: number;
  g: number;
  b: number;
  a: number;
}

export type Color = PureColor | string;

export interface IColorInfo {
  rgb: tinycolor.ColorFormats.RGB;
  hsv: tinycolor.ColorFormats.HSV;
  hsl: tinycolor.ColorFormats.HSL;
  alpha: number;
}

/**
 * 渐变色中的一个颜色段
 */
export interface IColorStop {
  color: Color;
  /**
   * 取值范围0-1
   */
  point?: number;
}

export interface IGradient {
  colorStops: IColorStop[];
}

/**
 * 线性渐变数据类型
 */
export interface ILinearGradient extends IGradient {
  direction?: {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
  };
}

/**
 * 径向渐变数据类型
 */
export interface IRadialGradient extends IGradient {
  from?: { x: number; y: number; r: number };
  to?: { x: number; y: number; r: number };
  // 旋转角度
  angle?: number;
  // 宽度（相对高度的比例）
  widthRatio?: number;
}

/**
 * 线条笔触数据类型
 */
export interface IStrokeBrush {
  thickness: number;
  color: string;
  dashModel: DahModel;
}

export type FillData = Color | ILinearGradient | IRadialGradient;

/**
 * 填充笔触数据类型
 */
export interface IFill {
  type: FillType;
  color: FillData;
}

export function isLight(color: PureColor): boolean {
  const { r, g, b, a } = color;
  let isTrue = r * 0.299 + g * 0.587 + b * 0.114 > 128;
  if (!isTrue) {
    if (a < 0.3) {
      isTrue = true;
    }
  }

  return isTrue;
}

/**
 * 处理组件径向渐变旧数据
 */
export function reviseRadialGradient(color: IRadialGradient, size: ISize) {
  const cloneColor = depthClone(color);
  const { to, from, widthRatio, angle } = cloneColor;

  if (_.isUndefined(widthRatio)) {
    const newTo = to || { x: 0.5, y: 0.5, r: 0.5 };
    newTo.r = newTo.r * 1.414;
    cloneColor.to = newTo;
    cloneColor.from = from || { x: 0.5, y: 0.5, r: 0 };
    cloneColor.angle = angle || 0;
    cloneColor.widthRatio = size.width / size.height;
  } else {
    cloneColor.to = to || { x: 0.5, y: 0.5, r: 0.5 };
    cloneColor.from = from || { x: 0.5, y: 0.5, r: 0 };
    cloneColor.angle = angle || 0;
    cloneColor.widthRatio = widthRatio;
  }
  return cloneColor;
}

export function mergeColor(color: PureColor, alpha: number): PureColor {
  const { r, g, b } = color;
  let opacity = alpha;
  if (alpha > 100) {
    opacity = (alpha % 255) / 255;
  } else if (alpha > 1) {
    opacity = alpha / 100;
  }
  return { r, g, b, a: opacity };
}

/**
 * 把一个纯色数据转换为html可用的颜色字符串
 * @param {PureColor} color
 * @returns {string}
 */
export function parseColorToString(color: Color): string {
  if (!color) {
    return 'rgba(0,0,0,1)';
  }
  if (typeof color === 'string') {
    return color;
  }
  const { r, g, b, a } = color;
  const getValue = (value?: number | null, defaultValue?: number) => {
    if (_.isUndefined(value) || _.isNull(value) || _.isNaN(value)) {
      return defaultValue || 0;
    }
    return value;
  };
  return _.memoize(() => `rgba(${getValue(r)}, ${getValue(g)}, ${getValue(b)}, ${getValue(a, 1)})`)();
}

export function transColorToRGBA(color: Color): PureColor {
  if (typeof color === 'object') {
    return color as PureColor;
  } else {
    if (color[0] === '#') {
      let colorStr = color.substring(1);
      let alpha: string = 'ff';
      if (color.length > 7) {
        colorStr = color.substr(1, 7);
        alpha = color.substr(7);
      }
      const a = Math.round((parseInt(alpha, 16) * 100) / 255) / 100;
      const { r, g, b } = hex2Rgb(colorStr);
      return { r, g, b, a };
    } else if (color.indexOf('rgba') === 0) {
      // eslint-disable-next-line no-useless-escape
      const tx = color.replace(/[rgba\(\)]/g, '').split(',');
      const r = parseInt(tx[0].trim(), 10);
      const g = parseInt(tx[1].trim(), 10);
      const b = parseInt(tx[2].trim(), 10);
      const a = parseFloat(tx[3].trim());
      return {
        r,
        g,
        b,
        a,
      };
    } else if (color.indexOf('rgb') === 0) {
      // eslint-disable-next-line no-useless-escape
      const tx = color.replace(/[rgb\(\)]/g, '').split(',');
      const r = parseInt(tx[0].trim(), 10);
      const g = parseInt(tx[1].trim(), 10);
      const b = parseInt(tx[2].trim(), 10);
      return {
        r,
        g,
        b,
        a: 1,
      };
    }
  }
  return { r: 0, g: 0, b: 0, a: 0 };
}

export function RGBA2HEX(color: PureColor): string {
  const hex6 = rgb2Hex(color);
  let a = Math.round(color.a * 255)
    .toString(16)
    .toUpperCase();
  if (a.length === 1) {
    a = `0${a}`;
  }
  return `#${hex6}${a}`;
}

/**
 * rgb对象hex转十六进制hex
 * @returns {string}
 */
export function rgb2Hex(rgb: tinycolor.ColorFormats.RGB): string {
  const r = parseInt(rgb.r + '');
  const g = parseInt(rgb.g + '');
  const b = parseInt(rgb.b + '');

  return ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
}

function getHex6(hex: string): string {
  const length = hex.length;
  if (length === 6) {
    return hex;
  } else if (length === 3) {
    return hex
      .split('')
      .map((x) => x + x)
      .join('');
  } else {
    return hex;
  }
}

/**
 * 比较hex
 * @returns {boolean}
 */
export function compareHex(hex1: string, hex2: string): boolean {
  const upHex1 = hex1.toUpperCase();
  const upHex2 = hex2.toUpperCase();
  return getHex6(upHex1) === getHex6(upHex2);
}

/**
 * 十六进制hex转rgb对象(使用之前先正则判断参数) /^[a-fA-F0-9]{6}$/
 * @returns {string}
 */
export function hex2Rgb(hex: string): tinycolor.ColorFormats.RGB {
  const colorChange = [];
  for (let i = 0; i < 6; i += 2) {
    colorChange.push(parseInt('0x' + hex.slice(i, i + 2)));
  }
  return {
    r: colorChange[0],
    g: colorChange[1],
    b: colorChange[2],
  };
}

export const hexChipPattern = /^([a-fA-F0-9]{1,6})$/;

/**
 * 自动补全hex片段
 * @param {string} chip (/^([a-fA-F0-9]{1,6})$/)
 */
export const autoCompleHex = (chip: string) => {
  let hexNew = '';
  if (chip.length === 6) {
    return chip;
  } else if (chip.length === 3) {
    for (let i = 0; i < 3; i += 1) {
      hexNew += chip.slice(i, i + 1).concat(chip.slice(i, i + 1));
    }
    return hexNew;
  } else if (chip.length === 2) {
    return chip + chip + chip;
  } else if (chip.length === 1) {
    return chip + chip + chip + chip + chip + chip;
  } else if (/^([a-fA-F0-9])([a-fA-F0-9])\1\2$/.test(chip)) {
    return chip.concat(chip.slice(0, 2));
  } else if (/^([a-fA-F0-9])\1([a-fA-F0-9])\2$/.test(chip)) {
    const a = chip.slice(0, 1);
    const b = chip.slice(2, 3);
    return a + a + a + b + b + b;
  } else if (/^([a-fA-F0-9])\1([a-fA-F0-9])\2[a-fA-F0-9]$/.test(chip)) {
    return chip.concat(chip.slice(4, 5));
  } else if (/^([a-fA-F0-9])([a-fA-F0-9])\1\2\1$/.test(chip)) {
    return chip.concat(chip.slice(1, 2));
  } else {
    hexNew = chip.padEnd(6, '0');
    return hexNew;
  }
};

/**
 * rgba 转 hex
 * @param {PureColor} { r, g, b, a }
 * @param ignoreAlpha
 * @returns {string}
 */
export function rgba2hex({ r, g, b, a }: PureColor, ignoreAlpha?: boolean): string {
  const red = r << 16;
  const green = g << 8;
  const blue = b;
  let alpha = Math.round(a * 255).toString(16);
  if (alpha.length !== 2) {
    alpha = `0${alpha}`;
  }

  const colorValue = ((1 << 24) + red + green + blue).toString(16).slice(1);
  return ['#', colorValue, ignoreAlpha ? '' : alpha].join('').toUpperCase();
}

/**
 * Color 转 hex字符串
 * @param {Color} color
 * @returns {string}
 */
export function color2hexString(color: Color) {
  const tcolor = tinycolor(parseColorToString(color));
  const colorInfo: IColorInfo = {
    rgb: tcolor.toRgb(),
    hsv: tcolor.toHsv(),
    alpha: tcolor.getAlpha() * 100,
    hsl: tcolor.toHsl(),
  };
  return `#${rgb2Hex(colorInfo.rgb)}`;
}

/**
 * IFill 转 hex 字符串
 * @param {IFill} fill
 * @returns {string}
 */
export function getFillToolColorString(fill: IFill) {
  const { type, color } = fill;
  if (type === FillType.solid) {
    return color2hexString(color as Color);
  } else {
    const { colorStops } = color as ILinearGradient | IRadialGradient;
    const colorStrings = colorStops.map((item) => {
      return color2hexString(item.color);
    });
    const lst: string[][] = [];
    colorStrings.forEach((c, i) => {
      if (i % 5 === 0) {
        lst.push([]);
      }
      lst[lst.length - 1].push(c);
    });

    return lst.map((item) => item.join(', ')).join('\n');
  }
}

type IColorStopEx = IColorStop & { index: number };

/**
 * 把一个渐变中的所有颜色段数据中缺失的部分补全
 * @param {Array<IColorStop>} colorStops
 * @returns {Array<{index: number} & IColorStop>}
 */
export function fullColorStops(colorStops: IColorStop[]): IColorStopEx[] {
  const arr = colorStops.map((item, index) => ({ index, color: item.color, point: item.point }));
  arr[0].point = 0;
  arr[arr.length - 1].point = 100;
  const between = (start: number): void => {
    const check: { start: number; end: number | undefined } = { start, end: undefined };
    for (let i = start + 1; i < arr.length; i++) {
      if (arr[i].point !== undefined) {
        check.end = i;
        break;
      }
    }
    if (!check.end) {
      check.end = arr.length - 1;
    }
    if (check.end - check.start > 1) {
      const a = arr[check.start].point as number;
      const b = arr[check.end].point as number;
      const c = check.end - check.start;
      const s = (b - a) / c;
      let d = a + s;
      for (let i = check.start + 1; i < check.end; i++) {
        arr[i].point = d;
        d = d + s;
      }
    }
    if (check.end < arr.length - 1) {
      between(check.end);
    }
  };
  between(0);
  return arr;
}

export function calcColorStopByInsertPoint(previousColor: IColorStopEx, nextColor: IColorStopEx, point: number) {
  const len = nextColor.point! - previousColor.point!;
  const step = point - previousColor.point!;
  const percent = step / len;

  const newColor: IColorStopEx = { ...depthClone(previousColor), point };
  const colorValue = newColor.color as PureColor;
  const { r: pr, g: pg, b: pb, a: pa } = previousColor.color as PureColor;
  const { r: nr, g: ng, b: nb, a: na } = nextColor.color as PureColor;
  colorValue.a = pa + (na - pa) * percent;
  colorValue.r = pr + (nr - pr) * percent;
  colorValue.g = pg + (ng - pg) * percent;
  colorValue.b = pb + (nb - pb) * percent;
  return newColor;
}

function isWhite(color: PureColor) {
  const maxColorValue = 240;
  return color.r >= maxColorValue && color.g >= maxColorValue && color.b >= maxColorValue;
}

function calcGradientEndColorByColor(color: PureColor): PureColor {
  let result = color;
  const { a } = color;
  const hsv = tinycolor({ ...color, a: 1 }).toHsv();
  if (isWhite(color)) {
    hsv.v -= 0.3;
    result = { ...tinycolor(hsv).toRgb(), a };
  } else {
    if (hsv.s > 0.7) {
      hsv.s -= 0.4;
    } else {
      hsv.s = Math.max(0, hsv.s - 0.3);
    }
    if (hsv.v < 0.3) {
      hsv.v += 0.3;
    } else {
      hsv.v = Math.min(hsv.v + 0.2, 1);
    }
    result = { ...tinycolor(hsv).toRgb(), a };
  }
  return result;
}

function transitionColorToColorStops(color: Color): IColorStop[] {
  const rgba = transColorToRGBA(color);
  const otherColor = calcGradientEndColorByColor(rgba);
  let c1 = rgba;
  let c2 = otherColor;
  if (!isWhite(rgba)) {
    c1 = otherColor;
    c2 = rgba;
  }
  return [
    { color: c1, point: 0 },
    { color: c2, point: 100 },
  ];
}

export function transitionPureColorToLinearGradient(color: Color): ILinearGradient {
  const colorStops = transitionColorToColorStops(color);
  return {
    colorStops,
    direction: {
      x1: 0.5,
      x2: 0.5,
      y1: 0,
      y2: 1,
    },
  };
}

export function transitionPureColorToRadialGradient(color: Color): IRadialGradient {
  const colorStops = transitionColorToColorStops(color);
  return {
    colorStops,
    to: { x: 0.5, y: 0.5, r: 0.5 },
    from: { x: 0.5, y: 0.5, r: 0 },
    widthRatio: 1,
    angle: 0,
  };
}

export function transitionGradientToPureColor(gradient: IGradient) {
  return gradient.colorStops[0].color;
}

export function parseGradientColorToString(colorStops: IColorStop[], offset = { start: 0, end: 1 }): string {
  const { start, end } = offset;
  const coefficient = end - start;
  const stops = fullColorStops(colorStops).sort((a, b) => (a.point || 0) - (b.point || 0));
  const arr = stops.map((item) => {
    const color = typeof item.color === 'string' ? item.color : parseColorToString(item.color);
    return `${color} ${(item.point ?? 0) * coefficient + start * 100}%`;
  });
  return arr.join(',');
}

export function parseLinearToString(linear: ILinearGradient, size = { width: 100, height: 100 }): string {
  const { direction, colorStops } = linear;
  const { x1, x2, y1, y2 } = direction || { x1: 0, x2: 0, y1: 0, y2: 1 };
  const { width, height } = size;
  const p1 = {
    x: x1 * width,
    y: y1 * height,
  };
  const p2 = {
    x: x2 * width,
    y: y2 * height,
  };
  const rotate = getPointAngle(p1, p2) - 90;
  const deg = `${rotate}deg,`;

  const { height: length } = getBoundsOfRotatedRectangle({
    position: { x: 0, y: 0 },
    size,
    rotate,
  });

  const center = {
    x: width / 2,
    y: height / 2,
  };
  const start = rotatePoint({ x: center.x, y: center.y + length / 2 }, center, rotate);
  const end = rotatePoint({ x: center.x, y: center.y - length / 2 }, center, rotate);

  const foot1 = getFootOfPerpendicular(p1, start, end);
  const foot2 = getFootOfPerpendicular(p2, start, end);
  const offset = {
    start: getStopInLine(foot1, start, end, length),
    end: getStopInLine(foot2, start, end, length),
  };
  return `linear-gradient(${deg}${parseGradientColorToString(colorStops, offset)})`;
}

/**
 * 点在线段间的位置比例
 */
function getStopInLine(pt: IPoint, start: IPoint, end: IPoint, length: number) {
  const distance1 = getTowPointDis(pt, start);
  const distance2 = getTowPointDis(pt, end);
  const plusSign = (distance1 <= length && distance2 <= length) || distance2 < distance1;
  return ((plusSign ? 1 : -1) * distance1) / length;
}

export function parseRadialToString(radial: IRadialGradient): string {
  const { from, colorStops } = radial;
  const pt = from ? ` at ${from.x * 100}% ${from.y * 100}%` : '';
  return `radial-gradient(ellipse${pt},${parseGradientColorToString(colorStops)})`;
}

export function applyFillToStyle(fill: IFill, style: React.CSSProperties): void {
  switch (fill.type) {
    case FillType.solid:
      style.background = parseColorToString(fill.color as Color);
      break;
    case FillType.radial:
      style.background = parseRadialToString(fill.color as IRadialGradient);
      break;
    case FillType.linear:
      style.background = parseLinearToString(fill.color as ILinearGradient);
      break;
  }
  if (style.background) {
    style.fill = style.background as string;
  }
}

/**
 * 设置绘图API的虚线值
 * @param {CanvasRenderingContext2D} ctx
 * @param {number[]} dashArray
 */
export function setDash(ctx: CanvasRenderingContext2D, dashArray: number[]) {
  if (!ctx) {
    return;
  }
  const dashArr = dashArray || [];
  if (ctx.setLineDash) {
    ctx.setLineDash(dashArr);
    ctx.lineDashOffset = 0;
  } else if (ctx.mozDash) {
    ctx.mozDash = dashArr;
    ctx.mozDashOffset = 5;
  }
}

/**
 * 获取当前屏幕密度倍率
 * @param {CanvasRenderingContext2D} graphics
 * @returns {number}
 */
export function getDeviceRatio(graphics: CanvasRenderingContext2D): number {
  if (!graphics) {
    return 1;
  }
  const dratio = window.devicePixelRatio || 1;
  const gratio =
    graphics.backingStorePixelRatio ||
    graphics.webkitBackingStorePixelRatio ||
    graphics.mozBackingStorePixelRatio ||
    graphics.msBackingStorePixelRatio ||
    graphics.oBackingStorePixelRatio ||
    graphics.backingStorePixelRatio ||
    1;
  return dratio / gratio;
}

export function transSpriteImgToSmallImg(img: HTMLImageElement, sx: number, sy: number) {
  let canvas: HTMLCanvasElement | undefined = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (ctx) {
    const ratio = getDeviceRatio(ctx);
    const thumbWidth = 40;
    const thumbHeight = 40;
    ctx.scale(ratio, ratio);
    let zoom = 1;
    if (isSafari()) {
      zoom = ratio;
    }
    canvas.width = thumbWidth * zoom;
    canvas.height = thumbHeight * zoom;
    ctx.drawImage(img, sx, sy, thumbWidth, thumbHeight, 0, 0, canvas.width, canvas.height);
    const url = canvas.toDataURL();
    canvas = undefined;
    return url;
  }
  return '';
}

export function transImgToSmallImg(
  img: HTMLImageElement,
  sx: number,
  sy: number,
  imageWidth: number,
  imageHeight: number,
  maxWdith: number,
) {
  let canvas: HTMLCanvasElement | undefined = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (ctx) {
    const ratio = getDeviceRatio(ctx);
    const thumbWidth = imageWidth;
    const thumbHeight = imageHeight;
    ctx.scale(ratio, ratio);
    let zoom = 1;
    if (isSafari()) {
      zoom = ratio;
    }
    const newHeight = imageWidth > maxWdith ? (maxWdith / imageWidth) * imageHeight : thumbHeight;
    const newWidth = thumbWidth > maxWdith ? maxWdith : thumbWidth;
    canvas.width = newWidth * zoom;
    canvas.height = newHeight * zoom;
    ctx.drawImage(img, sx, sy, newWidth, newHeight);
    const url = canvas.toDataURL();
    canvas = undefined;
    return url;
  }
  return '';
}

/**
 * 文字转图像
 * @param {string} text
 * @param {string} family
 * @param {number} size
 * @param {Color} color
 * @returns {string}
 */
export function transTextToImage(text: string, family: string, size: number, color?: Color) {
  let canvas: HTMLCanvasElement | undefined = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (ctx) {
    const scale = getDeviceRatio(ctx);
    ctx.scale(scale, scale);
    const zoom = isSafari() ? scale : 1;
    canvas.width = canvas.height = size * zoom;
    ctx.fillStyle = parseColorToString(color || '#000');
    ctx.font = `${size * zoom}px ${family}`;
    ctx.fillText(text, 0, (size * zoom * 7) / 8);
    const url = canvas.toDataURL();
    // TODO 不要删除，用于释放canvas
    canvas = undefined;
    return url;
  }
  return '';
}

/**
 * 根据当前绘制线条宽度及线型应用虚线值
 * @param {CanvasRenderingContext2D} ctx
 * @param {number} lineWidth
 * @param {DahModel} dashModel
 */
function applyDashArrayFromLineWidth(ctx: CanvasRenderingContext2D, lineWidth: number, dashModel: DahModel) {
  if (!ctx) {
    return;
  }
  let dashArr: number[];
  const ratio = getDeviceRatio(ctx);
  lineWidth = lineWidth || 1;
  const short = Math.max(1, lineWidth) * ratio;
  const lang = (lineWidth < 3 ? lineWidth * 4 : lineWidth * 3) * ratio;
  const space = Math.max(short, 2);
  switch (dashModel) {
    case DahModel.Dashed:
      dashArr = [lang, lang];
      break;
    case DahModel.Dotted:
      dashArr = [short, space];
      break;
    case DahModel.DashDot:
      dashArr = [short, space, lang, space];
      break;
    case DahModel.DashDotDot:
      dashArr = [short, space, lang, space, short, space];
      break;
    default:
      dashArr = [];
      break;
  }
  setDash(ctx, dashArr);
}

enum PathPointCommand {
  moveTo = 'moveTo',
  lineTo = 'lineTo',
  arcTo = 'arcTo',
  curveTo = 'curveTo',
}

/**
 * 路径类
 */
export class PathData {
  private pointsCmd: { cmd: PathPointCommand; params: number[] }[] = [];
  private lastMoveCmd: { params: number[] } | undefined;
  private isClose: boolean = false;

  constructor() {}

  public toSVG = (): string => {
    const first = this.pointsCmd[0];

    const arr: string[] = this.pointsCmd.map((point) => {
      const { cmd, params } = point;
      const [v0, v1, v2, v3, v4, v5] = params;
      const len = params.length;
      switch (cmd) {
        case PathPointCommand.moveTo:
          return `M${v0} ${v1}`;
        case PathPointCommand.lineTo:
          return `L${v0} ${v1}`;
        case PathPointCommand.curveTo:
          if (len === 4) {
            return `Q${v0} ${v1} ${v2} ${v3}`;
          } else if (len === 6) {
            return `C${v0} ${v1} ${v4} ${v5} ${v2} ${v3} `;
          }
          return '';
        case PathPointCommand.arcTo:
          return `A${v0} ${v1} ${v4} 0 1 ${v2} ${v3}`;
        default:
          return '';
      }
    });
    if (first && first.cmd !== PathPointCommand.moveTo) {
      arr.unshift('M0 0');
    }
    let path = arr.join(' ');
    if (this.isClose) {
      path = `${path}Z`;
    }
    return path;
  };

  public moveTo = (x: number, y: number) => {
    this.lastMoveCmd = { params: [x, y] };
    this.pointsCmd.push({ cmd: PathPointCommand.moveTo, params: [x, y] });
    return this;
  };

  public lineTo = (x: number, y: number) => {
    this.pointsCmd.push({ cmd: PathPointCommand.lineTo, params: [x, y] });
    return this;
  };

  // hLineTo = (x: number) => {
  //   let y = 0;
  //   if (this.pointsCmd.length) {
  //     const last = this.pointsCmd[this.pointsCmd.length - 1];
  //     if (last.cmd === PathPointCommand.arcTo) {
  //       y = last.params[last.params.length - 2];
  //     } else {
  //       y = last.params[last.params.length - 1];
  //     }
  //   }
  //   this.lineTo(x, y);
  // };
  //
  // vLineTo = (y: number) => {
  //   let x = 0;
  //   if (this.pointsCmd.length) {
  //     const last = this.pointsCmd[this.pointsCmd.length - 1];
  //     if (last.cmd === PathPointCommand.arcTo) {
  //       x = last.params[last.params.length - 3];
  //     } else {
  //       x = last.params[last.params.length - 2];
  //     }
  //   }
  //   this.lineTo(x, y);
  // };

  public curveTo = (cx: number, cy: number, x: number, y: number, ex?: number, ey?: number) => {
    const params = [cx, cy, x, y];
    if (ex !== undefined && ey !== undefined) {
      params.push(ex, ey);
    }
    this.pointsCmd.push({ cmd: PathPointCommand.curveTo, params });
    return this;
  };

  public arcTo = (rx: number, ry: number, x2: number, y2: number, r: number) => {
    this.pointsCmd.push({ cmd: PathPointCommand.arcTo, params: [rx, ry, x2, y2, r] });
    return this;
  };

  public close = () => {
    if (this.lastMoveCmd) {
      const [x, y] = this.lastMoveCmd.params;
      this[PathPointCommand.lineTo].apply(this, [x, y]);
    }
    this.isClose = true;
    return this;
  };

  public clear = () => {
    this.pointsCmd = [];
    this.lastMoveCmd = undefined;
    this.isClose = false;
    return this;
  };

  public stroke = (graphics: CanvasRenderingContext2D, brush: IStrokeBrush) => {
    if (!graphics) {
      return;
    }
    graphics.strokeStyle = brush.color;
    graphics.lineWidth = brush.thickness;
    applyDashArrayFromLineWidth(graphics, brush.thickness, brush.dashModel);
    graphics.stroke();
  };

  public fill = (graphics: CanvasRenderingContext2D, brush: IFill) => {
    if (brush.type === FillType.solid) {
      graphics.fillStyle = parseColorToString(brush.color as PureColor);
    } else {
      const colors = fullColorStops((brush.color as IGradient).colorStops);
      let gradient: CanvasGradient;
      if (brush.type === FillType.linear) {
        const { direction } = brush.color as ILinearGradient;
        const { x1, x2, y1, y2 } = direction || { x1: 0, x2: 0, y1: 0, y2: 0 };
        gradient = graphics.createLinearGradient(x1, y1, x2, y2);
      } else {
        const { from, to } = brush.color as IRadialGradient;
        const sx = from ? from.x : 0;
        const sy = from ? from.y : 0;
        const sr = from ? from.r : 0;
        const ex = to ? to.x : 0;
        const ey = to ? to.y : 1;
        const er = to ? to.r : 1;
        gradient = graphics.createRadialGradient(sx, sy, sr, ex, ey, er);
      }
      colors.forEach((item) => {
        gradient.addColorStop(item.point || 0, parseColorToString(item.color));
      });
      graphics.fillStyle = gradient;
    }

    graphics.fill();
  };
}

interface IPoint {
  x: number;
  y: number;
}

/**
 * 多边形类
 */
export class PolygonData {
  constructor(public size: { width: number; height: number }, public sideCount: number = 3) {
    this.size = size;
    this.sideCount = Math.max(sideCount, 3);
  }

  // 六边形
  getHexagonPoints = () => {
    const { width, height } = this.size;
    const basePosition = Math.min(width, height) * (15 / 53);
    return [
      { x: basePosition, y: 0 },
      { x: 0, y: height / 2 },
      { x: basePosition, y: height },
      { x: width - basePosition, y: height },
      { x: width, y: height / 2 },
      { x: width - basePosition, y: 0 },
    ];
  };

  // 八边形
  getOctagonPoints = () => {
    const { width, height } = this.size;
    const basePosition = Math.min(width, height) * (15 / 60);
    return [
      { x: basePosition, y: 0 },
      { x: 0, y: basePosition },
      { x: 0, y: height - basePosition },
      { x: basePosition, y: height },
      { x: width - basePosition, y: height },
      { x: width, y: height - basePosition },
      { x: width, y: basePosition },
      { x: width - basePosition, y: 0 },
    ];
  };

  /**
   * 转换成多边形
   * @param {number} diff
   * @returns {Array<IPoint>}
   */
  public toPolygonPoints = (diff: number = 1): IPoint[] => {
    const { width, height } = this.size;
    // 三边时
    if (this.sideCount === 3) {
      return [
        { x: width / 2, y: diff },
        { x: width - diff, y: height - diff },
        { x: diff, y: height - diff },
      ];
    }
    // 四边时
    if (this.sideCount === 4) {
      return [
        { x: width / 2, y: diff },
        { x: width - diff, y: height / 2 },
        { x: width / 2, y: height - diff },
        { x: diff, y: height / 2 },
      ];
    }
    // 六边形
    if (this.sideCount === 6) {
      return this.getHexagonPoints();
    }
    if (this.sideCount === 8) {
      return this.getOctagonPoints();
    }

    /*
      1、以一个正圆计算多边形各边占用弧度
      2、计算多边形各角点在正圆上的位置
      3、根据当前矩形尺寸，计算宽和高相对正圆时的比例
      4、根据比例计算各角点在矩形内的点的位置
     */
    const diffAngle = 360 / this.sideCount;
    const cx = 50;
    const cy = 50;
    const r = 50;
    let startAngle = 90 - diffAngle / 2;

    const points: IPoint[] = [];
    let left = cx;
    let right = cx;
    let top = cy;
    let bottom = cy;

    // 计算在一个正圆中内接多边形的各个点，并获取多边形在正圆中实际占用的区域
    for (let i = 0; i < this.sideCount; i++) {
      // 多边形各角点
      const x = cx + r * Math.cos((startAngle * Math.PI) / 180);
      const y = cy + r * Math.sin((startAngle * Math.PI) / 180);
      // 最左，最上，最右，最下
      // FIXME Mxj 以完全充满的方式时使用下面的计算
      left = Math.min(left, x);
      right = Math.max(right, x);
      top = Math.min(top, y);
      bottom = Math.max(bottom, y);
      points.push({ x, y });
      // 下一个点的角度
      startAngle += diffAngle;
      if (startAngle >= 360) {
        startAngle -= 360;
      }
    }

    // 根据实际占用区域和需要的尺寸，计算最终各点位置

    const size = {
      width: this.size.width - diff * 2,
      height: this.size.height - diff * 2,
    };
    //
    // // FIXME 内切的内接，用以下方式处理
    // if (window.isDT) {
    //   bottom = right = 100;
    //   top = left = 0;
    // }

    const sx = size.width / (right - left);
    const sy = size.height / (bottom - top);
    points.forEach((point) => {
      point.x = (point.x - left) * sx + diff;
      point.y = (point.y - top) * sy + diff;
    });
    return points;
  };

  /**
   * 多边星形，为之后预留
   * @returns {Array<IPoint>}
   */
  public toStarPoints = (): IPoint[] => {
    return [];
  };

  /*
  x1   =   x0   +   r   *   cos(a   *   PI   /180   )
  y1   =   y0   +   r   *   sin(a   *   PI  /180   )
  r/Math.cos(70*Math.PI/180)
   */
  // toPolygonPointsData = (diff: number = 1): string => {
  //   const points = this.toPolygonPoints(diff);
  //   /*
  //     补充圆角计算方式：
  //     1、根据当前顶点和前后相邻点，计算夹角角度
  //
  //     ????????????????
  //     当前点坐标为：p1 = x1,y1, 前一点为 p2 = x2,y2, 后一点为 p3 = x3,y3;
  //     const ab = p2 - p1;
  //     const ac = p3 - p1;
  //     const bc = p3-p2;
  //     const cosa = (ab**2 + ac**2-bc**2)/(ac*ab*2)
  //     const angle = Math.acos(cosa);
  //
  //     ?????????????????????
  //
  //     2、以圆角半径为直角边，夹角的一半为直角边的对角计算夹角边上开始圆角的点的位置
  //     3、根据得到的点换算成曲线路径
  //
  //     *: 最大半径值不得超过最短边的长度
  //    */
  //   return points.map(point => `${point.x},${point.y}`).join(' ');
  // };

  // toPath = (radius: number, diff: number = 1): string => {
  //   const points = this.toPolygonPoints(diff);
  //
  //   for (let i = 0, c = points.length; i < c; i++) {
  //     let p0 = points[i];
  //     let pr = i === 0 ? points[c - 1] : points[i - 1];
  //     let pn = i === c - 1 ? points[0] : points[i + 1];
  //     const ab = pr - p0;
  //     const ac = pn - p0;
  //     const bc = pn - pr;
  //     const cosa = (ab ** 2 + ac ** 2 - bc ** 2) / (ac * ab * 2);
  //     const angle = Math.acos(cosa) / 2;
  //
  //
  //   }
  // };
}

interface ISize {
  width: number;
  height: number;
}

interface IRadius {
  isPercent?: boolean;
  topLeft?: number;
  topRight?: number;
  bottomLeft?: number;
  bottomRight?: number;
}

interface IBorderVisible {
  left?: boolean;
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
}

interface IStroke {
  thickness?: number;
  caps?: 'butt' | 'round' | 'square';
  joins?: 'round' | 'bevel' | 'miter';
  position?: 'inner' | 'center' | 'outer';
}

interface IStrokeConfig {
  size: ISize;
  type?: string;
  stroke?: IStroke;
  radius?: IRadius;
  borderModel?: IBorderVisible;
}

/**
 * 描边偏移不在这里处理
 */
export function createRectStrokePath(option: IStrokeConfig) {
  const { size, stroke, radius, borderModel } = option;
  const { width, height } = size;
  if (width <= 0 || height <= 0) {
    return '';
  }
  const thickness = stroke ? stroke.thickness || 1 : 0;
  const maxRadius = getMaxRadius(width, height, thickness, stroke);
  let left = true;
  let top = true;
  let right = true;
  let bottom = true;

  if (borderModel) {
    left = !!borderModel.left;
    top = !!borderModel.top;
    right = !!borderModel.right;
    bottom = !!borderModel.bottom;
  }

  const { topRight, bottomRight, bottomLeft, topLeft } = getStrokeRadius(size, maxRadius, 0, radius);

  const x = 0;
  const y = 0;
  const r = width;
  const b = height;
  const pathData = new PathData();

  // 1
  const onlyTop = () => {
    pathData.moveTo(0, y).lineTo(width, y);
  };
  // 2
  const topAndRight = () => {
    pathData.moveTo(0, y);
    if (topRight) {
      pathData.lineTo(r - topRight, y).arcTo(topRight, topRight, r, y + topRight, 90);
    } else {
      pathData.lineTo(r, y);
    }
    pathData.lineTo(r, height);
  };
  // 3
  const topRightBottom = () => {
    pathData.moveTo(0, y);
    if (topRight) {
      pathData.lineTo(r - topRight, y).arcTo(topRight, topRight, r, y + topRight, 90);
    } else {
      pathData.lineTo(r, y);
    }
    if (bottomRight) {
      pathData.lineTo(r, b - bottomRight).arcTo(bottomRight, bottomRight, r - bottomRight, b, 180);
    } else {
      pathData.lineTo(r, b);
    }
    pathData.lineTo(0, b);
  };
  // 4
  const onlyRight = () => {
    pathData.moveTo(r, 0).lineTo(r, height);
  };
  // 5
  const rightAndBottom = () => {
    pathData.moveTo(r, 0);
    if (bottomRight) {
      pathData.lineTo(r, b - bottomRight).arcTo(bottomRight, bottomRight, r - bottomRight, b, 180);
    } else {
      pathData.lineTo(r, b);
    }
    pathData.lineTo(0, b);
  };
  // 6
  const rightBottomLeft = () => {
    pathData.moveTo(r, 0);
    if (bottomRight) {
      pathData.lineTo(r, b - bottomRight).arcTo(bottomRight, bottomRight, r - bottomRight, b, 180);
    } else {
      pathData.lineTo(r, b);
    }
    if (bottomLeft) {
      pathData.lineTo(x + bottomLeft, b).arcTo(bottomLeft, bottomLeft, x, b - bottomLeft, 270);
    } else {
      pathData.lineTo(x, b);
    }
    pathData.lineTo(x, 0);
  };
  // 7
  const onlyBottom = () => {
    pathData.moveTo(0, b).lineTo(width, b);
  };
  // 8
  const bottomAndLeft = () => {
    pathData.moveTo(width, b);
    if (bottomLeft) {
      pathData.lineTo(x + bottomLeft, b).arcTo(bottomLeft, bottomLeft, x, b - bottomLeft, 270);
    } else {
      pathData.lineTo(x, b);
    }
    pathData.lineTo(x, 0);
  };
  // 9
  const bottomLeftTop = () => {
    pathData.moveTo(width, b);
    if (bottomLeft) {
      pathData.lineTo(x + bottomLeft, b).arcTo(bottomLeft, bottomLeft, x, b - bottomLeft, 270);
    } else {
      pathData.lineTo(x, b);
    }
    if (topLeft) {
      pathData.lineTo(x, y + topLeft).arcTo(topLeft, topLeft, x + topLeft, y, 0);
    } else {
      pathData.lineTo(x, y);
    }
    pathData.lineTo(width, y);
  };
  // 10
  const onlyLeft = () => {
    pathData.moveTo(x, 0).lineTo(x, height);
  };
  // 11
  const leftAndTop = () => {
    pathData.moveTo(x, height);
    if (topLeft) {
      pathData.lineTo(x, y + topLeft).arcTo(topLeft, topLeft, x + topLeft, y, 0);
    } else {
      pathData.lineTo(x, y);
    }
    pathData.lineTo(width, y);
  };
  // 12
  const leftTopRight = () => {
    pathData.moveTo(x, height);
    if (topLeft) {
      pathData.lineTo(x, y + topLeft).arcTo(topLeft, topLeft, x + topLeft, y, 0);
    } else {
      pathData.lineTo(x, y);
    }
    if (topRight) {
      pathData.lineTo(r - topRight, y).arcTo(topRight, topRight, r, y + topRight, 90);
    } else {
      pathData.lineTo(r, y);
    }
    pathData.lineTo(r, height);
  };
  // 13
  const leftRight = () => {
    pathData.moveTo(x, 0).lineTo(x, height).moveTo(r, 0).lineTo(r, height);
  };
  // 14
  const topBottom = () => {
    pathData.moveTo(0, y).lineTo(width, y).moveTo(0, b).lineTo(width, b);
  };
  // 15
  const allSlide = () => {
    if (topLeft) {
      pathData.moveTo(x, y + topLeft).arcTo(topLeft, topLeft, x + topLeft, y, 0);
    } else {
      pathData.moveTo(x, y);
    }
    if (topRight) {
      pathData.lineTo(r - topRight, y).arcTo(topRight, topRight, r, y + topRight, 90);
    } else {
      pathData.lineTo(r, y);
    }
    if (bottomRight) {
      pathData.lineTo(r, b - bottomRight).arcTo(bottomRight, bottomRight, r - bottomRight, b, 180);
    } else {
      pathData.lineTo(r, b);
    }
    if (bottomLeft) {
      pathData
        .lineTo(x + bottomLeft, b)
        .arcTo(bottomLeft, bottomLeft, x, b - bottomLeft, 270)
        .close();
    } else {
      pathData.lineTo(x, b).close();
    }
  };

  const isOnly = !((!top && !bottom && left && right) || (!left && !right && top && bottom));
  if (isOnly) {
    const isOpen = !top || !left || !right || !bottom;
    if (!isOpen) {
      allSlide();
    } else {
      if (top) {
        if (left && right) {
          leftTopRight();
        } else if (left && bottom) {
          bottomLeftTop();
        } else if (right && bottom) {
          topRightBottom();
        } else if (left) {
          leftAndTop();
        } else if (right) {
          topAndRight();
        } else {
          onlyTop();
        }
      } else if (left) {
        if (top && bottom) {
          bottomLeftTop();
        } else if (top && right) {
          leftTopRight();
        } else if (bottom && right) {
          rightBottomLeft();
        } else if (top) {
          leftAndTop();
        } else if (bottom) {
          bottomAndLeft();
        } else {
          onlyLeft();
        }
      } else if (right) {
        if (top && bottom) {
          topRightBottom();
        } else if (top && left) {
          leftTopRight();
        } else if (bottom && left) {
          rightBottomLeft();
        } else if (top) {
          topAndRight();
        } else if (bottom) {
          rightAndBottom();
        } else {
          onlyRight();
        }
      } else if (bottom) {
        if (left && right) {
          rightBottomLeft();
        } else if (left && top) {
          bottomLeftTop();
        } else if (right && top) {
          topRightBottom();
        } else if (left) {
          bottomAndLeft();
        } else if (right) {
          rightAndBottom();
        } else {
          onlyBottom();
        }
      }
    }
  } else {
    if (top && bottom) {
      topBottom();
    } else {
      leftRight();
    }
  }
  return pathData.toSVG();
}

/**
 * 内外描边
 */
function getStrokeRadius(size: ISize, maxRadius: number, offset: number, radius: IRadius | undefined) {
  const getRadius = (maxNum: number, num: number = 0) => {
    const radiu = radius?.isPercent ? (min(size.width, size.height) * num) / 200 : num;
    return min(max(radiu - offset, 0), maxNum);
  };

  const topLeft = getRadius(maxRadius, radius?.topLeft);
  const topRight = getRadius(maxRadius, radius?.topRight);
  const bottomLeft = getRadius(maxRadius, radius?.bottomLeft);
  const bottomRight = getRadius(maxRadius, radius?.bottomRight);
  return { topRight, bottomRight, bottomLeft, topLeft };
}

function getMaxRadius(width: number, height: number, thickness: number, stroke: IStroke | undefined) {
  let maxRadius = min(width - thickness, height - thickness) / 2;

  const strokePosition = stroke?.position || 'inner';
  switch (strokePosition) {
    case 'outer':
      maxRadius = min(width + thickness, height + thickness) / 2;
      break;
    case 'center':
      maxRadius = min(width, height) / 2;
      break;
    case 'inner':
    default:
      break;
  }
  return maxRadius;
}

// function getStrockOffset(width: number, strockePosition: IStroke['position'] = 'inner') {
//   switch (strockePosition) {
//     case 'center':
//       return 0;
//     case 'inner':
//       return 0.5 * width;
//     case 'outer':
//       return -0.5 * width;
//     default:
//       return 0.5 * width;
//   }
// }

export function createRectFillPath(size: ISize, radius: IRadius, stroke?: IStroke, borderVisible?: IBorderVisible) {
  // FIXME: zjq
  const x = 0;
  const y = 0;
  const { width, height } = size;
  if (width <= 0 || height <= 0) {
    return '';
  }
  let { topRight, topLeft, bottomRight, bottomLeft } = radius;
  const pathData = new PathData();
  const maxRadius = min(width, height) / 2;

  topRight = min(topRight || 0, maxRadius);
  topLeft = min(topLeft || 0, maxRadius);
  bottomLeft = min(bottomLeft || 0, maxRadius);
  bottomRight = min(bottomRight || 0, maxRadius);

  const { left, top, right, bottom } = borderVisible || { left: true, top: true, right: true, bottom: true };
  const isBevel = false; //stroke && join === 'bevel';

  if (left && top && topLeft) {
    pathData.moveTo(x, topLeft);
    pathData.arcTo(topLeft, topLeft, x + topLeft, y, 0);
  } else {
    if (left && top && isBevel) {
      pathData.moveTo(x, 0).lineTo(0, y);
    } else {
      pathData.moveTo(x, y);
    }
  }
  if (top && right && topRight) {
    pathData.lineTo(width - topRight, y);
    pathData.arcTo(topRight, topRight, width, topRight, 90);
  } else {
    if (top && right && isBevel) {
      pathData.lineTo(width, y).lineTo(width, 0);
    } else {
      pathData.lineTo(width, y);
    }
  }
  if (right && bottom && bottomRight) {
    pathData.lineTo(width, height - bottomRight);
    pathData.arcTo(bottomRight, bottomRight, width - bottomRight, height, 180);
  } else {
    if (right && bottom && isBevel) {
      pathData.lineTo(width, height).lineTo(width, height);
    } else {
      pathData.lineTo(width, height);
    }
  }
  if (bottom && left && bottomLeft) {
    pathData.lineTo(bottomLeft, height);
    pathData.arcTo(bottomLeft, bottomLeft, x, height - bottomLeft, 270);
  } else {
    if (bottom && left && isBevel) {
      pathData.lineTo(0, height).lineTo(x, height);
    } else {
      pathData.lineTo(x, height);
    }
  }
  pathData.close();
  return pathData.toSVG();
}

export function isSameColor(aColor: Color, bColor: Color) {
  return parseColorToString(aColor) === parseColorToString(bColor);
}

/**
 * 待完善，考虑构建一个完整的API
 */
// class Graphics {
//   constructor(private canvas: CanvasRenderingContext2D) {

//   }

//   drawLine() {
//   }

//   drawRect(bounds: {}, radius: number) {
//   }
// }
