import { NativeTypes } from 'react-dnd-html5-backend';

// 项目树上的页面节点
// export const TREE_NODE = Symbol();

// 组件库的组件
export const COMPONENT = Symbol();

// 图标库的图标
export const ICON = Symbol();

export const FILE = NativeTypes.FILE;

// 设计资源中的组件
// export const DS_COMPONENT = Symbol();
//
// export const DS_COLOR = Symbol();
//
// export const DS_TYPOGRAPHY = Symbol();

export const DS_RESOURCE = Symbol();

// 树组件的的节点
export const COMP_TREE_NODE = Symbol();

//第三方组件库
export const CUSTOM_COMPONENT = Symbol();
