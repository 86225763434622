import * as React from 'react';

import { ICustomTargetStr, ICustomTargetStrOrigin } from '../../models/MatchStrSpan';

import { filterXSS } from 'xss';

import './index.scss';

interface IMatchStrSpanProps {
  str: string;
  targetStr?: string;
  targetClassName?: string;
  theme?: 'blue' | 'red';
  // 目标字符串有多个，采用相同样式
  targetStrArr?: string[];
  // 目标字符串有多个，可自定义样式及是否是超链接
  targetStrCustomArr?: ICustomTargetStr[];
  origin?: ICustomTargetStrOrigin;
  onClickTarget?(): void;
}

const MatchStrSpan: React.FC<IMatchStrSpanProps> = ({
  str,
  targetStr,
  targetClassName,
  theme,
  targetStrArr,
  targetStrCustomArr,
  origin,
  onClickTarget,
}: IMatchStrSpanProps) => {
  const onClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    // @ts-ignore 解决className报错问题
    if (e.target.className.includes('target-str')) {
      onClickTarget && onClickTarget();
    }
  };

  const filterSpecialStr = (str: string) => {
    return str.replace(/[\\|\*|\+|\?|]/g, '');
  };

  const isUndefined = (val: any): boolean => {
    return typeof val === 'undefined';
  };

  const getHtml = () => {
    if (targetStrCustomArr && targetStrCustomArr.length > 0) {
      if (origin && origin.args) {
        return origin.key.replace(/{(\d+)}/g, (match: string, number: string) => {
          const matchNum = Number(number);
          if (isUndefined(origin.args![matchNum])) {
            return match;
          }

          const targetItem = targetStrCustomArr?.find((item) => item.index === matchNum);
          const xssName = filterXSS(targetItem?.name || '');

          if (targetItem) {
            if (targetItem?.url) {
              return (
                `<a class="target-str-item ${targetItem?.class || ''} ${theme || ''}" href="${
                  targetItem?.url
                }" target="${targetItem?.target || '_blank'}">` +
                xssName +
                '</a>'
              );
            }
            return `<span class="target-str-item ${targetItem?.class || ''} ${theme || ''}">` + xssName + '</span>';
          }
          return origin.args![matchNum];
        });
      }

      let html = str;

      targetStrCustomArr.forEach((item) => {
        const xssName = filterXSS(item.name);
        if (item.url) {
          html = html.replace(
            item.name,
            `<a class="target-str-item ${item.class || ''} ${theme || ''}" href="${item.url}" target="${
              item.target || '_blank'
            }">` +
              xssName +
              '</a>',
          );
        } else {
          const pattern = filterSpecialStr(xssName);
          const reg = new RegExp(pattern, 'g');
          html = html.replace(
            reg,
            `<span class="target-str-item ${item.class || ''} ${theme || ''}">` + xssName + '</span>',
          );
        }
      });
      return html;
    }

    if (targetStrArr && targetStrArr.length > 0) {
      let html = str;
      targetStrArr.forEach((item) => {
        const pattern = filterSpecialStr(item);
        const reg = new RegExp(pattern, 'g');

        const xssStr = filterXSS(item);

        html = html.replace(
          reg,
          `<span class="target-str ${targetClassName || ''} ${theme || ''}">` + xssStr + '</span>',
        );
      });
      return html;
    }
    if (targetStr) {
      const pattern = filterSpecialStr(targetStr);
      const reg = new RegExp(pattern, 'g');
      const xssStr = filterXSS(targetStr);

      return str.replace(reg, `<span class="target-str ${targetClassName || ''} ${theme || ''}">` + xssStr + '</span>');
    }

    return str;
  };

  return <span className="match-str-wrapper" onClick={onClick} dangerouslySetInnerHTML={{ __html: getHtml() }}></span>;
};

MatchStrSpan.defaultProps = {
  targetStr: '',
};

export default MatchStrSpan;
