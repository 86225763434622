import 'babel-polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as packageInfo from 'package.json';

import { zipStore } from '@/store';

import Main from './main';

import '../index.scss';

console.log(packageInfo.version);

ReactDOM.render(
  <Provider store={zipStore}>
    <Main />
  </Provider>,
  document.getElementById('app'),
);
