import { IcomponentLibGroup, IComponentCustomLib, ICustomComponentItem } from '@libs/types';

import { get, post } from './helper';

/**
 * 获取全部第三方组件项目
 */
export function getCustomComponentGroupList(): Promise<IcomponentLibGroup<IComponentCustomLib>[]> {
  return get(`/libsOf3rdParty/libs`);
}

/**
 * 获取第三方组件项目内容
 */
export function getCustomComponetList(libId: string, groupId: string, ids?: string[]): Promise<ICustomComponentItem[]> {
  return get(`/libsOf3rdParty/lib/${libId}/${groupId}${ids && ids.length ? `/${ids.join(',')}` : ''}`);
}

export interface IGeoFeature {
  type: string;
  properties: {
    id: string;
    name: string;
  };
}

export interface IGeoJson {
  type: string;
  features: IGeoFeature[];
}

/**
 * 获取地图组件所有区域数据
 */
export function getAlRegionData(): Promise<{ region: string; data: IGeoJson }[]> {
  return get('/chart/maps');
}

/**
 * 获取地图组件区域数据
 * @param id
 */
export function getRegionData(id: string): Promise<IGeoJson> {
  return get(`/chart/map/${id}`);
}

/**
 * 上报资源组件
 * @param id
 */
export function reportResolveCompUseCount(useCompsId: string[]) {
  return post(`/ds/component/usageCount`, { componentIDs: useCompsId });
}
