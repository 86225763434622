import * as React from 'react';
import * as ReactDom from 'react-dom';
import classnames from 'classnames';

import { titleBarHeight } from '@/utils/envUtils';

import withAutoClose, { IAutoCloseComponentProps } from '../withAutoClose';
import List, { IListItem } from '../List';
import { ComponentTheme } from '../common';

import './index.scss';
export interface IDropDownProp extends IAutoCloseComponentProps {
  selectDom: React.RefObject<HTMLElement>;
  className?: string;
  listClassName?: string;
  data: Array<IListItem>;
  maxWidth?: number;
  placement?: 'left' | 'right';
  theme?: ComponentTheme;
  selectedIndex?: number;
  maxRowCount?: number;
  itemHeight?: number;
  onSelect: (item: IListItem) => void;
  onMouseDown?: React.MouseEventHandler;
  itemRender?: (item: IListItem, selected: boolean) => React.ReactNode;
  allowHover?: boolean;
}

export interface IDropDownState {
  position?: { left: number; top?: number; bottom?: number };
  width?: number;
  height: number;
  opacity: number;
  popDirection: 'up' | 'down';
  data: Array<IListItem>;
}

class DropDown extends React.Component<IDropDownProp, IDropDownState> {
  static defaultProps: Partial<IDropDownProp> = {};

  selfRef: React.RefObject<HTMLDivElement>;
  listDom: React.RefObject<List> = React.createRef();

  constructor(props: IDropDownProp) {
    super(props);
    const { data, maxRowCount } = props;
    let d = data;
    if (maxRowCount && maxRowCount > data.length) {
      d = data.slice(0, maxRowCount);
    }
    this.state = {
      height: 0,
      width: 0,
      opacity: 0,
      popDirection: 'down',
      data: d,
    };
    this.selfRef = props.forwardedRef || React.createRef();
  }

  UNSAFE_componentWillReceiveProps(props: IDropDownProp) {
    if (props.data !== this.props.data) {
      this.setState({ data: props.data });
    }
  }

  componentDidMount() {
    this.doAdjustPopupPosition();
    if (this.state.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
    window.addEventListener('resize', this.doAdjustPopupPosition);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.doAdjustPopupPosition);
  }

  doAdjustPopupPosition = () => {
    const { selectDom, placement } = this.props;
    if (!selectDom) {
      return;
    }
    if (selectDom.current && this.selfRef.current) {
      const bounds = selectDom.current.getBoundingClientRect();
      const listEl = this.selfRef.current.children[0] as HTMLElement;
      const listBounds = listEl.getBoundingClientRect();
      let width = Math.max(bounds.width, listBounds.width);
      if (this.listDom.current) {
        width = Math.max(width, this.listDom.current.contentWidth);
      }
      const minTop = titleBarHeight + 5;
      let popDirection: 'up' | 'down' = 'down';
      const position = { left: bounds.left, top: bounds.bottom + 2 };
      if (placement === 'right') {
        position.left = bounds.right - width;
      }
      const { innerWidth, innerHeight } = window;
      if (placement !== 'right' && position.left + width > innerWidth) {
        position.left = bounds.right - width;
      } else if (placement === 'right' && position.left < 0) {
        position.left = bounds.left;
      }
      if (position.top + listBounds.height > innerHeight) {
        position.top = Math.max(bounds.top - listBounds.height - 2, minTop);
        popDirection = 'up';
      }

      if (popDirection === 'down') {
        this.setState({
          position,
          width,
          height: listBounds.height,
          opacity: 1,
          popDirection: popDirection,
        });
      } else {
        this.setState(
          {
            position: {
              left: position.left,
              bottom: Math.min(
                window.innerHeight - (bounds.top - 2),
                window.innerHeight - (minTop + listBounds.height),
              ),
              top: undefined,
            },
            width: 0,
            height: 0,
          },
          () => {
            this.setState({
              height: listBounds.height,
              width,
              opacity: 1,
              popDirection,
            });
          },
        );
      }
    }
  };

  handleTransionEnd = (e: React.TransitionEvent) => {
    e.stopPropagation();
    this.setState({ height: this.state.height });
  };

  render() {
    const {
      itemHeight,
      itemRender,
      onSelect,
      maxRowCount,
      allowHover,
      theme,
      selectedIndex,
      className,
      onMouseDown,
      maxWidth,
      listClassName,
    } = this.props;
    const { position, width, height, opacity, popDirection, data } = this.state;
    return ReactDom.createPortal(
      <div
        className={classnames(`popup-with-body ${className}`, {
          'pop-up': popDirection === 'up',
          'pop-down': popDirection === 'down',
        })}
        ref={this.selfRef}
        onMouseDown={onMouseDown}
        style={{
          ...position,
          width,
          height,
          opacity,
          maxWidth,
        }}
        onTransitionEnd={this.handleTransionEnd}
      >
        <List
          ref={this.listDom}
          className={listClassName}
          selectedIndex={selectedIndex}
          maxRowCount={maxRowCount}
          maxWidth={maxWidth}
          data={data}
          theme={theme}
          itemRender={itemRender}
          itemHeight={itemHeight}
          onSelect={onSelect}
          allowHover={allowHover}
        />
      </div>,
      document.body,
    );
  }
}

export default withAutoClose<IDropDownProp>(DropDown);
