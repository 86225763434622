import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { getNewID } from '@/helpers/idHelper';

import { CRectangle } from '../constants';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';
import { makeRect } from '../basic/Rect/config';

// 矩形
export default {
  ...flowBase,
  name: i18n('resource.flow.rectangle'),
  type: CRectangle,
  thumb: {
    spriteIconClass: SpriteThumb.Rectangle.className,
    dragPosition: SpriteThumb.Rectangle.position,
  },
  template: getShapeData(
    i18n('resource.flow.rectangle'),
    {
      width: 100,
      lockedRatio: false,
      height: 60,
    },
    {
      data: [
        {
          point: {
            x: 0,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 100,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 100,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
      closed: true,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};

export const makeRectangle = () => {
  return makeRect(getNewID(), {
    name: i18n('resource.flow.rectangle'),
    size: {
      width: 100,
      lockedRatio: false,
      height: 60,
    },
  });
};
