import { measureTextSize } from '@utils/textUtils';

import i18n from '@i18n';
import { makeCommonComponent } from '@/libs/helper';
import { makeIcon } from '@/libs/basic/Icon';
import { getNewID } from '@/helpers/idHelper';
import { StyleHelper } from '@helpers/styleHelper';
import {
  ITreeArrData,
  treeToArr,
  TreeItemAlias,
  TreeItemColumn,
  arrToTree,
  mapChildsIDByCompID,
} from '@/helpers/treeCompHelper';
import { SpriteThumb } from '@consts/spriteIcons';
import {
  DEFAULT_SIZE,
  DEFAULT_TEXT_STYLE,
  getDefaultCollapseData,
  DEFAULT_EXPAND_ICON,
} from '@/consts/defaultData/collapse';
import {
  DefaultStrokeColor1,
  ShadowColor,
  DefaultSelectColor,
  DisabledDarkFillColor,
  WhiteColor,
} from '@/consts/colors';
import { UIComponent } from '@/editor/comps';
import UICollapseComponent from '@/editor/comps/UICollapseComponent';
import { CPureText, CCollapse, CTreeItem } from '@libs/constants';
import { IComponentItem } from '@libs/types';
import { CollapsePropertyName, IconPosition } from '@/fbs/rp/models/properties/collapse';
import { ArtboardPatches, Ops, ArtboardOperations } from '@/fbs/rp/utils/patch';
import { IComponentData } from '@/fbs/rp/models/component';
import { HorizontalAlign, VerticalAlign } from '@/fbs/rp/models/layout';
import { PropertyValue } from '@/fbs/rp/models/property';
import { StrokeLineCap, StrokeLineJoin } from '@/fbs/rp/models/properties/stroke';
import { DefaultCollapseIconType } from '@/fbs/rp/models/properties/tree';
import { ITreeDataItem, ITreeData } from '@/fbs/rp/models/value';

function onPropertyUpdate(container: UIComponent, propertyName: string, newValue: PropertyValue): ArtboardPatches {
  return (container as UICollapseComponent).modifyValueWhenPropertyChange(propertyName, newValue);
}

function onClone(comp: IComponentData, idMaps: { [key: string]: string }) {
  const { expandACollapseIcon, relation } = comp.value as ITreeData;
  const newRelation = mapChildsIDByCompID(relation, idMaps);
  comp.value = { expandACollapseIcon, relation: newRelation };
}

function onTriggerState(
  container: UIComponent,
  triggerComp: UIComponent,
  stateName: string,
  value: any,
): ArtboardOperations | null {
  if (value !== TreeItemColumn.Expand || triggerComp.alias !== TreeItemAlias.ExpandIcon) {
    return null;
  }
  const options: ArtboardOperations = {};
  const treeComp = container as UICollapseComponent;
  const { relation } = treeComp.value as ITreeData;
  const isExpandOneItem = container.properties.expandOneItem?.value; // 只展开一项
  const arrTree = treeToArr(relation);
  const expandCompId = triggerComp.parent?.id || '';
  const expand = stateName === 'checked';
  const newArrTree = arrTree.map((item) => {
    if (item.id === expandCompId) {
      item.expand = expand;
    } else if (isExpandOneItem && item.expand && item.level === 0) {
      item.expand = !expand;
      const expandComp = treeComp.getItemCompById(item.id).expandComp;
      options[expandComp.id] = [Ops.replace('/selected', false), Ops.replace('/_currentState', 'unchecked')];
    }
    return item;
  });

  const newTreeData = arrToTree(newArrTree);
  options[container.id] = [Ops.replace('/value', { relation: newTreeData })];
  return options;
}

function makeItem(item: ITreeArrData) {
  const chartKey = `resource.componentsText.${item.level === 0 ? 'title' : 'content'}`;
  const text = `${i18n(chartKey)} ${item.index + 1}`;
  const parser = StyleHelper.initCSSStyleParser({ textStyle: DEFAULT_TEXT_STYLE });
  const textSize = measureTextSize(parser.getTextStyle(), text);

  return makeCommonComponent(item.id, CTreeItem, {
    size: { width: 300, height: 42 },
    position: { x: 0, y: 0 },
    components: [
      makeIcon(getNewID(), {
        alias: TreeItemAlias.ExpandIcon,
        column: TreeItemColumn.Expand,
        size: { width: 12, height: 12 },
        value: DEFAULT_EXPAND_ICON.collapse.icon,
        selected: !!item.expand,
        properties: {
          expand: { ref: '@properties.treeExpand' },
        },
        states: {
          checked: {
            enabled: true,
            properties: {},
            value: DEFAULT_EXPAND_ICON.expand.icon,
          },
        },
        select: {
          target: 'self',
          enabled: true,
          reversible: true,
        },
      }),
      makeCommonComponent(getNewID(), CPureText, {
        alias: TreeItemAlias.NodeText,
        size: textSize,
        column: TreeItemColumn.Text,
        value: text,
      }),
    ],
  });
}

export function makeCollapse(id: string): IComponentData {
  const treeItemData = getDefaultCollapseData();
  return makeCommonComponent(id, CCollapse, {
    ...CollapseCfg.getDefaultData?.(),
    alias: CCollapse,
    lib: {
      id: 'common',
      type: CCollapse,
    },
    size: DEFAULT_SIZE,
    sealed: true,
    layout: {
      vertical: VerticalAlign.Middle,
      horizontal: HorizontalAlign.Left,
      fixedWidth: true,
      fixedHeight: true,
      auto: true,
      responsive: false,
    },
    value: {
      expandACollapseIcon: DEFAULT_EXPAND_ICON,
      relation: treeItemData,
    },
    components: [
      ...treeToArr(treeItemData as ITreeDataItem[]).map((item) => {
        return makeItem(item);
      }),
    ],
    select: {
      target: 'child',
      minCount: 1,
      maxCount: 1,
      autoUnselect: true,
      reversible: true,
      enabled: true,
    },
  });
}

export const CollapseCfg: IComponentItem = {
  type: CCollapse,
  name: i18n('resource.components.collapse'),
  thumb: {
    spriteIconClass: SpriteThumb.Collapse.className,
    dragPosition: SpriteThumb.Collapse.position,
  },
  isList: true,
  isTextComp: true,
  editor: {
    onPropertyUpdate,
    onClone,
  },
  preview: {
    onTriggerState,
  },
  getDefaultData() {
    return {
      properties: {
        layout: {
          hidden: true,
          direction: 'horizontal',
          horizontalGap: 0,
          verticalGap: 0,
          disabled: false,
        },
        cell: {
          ratioWidth: true,
          hidden: true,
        },
        stroke: {
          thickness: 1,
          color: DefaultStrokeColor1,
          cap: StrokeLineCap.Round,
          join: StrokeLineJoin.Round,
          disabled: false,
        },
        shadow: {
          x: 0,
          y: 3,
          blur: 6,
          color: ShadowColor,
          disabled: true,
        },
        expandOneItem: {
          prop: 'boolean',
          name: i18n('property.propertyNames.expandOneItem'),
          value: false,
        },
        container: {
          scroll: true,
          showScroll: true,
        },
        separator: {
          thickness: 1,
          color: DefaultSelectColor,
          disabled: false,
        },
        treeExpand: {
          prop: CollapsePropertyName,
          disabled: false,
          iconSize: 12,
          chooseExpandType: DefaultCollapseIconType.Arrow,
          iconPosition: IconPosition.Left,
          iconColor: DefaultSelectColor,
        },
        verticalPadding: {
          prop: 'number',
          name: i18n('property.propertyNames.textVerticalPadding'),
          value: {
            min: 0,
            max: 100,
            value: 12,
            unit: 'px',
          },
        },
        titleBgColor: {
          prop: 'color',
          name: i18n('property.propertyNames.titleBgColor'),
          value: DisabledDarkFillColor,
        },
        contentBgColor: {
          prop: 'color',
          name: i18n('property.propertyNames.contentBgColor'),
          value: WhiteColor,
        },
        titleTextStyle: {
          name: i18n('property.propertyNames.titleTextStyle'),
          prop: 'textStyle',
          color: DefaultSelectColor,
          ...DEFAULT_TEXT_STYLE,
        },
        contentTextStyle: {
          name: i18n('property.propertyNames.contentTextStyle'),
          prop: 'textStyle',
          color: DefaultSelectColor,
          ...DEFAULT_TEXT_STYLE,
        },
      },
    };
  },
};
