import { svgToDataUri } from '../util';

/**
 * 加载image
 * @param uri
 * @returns
 */

export function makeImage(uri: string): Promise<HTMLImageElement> {
  // saveAs(uri, 'uri.txt');
  console.time('makeImage');
  return new Promise((resolve, reject) => {
    const image: HTMLImageElement = new Image();
    image.onload = function () {
      console.timeEnd('makeImage');
      resolve(image);
    };
    image.onerror = reject;
    image.src = uri;
  });
}

/**
 * 浏览器加载svg图片
 * @param svg
 * @param name
 */
export const makeImageBySvg = (svg: string, name?: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    const datauri = svgToDataUri(svg);
    function resolveImage() {
      // 避免动态图片的事件多次触发问题。
      image.onload = null;
      resolve(image);
    }
    //  fixme:chrome 一次加载多图时，最后几张，需要手动碰触页面，触发重绘,才会完成加载 (通过triggerImageload已处理)
    image.onload = resolveImage;
    image.onerror = () => {
      reject(name);
    };
    image.src = datauri;

    // image.decode().then(resolveImage)
  });
};
