import { measureTextSize } from '@utils/textUtils';
import { roundNumberObject } from '@utils/globalUtils';

import { IPosition } from '@fbs/common/models/common';
import { PropertyValue } from '@fbs/rp/models/property';
import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { TextPropertyName } from '@fbs/rp/models/properties/text';
import ITextFormatEx from '@fbs/rp/models/properties/textFormat';

import i18n from '@i18n';

import { UIComponent, UIContainerComponent } from '@editor/comps';
import { StyleHelper } from '@helpers/styleHelper';

import { PredefinedStates } from '@consts/state';
import { SpriteThumb } from '@consts/spriteIcons';
import { getDefaultDataFromTemplate } from '@/libs/helper';

import { IComponentItem } from '../../types';
import { CPureText } from '../../constants';
import { SizeMode } from '../../enum';

import initCSSStyleParser = StyleHelper.initCSSStyleParser;

interface ISelectTabConfig {
  type: string;
  name: string;
  thumb: {
    spriteIconClass?: string;
    dragPosition?: IPosition;
    x1?: string;
    x2?: string;
    drag_x1?: string;
    drag_x2?: string;
  };
  values: string[];
  itemSize: {
    width: number;
    height: number;
  };
}

export const DEFAULT_INDICATOR_WIDTH = 40; // 默认标识条宽度

function onPropertyUpdate(container: UIComponent, propertyName: string, value?: PropertyValue): ArtboardPatches | null {
  if (propertyName === TextPropertyName) {
    const tab = container as UIContainerComponent;
    const textStyle = value as ITextFormatEx;
    const style = initCSSStyleParser({}).doGetTextStyle(textStyle);
    const patches: ArtboardPatches = { do: {}, undo: {} };
    tab.components.forEach((item) => {
      const itemContainer = item as UIContainerComponent;
      const textComp =
        itemContainer.getComponentByAlias('text') || itemContainer.components.find((item) => item.type === CPureText);
      const { size: oldSize, position } = textComp!;
      const text = textComp!.value as string;
      const { width, height } = measureTextSize(style, text, {});
      const { size } = itemContainer;
      const x = Math.round((size.width - width) / 2);
      const y = Math.round((size.height - height) / 2);
      patches.do[textComp!.id] = [
        Ops.replace('./size', roundNumberObject({ ...oldSize, width, height })),
        Ops.replace('./position', roundNumberObject({ x, y })),
      ];
      patches.undo[textComp!.id] = [Ops.replace('./size', oldSize), Ops.replace('./position', position)];
    });
    return patches;
  }
  return null;
}

function makeData(cfg: ISelectTabConfig): IComponentItem {
  const { type, name, thumb, values, itemSize } = cfg;
  const parser = StyleHelper.initCSSStyleParser({
    textStyle: {
      fontSize: 14,
      fontFamily: 'Microsoft YaHei',
    },
  });

  const { width: textWidth, height: textHeight } = measureTextSize(
    parser.getTextStyle(),
    `${i18n('resource.componentsText.optionText')} 2`,
  );

  return {
    type: type,
    name: name,
    thumb: thumb,
    isList: true,
    isTextComp: true, // 数据填充文本-判断需要
    editor: {
      onAddChildren,
      onPropertyUpdate,
    },
    constraint: {
      text: {
        resize: SizeMode.none,
      },
      indicator: {
        resize: SizeMode.vertical,
      },
    },

    template: `
      {
        type:  @@C.ListLayoutPanel,
        sealed: true,
        size: { width: 270, height: 30},
        layout:{
          vertical: 'middle',
          horizontal: 'left',
          fixedWidth: true,
          fixedHeight: true,
          auto: true,
          responsive: true,
        },
        select:{
          target: 'child',
          minCount: 1,
          maxCount: 1,
          reversible: false,
          autoUnselect: true,
          enabled: true,
        },
        properties: {
          layout: {
            hidden: true,
            direction: 'horizontal',
            vertical: 'middle',
            horizontal: 'center',
            horizontalGap: 0,
            verticalGap: 0,
            disabled: false,
          },
          cell:{
            ratioWidth: true,
            hidden: true,
          },
          background: {
            prop:'fill',
            type: @@FillType.solid,
            color: @@SystemColors.DefaultWhiteFillColor,
            disabled: false,
          },
          fill2: {
            prop: 'fill',
            name: '${i18n('property.propertyNames.checkedBgcolor')}',
            type: @@FillType.solid,
            color: @@SystemColors.DefaultWhiteFillColor,
            disabled: false,
          },
          separator: {
            thickness: 1,
            color:@@SystemColors.DefaultSelectColor,
            disabled: true,
          },
          separatorRatio: {
            prop: 'number',
            type: 'number',
            name: '${i18n('property.propertyNames.separatorRatio')}',
            value: {
              value: 100,
              unit: '%',
            }
          },
          textStyle: {
            disabled: false,
            color: @@SystemColors.DefaultTextColor,
            fontSize: 14,
            textAlign:@@TextAlign.center,
            fontFamily: 'Microsoft YaHei',
            fontStyle: { underline: false, bold: false, strike: false, italic: false },
          },
          textStyleChecked: {
            prop: 'textStyle',
            name: '${i18n('property.propertyNames.checkedText')}',
            color: @@SystemColors.DeepBlueColor,
            fontFamily: 'Microsoft YaHei',
            fontSize: 14,
            textAlign:@@TextAlign.center,
            disabled: false,
          },
          fillChecked: {
            prop: 'fill',
            name: '${i18n('property.propertyNames.checkedUnderbar')}',
            type: @@FillType.solid,
            color: @@SystemColors.DeepBlueColor,
            disabled: false,
          },
          stroke: {
            thickness: 1,
            color: @@SystemColors.DefaultStrokeColor1,
            cap: 'butt',
            join: 'miter',
            position: 'inner',
            mode: 'custom',
            disabled: true,
          },
          shadow: {
            disabled: true,
            hidden: false,
            x: 0,
            y: 3,
            blur: 6,
            color:  @@SystemColors.ShadowColor,
          },
          radius: {
            topRight: 0,
            topLeft: 0,
            bottomLeft: 0,
            bottomRight: 0,
            isPercent: false,
            disabled: false,
          },
          border: {
            left: true,
            top: true,
            right: true,
            bottom: true,
          },
        },
        components: [
          ${values
            .map((value, i) => {
              if (i === 0) {
                return `@@ITEM-${value}|true|0,`;
              } else if (i === values.length - 1) {
                return `@@ITEM-${value}|false|${itemSize.width * i},`;
              } else {
                return `@@ITEM-${value}|false|${itemSize.width * i},`;
              }
            })
            .join('\n')}
        ]
      }
    `,
    item: `
      {
        type: @@C.CanvasPanel,
        properties:{
          fill: { ref: '@properties.background' },
        },
        size: {
          width: ${itemSize.width}, 
          height: ${itemSize.height}
        },
        position:{
          x: {2},
          y: 0,
        },
        selected: {1},
        states: {
          @@PredefinedStates.checked: {
            enabled: true,
            properties: {
              fill: {
                ref: '@properties.fill2',
              },
            },
          },
        },     
        layout:{
          vertical: @@VerticalAlign.TopAndBottom,
          horizontal: @@HorizontalAlign.Auto,
          fixedWidth: false,
          fixedHeight: false,
          auto: false,
          responsive: true,
        },
        components: [
          {
            type: @@C.PureText,
            alias:'text',
            position:{
              x: ${Math.round((itemSize.width - textWidth) / 2)}, 
              y: 7
            },
            autoSize: true,
            size: {
              width: ${textWidth}, 
              height: ${textHeight}
            },
            layout: {
              vertical: @@VerticalAlign.Middle,
              horizontal: @@HorizontalAlign.Center,
              fixedWidth: false,
              fixedHeight: false,
              auto: false,
              responsive: true,
            },
            properties:{
              textStyle: { ref: '@properties.textStyle' },
            },
            states: {
              @@PredefinedStates.checked: {
                enabled: true,
                properties: {
                  textStyle: { ref: '@properties.textStyleChecked' },
                },
              },
            },
            value:'{0}',
            
          },
          {
            type: @@C.Rect,
            size: {width: ${DEFAULT_INDICATOR_WIDTH}, height: 4},
            alias: 'indicator',
            position:{
              x: ${Math.round((itemSize.width - 40) / 2)},
              y: ${itemSize.height - 4},
            },
            layout: {
              vertical: 'bottom',
              horizontal: 'center',
              auto: false,
              fixedWidth: true,
              fixedHeight: true,
              responsive: true,
            },
            properties: {
              fill:{
                type: 'solid',
                color: {r: 255, g: 255, b: 255, a: 0},
                disabled: true,
              },
              radius:{
                disabled: true,
                topLeft: 0,
                topRight: 0,
                bottomLeft: 0,
                bottomRight: 0,
                isPercent: false,
              }
            },
            states: {
              @@PredefinedStates.checked: {
                enabled: true,
                properties: {
                  fill: { ref: '@properties.fillChecked',},
                },
              },
            }, 
          }
        ]
      }
    `,
    itemArgs: [
      {
        type: 'string',
        value: i18n('resource.componentsText.optionText'),
      },
      {
        type: 'boolean',
        value: false,
      },
      {
        type: 'number',
        value: 0,
      },
    ],
    predefinedStates: [PredefinedStates.disabled],
    getDefaultData() {
      return {
        properties: defaultData?.properties,
      };
    },
  };
}

// eslint-disable-next-line no-unused-vars
function onAddChildren() {
  return null;
}

export const TabsCfg = makeData({
  type: 'selectTab',
  name: i18n('resource.components.selectTab'),
  thumb: {
    spriteIconClass: SpriteThumb.TabBar.className,
    dragPosition: SpriteThumb.TabBar.position,
  },
  values: [
    `${i18n('resource.componentsText.optionText')} 1`,
    `${i18n('resource.componentsText.optionText')} 2`,
    `${i18n('resource.componentsText.optionText')} 3`,
  ],
  itemSize: { width: 90, height: 30 },
});

const defaultData = getDefaultDataFromTemplate(TabsCfg);
