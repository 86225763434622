/**
 * 统计工具
 */

import { sortByLength } from '.';

interface IStatisticItem {
  name: string;
  size: number;
}

interface IStatisticValues {
  name: string;
  values: string[];
}

class ExportImagesStatistics {
  private originSize?: number = 0;
  private reduceList: IStatisticItem[] = [];
  private svgList: IStatisticItem[] = [];
  private blobList: IStatisticItem[] = [];
  private timeList: IStatisticItem[] = [];
  private timeId = 'ExportImagesStatistics';
  private fontList: IStatisticValues[] = [];
  private classnameList: IStatisticValues[] = [];
  isDebug() {
    return window.debug;
  }
  begin(size?: number) {
    this.originSize = size;
    this.reset();
    console.time(this.timeId);
  }
  add(list: (IStatisticValues | IStatisticItem)[], item: IStatisticValues | IStatisticItem) {
    this.isDebug && list.push(item);
  }

  /**
   * 统计svg大小
   * @param name
   * @param size
   */
  addSvg(name: string, size: number) {
    this.add(this.svgList, {
      name,
      size,
    });
  }
  /**
   * 统计图片大小
   * @param name
   * @param size
   */
  addBlob(name: string, size: number) {
    this.add(this.blobList, {
      name,
      size,
    });
  }

  /**
   * 统计时间
   * @param name
   * @param size
   */
  addTime(name: string, size: number) {
    this.add(this.timeList, {
      name,
      size,
    });
  }

  /**
   * 统计字体
   * @param name
   * @param values
   */
  addFont(name: string, values: string[]) {
    this.add(this.fontList, {
      name,
      values,
    });
  }

  addClassname(name: string, values: string[]) {
    this.add(this.classnameList, {
      name,
      values,
    });
  }

  /**
   * 统计减少
   * @param name
   * @param size
   */
  addReduce(name: string, size: number) {
    this.add(this.reduceList, {
      name,
      size,
    });
  }

  report() {
    const totalReduce = this.reduceList.reduce((tmp, curr) => {
      return tmp + curr.size;
    }, 0);
    const totalSvg = this.svgList.reduce((tmp, curr) => {
      return tmp + curr.size;
    }, 0);
    const totalBlob = this.blobList.reduce((tmp, curr) => {
      return tmp + curr.size;
    }, 0);
    const totalTime = this.timeList.reduce((tmp, curr) => {
      return tmp + curr.size;
    }, 0);
    const fontGrid = this.fontList.map((t) => t.values);
    const fontSet = new Set<string>();
    this.fontList.forEach((t) => {
      t.values.length && t.values.forEach((v) => fontSet.add(v));
    });

    const classnameGrid = this.classnameList.map((t) => t.values);
    const classnameSet = new Set<string>();
    this.classnameList.forEach((t) => {
      t.values.length && t.values.forEach((v) => classnameSet.add(v));
    });

    if (window.debug) {
      console.log('images number:' + this.svgList.length);
      this.originSize && console.log('origin size:' + this.originSize / 1024 + ' kb');
      console.log('reduce size:' + totalReduce / 1024 + ' kb');
      console.log('svg size:' + totalSvg / 1024 + ' kb');
      console.log('blob size:' + totalBlob / 1024 + ' kb');
      console.log('accumulation time:' + totalTime / 1000 + 's');
      console.log('all fonts:', Array.from(fontSet), fontGrid);
      console.log('all classnames:', sortByLength(Array.from(classnameSet)), classnameGrid);
      console.timeEnd(this.timeId);
    }
  }
  reset() {
    this.reduceList = [];
    this.svgList = [];
    this.blobList = [];
  }
}
export const globalStatistics = new ExportImagesStatistics();
