import i18n from '@/i18n';
import { IComponentItem } from '@/libs/types';
import { CContentPanel } from '@/libs/constants';
import { SpriteThumb } from '@/consts/spriteIcons';
import { UIContainerComponent, UIComponent } from '@/editor/comps';
import { ArtboardPatches, Ops } from '@/fbs/rp/utils/patch';
import { makeCommonComponent } from '@/libs/helper';
import { ScrollMode } from '@/consts/enums/scrollMode';
import { FillType } from '@/fbs/rp/models/properties/fill';
import { DefaultStrokeColor } from '@/consts/colors';
import { DefaultBorder } from '@/consts/border';

export const ContentPanelConfig: IComponentItem = {
  type: CContentPanel,
  name: i18n('resource.components.contentPanel'),
  thumb: {
    spriteIconClass: SpriteThumb.ContentPanel.className,
    dragPosition: SpriteThumb.ContentPanel.position,
  },
  editor: {
    onChildSelectedChange: changeContentPanelSelect,
  },
  getDefaultData() {
    return {
      properties: {
        container: {
          scroll: true,
          scrollMode: ScrollMode.Both,
          showScroll: true,
        },
        fill: {
          type: FillType.solid,
          color: '#fff',
          disabled: true,
        },
        stroke: {
          thickness: 1,
          color: DefaultStrokeColor,
          disabled: true,
        },
        border: DefaultBorder,
      },
    };
  },
};

function changeContentPanelSelect(
  group: UIContainerComponent,
  comp: UIComponent,
  selected: boolean,
): ArtboardPatches | null {
  let patches: ArtboardPatches = { do: {}, undo: {} };
  const selComp = group.components.find((comp) => comp.selected);
  if (!selected || selComp?.id === comp.id) {
    return null;
  }

  group.components.forEach((item) => {
    if (item.selected) {
      patches.do[item.id] = [Ops.replace('/selected', false)];
      patches.undo[item.id] = [Ops.replace('/selected', true)];
    }
    if (item.id === comp.id) {
      patches.do[item.id] = [Ops.replace('/selected', true)];
      patches.undo[item.id] = [Ops.replace('/selected', false)];
    }
  });

  return patches;
}

export function makeContentPanel(id: string) {
  return makeCommonComponent(id, CContentPanel, {
    size: { width: 300, height: 200 },
    sealed: true,
    properties: {
      // 因为旧数据，暂保留
      container: {
        scroll: true,
        scrollMode: ScrollMode.Both,
        showScroll: true,
      },
    },
    value: [],
  });
}
