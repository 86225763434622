import { depthClone } from '@utils/globalUtils';
import { enumToArray } from '@utils/enumUtils';

import IArtboard from '@fbs/rp/models/artboard';
import { ArtboardOperations, Operation, Ops, PageOperations, applyOperations } from '@fbs/rp/utils/patch';
import {
  IAnimation,
  IEventHandle,
  IFragmentAction,
  IPageAction,
  IScrollCommandParams,
  PageSkipEffectType,
} from '@fbs/rp/models/interactions';
import INode from '@fbs/rp/models/node';
import { EventTypes } from '@fbs/rp/models/event';
import { ISize } from '@fbs/common/models/common';

import { isPredefinedState, PredefinedStates } from '@consts/state';

import Doc from '@editor/document';
import {
  UIComponent,
  UIContainerComponent,
  UIContentPanelComponent,
  UIContentPanelV2Component,
  UIFragment,
  UISymbolComponent,
} from '@editor/comps';

import { isMobileDevice } from '@helpers/previewHelper';
import { extractContentPanels, getFragmentDataFromDoc, traverseComponents } from '@/helpers/componentHelper';
import { getSizeFromOrientation, getOrientationFromSize } from '@/helpers/artboardDimensionHelper';

import { CSelect, CMultipleSelect } from '@libs/constants';

import WorkerManager from './WorkerManager';
import { IWorkerExtensionFeature, IWorkManagerBase } from './types';
import { ComponentAvlTree } from './ComponentAvlTree';
import CollectActions from './CollectActions';

class Theater {
  public doc!: Doc;
  public docMap = new Map<string, Doc>();
  public usedLibsID?: string[];
  public appID: string;

  //原始的artboards，请勿使用这个参数，要使用请使用artboards = depthClone(originArtboards)
  private readonly originArtboards: IArtboard[] = [];
  readonly nodeID: string;
  private componentTree: ComponentAvlTree<string, UIComponent> = new ComponentAvlTree<string, UIComponent>(
    (a: string, b: string) => a.localeCompare(b),
  );

  private workerManager?: IWorkManagerBase;
  public animationEffect?: {
    params: PageSkipEffectType;
    animation: IAnimation;
  };

  public activeFragmentAction?: IFragmentAction;

  public hasBack?: boolean;
  public hasNext?: boolean;

  /**
   * 是否执行页面跳转载入时
   */
  public doLoaded?: boolean;

  // onFragmentOverlay方法是否被初始化
  public fragmentOverlayInitialized?: boolean;

  public updateListener?: () => void;

  // private revertActions: { [compID: string]: IActionBase[] };

  private compTargetFlag: boolean = false;

  /**
   * 当一个交互目标为页面跳转时，需要执行的方法
   */
  public onSkipToPage?: (action: IPageAction, currentPageId?: string) => void;

  /**
   * 滚动到，需要执行的方法
   */
  public onScrollToComp?: (targetID: string, param: IScrollCommandParams, duration: number) => void;

  /**
   * 页面跳转动画执行完成之后调用
   */
  public onAfterSkipPage?: (nextId?: string) => void;

  /**
   * 当执行页面前进\后退\返回首页，需要执行的方法
   */
  public onBackward?: () => void;
  public onForward?: () => void;
  public onHome?: (homeID: string, artboardID: string) => void;

  /**
   * 当执行画板动画，需要执行的方法
   */
  public onBeginFragmentAction?: (fragmentAction: IFragmentAction) => void;
  public onEndFragmentAction?: (fragmentAction: IFragmentAction) => void;

  private contentPanels: UIComponent[] = [];

  readonly multiFragments = false;

  constructor(
    appID: string,
    artboards: IArtboard[],
    nodeID: string,
    private allNodes: INode[],
    private pageSize: ISize,
  ) {
    this.appID = appID;
    this.originArtboards = artboards;
    this.nodeID = nodeID;
    this.initTheater();
  }

  get fragmentCommandMap() {
    return this.workerManager?.fragmentCommandMap;
  }

  get pageID() {
    return this.doc.pageID;
  }

  get getAllFragments(): UIFragment[] {
    return Array.from(this.docMap.values()).reduce((res: UIFragment[], curr) => {
      return [...res, ...curr.fragments];
    }, []);
  }

  get cloneArtboards() {
    return depthClone(this.originArtboards);
  }

  get artboards() {
    return new Proxy(this.originArtboards, {
      get(target, key, receiver) {
        return Reflect.get(target, key, receiver);
      },
    });
  }

  public reset() {
    this.initTheater();
  }

  private initTheater() {
    this.doc = this.createDoc(this.appID, this.cloneArtboards, this.nodeID);
    // 主画板应放在最前面，使它的内容面板最先被 load ，不然会重复 load
    this.contentPanels = this.extractContentPanels([this.doc.mainArtboard, ...this.doc.fragments]);
    // 要先 updateContentPanel，再 buildTree，保证克隆副本能插入树中。
    this.updateContentPanel();
    this.updateTree(true);
    this.compTargetFlag = true;
    this.workerManager = new WorkerManager(this.doc, this.componentTree, depthClone(this.allNodes));
    this.updateWorkerManagerExtensionFeature({
      patch: this.patch.bind(this),
    });
  }

  /**
   * 创建doc数据
   * @param appID 项目ID
   * @param artboards 画板数据
   * @param nodeID 页面ID
   */
  private createDoc(appID: string, artboards: IArtboard[], nodeID: string): Doc {
    const artboardsData = depthClone(artboards);
    const usedLibsID: string[] = [];

    this.reCalcFloatCompData(artboardsData);

    const doc = new Doc(appID, nodeID, artboardsData, { isPreview: true });
    this.docMap.set(nodeID, doc);
    doc.artboardsFragments.forEach((fragment) => {
      traverseComponents(fragment, (comp) => {
        if (comp instanceof UISymbolComponent && comp.symbolInfo) {
          const { masterID } = comp.symbolInfo;
          if (!usedLibsID.includes(masterID)) {
            usedLibsID.push(masterID);
          }
        }
      });
    });
    this.usedLibsID = usedLibsID;
    return doc;
  }

  private reCalcFloatCompData(artboards: IArtboard[]) {
    const mainArtboard = artboards.find(({ type }) => type === 'main');
    if (!mainArtboard) {
      return;
    }
    let shellScale = 1;
    // 移动端项目需要计算缩放影响
    if (isMobileDevice()) {
      const orientation = getOrientationFromSize(mainArtboard.size);
      const appSize = getSizeFromOrientation(orientation, this.pageSize);
      shellScale = window.innerWidth / appSize.width;
    }
    const { height, width } = mainArtboard.size;
    const simulationScreenWidth = isMobileDevice() ? window.innerWidth : this.pageSize.width;
    const simulationScreenHeight = isMobileDevice() ? window.innerHeight : this.pageSize.height;
    mainArtboard?.components?.forEach((comp) => {
      if (comp.float) {
        const {
          position: { y, x },
          size: { height: compHeight, width: compWidth },
        } = comp;
        const bottom = y + compHeight;
        if (y > simulationScreenHeight) {
          // 以项目尺寸分屏
          // 位于最末尾一屏时
          if (y >= height - simulationScreenHeight) {
            const bottomOffset = height - bottom;
            comp.position.y = simulationScreenHeight / shellScale - bottomOffset - compHeight;
          } else {
            comp.position.y = y % (simulationScreenHeight / shellScale);
          }
        }
        if (x > simulationScreenWidth) {
          let right = x + compWidth;
          if (x >= width - simulationScreenWidth) {
            comp.position.x = simulationScreenWidth - (width - right) - compWidth;
          } else {
            comp.position.x = x % simulationScreenWidth;
          }
        }
      }
    });
  }

  private extractContentPanels(fragments: UIFragment[]) {
    return fragments.reduce<UIComponent[]>((prevContentPanels, artboard) => {
      return prevContentPanels.concat(extractContentPanels(artboard));
    }, []);
  }

  public setName(value: string) {
    this.workerManager?.setName(value);
  }

  /**
   * 创建多个doc
   * @param appID
   * @param artboards
   * @param nodeID
   */
  public makeDocToDocs(appID: string, artboards: IArtboard[], nodeID: string) {
    if (this.docMap.has(nodeID)) {
      return;
    }
    this.createDoc(appID, artboards, nodeID);
    this.updateTree(true);
    // const doc = this.createDoc(appID, [...this.doc.artboards, ...artboards], nodeID);
    // this.doc = doc;
  }

  public resetWorkerManager() {
    this.workerManager?.reset();
  }

  public updateWorkerManagerExtensionFeature = (data: IWorkerExtensionFeature) => {
    if (this.workerManager) {
      this.workerManager.initExtensionFeature(data);
    }
  };

  public closeFragmentByAction(action: IFragmentAction, forceUpdate: () => void) {
    const {
      target: id,
      params: { showBackground, closeWithOutClick },
    } = action;
    if (!showBackground || !closeWithOutClick || !this.fragmentCommandMap) {
      return;
    }
    this.fragmentCommandMap.delete(id);
    forceUpdate();
  }

  /**
   * @deprecated
   * 构建组件树
   * @param {UIComponent} component
   */
  doCreateTreeNode = (component: UIComponent) => {
    // 更新 v
    component.toJSON().v = Math.round(Math.random() * 100);
    // 插入 comp
    const id = component.toJSON()._id;
    if (this.componentTree.contains(id)) {
      this.componentTree.insertElementToNodeValue(id, component);
    } else {
      this.componentTree.insert(id, [component]);
    }

    if (component.isContainer) {
      // 更新 contentPanels
      if (component instanceof UIContentPanelComponent || component instanceof UIContentPanelV2Component) {
        this.contentPanels.push(component);
      }

      (component as UIContainerComponent).components.forEach(this.doCreateTreeNode);
    }
  };

  /**
   * @deprecated
   * 可使用 theater.updateTree
   */
  refreshTree = () => {};

  updateTree = (updateV = false) => {
    // 将克隆源 comp 插入树中，在组件级别通知 update 时会用到。
    // 在处理主画板前插入，似的 comp 插在同 id comp 数组的第一个位置。
    this.getAllFragments.forEach((item) => this.insertTreeNode(item, updateV));
    this.insertTreeNode(this.doc.mainArtboard, updateV);
  };

  insertTreeNode = (component: UIComponent, updateV: boolean) => {
    const id = component.toJSON()._id;
    if (updateV) {
      // 更新 v, 防止不同页面相同id的组件不更新（页面克隆）
      component.toJSON().v = Math.ceil(Math.random() * 255);
    }
    if (this.componentTree.contains(id)) {
      this.componentTree.insertElementToNodeValue(id, component);
    } else {
      this.componentTree.insert(id, [component]);
    }
    if (component.isContainer) {
      (component as UIContainerComponent).components.forEach((item) => this.insertTreeNode(item, updateV));
    }
  };

  // 同步 doc.tree 中 comp.data 的 patch 到 theater.componentTree 中的 comp.data 中，用于渲染
  patchTree = (pageOperations: PageOperations) => {
    for (let artboardID in pageOperations) {
      const artboardOperations = pageOperations[artboardID];
      for (const compID in artboardOperations) {
        // 忽略画板操作
        if (compID === 'ROOT' || compID === 'self') {
          continue;
        }
        // 更新所有 comp 副本对象
        const comps = this.componentTree.get(compID);
        comps?.forEach((comp) => {
          const compData = comp.toJSON();
          if (compData) {
            // TODO 同步源 comp 与副本的 v
            applyOperations(compData, artboardOperations[compID]);
          }
        });
      }
    }
  };

  afterLoaded = () => {
    this.doLoaded = false;
    this.updateListener && this.updateListener();
  };

  /**
   * 执行页面跳转动画结束
   */
  pageAnimationEnd() {
    if (this.onAfterSkipPage) {
      this.compTargetFlag = true;
      this.onAfterSkipPage();
    }
  }

  /**
   * 执行上一页
   */
  goBackward() {
    if (this.onBackward) {
      // this.removeFragmentRevertAction();
      this.onBackward();
    }
  }

  /**
   * 执行下一页
   */
  goForward() {
    if (this.onForward) {
      // this.removeFragmentRevertAction();
      this.onForward();
    }
  }

  /**
   * 执行返回首页
   * @param {string} homeID
   * @param {string} artboardID
   */
  goHome(homeID: string, artboardID: string) {
    if (this.onHome) {
      // this.removeFragmentRevertAction();
      this.onHome(homeID, artboardID);
    }
  }

  /**
   * 移除所有画板交互动画
   */
  removeFragmentAction = () => {
    // if(this.activeFragmentAction){
    this.activeFragmentAction = undefined;
    // }
  };

  // /**
  //  * 移除所有画板交互还原动画
  //  */
  // removeFragmentRevertAction = () => {
  //   Object.values(this.revertActions).forEach((item: IActionBase[]) => {
  //     item &&
  //       item.forEach((action) => {
  //         if (action.type === 'fragment') {
  //           const index = item.indexOf(action);
  //           index != -1 && item.splice(index, 1);
  //         }
  //       });
  //   });
  // };

  start(trigger: UIComponent | UIFragment, event: string): boolean {
    const handle: IEventHandle = trigger.interactions[event];
    // 统计动画总时长
    if (!handle || !handle.active) {
      return false;
    }
    // 页面跳转过程
    if (!this.compTargetFlag) {
      return false;
    }
    // 触发事件类型不对
    if (!enumToArray(EventTypes).includes(event)) {
      return false;
    }
    if (handle.actions.find((item) => item.type === 'page')) {
      CollectActions.collect<UIComponent | UIFragment, string>(trigger, event)?.then((collectionsData) => {
        const data = Array.from(collectionsData)[0];
        this.workerManager?.startWorker(data[0], data[1] as EventTypes);
        CollectActions.clear();
      });
    } else {
      this.workerManager?.startWorker(trigger, event as EventTypes);
    }
    return true;
  }

  termination() {
    this.workerManager?.termination();
  }

  /**
   * @deprecated
   *  添加数据更新监听者事件
   */
  addUpdateListener(fn: () => void) {
    this.updateListener = fn;
  }

  /**
   * @deprecated
   *  移除数据更新监听者事件
   */
  removeUpdateListener() {
    this.updateListener = undefined;
  }

  protected notifyUpdateToUI(updatedCompIDs: string[]) {
    let needForceUpdate = false;

    updatedCompIDs.forEach((compID) => {
      const comps = this.componentTree.get(compID);
      // 更新视图
      comps?.forEach((comp) => {
        let updateComponentView: UIComponent['updateComponentView'];
        let current: UIComponent | undefined = comp;
        // 寻找最近的 lib Component 的 updateComponentView
        while (!updateComponentView && current) {
          updateComponentView = current?.updateComponentView;
          current = current?.parent;
        }
        if (updateComponentView) {
          updateComponentView(false);
        } else {
          needForceUpdate = true;
          !comp.parent?.isArtboard && console.warn('No updateComponentView: ', updateComponentView);
        }
      });
    });

    needForceUpdate && this.updateListener && this.updateListener();
  }

  protected updateContentPanel() {
    this.contentPanels.forEach((comp) => {
      (comp as UIContentPanelComponent).loadContentByPreview([], this.findFragmentData);
    });
  }

  private contentPanelCache: { [compID: string]: IArtboard } = {};

  private findFragmentData = (refCompID: string, fragmentID: string): IArtboard => {
    let data: IArtboard = this.contentPanelCache[refCompID];
    if (!data) {
      this.contentPanelCache[refCompID] = data = getFragmentDataFromDoc(this.doc, fragmentID);
    }
    return data;
  };

  extractUpdatedCompIDsFromPageOperations = (pageOperations: PageOperations): string[] => {
    const updatedCompIDs: string[] = [];
    for (let artboardID in pageOperations) {
      const artboardOperations = pageOperations[artboardID];
      for (const compID in artboardOperations) {
        if (compID === 'ROOT' || compID === 'self') {
          continue;
        }
        updatedCompIDs.push(compID);
      }
    }
    return updatedCompIDs;
  };

  /**
   * 提交更新
   * FIXME: 不能直接使用 patch main artboard 了
   */
  patch(patches: PageOperations) {
    this.doc.patchArtboard(patches, () => {
      // 好像在演示时这个没必要，因为不会有画板数据增删
      this.doc.updateAppDataManagerArtboardsByOperations();
      // 检查这个，symbol comp 已在树中，走下面的 patchTree 即可
      this.patchTree(patches);
      this.notifyUpdateToUI(this.extractUpdatedCompIDsFromPageOperations(patches));
    });
  }

  triggerInnerAction(innerActions: IInnerActionInfo[]) {
    const source: PageOperations = {};
    const patches: PageOperations = innerActions.reduce((acc, curr) => {
      const artboardID = curr.comp.ownerArtboardID;
      if (acc[artboardID]) {
        acc[artboardID][curr.comp.id] = curr.actions;
      } else {
        acc[artboardID] = { [curr.comp.id]: curr.actions };
      }
      return acc;
    }, source);
    this.patch(patches);
  }

  /**
   * 更新兄弟组件状态
   * @param {UIComponent} comp
   * @returns {ArtboardOperations}
   */
  private static doUnselectSiblingComps(comp: UIComponent): ArtboardOperations {
    const parent = comp.parent!;
    const select = parent.select;
    const patches: ArtboardOperations = {};
    if (select && select.target === 'child') {
      const { maxCount, autoUnselect } = select;
      if (maxCount !== -1) {
        const parentSelectComps = parent.components.filter((c) => c.selected);
        if (parentSelectComps.length !== 0 && parentSelectComps.length >= maxCount) {
          if (autoUnselect) {
            while (parentSelectComps.length >= maxCount) {
              const siblingComp = parentSelectComps[0];
              parentSelectComps.shift();
              patches[siblingComp.id] = [
                Ops.replace('./selected', false),
                Ops.replace('./_currentState', PredefinedStates.normal),
              ];
            }
          }
        }
      }
    }
    return patches;
  }

  /**
   * 特殊组件状态更新
   * @param {UIComponent} comp
   * @returns {{}}
   */
  private static doUpdateSpecialComponent(comp: UIComponent, checked = PredefinedStates.checked) {
    const container = Theater.getSelectBoxChild(comp);
    if (container) {
      const fn = container.libData?.preview?.onTriggerState;
      if (fn) {
        return fn(container, comp, checked, comp.value);
      }
    }
    return null;
  }

  private static getSelectBoxChild(comp: UIComponent): UIContainerComponent | null {
    let parent = comp.parent;
    let isSelectBox = false;
    while (parent) {
      if ([CSelect, CMultipleSelect].includes(parent.type)) {
        isSelectBox = true;
        break;
      }
      parent = parent.parent;
    }
    if (isSelectBox) {
      return parent as UIContainerComponent;
    }
    return null;
  }

  private doChangeSiblingSelect = (comp: UIComponent, stateName: string): ArtboardOperations | null => {
    const sealed = comp.isSealed ? comp : comp.nearestSealedComponent;
    if (sealed) {
      const fn = sealed.libData?.preview?.onTriggerState;
      if (fn) {
        const index = comp === sealed ? -1 : comp.parent!.components.indexOf(comp);
        return fn(sealed, comp, stateName, index);
      }
    }
    return null;
  };

  private static mergeOperation(source: ArtboardOperations, target: ArtboardOperations | null) {
    if (target) {
      Object.keys(target).forEach((id) => {
        if (source[id]) {
          source[id].push(...target[id]);
        } else {
          source[id] = target[id];
        }
      });
    }
  }

  /**
   * 组件选中
   * @param {UIComponent} comp
   */
  private doSelectedState(comp: UIComponent) {
    if (comp.selectable) {
      const patches: ArtboardOperations = {
        [comp.id]: [Ops.replace('./selected', true), Ops.replace('./_currentState', PredefinedStates.checked)],
      };
      const siblingOptions = Theater.doUnselectSiblingComps(comp);
      const specialPatches = Theater.doUpdateSpecialComponent(comp);
      const customPatches = this.doChangeSiblingSelect(comp, PredefinedStates.checked);
      Theater.mergeOperation(patches, siblingOptions);
      Theater.mergeOperation(patches, specialPatches);
      Theater.mergeOperation(patches, customPatches);
      this.patch({ [comp.ownerArtboardID]: patches });
    }
  }

  /**
   * 组件未选中
   * @param {UIComponent} comp
   */
  private doUnSelectState(comp: UIComponent) {
    if (comp.unselectable) {
      const patches = {
        [comp.ownerArtboardID]: {
          [comp.id]: [Ops.replace('./selected', false), Ops.replace('./_currentState', PredefinedStates.normal)],
        },
      };
      const specialPatches = Theater.doUpdateSpecialComponent(comp, PredefinedStates.unchecked); // 多选组件的反选处理
      const customPatches = this.doChangeSiblingSelect(comp, PredefinedStates.unchecked);
      Theater.mergeOperation(patches[comp.ownerArtboardID], specialPatches);
      Theater.mergeOperation(patches[comp.ownerArtboardID], customPatches);
      this.patch(patches);
    }
  }

  // 鼠标mouseUp，清除pressed状态
  clearPressedState(comp: UIComponent) {
    if (comp.hasActiveState(PredefinedStates.pressed) && comp._currentState === PredefinedStates.pressed) {
      // 当前组件被选中，重置为选中状态，否则默认状态
      const newState = comp.selected ? PredefinedStates.checked : PredefinedStates.normal;
      const patches = {
        [comp.ownerArtboardID]: {
          [comp.id]: [Ops.replace('./_currentState', newState)],
        },
      };
      this.patch(patches);
    }
  }

  /**
   * 更改组件预定状态
   * @param {UIComponent} comp
   * @param {PredefinedStates} stateName
   */
  private doChangePredefinedState(comp: UIComponent, stateName: PredefinedStates) {
    const { states, _currentState } = comp.toJSON();
    let currentState = _currentState;
    if (stateName === PredefinedStates.normal) {
      currentState = undefined;
      if (!_currentState || !isPredefinedState(_currentState)) {
        currentState = _currentState;
      }
    } else {
      if (states[stateName]) {
        currentState = stateName;
      }
    }
    if (currentState !== _currentState) {
      const patches: ArtboardOperations = {
        [comp.id]: [Ops.replace('./_currentState', currentState)],
      };
      this.patch({ [comp.ownerArtboardID]: patches });
    }
  }

  /**
   * 触发组件预定状态对应事件
   * @param {UIComponent} comp
   * @param {PredefinedStates} stateName
   */
  private doTriggerEventByPredefinedState(comp: UIComponent, stateName: PredefinedStates) {
    let eventName: EventTypes | undefined;
    switch (stateName) {
      case PredefinedStates.checked: {
        eventName = EventTypes.checked;
        break;
      }
      case PredefinedStates.unchecked: {
        eventName = EventTypes.unChecked;
        break;
      }
      case PredefinedStates.toggleCheck: {
        eventName = EventTypes.toggleCheck;
        break;
      }
    }

    if (eventName) {
      setTimeout(() => {
        if (eventName && [EventTypes.checked, EventTypes.unChecked, EventTypes.toggleCheck].includes(eventName)) {
          this.start(comp, eventName);
        }
      }, 10);
    }
  }

  /**
   * 触发组件预定状态
   * @param {UIComponent} comp
   * @param {PredefinedStates} stateName
   */
  triggerPredefinedState(comp: UIComponent, stateName: PredefinedStates) {
    switch (stateName) {
      case PredefinedStates.checked: {
        this.doSelectedState(comp);
        break;
      }
      case PredefinedStates.unchecked: {
        this.doUnSelectState(comp);
        break;
      }
      default: {
        this.doChangePredefinedState(comp, stateName);
        break;
      }
    }

    this.doTriggerEventByPredefinedState(comp, stateName);
  }
}

interface IInnerActionInfo {
  comp: UIComponent;
  actions: Operation[];
}

export default Theater;

/**
 * 用于演示时临时缓存一些本地数据
 * @type {{}}
 */
export const PreviewCache: { [key: string]: any } = {};
