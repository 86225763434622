import * as React from 'react';

import classnames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';
import * as _ from 'lodash';

import { parseColorToString } from '@utils/graphicsUtils';
import { transBlankChart, measureTextSize } from '@utils/textUtils';

import { DefaultIconColor } from '@consts/colors';
import { markerStripSize, textAndIconSpace } from '@/consts/defaultData/menu';
import { ITreeItem } from '@helpers/treeCompHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { hasInteraction } from '@helpers/interactionHelper';
import { UITreeItemComponent, UIHorizontalMenuComponent } from '@editor/comps';
import { IconValue, ITreeDataItem } from '@fbs/rp/models/value';
import { IComponentSize } from '@/fbs/rp/models/component';

import MenuChildItem from './MenuChildItem';

import './index.scss';

export interface IHorizontalMenuItemProps {
  _id: string;
  item: ITreeItem;
  comp: UITreeItemComponent;
  horizontalComp: UIHorizontalMenuComponent;
  initTree: ITreeItem[];
  horizontalMenuHoverItemID?: string;
  horizontalContentRects: DOMRectList;
  padding: { left: number; right: number; top: number; bottom: number };
  lineHeight: number;
  selectedIDs: string[];
  level: number;
  index: number;
  parentIndex: number;
  itemIndex: number;
  maxChildLength: number;
  size: IComponentSize;
  intention: number;
  checkedFill: React.CSSProperties;
  checkedMarkerStrip: React.CSSProperties;
  checkedTextStyle: React.CSSProperties;
  checkedIconColor: string;
  paddingStyle: React.CSSProperties;
  showExpandIcon: boolean;
  showNodeIcon: boolean;
  isPreview: boolean;
  floatPanelRects: Record<string, number>;
  defaultScale: number;
  children?: any;
  showInteractionBackGround?: boolean;
  showMobileCursor?: boolean;

  onExpandClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onCheckBoxClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseDown?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseUp?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemDoubleClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemContext?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseLeave?: (node: ITreeDataItem, isHover: boolean, e: React.MouseEvent) => void;
  onItemMouseEnter?: (node: ITreeDataItem, isHover: boolean, e: React.MouseEvent) => void;
}

let menuItemID: string;

const HorizontalMenuItem: React.FC<IHorizontalMenuItemProps> = (props: IHorizontalMenuItemProps) => {
  const boxRef = React.useRef<HTMLDivElement>(null);
  const [itemMainHover, setItemMainHover] = React.useState<boolean>(false);
  const [markerStripWidth, setMarkerStripWidth] = React.useState<number>(0);

  React.useEffect(() => {
    let resizeObserver: ResizeObserver;
    if (boxRef.current) {
      resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        entries.forEach((entry: ResizeObserverEntry) => {
          setMarkerStripWidth(entry.contentRect.width ?? 0);
        });
      });
      resizeObserver.observe(boxRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleItemClick = (e: React.MouseEvent) => {
    const { onItemClick, item, level } = props;
    if (item.children?.length) {
      if (!menuItemID) {
        return;
      }
      setItemMainHover(false);
      level === 0 && (menuItemID = '');
      return;
    }
    menuItemID = item.data.id;
    setItemMainHover(false);
    onItemClick && onItemClick(item.data, e);
  };

  const handleItemMouseDown = (e: React.MouseEvent) => {
    const { onItemMouseDown, item } = props;
    onItemMouseDown && onItemMouseDown(item.data, e);
  };

  const handleItemDoubleClick = (e: React.MouseEvent) => {
    const { onItemDoubleClick, item } = props;
    onItemDoubleClick && onItemDoubleClick(item.data, e);
  };

  const handleItemMouseUp = (e: React.MouseEvent) => {
    const { onItemMouseUp, item } = props;
    onItemMouseUp && onItemMouseUp(item.data, e);
  };

  const handleItemMouseEnter = (e: React.MouseEvent) => {
    const { onItemMouseEnter, item } = props;
    setItemMainHover(true);
    onItemMouseEnter && onItemMouseEnter(item.data, true, e);
  };

  const handleItemMouseLeave = (e: React.MouseEvent) => {
    const { onItemMouseLeave, item } = props;
    setItemMainHover(false);
    onItemMouseLeave && onItemMouseLeave(item.data, false, e);
  };

  const handleItemContext = (e: React.MouseEvent) => {
    const { onItemContext, item } = props;
    onItemContext && onItemContext(item.data, e);
  };

  const renderExtendIcon = () => {
    const { showExpandIcon, item, comp, checkedTextStyle, selectedIDs } = props;

    if (!showExpandIcon) {
      return null;
    }
    const expandComp = comp.expandComp;
    if (!expandComp) {
      return null;
    }
    if (!item.children?.length) {
      return null;
    }

    const { size, value, properties } = expandComp;
    const icon = value as IconValue;
    const fontSize = Math.min(size.width, size.height);
    let color: string | undefined = parseColorToString(properties.icon?.color || DefaultIconColor);
    if (itemMainHover) {
      color = checkedTextStyle.color;
    }
    if (selectedIDs && selectedIDs.includes(comp.id)) {
      color = checkedTextStyle.color;
    }
    return (
      <div
        className="comp-horizontal-menu-expand"
        style={{
          width: size.width,
          minWidth: size.width,
          height: size.height,
          marginLeft: textAndIconSpace,
          fontFamily: icon.fontName,
          fontSize,
          color,
        }}
      >
        {String.fromCharCode(icon.iconCode)}
      </div>
    );
  };

  const renderNodeIcon = () => {
    const { comp, showNodeIcon, level, checkedTextStyle, selectedIDs, checkedIconColor } = props;
    if (!showNodeIcon) {
      return;
    }

    if (level) {
      return;
    }

    const nodeComp = comp.nodeComp;
    if (!nodeComp) {
      return null;
    }
    const { size, value, properties } = nodeComp;
    const icon = value as IconValue;
    const fontSize = Math.min(size.width, size.height);
    let color: string | undefined = parseColorToString(properties.icon?.color || DefaultIconColor);
    if (itemMainHover) {
      color = checkedTextStyle.color;
    }
    if (selectedIDs && selectedIDs.includes(comp.id)) {
      color = checkedIconColor;
    }
    return (
      <div
        className="comp-horizontal-menu-node"
        style={{
          width: size.width,
          height: size.height,
          fontSize,
          fontFamily: icon.fontName,
          lineHeight: `${fontSize}px`,
          color,
        }}
      >
        {String.fromCharCode(icon.iconCode)}
      </div>
    );
  };

  const renderText = () => {
    const { comp, checkedTextStyle, selectedIDs, item } = props;
    const { textComp } = comp;
    const { size: textSize, position, properties: oldProperties, value } = textComp;
    const properties = _.cloneDeep(oldProperties);
    const parser = StyleHelper.createCSSStyleParser({});
    let { style } = parser.getTextStyleData(textSize, properties.textStyle);
    if (selectedIDs && selectedIDs.includes(comp.id)) {
      style = { ...checkedTextStyle, lineHeight: style.lineHeight };
    }
    if (itemMainHover) {
      style.color = checkedTextStyle.color;
    }
    const textStyle: React.CSSProperties = {
      ...style,
      top: position.y,
    };

    const textBoxStyle = {
      // 文本初始值在配置时被固定，无法直接使用textSize
      width: measureTextSize(textStyle, transBlankChart(`${value}`)).width / 2,
    };

    return (
      <div className="comp-horizontal-menu-box" ref={boxRef}>
        {renderNodeIcon()}
        <div className="comp-horizontal-menu-textBox" style={textBoxStyle}>
          <div className="comp-horizontal-menu-text" style={textStyle}>
            {transBlankChart(`${value}`)}
          </div>
        </div>
        {item.parent && renderExtendIcon()}
      </div>
    );
  };

  const renderCheckedMarkerStrip = () => {
    const { comp, level, checkedMarkerStrip, selectedIDs } = props;
    if (level !== 0 || !selectedIDs.includes(comp.id)) {
      return;
    }
    return (
      <span
        className="item-selected-markerStrip"
        style={{
          ...checkedMarkerStrip,
          width: markerStripWidth,
          height: markerStripSize,
        }}
      ></span>
    );
  };

  const renderSelectBack = () => {
    const { comp, lineHeight, checkedFill, level } = props;
    if (!comp.selected || level === 0) {
      return null;
    }
    return (
      <div
        className="item-selected-background"
        style={{
          height: lineHeight,
          ...checkedFill,
        }}
      ></div>
    );
  };

  const renaderInteractionBack = React.useMemo(() => {
    const { comp, level, lineHeight, showInteractionBackGround } = props;
    if (!showInteractionBackGround || !hasInteraction(comp)) {
      return null;
    }
    const interactionStyle: React.CSSProperties = {
      height: lineHeight,
      width: level ? `calc(100% + 28px)` : '100%',
      left: level ? '-12px' : 0,
    };
    return <div className="item-interaction-background" style={interactionStyle}></div>;
  }, [props.comp.interactions.length, props.showInteractionBackGround]);

  const renderChildItem = () => {
    return <MenuChildItem {...props} />;
  };

  const renderMain = () => {
    const { lineHeight, isPreview, comp, horizontalMenuHoverItemID, initTree, size, _id, level, item } = props;
    let style: React.CSSProperties = {
      width: size.width / initTree.length,
      lineHeight: level ? lineHeight : size.height,
      height: level ? lineHeight : size.height,
    };
    if (level) {
      style.width = '100%';
    }
    return (
      <div
        className={classnames('item-main', {
          'item-interaction-flag': !isPreview && !item.children?.length && hasInteraction(comp),
        })}
        style={style}
        onClick={handleItemClick}
        onMouseDown={handleItemMouseDown}
        onDoubleClick={handleItemDoubleClick}
        onMouseUp={handleItemMouseUp}
        onMouseEnter={handleItemMouseEnter}
        onMouseLeave={handleItemMouseLeave}
        onContextMenu={handleItemContext}
      >
        {renderSelectBack()}
        {renaderInteractionBack}
        <div className="item-main-inner">
          {renderText()}
          {renderCheckedMarkerStrip()}
        </div>
        {(horizontalMenuHoverItemID === _id || itemMainHover) && props.children && renderChildItem()}
      </div>
    );
  };

  const render = () => {
    return (
      <div className={classnames('lib-comp-horizontal-menu-item', { 'component-cursor-pointer': props.isPreview })}>
        {renderMain()}
      </div>
    );
  };
  return render();
};

export default React.memo(HorizontalMenuItem);
