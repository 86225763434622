import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

interface IPaginationItemProps {
  data: number;
  current: number;
  onChange?(index: number): void;
}

const PaginationItem: React.FC<IPaginationItemProps> = ({ data, current, onChange }: IPaginationItemProps) => {
  return (
    <li
      className={classnames('dsm-c-pagination-item', {
        'dsm-c-pagination-item-active': current === data,
      })}
      tabIndex={0}
      onClick={() => onChange && onChange(data)}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          onChange && onChange(data);
        }
      }}
    >
      <a rel="nofollow">{data}</a>
    </li>
  );
};

export default PaginationItem;
