import * as React from 'react';

import { ICompareRefs, IPoint, ITableColumn, ITableData, TableSelection } from '../../../models';

import TableRow from './TableRow';

import './index.scss';

interface ITableBodyProps {
  theme: 'red' | 'blue';
  dataSource: ITableData[];
  columns: ITableColumn[];
  activeMenuRow: ITableData;
  rowSelectionType: TableSelection;
  checked: (string | number)[];
  cellStyle?: React.CSSProperties;
  searchKey: string;
  disableSelectionRows: (string | number)[];

  onFormat?(data: ITableData, column: ITableColumn, ref?: ICompareRefs): React.ReactChild;

  handleClickRow(data: ITableData): void;

  handleContextRow(data: ITableData, position: IPoint, fromOperateBtn?: boolean): void;

  setChecked(checked: (string | number)[]): void;

  setTip(text: string, style: React.CSSProperties | null): void;
}

const TableBody: React.FC<ITableBodyProps> = (props: ITableBodyProps) => {
  const {
    theme,
    dataSource,
    columns,
    activeMenuRow,
    rowSelectionType,
    checked,
    cellStyle,
    searchKey,
    disableSelectionRows,
    handleClickRow,
    handleContextRow,
    setChecked,
    onFormat,
    setTip,
  } = props;

  return (
    <tbody className={'c-table-tbody'}>
      {dataSource.map((row, index) => {
        const activeID = !!activeMenuRow && activeMenuRow.id;
        return (
          <TableRow
            key={`${row?.id || ''}-${index}`}
            columns={columns}
            data={row}
            order={index}
            activeMenu={activeID && activeID === row.id}
            rowSelectionType={rowSelectionType}
            checked={checked}
            cellStyle={cellStyle}
            searchKey={searchKey}
            theme={theme}
            disableSelectionRows={disableSelectionRows}
            onFormat={onFormat}
            onClick={handleClickRow}
            onContext={handleContextRow}
            setChecked={setChecked}
            setTip={setTip}
          />
        );
      })}
    </tbody>
  );
};

export default TableBody;
