import { cloneDeep } from 'lodash';

import { CStickNote } from '@libs/constants';
import { UIComponent } from '@/editor/comps';
import { makeUIComponent } from '@/editor/comps/factory';
import { CGroup, CCanvasPanel, CContentPanel, CContentPanelV2, CCarouselChart } from '@/libs/constants';
import { IExportData, ISize, IParseComponentOptions } from '../type';
import { renderToHtml } from '../render/renderComponent';
import { updateContentPanel, deepCloneContentPanel, simplifyCarouselChartData } from '../util/artboardUtil';
import { calcForeignObjectOffsetPosition } from '../svg/makeTransform';
import { calcGroupOuterBounds } from '../util/boundsUtil';
import { offseOpposite } from '../util';

export const parseComponent = async (options: IParseComponentOptions): Promise<IExportData> => {
  const { t, isRoot, doc, renderBlankFilter } = options;
  const { id, size, rotate, type } = t;

  // 位置，区分组件是否独立导出
  let position = isRoot ? { x: 0, y: 0 } : t.position;

  const { offset, bounds } = calcGroupOuterBounds(t.toJSON(), true);
  const newSize = bounds as ISize;
  // 这里的位置归零，但要保留偏移位置 // 旋转导致的中心偏移
  if (rotate) {
    position = calcForeignObjectOffsetPosition({ size, rotate });
  }
  position = offseOpposite(position, offset);

  let newComponent: UIComponent;
  // 内容面板数据
  if ([CGroup, CCanvasPanel, CContentPanel, CContentPanelV2].includes(type)) {
    const json = { ...deepCloneContentPanel(t.toJSON()), position };
    newComponent = makeUIComponent(json);
    doc && updateContentPanel(newComponent, doc);
  } else if (t.lib?.type === CStickNote) {
    // 便签条组件不导出连接线
    const json = cloneDeep(t.toJSON());
    json.position = position;
    if (json.properties.connectedLine) {
      json.properties.connectedLine.disabled = true;
    }
    newComponent = makeUIComponent(json);
  } else {
    let json = { ...t.toJSON(), position };
    // 轮播图，精简
    if (CCarouselChart === type) {
      json = simplifyCarouselChartData(json);
    }
    newComponent = makeUIComponent(json);
  }
  window.debug && console.log(newComponent);
  const html = await renderToHtml(newComponent, renderBlankFilter);
  return {
    id,
    type: type,
    size: newSize,
    position,
    rotate,
    offset,
    outerBounds: bounds,
    html,
    isRoot,
  };
};
