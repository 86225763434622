import * as React from 'react';
import classnames from 'classnames';

import Icon from '../Icon';
import Checkbox from '../Checkbox';
import i18n from '../../i18n';
import { isIE } from '../utils';

import './index.scss';

export interface IDialogProps {
  title?: string;
  subTitle?: string;
  show?: boolean;
  width?: number | string;
  contentMinHeight?: number;
  contentMaxHeight?: number;
  children?: any;
  showOk?: boolean;
  disableCenter?: boolean;
  disableOk?: boolean;
  showLeft?: boolean;
  leftText?: string;
  disableLeft?: boolean;
  showCenter?: boolean;
  centerText?: string;
  showCancel?: boolean;
  showClose?: boolean;
  okText?: string;
  cancelText?: string;
  clickModalToDismiss?: boolean;
  sureDelete?: boolean;
  showNotTips?: boolean;
  isNotTips?: boolean;
  isNotTipsIndeterminate?: boolean;
  notTipsHint?: string;
  titleAlignCenter?: boolean;
  escToClose?: boolean;
  contentOverflow?: 'auto' | 'hidden' | 'visible' | 'default';

  onOk?(e: any): void;

  onCenterBtnClick?(e: React.MouseEvent<HTMLButtonElement>): void;

  onCancel?(e: any): void;

  onNotTips?(isChecked: boolean): void;

  onLeftBtnClick?(e: React.MouseEvent<HTMLButtonElement>): void;

  boldTitle?: boolean;
  lightTitle?: boolean;
  theme?: 'blue';
}

interface IDialogState {
  closing: boolean;
}

class Dialog extends React.Component<IDialogProps, IDialogState> {
  static defaultProps = {
    title: '',
    subTitle: '',
    show: true,
    showOk: true,
    showCancel: false,
    disableOk: false,
    children: '',
    showClose: true,
    okText: i18n('Dialog.ok'),
    cancelText: i18n('Dialog.cancel'),
    clickModalToDismiss: false,
    sureDelete: false,
    showNotTips: false,
    isNotTips: false,
    notTipsHint: '',
    titleAlignCenter: false,
    contentOverflow: 'auto',
    escToClose: true,
    contentMinHeight: 0,
    lightTitle: false,
    showLeft: false,
    leftText: '',
    disableLeft: false,
  };

  private dialogContent: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: IDialogProps) {
    super(props);
    this.state = {
      closing: false,
    };
  }

  componentDidMount(): void {
    if (this.props.escToClose) {
      window.addEventListener('keydown', this.onWindowKeyDown);
    }
  }

  componentWillUnmount(): void {
    if (this.props.escToClose) {
      window.removeEventListener('keydown', this.onWindowKeyDown);
    }
  }

  onWindowKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && this.props.onCancel) {
      this.handleClose(e);
    }

    if (e.key === 'Enter' && !this.props.disableOk && this.props.onOk) {
      e.stopPropagation();
      this.props.onOk(e);
    }
  };

  handleAnimationEnd = (e: any) => {
    const { onCancel } = this.props;
    const animateDom = this.dialogContent.current;
    onCancel && onCancel(e);
    animateDom?.removeEventListener('animationend', this.handleAnimationEnd);
  };

  handleClose = (e: any) => {
    const { onCancel } = this.props;
    const animateDom = this.dialogContent.current;
    animateDom?.addEventListener('animationend', this.handleAnimationEnd);
    this.setState({ closing: true }, () => {
      !animateDom && onCancel && onCancel(e);
    });
  };

  render() {
    let {
      showOk,
      showCenter,
      showCancel,
      clickModalToDismiss,
      okText,
      disableOk,
      disableCenter,
      title,
      show,
      showClose,
      cancelText,
      centerText,
      children,
      sureDelete,
      showNotTips,
      isNotTips,
      isNotTipsIndeterminate,
      notTipsHint,
      titleAlignCenter,
      contentOverflow,
      subTitle,
      width,
      contentMinHeight,
      contentMaxHeight,
      boldTitle,
      lightTitle,
      theme,
      showLeft,
      leftText,
      disableLeft,
      onOk,
      onCenterBtnClick,
      onNotTips,
      onLeftBtnClick,
    } = this.props;

    const { closing } = this.state;

    return (
      <div
        className={classnames('', {
          'dsm-c-comp-dialog': show,
          hide: !show,
          ie: isIE(),
          'theme-blue': theme === 'blue',
        })}
      >
        <div
          className={classnames('dsm-c-bg', {
            closing,
          })}
          onClick={(e: any) => clickModalToDismiss && this.handleClose(e)}
        />
        <div className="dsm-c-pop-wrap">
          <div
            ref={this.dialogContent}
            className={classnames('dsm-c-pop', {
              'empty-title': !title || !title.trim(),
              closing: closing,
            })}
            style={{
              overflow: contentOverflow,
              width,
              minHeight: contentMinHeight,
              maxHeight: contentMaxHeight,
            }}
          >
            {showClose && <Icon cls="demo_close" onClick={this.handleClose} />}
            {title && title.trim() && (
              <div
                className={classnames('dsm-c-pop-title', {
                  'dsm-c-pop-title-bold': boldTitle,
                  'dsm-c-pop-title-light': lightTitle,
                })}
                style={titleAlignCenter ? { textAlign: 'center' } : {}}
              >
                {title}
                {subTitle && subTitle.trim() && <span className="dsm-c-pop-sub-title">{subTitle}</span>}
              </div>
            )}
            <div className={classnames('dsm-c-pop-content')}>{children}</div>
            <div className="dsm-c-button-wrap">
              {showNotTips && (
                <Checkbox
                  text={notTipsHint}
                  checked={isNotTips}
                  indeterminate={isNotTipsIndeterminate}
                  onChange={(isChecked) => {
                    onNotTips && onNotTips(isChecked);
                  }}
                />
              )}
              {/* showLeftBtn */}
              {showLeft && (
                <button
                  className={classnames('dsm-c-buttons', {
                    'dsm-c-disable-button': disableLeft,
                  })}
                  disabled={disableLeft}
                  onClick={onLeftBtnClick}
                >
                  {leftText}
                </button>
              )}
              {showOk && (
                <button
                  className={classnames('dsm-c-buttons', {
                    'dsm-c-spc-button': !showCancel || !sureDelete,
                    'dsm-c-disable-button': disableOk,
                  })}
                  disabled={disableOk}
                  onClick={onOk}
                >
                  {okText || i18n('Dialog.ok')}
                </button>
              )}
              {/* showCenterBtn */}
              {showCenter && (
                <button
                  className={classnames('dsm-c-buttons', {
                    'dsm-c-disable-button': disableCenter,
                  })}
                  disabled={disableCenter}
                  onClick={onCenterBtnClick}
                >
                  {centerText}
                </button>
              )}
              {showCancel && (
                <button
                  className={classnames('dsm-c-buttons', {
                    'dsm-c-spc-button': sureDelete,
                  })}
                  onClick={this.handleClose}
                >
                  {cancelText}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dialog;
