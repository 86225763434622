import Base from '../base';

import IColor from './color';
import ITypography from './typography';
import IComponent from './component';
import { IDSGroup } from './group';
import { IAppF } from '../../../idoc/models/app';

export interface ILibBase {
  name?: string;
  visibility?: VisibilityType;
}

export default interface ILib extends Base, ILibBase {
  _id: string;
  appID: string;
  appName: string;
  teamID: string;
  name: string;
  app?: IAppF; // 该资源库在iDoc中的项目详情
}

export interface ILibF extends ILib {
  colors: IColor[];
  typographies: ITypography[];
  components: IDSGroup[];
  componentsRecycle?: IComponent[];
}

export enum VisibilityType {
  unShare = '', // 未共享
  team = 'team', // 共享至团队
  system = 'system', // 共享至系统
}

export enum ResourceType {
  color = 'color',
  typography = 'typography',
  component = 'component',
}

export type IResourceItem = IColor | ITypography | IComponent;
