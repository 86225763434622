import { base64_encode } from './base64';
import JSZip from 'jszip';

export function uriToBlob(dataurl: string, mime: string) {
  // var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
  const bstr = atob(dataurl);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export const svgToBlob = (svg: string) => {
  return uriToBlob(base64_encode(svg), 'image/svg+xml');
};

// all images are from wikimedia
// const urls = ['/3/3b/Hulda_Klagers_house_and_lawn.jpg/320px-Hulda_Klagers_house_and_lawn.jpg', '/1/15/P%C3%A8re-Lachaise_-_Division_79_-_Floriot_02.jpg/320px-P%C3%A8re-Lachaise_-_Division_79_-_Floriot_02.jpg', '/a/a6/V37-20180910-055_%2845088120261%29.jpg/320px-V37-20180910-055_%2845088120261%29.jpg', '/2/2b/MormantulLuiAmzaPellea_%284%29.JPG/360px-MormantulLuiAmzaPellea_%284%29.JPG', '/f/f8/Launch_of_LAWRENCE_LCCN2014710971.tif/lossy-page1-174px-Launch_of_LAWRENCE_LCCN2014710971.tif.jpg']
// .map((url) => 'https://upload.wikimedia.org/wikipedia/commons/thumb' + url);

export const downloadImages = (urls: string[]) => {
  // var tempLink = document.createElement('a');
  // tempLink.setAttribute('href', urls[i]);
  // tempLink.setAttribute('download', urls[i].split('/')[urls[i].split('/').length*1-1*1]);
  // tempLink.click();

  fetchBlobs(urls)
    .then(pack)
    .then((zipFile) => URL.createObjectURL(zipFile));
};

function fetchBlobs(urls: string[]) {
  return Promise.all(
    urls.map((url) =>
      fetch(url)
        .then((resp) => resp.blob())
        .then((blob: Blob) => {
          // store the file name
          // blob.name = url.slice(url.lastIndexOf('/') + 1)
          return {
            blob,
            name: url.slice(url.lastIndexOf('/') + 1),
          };
        }),
    ),
  );
}
function pack(blobs: { blob: Blob; name: string }[]) {
  const zip = new JSZip();
  const folder = zip.folder('my_images');
  if (folder) {
    blobs.forEach((blob) => folder.file(blob.name, blob.blob));
  }

  return zip.generateAsync({ type: 'blob' });
}

/**
 *  FileReader
 * @param blob
 * @returns
 */
export const blobToBase64 = (blob?: Blob): Promise<string> => {
  if (!blob || blob.size === 0) {
    return Promise.resolve('');
  }
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      const data = e.target?.result;
      data ? resolve(data as string) : reject(e);
    };
    fileReader.readAsDataURL(blob);
  });
};

/**
 * 这里图片跨域会导致页面卡住
 * @param blob
 * @returns
 */
export const blobToImage = (blob: Blob): Promise<HTMLImageElement> => {
  return new Promise((resovle) => {
    const img = new Image();
    img.crossOrigin = '*';
    img.onload = () => {
      window.URL.revokeObjectURL(img.src);
      resovle(img);
    };
    img.src = window.URL.createObjectURL(blob);
    return img;
  });
};

export function dataURItoBlobUrl(dataURI: string): string {
  const svg = decodeURI(dataURI).split(',')[1];
  const blob = new Blob([svg], { type: 'image/svg+xml' });

  return URL.createObjectURL(blob);
}
