import * as React from 'react';
import { once } from 'lodash';
import { UIFragment, UIContainerComponent } from '@/editor/comps';
import ComponentsWrapper from '@/components/Application/WorkContainer/Workspace/Page/ComponentsWrapper';
import { IAppWithNestedChildren } from '@/fbs/rp/models/app';
import Doc from '@/editor/document';
import { JSXtoHtml, renderSimpleComponent } from './renderComponent';
import SimpleArtboard from './SimpleArtboard';
import { renderDevice } from './renderDevice';
import { MobileType } from '@/fbs/rp/utils/preview';
import { useShell } from '../util/shellUtil';
import { IHtmlContent } from '../type';
import { getPageName } from '../util/filenameUtil';

/**
 * 渲染容器组件
 *
 * @param {UIContainerComponent} group
 * @param {number} scale
 * @memberof Artboard
 */
const renderContainerChildren = (group: UIContainerComponent, scale: number) => {
  return (
    group.components
      // .filter((comp) => !(isEditingPathComponent(comp) || isConnectorBeingSelected(comp)))
      // .map((comp) => renderComponent(comp, scale))
      .map((comp) => renderSimpleComponent(comp, scale))
  );
};

const renderContianerChildrenOnce = once(renderContainerChildren);

const renderContainerChildrenWithCache = (isSpacePlusMouseToMovingPage?: boolean) => {
  return isSpacePlusMouseToMovingPage ? renderContianerChildrenOnce : renderContainerChildren;
};

/**
 * 渲染面板
 *
 * @private
 * @memberof Page
 */
export const renderArtboard = (artboard: UIFragment) => {
  const appID = 'teset';
  const scaleFactor = 1;
  const artboardCreating = false;
  const compsCreating = false;
  const hiddenArtbords = false;
  const isZooming = false;
  const isSpacePlusMouseToMovingPage = false;
  const isWheeling = false;
  const isSelectByRect = false;
  const isArtboardMoving = false;
  const isArtboardResizing = false;
  const isActived = false; //coreEditor.isActiveArtboard(artboard);
  // // TODO 考虑 主画板的情况，主画板
  // 内容面板
  const isSelected = false; //true; // coreEditor.selectedFragments.has(artboard);
  // const activeContainer = coreEditor.activeContainer;
  const renderContainerFn = renderContainerChildrenWithCache();
  // const { ruleConfig } = this.context;
  const isPageScrolling: boolean = false; // 画布是否发生是滚动变化
  const isNormalArtboardResizing = isArtboardResizing && !artboard.layout.responsive;
  const renderChildren = () => {
    return (
      <ComponentsWrapper
        isWheeling={isWheeling}
        isPageScrolling={isPageScrolling}
        isZooming={isZooming}
        isArtboardMoving={isArtboardMoving}
        isNormalArtboardResizing={isNormalArtboardResizing}
        isSpacePlusMouseToMovingPage={isSpacePlusMouseToMovingPage}
        isSelectByRect={isSelectByRect}
        renderContainer={() => (
          <>
            {!hiddenArtbords && renderContainerFn(artboard, 1)}
            {/* {this.state.flow.isCreating && activeContainer === artboard && (
              <TempConnector paths={this.state.flow.paths || []} />
            )} */}
          </>
        )}
      />
    );
  };

  return (
    <>
      <SimpleArtboard
        key={artboard.artboardID}
        isZooming={isZooming}
        appID={appID}
        artboard={artboard}
        isActived={isActived}
        isSelected={isSelected}
        scaleFactor={scaleFactor}
        artboardCreating={artboardCreating}
        compsCreating={compsCreating}
        renderChildren={renderChildren}
      />
    </>
  );
};

export const renderArtboardToHtml = async (
  artboard: UIFragment,
  doc?: Doc,
  app?: IAppWithNestedChildren,
  mobileType?: MobileType,
): Promise<IHtmlContent | undefined> => {
  const jsx = useShell(mobileType) ? renderDevice({ artboard, doc, app, mobileType }) : renderArtboard(artboard);
  if (!jsx) {
    return;
  }
  const pageName = getPageName(artboard.$data, app);
  return JSXtoHtml(jsx, { name: artboard.name, type: artboard.type, pageName });
};
