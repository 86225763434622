import { IComponentData } from '@fbs/rp/models/component';

import { depthClone } from '@utils/globalUtils';
import { getViewBoundsOfComponents } from '@editor/bounds';
import { resetID, resetInteractionTargetID, resetValueMap, writeMasterID } from '@helpers/componentHelper';
import { ArtboardPatches, Ops, PagePatches } from '@fbs/rp/utils/patch';
import { makeSymbol } from '../../libs/basic/Symbol';
import { UIComponent, UIContainerComponent, UISymbolComponent } from '@editor/comps';
import CoreExport from './export';

/**
 * 关于设计资源操作的部分
 * @description
 *   1、流程线不可以包含到设计资源的组件部分中，如果选中的组件或其子中包含的有流程线，则不允许另存为组件
 */
export default class CoreDS extends CoreExport {
  doDetachSymbolFromComp(comp: UIContainerComponent): ArtboardPatches {
    const data = depthClone(comp.toJSON());
    const index = comp.parent!.components.indexOf(comp);
    const containerID = this.activeContainer.id;
    // 如果需要脱离的内部子组件只有一个， 需要解组
    const children = comp.components;
    if (children.length === 1) {
      const child = children[0];
      const position = child.getPositionWithoutParent();
      const childRotate = (data.rotate || 0) + child.rotate;
      const childOriginData = child.toJSON();
      const childData: IComponentData = {
        ...childOriginData,
        _id: comp.id,
        position,
        rotate: childRotate,
        v: data.v,
      };
      const idMap = { [child.id]: comp.id };
      resetInteractionTargetID([childData], idMap);
      this.resetInfoWhenCompIsLine(child, childData);
      return {
        do: {
          [containerID]: [Ops.removeChildren([comp.id]), Ops.addChildren(`${index}`, [childData])],
        },
        undo: {
          [containerID]: [Ops.removeChildren([childData._id]), Ops.addChildren(`${index}`, [data])],
        },
      };
    }

    const group: IComponentData = {
      ...data,
      type: 'group',
      components: data.components,
    };

    return {
      do: {
        [containerID]: [Ops.removeChildren([comp.id]), Ops.addChildren(`${index}`, [group])],
      },
      undo: {
        [containerID]: [Ops.removeChildren([group._id]), Ops.addChildren(`${index}`, [data])],
      },
    };
  }

  /**
   * 脱离
   */
  detachSymbolWithSelectedComps() {
    const comp = this.firstSelectedComponent!;
    if (comp instanceof UISymbolComponent) {
      const patch = this.doDetachSymbolFromComp(comp);
      this.updateSingleArtboard(this.activeArtboard.artboardID, patch);
      this.selectByIDs([comp.id], false);
    }
  }

  /**
   * 脱离整个项目中某个Symbol的引用
   * @param {string} masterID
   */
  detachSymbolOfDocument(libID: string, masterID: string) {
    const symbolComps: UISymbolComponent[] = [];
    const flat = (container: UIContainerComponent) => {
      container.components.forEach((comp) => {
        const { symbol } = comp.toJSON();
        if (comp instanceof UISymbolComponent && symbol!.libID === libID && symbol!.masterID === masterID) {
          symbolComps.push(comp);
        } else if (comp instanceof UIContainerComponent) {
          flat(comp);
        }
      });
    };
    flat(this.doc.mainArtboard);
    this.doc.fragments.forEach((fragment) => flat(fragment));
    const patches: PagePatches = {};
    symbolComps.forEach((comp) => {
      const compPatch = this.doDetachSymbolFromComp(comp);
      const artboardID = comp.ownerArtboardID;
      if (!patches[artboardID]) {
        patches[artboardID] = { do: {}, undo: {} };
      }
      const artboardPatch = patches[artboardID];
      Object.keys(compPatch.do).forEach((id) => {
        if (artboardPatch.do[id]) {
          artboardPatch.do[id].push(...compPatch.do[id]);
          artboardPatch.undo[id].push(...compPatch.undo[id]);
        } else {
          artboardPatch.do[id] = compPatch.do[id];
          artboardPatch.undo[id] = compPatch.undo[id];
        }
      });
    });
    this.update(patches);
  }

  /**
   * 把选中的组件和Symbol关联，发生在选中的组件保存到设计资源后
   */
  relatedSymbolToSelectedComps(libID: string, masterID: string, comps: UIComponent[], name?: string) {
    const bounds = getViewBoundsOfComponents(comps)!;
    const { left, top } = bounds;
    const components = comps.map((comp) => depthClone(comp.toJSON()));
    components!.forEach((comp) => {
      comp.masterID = comp._id;
      comp.symbol = undefined;
      comp.layout.auto = true;
      comp.layout.responsive = true;
      const { x, y } = comp.position;
      comp.position = {
        x: x - left,
        y: y - top,
      };
      //  这里还要重置坐标
    });
    components.forEach(writeMasterID);
    const idMap = resetID(components!);
    resetInteractionTargetID(components!, idMap);
    resetValueMap(components!, idMap);
    const group = makeSymbol(bounds, components!);
    // group.masterID = masterID;
    group.symbol = {
      libID,
      masterID,
    };
    if (name) {
      group.name = name;
    }
    group.layout.responsive = true;
    const container = comps[0].parent!;
    const patches: ArtboardPatches = {
      do: {
        [container.id]: [
          Ops.removeChildren(comps.map((c) => c.id)),
          Ops.addChildren(`${this.activeArtboard.components.findIndex((c) => c.id === comps[0]?.id)}`, [group]),
        ],
      },
      undo: {
        [container.id]: [
          Ops.removeChildren([group._id]),
          Ops.addChildren(
            `${container.components.indexOf(comps[0])}`,
            comps.map((c) => c.toJSON()),
          ),
        ],
      },
    };
    this.clearSelected();
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
    this.selectByIDs([group._id], false);
  }

  /**
   * 应用Symbol到当前文档中所有引用的目标上
   */
  applySymbolToDocument() {}
}
