import { INode, INodeWithParentID, NodeIconType } from './model';
import { findNodeByID } from '@/fbs/rp/utils/app';
import { INodeWithChildren, NodeType } from '@/fbs/rp/models/node';
import { transBlankChart } from '@/utils/textUtils';

interface INodeParseOption {
  showPageNumber: boolean;
  count?: number;
  parentNumber?: string;
}

export function getAllParentNodeIDs(nodes: INode[], level: number, currentLevel: number = 1): string[] {
  const res: Set<string> = new Set<string>();
  nodes.forEach((node) => {
    if (!node.isLeaf) {
      res.add(node.id);
    }
    if (node.children) {
      res.add(node.id);
      if (level == -1 || currentLevel < level) {
        const subRes = getAllParentNodeIDs(node.children, level, currentLevel + 1);
        if (subRes.length > 0) {
          subRes.forEach((id) => res.add(id));
        }
      }
    }
  });
  return Array.from(res);
}

// 获取分组下的页面
export function getGroupPageNum(nodes: Array<INode | INodeWithChildren>, isHide: boolean = false): string[] {
  const res: Set<string> = new Set<string>();
  nodes.forEach((node) => {
    const oldNode = node as INode;
    const withNode = node as INodeWithChildren;
    const filterHidePage = isHide && withNode.hidden;
    if ((oldNode.isLeaf || withNode.type === 'page') && !filterHidePage) {
      res.add(oldNode.id || withNode._id);
    }
    if (node.children) {
      const subRes = getGroupPageNum(node.children, isHide);
      if (subRes.length > 0) {
        subRes.forEach((id) => res.add(id));
      }
    }
  });
  return Array.from(res);
}

// 节点名字和搜索匹配
export function isNodeMatch(node: INode | INodeWithChildren, searchKey: string): boolean {
  return (
    node.name.toLowerCase().includes(searchKey.toLowerCase()) ||
    ((node as INode).pinyinName ? (node as any).pinyinName.toLowerCase().includes(searchKey.toLowerCase()) : false)
  );
}

// 节点或者节点的子和搜索匹配
export function isNodeOrChildrenMatch(
  node: INode | INodeWithChildren,
  searchKey: string,
  isShowNode?: boolean /*是否强制显示*/,
): boolean {
  if (isShowNode || isNodeMatch(node, searchKey)) {
    return true;
  }
  if (!node.children || node.children.length === 0) {
    return false;
  }
  return node.children.some((node: unknown) => isNodeOrChildrenMatch(node as INode | INodeWithChildren, searchKey));
}

// 当搜索时， 展开匹配的文件夹
export function getExpandIDsWhenSearch(nodes: INodeWithChildren[], searchVal: string): string[] {
  const expandedIDs: string[] = [];
  const computedSearchExpand = (nodes: INodeWithChildren[], expandedIDs: string[], searchVal: string): boolean => {
    if (!searchVal) {
      return false;
    }
    let cursor: number = 0;
    let searchMatchChild = false;
    while (cursor < nodes.length) {
      const node = nodes[cursor];
      if (node.children?.length) {
        //自下向上计算
        const match = computedSearchExpand(node.children, expandedIDs, searchVal);
        searchMatchChild = searchMatchChild ? searchMatchChild : match;
        //如果子组件有匹配的结果
        if (match || isNodeMatch(node, searchVal)) {
          expandedIDs.push(node._id);
        }
      }
      //判断名称是否搜索匹配
      if (isNodeMatch(node, searchVal)) {
        searchMatchChild = true;
      }
      cursor++;
    }
    //子组件有匹配需要返回通知父组件
    return searchMatchChild;
  };
  computedSearchExpand(nodes, expandedIDs, searchVal);
  return expandedIDs;
}

export function getTreeNodes(nodes: INodeWithChildren[] | undefined, option: INodeParseOption): INode[] | undefined {
  if (!nodes) {
    return;
  }
  const { showPageNumber, parentNumber } = option;
  return nodes.map(
    (node, i): INode => {
      let serialNumber = '';
      if (showPageNumber) {
        const n = i + 1;
        serialNumber = parentNumber ? `${parentNumber}.${n}` : `${n}`;
      }

      const childOption = {
        showPageNumber,
        parentNumber: serialNumber,
        count: node.children.filter((childNode) => childNode.type === 'page').length,
      };
      const children = getTreeNodes(node.children, childOption);
      option.count = (option.count || 0) + childOption.count;

      return {
        id: node._id,
        serialNumber: serialNumber,
        name: transBlankChart(node.name),
        children,
        childCount: node.type === 'folder' ? childOption.count : 0,
        data: node,
        isLeaf: node.type === 'page',
        icon: node.type === 'folder' ? NodeIconType.Folder : NodeIconType.None,
        suffixIcon: !node.hidden
          ? undefined
          : {
              icon: (node.hidden ? 'icon_tree_unview' : NodeIconType.None) as NodeIconType,
              color: '',
              label: '',
              disabled: false,
            },
      };
    },
  );
}

/**
 * 查询 node 的 parent 和该 node 在父中的位置
 */
export function findParentNodeByID(
  nodes: INode[],
  nodeID: string,
  parent: INode | null = null,
): {
  parent: INode | null;
  index: number;
} {
  for (let i = 0; i < nodes?.length; i++) {
    const node = nodes[i];
    if (node.id === nodeID) {
      return {
        parent,
        index: i,
      };
    }
    if (node.children) {
      const foundFromNodeChildren = findParentNodeByID(node.children, nodeID, node);
      if (foundFromNodeChildren.index !== -1) {
        return foundFromNodeChildren;
      }
    }
  }
  return {
    parent,
    index: -1,
  };
}

export function flattenChildren(nodes: INode[], parentID: string = ''): INodeWithParentID[] {
  if (nodes.length === 0) {
    return [];
  }
  const initialValue: INodeWithParentID[] = [];
  return nodes.reduce((flat, toFlatten) => {
    flat.push(
      Object.assign({}, toFlatten, {
        parentID,
      }),
    );
    if (toFlatten.children) {
      return flat.concat(flattenChildren(toFlatten.children, toFlatten.id));
    }
    return flat;
  }, initialValue);
}

// 判断某个节点的多选框状态checked：boolean，indeterminate（半选中）：boolean，disabled：boolean
export function getCheckboxState(
  node: INode,
  checkedKeys: string[],
): {
  checked: boolean;
  indeterminate: boolean;
  disabled: boolean;
} {
  if (node.isLeaf) {
    return {
      checked: checkedKeys.includes(node.id),
      indeterminate: false,
      disabled: false,
    };
  }

  let checked = false;
  let indeterminate = false;
  let disabled = !node.children || node.children.length <= 0;

  if (node.children && node.children.length > 0) {
    const allChildren = flattenChildren(node.children);
    const isLeafChildren = allChildren.filter((c) => c.isLeaf);
    const checkedChildren = allChildren.filter((c) => checkedKeys.includes(c.id));
    const isLeafCheckedChildren = checkedChildren.filter((c) => c.isLeaf);

    if (!isLeafChildren || isLeafChildren.length <= 0) {
      disabled = true;
    } else {
      checked = !!checkedChildren && checkedChildren.length > 0;
      indeterminate =
        isLeafCheckedChildren &&
        isLeafCheckedChildren.length > 0 &&
        isLeafCheckedChildren.length < isLeafChildren.length;
    }
  }

  return {
    checked,
    indeterminate,
    disabled,
  };
}

// 是否是兄弟节点
export function isSiblings(nodes: INodeWithParentID[], aNodeID: string, bNodeID: string): boolean {
  const aNode = nodes.find((node) => node.id === aNodeID);
  const bNode = nodes.find((node) => node.id === bNodeID);
  return (aNode && bNode && aNode.parentID === bNode.parentID) || false;
}

// 是否可以编组
export function CanGroup(nodes: INodeWithChildren[], selectedIDs: string[]) {
  let isGroup = false;
  let groupVisible = false;
  const nodeList = [];
  if (selectedIDs.length > 1) {
    groupVisible = true;
  }
  for (let i = 0; i < selectedIDs.length; i++) {
    const node = findNodeByID(nodes, selectedIDs[i])!;
    if (node) {
      nodeList.push(node);
    }
  }
  if (nodeList.length === 1 && nodeList[0].type === NodeType.Folder && nodeList[0].children.length) {
    isGroup = true;
    groupVisible = true;
  }
  return { groupVisible, isGroup };
}
