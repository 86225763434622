import PropertyBase from './base';

export enum CompoundOperation {
  Unite = 'unite',
  Subtract = 'subtract',
  Intersect = 'intersect',
  Exclude = 'exclude',
}

export default interface ICompoundPath extends PropertyBase {
  componentOperation: CompoundOperation;
  hidden: true;
}

export const CompoundPathPropertyName = 'compoundPath';





