import * as React from 'react';
import classnames from 'classnames';

import { depthClone } from '@utils/globalUtils';

import { ISize } from '@/fbs/common/models/common';
import { IComponentData } from '@fbs/rp/models/component';
import { IProperties } from '@fbs/rp/models/property';
import { FillType } from '@fbs/rp/models/properties/fill';
import { StrokeLineCap, StrokeLineJoin, StrokePosition } from '@fbs/rp/models/properties/stroke';
import { TextAlign, VerticalAlign as TextVerticalAlign } from '@fbs/rp/models/properties/text';

import { StyleHelper } from '@helpers/styleHelper';
import appOptions from '@helpers/appOptions';
import { DefaultStrokeColor1, DefaultTextColor } from '@consts/colors';
import * as SystemsColor from '@consts/colors';
import { FontBoxScale } from '@consts/fonts';

import { CLine } from '../../constants';
import { IComponentProps } from '../../types';
import { makeCommonComponent, getDefaultShadow } from '../../helper';
import { createShapeCompTextFragment } from './textFragment';

import './shapeText.scss';

export interface IShapeTextCompState {
  textStyle?: React.CSSProperties;
}

// TODO 1.去掉这种参数 2.line没有padding
export function getShapeBaseDefaultData(fill: boolean): Partial<IComponentData> {
  return {
    properties: {
      stroke: {
        thickness: 1,
        color: DefaultStrokeColor1,
        cap: StrokeLineCap.Butt,
        join: StrokeLineJoin.Miter,
        position: StrokePosition.inner,
        mode: 'custom',
        disabled: false,
      },
      shadow: getDefaultShadow(),
      fill: {
        disabled: !fill,
        type: FillType.solid,
        color: SystemsColor.DefaultWhiteFillColor,
      },
      textFormat: {
        disabled: false,
        fontFamily: 'Microsoft YaHei',
        fontSize: 14,
        textAlign: TextAlign.center,
        color: DefaultTextColor,
        fontStyle: {
          bold: false,
          italic: false,
          underline: false,
          strike: false,
        },
        verticalAlign: TextVerticalAlign.middle,
        letterSpace: 0,
        lineHeightEx: 20,
        wrap: true,
        isMulti: true,
      },
      padding: {
        disabled: false,
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
    },
  };
}

export function makeShapeBase(id: string, type: string, fill: boolean, data?: Partial<IComponentData>) {
  const { properties, states, size, ...other } = data || {};
  const newData: Partial<IComponentData> = {
    fixContent: true,
    properties: {
      stroke: {
        thickness: 1,
        color: DefaultStrokeColor1,
        cap: StrokeLineCap.Butt,
        join: StrokeLineJoin.Miter,
        position: StrokePosition.inner,
        mode: appOptions.strokeDashMode,
        disabled: false,
      },
      ...properties,
    },
    size: size || { width: 100, height: 100 },
    ...other,
  };

  newData.states = Object.assign(
    {
      disabled: {
        enabled: false,
        opacity: 30,
      },
    },
    states,
  );
  return makeCommonComponent(id, type, newData);
}

/**
 * 带文本属性的形状组件基类
 * @author Matt
 * @description 所有带文本属性的基元形状组件都从此继承，该组件类提供该类组件公共的方法及样式处理
 */
export default class ShapeTextBase<P extends IComponentProps, S extends IShapeTextCompState> extends React.Component<
  P,
  S
> {
  protected parserTextStyle = (props: P): React.CSSProperties => {
    const { properties, type, libData } = props.comp;
    const { multiText, textFormat, fill, stroke, shadow } = properties;
    let { width, height } = props.comp.size;
    let strokeThickness = 0;
    width /= FontBoxScale;
    height /= FontBoxScale;
    if (stroke && !stroke.disabled) {
      strokeThickness = stroke.thickness || 0;
    }
    const cssParser = StyleHelper.initCSSStyleParser(properties);
    const allowUsePaddingForText = type !== CLine;
    const paddingValue = allowUsePaddingForText ? strokeThickness || 0 : 0;
    const padding = {
      left: paddingValue,
      top: paddingValue,
      right: paddingValue,
      bottom: paddingValue,
    };
    let textWidth: number | undefined = undefined;
    let textHeight: number | undefined = undefined;
    let overflow = undefined;
    const vertical = textFormat?.vertical || multiText?.vertical;
    if (properties.padding) {
      const { left, top, right, bottom } = properties.padding;
      // 水滴组件自带26px底边距
      if (
        libData?.shortCut === 'D' &&
        ((vertical && textFormat?.textAlign === 'right') || (!vertical && textFormat?.verticalAlign === 'bottom'))
      ) {
        padding.bottom = (padding.bottom + (bottom || 0)) / FontBoxScale;
      }
      if (properties.padding.disabled === false) {
        if (!vertical) {
          padding.left = (padding.left + (left || 0)) / FontBoxScale;
          padding.right = (padding.right + (right || 0)) / FontBoxScale;
          padding.bottom = (padding.bottom + (bottom || 0)) / FontBoxScale;
          padding.top = (padding.top + (top || 0)) / FontBoxScale;
        } else {
          padding.top = (padding.top + (left || 0)) / FontBoxScale;
          padding.right = (padding.right + (top || 0)) / FontBoxScale;
          padding.bottom = (padding.bottom + (right || 0)) / FontBoxScale;
          padding.left = (padding.left + (bottom || 0)) / FontBoxScale;
        }
      }
    }
    if (props.valueEditing) {
      textWidth = vertical ? undefined : width - padding.left - padding.right;
      textHeight = vertical ? height - padding.top - padding.bottom : undefined;
    } else {
      textWidth = vertical ? undefined : width;
      textHeight = vertical ? height : undefined;
      if (properties['overflow'] && properties['overflow'].value) {
        overflow = 'hidden';
        if (vertical) {
          textWidth = width - padding.left - padding.right;
        } else {
          textHeight = height - padding.top - padding.bottom;
        }
      }
    }
    let isFill = true;
    if ((!fill || fill.disabled) && (!stroke || stroke.disabled)) {
      isFill = false;
    }
    let shadowStyle: React.CSSProperties = {};

    if (!isFill && !shadow?.disabled) {
      shadowStyle = isFill ? cssParser.getShadowStyle() : cssParser.getTextShadow(1 / FontBoxScale);
    }
    return {
      ...cssParser.getTextStyle(),
      ...shadowStyle,
      paddingLeft: padding.left,
      paddingTop: padding.top,
      paddingRight: padding.right,
      paddingBottom: padding.bottom,
      maxWidth: textWidth,
      maxHeight: textHeight,
      overflow,
    };
  };

  private handleSubmit = (
    value: string,
    option?: {
      wrap?: boolean;
      size?: ISize;
    },
    holdEdit?: boolean,
  ) => {
    const { onValueEdited, comp } = this.props;
    if (!holdEdit) {
      const selection = document.getSelection();
      if (selection) {
        selection.empty();
      }
    }
    onValueEdited && onValueEdited(comp, value, { wrap: option?.wrap }, holdEdit);
  };

  private handleValueEditing = () => {
    const { onValueEditing, comp } = this.props;
    onValueEditing && onValueEditing(comp);
  };

  protected renderTextFragment(text: string, properties: IProperties, opacity?: number) {
    const { comp, valueEditing, isPreview } = this.props;
    // 非编辑模式下，透明的或无文本内容时
    if ((!text || !opacity) && !valueEditing) {
      return null;
    }
    const { size, textFormat } = comp;
    let { width, height } = size;
    const { multiText, textStyle } = properties;
    const wrap = textFormat?.wrap || multiText?.wrap;
    const vertical = (textFormat || multiText)?.vertical;
    const verticalAlignModel = textFormat?.verticalAlign || textStyle?.verticalAlign || 'middle';
    const style = depthClone(this.state.textStyle);
    const fontSize = (textFormat || textStyle)?.fontSize || 14;
    const letterSpace = (textFormat || textStyle)?.letterSpace || 0;
    const lineHeightEx = textFormat?.lineHeightEx || comp.lineHeight || 20;
    const verticalAligns = ['top', 'center', 'bottom'];
    let verticalAlign: string = verticalAlignModel;
    if (style) {
      style.transition = comp.getTransition();
      style.fontSize = fontSize / FontBoxScale;
      style.lineHeight = lineHeightEx / FontBoxScale + 'px';
      style.letterSpacing = letterSpace / FontBoxScale;
      style.transform = 'scale(' + FontBoxScale + ')';
      style.left = 0;
      if (!verticalAligns.includes(verticalAlign)) {
        verticalAlign = 'center';
      }
      if (vertical) {
        style.height = height / FontBoxScale + 'px';
        style.transformOrigin = 'left top';
        style.width = undefined;
        switch (verticalAlign) {
          case 'top':
            style.right = 0;
            style.left = undefined;
            style.transformOrigin = 'right top';
            break;
          case 'center':
            style.transform += ' translateX(-50%)';
            break;
          default:
            break;
        }
        style.top = '50%';
        style.transform += ' translateY(-50%)';
      } else {
        style.width = width / FontBoxScale + 'px';
        style.height = undefined;
        style.transformOrigin = 'left ' + verticalAlign;
        style.left = '50%';
        // 线条和箭头
        if (comp.type === 'line') {
          style.transformOrigin = 'left top';
          style.transform += ' translate(-50%, -50%)';
        } else {
          style.transform += ' translateX(-50%)';
          if (verticalAlign === 'center') {
            style.transform += ' translateY(-' + 50 / FontBoxScale + '%)';
          }
        }
      }
      if (comp.lib?.type === 'drops') {
        style.top = '37%';
      }
    }
    return createShapeCompTextFragment(comp, text || '', {
      onChanged: this.handleSubmit,
      onChanging: this.handleValueEditing,
      editing: valueEditing,
      wrap,
      style,
      opacity,
      className: classnames(
        'lib-shape-comp-text',
        verticalAlignModel,
        {
          wrap,
          vertical,
          editing: valueEditing,
          normal: !isPreview && !valueEditing,
          preview: isPreview,
        },
        comp.getInheritClassName(),
      ),
    });
  }

  render(): any {
    return null;
  }
}
