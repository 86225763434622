import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { getShapeData, shapeDefaultSize100_60, flowBase, getShapePropertiesData } from './shapeDataFragment';

// 斜角形成的等腰三角形腰长为5，组件的宽度或者高度小于10，三角形腰长等比缩小
// const basePosition = '(Math.min(sw,sh)>=10?5:(Math.min(sw,sh)*(5/10)))';
const basePosition = 'Math.min(sw,sh)*(5/60)';

// '斜角矩形'
export default {
  ...flowBase,
  name: i18n('resource.flow.beveledRectangle'),
  type: 'beveledRectangle',
  thumb: {
    spriteIconClass: SpriteThumb.Beveledrectangle.className,
    dragPosition: SpriteThumb.Beveledrectangle.position,
  },
  template: getShapeData(i18n('resource.flow.beveledRectangle'), shapeDefaultSize100_60, {
    data: [
      { point: { x: 5, y: 0 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: 95, y: 0 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: 100, y: 5 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: 100, y: 55 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: 95, y: 60 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: 5, y: 60 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: 0, y: 55 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: 0, y: 5 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
    ],
    closed: true,
    isTemplateShape: true,
    templateData: [
      { point: { x: basePosition, y: 0 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: `sw-${basePosition}`, y: 0 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: 'sw', y: basePosition }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: 'sw', y: `sh-${basePosition}` }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: `sw-${basePosition}`, y: 'sh' }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: basePosition, y: 'sh' }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: 0, y: `sh-${basePosition}` }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: 0, y: basePosition }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
    ],
  }),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
