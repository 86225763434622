import { measureTextSize } from '@utils/textUtils';
import { max } from '@utils/globalUtils';

import { MoveDelta } from '@fbs/common/models/resize';
import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';

import i18n from '@i18n';

import { ArtboardPatchesClass } from '@editor/patches/artboardPatches';
import { UIComponent, UIContainerComponent } from '@editor/comps';
import { getFinalPositionWhenMove } from '@editor/corePartial/helper';
import { ComponentPatchesClass } from '@editor/patches/ComponentPatches';
import { PredefinedStates } from '@consts/state';
import { SpriteThumb } from '@/consts/spriteIcons';
import { getDefaultDataFromTemplate } from '@/libs/helper';

import { IComponentItem } from '../../types';
import { CIcon } from '../../constants';
import { SizeMode, MoveMode } from '../../enum';

const breadCrumbsText = i18n('resource.componentsText.breadCrumbsText');

const { height: textHeight } = measureTextSize({ fontSize: 14 }, `${breadCrumbsText} 2`);

const parseItem = (str: string) => {
  const splitArr = str.split('|');
  const mapArr = splitArr.map((item) => {
    return `@@ITEM-${item}|${measureTextSize({ fontSize: 14 }, item).width}`;
  });
  return mapArr.join(',');
};

const height = Math.max(textHeight, 20);

const itemTemplate = `
    {
      type: @@C.StackPanel,
      layout:{
        horizontal: @@HorizontalAlign.Auto,
        vertical: @@VerticalAlign.TopAndBottom,
        fixedWidth: false,
        fixedHeight: false,
        auto: true,
        responsive: true,
      },
      properties:{
        layout:{
          disabled: false,
          direction: 'horizontal',
          verticalAlign: 'middle',
          horizontalGap: 4,
          disabledSwitch: false,
        }
      },
      position:{x:0, y: 0},
      size:{width: {1} + 15, height: ${height}},
      components:[
      {
        type: @@C.PureText,
        alias:"text",
        size: {
          width: {1},
          height: ${textHeight},
        },
        autoSize: true,
        position:{x: 0, y: ${Math.round((height - textHeight) / 2)}},
        layout: {
          vertical: @@VerticalAlign.Middle,
          horizontal: @@HorizontalAlign.LeftAndRight,
          fixedWidth: true,
          fixedHeight: true,
          auto: false,
          responsive: true,
        },
        properties: {
          textStyle: {
            ref: '@properties.textStyle'
          },
          shadow:{
            ref: '@properties.shadow',
          }
        },
        states: {
          @@PredefinedStates.hover: {
            enabled: true,
            properties: {
              textStyle: {
                ref: '@properties.hoverTextStyle'
              }
            }
          }
        },
        value: '{0}'
      },
      { 
        type: @@C.Icon,
        alias:'icon',
        size:{
          width: 12,
          height: 12,
        },
        position:{
          x: {1} - 5, y: ${Math.round((height - 12) / 2)},
        },
        layout:{
          vertical: @@VerticalAlign.Middle,
          horizontal: @@HorizontalAlign.Left,
          auto: true,
          fixedWidth: false,
          fixedHeight: false,
          responsive: true,
        },
        properties:{
          icon:{
            ref: '@properties.icon',
          },
          shadow:{
            ref: '@properties.shadow',
          }
        },
        value: {
          iconCode: 59796,
          fontName: 'boldIconFont',
        },          
      },
      ]
    }`;

const template = `{
    type: @@C.StackPanel,
    size: {width: 300,height: ${height}},
    position: {x: 0, y: 0},
    layout:{
      vertical: 'middle',
      horizontal: 'left',
      fixedWidth: true,
      fixedHeight: true,    
      auto: true,
      responsive: true,
    },
    properties:{
      layout:{
        direction: 'horizontal',
        horizontalGap: 10,
        verticalAlign: 'middle',
        disabledSwitch: true,
      },
      textStyle:{
        color: @@SystemColors.DefaultTextColor,
        textAlign: @@TextAlign.left,
        fontFamily: 'Microsoft YaHei',
        fontStyle: { underline: false, bold: false, strike: false, italic: false },
        fontSize: 14,
      },
      hoverTextStyle:{
        disabled: false,
        prop: 'textStyle',
        name: '${i18n('property.propertyNames.hoverTextStyle')}',
        fontFamily: 'Microsoft YaHei',
        fontSize: 14,
        color: @@SystemColors.RedColor,
        fontStyle:{ underline: true, bold: false, strike: false, italic: false },
      },
      icon:{
        color: @@SystemColors.DefaultTextColor,
      },
      shadow: {
        disabled: true,
        hidden: false,
        x: 1,
        y: 1,
        blur: 2,
        color: @@SystemColors.TextShadowColor,
      },
    },
    components:[
      ${parseItem(breadCrumbsText)}
    ],
    select:{
      target: 'child',
      enabled: true,
      maxCount: 1,
      minCount: 0,
      reversible: false,
      autoUnselect: true,
    },
    sealed: true,
  }`;

/**
 * 面包屑子项移动时
 * @description 用于高级编辑
 * @param {MoveDelta} delta
 * @param {UIContainerComponent} sealedComp
 * @param {UIComponent[] | undefined} children
 * @returns {ArtboardPatches | null}
 */
function onChildMove(
  delta: MoveDelta,
  sealedComp: UIContainerComponent,
  children?: UIComponent[] | undefined,
): ArtboardPatches | null {
  const isMoveIcon = children && children.length === 1 && (children[0].alias === 'icon' || children[0].type === CIcon);
  const patches = new ArtboardPatchesClass();

  if (isMoveIcon) {
    const movingComp = children![0];
    const originPosition = movingComp.position;
    const parentOfMovingComp = movingComp.parent!;
    const newPosition = getFinalPositionWhenMove([movingComp], delta)[0].position;
    const path = parentOfMovingComp.getCurrentPropertiesPath('properties/layout');
    const compId = parentOfMovingComp.id;
    const horizontalDiff = newPosition.x - originPosition.x;
    const properties = parentOfMovingComp.properties;
    const newValue = {
      ...properties.layout,
      horizontalGap: max(0, properties.layout?.horizontalGap! + horizontalDiff),
    };
    const componentPatches = new ComponentPatchesClass().getAttrChangePatches(compId, path, {
      oldVal: properties,
      newVal: newValue,
    });
    const result = patches.getPatchesByCompChange(compId, componentPatches);

    const { id, currentStateID, states } = parentOfMovingComp;
    if (currentStateID && currentStateID !== PredefinedStates.normal && !states[currentStateID]) {
      const path = `./states/${currentStateID}`;
      result.do[id].unshift(Ops.add(path, { enabled: true, properties: {} }));
      result.undo[id].push(Ops.remove(path));
    }
    return result;
  }
  return null;
}

export const BreadCrumbsCfg: IComponentItem = {
  name: i18n('resource.components.breadCrumbs'),
  type: 'breadCrumbs',
  thumb: {
    spriteIconClass: SpriteThumb.BreadCrumbs.className,
    dragPosition: SpriteThumb.BreadCrumbs.position,
  },
  constraint: {
    text: {
      resize: SizeMode.none,
      move: MoveMode.neither,
    },
    icon: {
      move: MoveMode.horizontal,
    },
  },
  editor: {
    onChildMove,
  },
  sizeMode: SizeMode.none,
  isList: true,
  isTextComp: true, // 数据填充文本-判断需要
  item: itemTemplate,
  template,
  itemArgs: [
    {
      type: 'string',
      value: 'Item',
    },
    {
      type: 'number',
      value: measureTextSize({ fontSize: 14 }, 'Item 2').width,
    },
  ],
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
};

const defaultData = getDefaultDataFromTemplate(BreadCrumbsCfg);
