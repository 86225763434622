import * as React from 'react';

import './index.scss';

interface IPopContentProps {
  content: React.ReactNode;
  minWidth?: string | number;
}

const PopContent: React.FC<IPopContentProps> = (props: IPopContentProps) => {
  const { content, minWidth } = props;
  return (
    <div className="dsm-c-popover-content" style={{ minWidth }}>
      <div className="dsm-c-popover-content-arrow" />
      {content}
    </div>
  );
};

export default PopContent;
