import { get, set } from 'lodash';

import { IComponentData } from '@fbs/rp/models/component';
import IArtboard from '@fbs/rp/models/artboard';
import { IProperties } from '@fbs/rp/models/property';
import { ISize } from '@fbs/common/models/common';
import { IPosition } from '@fbs/idoc/models/common';
import { FillType } from '@fbs/rp/models/properties/fill';

import i18n from '@i18n';

import { CSelect } from '@libs/constants';
import { getDefaultComponentName } from '@libs/libs';

import { UIContainerComponent } from './comps';
import { getNewPositionWhenCenter } from './comps/resizeHelper';

export function resetCompData(containerComponent: UIContainerComponent, compData: IComponentData, artboardSize: ISize) {
  const { width: artboardWidth, height: artboardHeight } = artboardSize;
  const {
    position: { x, y },
  } = containerComponent;
  const {
    size: { width, height },
  } = compData;

  compData.position = {
    x: Math.max(0, Math.min(x, artboardWidth - width)),
    y: Math.max(0, Math.min(y, artboardHeight - height)),
  };
  compData.position = getNewPositionWhenCenter(
    compData,
    compData.position,
    compData.size,
    { width: artboardWidth, height: artboardHeight },
    { isLayoutMiddleAtVertical: true, isLayoutCenterAtHorizontal: true },
  );
  compData.selected = compData.disabled = false;
  compData._currentState = undefined;
}

export const modifyChildProperties = (childProperties: IProperties, containerProperties: IProperties) => {
  Object.keys(childProperties).forEach((propName) => {
    const property = childProperties[propName]!;
    if (property.ref) {
      const ownerPropName = property.ref.replace('@properties.', '');
      const containerProperty = get(containerProperties, ownerPropName);
      if (containerProperty) {
        set(childProperties, propName, { ...containerProperty, name: undefined, ref: property.ref });
      }
    }
  });
};

/**
 * 组件在某些情况下,只需要稍大于自身的面板当背景板
 * @param comp
 */
export function getCompAdvanceArtboardSize(comp: IComponentData) {
  let plus = 100;
  return {
    height: comp.size.height + plus,
    width: comp.size.width + plus,
  };
}

export function getArtboardData(
  mainArtboardData: IArtboard,
  containerComponent: UIContainerComponent,
  ownerArtboardInfo: { size: ISize; position: IPosition },
  compDatas: IComponentData[],
): IArtboard[] {
  const isSelect = containerComponent.type === CSelect;

  const first = compDatas[0];
  const defaultBackground = {
    color: { r: 255, g: 255, b: 255, a: 1 },
    type: FillType.solid,
    disabled: false,
  };
  const appID = containerComponent.compType;
  const artboard: IArtboard[] = [
    {
      ...mainArtboardData,
      guides: {
        vertical: [],
        horizontal: [],
      },
      _id: containerComponent.id,
      name: getDefaultComponentName(containerComponent.type, containerComponent.lib),
      appID,
      size: getCompAdvanceArtboardSize(first),
      type: 'main',
      position: ownerArtboardInfo.position,
      components: [first],
      background: defaultBackground,
    },
  ];
  if (isSelect) {
    const [, dropDown] = compDatas;
    artboard.push({
      ...mainArtboardData,
      _id: dropDown._id,
      name: i18n('resource.components.dropDown'),
      appID,
      size: getCompAdvanceArtboardSize(dropDown),
      type: dropDown.alias || 'drop-down',
      position: {
        x: ownerArtboardInfo.position.x + artboard[0].size.width + 100,
        y: ownerArtboardInfo.position.y,
      },
      background: defaultBackground,
      components: [dropDown],
    });
  }
  return artboard;
}

export type FlatCompListItem = { comp: IComponentData; parent?: IComponentData };
/**
 * 将树状的父子关系的组件结构,转换成一位数组,存储在 list 中
 * @param comp
 * @param list 存储的结果
 * @param parent
 */
export const flatChild = (comp: IComponentData, list: FlatCompListItem[], parent?: IComponentData) => {
  list.push({ comp, parent });
  comp.components?.forEach((c) => flatChild(c, list, comp));
};
