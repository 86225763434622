import { get, round } from 'lodash';

import i18n from '@/i18n';
import { PredefinedStates } from '@/consts/state';
import { SpriteThumb } from '@/consts/spriteIcons';
import * as SystemsColor from '@consts/colors';
import { roundNumberObject } from '@utils/globalUtils';
import { getNewID } from '@helpers/idHelper';
import { mergePatches } from '@/helpers/patchHelper';
import { UIComponent, UIContainerComponent } from '@/editor/comps';
import { StrokeLineCap, StrokeLineJoin, StrokePosition } from '@/fbs/rp/models/properties/stroke';
import { FillType } from '@/fbs/rp/models/properties/fill';
import { VerticalAlign, HorizontalAlign } from '@/fbs/rp/models/layout';
import { CarouselChartPropertyName, IndicatorType, EffectType } from '@/fbs/rp/models/properties/carouselChart';
import { Ops, ArtboardPatches } from '@/fbs/rp/utils/patch';
import { IComponentData } from '@/fbs/rp/models/component';
import { ISize } from '@/fbs/common/models/common';
import { PropertyValue } from '@/fbs/rp/models/property';

import * as DefaultImg1 from '@assets/image/defaultCarousel1.png';
import * as DefaultImg2 from '@assets/image/defaultCarousel2.png';

import { CEllipse, CRect, CImage, CCanvasPanel, CIcon, CCarouselChart } from '../../constants';
import { makeCommonComponent, getDefaultShadow } from '../../helper';
import { IComponentItem } from '../../types';

export enum ImageAliasType {
  Default1 = 'default1',
  Default2 = 'default2',
  Normal = 'normal',
}

type TBtnType = 'left' | 'right';

const DEFAULT_SIZE = { width: 375, height: 150 };
const DEFAULT_MASK_SIZE = { width: 375, height: 40 }; // 蒙层的size
const DEFAULT_PAGE_TURN_SIZE = 30; // 左右侧翻页按钮宽高
const PAGE_TURN_ICON_SIZE = 12; // 左右侧按钮内图标大小
const PAGE_TURN_BTN_SPACE = 12; // 翻页按钮距离两边距离
const INDICATOR_PADDING_BOTTOM = 8; // 指示器距底部距离
const INDICATOR_SPACE = 6; // 每个指示器之间的间距
// 每个指示器类型和size
const INDICATOR_DATE = {
  [IndicatorType.Circle]: {
    size: { width: 6, height: 6 },
    type: CEllipse,
  },
  [IndicatorType.Rect]: {
    size: { width: 22, height: 2 },
    type: CRect,
  },
};
// 左右翻页器信息
const TURN_BTN_DATES = {
  left: {
    alias: 'page-turn-left',
    name: 'pageTurnLeft',
    horizontal: HorizontalAlign.Left,
    x: PAGE_TURN_BTN_SPACE,
    rotate: 0,
  },
  right: {
    alias: 'page-turn-right',
    name: 'pageTurnRight',
    horizontal: HorizontalAlign.Right,
    x: DEFAULT_SIZE.width - DEFAULT_PAGE_TURN_SIZE - PAGE_TURN_BTN_SPACE,
    rotate: 180,
  },
};
const DEFAULT_IMGS = [ImageAliasType.Default1, ImageAliasType.Default2]; // 默认图标alias，默认个数
const DEFAULT_VALUE: string[] = []; // 默认图片地址 ，引用类型方便引用修改
const BASE_RATIO = DEFAULT_SIZE.width / DEFAULT_SIZE.height; // 翻页按钮比较基准值
const CHANGE_BEGIN_SIZE = { width: 430, height: 430 / BASE_RATIO }; // 缩放时，起始变化size
const CHANGE_END_SIZE = { width: 710, height: 710 / BASE_RATIO }; // 超出不再变化，最大缩放为2倍
const MAX_CHANGE_WIDTH = CHANGE_END_SIZE.width - CHANGE_BEGIN_SIZE.width;
const MAX_CHANGE_HEIGHT = MAX_CHANGE_WIDTH / BASE_RATIO;

/**
 * AI修改默认引用类型的值
 * @param params
 */
export const AIChangeDefaultQuoteVariable = function (params: {
  size?: ISize;
  value?: string[];
}): { release: () => void } {
  const default_size = Object.assign({}, DEFAULT_SIZE);
  if (params.size) {
    Object.assign(DEFAULT_SIZE, params.size);
    Object.assign(DEFAULT_MASK_SIZE, params.size);
  }

  const default_value = DEFAULT_VALUE.slice();
  if (params.value) {
    DEFAULT_VALUE.splice(0, DEFAULT_VALUE.length);
    params.value.forEach((v, i) => {
      DEFAULT_VALUE[i] = v;
    });
  }
  return {
    // 释放数据，恢复原始默认值
    release() {
      Object.assign(DEFAULT_SIZE, default_size);
      Object.assign(DEFAULT_MASK_SIZE, default_size);

      DEFAULT_VALUE.slice(0, default_value.length);
      default_value.forEach((v, i) => {
        DEFAULT_VALUE[i] = v;
      });
    },
  };
};

// 获取实际的图片
export function getRealUrl(comp: UIComponent) {
  const { alias, value } = comp;
  switch (alias) {
    case ImageAliasType.Default1: {
      return DefaultImg1;
    }
    case ImageAliasType.Default2: {
      return DefaultImg2;
    }
    default: {
      return value;
    }
  }
}

/**
 * 根据当前组件的size计算翻页按钮以及指示器缩放比例
 * 已默认宽高比为基准，大于宽高比，以高为比较值，小于则以宽为比较值，最大放大两倍 宽度处于430-710之间进行缩放
 * @param newSize
 */
function calcScaleRatio(newSize: ISize) {
  const newRatio = newSize.width / newSize.height;
  let realRatio = 0;
  if (newRatio > BASE_RATIO) {
    // 宽偏长，根据高度计算size
    realRatio = (newSize.height - CHANGE_BEGIN_SIZE.height) / MAX_CHANGE_HEIGHT;
  } else {
    // 高偏长，根据宽度计算size
    realRatio = (newSize.width - CHANGE_BEGIN_SIZE.width) / MAX_CHANGE_WIDTH;
  }
  realRatio = realRatio + 1;

  let scaleRatio = realRatio;
  if (realRatio < 1) {
    scaleRatio = 1;
  } else if (realRatio > 2) {
    scaleRatio = 2;
  }
  return { scaleRatio, realRatio };
}

/**
 * 计算指示器的size和position
 * @param len 指示器个数
 * @param mainSize 轮播图size
 * @param indicatorType 指示器类型
 */
export function calcIndicatorSizeAndPosition(
  len: number,
  mainSize: ISize,
  indicatorType: IndicatorType = IndicatorType.Circle,
  ratio?: number,
) {
  const scaleRatio = ratio || calcScaleRatio(mainSize).scaleRatio;
  const baseSize = INDICATOR_DATE[indicatorType].size;
  const size = {
    height: round(baseSize.height * scaleRatio),
    width: round(len * baseSize.width * scaleRatio + (len - 1) * INDICATOR_SPACE * scaleRatio),
  };
  const position = {
    x: round((mainSize.width - size.width) / 2),
    y: round(mainSize.height - INDICATOR_PADDING_BOTTOM * scaleRatio - size.height),
  };
  return { size, position };
}

export function calcIndicatorItemInfo(
  container: UIContainerComponent,
  indicatorType?: IndicatorType,
  scaleRatio?: number,
) {
  const _indicatorType =
    indicatorType || (get(container, 'properties.carousel.indicator.indicatorType') as IndicatorType);
  const _scaleRatio = scaleRatio || calcScaleRatio(container.size).scaleRatio;
  const baseSize = INDICATOR_DATE[_indicatorType].size;
  const size = {
    width: round(baseSize.width * _scaleRatio),
    height: round(baseSize.height * _scaleRatio),
  };
  const space = round(INDICATOR_SPACE * _scaleRatio);
  return { space, size };
}

function getTurnBtnCompPatches(containerSize: ISize, comp: UIContainerComponent, scaleRatio: number, type: TBtnType) {
  const iconComp = comp.components[0];
  const size = round(DEFAULT_PAGE_TURN_SIZE * scaleRatio);
  const iconSize = round(PAGE_TURN_ICON_SIZE * scaleRatio);
  const position = {
    x: type === 'right' ? containerSize.width - size - PAGE_TURN_BTN_SPACE : PAGE_TURN_BTN_SPACE,
    y: round((containerSize.height - size) / 2),
  };
  const iconPositionPX = round((size - iconSize) / 2);
  const iconPosition = { x: iconPositionPX, y: iconPositionPX };
  return {
    do: {
      [comp.id]: [
        Ops.replace('./size', { width: size, height: size }),
        Ops.replace('./position', roundNumberObject(position)),
      ],
      [iconComp.id]: [
        Ops.replace('./size', roundNumberObject({ width: iconSize, height: iconSize })),
        Ops.replace('./position', roundNumberObject(iconPosition)),
      ],
    },
    undo: {
      [comp.id]: [Ops.replace('./size', comp.size), Ops.replace('./position', comp.position)],
      [iconComp.id]: [Ops.replace('./size', iconComp.size), Ops.replace('./position', iconComp.position)],
    },
  };
}

// 拖拽时，指示器与蒙层产生的patches
function getIndictorCompPatches(containerSize: ISize, container: UIContainerComponent, scaleRatio: number) {
  const maskComp = container.getComponentByAlias('indicator-mask') as UIContainerComponent;
  const indicatorComp = container.getComponentByAlias('indicator-main') as UIContainerComponent;
  const indicatorType = get(container, 'properties.carousel.indicator.indicatorType');
  const { size, position } = calcIndicatorSizeAndPosition(
    indicatorComp.components.length,
    containerSize,
    indicatorType,
    scaleRatio,
  );
  const patches = {
    do: {
      [indicatorComp.id]: [Ops.replace('./size', size), Ops.replace('./position', position)],
    },
    undo: {
      [indicatorComp.id]: [
        Ops.replace('./size', indicatorComp.size),
        Ops.replace('./position', indicatorComp.position),
      ],
    },
  };
  if (maskComp) {
    const newHeight = round(DEFAULT_MASK_SIZE.height * scaleRatio);
    patches.do[maskComp.id] = [
      Ops.replace('./size', { width: containerSize.width, height: newHeight }),
      Ops.replace('./position', { x: maskComp.position.x, y: containerSize.height - newHeight }),
    ];
    patches.undo[maskComp.id] = [Ops.replace('./size', maskComp.size), Ops.replace('./position', maskComp.position)];
  }
  return patches;
}

// 指示器item大小和位置的变化
function getIndicatorItemsPatches(container: UIContainerComponent, indicatorType: IndicatorType, scaleRatio: number) {
  const patches: ArtboardPatches = { do: {}, undo: {} };
  const comps = (container.getComponentByAlias('indicator-main') as UIContainerComponent).components as UIComponent[];
  const { space, size: itemSize } = calcIndicatorItemInfo(container, indicatorType, scaleRatio);
  comps.forEach((comp, index) => {
    patches.do[comp.id] = [
      Ops.replace('./size', itemSize),
      Ops.replace('./type', INDICATOR_DATE[indicatorType].type),
      Ops.replace('./position', { ...comp.position, x: (itemSize.width + space) * index }),
    ];
    patches.undo[comp.id] = [
      Ops.replace('./size', comp.size),
      Ops.replace('./position', comp.position),
      Ops.replace('./type', comp.type),
    ];
  });
  return patches;
}

// 处理样式变更
function onPropertyUpdate(comp: UIComponent, propertyName: string, newValue: PropertyValue) {
  // 指示器类型变更, 修改每项的size和整体size与position
  const container = comp as UIContainerComponent;
  const newType = get(newValue, 'indicator.indicatorType') as IndicatorType;
  if (propertyName === 'carousel' && newType !== get(comp, 'properties.carousel.indicator.indicatorType')) {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    const indicatorComp = container.getComponentByAlias('indicator-main') as UIContainerComponent;
    const indicatorComps = indicatorComp.components;
    const scaleRatio = calcScaleRatio(container.size).scaleRatio;
    const { size, position } = calcIndicatorSizeAndPosition(indicatorComps.length, container.size, newType, scaleRatio);
    patches.do[indicatorComp.id] = [Ops.replace('./size', size), Ops.replace('./position', position)];
    patches.undo[indicatorComp.id] = [
      Ops.replace('./size', indicatorComp.size),
      Ops.replace('./position', indicatorComp.position),
    ];
    const itemPatches = getIndicatorItemsPatches(container, newType, scaleRatio);
    mergePatches(patches, itemPatches);
    return patches;
  }
  return null;
}

function onResize(comp: UIComponent, newSize: ISize) {
  const container = comp as UIContainerComponent;
  const { scaleRatio } = calcScaleRatio(newSize);
  const patches: ArtboardPatches = { do: {}, undo: {} };
  const leftBtnComp = container.getComponentByAlias('page-turn-left') as UIContainerComponent;
  const rightBtnComp = container.getComponentByAlias('page-turn-right') as UIContainerComponent;
  const indicatorType = get(container, 'properties.carousel.indicator.indicatorType') as IndicatorType;
  const leftCompPatches = getTurnBtnCompPatches(newSize, leftBtnComp, scaleRatio, 'left');
  const rightCompPatches = getTurnBtnCompPatches(newSize, rightBtnComp, scaleRatio, 'right');
  const indicatorCompPatches = getIndictorCompPatches(newSize, container, scaleRatio);
  const indicatorItemCompPatches = getIndicatorItemsPatches(container, indicatorType, scaleRatio);
  mergePatches(patches, leftCompPatches);
  mergePatches(patches, rightCompPatches);
  mergePatches(patches, indicatorCompPatches);
  mergePatches(patches, indicatorItemCompPatches);
  return patches;
}

/**
 * 演示时的状态触发
 * @param {UIComponent} container
 * @param {UIComponent} triggerComp
 * @param {string} stateName
 * @return {any}
 */
function onTriggerState(container: UIComponent, triggerComp: UIComponent) {
  const group = container as UIContainerComponent;
  const indicatorComp = group.getComponentByAlias('indicator-main') as UIContainerComponent;
  const comps = indicatorComp.components;
  const value = comps.findIndex((comp) => comp.id === triggerComp.id);
  return {
    [container.id]: [Ops.replace('./value', value)],
  };
}

function makeDefaultImgItem(alias: string, index = 0) {
  const useDefImage = alias === '' || alias === ImageAliasType.Default1 || alias === ImageAliasType.Default2;
  return makeCommonComponent(getNewID(), CImage, {
    size: { ...DEFAULT_SIZE },
    value: useDefImage ? '' : alias,
    alias: useDefImage ? alias || ImageAliasType.Default1 : ImageAliasType.Normal,
    properties: {
      image: DEFAULT_VALUE.length
        ? {
            fitMode: 'stretch',
            showModel: 'placeholder',
          }
        : {
            fitMode: 'fit',
            showModel: 'empty',
          },
      fill: {
        color: index > 0 ? SystemsColor.DefaultDarkFillColor : SystemsColor.DisabledDarkFillColor,
      },
    },
  });
}

// 图片list
function makeImageMain() {
  return makeCommonComponent(getNewID(), CCanvasPanel, {
    alias: 'image-main',
    name: 'imageMain',
    position: { x: 0, y: 0 },
    size: { ...DEFAULT_SIZE },
    layout: {
      auto: true,
      responsive: true,
      fixedWidth: false,
      fixedHeight: true,
      vertical: VerticalAlign.TopAndBottom,
      horizontal: HorizontalAlign.LeftAndRight,
    },
    components: (DEFAULT_VALUE.length ? DEFAULT_VALUE : DEFAULT_IMGS).map((img, index) =>
      makeDefaultImgItem(img, index),
    ),
  });
}

function makeIndicatorItem(index = 0) {
  const size = INDICATOR_DATE[IndicatorType.Circle].size;
  return makeCommonComponent(getNewID(), CEllipse, {
    alias: 'indicator',
    name: 'indicator',
    position: { x: (size.width + INDICATOR_SPACE) * index, y: 0 },
    size,
    layout: {
      vertical: VerticalAlign.Bottom,
      horizontal: HorizontalAlign.Center,
      auto: false,
      fixedWidth: true,
      fixedHeight: true,
      responsive: true,
    },
    properties: {
      fill: { ref: '@properties.carousel.indicator.defaultColor' },
    },
    states: {
      [PredefinedStates.checked]: {
        enabled: true,
        properties: {
          fill: { ref: '@properties.carousel.indicator.activeColor' },
        },
      },
    },
    selected: index === 0,
  });
}

// 指示器蒙层
function makeIndicatorMask() {
  return makeCommonComponent(getNewID(), CRect, {
    alias: 'indicator-mask',
    name: 'indicatorMask',
    size: { ...DEFAULT_MASK_SIZE },
    position: {
      x: 0,
      y: DEFAULT_SIZE.height - DEFAULT_MASK_SIZE.height,
    },
    layout: {
      vertical: VerticalAlign.Bottom,
      horizontal: HorizontalAlign.Auto,
      auto: false,
      fixedWidth: true,
      fixedHeight: true,
      responsive: true,
    },
  });
}

function makeIndicatorMain() {
  const { size, position } = calcIndicatorSizeAndPosition(DEFAULT_IMGS.length, { ...DEFAULT_SIZE });
  return makeCommonComponent(getNewID(), CCanvasPanel, {
    alias: 'indicator-main',
    name: 'indicatorMain',
    size,
    position: position,
    layout: {
      vertical: VerticalAlign.Bottom,
      horizontal: HorizontalAlign.Center,
      auto: false,
      fixedWidth: true,
      fixedHeight: true,
      responsive: true,
    },
    select: {
      minCount: 0,
      maxCount: 1,
      target: 'child',
      reversible: true,
      autoUnselect: true,
      enabled: true,
    },
    components: (DEFAULT_VALUE.length ? DEFAULT_VALUE : DEFAULT_IMGS).map((_img, index) => makeIndicatorItem(index)),
  });
}

function makePageTurn(type: TBtnType = 'left') {
  const info = TURN_BTN_DATES[type];
  return makeCommonComponent(getNewID(), CCanvasPanel, {
    alias: info.alias,
    name: info.name,
    position: { x: info.x, y: round((DEFAULT_SIZE.height - DEFAULT_PAGE_TURN_SIZE) / 2) },
    size: { width: DEFAULT_PAGE_TURN_SIZE, height: DEFAULT_PAGE_TURN_SIZE },
    layout: {
      vertical: VerticalAlign.Middle,
      horizontal: info.horizontal,
      auto: false,
      fixedWidth: true,
      fixedHeight: true,
      responsive: true,
    },
    components: [
      makeCommonComponent(getNewID(), CIcon, {
        value: 'icon_back',
        size: { width: PAGE_TURN_ICON_SIZE, height: PAGE_TURN_ICON_SIZE },
        position: {
          x: (DEFAULT_PAGE_TURN_SIZE - PAGE_TURN_ICON_SIZE) / 2,
          y: (DEFAULT_PAGE_TURN_SIZE - PAGE_TURN_ICON_SIZE) / 2,
        },
        rotate: info.rotate,
        properties: {
          icon: {
            color: SystemsColor.WhiteColor,
          },
        },
      }),
    ],
  });
}

export function makeCarouselChart(id: string): IComponentData {
  const makeData = {
    ...CarouselChartConfig.getDefaultData?.(),
    _id: id,
    alias: 'carouselChart',
    size: { ...DEFAULT_SIZE },
    layout: {
      auto: true,
      responsive: true,
      fixedWidth: false,
      fixedHeight: true,
      vertical: VerticalAlign.TopAndBottom,
      horizontal: HorizontalAlign.LeftAndRight,
    },
    states: {
      [PredefinedStates.disabled]: {
        enabled: false,
        opacity: 30,
        properties: {},
      },
    },
    value: 0,
    sealed: true,
  };
  return makeCommonComponent(id, CCarouselChart, {
    ...makeData,
    components: [makePageTurn(), makeImageMain(), makeIndicatorMask(), makeIndicatorMain(), makePageTurn('right')],
  });
}

export const CarouselChartConfig: IComponentItem = {
  type: CCarouselChart,
  thumb: {
    spriteIconClass: SpriteThumb.CarouselChart.className,
    dragPosition: SpriteThumb.CarouselChart.position,
  },
  isList: true,
  isTextComp: false,
  name: i18n('resource.components.carouselChart'),
  editor: {
    onPropertyUpdate,
    onResize,
  },
  preview: {
    onTriggerState,
  },
  getDefaultData() {
    return {
      properties: {
        layout: {
          direction: 'vertical',
          hidden: true,
        },
        cell: {
          ratioWidth: true,
          hidden: true,
        },
        border: { bottom: true, top: true, left: true, right: true },
        stroke: {
          cap: StrokeLineCap.Butt,
          color: SystemsColor.DefaultStrokeColor,
          disabled: false,
          join: StrokeLineJoin.Miter,
          mode: 'custom',
          thickness: 1,
          position: StrokePosition.inner,
        },
        radius: {
          topLeft: 0,
          topRight: 0,
          bottomLeft: 0,
          bottomRight: 0,
          isPercent: false,
          disabled: false,
        },
        shadow: getDefaultShadow(),
        carousel: {
          prop: CarouselChartPropertyName,
          showAutoplay: true,
          indicator: {
            showIndicator: true,
            indicatorType: IndicatorType.Circle,
            defaultColor: {
              disabled: false,
              type: FillType.solid,
              color: SystemsColor.MarkerStripColor,
            },
            activeColor: {
              disabled: false,
              type: FillType.solid,
              color: SystemsColor.DefaultWhiteFillColor,
            },
          },
          showPageTurnBtn: false,
          effect: EffectType.Push,
          playInterval: 3000,
          playSpeed: 700,
        },
      },
    };
  },
};
