import * as React from 'react';

import Checkbox from '../../Checkbox';
import SearchInput from '../../SearchInput';
import SearchList from './SearchList';
import ScrollView from '../../ScrollView';
import CheckGroup from './CheckGroup';

import { IGroupProps, IItemProps } from '../../../models';
import { CheckType, TreeTransferKeys } from '../../../constants/TreeTransfer';
import { loadFromCache } from '../../utils';
import i18n from '../../../i18n';

const GroupSculpture = 'https://img02.mockplus.cn/idoc/image/2020-09-23/fbdfd2f1-fd67-11ea-9a10-8fdd726ad5b3.png';

import './index.scss';
import LastGroup from './LastGroup';

interface ICheckListProps {
  enableEmailSearch?: boolean;
  showAddGroup?: boolean;
  autoFocus?: boolean;
  groups: IGroupProps[];
  singles: IItemProps[];
  searchPlaceholder: string;
  lastKey?: TreeTransferKeys;
  addGroupTooltip: string;
  checkedItems: string[];
  checkedGroups: string[];
  disabledGroup?: number[];
  hideGroupCheck: boolean;
  maxCount: number;
  allItems: IItemProps[];
  enableThirdPartyUUIDSearch?: boolean;

  onCheckItem(ids: string[]): void;
  onCheckGroup(ids: string[]): void;
  onCheckDisable?(id: string): void;
  onSearch?(search?: string): void;
}

const getLastInfo = (
  allItems: IItemProps[],
  groups: IGroupProps[],
  disabledGroup?: number[],
  lastKey?: TreeTransferKeys,
  showAddGroup?: boolean,
): { lastItems: IItemProps[]; lastGroups: IItemProps[] } => {
  if (!lastKey) {
    return { lastItems: [], lastGroups: [] };
  }
  const lastItems = loadFromCache(`${lastKey}-items`) || [];
  const lastItemsInfo: IItemProps[] = allItems.filter((item) => lastItems.includes(item.id));

  let lastGroupsInfo: IItemProps[] = [];

  if (showAddGroup) {
    const lastGroups = loadFromCache(`${lastKey}-groups`) || [];
    const lastGroupsInCurrentGroups = groups.filter((item) => lastGroups.includes(item.id));
    lastGroupsInfo = lastGroupsInCurrentGroups.map((item) => ({
      id: item.id,
      name: item.name,
      email: '',
      imageURL: GroupSculpture,
      disabled: disabledGroup?.includes(Number(item.id)),
      isGroup: true,
    }));
  }

  return { lastItems: lastItemsInfo, lastGroups: lastGroupsInfo };
};

const CheckList: React.FC<ICheckListProps> = (props: ICheckListProps) => {
  const {
    enableEmailSearch,
    showAddGroup,
    autoFocus,
    groups,
    singles,
    searchPlaceholder,
    lastKey,
    addGroupTooltip,
    checkedItems,
    checkedGroups,
    disabledGroup,
    hideGroupCheck,
    maxCount,
    allItems,
    enableThirdPartyUUIDSearch,
    onCheckItem,
    onCheckGroup,
    onCheckDisable,
    onSearch,
  } = props;

  const [searchKey, setSearchKey] = React.useState('');
  const [lastItems, setLastItems] = React.useState<IItemProps[]>([]);
  const [lastGroups, setLastGroups] = React.useState<IItemProps[]>([]);

  React.useEffect(() => {
    const { lastItems, lastGroups } = getLastInfo(allItems, groups || [], disabledGroup, lastKey, showAddGroup);
    setLastItems(lastItems);
    setLastGroups(lastGroups);
  }, []);

  React.useEffect(() => {
    onSearch && onSearch(searchKey);
  }, [searchKey]);

  const onCheckAll = () => {
    const checkType = getCheckAllType();
    const allItemIds = allItems.map((item) => item.id);
    const lastGroupIds = lastGroups.map((item) => item.id);
    if (checkType !== CheckType.All) {
      onCheckItem(allItemIds);
      lastKey && showAddGroup && !!lastGroupIds.length && onCheckGroup && onCheckGroup(lastGroupIds);
      return;
    }
    const disabled = allItems.filter((item) => item.disabled).map((item) => item.id);
    onCheckItem([...disabled]);
    const disabledGroups = lastGroups.filter((item) => item.disabled).map((item) => item.id);
    onCheckGroup([...disabledGroups]);
  };

  const getCheckAllType = () => {
    const hasLastGroup = showAddGroup && lastKey && !!lastGroups.length;
    const checked = hasLastGroup ? checkedItems.length !== 0 || checkedGroups.length !== 0 : checkedItems.length !== 0;
    const indeterminate = hasLastGroup
      ? allItems.length + lastGroups.length !== checkedItems.length + checkedGroups.length
      : allItems.length !== checkedItems.length;
    if (!checked) {
      return CheckType.None;
    }
    if (checked && indeterminate) {
      return CheckType.Indeterminate;
    }
    return CheckType.All;
  };

  const renderCheckAll = () => {
    const checkType = getCheckAllType();
    return (
      <div className="check-all">
        <Checkbox
          checked={checkType !== CheckType.None}
          indeterminate={checkType === CheckType.Indeterminate}
          text={i18n('checkSelect.selectAll')}
          onChange={onCheckAll}
        />
      </div>
    );
  };

  const getAllItemsAndLast = (): IItemProps[] => {
    return [...lastGroups, ...allItems];
  };

  const getSingleGroup = () => {
    if (!singles || singles.length <= 0) {
      return null;
    }
    return {
      id: 'single-group',
      name: i18n('checkSelect.unclassified'),
      expand: false,
      children: singles,
    };
  };

  const renderLastSelect = () => {
    if (!lastItems.length && !lastGroups.length) {
      return null;
    }

    return (
      <LastGroup
        lastItems={lastItems}
        lastGroups={lastGroups}
        checkedItems={checkedItems}
        checkedGroups={checkedGroups}
        hideGroupCheck={hideGroupCheck}
        maxCount={maxCount}
        onCheckItem={onCheckItem}
        onCheckGroup={onCheckGroup}
        onCheckDisable={onCheckDisable}
      />
    );
  };

  const renderList = () => {
    const singleGroup = getSingleGroup();
    return (
      <div className="check-list-box">
        <ScrollView height={hideGroupCheck ? `calc(100% - 44px)` : 'calc(100% - 74px)'}>
          {lastKey && renderLastSelect()}
          {groups &&
            groups.map((group: IGroupProps) => {
              const { id, children } = group;
              if (children && !children.length) {
                return null;
              }
              return (
                <CheckGroup
                  key={id}
                  data={group}
                  showAddGroup={!!showAddGroup}
                  addGroupTooltip={addGroupTooltip}
                  checkedItems={checkedItems}
                  checkedGroups={checkedGroups}
                  hideGroupCheck={hideGroupCheck}
                  maxCount={maxCount}
                  onCheckItem={onCheckItem}
                  onCheckGroup={onCheckGroup}
                  onCheckDisable={onCheckDisable}
                />
              );
            })}
          {singleGroup && (
            <CheckGroup
              key={singleGroup.id}
              data={singleGroup}
              showAddGroup={!!showAddGroup}
              checkedItems={checkedItems}
              checkedGroups={checkedGroups}
              hideGroupCheck={hideGroupCheck}
              maxCount={maxCount}
              onCheckItem={onCheckItem}
              onCheckGroup={onCheckGroup}
              onCheckDisable={onCheckDisable}
            />
          )}
        </ScrollView>
      </div>
    );
  };

  return (
    <div className={'c-tree-transfer-check-list'}>
      <div className="search-wrapper">
        <SearchInput
          width="100%"
          showClose
          autoFocus={autoFocus}
          value={searchKey}
          placeholder={searchPlaceholder}
          onChange={setSearchKey}
          onStopSearch={() => setSearchKey('')}
        />
      </div>
      {searchKey ? (
        <SearchList
          items={getAllItemsAndLast()}
          checkedItems={checkedItems}
          checkedGroups={checkedGroups}
          searchKey={searchKey}
          enableEmailSearch={enableEmailSearch}
          enableThirdPartyUUIDSearch={enableThirdPartyUUIDSearch}
          hideGroupCheck={hideGroupCheck}
          maxCount={maxCount}
          onCheckItem={onCheckItem}
          onCheckGroup={onCheckGroup}
          onCheckDisable={onCheckDisable}
        />
      ) : (
        <div className="check-container">
          {!hideGroupCheck && renderCheckAll()}
          {renderList()}
        </div>
      )}
    </div>
  );
};

export default CheckList;
