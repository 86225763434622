import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

export interface IInputProps {
  value?: string | number;
  maxLength?: number;
  type?: string;
  autoFocus?: boolean;
  autoSelect?: boolean;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  width?: number | string;
  height?: number | string;
  fullBorder?: boolean;
  canPaste?: boolean;

  onChange?(value: any): void;
  onFocus?(): void;
  onDefaultFocus?(): void;

  onSubmit?(value: any, e?: any): void;

  onInput?: Function;
  onBlur?: React.FocusEventHandler;
  setInputRef?: React.RefObject<HTMLInputElement>;
  onDelaySearch?(value: any): void;
  onInputKeyDown?(e: any): void;
}

interface IInputState {}

class Input extends React.Component<IInputProps, IInputState> {
  static defaultProps: Partial<IInputProps> = {
    value: '',
    maxLength: undefined,
    type: 'text',
    placeholder: '',
    error: '',
    disabled: false,
    autoFocus: false,
    fullBorder: false,
    canPaste: true,
  };

  private isWaitingCompositionEnd: boolean = false;
  inputRef: React.RefObject<HTMLInputElement>;
  private delayTimer: NodeJS.Timeout | null = null;

  constructor(props: IInputProps) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputKeyDown = this.onInputKeyDown.bind(this);
    this.onInput = this.onInput.bind(this);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.inputRef.current) {
        return;
      }
      if (this.props.autoSelect) {
        this.inputRef.current.focus();
        this.inputRef.current.select();
        const value = this.inputRef.current.value;
        const start = value.length;
        this.inputRef.current.selectionStart = 0;
        this.inputRef.current.selectionEnd = start;
      }
    }, 100);
  }

  onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { onChange } = this.props;
    onChange && onChange(e.target.value);
  }

  onInput(e: React.ChangeEvent<HTMLInputElement>) {
    const { onInput } = this.props;
    if (!onInput) {
      return;
    }
    onInput(e.target.value);
  }

  onInputKeyDown(e: React.KeyboardEvent) {
    if (e.key !== 'Escape') {
      e.stopPropagation();
    }
    const value = (e.target as HTMLInputElement).value;
    const { onInputKeyDown, onDelaySearch } = this.props;

    if (onInputKeyDown) {
      onInputKeyDown(e);
    }

    if (onDelaySearch) {
      let i = 0;

      if (this.delayTimer) {
        clearInterval(this.delayTimer);
      }
      this.delayTimer = setInterval(() => {
        i++;
        if (i >= 10) {
          // @ts-ignore
          clearInterval(this.delayTimer);
          onDelaySearch && onDelaySearch(value);
        }
      }, 30);
      return;
    }

    const { onSubmit } = this.props;
    if (!onSubmit) {
      return;
    }
    if (this.isWaitingCompositionEnd) {
      return;
    }
    if (e.key === 'Enter') {
      e.stopPropagation();
      if (!value.trim()) {
        return;
      }

      onSubmit(value, e);
    }
  }

  onFocus = () => {
    if (this.props.autoSelect && this.props.onFocus) {
      this.props.onFocus();
      return;
    }
    this.props.onDefaultFocus && this.props.onDefaultFocus();
  };

  onCompositionStart = () => {
    if (this.isWaitingCompositionEnd) {
      return;
    }
    this.isWaitingCompositionEnd = true;
  };

  onCompositionUpdate = () => {
    if (this.isWaitingCompositionEnd) {
      return;
    }
    this.isWaitingCompositionEnd = true;
  };

  onCompositionEnd = () => {
    this.isWaitingCompositionEnd = false;
  };

  onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const { canPaste } = this.props;
    if (!canPaste) {
      e.preventDefault();
    }
  };

  render() {
    const { value, width, height, placeholder, type, error, disabled, autoFocus, fullBorder, canPaste } = this.props;
    const style: {
      [name: string]: string | number;
    } = {};
    if (width) {
      style.width = width;
    }
    if (height) {
      style.height = height;
    }
    return (
      <div
        className={classnames('dsm-c-input', {
          'dsm-c-input-error': error !== '',
          'dsm-c-input-full-border': fullBorder,
        })}
        style={style}
      >
        <input
          type={type}
          value={value}
          onFocus={this.onFocus}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onChange={this.onInputChange}
          onKeyDown={this.onInputKeyDown}
          onInput={this.onInput}
          disabled={disabled}
          ref={this.props.setInputRef || this.inputRef}
          onBlur={this.props.onBlur}
          maxLength={this.props.maxLength}
          onCompositionStart={this.onCompositionStart}
          onCompositionUpdate={this.onCompositionUpdate}
          onCompositionEnd={this.onCompositionEnd}
          onPaste={this.onPaste}
        />
        <span className="ds-c-input-error-msg" hidden={!error}>
          {error}
        </span>
      </div>
    );
  }
}

export default Input;
