import * as React from 'react';
import classnames from 'classnames';

import { transBlankChart } from '@utils/textUtils';
import { parseColorToString } from '@/utils/graphicsUtils';

import { hasInteraction } from '@helpers/interactionHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { UIContainerComponent, UIComponent } from '@editor/comps';

import { ISize } from '@/fbs/common/models/common';
import { IRange } from '@fbs/rp/models/properties/base';
import { IconValue } from '@/fbs/rp/models/value';
import IImageTextTabsIconStyle from '@/fbs/rp/models/properties/imageTextTabsIconStyle';

import { DEFAULT_INDICATOR_WIDTH } from './config';
import { getListEvents } from '../helps';
import { IListCompositeProps } from '../../types';

import '../general.scss';
import './index.scss';

const ImageTextTabs: React.FC<IListCompositeProps> = (props: IListCompositeProps) => {
  const { comp, itemEvents, isPreview, showInteract } = props;
  const container = comp as UIContainerComponent;
  const {
    size,
    opacity,
    components,
    properties: { separator, separatorRatio, iconStyle },
  } = container;
  const showSeparator = separator ? !separator.disabled : false;

  const parser = StyleHelper.initCSSStyleParser(comp.properties);
  const style = {
    ...size,
    ...parser.getShadowStyle(),
    ...parser.getStrokeStyle(),
    ...parser.getRadiusStyle(size),
    opacity: StyleHelper.getOpacity(opacity),
  };

  // 分割线样式
  const calcSeparatorStyle = () => {
    if (!showSeparator) {
      return undefined;
    }
    return {
      ...StyleHelper.parserSeparator('right', separator),
      width: 0,
      height: `${separatorRatio ? (separatorRatio.value as IRange).value : 100}%`,
    };
  };

  const renderFontIconComp = (iconComp: UIComponent) => {
    const iconInfo = iconStyle as IImageTextTabsIconStyle;
    if (!iconComp) {
      return null;
    }
    const { value, position } = iconComp;
    const icon = value as IconValue;
    const fontSize = iconInfo.iconSize;
    const color = iconComp.properties.icon?.color || '#000000';
    return (
      <div
        className="item-font-icon"
        style={{
          width: fontSize,
          height: fontSize,
          fontSize: fontSize,
          fontFamily: icon.fontName,
          top: position.y,
          lineHeight: `${fontSize}px`,
          color: parseColorToString(color),
        }}
      >
        {String.fromCharCode(icon.iconCode)}
      </div>
    );
  };

  const renderTextComp = (textComp: UIComponent) => {
    const {
      opacity,
      position,
      size,
      properties: { textStyle },
    } = textComp;
    const itemParser = StyleHelper.initCSSStyleParser({ textStyle: textStyle });
    const { style } = itemParser.getTextStyleData(size, textStyle);
    const textStyles = {
      ...style,
      top: position.y,
      opacity: StyleHelper.getOpacity(opacity),
      lineHeight: 'unset',
    };
    return (
      <label className="item-text" style={textStyles}>
        {transBlankChart(textComp?.value as string)}
      </label>
    );
  };

  const renderSeparator = (index: number) => {
    if (showSeparator && index < components.length - 1) {
      return <div className="item-separator" style={calcSeparatorStyle()} />;
    }
    return null;
  };

  const renderRectComp = (rectComp: UIComponent, textSize: ISize) => {
    if (!rectComp) {
      return null;
    }
    const parser = StyleHelper.createCSSStyleParser(rectComp.properties);
    const selectorSize = rectComp.size;
    const selectorStyle = {
      top: rectComp.position.y,
      opacity: StyleHelper.getOpacity(rectComp.opacity),
      ...selectorSize,
      ...parser.getFillStyle(),
      ...parser.getRadiusStyle(selectorSize),
      ...parser.getStrokeStyle(),
    };
    // 标识条依然为默认值，认定标识条宽度未修改，维持标识条宽度等于文本宽度
    if (selectorSize.width === DEFAULT_INDICATOR_WIDTH) {
      selectorStyle.width = textSize.width;
    }
    return <div className="item-selected-indicator" style={selectorStyle} />;
  };

  const events = isPreview ? getListEvents(components, itemEvents) : {};

  return (
    <div className={classnames('lib-comp-image-text-tabs', { preview: isPreview })} style={style}>
      {components.map((comp, i) => {
        const _hasInteraction = hasInteraction(comp);
        const needShowInteraction = !isPreview && _hasInteraction && !!showInteract;
        const item = comp as UIContainerComponent;
        const iconComp = item.getComponentByAlias('fontIcon') || item.components[0];
        const textComp = item.getComponentByAlias('text') || item.components[1];
        const rectComp = item.getComponentByAlias('indicator') || item.components[2];

        const {
          properties: { fill, stroke },
          size,
        } = item;
        const itemParser = StyleHelper.initCSSStyleParser({ fill, stroke });
        const itemStyle = {
          ...size,
          ...itemParser.getFillStyle(),
          opacity: StyleHelper.getOpacity(comp.opacity),
        };

        return (
          <div
            className={classnames('item', {
              'item-interaction-flag': needShowInteraction,
              'item-hot-area': isPreview && _hasInteraction,
              'component-cursor-pointer': isPreview,
            })}
            key={comp.id}
            style={itemStyle}
            {...(events[comp.id] || {})}
          >
            {renderFontIconComp(iconComp)}
            {renderTextComp(textComp)}
            {renderSeparator(i)}
            {renderRectComp(rectComp, textComp.size)}
          </div>
        );
      })}
    </div>
  );
};

export default ImageTextTabs;
