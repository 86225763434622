import { CellTypes, ITableCell, ITableRow, ITableColumn } from '@fbs/rp/models/table';
import { TextAlign, VerticalAlign } from '@fbs/rp/models/properties/text';
import { ICellStyle } from '@fbs/rp/models/table';
import IStroke, { PresetDashModel } from '@fbs/rp/models/properties/stroke';

import { DefaultWhiteFillColor, DefaultTableOuterStrokeColor, DefaultTableInnerStrokeColor } from '../colors';

// 默认单元格高
export const DefaultCellHeight = 30;

// 默认单元格宽
export const DefaultCellWidth = 80;

// 单元格最小高
export const MinCellHeight = 20;

// 单元格最小宽
export const MinCellWidth = 20;

// 单元格最大行数
export const MaxRowsCount = 300;

// 单元格最大列数
export const MaxColumnsCount = 50;

export const DefaultCellPadding = 4;

export const DefalutTableStroke: IStroke = {
  disabled: false,
  thickness: 1,
  color: DefaultTableInnerStrokeColor,
  dashModel: PresetDashModel.solid,
};

export const DefalutTableOuterStroke: IStroke = {
  disabled: false,
  thickness: 1,
  color: DefaultTableOuterStrokeColor,
  dashModel: PresetDashModel.solid,
};

// 默认单元格样式
export const DefalutCellStyle: ICellStyle = {
  textAlign: TextAlign.left,
  verticalAlign: VerticalAlign.middle,
  fill: DefaultWhiteFillColor,
  wrap: true,
  border: {
    top: DefalutTableStroke,
    bottom: DefalutTableStroke,
    left: DefalutTableStroke,
    right: DefalutTableStroke,
  },
};

// 默认行
export const DefalutRow: ITableRow = {
  height: DefaultCellHeight,
  selected: false,
};

// 默认列
export const DefalutColumn: ITableColumn = {
  width: 80,
  type: CellTypes.Text,
  data: { text: '' },
  dataSource: [],
  style: DefalutCellStyle,
  mergeAcross: 0,
  mergeDown: 0,
};

// 默认单元格
export const DefaultCell: ITableCell = {
  type: CellTypes.Text,
  data: { text: '' },
  dataSource: [],
  style: DefalutCellStyle,
  mergeAcross: 0,
  mergeDown: 0,
};
