import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

interface ILabelProps {
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  children: any;
  onClick?: React.MouseEventHandler;
}

const Label: React.FC<ILabelProps> = (props: ILabelProps) => {
  const { style, className, disabled, children } = props;
  return (
    <label style={style} className={classnames('dsm-c-rp-label', className, { disabled })} onClick={props.onClick}>
      {children}
    </label>
  );
};

export default React.memo(Label);
