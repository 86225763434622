import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';
import { CFlowPentagon } from '../constants';

// 五边形
export default {
  ...flowBase,
  name: i18n('resource.flow.pentagon'),
  type: CFlowPentagon,
  thumb: {
    spriteIconClass: SpriteThumb.pentagon.className,
    dragPosition: SpriteThumb.pentagon.position,
  },
  template: getShapeData(
    i18n('resource.flow.pentagon'),
    {
      width: 100,
      height: 100,
    },
    {
      data: [
        {
          point: {
            x: 80,
            y: 100,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 20,
            y: 100,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 38,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 50,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 100,
            y: 38,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
      closed: true,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
