import * as React from 'react';
import { IComponentProps, IListCompositeProps, ICustomCompositeProps, IComponentLib } from '../types';

import CheckBox from './CheckBox';
import Radio from './Radio';
import CheckBoxGroup from './CheckBoxGroup';
import RadioButtonGroup from './RadioGroup';
import Progress from './ProgressBar';
import Score from './Score';
import Switch from './Switch';
import CircleProgress from './CircleProgressBar';
import List from './List';
import Segment from './Segments';
import Tabs from './Tabs';
import BreadCrumbs from './BreadCrumbs';
import Numerice from './Numeric';
import Slider from './Slider';
import SelectBox from './SelectBox';
import QRCodeComd from './QRCodeComp';
import Keyboard from './Keyboard';
import Tree from './Tree';
import Table from './Table';
import MultipleSelect from './MultipleSelect';
import NavigationMenu from './NavigationMenu';
import VerticalMenu from './VerticalMenu';
import HorizontalMenu from './HorizontalMenu';
import ImageTextTabs from './ImageTextTabs';
import CarouselChart from './CarouselChart';
import StickNote from '../comment/StickNote';
import MapChart from '../ChartComponents/MapChart';
import Collapse from './Collapse';

import i18n from '@i18n';

import { VideoConfig } from '../basic/Video';
import { QRCodeConfig } from './QRCodeComp/config';
import { KeyboardConfig } from './Keyboard/config';
import { SliderConfig } from './Slider/config';
import { SelectBoxConfig } from './SelectBox/config';
import { NumericStepConfig } from './Numeric/config';
import { TableConfig } from './Table/config';
import { CheckBoxCfg } from './CheckBox/config';
import { CheckBoxGroupCfg } from './CheckBoxGroup/config';
import { RadioCfg } from './Radio/config';
import { ListCfg } from './List/config';
import { RadioGroupCfg } from './RadioGroup/config';
import { SwitchCfg } from './Switch/config';
import { BreadCrumbsCfg } from './BreadCrumbs/config';
import { SegmentsCfg } from './Segments/config';
import { ProgressBarCfg } from './ProgressBar/config';
import { TabsCfg } from './Tabs/config';
import { CircleProgressBarCfg } from './CircleProgressBar/config';
import { ScoreCfg } from './Score/config';
import { HotAreaCfg } from './HotArea/config';
import { TreeConfig } from './Tree/config';
import { DatePickerConfig } from './DatePicker/config';
import DatePicker from './DatePicker';
import TimePicker from './TimerPicker';
import { TimePickerConfig } from './TimerPicker/config';
import NormalChart from '../ChartComponents/SeriesChartOne';
import PieChart from '../ChartComponents/SeriesChartTwo';
import { AudioConfig } from '../basic/Audio';
import { MultipleSelectConfig } from './MultipleSelect/config';
import { NavigationMenuConfig } from './NavigationMenu/config';
import { VerticalMenuConfig } from './VerticalMenu/config';
import { HorizontalMenuConfig } from './HorizontalMenu/config';
import { ImageTextTabsCfg } from './ImageTextTabs/config';
import { CarouselChartConfig } from './CarouselChart/config';
import { CollapseCfg } from './Collapse/config';
import {
  CImageTextTabs,
  CQRCode,
  CKeyboard,
  CTable,
  CTree,
  CDatePicker,
  CTimePicker,
  CBarChart,
  CPieChart,
  CDoughnutChart,
  CLineChart,
  CBarChartHorizontal,
  CAreaChart,
  CRadarChart,
  CScatterChart,
  CMultipleSelect,
  CNavigationMenu,
  CVerticalMenu,
  CHorizontalMenu,
  CCarouselChart,
  CCombinationChart,
  CStickNote,
  CGaugeChart,
  CMapChart,
  CCollapse,
  CVerticalTabs,
} from '../constants';
import { PropertyStructureAlign } from '../enum';
import GaugeChart from '../ChartComponents/GaugeChart';
import Button from './Button';
import { VerticalTabsCfg } from './VerticalTabs/config';
import VerticalTabs from './VerticalTabs';

type ICompoundComponentClass =
  | React.ComponentClass<IComponentProps>
  | React.ComponentClass<ICustomCompositeProps>
  | React.ComponentClass<IListCompositeProps>
  | React.FunctionComponent<IComponentProps>
  | React.FunctionComponent<ICustomCompositeProps>
  | React.FunctionComponent<IListCompositeProps>;

export const CompoundComponentClass: { [libID: string]: ICompoundComponentClass } = {
  breadCrumbs: BreadCrumbs,
  button: Button,
  checkbox: CheckBox,
  'checkbox-group': CheckBoxGroup,
  circleProgressBar: CircleProgress,
  list: List,
  numericStep: Numerice,
  progressBar: Progress,
  radio: Radio,
  'radio-button-group': RadioButtonGroup,
  score: Score,
  'segments-control': Segment,
  select: SelectBox,
  slider: Slider,
  switch: Switch,
  selectTab: Tabs,
  [CImageTextTabs]: ImageTextTabs,
  [CVerticalTabs]: VerticalTabs,
  [CQRCode]: QRCodeComd,
  [CKeyboard]: Keyboard,
  [CTable]: Table,
  [CTree]: Tree,
  [CDatePicker]: DatePicker,
  [CTimePicker]: TimePicker,
  [CBarChart]: NormalChart,
  [CPieChart]: PieChart,
  [CDoughnutChart]: PieChart,
  [CLineChart]: NormalChart,
  [CBarChartHorizontal]: NormalChart,
  [CAreaChart]: NormalChart,
  [CRadarChart]: NormalChart,
  [CScatterChart]: NormalChart,
  [CCombinationChart]: NormalChart,
  [CGaugeChart]: GaugeChart,
  [CMultipleSelect]: MultipleSelect,
  [CNavigationMenu]: NavigationMenu,
  [CVerticalMenu]: VerticalMenu,
  [CHorizontalMenu]: HorizontalMenu,
  [CCarouselChart]: CarouselChart,
  [CStickNote]: StickNote,
  [CMapChart]: MapChart,
  [CCollapse]: Collapse,
};

const CommonComponentLib: IComponentLib = {
  id: 'common',
  name: i18n('resource.components.common'),
  components: [
    CheckBoxCfg,
    CheckBoxGroupCfg,
    SelectBoxConfig,
    MultipleSelectConfig,
    RadioCfg,
    RadioGroupCfg,
    ListCfg,
    SwitchCfg,
    SegmentsCfg,
    TabsCfg,
    ImageTextTabsCfg,
    VerticalTabsCfg,
    NumericStepConfig,
    ProgressBarCfg,
    SliderConfig,
    BreadCrumbsCfg,
    CircleProgressBarCfg,
    ScoreCfg,
    VideoConfig,
    AudioConfig,
    QRCodeConfig,
    KeyboardConfig,
    HotAreaCfg,
    TableConfig,
    TreeConfig,
    NavigationMenuConfig,
    VerticalMenuConfig,
    HorizontalMenuConfig,
    DatePickerConfig,
    TimePickerConfig,
    CarouselChartConfig,
    CollapseCfg,
  ],
};

export const compoundStructureList = [
  { name: i18n('property.component.structureAlignLeft'), value: PropertyStructureAlign.TextLeft },
  { name: i18n('property.component.structureAlignRight'), value: PropertyStructureAlign.TextRight },
];

export default CommonComponentLib;
