const PC_HOME = '/home';
const PC_LOGIN = '/login';
const EXAMPLE = '/example';
const PC_SIGNIN = '/signin';

const prefix = window.apis?.rpDomain ?? '';

function getNoPermissionPageUrl(teamID: string = '', errorCode: string | number) {
  return `${prefix}/permission/team/${teamID}?ps=${errorCode}`;
}

function getRouterURL() {
  return {
    routePrefix: prefix,
    testHome: `${prefix}/team/:teamID`,
    editor: `${prefix}/editor/:id/:pageID?`,
    localPreview: `${prefix}/run/:id/:pageID?`,
    sharePreview: `${prefix}/run/:id/:linkID/:pageID?`,

    standalonePreview: `${prefix}/standalone/rp/:id/:pageID?`,
    example: `${prefix}/example/:id/:pageID?`,

    deprecatedTestHome: `${prefix}/rp/team/:teamID`,
    deprecatedEditor: `${prefix}/rp/editor/:id/:pageID?`,
    deprecatedLocalPreview: `${prefix}/run/rp/:id/:pageID?`,
    deprecatedSharePreview: `${prefix}/run/rp/:id/:linkID/:pageID?`,
  };
}

function getCCPrototypeURL(appID: string, prototypeID: string) {
  return `${window.apis.iDocDomain}/app/${appID}/prototype/${prototypeID}`;
}

export const getHomeTeam = (teamID: string) => {
  return `${prefix}/team/${teamID}`;
};

export default {
  PC_HOME,
  PC_LOGIN,
  EXAMPLE,
  PC_SIGNIN,
  getNoPermissionPageUrl,
  getCCPrototypeURL,
  ...getRouterURL(),
};
