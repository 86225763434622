import { ExampleAppState, IExampleApp, IExampleInfo } from '@fbs/rp/models/example';

import { get, post } from './helper';

/**
 * 获取全部例子项目
 */
export function getAllExampleApp(pageIndex: number, pageSize: number): Promise<IExampleInfo> {
  return get(`/example/all`, {
    params: {
      pageIndex,
      pageSize,
    },
  });
}

/**
 * 获取偏好的例子项目列表
 * @param pageIndex
 * @param pageSize
 */
export function getLikeExampleApp(pageIndex: number, pageSize: number): Promise<IExampleInfo> {
  return get(`/example/like`, {
    params: {
      pageIndex,
      pageSize,
    },
  });
}

/**
 * 检索例子项目
 * @param pageIndex
 * @param pageSize
 * @param search
 */
export function getSearchExampleApp(pageIndex: number, pageSize: number, search: string): Promise<IExampleInfo> {
  return get(`/example/search`, {
    params: {
      pageIndex,
      pageSize,
      queryString: search,
    },
  });
}

/**
 * 获取某一种类的例子项目列表
 * @param category
 * @param pageIndex
 * @param pageSize
 */
export function getCategoryExampleApp(pageIndex: number, pageSize: number, category: string): Promise<IExampleInfo> {
  return get(`/example/category/${category}`, {
    params: {
      pageIndex,
      pageSize,
    },
  });
}

/**
 * 获取某个例子项目详情
 * @param exampleID
 */
export function getExampleAppDetail(exampleID: number) {
  return get<{
    example: IExampleApp;
    isAdmin: number;
    tops: IExampleApp[];
  }>(`/example/${exampleID}/detail`);
}

/**
 * 修改对某个例子项目的喜好
 * @param exampleID
 * @param state
 */
export function patchLikeExampleApp(exampleID: string, state: ExampleAppState) {
  return post(`/example/patchLike`, {
    exampleID,
    state,
  });
}
