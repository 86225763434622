export default {
  TagButtonGroup: {
    all: '全部',
    settings: '设置',
  },
  Dialog: {
    ok: '确定',
    cancel: '取消',
  },
  RadioButton: {
    text: '单选按钮',
  },
  Select: {
    placeholder: '请选择或输入',
  },
  TagsManager: {
    maxLength: '20',
    error: '分组名称长度限制20个字符。',
    dialog: '删除项目分组后不可恢复，确定仍要删除吗？',
    placeholder: '请输入{0}名称',
    title: '分组管理',
    actionName: '新建分组',
    rename: '重命名',
    delete: '删除',
  },
  tree: {
    emptySearch: '未搜索到结果',
  },
  colorPanel: {
    recentUse: '最近使用',
    customizeColor: '自定义颜色',
  },
  shortCutKey: {
    shortCutKey: '快捷键',
    projectTree: '项目树',
    canvas: '画布',
    storyboard: '画板',
    page: '页面',
    prototype: '交互',
    design: '设计',
    drag: '拖动',
    leftMouseButton: '左键 + 空格',
    zoom: '缩放',
    mouseWheel: '滚轮',
    zoomOut: '放大工作区',
    zoomIn: '缩小工作区',
    zoomToOne: '100%显示',
    zoomToTwo: '200%显示',
    zoomToThree: '300%显示',
    zoomToFour: '50%显示',
    zoomToArtboard: '适应画板',
    zoomToCanvas: '适应画布',
    zoomToSelection: '适应选区',
    delete: '删除',
    leftKey: '左键',
    overview: '全览',
    map: '导航',
    preview: '全屏模式',
    lineDescription: '逻辑线描述',
    multiSelect: '多选',
    rename: '重命名',
    copy: '复制',
    paste: '粘贴',
    cut: '剪切',
    magnifier: '放大镜',
    doubleClick: '双击',
    percentageSpecs: '百分比标注',
    copyLink: '复制链接',
    select: '选择',
    pin: '图钉',
    rectangle: '矩形',
    circle: '圆形',
    straightLine: '直线',
    arrow: '箭头',
    text: '文字标注',
    imgLayers: '图层列表',
    createrHotArea: '快速创建热区',
    undoBtn: '撤销',
    redoBtn: '恢复',
    prePage: '上一页',
    nextPage: '下一页',
    lastOpenPage: '最近打开的页面',
    savePageVersion: '保存页面版本',
    left: '左',
    right: '右',
    selectAll: '全选',
    commentMode: '评论',
    reviewMode: '定稿',
    developMode: '开发',
    prdDocTree: '文档树',
    focus: '聚焦',
    generateOperation: '通用操作',
    dragWorkspace: '拖动工作区',
    mouseWheelUp: '滚轮向上',
    mouseWheelDown: '滚轮向下',
    basicOperation: '基础',
    lock: '锁定',
    unlock: '解除锁定',
    demonstrate: '演示',
    flowChart: '进入/退出流程图模式',
    componentClone: '组件克隆',
    dragComponent: '拖动组件',
    verticalClone: '水平或垂直克隆组件',
    mergeOrdissolve: '编组/取消编组', // 待删除
    hidden: '显示/隐藏',
    containSelect: '包含框选',
    mouseRegion: '鼠标框选',
    selectDown: '选中下层',
    unSelect: '取消选择',
    addElement: '元素',
    texts: '文本',
    button: '按钮',
    image: '图片',
    pen: '钢笔',
    pencil: '铅笔',
    stickyNote: '便签条',
    circleMarker: '编号',
    dropMarker: '水滴',
    attributeSetting: '属性',
    componentHeight: '调整组件高度',
    componentWidth: '调整组件宽度',
    componentTransparent: '调整组件透明度',
    numberKey: '0~9',
    addFontsize: '字号增大',
    decreaseFontsize: '字号减小',
    bold: '粗体',
    italic: '斜体',
    line: '直线',
    underline: '下划线',
    oval: '椭圆',
    layoutSetting: '布局',
    componentMove: '组件移动',
    componentMoveFast: '组件移动10px',
    verticalMove: '水平/垂直移动',
    click: '单击',
    addRegion: '追加框选',
    selectRegion: '框选',
    forwardTier: '组件层级上移',
    abackTier: '组件层级下移',
    toTop: '组件置顶',
    toBottom: '组件置底',
    boardInterval: '查看间距',
    MouseOverComponent: '悬停',
    selectComponent: '选中组件',
    seeMouseOver: '悬停要查看的组件',
    justifyLeft: '左对齐',
    justifyRight: '右对齐',
    justifyTop: '顶对齐',
    justifyBottom: '底对齐',
    justifyCenter: '水平居中对齐',
    justifyVertical: '垂直居中对齐',
    levelIsometry: '水平等距',
    verticalIsometry: '垂直等距',
    projectSetting: '项目设置',
    newPage: '新建页面',
    actionName: '新建分组',
    pageUp: '页面上移',
    pageDown: '页面下移',
    recentPage: '切换到最近打开页面',
    InterfaceView: '其它',
    interfaceSetting: '项目',
    openTree: '打开项目',
    openComponent: '打开组件',
    openIcons: '打开图标',
    openResource: '打开资源',
    openLayer: '打开图层',
    unfoldLeftBoard: '收起/展开左侧面板',
    unfoldRightBoard: '收起/展开右侧面板',
    levelRoll: '水平滚动工作区',
    locateMainBoard: '定位到主画板',
    searchFast: '快速搜索',
    findReplaceText: '查找与替换文字',
    switchTabs: '切换分类',
    showHideGrid: '显示/隐藏网格',
    showHideLayout: '显示/隐藏布局',
    artboard: '画板',
    notes: '备注',
    autoSize: '自动大小',
    showRuler: '显示标尺',
    showGuides: '显示参考线',
    save: '保存',
    copyProperties: '复制样式',
    pasteProperties: '粘贴样式',
    showHideLeftPanel: '显示/隐藏左侧面板',
    showHideRightPanel: '显示/隐藏右侧面板',
    fullScreen: '全屏',
  },
  mobileDialog: {
    title: '请在电脑上体验摹客完整功能',
    text: '如需进行移动端项目演示，请使用摹客App',
    ok: '好的',
    openApp: '打开App',
    unInstall: '尚未安装？',
    clickDownload: '点击下载',
    openAppFail: '抱歉，当前无法自动打开App',
    downloadApp: '点击下载最新版摹客App',
    openManually: '如已安装，请手动打开',
    cancel: '取消',
    download: '下载',
    appUrl: 'https://app.mockplus.cn',
  },
  checkSelect: {
    hasSelected: '已选 {0} 人',
    hasSelectedGroup: '，{0} 个部门',
    clearAll: '清空',
    unclassified: '未分组',
    selectAll: '全选',
    hasNotFound: '没有找到结果',
    maximumOfPeople: '已达人数上限。',
    last: '上次的选择',
  },
  searchInput: {
    searchHistory: '搜索历史',
    clearHistory: '清除',
  },

  rpActivity: {
    title: '摹客RP免费计划',
    inviteToGetFree: '邀请{0}名成员注册并加入团队一起工作，永久获得摹客RP创业版',
    inviteTimeStr: '活动时间：2023年1月10日前',
    deadline: '活动截止：{0}',
    whatUnlimitedDesign: '什么是RP创业版？',
    definitionOfUnlimitedDesign:
      'RP创业版中包含摹客RP所有功能，页面数量无上限，设计功能无限制！获取后，团队所有成员均可享受。',
    invitationProgress: '邀请进度',
    invitationStatusExpired: 'RP创业版已于{0}到期，再邀请{1}人即可永久获得，点击查看邀请记录。',
    invitationStatusWillExpire: '已获得{0}个月RP创业版，再邀请{1}人即可永久获得，点击查看邀请记录。',
    invitationStatusHasGot: '已获得永久RP创业版，点击查看邀请记录。',
    viewInvitationHistory: '查看邀请记录',
    invitationExplainTitle: '邀请说明',
    invitationExplainContent: '请通过下方“立即邀请”中的链接，邀请未注册的新用户，否则不会计入邀请进度。',
    oneYear: '1年',
    oneMonth: '1个月',
    addThreeMonth: '+3个月',
    firstPrice: '价值166元',
    secondPrice: '价值498元',
    thirdPrice: '价值19900元',
    forever: '永久',
    inviteSeveral: '邀请第{0}名成员',
    hasGotIt: '已获得',
    inviteNow: '立即邀请',
    getForeverPackage: '领取永久奖励',
    hasGotPackage: '团队已领取',
    goBack: '返回',
    invitation: {
      linkInvitation: '链接邀请',
      copyLink: '复制链接',
      inviteLinkDesc: '将链接发送给同事，邀请对方加入“{0}”',
      emailInvitation: '邮箱邀请',
      emailPlaceholder: '请输入企业邮箱，多个邮箱使用分号隔开',
      copySuccess: '复制成功',
      sendInvitation: '发送邀请',
      sendInvitationSuccess: '已成功发送',
      emailFormatError: '存在错误的邮箱地址，请更正后再试',
      copyText:
        'Hi~这款永久免费的原型设计工具，我必须要分享给你！功能强大，上手简单，不限页面，不限功能。马上注册，开始使用吧：{0}',
      foreverRPFree: '永久RP创业版',
      teamGotForeverFree: ' 的团队获得了永久RP创业版',
    },
    guestLimitTip: '你在“{0}”中的身份是{1}，无法参与此活动。',
    guestSwitchTeam: '请切换团队或联系管理员修改身份后再试。',
    record: {
      num: '序号',
      time: '时间',
      sender: '邀请人',
      receiver: '受邀人',
      reward: '奖励',
      threeMonthReward: '3个月RP创业版',
      unlimitedPackage: '永久RP创业版',
      emptyTip: '还没有被邀请的成员呢，赶紧去邀请吧~',
    },
    receiveForever: {
      scanQR: '扫描活动客服二维码',
      get: '领取',
      unlimitedPackage: '永久RP创业版',
      rewardWillSoonArrived: '永久RP创业版预计1个工作日内发放至团队',
    },
  },

  pager: {
    total: '共 {0} 个',
    checkAll: '全选',
    selected: '，已选{0}',
    batchOperate: '批量操作',
    sizeOfPerPage: '{0} 条/页',
    jumpTo: '跳至',
    page: '页',
  },
  emptyTip: {
    emptyTip: '暂无内容',
    searchEmptyTip: '未搜索到相关内容',
  },
  role: {
    superAdministrator: '超级管理员',
    administrator: '管理员',
    member: '成员',
    guest: '协同人',
    observer: '访客',
  },
};
