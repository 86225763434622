import * as React from 'react';

import * as _ from 'lodash';
import classnames from 'classnames';

import { isSafari } from '@dsm2/components/utils';
import { parseColorToString } from '@utils/graphicsUtils';

import { StyleHelper } from '@helpers/styleHelper';
import * as SystemsColor from '@consts/colors';
import { getImageColorFilter } from '@helpers/propertiesHelper';
import { UIComponent } from '@/editor/comps';
import { ArtboardPatches, Ops } from '@/fbs/rp/utils/patch';
import { round } from '@utils/globalUtils';
import { IComponentValue } from '@fbs/rp/models/value';
import { ISize } from '@/fbs/common/models/common';
import { IProperties } from '@/fbs/rp/models/property';

import { getVPCPrivateURL } from '../../helper';
import { IComponentProps } from '../../types';

import './index.scss';

// 是否是在 RP 截图模式下的渲染，在这个模式下面，要将图片转为内网地址，加快访问速度
const isStandalonePreviewMode =
  window.location.href.indexOf('localhost') !== -1 && window.location.href.indexOf('/standalone-preview') !== -1;

export function updateImageValue(comp: UIComponent, value: any): ArtboardPatches | null {
  const { value: url, id, properties } = comp;
  if (value && url !== value && properties.image) {
    const newImg = { ...properties.image, clipBounds: undefined };
    const path = comp.getCurrentPath('properties/image');
    return {
      do: {
        [id]: [Ops.replace(path, newImg)],
      },
      undo: {
        [id]: [Ops.replace(path, properties.image)],
      },
    };
  }
  return null;
}

interface IImageState {
  stroke: StyleHelper.ISVGStroke;
  strokePathData: string;
  style: React.CSSProperties;
  cropDisplayWidth: number;
  cropDisplayHeight: number;
  cropDisplayOffsetX: number;
  cropDisplayOffsetY: number;
}

// Pure
export default class ImageComponent extends React.Component<IComponentProps, IImageState> {
  private url?: string;
  private imgRealWidth: number = 0;
  private imgRealHeight: number = 0;
  private needMeasureImageSize: boolean = false;

  private _disposed = false;

  constructor(props: IComponentProps) {
    super(props);

    this.state = {
      style: this.doParsePropertiesToStyle(this.props),
      ...this.doParserBorder(this.props),
      cropDisplayWidth: 0,
      cropDisplayHeight: 0,
      cropDisplayOffsetX: 0,
      cropDisplayOffsetY: 0,
    };

    const {
      comp: { size, properties, value, opacity, version },
      isPreview,
      globalScale,
    } = props;
    const transition = props.comp.getTransition();

    this.param = {
      size: _.cloneDeep(size),
      properties: _.cloneDeep(properties),
      value: _.cloneDeep(value),
      opacity,
      isPreview,
      globalScale,
      transition,
      version,
    };
  }

  /**
   * 组件是否刷新的关键
   */
  private param: {
    size: ISize;
    properties: IProperties;
    value?: IComponentValue;
    opacity: number;
    isPreview?: boolean;
    globalScale: number;
    transition: string;
    version: string;
  } & { [key: string]: any };

  shouldComponentUpdate(nextProps: IComponentProps) {
    const {
      comp: { size, properties, value, opacity, version },
      isPreview,
      globalScale,
    } = nextProps;
    const transition = nextProps.comp.getTransition();

    const newParam: { [key: string]: any } = {
      size,
      properties,
      value,
      opacity,
      isPreview,
      globalScale,
      transition,
      version,
    };

    let flag = false;
    Object.keys(this.param).forEach((key) => {
      if (!_.isEqual(newParam[key], this.param[key])) {
        flag = true;
        this.param[key] = _.cloneDeep(newParam[key]);
      }
    });

    return flag;
  }

  componentDidMount() {
    this.doRefresh(this.props);
  }

  componentWillUnmount() {
    this._disposed = true;
  }

  doRefresh(props: IComponentProps) {
    const { comp } = props;
    const { value } = comp;
    if (value) {
      if (this.url !== value) {
        this.url = value as string;
        this.needMeasureImageSize = true;
      }
      if (this.needMeasureImageSize) {
        this.getImgDimensions(value as string, ({ width, height }: { width: number; height: number }) => {
          if (this._disposed) {
            return;
          }
          this.imgRealWidth = width;
          this.imgRealHeight = height;
          this.needMeasureImageSize = false;
          this.doParserClipBounds(props);
        });
      } else {
        this.doParserClipBounds(props);
      }
    } else {
      this.doParserClipBounds(props);
    }
  }

  private doParserClipBounds = (props: IComponentProps) => {
    const { comp } = props;
    const { size, properties } = comp;
    const { image } = properties;
    const { clipBounds } = image || {};

    if (clipBounds) {
      const cropDisplayWidth = (size.width * this.imgRealWidth) / clipBounds.width;
      const cropDisplayHeight = (size.height * this.imgRealHeight) / clipBounds.height;
      const cropDisplayOffsetX = (size.width * clipBounds.left) / clipBounds.width;
      const cropDisplayOffsetY = (size.height * clipBounds.top) / clipBounds.height;
      this.setState({
        cropDisplayWidth: round(cropDisplayWidth),
        cropDisplayHeight: round(cropDisplayHeight),
        cropDisplayOffsetX: round(cropDisplayOffsetX),
        cropDisplayOffsetY: round(cropDisplayOffsetY),
      });
    }
    this.setState({
      style: this.doParsePropertiesToStyle(props),
      ...this.doParserBorder(props),
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps: IComponentProps) {
    this.doRefresh(nextProps);
  }

  doParsePropertiesToStyle = (props: IComponentProps): React.CSSProperties => {
    const {
      size: { width, height },
      properties,
      opacity,
    } = props.comp;
    const parser = StyleHelper.initCSSStyleParser(properties);

    const style = {
      ...parser.getRadiusStyle({ width, height }),
      overflow: 'hidden',
      opacity: _.isUndefined(opacity) ? 1 : opacity / 100,
      ...parser.getStrokeStyle(),
    } as React.CSSProperties;
    // if (props.isPreview) {
    //   style.width = width;
    //   style.height = height;
    // }
    if (isSafari()) {
      style.boxShadow = parser.getShadowStyle(true).boxShadow;
    } else {
      style.filter = StyleHelper.parserDropShadow(properties.shadow);
    }
    return style;
  };

  doParserBorder = (
    props: IComponentProps,
  ): {
    stroke: StyleHelper.ISVGStroke;
    strokePathData: string;
  } => {
    const { properties, size } = props.comp;
    const prop = { ...properties };
    if (!props.comp.value) {
      if (prop.stroke) {
        prop.stroke = { ...prop.stroke, thickness: 1, disabled: false };
        // 阻止border属性影响默认边框
        delete prop.border;
      }
    }
    const thickness = prop.stroke?.thickness || 1;
    const svgParser = StyleHelper.initSVGStyleParser(prop);
    const {
      stroke,
      strokeDasharray,
      strokePathData,
      strokeWidth,
      strokeLinejoin,
      strokeLinecap,
      position,
    } = svgParser.getStrokeEx({ width: size.width - thickness, height: size.height - thickness });
    return {
      stroke: { stroke, strokeDasharray, strokeLinecap, strokeLinejoin, strokeWidth, position },
      strokePathData,
    };
  };

  renderPlaceHolder() {
    const { strokePathData } = this.state;
    const { properties, size } = this.props.comp;
    const { topLeft, topRight, bottomLeft, bottomRight } = StyleHelper.calculateRadius(size, properties.radius);
    const { width, height } = size;
    const { stroke } = properties;
    const thickness = stroke?.thickness || 1;
    const needBorder = !!stroke?.disabled;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width={size.width}
        height={size.height}
        style={{ position: 'absolute', left: 0, top: 0 }}
        className="empty-image"
      >
        {needBorder && (
          <path
            height="100%"
            width="100%"
            d={strokePathData}
            fill="white"
            stroke={parseColorToString(SystemsColor.DefaultStrokeColor)}
            strokeWidth={1}
            transform="translate(0.5 0.5)"
          />
        )}
        <line
          x1={topLeft / 2}
          y1={topLeft / 2}
          x2={width - (needBorder ? 0 : thickness * 2) - topRight / 2}
          y2={height - (needBorder ? 0 : thickness * 2) - bottomRight / 2}
          stroke={parseColorToString(SystemsColor.DefaultStrokeColor)}
          strokeWidth={1}
        />
        <line
          x1={width - (needBorder ? 0 : thickness * 2) - topRight / 2}
          y1={topRight / 2}
          x2={bottomLeft / 2}
          y2={height - (needBorder ? 0 : thickness * 2) - bottomLeft / 2}
          stroke={parseColorToString(SystemsColor.DefaultStrokeColor)}
          strokeWidth={1}
        />
      </svg>
    );
  }

  renderEmptyImage() {
    const {
      size: { width, height },
    } = this.props.comp;
    const r = Math.max(1, Math.round(Math.min(width, height) * 0.08));
    const thickness = Math.min(20, r);

    const offset = Math.round(thickness / 2);
    const w = width - thickness * 2;
    const h = height - thickness * 2;
    const left = thickness;
    const top = thickness;
    const right = width - thickness;
    const bottom = height - thickness;
    const pt1 = { x: left + offset, y: bottom - offset };
    const pt2 = { x: left + w * 0.4, y: top + h * 0.45 };
    const pt3 = { x: left + w * 0.65, y: top + h * 0.7 };
    const pt4 = { x: left + w * 0.75, y: top + h * 0.55 };
    const pt5 = { x: right - offset, y: bottom - offset };

    const d = `M${pt1.x} ${pt1.y} 
               L${pt2.x} ${pt2.y} 
               L${pt3.x} ${pt3.y}
               L${pt4.x} ${pt4.y} 
               L${pt5.x} ${pt5.y}
               Z`;
    return (
      <svg width="100%" height="100%" viewBox={`0 0 ${width} ${height}`} className="image-place-holder">
        <rect width={width} height={height} x={0} y={0} fill="#cbcbcc" />
        <rect
          width={width - Math.max(thickness, 1) * 2}
          height={height - Math.max(thickness, 1) * 2}
          x={Math.max(thickness, 1)}
          y={Math.max(thickness, 1)}
          strokeWidth={2}
          stroke="none"
          fill="#fff"
        />
        <path d={d} stroke="none" />
        <circle cx={left + w * 0.8} cy={top + h * 0.2} r={r} />
      </svg>
    );
  }

  renderNoneImage() {
    const {
      comp: {
        properties: { image },
      },
    } = this.props;
    if (image?.showModel === 'empty') {
      return this.renderEmptyImage();
    }
    return this.renderPlaceHolder();
  }

  getImgDimensions = (imgUrl: string, callback: Function) => {
    const img = new Image();
    img.src = imgUrl;
    // 判断是否有缓存
    if (img.complete) {
      callback({ width: img.width, height: img.height });
    } else {
      img.onload = () => {
        callback({ width: img.width, height: img.height });
      };
    }
  };

  renderImg() {
    const { comp } = this.props;
    const { value, properties, flip, size } = comp;
    const { image, blur, colorFilter, stroke } = properties;
    const { fitMode, clipBounds } = image || {};
    const classObj = classnames('img-box', { [`fit-mode-${fitMode}`]: !!fitMode });
    const filterStr: string = getImageColorFilter(colorFilter, blur);
    let imgStyles: React.CSSProperties = {};
    if (clipBounds && clipBounds.width > 0 && clipBounds.height > 0) {
      imgStyles = {
        background: `url(${value as string}) no-repeat ${-this.state.cropDisplayOffsetX}px ${-this.state
          .cropDisplayOffsetY}px / ${this.state.cropDisplayWidth}px ${this.state.cropDisplayHeight}px`,
      };
    }
    const imgUrl = isStandalonePreviewMode ? getVPCPrivateURL(value as string) : (value as string);
    const thickness = stroke && !stroke.disabled ? stroke.thickness || 1 : 0;
    const horizontalFlip = flip?.horizontal;
    const verticalFlip = flip?.vertical;
    let containerStyle: React.CSSProperties = {
      transition: comp.getTransition(),
      filter: filterStr,
      left: -thickness,
      top: -thickness,
      right: -thickness,
      bottom: -thickness,
    };
    if (horizontalFlip || verticalFlip) {
      containerStyle = Object.assign({}, containerStyle, {
        transformOrigin: 'center center',
        transform: `scale(${horizontalFlip ? -1 : 1},${verticalFlip ? -1 : 1})`,
      });
    }
    return (
      <div className={classObj} style={containerStyle}>
        <img
          width={size.width}
          height={size.height}
          src={imgUrl}
          style={imgStyles}
          className={classnames({ [`img-mode-${fitMode}`]: !!fitMode })}
        />
      </div>
    );
  }

  render() {
    const { comp, isPreview } = this.props;
    return (
      <div
        style={{ ...this.state.style, transition: comp.getTransition(), ...(isPreview ? comp.size : {}) }}
        className="lib-comp-image"
      >
        {comp.value && this.renderImg()}
        {!comp.value && this.renderNoneImage()}
      </div>
    );
  }
}
