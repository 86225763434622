import * as React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';

import { parseColorToString } from '@utils/graphicsUtils';
import { transBlankChart } from '@utils/textUtils';

import { IconValue } from '@fbs/rp/models/value';

import { UIContainerComponent } from '@editor/comps';
import { StyleHelper } from '@helpers/styleHelper';
import { hasInteraction } from '@helpers/interactionHelper';
import { BlackColor } from '@consts/colors';

import { IListCompositeProps } from '../../types';
import { getListEvents } from '../helps';

import './index.scss';
import '../general.scss';

const BreadCrumbs: React.FC<IListCompositeProps> = (props: IListCompositeProps) => {
  const { comp, itemEvents, isPreview, showInteract } = props;
  const group = comp as UIContainerComponent;
  const { size, opacity, properties, components } = group;
  const events = isPreview ? _.memoize(getListEvents)(components, itemEvents) : {};
  const textComps = components.map((comp) => {
    const itemGroup = comp as UIContainerComponent;
    return itemGroup.getComponentByAlias('text') || itemGroup.components[0];
  });
  const textItemEvents = { onItemLeave: itemEvents?.onItemLeave, onItemEnter: itemEvents?.onItemEnter };
  const textEvents = isPreview ? getListEvents(textComps, textItemEvents) : {};

  const style = _.memoize(
    () => ({
      ...size,
      opacity: StyleHelper.getOpacity(opacity),
      filter: StyleHelper.parserDropShadow(
        properties.shadow
          ? {
              ...properties.shadow,
              blur: (properties.shadow.blur || 0) / 2,
            }
          : undefined,
      ),
    }),
    () => `${comp.id}`,
  )();

  return (
    <div className={classnames('lib-comp-breadcrumbs', { preview: isPreview })} style={style}>
      {components.map((comp, cIndex, cData) => {
        const isLast = cIndex >= cData.length - 1; // 判断是否最后一个
        const item = comp as UIContainerComponent;
        const {
          components: child,
          id,
          size: { width: itemWidth },
        } = item;
        const textComp = item.getComponentByAlias('text') || child[0];
        const iconComp = item.getComponentByAlias('icon') || child[1];
        const {
          properties: textProps,
          size: { width },
        } = textComp;
        const {
          properties: { icon },
          size: iconSize,
        } = iconComp;
        const { fontName, iconCode } = iconComp.value as IconValue;
        const parser = StyleHelper.createCSSStyleParser(textProps);
        const { style } = parser.getTextStyleData(textComp.size, textProps.textStyle);
        const textStyle = _.memoize(
          () => ({
            width,
            ...style,
            opacity: StyleHelper.getOpacity(textComp.opacity),
          }),
          () => `${textComp.id}`,
        )();
        const iconStyle = _.memoize(
          () => ({
            color: parseColorToString(icon?.color || BlackColor),
            ...iconSize,
            fontFamily: fontName,
            opacity: StyleHelper.getOpacity(iconComp.opacity),
          }),
          () => `${iconComp.id}`,
        )();
        const _hasInteraction = hasInteraction(comp);
        const needShowInteraction = !isPreview && _hasInteraction && false !== showInteract;
        return (
          <div
            key={id}
            className={classnames('lib-comp-breadcrumbs-item', {
              'item-interaction-flag': needShowInteraction,
              'item-hot-area': isPreview && _hasInteraction,
              'component-cursor-pointer': isPreview,
            })}
            style={{
              width: itemWidth,
              opacity: StyleHelper.getOpacity(comp.opacity),
            }}
            {...(events[id] || {})}
          >
            <label className={classnames('lib-comp-breadcrumbs-value')} style={textStyle} {...textEvents[textComp.id]}>
              {transBlankChart(textComp.value as string)}
            </label>
            {!isLast && (
              <label className="lib-comp-breadcrumbs-icon" style={iconStyle}>
                {String.fromCharCode(iconCode)}
              </label>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default BreadCrumbs;
