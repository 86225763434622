import * as React from 'react';
import classnames from 'classnames';

import { Button, Dialog } from '@dsm';

import './index.scss';

interface IProps {
  title?: string;
  submitText?: string;
  submitWidth?: number | string;
  closeText?: string;
  className?: string;
  onClose: () => void;
  onSubmit?: () => void;
  titleRender?: (title?: string) => React.ReactElement;
}

interface IState {}

class ConfirmDialog extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { className, title, submitText, closeText, submitWidth, onClose, onSubmit, titleRender } = this.props;
    return (
      <Dialog contentClassName={classnames(className, 'common-msg-dialog')} backFade onClose={onClose}>
        <p className="common-msg-dialog-title">{titleRender ? titleRender(title) : title}</p>
        <div className="common-msg-dialog-bottom">
          {submitText && (
            <Button
              className="common-msg-dialog-submit"
              width={submitWidth}
              theme="dialog"
              activated
              onClick={onSubmit}
            >
              {submitText}
            </Button>
          )}
          {closeText && (
            <Button className="common-msg-dialog-close" theme="dialog" onClick={onClose}>
              {closeText}
            </Button>
          )}
        </div>
      </Dialog>
    );
  }
}

export default ConfirmDialog;
