import { depthClone } from '@utils/globalUtils';

import { ILibF } from '@fbs/rp/models/ds/lib';
import IComponent from '@fbs/rp/models/ds/component';

import { getAllComponentResource } from '@helpers/resourceHelper';
import { resetID, resetInteractionTargetID, resetValueMap } from '@helpers/componentHelper';

import IDesignManagerBase, { IGetSymbolDataOptions } from '../types/base';

export default class DesignManager implements IDesignManagerBase {
  // protected libs?: ILibF[];

  protected _libs: { [libID: string]: ILibF } = {};

  protected instanceCache: { [instanceID: string]: { clone?: boolean; data: IComponent } } = {};

  setLibs(value?: ILibF[]) {
    // this.libs = value;
    value?.forEach((lib) => {
      this._libs[lib._id] = lib;
    });
  }

  getLib(libID: string): ILibF | undefined {
    // if (this.libs) {
    //   return this.libs?.find((item) => {
    //     return item._id === libID;
    //   });
    // }
    // return undefined;
    return this._libs[libID];
  }

  getSymbolData(
    libID: string,
    masterID: string,
    options: IGetSymbolDataOptions = { enableClone: true },
    instanceID?: string,
  ): IComponent | undefined {
    // 此处无法解决资源库更新的问题。导致资源库性能问题是由于 getSymbolData 的获取由state状态更新频繁导致
    // if (instanceID) {
    //   const cache = this.instanceCache[instanceID];
    //   if (cache) {
    //     return cache.data;
    //   }
    // }
    const lib = this.getLib(libID);
    if (lib) {
      const allComps = [...getAllComponentResource(lib), ...(lib.componentsRecycle || [])];
      const symbol = allComps.find((item) => item._id === masterID);
      if (symbol) {
        let result: IComponent;
        if (options.enableClone) {
          result = depthClone(symbol);
          const idMap = resetID([result.value]);
          resetInteractionTargetID([result.value], idMap);
          result.value.components?.length && resetValueMap(result.value.components, idMap);
        } else {
          result = symbol;
        }
        if (instanceID) {
          this.instanceCache[instanceID] = { data: result, clone: options.enableClone };
        }
        return result;
      }
    }
    return undefined;
  }
}
