import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

/**
 * 工具提示属性对象
 */
interface ITooltipProps {
  theme?: 'normal' | 'small';
  direction?: 'up' | 'down';
  text?: string | React.ReactNode;
  width?: number | string;
  align?: 'left' | 'center' | 'right';
  children?: React.ReactNode;
  maxWidth?: number;
  tipStyle?: React.CSSProperties;
  NoCover?: boolean;
}

const Tooltip: React.FC<ITooltipProps> = ({
  theme,
  direction,
  text,
  children,
  width,
  align,
  tipStyle,
  NoCover,
}: ITooltipProps) => (
  <div style={{ width }} className="dsm-c-tooltip">
    {text && (
      <div
        style={tipStyle}
        className={classnames('tips', direction, align, { 'size-s': theme === 'small' }, { cover: NoCover })}
      >
        {text}
      </div>
    )}
    {children}
  </div>
);

Tooltip.defaultProps = {
  theme: 'normal',
  direction: 'down',
  text: '',
  align: 'center',
  tipStyle: {},
};

export default Tooltip;
