import { unstable_batchedUpdates } from 'react-dom';

import { MouseButtons } from '@consts/enums/mouseButton';
import optimizeCall from './optimizeCallUtils';

const optimizeMouseMoveCall = optimizeCall();
window.addEventListener(
  'mousedown',
  (e) => {
    window.mouseDownTarget = e.target;
  },
  true,
);

window.addEventListener('mousemove', optimizeMouseMoveCall.call);

window.addEventListener(
  'mouseup',
  () => {
    window.mouseDownTarget = null;
  },
  true,
);

type OnMoving = (
  e: MouseEvent,
  delta: { x: number; y: number },
  offset?: { dx: number; dy: number },
  startPosition?: { pageX: number; pageY: number },
) => void;

type OnMoveEnd = (e: MouseEvent, delta: { x: number; y: number }, isMoved: boolean) => void;

/**
 * 鼠标拖拽代理工具方法
 * @param {OnMoving} onMove 鼠标移动过程中的回调事件
 * @param {OnMoveEnd} onEnd 鼠标松开时的回调事件
 * @param {batchSetState} boolean 批处理onMove和onEnd内的setState
 * @description 此拖拽非系统原生的DragEvent事件，应用场景，当一个元素在鼠标按下后，需要监听鼠标的移动与释放时，适用该该方法
 * 已在该方法中进行了move的频率优化，使用该方法的前提是界面元素的onMouseMove,onMouseUp事件中，不能阻止冒泡，
 * 否则会导致鼠标移动到这类元素上时，不能触发该方法上的onMoving回调；鼠标在阻止mouseUp冒泡的元素上松开时，不能触发onEnd回调；
 */
export const dragDelegate = (onMove: OnMoving, onEnd: OnMoveEnd, batchSetState = true) => {
  let startPosition: { pageX: number; pageY: number };
  let currentPosition: { pageX: number; pageY: number };
  const delta = {
    x: 0,
    y: 0,
  };
  const offset = { dx: 0, dy: 0 };
  let isMoving = false;
  let lastDelta = { ...delta };
  let endCalled = false; //
  // FIXME Mxj 在鼠标移动的第一帧触发之前，可能还会有大量耗时的逻辑需要处理，应在这之前先记录下鼠标的位置
  if (window.event instanceof MouseEvent) {
    startPosition = {
      pageX: window.event.pageX,
      pageY: window.event.pageY,
    };
  }
  const onMoveProxy = (e: MouseEvent) => {
    if (window.mouseDownTarget !== onMoveProxy.mouseDownTarget || window.mouseDownTarget === null) {
      onEndProxy(e); // 防止mouseup未响应，在这里卸载之前的
      return;
    }
    // 实现 鼠标滚轮按下拖拽移动画布功能
    if (e.buttons !== MouseButtons.Left && e.buttons !== MouseButtons.Wheel) {
      return;
    }
    if (!startPosition) {
      startPosition = {
        pageX: e.pageX,
        pageY: e.pageY,
      };
    }
    if (!currentPosition) {
      currentPosition = startPosition;
    }
    delta.x = e.pageX - startPosition.pageX;
    delta.y = e.pageY - startPosition.pageY;
    offset.dx = e.pageX - currentPosition.pageX;
    offset.dy = e.pageY - currentPosition.pageY;

    currentPosition = {
      pageX: e.pageX,
      pageY: e.pageY,
    };
    if (!isMoving) {
      if (Math.abs(delta.x) >= 5 || Math.abs(delta.y) >= 5) {
        isMoving = true;
      }
    }
    const isChanged = lastDelta.x !== delta.x || lastDelta.y !== delta.y;
    if (isMoving && isChanged) {
      lastDelta = { ...delta };
      batchSetState
        ? unstable_batchedUpdates(() => {
            onMove(e, delta, offset, startPosition);
          })
        : onMove(e, delta, offset, startPosition);
    }
  };
  const onEndProxy = (e: MouseEvent) => {
    if (endCalled) {
      return;
    }
    endCalled = true;
    window.removeEventListener('mouseup', onEndProxy);
    optimizeMouseMoveCall.remove(onMoveProxy);
    batchSetState
      ? unstable_batchedUpdates(() => {
          onEnd(e, delta, isMoving);
        })
      : onEnd(e, delta, isMoving);
  };
  window.addEventListener('mouseup', onEndProxy);
  onMoveProxy.mouseDownTarget = window.mouseDownTarget;
  optimizeMouseMoveCall.add(onMoveProxy);
};

/**
 * get mouse position in a node and page
 * @param node
 * @param e
 * @returns {{x: *, y: *, pageX: *, pageY: *}}
 */
export const getMousePosition = (node: HTMLElement, e: MouseEvent) => {
  const boundingRect = node.getBoundingClientRect();
  return {
    x: e.pageX - boundingRect.left + node.scrollLeft,
    y: e.pageY - boundingRect.top + node.scrollTop,
    pageX: e.pageX,
    pageY: e.pageY,
  };
};

export const onMove = (cb: Function) => {
  optimizeMouseMoveCall.add(cb);
};

export const offMove = (cb: Function) => {
  optimizeMouseMoveCall.remove(cb);
};
