import * as React from 'react';
import classnames from 'classnames';

import Tooltip, { ITooltipProp } from '.';

import './index.scss';

interface IEllipsisTooltip extends ITooltipProp {
  childClassName?: string;
}

const EllipsisTooltip: React.FC<IEllipsisTooltip> = ({ children, childClassName, ...resetProps }) => {
  const eleRef = React.useRef<HTMLDivElement>(null);
  const [isEllipsis, setIsEllipsis] = React.useState(false);

  React.useEffect(() => {
    if (eleRef.current) {
      setIsEllipsis(eleRef.current?.scrollWidth - eleRef.current?.offsetWidth > 0);
    }
  }, [children]);

  const renderDom = (className = '') => (
    <div className={classnames('dsm-c-rp-ellipsis', className, childClassName)} ref={eleRef}>
      {children}
    </div>
  );

  return isEllipsis ? <Tooltip {...resetProps}>{renderDom('dsm-c-rp-ellipsis-tool-tip')}</Tooltip> : renderDom();
};

export default EllipsisTooltip;
