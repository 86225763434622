import * as sanitizeHtml from 'sanitize-html';
import { isUndefined, isEqual, isNumber, kebabCase } from 'lodash';

import { isEqualDate, jsonClone, max, between } from '@utils/globalUtils';
import * as BoundsUtils from '@utils/boundsUtils';
import { measureTextSize } from '@/utils/textUtils';
import { moveArrayItems } from '@utils/globalUtils';

import {
  CellBorder,
  ICellStyle,
  ITableCell,
  ITableColumn,
  ITableRow,
  ITableValue,
  CellTypes,
} from '@fbs/rp/models/table';
import ITextFormatEx from '@/fbs/rp/models/properties/textFormat';
import ITextFormat, { FontStyle, TextAlign, VerticalAlign } from '@fbs/rp/models/properties/text';
import { IProperties } from '@fbs/rp/models/property';
import { ETextBehaviour, IComponentData } from '@/fbs/rp/models/component';
import { Color, PureColor } from '@fbs/rp/models/properties/color';
import IStroke from '@fbs/rp/models/properties/stroke';
import { ISize } from '@fbs/common/models/common';

import {
  DefalutColumn,
  DefalutRow,
  DefaultCell,
  DefaultCellHeight,
  DefaultCellWidth,
  MinCellHeight,
  MinCellWidth,
  DefaultCellPadding,
  MaxRowsCount,
  MaxColumnsCount,
  DefalutTableStroke,
} from '@consts/defaultData/table';
import { UIComponent } from '@editor/comps';
import { makeText } from '@libs/basic/NewText';
import { StyleHelper } from '@helpers/styleHelper';
import { makeComponent } from '@libs/libs';
import { CText, CPureText } from '@/libs/constants';
import i18n from '@/i18n';
import { parseColorToString } from '@/utils/graphicsUtils';

import { getNewID } from './idHelper';

export type ICellPosition = { row: number; column: number };

export interface ISelectArea {
  start: ICellPosition;
  end: ICellPosition;
}

export interface ITableClipboardData {
  cells: ITableCell[][];
  rowHeights?: (number | undefined)[];
  colWidths?: (number | undefined)[];
  components?: IComponentData[];
  cellComponents?: IComponentData[];
}

/**
 * 根据value获取表格size
 */
export const getTableSizeFromValue = (value: ITableValue) => {
  const { rows, columns, showHeader, headerHeight } = value;
  const width = columns.map((i) => i.width).reduce((a, b) => a + b);
  let height = rows.map((i) => i.height).reduce((a, b) => a + b);
  if (showHeader) {
    height += headerHeight;
  }
  return { width, height };
};

/**
 * 根据value获取表格最小size
 */
export const getTableMinSizeFromValue = (value: ITableValue) => {
  const { rows, columns, showHeader } = value;
  const width = columns.length * MinCellWidth;
  let height = rows.length * MinCellHeight;
  if (showHeader) {
    height += MinCellHeight;
  }
  return { width, height };
};

/**
 * flex水平对齐
 */
export const textAlign2Flex = (align: TextAlign) => {
  switch (align) {
    case 'left':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'right':
      return 'flex-end';
    default:
      return 'flex-start';
  }
};

/**
 * flex垂直对齐
 */
export const verticalAlign2Flex = (align: VerticalAlign) => {
  switch (align) {
    case 'top':
      return 'flex-start';
    case 'middle':
      return 'center';
    case 'bottom':
      return 'flex-end';
    default:
      return 'flex-start';
  }
};

/**
 * 处理被合并的单元格边框的显示问题（不考虑位置）
 */
export const parserMergeBorder = (
  tableValue: ITableValue,
  cell: ITableCell | ITableColumn,
  position: ICellPosition,
  tableLine = { rowLine: true, columnLine: true },
): CellBorder => {
  const {
    style: { border },
    mergeAcross,
    mergeDown,
    mergedBy,
  } = cell;
  const copy = jsonClone(border);
  if (mergeAcross + mergeDown) {
    // 合并单元格
    if (mergeAcross) copy.right.disabled = true;
    if (mergeDown) copy.bottom.disabled = true;
  } else if (mergedBy) {
    const [column, row] = mergedBy;
    // 被合并的单元格
    const mergeCell = findCell(tableValue, { row, column });
    if (mergeCell) {
      // 不在左
      if (position.column !== column) copy.left.disabled = true;
      // 不在右
      if (position.column !== column + mergeCell.mergeAcross) copy.right.disabled = true;
      // 不在上
      if (position.row !== row) copy.top.disabled = true;
      // 不在下
      if (position.row !== row + mergeCell.mergeDown) copy.bottom.disabled = true;
    }
  }
  // 处理表格风格
  const { rowLine, columnLine } = tableLine;
  if (!rowLine) {
    copy.top.disabled = true;
    copy.bottom.disabled = true;
  }
  if (!columnLine) {
    copy.left.disabled = true;
    copy.right.disabled = true;
  }
  return copy;
};

/**
 * 表格resize时，修改行列；
 */
export const updateValueWithTableSizeDiff = (tableValue: ITableValue, sizeDiff: ISize) => {
  const copy = jsonClone(tableValue);
  if (!sizeDiff.height && !sizeDiff.width) return copy;
  const { rows, showHeader, columns } = copy;
  const columnDataArr = columns.map((item, index) => {
    return { index, data: item.width };
  });
  const widthArr = prorateDistribution(columnDataArr, sizeDiff.width, MinCellWidth);
  const rowDataArr = rows.map((item, index) => {
    return { index, data: item.height };
  });
  if (showHeader) {
    rowDataArr.unshift({ index: -1, data: copy.headerHeight });
  }
  const heightArr = prorateDistribution(rowDataArr, sizeDiff.height, MinCellHeight);

  copy.rows.forEach((row, i) => {
    const heightItem = heightArr.find((c) => c.index === i);
    if (!isUndefined(heightItem)) {
      row.height = heightItem.data;
    }
  });
  if (showHeader) {
    const heightItem = heightArr.find((c) => c.index === -1);
    if (!isUndefined(heightItem)) {
      copy.headerHeight = heightItem.data;
    }
  }
  copy.columns.forEach((column, i) => {
    const heightItem = widthArr.find((c) => c.index === i);
    if (!isUndefined(heightItem)) {
      column.width = heightItem.data;
    }
  });

  return copy;
};

/**
 * 按比例取整分配
 */
export const prorateDistribution = (origins: { index: number; data: number }[], diff: number, min = 0) => {
  const copy = jsonClone(origins).sort((a, b) => a.data - b.data);
  // 按比例分配
  let count = copy.reduce((a, b) => {
    return { index: 0, data: a.data + b.data };
  }).data;
  const newDiff = Math.max(min * origins.length - count, diff);
  let pec = 0;
  let rest = newDiff;
  if (newDiff < 0) {
    for (let i = 0; i < copy.length; i++) {
      const arr1 = copy.slice(0, i);
      const arr2 = copy.slice(i, copy.length);
      let curRest =
        newDiff -
        (arr1.length === 0
          ? 0
          : min * arr1.length -
            arr1.reduce((a, b) => {
              return { index: 0, data: a.data + b.data };
            }).data);
      const restCount =
        arr2.length === 0
          ? 0
          : arr2.reduce((a, b) => {
              return { index: 0, data: a.data + b.data };
            }).data;
      const newData = copy[i].data + (curRest * copy[i].data) / restCount;
      if (newData >= min) {
        pec = i;
        count = restCount;
        rest = curRest;
        break;
      }
    }
  }
  copy.forEach((item, i) => {
    if (i < pec) {
      item.data = min;
    } else {
      const newData = item.data + (rest * copy[i].data) / count;
      item.data = newData;
    }
  });
  // 向下取整，再分配
  let surplus = 0;
  copy.forEach((item) => {
    const newData = Math.floor(item.data);
    surplus = surplus + (item.data - newData);
    item.data = newData;
  });

  for (let i = copy.length - 1; i >= 0 && surplus > 0; i--) {
    if (surplus < 1) {
      copy[i].data = copy[i].data + surplus;
      surplus = 0;
    } else {
      copy[i].data = copy[i].data + 1;
      surplus = surplus - 1;
    }
  }
  return copy;
};

/**
 * 是否可合并单元格
 * 条件： 1.皆零散单元格且>1;
 *        2.不能横跨表头与普通单元格;
 */
export const canMergeCells = (value: ITableValue, selectArea: ISelectArea) => {
  if (selectArea.start.row === -1 && selectArea.end.row > -1) return false;
  const cells = getAllCellsOfSelectArea(value, selectArea);
  if (cells.length < 2) return false;
  return !cells.some((item) => {
    const { mergedBy, mergeAcross, mergeDown } = item.cell;
    return mergedBy || mergeAcross || mergeDown;
  });
};

/**
 * 是否可拆分单元格
 * 条件：选中范围内存在合并单元格的部分
 */
export const canSplitCells = (value: ITableValue, selectArea: ISelectArea) => {
  const cells = getAllCellsOfSelectArea(value, selectArea);
  return cells.some((item) => item.cell.mergeAcross || item.cell.mergeDown || item.cell.mergedBy);
};

/**
 * 是否可以删除行
 */
export const canRemoveRows = (value: ITableValue, selectRows: number[]) => {
  const newRows = Array.from(new Set(selectRows)).sort((a, b) => a - b);
  const rowCount = value.showHeader ? value.rows.length + 1 : value.rows.length;
  return newRows.length < rowCount;
};

/**
 * 是否可以删除列
 */
export const canRemoveColumns = (value: ITableValue, selectColumns: number[]) => {
  const newColumns = Array.from(new Set(selectColumns)).sort((a, b) => a - b);
  return newColumns.length < value.columns.length;
};

/**
 * 合并单元格，此处处理单元格合并， UITableComponent处理子组件
 */
export const mergeCells = (value: ITableValue, selectArea: ISelectArea) => {
  const copy = jsonClone(value);
  resetSelectAreaOrigin(selectArea);
  const firstCell = findCell(copy, selectArea.start);
  if (firstCell) {
    firstCell.mergeDown = selectArea.end.row - selectArea.start.row;
    firstCell.mergeAcross = selectArea.end.column - selectArea.start.column;
  }
  const cells = getAllCellsOfSelectArea(copy, selectArea);
  cells.forEach((item) => {
    const { cell } = item;
    if (!cell.mergeAcross && !cell.mergeDown) {
      cell.mergedBy = [selectArea.start.column, selectArea.start.row];
      cell.type = jsonClone(DefaultCell.type);
      cell.data = jsonClone(DefaultCell.data);
      cell.dataSource = jsonClone(DefaultCell.dataSource);
    }
  });
  return copy;
};

/**
 * 拆分单元格
 * 统一其他样式，保持边框独立
 */
export const splitCells = (value: ITableValue, selectArea: ISelectArea) => {
  const copy = jsonClone(value);
  const cells = getAllCellsOfSelectArea(copy, selectArea);
  const mergeCells = cells.filter((item) => item.cell.mergeAcross || item.cell.mergeDown || item.cell.mergedBy);
  mergeCells.forEach((item) => {
    const { cell, position } = item;
    const area = getAreaOfCell(copy, cell, position);
    const cells = getAllCellsOfSelectArea(copy, area);
    const firstCell = cells.find((cellData) => cellData.cell.mergedBy);
    cells.forEach((cellData) => {
      cellData.cell.mergeAcross = 0;
      cellData.cell.mergeDown = 0;
      cellData.cell.mergedBy = undefined;
      if (firstCell && cellData !== firstCell) {
        cellData.cell.style = { ...jsonClone(firstCell.cell.style), border: cellData.cell.style.border };
        cellData.cell.data = jsonClone(DefaultCell.data);
        cellData.cell.dataSource = jsonClone(DefaultCell.dataSource);
        cellData.cell.type = jsonClone(DefaultCell.type);
      }
    });
  });
  return copy;
};

/**
 * 向前插入行，此处处理单元格合并， UITableComponent处理子组件
 */
export const unshiftRows = (value: ITableValue, rowIndex: number, count: number) => {
  const copy = jsonClone(value);
  // 当前将插入位置的行
  const currentRowArea: ISelectArea = {
    start: {
      row: rowIndex,
      column: 0,
    },
    end: {
      row: rowIndex,
      column: copy.columns.length - 1,
    },
  };
  const currentRowCells = getAllCellsOfSelectArea(copy, currentRowArea);
  // 添加的行
  const addRowCells: ITableCell[][] = jsonClone(
    new Array(count).fill(new Array(copy.columns.length).fill(DefaultCell)),
  );
  const addRows: ITableRow[] = jsonClone(new Array(count).fill(DefalutRow));
  updateAddRowsStyle(copy, rowIndex, addRowCells, addRows);
  // 处理单元格合并
  updataMergeCellsAfterAddRows(currentRowCells, rowIndex, addRowCells, copy, count);
  // 插入行
  copy.cells.splice(rowIndex, 0, ...addRowCells);
  copy.rows.splice(rowIndex, 0, ...addRows);
  return copy;
};

/**
 * 向后插入行，此处处理单元格合并， UITableComponent处理子组件
 */
export const pushRows = (value: ITableValue, rowIndex: number, count: number) => {
  const copy = jsonClone(value);
  // 将添加的行
  const addRowCells: ITableCell[][] = jsonClone(
    new Array(count).fill(new Array(copy.columns.length).fill(DefaultCell)),
  );
  const addRows: ITableRow[] = jsonClone(new Array(count).fill(DefalutRow));
  // 当前选择行
  const selRowIndex = rowIndex - 1;
  updateAddRowsStyle(copy, selRowIndex, addRowCells, addRows);
  // 当前插入位置的行区域
  let currentRowArea: ISelectArea | undefined;
  if (rowIndex < copy.rows.length) {
    // 在中间或前面插入
    currentRowArea = {
      start: {
        row: rowIndex,
        column: 0,
      },
      end: {
        row: rowIndex,
        column: copy.columns.length - 1,
      },
    };
    const currentRowCells = getAllCellsOfSelectArea(copy, currentRowArea);
    // 处理单元格合并
    updataMergeCellsAfterAddRows(currentRowCells, rowIndex, addRowCells, copy, count);
  }
  copy.cells.splice(rowIndex, 0, ...addRowCells);
  copy.rows.splice(rowIndex, 0, ...addRows);
  return copy;
};

/**
 * 单行删除，此处处理单元格合并， UITableComponent处理子组件
 */
export const removeRow = (value: ITableValue, rowIndex: number) => {
  const copy = jsonClone(value);
  if (rowIndex === -1) {
    // 表头重置样式并隐藏
    copy.showHeader = false;
    copy.headerHeight = DefaultCellHeight;
    const newColumns = jsonClone(new Array(copy.columns.length).fill(DefalutColumn) as ITableColumn[]);
    newColumns.forEach((item, i) => {
      item.width = copy.columns[i]?.width || DefaultCellWidth;
    });
    copy.columns = newColumns;
  } else {
    const removeArea: ISelectArea = {
      start: { row: rowIndex, column: 0 },
      end: { row: rowIndex, column: copy.columns.length - 1 },
    };
    // 处理被合并的关系
    const removeCells = getAllCellsOfSelectArea(copy, removeArea);
    removeCells.forEach((item) => {
      const { mergedBy } = item.cell;
      if (mergedBy && mergedBy[1] < rowIndex && mergedBy[0] === item.position.column) {
        // 处理mergeDown - 1
        const position = { row: mergedBy[1], column: mergedBy[0] };
        const mergeCell = findCell(copy, position);
        if (mergeCell?.mergeDown) {
          mergeCell.mergeDown = mergeCell.mergeDown - 1;
        }
      }
    });
    // 处理合并的关系
    if (rowIndex < copy.rows.length - 1) {
      // 删除的行的下面的区域
      const lowerArea: ISelectArea = {
        start: { row: rowIndex + 1, column: 0 },
        end: { row: copy.rows.length - 1, column: copy.columns.length - 1 },
      };
      const lowerAreaCells = getAllCellsOfSelectArea(copy, lowerArea);
      lowerAreaCells.forEach((item) => {
        const { cell, position } = item;
        if (cell.mergedBy) {
          if (cell.mergedBy[1] === rowIndex) {
            // 被将删除的单元格合并
            const mergePosition = {
              row: cell.mergedBy[1],
              column: cell.mergedBy[0],
            };
            const mergeCell = findCell(copy, mergePosition);
            if (mergeCell?.mergeDown === 1 && mergeCell.mergeAcross === 0) {
              cell.mergedBy = undefined;
            } else if (mergeCell) {
              if (position.row === mergePosition.row + 1 && position.column === mergePosition.column) {
                cell.mergedBy = undefined;
                cell.mergeDown = mergeCell.mergeDown - 1;
                cell.mergeAcross = mergeCell.mergeAcross;
              }
            }
          } else if (cell.mergedBy[1] > rowIndex) {
            // 被其他区域的单元格合并
            cell.mergedBy = [cell.mergedBy[0], cell.mergedBy[1] - 1];
          }
        }
      });
    }
    copy.rows.splice(rowIndex, 1);
    copy.cells.splice(rowIndex, 1);
  }
  return copy;
};

/**
 * 向前插入列，此处处理单元格合并， UITableComponent处理子组件
 */
export const unshiftColumns = (value: ITableValue, columnIndex: number, count: number) => {
  const copy = jsonClone(value);
  // 当前插入的位置所在列的区域
  const currentColumnArea: ISelectArea = {
    start: {
      row: -1,
      column: columnIndex,
    },
    end: {
      row: copy.rows.length - 1,
      column: columnIndex,
    },
  };
  // 当前插入位置的单元格
  const currentColumnCells = getAllCellsOfSelectArea(copy, currentColumnArea);
  // 添加的列
  const addColumns: ITableColumn[] = jsonClone(new Array(count).fill(DefalutColumn));
  const addColumnCells: ITableCell[][] = jsonClone(
    new Array(copy.rows.length).fill(new Array(count).fill(DefaultCell)),
  );
  updateAddColumnsStyle(copy, columnIndex, addColumnCells, addColumns);
  // 处理单元格合并
  const mergeCellPositions: ICellPosition[] = [];
  // 调整mergeby
  currentColumnCells.forEach((item) => {
    const { mergedBy, style } = item.cell;
    // 被左侧合并的
    if (mergedBy && mergedBy[0] < columnIndex) {
      if (item.position.row === -1) {
        addColumns.forEach((cell) => {
          cell.mergedBy = mergedBy;
        });
      } else {
        addColumnCells[item.position.row].forEach((cell) => {
          cell.mergedBy = jsonClone(mergedBy);
          cell.style = jsonClone(style);
        });
      }

      const position = {
        row: mergedBy[1],
        column: mergedBy[0],
      };
      if (!mergeCellPositions.some((item) => isEqualDate(item, position))) {
        mergeCellPositions.push(position);
      }
    }
  });
  // 调整mergeAcross
  mergeCellPositions.forEach((position) => {
    const cell = findCell(copy, position);
    if (cell && cell.mergeAcross) {
      cell.mergeAcross = cell.mergeAcross + count;
    }
  });
  // 调整mergeby
  copy.cells.forEach((row) => {
    row.forEach((cell) => {
      if (cell.mergedBy && cell.mergedBy[0] >= columnIndex) {
        cell.mergedBy = [cell.mergedBy[0] + count, cell.mergedBy[1]];
      }
    });
  });
  copy.columns.forEach((column) => {
    if (column.mergedBy && column.mergedBy[0] >= columnIndex) {
      column.mergedBy = [column.mergedBy[0] + count, column.mergedBy[1]];
    }
  });
  // 插入列
  copy.cells.forEach((copyRow, rowIndex) => {
    copyRow.splice(columnIndex, 0, ...addColumnCells[rowIndex]);
  });
  copy.columns.splice(columnIndex, 0, ...addColumns);
  return copy;
};

/**
 * 向后插入列，此处处理单元格合并， UITableComponent处理子组件
 */
export const pushColumns = (value: ITableValue, columnIndex: number, count: number) => {
  const copy = jsonClone(value);
  // 添加的列
  const addColumns: ITableColumn[] = jsonClone(new Array(count).fill(DefalutColumn));
  const addColumnCells: ITableCell[][] = jsonClone(
    new Array(copy.rows.length).fill(new Array(count).fill(DefaultCell)),
  );
  // 当前选择列
  const selColumnIndex = columnIndex - 1;
  updateAddColumnsStyle(copy, selColumnIndex, addColumnCells, addColumns);
  // 当前插入的位置所在列的区域
  let currentColumnArea: ISelectArea | undefined;
  if (columnIndex <= copy.columns.length) {
    currentColumnArea = {
      start: {
        row: -1,
        column: columnIndex,
      },
      end: {
        row: copy.rows.length - 1,
        column: columnIndex,
      },
    };
    // 当前插入位置的单元格
    const currentColumnCells = getAllCellsOfSelectArea(copy, currentColumnArea);
    // 处理单元格合并
    const mergeCellPositions: ICellPosition[] = [];
    // 调整mergeby
    currentColumnCells.forEach((item) => {
      const { mergedBy, style } = item.cell;
      // 被左侧合并的
      if (mergedBy && mergedBy[0] < columnIndex) {
        if (item.position.row === -1) {
          addColumns.forEach((cell) => {
            cell.mergedBy = mergedBy;
          });
        } else {
          addColumnCells[item.position.row].forEach((cell) => {
            cell.mergedBy = jsonClone(mergedBy);
            cell.style = jsonClone(style);
          });
        }

        const position = {
          row: mergedBy[1],
          column: mergedBy[0],
        };
        if (!mergeCellPositions.some((item) => isEqualDate(item, position))) {
          mergeCellPositions.push(position);
        }
      }
    });
    // 调整mergeAcross
    mergeCellPositions.forEach((position) => {
      const cell = findCell(copy, position);
      if (cell && cell.mergeAcross) {
        cell.mergeAcross = cell.mergeAcross + count;
      }
    });
    // 调整mergeby
    copy.cells.forEach((row) => {
      row.forEach((cell) => {
        if (cell.mergedBy && cell.mergedBy[0] >= columnIndex) {
          cell.mergedBy = [cell.mergedBy[0] + count, cell.mergedBy[1]];
        }
      });
    });
    copy.columns.forEach((column) => {
      if (column.mergedBy && column.mergedBy[0] >= columnIndex) {
        column.mergedBy = [column.mergedBy[0] + count, column.mergedBy[1]];
      }
    });
  }

  // 插入列
  copy.cells.forEach((copyRow, rowIndex) => {
    copyRow.splice(columnIndex, 0, ...addColumnCells[rowIndex]);
  });
  copy.columns.splice(columnIndex, 0, ...addColumns);
  return copy;
};

/**
 * 单列删除，此处处理单元格合并， UITableComponent处理子组件
 */
export const removeColumn = (value: ITableValue, columnIndex: number) => {
  const copy = jsonClone(value);
  const removeArea: ISelectArea = {
    start: { row: -1, column: columnIndex },
    end: { row: copy.rows.length - 1, column: columnIndex },
  };
  // 处理被合并的关系
  const removeCells = getAllCellsOfSelectArea(copy, removeArea);
  removeCells.forEach((item) => {
    const { mergedBy } = item.cell;
    if (mergedBy && mergedBy[0] < columnIndex && mergedBy[1] === item.position.row) {
      // 处理mergeAcross - 1
      const position = { row: mergedBy[1], column: mergedBy[0] };
      const mergeCell = findCell(copy, position);
      if (mergeCell?.mergeAcross) {
        mergeCell.mergeAcross = mergeCell.mergeAcross - 1;
      }
    }
  });
  // 处理合并的关系
  if (columnIndex < copy.columns.length - 1) {
    // 删除的列的右侧的区域
    const rightArea: ISelectArea = {
      start: { row: -1, column: columnIndex + 1 },
      end: { row: copy.rows.length - 1, column: copy.columns.length - 1 },
    };
    const rightAreaCells = getAllCellsOfSelectArea(copy, rightArea);
    rightAreaCells.forEach((item) => {
      const { cell, position } = item;
      if (cell.mergedBy) {
        if (cell.mergedBy[0] === columnIndex) {
          // 被将删除的单元格合并
          const mergePosition = {
            row: cell.mergedBy[1],
            column: cell.mergedBy[0],
          };
          const mergeCell = findCell(copy, mergePosition);
          if (mergeCell?.mergeAcross === 1 && mergeCell.mergeDown === 0) {
            cell.mergedBy = undefined;
          } else if (mergeCell) {
            if (position.column === mergePosition.column + 1 && position.row === mergePosition.row) {
              cell.mergedBy = undefined;
              cell.mergeDown = mergeCell.mergeDown;
              cell.mergeAcross = mergeCell.mergeAcross - 1;
            }
          }
        } else if (cell.mergedBy[0] > columnIndex) {
          // 被其他区域的单元格合并
          cell.mergedBy = [cell.mergedBy[0] - 1, cell.mergedBy[1]];
        }
      }
    });
  }
  copy.columns.splice(columnIndex, 1);
  copy.cells.forEach((row) => row.splice(columnIndex, 1));
  return copy;
};

/**
 * 根据行列位置查找单元格
 */
export const findCell = (value: ITableValue, cellPosition: ICellPosition) => {
  const { row, column } = cellPosition;
  const { columns, cells } = value;
  let cell: ITableColumn | ITableCell | undefined;
  if (row === -1) {
    cell = columns.find((_item, i) => i === column);
  } else {
    cell = cells.find((_item, i) => i === row)?.find((_item, i) => i === column);
  }
  return cell;
};

/**
 * 获取选择的所有单元格（包括被合并的）
 */
export const getAllCellsOfSelectArea = (value: ITableValue, selectArea: ISelectArea) => {
  const cells: {
    cell: ITableColumn | ITableCell;
    position: ICellPosition;
  }[] = [];
  for (let i = selectArea.start.row; i <= selectArea.end.row; i++) {
    for (let j = selectArea.start.column; j <= selectArea.end.column; j++) {
      const position = { row: i, column: j };
      const cell = findCell(value, position);
      if (cell) cells.push({ cell, position });
    }
  }
  return cells;
};

/**
 * 获取选择的行列序号
 */
export const getRowsAndColumnsOfSelectArea = (selectArea: ISelectArea) => {
  const { start, end } = selectArea;
  const rows = [];
  const columns = [];
  for (let i = start.row; i <= end.row; i++) {
    rows.push(i);
  }
  for (let j = start.column; j <= end.column; j++) {
    columns.push(j);
  }
  return { rows, columns };
};

/**
 * 数组序号转字母序号： A、B、C、D...Z、AA、AB、AC...
 */
export const index2String = (index: number) => {
  const ordA = 'A'.charCodeAt(0);
  const ordZ = 'Z'.charCodeAt(0);
  const len = ordZ - ordA + 1;
  let s = '';
  while (index >= 0) {
    s = String.fromCharCode((index % len) + ordA) + s;
    index = Math.floor(index / len) - 1;
  }
  return s;
};

/**
 * 联合两个选择区
 */
export const uniteSelectArea = (area1: ISelectArea, area2: ISelectArea) => {
  resetSelectAreaOrigin(area1);
  resetSelectAreaOrigin(area2);
  const copy = jsonClone(area1);
  area1.start = {
    row: Math.min(copy.start.row, area2.start.row),
    column: Math.min(copy.start.column, area2.start.column),
  };
  area1.end = {
    row: Math.max(copy.end.row, area2.end.row),
    column: Math.max(copy.end.column, area2.end.column),
  };
  return area1;
};

/**
 * 将选择区域的起点重置为左上角
 */
export const resetSelectAreaOrigin = (selectArea: ISelectArea) => {
  const copy = jsonClone(selectArea);
  selectArea.start.row = Math.min(copy.start.row, copy.end.row);
  selectArea.end.row = Math.max(copy.start.row, copy.end.row);
  selectArea.start.column = Math.min(copy.start.column, copy.end.column);
  selectArea.end.column = Math.max(copy.start.column, copy.end.column);
};

/**
 * 获取单元格的真实区域
 */
export const getAreaOfCell = (
  tableValue: ITableValue,
  cell: ITableColumn | ITableCell,
  cellPosition: ICellPosition,
) => {
  const selectArea = { start: cellPosition, end: cellPosition };
  if (cell.mergedBy) {
    selectArea.start = { column: cell.mergedBy[0], row: cell.mergedBy[1] };
    const startCell = findCell(tableValue, selectArea.start);
    selectArea.end = {
      column: cell.mergedBy[0] + (startCell?.mergeAcross || 0),
      row: cell.mergedBy[1] + (startCell?.mergeDown || 0),
    };
  } else if (cell.mergeAcross || cell.mergeDown) {
    selectArea.end = { column: cellPosition.column + cell.mergeAcross, row: cellPosition.row + cell.mergeDown };
  }
  resetSelectAreaOrigin(selectArea);
  return selectArea;
};

/**
 * 获取当前选择区域的真实范围
 */
export const toRealAreaOfSelectArea = (tableValue: ITableValue, selectArea: ISelectArea) => {
  const copy = jsonClone(selectArea);
  const ousideCells = getOutsideCells(tableValue, selectArea);
  const areas = ousideCells.map((data) => getAreaOfCell(tableValue, data.cell, data.position));
  const newArea = areas.reduce((a, b) => uniteSelectArea(a, b));
  selectArea.start = { ...newArea.start };
  selectArea.end = { ...newArea.end };
  if (!isEqualDate(copy, selectArea)) {
    toRealAreaOfSelectArea(tableValue, selectArea);
  }
};

/**
 * 获取选择区域外围的单元格
 */
export const getOutsideCells = (
  tableValue: ITableValue,
  selectArea: ISelectArea,
): {
  cell: ITableColumn | ITableCell;
  position: ICellPosition;
}[] => {
  const { columns, cells } = tableValue;
  const { start, end } = selectArea;
  const result: {
    cell: ITableColumn | ITableCell;
    position: ICellPosition;
  }[] = [];
  if (start.row === -1 || end.row === -1) {
    columns.forEach((column, i) => {
      if (i >= Math.min(start.column, end.column) && i <= Math.max(start.column, end.column)) {
        result.push({
          cell: column,
          position: { row: -1, column: i },
        });
      }
    });
  }
  cells.forEach((row, rowInex) => {
    row.forEach((cell, columnIndex) => {
      if (
        [start.row, end.row].includes(rowInex) &&
        columnIndex >= Math.min(start.column, end.column) &&
        columnIndex <= Math.max(start.column, end.column)
      ) {
        result.push({
          cell: cell,
          position: { row: rowInex, column: columnIndex },
        });
      } else if (
        rowInex > Math.min(start.row, end.row) &&
        rowInex < Math.max(start.row, end.row) &&
        (columnIndex === start.column || columnIndex === end.column)
      ) {
        result.push({
          cell: cell,
          position: { row: rowInex, column: columnIndex },
        });
      }
    });
  });
  return result;
};

/**
 * 判断单元格是否被选中
 */
export const isCellSelected = (row: number, column: number, selectArea?: ISelectArea) => {
  if (!selectArea) {
    return false;
  }
  return (
    selectArea.start.row <= row &&
    selectArea.end.row >= row &&
    selectArea.start.column <= column &&
    selectArea.end.column >= column
  );
};

/**
 * 获取相邻的单元格
 */
export const getAdjacentCells = (tabelValue: ITableValue, position: ICellPosition) => {
  const { row, column } = position;
  return {
    left: findCell(tabelValue, { row, column: column - 1 }),
    right: findCell(tabelValue, { row, column: column + 1 }),
    top: findCell(tabelValue, { row: row - 1, column }),
    bottom: findCell(tabelValue, { row: row + 1, column }),
  };
};

/**
 * 根据表格单元格尺寸与样式修改子组件
 */
export const formatCompPropertiesAndValue = (comp: UIComponent, cellStyle: ICellStyle) => {
  const { type } = comp;
  let newProperties: IProperties = {};
  let newValue = comp.value;
  switch (type) {
    case CText: {
      newValue = sanitizeHtml(comp.value as string, {
        allowedTags: [],
        allowedAttributes: {},
      });
      const textComp = makeText('', newValue);
      newProperties = textComp.properties;
      newProperties.textFormat = textComp.properties.textFormat!;
      newProperties.textFormat.verticalAlign = cellStyle.verticalAlign;
      newProperties.textFormat.textAlign = cellStyle.textAlign;
      newProperties.textFormat.wrap = true;
      break;
    }
    // FIXME: 支持其他组价类型
    default:
      return;
  }
  return { newProperties, newValue };
};

/**
 * 获取选择区的bounds
 */
export const getBoundsOfSelectArea = (tableValue: ITableValue, selectArea: ISelectArea) => {
  const { rows, columns, headerHeight, showHeader } = tableValue;
  const { start, end } = selectArea;
  let startLeft = 0;
  let endLeft = 0;
  columns.forEach((item, i) => {
    if (i < start.column) {
      startLeft += item.width;
    }
    if (i <= end.column) {
      endLeft += item.width;
    }
  });
  let startTop = 0;
  let endTop = 0;
  rows.forEach((item, i) => {
    if (i < start.row) {
      startTop += item.height;
    }
    if (i <= end.row) {
      endTop += item.height;
    }
  });
  if (showHeader) {
    startTop += headerHeight;
    endTop += headerHeight;
  }
  if (start.row === -1) {
    startTop -= headerHeight;
  }
  return BoundsUtils.init(
    {
      left: startLeft,
      top: startTop,
    },
    {
      left: endLeft,
      top: endTop,
    },
  );
};

/**
 * 设置行高
 */
export const setRowsHeight = (tableValue: ITableValue, selectRows: number[], height: number) => {
  const copy = jsonClone(tableValue);
  selectRows.forEach((i) => {
    copy.rows[i].height = Math.max(height, MinCellHeight);
  });
  return copy;
};

/**
 * 设置列宽
 */
export const setColumnsWidth = (tableValue: ITableValue, selectColumns: number[], width: number) => {
  const copy = jsonClone(tableValue);
  selectColumns.forEach((i) => {
    copy.columns[i].width = Math.max(width, MinCellWidth);
  });
  return copy;
};

/**
 * 设置文本格式
 */
export const setTextFormat = (tableValue: ITableValue, selectArea: ISelectArea, fragment: Partial<ITextFormatEx>) => {
  const copy = jsonClone(tableValue);
  const cells = getAllCellsOfSelectArea(copy, selectArea);
  cells.forEach((c) => {
    const style = c.cell.style;
    style.textFormat = jsonClone({ ...(style.textFormat ?? {}), ...fragment });
  });
  return copy;
};

/**
 * 设置水平对齐
 */
export const setTextAlign = (tableValue: ITableValue, selectArea: ISelectArea, align: TextAlign) => {
  const copy = jsonClone(tableValue);
  const cells = getAllCellsOfSelectArea(copy, selectArea);
  cells.forEach((c) => {
    c.cell.style.textAlign = align;
  });
  return copy;
};

/**
 * 设置垂直对齐
 */
export const setVerticalAlign = (tableValue: ITableValue, selectArea: ISelectArea, align: VerticalAlign) => {
  const copy = jsonClone(tableValue);
  const cells = getAllCellsOfSelectArea(copy, selectArea);
  cells.forEach((c) => {
    c.cell.style.verticalAlign = align;
  });
  return copy;
};

/**
 * 设置填充
 */
export const setFill = (tableValue: ITableValue, selectArea: ISelectArea, fill: PureColor) => {
  const copy = jsonClone(tableValue);
  const cells = getAllCellsOfSelectArea(copy, selectArea);
  cells.forEach((c) => {
    c.cell.style.fill = { ...fill };
  });
  return copy;
};

const mergeBorderStyle = (
  border: IStroke,
  borderChange: {
    width: number | undefined;
    color: Color | undefined;
  },
) => {
  const copy = jsonClone(border);
  const { width, color } = borderChange;
  isNumber(width) && (copy.thickness = width);
  color && (copy.color = color);
  return copy;
};

/**
 * 向前或向后增加行时处理合并问题
 */
function updataMergeCellsAfterAddRows(
  currentRowCells: { cell: ITableColumn | ITableCell; position: ICellPosition }[],
  rowIndex: number,
  addRowCells: ITableCell[][],
  copy: ITableValue,
  count: number,
) {
  const mergeCellPositions: ICellPosition[] = [];
  // 调整mergeby
  currentRowCells.forEach((item) => {
    const { mergedBy, style } = item.cell;
    // 被上面合并的
    if (mergedBy && mergedBy[1] < rowIndex) {
      addRowCells.forEach((row) => {
        const cell = row[item.position.column];
        cell.mergedBy = jsonClone(mergedBy);
        cell.style = jsonClone(style);
      });
      const position = {
        row: mergedBy[1],
        column: mergedBy[0],
      };
      if (!mergeCellPositions.some((item) => isEqualDate(item, position))) {
        mergeCellPositions.push(position);
      }
    }
  });
  // 调整mergedown
  mergeCellPositions.forEach((position) => {
    const cell = findCell(copy, position);
    if (cell && cell.mergeDown) {
      cell.mergeDown = cell.mergeDown + count;
    }
  });
  // 调整mergeby
  copy.cells.forEach((row) => {
    row.forEach((cell) => {
      if (cell.mergedBy && cell.mergedBy[1] >= rowIndex) {
        cell.mergedBy = [cell.mergedBy[0], cell.mergedBy[1] + count];
      }
    });
  });
}

/**
 * 单元格一边框颜色重置并隐藏
 */
function hideBorders(cell: ITableColumn | ITableCell | undefined, position: keyof CellBorder) {
  if (cell) {
    const border = jsonClone(Object.assign({}, DefalutTableStroke, { disabled: true }));
    cell.style.border[position] = border;
  }
}

/**
 * 设置边框颜色，临边颜色重置并隐藏
 */
export const setBorder = (
  tableValue: ITableValue,
  selectArea: ISelectArea,
  borderChange: {
    width: number | undefined;
    color: Color | undefined;
  },
  // eslint-disable-next-line no-unused-vars
  _mode?: string,
) => {
  const copy = jsonClone(tableValue);
  const cells = getAllCellsOfSelectArea(copy, selectArea);
  // 隐藏选中单元格的相邻边框
  cells.forEach((c) => {
    const { position } = c;
    const { left, right, bottom, top } = getAdjacentCells(copy, position);
    hideBorders(left, 'right');
    hideBorders(right, 'left');
    hideBorders(top, 'bottom');
    hideBorders(bottom, 'top');
  });
  // 修改选中部分的边框
  cells.forEach((c) => {
    const { left, right, bottom, top } = c.cell.style.border;
    c.cell.style.border = {
      left: { ...mergeBorderStyle(left, borderChange), disabled: false },
      right: { ...mergeBorderStyle(right, borderChange), disabled: false },
      top: { ...mergeBorderStyle(top, borderChange), disabled: false },
      bottom: { ...mergeBorderStyle(bottom, borderChange), disabled: false },
    };
  });
  return copy;
};

interface ITextOpt {
  text: string;
  fontFamily?: string;
  letterSpace?: number;
  fontSize?: number;
  fontStyle?: FontStyle;
  color?: PureColor;
}

export const makeCompInTable = (
  type: CellTypes,
  textOpt: ITextOpt,
  tableValue: ITableValue,
  position: ICellPosition,
) => {
  switch (type) {
    case CellTypes.CheckBox: {
      return makeCheckBoxInTable(textOpt, position);
    }
    case CellTypes.Radio: {
      return makeRadioInTable(textOpt, position);
    }
    case CellTypes.Text:
    default: {
      if (!textOpt.text) {
        return null;
      }
      return makeTextInTable(textOpt, tableValue, position);
    }
  }
};

const makeTextInTable = (textOpt: ITextOpt, tableValue: ITableValue, position: ICellPosition) => {
  const { text, fontFamily, letterSpace, fontStyle, fontSize, color } = textOpt;
  const textComp = makeText(getNewID(), `${text}`);
  textComp.row = position.row;
  textComp.column = position.column;
  const cell = findCell(tableValue, position);
  if (cell) {
    textComp.properties.textFormat!.textAlign = cell.style.textAlign;
    textComp.properties.textFormat!.verticalAlign = cell.style.verticalAlign;
    textComp.properties.textFormat!.wrap = true;
    mergeTextFormatInTable(textComp.properties.textFormat, { fontFamily, letterSpace, fontStyle, fontSize, color });
    const cssParser = StyleHelper.initCSSStyleParser(textComp.properties);
    const realArea = getAreaOfCell(tableValue, cell, position);
    const bounds = getBoundsOfSelectArea(tableValue, realArea);
    const { width, height } = measureTextSize(cssParser.getTextStyle(), `${text}`, {
      isMultiText: true,
      isRich: true,
      wrap: true,
      defaultWidth: bounds.width - 2 * DefaultCellPadding,
    });
    textComp.size.width = width;
    textComp.size.height = height;
    textComp.position = { x: 0, y: 0 };
    textComp.autoSize = true;
    textComp.textBehaviour = ETextBehaviour.Height;
  }

  return textComp;
};

const makeCheckBoxInTable = (textOpt: ITextOpt, position: ICellPosition) => {
  const checkBoxComp = makeComponent('common', 'checkbox', '');
  if (checkBoxComp) {
    checkBoxComp.name = i18n('resource.components.checkbox');
    const { text, fontFamily, letterSpace, fontStyle, fontSize, color } = textOpt;
    checkBoxComp.value = text;
    checkBoxComp.row = position.row;
    checkBoxComp.column = position.column;
    mergeTextFormatInTable(checkBoxComp.properties.textStyle, { fontFamily, letterSpace, fontStyle, fontSize, color });
    const cssParser = StyleHelper.initCSSStyleParser(checkBoxComp.properties);
    const size = measureTextSize(cssParser.getTextStyle(), text, {
      isMultiText: false,
      wrap: false,
      isRich: false,
    });
    checkBoxComp.size.width = 26 + size.width;
    const pureText = checkBoxComp.components?.filter((c) => c.type === CPureText);
    if (pureText?.length) {
      pureText[0].size = size;
    }
  }

  return checkBoxComp;
};

function mergeTextFormatInTable(
  format: ITextFormat | undefined,
  opt: { fontFamily?: string; letterSpace?: number; fontSize?: number; fontStyle?: FontStyle; color?: PureColor },
) {
  if (!format) {
    return;
  }
  const change: { [key: string]: any } = {};
  ['fontFamily', 'letterSpace', 'fontSize', 'fontStyle', 'color'].forEach((k) => {
    const key = k as 'fontFamily' | 'letterSpace' | 'fontSize' | 'fontStyle' | 'color';
    if (!isUndefined(opt[key])) {
      change[key] = opt[key];
    }
  });
  Object.assign(format, change);
}

const makeRadioInTable = (
  textOpt: {
    text: string;
    fontFamily?: string;
    letterSpace?: number;
    fontSize?: number;
    fontStyle?: FontStyle;
    color?: PureColor;
  },
  position: ICellPosition,
) => {
  const radioComp = makeComponent('common', 'radio', '');
  if (radioComp) {
    radioComp.name = i18n('resource.components.radio');
    const { text, fontFamily, letterSpace, fontStyle, fontSize, color } = textOpt;
    radioComp.value = textOpt;
    radioComp.row = position.row;
    radioComp.column = position.column;
    mergeTextFormatInTable(radioComp.properties.textStyle, { fontFamily, letterSpace, fontStyle, fontSize, color });
    const cssParser = StyleHelper.initCSSStyleParser(radioComp.properties);
    const size = measureTextSize(cssParser.getTextStyle(), text, {
      isMultiText: false,
      wrap: false,
      isRich: false,
    });
    radioComp.size.width = 26 + size.width;
    const pureText = radioComp.components?.filter((c) => c.type === CPureText);
    if (pureText?.length) {
      pureText[0].size = size;
    }
  }

  return radioComp;
};

/**
 * 获取整行单元格样式
 */
export const getStyleOfRow = (tableValue: ITableValue, rowIndex: number): Array<ICellStyle> | undefined => {
  const copy = jsonClone(tableValue);
  const { columns, rows, cells } = copy;
  if (rowIndex < -1 || rowIndex >= rows.length) {
    return undefined;
  }
  const curCells: Array<ITableCell | ITableColumn> | undefined = rowIndex === -1 ? columns : cells[rowIndex];
  let cellStyles: Array<ICellStyle> | undefined;
  if (curCells?.length) {
    cellStyles = curCells.map((c, i) => {
      const area = getAreaOfCell(copy, c, {
        row: rowIndex,
        column: i,
      });
      const first = findCell(copy, area.start);
      return (first || c).style;
    });
  }
  return cellStyles;
};

/**
 * 获取整列的单元格样式
 */
export const getStyleOfColumn = (tableValue: ITableValue, columnIndex: number): Array<ICellStyle> | undefined => {
  const copy = jsonClone(tableValue);
  const { columns, cells } = copy;
  if (columnIndex < 0 || columnIndex >= columns.length) {
    return undefined;
  }
  const curCells: Array<ITableCell | ITableColumn> = cells.map((r) => r[columnIndex]);
  curCells.unshift(columns[columnIndex]);
  let cellStyles: Array<ICellStyle> | undefined;
  if (curCells?.length) {
    cellStyles = curCells.map((c, i) => {
      const area = getAreaOfCell(copy, c, {
        row: i - 1,
        column: columnIndex,
      });
      const first = findCell(copy, area.start);
      return (first || c).style;
    });
  }
  return cellStyles;
};

/**
 * 获取该行行高
 */
export const getRowHeight = (tableValue: ITableValue, rowIndex: number) => {
  const { headerHeight, rows } = tableValue;
  if (rowIndex === -1) {
    return headerHeight;
  } else {
    return rows[rowIndex].height;
  }
};

/**
 * 获取该列列宽
 */
export const getColumnWidth = (tableValue: ITableValue, columnIndex: number) => {
  const { columns } = tableValue;
  return columns[columnIndex].width;
};

/**
 * 获取行列移动后的新索引
 */
export const getNewIndexAfterMoved = (
  tableValue: ITableValue,
  start: number,
  end: number,
  offset: number,
  isRow?: boolean,
): number => {
  let newIndex = start;
  const { rows, columns } = tableValue;
  const sizeArray = isRow
    ? offset < 0
      ? new Array(start).fill(0).map((_v, i) => rows[start - 1 - i].height)
      : new Array(rows.length - end - 1).fill(0).map((_v, i) => rows[end + i + 1].height)
    : offset < 0
    ? new Array(start).fill(0).map((_v, i) => columns[start - 1 - i].width)
    : new Array(columns.length - end - 1).fill(0).map((_v, i) => columns[end + i + 1].width);
  if (offset < 0) {
    let i = 0;
    for (; i < sizeArray.length; i++) {
      if (-offset < sizeArray[i]) {
        break;
      }
      offset += sizeArray[i];
    }
    newIndex -= i;
  } else if (offset > 0) {
    let i = 0;
    for (; i < sizeArray.length; i++) {
      if (offset < sizeArray[i]) {
        break;
      }
      offset -= sizeArray[i];
    }
    newIndex += i;
  }
  return max(newIndex, 0);
};

/**
 * 能否移动行
 */
export const canMoveRows = (
  tableValue: ITableValue,
  start: number,
  end: number,
  newIndex: number,
): { enable: boolean; text?: string } => {
  if (newIndex === start) {
    return {
      enable: false,
    };
  }
  if (start === -1) {
    return {
      enable: false,
      text: i18n('alert.headCannotBeMoved'),
    };
  }
  if (newIndex === -1) {
    return {
      enable: false,
      text: i18n('alert.cannotMoveToHeader'),
    };
  }
  const { rows, columns } = tableValue;
  if (!between(newIndex, { min: 0, max: rows.length - 1 }, true)) {
    return {
      enable: false,
    };
  }
  const selectArea: ISelectArea = {
    start: {
      row: start,
      column: 0,
    },
    end: {
      row: end,
      column: columns.length - 1,
    },
  };
  toRealAreaOfSelectArea(tableValue, selectArea);
  if (selectArea.start.row !== start || selectArea.end.row !== end) {
    return {
      enable: false,
      text: i18n('alert.cannotMoveRow1'),
    };
  }
  const moveUp = newIndex < start;
  const exchangeArea: ISelectArea = {
    start: {
      row: moveUp ? newIndex : end + 1,
      column: 0,
    },
    end: {
      row: moveUp ? start - 1 : end + newIndex - start,
      column: columns.length - 1,
    },
  };
  const realExchArea = jsonClone(exchangeArea);
  toRealAreaOfSelectArea(tableValue, realExchArea);
  if (!isEqual(realExchArea, exchangeArea)) {
    return {
      enable: false,
      text: i18n('alert.cannotMoveRow2'),
    };
  }
  return { enable: true };
};

/**
 * 能否移动列
 */
export const canMoveColumns = (
  tableValue: ITableValue,
  start: number,
  end: number,
  newIndex: number,
): { enable: boolean; text?: string } => {
  if (newIndex === start) {
    return {
      enable: false,
    };
  }
  const { rows, columns, showHeader } = tableValue;
  if (!between(newIndex, { min: 0, max: columns.length - 1 }, true)) {
    return {
      enable: false,
    };
  }
  const selectArea: ISelectArea = {
    start: {
      row: showHeader ? -1 : 0,
      column: start,
    },
    end: {
      row: rows.length - 1,
      column: end,
    },
  };
  toRealAreaOfSelectArea(tableValue, selectArea);
  if (selectArea.start.column !== start || selectArea.end.column !== end) {
    return {
      enable: false,
      text: i18n('alert.cannotMoveColumn1'),
    };
  }
  const moveUp = newIndex < start;
  const exchangeArea: ISelectArea = {
    start: {
      row: showHeader ? -1 : 0,
      column: moveUp ? newIndex : end + 1,
    },
    end: {
      row: rows.length - 1,
      column: moveUp ? start - 1 : end + newIndex - start,
    },
  };
  const realExchArea = jsonClone(exchangeArea);
  toRealAreaOfSelectArea(tableValue, realExchArea);
  if (!isEqual(realExchArea, exchangeArea)) {
    return {
      enable: false,
      text: i18n('alert.cannotMoveColumn2'),
    };
  }
  return { enable: true };
};

/**
 * 设置区域顶部单元格左、上描边显示
 */
export const updateAreaTopOrLeftBorderShow = (tableValue: ITableValue, area: ISelectArea, isTop: boolean) => {
  getAllCellsOfSelectArea(tableValue, area).forEach((item) => {
    const { cell, position } = item;
    if (isTop && position.row === area.start.row) {
      cell.style.border.top.disabled = false;
    } else if (position.column === area.start.column) {
      cell.style.border.left.disabled = false;
    }
  });
};

/**
 * 移动行(表头不参与)
 */
export const moveRows = (tableValue: ITableValue, start: number, end: number, newIndex: number): ITableValue => {
  const copy = jsonClone(tableValue);
  const moveOffset = newIndex - start;
  const { columns, rows, cells } = copy;
  // 选择的行
  const selectArea: ISelectArea = {
    start: {
      row: start,
      column: 0,
    },
    end: {
      row: end,
      column: columns.length - 1,
    },
  };
  const moveUp = newIndex < start;
  // 交换的行
  const exchangeArea: ISelectArea = {
    start: {
      row: moveUp ? newIndex : end + 1,
      column: 0,
    },
    end: {
      row: moveUp ? start - 1 : end + newIndex - start,
      column: columns.length - 1,
    },
  };
  updateAreaTopOrLeftBorderShow(copy, selectArea, true);
  updateAreaTopOrLeftBorderShow(copy, exchangeArea, true);
  // 修改合并单元格
  cells.forEach((r, rIndex) => {
    const inRow =
      between(rIndex, { min: start, max: end }, true) ||
      between(rIndex, { min: exchangeArea.start.row, max: exchangeArea.end.row }, true);
    if (!inRow) {
      return;
    }
    r.forEach((c, cIndex) => {
      const isMergedBy = !!c.mergedBy;
      const isSelected = isCellSelected(rIndex, cIndex, selectArea);
      const isExchanged = isCellSelected(rIndex, cIndex, exchangeArea);
      if (isMergedBy) {
        if (isSelected) {
          c.mergedBy = [c.mergedBy![0], c.mergedBy![1] + moveOffset];
        } else if (isExchanged) {
          c.mergedBy = [c.mergedBy![0], c.mergedBy![1] - Math.sign(moveOffset) * (end - start + 1)];
        }
      }
    });
  });
  copy.rows = moveArrayItems(rows, start, newIndex, end + 1 - start);
  copy.cells = moveArrayItems(cells, start, newIndex, end + 1 - start);
  return copy;
};

/**
 * 移动列
 */
export const moveColumns = (tableValue: ITableValue, start: number, end: number, newIndex: number): ITableValue => {
  const copy = jsonClone(tableValue);
  const moveOffset = newIndex - start;
  const { rows, cells, showHeader } = copy;
  // 选择的行
  const selectArea: ISelectArea = {
    start: {
      row: showHeader ? -1 : 0,
      column: start,
    },
    end: {
      row: rows.length - 1,
      column: end,
    },
  };
  const moveUp = newIndex < start;
  // 交换的行
  const exchangeArea: ISelectArea = {
    start: {
      row: showHeader ? -1 : 0,
      column: moveUp ? newIndex : end + 1,
    },
    end: {
      row: rows.length - 1,
      column: moveUp ? start - 1 : end + newIndex - start,
    },
  };
  updateAreaTopOrLeftBorderShow(copy, selectArea, false);
  updateAreaTopOrLeftBorderShow(copy, exchangeArea, false);
  // 修改合并单元格
  cells.forEach((r, rIndex) => {
    r.forEach((c, cIndex) => {
      const isMergedBy = !!c.mergedBy;
      const isSelected = isCellSelected(rIndex, cIndex, selectArea);
      const isExchanged = isCellSelected(rIndex, cIndex, exchangeArea);
      if (isMergedBy) {
        if (isSelected) {
          c.mergedBy = [c.mergedBy![0] + moveOffset, c.mergedBy![1]];
        } else if (isExchanged) {
          c.mergedBy = [c.mergedBy![0] - Math.sign(moveOffset) * (end - start + 1), c.mergedBy![1]];
        }
      }
    });
  });
  copy.columns = moveArrayItems(jsonClone(copy.columns), start, newIndex, end + 1 - start);
  copy.cells = copy.cells.map((r) => moveArrayItems(jsonClone(r), start, newIndex, end + 1 - start));
  return copy;
};

/**
 * 同步新增列的样式
 */
function updateAddColumnsStyle(
  copy: ITableValue,
  columnIndex: number,
  addColumnCells: ITableCell[][],
  addColumns: ITableColumn[],
) {
  const curColumnWidth = getColumnWidth(copy, columnIndex);
  const columnStyles = getStyleOfColumn(copy, columnIndex);
  if (columnStyles) {
    addColumnCells.forEach((r, i) => {
      r.forEach((c) => {
        c.style = Object.assign({}, c.style, columnStyles[i + 1]);
      });
    });
    addColumns.forEach((c) => {
      c.style = Object.assign({}, c.style, columnStyles[0]);
    });
  }
  addColumns.forEach((c) => {
    c.width = curColumnWidth || c.width;
  });
}

/**
 * 同步新增行的样式
 */
function updateAddRowsStyle(copy: ITableValue, rowIndex: number, addRowCells: ITableCell[][], addRows: ITableRow[]) {
  const curRowHeight = getRowHeight(copy, rowIndex);
  const rowStyles = getStyleOfRow(copy, rowIndex);
  if (rowStyles) {
    addRowCells.forEach((r) => {
      r.forEach((c, i) => {
        c.style = Object.assign({}, c.style, rowStyles[i]);
      });
    });
  }
  addRows.forEach((r) => {
    r.height = curRowHeight || r.height;
  });
}

/**
 * 派发表格单元格选中事件
 */
export function dispatchTableCellSelectingEvent(id: string, pageX: number, pageY: number, canDrag: boolean) {
  const position = { x: pageX, y: pageY };
  const event = new CustomEvent('tableCellSelecting', { detail: { position, id, canDrag } });
  window.dispatchEvent(event);
}

/**
 * 派发表格单元格选中事件
 */
export function dispatchTableCellSelectedEvent() {
  const event = new CustomEvent('tableCellSelected');
  window.dispatchEvent(event);
}

export namespace TableClipboardHelper {
  const getSelectAreaSize = (selArea: ISelectArea) => {
    return {
      row: selArea.end.row - selArea.start.row + 1,
      column: selArea.end.column - selArea.start.column + 1,
    };
  };

  // 粘贴的html
  export const buildHtmlByTableClipboardData = (clipboardData: ITableClipboardData): string => {
    const { cells: tableCells, components, colWidths, rowHeights } = clipboardData;
    const html = ['<table>'];

    try {
      if (colWidths && colWidths.length) {
        html.push('<colgroup>');
        colWidths.forEach((colWidth) => {
          html.push(`<col width="${colWidth}"/>`);
        });
        html.push('</colgroup>');
      }
      for (let r = 0, rLen = tableCells.length; r < rLen; r++) {
        const cells = tableCells[r];
        let rowStyle = '';
        if (rowHeights && rowHeights[r]) {
          rowStyle = `height:${rowHeights[r]}px`;
        }
        html.push(`<tr style="${rowStyle}">`);
        for (let c = 0, cLen = cells.length; c < cLen; c++) {
          const cell = cells[c];
          if (!cell.mergedBy) {
            const comp = components?.find((d) => d.row === r && d.column === c);
            const text = cell.data.text || comp ? comp?.value : cell.data.text;

            let tdCssStyle = [];
            if (cell.style.fill) {
              tdCssStyle.push(`background:${parseColorToString(cell.style.fill)}`);
            }
            if (cell.style.textAlign) {
              tdCssStyle.push(`text-align:${cell.style.textAlign}`);
            }
            if (cell.style.verticalAlign) {
              tdCssStyle.push(`vertical-align:${cell.style.verticalAlign}`);
            }
            if (comp) {
              const parser = StyleHelper.initCSSStyleParser(comp.properties);
              tdCssStyle = Array.from(
                Object.entries({
                  ...parser.getTextStyle(),
                  ...parser.getMultiStyle(),
                }),
              ).reduce((styles, [name, value]) => {
                if (value !== undefined) {
                  styles.push(`${kebabCase(name)}:${value as string}`);
                }
                return styles;
              }, tdCssStyle as string[]);
            }

            html.push(
              `<td style="${tdCssStyle.join(';')}" rowSpan="${cell.mergeDown + 1}" colSpan="${cell.mergeAcross + 1}">`,
            );
            html.push(text);
            html.push('</td>');
          }
        }
        html.push('</tr>');
      }
    } catch (e) {
      console.log('copy table at error', e);
    }
    html.push('</table>');
    return html.join('');
  };

  export const getPasteAreas = (
    tableValue: ITableValue,
    selArea: ISelectArea,
    copyAreaSize: { row: number; column: number },
  ) => {
    const selAreaSize = getSelectAreaSize(selArea);
    // 横向纵向可以粘贴的复制内容的个数
    let rowCount = Math.floor(selAreaSize.row / copyAreaSize.row);
    let columnCount = Math.floor(selAreaSize.column / copyAreaSize.column);
    // 表格需要扩展的行列数
    let addRows = 0;
    let addColumns = 0;
    const firstCell = selArea.start;
    if (!rowCount || !columnCount) {
      // 当选区小于复制
      rowCount = 1;
      columnCount = 1;
      const totalRows = firstCell.row + copyAreaSize.row;
      const totalColumns = firstCell.column + copyAreaSize.column;
      addRows = max(0, totalRows - tableValue.rows.length);
      addColumns = max(0, totalColumns - tableValue.columns.length);
      // 超过最大行，最大列取消粘贴操作
      if (totalRows > MaxRowsCount || totalColumns > MaxColumnsCount) {
        return undefined;
      }
    }

    const pasteAreas: ISelectArea[] = [];
    const pasteArea = (rowIndex: number, colIndex: number) => {
      const area = {
        start: {
          row: firstCell.row + copyAreaSize.row * rowIndex,
          column: firstCell.column + copyAreaSize.column * colIndex,
        },
        end: {
          row: firstCell.row + copyAreaSize.row * (rowIndex + 1) - 1,
          column: firstCell.column + copyAreaSize.column * (colIndex + 1) - 1,
        },
      };
      return area;
    };
    for (let i = 0; i < rowCount; i++) {
      for (let j = 0; j < columnCount; j++) {
        pasteAreas.push(pasteArea(i, j));
      }
    }

    return {
      addRows,
      addColumns,
      pasteAreas,
    };
  };

  /**
   * 设置粘贴区域的行高列宽，非通用方法
   */
  export const setPasteAreaRowAndColumnSize = (
    tableValue: ITableValue,
    area: ISelectArea,
    rowHeights?: (number | undefined)[],
    colWidths?: (number | undefined)[],
  ) => {
    const { rows, columns } = tableValue;
    const { start, end } = area;
    if (rowHeights?.length) {
      for (let i = 0; i < rowHeights.length && i < end.row - start.row + 1; i++) {
        const height = rowHeights[i];
        if (isNumber(height)) {
          const rowIndex = i + start.row;
          const isHeader = rowIndex === -1;
          if (isHeader) {
            tableValue.headerHeight = height;
          } else if (rows[rowIndex]) {
            rows[rowIndex].height = height;
          }
        }
      }
    }
    if (colWidths?.length) {
      for (let i = 0; i < colWidths.length && i < end.column - start.column + 1; i++) {
        const width = colWidths[i];
        if (isNumber(width)) {
          const colIndex = i + start.column;
          if (columns[colIndex]) {
            columns[colIndex].width = width;
          }
        }
      }
    }
  };

  /**
   * 将表格编辑器选择的区域构建成可粘贴的表格数据
   */
  export const buildTableClipboardData = (
    tableValue: ITableValue,
    selectArea: ISelectArea,
    comps: UIComponent[],
    cellComps: UIComponent[],
  ) => {
    const copy = jsonClone(tableValue);
    const { start, end } = selectArea;
    const rowCount = end.row - start.row + 1;
    const columnCount = end.column - start.column + 1;
    // 复制的单元格
    const cells: ITableCell[][] = buildClipCellsData(copy, rowCount, columnCount, selectArea);

    const { rows, columns, headerHeight } = copy;
    // 行高
    const rowHeights = new Array(rowCount).fill(undefined).map((_item, i) => {
      const rowIndex = start.row + i;
      if (rowIndex === -1) {
        return headerHeight;
      } else {
        return rows[rowIndex]?.height || undefined;
      }
    });
    // 列宽
    const colWidths = new Array(columnCount).fill(undefined).map((_item, i) => {
      const colIndex = start.column + i;
      return columns[colIndex]?.width || undefined;
    });
    // 子组件
    const components = comps
      .filter((comp) => {
        const { row, column } = comp;
        return isCellSelected(row, column, selectArea);
      })
      .map((item) => {
        const itemCopy = jsonClone(item.toJSON());
        itemCopy.column = (itemCopy.column || 0) - start.column;
        itemCopy.row = (itemCopy.row || 0) - start.row;
        return itemCopy;
      });
    // 单元格组件
    const cellComponents = cellComps.map((item) => {
      const itemCopy = jsonClone(item.toJSON());
      itemCopy.column = (itemCopy.column || 0) - start.column;
      itemCopy.row = (itemCopy.row || 0) - start.row;
      return itemCopy;
    });

    return {
      cells,
      rowHeights,
      colWidths,
      components,
      cellComponents,
    };
  };

  /**
   * 构造复制的单元格数据
   */
  const buildClipCellsData = (
    tableValue: ITableValue,
    rowCount: number,
    columnCount: number,
    selectArea: ISelectArea,
  ) => {
    const { start, end } = selectArea;
    const cells: ITableCell[][] = jsonClone(new Array(rowCount).fill(new Array(columnCount).fill(DefaultCell)));

    getAllCellsOfSelectArea(tableValue, selectArea).forEach((item) => {
      const { cell, position } = item;
      const { mergeAcross, mergeDown, mergedBy } = cell;
      // 处理mergeby
      if (mergedBy) {
        const mergePosition = {
          row: mergedBy[1],
          column: mergedBy[0],
        };
        // 若合并单元格在选取外，将解除单元格合并
        // 重置合并单元格起点
        const isMergeCellSelected = isCellSelected(mergePosition.row, mergePosition.column, selectArea);
        if (!isMergeCellSelected) {
          cell.mergedBy = undefined;
        } else {
          mergedBy[0] -= start.column;
          mergedBy[1] -= start.row;
        }
      }
      // 处理mergeAcross
      if (mergeAcross && mergeAcross + position.column > end.column) {
        cell.mergeAcross = end.column - position.column;
      }
      // 处理mergeDown
      if (mergeDown && mergeDown + position.row > end.row) {
        cell.mergeDown = end.row - position.row;
      }
      const isCurCellSelected = isCellSelected(position.row, position.column, selectArea);
      if (isCurCellSelected) {
        cells[position.row - start.row][position.column - start.column] = cell;
      }
    });
    return cells;
  };
}
