import * as React from 'react';
import * as ReactDom from 'react-dom';

import classnames from 'classnames';

import { titleBarHeight } from '@/utils/envUtils';
import { dragDelegate } from '@utils/mouseUtils';
import { PopupManager } from '../withAutoClose';
import { ComponentTheme } from '../common';

import './index.scss';

export interface IFloatPanelProp {
  children: any;
  title?: string;
  float?: boolean;
  theme?: ComponentTheme;
  customRef?: React.RefObject<HTMLDivElement>;
  titleRender?: (title: string) => React.ReactNode;
  allowDrag?: boolean;
  point?: { left: number; top: number };
  className?: string;
  style?: React.CSSProperties;
  showClose?: boolean;
  onClose?: () => void;
  onMoved?: (point: { left: number; top: number }) => void;
  onMouseDown?: React.MouseEventHandler;
  onMouseDownCapture?: React.MouseEventHandler;
  onMouseUp?: React.MouseEventHandler;
  onClick?: React.MouseEventHandler;
  onDoubleClick?: React.MouseEventHandler;
  onContextMenu?: React.MouseEventHandler;
}

export interface IFloatPanelState {
  point?: { left: number; top: number };
}

class FloatPanel extends React.Component<IFloatPanelProp, IFloatPanelState> {
  static defaultProps: Partial<IFloatPanelProp> = {
    float: true,
  };

  selfRef: React.RefObject<HTMLDivElement>;

  constructor(props: IFloatPanelProp) {
    super(props);
    this.state = {
      point: props.point,
    };

    this.selfRef = props.customRef || React.createRef();
  }

  componentDidMount() {
    this.props.allowDrag && window.addEventListener('resize', this.handleWindowResize);
    const { point } = this.state;
    point && this.reformPoint(point);
    this.doCalculatePosition();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  reformPoint = (point: { left: number; top: number }) => {
    const dom = this.selfRef.current!;
    const height = dom.clientHeight;
    const windowHeight = window.innerHeight;
    const safetyMargin = 10;
    if (point && point.top + height + safetyMargin > windowHeight) {
      const top = Math.round(windowHeight - height - safetyMargin);
      const left = Math.round(point.left);
      if (!this.state.point || top !== this.state.point.top || left !== this.state.point.left) {
        this.setState({ point: { left, top } });
      }
    } else {
      this.state.point !== point && this.setState({ point });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps: IFloatPanelProp) {
    nextProps.point && nextProps.point !== this.props.point && this.reformPoint(nextProps.point);
  }

  get bounds(): { left: number; top: number; width: number; height: number; right: number; bottom: number } {
    const dom = this.selfRef.current!;
    const { clientWidth: width, clientHeight: height } = dom;
    const { left, top } = this.state.point || { left: 0, top: 0 };
    return { left, top, right: left + width, bottom: top + height, width, height };
  }

  /**
   *  窗口大小改变，组件位置始终可见
   */
  handleWindowResize = () => {
    this.doCalculatePosition();
  };

  doCalculatePosition() {
    const { point } = this.state;
    const panelWidth = this.selfRef.current?.clientWidth;
    if (!point || !panelWidth) {
      return;
    }

    const { onMoved } = this.props;
    const { innerWidth } = window;
    const newPoint = { ...point };
    if (point.left + panelWidth > innerWidth) {
      newPoint.left = Math.round(innerWidth - panelWidth);
    }
    newPoint.top = Math.round(newPoint.top);

    if (newPoint.left !== point.left) {
      this.setState({ point: newPoint });
      onMoved?.(newPoint);
    }
  }

  /**
   *  获取鼠标拖拽信息，界定在window范围
   */
  handleMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    PopupManager.manager.closeAll();
    const { allowDrag, onMoved } = this.props;
    if (!allowDrag) {
      return;
    }
    // const { point } = this.state;
    const { top, left } = this.selfRef.current!.getBoundingClientRect();
    const minTop = titleBarHeight;
    const startPoint = { left, top };
    dragDelegate(
      (e: MouseEvent, delta: { x: number; y: number }) => {
        const newPoint = {
          left: startPoint.left + delta.x,
          top: startPoint.top + delta.y,
        };
        if (newPoint.left < 0) {
          newPoint.left = 0;
        } else if (newPoint.left > window.innerWidth - this.selfRef.current!.clientWidth) {
          newPoint.left = window.innerWidth - this.selfRef.current!.clientWidth;
        }
        if (newPoint.top < minTop) {
          newPoint.top = minTop;
        } else if (newPoint.top > window.innerHeight - this.selfRef.current!.clientHeight) {
          newPoint.top = window.innerHeight - this.selfRef.current!.clientHeight;
        }
        newPoint.left = Math.round(newPoint.left);
        newPoint.top = Math.round(newPoint.top);

        if (this.props.allowDrag) {
          this.setState({
            point: newPoint,
          });
        }
      },
      () => {
        onMoved && onMoved(this.state.point!);
      },
    );
  };

  contains = (node: Node) => {
    if (this.selfRef.current) {
      return this.selfRef.current.contains(node);
    }
    return false;
  };

  renderContent = () => {
    const {
      children,
      title,
      titleRender,
      className,
      style,
      theme,
      showClose,
      onMouseDownCapture,
      onMouseDown,
      onMouseUp,
      onClick,
      onDoubleClick,
      onContextMenu,
      onClose,
    } = this.props;
    const { point } = this.state;
    let styles: React.CSSProperties = {};
    if (point) {
      styles.left = point.left;
      styles.top = point.top;
    }
    if (style) {
      styles = { ...styles, ...style };
    }
    return (
      <div
        className={classnames('dsm-c-rp-float-panel', className ?? '', theme ?? '')}
        style={styles}
        ref={this.selfRef}
        tabIndex={1}
        onMouseDown={onMouseDown}
        onMouseDownCapture={onMouseDownCapture}
        onMouseUp={onMouseUp}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        onContextMenu={onContextMenu}
      >
        {title && (
          <div className="title" onMouseDown={this.handleMouseDown}>
            {!titleRender && <label>{title}</label>}
            {titleRender && titleRender(title)}
            {showClose && <span className="close" onClick={onClose} />}
          </div>
        )}
        <div className="content">{children}</div>
      </div>
    );
  };

  render() {
    const { float } = this.props;
    if (float) {
      return ReactDom.createPortal(this.renderContent(), document.body);
    } else {
      return this.renderContent();
    }
  }
}

export default FloatPanel;
