import {
  IBounds,
  IMakeSvgByElementOptions,
  IMakeSvgByHtmlOptions,
  IMakeSvgContentOptions,
  IMakeSvgTemplateOptions,
  ISize,
  IStyle,
  ITextStyle,
} from '../type';
import { makeElementByString } from '../util';
import { makeGradient } from './makeGradient';
import { makePattern } from './makePattern';
import { makeRect, makeTextInRect } from './makeRect';
import { makePath } from './makePath';
import { makeForeignObject } from './makeForeignObject';
import { makeTransform, makeforeignObjectTransform } from './makeTransform';
import { getMaxSize } from '../util/imageUtil';

export const makeSvgContentTemplate = async (options: IMakeSvgTemplateOptions): Promise<string> => {
  const {
    id,
    type,
    text,
    textStyle,
    pathData,
    patternUri,
    size,
    style,
    svgValue,
    html,
    isRoot = true,
    position,
    rotate,
    outerBounds,
    innerBounds,
    offset,
    matrix,
  } = options;
  const { width = 100, height = 100 } = size ?? {};
  const { shadow, colorFilter, borderRadius, borderWidth, borderColor = '', background } = style ?? {};
  const rx = borderRadius ? ` rx="${borderRadius}"` : '';
  const imageFilter = colorFilter ? `style="filter:${colorFilter}"` : '';
  // const shadowFilter = shadow ? ` style="filter:${shadow}"` : '';
  const strokeWidth = borderWidth ?? 0;
  const stroke = borderColor ? `stroke="${borderColor}"` : '';
  const styles = [];
  if (shadow) {
    styles.push(`filter:${shadow}`);
  }
  const patternId = 'pattern_' + id;
  const gradientId = 'gradient_' + id;
  const { fill } = svgValue ?? {};

  const gradient = makeGradient(fill, gradientId, { width, height });
  const pattern = makePattern({
    uri: patternUri,
    id: patternId,
    size: { width, height },
    filter: imageFilter,
  });

  const fillId = pattern ? patternId : gradient ? gradientId : '';

  const rectStyleString = styles.length ? ` style="${styles.join(';')}"` : '';

  const path = makePath(pathData, {
    stroke,
    strokeWidth,
    style: rectStyleString,
    fill: fillId ? `fill="url(#${fillId})"` : background ? `fill="${background}"` : 'fill="transparent"',
    isRoot,
    position,
    rotate,
    size,
    outerBounds,
    offset,
    matrix,
  });

  // 有路径时不用画框
  const rect = pathData
    ? ''
    : await makeRect(innerBounds, {
        type,
        rx,
        style: rectStyleString,
        stroke,
        strokeWidth,
        fillId,
        isRoot,
        position,
        rotate,
        size,
        outerBounds,
        matrix,
      });
  const transform = makeTransform({ position, rotate, size, isRoot, outerBounds });
  const textNode = text ? await makeTextInRect(text, textStyle, innerBounds, transform) : '';

  const froeignObject = makeForeignObject(html);
  const defs = gradient || pattern ? `<defs>${gradient}  ${pattern}</defs>` : '';
  const content = `${defs}
  ${path}
  ${rect}
  ${textNode}
  ${froeignObject}`;
  return content;
};

/**
 * clippath模板
 * @param uri
 * @param size
 * @param style
 * @returns
 */
export const makSvgByBase64 = (uri: string, size: ISize, style?: IStyle): string => {
  const { width = 100, height = 100 } = size;
  const { filter, borderRadius } = style ?? {};
  const styleArr = [];
  if (filter) {
    styleArr.push(`filter:${filter}`);
  }
  let rx = '';
  if (borderRadius) {
    rx = borderRadius ? ` rx="${borderRadius}"` : '';
  }

  const styleString = styleArr.length ? ` style="${styleArr.join(';')}"` : '';

  const svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
    width="${width}"
    height="${height}"
    >
    <symbol>
    <clipPath id="clipPath">
    <rect x="0" y="0" width="${width}" height="${height}"${rx}/>
    </clipPath>
    </symbol>
  
    <g clip-path="url(#clipPath)">
    <image preserveAspectRatio="none" vector-effect="non-scaling-stroke"  width="${width}" height="${height}" ${styleString} xlink:href="${uri}" />
    </g>
    </svg>`;
  return svg;
};

/**
 * pattern模板
 * @param uri
 * @param size
 * @param style
 * @returns
 */
export const makeSvgPatternTemplateByBase64 = async (
  uri: string,
  size: ISize,
  style?: IStyle,
  text?: string,
  textStyle?: ITextStyle,
  innerBounds?: IBounds,
  outerBounds?: IBounds,
): Promise<string> => {
  const { width = 100, height = 100 } = size;
  const { shadow, colorFilter, borderRadius, borderWidth, borderColor = '', fill } = style ?? {};
  const rx = borderRadius ? ` rx="${borderRadius}"` : '';
  const imageFilter = colorFilter ? `style="filter:${colorFilter}"` : '';
  // const shadowFilter = shadow ? ` style="filter:${shadow}"` : '';
  const strokeWidth = borderWidth ?? 0;
  const stroke = borderColor ? `stroke="${borderColor}"` : '';
  const styles = [];
  if (shadow) {
    styles.push(`filter:${shadow}`);
  }

  const patternId = 'patternId';
  const gradientId = 'gradient1';

  const gradient = makeGradient(fill, gradientId, { width, height });
  const pattern = makePattern({
    uri,
    id: patternId,
    size: { width, height },
    filter: imageFilter,
  });

  const fillId = pattern ? patternId : gradient ? gradientId : '';

  const rectStyleString = styles.length ? ` style="${styles.join(';')}"` : '';
  const rect = await makeRect(innerBounds, {
    rx,
    style: rectStyleString,
    stroke,
    strokeWidth,
    fillId,
    text,
    textStyle,
  });

  const content = `<defs>
  ${gradient}
  ${pattern}
  </defs>
  ${rect}
  `;

  return wrapSvgContent({
    content,
    size: outerBounds as ISize,
  });
};

export const makeSvgContentByElement = (options: IMakeSvgByElementOptions): Promise<string> => {
  const { node, size, isRoot = true, position, rotate, outerBounds, offset, webfont, style } = options;
  const { width = 100, height = 100 } = (outerBounds || size) ?? {};

  // 这里不要旋转信息，旋转在组件内部计算，偏移量要加回来
  const transform = !isRoot ? makeforeignObjectTransform({ position, rotate, size, isRoot, outerBounds, offset }) : '';
  const processer = Promise.resolve(node)
    .then((node) => {
      node.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
      node.setAttribute('style', 'width: 100%;height: 100%;overflow: hidden;position: relative;');
      const xhtml = new XMLSerializer().serializeToString(node);
      node.remove();
      return xhtml;
    })
    .then((xhtml) => {
      return `
      <defs>${style}</defs>
      
      <foreignObject x="0" y="0" width="${isRoot ? '100%' : width}" height="${
        isRoot ? '100%' : height
      }" ${transform}>${xhtml}</foreignObject>
      
      <defs>${webfont}</defs>
      `;
    });

  return processer;
};
// export const makeSvgContentByHtml = (options: IMakeSvgByHtmlOptions): Promise<string> => {
//   const { html, size, isRoot, position, rotate, offset, outerBounds } = options;
//   const { width = 100, height = 100 } = (outerBounds || size) ?? {};

//   // 这里不要旋转信息，旋转在组件内部计算，偏移量要加回来
//   const transform = !isRoot ? makeforeignObjectTransform({ position, rotate, size, isRoot, outerBounds, offset }) : '';
//   // const processer = Promise.resolve(node)
//   //
//   // ${escapeXhtml(html)}
//   const body = `<foreignObject x="0" y="0" width="${isRoot ? '100%' : width}" height="${
//     isRoot ? '100%' : height}" ${transform}>
//   <body xmlns="http://www.w3.org/1999/xhtml" >${html}</body>
//   </foreignObject>`;
//   return Promise.resolve(body)
// }

export const makeSvgContentByHtml = async (options: IMakeSvgByHtmlOptions): Promise<string> => {
  const { html, size, isRoot, position, rotate, offset, outerBounds } = options;
  const { content, webfont, style } = html;
  const element = makeElementByString(content);
  return await makeSvgContentByElement({
    node: element,
    webfont,
    style,
    size,
    isRoot,
    position,
    rotate,
    offset,
    outerBounds,
  });
};

/**
 * 旋转偏移量都在plain组件时计算好
 * @param options
 * @returns
 */
export const wrapSvgContent = (options: IMakeSvgContentOptions) => {
  const { content, size, scale = 1 } = options;

  // 兼容性问题处理
  if (scale === 0.5) {
    const { width: viewWidth, height: viewHeight } = getSvgSize(size, 1);
    const viewBox = `0 0 ${viewWidth} ${viewHeight}`;
    const style = `user-select:none;transform: scale(${scale}); transform-origin: left top;`;
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${viewWidth}" height="${viewHeight}" viewBox="${viewBox}" style="${style}"  shape-rendering="geometricPrecision">${content}</svg>`;
    return svg;
  }

  const { width: viewWidth, height: viewHeight } = getSvgSize(size, scale);
  let { width = 100, height = 100 } = size ?? {};
  width = Math.ceil(width);
  height = Math.ceil(height);
  const viewBox = `0 0 ${width} ${height}`;
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${viewWidth}" height="${viewHeight}" viewBox="${viewBox}" style="user-select:none"  shape-rendering="geometricPrecision">${content}</svg>`;
  return svg;
};

export const getSvgSize = (size: ISize, scale: number) => {
  let { width = 100, height = 100 } = size ?? {};
  width = Math.ceil(width);
  height = Math.ceil(height);
  return getMaxSize(width * scale, height * scale);
};
