import * as React from 'react';
import * as moment from 'moment';

import { IRPFreeProgramInvitationRecord } from '../../../../models/rpFreeProgram';
import { rpFreeProgressInviteCount } from '../../../../constants/rpFreeProgram';
import i18n from '../../../../i18n';

import './index.scss';

const EmptyPicture = 'https://img02.mockplus.cn/image/2021-02-26/44c39324-dbf4-20ed-0d30-c5f532bd2792.png';

interface IRecordProps {
  allInvitation: IRPFreeProgramInvitationRecord[];
}

const Record: React.FC<IRecordProps> = (props: IRecordProps) => {
  const { allInvitation } = props;

  const renderContent = () => {
    return (
      <div className="record-table-wrap">
        <table className="record-table">
          <colgroup>
            <col width={40} />
            <col width={120} />
            <col width={120} />
            <col width={120} />
            <col width={127} />
          </colgroup>
          <thead>
            <tr>{renderTableHeader()}</tr>
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </table>
      </div>
    );
  };

  const renderTableHeader = () => {
    return (
      <React.Fragment>
        <td>{i18n('rpActivity.record.num')}</td>
        <td>{i18n('rpActivity.record.time')}</td>
        <td>{i18n('rpActivity.record.sender')}</td>
        <td>{i18n('rpActivity.record.receiver')}</td>
        <td>{i18n('rpActivity.record.reward')}</td>
      </React.Fragment>
    );
  };

  const renderTableBody = () => {
    if (!allInvitation.length) {
      return null;
    }
    return allInvitation.map((invitation: IRPFreeProgramInvitationRecord, index: number) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{moment(invitation.createdAt).format('YYYY-MM-DD')}</td>
          <td>{invitation.invitationUsername}</td>
          <td>{invitation.username}</td>
          <td className="reward">
            {index + 1 >= rpFreeProgressInviteCount
              ? i18n('rpActivity.record.unlimitedPackage')
              : i18n('rpActivity.record.threeMonthReward')}
          </td>
        </tr>
      );
    });
  };

  const renderEmpty = () => {
    if (allInvitation.length > 0) {
      return null;
    }
    return (
      <div className="empty">
        <img src={EmptyPicture} alt="" />
        <p className="empty-tip">{i18n('rpActivity.record.emptyTip')}</p>
      </div>
    );
  };

  return (
    <div className="rp-free-record">
      {renderContent()}
      {renderEmpty()}
    </div>
  );
};

export default Record;
