import * as React from 'react';

import ValueEditorType from '@/consts/enums/valueEditorType';
import { MaxImageSize } from '@/consts/file';
import i18n, { languageManager } from '@/i18n';
import {
  selectMultipleImage,
  BatchUploadFilesItem,
  GroupFileItem,
  FileType,
  batchUploadFiles,
  getLocalImageFileSize,
  getFileType,
} from '@/helpers/fileUploadHelper';

import { ProgressBar, Button, Dialog } from '@dsm';

import './index.scss';

const IMAGE_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'svg'];
const SIZE = { width: 10000, height: 10000 };
const MIN_UPLOAD_TIME = 1000; // 上传弹窗最小存在时长

interface IUploadState {
  uploading: boolean;
  uploadContent: string;
  progress: number;
  error?: string;
}

interface IUploadProps {
  maxUploadLen: number; // 最大上传个数
}

const InitialState = {
  uploading: false,
  progress: 0,
  uploadContent: '',
};

class Upload extends React.Component<IUploadProps, IUploadState> {
  private isCancelUpload = false;
  constructor(props: IUploadProps) {
    super(props);
    this.state = { ...InitialState };
  }

  getErrorString = (typeErrs: number, sizeErrs: number, widthOrHeightErrs: number) => {
    const total = typeErrs + sizeErrs + widthOrHeightErrs;
    let format = 'jpg、png、svg、gif';
    if (languageManager.isEnLanguage) {
      format = format.replace(/、/g, ', ');
    }
    if (total === 0) {
      return undefined;
    }
    if (typeErrs > 0 && sizeErrs === 0 && widthOrHeightErrs === 0) {
      return i18n('alert.moreFileUploadFailFormat', typeErrs, format);
    }
    if (sizeErrs > 0 && typeErrs === 0 && widthOrHeightErrs === 0) {
      return i18n('alert.moreFileUploadFaiFile', sizeErrs);
    }
    if (widthOrHeightErrs > 0 && typeErrs === 0 && sizeErrs === 0) {
      return i18n('alert.moreFileUploadFailSize', widthOrHeightErrs);
    }
    return i18n('alert.moreFileUploadFailAll', typeErrs + sizeErrs + widthOrHeightErrs, format);
  };

  public doUploadImage = (isMultiple: boolean, cb: (res: BatchUploadFilesItem[]) => void) => {
    this.isCancelUpload = false;
    this.setState({ ...InitialState });
    selectMultipleImage(IMAGE_TYPES, isMultiple).then(async (fileList) => {
      const validFiles: GroupFileItem[] = [];
      const sliceIndex = isMultiple ? this.props.maxUploadLen : 1;
      const loopFiles = Array.from(fileList).slice(0, sliceIndex);
      let typeErrs = 0; // 类型错误数
      let sizeErrs = 0; // size错误数
      let widthOrHeightErrs = 0; // 尺寸错误
      let time = 0;
      for (let i = 0; i < loopFiles.length; i++) {
        const fileData = loopFiles[i];
        const { size } = await getLocalImageFileSize(fileData);
        const index = fileData.name.lastIndexOf('.');
        const extension = fileData.name.substring(index + 1).toLowerCase();
        if (size.height > SIZE.height || size.width > SIZE.width) {
          widthOrHeightErrs++;
        } else if (!IMAGE_EXTENSIONS.includes(extension)) {
          typeErrs++;
        } else if (fileData.size > MaxImageSize) {
          sizeErrs++;
        } else {
          const fileType = getFileType(fileData);
          let valueEditType = ValueEditorType.Image;
          if (fileType === FileType.SVG) {
            valueEditType = ValueEditorType.Svg;
          }
          validFiles.push({ file: fileData, type: fileType, valueEditType });
        }
      }
      const opts = {
        onProgress: (percent: number, total: number, completeCount: number) => {
          // 上传至100暂不直接跑完滚动条,在完成方法中跑完
          this.setState({
            progress: Math.min(percent, 90),
            uploadContent: i18n('alert.imageUploading', completeCount - 1, total),
          });
        },
        onComplete: (res: BatchUploadFilesItem[]) => {
          const uploadedTime = performance.now() - time;
          setTimeout(() => {
            this.setState({ progress: 100, uploadContent: i18n('alert.imageUploading', res.length, res.length) });
            setTimeout(() => {
              if (!this.isCancelUpload) {
                this.setState({ uploading: false, error: this.getErrorString(typeErrs, sizeErrs, widthOrHeightErrs) });
                cb(res);
              }
            }, 100);
          }, Math.max(0, MIN_UPLOAD_TIME - uploadedTime));
        },
      };
      if (validFiles.length > 0) {
        this.setState({ uploading: true });
        batchUploadFiles(validFiles, opts).start();
        time = performance.now();
      } else {
        this.setState({ error: this.getErrorString(typeErrs, sizeErrs, widthOrHeightErrs) });
      }
    });
  };

  handleClose = () => {
    this.isCancelUpload = true;
    this.setState({ error: undefined, uploading: false });
  };

  render() {
    const { uploading, progress, uploadContent, error } = this.state;
    if (!uploading && !error) {
      return null;
    }
    return (
      <Dialog closable width={500} onClose={this.handleClose}>
        <div className="upload-pop">
          <div className="upload-content">
            {uploading && (
              <div className="progress-content">
                <div
                  className="pop-progress-pop-content-value"
                  dangerouslySetInnerHTML={{ __html: uploadContent }}
                ></div>
                <ProgressBar className="pop-progress-bar" colorTheme="blue" progress={progress} hiddenTips />
              </div>
            )}
            {error && <div className="error-content">{error}</div>}
            <div className="btn-wrap">
              {uploading && (
                <Button theme="dialog" onClick={this.handleClose}>
                  {i18n('general.cancel')}
                </Button>
              )}
              {error && (
                <Button theme="dialog" onClick={this.handleClose} activated>
                  {i18n('general.ok')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default Upload;
