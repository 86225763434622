import * as React from 'react';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { UIComponent, UIFragment } from '@editor/comps';

import { IRemark } from '@fbs/rp/models/component';
import { getDefaultComponentName } from '@libs/libs';
import RefactorRemarkManager from '@managers/refactorRemarkManager';

import RemarkItem from '../RemarkItem';

import './index.scss';

export interface IRemarkGroup {
  target: UIComponent | UIFragment;
  remark?: IRemark;
  showTag?: boolean;
  showMultiRemarks?: boolean;
  isPreview?: boolean;
  isOnlyArtboardRemark?: boolean;
  orderNumMap?: {
    [id: string]: number;
  };
  orderNumber?: number;
  selected?: boolean;
  onChange?: (value?: string, target?: UIComponent) => void;
  onScrollToComp?: (id: string) => void;
  onRename?: (value: string, target?: UIComponent) => void;
  onClick?: (e?: React.MouseEvent, comp?: UIComponent) => void;
  onShowRemark?: (comp?: UIComponent) => void;
}

const RemarkGroup: React.FC<IRemarkGroup> = (props: IRemarkGroup) => {
  const {
    showTag,
    target,
    showMultiRemarks,
    isPreview,
    isOnlyArtboardRemark,
    orderNumber,
    remark,
    selected,
    onChange,
    onScrollToComp,
    onRename,
    onClick,
    onShowRemark,
  } = props;

  const { type, id, displayName, lib } = target;
  const realName = displayName || getDefaultComponentName(type, lib);

  const handleRename = React.useCallback(
    (value: string) => {
      onRename && onRename(value, target);
    },
    [target, onRename],
  );

  const handleClick = React.useCallback(
    (e?: React.MouseEvent) => {
      if (isPreview) {
        onScrollToComp?.(target.id);
        onShowRemark && onShowRemark(target);
        return;
      }
      // TODO：输入模框需要点击联动效果
      // if (isInputting()) {
      //   RefactorRemarkManager.remarkFocus(id, true);
      //   return;
      // }
      onClick?.(e, target);
    },
    [isPreview, target],
  );
  const handelMouseEnter = React.useCallback(() => {
    onShowRemark && onShowRemark(target);
  }, [target]);
  const handelMouseLeave = React.useCallback(() => {
    onShowRemark && onShowRemark();
  }, [target]);
  const handleBlur = React.useCallback(() => {
    RefactorRemarkManager.remarkFocus(id, false);
    onScrollToComp && onScrollToComp(id);
  }, [id, isPreview]);
  const handleChange = React.useCallback(
    (val) => {
      onChange && onChange(val, target);
    },
    [target],
  );

  return (
    <div
      className={classNames('remark-group-wrap', isPreview && 'remark-group-preview-wrap')}
      onClick={handleClick}
      onBlur={handleBlur}
      onMouseEnter={handelMouseEnter}
      onMouseLeave={handelMouseLeave}
    >
      <div
        className={classNames([
          {
            'remark-group': !isPreview,
            'remark-group-preview': isPreview,
            'remark-add-border': !isOnlyArtboardRemark && showMultiRemarks && !isPreview,
            'remark-panel-add-border': isPreview,
            'artboard-remark': target.isArtboard,
            selected,
          },
        ])}
        style={
          !showTag
            ? {
                paddingTop: !isPreview ? 2 : 12,
              }
            : undefined
        }
      >
        {showTag && !isUndefined(orderNumber) && (
          <span
            className={classNames([
              'remark-tag',
              {
                'selected-tag': selected,
              },
            ])}
          >
            {orderNumber}
          </span>
        )}
        <RemarkItem
          targetId={id}
          targetName={realName!}
          content={remark}
          showMultiRemarks={showMultiRemarks}
          isPreview={isPreview}
          isOnlyArtboardRemark={isOnlyArtboardRemark}
          onChange={handleChange}
          onRename={handleRename}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

RemarkGroup.defaultProps = {
  showTag: false,
  showMultiRemarks: false,
  isPreview: false,
};

export default React.memo(RemarkGroup);
