import * as React from 'react';
import classnames from 'classnames';

import { ITag } from '../../constants';
import i18n from '../../i18n';

import ScrollView from '../ScrollView';
import Dialog from '../Dialog';
import Icon from '../Icon';
import DialogWithInput from '../DialogWithInput';
import Tag from './Tag';
import Button from '../Button';
import UpgradeTip from '../UpgradeTip';

import './index.scss';

const noGroupImg = require('./no_group.png');

export interface ITagsProp {
  title?: string;
  actionName?: string;
  dialogTitle?: string;
  editTitle?: string;
  noTagsTip?: string;
  tags?: Array<ITag>;
  addTip?: string;
  text: string;
  onCancel(): void;
  onCreateNewTag(name: string): void;
  onRenameTag(id: string | number, name: string): void;
  onDeleteTag(id: number): void;
  isAddGroupReactLimit?: boolean;
  upgradeTipText?: string;
  upgradeLinkText?: string;
  onClickLink?(): void;
}

class GroupManage extends React.Component<
  ITagsProp,
  { toDelete: any; createNewTag: boolean; newTag: string; error: string }
> {
  static defaultProps: Partial<ITagsProp> = {
    title: i18n('TagsManager.title'),
    tags: [],
    actionName: i18n('TagsManager.actionName'),
    isAddGroupReactLimit: false,
    upgradeTipText: '',
    upgradeLinkText: '',
  };

  newTagInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: ITagsProp) {
    super(props);
    this.state = {
      toDelete: null,
      createNewTag: false,
      newTag: '',
      error: '',
    };
    this.onOk = this.onOk.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onStartCreateNewTag = this.onStartCreateNewTag.bind(this);
    this.onCancelCreateNewTag = this.onCancelCreateNewTag.bind(this);
    this.onNewTagNameChange = this.onNewTagNameChange.bind(this);
    this.onCreateNewTag = this.onCreateNewTag.bind(this);
    this.newTagInputRef = React.createRef();
  }

  onRenameTag(id: string | number, newName: string) {
    const { onRenameTag } = this.props;
    onRenameTag(id, newName);
  }

  onDeleteTag(id: string | number) {
    this.setState({
      toDelete: id,
    });
  }

  onOk() {
    const { toDelete } = this.state;
    const { onDeleteTag } = this.props;
    this.setState(
      {
        toDelete: null,
      },
      () => {
        onDeleteTag(toDelete);
      },
    );
  }

  onCancel() {
    this.setState({
      toDelete: null,
    });
  }

  onStartCreateNewTag() {
    this.setState({
      createNewTag: true,
    });
  }

  onCancelCreateNewTag() {
    this.setState({
      createNewTag: false,
      newTag: '',
      error: '',
    });
  }

  onNewTagNameChange(value: string) {
    if (value.length > parseInt(i18n('TagsManager.maxLength'), 10)) {
      this.setState({
        newTag: value,
        error: i18n('TagsManager.error'),
      });
    } else {
      this.setState({
        error: '',
        newTag: value,
      });
    }
  }

  onCreateNewTag() {
    const { onCreateNewTag } = this.props;
    const { error, newTag } = this.state;
    if (!error) {
      onCreateNewTag(newTag);
      this.setState({
        createNewTag: false,
        newTag: '',
        error: '',
      });
    }
  }

  render() {
    const {
      title,
      actionName,
      tags,
      dialogTitle,
      editTitle,
      noTagsTip,
      onCancel,
      addTip,
      text,
      isAddGroupReactLimit,
      upgradeTipText,
      upgradeLinkText,
      onClickLink,
    } = this.props;
    const { toDelete, createNewTag, newTag, error } = this.state;
    let scrollHeight = 440;
    if (window.innerHeight < 700) {
      scrollHeight = window.innerHeight - 230;
    }
    return (
      <div className={classnames('dsm-c-comp-dialog')}>
        <div className="dsm-c-bg">
          <div className={classnames('dsm-c-group-pop')}>
            <Icon cls="demo_close" onClick={onCancel} />
            <div className="group-manager-wrapper">
              <div className="dsm-c-panel-group">
                <div
                  className={classnames('dsm-c-panel-title', {
                    'dsm-c-panel-title-border': tags && tags.length === 0,
                  })}
                >
                  <div className="title">{title}</div>
                  <div className="add-group">
                    <Button theme="small" onClick={this.onStartCreateNewTag} disabled={isAddGroupReactLimit} width={60}>
                      {text}
                    </Button>
                    {isAddGroupReactLimit && (
                      <UpgradeTip tooltip={upgradeTipText} linkText={upgradeLinkText} onClickLink={onClickLink} />
                    )}
                  </div>
                </div>
                <div className="dsm-c-panel-content">
                  <ScrollView height={scrollHeight}>
                    {tags && tags.length === 0 && (
                      <div className="no-tags-tip">
                        <img src={noGroupImg} alt="no_group.png" className="no_group_img" />
                        <p className="no-group-text">{noTagsTip}</p>
                      </div>
                    )}
                    {tags &&
                      tags.length !== 0 &&
                      tags.map(({ id, text }, index) => (
                        <Tag
                          key={id}
                          editTitle={editTitle || ''}
                          value={text}
                          onRename={(value: string) => {
                            this.onRenameTag(id, value);
                          }}
                          isLast={index > 7 && index === tags.length - 1}
                          onDelete={() => {
                            this.onDeleteTag(id);
                          }}
                        />
                      ))}
                  </ScrollView>
                  {toDelete !== null && (
                    <Dialog
                      showCancel
                      sureDelete
                      lightTitle
                      onOk={this.onOk}
                      onCancel={this.onCancel}
                      title={i18n('TagsManager.dialog')}
                    />
                  )}
                  {createNewTag && (
                    <DialogWithInput
                      showClose
                      title={dialogTitle}
                      placeholder={i18n('TagsManager.placeholder', [actionName])}
                      onOk={this.onCreateNewTag}
                      onChange={this.onNewTagNameChange}
                      onCancel={this.onCancelCreateNewTag}
                      value={newTag}
                      errorMsg={error}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GroupManage;
