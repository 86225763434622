import { combineReducers } from 'redux';

import { IMainState } from './webTypes';
import app from './app/reducers';
import preview from './preview/reducers';
import global from './global/reducers';
import resource from './resource/reducers';
import userPreference from './preference/reducers';
import revisions from './revisions/reducers';
import share from './share/reducers';
import appShare from './shareApp/reducers';
import message from './message/reducers';
import member from './member/reducers';
import publish from './publish/reducers';
import example from './example/reducers';
import components from './components/reducers';

export default combineReducers<IMainState>({
  app,
  preview,
  global,
  resource,
  userPreference,
  revisions,
  share,
  appShare,
  message,
  member,
  publish,
  example,
  components,
});
