// 核心编辑器的辅助方法
import { IComponentData } from '@fbs/rp/models/component';
import IArtboard from '@/fbs/rp/models/artboard';
import {
  resetComponentsData,
  cloneComponentsData,
  ICloneComponentDataOptions,
  resetRefArtboardOwnerID,
} from '@helpers/componentHelper';
import { depthClone } from '@/utils/globalUtils';
import { CConnector } from '@libs/constants';
import { MoveMode } from '@libs/enum';

import { UIContainerComponent, UIComponent } from './comps';

/**
 * 获取组件克隆并重置后的数据
 */
export function cloneComponents(options: ICloneComponentDataOptions): IComponentData[] {
  const clonedData: IComponentData[] = cloneComponentsData(options);
  resetComponentsData(clonedData);
  return clonedData;
}

export function cloneComponentsV2(
  options: ICloneComponentDataOptions,
): { componentsData: IComponentData[]; refArtboardsData: IArtboard[] } {
  const clonedData: IComponentData[] = cloneComponentsData(options);
  const clonedRefArtboardsData: IArtboard[] = depthClone(options.refArtboardsData) ?? [];

  const idMap = resetComponentsData(clonedData.concat(clonedRefArtboardsData));
  resetRefArtboardOwnerID(clonedRefArtboardsData, idMap);

  return { componentsData: clonedData, refArtboardsData: clonedRefArtboardsData };
}

export function cloneArtboardData(artboard: IComponentData): IComponentData {
  const clonedData: IComponentData = depthClone(artboard);
  resetComponentsData([clonedData]);
  return clonedData;
}

export function cloneArtboardDataV2(artboard: IComponentData[]): IComponentData[] {
  const clonedData = depthClone(artboard);
  const idMap = resetComponentsData(clonedData);
  resetRefArtboardOwnerID(clonedData as IArtboard[], idMap);
  return clonedData;
}

/**
 *
 * @param comps
 * @param originContainer 高级编辑时,该参数为最外层的复合组件
 */
export function getCompMoveInfo(comps: UIComponent[], originContainer?: UIContainerComponent) {
  const filtedComp = comps.filter((comp) => comp.type !== CConnector);
  if (filtedComp.length === 0) {
    return { horizontal: false, vertical: false };
  }
  if (filtedComp[0]) {
    const isInSealedComp = filtedComp[0].isInSuchParent((comp) => comp.isSealed);
    const moveInfo = filtedComp.map((comp) => {
      const alias = comp.alias || '';
      const constraint = originContainer && originContainer.constraint && originContainer.constraint[alias];
      const constraintInSealedComp = constraint?.move;
      return constraintInSealedComp || comp.moveConstrainInSealedComp;
    });
    if ((originContainer || isInSealedComp) && moveInfo[0]) {
      //如果选中的一堆组件中，有禁止某个方向的，那么整体都禁止该方向的移动
      const result = moveInfo.reduce(
        (acc, compMoveInfo) => {
          if (compMoveInfo === MoveMode.horizontal) {
            acc.vertical = false;
          }
          if (compMoveInfo === MoveMode.vertical) {
            acc.horizontal = false;
          }
          if (compMoveInfo === MoveMode.neither) {
            acc.horizontal = false;
            acc.vertical = false;
          }
          return acc;
        },
        { horizontal: true, vertical: true },
      );
      return result;
    }
  }
  // 2.其次如果是
  const container = filtedComp[0].parent!;
  const isInSuchParentWhichChildCanNotMove = ['wrap-panel', 'list-layout-panel'].includes(container.type);
  if (isInSuchParentWhichChildCanNotMove) {
    return {
      horizontal: false,
      vertical: false,
    };
  }
  if (filtedComp.length === 1) {
    return {
      horizontal: !filtedComp[0].isLayoutCenterAtHorizontal,
      vertical: !filtedComp[0].isLayoutMiddleAtVertical,
    };
  }
  return {
    horizontal: true,
    vertical: true,
  };
}
