import { SpriteThumb } from '@consts/spriteIcons';
import { getShapeData, shapeDefaultSize100_60, flowBase, getShapePropertiesData } from './shapeDataFragment';

// APQC
export default {
  name: 'APQC',
  type: 'APQC',
  thumb: {
    spriteIconClass: SpriteThumb.APQC.className,
    dragPostion: SpriteThumb.APQC.position,
  },
  ...flowBase,
  template: getShapeData('APQC', shapeDefaultSize100_60, {
    data: [
      { point: { x: 50, y: 0 }, handleIn: { x: 30, y: 0 }, handleOut: { x: -30, y: 0 } },
      { point: { x: 0, y: 10 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: 0, y: 60 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: 100, y: 60 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      { point: { x: 100, y: 10 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
    ],
    closed: true,
  }),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
