import * as React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';

import { isMultiLine, measureTextSize, replaceOldFontSize, transBlankChartNotInTag } from '@utils/textUtils';

import { ETextBehaviour, IComponentData, IComponentSize } from '@fbs/rp/models/component';
import ITextFormatEx, { TextAlign } from '@fbs/rp/models/properties/textFormat';
import { StrokeLineCap, StrokeLineJoin } from '@fbs/rp/models/properties/stroke';
import { FillType } from '@fbs/rp/models/properties/fill';
import { ISize } from '@fbs/common/models/common';
import { IComponentValue } from '@/fbs/rp/models/value';

import { UIComponent, UITextComponent } from '@editor/comps';
import { clearCompDynamicInfo } from '@editor/comps/resizeHelper';
import { StyleHelper } from '@helpers/styleHelper';
import * as SystemsColor from '@consts/colors';
import { IProperties } from '@fbs/rp/models/property';
import i18n from '@i18n';
import { FontBoxScale } from '@consts/fonts';
import { DefaultBorder } from '@/consts/border';
import { SpriteThumb } from '@consts/spriteIcons';
import { IComponentItem } from '@/libs/types';

import { makeCommonComponent, addTooltipsProperty, getTextShadow } from '../../helper';
import { CContentPanel, CContentPanelV2, CText } from '../../constants';

import RichTextEditor from '../common/RichTextEditor';
import Background from '../common/Background';

import './index.scss';

export interface ITextAreaProp {
  comp: UIComponent;

  [key: string]: any;
}

export interface ITextAreaState {
  style: React.CSSProperties;
  textStyle: React.CSSProperties;
  value: string;
}

export const defaultTextValue = i18n('resource.components.textContent');
export const DEFAULT_LIMIT_SIZE = 300;

export const config: IComponentItem = {
  type: CText,
  name: i18n('resource.components.text'),
  shortCut: 'T',
  thumb: {
    spriteIconClass: SpriteThumb.Text.className,
    dragPosition: SpriteThumb.Text.position,
  },
  getDefaultData: () => {
    return {
      properties: {
        radius: {
          topRight: 0,
          topLeft: 0,
          bottomLeft: 0,
          bottomRight: 0,
          isPercent: false,
          disabled: false,
        },
        fill: {
          type: FillType.solid,
          color: SystemsColor.DefaultWhiteFillColor,
          disabled: true,
        },
        stroke: {
          disabled: true,
          thickness: 1,
          color: SystemsColor.DefaultStrokeColor,
          cap: StrokeLineCap.Round,
          join: StrokeLineJoin.Round,
        },
        border: DefaultBorder,
        shadow: getTextShadow(),
      },
    };
  },
};

/**
 * 获得初始创建的文本组件的尺寸，宽度或高度限制 <= 300
 * @param style
 * @param value
 */
export function measureTextCompInitialSize(style: React.CSSProperties, value: string, limitSize = DEFAULT_LIMIT_SIZE) {
  // 计算自然尺寸
  const { width: natureWidth, height: natureHeight } = measureTextSize(style, value, {
    isMultiText: true,
    isRich: true,
    wrap: false,
  });

  // 判断是否竖排
  let isVertical = style.writingMode === 'vertical-rl';
  if (!isVertical) {
    // 横排
    if (natureWidth <= limitSize) {
      return { width: natureWidth, height: natureHeight, wrap: false, textBehaviour: ETextBehaviour.Width };
    }
    const { width: limitedWidth, height: limitedHeight } = measureTextSize(style, value, {
      isMultiText: true,
      isRich: true,
      wrap: true,
      defaultWidth: limitSize,
    });

    return { width: limitedWidth, height: limitedHeight, wrap: true, textBehaviour: ETextBehaviour.Height };
  } else {
    // 处理竖排计算
    if (natureHeight <= limitSize) {
      return { width: natureWidth, height: natureHeight, wrap: false, textBehaviour: ETextBehaviour.Height };
    }

    const { width: limitedWidth, height: limitedHeight } = measureTextSize(style, value, {
      isMultiText: true,
      isRich: true,
      wrap: true,
      defaultHeight: limitSize,
    });
    return { width: limitedWidth, height: limitedHeight, wrap: true, textBehaviour: ETextBehaviour.Width };
  }
}

/**
 * 创建的默认文本样式
 */
export function getDefaultTextFormat(): ITextFormatEx {
  return {
    fontFamily: 'Microsoft YaHei',
    fontStyle: { underline: false, bold: false, strike: false, italic: false },
    fontSize: 14,
    color: { ...SystemsColor.DefaultTextColor },
    textAlign: TextAlign.left,
    wrap: false,
    isMulti: true,
    listType: undefined,
  };
}

export const makeText = addTooltipsProperty(_makeText);

/**
 * 创建文本组件
 */
function _makeText(id: string, text = defaultTextValue, textStyle = getDefaultTextFormat()): IComponentData {
  const { width = DEFAULT_LIMIT_SIZE, height = 220, wrap = textStyle.wrap, textBehaviour = ETextBehaviour.Width } = text
    ? measureTextCompInitialSize(textStyle as React.CSSProperties, text)
    : {};

  textStyle.wrap = wrap;

  const template: Partial<IComponentData> = {
    size: { width, height },
    value: text,
    autoSize: true,
    fixContent: true,
    properties: {
      textFormat: {
        ...textStyle,
      },
      // radius: {
      //   topRight: 0,
      //   topLeft: 0,
      //   bottomLeft: 0,
      //   bottomRight: 0,
      //   isPercent: false,
      //   disabled: false,
      // },
      // fill: {
      //   type: FillType.solid,
      //   color: SystemsColor.DefaultWhiteFillColor,
      //   disabled: true,
      // },
      // stroke: {
      //   disabled: true,
      //   thickness: 1,
      //   color: SystemsColor.DefaultStrokeColor,
      //   cap: StrokeLineCap.Round,
      //   join: StrokeLineJoin.Round,
      // },
      // border: DefaultBorder,
      // shadow: getDefaultShadow(true),
    },
    states: {
      disabled: {
        enabled: false,
        opacity: 30,
      },
    },
    textBehaviour,
  };

  return makeCommonComponent(id, CText, template);
}

// Pure
class Text extends React.Component<ITextAreaProp, ITextAreaState> {
  private dom: React.RefObject<HTMLDivElement> = React.createRef();

  private focused?: boolean;

  constructor(props: ITextAreaProp) {
    super(props);
    this.state = {
      ...this.doParsePropertiesToStyle(props),
      value: props.comp.value as string,
    };
    this.param = this.getParams(props);
  }

  private param: {
    size: ISize;
    properties: IProperties;
    value?: IComponentValue;
    opacity: number;
    isPreview?: boolean;
    globalScale: number;
    valueEditing: any;
    transition: string;
    autoSize: boolean;
    version: string;
  } & { [key: string]: any };

  private getParams = (props: ITextAreaProp) => {
    const {
      comp: { size, properties, value, opacity, autoSize, version },
      isPreview,
      globalScale,
      valueEditing,
    } = props;
    const transition = props.comp.getTransition();

    return {
      size: _.cloneDeep(size),
      properties: _.cloneDeep(properties),
      value: _.cloneDeep(value),
      opacity,
      isPreview,
      globalScale,
      transition,
      valueEditing,
      autoSize,
      version,
    };
  };

  shouldComponentUpdate(nextProps: ITextAreaProp) {
    const newParam: { [key: string]: any } = this.getParams(nextProps);

    let flag = false;
    Object.keys(this.param).forEach((key) => {
      if (!_.isEqual(newParam[key], this.param[key])) {
        flag = true;
        this.param[key] = _.cloneDeep(newParam[key]);
      }
    });

    return flag;
  }

  UNSAFE_componentWillReceiveProps(nextProps: ITextAreaProp) {
    const styles = this.doParsePropertiesToStyle(nextProps);
    this.setState({
      ...styles,
      value: nextProps.comp.value as string,
    });
  }

  editorBounds = (properties: IProperties, size: IComponentSize) => {
    const style: React.CSSProperties = {};
    const { autoSize } = this.props.comp;
    const { textStyle, multiText, textFormat } = properties;
    const { width, height } = size;
    let textAlign: TextAlign = TextAlign.left;
    if (textFormat) {
      textAlign = textFormat.textAlign || TextAlign.left;
    } else if (textStyle) {
      textAlign = textStyle.textAlign || TextAlign.left;
    }
    const alignConfig = {
      [TextAlign.left]: '0',
      [TextAlign.center]: '50%',
      [TextAlign.right]: '0',
      [TextAlign.justify]: '0',
    };

    let wrap = false;
    let vertical = false;
    if (textFormat) {
      //单行文本的时候,如果是 !autoSize,那么应该可以换行
      wrap = !autoSize ? true : textFormat.wrap;
      vertical = !!textFormat.vertical;
    } else if (multiText) {
      wrap = multiText.wrap;
      vertical = !!multiText.vertical;
    }

    if (wrap) {
      style.top = 0;
      if (vertical) {
        style.right = 0;
        style.minHeight = height / FontBoxScale;
      } else {
        style.height = 'auto';
        style.width = !autoSize ? width / FontBoxScale : 'auto';
        style.left = 0;
      }
    } else {
      if (vertical) {
        style.height = 'auto';
        style.minHeight = height;
        style.right = 0;
        if (textAlign !== TextAlign.right) {
          style.top = alignConfig[textAlign];
          style.transform = `translateY(-${alignConfig[textAlign]})`;
        } else {
          style.bottom = 0;
        }
      } else {
        style.width = 'auto';
        style.top = 0;
        if (textAlign !== TextAlign.right) {
          style.left = alignConfig[textAlign];
          style.transform = `translateX(-${alignConfig[textAlign]})`;
        } else {
          style.right = 0;
        }
      }
      const transformAlign = [textFormat?.textAlign || '', 'top'];
      if (!['left', 'center', 'right'].includes(transformAlign[0])) {
        transformAlign[0] = 'left';
      }
      if (vertical) {
        transformAlign[1] = ['top', 'center', 'bottom'][['left', 'center', 'right'].indexOf(transformAlign[0])];
        transformAlign[0] = 'right';
      }
      style.transformOrigin = transformAlign.join(' ');
    }
    style.transform = (style.transform || '') + ' scale(' + FontBoxScale + ')';

    return style;
  };

  doParsePropertiesToStyle = (props: ITextAreaProp): { style: React.CSSProperties; textStyle: React.CSSProperties } => {
    const { properties, size, opacity, value, _animation: animation } = props.comp;
    const parser = StyleHelper.initCSSStyleParser(properties);
    const radius = props.valueEditing ? {} : parser.getRadiusStyle(size);
    let style: React.CSSProperties = {
      ...radius,
      opacity: _.isUndefined(opacity) ? 1 : opacity / 100,
    };
    const { fill, stroke, multiText, textFormat } = properties;
    const vertical = textFormat ? textFormat.vertical : multiText?.vertical;
    const wrap = (textFormat || multiText)?.wrap;
    const textStyle = {
      ...parser.getTextStyle(),
      ...radius,
      ...(props.valueEditing ? {} : parser.getTextShadow(1 / FontBoxScale)),
    };
    const { width, height } = size;
    const tx = typeof value === 'string' ? value : '';
    if (!isMultiLine(tx) && !wrap) {
      textStyle.lineHeight = vertical ? `${width}px` : `${height}px`;
    }

    if (vertical) {
      textStyle.right = 0;
      delete textStyle.left;
    }
    if ((fill && !fill.disabled) || (stroke && !stroke.disabled)) {
      style = {
        ...style,
        ...(props.valueEditing ? {} : parser.getShadowStyle(true)),
      };
    }
    if (animation) {
      style.transition = `all ${animation.duration || 1}ms ${animation.timing}`;
    }
    // 这里也处理了样式，感觉重复处理了，这不去掉根元素上的组件数据相关样式也没问题
    // StyleHelper.clearComponentStyleWithRichTextValue(textStyle, value as string);
    return {
      style,
      textStyle,
    };
  };

  private doSubmit = (value: string, option: { wrap?: boolean; size?: ISize }, holdEdit?: boolean) => {
    this.focused = false;
    const { onValueEdited, comp } = this.props;
    //handleSizeChange设置的comp.dynamicInfo，在提交后要取消掉
    clearCompDynamicInfo([comp]);

    if (!holdEdit) {
      const selection = document.getSelection();
      if (selection) {
        selection.empty();
      }
    }
    const valueElement = document.createElement('div');
    valueElement.innerHTML = value;
    if (valueElement.innerText === '') {
      value = '';
    }

    onValueEdited && onValueEdited(comp, value, option, holdEdit);
  };

  private doValueEditing = () => {
    const { onValueEditing, comp } = this.props;
    onValueEditing && onValueEditing(comp);
  };

  private handleSizeChange = (width: number, height: number) => {
    const { comp } = this.props;
    const prevWidth = comp.size.width;
    const prevHeight = comp.size.height;
    const textBehaviour = (comp as UITextComponent).textBehaviour;
    //固定宽度,不进行计算宽度高度跟随
    if (textBehaviour === ETextBehaviour.Both) {
      return;
    }
    if (prevWidth !== width || prevHeight !== height) {
      comp.dynamicInfo = {
        ...comp.dynamicInfo,
        size: { width, height },
      };
      //原则上这里不应该使用updateComponentView,目前因为RichTextEditor的输入回调不是实时调用返回，动态输入时候要动态跟随边框
      //只能把size大小回传,设置dynamicInfo.size重新更新一下Ui
      comp.updateComponentView?.();
    }
  };

  getCSSWrapAttr = () => {
    const { comp } = this.props;
    const { /*properties,*/ autoSize, textBehaviour } = comp as UITextComponent;
    // const { multiText, textFormat } = properties;
    const sealCom = comp.nearestSealedComponent;
    if (sealCom && sealCom.type !== CContentPanel && sealCom.type !== CContentPanelV2) {
      return true;
    }
    // return autoSize ? false : (textFormat || multiText)?.wrap;
    if (_.isUndefined(textBehaviour)) {
      return !autoSize;
    }
    return textBehaviour !== ETextBehaviour.Width;
  };

  render() {
    const { valueEditing, isPreview, comp } = this.props;
    const { size, properties, lineHeight: defaultLH, opacity, textFormat, autoSize } = comp;
    const value = comp.value as string;
    let { width, height } = size;
    const { multiText, padding, border, fill, stroke, radius } = properties;
    const textBehaviour = (comp as UITextComponent).textBehaviour;
    const _autoSize = _.isUndefined(textBehaviour) ? autoSize : textBehaviour === ETextBehaviour.Width;
    const wrap = this.getCSSWrapAttr();
    const isVertical = !!(textFormat || multiText)?.vertical;

    const { style, textStyle } = this.state;
    const fontSize = (textFormat || properties.textStyle)?.fontSize || 14;
    const lineHeightEx = textFormat?.lineHeightEx || defaultLH;

    const letterSpace = (textFormat || properties.textStyle)?.letterSpace || 0;
    textStyle.transformOrigin = isVertical ? 'right top' : 'left top';
    width /= FontBoxScale;
    height /= FontBoxScale;
    textStyle.fontSize = fontSize / FontBoxScale;
    textStyle.lineHeight = lineHeightEx / FontBoxScale + 'px';
    textStyle.letterSpacing = letterSpace / FontBoxScale + 'px';
    textStyle.transform = 'scale(' + FontBoxScale + ')';
    if (padding && !padding.disabled) {
      if (!isVertical) {
        textStyle.paddingTop = (padding.top || 0) / FontBoxScale;
        textStyle.paddingBottom = (padding.bottom || 0) / FontBoxScale;
        textStyle.paddingLeft = (padding.left || 0) / FontBoxScale;
        textStyle.paddingRight = (padding.right || 0) / FontBoxScale;
      } else {
        textStyle.paddingTop = (padding.left || 0) / FontBoxScale;
        textStyle.paddingBottom = (padding.right || 0) / FontBoxScale;
        textStyle.paddingLeft = (padding.bottom || 0) / FontBoxScale;
        textStyle.paddingRight = (padding.top || 0) / FontBoxScale;
      }
    }

    let richTextEditorStyle: React.CSSProperties = {
      ...textStyle,
      position: 'absolute',
      ...this.editorBounds(properties, size),
      boxSizing: 'border-box',
      width: _autoSize ? undefined : `${100 / FontBoxScale}%`,
      zIndex: 1,
      // filter: 'blur(0)', // 弱化2D渲染的产生的线性问题 NOTE: 超多文本组件会影响
    };
    const text = typeof value === 'string' ? value : '';

    const _text = transBlankChartNotInTag(text.replace(/\n/g, '__$_&_$__'))
      .replace(/[ ]|&nbsp;/g, '\u0020') // 替换空格为编码
      .replace(/__\$_&_\$__/g, '<br/>'); //转义字符（&nbsp;）生成的空格替换为空格

    const isTransparent = opacity === 0;
    // 以 textBehavior 为主，处理文字遮挡
    const autoSizeDerivedFromTextBehaviour = textBehaviour ? textBehaviour !== ETextBehaviour.Both : autoSize;
    const parser = StyleHelper.initCSSStyleParser({ stroke, radius, border });
    const radiusStyle = parser.getRadiusStyle(size);
    return (
      <div
        className="lib-comp-text"
        style={{
          overflow: autoSizeDerivedFromTextBehaviour || valueEditing ? 'visible' : 'hidden',
          ...style,
        }}
      >
        {!fill?.disabled && !isTransparent && (
          <Background size={size} properties={{ fill }} transition={comp.getTransition()} coverStyle={radiusStyle} />
        )}
        {!isPreview && valueEditing && (
          <RichTextEditor
            className={classnames('atom-comp-text', { editing: valueEditing, wrap })}
            style={richTextEditorStyle}
            value={_text}
            wrap={wrap}
            autoSize={_autoSize}
            autoSave
            onValueChanged={this.doSubmit}
            onSelectChanged={this.doValueEditing}
            onSizeChange={this.handleSizeChange}
            multi
            ownerComp={comp}
            onlyText
            vertical={isVertical}
          />
        )}
        {(isPreview || !valueEditing) && text && (
          <div
            ref={this.dom}
            className={classnames(
              'atom-comp-text normal',
              {
                wrap,
                'no-wrap': !wrap,
                vertical: isVertical,
                preview: isPreview,
                interaction: isPreview && Object.keys(comp.interactions).length,
                'fixed-width': textBehaviour === ETextBehaviour.Width || autoSize,
                'fixed-height': textBehaviour === ETextBehaviour.Height || autoSize,
              },
              comp.getInheritClassName(),
            )}
            style={{
              ...textStyle,
              transition: comp.getTransition(),
              position: 'absolute',
              top: 0,
              left: isVertical ? undefined : 0,
              right: isVertical ? 0 : undefined,
              width,
              minWidth: isVertical ? undefined : width,
              minHeight: isVertical ? height : undefined,
            }}
            dangerouslySetInnerHTML={{ __html: replaceOldFontSize(_text, 'out') }}
          />
        )}
        {!stroke?.disabled && !isTransparent && (
          <Background
            size={size}
            properties={{ stroke, border }}
            transition={comp.getTransition()}
            coverStyle={radiusStyle}
          />
        )}
      </div>
    );
  }
}

export default Text;
