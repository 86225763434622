import { IExportItemData } from '@/fbs/rp/models/component';
import { ExportImageLoading } from './ExportImageLoading';
import { getIsLimitExportAndShowDialog } from '../util/dialogUtil';

/**
 * 导出流程
 * 包括加载中，异常等弹窗信息
 */
export class ExportImageProcessor {
  private loging = new ExportImageLoading();
  private timer: NodeJS.Timeout | null = null;

  setStep(step: number) {
    this.loging.setStep(step);
  }

  run(
    processor: (onProgress?: (progress: number, e?: string[]) => void) => Promise<void> | undefined,
    exportFormats?: IExportItemData[],
  ) {
    // 权限
    if (getIsLimitExportAndShowDialog(exportFormats)) {
      return;
    }
    // 非自动进度条
    this.loging.begin(false);
    this.timer = setTimeout(() => {
      const result = processor((progress: number, e?: string[]) => {
        // 实时进度条
        window.debug && console.log('progress:' + progress);
        this.loging.run(progress, e);
      });
      if (!result) {
        return;
      }
      result.catch((e) => {
        // 异常处理
        console.error(e);
      });
    });
  }

  progres(n: number) {
    window.debug && console.log('progres' + n);
    this.loging.run(n);
  }
  next() {
    this.loging.next();
  }
  terminate() {
    window.debug && console.log('terminate processor');
    this.timer && clearTimeout(this.timer);
    this.loging.terminate();
  }
}

// 执行流程，包含提示框
export const processor = new ExportImageProcessor();
