import { IComponentData } from '@/fbs/rp/models/component';
import { IPosition } from '@/fbs/idoc/models/common';
import { getBoundsWithPoints, offsetPoint, rotatePoint } from '@/utils/boundsUtils';
import {
  CArtboard,
  CGroup,
  CCanvasPanel,
  CContentPanel,
  CContentPanelV2,
  CSymbol,
  CTable,
  CPath,
  CConnector,
  CLine,
} from '@/libs/constants';
import { IBounds, ISize } from '../type';
import { LinePointType } from '@/fbs/rp/models/properties/line';
import { UIComponent, UIContainerComponent } from '@/editor/comps';
import { offseOpposite } from '.';

// 中心点
export const calcCenter = (bounds: IBounds) => {
  const { x, y, width, height } = bounds;
  const center = {
    x: x + width / 2,
    y: y + height / 2,
  };
  return center;
};

/**
 * 旋转后的bounds
 * @param bounds
 * @param rotate
 * @returns
 */

export const getBoundsAfterRotate = (
  bounds: IBounds,
  rotate: number,
  center?: IPosition,
  isRoot?: boolean,
): IBounds => {
  if (!rotate) {
    return bounds;
  }
  const { x, y, width, height } = bounds;

  const points = [
    { x, y },
    { x: x + width, y },
    { x: x + width, y: y + height },
    { x, y: y + height },
  ];

  const newcenter = center ?? calcCenter(bounds);

  const newPoints = points.map((p) => rotatePoint(p, newcenter, rotate));
  const { left, top, width: newWidth, height: newHeight } = getBoundsWithPoints(newPoints, true);

  return {
    x: isRoot ? 0 : left,
    y: isRoot ? 0 : top,
    width: newWidth,
    height: newHeight,
  };
};

export const offsetBounds = (bounds: IBounds, offset?: IPosition) => {
  const { x, y, width, height } = bounds;
  const { x: offsetX = 0, y: offsetY = 0 } = offset ?? {};
  return {
    x,
    y,
    width: width + Math.min(offsetX, 0),
    height: height + Math.min(offsetY, 0),
  };
};

/**
 * 旋转后的size
 * @param size
 * @param rotate
 * @returns
 */
export const getSizeAfterRotate = (size: ISize, rotate: number): ISize => {
  if (!rotate) {
    return size;
  }
  const { width = 100, height = 100 } = size;

  const newBounds = getBoundsAfterRotate({ x: 0, y: 0, width, height }, rotate);
  return {
    width: newBounds.width,
    height: newBounds.height,
  };
};

/**
 * 中心旋转后，保持topleft点不变，中心点偏移量
 */
export const getCenterOffsetInViewportAfterRotate = (size: ISize, rotate: number): IPosition => {
  if (rotate) {
    const { width = 100, height = 100 } = size ?? {};
    let newSize = { width, height };
    newSize = getSizeAfterRotate(newSize, rotate);
    const newCenter = calcCenter({ x: 0, y: 0, ...newSize });
    const center = calcCenter({ x: 0, y: 0, ...size });
    return { x: newCenter.x - center.x, y: newCenter.y - center.y };
  }
  return {
    x: 0,
    y: 0,
  };
};

interface unionBoundsOptions {
  position: IPosition;
  outerBounds: IBounds;
  offset?: IPosition;
}
export const unionBounds = (data: unionBoundsOptions[]): { bounds: IBounds; offset: IPosition } => {
  let width = 0;
  let height = 0;
  let offsetX = 0;
  let offsetY = 0;

  data.forEach((t) => {
    const { position, outerBounds, offset } = t;
    const { x = 0, y = 0 } = position ?? {};
    const { x: itemX, y: itemY, width: itemWidth, height: itemHeight } = outerBounds;
    const { x: itemOffsetX, y: itemOffsetY } = offset ?? { x: 0, y: 0 };

    if (itemOffsetX < 0 && x + itemOffsetX < 0) {
      offsetX = Math.min(offsetX, x + itemOffsetX);
    }
    if (itemOffsetY < 0 && y + itemOffsetY < 0) {
      offsetY = Math.min(offsetY, y + itemOffsetY);
    }
    width = Math.max(width, itemWidth + x + itemX);
    height = Math.max(height, itemHeight + y + itemY);
  });

  return {
    bounds: {
      x: 0,
      y: 0,
      width: Math.ceil(width - offsetX),
      height: Math.ceil(height - offsetY),
    },
    offset: {
      x: offsetX,
      y: offsetY,
    },
  };
};

/**
 * 计算组件占位区域
 * @param t
 * @returns
 */
export const calcOuterBounds = (t: IComponentData, isRoot?: boolean) => {
  if (t.type === CArtboard) {
    const size = t.size;
    const bounds = {
      x: 0,
      y: 0,
      ...size,
    };
    return {
      outerBounds: bounds,
      innerBounds: bounds,
      offset: {
        x: 0,
        y: 0,
      },
    };
  }
  // fixme:链接线
  // 编组内链接线有世界坐标的情况？
  // 与一头链接的链接线编组后位置改变，是个bug
  // if (t.type === CConnector) {
  //   const { startPoint, endPoint } = t.value
  //   const size = {
  //     width: endPoint.x - startPoint.x,
  //     height: endPoint.y - endPoint.y
  //   }
  //   // const size = t.size
  //   const bounds = {
  //     x: 0,
  //     y: 0,
  //     ...size,
  //   };
  //   return {
  //     outerBounds: bounds,
  //     innerBounds: bounds,
  //     offset: {
  //       x: 0,
  //       y: 0,
  //     },
  //   };
  // }
  const { properties, rotate, size } = t;
  let { width, height } = size;

  let offsetX = 0;
  let offsetY = 0;
  let offsetRight = 0;
  let offsetBottom = 0;
  let blur = 0;
  const { shadow, stroke, line } = properties ?? {};
  // 阴影
  if (shadow && !shadow.disabled) {
    // 偏移的距离
    let { x = 0, y = 0 } = shadow;
    blur = shadow.blur ?? 0;

    // 模糊
    offsetX = Math.min(0, x - blur);
    offsetY = Math.min(0, y - blur);
    offsetRight = Math.max(0, Math.abs(x) + blur);
    offsetBottom = Math.max(0, Math.abs(y) + blur);

    //  旋转
    if (rotate) {
      const rotateShadow = rotatePoint({ x, y }, { x: 0, y: 0 }, rotate);
      offsetX = Math.min(0, rotateShadow.x - blur);
      offsetY = Math.min(0, rotateShadow.y - blur);
    }
  }

  // 路径的heigt =0,需要调整
  const thickness = stroke?.thickness ?? 0;

  // 表格是外描边，其他是内描边
  if (t.type === CTable) {
    offsetX -= thickness;
    offsetY -= thickness;
    offsetRight += thickness;
    offsetBottom += thickness;
  }

  // 路径是居中描边
  if (t.type === CPath) {
    offsetX -= thickness / 2;
    offsetY -= thickness / 2;
    offsetRight += thickness / 2;
    offsetBottom += thickness / 2;
  }

  // 进度条最小size
  if (t.lib?.type === 'progressBar') {
    width = Math.max(width, thickness * 2);
    height = Math.max(height, thickness * 2);
  }

  // svg是采用居中描边， rp是内描边，这里计算图形实际bounds
  const innerBounds = {
    x: thickness / 2,
    y: thickness / 2,
    width: width - thickness * 2,
    height: height - thickness * 2,
  };

  // 箭头 , 带箭头的线条
  let arrowOverflow = thickness;
  if ((t.type === CLine || t.lib?.type === 'arrow') && line) {
    const { startArrow, endArrow, endPointType, startPointType } = line;
    if (startArrow || endArrow) {
      if (startPointType === LinePointType.none && endPointType === LinePointType.none) {
        arrowOverflow = thickness;
      } else {
        arrowOverflow = thickness * 5;
        offsetY = -thickness * 5;
        offsetX = -thickness * 2.3;
        offsetRight += thickness * 2.3;
        offsetBottom += thickness + 1;
      }
    }
  }

  // 包括溢出部分
  let outerBounds = {
    x: 0,
    y: 0,
    width: Math.max(width + offsetRight, thickness, arrowOverflow),
    height: Math.max(height + offsetBottom, thickness, arrowOverflow),
  };

  // 旋转后的bounds
  if (rotate) {
    outerBounds = getBoundsAfterRotate(outerBounds, rotate, undefined, isRoot);
  }

  const offset = {
    x: offsetX,
    y: offsetY,
  };
  // 排除外描边
  if (![CTable, CPath, CLine].includes(t.type) && !['arrow', 'progressBar'].includes(t.lib?.type ?? '')) {
    outerBounds = offsetBounds(outerBounds, {
      x: offsetX + blur,
      y: offsetY + blur,
    });
  }

  return { outerBounds, offset, innerBounds };
};

export const needPlainCompoent = (type: string) => {
  return [CArtboard, CGroup, CCanvasPanel, CContentPanel, CContentPanelV2, CSymbol].includes(type);
};

/**
 * 展开容器组件
 * @param t
 * @param isRoot
 * @param needPlain
 */
export const plainContainer = (
  t: IComponentData | IComponentData[],
  isRoot?: boolean,
  needPlain?: (type: string) => boolean,
): IComponentData[] => {
  const arr = Array.isArray(t) ? t : [t];
  const list: IComponentData[] = [];
  arr.forEach((t) => {
    const type = t.type;
    const basePosition = t.position;
    const position = isRoot ? { x: 0, y: 0 } : basePosition;
    const check = needPlain ?? needPlainCompoent;

    if (!t.sealed && check(type) && t.components?.length) {
      list.push({ ...t, position });
      t.components.forEach((t) => {
        const newPosition = offsetPoint(position, t.position);
        list.push(...plainContainer({ ...t, position: newPosition }, false, needPlain));
      });
    } else {
      list.push({ ...t, position });
    }
  });

  return list;
};

/**
 * 只展开获取基础组件名字名字
 * @param t
 * @param isRoot
 * @param needPlain
 */
export const plainUIContainer = (
  t: UIComponent | UIComponent[],
  needPlain?: (type: string) => boolean,
): UIComponent[] => {
  const arr = Array.isArray(t) ? t : [t];
  const list: UIComponent[] = [];
  arr.forEach((t) => {
    const type = t.type;
    const check = needPlain ?? needPlainCompoent;
    if (check(type) && (t as UIContainerComponent).components?.length) {
      list.push(t);
      (t as UIContainerComponent).components.forEach((t) => {
        list.push(...plainUIContainer(t));
      });
    } else {
      list.push(t);
    }
  });

  return list;
};

/**
 *  只展开编组和画板
 * @param t
 * @param isRoot
 */
const plainGroup = (t: IComponentData | IComponentData[], isRoot?: boolean): IComponentData[] => {
  const needPlain = (type: string) => {
    return [CArtboard, CGroup, CSymbol].includes(type);
  };
  return plainContainer(t, isRoot, needPlain);
};

/**
 * 计算编组外框区域
 * @param t
 * @param isRoot
 */
export const calcGroupOuterBounds = (t: IComponentData, isRoot: boolean): { bounds: IBounds; offset: IPosition } => {
  const basePosition = t.position;

  const list = plainGroup(t, isRoot);
  const connectPoints: IPosition[] = [];
  const bounds: {
    outerBounds: IBounds;
    offset: IPosition;
    position: IPosition;
  }[] = [];

  // 编组的尺寸会不准确，排除
  list
    .filter((t) => t.type !== CGroup)
    .forEach((t, index) => {
      // 单独组件导出 ，为根组件
      // 单独内容面板导出，包括多个组件，第一个为根组件

      const position = t.position;
      if (t.type === CConnector) {
        const { startPoint, endPoint } = t.value;
        connectPoints.push(offseOpposite(startPoint, basePosition));
        connectPoints.push(offseOpposite(endPoint, basePosition));
      } else {
        const isItemRoot = isRoot && index === 0;
        const { outerBounds, offset } = calcOuterBounds(t, isItemRoot);

        bounds.push({ outerBounds, position, offset });
      }
    });

  const result = unionBounds(bounds);
  return result;
};
