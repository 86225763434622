import i18n from '@/i18n';

import { IComponentLib } from '../types';
import { BarChartConfig } from './SeriesChartOne/BarChart/config';
import { PieChartConfig } from './SeriesChartTwo/PieChart/config';
import { DoughnutChartConfig } from './SeriesChartTwo/DoughnutChart/config';
import { LineChartConfig } from './SeriesChartOne/LineChart/config';
import { BarChartHorizontal } from './SeriesChartOne/CBarChartHorizontal/config';
import { AreaChartConfig } from './SeriesChartOne/AreaChart/config';
import { RadarChartConfig } from './SeriesChartOne/RadarChart/config';
import { ScatterChartConfig } from './SeriesChartOne/Scatter/config';
import { CombinationChartConfig } from './SeriesChartOne/CombinationChart/config';
import { GaugeConfig } from './GaugeChart/config';
import { MapChartConfig } from './MapChart/config';

const ChartComponentLib: IComponentLib = {
  id: 'chart',
  name: i18n('resource.components.chart'),
  components: [
    BarChartConfig,
    BarChartHorizontal,
    PieChartConfig,
    DoughnutChartConfig,
    LineChartConfig,
    AreaChartConfig,
    CombinationChartConfig,
    RadarChartConfig,
    ScatterChartConfig,
    GaugeConfig,
    MapChartConfig,
  ],
};
export default ChartComponentLib;
