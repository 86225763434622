import Doc from '@/editor/document';
import { IAppWithNestedChildren } from '@/fbs/rp/models/app';
import IArtboard from '@/fbs/rp/models/artboard';
import { IComponentData, NamingScheme, IExportItemData } from '@/fbs/rp/models/component';
import i18n from '@/i18n';
import { IMakeZipItemFilenameOptions } from '../type';
import { CText } from '@/libs/constants';
import { uniqueName } from './uniqueName';

interface IMakeFilenameOptions {
  t: IComponentData;
  index?: number;
  folder?: string;
  scale?: number;
  namingScheme?: NamingScheme;
}

interface IMakeFilenameBySchemeOptions {
  name: string;
  id?: string;
  index?: number;
  folder?: string;
  scale?: number;
  namingScheme?: NamingScheme;
}

export const getScales = (exportFormats?: IExportItemData[]): number[] => {
  if (!exportFormats) {
    return [1];
  }
  return exportFormats.map((t) => (t.exportRatio ? parseFloat(t.exportRatio) : 1));
};

/**
 * 去掉特殊字符
 * @param name
 */
const stripFilenameSpecialCharacter = (name: string): string => {
  return name.replace(/\//g, '_');
};
/**
 * 获取组件名
 * @param t
 */
export const geComponenttName = (t: IComponentData) => {
  const { type } = t;
  const defaultName = CText === type ? i18n(`resource.components.${type}`) : type;
  const name = t.name ? stripFilenameSpecialCharacter(t.name) : defaultName;
  return name;
};

export const makeFilenameByScheme = (options: IMakeFilenameBySchemeOptions) => {
  const { name, folder, scale = 1, namingScheme, id } = options;
  const adorns = `@${scale}x`;
  const _id = id ?? '';
  if (namingScheme == NamingScheme.Prefix) {
    // 前缀
    return uniqueName.makeFilename(`${folder ? folder + '/' : ''}${adorns}${name}$index`, _id);
  }
  // 后缀
  return uniqueName.makeFilename(`${folder ? folder + '/' : ''}${name}$index${adorns}`, _id);
};
/**
 * 文件名
 * @param t
 * @param index 防止重命名
 * @returns
 */
export const makeFilename = (options: IMakeFilenameOptions) => {
  const { t, folder, scale = 1, namingScheme } = options;
  const { _id } = t;
  const name = geComponenttName(t);
  return makeFilenameByScheme({
    folder,
    name,
    scale,
    namingScheme,
    id: _id,
  });
};

/**
 * tofix:重名情况处理
 * @param options
 */
export const makeZipItemFilename = (options: IMakeZipItemFilenameOptions) => {
  const { t, index, app, scale, namingScheme, isOutputAllPages } = options;
  let folder = '';
  if (isOutputAllPages) {
    // 导出全部页面，用文件夹
    const pageID = (t as IArtboard).nodeID;
    if (app && pageID) {
      folder = getUniqueFolderName(app, pageID);
    }
  }

  return makeFilename({ t, index, folder, scale, namingScheme });
};

/**
 * 页面名称
 * @param t
 * @param app
 * @returns
 */
export const getPageName = (t: IArtboard, app?: IAppWithNestedChildren): string => {
  const pageID = t.nodeID;
  if (app && pageID) {
    return getFolderName(app, pageID);
  }
  return '';
};

/**
 *
 * @param app
 * @param pageID
 * @returns
 */
const getFolderName = (app: IAppWithNestedChildren, pageID: string) => {
  // 去掉ROOt
  const page = app.flatChildren[pageID];
  const paths = page.path.split(',').slice(1);
  const pathname = paths
    .map((t) => {
      const child = app.flatChildren[t];
      if (child) {
        return stripFilenameSpecialCharacter(child.name);
      }
      return '';
    })
    .filter((t) => t);
  const foldername = [...pathname, stripFilenameSpecialCharacter(page.name)].join('/');
  return foldername;
};

/**
 * 唯一文件夹名
 * @param foldername
 * @param pageID
 * @returns
 */
const getUniqueFolderName = (app: IAppWithNestedChildren, pageID: string) => {
  const foldername = getFolderName(app, pageID);
  return uniqueName.makeFolderNmae(foldername + '$index', pageID);
};

export const makeZipFilename = (doc?: Doc, app?: IAppWithNestedChildren, isOutputAllPages?: boolean): string => {
  // 导出所有页 的画板
  if (isOutputAllPages && app && app.name) {
    return app.name;
  }
  if (doc) {
    const pageId = doc.pageID;
    if (app && pageId) {
      // 当前页
      const page = app.flatChildren[pageId];
      return page ? page.name : 'rp';
    }
  }
  return 'rp';
};
