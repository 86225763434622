import * as React from 'react';

import { isUS } from '../../../envHelper';
import i18n from '../../../../i18n';

import Input from '../../../Input';

import './index.scss';

interface IQuickJumperProps {
  show: boolean;
  totalPage: number;

  onChange?(pageNumber: number): void;
}

const QuickJumper: React.FC<IQuickJumperProps> = (props: IQuickJumperProps) => {
  const { show, totalPage, onChange } = props;
  const [value, setValue] = React.useState('');

  const onSubmit = () => {
    const valueInteger = parseInt(value.trim());
    if (isNaN(valueInteger)) {
      setValue('');
      return;
    }
    if (totalPage === 0) {
      setValue('');
      return;
    }
    if (valueInteger > totalPage) {
      onChange && onChange(totalPage);
      setValue('');
      return;
    }
    if (valueInteger <= 0) {
      setValue('');
      return;
    }
    onChange && onChange(valueInteger);
    setValue('');
  };

  if (!show) {
    return null;
  }

  return (
    <div className="dsm-c-pagination-quick-jumper">
      {i18n('pager.jumpTo')}
      <Input width={55} value={value} fullBorder onChange={setValue} onSubmit={onSubmit} />

      {!isUS && i18n('pager.page')}
    </div>
  );
};

export default QuickJumper;
