export enum Direction {
  EAST = 'east',
  SOUTH = 'south',
  WEST = 'west',
  NORTH = 'north',
  WN = 'wn',
  WS = 'ws',
  EN = 'en',
  ES = 'es',
}
