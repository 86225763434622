import { IExportItemData } from '@/fbs/rp/models/component';
import { ArtboardPatches, ComponentPatches } from '@/fbs/rp/utils/patch';
import CoreStyle from './style';
import { UIComponent } from '../comps';

/**
 * 导出图片设置
 */
export default class CoreExport extends CoreStyle {
  private updateExportOption = (fn: (t: UIComponent) => ComponentPatches) => {
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    let components: UIComponent[];
    if (this.selectedComponentList.length) {
      components = this.selectedComponentList;
    } else if (this.selectedFragments.size) {
      components = Array.from(this.selectedFragments).slice(0, 1);
    } else {
      components = [this.activeArtboard];
    }

    components.forEach((t: UIComponent) => {
      const comppatches = fn(t);
      patches.do[t.id] = comppatches.do;
      patches.undo[t.id] = comppatches.undo;
    });

    this.update({
      [this.activeArtboard.artboardID]: patches,
    });
  };

  /**
   * 增加一行配置
   * @param option
   */
  public addExportOption = (option: IExportItemData) => {
    this.updateExportOption((t: UIComponent) => {
      return t.addExportOptionItem(option);
    });
  };

  /**
   * 删除一行配置
   * @param option
   */
  public removeExportOption = (option: IExportItemData) => {
    this.updateExportOption((t: UIComponent) => {
      return t.removeExportOptionItem(option);
    });
  };

  /**
   * 修改
   */
  public replaceExportOption = (option: IExportItemData, index?: number) => {
    this.updateExportOption((t: UIComponent) => {
      return t.replaceExportOptionItem(option, index);
    });
  };
}
