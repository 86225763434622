import { get, sortBy } from 'lodash';

import i18n from '@/i18n';
import { SpriteThumb } from '@/consts/spriteIcons';
import ValueEditorType from '@/consts/enums/valueEditorType';
import {
  DeepBlueColor,
  DefaultTextColor,
  DefaultWhiteFillColor,
  DefaultStrokeColor,
  MapSingleColor,
} from '@/consts/colors';
import { DEFAULT_MAP_VALUES, DEFAULT_MAP_COLORS, DEFAULT_VALUE_NAMES } from '@/consts/defaultData/mapChart';
import { IComponentData } from '@/fbs/rp/models/component';
import { IProperties, PropertyValue } from '@/fbs/rp/models/property';
import { ArtboardPatches, Ops } from '@/fbs/rp/utils/patch';
import { PureColor } from '@/fbs/rp/models/properties/color';
import { IMapChartValue } from '@/fbs/rp/models/chart';
import IMapChart, { MapType, LegendPosition, MapChartPropertyName } from '@/fbs/rp/models/properties/mapChart';
import { IComponentItem } from '@/libs/types';
import { makeCommonComponent } from '@/libs/helper';
import { UIComponent } from '@/editor/comps';
import { getChartCommonProperties } from '../common';
import { CMapChart } from '../../constants';

function getDefaultProperties(): IProperties {
  const commonProperties = getChartCommonProperties();

  return {
    ...commonProperties,
    textStyle: {
      fontFamily: 'Microsoft YaHei',
      fontSize: 10,
      color: DefaultTextColor,
    },
    mapChart: {
      prop: MapChartPropertyName,
      region: '100000', // 默认中国地图
      type: MapType.Monochrome,
      showLabel: true,
      regionBorder: {
        color: DefaultStrokeColor,
      },
      hover: {
        border: {
          disabled: false,
          color: DefaultStrokeColor,
        },
        fill: {
          disabled: false,
          color: DeepBlueColor,
        },
        textStyle: {
          fontFamily: 'Microsoft YaHei',
          fontSize: 10,
          color: DefaultTextColor,
        },
      },
      popup: {
        border: {
          disabled: true,
          color: DefaultStrokeColor,
        },
        fill: {
          disabled: false,
          color: DefaultWhiteFillColor,
        },
        textStyle: {
          fontFamily: 'Microsoft YaHei',
          fontSize: 12,
          color: DefaultTextColor,
        },
      },
      legend: {
        disabled: false,
        position: LegendPosition.Rightbottom,
      },
    },
  };
}

/**
 * 存在旧值，用旧值覆盖新值，否则使用默认值
 * 新旧判断根据dataSource判定
 * @param mapData
 * @param oldValue
 */
function getDefaultValue(names: string[], oldValue?: IMapChartValue) {
  const xAxis = oldValue?.xAxis || [i18n('resource.components.user_count'), i18n('resource.components.sale_count')];
  const color = oldValue?.color || MapSingleColor;
  const colorList: PureColor[] = [];

  const dataSource = names.map((name, index: number) => {
    if (oldValue) {
      const { colors: oldColors, dataSource: oldData } = oldValue;
      const oldItem = oldData![index];
      if (oldItem) {
        colorList.push(oldColors![index]);
        return {
          name,
          data: [...oldItem.data],
        };
      }
    }

    const value = DEFAULT_MAP_VALUES[index % DEFAULT_MAP_VALUES.length];
    colorList.push(DEFAULT_MAP_COLORS[index % DEFAULT_MAP_COLORS.length]);
    return {
      name,
      data: xAxis?.map((_axis, i) => {
        return i === 0 ? value : value * 2;
      }),
    };
  });
  return {
    xAxis: xAxis,
    yAxis: [],
    dataSource,
    colors: colorList,
    color: color,
  };
}

/**
 * 区域切换，数据从mapCharts里面获取
 * @param comp
 * @param propertyName
 * @param newValue
 */
function onPropertyUpdate(comp: UIComponent, propertyName: string, newValue: PropertyValue): ArtboardPatches | null {
  if (propertyName === MapChartPropertyName && window.mapCharts) {
    const region = (newValue as IMapChart).region;
    if ((comp.properties.mapChart as IMapChart).region !== region) {
      const oldValue = comp.value as IMapChartValue;
      const mapData = window.mapCharts.find((item: { region: string }) => item.region === region).data;
      const list = sortBy(
        get(mapData, 'features', []),
        (item) => get(item, 'properties.id') || get(item, 'id') || get(item, 'properties.adcode'),
      ).map((item) => get(item, 'properties.name'));

      return {
        do: { [comp.id]: [Ops.replace('./value', getDefaultValue(list, oldValue))] },
        undo: { [comp.id]: [Ops.replace('./value', oldValue)] },
      };
    }
  }
  return null;
}

export const MapChartConfig: IComponentItem = {
  type: CMapChart,
  name: i18n('resource.components.mapChart'),
  thumb: {
    spriteIconClass: SpriteThumb.MapChart.className,
    dragPosition: SpriteThumb.MapChart.position,
  },
  value: {
    type: ValueEditorType.MapChart,
  },
  editor: {
    onPropertyUpdate,
  },
  getDefaultData() {
    return {
      properties: getDefaultProperties(),
    };
  },
};

export function makeMapChart(id: string): IComponentData {
  return makeCommonComponent(id, CMapChart, {
    size: {
      width: 800,
      height: 600,
    },
    properties: getDefaultProperties(),
    value: getDefaultValue(DEFAULT_VALUE_NAMES),
  });
}
