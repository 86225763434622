import * as React from 'react';

import Tooltip from '../Tooltip';
import './index.scss';

import * as IconAxure from './svgs/20_doc_axure.svg';

import * as IconDoc from './svgs/20_doc_doc.svg';
import * as IconPRD from './svgs/20_doc_prd.svg';
import * as IconAi from './svgs/20_app_ai.svg';
import * as IconExcel from './svgs/20_doc_excel.svg';
import * as IconJustinmind from './svgs/20_doc_justinmind.svg';
import * as IconMockplus from './svgs/20_doc_mockplus.svg';
import * as IconPdf from './svgs/20_doc_pdf.svg';
import * as IconPic from './svgs/20_doc_pic.svg';
import * as IconPpt from './svgs/20_doc_ppt.svg';
import * as IconPS from './svgs/20_doc_PS.svg';
import * as IconTxt from './svgs/20_doc_txt.svg';
import * as IconWord from './svgs/20_doc_word.svg';
import * as IconXD from './svgs/20_doc_XD.svg';
import * as IconZip from './svgs/20_doc_zip.svg';
import * as IconAddfilePic from './svgs/80_addfile_pic.svg';
import * as IconAddfilePS from './svgs/80_addfile_PS.svg';
import * as IconAddfileSketch from './svgs/80_addfile_sketch.svg';
import * as IconAddfileXD from './svgs/80_addfile_XD.svg';

import * as IconAddfilePS30 from './svgs/30_addfile_PS.svg';
import * as IconAddfileSketch30 from './svgs/30_addfile_Sketch.svg';
import * as IconAddfileXD30 from './svgs/30_addfile_XD.svg';
import * as IconAddfileAxure30 from './svgs/30_addfile_Axure.svg';
import * as IconAddfileSketchGray from './svgs/30_addfile_sketch_gray.svg';
import * as IconAddfilePSGray from './svgs/30_addfile_PS_gray.svg';
import * as IconAddfileXDGray from './svgs/30_addfile_XD_gray.svg';
import * as IconAddfileAxureGray from './svgs/30_addfile_Axure_gray.svg';
import * as IconAddfileFigma30 from './svgs/30_addfile_Figma.svg';

import * as IconIDoc from './svgs/20_doc_idoc.svg';
import * as IconConfluence from './svgs/20_doc_confluence.svg';
import * as IconAppAxure from './svgs/20_app_axure.svg';
import * as IconAppMockplus from './svgs/20_app_mockplus.svg';
import classnames from 'classnames';
const AllColorfulIcons: {
  [name: string]: string;
} = {
  doc_axure: IconAxure,
  doc_ai: IconAi,
  doc_doc: IconDoc,
  doc_excel: IconExcel,
  doc_justinmind: IconJustinmind,
  doc_mockplus: IconMockplus,
  doc_pdf: IconPdf,
  doc_pic: IconPic,
  doc_ppt: IconPpt,
  doc_PS: IconPS,
  doc_txt: IconTxt,
  doc_word: IconWord,
  doc_XD: IconXD,
  doc_zip: IconZip,
  doc_idoc: IconIDoc,
  doc_confluence: IconConfluence,
  doc_prd: IconPRD,
  addfile_pic: IconAddfilePic,
  addfile_PS: IconAddfilePS,
  addfile_Sketch: IconAddfileSketch,
  addfile_XD: IconAddfileXD,

  addfile_PS_30: IconAddfilePS30,
  addfile_Sketch_30: IconAddfileSketch30,
  addfile_XD_30: IconAddfileXD30,
  addfile_Axure_30: IconAddfileAxure30,
  addfile_Sketch_gray: IconAddfileSketchGray,
  addfile_PS_gray: IconAddfilePSGray,
  addfile_XD_gray: IconAddfileXDGray,
  addfile_Axure_gray: IconAddfileAxureGray,
  addfile_Figma: IconAddfileFigma30,

  app_axure: IconAppAxure,
  app_mockplus: IconAppMockplus,
};

export interface IColorfulIconProps {
  cls: string;
  disabled?: boolean;
  width?: number | string;
  height?: number | string;
  tooltip?: string;
  className?: string;
  onClick?: React.MouseEventHandler;
}

const ColorfulIcon: React.FC<IColorfulIconProps> = (props: IColorfulIconProps) => {
  const { cls, tooltip, width, height, className, onClick, disabled } = props;
  const iconComp: any = (
    <img
      className={classnames(className, {
        disabled,
      })}
      width={width}
      height={height}
      src={AllColorfulIcons[cls]}
      alt={tooltip}
      onClick={onClick}
    />
  );

  if (tooltip) {
    return (
      <Tooltip text={tooltip} theme="small">
        {iconComp}
      </Tooltip>
    );
  }
  return iconComp;
};

ColorfulIcon.defaultProps = {
  cls: 'doc_axure',
  width: 20,
  height: 20,
  tooltip: '',
};

export default ColorfulIcon;
