// ref 是 component 中值/属性引用最基本的方式
// 用一个特定格式的字符串来表示引用
//
// `@<pattern>`
//
// 规则：
//   + @value 表示引用最近一个 sealed 组件的 value
//   + @properties.color 表示引用最近一个 sealed 组件的 color 属性
//
// 转义：
//   如果值中本身就包含 @，需要进行转义: @@ 表示 @
//

import { depthClone } from '@utils/globalUtils';

export function escape(text: string): string {
  return text.replace(/@/g, '@@');
}

export function unescape(text: string): string {
  return text.replace(/@@/g, '@');
}

// 是否是 ref
export function isRefKey(key: string): boolean {
  return key.startsWith('@') && !key.startsWith('@@') && key.length > 1;
}

export function getRefValue(key: string, data: any): any {
  if (!isRefKey(key)) {
    return null;
  }
  // 去掉前面的 @ 符号
  const parts = key.substr(1).split('.');
  return parts.reduce((acc, curr) => {
    return acc[curr];
  }, data);
}

/**
 * 判断一个对象中是否包含引用
 * @param value
 * @returns {boolean}
 */
export function isRefValue(value: any): boolean {
  if (typeof value === 'object') {
    return Object.keys(value).some(key => isRefValue(value[key]));
  } else if (typeof value === 'string') {
    return isRefKey(value);
  }
  return false;
}

/**
 * 从源数据合并引用数据到目标
 * @param from
 * @param ref
 * @returns {any}
 */
export function mergeRefValue(from: any, ref: any) {
  if (typeof ref === 'object') {
    const keys = Object.keys(ref);
    const result = depthClone(ref);
    keys.forEach(key => {
      const val = ref[key];
      if (isRefValue(val)) {
        result[key] = getRefValue(val, from);
      }
    });
    return result;
  } else if (typeof ref === 'string') {
    return getRefValue(ref, from);
  }
  return ref;
}
