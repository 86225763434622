import { IMakeRegForFilter, getCssRules } from './getCssRules';
import { styleWrapper } from '../util/styleUtil';

export const getStyleByNames = (names: string[], makeReg?: IMakeRegForFilter): string => {
  const style = [getStyleByNamesFromDocument(names, makeReg), fixComponentStyle(names)].join('\n');
  return styleWrapper(style);
};

/**
 * 组件样式修正
 * @param names
 */
export const fixComponentStyle = (names: string[]): string => {
  const list: string[] = [];
  // 内容面板
  if (names.find((t) => /lib-comp-content-panel/.test(t))) {
    list.push(`.lib-comp-content-panel.preview > .component{ display: block;}`);
  }
  // 图标
  if (names.find((t) => /lib-comp-icon/.test(t))) {
    list.push(`
    .component-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    `);
  }

  return list.join('\n');
};

/**
 * 去掉混淆样式
 * @param style
 */
const clearConfusedStyle = (style: string) => {
  // 背影颜色
  return style.replace(/body[^{}]*?{[^{}]*?background-color:[^{}]*?}/gi, '');
};
/**
 * 获取文档中的样式
 * @param name
 */
export const getStyleByNamesFromDocument = (names: string[], makeReg?: IMakeRegForFilter): string => {
  let style = getCssRules(names, makeReg)
    .map((t) => t.cssText)
    .join('\n');

  // 去掉混淆样式
  style = clearConfusedStyle(style);
  return style;
};
