import { ISaveFileOptions, OuputType } from '../type';
import { globalStatistics } from '../util/statisticsUtil';
import { getScales } from '../util/filenameUtil';
import { PackImages } from './packImages';
import { httpCach } from '../util/httpCachUtil';
import { selectComponentFilter } from '../util/filterUtil';
import { scheduler } from '../task';
import { ParseComponentProcessor } from './parseComponentProcessor';
import { exceptionCollector } from '../exception';
import { UIArtboard, UIFragment } from '@/editor/comps';
import { uniqueName } from '../util/uniqueName';

/**
 * 导出任务
 */
export const runExportTask = (options: ISaveFileOptions) => {
  const {
    components,
    doc,
    app,
    exportFormats,
    isOutputAllPages,
    renderBlankFilter,
    mobileType,
    onFinished,
    onProgress,
    exportMainArtboardsOnly,
    ids,
    ouputType = OuputType.File,
  } = options;
  if (!exportFormats) {
    return;
  }
  // 开始统计
  globalStatistics.begin();
  // 异常信息收集
  exceptionCollector.reset();
  // 文件名唯一
  uniqueName.reset();

  // 多倍率导出
  const scales: number[] = getScales(exportFormats);
  // 隐藏、热区、透明度过滤掉不要导出
  let exported = components.filter(selectComponentFilter);

  // 只导出主画板
  if (exportMainArtboardsOnly) {
    exported = exported.filter((t) => {
      if (t instanceof UIArtboard || t instanceof UIFragment) {
        return t.type === 'main';
      }
      return false;
    });
  } else {
    // 包含主画板
    if (exported.find((t) => t.type === 'main')) {
      exported = exported.filter((t) => {
        if (t instanceof UIArtboard || t instanceof UIFragment) {
          // 排除隐藏的内容面板
          return !t.$data.ownerID;
        }
        return true;
      });
    }
  }

  // 测试数据
  // if (window.debug) {
  //   exported = fakeData(components, 100);
  // }

  let exportedCount = exported.length;
  window.debug && console.log('exportedCount' + exportedCount);
  // 打包图片
  const packImages = new PackImages({
    components: exported,
    doc,
    app,
    exportFormats,
    isOutputAllPages,
    ids,
  });

  const onCompleate = () => {
    // 异常信息处理
    const onError = (e: string[]) => {
      onProgress && onProgress(100, e);
    };

    // 正常结束
    const onSuccess = () => {
      onProgress && onProgress(100);
    };

    if (ouputType === 'file') {
      // 保存文件
      packImages.saveAs(onSuccess, onError);
    }

    // 清空缓存
    httpCach.clear();
    globalStatistics.report();
    // 结束
    onFinished && onFinished(packImages.files);
  };

  // 解析组件过程
  const parser = new ParseComponentProcessor({
    total: exportedCount,
    scales,
    onSuccess: async (options) => {
      await packImages.addSvg(options);
    },
    doc,
    app,
    renderBlankFilter,
    mobileType,
  });

  // 队列管理
  scheduler.reset();
  exported.forEach((t, index) => {
    scheduler.push(async (done) => {
      await parser.exec(t);
      //   进度条动态
      if (onProgress) {
        // const progress = Math.ceil((100 * (index + 1)) / exportedCount);
        // 最后的保存过程算1
        // 最大99 ，到不了100 , 100才表示结束
        const progress = Math.floor((100 * (index + 1)) / (1 + exportedCount));
        onProgress(progress);
      }
      done();
    });
  });

  scheduler.continue(() => {
    window.debug && console.log('fininshed');
    onCompleate();
  });
};
