import { IDSGroup } from '@fbs/rp/models/ds/group';
import { axDelete, patch, post, put } from '../helper';


export async function addGroup(libID: string, groupName: string): Promise<IDSGroup> {
  return await put(`/ds/lib/${libID}/group`, { name: groupName });
}

export async function renameGroup(libID: string, groupID: string, name: string): Promise<IDSGroup> {
  return await patch(`/ds/lib/${libID}/group/${groupID}`, { name });
}

export async function moveGroup(libID: string, groupID: string, newIndex: number): Promise<any> {
  return await post(`/ds/lib/${libID}/group/move/${groupID}`, { index: newIndex });
}

export async function removeGroup(libID: string, grouID: string): Promise<any> {
  return axDelete(`/ds/lib/${libID}/group/${grouID}`);
}