import { blobToBase64 } from './blobUtil';
import { httpCach } from './httpCachUtil';

export const fetchUrl = async (url: string): Promise<string> => {
  if (!isHttpUrl(url)) {
    console.log(url);
    return Promise.resolve(url);
  }
  const res = await window.fetch(url);
  const blob = await res.blob();
  return blobToBase64(blob);
};

export function isHttpUrl(str: string) {
  return /http(s)?:\/\//.test(str);
}
/**
 * 处理因为特殊字符导致的编码原因
 * @param {*} char 字符串
 * @returns
 */
export const encodeSpecialChar = (char: string) => {
  const encodeArr = [
    {
      code: '%',
      encode: '%25',
    },
    {
      code: '?',
      encode: '%3F',
    },
    {
      code: '#',
      encode: '%23',
    },
    {
      code: '&',
      encode: '%26',
    },
    {
      code: '=',
      encode: '%3D',
    },
  ];
  return char.replace(/[%?#&=]/g, ($: string) => {
    for (const k of encodeArr) {
      if (k.code === $) {
        return k.encode;
      }
    }
    return '';
  });
};

/**
 * http https混用会有图片跨域问题
 * 异常处理
 * Uncaught (in promise) DOMException: The URI is malformed.
 * @param url
 * @returns
 */
export function getAndEncode(url: string): Promise<string> {
  // 字体下载超时60s，不下载
  const TIMEOUT = 60000;
  if (!isHttpUrl(url)) {
    return Promise.resolve('');
  }

  const content = httpCach.load(url);
  if (content) {
    return content; // Promise.resolve(content);
  }

  try {
    const result: Promise<string> = new Promise(function (resolve) {
      const request = new XMLHttpRequest();
      request.onreadystatechange = done;
      request.ontimeout = timeout;
      request.responseType = 'blob';
      request.timeout = TIMEOUT;
      request.open('GET', url, true);
      request.send();
      let placeholder: any;
      function done() {
        if (request.readyState !== 4) return;

        if (request.status !== 200) {
          if (placeholder) {
            resolve(placeholder);
          } else {
            fail('cannot fetch resource: ' + url + ', status: ' + request.status);
          }
          return;
        }
        const encoder: any = new FileReader();
        encoder.onloadend = function () {
          const content = encoder.result.split(/,/)[1];
          resolve(content);
        };
        encoder.readAsDataURL(request.response);
      }

      function timeout() {
        if (placeholder) {
          resolve(placeholder);
        } else {
          fail('timeout of ' + TIMEOUT + 'ms occured while fetching resource: ' + url);
        }
      }

      function fail(message: string) {
        console.error(message);
        resolve('');
      }
    });

    // 缓存
    httpCach.save(url, result);

    return result;
  } catch (e) {
    console.error(e);
    return Promise.resolve('');
  }
}
