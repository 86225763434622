import { cloneDeep } from 'lodash';
import Doc from '@/editor/document';
import { UIFragment, UIComponent, UIContentPanelV2Component, UIContainerComponent } from '@editor/comps';
import { DynamicEditInfo } from '@editor/comps/resizeHelper';
import { IPosition, ISize } from '@fbs/common/models/common';
import { IComponentData } from '@fbs/rp/models/component';
import { CContentPanel, CContentPanelV2 } from '@libs/constants';
import { makeUIComponent } from '@editor/comps/factory';

export const contenPanelLayoutLineLimit = 3;
export const contenPanelLayoutPadding = 64;
export const contenPanelLayoutPaddingCenter = 32;

export const setArtboardSyncLayoutPatch = (
  artboardsFragments: UIFragment[],
  options: {
    layoutLine: number;
    uniDynamicInfo?: DynamicEditInfo;
    clearDynamic?: boolean;
  },
) => {
  if (!artboardsFragments.length) {
    return;
  }
  const { layoutLine, uniDynamicInfo, clearDynamic } = options;
  let lastArtboardPositionSize: { position: IPosition; size: ISize };
  //如果存在统一的属性
  if (uniDynamicInfo) {
    artboardsFragments.forEach((artboards) => (artboards.dynamicInfo = uniDynamicInfo));
  }
  const metaCollectionPatch = artboardsFragments.map((artboard, index) => {
    const currentArtboard = artboardsFragments[index % layoutLine];
    const size = cloneDeep(currentArtboard.size);
    const position = cloneDeep(currentArtboard.position);
    if (!lastArtboardPositionSize) {
      lastArtboardPositionSize = { position, size };
    } else {
      const { position: lastPosition, size: lastSize } = lastArtboardPositionSize;
      const lastPositionRightX = lastPosition.x + lastSize.width;
      const lastPositionRightY = index % layoutLine === 0 ? lastPosition.y + lastSize.height : lastPosition.y;
      //修正间距 x轴
      if (
        index % layoutLine !== 0 /*首位不计算*/ &&
        index < layoutLine /*只计算第一行X轴*/ &&
        position.x - lastPositionRightX !== contenPanelLayoutPadding
      ) {
        if (position.x - lastPositionRightX >= 0) {
          const countPadding = contenPanelLayoutPadding - (position.x - lastPositionRightX);
          //间距过小
          position.x =
            position.x - lastPositionRightX - contenPanelLayoutPadding >= 0
              ? lastPositionRightX + contenPanelLayoutPadding
              : lastPositionRightX +
                (countPadding > 0 && countPadding < contenPanelLayoutPadding ? contenPanelLayoutPadding : countPadding);
        } else {
          //间距过大
          position.x = lastPositionRightX + contenPanelLayoutPadding;
        }
      }
      //修改间距 Y轴
      if (index + 1 > layoutLine /*首行不计算*/ && position.y - lastPositionRightY !== contenPanelLayoutPadding) {
        if (position.y - lastPositionRightY >= 0) {
          //间距过小
          position.y =
            position.y - lastPositionRightY - contenPanelLayoutPadding >= 0
              ? lastPositionRightY + contenPanelLayoutPadding
              : lastPositionRightY + (contenPanelLayoutPadding - (position.y - lastPositionRightY));
        } else {
          //间距过大
          position.y =
            index % layoutLine == 0 /*首位计算*/ ? lastPositionRightY + contenPanelLayoutPadding : lastPositionRightY;
        }
      } else if (index < layoutLine && position.y - lastPositionRightY > 0 /*首行存在删除的情况*/) {
        position.y = lastPositionRightY;
      }
    }
    const meta = { position, size };
    //重新设置动态数据，用于后面复用
    artboard.dynamicInfo = meta;
    //记录当前
    lastArtboardPositionSize = meta;
    return {
      meta,
      artboardID: artboard.artboardID,
    };
  });
  //清空动态计算
  if (clearDynamic) {
    artboardsFragments.forEach((artboard) => (artboard.dynamicInfo = {}));
  }
  return metaCollectionPatch;
};

export const checkContainContentPanel = (componentData: IComponentData[]): boolean => {
  return componentData.some((comp) => {
    if (comp.type === CContentPanel) {
      return true;
    }
    if (comp.components && comp.components.length) {
      return checkContainContentPanel(comp.components);
    }
    return false;
  });
};

export const checkContainContentPanelV2 = (componentData: IComponentData[]): boolean => {
  return componentData.some((comp) => {
    if (comp.type === CContentPanelV2) {
      return true;
    }
    if (comp.components && comp.components.length) {
      return checkContainContentPanelV2(comp.components);
    }
    return false;
  });
};

export const getContentPanelRelationFragments = (fragments: UIFragment[], doc: Doc): UIFragment[] => {
  let contentPanelRelatingFragments: UIFragment[] = [];
  fragments.forEach((fragment) => {
    fragment.components.forEach((comp: UIComponent) => {
      contentPanelRelatingFragments = contentPanelRelatingFragments.concat(getCompIsContentPanleFragments(comp, doc));
    });
  });
  return contentPanelRelatingFragments;
};

export const getCompIsContentPanleFragments = (comp: UIComponent, doc: Doc): UIFragment[] => {
  if (comp.type === CContentPanelV2) {
    return getCompContentPanleFragments(comp as UIContentPanelV2Component, doc);
  }
  if (comp instanceof UIContainerComponent) {
    return getCompChildrenContentPanleFragments(comp as UIContainerComponent, doc);
  }
  return [];
};

export const getCompContentPanleFragments = (comp: UIContentPanelV2Component, doc: Doc): UIFragment[] => {
  return doc.artboardsFragments.filter((artboard) => artboard.$data.ownerID && artboard.$data.ownerID === comp.id);
};

export const getCompChildrenContentPanleFragments = (comp: UIContainerComponent, doc: Doc): UIFragment[] => {
  let fragments: UIFragment[] = [];
  comp.components.forEach((comp) => {
    if (comp.type === CContentPanelV2) {
      fragments = fragments.concat(getCompContentPanleFragments(comp as UIContentPanelV2Component, doc));
      return;
    }
    if (comp instanceof UIContainerComponent) {
      fragments = fragments.concat(getCompChildrenContentPanleFragments(comp as UIContainerComponent, doc));
      return;
    }
  });
  return fragments;
};

export const getFragmentShouldRComponents = (comp: UIComponent) => {
  if (comp.type !== CContentPanelV2) {
    return [];
  }
  return (comp as UIContentPanelV2Component).components.map((panel) => {
    //替换子元素的内容为辅助画板
    const fragmentId = panel.value;
    const matchFragment = comp.document?.artboardsFragments.find(
      (fragment) => fragment.$data.ownerID && fragment.$data.ownerID === comp.id && fragment.artboardID === fragmentId,
    );
    if (matchFragment && matchFragment.isContainer) {
      //保留panelcomp,将匹配的matchFragment填充过去，防止丢失selectd next-comp样式等的选中状态
      const renderChildren = makeUIComponent(panel.toJSON()) as UIContainerComponent;
      renderChildren.components = [...(matchFragment as UIContainerComponent).components];
      return renderChildren;
    }
    return panel;
  });
};
