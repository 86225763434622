import * as React from 'react';

import i18n from '../../i18n';

import './index.scss';

interface IEmptyTipProps {
  searchMode?: boolean;
  top?: number;
  height?: string | number;
  minHeight?: string | number;
  emptyPicture?: string;
  searchEmptyPicture?: string;
  emptyTip?: string;
  searchEmptyTip?: string;
}

const EmptyTip: React.FC<IEmptyTipProps> = (props: IEmptyTipProps) => {
  const { searchMode, top, height, minHeight, emptyPicture, searchEmptyPicture, emptyTip, searchEmptyTip } = props;
  if (searchMode) {
    return (
      <div className="c-empty-box" style={{ top, height, minHeight }}>
        <div className="empty-container">
          <img src={searchEmptyPicture} alt="empty.png" />
          <div className="tip">{searchEmptyTip || i18n('emptyTip.searchEmptyTip')}</div>
        </div>
      </div>
    );
  }
  return (
    <div className="c-empty-box" style={{ top, height, minHeight }}>
      <div className="empty-container">
        <img src={emptyPicture} alt="empty.png" />
        <div className="tip">{emptyTip || i18n('emptyTip.emptyTip')}</div>
      </div>
    </div>
  );
};

EmptyTip.defaultProps = {
  searchMode: false,
  minHeight: 600,
  height: 'auto',
  emptyPicture: '',
  searchEmptyPicture: '',
};

export default EmptyTip;
