import { IPathOptions } from '../type';
import { toSVGTransform } from './makeTransform';
import { makeTransform } from './makeTransform';

export const makePath = (d?: string, options?: IPathOptions): string => {
  if (!d) {
    return '';
  }
  const { stroke = '', strokeWidth = '', fill, style, matrix, position, rotate, isRoot, size, outerBounds, offset } =
    options ?? {};
  let transform = '';
  if (matrix) {
    transform = toSVGTransform(matrix);
  } else {
    transform = makeTransform({ position, rotate, size, isRoot, outerBounds, offset });
  }

  return `<path d="${d}" ${fill}  ${stroke} stroke-width="${strokeWidth}" ${transform} ${style}/>`;
};
