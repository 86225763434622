import { Middleware } from 'redux';
import { IOType, PageIOPatches, PageIOSelect, PageIOSelectType } from '@/fbs/rp/models/io';
import INode from '@/fbs/rp/models/node';
import { EditorManager } from '@/managers/pageArtboardManager';
import { IMainState } from '../webTypes';
import { ActionType } from '../types';
import { IAppState } from './reducers';

const updateOtherSelectedComponents = (app: IAppState) => {
  app.coopersSelected.forEach((item) => {
    const { appID, nodeID, sessionID, ids, type } = item;
    if (app.appID !== appID) {
      return false;
    }
    const id = type === PageIOSelectType.Symbol ? app.selectedNodeID : nodeID;
    const coreEditor = EditorManager.getCoreEditor(id);
    if (!coreEditor) {
      return false;
    }
    coreEditor?.updateOtherSelectedComponents(sessionID, ids);
  });
};

export const appMiddleware: Middleware<{}, IMainState> = (storeApi) => (next) => (action) => {
  // side effect
  switch (action.type) {
    case ActionType.App_LoadArtboards: {
      const { forceUpdate } = action.payload;
      if (!forceUpdate) {
        const cache = EditorManager.getCoreEditor(action.payload.pageID);
        if (cache) {
          cache.reload();
        }
      }
      break;
    }

    default:
      break;
  }

  let result = next(action);

  // side effect
  const { app } = storeApi.getState();
  switch (action.type) {
    case ActionType.App_SelectNode: {
      // app.coreEditor?.clearSelected();  TODO:使非当前页面所有组件为选中状态——暂缓需求 （操作这个后需要发送一个page-select消息）
      app.coreEditor?.reload();
      if (app.coreEditor) {
        updateOtherSelectedComponents(app);
      }
      break;
    }

    case ActionType.App_PatchNode: {
      const payload = action.payload as INode;
      if (payload.type === 'page') {
        EditorManager.setNodeData(payload._id, payload);
      }
      break;
    }

    // 页面加载，仅一次 - 此处是在dispatch数据更新后（next）的副作用
    case ActionType.App_LoadArtboards: {
      const { app } = storeApi.getState();
      updateOtherSelectedComponents(app);
      break;
    }

    case ActionType.APP_RefreshPage: {
      app.coreEditor?.reload();
      break;
    }

    // 更新节点结构
    case ActionType.App_UpdateNodes: {
      app.coreEditor?.clearSelected();
      break;
    }

    case ActionType.App_Tree_HidePage: {
      app.coreEditor?.clearSelected();
      break;
    }

    case ActionType.App_ArtboardSelectSync: {
      const { appID, nodeID, selected } = action.payload;
      // 收到的更新已经不是当前 app
      if (app.appID !== appID) {
        break;
      }
      const coreEditor = EditorManager.getCoreEditor(nodeID);
      coreEditor?.syncOtherSelectedComponents(selected);
      break;
    }

    case ActionType.App_KillCoopers: {
      const { sessionIDs } = action.payload;
      sessionIDs.forEach((sid: string) => {
        app.coreEditor?.updateOtherSelectedComponents(sid, []);
      });
      break;
    }

    // 接收到其他人的锁信息
    case ActionType.App_CoopersSelectedIds: {
      const { appID, nodeID, sessionID, ids, type } = action.payload as PageIOSelect;
      // 收到的更新已经不是当前 app
      if (app.appID !== appID) {
        break;
      }
      const id = type === PageIOSelectType.Symbol ? app.selectedNodeID : nodeID;
      const coreEditor = EditorManager.getCoreEditor(id);
      if (!coreEditor) {
        break;
      }
      coreEditor.updateOtherSelectedComponents(sessionID, ids);
      break;
    }

    case ActionType.IO_UPDATE: {
      const { type, payload } = action.payload;
      switch (type) {
        // case IOType.UpdateSymbol:{}
        case IOType.PagePatches: {
          const { appID, nodeID, patches, sessionID } = payload as PageIOPatches;
          // 收到的更新已经不是当前 app
          if (app.appID !== appID) {
            break;
          }
          const coreEditor = EditorManager.getCoreEditor(nodeID);
          if (!coreEditor) {
            break;
          }
          // FIXME Matt 排除不必要的patch
          const keys = Object.keys(patches);
          let len = keys.length;
          keys.forEach((id) => {
            const opt = patches[id];
            const ks = Object.keys(opt);
            let l = ks.length;
            ks.forEach((key) => {
              if (!opt[key].length) {
                delete opt[key];
                l--;
              }
            });
            if (l === 0) {
              delete patches[id];
              len--;
            }
          });

          if (len) {
            coreEditor.otherCooperPagePatchFlag = sessionID !== coreEditor.currentSessionID;

            coreEditor.patch(patches);

            if (!coreEditor.otherCooperPagePatchFlag) {
              coreEditor.autoReselectActiveArtboard(patches);
            }
          }
          break;
        }

        case IOType.NodeUpdate:
          // no side effect
          break;
      }

      break;
    }
  }

  return result;
};
