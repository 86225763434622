import { isUndefined, isBoolean } from 'lodash';

import { enumToArray } from '@utils/enumUtils';
import { depthClone } from '@/utils/globalUtils';

/**
 * 判断是否运行在移动设备上
 * @returns {boolean}
 */
import {
  IMoveCommandParams,
  IResizeCommandParams,
  IRotateCommandParams,
  IScaleCommandParams,
  IVisibleCommandParams,
} from '@fbs/rp/models/interactions';
import { MobileType, PreviewUrlSearchKey } from '@fbs/rp/utils/preview';
import { UIComponent } from '@editor/comps';
import { ISize } from '@fbs/common/models/common';
import { IPosition } from '@fbs/idoc/models/common';
import { adapterPhoneShell, isValidateOption } from './shellHelper';
import INode, { INodeWithChildren } from '@/fbs/rp/models/node';
import { TreeItemData } from '@/dsm';
import { NodeState, isPageHidden } from '@/fbs/rp/utils/app';
import { getOfflineDemoData } from '@/apis/offlineDemo/data';
import { IAppF, IAppWithNestedChildren } from '@/fbs/rp/models/app';
import { ControlPanelState } from '@/fbs/rp/models/preview';
import { Role, MemberPermission, IAppType } from '@/fbs/teamManagement';
import { IAppMembersInfo } from '@/fbs/idoc/models/app';
import AppOptions from '@helpers/appOptions';
import { parseUrlSearch } from '@/utils/urlUtils';
import i18n from '@/i18n';
import appOptions from '@helpers/appOptions';

// 原有的分享时可设置的项
interface IPreviewShareOption {
  showLinkAreaWhenHovered?: boolean;
  alwaysShowLinkArea?: boolean;
  mobileType?: MobileType;
  noBoundary?: boolean;
  showRemarkTag?: boolean;
}

export interface IPreviewOption extends IPreviewShareOption {
  // 演示界面调整时新加的，分享时不可设置的项
  fullWidth?: boolean; // 全屏
  scale?: number; // 视图缩放比
  autoScreen?: boolean; // 适应屏幕
  showNavigationBar?: boolean; // 是否自动隐藏导航条
}

export interface IShareOption extends IPreviewShareOption {
  controlPanelState?: ControlPanelState;
  remarkPanelState?: ControlPanelState;
  showNavigationBar?: boolean;
  autoScreen?: boolean;
}

export const ControlPanelSelectOptions = [
  {
    id: ControlPanelState.Expand,
    text: i18n('preview.controlPanelState.expand'),
  },
  {
    id: ControlPanelState.Collapse,
    text: i18n('preview.controlPanelState.collapse'),
  },
  {
    id: ControlPanelState.Hide,
    text: i18n('preview.controlPanelState.hide'),
  },
];

enum ProjectType {
  PRIVATE = 'private', // 私有项目
  INTERNAL = 'internal', // 团队项目
}

/**
 * 将对象值中的对应字符串转为布尔值，数字
 */
function convertSearchOptData(oldData: { [key: string]: any }): { [key: string]: boolean | number | string } {
  const copy = depthClone(oldData);
  Object.keys(copy).forEach((key) => {
    const oldValue = oldData[key].toLowerCase();
    const num = Number(oldValue);
    if (oldValue === '1') {
      copy[key] = true;
    } else if (oldValue === '0') {
      copy[key] = false;
    } else if (!isNaN(num)) {
      copy[key] = num;
    }
  });
  return copy;
}

export function getUrlSearchObj(searchStr?: string) {
  const obj = parseUrlSearch(searchStr);
  return convertSearchOptData(obj);
}

export const SearchParamConfig = {
  showNavigationBar: PreviewUrlSearchKey.ShowNavigationBar,
  alwaysShowLinkArea: PreviewUrlSearchKey.AlwayshowLinkArea,
  showLinkAreaWhenHovered: PreviewUrlSearchKey.ShowLinkAreaWhenMouseHover,
  noBoundary: PreviewUrlSearchKey.ShowOutside,
  showRemarkTag: PreviewUrlSearchKey.ShowRemarkTag,
  controlPanelState: PreviewUrlSearchKey.ControlPanelState,
  remarkPanelState: PreviewUrlSearchKey.RemarkPanelState,
  mobileType: PreviewUrlSearchKey.DeviceType,
  autoScreen: PreviewUrlSearchKey.FitToScreen,
};

/**
 * 将演示分享配置转化为search字符串
 */
export function shareOptionToSearchStr(opt: IShareOption, excludeParamKeys: string[] = []) {
  const keys = Object.keys(opt).filter((k) => !isUndefined(opt[k as keyof IShareOption])) as Array<keyof IShareOption>;
  let searchStr = '';
  const transData = (value: any) => {
    if (isBoolean(value)) {
      return value ? 1 : 0;
    } else {
      return value;
    }
  };

  const fullSearch = getUrlSearchObj();

  const searchArray = keys
    .map((k) => {
      const value: any = opt[k];
      const queryName = SearchParamConfig[k];
      if (queryName) {
        delete fullSearch[queryName];
        return `${queryName}=${transData(value)}`;
      }
      return undefined;
    })
    .filter((item) => !isUndefined(item));

  if (searchArray.length) {
    searchStr = `${searchArray.join('&')}`;
  }

  const otherSearchValue = Object.keys(fullSearch)
    .filter((key) => !excludeParamKeys.includes(key))
    .map((key) => `${key}=${fullSearch[key] || ''}`)
    .join('&');
  if (otherSearchValue) {
    searchStr = `${searchStr}${searchStr.length ? '&' : ''}${otherSearchValue}`;
  }
  return searchStr;
}

/**
 * 获取演示配置
 */
export function getDefaultOption(app: IAppF): IPreviewOption {
  const { appType, size, _id: appID } = app;
  let shellType: MobileType = MobileType.None;
  switch (appType) {
    case 'pad':
      shellType = MobileType.Pad;
      break;
    case 'phone':
      shellType = adapterPhoneShell(size.height / size.width);
      break;
    case 'watch':
      shellType = MobileType.Apple_Watch_45mm;
      break;
    case 'vehicle':
      shellType = MobileType.Vehicle;
      break;
    default:
      shellType = MobileType.None;
      break;
  }
  const isMobile = ['pad', 'phone', 'watch', 'vehicle'].includes(appType);
  const option: IPreviewOption = {
    showLinkAreaWhenHovered: false,
    alwaysShowLinkArea: false,
    autoScreen: isMobile,
    mobileType: shellType,
    noBoundary: !isMobile,
    showRemarkTag: true,
    showNavigationBar: true,
  };
  const offlineDemoOpt = getPreviewOptFromOfflineDemo();
  const searchOpt = getPreviewOptFromUrl();
  const cacheOptions = AppOptions.previewOption || [];
  let newOption: IPreviewOption = option;
  if (!cacheOptions.length) {
    newOption = Object.assign({}, option, searchOpt, offlineDemoOpt);
    AppOptions.previewOption = [
      {
        appID: appID,
        option: newOption,
      },
    ];
  } else {
    const cacheOption = cacheOptions.find((item) => {
      return item.appID === appID;
    });
    const cacheShellType = cacheOption?.option?.mobileType || '';
    const newShellType = mobileTypeCompatibilityProcessing(cacheShellType, shellType);

    newOption = Object.assign({}, option, cacheOption?.option, { mobileType: newShellType }, searchOpt, offlineDemoOpt);
  }
  if (!['phone', 'pad', 'watch', 'vehicle'].includes(appType)) {
    delete newOption.mobileType;
  }

  if (isStandalonePreview) {
    newOption.noBoundary = false;
  }

  // 有效性检查
  if (!isValidateOption(newOption, appType)) {
    // console.log(newOption,appType)
    newOption.mobileType = MobileType.None;
  }
  return newOption;
}

// 获取下载离线演示包默认配置
export function getDownloadOfflineDemoOptions(app: IAppF) {
  const { appType, size } = app;
  let shellType: MobileType = MobileType.None;
  switch (appType) {
    case 'pad':
      shellType = MobileType.Pad;
      break;
    case 'phone':
      shellType = adapterPhoneShell(size.height / size.width);
      break;
    case 'watch':
      shellType = MobileType.Apple_Watch_45mm;
      break;
    case 'vehicle':
      shellType = MobileType.Vehicle;
      break;
    default:
      shellType = MobileType.None;
      break;
  }
  const isMobile = ['pad', 'phone', 'watch', 'vehicle'].includes(appType);
  const options = {
    mobileType: shellType,
    controlPanelState: ControlPanelState.Expand,
    remarkPanelState: ControlPanelState.Hide,
    showNavigationBar: true,
    showLinkAreaWhenHovered: false,
    alwaysShowLinkArea: false,
    noBoundary: !isMobile,
    showRemarkTag: true,
    autoScreen: isMobile,
  };
  const cacheOptions = AppOptions.offlinePreviewOptions || {};
  const res = { ...options, ...cacheOptions };
  if (!isMobile) {
    Reflect.deleteProperty(res, 'mobileType');
  }
  return res;
}

/**
 * 离线演示包配置
 */
function getPreviewOptFromOfflineDemo(): IPreviewOption {
  const config = getOfflineDemoData()?.config;
  if (!config) {
    return {};
  }
  return {
    showLinkAreaWhenHovered: config.showLinkAreaWhenHovered,
    alwaysShowLinkArea: config.alwaysShowLinkArea,
    autoScreen: config.autoScreen,
    mobileType: mobileTypeCompatibilityProcessing(config.mobileType),
    noBoundary: config.noBoundary,
    showRemarkTag: config.showRemarkTag,
    showNavigationBar: config.showNavigationBar,
  };
}

// 机型旧数据适配
export function mobileTypeCompatibilityProcessing(oldType: string, defaultValue = MobileType.None): MobileType {
  const assert = enumToArray(MobileType).includes(oldType);
  if (assert) return oldType as MobileType;

  const dataMap: { [key: string]: MobileType } = {
    android: MobileType.HUAWEI_P40,
  };

  return dataMap[oldType] ?? defaultValue;
}

/**
 * url中search获取演示配置
 */
export function getPreviewOptFromUrl(option?: { [key: string]: string | number | boolean }): IPreviewOption {
  const data = option ?? getUrlSearchObj();
  const opt: IPreviewOption = {};
  Object.keys(data).forEach((key) => {
    switch (key) {
      case PreviewUrlSearchKey.ShowOutside:
        opt.noBoundary = !!data[key];
        break;
      case PreviewUrlSearchKey.ShowRemarkTag:
        opt.showRemarkTag = !!data[key];
        break;
      case PreviewUrlSearchKey.DeviceType: {
        opt.mobileType = mobileTypeCompatibilityProcessing(data[key] as string);
        break;
      }
      case PreviewUrlSearchKey.AlwayshowLinkArea:
        opt.alwaysShowLinkArea = !!data[key];
        break;
      case PreviewUrlSearchKey.ShowLinkAreaWhenMouseHover:
        opt.showLinkAreaWhenHovered = !!data[key];
        break;
      case PreviewUrlSearchKey.ShowNavigationBar:
        opt.showNavigationBar = !!data[key];
        break;
      case PreviewUrlSearchKey.FitToScreen:
        opt.autoScreen = !!data[key];
        break;
      default:
        break;
    }
  });
  return opt;
}

/**
 * url判断：
 * 手机端进入(目前也包括飞书小程序进入)
 */
export const isMobileApp = window.location.href.includes('phoneUrl=true');

/**
 * 判断是否移动设备
 */
export const isMobileDevice = (): boolean => {
  const u = navigator.userAgent;
  return u.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i) !== null;
};

export function parserMoveCommand(component: UIComponent, params: IMoveCommandParams): IPosition {
  const { x, y, fromCurrent } = params;
  const { position } = component;

  let newX = position.x;
  let newY = position.y;
  if (fromCurrent) {
    newX += x;
    newY += y;
  } else {
    newX = x;
    newY = y;
  }
  return {
    x: newX,
    y: newY,
  };
}

export function parserResizeCommand(
  component: UIComponent,
  params: IResizeCommandParams,
): { size: ISize; position: IPosition } {
  const { size, position } = component;
  const { width, height, fromCurrent, transformOrigin } = params;
  let newW;
  let newH;
  if (fromCurrent) {
    newW = size.width + width;
    newH = size.height + height;
  } else {
    newW = width;
    newH = height;
  }
  let newX = position.x;
  let newY = position.y;
  const offsetWidth = newW - size.width;
  const offsetHeight = newH - size.height;
  let offsetTop = 0;
  let offsetLeft = 0;

  if (transformOrigin === 'center' || transformOrigin === 'top' || transformOrigin === 'bottom') {
    offsetLeft = -offsetWidth / 2;
  } else if (transformOrigin === 'right' || transformOrigin === 'topRight' || transformOrigin === 'bottomRight') {
    offsetLeft = -offsetWidth;
  }

  if (transformOrigin === 'left' || transformOrigin === 'right' || transformOrigin === 'center') {
    offsetTop = -offsetHeight / 2;
  } else if (transformOrigin === 'bottomLeft' || transformOrigin === 'bottom' || transformOrigin === 'bottomRight') {
    offsetTop = -offsetHeight;
  }

  newX += offsetLeft;
  newY += offsetTop;

  return {
    size: {
      width: newW,
      height: newH,
    },

    position: {
      x: newX,
      y: newY,
    },
  };
}

export function parserRotateCommand(component: UIComponent, params: IRotateCommandParams): number {
  const oldRotate = component.rotate || 0;
  const { rotate, fromCurrent } = params;
  let newRotate = rotate;
  if (fromCurrent) {
    newRotate = oldRotate + rotate;
  }
  return newRotate;
}

export function parserScaleCommand(
  component: UIComponent,
  params: IScaleCommandParams,
): { scale: { x: number; y: number }; position: IPosition } {
  const oldScale = component._scale;
  const { size, position } = component;
  const { x, y, fromCurrent, transformOrigin } = params;
  let newX = x;
  let newY = y;
  if (fromCurrent) {
    newX = (oldScale.x || 1) * x;
    newY = (oldScale.y || 1) * y;
  }
  let positionX = position.x;
  let positionY = position.y;
  let newW = size.width * newX;
  let newH = size.height * newY;
  const offsetWidth = newW - size.width * oldScale.x;
  const offsetHeight = newH - size.height * oldScale.y;

  let offsetTop = 0;
  let offsetLeft = 0;

  if (transformOrigin === 'center' || transformOrigin === 'top' || transformOrigin === 'bottom') {
    offsetLeft = -offsetWidth / 2;
  } else if (transformOrigin === 'left' || transformOrigin === 'topLeft' || transformOrigin === 'bottomLeft') {
    offsetLeft = 0;
  } else if (transformOrigin === 'right' || transformOrigin === 'topRight' || transformOrigin === 'bottomRight') {
    offsetLeft = -offsetWidth;
  }

  if (transformOrigin === 'left' || transformOrigin === 'right' || transformOrigin === 'center') {
    offsetTop = -offsetHeight / 2;
  } else if (transformOrigin === 'topLeft' || transformOrigin === 'top' || transformOrigin === 'topRight') {
    offsetTop = 0;
  } else if (transformOrigin === 'bottomLeft' || transformOrigin === 'bottom' || transformOrigin === 'bottomRight') {
    offsetTop = -offsetHeight;
  }

  positionX += offsetLeft;
  positionY += offsetTop;

  return {
    scale: {
      x: newX,
      y: newY,
    },
    position: {
      x: positionX,
      y: positionY,
    },
  };
}

/**
 * 获取当前成员是否是项目集中的成员
 * @param membersFromApp
 * @param appId
 * @param userInfoID
 */
function getIsAppMember(membersFromApp: IAppMembersInfo[], userInfoID?: number): boolean {
  if (!userInfoID) {
    return false;
  }

  if (!Array.isArray(membersFromApp)) {
    return false;
  }

  const membersIDs = membersFromApp.map((item) => item.id);
  return membersIDs.includes(userInfoID);
}

export const getIsHaveProjectPermission = (
  roleInTeam?: Role,
  app?: IAppWithNestedChildren | IAppF | null,
  userInfoID?: number,
  membersFromApp?: IAppMembersInfo[],
): boolean => {
  // 1. 拥有项目权限：1、用户为团队超级管理员；2、或项目为团队项目；3、或当前项目为私有项目，而用户在项目成员列表中；4、项目为私有项目，而用户在此项目所在的项目集的成员列表中
  if (!roleInTeam) {
    return false;
  }

  let isHaveProjectPermission: boolean = false;
  const { members, visibility } = app as IAppF;

  if (MemberPermission.isSuperAdmin(roleInTeam)) {
    isHaveProjectPermission = true;
  }

  if (visibility === ProjectType.INTERNAL) {
    isHaveProjectPermission = true;
  }

  if (visibility === ProjectType.PRIVATE && Array.isArray(members) && members.includes(Number(userInfoID))) {
    isHaveProjectPermission = true;
  }

  if (visibility === ProjectType.PRIVATE && membersFromApp && getIsAppMember(membersFromApp, userInfoID)) {
    isHaveProjectPermission = true;
  }

  // 2. 团队身份为成员、管理员或超级管理员
  const isMemberInTeam = MemberPermission.isEditor(roleInTeam);

  return isHaveProjectPermission && isMemberInTeam;
};

/**
 * 是否在演示可以看到隐藏的页面
 * 用户在团队中的身份为成员、管理员或超级管理员，协同人不行
 * 并且用户需拥有项目访问权限
 * 从cc单页模式跳转过来的不行
 */
export const shouldHiddenPageVisible = (
  roleInTeam?: Role,
  app?: IAppWithNestedChildren | IAppF | null,
  userInfoID?: number,
  membersFromApp?: IAppMembersInfo[],
): boolean => {
  const moduleID = new URLSearchParams(location.search).get('moduleID');
  if (moduleID) {
    return false;
  }
  return getIsHaveProjectPermission(roleInTeam, app, userInfoID, membersFromApp);
};

export function parserVisibleCommand(component: UIComponent, params: IVisibleCommandParams) {
  const hidden = component.hidden;
  const { state } = params;
  let newValue = hidden;
  switch (state) {
    case 'show':
      newValue = false;
      break;
    case 'hidden':
      newValue = true;
      break;
    default:
      newValue = !hidden;
      break;
  }
  return newValue;
}

// TODO 以下几个方法为其它应用内演示时要使用到的，不可清理掉

// 飞书内置浏览器（会话框点击链接进入）
export const isLarkBrowser = navigator.userAgent.includes('Lark') && window.location.href.includes('lark=true');

// 飞书小程序
export const isLarkApplets = window.location.href.includes('lark=true');

// 微信小程序
export const isWeChatApplets =
  navigator.userAgent.toLowerCase().match(/miniprogram/i) || window.__wxjs_environment === 'miniprogram';

// 百度小程序
export const isBaiduApplets = /swan\//.test(navigator.userAgent) || /^webswan-/.test(window.name);

// idoc手机端进入(目前也包括飞书小程序进入)
export const isPhoneIn = window.location.href.includes('phoneUrl=true');

// 是否是从演示页面进入的
// 这个仅用于判断首次进入的是演示页面，用意是将演示环境和编辑环境区分开来，并不代表当前实际是处于演示或者编辑
export const isStartFromPreview = ['/run', '/standalone'].some((item) => window.location.href.includes(item));

export const isStandalonePreview = window.location.href.includes('/standalone/rp');

export const isStandalone = window.location.href.includes('/standalone');

// 初始时有无控制面板
// NOTE: 此方法已经没在调用了
export const isStartWithControlPanel = window.location.href.includes(`${PreviewUrlSearchKey.ShowControllerPanel}=true`);

// 转换数据成RP的树
export function parseDataToRPTree(
  selectedID: string,
  nodes: INodeWithChildren[],
  option: {
    allCollapsed?: boolean;
    shouldHidePage?: boolean;
    showPageNumber?: boolean;
    filterCallback?: (TreeNode: INodeWithChildren) => Boolean;
  },
) {
  const { allCollapsed = false, shouldHidePage = false, showPageNumber = false, filterCallback } = option;

  const doParserNode = (
    nodes: INodeWithChildren[],
    parent?: TreeItemData<INodeWithChildren>,
  ): TreeItemData<INodeWithChildren>[] => {
    return nodes
      .filter((item) => {
        if (filterCallback) {
          return filterCallback(item);
        }
        return shouldHidePage ? !item.hidden : true;
      })
      .map((node, i) => {
        if (showPageNumber) {
          const n = i + 1;
          const parentNumber = parent?.data?.serialNumber;
          node.serialNumber = parentNumber ? `${parentNumber}.${n}` : `${n}`;
        }

        const treeItem: TreeItemData<INodeWithChildren> = {
          data: node,
          isLeaf: !node.children || !node.children.length,
          selected: node._id === selectedID,
          parent,
        };
        if (!treeItem.isLeaf) {
          treeItem.expand = !allCollapsed;
          treeItem.children = doParserNode((node as INodeWithChildren).children, treeItem);
          if (!treeItem.children.length) {
            treeItem.isLeaf = true;
          }
        }
        return treeItem;
      });
  };
  return doParserNode(nodes);
}

export function isNodeVisible(node: INode) {
  return !node.hidden && node.state !== NodeState.Deleted;
}

/**
 * 隐藏页面是否可以查看，判断权限
 */
export function couldPagePreview(
  page: INodeWithChildren | null | undefined,
  roleInTeam: Role | undefined,
  app?: IAppWithNestedChildren | IAppF | null,
  userInfoID?: number,
) {
  const isHasPreviewPermission = shouldHiddenPageVisible(roleInTeam, app, userInfoID);
  if (!page || (!isHasPreviewPermission && isPageHidden(page)) || page.state === NodeState.Deleted) {
    return false;
  }
  return true;
}

/**
 * 是否是移动端appType
 */
export function isMobileAppType(appType: string) {
  return ['pad', 'phone'].includes(appType);
}
export function isWatchAppType(appType: string) {
  return appType === IAppType.Watch;
}
export function isVehicleAppType(appType: string) {
  return appType === IAppType.Vehicle;
}

// 演示面板状态缓存
export function updatePreviewPanelCatch(
  appID: string,
  state: { [key in 'controlPanelState' | 'remarkPanelState']?: ControlPanelState },
) {
  const MAX_COUNT = 5;
  const { controlPanelState, remarkPanelState } = state;

  if (!controlPanelState && !remarkPanelState) return;

  const newCatch = appOptions.previewPanel;

  const app = newCatch.find((item) => item.appID === appID);

  const newState: { [key in 'controlPanelState' | 'remarkPanelState']?: boolean } = app?.option ?? {};
  if (controlPanelState) {
    newState.controlPanelState = controlPanelState === ControlPanelState.Expand;
  }
  if (remarkPanelState) {
    newState.remarkPanelState = remarkPanelState === ControlPanelState.Expand;
  }

  if (!app) {
    if (newCatch.length >= MAX_COUNT) newCatch.shift();
    newCatch.push({ appID, option: newState });
  } else {
    app.option = newState;
  }
  appOptions.previewPanel = newCatch;
}
