import { Dispatch } from 'redux';
import { batch } from 'react-redux';

import { depthClone } from '@utils/globalUtils';

import { IAppF, IAppWithNestedChildren } from '@fbs/rp/models/app';
import INode, { INodeWithChildren } from '@fbs/rp/models/node';
import { IFragmentAction, IPageAction } from '@fbs/rp/models/interactions';
import { findNodeByID, getFirstPageNodeOfTree, NodeState, parseNodesToTree } from '@fbs/rp/utils/app';
import { IUserInfo } from '@fbs/idoc/models/user';
import IArtboard from '@fbs/rp/models/artboard';
import { ITeams } from '@fbs/idoc/models/team';
import { CustomError, Role, ErrorCode as CustomErrorCode } from '@/fbs/teamManagement';

import apis from '@apis';

import { hasNoSign } from '@helpers/errorCodeHelper';
import { couldPagePreview } from '@helpers/previewHelper';
import { getQueryString } from '@helpers/urlHelper';
import snapshotManager from '@/managers/snapshotManager';
import { getOfflineDemoData } from '@apis/offlineDemo/data';
import { IIDocShareMsg } from '@/apis/app';
import { IMainState } from '@/store/webTypes';
import ErrorCode from '@consts/ErrorCode';
import Routers from '@/consts/router';
import { IPopularize } from '@/fbs/teamManagement/utils/popularize';
import { getWaterMarkContent } from '@/utils/waterMarkerUtils';
import { setAxiosMethodProxyInterceptor } from '@/apis/helper';

import Theater from '../../theater';
import { ActionsUnion, ActionType, createAction, appIsNotExits, noPermission, notFound } from '../types';
import { ISkipHistoryItem } from './reducers';
import { Actions as GlobalActions } from '../global/actions';
import { Actions as ShareAction } from '../share/actions';
import { Actions as AppAction } from '../app/actions';
import { GlobalThunkActions } from '../actions';

export interface IPreviewState {
  appID: string;
  app: IAppWithNestedChildren | null;
  currentTheater?: Theater;
  nextTheater?: Theater;
  actionEffect?: IPageAction;
  selectedPageID?: string;
  skipHistory?: ISkipHistoryItem[];
  landmark?: number;
  allNodes: INode[];
  message: string;
  msg: string;
  msgId: number;
  showMsg: boolean;
  isProjectExist: boolean;
  queryLinkId: string;
  linkID: string;
  waterMark?: string;
  iDocAppShareMsg?: IIDocShareMsg;
  popularizes: IPopularize[];
}

export interface IPageActionParam {
  nextPageID: string;
  nextArtBoards: IArtboard[];
  currentPageID: string;
  currentArtBoards: IArtboard[];
  actionEffect: IPageAction;
}

export const artboardList: {
  [pageID: string]: IArtboard[];
} = {};

export const Actions = {
  getApp: (app: IAppF & { appID: string }, nodes?: string[]) => createAction(ActionType.Preview_GetApp, { app, nodes }),
  getTeamInfo: (payload: ITeams) => createAction(ActionType.Share_GetTeamInfo, payload),
  loadArtBoard: (pageID: string, artboards: IArtboard[]) =>
    createAction(ActionType.Preview_LoadArtBoard, {
      pageID,
      artboards,
    }),
  reloadPage: (artboards: IArtboard[]) => createAction(ActionType.Preview_ReloadPage, artboards),
  loadPageAfter: () => createAction(ActionType.Preview_AfterSkipToPage),
  selectNode: (nodeID: string, artBoards: IArtboard[]) =>
    createAction(ActionType.Preview_SelectNode, { nodeID, artBoards }),
  exit: () => createAction(ActionType.Preview_Exit),
  beforeSkipToPage: (pageActionParam: IPageActionParam) =>
    createAction(ActionType.Preview_beforeSkipToPage, {
      pageActionParam,
    }),
  goBackward: (actionEffect: IPageAction | undefined) =>
    createAction(ActionType.Preview_goBackward, {
      actionEffect,
    }),
  goForward: () => createAction(ActionType.Preview_goForward),
  goHome: (homeID: string, homeArtBoards: IArtboard[]) =>
    createAction(ActionType.Preview_returnHome, {
      homeID,
      homeArtBoards,
    }),
  showFragment: (action: IFragmentAction) =>
    createAction(ActionType.Preview_showFragment, {
      action,
    }),

  showMessage: (message: string) =>
    createAction(ActionType.Preview_showMessage, {
      message,
    }),

  showMessageWithId: (message: string, id: number) =>
    createAction(ActionType.Preview_showMessageWithId, {
      message,
      id,
    }),

  hideMessage: () => createAction(ActionType.Preview_hideMessage),

  hideMessageWithId: (id: number) => createAction(ActionType.Preview_hideMessageWithId, id),

  toLogIn: (shareID: string, openPage?: string) =>
    createAction(ActionType.Preview_toLogIn, {
      shareID,
      openPage,
    }),
  pwdEnabled: (enabled: boolean) => createAction(ActionType.Preview_PwdEnabled, enabled),
  isProjectExist: (isExist: boolean) => createAction(ActionType.Preview_ProjectExist, isExist),
  setCurrLinkID: (linkID: string) => createAction(ActionType.Preview_setCurrLinkID, { linkID }),
  checkWaterMar: (waterMark?: string) => createAction(ActionType.Preview_ShowWater, waterMark),
  setIDocAppShareMsg: (iDocAppShareMsg: IIDocShareMsg) =>
    createAction(ActionType.Preview_SetIDocAppShareMsg, iDocAppShareMsg),
  getPopularizes: (data: IPopularize[]) => createAction(ActionType.Popularize_Loaded, data),
  setQueryLinkID: (linkID: string) => createAction(ActionType.Preview_SetQueryLinkId, { linkID }),
};

async function loadArtboards(pageID: string, prototypeID?: string) {
  let artBoards = artboardList[pageID];
  if (!artBoards) {
    artBoards = await apis.artboard.getAllPreviewArtboardsByNodeID(pageID, prototypeID);
    artboardList[pageID] = depthClone(artBoards);
  }
  return artBoards;
}

function findPageFromTreeNodes(tree: INodeWithChildren[], roleInTeam?: Role, app?: IAppF, userInfoID?: number) {
  return getFirstPageNodeOfTree(tree, (node) => {
    let _node: INodeWithChildren | undefined = node;
    while (_node) {
      if (_node.state === NodeState.Deleted) {
        return false;
      }
      _node = _node.parent;
    }
    return couldPagePreview(node, roleInTeam, app, userInfoID);
  });
}

function licenseErrorToPermissionPage(code: number): boolean {
  const teamPermissionRoute = `/permission/team?ps=${code}&redirect=${encodeURIComponent(location.href)}`;
  if ([CustomErrorCode.PDLicenseExpired, CustomErrorCode.PDLicenseNoRP].includes(code)) {
    window.location.href = `${Routers.routePrefix}${teamPermissionRoute}`;
    return true;
  }
  return false;
}

export const ThunkActions = {
  openPageWithFirstLoad: (
    app: IAppF & { appID: string },
    prototypeID?: string,
    roleInTeam?: Role,
    pageID?: string,
    userInfoID?: number,
    nodes?: string[],
  ) => (dispatch: Dispatch) => {
    dispatch(Actions.getApp(app, nodes));
    let firstPage: INodeWithChildren | null = null;
    const nodeTree = parseNodesToTree(app.children);
    if (pageID) {
      firstPage = findNodeByID(nodeTree, pageID);
    }

    if (firstPage?.type === 'folder') {
      firstPage = findPageFromTreeNodes([firstPage], roleInTeam, app, userInfoID);
    }

    if (!couldPagePreview(firstPage, roleInTeam, app, userInfoID)) {
      firstPage = findPageFromTreeNodes(nodeTree, roleInTeam, app, userInfoID);
    }
    if (firstPage) {
      ThunkActions.loadPage(firstPage, prototypeID)(dispatch);
    }
  },

  loadSnapshotPage: (
    app: IAppF & { appID: string },
    prototypeID?: string,
    roleInTeam?: Role,
    firstOpenPageID?: string,
    userInfoID?: number,
    nodes?: string[],
  ) => (dispatch: Dispatch) => {
    snapshotManager
      .loadAllUsedResource(app._id)
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        ThunkActions.openPageWithFirstLoad(app, prototypeID, roleInTeam, firstOpenPageID, userInfoID, nodes)(dispatch);
      });
  },

  getApp: (shareID: string, prototypeID?: string, iDocAppID?: string, openPage?: string, linkId?: string) => async (
    dispatch: Dispatch,
  ) => {
    try {
      // 根据 iDoc 对分享演示链接的策略变化而做的调整
      if (iDocAppID && prototypeID) {
        const iDocAppShareMsg = await apis.app.getIDocAppShareMsg(iDocAppID);
        dispatch(Actions.setIDocAppShareMsg(iDocAppShareMsg));
      }

      // 权限
      if (!iDocAppID && !prototypeID && !linkId && getQueryString('as')) {
        const AppShareMsg = await apis.app.getAppShareMsg(shareID);
        dispatch(Actions.setIDocAppShareMsg(AppShareMsg));
      }

      // 这里是 rp 项目原来的加载流程，无调整
      apis.app
        .getAppByShareID(shareID, prototypeID)
        .then(async (app) => {
          Object.keys(artboardList).forEach((key) => {
            delete artboardList[key];
          });
          //团队信息
          let teamInfo;
          try {
            teamInfo = await apis.team.getTeamInfo(app.teamID);
          } catch (err) {
            const errorCode = (err as CustomError).code;
            licenseErrorToPermissionPage(errorCode);
            window.debug && console.log(err);
          }
          if (teamInfo) {
            dispatch(Actions.getTeamInfo(teamInfo));
          }
          const roleInTeam = teamInfo?.roleInTeam as Role | undefined;
          //用户信息
          let userInfo: IUserInfo | undefined;
          if (teamInfo) {
            try {
              userInfo = await apis.user.getUserInfo();
            } catch (err) {
              const errorCode = (err as CustomError).code;
              licenseErrorToPermissionPage(errorCode);
              window.debug && console.log(err);
            }
          }
          if (userInfo) {
            dispatch(ShareAction.getUserInfo(userInfo));
          }

          //pageInfo
          let pageNodes: { nodes: string[] } | undefined;
          if (linkId) {
            try {
              pageNodes = await apis.share.getPageInShareRunLink(linkId);
            } catch (err) {
              window.debug && console.log(err);
            }
          }
          if (userInfo) {
            dispatch(ShareAction.setShareLinkNodeIDs(pageNodes?.nodes || []));
          }

          // 如果部分选择页面中，没有当前打开的页面，则使用部分页面队列中第一个页面
          if (openPage && pageNodes && pageNodes.nodes && pageNodes.nodes.length > 0) {
            if (!pageNodes.nodes.includes(openPage)) {
              openPage = pageNodes.nodes[0];
            }
          }

          ThunkActions.loadSnapshotPage(
            app,
            prototypeID,
            roleInTeam,
            openPage,
            userInfo?.id,
            pageNodes?.nodes,
          )(dispatch);
        })
        .catch((error) => {
          if (licenseErrorToPermissionPage(error.code)) {
            return;
          }
          const noSign = hasNoSign(error.code);
          if (noSign) {
            dispatch(Actions.toLogIn(shareID, openPage));
          } else if (error.code === ErrorCode.ERROR_MULTI_SIGN_IN) {
            dispatch(GlobalActions.remoteLogin());
          } else {
            dispatch(Actions.isProjectExist(false));
          }
        });
    } catch (error) {
      const errorCode = (error as CustomError).code;
      if (licenseErrorToPermissionPage(errorCode)) {
        return;
      }
      if (appIsNotExits(errorCode) || noPermission(errorCode)) {
        dispatch(AppAction.noPermission(errorCode));
      } else if (hasNoSign(errorCode)) {
        dispatch(Actions.toLogIn(shareID, openPage));
      } else if (errorCode === ErrorCode.ERROR_MULTI_SIGN_IN) {
        dispatch(GlobalActions.remoteLogin());
      } else if (notFound(errorCode)) {
        dispatch(Actions.isProjectExist(false));
      } else {
        GlobalThunkActions.thunkErrorMessage(error as CustomError, false, 0)(dispatch);
      }
    }
  },

  loadPage: (page: INode, prototypeID?: string) => async (dispatch: Dispatch) => {
    dispatch(GlobalActions.waiting(true));
    const artBoards = await loadArtboards(page._id, prototypeID);
    batch(() => {
      dispatch(Actions.selectNode(page._id, artBoards));
      dispatch(GlobalActions.waiting(false));
    });
  },

  pushArtboardsReload: (artboards: IArtboard[]) => async (dispatch: Dispatch) => {
    batch(() => {
      dispatch(Actions.reloadPage(artboards));
    });
  },

  selectPageNode: (nodeID: string, prototypeID?: string) => async (dispatch: Dispatch) => {
    dispatch(GlobalActions.waiting(true));
    const artBoards = await loadArtboards(nodeID, prototypeID);
    batch(() => {
      dispatch(Actions.selectNode(nodeID, artBoards));
      dispatch(GlobalActions.waiting(false));
    });
  },

  afterPageSkip: () => (dispatch: Dispatch) => {
    dispatch(Actions.loadPageAfter());
  },

  exit: () => (dispatch: Dispatch) => {
    Object.keys(artboardList).forEach((key) => {
      delete artboardList[key];
    });
    dispatch(Actions.exit());
  },

  beforeSkipToPage: (nextPage: INode, currentPage: INode, actionEffect: IPageAction) => async (dispatch: Dispatch) => {
    const nextArtBoards = await loadArtboards(nextPage._id);
    if (currentPage && nextArtBoards) {
      const currentArtBoards = await loadArtboards(currentPage._id);
      const pageActionParam = {
        nextPageID: nextPage._id,
        nextArtBoards,
        currentPageID: currentPage._id,
        currentArtBoards,
        actionEffect,
      };
      dispatch(Actions.beforeSkipToPage(pageActionParam));
    }
  },

  goBackward: (actionEffect: IPageAction | undefined) => (dispatch: Dispatch) => {
    dispatch(Actions.goBackward(actionEffect));
  },

  goForward: () => (dispatch: Dispatch) => {
    dispatch(Actions.goForward());
  },

  goHome: (homeID: string, prototypeID?: string) => async (dispatch: Dispatch) => {
    const homeArtBoards = await loadArtboards(homeID, prototypeID);
    dispatch(Actions.goHome(homeID, homeArtBoards));
    dispatch(GlobalActions.waiting(false));
  },

  showFragment: (action: IFragmentAction) => (dispatch: Dispatch) => {
    dispatch(Actions.showFragment(action));
  },

  showMessage: (msg: string, time: number) => (dispatch: Dispatch) => {
    dispatch(Actions.showMessage(msg));
    setTimeout(() => {
      dispatch(Actions.hideMessage());
    }, time);
  },

  showMessageWithId: (msg: string, time: number, id: number) => (dispatch: Dispatch) => {
    dispatch(Actions.showMessageWithId(msg, id));
    if (time > 0) {
      setTimeout(() => {
        dispatch(Actions.hideMessageWithId(id));
      }, time);
    }
  },

  setCurrLinkID: (linkID: string) => (dispatch: Dispatch) => {
    dispatch(Actions.setCurrLinkID(linkID));
  },

  checkWaterMark: () => (dispatch: Dispatch, getState: Function) => {
    if (RP_CONFIGS.isOfflineDemo) {
      dispatch(Actions.checkWaterMar(getOfflineDemoData()?.config.waterMark));
    } else if (RP_CONFIGS.isPrivateDeployment) {
      const { share } = getState() as IMainState;
      apis.team.getPDConfigs().then((res) => {
        // side effect
        if (res.methodProxy) {
          setAxiosMethodProxyInterceptor();
        }

        const waterMark = res.isOpenWatermark && share.userInfo ? getWaterMarkContent(share.userInfo!.email) : '';
        dispatch(Actions.checkWaterMar(waterMark));
      });
    }
  },
  getPopularizes: () => async (dispatch: Dispatch) => {
    const result = await apis.activity.getPopularizes();
    dispatch(Actions.getPopularizes(result));
  },
};

export type PreviewAction = ActionsUnion<typeof Actions>;
