import { AutoFillType } from '.';

export enum SexType {
  male,
  female,
  both,
}

export enum DateFormat {
  YYMMDD, // 2023/05/24
  YYMMDD_HHMMSS,
  YY_MM_DD, // 2023-05-24
  YY_MM_DD_HHMMSS,
  YY$MM$DD, // 2023年05月24日
  MM$DD, // 05月24日
  YY$MM$DD_HH$MM$$SS,
  YY_MM_DD_WW, // 2023-05-24 星期三
  YY$MM,
  YYMM, // 2023/05
  YY_MM, // 2023-05
  MMDD, // 05/24
  MM_DD, // 05-24
  ZH_YYMMDD, // 二〇二三年五月二十四日
  ZH_MMDD, // 五月二十四日

  MMDDYY, // 05/24/2023
  MM_DD_YY, // 05-24-2023
  WW_MM_DD_YY, //Wednesday 05-24-2023

  HHMMSS, // 23时59分59秒
  HHMM, // 23时59分
  MMSS, //55分20秒
  HH_MM_SS, // 23:59:59
  HH_MM, // 23:59
  MM_SS, // 01:23
}

export enum ZH_DateFormat {
  YYMMDD = DateFormat.YYMMDD,
  YY_MM_DD = DateFormat.YY_MM_DD,
  YY$MM$DD = DateFormat.YY$MM$DD,
  MM$DD = DateFormat.MM$DD,
  YY_MM_DD_WW = DateFormat.YY_MM_DD_WW,
  MMDD = DateFormat.MMDD,
  MM_DD = DateFormat.MM_DD,
  ZH_YYMMDD = DateFormat.ZH_YYMMDD,
  ZH_MMDD = DateFormat.ZH_MMDD,
  HHMMSS = DateFormat.HHMMSS,
  HHMM = DateFormat.HHMM,
  HH_MM_SS = DateFormat.HH_MM_SS,
  HH_MM = DateFormat.HH_MM,
  MMSS = DateFormat.MMSS,
}

export enum EN_DateFormat {
  MMDDYY = DateFormat.MMDDYY,
  MM_DD_YY = DateFormat.MM_DD_YY,
  WW_MM_DD_YY = DateFormat.WW_MM_DD_YY,

  HHMMSS = DateFormat.HHMMSS, // 时分秒
  HHMM = DateFormat.HHMM, // 时分
  HH_MM_SS = DateFormat.HH_MM_SS, // :
  HH_MM = DateFormat.HH_MM, // :
}

export enum NumberType {
  largNumber,
  smalNumber,
  decimalNumber,
  priceNumber,
  percentNumber,
  zipCodeNomber,
  serialNumber,
  idCardNumber,
}

export interface IImageCategory {
  id: ImageCategory;
  name: string;
  iconPath?: string;
}

export interface ITextCategory {
  id: TextCategory;
  name: string;
  icon?: string;
  iconCode?: number;
  subCategory?: { id: any; name: string; option?: [number, number]; width?: number; style?: React.CSSProperties }[];
}

/**
 * 文本分类
 */
export enum TextCategory {
  name = 'name',
  name_both = 'name_both', //姓名随机
  name_female = 'name_female', // 女性
  name_male = 'name_male', // 男姓
  email = 'email',
  phone = 'phone',
  phone_mobile = 'phone_mobile', // 手机号
  phone_landline = 'phone_landline', // 座机
  date = 'date',
  time = 'time',
  number = 'number',
  number_tiny = 'number_tiny',
  number_small = 'number_small',
  number_middle = 'number_middle',
  number_large = 'number_large',
  number_huge = 'number_huge',
  text = 'text',
  address = 'address',
  address_province = 'address_province',
  address_country = 'address_country',
  address_city = 'address_city',
  address_nativePlace = 'address_nativePlace',
  serial = 'serial',
}

export enum TextAddressCategory {
  country = 'country',
  province = 'province',
  city = 'city',
  nativePlace = 'nativePlace',
}

export enum TextNumberCategory {
  tiny = 'tiny',
  small = 'small',
  middle = 'middle',
  large = 'large',
  huge = 'huge',
}

/**
 * 图片分类
 */
export enum ImageCategory {
  people = 'people', //人物
  office = 'office', // 商务
  animal = 'animal', // 动物
  science = 'science', // 科技
  scenery = 'scenery', // 风景
  food = 'food', // 美食
  edifice = 'edifice', //建筑
  misc = 'misc', // 综合
  clothing = 'clothing', // 衣服
}

export type TextSubCategory = DateFormat | TextAddressCategory | TextNumberCategory;

type TAddress<T = { [k: string]: string[] | TAddress }> = T;

/**
 * 数据配置结构，对应index.json文件
 */
export interface IAutoFillData {
  host: string[];
  hostName: string;
  name: {
    family: string;
    male: string;
    female: string;
  };
  nickName: string[];
  country: string[];
  city: TAddress;
  areaCode: string[];
  content: string[];
  phonePrefix: number[];
  image: { category: ImageCategory; items: string[] }[];
}

export default interface IAutoFill {
  /**
   * 注入自己的图片库
   * @param category
   * @param imgs
   */
  injectImage(category: string, imgs: string[]): void;

  setSerialMax(num: number): void;
  /**
   * 男名
   */
  maleName(): string;
  /**
   * 女名
   */
  femaleName(): string;
  /**
   * 任意人名
   */
  bothName(): string;

  /**
   * 昵称
   */
  getNickName(): string;
  /**
   * 手机号
   */
  getMobilePhone(): string;

  /**
   * 坐机号
   */
  getTelPhone(): string;

  /**
   * 邮箱
   */
  getEmail(): string;

  /**
   * 日期
   * @param format 日期格式
   */
  getDate(format: DateFormat): string;

  /**
   * 数字，永远不会超过最大值，当最大值 <= 1 时，获取的是两位小数
   * @param min 最小值
   * @param max 最大值
   */
  getNumber(min: number, max: number, decimal?: number): string;

  getFormatNumber(type: NumberType, unit?: string): string;

  /**
   * 获取城市名
   */
  getCity(): string;

  /**
   * 国家名
   */
  getCountry(): string;

  /**
   * 省
   */
  getProvince(): string;

  /**
   * 地区名：省 . 市
   */
  getNativePlace(): string;

  /**
   * 地址
   */
  getAddress(): string;

  /**
   * 内容
   */
  getContent(): string;

  getSerial(prefix: string): string;

  start(fillType: AutoFillType, category: string, subCategory?: TextSubCategory | string): (value?: string) => string;

  /**
   * 图片
   * @param category 图片分类
   */
  getImage(category: string): string;
}
