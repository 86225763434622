import { IPrototypeInfo } from '@fbs/idoc/models/app';

import { get, patch, put, axDelete, idocBaseUrl } from './helper';
import { AppState, IAppSetCreateReq, IAppsOfTeam } from '@fbs/teamManagement';
// import team from '@apis/offlineDemo/team';

// /**
//  * 获取所有项目集以及项目
//  * @param {string} teamID
//  * @returns {Promise<IAppF[]>}
//  */
// export function getAllAppSet(teamID: string): Promise<{ apps: IAppInfo[] }> {
//   return get<{ apps: IAppInfo[] }>(
//     `/app/allAppAndAppSet/${teamID}?needArchivedApp=true&source=all&needAppSet=true`,
//   );
//   // return get<{ apps: IAppInfo[] }>(`/app/all/${teamID}`);
// }

/**
 * 添加项目集
 * @param {Partial<IAppF>} data
 * @returns {Promise<IAppF>}
 */
export async function addAppSet(data: IAppSetCreateReq): Promise<IAppsOfTeam> {
  return put<IAppsOfTeam>(`/app/appSet`, data);
}

export async function renameAppSet(appID: string, name: string): Promise<IAppsOfTeam> {
  return patch<IAppsOfTeam>(`/app/${appID}`, { name });
}

/**
 * 从项目集中删除项目
 * @param teamID
 * @param {string} appID
 * @returns {Promise}
 */
export async function removeApp(teamID: string, appID: string): Promise<any> {
  return axDelete(`/app/${teamID}/${appID}`, { data: { state: AppState.RECYCLE } });
}

export async function removeAppSet(teamID: string, appSetID: string): Promise<any> {
  return axDelete(`/app/appSet/${teamID}/${appSetID}`);
}

/**
 * 把项目添加到某个项目集
 * @param {string} teamID
 * @param {string} appSetID
 * @param {string} appID
 * @returns {Promise}
 */
export async function moveAppToAppSet(teamID: string, appSetID: string, appID: string): Promise<any> {
  return patch(`/app/appSet/${teamID}/${appSetID}/add/${appID}`, null);
}

// export async function getRecycle(teamID: string): Promise<IAppsOfTeam[]> {
//   return get<IAppsOfTeam[]>(`/team/recycle/${teamID}`);
// }

export async function restoreApp(appID: string): Promise<any> {
  return patch(`/app/restore/${appID}`, null);
}

export async function clearAppFromRecycle(teamID: string, appID: string): Promise<any> {
  return axDelete(`/app/${teamID}/${appID}/4`);
}

export async function starAppInfo(appID: string): Promise<any> {
  return put<any>(`/app/appCollection/${appID}`, null);
}

export async function unStarAppInfo(appID: string): Promise<any> {
  return axDelete(`/app/appCollection/${appID}`);
}

export async function cloneAppInfo(appID: string): Promise<{ taskID: string }> {
  return put(`/app/clone`, { appID });
}

export async function getClonedAppInfoByTaskID(taskID: string): Promise<IAppsOfTeam> {
  return await get<IAppsOfTeam>(`/app/clone/${taskID}`);
}

/**
 * 获取所有原型项目
 * @param appID
 */
export function getAllPrototypeApps(appID: string) {
  return get<IPrototypeInfo[]>(`${idocBaseUrl}/app/prototype/all/${appID}`);
}
