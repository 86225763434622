import { escapeRegExp } from 'lodash';

import { filterValueText, NUMBER_FILTER_CHARS } from '@/utils/textUtils';

import IInputModel, { InputModel } from '@/fbs/rp/models/properties/inputModel';
import { ArtboardPatches } from '@/fbs/rp/utils/patch';
import { CNumericStep, CInput } from '@libs/constants';

import UIComponent, { IFindResult } from './UIComponent';

export default class UIPureTextComponent extends UIComponent {
  public matchText(text: string, pageID: string): IFindResult[] {
    const result: IFindResult[] = [];
    const escapedText = escapeRegExp(text);
    const regex = new RegExp(escapedText, 'gi');
    const currentTextValue = this.value as string;

    let match: ReturnType<RegExp['exec']>;
    while ((match = regex.exec(currentTextValue)) !== null) {
      console.log(`Found ${match[0]} start=${match.index} end=${regex.lastIndex}.`);
      const customMatch = Object.assign(match, {
        start: match.index,
        end: match.index + match[0].length,
      });
      result.push({ pageID, component: this, match: customMatch });
    }

    return result;
  }

  public replaceText(matchInfo: IFindResult['match'], text: string, newText: string): ArtboardPatches {
    const currentTextValue = this.value as string;
    const matchPart = matchInfo[0];
    const matchPartNotExist = matchPart !== currentTextValue.substring(matchInfo.start, matchInfo.end);
    if (matchPartNotExist) {
      return { do: {}, undo: {} };
    }

    const compInLibs = this.nearestSealedComponent ?? this;
    switch (compInLibs.type) {
      case CNumericStep: {
        return this.doReplaceCNumericStepText(currentTextValue, matchInfo, newText);
      }
      case CInput: {
        if ((compInLibs.properties.inputModel as IInputModel).value === InputModel.numeric) {
          return this.doReplaceCNumericStepText(currentTextValue, matchInfo, newText);
        }
        return this.doReplaceText(currentTextValue, matchInfo, newText);
      }

      default:
        return this.doReplaceText(currentTextValue, matchInfo, newText);
    }
  }

  private doReplaceCNumericStepText(
    currentTextValue: string,
    matchInfo: IFindResult['match'],
    newText: string,
  ): ArtboardPatches {
    let patches = { do: {}, undo: {} };

    const numericText = filterValueText(newText, NUMBER_FILTER_CHARS);

    // 替换内容中没有数值内容
    if (!numericText) {
      return patches;
    }

    // 获取新 value
    const newValue =
      currentTextValue.substring(0, matchInfo.start) + numericText + currentTextValue.substring(matchInfo.end);

    const numberValue = Number(newValue);

    if (isNaN(numberValue)) {
      return patches;
    }

    const finalNewValue = numberValue.toString();

    // 生成 patch
    patches = this.setValue(finalNewValue);
    window.debug && console.log('replaceText patches: ', patches);

    return patches;
  }

  private doReplaceText(currentTextValue: string, matchInfo: IFindResult['match'], newText: string): ArtboardPatches {
    // 获取新 value
    const newValue =
      currentTextValue.substring(0, matchInfo.start) + newText + currentTextValue.substring(matchInfo.end);

    // 生成 patch
    const patches = this.setValue(newValue);
    window.debug && console.log('replaceText patches: ', patches);

    return patches;
  }

  public replaceAllText(matchInfo: IFindResult['match'], text: string, newText: string): ArtboardPatches {
    const currentTextValue = this.value as string;
    const compInLibs = this.nearestSealedComponent ?? this;
    switch (compInLibs.type) {
      case CNumericStep: {
        return this.doReplaceCNumericStepAllText(currentTextValue, matchInfo, newText);
      }
      case CInput: {
        if ((compInLibs.properties.inputModel as IInputModel).value === InputModel.numeric) {
          return this.doReplaceCNumericStepAllText(currentTextValue, matchInfo, newText);
        }
        return this.doReplaceAllText(currentTextValue, matchInfo, newText);
      }

      default:
        return this.doReplaceAllText(currentTextValue, matchInfo, newText);
    }
  }

  private doReplaceAllText(
    currentTextValue: string,
    matchInfo: IFindResult['match'],
    newText: string,
  ): ArtboardPatches {
    // 获取新 value
    const matchPart = matchInfo[0];
    const escapedMatchPart = escapeRegExp(matchPart);
    const regex = new RegExp(escapedMatchPart, 'gi');
    const newValue = currentTextValue.replace(regex, newText);

    // 生成 patch
    const patches = this.setValue(newValue);
    window.debug && console.log('replaceText patches: ', patches);

    return patches;
  }

  private doReplaceCNumericStepAllText(
    currentTextValue: string,
    matchInfo: IFindResult['match'],
    newText: string,
  ): ArtboardPatches {
    let patches = { do: {}, undo: {} };

    const numericText = filterValueText(newText, NUMBER_FILTER_CHARS);

    // 替换内容中没有数值内容
    if (!numericText) {
      return patches;
    }

    // 获取新 value
    const matchPart = matchInfo[0];
    const escapedMatchPart = escapeRegExp(matchPart);
    const regex = new RegExp(escapedMatchPart, 'gi');
    const newValue = currentTextValue.replace(regex, numericText);

    const numberValue = Number(newValue);

    if (isNaN(numberValue)) {
      return patches;
    }

    const finalNewValue = numberValue.toString();

    // 生成 patch
    patches = this.setValue(finalNewValue);
    window.debug && console.log('replaceText patches: ', patches);

    return patches;
  }
}
