import * as React from 'react';

import Icon from '../../../Icon';
import { IItemProps } from '../../../../models';

import './index.scss';

interface ISelectItemProps {
  item: IItemProps;

  onRemoveItem(id: string): void;
  onRemoveGroup?(id: string): void;
  onCheckDisable?(id: string): void;
}

const SelectItem: React.FC<ISelectItemProps> = (props: ISelectItemProps) => {
  const { item, onRemoveItem, onRemoveGroup, onCheckDisable } = props;

  const { id, name, imageURL } = item;

  const handleRemoveItem = () => {
    if (item.disabled) {
      onCheckDisable && onCheckDisable(item.id);
      return;
    }
    if (item.isGroup) {
      onRemoveGroup && onRemoveGroup(item.id);
      return;
    }
    onRemoveItem(id);
  };

  return (
    <div className="select-item-box">
      <div className="select-avatar">{imageURL ? <img src={imageURL} /> : <Icon size={20} />}</div>
      <span className="name">{name}</span>
      <div className="item-remove">
        <Icon disableHover allowDisabledClick cls="demo_close" disabled={item.disabled} onClick={handleRemoveItem} />
      </div>
    </div>
  );
};

export default SelectItem;
