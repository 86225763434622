import PropertyBase from './base';
import IIcon from './icon';

export const VerticalTabsIconStylePropertyName = 'verticalTabsIconStyle';

export enum IconPositionMode {
  Left,
  Top,
}

interface IVerticalTabsIconStyle extends PropertyBase {
  isShow: boolean;
  iconSize: number;
  iconDefaultStyle: IIcon;
  iconSelectedStyle: IIcon;
  iconPositionMode: IconPositionMode;
}

export default IVerticalTabsIconStyle;
