import * as React from 'react';
import * as ReactDom from 'react-dom';
import classnames from 'classnames';

import './index.scss';

export interface ITooltipProp {
  children: any;
  text: string;
  overflowCheck?: boolean;
  className?: string;
  style?: React.CSSProperties;
  autoHide?: boolean;
  ownerIsDialog?: boolean;
  popupClassName?: string;
}

export interface ITooltipState {
  show: boolean;
  autoWrap?: boolean;
  popupPosition: { left: number; top: number };
}

class Tooltip extends React.PureComponent<ITooltipProp, ITooltipState> {
  static defaultProps: Partial<ITooltipProp> = {
    autoHide: true,
  };

  selfRef: React.RefObject<HTMLDivElement> = React.createRef();
  textRef: React.RefObject<HTMLDivElement> = React.createRef();
  popup: React.RefObject<HTMLDivElement> = React.createRef();
  triggerTimeID: number = 0;
  private pagePoint: { x: number; y: number } = { x: 0, y: 0 };

  constructor(props: ITooltipProp) {
    super(props);
    this.state = {
      show: false,
      popupPosition: { left: 0, top: 0 },
    };
  }

  componentWillUnmount(): void {
    window.clearTimeout(this.triggerTimeID);
    window.removeEventListener('mousemove', this.onWindowMoving);
  }

  componentDidUpdate() {
    this.doAdjustPopupPosition();
  }

  doAdjustPopupPosition = () => {
    const { popupPosition } = this.state;
    if (this.popup.current && this.selfRef.current) {
      const bounds = this.popup.current.getBoundingClientRect();
      const selfBounds = this.selfRef.current.getBoundingClientRect();
      const { innerWidth, innerHeight } = window;
      const { right, bottom, width, height } = bounds;
      let { left, top } = bounds;
      if (right > innerWidth) {
        left = selfBounds.right - width;
      }
      if (bottom > innerHeight) {
        const topOffset = 6;
        top = selfBounds.top - height - topOffset;
      }
      left = Math.round(left);
      top = Math.round(top);
      if (left !== popupPosition.left || top !== popupPosition.top) {
        this.setState({ popupPosition: { left, top } });
      }
      const autoWrapMaxWidth = 500;
      if (width >= autoWrapMaxWidth) {
        this.setState({ autoWrap: true });
      }
    }
  };

  onWindowMoving = (e: MouseEvent) => {
    this.pagePoint.x = e.pageX;
    this.pagePoint.y = e.pageY;
  };

  onMouseEnter = (e: React.MouseEvent) => {
    this.pagePoint.x = e.pageX;
    this.pagePoint.y = e.pageY;
    if (this.selfRef.current) {
      const delayTime = 500;
      window.addEventListener('mousemove', this.onWindowMoving);
      this.triggerTimeID = window.setTimeout(() => {
        if (this.triggerTimeID) {
          window.clearTimeout(this.triggerTimeID);
        }
        // 确保正常情况下，始终位于光标点的下部20px处
        this.doShow();
      }, delayTime);
    }
  };

  doShow = () => {
    const positionOffset = 22;
    this.setState(
      {
        show: true,
        popupPosition: {
          left: this.pagePoint.x,
          top: this.pagePoint.y + positionOffset,
        },
      },
      () => {
        if (this.props.autoHide) {
          const { text } = this.props;
          const textDelayTime = 50;
          const delayTime = 2000;
          this.triggerTimeID = window.setTimeout(this.doHide, delayTime + (text || '').length * textDelayTime);
        }
      },
    );
  };

  doHide = () => {
    if (this.state.show) {
      this.setState({ show: false });
    }
  };

  onMouseLeave = () => {
    window.removeEventListener('mousemove', this.onWindowMoving);
    if (this.triggerTimeID) {
      clearTimeout(this.triggerTimeID);
    }
    this.doHide();
  };

  renderTips = () => {
    const { text, ownerIsDialog, popupClassName, overflowCheck } = this.props;
    const { popupPosition, autoWrap } = this.state;
    if (!text) {
      return null;
    }
    if (overflowCheck) {
      const textWidth = this.textRef.current?.getBoundingClientRect().width || 0;
      const selfWidth = this.selfRef.current?.getBoundingClientRect().width || 0;
      const isOverflow = textWidth > selfWidth;
      if (!isOverflow) {
        return null;
      }
    }
    const isMulti = text.includes('\n');
    return ReactDom.createPortal(
      <div
        ref={this.popup}
        style={popupPosition}
        className={classnames('dsm-c-rp-tool-tips', popupClassName, {
          'in-dialog': ownerIsDialog,
          'auto-wrap': autoWrap,
          'multi-line': isMulti,
        })}
      >
        {text}
      </div>,
      document.body,
    );
  };

  private renderItem(): React.ReactNode {
    const { children, text, overflowCheck } = this.props;
    if (overflowCheck) {
      return <span ref={this.textRef}>{text}</span>;
    }
    return children;
  }

  render() {
    const { className, style, overflowCheck } = this.props;
    const { show } = this.state;
    return (
      <React.Fragment>
        <div
          style={style}
          className={classnames(className, { 'dsm-c-rp-text-overflow': overflowCheck })}
          ref={this.selfRef}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          {this.renderItem()}
        </div>
        {show && this.renderTips()}
      </React.Fragment>
    );
  }
}

export default Tooltip;
