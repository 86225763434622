import * as React from 'react';

import i18n from '../../../../../i18n';

import MatchStrSpan from '../../../../MatchStrSpan';

import './index.scss';

interface IReceivedForeverRecordProps {
  getReceivedForeverRecord(cb: Function): void;
}

const ReceivedForeverRecord: React.FC<IReceivedForeverRecordProps> = (props: IReceivedForeverRecordProps) => {
  const { getReceivedForeverRecord } = props;
  const lineHeight = 34;

  const [loaded, setLoaded] = React.useState(false);
  const [list, setList] = React.useState<string[]>([]);
  const [marginTop, setMarginTop] = React.useState(0);
  const ref = React.useRef<HTMLUListElement>(null);
  let scrollTimer: any = null;

  React.useEffect(() => {
    getReceivedForeverRecord((superAdminNames: string[]) => {
      if (superAdminNames.length > 0) {
        setList(superAdminNames);
        setLoaded(true);
      }
    });
    return () => {
      clearInterval(scrollTimer);
    };
  }, []);

  React.useEffect(() => {
    if (loaded) {
      const refDom = ref.current;
      refDom && scrollUL();
    }
  }, [loaded]);

  const scrollUL = () => {
    let marginTopAll = marginTop;
    let copyList = JSON.parse(JSON.stringify(list));
    scrollTimer = setInterval(() => {
      marginTopAll -= lineHeight;
      if (-marginTopAll > (copyList.length - 3) * lineHeight) {
        copyList = [...copyList, list[(-marginTopAll / lineHeight + 1) % list.length]];
        setList([...copyList]);
      }
      setMarginTop(marginTopAll);
    }, 3000);
  };

  const getNickName = (name: string) => {
    if (!name) {
      return '*';
    }
    const eightStr = name.slice(0, 8);
    return eightStr.substr(0, 1) + new Array(eightStr.length - 1).join('*');
  };

  return (
    <div className="invitation-received-forever">
      {list.length > 0 && (
        <ul ref={ref} style={{ marginTop }}>
          {list.map((item, index) => {
            const nick = getNickName(item);
            return (
              <li key={index}>
                <span>{nick}</span>
                <MatchStrSpan
                  str={i18n('rpActivity.invitation.teamGotForeverFree')}
                  targetStr={i18n('rpActivity.invitation.foreverRPFree')}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default ReceivedForeverRecord;
