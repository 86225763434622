import * as path from 'path';

import { ISize } from '@fbs/common/models/common';

import ValueEditorType from '@consts/enums/valueEditorType';

import apis from '@apis';
import i18n from '@i18n';
import { MaxAudioSize, MaxImageSize, MaxVideoSize } from '@consts/file';

let uploadDom: HTMLInputElement;

function initUploadDom() {
  if (!uploadDom) {
    uploadDom = document.createElement('input');
    uploadDom.type = 'file';
    uploadDom.style.position = 'absolute';
    uploadDom.style.zIndex = '0';
    uploadDom.style.left = '-1000px';
    uploadDom.style.top = '-1000px';
    uploadDom.style.width = '10px';
    uploadDom.style.height = '10px';
  }
  uploadDom.onchange = null;
  uploadDom.value = '';
  return uploadDom;
}

const IMGAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];

export enum IMGAGE_EXTENSION {
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  GIF = 'gif',
  BMP = 'bmp',
  SVG = 'svg',
}

export const VIDEO_EXTENSIONS = ['ogg', 'mp4'];
export const AUDIO_EXTENSIONS = ['mp3'];

export function validateFileType(file: File, type: FileType): { success: boolean; msg?: string } {
  const index = file.name.lastIndexOf('.');
  const extension = file.name.substring(index + 1).toLowerCase();
  if (type === FileType.Image) {
    if (IMGAGE_EXTENSIONS.indexOf(extension) === -1) {
      return { success: false, msg: i18n('alert.errorSelectingFileType', IMGAGE_EXTENSIONS.join(', ')) };
    }
  } else if (type === FileType.Video) {
    if (VIDEO_EXTENSIONS.indexOf(extension) === -1) {
      return { success: false, msg: i18n('alert.errorSelectingFileType', VIDEO_EXTENSIONS.join(', ')) };
    }
  } else if (type === FileType.Audio) {
    if (AUDIO_EXTENSIONS.indexOf(extension) === -1) {
      return { success: false, msg: i18n('alert.errorSelectingFileType', AUDIO_EXTENSIONS.join(', ')) };
    }
  }
  return { success: true };
}

/**
 * 获取本地图片文件大小
 * @param {File} file
 * @returns {Promise<{width: number, height: number}>}
 */
export async function getLocalImageFileSize(file: File): Promise<{ size: { width: number; height: number } }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectURL = URL.createObjectURL(file);
    img.src = objectURL;
    img.onload = () => {
      const size = { width: img.width, height: img.height };
      resolve({
        size,
      });
      URL.revokeObjectURL(objectURL);
      img.remove();
    };
    img.onerror = () => {
      reject();
      img.remove();
    };
  });
}

export async function getImageSize(url: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
      img.remove();
    };
    img.onerror = () => {
      reject();
      img.remove();
    };
  });
}

export const AllowedImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/svg+xml'];

/**
 * 获取图片file对象
 */
export async function selectImage(accepts: string[]): Promise<File> {
  return new Promise<File>((resolve, reject) => {
    const dom = initUploadDom();
    dom.accept = accepts.join(',');
    dom.multiple = false;
    dom.onchange = (e) => {
      const el: HTMLInputElement = e.target as HTMLInputElement;
      if (el.files && el.files.length) {
        const file: File = el.files[0];
        const { success, msg } = validateFileType(file, FileType.Image);
        success ? resolve(file) : reject(msg);
      }
    };
    dom.click();
  });
}

/**
 * 获取多个图片file对象
 * 对返回结果不再进行类型验证
 * @param accepts 允许上传格式
 * @param isMultiple 是否是多选
 */
export async function selectMultipleImage(accepts: string[], isMultiple = true): Promise<FileList> {
  return new Promise<FileList>((resolve) => {
    const dom = initUploadDom();
    dom.accept = accepts.join(',');
    dom.multiple = isMultiple;
    dom.onchange = (e) => {
      const el: HTMLInputElement = e.target as HTMLInputElement;
      if (el.files && el.files.length) {
        const files: FileList = el.files;
        resolve(files);
      }
    };
    dom.click();
  });
}

/**
 * 获取视频video对象
 */
export async function selectVideo(): Promise<File> {
  return new Promise<File>((resolve, reject) => {
    const dom = initUploadDom();
    dom.accept = VIDEO_EXTENSIONS.map((type) => `video/${type}`).join(',');
    dom.multiple = false;
    dom.onchange = (e) => {
      const el: HTMLInputElement = e.target as HTMLInputElement;
      if (el.files && el.files.length) {
        const file: File = el.files[0];
        const { success, msg } = validateFileType(file, FileType.Video);
        success ? resolve(file) : reject(msg);
      }
    };
    dom.click();
  });
}

/**
 * 获取音频audio对象
 */
export async function selectAudio(): Promise<File> {
  return new Promise<File>((resolve, reject) => {
    const dom = initUploadDom();
    dom.accept = 'audio/mp3';
    dom.multiple = false;
    dom.onchange = (e) => {
      const el: HTMLInputElement = e.target as HTMLInputElement;
      if (el.files && el.files.length) {
        const file: File = el.files[0];
        const { success, msg } = validateFileType(file, FileType.Audio);
        success ? resolve(file) : reject(msg);
      }
    };
    dom.click();
  });
}
// /**
//  * 获取音频audio对象
//  */
// export async function selectAudio(): Promise<File> {
//   return new Promise<File>((resolve, reject) => {
//     const dom = initUploadDom();
//     const audioExtension = ['ogg', 'mpeg'];
//     dom.accept = audioExtension.map(type => `audio/${type}`).join(',');
//     dom.multiple = false;
//     dom.onchange = e => {
//       const el: HTMLInputElement = e.target as HTMLInputElement;
//       if (el.files && el.files.length) {
//         const file: File = el.files[0];
//         const index = file.name.lastIndexOf('.');
//         const extension = file.name.substring(index + 1).toLowerCase();
//         if (audioExtension.indexOf(extension) !== -1) {
//           resolve(file);
//         } else {
//           reject(i18n('alert.errorSelectingFileType', audioExtension.join(', ')));
//         }
//       }
//     };
//     dom.click();
//   });
// }

/**
 * 上传图片到服务器接口，服务器返回图片绝对地址和尺寸信息
 * @param file
 * @param type
 * @param isImitation 是否虚拟上传
 * @param fn
 */
export async function uploadFile(
  file: File,
  type: ValueEditorType,
  isImitation = false,
  fn?: (complet: number) => void,
): Promise<{ URL: string; name: string; size: { width: number; height: number } }> {
  if (isImitation) {
    const url = URL.createObjectURL(file);
    let size: { width: number; height: number };
    if ([ValueEditorType.Image, ValueEditorType.Svg].includes(type)) {
      size = await getImageSize(url);
    } else {
      size = await apis.file.getVideoDimensions(url);
    }
    return new Promise<{ URL: string; name: string; size: { width: number; height: number } }>((resolve) => {
      resolve({ URL: url, name: file.name, size });
    });
  }
  if (type === ValueEditorType.Image) {
    return apis.file.uploadImg(file, fn);
  } else if (type === ValueEditorType.Video) {
    return apis.file.uploadVideo(file, fn);
  } else if (type === ValueEditorType.Audio) {
    return apis.file.uploadAudio(file, fn);
  } else {
    return apis.file.uploadSvg(file, fn);
  }
}

/**
 * File转base64
 */
export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const result = e.target?.result;
      if (result) {
        resolve(result as string);
      } else {
        reject();
      }
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsDataURL(file);
  });
}

/**
 * base64转blob
 */
export function base64ToBlob(base64Data: string) {
  const arr = base64Data.split(',');
  const fileType = arr[0]!.match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let i = bstr.length;
  const u8Arr = new Uint8Array(i);

  while (i--) {
    u8Arr[i] = bstr.charCodeAt(i);
  }
  return new Blob([u8Arr], {
    type: fileType,
  });
}

/**
 * blob转file
 */
export function blobToFile(newBlob: Blob, fileName: string, type = 'image/png') {
  return new File([newBlob], fileName, { type });
}

// export function zipImg(url: string, size: { height: number; width: number }, imgType: string): string {
//   const image = new Image();
//   image.src = url;
//   const canvas = document.createElement('canvas');
//   const context = canvas.getContext('2d');
//   canvas.width = size.width;
//   canvas.height = size.height;
//   context!.clearRect(0, 0, size.width, size.height);
//   context!.drawImage(image, 0, 0, size.width, size.height);
//   return canvas.toDataURL(imgType);
// }

export function getUrlBase64(
  url: string,
  ext: IMGAGE_EXTENSION,
  fn: (dataURL: string, data: any, size: ISize) => void,
  data: any,
) {
  const canvas = document.createElement('canvas'); //创建canvas DOM元素
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.crossOrigin = 'anonymous';
  img.src = url;
  img.onload = function () {
    const { width, height, naturalHeight, naturalWidth } = img;
    const size = naturalHeight
      ? {
          width: naturalWidth,
          height: naturalHeight,
        }
      : {
          width,
          height,
        };
    canvas.height = size.height; //指定画板的高度,自定义
    canvas.width = size.width; //指定画板的宽度，自定义
    if (!ctx) {
      return;
    }
    ctx.drawImage(img, 0, 0, width, height); //参数可自定义
    const dataURL = canvas.toDataURL('image/' + ext);
    fn.call(this, dataURL, data, size); //回掉函数获取Base64编码
  };
  img.onerror = function (e) {
    window.debug && console.log(e, url);
    fn('', data, { width: 0, height: 0 });
  };
}

export enum FileType {
  Unknown,
  Image,
  Video,
  Audio,
  SVG,
}

// 通过文件的名字获取文件的类型
export function getFileType(file: File): FileType {
  const { name, type } = file;
  const isImg = type.indexOf('image') === 0;
  const isVideo = type.indexOf('video') === 0;
  const isAudio = type.indexOf('audio') === 0;
  if (!name) {
    return isImg ? FileType.Image : FileType.Unknown;
  }
  const ext = path.extname(name).toLowerCase();
  if (!ext) {
    return isImg ? FileType.Image : FileType.Unknown;
  }

  if (isImg && ['.jpeg', '.jpg', '.png', '.gif'].includes(ext)) {
    return FileType.Image;
  }
  if (isVideo && ext === '.mp4') {
    return FileType.Video;
  }
  if (isImg && ext === '.svg') {
    return FileType.SVG;
  }
  if (isAudio && ext === '.mp3') {
    return FileType.Audio;
  }

  return FileType.Unknown;
}

export type GroupFileItem = {
  type: FileType;
  valueEditType: ValueEditorType;
  file: File;
};
type GroupFileTypeData = {
  maxSize: number;
  files: GroupFileItem[]; //当前
};
type GroupFilesResult = {
  total: number; // 有效文件
  invalidCount: number; // 不合规的文件数量, 这部分不会上传服务器验证
  hasLargFile: boolean; // 存在大文件
  hasFormatFailFile: boolean; //存在夫效格式
  hasUploadLimit: boolean; //超出最大上传限制
  unknownFormatFileCount: number;
  groups: {
    [K in FileType]: GroupFileTypeData;
  };
  getFiles: () => GroupFileItem[];
};
type GroupFilesOptions = {
  maxUploadCount: number;
  types: FileType[];
};
export type BatchUploadFilesItem = {
  type: FileType;
  valueEditType: ValueEditorType;
  data: { URL: string; name: string; size: { width: number; height: number } };
};
type BatchUploadFilesOptions = {
  isExample?: boolean;
  maxRequestCount?: number;
  skipFial?: boolean;
  onComplete: (results: BatchUploadFilesItem[], total: number) => void;
  onProgress: (percent: number, total: number, completeCount: number) => void;
};
/**
 * 批量上传
 * @param files 文件列表
 * @param opts  选项
 * @returns
 */
export function batchUploadFiles(files: GroupFileItem[], opts: BatchUploadFilesOptions) {
  let { isExample = false, maxRequestCount = 3, skipFial = false, onComplete, onProgress } = opts;
  let count = files.length;
  let requestStackIndex = 0;
  let results: BatchUploadFilesItem[] = new Array(count),
    loadedCount = 0,
    currentIndex = 0,
    isCompleteUpload = false,
    completeCount = 0;
  function progressHandle(complete: number) {
    // 如果是多文件上传，就以文件完成数量为进度,否则以实时进度
    if (complete >= 100) {
      completeCount++;
      if (count > 1 && onProgress) {
        onProgress(Math.round((completeCount / count) * 100), count, completeCount);
      }
    }
    if (count == 1) {
      if (onProgress) {
        onProgress(Math.round(complete), count, completeCount);
      }
    }
  }
  function upload(index: number, file: GroupFileItem) {
    if (isCompleteUpload) {
      return;
    }
    uploadFile(file.file, file.valueEditType, isExample, progressHandle)
      .then((data) => {
        results[index] = {
          type: file.type,
          valueEditType: file.valueEditType,
          data: data,
        };
        nextUpload();
      })
      .catch(() => {
        // 如果不跳过失败，当失败时，直接终止剩下上传
        if (!skipFial) {
          completeHanlde();
        } else {
          nextUpload();
        }
      });
  }
  function nextUpload() {
    if (isCompleteUpload) {
      return;
    }
    requestStackIndex--;
    loadedCount++;

    if (loadedCount >= count) {
      completeHanlde();
      return;
    }
    startNextUpload();
  }
  function startNextUpload() {
    while (requestStackIndex < maxRequestCount && currentIndex < count) {
      requestStackIndex++;
      upload(currentIndex, files[currentIndex]);
      currentIndex++;
    }
  }
  function completeHanlde() {
    if (isCompleteUpload) {
      return;
    }
    isCompleteUpload = true;
    if (onComplete) {
      onComplete(results.filter(Boolean), count);
    }
  }
  function start() {
    if (count <= 0 || currentIndex >= count || isCompleteUpload) {
      if (!isCompleteUpload) {
        completeHanlde();
      }
      return;
    }
    startNextUpload();
  }
  return {
    start,
  };
}
export function groupFiles(files: File[], options: GroupFilesOptions): GroupFilesResult {
  let { types, maxUploadCount } = options;
  const result: GroupFilesResult = {
    total: 0, // 有效文件数量
    invalidCount: 0,
    unknownFormatFileCount: 0, // 无效文件数量
    hasLargFile: false,
    hasFormatFailFile: false,
    hasUploadLimit: false,
    groups: {
      [FileType.Unknown]: {
        maxSize: Infinity,
        files: [], // // 未知文件格式数量
      },
      [FileType.Image]: {
        maxSize: MaxImageSize,
        files: [],
      },
      [FileType.SVG]: {
        maxSize: MaxImageSize,
        files: [],
      },
      [FileType.Video]: {
        maxSize: MaxVideoSize,
        files: [],
      },
      [FileType.Audio]: {
        maxSize: MaxAudioSize,
        files: [],
      },
    },
    getFiles: function () {
      return types.reduce((files, type) => {
        return files.concat(this.groups[type].files);
      }, [] as GroupFileItem[]);
    },
  };
  // 之前不知道谁定义了,用ValueEditorType作为上传，所以这里暂时，先映射一下
  let valueEditTypeMap = {
    [FileType.Unknown]: ValueEditorType.None,
    [FileType.Image]: ValueEditorType.Image,
    [FileType.SVG]: ValueEditorType.Svg,
    [FileType.Audio]: ValueEditorType.Audio,
    [FileType.Video]: ValueEditorType.Video,
  };
  files.forEach((file) => {
    let fileType = getFileType(file);
    if (types.includes(fileType)) {
      let current = result.groups[fileType];
      // 超过文件大小限制
      if (file.size > current.maxSize) {
        result.hasLargFile = true;
        result.invalidCount++;
      } else if (result.total < maxUploadCount) {
        current.files.push({
          type: fileType,
          valueEditType: valueEditTypeMap[fileType],
          file: file,
        });
      } else {
        // 超过上传数量限制了
        result.hasUploadLimit = true;
      }
      result.total++;
    } else {
      // 无效格式
      result.unknownFormatFileCount++;
    }
  });
  result.hasFormatFailFile = result.unknownFormatFileCount > 0;
  return result;
}

/**
 * svg字符串转base64
 */
export async function svgStr2ImgBase64(svgStr: string, type: string): Promise<string> {
  return new Promise((resolve, reject) => {
    svgStr2Canvas(svgStr)
      .then((canvas) => {
        const fileType = 'image/' + type;
        const base64Url = canvas.toDataURL(fileType);
        resolve(base64Url);
      })
      .catch((e) => reject(e));
  });
}

/**
 * svg字符串转canvas
 */
async function svgStr2Canvas(svgStr: string): Promise<HTMLCanvasElement> {
  const base64 = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(svgStr)));
  return imgUrl2Canvas(base64);
}

async function imgUrl2Canvas(url: string): Promise<HTMLCanvasElement> {
  return new Promise((resolve, reject) => {
    //新建Image对象
    let img = new Image();
    //svg编码成base64
    img.src = url; //svg内容中可以有中文字符
    img.crossOrigin = '*';
    //图片初始化完成后调用
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.height = img.height;
      canvas.width = img.width;
      const context = canvas.getContext('2d');
      //canvas画图片
      if (context) {
        context.drawImage(img, 0, 0);
        resolve(canvas);
      } else {
        reject();
      }
      img.remove();
    };
    img.onerror = () => {
      reject();
      img.remove();
    };
  });
}

// //获取视频文件的第一帧成为图片
// export function getFirstFrameOfVideo() {
//
// }
