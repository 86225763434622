import {
  IGridSettings,
  ILayoutSettings,
  ViewMode,
  ILayoutAndGridColors,
  IAlignConfig,
  IGeneralSettings,
} from '@fbs/rp/models/grid';

import { PureColor } from '@/fbs/rp/models/properties/color';

// 默认背景颜色
export const defaultBackgroundColor: PureColor = { r: 32, g: 40, b: 53, a: 1 };
/**
 * 默认网格参数
 */
export const defaultAppGridSettings: IGridSettings = {
  blockSize: 20,
  thickEvery: 10,
  visible: false,
};

/**
 * 默认栅格参数
 */
export const defaultAppLayoutSettings: ILayoutSettings = {
  visible: false,
  totalWidth: 0,
  offset: 0,
  columns: {
    visible: true,
    gutterOnOutside: true,
    gutterWidth: 10,
    columnsCount: 12,
  },
  rows: {
    gutterHeight: 12,
    rowHeight: 5,
    visible: false,
    drawAllLines: false,
  },
  viewMode: ViewMode.fill,
};

export const defaultLayoutAndGridColors: ILayoutAndGridColors = {
  thickColor: { r: 224, g: 32, b: 32, a: 0.3 },
  lightColor: { r: 224, g: 32, b: 32, a: 0.1 },
};

export const defaultAlignConfig: IAlignConfig = {
  normalAlign: true,
  testEquidistant: true,
  markDistance: true,
};

export const defaultGeneralSettings: IGeneralSettings = {
  pasteOffset: {
    x: 0,
    y: 0,
  },
  pasteAtTop: true,
  showHiddenArea: true,
  enableCmdShiftGToUnGrouping: false,
  backgroundColor: defaultBackgroundColor,
};
