export enum HorizontalAlign {
  Auto = 'auto',
  Left = 'left',
  Right = 'right',
  LeftAndRight = 'left&right',
  Center = 'center',
}

export enum VerticalAlign {
  Auto = 'auto',
  Top = 'top',
  Bottom = 'bottom',
  TopAndBottom = 'top&bottom',
  Middle = 'middle',
}

export interface IBasicLayout {
  horizontal: HorizontalAlign,
  vertical: VerticalAlign,
  // 宽度固定
  fixedWidth: boolean;
  // 高度固定
  fixedHeight: boolean;
}

// 定位信息
export interface ILayout extends IBasicLayout {
  // 是否开启响应式布局
  responsive: boolean;
  // 是否自动布局
  auto: boolean;
}

