import { isEqual, mapValues, memoize } from 'lodash';

import { abs, max, min, round, sameNumber } from '@utils/globalUtils';
import { inflate, isEqualPoint, ISize, offsetBounds, offsetPoint, scale, union, rotatePoint } from '@utils/boundsUtils';

import { IConnectorLineValue, INoteValue } from '@fbs/rp/models/value';
import { IBounds, IPoint, IPosition } from '@fbs/common/models/common';
import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { IComponentData } from '@fbs/rp/models/component';
import ILine from '@fbs/rp/models/properties/line';

import { ComponentChange, DynamicEditInfo, getMinMaxXY } from '@editor/comps/resizeHelper';
import { UIComponent, UIConnectorComponent, UIContainerComponent } from '@editor/comps';

import { CLine, CStickNote } from '@libs/constants';

import { getAllMiddlePointByCornerPoint, getRectPoints } from './rotateHelper';
import { coverPatches } from './patchHelper';
import { scalePath } from './pathHelper';

interface IConnectControlPoint {
  x: number;
  y: number;
  direction: string;
}

const ndarray = require('ndarray');
const createPlanner = require('l1-path-finder');

// 组件的保护区域，该区域内，不画连接线
export const DELTA = 15;

// 创建墙
function buildBlocks(
  nda: any,
  bounds: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  }[],
) {
  bounds.forEach((bounds) => {
    for (let i = bounds.left; i <= bounds.right; i++) {
      nda.set(i, bounds.top, 1);
      nda.set(i, bounds.bottom, 1);
    }
    for (let i = bounds.top; i <= bounds.bottom; i++) {
      nda.set(bounds.left, i, 1);
      nda.set(bounds.right, i, 1);
    }
  });
}

/**
 * 计算当前容器中的所有组件基于容器左上角形成的大区域的bounds
 * @param component
 */
function getViewBoundsOfContainerComps(component: UIContainerComponent) {
  const bounds = union(
    ...component.components.filter((comp) => !comp.isConnector).map((comp) => comp.getViewBoundsInParent()),
  );
  const inflateBounds = inflate(bounds, { left: DELTA * 3, top: DELTA * 3 });
  return { bigBounds: inflateBounds };
}

/**
 * 获取大区域时的缩放比
 * 取max(宽， 高)，当位数大于 3 位，位数每增加一位，新缩放比为0.1的 numLen - 3 次方
 * @param bounds
 */
function calculatePathScaleNum(bounds: IBounds) {
  let scaleNum = 1;
  const maxNum = round(max(bounds.width, bounds.height));
  const numLen = maxNum.toString().length;
  if (numLen > 3) {
    scaleNum = Math.pow(0.1, numLen - 3);
  }
  return scaleNum;
}

/**
 * 计算 scaleNum 和 nda
 * @param { IBounds } bounds  最大边距，作为判断是否使用缓存的依据
 * @returns {scaleNum: number; nda: View2darray} nda为三方库计算结果(路线矩阵)
 */
const getScaleAndNda = memoize(
  (bounds: IBounds) => {
    //获取缩放比
    const scaleNum = calculatePathScaleNum(bounds);
    const newBound = scale(bounds, scaleNum);
    //这里对搜索范围扩大了DELTA * 2, 减少边界寻路失败
    const size = { width: newBound.width + DELTA * 2, height: newBound.height + DELTA * 2 };
    const arr = new Array(round(size.width * size.height)).fill(0);
    const nda = ndarray(arr, [round(size.width), round(size.height)]);
    return { scaleNum, nda };
  },
  (bounds) => JSON.stringify(bounds),
);

/**
 * 创建路径
 * @param {IBounds}  bounds 是 nda 变化的唯一影响因素, 回调中不用判断nda
 * @param {Partial<IBounds>[]} buildBlocks 障碍组件位置集合
 * @param {View2darray} nda 路径矩阵
 * @returns {L1PathPlanner} planner 通过路径矩阵创建的路径对象
 */
const createPathPlanner = memoize(
  (_bounds, blockCompsAllBounds, nda) => {
    buildBlocks(nda, blockCompsAllBounds);
    return createPlanner(nda);
  },
  (bounds, blockCompsAllBounds) => {
    return JSON.stringify(bounds) + JSON.stringify(blockCompsAllBounds);
  },
);

/**
 * 获取寻路的planner
 * @param {UIContainerComponent} container 容器组件
 * @param {string[]} blockCompsIds 需要绕过的组件ID
 * @return {planner: L1PathPlanner, bigBounds: IBounds, scaleNum: number}
 */
export function getPathPlannerOfContainer(container: UIContainerComponent, blockCompsIds?: string[]) {
  //获取container中所有组件构成的范围数据
  const { bigBounds: bounds } = getViewBoundsOfContainerComps(container);
  const { scaleNum, nda } = getScaleAndNda(bounds);
  const blockCompsAllBounds: Partial<IBounds>[] = [];

  container.components.forEach((comp) => {
    const isBlockComp =
      !comp.isConnector && comp.type !== CLine && !comp.locked && (!blockCompsIds || blockCompsIds.includes(comp.id));
    if (isBlockComp) {
      const compBounds = comp.getViewBoundsInParent();
      const newBounds = offsetBounds(compBounds, { left: -bounds.left, top: -bounds.top });
      const scaleBounds = scale(newBounds, scaleNum);
      const delta = DELTA * scaleNum;
      blockCompsAllBounds.push({
        left: max(round(scaleBounds.left - delta + 1 + DELTA), 0),
        right: max(round(scaleBounds.right + delta - 1 + DELTA), 0),
        top: max(round(scaleBounds.top - delta + 1 + DELTA), 0),
        bottom: max(round(scaleBounds.bottom + delta - 1 + DELTA), 0),
      });
    }
  });

  const planner = createPathPlanner(bounds, blockCompsAllBounds, nda);
  return {
    planner,
    bigBounds: bounds,
    scaleNum,
  };
}

/**
 * 路径查询搜索
 * @param startPoint
 * @param endPoint
 * @param planner
 * @param bounds
 * @param scaleNum
 */
export function searchPathByPlanner(
  startPoint: IPoint,
  endPoint: IPoint,
  planner: any,
  bounds: IBounds,
  scaleNum: number,
) {
  let path: number[] = [];
  //开始与结束点相同，直接返回空数组
  if (sameNumber(startPoint.x, endPoint.x) && sameNumber(startPoint.y, endPoint.y)) {
    return path;
  }

  const realP1 = {
    x: round((startPoint.x - bounds.left) * scaleNum),
    y: round((startPoint.y - bounds.top) * scaleNum),
  };

  const realP2 = {
    x: round((endPoint.x - bounds.left) * scaleNum),
    y: round((endPoint.y - bounds.top) * scaleNum),
  };

  //大区域下，缩放后可能存在搜索点相同的情况
  if (sameNumber(realP1.x, realP2.x) && sameNumber(realP1.y, realP2.y)) {
    return path;
  }
  planner.search(realP1.x + DELTA, realP1.y + DELTA, realP2.x + DELTA, realP2.y + DELTA, path);
  //得到的路径可能有在一条直线上的，这里去掉在一条直线上多余的点
  path = removePathPointByLine(path);
  const len = path.length;
  if (path.length >= 4) {
    const startDirection = sameNumber(path[0], path[2]) ? 'vertical' : 'horizontal';
    const endDirection = sameNumber(path[len - 4], path[len - 2]) ? 'vertical' : 'horizontal';
    path = path.map((p, index) => {
      //进行缩放计算过，这里进行开始和结束点的矫正
      if (scaleNum !== 1) {
        if (index === len - 2) return endPoint.x;
        if (index === len - 1) return endPoint.y;

        if (endDirection === 'horizontal' && index === len - 3) return endPoint.y;
        if (endDirection === 'vertical' && index === len - 4) return endPoint.x;

        if (index === 0) return startPoint.x;
        if (index === 1) return startPoint.y;

        if (startDirection === 'horizontal' && index === 3) return startPoint.y;
        if (startDirection === 'vertical' && index === 2) return startPoint.x;
      }

      if (index % 2 === 0) {
        return round((p - DELTA) / scaleNum + bounds.left);
      } else {
        return round((p - DELTA) / scaleNum + bounds.top);
      }
    });
  }

  // TEMP: 修正大范围路径时，开始和起始点缩放后在一条直线上, 上一步路径修正的需要插入的问题
  const newPoint = convertPointArrayToPoint(path);
  if (scaleNum !== 1 && newPoint.length && !isSamePoint(newPoint[0], endPoint)) {
    path.unshift(startPoint.x, startPoint.y);
  }

  return path;
}

//当自动寻路失败时，硬寻路
export function searchPathBySelf(start: IPoint, end: IPoint): number[] {
  const centerPoint = {
    x: end.x,
    y: start.y,
  };
  let arr: number[] = [start.x, start.y, centerPoint.x, centerPoint.y, end.x, end.y];
  if (isEqualPoint(start, centerPoint) || isEqualPoint(centerPoint, end)) {
    arr = [start.x, start.y, end.x, end.y];
  }
  return arr;
}

// 获取真实的路径端点
export function getRealPoint(
  container: UIContainerComponent,
  connectPoint: {
    id: string;
    direction: string;
    x?: number;
    y?: number;
  },
): IPoint {
  if (!connectPoint.id) {
    return {
      x: round(connectPoint.x || 0),
      y: round(connectPoint.y || 0),
    };
  }
  const comp = container.components.find((comp) => comp.id === connectPoint.id);
  if (!comp) {
    return {
      x: connectPoint.x || 0,
      y: connectPoint.y || 0,
    };
  }
  const { position, size } = comp;
  const boxPoints = getRectPoints(position, size, comp.rotate);
  const [topMiddle, rightMiddle, bottomMiddle, leftMiddle] = getAllMiddlePointByCornerPoint(boxPoints);
  switch (connectPoint.direction) {
    case 'left':
      return {
        x: round(leftMiddle.x),
        y: round(leftMiddle.y),
      };
    case 'top':
      return {
        x: round(topMiddle.x),
        y: round(topMiddle.y),
      };
    case 'right':
      return {
        x: round(rightMiddle.x),
        y: round(rightMiddle.y),
      };
    case 'bottom':
      return {
        x: round(bottomMiddle.x),
        y: round(bottomMiddle.y),
      };
  }
  return {
    x: round(connectPoint.x || 0),
    y: round(connectPoint.y || 0),
  };
}

type Direction = 'left' | 'right' | 'bottom' | 'top';
type AngleInfo = {
  [key: string]: number;
  left: number;
  right: number;
  top: number;
  bottom: number;
};

export function getAllEndPosition(
  comp: UIComponent,
): {
  left: Direction;
  right: Direction;
  top: Direction;
  bottom: Direction;
} {
  const { size, rotate } = comp;
  const initAngle: AngleInfo = {
    left: 270,
    top: 0,
    right: 90,
    bottom: 180,
  };
  return mapValues<AngleInfo, Direction>(initAngle, (value, key) => {
    const endAngle = (initAngle[key] + rotate) % 360;
    return getEndPosition(endAngle, size);
  });
}

export function getEndPosition(endAngle: number, size: ISize) {
  const angle = (Math.atan(size.width / size.height) / Math.PI) * 180;
  //这代表了上下左右四个区域
  const targetArea = {
    left: [270 - angle, 360 - angle],
    top: [360 - angle, 90 - angle],
    right: [90 - angle, 90 + angle],
    bottom: [90 + angle, 270 - angle],
  };
  //遍历目标区域，如果endAngle落在其中,则返回对应的key值
  return Object.entries(targetArea).reduce((acc, [direction, range]) => {
    if (isAngleInRange(endAngle % 360, range)) {
      acc = direction as Direction;
    }
    return acc;
  }, 'left' as Direction);
}

export function isAngleInRange(angle: number, range: number[]) {
  const [rangeNum1, rangeNum2] = range;
  if (rangeNum1 < rangeNum2) {
    return rangeNum1 < angle && angle <= rangeNum2;
  }
  // num2小，num1大
  else {
    return (angle >= 0 && angle <= rangeNum2) || (angle > rangeNum1 && angle <= 360);
  }
}

// 搜索点，在真实点上向外有拓展，避免挨着组件走
export function getSearchPoint(
  container: UIContainerComponent,
  connectPoint: {
    id: string;
    direction: string;
    x?: number;
    y?: number;
  },
): IPoint {
  if (!connectPoint.id) {
    return {
      x: round(connectPoint.x || 0),
      y: round(connectPoint.y || 0),
    };
  }
  const comp = container.components.find((comp) => comp.id === connectPoint.id);
  if (!comp) {
    return {
      x: connectPoint.x || 0,
      y: connectPoint.y || 0,
    };
  }
  const { position, size, rotate } = comp;
  const [topMiddle, rightMiddle, bottomMiddle, leftMiddle] = getAllMiddlePointByCornerPoint(
    getRectPoints(position, size, rotate),
  );
  const endPosition = getAllEndPosition(comp);
  const endPositionMap: { [key: string]: { x: number; y: number } } = {
    left: { x: -DELTA, y: 0 },
    top: { x: 0, y: -DELTA },
    right: { x: DELTA, y: 0 },
    bottom: { x: 0, y: DELTA },
  };
  switch (connectPoint.direction) {
    case 'left': {
      const myEndPosition = endPosition['left'];
      const offset = endPositionMap[myEndPosition];
      return {
        x: round(leftMiddle.x + offset.x),
        y: round(leftMiddle.y + offset.y),
      };
    }
    case 'top': {
      const myEndPosition = endPosition['top'];
      const offset = endPositionMap[myEndPosition];
      return {
        x: round(topMiddle.x + offset.x),
        y: round(topMiddle.y + offset.y),
      };
    }
    case 'right': {
      const myEndPosition = endPosition['right'];
      const offset = endPositionMap[myEndPosition];
      return {
        x: round(rightMiddle.x + offset.x),
        y: round(rightMiddle.y + offset.y),
      };
    }
    case 'bottom': {
      const myEndPosition = endPosition['bottom'];
      const offset = endPositionMap[myEndPosition];
      return {
        x: round(bottomMiddle.x + offset.x),
        y: round(bottomMiddle.y + offset.y),
      };
    }
  }
  return {
    x: round(connectPoint.x || 0),
    y: round(connectPoint.y || 0),
  };
}

/**
 * 根据paths的点，获取构成区域的左上及右下点
 * @param paths
 */
function getMinAMaxPoint(
  paths: number[],
): {
  minPoint: IPoint;
  maxPoint: IPoint;
} {
  const xArr = paths.filter((val, index) => index % 2 === 0);
  const yArr = paths.filter((val, index) => index % 2 !== 0);

  if (!xArr.length || !yArr.length) {
    return {
      minPoint: { x: 0, y: 0 },
      maxPoint: { x: 0, y: 0 },
    };
  }

  let minX = Math.min(...xArr);
  let maxX = Math.max(...xArr);
  let minY = Math.min(...yArr);
  let maxY = Math.max(...yArr);
  return {
    minPoint: { x: minX, y: minY },
    maxPoint: { x: maxX, y: maxY },
  };
}

/**
 * 根据paths 计算position
 * @param paths
 */
export function getPositionByPaths(paths: number[]) {
  const { minPoint } = getMinAMaxPoint(paths);
  return {
    x: minPoint.x,
    y: minPoint.y,
  };
}

/**
 * 根据paths 计算size
 * @param paths
 */
export function getSizeByPaths(paths: number[]) {
  const { minPoint, maxPoint } = getMinAMaxPoint(paths);
  return {
    width: max(maxPoint.x - minPoint.x, 1),
    height: max(maxPoint.y - minPoint.y, 1),
  };
}

/**
 * 将使用一维数组表示的点转换为点数组， 并进行偏移
 * @param path
 * @param offset
 */
export function convertPointArrayToPoint(
  path: number[],
  offset: { offsetX: number; offsetY: number } = {
    offsetX: 0,
    offsetY: 0,
  },
): IPoint[] {
  const ps: IPoint[] = [];
  const { offsetX, offsetY } = offset;
  for (let i = 0; i < path.length / 2; i++) {
    ps.push({
      x: path[i * 2] + offsetX,
      y: path[i * 2 + 1] + offsetY,
    });
  }
  return ps;
}

/**
 * 将点数组转换为一维数组
 *
 * [{x: 100, y: 200}, {x: 100, y: 250}] => [100, 200, 100, 250]
 *
 * @export
 * @param {IPoint[]} points
 * @returns {*}  {number[]}
 */
export function convertPointsToArray(points: IPoint[]): number[] {
  return points.reduce((prev, curr) => {
    prev.push(curr.x, curr.y);
    return prev;
  }, [] as number[]);
}

/**
 * 偏移路径
 * @param path
 * @param offset
 */
export function offsetPointArray(
  path: number[],
  offset: { offsetX: number; offsetY: number } = {
    offsetX: 0,
    offsetY: 0,
  },
): number[] {
  const ps: number[] = [];
  const { offsetX, offsetY } = offset;
  for (let i = 0; i < path.length / 2; i++) {
    ps.push(path[i * 2] + offsetX, path[i * 2 + 1] + offsetY);
  }
  return ps;
}

//反转路径，让路径是从起始点或结束点开始
function reversePath(path: number[]) {
  const ps: number[] = [];
  for (let i = path.length / 2 - 1; i >= 0; i--) {
    ps.push(path[i * 2], path[i * 2 + 1]);
  }
  return ps;
}

/**
 * 根据真实的点转换路径搜索的点
 * @param realPoint
 * @param direction
 */
export function getSearchPointByRealPoint(realPoint: IPoint, direction: string) {
  switch (direction) {
    case 'left':
      return {
        x: realPoint.x - DELTA,
        y: realPoint.y,
      };
    case 'top':
      return {
        x: realPoint.x,
        y: realPoint.y - DELTA,
      };
    case 'right':
      return {
        x: realPoint.x + DELTA,
        y: realPoint.y,
      };
    case 'bottom':
      return {
        x: realPoint.x,
        y: realPoint.y + DELTA,
      };
    default:
      return realPoint;
  }
}

/**
 * 根据组件和传入的方向获取连接点
 *
 * @param component
 * @param direction
 */
export function getPointByComponent(
  component: { position: IPosition; size: ISize; rotate: number },
  direction: string,
) {
  const { position, size, rotate } = component;
  const [topMiddle, rightMiddle, bottomMiddle, leftMiddle] = getAllMiddlePointByCornerPoint(
    getRectPoints(position, size, rotate),
  );
  switch (direction) {
    case 'left':
      return {
        x: round(leftMiddle.x),
        y: round(leftMiddle.y),
      };
    case 'top':
      return {
        x: round(topMiddle.x),
        y: round(topMiddle.y),
      };
    case 'right':
      return {
        x: round(rightMiddle.x),
        y: round(rightMiddle.y),
      };
    case 'bottom':
      return {
        x: round(bottomMiddle.x),
        y: round(bottomMiddle.y),
      };
    default:
      return {
        x: 0,
        y: 0,
      };
  }
}

//------------------------------------------------渲染流程线组件相关-----------------------------------------------------
/**
 * 获取路径上开始或结束端的2个点
 *
 * 由于反转了路径，point1 === endPoint
 *
 * @param path
 * @param isStart
 */
export function getPathStartOrEndTwoPoint(path: number[], isStart: boolean = true) {
  const newPath = isStart ? path : reversePath(path);
  return {
    point1: {
      x: newPath[0] ?? 0,
      y: newPath[1] ?? 0,
    },
    point2: {
      x: newPath[2] ?? 0,
      y: newPath[3] ?? 0,
    },
  };
}

/**
 * 获取路径开始或结束的方向
 *
 * @param {number[]} path
 * @param {boolean} [isStart=true]
 * @returns {*}
 */
export function getPathStartOrEndDirection(path: number[], isStart: boolean = true) {
  if (path.length >= 4) {
    const { point1, point2 } = getPathStartOrEndTwoPoint(path, isStart);
    return getDirectionByPoints(point1, point2);
  }
  return '';
}

/**
 * 整体偏移路径的XY后， 对开始和结束点的修正
 * @param path
 * @param offset
 * @param isStart
 */
function modifyStartOrEndOffsetByDirection(path: number[], offset: number, isStart: boolean = true) {
  const len = path.length;
  const direction = getPathStartOrEndDirection(path, isStart);
  if (['arrow-down', 'arrow-up'].includes(direction)) {
    if (isStart) {
      path[1] -= offset;
    } else {
      path[len - 1] -= offset;
    }
  } else if (['arrow-left', 'arrow-right'].includes(direction)) {
    if (isStart) {
      path[0] -= offset;
    } else {
      path[len - 2] -= offset;
    }
  }
}

/**
 * 偏移对应的线条，保证不虚化
 *
 * @param {number[]} path
 * @param {number} lineWidth
 */
export function offsetPathWhenRender(path: number[], lineWidth: number) {
  let newPath: number[] = [];
  const offset = (lineWidth % 2) / 2;
  newPath = path.map((num) => {
    return num + offset;
  });
  modifyStartOrEndOffsetByDirection(newPath, offset);
  modifyStartOrEndOffsetByDirection(newPath, offset, false);
  return newPath;
}

/**
 * 判断开始和结束的相邻2个点的方向， 进行特殊处理
 *
 * @param path
 */
export function modifyPathWhenRender(path: number[]) {
  const newPath = [...path];
  const startPoint1 = { x: newPath[0], y: newPath[1] };
  const startPoint2 = { x: newPath[2], y: newPath[3] };
  const startPoint3 = { x: newPath[4], y: newPath[5] };
  //反向 或 拓展点超过拐点
  if (isExtendReverse(startPoint1, startPoint2, startPoint3) || isExtendOver(startPoint1, startPoint2, startPoint3)) {
    newPath.splice(2, 2);
  }

  const len = newPath.length;
  const endPoint1 = { x: newPath[len - 2], y: newPath[len - 1] };
  const endPoint2 = { x: newPath[len - 4], y: newPath[len - 3] };
  const endPoint3 = { x: newPath[len - 6], y: newPath[len - 5] };

  if (isExtendReverse(endPoint1, endPoint2, endPoint3) || isExtendOver(endPoint1, endPoint2, endPoint3)) {
    newPath.splice(len - 4, 2);
  }

  return newPath;
}

function modifyPathWithArrow(paths: number[], line: ILine, startClipSize: ISize, endClipSize: ISize) {
  const { startArrow, endArrow } = line;
  const newPaths = [...paths];
  if (startArrow) {
    newPaths[0] += startClipSize.width;
    newPaths[1] += startClipSize.height;
  }

  if (endArrow) {
    const len = newPaths.length;
    newPaths[len - 2] += endClipSize.width;
    newPaths[len - 1] += endClipSize.height;
  }

  return newPaths;
}

/**
 * 构造流程线的线段
 * @param oldPaths
 * @param strokeWidth
 * @param isShow
 */
export function parseLineStr(
  oldPaths: number[],
  strokeWidth: number,
  line: ILine,
  startClipSize: ISize,
  endClipSize: ISize,
): string {
  let paths = offsetPathWhenRender(oldPaths, strokeWidth);
  paths = modifyPathWithArrow(paths, line, startClipSize, endClipSize);
  paths = modifyPathWhenRender(paths);
  const commands: string[] = [];
  for (let i = 0; i < paths.length / 2; i++) {
    if (i === 0) {
      commands.push(`M ${paths[i]} ${paths[i + 1]}`);
      continue;
    }
    commands.push(`L ${paths[i * 2]} ${paths[i * 2 + 1]}`);
  }
  return commands.join(' ');
}

/**
 * 获取编辑器中，路径的控制点
 *
 * @export
 * @param {number[]} paths
 * @param {number} strokeWidth
 * @returns {*}
 */
export function getPathControlPoints(
  paths: number[],
  strokeWidth: number,
): { centerPoints: IConnectControlPoint[]; startControl: IPoint; endControl: IPoint } {
  const offsetPaths = offsetPathWhenRender(paths, strokeWidth);
  const points = convertPointArrayToPoint(offsetPaths);
  const centerPoints: IConnectControlPoint[] = [];
  //查找2个点的中点
  for (let i = 0; i < points.length; i++) {
    const point1X = points[i].x;
    const point1Y = points[i].y;
    if (i + 1 < points.length) {
      const point2X = points[i + 1].x;
      const point2Y = points[i + 1].y;
      let centerPX = min(point1X, point2X) + abs(point2X - point1X) / 2;
      let centerPY = min(point1Y, point2Y) + abs(point2Y - point1Y) / 2;
      const canDragDirection = point1X === point2X ? 'horizontal' : 'vertical';
      if (i === 0) {
        canDragDirection === 'horizontal' ? (centerPY = point1Y) : (centerPX = point1X);
      }
      if (i === points.length - 2) {
        canDragDirection === 'horizontal' ? (centerPY = point2Y) : (centerPX = point2X);
      }
      centerPoints.push({ x: centerPX, y: centerPY, direction: canDragDirection });
    }
  }
  return { centerPoints, startControl: centerPoints[0], endControl: centerPoints[points.length - 2] };
}

/**
 * 根据路径的长度, 返回路径中点点，用于文本中心定位
 *
 * @param path
 */
export function getMidpointWithPath(path: number[]): IPoint {
  let pathLength: number = 0;
  let pathLengthArr: number[] = []; //记录每个点相对起点的距离
  const points = convertPointArrayToPoint(path, { offsetX: 0, offsetY: 0 });
  for (let i = 0, len = points.length; i < len - 1; i++) {
    const p1 = points[i];
    const p2 = points[i + 1];
    //由于都是水平或垂直的线，才这样直接求距离
    pathLength += abs(p1.x - p2.x) + abs(p1.y - p2.y);
    pathLengthArr.push(pathLength);
  }

  //查找中点在哪段路径上
  const pathLengthHalf = pathLength / 2;
  let cursor = 0;
  let absDistance = 0;
  while (cursor < pathLengthArr.length - 1) {
    if (pathLengthArr[cursor] < pathLengthHalf && pathLengthArr[cursor + 1] >= pathLengthHalf) {
      absDistance = pathLengthHalf - pathLengthArr[cursor];
      cursor++;
      break;
    }
    cursor++;
  }

  //根据下标找到对应的点，计算中点
  const beforePoint = points[cursor];
  const afterPoint = points[cursor + 1];
  const midpoint: IPoint = { ...beforePoint };
  if (beforePoint && afterPoint && beforePoint.x === afterPoint.x) {
    midpoint.y = afterPoint.y > beforePoint.y ? beforePoint.y + absDistance : beforePoint.y - absDistance;
  }

  if (beforePoint && afterPoint && beforePoint.y === afterPoint.y) {
    midpoint.x = afterPoint.x > beforePoint.x ? beforePoint.x + absDistance : beforePoint.x - absDistance;
  }

  return midpoint || { x: 0, y: 0 };
}

export function getManhattanDistanceOfTwoPoints(point1: IPoint, point2: IPoint): number {
  return abs(point1.x - point2.x) + abs(point1.y - point2.y);
}

//---------------------------------------手动控制，调整路径----------------------------------------------------------

/**
 * 根据点计算方向, 这里的开始和下一个点都在一个水平或竖直方向
 *
 * @param begin 开始点
 * @param next 下一个点
 */
export function getDirectionByPoints(begin: IPoint, next: IPoint) {
  if (sameNumber(begin.x, next.x) && sameNumber(begin.y, next.y)) {
    return 'arrow-center';
  }

  if (sameNumber(begin.x, next.x)) {
    if (begin.y < next.y) {
      return 'arrow-down';
    } else {
      return 'arrow-up';
    }
  }

  if (sameNumber(begin.y, next.y)) {
    if (begin.x > next.x) {
      return 'arrow-left';
    } else {
      return 'arrow-right';
    }
  }
  return '';
}

/**
 * 整理路径， 去掉在一条直线上中间的点, 可配置是否去重, 指定路径的哪段去除中间点
 */
export function removePathPointByLine(
  paths: number[],
  config?: {
    beginCursor?: number; // 从第几个点开始去掉
    endCursor?: number; // 从倒数第几个点结束
    removeSamePoint?: boolean;
  },
): number[] {
  const newPaths = [...paths];
  const points = convertPointArrayToPoint(newPaths);
  const pointLength = points.length;

  let removePointIndex: number[] = [];
  let endIndex: number = pointLength - (config?.endCursor || 1);
  // 参照点的索引
  let targetCursor: number = config?.beginCursor || 0;
  // 比较点的索引
  let compareCursor = targetCursor + 1;
  //当前比较方向
  let direction = '';
  //在一条直线的点的index
  let linePointIndex: number[] = [];

  while (targetCursor < pointLength - 1) {
    //比较到指定的退出索引位置，退出循环, 截取记录直线点的数组除开始结束的中间部分
    if (compareCursor > endIndex) {
      removePointIndex.push(...linePointIndex.slice(1, linePointIndex.length - 1));
      break;
    }

    const targetPoint = points[targetCursor];
    const comparePoint = points[compareCursor];

    // 首次进入, 通过参照点, 确定这条线的方向，记录在这条线上的点
    if (targetPoint.x === comparePoint.x && !direction) {
      direction = 'vertical';
      linePointIndex.push(targetCursor);
    }
    if (targetPoint.y === comparePoint.y && !direction) {
      direction = 'horizontal';
      linePointIndex.push(targetCursor);
    }

    //方向已存在，则判断后面的点与之是否在一条直线上
    //在一条直线上，记录这个点，并移动比较索引,继续循环
    //不在一条直线上，方向重置， 移动参照索引, 比较索引，截取记录直线点的数组除开始结束的中间部分, 清空记录直线点的数组
    if (
      (direction === 'vertical' && sameNumber(targetPoint.x, comparePoint.x)) ||
      (direction === 'horizontal' && sameNumber(targetPoint.y, comparePoint.y))
    ) {
      linePointIndex.push(compareCursor);
      compareCursor++;
    } else {
      if (linePointIndex.length > 2) {
        targetCursor = linePointIndex[linePointIndex.length - 1];
        removePointIndex.push(...linePointIndex.slice(1, linePointIndex.length - 1));
      } else {
        targetCursor++;
      }
      direction = '';
      compareCursor = targetCursor + 1;
      linePointIndex = [];
    }
  }
  //移除在一条直线中间的点
  let newPoints = points.filter((point, index) => !removePointIndex.includes(index));

  //删除重复的点
  if (config?.removeSamePoint) {
    newPoints = removeSamePathPoint(newPoints);
  }

  const arr: number[] = [];
  newPoints.forEach((point) => {
    arr.push(point.x, point.y);
  });
  return arr;
}

/**
 * 去掉相同的点
 * @param points
 * @param startCursor
 */
function removeSamePathPoint(points: IPoint[], startCursor: number = 0): IPoint[] {
  const newPoints = [...points];
  //指针, 忽略开始的保护点
  let targetCursor = startCursor;
  let compareCursor = targetCursor + 1;
  let sameIndex: number[] = [];
  while (targetCursor < newPoints.length - 1 && compareCursor < newPoints.length) {
    const targetPoint = newPoints[targetCursor];
    const comparePoint = newPoints[compareCursor];
    if (targetPoint.x === comparePoint.x && targetPoint.y === comparePoint.y) {
      //除了首尾会自动添加重复点，如果中间出现了重复点，说明前后2个线段出现了重叠，此时应该删除中间全部
      if (targetCursor > 1 && compareCursor < newPoints.length - 2) {
        sameIndex.push(targetCursor, compareCursor);
      } else {
        sameIndex.push(compareCursor);
      }
    }
    targetCursor++;
    compareCursor++;
  }

  return newPoints.filter((p, index) => !sameIndex.includes(index));
}

//判断是否具有拓展点
function hasExtendPoint(point1: IPoint, point2: IPoint) {
  return sameNumber(getManhattanDistanceOfTwoPoints(point1, point2), DELTA);
}

//判断得到的方向是否相反
function isOppositeDirection(direction1: string, direction2: string): boolean {
  return (
    (direction1 === 'arrow-down' && direction2 === 'arrow-up') ||
    (direction1 === 'arrow-up' && direction2 === 'arrow-down') ||
    (direction1 === 'arrow-left' && direction2 === 'arrow-right') ||
    (direction1 === 'arrow-right' && direction2 === 'arrow-left')
  );
}

//判断得到的方向是否相同
function isSameDirection(direction1: string, direction2: string): boolean {
  return (
    (direction1 === 'arrow-down' && direction2 === 'arrow-down') ||
    (direction1 === 'arrow-up' && direction2 === 'arrow-up') ||
    (direction1 === 'arrow-left' && direction2 === 'arrow-left') ||
    (direction1 === 'arrow-right' && direction2 === 'arrow-right') ||
    (direction1 === '' && direction2 === '') ||
    direction1 === 'arrow-center' ||
    direction2 === 'arrow-center'
  );
}

/**
 * 判断3 是否在1, 2 之间
 *
 * @param point1
 * @param point2
 * @param point3
 */
function isExtendOver(point1: IPoint, point2: IPoint, point3: IPoint) {
  const startDirection = getDirectionByPoints(point1, point2);
  const secondDirection = getDirectionByPoints(point1, point3);
  if (isSameDirection(startDirection, secondDirection)) {
    const distance1 = getManhattanDistanceOfTwoPoints(point1, point2);
    const distance2 = getManhattanDistanceOfTwoPoints(point1, point3);
    if (distance1 >= distance2) {
      return true;
    }
  }
  return false;
}

//判断是否应该反向
function isExtendReverse(point1: IPoint, point2: IPoint, point3: IPoint) {
  const startDirection = getDirectionByPoints(point1, point2);
  const secondDirection = getDirectionByPoints(point1, point3);
  return isOppositeDirection(startDirection, secondDirection);
}

//获取拓展点
function getExtendPoint(point1: IPoint, point2: IPoint): IPoint {
  let extendPoint = { x: point1.x, y: point1.y };
  if (point1.x === point2.x && point1.y < point2.y) {
    extendPoint.y += DELTA;
  }

  if (point1.x === point2.x && point1.y > point2.y) {
    extendPoint.y -= DELTA;
  }

  if (point1.y === point2.y && point1.x < point2.x) {
    extendPoint.x += DELTA;
  }

  if (point1.y === point2.y && point1.x > point2.x) {
    extendPoint.x -= DELTA;
  }

  return extendPoint;
}

export function isSamePoint(point1: IPoint, point2: IPoint, offset: number = 0): boolean {
  return sameNumber(point1.x, point2.x, offset) && sameNumber(point1.y, point2.y, offset);
}

/**
 * 判断并增加拓展点
 * @param path
 */
export function judgeExtendWithPut(path: number[]) {
  const newPath = [...path];
  const startPoint1 = { x: path[0], y: path[1] };
  const startPoint2 = { x: path[2], y: path[3] };
  const len = path.length;
  const endPoint1 = { x: path[len - 2], y: path[len - 1] };
  const endPoint2 = { x: path[len - 4], y: path[len - 3] };

  if (getManhattanDistanceOfTwoPoints(endPoint1, endPoint2) > DELTA) {
    const snapExtendPoint = getExtendPoint(endPoint1, endPoint2);
    newPath.splice(len - 2, 0, snapExtendPoint.x, snapExtendPoint.y);
  }

  if (getManhattanDistanceOfTwoPoints(startPoint1, startPoint2) > DELTA) {
    const snapExtendPoint = getExtendPoint(startPoint1, startPoint2);
    newPath.splice(2, 0, snapExtendPoint.x, snapExtendPoint.y);
  }

  return newPath;
}

/**
 * 流程线手动调整时的自动吸附
 * @param path
 * @param index
 * @param direction
 * @param AdsorptionDistance
 */
export function autoAdsorption(
  path: number[],
  index: number,
  direction: 'vertical' | 'horizontal',
  AdsorptionDistance: number = 5,
) {
  let newPath = [...path];
  const offsetIndex = direction === 'vertical' ? 1 : 0;
  const lastCursor = (index - 1) * 2 + offsetIndex;
  const nextCursor = (index + 2) * 2 + offsetIndex;
  const modifyLastCursor = index * 2 + offsetIndex;
  const modifyNextCursor = (index + 1) * 2 + offsetIndex;
  if (index > 1 && index + 2 < newPath.length / 2 - 1) {
    const lastCornerNum = newPath[lastCursor];
    const afterCornerNum = newPath[nextCursor];
    if (abs(newPath[modifyLastCursor] - lastCornerNum) < AdsorptionDistance) {
      newPath[modifyLastCursor] = newPath[modifyNextCursor] = lastCornerNum;
    }

    if (abs(newPath[modifyNextCursor] - afterCornerNum) < AdsorptionDistance) {
      newPath[modifyLastCursor] = newPath[modifyNextCursor] = afterCornerNum;
    }
  }
  return newPath;
}

export function autoAdsorptionWithDirection(
  path: number[],
  index: number,
  direction: 'vertical' | 'horizontal',
  AdsorptionDistance: number = 5,
) {
  let newPath = [...path];
  const len = path.length;
  if (index === len / 2 - 4 && len >= 8) {
    const endP1 = { x: path[len - 2], y: path[len - 1] };
    const endP2 = { x: path[len - 4], y: path[len - 3] };
    const endP3 = { x: path[len - 6], y: path[len - 5] };
    const endP4 = { x: path[len - 8], y: path[len - 7] };
    const hasExtend = hasExtendPoint(endP1, endP2);
    if (
      (hasExtend && getManhattanDistanceOfTwoPoints(endP1, endP3) < AdsorptionDistance) ||
      (!hasExtend && getManhattanDistanceOfTwoPoints(endP1, endP2) < AdsorptionDistance)
    ) {
      if (direction === 'horizontal') {
        newPath.splice(len - 8, 4, endP1.x, endP4.y, endP1.x, endP1.y);
      } else {
        newPath.splice(len - 8, 4, endP4.x, endP1.y, endP1.x, endP1.y);
      }
    }
  }

  if (index === 2 && len >= 8) {
    const startP1 = { x: path[0], y: path[1] };
    const startP2 = { x: path[2], y: path[3] };
    const startP3 = { x: path[4], y: path[5] };
    const startP4 = { x: path[6], y: path[7] };
    const hasExtend = hasExtendPoint(startP1, startP2);
    if (
      (hasExtend && getManhattanDistanceOfTwoPoints(startP1, startP3) < AdsorptionDistance) ||
      (!hasExtend && getManhattanDistanceOfTwoPoints(startP1, startP3) < AdsorptionDistance)
    ) {
      if (direction === 'horizontal') {
        newPath.splice(4, 4, startP1.x, startP1.y, startP1.x, startP4.y);
      } else {
        newPath.splice(4, 4, startP1.x, startP1.y, startP4.x, startP1.y);
      }
    }
  }
  return newPath;
}

export function shouldModifyStartPointForDirection(
  component: UIConnectorComponent,
  paths: number[],
  direction: string,
) {
  if (paths.length >= 6) {
    const startP1 = { x: paths[0], y: paths[1] };
    const startP2 = { x: paths[2], y: paths[3] };
    const startP3 = { x: paths[4], y: paths[5] };
    const startDirection = component.getStartDirection();
    if (
      getDirectionByPoints(startP1, startP2) === getDirectionByPoints(startP2, startP3) &&
      (((startDirection === 'left' || startDirection === 'right') && direction === 'horizontal') ||
        ((startDirection === 'top' || startDirection === 'bottom') && direction === 'vertical'))
    ) {
      return true;
    }
  }
  return false;
}

export function shouldModifyEndPointForDirection(component: UIConnectorComponent, paths: number[], direction: string) {
  const len = paths.length;
  if (len >= 6) {
    const endP1 = { x: paths[len - 2], y: paths[len - 1] };
    const endP2 = { x: paths[len - 4], y: paths[len - 3] };
    const endP3 = { x: paths[len - 6], y: paths[len - 5] };
    const endDirection = component.getEndDirection();
    if (
      getDirectionByPoints(endP1, endP2) === getDirectionByPoints(endP1, endP3) &&
      (((endDirection === 'left' || endDirection === 'right') && direction === 'horizontal') ||
        ((endDirection === 'top' || endDirection === 'bottom') && direction === 'vertical'))
    ) {
      return true;
    }
  }

  return false;
}

//-------------------------------------------数据提交及修正------------------------------------------

//连接线与哪个组件的哪一端有联系
interface IConnectRoundInfo {
  connectId: string; //流程线
  compId: string; //组件
  isStart: boolean; //关联的是头还是尾
}

/**
 * 过滤得到应该一起操作 和 不一起操作但有关联的流程线
 * 成组时， 开始端与选中组件有关系，则关联
 * 移动时，开始与结束都有关系，才关联
 * @param activeContainer
 * @param selectIds
 * @param isGroup 是否是成组
 *
 * @returns any
 */
export function getInnerOrAroundConnect(
  activeContainer: UIContainerComponent,
  selectIds: string[],
  isGroup: boolean = false,
): {
  innerConnectComps: UIConnectorComponent[]; //完全相关：两端都包含在选中的组件中, 或本身就被选中
  roundConnectComps: UIConnectorComponent[]; //单端相关: 有一端包含在选中的组件中
  aloneConnectComps: UIConnectorComponent[]; //单端相关孤立: 有一端包含在选中的组件中，另一端没有固定的组件
  notAloneConnectComps: UIConnectorComponent[]; //单端相关不孤立: 有一端包含在选中的组件中, 另一端有固定的组件
  roundMapInfo: IConnectRoundInfo[]; //单端相关的Map具体联系
} {
  //具体周围相关的是哪条线的哪个端点
  const roundMapInfo: IConnectRoundInfo[] = [];
  //应该关联选中的流程线(完全相关)
  const innerConnectComps: UIConnectorComponent[] = [];
  //周围有关系的流程线(有关)
  const roundConnectComps: UIConnectorComponent[] = activeContainer.components.filter((comp) => {
    if (comp.isConnector) {
      const component = comp as UIConnectorComponent;
      const { startPoint, endPoint } = component.value as IConnectorLineValue;
      //如果连接线被选中，肯定相关
      if (selectIds.includes(comp.id)) {
        innerConnectComps.push(component);
        return false;
      }
      //成组时，开始点与选中组件有联系则关联选中
      if (isGroup && selectIds.includes(startPoint.id)) {
        innerConnectComps.push(component);
      }
      //移动时，开始点及结束点都与选中组件有联系才关联选中
      if (!isGroup && selectIds.includes(startPoint.id) && selectIds.includes(endPoint.id)) {
        innerConnectComps.push(component);
      } else {
        if (selectIds.includes(startPoint.id)) {
          roundMapInfo.push({ connectId: comp.id, compId: startPoint.id, isStart: true });
          return true;
        }
        if (selectIds.includes(endPoint.id)) {
          roundMapInfo.push({ connectId: comp.id, compId: endPoint.id, isStart: false });
          return true;
        }
      }
    }
  }) as UIConnectorComponent[];
  const notAloneConnectComps: UIConnectorComponent[] = [];
  //周围有关系的流程线(如果没有选中的组件，则为孤立)
  const aloneConnectComps = roundConnectComps.filter((comp) => {
    if (comp.isConnector && (comp as UIConnectorComponent).isAloneLineAfterDelete([...selectIds])) {
      return true;
    }
    notAloneConnectComps.push(comp);
  });
  return { innerConnectComps, roundConnectComps, roundMapInfo, aloneConnectComps, notAloneConnectComps };
}

function modifyConnectPoint(
  activeContainer: UIContainerComponent,
  connect: UIConnectorComponent,
  allSelectIds: string[],
  diff: { offsetX: number; offsetY: number },
  roundMapInfo: IConnectRoundInfo[],
) {
  const { startPoint, endPoint } = connect.value as IConnectorLineValue;
  const newStart = { ...startPoint };
  const newEnd = { ...endPoint };
  const innerStartInfo = roundMapInfo.find((r) => r.connectId === connect.id && r.isStart);
  const roundEndInfo = roundMapInfo.find((r) => r.connectId === connect.id && !r.isStart);

  //如果是相关的是起始点，则需要解绑并偏移结束点的位置
  if (innerStartInfo && innerStartInfo.isStart) {
    const unSelectComp = activeContainer.components.find((comp) => comp.id === endPoint.id);
    if (!unSelectComp) {
      newEnd.id = endPoint.id;
      newEnd.direction = endPoint.direction;
      newEnd.x = endPoint.x + diff.offsetX;
      newEnd.y = endPoint.y + diff.offsetY;
    } else {
      if (!allSelectIds.includes(endPoint.id)) {
        const point = getPointByComponent(unSelectComp!, endPoint.direction);
        newEnd.x = point.x + diff.offsetX;
        newEnd.y = point.y + diff.offsetY;
      }
    }
  }

  //如果是结束点，则更新为当前的位置
  if (roundEndInfo) {
    const selectComp = activeContainer.components.find((comp) => comp.id === roundEndInfo.compId);
    if (!roundEndInfo.isStart && selectComp) {
      const point = getPointByComponent(selectComp!, endPoint.direction);
      newEnd.x = point.x;
      newEnd.y = point.y;
    }
  }

  return { newStart, newEnd };
}

/**
 * 获取连接线更新到后台的 patches
 *
 * @param activeContainer
 * @param connectComp
 * @param allSelectIds
 * @param diff
 * @param isRound 是否是相关流程线，如果是则更新点，重置path, 不是则2者都更新
 * @param roundMapInfo // 与选中组件有关系但不会一起操作的相关组件信息
 */
function getConnectPointAPathPatches(
  activeContainer: UIContainerComponent,
  connectComp: UIConnectorComponent[],
  allSelectIds: string[],
  diff: { offsetX: number; offsetY: number },
  isRound: boolean = false,
  roundMapInfo: IConnectRoundInfo[],
): ArtboardPatches {
  const patches: ArtboardPatches = { do: {}, undo: {} };
  connectComp.forEach((comp) => {
    const { startPoint, endPoint, path } = comp.value as IConnectorLineValue;
    const { newStart, newEnd } = modifyConnectPoint(activeContainer, comp, allSelectIds, diff, roundMapInfo);
    //是选中的，且有手动路径
    if (!isRound && path?.length) {
      const newPath: number[] = offsetPointArray(path, diff);
      patches.do[comp.id] = [Ops.replace('/value', { startPoint: newStart, endPoint: newEnd, path: newPath })];
      patches.undo[comp.id] = [Ops.replace('/value', { startPoint, endPoint, path })];
    } else {
      patches.do[comp.id] = [Ops.replace('/value', { startPoint: newStart, endPoint: newEnd })];
      patches.undo[comp.id] = [Ops.replace('/value', { startPoint, endPoint })];
    }
  });

  return patches;
}

/**
 * 删除组件时，相关需要删除的流程线的处理
 *
 * @param activeContainer
 * @param selectComponents
 */
export function removeConnectWhenDelete(
  activeContainer: UIContainerComponent,
  selectComponents: UIComponent[],
): ArtboardPatches {
  let patches: ArtboardPatches = { do: {}, undo: {} };
  const selectedIds = selectComponents.filter((comp) => !comp.isConnector).map((comp) => comp.id);
  //完全关联的删除， 关联且独立的删除
  const { innerConnectComps, aloneConnectComps } = getInnerOrAroundConnect(activeContainer, selectedIds);

  const removeIds: string[] = [];
  const unRemoveOperations: any[] = [];
  [...innerConnectComps, ...aloneConnectComps].forEach((comp) => {
    removeIds.push(comp.id);
    const index = activeContainer.components.findIndex((cp) => cp.id === comp.id);
    unRemoveOperations.push(Ops.addChildren(`${index}`, [comp.toJSON()]));
  });

  if (removeIds.length) {
    patches.do[activeContainer.id] = [Ops.removeChildren(removeIds)];
    patches.undo[activeContainer.id] = [...unRemoveOperations];
  }

  return patches;
}

/**
 * 更新成组时的相关点和位置的计算与偏移
 *
 * @param activeContainer
 * @param selectedComponents
 * @param diff
 * @param isGroup
 * @param isToOtherArtboard
 */
export function updateConnectByDiff(
  activeContainer: UIContainerComponent,
  selectedComponents: UIComponent[],
  diff: { offsetX: number; offsetY: number },
  isGroup: boolean = false,
  isToOtherArtboard: boolean = false,
) {
  //获取选中组件中除流程线的ID
  const selectedIds = selectedComponents.filter((comp) => !comp.isConnector).map((comp) => comp.id);
  //所有组件的ID
  const allSelectIds = selectedComponents.map((comp) => comp.id);
  //会一起选中的流程线 及周边有关系的流程线
  const { innerConnectComps, roundConnectComps, roundMapInfo } = getInnerOrAroundConnect(
    activeContainer,
    selectedIds,
    isGroup,
  );
  //会被一起操作的流程线，需要更新点的位置 及手动路径的位置
  const patches2 = getConnectPointAPathPatches(
    activeContainer,
    innerConnectComps as UIConnectorComponent[],
    allSelectIds,
    diff,
    false,
    roundMapInfo,
  );
  //不会一起操作但有关系的流程线， 需要更新点的位置 及 删除手动路径
  const newDiff = isToOtherArtboard ? { offsetX: 0, offsetY: 0 } : diff;
  const patches1 = getConnectPointAPathPatches(
    activeContainer,
    roundConnectComps as UIConnectorComponent[],
    allSelectIds,
    newDiff,
    true,
    roundMapInfo,
  );

  return coverPatches(patches1, patches2);
}

/**
 * 寻找当前选中的组件相关的连接线，重置或修正path
 *
 * 1. resize 时, 组件的position , size 都可能发生改变, 重置所有完全相关的连接线的属性， 单端相关的进行点的修正
 * 2. move 时， 组件只有 position 发生变化, 完全相关的进行 diff 处理点， 单端相关的进行点的修正
 *
 * @param activeContainer
 * @param changedComps
 * @param patches
 * @param diff
 * @param inGroup
 */
export function updateConnectComponentPatches(
  activeContainer: UIContainerComponent,
  changedComps: ComponentChange[],
  patches: ArtboardPatches,
  diff: { x: number; y: number } = { x: 0, y: 0 }, // group的position发生改变时, 内部的所有connect 都需要修改
  inGroup?: boolean,
) {
  const changedCompIds = changedComps.map((comp) => comp.id);
  const { innerConnectComps, roundConnectComps } = getInnerOrAroundConnect(activeContainer, changedCompIds, false);
  roundConnectComps.forEach((comp: UIConnectorComponent) => {
    const change = changedComps.find((cm) => cm.id === comp.getStartCompID() || cm.id === comp.getEndCompID())!;
    change && resetConnectPatch(comp, change, patches);
  });

  innerConnectComps.forEach((comp: UIConnectorComponent) => {
    const startId = comp.getStartCompID();
    const endId = comp.getEndCompID();
    const startChange = changedComps.find((cm) => cm.id === startId);
    const endChange = changedComps.find((cm) => cm.id === endId);
    const originStartChange = activeContainer.components.find((comp) => comp.id === startId);
    const originEndChange = activeContainer.components.find((comp) => comp.id === endId);

    const startPositionDiff = { x: 0, y: 0 };
    if (startChange && originStartChange) {
      startPositionDiff.x = startChange.position.x - originStartChange.position.x;
      startPositionDiff.y = startChange.position.y - originStartChange.position.y;
    }

    const endPositionDiff = { x: 0, y: 0 };
    if (endChange && originEndChange) {
      endPositionDiff.x = endChange.position.x - originEndChange.position.x;
      endPositionDiff.y = endChange.position.y - originEndChange.position.y;
    }

    // 如果为单端，且某一端移动了，直接整体移动
    if (startChange && originStartChange && !originEndChange) {
      modifyConnectPatch(comp, offsetPoint(startPositionDiff, { x: 0 - diff.x, y: 0 - diff.y }), patches);
      return;
    }

    if (endChange && !originStartChange && originEndChange) {
      modifyConnectPatch(comp, offsetPoint(endPositionDiff, { x: 0 - diff.x, y: 0 - diff.y }), patches);
      return;
    }

    // 如果为多段，只有2端移动相同的距离， 且不是resize， 才直接整体移动
    if (
      isSamePoint(startPositionDiff, endPositionDiff) &&
      originStartChange &&
      originEndChange &&
      isEqual(startChange?.size, originStartChange?.size) &&
      isEqual(endChange?.size, originEndChange?.size)
    ) {
      modifyConnectPatch(comp, offsetPoint(startPositionDiff, { x: 0 - diff.x, y: 0 - diff.y }), patches);
      return;
    }

    modifyConnectAutoPatchWithChanges(comp, changedComps, patches);
  });
  //在组内还要修改 其他的连接线
  if (inGroup) {
    const hasComputedComps = [...innerConnectComps, ...roundConnectComps].map((comp) => comp.id);
    const otherConnectComps = activeContainer.components.filter(
      (comp) => comp.isConnector && !hasComputedComps.includes(comp.id),
    ) as UIConnectorComponent[];
    otherConnectComps.forEach((comp: UIConnectorComponent) => {
      modifyConnectPatch(comp, { x: 0 - diff.x, y: 0 - diff.y }, patches);
    });
  }
}

/**
 * 处理移动结束时的流程线的数据
 * (每次微调路径都是相对上次的路径，clearDynamicInfoMap后，需要重新将数据赋值给patch)
 * @param selectedRoundConnectors
 * @param dynamicInfoMap
 */
export function updateConnectCompWhenEndMove(
  selectedRoundConnectors: UIConnectorComponent[],
  dynamicInfoMap: { [key: string]: DynamicEditInfo },
) {
  let connectPatches: ArtboardPatches = { do: {}, undo: {} };
  selectedRoundConnectors.forEach((comp) => {
    if (comp.isConnector && dynamicInfoMap[comp.id]?.value) {
      connectPatches.do[comp.id] = [Ops.replace('/value', dynamicInfoMap[comp.id].value)];
      connectPatches.undo[comp.id] = [Ops.replace('/value', comp.$data.value)];
    }
  });
  return connectPatches;
}

/**
 * 平移连接点时，直接平移点的数据
 *
 * @param connect
 * @param diff
 * @param patches
 */
export function modifyConnectPatch(
  connect: UIConnectorComponent,
  diff: { x: number; y: number },
  patches: ArtboardPatches,
) {
  const { startPoint, endPoint, path } = connect.value as IConnectorLineValue;
  const newStartPoint = offsetPoint(connect.getStartPoint(true), diff);
  const newEndPoint = offsetPoint(connect.getEndPoint(true), diff);

  const newStart = Object.assign({}, startPoint, newStartPoint);
  const newEnd = Object.assign({}, endPoint, newEndPoint);
  const newPath = path?.length ? offsetPointArray(path, { offsetX: diff.x, offsetY: diff.y }) : [];
  const newValue = path?.length
    ? { startPoint: newStart, endPoint: newEnd, path: newPath }
    : {
        startPoint: newStart,
        endPoint: newEnd,
      };

  patches.do[connect.id] = [Ops.replace('/value', newValue)];
  patches.undo[connect.id] = [Ops.replace('/value', connect.value)];
}

// 根据changes 重置为自动
function modifyConnectAutoPatchWithChanges(
  connect: UIConnectorComponent,
  changes: ComponentChange[],
  patches: ArtboardPatches,
) {
  const { startPoint, endPoint } = connect.value as IConnectorLineValue;
  const startChange = changes.find((cm) => cm.id === connect.getStartCompID());
  const endChange = changes.find((cm) => cm.id === connect.getEndCompID());
  const newStartPoint = startChange && getPointByComponent(startChange, startPoint.direction);
  const newEndPoint = endChange && getPointByComponent(endChange, endPoint.direction);
  patches.do[connect.id] = [
    Ops.replace('/value', {
      startPoint: Object.assign({}, startPoint, newStartPoint),
      endPoint: Object.assign({}, endPoint, newEndPoint),
    }),
  ];
  patches.undo[connect.id] = [Ops.replace('/value', connect.value)];
}

function getCustomPointWithPath(path: number[]) {
  const len = path.length;
  const originStartPoint = { x: path[0], y: path[1] };
  const secondStartPoint = { x: path[2], y: path[3] };
  let originCornerPoint = { x: path[4], y: path[5] };
  const originEndPoint = { x: path[len - 2], y: path[len - 1] };

  //如果是一条直线，拐角点取中点
  let isLineCorner = false;
  if (
    path.length === 8 &&
    getDirectionByPoints(originStartPoint, secondStartPoint) === getDirectionByPoints(originStartPoint, originEndPoint)
  ) {
    isLineCorner = true;
    const direction = getDirectionByPoints(originStartPoint, originEndPoint);
    if (direction === 'arrow-left') {
      originCornerPoint = {
        x: round(originEndPoint.x + (originStartPoint.x - originEndPoint.x) / 2),
        y: originEndPoint.y,
      };
    } else if (direction === 'arrow-up') {
      originCornerPoint = {
        x: originEndPoint.x,
        y: round(originEndPoint.y + (originStartPoint.y - originEndPoint.y) / 2),
      };
    } else if (direction === 'arrow-right') {
      originCornerPoint = {
        x: round(originStartPoint.x + (originEndPoint.x - originStartPoint.x) / 2),
        y: originStartPoint.y,
      };
    } else if (direction === 'arrow-down') {
      originCornerPoint = {
        x: originStartPoint.x,
        y: round(originStartPoint.y + (originEndPoint.y - originStartPoint.y) / 2),
      };
    } else {
      isLineCorner = false;
    }
  }

  //判断拓展点即为转角点， 需要做插入处理，否则是修改
  let isExtendACorner = false;
  if (!getDirectionByPoints(originStartPoint, originCornerPoint)) {
    const originExtendPoint = { x: path[2], y: path[3] };
    isExtendACorner = true;
    originCornerPoint = { ...originExtendPoint };
  }

  return {
    isExtendACorner,
    isLineCorner,
    originStartPoint,
    originCornerPoint,
    originEndPoint,
  };
}

/**
 * 手动调整过的流程线单一端点改变的微调处理
 * @param path
 * @param newPoint
 * @param direction
 */
export function modifyCustomPath(path: number[], newPoint: IPoint, direction: string) {
  const resetDelta: number = 5;
  let originPath = [...path];
  const len = path.length;
  if (len < 6) {
    return path;
  }

  //判断移动的是开始点还是结束点
  if (direction !== 'start') {
    originPath = reversePath(path);
  }

  const { isExtendACorner, isLineCorner, originStartPoint, originCornerPoint } = getCustomPointWithPath(originPath);

  //判断开始与下一条直线的位置
  const startDirection = getDirectionByPoints(originStartPoint, originCornerPoint);
  if (startDirection === 'arrow-left') {
    if (newPoint.x > originCornerPoint.x + resetDelta) {
      originPath[0] = newPoint.x;
      originPath[1] = newPoint.y;
      originPath[2] = newPoint.x - DELTA;
      originPath[3] = newPoint.y;
      if (isExtendACorner) {
        originPath.splice(4, 0, originCornerPoint.x, newPoint.y);
      } else if (isLineCorner) {
        originPath.splice(4, 0, originCornerPoint.x, newPoint.y, originCornerPoint.x, originCornerPoint.y);
      } else {
        originPath[5] = newPoint.y;
      }
    } else {
      originPath = [];
    }
  } else if (startDirection === 'arrow-up') {
    if (newPoint.y > originCornerPoint.y + resetDelta) {
      originPath[0] = newPoint.x;
      originPath[1] = newPoint.y;
      originPath[2] = newPoint.x;
      originPath[3] = newPoint.y - DELTA;
      if (isExtendACorner) {
        originPath.splice(4, 0, newPoint.x, originCornerPoint.y);
      } else if (isLineCorner) {
        originPath.splice(4, 0, newPoint.x, originCornerPoint.y, originCornerPoint.x, originCornerPoint.y);
      } else {
        originPath[4] = newPoint.x;
      }
    } else {
      originPath = [];
    }
  } else if (startDirection === 'arrow-right') {
    if (newPoint.x < originCornerPoint.x - resetDelta) {
      originPath[0] = newPoint.x;
      originPath[1] = newPoint.y;
      originPath[2] = newPoint.x + DELTA;
      originPath[3] = newPoint.y;
      if (isExtendACorner) {
        originPath.splice(4, 0, originCornerPoint.x, newPoint.y);
      } else if (isLineCorner) {
        originPath.splice(4, 0, originCornerPoint.x, newPoint.y, originCornerPoint.x, originCornerPoint.y);
      } else {
        originPath[5] = newPoint.y;
      }
    } else {
      originPath = [];
    }
  } else if (startDirection === 'arrow-down') {
    if (newPoint.y < originCornerPoint.y - resetDelta) {
      originPath[0] = newPoint.x;
      originPath[1] = newPoint.y;
      originPath[2] = newPoint.x;
      originPath[3] = newPoint.y + DELTA;
      if (isExtendACorner) {
        originPath.splice(4, 0, newPoint.x, originCornerPoint.y);
      } else if (isLineCorner) {
        originPath.splice(4, 0, newPoint.x, originCornerPoint.y, originCornerPoint.x, originCornerPoint.y);
      } else {
        originPath[4] = newPoint.x;
      }
    } else {
      originPath = [];
    }
  }

  if (direction !== 'start') {
    originPath = reversePath(originPath);
  }

  originPath = removePathPointByLine(originPath, { removeSamePoint: true, beginCursor: 1, endCursor: 2 });

  // 当点少于4个时，说明某端的拓展点与开始点相同了
  if (originPath.length < 8) {
    originPath = [];
  }

  return originPath;
}

/**
 * 将有一端与选中组件相关的流程线的path point 修正
 * @param connect
 * @param change
 * @param patches
 */
export function resetConnectPatch(connect: UIConnectorComponent, change: ComponentChange, patches: ArtboardPatches) {
  const { startPoint, endPoint, path } = connect.value as IConnectorLineValue;
  const newStartPoint = { ...startPoint };
  const newEndPoint = { ...endPoint };
  let newPath: number[] = [];
  if (change.id === startPoint.id) {
    const startP = getPointByComponent(change, startPoint.direction);
    newStartPoint.x = startP.x;
    newStartPoint.y = startP.y;
    if (path && !connect.parent?.isGroup) {
      newPath = modifyCustomPath(path, newStartPoint, 'start');
    }
  }

  if (change.id === endPoint.id) {
    const endP = getPointByComponent(change, endPoint.direction);
    newEndPoint.x = endP.x;
    newEndPoint.y = endP.y;
    if (path && !connect.parent?.isGroup) {
      newPath = modifyCustomPath(path, newEndPoint, 'end');
    }
  }

  if (newPath.length) {
    patches.do[connect.id] = [
      Ops.replace('/value', {
        startPoint: newStartPoint,
        endPoint: newEndPoint,
        path: newPath,
      }),
    ];
  } else {
    patches.do[connect.id] = [Ops.replace('/value', { startPoint: newStartPoint, endPoint: newEndPoint })];
  }
  patches.undo[connect.id] = [Ops.replace('/value', connect.value)];
}

/**
 * 多个选中的组件resize时， 重置内部的流程线
 * @param activeContainer
 * @param selectComps
 * @param newComponentsInfo
 */
export function resetConnectPatchWhenResize(
  activeContainer: UIContainerComponent,
  selectComps: UIComponent[],
  newComponentsInfo: ComponentChange[],
): ArtboardPatches {
  const patches: ArtboardPatches = { do: {}, undo: {} };
  const selectIds = selectComps.filter((comp) => !comp.isConnector).map((comp) => comp.id);
  const { innerConnectComps } = getInnerOrAroundConnect(activeContainer, selectIds);
  innerConnectComps.forEach((comp) => {
    const { startPoint, endPoint } = comp.value as IConnectorLineValue;
    const change = newComponentsInfo.find((info) => info.id === startPoint.id || info.id === endPoint.id);
    change && resetConnectPatch(comp as UIConnectorComponent, change, patches);
  });
  return patches;
}

/**
 * 返回连接线和文字边框的交点
 * @param line1 连接线的线段
 * @param line2 文字边框的线段
 */
export function getInteractionOfTwoSpecialLine(line1: IPoint[], line2: IPoint[]) {
  const directionOfLine1 = line1[0].x === line1[1].x ? 'vertical' : 'horizontal';
  const directionOfLine2 = line2[0].x === line2[1].x ? 'vertical' : 'horizontal';
  if (directionOfLine1 === 'horizontal' && directionOfLine2 === 'horizontal') {
    if (line1[0].y !== line2[0].y) {
      return undefined;
    } else {
      const { minX: minXOfLine1, maxX: maxXOfLine1 } = getMinMaxXY(line1);
      const { minX: minXOfLine2, maxX: maxXOfLine2 } = getMinMaxXY(line2);
      if (minXOfLine1 > maxXOfLine2 || maxXOfLine1 < minXOfLine2) {
        return undefined;
      }
      // line1在line2内部
      else if (minXOfLine1 > minXOfLine2 && maxXOfLine1 < maxXOfLine2) {
        return undefined;
      }
      // line2在line1内部
      else if (minXOfLine2 > minXOfLine1 && maxXOfLine2 < maxXOfLine1) {
        return undefined;
      }
      //line1在左，line2在右，且相交
      else if (minXOfLine1 < minXOfLine2 && maxXOfLine1 > minXOfLine2) {
        return [{ x: minXOfLine2, y: line2[0].y }];
      }
      //line1在右，line2在左，且相交
      else if (maxXOfLine1 > maxXOfLine2 && minXOfLine1 < maxXOfLine2) {
        return [{ x: maxXOfLine2, y: line2[0].y }];
      }
    }
  } else if (directionOfLine1 === 'vertical' && directionOfLine2 === 'vertical') {
    if (line1[0].x !== line2[0].x) {
      return undefined;
    } else {
      const { minY: minYOfLine1, maxY: maxYOfLine1 } = getMinMaxXY(line1);
      const { minY: minYOfLine2, maxY: maxYOfLine2 } = getMinMaxXY(line2);
      if (minYOfLine1 > maxYOfLine2 || maxYOfLine1 < minYOfLine2) {
        return undefined;
      }
      // line1在line2内部
      else if (minYOfLine1 > minYOfLine2 && maxYOfLine1 < maxYOfLine2) {
        return undefined;
      }
      // line2在line1内部
      else if (minYOfLine2 > maxYOfLine1 && maxYOfLine2 < maxYOfLine1) {
        return undefined;
      }
      //line1在上，line2在下，且相交
      else if (minYOfLine1 < minYOfLine2 && maxYOfLine1 > minYOfLine2) {
        return [{ x: line2[0].x, y: minYOfLine2 }];
      }
      //line2在上，line1在下，且相交
      else if (maxYOfLine1 > maxYOfLine2 && minYOfLine1 < maxYOfLine2) {
        return [{ x: line2[0].x, y: maxYOfLine2 }];
      }
    }
  } else if (directionOfLine1 === 'vertical' && directionOfLine2 === 'horizontal') {
    // 线1是垂直，线2是水平的
    const yOfLine2 = line2[0].y;
    const xOfLine1 = line1[0].x;
    const { minY: minYOfLine1, maxY: maxYOfLine1 } = getMinMaxXY(line1);
    const { minX: minXOfLine2, maxX: maxXOfLine2 } = getMinMaxXY(line2);
    if (yOfLine2 <= maxYOfLine1 && yOfLine2 >= minYOfLine1 && xOfLine1 <= maxXOfLine2 && xOfLine1 >= minXOfLine2) {
      return [{ x: xOfLine1, y: yOfLine2 }];
    } else {
      return undefined;
    }
  } else {
    //线段1是水平的，线段2是垂直的，只要线段2的Y值在线段1之间
    const yOfLine1 = line1[0].y;
    const xOfLine2 = line2[0].x;
    const { minY: minYOfLine2, maxY: maxYOfLine2 } = getMinMaxXY(line2);
    const { minX: minXOfLine1, maxX: maxXOfLine1 } = getMinMaxXY(line1);
    if (yOfLine1 <= maxYOfLine2 && yOfLine1 >= minYOfLine2 && xOfLine2 <= maxXOfLine1 && xOfLine2 >= minXOfLine1) {
      return [{ x: xOfLine2, y: yOfLine1 }];
    } else {
      return undefined;
    }
  }
}

/**
 * 根据偏移修正流程线原始的数据结构
 * @param comp
 * @param offset
 */
export function modifyPathWithOffset(comp: UIComponent | Partial<IComponentData>, offset: IPosition) {
  let { startPoint, endPoint, path } = comp.value as IConnectorLineValue;
  startPoint.x += offset.x;
  startPoint.y += offset.y;
  endPoint.x += offset.x;
  endPoint.y += offset.y;
  if (path?.length) {
    comp.value.path = offsetPointArray(path, { offsetX: offset.x, offsetY: offset.y });
  }
}

/**
 * 获取便签条链接的组件
 * @param parent
 * @param compIds
 */
export function getStickNoteConnectComps(parent: UIContainerComponent, compIds: string[]) {
  return parent.components.filter((item) => {
    if (item.lib?.type === CStickNote) {
      const value = item.value as INoteValue;
      const connectCompId = value.endCompId;
      return connectCompId && compIds.includes(connectCompId);
    }
    return false;
  });
}

/**
 * 便签条关联组件size/position变化，便签条信息同步修改
 * @param activeContainer
 * @param changedComps
 * @param patches
 */
export function updateStickNoteConnectCompPatches(
  activeContainer: UIContainerComponent,
  changedComps: ComponentChange[],
  patches: ArtboardPatches,
) {
  const compIds = changedComps.map((comp) => comp.id);
  const comps = getStickNoteConnectComps(activeContainer, compIds);
  // 先计算变更前便签条连接点在目标组件位置的百分比，而后根据变化后的位置算出结束点的相对位置
  comps.forEach((noteComp) => {
    const value = noteComp.value as INoteValue;
    const changeCompId = value.endCompId!;
    const change = changedComps.find((item) => item.id === changeCompId);
    if (change) {
      const targetComp = activeContainer.components.find((item) => item.id === change.id)!;
      const noteChange = changedComps.find((item) => item.id === noteComp.id);
      const endPoint = value.endPoint!;

      const notePosition = noteChange ? noteChange.position : noteComp.position;
      const { size, position, rotate } = targetComp;
      const { size: changeSize, position: changePosition, rotate: changeRotate } = change;
      const targetCenter = { x: position.x + size.width / 2, y: position.y + size.height / 2 };
      const rotatePt = {
        x: endPoint.x + noteComp.position.x,
        y: endPoint.y + noteComp.position.y,
      };
      const targetRotatedPt = rotatePoint(rotatePt, targetCenter, -rotate);

      const ratio = {
        x: (targetRotatedPt.x - position.x) / size.width,
        y: (targetRotatedPt.y - position.y) / size.height,
      };
      const centerPt = { x: changePosition.x + changeSize.width / 2, y: changePosition.y + changeSize.height / 2 };

      let realEndPoint = {
        x: changePosition.x + changeSize.width * ratio.x,
        y: changePosition.y + changeSize.height * ratio.y,
      };
      realEndPoint = rotatePoint(realEndPoint, centerPt, changeRotate);

      const newEndPoint = {
        x: round(realEndPoint.x - notePosition.x),
        y: round(realEndPoint.y - notePosition.y),
      };

      const newValue = { ...value, endPoint: newEndPoint };
      if (noteChange) {
        const scale = {
          x: noteChange.size.width / noteComp.size.width,
          y: noteChange.size.height / noteComp.size.height,
        };
        const newPathData = scalePath(newValue, scale).data;
        newValue.data = newPathData;
      }

      if (patches.do[noteComp.id]) {
        patches.do[noteComp.id].push(Ops.replace('/value', newValue));
        patches.undo[noteComp.id].push(Ops.replace('/value', value));
      } else {
        patches.do[noteComp.id] = [Ops.replace('/value', newValue)];
        patches.undo[noteComp.id] = [Ops.replace('/value', value)];
      }
    }
  });
}

/**
 * 移除便签条与组件的联系
 * @param activeContainer
 * @param selectedComps
 */
export function removeContactBetweenStickNoteAndComp(
  activeContainer: UIContainerComponent,
  selectedComps: UIComponent[],
): ArtboardPatches | null {
  const compIds = selectedComps.map((comp) => comp.id);
  const comps = getStickNoteConnectComps(activeContainer, compIds).filter((item) => !compIds.includes(item.id));
  if (comps.length) {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    comps.forEach((comp) => {
      const value = comp.value as INoteValue;
      const newValue = { ...value };
      delete newValue.endCompId;
      patches.do[comp.id] = [Ops.replace('/value', newValue)];
      patches.undo[comp.id] = [Ops.replace('/value', comp.value)];
    });
    return patches;
  }
  return null;
}
