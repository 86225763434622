import { IComponentData } from '@/fbs/rp/models/component';
import { EChartType_basic } from '@/fbs/rp/models/properties/chart';
import { IChartValue } from '@/fbs/rp/models/chart';
import { makeCommonComponent } from '@/libs/helper';

import { IComponentItem } from '../../../types';
import { CBarChart } from '../../../constants';
import { getChartConfig, makeChartData, getSeriesChartOneDefaultData } from '../common';

export const BarChartConfig: IComponentItem = getChartConfig(CBarChart, EChartType_basic.Bar);

export function makeBarChart(id: string): IComponentData {
  return makeChartData(id, CBarChart, EChartType_basic.Bar);
}

export function makeAIBarChart(id: string, value: IChartValue): IComponentData {
  return makeCommonComponent(id, CBarChart, {
    ...getSeriesChartOneDefaultData(CBarChart, EChartType_basic.Bar),
    size: {
      width: 400,
      height: 250,
    },
    lib: {
      id: 'chart',
      type: CBarChart,
    },
    value: value,
    name: '',
  });
}
