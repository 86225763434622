import { cloneDeep } from 'lodash';

import i18n from '@/i18n';
import { RELATION_DATA } from '@/consts/defaultData/collapse';
import { ITreeDataItem, ITreeData } from '@/fbs/rp/models/value';
import { IComponentData } from '@/fbs/rp/models/component';
import { PropertyValue } from '@/fbs/rp/models/property';
import { IUICompConstructOptions } from '@/customTypes';
import { ArtboardPatches, Ops } from '@/fbs/rp/utils/patch';
import { mergePatches } from '@/helpers/patchHelper';
import { createAvlTreeWithTreeData, parseTreeData } from '@/helpers/treeCompHelper';
import { UIContainerComponent, UITreeComponent } from '.';

export default class UICollapseComponent extends UITreeComponent {
  constructor(data: IComponentData, public parent?: UIContainerComponent, public options?: IUICompConstructOptions) {
    super(data, parent, options);
  }

  // @override
  createNewTreeData(newNodeID: string, level: number, index: number) {
    return {
      ...RELATION_DATA,
      id: newNodeID,
      index,
    } as ITreeDataItem;
  }

  // @override
  getAddTextValue = (level: number | undefined, index: number) => {
    return `${i18n(`resource.componentsText.${(level || 0) <= 1 ? 'title' : 'content'}`)} ${index + 1}`;
  };

  //展开折叠
  modifyExpandState(targetNodeID: string) {
    const { relation, expandACollapseIcon } = this.value as ITreeData;
    const isExpandOneItem = this.properties.expandOneItem?.value;
    const { tree, treeRelation } = createAvlTreeWithTreeData(cloneDeep(relation));
    const targetNode = tree.get(targetNodeID)!;
    const expand = targetNode.data.expand;

    // 勾选只展示一项，收起其余选项
    const patches: ArtboardPatches = { do: {}, undo: {} };
    treeRelation.forEach((item) => {
      let hasChange = true;
      if (item.data.id === targetNodeID) {
        item.data.expand = !expand;
      } else if (!expand && isExpandOneItem && item.data.expand) {
        item.data.expand = false;
      } else {
        hasChange = false;
      }
      if (hasChange) {
        const expandComp = this.getItemCompById(item.data.id).expandComp;
        patches.do[expandComp.id] = [Ops.replace('/selected', item.data.expand)];
        patches.undo[expandComp.id] = [Ops.replace('/selected', !item.data.expand)];
      }
    });

    const newValue = parseTreeData(treeRelation);
    const valuePatches = this.treeValuePathes({ expandACollapseIcon, relation: newValue });

    return mergePatches(valuePatches, patches);
  }

  modifyValueWhenPropertyChange(propertyName: string, newValue: PropertyValue): ArtboardPatches {
    const parentPatches = super.modifyValueWhenPropertyChange(propertyName, newValue);
    const patches: ArtboardPatches = { do: {}, undo: {} };

    // 展开一项勾选，只保留一项
    if (propertyName === 'expandOneItem') {
      const newProperty = newValue.value;
      if (newProperty) {
        const { relation, expandACollapseIcon } = this.value as ITreeData;
        const { treeRelation } = createAvlTreeWithTreeData(cloneDeep(relation));
        let isExist = false;
        treeRelation.forEach((item) => {
          if (!isExist && item.data.expand) {
            isExist = true;
          } else if (item.data.expand) {
            item.data.expand = false;
            const expandComp = this.getItemCompById(item.data.id).expandComp;
            patches.do[expandComp.id] = [Ops.replace('/selected', false)];
            patches.undo[expandComp.id] = [Ops.replace('/selected', true)];
          }
        });
        const newValue = parseTreeData(treeRelation);
        const valuePatches = this.treeValuePathes({ expandACollapseIcon, relation: newValue });
        mergePatches(patches, valuePatches);
      }
    }

    return mergePatches(parentPatches, patches);
  }
}
