import { isUndefined, set } from 'lodash';
import { IComponentState } from '@fbs/rp/models/component';
import { PropertyValue } from '@fbs/rp/models/property';
import { depthClone, isEqualDate } from '@utils/globalUtils';
import { ComponentPatchesClass, doAddOperationByNewValue } from '@editor/patches/ComponentPatches';

export const isSameProperty = (p1: PropertyValue, p2: PropertyValue) => {
  if (p1 && p2) {
    type PropName = keyof typeof p1;
    const fullKeys: string[] = [];
    Object.keys(p1)
      .concat(...Object.keys(p2))
      .forEach((k) => {
        if (['ref', 'name', 'prop', 'hidden'].indexOf(k) === -1) {
          fullKeys.push(k);
        }
      });
    for (let i = 0; i < fullKeys.length; i++) {
      const k = fullKeys[i] as PropName;
      if (isUndefined(p1[k]) || isUndefined(p2[k])) {
        return false;
      } else if (!isUndefined(p1[k]) && !isUndefined(p2[k])) {
        if (typeof p1[k] === 'object') {
          if (!isEqualDate(p1[k], p2[k])) {
            return false;
          }
        } else {
          if (p1[k] !== p2[k]) {
            return false;
          }
        }
      }
    }
  }

  return true;
};

// export const doReplaceOperationByPath = (path: string, oldValue: any, newValue: any, patches: ComponentPatches) => {
//   const isValueChanged = !isEqual(oldValue, newValue);
//   if (isValueChanged && !patches.do.find((p) => p.path === path)) {
//     patches.do.push(Ops.replace(path, newValue));
//     patches.undo.push(Ops.replace(path, oldValue));
//   }
// };

export function updatePatchesBaseOnNewStateInTempState(
  tempState: IComponentState,
  key: any,
  patches: ComponentPatchesClass,
) {
  const data = depthClone(tempState);
  Object.values(data.properties!).forEach((v) => {
    if (v && !(v.ref && Object.keys(v).length === 1)) {
      v.ref = undefined;
    }
  });
  set(data, 'enabled', true);
  doAddOperationByNewValue(`./states/${key}`, data, patches);
}
