import IconConfig from '@consts/types/iconData';
import i18n from '@i18n';
// 1229个
const lightIconFont: IconConfig = {
  iconFontName: 'lightIconFont',
  version: '1.0',
  icons: [
    {
      name: i18n('resource.icons.pay'),
      id: 'pay',
      items: [
        {
          iconCode: 60512,
          searchKey: {
            zhCN: '标签, 价格, 书签, 标记 , 商品',
            enUS: 'Label, price, bookmark, mark +, product',
            zhSpell: 'biaoqian, jiage, shuqian, biaoji , shangpin',
          },
          group: 'pay',
        },
        {
          iconCode: 60513,
          searchKey: {
            zhCN: '标签, 价格, 书签, 标记 , 商品',
            enUS: 'Label, price, bookmark, mark +, product',
            zhSpell: 'biaoqian, jiage, shuqian, biaoji , shangpin',
          },
          group: 'pay',
        },
        {
          iconCode: 60514,
          searchKey: {
            zhCN: '安全, 资金, 人民币, 盾牌, 保障',
            enUS: 'Security, capital, renminbi, RMB, shield, protection',
            zhSpell: 'anquan, zijin, renminbi, dunpai, baozhang',
          },
          group: 'pay',
        },
        {
          iconCode: 60515,
          searchKey: {
            zhCN: '安全, 保障, 防护, 盾牌, 保护',
            enUS: 'Safety, protection, protection, shield, protection',
            zhSpell: 'anquan, baozhang, fanghu, dunpai, baohu',
          },
          group: 'pay',
        },
        {
          iconCode: 60516,
          searchKey: {
            zhCN: '充值, 时间, 金钱, 人民币, 交易, 历史记录',
            enUS: 'Top-up, time, money, RMB, trading, history',
            zhSpell: 'chongzhi, shijian, jinqian, renminbi, jiaoyi, lishijilu',
          },
          group: 'pay',
        },
        {
          iconCode: 60517,
          searchKey: {
            zhCN: '安全, 保障, 防护, 盾牌, 保护',
            enUS: 'Safety, protection, protection, shield, protection',
            zhSpell: 'anquan, baozhang, fanghu, dunpai, baohu',
          },
          group: 'pay',
        },
        {
          iconCode: 60518,
          searchKey: {
            zhCN: '充值, 付款, 增加, 交易, 付钱, 资金',
            enUS: 'Top up, increase, trade, pay, fund',
            zhSpell: 'chongzhi, fukuan, zengjia, jiaoyi, fuqian, zijin',
          },
          group: 'pay',
        },
        {
          iconCode: 60519,
          searchKey: {
            zhCN: '二维码, 扫一扫, 扫码, 扫描, 验证',
            enUS: 'QR code, sweep, scan code, scan, verify',
            zhSpell: 'erweima, saoyisao, saoma, saomiao, yanzheng',
          },
          group: 'pay',
        },
        {
          iconCode: 60520,
          searchKey: {
            zhCN: '安全, 资金, 人民币, 盾牌, 保障',
            enUS: 'Security, capital, renminbi, RMB, shield, protection',
            zhSpell: 'anquan, zijin, renminbi, dunpai, baozhang',
          },
          group: 'pay',
        },
        {
          iconCode: 60521,
          searchKey: {
            zhCN: '购物袋, 购物, 袋子, 包, 口袋',
            enUS: 'Shopping bags, shopping, bags, bags, pockets',
            zhSpell: 'gouwudai, gouwu, daizi, bao, koudai',
          },
          group: 'pay',
        },
        {
          iconCode: 60522,
          searchKey: {
            zhCN: '货币, 比特币, 虚拟货币, 交易, 投资, 理财',
            enUS: 'Currency, bitcoin, virtual currency, trading, investment, financial management',
            zhSpell: 'huobi, bitebi, xunihuobi, jiaoyi, touzi, licai',
          },
          group: 'pay',
        },
        {
          iconCode: 60523,
          searchKey: {
            zhCN: '红包, 现金, 钱, 交易, 购物',
            enUS: 'Red envelope, cash, money, transaction, shopping',
            zhSpell: 'hongbao, xianjin, qian, jiaoyi, gouwu',
          },
          group: 'pay',
        },
        {
          iconCode: 60524,
          searchKey: {
            zhCN: '购物袋, 购物, 袋子, 包, 口袋',
            enUS: 'Shopping bags, shopping, bags, bags, pockets',
            zhSpell: 'gouwudai, gouwu, daizi, bao, koudai',
          },
          group: 'pay',
        },
        {
          iconCode: 60525,
          searchKey: {
            zhCN: '扫一扫, 二维码, 扫码, 扫描, 验证',
            enUS: 'Sweep, QR code, scan code, scan, verify',
            zhSpell: 'saoyisao, erweima, saoma, saomiao, yanzheng',
          },
          group: 'pay',
        },
        {
          iconCode: 60526,
          searchKey: {
            zhCN: '货币, 卢币, 支付, 付款, 钱',
            enUS: 'Currency, rupiah, payment, payment, money',
            zhSpell: 'huobi, lubi, zhifu, fukuan, qian',
          },
          group: 'pay',
        },
        {
          iconCode: 60527,
          searchKey: {
            zhCN: '货币, 韩元, 钱, 支付, 付款',
            enUS: 'Currency, won, money, payment, payment',
            zhSpell: 'huobi, hanyuan, qian, zhifu, fukuan',
          },
          group: 'pay',
        },
        {
          iconCode: 60528,
          searchKey: {
            zhCN: '货币, 比特币, 虚拟货币, 交易, 投资, 理财',
            enUS: 'Currency, bitcoin, virtual currency, trading, investment, financial management',
            zhSpell: 'huobi, bitebi, xunihuobi, jiaoyi, touzi, licai',
          },
          group: 'pay',
        },
        {
          iconCode: 60529,
          searchKey: {
            zhCN: '货币, 美元, 美金, 付款, 交易',
            enUS: 'Currency, dollar, dollar, payment, transaction',
            zhSpell: 'huobi, meiyuan, meijin, fukuan, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60530,
          searchKey: {
            zhCN: '货币, 卢币, 支付, 付款, 钱',
            enUS: 'Currency, rupiah, payment, payment, money',
            zhSpell: 'huobi, lubi, zhifu, fukuan, qian',
          },
          group: 'pay',
        },
        {
          iconCode: 60531,
          searchKey: {
            zhCN: '货币, 比特币, 虚拟货币, 交易, 投资, 理财',
            enUS: 'Currency, bitcoin, virtual currency, trading, investment, financial management',
            zhSpell: 'huobi, bitebi, xunihuobi, jiaoyi, touzi, licai',
          },
          group: 'pay',
        },
        {
          iconCode: 60532,
          searchKey: {
            zhCN: '货币, 卢币, 支付, 付款, 钱',
            enUS: 'Currency, rupiah, payment, payment, money',
            zhSpell: 'huobi, lubi, zhifu, fukuan, qian',
          },
          group: 'pay',
        },
        {
          iconCode: 60533,
          searchKey: {
            zhCN: '二维码, 扫一扫, 扫码, 扫描, 验证',
            enUS: 'QR code, sweep, scan code, scan, verify',
            zhSpell: 'erweima, saoyisao, saoma, saomiao, yanzheng',
          },
          group: 'pay',
        },
        {
          iconCode: 60534,
          searchKey: {
            zhCN: '货币, 美元, 美金, 付款, 交易',
            enUS: 'Currency, dollar, dollar, payment, transaction',
            zhSpell: 'huobi, meiyuan, meijin, fukuan, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60535,
          searchKey: {
            zhCN: '货币, 韩元, 钱, 支付, 付款',
            enUS: 'Currency, won, money, payment, payment',
            zhSpell: 'huobi, hanyuan, qian, zhifu, fukuan',
          },
          group: 'pay',
        },
        {
          iconCode: 60536,
          searchKey: {
            zhCN: '货币, 欧元, 钱, 支付, 交易',
            enUS: 'Currency, euro, money, payment, transaction',
            zhSpell: 'huobi, ouyuan, qian, zhifu, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60537,
          searchKey: {
            zhCN: '货币, 土耳其里拉, 钱, 支付, 交易',
            enUS: 'Currency, Turkish lira, money, payment, transaction',
            zhSpell: 'huobi, tuerqilila, qian, zhifu, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60538,
          searchKey: {
            zhCN: '货币, 人民币, 钱, 交易, 支付',
            enUS: 'Currency, RMB, money, transactions, payment',
            zhSpell: 'huobi, renminbi, qian, jiaoyi, zhifu',
          },
          group: 'pay',
        },
        {
          iconCode: 60539,
          searchKey: {
            zhCN: '货币, 人民币, 钱, 交易, 支付',
            enUS: 'Currency, RMB, money, transactions, payment',
            zhSpell: 'huobi, renminbi, qian, jiaoyi, zhifu',
          },
          group: 'pay',
        },
        {
          iconCode: 60540,
          searchKey: {
            zhCN: '货币, 人民币, 钱, 交易, 支付',
            enUS: 'Currency, RMB, money, transactions, payment',
            zhSpell: 'huobi, renminbi, qian, jiaoyi, zhifu',
          },
          group: 'pay',
        },
        {
          iconCode: 60541,
          searchKey: {
            zhCN: '货币, 韩元, 钱, 支付, 付款',
            enUS: 'Currency, won, money, payment, payment',
            zhSpell: 'huobi, hanyuan, qian, zhifu, fukuan',
          },
          group: 'pay',
        },
        {
          iconCode: 60542,
          searchKey: {
            zhCN: '货币, 欧元, 钱, 支付, 交易',
            enUS: 'Currency, euro, money, payment, transaction',
            zhSpell: 'huobi, ouyuan, qian, zhifu, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60543,
          searchKey: {
            zhCN: '货币, 欧元, 钱, 支付, 交易',
            enUS: 'Currency, euro, money, payment, transaction',
            zhSpell: 'huobi, ouyuan, qian, zhifu, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60544,
          searchKey: {
            zhCN: '货币, 美元, 美金, 付款, 交易',
            enUS: 'Currency, dollar, dollar, payment, transaction',
            zhSpell: 'huobi, meiyuan, meijin, fukuan, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60545,
          searchKey: {
            zhCN: '货币, 英镑, 钱, 支付, 交易',
            enUS: 'Currency, pound, money, payment, transaction',
            zhSpell: 'huobi, yingbang, qian, zhifu, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60546,
          searchKey: {
            zhCN: '货币, 维尔币, 钱, 支付, 交易',
            enUS: 'Currency, ville coin, money, payment, transaction',
            zhSpell: 'huobi, weierbi, qian, zhifu, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60547,
          searchKey: {
            zhCN: '货币, 印度卢比, 钱, 支付, 交易',
            enUS: 'Currency, Indian rupee, money, payment, transaction',
            zhSpell: 'huobi, yindulubi, qian, zhifu, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60548,
          searchKey: {
            zhCN: '货币, 土耳其里拉, 钱, 支付, 交易',
            enUS: 'Currency, Turkish lira, money, payment, transaction',
            zhSpell: 'huobi, tuerqilila, qian, zhifu, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60549,
          searchKey: {
            zhCN: '货币, 维尔币, 钱, 支付, 交易',
            enUS: 'Currency, ville coin, money, payment, transaction',
            zhSpell: 'huobi, weierbi, qian, zhifu, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60550,
          searchKey: {
            zhCN: '货币, 英镑, 钱, 支付, 交易',
            enUS: 'Currency, pound, money, payment, transaction',
            zhSpell: 'huobi, yingbang, qian, zhifu, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60551,
          searchKey: {
            zhCN: '货币, 维尔币, 钱, 支付, 交易',
            enUS: 'Currency, ville coin, money, payment, transaction',
            zhSpell: 'huobi, weierbi, qian, zhifu, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60552,
          searchKey: {
            zhCN: '货币, 印度卢比, 钱, 支付, 交易',
            enUS: 'Currency, Indian rupee, money, payment, transaction',
            zhSpell: 'huobi, yindulubi, qian, zhifu, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60553,
          searchKey: {
            zhCN: '价格, 标签, 人民币, 支付, 卡券, 交易',
            enUS: 'Price, label, RMB, payment, card coupon, transaction',
            zhSpell: 'jiage, biaoqian, renminbi, zhifu, kaquan, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60554,
          searchKey: {
            zhCN: '货币, 土耳其里拉, 钱, 支付, 交易',
            enUS: 'Currency, Turkish lira, money, payment, transaction',
            zhSpell: 'huobi, tuerqilila, qian, zhifu, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60555,
          searchKey: {
            zhCN: '货币, 英镑, 钱, 支付, 交易',
            enUS: 'Currency, pound, money, payment, transaction',
            zhSpell: 'huobi, yingbang, qian, zhifu, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60556,
          searchKey: {
            zhCN: '价格, 标签, 人民币, 支付, 卡券, 交易',
            enUS: 'Price, label, RMB, payment, card coupon, transaction',
            zhSpell: 'jiage, biaoqian, renminbi, zhifu, kaquan, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60557,
          searchKey: {
            zhCN: '价格, 标签, 人民币, 支付, 卡券, 交易',
            enUS: 'Price, label, RMB, payment, card coupon, transaction',
            zhSpell: 'jiage, biaoqian, renminbi, zhifu, kaquan, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60558,
          searchKey: {
            zhCN: '价格, 标签, 购物, 商品, 促销',
            enUS: 'Price, label, shopping, merchandise, promotion',
            zhSpell: 'jiage, biaoqian, gouwu, shangpin, chuxiao',
          },
          group: 'pay',
        },
        {
          iconCode: 60559,
          searchKey: {
            zhCN: '价格, 标签, 购物, 商品, 促销',
            enUS: 'Price, label, shopping, merchandise, promotion',
            zhSpell: 'jiage, biaoqian, gouwu, shangpin, chuxiao',
          },
          group: 'pay',
        },
        {
          iconCode: 60560,
          searchKey: {
            zhCN: '货币, 印度卢比, 钱, 支付, 交易',
            enUS: 'Currency, Indian rupee, money, payment, transaction',
            zhSpell: 'huobi, yindulubi, qian, zhifu, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60561,
          searchKey: {
            zhCN: '价格, 标签, 购物, 商品, 促销',
            enUS: 'Price, label, shopping, merchandise, promotion',
            zhSpell: 'jiage, biaoqian, gouwu, shangpin, chuxiao',
          },
          group: 'pay',
        },
        {
          iconCode: 60562,
          searchKey: {
            zhCN: '价格, 标签, 人民币, 支付, 卡券, 交易',
            enUS: 'Price, label, RMB, payment, card coupon, transaction',
            zhSpell: 'jiage, biaoqian, renminbi, zhifu, kaquan, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60563,
          searchKey: {
            zhCN: '价格, 标签, 购物, 商品, 促销',
            enUS: 'Price, label, shopping, merchandise, promotion',
            zhSpell: 'jiage, biaoqian, gouwu, shangpin, chuxiao',
          },
          group: 'pay',
        },
        {
          iconCode: 60564,
          searchKey: {
            zhCN: '卡券, 票, 购物券, 优惠券, 消费, 购物, 商品',
            enUS: 'Card coupon, ticket, shopping voucher, coupon, consumption, shopping, merchandise',
            zhSpell: 'kaquan, piao, gouwuquan, youhuiquan, xiaofei, gouwu, shangpin',
          },
          group: 'pay',
        },
        {
          iconCode: 60565,
          searchKey: {
            zhCN: '金额, 充值, 交易, 支付, 钱',
            enUS: 'Amount, recharge, transaction, payment, money',
            zhSpell: 'jine, chongzhi, jiaoyi, zhifu, qian',
          },
          group: 'pay',
        },
        {
          iconCode: 60566,
          searchKey: {
            zhCN: '券, 人民币, 购物券, 优惠券, 代金券, 交易',
            enUS: 'Voucher, RMB, shopping voucher, coupon, voucher, transaction',
            zhSpell: 'quan, renminbi, gouwuquan, youhuiquan, daijinquan, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60567,
          searchKey: {
            zhCN: '金额, 充值, 交易, 支付, 钱',
            enUS: 'Amount, recharge, transaction, payment, money',
            zhSpell: 'jine, chongzhi, jiaoyi, zhifu, qian',
          },
          group: 'pay',
        },
        {
          iconCode: 60568,
          searchKey: {
            zhCN: '卡券, 票, 购物券, 优惠券, 消费, 购物, 商品',
            enUS: 'Card coupon, ticket, shopping voucher, coupon, consumption, shopping, merchandise',
            zhSpell: 'kaquan, piao, gouwuquan, youhuiquan, xiaofei, gouwu, shangpin',
          },
          group: 'pay',
        },
        {
          iconCode: 60569,
          searchKey: {
            zhCN: '卡券, 票, 购物券, 优惠券, 消费, 购物, 商品',
            enUS: 'Card coupon, ticket, shopping voucher, coupon, consumption, shopping, merchandise',
            zhSpell: 'kaquan, piao, gouwuquan, youhuiquan, xiaofei, gouwu, shangpin',
          },
          group: 'pay',
        },
        {
          iconCode: 60570,
          searchKey: {
            zhCN: '券, 人民币, 购物券, 优惠券, 代金券, 交易',
            enUS: 'Voucher, RMB, shopping voucher, coupon, voucher, transaction',
            zhSpell: 'quan, renminbi, gouwuquan, youhuiquan, daijinquan, jiaoyi',
          },
          group: 'pay',
        },
        {
          iconCode: 60571,
          searchKey: {
            zhCN: '卡券, 人民币, 购物券, 优惠券, 代金券, 电影票',
            enUS: 'Card, renminbi, RMB, shopping vouchers, coupons, vouchers, movie ticket',
            zhSpell: 'kaquan, renminbi, gouwuquan, youhuiquan, daijinquan, dianyingpiao',
          },
          group: 'pay',
        },
        {
          iconCode: 60572,
          searchKey: {
            zhCN: '银行卡, 卡片, 信用卡, 白金卡, 借记卡, 储蓄卡',
            enUS: 'Bank card, card, credit card, platinum card, debit card',
            zhSpell: 'yinxingka, kapian, xinyongka, baijinka, jiejika, chuxuka',
          },
          group: 'pay',
        },
        {
          iconCode: 60573,
          searchKey: {
            zhCN: '钱包, 付款, 支付, 钱, 购物, 现金, 包',
            enUS: 'Wallet, payment, payment, money, shopping, cash, package',
            zhSpell: 'qianbao, fukuan, zhifu, qian, gouwu, xianjin, bao',
          },
          group: 'pay',
        },
        {
          iconCode: 60574,
          searchKey: {
            zhCN: '卡券, 人民币, 购物券, 优惠券, 代金券, 电影票',
            enUS: 'Card, renminbi, RMB, shopping vouchers, coupons, vouchers, movie ticket',
            zhSpell: 'kaquan, renminbi, gouwuquan, youhuiquan, daijinquan, dianyingpiao',
          },
          group: 'pay',
        },
        {
          iconCode: 60575,
          searchKey: {
            zhCN: '银行卡, 卡片, 信用卡, 白金卡, 借记卡, 储蓄卡',
            enUS: 'Bank card, card, credit card, platinum card, debit card',
            zhSpell: 'yinxingka, kapian, xinyongka, baijinka, jiejika, chuxuka',
          },
          group: 'pay',
        },
        {
          iconCode: 60576,
          searchKey: {
            zhCN: '已收款, 结账, 支付, 钱, 人民币, 完成, 结清',
            enUS: 'Received, settled, paid, money, RMB, completed, settled',
            zhSpell: 'yishoukuan, jiezhang, zhifu, qian, renminbi, wancheng, jieqing',
          },
          group: 'pay',
        },
        {
          iconCode: 60577,
          searchKey: {
            zhCN: '卡券, 票, 购物券, 优惠券, 消费, 购物, 商品',
            enUS: 'Card coupon, ticket, shopping voucher, coupon, consumption, shopping, merchandise',
            zhSpell: 'kaquan, piao, gouwuquan, youhuiquan, xiaofei, gouwu, shangpin',
          },
          group: 'pay',
        },
        {
          iconCode: 60578,
          searchKey: {
            zhCN: '银行卡, 卡片, 信用卡, 白金卡, 借记卡, 储蓄卡',
            enUS: 'Bank card, card, credit card, platinum card, debit card',
            zhSpell: 'yinxingka, kapian, xinyongka, baijinka, jiejika, chuxuka',
          },
          group: 'pay',
        },
        {
          iconCode: 60579,
          searchKey: {
            zhCN: '银行卡, 卡片, 信用卡, 借记卡, 储蓄卡, 多个',
            enUS: 'Bank card, card, credit card, debit card, multiple',
            zhSpell: 'yinxingka, kapian, xinyongka, jiejika, chuxuka, duoge',
          },
          group: 'pay',
        },
        {
          iconCode: 60580,
          searchKey: {
            zhCN: '银行卡, 提现, 添加, 信用卡, 借记卡, 储蓄卡',
            enUS: 'Bank card, withdrawal, add, credit card, debit card',
            zhSpell: 'yinxingka, tixian, tianjia, xinyongka, jiejika, chuxuka',
          },
          group: 'pay',
        },
      ],
    },
    {
      name: i18n('resource.icons.office'),
      id: 'office',
      items: [
        {
          iconCode: 60416,
          searchKey: {
            zhCN: '办公用品, 眼镜, 镜片, 近视, 视力',
            enUS: 'Office supplies, glasses, lenses, myopia, vision',
            zhSpell: 'bangongyongpin, yanjing, jingpian, jinshi, shili',
          },
          group: 'office',
        },
        {
          iconCode: 60417,
          searchKey: {
            zhCN: '办公用品, 眼镜, 镜片, 近视, 视力',
            enUS: 'Office supplies, glasses, lenses, myopia, vision',
            zhSpell: 'bangongyongpin, yanjing, jingpian, jinshi, shili',
          },
          group: 'office',
        },
        {
          iconCode: 60418,
          searchKey: {
            zhCN: '办公用品, 眼镜, 镜片, 近视, 视力',
            enUS: 'Office supplies, glasses, lenses, myopia, vision',
            zhSpell: 'bangongyongpin, yanjing, jingpian, jinshi, shili',
          },
          group: 'office',
        },
        {
          iconCode: 60419,
          searchKey: {
            zhCN: '办公用品, 尺子, 直尺, 度量, 标尺',
            enUS: 'Office supplies, ruler, ruler, measure, ruler',
            zhSpell: 'bangongyongpin, chizi, zhichi, duliang, biaochi',
          },
          group: 'office',
        },
        {
          iconCode: 60420,
          searchKey: {
            zhCN: '办公用品, 眼镜, 镜片, 近视, 视力',
            enUS: 'Office supplies, glasses, lenses, myopia, vision',
            zhSpell: 'bangongyongpin, yanjing, jingpian, jinshi, shili',
          },
          group: 'office',
        },
        {
          iconCode: 60421,
          searchKey: {
            zhCN: '白板, 办公, 画板, 面板, 展示, 演示',
            enUS: 'Whiteboard, office, drawing board, panel, display, presentation',
            zhSpell: 'baiban, bangong, huaban, mianban, zhanshi, yanshi',
          },
          group: 'office',
        },
        {
          iconCode: 60422,
          searchKey: {
            zhCN: '白板, 办公, 画板, 面板, 黑板, 演示',
            enUS: 'Whiteboard, office, drawing board, panel, blackboard, presentation',
            zhSpell: 'baiban, bangong, huaban, mianban, heiban, yanshi',
          },
          group: 'office',
        },
        {
          iconCode: 60423,
          searchKey: {
            zhCN: '办公用品, 针, 图钉, 钉子, 标记',
            enUS: 'Office supplies, needles, pushpins, nails, markers',
            zhSpell: 'bangongyongpin, zhen, tuding, dingzi, biaoji',
          },
          group: 'office',
        },
        {
          iconCode: 60424,
          searchKey: {
            zhCN: '办公用品, USB, U盘, 存储, 存储卡',
            enUS: 'Office supplies, USB, USB flash drive, storage, memory card',
            zhSpell: 'bangongyongpin, USB, Upan, cunchu, cunchuka',
          },
          group: 'office',
        },
        {
          iconCode: 60425,
          searchKey: {
            zhCN: '笔记, 记录, 编辑, 文档, 笔',
            enUS: 'Notes, records, edits, documents, pens',
            zhSpell: 'biji, jilu, bianji, wendang, bi',
          },
          group: 'office',
        },
        {
          iconCode: 60426,
          searchKey: {
            zhCN: '抽屉, 盒子, 托盘, 文件, 多个',
            enUS: 'Drawers, boxes, trays, documents, multiple',
            zhSpell: 'chouti, hezi, tuopan, wenjian, duoge',
          },
          group: 'office',
        },
        {
          iconCode: 60427,
          searchKey: {
            zhCN: '传输, 上传, 文件夹, 存储, 数据',
            enUS: 'Transfer, upload, folder, store, data',
            zhSpell: 'chuanshu, shangchuan, wenjianjia, cunchu, shuju',
          },
          group: 'office',
        },
        {
          iconCode: 60428,
          searchKey: {
            zhCN: '报告, 目录, 文档, 新闻, 列表, 文件',
            enUS: 'Reports, catalogs, documents, news, lists, documents',
            zhSpell: 'baogao, mulu, wendang, xinwen, liebiao, wenjian',
          },
          group: 'office',
        },
        {
          iconCode: 60429,
          searchKey: {
            zhCN: '记录, 编辑, 笔记, 文档, 笔',
            enUS: 'Record, edit, note, document, pen',
            zhSpell: 'jilu, bianji, biji, wendang, bi',
          },
          group: 'office',
        },
        {
          iconCode: 60430,
          searchKey: {
            zhCN: '抽屉, 盒子, 托盘, 文件, 格子',
            enUS: 'Drawers, boxes, trays, documents, plaids',
            zhSpell: 'chouti, hezi, tuopan, wenjian, gezi',
          },
          group: 'office',
        },
        {
          iconCode: 60431,
          searchKey: {
            zhCN: '传输, 下载, 文件夹, 存储, 数据',
            enUS: 'Transfer, download, folder, store, data',
            zhSpell: 'chuanshu, xiazai, wenjianjia, cunchu, shuju',
          },
          group: 'office',
        },
        {
          iconCode: 60432,
          searchKey: {
            zhCN: '传输, 上传, 文件夹, 存储, 数据',
            enUS: 'Transfer, upload, folder, store, data',
            zhSpell: 'chuanshu, shangchuan, wenjianjia, cunchu, shuju',
          },
          group: 'office',
        },
        {
          iconCode: 60433,
          searchKey: {
            zhCN: '档案夹, 文件夹, 关闭文件夹, 空白, 档案',
            enUS: 'Folder, folder, close folder, blank, file',
            zhSpell: 'danganjia, wenjianjia, guanbiwenjianjia, kongbai, dangan',
          },
          group: 'office',
        },
        {
          iconCode: 60434,
          searchKey: {
            zhCN: '处理成功, 记录, 完成, 笔记, 文档, 检查',
            enUS: 'Processing success, recording, completion, notes, documentation, inspection',
            zhSpell: 'chulichenggong, jilu, wancheng, biji, wendang, jiancha',
          },
          group: 'office',
        },
        {
          iconCode: 60435,
          searchKey: {
            zhCN: '档案夹, 文件夹, 打开文件夹, 空, 档案',
            enUS: 'Folder, folder, open folder, empty, file',
            zhSpell: 'danganjia, wenjianjia, dakaiwenjianjia, kong, dangan',
          },
          group: 'office',
        },
        {
          iconCode: 60436,
          searchKey: {
            zhCN: '报道, 报纸, 新闻, 布局, 排版',
            enUS: 'Report, newspaper, news, layout, typesetting',
            zhSpell: 'baodao, baozhi, xinwen, buju, paiban',
          },
          group: 'office',
        },
        {
          iconCode: 60437,
          searchKey: {
            zhCN: '文件夹, 减少, 删除, 移除, 清除',
            enUS: 'Folder, reduce, delete, remove, clear',
            zhSpell: 'wenjianjia, jianshao, shanchu, yichu, qingchu',
          },
          group: 'office',
        },
        {
          iconCode: 60438,
          searchKey: {
            zhCN: '公文包, 办公, 手提袋, 包, 商业, 商务',
            enUS: 'Briefcase, office, handbag, bag, business, business',
            zhSpell: 'gongwenbao, bangong, shoutidai, bao, shangye, shangwu',
          },
          group: 'office',
        },
        {
          iconCode: 60439,
          searchKey: {
            zhCN: '传输, 下载, 文件夹, 存储, 数据',
            enUS: 'Transfer, download, folder, store, data',
            zhSpell: 'chuanshu, xiazai, wenjianjia, cunchu, shuju',
          },
          group: 'office',
        },
        {
          iconCode: 60440,
          searchKey: {
            zhCN: '公文包, 办公, 手提袋, 包, 商业, 工具箱',
            enUS: 'Briefcase, office, tote bag, bag, business, toolbox',
            zhSpell: 'gongwenbao, bangong, shoutidai, bao, shangye, gongjuxiang',
          },
          group: 'office',
        },
        {
          iconCode: 60441,
          searchKey: {
            zhCN: '文件夹, 增加, 添加, 新建, 创建',
            enUS: 'Folder, add, create',
            zhSpell: 'wenjianjia, zengjia, tianjia, xinjian, chuangjian',
          },
          group: 'office',
        },
        {
          iconCode: 60442,
          searchKey: {
            zhCN: '打印机, 扫描机, 办公用品, 复印, 输出',
            enUS: 'Printer, scanner, office supplies, copy, output',
            zhSpell: 'dayinji, saomiaoji, bangongyongpin, fuyin, shuchu',
          },
          group: 'office',
        },
        {
          iconCode: 60443,
          searchKey: {
            zhCN: '打印机, 扫描机, 办公用品, 复印, 输出, 设备',
            enUS: 'Printer, scanner, office supplies, copy, output, equipment',
            zhSpell: 'dayinji, saomiaoji, bangongyongpin, fuyin, shuchu, shebei',
          },
          group: 'office',
        },
        {
          iconCode: 60444,
          searchKey: {
            zhCN: '文件夹, 新建, 创建, 添加, 文件',
            enUS: 'Folder, new, create, add, file',
            zhSpell: 'wenjianjia, xinjian, chuangjian, tianjia, wenjian',
          },
          group: 'office',
        },
        {
          iconCode: 60445,
          searchKey: {
            zhCN: '抽屉, 盒子, 托盘, 文件, 格子',
            enUS: 'Drawers, boxes, trays, documents, plaids',
            zhSpell: 'chouti, hezi, tuopan, wenjian, gezi',
          },
          group: 'office',
        },
        {
          iconCode: 60446,
          searchKey: {
            zhCN: '档案夹, 文件夹, 关闭文件夹, 空白, 档案',
            enUS: 'Folder, folder, close folder, blank, file',
            zhSpell: 'danganjia, wenjianjia, guanbiwenjianjia, kongbai, dangan',
          },
          group: 'office',
        },
        {
          iconCode: 60447,
          searchKey: {
            zhCN: '盒子, 文件, 存储箱, 存储, 档案',
            enUS: 'Box, file, storage box, storage, file',
            zhSpell: 'hezi, wenjian, cunchuxiang, cunchu, dangan',
          },
          group: 'office',
        },
        {
          iconCode: 60448,
          searchKey: {
            zhCN: '盒子, 文件, 存储箱, 存储, 档案',
            enUS: 'Box, file, storage box, storage, file',
            zhSpell: 'hezi, wenjian, cunchuxiang, cunchu, dangan',
          },
          group: 'office',
        },
        {
          iconCode: 60449,
          searchKey: {
            zhCN: '订单, 单子, 文件, 纸, 稿件, 文档',
            enUS: 'Order, order, document, paper, manuscript, document',
            zhSpell: 'dingdan, danzi, wenjian, zhi, gaojian, wendang',
          },
          group: 'office',
        },
        {
          iconCode: 60450,
          searchKey: {
            zhCN: '盒子, 打包下载, 下载, 存储箱, 存储',
            enUS: 'Box, package download, download, storage box, storage',
            zhSpell: 'hezi, dabaoxiazai, xiazai, cunchuxiang, cunchu',
          },
          group: 'office',
        },
        {
          iconCode: 60451,
          searchKey: {
            zhCN: '盒子, 更多内容, 存储箱, 存储, 文件',
            enUS: 'Box, more content, storage box, storage, file',
            zhSpell: 'hezi, gengduonarong, cunchuxiang, cunchu, wenjian',
          },
          group: 'office',
        },
        {
          iconCode: 60452,
          searchKey: {
            zhCN: '盒子, 打包上传, 上传, 存储箱, 存储',
            enUS: 'Box, package upload, upload, storage box, storage',
            zhSpell: 'hezi, dabaoshangchuan, shangchuan, cunchuxiang, cunchu',
          },
          group: 'office',
        },
        {
          iconCode: 60453,
          searchKey: {
            zhCN: '回收站, 删除, 垃圾桶, 卸载, 扔掉, 抹除',
            enUS: 'Recycle bin, delete, trash can, unload, throw away, erase',
            zhSpell: 'huishouzhan, shanchu, lajitong, xiezai, rengdiao, mochu',
          },
          group: 'office',
        },
        {
          iconCode: 60454,
          searchKey: {
            zhCN: '单页, 下载, 文档, 向下, 文件',
            enUS: 'Single page, download, document, down, file',
            zhSpell: 'danye, xiazai, wendang, xiangxia, wenjian',
          },
          group: 'office',
        },
        {
          iconCode: 60455,
          searchKey: {
            zhCN: '多页, 文档, 文件, 复制, 报告, 粘贴',
            enUS: 'Multiple pages, documents, files, copy, report, paste',
            zhSpell: 'duoye, wendang, wenjian, fuzhi, baogao, zhantie',
          },
          group: 'office',
        },
        {
          iconCode: 60456,
          searchKey: {
            zhCN: '记事板, 单页, 文件, 文档, 笔记, 记录',
            enUS: 'Notepad, single page, file, document, note, record',
            zhSpell: 'jishiban, danye, wenjian, wendang, biji, jilu',
          },
          group: 'office',
        },
        {
          iconCode: 60457,
          searchKey: {
            zhCN: '会议笔记, 记录, 文件, 文档, 编辑, 笔',
            enUS: 'Meeting notes, records, documents, documents, edits, pens',
            zhSpell: 'huiyibiji, jilu, wenjian, wendang, bianji, bi',
          },
          group: 'office',
        },
        {
          iconCode: 60458,
          searchKey: {
            zhCN: '禁止, 禁用, 文件, 文档, 页面, 不可用',
            enUS: 'Prohibited, disabled, files, documents, pages, not available',
            zhSpell: 'jinzhi, jinyong, wenjian, wendang, yemian, bukeyong',
          },
          group: 'office',
        },
        {
          iconCode: 60459,
          searchKey: {
            zhCN: '盒子, 更多内容, 存储箱, 存储, 文件',
            enUS: 'Box, more content, storage box, storage, file',
            zhSpell: 'hezi, gengduonarong, cunchuxiang, cunchu, wenjian',
          },
          group: 'office',
        },
        {
          iconCode: 60460,
          searchKey: {
            zhCN: '文件, 增加, 导入, 文件夹, 进入, 箭头',
            enUS: 'File, add, import, folder, enter, arrow',
            zhSpell: 'wenjian, zengjia, daoru, wenjianjia, jinru, jiantou',
          },
          group: 'office',
        },
        {
          iconCode: 60461,
          searchKey: {
            zhCN: '单页, 上传, 文档, 向上, 文件',
            enUS: 'Single page, upload, document, up, file',
            zhSpell: 'danye, shangchuan, wendang, xiangshang, wenjian',
          },
          group: 'office',
        },
        {
          iconCode: 60462,
          searchKey: {
            zhCN: '公文包, 办公, 手提袋, 包, 商业, 商务, 工具箱',
            enUS: 'Briefcase, office, handbag, bag, business, business, toolbox',
            zhSpell: 'gongwenbao, bangong, shoutidai, bao, shangye, shangwu, gongjuxiang',
          },
          group: 'office',
        },
        {
          iconCode: 60463,
          searchKey: {
            zhCN: '记事板, 空白, 文件, 文档, 空',
            enUS: 'Notepad, blank, file, document, empty',
            zhSpell: 'jishiban, kongbai, wenjian, wendang, kong',
          },
          group: 'office',
        },
        {
          iconCode: 60464,
          searchKey: {
            zhCN: '文件夹, 档案夹, 关闭文件夹, 空白, 档案',
            enUS: 'Folder, folder, close folder, blank, file',
            zhSpell: 'wenjianjia, danganjia, guanbiwenjianjia, kongbai, dangan',
          },
          group: 'office',
        },
        {
          iconCode: 60465,
          searchKey: {
            zhCN: '代码, 办公, 文件, 文档, 文件类型',
            enUS: 'Code, office, file, document, file type',
            zhSpell: 'daima, bangong, wenjian, wendang, wenjianleixing',
          },
          group: 'office',
        },
        {
          iconCode: 60466,
          searchKey: {
            zhCN: '视频, 办公, 文件, 文档, 文件类型',
            enUS: 'Video, office, file, document, file type',
            zhSpell: 'shipin, bangong, wenjian, wendang, wenjianleixing',
          },
          group: 'office',
        },
        {
          iconCode: 60467,
          searchKey: {
            zhCN: '删除, 办公, 表格, 文件, 文档, 文件类型, 清除',
            enUS: 'Delete, office, form, file, document, file type, clear',
            zhSpell: 'shanchu, bangong, biaoge, wenjian, wendang, wenjianleixing, qingchu',
          },
          group: 'office',
        },
        {
          iconCode: 60468,
          searchKey: {
            zhCN: 'PDF, 办公, 文件, 文档, 文件类型',
            enUS: 'PDF, office, file, document, file type',
            zhSpell: 'PDF, bangong, wenjian, wendang, wenjianleixing',
          },
          group: 'office',
        },
        {
          iconCode: 60469,
          searchKey: {
            zhCN: '文件, 文档, 页面, 纸, 稿件, 文件类型',
            enUS: 'File, document, page, paper, manuscript, file type',
            zhSpell: 'wenjian, wendang, yemian, zhi, gaojian, wenjianleixing',
          },
          group: 'office',
        },
        {
          iconCode: 60470,
          searchKey: {
            zhCN: 'PPT, 幻灯版, 办公, 文件, 文档, 文件类型',
            enUS: 'PPT, Powerpoint, slider, office, file, document, file type',
            zhSpell: 'PPT, huandengpian, bangong, wenjian, wendang, wenjianleixing',
          },
          group: 'office',
        },
        {
          iconCode: 60471,
          searchKey: {
            zhCN: '记事板, 复制, 多页, 文档, 文件, 记录',
            enUS: 'Notepad, copy, multiple pages, documents, files, records',
            zhSpell: 'jishiban, fuzhi, duoye, wendang, wenjian, jilu',
          },
          group: 'office',
        },
        {
          iconCode: 60472,
          searchKey: {
            zhCN: '单页, 空白, 文件, 文档, 纸, 空',
            enUS: 'Single page, blank, file, document, paper, empty',
            zhSpell: 'danye, kongbai, wenjian, wendang, zhi, kong',
          },
          group: 'office',
        },
        {
          iconCode: 60473,
          searchKey: {
            zhCN: '爱心, 收藏, 办公, 文件, 文档, 喜爱',
            enUS: 'Love, collection, office, documents, documents, love',
            zhSpell: 'aixin, shoucang, bangong, wenjian, wendang, xiai',
          },
          group: 'office',
        },
        {
          iconCode: 60474,
          searchKey: {
            zhCN: 'WORD, 办公, 文件, 文档, 文件类型',
            enUS: 'WORD, office, file, document, file type',
            zhSpell: 'WORD, bangong, wenjian, wendang, wenjianleixing',
          },
          group: 'office',
        },
        {
          iconCode: 60475,
          searchKey: {
            zhCN: '书籍, 读书笔记, 阅读, 书, 字典',
            enUS: 'Books, reading notes, reading, books, dictionaries',
            zhSpell: 'shuji, dushubiji, yuedu, shu, zidian',
          },
          group: 'office',
        },
        {
          iconCode: 60476,
          searchKey: {
            zhCN: '书籍, 阅读, 书, 学习, 字典, 看书',
            enUS: 'Books, reading, books, learning, dictionaries, reading books',
            zhSpell: 'shuji, yuedu, shu, xuexi, zidian, kanshu',
          },
          group: 'office',
        },
        {
          iconCode: 60477,
          searchKey: {
            zhCN: '日历, 日程, 计划表, 完成, 成功, 日期',
            enUS: 'Calendar, schedule, schedule, completion, success, date',
            zhSpell: 'rili, richeng, jihuabiao, wancheng, chenggong, riqi',
          },
          group: 'office',
        },
        {
          iconCode: 60478,
          searchKey: {
            zhCN: '表格, Excel, 办公, 文件, 文档, 文件类型',
            enUS: 'Form, Excel, office, file, document, file type',
            zhSpell: 'biaoge, Excel, bangong, wenjian, wendang, wenjianleixing',
          },
          group: 'office',
        },
        {
          iconCode: 60479,
          searchKey: {
            zhCN: '压缩, 办公, 文件, 文档, 文件类型',
            enUS: 'Compression, office, file, document, file type',
            zhSpell: 'yasuo, bangong, wenjian, wendang, wenjianleixing',
          },
          group: 'office',
        },
        {
          iconCode: 60480,
          searchKey: {
            zhCN: '日历, 日程, 计划表, 完成, 成功, 日期',
            enUS: 'Calendar, schedule, schedule, completion, success, date',
            zhSpell: 'rili, richeng, jihuabiao, wancheng, chenggong, riqi',
          },
          group: 'office',
        },
        {
          iconCode: 60481,
          searchKey: {
            zhCN: '条文, 列表, 文件, 项目, 目录, 文档, 菜单',
            enUS: 'Articles, lists, files, projects, directories, documents, menus',
            zhSpell: 'tiaowen, liebiao, wenjian, xiangmu, mulu, wendang, caidan',
          },
          group: 'office',
        },
        {
          iconCode: 60482,
          searchKey: {
            zhCN: '下载, 文件, 文档, 传输, 页面',
            enUS: 'Download, file, document, transfer, page',
            zhSpell: 'xiazai, wenjian, wendang, chuanshu, yemian',
          },
          group: 'office',
        },
        {
          iconCode: 60483,
          searchKey: {
            zhCN: '日历, 日程, 计划表, 空白, 事件, 日期',
            enUS: 'Calendar, schedule, schedule, blank, event, date',
            zhSpell: 'rili, richeng, jihuabiao, kongbai, shijian, riqi',
          },
          group: 'office',
        },
        {
          iconCode: 60484,
          searchKey: {
            zhCN: '图片, 图案, 照片, 图像, 图片预览',
            enUS: 'Pictures, patterns, photos, images, photo previews',
            zhSpell: 'tupian, tuan, zhaopian, tuxiang, tupianyulan',
          },
          group: 'office',
        },
        {
          iconCode: 60485,
          searchKey: {
            zhCN: '文档, 成功, 完成, 文件, 页面',
            enUS: 'Document, success, completion, file, page',
            zhSpell: 'wendang, chenggong, wancheng, wenjian, yemian',
          },
          group: 'office',
        },
        {
          iconCode: 60486,
          searchKey: {
            zhCN: '井号, 办公, 文件, 文档, 文件类型',
            enUS: 'Well number, office, file, document, file type',
            zhSpell: 'jinghao, bangong, wenjian, wendang, wenjianleixing',
          },
          group: 'office',
        },
        {
          iconCode: 60487,
          searchKey: {
            zhCN: '图片, 图案, 照片, 图像, 图形, 摄影',
            enUS: 'Pictures, patterns, photos, images, graphics, photography',
            zhSpell: 'tupian, tuan, zhaopian, tuxiang, tuxing, sheying',
          },
          group: 'office',
        },
        {
          iconCode: 60488,
          searchKey: {
            zhCN: '文档, 加密, 安全, 机密, 文件, 锁',
            enUS: 'Document, encryption, security, confidential, file, lock',
            zhSpell: 'wendang, jiami, anquan, jimi, wenjian, suo',
          },
          group: 'office',
        },
        {
          iconCode: 60489,
          searchKey: {
            zhCN: '日历, 日程, 日期, 计划表, 事件, 号数, 数字',
            enUS: 'Calendar, schedule, date, schedule, event, number, number',
            zhSpell: 'rili, richeng, riqi, jihuabiao, shijian, haoshu, shuzi',
          },
          group: 'office',
        },
        {
          iconCode: 60490,
          searchKey: {
            zhCN: '阅读, 书籍, 报纸, 新闻, 学习, 资讯, 信息, 杂志',
            enUS: 'Reading, books, newspapers, news, learning, information, information, magazine',
            zhSpell: 'yuedu, shuji, baozhi, xinwen, xuexi, zixun, xinxi, zazhi',
          },
          group: 'office',
        },
        {
          iconCode: 60491,
          searchKey: {
            zhCN: '声音, 音频, 办公, 文件, 文档, 文件类型',
            enUS: 'Sound, audio, office, file, document, file type',
            zhSpell: 'shengyin, yinpin, bangong, wenjian, wendang, wenjianleixing',
          },
          group: 'office',
        },
        {
          iconCode: 60492,
          searchKey: {
            zhCN: '书籍, 字典, 阅读, 学习, 查阅, 看书',
            enUS: 'Books, dictionaries, reading, learning, consulting, reading',
            zhSpell: 'shuji, zidian, yuedu, xuexi, chayue, kanshu',
          },
          group: 'office',
        },
        {
          iconCode: 60493,
          searchKey: {
            zhCN: '日历, 日程, 日期, 计划表, 事件, 号数, 数字',
            enUS: 'Calendar, schedule, date, schedule, event, number, number',
            zhSpell: 'rili, richeng, riqi, jihuabiao, shijian, haoshu, shuzi',
          },
          group: 'office',
        },
        {
          iconCode: 60494,
          searchKey: {
            zhCN: '条文, 列表, 文件, 项目, 目录, 文档, 菜单',
            enUS: 'Articles, lists, files, projects, directories, documents, menus',
            zhSpell: 'tiaowen, liebiao, wenjian, xiangmu, mulu, wendang, caidan',
          },
          group: 'office',
        },
        {
          iconCode: 60495,
          searchKey: {
            zhCN: '阅读, 书籍, 报纸, 新闻, 学习, 资讯, 信息, 杂志',
            enUS: 'Reading, books, newspapers, news, learning, information, information, magazine',
            zhSpell: 'yuedu, shuji, baozhi, xinwen, xuexi, zixun, xinxi, zazhi',
          },
          group: 'office',
        },
        {
          iconCode: 60496,
          searchKey: {
            zhCN: '文档, 历史, 历史记录, 文件, 页面',
            enUS: 'Document, history, history, file, page',
            zhSpell: 'wendang, lishi, lishijilu, wenjian, yemian',
          },
          group: 'office',
        },
        {
          iconCode: 60497,
          searchKey: {
            zhCN: '相簿, 相册, 集合, 文档, 文件',
            enUS: 'Albums, albums, collections, documents, documents',
            zhSpell: 'xiangbu, xiangce, jihe, wendang, wenjian',
          },
          group: 'office',
        },
        {
          iconCode: 60498,
          searchKey: {
            zhCN: '文档, 增加, 添加, 新建, 文件, 单页',
            enUS: 'Document, add, create, file, single page',
            zhSpell: 'wendang, zengjia, tianjia, xinjian, wenjian, danye',
          },
          group: 'office',
        },
        {
          iconCode: 60499,
          searchKey: {
            zhCN: '文件, 多页, 文档, 复制, 粘贴, 页面',
            enUS: 'File, multiple pages, document, copy, paste, page',
            zhSpell: 'wenjian, duoye, wendang, fuzhi, zhantie, yemian',
          },
          group: 'office',
        },
        {
          iconCode: 60500,
          searchKey: {
            zhCN: '现场记录, 笔记, 笔, 问卷, 表单, 记录',
            enUS: 'Live record, notes, pen, questionnaire, form, record',
            zhSpell: 'xianchangjilu, biji, bi, wenjuan, biaodan, jilu',
          },
          group: 'office',
        },
        {
          iconCode: 60501,
          searchKey: {
            zhCN: '文档, 设置, 工具, 文件, 页面',
            enUS: 'Documents, settings, tools, files, pages',
            zhSpell: 'wendang, shezhi, gongju, wenjian, yemian',
          },
          group: 'office',
        },
        {
          iconCode: 60502,
          searchKey: {
            zhCN: '问卷答案, 提交, 完成, 文件, 页面',
            enUS: 'Questionnaire answers, submissions, completions, documents, pages',
            zhSpell: 'wenjuandaan, tijiao, wancheng, wenjian, yemian',
          },
          group: 'office',
        },
        {
          iconCode: 60503,
          searchKey: {
            zhCN: '文档, 搜索, 查询, 文件, 页面, 查找',
            enUS: 'Document, search, query, file, page, find',
            zhSpell: 'wendang, sousuo, chaxun, wenjian, yemian, chazhao',
          },
          group: 'office',
        },
        {
          iconCode: 60504,
          searchKey: {
            zhCN: '图层, 层, 重叠, 堆放, 文件, 集合',
            enUS: 'Layers, layers, overlaps, stacks, files, collections',
            zhSpell: 'tuceng, ceng, zhongdie, duifang, wenjian, jihe',
          },
          group: 'office',
        },
        {
          iconCode: 60505,
          searchKey: {
            zhCN: '资料, 文档, WORD, 文件, 页面',
            enUS: 'Data, document, WORD, file, page',
            zhSpell: 'ziliao, wendang, WORD, wenjian, yemian',
          },
          group: 'office',
        },
        {
          iconCode: 60506,
          searchKey: {
            zhCN: '下载, 文件, 文档, 传输, 页面',
            enUS: 'Download, file, document, transfer, page',
            zhSpell: 'xiazai, wenjian, wendang, chuanshu, yemian',
          },
          group: 'office',
        },
        {
          iconCode: 60507,
          searchKey: {
            zhCN: '音乐, 办公, 文件, 文档, 文件类型',
            enUS: 'Music, office, file, document, file type',
            zhSpell: 'yinyue, bangong, wenjian, wendang, wenjianleixing',
          },
          group: 'office',
        },
        {
          iconCode: 60508,
          searchKey: {
            zhCN: '资料, 文档, 表格, 表单, 文件, 页面',
            enUS: 'Data, documents, forms, forms, documents, pages',
            zhSpell: 'ziliao, wendang, biaoge, biaodan, wenjian, yemian',
          },
          group: 'office',
        },
        {
          iconCode: 60509,
          searchKey: {
            zhCN: '图纸, 文件, 文档, 空, 文件夹',
            enUS: 'Drawings, documents, documents, empty, folders',
            zhSpell: 'tuzhi, wenjian, wendang, kong, wenjianjia',
          },
          group: 'office',
        },
        {
          iconCode: 60510,
          searchKey: {
            zhCN: '学习计划, 笔记, 记录, 表单, 问卷',
            enUS: 'Learning plan, notes, records, forms, questionnaires',
            zhSpell: 'xuexijihua, biji, jilu, biaodan, wenjuan',
          },
          group: 'office',
        },
        {
          iconCode: 60511,
          searchKey: {
            zhCN: '列表模式, 平铺, 网格, 表格, 格子, 单元格, 九宫格',
            enUS: 'List mode, tile, table, grid, cell, nine-square grid',
            zhSpell: 'liebiaomoshi, pingpu, wangge, biaoge, gezi, danyuange, jiugongge',
          },
          group: 'office',
        },
      ],
    },
    {
      name: i18n('resource.icons.chart'),
      id: 'chart',
      items: [
        {
          iconCode: 60359,
          searchKey: {
            zhCN: '饼图, 数据, 扇形, 统计, 分析',
            enUS: 'Pie chart, data, fan shape, statistics, analysis',
            zhSpell: 'bingtu, shuju, shanxing, tongji, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60360,
          searchKey: {
            zhCN: '饼图, 数据, 扇形, 统计, 分析',
            enUS: 'Pie chart, data, fan shape, statistics, analysis',
            zhSpell: 'bingtu, shuju, shanxing, tongji, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60361,
          searchKey: {
            zhCN: '饼图, 数据, 扇形, 统计, 分析, 区域, 划分',
            enUS: 'Pie chart, data, sector, statistics, analysis, region, division',
            zhSpell: 'bingtu, shuju, shanxing, tongji, fenxi, quyu, huafen',
          },
          group: 'chart',
        },
        {
          iconCode: 60362,
          searchKey: {
            zhCN: '饼图, 数据, 扇形, 统计, 分析, 区域, 划分',
            enUS: 'Pie chart, data, sector, statistics, analysis, region, division',
            zhSpell: 'bingtu, shuju, shanxing, tongji, fenxi, quyu, huafen',
          },
          group: 'chart',
        },
        {
          iconCode: 60363,
          searchKey: {
            zhCN: '饼图, 数据, 扇形, 统计, 分析',
            enUS: 'Pie chart, data, fan shape, statistics, analysis',
            zhSpell: 'bingtu, shuju, shanxing, tongji, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60364,
          searchKey: {
            zhCN: '饼图, 数据, 扇形, 统计, 分析, 区域, 划分',
            enUS: 'Pie chart, data, sector, statistics, analysis, region, division',
            zhSpell: 'bingtu, shuju, shanxing, tongji, fenxi, quyu, huafen',
          },
          group: 'chart',
        },
        {
          iconCode: 60365,
          searchKey: {
            zhCN: '饼图, 数据, 扇形, 统计, 分析',
            enUS: 'Pie chart, data, fan shape, statistics, analysis',
            zhSpell: 'bingtu, shuju, shanxing, tongji, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60366,
          searchKey: {
            zhCN: '饼图, 数据, 扇形, 统计, 分析, 区域, 划分',
            enUS: 'Pie chart, data, sector, statistics, analysis, region, division',
            zhSpell: 'bingtu, shuju, shanxing, tongji, fenxi, quyu, huafen',
          },
          group: 'chart',
        },
        {
          iconCode: 60367,
          searchKey: {
            zhCN: '参数, 数据, 流程, 统计, 分析',
            enUS: 'Parameters, data, processes, statistics, analysis',
            zhSpell: 'canshu, shuju, liucheng, tongji, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60368,
          searchKey: {
            zhCN: '饼图, 数据, 扇形, 统计, 分析, 区域, 划分',
            enUS: 'Pie chart, data, sector, statistics, analysis, region, division',
            zhSpell: 'bingtu, shuju, shanxing, tongji, fenxi, quyu, huafen',
          },
          group: 'chart',
        },
        {
          iconCode: 60369,
          searchKey: {
            zhCN: '点图, 统计, 分析, 数据, 圆点',
            enUS: 'Dot plot, statistics, analysis, data, dots',
            zhSpell: 'diantu, tongji, fenxi, shuju, yuandian',
          },
          group: 'chart',
        },
        {
          iconCode: 60370,
          searchKey: {
            zhCN: '饼图, 数据, 图表, 划分, 区域',
            enUS: 'Pie chart, data, chart, division, area',
            zhSpell: 'bingtu, shuju, tubiao, huafen, quyu',
          },
          group: 'chart',
        },
        {
          iconCode: 60371,
          searchKey: {
            zhCN: '分支, 数据, 分析, 分类, 划分, 节点',
            enUS: 'Branch, data, analysis, classification, division, node',
            zhSpell: 'fenzhi, shuju, fenxi, fenlei, huafen, jiedian',
          },
          group: 'chart',
        },
        {
          iconCode: 60372,
          searchKey: {
            zhCN: '饼图, 数据, 扇形, 统计, 分析, 区域, 划分',
            enUS: 'Pie chart, data, sector, statistics, analysis, region, division',
            zhSpell: 'bingtu, shuju, shanxing, tongji, fenxi, quyu, huafen',
          },
          group: 'chart',
        },
        {
          iconCode: 60373,
          searchKey: {
            zhCN: '积分及数据, 数据库, 单个, 圆柱, 数据, 统计',
            enUS: 'Points and data, data base,  single, cylindrical, data, statistics',
            zhSpell: 'jifenjishuju, shujuku,  dange, yuanzhu, shuju, tongji',
          },
          group: 'chart',
        },
        {
          iconCode: 60374,
          searchKey: {
            zhCN: '参数, 数据, 流程, 统计, 分析',
            enUS: 'Parameters, data, processes, statistics, analysis',
            zhSpell: 'canshu, shuju, liucheng, tongji, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60375,
          searchKey: {
            zhCN: '分支, 数据, 分析, 分类, 划分, 节点',
            enUS: 'Branch, data, analysis, classification, division, node',
            zhSpell: 'fenzhi, shuju, fenxi, fenlei, huafen, jiedian',
          },
          group: 'chart',
        },
        {
          iconCode: 60376,
          searchKey: {
            zhCN: '流程图, 流程, 联系, 数据, 分析, 板块',
            enUS: 'Flow chart, process, contact, data, analysis, plate',
            zhSpell: 'liuchengtu, liucheng, lianxi, shuju, fenxi, bankuai',
          },
          group: 'chart',
        },
        {
          iconCode: 60377,
          searchKey: {
            zhCN: '积分及数据, 金额, 钱, 人民币, 金币',
            enUS: 'Points and data, amount, money, RMB, gold coins',
            zhSpell: 'jifenjishuju, jine, qian, renminbi, jinbi',
          },
          group: 'chart',
        },
        {
          iconCode: 60378,
          searchKey: {
            zhCN: '平稳, 走势, 趋势, 数据, 分析, 上升',
            enUS: 'Smooth, trend, trend, data, analysis, rise',
            zhSpell: 'pingwen, zoushi, qushi, shuju, fenxi, shangsheng',
          },
          group: 'chart',
        },
        {
          iconCode: 60379,
          searchKey: {
            zhCN: '积分及数据, 多个, 圆柱, 数据, 统计',
            enUS: 'Points and data, multiple, cylinder, data, statistics',
            zhSpell: 'jifenjishuju, duoge, yuanzhu, shuju, tongji',
          },
          group: 'chart',
        },
        {
          iconCode: 60380,
          searchKey: {
            zhCN: '流程图, 流程, 联系, 数据, 分析, 板块',
            enUS: 'Flow chart, process, contact, data, analysis, plate',
            zhSpell: 'liuchengtu, liucheng, lianxi, shuju, fenxi, bankuai',
          },
          group: 'chart',
        },
        {
          iconCode: 60381,
          searchKey: {
            zhCN: '划分, 分支, 走向, 分类, 数据',
            enUS: 'Division, branch, trend, classification, data',
            zhSpell: 'huafen, fenzhi, zouxiang, fenlei, shuju',
          },
          group: 'chart',
        },
        {
          iconCode: 60382,
          searchKey: {
            zhCN: '数据, 统计, 分析, 走势, 圆点, 上升',
            enUS: 'Data, statistics, analysis, trends, dots, rise',
            zhSpell: 'shuju, tongji, fenxi, zoushi, yuandian, shangsheng',
          },
          group: 'chart',
        },
        {
          iconCode: 60383,
          searchKey: {
            zhCN: '流程图, 组织结构图, 网站地图, 导航, 数据, 分析',
            enUS: 'Flow chart, organization, structure diagram, site map, navigation, data, analysis',
            zhSpell: 'liuchengtu, zuzhijiegoutu, wangzhanditu, daohang, shuju, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60384,
          searchKey: {
            zhCN: '趋势, 下降, 走势, 数据, 分析',
            enUS: 'Trend, decline, trend, data, analysis',
            zhSpell: 'qushi, xiajiang, zoushi, shuju, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60385,
          searchKey: {
            zhCN: '演示, 数据, 柱状图, 分析, 展示, 屏幕',
            enUS: 'Demo, data, histogram, analysis, display, screen',
            zhSpell: 'yanshi, shuju, zhuzhuangtu, fenxi, zhanshi, pingmu',
          },
          group: 'chart',
        },
        {
          iconCode: 60386,
          searchKey: {
            zhCN: '积分, 数据, 两个, 圆柱, 数据, 统计',
            enUS: 'Points, data base, two, cylinder, data, statistics',
            zhSpell: 'jifen, shujuku, liangge, yuanzhu, shuju, tongji',
          },
          group: 'chart',
        },
        {
          iconCode: 60387,
          searchKey: {
            zhCN: '统计, 柱状图, 笔, 分析, 数据',
            enUS: 'Statistics, histogram, pen, analysis, data',
            zhSpell: 'tongji, zhuzhuangtu, bi, fenxi, shuju',
          },
          group: 'chart',
        },
        {
          iconCode: 60388,
          searchKey: {
            zhCN: '演示, 上升, 增长, 数据, 分析',
            enUS: 'Demonstration, rise, growth, data, analysis',
            zhSpell: 'yanshi, shangsheng, zengzhang, shuju, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60389,
          searchKey: {
            zhCN: '数据, 统计, 分析, 走势, 圆点, 上升',
            enUS: 'Data, statistics, analysis, trends, dots, rise',
            zhSpell: 'shuju, tongji, fenxi, zoushi, yuandian, shangsheng',
          },
          group: 'chart',
        },
        {
          iconCode: 60390,
          searchKey: {
            zhCN: '演示, 上升, 增长, 数据, 分析',
            enUS: 'Demonstration, rise, growth, data, analysis',
            zhSpell: 'yanshi, shangsheng, zengzhang, shuju, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60391,
          searchKey: {
            zhCN: '演示, 数据, 柱状图, 分析, 展示, 屏幕',
            enUS: 'Demo, data, histogram, analysis, display, screen',
            zhSpell: 'yanshi, shuju, zhuzhuangtu, fenxi, zhanshi, pingmu',
          },
          group: 'chart',
        },
        {
          iconCode: 60392,
          searchKey: {
            zhCN: '演示, 上升, 增长, 数据, 分析, 屏幕',
            enUS: 'Demonstration, rise, growth, data, analysis, screen',
            zhSpell: 'yanshi, shangsheng, zengzhang, shuju, fenxi, pingmu',
          },
          group: 'chart',
        },
        {
          iconCode: 60393,
          searchKey: {
            zhCN: '柱状图, 数据, 统计, 分析, 数学',
            enUS: 'Histogram, data, statistics, analysis, mathematics',
            zhSpell: 'zhuzhuangtu, shuju, tongji, fenxi, shuxue',
          },
          group: 'chart',
        },
        {
          iconCode: 60394,
          searchKey: {
            zhCN: '统计, 上升, 趋势, 数据, 分析',
            enUS: 'Statistics, rise, trend, data, analysis',
            zhSpell: 'tongji, shangsheng, qushi, shuju, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60395,
          searchKey: {
            zhCN: '演示, 下降, 数据, 分析, 展示',
            enUS: 'Demonstration, descent, data, analysis, display',
            zhSpell: 'yanshi, xiajiang, shuju, fenxi, zhanshi',
          },
          group: 'chart',
        },
        {
          iconCode: 60396,
          searchKey: {
            zhCN: '柱状图, 数据, 统计, 分析, 趋势',
            enUS: 'Histogram, data, statistics, analysis, trend',
            zhSpell: 'zhuzhuangtu, shuju, tongji, fenxi, qushi',
          },
          group: 'chart',
        },
        {
          iconCode: 60397,
          searchKey: {
            zhCN: '柱状图, 下降, 趋势, 数据, 统计',
            enUS: 'Histogram, drop, trend, data, statistics',
            zhSpell: 'zhuzhuangtu, xiajiang, qushi, shuju, tongji',
          },
          group: 'chart',
        },
        {
          iconCode: 60398,
          searchKey: {
            zhCN: '流程图, 网站地图, 导航, 数据, 分析',
            enUS: 'Flow chart, site map, navigation, data, analysis',
            zhSpell: 'liuchengtu, wangzhanditu, daohang, shuju, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60399,
          searchKey: {
            zhCN: '演示, 下降, 数据, 分析, 展示',
            enUS: 'Demonstration, descent, data, analysis, display',
            zhSpell: 'yanshi, xiajiang, shuju, fenxi, zhanshi',
          },
          group: 'chart',
        },
        {
          iconCode: 60400,
          searchKey: {
            zhCN: '统计, 下降, 趋势, 数据, 分析',
            enUS: 'Statistics, decline, trend, data, analysis',
            zhSpell: 'tongji, xiajiang, qushi, shuju, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60401,
          searchKey: {
            zhCN: '柱状图, 数据, 统计, 分析, 数学',
            enUS: 'Histogram, data, statistics, analysis, mathematics',
            zhSpell: 'zhuzhuangtu, shuju, tongji, fenxi, shuxue',
          },
          group: 'chart',
        },
        {
          iconCode: 60402,
          searchKey: {
            zhCN: '柱状图, 数据, 统计, 分析, 数学',
            enUS: 'Histogram, data, statistics, analysis, mathematics',
            zhSpell: 'zhuzhuangtu, shuju, tongji, fenxi, shuxue',
          },
          group: 'chart',
        },
        {
          iconCode: 60403,
          searchKey: {
            zhCN: '柱状图, 数据, 统计, 分析, 数学',
            enUS: 'Histogram, data, statistics, analysis, mathematics',
            zhSpell: 'zhuzhuangtu, shuju, tongji, fenxi, shuxue',
          },
          group: 'chart',
        },
        {
          iconCode: 60404,
          searchKey: {
            zhCN: '柱状图, 数据, 统计, 分析, 数学',
            enUS: 'Histogram, data, statistics, analysis, mathematics',
            zhSpell: 'zhuzhuangtu, shuju, tongji, fenxi, shuxue',
          },
          group: 'chart',
        },
        {
          iconCode: 60405,
          searchKey: {
            zhCN: '数据, 脉动, 分析, 统计, 心率, 波动, 心电图',
            enUS: 'Data, pulsation, analysis, statistics, heart rate, fluctuations, electrocardiogram',
            zhSpell: 'shuju, maidong, fenxi, tongji, xinlv, bodong, xindiantu',
          },
          group: 'chart',
        },
        {
          iconCode: 60406,
          searchKey: {
            zhCN: '趋势, 上升, 走势, 数据, 分析',
            enUS: 'Trend, rise, trend, data, analysis',
            zhSpell: 'qushi, shangsheng, zoushi, shuju, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60407,
          searchKey: {
            zhCN: '柱状图, 平稳, 数据, 统计, 分析',
            enUS: 'Histogram, smooth, data, statistics, analysis',
            zhSpell: 'zhuzhuangtu, pingwen, shuju, tongji, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60408,
          searchKey: {
            zhCN: '柱状图, 数据, 统计, 分析, 数学',
            enUS: 'Histogram, data, statistics, analysis, mathematics',
            zhSpell: 'zhuzhuangtu, shuju, tongji, fenxi, shuxue',
          },
          group: 'chart',
        },
        {
          iconCode: 60409,
          searchKey: {
            zhCN: '柱状图, 上升, 数据, 统计, 分析',
            enUS: 'Histogram, rise, data, statistics, analysis',
            zhSpell: 'zhuzhuangtu, shangsheng, shuju, tongji, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60410,
          searchKey: {
            zhCN: '柱状图, 数据, 统计, 分析, 数学',
            enUS: 'Histogram, data, statistics, analysis, mathematics',
            zhSpell: 'zhuzhuangtu, shuju, tongji, fenxi, shuxue',
          },
          group: 'chart',
        },
        {
          iconCode: 60411,
          searchKey: {
            zhCN: '上升, 增长, 走势, 数据, 分析',
            enUS: 'Rise, growth, trend, data, analysis',
            zhSpell: 'shangsheng, zengzhang, zoushi, shuju, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60412,
          searchKey: {
            zhCN: '上升, 走势, 柱状, 数据, 分析',
            enUS: 'Rise, trend, column, data, analysis',
            zhSpell: 'shangsheng, zoushi, zhuzhuang, shuju, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60413,
          searchKey: {
            zhCN: '上升, 走势, 柱状, 数据, 分析',
            enUS: 'Rise, trend, column, data, analysis',
            zhSpell: 'shangsheng, zoushi, zhuzhuang, shuju, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60414,
          searchKey: {
            zhCN: '下降, 降低, 走势, 数据, 分析',
            enUS: 'Decrease, decrease, trend, data, analysis',
            zhSpell: 'xiajiang, jiangdi, zoushi, shuju, fenxi',
          },
          group: 'chart',
        },
        {
          iconCode: 60415,
          searchKey: {
            zhCN: '下降, 走势, 柱状, 数据, 分析',
            enUS: 'Decline, trend, column, data, analysis',
            zhSpell: 'xiajiang, zoushi, zhuzhuang, shuju, fenxi',
          },
          group: 'chart',
        },
      ],
    },
    {
      name: i18n('resource.icons.gesture'),
      id: 'gesture',
      items: [
        {
          iconCode: 60334,
          searchKey: {
            zhCN: '点赞, 赞同, 喜欢, 同意, 评价, 手势, 拇指',
            enUS: 'Like, agree, like, agree, rate, gesture, thumb',
            zhSpell: 'dianzan, zantong, xihuan, tongyi, pingjia, shoushi, muzhi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60335,
          searchKey: {
            zhCN: '讨厌, 不喜欢, 踩, 评价, 表达, 手势',
            enUS: 'Hate, dislike, step on, evaluate, express, gesture',
            zhSpell: 'taoyan, buxihuan, cai, pingjia, biaoda, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60336,
          searchKey: {
            zhCN: '讨厌, 不喜欢, 踩, 评价, 表达, 手势',
            enUS: 'Hate, dislike, step on, evaluate, express, gesture',
            zhSpell: 'taoyan, buxihuan, cai, pingjia, biaoda, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60337,
          searchKey: {
            zhCN: '操作, 上滑, 向上, 手指, 手势',
            enUS: 'Operation, slide up, up, finger, gesture',
            zhSpell: 'caozuo, shanghua, xiangshang, shouzhi, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60338,
          searchKey: {
            zhCN: '操作, 向上, 指向, 手指, 空, 手势',
            enUS: 'Operation, up, pointing, finger, empty, gesture',
            zhSpell: 'caozuo, xiangshang, zhixiang, shouzhi, kong, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60339,
          searchKey: {
            zhCN: '操作, 上下滑动, 方向, 手指, 手势',
            enUS: 'Operation, up and down, slide, direction, finger, gesture',
            zhSpell: 'caozuo, shangxiahuadong, fangxiang, shouzhi, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60340,
          searchKey: {
            zhCN: '点赞, 赞同, 喜欢, 同意, 评价, 手势, 拇指',
            enUS: 'Like, agree, like, agree, rate, gesture, thumb',
            zhSpell: 'dianzan, zantong, xihuan, tongyi, pingjia, shoushi, muzhi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60341,
          searchKey: {
            zhCN: '操作, 点击, 手指, 手势, 手',
            enUS: 'Operation, click, finger, gesture, hand',
            zhSpell: 'caozuo, dianji, shouzhi, shoushi, shou',
          },
          group: 'gesture',
        },
        {
          iconCode: 60342,
          searchKey: {
            zhCN: '操作, 下滑, 向下, 手指, 手势',
            enUS: 'Operation, sliding, down, finger, gesture',
            zhSpell: 'caozuo, xiahua, xiangxia, shouzhi, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60343,
          searchKey: {
            zhCN: '操作, 左右滑动, 两边, 手指, 手势',
            enUS: 'Operation, left and right, side, fingers, gestures',
            zhSpell: 'caozuo, zuoyouhuadong, liangbian, shouzhi, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60344,
          searchKey: {
            zhCN: '操作, 点击, 按下, 手指, 手势, 手',
            enUS: 'Operation, click, press, finger, gesture, hand',
            zhSpell: 'caozuo, dianji, anxia, shouzhi, shoushi, shou',
          },
          group: 'gesture',
        },
        {
          iconCode: 60345,
          searchKey: {
            zhCN: '手, 向上, 指向, 手指, 手势',
            enUS: 'Hand, pointing up, pointing, finger, gesture',
            zhSpell: 'shou, xiangshang, zhixiang, shouzhi, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60346,
          searchKey: {
            zhCN: '手, 向右, 指向, 手指, 手势',
            enUS: 'Hand, right, pointing, finger, gesture',
            zhSpell: 'shou, xiangyou, zhixiang, shouzhi, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60347,
          searchKey: {
            zhCN: '手, 向右, 指向, 手指, 手势',
            enUS: 'Hand, right, pointing, finger, gesture',
            zhSpell: 'shou, xiangyou, zhixiang, shouzhi, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60348,
          searchKey: {
            zhCN: '手, 向上, 指向, 手指, 手势',
            enUS: 'Hand, pointing up, pointing, finger, gesture',
            zhSpell: 'shou, xiangshang, zhixiang, shouzhi, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60349,
          searchKey: {
            zhCN: '手, 向下, 指向, 手指, 手势',
            enUS: 'Hand, down, pointing, finger, gesture',
            zhSpell: 'shou, xiangxia, zhixiang, shouzhi, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60350,
          searchKey: {
            zhCN: '手, 向下, 指向, 手指, 手势',
            enUS: 'Hand, down, pointing, finger, gesture',
            zhSpell: 'shou, xiangxia, zhixiang, shouzhi, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60351,
          searchKey: {
            zhCN: '手, 向左, 指向, 手指, 手势',
            enUS: 'Hand, left, pointing, finger, gesture',
            zhSpell: 'shou, xiangzuo, zhixiang, shouzhi, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60352,
          searchKey: {
            zhCN: '手, 向左, 指向, 手指, 手势',
            enUS: 'Hand, left, pointing, finger, gesture',
            zhSpell: 'shou, xiangzuo, zhixiang, shouzhi, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60353,
          searchKey: {
            zhCN: '操作, 交互, 左右, 方向, 手指, 手势',
            enUS: 'Operation, interact, left and right, direction, finger, gesture',
            zhSpell: 'caozuo, jiaohu, zuoyou, fangxiang, shouzhi, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60354,
          searchKey: {
            zhCN: '操作, 点击, 按下, 手指, 手势, 手',
            enUS: 'Operation, click, press, finger, gesture, hand',
            zhSpell: 'caozuo, dianji, anxia, shouzhi, shoushi, shou',
          },
          group: 'gesture',
        },
        {
          iconCode: 60355,
          searchKey: {
            zhCN: '手, 向左, 指向, 手指, 手势',
            enUS: 'Hand, left, pointing, finger, gesture',
            zhSpell: 'shou, xiangzuo, zhixiang, shouzhi, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60356,
          searchKey: {
            zhCN: '操作, 向上, 指向, 手指, 空, 手势',
            enUS: 'Operation, up, pointing, finger, empty, gesture',
            zhSpell: 'caozuo, xiangshang, zhixiang, shouzhi, kong, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60357,
          searchKey: {
            zhCN: '手, 向下, 指向, 手指, 手势',
            enUS: 'Hand, down, pointing, finger, gesture',
            zhSpell: 'shou, xiangxia, zhixiang, shouzhi, shoushi',
          },
          group: 'gesture',
        },
        {
          iconCode: 60358,
          searchKey: {
            zhCN: '手, 向右, 指向, 手指, 手势',
            enUS: 'Hand, right, pointing, finger, gesture',
            zhSpell: 'shou, xiangyou, zhixiang, shouzhi, shoushi',
          },
          group: 'gesture',
        },
      ],
    },
    {
      name: i18n('resource.icons.social'),
      id: 'social',
      items: [
        {
          iconCode: 60240,
          searchKey: {
            zhCN: '爱心之家, 主页, 首页, 房子, 住宅, 心',
            enUS: 'Home of love, homepage, home, house, residence, heart',
            zhSpell: 'aixinzhijia, zhuye, shouye, fangzi, zhuzhai, xin',
          },
          group: 'social',
        },
        {
          iconCode: 60241,
          searchKey: {
            zhCN: '可见, 查看, 预览, 看见, 眼睛',
            enUS: 'Visible, view, preview, see, eyes',
            zhSpell: 'kejian, chakan, yulan, kanjian, yanjing',
          },
          group: 'social',
        },
        {
          iconCode: 60242,
          searchKey: {
            zhCN: '可见, 查看, 预览, 看见, 眼睛',
            enUS: 'Visible, view, preview, see, eyes',
            zhSpell: 'kejian, chakan, yulan, kanjian, yanjing',
          },
          group: 'social',
        },
        {
          iconCode: 60243,
          searchKey: {
            zhCN: '不可见, 查看, 视角, 隐私, 眼睛, 盲区',
            enUS: 'Invisible, view, perspective, privacy, eyes, blind spot',
            zhSpell: 'bukejian, chakan, shijiao, yinsi, yanjing, mangqu',
          },
          group: 'social',
        },
        {
          iconCode: 60244,
          searchKey: {
            zhCN: '爱心之家, 主页, 首页, 房子, 住宅, 心',
            enUS: 'Home of love, homepage, home, house, residence, heart',
            zhSpell: 'aixinzhijia, zhuye, shouye, fangzi, zhuzhai, xin',
          },
          group: 'social',
        },
        {
          iconCode: 60245,
          searchKey: {
            zhCN: '可见, 查看, 预览, 看见, 眼睛',
            enUS: 'Visible, view, preview, see, eyes',
            zhSpell: 'kejian, chakan, yulan, kanjian, yanjing',
          },
          group: 'social',
        },
        {
          iconCode: 60246,
          searchKey: {
            zhCN: '分享, 链接, 转发, 社交, 网络',
            enUS: 'Share, link, forward, social, network',
            zhSpell: 'fenxiang, lianjie, zhuanfa, shejiao, wangluo',
          },
          group: 'social',
        },
        {
          iconCode: 60247,
          searchKey: {
            zhCN: '发现, 探索, 圈子, 动态, 社交, 圆',
            enUS: 'Discover, explore, circle, dynamic, social, round',
            zhSpell: 'faxian, tansuo, quanzi, dongtai, shejiao, yuan',
          },
          group: 'social',
        },
        {
          iconCode: 60248,
          searchKey: {
            zhCN: '脸, 得意, 搞怪, 表情, 态度',
            enUS: 'Face, smug, funny, expression, attitude',
            zhSpell: 'lian, deyi, gaoguai, biaoqing, taidu',
          },
          group: 'social',
        },
        {
          iconCode: 60249,
          searchKey: {
            zhCN: '电话, 联系, 呼叫, 通讯, 打电话',
            enUS: 'Phone, contact, call, communication, call',
            zhSpell: 'dianhua, lianxi, hujiao, tongxun, dadianhua',
          },
          group: 'social',
        },
        {
          iconCode: 60250,
          searchKey: {
            zhCN: '发布, 飞机, 发送, 传达, 邮件',
            enUS: 'Release, aircraft, send, communicate, mail',
            zhSpell: 'fabu, feiji, fasong, chuanda, youjian',
          },
          group: 'social',
        },
        {
          iconCode: 60251,
          searchKey: {
            zhCN: '分享, 链接, 转发, 社交, 网络, 按钮',
            enUS: 'Share, link, forward, social, network, button',
            zhSpell: 'fenxiang, lianjie, zhuanfa, shejiao, wangluo, anniu',
          },
          group: 'social',
        },
        {
          iconCode: 60252,
          searchKey: {
            zhCN: '分享, 转发, 箭头, 社交, 恢复',
            enUS: 'Share, forward, arrow, social, recovery',
            zhSpell: 'fenxiang, zhuanfa, jiantou, shejiao, huifu',
          },
          group: 'social',
        },
        {
          iconCode: 60253,
          searchKey: {
            zhCN: '脸, 惊奇, 惊讶, 表情, 态度',
            enUS: 'Face, surprise, surprise, expression, attitude',
            zhSpell: 'lian, jingqi, jingya, biaoqing, taidu',
          },
          group: 'social',
        },
        {
          iconCode: 60254,
          searchKey: {
            zhCN: '脸, 微笑, 高兴, 笑脸, 表情, 满意',
            enUS: 'Face, smile, happy, smile, expression, satisfaction',
            zhSpell: 'lian, weixiao, gaoxing, xiaolian, biaoqing, manyi',
          },
          group: 'social',
        },
        {
          iconCode: 60255,
          searchKey: {
            zhCN: '客服, 人工服务, 语音, 服务人员, 在线咨询',
            enUS: 'Customer service, manual service, voice, service staff, online consultation',
            zhSpell: 'kefu, rengongfuwu, yuyin, fuwurenyuan, zaixianzixun',
          },
          group: 'social',
        },
        {
          iconCode: 60256,
          searchKey: {
            zhCN: '分享, 转发, 箭头, 社交, 恢复',
            enUS: 'Share, forward, arrow, social, recovery',
            zhSpell: 'fenxiang, zhuanfa, jiantou, shejiao, huifu',
          },
          group: 'social',
        },
        {
          iconCode: 60257,
          searchKey: {
            zhCN: '脸, 苦, 不高兴, 难过, 表情, 不满意',
            enUS: 'Face, bitter, unhappy, sad, expression, dissatisfied',
            zhSpell: 'lian, ku, bugaoxing, nanguo, biaoqing, bumanyi',
          },
          group: 'social',
        },
        {
          iconCode: 60258,
          searchKey: {
            zhCN: '脸, 惊奇, 惊讶, 表情, 态度',
            enUS: 'Face, surprise, surprise, expression, attitude',
            zhSpell: 'lian, jingqi, jingya, biaoqing, taidu',
          },
          group: 'social',
        },
        {
          iconCode: 60259,
          searchKey: {
            zhCN: '脸, 中立, 冷漠, 表情, 表情符号',
            enUS: 'Face, neutral, indifference, expression, emoji',
            zhSpell: 'lian, zhongli, lingmo, biaoqing, biaoqingfuhao',
          },
          group: 'social',
        },
        {
          iconCode: 60260,
          searchKey: {
            zhCN: '脸, 中立, 冷漠, 表情, 表情符号',
            enUS: 'Face, neutral, indifference, expression, emoji',
            zhSpell: 'lian, zhongli, lingmo, biaoqing, biaoqingfuhao',
          },
          group: 'social',
        },
        {
          iconCode: 60261,
          searchKey: {
            zhCN: '脸, 得意, 搞怪, 表情, 态度',
            enUS: 'Face, smug, funny, expression, attitude',
            zhSpell: 'lian, deyi, gaoguai, biaoqing, taidu',
          },
          group: 'social',
        },
        {
          iconCode: 60262,
          searchKey: {
            zhCN: '客服, 人工服务, 语音, 服务人员, 在线咨询',
            enUS: 'Customer service, manual service, voice, service staff, online consultation',
            zhSpell: 'kefu, rengongfuwu, yuyin, fuwurenyuan, zaixianzixun',
          },
          group: 'social',
        },
        {
          iconCode: 60263,
          searchKey: {
            zhCN: '电话簿, 通讯录, 联系人, 地址簿, 电话',
            enUS: 'Phone book, address book, contact, address book, phone',
            zhSpell: 'dianhuabu, tongxunlu, lianxiren, dizhibu, dianhua',
          },
          group: 'social',
        },
        {
          iconCode: 60264,
          searchKey: {
            zhCN: '脸, 微笑, 高兴, 笑脸, 表情, 满意',
            enUS: 'Face, smile, happy, smile, expression, satisfaction',
            zhSpell: 'lian, weixiao, gaoxing, xiaolian, biaoqing, manyi',
          },
          group: 'social',
        },
        {
          iconCode: 60265,
          searchKey: {
            zhCN: '脸, 苦, 不高兴, 难过, 表情, 不满意',
            enUS: 'Face, bitter, unhappy, sad, expression, dissatisfied',
            zhSpell: 'lian, ku, bugaoxing, nanguo, biaoqing, bumanyi',
          },
          group: 'social',
        },
        {
          iconCode: 60266,
          searchKey: {
            zhCN: '聊天, 信息, 气泡, 对话框, 交流',
            enUS: 'Chat, information, bubble, dialog, communication',
            zhSpell: 'liaotian, xinxi, qipao, duihuakuang, jiaoliu',
          },
          group: 'social',
        },
        {
          iconCode: 60267,
          searchKey: {
            zhCN: '聊天, 信息, 气泡, 对话框, 交流, 空',
            enUS: 'Chat, message, bubble, dialog, exchange, empty',
            zhSpell: 'liaotian, xinxi, qipao, duihuakuang, jiaoliu, kong',
          },
          group: 'social',
        },
        {
          iconCode: 60268,
          searchKey: {
            zhCN: '聊天, 信息, 消息, 气泡, 留言, 交流, 评论',
            enUS: 'Chat, message, message, bubble, message, exchange, comment',
            zhSpell: 'liaotian, xinxi, xiaoxi, qipao, liuyan, jiaoliu, pinglun',
          },
          group: 'social',
        },
        {
          iconCode: 60269,
          searchKey: {
            zhCN: '聊天, 信息, 消息, 气泡, 留言, 交流, 评论',
            enUS: 'Chat, message, message, bubble, message, exchange, comment',
            zhSpell: 'liaotian, xinxi, xiaoxi, qipao, liuyan, jiaoliu, pinglun',
          },
          group: 'social',
        },
        {
          iconCode: 60270,
          searchKey: {
            zhCN: '聊天, 群聊, 交流, 讨论, 信息, 消息',
            enUS: 'Chat, group chat, exchange, discussion, information, message',
            zhSpell: 'liaotian, qunliao, jiaoliu, taolun, xinxi, xiaoxi',
          },
          group: 'social',
        },
        {
          iconCode: 60271,
          searchKey: {
            zhCN: '聊天, 群聊, 交流, 讨论, 信息, 消息',
            enUS: 'Chat, group chat, exchange, discussion, information, message',
            zhSpell: 'liaotian, qunliao, jiaoliu, taolun, xinxi, xiaoxi',
          },
          group: 'social',
        },
        {
          iconCode: 60272,
          searchKey: {
            zhCN: '聊天, 群聊, 交流, 讨论, 信息, 消息',
            enUS: 'Chat, group chat, exchange, discussion, information, message',
            zhSpell: 'liaotian, qunliao, jiaoliu, taolun, xinxi, xiaoxi',
          },
          group: 'social',
        },
        {
          iconCode: 60273,
          searchKey: {
            zhCN: '聊天, 信息, 消息, 气泡, 留言, 交流, 评论',
            enUS: 'Chat, message, message, bubble, message, exchange',
            zhSpell: 'liaotian, xinxi, xiaoxi, qipao, liuyan, jiaoliu, pinglun, comment',
          },
          group: 'social',
        },
        {
          iconCode: 60274,
          searchKey: {
            zhCN: '聊天, 信息, 消息, 气泡, 留言, 交流, 评论',
            enUS: 'Chat, message, message, bubble, message, exchange',
            zhSpell: 'liaotian, xinxi, xiaoxi, qipao, liuyan, jiaoliu, pinglun, comment',
          },
          group: 'social',
        },
        {
          iconCode: 60275,
          searchKey: {
            zhCN: '聊天, 信息, 消息, 气泡, 留言, 交流, 评论',
            enUS: 'Chat, message, message, bubble, message, exchange, comment',
            zhSpell: 'liaotian, xinxi, xiaoxi, qipao, liuyan, jiaoliu, pinglun',
          },
          group: 'social',
        },
        {
          iconCode: 60276,
          searchKey: {
            zhCN: '聊天, 群聊, 交流, 讨论, 信息, 消息',
            enUS: 'Chat, group chat, exchange, discussion, information, message',
            zhSpell: 'liaotian, qunliao, jiaoliu, taolun, xinxi, xiaoxi',
          },
          group: 'social',
        },
        {
          iconCode: 60277,
          searchKey: {
            zhCN: '聊天, 信息, 气泡, 对话框, 交流, 更多',
            enUS: 'Chat, information, bubbles, dialogs, communication, more',
            zhSpell: 'liaotian, xinxi, qipao, duihuakuang, jiaoliu, gengduo',
          },
          group: 'social',
        },
        {
          iconCode: 60278,
          searchKey: {
            zhCN: '聊天, 信息, 气泡, 对话框, 交流, 更多',
            enUS: 'Chat, information, bubbles, dialogs, communication, more',
            zhSpell: 'liaotian, xinxi, qipao, duihuakuang, jiaoliu, gengduo',
          },
          group: 'social',
        },
        {
          iconCode: 60279,
          searchKey: {
            zhCN: '人物, 成功, 完成, 确认, 打钩',
            enUS: 'Character, success, completion, confirmation, check',
            zhSpell: 'renwu, chenggong, wancheng, queren, dagou',
          },
          group: 'social',
        },
        {
          iconCode: 60280,
          searchKey: {
            zhCN: '聊天, 信息, 气泡, 对话框, 交流',
            enUS: 'Chat, information, bubble, dialog, communication',
            zhSpell: 'liaotian, xinxi, qipao, duihuakuang, jiaoliu',
          },
          group: 'social',
        },
        {
          iconCode: 60281,
          searchKey: {
            zhCN: '朋友圈, 圈子, 社交圈, 动态, 发现',
            enUS: 'Circle of friends, circles, social circles, dynamics, discovery',
            zhSpell: 'pengyouquan, quanzi, shejiaoquan, dongtai, faxian',
          },
          group: 'social',
        },
        {
          iconCode: 60282,
          searchKey: {
            zhCN: '聊天, 信息, 消息, 气泡, 留言, 交流',
            enUS: 'Chat, message, message, bubble, message, exchange',
            zhSpell: 'liaotian, xinxi, xiaoxi, qipao, liuyan, jiaoliu',
          },
          group: 'social',
        },
        {
          iconCode: 60283,
          searchKey: {
            zhCN: '评价, 好评, 花, 评论, 点评',
            enUS: 'Evaluation, praise, flower, reviews, reviews',
            zhSpell: 'pingjia, haoping, hua, pinglun, dianping',
          },
          group: 'social',
        },
        {
          iconCode: 60284,
          searchKey: {
            zhCN: '评价, 差评, 花, 评论, 点评',
            enUS: 'Evaluation, bad review, flower, reviews, reviews',
            zhSpell: 'pingjia, chaping, hua, pinglun, dianping',
          },
          group: 'social',
        },
        {
          iconCode: 60285,
          searchKey: {
            zhCN: '评价, 好评, 花, 评论, 点评',
            enUS: 'Evaluation, praise, flower, reviews, reviews',
            zhSpell: 'pingjia, haoping, hua, pinglun, dianping',
          },
          group: 'social',
        },
        {
          iconCode: 60286,
          searchKey: {
            zhCN: '评价, 差评, 花, 评论, 点评',
            enUS: 'Evaluation, bad review, flower, reviews, reviews',
            zhSpell: 'pingjia, chaping, hua, pinglun, dianping',
          },
          group: 'social',
        },
        {
          iconCode: 60287,
          searchKey: {
            zhCN: '团队, 群组, 多人, 用户, 人群',
            enUS: 'Team, group, multiplayer, user, crowd',
            zhSpell: 'tuandui, qunzu, duoren, yonghu, renqun',
          },
          group: 'social',
        },
        {
          iconCode: 60288,
          searchKey: {
            zhCN: '人物, 移除, 删除, 清空, 减',
            enUS: 'Character, remove, delete, empty, subtract',
            zhSpell: 'renwu, yichu, shanchu, qingkong, jian',
          },
          group: 'social',
        },
        {
          iconCode: 60289,
          searchKey: {
            zhCN: '人物, 移除, 删除, 清空, 叉, 错误',
            enUS: 'Character, remove, delete, empty, cross, error',
            zhSpell: 'renwu, yichu, shanchu, qingkong, cha, cuowu',
          },
          group: 'social',
        },
        {
          iconCode: 60290,
          searchKey: {
            zhCN: '人物, 邮件, 信息, 信封, 收件, 发送',
            enUS: 'Character, mail, message, envelope, receipt, send',
            zhSpell: 'renwu, youjian, xinxi, xinfeng, shoujian, fasong',
          },
          group: 'social',
        },
        {
          iconCode: 60291,
          searchKey: {
            zhCN: '个人, 人, 联系人, 用户, 头像',
            enUS: 'Personal, person, contact, user, avatar',
            zhSpell: 'geren, ren, lianxiren, yonghu, touxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60292,
          searchKey: {
            zhCN: '人物, @, 信息, 邮件, 符号, 社交',
            enUS: 'People, @, information, mail, symbols, social',
            zhSpell: 'renwu, @, xinxi, youjian, fuhao, shejiao',
          },
          group: 'social',
        },
        {
          iconCode: 60293,
          searchKey: {
            zhCN: '聊天, 群聊, 交流, 讨论, 信息, 消息',
            enUS: 'Chat, group chat, exchange, discussion, information, message',
            zhSpell: 'liaotian, qunliao, jiaoliu, taolun, xinxi, xiaoxi',
          },
          group: 'social',
        },
        {
          iconCode: 60294,
          searchKey: {
            zhCN: '人物, 人, 联系人, 移除, 删除, 减少',
            enUS: 'People, people, contacts, remove, delete, reduce',
            zhSpell: 'renwu, ren, lianxiren, yichu, shanchu, jianshao',
          },
          group: 'social',
        },
        {
          iconCode: 60295,
          searchKey: {
            zhCN: '个人, 人, 联系人, 用户, 头像',
            enUS: 'Personal, person, contact, user, avatar',
            zhSpell: 'geren, ren, lianxiren, yonghu, touxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60296,
          searchKey: {
            zhCN: '个人, 人, 联系人, 用户, 头像',
            enUS: 'Personal, person, contact, user, avatar',
            zhSpell: 'geren, ren, lianxiren, yonghu, touxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60297,
          searchKey: {
            zhCN: '人物, 人, 联系人, 添加, 增加, 新增',
            enUS: 'People, contacts, add',
            zhSpell: 'renwu, ren, lianxiren, tianjia, zengjia, xinzeng',
          },
          group: 'social',
        },
        {
          iconCode: 60298,
          searchKey: {
            zhCN: '评论, 点赞, 赞, 评价, 表达, 手势, 拇指',
            enUS: 'Comment, like, praise, evaluation, expression, gesture, thumb',
            zhSpell: 'pinglun, dianzan, zan, pingjia, biaoda, shoushi, muzhi',
          },
          group: 'social',
        },
        {
          iconCode: 60299,
          searchKey: {
            zhCN: '评论, 点赞, 赞, 评价, 表达, 手势, 拇指',
            enUS: 'Comment, like, praise, evaluation, expression, gesture, thumb',
            zhSpell: 'pinglun, dianzan, zan, pingjia, biaoda, shoushi, muzhi',
          },
          group: 'social',
        },
        {
          iconCode: 60300,
          searchKey: {
            zhCN: '个人, 人, 联系人, 用户, 头像',
            enUS: 'Personal, person, contact, user, avatar',
            zhSpell: 'geren, ren, lianxiren, yonghu, touxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60301,
          searchKey: {
            zhCN: '个人, 人, 联系人, 用户, 头像',
            enUS: 'Personal, person, contact, user, avatar',
            zhSpell: 'geren, ren, lianxiren, yonghu, touxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60302,
          searchKey: {
            zhCN: '团队, 群组, 多人, 用户, 朋友',
            enUS: 'Team, group, multiplayer, user, friend',
            zhSpell: 'tuandui, qunzu, duoren, yonghu, pengyou',
          },
          group: 'social',
        },
        {
          iconCode: 60303,
          searchKey: {
            zhCN: '我, 人, 联系人, 用户, 头像',
            enUS: 'Me, person, contact, user, avatar',
            zhSpell: 'wo, ren, lianxiren, yonghu, touxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60304,
          searchKey: {
            zhCN: '人物, 人, 联系人, 移除, 删除, 减少',
            enUS: 'People, contacts, remove, delete, reduce',
            zhSpell: 'renwu, ren, lianxiren, yichu, shanchu, jianshao',
          },
          group: 'social',
        },
        {
          iconCode: 60305,
          searchKey: {
            zhCN: '人物, 人, 联系人, 添加, 增加, 新增',
            enUS: 'People, contacts, add',
            zhSpell: 'renwu, ren, lianxiren, tianjia, zengjia, xinzeng',
          },
          group: 'social',
        },
        {
          iconCode: 60306,
          searchKey: {
            zhCN: '身份证, 验证, 通讯录, 成功, 证件, 通过',
            enUS: 'ID card, verification, address book, success, certificate, pass',
            zhSpell: 'shenfenzheng, yanzheng, tongxunlu, chenggong, zhengjian, tongguo',
          },
          group: 'social',
        },
        {
          iconCode: 60307,
          searchKey: {
            zhCN: '人物, 人, 联系人, 添加, 增加, 新增',
            enUS: 'People, contacts, add',
            zhSpell: 'renwu, ren, lianxiren, tianjia, zengjia, xinzeng',
          },
          group: 'social',
        },
        {
          iconCode: 60308,
          searchKey: {
            zhCN: '电话簿, 通讯录, 联系人, 书籍, 相册, 笔记本',
            enUS: 'Phone book, contacts, contacts, books, photo albums, notebooks',
            zhSpell: 'dianhuabu, tongxunlu, lianxiren, shuji, xiangce, bijiben',
          },
          group: 'social',
        },
        {
          iconCode: 60309,
          searchKey: {
            zhCN: '个人, 人, 联系人, 用户, 头像',
            enUS: 'Personal, person, contact, user, avatar',
            zhSpell: 'geren, ren, lianxiren, yonghu, touxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60310,
          searchKey: {
            zhCN: '女, 人, 联系人, 用户, 头像',
            enUS: 'Female, person, contact, user, avatar',
            zhSpell: 'nv, ren, lianxiren, yonghu, touxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60311,
          searchKey: {
            zhCN: '心, 收藏, 评价, 投票, 爱心, 喜欢',
            enUS: 'Heart, collection, evaluation, voting, love, like',
            zhSpell: 'xin, shoucang, pingjia, toupiao, aixin, xihuan',
          },
          group: 'social',
        },
        {
          iconCode: 60312,
          searchKey: {
            zhCN: '邮件, 删除, 清空, 信, 信封',
            enUS: 'Mail, delete, empty, letter, envelope',
            zhSpell: 'youjian, shanchu, qingkong, xin, xinfeng',
          },
          group: 'social',
        },
        {
          iconCode: 60313,
          searchKey: {
            zhCN: '女, 人, 联系人, 用户, 头像',
            enUS: 'Female, person, contact, user, avatar',
            zhSpell: 'nv, ren, lianxiren, yonghu, touxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60314,
          searchKey: {
            zhCN: '个人, 人, 联系人, 用户, 头像',
            enUS: 'Personal, person, contact, user, avatar',
            zhSpell: 'geren, ren, lianxiren, yonghu, touxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60315,
          searchKey: {
            zhCN: '个人, 人, 联系人, 用户, 头像',
            enUS: 'Personal, person, contact, user, avatar',
            zhSpell: 'geren, ren, lianxiren, yonghu, touxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60316,
          searchKey: {
            zhCN: '星星, 收藏, 评分, 精选, 书签',
            enUS: 'Stars, collections, ratings, featured, bookmarks',
            zhSpell: 'xingxing, shoucang, pingfen, jingxuan, shuqian',
          },
          group: 'social',
        },
        {
          iconCode: 60317,
          searchKey: {
            zhCN: '邮件, 信件, 信, 信封, 收件箱, 按钮',
            enUS: 'Mail, letter, letter, envelope, inbox, button',
            zhSpell: 'youjian, xinjian, xin, xinfeng, shoujianxiang, anniu',
          },
          group: 'social',
        },
        {
          iconCode: 60318,
          searchKey: {
            zhCN: '邮件, 信件, 信, 信封, 收件箱',
            enUS: 'Mail, letter, letter, envelope, inbox',
            zhSpell: 'youjian, xinjian, xin, xinfeng, shoujianxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60319,
          searchKey: {
            zhCN: '邮件, 添加, 增加, 新增, 信, 信封',
            enUS: 'Mail, add, letter, envelope',
            zhSpell: 'youjian, tianjia, zengjia, xinzeng, xin, xinfeng',
          },
          group: 'social',
        },
        {
          iconCode: 60320,
          searchKey: {
            zhCN: '邮件, 信件, 信, 信封, 收件箱',
            enUS: 'Mail, letter, envelope, inbox',
            zhSpell: 'youjian, xinjian, xin, xinfeng, shoujianxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60321,
          searchKey: {
            zhCN: '主页, 首页, 家, 房子, 住宅',
            enUS: 'Home, house, residential',
            zhSpell: 'zhuye, shouye, jia, fangzi, zhuzhai',
          },
          group: 'social',
        },
        {
          iconCode: 60322,
          searchKey: {
            zhCN: '主页, 首页, 家, 房子, 住宅',
            enUS: 'Home, house, residential',
            zhSpell: 'zhuye, shouye, jia, fangzi, zhuzhai',
          },
          group: 'social',
        },
        {
          iconCode: 60323,
          searchKey: {
            zhCN: '主页, 首页, 家, 房子, 住宅',
            enUS: 'Home,house, residential',
            zhSpell: 'zhuye, shouye, jia, fangzi, zhuzhai',
          },
          group: 'social',
        },
        {
          iconCode: 60324,
          searchKey: {
            zhCN: '电话簿, 通讯录, 联系人, 书籍, 相册',
            enUS: 'Phone book, contacts, books, photo albums',
            zhSpell: 'dianhuabu, tongxunlu, lianxiren, shuji, xiangce',
          },
          group: 'social',
        },
        {
          iconCode: 60325,
          searchKey: {
            zhCN: '邮件, 多个, 信, 信封, 收件箱, 集',
            enUS: 'Mail, multiple, letter, envelope, inbox, set',
            zhSpell: 'youjian, duoge, xin, xinfeng, shoujianxiang, ji',
          },
          group: 'social',
        },
        {
          iconCode: 60326,
          searchKey: {
            zhCN: '主页, 首页, 家, 房子, 住宅',
            enUS: 'Home, house, residential',
            zhSpell: 'zhuye, shouye, jia, fangzi, zhuzhai',
          },
          group: 'social',
        },
        {
          iconCode: 60327,
          searchKey: {
            zhCN: '主页, 首页, 家, 房子, 住宅',
            enUS: 'Home, house, residential',
            zhSpell: 'zhuye, shouye, jia, fangzi, zhuzhai',
          },
          group: 'social',
        },
        {
          iconCode: 60328,
          searchKey: {
            zhCN: '邮件, 信件, 信, 信封, 收件箱',
            enUS: 'Mail, letter, envelope, inbox',
            zhSpell: 'youjian, xinjian, xin, xinfeng, shoujianxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60329,
          searchKey: {
            zhCN: '男, 人, 联系人, 用户, 头像',
            enUS: 'Male, person, contact, user, avatar',
            zhSpell: 'nan, ren, lianxiren, yonghu, touxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60330,
          searchKey: {
            zhCN: '心, 收藏, 评价, 投票, 爱心, 喜欢',
            enUS: 'Heart, collection, evaluation, voting, love, like',
            zhSpell: 'xin, shoucang, pingjia, toupiao, aixin, xihuan',
          },
          group: 'social',
        },
        {
          iconCode: 60331,
          searchKey: {
            zhCN: '男, 人, 联系人, 用户, 头像',
            enUS: 'Male, person, contact, user, avatar',
            zhSpell: 'nan, ren, lianxiren, yonghu, touxiang',
          },
          group: 'social',
        },
        {
          iconCode: 60332,
          searchKey: {
            zhCN: '星星, 收藏, 评分, 精选, 书签',
            enUS: 'Stars, collections, ratings, featured, bookmarks',
            zhSpell: 'xingxing, shoucang, pingfen, jingxuan, shuqian',
          },
          group: 'social',
        },
        {
          iconCode: 60333,
          searchKey: {
            zhCN: '我, 人, 联系人, 用户, 头像',
            enUS: 'Me, person, contact, user, avatar',
            zhSpell: 'wo, ren, lianxiren, yonghu, touxiang',
          },
          group: 'social',
        },
      ],
    },
    {
      name: i18n('resource.icons.device'),
      id: 'device',
      items: [
        {
          iconCode: 60236,
          searchKey: {
            zhCN: '电池, 低电量, 电量, 能量, 电',
            enUS: 'Battery, low battery, power, energy, electricity',
            zhSpell: 'dianchi, didianliang, dianliang, nengliang, dian',
          },
          group: 'device',
        },
        {
          iconCode: 59368,
          searchKey: {
            zhCN: '手机信号, 网络连接, 移动数据, 通信',
            enUS: 'cell phone signal, network connection, mobile data, communication',
            zhSpell: 'shoujixinhao, wangluolianjie, yidongshuju, tongxin',
          },
          group: 'device',
        },
        {
          iconCode: 60237,
          searchKey: {
            zhCN: '亮度, 对比度, 调节, 空, 对比度调节',
            enUS: 'Brightness, contrast, adjustment, null, contrast adjustment',
            zhSpell: 'liangdu, duibidu, tiaojie, kong, duibidutiaojie',
          },
          group: 'device',
        },
        {
          iconCode: 60239,
          searchKey: {
            zhCN: '游戏机, 游戏手柄, 娱乐, 玩, 游戏',
            enUS: 'Game console, gamepad, entertainment, play, game',
            zhSpell: 'youxiji, youxishoubing, yule, wan, youxi',
          },
          group: 'device',
        },
        {
          iconCode: 60162,
          searchKey: {
            zhCN: '笔记本, 电脑, 手提电脑, 设备, Mac',
            enUS: 'Notebook, computer, laptop, device, Mac',
            zhSpell: 'bijiben, diannao, shoutidiannao, shebei, Mac',
          },
          group: 'device',
        },
        {
          iconCode: 60163,
          searchKey: {
            zhCN: '电池, 空电量, 电量, 能量, 电',
            enUS: 'Battery, empty battery, electricity, energy, electricity',
            zhSpell: 'dianchi, kongdianliang, dianliang, nengliang, dian',
          },
          group: 'device',
        },
        {
          iconCode: 60164,
          searchKey: {
            zhCN: '电池, 充电, 电量, 能量, 电',
            enUS: 'Battery, charge, battery, energy, electricity',
            zhSpell: 'dianchi, chongdian, dianliang, nengliang, dian',
          },
          group: 'device',
        },
        {
          iconCode: 60165,
          searchKey: {
            zhCN: '地球仪, 地球, 网络, 全球, 国际',
            enUS: 'Globe, earth, network, global, international',
            zhSpell: 'diqiuyi, diqiu, wangluo, quanqiu, guoji',
          },
          group: 'device',
        },
        {
          iconCode: 60166,
          searchKey: {
            zhCN: '打印机, 设备, 办公, 扫描机, 复印',
            enUS: 'Printer, equipment, office, scanner, copy',
            zhSpell: 'dayinji, shebei, bangong, saomiaoji, fuyin',
          },
          group: 'device',
        },
        {
          iconCode: 60167,
          searchKey: {
            zhCN: '电脑, 显示器, 屏幕, 设备, 桌面',
            enUS: 'Computer, monitor, screen, device, desktop',
            zhSpell: 'diannao, xianshiqi, pingmu, shebei, zhuomian',
          },
          group: 'device',
        },
        {
          iconCode: 60168,
          searchKey: {
            zhCN: '电池, 中电量, 一半, 电量, 能量, 电',
            enUS: 'Battery, medium power, half, power, energy, electricity',
            zhSpell: 'dianchi, zhongdianliang, yiban, dianliang, nengliang, dian',
          },
          group: 'device',
        },
        {
          iconCode: 60169,
          searchKey: {
            zhCN: '关闭, 关, 关机, 重启, 停止',
            enUS: 'Close, close, shut down, restart, stop',
            zhSpell: 'guanbi, guan, guanji, chongqi, tingzhi',
          },
          group: 'device',
        },
        {
          iconCode: 60170,
          searchKey: {
            zhCN: '电视, 播放, 视频, 屏幕, 播放器',
            enUS: 'TV, play, video, screen, player',
            zhSpell: 'dianshi, bofang, shipin, pingmu, bofangqi',
          },
          group: 'device',
        },
        {
          iconCode: 60171,
          searchKey: {
            zhCN: '地球仪, 地球, 全球, 互联网, 地理',
            enUS: 'Globe, earth, globe, internet, geography',
            zhSpell: 'diqiuyi, diqiu, quanqiu, hulianwang, dili',
          },
          group: 'device',
        },
        {
          iconCode: 60172,
          searchKey: {
            zhCN: '电视, 播放, 视频, 屏幕, 播放器',
            enUS: 'TV, play, video, screen, player',
            zhSpell: 'dianshi, bofang, shipin, pingmu, bofangqi',
          },
          group: 'device',
        },
        {
          iconCode: 60173,
          searchKey: {
            zhCN: '电脑, 显示器, 屏幕, 设备, 桌面',
            enUS: 'Computer, monitor, screen, device, desktop',
            zhSpell: 'diannao, xianshiqi, pingmu, shebei, zhuomian',
          },
          group: 'device',
        },
        {
          iconCode: 60174,
          searchKey: {
            zhCN: '开关, 关, 滑块, 切换, 控制',
            enUS: 'Switch, off, slider, switch, control',
            zhSpell: 'kaiguan, guan, huakuai, qiehuan, kongzhi',
          },
          group: 'device',
        },
        {
          iconCode: 60175,
          searchKey: {
            zhCN: '计算器, 计算, 会计, 数学, 运算',
            enUS: 'Calculator, calculation, accounting, math, calculation',
            zhSpell: 'jisuanqi, jisuan, huiji, shuxue, yunsuan',
          },
          group: 'device',
        },
        {
          iconCode: 60176,
          searchKey: {
            zhCN: '蓝牙, 信号, 通讯, 发送, 设备',
            enUS: 'Bluetooth, signal, communication, sending, device',
            zhSpell: 'lanya, xinhao, tongxun, fasong, shebei',
          },
          group: 'device',
        },
        {
          iconCode: 60178,
          searchKey: {
            zhCN: '开关, 开, 滑块, 切换, 控制',
            enUS: 'Switch, open, slider, switch, control',
            zhSpell: 'kaiguan, kai, huakuai, qiehuan, kongzhi',
          },
          group: 'device',
        },
        {
          iconCode: 60179,
          searchKey: {
            zhCN: '蓝牙, 连接, 信号, 传输, 设备',
            enUS: 'Bluetooth, connection, signal, transmission, device',
            zhSpell: 'lanya, lianjie, xinhao, chuanshu, shebei',
          },
          group: 'device',
        },
        {
          iconCode: 60180,
          searchKey: {
            zhCN: '闹钟, 时间, 计时器, 完成, 钟表',
            enUS: 'Alarm clock, time, timer, finish, clock',
            zhSpell: 'naozhong, shijian, jishiqi, wancheng, zhongbiao',
          },
          group: 'device',
        },
        {
          iconCode: 60181,
          searchKey: {
            zhCN: '亮度, 对比度, 调节, 一半, 对比度调节',
            enUS: 'Brightness, contrast, adjustment, half, contrast adjustment',
            zhSpell: 'liangdu, duibidu, tiaojie, yiban, duibidutiaojie',
          },
          group: 'device',
        },
        {
          iconCode: 60182,
          searchKey: {
            zhCN: '键盘, 电脑, 输入, 打字, 硬件',
            enUS: 'Keyboard, computer, input, typing, hardware',
            zhSpell: 'jianpan, diannao, shuru, dazi, yingjian',
          },
          group: 'device',
        },
        {
          iconCode: 60183,
          searchKey: {
            zhCN: '闹钟, 时间, 计时器, 关闭, 钟表',
            enUS: 'Alarm clock, time, timer, close, clock',
            zhSpell: 'naozhong, shijian, jishiqi, guanbi, zhongbiao',
          },
          group: 'device',
        },
        {
          iconCode: 60184,
          searchKey: {
            zhCN: '秒表, 计时器, 时间, 码表, 钟表',
            enUS: 'Stopwatch, timer, time, stopwatch, clock',
            zhSpell: 'miaobiao, jishiqi, shijian, mabiao, zhongbiao',
          },
          group: 'device',
        },
        {
          iconCode: 60185,
          searchKey: {
            zhCN: '闹钟, 时间, 计时器, 提醒, 钟表',
            enUS: 'Alarm clock, time, timer, reminder, clock',
            zhSpell: 'naozhong, shijian, jishiqi, tixing, zhongbiao',
          },
          group: 'device',
        },
        {
          iconCode: 60186,
          searchKey: {
            zhCN: '蓝牙, 音频, 搜索, 信号, 设备',
            enUS: 'Bluetooth, audio, search, signal, device',
            zhSpell: 'lanya, yinpin, sousuo, xinhao, shebei',
          },
          group: 'device',
        },
        {
          iconCode: 60187,
          searchKey: {
            zhCN: '闹钟, 时间, 计时器, 删除, 钟表',
            enUS: 'Alarm clock, time, timer, delete, clock',
            zhSpell: 'naozhong, shijian, jishiqi, shanchu, zhongbiao',
          },
          group: 'device',
        },
        {
          iconCode: 60188,
          searchKey: {
            zhCN: '时钟, 时间, 历史记录, 日期, 钟表',
            enUS: 'Clock, time, history, date, timepiece',
            zhSpell: 'shizhong, shijian, lishijilu, riqi, zhongbiao',
          },
          group: 'device',
        },
        {
          iconCode: 60189,
          searchKey: {
            zhCN: '时钟, 时间, 历史记录, 日期, 钟表',
            enUS: 'Clock, time, history, date, timepiece',
            zhSpell: 'shizhong, shijian, lishijilu, riqi, zhongbiao',
          },
          group: 'device',
        },
        {
          iconCode: 60190,
          searchKey: {
            zhCN: '闪光, 开启, 闪电, 电力, 雷电',
            enUS: 'Flash, turn on, lightning, electricity, lightning',
            zhSpell: 'shanguang, kaiqi, shandian, dianli, leidian',
          },
          group: 'device',
        },
        {
          iconCode: 60191,
          searchKey: {
            zhCN: '收音机, 收听, 电台, 录音机, 设备',
            enUS: 'Radio, listening, radio, recorder, equipment',
            zhSpell: 'shouyinji, shouting, diantai, luyinji, shebei',
          },
          group: 'device',
        },
        {
          iconCode: 60192,
          searchKey: {
            zhCN: '闪光, 开启, 闪电, 电力, 雷电',
            enUS: 'Flash, turn on, lightning, electricity, lightning',
            zhSpell: 'shanguang, kaiqi, shandian, dianli, leidian',
          },
          group: 'device',
        },
        {
          iconCode: 60193,
          searchKey: {
            zhCN: '设置, 齿轮, 偏好, 选项, 工具',
            enUS: 'Settings, gears, preferences, options, tools',
            zhSpell: 'shezhi, chilun, pianhao, xuanxiang, gongju',
          },
          group: 'device',
        },
        {
          iconCode: 60194,
          searchKey: {
            zhCN: '收音机, 收听, 电台, 录音机, 设备',
            enUS: 'Radio, listening, radio, recorder, equipment',
            zhSpell: 'shouyinji, shouting, diantai, luyinji, shebei',
          },
          group: 'device',
        },
        {
          iconCode: 60195,
          searchKey: {
            zhCN: '亮度, 对比度, 调节, 满, 对比度调节',
            enUS: 'Brightness, contrast, adjustment, full, contrast adjustment',
            zhSpell: 'liangdu, duibidu, tiaojie, man, duibidutiaojie',
          },
          group: 'device',
        },
        {
          iconCode: 60196,
          searchKey: {
            zhCN: '闹钟, 时间, 计时器, 添加, 钟表',
            enUS: 'Alarm clock, time, timer, add, clock',
            zhSpell: 'naozhong, shijian, jishiqi, tianjia, zhongbiao',
          },
          group: 'device',
        },
        {
          iconCode: 60197,
          searchKey: {
            zhCN: '闹钟, 时间, 计时器, 提醒, 钟表',
            enUS: 'Alarm clock, time, timer, reminder, clock',
            zhSpell: 'naozhong, shijian, jishiqi, tixing, zhongbiao',
          },
          group: 'device',
        },
        {
          iconCode: 60198,
          searchKey: {
            zhCN: '电池, 满电量, 电量, 能量, 电',
            enUS: 'Battery, full charge, power, energy, electricity',
            zhSpell: 'dianchi, mandianliang, dianliang, nengliang, dian',
          },
          group: 'device',
        },
        {
          iconCode: 60199,
          searchKey: {
            zhCN: '电视, 电视机, 视频, 屏幕, 播放器',
            enUS: 'TV, TV, video, screen, player',
            zhSpell: 'dianshi, dianshiji, shipin, pingmu, bofangqi',
          },
          group: 'device',
        },
        {
          iconCode: 60200,
          searchKey: {
            zhCN: '闪光, 关闭, 闪电, 电力, 雷电',
            enUS: 'Flash, off, lightning, electricity, lightning',
            zhSpell: 'shanguang, guanbi, shandian, dianli, leidian',
          },
          group: 'device',
        },
        {
          iconCode: 60201,
          searchKey: {
            zhCN: '蓝牙, 关闭, 信号, 通讯, 设备',
            enUS: 'Bluetooth, off, signal, communication, device',
            zhSpell: 'lanya, guanbi, xinhao, tongxun, shebei',
          },
          group: 'device',
        },
        {
          iconCode: 60202,
          searchKey: {
            zhCN: '闹钟, 时间, 计时器, 提醒, 钟表',
            enUS: 'Alarm clock, time, timer, reminder, clock',
            zhSpell: 'naozhong, shijian, jishiqi, tixing, zhongbiao',
          },
          group: 'device',
        },
        {
          iconCode: 60203,
          searchKey: {
            zhCN: '手机, 电话, 设备, 智能手机, 竖屏',
            enUS: 'Cell phone, phone, device, smartphone, vertical screen',
            zhSpell: 'shouji, dianhua, shebei, zhinenshouji, shuping',
          },
          group: 'device',
        },
        {
          iconCode: 60204,
          searchKey: {
            zhCN: '鼠标, 点击, 电脑, 工具, 设备',
            enUS: 'Mouse, click, computer, tool, device',
            zhSpell: 'shubiao, dianji, diannao, gongju, shebei',
          },
          group: 'device',
        },
        {
          iconCode: 60205,
          searchKey: {
            zhCN: '有线鼠标, 点击, 电脑, 工具, 设备',
            enUS: 'Wired mouse, click, computer, tool, device',
            zhSpell: 'youxianshubiao, dianji, diannao, gongju, shebei',
          },
          group: 'device',
        },
        {
          iconCode: 60206,
          searchKey: {
            zhCN: '相机, 照相, 摄影机, 录像机, 照片, 拍照',
            enUS: 'Camera, camera, camera, video recorder, photo, taking phone',
            zhSpell: 'xiangji, zhaoxiang, sheyingji, luxiangji, zhaopian, paizhao',
          },
          group: 'device',
        },
        {
          iconCode: 60207,
          searchKey: {
            zhCN: '相机, 照相, 摄影机, 录像机, 照片, 拍照',
            enUS: 'Camera, camera, camera, video recorder, photo, taking phone',
            zhSpell: 'xiangji, zhaoxiang, sheyingji, luxiangji, zhaopian, paizhao',
          },
          group: 'device',
        },
        {
          iconCode: 60208,
          searchKey: {
            zhCN: '网页, 浏览器, 窗口, 网络, 互联网',
            enUS: 'Web, browser, window, network, internet',
            zhSpell: 'wangye, liulanqi, chuangkou, wangluo, hulianwang',
          },
          group: 'device',
        },
        {
          iconCode: 60209,
          searchKey: {
            zhCN: '投影仪, 演示, 演讲, 屏幕, 课程',
            enUS: 'Projector, presentation, presentation, screen, course',
            zhSpell: 'touyingyi, yanshi, yanjiang, pingmu, kecheng',
          },
          group: 'device',
        },
        {
          iconCode: 60210,
          searchKey: {
            zhCN: '相机, 切换, 照相, 摄影机, 录像机',
            enUS: 'Camera, switch, camera, camera, video recorder',
            zhSpell: 'xiangji, qiehuan, zhaoxiang, sheyingji, luxiangji',
          },
          group: 'device',
        },
        {
          iconCode: 60211,
          searchKey: {
            zhCN: '相机, 关闭, 照相, 摄影机, 录像机',
            enUS: 'Camera, off, camera, camera, video recorder',
            zhSpell: 'xiangji, guanbi, zhaoxiang, sheyingji, luxiangji',
          },
          group: 'device',
        },
        {
          iconCode: 60212,
          searchKey: {
            zhCN: '游戏机, 游戏手柄, 娱乐, 玩, 游戏',
            enUS: 'Game console, gamepad, entertainment, play, game',
            zhSpell: 'youxiji, youxishoubing, yule, wan, youxi',
          },
          group: 'device',
        },
        {
          iconCode: 60213,
          searchKey: {
            zhCN: '云, 天气, 云数据, 云存储, 软件',
            enUS: 'Cloud, weather, cloud data, cloud storage, software',
            zhSpell: 'yun, tianqi, yunshuju, yuncunchu, ruanjian',
          },
          group: 'device',
        },
        {
          iconCode: 60214,
          searchKey: {
            zhCN: '云, 关闭, 云数据, 云存储, 软件',
            enUS: 'Cloud, shutdown, cloud data, cloud storage, software',
            zhSpell: 'yun, guanbi, yunshuju, yuncunchu, ruanjian',
          },
          group: 'device',
        },
        {
          iconCode: 60215,
          searchKey: {
            zhCN: '云, 上传, 云数据, 云存储, 软件',
            enUS: 'Cloud, upload, cloud data, cloud storage, software',
            zhSpell: 'yun, shangchuan, yunshuju, yuncunchu, ruanjian',
          },
          group: 'device',
        },
        {
          iconCode: 60216,
          searchKey: {
            zhCN: '游戏机, 游戏, 娱乐, 玩, 手游, 电玩',
            enUS: 'Game consoles, games, entertainment, play, mobile games, video games',
            zhSpell: 'youxiji, youxi, yule, wan, shouyou, dianwan',
          },
          group: 'device',
        },
        {
          iconCode: 60217,
          searchKey: {
            zhCN: '云, 天气, 云数据, 云存储, 软件',
            enUS: 'Cloud, weather, cloud data, cloud storage, software',
            zhSpell: 'yun, tianqi, yunshuju, yuncunchu, ruanjian',
          },
          group: 'device',
        },
        {
          iconCode: 60218,
          searchKey: {
            zhCN: '云, 下载, 云数据, 云存储, 软件',
            enUS: 'Cloud, download, cloud data, cloud storage, software',
            zhSpell: 'yun, xiazai, yunshuju, yuncunchu, ruanjian',
          },
          group: 'device',
        },
        {
          iconCode: 60219,
          searchKey: {
            zhCN: '游戏机, 游戏手柄, 娱乐, 玩, 游戏',
            enUS: 'Game console, gamepad, entertainment, play, game',
            zhSpell: 'youxiji, youxishoubing, yule, wan, youxi',
          },
          group: 'device',
        },
        {
          iconCode: 60220,
          searchKey: {
            zhCN: '云, 搜索, 云数据, 云存储, 软件, 查询, 查找',
            enUS: 'Cloud, search, cloud data, cloud storage, software, query, lookup',
            zhSpell: 'yun, sousuo, yunshuju, yuncunchu, ruanjian, chaxun, chazhao',
          },
          group: 'device',
        },
        {
          iconCode: 60221,
          searchKey: {
            zhCN: '云, 上传, 云数据, 云存储, 软件',
            enUS: 'Cloud, upload, cloud data, cloud storage, software',
            zhSpell: 'yun, shangchuan, yunshuju, yuncunchu, ruanjian',
          },
          group: 'device',
        },
        {
          iconCode: 60222,
          searchKey: {
            zhCN: '云, 下载, 云数据, 云存储, 软件',
            enUS: 'Cloud, download, cloud data, cloud storage, software',
            zhSpell: 'yun, xiazai, yunshuju, yuncunchu, ruanjian',
          },
          group: 'device',
        },
        {
          iconCode: 60223,
          searchKey: {
            zhCN: '云, 天气, 云数据, 云存储, 软件',
            enUS: 'Cloud, weather, cloud data, cloud storage, software',
            zhSpell: 'yun, tianqi, yunshuju, yuncunchu, ruanjian',
          },
          group: 'device',
        },
        {
          iconCode: 60224,
          searchKey: {
            zhCN: '云, 下载, 云数据, 云存储, 软件',
            enUS: 'Cloud, download, cloud data, cloud storage, software',
            zhSpell: 'yun, xiazai, yunshuju, yuncunchu, ruanjian',
          },
          group: 'device',
        },
        {
          iconCode: 60225,
          searchKey: {
            zhCN: '云, 数据传输, 云数据, 云存储, 软件, 数据交换',
            enUS: 'Cloud, data transfer, cloud data, cloud storage, software, data exchange',
            zhSpell: 'yun, shujuchuanshu, yunshuju, yuncunchu, ruanjian, shujujiaohuan',
          },
          group: 'device',
        },
        {
          iconCode: 60226,
          searchKey: {
            zhCN: '数据线, 连接器, 通串线, USB接口, 数据传输',
            enUS: 'Data cable, connector, serial cable, USB interface, data transmission',
            zhSpell: 'shujuxian, lianjieqi, tongchuanxian, USBjiekou, shujuchuanshu',
          },
          group: 'device',
        },
        {
          iconCode: 60227,
          searchKey: {
            zhCN: '云, 上传, 云数据, 云存储, 软件',
            enUS: 'Cloud, upload, cloud data, cloud storage, software',
            zhSpell: 'yun, shangchuan, yunshuju, yuncunchu, ruanjian',
          },
          group: 'device',
        },
        {
          iconCode: 60228,
          searchKey: {
            zhCN: '云, 天气, 云数据, 云存储, 软件',
            enUS: 'Cloud, weather, cloud data, cloud storage, software',
            zhSpell: 'yun, tianqi, yunshuju, yuncunchu, ruanjian',
          },
          group: 'device',
        },
        {
          iconCode: 60229,
          searchKey: {
            zhCN: '云, 下载完毕, 云数据, 云存储, 软件',
            enUS: 'Cloud, download completed, cloud data, cloud storage, software',
            zhSpell: 'yun, xiazaiwanbi, yunshuju, yuncunchu, ruanjian',
          },
          group: 'device',
        },
        {
          iconCode: 60230,
          searchKey: {
            zhCN: '办公用品, USB, U盘, 存储, 存储卡',
            enUS: 'Office supplies, USB, USB flash drive, storage, memory card',
            zhSpell: 'bangongyongpin, USB, Upan, cunchu, cunchuka',
          },
          group: 'device',
        },
        {
          iconCode: 60231,
          searchKey: {
            zhCN: '智能优化, 联络, 网络, 形状, 智能',
            enUS: 'Intelligent optimization, contact, network, shape, intelligence',
            zhSpell: 'zhinenyouhua, lianluo, wangluo, xingzhuang, zhinen',
          },
          group: 'device',
        },
        {
          iconCode: 60232,
          searchKey: {
            zhCN: '处理器, 方块, 硬件, 芯片, CPU, 记忆, 中心',
            enUS: 'Processor, block, hardware, chip, CPU, memory, center',
            zhSpell: 'chuliqi, fangkuai, yingjian, xinpian, CPU, jiyi, zhongxin',
          },
          group: 'device',
        },
        {
          iconCode: 60233,
          searchKey: {
            zhCN: 'Wifi, 无线, 上网, 网络信号, 局域网',
            enUS: 'Wifi, wireless, internet, network signal, LAN',
            zhSpell: 'Wifi, wuxian, shangwang, wangluoxinhao, juyuwang',
          },
          group: 'device',
        },
        {
          iconCode: 59367,
          searchKey: {
            zhCN: 'Wifi, 无线, 上网, 网络信号, 局域网',
            enUS: 'Wifi, wireless, internet, network signal, LAN',
            zhSpell: 'Wifi, wuxian, shangwang, wangluoxinhao, juyuwang',
          },
          group: 'device',
        },
        {
          iconCode: 60234,
          searchKey: {
            zhCN: '云, 下载完毕, 云数据, 云存储, 软件',
            enUS: 'Cloud, download completed, cloud data, cloud storage, software',
            zhSpell: 'yun, xiazaiwanbi, yunshuju, yuncunchu, ruanjian',
          },
          group: 'device',
        },
        {
          iconCode: 60235,
          searchKey: {
            zhCN: '无线波, 无线电, 通讯, 无线上网, 电波, 信号',
            enUS: 'Wireless wave, radio, communication, wireless internet, radio wave, signal',
            zhSpell: 'wuxianbo, wuxiandian, tongxun, wuxianshangwang, dianbo, xinhao',
          },
          group: 'device',
        },
        {
          iconCode: 59369,
          searchKey: {
            zhCN: '小程序, 微信, 更多, 设置, 圆点',
            enUS: 'Mini program, wechat, more, setting, dot',
            zhSpell: 'xiaochengxu, weixin, gengduo, shezhi, yuandian',
          },
          group: 'device',
        },
        {
          iconCode: 59370,
          searchKey: {
            zhCN: '小程序, 关闭, 微信, 圆点',
            enUS: 'Mini program, close, wechat, dot',
            zhSpell: 'xiaochengxu, guanbi, weixin, yuandian',
          },
          group: 'device',
        },
      ],
    },
    {
      name: i18n('resource.icons.traffic'),
      id: 'traffic',
      items: [
        {
          iconCode: 60120,
          searchKey: {
            zhCN: '地点, 瞄准, 导航, 位置, 目的地',
            enUS: 'Location, aiming, navigation, location, destination',
            zhSpell: 'didian, miaozhun, daohang, weizhi, mudedi',
          },
          group: 'traffic',
        },
        {
          iconCode: 60121,
          searchKey: {
            zhCN: '地点, 关闭, 导航, 位置, 目的地',
            enUS: 'Location, close, navigation, location, destination',
            zhSpell: 'didian, guanbi, daohang, weizhi, mudedi',
          },
          group: 'traffic',
        },
        {
          iconCode: 60122,
          searchKey: {
            zhCN: '地图, 位置, 方向, 定位, 导航',
            enUS: 'Map, location, direction, location, navigation',
            zhSpell: 'ditu, weizhi, fangxiang, dingwei, daohang',
          },
          group: 'traffic',
        },
        {
          iconCode: 60123,
          searchKey: {
            zhCN: '地图, 位置, 方向, 定位, 导航',
            enUS: 'Map, location, direction, location, navigation',
            zhSpell: 'ditu, weizhi, fangxiang, dingwei, daohang',
          },
          group: 'traffic',
        },
        {
          iconCode: 60124,
          searchKey: {
            zhCN: '地图, 位置, 方向, 定位, 导航',
            enUS: 'Map, location, direction, location, navigation',
            zhSpell: 'ditu, weizhi, fangxiang, dingwei, daohang',
          },
          group: 'traffic',
        },
        {
          iconCode: 60125,
          searchKey: {
            zhCN: '地图, 位置, 方向, 定位, 导航',
            enUS: 'Map, location, direction, location, navigation',
            zhSpell: 'ditu, weizhi, fangxiang, dingwei, daohang',
          },
          group: 'traffic',
        },
        {
          iconCode: 60126,
          searchKey: {
            zhCN: '定位, 位置, 地点, 陆标, 定位针',
            enUS: 'Positioning, location, location, landmark, positioning pin',
            zhSpell: 'dingwei, weizhi, didian, lubiao, dingweizhen',
          },
          group: 'traffic',
        },
        {
          iconCode: 60127,
          searchKey: {
            zhCN: '定位, 位置, 地点, 陆标, 定位针',
            enUS: 'Positioning, location, location, landmark, positioning pin',
            zhSpell: 'dingwei, weizhi, didian, lubiao, dingweizhen',
          },
          group: 'traffic',
        },
        {
          iconCode: 60128,
          searchKey: {
            zhCN: '定位, 位置, 地点, 陆标, 定位针',
            enUS: 'Positioning, location, location, landmark, positioning pin',
            zhSpell: 'dingwei, weizhi, didian, lubiao, dingweizhen',
          },
          group: 'traffic',
        },
        {
          iconCode: 60129,
          searchKey: {
            zhCN: '定位, 位置, 地点, 陆标, 定位针',
            enUS: 'Positioning, location, location, landmark, positioning pin',
            zhSpell: 'dingwei, weizhi, didian, lubiao, dingweizhen',
          },
          group: 'traffic',
        },
        {
          iconCode: 60130,
          searchKey: {
            zhCN: '定位, 位置, 方向, 导航, 指针',
            enUS: 'Positioning, position, direction, navigation, pointer',
            zhSpell: 'dingwei, weizhi, fangxiang, daohang, zhizhen',
          },
          group: 'traffic',
        },
        {
          iconCode: 60131,
          searchKey: {
            zhCN: '定位, 位置, 地点, 地图, 定位针',
            enUS: 'Positioning, location, location, map, positioning pin',
            zhSpell: 'dingwei, weizhi, didian, ditu, dingweizhen',
          },
          group: 'traffic',
        },
        {
          iconCode: 60132,
          searchKey: {
            zhCN: '定位, 位置, 地点, 地图, 定位针',
            enUS: 'Positioning, location, location, map, positioning pin',
            zhSpell: 'dingwei, weizhi, didian, ditu, dingweizhen',
          },
          group: 'traffic',
        },
        {
          iconCode: 60133,
          searchKey: {
            zhCN: '定位, 位置, 指针, 方向, 导航',
            enUS: 'Positioning, position, pointer, direction, navigation',
            zhSpell: 'dingwei, weizhi, zhizhen, fangxiang, daohang',
          },
          group: 'traffic',
        },
        {
          iconCode: 60134,
          searchKey: {
            zhCN: '飞机, 飞行模式, 交通工具, 航班, 旅行',
            enUS: 'Aircraft, airplane mode, transportation, flight, travel',
            zhSpell: 'feiji, feixingmoshi, jiaotonggongju, hangban, lvxing',
          },
          group: 'traffic',
        },
        {
          iconCode: 60135,
          searchKey: {
            zhCN: '飞机, 飞行模式, 交通工具, 航班, 旅行',
            enUS: 'Aircraft, airplane mode, transportation, flight, travel',
            zhSpell: 'feiji, feixingmoshi, jiaotonggongju, hangban, lvxing',
          },
          group: 'traffic',
        },
        {
          iconCode: 60136,
          searchKey: {
            zhCN: '飞机, 飞行模式, 交通工具, 航班, 旅行',
            enUS: 'Aircraft,  airplane mode, transportation, flight, travel',
            zhSpell: 'feiji, feixingmoshi, jiaotonggongju, hangban, lvxing',
          },
          group: 'traffic',
        },
        {
          iconCode: 60137,
          searchKey: {
            zhCN: '火车, 电车, 动车, 高铁, 轻轨, 铁路, 交通工具',
            enUS: 'Train, tram, motor train, high-speed rail, light rail, railway, transportation',
            zhSpell: 'huoche, dianche, dongche, gaotie, qinggui, tielu, jiaotonggongju',
          },
          group: 'traffic',
        },
        {
          iconCode: 60138,
          searchKey: {
            zhCN: '飞机, 飞行模式, 交通工具, 航班, 旅行',
            enUS: 'Aircraft,  airplane mode, transportation, flight, travel',
            zhSpell: 'feiji, feixingmoshi, jiaotonggongju, hangban, lvxing',
          },
          group: 'traffic',
        },
        {
          iconCode: 60139,
          searchKey: {
            zhCN: '火箭, 航空, 发射, 太空, 加速, 快速',
            enUS: 'Rocket, aviation, launch, space, acceleration, fast',
            zhSpell: 'huojian, hangkong, fashe, taikong, jiasu, kuaisu',
          },
          group: 'traffic',
        },
        {
          iconCode: 60140,
          searchKey: {
            zhCN: '火箭, 航空, 发射, 太空, 加速, 快速',
            enUS: 'Rocket, aviation, launch, space, acceleration, fast',
            zhSpell: 'huojian, hangkong, fashe, taikong, jiasu, kuaisu',
          },
          group: 'traffic',
        },
        {
          iconCode: 60141,
          searchKey: {
            zhCN: '火箭, 航空, 发射, 太空, 加速, 快速',
            enUS: 'Rocket, aviation, launch, space, acceleration, fast',
            zhSpell: 'huojian, hangkong, fashe, taikong, jiasu, kuaisu',
          },
          group: 'traffic',
        },
        {
          iconCode: 60142,
          searchKey: {
            zhCN: '火箭, 航空, 发射, 太空, 加速, 快速',
            enUS: 'Rocket, aviation, launch, space, acceleration, fast',
            zhSpell: 'huojian, hangkong, fashe, taikong, jiasu, kuaisu',
          },
          group: 'traffic',
        },
        {
          iconCode: 60143,
          searchKey: {
            zhCN: '轿车, 小车, 出租车, 交通工具, 出行',
            enUS: 'Car, car, taxi, transportation, travel',
            zhSpell: 'jiaoche, xiaoche, chuzuche, jiaotonggongju, chuxing',
          },
          group: 'traffic',
        },
        {
          iconCode: 60144,
          searchKey: {
            zhCN: '轿车, 小车, 出租车, 私家车, 交通工具, 出行',
            enUS: 'Cars, cars, taxis, private car, transportation, travel',
            zhSpell: 'jiaoche, xiaoche, chuzuche, sijiache, jiaotonggongju, chuxing',
          },
          group: 'traffic',
        },
        {
          iconCode: 60145,
          searchKey: {
            zhCN: '轿车, 小车, 出租车, 交通工具, 出行',
            enUS: 'Car, car, taxi, transportation, travel',
            zhSpell: 'jiaoche, xiaoche, chuzuche, jiaotonggongju, chuxing',
          },
          group: 'traffic',
        },
        {
          iconCode: 60146,
          searchKey: {
            zhCN: '轿车, 小车, 出租车, 交通工具, 出行',
            enUS: 'Car, car, taxi, transportation, travel',
            zhSpell: 'jiaoche, xiaoche, chuzuche, jiaotonggongju, chuxing',
          },
          group: 'traffic',
        },
        {
          iconCode: 60147,
          searchKey: {
            zhCN: '路灯, 交通, 道路, 灯, 照亮',
            enUS: 'Street light, traffic, road, light, illuminate',
            zhSpell: 'ludeng, jiaotong, daolu, deng, zhaoliang',
          },
          group: 'traffic',
        },
        {
          iconCode: 60148,
          searchKey: {
            zhCN: '路灯, 交通, 道路, 灯, 照亮',
            enUS: 'Street light, traffic, road, light, illuminate',
            zhSpell: 'ludeng, jiaotong, daolu, deng, zhaoliang',
          },
          group: 'traffic',
        },
        {
          iconCode: 60149,
          searchKey: {
            zhCN: '路径, 公路, 道路, 交通, 人行道',
            enUS: 'Path, road, road, traffic, sidewalk',
            zhSpell: 'lujing, gonglu, daolu, jiaotong, renxingdao',
          },
          group: 'traffic',
        },
        {
          iconCode: 60150,
          searchKey: {
            zhCN: '路障, 障碍, 交通, 故障, 阻挡',
            enUS: 'Roadblocks, obstacles, traffic, failure, blocking',
            zhSpell: 'luzhang, zhangai, jiaotong, guzhang, zudang',
          },
          group: 'traffic',
        },
        {
          iconCode: 60151,
          searchKey: {
            zhCN: '轮船, 游艇, 巡航, 游轮, 海波, 海上, 交通工具',
            enUS: 'Ship, yacht, cruise, cruise ship, sea wave, sea, transportation',
            zhSpell: 'lunchuan, youting, xunhang, youlun, haibo, haishang, jiaotonggongju',
          },
          group: 'traffic',
        },
        {
          iconCode: 60152,
          searchKey: {
            zhCN: '锚, 码头, 海军, 海事, 停泊, 停靠',
            enUS: 'Anchor, dock, navy, maritime, mooring, docking',
            zhSpell: 'mao, matou, haijun, haishi, tingbo, tingkao',
          },
          group: 'traffic',
        },
        {
          iconCode: 60153,
          searchKey: {
            zhCN: '骑车, 骑车的人, 自行车, 共享单车, 摩托车, 非机动车, 交通工具',
            enUS: 'Cycling, bicyclists, bicycles, bike sharing, motorcycles, non-motor vehicles, vehicles',
            zhSpell: 'qiche, qichederen, zixingche, gongxiangdanche, motuoche, feijidongche, jiaotonggongju',
          },
          group: 'traffic',
        },
        {
          iconCode: 60154,
          searchKey: {
            zhCN: '汽车, 货车, 物流, 运输, 交通工具',
            enUS: 'Cars, trucks, logistics, transportation, transportation',
            zhSpell: 'qiche, huoche, wuliu, yunshu, jiaotonggongju',
          },
          group: 'traffic',
        },
        {
          iconCode: 60155,
          searchKey: {
            zhCN: '汽车, 货车, 物流, 运输, 交通工具',
            enUS: 'Cars, trucks, logistics, transportation, transportation',
            zhSpell: 'qiche, huoche, wuliu, yunshu, jiaotonggongju',
          },
          group: 'traffic',
        },
        {
          iconCode: 60156,
          searchKey: {
            zhCN: '铁路, 地铁, 高铁, 轨道, 交通',
            enUS: 'Railway, subway, high-speed rail, track, traffic',
            zhSpell: 'tielu, ditie, gaotie, guidao, jiaotong',
          },
          group: 'traffic',
        },
        {
          iconCode: 60157,
          searchKey: {
            zhCN: '信号灯, 交通灯, 红绿灯, 交通, 道路',
            enUS: 'Signal lights, traffic lights, traffic lights, traffic, roads',
            zhSpell: 'xinhaodeng, jiaotongdeng, honglvdeng, jiaotong, daolu',
          },
          group: 'traffic',
        },
        {
          iconCode: 60158,
          searchKey: {
            zhCN: '自行车, 共享单车,脚踏车, 交通工具, 骑车, 车辆',
            enUS: 'Bicycle, bike sharing, bicycle, vehicle, bicycle, vehicle',
            zhSpell: 'zixingche, gongxiangdanche, jiaotache, jiaotonggongju, qiche, cheliang',
          },
          group: 'traffic',
        },
        {
          iconCode: 60159,
          searchKey: {
            zhCN: '信号灯, 交通灯, 红绿灯, 交通, 道路',
            enUS: 'Signal lights, traffic lights, traffic lights, traffic, roads',
            zhSpell: 'xinhaodeng, jiaotongdeng, honglvdeng, jiaotong, daolu',
          },
          group: 'traffic',
        },
        {
          iconCode: 60160,
          searchKey: {
            zhCN: '自行车, 脚踏车, 共享单车, 交通工具, 骑车, 车辆',
            enUS: 'Bicycle, bicycle, bike sharing, vehicle, bicycle, vehicle',
            zhSpell: 'zixingche, jiaotache, gongxiangdanche, jiaotonggongju, qiche, cheliang',
          },
          group: 'traffic',
        },
      ],
    },
    {
      name: i18n('resource.icons.symbol'),
      id: 'symbol',
      items: [
        {
          iconCode: 60007,
          searchKey: {
            zhCN: '标点, 星号, 标记, 注释, 偏好',
            enUS: 'Punctuation, asterisk, mark, comment, preference',
            zhSpell: 'biaodian, xinghao, biaoji, zhushi, pianhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60008,
          searchKey: {
            zhCN: '标点, @, 社交, 联系人, 符号, 邮件',
            enUS: 'Punctuation, @, social, contact, symbol, mail',
            zhSpell: 'biaodian, @, shejiao, lianxiren, fuhao, youjian',
          },
          group: 'symbol',
        },
        {
          iconCode: 60009,
          searchKey: {
            zhCN: '标点, 百分号, 百分比, 符号, 数据, 数学',
            enUS: 'Punctuation, percent sign, symbol, data, mathematics',
            zhSpell: 'biaodian, baifenhao, baifenbi, fuhao, shuju, shuxue',
          },
          group: 'symbol',
        },
        {
          iconCode: 60010,
          searchKey: {
            zhCN: '标点, 大括号, 符号, 括号, 引用',
            enUS: 'Punctuation, braces, symbols, brackets, references',
            zhSpell: 'biaodian, dakuohao, fuhao, kuohao, yinyong',
          },
          group: 'symbol',
        },
        {
          iconCode: 59386,
          searchKey: {
            zhCN: '标点，大括号，大左括号，符号，括号，引用',
            enUS: 'Punctuation, brace, brace left, symbol, bracket, reference',
            zhSpell: 'biaodian, dakuohao, dazuokuohao, fuhao, kuohao, yinyong',
          },
          group: 'symbol',
        },
        {
          iconCode: 59382,
          searchKey: {
            zhCN: '标点，大括号，大右括号，符号，括号，引用',
            enUS: 'Punctuation, braces, closing braces，symbols, parentheses, references',
            zhSpell: 'biaodian, dakuohao, dayoukuohao, fuhao, kuohao, yinyong',
          },
          group: 'symbol',
        },
        {
          iconCode: 60011,
          searchKey: {
            zhCN: '标点, 井号, 标签, 关键词, 编号',
            enUS: 'Punctuation, hash, label, keyword, number',
            zhSpell: 'biaodian, jinghao, biaoqian, guanjianci, bianhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60012,
          searchKey: {
            zhCN: '标点, 感叹号, 小心, 提示, 注意, 提醒',
            enUS: 'Punctuation, exclamation point, caution, reminder, attention, reminder',
            zhSpell: 'biaodian, gantanhao, xiaoxin, tishi, zhuyi, tixing',
          },
          group: 'symbol',
        },
        {
          iconCode: 60013,
          searchKey: {
            zhCN: '标点, 括号, 括号, 引用, 补充',
            enUS: 'Punctuation, brackets, brackets, references, supplements',
            zhSpell: 'biaodian, kuohao, kuohao, yinyong, buchong',
          },
          group: 'symbol',
        },
        {
          iconCode: 59385,
          searchKey: {
            zhCN: '标点，括号，左括号，引用，补充',
            enUS: 'Punctuation, parenthesis, left parenthesis, reference, supplement',
            zhSpell: 'biaodian, kuohao, zuokuohao, yinyong, buchong',
          },
          group: 'symbol',
        },
        {
          iconCode: 59383,
          searchKey: {
            zhCN: '标点，括号，右括号，引用，补充',
            enUS: 'Punctuation, parenthesis, right parenthesis, reference, supplement',
            zhSpell: 'biaodian, kuohao, youkuohao, yinyong, buchong',
          },
          group: 'symbol',
        },
        {
          iconCode: 60014,
          searchKey: {
            zhCN: '标点, 引号, 符号, 双引号, 引用',
            enUS: 'Punctuation, quotes, symbols, double quotes, references',
            zhSpell: 'biaodian, yinhao, fuhao, shuangyinhao, yinyong',
          },
          group: 'symbol',
        },
        {
          iconCode: 60015,
          searchKey: {
            zhCN: '标点, 问号, 疑问, 帮助, 咨询, 问题',
            enUS: 'Punctuation, question mark, question, help, consultation, question',
            zhSpell: 'biaodian, wenhao, yiwen, bangzhu, zixun, wenti',
          },
          group: 'symbol',
        },
        {
          iconCode: 60016,
          searchKey: {
            zhCN: '标点, 反引号, 符号, 双引号, 引用',
            enUS: 'Punctuation, backquotes, symbols, double quotes, references',
            zhSpell: 'biaodian, fanyinhao, fuhao, shuangyinhao, yinyong',
          },
          group: 'symbol',
        },
        {
          iconCode: 60017,
          searchKey: {
            zhCN: '标点, 反引号, 符号, 双引号, 引用',
            enUS: 'Punctuation, backquotes, symbols, double quotes, references',
            zhSpell: 'biaodian, fanyinhao, fuhao, shuangyinhao, yinyong',
          },
          group: 'symbol',
        },
        {
          iconCode: 60018,
          searchKey: {
            zhCN: '标点, 引号, 符号, 双引号, 引用',
            enUS: 'Punctuation, quotes, symbols, double quotes, references',
            zhSpell: 'biaodian, yinhao, fuhao, shuangyinhao, yinyong',
          },
          group: 'symbol',
        },
        {
          iconCode: 60019,
          searchKey: {
            zhCN: '标点, 引号, 符号, 双引号, 引用',
            enUS: 'Punctuation, quotes, symbols, double quotes, references',
            zhSpell: 'biaodian, yinhao, fuhao, shuangyinhao, yinyong',
          },
          group: 'symbol',
        },
        {
          iconCode: 60020,
          searchKey: {
            zhCN: '标点, 中括号, 符号, 括号, 引用',
            enUS: 'Punctuation, brackets, symbols, brackets, references',
            zhSpell: 'biaodian, zhongkuohao, fuhao, kuohao, yinyong',
          },
          group: 'symbol',
        },
        {
          iconCode: 59384,
          searchKey: {
            zhCN: '标点，符号，中括号，中左括号，括号，引用',
            enUS: 'Punctuation, symbol, bracket, middle left bracket, bracket, reference',
            zhSpell: 'biaodian, fuhao, zhongkuohao, zhongzuokuohao, kuohao, yinyong',
          },
          group: 'symbol',
        },
        {
          iconCode: 59388,
          searchKey: {
            zhCN: '标点，符号，中括号，中右括号，括号，引用',
            enUS: 'Punctuation, symbol, bracket,middle right bracket, bracket, reference',
            zhSpell: 'biaodian, fuhao, zhongkuohao, zhongyoukuohao, kuohao, yinyong',
          },
          group: 'symbol',
        },
        {
          iconCode: 60021,
          searchKey: {
            zhCN: '标点, 反引号, 符号, 双引号, 引用',
            enUS: 'Punctuation, backquotes, symbols, double quotes, references',
            zhSpell: 'biaodian, fanyinhao, fuhao, shuangyinhao, yinyong',
          },
          group: 'symbol',
        },
        {
          iconCode: 60022,
          searchKey: {
            zhCN: '标点, 符号, 问号, 星号, 点, 集合',
            enUS: 'Punctuation, symbol, question mark, asterisk, point, collection',
            zhSpell: 'biaodian, fuhao, wenhao, xinghao, dian, jihe',
          },
          group: 'symbol',
        },
        {
          iconCode: 60023,
          searchKey: {
            zhCN: '标点, 破折号, 符号, 括号, 引用',
            enUS: 'Punctuation, dashes, symbols, brackets, references',
            zhSpell: 'biaodian, pozhehao, fuhao, kuohao, yinyong',
          },
          group: 'symbol',
        },
        {
          iconCode: 59389,
          searchKey: {
            zhCN: '标点，破折号，左破折号，符号，括号，引用',
            enUS: 'Punctuation, dash, left dash, symbol, bracket, reference',
            zhSpell: 'biaodian, pozhehao, zuopozhehao, fuhao, kuohao, yinyong',
          },
          group: 'symbol',
        },
        {
          iconCode: 59387,
          searchKey: {
            zhCN: '标点，破折号，右破折号，符号，括号，引用',
            enUS: 'Punctuation, dash, right dash, symbol, bracket, reference',
            zhSpell: 'biaodian, pozhehao, youpozhehao, fuhao, kuohao, yinyong',
          },
          group: 'symbol',
        },
        {
          iconCode: 60024,
          searchKey: {
            zhCN: '程序, 括号, 更多, 点, 破折号',
            enUS: 'Program, brackets, more, points, dashes',
            zhSpell: 'chengxu, kuohao, gengduo, dian, pozhehao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60025,
          searchKey: {
            zhCN: '程序, 括号, 破折号, 代码, 符号',
            enUS: 'Program, brackets, dash, code, symbol',
            zhSpell: 'chengxu, kuohao, pozhehao, daima, fuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60026,
          searchKey: {
            zhCN: '方形, 错误, 删除, 叉, 关闭, 退出, 按钮',
            enUS: 'Square, error, delete, cross, close, exit, button',
            zhSpell: 'fangxing, cuowu, shanchu, cha, guanbi, tuichu, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60027,
          searchKey: {
            zhCN: '方形, 错误, 删除, 叉, 关闭, 退出, 按钮',
            enUS: 'Square, error, delete, cross, close, exit, button',
            zhSpell: 'fangxing, cuowu, shanchu, cha, guanbi, tuichu, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60028,
          searchKey: {
            zhCN: '正确, 勾选, 对, 完成, 方框',
            enUS: 'Correct, tick, right, complete, box',
            zhSpell: 'zhengque, gouxuan, dui, wancheng, fangkuang',
          },
          group: 'symbol',
        },
        {
          iconCode: 60029,
          searchKey: {
            zhCN: '方形, 对, 完成, 勾选, 正确, 按钮',
            enUS: 'Square, right, done, ticked, correct, button',
            zhSpell: 'fangxing, dui, wancheng, gouxuan, zhengque, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60030,
          searchKey: {
            zhCN: '方形, 对, 完成, 勾选, 正确, 按钮',
            enUS: 'Square, right, done, ticked, correct, button',
            zhSpell: 'fangxing, dui, wancheng, gouxuan, zhengque, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60031,
          searchKey: {
            zhCN: '-, 方形, 减, 移除, 删除, 清空, 按钮',
            enUS: '-, Square, subtract, remove, delete, empty, button',
            zhSpell: '-, fangxing, jian, yichu, shanchu, qingkong, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60032,
          searchKey: {
            zhCN: '-, 方形, 减, 移除, 删除, 清空, 按钮',
            enUS: '-, Square, subtract, remove, delete, empty, button',
            zhSpell: '-, fangxing, jian, yichu, shanchu, qingkong, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60033,
          searchKey: {
            zhCN: '错误, 删除, 叉, 关闭, 清除',
            enUS: 'Error, delete, cross, close, clear',
            zhSpell: 'cuowu, shanchu, cha, guanbi, qingchu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60034,
          searchKey: {
            zhCN: '+, 十字, 加号, 添加, 增加, 新建, 方形, 创建, 按钮',
            enUS: '+, Plus, add, new, square, create, button',
            zhSpell: '+, jia, tianjia, zengjia, xinjian, fangxing, chuangjian, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60035,
          searchKey: {
            zhCN: '-, 减, 删除, 取消, 减去, 去掉',
            enUS: '-, Subtract, delete, cancel, subtract, remove',
            zhSpell: '-, jian, shanchu, quxiao, jianqu, qudiao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60036,
          searchKey: {
            zhCN: '+, 十字, 加号, 添加, 增加, 新建, 放大, 创建',
            enUS: '+, Plus, add, enlarge, create',
            zhSpell: '+, jia, tianjia, zengjia, xinjian, fangda, chuangjian',
          },
          group: 'symbol',
        },
        {
          iconCode: 60037,
          searchKey: {
            zhCN: '+, 十字, 加号, 添加, 增加, 新建, 方形, 创建, 按钮',
            enUS: '+, Plus, add, new, square, create, button',
            zhSpell: '+, jia, tianjia, zengjia, xinjian, fangxing, chuangjian, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60038,
          searchKey: {
            zhCN: '-, 减, 删除, 取消, 减去, 去掉',
            enUS: '-, Subtract, delete, cancel, subtract, remove',
            zhSpell: '-, jian, shanchu, quxiao, jianqu, qudiao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60039,
          searchKey: {
            zhCN: '+, 十字, 加号, 添加, 增加, 新建, 放大, 创建',
            enUS: '+, Plus, add, enlarge, create',
            zhSpell: '+, jia, tianjia, zengjia, xinjian, fangda, chuangjian',
          },
          group: 'symbol',
        },
        {
          iconCode: 60040,
          searchKey: {
            zhCN: '符号, 正确, 勾, 对, 完成, 检查',
            enUS: 'Symbol, correct, tick, right, complete, check',
            zhSpell: 'fuhao, zhengque, gou, dui, wancheng, jiancha',
          },
          group: 'symbol',
        },
        {
          iconCode: 60041,
          searchKey: {
            zhCN: '错误, 删除, 叉, 关闭, 清除',
            enUS: 'Error, delete, cross, close, clear',
            zhSpell: 'cuowu, shanchu, cha, guanbi, qingchu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60042,
          searchKey: {
            zhCN: '符号, 正确, 勾, 对, 完成, 检查',
            enUS: 'Symbol, correct, tick, right, complete, check',
            zhSpell: 'fuhao, zhengque, gou, dui, wancheng, jiancha',
          },
          group: 'symbol',
        },
        {
          iconCode: 60043,
          searchKey: {
            zhCN: '更多, 省略号, 点, 水平点, 对齐点',
            enUS: 'More, ellipses, points, horizontal points, alignment points',
            zhSpell: 'gengduo, shengluehao, dian, shuipingdian, duiqidian',
          },
          group: 'symbol',
        },
        {
          iconCode: 60044,
          searchKey: {
            zhCN: '更多, 方点, 点, 竖点, 对齐点',
            enUS: 'More, square points, points, vertical points, alignment points',
            zhSpell: 'gengduo, fangdian, dian, shudian, duiqidian',
          },
          group: 'symbol',
        },
        {
          iconCode: 60045,
          searchKey: {
            zhCN: '更多, 省略号, 点, 水平点, 对齐点',
            enUS: 'More, ellipses, points, horizontal points, alignment points',
            zhSpell: 'gengduo, shengluehao, dian, shuipingdian, duiqidian',
          },
          group: 'symbol',
        },
        {
          iconCode: 60046,
          searchKey: {
            zhCN: '更多, 省略号, 点, 对平点, 对齐点, 圆形',
            enUS: 'More, ellipses, points, pairs of flat points, alignment points, circles',
            zhSpell: 'gengduo, shengluehao, dian, duipingdian, duiqidian, yuanxing',
          },
          group: 'symbol',
        },
        {
          iconCode: 60047,
          searchKey: {
            zhCN: '更多, 省略号, 点, 对平点, 对齐点, 圆形',
            enUS: 'More, ellipses, points, pairs of flat points, alignment points, circles',
            zhSpell: 'gengduo, shengluehao, dian, duipingdian, duiqidian, yuanxing',
          },
          group: 'symbol',
        },
        {
          iconCode: 60048,
          searchKey: {
            zhCN: '禁止, 禁行, 严禁, 路标, 圆形, 不可行',
            enUS: 'Prohibited, forbidden, forbidden, road sign, round, not feasible',
            zhSpell: 'jinzhi, jinxing, yanjin, lubiao, yuanxing, bukexing',
          },
          group: 'symbol',
        },
        {
          iconCode: 60049,
          searchKey: {
            zhCN: '气泡, 知识问答, 咨询, 服务, 问题, 帮助, 聊天',
            enUS: 'Bubble, knowledge quiz, consultation, service, problem, help, chat',
            zhSpell: 'qipao, zhishiwenda, zixun, fuwu, wenti, bangzhu, liaotian',
          },
          group: 'symbol',
        },
        {
          iconCode: 60050,
          searchKey: {
            zhCN: '气泡, 知识问答, 咨询, 服务, 问题, 帮助, 聊天',
            enUS: 'Bubble, knowledge quiz, consultation, service, problem, help, chat',
            zhSpell: 'qipao, zhishiwenda, zixun, fuwu, wenti, bangzhu, liaotian',
          },
          group: 'symbol',
        },
        {
          iconCode: 60051,
          searchKey: {
            zhCN: '警告, 感叹号, 小心, 提示, 注意, 提醒, 三角形',
            enUS: 'Warning, exclamation point, caution, reminder, note, reminder, triangle',
            zhSpell: 'jinggao, gantanhao, xiaoxin, tishi, zhuyi, tixing, sanjiaoxing',
          },
          group: 'symbol',
        },
        {
          iconCode: 60052,
          searchKey: {
            zhCN: '警告, 感叹号, 小心, 提示, 注意, 提醒, 三角形',
            enUS: 'Warning, exclamation point, caution, reminder, note, reminder, triangle',
            zhSpell: 'jinggao, gantanhao, xiaoxin, tishi, zhuyi, tixing, sanjiaoxing',
          },
          group: 'symbol',
        },
        {
          iconCode: 60053,
          searchKey: {
            zhCN: '删除, 移除, 退出, 返回, 关闭, 叉',
            enUS: 'Delete, remove, exit, return, close, cross',
            zhSpell: 'shanchu, yichu, tuichu, fanhui, guanbi, cha',
          },
          group: 'symbol',
        },
        {
          iconCode: 60054,
          searchKey: {
            zhCN: '删除, 移除, 退出, 返回, 关闭, 叉',
            enUS: 'Delete, remove, exit, return, close, cross',
            zhSpell: 'shanchu, yichu, tuichu, fanhui, guanbi, cha',
          },
          group: 'symbol',
        },
        {
          iconCode: 60055,
          searchKey: {
            zhCN: '删除, 移除, 退出, 气泡, 关闭, 叉',
            enUS: 'Delete, remove, exit, bubble, close, cross',
            zhSpell: 'shanchu, yichu, tuichu, qipao, guanbi, cha',
          },
          group: 'symbol',
        },
        {
          iconCode: 60056,
          searchKey: {
            zhCN: '删除, 移除, 退出, 气泡, 关闭, 叉',
            enUS: 'Delete, remove, exit, bubble, close, cross',
            zhSpell: 'shanchu, yichu, tuichu, qipao, guanbi, cha',
          },
          group: 'symbol',
        },
        {
          iconCode: 60057,
          searchKey: {
            zhCN: '错误, 删除, 叉, 关闭, 八边形, 退出, 按钮',
            enUS: 'Error, delete, cross, close, octagon, exit, button',
            zhSpell: 'cuowu, shanchu, cha, guanbi, babianxing, tuichu, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60058,
          searchKey: {
            zhCN: '错误, 删除, 叉, 关闭, 八边形, 退出, 按钮',
            enUS: 'Error, delete, cross, close, octagon, exit, button',
            zhSpell: 'cuowu, shanchu, cha, guanbi, babianxing, tuichu, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60059,
          searchKey: {
            zhCN: '数学, 总和, 求和, 符号, 累计, 计算, 希腊字母',
            enUS: 'Mathematics, sum, symbol, cumulative, calculation, greek letters',
            zhSpell: 'shuxue, zonghe, qiuhe, fuhao, leiji, jisuan, xilazimu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60060,
          searchKey: {
            zhCN: '所有, 双勾, 正确, 检查, 完成',
            enUS: 'All, double hook, correct, check, complete',
            zhSpell: 'suoyou, shuanggou, zhengque, jiancha, wancheng',
          },
          group: 'symbol',
        },
        {
          iconCode: 60061,
          searchKey: {
            zhCN: '无限, 数学, 无穷大, 环, 形状',
            enUS: 'Infinity, math, infinity, ring, shape',
            zhSpell: 'wuxian, shuxue, wuqiongda, huan, xingzhuang',
          },
          group: 'symbol',
        },
        {
          iconCode: 60062,
          searchKey: {
            zhCN: '信息, 帮助, 学习, 资料, 字母',
            enUS: 'Information, help, study, materials, letters',
            zhSpell: 'xinxi, bangzhu, xuexi, ziliao, zimu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60063,
          searchKey: {
            zhCN: '星座, 白羊, 白羊座, 符号, 特殊符号',
            enUS: 'Constellation, Aries, Aries, symbols, special symbols',
            zhSpell: 'xingzuo, baiyang, baiyangzuo, fuhao, teshufuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60064,
          searchKey: {
            zhCN: '星座, 谷神星, 符号, 特殊符号',
            enUS: 'Constellation, Ceres, symbols, special symbols',
            zhSpell: 'xingzuo, gushenxing, fuhao, teshufuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60065,
          searchKey: {
            zhCN: '星座, 金牛, 金牛座, 符号, 特殊符号',
            enUS: 'Constellation, Taurus, Taurus, symbol, special symbol',
            zhSpell: 'xingzuo, jinniu, jinniuzuo, fuhao, teshufuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60066,
          searchKey: {
            zhCN: '星座, 海王星, 符号, 叉子, 特殊符号',
            enUS: 'Constellation, Neptune, symbol, fork, special symbol',
            zhSpell: 'xingzuo, haiwangxing, fuhao, chazi, teshufuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60067,
          searchKey: {
            zhCN: '星座, 巨蟹, 巨蟹座, 符号, 特殊符号',
            enUS: 'Constellation, Cancer, Cancer, Symbol, Special Symbol',
            zhSpell: 'xingzuo, juxie, juxiezuo, fuhao, teshufuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60068,
          searchKey: {
            zhCN: '星座, 射手, 射手座, 符号, 特殊符号',
            enUS: 'Constellation, shooter, Sagittarius, symbol, special symbol',
            zhSpell: 'xingzuo, sheshou, sheshouzuo, fuhao, teshufuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60069,
          searchKey: {
            zhCN: '星座, 冥王星, 符号, 特殊符号',
            enUS: 'Constellation, Pluto, symbol, special symbol',
            zhSpell: 'xingzuo, mingwangxing, fuhao, teshufuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60070,
          searchKey: {
            zhCN: '星座, 摩羯, 摩羯座, 符号, 特殊符号',
            enUS: 'Constellation, Capricorn, Capricorn, symbol, special symbol',
            zhSpell: 'xingzuo, mojie, mojiezuo, fuhao, teshufuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60071,
          searchKey: {
            zhCN: '星座, 双鱼, 双鱼座, 符号, 特殊符号',
            enUS: 'Constellation, Pisces, Pisces, symbols, special symbols',
            zhSpell: 'xingzuo, shuangyu, shuangyuzuo, fuhao, teshufuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60072,
          searchKey: {
            zhCN: '星座, 狮子, 狮子座, 符号, 特殊符号',
            enUS: 'Constellation, lion, leo, symbol, special symbol',
            zhSpell: 'xingzuo, shizi, shizizuo, fuhao, teshufuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60073,
          searchKey: {
            zhCN: '星座, 双子, 双子座, 符号, 特殊符号',
            enUS: 'Constellation, Gemini, Gemini, symbol, special symbol',
            zhSpell: 'xingzuo, shuangzi, shuangzizuo, fuhao, teshufuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60074,
          searchKey: {
            zhCN: '星座, 水瓶, 水瓶座, 符号, 特殊符号',
            enUS: 'Constellation, water bottle, aquarius, symbol, special symbol',
            zhSpell: 'xingzuo, shuiping, shuipingzuo, fuhao, teshufuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60075,
          searchKey: {
            zhCN: '星座, 天秤, 天秤座, 符号, 特殊符号',
            enUS: 'Constellation, Libra, Libra, symbol, special symbol',
            zhSpell: 'xingzuo, tiancheng, tianchengzuo, fuhao, teshufuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60076,
          searchKey: {
            zhCN: '星座, 占星, 符号, 占卜, 特殊符号',
            enUS: 'Constellation, astrology, symbol, divination, special symbol',
            zhSpell: 'xingzuo, zhanxing, fuhao, zhanbu, teshufuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60077,
          searchKey: {
            zhCN: '性别, 男, 男性, 男人, 精子',
            enUS: 'Gender, male, male, man, sperm',
            zhSpell: 'xingbie, nan, nanxing, nanren, jingzi',
          },
          group: 'symbol',
        },
        {
          iconCode: 60078,
          searchKey: {
            zhCN: '星座, 天蝎, 天蝎座, 符号, 特殊符号',
            enUS: 'Constellation, Scorpio, Scorpio, symbol, special symbol',
            zhSpell: 'xingzuo, tianxie, tianxiezuo, fuhao, teshufuhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60079,
          searchKey: {
            zhCN: '语音, 提示, 警告, 提醒, 小心, 注意',
            enUS: 'Voice, reminder, warning, reminder, caution, attention',
            zhSpell: 'yuyin, tishi, jinggao, tixing, xiaoxin, zhuyi',
          },
          group: 'symbol',
        },
        {
          iconCode: 60080,
          searchKey: {
            zhCN: '语音, 提示, 警告, 提醒, 小心, 注意',
            enUS: 'Voice, reminder, warning, reminder, caution, attention',
            zhSpell: 'yuyin, tishi, jinggao, tixing, xiaoxin, zhuyi',
          },
          group: 'symbol',
        },
        {
          iconCode: 60081,
          searchKey: {
            zhCN: '性别, 女, 女孩, 妇女, 女性',
            enUS: 'Gender, female, girl, women, female',
            zhSpell: 'xingbie, nv, nvhai, funv, nvxing',
          },
          group: 'symbol',
        },
        {
          iconCode: 60082,
          searchKey: {
            zhCN: '气泡, 语音, 咨询, 服务, 问题, 帮助, 聊天',
            enUS: 'Bubble, voice, consultation, service, problem, help, chat',
            zhSpell: 'qipao, yuyin, zixun, fuwu, wenti, bangzhu, liaotian',
          },
          group: 'symbol',
        },
        {
          iconCode: 60083,
          searchKey: {
            zhCN: '性别, 男女, 维纳斯, 男性, 女性',
            enUS: 'Gender, men and women, Venus, male, female',
            zhSpell: 'xingbie, nannv, weinasi, nanxing, nvxing',
          },
          group: 'symbol',
        },
        {
          iconCode: 60084,
          searchKey: {
            zhCN: '圆形, 版权, 注册商标, 字母',
            enUS: 'Round,copyright,trademark,letters,font',
            zhSpell: 'yuanxing,banquan,shangbiao,zimu,zimu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60085,
          searchKey: {
            zhCN: '气泡, 语音, 咨询, 服务, 问题, 帮助, 聊天',
            enUS: 'Bubble, voice, consultation, service, problem, help, chat',
            zhSpell: 'qipao, yuyin, zixun, fuwu, wenti, bangzhu, liaotian',
          },
          group: 'symbol',
        },
        {
          iconCode: 60086,
          searchKey: {
            zhCN: '圆形, 版权, 注册商标, 字母',
            enUS: 'Round,copyright,trademark,letters,font',
            zhSpell: 'yuanxing,banquan,shangbiao,zimu,zimu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60087,
          searchKey: {
            zhCN: '圆形, 版权, 注册商标, 字母',
            enUS: 'Round,copyright,trademark,letters,font',
            zhSpell: 'yuanxing,banquan,shangbiao,zimu,zimu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60088,
          searchKey: {
            zhCN: '错误, 删除, 叉, 关闭, 圆形, 按钮',
            enUS: 'Error, delete, cross, close, round, button',
            zhSpell: 'cuowu, shanchu, cha, guanbi, yuanxing, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60089,
          searchKey: {
            zhCN: '圆形, 版权, 注册商标, 字母',
            enUS: 'Round,copyright,trademark,letters,font',
            zhSpell: 'yuanxing,banquan,shangbiao,zimu,zimu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60090,
          searchKey: {
            zhCN: '对, 完成, 勾选, 正确, 圆形, 按钮',
            enUS: 'Right, done, ticked, correct, round, button',
            zhSpell: 'dui, wancheng, gouxuan, zhengque, yuanxing, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60091,
          searchKey: {
            zhCN: '对, 完成, 勾选, 正确, 圆形, 按钮',
            enUS: 'Right, done, ticked, correct, round, button',
            zhSpell: 'dui, wancheng, gouxuan, zhengque, yuanxing, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60092,
          searchKey: {
            zhCN: '圆形, 公网IP, IP电话, 圆圈, 标志',
            enUS: 'Round, public IP, IP phone, circle, logo',
            zhSpell: 'yuanxing, gongwangIP, IPdianhua, yuanquan, biaozhi',
          },
          group: 'symbol',
        },
        {
          iconCode: 60093,
          searchKey: {
            zhCN: '圆形, 公网IP, IP电话, 圆圈, 标志',
            enUS: 'Round, public IP, IP phone, circle, logo',
            zhSpell: 'yuanxing, gongwangIP, IPdianhua, yuanquan, biaozhi',
          },
          group: 'symbol',
        },
        {
          iconCode: 60094,
          searchKey: {
            zhCN: '错误, 删除, 叉, 关闭, 圆形, 按钮',
            enUS: 'Error, delete, cross, close, round, button',
            zhSpell: 'cuowu, shanchu, cha, guanbi, yuanxing, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60095,
          searchKey: {
            zhCN: '+, 十字, 加号, 添加, 增加, 新建, 创建, 圆形, 按钮',
            enUS: '+, Plus, add, create, circle, button',
            zhSpell: '+, jia, tianjia, zengjia, xinjian, chuangjian, yuanxing, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60096,
          searchKey: {
            zhCN: '+, 十字, 加号, 添加, 增加, 新建, 创建, 圆形, 按钮',
            enUS: '+, Plus, add, create, circle, button',
            zhSpell: '+, jia, tianjia, zengjia, xinjian, chuangjian, yuanxing, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60097,
          searchKey: {
            zhCN: '-, 减, 删除, 取消, 减去, 去掉, 圆形, 禁止, 按钮',
            enUS: '-, Minus, delete, cancel, subtract, remove, round, forbidden, button',
            zhSpell: '-, jian, shanchu, quxiao, jianqu, qudiao, yuanxing, jinzhi, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60098,
          searchKey: {
            zhCN: '+, 十字, 圆形, 加号, 添加, 增加, 新建, 虚线',
            enUS: '+, Round, plus, add, new, dotted',
            zhSpell: '+, yuanxing, jiahao, tianjia, zengjia, xinjian, xuxian',
          },
          group: 'symbol',
        },
        {
          iconCode: 60099,
          searchKey: {
            zhCN: '-, 减, 删除, 取消, 减去, 去掉, 圆形, 禁止, 按钮',
            enUS: '-, Minus, delete, cancel, subtract, remove, round, forbidden, button',
            zhSpell: '-, jian, shanchu, quxiao, jianqu, qudiao, yuanxing, jinzhi, anniu',
          },
          group: 'symbol',
        },
        {
          iconCode: 60100,
          searchKey: {
            zhCN: '警告, 感叹号, 小心, 提示, 注意, 圆形',
            enUS: 'Warning, exclamation mark, caution, hint, attention, round',
            zhSpell: 'jinggao, gantanhao, xiaoxin, tishi, zhuyi, yuanxing',
          },
          group: 'symbol',
        },
        {
          iconCode: 60101,
          searchKey: {
            zhCN: '警告, 感叹号, 小心, 提示, 注意, 圆形',
            enUS: 'Warning, exclamation mark, caution, hint, attention, round',
            zhSpell: 'jinggao, gantanhao, xiaoxin, tishi, zhuyi, yuanxing',
          },
          group: 'symbol',
        },
        {
          iconCode: 60102,
          searchKey: {
            zhCN: '圆形, 停车场, 停车, 字母, 标志',
            enUS: 'Round, parking lot, parking, letters, signs',
            zhSpell: 'yuanxing, tingchechang, tingche, zimu, biaozhi',
          },
          group: 'symbol',
        },
        {
          iconCode: 60103,
          searchKey: {
            zhCN: '圆形, 停车场, 停车, 字母, 标志',
            enUS: 'Round, parking lot, parking, letters, signs',
            zhSpell: 'yuanxing, tingchechang, tingche, zimu, biaozhi',
          },
          group: 'symbol',
        },
        {
          iconCode: 60104,
          searchKey: {
            zhCN: '语音, 咨询, 服务, 问题, 问号',
            enUS: 'Voice, consultation, service, question, question mark',
            zhSpell: 'yuyin, zixun, fuwu, wenti, wenhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60105,
          searchKey: {
            zhCN: '语音, 咨询, 服务, 问题, 问号',
            enUS: 'Voice, consultation, service, question, question mark',
            zhSpell: 'yuyin, zixun, fuwu, wenti, wenhao',
          },
          group: 'symbol',
        },
        {
          iconCode: 60106,
          searchKey: {
            zhCN: '信息, 感叹号, 小心, 提示, 注意',
            enUS: 'Information, exclamation mark, caution, reminder, attention',
            zhSpell: 'xinxi, gantanhao, xiaoxin, tishi, zhuyi',
          },
          group: 'symbol',
        },
        {
          iconCode: 60107,
          searchKey: {
            zhCN: '信息, 感叹号, 小心, 提示, 注意',
            enUS: 'Information, exclamation mark, caution, reminder, attention',
            zhSpell: 'xinxi, gantanhao, xiaoxin, tishi, zhuyi',
          },
          group: 'symbol',
        },
        {
          iconCode: 60108,
          searchKey: {
            zhCN: '圆形, 字母, 空心, 字体, 圆圈',
            enUS: 'Circle, letter, hollow, font, circle',
            zhSpell: 'yuanxing, zimu, kongxin, ziti, yuanquan',
          },
          group: 'symbol',
        },
        {
          iconCode: 60109,
          searchKey: {
            zhCN: '圆形, 字母, 实心, 字体, 圆圈',
            enUS: 'Circle, letter, solid, font, circle',
            zhSpell: 'yuanxing, zimu, shixin, ziti, yuanquan',
          },
          group: 'symbol',
        },
        {
          iconCode: 60110,
          searchKey: {
            zhCN: '正确, 勾选, 对, 完成, 选项, 检查',
            enUS: 'Correct, tick, correct, complete, option, check',
            zhSpell: 'zhengque, gouxuan, dui, wancheng, xuanxiang, jiancha',
          },
          group: 'symbol',
        },
        {
          iconCode: 60111,
          searchKey: {
            zhCN: '运算, 大于, 符号, 数学, 计算',
            enUS: 'Operation, greater than, symbol, math, calculation',
            zhSpell: 'yunsuan, dayu, fuhao, shuxue, jisuan',
          },
          group: 'symbol',
        },
        {
          iconCode: 60112,
          searchKey: {
            zhCN: '运算, 除, 符号, 数学, 计算',
            enUS: 'Operation, division, symbol, math, calculation',
            zhSpell: 'yunsuan, chu, fuhao, shuxue, jisuan',
          },
          group: 'symbol',
        },
        {
          iconCode: 60113,
          searchKey: {
            zhCN: '运算, 等于, 符号, 数学, 计算',
            enUS: 'Operation, equal, symbol, math, calculation',
            zhSpell: 'yunsuan, dengyu, fuhao, shuxue, jisuan',
          },
          group: 'symbol',
        },
        {
          iconCode: 60114,
          searchKey: {
            zhCN: '运算, 小于, 符号, 数学, 计算',
            enUS: 'Operation, less than, symbol, math, calculation',
            zhSpell: 'yunsuan, xiaoyu, fuhao, shuxue, jisuan',
          },
          group: 'symbol',
        },
        {
          iconCode: 60115,
          searchKey: {
            zhCN: '运算, 大于等于, 符号, 数学, 计算',
            enUS: 'Operation, greater than or equal, symbol, math, calculation',
            zhSpell: 'yunsuan, dayudengyu, fuhao, shuxue, jisuan',
          },
          group: 'symbol',
        },
        {
          iconCode: 60116,
          searchKey: {
            zhCN: '运算, 加减, 符号, 数学, 计算',
            enUS: 'Operation, addition, subtraction, symbol, math, calculation',
            zhSpell: 'yunsuan, jiajian, fuhao, shuxue, jisuan',
          },
          group: 'symbol',
        },
        {
          iconCode: 60117,
          searchKey: {
            zhCN: '运算, 加减乘等于, 符号, 数学, 计算',
            enUS: 'Operation, addition, subtraction, multiplication, equal sign, math, calculation',
            zhSpell: 'yunsuan, jiajianchengdengyu, fuhao, shuxue, jisuan',
          },
          group: 'symbol',
        },
        {
          iconCode: 60118,
          searchKey: {
            zhCN: '运算, 小于等于, 符号, 数学, 计算',
            enUS: 'Operation, less than or equal, symbol, math, calculation',
            zhSpell: 'yunsuan, xiaoyudengyu, fuhao, shuxue, jisuan',
          },
          group: 'symbol',
        },
        {
          iconCode: 60119,
          searchKey: {
            zhCN: '章节, 段落, 文本, 编辑, 格式',
            enUS: 'Chapter, paragraph, text, edit, format',
            zhSpell: 'zhangjie, duanluo, wenben, bianji, geshi',
          },
          group: 'symbol',
        },
      ],
    },
    {
      name: i18n('resource.icons.direction'),
      id: 'direction',
      items: [
        {
          iconCode: 59824,
          searchKey: {
            zhCN: '导出, 上, 向上, 上传, 方向',
            enUS: 'Export, up, up, upload, direction',
            zhSpell: 'daochu, shang, xiangshang, shangchuan, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59825,
          searchKey: {
            zhCN: '导航, 定位, 位置, 指针, 方向',
            enUS: 'Navigation, positioning, position, pointer, direction',
            zhSpell: 'daohang, dingwei, weizhi, zhizhen, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59826,
          searchKey: {
            zhCN: '导航, 定位, 位置, 指针, 方向',
            enUS: 'Navigation, positioning, position, pointer, direction',
            zhSpell: 'daohang, dingwei, weizhi, zhizhen, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59827,
          searchKey: {
            zhCN: '导出, 右, 向右, 箭头, 退出',
            enUS: 'Export, right, right, arrow, exit',
            zhSpell: 'daochu, you, xiangyou, jiantou, tuichu',
          },
          group: 'direction',
        },
        {
          iconCode: 59828,
          searchKey: {
            zhCN: '导航, 指南针, 罗盘, 指针, 方向',
            enUS: 'Navigation, compass, compass, pointer, direction',
            zhSpell: 'daohang, zhinanzhen, luopan, zhizhen, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59829,
          searchKey: {
            zhCN: '导航, 指南针, 罗盘, 指针, 方向',
            enUS: 'Navigation, compass, compass, pointer, direction',
            zhSpell: 'daohang, zhinanzhen, luopan, zhizhen, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59830,
          searchKey: {
            zhCN: '导航, 指南针, 罗盘, 指针, 方向',
            enUS: 'Navigation, compass, compass, pointer, direction',
            zhSpell: 'daohang, zhinanzhen, luopan, zhizhen, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59831,
          searchKey: {
            zhCN: '导入, 右, 向右, 箭头, 进入',
            enUS: 'Import, right, right, arrow, enter',
            zhSpell: 'daoru, you, xiangyou, jiantou, jinru',
          },
          group: 'direction',
        },
        {
          iconCode: 59832,
          searchKey: {
            zhCN: '导入, 下, 向下, 下载, 箭头',
            enUS: 'Import, down, down, download, arrow',
            zhSpell: 'daoru, xia, xiangxia, xiazai, jiantou',
          },
          group: 'direction',
        },
        {
          iconCode: 59833,
          searchKey: {
            zhCN: '箭头, 向下, 更多, 删除, 雪佛兰',
            enUS: 'Arrow, down, more, delete, chevrolet',
            zhSpell: 'jiantou, xiangxia, gengduo, shanchu, xuefulan',
          },
          group: 'direction',
        },
        {
          iconCode: 59834,
          searchKey: {
            zhCN: '箭头, 向上, 忽略, 标记, 方向',
            enUS: 'Arrow, up, ignore, mark, direction',
            zhSpell: 'jiantou, xiangshang, hulue, biaoji, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59835,
          searchKey: {
            zhCN: '双箭头, 向上, 忽略, 标记, 方向',
            enUS: 'Double arrow, up, ignore, mark, direction',
            zhSpell: 'shuangjiantou, xiangshang, hulue, biaoji, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59836,
          searchKey: {
            zhCN: '双箭头, 向下, 更多, 展开, 箭头',
            enUS: 'Double arrow, down, more, expand, arrow',
            zhSpell: 'shuangjiantou, xiangxia, gengduo, zhankai, jiantou',
          },
          group: 'direction',
        },
        {
          iconCode: 59837,
          searchKey: {
            zhCN: '箭头, 向右, 向后, 下一步, 方向',
            enUS: 'Arrow, right, backward, next, direction',
            zhSpell: 'jiantou, xiangyou, xianghou, xiayibu, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59838,
          searchKey: {
            zhCN: '箭头, 向右, 向后, 雪佛兰, 方向',
            enUS: 'Arrow, right, backward, chevrolet, direction',
            zhSpell: 'jiantou, xiangyou, xianghou, xuefulan, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59839,
          searchKey: {
            zhCN: '箭头, 向左, 向前, 雪佛兰, 方向',
            enUS: 'Arrow, left, forward, chevrolet, direction',
            zhSpell: 'jiantou, xiangzuo, xiangqian, xuefulan, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59840,
          searchKey: {
            zhCN: '箭头, 向下, 更多, 减少, 南',
            enUS: 'Arrow, down, more, decrease, south',
            zhSpell: 'jiantou, xiangxia, gengduo, jianshao, nan',
          },
          group: 'direction',
        },
        {
          iconCode: 59841,
          searchKey: {
            zhCN: '箭头, 向上, 忽略, 标记, 方向',
            enUS: 'Arrow, up, ignore, mark, direction',
            zhSpell: 'jiantou, xiangshang, hulue, biaoji, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59842,
          searchKey: {
            zhCN: '双箭头, 向左, 向前, 上一步, 方向',
            enUS: 'Double arrow, left, forward, back, direction',
            zhSpell: 'shuangjiantou, xiangzuo, xiangqian, shangyibu, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59843,
          searchKey: {
            zhCN: '箭头, 上下, 导入, 导出, 展开',
            enUS: 'Arrow, up, down, import, export, expand',
            zhSpell: 'jiantou, shangxia, daoru, daochu, zhankai',
          },
          group: 'direction',
        },
        {
          iconCode: 59844,
          searchKey: {
            zhCN: '箭头, 向右, 向后, 下一步, 方向',
            enUS: 'Arrow, right, backward, next, direction',
            zhSpell: 'jiantou, xiangyou, xianghou, xiayibu, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59845,
          searchKey: {
            zhCN: '箭头, 向左, 向前, 上一步, 方向, 返回',
            enUS: 'Arrow, left, forward, back, direction, return',
            zhSpell: 'jiantou, xiangzuo, xiangqian, shangyibu, fangxiang, fanhui',
          },
          group: 'direction',
        },
        {
          iconCode: 59846,
          searchKey: {
            zhCN: '双箭头, 上下, 放大, 增大, 调整',
            enUS: 'Double arrow, up and down, amplifier, and adjustment',
            zhSpell: 'shuangjiantou, shangxia, fangda, zengda, tiaozheng',
          },
          group: 'direction',
        },
        {
          iconCode: 59847,
          searchKey: {
            zhCN: '双箭头, 左右, 伸展, 调整, 方向',
            enUS: 'Double arrow, left and right, stretch, adjust, direction',
            zhSpell: 'shuangjiantou, zuoyou, shenzhan, tiaozheng, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59848,
          searchKey: {
            zhCN: '箭头, 右左, 交换, 扩展, 分离',
            enUS: 'Arrow, right and left, exchange, expansion, separation',
            zhSpell: 'jiantou, youzuo, jiaohuan, kuozhan, fenli',
          },
          group: 'direction',
        },
        {
          iconCode: 59849,
          searchKey: {
            zhCN: '箭头, 选中, 移动, 全屏, 扩展',
            enUS: 'Arrow, selected, moved, full screen, extended',
            zhSpell: 'jiantou, xuanzhong, yidong, quanping, kuozhan',
          },
          group: 'direction',
        },
        {
          iconCode: 59850,
          searchKey: {
            zhCN: '箭头, 收缩, 缩小, 退出全屏, 关闭',
            enUS: 'Arrow, shrink, shrink, exit full screen, close',
            zhSpell: 'jiantou, shousuo, suoxiao, tuichuquanping, guanbi',
          },
          group: 'direction',
        },
        {
          iconCode: 59851,
          searchKey: {
            zhCN: '箭头, 向上, 上升, 标记, 方向',
            enUS: 'Arrow, up, rise, mark, direction',
            zhSpell: 'jiantou, xiangshang, shangsheng, biaoji, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59852,
          searchKey: {
            zhCN: '箭头, 上下, 切换, 方向, 双向',
            enUS: 'Arrow, up and down, switch, direction, two way',
            zhSpell: 'jiantou, shangxia, qiehuan, fangxiang, shuangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59853,
          searchKey: {
            zhCN: '箭头, 向下, 下降, 减少, 南',
            enUS: 'Arrow, down, down, decrease, south',
            zhSpell: 'jiantou, xiangxia, xiajiang, jianshao, nan',
          },
          group: 'direction',
        },
        {
          iconCode: 59854,
          searchKey: {
            zhCN: '箭头, 左, 向左, 上一步, 方向',
            enUS: 'Arrow, left, left, previous, direction',
            zhSpell: 'jiantou, zuo, xiangzuo, shangyibu, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59855,
          searchKey: {
            zhCN: '箭头, 下, 向下, 置底, 方向',
            enUS: 'Arrow, down, down, bottom, direction',
            zhSpell: 'jiantou, xia, xiangxia, zhidi, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59856,
          searchKey: {
            zhCN: '箭头, 右, 向右, 下一步, 方向',
            enUS: 'Arrow, right, right, next, direction',
            zhSpell: 'jiantou, you, xiangyou, xiayibu, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59857,
          searchKey: {
            zhCN: '箭头, 上, 向上, 置顶, 回到顶部, 方向',
            enUS: 'Arrow, up, up, top, back to top, direction',
            zhSpell: 'jiantou, shang, xiangshang, zhiding, huidaodingbu, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59858,
          searchKey: {
            zhCN: '箭头, 收回上下, 收缩, 缩短, 间距',
            enUS: 'Arrow, retracting up and down, shrinking, shortening, spacing',
            zhSpell: 'jiantou, shouhuishangxia, shousuo, suoduan, jianju',
          },
          group: 'direction',
        },
        {
          iconCode: 59859,
          searchKey: {
            zhCN: '箭头, 下, 向下, 置底, 方向, 下载',
            enUS: 'Arrow, down, down, bottom, direction, download',
            zhSpell: 'jiantou, xia, xiangxia, zhidi, fangxiang, xiazai',
          },
          group: 'direction',
        },
        {
          iconCode: 59860,
          searchKey: {
            zhCN: '导出, 上, 向上, 上传, 箭头',
            enUS: 'Export, up, up, upload, arrow',
            zhSpell: 'daochu, shang, xiangshang, shangchuan, jiantou',
          },
          group: 'direction',
        },
        {
          iconCode: 59861,
          searchKey: {
            zhCN: '箭头, 上, 向上, 置顶, 回到顶部, 方向',
            enUS: 'Arrow, up, up, top, back to top, direction',
            zhSpell: 'jiantou, shang, xiangshang, zhiding, huidaodingbu, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59862,
          searchKey: {
            zhCN: '导入, 下, 向下, 下载, 箭头',
            enUS: 'Import, down, down, download, arrow',
            zhSpell: 'daoru, xia, xiangxia, xiazai, jiantou',
          },
          group: 'direction',
        },
        {
          iconCode: 59863,
          searchKey: {
            zhCN: '箭头, 趋势, 上升, 向上, 方向',
            enUS: 'Arrow, trend, rise, up, direction',
            zhSpell: 'jiantou, qushi, shangsheng, xiangshang, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59864,
          searchKey: {
            zhCN: '箭头, 向右, 右转, 直角, 方向',
            enUS: 'Arrow, right, right turn, right angle, direction',
            zhSpell: 'jiantou, xiangyou, youzhuan, zhijiao, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59865,
          searchKey: {
            zhCN: '箭头, 趋势, 下降, 向下, 方向',
            enUS: 'Arrow, trend, fall, down, direction',
            zhSpell: 'jiantou, qushi, xiajiang, xiangxia, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59866,
          searchKey: {
            zhCN: '箭头, 直角循环, 顺时针, 圈, 刷新',
            enUS: 'Arrow, right angle loop, clockwise, circle, refresh',
            zhSpell: 'jiantou, zhijiaoxunhuan, shunshizhen, quan, shuaxin',
          },
          group: 'direction',
        },
        {
          iconCode: 59867,
          searchKey: {
            zhCN: '箭头, 向左, 左转, 直角, 方向',
            enUS: 'Arrow, left, left, right angle, direction',
            zhSpell: 'jiantou, xiangzuo, zuozhuan, zhijiao, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59868,
          searchKey: {
            zhCN: '箭头, 右, 向右, 返回, 恢复',
            enUS: 'Arrow, right, right, back, recovery',
            zhSpell: 'jiantou, you, xiangyou, fanhui, huifu',
          },
          group: 'direction',
        },
        {
          iconCode: 59869,
          searchKey: {
            zhCN: '箭头, 向下, 掉头, 拐弯, 方向',
            enUS: 'Arrow, down, turn, turn, direction',
            zhSpell: 'jiantou, xiangxia, diaotou, guaiwan, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59870,
          searchKey: {
            zhCN: '箭头, 左, 向左, 向前, 撤销',
            enUS: 'Arrow, left, left, forward, revoke',
            zhSpell: 'jiantou, zuo, xiangzuo, xiangqian, chexiao',
          },
          group: 'direction',
        },
        {
          iconCode: 59871,
          searchKey: {
            zhCN: '箭头, 向下, 掉头, 拐弯, 方向',
            enUS: 'Arrow, down, turn, turn, direction',
            zhSpell: 'jiantou, xiangxia, diaotou, guaiwan, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59872,
          searchKey: {
            zhCN: '双箭头, 右, 向右, 返回, 恢复',
            enUS: 'Double arrow, right, right, back, recovery',
            zhSpell: 'shuangjiantou, you, xiangyou, fanhui, huifu',
          },
          group: 'direction',
        },
        {
          iconCode: 59873,
          searchKey: {
            zhCN: '箭头, 循环, 逆时针, 圈, 刷新',
            enUS: 'Arrow, loop, counterclockwise, circle, refresh',
            zhSpell: 'jiantou, xunhuan, nishizhen, quan, shuaxin',
          },
          group: 'direction',
        },
        {
          iconCode: 59874,
          searchKey: {
            zhCN: '箭头, 单循环, 逆时针, 圈, 刷新',
            enUS: 'Arrow, single loop, counterclockwise, circle, refresh',
            zhSpell: 'jiantou, danxunhuan, nishizhen, quan, shuaxin',
          },
          group: 'direction',
        },
        {
          iconCode: 59875,
          searchKey: {
            zhCN: '箭头, 向右, 右转, 方向, 路标',
            enUS: 'Arrow, right, right turn, direction, road sign',
            zhSpell: 'jiantou, xiangyou, youzhuan, fangxiang, lubiao',
          },
          group: 'direction',
        },
        {
          iconCode: 59876,
          searchKey: {
            zhCN: '双箭头, 左, 向左, 向前, 撤销',
            enUS: 'Double arrow, left, left, forward, revoke',
            zhSpell: 'shuangjiantou, zuo, xiangzuo, xiangqian, chexiao',
          },
          group: 'direction',
        },
        {
          iconCode: 59877,
          searchKey: {
            zhCN: '箭头, 向左, 左转, 路标, 方向',
            enUS: 'Arrow, left, left turn, road sign, direction',
            zhSpell: 'jiantou, xiangzuo, zuozhuan, lubiao, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59878,
          searchKey: {
            zhCN: '箭头, 右, 向右, 返回, 恢复, 半圆',
            enUS: 'Arrow, right, right, back, recovery, semicircle',
            zhSpell: 'jiantou, you, xiangyou, fanhui, huifu, banyuan',
          },
          group: 'direction',
        },
        {
          iconCode: 59879,
          searchKey: {
            zhCN: '箭头, 左, 向左, 向前, 撤销, 半圆',
            enUS: 'Arrow, left, left, forward, undo, semicircle',
            zhSpell: 'jiantou, zuo, xiangzuo, xiangqian, chexiao, banyuan',
          },
          group: 'direction',
        },
        {
          iconCode: 59880,
          searchKey: {
            zhCN: '箭头, 刷新, 旋转, 恢复, 圆圈, 顺时针',
            enUS: 'Arrow, refresh, rotate, resume, circle, clockwise',
            zhSpell: 'jiantou, shuaxin, xuanzhuan, huifu, yuanquan, shunshizhen',
          },
          group: 'direction',
        },
        {
          iconCode: 59881,
          searchKey: {
            zhCN: '箭头, 上, 向上, 导出, 方向',
            enUS: 'Arrow, up, up, export, direction',
            zhSpell: 'jiantou, shang, xiangshang, daochu, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59882,
          searchKey: {
            zhCN: '箭头, 刷新, 循环, 加载, 转圈, 逆时针',
            enUS: 'Arrow, refresh, loop, load, circle, counterclockwise',
            zhSpell: 'jiantou, shuaxin, xunhuan, jiazai, zhuanquan, nishizhen',
          },
          group: 'direction',
        },
        {
          iconCode: 59883,
          searchKey: {
            zhCN: '箭头, 刷新, 上一步, 撤销, 历史, 逆时针',
            enUS: 'Arrow, refresh, previous step, undo, history, counterclockwise',
            zhSpell: 'jiantou, shuaxin, shangyibu, chexiao, lishi, nishizhen',
          },
          group: 'direction',
        },
        {
          iconCode: 59884,
          searchKey: {
            zhCN: '箭头, 上, 向上, 导出, 方向',
            enUS: 'Arrow, up, up, export, direction',
            zhSpell: 'jiantou, shang, xiangshang, daochu, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59885,
          searchKey: {
            zhCN: '箭头, 上, 向上, 指示, 方向',
            enUS: 'Arrow, up, up, direction, direction',
            zhSpell: 'jiantou, shang, xiangshang, zhishi, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59886,
          searchKey: {
            zhCN: '箭头, 上, 向上, 指示, 方向',
            enUS: 'Arrow, up, up, direction, direction',
            zhSpell: 'jiantou, shang, xiangshang, zhishi, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59887,
          searchKey: {
            zhCN: '箭头, 下, 向下, 指示, 方向',
            enUS: 'Arrow, down, down, direction, direction',
            zhSpell: 'jiantou, xia, xiangxia, zhishi, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59888,
          searchKey: {
            zhCN: '箭头, 下, 向下, 指示, 方向',
            enUS: 'Arrow, down, down, direction, direction',
            zhSpell: 'jiantou, xia, xiangxia, zhishi, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59889,
          searchKey: {
            zhCN: '导出, 下, 向下, 下载, 箭头',
            enUS: 'Export, down, down, download, arrow',
            zhSpell: 'daochu, xia, xiangxia, xiazai, jiantou',
          },
          group: 'direction',
        },
        {
          iconCode: 59890,
          searchKey: {
            zhCN: '导出, 下, 向下, 下载, 箭头',
            enUS: 'Export, down, down, download, arrow',
            zhSpell: 'daochu, xia, xiangxia, xiazai, jiantou',
          },
          group: 'direction',
        },
        {
          iconCode: 59891,
          searchKey: {
            zhCN: '箭头, 右, 向右, 指示, 向后, 方向',
            enUS: 'Arrow, right, right, indication, backward, direction',
            zhSpell: 'jiantou, you, xiangyou, zhishi, xianghou, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59892,
          searchKey: {
            zhCN: '箭头, 左, 向左, 指示, 向前, 方向',
            enUS: 'Arrow, left, left, indication, forward, direction',
            zhSpell: 'jiantou, zuo, xiangzuo, zhishi, xiangqian, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59893,
          searchKey: {
            zhCN: '箭头, 右, 向右, 指示, 向后, 方向',
            enUS: 'Arrow, right, right, indication, backward, direction',
            zhSpell: 'jiantou, you, xiangyou, zhishi, xianghou, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59894,
          searchKey: {
            zhCN: '箭头, 向下, 下降, 方向, 三角形',
            enUS: 'Arrow, down, drop, direction, triangle',
            zhSpell: 'jiantou, xiangxia, xiajiang, fangxiang, sanjiaoxing',
          },
          group: 'direction',
        },
        {
          iconCode: 59895,
          searchKey: {
            zhCN: '箭头, 向右, 后退, 下一首, 三角形',
            enUS: 'Arrow, right, back, next, triangle',
            zhSpell: 'jiantou, xiangyou, houtui, xiayishou, sanjiaoxing',
          },
          group: 'direction',
        },
        {
          iconCode: 59896,
          searchKey: {
            zhCN: '箭头, 向上, 上升, 方向, 三角形',
            enUS: 'Arrow, up, rise, direction, triangle',
            zhSpell: 'jiantou, xiangshang, shangsheng, fangxiang, sanjiaoxing',
          },
          group: 'direction',
        },
        {
          iconCode: 59897,
          searchKey: {
            zhCN: '箭头, 向下, 下降, 方向, 三角形',
            enUS: 'Arrow, down, drop, direction, triangle',
            zhSpell: 'jiantou, xiangxia, xiajiang, fangxiang, sanjiaoxing',
          },
          group: 'direction',
        },
        {
          iconCode: 59898,
          searchKey: {
            zhCN: '箭头, 向上, 上升, 方向, 三角形',
            enUS: 'Arrow, up, rise, direction, triangle',
            zhSpell: 'jiantou, xiangshang, shangsheng, fangxiang, sanjiaoxing',
          },
          group: 'direction',
        },
        {
          iconCode: 59899,
          searchKey: {
            zhCN: '箭头, 向左, 前进, 上一首, 三角形',
            enUS: 'Arrow, left, forward, previous, triangle',
            zhSpell: 'jiantou, xiangzuo, qianjin, shangyishou, sanjiaoxing',
          },
          group: 'direction',
        },
        {
          iconCode: 59900,
          searchKey: {
            zhCN: '箭头, 左, 向左, 指示, 向前, 方向',
            enUS: 'Arrow, left, left, indication, forward, direction',
            zhSpell: 'jiantou, zuo, xiangzuo, zhishi, xiangqian, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59901,
          searchKey: {
            zhCN: '箭头, 向左, 前进, 上一首, 三角形',
            enUS: 'Arrow, left, forward, previous, triangle',
            zhSpell: 'jiantou, xiangzuo, qianjin, shangyishou, sanjiaoxing',
          },
          group: 'direction',
        },
        {
          iconCode: 59902,
          searchKey: {
            zhCN: '双箭头, 上, 向上, 三角形, 方向',
            enUS: 'Double arrow, up, up, triangle, direction',
            zhSpell: 'shuangjiantou, shang, xiangshang, sanjiaoxing, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59903,
          searchKey: {
            zhCN: '双箭头, 上, 向上, 三角形, 方向',
            enUS: 'Double arrow, up, up, triangle, direction',
            zhSpell: 'shuangjiantou, shang, xiangshang, sanjiaoxing, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59904,
          searchKey: {
            zhCN: '箭头, 上下扩展, 分类, 三角形, 上下',
            enUS: 'Arrow, up and down expansion, classification, triangle, up and down',
            zhSpell: 'jiantou, shangxiakuozhan, fenlei, sanjiaoxing, shangxia',
          },
          group: 'direction',
        },
        {
          iconCode: 59905,
          searchKey: {
            zhCN: '箭头, 向右, 后退, 下一首, 三角形',
            enUS: 'Arrow, right, back, next, triangle',
            zhSpell: 'jiantou, xiangyou, houtui, xiayishou, sanjiaoxing',
          },
          group: 'direction',
        },
        {
          iconCode: 59906,
          searchKey: {
            zhCN: '箭头, 上下扩展, 分类, 三角形, 上下',
            enUS: 'Arrow, up and down expansion, classification, triangle, up and down',
            zhSpell: 'jiantou, shangxiakuozhan, fenlei, sanjiaoxing, shangxia',
          },
          group: 'direction',
        },
        {
          iconCode: 59907,
          searchKey: {
            zhCN: '双箭头, 下, 向下, 三角形, 方向',
            enUS: 'Double arrow, down, down, triangle, direction',
            zhSpell: 'shuangjiantou, xia, xiangxia, sanjiaoxing, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59908,
          searchKey: {
            zhCN: '双箭头, 下, 向下, 三角形, 方向',
            enUS: 'Double arrow, down, down, triangle, direction',
            zhSpell: 'shuangjiantou, xia, xiangxia, sanjiaoxing, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59909,
          searchKey: {
            zhCN: '双箭头, 向右, 下一曲, 快进, 三角形, 方向',
            enUS: 'Double arrow, right, next song, fast forward, triangle, direction',
            zhSpell: 'shuangjiantou, xiangyou, xiayiqu, kuaijin, sanjiaoxing, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59910,
          searchKey: {
            zhCN: '箭头, 左, 向左, 指示, 向前, 方向',
            enUS: 'Arrow, left, left, indication, forward, direction',
            zhSpell: 'jiantou, zuo, xiangzuo, zhishi, xiangqian, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59911,
          searchKey: {
            zhCN: '双箭头, 向左, 上一曲, 快退, 三角形, 方向',
            enUS: 'Double arrow, left, last track, rewind, triangle, direction',
            zhSpell: 'shuangjiantou, xiangzuo, shangyiqu, kuaitui, sanjiaoxing, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59912,
          searchKey: {
            zhCN: '箭头, 右, 向右, 指示, 向后, 方向',
            enUS: 'Arrow, right, right, indication, backward, direction',
            zhSpell: 'jiantou, you, xiangyou, zhishi, xianghou, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59913,
          searchKey: {
            zhCN: '箭头, 右, 向右, 指示, 向后, 方向',
            enUS: 'Arrow, right, right, indication, backward, direction',
            zhSpell: 'jiantou, you, xiangyou, zhishi, xianghou, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59914,
          searchKey: {
            zhCN: '箭头, 左, 向左, 指示, 向前, 方向',
            enUS: 'Arrow, left, left, indication, forward, direction',
            zhSpell: 'jiantou, zuo, xiangzuo, zhishi, xiangqian, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59915,
          searchKey: {
            zhCN: '箭头, 上, 向上, 上传, 方向',
            enUS: 'Arrow, up, up, upload, direction',
            zhSpell: 'jiantou, shang, xiangshang, shangchuan, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59916,
          searchKey: {
            zhCN: '双箭头, 向左, 上一曲, 快退, 三角形, 方向',
            enUS: 'Double arrow, left, last track, rewind, triangle, direction',
            zhSpell: 'shuangjiantou, xiangzuo, shangyiqu, kuaitui, sanjiaoxing, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59917,
          searchKey: {
            zhCN: '双箭头, 向右, 下一曲, 快进, 三角形, 方向',
            enUS: 'Double arrow, right, next song, fast forward, triangle, direction',
            zhSpell: 'shuangjiantou, xiangyou, xiayiqu, kuaijin, sanjiaoxing, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59918,
          searchKey: {
            zhCN: '导出, 下, 向下, 下载, 箭头',
            enUS: 'Export, down, down, download, arrow',
            zhSpell: 'daochu, xia, xiangxia, xiazai, jiantou',
          },
          group: 'direction',
        },
        {
          iconCode: 59919,
          searchKey: {
            zhCN: '导出, 下, 向下, 下载, 箭头',
            enUS: 'Export, down, down, download, arrow',
            zhSpell: 'daochu, xia, xiangxia, xiazai, jiantou',
          },
          group: 'direction',
        },
        {
          iconCode: 59920,
          searchKey: {
            zhCN: '箭头, 上, 向上, 上传, 方向',
            enUS: 'Arrow, up, up, upload, direction',
            zhSpell: 'jiantou, shang, xiangshang, shangchuan, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59921,
          searchKey: {
            zhCN: '箭头, 右, 向右, 返回, 恢复',
            enUS: 'Arrow, right, right, back, recovery',
            zhSpell: 'jiantou, you, xiangyou, fanhui, huifu',
          },
          group: 'direction',
        },
        {
          iconCode: 59922,
          searchKey: {
            zhCN: '箭头, 上, 向上, 方向, 圆圈, 按钮',
            enUS: 'Arrow, up, up, direction, circle, button',
            zhSpell: 'jiantou, shang, xiangshang, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59923,
          searchKey: {
            zhCN: '箭头, 左, 向左, 向前, 撤销',
            enUS: 'Arrow, left, left, forward, revoke',
            zhSpell: 'jiantou, zuo, xiangzuo, xiangqian, chexiao',
          },
          group: 'direction',
        },
        {
          iconCode: 59924,
          searchKey: {
            zhCN: '箭头, 右, 向右, 返回, 恢复',
            enUS: 'Arrow, right, right, back, recovery',
            zhSpell: 'jiantou, you, xiangyou, fanhui, huifu',
          },
          group: 'direction',
        },
        {
          iconCode: 59925,
          searchKey: {
            zhCN: '箭头, 上, 向上, 方向, 圆圈, 按钮',
            enUS: 'Arrow, up, up, direction, circle, button',
            zhSpell: 'jiantou, shang, xiangshang, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59926,
          searchKey: {
            zhCN: '箭头, 左, 向左, 向前, 撤销',
            enUS: 'Arrow, left, left, forward, revoke',
            zhSpell: 'jiantou, zuo, xiangzuo, xiangqian, chexiao',
          },
          group: 'direction',
        },
        {
          iconCode: 59927,
          searchKey: {
            zhCN: '箭头, 下, 向下, 方向, 圆圈, 按钮',
            enUS: 'Arrow, down, down, direction, circle, button',
            zhSpell: 'jiantou, xia, xiangxia, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59928,
          searchKey: {
            zhCN: '箭头, 下, 向下, 方向, 圆圈, 按钮',
            enUS: 'Arrow, down, down, direction, circle, button',
            zhSpell: 'jiantou, xia, xiangxia, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59929,
          searchKey: {
            zhCN: '箭头, 左, 向左, 方向, 圆圈, 按钮',
            enUS: 'Arrow, left, left, direction, circle, button',
            zhSpell: 'jiantou, zuo, xiangzuo, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59930,
          searchKey: {
            zhCN: '箭头, 右, 向右, 方向, 圆圈, 按钮',
            enUS: 'Arrow, right, right, direction, circle, button',
            zhSpell: 'jiantou, you, xiangyou, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59931,
          searchKey: {
            zhCN: '箭头, 右, 向右, 方向, 圆圈, 按钮',
            enUS: 'Arrow, right, right, direction, circle, button',
            zhSpell: 'jiantou, you, xiangyou, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59932,
          searchKey: {
            zhCN: '箭头, 左, 向左, 方向, 圆圈, 按钮',
            enUS: 'Arrow, left, left, direction, circle, button',
            zhSpell: 'jiantou, zuo, xiangzuo, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59933,
          searchKey: {
            zhCN: '双箭头, 上, 向上, 方向, 圆圈, 按钮',
            enUS: 'Double arrow, up, up, direction, circle, button',
            zhSpell: 'shuangjiantou, shang, xiangshang, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59934,
          searchKey: {
            zhCN: '双箭头, 下, 向下, 方向, 圆圈, 按钮',
            enUS: 'Double arrow, down, down, direction, circle, button',
            zhSpell: 'shuangjiantou, xia, xiangxia, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59935,
          searchKey: {
            zhCN: '双箭头, 上, 向上, 方向, 圆圈, 按钮',
            enUS: 'Double arrow, up, up, direction, circle, button',
            zhSpell: 'shuangjiantou, shang, xiangshang, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59936,
          searchKey: {
            zhCN: '双箭头, 下, 向下, 方向, 圆圈, 按钮',
            enUS: 'Double arrow, down, down, direction, circle, button',
            zhSpell: 'shuangjiantou, xia, xiangxia, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59937,
          searchKey: {
            zhCN: '双箭头, 左, 向左, 方向, 圆圈, 按钮',
            enUS: 'Double arrow, left, left, direction, circle, button',
            zhSpell: 'shuangjiantou, zuo, xiangzuo, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59938,
          searchKey: {
            zhCN: '双箭头, 左, 向左, 方向, 圆圈, 按钮',
            enUS: 'Double arrow, left, left, direction, circle, button',
            zhSpell: 'shuangjiantou, zuo, xiangzuo, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59939,
          searchKey: {
            zhCN: '箭头, 上, 向上, 方向, 圆圈, 按钮',
            enUS: 'Arrow, up, up, direction, circle, button',
            zhSpell: 'jiantou, shang, xiangshang, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59940,
          searchKey: {
            zhCN: '双箭头, 右, 向右, 方向, 圆圈, 按钮',
            enUS: 'Double arrow, right, right, direction, circle, button',
            zhSpell: 'shuangjiantou, you, xiangyou, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59941,
          searchKey: {
            zhCN: '箭头, 下, 向下, 方向, 圆圈, 按钮',
            enUS: 'Arrow, down, down, direction, circle, button',
            zhSpell: 'jiantou, xia, xiangxia, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59942,
          searchKey: {
            zhCN: '双箭头, 右, 向右, 方向, 圆圈, 按钮',
            enUS: 'Double arrow, right, right, direction, circle, button',
            zhSpell: 'shuangjiantou, you, xiangyou, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59943,
          searchKey: {
            zhCN: '箭头, 上, 向上, 方向, 圆圈, 按钮',
            enUS: 'Arrow, up, up, direction, circle, button',
            zhSpell: 'jiantou, shang, xiangshang, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59944,
          searchKey: {
            zhCN: '箭头, 右, 向右, 方向, 圆圈, 按钮',
            enUS: 'Arrow, right, right, direction, circle, button',
            zhSpell: 'jiantou, you, xiangyou, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59945,
          searchKey: {
            zhCN: '箭头, 下, 向下, 方向, 圆圈, 按钮',
            enUS: 'Arrow, down, down, direction, circle, button',
            zhSpell: 'jiantou, xia, xiangxia, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59946,
          searchKey: {
            zhCN: '箭头, 右, 向右, 方向, 圆圈, 按钮',
            enUS: 'Arrow, right, right, direction, circle, button',
            zhSpell: 'jiantou, you, xiangyou, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59947,
          searchKey: {
            zhCN: '箭头, 左, 向左, 方向, 圆圈, 按钮',
            enUS: 'Arrow, left, left, direction, circle, button',
            zhSpell: 'jiantou, zuo, xiangzuo, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59948,
          searchKey: {
            zhCN: '箭头, 左, 向左, 方向, 圆圈, 按钮',
            enUS: 'Arrow, left, left, direction, circle, button',
            zhSpell: 'jiantou, zuo, xiangzuo, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59949,
          searchKey: {
            zhCN: '箭头, 上, 向上, 方向, 圆圈, 按钮',
            enUS: 'Arrow, up, up, direction, circle, button',
            zhSpell: 'jiantou, shang, xiangshang, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59950,
          searchKey: {
            zhCN: '箭头, 右, 向右, 方向, 圆圈, 按钮',
            enUS: 'Arrow, right, right, direction, circle, button',
            zhSpell: 'jiantou, you, xiangyou, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59951,
          searchKey: {
            zhCN: '箭头, 上, 向上, 方向, 圆圈, 按钮',
            enUS: 'Arrow, up, up, direction, circle, button',
            zhSpell: 'jiantou, shang, xiangshang, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59952,
          searchKey: {
            zhCN: '箭头, 下, 向下, 方向, 圆圈, 按钮',
            enUS: 'Arrow, down, down, direction, circle, button',
            zhSpell: 'jiantou, xia, xiangxia, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59953,
          searchKey: {
            zhCN: '箭头, 右, 向右, 方向, 圆圈, 按钮',
            enUS: 'Arrow, right, right, direction, circle, button',
            zhSpell: 'jiantou, you, xiangyou, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59954,
          searchKey: {
            zhCN: '箭头, 下, 向下, 方向, 圆圈, 按钮',
            enUS: 'Arrow, down, down, direction, circle, button',
            zhSpell: 'jiantou, xia, xiangxia, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59955,
          searchKey: {
            zhCN: '箭头, 左, 向左, 方向, 圆圈, 按钮',
            enUS: 'Arrow, left, left, direction, circle, button',
            zhSpell: 'jiantou, zuo, xiangzuo, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59956,
          searchKey: {
            zhCN: '箭头, 左, 向左, 方向, 圆圈, 按钮',
            enUS: 'Arrow, left, left, direction, circle, button',
            zhSpell: 'jiantou, zuo, xiangzuo, fangxiang, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59957,
          searchKey: {
            zhCN: '箭头, 左右, 交换, 扩展, 分离, 圆圈, 按钮',
            enUS: 'Arrow, left and right, exchange, expansion, separation, circle, button',
            zhSpell: 'jiantou, zuoyou, jiaohuan, kuozhan, fenli, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59958,
          searchKey: {
            zhCN: '箭头, 左右, 交换, 扩展, 分离, 圆圈, 按钮',
            enUS: 'Arrow, left and right, exchange, expansion, separation, circle, button',
            zhSpell: 'jiantou, zuoyou, jiaohuan, kuozhan, fenli, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59959,
          searchKey: {
            zhCN: '箭头, 向左, 前进, 上一首, 三角形, 圆圈, 按钮',
            enUS: 'Arrow, left, forward, previous, triangle, circle, button',
            zhSpell: 'jiantou, xiangzuo, qianjin, shangyishou, sanjiaoxing, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59960,
          searchKey: {
            zhCN: '箭头, 向右, 后退, 下一首, 三角形, 圆圈, 按钮',
            enUS: 'Arrow, right, back, next, triangle, circle, button',
            zhSpell: 'jiantou, xiangyou, houtui, xiayishou, sanjiaoxing, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59961,
          searchKey: {
            zhCN: '箭头, 向左, 前进, 上一首, 三角形, 圆圈, 按钮',
            enUS: 'Arrow, left, forward, previous, triangle, circle, button',
            zhSpell: 'jiantou, xiangzuo, qianjin, shangyishou, sanjiaoxing, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59962,
          searchKey: {
            zhCN: '箭头, 右左, 交换, 扩展, 分离, 圆圈, 按钮',
            enUS: 'Arrow, right and left, exchange, expansion, separation, circle, button',
            zhSpell: 'jiantou, youzuo, jiaohuan, kuozhan, fenli, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59963,
          searchKey: {
            zhCN: '箭头, 下, 向下, 方向, 方形, 按钮',
            enUS: 'Arrow, down, down, direction, square, button',
            zhSpell: 'jiantou, xia, xiangxia, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59964,
          searchKey: {
            zhCN: '箭头, 下, 向下, 方向, 方形, 按钮',
            enUS: 'Arrow, down, down, direction, square, button',
            zhSpell: 'jiantou, xia, xiangxia, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59965,
          searchKey: {
            zhCN: '导入, 下, 向下, 下载, 箭头',
            enUS: 'Import, down, down, download, arrow',
            zhSpell: 'daoru, xia, xiangxia, xiazai, jiantou',
          },
          group: 'direction',
        },
        {
          iconCode: 59966,
          searchKey: {
            zhCN: '导入, 下, 向下, 下载, 箭头',
            enUS: 'Import, down, down, download, arrow',
            zhSpell: 'daoru, xia, xiangxia, xiazai, jiantou',
          },
          group: 'direction',
        },
        {
          iconCode: 59967,
          searchKey: {
            zhCN: '箭头, 上, 向上, 方向, 方形, 按钮',
            enUS: 'Arrow, up, up, direction, square, button',
            zhSpell: 'jiantou, shang, xiangshang, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59968,
          searchKey: {
            zhCN: '箭头, 恢复, 刷新, 循环, 圆圈',
            enUS: 'Arrow, recovery, refresh, loop, circle',
            zhSpell: 'jiantou, huifu, shuaxin, xunhuan, yuanquan',
          },
          group: 'direction',
        },
        {
          iconCode: 59969,
          searchKey: {
            zhCN: '箭头, 上, 向上, 方向, 方形, 按钮',
            enUS: 'Arrow, up, up, direction, square, button',
            zhSpell: 'jiantou, shang, xiangshang, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59970,
          searchKey: {
            zhCN: '箭头, 左, 左转, 三角形, 路标',
            enUS: 'Arrow, left, left turn, triangle, road sign',
            zhSpell: 'jiantou, zuo, zuozhuan, sanjiaoxing, lubiao',
          },
          group: 'direction',
        },
        {
          iconCode: 59971,
          searchKey: {
            zhCN: '箭头, 右, 右转, 三角形, 路标',
            enUS: 'Arrow, right, right turn, triangle, road sign',
            zhSpell: 'jiantou, you, youzhuan, sanjiaoxing, lubiao',
          },
          group: 'direction',
        },
        {
          iconCode: 59972,
          searchKey: {
            zhCN: '箭头, 左, 左转, 三角形, 路标',
            enUS: 'Arrow, left, left turn, triangle, road sign',
            zhSpell: 'jiantou, zuo, zuozhuan, sanjiaoxing, lubiao',
          },
          group: 'direction',
        },
        {
          iconCode: 59973,
          searchKey: {
            zhCN: '导出, 右, 向右, 箭头, 退出',
            enUS: 'Export, right, right, arrow, exit',
            zhSpell: 'daochu, you, xiangyou, jiantou, tuichu',
          },
          group: 'direction',
        },
        {
          iconCode: 59974,
          searchKey: {
            zhCN: '箭头, 向上, 上升, 方向, 三角形, 圆圈, 按钮',
            enUS: 'Arrow, up, rising, direction, triangle, circle, button',
            zhSpell: 'jiantou, xiangshang, shangsheng, fangxiang, sanjiaoxing, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59975,
          searchKey: {
            zhCN: '箭头, 向下, 下降, 方向, 三角形, 圆圈, 按钮',
            enUS: 'Arrow, down, drop, direction, triangle, circle, button',
            zhSpell: 'jiantou, xiangxia, xiajiang, fangxiang, sanjiaoxing, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59976,
          searchKey: {
            zhCN: '箭头, 向下, 下降, 方向, 三角形, 圆圈, 按钮',
            enUS: 'Arrow, down, drop, direction, triangle, circle, button',
            zhSpell: 'jiantou, xiangxia, xiajiang, fangxiang, sanjiaoxing, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59977,
          searchKey: {
            zhCN: '箭头, 向右, 后退, 下一首, 三角形, 圆圈, 按钮',
            enUS: 'Arrow, right, back, next, triangle, circle, button',
            zhSpell: 'jiantou, xiangyou, houtui, xiayishou, sanjiaoxing, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59978,
          searchKey: {
            zhCN: '箭头, 右, 向右, 方向, 方形, 按钮',
            enUS: 'Arrow, right, right, direction, square, button',
            zhSpell: 'jiantou, you, xiangyou, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59979,
          searchKey: {
            zhCN: '箭头, 右, 向右, 方向, 方形, 按钮',
            enUS: 'Arrow, right, right, direction, square, button',
            zhSpell: 'jiantou, you, xiangyou, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59980,
          searchKey: {
            zhCN: '箭头, 向上, 上升, 方向, 三角形, 圆圈, 按钮',
            enUS: 'Arrow, up, rising, direction, triangle, circle, button',
            zhSpell: 'jiantou, xiangshang, shangsheng, fangxiang, sanjiaoxing, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59981,
          searchKey: {
            zhCN: '箭头, 左, 向左, 方向, 方形, 按钮',
            enUS: 'Arrow, left, left, direction, square, button',
            zhSpell: 'jiantou, zuo, xiangzuo, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59982,
          searchKey: {
            zhCN: '箭头, 上, 向上, 方向, 方形, 按钮',
            enUS: 'Arrow, up, up, direction, square, button',
            zhSpell: 'jiantou, shang, xiangshang, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59983,
          searchKey: {
            zhCN: '箭头, 下, 向下, 方向, 方形, 按钮',
            enUS: 'Arrow, down, down, direction, square, button',
            zhSpell: 'jiantou, xia, xiangxia, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59984,
          searchKey: {
            zhCN: '箭头, 上, 向上, 方向, 方形, 按钮',
            enUS: 'Arrow, up, up, direction, square, button',
            zhSpell: 'jiantou, shang, xiangshang, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59985,
          searchKey: {
            zhCN: '箭头, 左, 向左, 方向, 方形, 按钮',
            enUS: 'Arrow, left, left, direction, square, button',
            zhSpell: 'jiantou, zuo, xiangzuo, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 59986,
          searchKey: {
            zhCN: '箭头, 上, 向上, 置顶, 回到顶部, 出库',
            enUS: 'Arrow, up, up, top, back to top, out of the library',
            zhSpell: 'jiantou, shang, xiangshang, zhiding, huidaodingbu, chuku',
          },
          group: 'direction',
        },
        {
          iconCode: 59987,
          searchKey: {
            zhCN: '箭头, 下, 向下, 置底, 方向, 下载, 入库',
            enUS: 'Arrow, down, down, down, direction, download, put in storage',
            zhSpell: 'jiantou, xia, xiangxia, zhidi, fangxiang, xiazai, ruku',
          },
          group: 'direction',
        },
        {
          iconCode: 59988,
          searchKey: {
            zhCN: '箭头, 右下, 向右, 向下, 方向',
            enUS: 'Arrow, right, right, down, direction',
            zhSpell: 'jiantou, youxia, xiangyou, xiangxia, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59989,
          searchKey: {
            zhCN: '导出, 上, 向上, 上传, 方向',
            enUS: 'Export, upward, upload, the direction',
            zhSpell: 'daochu, shang, xiangshang, shangchuan, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59990,
          searchKey: {
            zhCN: '箭头, 右下, 向右, 向下, 方向',
            enUS: 'Arrow, right, right, down, direction',
            zhSpell: 'jiantou, youxia, xiangyou, xiangxia, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59991,
          searchKey: {
            zhCN: '箭头, 发布, 分享, 转发, 导出',
            enUS: 'Forward arrows, publish, share, and export',
            zhSpell: 'jiantou, fabu, fenxiang, zhuanfa, daochu',
          },
          group: 'direction',
        },
        {
          iconCode: 59992,
          searchKey: {
            zhCN: '箭头, 下, 向下, 置底, 方向, 下载, 入库',
            enUS: 'Arrow, down, down, down, direction, download, put in storage',
            zhSpell: 'jiantou, xia, xiangxia, zhidi, fangxiang, xiazai, ruku',
          },
          group: 'direction',
        },
        {
          iconCode: 59993,
          searchKey: {
            zhCN: '导出, 上, 向上, 上传, 方向',
            enUS: 'Export, upward, upload, the direction',
            zhSpell: 'daochu, shang, xiangshang, shangchuan, fangxiang',
          },
          group: 'direction',
        },
        {
          iconCode: 59994,
          searchKey: {
            zhCN: '路牌, 右左, 方向, 路标, 指引',
            enUS: 'Street, left and right direction, road signs, guidance',
            zhSpell: 'lupai, youzuo, fangxiang, lubiao, zhiyin',
          },
          group: 'direction',
        },
        {
          iconCode: 59995,
          searchKey: {
            zhCN: '路牌, 向右, 方向, 路标, 指引',
            enUS: 'Street, to the right, the direction, road signs, guide',
            zhSpell: 'lupai, xiangyou, fangxiang, lubiao, zhiyin',
          },
          group: 'direction',
        },
        {
          iconCode: 59996,
          searchKey: {
            zhCN: '路牌, 右左, 方向, 路标, 指引',
            enUS: 'Street, left and right direction, road signs, guidance',
            zhSpell: 'lupai, youzuo, fangxiang, lubiao, zhiyin',
          },
          group: 'direction',
        },
        {
          iconCode: 59997,
          searchKey: {
            zhCN: '箭头, 右, 右转, 三角形, 路标',
            enUS: 'Arrow, right, right turn, triangle, road sign',
            zhSpell: 'jiantou, you, youzhuan, sanjiaoxing, lubiao',
          },
          group: 'direction',
        },
        {
          iconCode: 59998,
          searchKey: {
            zhCN: '导入, 右, 向右, 箭头, 进入',
            enUS: 'Import, right, right, arrow, enter',
            zhSpell: 'daoru, you, xiangyou, jiantou, jinru',
          },
          group: 'direction',
        },
        {
          iconCode: 59999,
          searchKey: {
            zhCN: '箭头, 右左, 交换, 扩展, 分离, 圆圈, 按钮',
            enUS: 'Arrow, right and left, exchange, expansion, separation, circle, button',
            zhSpell: 'jiantou, youzuo, jiaohuan, kuozhan, fenli, yuanquan, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 60000,
          searchKey: {
            zhCN: '路牌, 向右, 方向, 路标, 指引',
            enUS: 'Street, to the right, the direction, road signs, guide',
            zhSpell: 'lupai, xiangyou, fangxiang, lubiao, zhiyin',
          },
          group: 'direction',
        },
        {
          iconCode: 60001,
          searchKey: {
            zhCN: '箭头, 左, 向左, 方向, 方形, 按钮',
            enUS: 'Arrow, left, left, direction, square, button',
            zhSpell: 'jiantou, zuo, xiangzuo, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 60002,
          searchKey: {
            zhCN: '箭头, 左, 向左, 方向, 方形, 按钮',
            enUS: 'Arrow, left, left, direction, square, button',
            zhSpell: 'jiantou, zuo, xiangzuo, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 60003,
          searchKey: {
            zhCN: '箭头, 上, 向上, 置顶, 回到顶部, 出库',
            enUS: 'Arrow, up, up, top, back to top, out of the library',
            zhSpell: 'jiantou, shang, xiangshang, zhiding, huidaodingbu, chuku',
          },
          group: 'direction',
        },
        {
          iconCode: 60004,
          searchKey: {
            zhCN: '箭头, 右, 向右, 方向, 方形, 按钮',
            enUS: 'Arrow, right, right, direction, square, button',
            zhSpell: 'jiantou, you, xiangyou, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 60005,
          searchKey: {
            zhCN: '箭头, 右, 向右, 方向, 方形, 按钮',
            enUS: 'Arrow, right, right, direction, square, button',
            zhSpell: 'jiantou, you, xiangyou, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
        {
          iconCode: 60006,
          searchKey: {
            zhCN: '箭头, 下, 向下, 方向, 方形, 按钮',
            enUS: 'Arrow, down, down, direction, square, button',
            zhSpell: 'jiantou, xia, xiangxia, fangxiang, fangxing, anniu',
          },
          group: 'direction',
        },
      ],
    },
    {
      name: i18n('resource.icons.media'),
      id: 'media',
      items: [
        {
          iconCode: 59717,
          searchKey: {
            zhCN: '播放方式, 单曲循环, 单曲, 圈, 箭头, 顺时针, 数字',
            enUS: 'Play mode, single cycle, single, circle, arrows, clockwise, Numbers',
            zhSpell: 'bofangfangshi, danquxunhuan, danqu, quan, jiantou, shunshizhen, shuzi',
          },
          group: 'media',
        },
        {
          iconCode: 59718,
          searchKey: {
            zhCN: '播放器, 音浪, 音频, 音波, 声音',
            enUS: 'Player, sound waves, audio, sonic, sound',
            zhSpell: 'bofangqi, yinlang, yinpin, yinbo, shengyin',
          },
          group: 'media',
        },
        {
          iconCode: 59719,
          searchKey: {
            zhCN: '播放方式, 随机, 播放, 混合, 交叉, 箭头',
            enUS: 'Play mode, random, playback, mixed, crossing, arrow',
            zhSpell: 'bofangfangshi, suiji, bofang, hunhe, jiaocha, jiantou',
          },
          group: 'media',
        },
        {
          iconCode: 59720,
          searchKey: {
            zhCN: '播放方式, 循环, 播放, 圈, 刷新, 顺时针',
            enUS: 'Broadcast, circulation, playback, circle, refresh, clockwise',
            zhSpell: 'bofangfangshi, xunhuan, bofang, quan, shuaxin, shunshizhen',
          },
          group: 'media',
        },
        {
          iconCode: 59721,
          searchKey: {
            zhCN: '播放方式, 顺序, 播放, 右, 双箭头',
            enUS: 'Broadcast way, order, broadcast, right, the double arrow',
            zhSpell: 'bofangfangshi, shunxu, bofang, you, shuangjiantou',
          },
          group: 'media',
        },
        {
          iconCode: 59722,
          searchKey: {
            zhCN: '播放方式, 循环点, 箭头, 圈, 无限',
            enUS: 'Play mode, circulation, arrows, circles, infinite',
            zhSpell: 'bofangfangshi, xunhuandian, jiantou, quan, wuxian',
          },
          group: 'media',
        },
        {
          iconCode: 59723,
          searchKey: {
            zhCN: '播放方式, 随机, 播放, 混合, 交叉, 箭头',
            enUS: 'Play mode, random, playback, mixed, crossing, arrow',
            zhSpell: 'bofangfangshi, suiji, bofang, hunhe, jiaocha, jiantou',
          },
          group: 'media',
        },
        {
          iconCode: 59724,
          searchKey: {
            zhCN: '播放器, 播放, 上一首, 上一曲, 箭头, 左',
            enUS: 'Player, play, poem, a song, arrows, to the left',
            zhSpell: 'bofangqi, bofang, shangyishou, shangyiqu, jiantou, zuo',
          },
          group: 'media',
        },
        {
          iconCode: 59725,
          searchKey: {
            zhCN: '播放器, 播放, 下一首, 下一曲, 箭头, 右',
            enUS: 'Player, play, song, the next song, arrow, right',
            zhSpell: 'bofangqi, bofang, xiayishou, xiayiqu, jiantou, you',
          },
          group: 'media',
        },
        {
          iconCode: 59726,
          searchKey: {
            zhCN: '播放器, 快退, 上一首, 上一曲, 双箭头, 左',
            enUS: 'Player, retreat quickly, a song, a song, a double arrow, to the left',
            zhSpell: 'bofangqi, kuaitui, shangyishou, shangyiqu, shuangjiantou, zuo',
          },
          group: 'media',
        },
        {
          iconCode: 59727,
          searchKey: {
            zhCN: '播放器, 暂停, 停止, 音乐, 视频, 媒体',
            enUS: 'Player, pause, stop, music, video, media',
            zhSpell: 'bofangqi, zanting, tingzhi, yinyue, shipin, meiti',
          },
          group: 'media',
        },
        {
          iconCode: 59728,
          searchKey: {
            zhCN: '播放器, 快进, 下一首, 下一曲, 双箭头, 右',
            enUS: 'Player, fast forward, next, next, double arrow, right',
            zhSpell: 'bofangqi, kuaijin, xiayishou, xiayiqu, shuangjiantou, you',
          },
          group: 'media',
        },
        {
          iconCode: 59729,
          searchKey: {
            zhCN: '播放器, 快退, 上一首, 上一曲, 双箭头, 左',
            enUS: 'Player, retreat quickly, a song, a song, a double arrow, to the left',
            zhSpell: 'bofangqi, kuaitui, shangyishou, shangyiqu, shuangjiantou, zuo',
          },
          group: 'media',
        },
        {
          iconCode: 59730,
          searchKey: {
            zhCN: '播放器, 播放, 下一首, 下一曲, 箭头, 右',
            enUS: 'Player, play, song, the next song, arrow, right',
            zhSpell: 'bofangqi, bofang, xiayishou, xiayiqu, jiantou, you',
          },
          group: 'media',
        },
        {
          iconCode: 59731,
          searchKey: {
            zhCN: '播放器, 播放, 下一首, 下一曲, 箭头, 右',
            enUS: 'Player, play, song, the next song, arrow, right',
            zhSpell: 'bofangqi, bofang, xiayishou, xiayiqu, jiantou, you',
          },
          group: 'media',
        },
        {
          iconCode: 59732,
          searchKey: {
            zhCN: '播放器, 播放, 下一首, 下一曲, 箭头, 右',
            enUS: 'Player, play, song, the next song, arrow, right',
            zhSpell: 'bofangqi, bofang, xiayishou, xiayiqu, jiantou, you',
          },
          group: 'media',
        },
        {
          iconCode: 59733,
          searchKey: {
            zhCN: '播放器, 播放, 上一首, 上一曲, 箭头, 左',
            enUS: 'Player, play, poem, a song, arrows, to the left',
            zhSpell: 'bofangqi, bofang, shangyishou, shangyiqu, jiantou, zuo',
          },
          group: 'media',
        },
        {
          iconCode: 59734,
          searchKey: {
            zhCN: '播放器, 播放, 上一首, 上一曲, 箭头, 左, 按钮',
            enUS: 'Player, play, poem, a song, arrows, the left button',
            zhSpell: 'bofangqi, bofang, shangyishou, shangyiqu, jiantou, zuo, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59735,
          searchKey: {
            zhCN: '播放器, 快进, 下一首, 下一曲, 箭头, 按钮',
            enUS: 'Player, fast forward, next, next, arrows, button',
            zhSpell: 'bofangqi, kuaijin, xiayishou, xiayiqu, jiantou, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59736,
          searchKey: {
            zhCN: '播放器, 播放, 媒体, 向右, 箭头, 按钮',
            enUS: 'Player, play, the media, to the right, arrows, button',
            zhSpell: 'bofangqi, bofang, meiti, xiangyou, jiantou, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59737,
          searchKey: {
            zhCN: '播放器, 快退, 上一首, 上一曲, 箭头, 按钮',
            enUS: 'Player, retreat quickly, a song, a song, arrows, the button',
            zhSpell: 'bofangqi, kuaitui, shangyishou, shangyiqu, jiantou, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59738,
          searchKey: {
            zhCN: '播放器, 快进, 下一首, 下一曲, 箭头, 按钮',
            enUS: 'Player, fast forward, next, next, arrows, button',
            zhSpell: 'bofangqi, kuaijin, xiayishou, xiayiqu, jiantou, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59739,
          searchKey: {
            zhCN: '播放器, 快退, 上一首, 上一曲, 箭头, 按钮',
            enUS: 'Player, retreat quickly, a song, a song, arrows, the button',
            zhSpell: 'bofangqi, kuaitui, shangyishou, shangyiqu, jiantou, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59740,
          searchKey: {
            zhCN: '播放器, 播放, 媒体, 向右, 箭头, 按钮',
            enUS: 'Player, play, the media, to the right, arrows, button',
            zhSpell: 'bofangqi, bofang, meiti, xiangyou, jiantou, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59741,
          searchKey: {
            zhCN: '播放器, 快进, 下一首, 下一曲, 双箭头, 右',
            enUS: 'Player, fast forward, next, next, double arrow, right',
            zhSpell: 'bofangqi, kuaijin, xiayishou, xiayiqu, shuangjiantou, you',
          },
          group: 'media',
        },
        {
          iconCode: 59742,
          searchKey: {
            zhCN: '播放器, 播放, 上一首, 上一曲, 箭头, 左, 按钮',
            enUS: 'Player, play, poem, a song, arrows, the left button',
            zhSpell: 'bofangqi, bofang, shangyishou, shangyiqu, jiantou, zuo, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59743,
          searchKey: {
            zhCN: '播放器, 向下, 展开, 箭头, 按钮',
            enUS: 'Player, down, and the arrow button',
            zhSpell: 'bofangqi, xiangxia, zhankai, jiantou, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59744,
          searchKey: {
            zhCN: '播放器, 播放, 下一首, 下一曲, 箭头, 右, 按钮',
            enUS: 'Player, play, song, the next song, arrow, right, button',
            zhSpell: 'bofangqi, bofang, xiayishou, xiayiqu, jiantou, you, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59745,
          searchKey: {
            zhCN: '播放器, 播放, 下一首, 下一曲, 箭头, 右, 按钮',
            enUS: 'Player, play, song, the next song, arrow, right, button',
            zhSpell: 'bofangqi, bofang, xiayishou, xiayiqu, jiantou, you, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59746,
          searchKey: {
            zhCN: '播放器, 向下, 展开, 箭头, 按钮',
            enUS: 'Player, down, and the arrow button',
            zhSpell: 'bofangqi, xiangxia, zhankai, jiantou, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59747,
          searchKey: {
            zhCN: '播放器, 音浪, 音频, 音波, 声音, 按钮',
            enUS: 'Player, sound waves, audio, sonic, sound, button',
            zhSpell: 'bofangqi, yinlang, yinpin, yinbo, shengyin, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59748,
          searchKey: {
            zhCN: '播放器, 音浪, 音频, 音波, 声音, 按钮',
            enUS: 'Player, sound waves, audio, sonic, sound, button',
            zhSpell: 'bofangqi, yinlang, yinpin, yinbo, shengyin, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59749,
          searchKey: {
            zhCN: '播放器, 暂停, 停止, 音乐, 视频, 媒体, 按钮',
            enUS: 'Player, pause, stop, music, video, media, and button',
            zhSpell: 'bofangqi, zanting, tingzhi, yinyue, shipin, meiti, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59750,
          searchKey: {
            zhCN: '播放器, 向上, 收起, 箭头, 按钮',
            enUS: 'The player, up, up, arrow button',
            zhSpell: 'bofangqi, xiangshang, shouqi, jiantou, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59751,
          searchKey: {
            zhCN: '播放器, 向上, 收起, 箭头, 按钮',
            enUS: 'The player, up, up, arrow button',
            zhSpell: 'bofangqi, xiangshang, shouqi, jiantou, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59752,
          searchKey: {
            zhCN: '播放器, 光盘, 录音, 录像, 音乐, 视频, 媒体',
            enUS: 'Player, CD, sound recording, video, music, video, media',
            zhSpell: 'bofangqi, guangpan, luyin, luxiang, yinyue, shipin, meiti',
          },
          group: 'media',
        },
        {
          iconCode: 59753,
          searchKey: {
            zhCN: '播放器, 暂停, 停止, 音乐, 视频, 媒体, 按钮',
            enUS: 'Player, pause, stop, music, video, media, and button',
            zhSpell: 'bofangqi, zanting, tingzhi, yinyue, shipin, meiti, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59754,
          searchKey: {
            zhCN: '播放器, 向上, 收起, 箭头, 按钮',
            enUS: 'The player, up, up, arrow button',
            zhSpell: 'bofangqi, xiangshang, shouqi, jiantou, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59755,
          searchKey: {
            zhCN: '播放器, 暂停, 停止, 音乐, 视频, 媒体, 按钮',
            enUS: 'Player, pause, stop, music, video, media, and button',
            zhSpell: 'bofangqi, zanting, tingzhi, yinyue, shipin, meiti, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59756,
          searchKey: {
            zhCN: '播放器, 光盘, 录音, 录像, 音乐, 视频, 媒体',
            enUS: 'Player, CD, sound recording, video, music, video, media',
            zhSpell: 'bofangqi, guangpan, luyin, luxiang, yinyue, shipin, meiti',
          },
          group: 'media',
        },
        {
          iconCode: 59757,
          searchKey: {
            zhCN: '播放器, 向上, 收起, 箭头, 按钮',
            enUS: 'Player, up, put away, arrow, button',
            zhSpell: 'bofangqi, xiangshang, shouqi, jiantou, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59758,
          searchKey: {
            zhCN: '播放器, 暂停, 停止, 音乐, 视频, 媒体, 按钮',
            enUS: 'Player, pause, stop, music, video, media, and button',
            zhSpell: 'bofangqi, zanting, tingzhi, yinyue, shipin, meiti, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59759,
          searchKey: {
            zhCN: '播放器, 语音, 声音, 音量, 声波, 按钮',
            enUS: 'Player, voice, sound, volume, sound waves, button',
            zhSpell: 'bofangqi, yuyin, shengyin, yinliang, shengbo, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59760,
          searchKey: {
            zhCN: '播放器, 语音, 声音, 音量, 声波, 按钮',
            enUS: 'Player, voice, sound, volume, sound waves, button',
            zhSpell: 'bofangqi, yuyin, shengyin, yinliang, shengbo, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59761,
          searchKey: {
            zhCN: '播放器, 播放, 下一首, 下一曲, 箭头, 右, 按钮',
            enUS: 'Player, play, song, the next song, arrow, right, button',
            zhSpell: 'bofangqi, bofang, xiayishou, xiayiqu, jiantou, you, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59762,
          searchKey: {
            zhCN: '播放器, 向下, 展开, 箭头, 按钮',
            enUS: 'Player, down, and the arrow button',
            zhSpell: 'bofangqi, xiangxia, zhankai, jiantou, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59763,
          searchKey: {
            zhCN: '播放器, 向下, 展开, 箭头, 按钮',
            enUS: 'Player, down, and the arrow button',
            zhSpell: 'bofangqi, xiangxia, zhankai, jiantou, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59764,
          searchKey: {
            zhCN: '播放器, 播放, 上一首, 上一曲, 箭头, 左, 按钮',
            enUS: 'Player, play, poem, a song, arrows, the left button',
            zhSpell: 'bofangqi, bofang, shangyishou, shangyiqu, jiantou, zuo, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59765,
          searchKey: {
            zhCN: '电影, 胶片, 视频, 影片, 电影院, 影视',
            enUS: 'Movies, film, video, film, the cinema, the film and television',
            zhSpell: 'dianying, jiaopian, shipin, yingpian, dianyingyuan, yingshi',
          },
          group: 'media',
        },
        {
          iconCode: 59766,
          searchKey: {
            zhCN: '播放器, 播放, 上一首, 上一曲, 箭头, 左, 按钮',
            enUS: 'Player, play, poem, a song, arrows, the left button',
            zhSpell: 'bofangqi, bofang, shangyishou, shangyiqu, jiantou, zuo, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59767,
          searchKey: {
            zhCN: '电影, 胶片, 视频, 影片, 电影院, 影视',
            enUS: 'Movies, film, video, film, the cinema, the film and television',
            zhSpell: 'dianying, jiaopian, shipin, yingpian, dianyingyuan, yingshi',
          },
          group: 'media',
        },
        {
          iconCode: 59768,
          searchKey: {
            zhCN: '电影, 影片, 拍摄, 电影院, 电影胶片, 录像',
            enUS: 'Film, film, photography, cinema, film, video',
            zhSpell: 'dianying, yingpian, paishe, dianyingyuan, dianyingjiaopian, luxiang',
          },
          group: 'media',
        },
        {
          iconCode: 59769,
          searchKey: {
            zhCN: '电影, 播放电影, 电影胶带, 影片, 电影播放器',
            enUS: 'Movies, movies, film adhesive tape, film, movie player',
            zhSpell: 'dianying, bofangdianying, dianyingjiaodai, yingpian, dianyingbofangqi',
          },
          group: 'media',
        },
        {
          iconCode: 59770,
          searchKey: {
            zhCN: '电影, 播放电影, 电影胶带, 影片, 电影播放器',
            enUS: 'Movies, movies, film adhesive tape, film, movie player',
            zhSpell: 'dianying, bofangdianying, dianyingjiaodai, yingpian, dianyingbofangqi',
          },
          group: 'media',
        },
        {
          iconCode: 59771,
          searchKey: {
            zhCN: '电影, 播放, 视频, 影视, 影片, 播放器',
            enUS: 'Movie, play, video, video, video, player',
            zhSpell: 'dianying, bofang, shipin, yingshi, yingpian, bofangqi',
          },
          group: 'media',
        },
        {
          iconCode: 59772,
          searchKey: {
            zhCN: '调节器, 滑块, 控制, 设置, 面板, 平衡器',
            enUS: 'Regulator, the slider, control, setting, panel, balancer',
            zhSpell: 'tiaojieqi, huakuai, kongzhi, shezhi, mianban, pinghengqi',
          },
          group: 'media',
        },
        {
          iconCode: 59773,
          searchKey: {
            zhCN: '调节器, 滑块, 控制, 设置, 平衡器',
            enUS: 'Regulator, the slider, control, setting, balancer',
            zhSpell: 'tiaojieqi, huakuai, kongzhi, shezhi, pinghengqi',
          },
          group: 'media',
        },
        {
          iconCode: 59774,
          searchKey: {
            zhCN: '声波, 声音, 音量, 立体声, 环绕声, 信号',
            enUS: 'Sound waves, sound, volume, stereo, surround sound, signal',
            zhSpell: 'shengbo, shengyin, yinliang, litisheng, huanraosheng, xinhao',
          },
          group: 'media',
        },
        {
          iconCode: 59775,
          searchKey: {
            zhCN: '调节器, 滑块, 控制, 设置, 面板, 平衡器',
            enUS: 'Regulator, the slider, control, setting, panel, balancer',
            zhSpell: 'tiaojieqi, huakuai, kongzhi, shezhi, mianban, pinghengqi',
          },
          group: 'media',
        },
        {
          iconCode: 59776,
          searchKey: {
            zhCN: '调节器, 滑块, 控制, 设置, 平衡器',
            enUS: 'Regulator, the slider, control, setting, balancer',
            zhSpell: 'tiaojieqi, huakuai, kongzhi, shezhi, pinghengqi',
          },
          group: 'media',
        },
        {
          iconCode: 59777,
          searchKey: {
            zhCN: '麦克风, 静音, 话筒, 扩声器, 关闭, 声音',
            enUS: 'The microphone, mute, microphone, microphone, closed and voice',
            zhSpell: 'maikefeng, jingyin, huatong, kuoshengqi, guanbi, shengyin',
          },
          group: 'media',
        },
        {
          iconCode: 59778,
          searchKey: {
            zhCN: '耳机, 语音, 客服, 耳麦, 随身听',
            enUS: 'Headphones, voice, customer service, headphones, walkman',
            zhSpell: 'erji, yuyin, kefu, ermai, suishenting',
          },
          group: 'media',
        },
        {
          iconCode: 59779,
          searchKey: {
            zhCN: '麦克风, 话筒, 扩声器, 音频, 声音, 语音',
            enUS: 'The microphone, receiver, microphone, audio, voice, the voice',
            zhSpell: 'maikefeng, huatong, kuoshengqi, yinpin, shengyin, yuyin',
          },
          group: 'media',
        },
        {
          iconCode: 59780,
          searchKey: {
            zhCN: '麦克风, 话筒, 扩声器, 音频, 声音, 语音, 录音机',
            enUS: 'The microphone, receiver, microphone, audio, voice, the voice, recorder',
            zhSpell: 'maikefeng, huatong, kuoshengqi, yinpin, shengyin, yuyin, luyinji',
          },
          group: 'media',
        },
        {
          iconCode: 59781,
          searchKey: {
            zhCN: '麦克风, 话筒, 扩声器, 音频, 声音, 语音, 录音机',
            enUS: 'The microphone, receiver, microphone, audio, voice, the voice, recorder',
            zhSpell: 'maikefeng, huatong, kuoshengqi, yinpin, shengyin, yuyin, luyinji',
          },
          group: 'media',
        },
        {
          iconCode: 59782,
          searchKey: {
            zhCN: '麦克风, 话筒, 扩声器, 语音, 声音, 按钮',
            enUS: 'Microphone, receiver, microphone, audio, voice, button',
            zhSpell: 'maikefeng, huatong, kuoshengqi, yuyin, shengyin, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59783,
          searchKey: {
            zhCN: '麦克风, 话筒, 扩声器, 唱歌, 卡拉OK, 语音',
            enUS: 'Microphone, receiver, microphone, singing, karaoke, voice',
            zhSpell: 'maikefeng, huatong, kuoshengqi, changge, kalaOK, yuyin',
          },
          group: 'media',
        },
        {
          iconCode: 59784,
          searchKey: {
            zhCN: '播放器, 播放, 媒体, 向右, 箭头, 按钮',
            enUS: 'Player, play, the media, to the right, arrows, button',
            zhSpell: 'bofangqi, bofang, meiti, xiangyou, jiantou, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59785,
          searchKey: {
            zhCN: '视频, 录制, 录影, 多媒体, 录像, 设备',
            enUS: 'Video recording, video, multimedia, video, equipment',
            zhSpell: 'shipin, luzhi, luying, duomeiti, luxiang, shebei',
          },
          group: 'media',
        },
        {
          iconCode: 59786,
          searchKey: {
            zhCN: '电影, 播放, 视频, 投影仪, 影片, 演示',
            enUS: 'Film, broadcast, video, projector, film, demonstration',
            zhSpell: 'dianying, bofang, shipin, touyingyi, yingpian, yanshi',
          },
          group: 'media',
        },
        {
          iconCode: 59787,
          searchKey: {
            zhCN: '电影, 播放, 视频, 投影仪, 影片, 演示',
            enUS: 'Film, broadcast, video, projector, film, demonstration',
            zhSpell: 'dianying, bofang, shipin, touyingyi, yingpian, yanshi',
          },
          group: 'media',
        },
        {
          iconCode: 59788,
          searchKey: {
            zhCN: '电影, 播放, 视频, 影视, 影片, 播放器',
            enUS: 'Film and television, film, film, broadcast, video, player',
            zhSpell: 'dianying, bofang, shipin, yingshi, yingpian, bofangqi',
          },
          group: 'media',
        },
        {
          iconCode: 59789,
          searchKey: {
            zhCN: '视频, 录制, 录影, 多媒体, 录像, 设备',
            enUS: 'Video recording, video, multimedia, video, equipment',
            zhSpell: 'shipin, luzhi, luying, duomeiti, luxiang, shebei',
          },
          group: 'media',
        },
        {
          iconCode: 59790,
          searchKey: {
            zhCN: '视频, 录制, 录影, 多媒体, 录像, 设备',
            enUS: 'Video recording, video, multimedia, video, equipment',
            zhSpell: 'shipin, luzhi, luying, duomeiti, luxiang, shebei',
          },
          group: 'media',
        },
        {
          iconCode: 59791,
          searchKey: {
            zhCN: '麦克风, 话筒, 扩声器, 唱歌, 卡拉OK, 语音',
            enUS: 'Microphone, receiver, microphone, singing, karaoke, voice',
            zhSpell: 'maikefeng, huatong, kuoshengqi, changge, kalaOK, yuyin',
          },
          group: 'media',
        },
        {
          iconCode: 59792,
          searchKey: {
            zhCN: '视频, 播放器, 录像, 摄像机, 电影, 投影仪',
            enUS: 'Video player, video, camera, film, projector',
            zhSpell: 'shipin, bofangqi, luxiang, shexiangji, dianying, touyingyi',
          },
          group: 'media',
        },
        {
          iconCode: 59793,
          searchKey: {
            zhCN: '视频, 播放器, 录像, 摄像机, 电影, 投影仪',
            enUS: 'Video player, video, camera, film, projector',
            zhSpell: 'shipin, bofangqi, luxiang, shexiangji, dianying, touyingyi',
          },
          group: 'media',
        },
        {
          iconCode: 59794,
          searchKey: {
            zhCN: '视频, 播放器, 录像, 摄像机, 电影, 投影仪',
            enUS: 'Video player, video, camera, film, projector',
            zhSpell: 'shipin, bofangqi, luxiang, shexiangji, dianying, touyingyi',
          },
          group: 'media',
        },
        {
          iconCode: 59795,
          searchKey: {
            zhCN: '视频, 拍摄, 播放器, 录像, 摄像机, 电影, 投影仪',
            enUS: 'Video, photography, player, video, camera, film, projector',
            zhSpell: 'shipin, paishe, bofangqi, luxiang, shexiangji, dianying, touyingyi',
          },
          group: 'media',
        },
        {
          iconCode: 59796,
          searchKey: {
            zhCN: '音乐, 音符, 歌曲, 声音, 播放器',
            enUS: 'Music, notes, songs, voice, player',
            zhSpell: 'yinyue, yinfu, gequ, shengyin, bofangqi',
          },
          group: 'media',
        },
        {
          iconCode: 59797,
          searchKey: {
            zhCN: '视频, 播放器, 录像, 摄像机, 电影, 投影仪',
            enUS: 'Video player, video, camera, film, projector',
            zhSpell: 'shipin, bofangqi, luxiang, shexiangji, dianying, touyingyi',
          },
          group: 'media',
        },
        {
          iconCode: 59798,
          searchKey: {
            zhCN: '音乐, 音符, 歌曲, 声音, 播放器',
            enUS: 'Music, notes, songs, voice, player',
            zhSpell: 'yinyue, yinfu, gequ, shengyin, bofangqi',
          },
          group: 'media',
        },
        {
          iconCode: 59799,
          searchKey: {
            zhCN: '音乐, 音符, 歌曲, 声音, 播放器',
            enUS: 'Music, notes, songs, voice, player',
            zhSpell: 'yinyue, yinfu, gequ, shengyin, bofangqi',
          },
          group: 'media',
        },
        {
          iconCode: 59800,
          searchKey: {
            zhCN: '视频, 拍摄, 播放器, 录像, 摄像机, 电影, 投影仪',
            enUS: 'Video, photography, player, video, camera, film, projector',
            zhSpell: 'shipin, paishe, bofangqi, luxiang, shexiangji, dianying, touyingyi',
          },
          group: 'media',
        },
        {
          iconCode: 59801,
          searchKey: {
            zhCN: '视频, 录制, 录影, 多媒体, 录像, 设备',
            enUS: 'Video recording, video, multimedia, video, equipment',
            zhSpell: 'shipin, luzhi, luying, duomeiti, luxiang, shebei',
          },
          group: 'media',
        },
        {
          iconCode: 59802,
          searchKey: {
            zhCN: '播放器, 播放, 下一首, 下一曲, 箭头, 右, 按钮',
            enUS: 'Player, play, song, the next song, arrow, right, button',
            zhSpell: 'bofangqi, bofang, xiayishou, xiayiqu, jiantou, you, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59803,
          searchKey: {
            zhCN: '音乐, 音符, 歌曲, 声音, 播放器, 按钮',
            enUS: 'Music, notes, songs, voice, player, button',
            zhSpell: 'yinyue, yinfu, gequ, shengyin, bofangqi, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59804,
          searchKey: {
            zhCN: '音乐, 音符, 歌曲, 声音, 播放器',
            enUS: 'Music, notes, songs, voice, player',
            zhSpell: 'yinyue, yinfu, gequ, shengyin, bofangqi',
          },
          group: 'media',
        },
        {
          iconCode: 59805,
          searchKey: {
            zhCN: '音乐, 音符, 歌曲, 声音, 播放器, 按钮',
            enUS: 'Music, notes, songs, voice, player, button',
            zhSpell: 'yinyue, yinfu, gequ, shengyin, bofangqi, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59806,
          searchKey: {
            zhCN: '音乐, 音符, 歌曲, 声音, 播放器, 按钮',
            enUS: 'Music, notes, songs, voice, player, button',
            zhSpell: 'yinyue, yinfu, gequ, shengyin, bofangqi, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59807,
          searchKey: {
            zhCN: '音乐, 音符, 歌曲, 声音, 播放器, 按钮',
            enUS: 'Music, notes, songs, voice, player, button',
            zhSpell: 'yinyue, yinfu, gequ, shengyin, bofangqi, anniu',
          },
          group: 'media',
        },
        {
          iconCode: 59808,
          searchKey: {
            zhCN: '文件, 文件夹, 专辑, 唱片, 音乐, 音符, 歌曲, 声音',
            enUS: 'Files, folders, album,record, music, notes, songs, sounds',
            zhSpell: 'wenjian, wenjianjia, zhuanji, changpian, yinyue, yinfu, gequ, shengyin',
          },
          group: 'media',
        },
        {
          iconCode: 59809,
          searchKey: {
            zhCN: '文件, 文件夹, 专辑, 唱片, 音乐, 音符, 歌曲, 声音',
            enUS: 'Files, folders, album,record, music, notes, songs, sounds',
            zhSpell: 'wenjian, wenjianjia, zhuanji, changpian, yinyue, yinfu, gequ, shengyin',
          },
          group: 'media',
        },
        {
          iconCode: 59810,
          searchKey: {
            zhCN: '音量, 声音, 减少, 调音, 扬声器',
            enUS: 'Volume, sound, reduce, tuning up and the speaker',
            zhSpell: 'yinliang, shengyin, jianshao, tiaoyin, yangshengqi',
          },
          group: 'media',
        },
        {
          iconCode: 59811,
          searchKey: {
            zhCN: '静音, 声音, 音量, 调音, 扬声器, 关闭',
            enUS: 'Mute, sound, volume, tuning, speakers, shut down',
            zhSpell: 'jingyin, shengyin, yinliang, tiaoyin, yangshengqi, guanbi',
          },
          group: 'media',
        },
        {
          iconCode: 59812,
          searchKey: {
            zhCN: '音量, 声音, 减少, 调音, 扬声器',
            enUS: 'Volume, sound, reduce, tuning up and the speaker',
            zhSpell: 'yinliang, shengyin, jianshao, tiaoyin, yangshengqi',
          },
          group: 'media',
        },
        {
          iconCode: 59813,
          searchKey: {
            zhCN: '静音, 声音, 音量, 调音, 扬声器, 关闭',
            enUS: 'Mute, sound, volume, tuning, speakers, shut down',
            zhSpell: 'jingyin, shengyin, yinliang, tiaoyin, yangshengqi, guanbi',
          },
          group: 'media',
        },
        {
          iconCode: 59814,
          searchKey: {
            zhCN: '音量, 声音, 增加, 调音, 扬声器',
            enUS: 'The volume, voice, increase, tuning up and the speaker',
            zhSpell: 'yinliang, shengyin, zengjia, tiaoyin, yangshengqi',
          },
          group: 'media',
        },
        {
          iconCode: 59815,
          searchKey: {
            zhCN: '音量, 声音, 增加, 调音, 扬声器',
            enUS: 'The volume, voice, increase, tuning up and the speaker',
            zhSpell: 'yinliang, shengyin, zengjia, tiaoyin, yangshengqi',
          },
          group: 'media',
        },
        {
          iconCode: 59816,
          searchKey: {
            zhCN: '耳机, 语音, 客服, 服务, 接听',
            enUS: 'Headphones, voice, customer service, service, answer',
            zhSpell: 'erji, yuyin, kefu, fuwu, jieting',
          },
          group: 'media',
        },
        {
          iconCode: 59817,
          searchKey: {
            zhCN: '音量, 声音, 增加, 调音, 扬声器',
            enUS: 'The volume, voice, increase, tuning up and the speaker',
            zhSpell: 'yinliang, shengyin, zengjia, tiaoyin, yangshengqi',
          },
          group: 'media',
        },
        {
          iconCode: 59818,
          searchKey: {
            zhCN: '音量, 声音, 增加, 调音, 扬声器',
            enUS: 'The volume, voice, increase, tuning up and the speaker',
            zhSpell: 'yinliang, shengyin, zengjia, tiaoyin, yangshengqi',
          },
          group: 'media',
        },
        {
          iconCode: 59819,
          searchKey: {
            zhCN: '录音, 语音, 录像, 多媒体, 播放器',
            enUS: 'Recording, voice, video, multimedia, player',
            zhSpell: 'luyin, yuyin, luxiang, duomeiti, bofangqi',
          },
          group: 'media',
        },
        {
          iconCode: 59820,
          searchKey: {
            zhCN: '录音, 语音, 录像, 多媒体, 播放器',
            enUS: 'Recording, voice, video, multimedia, player',
            zhSpell: 'luyin, yuyin, luxiang, duomeiti, bofangqi',
          },
          group: 'media',
        },
        {
          iconCode: 59821,
          searchKey: {
            zhCN: '播放器, 音乐, 音频, MP3, MP4, iPod, 音箱',
            enUS: 'Player, music, audio, MP3, MP4, iPod, speakers',
            zhSpell: 'bofangqi, yinyue, yinpin, MP3, MP4, iPod, yinxiang',
          },
          group: 'media',
        },
        {
          iconCode: 59822,
          searchKey: {
            zhCN: '耳机, 语音, 客服, 服务, 接听',
            enUS: 'Headphones, voice, customer service, service, answer',
            zhSpell: 'erji, yuyin, kefu, fuwu, jieting',
          },
          group: 'media',
        },
        {
          iconCode: 59823,
          searchKey: {
            zhCN: '播放器, 音乐, 音频, MP3, MP4, iPod, 音箱',
            enUS: 'Player, music, audio, MP3, MP4, iPod, speakers',
            zhSpell: 'bofangqi, yinyue, yinpin, MP3, MP4, iPod, yinxiang',
          },
          group: 'media',
        },
      ],
    },
    {
      name: i18n('resource.icons.edit'),
      id: 'edit',
      items: [
        {
          iconCode: 59626,
          searchKey: {
            zhCN: '编组, 合并, 组, 组合, 层, 分组',
            enUS: 'Grouping, merging, group and combination, layer, grouping',
            zhSpell: 'bianzu, hebing, zu, zuhe, ceng, fenzu',
          },
          group: 'edit',
        },
        {
          iconCode: 59627,
          searchKey: {
            zhCN: '编组, 拆分, 分离, 解散组, 取消编组',
            enUS: 'Group, split, separation, dismiss, ungroup',
            zhSpell: 'bianzu, chaifen, fenli, jiansanzu, quxiaobianzu',
          },
          group: 'edit',
        },
        {
          iconCode: 59628,
          searchKey: {
            zhCN: '保存, 存储, 备份, 文件, 磁盘',
            enUS: 'Preservation, storage, backup, files, disk',
            zhSpell: 'baocun, cunchu, beifen, wenjian, cipan',
          },
          group: 'edit',
        },
        {
          iconCode: 59629,
          searchKey: {
            zhCN: '编组, 拆分, 分离, 解散组, 取消编组',
            enUS: 'Group, split, separation, dismiss, ungroup',
            zhSpell: 'bianzu, chaifen, fenli, jiansanzu, quxiaobianzu',
          },
          group: 'edit',
        },
        {
          iconCode: 59630,
          searchKey: {
            zhCN: '表单, 勾选, 列表, 检查, 完成, 核对',
            enUS: 'Forms, check, list, check, complete, check it',
            zhSpell: 'biaodan, gouxuan, liebiao, jiancha, wancheng, hedui',
          },
          group: 'edit',
        },
        {
          iconCode: 59631,
          searchKey: {
            zhCN: '列表, 编号, 菜单, 表单, 选项, 数字',
            enUS: 'List of Numbers, menus, forms, options and Numbers',
            zhSpell: 'liebiao, bianhao, caidan, biaodan, xuanxiang, shuzi',
          },
          group: 'edit',
        },
        {
          iconCode: 59632,
          searchKey: {
            zhCN: '对齐, 顶对齐, 布局, 区域, 调整',
            enUS: 'Alignment, top alignment, layout, area, adjustment',
            zhSpell: 'duiqi, dingduiqi, buju, quyu, tiaozheng',
          },
          group: 'edit',
        },
        {
          iconCode: 59633,
          searchKey: {
            zhCN: '对齐, 两端对齐, 文本, 线条, 调整文字',
            enUS: 'Both ends of alignment, alignment, text, lines, adjust the text',
            zhSpell: 'duiqi, liangduanduiqi, wenben, xiantiao, tiaozhengwenzi',
          },
          group: 'edit',
        },
        {
          iconCode: 59634,
          searchKey: {
            zhCN: '对齐, 底对齐, 布局, 区域, 调整',
            enUS: 'Alignment, bottom alignment, layout, area, adjust',
            zhSpell: 'duiqi, diduiqi, buju, quyu, tiaozheng',
          },
          group: 'edit',
        },
        {
          iconCode: 59635,
          searchKey: {
            zhCN: '对齐, 右对齐, 文本, 线条, 调整文字',
            enUS: 'Right alignment, alignment, text, line, adjust the text',
            zhSpell: 'duiqi, youduiqi, wenben, xiantiao, tiaozhengwenzi',
          },
          group: 'edit',
        },
        {
          iconCode: 59636,
          searchKey: {
            zhCN: '对齐, 水平居中对齐, 布局, 区域, 调整',
            enUS: 'Center alignment, alignment, layout, regional, adjustment',
            zhSpell: 'duiqi, shuipingjuzhongduiqi, buju, quyu, tiaozheng',
          },
          group: 'edit',
        },
        {
          iconCode: 59637,
          searchKey: {
            zhCN: '对齐, 居中对齐, 文本, 线条, 调整文字',
            enUS: 'Center alignment, alignment, text, line, adjust the text',
            zhSpell: 'duiqi, juzhongduiqi, wenben, xiantiao, tiaozhengwenzi',
          },
          group: 'edit',
        },
        {
          iconCode: 59638,
          searchKey: {
            zhCN: '对齐, 左对齐, 文本, 线条, 调整文字',
            enUS: 'Alignment, left-aligned, text, lines, adjust the text',
            zhSpell: 'duiqi, zuoduiqi, wenben, xiantiao, tiaozhengwenzi',
          },
          group: 'edit',
        },
        {
          iconCode: 59639,
          searchKey: {
            zhCN: '对齐, 垂直居中对齐, 布局, 区域, 调整',
            enUS: 'Alignment, Middle alignment, layout, area, adjustment',
            zhSpell: 'duiqi, chuizhijuzhongduiqi, buju, quyu, tiaozheng',
          },
          group: 'edit',
        },
        {
          iconCode: 59640,
          searchKey: {
            zhCN: '对齐, 右对齐, 布局, 区域, 调整',
            enUS: 'Alignment, right-aligned, layout, area, adjust',
            zhSpell: 'duiqi, youduiqi, buju, quyu, tiaozheng',
          },
          group: 'edit',
        },
        {
          iconCode: 59641,
          searchKey: {
            zhCN: '放大镜, 放大, 搜索, 查询, 加号, 查找',
            enUS: 'Magnifying glass, amplifier, search, query, plus, lookup',
            zhSpell: 'fangdajing, fangda, sousuo, chaxun, jiahao, chazhao',
          },
          group: 'edit',
        },
        {
          iconCode: 59642,
          searchKey: {
            zhCN: '对齐, 左对齐, 布局, 区域, 调整',
            enUS: 'Alignment, left-aligned, layout, area, adjust',
            zhSpell: 'duiqi, zuoduiqi, buju, quyu, tiaozheng',
          },
          group: 'edit',
        },
        {
          iconCode: 59643,
          searchKey: {
            zhCN: '放大镜, 放大, 搜索, 查询, 加号, 查找',
            enUS: 'Magnifying glass, amplifier, search, query, plus, lookup',
            zhSpell: 'fangdajing, fangda, sousuo, chaxun, jiahao, chazhao',
          },
          group: 'edit',
        },
        {
          iconCode: 59644,
          searchKey: {
            zhCN: '对齐, 左对齐, 文本, 线条, 箭头, 调整文字',
            enUS: 'Alignment, left-aligned, text, lines, arrows, adjust the text',
            zhSpell: 'duiqi, zuoduiqi, wenben, xiantiao, jiantou, tiaozhengwenzi',
          },
          group: 'edit',
        },
        {
          iconCode: 59645,
          searchKey: {
            zhCN: '放大镜, 缩小, 搜索, 查询, 减号',
            enUS: 'Magnifying glass, narrow, search, query, a minus sign',
            zhSpell: 'fangdajing, suoxiao, sousuo, chaxun, jianhao',
          },
          group: 'edit',
        },
        {
          iconCode: 59646,
          searchKey: {
            zhCN: '对齐, 行距, 文本, 扩展, 箭头',
            enUS: 'Alignment, spacing, text, extension, arrow',
            zhSpell: 'duiqi, xingju, wenben, kuozhan, jiantou',
          },
          group: 'edit',
        },
        {
          iconCode: 59647,
          searchKey: {
            zhCN: '放大镜, 放大, 搜索, 查询, 查找',
            enUS: 'A magnifying glass, amplification, search, query and search',
            zhSpell: 'fangdajing, fangda, sousuo, chaxun, chazhao',
          },
          group: 'edit',
        },
        {
          iconCode: 59648,
          searchKey: {
            zhCN: '放大镜, 放大, 搜索, 查询, 查找, 项目',
            enUS: 'Magnifying glass, amplifier, search, query, search, project',
            zhSpell: 'fangdajing, fangda, sousuo, chaxun, chazhao, xiangmu',
          },
          group: 'edit',
        },
        {
          iconCode: 59649,
          searchKey: {
            zhCN: '放大镜, 缩小, 搜索, 查询, 减号',
            enUS: 'Magnifying glass, narrow, search, query, a minus sign',
            zhSpell: 'fangdajing, suoxiao, sousuo, chaxun, jianhao',
          },
          group: 'edit',
        },
        {
          iconCode: 59650,
          searchKey: {
            zhCN: '剪裁, 剪切, 裁切, 图片, 照片',
            enUS: 'Cut, cut, cut, pictures, photos',
            zhSpell: 'jiancai, jianqie, caiqie, tupian, zhaopian',
          },
          group: 'edit',
        },
        {
          iconCode: 59651,
          searchKey: {
            zhCN: '箭头, 撤销, 向左, 返回, 上一步',
            enUS: 'The arrow, cancellation, to the left, and back up one step',
            zhSpell: 'jiantou, chexiao, xiangzuo, fanhui, shangyibu',
          },
          group: 'edit',
        },
        {
          iconCode: 59652,
          searchKey: {
            zhCN: '对齐, 右对齐, 文本, 线条, 箭头, 调整文字',
            enUS: 'Right alignment, alignment, text, lines, arrows, adjust the text',
            zhSpell: 'duiqi, youduiqi, wenben, xiantiao, jiantou, tiaozhengwenzi',
          },
          group: 'edit',
        },
        {
          iconCode: 59653,
          searchKey: {
            zhCN: '剪裁, 剪切, 裁切, 图片, 照片',
            enUS: 'Cut, cut, cut, pictures, photos',
            zhSpell: 'jiancai, jianqie, caiqie, tupian, zhaopian',
          },
          group: 'edit',
        },
        {
          iconCode: 59654,
          searchKey: {
            zhCN: '放大镜, 放大, 搜索, 查询, 查找, 文本',
            enUS: 'Magnifying glass, amplifier, search, query, search, text',
            zhSpell: 'fangdajing, fangda, sousuo, chaxun, chazhao, wenben',
          },
          group: 'edit',
        },
        {
          iconCode: 59655,
          searchKey: {
            zhCN: '箭头, 撤销, 向左, 返回, 上一步',
            enUS: 'The arrow, cancellation, to the left, and back up one step',
            zhSpell: 'jiantou, chexiao, xiangzuo, fanhui, shangyibu',
          },
          group: 'edit',
        },
        {
          iconCode: 59656,
          searchKey: {
            zhCN: '关联, 联系, 链接, 组合, 文件, 热点',
            enUS: 'Link, link, link, portfolio, file, hot spots',
            zhSpell: 'guanlian, lianxi, lianjie, zuhe, wenjian, redian',
          },
          group: 'edit',
        },
        {
          iconCode: 59657,
          searchKey: {
            zhCN: '放大镜, 放大, 搜索, 查询, 查找',
            enUS: 'A magnifying glass, amplification, search, query and search',
            zhSpell: 'fangdajing, fangda, sousuo, chaxun, chazhao',
          },
          group: 'edit',
        },
        {
          iconCode: 59658,
          searchKey: {
            zhCN: '箭头, 历史记录, 浏览, 撤销, 后退, 时间',
            enUS: 'Arrow, historical records, browsing, revocation, retreat, time',
            zhSpell: 'jiantou, lishijilu, liulan, chexiao, houtui, shijian',
          },
          group: 'edit',
        },
        {
          iconCode: 59659,
          searchKey: {
            zhCN: '剪切, 剪刀, 工具, 剪裁, 裁剪, 剪断',
            enUS: 'Shear, scissors, tools, cut, cut, cut',
            zhSpell: 'jianqie, jiandao, gongju, jiancai, caijian, jianduan',
          },
          group: 'edit',
        },
        {
          iconCode: 59660,
          searchKey: {
            zhCN: '箭头, 全屏, 扩展, 放大, 最大化',
            enUS: 'Arrow, full screen, extension, amplifier, maximize',
            zhSpell: 'jiantou, quanping, kuozhan, fangda, zuidahua',
          },
          group: 'edit',
        },
        {
          iconCode: 59661,
          searchKey: {
            zhCN: '箭头, 恢复, 向右, 跳转, 下一步',
            enUS: 'Arrow, restore to the right, jump, the next step',
            zhSpell: 'jiantou, huifu, xiangyou, tiaozhuan, xiayibu',
          },
          group: 'edit',
        },
        {
          iconCode: 59662,
          searchKey: {
            zhCN: '箭头, 恢复, 向右, 跳转, 下一步',
            enUS: 'Arrow, restore to the right, jump, the next step',
            zhSpell: 'jiantou, huifu, xiangyou, tiaozhuan, xiayibu',
          },
          group: 'edit',
        },
        {
          iconCode: 59663,
          searchKey: {
            zhCN: '箭头, 全屏, 扩展, 放大, 最大化',
            enUS: 'Arrow, full screen, extension, amplifier, maximize',
            zhSpell: 'jiantou, quanping, kuozhan, fangda, zuidahua',
          },
          group: 'edit',
        },
        {
          iconCode: 59664,
          searchKey: {
            zhCN: '箭头, 刷新, 顺时针, 圈, 加载',
            enUS: 'Arrow, refresh, clockwise, circle, load',
            zhSpell: 'jiantou, shuaxin, shunshizhen, quan, jiazai',
          },
          group: 'edit',
        },
        {
          iconCode: 59665,
          searchKey: {
            zhCN: '箭头, 全屏, 扩展, 放大, 最大化, 扩大',
            enUS: 'Arrow, full screen, extension, amplifier, maximize, expand',
            zhSpell: 'jiantou, quanping, kuozhan, fangda, zuidahua, kuoda',
          },
          group: 'edit',
        },
        {
          iconCode: 59666,
          searchKey: {
            zhCN: '链接, 回形针, 曲别针, 文件, 附件, 超链接',
            enUS: 'Links, paper clips, file attachments, hyperlinks',
            zhSpell: 'lianjie, huixingzhen, qubiezhen, wenjian, fujian, chaolianjie',
          },
          group: 'edit',
        },
        {
          iconCode: 59667,
          searchKey: {
            zhCN: '箭头, 全屏, 扩展, 放大, 最大化, 扩大',
            enUS: 'Arrow, full screen, extension, amplifier, maximize, expand',
            zhSpell: 'jiantou, quanping, kuozhan, fangda, zuidahua, kuoda',
          },
          group: 'edit',
        },
        {
          iconCode: 59668,
          searchKey: {
            zhCN: '列表, 菜单, 导航, 抽屉, 汉堡菜单',
            enUS: 'List, menus, navigation, drawer, burger menu',
            zhSpell: 'liebiao, caidan, daohang, chouti, hanbaocaidan',
          },
          group: 'edit',
        },
        {
          iconCode: 59669,
          searchKey: {
            zhCN: '列表, 菜单, 目录, 导航, 项目, 大纲, 方点',
            enUS: 'List, menus, directory, navigation, project, outline, the point',
            zhSpell: 'liebiao, caidan, mulu, daohang, xiangmu, dagang, fangdian',
          },
          group: 'edit',
        },
        {
          iconCode: 59670,
          searchKey: {
            zhCN: '箭头, 缩小, 退出全屏, 取消, 屏幕, 设置',
            enUS: 'Arrow, narrow, exit full screen, cancel, screen Settings',
            zhSpell: 'jiantou, suoxiao, tuichuquanping, quxiao, pingmu, shezhi',
          },
          group: 'edit',
        },
        {
          iconCode: 59671,
          searchKey: {
            zhCN: '箭头, 移动, 选中, 鼠标, 扩大',
            enUS: 'The selected, the mouse, arrows, mobile',
            zhSpell: 'jiantou, yidong, xuanzhong, shubiao, kuoda',
          },
          group: 'edit',
        },
        {
          iconCode: 59672,
          searchKey: {
            zhCN: '列表, 编号, 菜单, 表单, 选项, 数字',
            enUS: 'List of Numbers, menus, forms, options and Numbers',
            zhSpell: 'liebiao, bianhao, caidan, biaodan, xuanxiang, shuzi',
          },
          group: 'edit',
        },
        {
          iconCode: 59673,
          searchKey: {
            zhCN: '链接, 附件, 联系, 关联, 超链接, 网站',
            enUS: 'Links, attachments, links, connection and hyperlinks, website',
            zhSpell: 'lianjie, fujian, lianxi, guanlian, chaolianjie, wangzhan',
          },
          group: 'edit',
        },
        {
          iconCode: 59674,
          searchKey: {
            zhCN: '链接, 附件, 联系, 关联, 超链接, 网站',
            enUS: 'Links, attachments, links, connection and hyperlinks, website',
            zhSpell: 'lianjie, fujian, lianxi, guanlian, chaolianjie, wangzhan',
          },
          group: 'edit',
        },
        {
          iconCode: 59675,
          searchKey: {
            zhCN: '列表, 菜单, 导航, 抽屉, 汉堡菜单',
            enUS: 'List, menus, navigation, drawer, burger menu',
            zhSpell: 'liebiao, caidan, daohang, chouti, hanbaocaidan',
          },
          group: 'edit',
        },
        {
          iconCode: 59676,
          searchKey: {
            zhCN: '列表, 菜单, 目录, 导航, 项目, 大纲, 按钮',
            enUS: 'List, menus, directory, navigation, project, outline, button',
            zhSpell: 'liebiao, caidan, mulu, daohang, xiangmu, dagang, anniu',
          },
          group: 'edit',
        },
        {
          iconCode: 59677,
          searchKey: {
            zhCN: '列表, 菜单, 目录, 导航, 项目, 大纲, 圆点',
            enUS: 'List, menus, directory, navigation, project, outline, dot',
            zhSpell: 'liebiao, caidan, mulu, daohang, xiangmu, dagang, yuandian',
          },
          group: 'edit',
        },
        {
          iconCode: 59678,
          searchKey: {
            zhCN: '筛选, 过滤, 漏斗, 搜索, 选取',
            enUS: 'Sorting, filtering, funnel, search, select',
            zhSpell: 'shaixuan, guolv, loudou, sousuo, xuanqu',
          },
          group: 'edit',
        },
        {
          iconCode: 59679,
          searchKey: {
            zhCN: '筛选, 过滤, 漏斗, 搜索, 选取',
            enUS: 'Sorting, filtering, funnel, search, select',
            zhSpell: 'shaixuan, guolv, loudou, sousuo, xuanqu',
          },
          group: 'edit',
        },
        {
          iconCode: 59680,
          searchKey: {
            zhCN: '列表, 表单, 检查, 完成, 审核, 项目, 目录',
            enUS: 'Lists, forms, check, complete, audit, project directory',
            zhSpell: 'liebiao, biaodan, jiancha, wancheng, shenhe, xiangmu, mulu',
          },
          group: 'edit',
        },
        {
          iconCode: 59681,
          searchKey: {
            zhCN: '列表, 菜单, 目录, 导航, 项目, 大纲',
            enUS: 'List, menus, directory, navigation, project outline',
            zhSpell: 'liebiao, caidan, mulu, daohang, xiangmu, dagang',
          },
          group: 'edit',
        },
        {
          iconCode: 59682,
          searchKey: {
            zhCN: '列表, 菜单, 目录, 导航, 项目, 大纲, 按钮',
            enUS: 'List, menus, directory, navigation, project, outline, button',
            zhSpell: 'liebiao, caidan, mulu, daohang, xiangmu, dagang, anniu',
          },
          group: 'edit',
        },
        {
          iconCode: 59683,
          searchKey: {
            zhCN: '删除, 清除, 后退, 关闭, 叉, 退出',
            enUS: 'Delete, clear, retreat, closed, cross, exit',
            zhSpell: 'shanchu, qingchu, houtui, guanbi, cha, tuichu',
          },
          group: 'edit',
        },
        {
          iconCode: 59684,
          searchKey: {
            zhCN: '图片集, 集合, 多个, 文件, 文档, 相册',
            enUS: 'Photo collection, collection, multiple, files, documents, photo album',
            zhSpell: 'tupianji, jihe, duoge, wenjian, wendang, xiangce',
          },
          group: 'edit',
        },
        {
          iconCode: 59685,
          searchKey: {
            zhCN: '外观, 皮肤, 设置, 魔法棒, 魔杖',
            enUS: 'Appearance, skin, Settings, magic wand, the wand',
            zhSpell: 'waiguan, pifu, shezhi, mofabang, mozhang',
          },
          group: 'edit',
        },
        {
          iconCode: 59686,
          searchKey: {
            zhCN: '删除, 垃圾桶, 清除, 回收站, 卸载',
            enUS: 'Delete, trash can, remove and recycle bin, unloading',
            zhSpell: 'shanchu, lajitong, qingchu, huishouzhan, xiezai',
          },
          group: 'edit',
        },
        {
          iconCode: 59687,
          searchKey: {
            zhCN: '筛选, 过滤, 漏斗, 搜索, 选取',
            enUS: 'Sorting, filtering, funnel, search, select',
            zhSpell: 'shaixuan, guolv, loudou, sousuo, xuanqu',
          },
          group: 'edit',
        },
        {
          iconCode: 59688,
          searchKey: {
            zhCN: '文本, 编辑, 修改, 记录, 笔, 笔记',
            enUS: 'Text, edit, modify, records, pen, note',
            zhSpell: 'wenben, bianji, xiugai, jilu, bi, biji',
          },
          group: 'edit',
        },
        {
          iconCode: 59689,
          searchKey: {
            zhCN: '图片, 上传, 图像, 相册, 照片, 存储',
            enUS: 'Pictures, upload, image, photo albums, photos, storage',
            zhSpell: 'tupian, shangchuan, tuxiang, xiangce, zhaopian, cunchu',
          },
          group: 'edit',
        },
        {
          iconCode: 59690,
          searchKey: {
            zhCN: '文本, 加粗, 粗体, 文字, 编辑, 字母',
            enUS: 'Text, bold, bold, writing, editing, letters',
            zhSpell: 'wenben, jiacu, cuti, wenzi, bianji, zimu',
          },
          group: 'edit',
        },
        {
          iconCode: 59691,
          searchKey: {
            zhCN: '文本, 倾斜, 斜体, 文字, 编辑',
            enUS: 'Text, tilt, italics, writing, editing',
            zhSpell: 'wenben, qingxie, xieti, wenzi, bianji',
          },
          group: 'edit',
        },
        {
          iconCode: 59692,
          searchKey: {
            zhCN: '文本, 大小写, 字母, 文字, 编辑',
            enUS: 'Text, capitalization, letters, words, and editing',
            zhSpell: 'wenben, daxiaoxie, zimu, wenzi, bianji',
          },
          group: 'edit',
        },
        {
          iconCode: 59693,
          searchKey: {
            zhCN: '表单, 未勾选, 列表, 检查, 填写, 核对',
            enUS: ', forms, this option is list, check, fill in and check it',
            zhSpell: 'biaodan, weigouxuan, liebiao, jiancha, tianxie, hedui',
          },
          group: 'edit',
        },
        {
          iconCode: 59694,
          searchKey: {
            zhCN: '文本, 平方, 数学, 上标, 计算, 数字, 字母',
            enUS: 'Text, square, mathematics, superscript, calculation, Numbers and letters',
            zhSpell: 'wenben, pingfang, shuxue, shangbiao, jisuan, shuzi, zimu',
          },
          group: 'edit',
        },
        {
          iconCode: 59695,
          searchKey: {
            zhCN: '文本, 多行文本, 段落, 文字, 编辑, 布局',
            enUS: 'Text, multiline text, paragraph, writing, editing, layout',
            zhSpell: 'wenben, duoxingwenben, duanluo, wenzi, bianji, buju',
          },
          group: 'edit',
        },
        {
          iconCode: 59696,
          searchKey: {
            zhCN: '文本, 删除线, 去掉, 文字, 编辑, 删除',
            enUS: 'Text, strikethrough, take out, writing, editing, deletion',
            zhSpell: 'wenben, shanchuxian, qudiao, wenzi, bianji, shanchu',
          },
          group: 'edit',
        },
        {
          iconCode: 59697,
          searchKey: {
            zhCN: '文本, 文字, 字体, 编辑, 格式',
            enUS: 'Text, text, font, edit, format',
            zhSpell: 'wenben, wenzi, ziti, bianji, geshi',
          },
          group: 'edit',
        },
        {
          iconCode: 59698,
          searchKey: {
            zhCN: '文本, 富文本, 字体, 编辑, 格式',
            enUS: 'Text, rich text, font, edit, format',
            zhSpell: 'wenben, fuwenben, ziti, bianji, geshi',
          },
          group: 'edit',
        },
        {
          iconCode: 59699,
          searchKey: {
            zhCN: '文本, 排序, 行距, 编辑, 调整',
            enUS: 'Text, sorting, row spacing, edit, adjust',
            zhSpell: 'wenben, paixu, xingju, bianji, tiaozheng',
          },
          group: 'edit',
        },
        {
          iconCode: 59700,
          searchKey: {
            zhCN: '文本, 下划线, 重点, 文字, 编辑, 可点击',
            enUS: 'Text, underline, focus, writing, editing, clickable',
            zhSpell: 'wenben, xiahuaxian, zhongdian, wenzi, bianji, kedianji',
          },
          group: 'edit',
        },
        {
          iconCode: 59701,
          searchKey: {
            zhCN: '文本, 字母排序, 排序, 降序, 顺序',
            enUS: 'Text, alphabetical order, sorting, descending order, order',
            zhSpell: 'wenben, zimupaixu, paixu, jiangxu, shunxu',
          },
          group: 'edit',
        },
        {
          iconCode: 59702,
          searchKey: {
            zhCN: '文本, 大小写, 大写, 小写, 文字, 编辑',
            enUS: 'Text, case, uppercase and lowercase, writing, editing',
            zhSpell: 'wenben, daxiaoxie, daxie, xiaoxie, wenzi, bianji',
          },
          group: 'edit',
        },
        {
          iconCode: 59703,
          searchKey: {
            zhCN: '文本, 小写, 调整, 箭头, 文字',
            enUS: 'Text, lowercase, adjustment, arrow, text',
            zhSpell: 'wenben, xiaoxie, tiaozheng, jiantou, wenzi',
          },
          group: 'edit',
        },
        {
          iconCode: 59704,
          searchKey: {
            zhCN: '文本, 段落, 段, 粗体, 加粗, 字母',
            enUS: 'Text, paragraph, paragraph, bold, bold, letters',
            zhSpell: 'wenben, duanluo, duan, cuti, jiacu, zimu',
          },
          group: 'edit',
        },
        {
          iconCode: 59705,
          searchKey: {
            zhCN: '文本, 大写, 调整, 箭头, 文字',
            enUS: 'Text, caps, adjustment, arrow, text',
            zhSpell: 'wenben, daxie, tiaozheng, jiantou, wenzi',
          },
          group: 'edit',
        },
        {
          iconCode: 59706,
          searchKey: {
            zhCN: '文本, 文字, 字体, 编辑, 格式, 字母',
            enUS: 'Text, text, font, edit, format, and letters',
            zhSpell: 'wenben, wenzi, ziti, bianji, geshi, zimu',
          },
          group: 'edit',
        },
        {
          iconCode: 59707,
          searchKey: {
            zhCN: '文本, 语言切换, 转换, 翻译, 词典, 中英文切换',
            enUS: 'Text, language switch, transformation, translation, dictionary, switch in both Chinese and English',
            zhSpell: 'wenben, yuyanqiehuan, zhuanhuan, fanyi, cidian, zhongyingwenqiehuan',
          },
          group: 'edit',
        },
        {
          iconCode: 59708,
          searchKey: {
            zhCN: '修改, 加, 编辑, 笔, 记录, 增加, 添加',
            enUS: 'Modify, add, edit, pen, records, increase',
            zhSpell: 'xiugai, jia, bianji, bi, jilu, zengjia, tianjia',
          },
          group: 'edit',
        },
        {
          iconCode: 59709,
          searchKey: {
            zhCN: '修改, 笔, 编辑, 书写, 铅笔',
            enUS: 'Modify, pen, editing, writing, pencil',
            zhSpell: 'xiugai, bi, bianji, shuxie, qianbi',
          },
          group: 'edit',
        },
        {
          iconCode: 59710,
          searchKey: {
            zhCN: '修改, 笔, 编辑, 创作, 写',
            enUS: 'Revision, pen, editing, writing, writing',
            zhSpell: 'xiugai, bi, bianji, chuangzuo, xie',
          },
          group: 'edit',
        },
        {
          iconCode: 59711,
          searchKey: {
            zhCN: '文本, 编辑, 修改, 记录, 笔, 笔记',
            enUS: 'Text, edit, modify, records, pen, note',
            zhSpell: 'wenben, bianji, xiugai, jilu, bi, biji',
          },
          group: 'edit',
        },
        {
          iconCode: 59712,
          searchKey: {
            zhCN: '修改, 笔, 编辑, 书写, 铅笔',
            enUS: 'Modify, pen, editing, writing, pencil',
            zhSpell: 'xiugai, bi, bianji, shuxie, qianbi',
          },
          group: 'edit',
        },
        {
          iconCode: 59713,
          searchKey: {
            zhCN: '修改, 写, 编辑, 笔, 记录',
            enUS: 'Modify, writing, editing, pen, record',
            zhSpell: 'xiugai, xie, bianji, bi, jilu',
          },
          group: 'edit',
        },
        {
          iconCode: 59714,
          searchKey: {
            zhCN: '修改, 减, 编辑, 笔, 记录, 写',
            enUS: 'Modification, reduction, editing, pen, records, write',
            zhSpell: 'xiugai, jian, bianji, bi, jilu, xie',
          },
          group: 'edit',
        },
        {
          iconCode: 59715,
          searchKey: {
            zhCN: '文本, 语言切换, 转换, 翻译, 词典, 中英文切换',
            enUS: 'Text, language switch, transformation, translation, dictionary, switch in both Chinese and English',
            zhSpell: 'wenben, yuyanqiehuan, zhuanhuan, fanyi, cidian, zhongyingwenqiehuan',
          },
          group: 'edit',
        },
        {
          iconCode: 59716,
          searchKey: {
            zhCN: '复制, 粘贴, 拷贝, 克隆, 编辑, 多个',
            enUS: 'Copy, paste, copy, cloning, editing, multiple',
            zhSpell: 'fuzhi, zhantie, kaobei, kelong, bianji, duoge',
          },
          group: 'edit',
        },
      ],
    },
    {
      name: 'Logo',
      id: 'logo',
      items: [
        {
          iconCode: 60726,
          searchKey: {
            zhCN: '标志, 百度, 浏览器, 搜索引擎, 上网, 查找, 网络',
            enUS: 'Logo, baidu, browser, search engines, Internet access, search, network',
            zhSpell: 'biaozhi, baidu, liulanqi, sousuoyinqing, shangwang, chazhao, wangluo',
          },
          group: 'logo',
        },
        {
          iconCode: 60727,
          searchKey: {
            zhCN: '标志, 大麦, 购票, 抢票, 演出, 演唱会',
            enUS: 'Mark, barley, ticketing, rob tickets, performances, concerts',
            zhSpell: 'biaozhi, damai, goupiao, qiangpiao, yanchu, yanchanghui',
          },
          group: 'logo',
        },
        {
          iconCode: 60728,
          searchKey: {
            zhCN: '标志, 百度, 浏览器, 搜索引擎, 上网, 查找, 网络',
            enUS: 'Logo, baidu, browser, search engines, Internet access, search, network',
            zhSpell: 'biaozhi, baidu, liulanqi, sousuoyinqing, shangwang, chazhao, wangluo',
          },
          group: 'logo',
        },
        {
          iconCode: 60729,
          searchKey: {
            zhCN: '标志, 百度, 浏览器, 搜索引擎, 上网, 查找, 网络',
            enUS: 'Logo, baidu, browser, search engines, Internet access, search, network',
            zhSpell: 'biaozhi, baidu, liulanqi, sousuoyinqing, shangwang, chazhao, wangluo',
          },
          group: 'logo',
        },
        {
          iconCode: 60730,
          searchKey: {
            zhCN: '标志, 500px, 照片, 图片社区, 分享, 平台, 摄影',
            enUS: 'Mark, 500 px, photos, pictures community, sharing, platform, and photography',
            zhSpell: 'biaozhi, 500px, zhaopian, tupianshequ, fenxiang, pingtai, sheying',
          },
          group: 'logo',
        },
        {
          iconCode: 60731,
          searchKey: {
            zhCN: '标志, 京东, 购物, 电子商务, 互联网, 平台',
            enUS: 'Logo, jingdong, shopping, e-commerce, Internet, platform',
            zhSpell: 'biaozhi, jingdong, gouwu, dianzishangwu, hulianwang, pingtai',
          },
          group: 'logo',
        },
        {
          iconCode: 60732,
          searchKey: {
            zhCN: '标志, 旺旺, 服务, 客服, 交流, 消息',
            enUS: 'Logo, want want, service, customer service, communication, news',
            zhSpell: 'biaozhi, wangwang, fuwu, kefu, jiaoliu, xiaoxi',
          },
          group: 'logo',
        },
        {
          iconCode: 60733,
          searchKey: {
            zhCN: '标志, 联通, 通讯, 网络, 设备, 基站, 沟通',
            enUS: 'Logo, unicom, communication, network, equipment, base station, communication',
            zhSpell: 'biaozhi, liantong, tongxun, wangluo, shebei, jizhan, goutong',
          },
          group: 'logo',
        },
        {
          iconCode: 60734,
          searchKey: {
            zhCN: '标志, 腾讯微博, 网站, 博客, 社交媒体, 分享',
            enUS: 'Logo,tencent weibo,websites,micro blog,social media,share',
            zhSpell: 'biaozhi,tengxunweibo,wangzhan,boke,shejiaomeiti,fenxiang',
          },
          group: 'logo',
        },
        {
          iconCode: 60735,
          searchKey: {
            zhCN: '标志, 微信, 腾讯, 社交, 聊天, 通讯, 分享',
            enUS: 'Mark, WeChat, tencent, social networking, chat, communication and sharing',
            zhSpell: 'biaozhi, weixin, tengxun, shejiao, liaotian, tongxun, fenxiang',
          },
          group: 'logo',
        },
        {
          iconCode: 60736,
          searchKey: {
            zhCN: '标志, 旺旺, 服务, 客服, 交流, 消息',
            enUS: 'Logo, want want, service, customer service, communication, news',
            zhSpell: 'biaozhi, wangwang, fuwu, kefu, jiaoliu, xiaoxi',
          },
          group: 'logo',
        },
        {
          iconCode: 60737,
          searchKey: {
            zhCN: '标志, 京东, 购物, 电子商务, 互联网, 平台',
            enUS: 'Logo, jingdong, shopping, e-commerce, Internet, platform',
            zhSpell: 'biaozhi, jingdong, gouwu, dianzishangwu, hulianwang, pingtai',
          },
          group: 'logo',
        },
        {
          iconCode: 60738,
          searchKey: {
            zhCN: '标志, 支付宝, 交易, 付款, 网购',
            enUS: 'Mark, pay treasure, trading, payment, online shopping',
            zhSpell: 'biaozhi, zhifubao, jiaoyi, fukuan, wanggou',
          },
          group: 'logo',
        },
        {
          iconCode: 60739,
          searchKey: {
            zhCN: '标志, 火狐, 浏览器, 搜索引擎, 上网, 网络',
            enUS: 'Mark, firefox browser, search engines, Internet access, network',
            zhSpell: 'biaozhi, huohu, liulanqi, sousuoyinqing, shangwang, wangluo',
          },
          group: 'logo',
        },
        {
          iconCode: 60740,
          searchKey: {
            zhCN: '标志, 新浪微博, 网站, 博客, 腾讯, 社交媒体, 分享',
            enUS: 'Mark, sina weibo, websites, blogs, tencent, social media, to share',
            zhSpell: 'biaozhi, xinlangweibo, wangzhan, boke, tengxun, shejiaomeiti, fenxiang',
          },
          group: 'logo',
        },
        {
          iconCode: 60741,
          searchKey: {
            zhCN: '标志, Behance, 设计, 分享, 平台, 网站',
            enUS: 'Logo, design, Behance, sharing, platform, website',
            zhSpell: 'biaozhi, Behance, sheji, fenxiang, pingtai, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60742,
          searchKey: {
            zhCN: '标志, 安卓, 手机, 系统, 移动设备',
            enUS: 'Logo, android, mobile phones, system, mobile devices',
            zhSpell: 'biaozhi, anzhuo, shouji, xitong, yidongshebei',
          },
          group: 'logo',
        },
        {
          iconCode: 60743,
          searchKey: {
            zhCN: '标志, 支付宝, 交易, 付款, 网购',
            enUS: 'Logo, Alipay, transaction, payment, online shopping',
            zhSpell: 'biaozhi, zhifubao, jiaoyi, fukuan, wanggou',
          },
          group: 'logo',
        },
        {
          iconCode: 60744,
          searchKey: {
            zhCN: '标志, 支付宝, 交易, 付款, 网购',
            enUS: 'Mark, pay treasure, trading, payment, online shopping',
            zhSpell: 'biaozhi, zhifubao, jiaoyi, fukuan, wanggou',
          },
          group: 'logo',
        },
        {
          iconCode: 60745,
          searchKey: {
            zhCN: '标志, Adobe, 设计, 图形, 网页制作, 数码视频',
            enUS: 'Mark, Adobe, design, graphics, web production, digital video',
            zhSpell: 'biaozhi, Adobe, sheji, tuxing, wangyezhizuo, shumashipin',
          },
          group: 'logo',
        },
        {
          iconCode: 60746,
          searchKey: {
            zhCN: '标志, 芝麻信用, 贷款, 借贷, 交易',
            enUS: 'Logo, sesame credit, loans, loans, trade',
            zhSpell: 'biaozhi, zhimaxinyong, daikuan, jiedai, jiaoyi',
          },
          group: 'logo',
        },
        {
          iconCode: 60747,
          searchKey: {
            zhCN: '标志, 京东, 购物, 电子商务, 互联网, 平台, 狗',
            enUS: 'Logo, jingdong, shopping, e-commerce, Internet, platform, dog',
            zhSpell: 'biaozhi, jingdong, gouwu, dianzishangwu, hulianwang, pingtai, gou',
          },
          group: 'logo',
        },
        {
          iconCode: 60748,
          searchKey: {
            zhCN: '标志, 移动, 通讯, 网络, 设备, 基站',
            enUS: 'Signs, mobile communication, network, equipment, base station',
            zhSpell: 'biaozhi, yidong, tongxun, wangluo, shebei, jizhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60749,
          searchKey: {
            zhCN: '标志, 爱彼迎, 旅游, 住宿, 民宿, 平台, 网站',
            enUS: 'Mark, Abby, travel, accommodation, homestay, platform, website',
            zhSpell: 'biaozhi, aibiying, lvyou, zhusu, minsu, pingtai, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60750,
          searchKey: {
            zhCN: '标志, Behance, 设计, 分享, 平台, 网站',
            enUS: 'Logo, design, Behance, sharing, platform, website',
            zhSpell: 'biaozhi, Behance, sheji, fenxiang, pingtai, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60751,
          searchKey: {
            zhCN: '标志, Css, 程序, 编程, 开发, 网站',
            enUS: 'Logo, Css, procedure, programming, development, web site',
            zhSpell: 'biaozhi, Css, chengxu, biancheng, kaifa, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60752,
          searchKey: {
            zhCN: '标志, 电信, 通讯, 网络, 设备, 基站, 沟通',
            enUS: 'Mark, telecom, communication, network, equipment, base station, communication',
            zhSpell: 'biaozhi, dianxin, tongxun, wangluo, shebei, jizhan, goutong',
          },
          group: 'logo',
        },
        {
          iconCode: 60753,
          searchKey: {
            zhCN: '标志, Behance, 设计, 分享, 平台, 网站',
            enUS: 'Logo, design, Behance, sharing, platform, website',
            zhSpell: 'biaozhi, Behance, sheji, fenxiang, pingtai, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60754,
          searchKey: {
            zhCN: '标志, Dribbble, 设计, 分享, 平台, 网站',
            enUS: 'Logo, design, Dribbble, sharing, platform, website',
            zhSpell: 'biaozhi, Dribbble, sheji, fenxiang, pingtai, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60755,
          searchKey: {
            zhCN: '标志, Dribbble, 设计, 分享, 平台, 网站',
            enUS: 'Logo, design, Dribbble, sharing, platform, website',
            zhSpell: 'biaozhi, Dribbble, sheji, fenxiang, pingtai, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60756,
          searchKey: {
            zhCN: '标志, Dribbble, 设计, 分享, 平台, 网站',
            enUS: 'Logo, design, Dribbble, sharing, platform, website',
            zhSpell: 'biaozhi, Dribbble, sheji, fenxiang, pingtai, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60757,
          searchKey: {
            zhCN: '标志, Dribbble, 设计, 分享, 平台, 网站',
            enUS: 'Logo, design, Dribbble, sharing, platform, website',
            zhSpell: 'biaozhi, Dribbble, sheji, fenxiang, pingtai, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60758,
          searchKey: {
            zhCN: '标志, 安卓, 手机, 系统, 移动设备',
            enUS: 'Logo, android, mobile phones, system, mobile devices',
            zhSpell: 'biaozhi, anzhuo, shouji, xitong, yidongshebei',
          },
          group: 'logo',
        },
        {
          iconCode: 60759,
          searchKey: {
            zhCN: '标志, 脸书, 社交, 分享, 平台, 网站, 社区',
            enUS: 'Logo, facebook, social networking, sharing, platform, website, community',
            zhSpell: 'biaozhi, lianshu, shejiao, fenxiang, pingtai, wangzhan, shequ',
          },
          group: 'logo',
        },
        {
          iconCode: 60760,
          searchKey: {
            zhCN: '标志, 碉堡箱, 箱, 云存储, 下载, 分享, 互联网',
            enUS: 'Mark, bunker box, box, cloud storage, download, share, the Internet',
            zhSpell: 'biaozhi, diaobaoxiang, xiang, yuncunchu, xiazai, fenxiang, hulianwang',
          },
          group: 'logo',
        },
        {
          iconCode: 60761,
          searchKey: {
            zhCN: '标志, Foursquare, 发现, 分享, 位置, 定位',
            enUS: 'Logo, Foursquare, to find, share, location, location',
            zhSpell: 'biaozhi, Foursquare, faxian, fenxiang, weizhi, dingwei',
          },
          group: 'logo',
        },
        {
          iconCode: 60762,
          searchKey: {
            zhCN: '标志, 碉堡箱, 箱, 云存储, 下载, 分享, 互联网',
            enUS: 'Mark, bunker box, box, cloud storage, download, share, the Internet',
            zhSpell: 'biaozhi, diaobaoxiang, xiang, yuncunchu, xiazai, fenxiang, hulianwang',
          },
          group: 'logo',
        },
        {
          iconCode: 60763,
          searchKey: {
            zhCN: '标志, Foursquare, 发现, 分享, 位置, 定位',
            enUS: 'Logo, Foursquare, to find, share, location, location',
            zhSpell: 'biaozhi, Foursquare, faxian, fenxiang, weizhi, dingwei',
          },
          group: 'logo',
        },
        {
          iconCode: 60764,
          searchKey: {
            zhCN: '标志, 脸书, 社交, 分享, 平台, 网站, 社区',
            enUS: 'Logo, facebook, social networking, sharing, platform, website, community',
            zhSpell: 'biaozhi, lianshu, shejiao, fenxiang, pingtai, wangzhan, shequ',
          },
          group: 'logo',
        },
        {
          iconCode: 60765,
          searchKey: {
            zhCN: '标志, 脸书, 社交, 分享, 平台, 网站, 社区',
            enUS: 'Logo, facebook, social networking, sharing, platform, website, community',
            zhSpell: 'biaozhi, lianshu, shejiao, fenxiang, pingtai, wangzhan, shequ',
          },
          group: 'logo',
        },
        {
          iconCode: 60766,
          searchKey: {
            zhCN: '标志, Dribbble, 设计, 分享, 平台, 网站',
            enUS: 'Logo, design, Dribbble, sharing, platform, website',
            zhSpell: 'biaozhi, Dribbble, sheji, fenxiang, pingtai, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60767,
          searchKey: {
            zhCN: '标志, 隐藏字幕, 字幕, 小标题, 电影, 电视',
            enUS: 'Mark, closed caption, captions, headings, film and television',
            zhSpell: 'biaozhi, yincangzimu, zimu, xiaobiaoti, dianying, dianshi',
          },
          group: 'logo',
        },
        {
          iconCode: 60768,
          searchKey: {
            zhCN: '标志, 脸书, 社交, 分享, 平台, 网站, 社区',
            enUS: 'Logo, facebook, social networking, sharing, platform, website, community',
            zhSpell: 'biaozhi, lianshu, shejiao, fenxiang, pingtai, wangzhan, shequ',
          },
          group: 'logo',
        },
        {
          iconCode: 60769,
          searchKey: {
            zhCN: '标志, 谷歌, 平台, 搜索引擎, 社交',
            enUS: 'Mark, Google, platform, search engines, social networking',
            zhSpell: 'biaozhi, guge, pingtai, sousuoyinqing, shejiao',
          },
          group: 'logo',
        },
        {
          iconCode: 60770,
          searchKey: {
            zhCN: '标志, Gmail, 社交, 邮件, 邮箱, 信件, 收件',
            enUS: 'Mark, Gmail, social, mail, E-mail, letter, the receipt',
            zhSpell: 'biaozhi, Gmail, shejiao, youjian, youxiang, xinjian, shoujian',
          },
          group: 'logo',
        },
        {
          iconCode: 60771,
          searchKey: {
            zhCN: '标志, Github, 开发, 程序, 社交网络',
            enUS: 'Mark, lot, development, application, social network',
            zhSpell: 'biaozhi, Github, kaifa, chengxu, shejiaowangluo',
          },
          group: 'logo',
        },
        {
          iconCode: 60772,
          searchKey: {
            zhCN: '标志, 谷歌加, 平台, 社交, 分享, 转发',
            enUS: 'Mark, Google plus, platform, social networking, sharing, forward',
            zhSpell: 'biaozhi, gugejia, pingtai, shejiao, fenxiang, zhuanfa',
          },
          group: 'logo',
        },
        {
          iconCode: 60773,
          searchKey: {
            zhCN: '标志, Github, 开发, 程序, 社交网络',
            enUS: 'Mark, lot, development, application, social network',
            zhSpell: 'biaozhi, Github, kaifa, chengxu, shejiaowangluo',
          },
          group: 'logo',
        },
        {
          iconCode: 60774,
          searchKey: {
            zhCN: '标志, 微信, 腾讯, 社交, 聊天, 通讯, 分享',
            enUS: 'Mark, WeChat, tencent, social networking, chat, communication and sharing',
            zhSpell: 'biaozhi, weixin, tengxun, shejiao, liaotian, tongxun, fenxiang',
          },
          group: 'logo',
        },
        {
          iconCode: 60775,
          searchKey: {
            zhCN: '标志, Gmail, 社交, 邮件, 邮箱, 信件, 收件',
            enUS: 'Mark, Gmail, social, mail, E-mail, letter, the receipt',
            zhSpell: 'biaozhi, Gmail, shejiao, youjian, youxiang, xinjian, shoujian',
          },
          group: 'logo',
        },
        {
          iconCode: 60776,
          searchKey: {
            zhCN: '标志, 谷歌加, 平台, 社交, 分享, 转发',
            enUS: 'Mark, Google plus, platform, social networking, sharing, forward',
            zhSpell: 'biaozhi, gugejia, pingtai, shejiao, fenxiang, zhuanfa',
          },
          group: 'logo',
        },
        {
          iconCode: 60777,
          searchKey: {
            zhCN: '标志, 谷歌加, 平台, 社交, 分享, 转发',
            enUS: 'Mark, Google plus, platform, social networking, sharing, forward',
            zhSpell: 'biaozhi, gugejia, pingtai, shejiao, fenxiang, zhuanfa',
          },
          group: 'logo',
        },
        {
          iconCode: 60778,
          searchKey: {
            zhCN: '标志, Instagram, 照片, 图片, 分享, 图像, 网站',
            enUS: 'Mark, sets, photos, pictures, sharing, images, websites',
            zhSpell: 'biaozhi, Instagram, zhaopian, tupian, fenxiang, tuxiang, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60779,
          searchKey: {
            zhCN: '标志, IE, 浏览器, 搜索引擎, 上网, 网络',
            enUS: 'Logo, IE browser, search engines, Internet access, network',
            zhSpell: 'biaozhi, IE, liulanqi, sousuoyinqing, shangwang, wangluo',
          },
          group: 'logo',
        },
        {
          iconCode: 60780,
          searchKey: {
            zhCN: '标志, 谷歌, 平台, 搜索引擎, 社交',
            enUS: 'Mark, Google, platform, search engines, social networking',
            zhSpell: 'biaozhi, guge, pingtai, sousuoyinqing, shejiao',
          },
          group: 'logo',
        },
        {
          iconCode: 60781,
          searchKey: {
            zhCN: '标志, Invision, 设计, 平台, 网站',
            enUS: 'Mark, Invision, design, platform, website',
            zhSpell: 'biaozhi, Invision, sheji, pingtai, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60782,
          searchKey: {
            zhCN: '标志, 谷歌, 浏览器, 搜索引擎, 社交',
            enUS: 'Mark, Google browser, search engines, social networking',
            zhSpell: 'biaozhi, guge, liulanqi, sousuoyinqing, shejiao',
          },
          group: 'logo',
        },
        {
          iconCode: 60783,
          searchKey: {
            zhCN: '标志, 谷歌加, 平台, 社交, 分享, 转发',
            enUS: 'Mark, Google plus, platform, social networking, sharing, forward',
            zhSpell: 'biaozhi, gugejia, pingtai, shejiao, fenxiang, zhuanfa',
          },
          group: 'logo',
        },
        {
          iconCode: 60784,
          searchKey: {
            zhCN: '标志, Html, 程序, 编程, 开发, 网站',
            enUS: 'Logo, Html, program, programming, development, web site',
            zhSpell: 'biaozhi, Html, chengxu, biancheng, kaifa, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60785,
          searchKey: {
            zhCN: '标志, 苹果, iOS, 手机, 系统, 移动设备',
            enUS: 'Logo, apple, iOS, mobile phone system, mobile devices',
            zhSpell: 'biaozhi, pingguo, iOS, shouji, xitong, yidongshebei',
          },
          group: 'logo',
        },
        {
          iconCode: 60786,
          searchKey: {
            zhCN: '标志, Invision, 设计, 平台, 网站',
            enUS: 'Mark, Invision, design, platform, website',
            zhSpell: 'biaozhi, Invision, sheji, pingtai, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60787,
          searchKey: {
            zhCN: '标志, Nodejs, 程序, 编程, 开发, 网站',
            enUS: 'Mark, Nodejs, procedure, programming, development, web site',
            zhSpell: 'biaozhi, Nodejs, chengxu, biancheng, kaifa, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60788,
          searchKey: {
            zhCN: '标志, 苹果, iOS, 手机, 系统, 移动设备',
            enUS: 'Logo, apple, iOS, mobile phone system, mobile devices',
            zhSpell: 'biaozhi, pingguo, iOS, shouji, xitong, yidongshebei',
          },
          group: 'logo',
        },
        {
          iconCode: 60789,
          searchKey: {
            zhCN: '标志, Pinterest, 图片, 分享, 设计图片素材, 网站',
            enUS: 'Logo, Pinterest, pictures, sharing, design picture material, web sites',
            zhSpell: 'biaozhi, Pinterest, tupian, fenxiang, shejitupiansucai, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60790,
          searchKey: {
            zhCN: '标志, 支付宝, 交易, 付款, 网购',
            enUS: 'Mark, pay treasure, trading, payment, online shopping',
            zhSpell: 'biaozhi, zhifubao, jiaoyi, fukuan, wanggou',
          },
          group: 'logo',
        },
        {
          iconCode: 60791,
          searchKey: {
            zhCN: '标志, Invision, 设计, 平台, 网站',
            enUS: 'Mark, Invision, design, platform, website',
            zhSpell: 'biaozhi, Invision, sheji, pingtai, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60792,
          searchKey: {
            zhCN: '标志, Pinterest, 图片, 分享, 设计图片素材, 网站',
            enUS: 'Logo, Pinterest, pictures, sharing, design picture material, web sites',
            zhSpell: 'biaozhi, Pinterest, tupian, fenxiang, shejitupiansucai, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60793,
          searchKey: {
            zhCN: '标志, Github, 开发, 程序, 社交网络',
            enUS: 'Mark, lot, development, application, social network',
            zhSpell: 'biaozhi, Github, kaifa, chengxu, shejiaowangluo',
          },
          group: 'logo',
        },
        {
          iconCode: 60794,
          searchKey: {
            zhCN: '标志, 人人网, 社交, 分享, 社区, 社交媒体',
            enUS: 'Logo, renren, social networking, sharing, community, social media',
            zhSpell: 'biaozhi, renrenwang, shejiao, fenxiang, shequ, shejiaomeiti',
          },
          group: 'logo',
        },
        {
          iconCode: 60795,
          searchKey: {
            zhCN: '标志, QQ, 腾讯, 社交, 聊天, 通讯, 企鹅, 交流',
            enUS: 'Logo, QQ, tencent, social networking, chat, communication, penguins, communication',
            zhSpell: 'biaozhi, QQ, tengxun, shejiao, liaotian, tongxun, qie, jiaoliu',
          },
          group: 'logo',
        },
        {
          iconCode: 60796,
          searchKey: {
            zhCN: '标志, Skype, 社交, 聊天, 通讯, 交流, 视频聊天',
            enUS: 'Logo, Skype, social, chat, newsletter, communication, video chat',
            zhSpell: 'biaozhi, Skype, shejiao, liaotian, tongxun, jiaoliu, shipinliaotian',
          },
          group: 'logo',
        },
        {
          iconCode: 60797,
          searchKey: {
            zhCN: '标志, QQ, 腾讯, 社交, 聊天, 通讯, 企鹅, 交流',
            enUS: 'Logo, QQ, Tencent, social, chat, communication, penguin, communication',
            zhSpell: 'biaozhi, QQ, tengxun, shejiao, liaotian, tongxun, qie, jiaoliu',
          },
          group: 'logo',
        },
        {
          iconCode: 60798,
          searchKey: {
            zhCN: '标志, Instagram, 照片, 图片, 分享, 图像, 网站',
            enUS: 'Logo, instagram, photo, photo, share, image, website',
            zhSpell: 'biaozhi, Instagram, zhaopian, tupian, fenxiang, tuxiang, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60799,
          searchKey: {
            zhCN: '标志, Pinterest, 图片, 分享, 设计图片素材, 网站',
            enUS: 'Logo, Pinterest, pictures, sharing, design picture material, web sites',
            zhSpell: 'biaozhi, Pinterest, tupian, fenxiang, shejitupiansucai, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60800,
          searchKey: {
            zhCN: '标志, Javascript, 程序, 编程, 开发',
            enUS: 'Logo, Javascript, program, programming, development',
            zhSpell: 'biaozhi, Javascript, chengxu, biancheng, kaifa',
          },
          group: 'logo',
        },
        {
          iconCode: 60801,
          searchKey: {
            zhCN: '标志, Slack, 社交, 聊天, 通讯, 交流, 协作',
            enUS: 'Logo, Slack, social, chat, communication, communication, collaboration',
            zhSpell: 'biaozhi, Slack, shejiao, liaotian, tongxun, jiaoliu, xiezuo',
          },
          group: 'logo',
        },
        {
          iconCode: 60802,
          searchKey: {
            zhCN: '标志, Skype, 社交, 聊天, 通讯, 交流, 视频聊天',
            enUS: 'Logo, Skype, social, chat, newsletter, communication, video chat',
            zhSpell: 'biaozhi, Skype, shejiao, liaotian, tongxun, jiaoliu, shipinliaotian',
          },
          group: 'logo',
        },
        {
          iconCode: 60803,
          searchKey: {
            zhCN: '标志, Tumblr, 轻博客, 微博客, 社交, 分享',
            enUS: 'Logo, Tumblr, light blog, microblogging, social, sharing',
            zhSpell: 'biaozhi, Tumblr, qingboke, weiboke, shejiao, fenxiang',
          },
          group: 'logo',
        },
        {
          iconCode: 60804,
          searchKey: {
            zhCN: '标志, Tumblr, 轻博客, 微博客, 社交, 分享',
            enUS: 'Logo, Tumblr, light blog, microblogging, social, sharing',
            zhSpell: 'biaozhi, Tumblr, qingboke, weiboke, shejiao, fenxiang',
          },
          group: 'logo',
        },
        {
          iconCode: 60805,
          searchKey: {
            zhCN: '标志, Slack, 社交, 聊天, 通讯, 交流, 协作',
            enUS: 'Logo, Slack, social, chat, communication, communication, collaboration',
            zhSpell: 'biaozhi, Slack, shejiao, liaotian, tongxun, jiaoliu, xiezuo',
          },
          group: 'logo',
        },
        {
          iconCode: 60806,
          searchKey: {
            zhCN: '标志, Snapchat, 社交, 聊天, 即时通讯, 图片, 分享',
            enUS: 'Logo, Snapchat, social, chat, instant messaging, pictures, sharing',
            zhSpell: 'biaozhi, Snapchat, shejiao, liaotian, jishitongxun, tupian, fenxiang',
          },
          group: 'logo',
        },
        {
          iconCode: 60807,
          searchKey: {
            zhCN: '标志, Snapchat, 社交, 聊天, 即时通讯, 图片, 分享',
            enUS: 'Logo, Snapchat, social, chat, instant messaging, pictures, sharing',
            zhSpell: 'biaozhi, Snapchat, shejiao, liaotian, jishitongxun, tupian, fenxiang',
          },
          group: 'logo',
        },
        {
          iconCode: 60808,
          searchKey: {
            zhCN: '标志, 推特, 社交, 分享, 平台, 网站, 社区',
            enUS: 'Logo, tweet, twitter, social, sharing, platform, website, community',
            zhSpell: 'biaozhi, tuite, shejiao, fenxiang, pingtai, wangzhan, shequ',
          },
          group: 'logo',
        },
        {
          iconCode: 60809,
          searchKey: {
            zhCN: '标志, Tumblr, 轻博客, 微博客, 社交, 分享',
            enUS: 'Logo, Tumblr, light blog, microblogging, social, sharing',
            zhSpell: 'biaozhi, Tumblr, qingboke, weiboke, shejiao, fenxiang',
          },
          group: 'logo',
        },
        {
          iconCode: 60810,
          searchKey: {
            zhCN: '标志, 推特, 社交, 分享, 平台, 网站, 社区',
            enUS: 'Logo, tweet, twitter, social, sharing, platform, website, community',
            zhSpell: 'biaozhi, tuite, shejiao, fenxiang, pingtai, wangzhan, shequ',
          },
          group: 'logo',
        },
        {
          iconCode: 60811,
          searchKey: {
            zhCN: '标志, Tumblr, 轻博客, 微博客, 社交, 分享',
            enUS: 'Logo, Tumblr, light blog, microblogging, social, sharing',
            zhSpell: 'biaozhi, Tumblr, qingboke, weiboke, shejiao, fenxiang',
          },
          group: 'logo',
        },
        {
          iconCode: 60812,
          searchKey: {
            zhCN: '标志, Whatsapp, 聊天软件, 聊天工具, 交流, 信息, 消息',
            enUS: 'Logo, Whatsapp, chat software, chat tool, communication, information, message',
            zhSpell: 'biaozhi, Whatsapp, liaotianruanjian, liaotiangongju, jiaoliu, xinxi, xiaoxi',
          },
          group: 'logo',
        },
        {
          iconCode: 60813,
          searchKey: {
            zhCN: '标志, Vimeo, 视频博客, 社交, 分享, 网站',
            enUS: 'Logo, Vimeo, video blog, social, sharing, website',
            zhSpell: 'biaozhi, Vimeo, shipinboke, shejiao, fenxiang, wangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60814,
          searchKey: {
            zhCN: '标志, 推特, 社交, 分享, 平台, 网站, 社区',
            enUS: 'Logo, tweet, twitter, social, sharing, platform, website, community',
            zhSpell: 'biaozhi, tuite, shejiao, fenxiang, pingtai, wangzhan, shequ',
          },
          group: 'logo',
        },
        {
          iconCode: 60815,
          searchKey: {
            zhCN: '标志, Whatsapp, 聊天软件, 聊天工具, 交流, 信息, 消息',
            enUS: 'Logo, Whatsapp, chat software, chat tool, communication, information, message',
            zhSpell: 'biaozhi, Whatsapp, liaotianruanjian, liaotiangongju, jiaoliu, xinxi, xiaoxi',
          },
          group: 'logo',
        },
        {
          iconCode: 60816,
          searchKey: {
            zhCN: '标志, Xbox, 电视游戏机, 微软, 游戏, 平台',
            enUS: 'Logo, Xbox, video game console, Microsoft, games, platform',
            zhSpell: 'biaozhi, Xbox, dianshiyouxiji, weiruan, youxi, pingtai',
          },
          group: 'logo',
        },
        {
          iconCode: 60817,
          searchKey: {
            zhCN: '标志, Windows, 操作系统, 软件, 微软, 电脑, 窗口',
            enUS: 'Logo, windows, operating system, software, microsoft, computer, window',
            zhSpell: 'biaozhi, Windows, caozuoxitong, ruanjian, weiruan, diannao, chuangkou',
          },
          group: 'logo',
        },
        {
          iconCode: 60818,
          searchKey: {
            zhCN: '标志, Windows, 操作系统, 软件, 微软, 电脑, 窗口',
            enUS: 'Logo, windows, operating system, software, microsoft, computer, window',
            zhSpell: 'biaozhi, Windows, caozuoxitong, ruanjian, weiruan, diannao, chuangkou',
          },
          group: 'logo',
        },
        {
          iconCode: 60819,
          searchKey: {
            zhCN: '标志, Wordpress, 网站, 博客, 开源, 内容管理软件',
            enUS: 'Logo, Wordpress, Website, Blog, Open Source, Content Management Software',
            zhSpell: 'biaozhi, Wordpress, wangzhan, boke, kaiyuan, narongguanliruanjian',
          },
          group: 'logo',
        },
        {
          iconCode: 60820,
          searchKey: {
            zhCN: '标志, 小米, 手机, 移动设备, 智能, 数码',
            enUS: 'Logo, millet, mobile phone, mobile device, smart, digital',
            zhSpell: 'biaozhi, xiaomi, shouji, yidongshebei, zhinen, shuma',
          },
          group: 'logo',
        },
        {
          iconCode: 60821,
          searchKey: {
            zhCN: '标志, Xing, 社交, 职业, 求职, 招聘, 工作',
            enUS: 'Logo, Xing, social, vocational, job, recruitment, work',
            zhSpell: 'biaozhi, Xing, shejiao, zhiye, qiuzhi, zhaopin, gongzuo',
          },
          group: 'logo',
        },
        {
          iconCode: 60822,
          searchKey: {
            zhCN: '标志, Youtube, 视频, 社交, 分享, 娱乐, 视频网站',
            enUS: 'Logo, Youtube, video, social, sharing, entertainment, video site',
            zhSpell: 'biaozhi, Youtube, shipin, shejiao, fenxiang, yule, shipinwangzhan',
          },
          group: 'logo',
        },
        {
          iconCode: 60823,
          searchKey: {
            zhCN: '标志, Linux, 系统, 操作系统, 电脑, 企业',
            enUS: 'Logo, Linux, system, operating system, computer, enterprise',
            zhSpell: 'biaozhi, Linux, xitong, caozuoxitong, diannao, qiye',
          },
          group: 'logo',
        },
      ],
    },
    {
      name: 'App',
      id: 'app',
      items: [
        {
          iconCode: 60581,
          searchKey: {
            zhCN: '餐具, 刀叉, 用餐, 吃饭, 餐馆',
            enUS: 'Tableware, cutlery, dining, eating, restaurant',
            zhSpell: 'canju, daocha, yongcan, chifan, canguan',
          },
          group: 'app',
        },
        {
          iconCode: 60582,
          searchKey: {
            zhCN: '尺寸, 宽, 宽度, 增宽, 加宽, 箭头, 长方形',
            enUS: 'Size, width, width, widening, widening, arrow, rectangle',
            zhSpell: 'chicun, kuan, kuandu, zengkuan, jiakuan, jiantou, changfangxing',
          },
          group: 'app',
        },
        {
          iconCode: 60583,
          searchKey: {
            zhCN: '尺寸, 高, 高度, 增高, 加高, 箭头, 长方形',
            enUS: 'Size, height, height, height, height, arrow, rectangle',
            zhSpell: 'chicun, gao, gaodu, zenggao, jiagao, jiantou, changfangxing',
          },
          group: 'app',
        },
        {
          iconCode: 60584,
          searchKey: {
            zhCN: '报警, 警察, 报警器, 警报, 铃声, 闪光',
            enUS: 'Alarm, police, alarm, alarm, ringtone, flash',
            zhSpell: 'baojing, jingcha, baojingqi, jingbao, lingsheng, shanguang',
          },
          group: 'app',
        },
        {
          iconCode: 60585,
          searchKey: {
            zhCN: '盾牌, 安全, 保障, 屏障, 防护, 保护',
            enUS: 'Shield, security, security, barrier, protection, protection',
            zhSpell: 'dunpai, anquan, baozhang, pingzhang, fanghu, baohu',
          },
          group: 'app',
        },
        {
          iconCode: 60586,
          searchKey: {
            zhCN: '拨号, 点, 电话, 拨号键盘, 数字, 输入',
            enUS: 'Dial, point, phone, dial pad, number, input',
            zhSpell: 'bohao, dian, dianhua, bohaojianpan, shuzi, shuru',
          },
          group: 'app',
        },
        {
          iconCode: 60587,
          searchKey: {
            zhCN: '发布, 飞机, 发送, 传达, 邮件',
            enUS: 'Release, aircraft, send, communicate, mail',
            zhSpell: 'fabu, feiji, fasong, chuanda, youjian',
          },
          group: 'app',
        },
        {
          iconCode: 60588,
          searchKey: {
            zhCN: '抽样, 调查, 填写, 表单, 检查, 核对',
            enUS: 'Sampling, investigation, filling out, form, checking, checking',
            zhSpell: 'chouyang, diaocha, tianxie, biaodan, jiancha, hedui',
          },
          group: 'app',
        },
        {
          iconCode: 60589,
          searchKey: {
            zhCN: '电话, 固话, 座机, 沟通, 联系, 设备, 拨打',
            enUS: 'Telephone, fixed line, communication, contact, equipment, dialing',
            zhSpell: 'dianhua, guhua, zuoji, goutong, lianxi, shebei, boda',
          },
          group: 'app',
        },
        {
          iconCode: 60590,
          searchKey: {
            zhCN: '拨号, 点, 电话, 拨号键盘, 数字, 输入',
            enUS: 'Dial, point, phone, dial pad, number, input',
            zhSpell: 'bohao, dian, dianhua, bohaojianpan, shuzi, shuru',
          },
          group: 'app',
        },
        {
          iconCode: 60591,
          searchKey: {
            zhCN: '耳机, 语音, 客服, 服务, 接听, 人工服务',
            enUS: 'Headset, voice, customer service, service, answer, manual service',
            zhSpell: 'erji, yuyin, kefu, fuwu, jieting, rengongfuwu',
          },
          group: 'app',
        },
        {
          iconCode: 60592,
          searchKey: {
            zhCN: '定位, 位置, 导航, 方向, 瞄准, 对准',
            enUS: 'Positioning, position, navigation, direction, aiming, alignment',
            zhSpell: 'dingwei, weizhi, daohang, fangxiang, miaozhun, duizhun',
          },
          group: 'app',
        },
        {
          iconCode: 60593,
          searchKey: {
            zhCN: '耳机, 语音, 客服, 服务, 接听, 人工服务',
            enUS: 'Headset, voice, customer service, service, answer, manual service',
            zhSpell: 'erji, yuyin, kefu, fuwu, jieting, rengongfuwu',
          },
          group: 'app',
        },
        {
          iconCode: 60594,
          searchKey: {
            zhCN: '电话, 固话, 座机, 沟通, 联系, 设备, 拨打',
            enUS: 'Telephone, fixed line, communication, contact, equipment, dialing',
            zhSpell: 'dianhua, guhua, zuoji, goutong, lianxi, shebei, boda',
          },
          group: 'app',
        },
        {
          iconCode: 60595,
          searchKey: {
            zhCN: '负载均衡, 三角形, 稳定, 形状, 数据',
            enUS: 'Load balancing, triangle, stability, shape, data',
            zhSpell: 'fuzaijunheng, sanjiaoxing, wending, xingzhuang, shuju',
          },
          group: 'app',
        },
        {
          iconCode: 60596,
          searchKey: {
            zhCN: '负载均衡, 三角形, 稳定, 形状, 数据',
            enUS: 'Load balancing, triangle, stability, shape, data',
            zhSpell: 'fuzaijunheng, sanjiaoxing, wending, xingzhuang, shuju',
          },
          group: 'app',
        },
        {
          iconCode: 60597,
          searchKey: {
            zhCN: '盾牌, 安全, 保障, 屏障, 防护, 保护',
            enUS: 'Shield, security, security, barrier, protection, protection',
            zhSpell: 'dunpai, anquan, baozhang, pingzhang, fanghu, baohu',
          },
          group: 'app',
        },
        {
          iconCode: 60598,
          searchKey: {
            zhCN: '设置, 偏好, 配置, 工具, 修理, 扳手',
            enUS: 'Settings, preferences, configuration, tools, repair, wrench',
            zhSpell: 'shezhi, pianhao, peizhi, gongju, xiuli, banshou',
          },
          group: 'app',
        },
        {
          iconCode: 60599,
          searchKey: {
            zhCN: '设置, 偏好, 配置, 工具, 修理, 扳手',
            enUS: 'Settings, preferences, configuration, tools, repair, wrench',
            zhSpell: 'shezhi, pianhao, peizhi, gongju, xiuli, banshou',
          },
          group: 'app',
        },
        {
          iconCode: 60600,
          searchKey: {
            zhCN: '工具箱, 包, 箱子, 手提箱, 公文包, 办公, 商务',
            enUS: 'Toolbox, bag, box, suitcase, briefcase, office, business',
            zhSpell: 'gongjuxiang, bao, xiangzi, shoutixiang, gongwenbao, bangong, shangwu',
          },
          group: 'app',
        },
        {
          iconCode: 60601,
          searchKey: {
            zhCN: '购物车, 减少, 清空, 购物, 商场, 超市, 电子商务',
            enUS: 'Shopping cart, reduce, empty, shopping, mall, supermarket, e-commerce',
            zhSpell: 'gouwuche, jianshao, qingkong, gouwu, shangchang, chaoshi, dianzishangwu',
          },
          group: 'app',
        },
        {
          iconCode: 60602,
          searchKey: {
            zhCN: '购物车, 添加, 增加, 商品, 商场, 超市, 电子商务',
            enUS: 'Shopping cart, add, merchandise, mall, supermarket, e-commerce',
            zhSpell: 'gouwuche, tianjia, zengjia, shangpin, shangchang, chaoshi, dianzishangwu',
          },
          group: 'app',
        },
        {
          iconCode: 60603,
          searchKey: {
            zhCN: '购物车, 减少, 清空, 购物, 商场, 超市, 电子商务',
            enUS: 'Shopping cart, reduce, empty, shopping, mall, supermarket, e-commerce',
            zhSpell: 'gouwuche, jianshao, qingkong, gouwu, shangchang, chaoshi, dianzishangwu',
          },
          group: 'app',
        },
        {
          iconCode: 60604,
          searchKey: {
            zhCN: '购物车, 购物, 商品, 商场, 超市, 电子商务',
            enUS: 'Shopping cart, shopping, merchandise, shopping mall, supermarket, e-commerce',
            zhSpell: 'gouwuche, gouwu, shangpin, shangchang, chaoshi, dianzishangwu',
          },
          group: 'app',
        },
        {
          iconCode: 60605,
          searchKey: {
            zhCN: '购物, 购物袋, 应用商店, 安装软件, 下载',
            enUS: 'Shopping, shopping bags, app store, install software, download',
            zhSpell: 'gouwu, gouwudai, yingyongshangdian, anzhuangruanjian, xiazai',
          },
          group: 'app',
        },
        {
          iconCode: 60606,
          searchKey: {
            zhCN: '公告, 喇叭, 广播, 语音, 宣告, 声音, 扩音器',
            enUS: 'Announcement, horn, broadcast, voice, announcement, sound, megaphone',
            zhSpell: 'gonggao, laba, guangbo, yuyin, xuangao, shengyin, kuoyinqi',
          },
          group: 'app',
        },
        {
          iconCode: 60607,
          searchKey: {
            zhCN: '设置, 偏好, 配置, 工具, 修理, 扳手',
            enUS: 'Settings, preferences, configuration, tools, repair, wrench',
            zhSpell: 'shezhi, pianhao, peizhi, gongju, xiuli, banshou',
          },
          group: 'app',
        },
        {
          iconCode: 60608,
          searchKey: {
            zhCN: '购物车, 添加, 增加, 商品, 商场, 超市, 电子商务',
            enUS: 'Shopping cart, add, merchandise, mall, supermarket, e-commerce',
            zhSpell: 'gouwuche, tianjia, zengjia, shangpin, shangchang, chaoshi, dianzishangwu',
          },
          group: 'app',
        },
        {
          iconCode: 60609,
          searchKey: {
            zhCN: '公告, 喇叭, 广播, 语音, 宣告, 声音, 扩音器',
            enUS: 'Announcement, horn, broadcast, voice, announcement, sound, megaphone',
            zhSpell: 'gonggao, laba, guangbo, yuyin, xuangao, shengyin, kuoyinqi',
          },
          group: 'app',
        },
        {
          iconCode: 60610,
          searchKey: {
            zhCN: '购物车, 空的, 商品, 商场, 超市, 电子商务',
            enUS: 'Shopping cart, empty, merchandise, shopping mall, supermarket, e-commerce',
            zhSpell: 'gouwuche, kongde, shangpin, shangchang, chaoshi, dianzishangwu',
          },
          group: 'app',
        },
        {
          iconCode: 60611,
          searchKey: {
            zhCN: '皇冠, 荣誉, 国王, 国家, 尊贵, 加冕',
            enUS: 'Crown, honor, king, country, honor, crown',
            zhSpell: 'huangguan, rongyu, guowang, guojia, zungui, jiamian',
          },
          group: 'app',
        },
        {
          iconCode: 60612,
          searchKey: {
            zhCN: '盒子, 文件, 存储箱, 存储, 档案',
            enUS: 'Box, file, storage box, storage, file',
            zhSpell: 'hezi, wenjian, cunchuxiang, cunchu, dangan',
          },
          group: 'app',
        },
        {
          iconCode: 60613,
          searchKey: {
            zhCN: '购物车, 购物, 商品, 商场, 超市, 电子商务',
            enUS: 'Shopping cart, shopping, merchandise, shopping mall, supermarket, e-commerce',
            zhSpell: 'gouwuche, gouwu, shangpin, shangchang, chaoshi, dianzishangwu',
          },
          group: 'app',
        },
        {
          iconCode: 60614,
          searchKey: {
            zhCN: '加载, 等待, 执行, 稍等, 刷新',
            enUS: 'Load, wait, execute, wait, refresh',
            zhSpell: 'jiazai, dengdai, zhixing, shaodeng, shuaxin',
          },
          group: 'app',
        },
        {
          iconCode: 60615,
          searchKey: {
            zhCN: '皇冠, 荣誉, 国王, 国家, 尊贵, 加冕',
            enUS: 'Crown, honor, king, country, honor, crown',
            zhSpell: 'huangguan, rongyu, guowang, guojia, zungui, jiamian',
          },
          group: 'app',
        },
        {
          iconCode: 60616,
          searchKey: {
            zhCN: '购物车, 空的, 商品, 商场, 超市, 电子商务',
            enUS: 'Shopping cart, empty, merchandise, shopping mall, supermarket, e-commerce',
            zhSpell: 'gouwuche, kongde, shangpin, shangchang, chaoshi, dianzishangwu',
          },
          group: 'app',
        },
        {
          iconCode: 60617,
          searchKey: {
            zhCN: '建筑, 房子, 大学, 学校, 图书馆',
            enUS: 'Building, house, university, school, library',
            zhSpell: 'jianzhu, fangzi, daxue, xuexiao, tushuguan',
          },
          group: 'app',
        },
        {
          iconCode: 60618,
          searchKey: {
            zhCN: '奖章, 积分, 比赛, 荣誉, 奖品, 胜利',
            enUS: 'Medal, points, competition, honor, prize, victory',
            zhSpell: 'jiangzhang, jifen, bisai, rongyu, jiangpin, shengli',
          },
          group: 'app',
        },
        {
          iconCode: 60619,
          searchKey: {
            zhCN: '火, 火苗, 火焰, 燃烧, 热, 温度, 高温',
            enUS: 'Fire, flame, flame, burning, heat, temperature, high temperature',
            zhSpell: 'huo, huomiao, huoyan, ranshao, re, wendu, gaowen',
          },
          group: 'app',
        },
        {
          iconCode: 60620,
          searchKey: {
            zhCN: '奖杯, 获奖, 比赛, 荣誉, 奖品, 胜利',
            enUS: 'Trophy, award, competition, honor, prize, victory',
            zhSpell: 'jiangbei, huojiang, bisai, rongyu, jiangpin, shengli',
          },
          group: 'app',
        },
        {
          iconCode: 60621,
          searchKey: {
            zhCN: '皇冠, 荣誉, 国王, 国家, 尊贵, 加冕',
            enUS: 'Crown, honor, king, country, honor, crown',
            zhSpell: 'huangguan, rongyu, guowang, guojia, zungui, jiamian',
          },
          group: 'app',
        },
        {
          iconCode: 60622,
          searchKey: {
            zhCN: '皇冠, 荣誉, 国王, 国家, 尊贵, 加冕',
            enUS: 'Crown, honor, king, country, honor, crown',
            zhSpell: 'huangguan, rongyu, guowang, guojia, zungui, jiamian',
          },
          group: 'app',
        },
        {
          iconCode: 60623,
          searchKey: {
            zhCN: '购物车, 空的, 商品, 商场, 超市, 电子商务',
            enUS: 'Shopping cart, empty, merchandise, shopping mall, supermarket, e-commerce',
            zhSpell: 'gouwuche, kongde, shangpin, shangchang, chaoshi, dianzishangwu',
          },
          group: 'app',
        },
        {
          iconCode: 60624,
          searchKey: {
            zhCN: '奖杯, 获奖, 比赛, 荣誉, 奖品, 胜利',
            enUS: 'Trophy, award, competition, honor, prize, victory',
            zhSpell: 'jiangbei, huojiang, bisai, rongyu, jiangpin, shengli',
          },
          group: 'app',
        },
        {
          iconCode: 60625,
          searchKey: {
            zhCN: '奖章, 积分, 比赛, 荣誉, 奖品, 胜利',
            enUS: 'Medal, points, competition, honor, prize, victory',
            zhSpell: 'jiangzhang, jifen, bisai, rongyu, jiangpin, shengli',
          },
          group: 'app',
        },
        {
          iconCode: 60626,
          searchKey: {
            zhCN: '活动, 棒球, 体育, 运动, 球类',
            enUS: 'Activity, baseball, sports, sports, ball',
            zhSpell: 'huodong, bangqiu, tiyu, yundong, qiulei',
          },
          group: 'app',
        },
        {
          iconCode: 60627,
          searchKey: {
            zhCN: '酒杯, 杯子, 饮料, 饮品, 喝酒, 酒吧',
            enUS: 'Wine glasses, mugs, drinks, drinks, drinks, bars',
            zhSpell: 'jiubei, beizi, yinliao, yinpin, hejiu, jiuba',
          },
          group: 'app',
        },
        {
          iconCode: 60628,
          searchKey: {
            zhCN: '奖牌, 积分, 比赛, 荣誉, 奖品, 胜利, 金牌',
            enUS: 'Medals, points, matches, honors, prizes, victory, gold medals',
            zhSpell: 'jiangpai, jifen, bisai, rongyu, jiangpin, shengli, jinpai',
          },
          group: 'app',
        },
        {
          iconCode: 60629,
          searchKey: {
            zhCN: '酒杯, 杯子, 饮料, 饮品, 喝酒, 酒吧',
            enUS: 'Wine glasses, mugs, drinks, drinks, drinks, bars',
            zhSpell: 'jiubei, beizi, yinliao, yinpin, hejiu, jiuba',
          },
          group: 'app',
        },
        {
          iconCode: 60630,
          searchKey: {
            zhCN: '咖啡, 休息一下, 饮料, 杯子, 喝水, 咖啡店',
            enUS: 'Coffee, break, drink, cup, drink, coffee shop',
            zhSpell: 'kafei, xiuxiyixia, yinliao, beizi, heshui, kafeidian',
          },
          group: 'app',
        },
        {
          iconCode: 60631,
          searchKey: {
            zhCN: '酒杯, 杯子, 饮料, 饮品, 喝酒, 酒吧',
            enUS: 'Wine glasses, mugs, drinks, drinks, drinks, bars',
            zhSpell: 'jiubei, beizi, yinliao, yinpin, hejiu, jiuba',
          },
          group: 'app',
        },
        {
          iconCode: 60632,
          searchKey: {
            zhCN: '公告, 喇叭, 广播, 语音, 宣告, 声音, 扩音器',
            enUS: 'Announcement, horn, broadcast, voice, announcement, sound, megaphone',
            zhSpell: 'gonggao, laba, guangbo, yuyin, xuangao, shengyin, kuoyinqi',
          },
          group: 'app',
        },
        {
          iconCode: 60633,
          searchKey: {
            zhCN: '奖章, 积分, 比赛, 荣誉, 奖品, 胜利',
            enUS: 'Medal, points, competition, honor, prize, victory',
            zhSpell: 'jiangzhang, jifen, bisai, rongyu, jiangpin, shengli',
          },
          group: 'app',
        },
        {
          iconCode: 60634,
          searchKey: {
            zhCN: '开关, 调节, 按钮, 切换, 控制',
            enUS: 'Switch, adjustment, button, switch, control',
            zhSpell: 'kaiguan, tiaojie, anniu, qiehuan, kongzhi',
          },
          group: 'app',
        },
        {
          iconCode: 60635,
          searchKey: {
            zhCN: '开关, 调节, 按钮, 切换, 控制',
            enUS: 'Switch, adjustment, button, switch, control',
            zhSpell: 'kaiguan, tiaojie, anniu, qiehuan, kongzhi',
          },
          group: 'app',
        },
        {
          iconCode: 60636,
          searchKey: {
            zhCN: '加载, 等待, 执行, 稍等, 刷新',
            enUS: 'Load, wait, execute, wait, refresh',
            zhSpell: 'jiazai, dengdai, zhixing, shaodeng, shuaxin',
          },
          group: 'app',
        },
        {
          iconCode: 60637,
          searchKey: {
            zhCN: '铃铛, 提醒, 提示, 消息, 通知, 闹铃',
            enUS: 'Bell, reminder, reminder, message, notification, alarm',
            zhSpell: 'lingdang, tixing, tishi, xiaoxi, tongzhi, naoling',
          },
          group: 'app',
        },
        {
          iconCode: 60638,
          searchKey: {
            zhCN: '礼物, 盒子, 奖品, 生日, 节日',
            enUS: 'Gift, box, prize, birthday, holiday',
            zhSpell: 'liwu, hezi, jiangpin, shengri, jieri',
          },
          group: 'app',
        },
        {
          iconCode: 60639,
          searchKey: {
            zhCN: '铃铛, 提醒, 提示, 消息, 通知, 闹铃',
            enUS: 'Bell, reminder, reminder, message, notification, alarm',
            zhSpell: 'lingdang, tixing, tishi, xiaoxi, tongzhi, naoling',
          },
          group: 'app',
        },
        {
          iconCode: 60640,
          searchKey: {
            zhCN: '铃铛, 提醒, 提示, 消息, 通知, 闹铃',
            enUS: 'Bell, reminder, reminder, message, notification, alarm',
            zhSpell: 'lingdang, tixing, tishi, xiaoxi, tongzhi, naoling',
          },
          group: 'app',
        },
        {
          iconCode: 60641,
          searchKey: {
            zhCN: '烹饪, 美食, 厨师, 厨房, 用餐',
            enUS: 'Cooking, food, chef, kitchen, dining',
            zhSpell: 'pengren, meishi, chushi, chufang, yongcan',
          },
          group: 'app',
        },
        {
          iconCode: 60642,
          searchKey: {
            zhCN: '旗帜, 标记, 胜利, 目标, 国旗, 红旗',
            enUS: 'Banner, mark, victory, goal, flag, red flag',
            zhSpell: 'qizhi, biaoji, shengli, mubiao, guoqi, hongqi',
          },
          group: 'app',
        },
        {
          iconCode: 60643,
          searchKey: {
            zhCN: '企业, 大楼, 写字楼, 建筑, 城市',
            enUS: 'Business, building, office building, building, city',
            zhSpell: 'qiye, dalou, xiezilou, jianzhu, chengshi',
          },
          group: 'app',
        },
        {
          iconCode: 60644,
          searchKey: {
            zhCN: '旗帜, 标记, 胜利, 目标, 国旗, 红旗',
            enUS: 'Banner, mark, victory, goal, flag, red flag',
            zhSpell: 'qizhi, biaoji, shengli, mubiao, guoqi, hongqi',
          },
          group: 'app',
        },
        {
          iconCode: 60645,
          searchKey: {
            zhCN: '烹饪, 美食, 厨师, 厨房, 用餐',
            enUS: 'Cooking, food, chef, kitchen, dining',
            zhSpell: 'pengren, meishi, chushi, chufang, yongcan',
          },
          group: 'app',
        },
        {
          iconCode: 60646,
          searchKey: {
            zhCN: '铃铛, 提醒, 提示, 消息, 通知, 闹铃',
            enUS: 'Bell, reminder, reminder, message, notification, alarm',
            zhSpell: 'lingdang, tixing, tishi, xiaoxi, tongzhi, naoling',
          },
          group: 'app',
        },
        {
          iconCode: 60647,
          searchKey: {
            zhCN: '切换账号, 交换, 按钮, 改变, 箭头',
            enUS: 'Switch account, exchange, button, change, arrow',
            zhSpell: 'qiehuanzhanghao, jiaohuan, anniu, gaibian, jiantou',
          },
          group: 'app',
        },
        {
          iconCode: 60648,
          searchKey: {
            zhCN: '切换账号, 交换, 按钮, 改变, 箭头',
            enUS: 'Switch account, exchange, button, change, arrow',
            zhSpell: 'qiehuanzhanghao, jiaohuan, anniu, gaibian, jiantou',
          },
          group: 'app',
        },
        {
          iconCode: 60649,
          searchKey: {
            zhCN: '目标, 定位, 位置, 导航, 方向, 瞄准',
            enUS: 'Target, positioning, location, navigation, direction, aiming',
            zhSpell: 'mubiao, dingwei, weizhi, daohang, fangxiang, miaozhun',
          },
          group: 'app',
        },
        {
          iconCode: 60650,
          searchKey: {
            zhCN: '人脸识别, 认证, 开启, 解锁, 身份识别',
            enUS: 'Face recognition, authentication, opening, unlocking, identification',
            zhSpell: 'renlianshibie, renzheng, kaiqi, jiesuo, shenfenshibie',
          },
          group: 'app',
        },
        {
          iconCode: 60651,
          searchKey: {
            zhCN: '企业, 大楼, 写字楼, 建筑, 城市',
            enUS: 'Business, building, office building, building, city',
            zhSpell: 'qiye, dalou, xiezilou, jianzhu, chengshi',
          },
          group: 'app',
        },
        {
          iconCode: 60652,
          searchKey: {
            zhCN: '奖牌, 积分, 比赛, 荣誉, 奖品, 胜利, 金牌',
            enUS: 'Medals, points, matches, honors, prizes, victory, gold medals',
            zhSpell: 'jiangpai, jifen, bisai, rongyu, jiangpin, shengli, jinpai',
          },
          group: 'app',
        },
        {
          iconCode: 60653,
          searchKey: {
            zhCN: '商店, 店铺, 铺子, 商业, 购物, 商品',
            enUS: 'Shop, shop, shop, business, shopping, merchandise',
            zhSpell: 'shangdian, dianpu, puzi, shangye, gouwu, shangpin',
          },
          group: 'app',
        },
        {
          iconCode: 60654,
          searchKey: {
            zhCN: '奖章, 积分, 比赛, 荣誉, 奖品, 胜利',
            enUS: 'Medal, points, competition, honor, prize, victory',
            zhSpell: 'jiangzhang, jifen, bisai, rongyu, jiangpin, shengli',
          },
          group: 'app',
        },
        {
          iconCode: 60655,
          searchKey: {
            zhCN: '书籍, 收藏, 阅读, 字典, 学习, 书本',
            enUS: 'Books, collections, reading, dictionaries, learning, books',
            zhSpell: 'shuji, shoucang, yuedu, zidian, xuexi, shuben',
          },
          group: 'app',
        },
        {
          iconCode: 60656,
          searchKey: {
            zhCN: '书籍, 图书馆, 书本, 字典, 学习',
            enUS: 'Books, library, books, dictionaries, learning',
            zhSpell: 'shuji, tushuguan, shuben, zidian, xuexi',
          },
          group: 'app',
        },
        {
          iconCode: 60657,
          searchKey: {
            zhCN: '身份证, 证件, 驾照, 护照, 照片',
            enUS: 'ID card, ID, driver, slicense, passport, photo',
            zhSpell: 'shenfenzheng, zhengjian, jiazhao, huzhao, zhaopian',
          },
          group: 'app',
        },
        {
          iconCode: 60658,
          searchKey: {
            zhCN: '清理, 清除, 刷子, 格式刷, 装饰, 装修, 油漆',
            enUS: 'Cleaning, brush, format brush, decoration, decoration, paint',
            zhSpell: 'qingli, qingchu, shuazi, geshishua, zhuangshi, zhuangxiu, youqi',
          },
          group: 'app',
        },
        {
          iconCode: 60659,
          searchKey: {
            zhCN: '刷子, 粉刷, 装修, 格式刷, 油漆, 装饰',
            enUS: 'Brush, paint, decoration, format brush, paint, decoration',
            zhSpell: 'shuazi, fenshua, zhuangxiu, geshishua, youqi, zhuangshi',
          },
          group: 'app',
        },
        {
          iconCode: 60660,
          searchKey: {
            zhCN: '锁, 开锁, 上锁, 安全, 关闭, 加密, 密码',
            enUS: 'Lock, unlock, lock, secure, close, encrypt, password',
            zhSpell: 'suo, kaisuo, shangsuo, anquan, guanbi, jiami, mima',
          },
          group: 'app',
        },
        {
          iconCode: 60661,
          searchKey: {
            zhCN: '书籍, 笔记本, 学习, 阅读, 文件',
            enUS: 'Books, notebooks, learning, reading, documents',
            zhSpell: 'shuji, bijiben, xuexi, yuedu, wenjian',
          },
          group: 'app',
        },
        {
          iconCode: 60662,
          searchKey: {
            zhCN: '伞, 下雨, 雨伞, 保护, 天气, 雨天',
            enUS: 'Umbrella, rain, umbrella, protection, weather, rainy day',
            zhSpell: 'san, xiayu, yusan, baohu, tianqi, yutian',
          },
          group: 'app',
        },
        {
          iconCode: 60663,
          searchKey: {
            zhCN: '伞, 下雨, 雨伞, 保护, 天气, 雨天',
            enUS: 'Umbrella, rain, umbrella, protection, weather, rainy day',
            zhSpell: 'san, xiayu, yusan, baohu, tianqi, yutian',
          },
          group: 'app',
        },
        {
          iconCode: 60664,
          searchKey: {
            zhCN: '书籍, 字典, 学习, 阅读, 查阅, 看书',
            enUS: 'Books, dictionaries, learning, reading, reading, reading',
            zhSpell: 'shuji, zidian, xuexi, yuedu, chayue, kanshu',
          },
          group: 'app',
        },
        {
          iconCode: 60665,
          searchKey: {
            zhCN: '锁, 开锁, 解锁, 安全, 打开, 解密, 密码',
            enUS: 'Lock, unlock, unlock, secure, open, decrypt, password',
            zhSpell: 'suo, kaisuo, jiesuo, anquan, dakai, jiemi, mima',
          },
          group: 'app',
        },
        {
          iconCode: 60666,
          searchKey: {
            zhCN: '锁, 开锁, 上锁, 安全, 关闭, 加密, 密码',
            enUS: 'Lock, unlock, lock, secure, close, encrypt, password',
            zhSpell: 'suo, kaisuo, shangsuo, anquan, guanbi, jiami, mima',
          },
          group: 'app',
        },
        {
          iconCode: 60667,
          searchKey: {
            zhCN: '刷子, 粉刷, 装修, 格式刷, 油漆, 装饰',
            enUS: 'Brush, paint, decoration, format brush, paint, decoration',
            zhSpell: 'shuazi, fenshua, zhuangxiu, geshishua, youqi, zhuangshi',
          },
          group: 'app',
        },
        {
          iconCode: 60668,
          searchKey: {
            zhCN: '天气, 大雨, 云, 下雨, 有雨',
            enUS: 'Weather, heavy rain, clouds, rain, rain',
            zhSpell: 'tianqi, dayu, yun, xiayu, youyu',
          },
          group: 'app',
        },
        {
          iconCode: 60669,
          searchKey: {
            zhCN: '天气, 暴雨, 大雨, 下雨, 云',
            enUS: 'Weather, heavy rain, heavy rain, rain, clouds',
            zhSpell: 'tianqi, baoyu, dayu, xiayu, yun',
          },
          group: 'app',
        },
        {
          iconCode: 60670,
          searchKey: {
            zhCN: '锁, 开锁, 解锁, 安全, 打开, 解密, 密码',
            enUS: 'Lock, unlock, unlock, secure, open, decrypt, password',
            zhSpell: 'suo, kaisuo, jiesuo, anquan, dakai, jiemi, mima',
          },
          group: 'app',
        },
        {
          iconCode: 60671,
          searchKey: {
            zhCN: '天气, 风, 吹风, 大风, 风暴, 台风',
            enUS: 'Weather, wind, hair, wind, storm, typhoon',
            zhSpell: 'tianqi, feng, chuifeng, dafeng, fengbao, taifeng',
          },
          group: 'app',
        },
        {
          iconCode: 60672,
          searchKey: {
            zhCN: '温度计, 温度, 热度, 摄氏度, 量体温',
            enUS: 'Thermometer, temperature, heat, Celsius, body temperature',
            zhSpell: 'wenduji, wendu, redu, sheshidu, liangtiwen',
          },
          group: 'app',
        },
        {
          iconCode: 60673,
          searchKey: {
            zhCN: '天气, 雪, 寒冷, 下雪, 雪花, 冬天',
            enUS: 'Weather, snow, cold, snow, snowflakes, winter',
            zhSpell: 'tianqi, xue, hanling, xiaxue, xuehua, dongtian',
          },
          group: 'app',
        },
        {
          iconCode: 60674,
          searchKey: {
            zhCN: '天气, 晴有云, 晴, 转晴, 太阳',
            enUS: 'Weather, sunny, sunny, sunny, sun',
            zhSpell: 'tianqi, qingyouyun, qing, zhuanqing, taiyang',
          },
          group: 'app',
        },
        {
          iconCode: 60675,
          searchKey: {
            zhCN: '天气, 太阳, 晴, 晴天, 亮度, 阳光, 白天',
            enUS: 'Weather, sun, sunny, sunny, brightness, sunshine, daytime',
            zhSpell: 'tianqi, taiyang, qing, qingtian, liangdu, yangguang, baitian',
          },
          group: 'app',
        },
        {
          iconCode: 60676,
          searchKey: {
            zhCN: '天气, 太阳, 晴, 晴天, 亮度, 阳光, 白天',
            enUS: 'Weather, sun, sunny, sunny, brightness, sunshine, daytime',
            zhSpell: 'tianqi, taiyang, qing, qingtian, liangdu, yangguang, baitian',
          },
          group: 'app',
        },
        {
          iconCode: 60677,
          searchKey: {
            zhCN: '服务管理, 捐助, 捐款, 手, 爱心, 关爱, 公益',
            enUS: 'Service management, donate, hand, love, care, charity',
            zhSpell: 'fuwuguanli, juanzhu, juankuan, shou, aixin, guanai, gongyi',
          },
          group: 'app',
        },
        {
          iconCode: 60678,
          searchKey: {
            zhCN: '项目, 应用, 菜单, 列表, 形状',
            enUS: 'Project, application, menu, list, shape',
            zhSpell: 'xiangmu, yingyong, caidan, liebiao, xingzhuang',
          },
          group: 'app',
        },
        {
          iconCode: 60679,
          searchKey: {
            zhCN: '项目, 应用, 菜单, 列表, 形状',
            enUS: 'Project, application, menu, list, shape',
            zhSpell: 'xiangmu, yingyong, caidan, liebiao, xingzhuang',
          },
          group: 'app',
        },
        {
          iconCode: 60680,
          searchKey: {
            zhCN: '项目, 应用, 菜单, 列表, 方块, 形状',
            enUS: 'Project, application, menu, list, square, shape',
            zhSpell: 'xiangmu, yingyong, caidan, liebiao, fangkuai, xingzhuang',
          },
          group: 'app',
        },
        {
          iconCode: 60681,
          searchKey: {
            zhCN: '项目, 应用, 菜单, 列表, 形状',
            enUS: 'Project, application, menu, list, shape',
            zhSpell: 'xiangmu, yingyong, caidan, liebiao, xingzhuang',
          },
          group: 'app',
        },
        {
          iconCode: 60682,
          searchKey: {
            zhCN: '形状, 星星, 五角星, 收藏, 评价, 喜欢',
            enUS: 'Shape, stars, pentagram, collection, rating, like',
            zhSpell: 'xingzhuang, xingxing, wujiaoxing, shoucang, pingjia, xihuan',
          },
          group: 'app',
        },
        {
          iconCode: 60683,
          searchKey: {
            zhCN: '形状, 几何, 三角形, 组合, 切换',
            enUS: 'Shape, geometry, triangle, combination, switch',
            zhSpell: 'xingzhuang, jihe, sanjiaoxing, zuhe, qiehuan',
          },
          group: 'app',
        },
        {
          iconCode: 60684,
          searchKey: {
            zhCN: '形状, 三角形, 稳定, 稳固, 向上',
            enUS: 'Shape, triangle, stable, steady, up',
            zhSpell: 'xingzhuang, sanjiaoxing, wending, wengu, xiangshang',
          },
          group: 'app',
        },
        {
          iconCode: 60685,
          searchKey: {
            zhCN: '钥匙, 解锁, 开锁, 密码, 加密, 机密',
            enUS: 'Key, unlock, unlock, password, encryption, secret',
            zhSpell: 'yuechi, jiesuo, kaisuo, mima, jiami, jimi',
          },
          group: 'app',
        },
        {
          iconCode: 60686,
          searchKey: {
            zhCN: '形状, 星星, 五角星, 收藏, 评价, 喜欢',
            enUS: 'Shape, stars, pentagram, collection, rating, like',
            zhSpell: 'xingzhuang, xingxing, wujiaoxing, shoucang, pingjia, xihuan',
          },
          group: 'app',
        },
        {
          iconCode: 60687,
          searchKey: {
            zhCN: '钥匙, 解锁, 开锁, 密码, 加密, 机密',
            enUS: 'Key, unlock, unlock, password, encryption, secret',
            zhSpell: 'yuechi, jiesuo, kaisuo, mima, jiami, jimi',
          },
          group: 'app',
        },
        {
          iconCode: 60688,
          searchKey: {
            zhCN: '天气, 闪电, 云, 雷电, 雷雨',
            enUS: 'Weather, lightning, clouds, thunder, thunderstorm',
            zhSpell: 'tianqi, shandian, yun, leidian, leiyu',
          },
          group: 'app',
        },
        {
          iconCode: 60689,
          searchKey: {
            zhCN: '应用, 立方体, 结构, 方块, 正方体',
            enUS: 'Application, cube, structure, square, cube',
            zhSpell: 'yingyong, lifangti, jiegou, fangkuai, zhengfangti',
          },
          group: 'app',
        },
        {
          iconCode: 60690,
          searchKey: {
            zhCN: '印章, 盖章, 公章, 审核, 审定',
            enUS: 'Seal, stamp, official seal, review, validation',
            zhSpell: 'yinzhang, gaizhang, gongzhang, shenhe, shending',
          },
          group: 'app',
        },
        {
          iconCode: 60691,
          searchKey: {
            zhCN: '应用, 立方体, 结构, 方块, 正方体',
            enUS: 'Application, cube, structure, square, cube',
            zhSpell: 'yingyong, lifangti, jiegou, fangkuai, zhengfangti',
          },
          group: 'app',
        },
        {
          iconCode: 60692,
          searchKey: {
            zhCN: '修改, 设置, 调整, 形状, 六边形, 螺母',
            enUS: 'Modify, set, adjust, shape, hexagon, nut',
            zhSpell: 'xiugai, shezhi, tiaozheng, xingzhuang, liubianxing, luomu',
          },
          group: 'app',
        },
        {
          iconCode: 60693,
          searchKey: {
            zhCN: '选择, 鼠标, 箭头, 方向, 点击',
            enUS: 'Choice, mouse, arrow, direction, click',
            zhSpell: 'xuanze, shubiao, jiantou, fangxiang, dianji',
          },
          group: 'app',
        },
        {
          iconCode: 60694,
          searchKey: {
            zhCN: '握手, 合作, 协作, 手, 友谊, 友好',
            enUS: 'Handshake, cooperation, collaboration, hand, friendship, friendship',
            zhSpell: 'woshou, hezuo, xiezuo, shou, youyi, youhao',
          },
          group: 'app',
        },
        {
          iconCode: 60695,
          searchKey: {
            zhCN: '印章, 盖章, 公章, 审核, 审定',
            enUS: 'Seal, stamp, official seal, review, validation',
            zhSpell: 'yinzhang, gaizhang, gongzhang, shenhe, shending',
          },
          group: 'app',
        },
        {
          iconCode: 60696,
          searchKey: {
            zhCN: '圆形, 选中, 圆圈, 形状, 实心, 填充',
            enUS: 'Circle, checked, circle, shape, solid, fill',
            zhSpell: 'yuanxing, xuanzhong, yuanquan, xingzhuang, shixin, tianchong',
          },
          group: 'app',
        },
        {
          iconCode: 60697,
          searchKey: {
            zhCN: '月亮, 夜晚, 黑夜, 夜间, 晚安',
            enUS: 'Moon, night, night, night, good night',
            zhSpell: 'yueliang, yewan, heiye, yejian, wanan',
          },
          group: 'app',
        },
        {
          iconCode: 60698,
          searchKey: {
            zhCN: '圆形, 选中, 单选按钮, 圆圈, 形状',
            enUS: 'Circle, checked, radio button, circle, shape',
            zhSpell: 'yuanxing, xuanzhong, danxuananniu, yuanquan, xingzhuang',
          },
          group: 'app',
        },
        {
          iconCode: 60699,
          searchKey: {
            zhCN: '圆形, 未选中, 圆圈, 形状, 实心, 填充',
            enUS: 'Round, unchecked, circle, shape, solid, fill',
            zhSpell: 'yuanxing, weixuanzhong, yuanquan, xingzhuang, shixin, tianchong',
          },
          group: 'app',
        },
        {
          iconCode: 60700,
          searchKey: {
            zhCN: '项目, 应用, 菜单, 列表, 形状',
            enUS: 'Project, application, menu, list, shape',
            zhSpell: 'xiangmu, yingyong, caidan, liebiao, xingzhuang',
          },
          group: 'app',
        },
        {
          iconCode: 60701,
          searchKey: {
            zhCN: '月亮, 夜晚, 黑夜, 夜间, 晚安',
            enUS: 'Moon, night, night, night, good night',
            zhSpell: 'yueliang, yewan, heiye, yejian, wanan',
          },
          group: 'app',
        },
        {
          iconCode: 60702,
          searchKey: {
            zhCN: '主题, 外观, 星星, 设置, 魔法, 魔棒',
            enUS: 'Theme, look, stars, settings, magic, magic wand',
            zhSpell: 'zhuti, waiguan, xingxing, shezhi, mofa, mobang',
          },
          group: 'app',
        },
        {
          iconCode: 60703,
          searchKey: {
            zhCN: '圆形, 未选中, 单选按钮, 圆圈, 形状',
            enUS: 'Round, unchecked, radio button, circle, shape',
            zhSpell: 'yuanxing, weixuanzhong, danxuananniu, yuanquan, xingzhuang',
          },
          group: 'app',
        },
        {
          iconCode: 60704,
          searchKey: {
            zhCN: '应用, 立方体, 结构, 方块, 正方体',
            enUS: 'Application, cube, structure, square, cube',
            zhSpell: 'yingyong, lifangti, jiegou, fangkuai, zhengfangti',
          },
          group: 'app',
        },
        {
          iconCode: 60705,
          searchKey: {
            zhCN: '组件, 拼图, 协作, 合作, 娱乐, 游戏',
            enUS: 'Components, puzzles, collaboration, collaboration, entertainment, games',
            zhSpell: 'zujian, pintu, xiezuo, hezuo, yule, youxi',
          },
          group: 'app',
        },
        {
          iconCode: 60706,
          searchKey: {
            zhCN: '钻石, 宝石, 珠宝, 尊贵, 荣誉, 菱形',
            enUS: 'Diamonds, gems, jewels, honor, honor, diamond',
            zhSpell: 'zuanshi, baoshi, zhubao, zungui, rongyu, lingxing',
          },
          group: 'app',
        },
        {
          iconCode: 60707,
          searchKey: {
            zhCN: '主题, 外观, 星星, 设置, 魔法, 魔棒',
            enUS: 'Theme, look, stars, settings, magic, magic wand',
            zhSpell: 'zhuti, waiguan, xingxing, shezhi, mofa, mobang',
          },
          group: 'app',
        },
        {
          iconCode: 60708,
          searchKey: {
            zhCN: '漏洞, 程序, 开发, 问题, 虫',
            enUS: 'Vulnerabilities, programs, development, problems, bugs',
            zhSpell: 'loudong, chengxu, kaifa, wenti, chong',
          },
          group: 'app',
        },
        {
          iconCode: 60709,
          searchKey: {
            zhCN: '复选框, 未选中, 方形, 形状, 正方形',
            enUS: 'Check box, unchecked, square, shape, square',
            zhSpell: 'fuxuankuang, weixuanzhong, fangxing, xingzhuang, zhengfangxing',
          },
          group: 'app',
        },
        {
          iconCode: 60710,
          searchKey: {
            zhCN: '修改, 设置, 调整, 形状, 六边形, 螺母',
            enUS: 'Modify, set, adjust, shape, hexagon, nut',
            zhSpell: 'xiugai, shezhi, tiaozheng, xingzhuang, liubianxing, luomu',
          },
          group: 'app',
        },
        {
          iconCode: 60711,
          searchKey: {
            zhCN: '形状, 三角形, 稳定, 稳固, 向上',
            enUS: 'Shape, triangle, stable, steady, up',
            zhSpell: 'xingzhuang, sanjiaoxing, wending, wengu, xiangshang',
          },
          group: 'app',
        },
        {
          iconCode: 60712,
          searchKey: {
            zhCN: '学士帽, 大学, 毕业, 帽子, 教育, 学校',
            enUS: 'Bachelor hat, university, graduation, hat, education, school',
            zhSpell: 'xueshimao, daxue, biye, maozi, jiaoyu, xuexiao',
          },
          group: 'app',
        },
        {
          iconCode: 60713,
          searchKey: {
            zhCN: '商店, 应用商城, 下载, 软件, 视频, 购物',
            enUS: 'Store, app store, download, software, video, shopping',
            zhSpell: 'shangdian, yingyongshangcheng, xiazai, ruanjian, shipin, gouwu',
          },
          group: 'app',
        },
        {
          iconCode: 60714,
          searchKey: {
            zhCN: 'iOS, 设置, 偏好, 配置, 齿轮, 工具',
            enUS: 'iOS, settings, preferences, configuration, gears, tools',
            zhSpell: 'iOS, shezhi, pianhao, peizhi, chilun, gongju',
          },
          group: 'app',
        },
        {
          iconCode: 60715,
          searchKey: {
            zhCN: 'iOS, 指纹, 认证, 解锁, 身份认证, 识别',
            enUS: 'iOS, fingerprint, authentication, unlock, identity authentication, recognition',
            zhSpell: 'iOS, zhiwen, renzheng, jiesuo, shenfenrenzheng, shibie',
          },
          group: 'app',
        },
        {
          iconCode: 60716,
          searchKey: {
            zhCN: '组件, 拼图, 协作, 合作, 娱乐, 游戏',
            enUS: 'Components, puzzles, collaboration, collaboration, entertainment, games',
            zhSpell: 'zujian, pintu, xiezuo, hezuo, yule, youxi',
          },
          group: 'app',
        },
        {
          iconCode: 60717,
          searchKey: {
            zhCN: '应用, 立方体, 结构, 方块, 正方体',
            enUS: 'Application, cube, structure, square, cube',
            zhSpell: 'yingyong, lifangti, jiegou, fangkuai, zhengfangti',
          },
          group: 'app',
        },
        {
          iconCode: 60718,
          searchKey: {
            zhCN: '天气, 雪, 寒冷, 下雪, 冬天',
            enUS: 'Weather, snow, cold, snow, winter',
            zhSpell: 'tianqi, xue, hanling, xiaxue, dongtian',
          },
          group: 'app',
        },
        {
          iconCode: 60719,
          searchKey: {
            zhCN: '圆形, 虚线, 形状, 圆圈, 空心',
            enUS: 'Round, dotted, shape, circle, hollow',
            zhSpell: 'yuanxing, xuxian, xingzhuang, yuanquan, kongxin',
          },
          group: 'app',
        },
        {
          iconCode: 60720,
          searchKey: {
            zhCN: '天气, 雷阵雨, 云, 下雨, 闪电',
            enUS: 'Weather, thunderstorm, cloud, rain, lightning',
            zhSpell: 'tianqi, leizhenyu, yun, xiayu, shandian',
          },
          group: 'app',
        },
        {
          iconCode: 60721,
          searchKey: {
            zhCN: '复选框, 选中, 方形, 形状, 正方形, 完成, 核对',
            enUS: 'Check box, checked, square, shape, square, complete, check',
            zhSpell: 'fuxuankuang, xuanzhong, fangxing, xingzhuang, zhengfangxing, wancheng, hedui',
          },
          group: 'app',
        },
        {
          iconCode: 60722,
          searchKey: {
            zhCN: 'iOS, 指纹, 认证, 解锁, 身份认证, 识别',
            enUS: 'iOS, fingerprint, authentication, unlock, identity authentication, recognition',
            zhSpell: 'iOS, zhiwen, renzheng, jiesuo, shenfenrenzheng, shibie',
          },
          group: 'app',
        },
        {
          iconCode: 60723,
          searchKey: {
            zhCN: '项目, 应用, 菜单, 列表, 方块, 形状',
            enUS: 'Project, application, menu, list, square, shape',
            zhSpell: 'xiangmu, yingyong, caidan, liebiao, fangkuai, xingzhuang',
          },
          group: 'app',
        },
        {
          iconCode: 60724,
          searchKey: {
            zhCN: '应用, 立方体, 结构, 方块, 正方体',
            enUS: 'Application, cube, structure, square, cube',
            zhSpell: 'yingyong, lifangti, jiegou, fangkuai, zhengfangti',
          },
          group: 'app',
        },
        {
          iconCode: 60725,
          searchKey: {
            zhCN: '网络, 地球, 星球, 卫星, 环绕, 宇宙',
            enUS: 'Network, earth, planet, satellite, surround, universe',
            zhSpell: 'wangluo, diqiu, xingqiu, weixing, huanrao, yuzhou',
          },
          group: 'app',
        },
      ],
    },
    {
      name: i18n('resource.icons.letter'),
      id: 'letter',
      items: [
        {
          iconCode: 59280,
          searchKey: {
            zhCN: '0, 零, 数字, 编号, 计算, 圆圈, 数字 0',
            enUS: '0, zero, number, calculation, circle, number0',
            zhSpell: '0, ling, shuzi, bianhao, jisuan, yuanquan, shuzi0',
          },
          group: 'letter',
        },
        {
          iconCode: 59243,
          searchKey: {
            zhCN: '1, 一, 数字, 编号, 计算, 圆圈, 数字 1',
            enUS: '1, one, number, calculation, circle, number1',
            zhSpell: '1, yi, shuzi, bianhao, jisuan, yuanquan, shuzi1',
          },
          group: 'letter',
        },
        {
          iconCode: 59244,
          searchKey: {
            zhCN: '2, 二, 数字, 编号, 计算, 圆圈, 数字 2',
            enUS: '2, two, numbers, calculations, circles, number2',
            zhSpell: '2, er, shuzi, bianhao, jisuan, yuanquan, shuzi2',
          },
          group: 'letter',
        },
        {
          iconCode: 59290,
          searchKey: {
            zhCN: '3, 三, 数字, 编号, 计算, 圆圈, 数字 3',
            enUS: '3, three, numbers, calculations, circles, number3',
            zhSpell: '3, san, shuzi, bianhao, jisuan, yuanquan, shuzi3',
          },
          group: 'letter',
        },
        {
          iconCode: 59246,
          searchKey: {
            zhCN: '4, 四, 数字, 编号, 计算, 圆圈, 数字 4',
            enUS: '4, four, numbers, calculations, circles, number4',
            zhSpell: '4, si, shuzi, bianhao, jisuan, yuanquan, shuzi4',
          },
          group: 'letter',
        },
        {
          iconCode: 59245,
          searchKey: {
            zhCN: '5, 五, 数字, 编号, 计算, 圆圈, 数字 5',
            enUS: '5, five, numbers, calculations, circles, number5',
            zhSpell: '5, wu, shuzi, bianhao, jisuan, yuanquan, shuzi5',
          },
          group: 'letter',
        },
        {
          iconCode: 59250,
          searchKey: {
            zhCN: '6, 六, 数字, 编号, 计算, 圆圈, 数字 6',
            enUS: '6, six, numbers, calculations, circles, number6',
            zhSpell: '6, liu, shuzi, bianhao, jisuan, yuanquan, shuzi6',
          },
          group: 'letter',
        },
        {
          iconCode: 59247,
          searchKey: {
            zhCN: '7, 七, 数字, 编号, 计算, 圆圈, 数字 7',
            enUS: '7, seven, numbers, calculations, circles, number7',
            zhSpell: '7, qi, shuzi, bianhao, jisuan, yuanquan, shuzi7',
          },
          group: 'letter',
        },
        {
          iconCode: 59248,
          searchKey: {
            zhCN: '8, 八, 数字, 编号, 计算, 圆圈, 数字 8',
            enUS: '8, eight, numbers, calculations, circles, number8',
            zhSpell: '8, ba, shuzi, bianhao, jisuan, yuanquan, shuzi8',
          },
          group: 'letter',
        },
        {
          iconCode: 59251,
          searchKey: {
            zhCN: '9, 九, 数字, 编号, 计算, 圆圈, 数字 9',
            enUS: '9, nine, number, calculation, circle, number9',
            zhSpell: '9, jiu, shuzi, bianhao, jisuan, yuanquan, shuzi9',
          },
          group: 'letter',
        },
        {
          iconCode: 59310,
          searchKey: {
            zhCN: '0,零,数字0,数字 0,圆形,实心',
            enUS: '0,zero,number 0,number +0,round,solid',
            zhSpell: '0,ling,shuzi0,shuzi 0,yuanxing,shixin',
          },
          group: 'letter',
        },
        {
          iconCode: 59305,
          searchKey: {
            zhCN: '1,一,数字1,数字 1,圆形,实心',
            enUS: '1,one,number 1,number +1,round,solid',
            zhSpell: '1,yi,shuzi1,shuzi 1,yuanxing,shixin',
          },
          group: 'letter',
        },
        {
          iconCode: 59323,
          searchKey: {
            zhCN: '2,二一,数字2,数字 2,圆形,实心',
            enUS: '2,two one,number 2,number +2,round,solid',
            zhSpell: '2,eryi,shuzi2,shuzi 2,yuanxing,shixin',
          },
          group: 'letter',
        },
        {
          iconCode: 59316,
          searchKey: {
            zhCN: '3,三,数字3,数字 3,圆形,实心',
            enUS: '3,three,number 3,number +3,round,solid',
            zhSpell: '3,san,shuzi3,shuzi 3,yuanxing,shixin',
          },
          group: 'letter',
        },
        {
          iconCode: 59315,
          searchKey: {
            zhCN: '4,四,数字4,数字 4,圆形,实心',
            enUS: '4,four,number 4,number +4,round,solid',
            zhSpell: '4,si,shuzi4,shuzi 4,yuanxing,shixin',
          },
          group: 'letter',
        },
        {
          iconCode: 59348,
          searchKey: {
            zhCN: '5,五,数字5,数字 5,圆形,实心',
            enUS: '5,five,number 5,number +5,round,solid',
            zhSpell: '5,wu,shuzi5,shuzi 5,yuanxing,shixin',
          },
          group: 'letter',
        },
        {
          iconCode: 59320,
          searchKey: {
            zhCN: '6,六一,数字6,数字 6,圆形,实心',
            enUS: '6,six one,number 6,number +6,round,solid',
            zhSpell: '6,liuyi,shuzi6,shuzi 6,yuanxing,shixin',
          },
          group: 'letter',
        },
        {
          iconCode: 59307,
          searchKey: {
            zhCN: '7,七,数字7,数字 7,圆形,实心',
            enUS: '7,seven,number 7,number +7,round,solid',
            zhSpell: '7,qi,shuzi7,shuzi 7,yuanxing,shixin',
          },
          group: 'letter',
        },
        {
          iconCode: 59313,
          searchKey: {
            zhCN: '8,八,数字8,数字 8,圆形,实心',
            enUS: '8,eight,number 8,number +8,round,solid',
            zhSpell: '8,ba,shuzi8,shuzi 8,yuanxing,shixin',
          },
          group: 'letter',
        },
        {
          iconCode: 59319,
          searchKey: {
            zhCN: '9,九,数字9,数字 9,圆形,实心',
            enUS: '9,nine,number 9,number +9,round,solid',
            zhSpell: '9,jiu,shuzi9,shuzi 9,yuanxing,shixin',
          },
          group: 'letter',
        },
        {
          iconCode: 59277,
          searchKey: {
            zhCN: 'a, A, 字母a, 符号, 空心, 圆形, 字母 a',
            enUS: 'A, letter a, symbol, hollow, round, lettera',
            zhSpell: 'a, A, zimu a, fuhao, kongxin, yuanxing, zimua',
          },
          group: 'letter',
        },
        {
          iconCode: 59278,
          searchKey: {
            zhCN: 'b, B, 字母b, 符号, 空心, 圆形, 字母 b',
            enUS: 'B, letter b, symbol, hollow, round, letterb',
            zhSpell: 'b, B, zimu b, fuhao, kongxin, yuanxing, zimub',
          },
          group: 'letter',
        },
        {
          iconCode: 59281,
          searchKey: {
            zhCN: 'c, C, 字母c, 符号, 空心, 圆形, 字母 c',
            enUS: 'C, letter c, symbol, hollow, round, letterc',
            zhSpell: 'c, C, zimu c, fuhao, kongxin, yuanxing, zimuc',
          },
          group: 'letter',
        },
        {
          iconCode: 59304,
          searchKey: {
            zhCN: 'd, D, 字母d, 符号, 空心, 圆形, 字母 d',
            enUS: 'D, letter d, symbol, hollow, round, letterd',
            zhSpell: 'd, D, zimu d, fuhao, kongxin, yuanxing, zimud',
          },
          group: 'letter',
        },
        {
          iconCode: 59283,
          searchKey: {
            zhCN: 'e, E, 字母e, 符号, 空心, 圆形, 字母 e',
            enUS: 'E, letter e, symbol, hollow, round, lettere',
            zhSpell: 'e, E, zimu e, fuhao, kongxin, yuanxing, zimue',
          },
          group: 'letter',
        },
        {
          iconCode: 59279,
          searchKey: {
            zhCN: 'f, F, 字母f, 符号, 空心, 圆形, 字母 f',
            enUS: 'F, letter f, symbol, hollow, round, letterf',
            zhSpell: 'f, F, zimu, fuhao, kongxin, yuanxing, zimuf',
          },
          group: 'letter',
        },
        {
          iconCode: 59285,
          searchKey: {
            zhCN: 'g, G, 字母g, 符号, 空心, 圆形, 字母 g',
            enUS: 'G, letter g, symbol, hollow, round, letterg',
            zhSpell: 'g, G, zimu g, fuhao, kongxin, yuanxing, zimug',
          },
          group: 'letter',
        },
        {
          iconCode: 59302,
          searchKey: {
            zhCN: 'h, H, 字母h, 符号, 空心, 圆形, 字母 h',
            enUS: 'H, letter h, symbol, hollow, round, letterh',
            zhSpell: 'h, H, zimu h, fuhao, kongxin, yuanxing, zimuh',
          },
          group: 'letter',
        },
        {
          iconCode: 59282,
          searchKey: {
            zhCN: 'i, I, 字母i, 符号, 空心, 圆形, 字母 i',
            enUS: 'I, letter i, symbol, hollow, round, letteri',
            zhSpell: 'i, I, zimu i, fuhao, kongxin, yuanxing, zimui',
          },
          group: 'letter',
        },
        {
          iconCode: 59284,
          searchKey: {
            zhCN: 'j, J, 字母j, 符号, 空心, 圆形, 字母 j',
            enUS: 'J, letter j, symbol, hollow, round, letterj',
            zhSpell: 'j, J, zimu j, fuhao, kongxin, yuanxing, zimuj',
          },
          group: 'letter',
        },
        {
          iconCode: 59286,
          searchKey: {
            zhCN: 'k, K, 字母k, 符号, 空心, 圆形, 字母 k',
            enUS: 'K, letter , symbol, hollow, round, letterk',
            zhSpell: 'k, K, zimu k, fuhao, kongxin, yuanxing, zimuk',
          },
          group: 'letter',
        },
        {
          iconCode: 59287,
          searchKey: {
            zhCN: 'l, L, 字母l, 符号, 空心, 圆形, 字母 l',
            enUS: 'L, letter l, symbol, hollow, round, letterl',
            zhSpell: 'l, L, zimu l, fuhao, kongxin, yuanxing, zimul',
          },
          group: 'letter',
        },
        {
          iconCode: 59289,
          searchKey: {
            zhCN: 'm, M, 字母m, 符号, 空心, 圆形, 字母 m',
            enUS: 'M, letter m, symbol, hollow, round, letterm',
            zhSpell: 'm, M, zimu m, fuhao, kongxin, yuanxing, zimum',
          },
          group: 'letter',
        },
        {
          iconCode: 59288,
          searchKey: {
            zhCN: 'n, N, 字母n, 符号, 空心, 圆形, 字母 n',
            enUS: 'N, letter n, symbol, hollow, round, lettern',
            zhSpell: 'n, N, zimu n, fuhao, kongxin, yuanxing, zimun',
          },
          group: 'letter',
        },
        {
          iconCode: 59291,
          searchKey: {
            zhCN: 'o, O, 字母o, 符号, 空心, 圆形, 字母 o',
            enUS: 'O, letter o, symbol, hollow, round, lettero',
            zhSpell: 'o, O, zimu o, fuhao, kongxin, yuanxing, zimuo',
          },
          group: 'letter',
        },
        {
          iconCode: 59293,
          searchKey: {
            zhCN: 'p, P, 字母p, 符号, 空心, 圆形, 字母 p',
            enUS: 'P, letter p, symbol, hollow, round, letterp',
            zhSpell: 'p, P, zimu p, fuhao, kongxin, yuanxing, zimup',
          },
          group: 'letter',
        },
        {
          iconCode: 59295,
          searchKey: {
            zhCN: 'q, Q, 字母q, 符号, 空心, 圆形, 字母 q',
            enUS: 'Q, letter q, symbols, hollow, round, letterq',
            zhSpell: 'q, Q, zimu q, fuhao, kongxin, yuanxing, zimuq',
          },
          group: 'letter',
        },
        {
          iconCode: 59292,
          searchKey: {
            zhCN: 'r, R, 字母r, 符号, 空心, 圆形, 字母 r',
            enUS: 'R, letter r, symbol, hollow, round, letterr',
            zhSpell: 'r, R, zimu r, fuhao, kongxin, yuanxing, zimur',
          },
          group: 'letter',
        },
        {
          iconCode: 59296,
          searchKey: {
            zhCN: 's, S, 字母s, 符号, 空心, 圆形, 字母 s',
            enUS: 'S, letter s, symbol, hollow, round, letters',
            zhSpell: 's, S, zimu s, fuhao, kongxin, yuanxing, zimus',
          },
          group: 'letter',
        },
        {
          iconCode: 59299,
          searchKey: {
            zhCN: 't, T, 字母t, 符号, 空心, 圆形, 字母 t',
            enUS: 'T, letter t, symbol, hollow, round, lettert',
            zhSpell: 't, T, zimu t, fuhao, kongxin, yuanxing, zimut',
          },
          group: 'letter',
        },
        {
          iconCode: 59303,
          searchKey: {
            zhCN: 'u, U, 字母u, 符号, 空心, 圆形, 字母 u',
            enUS: 'U, letter u, symbols, hollow, round, letteru',
            zhSpell: 'u, U, zimu u, fuhao, kongxin, yuanxing, zimuu',
          },
          group: 'letter',
        },
        {
          iconCode: 59294,
          searchKey: {
            zhCN: 'v, V, 字母v, 符号, 空心, 圆形, 字母 v',
            enUS: 'V, letter v, symbol, hollow, round, letterv',
            zhSpell: 'v, V, zimu v, fuhao, kongxin, yuanxing, zimuv',
          },
          group: 'letter',
        },
        {
          iconCode: 59297,
          searchKey: {
            zhCN: 'w, W, 字母w, 符号, 空心, 圆形, 字母 w',
            enUS: 'W, letter w, symbol, hollow, round, letterw',
            zhSpell: 'w, W, zimu w, fuhao, kongxin, yuanxing, zimuw',
          },
          group: 'letter',
        },
        {
          iconCode: 59300,
          searchKey: {
            zhCN: 'x, X, 字母x, 符号, 空心, 圆形, 字母 x',
            enUS: 'X, letter x, symbol, hollow, round, letterx',
            zhSpell: 'x, X, zimu x, fuhao, kongxin, yuanxing, zimux',
          },
          group: 'letter',
        },
        {
          iconCode: 59301,
          searchKey: {
            zhCN: 'y, Y, 字母y, 符号, 空心, 圆形, 字母 y',
            enUS: 'Y, letter y, symbol, hollow, round, lettery',
            zhSpell: 'y, Y, zimu y, fuhao, kongxin, yuanxing, zimuy',
          },
          group: 'letter',
        },
        {
          iconCode: 59298,
          searchKey: {
            zhCN: 'z, Z, 字母z, 符号, 空心, 圆形, 字母 z',
            enUS: 'Z, letter z, symbol, hollow, round, letterz',
            zhSpell: 'z, Z, zimu z, fuhao, kongxin, yuanxing, zimuz',
          },
          group: 'letter',
        },
        {
          iconCode: 59249,
          searchKey: {
            zhCN: 'A, a, 字母A, 符号, 空心, 圆形, 字母 A',
            enUS: 'A, letter A, symbol, hollow, round, letterA',
            zhSpell: 'A, a, zimu A, fuhao, kongxin, yuanxing, zimuA',
          },
          group: 'letter',
        },
        {
          iconCode: 59253,
          searchKey: {
            zhCN: 'B, b, 字母B, 符号, 空心, 圆形, 字母 B',
            enUS: 'B, letter B, symbol, hollow, round, letterB',
            zhSpell: 'B, b, zimu B, fuhao, kongxin, yuanxing, zimuB',
          },
          group: 'letter',
        },
        {
          iconCode: 59254,
          searchKey: {
            zhCN: 'C, c, 字母C, 符号, 空心, 圆形, 字母 C',
            enUS: 'C, letter C, symbol, hollow, round, letterC',
            zhSpell: 'C, c, zimu C, fuhao, kongxin, yuanxing, zimuC',
          },
          group: 'letter',
        },
        {
          iconCode: 59252,
          searchKey: {
            zhCN: 'D, d, 字母 D, 符号, 空心, 圆形, 字母 D',
            enUS: 'D, letter, symbol, hollow, round, letterD',
            zhSpell: 'D, d, zimu D, fuhao, kongxin, yuanxing, zimuD',
          },
          group: 'letter',
        },
        {
          iconCode: 59258,
          searchKey: {
            zhCN: 'E, e, 字母E, 符号, 空心, 圆形, 字母 E',
            enUS: 'E, letter E, symbol, hollow, round, letterE',
            zhSpell: 'E, e, zimu E, fuhao, kongxin, yuanxing, zimuE',
          },
          group: 'letter',
        },
        {
          iconCode: 59257,
          searchKey: {
            zhCN: 'F, f, 字母F, 符号, 空心, 圆形, 字母 F',
            enUS: 'F, letter F, symbol, hollow, round, letterF',
            zhSpell: 'F, f, zimu F, fuhao, kongxin, yuanxing, zimuF',
          },
          group: 'letter',
        },
        {
          iconCode: 59260,
          searchKey: {
            zhCN: 'G, g, 字母G, 符号, 空心, 圆形, 字母 G',
            enUS: 'G, letter G, symbol, hollow, round, letterG',
            zhSpell: 'G, g, zimu G, fuhao, kongxin, yuanxing, zimuG',
          },
          group: 'letter',
        },
        {
          iconCode: 59255,
          searchKey: {
            zhCN: 'H, h, 字母H, 符号, 空心, 圆形, 字母 H',
            enUS: 'H, letter H, symbol, hollow, round, letterH',
            zhSpell: 'H, h, zimu H, fuhao, kongxin, yuanxing, zimuH',
          },
          group: 'letter',
        },
        {
          iconCode: 59256,
          searchKey: {
            zhCN: 'I, i, 字母I, 符号, 空心, 圆形, 字母 I',
            enUS: 'I, letter I, symbol, hollow, round, letterI',
            zhSpell: 'I, i, zimu I, fuhao, kongxin, yuanxing, zimuI',
          },
          group: 'letter',
        },
        {
          iconCode: 59264,
          searchKey: {
            zhCN: 'J, j, 字母J, 符号, 空心, 圆形, 字母 J',
            enUS: 'J, letter J, symbol, hollow, round, letterJ',
            zhSpell: 'J, j, zimu J, fuhao, kongxin, yuanxing, zimuJ',
          },
          group: 'letter',
        },
        {
          iconCode: 59263,
          searchKey: {
            zhCN: 'K, k, 字母K, 符号, 空心, 圆形, 字母 K',
            enUS: 'K, letter K, symbols, hollow, round, letterK',
            zhSpell: 'K, k, zimu K, fuhao, kongxin, yuanxing, zimuK',
          },
          group: 'letter',
        },
        {
          iconCode: 59259,
          searchKey: {
            zhCN: 'L, l, 字母L, 符号, 空心, 圆形, 字母 L',
            enUS: 'L, letter L, symbol, hollow, round, letterL',
            zhSpell: 'L, l, zimu L, fuhao, kongxin, yuanxing, zimuL',
          },
          group: 'letter',
        },
        {
          iconCode: 59262,
          searchKey: {
            zhCN: 'M, m, 字母M, 符号, 空心, 圆形, 字母 M',
            enUS: 'M, letter M, symbol, hollow, round, letterM',
            zhSpell: 'M, m, zimu M, fuhao, kongxin, yuanxing, zimuM',
          },
          group: 'letter',
        },
        {
          iconCode: 59261,
          searchKey: {
            zhCN: 'N, n, 字母N, 符号, 空心, 圆形, 字母 N',
            enUS: 'N, letter N, symbol, hollow, round, letterN',
            zhSpell: 'N, n, zimu N, fuhao, kongxin, yuanxing, zimuN',
          },
          group: 'letter',
        },
        {
          iconCode: 59276,
          searchKey: {
            zhCN: 'O, o, 字母O, 符号, 空心, 圆形, 字母 O',
            enUS: 'O, letter O, symbol, hollow, round, letterO',
            zhSpell: 'O, o, zimu O, fuhao, kongxin, yuanxing, zimuO',
          },
          group: 'letter',
        },
        {
          iconCode: 59266,
          searchKey: {
            zhCN: 'P, p, 字母P, 符号, 空心, 圆形, 字母 P',
            enUS: 'P, letter P, symbol, hollow, round, letterP',
            zhSpell: 'P, p, zimu P, fuhao, kongxin, yuanxing, zimuP',
          },
          group: 'letter',
        },
        {
          iconCode: 59265,
          searchKey: {
            zhCN: 'Q, q, 字母Q, 符号, 空心, 圆形, 字母 Q',
            enUS: 'Q, letter Q, symbols, hollow, round, letterQ',
            zhSpell: 'Q, q, zimu Q, fuhao, kongxin, yuanxing, zimuQ',
          },
          group: 'letter',
        },
        {
          iconCode: 59267,
          searchKey: {
            zhCN: 'R, r, 字母R, 符号, 空心, 圆形, 字母 R',
            enUS: 'R, letter R, symbol, hollow, round, letterR',
            zhSpell: 'R, r, zimu R, fuhao, kongxin, yuanxing, zimuR',
          },
          group: 'letter',
        },
        {
          iconCode: 59270,
          searchKey: {
            zhCN: 'S, s, 字母S, 符号, 空心, 圆形, 字母 S',
            enUS: 'S, letter S, symbol, hollow, round, letterS',
            zhSpell: 'S, s, zimu S, fuhao, kongxin, yuanxing, zimuS',
          },
          group: 'letter',
        },
        {
          iconCode: 59268,
          searchKey: {
            zhCN: 'T, t, 字母T, 符号, 空心, 圆形, 字母 T',
            enUS: 'T, letter T, symbol, hollow, round, letterT',
            zhSpell: 'T, t, zimu T, fuhao, kongxin, yuanxing, zimuT',
          },
          group: 'letter',
        },
        {
          iconCode: 59269,
          searchKey: {
            zhCN: 'U, u, 字母U, 符号, 空心, 圆形, 字母 U',
            enUS: 'U, letter U, symbols, hollow, round, letterU',
            zhSpell: 'U, u, zimu U, fuhao, kongxin, yuanxing, zimuU',
          },
          group: 'letter',
        },
        {
          iconCode: 59272,
          searchKey: {
            zhCN: 'V, v, 字母V, 符号, 空心, 圆形, 字母 V',
            enUS: 'V, letter V, symbol, hollow, round, letterV',
            zhSpell: 'V, v, zimu V, fuhao, kongxin, yuanxing, zimuV',
          },
          group: 'letter',
        },
        {
          iconCode: 59275,
          searchKey: {
            zhCN: 'W, w, 字母W, 符号, 空心, 圆形, 字母 W',
            enUS: 'W, letter W, symbol, hollow, round, letterW',
            zhSpell: 'W, w, zimu W, fuhao, kongxin, yuanxing, zimuW',
          },
          group: 'letter',
        },
        {
          iconCode: 59271,
          searchKey: {
            zhCN: 'X, x, 字母X, 符号, 空心, 圆形, 字母 X',
            enUS: 'X, letter X, symbol, hollow, round, letterX',
            zhSpell: 'X, x, zimu X, fuhao, kongxin, yuanxing, zimuX',
          },
          group: 'letter',
        },
        {
          iconCode: 59274,
          searchKey: {
            zhCN: 'Y, y, 字母Y, 符号, 空心, 圆形, 字母 Y',
            enUS: 'Y, letter Y, symbol, hollow, round, letterY',
            zhSpell: 'Y, y, zimu Y, fuhao, kongxin, yuanxing, zimuY',
          },
          group: 'letter',
        },
        {
          iconCode: 59273,
          searchKey: {
            zhCN: 'Z, z, 字母Z, 符号, 空心, 圆形, 字母 Z',
            enUS: 'Z, letter Z, symbols, hollow, round, letterZ',
            zhSpell: 'Z, z, zimu Z, fuhao, kongxin, yuanxing, zimuZ',
          },
          group: 'letter',
        },
        {
          iconCode: 59351,
          searchKey: {
            zhCN: 'a,字母a,字母 a,圆形,实心,小写',
            enUS: 'A,letter a,letter + a,round,solid,lower case',
            zhSpell: 'a,zimua,zimu a,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59360,
          searchKey: {
            zhCN: 'b,字母b,字母 b,圆形,实心,小写',
            enUS: 'B,letter b,letter + b,round,solid,lower case',
            zhSpell: 'b,zimub,zimu b,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59340,
          searchKey: {
            zhCN: 'c,字母c,字母 c,圆形,实心,小写',
            enUS: 'C,letter c,letter + c,round,solid,lower case',
            zhSpell: 'c,zimuc,zimu c,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59361,
          searchKey: {
            zhCN: 'd,字母d,字母 d,圆形,实心,小写',
            enUS: 'D,letter d,letter + d,round,solid,lower case',
            zhSpell: 'd,zimud,zimu d,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59363,
          searchKey: {
            zhCN: 'e,字母e,字母 e,圆形,实心,小写',
            enUS: 'E,letter e,letter + e,round,solid,lower case',
            zhSpell: 'e,zimue,zimu e,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59352,
          searchKey: {
            zhCN: 'f,字母f,字母 f,圆形,实心,小写',
            enUS: 'F,letter f,letter + f,round,solid,lower case',
            zhSpell: 'f,zimuf,zimu f,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59342,
          searchKey: {
            zhCN: 'g,字母g,字母 g,圆形,实心,小写',
            enUS: 'G,letter g,letter + g,round,solid,lower case',
            zhSpell: 'g,zimug,zimu g,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59359,
          searchKey: {
            zhCN: 'h,字母h,字母 h,圆形,实心,小写',
            enUS: 'H,letter h,letter + h,round,solid,lower case',
            zhSpell: 'h,zimuh,zimu h,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59317,
          searchKey: {
            zhCN: 'i,字母i,字母 i,圆形,实心,小写',
            enUS: 'I,letter i,letter + i,round,solid,lower case',
            zhSpell: 'i,zimui,zimu i,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59338,
          searchKey: {
            zhCN: 'j,字母j,字母 j,圆形,实心,小写',
            enUS: 'J,letter j,letter + j,round,solid,lower case',
            zhSpell: 'j,zimuj,zimu j,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59346,
          searchKey: {
            zhCN: 'k,字母k,字母 k,圆形,实心,小写',
            enUS: 'K,letter k,letter + k,round,solid,lower case',
            zhSpell: 'k,zimuk,zimu k,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59339,
          searchKey: {
            zhCN: 'l,字母l,字母 l,圆形,实心,小写',
            enUS: 'L,letter l,letter + l,round,solid,lower case',
            zhSpell: 'l,zimul,zimu l,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59362,
          searchKey: {
            zhCN: 'm,字母m,字母 m,圆形,实心,小写',
            enUS: 'M,letter m,letter + m,round,solid,lower case',
            zhSpell: 'm,zimum,zimu m,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59364,
          searchKey: {
            zhCN: 'n,字母n,字母 n,圆形,实心,小写',
            enUS: 'N,letter n,letter + n,round,solid,lower case',
            zhSpell: 'n,zimun,zimu n,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59355,
          searchKey: {
            zhCN: 'o,字母o,字母 o,圆形,实心,小写',
            enUS: 'O,letter o,letter + o,round,solid,lower case',
            zhSpell: 'o,zimuo,zimu o,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59356,
          searchKey: {
            zhCN: 'p,字母p,字母 p,圆形,实心,小写',
            enUS: 'P,letter p,letter + p,round,solid,lower case',
            zhSpell: 'p,zimup,zimu p,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59350,
          searchKey: {
            zhCN: 'q,字母q,字母 q,圆形,实心,小写',
            enUS: 'Q,letter q,letter + q,round,solid,lower case',
            zhSpell: 'q,zimuq,zimu q,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59347,
          searchKey: {
            zhCN: 'r,字母r,字母 r,圆形,实心,小写',
            enUS: 'R,letter r,letter + r,round,solid,lower case',
            zhSpell: 'r,zimur,zimu r,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59357,
          searchKey: {
            zhCN: 's,字母s,字母 s,圆形,实心,小写',
            enUS: 'S,letter s,letter + s,round,solid,lower case',
            zhSpell: 's,zimus,zimu s,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59366,
          searchKey: {
            zhCN: 't,字母t,字母 t,圆形,实心,小写',
            enUS: 'T,letter t,letter + t,round,solid,lower case',
            zhSpell: 't,zimut,zimu t,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59343,
          searchKey: {
            zhCN: 'u,字母u,字母 u,圆形,实心,小写',
            enUS: 'U,letter u,letter + u,round,solid,lower case',
            zhSpell: 'u,zimuu,zimu u,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59337,
          searchKey: {
            zhCN: 'v,字母v,字母 v,圆形,实心,小写',
            enUS: 'V,letter v,letter + v,round,solid,lower case',
            zhSpell: 'v,zimuv,zimu v,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59341,
          searchKey: {
            zhCN: 'w,字母w,字母 w,圆形,实心,小写',
            enUS: 'W,letter w,letter + w,round,solid,lower case',
            zhSpell: 'w,zimuw,zimu w,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59349,
          searchKey: {
            zhCN: 'x,字母x,字母 x,圆形,实心,小写',
            enUS: 'X,letter x,letter + x,round,solid,lower case',
            zhSpell: 'x,zimux,zimu x,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59365,
          searchKey: {
            zhCN: 'y,字母y,字母 y,圆形,实心,小写',
            enUS: 'Y,letter y,letter + y,round,solid,lower case',
            zhSpell: 'y,zimuy,zimu y,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59353,
          searchKey: {
            zhCN: 'z,字母z,字母 z,圆形,实心,小写',
            enUS: 'Z,letter z,letter + z,round,solid,lower case',
            zhSpell: 'z,zimuz,zimu z,yuanxing,shixin,xiaoxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59306,
          searchKey: {
            zhCN: 'A,字母A,字母 A,圆形,实心,大写',
            enUS: 'A,letter a,letter + a,round,solid,uppercase',
            zhSpell: 'A,zimuA,zimu A,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59314,
          searchKey: {
            zhCN: 'B,字母B,字母 B,圆形,实心,大写',
            enUS: 'B,letter b,letter + b,round,solid,uppercase',
            zhSpell: 'B,zimuB,zimu B,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59321,
          searchKey: {
            zhCN: 'C,字母C,字母 C,圆形,实心,大写',
            enUS: 'C,letter c,letter + c,round,solid,uppercase',
            zhSpell: 'C,zimuC,zimu C,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59333,
          searchKey: {
            zhCN: 'D,字母D,字母 D,圆形,实心,大写',
            enUS: 'D,letter d,letter + d,round,solid,uppercase',
            zhSpell: 'D,zimuD,zimu D,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59318,
          searchKey: {
            zhCN: 'E,字母E,字母 E,圆形,实心,大写',
            enUS: 'E,letter e,letter + e,round,solid,uppercase',
            zhSpell: 'E,zimuE,zimu E,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59330,
          searchKey: {
            zhCN: 'F,字母F,字母 F,圆形,实心,大写',
            enUS: 'F,letter f,letter + f,round,solid,uppercase',
            zhSpell: 'F,zimuF,zimu F,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59312,
          searchKey: {
            zhCN: 'G,字母G,字母 G,圆形,实心,大写',
            enUS: 'G,letter g,letter + g,round,solid,uppercase',
            zhSpell: 'G,zimuG,zimu G,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59311,
          searchKey: {
            zhCN: 'H,字母H,字母 H,圆形,实心,大写',
            enUS: 'H,letter h,letter + h,round,solid,uppercase',
            zhSpell: 'H,zimuH,zimu H,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59332,
          searchKey: {
            zhCN: 'I,字母I,字母 I,圆形,实心,大写',
            enUS: 'I,letter i,letter + i,round,solid,uppercase',
            zhSpell: 'I,zimuI,zimu I,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59322,
          searchKey: {
            zhCN: 'J,字母J,字母 J,圆形,实心,大写',
            enUS: 'J,letter j,letter + j,round,solid,uppercase',
            zhSpell: 'J,zimuJ,zimu J,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59329,
          searchKey: {
            zhCN: 'K,字母K,字母 K,圆形,实心,大写',
            enUS: 'K,letter k,letter + k,round,solid,uppercase',
            zhSpell: 'K,zimuK,zimu K,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59308,
          searchKey: {
            zhCN: 'L,字母L,字母 L,圆形,实心,大写',
            enUS: 'L,letter l,letter + l,round,solid,uppercase',
            zhSpell: 'L,zimuL,zimu L,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59326,
          searchKey: {
            zhCN: 'M,字母M,字母 M,圆形,实心,大写',
            enUS: 'M,letter m,letter + m,round,solid,uppercase',
            zhSpell: 'M,zimuM,zimu M,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59327,
          searchKey: {
            zhCN: 'N,字母N,字母 N,圆形,实心,大写',
            enUS: 'N,letter n,letter + n,round,solid,uppercase',
            zhSpell: 'N,zimuN,zimu N,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59344,
          searchKey: {
            zhCN: 'O,字母O,字母 O,圆形,实心,大写',
            enUS: 'O,letter o,letter + o,round,solid,uppercase',
            zhSpell: 'O,zimuO,zimu O,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59335,
          searchKey: {
            zhCN: 'P,字母P,字母 P,圆形,实心,大写',
            enUS: 'P,letter p,letter + p,round,solid,uppercase',
            zhSpell: 'P,zimuP,zimu P,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59358,
          searchKey: {
            zhCN: 'Q,字母Q,字母 Q,圆形,实心,大写',
            enUS: 'Q,letter q,letter + q,round,solid,uppercase',
            zhSpell: 'Q,zimuQ,zimu Q,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59354,
          searchKey: {
            zhCN: 'R,字母R,字母 R,圆形,实心,大写',
            enUS: 'R,letter r,letter + r,round,solid,uppercase',
            zhSpell: 'R,zimuR,zimu R,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59325,
          searchKey: {
            zhCN: 'S,字母S,字母 S,圆形,实心,大写',
            enUS: 'S,letter s,letter + s,round,solid,uppercase',
            zhSpell: 'S,zimuS,zimu S,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59328,
          searchKey: {
            zhCN: 'T,字母T,字母 T,圆形,实心,大写',
            enUS: 'T,letter t,letter + t,round,solid,uppercase',
            zhSpell: 'T,zimuT,zimu T,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59345,
          searchKey: {
            zhCN: 'U,字母U,字母 U,圆形,实心,大写',
            enUS: 'U,letter u,letter + u,round,solid,uppercase',
            zhSpell: 'U,zimuU,zimu U,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59334,
          searchKey: {
            zhCN: 'V,字母V,字母 V,圆形,实心,大写',
            enUS: 'V,letter v,letter + v,round,solid,uppercase',
            zhSpell: 'V,zimuV,zimu V,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59309,
          searchKey: {
            zhCN: 'W,字母W,字母 W,圆形,实心,大写',
            enUS: 'W,letter w,letter + w,round,solid,uppercase',
            zhSpell: 'W,zimuW,zimu W,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59336,
          searchKey: {
            zhCN: 'X,字母X,字母 X,圆形,实心,大写',
            enUS: 'X,letter x,letter + x,round,solid,uppercase',
            zhSpell: 'X,zimuX,zimu X,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59324,
          searchKey: {
            zhCN: 'Y,字母Y,字母 Y,圆形,实心,大写',
            enUS: 'Y,letter y,letter + y,round,solid,uppercase',
            zhSpell: 'Y,zimuY,zimu Y,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
        {
          iconCode: 59331,
          searchKey: {
            zhCN: 'Z,字母Z,字母 Z,圆形,实心,大写',
            enUS: 'Z,letter z,letter + z,round,solid,uppercase',
            zhSpell: 'Z,zimuZ,zimu Z,yuanxing,shixin,daxie',
          },
          group: 'letter',
        },
      ],
    },
    {
      name: i18n('resource.icons.treatment'),
      id: 'treatment',
      items: [
        {
          iconCode: 59511,
          searchKey: {
            zhCN: '医院, 房子, 首页, 建筑, 医疗',
            enUS: 'Hospital, house, home page, construction, medical',
            zhSpell: 'yiyuan, fangzi, shouye, jianzhu, yiliao',
          },
          group: 'treatment',
        },
        {
          iconCode: 59474,
          searchKey: {
            zhCN: '急诊, 警报, 120, 救护车, 报警, 急救',
            enUS: 'Emergency, alarm, 120, ambulance, alarm, first-aid',
            zhSpell: 'jizhen, jingbao, 120, jiuhuche, baojing, jijiu',
          },
          group: 'treatment',
        },
        {
          iconCode: 59473,
          searchKey: {
            zhCN: '急诊, 警报, 120, 救护车, 报警, 急救',
            enUS: 'Emergency, alarm, 120, ambulance, alarm, first-aid',
            zhSpell: 'jizhen, jingbao, 120, jiuhuche, baojing, jijiu',
          },
          group: 'treatment',
        },
        {
          iconCode: 59507,
          searchKey: {
            zhCN: '中医, 中药, 碗',
            enUS: 'Traditional Chinese medicine, bowl',
            zhSpell: 'zhongyi, zhongyao, wan',
          },
          group: 'treatment',
        },
        {
          iconCode: 59498,
          searchKey: {
            zhCN: '西医, 胶囊, 药品, 丸药',
            enUS: 'Western Medicine, capsule, medicine, pill',
            zhSpell: 'xiyi, jiaonang, yao, yaopin, yaowan',
          },
          group: 'treatment',
        },
        {
          iconCode: 59489,
          searchKey: {
            zhCN: '死亡, 骷髅, 危险品',
            enUS: 'Death, skeleton, dangerous goods',
            zhSpell: 'siwang, kulou, weixianpin',
          },
          group: 'treatment',
        },
        {
          iconCode: 59469,
          searchKey: {
            zhCN: '急诊箱, 医疗箱, 药品, 箱子',
            enUS: 'Emergency box, medical box, medicine, box',
            zhSpell: 'jizhenxiang, yiliaoxiang, yaopin, xiangzi',
          },
          group: 'treatment',
        },
        {
          iconCode: 59479,
          searchKey: {
            zhCN: '救护车, 120, 汽车, 急救',
            enUS: 'Medical treatment, Ambulance, 120, car, first-aid',
            zhSpell: 'jiuhuche, che, 120, qiche, jijiu',
          },
          group: 'treatment',
        },
        {
          iconCode: 59488,
          searchKey: {
            zhCN: '听诊器, 医生, 医疗器材',
            enUS: 'Stethoscope, Doctor, medical equipment',
            zhSpell: 'tingzhenqi, yisheng, yiliaoqicai',
          },
          group: 'treatment',
        },
        {
          iconCode: 59464,
          searchKey: {
            zhCN: '打针, 针管, 医生, 疫苗',
            enUS: 'Needle, injection, syringe, doctor, vaccine',
            zhSpell: 'zhen, dazhen, zhenguan, yisheng, yimiao',
          },
          group: 'treatment',
        },
        {
          iconCode: 59495,
          searchKey: {
            zhCN: '体温计, 温度计, 温度, 感冒, 发烧',
            enUS: 'Thermometer, thermometer, temperature, fever',
            zhSpell: 'tiwenji, wenduji, wendu, ganmao, fashao',
          },
          group: 'treatment',
        },
        {
          iconCode: 59482,
          searchKey: {
            zhCN: '烧杯, 杯子, 玻璃杯, 医疗器材, 器材',
            enUS: 'Beaker, cup, glass, medical equipment, equipment',
            zhSpell: 'shaobei, beizi, bolibei, yiliaoqicai, qicai',
          },
          group: 'treatment',
        },
        {
          iconCode: 59468,
          searchKey: {
            zhCN: '玻璃试管, 医疗器材',
            enUS: 'Test tube, glass test tube, medical equipment, equipment',
            zhSpell: 'shiguan, bolishiguan, yiliaoqicai, qicai',
          },
          group: 'treatment',
        },
        {
          iconCode: 59499,
          searchKey: {
            zhCN: '显微镜, 医疗器材',
            enUS: 'Microscope, medical equipment, equipment',
            zhSpell: 'xianweijing, yiliaoqicai, qicai',
          },
          group: 'treatment',
        },
        {
          iconCode: 59494,
          searchKey: {
            zhCN: '轮椅, 椅子, 残疾',
            enUS: 'Wheelchair, chair, disability',
            zhSpell: 'lunyi, yizi, canji',
          },
          group: 'treatment',
        },
        {
          iconCode: 59466,
          searchKey: {
            zhCN: '妇科, 人, 女性',
            enUS: 'Gynecology, human, female',
            zhSpell: 'fuke, ren, nvxing',
          },
          group: 'treatment',
        },
        {
          iconCode: 59480,
          searchKey: {
            zhCN: '男科, 人, 男性',
            enUS: 'Andrology, human, male',
            zhSpell: 'nanke, ren, nanxing',
          },
          group: 'treatment',
        },
        {
          iconCode: 59477,
          searchKey: {
            zhCN: '儿科, 小孩, 婴儿, 母婴, 发育',
            enUS: 'Pediatrics, children, infants, mothers and infants, development',
            zhSpell: 'erke, xiaohai, yinger, muying, fayu',
          },
          group: 'treatment',
        },
        {
          iconCode: 59505,
          searchKey: {
            zhCN: '医生, 医务人员',
            enUS: 'Doctor, medical staff, person',
            zhSpell: 'yisheng, yiwurenyuan, ren',
          },
          group: 'treatment',
        },
        {
          iconCode: 59476,
          searchKey: {
            zhCN: '护士, 医务人员',
            enUS: 'Nurse, medical staff, person',
            zhSpell: 'hushi, yiwurenyuan, ren',
          },
          group: 'treatment',
        },
        {
          iconCode: 59496,
          searchKey: {
            zhCN: '问诊, 诊断, 消息',
            enUS: 'Consultation, diagnosis, message',
            zhSpell: 'wenzhen, zhenduan, xiaoxi',
          },
          group: 'treatment',
        },
        {
          iconCode: 59470,
          searchKey: {
            zhCN: '挂号, 看病, 诊断',
            enUS: 'Register, see a doctor, diagnose',
            zhSpell: 'guahao, kanbing, zhenduan',
          },
          group: 'treatment',
        },
        {
          iconCode: 59462,
          searchKey: {
            zhCN: '处方, 药品管理, 订单',
            enUS: 'Prescription, drug management, order',
            zhSpell: 'chufang, yaopin, yaopingguanli, dingdan',
          },
          group: 'treatment',
        },
        {
          iconCode: 59506,
          searchKey: {
            zhCN: '医生讲堂, 小知识, 视频',
            enUS: "Doctor's lecture hall, lecture hall, small knowledge, video",
            zhSpell: 'yishengjiangtang, jiangtang, xiaozhishi, shipin',
          },
          group: 'treatment',
        },
        {
          iconCode: 59490,
          searchKey: {
            zhCN: '体检, 检查报告',
            enUS: 'Physical examination, examination, report, examination report',
            zhSpell: 'tijian, jianyan, baogao, jianyanbaogao',
          },
          group: 'treatment',
        },
        {
          iconCode: 59471,
          searchKey: {
            zhCN: '耳朵, 听力, 耳聋, 五官',
            enUS: 'Ear, hearing, deafness, facial features',
            zhSpell: 'er, erduo, tingli, erlong, wuguan',
          },
          group: 'treatment',
        },
        {
          iconCode: 59465,
          searchKey: {
            zhCN: '鼻子, 嗅觉, 鼻炎, 五官',
            enUS: 'Nose, smell, rhinitis, facial features',
            zhSpell: 'bi, bizi, xiujue, biyan, wuguan',
          },
          group: 'treatment',
        },
        {
          iconCode: 59501,
          searchKey: {
            zhCN: '牙齿, 口腔, 牙科',
            enUS: 'Teeth, oral, dental',
            zhSpell: 'ya, yachi, kouqiang, yake',
          },
          group: 'treatment',
        },
        {
          iconCode: 59509,
          searchKey: {
            zhCN: '嘴唇, 口, 吻, 五官',
            enUS: 'Mouth, lips, mouth, kiss, facial features',
            zhSpell: 'zui, zuichun, kou, wen, wuguan',
          },
          group: 'treatment',
        },
        {
          iconCode: 59502,
          searchKey: {
            zhCN: '咽喉, 喉咙, 五官',
            enUS: 'Throat, facial features',
            zhSpell: 'houlong, yanhou, wuguan',
          },
          group: 'treatment',
        },
        {
          iconCode: 59504,
          searchKey: {
            zhCN: '眼睛, 视力, 五官',
            enUS: 'Eyes, eyesight, facial features',
            zhSpell: 'yan, yanjing, shili, wuguan',
          },
          group: 'treatment',
        },
        {
          iconCode: 59503,
          searchKey: {
            zhCN: '眼睛, 视力, 五官',
            enUS: 'Eyes, eyesight, facial features',
            zhSpell: 'yan, yanjing, shili, wuguan',
          },
          group: 'treatment',
        },
        {
          iconCode: 59484,
          searchKey: {
            zhCN: '舌头, 味觉, 吐舌, 搞怪',
            enUS: 'Tongue, taste, spit out, funny',
            zhSpell: 'shetou, weijue, tuse, gaoguai',
          },
          group: 'treatment',
        },
        {
          iconCode: 59463,
          searchKey: {
            zhCN: 'X光, 拍片, CT, 检查',
            enUS: 'X-ray, film, CT, examination',
            zhSpell: 'xguang, paipian, CT, jiancha',
          },
          group: 'treatment',
        },
        {
          iconCode: 59508,
          searchKey: {
            zhCN: '心电图, 检查, 心跳, 频率',
            enUS: 'ECG, examination, heart, heartbeat, frequency',
            zhSpell: 'xindiantu, jiancha, xin, xintiao, pinlv',
          },
          group: 'treatment',
        },
        {
          iconCode: 59487,
          searchKey: {
            zhCN: '皮肤科, 皮肤病',
            enUS: 'Dermatology, skin, dermatology',
            zhSpell: 'pifuke, pifu, pifubing',
          },
          group: 'treatment',
        },
        {
          iconCode: 59483,
          searchKey: {
            zhCN: '内科, 肠道',
            enUS: 'Internal medicine, intestine',
            zhSpell: 'neike, changdao',
          },
          group: 'treatment',
        },
        {
          iconCode: 59485,
          searchKey: {
            zhCN: '糖尿病, 血液, 水滴',
            enUS: 'Diabetes, blood, water, water drops',
            zhSpell: 'tangniaobing, xueye, shui, shuidi',
          },
          group: 'treatment',
        },
        {
          iconCode: 59491,
          searchKey: {
            zhCN: '生育, 男女, 性别, 不孕不育',
            enUS: 'Fertility, male and female, gender, infertility',
            zhSpell: 'shengyu, nannv, xingbie, buyunbuyu',
          },
          group: 'treatment',
        },
        {
          iconCode: 59492,
          searchKey: {
            zhCN: '脱发, 头发',
            enUS: 'Hair loss, hair loss',
            zhSpell: 'tuofa, toufa',
          },
          group: 'treatment',
        },
        {
          iconCode: 59497,
          searchKey: {
            zhCN: '胃, 消化',
            enUS: 'stomach, digestion',
            zhSpell: 'wei, xiaohua',
          },
          group: 'treatment',
        },
        {
          iconCode: 59472,
          searchKey: {
            zhCN: '肝脏',
            enUS: 'Liver',
            zhSpell: 'gan, ganzang',
          },
          group: 'treatment',
        },
        {
          iconCode: 59486,
          searchKey: {
            zhCN: '肾脏',
            enUS: 'Kidney',
            zhSpell: 'shen, shenzang',
          },
          group: 'treatment',
        },
        {
          iconCode: 59500,
          searchKey: {
            zhCN: '心脏',
            enUS: 'Heart',
            zhSpell: 'xin, xinzang',
          },
          group: 'treatment',
        },
        {
          iconCode: 59478,
          searchKey: {
            zhCN: '大脑, 智慧, 思想',
            enUS: 'Brain, wisdom, thought',
            zhSpell: 'nao, danao, zhihui, sixiang',
          },
          group: 'treatment',
        },
        {
          iconCode: 59493,
          searchKey: {
            zhCN: '卵巢, 生育',
            enUS: 'Ovary, fertility',
            zhSpell: 'luanchao, shengyu',
          },
          group: 'treatment',
        },
        {
          iconCode: 59475,
          searchKey: {
            zhCN: '骨科, 骨头, 骨折',
            enUS: 'Orthopedics, bone, fracture',
            zhSpell: 'guke, gutou, guzhe',
          },
          group: 'treatment',
        },
        {
          iconCode: 59481,
          searchKey: {
            zhCN: '奶瓶, 婴幼儿发育, 发育, 瓶子',
            enUS: 'Bottle, infant development, development, bottle',
            zhSpell: 'naiping, yingyouerfayu, fayu, pingzi, ping',
          },
          group: 'treatment',
        },
        {
          iconCode: 59510,
          searchKey: {
            zhCN: '肿瘤, 病毒, 病菌, 新冠',
            enUS: 'Tumor, virus, pathogen, new crown',
            zhSpell: 'zhongliu, bingdu, bingjun, xinguan',
          },
          group: 'treatment',
        },
        {
          iconCode: 59467,
          searchKey: {
            zhCN: '艾滋, 世界艾滋日',
            enUS: 'AIDS, World AIDS',
            zhSpell: 'aizi, shijieaiziri',
          },
          group: 'treatment',
        },
      ],
    },
    {
      name: i18n('resource.icons.finance'),
      id: 'finance',
      items: [
        {
          iconCode: 60865,
          searchKey: {
            zhCN: '自选, 星',
            enUS: 'Optional, star',
            zhSpell: 'zixuan, xing',
          },
          group: 'finance',
        },
        {
          iconCode: 60864,
          searchKey: {
            zhCN: '资债信息, 上涨',
            enUS: 'Asset and debt information, rise',
            zhSpell: 'zizhaixinxi, shangzhang',
          },
          group: 'finance',
        },
        {
          iconCode: 60867,
          searchKey: {
            zhCN: '资金流向, 存钱',
            enUS: 'Capital flow, save money',
            zhSpell: 'zijinliuxiang, unqian',
          },
          group: 'finance',
        },
        {
          iconCode: 60868,
          searchKey: {
            zhCN: '资金归集, 存款, 钱',
            enUS: 'Fund collection, Deposits, money',
            zhSpell: 'zijinguiji, cunkuan, qian',
          },
          group: 'finance',
        },
        {
          iconCode: 60866,
          searchKey: {
            zhCN: '资产托管, 握手, 友好, 关爱, 合作',
            enUS: 'Asset custody, handshake, Friendly, care, cooperation',
            zhSpell: 'zichantuoguan, woshou, youhao, guanai, hezuo',
          },
          group: 'finance',
        },
        {
          iconCode: 60862,
          searchKey: {
            zhCN: '资产, 钱包, 钱袋',
            enUS: 'Assets, wallet, purse',
            zhSpell: 'zichan, qianbao, qiandai',
          },
          group: 'finance',
        },
        {
          iconCode: 60861,
          searchKey: {
            zhCN: '猪, 存钱罐, 金融, 理财, 资产',
            enUS: 'Pigs, piggy bank, finance, financial transactions, assets',
            zhSpell: 'zhu, cunqianguan, jinrong, licai, zichan',
          },
          group: 'finance',
        },
        {
          iconCode: 60859,
          searchKey: {
            zhCN: '猪, 存钱罐, 金融, 理财, 资产',
            enUS: 'Pigs, piggy bank, finance, financial transactions, assets',
            zhSpell: 'zhu, cunqianguan, jinrong, licai, zichan',
          },
          group: 'finance',
        },
        {
          iconCode: 60863,
          searchKey: {
            zhCN: '信用卡, 资产, 钱包, 银行卡',
            enUS: 'Credit Card, my assets, wallet, bank card',
            zhSpell: 'xinyongka, wodezichan, qianbao, yinhangka',
          },
          group: 'finance',
        },
        {
          iconCode: 60851,
          searchKey: {
            zhCN: '信托机构, 银行, 存款, 金融机构',
            enUS: 'Trust institutions, bank, deposit, financial institution',
            zhSpell: 'xintuojigou, yinhang, cunkuan, jinrongjigou',
          },
          group: 'finance',
        },
        {
          iconCode: 60860,
          searchKey: {
            zhCN: '视频面签, 开视频, 拍照, 人物',
            enUS: 'Video signature, open video, photograph, character',
            zhSpell: 'shipinmianqian, kaishipin, paizhao, renwu',
          },
          group: 'finance',
        },
        {
          iconCode: 60852,
          searchKey: {
            zhCN: '实力, 奖杯',
            enUS: 'Strength, trophy',
            zhSpell: 'shili, jiangbei',
          },
          group: 'finance',
        },
        {
          iconCode: 60858,
          searchKey: {
            zhCN: '任务中心, 日历, 签到, 记事本',
            enUS: 'Mission Center, Calendar, Check in, Notepad',
            zhSpell: 'renwuzhongxin, rili, qiandao, jishiben',
          },
          group: 'finance',
        },
        {
          iconCode: 60854,
          searchKey: {
            zhCN: '钱袋, 贷款, 资产',
            enUS: 'Purse, Loans, assets',
            zhSpell: 'qiandai, daikuan, zichan',
          },
          group: 'finance',
        },
        {
          iconCode: 60857,
          searchKey: {
            zhCN: '理财, 铜钱, 金融',
            enUS: 'financial transactions, copper, finance',
            zhSpell: 'licai, tongqian, jinrong',
          },
          group: 'finance',
        },
        {
          iconCode: 60845,
          searchKey: {
            zhCN: '开户流程, 线路',
            enUS: 'Account opening process, line',
            zhSpell: 'kaihuliucheng, xianlu',
          },
          group: 'finance',
        },
        {
          iconCode: 60855,
          searchKey: {
            zhCN: '开户, 办理',
            enUS: 'Account opening, handle',
            zhSpell: 'kaihu, banli',
          },
          group: 'finance',
        },
        {
          iconCode: 60848,
          searchKey: {
            zhCN: '金融圈, 圆圈, 波纹, 水波',
            enUS: 'Financial circles, ripple, wave',
            zhSpell: 'jinrongquan, yuanquan, bowen, shuibo',
          },
          group: 'finance',
        },
        {
          iconCode: 60847,
          searchKey: {
            zhCN: '交易记录, 信息, 记事本',
            enUS: 'Transactions, information, notepad',
            zhSpell: 'jiaoyijilu, xinxi, jishiben',
          },
          group: 'finance',
        },
        {
          iconCode: 60850,
          searchKey: {
            zhCN: '交易查询, 信息查询, 信息搜索',
            enUS: 'Transaction inquiry, information Service',
            zhSpell: 'jiaoyichaxun, xinxichaxun, xinxisousuo ',
          },
          group: 'finance',
        },
        {
          iconCode: 60853,
          searchKey: {
            zhCN: '基金, 信息, 图表',
            enUS: 'Fund, Information, Chart',
            zhSpell: 'jijin, xinxi, tubiao',
          },
          group: 'finance',
        },
        {
          iconCode: 60846,
          searchKey: {
            zhCN: '货币, 资产',
            enUS: 'Money, assets',
            zhSpell: 'huobi, zichan',
          },
          group: 'finance',
        },
        {
          iconCode: 60849,
          searchKey: {
            zhCN: '货币, 交易, 资产',
            enUS: 'Money, Deal, assets',
            zhSpell: 'huobi, jiaoyi, zichan',
          },
          group: 'finance',
        },
        {
          iconCode: 60844,
          searchKey: {
            zhCN: '黄金, 元宝, 钱, 金额, 资金',
            enUS: 'Gold, Yuanbao, amount of money, capital',
            zhSpell: 'huangjin, yuanbao, qian, jine, zijin',
          },
          group: 'finance',
        },
        {
          iconCode: 60841,
          searchKey: {
            zhCN: '黄金, 金条, 堆积, 货物, 钱, 金额, 资金',
            enUS: 'Gold, bullion, Accumulation, Goods, amount of money, capital',
            zhSpell: 'huangjin, jintiao, duiji, huowu, qian, jine, zijin',
          },
          group: 'finance',
        },
        {
          iconCode: 60856,
          searchKey: {
            zhCN: '行业分析, 数据分析, 柱状图, 信息, 报表',
            enUS: 'Industry analysis, Data analysis, Histogram, Information, report form',
            zhSpell: 'hangyefenxi, shujufenxi, zhuzhuangtu, xinxi, babiao',
          },
          group: 'finance',
        },
        {
          iconCode: 60839,
          searchKey: {
            zhCN: '国债',
            enUS: 'TB',
            zhSpell: 'guozhai',
          },
          group: 'finance',
        },
        {
          iconCode: 60836,
          searchKey: {
            zhCN: '管理信息, 规划',
            enUS: 'Management information, plan',
            zhSpell: 'guanlixinxi, guihua',
          },
          group: 'finance',
        },
        {
          iconCode: 60835,
          searchKey: {
            zhCN: '管理基金, 上涨, 交易',
            enUS: 'Managing funds, rise, transaction',
            zhSpell: 'gupiao-zuhe, zichan, quanbu, zujian',
          },
          group: 'finance',
        },
        {
          iconCode: 60831,
          searchKey: {
            zhCN: '股票-组合, 资产, 全部, 组件',
            enUS: 'Stock portfolio, assets, All, assembly',
            zhSpell: 'kaihuliucheng, xianlu',
          },
          group: 'finance',
        },
        {
          iconCode: 60837,
          searchKey: {
            zhCN: '股票, 设置',
            enUS: 'Stocks, set up',
            zhSpell: 'gupiao, shezhi',
          },
          group: 'finance',
        },
        {
          iconCode: 60833,
          searchKey: {
            zhCN: '股票, 上升, 上涨, 涨幅, 数据',
            enUS: 'Stocks, rise, Increase, data',
            zhSpell: 'gupiao, shangsheng, shangzhang, zhangfu, shuju',
          },
          group: 'finance',
        },
        {
          iconCode: 60840,
          searchKey: {
            zhCN: '服务记录, 关怀, 关爱, 喜欢',
            enUS: 'Service records, care for, like',
            zhSpell: 'fuwujilu, guanhuai, guanai, xihuan',
          },
          group: 'finance',
        },
        {
          iconCode: 60842,
          searchKey: {
            zhCN: '风险测评',
            enUS: 'Risk assessment',
            zhSpell: 'fengxianceping ',
          },
          group: 'finance',
        },
        {
          iconCode: 60828,
          searchKey: {
            zhCN: '风险评估',
            enUS: 'risk assessment',
            zhSpell: 'fengxianpinggu',
          },
          group: 'finance',
        },
        {
          iconCode: 60830,
          searchKey: {
            zhCN: '到期产品, 记录, 签到, 打卡, 规划',
            enUS: 'Expired products, record, Check in, punch the clock, plan',
            zhSpell: 'daoqichanpin, jilu, qiandao, daka, guihua',
          },
          group: 'finance',
        },
        {
          iconCode: 60827,
          searchKey: {
            zhCN: '产品分析, 3D, 立方体',
            enUS: 'Product analysis, 3D, cube',
            zhSpell: 'chanpinfenxi, 3D, lifangti',
          },
          group: 'finance',
        },
        {
          iconCode: 60834,
          searchKey: {
            zhCN: '产品查询, 搜索',
            enUS: 'Product query, search',
            zhSpell: 'chanpinchaxun, sousuo',
          },
          group: 'finance',
        },
        {
          iconCode: 60825,
          searchKey: {
            zhCN: '查看更多, 更多选择, 全部, 分类',
            enUS: 'See more, more choices, all, categories',
            zhSpell: 'chakangengduo, gengduoxuanze, quanbu,fenlei',
          },
          group: 'finance',
        },
        {
          iconCode: 60829,
          searchKey: {
            zhCN: '操作指南, 操作手册, 教程',
            enUS: 'Operation guide, operation manual, tutorial',
            zhSpell: 'caozuozhinan, caozuoshouce, jiaoc',
          },
          group: 'finance',
        },
        {
          iconCode: 60826,
          searchKey: {
            zhCN: '本地服务, 心, 关注, 收藏',
            enUS: 'Local service, heart, attention, collection',
            zhSpell: 'bendifuwu, xin, guanzhu, shoucang',
          },
          group: 'finance',
        },
        {
          iconCode: 60838,
          searchKey: {
            zhCN: '被保人, 盾, 保险',
            enUS: 'The insured, Shield, Insurance',
            zhSpell: 'beibaoren, dun, baoxian',
          },
          group: 'finance',
        },
        {
          iconCode: 60832,
          searchKey: {
            zhCN: '保险, 雨伞, 雨具, 下雨',
            enUS: 'Insurance, Umbrella, Rain gear',
            zhSpell: 'baoxian, qian, anquan, dunpai',
          },
          group: 'finance',
        },
        {
          iconCode: 60843,
          searchKey: {
            zhCN: '安全, 盾牌',
            enUS: 'security, shield',
            zhSpell: 'anquan, dunpai',
          },
          group: 'finance',
        },
      ],
    },
    {
      name: i18n('resource.icons.eating'),
      id: 'eating',
      items: [
        {
          iconCode: 60875,
          searchKey: {
            zhCN: '贝壳, 水产, 食物, 海鲜',
            enUS: 'Shells, aquatic products, food, seafood',
            zhSpell: 'beike, shuichan, shiwu, haixian',
          },
          group: 'eating',
        },
        {
          iconCode: 60878,
          searchKey: {
            zhCN: '冰淇淋, 雪糕',
            enUS: 'ice cream',
            zhSpell: 'bingqilin, xuegao',
          },
          group: 'eating',
        },
        {
          iconCode: 60883,
          searchKey: {
            zhCN: '冰淇淋, 雪糕',
            enUS: 'ice cream',
            zhSpell: 'bingqilin, xuegao',
          },
          group: 'eating',
        },
        {
          iconCode: 60879,
          searchKey: {
            zhCN: '草莓, 水果',
            enUS: 'Strawberry, fruit',
            zhSpell: 'caomei, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60870,
          searchKey: {
            zhCN: '草莓, 水果',
            enUS: 'Strawberry, fruit',
            zhSpell: 'caomei, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60869,
          searchKey: {
            zhCN: '茶壶, 水壶',
            enUS: 'Teapot, kettle',
            zhSpell: 'chahu, shuihu',
          },
          group: 'eating',
        },
        {
          iconCode: 60872,
          searchKey: {
            zhCN: '茶叶, 叶子',
            enUS: 'Tea, leaf',
            zhSpell: 'chaye, yezi',
          },
          group: 'eating',
        },
        {
          iconCode: 60877,
          searchKey: {
            zhCN: '橙子, 水果',
            enUS: 'Orange, fruit',
            zhSpell: 'chengzi, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60874,
          searchKey: {
            zhCN: '橙子, 水果',
            enUS: 'Orange, fruit',
            zhSpell: 'chengzi, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60876,
          searchKey: {
            zhCN: '果汁, 饮品, 冷饮',
            enUS: 'Fruit juice, drink, cold drink',
            zhSpell: 'guozhi, yinpin, lengyin',
          },
          group: 'eating',
        },
        {
          iconCode: 60888,
          searchKey: {
            zhCN: '汉堡, 快餐',
            enUS: 'Hamburger, fast food',
            zhSpell: 'hanbao, kuaican',
          },
          group: 'eating',
        },
        {
          iconCode: 60871,
          searchKey: {
            zhCN: '红椒, 辣椒, 蔬菜, 食物',
            enUS: 'Red pepper, pepper, vegetables, food',
            zhSpell: 'hongjiao, lajiao, shucai, shiwu',
          },
          group: 'eating',
        },
        {
          iconCode: 60885,
          searchKey: {
            zhCN: '火锅',
            enUS: 'Hot Pot',
            zhSpell: 'huoguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60882,
          searchKey: {
            zhCN: '鸡蛋, 食物, 蛋白质',
            enUS: 'Egg, food, protein',
            zhSpell: 'jidan, shiwu, danbaizhi',
          },
          group: 'eating',
        },
        {
          iconCode: 60887,
          searchKey: {
            zhCN: '鸡肉, 食物',
            enUS: 'Chicken, food',
            zhSpell: 'jirou, shiwu',
          },
          group: 'eating',
        },
        {
          iconCode: 60881,
          searchKey: {
            zhCN: '鸡腿, 食物',
            enUS: 'Chicken leg, food',
            zhSpell: 'jitui, shiwu',
          },
          group: 'eating',
        },
        {
          iconCode: 60884,
          searchKey: {
            zhCN: '鸡尾酒, 饮品, 冷饮',
            enUS: 'Cocktails, drinks, cold drinkse',
            zhSpell: 'jiweijiu, yinpin, lengyin',
          },
          group: 'eating',
        },
        {
          iconCode: 60873,
          searchKey: {
            zhCN: '煎蛋, 鸡蛋, 食物, 蛋白质, 蛋饼',
            enUS: 'Fried egg, egg, food, protein, egg cake',
            zhSpell: 'jiandan, jidan, shiwu, danbaizhi, danbing',
          },
          group: 'eating',
        },
        {
          iconCode: 60880,
          searchKey: {
            zhCN: '咖啡',
            enUS: 'Coffee',
            zhSpell: 'kafei',
          },
          group: 'eating',
        },
        {
          iconCode: 60886,
          searchKey: {
            zhCN: '咖啡杯, 茶',
            enUS: 'Coffee cup, tea',
            zhSpell: 'kafeibei, cha',
          },
          group: 'eating',
        },
        {
          iconCode: 60889,
          searchKey: {
            zhCN: '咖啡杯',
            enUS: 'Coffee cup',
            zhSpell: 'kafeibei',
          },
          group: 'eating',
        },
        {
          iconCode: 60908,
          searchKey: {
            zhCN: '咖啡杯',
            enUS: 'Coffee cup',
            zhSpell: 'kafeibei',
          },
          group: 'eating',
        },
        {
          iconCode: 60893,
          searchKey: {
            zhCN: '咖啡豆, 豆类, 豆子',
            enUS: 'Coffee beans, beans, beans',
            zhSpell: 'kafeidou, doulei, douzi',
          },
          group: 'eating',
        },
        {
          iconCode: 60894,
          searchKey: {
            zhCN: '烤肉, 食物',
            enUS: 'Barbecue, food',
            zhSpell: 'kaorou, shiwu',
          },
          group: 'eating',
        },
        {
          iconCode: 60896,
          searchKey: {
            zhCN: '可乐, 饮料, 瓶子',
            enUS: 'Coke, drink, bottle',
            zhSpell: 'kele, yinliao, pingzi',
          },
          group: 'eating',
        },
        {
          iconCode: 60892,
          searchKey: {
            zhCN: '可乐, 杯子',
            enUS: 'Coke , cup',
            zhSpell: 'kele, beizi',
          },
          group: 'eating',
        },
        {
          iconCode: 60895,
          searchKey: {
            zhCN: '可乐, 易拉罐, 饮料',
            enUS: 'Coke , cans, beverages',
            zhSpell: 'kele, yilaguan, yinliao',
          },
          group: 'eating',
        },
        {
          iconCode: 60905,
          searchKey: {
            zhCN: '蓝莓, 水果',
            enUS: 'Blueberry , fruit',
            zhSpell: 'lanmei, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60898,
          searchKey: {
            zhCN: '蓝莓, 水果',
            enUS: 'Blueberry , fruit',
            zhSpell: 'lanmei, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60897,
          searchKey: {
            zhCN: '冷饮, 饮料, 饮品',
            enUS: 'Cold drink, drink, drink',
            zhSpell: 'lengyin, yinliao, yinpin',
          },
          group: 'eating',
        },
        {
          iconCode: 60902,
          searchKey: {
            zhCN: '梨, 水果',
            enUS: 'Pear, fruit',
            zhSpell: 'li, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60899,
          searchKey: {
            zhCN: '龙虾, 水产, 食物, 海鲜',
            enUS: 'Lobster, aquatic products, seafood',
            zhSpell: 'longxia, shuichan, shiwu, haixian',
          },
          group: 'eating',
        },
        {
          iconCode: 60890,
          searchKey: {
            zhCN: '龙虾, 水产, 食物, 海鲜',
            enUS: 'Lobster, aquatic products, seafood',
            zhSpell: 'longxia, shuichan, shiwu, haixian',
          },
          group: 'eating',
        },
        {
          iconCode: 60901,
          searchKey: {
            zhCN: '帽子, 厨师, 餐饮',
            enUS: 'Hat, chef, catering',
            zhSpell: 'maozi, chushi, canyin',
          },
          group: 'eating',
        },
        {
          iconCode: 60904,
          searchKey: {
            zhCN: '面包, 三明治, 奶酪, 食物, 糕点, 甜点',
            enUS: 'Bread, sandwich, cheese, food, pastry, dessert',
            zhSpell: 'mianbao, sanmingzhi, nailao, shiwu, gaodian, tiandian',
          },
          group: 'eating',
        },
        {
          iconCode: 60891,
          searchKey: {
            zhCN: '奶茶, 饮品',
            enUS: 'Milk tea, drink',
            zhSpell: 'naicha, yinpin',
          },
          group: 'eating',
        },
        {
          iconCode: 60906,
          searchKey: {
            zhCN: '牛奶, 乳酸菌',
            enUS: 'Milk, lactic acid bacteria',
            zhSpell: 'niunai, rusuanjun',
          },
          group: 'eating',
        },
        {
          iconCode: 60903,
          searchKey: {
            zhCN: '牛肉, 食物',
            enUS: 'Beef, food',
            zhSpell: 'niurou, shiwu',
          },
          group: 'eating',
        },
        {
          iconCode: 60900,
          searchKey: {
            zhCN: '牛油果, 水果',
            enUS: 'Avocado, fruit',
            zhSpell: 'niuyouguo, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60907,
          searchKey: {
            zhCN: '排骨, 食物',
            enUS: 'Ribs, food',
            zhSpell: 'paigu, shiwu',
          },
          group: 'eating',
        },
        {
          iconCode: 60914,
          searchKey: {
            zhCN: '螃蟹, 水产, 食物, 海鲜',
            enUS: 'Crab, aquatic products, food, seafood',
            zhSpell: 'pangxie, shuichan, shiwu, haixian',
          },
          group: 'eating',
        },
        {
          iconCode: 60924,
          searchKey: {
            zhCN: '啤酒, 泡沫, 聚会',
            enUS: 'Beer, foam, Party',
            zhSpell: 'pijiu, paomo, juhui',
          },
          group: 'eating',
        },
        {
          iconCode: 60913,
          searchKey: {
            zhCN: '苹果, 水果',
            enUS: 'Apple, fruit',
            zhSpell: 'pingguo, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60910,
          searchKey: {
            zhCN: '苹果, 水果',
            enUS: 'Apple, fruit',
            zhSpell: 'pingguo, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60911,
          searchKey: {
            zhCN: '苹果, 水果',
            enUS: 'Apple, fruit',
            zhSpell: 'pingguo, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60909,
          searchKey: {
            zhCN: '苹果, 水果',
            enUS: 'Apple, fruit',
            zhSpell: 'pingguo, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60925,
          searchKey: {
            zhCN: '葡萄, 水果',
            enUS: 'Grape, fruit',
            zhSpell: 'putao, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60915,
          searchKey: {
            zhCN: '葡萄干, 水果',
            enUS: 'Raisins, fruit',
            zhSpell: 'putaogan, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60916,
          searchKey: {
            zhCN: '茄子, 蔬菜',
            enUS: 'Eggplant, vegetable',
            zhSpell: 'qiezi, shucai',
          },
          group: 'eating',
        },
        {
          iconCode: 60912,
          searchKey: {
            zhCN: '青椒, 辣椒, 蔬菜, 食物',
            enUS: 'Green pepper, pepper, vegetable, food',
            zhSpell: 'qingjiao, lajiao, shucai, shiwu',
          },
          group: 'eating',
        },
        {
          iconCode: 60917,
          searchKey: {
            zhCN: '热茶, 杯子, 咖啡',
            enUS: 'Hot tea, cup, coffee',
            zhSpell: 'recha, beizi, kafei',
          },
          group: 'eating',
        },
        {
          iconCode: 60928,
          searchKey: {
            zhCN: '肉卷, 早餐',
            enUS: 'Meat rolls, breakfast',
            zhSpell: 'roujuan, zaocan',
          },
          group: 'eating',
        },
        {
          iconCode: 60918,
          searchKey: {
            zhCN: '薯条, 快餐, 肯德基, 食物',
            enUS: 'French fries, fast food, KFC, food',
            zhSpell: 'shutiao, kuaican, kendeji, shiwu',
          },
          group: 'eating',
        },
        {
          iconCode: 60922,
          searchKey: {
            zhCN: '水, 杯子',
            enUS: 'Water, cup',
            zhSpell: 'shui, beizi',
          },
          group: 'eating',
        },
        {
          iconCode: 60919,
          searchKey: {
            zhCN: '水, 杯子, 酒杯',
            enUS: 'Water, cup, wine cup',
            zhSpell: 'shui, beizi, jiubei',
          },
          group: 'eating',
        },
        {
          iconCode: 60923,
          searchKey: {
            zhCN: '水壶, 咖啡机',
            enUS: 'Kettle, coffee machine',
            zhSpell: 'shuihu, kafeiji',
          },
          group: 'eating',
        },
        {
          iconCode: 60921,
          searchKey: {
            zhCN: '糖葫芦, 零食',
            enUS: 'Candied haws, snacks',
            zhSpell: 'tanghulu, lingshi',
          },
          group: 'eating',
        },
        {
          iconCode: 60926,
          searchKey: {
            zhCN: '糖葫芦, 零食',
            enUS: 'Candied haws, snacks',
            zhSpell: 'tanghulu, lingshi',
          },
          group: 'eating',
        },
        {
          iconCode: 60920,
          searchKey: {
            zhCN: '桃子, 水果',
            enUS: 'Peaches, fruits',
            zhSpell: 'taozi, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60927,
          searchKey: {
            zhCN: '西餐, 食物',
            enUS: 'Western, food',
            zhSpell: 'xican, shiwu',
          },
          group: 'eating',
        },
        {
          iconCode: 60931,
          searchKey: {
            zhCN: '西瓜, 水果',
            enUS: 'Watermelon, fruit',
            zhSpell: 'xigua, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60940,
          searchKey: {
            zhCN: '西瓜, 水果',
            enUS: 'Watermelon, fruit',
            zhSpell: 'xigua, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60935,
          searchKey: {
            zhCN: '西瓜, 水果',
            enUS: 'Watermelon, fruit',
            zhSpell: 'xigua, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60929,
          searchKey: {
            zhCN: '西柚, 桔子, 果肉, 水果',
            enUS: 'Grapefruit, orange, pulp, fruit',
            zhSpell: 'xiyou, juzi, guorou, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60933,
          searchKey: {
            zhCN: '香蕉, 水果',
            enUS: 'Banana, fruit',
            zhSpell: 'xiangjiao, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60938,
          searchKey: {
            zhCN: '香蕉, 水果',
            enUS: 'Banana, fruit',
            zhSpell: 'xiangjiao, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60936,
          searchKey: {
            zhCN: '椰子, 水果, 球体',
            enUS: 'Coconut, fruit, sphere',
            zhSpell: 'yezi, shuiguo, qiuti',
          },
          group: 'eating',
        },
        {
          iconCode: 60930,
          searchKey: {
            zhCN: '椰子, 水果',
            enUS: 'Coconut, fruit',
            zhSpell: 'yezi, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60932,
          searchKey: {
            zhCN: '椰子, 椰汁',
            enUS: 'Coconut, coconut milk',
            zhSpell: 'yezi, yezhi',
          },
          group: 'eating',
        },
        {
          iconCode: 60939,
          searchKey: {
            zhCN: '樱桃, 水果',
            enUS: 'Cherry, fruit',
            zhSpell: 'yingtao, shuiguo',
          },
          group: 'eating',
        },
        {
          iconCode: 60934,
          searchKey: {
            zhCN: '鱿鱼, 水产, 食物, 海鲜',
            enUS: 'Squid, aquatic products, food, seafood',
            zhSpell: 'youyu, shuichan, shiwu, haixian',
          },
          group: 'eating',
        },
        {
          iconCode: 60937,
          searchKey: {
            zhCN: '鱼肉, 食物',
            enUS: 'Fish, food',
            zhSpell: 'yurou, shiwu',
          },
          group: 'eating',
        },
      ],
    },
    {
      name: i18n('resource.icons.nature'),
      id: 'nature',
      items: [
        {
          iconCode: 60947,
          searchKey: {
            zhCN: '大本营, 营地, 帐篷, 探险, 夏令营',
            enUS: 'Base camp, campsite, tent, exploration, summer camp',
            zhSpell: 'dabenying, yingdi, zhangpeng, tanxian, xialingying',
          },
          group: 'nature',
        },
        {
          iconCode: 60943,
          searchKey: {
            zhCN: '地形, 地理, 山脉, 旅游',
            enUS: 'Terrain, Geography, Mountains, Tourism',
            zhSpell: 'dixing, dili, shanmai, lvyou',
          },
          group: 'nature',
        },
        {
          iconCode: 60942,
          searchKey: {
            zhCN: '电力, 闪电, 动力, 闪光, 充电, 箭头',
            enUS: 'Electricity, lightning, power, flash, charging, arrow',
            zhSpell: 'dianli, shandian, dongli, shanguang, chogndian, jiantou',
          },
          group: 'nature',
        },
        {
          iconCode: 60944,
          searchKey: {
            zhCN: '电力, 闪电, 动力, 闪光, 充电, 箭头',
            enUS: 'Electricity, lightning, power, flash, charging, arrow',
            zhSpell: 'dianli, shandian, dongli, shanguang, chogndian, jiantou',
          },
          group: 'nature',
        },
        {
          iconCode: 60945,
          searchKey: {
            zhCN: '多雨, 天气, 云',
            enUS: 'Rainy, Weather, Cloudy',
            zhSpell: 'duoyu, tianqi, yun',
          },
          group: 'nature',
        },
        {
          iconCode: 60955,
          searchKey: {
            zhCN: '多雨, 天气, 云',
            enUS: 'Rainy, Weather, Cloudy',
            zhSpell: 'duoyu, tianqi, yun',
          },
          group: 'nature',
        },
        {
          iconCode: 60941,
          searchKey: {
            zhCN: '多云, 气象，天气预报',
            enUS: 'Cloudy, meteorological, weather forecast',
            zhSpell: 'duoyun, qixiang, tianqiyubao',
          },
          group: 'nature',
        },
        {
          iconCode: 60946,
          searchKey: {
            zhCN: '多云, 气象，天气预报',
            enUS: 'Cloudy, meteorological, weather forecast',
            zhSpell: 'duoyun, qixiang, tianqiyubao',
          },
          group: 'nature',
        },
        {
          iconCode: 60952,
          searchKey: {
            zhCN: '花, 芳香, 春天',
            enUS: 'Flowers, fragrant, spring',
            zhSpell: 'hua, fangxiang, chuntian',
          },
          group: 'nature',
        },
        {
          iconCode: 60965,
          searchKey: {
            zhCN: '花, 芳香, 春天',
            enUS: 'Flowers, fragrant, spring',
            zhSpell: 'hua, fangxiang, chuntian',
          },
          group: 'nature',
        },
        {
          iconCode: 60980,
          searchKey: {
            zhCN: '花, 芳香, 春天',
            enUS: 'Flowers, fragrant, spring',
            zhSpell: 'hua, fangxiang, chuntian',
          },
          group: 'nature',
        },
        {
          iconCode: 60961,
          searchKey: {
            zhCN: '火球, 火焰, 燃烧, 热',
            enUS: 'fireball, flame, burning, heat',
            zhSpell: 'huoqiu, huoyan, ranshao, re',
          },
          group: 'nature',
        },
        {
          iconCode: 60948,
          searchKey: {
            zhCN: '火焰, 燃烧, 热, 流行',
            enUS: 'Flame, combustion, heat, popularity',
            zhSpell: 'huoyan, ranshao, re, liuxing',
          },
          group: 'nature',
        },
        {
          iconCode: 60950,
          searchKey: {
            zhCN: '雷雨, 闪电, 云, 下雨',
            enUS: 'Thunderstorm, lightning, clouds, rain',
            zhSpell: 'leiyu, shandian, yun, xiayu',
          },
          group: 'nature',
        },
        {
          iconCode: 60951,
          searchKey: {
            zhCN: '雷雨, 闪电, 云, 下雨',
            enUS: 'Thunderstorm, lightning, clouds, rain',
            zhSpell: 'leiyu, shandian, yun, xiayu',
          },
          group: 'nature',
        },
        {
          iconCode: 60976,
          searchKey: {
            zhCN: '漏洞, 昆虫, 甲虫, 瓢虫',
            enUS: 'Bug, insects, beetles, ladybugs',
            zhSpell: 'loudong, kunchong, jiachong, piaochong',
          },
          group: 'nature',
        },
        {
          iconCode: 60954,
          searchKey: {
            zhCN: '玫瑰, 花, 爱情',
            enUS: 'Roses, flowers, love',
            zhSpell: 'meigui, hua, aiqing',
          },
          group: 'nature',
        },
        {
          iconCode: 60973,
          searchKey: {
            zhCN: '玫瑰, 花, 爱情',
            enUS: 'Roses, flowers, love',
            zhSpell: 'meigui, hua, aiqing',
          },
          group: 'nature',
        },
        {
          iconCode: 60959,
          searchKey: {
            zhCN: '晴朗, 天气预报, 温度, 白天, 光线',
            enUS: 'Sunny, weather forecast, temperature, daytime, light, sun',
            zhSpell: 'qinglang, tianqiyubao, wendu, baitian, guangxian',
          },
          group: 'nature',
        },
        {
          iconCode: 60957,
          searchKey: {
            zhCN: '晴朗, 天气预报, 温度, 白天, 光线',
            enUS: 'Sunny, weather forecast, temperature, daytime, light, sun',
            zhSpell: 'qinglang, tianqiyubao, wendu, baitian, guangxian',
          },
          group: 'nature',
        },
        {
          iconCode: 60964,
          searchKey: {
            zhCN: '晴朗, 天气预报, 温度, 白天, 光线',
            enUS: 'Sunny, weather forecast, temperature, daytime, light, sun',
            zhSpell: 'qinglang, tianqiyubao, wendu, baitian, guangxian',
          },
          group: 'nature',
        },
        {
          iconCode: 60974,
          searchKey: {
            zhCN: '晴天, 云, 天气预报, 太阳',
            enUS: 'Sunny, cloudy, weather forecast, sun',
            zhSpell: 'qingtian, yun, tianqiyubao, taiyang',
          },
          group: 'nature',
        },
        {
          iconCode: 60956,
          searchKey: {
            zhCN: '晴天, 云, 天气预报, 太阳',
            enUS: 'Sunny, cloudy, weather forecast, sun',
            zhSpell: 'qingtian, yun, tianqiyubao, taiyang',
          },
          group: 'nature',
        },
        {
          iconCode: 60949,
          searchKey: {
            zhCN: '热, 火焰, 燃烧, 高温, 温度',
            enUS: 'heat, Flame, combustion, high temperature',
            zhSpell: 're, huoyan, ranshao, gaowen, wendu',
          },
          group: 'nature',
        },
        {
          iconCode: 60963,
          searchKey: {
            zhCN: '热, 火焰, 燃烧, 高温, 温度',
            enUS: 'heat, Flame, combustion, high temperature',
            zhSpell: 're, huoyan, ranshao, gaowen, wendu',
          },
          group: 'nature',
        },
        {
          iconCode: 60958,
          searchKey: {
            zhCN: '热, 火焰, 燃烧, 高温, 温度',
            enUS: 'heat, Flame, combustion, high temperature',
            zhSpell: 're, huoyan, ranshao, gaowen, wendu',
          },
          group: 'nature',
        },
        {
          iconCode: 60962,
          searchKey: {
            zhCN: '山丘, 爬山, 旅游, 景点',
            enUS: 'Hills, mountain climbing, tourism, attractions',
            zhSpell: 'shanqiu ,pashan, lvyou, jingdian',
          },
          group: 'nature',
        },
        {
          iconCode: 60989,
          searchKey: {
            zhCN: '闪电, 光, 天气, 大自然, 打雷, 能量, 快速',
            enUS: 'Lightning, light, weather, nature, thunder, energy, speed',
            zhSpell: 'shandian, guang, tianqi, daziran, dalei, nengliang, kuaisu',
          },
          group: 'nature',
        },
        {
          iconCode: 60988,
          searchKey: {
            zhCN: '闪电, 光, 天气, 大自然, 打雷, 能量, 快速',
            enUS: 'Lightning, light, weather, nature, thunder, energy, speed',
            zhSpell: 'shandian, guang, tianqi, daziran, dalei, nengliang, kuaisu',
          },
          group: 'nature',
        },
        {
          iconCode: 60960,
          searchKey: {
            zhCN: '闪光灯, 关闭, 照相, 相机, 照片',
            enUS: 'Flash, off, camera, photo',
            zhSpell: 'shanguangdeng, guanbi, zhaoxiang, xiangji, zhaopian',
          },
          group: 'nature',
        },
        {
          iconCode: 60967,
          searchKey: {
            zhCN: '闪光灯, 开启, 照相, 相机, 照片',
            enUS: 'Flash, turn on, take photos, camera, photo',
            zhSpell: 'shanguangdeng, kaiqi, zhaoxiang, xiangji, zhaopian',
          },
          group: 'nature',
        },
        {
          iconCode: 60953,
          searchKey: {
            zhCN: '闪光灯, 自动, 照相, 相机, 照片',
            enUS: 'Flash, automatic, camera, photo',
            zhSpell: 'shanguangdeng, zidong, zhaoxiang, xiangji, zhaopian',
          },
          group: 'nature',
        },
        {
          iconCode: 60970,
          searchKey: {
            zhCN: '可缩放矢量图形, 文件类型',
            enUS: 'SVG, vectors, graphics, files, file types',
            zhSpell: 'kesuofangshiliangtuxing, wenjianleixing',
          },
          group: 'nature',
        },
        {
          iconCode: 60969,
          searchKey: {
            zhCN: '树木, 植物, 森林, 公园, 松树, 木头, 木材, 伐木场',
            enUS: 'Trees, plants, forests, parks, pine trees, wood, timber, logging yards',
            zhSpell: 'shumu, zhiwu, senlin, gongyuan, songshu, mutou, mucai, famuchang',
          },
          group: 'nature',
        },
        {
          iconCode: 60978,
          searchKey: {
            zhCN: '树木, 植物, 森林, 公园, 松树, 木头, 木材, 伐木场',
            enUS: 'Trees, plants, forests, parks, pine trees, wood, timber, logging yards',
            zhSpell: 'shumu, zhiwu, senlin, gongyuan, songshu, mutou, mucai, famuchang',
          },
          group: 'nature',
        },
        {
          iconCode: 60979,
          searchKey: {
            zhCN: '树叶, 植物, 绿色, 有机,环保',
            enUS: 'Leaves, plants, green, organic, environmentally friendly',
            zhSpell: 'shuye, zhiwu, lvse, youji, huanbao',
          },
          group: 'nature',
        },
        {
          iconCode: 60966,
          searchKey: {
            zhCN: '数据, 云, 圆形, 同步, 服务器',
            enUS: 'Data, Cloud, Circle, Sync, Server',
            zhSpell: 'shuju, yun, yuanxing, tongbu, fuwuqi',
          },
          group: 'nature',
        },
        {
          iconCode: 60975,
          searchKey: {
            zhCN: '太阳, 阳光, 日光, 天气, 晴天, 天气, 户外, 光线, 白天, 温暖',
            enUS: 'Sun, sunshine, sunlight, weather, sunny day, outdoor, light, day, warmth',
            zhSpell: 'taiyang, yangguang, riguang, tianqi, qingtian, tianqi, huwai, guangxian, baitian, wennuan',
          },
          group: 'nature',
        },
        {
          iconCode: 60971,
          searchKey: {
            zhCN: '太阳, 阳光, 日光, 天气, 晴天, 天气, 户外, 光线, 白天, 温暖',
            enUS: 'Sun, sunshine, sunlight, weather, sunny day, outdoor, light, day, warmth',
            zhSpell: 'taiyang, yangguang, riguang, tianqi, qingtian, tianqi, huwai, guangxian, baitian, wennuan',
          },
          group: 'nature',
        },
        {
          iconCode: 60968,
          searchKey: {
            zhCN: '太阳, 阳光, 日光, 天气, 晴天, 天气, 户外, 光线, 白天, 温暖',
            enUS: 'Sun, sunshine, sunlight, weather, sunny day, outdoor, light, day, warmth',
            zhSpell: 'taiyang, yangguang, riguang, tianqi, qingtian, tianqi, huwai, guangxian, baitian, wennuan',
          },
          group: 'nature',
        },
        {
          iconCode: 60990,
          searchKey: {
            zhCN: '卫星, 图像, 山脉, 圆形',
            enUS: 'Satellite, image, mountain range, circular',
            zhSpell: 'weixing, tuxiang, shanmai, yuanxing',
          },
          group: 'nature',
        },
        {
          iconCode: 60987,
          searchKey: {
            zhCN: '下雪, 天气, 冬天',
            enUS: 'Snow, weather, winter',
            zhSpell: 'xiaxue, tianqi, dongtian',
          },
          group: 'nature',
        },
        {
          iconCode: 60977,
          searchKey: {
            zhCN: '星球, 环球, 空间, 宇宙',
            enUS: 'Planet, Globe, Space, Universe',
            zhSpell: 'xingqiu, huanqiu, kongjian, yuzhou',
          },
          group: 'nature',
        },
        {
          iconCode: 60993,
          searchKey: {
            zhCN: '叶子, 树木, 秋天, 树叶, 生态, 环境, 绿色, 环保',
            enUS: 'Leaves, trees, autumn, ecology, environment, green, environmental protection',
            zhSpell: 'yezi, shumu, qiutian, shuye, shengtai, huanjing, lvse, huanbao',
          },
          group: 'nature',
        },
        {
          iconCode: 60984,
          searchKey: {
            zhCN: '叶子, 树木, 秋天, 树叶, 生态, 环境, 绿色, 环保',
            enUS: 'Leaves, trees, autumn, ecology, environment, green, environmental protection',
            zhSpell: 'yezi, shumu, qiutian, shuye, shengtai, huanjing, lvse, huanbao',
          },
          group: 'nature',
        },
        {
          iconCode: 60981,
          searchKey: {
            zhCN: '夜晚, 多云, 月亮, 夜间',
            enUS: 'Night, cloudy, moon',
            zhSpell: 'yewan, duoyun, yueliang, yejian',
          },
          group: 'nature',
        },
        {
          iconCode: 60992,
          searchKey: {
            zhCN: '夜晚, 多云, 月亮, 夜间',
            enUS: 'Night, cloudy, moon',
            zhSpell: 'yewan, duoyun, yueliang, yejian',
          },
          group: 'nature',
        },
        {
          iconCode: 60995,
          searchKey: {
            zhCN: '月亮, 夜间, 夜晚, 月光, 天空',
            enUS: 'Moon, night, moonlight, sky',
            zhSpell: 'yueliang, yejian, yewan, yueguang, tiankong',
          },
          group: 'nature',
        },
        {
          iconCode: 60972,
          searchKey: {
            zhCN: '月亮, 夜间, 夜晚, 月光, 天空',
            enUS: 'Moon, night, moonlight, sky',
            zhSpell: 'yueliang, yejian, yewan, yueguang, tiankong',
          },
          group: 'nature',
        },
        {
          iconCode: 60996,
          searchKey: {
            zhCN: '月亮, 夜间, 夜晚, 月光, 天空',
            enUS: 'Moon, night, moonlight, sky',
            zhSpell: 'yueliang, yejian, yewan, yueguang, tiankong',
          },
          group: 'nature',
        },
        {
          iconCode: 60983,
          searchKey: {
            zhCN: '云计算, 云数据, 云存储, 天气预报, 多云, 备份, 驱动, 天气, 气象, 平台',
            enUS: 'Cloud computing, cloud data, cloud storage, weather forecast, cloudy, backup, drive,  platform',
            zhSpell: 'yunjisuan, yunshuju, yuncunchu, tianqiyubao, duoyun, beifen, qudong, tianqi, qixiang, pingtai',
          },
          group: 'nature',
        },
        {
          iconCode: 60997,
          searchKey: {
            zhCN: '云计算, 云数据, 云存储, 天气预报, 多云, 备份, 驱动, 天气, 气象, 平台',
            enUS: 'Cloud computing, cloud data, cloud storage, weather forecast, cloudy, backup, drive,  platform',
            zhSpell: 'yunjisuan, yunshuju, yuncunchu, tianqiyubao, duoyun, beifen, qudong, tianqi, qixiang, pingtai',
          },
          group: 'nature',
        },
        {
          iconCode: 60985,
          searchKey: {
            zhCN: '云计算, 云数据, 云存储, 天气预报, 多云, 备份, 驱动, 天气, 气象, 平台',
            enUS: 'Cloud computing, cloud data, cloud storage, weather forecast, cloudy, backup, drive,  platform',
            zhSpell: 'yunjisuan, yunshuju, yuncunchu, tianqiyubao, duoyun, beifen, qudong, tianqi, qixiang, pingtai',
          },
          group: 'nature',
        },
        {
          iconCode: 60982,
          searchKey: {
            zhCN: '云计算, 云数据, 云存储, 天气预报, 多云, 备份, 驱动, 天气, 气象, 平台',
            enUS: 'Cloud computing, cloud data, cloud storage, weather forecast, cloudy, backup, drive,  platform',
            zhSpell: 'yunjisuan, yunshuju, yuncunchu, tianqiyubao, duoyun, beifen, qudong, tianqi, qixiang, pingtai',
          },
          group: 'nature',
        },
        {
          iconCode: 60991,
          searchKey: {
            zhCN: '云计算, 云数据, 云存储, 天气预报, 多云, 备份, 驱动, 天气, 气象, 平台',
            enUS: 'Cloud computing, cloud data, cloud storage, weather forecast, cloudy, backup, drive,  platform',
            zhSpell: 'yunjisuan, yunshuju, yuncunchu, tianqiyubao, duoyun, beifen, qudong, tianqi, qixiang, pingtai',
          },
          group: 'nature',
        },
        {
          iconCode: 60998,
          searchKey: {
            zhCN: '云计算, 云数据, 云存储, 天气预报, 多云, 备份, 驱动, 天气, 气象, 平台',
            enUS: 'Cloud computing, cloud data, cloud storage, weather forecast, cloudy, backup, drive,  platform',
            zhSpell: 'yunjisuan, yunshuju, yuncunchu, tianqiyubao, duoyun, beifen, qudong, tianqi, qixiang, pingtai',
          },
          group: 'nature',
        },
        {
          iconCode: 60986,
          searchKey: {
            zhCN: '云计算, 云数据, 云存储, 天气预报, 多云, 备份, 驱动, 天气, 气象, 平台',
            enUS: 'Cloud computing, cloud data, cloud storage, weather forecast, cloudy, backup, drive,  platform',
            zhSpell: 'yunjisuan, yunshuju, yuncunchu, tianqiyubao, duoyun, beifen, qudong, tianqi, qixiang, pingtai',
          },
          group: 'nature',
        },
        {
          iconCode: 60994,
          searchKey: {
            zhCN: '云计算, 云数据, 云存储, 天气预报, 多云, 备份, 驱动, 天气, 气象, 平台',
            enUS: 'Cloud computing, cloud data, cloud storage, weather forecast, cloudy, backup, drive,  platform',
            zhSpell: 'yunjisuan, yunshuju, yuncunchu, tianqiyubao, duoyun, beifen, qudong, tianqi, qixiang, pingtai',
          },
          group: 'nature',
        },
      ],
    },
    /*
    {
      'name': i18n('resource.icons.treatment'),
      'id': 'treatment',
      'items': [
        {
          'iconCode': 59209,
          'searchKey': {
            'zhCN': '医院,诊所,药店,养老院,治疗,住院,疗养院,医疗机构',
            'enUS': 'Hospital,clinic,pharmacy,nursing home,treatment,hospitalization,medical institution',
            'zhSpell': 'yiyuan,zhensuo,yaodian,yanglaoyuan,zhiliao,zhuyuan,liaoyangyuan,yiliaojigou',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59210,
          'searchKey': {
            'zhCN': '病历,体检报告,清单,药店,看病,住院,生病,医疗',
            'enUS': 'Medical records,medical report,checklist,pharmacy,medical treatment,hospitalization,illness',
            'zhSpell': 'bingli,tijianbaogao,qingdan,yaodian,kanbing,zhuyuan,shengbing,yiliao',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59211,
          'searchKey': {
            'zhCN': '胶囊,药物,西药,治疗,恢复,医药,药丸,生病,医疗',
            'enUS': 'Capsule,drug,western medicine,treatment,recovery,medicine,pill,ill,medical',
            'zhSpell': 'jiaonan,yaowu,xiyao,zhiliao,huifu,yiyao,yaowan,shengbing,yiliao',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59212,
          'searchKey': {
            'zhCN': '打吊瓶,静脉输液,输水,打吊针,打点滴,生病,医疗',
            'enUS': 'Hanging bottle,intravenous infusion,water delivery,hanging needle,drip,sick,medical',
            'zhSpell': 'dadiaoping,jingmaishuye,shushui,dadiaozhen,dadiandi,shengbing,yiliao',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59213,
          'searchKey': {
            'zhCN': '流行病毒,病菌,细菌,瘟疫,传染病,流感,新冠肺炎,瘟疫,感染',
            'enUS': 'Epidemic viruses,germs,bacteria,plague,infectious diseases,influenza,neopneumonia,infection',
            'zhSpell': 'liuxingbingdu,bingjun,xijun,wenyi,chuanranbing,liugan,xinguanfeiyan,wenyi,ganran',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59214,
          'searchKey': {
            'zhCN': '鼻子,鼻涕,五官科,鼻科,鼻腔,嗅觉,味道,气味',
            'enUS': 'Nose,snot,facial features,rhinology,nasal cavity,smell,taste',
            'zhSpell': 'bizi,biti,wuguanke,bike,biqiang,xiujue,weidao,qiwei',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59215,
          'searchKey': {
            'zhCN': '耳朵,耳科,听觉,听力,声音,五官科',
            'enUS': 'Ear,otology,hearing,sound,facial features',
            'zhSpell': 'erduo,erke,tingjue,tingli,shengyin,wuguanke',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59216,
          'searchKey': {
            'zhCN': '骷髅,死亡,病毒,尸体,人头,瘟疫,致命',
            'enUS': 'Skeleton,death,virus,corpse,human head,plague,deadly',
            'zhSpell': 'kulou,siwang,bingdu,shiti,rentou,wenyi,zhiming',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59217,
          'searchKey': {
            'zhCN': '试管,化验,验血,体检,量瓶,化学,实验,剂量',
            'enUS': 'Test tube,laboratory test,blood test,medical examination,measuring bottle,chemistry,experiment,dose',
            'zhSpell': 'shiguan,huayan,yanxue,tijian,liangping,huaxue,shiyan,jiliang',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59218,
          'searchKey': {
            'zhCN': '烧瓶,化学,实验,加热,医疗,检验',
            'enUS': 'Flask,chemistry,experiment,heating,medical,inspection',
            'zhSpell': 'shaoping,huaxue,shiyan,jiare,yiliao,jianyan',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59219,
          'searchKey': {
            'zhCN': '辐射,放射科,放射性物质,实验,核物质,化学',
            'enUS': 'Radiation,radiology,radioactive material,experiment,nuclear material,chemistry',
            'zhSpell': 'fushe,fangsheke,fangshexingwuzhi,shiyan,hewuzhi,huaxue',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59220,
          'searchKey': {
            'zhCN': '轮椅,残疾人,医疗器具,无障碍设施',
            'enUS': 'Wheelchairs,disabled people,medical equipment,barrier-free facilities',
            'zhSpell': 'lunyi,canjiren,yiliaoqiju,wuzhangaisheshi',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59221,
          'searchKey': {
            'zhCN': '洗手液,消毒液,沐浴露,洗发水,香波,个人卫生',
            'enUS': 'Hand soap,disinfectant,shower gel,shampoo,personal hygiene',
            'zhSpell': 'xishouye,xiaoduye,muyulu,xifashui,xiangbo,gerenweisheng',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59222,
          'searchKey': {
            'zhCN': '嘴巴,嘴唇,亲吻,口部,口红,口腔,声音',
            'enUS': 'Mouth,lips,kiss,lipstick,voice',
            'zhSpell': 'zuiba,zuichun,qinwen,koubu,kouhong,kouqiang,shengyin',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59223,
          'searchKey': {
            'zhCN': '温度计,体温,量体温,发热门诊,发烧,感冒',
            'enUS': 'Thermometer,body temperature,temperature measurement,fever clinic,fever,cold',
            'zhSpell': 'wenduji,tiwen,liangtiwen,faremenzhen,fashao,ganmao',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59224,
          'searchKey': {
            'zhCN': '温度,温度计,气温,湿度,湿度计,体温,发烧,感冒',
            'enUS': 'Temperature,thermometer,air temperature,humidity,hygrometer,body temperature,fever,cold',
            'zhSpell': 'wendu,wenduji,qiwen,shidu,shiduji,tiwen,fashao,ganmao',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59225,
          'searchKey': {
            'zhCN': '眼睛,眼科,视觉,视力,可视性,可见,瞳孔,眼药水',
            'enUS': 'Eye,ophthalmology,vision,visibility,visible,pupil,eye drops',
            'zhSpell': 'yanjing,yanke,shijue,shili,keshixing,kejian,tongkong,yanyaoshui',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59226,
          'searchKey': {
            'zhCN': '救护车,车辆,急救,120,911,医疗队,救援',
            'enUS': 'Ambulance,vehicle,first aid,120,911,medical team,rescue',
            'zhSpell': 'jiuhuche,cheliang,jijiu,120,911,yiliaodui,jiuyuan',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59227,
          'searchKey': {
            'zhCN': '心电图,心率,频率,心跳,ECG,医疗',
            'enUS': 'Ecg,heart rate,frequency,heartbeat,medical',
            'zhSpell': 'xindiantu,xinlv,pinlv,xintiao,ECG,yiliao',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59228,
          'searchKey': {
            'zhCN': '显微镜,微生物,细菌,生物学,细胞',
            'enUS': 'Microscope,microorganism,bacteria,biology,cell',
            'zhSpell': 'xianweijing,weishengwu,xijun,shengwuxue,xibao',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59229,
          'searchKey': {
            'zhCN': '胶囊,药物,西药,治疗,恢复,医药,药丸,医疗用品,生病',
            'enUS': 'Capsule,drug,western medicine,treatment,recovery,medicine,pill,medical supplies,ill',
            'zhSpell': 'jiaonan,yaowu,xiyao,zhiliao,huifu,yiyao,yaowan,yiliaoyongpin,shengbing',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59230,
          'searchKey': {
            'zhCN': '药瓶,保健品,药品,药物,西药,医疗用品,瓶子',
            'enUS': 'Medicine bottles,health products,medicines,drugs,western medicine,medical supplies,bottles',
            'zhSpell': 'yaoping,baojianpin,yaopin,yaowu,xiyao,yiliaoyongpin,pingzi',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59231,
          'searchKey': {
            'zhCN': '饮用水,水滴,血液,液体,点滴,水源',
            'enUS': 'Drinking water,water droplets,blood,liquid,drip,water source',
            'zhSpell': 'yinyongshui,shuidi,xueye,yeti,diandi,shuiyuan',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59232,
          'searchKey': {
            'zhCN': '医疗箱,医药箱,急救箱,药品,箱子,生病,医疗包',
            'enUS': 'Medical kit,medicine kit,first aid kit,medicine,box,sick',
            'zhSpell': 'yiliaoxiang,yiyaoxiang,jijiuxiang,yaopin,xiangzi,shengbing,yiliaobao',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59233,
          'searchKey': {
            'zhCN': '医用剪刀,手术刀,缝合,剪开,开刀,做手术',
            'enUS': 'Medical scissors,scalpel,suturing,cutting,opening,surgery',
            'zhSpell': 'yiyongjiandao,shoushudao,fenghe,jiankai,kaidao,zuoshoushu',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59234,
          'searchKey': {
            'zhCN': '流行病毒,病菌,细菌,瘟疫,传染病,流感,瘟疫,感染,生病',
            'enUS': 'Epidemic viruses,germs,bacteria,plague,infectious diseases,influenza,infection,sickness',
            'zhSpell': 'liuxingbingdu,bingjun,xijun,wenyi,chuanranbing,liugan,wenyi,ganran,shengbing',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59235,
          'searchKey': {
            'zhCN': '医院,诊所,医疗机构,疗养院,康复,治疗,医疗',
            'enUS': 'Hospital,clinic,medical institution,nursing home,rehabilitation,treatment,medical',
            'zhSpell': 'yiyuan,zhensuo,yiliaojigou,liaoyangyuan,kangfu,zhiliao,yiliao',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59236,
          'searchKey': {
            'zhCN': '医疗,医疗器具,听诊器,心跳,诊断,看病',
            'enUS': 'Medical,medical equipment,stethoscope,heartbeat,diagnosis,medical treatment',
            'zhSpell': 'yiliao,yiliaoqiju,tingzhenqi,xintiao,zhenduan,kanbing',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59237,
          'searchKey': {
            'zhCN': '量杯,医疗,体检,化验,烧杯,化学,实验',
            'enUS': 'Measuring cup,medical,medical examination,laboratory test,beaker,chemistry,experiment',
            'zhSpell': 'liangbei,yiliao,tijian,huayan,shaobei,huaxue,shiyan',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59238,
          'searchKey': {
            'zhCN': '医疗器具,针管,针筒,打针,注射器,看病,治疗',
            'enUS': 'Medical appliances,needles,syringes,injections,medical treatment,treatment',
            'zhSpell': 'yiliaoqiju,zhenguan,zhentong,dazhen,zhusheqi,kanbing,zhiliao',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59239,
          'searchKey': {
            'zhCN': 'DNA,基因,生物学,遗传因子,遗传物质,染色体',
            'enUS': 'Dna,genes,biology,genetic factors,genetic material,chromosomes',
            'zhSpell': 'DNA,jiyin,shengwuxue,yichuanyinzi,yichuanwuzhi,ranseti',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59240,
          'searchKey': {
            'zhCN': '医生,医疗,医护人员,看病,生病,治疗,健康',
            'enUS': 'Doctor,medical,medical staff,see a doctor,get sick,treatment,health',
            'zhSpell': 'yisheng,yiliao,yihurenyuan,kanbing,shengbing,zhiliao,jiankang',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59241,
          'searchKey': {
            'zhCN': '研磨器,研磨碗,餐饮,饮食,餐具,吃饭',
            'enUS': 'Grinder,grinding bowl,catering,diet,tableware,meal',
            'zhSpell': 'yanmoqi,yanmowan,canyin,yinshi,canju,chifan',
          },
          'group': 'treatment',
        },
        {
          'iconCode': 59242,
          'searchKey': {
            'zhCN': '急救,救护车,紧急情况,红灯,警报器,危险',
            'enUS': 'First aid,ambulance,emergency,red light,siren,danger',
            'zhSpell': 'jijiu,jiuhuche,jinjiqingkuang,hongdeng,jingbaoqi,weixian',
          },
          'group': 'treatment',
        },
      ],
    },
    */
  ],
};

export default lightIconFont;
