import * as React from 'react';
import EditorContext from '@contexts/editor';
import { IValueEditorPanel } from '@/customTypes';
import { IPopupComponent } from '@/dsm/withAutoClose';

export interface InjectedUpdatePreventCloseProps {
  onMouseDown: () => void;
  onMouseUp: () => void;
}

export default function updatePreventClose<P>(
  WrappedComponent: React.ComponentClass<P & InjectedUpdatePreventCloseProps>,
) {
  return class C extends React.Component<P> implements IValueEditorPanel {
    static contextType = EditorContext;
    private timeID?: Timeout = undefined;

    componentDidMount() {
      this.context.uiManager.listItemValueEditorPanel = this;
    }

    componentWillUnmount() {
      this.context.uiManager.listItemValueEditorPanel = undefined;
      clearTimeout(this.timeID);
    }

    get preventClose(): boolean {
      if (this.panel.current) {
        return !!(this.panel.current as IPopupComponent).preventClose;
      }
      return false;
    }

    set preventClose(value: boolean) {
      if (this.panel.current) {
        (this.panel.current as IPopupComponent).preventClose = value;
      }
    }

    handleMouseDown = () => {
      clearTimeout(this.timeID);
      if (this.panel.current) {
        (this.panel.current as IPopupComponent).preventClose = true;
      }
    };

    handleMouseUp = () => {
      this.timeID = window.setTimeout(() => {
        if (this.panel.current) {
          (this.panel.current as IPopupComponent).preventClose = false;
        }
      }, 120);
    };

    // @ts-ignore
    panel: React.RefObject<PanelClass> = React.createRef();

    render() {
      return (
        <WrappedComponent
          ref={this.panel}
          {...this.props}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
        />
      );
    }
  };
}
