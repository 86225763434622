import { cloneDeep, isEmpty, isEqual, partial, fromPairs } from 'lodash';
import { generate } from 'shortid';

import i18n from '@/i18n';

import { Matrix } from '@utils/matrixUtils';
import { depthClone, min, round, sameNumber, roundNumberObject } from '@utils/globalUtils';
import * as BoundsUtils from '@utils/boundsUtils';
import { getRoundedPositionOfPoint } from '@utils/boundsUtils';

import { MoveDelta } from '@/fbs/common/models/resize';
import { IBoundsOffset, IPoint, IPosition, ISize } from '@fbs/common/models/common';
import { IComponentData, ETextBehaviour } from '@fbs/rp/models/component';
import { HorizontalAlign, ILayout, VerticalAlign } from '@fbs/rp/models/layout';
import { IComponentAction } from '@fbs/rp/models/interactions';
import ITextFormat, { FontStyle } from '@fbs/rp/models/properties/text';
import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { IConnectorLineValue } from '@fbs/rp/models/value';
import IRadius from '@fbs/rp/models/properties/radius';
import IPadding from '@fbs/rp/models/properties/padding';
import { FillPropertyName, FillType, default as IFill } from '@fbs/rp/models/properties/fill';
import { TextFormatExPropertyName } from '@fbs/rp/models/properties/textFormat';
import { default as IStroke, StrokePropertyName } from '@fbs/rp/models/properties/stroke';
import { IconPropertyName } from '@fbs/rp/models/properties/icon';
import { PropertyValue } from '@fbs/rp/models/property';
import IArtboard from '@fbs/rp/models/artboard';

import { CompTextStyleNameConfig } from '@consts/component';
import SelectionFrameType from '@consts/enums/selectionBoxType';
import { DeepBlueColor, WhiteColor } from '@consts/colors';

import {
  UIComponent,
  UIContainerComponent,
  UIContentPanelComponent,
  UIContentPanelV2Component,
  UIGroupComponent,
} from '@editor/comps';
import RotatedComponent from '@editor/comps/base/RotatedComponent';
import ComponentBase from '@editor/comps/base/ComponentBase';
import { ArtboardPatchesClass } from '@editor/patches/artboardPatches';
import Doc from '@editor/document';

import {
  CArtboard,
  CConnector,
  CInput,
  CPureText,
  CText,
  CBreadCrumbs,
  CSelect,
  CMultipleSelect,
  CImage,
  CContentPanelV2,
} from '@libs/constants';
import { getLibData } from '@libs/libs';
import { SizeMode } from '@libs/enum';
// TODO **** 这部分引用不可提前, 能编译过，但在运行时会产生引用问题 ****
import {
  clearCompDynamicInfo,
  ComponentChangeType,
  DynamicInfoMap,
  extractDynamicInfoFromPatch,
} from '@editor/comps/resizeHelper';

import { getNewID } from './idHelper';
import { FontStyleHelper } from './fontStyleHelper';
import * as CompSpaceHelper from './compSpaceHelper';
import { IFlipModel, updatePatchesByWhenFlip } from './flipHelper';
import { getCenter, getNWPoint } from './rotateHelper';

export interface ICloneComponentDataOptions {
  componentsData: IComponentData[];
  parent: UIContainerComponent;
  delta: MoveDelta;
  refArtboardsData?: IArtboard[];
  // 默认应该是 true
  enableSort?: boolean;
}

/**
 * 获取选择框的四个顶点
 * @param {Set<UIComponent>} components
 * @returns {IPoint[] | null}
 */
export function getSelectionBoxPoints(components: Set<UIComponent>): IPoint[] | null {
  if (components.size === 0) {
    return null;
  }

  if (components.size === 1) {
    const first = components.values().next().value;
    return (first as RotatedComponent).getBoxPointsInArtboard(false);
  }

  let minX = Number.POSITIVE_INFINITY;
  let minY = Number.POSITIVE_INFINITY;
  let maxX = Number.NEGATIVE_INFINITY;
  let maxY = Number.NEGATIVE_INFINITY;
  components.forEach((com) => {
    const points = com.getBoxPointsInArtboard(false);
    points.forEach((point) => {
      if (point.x < minX) minX = point.x;
      if (point.x > maxX) maxX = point.x;
      if (point.y < minY) minY = point.y;
      if (point.y > maxY) maxY = point.y;
    });
  });

  return [
    { x: minX, y: minY },
    { x: maxX, y: minY },
    { x: maxX, y: maxY },
    { x: minX, y: maxY },
  ];
}

interface IIDMap {
  [oldID: string]: string;
}

/**
 * 重置组件ID，并返回一个组件旧ID与新ID的映射表
 * @param {IComponentData[]} components
 * @returns IIDMap
 */
export function resetID(components: IComponentData[]): IIDMap {
  const result: IIDMap = {};
  components.forEach((comp) => {
    // 画板ID的生成方式与后端保持一致
    const newID = comp.type === CArtboard ? generate() : getNewID();
    result[comp._id] = newID;
    comp._id = newID;
    if (comp.components) {
      const childIDMap = resetID(comp.components);
      for (let key in childIDMap) {
        result[key] = childIDMap[key];
      }
    }
  });
  return result;
}

export function resetValueMap(comps: IComponentData[], idMap: IIDMap) {
  // FIXME 下面这段代码之后要调整，在这个方法里，它是一个特殊的存在，不具有普遍性
  comps.forEach((compData) => {
    const lib = getLibData(compData.lib?.type || compData.type);
    if (lib?.editor?.onClone) {
      lib.editor.onClone(compData, idMap);
    }
    compData.components?.length && resetValueMap(compData.components, idMap);
  });
}

export function resetRoundPositionAndSize(comps: IComponentData[]) {
  comps.forEach((compData) => {
    compData.position = roundNumberObject(compData.position);
    compData.size = roundNumberObject(compData.size);
    compData.components?.length && resetRoundPositionAndSize(compData.components);
  });
}

export function resetRefArtboardOwnerID(refArtboards: IArtboard[], idMap: IIDMap) {
  refArtboards.forEach((refArtboard) => {
    const newOwnerID = idMap[refArtboard.ownerID ?? ''];
    if (newOwnerID) {
      refArtboard.ownerID = newOwnerID;
    }
  });
}

/**
 * 重置组件交互目标
 * @param {IComponentData[]} components
 * @param {IIDMap} idMap
 */
export function resetInteractionTargetID(components: IComponentData[], idMap: IIDMap) {
  const list: IComponentData[] = [];
  const flat = (comps: IComponentData[]) => {
    list.push(...comps);
    comps.forEach((comp) => {
      if (comp.components) {
        flat(comp.components);
      }
    });
  };
  flat(components);
  list.forEach((comp) => {
    const { interaction, type } = comp;
    if (CConnector === type) {
      const { startPoint, endPoint } = comp.value as IConnectorLineValue;
      if (startPoint.id) {
        const newID = idMap[startPoint.id];
        startPoint.id = newID || '';
      }
      if (endPoint.id) {
        const newID = idMap[endPoint.id];
        endPoint.id = newID || '';
      }
    }

    for (let event in interaction) {
      const handle = interaction[event];
      if (handle) {
        handle.actions.forEach((action) => {
          if (action.type === 'component' || action.type === 'fragment') {
            const currentAction = action as IComponentAction;

            const targetID = currentAction.target;
            const newTargetID = idMap[targetID];
            if (newTargetID) {
              currentAction.target = newTargetID;
            }

            if (currentAction.command === 'switchContent') {
              const switchContentTargetID = currentAction.params.target as string;
              const newSwitchContentTargetID = idMap[switchContentTargetID];
              if (newSwitchContentTargetID) {
                currentAction.params.target = newSwitchContentTargetID;
              }
            }
          }
        });
      }
    }
  });
}

/**
 * 重置组件数据：ID，交互目标，lib.editor.onClone 调用。
 * @param {IComponentData[]} components
 * @returns {IIDMap}
 */
export function resetComponentsData(components: IComponentData[]): IIDMap {
  const idMap = resetID(components);
  resetInteractionTargetID(components, idMap);
  resetRoundPositionAndSize(components);
  // lib.editor.onClone 调用
  // 这里面处理 cpv2的value与components
  resetValueMap(components, idMap);
  return idMap;
}

/**
 * 克隆组件的数据
 */
export function cloneComponentsData(options: ICloneComponentDataOptions): IComponentData[] {
  const { componentsData, parent, delta, enableSort = true } = options;
  const clonedData: IComponentData[] = depthClone(componentsData);

  // 因发现不同页面组件id相同的情况，所以跨页面粘贴组件的时候下面这种操作会出问题，
  if (enableSort) {
    const comps = parent.components;
    clonedData.sort((a: IComponentData, b: IComponentData) => {
      const aIndex = comps.findIndex((c) => c.id === a._id);
      const bIndex = comps.findIndex((c) => c.id === b._id);
      return aIndex - bIndex;
    });
  }

  // 为组件取一个好名字，命名方式： 组件名 - 副本 {编号}
  const baseNameObj: {
    [key: string]: number;
  } = {};
  // 为啥replace?: 编辑后返回的字符串中的空格与自定义的空格不相同，需要统一空格比较；
  const reg = new RegExp(`^([^-]+)\\s-\\s${i18n('workspace.artboard.copy')}\\s(\\d+)$`);
  parent.components.forEach((comp) => {
    if (!comp.name) {
      return;
    }

    const m = comp.name.match(reg);
    if (m) {
      const baseName = m[1].replace(/\s/g, ' ');
      const idx = Number(m[2]);
      baseNameObj[baseName] = (baseNameObj[baseName] || 0) > idx ? baseNameObj[baseName] || 0 : idx;
    }
  });

  clonedData.forEach((compData) => {
    compData.position.x += delta.x;
    compData.position.y += delta.y;

    compData._currentState = undefined;
    if (!compData.name) {
      return;
    }

    let baseName = compData.name.replace(/\s/g, ' ');
    let idx = 0;
    const m = compData.name.match(/^([^-]+)\s-\s(副本|copy)\s(\d+)$/);
    if (m) {
      baseName = m[1].replace(/\s/g, ' ');
      idx = Number(m[3]);
    }
    if (baseNameObj[baseName]) {
      idx = baseNameObj[baseName];
      baseNameObj[baseName]++;
    }

    compData.name = `${baseName} - ${i18n('workspace.artboard.copy')} ${idx + 1}`;
  });

  return clonedData;
}

/**
 * 角度转弧度
 * @param {number} angle
 * @returns {number}
 */
function degToRad(angle: number) {
  return angle * (Math.PI / 180);
}

type MatrixOperation = {
  size: { width: number; height: number };
  position: { x: number; y: number };
  rotate?: number;
  scale?: number;
  isRoot?: boolean;
};

/**
 * 计算一个组件的本地矩阵
 * @param size 组件尺寸
 * @param position 组件在父中的坐标
 * @param {number} rotate 组件旋转角度
 * @param {number} scale 缩放比例
 * @param {boolean} isRoot 是否根（如果是画板则为true，其余为false）
 * @returns {Matrix}
 *
 * @description 目前组件是相对于中心点旋转的，故该方法直接固定以中心点进行计算的，缩放比例也是以宽高相同比例处理的
 */
export function calculateLocalMatrix({ size, position, rotate, scale, isRoot }: MatrixOperation) {
  let localMatrix: Matrix;
  const rotateAngle = rotate || 0;
  const zoom = scale || 1;
  const x = position?.x || 0;
  const y = position?.y || 0;
  const width = size?.width || 0;
  const height = size?.height || 0;

  if (isRoot) {
    localMatrix = Matrix.identity;
    localMatrix.m31 = localMatrix.m32 = 0;
  } else {
    if (sameNumber(rotateAngle, 0) && sameNumber(zoom, 1)) {
      localMatrix = Matrix.identity;
      localMatrix.m31 = x;
      localMatrix.m32 = y;
    } else {
      if (!sameNumber(rotateAngle, 0)) {
        //scale
        const scaleMatrix = Matrix.identity;
        scaleMatrix.m11 = zoom;
        scaleMatrix.m22 = zoom;
        localMatrix = scaleMatrix.clone();
        // rotate
        const m1 = Matrix.identity;
        m1.m31 = -0.5 * width * zoom;
        m1.m32 = -0.5 * height * zoom;
        const m2 = Matrix.identity;
        m2.m31 = 0.5 * width * zoom;
        m2.m32 = 0.5 * height * zoom;
        const rotateMatrix = Matrix.createRotation(degToRad(rotateAngle));
        rotateMatrix.multiplyMatrix(m2);
        m1.multiplyMatrix(rotateMatrix);
        localMatrix.multiplyMatrix(m1);
        // translate
        const translateMatrix = Matrix.identity;
        translateMatrix.m31 = x;
        translateMatrix.m32 = y;
        localMatrix.multiplyMatrix(translateMatrix);
      } else {
        localMatrix = Matrix.identity;
        localMatrix.m31 = x;
        localMatrix.m32 = y;
        localMatrix.m11 = zoom;
        localMatrix.m22 = zoom;
      }
    }
  }
  return localMatrix;
}

/**
 * 根据组件data相对于父的占用区域
 * @param {size, position, rotate} comp
 * @returns {IBounds}
 */
export function getBoundsInParent({
  size,
  rotate,
  position,
}: {
  size: { height: number; width: number };
  rotate: number;
  position: { x: number; y: number };
}) {
  const localMatrix: Matrix = calculateLocalMatrix({ size, position, rotate });
  return localMatrix.multiplyRect({ left: 0, top: 0, right: size.width, bottom: size.height });
}

export function getCompLocalMatrix(comp: ComponentBase, scale: number = 1) {
  const { size, position, rotate } = comp;
  return calculateLocalMatrix({ size, position, rotate, scale, isRoot: (comp as UIComponent).isArtboard });
}

/**
 * 获取组件相对于画板的矩阵对象
 * @param {ComponentBase} comp
 * @param {number} scale
 * @returns {Matrix}
 */
export function getCompAbsoluteMatrix(comp: ComponentBase, scale: number = 1) {
  const { size, position, rotate } = comp as UIComponent;
  const operation: MatrixOperation = {
    scale,
    size,
    position,
    rotate,
    isRoot: (comp as UIComponent).isArtboard,
  };
  const localMatrix: Matrix = calculateLocalMatrix(operation);
  let absoluteMatrix: Matrix;
  if (!comp.parent) {
    absoluteMatrix = localMatrix;
  } else {
    const finalLocalMatrix = localMatrix.clone();
    finalLocalMatrix.multiplyMatrix(getCompAbsoluteMatrix(comp.parent));
    absoluteMatrix = finalLocalMatrix;
  }
  return absoluteMatrix;
}

export function getCompAbsoluteMatrixWithParentMatrix(
  comp: ComponentBase,
  parentAbsoluteMatrix: Matrix,
  scale = 1,
  useSelfSize?: boolean,
) {
  const { position, rotate } = comp as UIComponent;
  const size = useSelfSize ? (comp as UIGroupComponent).selfSize : comp.size;
  const operation: MatrixOperation = {
    scale,
    size,
    position,
    rotate,
    isRoot: (comp as UIComponent).isArtboard,
  };
  const localMatrix: Matrix = calculateLocalMatrix(operation);
  let absoluteMatrix: Matrix;
  if (!comp.parent) {
    absoluteMatrix = localMatrix;
  } else {
    const finalLocalMatrix = localMatrix.clone();
    finalLocalMatrix.multiplyMatrix(parentAbsoluteMatrix);
    absoluteMatrix = finalLocalMatrix;
  }
  return absoluteMatrix;
}

/**
 * 获取组件相对于画板的占用区域
 * @param {ComponentBase} comp
 * @returns {IBounds}
 */
export function getCompBoundsInArtboard(comp: ComponentBase, parentMatrix?: Matrix, useSelfSize?: boolean) {
  if (parentMatrix) {
    return getCompAbsoluteBounds(comp, parentMatrix, useSelfSize);
  }
  const size = useSelfSize ? (comp as UIGroupComponent).selfSize : comp.size;
  const matrix = getCompAbsoluteMatrix(comp);
  return matrix.multiplyRect({ left: 0, top: 0, right: size.width, bottom: size.height });
}

export function getCompAbsoluteBounds(comp: ComponentBase, parentMatrix: Matrix, useSelfSize?: boolean) {
  const size = useSelfSize ? (comp as UIGroupComponent).selfSize : comp.size;
  const matrix = getCompAbsoluteMatrixWithParentMatrix(comp, parentMatrix, 1, useSelfSize);
  return matrix.multiplyRect({ left: 0, top: 0, right: size.width, bottom: size.height });
}

/**
 * 获取多组件相对于画板的占用区域
 */
export function getCompsBoundsInArtboard(comps: ComponentBase[]) {
  if (!comps.length) {
    return BoundsUtils.empty();
  }
  const parentMatrix = getCompAbsoluteMatrix(comps[0].parent!);
  return BoundsUtils.union(...comps.map((comp) => getCompAbsoluteBounds(comp, parentMatrix)));
}

/**
 * 获取组件相对于页面的占用区域
 * @param {ComponentBase} comp
 * @returns {Bounds}
 */
export function getCompBoundsInPage(comp: ComponentBase, parentMatrix?: Matrix) {
  const artboard = (comp as UIComponent).getRootNode();
  const { x, y } = artboard.position;
  const bounds = getCompBoundsInArtboard(comp, parentMatrix);
  return BoundsUtils.offsetBounds(bounds, { left: x, top: y });
}

/**
 * 属性面板使用的layout数据
 * @export
 * @interface IEditLayout
 */
export interface IEditLayout {
  horizontal: HorizontalAlign | undefined;
  vertical: VerticalAlign | undefined;
  fixedWidth: boolean | undefined;
  fixedHeight: boolean | undefined;
  responsive: boolean | undefined;
  auto: boolean | undefined;
}

/**
 * 属性面板使用的layout数据对应的锚点设置
 * @export
 * @interface IEditAnchors
 */
export interface IEditAnchors {
  left: boolean | undefined;
  right: boolean | undefined;
  top: boolean | undefined;
  bottom: boolean | undefined;
  center: boolean | undefined;
  middle: boolean | undefined;
}

/**
 * 获取多个组件的总anchors和锚点设置（属性面板使用）
 * @param {UIComponent[]} comps
 * @returns {ILayout}
 */
export function getAnchorsList(comps: UIComponent[]): { layout: IEditLayout; anchors: IEditAnchors } {
  const keys = Object.keys(comps[0].layout) as Array<keyof ILayout>;

  const theSame: {
    [name: string]: boolean;
  } = {};

  keys.forEach((key) => {
    theSame[key] = !comps.some((comp) => {
      return comp.layout[key] !== (comps[0] && comps[0].layout[key]);
    });
  });

  const theSameAnchors = {
    left: true,
    top: true,
    bottom: true,
    right: true,
    center: false,
    middle: false,
  };

  const firstAnchors = getAnchors(comps[0].layout);

  (Object.keys(theSameAnchors) as Array<keyof IEditAnchors>).forEach((key) => {
    theSameAnchors[key] = !comps.some((comp) => {
      return getAnchors(comp.layout)[key] !== firstAnchors[key];
    });
  });

  return {
    layout: {
      responsive: theSame['responsive'] ? comps[0].layout.responsive : undefined,
      auto: theSame['auto'] ? comps[0].layout.auto : undefined,
      vertical: theSame['vertical'] ? comps[0].layout.vertical : undefined,
      horizontal: theSame['horizontal'] ? comps[0].layout.horizontal : undefined,
      fixedWidth: theSame['fixedWidth'] ? comps[0].layout.fixedWidth : undefined,
      fixedHeight: theSame['fixedHeight'] ? comps[0].layout.fixedHeight : undefined,
    },
    anchors: {
      left: theSameAnchors.left ? firstAnchors.left : undefined,
      right: theSameAnchors.right ? firstAnchors.right : undefined,
      top: theSameAnchors.top ? firstAnchors.top : undefined,
      bottom: theSameAnchors.bottom ? firstAnchors.bottom : undefined,
      center: theSameAnchors.center ? firstAnchors.center : undefined,
      middle: theSameAnchors.middle ? firstAnchors.middle : undefined,
    },
  };
}

export function getAnchors(layout: IEditLayout) {
  const anchors: IEditAnchors = {
    left: false,
    right: false,
    top: false,
    bottom: false,
    center: false,
    middle: false,
  };
  switch (layout.horizontal) {
    case HorizontalAlign.Left:
      anchors.left = true;
      break;
    case HorizontalAlign.Right:
      anchors.right = true;
      break;
    case HorizontalAlign.LeftAndRight:
      anchors.left = true;
      anchors.right = true;
      break;
    case HorizontalAlign.Center:
      anchors.center = true;
      break;
    default:
      break;
  }
  switch (layout.vertical) {
    case VerticalAlign.Top:
      anchors.top = true;
      break;
    case VerticalAlign.Bottom:
      anchors.bottom = true;
      break;
    case VerticalAlign.TopAndBottom:
      anchors.top = true;
      anchors.bottom = true;
      break;
    case VerticalAlign.Middle:
      anchors.middle = true;
      break;
    default:
      break;
  }
  return anchors;
}

export function getLayoutDirection(anchors: IEditAnchors) {
  const layoutDirection = { horizontal: HorizontalAlign.Auto, vertical: VerticalAlign.Auto };
  if (anchors.center && !anchors.left && !anchors.right) {
    layoutDirection.horizontal = HorizontalAlign.Center;
  } else if (anchors.left && anchors.right) {
    layoutDirection.horizontal = HorizontalAlign.LeftAndRight;
  } else if (anchors.left && !anchors.right) {
    layoutDirection.horizontal = HorizontalAlign.Left;
  } else if (!anchors.left && anchors.right) {
    layoutDirection.horizontal = HorizontalAlign.Right;
  } else if (!anchors.left && !anchors.right) {
    layoutDirection.horizontal = HorizontalAlign.Auto;
  }
  if (anchors.middle && !anchors.top && !anchors.bottom) {
    layoutDirection.vertical = VerticalAlign.Middle;
  } else if (anchors.top && anchors.bottom) {
    layoutDirection.vertical = VerticalAlign.TopAndBottom;
  } else if (anchors.top && !anchors.bottom) {
    layoutDirection.vertical = VerticalAlign.Top;
  } else if (!anchors.top && anchors.bottom) {
    layoutDirection.vertical = VerticalAlign.Bottom;
  } else if (!anchors.top && !anchors.bottom) {
    layoutDirection.vertical = VerticalAlign.Auto;
  }
  return layoutDirection;
}

/**将当前坐标系下的一个定点，转化到另外一个坐标系下面，两个坐标系可以通过平移+旋转变幻转化
 * 特别注意我们的坐标系和网上的标准坐标系是关于X轴对称的，所以网上的公式不能照搬，自己要推导一下
 * @param positionInCurrCoordinates 当前坐标系下的坐标
 * @param rotationOffsetAngle target坐标系顺时针旋转rotationOffsetAngle得到当前坐标系（不算平移的话）
 * @param offsetOfOriginCoordinate target坐标系通过offsetOfOriginCoordinate得到当前坐标系（不算旋转的话）
 */
export function mapPositionToTargetCoordinates(
  positionInCurrCoordinates: IPoint,
  rotationOffsetAngle: number,
  offsetOfOriginCoordinate: { x: number; y: number },
) {
  // x,y为父容器下的坐标，x',y'为当前组坐标系， a,b为组坐标系原点在组的父容器坐标系下的坐标
  // θ为当前组件 相对与画板的旋转角度
  // 下方的方程为两者转换的关系，要根据x,y 得到最终的 x',y'
  // x = x'*cos(θ) - y'*sin(θ) + a
  // y = y'*cos(θ) + x'*sin(θ) + b
  const cosine = Math.cos((rotationOffsetAngle / 180) * Math.PI);
  const sine = Math.sin((rotationOffsetAngle / 180) * Math.PI);
  return {
    x: positionInCurrCoordinates.x * cosine - positionInCurrCoordinates.y * sine + offsetOfOriginCoordinate.x,
    y: positionInCurrCoordinates.y * cosine + positionInCurrCoordinates.x * sine + offsetOfOriginCoordinate.y,
  };
}

/**
 * 当原坐标系为正， 目标坐标系为旋转时， 转换原坐标系的点到目标坐标系
 * @param newPointOfParent 在原坐标系中的点
 * @param rotationAngle 目标坐标系旋转的角度
 * @param originCoordinatesOfParent 目标坐标系的原点在原坐标系中的位置
 */
export function reverseMapPosition(newPointOfParent: IPoint, rotationAngle: number, originCoordinatesOfParent: IPoint) {
  // x,y为原坐标系的点，x',y'为目标坐标系的点， a,b为目标坐标系的原点在原坐标系中的位置
  // 下方的方程为两者转换的关系，要根据x,y 得到最终的 x',y'
  // x = x'*cos(θ) - y'*sin(θ) + a
  // y = y'*cos(θ) + x'*sin(θ) + b
  // 最后解得
  // x' = x*cos(θ) +y*sin(θ) - a*cos(θ) - b*sin(θ)
  // y' = y*cos(θ) - x*sin(θ) + a*sin(θ) -b*cos(θ)
  const cosine = Math.cos((rotationAngle / 180) * Math.PI);
  const sine = Math.sin((rotationAngle / 180) * Math.PI);

  return {
    x:
      newPointOfParent.x * cosine +
      newPointOfParent.y * sine -
      originCoordinatesOfParent.x * cosine -
      originCoordinatesOfParent.y * sine,
    y:
      newPointOfParent.y * cosine -
      newPointOfParent.x * sine +
      originCoordinatesOfParent.x * sine -
      originCoordinatesOfParent.y * cosine,
  };
}

/**
 * 将一个向量转化为以另一个坐标系中的向量
 * @param vector 当前坐标系A下的向量
 * @param rotationOffsetAngle 由当前坐标系——》目标坐标系的旋转角度，角度为逆时针
 * 例如 向量在主画板中，需要转换为组件坐标系中，那么旋转角度为  - comp.rotate
 *      向量以组件为坐标系，需要转换为画板坐标系中，那么旋转角度为 comp.rotate
 *
 */
export function mapVectorToTargetCoordinates(vector: { x: number; y: number }, rotationOffsetAngle: number) {
  const cosine = Math.cos((rotationOffsetAngle / 180) * Math.PI);
  const sine = Math.sin((rotationOffsetAngle / 180) * Math.PI);
  const { x, y } = vector;
  return {
    x: x * cosine - y * sine,
    y: y * cosine + x * sine,
  };
}

/**
 * 通过将点由画板坐标系转换到当前group
 * 是mapPositionToTargetCoordinates的逆运算
 * @param newPointsInArtBoard 点在画板中的坐标
 * @param parent 当前点所在的父级容器
 * @param parentCoordinatesInArtboard 当前点父容器在画板中的位置(为了避免重复计算)
 */
export function tansPointInArtBoardToGroup(
  newPointsInArtBoard: IPoint[],
  parent: UIComponent,
  parentCoordinatesInArtboard?: IPoint,
) {
  // x,y为画板系下的坐标，x',y'为当前组坐标系， a,b为组坐标系原点在画板坐标系下的坐标
  // θ为当前组件 相对与画板的旋转角度
  // 下方的方程为两者转换的关系，要根据x,y 得到最终的 x',y'
  // x = x'*cos(θ) - y'*sin(θ) + a
  // y = y'*cos(θ) + x'*sin(θ) + b
  // 最后解得
  // x' = x*cos(θ) +y*sin(θ) - a*cos(θ) - b*sin(θ)
  // y' = y*cos(θ) - x*sin(θ) + a*sin(θ) -b*cos(θ)
  let container = parent;
  let sumRotate = 0;

  const originCoordinatesOfParent = parentCoordinatesInArtboard ?? parent.getBoxPointsInArtboard()[0];
  while (container instanceof UIComponent && !container.isArtboard) {
    sumRotate += container.rotate;
    // @ts-ignore
    container = container.parent!;
  }
  const cosine = Math.cos((sumRotate / 180) * Math.PI);
  const sine = Math.sin((sumRotate / 180) * Math.PI);

  return newPointsInArtBoard.map((newPointInArtBoard) => {
    return {
      x:
        newPointInArtBoard.x * cosine +
        newPointInArtBoard.y * sine -
        originCoordinatesOfParent.x * cosine -
        originCoordinatesOfParent.y * sine,
      y:
        newPointInArtBoard.y * cosine -
        newPointInArtBoard.x * sine +
        originCoordinatesOfParent.x * sine -
        originCoordinatesOfParent.y * cosine,
    };
  });
}

/**
 * 得到两个值中不一样的字段，保留value1的值
 * @param value1
 * @param value2
 */
export function getChangedValue<T>(value1: T, value2: T): Partial<T> {
  type KeyType = keyof T;
  const result: Partial<T> = {};
  const keys = Object.keys(value1);
  for (const key of keys) {
    const keyInValue1 = key as KeyType;
    const v1 = value1[keyInValue1];
    const v2 = value2[keyInValue1];
    if (!isEqual(v1, v2)) {
      result[keyInValue1] = v1;
    }
  }
  return result as T;
}

/**
 * 当组件的整体文本样式更改，同步修改选中文本样式
 * 修改组件的默认文本颜色，不应影响选中文本颜色/链接文本颜色。
 * 除字色外，修改组件默认状态下的其他文本属性，都应同步修改选中状态下的相关属性
 * @param comp 组件
 * @param propertyName 修改的默认文本样式的名称
 * @param changedValue 修改的文本样式的值
 */
export function getCheckedTextStylePatchesWhenMainTextStyleChange(
  comp: UIComponent,
  propertyName: string,
  changedValue: ITextFormat,
) {
  const patches: ArtboardPatches = {
    do: {},
    undo: {},
  };
  if (CompTextStyleNameConfig[comp.lib?.type || comp.type].entireTextStyleName === propertyName) {
    //不同组件的选中文本样式的名称不一样
    const type = comp.lib?.type || comp.type;
    if (CompTextStyleNameConfig[type]) {
      let checkedTextStyleName = CompTextStyleNameConfig[type].checkedTextStyleName;
      // 面包屑组件hoverStyle样式与默认样式同步
      if (comp.libData?.type === CBreadCrumbs) {
        checkedTextStyleName = CompTextStyleNameConfig[type].hoverTextStyleName;
      }
      if (checkedTextStyleName) {
        const path = comp.getCurrentPropertiesPath(`properties/${checkedTextStyleName}`);
        const properties = comp.properties;
        const old = properties[checkedTextStyleName];
        // 修改组件的默认文本颜色，不应影响选中文本颜色/链接文本颜色
        Reflect.deleteProperty(changedValue, 'color');
        const value = { ...old, ...changedValue };
        patches.do[comp.id] = [Ops.replace(path, value)];
        patches.undo[comp.id] = [Ops.replace(path, properties[checkedTextStyleName])];
      }
    }
  }
  return patches;
}

export function getTextCompInSealed(comp: UIContainerComponent): UIComponent | null {
  let result = comp.components.find((comp) => [CPureText, CText, CInput].includes(comp.type)) || null;
  if (!result) {
    for (let i = 0; i < comp.components.length; i++) {
      if (comp.components[i] instanceof UIContainerComponent) {
        result = getTextCompInSealed((comp.components[i] as unknown) as UIContainerComponent);
        if (result) {
          break;
        }
      }
    }
  }
  return result;
}

export function getTextCompInListSealed(comp: UIContainerComponent): UIComponent[] {
  const comps: UIComponent[] = [];
  let container: UIContainerComponent | null = comp;
  if ([CSelect, CMultipleSelect].includes(comp.type)) {
    container = comp.getComponentByAlias('list', true) as UIContainerComponent;
  }
  if (!container) {
    return [];
  }

  const findTextComp = (comp: UIComponent): UIComponent | undefined => {
    if (comp.type === CPureText) {
      return comp;
    }
    if (comp.isContainer) {
      const comps = (comp as UIContainerComponent).components;
      for (let index = 0; index < comps.length; index++) {
        const element = comps[index];
        const textComp = findTextComp(element);
        if (textComp) {
          return textComp;
        }
      }
    }
    return undefined;
  };

  container.components.forEach((item) => {
    const textComp = findTextComp(item);
    if (textComp) {
      comps.push(textComp);
    }
  });

  return comps;
}

export function getImageCompInListSealed(comp: UIContainerComponent): UIComponent[] {
  const result: UIComponent[] = [];

  const findImage = (container: UIContainerComponent) => {
    container.components.forEach((item) => {
      if (item.type === CImage) {
        result.push(item);
      } else if (!item.isSymbol && item instanceof UIContainerComponent) {
        findImage(item);
      }
    });
  };
  findImage(comp);
  return result;
}

export function translateSizeMode(mode: SizeMode) {
  if (mode === SizeMode.horizontal) {
    return {
      canChangeHeight: false,
      canChangeWidth: true,
    };
  }
  if (mode === SizeMode.vertical) {
    return {
      canChangeHeight: true,
      canChangeWidth: false,
    };
  }
  if (mode === SizeMode.none) {
    return {
      canChangeHeight: false,
      canChangeWidth: false,
    };
  }
  return {
    canChangeHeight: true,
    canChangeWidth: true,
  };
}

export function transRadius(newRadius: IRadius, oldRadius: IRadius, size: ISize): IRadius {
  const { width, height } = size;
  const maxRadius = round(min(width, height) / 2);

  const { prop, name } = oldRadius;

  const calcPercentValue = (value: number) => {
    return min((value / maxRadius) * 100, 100);
  };

  const calcPixeValue = (value: number) => {
    return min((value / 100) * maxRadius, maxRadius);
  };

  const calcValue = (value: number, isPercent?: boolean) => {
    return isPercent ? calcPercentValue(value) : calcPixeValue(value);
  };

  if (newRadius.isPercent !== oldRadius.isPercent) {
    let { topLeft, topRight, bottomRight, bottomLeft } = oldRadius;
    if (topLeft) {
      topLeft = round(calcValue(topLeft, newRadius.isPercent));
    }
    if (topRight) {
      topRight = round(calcValue(topRight, newRadius.isPercent));
    }
    if (bottomRight) {
      bottomRight = round(calcValue(bottomRight, newRadius.isPercent));
    }
    if (bottomLeft) {
      bottomLeft = round(calcValue(bottomLeft, newRadius.isPercent));
    }
    return {
      prop,
      name,
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
      isPercent: newRadius.isPercent,
      disabled: newRadius.disabled,
    };
  } else {
    let { topLeft, topRight, bottomRight, bottomLeft, isPercent } = newRadius;
    if (topLeft) {
      topLeft = isPercent ? min(topLeft, 100) : min(maxRadius, topLeft);
    }
    if (topRight) {
      topRight = isPercent ? min(topRight, 100) : min(maxRadius, topRight);
    }
    if (bottomLeft) {
      bottomLeft = isPercent ? min(bottomLeft, 100) : min(maxRadius, bottomLeft);
    }
    if (bottomRight) {
      bottomRight = isPercent ? min(bottomRight, 100) : min(maxRadius, bottomRight);
    }
    return {
      prop,
      name,
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
      isPercent: newRadius.isPercent,
      disabled: newRadius.disabled,
    };
  }
}

export function setValueWithFontStyle(
  value: string,
  fontStyle: FontStyle | undefined,
  oldFontStyle: FontStyle | undefined,
) {
  const underline = fontStyle?.underline;
  const strike = fontStyle?.strike;
  const bold = fontStyle?.bold;
  const italic = fontStyle?.italic;
  const fontStyleHelper = new FontStyleHelper();
  const { value: newValue } = fontStyleHelper.changeTextFontStyle(
    value as string,
    [
      { type: 'underline', boolean: underline },
      { type: 'strike', boolean: strike },
      { type: 'bold', boolean: bold },
      { type: 'italic', boolean: italic },
    ],
    oldFontStyle,
  );
  return newValue;
}

/**
 * 根据条件查找最近的子
 */
export function findChildrenByFiltering(
  parent: UIComponent,
  filter: (comp: UIComponent) => boolean,
): UIComponent[] | undefined {
  if (!parent.isContainer) {
    return;
  }
  const result: UIComponent[] = [];
  const fn = (container: UIContainerComponent) => {
    const arr = container.components.filter(filter);
    if (arr.length) {
      result.push(...arr);
    } else {
      const connectors = container.components.filter((comp) => comp.isContainer);
      connectors.forEach((c) => {
        fn((c as unknown) as UIContainerComponent);
      });
    }
  };
  fn((parent as unknown) as UIContainerComponent);
  if (result.length) {
    return result;
  } else {
    return;
  }
}

/**
 * 根据条件查找最近的父
 */
export function findParentByFiltering(
  child: UIComponent,
  filter: (comp: UIComponent) => boolean,
): UIComponent | undefined {
  if (!child) {
    return;
  }
  let result: UIComponent | undefined = undefined;
  const fn = (child: UIComponent) => {
    if (!child.parent) {
      return;
    }
    // @ts-ignore
    if (child.parent && filter(child.parent)) {
      // @ts-ignore
      result = child.parent;
    } else {
      // @ts-ignore
      fn(child.parent);
    }
  };
  fn(child);
  return result;
}

export function extractComps(comps: UIComponent[]): UIComponent[] {
  const result: UIComponent[] = [...comps];

  comps.forEach((comp) => {
    if (comp instanceof UIContainerComponent) {
      result.push(...extractComps(comp.components));
    }
  });

  return result;
}

// 控制器每移动 x ,间距移动f(x), 倍数关系是 2/（2n+1）,n为控制器的index,
export function getGapByControlOffset(controlOffset: { x: number; y: number }, control: CompSpaceHelper.SpaceControl) {
  const { type, rowIndex, colIndex } = control;
  const controlIndex = type === 'horizontal' ? colIndex : rowIndex;
  const offset = type === 'horizontal' ? controlOffset.x : controlOffset.y;
  return offset * (2 / (2 * controlIndex! + 1));
}

export function allCompsSatisfy(comps: UIComponent[], satisfyFunc: (comp: UIComponent) => boolean) {
  return comps.reduce((acc, comp) => {
    acc = acc && satisfyFunc(comp);
    return acc;
  }, true);
}

export function getAdjustDiffWhenMove(
  moveInfo: { horizontal: boolean; vertical: boolean },
  diff: { x: number; y: number },
) {
  const { horizontal, vertical } = moveInfo;
  let newDiff = {
    x: diff.x,
    y: diff.y,
  };
  if (!horizontal) {
    newDiff.x = 0;
  }
  if (!vertical) {
    newDiff.y = 0;
  }
  return newDiff;
}

export function getBoundsOffsetBySizeChange(originSize: ISize, newSize: ISize): IBoundsOffset {
  const { width, height } = originSize;
  const { width: newWidth, height: newHeight } = newSize;
  const heightChange = newHeight - height;
  const widthChange = newWidth - width;
  return {
    right: widthChange,
    bottom: heightChange,
    top: 0,
    left: 0,
  };
}

export function getPadding(padding: IPadding | undefined) {
  if (!padding || padding.disabled) {
    return { left: 0, right: 0, top: 0, bottom: 0 };
  }
  const { left, right, top, bottom } = padding;
  return {
    left: left || 0,
    right: right || 0,
    top: top || 0,
    bottom: bottom || 0,
  };
}

export function getMovingTipsPosition(e: MouseEvent, contentWidth?: number, contentHeight?: number) {
  let yDiff = 16;
  let xDiff = 16;
  contentWidth = contentWidth || 90;
  contentHeight = contentHeight || 20;
  const isNearViewPortRightEdge = e.pageX + contentWidth + xDiff > window.innerWidth;
  const isNearViewPortTopEdge = e.pageY + contentHeight + xDiff > window.innerHeight;
  if (isNearViewPortRightEdge) {
    return { left: window.innerWidth - contentWidth, top: e.clientY + yDiff };
  }
  if (isNearViewPortTopEdge) {
    return { left: e.clientX + xDiff, top: window.innerHeight - contentHeight };
  }
  return { left: e.clientX + xDiff, top: e.clientY + yDiff };
}

export function getParentByFilter(
  comp: UIComponent,
  filter: (patrent: UIComponent) => boolean,
): UIComponent | undefined {
  const parent = comp.parent;
  if (!parent) return undefined;
  // @ts-ignore
  if (filter(parent)) return parent;
  // @ts-ignore
  return getParentByFilter(parent, filter);
}

export function generatorFnMapWithEnumValue(arr: Array<any>, fn: any) {
  return fromPairs(arr.map((value) => [`${value}`, partial(fn, value)]));
}

/**
 * 过滤组件中失效的数据
 * @param {IComponentData[]} comps
 * @returns {IComponentData[]}
 */
export function filterInvalidateComps(comps: IComponentData[]) {
  const result = comps.filter((comp) => !!comp);
  result.forEach((comp) => {
    if (comp.components) {
      comp.components = filterInvalidateComps(comp.components);
    }
  });
  return result;
}

/**
 * 写入masterID
 * @author Matt
 * @since 2020-12-2
 * @param {IComponentData} comp
 */
export function writeMasterID(comp: IComponentData) {
  comp.masterID = comp._id;
  comp.components?.forEach(writeMasterID);
}

export function extractDynamicInfo(comps: UIComponent[]) {
  return comps.reduce((dynamicInfoMap, comp) => {
    const compDynamicInfo = comp.dynamicInfo;
    const dynamicInfoExisted = !isEmpty(compDynamicInfo);
    if (dynamicInfoExisted) {
      dynamicInfoMap[comp.id] = compDynamicInfo;
    }
    return dynamicInfoMap;
  }, {} as DynamicInfoMap);
}

export function getCurrentMousePosition(e: MouseEvent) {
  const { pageY, pageX } = e;
  return { x: pageX, y: pageY };
}

/**
 * 将移动组件的动态数据,复制给克隆组件,清除源动态数据
 * @param movingComp
 * @param clonedComps
 */
export function setMovingDataFromOriginToClonedComps(
  movingComp: UIComponent[],
  clonedComps: { comp: UIComponent; source: string }[],
) {
  const dynamicInfoMap = extractDynamicInfo(movingComp);
  clonedComps.forEach(({ comp, source }) => {
    comp.dynamicInfo = dynamicInfoMap[source];
  });
  clearCompDynamicInfo(movingComp);
}

export function setMovingDataFromClonedCompsToOrgin(
  movingComp: UIComponent[],
  clonedComps: { comp: UIComponent; source: string }[],
) {
  const cloneComps = clonedComps.map(({ comp }) => comp);
  const dynamicInfoMap = clonedComps.reduce((dynamicInfoMap, { comp, source }) => {
    dynamicInfoMap[source] = comp.dynamicInfo;
    return dynamicInfoMap;
  }, {} as DynamicInfoMap);
  movingComp.forEach((comp) => {
    comp.dynamicInfo = dynamicInfoMap[comp.id];
  });
  clearCompDynamicInfo(cloneComps);
}

export function getModifyFlipAttrPatches(comps: UIComponent[], flipModel: IFlipModel, value: boolean): ArtboardPatches {
  const flipPath = flipModel === IFlipModel.Horizontal ? 'horizontal' : 'vertical';
  let patches = new ArtboardPatchesClass();
  return comps.reduce((patches, comp) => {
    const newValue = {
      ...(comp.flip || {}),
      [flipPath]: value,
    };
    const oldValue = comp.flip === undefined ? undefined : comp.flip;
    const newPatches = getModifyAttrArtboardPatches(comp.id, '/flip', { oldValue, newValue });
    return patches.coverPatches(newPatches);
  }, patches);
}

export function getModifyAttrArtboardPatches(
  id: string,
  path: string,
  valueChange: { oldValue: any; newValue: any },
): ArtboardPatches {
  const doOps = [Ops.replace(path, valueChange.newValue)];
  const undoOps = [Ops.replace(path, valueChange.oldValue)];
  return {
    do: {
      [id]: doOps,
    },
    undo: {
      [id]: undoOps,
    },
  };
}

export function updateChildDataByFlip(
  child: IComponentData,
  parent: UIContainerComponent,
  containerFlip: { horizontal: boolean; vertical: boolean },
) {
  const comp = new UIComponent(child, parent, { isPreview: false });
  const patches = new ArtboardPatchesClass();
  if (containerFlip.horizontal) {
    updatePatchesByWhenFlip(comp, IFlipModel.Horizontal, patches);
  }
  if (containerFlip.vertical) {
    updatePatchesByWhenFlip(comp, IFlipModel.Vertical, patches);
  }
  const info = extractDynamicInfoFromPatch(patches);
  const data = info[child._id];
  if (data) {
    data.rotate !== undefined && (child.rotate = data.rotate);
    data.value !== undefined && (child.value = data.value);
  }
}

export function getMaxIndexOfComponents(selectedComponentList: UIComponent[], allComps: UIComponent[]) {
  const indexArr = allComps.reduce((acc, comp, index) => {
    if (selectedComponentList.find((selectedComp) => selectedComp.id === comp.id)) {
      acc.push(index);
    }
    return acc;
  }, [] as number[]);
  return Math.max(-1, ...indexArr);
}

type TwoIComponentDataArr = IComponentData[][];

/**
 * 获取一个二维数组,初始值为 1
 * @param rowCount
 * @param columnCount
 * @param initialValue
 */
export function getTwoDimensionalArray(
  rowCount: number,
  columnCount: number,
  initialValue: IComponentData,
): TwoIComponentDataArr {
  const row = new Array(columnCount).fill(1).map(() => {
    const data = cloneDeep(initialValue) as IComponentData;
    const idMap = resetID([data]);
    resetInteractionTargetID([data], idMap);
    return data;
  });
  return new Array(rowCount).fill(1).map(() => {
    const newRow = cloneDeep(row);
    return newRow.map((compData) => {
      const data = cloneDeep(compData) as IComponentData;
      const idMap = resetID([compData]);
      resetInteractionTargetID([data], idMap);
      return compData;
    });
  });
}

/**
 *
 * @param comps 二维数组
 * @param rowGap
 * @param columnGap
 */
export function updateChildrenPositionByGrid(comps: TwoIComponentDataArr, rowGap: number, columnGap: number) {
  let accY: number = 0;
  comps.forEach((row) => {
    let accX: number = 0;
    let rowHeight = 0;
    row.forEach((item) => {
      const position = item.position;
      position.y = accY;
      position.x = accX;
      accX += item.size.width + columnGap;
      rowHeight = Math.max(rowHeight, item.size.height);
    });
    accY += rowHeight + rowGap;
  });
  return comps;
}

function resetCompState(comp: IComponentData, stateName: string) {
  const { properties, states } = comp;
  if (!states[stateName]) {
    states[stateName] = { enabled: true, properties: {} };
  }
  const state = states[stateName]!;
  state.enabled = true;
  if (!state.properties) {
    state.properties = {};
  }
  Object.keys(properties).forEach((propName) => {
    const prop = properties[propName]!;
    const name = prop.prop || propName;
    const stateProp = state.properties![propName];

    switch (name) {
      case FillPropertyName: {
        const fill = prop as IFill;
        if (!stateProp && fill.type === FillType.solid) {
          state.properties![propName] = { ...fill, color: DeepBlueColor };
        }
        break;
      }
      case StrokePropertyName:
      case IconPropertyName: {
        !stateProp && (state.properties![propName] = { ...prop, color: DeepBlueColor } as IStroke);
        break;
      }
      case TextFormatExPropertyName: {
        if (name === TextFormatExPropertyName && !properties[FillPropertyName]?.disabled) {
          !stateProp && (state.properties![propName] = { ...prop, color: WhiteColor } as PropertyValue);
        } else {
          !stateProp && (state.properties![propName] = { ...prop, color: DeepBlueColor } as PropertyValue);
        }
        break;
      }
      default:
        break;
    }
  });
  comp.components?.forEach((comp) => resetCompState(comp, stateName));
}

export function setDefaultStatusOfSelectionGroupChild(comps: IComponentData[], stateName: string) {
  comps.forEach((comp) => resetCompState(comp, stateName));
  comps[0].selected = true;
  return comps;
}

/**
 * 根据组的特性,来更改子的属性
 * @param activeContainer
 * @param comp
 * @private
 */
export function updateCompDataBaseOnContainer(activeContainer: any, comp: IComponentData) {
  const containerFlip = activeContainer.flip;
  const isContainerFlip = containerFlip?.horizontal || containerFlip?.vertical;
  isContainerFlip &&
    updateChildDataByFlip(comp, activeContainer, {
      horizontal: !!containerFlip?.horizontal,
      vertical: !!containerFlip?.vertical,
    });
}

export function getSelectionFrameTypeByConstraint(sizeMode: SizeMode) {
  if (sizeMode === SizeMode.custom) {
    return SelectionFrameType.box;
  } else if (sizeMode === SizeMode.ratio) {
    return SelectionFrameType.corner;
  } else if (sizeMode === SizeMode.horizontal) {
    return SelectionFrameType.leftMiddle_to_rightMiddle;
  } else if (sizeMode === SizeMode.vertical) {
    return SelectionFrameType.topMiddle_to_bottomMiddle;
  } else if (sizeMode === SizeMode.none) {
    return SelectionFrameType.none;
  }
  return SelectionFrameType.box;
}

export function flattComps(comp: UIComponent) {
  const list: UIComponent[] = [];
  const flatt = (comps: UIComponent[]) => {
    list.push(...comps);
    comps.forEach((comp) => {
      if (comp instanceof UIContainerComponent) {
        flatt(comp.components);
      }
    });
  };

  flatt([comp]);
  return list;
}

/**
 * 根据新的西北角的数据,计算最终的 position 位置
 * @param comp
 * @param position
 */
export function getCompChangeByNewPosition(comp: UIComponent, position: IPosition) {
  //这里传给我们的position是 实际组件占用区域的西北角的位置，我们要根据该位置计算出真实的position

  // 1.我们根据ViewBounds西北角点的位置，和ViewBounds的大小可以得到中心点，该中心点与组件中心点是重合的
  // 2.有了组件的中心点和组件的size，那么组件的position也就有了
  const { width: viewBoundsWidth, height: viewBoundsHeight } = comp.getViewBoundsInParent();
  const center = getCenter(position, { height: viewBoundsHeight, width: viewBoundsWidth }, 0);
  const realPosition = getRoundedPositionOfPoint(getNWPoint(center, comp.size, 0));
  // 如果居中的话，就不能移动组件
  if (comp.isLayoutCenterAtHorizontal) {
    realPosition.x = comp.position.x;
  }
  if (comp.isLayoutMiddleAtVertical) {
    realPosition.y = comp.position.y;
  }
  return {
    id: comp.id,
    type: ComponentChangeType.Edit,
    position: realPosition,
    size: comp.size,
    rotate: comp.rotate,
  };
}

export function extractContentPanels(container: UIContainerComponent) {
  const contentPanels: UIContentPanelComponent[] = [];
  container.components.forEach((comp) => {
    if (comp.isContainer) {
      if (comp instanceof UIContentPanelComponent || comp instanceof UIContentPanelV2Component) {
        contentPanels.push(comp);
      } else {
        contentPanels.push(...extractContentPanels(comp as UIContainerComponent));
      }
    }
  });
  return contentPanels;
}

/**
 * 获取画板数据
 * @param {Doc} doc 当前页面文档
 * @param {string} fragmentID 目标画板ID
 * @return {IArtboard} 返回的画板数据，如果没有，返回一个虚构的画板数据
 */
export function getFragmentDataFromDoc(doc: Doc, fragmentID: string): IArtboard {
  let fragment: IArtboard | undefined = doc.getFragmentData(fragmentID) as IArtboard;
  if (!fragment) {
    fragment = {
      _id: '',
      appID: '',
      nodeID: '',
      position: { x: 0, y: 0 },
      size: { width: 0, height: 0 },
      background: { color: { r: 255, g: 255, b: 255, a: 1 }, type: FillType.solid, disabled: false },
      type: CArtboard,
      interaction: {},
      responsive: false,
      components: [],
      latestRevisionID: '',
      variables: [],
      modified: false,
      imageURL: '',
      imageCreatedAt: new Date(),
      userID: 0,
      layout: {
        responsive: false,
        auto: true,
        horizontal: HorizontalAlign.Auto,
        vertical: VerticalAlign.Auto,
        fixedWidth: false,
        fixedHeight: false,
      },
      properties: {},
      states: {},
      connectors: [],
      v: 0,
      artboardID: '',
    };
  }
  return fragment;
}

export function traverseComponents(container: UIContainerComponent, callBack: (comp: UIComponent) => void) {
  container.components?.forEach((comp) => {
    callBack(comp);
    if (comp instanceof UIContainerComponent && !comp.isSymbol) {
      traverseComponents(comp, callBack);
    }
  });
}

export function traverseComponentsV2(comps: UIComponent[], callBack: (comp: UIComponent) => void) {
  comps.forEach((comp) => {
    callBack(comp);
    if (!comp.isSymbol && !comp.isSealed && comp instanceof UIContainerComponent) {
      traverseComponentsV2(comp.components, callBack);
    }
  });
}

export function getRefArtboardsData(dataItems: UIComponent[], doc: Doc) {
  return getAllRefArtboardsData(dataItems, doc);
}

function getAllRefArtboardsData(dataItems: UIComponent[], doc: Doc) {
  const refArtboardsData: IArtboard[] = [];
  // 遍历所有组件 是普通容器或者动态面板
  let comps = [...dataItems];
  let comp;
  while (((comp = comps.shift()), comp)) {
    if (comp instanceof UIContentPanelV2Component) {
      const refArtboards = comp.value.map((fragmentID: string) => doc.getArtboardByID(fragmentID)!);
      refArtboards.forEach((refArtboard) => {
        comps.push(...refArtboard.components);
      });
      refArtboardsData.push(...refArtboards.map((refArtboard) => refArtboard.$data));
    } else if (!comp.isSymbol && !comp.isSealed && comp instanceof UIContainerComponent) {
      comps.push(...comp.components);
    }
  }

  return refArtboardsData;
}

export function translateComponent(newComp: IComponentData) {
  // 修改一些内置属性，
  if (newComp.type === CText) {
    newComp.textBehaviour = ETextBehaviour.Both;
  }

  const does: [string, (v: string) => void][] = [];

  (['name', 'text', 'value', 'properties'] as const).forEach((k) => {
    if (!newComp[k]) {
      return;
    }

    const content: string = newComp[k];

    if (k === 'properties') {
      const val = newComp.properties.placeholder?.value ?? '';
      if (val && typeof val === 'string' && /[\u4e00-\u9fa5]/.test(val)) {
        does.push([
          val,
          (v) => {
            newComp.properties.placeholder!.value = v;
          },
        ]);
      }
      const tooltips = newComp.properties.tooltips?.value ?? '';
      if (tooltips && typeof tooltips === 'string' && /[\u4e00-\u9fa5]/.test(tooltips)) {
        does.push([
          tooltips,
          (v) => {
            newComp.properties.tooltips!.value = v;
          },
        ]);
      }
      return;
    }

    if (k === 'value' && typeof content === 'object' && /[\u4e00-\u9fa5]/.test(content!)) {
      does.push([
        content!,
        (v) => {
          try {
            newComp[k] = JSON.parse(v);
          } catch (error) {
            newComp[k] = 'erorr';
          }
        },
      ]);
    }

    // 仅支持中文的翻译
    if (typeof content === 'string' && /[\u4e00-\u9fa5]/.test(content!)) {
      does.push([
        content!,
        (v) => {
          newComp[k] = v;
        },
      ]);
    }
  });

  if (newComp.components?.length) {
    newComp.components.forEach((comp) => {
      const res = translateComponent(comp);
      does.push(...res);
    });
  }
  return does;
}

export function getArtboardInContentPanleV2(comp: IArtboard | IComponentData): IComponentData[] {
  let contentPanelV2: IComponentData[] = [];
  if (comp.type === CContentPanelV2) {
    contentPanelV2.push(comp);
  }
  comp.components?.forEach((childrenComp) => {
    if (childrenComp.type === CContentPanelV2) {
      contentPanelV2.push(childrenComp);
      return;
    }
    if (childrenComp.components) {
      contentPanelV2 = contentPanelV2.concat(
        childrenComp.components
          .map((e) => getArtboardInContentPanleV2(e))
          .reduce((prev, next) => {
            return prev.concat(next);
          }, []),
      );
    }
  });
  return contentPanelV2;
}

export function getAllContentPanelv2RefArtboardsData(contentPanelComp: IComponentData, doc: Doc) {
  let refArtboardsData: IArtboard[] = [];
  const artboardsData = contentPanelComp.value
    .map((fragmentID: string) => doc.getFragmentData(fragmentID) as IArtboard)
    .filter((e: IArtboard) => e);
  refArtboardsData = refArtboardsData.concat(artboardsData);
  //向下递归
  refArtboardsData = refArtboardsData.concat(
    artboardsData
      .map((fragment: IArtboard) => getArtboardInContentPanleV2(fragment))
      .reduce((prev: IComponentData[], next: IComponentData[]) => {
        return prev.concat(next);
      }, [])
      .map((e: IComponentData) => getAllContentPanelv2RefArtboardsData(e, doc))
      .reduce((prev: IArtboard[], next: IArtboard[]) => {
        return prev.concat(next);
      }, []),
  );
  return refArtboardsData;
}
