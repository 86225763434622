import i18n from '@/i18n';
import { IComponentSize } from '@fbs/rp/models/component';
import { CFlowTriangle, CFlowOctagon, CFlowPentagon, CFlowHexagon } from '@/libs/constants';

interface ItemConfig {
  sideCount: number;
  name: string;
  size: IComponentSize;
}

interface Config {
  [type: string]: ItemConfig;
}

export const PolygonTypeConfig: Config = {
  [CFlowTriangle]: {
    sideCount: 3,
    name: i18n('resource.flow.triangle'),
    size: {
      width: 100,
      height: 60,
      lockedRatio: false,
    },
  },
  [CFlowPentagon]: {
    sideCount: 5,
    name: i18n('resource.flow.pentagon'),
    size: {
      width: 100,
      height: 100,
      lockedRatio: false,
    },
  },
  [CFlowHexagon]: {
    sideCount: 6,
    name: i18n('resource.flow.hexagon'),
    size: {
      width: 60,
      lockedRatio: false,
      height: 53,
    },
  },
  [CFlowOctagon]: {
    sideCount: 8,
    name: i18n('resource.flow.octagon'),
    size: {
      width: 60,
      height: 60,
      lockedRatio: false,
    },
  },
};

export const PolygonTypes = Object.keys(PolygonTypeConfig);
