/**
 * @description libs及各个子libs中仅配置库的数据
 * 如果各个组件有一些非常规的数据操作或约束，在各个组件内部处理，并把相关方法配置到组件的库数据上；
 * 外部对于这些特殊的操作，通过组件的模板库数据中的配置来调用。
 * @author Matt
 * @example
 *
 * ./src/libs/common/button.ts;
 *
 * export const ButtonConfig: IComponentItem = {
 *  type: 'button',
 *  template: '',
 *  thumb:{},
 *  editor: {
 *    onResize: (comp: UIComponent, newBounds: IBounds): ArtboardPatch|null=>{...},
 *    ...
 *  },
 *  preview: {
 *    onTriggerState: (comp: UIComponent, stateName: string, value: any)=>{...},
 *  },
 *  initialization:(appType: string, data: IComponentData, container?: UIContainerComponent, size?: ISize)=>{...},
 *  property: {
 *    getValueNames: (key: string):Array<{value: any, name: string}>|undefined=>{
 *      return [];
 *    }
 *  },
 *  ....
 * }
 */

import { isUndefined, omit } from 'lodash';

import { IComponentData, IComponentSize } from '@fbs/rp/models/component';
import { HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import { IProperties } from '@/fbs/rp/models/property';

import i18n from '@i18n';

import { getNewID } from '@helpers/idHelper';
import { mergePropertyCacheToComp } from '@helpers/propertiesHelper';
import { UIComponent } from '@editor/comps';

import ValueEditorType from '@consts/enums/valueEditorType';
import { PolygonTypes } from '@/consts/polygonPath';

import { addTooltipsProperty, fillItems, executeTemplate } from './helper';

import BasicComponentLib, { DefaultBasicComponentsSize } from './basic/lib';
import CommonComponentLib from './compoundComponents/lib';
import ContainerComponentLib from './containers/lib';
import { makeSegments } from './compoundComponents/Segments/config';

import FlowComponentLib, { flowMakeConfig } from './flow/libs';
import CommentComponentLib from './comment/lib';
import ChartComponentLib from './ChartComponents/lib';

import { makeImage } from './basic/Image/config';
import { makeVideo } from './basic/Video';
import { makeAudio } from './basic/Audio';
import { makePolygonCompData } from './basic/Polygon/config';
import { IComponentLib, IComponentItem, IValueEditorInfo } from './types';
import {
  CRect,
  CEllipse,
  CIcon,
  CImage,
  CLine,
  CPath,
  CPolygon,
  CText,
  CParagraph,
  CInput,
  CGroup,
  CCanvasPanel,
  CFrame,
  CStackPanel,
  CWrapPanel,
  CContentPanel,
  CContentPanelV2,
  CListLayoutPanel,
  CSelect,
  CSlider,
  CQRCode,
  CNumericStep,
  CPureText,
  CTextArea,
  CSnapshot,
  CVideo,
  CAudio,
  CSvg,
  CTree,
  CSelectPanel,
  CNavigationMenu,
  CVerticalMenu,
  CHorizontalMenu,
  CCarouselChart,
  CCompoundPath,
  CConnector,
  CKeyboard,
  CTable,
  CMultipleSelect,
  CRectangle,
  CRoundRectangle,
  CStartOrEnd,
  CFlowCircle,
  CFlowEllipse,
  CSegmentsControl,
} from './constants';

export const ComponentLibs: IComponentLib[] = [
  BasicComponentLib,
  ContainerComponentLib,
  CommonComponentLib,
  ChartComponentLib,
  CommentComponentLib,
  FlowComponentLib,

  // AntDesignComponentLib,
  // IOSComponentLib,
];

function correctionData(comp: IComponentData) {
  const { type, size, components, properties } = comp;
  if (type === CListLayoutPanel) {
    const { cell, layout } = properties;
    if (layout) {
      if (layout.direction === 'vertical') {
        if (cell) {
          let h = size.height / components?.length!;
          if (!cell.ratioHeight) {
            h = cell.rowHeight || h;
          }
          let t = 0;
          components?.forEach((comp) => {
            comp.size.height = h;
            comp.position.y = t;
            t += h;
          });
          comp.size.height = h * components!.length;
        } else {
          let t = 0;
          components?.forEach((comp) => {
            comp.position.y = t;
            t += comp.size.height;
          });
        }
      } else {
        if (cell) {
          let w = size.width / components?.length!;
          if (!cell.ratioWidth) {
            w = cell.columnWidth || w;
          }
          let l = 0;
          components?.forEach((comp) => {
            comp.size.width = w;
            comp.position.x = l;
            l += w;
          });
          comp.size.width = w * components!.length;
        } else {
          let l = 0;
          components?.forEach((comp) => {
            comp.position.x = l;
            l += comp.size.width;
          });
        }
      }
    }
  }
}

function createComponent(comp: IComponentData, idMaps?: { [oldID: string]: string }): IComponentData {
  if (comp.components) {
    comp.components = comp.components.map((c) => createComponent(c, idMaps));
  }
  const newID = getNewID();
  const oldID = comp._id;
  if (idMaps && oldID) {
    idMaps[oldID] = newID;
  }
  const compData = Object.assign(
    {
      layout: {
        vertical: VerticalAlign.Auto,
        horizontal: HorizontalAlign.Auto,
        fixedWidth: false,
        fixedHeight: false,
        auto: true,
        responsive: true,
      },
      position: { x: 0, y: 0 },
      properties: {},
      interaction: {},
      size: {
        width: 0,
        height: 0,
      },
      v: 0,
      states: {},
    },
    comp,
    {
      _id: newID,
    },
  );
  correctionData(compData);
  return compData;
}

function replaceComponentInteractionTargets(comp: IComponentData, idMaps: { [oldID: string]: string }) {
  if (comp.interaction) {
    const events = Object.keys(comp.interaction);
    events.forEach((evt) => {
      const actions = comp.interaction[evt].actions;
      actions.forEach((action) => {
        if (action.type === 'component') {
          const newTarget = idMaps[action.target];
          if (newTarget) {
            action.target = newTarget;
          } else if (action.target === '@self') {
            action.target = comp._id;
          }
        }
      });
    });
  }
  if (comp.components) {
    comp.components.forEach((comp) => replaceComponentInteractionTargets(comp, idMaps));
  }
}

export const makeComponent = addTooltipsProperty(_makeComponent);
export const makeImageComponent = addTooltipsProperty(_makeImageComponent);
export const makeVideoComponent = addTooltipsProperty(_makeVideoComponent);
export const makeAudioComponent = addTooltipsProperty(_makeAudioComponent);

/**
 * flow下部分组件由basic组件实现
 * @param libName
 * @param componentName
 */
function isCompDataCreatedByBasic(libName: string, componentName: string) {
  return (
    libName === 'flow' && [CRectangle, CRoundRectangle, CStartOrEnd, CFlowCircle, CFlowEllipse].includes(componentName)
  );
}

/**
 * 创建新组件
 */
function _makeComponent(libName: string, componentName: string, appType: string): IComponentData | null {
  let comp = undefined;
  const isCreateFlowByBasic = isCompDataCreatedByBasic(libName, componentName);
  if (libName === 'basic' || libName === 'container' || libName === 'fast' || isCreateFlowByBasic) {
    const compTemplate = BasicComponentLib.components.find((item) => item.type === componentName);
    if (!compTemplate?.lib) {
      const data = isCreateFlowByBasic ? flowMakeConfig[componentName]() : BasicComponentLib.make(componentName);
      mergePropertyCacheToComp(data);
      if (DefaultBasicComponentsSize[componentName]) {
        const size = DefaultBasicComponentsSize[componentName][appType];
        if (size) {
          data.size = { ...data.size, ...size };
        }
      }
      // 数据初始化
      compTemplate && compTemplate.initialization && compTemplate.initialization(appType, data);
      return data;
    } else {
      comp = compTemplate;
    }
  }
  // 形状组件内多边形组件数据创建
  if (libName === 'flow' && PolygonTypes.includes(componentName)) {
    return makePolygonCompData(componentName);
  }
  if (!comp) {
    if (componentName === CSegmentsControl) {
      comp = makeSegments();
    } else {
      comp = getComponent({ id: libName, type: componentName });
    }
  }
  if (comp && comp.template) {
    let template = comp.template.trim();
    if ((comp.isList || componentName === CSelect) && comp.item) {
      template = fillItems(template, comp.item);
    }
    const res = executeTemplate(template);
    const componentData = Object.assign({}, res, {
      lib: {
        id: libName,
        type: componentName,
      },
    });
    comp.initialization && comp.initialization(appType, componentData);
    const idMaps = {};
    const result = createComponent(componentData, idMaps);
    replaceComponentInteractionTargets(result, idMaps);
    // 默认关闭响应式布局
    if (result.layout) {
      result.layout = Object.assign({}, result.layout, {
        ...res.layout,
        responsive: false,
      });
    }
    return result;
  }

  const compData = BasicComponentLib.make(componentName, 'No supported.');
  if (libName !== 'basic' && libName !== 'container') {
    return {
      ...compData,
      lib: {
        id: libName,
        type: componentName,
      },
    };
  }
  return compData;
}

// 创建图片组件
function _makeImageComponent(url: string, name: string, size: IComponentSize): IComponentData {
  const id = getNewID();
  return makeImage(id, url, name, size);
}

function _makeVideoComponent(url: string, name: string, size: IComponentSize): IComponentData {
  const id = getNewID();
  return makeVideo(id, url, name, size);
}
function _makeAudioComponent(url: string, name: string): IComponentData {
  const id = getNewID();
  return makeAudio(id, url, name);
}

// 创建某个库中组件的子项
export function makeComponentItem(
  libName: string,
  componentName: string,
  appType: string,
  args?: any[],
): IComponentData {
  const comp = getComponent({ id: libName, type: componentName });
  if (!comp || !comp.item) {
    throw new Error('尝试获取没有配置 item 的组件的 item.');
  }
  const item = comp.item;
  const params: any[] = args ? args : comp.itemArgs ? comp.itemArgs.map((arg) => arg.value) : [];
  const currentItem = item.replace(/{(\d+)}/g, (m, idx) => {
    if (params[idx] === undefined) {
      if (comp.itemArgs) {
        const arg = comp.itemArgs[idx];
        if (arg) {
          return arg.value;
        }
      }
      return m;
    }
    return params[idx];
  });
  const res = executeTemplate(currentItem.trim());
  const idMaps = {};
  const result: IComponentData = createComponent(res, idMaps);
  replaceComponentInteractionTargets(res, idMaps);
  return result;
}

/**
 * 根据组件的库信息获取该组件信息
 */
export function getComponent(lib: { id: string; type: string }): IComponentItem | undefined {
  const compLib = ComponentLibs.find((item) => item.id === lib.id);
  if (compLib) {
    return compLib.components.find((comp) => comp.type === lib.type);
  }
}

export function getSealedCompoundComponentLib(type: string) {
  return CommonComponentLib.components.find((lib) => lib.type === type);
}

const BasicComponentsSupportValueEditor: { [key: string]: ValueEditorType } = {
  [CParagraph]: ValueEditorType.RichText,
  [CInput]: ValueEditorType.PureText,
  [CTextArea]: ValueEditorType.PureText,
  [CText]: ValueEditorType.RichText,
  [CImage]: ValueEditorType.Image,
  [CSnapshot]: ValueEditorType.Snapshot,
  [CIcon]: ValueEditorType.Icon,
  [CVideo]: ValueEditorType.Video,
  [CAudio]: ValueEditorType.Audio,
  [CQRCode]: ValueEditorType.PureText,
  [CSvg]: ValueEditorType.Svg,
  [CContentPanel]: ValueEditorType.ItemValue,
  [CContentPanelV2]: ValueEditorType.ContentPanel,
  [CSelect]: ValueEditorType.ItemValue,
  [CNumericStep]: ValueEditorType.PureText,
  [CPureText]: ValueEditorType.PureText,
  [CTree]: ValueEditorType.Tree,
  [CSelectPanel]: ValueEditorType.ItemValue,
  [CFrame]: ValueEditorType.PureText,
  [CNavigationMenu]: ValueEditorType.Menu,
  [CVerticalMenu]: ValueEditorType.Menu,
  [CHorizontalMenu]: ValueEditorType.Menu,
  [CCarouselChart]: ValueEditorType.CarouselChart,
};

export function getComponentSupportValueEditorType(
  type: string,
  lib?: {
    id: string;
    type: string;
  },
): ValueEditorType | undefined {
  const info = getComponentValueEditorInfo(type, lib);
  if (info) {
    return info.type;
  }
}

export function getComponentValueEditorInfo(
  type: string,
  lib?: {
    id: string;
    type: string;
  },
): IValueEditorInfo | null {
  const value = BasicComponentsSupportValueEditor[type];
  if (!isUndefined(value)) {
    return { type: value };
  }
  if (lib) {
    const compItem = getComponent(lib);
    if (!compItem || !compItem.value) {
      return null;
    }
    return compItem.value;
  }
  return null;
}

// TODO: 是否在创建时就应添加组件名
const DefaultComponentName: { [type: string]: string } = {
  [CAudio]: i18n('resource.components.audio'),
  [CCanvasPanel]: i18n('resource.components.canvasPanel'),
  [CCompoundPath]: i18n('resource.components.compoundPath'),
  [CContentPanel]: i18n('resource.components.contentPanel'),
  [CContentPanelV2]: i18n('resource.components.contentPanel'),
  [CConnector]: i18n('resource.components.connector'),
  [CEllipse]: i18n('resource.components.ellipse'),
  [CGroup]: i18n('resource.components.group'),
  [CIcon]: i18n('resource.icon'),
  [CImage]: i18n('resource.components.image'),
  [CSnapshot]: i18n('resource.components.snapshot'),
  [CInput]: i18n('resource.components.input'),
  [CKeyboard]: i18n('resource.components.keyboard'),
  [CLine]: i18n('resource.components.line'),
  [CListLayoutPanel]: i18n('resource.components.listLayoutPanel'),
  [CNumericStep]: i18n('resource.components.numericStep'),
  [CParagraph]: i18n('resource.components.paragraph'),
  [CPath]: i18n('resource.components.path'),
  [CPolygon]: i18n('resource.components.polygon'),
  [CQRCode]: i18n('resource.components.qrcode'),
  [CRect]: i18n('resource.components.rect'),
  [CSlider]: i18n('resource.components.slider'),
  [CSelect]: i18n('resource.components.select'),
  [CStackPanel]: i18n('resource.components.stackPanel'),
  [CSelectPanel]: i18n('resource.components.selectPanel'),
  [CTextArea]: i18n('resource.components.textarea'),
  [CText]: i18n('resource.components.text'),
  [CVideo]: i18n('resource.components.video'),
  [CWrapPanel]: i18n('resource.components.wrapPanel'),
  [CPureText]: i18n('resource.components.pureText'),
  [CTable]: i18n('resource.components.table'),
  [CTree]: i18n('resource.components.tree'),
  [CMultipleSelect]: i18n('resource.components.multipleSelect'),
  [CNavigationMenu]: i18n('resource.components.navigationMenu'),
  [CVerticalMenu]: i18n('resource.components.verticalMenu'),
  [CHorizontalMenu]: i18n('resource.components.horizontalMenu'),
};

export function getDefaultComponentName(type: string, lib?: { id: string; type: string }): string {
  if (lib) {
    const libData = getComponent(lib);
    if (libData) {
      return libData.name;
    }
  }
  const name = DefaultComponentName[type];
  return name || type || 'component';
}

export function getNameForNewComponent(
  existedComponents: UIComponent[],
  lib: {
    id: string;
    type: string;
  },
) {
  const existedSameTypeComponentsCount = existedComponents.filter((comp) =>
    comp.lib ? comp.lib.id === lib.id && comp.lib.type === lib.type : comp.type === lib.type,
  ).length;
  const defaultName = getDefaultComponentName(lib.type, lib);
  return `${defaultName} ${existedSameTypeComponentsCount + 1}`;
}

const libCache: { [libType: string]: IComponentItem | null } = {};

// TODO 用这个
// export function getLibDefaultData(comp: ComponentBase) {
//   const type = comp.lib?.type || (comp.nearestSealedComponent ? undefined : comp.type);
//   const libData = type ? getLibData(type) : null;

//   return libData?.getDefaultData?.();
// }

export function getLibDefaultData(data: IComponentData) {
  // TODO: 这里不能简单处理空值，要区分最外层和子
  // 用 data.type 就是处理 basic 组件
  const type = data.lib?.type || (data.alias ? undefined : data.type);
  const libData = type ? getLibData(type) : null;

  return libData?.getDefaultData?.(data);
}

export function adaptOldTextStyleProperties(properties: IProperties, defaultProperties: Partial<IProperties>) {
  if (properties.textStyle && defaultProperties.textFormat) {
    if (!properties.textFormat) {
      properties.textFormat = {
        ...defaultProperties.textFormat,
        ...properties.textStyle,
        ...omit(properties.multiText, 'lineHeight'),
        lineHeightEx: properties.multiText?.lineHeight,
        disabled: false,
      };
    }
    delete properties.textStyle;
    delete properties.multiText;
  }
  return properties;
}

export function adaptSimplifiedCompData(data: IComponentData) {
  const libDefaultData = getLibDefaultData(data);
  if (libDefaultData) {
    const { properties: defaultProperties = {}, states: defaultStates = {} } = libDefaultData;

    // 文本样式旧数据兼容
    const properties = adaptOldTextStyleProperties(data.properties, defaultProperties);

    // 状态中 文本样式旧数据兼容
    const states = data.states ?? {};
    Object.keys(states).forEach((key) => {
      adaptOldTextStyleProperties(states[key].properties ?? {}, defaultProperties);
    });

    const fullProperties = { ...defaultProperties, ...properties };
    const fullStates = { ...defaultStates, ...states };

    // NOTE 修改源data对data其他字段的精简是友好的
    data.properties = fullProperties;
    data.states = fullStates;
  }
  return data;
}

export function getLibData(libType: string): IComponentItem | null {
  const cache = libCache[libType];
  if (cache !== undefined) {
    return cache ? cache : null;
  }

  for (let i = 0, c = ComponentLibs.length; i < c; i++) {
    const lib = ComponentLibs[i];
    const data = lib.components.find((lib) => lib.type === libType);
    if (data) {
      libCache[libType] = data;
      return data;
    }
  }
  libCache[libType] = null;
  return null;
}

export function getLibByShortCutOrType(keyOrType: string): { type: string; data: IComponentItem } | undefined {
  let lib = getLibDataByShortCut(keyOrType);
  if (!lib) {
    lib = getLibByType(keyOrType);
  }
  return lib;
}

export function getLibByType(libType: string): { type: string; data: IComponentItem } | undefined {
  const _libType = libType.toLowerCase();
  for (let i = 0, c = ComponentLibs.length; i < c; i++) {
    const lib = ComponentLibs[i];
    const data = lib.components.find((lib) => lib.type.toLowerCase() === _libType);
    if (data) {
      return { type: lib.id, data };
    }
  }
  return undefined;
}

/**
 * 根据快捷键或类型，创建组件
 *
 * @export
 * @param {string} keyOrType
 * @param {string} appType
 * @returns {*}
 */
export function makeComponentByKeyOrType(keyOrType: string, appType: string) {
  const key = keyOrType.toUpperCase();
  const libData = getLibByShortCutOrType(key)!;
  return makeComponent(libData.type, libData.data.type, appType)!;
}

export function getLibDataByShortCut(shortCut: string): { type: string; data: IComponentItem } | undefined {
  const str = shortCut.toLowerCase().trim();
  for (let i = 0, c = ComponentLibs.length; i < c; i++) {
    const lib = ComponentLibs[i];
    for (let j = 0, l = lib.components.length; j < l; j++) {
      const libItem = lib.components[j];
      if (libItem.shortCut) {
        if (libItem.shortCut.toLowerCase().trim() === str) {
          return { type: lib.id, data: libItem };
        }
      }
    }
  }
  return undefined;
}
