import { measureTextSize, transBlankChart } from '@utils/textUtils';

import {
  DefaultWhiteFillColor,
  DisabledWhiteFillColor,
  LightGrayColor,
  ShadowColor,
  SilverWhiteColor,
  WhiteColor,
} from '@consts/colors';
import { PredefinedStates } from '@consts/state';
import { DefaultPadding } from '@/consts/padding';
import * as SystemsColor from '@consts/colors';
import { SpriteThumb } from '@consts/spriteIcons';
import i18n from '@i18n';
import { getNewID } from '@helpers/idHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { getChangedValue } from '@/helpers/componentHelper';
import { UIComponent, UIContainerComponent, UIListLayoutSealedComponent } from '@editor/comps';
import { HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import { default as ITextFormat, TextAlign, TextPropertyName } from '@fbs/rp/models/properties/text';
import { StrokeLineCap, StrokeLineJoin, StrokePosition } from '@fbs/rp/models/properties/stroke';
import { SelectListPropertyName } from '@fbs/rp/models/properties/selectList';
import { ArtboardPatches, Operation, Ops } from '@fbs/rp/utils/patch';
import IFill, { FillType } from '@fbs/rp/models/properties/fill';
import { IComponentData } from '@fbs/rp/models/component';
import { PropertyValue } from '@fbs/rp/models/property';
import IPadding from '@/fbs/rp/models/properties/padding';
import ITree, { DefaultCollapseIconType } from '@/fbs/rp/models/properties/tree';
import IIcon from '@/fbs/rp/models/properties/icon';
import { PureColor } from '@/fbs/rp/models/properties/color';
import { TreeItemAlias, TreeItemColumn } from '@/helpers/treeCompHelper';
import { makeIcon } from '@/libs/basic/Icon';
import { defaultExpandIcon, defaultSelectIcon } from '@/consts/defaultData/menu';

import { CPureText, CText, CCanvasPanel, CListLayoutPanel, CSelect } from '../../constants';
import { IComponentItem } from '../../types';
import { makeCommonComponent } from '../../helper';

const TEXT_TO_RIGHT_SPACE = 16; // 文本距组件右侧距离

/**
 * 当下拉部分选项改变时，更新主体的文本显示内容
 * @param {UIComponent} group
 * @param {UIComponent} comp
 * @param {boolean} selected
 * @return {ArtboardPatches | null}
 */
function onChildSelectedChange(group: UIComponent, comp: UIComponent, selected: boolean): ArtboardPatches | null {
  const container = (group as unknown) as UIContainerComponent;
  const list = container.getComponentByAlias('list', true);
  const mainGroup = container.getComponentByAlias('main', true);
  let patches: ArtboardPatches | null = null;
  if (list) {
    patches = { do: {}, undo: {} };
    const listGroup = (list as unknown) as UIContainerComponent;
    const selComp = listGroup.components.find((comp) => comp.selected);
    if (selComp) {
      const opt = selComp.changeStateEnabled(PredefinedStates.checked, false);
      if (opt) {
        opt.do.push(Ops.replace('./selected', false));
        opt.undo.push(Ops.replace('./selected', true));
        patches.do[selComp.id] = opt.do;
        patches.undo[selComp.id] = opt.undo;
      }
    }
    if (mainGroup) {
      const textComp = ((mainGroup as unknown) as UIContainerComponent).components.find(
        (comp) => comp.type === CPureText,
      )!;
      const itemTextComp = ((comp as unknown) as UIContainerComponent).components.find(
        (comp) => comp.type === CPureText,
      )!;
      let opt: ArtboardPatches = { do: {}, undo: {} };
      if (textComp.isRefValue()) {
        if (!mainGroup.isRefValue()) {
          opt.do[mainGroup.id] = [Ops.replace('./value', '@value')];
        }
        opt.do[group.id] = [Ops.replace('./value', selected ? itemTextComp.value! : '')];
        opt.undo[group.id] = [Ops.replace('./value', group.value)];
      } else {
        const path = '/value';
        opt.do[textComp.id] = [Ops.replace(path, itemTextComp.value!)];
        opt.undo[textComp.id] = [Ops.replace(path, textComp.value)];
      }
      Object.keys(opt.do).forEach((id) => {
        patches!.do[id] = [...(patches!.do[id] || []), ...opt.do[id]];
        patches!.undo[id] = [...(patches!.undo[id] || []), ...(opt.undo[id] || [])];
      });
    }
  }
  return patches;
}

/**
 * 当下拉部分的值改变时，更新主体部分的显示内容
 * @param value
 * @param {UIComponent} comp
 * @return {ArtboardPatches | null}
 */
function onChildValueChange(value: any, comp?: UIComponent): ArtboardPatches | null {
  if (!comp) {
    return null;
  }
  const owner = comp.nearestSealedComponent;
  const patches: ArtboardPatches = { do: {}, undo: {} };
  if (owner?.alias === 'drop-down' && owner?.selected) {
    if (owner.value) {
      patches.do[owner.id] = [Ops.replace('./value', undefined)];
    }
  }
  const select = owner?.nearestSealedComponent;
  if (select && owner?.selected) {
    patches.do[select.id] = [Ops.replace('./value', value)];
    patches.undo[select.id] = [Ops.replace('./value', select.value)];
  }
  return patches;
}

/**
 * 获取主体部分中的文本部分的组件
 * @param {UIContainerComponent} container
 * @return { | undefined}
 */
function getTextComp(container: UIContainerComponent) {
  return container.getFirstChildComponentByType(CPureText) || container.getFirstChildComponentByType(CText);
}

/**
 * 演示时的状态触发
 * @param {UIComponent} container
 * @param {UIComponent} triggerComp
 * @param {string} stateName
 * @return {any}
 */
function onTriggerState(container: UIComponent, triggerComp: UIComponent, stateName: string) {
  if (stateName !== PredefinedStates.checked) {
    return null;
  }
  let textComp: UIComponent | undefined = undefined;
  if (triggerComp.isContainer) {
    textComp = getTextComp(triggerComp as UIContainerComponent);
  }

  if (!textComp) {
    return null;
  }
  let mainText: UIComponent | undefined = undefined;
  const mainComp = (container as UIContainerComponent).getComponentByAlias('main');
  if (mainComp) {
    mainText = getTextComp(mainComp as UIContainerComponent);
  }
  if (!mainText) {
    return null;
  }
  // 演示的时候，直接改显示的文本内容
  return {
    [mainText.id]: [Ops.replace('./value', textComp.value)],
  };
}

/**
 * 下拉列表属性更改
 * @param container
 * @param propertyValue
 */
function onSelectListUpdate(container: UIContainerComponent, propertyValue: PropertyValue) {
  const diffProperty = getChangedValue(propertyValue, container.properties.selectList);
  if (diffProperty) {
    if ('lineHeight' in diffProperty) {
      const listComp = container.getComponentByAlias('list') as UIListLayoutSealedComponent;
      const cell = listComp.properties.cell;
      return listComp.getPatchesWhenSetCellProperty({ ...cell, rowHeight: diffProperty.lineHeight });
    } else {
      const comp = container.getComponentByAlias('drop-down')!;
      const doPatches: Operation[] = [];
      const undoPatches: Operation[] = [];
      if ('bgColor' in diffProperty) {
        const path = '/properties/fill/color';
        doPatches.push(Ops.replace(path, diffProperty.bgColor));
        undoPatches.push(Ops.replace(path, comp.properties.fill!.color));
      }
      if ('selectedBgColor' in diffProperty) {
        const path = '/properties/itemSelectFill/color';
        doPatches.push(Ops.replace(path, diffProperty.selectedBgColor));
        undoPatches.push(Ops.replace(path, (comp.properties.itemSelectFill as IFill)!.color));
      }
      if ('selectedFontColor' in diffProperty) {
        const path = '/properties/itemSelectTextStyle/color';
        doPatches.push(Ops.replace(path, diffProperty.selectedFontColor));
        undoPatches.push(Ops.replace(path, (comp.properties.itemSelectTextStyle as ITextFormat)!.color));
      }
      const patches = { do: { [comp.id]: doPatches }, undo: { [comp.id]: undoPatches } };
      return patches;
    }
  }

  return null;
}

/**
 * 当下拉属性修改时
 * @param {UIComponent} container
 * @param {string} propertyName
 * @param {PropertyValue} propertyValue
 * @return {ArtboardPatches | null}
 */
function onPropertyUpdate(
  container: UIComponent,
  propertyName: string,
  propertyValue: PropertyValue,
): ArtboardPatches | null {
  const select = container as UIContainerComponent;
  if (propertyName === 'selectList') {
    return onSelectListUpdate(select, propertyValue);
  }

  const dropDown = select.getComponentByAlias('list') as UIContainerComponent;
  if (!dropDown) {
    return null;
  }
  const selItem = dropDown.components.find((comp) => comp.selected);
  if (!selItem) {
    return null;
  }

  // 修改占位文本相关信息
  if (propertyName === 'placeholder') {
    const value = propertyValue.value as string;
    if (!value) {
      return null;
    }
    const main = select.getComponentByAlias('main');
    const { id } = selItem;
    const path = `/selected`;
    const valuePath = '/value';
    const isRefValue = main?.isRefValue();
    const mainID = isRefValue ? '' : main!.id;
    const mainValue = isRefValue ? '' : main!.value;
    const mainDo = isRefValue ? {} : { [mainID]: [Ops.replace(valuePath, value)] };
    const mainUndo = isRefValue ? {} : { [mainID]: [Ops.replace(valuePath, mainValue)] };
    return {
      do: {
        [id]: [Ops.replace(path, false)],
        [container.id]: [Ops.replace(valuePath, '')],
        ...mainDo,
      },
      undo: {
        [id]: [Ops.replace(path, true)],
        [container.id]: [Ops.replace(valuePath, container.value)],
        ...mainUndo,
      },
    };
  }
  if (propertyName === TextPropertyName) {
    const {
      id,
      properties: { textStyle },
    } = dropDown.parent!;
    const {
      components,
      properties: { cell },
      id: dropDownID,
    } = dropDown;
    const textStylePath = '/properties/textStyle';
    const selectedTextStylePath = '/properties/itemSelectTextStyle';
    const cellPath = '/properties/cell';
    const listPath = '/properties/selectList';

    const oldSelectList = select.properties.selectList;
    const { fontFamily, fontSize, color } = propertyValue as ITextFormat;
    const newPropertyValue = { ...textStyle, fontFamily, fontSize };
    const style = StyleHelper.initCSSStyleParser({ textStyle: newPropertyValue }).getTextStyle();
    const { height } = measureTextSize(style, 'j', {});
    const newCell = { ...cell, rowHeight: height };
    const itemPatches = components.reduce(
      (acc, comp) => {
        const { size } = comp;
        acc.do[comp.id] = [Ops.replace('/size/height', height + 10)];
        acc.undo[comp.id] = [Ops.replace('/size/height', size.height)];
        return acc;
      },
      { do: {}, undo: {} } as { do: { [id: string]: Operation[] }; undo: { [id: string]: Operation[] } },
    );
    return {
      do: {
        [id]: [
          Ops.replace(textStylePath, { ...textStyle, fontFamily, fontSize }),
          Ops.replace(selectedTextStylePath, { ...textStyle, fontFamily, fontSize, color }),
        ],
        [dropDownID]: [Ops.replace(cellPath, newCell)],
        [select.id]: [Ops.replace(listPath, { ...oldSelectList, selectedFontColor: color })],
        ...itemPatches.do,
      },
      undo: {
        [id]: [Ops.replace(textStylePath, textStyle), Ops.replace(selectedTextStylePath, textStyle)],
        [dropDownID]: [Ops.replace(cellPath, cell)],
        [select.id]: [Ops.replace(listPath, oldSelectList)],
        ...itemPatches.undo,
      },
    };
  }

  if (propertyName === 'treeExpand') {
    // 修改所有的 展开折叠icon 的  value
    const newIconFont = defaultSelectIcon.find((item) => item.id === (propertyValue as ITree).chooseExpandType);
    const expandIcon = select.getComponentByAlias('expandIcon');
    const treeExpandPath = '/properties/treeExpand';
    const iconPath = '/value';
    const id = expandIcon?.id || select.id;
    if (expandIcon && newIconFont?.value && newIconFont.value.length === 2) {
      const pathes = {
        do: {
          [id]: [Ops.replace(iconPath, newIconFont.value[0])],
          [select.id]: [Ops.replace(treeExpandPath, { ...propertyValue })],
        },
        undo: {
          [id]: [Ops.replace(iconPath, expandIcon.value as ITree)],
          [select.id]: [Ops.replace(treeExpandPath, { ...select.properties.treeExpand })],
        },
      };
      //修改默认值
      defaultExpandIcon.expand.icon = newIconFont.value[0];
      defaultExpandIcon.collapse.icon = newIconFont.value[1];
      defaultExpandIcon.id = newIconFont.id;

      return pathes;
    }
  }

  return null;
}

/**
 * 处理右侧属性面板内边距最大值的处理
 * @param group
 * @param padding
 * @param fontSize
 */
function onCalcMaxPadding(group: UIContainerComponent, padding: IPadding, fontSize: number) {
  const { left, right, bottom, top } = padding;
  const { width, height } = group.size;
  const _width = width - TEXT_TO_RIGHT_SPACE;
  const maxValue = {
    top: height - (bottom || 0) - fontSize,
    right: _width - (left || 0) - fontSize,
    bottom: height - (top || 0) - fontSize,
    left: _width - (right || 0) - fontSize,
  };
  const defaultMaxValue = Math.min(_width / 2, height / 2);
  return { maxValue, defaultMaxValue };
}

/**
 * 创建一条下拉项数据
 * @param {string} value
 * @param {number} y
 * @param {boolean} selected
 * @return {IComponentData}
 */
function makeDropDownItem(value: string, y: number, selected: boolean = false) {
  return makeCommonComponent(getNewID(), CCanvasPanel, {
    name: 'listItem',
    size: { width: 148, height: 30 },
    position: { x: 1, y },
    layout: {
      auto: true,
      responsive: true,
      fixedWidth: false,
      fixedHeight: true,
      vertical: VerticalAlign.Top,
      horizontal: HorizontalAlign.LeftAndRight,
    },
    selected,
    properties: {
      border: { disabled: true, hidden: true },
      fill: { ref: '@properties.fill' },
      textStyle: { ref: '@properties.textStyle' },
    },
    states: {
      [PredefinedStates.hover]: {
        enabled: true,
        properties: {
          fill: { ref: '@properties.itemHoverFill' },
        },
      },
      [PredefinedStates.checked]: {
        enabled: true,
        properties: {
          fill: { ref: '@properties.itemSelectFill' },
        },
      },
    },
    components: [
      makeCommonComponent(getNewID(), CPureText, {
        position: { x: 8, y: 5 },
        size: { height: 20, width: 130 },
        autoSize: false,
        layout: {
          auto: false,
          responsive: true,
          fixedWidth: false,
          fixedHeight: true,
          vertical: VerticalAlign.Middle,
          horizontal: HorizontalAlign.LeftAndRight,
        },
        properties: {
          textStyle: { ref: '@properties.textStyle' },
        },
        states: {
          [PredefinedStates.checked]: {
            enabled: true,
            properties: {
              textStyle: { ref: '@properties.itemSelectTextStyle' },
            },
          },
        },
        value,
      }),
    ],
  });
}

/**
 * 创建下拉部分数据
 * @return {IComponentData}
 */
function makeDropDown() {
  return makeCommonComponent(getNewID(), CCanvasPanel, {
    alias: 'drop-down',
    name: 'dropDown',
    hidden: true,
    sealed: true,
    position: {
      x: 0,
      y: 31,
    },
    size: {
      width: 150,
      height: 142,
    },
    layout: {
      auto: false,
      responsive: true,
      fixedWidth: false,
      fixedHeight: true,
      vertical: VerticalAlign.Bottom,
      horizontal: HorizontalAlign.LeftAndRight,
    },
    properties: {
      shadow: {
        x: 0,
        y: 3,
        blur: 6,
        color: ShadowColor,
        disabled: false,
      },
      fill: {
        type: FillType.solid,
        color: WhiteColor,
        disabled: false,
      },
      stroke: {
        thickness: 1,
        color: LightGrayColor,
        disabled: false,
      },
      itemHoverFill: {
        prop: 'fill',
        name: i18n('property.propertyNames.hoverFill'),
        type: FillType.solid,
        color: SilverWhiteColor,
        disabled: false,
      },
      itemSelectFill: {
        prop: 'fill',
        name: i18n('property.propertyNames.checkedBgcolor'),
        type: FillType.solid,
        color: LightGrayColor,
        disabled: false,
      },
      textStyle: {
        textAlign: TextAlign.left,
        color: SystemsColor.DefaultTextColor,
        fontSize: 14,
        fontFamily: 'Microsoft YaHei',
        fontStyle: { underline: false, bold: false, strike: false, italic: false },
      },
      itemSelectTextStyle: {
        prop: 'textStyle',
        name: i18n('property.propertyNames.checkedText'),
        textAlign: TextAlign.left,
        color: SystemsColor.DefaultTextColor,
        fontSize: 14,
        fontFamily: 'Microsoft YaHei',
        fontStyle: { underline: false, bold: false, strike: false, italic: false },
      },
    },
    components: [
      makeCommonComponent(getNewID(), CListLayoutPanel, {
        alias: 'list',
        name: 'list',
        position: { x: 1, y: 10 },
        size: {
          width: 148,
          height: 122,
        },
        layout: {
          auto: true,
          responsive: true,
          fixedWidth: false,
          fixedHeight: false,
          vertical: VerticalAlign.Top,
          horizontal: HorizontalAlign.LeftAndRight,
        },
        properties: {
          layout: {
            hidden: true,
            direction: 'vertical',
            horizontalAlign: 'left',
          },
          cell: {
            disabled: true,
            name: i18n('property.propertyNames.lineHeight'),
            rowHeight: 30,
            ratioHeight: false,
            ratioWidth: false,
          },
          container: {
            scroll: true,
            showScroll: true,
            disabled: false,
          },
        },
        select: {
          minCount: 0,
          maxCount: 1,
          target: 'child',
          reversible: false,
          autoUnselect: true,
          enabled: true,
        },
        components: [1, 2, 3, 4].map((i) =>
          makeDropDownItem(
            transBlankChart(`${i18n('resource.componentsText.optionText')} ${i}`),
            (i - 1) * 30,
            i === 1,
          ),
        ),
      }),
    ],
  });
}

/**
 * 创建主体部分数据
 * @return {IComponentData}
 */
function makeMain() {
  return makeCommonComponent(getNewID(), CCanvasPanel, {
    alias: 'main',
    name: 'combo',
    position: { x: 0, y: 0 },
    size: { width: 150, height: 30 },
    layout: {
      auto: true,
      responsive: true,
      fixedWidth: false,
      fixedHeight: false,
      vertical: VerticalAlign.TopAndBottom,
      horizontal: HorizontalAlign.LeftAndRight,
    },
    properties: {
      textStyle: { ref: '@properties.textStyle' },
      fill: { ref: '@properties.fill' },
      radius: { ref: '@properties.radius' },
      border: { ref: '@properties.border' },
      padding: { ref: '@properties.padding' },
    },
    value: '@value',
    components: [
      // 自定义图标数据
      makeIcon(getNewID(), {
        alias: TreeItemAlias.ExpandIcon,
        column: TreeItemColumn.Expand,
        size: { width: 12, height: 12 },
        value: defaultExpandIcon.expand.icon,
        selected: true,
        properties: {
          icon: {
            color: SystemsColor.DefaultTextColor,
          },
        },
        states: {
          checked: {
            enabled: true,
            properties: {},
            value: defaultExpandIcon.expand.icon,
          },
        },
        select: {
          target: 'self',
          enabled: true,
          reversible: true,
        },
      }),
      // makeCommonComponent(getNewID(), CPath, {
      //   position: {
      //     x: 136,
      //     y: 13,
      //   },
      //   value: {
      //     closed: true,
      //     data: [
      //       { handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 }, point: { x: 0, y: 0 }, radius: 0.3 },
      //       { handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 }, point: { x: 8, y: 0 }, radius: 0.3 },
      //       { handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 }, point: { x: 4, y: 5 }, radius: 0.3 },
      //     ],
      //   },

      //   size: { width: 8, height: 5, lockedRatio: true },
      //   layout: {
      //     auto: false,
      //     responsive: true,
      //     fixedWidth: true,
      //     fixedHeight: true,
      //     vertical: VerticalAlign.Middle,
      //     horizontal: HorizontalAlign.Right,
      //   },
      //   rotate: 0,
      //   properties: {
      //     fill: { ref: '@properties.iconColor' },
      //     stroke: { color: { a: 1, b: 119, g: 119, r: 119 }, disabled: true },
      //   },
      // }),
      makeCommonComponent(getNewID(), CPureText, {
        autoSize: false,
        position: {
          x: 0,
          y: 0,
        },
        size: { height: 30, width: 134 },
        layout: {
          auto: false,
          responsive: true,
          fixedWidth: false,
          fixedHeight: false,
          vertical: VerticalAlign.Middle,
          horizontal: HorizontalAlign.LeftAndRight,
        },
        properties: {
          textStyle: { ref: '@properties.textStyle' },
        },
        value: '@value',
      }),
    ],
  });
}

/**
 * 创建整个下拉数据
 * @param {string} id
 * @return {IComponentData}
 */
export function makeSelect(id: string): IComponentData {
  return makeCommonComponent(id, CSelect, {
    ...SelectBoxConfig.getDefaultData?.(),
    _id: id,
    alias: 'select',
    size: { width: 150, height: 30 },
    value: `${i18n('resource.componentsText.optionText')} 1`,
    states: {
      [PredefinedStates.disabled]: {
        enabled: true,
        properties: {
          textStyle: {
            color: SystemsColor.DisabledTextColor,
          },
          fill: {
            type: FillType.solid,
            color: DisabledWhiteFillColor,
          },
        },
      },
    },
    sealed: true,
    components: [makeMain(), makeDropDown()],
  });
}

export const SelectBoxConfig: IComponentItem = {
  type: CSelect,
  thumb: {
    spriteIconClass: SpriteThumb.Select.className,
    dragPosition: SpriteThumb.Select.position,
  },
  isList: true,
  isTextComp: true,
  name: i18n('resource.components.select'),
  predefinedStates: [PredefinedStates.hover, PredefinedStates.focus, PredefinedStates.disabled],
  editor: {
    onChildSelectedChange,
    onChildValueChange,
    onPropertyUpdate,
    specials: {
      onCalcMaxPadding,
    },
  },
  preview: {
    onTriggerState,
  },
  getDefaultData(originalData) {
    // 自定义图标
    const treeExpand: ITree = {
      name: i18n('property.component.tree.treeExpand'),
      prop: 'tree',
      isShow: true,
      // 默认箭头
      chooseExpandType: defaultExpandIcon.id ?? DefaultCollapseIconType.Arrow,
      iconColor: SystemsColor.DefaultSelectColor,
      iconSize: 12,
    };
    // 兼容老数据
    if (originalData) {
      const originalIcon = originalData.properties?.iconColor as IIcon;
      if (originalIcon) {
        const { disabled, color } = originalIcon;
        treeExpand.isShow = !disabled;
        treeExpand.iconColor = (color as PureColor) ?? SystemsColor.DefaultSelectColor;
        treeExpand.chooseExpandType = DefaultCollapseIconType.Triangle;
        delete originalData.properties.iconColor;
      }
    }
    return {
      properties: {
        layout: {
          direction: 'vertical',
          hidden: true,
        },
        textStyle: {
          color: SystemsColor.DefaultTextColor,
          fontSize: 14,
          fontFamily: 'Microsoft YaHei',
          fontStyle: { underline: false, bold: false, strike: false, italic: false },
          textAlign: TextAlign.left,
        },
        border: { bottom: true, top: true, left: true, right: true },
        stroke: {
          cap: StrokeLineCap.Butt,
          color: SystemsColor.DefaultStrokeColor1,
          disabled: false,
          join: StrokeLineJoin.Miter,
          mode: 'custom',
          thickness: 1,
          position: StrokePosition.inner,
        },
        fill: {
          type: FillType.solid,
          color: DefaultWhiteFillColor,
          disabled: false,
        },
        // 自定义图标
        treeExpand,
        radius: {
          topLeft: 2,
          topRight: 2,
          bottomLeft: 2,
          bottomRight: 2,
          isPercent: false,
          disabled: false,
        },
        selectList: {
          prop: SelectListPropertyName,
          bgColor: SystemsColor.DefaultWhiteFillColor,
          selectedBgColor: SystemsColor.LightGrayColor,
          selectedFontColor: SystemsColor.DefaultTextColor,
          lineHeight: 30,
        },
        canSearch: {
          name: i18n('property.propertyNames.canSearch'),
          prop: 'boolean',
          value: false,
        },

        // 去掉图标数据
        // iconColor: {
        //   name: i18n('property.propertyNames.icon'),
        //   prop: 'fill',
        //   type: FillType.solid,
        //   color: SystemsColor.DefaultIconColor,
        //   disabled: false,
        // },
        placeholder: {
          prop: 'string',
          name: i18n('property.propertyNames.placeholder'),
          value: '',
        },
        placeHolderStyle: {
          prop: 'color',
          name: i18n('property.propertyNames.placeholderColor'),
          value: SystemsColor.GrayColor,
        },
        padding: DefaultPadding,
      },
    };
  },
};
