import { PageOperations } from '../utils/patch';
import INode, { INodeAddPatch } from '../models/node';
import { IArtboardAddPatch } from './artboard';
import { Orientation } from '../../common/models/common';

interface PageIOPatchBase {
  appID: string;
  sessionID: string;
  nodeID: string;
  patchesID?: string;
  deviceID?: string;
  patchOffline?: boolean;
}

export interface PageIOPatches extends PageIOPatchBase {
  patches: PageOperations;
  notifyAll?: boolean;
  patchesID?: string;
  deviceID?: string;
}

export interface AppPatch {
  appID: string;
  sessionID: string;
  device: {
    appType: string;
    name: string;
    size: {
      width: number;
      height: number;
    };
    orientation?: Orientation;
  };
}

export enum PageIOSelectType {
    Component = 'component', //组件
    Symbol = 'symbol', //资源
}

export interface PageIOSelect extends PageIOPatchBase {
  ids: string[];
  type: 'component' | 'symbol';
}

export type UserSelectedComponents = {
  [name: string]: string[];
};

export interface ArtboardComponentSelect {
  appID: string;
  nodeID: string;
  selected: UserSelectedComponents;
}

export enum IOType {
  // 页面组件修改
  PagePatches,
  // 页面组件选中
  PageSelect,

  PageRollback,
  // 节点添加删除重命名位置修改等
  NodeUpdate,
  // 节点删除
  NodeRemove,
  // 协同人列表更新
  CoopersUpdate,
  // 协同人挂掉
  CoopersObituary,
  // 信息
  Message,
  OfflineSync,
  // socket状态
  SocketStatus,
  // network状态
  NetworkStatus,
  // 修改项目尺寸
  AppPatch,
  // 下载离线演示包
  RPOfflineDemo,
  MultiSignIn,
  LoginExpired,
  // 失去项目权限
  LoseProjectPermission,
  SymbolUpdate = 'symbol-update',
}

export type IONotify =
  | {
      type: IOType.PagePatches;
      payload: PageIOPatches;
    }
  | {
      type: IOType.PageSelect;
      payload: PageIOSelect;
    }
  | {
      type: IOType.NodeUpdate;
      payload: INode[];
    }
  | {
      type: IOType.CoopersUpdate;
      payload: SessionInfo[];
    }
  | {
      type: IOType.CoopersObituary;
      payload: {
        appID: string;
        sessionIDs: string[];
      };
    }
  | {
      type: IOType.Message;
      payload: {
        id: number;
        message: string;
        time: Date;
        autoClear: boolean;
        fatal: boolean;
      };
    }
  | {
      type: IOType.SocketStatus;
      payload: {
        type: 'socket-state-change';
        value: boolean;
      };
    }
  | {
      type: IOType.NetworkStatus;
      payload: {
        type: 'network-state-change';
        value: boolean;
      };
    }
  | {
      type: IOType.PageRollback;
      payload: {
        pageID: string;
        appID: string;
        userName: string;
        snapshotName: string;
      };
    }
  | {
      type: IOType.AppPatch;
      payload: AppPatch;
    }
  | {
      type: IOType.OfflineSync;
      payload: {
        isSync: boolean;
      };
    }
  | {
      type: IOType.MultiSignIn;
      payload: {
        code: number;
        message?: string;
      };
    }
  | {
      type: IOType.RPOfflineDemo;
      payload: {
        code: number;
        v: string;
        url?: string;
        message?: string;
      };
    }
  | {
      type: IOType.MultiSignIn;
      payload: {
        code: number;
        message?: string;
      };
    }
  | {
      type: IOType.LoginExpired;
      payload: {
        code: number;
        message?: string;
      };
    }
  | {
      type: IOType.LoseProjectPermission;
      payload: {
        message: {
          code: number;
          content?: string;
          appID?: string;
          teamID?: string;
        };
      };
    }
  | {
      type: IOType.SymbolUpdate;
      payload: {
        libID: string;
        userID: number;
        updatedBy: number;
      };
    };

export type Patches =
  | {
      type: IOType.PagePatches;
      data: PageIOPatches;
    }
  | {
      type: IOType.PageSelect;
      data: PageIOSelect;
    };

export interface IOResponse<T> {
  code: number;
  message?: string;
  payload?: T;
}
/*
0,        成功
1,        失败，未定义的失败
10 ~ 19, 失败，需要重新登录
20 ~ 39，失败，无效的操作，不需要保存patch数据
40 ~ 59, 失败，服务端暂时无法处理数据，需要保留patch数据，后面再提交
*/
export enum IOResponseCode {
  Success = 0,
  Error = 1,
  Unauthorized = 10, //Unauthorized
  ArtboardNotFound = 20,
  SocketEmitTimeOut = 54,
  //NodeNotExist = 21,
  //NotExist = 22,
  //PageNotFound = 23,
}

export type IONotifyHandler = (data: IONotify) => void;

export enum IOEvent {
  // 心跳
  Heartbeat = 'heartbeat',

  // 加入编辑某个项目
  JoinAppCooper = 'join-app-cooper',

  // 结束编辑某个项目
  ExitAppCooper = 'exit-app-cooper',

  // 项目信息修改
  AppPatch = 'app-patch',

  // 协同人列表更新
  CoopersUpdate = 'coopers-update',

  // 更新页面
  PagePatch = 'page-patch',

  // 更新选中信息, 前端选择组件，资源, 尝试锁定
  PageSelect = 'page-select',

  // 同步选中信息
  PageSelectSync = 'page-select-sync',

  // 页面回滚事件
  PageRollback = 'page-rollback',

  // 添加画板
  ArtboardAdd = 'artboard-add',

  // 编辑节点，指进入某个页面的协同
  NodeEdit = 'node-edit',

  // 添加节点
  NodeAdd = 'node-add',

  // 节点编组
  NodeGroup = 'node-group',

  // 节点解组
  NodeUnGroup = 'node-ungroup',

  // 移动节点
  NodeMove = 'node-move',

  // 复制节点
  NodeCopy = 'node-copy',

  // 更新节点
  NodePatch = 'node-patch',

  // 节点更新通知
  NodeUpdate = 'node-update',

  NodeUngroup= 'node-ungroup',

  // 节点删除
  NodeRemove = 'node-remove',

  // 节点恢复
  NodeRecover = 'node-recover',

  // 节点永久删除
  NodeRemovePermanently = 'node-remove-permanently',

  // 删除回收站中所有节点
  NodeRemoveAllPermanently = 'node-remove-all-permanently',

  // 节点隐藏
  NodeHidden = 'node-hidden',

  // 判断页面是否能够被删除
  DetectNodeCanDelete = 'detect-node-can-delete',

  // session 死亡
  Obituary = 'session-obituary',

  RPOfflineDemo = 'downloadRPOfflinePreviewDemoZip',
  // 多点被登录
  MultiSignIn = 'multi-sign-in',
  // 失去项目权限
  LoseProjectPermission = 'notify',
  // symbol资源的更新
  SymbolUpdate = 'symbol-update',
}

export interface SessionInfo {
  id: string;
  name: string;
  nickName: string;
  userID: number;
  avatar: string;
  IP: string;
  UA: string;
  createdAt: Date | string;
}

// 离线操作类型
export type OffLineOperation = (
  | {
      type: OperationType.AddArtboard;
      data: IArtboardAddPatch;
    }
  | {
      type: OperationType.AddNode;
      data: INodeAddPatch;
    }
  | {
      type: OperationType.PushArtboardPatches;
      data: PageIOPatches;
    }
) &
  PatchSyncFlagParameter;

// 处理patch同步相关参数
export type PatchSyncFlagParameter = {
  patchesID?: string;
  deviceID?: string;
  patchIndex?: number;
  randomMask?: string;
};

export enum OperationType {
  PushArtboardPatches = 'PushArtboardPatches',
  AddNode = 'AddNode',
  AddArtboard = 'AddArtboard',
}
