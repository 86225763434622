import { languageManager } from '@/i18n';
import { getFeatureLimit, FUNCTIONAL_FEATURES } from '@/fbs/teamManagement';
import { ExportFileFormat, IExportItemData } from '@/fbs/rp/models/component';
import { Actions as GlobalActions } from '@/store/global/actions';
import { DialogType as GlobalDialogType, IDialogOptions } from '@/store/types';
import store from '@/store';
import { processor } from '../processor';
import { scheduler } from '../task';

/**
 * 权限对话框
 * @param exportType
 * @param dialogType
 */
export function getIsLimitExportAndShowDialog(exportFormats?: IExportItemData[]) {
  const exportType = FUNCTIONAL_FEATURES.ExportPicture;
  let dialogType = GlobalDialogType.upgradeExportPicture;
  if (exportFormats && exportFormats.find((t) => t.fileFormat === ExportFileFormat.Pdf)) {
    dialogType = GlobalDialogType.upgradeExportPDF;
  }
  const team = store.getState().app.teamInfo;
  const isEN = languageManager.isEnVersion;
  const exportLimit = getFeatureLimit(exportType, team, isEN);
  if (!RP_CONFIGS.isPrivateDeployment && Number.isFinite(exportLimit)) {
    store.dispatch(GlobalActions.showDialog(dialogType));
    return true;
  }
  return false;
}

/**
 * 导出图片加载中对话框
 */
export const showExportImageLoadingDialog = (opts?: IDialogOptions) => {
  const dialogType = GlobalDialogType.exportImageLoading;
  store.dispatch(GlobalActions.showDialog(dialogType, opts));
};

/**
 * 导出图片异常
 * @param opts
 */
export const showExportImageErrorDialog = (opts?: IDialogOptions) => {
  const dialogType = GlobalDialogType.exportImageError;
  store.dispatch(GlobalActions.showDialog(dialogType, opts));
};

/**
 * 关闭通用对话框
 */
export const closeGlobalDialog = () => {
  // 关闭执行流程
  processor.terminate();

  const dialogType = GlobalDialogType.none;
  store.dispatch(GlobalActions.showDialog(dialogType));
};

/**
 * 强制终止导出进程
 */
export const closeDialogAndTerminateTask = () => {
  scheduler.reset();
  closeGlobalDialog();
};

/**
 * 判断对话框是否关闭
 */
export const isDialogClosed = (): boolean => {
  return store.getState().global.dialogType === GlobalDialogType.none;
};

/**
 * 选择页面，导出图片
 * @param opts
 */
export const showExportArtboradsByChoosePagesDialog = (opts?: IDialogOptions) => {
  const dialogType = GlobalDialogType.exportArtboardsByChoosePages;
  store.dispatch(GlobalActions.showDialog(dialogType, opts));
};

/**
 * 选择页面，导出pdf
 * @param opts
 */
export const showExportArtboradsAsPdfByChoosePagesDialog = (opts?: IDialogOptions) => {
  const dialogType = GlobalDialogType.exportArtboardsAsPdfByChoosePages;
  store.dispatch(GlobalActions.showDialog(dialogType, opts));
};

/**
 * 导出画板，选择手机壳
 * @param opts
 */
export const showExportArtboradWithShellDialog = (opts?: IDialogOptions) => {
  const dialogType = GlobalDialogType.exportArtboardWithShell;
  store.dispatch(GlobalActions.showDialog(dialogType, opts));
};
