import { ISize, IPosition, IBounds } from '../../common/models/common';
import { ILayout } from './layout';
import { IComponentValue } from './value';
import { IProperties } from './property';
import { IInteraction } from './interactions';
import { Animation } from './animation';
import { Select } from './select';
import { MobileType } from '../utils/preview';

export enum ETextBehaviour {
  Width = 'width',
  Height = 'height',
  Both = 'both',
}

export interface IComponentSize extends ISize {
  lockedRatio?: boolean;
}

export interface IComponentStateData {
  name?: string;
  size?: IComponentSize;
  position?: IPosition;
  opacity?: number;
  autoSize?: boolean;
  value?: IComponentValue | any;
  // 组件的文字属性
  text?: string;
  // 属性
  properties?: IProperties;
  // 旋转
  rotate?: number;
  // 隐藏
  hidden?: boolean;
  _scale?: {
    x?: number;
    y?: number;
  };
}

export interface IComponentState extends IComponentStateData {
  // 是否可以通过继承父组件的方式获得该状态
  inherted?: boolean;
  // 是否启用该状态
  enabled: boolean;
}

// // 注释功能
// export interface IRemark {
//   targetId: string;
//   targetName: string;
//   value: string;
//   group?: IRemarkItem[];
// }

// export interface IRemarkItem {
//   id: string;
//   title: string;
//   content: string;
// }

export type TRealRemark = string;

export interface IRemark {
  createdAt: Date | string;
  value: string;
  num: number;
  position?: {
    x: number;
    y: number;
  };
}

/**
 * 名称使用前缀还是后缀，不要调整顺序
 */
export enum NamingScheme {
  Suffix = 0,
  Prefix = 1,
}
/**
 * 导出规则列表
 */
export enum ExportRatio {
  HalfScale = '0.5x',
  OneScale = '1x',
  TwoScale = '2x',
  ThreeScale = '3x',
}
/**
 * 支持导出的文件格式
 */
export enum ExportFileFormat {
  Png = 'png',
  Jpg = 'jpg',
  Tiff = 'tiff',
  Webp = 'webp',
  Pdf = 'pdf',
  Eps = 'eps',
  SVG = 'svg',
}
export interface IExportItemData {
  exportRatio?: ExportRatio; //默认1x
  namingScheme?: NamingScheme; //默认0
  fileFormat?: ExportFileFormat;
  mobileType?: MobileType; //手机壳 phoneShell
}
export interface IComponentData extends IComponentStateData {
  _id: string;
  readonly type: string;
  // 组件的库信息，basic 组件没有库信息，直接通过 type 去查找
  readonly lib?: {
    // 库的 id
    id: string;
    // 组件在库中的唯一类型标识
    type: string;
  };
  fixContent?: boolean;
  alias?: string;
  size: IComponentSize;
  position: IPosition;
  layout: ILayout;
  locked?: boolean;
  // 是否禁用
  disabled?: boolean;
  // 是否禁止编辑
  disableEditor?: boolean;
  // 属性
  properties: IProperties;
  interaction: IInteraction;
  // 子组件
  components?: IComponentData[];
  // 是否封装住了，当为 true 的时候，表示这是一个封装组件
  sealed?: boolean;
  // 组件状态
  states: {
    [name: string]: IComponentState;
  };
  // 选中信息，表示子组件的选中策略
  select?: Select;
  // 表示当前组件是否选中
  selected?: boolean;
  // 哪些线连接到了该组件，id 数组
  connectors: string[];
  // 数据版本号
  v: number;
  // 动画信息，不提交，仅用于客户端中的演示
  _animation?: Animation;
  _currentState?: string;

  remark?: IRemark;

  // 引用Symbol的组件的子元素与Symbol中组件对应的ID
  masterID?: string;
  // 引用Symbol的容器组件
  symbol?: {
    // Symbol所属的库
    libID: string;
    // Symbol的ID
    masterID: string;
  };
  sizeVersion?: number;
  row?: number;
  column?: number;
  flip?: {
    horizontal: boolean;
    vertical: boolean;
  };
  // 演示时是否浮动到顶层
  float?: boolean;
  // 文本自适应行为，仅富文本组件持有，当值为Width时，等同于之前的autoSize
  textBehaviour?: ETextBehaviour;
  // 演示时是否开启搜索，仅单选下拉框和多选下拉框持有
  search?: boolean;
  // 代码修改版本，仅多选下拉组件使用
  updateVersion?: number;
  // 导出图片设置
  exportImage?: IExportItemData[]
}

export interface ISymbolComponentData extends IComponentData {
  // 是否允许置换
  allowReplace?: boolean;
  updatedAt?: Date | string;
}

// 对应图片分割
export interface IImgSlice {
  name: string;
  url: string;
  bounds: IBounds;
}
