import {
  inflate,
  initBoundsWithPositionAndSize,
  isContainer,
  isContainerPoint,
  isIntersect,
  isIntersetLine,
  offsetBounds,
  offsetPoint,
  scale as scaleBounds,
} from '@utils/boundsUtils';

import { IBounds, IBoundsOffset, ISize } from '@fbs/common/models/common';
import { HorizontalAlign, ILayout, VerticalAlign } from '@fbs/rp/models/layout';
import { IComponentData, IComponentSize } from '@fbs/rp/models/component';
import { ComponentPatches, Operation, Ops } from '@fbs/rp/utils/patch';
import { ILineValue } from '@fbs/rp/models/value';

import { getBoundsInParent, getCompBoundsInArtboard, getCompBoundsInPage } from '@helpers/componentHelper';
import cachedBounds from '@helpers/BoundsCacheHelper';
import { CCanvasPanel } from '@libs/constants';

import { Matrix } from '@utils/matrixUtils';
import { IUICompConstructOptions } from '@/customTypes';

import { UIContainerComponent, UIPanelComponent } from '..';
import ComponentBase from './ComponentBase';

export default abstract class AnchoredComponent extends ComponentBase {
  protected constructor(data: IComponentData, public parent?: AnchoredComponent, options?: IUICompConstructOptions) {
    super(data, parent, options);
  }

  // 判断组件是否在面板中，面板中才能让组件居中
  private get isInCanvasPanel(): boolean {
    if (this.parent) {
      if ((this.parent as UIContainerComponent).isArtboard) {
        return true;
      }
      if (this.parent instanceof UIPanelComponent) {
        return true;
      }
      return this.parent.componentType === CCanvasPanel;
    }
    return false;
  }

  // 是否横向居中
  get isLayoutCenterAtHorizontal(): boolean {
    const layout = this.data.layout;
    return this.isInCanvasPanel && layout.responsive && !layout.auto && layout.horizontal === HorizontalAlign.Center;
  }

  // 是否纵向居中
  get isLayoutMiddleAtVertical(): boolean {
    const layout = this.data.layout;
    return this.isInCanvasPanel && layout.responsive && !layout.auto && layout.vertical === VerticalAlign.Middle;
  }

  // 获取根节点
  getRootNode(): AnchoredComponent {
    let node: AnchoredComponent = this;
    while (node.parent) {
      node = node.parent;
    }
    return node;
  }

  /**
   * 仅限于启用/禁用锚定时使用，修改锚定值时调用的updateBounds
   */
  setAnchors(anchors: ILayout): ComponentPatches {
    let path = this.getCurrentPropertiesPath('anchors');
    const operations: ComponentPatches = {
      do: [
        {
          op: 'replace',
          path,
          value: anchors,
        },
      ],
      undo: [
        {
          op: 'replace',
          path,
          value: this.data.layout,
        },
      ],
    };
    const size = this.size;
    if (size.width !== this.data.size.width) {
      path = `${this.getCurrentSizePath()}/width`;
      operations.do.push(Ops.replace(path, size.width));
      operations.undo.push(Ops.replace(path, this.data.size.width));
    }
    if (size.height !== this.data.size.height) {
      path = `${this.getCurrentSizePath()}/height`;
      operations.do.push(Ops.replace(path, size.height));
      operations.undo.push(Ops.replace(path, this.data.size.height));
    }

    return operations;
  }

  /**
   * 目前仅限于切换是否锁定宽高比时调用，修改具体宽高值时调用updateBounds
   */
  setSize(size: IComponentSize): ComponentPatches {
    const path = this.getCurrentSizePath();
    return {
      do: [
        {
          op: 'replace',
          path: path,
          value: size,
        },
      ],
      undo: [
        {
          op: 'replace',
          path: path,
          value: this.data.size,
        },
      ],
    };
  }

  protected get parentSize(): ISize {
    if (!this.parent) {
      return {
        width: 0,
        height: 0,
      };
    }
    return this.parent.size;
  }

  /**
   * 获取组件相对于父的区域
   */
  getViewBoundsInParent(): IBounds {
    return getBoundsInParent({
      position: this.position,
      size: this.size,
      rotate: this.rotate,
    });
  }

  // // 判断组件是否能够覆盖住后面的组件，如果能，后面的组件可不在画板中渲染了
  // //   1. 不透明的矩形
  // //   2. 不透明的图片
  // //   3. 不透明的视屏
  // // TODO: 待完善
  // // FIXME: 旋转加上后判断进一步变复杂了
  // isFilledRectangle(): boolean {
  //   if (this.componentType === CRect) {
  //     const opacity = this.opacity;
  //     if (opacity !== 100) {
  //       return false;
  //     }
  //
  //     const fill = this.properties.fill;
  //     // @ts-ignore
  //     if (fill && !fill.disabled && fill.color && fill.color.a === 1) {
  //       return true;
  //     }
  //   }
  //   if (this.componentType === CImage) {
  //     const opacity = this.opacity;
  //     return opacity === 100;
  //   }
  //   return false;
  // }

  /**
   *  获取在画板中的可视区域的 bounds
   */
  getViewBoundsInArtboard(parentMatrix?: Matrix, useSelfSize?: boolean): IBounds {
    return getCompBoundsInArtboard(this, parentMatrix, useSelfSize);
  }

  /**
   * 获取在页面中的可视区域的 bounds
   */
  getViewBoundsInPage(parentMatrix?: Matrix): IBounds {
    return getCompBoundsInPage(this, parentMatrix);
  }

  /**
   * 获取组件在屏幕中的边界
   * @param { left: number; top: number } pagePositionInScreen 页面在工作区的位置
   * @param { number } scale 缩放比例
   * @param { Matrix } parentMatrix 当前comp的父矩阵-可选参数
   * @returns { IBounds }
   */
  getViewBoundsInScreen(
    pagePositionInScreen: { left: number; top: number },
    scale: number = 1,
    parentMatrix?: Matrix,
  ): IBounds {
    let bounds = this.getViewBoundsInPage(parentMatrix);
    bounds = scaleBounds(bounds, scale);
    bounds = offsetBounds(bounds, pagePositionInScreen);
    return bounds;
  }

  /**
   * 是否与某个区域相交
   * @param {IBounds} bounds
   * @param {Matrix} parentMatrix
   * @param {"page" | "artboard"} boundsReference
   * @param {boolean} container 是否包含
   * @returns {boolean}
   */
  intersectBounds(bounds: IBounds, parentMatrix: Matrix, boundsReference: 'page' | 'artboard', container?: boolean) {
    let compBounds: IBounds;
    const compId = this.id;
    const cacheBounds = cachedBounds.get(compId);
    if (cacheBounds) {
      compBounds = cacheBounds;
    } else {
      compBounds =
        boundsReference === 'page'
          ? this.getViewBoundsInPage(parentMatrix)
          : this.getViewBoundsInArtboard(parentMatrix);
      cachedBounds.set(compId, compBounds);
    }

    if (container) {
      return isContainer(bounds, compBounds);
    }
    // 选择框经过线条
    if (this.data.type === 'line' && !this.rotate) {
      let { startPoint, endPoint } = this.value as ILineValue;
      startPoint = startPoint || { x: 0, y: 0 };
      endPoint = endPoint || { x: this.data.size.width, y: this.data.size.height };
      const offset = { x: compBounds.left, y: compBounds.top };
      const p1 = offsetPoint(startPoint, offset);
      const p2 = offsetPoint(endPoint, offset);
      return isIntersetLine(bounds, { start: p1, end: p2 });
    }
    // 选择框尺寸为0的组件
    if (compBounds.height === 0) {
      compBounds.height = 1;
      compBounds.top = compBounds.top - 0.5;
      compBounds.bottom = compBounds.bottom + 0.5;
    } else if (compBounds.width === 0) {
      compBounds.width = 1;
      compBounds.left = compBounds.left - 0.5;
      compBounds.right = compBounds.right + 0.5;
    }
    return isIntersect(bounds, compBounds);
  }

  /**
   * 判断事件触发点是否在当前组件内
   * @param screenPoint 事件触发点
   * @param { left: number; top: number } pagePosition 页面在工作区中的位置
   * @param { number } scale 缩放比例
   * @param { Matrix } parentMatrix 当前comp的父矩阵-可选参数
   * @returns { boolean }
   */
  isPointAt(
    screenPoint: { left: number; top: number },
    pagePosition: { left: number; top: number },
    scale: number,
    parentMatrix?: Matrix,
  ) {
    const bounds = this.getViewBoundsInScreen(pagePosition, scale, parentMatrix);
    const { type } = this.data;
    if (type === 'line') {
      let { startPoint, endPoint } = this.value as ILineValue;
      startPoint = startPoint || { x: 0, y: 0 };
      endPoint = endPoint || { x: this.data.size.width, y: this.data.size.height };
      const p1 = { x: startPoint.x * scale + bounds.left, y: startPoint.y * scale + bounds.top };
      const p2 = { x: endPoint.x * scale + bounds.left, y: endPoint.y * scale + bounds.top };
      const b = initBoundsWithPositionAndSize(
        { x: screenPoint.left - 5, y: screenPoint.top - 5 },
        {
          width: 10,
          height: 10,
        },
      );
      return isIntersetLine(b, { start: p1, end: p2 });
    } else {
      // 进行尺寸补偿
      let rect = bounds;
      if (rect.height < scale) {
        rect = inflate(rect, { top: scale / 2, left: 0 });
      }
      if (rect.height <= 1) {
        rect = inflate(rect, { left: 0, top: 0.5 });
      }
      if (rect.width < scale) {
        rect = inflate(rect, { top: 0, left: scale / 2 });
      }
      if (rect.width <= 1) {
        rect = inflate(rect, { left: 0.5, top: 0 });
      }
      return isContainerPoint(rect, screenPoint);
    }
  }

  /**
   * 位置发生移动时的数据更新操作
   */
  move(bound: IBoundsOffset): ComponentPatches {
    const doOps: Operation[] = [];
    const undoOps: Operation[] = [];
    const { left, top } = bound;
    const position = this.position;
    undoOps.push(Ops.replace('/position/x', position.x));
    undoOps.push(Ops.replace('/position/y', position.y));

    doOps.push(Ops.replace('/position/x', position.x + left));
    doOps.push(Ops.replace('/position/y', position.y + top));
    return {
      do: doOps,
      undo: undoOps,
    };
  }
}
