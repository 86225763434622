import { measureTextSize } from '@utils/textUtils';

import { IComponentData } from '@fbs/rp/models/component';
import { ArtboardPatches } from '@fbs/rp/utils/patch';
import IRadius, { RadiusPropertyName } from '@fbs/rp/models/properties/radius';
import { IPosition } from '@fbs/common/models/common';
import i18n from '@i18n';

import { StyleHelper } from '@helpers/styleHelper';
import { UIComponent, UIContainerComponent } from '@editor/comps';
import { PredefinedStates } from '@consts/state';
import { SpriteThumb } from '@consts/spriteIcons';
import { getDefaultDataFromTemplate } from '@/libs/helper';

import { IComponentItem } from '../../types';
import { SizeMode, MoveMode } from '../../enum';

interface IConfig {
  type: string;
  name: string;
  thumb: {
    spriteIconClass?: string;
    dragPosition?: IPosition;
    x1?: string;
    x2?: string;
    drag_x1?: string;
    drag_x2?: string;
  };
  radius: number;
  radiusIsApplyToAllItem?: boolean;
  values: string[];
  gap?: number;
  itemSize: {
    width: number;
    height: number;
  };
}

function makeData(cfg: IConfig): IComponentItem {
  const { type, name, thumb, values, gap, itemSize, radiusIsApplyToAllItem } = cfg;

  const parser = StyleHelper.initCSSStyleParser({
    textStyle: {
      fontSize: 14,
      fontFamily: 'Microsoft YaHei',
    },
  });
  const { width: textWidth, height: textHeight } = measureTextSize(
    parser.getTextStyle(),
    `${i18n('resource.componentsText.optionText')} 2`,
  );
  return {
    type,
    name,
    thumb,
    isList: true,
    isTextComp: true, // 数据填充文本-判断需要
    predefinedStates: [PredefinedStates.disabled],
    constraint: {
      text: {
        resize: SizeMode.none,
        move: MoveMode.neither,
      },
    },
    item: `{
    type: @@C.CanvasPanel,
    position:{x: {2}, y: 0},
    layout:{
      vertical: @@VerticalAlign.TopAndBottom,
      horizontal: @@HorizontalAlign.Auto,
      fixedWidth: true,
      fixedHeight: true,
      auto: true,
      responsive: true,
    },
    size: {width: ${itemSize.width}, height: ${itemSize.height}},
    selected:{1},
    properties:{
      fill: { ref: '@properties.background' },
    },
    states: {
      @@PredefinedStates.checked: {
        enabled: true,
        properties: {
          fill: {
            ref: '@properties.fill2',
          },
        },
      },
    },     
    components:[
      {
        type: @@C.PureText,
        alias:'text',
        autoSize: true,
        position:{
          x: ${Math.round(itemSize.width - textWidth) / 2}, 
          y: ${Math.round((itemSize.height - textHeight) / 2)},
        },
        size: {width: ${textWidth}, height: ${textHeight}},
        layout: {
          vertical: 'middle',
          horizontal: 'center',
          fixedWidth: true,
          fixedHeight: true,
          auto: false,
          responsive: true,
        },
        properties:{
          textStyle: { ref: '@properties.textStyle' },
        },
        states: {
            @@PredefinedStates.checked: {
              enabled: true,
              properties: {
                textStyle: { ref: '@properties.textStyle2' },
              },
            },
          },
        value:'{0}',
      }]
    }`,
    template: `{
      type: @@C.ListLayoutPanel,
      size: {width: ${itemSize.width * values.length + (gap || 0) * (values.length - 1)}, height: ${itemSize.height}},
      layout:{
        vertical: 'middle',
        horizontal: 'left',
        fixedWidth: true,
        fixedHeight: true,
        auto: true,
        responsive: true,
      },
      properties: {
        layout: {
          hidden: true,
          direction: 'horizontal',
          verticalAlign: 'middle',
          horizontalGap: ${gap}
        },
        cell:{
          hidden: true,
          ratioWidth: true,
        },
        background: {
          prop:'fill',
          type: @@FillType.solid,
          color: @@SystemColors.DefaultWhiteFillColor,
          disabled: false,
        },
        fill2: {
          prop: 'fill',
          name: '${i18n('property.propertyNames.checkedBgcolor')}',
          type: @@FillType.solid,
          color: @@SystemColors.DefaultSelectColor,
          disabled: false,
        },
        separator: {
          thickness: 1,
          color:@@SystemColors.DefaultSelectColor,
          disabled: false,
        },
        textStyle: {
          color: @@SystemColors.DefaultTextColor,
          fontSize: 14,
          textAlign:@@TextAlign.center,
          fontFamily: 'Microsoft YaHei',
          fontStyle: { underline: false, bold: false, strike: false, italic: false },
        },
        textStyle2: {
          color: @@SystemColors.WhiteColor,
          textAlign: @@TextAlign.center,
          fontSize: 14,
          fontFamily: 'Microsoft YaHei',
          fontStyle: { underline: false, bold: false, strike: false, italic: false },
          prop: 'textStyle',
          name: '${i18n('property.propertyNames.checkedText')}',
        },
        radius:{
          isPercent: true,
          topLeft: 100,
          topRight: 100,
          bottomRight: 100,
          bottomLeft: 100,
          disabled: false,
        },
        stroke:{
          thickness: 1,
          color:@@SystemColors.DefaultStrokeColor1,
          disabled: false,
        }
      },
      states:{
        disabled:{
          enabled: false,
          opacity: 30,
          properties:{
            
          },
        },
      },
      components:[
      ${values
        .map((value, i) => {
          const itemLeft = cfg.itemSize.width * i;
          if (radiusIsApplyToAllItem) {
            return `@@ITEM-${value}|${i === 0}|${itemLeft},`;
          } else {
            if (i === 0) {
              return `@@ITEM-${value}|true|${itemLeft},`;
            } else if (i === values.length - 1) {
              return `@@ITEM-${value}|false|${itemLeft},`;
            } else {
              return `@@ITEM-${value}|false|${itemLeft},`;
            }
          }
        })
        .join('\n')}
      ],
      sealed: true,
      select: {
        target: 'child',
        enabled: true,
        maxCount: 1,
        minCount: 0,
        reversible: false,
        autoUnselect: true,
      },
    }`,
    itemArgs: [
      { type: 'string', value: values[0] },
      { type: 'boolean', value: false },
      { type: 'number', value: 0 },
    ],
  };
}

function onAddChildren(container: UIContainerComponent, comps: IComponentData[]): ArtboardPatches | null {
  // 兼容性处理，新的数据结构圆角属性包含在组件本身的属性上了
  if (container.properties.radius) {
    return null;
  }
  const lastCompIndex = container.components.length - 1;
  const lastComp = container.components[lastCompIndex];
  const radius = lastComp.properties.radius!;
  const middleCompRadius: IRadius = {
    bottomLeft: 0,
    bottomRight: 0,
    topLeft: 0,
    topRight: 0,
    isPercent: false,
  };
  const lastCompRadius: IRadius = {
    bottomLeft: 0,
    bottomRight: 5,
    topLeft: 0,
    topRight: 5,
    isPercent: false,
  };
  comps.forEach((comp, index) => {
    const compIsInMiddle = index < comps.length - 1;
    comp.properties.radius = compIsInMiddle ? middleCompRadius : lastCompRadius;
  });
  return lastComp.setProperty(RadiusPropertyName, { ...radius, topRight: 0, bottomRight: 0 });
}

function onRemoveChildren(container: UIContainerComponent, removeIDs: string[]): ArtboardPatches | null {
  // 兼容性处理，新的数据结构中圆角包含在组件本身上了
  if (container.properties.radius) {
    return null;
  }
  const first = container.components[0];
  const last = container.components[container.components.length - 1];
  const others = container.components.filter((comp) => !removeIDs.includes(comp.id));
  let newFirst: UIComponent | undefined = undefined;
  let newLast: UIComponent | undefined = undefined;
  if (removeIDs.includes(first.id)) {
    newFirst = others[0];
  }
  if (removeIDs.includes(last.id)) {
    newLast = others[others.length - 1];
  }
  let patches: ArtboardPatches | null = null;
  const radius = first.properties.radius!;
  const { topLeft, bottomLeft } = radius;
  const maxRadius = Math.max(topLeft || 0, bottomLeft || 0);
  if (others.length === 1) {
    if (newFirst) {
      patches = newFirst.setProperty(RadiusPropertyName, {
        ...radius,
        topLeft: maxRadius,
        topRight: maxRadius,
        bottomRight: maxRadius,
        bottomLeft: maxRadius,
      });
    }
    if (newLast) {
      patches = newLast.setProperty(RadiusPropertyName, {
        ...radius,
        topLeft: maxRadius,
        topRight: maxRadius,
        bottomRight: maxRadius,
        bottomLeft: maxRadius,
      });
    }
  } else {
    if (newFirst || newLast) {
      patches = { do: {}, undo: {} };
      if (newFirst) {
        const firstPatches = newFirst.setProperty(RadiusPropertyName, { ...radius });
        patches.do[newFirst.id] = firstPatches.do[newFirst.id];
        patches.undo[newFirst.id] = firstPatches.undo[newFirst.id];
      }
      if (newLast) {
        const newValue = { ...last.properties.radius! };
        const lastPatches = newLast.setProperty(RadiusPropertyName, newValue);
        if (lastPatches.do[newLast.id]) {
          patches.do[newLast.id] = lastPatches.do[newLast.id];
          patches.undo[newLast.id] = lastPatches.undo[newLast.id];
        }
      }
    }
  }
  return patches;
}

let segmentsValues: string[] = [];

export const makeSegments = () => {
  return {
    ...makeData({
      type: 'segments-control',
      name: i18n('resource.components.segmentsControl'),
      values: segmentsValues.length
        ? segmentsValues
        : [
            `${i18n('resource.componentsText.optionText')} 1`,
            `${i18n('resource.componentsText.optionText')} 2`,
            `${i18n('resource.componentsText.optionText')} 3`,
          ],
      thumb: {
        spriteIconClass: SpriteThumb.SegmentControls.className,
        dragPosition: SpriteThumb.SegmentControls.position,
      },
      radius: 5,
      gap: 0,
      itemSize: {
        width: 75,
        height: 30,
      },
    }),
    editor: {
      onAddChildren,
      onRemoveChildren,
    },
    getDefaultData() {
      return {
        properties: defaultData?.properties,
      };
    },
  };
};

export const AIChangeDefaultQuoteVariable = (values: string[] = []) => {
  segmentsValues = values;
  return {
    release() {
      segmentsValues = [];
    },
  };
};

export const SegmentsCfg: IComponentItem = makeSegments();

const defaultData = getDefaultDataFromTemplate(SegmentsCfg);
