import { IPoint } from '@/fbs/common/models/common';
import { IComponentData } from '@/fbs/rp/models/component';
import { PagePatches, reversePagePatches } from '@/fbs/rp/utils/patch';

import { getInnerOrAroundConnect, removeContactBetweenStickNoteAndComp } from '@/helpers/pathFinderHelper';
import { mergePatches } from '@helpers/patchHelper';

import { UIFragment, UIComponent } from '../comps';
import CoreEditor from '../core';
import { BaseCommand } from './Command';

export class MoveSelectedToOtherArtboardCommand extends BaseCommand {
  constructor(private editor: CoreEditor, private offset: IPoint, private from: string, private to: string) {
    super();
  }

  /**
   * 为 undefined 时，说明 action 中没有执行有效的操作
   */
  actionInfo: ReturnType<MoveSelectedToOtherArtboardCommand['action']>;

  action() {
    /**
     * 1、从原画板删除组件
     * 2、在目标板指定位置添加组件
     * 3、激活目标画板
     */
    const editor = this.editor;
    const { fromArtboard, toArtboard } = this.getFromAndToArtboard();

    if (fromArtboard && toArtboard) {
      const selectedComponentList = editor.allSelectedUnLockedComps;
      const { patches: removePatches } = fromArtboard.removeComponents(selectedComponentList);
      //删除组件时， 没有包含流程线，在内部做了处理去判断删除，添加组件时应该加上这一部分
      const selectIds = selectedComponentList.map((comp) => comp.id);
      const { innerConnectComps } = getInnerOrAroundConnect(editor.activeContainer, selectIds);
      //获取流程线偏移后的正确位置数据
      const connectorAfterDiff = innerConnectComps.map((comp) => {
        return comp.modifyConnectPointByDiff(this.offset);
      });
      const selectCompList = selectedComponentList.filter((comp) => !comp.isConnector);
      const compsData = selectCompList.map((comp) => {
        return this.mapCompPosition(comp, toArtboard);
      });
      const shouldSelectComponents = [...compsData, ...connectorAfterDiff];

      const { patches: addPatches } = toArtboard.addComponents(shouldSelectComponents);
      const patches: PagePatches = Object.keys(addPatches).reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: addPatches[curr],
        };
      }, removePatches);

      // 便签条关联组件，移到新面板，需要切断和便签条的联系
      const notePatches = removeContactBetweenStickNoteAndComp(fromArtboard, selectCompList);
      notePatches && mergePatches(patches[fromArtboard.artboardID], notePatches);

      editor.new_update(patches);
      editor.setActiveArtboard(toArtboard);
      const ids = shouldSelectComponents.map((comp) => comp._id);
      const comps = toArtboard.components.filter((comp) => {
        return ids.includes(comp.id);
      });
      editor.select(comps, false);

      const actionInfo = {
        patch: patches,
        active: {
          from: this.from,
          to: this.to,
        },
        select: ids,
      };
      return actionInfo;
    }
  }

  execute() {
    const actionInfo = this.action();
    if (!actionInfo) {
      return;
    }

    this.actionInfo = actionInfo;
    this.editor.pushUndoStack(this);
    this.editor.clearRedoStack();
  }

  undo() {
    if (!this.actionInfo) {
      return;
    }

    const {
      actionInfo: { patch, active, select },
      editor,
    } = this;

    editor.pushRedoStack(this);

    const undoPatches = reversePagePatches(patch);
    editor.new_update(undoPatches);

    const toArtboard = editor.doc.getArtboardByID(active.from)!;
    editor.setActiveArtboard(toArtboard);

    const comps = toArtboard.components.filter((comp) => {
      return select.includes(comp.id);
    });
    editor.select(comps, false);
  }

  redo() {
    if (!this.actionInfo) {
      return;
    }

    const {
      actionInfo: { patch, active, select },
      editor,
    } = this;

    editor.pushUndoStack(this);

    editor.new_update(patch);

    const toArtboard = editor.doc.getArtboardByID(active.to)!;
    editor.setActiveArtboard(toArtboard);

    const comps = toArtboard.components.filter((comp) => {
      return select.includes(comp.id);
    });
    editor.select(comps, false);
  }

  getFromAndToArtboard() {
    const artboards = this.editor.doc.artboardsFragments;
    let fromArtboard: UIFragment | undefined;
    let toArtboard: UIFragment | undefined;
    for (let i = 0, c = artboards.length; i < c; i++) {
      const item = artboards[i];
      if (item.artboardID === this.from) {
        fromArtboard = item;
      }
      if (item.artboardID === this.to) {
        toArtboard = item;
      }
      if (fromArtboard && toArtboard) {
        break;
      }
    }
    return { fromArtboard, toArtboard };
  }

  mapCompPosition(comp: UIComponent, toArtboard: UIFragment): IComponentData {
    const r = { ...comp.toJSON(), _currentState: undefined };
    const { x, y } = comp.currentState.position || comp.$data.position;
    r.position = {
      x: x + this.offset.x,
      y: y + this.offset.y,
    };
    if (comp.isLayoutMiddleAtVertical) {
      r.position.y = toArtboard!.size.height / 2 - comp.size.height / 2;
    }
    if (comp.isLayoutCenterAtHorizontal) {
      r.position.x = toArtboard!.size.width / 2 - comp.size.width / 2;
    }
    return r;
  }
}
