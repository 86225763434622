import { UIComponent } from '@/editor/comps';
import { CHotArea, CConnector } from '@/libs/constants';

/**
 * 导出选择组件 过滤器
 * 滤掉热区 链接线
 * 保留 隐藏 透明 组件
 * @param comp
 */
export const selectComponentFilter = (comp: UIComponent) => {
  return !(comp.lib?.type === CHotArea || CConnector === comp.type); //comp.hidden || comp.opacity === 0 ||
};

// 渲染组件 成空白 过滤器
export const renderBlankFilter = (comp: UIComponent): boolean => {
  if (!comp) {
    return true;
  }
  return comp.hidden || comp.opacity === 0 || comp.lib?.type === CHotArea;
};
