import * as React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';

import * as GraphicsUtils from '@utils/graphicsUtils';

import { StyleHelper } from '@helpers/styleHelper';

import * as SystemsColor from '@consts/colors';
import { Ops } from '@fbs/rp/utils/patch';
import IInputModel, { InputModel } from '@fbs/rp/models/properties/inputModel';
import { PureColor } from '@fbs/rp/models/properties/color';
import { IComponentValue } from '@fbs/rp/models/value';
import { ISize } from '@/fbs/common/models/common';
import { IProperties } from '@/fbs/rp/models/property';

import { IComponentProps } from '../../types';
import InputEditor, { IInputEditorProps } from '../common/InputEditor';
import WithSelectTextRange from '../common/WithSelectTextRange';
import Background from '../common/Background';

import './index.scss';

export interface ITextInputState {
  style: React.CSSProperties;
  placeHolderStyle: React.CSSProperties;
  value: string;
  isInputFoucs?: boolean;
}

export const defaultInputSize = {
  phone: {
    width: 150,
    height: 48,
  },
  web: {
    width: 200,
    height: 30,
  },
};

const InputEditorTextRange = WithSelectTextRange<IInputEditorProps>(InputEditor);

// Pure
class TextInput extends React.Component<IComponentProps, ITextInputState> {
  private inputRef: React.RefObject<HTMLInputElement> = React.createRef();
  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      style: this.doParsePropertiesToStyle(props),
      value: props.comp.value as string,
      placeHolderStyle: this.doParsePlaceHolderStyle(props),
    };

    this.param = this.getParams(props);
  }

  private param: {
    size: ISize;
    properties: IProperties;
    value?: IComponentValue;
    opacity: number;
    isPreview?: boolean;
    globalScale: number;
    transition: string;
    valueEditing?: boolean;
    version: string;
  } & { [key: string]: any };

  private getParams(props: IComponentProps) {
    const {
      comp: { size, properties, value, opacity, version },
      isPreview,
      globalScale,
      valueEditing,
    } = props;
    const transition = props.comp.getTransition();

    return {
      size: _.cloneDeep(size),
      properties: _.cloneDeep(properties),
      value: _.cloneDeep(value),
      opacity,
      isPreview,
      globalScale,
      transition,
      valueEditing,
      version,
    };
  }

  shouldComponentUpdate(nextProps: IComponentProps) {
    const newParam: { [key: string]: any } = this.getParams(nextProps);

    let flag = false;
    Object.keys(this.param).forEach((key) => {
      if (!_.isEqual(newParam[key], this.param[key])) {
        flag = true;
        this.param[key] = _.cloneDeep(newParam[key]);
      }
    });

    return flag;
  }

  UNSAFE_componentWillReceiveProps(nextProps: IComponentProps) {
    this.setState({
      style: this.doParsePropertiesToStyle(nextProps),
      value: nextProps.comp.value as string,
      placeHolderStyle: this.doParsePlaceHolderStyle(nextProps),
    });
  }

  private validateValue = (value: string): string => {
    const { value: data } = this.state;
    const numPattern = /^-?\d*\.?\d*$/;
    const inputType = (this.props.comp.properties.inputModel as IInputModel).value;
    if (inputType === InputModel.numeric && !numPattern.test(value)) {
      if (!numPattern.test(data)) {
        return '';
      }
      return data;
    }
    return value;
  };

  handleInputChange = (e: React.ChangeEvent) => {
    const { onInnerAction, comp, isPreview } = this.props;
    if (isPreview) {
      const value = this.validateValue((e.target as HTMLInputElement).value);
      this.setState({ value }, () => {
        onInnerAction &&
          onInnerAction([
            {
              comp,
              actions: [Ops.replace('/value', this.state.value)],
            },
          ]);
      });
    }
  };

  doParsePropertiesToStyle = (props: IComponentProps): React.CSSProperties => {
    const { size, properties, opacity } = props.comp;
    const parser = StyleHelper.initCSSStyleParser(properties);

    return {
      display: 'flex',
      ...parser.getRadiusStyle(size),
      ...parser.getShadowStyle(),
      ...parser.getTextStyle(),
      opacity: _.isUndefined(opacity) ? 1 : opacity / 100,
      ...size,
    };
  };

  doParsePlaceHolderStyle = (props: IComponentProps): React.CSSProperties => {
    const {
      properties: { placeHolderStyle },
    } = props.comp;
    const defaultColor: PureColor =
      placeHolderStyle && placeHolderStyle.value ? (placeHolderStyle.value as PureColor) : SystemsColor.GrayColor;
    const color = GraphicsUtils.parseColorToString(defaultColor);
    return {
      color,
      // lineHeight: `${height}px`,
    };
  };

  private doSubmit = (value: string) => {
    const { onValueEdited, comp } = this.props;
    const selection = document.getSelection();
    if (selection) {
      selection.empty();
    }
    onValueEdited && onValueEdited(comp, value);
  };

  // 内边距
  getInputPadding() {
    const { comp } = this.props;
    const { properties, size } = comp;
    const { padding, textStyle, textFormat } = properties;
    if (!padding || padding.disabled) {
      return {};
    }
    const fontSize = (textFormat || textStyle)?.fontSize || 14;
    const ratio = Math.min(1, fontSize / 12);
    const { left, right, top, bottom } = padding;
    // const borderWidth = properties.stroke ? properties.stroke.thickness || 1 : 0;
    // const lineHeight = `${size.height - borderWidth * 2}px`;

    const topValue = ((top || 0) - (bottom || 0)) / ratio;

    let topTransfer = {};
    if (topValue > 0) {
      topTransfer = {
        paddingTop: topValue,
      };
    } else if (topValue < 0) {
      topTransfer = {
        marginTop: Math.max(-(size.height - fontSize) / 2, topValue),
      };
    }

    return {
      ...topTransfer,
      paddingRight: (right || 0) / ratio,
      paddingLeft: (left || 0) / ratio,
    };
  }

  private handleOnFocus = () => {
    this.setState({ isInputFoucs: true });
  };

  private handleOnBlur = () => {
    this.setState({ isInputFoucs: false });
  };

  renderInput() {
    const { isPreview, comp, valueEditing } = this.props;
    const { value, placeHolderStyle, style } = this.state;
    const { size, properties } = comp;
    const { placeholder, inputModel, stroke, textFormat, textStyle } = properties;
    const fontSize = (textFormat || textStyle)?.fontSize || 14;
    const ratio = Math.min(1, fontSize / 12);
    const borderWidth = stroke ? stroke.thickness || 1 : 0;
    const width = size.width / ratio - borderWidth * 2;
    const height = size.height / ratio - borderWidth * 2;
    const padding = this.getInputPadding();
    const transform = `scale(${ratio})`;

    const _style = {
      transform,
      marginTop: borderWidth,
      marginBottom: borderWidth,
      height,
      left: borderWidth,
      top: borderWidth,
      ...padding,
    };

    const baseStyle: React.CSSProperties = {
      fontStyle: style.fontStyle,
      textDecoration: style.textDecoration,
      textDecorationLine: style.textDecorationLine,
      transformOrigin: 'top left',
      letterSpacing: 'inherit',
      minWidth: width,
      maxWidth: width,
      ..._style,
    };

    if (!isPreview && valueEditing) {
      return (
        <InputEditorTextRange
          value={value}
          className=""
          inputModel={inputModel!.value}
          width={width}
          style={{
            ...baseStyle,
          }}
          onChange={this.doSubmit}
        />
      );
    }
    return (
      <>
        <input
          ref={this.inputRef}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
          readOnly={!isPreview}
          style={{
            ...baseStyle,
            transition: comp.getTransition(),
            userSelect: isPreview ? (comp.disabled ? 'none' : 'all') : 'none',
            pointerEvents: isPreview ? (comp.disabled ? 'none' : 'all') : 'none',
            width: size.width / ratio,
          }}
          value={value}
          type={inputModel!.value === InputModel.password ? 'password' : 'text'}
          onChange={this.handleInputChange}
        />

        {!value && (
          <p
            className="input-placeholder"
            style={{
              color: placeHolderStyle.color,
              width: size.width / ratio - borderWidth * 2,
              // 模拟input
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 8,
              paddingRight: 8,
              ..._style,
            }}
          >
            <span className="text">{placeholder ? (placeholder.value as string) : ''}</span>
          </p>
        )}
      </>
    );
  }

  render() {
    const {
      isPreview,
      valueEditing,
      comp: { size, properties },
    } = this.props;
    return (
      <div
        className={classnames('lib-comp-input', { preview: isPreview, editing: valueEditing })}
        style={this.state.style}
      >
        <Background size={size} properties={properties} transition={this.props.comp.getTransition()} />
        <div className={classnames('atom-comp-text-input-context', { editing: valueEditing })}>
          {this.renderInput()}
        </div>
      </div>
    );
  }
}

export default TextInput;
