import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { getShapeData, DefaultFlowShapeSize, flowBase, getShapePropertiesData } from './shapeDataFragment';

// 推迟
export default {
  ...flowBase,
  name: i18n('resource.flow.delay'),
  type: 'delay',
  thumb: {
    spriteIconClass: SpriteThumb.Delay.className,
    dragPosition: SpriteThumb.Delay.position,
  },
  template: getShapeData(i18n('resource.flow.delay'), DefaultFlowShapeSize.size100_60, {
    data: [
      {
        point: {
          x: 60,
          y: 0,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 100,
          y: 30,
        },
        handleIn: {
          x: 0,
          y: -30,
        },
        handleOut: {
          x: 0,
          y: 30,
        },
      },
      {
        point: {
          x: 60,
          y: 60,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 0,
          y: 60,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 0,
          y: 0,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
    ],
    closed: true,
  }),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
