import CoreEditor from '@editor/core';

type onPopStackCallback = (
  stackManager: CoreEditorStackManager,
  currentEditor: CoreEditor | undefined,
  prevEditor: CoreEditor | undefined,
) => void;

export interface ICoreEditorStackManager {
  coreEditorStack: CoreEditor[];
  stacksLength: number;
  currentEditor: CoreEditor | undefined;
  prevEditor: CoreEditor | undefined;
  push: (editor: CoreEditor) => void;
  pop: (callback: onPopStackCallback) => void;
}

class CoreEditorStackManager implements ICoreEditorStackManager {
  private editorStack: CoreEditor[] = [];

  static manager: CoreEditorStackManager;

  constructor() {
    CoreEditorStackManager.manager = this;
  }

  get stacksLength() {
    return this.coreEditorStack.length;
  }

  get currentEditor(): CoreEditor | undefined {
    const len = this.coreEditorStack.length;
    const coreEditor = this.coreEditorStack[len - 1];
    return coreEditor;
  }

  get prevEditor(): CoreEditor | undefined {
    const len = this.coreEditorStack.length;
    const coreEditor = this.coreEditorStack[len - 2];
    return coreEditor;
  }

  get coreEditorStack(): CoreEditor[] {
    return this.editorStack;
  }

  public push(editor: CoreEditor) {
    this.coreEditorStack.push(editor);
  }

  public pop(callback: onPopStackCallback) {
    const currentEditor = this.coreEditorStack.pop();
    const prevEditor = this.currentEditor;
    callback(this, currentEditor, prevEditor);
  }
}

export default CoreEditorStackManager;
