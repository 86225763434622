import i18n, { languageManager } from '@i18n';
import { Orientation, revertSize } from '@/fbs/common/models/common';

export interface DeviceInfo {
  id: number;
  name: string;
  size: {
    width: number;
    height: number;
  };
}

/**
 * 自定义
 */
export const CustomDevices: Array<DeviceInfo> = [
  {
    id: 1,
    name: i18n('workspace.statusBar.custom'),
    size: {
      width: 1000,
      height: 1000,
    },
  },
];

export const WebDevices: Array<DeviceInfo> = [
  {
    id: 4,
    name: 'Web 1920',
    size: {
      width: 1920,
      height: 1080,
    },
  },
  {
    id: 1,
    name: 'Web 1024',
    size: {
      width: 1024,
      height: 768,
    },
  },
  {
    id: 2,
    name: 'Web 1280',
    size: {
      width: 1280,
      height: 800,
    },
  },
  {
    id: 3,
    name: 'Web 1440',
    size: {
      width: 1440,
      height: 900,
    },
  },
];

// 竖屏
export const VehicleDevices: Array<DeviceInfo> = [
  {
    id: 1,
    name: '车载中控',
    size: {
      width: 720,
      height: 1280,
    },
  },
];
export const WatchDevices: Array<DeviceInfo> = [
  {
    id: 1,
    name: 'Apple Watch 38mm',
    size: {
      width: 136,
      height: 170,
    },
  },
  {
    id: 2,
    name: 'Apple Watch 40mm',
    size: {
      width: 162,
      height: 197,
    },
  },
  {
    id: 3,
    name: 'Apple Watch 42mm',
    size: {
      width: 156,
      height: 195,
    },
  },
  {
    id: 4,
    name: 'Apple Watch 44mm',
    size: {
      width: 184,
      height: 224,
    },
  },
  {
    id: 5,
    name: 'Apple Watch 45mm',
    size: {
      width: 198,
      height: 242,
    },
  },
  {
    id: 6,
    name: 'Apple Watch 49mm',
    size: {
      width: 205,
      height: 251,
    },
  },
];

/**
 * 竖屏：pad 信息
 */
export const PadDevices: Array<DeviceInfo> = [
  {
    id: 4,
    name: "iPad Pro 12.9''",
    size: {
      width: 1024,
      height: 1366,
    },
  },
  {
    id: 1,
    name: 'iPad',
    size: {
      width: 768,
      height: 1024,
    },
  },
  {
    id: 2,
    name: "iPad Pro 10.5''",
    size: {
      width: 834,
      height: 1112,
    },
  },
  {
    id: 3,
    name: "iPad Pro 11''",
    size: {
      width: 834,
      height: 1194,
    },
  },
  {
    id: 5,
    name: 'Nexus 9',
    size: {
      width: 768,
      height: 1024,
    },
  },
  // TODO surface 有竖屏模式吗
  {
    id: 6,
    name: 'Surface Pro 3',
    size: {
      width: 960,
      height: 1440,
    },
  },
  {
    id: 7,
    name: 'Surface Pro 4/5/6',
    size: {
      width: 912,
      height: 1368,
    },
  },
];


/**
 * 横屏：pad 信息
 */
export const LandscapePadDevices: Array<DeviceInfo> = PadDevices.map((deviceInfo) => {
  return { ...deviceInfo, size: revertSize(deviceInfo.size) };
});

export const PadDeviceInfoRecord: Record<Orientation, Array<DeviceInfo>> = {
  portrait: PadDevices,
  landscape: LandscapePadDevices,
};

/**
 * 竖屏：phone 信息
 */
export const PhoneDevices: Array<DeviceInfo> = [
  {
    id: 1,
    name: 'iPhone 14',
    size: {
      width: 390,
      height: 844,
    },
  },
  {
    id: 8,
    name: 'iPhone 14 Pro',
    size: {
      width: 393,
      height: 852,
    },
  },
  {
    id: 0,
    name: 'iPhone 14 Plus',
    size: {
      width: 428,
      height: 926,
    },
  },
  {
    id: 9,
    name: 'iPhone 14 Pro Max',
    size: {
      width: 430,
      height: 932,
    },
  },
  {
    id: 2,
    name: 'iPhone 13 mini / X',
    size: {
      width: 375,
      height: 812,
    },
  },
  {
    id: 3,
    name: 'iPhone 11 Pro Max',
    size: {
      width: 414,
      height: 896,
    },
  },
  {
    id: 5,
    name: 'iPhone 8',
    size: {
      width: 375,
      height: 667,
    },
  },
  {
    id: 4,
    name: 'iPhone 8 Plus',
    size: {
      width: 414,
      height: 736,
    },
  },
  {
    id: 6,
    name: 'iPhone 8 SE',
    size: {
      width: 320,
      height: 568,
    },
  },
  {
    id: 7,
    name: languageManager.isEnLanguage ? 'Huawei P30' : '华为 P30',
    size: {
      width: 360,
      height: 780,
    },
  },
];

/**
 * 横屏：phone 信息
 */
export const LandscapePhoneDevices: Array<DeviceInfo> = PhoneDevices.map((deviceInfo) => {
  return { ...deviceInfo, size: revertSize(deviceInfo.size) };
});

export const PhoneDeviceInfoRecord: Record<Orientation, Array<DeviceInfo>> = {
  portrait: PhoneDevices,
  landscape: LandscapePhoneDevices,
};

/**
 * 横屏：车载
 */
export const LandscapeVehicleDevices: Array<DeviceInfo> = VehicleDevices.map((deviceInfo) => {
  return { ...deviceInfo, size: revertSize(deviceInfo.size) };
});
export const VehicleDeviceInfoRecord: Record<Orientation, Array<DeviceInfo>> = {
  portrait: VehicleDevices,
  landscape: LandscapeVehicleDevices,
};

export type ProjectType = 'phone' | 'pad' | 'web' | 'watch' | 'vehicle';

export const DeviceInfoRecord: Record<ProjectType, Record<Orientation, Array<DeviceInfo>>> = {
  phone: PhoneDeviceInfoRecord,
  pad: PadDeviceInfoRecord,
  // web 目前没有横竖屏切换
  web: {
    portrait: WebDevices,
    landscape: WebDevices,
  },
  watch: {
    portrait: WatchDevices,
    landscape: WatchDevices,
  },
  vehicle: VehicleDeviceInfoRecord,
};
