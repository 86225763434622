import { Dispatch } from 'redux';

import IColor from '@fbs/rp/models/ds/color';
import ITypography, { ITypographyValue } from '@fbs/rp/models/ds/typography';
import IComponent, { IRelation } from '@fbs/rp/models/ds/component';
import { ILibBase, ILibF, IResourceItem, ResourceType } from '@fbs/rp/models/ds/lib';
import { PureColor } from '@fbs/rp/models/properties/color';
import { IComponentData } from '@fbs/rp/models/component';
import { CustomError } from '@fbs/common/models/error';
import { IDSGroup } from '@fbs/rp/models/ds/group';

import i18n from '@/i18n';

import apis from '@apis';

import appOptions from '@helpers/appOptions';

import IDesignManager from '@/managers/DesignManager/types/custom';
import { buildResourceLibs } from '@/helpers/resourceHelper';
import designManager from '@managers/DesignManager';
import CoreEditorStackManagerfrom from '@/managers/coreEditorStackManager';

import { ThunkActions as GlobalActions } from '../global/actions';
import { Actions as AppActions } from '../app/actions';
import { ActionsUnion, ActionType, createAction, ToastType } from '../types';
import { IMainState } from '../webTypes';

export const Actions = {
  loadLib: (lib: ILibF) => createAction(ActionType.Lib_LoadLib, lib),
  loadLibs: (libs: ILibF[]) => createAction(ActionType.Lib_LoadLibs, libs),
  loadError: () => createAction(ActionType.Lib_LoadError),
  addLib: (lib: ILibF) => createAction(ActionType.Lib_AddLib, lib),
  updateLib: (libID: string, data: ILibBase) =>
    createAction(ActionType.Lib_UpdateLib, {
      libID: libID,
      ...data,
    }),
  addColor: (data: IColor | IColor[]) => createAction(ActionType.Lib_AddColor, data),
  addTypography: (data: ITypography) => createAction(ActionType.Lib_AddTypography, data),
  addComponent: (data: IComponent) => createAction(ActionType.Lib_AddComponent, data),
  removeResourceItem: (res: IResourceItem, type: ResourceType) =>
    createAction(ActionType.Lib_RemoveLibItem, {
      res,
      type,
    }),
  patchColor: (data: IColor) => createAction(ActionType.Lib_PatchColorItem, data),
  patchComponent: (data: IComponent) => createAction(ActionType.Lib_PatchComponentItem, data),
  patchTypography: (data: ITypography) => createAction(ActionType.Lib_PatchTypographyItem, data),

  replaceColorItem: (res: IColor) => createAction(ActionType.Lib_PatchColorItem, res),
  replaceComponentItem: (res: IComponent) => createAction(ActionType.Lib_PatchComponentItem, res),
  replaceTypographyItem: (res: ITypography) => createAction(ActionType.Lib_PatchTypographyItem, res),
  resourceItemIndexChanged: (type: ResourceType, res: IResourceItem, newIndex: number) =>
    createAction(ActionType.Lib_ResourceItemIndexChanged, {
      type,
      res,
      newIndex,
    }),
  switchLib: (libID: string) => createAction(ActionType.Lib_SwitchLib, { libID }),

  addGroup: (data: IDSGroup) => createAction(ActionType.Lib_AddGroup, data),
  removeGroup: (libID: string, groupID: string) => createAction(ActionType.Lib_RemoveGroup, { libID, groupID }),
  renameGroup: (group: IDSGroup) => createAction(ActionType.Lib_RenameGroup, group),
  moveGroup: (libID: string, groupID: string, index: number) =>
    createAction(ActionType.Lib_MoveGroup, { libID, groupID, index }),
  moveComponentsGroup: (libID: string, components: IComponent[], groupID: string) =>
    createAction(ActionType.Lib_ResetGroup, { libID, groupID, components }),
  removeAfterMoveGroup: (libID: string, removeGroupID: string, targetGroupID: string, comps: IComponent[]) =>
    createAction(ActionType.Lib_RemoveAfterMoveGroup, { libID, removeGroupID, targetGroupID, comps }),
  removeComponents: (libID: string, componentIDs: string[]) =>
    createAction(ActionType.Lib_RemoveComponents, { libID, componentIDs }),
  addRelations: (libID: string, componentID: string, data: IRelation[]) =>
    createAction(ActionType.Lib_AddRelations, { libID, componentID, data }),
  patchRelations: (libID: string, componentID: string, data: IRelation[]) =>
    createAction(ActionType.Lib_PatchRelations, { libID, componentID, data }),
  removeRelations: (libID: string, componentID: string, relationIDs: string[]) =>
    createAction(ActionType.Lib_RemoveRelations, { libID, componentID, relationIDs }),
  patchComponents: (comps: IComponent[]) => createAction(ActionType.Lib_PatchComponents, comps),
};

export const ThunkActions = {
  thunkLoadLib: (appID: string, isExample: boolean = false) => async (dispatch: Dispatch) => {
    return apis.ds.lib
      .getLibs(appID, isExample)
      .then((libs) => {
        dispatch(Actions.loadLibs(libs));
        if (libs.length) {
          const lastUserID = appOptions.lastUsedDesignRepositoryID;
          const lib =
            libs.find((lib) => lib._id === lastUserID && lib.visibility) ||
            libs.find((lib) => lib.appID === appID) ||
            libs[0];
          if (lib) {
            dispatch(Actions.loadLib(lib));
            dispatch(AppActions.refresh());
            //嵌套模式下，深层更新画板
            CoreEditorStackManagerfrom.manager?.coreEditorStack.map((coreEditor) => {
              coreEditor.reload();
            });
          }
        }
      })
      .catch((e: CustomError) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
        dispatch(Actions.loadError());
      });
  },
  // for preview
  thunkLoadUsedLib: (ids: string[]) => async (dispatch: Dispatch) => {
    return apis.ds.lib
      .getUsedLibs(ids)
      .then((res) => {
        const libs = buildResourceLibs(res);
        dispatch(Actions.loadLibs(libs));
      })
      .catch((e: CustomError) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
        dispatch(Actions.loadError());
      });
  },
  thunkAddLib: (appID: string) => async (dispatch: Dispatch) => {
    apis.ds.lib
      .addLib(appID)
      .then((lib) => dispatch(Actions.addLib(lib)))
      .catch((e: CustomError) => GlobalActions.thunkErrorMessage(e, true)(dispatch));
  },
  thunkUpdateLib: (libID: string, data: ILibBase) => async (dispatch: Dispatch) => {
    apis.ds.lib
      .updateLib(libID, data)
      .then((lib) => dispatch(Actions.updateLib(libID, lib)))
      .catch((e: CustomError) => GlobalActions.thunkErrorMessage(e, true)(dispatch));
  },

  addColor: (libID: string, color: PureColor | PureColor[]) => async (dispatch: Dispatch) => {
    if (Array.isArray(color)) {
      const list: Promise<IColor>[] = color.map((c) => {
        return apis.ds.resourceItem.color.add(libID, c);
      });
      Promise.all(list)
        .then((colors) => dispatch(Actions.addColor(colors)))
        .catch((e: CustomError) => GlobalActions.thunkErrorMessage(e, true)(dispatch));
    } else {
      apis.ds.resourceItem.color
        .add(libID, color)
        .then((color) => dispatch(Actions.addColor(color)))
        .catch((e: CustomError) => GlobalActions.thunkErrorMessage(e, true)(dispatch));
    }
  },
  addTypography: (libID: string, typography: ITypographyValue) => async (dispatch: Dispatch) => {
    apis.ds.resourceItem.typography
      .add(libID, typography)
      .then((data) => dispatch(Actions.addTypography(data)))
      .catch((e: CustomError) => GlobalActions.thunkErrorMessage(e, true)(dispatch));
  },
  addComponent: (
    libID: string,
    tempID: string,
    data: {
      thumb: string;
      component: Partial<IComponentData>;
      name: string;
      groupID?: string;
      description?: string;
      appID?: string;
    },
  ) => async (dispatch: Dispatch, getState: Function) => {
    const state = getState() as IMainState;
    apis.ds.resourceItem.component
      .add(libID, {
        ...data,
        appID: state.app.appID,
      })
      .then((comp) => {
        dispatch(Actions.addComponent(comp));
        if (tempID && comp.value.type === 'symbol') {
          designManager.getInstance<IDesignManager>().relatedComponents(tempID, true, comp);
        }
        return comp;
      })
      .catch((e: CustomError) => GlobalActions.thunkErrorMessage(e, true)(dispatch));
  },

  removeResourceItem: (res: IResourceItem, type: ResourceType) => async (dispatch: Dispatch, getState: Function) => {
    const { _id: resourceID, libID } = res;
    const state = getState() as IMainState;
    const fun = apis.ds.resourceItem[type].remove;
    if (fun) {
      fun(resourceID, {
        appID: state.app.appID,
      })
        .then(() => {
          dispatch(Actions.removeResourceItem(res, type));
          designManager.getInstance<IDesignManager>().afterResourceRemoved(libID, resourceID, type);
        })
        .catch((e: CustomError) => GlobalActions.thunkErrorMessage(e, true)(dispatch));
    }
  },

  patchColor: (resID: string, color: Partial<IColor>) => async (dispatch: Dispatch, getState: Function) => {
    const state = getState() as IMainState;
    const libData = state.resource.currentLib!;
    const originalData = libData.colors.find((color) => color._id === resID)!;
    const tempColor: IColor = {
      ...originalData,
      ...color,
    };
    dispatch(Actions.replaceColorItem(tempColor));
    apis.ds.resourceItem.color
      .patch(resID, color)
      .then((color: IColor) => {
        dispatch(Actions.patchColor(color));
      })
      .catch((e: CustomError) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
        dispatch(Actions.replaceColorItem(originalData!));
      });
  },

  patchComponent: (resID: string, component: Partial<IComponent>) => async (dispatch: Dispatch, getState: Function) => {
    const state = getState() as IMainState;
    const libData = state.resource.currentLib!;
    const groupID = component.groupID;
    let comps: IComponent[];
    if (groupID) {
      comps = libData.components.find((item) => item._id === groupID)!.children;
    } else {
      comps = [];
      libData.components.forEach((item) => {
        comps.push(...(item.children || []));
      });
    }
    // const originalData = libData.components.find((comp) => comp._id === resID)!;
    const originalData = comps.find((comp) => comp._id === resID)!;
    const tempComponent: IComponent = {
      ...originalData,
      ...component,
    };
    dispatch(Actions.replaceComponentItem(tempComponent));
    apis.ds.resourceItem.component
      .patch(resID, { ...component, appID: state.app.appID })
      .then((component) => {
        dispatch(Actions.patchComponent(component));
        designManager.getInstance<IDesignManager>().afterUpdateComponent(component);
        dispatch(AppActions.refresh());
        //嵌套模式下，深层更新画板
        CoreEditorStackManagerfrom.manager?.coreEditorStack.map((coreEditor) => {
          coreEditor.reload();
        });
      })
      .catch((e: CustomError) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
        dispatch(Actions.replaceComponentItem(originalData));
        //嵌套模式下，深层更新画板
        CoreEditorStackManagerfrom.manager?.coreEditorStack.map((coreEditor) => {
          coreEditor.reload();
        });
      });
  },

  patchTypography: (resID: string, typography: Partial<ITypography>) => async (
    dispatch: Dispatch,
    getState: Function,
  ) => {
    const state = getState() as IMainState;
    const libData = state.resource.currentLib!;
    const originalData = libData.typographies.find((res) => res._id === resID)!;
    const tempData: ITypography = {
      ...originalData,
      ...typography,
    };
    dispatch(Actions.replaceTypographyItem(tempData));
    apis.ds.resourceItem.typography
      .patch(resID, typography)
      .then((typography) => {
        dispatch(Actions.patchTypography(typography));
      })
      .catch((e: CustomError) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
        dispatch(Actions.replaceTypographyItem(originalData));
      });
  },

  changeResourceIndex: (type: ResourceType, res: IResourceItem, index: number) => async (dispatch: Dispatch) => {
    const { libID, _id: resID } = res;
    let fun: ((libID: string, resID: string, index: number) => Promise<IResourceItem>) | undefined;
    fun = apis.ds.resourceItem[type].move;
    if (fun) {
      fun(libID, resID, index)
        .then(() => {
          dispatch(Actions.resourceItemIndexChanged(type, res, index));
        })
        .catch((e: CustomError) => {
          GlobalActions.thunkErrorMessage(e, true)(dispatch);
        });
    }
  },

  switchLib: (libID: string) => (dispatch: Dispatch) => {
    dispatch(Actions.switchLib(libID));
  },

  addGroup: (libID: string, name: string) => async (dispatch: Dispatch) => {
    apis.ds.group
      .addGroup(libID, name)
      .then((group: IDSGroup) => dispatch(Actions.addGroup(group)))
      .catch((e: CustomError) => GlobalActions.thunkErrorMessage(e, true)(dispatch));
  },

  removeGroup: (libID: string, groupID: string) => async (dispatch: Dispatch) => {
    apis.ds.group
      .removeGroup(libID, groupID)
      .then(() => {
        dispatch(Actions.removeGroup(libID, groupID));
      })
      .catch((e: CustomError) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
      });
  },

  renameGroup: (libID: string, groupID: string, name: string) => async (dispatch: Dispatch) => {
    apis.ds.group
      .renameGroup(libID, groupID, name)
      .then((group: IDSGroup) => {
        dispatch(Actions.renameGroup(group));
      })
      .catch((e: CustomError) => GlobalActions.thunkErrorMessage(e, true)(dispatch));
  },

  moveGroup: (libID: string, groupID: string, newIndex: number) => async (dispatch: Dispatch) => {
    apis.ds.group
      .moveGroup(libID, groupID, newIndex)
      .then(() => {
        dispatch(Actions.moveGroup(libID, groupID, newIndex));
      })
      .catch((e: CustomError) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
      });
  },

  resetComponentsGroup: (libID: string, comps: IComponent[], targetGroupID: string) => async (dispatch: Dispatch) => {
    apis.ds.resourceItem.component
      .resetGroup(
        libID,
        comps.map((comp) => comp._id),
        targetGroupID,
      )
      .then(() => {
        dispatch(Actions.moveComponentsGroup(libID, comps, targetGroupID));
      })
      .catch((e) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
      });
  },

  removeAfterMoveGroup: (libID: string, removeGroupID: string, targetGroupID: string, comps: IComponent[]) => async (
    dispatch: Dispatch,
  ) => {
    apis.ds.resourceItem.component
      .resetGroup(
        libID,
        comps.map((c) => c._id),
        targetGroupID,
      )
      .then(() => {
        apis.ds.group
          .removeGroup(libID, removeGroupID)
          .then(() => {
            dispatch(Actions.removeAfterMoveGroup(libID, removeGroupID, targetGroupID, comps));
          })
          .catch((e: CustomError) => {
            GlobalActions.thunkErrorMessage(e, true)(dispatch);
          });
      })
      .catch((e: CustomError) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
      });
  },

  removeComponents: (libID: string, componentIDs: string[]) => async (dispatch: Dispatch, getState: Function) => {
    const state = getState() as IMainState;
    apis.ds.resourceItem.component
      .removeComponents(componentIDs, {
        appID: state.app.appID,
      })
      .then(() => {
        dispatch(Actions.removeComponents(libID, componentIDs));
        designManager.getInstance<IDesignManager>().afterRemoveMoreComponents(libID, componentIDs);
      })
      .catch((e: CustomError) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
      });
  },

  addRelations: (libID: string, componentID: string, data: Partial<IRelation>[]) => async (dispatch: Dispatch) => {
    apis.ds.resourceItem.component
      .addRelations(componentID, data)
      .then((res) => {
        dispatch(Actions.addRelations(libID, componentID, res));
        designManager.getInstance<IDesignManager>().afterRelationsUpdate(libID, componentID, res);
      })
      .catch((e: CustomError) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
      });
  },

  patchRelations: (libID: string, componentID: string, data: IRelation[]) => async (dispatch: Dispatch) => {
    apis.ds.resourceItem.component
      .patchRelations(componentID, data)
      .then(() => {
        dispatch(Actions.patchRelations(libID, componentID, data));
        designManager.getInstance<IDesignManager>().afterRelationsUpdate(libID, componentID, data);
      })
      .catch((e: CustomError) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
      });
  },

  removeRelations: (libID: string, componentID: string, relationIDs: string[]) => async (dispatch: Dispatch) => {
    apis.ds.resourceItem.component
      .removeRelations(componentID, relationIDs)
      .then(() => {
        dispatch(Actions.removeRelations(libID, componentID, relationIDs));
        designManager.getInstance<IDesignManager>().afterRelationsUpdate(libID, componentID);
      })
      .catch((e: CustomError) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
      });
  },

  convertToSymbol: (comps: IComponent[]) => (dispatch: Dispatch, getState: Function) => {
    apis.ds.resourceItem.component
      .convertToSymbol(comps.map((c) => c._id))
      .then(() => {
        const state = getState() as IMainState;
        ThunkActions.thunkLoadLib(state.app.appID)(dispatch);
        GlobalActions.showToast(ToastType.Success, i18n('resource.libs.convertToastText'))(dispatch);
      })
      .catch((e: CustomError) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
      });
  },

  convertSymbolToNormal: (comps: IComponent[]) => (dispatch: Dispatch, getState: Function) => {
    apis.ds.resourceItem.component
      .convertSymbolToNormal(comps.map((c) => c._id))
      .then(() => {
        const state = getState() as IMainState;
        ThunkActions.thunkLoadLib(state.app.appID)(dispatch);
        GlobalActions.showToast(ToastType.Success, i18n('resource.libs.convertToastText'))(dispatch);
      })
      .catch((e: CustomError) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
      });
  },

  convertToSymbolOrNormal: (comps: IComponent[], symbolComps: IComponent[]) => async (
    dispatch: Dispatch,
    getState: Function,
  ) => {
    GlobalActions.showLoading(true, 'light')(dispatch);
    // 策略 - 体验，loading停留最短1秒
    try {
      comps.length && (await apis.ds.resourceItem.component.convertToSymbol(comps.map((c) => c._id)));
      symbolComps.length && (await apis.ds.resourceItem.component.convertSymbolToNormal(symbolComps.map((c) => c._id)));
      const state = getState() as IMainState;
      ThunkActions.thunkLoadLib(state.app.appID)(dispatch);
      setTimeout(() => {
        GlobalActions.showLoading(false)(dispatch);
        GlobalActions.showToast(ToastType.Success, i18n('resource.libs.convertToastText'))(dispatch);
      }, 1e3);
    } catch (e) {
      setTimeout(() => {
        GlobalActions.showLoading(false)(dispatch);
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
      }, 1e3);
    }
  },
};

export type ResourceAction = ActionsUnion<typeof Actions>;
