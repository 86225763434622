export interface IRPOfflinePreviewDemoConfig {
  v: string;
  config: IDemoConfig;
}

export enum ControlPanelState {
  Expand = 'expand',
  Collapse = 'collapse',
  Hide = 'hide',
}

export interface IDemoConfig {
  // icon不需要配置到data.js
  icon?: string;
  origin: string;
  showLinkAreaWhenHovered: boolean;
  alwaysShowLinkArea: boolean;
  autoScreen: boolean;
  mobileType: string;
  noBoundary: boolean;
  showRemarkTag: boolean;
  controlPanelState: ControlPanelState;
  remarkPanelState: ControlPanelState;
  showNavigationBar: boolean;
  lang: string;
  waterMark?: string;
}
