import {
  showExportImageLoadingDialog,
  closeDialogAndTerminateTask,
  showExportImageErrorDialog,
  closeGlobalDialog,
} from '../util/dialogUtil';
/**
 * 加载中，进度条
 */
export class ExportImageLoading {
  private _suspend = false;
  private progress = 0;
  private timer: NodeJS.Timeout | null = null;
  private autoRunNext = true;
  private progressStep = 5;
  private progressTimeStep = 0;

  setStep(progressStep: number) {
    this.progressStep = progressStep;
  }
  reset() {
    this._suspend = false;
    this.autoRunNext = true;
    this.progressStep = 5;
    this.progressTimeStep = 0;
  }

  showErrorMessage = (e: string[]) => {
    const content = `    ${e.length}个图片导出异常:    ${e.join('，')}    `;
    showExportImageErrorDialog({
      showProgressBar: false,
      showConfirmButton: false,
      showCancelButton: true,
      progress: this.progress,
      content,
      intervalTime: 100,
      cancel: () => {
        // 强制终止进程
        closeDialogAndTerminateTask();
      },
    });
  };

  onAnimationEnd = () => {
    // 结束动作，关闭对话框
    window.debug && console.log('onAnimationEnd');
    this.timer && clearTimeout(this.timer);
    this.reset();
    setTimeout(() => {
      // 关闭对话框
      // 保持500ms
      closeGlobalDialog();
    }, 500);
  };
  next = () => {
    this.progress += this.progressStep;
    window.debug && console.log('processor next' + this.progressStep);
    if (this.progress < 100 - this.progressStep) {
      this.run();
    }
  };
  run(progress?: number, e?: string[]) {
    this.timer && clearTimeout(this.timer);
    if (this._suspend) {
      window.debug && console.log('loading _suspend');
      return;
    }
    // 导致进度条不动情况，去掉timer
    // this.timer = setTimeout(() => {
    if (progress) {
      this.progress = progress;
      if (progress >= 100) {
        // 进度到100自动结束，
        this.end(e);
        return;
      }
    }

    window.debug && console.log('loading:' + this.progress);

    showExportImageLoadingDialog({
      showProgressBar: true,
      showConfirmButton: false,
      showCancelButton: true,
      onAnimationEnd: this.autoRunNext ? this.next() : null,
      progress: this.progress,
      intervalTime: 500,
      cancel: () => {
        // 强制终止进程
        closeDialogAndTerminateTask();
      },
    });
    // }, this.progressTimeStep);
  }

  begin(autoRunNext: boolean = true) {
    this.reset();
    this.timer && clearTimeout(this.timer);
    this.progress = autoRunNext ? this.progressStep : 0;
    this.autoRunNext = autoRunNext;
    this.run();
  }
  end(e?: string[]) {
    window.debug && console.log('loading end:' + !this._suspend);
    if (!this._suspend) {
      this.progress = 100;
      if (e?.length) {
        this.showErrorMessage(e);
      } else {
        showExportImageLoadingDialog({
          showProgressBar: true,
          showConfirmButton: false,
          showCancelButton: true,
          onAnimationEnd: this.onAnimationEnd(),
          progress: this.progress,
          intervalTime: 500,
          cancel: () => {
            // 强制终止进程
            closeDialogAndTerminateTask();
          },
        });
      }
    }
  }

  suspend() {
    // 意外停止
    this._suspend = true;
  }
  terminate() {
    window.debug && console.log('loading terminate');
    // 终止
    this.timer && clearTimeout(this.timer);
    this.suspend();
  }
}
