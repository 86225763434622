import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// 队列数据
export default {
  ...flowBase,
  name: i18n('resource.flow.queueData'),
  type: 'queueData',
  thumb: {
    spriteIconClass: SpriteThumb.QueueData.className,
    dragPosition: SpriteThumb.QueueData.position,
  },
  template: getShapeData(
    i18n('resource.flow.queueData'),
    {
      width: 60,
      lockedRatio: false,
      height: 60,
    },
    {
      data: [
        {
          point: {
            x: 60,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 30,
            y: 60,
          },
          handleIn: {
            x: 16,
            y: 0,
          },
          handleOut: {
            x: -16,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 30,
          },
          handleIn: {
            x: 0,
            y: 16,
          },
          handleOut: {
            x: 0,
            y: -16,
          },
        },
        {
          point: {
            x: 30,
            y: 0,
          },
          handleIn: {
            x: -16,
            y: 0,
          },
          handleOut: {
            x: 16,
            y: -0,
          },
        },
        {
          point: {
            x: 60,
            y: 30,
          },
          handleIn: {
            x: 0,
            y: -16,
          },
          handleOut: {
            x: 0,
            y: 16,
          },
        },
        {
          point: {
            x: 45,
            y: 55,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 60,
            y: 55,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
      closed: true,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
