import * as React from 'react';
import ScrollBar from 'react-custom-scrollbars';
import classnames from 'classnames';
import { isFirefox } from '../utils';
import { isInputting } from '@utils/globalUtils';
import { isMacOS } from '@utils/envUtils';
// import { dragDelegate } from '@utils/mouseUtils';

import './index.scss';

interface IScrollBarsProp {
  hiddenVerticalScrollBar?: boolean;
  hiddenHorizontalScrollBar?: boolean;
  className?: string;
  thumbClassName?: string;
  trackerClassName?: string;
  style?: React.CSSProperties;
  onClick?: any;
  autoHide?: boolean;
  thumbTheme?: 'normal' | 'large';
  theme?: 'light' | 'dark' | 'deep-dark';
  disabled?: boolean;
  thumbSize?: number;
  thumbMinSize?: number;
  children?: React.ReactNode;
  onScroll?: React.UIEventHandler;
  scrollShow?: boolean; // 是否在滚动时显示滚动条
  useGestureScroll?: boolean; // 是否支持使用手势滚动
  disabledHoriScroll?: boolean; // 是否禁用水平滚动
  disabledVertScroll?: boolean; // 是否禁用垂直滚动
}

interface IScrollbarState {
  showbar?: boolean;
}

const isMobileDevice = (): boolean => {
  const u = navigator.userAgent;
  return u.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i) !== null;
};

/**
 * 继承处理
 */
class _ScrollBars extends ScrollBar {
  private absoluteScale = 1;
  private frameId: number | undefined;

  private get _container(): HTMLElement {
    // @ts-ignore
    return this.view;
  }

  private get _absoluteWidth() {
    return this._container.getBoundingClientRect().width;
  }

  private cancelRaf = () => {
    if (this.frameId) {
      window.cancelAnimationFrame(this.frameId);
    }
  };

  // override 复写componentDidUpdate，先执行ScrollBar.componentDidUpdate
  componentDidUpdate() {
    // @ts-ignore
    super.componentDidUpdate();
    this.cancelRaf();
    this.frameId = window.requestAnimationFrame(() => {
      if (!this._container) {
        return;
      }
      const width = this._absoluteWidth;
      const clientWidth = this.getClientWidth();
      this.absoluteScale = width / clientWidth;
    });
  }

  componentWillMount() {
    this.cancelRaf();
  }

  private getScrollLeftForOffset(offset: number): number {
    // @ts-ignore
    const _offset = super.getScrollLeftForOffset(offset) as number;
    return _offset / this.absoluteScale;
  }

  private getScrollTopForOffset(offset: number): number {
    // @ts-ignore
    const _offset = super.getScrollTopForOffset(offset) as number;
    return _offset / this.absoluteScale;
  }
}

export default class ScrollBars extends React.Component<IScrollBarsProp, IScrollbarState> {
  self: React.RefObject<_ScrollBars> = React.createRef();

  private scrollTopWithStart: number;
  private timeid: Timeout;
  static defaultProps: Partial<IScrollBarsProp> = {
    autoHide: true,
    thumbMinSize: 8,
    scrollShow: false,
  };

  constructor(props: IScrollBarsProp) {
    super(props);
    this.scrollTopWithStart = 0;
    this.state = {
      showbar: false,
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  private handleKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 32 && !isInputting()) {
      e.preventDefault();
    }
  };

  getScrollTop = () => {
    return this.self.current!.getScrollTop();
  };

  scrollTop = (offset: number) => {
    this.self.current!.scrollTop(offset);
  };

  scrollLeft = (offset: number) => {
    this.self.current!.scrollLeft(offset);
  };

  scrollTo = (offsetX: number, offsetY: number) => {
    const scroll = this.self.current!;
    scroll.scrollTop(offsetY);
    scroll.scrollLeft(offsetX);
  };

  getClientHeight = () => {
    return this.self.current!.getClientHeight();
  };

  getClientWidth = () => {
    return this.self.current!.getClientWidth();
  };

  scrollToTop = () => {
    this.self.current!.scrollToTop();
  };

  scrollToBottom = () => {
    this.self.current!.scrollToBottom();
  };

  scrollToLeft = () => {
    this.self.current!.scrollToLeft();
  };

  scrollToRight = () => {
    this.self.current!.scrollToRight();
  };

  getScrollLeft = () => {
    return this.self.current!.getScrollLeft();
  };

  getScrollWidth = () => {
    return this.self.current!.getScrollWidth();
  };

  getScrollHeight = () => {
    return this.self.current!.getScrollHeight();
  };

  getValues = () => {
    return this.self.current!.getValues();
  };

  renderVerticalTracker() {
    const { thumbTheme } = this.props;
    return () => {
      if (isMobileDevice()) {
        return <i className="no-tracker" />;
      }
      if (this.props.hiddenVerticalScrollBar) {
        return <i className="no-tracker" />;
      }
      return (
        <div
          className={classnames('track-bar', 'scrollbars-vertical-tracker', thumbTheme, this.props.trackerClassName)}
        />
      );
    };
  }

  renderHorizontalTracker() {
    const { thumbTheme } = this.props;
    return () => {
      if (isMobileDevice()) {
        return <i className="no-tracker" />;
      }
      if (this.props.hiddenHorizontalScrollBar) {
        return <i className="no-tracker" />;
      }
      return (
        <div
          className={classnames('track-bar', 'scrollbars-horizontal-tracker', thumbTheme, this.props.trackerClassName)}
        />
      );
    };
  }

  renderVerticalThumb() {
    const { thumbTheme } = this.props;
    return () => {
      if (isMobileDevice()) {
        return <i className="no-thumb" />;
      }
      if (this.props.hiddenVerticalScrollBar) {
        return <i className="no-thumb" />;
      }
      return (
        <div className={classnames('scroll-thumb scroll-bars-vertical-thumb', this.props.thumbClassName, thumbTheme)} />
      );
    };
  }

  renderHorizontalThumb() {
    const { thumbTheme } = this.props;
    return () => {
      if (isMobileDevice()) {
        return <i className="no-thumb" />;
      }
      if (this.props.hiddenHorizontalScrollBar) {
        return <i className="no-thumb" />;
      }
      return (
        <div
          className={classnames('scroll-thumb scroll-bars-horizontal-thumb', thumbTheme, this.props.thumbClassName)}
        />
      );
    };
  }

  handleScroll = (e: React.UIEvent<any>) => {
    if (this.props.scrollShow) {
      this.setState({
        showbar: true,
      });
    }
    if (this.props.disabled) {
      this.scrollTop(this.scrollTopWithStart);
    }
    if (isMobileDevice()) {
      const clientHeight = this.getClientHeight();
      const scrollHeight = this.getScrollHeight();
      const scrollTop = this.getScrollTop();
      if (scrollTop <= 0) {
        this.scrollTop(0);
      } else if (scrollTop + clientHeight >= scrollHeight) {
        this.scrollTop(scrollHeight - clientHeight);
      }
    }
    this.props.onScroll && this.props.onScroll(e);
  };

  handleScrollStart = () => {
    if (this.timeid) {
      clearTimeout(this.timeid);
    }
    this.scrollTopWithStart = this.getScrollTop();

    this.props.useGestureScroll && window.addEventListener('selectstart', this.doSelectStart);
  };

  handleScrollStop = () => {
    if (!this.props.scrollShow) {
      return false;
    }
    this.timeid = setTimeout(() => {
      this.setState({
        showbar: false,
      });
    }, 700);

    window.removeEventListener('selectstart', this.doSelectStart);
  };

  doSelectStart = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // handleMouseDown = () => {
  //   if (!this.props.useGestureScroll) {
  //     return false;
  //   }
  //   const scrollTop = this.getScrollTop();
  //   const scrollLeft = this.getScrollLeft();
  //   // 启用模拟滚动时，关闭文本可选使滚动体验更流畅
  //   window.addEventListener('selectstart', this.doSelectStart);
  //   dragDelegate(
  //     (e, delta) => {
  //       // this.scrollTop(scrollTop - delta.y);
  //       // this.scrollLeft(scrollLeft + delta.x);
  //     },
  //     () => {
  //       window.removeEventListener('selectstart', this.doSelectStart);
  //     },
  //   );
  // };

  renderView = (style: { style: React.CSSProperties }) => {
    const { disabledHoriScroll, disabledVertScroll } = this.props;
    const overflowStyle: React.CSSProperties = {
      overflowX: disabledHoriScroll ? 'hidden' : 'scroll',
      overflowY: disabledVertScroll ? 'hidden' : 'scroll',
      // overflowX/overflowY不同的值,元素的client属性值也不同，隐藏时不设置margin负值
      marginRight: disabledVertScroll ? 0 : style.style.marginRight,
      marginBottom: disabledHoriScroll ? 0 : style.style.marginBottom,
    };
    return <div className="test-scroll" style={{ ...style.style, ...overflowStyle }}></div>;
  };

  render() {
    const {
      children,
      className,
      style,
      autoHide,
      theme,
      hiddenVerticalScrollBar,
      hiddenHorizontalScrollBar,
      thumbSize,
      thumbMinSize,
      scrollShow,
    } = this.props;
    const { showbar } = this.state;
    return (
      <_ScrollBars
        ref={this.self}
        autoFocus={true}
        autoHide={autoHide}
        renderView={this.renderView}
        style={style}
        className={classnames('dsm-c-rp-scrollbars', className, theme, {
          isMacOS,
          scrollbar: scrollShow,
          autoHide,
          showbar,
          firefox: isFirefox(),
          win: !isMobileDevice(),
          'hidden-vertical': hiddenVerticalScrollBar,
          'hidden-horizontal': hiddenHorizontalScrollBar,
        })}
        renderTrackHorizontal={this.renderHorizontalTracker()}
        renderTrackVertical={this.renderVerticalTracker()}
        thumbMinSize={thumbMinSize}
        thumbSize={thumbSize}
        renderThumbVertical={this.renderVerticalThumb()}
        renderThumbHorizontal={this.renderHorizontalThumb()}
        onScroll={this.handleScroll}
        onScrollStart={this.handleScrollStart}
        onScrollStop={this.handleScrollStop}
        // onMouseDown={this.handleMouseDown}
      >
        {children}
      </_ScrollBars>
    );
  }
}
