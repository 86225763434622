/*****************************************************
 * 一个组件上的数据结构示例
 const comp = {
  properties: {
    fill: {},
    stroke: {},
    radius: {},
    ...,
  },
  anchors: {},
  size:{},
  value:'',
  rotate:0,
  ....,


  interaction: {
    click: {
      parallel: false,
      active: true,
      actions: [{
        type: 'component',
        target: '',
        command: '',
        params: {},
        animation: {},
      }, {
        type: 'page',
        target: '',
        params: {},
        animation: {},
      }, {
        type: 'link',
        target: '',
        params: {},
      }, {
        type: 'variable',
        target: '',
        params: {},
      }],
    },
  },
};
 *****************************************************/
import IFill from './properties/fill';
import { IPosition } from '../../common/models/common';

/**
 * 页面跳转转场效果类型
 * @author Matt
 */
export type PageSkipEffectType =
  | 'none'
  | 'autoEffect'
  | 'pushToLeft'
  | 'pushToRight'
  | 'pushToTop'
  | 'pushToBottom'
  | 'slideToLeft'
  | 'slideToRight'
  | 'slideToTop'
  | 'slideToBottom'
  | 'fadeIn'
  | 'zoomIn'
  | 'flipInLeft'
  | 'flipInRight'
  | 'flipInTop'
  | 'flipInBottom'
  | 'slideToLeftRevert'
  | 'slideToRightRevert'
  | 'slideToTopRevert'
  | 'slideToBottomRevert'
  | 'zoomInRevert';

/**
 * 页面跳转转场效果类型
 * @author jq
 */
export type FragmentAnimationEffectType =
  | 'none'
  | 'autoEffect'
  | 'slideToLeft'
  | 'slideToRight'
  | 'slideToTop'
  | 'slideToBottom'
  | 'fadeIn'
  | 'zoomIn'
  | 'slideToLeftRevert'
  | 'slideToRightRevert'
  | 'slideToTopRevert'
  | 'slideToBottomRevert'
  | 'zoomInRevert';

/**
 * 交互目标类型
 * @author Matt
 */
export type TargetType = 'component' | 'page' | 'variable' | 'link' | 'fragment';

/**
 * 交互支持的动画类型，该类型的值可直接用于CSS动画属性的值
 * @author Matt
 */
export type AnimationEffect = 'none' | 'linear' | 'ease-in' | 'ease-out' | 'ease-in-out';

/**
 * 动画数据结构
 * @author Matt
 */
export interface IAnimation {
  effect: AnimationEffect;
  duration: number;
  delay: number;
}

/**
 * 组件交互动画数据结构
 * @author Matt
 * @extends IAnimation
 */
export interface IComponentAnimation extends IAnimation {
  loop?: boolean;
}

/**
 * 交互参数数据结构，此结构定义为空，主要用于在不同参数类型使用时，有一个统一的类型引用，避免写一些重复性的代码
 * @author Matt
 */
export interface IActionParams {}

export interface IComponentCommandActionParam extends IActionParams {
  disabledAutoRevert?: boolean;
}

/**
 * 组件交互命令参数
 * @author Matt
 * @extends IActionParams
 */
export interface ICommandActionParams extends IComponentCommandActionParam {
  [key: string]: string | number | boolean | undefined;
}

/**
 * 移动命令参数
 * @author Matt
 * @extends ICommandActionParams
 */
export interface IMoveCommandParams extends ICommandActionParams {
  x: number;
  y: number;
  fromCurrent: boolean;
}

/**
 * 调整尺寸命令参数
 * @author Matt
 * @extends ICommandActionParams
 */
export interface IResizeCommandParams extends ICommandActionParams {
  width: number;
  height: number;
  fromCurrent: boolean;
  transformOrigin:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'center'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight';
}

/**
 * 缩放命令参数
 * @author Matt
 * @extends ICommandActionParams
 */
export interface IScaleCommandParams extends ICommandActionParams {
  x: number;
  y: number;
  fromCurrent: boolean;
  transformOrigin:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'center'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight';
}

/**
 * 显示/隐藏命令参数
 * @author Matt
 * @extends ICommandActionParams
 */
export interface IVisibleCommandParams extends ICommandActionParams {
  state: 'show' | 'hidden' | 'toggle';
  // 置为顶层
  forceFront: boolean;
}

/**
 * 切换状态命令参数
 * @author Matt
 * @extends ICommandActionParams
 */
export interface IStateCommandParams extends ICommandActionParams {
  stateID: string;
}

/**
 * 旋转命令参数
 * @author Matt
 * @extends ICommandActionParams
 */
export interface IRotateCommandParams extends ICommandActionParams {
  rotate: number;
  fromCurrent: boolean;
  transformOrigin:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'center'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight';
}

/**
 * 内容切换命令参数
 */
export interface ISwitchContentParams extends ICommandActionParams {
  //内容面板中要切换的目标层引用的画板ID，该ID在同一内容面板中不得重复
  target: string;
  // 是否循环切换
  loopSwitch?: boolean;
}

/**
 * 滚动命令参数
 * @extends ICommandActionParams
 */
export interface IScrollCommandParams extends ICommandActionParams {
  horizontal: boolean;
  vertical: boolean;
}

/**
 * 组件支持的交互命令类型
 * @author Matt
 */
export type CommandType =
  | 'move'
  | 'resize'
  | 'scale'
  | 'rotation'
  | 'toggleVisible'
  | 'toggleState'
  | 'switchContent'
  | 'scroll';

/**
 * 交互事件类型
 * @author Matt
 */
export type EventType =
  | 'click'
  | 'doubleClick'
  | 'mouseDown'
  | 'mouseUp'
  | 'mouseEnter'
  | 'mouseLeave'
  | 'checked'
  | 'unChecked'
  | 'focus'
  | 'blur'
  | 'change'
  | 'scroll';

/**
 * 切换页面方式
 */
export enum OpenWindowModel {
  currentWindow = 'currentWindow',
  newWindow = 'newWindow',
  popupWindow = 'popupWindow',
  parentWindow = 'parentWindow',
}

/**
 * 组件上的交互数据
 * @author Matt
 */
export interface IInteraction {
  [event: string]: IEventHandle;
}

/**
 * 一个目标上的交互数据
 * @author Matt
 */
export interface IActionBase {
  _id: string;
  target: string;
  type: TargetType;
  params: IActionParams;
  command?: string;
}

/**
 * 组件目标的交互数据
 * @author Matt
 * @extends IActionBase
 */
export interface IComponentAction extends IActionBase {
  command: CommandType;
  params: ICommandActionParams;
  animation: IComponentAnimation;
}

/**
 * 页面目标的交互参数
 * @author Matt
 * @extends IActionParams
 */
export interface IPageActionParams extends IActionParams {
  effect: PageSkipEffectType;
  openWindowModel?: OpenWindowModel;
}

/**
 * 页面目标交互数据
 * @author Matt
 * @extends IActionBase
 */
export interface IPageAction extends IActionBase {
  animation: IAnimation;
  params: IPageActionParams;
}

export enum FragmentPositionMode {
  Custom = 'custom',
  Center = 'center',
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

export enum FragmentAnimationEffects {
  none = 'none',
  autoEffect = 'autoEffect',
  slideToLeft = 'slideToLeft',
  slideToRight = 'slideToRight',
  slideToTop = 'slideToTop',
  slideToBottom = 'slideToBottom',
  fadeIn = 'fadeIn',
  zoomIn = 'zoomIn',
  spreadIn = 'spreadIn',

  zoomInRevert = 'zoomInRevert',
  slideToTopRevert = 'slideToTopRevert',
  slideToLeftRevert = 'slideToLeftRevert',
  slideToRightRevert = 'slideToRightRevert',
  slideToBottomRevert = 'slideToBottomRevert',
  spreadInRevert = 'spreadInRevert',
  fadeInRevert = 'fadeInRevert',
}

/**
 * 画板目标的交互参数
 * @author Jq
 * @extends IActionParams
 */
export interface IFragmentActionParams extends IComponentCommandActionParam {
  effect: PageSkipEffectType;
  autoEffect?: boolean;
  // 是否显示背景
  showBackground?: boolean;
  // 是否点击辅助画板之外就关闭它
  closeWithOutClick?: boolean;
  /**
   * 背景遮罩颜色
   */
  backgroundColor?: IFill;
  mode?: FragmentPositionMode;
  position?: IPosition;
}

/**
 * 画板目标交互数据
 * @author Jq
 * @extends IActionBase
 */
export interface IFragmentAction extends IActionBase {
  animation: IAnimation;
  params: IFragmentActionParams;
  isExit: boolean;
  autoEffect?: boolean;
  pageId?: string;
}

/**
 * 变量目标交互参数
 * @author Matt
 * @extends IActionParams
 */
export interface IVariableActionParams extends IActionParams {
  value: any;
}

/**
 * 变量目标交互数据
 * @author Matt
 * @extends IActionBase
 */
export interface IVariableAction extends IActionBase {
  params: IVariableActionParams;
}

/**
 * 外部链接类型
 */
export type LinkType = 'common' | 'axure';

/**
 * 链接目标交互参数
 * @author Matt
 * @extends IActionParams
 */
export interface ILinkActionParams extends IActionParams {
  siteName: string;
  url: string;
  openWithSelf?: boolean;
  type?: LinkType;
  appID?: string;
  prototypeID?: string;
  pageID?: string;
}

/**
 * 链接交互目标数据
 * @author Matt
 * @extends IActionBase
 */
export interface ILinkAction extends IActionBase {
  params: ILinkActionParams;
}

/**
 * 一个交互事件对应的行为数据结构
 * @author Matt
 */
export interface IEventHandle {
  parallel?: boolean;
  active?: boolean;
  autoRevert: boolean;
  actions: IActionBase[];
}

/**
 * 交互参数别名
 * @author Matt
 */
export type IActionParam =
  | ICommandActionParams
  | IPageActionParams
  | ILinkActionParams
  | IVariableActionParams
  | IFragmentAction;
