enum ErrorCode {
  //通用错误码
  ERROR_GENERAL = 1,
  // 多点登录错误
  ERROR_MULTI_SIGN_IN = 202,
  // 轮询状态
  ERROR_POLL = 429,
}

export default ErrorCode;
