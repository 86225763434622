import { isFigma } from './utils';
let LANG = window.LANG || 'zh-cn';

try {
  if (!isFigma()) {
    const key = localStorage?.getItem('mockplus_language');
    LANG = key || LANG;
  }

} catch (error) {

}

// 是否是中文版本环境包括繁体
export const isZh = ['zh-cn', 'zh-tw'].includes(LANG);
export const isTW = LANG === 'zh-tw';
export const isUS = LANG === 'en-us';
