import i18n from '../i18n';

export const enum Role {
  ROLE_SUPER_ADMIN = 'super-admin',
  ROLE_ADMIN = 'admin',
  ROLE_MEMBER = 'member',
  ROLE_GUEST = 'guest',
  ROLE_VISITOR = 'visitor',
}

export const RoleList = [
  {
    key: Role.ROLE_SUPER_ADMIN,
    value: i18n('role.superAdministrator'),
  },
  {
    key: Role.ROLE_ADMIN,
    value: i18n('role.administrator'),
  },
  {
    key: Role.ROLE_MEMBER,
    value: i18n('role.member'),
  },
  {
    key: Role.ROLE_GUEST,
    value: i18n('role.guest'),
  },
];
