import IndexedDBBase from '@/utils/indexedDBBase';

import { IAppDataModel, IOfflineStorage } from '../types/appData.type';
import { StorageType } from '@/consts/storage';
import { OffLineOperation } from '@/fbs/rp/models/io';

export class AppDataModel implements IAppDataModel {
  /**
   * 这里没有使用短线的原因是appID中可能存在短线的情况
   */
  private _split: string = '|';

  private _OfflineStorage: IOfflineStorage = new IndexedDBBase(StorageType.OFFLINE);

  public setOfflineStorage(storage: IOfflineStorage): void {
    this._OfflineStorage = storage;
  }

  private getCacheKey(appID: string, patchID: string): string {
    return `${appID}${this._split}${patchID}`;
  }

  public async saveCache(appID: string, patchID: string, value: string): Promise<void> {
    try {
      await this._OfflineStorage.saveValue(this.getCacheKey(appID, patchID), value);
    } catch (e) {
      console.error(e);
    }
  }

  public async removeCache(appID: string, patchID: string): Promise<void> {
    try {
      await this._OfflineStorage.deleteKey(this.getCacheKey(appID, patchID));
    } catch (e) {
      console.error(e);
    }
  }

  public async getCaches(appID: string): Promise<OffLineOperation[]> {
    try {
      const data = await this._OfflineStorage.getValuesByKeyPrefix(appID);
      return data.map((item) => JSON.parse(item.value));
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  public async getAllKeys(): Promise<string[]> {
    try {
      const keys = await this._OfflineStorage.getAllKeys();

      const keySet: Set<string> = new Set();
      keys.forEach((key) => {
        const values = key.split(this._split);
        if (values.length >= 2) {
          keySet.add(values[0]);
        }
      });

      return Array.from(keySet);
    } catch (e) {
      console.error(e);
      return [];
    }
  }
}
