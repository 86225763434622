import { Segment } from 'paper';

export interface ISegment extends Segment {
  radius?: number;
  hidden?: boolean;
  type?: SegType;
}

export enum SegType {
  Straight = 'straight',
  Mirrored = 'mirrored',
  Disconnected = 'disconnected',
  Asymmetric = 'asymmetric',
}
