import { sortBy, without, uniq } from 'lodash';

import * as GlobalUtils from '@utils/globalUtils';
import * as BoundsUtils from '@utils/boundsUtils';

import { IPosition, IBounds, ISize, IPoint } from '@fbs/common/models/common';
import { IProperties } from '@fbs/rp/models/property';

import { UIComponent, UIGroupComponent } from '@editor/comps';
import { DynamicInfoMap, ComponentChangeType, extractDynamicInfoFromPatch } from '@editor/comps/resizeHelper';
import { getViewBoundsOfComponentsWithArtboard } from '@editor/bounds';

import {
  getBoundsInParent,
  getCompAbsoluteBounds,
  getCompAbsoluteMatrix,
  getGapByControlOffset,
  tansPointInArtBoardToGroup,
} from './componentHelper';
import { getCenter, getNWPoint } from './rotateHelper';
import { Matrix } from '@utils/matrixUtils';

type LineObject = { [key: string]: UIComponent[] };

export type LineInfo = {
  index: number;
  bounds: IBounds;
  comps: UIComponent[];
  minPosition: IPosition; //第一个组件的位置
  relativePosition: IPosition; //相对框选的位置
};

export type LineInfos = LineInfo[];

type TargetProp = 'left' | 'top';
type CompareProp = 'right' | 'bottom';

type LineDirection = 'vertical' | 'horizontal';

type SplitData = {
  isLineEquidistant: boolean;
  lineInfos: LineInfos;
  spaceNum: number;
  sortYKeys: number[];
  minXMinYPosition: IPosition;
};

export type EquidistantValue = {
  isEquidistant: boolean;
  isRow?: boolean;
  verticalSpace?: number;
  horizontalSpace?: number;
  minXMinYPosition?: IPosition;
  collatedInfo?: LineInfos;
};

export type BasicEquidistantValue = {
  isEquidistant: boolean;
  isRow: boolean;
  verticalSpace: number;
  horizontalSpace: number;
  minXMinYPosition: IPosition;
};

export type SpaceControl = {
  position?: IPosition;
  size?: ISize;
  type?: LineDirection;
  rowIndex?: number;
  colIndex?: number;
};

export interface IComponentBoundsInfo {
  id: string;
  isDragComp: boolean;
  isGroup: boolean;
  groupChildren: string[];
  index?: number[];
  position: IPosition;
  bounds: IBounds; // 拖拽前最原始的bounds
  movedBounds?: IBounds; // 满足条件后改变的bounds
  movingBounds?: IBounds; // 实时变化的bounds
}
interface IBoundsUIComponent extends UIComponent {
  originBounds: IBounds;
  positionInParent: IPosition;
  rotate: number;
  groupChildren: string[];
}

export interface ILineInfoWithIBounds {
  index: number;
  bounds: IBounds;
  comps: IBoundsUIComponent[];
  minPosition: IPosition; //第一个组件的位置
  relativePosition: IPosition; //相对框选的位置
}

export interface ISpacingCalcConfig {
  disableAdsorption: boolean;
}

export type SpacingChangeInfo = {
  type: string;
  spacingOffset: number;
  scale: number;
  spaceInfo: EquidistantValue;
  controls: SpaceControl[];
  config?: ISpacingCalcConfig;
};

export type SpaceAdjust = { x?: number; y?: number; comps: UIComponent[] };

interface IComponentsBounds {
  [key: string]: IBounds;
}

interface IPositionSize extends IPosition, ISize {}

const horizontalSpaceSize = { width: 6, height: 40 };
const verticalSpaceSize = { width: 40, height: 6 };
const minSpaceNum = 8;
const uiSpaceNum = 15;
const ToleranceNum = 0.1; // 由于js 的小数点问题， 误差只要在0.1 内都可以认为相等

//计算选择的组件形成的框的最小X Y
function getMinXYWithComponents(comps: UIComponent[]): IPosition {
  const firstComp = comps[0];
  const parentMatrix = getCompAbsoluteMatrix(firstComp.parent!);
  const firstCompBounds = firstComp.getViewBoundsInArtboard(parentMatrix);
  let minPositionX = firstCompBounds.left;
  let minPositionY = firstCompBounds.top;
  let cursor = 1;

  while (cursor < comps.length) {
    const comp = comps[cursor];
    const compBounds = getCompAbsoluteBounds(comp, parentMatrix); // comp.getViewBoundsInArtboard();
    minPositionX = GlobalUtils.min(minPositionX, compBounds.left);
    minPositionY = GlobalUtils.min(minPositionY, compBounds.top);
    ++cursor;
  }

  return {
    x: minPositionX,
    y: minPositionY,
  };
}

function getAdsorptionDistanceByScale(scale: number, size: number) {
  if (scale > 10) return 0; // 出现像素的网格后，吸附距离为0
  return GlobalUtils.floor((size * GlobalUtils.ceil(scale / size)) / scale);
}

//获取最小的高或宽
export function getMinWidthOrHeight(comps: UIComponent[], isHorizontal: boolean) {
  if (!comps.length) {
    return 0;
  }
  const parentMatrix = getCompAbsoluteMatrix(comps[0].parent!);
  return GlobalUtils.min(
    ...comps.map((comp) => {
      const compBounds = getCompAbsoluteBounds(comp, parentMatrix); // comp.getViewBoundsInArtboard();
      return isHorizontal ? compBounds.width : compBounds.height;
    }),
  );
}

// 等距离间距允许最大间距值
export const maxSpacingValue = 20000;

export function getBigBoundsWithComponents(comps: UIComponent[], parentMatrix?: Matrix): IBounds {
  if (!comps.length) {
    return BoundsUtils.empty();
  }
  let matrix: Matrix;
  if (!parentMatrix) {
    matrix = getCompAbsoluteMatrix(comps[0].parent!);
  } else {
    matrix = parentMatrix;
  }
  return BoundsUtils.round(getViewBoundsOfComponentsWithArtboard(comps, matrix)!);
}

function computedHorizontalControlByComps(
  targetComp: UIComponent,
  nextComp: UIComponent,
  firstComp: UIComponent,
  lineItem: LineInfo,
  horizontalSpace: number,
  scale: number,
  rowIndex: number,
  colIndex: number,
  parentMatrix: Matrix,
  ignoreComponentSize = false,
): SpaceControl {
  const {
    relativePosition,
    bounds: { top },
  } = lineItem;
  const { height, width } = horizontalSpaceSize;
  const targetCompBounds = getCompAbsoluteBounds(targetComp, parentMatrix); // targetComp.getViewBoundsInArtboard();
  const nextCompBounds = getCompAbsoluteBounds(nextComp, parentMatrix); // nextComp.getViewBoundsInArtboard();
  const firstCompBounds = getCompAbsoluteBounds(firstComp, parentMatrix); // firstComp.getViewBoundsInArtboard();
  const targetBigHeight = targetCompBounds.bottom - top;
  const nextBigHeight = nextCompBounds.bottom - top;
  const minPositionY = GlobalUtils.min(targetCompBounds.top, nextCompBounds.top) - top;
  const relativeDistance = (targetCompBounds.left - firstCompBounds.left) * scale;
  const maxHeight = (GlobalUtils.max(targetBigHeight, nextBigHeight) - minPositionY) * scale;
  const spaceHeight = GlobalUtils.min(height, maxHeight - uiSpaceNum * 2);

  //高过小时，这个控制点忽略
  if (!ignoreComponentSize && spaceHeight < minSpaceNum) {
    return {};
  }

  const position = {
    x:
      relativePosition.x * scale +
      relativeDistance +
      targetCompBounds.width * scale +
      (horizontalSpace * scale - width) / 2,
    y: (relativePosition.y + minPositionY) * scale + (maxHeight - spaceHeight) / 2,
  };

  return {
    position,
    size: { width, height: spaceHeight },
    type: 'horizontal',
    rowIndex,
    colIndex,
  };
}

//计算以行分的水平方向的控制
export function computedHorizontalControlInLineByRow(
  comps: UIComponent[],
  lineItem: LineInfo,
  horizontalSpace: number,
  scale: number,
  ignoreComponentSize = false,
): SpaceControl[] {
  const result: SpaceControl[] = [];
  const len = comps.length;
  if (!len) {
    return result;
  }
  const firstComp = comps[0];
  let targetCursor = 0;
  let parentMatrix: Matrix | undefined = undefined;
  while (targetCursor < len - 1) {
    const targetComp = comps[targetCursor];
    const nextComp = comps[targetCursor + 1];
    if (!parentMatrix) {
      parentMatrix = getCompAbsoluteMatrix(nextComp.parent!);
    }
    const horizontalControl = computedHorizontalControlByComps(
      targetComp,
      nextComp,
      firstComp,
      lineItem,
      horizontalSpace,
      scale,
      lineItem.index,
      targetCursor,
      parentMatrix,
      ignoreComponentSize,
    );
    if (Object.keys(horizontalControl).length) {
      result.push(horizontalControl);
    }
    ++targetCursor;
  }

  return result;
}

//计算以行分垂直方向的控制
function computedVerticalControlOfLineByRow(
  collatedInfo: LineInfos,
  item: LineInfo,
  verticalSpace: number,
  scale: number,
  rowIndex: number,
  colIndex: number,
  ignoreComponentSize = false,
): SpaceControl {
  const { width, height } = verticalSpaceSize;
  const lastLine = collatedInfo.find((col) => col.index === item.index - 1);
  const lastLinePositionX = lastLine?.relativePosition.x || 0;
  const lastLineWidth = lastLine?.bounds.width || 0;
  const lastBigLineWidth = lastLinePositionX + lastLineWidth;
  const nowBigLineWidth = item.relativePosition.x + item.bounds.width;
  const maxLineWidth = GlobalUtils.max(lastBigLineWidth, nowBigLineWidth) * scale;
  const minPositionX = GlobalUtils.min(lastLinePositionX, item.relativePosition.x) * scale;
  const spaceWidth = GlobalUtils.min(width, maxLineWidth - uiSpaceNum * 2);

  //宽过小时，这个控制点忽略
  if (!ignoreComponentSize && spaceWidth < minSpaceNum) {
    return {};
  }

  return {
    position: {
      x: minPositionX + (maxLineWidth - minPositionX - spaceWidth) / 2,
      y: item.relativePosition.y * scale - (verticalSpace * scale - height) / 2 - height,
    },
    size: { width: spaceWidth, height },
    type: 'vertical',
    rowIndex,
    colIndex,
  };
}

function computedVerticalControlByComps(
  targetComp: UIComponent,
  nextComp: UIComponent,
  firstComp: UIComponent,
  lineItem: LineInfo,
  horizontalSpace: number,
  scale: number,
  rowIndex: number,
  colIndex: number,
  parentMatix: Matrix,
  ignoreComponentSize = false,
): SpaceControl {
  const {
    relativePosition,
    bounds: { left },
  } = lineItem;
  const { height, width } = verticalSpaceSize;
  const targetCompBounds = getCompAbsoluteBounds(targetComp, parentMatix); // targetComp.getViewBoundsInArtboard();
  const nextCompBounds = getCompAbsoluteBounds(nextComp, parentMatix); // nextComp.getViewBoundsInArtboard();
  const firstCompBounds = getCompAbsoluteBounds(firstComp, parentMatix); // firstComp.getViewBoundsInArtboard();
  const targetBigWidth = targetCompBounds.right - left;
  const nextBigWidth = nextCompBounds.right - left;
  const minPositionX = GlobalUtils.min(targetCompBounds.left, nextCompBounds.left) - left;
  const maxWidth = (GlobalUtils.max(targetBigWidth, nextBigWidth) - minPositionX) * scale;
  const relativeDistance = (targetCompBounds.top - firstCompBounds.top) * scale;
  const spaceWidth = GlobalUtils.min(width, maxWidth - uiSpaceNum * 2);

  //高过小时，这个控制点忽略
  if (!ignoreComponentSize && spaceWidth < minSpaceNum) {
    return {};
  }

  const position = {
    x: (relativePosition.x + minPositionX) * scale + (maxWidth - spaceWidth) / 2,
    y:
      relativePosition.y * scale +
      relativeDistance +
      targetCompBounds.height * scale +
      (horizontalSpace * scale - height) / 2,
  };

  return {
    position,
    size: { width: spaceWidth, height },
    type: 'vertical',
    rowIndex,
    colIndex,
  };
}

//计算以列分的垂直方向的控制
export function computedVerticalControlInLineByCol(
  comps: UIComponent[],
  lineItem: LineInfo,
  horizontalSpace: number,
  scale: number,
  ignoreComponentSize = false,
): SpaceControl[] {
  const result: SpaceControl[] = [];
  const len = comps.length;
  if (!len) {
    return result;
  }
  const firstComp = comps[0];
  let targetCursor = 0;
  let parentMatrix: Matrix | undefined = undefined;
  while (targetCursor < len - 1) {
    const targetComp = comps[targetCursor];
    const nextComp = comps[targetCursor + 1];
    if (!parentMatrix) {
      parentMatrix = getCompAbsoluteMatrix(nextComp.parent!);
    }
    const verticalControl = computedVerticalControlByComps(
      targetComp,
      nextComp,
      firstComp,
      lineItem,
      horizontalSpace,
      scale,
      targetCursor,
      lineItem.index,
      parentMatrix,
      ignoreComponentSize,
    );
    if (Object.keys(verticalControl).length) {
      result.push(verticalControl);
    }
    ++targetCursor;
  }

  return result;
}

//计算以列分水平方向的控制
function computedHorizontalControlOfLineByCol(
  collatedInfo: LineInfos,
  item: LineInfo,
  horizontalSpace: number,
  scale: number,
  rowIndex: number,
  colIndex: number,
  ignoreComponentSize = false,
): SpaceControl {
  const { width, height } = horizontalSpaceSize;
  const lastLine = collatedInfo.find((col) => col.index === item.index - 1);
  const lastLinePositionY = lastLine?.relativePosition.y || 0;
  const lastLineHeight = lastLine?.bounds.height || 0;
  const lastBigLineHeight = lastLinePositionY + lastLineHeight;
  const nowBigLineHeight = item.relativePosition.y + item.bounds.height;
  const maxLineHeight = GlobalUtils.max(lastBigLineHeight, nowBigLineHeight) * scale;
  const minPositionY = GlobalUtils.min(lastLinePositionY || 0, item.relativePosition.y) * scale;
  const spaceHeight = GlobalUtils.min(height, maxLineHeight - uiSpaceNum * 2);

  //高度过小时忽略,不显示
  if (!ignoreComponentSize && spaceHeight < minSpaceNum) {
    return {};
  }

  return {
    position: {
      x: item.relativePosition.x * scale - (horizontalSpace * scale - width) / 2 - width,
      y: minPositionY + (maxLineHeight - minPositionY - spaceHeight) / 2,
    },
    size: { width, height: spaceHeight },
    type: 'horizontal',
    rowIndex,
    colIndex,
  };
}

//根据获取到的间隔信息，进行控制块位置的计算
export function computedControlPosition(
  spaceInfo: EquidistantValue,
  scale: number,
  ignoreComponentSize = false, //忽略组件大小
): SpaceControl[] {
  const result: SpaceControl[] = [];
  const { horizontalSpace, verticalSpace, collatedInfo, isRow } = spaceInfo;
  if (
    typeof horizontalSpace === 'undefined' ||
    typeof verticalSpace === 'undefined' ||
    typeof collatedInfo === 'undefined'
  ) {
    return result;
  }
  //计算行或列的控制点
  if (isRow) {
    //按行分
    collatedInfo.forEach((item) => {
      const arr = item.comps;
      const control = computedHorizontalControlInLineByRow(arr, item, horizontalSpace, scale, ignoreComponentSize);
      result.push(...control);
      if (item.index) {
        result.push(
          computedVerticalControlOfLineByRow(
            collatedInfo,
            item,
            verticalSpace,
            scale,
            item.index - 1,
            0,
            ignoreComponentSize,
          ),
        );
      }
    });
  } else {
    collatedInfo.forEach((item) => {
      const arr = item.comps;
      const control = computedVerticalControlInLineByCol(arr, item, verticalSpace, scale, ignoreComponentSize);
      result.push(...control);
      if (item.index) {
        result.push(
          computedHorizontalControlOfLineByCol(
            collatedInfo,
            item,
            horizontalSpace,
            scale,
            0,
            item.index - 1,
            ignoreComponentSize,
          ),
        );
      }
    });
  }
  return result;
}

function testMoveLimitAndAdsorption(
  minSize: number,
  initialGap: number,
  control: SpaceControl,
  mouseDiff: { x: number; y: number },
  scaleFactor: number,
  config?: ISpacingCalcConfig,
) {
  let gapChange = getGapByControlOffset(mouseDiff, control);
  const adsorptionDistance = getAdsorptionDistanceByScale(scaleFactor, 5);
  //吸附
  if (!config?.disableAdsorption && GlobalUtils.abs(initialGap + gapChange) < adsorptionDistance) {
    gapChange -= initialGap + gapChange;
  }

  let stop = false;
  // 反向的限制
  if (initialGap + gapChange < 1) {
    if (Math.abs(initialGap + gapChange) > minSize || minSize === 0) {
      stop = true;
      gapChange = -(initialGap + minSize - 1);
    }
  } else {
    if (Math.abs(initialGap + gapChange) > maxSpacingValue) {
      stop = true;
      gapChange = maxSpacingValue - initialGap;
    }
  }

  //对线条没有高度的特殊处理
  const gap = GlobalUtils.round(initialGap + gapChange - (minSize === 0 && stop ? 1 : 0));

  return {
    gapChange: GlobalUtils.round(gapChange),
    gap,
  };
}

export interface ICompBoundsProperty {
  id: string;
  size: ISize;
  rotate: number;
  position: IPosition;
}

function getCompPositionWhenMoveControl(
  comp: UIComponent,
  allCompInitialInfoRelativeToArtboard: ICompBoundsProperty[],
  offset: IPosition,
) {
  const initialPositionInArtBoard = allCompInitialInfoRelativeToArtboard.find((comp1) => comp.id === comp1.id)
    ?.position;
  const { size } = comp;
  const bounds1 = getBoundsInParent({
    size,
    rotate: comp.rotateRelativeToArtboard,
    position: initialPositionInArtBoard!,
  });
  const centerInArtBoard = getCenter(
    { x: bounds1.left, y: bounds1.top },
    { height: bounds1.height, width: bounds1.width },
    0,
  );
  const newCenterInArtBoard = { x: centerInArtBoard.x + offset.x, y: centerInArtBoard.y + offset.y };
  const centerInCurrentContainer = tansPointInArtBoardToGroup([newCenterInArtBoard], comp.parent!)[0];
  return getNWPoint(centerInCurrentContainer, size, 0);
}

export function getMovingInfoWhenControlMove(
  spaceInfo: EquidistantValue,
  control: SpaceControl,
  mouseDiff: { x: number; y: number },
  allCompInitialInfoRelativeToArtboard: ICompBoundsProperty[],
  scaleFactor: number,
  config?: ISpacingCalcConfig,
) {
  const { isRow, collatedInfo, horizontalSpace, verticalSpace } = spaceInfo;
  const isHorizontal: boolean = control.type === 'horizontal';
  const allComps: UIComponent[] = collatedInfo?.reduce((acc, cur) => acc.concat(cur.comps), [] as UIComponent[]) || [];

  const minSize = getMinWidthOrHeight(allComps, isHorizontal);
  const result = collatedInfo!.reduce(
    (acc, item, index) => {
      //以行分块
      if (isRow) {
        if (isHorizontal) {
          const LineCompExcludeFirst = item.comps.slice(1);
          acc.neededChangeComp = acc.neededChangeComp.concat(LineCompExcludeFirst);
          const { gapChange, gap } = testMoveLimitAndAdsorption(
            minSize,
            horizontalSpace!,
            control,
            mouseDiff,
            scaleFactor,
            config,
          );
          acc.gapX = gap;
          LineCompExcludeFirst.forEach((comp, index) => {
            const offset = { x: gapChange * (index + 1), y: 0 };
            const newPosition = getCompPositionWhenMoveControl(comp, allCompInitialInfoRelativeToArtboard, offset);
            acc.dynamicInfoMap[comp.id] = { position: newPosition };
          });
        } else {
          if (item.index !== 0) {
            acc.neededChangeComp = acc.neededChangeComp.concat(item.comps);
            const { gapChange, gap } = testMoveLimitAndAdsorption(
              minSize,
              verticalSpace!,
              control,
              mouseDiff,
              scaleFactor,
              config,
            );
            acc.gapY = gap;
            item.comps.forEach((comp) => {
              const offset = { x: 0, y: gapChange * index };
              const newPosition = getCompPositionWhenMoveControl(comp, allCompInitialInfoRelativeToArtboard, offset);
              acc.dynamicInfoMap[comp.id] = { position: newPosition };
            });
          }
        }
      } else {
        //以列分
        if (control.type === 'vertical') {
          const LineCompExcludeFirst = item.comps.slice(1);
          acc.neededChangeComp = acc.neededChangeComp.concat(LineCompExcludeFirst);
          const { gapChange, gap } = testMoveLimitAndAdsorption(
            minSize,
            verticalSpace!,
            control,
            mouseDiff,
            scaleFactor,
            config,
          );
          acc.gapY = gap;

          LineCompExcludeFirst.forEach((comp, index) => {
            const offset = { x: 0, y: gapChange * (index + 1) };
            const newPosition = getCompPositionWhenMoveControl(comp, allCompInitialInfoRelativeToArtboard, offset);
            acc.dynamicInfoMap[comp.id] = { position: newPosition };
          });
        } else {
          if (item.index !== 0) {
            acc.neededChangeComp = acc.neededChangeComp.concat(item.comps);
            const { gapChange, gap } = testMoveLimitAndAdsorption(
              minSize,
              horizontalSpace!,
              control,
              mouseDiff,
              scaleFactor,
              config,
            );
            acc.gapX = gap;
            item.comps.forEach((comp) => {
              const offset = { x: gapChange * index, y: 0 };
              const newPosition = getCompPositionWhenMoveControl(comp, allCompInitialInfoRelativeToArtboard, offset);
              acc.dynamicInfoMap[comp.id] = { position: newPosition };
            });
          }
        }
      }
      return acc;
    },
    {
      neededChangeComp: [],
      dynamicInfoMap: {},
    } as { neededChangeComp: UIComponent[]; dynamicInfoMap: DynamicInfoMap; gapX?: number; gapY?: number },
  );

  const parent = result.neededChangeComp[0].parent!;
  const changedComp = Object.entries(result.dynamicInfoMap).map(([id, value]) => {
    const { position, size, rotate } = value;
    const comp = allComps.find((comp) => comp.id === id)!;
    return {
      id,
      type: ComponentChangeType.Edit,
      size: size || comp.size,
      position: position || comp?.position,
      rotate: rotate || comp?.rotate,
    };
  });
  const { patches } = parent.getPositionPatchesOfChildrenChanged(changedComp, true)!;
  result.dynamicInfoMap = extractDynamicInfoFromPatch(patches);

  return result;
}

export function computedSpaceInfoWhenMove(spaceAdjust: SpaceAdjust, oldSpaceInfo: EquidistantValue) {
  const { isEquidistant, isRow, collatedInfo, minXMinYPosition } = oldSpaceInfo;
  const minX = minXMinYPosition?.x || 0;
  const minY = minXMinYPosition?.y || 0;
  const newSpaceInfo: EquidistantValue = { isEquidistant, isRow };
  if (!isEquidistant) {
    return { isEquidistant: false };
  }
  newSpaceInfo.horizontalSpace = spaceAdjust?.x || 0;
  newSpaceInfo.verticalSpace = spaceAdjust?.y || 0;
  const newComps = spaceAdjust?.comps || [];
  let parentMatrix: Matrix | undefined = undefined;
  newSpaceInfo.collatedInfo = collatedInfo!.map((data) => {
    const { comps } = data;
    data.comps = comps.map((comp) => {
      if (!parentMatrix) {
        parentMatrix = getCompAbsoluteMatrix(comp.parent!);
      }
      const newComp = newComps.find((cm) => cm.id === comp.id);
      if (newComp) {
        return newComp;
      }
      return comp;
    });
    const bigBounds = getBigBoundsWithComponents(data.comps, parentMatrix);
    data.bounds = bigBounds;
    data.relativePosition = {
      x: bigBounds.left - minX,
      y: bigBounds.top - minY,
    };
    return data;
  });
  const allComps: UIComponent[] =
    newSpaceInfo.collatedInfo?.reduce((acc, cur) => acc.concat(cur.comps), [] as UIComponent[]) || [];
  newSpaceInfo.minXMinYPosition = getMinXYWithComponents(allComps);
  return newSpaceInfo;
}

//----------------------------------------------等间距后的拖拽交换----------------------------------------------------------

export type BoundsWithCenter = {
  comp: UIComponent;
  bounds: IBounds;
  centerPoint: IPoint;
};

export function getBlueOutline(): IProperties {
  const draggedCompTempStyleProperties = {
    fill: {
      color: { r: 0, g: 157, b: 255, a: 0.3 },
      type: 'solid',
    },
    outline: {
      color: { r: 0, g: 157, b: 255, a: 1 },
      thickness: 2,
    },
  };
  return draggedCompTempStyleProperties as IProperties;
}

// 获取选中组件的bounds及中心点(相对page)
function computedCompBoundsWithCenter(comps: UIComponent[]): BoundsWithCenter[] {
  let result: BoundsWithCenter[] = [];
  const parentMatrix = comps.length ? getCompAbsoluteMatrix(comps[0].parent!) : undefined;
  comps.forEach((comp) => {
    const bounds = comp.getViewBoundsInPage(parentMatrix);
    const centerPoint = BoundsUtils.center(bounds);
    result.push({
      comp,
      bounds: bounds,
      centerPoint: {
        x: centerPoint.left,
        y: centerPoint.top,
      },
    });
  });
  return result;
}

// 获取等间距拖拽显示的控制点的坐标
export function getHoverCompCenterPoint(comps: UIComponent[], mouse: IPoint, scale: number) {
  const boundsInfo = computedCompBoundsWithCenter(comps);
  return boundsInfo.find((info) => {
    return (
      info.bounds.width * scale > 30 &&
      info.bounds.height * scale > 30 &&
      BoundsUtils.isContainerPoint(info.bounds, { left: mouse.x, top: mouse.y })
    );
  });
}

// 获取一行 或者 一列 触发位置变化的边界值 相对于画板
function getOneRowOrOneColBoundary(space: number, compBounds: IBounds[], isRow: boolean, minXMinYPosition: IPoint) {
  const prop = isRow ? 'height' : 'width';
  const distanceToArtboard = isRow ? minXMinYPosition.y : minXMinYPosition.x;
  const boundaryArr: number[] = [];
  compBounds.reduce((acc, item) => {
    boundaryArr.push(acc + distanceToArtboard + (space + item[prop]) / 2);
    return acc + space + item[prop];
  }, 0);
  return boundaryArr;
}

// 根据拖动的组件的bounds，获取触发的边界值
function getTriggerBoundary(
  compsWithBoundsArr: ILineInfoWithIBounds[],
  moveComp: IComponentBoundsInfo,
  spaceInfo: BasicEquidistantValue,
  singleRowOrCol: boolean,
) {
  const { isRow, verticalSpace, horizontalSpace, minXMinYPosition } = spaceInfo;
  const mainAxisSpace = isRow ? verticalSpace : horizontalSpace;
  const crossAxisSpace = isRow ? horizontalSpace : verticalSpace;

  if (singleRowOrCol) {
    // 单行或单列
    let indexFlag: [number] = [0];
    const compBounds = compsWithBoundsArr
      .filter((item, index) => {
        if (item.comps[0].id === moveComp.id) {
          indexFlag[0] = index;
          return false;
        }
        return true;
      })
      .map((item) => item.bounds);
    const mainAxisBoundary = getOneRowOrOneColBoundary(mainAxisSpace, compBounds, isRow, minXMinYPosition);
    return { mainAxisBoundary, crossAxisBoundary: null, indexArr: indexFlag };
  } else {
    // 有行有列的情况，计算边界值
    let mainAxisIndex = 0;
    let indexFlag: [number, number] = [0, 0];
    for (let i = 0, len = compsWithBoundsArr.length; i < len; i++) {
      const crossAxisComps = compsWithBoundsArr[i].comps;
      for (let j = 0, len = crossAxisComps.length; j < len; j++) {
        if (crossAxisComps[j].id === moveComp.id) {
          mainAxisIndex = i;
          indexFlag = [i, j];
          break;
        }
      }
      if (mainAxisIndex != 0) {
        break;
      }
    }
    const mainAxisBounds = compsWithBoundsArr
      .filter((_item, index) => index != mainAxisIndex)
      .map((item) => item.bounds);
    const mainAxisBoundary = getOneRowOrOneColBoundary(mainAxisSpace, mainAxisBounds, isRow, minXMinYPosition);

    let crossAxisBoundary: number[][] = [];
    compsWithBoundsArr.forEach((item) => {
      const crossBounds = item.comps.map((it) => it.originBounds);
      const oneCrossBoundaryArr = getOneRowOrOneColBoundary(crossAxisSpace!, crossBounds, !isRow, item.minPosition);
      crossAxisBoundary.push(oneCrossBoundaryArr);
    });
    return { mainAxisBoundary, crossAxisBoundary, indexArr: indexFlag };
  }
}

/**
 *
 * @param movedIndex 移动之后的索引
 * @param originIndex 移动之前的索引
 * @param mainAxisCompareProp 主轴上比较的属性
 * @param otherCompMoved 其他组件移动的距离
 * @param currentAxisComps 当前轴上的所有组件
 */
function changeInCurrentAxis(
  movedIndex: number,
  originIndex: number,
  mainAxisCompareProp: 'top' | 'left',
  otherCompMoved: number,
  currentAxisComps: IBoundsUIComponent[],
) {
  const direction = movedIndex - originIndex > 0;
  let start;
  let end;
  if (direction) {
    start = originIndex + 1;
    end = movedIndex + 1;
  } else {
    start = movedIndex;
    end = originIndex;
  }

  const otherCompsMoveDelta = Object.assign(
    {},
    { left: 0, top: 0 },
    { [mainAxisCompareProp]: (direction ? -1 : 1) * otherCompMoved },
  );
  let changeCompsArr: IComponentBoundsInfo[] = [];

  for (let i = start, index = 0; i < end; i++) {
    const id = currentAxisComps[i].id;

    const bounds = currentAxisComps[i].originBounds;
    changeCompsArr[index] = {
      id,
      isGroup: currentAxisComps[i].isGroup,
      groupChildren: getGroupChildren(currentAxisComps[i]),
      position: currentAxisComps[i].positionInParent,
      isDragComp: false,
      bounds,
    };
    changeCompsArr[index].movedBounds = BoundsUtils.offsetBounds(bounds, otherCompsMoveDelta);
    index++;
  }
  return changeCompsArr;
}

/**
 * 跨主轴拖拽的时候，获取目标组件在主轴上的差值
 * @param originMainAxis 目标组件拖动之前的在主轴上的索引
 * @param movedMainAxisIndex 目标组件拖动之后的在主轴上的索引
 * @param compsArr 当前所有组件
 * @param mainAxisSpace
 * @param isRow
 */
function getMainAxisDelta(
  originMainAxis: number,
  movedMainAxisIndex: number,
  compsWithBoundsArr: ILineInfoWithIBounds[],
  mainAxisSpace: number,
  isRow: boolean,
) {
  const mainAxisBoundsProp = isRow ? 'height' : 'width';
  const isDragAheadInMainAxis = originMainAxis - movedMainAxisIndex > 0;

  const start = GlobalUtils.min(originMainAxis, movedMainAxisIndex);
  const end = GlobalUtils.max(originMainAxis, movedMainAxisIndex);

  let mainAxisDelta = 0;
  for (let i = start; i < end; i++) {
    mainAxisDelta += compsWithBoundsArr[i].bounds[mainAxisBoundsProp] + mainAxisSpace;
  }
  return (isDragAheadInMainAxis ? -1 : 1) * mainAxisDelta;
}

// 获取拖拽组件跨行拖拽后的主轴的索引和交叉轴的索引
function getMainAxisIndexAndCrossAxisIndex(
  compsWithBoundsArr: ILineInfoWithIBounds[],
  mainAxisSpaceArr: number[],
  crossAxisSpaceArr: number[][],
  moveComp: IComponentBoundsInfo,
  isRow: boolean,
) {
  const mainAxisProp = isRow ? 'height' : 'width';
  const mainAxisCompareProp = isRow ? 'top' : 'left';
  const crossAxisCompareProp = isRow ? 'left' : 'top';
  const mainAxisLen = mainAxisSpaceArr.length;
  let movedMainAxisIndex = mainAxisLen;

  mainAxisSpaceArr.find((item, index) => {
    const boundary = item + (compsWithBoundsArr[index].bounds[mainAxisProp] - moveComp.movingBounds![mainAxisProp]) / 2;
    if (moveComp.movingBounds![mainAxisCompareProp] < boundary) {
      movedMainAxisIndex = index;
      return true;
    }
  });

  const crossAxisLen = crossAxisSpaceArr[movedMainAxisIndex].length;
  let movedCrossIndex = crossAxisLen;

  crossAxisSpaceArr[movedMainAxisIndex].find((item, index) => {
    if (moveComp.movingBounds![crossAxisCompareProp] < item) {
      movedCrossIndex = index;
      return true;
    }
  });

  return { movedMainAxisIndex, movedCrossIndex };
}

// 选中的组件只是单行或者单列的情况下组件的改变情况
function changeSingleRowOrCol(
  compsWithBoundsArr: ILineInfoWithIBounds[],
  moveComp: IComponentBoundsInfo,
  mainAxisSpaceArr: number[],
  spaceInfo: EquidistantValue,
) {
  const { isRow, verticalSpace, horizontalSpace } = spaceInfo;
  const mainAxisCompareProp = isRow ? 'top' : 'left';
  const mainAxisEndProp = isRow ? 'bottom' : 'right';
  const mainAxisSpace = (isRow ? verticalSpace : horizontalSpace) || 0;
  const otherCompMoved = (isRow ? moveComp.bounds['height'] : moveComp.bounds['width']) + mainAxisSpace;
  const len = mainAxisSpaceArr.length;
  const originMainIndex = moveComp.index![0];

  let movedIndex = len;
  for (let i = 0; i < len; i++) {
    if (moveComp.movingBounds![mainAxisCompareProp] < mainAxisSpaceArr[i]) {
      movedIndex = i;
      break;
    }
  }

  const targetProp = originMainIndex > movedIndex ? mainAxisCompareProp : mainAxisEndProp;
  const dragCompMoveDelta =
    compsWithBoundsArr[movedIndex].comps[0].originBounds[targetProp] - moveComp.bounds[targetProp];

  moveComp.movedBounds = BoundsUtils.offsetBounds(
    moveComp.bounds,
    Object.assign({}, { left: 0, top: 0 }, { [mainAxisCompareProp]: dragCompMoveDelta }),
  );

  let changeCompsArr: IComponentBoundsInfo[] = [];
  const mainAxisComps = compsWithBoundsArr.map((item) => item.comps[0]);
  changeCompsArr = changeInCurrentAxis(movedIndex, originMainIndex, mainAxisCompareProp, otherCompMoved, mainAxisComps);
  changeCompsArr.push(moveComp);
  return changeCompsArr;
}

// 选中的组件时多行或者多列的情况下 组件没有跨越主轴的情况
function changeMultiRowAndColInCurrentAxis(
  compsWithBoundsArr: ILineInfoWithIBounds[],
  movedMainAxisIndex: number,
  movedCrossIndex: number,
  spaceInfo: EquidistantValue,
  moveComp: IComponentBoundsInfo,
) {
  const { isRow, horizontalSpace, verticalSpace } = spaceInfo;
  const crossAxisCompareProp = isRow ? 'left' : 'top';
  const crossAxisEndProp = isRow ? 'right' : 'bottom';
  const crossAxisSpace = (isRow ? horizontalSpace : verticalSpace) || 0;
  const otherCompsCrossMoved = (isRow ? moveComp.bounds['width'] : moveComp.bounds['height']) + crossAxisSpace;
  const originCrossAxis = moveComp.index![1];
  const originCrossIndex = moveComp.index![1];

  movedCrossIndex = Math.min(movedCrossIndex, compsWithBoundsArr[movedMainAxisIndex].comps.length - 1);
  let dragDelta = 0;
  const isDragAhead = originCrossIndex > movedCrossIndex;
  if (isDragAhead) {
    dragDelta =
      compsWithBoundsArr[movedMainAxisIndex].comps[movedCrossIndex].originBounds[crossAxisCompareProp] -
      moveComp.bounds[crossAxisCompareProp];
  } else {
    dragDelta =
      compsWithBoundsArr[movedMainAxisIndex].comps[movedCrossIndex].originBounds[crossAxisEndProp] -
      moveComp.bounds[crossAxisEndProp];
  }

  const dragCompMoveDelta = Object.assign({}, { left: 0, top: 0 }, { [crossAxisCompareProp]: dragDelta });
  moveComp.movedBounds = BoundsUtils.offsetBounds(moveComp.bounds, dragCompMoveDelta);
  let changeCompsArr: IComponentBoundsInfo[] = [];
  changeCompsArr = changeInCurrentAxis(
    movedCrossIndex,
    originCrossAxis,
    crossAxisCompareProp,
    otherCompsCrossMoved,
    compsWithBoundsArr[movedMainAxisIndex].comps,
  );
  changeCompsArr.push(moveComp);

  return changeCompsArr;
}

// 选中的组件时多行或者多列的情况下 组件跨越主轴的情况
function changeMultiRowAndColAcrossAxis(
  compsWithBoundsArr: ILineInfoWithIBounds[],
  movedMainAxisIndex: number,
  movedCrossIndex: number,
  spaceInfo: EquidistantValue,
  moveComp: IComponentBoundsInfo,
) {
  const { isRow, verticalSpace, horizontalSpace } = spaceInfo;
  const crossAxisCompareProp = isRow ? 'left' : 'top';
  const crossAxisProp = isRow ? 'width' : 'height';
  const mainAxisSpace = (isRow ? verticalSpace : horizontalSpace) || 0;
  const crossAxisPositionProp = isRow ? 'x' : 'y';

  const mainAxisCompareProp = isRow ? 'top' : 'left';
  const crossAxisSpace = (spaceInfo.isRow ? spaceInfo.horizontalSpace : spaceInfo.verticalSpace) || 0;
  const otherCompsCrossMoved = (isRow ? moveComp.bounds['width'] : moveComp.bounds['height']) + crossAxisSpace;

  const crossAxisEndProp = isRow ? 'right' : 'bottom';

  const [originMainAxis, originCrossAxis] = moveComp.index!;
  const originCrossAxisComps = compsWithBoundsArr[originMainAxis].comps;
  const originCrossAxisLen = originCrossAxisComps.length;

  const movedCrossAxisComps = compsWithBoundsArr[movedMainAxisIndex].comps;
  const movedCrossAxisLen = movedCrossAxisComps.length;

  const mainAxisOtherCompsMoveDelta = Object.assign(
    {},
    { left: 0, top: 0 },
    { [crossAxisCompareProp]: -(crossAxisSpace + moveComp.bounds[crossAxisProp]) },
  );

  // 改变当前主轴的其他主件
  let mainAxisChangeCompsArr: IComponentBoundsInfo[] = [];
  for (let i = originCrossAxis + 1, index = 0; i < originCrossAxisLen; i++) {
    const bounds = originCrossAxisComps[i].originBounds;
    mainAxisChangeCompsArr[index] = {
      id: originCrossAxisComps[i].id,
      isGroup: originCrossAxisComps[i].isGroup,
      groupChildren: getGroupChildren(originCrossAxisComps[i]),
      position: originCrossAxisComps[i].positionInParent,
      isDragComp: false,
      bounds,
    };
    mainAxisChangeCompsArr[index].movedBounds = BoundsUtils.offsetBounds(bounds, mainAxisOtherCompsMoveDelta);
    index++;
  }

  // 改变拖动的组件在主轴上的变化
  const mainAxisDelta = getMainAxisDelta(
    originMainAxis,
    movedMainAxisIndex,
    compsWithBoundsArr,
    mainAxisSpace,
    spaceInfo.isRow!,
  );

  const crossAxisDelta =
    movedCrossIndex === 0
      ? compsWithBoundsArr[movedMainAxisIndex].minPosition[crossAxisPositionProp] -
        moveComp.bounds[crossAxisCompareProp]
      : compsWithBoundsArr[movedMainAxisIndex].comps[movedCrossIndex - 1].originBounds[crossAxisEndProp] +
        crossAxisSpace -
        moveComp.bounds[crossAxisCompareProp];
  const dragDelta = { [crossAxisCompareProp]: crossAxisDelta, [mainAxisCompareProp]: mainAxisDelta };
  moveComp.movedBounds = BoundsUtils.offsetBounds(moveComp.bounds, dragDelta as { left: number; top: number });

  // 改变插入到主轴的其他元素
  const crossAxisOtherCompsMoveDelta = Object.assign(
    {},
    { left: 0, top: 0 },
    { [crossAxisCompareProp]: otherCompsCrossMoved },
  );
  let crossAxisChangeCompsArr: IComponentBoundsInfo[] = [];
  for (let i = movedCrossIndex, index = 0; i < movedCrossAxisLen; i++) {
    const bounds = movedCrossAxisComps[i].originBounds;
    crossAxisChangeCompsArr[index] = {
      id: movedCrossAxisComps[i].id,
      isGroup: movedCrossAxisComps[i].isGroup,
      groupChildren: getGroupChildren(movedCrossAxisComps[i]),
      position: movedCrossAxisComps[i].positionInParent,
      isDragComp: false,
      bounds,
    };
    crossAxisChangeCompsArr[index].movedBounds = BoundsUtils.offsetBounds(bounds, crossAxisOtherCompsMoveDelta);
    index++;
  }
  const changeCompsArr = [...mainAxisChangeCompsArr, moveComp, ...crossAxisChangeCompsArr];

  return changeCompsArr;
}

function getPositionByMoveComp(
  compsWithBoundsArr: ILineInfoWithIBounds[],
  moveComp: IComponentBoundsInfo,
  mainAxisSpaceArr: number[],
  crossAxisSpaceArr: null | number[][],
  spaceInfo: BasicEquidistantValue,
  singleRowOrCol: boolean,
) {
  let changeCompsArr: IComponentBoundsInfo[] = [];

  if (singleRowOrCol) {
    changeCompsArr = changeSingleRowOrCol(compsWithBoundsArr, moveComp, mainAxisSpaceArr, spaceInfo);
  } else {
    if (!moveComp.index || crossAxisSpaceArr === null || spaceInfo.isRow === undefined) {
      return null;
    }
    const { movedMainAxisIndex, movedCrossIndex } = getMainAxisIndexAndCrossAxisIndex(
      compsWithBoundsArr,
      mainAxisSpaceArr,
      crossAxisSpaceArr,
      moveComp,
      spaceInfo.isRow,
    );

    const originMainAxis = moveComp.index[0];
    const inSelfMainAxis = originMainAxis === movedMainAxisIndex;
    if (inSelfMainAxis) {
      changeCompsArr = changeMultiRowAndColInCurrentAxis(
        compsWithBoundsArr,
        movedMainAxisIndex,
        movedCrossIndex,
        spaceInfo,
        moveComp,
      );
    } else {
      changeCompsArr = changeMultiRowAndColAcrossAxis(
        compsWithBoundsArr,
        movedMainAxisIndex,
        movedCrossIndex,
        spaceInfo,
        moveComp,
      );
    }
  }

  return changeCompsArr;
}

export function computedSwitchInfoWhenMove(
  compsWithBoundsArr: ILineInfoWithIBounds[],
  spaceInfo: BasicEquidistantValue,
  moveComp: IComponentBoundsInfo,
  totalComps: number,
) {
  if (totalComps < 2) {
    return null;
  }
  const singleRowOrCol = totalComps === compsWithBoundsArr.length;

  const { mainAxisBoundary, crossAxisBoundary, indexArr } = getTriggerBoundary(
    compsWithBoundsArr,
    moveComp,
    spaceInfo,
    singleRowOrCol,
  );
  moveComp.index = indexArr;
  const changeCompsArr = getPositionByMoveComp(
    compsWithBoundsArr,
    moveComp,
    mainAxisBoundary,
    crossAxisBoundary,
    spaceInfo,
    singleRowOrCol,
  );
  return changeCompsArr;
}

// 获取组里面的所有子组件的id
export function getGroupChildren(comp: UIComponent) {
  let compIdsArr: string[] = [];
  function getCompIdArr(comp: UIComponent) {
    if (comp instanceof UIGroupComponent) {
      comp.components.forEach((item: UIComponent) => {
        getCompIdArr(item);
      });
    } else {
      compIdsArr.push(comp.id);
    }
  }
  getCompIdArr(comp);
  return compIdsArr;
}

/****************************** 等间距计算类 ComponentsSpaceHelper ******************************/
export class ComponentsSpaceHelper {
  private selectedComponents: UIComponent[];
  // 所有选中组件相对于父组件的边距对象
  private compsAbsoluteBounds: IComponentsBounds = {};
  // 所有选中组件构成的最小x、y、width、height对象
  private selectedCompsMinXYWidthHeight: IPositionSize | undefined;
  // 当前计算模式是行/列
  private _currentCalcIsRow: boolean = false;
  // 当前计算的行/列是否合并处理
  private _currentCalcIsMerge: boolean = false;

  private readonly EquidistantConfig = [
    { isRow: true, isMerge: true }, //获取水平的并行
    { isRow: false, isMerge: true }, //获取垂直的并列
    { isRow: true, isMerge: false }, //获取水平的未并行
    { isRow: false, isMerge: false }, //获取垂直的未并列
  ];

  constructor(selectedComponents: UIComponent[]) {
    this.selectedComponents = selectedComponents;
    this.initData();
  }

  private get currentCalcIsRow() {
    return this._currentCalcIsRow;
  }

  private set currentCalcIsRow(value: boolean) {
    this._currentCalcIsRow = value;
  }

  private get currentCalcIsMerge() {
    return this._currentCalcIsMerge;
  }

  private set currentCalcIsMerge(value: boolean) {
    this._currentCalcIsMerge = value;
  }

  private get compareAndTargetProp(): {
    targetProp: TargetProp;
    compareProp: CompareProp;
  } {
    return {
      targetProp: this.currentCalcIsRow ? 'top' : 'left',
      compareProp: this.currentCalcIsRow ? 'bottom' : 'right',
    };
  }

  public getSelectedCompsMinXYWidthHeight() {
    return this.selectedCompsMinXYWidthHeight;
  }

  /**
   * 计算间距信息
   * @returns {EquidistantValue}
   */
  public getEquidistantInfo(): EquidistantValue {
    const selectedComponents = this.selectedComponents;
    if (selectedComponents.length < 2) {
      return { isEquidistant: false };
    }

    let cursor = 0;
    let rowLineObject: LineObject | undefined;
    let columnLineObject: LineObject | undefined;
    let lineObject: LineObject;

    while (cursor < this.EquidistantConfig.length) {
      const { isRow, isMerge } = this.EquidistantConfig[cursor];
      this.currentCalcIsRow = isRow;
      this.currentCalcIsMerge = isMerge;
      // 减少重复计算
      if (isRow) {
        rowLineObject = rowLineObject ?? this.getCompsLineObject();
        lineObject = rowLineObject;
      } else {
        columnLineObject = columnLineObject ?? this.getCompsLineObject();
        lineObject = columnLineObject;
      }

      const data = this.getSplitEquidistant(lineObject);

      if (data.isEquidistant) {
        return data;
      }
      cursor++;
    }

    return { isEquidistant: false };
  }

  private initData() {
    if (this.selectedComponents.length > 1) {
      this.getCompsAbsoluteBoundsInParentMatrix();
      this.getCompsMinXYWidthHeight();
    }
  }

  /**
   * 计算 parentMatrix 与 compsAbsoluteBounds
   */
  private getCompsAbsoluteBoundsInParentMatrix() {
    const selectedComponents = this.selectedComponents;
    const parentMatrix = getCompAbsoluteMatrix(selectedComponents[0].parent!);
    const compsAbsoluteBounds: IComponentsBounds = {};

    selectedComponents.forEach((comp) => {
      compsAbsoluteBounds[comp.id] = getCompAbsoluteBounds(comp, parentMatrix); // comp.getViewBoundsInArtboard();
    });

    this.compsAbsoluteBounds = compsAbsoluteBounds;
  }

  /**
   * 在父矩阵中计算选择的组件形成的框的最小X Y width height
   */
  private getCompsMinXYWidthHeight() {
    const { selectedComponents, compsAbsoluteBounds } = this;
    const firstComp = selectedComponents[0];
    const firstCompBounds = compsAbsoluteBounds[firstComp.id];

    this.selectedCompsMinXYWidthHeight = selectedComponents.reduce(
      (prev, comp) => {
        const compBounds = compsAbsoluteBounds[comp.id];
        return {
          x: Math.min(prev.x, compBounds.left),
          y: Math.min(prev.y, compBounds.top),
          width: Math.min(prev.width, compBounds.width),
          height: Math.min(prev.height, compBounds.height),
        };
      },
      {
        x: firstCompBounds.left,
        y: firstCompBounds.top,
        width: firstCompBounds.width,
        height: firstCompBounds.height,
      },
    );
  }

  /**
   * 对组件按照X轴或Y轴进行排序
   * 按照行分组时以X轴排序, 列分组以Y轴排序
   * @param comps 需要排序的组件集
   * @returns {UIComponent[]} 按照方向值排序后的组件
   */
  private sortCompsByPositionXOrY(comps: UIComponent[]): UIComponent[] {
    const { currentCalcIsRow: isSortX } = this;
    if (!comps.length) {
      return [];
    }
    const { compsAbsoluteBounds } = this;
    return sortBy(comps, function (item) {
      const { left, top } = compsAbsoluteBounds[item.id]; // item.getViewBoundsInArtboard();
      return isSortX ? left : top;
    });
  }

  /**
   * 获取所有组件的位置构造的数组
   * @param comps 组件集
   * @param compsAbsoluteBounds 所有组件的位置(键值对形式)
   * @returns {IBounds[]}
   */
  private getBoundsOfComponentsInArtboard(comps: UIComponent[]): IBounds[] {
    const { compsAbsoluteBounds } = this;
    return comps.map((comp) => {
      return compsAbsoluteBounds[comp.id]; // comp.getViewBoundsInArtboard();
    });
  }

  /**
   * 获取组件的最大范围
   * @param comps 组件集(数组)
   * @returns {IBounds}
   */
  private getBigBoundsWithComponentsBounds(comps: UIComponent[]): IBounds {
    if (!comps.length) {
      return BoundsUtils.empty();
    }

    const { compsAbsoluteBounds } = this;
    const boundsUnion = BoundsUtils.union(
      ...comps.map((comp) => {
        return compsAbsoluteBounds[comp.id]; // comp.getViewBoundsInArtboard();
      }),
    );
    return BoundsUtils.round(boundsUnion);
  }

  /**
   * 检查每个子元素是否 top 或 left 相同
   * @param {UIComponent[]} comps 组件集
   * @param {boolean} onlyTargetProp
   * @param {boolean} onlyCompareProp
   * @returns {boolean}
   */
  private judgeCompsCompleteSameByProp(
    comps: UIComponent[],
    onlyTargetProp: boolean,
    onlyCompareProp: boolean,
  ): boolean {
    if (!comps.length) {
      return false;
    }

    const { targetProp, compareProp } = this.compareAndTargetProp;
    const bounds: IBounds[] = this.getBoundsOfComponentsInArtboard(comps);
    let isLineSame: boolean = true;
    let cursor = 1;
    let lastBound = bounds[0];
    while (cursor < comps.length) {
      const nowBound = bounds[cursor];
      if (
        (onlyTargetProp && !GlobalUtils.sameNumber(nowBound[compareProp], lastBound[compareProp], ToleranceNum)) ||
        (onlyCompareProp && !GlobalUtils.sameNumber(nowBound[targetProp], lastBound[targetProp], ToleranceNum)) ||
        (!onlyTargetProp &&
          !onlyCompareProp &&
          !GlobalUtils.sameNumber(nowBound[targetProp], lastBound[targetProp], ToleranceNum))
      ) {
        isLineSame = false;
        break;
      }
      cursor++;
    }

    return isLineSame;
  }

  /**
   * 合并行/列
   * @param {LineObject} lineObject 行/列对象数组
   * @returns {LineObject}
   */
  private mergeLine(lineObject: LineObject): LineObject {
    const { targetProp, compareProp } = this.compareAndTargetProp;

    const sortYKeys = sortBy([...Object.keys(lineObject)].map(Number));
    const lineObject2: LineObject = {};
    let cursor = 0;
    let lastLineKey = sortYKeys[0];

    while (cursor < sortYKeys.length) {
      const key = sortYKeys[cursor];
      if (!cursor) {
        lineObject2[key] = [...lineObject[key]];
      } else {
        const nowLine = lineObject[key];
        const lastLine = lineObject2[lastLineKey] || lineObject[lastLineKey];
        //获取组件集合的bounds
        const lastBigBounds = this.getBigBoundsWithComponentsBounds(lastLine);
        const nowBigBounds = this.getBigBoundsWithComponentsBounds(nowLine);

        if (nowBigBounds[targetProp] < lastBigBounds[compareProp]) {
          lineObject2[lastLineKey] = this.sortCompsByPositionXOrY([...lastLine, ...nowLine]);
        } else {
          lastLineKey = key;
          lineObject2[key] = [...lineObject[key]];
        }
      }
      cursor++;
    }

    return lineObject2;
  }

  /**
   * 通过行/列对象数组计算行列数据
   * @param lineObject
   * @returns {Pick<SplitData, 'lineInfos' | 'sortYKeys'> } 行/列基础数据
   */
  private getSortYKeysLineInfos(lineObject: LineObject): Pick<SplitData, 'lineInfos' | 'sortYKeys'> {
    const { x, y } = this.selectedCompsMinXYWidthHeight!;
    const sortYLineKeys = sortBy([...Object.keys(lineObject)].map(Number));
    const lineInfos = sortYLineKeys.map((key, index) => {
      const lineComps = lineObject[key];
      const firstComp = lineComps[0];

      const bigBounds = this.getBigBoundsWithComponentsBounds(lineComps);
      const firstCompBounds = this.compsAbsoluteBounds[firstComp.id];
      const minCompPosition = { x: firstCompBounds.left, y: firstCompBounds.top };

      return {
        index,
        bounds: bigBounds,
        comps: lineObject[key],
        minPosition: minCompPosition,
        relativePosition: {
          x: bigBounds.left - x,
          y: bigBounds.top - y,
        },
      };
    });
    return {
      sortYKeys: sortYLineKeys,
      lineInfos,
    };
  }

  /**
   * 计算行/列等间距数据
   * @param {LineObject} lineObject 行/列坐标对象数组
   * @returns {Omit<SplitData, 'minXMinYPosition'>}
   */
  private computedSplitData(lineObject: LineObject): Omit<SplitData, 'minXMinYPosition'> {
    const { targetProp, compareProp } = this.compareAndTargetProp;
    const { sortYKeys, lineInfos } = this.getSortYKeysLineInfos(lineObject);

    let spaceNum = NaN;
    let isLineEquidistant = true;

    for (let line of lineInfos) {
      if (line.index) {
        const lastLine = lineInfos[line.index - 1];
        const nowSpace = line.bounds[targetProp] - lastLine.bounds[compareProp];
        if (isNaN(spaceNum)) {
          spaceNum = nowSpace;
        } else {
          if (!GlobalUtils.sameNumber(spaceNum, nowSpace, 0.5)) {
            isLineEquidistant = false;
            break;
          }
        }

        // 如果当前这行不是最后一行，检查每个子元素是否 top 或 left 相同
        const isLine = this.judgeCompsCompleteSameByProp(
          line.comps,
          line.index === 0,
          line.index === lineInfos.length - 1,
        );

        if (!isLine) {
          isLineEquidistant = false;
          break;
        }
      }
    }

    //只有一行, 另外划分
    if (isNaN(spaceNum)) {
      isLineEquidistant = false;
    }

    return {
      isLineEquidistant,
      lineInfos,
      spaceNum,
      sortYKeys,
    };
  }

  /**
   * 按照行(Y轴)/列(X轴)对组件进行排序处理，将同一行/列的组件以位置为key放到同一个数组中，并对每个位置的组件进行排序
   * 同一行的组件Y(top)值相同，同一列的组件X(left)值相同
   * @returns {LineObject} 以行/列位置为key的组件对象
   */
  private getCompsLineObject(): LineObject {
    const { selectedComponents, compsAbsoluteBounds, currentCalcIsRow: isRow } = this;
    const selectedComps = this.sortCompsByPositionXOrY(selectedComponents);
    //排序, 当isRow === true, 以Y轴排序， 否则以X轴排序
    const splitProperty = isRow ? 'top' : 'left';
    let lineObject: LineObject = {};

    //分排或分列
    selectedComps.forEach((comp) => {
      const bounds = compsAbsoluteBounds[comp.id]; // comp.getViewBoundsInArtboard();
      const key = bounds[splitProperty].toString();
      if (lineObject[key]) {
        lineObject[key].push(comp);
      } else {
        lineObject[key] = [comp];
      }
      lineObject[key] = this.sortCompsByPositionXOrY(lineObject[key]);
    });

    return lineObject;
  }

  /**
   * 通过方向分行/分列
   * @param {LineObject} lineObject 行/列坐标对象数组
   * @returns {SplitData}
   */
  private splitDataByDirection(lineObject: LineObject): SplitData {
    const { currentCalcIsRow: isRow, currentCalcIsMerge: isMerge } = this;

    if (isMerge) {
      lineObject = this.mergeLine(lineObject);
    }

    const minXYAndWH = this.selectedCompsMinXYWidthHeight!;
    const minXMinYPosition = {
      x: minXYAndWH.x,
      y: minXYAndWH.y,
    };

    let { isLineEquidistant, lineInfos, spaceNum, sortYKeys } = this.computedSplitData(lineObject);

    //当间距值为负，且间距大于按方向分的最小的宽或高， 则也不认为是这个方向的等间距
    const minSize = !isRow ? minXYAndWH.width : minXYAndWH.height;

    if (isLineEquidistant && spaceNum < 0 && GlobalUtils.abs(spaceNum) > minSize - 1) {
      isLineEquidistant = false;
    }

    return {
      isLineEquidistant,
      lineInfos,
      spaceNum,
      sortYKeys,
      minXMinYPosition,
    };
  }

  /**
   * 对已分行/列的数据进行计算得出间距值
   * @param {LineObject} lineObject 以坐标为key的行/列数组对象
   * @returns {EquidistantValue}
   */
  private getSplitEquidistant(lineObject: LineObject): EquidistantValue {
    const { currentCalcIsRow: isRow } = this;
    // 分行或列
    const { isLineEquidistant, lineInfos, spaceNum, minXMinYPosition } = this.splitDataByDirection(lineObject);

    if (!isLineEquidistant) {
      return { isEquidistant: false };
    }
    // 成功分行列后， 判断每行或每列是否也符合等间距
    const { isEquidistant, spaceNum: otherLineSpace } = this.judgeEquidistantByDirection(lineInfos);

    if (!isEquidistant) {
      return { isEquidistant: false };
    }

    return {
      isEquidistant,
      isRow: isRow,
      horizontalSpace: !isRow ? spaceNum : otherLineSpace,
      verticalSpace: isRow ? spaceNum : otherLineSpace,
      minXMinYPosition,
      collatedInfo: lineInfos,
    };
  }

  /**
   * 计算两组件间X/Y方向的间距大小
   * @param targetCompBounds 目标组件坐标区域
   * @param nextCompBounds 下一个组件坐标区域
   * @param isX 按照X或Y轴计算
   * @returns {number} 组件间的间距
   */
  private computedSpaceByCompBounds(targetCompBounds: IBounds, nextCompBounds: IBounds, isX: boolean): number {
    if (isX) {
      return GlobalUtils.round(nextCompBounds.left - targetCompBounds.left - targetCompBounds.width);
    } else {
      return GlobalUtils.round(nextCompBounds.top - targetCompBounds.top - targetCompBounds.height);
    }
  }

  /**
   * 计算间距值
   * @param comps 组件集
   * @param isX 按照X或Y轴计算
   * @returns {number}
   */
  private getEquidistantSpace(comps: UIComponent[]): number {
    const { compsAbsoluteBounds, currentCalcIsRow: isX } = this;
    const newComps = this.sortCompsByPositionXOrY(comps);
    let targetCursor = 0;
    let space = NaN;
    while (targetCursor < newComps.length - 1) {
      const targetComp = newComps[targetCursor];
      const nextComp = newComps[targetCursor + 1];
      const nowSpace = this.computedSpaceByCompBounds(
        compsAbsoluteBounds[targetComp.id],
        compsAbsoluteBounds[nextComp.id],
        isX,
      );
      if (isNaN(space)) {
        space = nowSpace;
      }

      if (!isNaN(space) && space !== nowSpace) {
        space = NaN;
        break;
      }
      ++targetCursor;
    }

    //这一排的组件只有一个时， 其水平间隔特殊设置
    if (comps.length === 1) {
      space = Infinity;
    }

    return space;
  }

  /**
   * 判断每行/每列是否符合等间距以及间距值
   * @param lineInfos 行/列数据
   * @returns {isEquidistant: boolean; spaceNum: number;}
   */
  private judgeEquidistantByDirection(
    lineInfos: LineInfos,
  ): {
    isEquidistant: boolean;
    spaceNum: number;
  } {
    let spaceNum: number[] = [];
    let isEquidistant: boolean = true;
    //每排的每列之间的间距
    lineInfos.forEach((lineInfo) => {
      const space = this.getEquidistantSpace(lineInfo.comps);
      spaceNum.push(space);
    });

    //去除一行只有一个组件的情况
    spaceNum = without(uniq(spaceNum), Infinity);

    //垂直每行只有一个时，全部为Infinity, 去重后为空
    if (!spaceNum.length) {
      spaceNum = [0];
    }

    //每排的每列之间出现多个值，直接返回
    if (spaceNum.length > 1 || isNaN(spaceNum[0])) {
      isEquidistant = false;
    }

    return {
      isEquidistant,
      spaceNum: spaceNum[0],
    };
  }
}
