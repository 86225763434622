import { IExportData } from '../type';
import { blobToBase64 } from '../util/blobUtil';

/**
 * 下载图片
 * @param url
 * @returns
 */
export const fetchImageBlob = async (url: string): Promise<Blob> => {
  try {
    const res = window.fetch(url, { mode: 'cors' });
    const t = await res;
    return t.blob();
  } catch (e) {
    // 异常收集，返回空白，不影响其他下载
    window.debug && console.log(url);
    // exceptionCollector.add(url);
    // 0 && exceptionCollector.add(compInfo?.name ?? i18n('workspace.artboard.downloadImageError'));
    return Promise.resolve(new Blob());
    // 图片跨域走这里
    // throw new Error(e);
  }
};

export const requestImageBlob = (url: string): Promise<Blob> => {
  const TIMEOUT = 3000;

  return new Promise((resolve) => {
    const request = new XMLHttpRequest();

    request.onreadystatechange = done;
    // request.ontimeout = timeout;
    request.responseType = 'blob';
    request.timeout = TIMEOUT;
    request.open('GET', url, true);
    request.send();

    // let placeholder: any;
    function done() {
      if (request.readyState !== 4) return;
      if (request.status !== 200) {
        return;
      }
      resolve(request.response);
    }
  });
};

/**
 * 原图
 * @param data
 * @returns
 */
export const getImageBlob = async (url: string): Promise<Blob> => {
  try {
    const res = await window.fetch(url, { mode: 'cors' });
    const blob = await res.blob();
    return blob;
  } catch (e) {
    return Promise.resolve(new Blob());
  }
};

/**
 * @param data
 * @returns
 */
export const getImageBase64 = (data: IExportData): Promise<string> => {
  const { url } = data;
  if (!url) {
    return Promise.resolve('');
  }

  return (
    fetchImageBlob(url)
      // return requestImageBlob(url).
      .then((blob) => {
        return blobToBase64(blob);
      })
  );
};
