import * as React from 'react';

interface IEditableInputProps {
  value: string;
  label: string;
  inputChange: Function;
}

class EditableInput extends React.Component<IEditableInputProps> {
  input: HTMLInputElement | null;

  constructor(props: IEditableInputProps) {
    super(props);
    this.input = null;
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    if (!this.input) {
      return;
    }
    const value = this.props.value;
    this.input.value = value;
  }

  componentWillReceiveProps(nextProps: IEditableInputProps) {
    if (!this.input) {
      return;
    }
    this.input.value = nextProps.value;
  }

  handleKeyDown() {
    if (this.props.label !== 'Hex') {
      return;
    }
    // const key = e.key;
    // const reg = /[a-fA-F0-9]/g;
    // TODO by secret
    return;
    // if (!reg.test(key)) {
    //   e.preventDefault();
    // }
  }

  handleChange(e: any) {
    let value = e.target.value;
    // const color = tinycolor(value);
    const label = this.props.label;
    const data: {
      label: string;
      value: number;
    } = {
      label,
      value,
    };
    const reg = /[^a-fA-F0-9]/g;
    if (reg.test(value)) {
      value = value.replace(reg, '');
      if (this.input) {
        this.input.value = value;
      }
    }

    if (label === 'A') {
      data.value /= 100;
    }
    if (label === 'Hex' && (value.length === 3 || value.length > 5)) {
      this.props.inputChange(data);
    } else if (label !== 'Hex') {
      data.label = label.toLowerCase();
      this.props.inputChange(data);
    }
  }

  render() {
    return (
      <label>
        <input
          type="text"
          ref={(div) => (this.input = div)}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
        />
        <span>{this.props.label}</span>
      </label>
    );
  }
}

export default EditableInput;
