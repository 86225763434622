// 组件库 形状组件
import * as sprite1x from '@assets/sprite/sprite1x.png';
import * as sprite2x from '@assets/sprite/sprite2x.png';
import * as spriteDrag from '@assets/sprite/spriteDrag.png';
import * as spriteDrag2 from '@assets/sprite/spriteDrag2.png';
// cursor
import * as fork2 from '@assets/cursors/16_icon_fork2.png';
import * as arrowNE from '@assets/cursors/arrowNE.png';
import * as arrowNS from '@assets/cursors/arrowNS.png';
import * as arrowNW from '@assets/cursors/arrowNW.png';
import * as arrowWE from '@assets/cursors/arrowWE.png';
import * as artboard from '@assets/cursors/artboard.png';
import * as drawBtn from '@assets/cursors/drawBtn.png';
import * as drawCircle from '@assets/cursors/drawCircle.png';
import * as drawImg from '@assets/cursors/drawImg.png';
import * as drawLine from '@assets/cursors/drawLine.png';
import * as drawRect from '@assets/cursors/drawRect.png';
import * as drawText from '@assets/cursors/drawText.png';
import * as pen from '@assets/cursors/pen.png';
import * as penAdd from '@assets/cursors/penAdd.png';
import * as penMove from '@assets/cursors/penMove.png';
import * as penClose from '@assets/cursors/penClose.png';
import * as pencil from '@assets/cursors/pencil.png';
import * as rotate from '@assets/cursors/rotate.png';
import * as Shears1 from '@assets/cursors/Shears1.png';
import * as Shears2 from '@assets/cursors/Shears2.png';
import * as mobileCusror1Png from '@assets/cursors/mobileCursor.png';
import * as mobileCusror1Cur from '@assets/cursors/mobileCursor.cur';
import * as mobileCusror2Png from '@assets/cursors/mobileCursor2.png';
import * as mobileCusror2Cur from '@assets/cursors/mobileCursor2.cur';
import * as Drag from '@assets/cursors/drag.png';
import * as Drop from '@assets/cursors/drop.png';

// other
import * as offlinePage from '@assets/image/offlinePage.png';
import * as waiting from '@assets/image/waiting.gif';
import * as loading from '@assets/image/loading_v2.gif';
import * as uploading from '@assets/image/uploading.gif';
import * as selectGroup from '@assets/image/selectGroup.gif';
import * as bottom1 from '@assets/image/bottom1.png';
import * as bottom2 from '@assets/image/bottom2.png';
import * as bottom3 from '@assets/image/bottom3.png';
import * as left1 from '@assets/image/left1.png';
import * as left2 from '@assets/image/left2.png';
import * as left3 from '@assets/image/left3.png';
import * as right1 from '@assets/image/right1.png';
import * as right2 from '@assets/image/right2.png';
import * as right3 from '@assets/image/right3.png';
import * as top1 from '@assets/image/top1.png';
import * as top2 from '@assets/image/top2.png';
import * as top3 from '@assets/image/top3.png';
import * as ArrowHollowIcon from '@assets/image/endpoint_arrow_hollow_thin.png';
import * as ArrowSolidIcon from '@assets/image/endpoint_arrow_solid_thin.png';
import * as CubeHollowIcon from '@assets/image/endpoint_cube_hollow_thin.png';
import * as CubeSolidIcon from '@assets/image/endpoint_cube_solid_thin.png';
import * as DiamondHollowIcon from '@assets/image/endpoint_diamond_hollow_thin.png';
import * as DiamondSolidIcon from '@assets/image/endpoint_diamond_solid_thin.png';
import * as DotHollowIcon from '@assets/image/endpoint_dot_hollow_thin.png';
import * as DotSolidIcon from '@assets/image/endpoint_dot_solid_thin.png';
import * as TriangleHollowIcon from '@assets/image/endpoint_triangle_hollow_thin.png';
import * as TriangleSolidIcon from '@assets/image/endpoint_triangle_solid_thin.png';
import * as PerpendicularIcon from '@assets/image/endpoint_perpendicular_thin.png';
import * as Alert from '@assets/image/Alert.png';
import * as Delete from '@assets/image/delete.png';
import * as cutOff from '@assets/image/cut_off.png';
import * as emptyProject from '@assets/image/emptyProject.png';
import * as projectEmpty from '@assets/image/projectEmpty.png';
import * as resourceLoadFail from '@assets/image/resource_load_fail.png';
import * as helpInfo2 from '@assets/image/helpInfo2.png';
import * as helpInfo1 from '@assets/image/helpInfo1.png';
import * as defaultImage from '@assets/image/defaultImage.png';
import * as componentBgPanel from '@assets/image/component_bg_panel.png';
import * as contentDark from '@assets/image/content_dark.png';
import * as contentLight from '@assets/image/content_light.png';
import * as hiddenDark from '@assets/image/hidden_dark.png';
import * as hiddenLight from '@assets/image/hidden_light.png';
import * as moveDark from '@assets/image/move_dark.png';
import * as moveLight from '@assets/image/move_light.png';
import * as resizeDark from '@assets/image/resize_dark.png';
import * as resizeLight from '@assets/image/resize_light.png';
import * as rotateDark from '@assets/image/rotate_dark.png';
import * as rotateLight from '@assets/image/rotate_light.png';
import * as stateDark from '@assets/image/state_dark.png';
import * as stateLight from '@assets/image/state_light.png';
import * as CompLibPreset from '@assets/image/compLibPreset@2x.png';
import * as CustomCompMaskBg from '@/assets/image/customItemBg@2x.png';

import * as IPhone_h1 from '@assets/image/shell/iPhone/h/01.png';
import * as IPhone_h2 from '@assets/image/shell/iPhone/h/02.png';
import * as IPhone_h3 from '@assets/image/shell/iPhone/h/03.png';
import * as IPhone_v1 from '@assets/image/shell/iPhone/v/01.png';
import * as IPhone_v2 from '@assets/image/shell/iPhone/v/02.png';
import * as IPhone_v3 from '@assets/image/shell/iPhone/v/03.png';
import * as IPhoneX_h1 from '@assets/image/shell/iPhoneX/h/01.png';
import * as IPhoneX_h2 from '@assets/image/shell/iPhoneX/h/02.png';
import * as IPhoneX_h3 from '@assets/image/shell/iPhoneX/h/03.png';
import * as IPhoneX_v1 from '@assets/image/shell/iPhoneX/v/01.png';
import * as IPhoneX_v2 from '@assets/image/shell/iPhoneX/v/02.png';
import * as IPhoneX_v3 from '@assets/image/shell/iPhoneX/v/03.png';
import * as Pad_h1 from '@assets/image/shell/pad/h/01.png';
import * as Pad_h2 from '@assets/image/shell/pad/h/02.png';
import * as Pad_h3 from '@assets/image/shell/pad/h/03.png';
import * as Pad_v1 from '@assets/image/shell/pad/v/01.png';
import * as Pad_v2 from '@assets/image/shell/pad/v/02.png';
import * as Pad_v3 from '@assets/image/shell/pad/v/03.png';
// import * as iPad_Air_h1 from '@assets/image/shell/iPad_Air/h/01.png';
// import * as iPad_Air_h2 from '@assets/image/shell/iPad_Air/h/02.png';
// import * as iPad_Air_h3 from '@assets/image/shell/iPad_Air/h/03.png';
// import * as iPad_Air_v1 from '@assets/image/shell/iPad_Air/v/01.png';
// import * as iPad_Air_v2 from '@assets/image/shell/iPad_Air/v/02.png';
// import * as iPad_Air_v3 from '@assets/image/shell/iPad_Air/v/03.png';
import * as iPhone_black_h1 from '@assets/image/shell/iPhone_black/h/01.png';
import * as iPhone_black_h2 from '@assets/image/shell/iPhone_black/h/02.png';
import * as iPhone_black_h3 from '@assets/image/shell/iPhone_black/h/03.png';
import * as iPhone_black_v1 from '@assets/image/shell/iPhone_black/v/01.png';
import * as iPhone_black_v2 from '@assets/image/shell/iPhone_black/v/02.png';
import * as iPhone_black_v3 from '@assets/image/shell/iPhone_black/v/03.png';
import * as Galaxy_h1 from '@assets/image/shell/Galaxy/h/01.png';
import * as Galaxy_h2 from '@assets/image/shell/Galaxy/h/02.png';
import * as Galaxy_h3 from '@assets/image/shell/Galaxy/h/03.png';
import * as Galaxy_v1 from '@assets/image/shell/Galaxy/v/01.png';
import * as Galaxy_v2 from '@assets/image/shell/Galaxy/v/02.png';
import * as Galaxy_v3 from '@assets/image/shell/Galaxy/v/03.png';
import * as HUAWEI_Mate_h1 from '@assets/image/shell/HUAWEI_Mate/h/01.png';
import * as HUAWEI_Mate_h2 from '@assets/image/shell/HUAWEI_Mate/h/02.png';
import * as HUAWEI_Mate_h3 from '@assets/image/shell/HUAWEI_Mate/h/03.png';
import * as HUAWEI_Mate_v1 from '@assets/image/shell/HUAWEI_Mate/v/01.png';
import * as HUAWEI_Mate_v2 from '@assets/image/shell/HUAWEI_Mate/v/02.png';
import * as HUAWEI_Mate_v3 from '@assets/image/shell/HUAWEI_Mate/v/03.png';
import * as HUAWEI_P40_h1 from '@assets/image/shell/HUAWEI_P40/h/01.png';
import * as HUAWEI_P40_h2 from '@assets/image/shell/HUAWEI_P40/h/02.png';
import * as HUAWEI_P40_h3 from '@assets/image/shell/HUAWEI_P40/h/03.png';
import * as HUAWEI_P40_v1 from '@assets/image/shell/HUAWEI_P40/v/01.png';
import * as HUAWEI_P40_v2 from '@assets/image/shell/HUAWEI_P40/v/02.png';
import * as HUAWEI_P40_v3 from '@assets/image/shell/HUAWEI_P40/v/03.png';
import * as XiaoMi_h1 from '@assets/image/shell/XiaoMi/h/01.png';
import * as XiaoMi_h2 from '@assets/image/shell/XiaoMi/h/02.png';
import * as XiaoMi_h3 from '@assets/image/shell/XiaoMi/h/03.png';
import * as XiaoMi_v1 from '@assets/image/shell/XiaoMi/v/01.png';
import * as XiaoMi_v2 from '@assets/image/shell/XiaoMi/v/02.png';
import * as XiaoMi_v3 from '@assets/image/shell/XiaoMi/v/03.png';
import * as Surface_Pro_h1 from '@assets/image/shell/Surface_Pro/h/01.png';
import * as Surface_Pro_h2 from '@assets/image/shell/Surface_Pro/h/02.png';
import * as Surface_Pro_h3 from '@assets/image/shell/Surface_Pro/h/03.png';
import * as Surface_Pro_v1 from '@assets/image/shell/Surface_Pro/v/01.png';
import * as Surface_Pro_v2 from '@assets/image/shell/Surface_Pro/v/02.png';
import * as Surface_Pro_v3 from '@assets/image/shell/Surface_Pro/v/03.png';
import * as DefaultCarousel1 from '@assets/image/defaultCarousel1.png';
import * as DefaultCarousel2 from '@assets/image/defaultCarousel2.png';
import * as WaitingImage from '@assets/image/waiting.png';

const compSprites = [sprite1x, sprite2x, spriteDrag, spriteDrag2];
const cursors = [
  fork2,
  arrowNE,
  arrowNS,
  arrowNW,
  arrowWE,
  artboard,
  drawBtn,
  drawCircle,
  drawImg,
  drawLine,
  drawRect,
  drawText,
  pen,
  penAdd,
  penMove,
  penClose,
  pencil,
  rotate,
  Shears1,
  Shears2,
  mobileCusror1Cur,
  mobileCusror1Png,
  mobileCusror2Cur,
  mobileCusror2Png,
  Drag,
  Drop,
];
const other = [
  offlinePage,
  waiting,
  loading,
  uploading,
  // saveSuccess,
  selectGroup,
  bottom1,
  bottom2,
  bottom3,
  left1,
  left2,
  left3,
  right1,
  right2,
  right3,
  top1,
  top2,
  top3,
  ArrowHollowIcon,
  ArrowSolidIcon,
  CubeHollowIcon,
  CubeSolidIcon,
  DiamondHollowIcon,
  DiamondSolidIcon,
  DotHollowIcon,
  DotSolidIcon,
  TriangleHollowIcon,
  TriangleSolidIcon,
  PerpendicularIcon,
  Alert,
  Delete,
  cutOff,
  emptyProject,
  projectEmpty,
  resourceLoadFail,
  helpInfo2,
  helpInfo1,
  defaultImage,
  componentBgPanel,
  contentDark,
  contentLight,
  hiddenDark,
  hiddenLight,
  moveDark,
  moveLight,
  resizeDark,
  resizeLight,
  rotateDark,
  rotateLight,
  stateDark,
  stateLight,
  DefaultCarousel1,
  DefaultCarousel2,
  CompLibPreset,
  CustomCompMaskBg,
  WaitingImage,
];
const shells = [
  IPhone_h1,
  IPhone_h2,
  IPhone_h3,
  IPhone_v1,
  IPhone_v2,
  IPhone_v3,
  IPhoneX_h1,
  IPhoneX_h2,
  IPhoneX_h3,
  IPhoneX_v1,
  IPhoneX_v2,
  IPhoneX_v3,
  Pad_h1,
  Pad_h2,
  Pad_h3,
  Pad_v1,
  Pad_v2,
  Pad_v3,
  // iPad_Air_h1,
  // iPad_Air_h2,
  // iPad_Air_h3,
  // iPad_Air_v1,
  // iPad_Air_v2,
  // iPad_Air_v3,
  iPhone_black_h1,
  iPhone_black_h2,
  iPhone_black_h3,
  iPhone_black_v1,
  iPhone_black_v2,
  iPhone_black_v3,
  Galaxy_h1,
  Galaxy_h2,
  Galaxy_h3,
  Galaxy_v1,
  Galaxy_v2,
  Galaxy_v3,
  HUAWEI_Mate_h1,
  HUAWEI_Mate_h2,
  HUAWEI_Mate_h3,
  HUAWEI_Mate_v1,
  HUAWEI_Mate_v2,
  HUAWEI_Mate_v3,
  HUAWEI_P40_h1,
  HUAWEI_P40_h2,
  HUAWEI_P40_h3,
  HUAWEI_P40_v1,
  HUAWEI_P40_v2,
  HUAWEI_P40_v3,
  XiaoMi_h1,
  XiaoMi_h2,
  XiaoMi_h3,
  XiaoMi_v1,
  XiaoMi_v2,
  XiaoMi_v3,
  Surface_Pro_h1,
  Surface_Pro_h2,
  Surface_Pro_h3,
  Surface_Pro_v1,
  Surface_Pro_v2,
  Surface_Pro_v3,
];
const iconFonts = ['lightIconFont', 'boldIconFont'];

class ImgLoader {
  constructor(promise?: Promise<unknown>) {
    this.promise = promise;
  }

  imgDom?: HTMLDivElement;
  bgURL: string[] = [];
  promise?: Promise<unknown>;

  initImg = (src: string) => {
    const img = new Image();
    img.src = src;
    if (!this.imgDom) {
      this.imgDom = document.createElement('div');
      this.imgDom.style.position = 'absolute';
      this.imgDom.style.pointerEvents = 'none';
      this.imgDom.style.width = '1px';
      this.imgDom.style.height = '1px';
      this.imgDom.style.left = '-1000px';
      document.body.appendChild(this.imgDom);
    }
    this.bgURL.push(src);
    this.imgDom.style.background = this.bgURL.map((url) => `url(${url})`).join(',');
  };

  initFont(fontFamily: string) {
    return new Promise((resolve) => {
      const span = document.createElement('span');
      span.style.fontFamily = fontFamily;
      span.style.pointerEvents = 'none';
      span.style.opacity = '0';
      span.style.position = 'absolute';
      span.style.left = '-1000px';
      span.style.top = '-1000px';
      if (fontFamily === 'boldIconFont') {
        span.innerText = String.fromCharCode(60462);
      } else {
        span.innerText = String.fromCharCode(60512);
      }
      document.body.appendChild(span);
      resolve(span);
    });
  }

  doPromiseLoadImg = (srcArray: string[]) => {
    return Promise.all(
      srcArray.map((src) => {
        return this.initImg(src);
      }),
    );
  };

  doPromiseLoadFont(srcArray: string[]) {
    return Promise.all(
      srcArray.map((src) => {
        return this.initFont(src);
      }),
    );
  }

  loadImgs(srcArray: string[]) {
    this.promise = this.doPromiseLoadImg(srcArray);
    return this;
  }

  loadFonts(srcArray: string[]) {
    this.promise = this.doPromiseLoadFont(srcArray).then((v) => {
      setTimeout(() => {
        v.forEach((el) => document.body.removeChild(el as HTMLElement));
      }, 10000);
    });
    return this;
  }
}

export function preloadingImgs() {
  const loader = new ImgLoader();
  loader.loadImgs(compSprites).loadFonts(iconFonts).loadImgs(cursors).loadImgs(other);
}

export function preloadAssetsInPreview() {
  const loader = new ImgLoader();
  loader
    .loadFonts(iconFonts)
    .loadImgs([mobileCusror1Cur, mobileCusror1Png, mobileCusror2Cur, mobileCusror2Png])
    .loadImgs(shells)
    .loadImgs([waiting, projectEmpty]);
}
