import * as React from 'react';
import * as ReactDom from 'react-dom';
import Dialog from '../Dialog';
import ScrollView from '../ScrollView';

import './index.scss';

export interface IDialogWithTextAreaProp {
  title?: string;
  width?: string;
  value?: string;
  maxLength?: number;
  showClose?: boolean;
  showCancel?: boolean;
  placeholder?: string;
  errorMsg?: string;
  isShowExtra: boolean;
  extraComponent: any;
  onOk(value: string): void;
  onCancel(): void;
}

interface IDialogWithTextAreaState {
  value: string;
  scrollHeight: number;
  textStyle?: {
    height: number;
  };
}

class DialogWithTextArea extends React.Component<IDialogWithTextAreaProp, IDialogWithTextAreaState> {
  textArea: HTMLTextAreaElement | null = null;
  bgcDiv: HTMLElement | null = null;

  private measureLayer = React.createRef<HTMLDivElement>();

  static defaultProps: Partial<IDialogWithTextAreaProp> = {
    maxLength: 500,
    showCancel: true,
    placeholder: '',
  };

  constructor(props: IDialogWithTextAreaProp) {
    super(props);
    this.state = {
      value: props.value || '',
      scrollHeight: 0,
    };
    this.onTextAreaChange = this.onTextAreaChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    // this.handleKeydown = this.handleKeydown.bind(this);
  }

  componentDidMount() {
    // window.addEventListener('keydown', this.handleKeydown);
    this.textArea?.focus();
    this.textArea?.select();
    this.calScrollHeight();
    this.autoHeight();
  }

  // componentWillUnmount() {
  //   window.removeEventListener('keydown', this.handleKeydown);
  // }
  //
  // handleKeydown(e: KeyboardEvent) {
  //   const { onCancel } = this.props;
  //   if (e.code === 'Escape') {
  //     onCancel();
  //   }
  // }

  calScrollHeight = () => {
    if (this.textArea) {
      const height = this.bgcDiv?.offsetHeight ?? 0;

      this.setScrollHeight(height);
    }
  };
  setScrollHeight(val: number) {
    this.setState({ scrollHeight: val });
  }

  onTextAreaChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      value: e.target.value,
    });
    this.autoHeight();
  }

  onSubmit() {
    const { onOk, errorMsg } = this.props;
    if (!onOk) {
      return;
    }
    const { value } = this.state;
    if (!errorMsg) {
      onOk(value);
    }
  }

  onInputKeyDown(e: React.KeyboardEvent) {
    if (e.key !== 'Escape') {
      e.stopPropagation();
    }
  }

  autoHeight() {
    if (!this.measureLayer.current) {
      return;
    }
    // TODO 暂时加16， chrome上看起来没问题，待各浏览器上测试后看结果咋样再调整
    const height = Math.round(this.measureLayer.current.offsetHeight + 20);
    this.setState({ textStyle: { height: height } });
  }

  render() {
    const { showCancel, showClose, onCancel, placeholder, maxLength, title, isShowExtra, extraComponent } = this.props;
    const { value, scrollHeight, textStyle } = this.state;

    return ReactDom.createPortal(
      <div
        className={'dsm-c-textArea-box'}
        onMouseDown={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <Dialog title={title} showClose={showClose} showCancel={showCancel} onOk={this.onSubmit} onCancel={onCancel}>
          <div className="dsm-c-textArea">
            <div
              className="bgc-div"
              ref={(div) => {
                this.bgcDiv = div;
              }}
            >
              {'a,b,c,d,e,f,g,h,i'.split(',').map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>

            <ScrollView width="100%" height={scrollHeight}>
              <div ref={this.measureLayer} className="measure-layer">
                {value}
              </div>

              <textarea
                ref={(dom) => (this.textArea = dom)}
                maxLength={maxLength}
                style={{ ...textStyle, minHeight: scrollHeight }}
                value={value}
                onKeyDown={this.onInputKeyDown}
                placeholder={placeholder}
                onChange={this.onTextAreaChange}
              />
            </ScrollView>
          </div>
          {isShowExtra && <div className="extra-box">{extraComponent()}</div>}
        </Dialog>
      </div>,
      document.body,
    );
  }
}

export default DialogWithTextArea;
