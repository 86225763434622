import IArtboard from '@/fbs/rp/models/artboard';
import CoreEditor from '@/editor/core';
import { UIFragment } from '@/editor/comps';
import { getAllArtboardsByNodeID } from '@/apis/artboard';
import apis from '@/apis';
import Theater from '@/theater';
import { ISize } from '@/utils/boundsUtils';
import { SessionInfo } from '@/fbs/rp/models/io';
import INode from '@/fbs/rp/models/node';

interface ICreateStates {
  appID: string;
  allNodes?: any;
  size?: ISize;
  currentSession?: SessionInfo;
}

type TManager<T> = T extends CoreEditorManager ? CoreEditor : Theater;

export type TNodeData = { _id: string } & Partial<INode>;

export interface IPageArtboard {
  artboardID: string;
  type: string;
  pageId: string;
  node: TNodeData;
  size: ISize;
  name?: string;
}

class CoreManager {
  private nodeMap = new Map<string, TNodeData>(); // 页面相关-来源服务器
  private artboardsMap = new Map<string, IPageArtboard[]>(); // 画板相关-包含新增/修改。
  protected coreMap = new Map<string, CoreEditor | Theater>(); // editor实例相关
  protected creator?: 'Theater' | 'CoreEditor';
  protected appStates?: ICreateStates;
  constructor() {}

  private getCoreMap(pageID: string) {
    return this.coreMap.get(pageID);
  }

  /**
   * 将页面实例中的artboards数据转换成IPageArtboard数据结构
   * @param pageID
   * @param artboards
   */
  private doArtboardToPageArtboard(pageID: string, artboards: IArtboard[]): IPageArtboard[] {
    const inode = this.nodeMap.get(pageID);
    if (!inode) {
      return [];
    }
    const pageArtboards = artboards
      .filter((a) => !a.ownerID)
      .map((artboard) => {
        return {
          artboardID: artboard._id,
          name: artboard.name,
          type: artboard.type,
          pageId: artboard.nodeID,
          size: artboard.size,
          node: inode,
        };
      });
    return pageArtboards;
  }

  /**
   * 创建页面实例
   * @param inode
   * @param artboards
   * @param states
   */
  private create(inode: TNodeData, artboards: IArtboard[], states?: ICreateStates) {
    const pageID = inode._id;

    if (this.appStates) {
      Object.assign(this.appStates, states);
    } else {
      this.appStates = states;
    }
    if (!this.appStates) {
      return;
    }

    this.nodeMap.set(pageID, inode);

    const { appID, allNodes, size, currentSession } = this.appStates;
    if (this.creator === 'Theater') {
      const coreManager = new Theater(appID, artboards, pageID, allNodes, size!);
      this.coreMap.set(pageID, coreManager);
    }
    if (this.creator === 'CoreEditor') {
      const coreManager = new CoreEditor(artboards, appID, pageID, currentSession!);
      this.coreMap.set(pageID, coreManager);
    }
  }

  protected setArtboards(pageID: string, artboards: IPageArtboard[]) {
    this.artboardsMap.set(pageID, artboards);
  }

  protected getArtboards(pageID: string): IPageArtboard[] {
    const artboards = this.coreMap.get(pageID)?.doc?.artboards ?? [];
    const appArtboards = (this.artboardsMap.get(pageID) ?? []).filter(
      (item) => artboards.findIndex((v) => v._id === item.artboardID) === -1,
    );
    const boards = this.doArtboardToPageArtboard(pageID, artboards).concat(appArtboards);
    return boards;
  }

  // 更新页面数据，浅更新
  protected setNodeMap(pageID: string, data: TNodeData) {
    const nodeData = this.nodeMap.get(pageID) || {};
    this.nodeMap.set(pageID, {
      ...nodeData,
      ...data,
    });
  }

  protected hasPageArtboards(pageID: string) {
    return this.artboardsMap.has(pageID);
  }

  protected getFragments(pageID: string): UIFragment[] {
    return this.coreMap.get(pageID)?.doc.fragments ?? [];
  }

  /**
   * 获取所有可用画板， 新内容面板为特殊画板，不被计入可用画板，
   */
  protected getAllFragments(): UIFragment[] {
    const fragments = Array.from(this.coreMap.values())
      .reduce((res: UIFragment[], curr) => {
        return [...res, ...curr.doc.fragments];
      }, [])
      .filter((fragment) => !fragment.ownerContentPanelID);
    return fragments;
  }

  /**
   * 获取已经加载的页面的相关画板，优先从CoreEditor.doc.artboard获取
   */
  protected getAllArtboards(): IPageArtboard[] {
    const editorLists = Array.from(this.coreMap.values());

    const artboards = editorLists.reduce((res: IPageArtboard[], curr) => {
      const pageArtboards = this.doArtboardToPageArtboard(curr.doc.pageID, curr.doc.artboards);
      return [...res, ...pageArtboards];
    }, []);

    const appArtboards = Array.from(this.artboardsMap.values())
      .reduce((res: IPageArtboard[], curr) => {
        return [...res, ...curr];
      }, [])
      .filter((item) => artboards.findIndex((v) => v.artboardID === item.artboardID) === -1);

    return [...appArtboards, ...artboards];
  }

  /**
   * 创建coreEditor， 再次调用从内存中取；重新创建使用rebuild
   * @param pageID
   * @param artboards
   */
  public build<T = CoreEditorManager>(
    pageNode: TNodeData,
    artboards: IArtboard[],
    states?: ICreateStates,
  ): TManager<T> {
    const coreMap = this.coreMap.get(pageNode._id) as TManager<T>;
    if (coreMap) {
      return coreMap;
    }
    this.create(pageNode, artboards, states);
    const core = this.getCoreMap(pageNode._id)!;
    return core as TManager<T>;
  }

  /**
   * 重新创建coreEditor，覆盖旧值
   * @param pageID
   * @param artboards
   */
  public rebuild<T = CoreEditorManager>(inode: TNodeData, artboards: IArtboard[], states?: ICreateStates): TManager<T> {
    this.create(inode, artboards, states);
    return this.getCoreMap(inode._id) as TManager<T>;
  }

  public clear(pageID?: string) {
    if (pageID) {
      this.coreMap.delete(pageID);
    } else {
      this.coreMap.clear();
    }
  }
}

class CoreEditorManager extends CoreManager {
  constructor() {
    super();
    this.creator = 'CoreEditor';
  }

  get allArtboards(): IPageArtboard[] {
    return super.getAllArtboards();
  }

  /**
   * 获取页面关联的所有画板
   * @param pageID
   */
  public async loadArtboard(pageID: string, isExample: boolean = false): Promise<IArtboard[]> {
    const payload = await getAllArtboardsByNodeID(pageID, undefined, isExample);
    return payload;
  }

  public setArtboards(pageID: string, artboards: IPageArtboard[]): void {
    super.setArtboards(pageID, artboards);
  }

  public getArtboards(pageID: string): IPageArtboard[] {
    return super.getArtboards(pageID);
  }

  public getFragments(pageID: string): UIFragment[] {
    return super.getFragments(pageID) || [];
  }

  public getCoreEditor(pageID: string): CoreEditor | undefined {
    return this.coreMap.get(pageID) as CoreEditor;
  }

  public setNodeData(pageID: string, data: TNodeData) {
    super.setNodeMap(pageID, data);
  }
}

class CoreTheaterManager extends CoreManager {
  constructor() {
    super();
    this.creator = 'Theater';
  }

  private rebuildTheater(theater: Theater, artboards: IArtboard[]) {
    super.rebuild({ _id: theater.pageID }, artboards);
  }

  public setAppStates(states: ICreateStates) {
    super.appStates = states;
  }

  /**
   * 获取页面关联的所有画板,包含离演示包
   * @param pageID
   * 注：
   *  请求缓存？
   *  可能appID不一样？，appID变化是否重置数据
   */
  public async loadArtboard(pageID: string) {
    return await apis.artboard.getAllPreviewArtboardsByNodeID(pageID);
  }

  public async pushArtboard(pageID: string, artboards: IArtboard[]): Promise<boolean> {
    const theater = this.getTheater(pageID);
    if (!theater) {
      return false;
    }
    this.rebuildTheater(theater, [...theater.doc.artboards, ...artboards]);
    return true;
  }

  /**
   * 设置页面相关联的画板
   */
  public setArtboards(pageID: string, artboards: IPageArtboard[]): void {
    super.setArtboards(pageID, artboards);
  }

  public hasPageArtboards(pageID: string) {
    return super.hasPageArtboards(pageID);
  }

  public getFragments(pageID: string) {
    return super.getFragments(pageID);
  }

  public getAllFragments() {
    return super.getAllFragments();
  }

  public getTheater(pageID: string): Theater {
    return this.coreMap.get(pageID) as Theater;
  }
}

const EditorManager = new CoreEditorManager();

const TheaterManager = new CoreTheaterManager();

export { EditorManager, TheaterManager, CoreEditorManager, CoreTheaterManager };
