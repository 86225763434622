export class CustomError extends Error {
  constructor(message: string, public code: number = 1) {
    super(message);
    this.code = code;
    this.message = message;
  }
}

export class CustomErrorWithPayload extends Error {
  constructor(message: string, public code: number = 1, public payload: any) {
    super(message);
    this.code = code;
    this.message = message;
    this.payload = payload;
  }
}
