import { get } from 'lodash';

import * as BoundsUtils from '@utils/boundsUtils';
import { flatArray, scaleObjectValue, betweenNumber } from '@utils/globalUtils';

import { IComponentData } from '@fbs/rp/models/component';
import { Ops, PagePatches, ArtboardPatches } from '@fbs/rp/utils/patch';
import ZOrderModel from '@consts/enums/zOrderType';
import { IBoundsOffset, IPoint } from '@fbs/common/models/common';
import { collectComponentsLayout } from '@helpers/responseLayoutHelper';
import { coverPatches, mergePatches, assignPatches } from '@helpers/patchHelper';
import { getInnerOrAroundConnect, modifyConnectPatch } from '@helpers/pathFinderHelper';
import { CompTextStyleNameConfig } from '@consts/component';
import { IBasicLayout, ILayout, HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import { IInteraction } from '@fbs/rp/models/interactions';
import { CSelectPanel, CSelect, CText, CSymbol, CCompoundPath, CParagraph, CPureText } from '@libs/constants';
import { ArtboardPatchesClass } from '@editor/patches/artboardPatches';
import { ComponentPatchesClass } from '@editor/patches/ComponentPatches';
import { PredefinedStates } from '@consts/state';
import { getCompAbsoluteMatrix } from '@helpers/componentHelper';
import { Matrix } from '@utils/matrixUtils';

import RemarkManager from '@/managers/refactorRemarkManager';
import { IUICompConstructOptions } from '@/customTypes';

import { getViewBoundsOfComponents } from '../bounds';

import {
  ComponentChange,
  ContainerPatches,
  ComponentChangeType,
  getNewPositionWhenCenter,
  extractDynamicInfoFromPatch,
  ResizeOptions,
} from './resizeHelper';
import { makeComponents, makeUIComponent } from './factory';
import { UIComponent, UIPanelComponent, UISymbolComponent, IFindResult } from '.';

export default abstract class UIContainerComponent extends UIComponent {
  public readonly ownerContentPanelID?: string;
  public components: UIComponent[];
  // 允许移动子
  public readonly isContainer: boolean = true;

  public isActived: boolean = false;

  // 添加子组件
  abstract addComponents(
    components: IComponentData[],
    index?: number,
  ): {
    patches: PagePatches;
    newActiveGroup?: UIContainerComponent;
  };

  abstract getPositionPatchesOfChildrenChanged(
    changes: ComponentChange[],
    includeSelf: boolean,
    willRemoveComps?: UIComponent[],
  ): ContainerPatches;

  constructor(data: IComponentData, public parent?: UIContainerComponent, public options?: IUICompConstructOptions) {
    super(data, parent, options);
    // repeater
    this.components = [];
    this.doAfterCreate();
    this.doCreateChildren();
  }

  /**
   * @override
   * @param {IComponentData[]} appendComponents
   * @return {ArtboardPatches | null}
   */
  protected afterAppendComponents(appendComponents: IComponentData[]): ArtboardPatches | null {
    const onAddChildren = this.libData?.editor?.onAddChildren;
    if (onAddChildren) {
      return onAddChildren(this, appendComponents);
    }
    return null;
  }

  protected afterRemoveComponents(removeIDs: string[]): ArtboardPatches | null {
    const onRemoveChildren = this.libData?.editor?.onRemoveChildren;
    if (onRemoveChildren) {
      return onRemoveChildren(this, removeIDs);
    }
    return null;
  }

  /**
   * 获取向下子的版本号链，一个组，可能它自己没有变化，但它的子有变化时，需要更新它的渲染
   * @returns {string}
   */
  public get version(): string {
    const version = super.version;
    const childVersions = this.components.map((comp) => comp.version);
    childVersions.unshift(version);
    return childVersions.join('-');
  }

  /**
   * 获取高级编辑的主体,如果是列表,那么主体就是列表的一项
   */
  getCompForAdvanceEditor(): IComponentData[] {
    let result: IComponentData[] = [];
    let component: IComponentData;
    let libData = this.libData;
    if (libData?.isList) {
      component = this.components[0].toJSON();
    } else if (this.isSealed && this.type === CSelectPanel) {
      component = this.components[0].toJSON();
    } else {
      component = this.toJSON();
    }
    result.push(component);
    if (this.type === CSelect && this.components[1]) {
      let comp = get(this.components[1], 'components[0].components[0]');
      comp && result.push(comp.toJSON());
    }
    return result;
  }

  getComponentByAlias(alias: string, isRecursive: boolean = true): UIComponent | undefined {
    if (alias) {
      let result = this.components.find((comp) => comp.alias === alias);
      if (!result && isRecursive) {
        for (let i = 0, c = this.components.length; i < c; i++) {
          const comp = this.components[i];
          if (comp.isContainer) {
            result = (comp as UIContainerComponent).getComponentByAlias(alias, isRecursive);
            if (result) {
              break;
            }
          }
        }
      }
      return result;
    }
    return undefined;
  }

  get componentsExceptConnector(): UIComponent[] {
    return this.components.filter((c) => !c.isConnector);
  }

  get compType() {
    return this.libData?.type || this.type;
  }

  removeComponents(
    components: UIComponent[],
  ): {
    patches: PagePatches;
    newActiveGroup?: UIContainerComponent;
  } {
    //这里的components 为移除的组件，可能有流程线，删除这些后，会孤立的流程线也要被删除, 剩下与这些组件相关的流程线要修正点
    const normalRemoveComps = components.filter((comp) => !comp.isConnector);
    const { innerConnectComps, roundConnectComps, aloneConnectComps } = getInnerOrAroundConnect(
      this,
      components.map((comp) => comp.id),
    );
    const removeComps = [...normalRemoveComps, ...innerConnectComps, ...aloneConnectComps];
    const removeIds = removeComps.map((comp) => comp.id);
    //移除组件，修正未选择的相关流程线的点
    const aloneConnectCompsIds = aloneConnectComps.map((comp) => comp.id);
    const replacePatches: ArtboardPatches = { do: {}, undo: {} };
    roundConnectComps
      .filter((comp) => !aloneConnectCompsIds.includes(comp.id))
      .forEach((comp) => {
        modifyConnectPatch(comp, { x: 0, y: 0 }, replacePatches);
      });

    const removePatches = {
      do: {
        [this.id]: [Ops.removeChildren(removeIds)],
      },
      undo: {
        [this.id]: removeComps.map((comp) =>
          Ops.addChildren(`${this.components.findIndex((c) => c === comp)}`, [comp.toJSON()]),
        ),
      },
    };
    const patches: PagePatches = {
      [this.ownerArtboardID]: mergePatches(replacePatches, removePatches),
    };
    const posPatches = this.afterRemoveComponents(components.map((comp) => comp.id));
    if (posPatches) {
      coverPatches(patches[this.ownerArtboardID], posPatches);
    }

    return {
      patches,
      newActiveGroup: this,
    };
  }

  updateBoundsOfChildUsingRefTextStyle = (propertyName: string, myNewValue: any) => {
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    this.components.forEach((comp) => {
      if (comp.isContainer) {
        // 如果是容器，就继续往下
        const childPatch = comp.updateBoundsOfChildUsingRefTextStyle(propertyName, myNewValue);
        coverPatches(patches, childPatch);
      } else {
        //如果组件使用了ref的方式设置的textStyle,那么要跟随父字体样式的变化，更新自己的bounds
        const compRef = comp.currentState?.properties?.textStyle?.ref;
        // 如果是组件整体设置文本样式，那么所有的都要更新bounds
        if (CompTextStyleNameConfig[comp.lib?.type || comp.type]?.entireTextStyleName === propertyName) {
          if (compRef && compRef.includes('@properties')) {
            const text = comp.value as string;
            const { textFormat, multiText } = comp.properties;
            const options = {
              textFormat: myNewValue,
              // textStyle: myNewValue,
              // multiText: comp.properties.multiText,
            };
            if (!textFormat) {
              options.textFormat = {
                ...myNewValue,
                ...(multiText || {}),
              };
            }

            const compPatches = comp.updateBoundsWithTextChange(text, options);
            coverPatches(patches, compPatches);
          }
        } else {
          //如果是修改选中文本的样式，那么就只更新选中文本的bounds
          const ref = `@properties.${propertyName}`;
          if (compRef && compRef === ref) {
            const { textFormat, multiText } = comp.properties;
            const text = comp.value as string;
            const options = {
              textFormat: myNewValue,
              // textStyle: myNewValue,
              // multiText: comp.properties.multiText,
            };
            if (!textFormat) {
              options.textFormat = {
                ...myNewValue,
                ...(multiText || {}),
              };
            }
            const compPatches = comp.updateBoundsWithTextChange(text, options);
            coverPatches(patches, compPatches);
          }
        }
      }
    });
    return patches;
  };

  updateResponsiveLayout = (responsive: boolean): ArtboardPatches => {
    const patches: ArtboardPatches = {
      do: {
        [this.id]: [Ops.replace('/layout/responsive', responsive)],
      },
      undo: {
        [this.id]: [Ops.replace('/layout/responsive', this.layout?.responsive)],
      },
    };
    // 开关按钮是否影响子的响应式布局
    let isNotAffectChildren;
    if (this.isSealed) {
      isNotAffectChildren = true;
    }
    if (this.type === CSelectPanel) {
      isNotAffectChildren = false;
    }
    if (isNotAffectChildren) {
      //复合组件 容器的响应式开关不要影响内部的响应式,会破坏提前设定好的布局
      return patches;
    }
    this.components.forEach((comp) => {
      if (comp.isContainer) {
        const posPatches = comp.updateResponsiveLayout(responsive);
        coverPatches(patches, posPatches);
      } else {
        patches.do[comp.id] = [Ops.replace('/layout/responsive', responsive)];
        patches.undo[comp.id] = [Ops.replace('/layout/responsive', comp.layout?.responsive)];
      }
    });
    return patches;
  };

  doAfterCreate() {}

  doCreateChildren() {
    if (this.data.components) {
      this.components = makeComponents(this.data.components, this, this.options);
    }
  }

  /**
   * 获取满足条件的child
   * @param fn 用于筛选的函数
   * @param isRecursive true表示所有的子孙，false表示只有子
   */
  getSuchChild(fn: (comp: UIComponent) => boolean, isRecursive: boolean = true) {
    const children = this.components;
    let result: UIComponent[] = [];
    if (isRecursive) {
      children.forEach((comp) => {
        if (comp.isContainer) {
          result = result.concat(comp.getSuchChild(fn, isRecursive));
        } else {
          if (fn(comp)) {
            result.push(comp);
          }
        }
      });
    } else {
      children.forEach((comp) => {
        if (fn(comp)) {
          result.push(comp);
        }
      });
    }
    return result;
  }

  getChildWhichTypeIsText = () => {
    const children = this.components;
    let result: UIComponent[] = [];
    children.forEach((comp) => {
      if (comp.isContainer) {
        result = result.concat(comp.getChildWhichTypeIsText());
      } else {
        if (comp.type === CText) {
          result.push(comp);
        }
      }
    });
    return result;
  };

  private updateRemark = (comp: UIComponent) => {
    const remarkManager = RemarkManager.getInstance(this.document || undefined);
    remarkManager?.retrieveComponentRemark(comp);
  };

  private removeRemark = (comp: UIComponent) => {
    const remarkManager = RemarkManager.getInstance(this.document || undefined);
    if (comp instanceof UIContainerComponent) {
      comp.components.forEach(this.removeRemark);
    }
    remarkManager?.removeRemark(comp.id);
  };

  // 处理 uiComp 的删除
  private handleComponentDeletion(compMap: { [id: string]: IComponentData }) {
    let i = this.components.length - 1;
    // TODO Matt 此处的备注管理是否存在性能问题，待验证
    for (; i >= 0; i--) {
      const uiComp = this.components[i];
      const itemData = compMap[uiComp.id];
      if (!itemData) {
        this.components.splice(i, 1);
        this.removeRemark(uiComp);
      } else {
        if (uiComp instanceof UIContainerComponent) {
          // NOTE: 因为有可能 uicomp 实例变了，所以要重新构建他的后代 uicomp 实例 ???
          uiComp.refreshComponents();
        }
        // 因为删除 uicomp 是在添加之后处理的，所以要重新更新下新增组件的remark
        this.updateRemark(uiComp);
      }
    }
  }

  refreshComponents() {
    if (!this.data.components) {
      return;
    }

    const compMap: { [id: string]: IComponentData } = {};
    // NOTE: data 是新的
    this.data.components.forEach((comp, idx) => {
      compMap[comp._id] = comp;
      const oldIndex = this.components.findIndex((uiComp) => uiComp.id === comp._id);
      if (idx === oldIndex) {
        // 位置相同，ID相同，但可能type等其它在构建时确定的数据已发生变化时，原地替换
        // 此种方式主要发生在组件类型变化后，需要保留其作为其它组件交互目标时
        const uiComp = this.components[idx];
        if (uiComp instanceof UISymbolComponent) {
          if (comp.type === CSymbol) {
            uiComp.updateSymbolBounds();
          } else {
            // 分离后
            this.components.splice(idx, 1, makeUIComponent(comp, this, this.options));
          }
        } else if (comp.type !== uiComp.type) {
          this.components.splice(idx, 1, makeUIComponent(comp, this, this.options));
        }
        return;
      }
      // 之前不存在这个组件，创建并添加上
      if (oldIndex === -1) {
        const uiComponent = makeUIComponent(comp, this, this.options);
        this.components.splice(idx, 0, uiComponent);
        this.updateRemark(uiComponent);
        return;
      }
      // 存在，但是位置不正确
      const oldComp = this.components[oldIndex];
      // 删除原来位置的
      this.components.splice(oldIndex, 1);
      // 放到正确的位置上
      this.components.splice(idx, 0, oldComp);
    });

    this.handleComponentDeletion(compMap);
  }

  protected doChangeZOrderBefor(
    componentIDs: string[],
    zOrderModel: ZOrderModel,
  ): { comps: IComponentData[]; patches: ArtboardPatches } | null {
    const selCount = componentIDs.length;
    if (selCount === 0) {
      return null;
    }
    const orderIDs: string[] = [];
    this.components.forEach((comp) => {
      if (componentIDs.includes(comp.id)) {
        orderIDs.push(comp.id);
      }
    });

    const groupID = this.type === 'artboard' ? 'ROOT' : this.id;
    const patches: ArtboardPatches = {
      do: {
        [groupID]: [],
      },
      undo: {
        [groupID]: [],
      },
    };

    const beforeComps = this.toJSON().components || [];
    const afterComps = [...beforeComps];

    if (zOrderModel === ZOrderModel.bringToFront) {
      // 置顶
      orderIDs.forEach((id) => {
        const index = afterComps.findIndex((comp) => comp._id === id);
        const comp = afterComps[index];
        afterComps.splice(index, 1);
        afterComps.push(comp);
      });
    } else if (zOrderModel === ZOrderModel.frontForward) {
      // 上移一格
      //1：不能移动的位置认为移动到了自身的位置,记录到forbiddenOrderIndex
      //2: 正常移动到目标位置后,记录到forbiddenOrderIndex
      //3：移动之前判断该位置是否已经被之前的移动占用过
      const forbiddenOrderIndex: number[] = [];
      for (let i = orderIDs.length - 1; i >= 0; i--) {
        const index = afterComps.findIndex((comp) => comp._id === orderIDs[i]);
        if (index < afterComps.length - 1) {
          if (!forbiddenOrderIndex.includes(index + 1)) {
            [afterComps[index], afterComps[index + 1]] = [afterComps[index + 1], afterComps[index]];
            forbiddenOrderIndex.push(index + 1);
          } else {
            forbiddenOrderIndex.push(index);
          }
        } else {
          forbiddenOrderIndex.push(index);
        }
      }
    } else if (zOrderModel === ZOrderModel.backForward) {
      // 下移一格
      //1：不能移动的位置认为移动到了自身的位置,记录到forbiddenOrderIndex
      //2: 正常移动到目标位置后,记录到forbiddenOrderIndex
      //3：移动之前判断该位置是否已经被之前的移动占用过
      const forbiddenOrderIndex: number[] = [];
      orderIDs.forEach((id) => {
        const index = afterComps.findIndex((comp) => comp._id === id);
        if (index > 0) {
          if (!forbiddenOrderIndex.includes(index - 1)) {
            [afterComps[index], afterComps[index - 1]] = [afterComps[index - 1], afterComps[index]];
            forbiddenOrderIndex.push(index - 1);
          } else {
            forbiddenOrderIndex.push(index);
          }
        } else {
          forbiddenOrderIndex.push(index);
        }
      });
    } else if (zOrderModel === ZOrderModel.sendToBack) {
      // 置底
      for (let i = orderIDs.length - 1; i >= 0; i--) {
        const index = afterComps.findIndex((comp) => comp._id === orderIDs[i]);
        const comp = afterComps[index];
        afterComps.splice(index, 1);
        afterComps.unshift(comp);
      }
    }

    patches.do[groupID].push(Ops.replace('/components', afterComps));
    patches.undo[groupID].push(Ops.replace('/components', beforeComps));

    return {
      comps: afterComps,
      patches,
    };
  }

  /**
   * 调整组件层级
   */
  doChangeZOrder(
    componentIDs: string[],
    zOrderModel: ZOrderModel,
  ): {
    patches: PagePatches | null;
    newContainer?: UIContainerComponent;
  } {
    const data = this.doChangeZOrderBefor(componentIDs, zOrderModel);
    if (!data) {
      return { patches: null };
    } else {
      return {
        patches: {
          [this.ownerArtboardID]: data.patches,
        },
      };
    }
  }

  moveChildOrder(oldIndex: number, newIndex: number): ArtboardPatches {
    let from = oldIndex;
    let to = newIndex;
    if (to > from) {
      to--;
    }
    const groupID = this.type === 'artboard' ? 'ROOT' : this.id;
    return {
      do: {
        [groupID]: [Ops.move(`/components/${to}`, `/components/${from}`)],
      },
      undo: {
        [groupID]: [Ops.move(`/components/${from}`, `/components/${to}`)],
      },
    };
  }

  getFirstChildComponentByType(type: string): UIComponent | undefined {
    return this.components.find((item) => item.type === type);
  }

  /**
   * 获取点位置下组内的组件
   * @param {{left: number, top: number}} point 相对于当前组内的一个坐标点
   * @param {{isRecursive: boolean,enterSealedComp: boolean }} option  是否递归查找
   * @returns { | undefined}
   */
  getComponentByPoint(
    point: { left: number; top: number },
    option?: { isRecursive?: boolean; enterSealedComp?: boolean; enterSymbol?: boolean; ignoreHiddenComp?: boolean },
  ): UIComponent | undefined {
    return (
      this.getChildrenByPagePoint(
        { x: point.left, y: point.top },
        {
          isRecursive: option?.isRecursive,
          allowSealedChild: option?.enterSealedComp,
          enterSymbol: option?.enterSymbol,
          ignoreHiddenComp: option?.ignoreHiddenComp,
        },
      ) || undefined
    );
  }

  getChildrenByPagePoint(
    point: IPoint,
    option?: { isRecursive?: boolean; allowSealedChild?: boolean; enterSymbol?: boolean; ignoreHiddenComp?: boolean },
  ): UIComponent | null {
    // 1、按顺序解开所有不为组的组件，根据规则确定是否递归，是否进入复合组件内部，并按从顶到底的顺序排列
    const flatComps = flatArray(this.components, (item) => {
      if (item.isContainer) {
        if (option?.isRecursive) {
          if ((item.isSealed && !option?.allowSealedChild) || (item.isSymbol && !option?.enterSymbol)) {
            return null;
          }
          return (item as UIContainerComponent).components;
        }
      }
      return null;
    }).reverse();
    const { x: left, y: top } = point;
    const groupList: UIContainerComponent[] = [];
    const matrixs: { [id: string]: Matrix } = {};
    // 2、先从上述基本组件查找，如果找到，直接返回，如果未找到执行第三步
    for (let i = 0; i < flatComps.length; i++) {
      if (flatComps[i].hidden && option?.ignoreHiddenComp) {
        continue;
      }
      const comp = flatComps[i];
      const parent = comp.parent!;
      const matrix = matrixs[parent.id] || getCompAbsoluteMatrix(parent);
      matrixs[parent.id] = matrix;
      const bounds = flatComps[i].getViewBoundsInPage(matrix);
      if (BoundsUtils.isContainerPoint(bounds, { left, top })) {
        return flatComps[i];
      }
      if (parent !== this && !groupList.includes(parent)) {
        groupList.push(parent);
      }
    }
    // 3、按层级尝试及顺序，从深到浅取出到当前容器之下的所有容器组件
    const allGroupList = [...groupList];
    const findParent = (comp: UIComponent) => {
      if (comp.parent !== this && !allGroupList.includes(comp.parent!)) {
        allGroupList.push(comp.parent!);
        findParent(comp.parent!);
      }
    };
    groupList.forEach(findParent);
    // 4、从容器组件中查找是否存在符合条件的目标，如果存在就返回它
    for (let i = 0; i < allGroupList.length; i++) {
      const group = allGroupList[i];
      let matrix: Matrix | undefined;
      if (group.parent) {
        matrix = matrixs[group.parent.id] || getCompAbsoluteMatrix(group.parent);
        matrixs[group.parent.id] = matrix;
      }
      const bounds = group.getViewBoundsInPage(matrix);
      if (BoundsUtils.isContainerPoint(bounds, { left, top })) {
        return allGroupList[i];
      }
    }
    return null;
  }

  /**
   *  如果在resize单个组件时，你所传入的offset是基于comp的viewBounds的变化，那么isResizeMySelf应该设置为false
   * @param components 被resize的组件
   * @param args
   * @param options 是否是shift,是否是resize单个组件
   */
  resizeChildren(
    components: UIComponent[],
    args: {
      offset: IBoundsOffset;
      backupAllCompsLayout?: WeakMap<UIComponent, IBasicLayout>;
      willRemoveComps?: UIComponent[];
    },
    options: {
      shift: boolean;
      allowLockedComp?: boolean;
      isScale?: boolean;
      isSwitchToShift?: boolean;
      changeRadius?: boolean;
      changeShadow?: boolean;
      scalePercent?: number;
    },
  ): ArtboardPatches {
    return this.resizeChildrenBase(components, args, options, true);
  }

  /**
   *  相比resizeChildren， 不更新容器
   */
  resizeChildrenWithoutUpdateSelf(
    components: UIComponent[],
    args: {
      offset: IBoundsOffset;
      backupAllCompsLayout?: WeakMap<UIComponent, IBasicLayout>;
      willRemoveComps?: UIComponent[];
    },
    options: {
      shift: boolean;
      allowLockedComp?: boolean;
      isScale?: boolean;
      isSwitchToShift?: boolean;
      changeRadius?: boolean;
      changeShadow?: boolean;
      scalePercent?: number;
    },
  ): ArtboardPatches {
    return this.resizeChildrenBase(components, args, options, false);
  }

  resizeChildrenBase(
    components: UIComponent[],
    args: {
      offset: IBoundsOffset;
      backupAllCompsLayout?: WeakMap<UIComponent, IBasicLayout>;
      willRemoveComps?: UIComponent[];
      dragIndex?: number;
    },
    options: {
      shift: boolean;
      allowLockedComp?: boolean; // 是否可以改变锁定组件的大小
      isScale?: boolean;
      isSwitchToShift?: boolean;
      changeRadius?: boolean;
      changeShadow?: boolean;
      scalePercent?: number;
    },
    updateSelf = true, // 是否更新容器自身
  ): ArtboardPatches {
    let patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    // 为什么需要在这里过滤，而不在外层过滤，是因为框选多个组件时，下面的ViewBounds需要计算整个框选的组件
    const unLockComponents = options.allowLockedComp ? components : components.filter((comp) => !comp.locked);
    const bounds = getViewBoundsOfComponents(components)!;
    const { offset, backupAllCompsLayout, willRemoveComps, dragIndex } = args;
    const layoutMap = backupAllCompsLayout || collectComponentsLayout(components, bounds);
    // 先获取当前一层comps的layout
    const isContainerResponsive = unLockComponents.reduce((acc, curr) => {
      acc = acc && curr.layout.responsive;
      return acc;
    }, true);
    const newSize = {
      width: bounds.width + offset.right - offset.left,
      height: bounds.height + offset.bottom - offset.top,
    };
    const childrenResizeOptions: ResizeOptions = {
      container: {
        before: {
          position: {
            x: bounds.left,
            y: bounds.top,
          },
          size: {
            width: bounds.width,
            height: bounds.height,
          },
        },
        after: {
          position: {
            x: bounds.left + offset.left,
            y: bounds.top + offset.top,
          },
          size: newSize,
        },
        isResponsive: isContainerResponsive,
        isResizeMySelf: options.isScale === true ? false : components.length === 1,
      },
      scale: {
        h: newSize.width / (bounds.width || 1),
        v: newSize.height / (bounds.height || 1),
      },
      ...options,
    };

    // 多选模式下，组件都关闭了响应式，shift拉动边点，会造成宽高同时变化
    // 这时多选框作为暂时的容器，比如把宽高的变化同时传递给子
    if (components.length > 1) {
      //超过1像素的移动才视为大小改变
      const isWidthChange = Math.abs(newSize.width - bounds.width) > 1;
      if (!isContainerResponsive && options.shift) {
        if (isWidthChange) {
          childrenResizeOptions.scale.v = childrenResizeOptions.scale.h;
        } else {
          childrenResizeOptions.scale.h = childrenResizeOptions.scale.v;
        }
      }
    }

    const parentSize = unLockComponents[0].parent?.size;
    const newComponentsInfo = unLockComponents.map((comp) => {
      let layout: IBasicLayout | ILayout | undefined = layoutMap.get(comp);
      if (this.type === CCompoundPath) {
        layout = {
          responsive: true,
          auto: false,
          horizontal: HorizontalAlign.LeftAndRight,
          vertical: VerticalAlign.TopAndBottom,
          fixedHeight: false,
          fixedWidth: false,
        };
      }
      const newCompInfo = comp.resizeHandler2(offset, layout!, childrenResizeOptions, backupAllCompsLayout, dragIndex);
      if (newCompInfo.patches) {
        coverPatches(patches, newCompInfo.patches);
      }
      //如果组件是居中的，那么需要重置position
      newCompInfo.position = getNewPositionWhenCenter(comp, newCompInfo.position, newCompInfo.size, parentSize!);
      return {
        id: comp.id,
        type: ComponentChangeType.Edit,
        position: newCompInfo.position,
        size: newCompInfo.size,
        rotate: newCompInfo.rotate,
      };
    });
    // 如果修改复合路径position、size、value、rotate，修改在默认属性上

    // 如果不需要更新容器，借用面板的方法
    const res = updateSelf
      ? this.getPositionPatchesOfChildrenChanged(newComponentsInfo, true, willRemoveComps)
      : UIPanelComponent.prototype.getPositionPatchesOfChildrenChanged.bind(this)(newComponentsInfo);
    if (res.patches) {
      coverPatches(patches, res.patches);
    }

    unLockComponents.forEach((comp) => {
      if ([CText, CParagraph].includes(comp.type)) {
        const { multiText, textFormat } = comp.properties;
        if (textFormat || multiText) {
          if (!(textFormat || multiText)!.wrap) {
            const stateID = comp.currentStateID;

            const path = comp.getCurrentPropertiesPath(`properties/${textFormat ? 'textFormat' : 'multiText'}`);
            const newValue = { ...(textFormat || multiText), wrap: true };
            const doOpt = Ops.replace(path, newValue);
            const undoOpt = Ops.replace(path, multiText);

            if (patches.do[comp.id]) {
              patches.do[comp.id].push(doOpt);
              patches.undo[comp.id].push(undoOpt);
            } else {
              patches.do[comp.id] = [doOpt];
              patches.undo[comp.id] = [undoOpt];
            }

            if (stateID && stateID !== PredefinedStates.normal) {
              if (!comp.states[stateID]) {
                patches.do[comp.id].unshift(Ops.add(`/states/${stateID}`, { disabled: false, properties: {} }));
                patches.undo[comp.id].push(Ops.remove(`/states/${stateID}`));
              } else if (!comp.states[stateID].properties) {
                patches.do[comp.id].unshift(Ops.add(`/states/${stateID}/properties`, {}));
              }
            }
          }
        }
      }
      // 自动大小的文本组件修改大小,就变为非自动大小状态了
      const autoSize = comp.autoSize;
      if ([CText, CPureText].includes(comp.type) && autoSize) {
        const artboardPatches = new ArtboardPatchesClass().getPatchesByCompChange(
          comp.id,
          new ComponentPatchesClass().getAttrChangePatches(comp.id, './autoSize', {
            oldVal: autoSize,
            newVal: !autoSize,
          }),
        );
        coverPatches(patches, artboardPatches);
      }

      const scale = (options.scalePercent || 100) / 100;
      if (options.changeShadow) {
        const shadowPatches = this.getShadowPatches(comp, scale);
        shadowPatches && coverPatches(patches, shadowPatches);
      }

      if (options.changeRadius) {
        const radiusPatches = this.getRadiusPatches(comp, scale);
        radiusPatches && coverPatches(patches, radiusPatches);

        const thicknessPatches = this.getThicknessPatches(comp, scale);
        thicknessPatches && coverPatches(patches, thicknessPatches);
      }
      const { type } = comp;
      const needWrap = autoSize || [CParagraph, CText].includes(type);
      const textFormatPatches = this.getScaleTextFormatPatches(comp, scale, needWrap);

      textFormatPatches && coverPatches(patches, textFormatPatches);
    });

    // 如果是复合组件的resize，那么可能会有复合组件特殊的定制的resize
    // 那么得到patches，并优先应用复合组件里面的返回的patches
    unLockComponents.forEach((comp) => {
      const newComp = newComponentsInfo.find((newComp) => newComp.id === comp.id)!;
      if (comp.type === 'symbol') {
        const map = extractDynamicInfoFromPatch(patches);
        const tempComp = comp as UIContainerComponent;
        tempComp.components.forEach((realComp) => {
          const newSize = map[realComp.id]?.size;
          if (newSize) {
            const sealedCompSpecialPatch = realComp.getSealedCompSpecialResizePatches(newSize);
            if (sealedCompSpecialPatch) {
              patches = assignPatches(patches, sealedCompSpecialPatch);
            }
          }
        });
      }
      const sealedCompSpecialPatch = comp.getSealedCompSpecialResizePatches(newComp.size, updateSelf);
      if (sealedCompSpecialPatch) {
        patches = assignPatches(patches, sealedCompSpecialPatch);
      }
    });
    return patches;
  }

  /**
   * DT专属：等比缩放阴影
   */
  getShadowPatches(component: UIComponent, scale: number) {
    if (!component.properties.shadow) {
      return;
    }
    const shadow = scaleObjectValue({ ...component.properties.shadow }, scale, {
      filter: (n) => Math.round(n),
    });
    return new ArtboardPatchesClass().getPatchesByCompChange(
      component.id,
      new ComponentPatchesClass().getAttrChangePatches(component.id, './properties/shadow', {
        oldVal: component.properties.shadow,
        newVal: shadow,
      }),
    );
  }

  /**
   * DT专属：等比缩放边框大小
   */
  getThicknessPatches = (component: UIComponent, scale: number) => {
    const stroke = component.properties.stroke;
    if (!stroke) {
      return;
    }
    stroke.thickness = Math.round(stroke.thickness! * scale);
    return new ArtboardPatchesClass().getPatchesByCompChange(
      component.id,
      new ComponentPatchesClass().getAttrChangePatches(component.id, './properties/stroke', {
        oldVal: component.properties.stroke,
        newVal: stroke,
      }),
    );
  };

  /**
   * DT专属：等比缩放圆角
   */
  getRadiusPatches(component: UIComponent, scale: number) {
    if (!component.properties.radius) {
      return;
    }
    const radius = scaleObjectValue({ ...component.properties.radius }, scale, {
      filter: (n) => Math.round(n),
    });
    return new ArtboardPatchesClass().getPatchesByCompChange(
      component.id,
      new ComponentPatchesClass().getAttrChangePatches(component.id, './properties/radius', {
        oldVal: component.properties.radius,
        newVal: radius,
      }),
    );
  }

  getScaleTextFormatPatches = (component: UIComponent, scale: number, wrapChange?: boolean) => {
    if (!component.properties.textFormat) {
      return;
    }
    const textFormat = { ...component.properties.textFormat };
    const { fontSize, lineHeight, lineHeightEx, letterSpace } = textFormat;
    if (fontSize) {
      textFormat.fontSize = betweenNumber(Math.round(fontSize * scale), { min: 8, max: 300 });
    }
    if (lineHeight) {
      textFormat.lineHeight = betweenNumber(Math.round(lineHeight * scale), { min: 0, max: 999 });
    }
    if (lineHeightEx) {
      textFormat.lineHeightEx = betweenNumber(Math.round(lineHeightEx * scale), { min: 0, max: 999 });
    }
    if (letterSpace) {
      textFormat.letterSpace = betweenNumber(Math.round(letterSpace * scale), { min: 0, max: 20 });
    }
    if (wrapChange) {
      textFormat.wrap = true;
    }
    return new ArtboardPatchesClass().getPatchesByCompChange(
      component.id,
      new ComponentPatchesClass().getAttrChangePatches(
        component.id,
        component.getCurrentPropertiesPath('/properties/textFormat'),
        {
          oldVal: component.properties.textFormat,
          newVal: textFormat,
        },
      ),
    );
  };

  /**
   * 是否包含某个子
   * @param {UIComponent} comp
   */
  isContainerChild = (comp: UIComponent) => {
    let parent = comp.parent;
    while (parent) {
      if (parent === this) {
        return true;
      } else {
        parent = parent.parent;
      }
    }
    return false;
  };

  hasConnector = (): boolean => {
    const children = this.components;
    return (
      children.filter((comp) => {
        if (comp.isContainer) {
          return (comp as UIContainerComponent).hasConnector();
        } else {
          return comp.isConnector;
        }
      }).length > 0
    );
  };

  public selectedItem?: UIComponent;

  /**
   * 临时添加子到data中，并不提交后端
   * @param comps
   * @param index
   */
  temporaryAddChild(comps: UIComponent[], index?: number) {
    const originChildrenIDs = this.components.map((child) => child.id);

    const compsNeedsAdd = comps.filter((comp) => {
      return !originChildrenIDs.includes(comp.id);
    });
    if (index !== undefined) {
      this.components.splice(index, 0, ...compsNeedsAdd);
    } else {
      compsNeedsAdd.forEach((compNeedsAdd) => {
        this.components.push(compNeedsAdd);
      });
    }
  }

  /**
   * 临时从数据中删除子，不与后端交互
   * @param comps
   */
  temporaryRemoveChild(comps: UIComponent[]) {
    const ids = comps.map((comp) => comp.id);
    ids.forEach((removeCompId) => {
      const index = this.components.findIndex((child) => child.id === removeCompId);
      if (index !== -1) {
        this.components.splice(index, 1);
      }
    });
  }

  get interactions(): IInteraction {
    if (this.isSealed) {
      const lib = this.libData;
      if (lib?.isList && this.selectedItem) {
        return this.selectedItem.interactions;
      }
    }
    return super.interactions;
  }

  public matchText(text: string, pageID: string): IFindResult[] {
    //@ts-ignore
    return this.components.flatMap((component) => component.matchText(text, pageID) || []);
  }

  getSelectedTextCompsCountWithAutoFill() {
    if (this.isSealed && this.libData?.isTextComp) {
      if (this.libData.isList) {
        return this.components.length;
      }
      return 1;
    }
    return 0;
  }
}
