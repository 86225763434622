import { omit } from 'lodash';
import i18n from '@/i18n';
import { IComponentItem } from '@/libs/types';
import { CPolygon, CPath, CCompoundPath } from '@/libs/constants';
import { UIComponent } from '@/editor/comps';
import { IComponentData } from '@/fbs/rp/models/component';
import { DefaultStrokeColor } from '@/consts/colors';
import { StrokeLineCap, StrokeLineJoin, StrokePosition } from '@/fbs/rp/models/properties/stroke';
import { FillType } from '@/fbs/rp/models/properties/fill';
import { PropertyValue } from '@/fbs/rp/models/property';
import { ArtboardPatches, Ops } from '@/fbs/rp/utils/patch';
import IPolygon, { PolygonPropertyName } from '@/fbs/rp/models/properties/polygon';
import { PolygonData } from '@/utils/graphicsUtils';

import { makeShapeBase, getShapeBaseDefaultData } from '../common/ShapeTextBase';

const pathDefaultData: Partial<IComponentData> = getPathDefaultData();

export const pathConfig: IComponentItem = {
  type: CPath,
  name: i18n('resource.components.path'),
  hidden: true,
  thumb: {},
  editor: {
    onValidateAllowEditor: selectPathEditorType,
    onPropertyUpdate: onPropertyChange,
  },
  getDefaultData() {
    return pathDefaultData;
  },
};

const compoundPathDefaultData: Partial<IComponentData> = getCompoundDefaultData();

// TODO 整理复合路径 make config
export const compoundPathConfig: IComponentItem = {
  type: CCompoundPath,
  name: i18n('resource.components.compoundPath'),
  hidden: true,
  thumb: {},
  getDefaultData() {
    return compoundPathDefaultData;
  },
};

export function makePathItem(id: string): IComponentData {
  // 初始化时，stroke的属性会从local缓存中取值，所以初始默认数据这里去掉
  const defaultData = omit(pathDefaultData, 'properties.stroke');
  return makeShapeBase(id, CPath, false, defaultData);
}

export function selectPathEditorType(comp: UIComponent, trigger: 'enter' | 'dblClick'): 'path' | 'value' {
  if (trigger === 'dblClick') {
    return 'path';
  }
  return 'value';
}

export function onPropertyChange(
  comp: UIComponent,
  propertyName: string,
  propValue: PropertyValue,
): ArtboardPatches | null {
  if (propertyName === PolygonPropertyName) {
    const { id, type, size, value } = comp;
    const newPolygon = propValue as IPolygon;
    const points = new PolygonData(size, newPolygon.sideCount).toPolygonPoints(0);

    const data = points.map((point) => ({
      point,
      handleIn: { x: 0, y: 0 },
      handleOut: { x: 0, y: 0 },
    }));
    const newValue = {
      data,
      closed: true,
    };
    return {
      do: {
        [id]: [Ops.replace('./type', CPolygon), Ops.replace('./value', newValue)],
      },
      undo: {
        [id]: [Ops.replace('./type', type), Ops.replace('./value', value)],
      },
    };
  }
  return null;
}

function getPathDefaultData() {
  const shapeBaseDefaultData = getShapeBaseDefaultData(false);

  Object.assign(shapeBaseDefaultData.properties, {
    stroke: {
      thickness: 1,
      color: DefaultStrokeColor,
      cap: StrokeLineCap.Butt,
      join: StrokeLineJoin.Miter,
      position: StrokePosition.center,
      disabled: false,
    },
    fill: {
      disabled: true,
      type: FillType.solid,
      color: { r: 255, g: 255, b: 255, a: 1 },
    },
    line: {
      startArrow: false,
      endArrow: false,
    },
  });

  return shapeBaseDefaultData;
}

function getCompoundDefaultData() {
  const shapeBaseDefaultData = getShapeBaseDefaultData(false);
  const { fill, stroke, shadow } = shapeBaseDefaultData.properties ?? {};

  return {
    properties: {
      fill,
      stroke,
      shadow,
    },
  };
}
