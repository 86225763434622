import { getStyleByNames } from './getStyleByNames';
import { searchByReg } from '../util/replaceUtil';
import { globalStatistics } from '../util/statisticsUtil';
import { sortByLength } from '../util';

export const getClassNameFromHtml = (html: string) => {
  // 样式名称

  const list: string[] = ['class="([^<>{}]*?)"'];
  const doMatchString = (str: string): string[] => {
    return str ? str.split(' ').filter((t) => t) : [];
  };
  const set = searchByReg(html, list, doMatchString);

  const result = sortByLength(Array.from(set));

  // window.debug && console.log(result);
  // 统计
  globalStatistics.addClassname('', result);
  return result;
};

/**
 * 样式名称缩写
 * @param names
 * @param matchName
 * @returns
 */
const abbrName = (names: string[], matchName: string) => {
  const index = names.findIndex((name) => matchName === name);
  // 保留字先不做替换
  if (/mockplus_rp/i.test(matchName)) {
    return matchName;
  }
  return index >= 0 ? 'c' + index : matchName;
};

/**
 * html中的classname缩写
 * @param html
 * @param className
 * @returns
 */
export const abbrClassName = (html: string, className?: string[]) => {
  if (!className || className.length === 0) {
    return html;
  }
  const names = className;
  const regStr = 'class="([^<>{}]*?)"';
  const reg = new RegExp(regStr, 'gi');
  const result = html.replace(reg, (a, m) => {
    const token = m.split(' ').map((t: string) => {
      return abbrName(names, t);
    });
    return a.replace(m, token.join(' '));
  });

  // window.debug && console.log('abbrClassName:' + (html.length - result.length) / 1024 + 'kb ');
  globalStatistics.addReduce('abbrClassName', html.length - result.length);

  return result;
};

/**
 * style中的 名称缩写，与html中classname名字一一对应
 * @param style
 * @param className
 * @returns
 */
export const abbrStyleName = (style: string, className?: string[]) => {
  if (!className || className.length === 0) {
    return style;
  }
  const regStr = '\\.([^<{}]*?){';
  const reg = new RegExp(regStr, 'gi');
  const regNames = new RegExp('(' + className.join(')|(') + ')', 'gi');
  const len = className.length;

  const result = style.replace(reg, (a, m) => {
    const token = m.replace(regNames, (all: string, ...ms: string[]) => {
      const names = ms.slice(0, len);
      return abbrName(names, all);
    });

    const replaceMement = a.replace(m, token);
    return replaceMement;
  });

  globalStatistics.addReduce('abbrStyleName', style.length - result.length);
  return result;
};

export const getClassNameFromRp = (html: string) => {
  const names = getClassNameFromHtml(html).filter(
    (t) =>
      ![
        'preview',
        'middle',
        'normal',
        'wrap',
        'no-wrap',
        'track-bar',
        'autoHide',
        'horizontal',
        'clip',
        'win',
        'web',
        'empty',
        'cell',
        'mask',
        'no-sealed',
        'content',
        'item',
        'text',
      ].includes(t),
  );
  return names;
};

export const getComponentStyle = (html: string, className?: string[]) => {
  const names = (className ?? getClassNameFromRp(html)).map((t) => {
    if (t === 'component') {
      return `^\\.${t}$|\\.component\\s\\.lib-comp-group`;
    }
    if (
      ['artboard', 'table', 'icon', 'mockplus_rp', 'lib-comp-content-panel', 'lib-comp-content-panel-v2'].includes(t)
    ) {
      return `^\\.${t}$`;
    }
    if (['lib-comp-image', 'lib-comp-text', 'lib-comp-keyboard', 'component-group', 'date-picker'].includes(t)) {
      return `^\\.${t}$|^\\.${t}[^-]*?[\\s\\.]`;
    }
    if (['hidden-layer', 'preview-device', 'preview-device-shell'].includes(t)) {
      return `\\.${t}$`;
    }
    return `^(div|svg)?\\.${t}`;
  });

  names.push('^ul$');
  names.push('^li$');
  names.push('^body$');
  return getStyleByNames(names);
};

export const getClassNameFromStyle = (html: string) => {
  // 样式名称
  const list: string[] = ['(\\.[^<{}]*?){'];
  const doMatchString = (str: string): string[] => {
    return [str.trim()];
  };
  const set = searchByReg(html, list, doMatchString);
  return Array.from(set);
};
