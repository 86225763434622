import { EPSILON, max, min, sameNumber, round as globalRoundFunc } from './globalUtils';

type MoveDelta = {
  readonly x: number;
  readonly y: number;
};

type IPosition = {
  left: number;
  top: number;
};

interface IBounds {
  left: number;
  top: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
}

interface IPoint {
  x: number;
  y: number;
}

interface IBoundsOffset {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

/**
 * 把点转换成坐标
 * @param {IPoint} point
 * @returns {IPosition}
 */
export function transPointToPosition(point: IPoint): IPosition {
  return {
    left: point.x,
    top: point.y,
  };
}

/**
 * 把坐标转换成点
 * @param {IPosition} position
 * @returns {IPoint}
 */
export function transPositionToPoint(position: IPosition): IPoint {
  return { x: position.left, y: position.top };
}

/**
 * 比较两个坐标点是否相同
 * @param {IPosition} a
 * @param {IPosition} b
 * @param num
 * @returns {boolean}
 */
export function isEqualPosition(a: IPosition, b: IPosition, num?: number): boolean {
  return sameNumber(a.top, b.top, num) && sameNumber(a.left, b.left, num);
}

/**
 * 比较两个点是否相同
 * @param {IPoint} a
 * @param {IPoint} b
 * @param num
 * @returns {boolean}
 */
export function isEqualPoint(a: IPoint, b: IPoint, num?: number): boolean {
  return isEqualPosition(transPointToPosition(a), transPointToPosition(b), num);
}

/**
 * 比较两组坐标点是否完全相等，相同长度，相同索引位置的点都相等时才相等
 * @param {IPoint[]} a
 * @param {IPoint[]} b
 * @return {boolean}
 */
export function isEqualPoints(a: IPoint[], b: IPoint[]): boolean {
  if (a.length !== b.length) {
    return false;
  }
  return a.every((pt, i) => {
    return isEqualPoint(pt, b[i]);
  });
}

// 注意这个 ISize 和组件的 ISize 不一样
export type ISize = {
  width: number;
  height: number;
};

/**
 * 比较两个尺寸是否相等
 * @param {ISize} a
 * @param {ISize} b
 * @returns {boolean}
 */
export function isEqualSize(a: ISize, b: ISize): boolean {
  return sameNumber(a.width, b.width) && sameNumber(a.height, b.height);
}

/**
 * 获取一个区域的左上角坐标
 * @param {IBounds} bounds
 * @returns {IPosition}
 */
export function topLeft(bounds: IBounds): IPosition {
  return {
    left: bounds.left,
    top: bounds.top,
  };
}

/**
 * 获取一个区域的右下角坐标
 * @param {IBounds} bounds
 * @returns {IPosition}
 */
export function bottomRight(bounds: IBounds): IPosition {
  return {
    left: bounds.right,
    top: bounds.bottom,
  };
}

/**
 * 获取一个区域的右上角坐标
 * @param {IBounds} bounds
 * @returns {IPosition}
 */
export function topRight(bounds: IBounds): IPosition {
  return {
    left: bounds.right,
    top: bounds.top,
  };
}

/**
 * 获取一个区域的左下角坐标
 * @param {IBounds} bounds
 * @returns {IPosition}
 */
export function bottomLeft(bounds: IBounds): IPosition {
  return {
    left: bounds.left,
    top: bounds.bottom,
  };
}

/**
 * 获取一个区域的中心点，已取整
 * @param {IBounds} bounds
 * @returns {IPosition}
 */
export function center(bounds: IBounds): IPosition {
  const { left, top, width, height } = bounds;
  const w = globalRoundFunc(width / 2);
  const h = globalRoundFunc(height / 2);
  return {
    left: left + w,
    top: top + h,
  };
}

export function isEmpty(bounds: IBounds): boolean {
  return bounds.height === 0 || bounds.width === 0;
}

/**
 * 区域中是否包含某点
 * @param {IBounds} bounds
 * @param {number} left
 * @param {number} top
 * @param {boolean} coincide 是否认为重合也包含在内
 * @returns {boolean}
 */
export function isContainerPoint(bounds: IBounds, { left, top }: IPosition, coincide?: boolean): boolean {
  // 这里要考虑浮点数的误差，否则可能判断不准确
  const l = bounds.left - EPSILON;
  const t = bounds.top - EPSILON;
  const r = bounds.right + EPSILON;
  const b = bounds.bottom + EPSILON;
  if (!coincide) {
    return l < left && r > left && t < top && b > top;
  } else {
    return l <= left && r >= left && t <= top && b >= top;
  }
}

/**
 * 多个区域是否存在包含的点
 */
export function isContainerPoint2(point: IPosition, coincide: boolean, ...boundsArr: IBounds[]): boolean {
  return boundsArr.some((bounds) => isContainerPoint(bounds, point, coincide));
}

/**
 * 两个区域是否相交
 * @param {IBounds} bounds
 * @param {IBounds} target
 * @returns {boolean}
 */
export function isIntersect(bounds: IBounds, target: IBounds): boolean {
  if (isEmpty(target)) {
    return isContainerPoint(bounds, topLeft(target)) || isContainerPoint(bounds, bottomRight(target));
  }

  if (isEmpty(bounds)) {
    return isContainerPoint(target, topLeft(bounds)) || isContainerPoint(target, bottomRight(bounds));
  }

  const { left: l1, top: t1, right: r1, bottom: b1 } = bounds;
  const { left: l2, top: t2, right: r2, bottom: b2 } = target;

  const left = Math.max(l1, l2);
  const top = Math.max(t1, t2);
  const right = Math.min(r1, r2);
  const bottom = Math.min(b1, b2);
  return right - left > 0 && bottom - top > 0;
}

function crossLine(line1: { start: IPoint; end: IPoint }, line2: { start: IPoint; end: IPoint }) {
  // 判读线段（a, b）和线段（c, d）是否相交
  const { start: a, end: b } = line1;
  const { start: c, end: d } = line2;
  const u = (c.x - a.x) * (b.y - a.y) - (b.x - a.x) * (c.y - a.y);
  const v = (d.x - a.x) * (b.y - a.y) - (b.x - a.x) * (d.y - a.y);
  const w = (a.x - c.x) * (d.y - c.y) - (d.x - c.x) * (a.y - c.y);
  const z = (b.x - c.x) * (d.y - c.y) - (d.x - c.x) * (b.y - c.y);
  return u * v < 0 && w * z < 0;
}

export function isIntersetLine(bounds: IBounds, line: { start: IPoint; end: IPoint }) {
  const tl = transPositionToPoint(topLeft(bounds));
  const tr = transPositionToPoint(topRight(bounds));
  const bl = transPositionToPoint(bottomLeft(bounds));
  const br = transPositionToPoint(bottomRight(bounds));
  const start = transPointToPosition(line.start);
  const end = transPointToPosition(line.end);
  let intersect = isContainerPoint(bounds, start) || isContainerPoint(bounds, end);
  if (!intersect) {
    intersect = crossLine(line, { start: tl, end: br }) || crossLine(line, { start: tr, end: bl });
  }
  return intersect;
}

/**
 * 是否包含另一个区域
 * @param {IBounds} bounds
 * @param {IBounds} target
 * @param {boolean} coincide 是否认为点重合的也包含在内
 * @returns {boolean}
 */
export function isContainer(bounds: IBounds, target: IBounds, coincide?: boolean): boolean {
  return (
    isContainerPoint(bounds, topLeft(target), coincide) &&
    isContainerPoint(bounds, topRight(target), coincide) &&
    isContainerPoint(bounds, bottomLeft(target), coincide) &&
    isContainerPoint(bounds, bottomRight(target), coincide)
  );
}

/**
 * 偏移bounds，将偏移后的bounds返回
 * @param {IBounds} bounds
 * @param {IPosition} offset
 * @returns {IBounds}
 */
export function offsetBounds(bounds: IBounds, offset: IPosition): IBounds {
  const { left, top } = offset;
  return {
    ...bounds,
    left: bounds.left + left,
    top: bounds.top + top,
    right: bounds.right + left,
    bottom: bounds.bottom + top,
  };
}

/**
 * 在各个方向上等距离扩张区域
 * @param {IBounds} bounds
 * @param {IPosition} offset
 * @returns {IBounds}
 */
export function inflate(bounds: IBounds, offset: IPosition): IBounds {
  let { left, top, right, bottom, width, height } = bounds;
  return {
    left: left - offset.left,
    top: top - offset.top,
    right: right + offset.left,
    bottom: bottom + offset.top,
    width: width + offset.left * 2,
    height: height + offset.top * 2,
  };
}

/**
 * 在各个方向上扩展区域，会修改源对象
 * @param {IBounds} bounds
 * @param {IBoundsOffset} diff
 * @returns {IBounds}
 */
export function inflateSide(bounds: IBounds, diff: IBoundsOffset): IBounds {
  let { left, top, right, bottom } = bounds;

  bounds.left = left - diff.left;
  bounds.top = top - diff.top;
  bounds.right = right + diff.right;
  bounds.bottom = bottom + diff.bottom;
  bounds.width = bounds.right - bounds.left;
  bounds.height = bounds.bottom - bounds.top;

  return { ...bounds };
}

/**
 * 缩放一个区域
 * @param {IBounds} bounds
 * @param {number} scale
 * @returns {IBounds}
 */
export function scale(bounds: IBounds, scale: number): IBounds {
  const { left, top, right, bottom, width, height } = bounds;
  return {
    left: left * scale,
    top: top * scale,
    right: right * scale,
    bottom: bottom * scale,
    width: width * scale,
    height: height * scale,
  };
}

/**
 * 分别沿X方向、Y方向缩放一个区域
 * @param bounds
 * @param scaleX
 * @param scaleY
 */
export function scaleXY(bounds: IBounds, scaleX: number, scaleY: number): IBounds {
  const { left, top, right, bottom, width, height } = bounds;
  return {
    left: left * scaleX,
    right: right * scaleX,
    width: width * scaleX,
    top: top * scaleY,
    bottom: bottom * scaleY,
    height: height * scaleY,
  };
}

/**
 * 对一个区域各个坐标点进行取整
 * @param {IBounds} bounds
 * @returns {IBounds}
 */
export function round(bounds: IBounds): IBounds {
  let { left, top, right, bottom } = bounds;
  left = globalRoundFunc(left);
  top = globalRoundFunc(top);
  right = globalRoundFunc(right);
  bottom = globalRoundFunc(bottom);
  const width = right - left;
  const height = bottom - top;
  return {
    left,
    top,
    right,
    bottom,
    width,
    height,
  };
}

/**
 * 设置到新的坐标
 * @param {IBounds} bounds
 * @param {IPosition} position
 * @returns {IBounds}
 */
export function setLocation(bounds: IBounds, position: IPosition): IBounds {
  const offset: IPosition = {
    left: position.left - bounds.left,
    top: position.top - bounds.top,
  };
  return offsetBounds(bounds, offset);
}

/**
 * 根据左上、右下两点初始化一个区域对象
 * @param {IPosition} topLeft
 * @param {IPosition} bottomRight
 * @returns {IBounds}
 */
export function init(topLeft: IPosition, bottomRight: IPosition): IBounds {
  const width = bottomRight.left - topLeft.left;
  const height = bottomRight.top - topLeft.top;
  return {
    ...topLeft,
    width,
    height,
    bottom: bottomRight.top,
    right: bottomRight.left,
  };
}

/**
 * 根据传入对象构建一个新的Bounds对象
 * @param {IBounds | IBounds | ClientRect} bounds
 * @returns {IBounds}
 */
export function create(bounds: IBounds | IBounds | ClientRect): IBounds {
  return init(topLeft(bounds), bottomRight(bounds));
}

export function initByRect(left: number, top: number, right: number, bottom: number) {
  return init({ left, top }, { left: right, top: bottom });
}

export function getPointsBounds(points: IPoint[]) {
  let left = Number.POSITIVE_INFINITY;
  let top = Number.POSITIVE_INFINITY;
  let right = Number.NEGATIVE_INFINITY;
  let bottom = Number.NEGATIVE_INFINITY;
  points.forEach((pt) => {
    left = min(left, pt.x);
    top = min(top, pt.y);
    right = max(right, pt.x);
    bottom = max(bottom, pt.y);
  });
  return initByRect(left, top, right, bottom);
}

/**
 *
 * @param position 组件的坐标信息
 * @param size 组件的大小
 */
export function initBoundsWithPositionAndSize(position: { x: number; y: number }, size: ISize) {
  const { height, width } = size;
  return init(transPointToPosition(position), transPointToPosition({ x: position.x + width, y: position.y + height }));
}

/**
 * 联合区域
 * @param {IBounds} bounds
 * @param {IBounds[]} bounds
 * @returns {IBounds}
 */
export function union(...bounds: IBounds[]): IBounds {
  if (!bounds.length) {
    return empty();
  }
  if (bounds.length === 1) {
    return bounds[0];
  }
  const boundsClone = [...bounds];
  const first = boundsClone.shift()!;
  let { left, top, right, bottom } = first;
  boundsClone.forEach((item) => {
    left = Math.min(left, item.left);
    top = Math.min(top, item.top);
    right = Math.max(right, item.right);
    bottom = Math.max(bottom, item.bottom);
  });
  return init({ top, left }, { left: right, top: bottom });
}

/**
 * 获取多个区域相交后的区域
 * @param {IBounds} bounds
 * @returns {IBounds}
 */
export function intersect(...bounds: IBounds[]): IBounds {
  if (!bounds.length) {
    return empty();
  }
  if (bounds.length === 1) {
    return bounds[0];
  }
  const backup = [...bounds];
  const first = backup.shift()!;
  let { left, top, right, bottom } = first;
  backup.forEach((item) => {
    left = max(left, item.left);
    top = max(top, item.top);
    right = min(right, item.right);
    bottom = min(bottom, item.bottom);
  });
  if (right <= left || bottom <= top) {
    return empty();
  }
  return init({ left, top }, { left: right, top: bottom });
}

/**
 * 获取一个区域的尺寸
 * @param {IBounds} bounds
 * @returns {number}
 */
export function size(bounds: IBounds): number {
  const { width, height } = bounds;
  return Math.hypot(width, height);
}

export function getBoundsWithPoints(points: IPoint[], needRoundedSize?: boolean): IBounds {
  const initBounds = {
    left: Number.POSITIVE_INFINITY,
    top: Number.POSITIVE_INFINITY,
    right: Number.NEGATIVE_INFINITY,
    bottom: Number.NEGATIVE_INFINITY,
  };
  const { left, top, right, bottom } = points.reduce(
    (acc, curr) => {
      acc.top = min(acc.top, curr.y);
      acc.left = min(acc.left, curr.x);
      acc.right = max(acc.right, curr.x);
      acc.bottom = max(acc.bottom, curr.y);
      return acc;
    },
    { ...initBounds },
  );
  return {
    left,
    top,
    right,
    bottom,
    width: needRoundedSize ? Math.round(right - left) : right - left,
    height: needRoundedSize ? Math.round(bottom - top) : bottom - top,
  };
}

/**
 * 将一个点的坐标进行四舍五入
 * @param point
 */
export function getRoundedPositionOfPoint(point: { x: number; y: number }) {
  // 这里保留0.5精度处理路径
  return {
    x: globalRoundFunc(point.x * 2) / 2,
    y: globalRoundFunc(point.y * 2) / 2,
  };
}

/**
 * 获取一个空的区域对象
 * @returns {IBounds}
 */
export function empty(): IBounds {
  return {
    left: 0,
    top: 0,
    right: 0,
    width: 0,
    height: 0,
    bottom: 0,
  };
}

/**
 * 判断两个区域是否完全重合
 * @param {IBounds} reference
 * @param {IBounds} target
 * @returns {boolean}
 */
export function isEqualBounds(reference: IBounds, target: IBounds): boolean {
  return (
    reference.left === target.left &&
    reference.top === target.top &&
    reference.width === target.width &&
    reference.height === target.height
  );
}

/**
 * 获取两个区域各个方向上的差值
 * @param {IBounds} oldBounds
 * @param {IBounds} newBounds
 * @returns {IBoundsOffset}
 */
export function getBoundsOffset(oldBounds: IBounds, newBounds: IBounds): IBoundsOffset {
  return {
    left: globalRoundFunc(oldBounds.left - newBounds.left),
    top: globalRoundFunc(oldBounds.top - newBounds.top),
    right: globalRoundFunc(newBounds.right - oldBounds.right),
    bottom: globalRoundFunc(newBounds.bottom - oldBounds.bottom),
  };
}

/**
 * 把一个偏移量转换为各个方向上的偏移量
 * @param {MoveDelta} delta
 * @returns {IBoundsOffset}
 */
export function tranDeltaToOffset(delta: MoveDelta): IBoundsOffset {
  return { left: -delta.x, right: delta.x, top: -delta.y, bottom: delta.y };
}

export function offsetPoint(pt: IPoint, offset: IPoint): IPoint {
  return {
    x: pt.x + offset.x,
    y: pt.y + offset.y,
  };
}

export function roundPoint(pt: IPoint): IPoint {
  return {
    x: globalRoundFunc(pt.x),
    y: globalRoundFunc(pt.y),
  };
}

/**
 * 获取一点相对于另一点的夹角
 * @param {IPoint} pt
 * @param {IPoint} center
 * @returns {number}
 */
export function getPointAngle(pt: IPoint, center: IPoint) {
  // 直角的边长
  const { x, y } = pt;
  const { x: cx, y: cy } = center;
  let angle = 0;
  if (x === cx) {
    if (y > cy) {
      angle = 90;
    } else {
      angle = 270;
    }
  } else if (y === cy) {
    if (x < cx) {
      angle = 180;
    } else {
      angle = 0;
    }
  } else {
    // 二象限
    angle = Math.atan((y - cy) / (x - cx)) * (180 / Math.PI);
    // 一象限
    if (x > cx && y < cy) {
      angle += 360;
    }
    // 三象限
    else if (x < cx && y > cy) {
      angle = 180 + angle;
    }
    // 四象限
    else if (x < cx && y < cy) {
      angle += 180;
    }
  }
  return angle % 360;
}

/**
 * 以指定中心旋转点
 * @param {IPoint} pt
 * @param {IPoint} center
 * @param {number} rotate
 * @returns {{x: number, y: number}}
 */
export function rotatePoint(pt: IPoint, center: IPoint, rotate: number) {
  const len = Math.hypot(pt.x - center.x, pt.y - center.y);
  let angle = (getPointAngle(pt, center) + rotate) % 360;
  if (angle < 0) {
    angle += 360;
  }
  return {
    x: center.x + len * Math.cos((angle * Math.PI) / 180),
    y: center.y + len * Math.sin((angle * Math.PI) / 180),
  };
}

/**
 * 旋转bounds获取新的
 */
export function rotate(bounds: IBounds, center: IPoint, rotate: number) {
  const points = boundsToPoints(bounds);
  const newPoints = points.map((p) => rotatePoint(p, center, rotate));
  return getBoundsWithPoints(newPoints);
}

// 通过区域大小创建 bounds
export function createBoundsBySize(size: ISize): IBounds {
  return {
    left: 0,
    top: 0,
    bottom: size.height,
    right: size.width,
    width: size.width,
    height: size.height,
  };
}

/**
 * bounds转四点坐标
 */
export function boundsToPoints(bounds: IBounds): IPoint[] {
  const { left, width, height, top } = bounds;
  return [
    { x: left, y: top },
    { x: left + width, y: top },
    { x: left + width, y: top + height },
    { x: left, y: top + height },
  ];
}

// bounds 的 九个点(左上角顺时针的8个点加中心点)
export function boundsToNinePoints(bounds: IBounds): IPoint[] {
  const { left, top, right, bottom, width, height } = bounds;
  const halfWidth = globalRoundFunc(width / 2);
  const halfHeight = globalRoundFunc(height / 2);
  return [
    { x: left, y: top },
    { x: left + halfWidth, y: top },
    { x: right, y: top },
    { x: right, y: top + halfHeight },
    { x: right, y: bottom },
    { x: left + halfWidth, y: bottom },
    { x: left, y: bottom },
    { x: left, y: top + halfHeight },
    { x: left + halfWidth, y: top + halfHeight },
  ];
}

export function toFixedPoint(point: IPoint, digit: number): IPoint {
  return {
    x: Number(point.x.toFixed(digit)),
    y: Number(point.y.toFixed(digit)),
  };
}
