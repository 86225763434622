import * as React from 'react';
import { isUndefined } from 'lodash';
import { UIComponent, UIContainerComponent } from '@editor/comps/index';
import { IPathValue, IComponentValue } from '@fbs/rp/models/value';
import {
  transformPathDataToPath,
  scalePath,
  isClosedPathWithArea,
  getNewPathPropertiesByScalingTheStrokeAndShadow,
} from '@helpers/pathHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { depthClone, isEqualDate } from '@utils/globalUtils';
import { IComponentSize } from '@/fbs/rp/models/component';
import { IProperties } from '@/fbs/rp/models/property';
import { StrokePosition } from '@/fbs/rp/models/properties/stroke';

import { IComponentProps } from '../../types';
import { CCompoundPath } from '../../constants';
import ShapeTextBase, { IShapeTextCompState } from '../common/ShapeTextBase';
import { renderClipFill } from '../common/GradientFragment';
import { renderMaskStroke } from '../common/SvgStrokeFragment';

interface ICompoundPath {
  comp: UIComponent;
  isContainerActiveContainer?: Boolean;
}

class CompoundPath extends ShapeTextBase<IComponentProps & ICompoundPath, IShapeTextCompState> {
  private size?: IComponentSize;
  private value?: IComponentValue;
  private text?: string;
  private properties?: IProperties;
  private opacity?: number;
  private transition?: string;
  private version: string;

  get pathData() {
    const value = this.props.comp.value as IPathValue[];
    const globalScale = this.props.globalScale || 1;
    return this.getPathData(value, globalScale);
  }

  constructor(props: IComponentProps & ICompoundPath) {
    super(props);
    this.state = {
      textStyle: {
        ...this.parserTextStyle(props),
        textAlign: 'center',
      },
    };
    const { size, value, text, properties, opacity, version } = props.comp;
    this.size = size;
    this.value = value as IPathValue[];
    this.text = text;
    this.properties = properties;
    this.opacity = opacity;
    this.transition = props.comp.getTransition();
    this.version = version;
  }

  UNSAFE_componentWillReceiveProps(nextProps: ICompoundPath) {
    this.setState({
      textStyle: {
        ...this.parserTextStyle(nextProps),
        textAlign: 'center',
      },
    });
  }

  shouldComponentUpdate(nextProps: IComponentProps & ICompoundPath, nextState: IShapeTextCompState) {
    if (this.version !== nextProps.comp.version) {
      this.version = nextProps.comp.version;
      return true;
    }
    if (this.props.selected !== nextProps.selected) {
      return true;
    }
    if (this.props.isPreview !== nextProps.isPreview) {
      return true;
    }
    if (!isEqualDate(this.size, nextProps.comp.size)) {
      this.size = depthClone(nextProps.comp.size);
      return true;
    }
    if (!isEqualDate(this.transition, nextProps.comp.getTransition())) {
      this.transition = nextProps.comp.getTransition();
      return true;
    }
    const compValue = nextProps.comp.value;
    if (!isEqualDate(this.value, compValue) || this.props.globalScale !== nextProps.globalScale) {
      this.value = depthClone(compValue) as IPathValue[];
      return true;
    }
    const compText = nextProps.comp.text;
    if (!isEqualDate(this.text, compText)) {
      this.text = compText;
      return true;
    }
    const compOpacity = nextProps.comp.opacity;
    if (!isEqualDate(this.opacity, compOpacity)) {
      this.opacity = compOpacity;
      return true;
    }
    if (!isEqualDate(this.state.textStyle, nextState.textStyle)) {
      return true;
    }
    const compProperties = nextProps.comp.properties;
    if (!isEqualDate(this.properties, compProperties)) {
      this.properties = depthClone(compProperties);
      return true;
    }
    if (!!this.props.isContainerActiveContainer !== !!nextProps.isContainerActiveContainer) {
      return true;
    }
    return false;
  }

  getPathData = (value: IPathValue[], globalScale: number) => {
    let data = '';
    value.forEach((pathValue) => {
      data += transformPathDataToPath(scalePath(pathValue, { x: globalScale, y: globalScale })) + ' ';
    });
    return data;
  };

  get newProperties() {
    const globalScale = this.props.globalScale || 1;
    return getNewPathPropertiesByScalingTheStrokeAndShadow(this.props.comp.properties, globalScale);
  }

  get strokeOpt() {
    const { comp } = this.props;
    const globalScale = this.props.globalScale || 1;
    const group = comp as UIContainerComponent;
    const { size, id } = comp;

    const properties = this.newProperties;

    const parser = StyleHelper.initSVGStyleParser(properties);
    const { stroke, strokeDasharray, strokeWidth, strokeLinecap, strokeLinejoin } = parser.getStroke();
    const compData = depthClone(group.toJSON());
    compData.value = group.dynamicInfo?.value || compData.value;
    const data = this.pathData || '';
    const transition = comp.getTransition();
    const isChildOfPath = this.isChildOfPath;

    const forceCenter = !!(comp.value as IPathValue[]).find((v) => !isClosedPathWithArea(v));

    return {
      id,
      size,
      scale: globalScale,
      stroke: {
        stroke: isChildOfPath ? '#ccc' : stroke,
        strokeWidth: isChildOfPath ? 1 : strokeWidth,
        strokeDasharray: isChildOfPath ? undefined : strokeDasharray,
        strokeLinecap: strokeLinecap,
        strokeLinejoin: strokeLinejoin,
      },
      data,
      strokePosition:
        isChildOfPath || forceCenter ? StrokePosition.center : properties.stroke?.position || StrokePosition.center,
      transition,
    };
  }

  get fillOpt() {
    const { comp } = this.props;
    const globalScale = this.props.globalScale || 1;
    const group = comp as UIContainerComponent;
    const { size, id, type } = comp;

    const properties = this.newProperties;

    const compData = depthClone(group.toJSON());
    compData.value = group.dynamicInfo?.value || compData.value;

    const transition = comp.getTransition();
    return {
      id,
      type,
      size,
      fill: this.isChildOfPath ? undefined : properties.fill,
      transition,
      scale: globalScale,
    };
  }

  get isChildOfPath() {
    return this.props.comp.parent?.type === CCompoundPath;
  }

  render() {
    const data = this.pathData || '';
    const { comp, isPreview } = this.props;
    const globalScale = this.props.globalScale || 1;
    const group = comp as UIContainerComponent;
    const { id, text, type, size } = comp;

    const properties = this.newProperties;

    const parser = StyleHelper.initSVGStyleParser(properties);
    const fill = parser.getFill(`${type}-fill-${id}`);
    const filter = parser.getShadow();
    const compData = depthClone(group.toJSON());
    compData.value = group.dynamicInfo?.value || compData.value;

    const transition = comp.getTransition();
    const isChildOfPath = this.isChildOfPath;
    const opacity = isUndefined(comp.opacity) ? 1 : comp.opacity / 100;

    const fillOpt = this.fillOpt;
    const strokeOpt = this.strokeOpt;

    const fillStyle = {
      transition,
    };
    const svgStyle: React.CSSProperties = {
      // svg感应区域过大，屏蔽鼠标感应
      pointerEvents: 'none',
      filter,
      overflow: 'visible',
      top: 0,
      left: 0,
      position: 'absolute',
      opacity,
      transition,
      transform: `scale(${1 / globalScale})`,
      transformOrigin: 'left top',
    };

    if (isPreview) {
      Object.assign(svgStyle, {
        width: size.width || 1,
        height: size.height || 1,
      });
    }

    return (
      <div className="lib-comp-compound-path" style={{ ...size }}>
        <svg style={svgStyle}>
          {renderClipFill(
            fillOpt,
            <path d={data} fill={isChildOfPath ? 'transparent' : fill} strokeWidth={0} style={fillStyle} />,
          )}
          {renderMaskStroke(strokeOpt)}
          <path d={data} fill="none" strokeWidth={8} stroke="transparent" />
        </svg>
        {!isChildOfPath && this.renderTextFragment(text, properties, opacity)}
      </div>
    );
  }
}

export default CompoundPath;
