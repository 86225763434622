import IFill from '@/fbs/rp/models/properties/fill';
import { IColorStop } from '@/utils/graphicsUtils';

export const MAX_FAVORITE_COLOR_COUNT = 23;

export interface IColorPanel {
  readonly color: IFill;
  readonly selectedIndex: number;
  selectedColorStop(index: number): void;
  colorStopChanged(newColorStops: IColorStop[], selectIndex: number): void;
  colorStopChanging(newColorStops: IColorStop[]): void;
  moveLinePoint(color: IFill, isChanging?: boolean): void;
  removeColorStop(): void;

  exitGradient(): void;
}

class ColorPanelManager {
  currentColorPanel?: IColorPanel;
  currentProperty?: { key: string; value: IFill };
}

const colorPanelManager = new ColorPanelManager();

export default colorPanelManager;
