import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

export interface IButtonGroupProps {
  className?: string;
  children?: any | string;
  theme?: 'light' | 'normal';
}

/**
 * children为全部<Button>或者全部<IconButtom>
 */
const ButtonGroup: React.FC<IButtonGroupProps> = (props: IButtonGroupProps) => {
  const { children, className, theme } = props;
  return <div className={classnames('dsm-c-rp-button-group', className, { light: theme === 'light' })}>{children}</div>;
};

export default React.memo(ButtonGroup);
