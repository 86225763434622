import { IRPFreeProgramInvitationRecord } from '@/dsm2/models/rpFreeProgram';
import { IPopularizeResult } from '@/fbs/teamManagement/utils/popularize';
import { get, put } from './helper';

/**
 * rp免费计划领取进度
 * @param teamID
 */
export async function getRPActivityProgress(
  teamID: string,
): Promise<{ progress: number; receivedRP: boolean; isActivityNew: boolean }> {
  return get(`/rpDesignActivity/progress/${teamID}`);
}

/**
 * rp免费计划领取第一年
 * @param teamID
 */
export async function upgradeActivity(teamID: string): Promise<any> {
  return get(`/rpDesignActivity/upgrade/${teamID}`);
}

/**
 * rp免费计划发送邮箱邀请
 * @param emails
 * @param teamID
 */
export async function sendEmailInvitation(emails: string[], token: string, teamID: string) {
  return put(`/rpDesignActivity/send/email/${teamID}`, { emails, token });
}

/**
 * rp免费计划查看所有邀请记录
 * @param teamID
 */
export async function viewAllInvitation(teamID: string): Promise<{ allInvitation: IRPFreeProgramInvitationRecord[] }> {
  return get(`/rpDesignActivity/allInvitation/${teamID}`);
}

/**
 * 获取邀请链接
 * @param teamID
 * @param role
 */
export async function getInvitationLink(teamID: string, role: string): Promise<{ invitationID: string; role: string }> {
  return get(`/team/${teamID}/invitationLink?role=${role}`);
}

/**
 * 获取rp免费计划邀请链接
 * @param teamID
 */
export async function getRPFreeInvitationLink(teamID: string): Promise<{ invitationID: string; role: string }> {
  return get(`/rpDesignActivity/invitationLink/${teamID}`);
}

/**
 * 获取团队rp免费计划的客服二维码
 * @param teamID
 */
export async function getCustomerServiceQR(teamID: string): Promise<any> {
  return get(`/rpDesignActivity/QR_code/${teamID}`);
}
/**
 * 获取团队rp免费计划的客服二维码
 * @param teamID
 */
export async function setCustomerServiceQR(teamID: string, url: string): Promise<void> {
  return get(`/rpDesignActivity/QR_code/${teamID}`, { url });
}

/**
 * 已获取无限包的用户列表
 */
export async function getReceivedForeverRecord(): Promise<{
  list: { id: number; teamID: string; superAdminName: string }[];
}> {
  return get(`/rpDesignActivity/latelyRPDesignActivity`);
}

/**
 * 获取推广活动
 * @returns 推广活动列表
 */
export async function getPopularizes(): Promise<IPopularizeResult> {
  return get(`/advert`);
}
