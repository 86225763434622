import * as React from 'react';
import * as ReactDnD from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import * as dnd from '@consts/dnd';
import classnames from 'classnames';

import { transBlankChart } from '@/utils/textUtils';

import { DropMode, ITreeItem, isParentDrag } from '@/helpers/treeCompHelper';
import { UITreeItemComponent } from '@/editor/comps';
import { Radio, Icon, Input } from '@/dsm';
import { IconValue } from '@/fbs/rp/models/value';
import KeyCodeMap from '@/dsm2/constants/KeyCodeMap';

import IconRender from '../../withReplaceIcon/IconRender';

import './index.scss';

interface IMenuEditorItemProps {
  item: ITreeItem;
  level: number;
  itemComp: UITreeItemComponent;
  selected: boolean;
  autoStartEditor: boolean;
  draggable: boolean;
  showNodeIcon: boolean;
  checked?: boolean;
  nodeIconSelected?: boolean;
  onInteractionDragger: (e: React.MouseEvent, selectedID: string) => void;
  onEditNext: (itemComp: UITreeItemComponent, offset: number, canAdd?: boolean) => void;
  onChangeValue: (itemComp: UITreeItemComponent, text: string) => void;
  onExpandClick: (id: string) => void;
  onSelectClick: (id: string) => void;
  onFontIconClick: (e: React.MouseEvent, id: string) => void;
  onItemClick?: (selectedID: string) => void;
  onItemDoubleClick?: (selectedID: string) => void;
  onItemChecked?: (itemComp: UITreeItemComponent, checked: boolean) => void;
  onMouseEnter?: (id: string) => void;
}

interface IDragSourceProp {
  isDragging?: boolean;
  connectDragSource: ReactDnD.ConnectDragSource;
  connectDragPreview: ReactDnD.ConnectDragPreview;
}

interface IDropTargetProp {
  isOver?: boolean;
  connectDropTarget: ReactDnD.ConnectDropTarget;
}

interface IMenuItemProps extends IDragSourceProp, IDropTargetProp, IMenuEditorItemProps {
  onDropEnd?: (dragItemID: string, dropItemID: string, dropMode: DropMode) => void;
}

interface IMenuItemState {
  dropMode: DropMode;
  editing?: boolean;
  text?: string;
}

class MenuItem extends React.Component<IMenuItemProps, IMenuItemState> {
  dragDom: React.RefObject<HTMLLIElement> = React.createRef();

  constructor(props: IMenuItemProps) {
    super(props);
    this.state = {
      dropMode: DropMode.None,
      editing: props.autoStartEditor,
      text: props.itemComp.getText(),
    };
  }

  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage());
  }

  UNSAFE_componentWillReceiveProps(nextProps: IMenuItemProps) {
    if (nextProps.autoStartEditor && this.props.autoStartEditor !== nextProps.autoStartEditor) {
      this.setState({ editing: true, text: nextProps.itemComp.getText() });
    }
  }

  handleInteractionDraggerMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const { onInteractionDragger, item } = this.props;
    onInteractionDragger && onInteractionDragger(e, item.data.id);
  };

  handleStartEditing = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { onItemDoubleClick, itemComp } = this.props;
    onItemDoubleClick && onItemDoubleClick(itemComp.id);
  };

  handleInputEnter = (value: string) => {
    this.setState({ editing: false });
    if (value.trim()) {
      const { onEditNext, itemComp } = this.props;
      onEditNext(itemComp, 1, true);
    }
  };

  handleInputBlur = (value: string) => {
    const { onChangeValue, itemComp } = this.props;
    onChangeValue(itemComp, value);
    this.setState({ editing: false, text: undefined });
  };

  handleInputChange = (value: string) => {
    this.setState({ text: value });
  };

  handleInputKeyDown = (e: React.KeyboardEvent) => {
    const { onEditNext, itemComp } = this.props;
    if (e.keyCode === KeyCodeMap.VK_DOWN) {
      onEditNext(itemComp, 1);
    } else if (e.keyCode === KeyCodeMap.VK_UP) {
      onEditNext(itemComp, -1);
    }
  };

  handleExpandclick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { onExpandClick, item } = this.props;
    item.children?.length && onExpandClick(item.data.id);
  };

  handleSelectClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { onSelectClick, item } = this.props;
    onSelectClick(item.data.id);
  };

  renderText(text: string) {
    const { editing } = this.state;
    if (editing) {
      return (
        <Input
          autoFocus
          autoSelectWhenFocus
          submitWithBlur
          value={text}
          maxlength={100}
          onEnter={this.handleInputEnter}
          onBlur={this.handleInputBlur}
          onChange={this.handleInputChange}
          onKeyDown={this.handleInputKeyDown}
        />
      );
    } else {
      return (
        <label onDoubleClick={this.handleStartEditing} className="menu-node-text">
          {transBlankChart(text)}
        </label>
      );
    }
  }

  renderDragger() {
    const { editing } = this.state;
    if (editing) {
      return;
    }
    return <div className="interaction-dragger" onMouseDown={this.handleInteractionDraggerMouseDown} />;
  }
  renderMain() {
    const {
      itemComp,
      item,
      onItemClick,
      isOver,
      selected,
      level,
      showNodeIcon,
      nodeIconSelected,
      onFontIconClick,
      onMouseEnter,
    } = this.props;
    const { dropMode } = this.state;
    const text = itemComp.getText();
    const { icon } = itemComp.getNodeIcon();
    const listWidth = 165;
    const hasInteraction = Object.keys(itemComp.interactions).length > 0;
    return (
      <div
        className={classnames('menu-editor-drag-item', {
          [`menu-node-drop-at-${dropMode}`]: isOver && dropMode !== DropMode.None,
          interaction: !item.children?.length && hasInteraction,
        })}
        onClick={onItemClick?.bind(this, item.data.id)}
        onMouseEnter={onMouseEnter?.bind(this, item.data.id)}
      >
        <div className={classnames('list-menu-item', { selected })} style={{ maxWidth: listWidth - 16 * (level - 1) }}>
          <div className="item-icon" onClick={this.handleExpandclick}>
            {item.children?.length && <Icon size={16} cls={item.data.expand ? 'tag_downarrow' : 'Right'} />}
          </div>
          {showNodeIcon && !level && (
            <IconRender
              className={classnames('item-icon', 'list-menu-item-icon')}
              selected={!!nodeIconSelected}
              compId={item.data.id}
              iconValue={icon as IconValue}
              onFontIconClick={onFontIconClick}
            />
          )}
          {this.renderText(text)}
        </div>
      </div>
    );
  }

  render() {
    const { children, itemComp, selected, level, item, showNodeIcon } = this.props;
    const { connectDragSource, connectDropTarget } = this.props;
    const marginSpace = 8;
    const intention = 16;
    const indent = 32; //缩进
    const iconIndent = 40; //带margin的缩进
    let LeftIntention = intention;
    if (showNodeIcon && level === 1) {
      LeftIntention = iconIndent;
    }
    const paddingLeft = level === 0 ? indent : LeftIntention;
    const selectStyle = {
      left: level ? -level * intention - marginSpace : marginSpace,
    };
    if (showNodeIcon && level > 1) {
      selectStyle.left = -level * intention - indent;
    }
    let bgIntention = level ? level * intention + intention : 0;
    if (level > 1) {
      bgIntention = level * intention + iconIndent;
    }
    return connectDropTarget(
      connectDragSource(
        <li className="list-menu-li" ref={this.dragDom}>
          {!item.children?.length && (
            <>
              <div className="list-menu-select" style={selectStyle} onClick={this.handleSelectClick}>
                <Radio checked={!!itemComp.selected} />
              </div>
              <div className="list-menu-action">{this.renderDragger()}</div>
            </>
          )}
          <div
            className={classnames('list-menu-bg', { selected: selected })}
            style={{ marginLeft: -bgIntention, width: `calc(100% + ${bgIntention}px)` }}
          />
          <div style={{ paddingLeft }}>
            {this.renderMain()}
            <div className="menu-editor-children">
              <ul>{children}</ul>
            </div>
          </div>
        </li>,
      ),
    );
  }
}

interface DragObject {
  data: ITreeItem;
  type: Symbol;
}

interface DropResult {
  data: ITreeItem;
  dropMode: DropMode;
}

const dragSpec: ReactDnD.DragSourceSpec<IDragSourceProp, DragObject> = {
  // eslint-disable-next-line no-unused-vars
  canDrag: (props: IDragSourceProp, monitor: ReactDnD.DragSourceMonitor) => {
    return (props as IMenuItemProps).draggable;
  },
  // eslint-disable-next-line no-unused-vars
  beginDrag: (props: IDragSourceProp, monitor: ReactDnD.DragSourceMonitor) => {
    return { data: (props as IMenuItemProps).item, type: dnd.COMP_TREE_NODE };
  },
  endDrag: (props: IDragSourceProp, monitor: ReactDnD.DragSourceMonitor, component: MenuItem) => {
    if (!monitor.didDrop()) {
      return;
    }
    const dragItem: ITreeItem = monitor.getItem().data;
    if (!dragItem) {
      return;
    }
    const dropResult: DropResult = monitor.getDropResult();
    const { data: dropItem, dropMode } = dropResult;
    if (dragItem.data.id === dropItem.data.id) {
      return;
    }
    component.props.onDropEnd && component.props.onDropEnd(dragItem.data.id, dropItem.data.id, dropMode);
  },
};

const dropSpec: ReactDnD.DropTargetSpec<IDropTargetProp> = {
  canDrop: (props: IDropTargetProp, monitor: ReactDnD.DropTargetMonitor) => {
    const dragItem: ITreeItem = monitor.getItem().data;
    const dropItem: ITreeItem = (props as IMenuItemProps).item;
    // 子级不能作为放置点
    if (isParentDrag(dragItem, dropItem)) {
      return false;
    }
    if (dragItem.data.id === dropItem.data.id) {
      return false;
    }

    if (!monitor.isOver({ shallow: true })) {
      return false;
    }
    return true;
  },

  drop: (props: IDropTargetProp, monitor: ReactDnD.DropTargetMonitor, component: any) => {
    const dropItem: ITreeItem = (props as IMenuItemProps).item;
    const mode = component.decoratedRef.current.state.dropMode;
    return {
      data: dropItem,
      dropMode: mode,
    };
  },

  hover: (props: IDropTargetProp, monitor: ReactDnD.DropTargetMonitor, component: any) => {
    if (!monitor.canDrop()) {
      return;
    }

    if (!monitor.isOver()) {
      return;
    }

    const intention = 16;
    const dragDomBounds = component.dragDom.current?.getBoundingClientRect();
    const hoverOffset = monitor.getClientOffset();
    const siblingLeft = intention + 32;
    if (dragDomBounds && hoverOffset && hoverOffset.x > dragDomBounds.left + siblingLeft) {
      component.setState({ dropMode: DropMode.Child });
      return;
    }
    if (dragDomBounds && hoverOffset && hoverOffset.y < dragDomBounds.top + 30 / 2) {
      component.setState({ dropMode: DropMode.Before });
    } else {
      component.setState({ dropMode: DropMode.After });
    }
  },
};

function dragCollect(connect: ReactDnD.DragSourceConnector, monitor: ReactDnD.DragSourceMonitor): IDragSourceProp {
  return {
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
  };
}

function dropCollect(connect: ReactDnD.DropTargetConnector, monitor: ReactDnD.DropTargetMonitor): IDropTargetProp {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
  };
}

const DragTarget = ReactDnD.DragSource(dnd.COMP_TREE_NODE, dragSpec, dragCollect)(MenuItem);
export default ReactDnD.DropTarget([dnd.COMP_TREE_NODE], dropSpec, dropCollect)(DragTarget);
