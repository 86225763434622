import * as React from 'react';

import { max } from '@utils/globalUtils';
import { fullColorStops, parseColorToString, reviseRadialGradient } from '@utils/graphicsUtils';

import { Color, ILinearGradientColor, IRadialGradientColor } from '@fbs/rp/models/properties/color';
import IFill, { FillType } from '@fbs/rp/models/properties/fill';
import { ISize } from '@/fbs/common/models/common';
import { IPosition } from '@fbs/idoc/models/common';
import { getNewID } from '@/helpers/idHelper';

export const renderLinearGradient = (compID: string, color: ILinearGradientColor, size: ISize) => {
  const { colorStops, direction } = color;
  const { x1, x2, y1, y2 } = direction || { x1: 0, x2: 0, y1: 0, y2: 1 };
  const stops = fullColorStops(colorStops);
  const rectSize = max(size.width, size.height);
  if (!rectSize) return null;
  const scale = {
    x: size.width / rectSize,
    y: size.height / rectSize,
  };

  return (
    <defs>
      <linearGradient id={`${compID}`} x1={x1 * scale.x} x2={x2 * scale.x} y1={y1 * scale.y} y2={y2 * scale.y}>
        {stops
          .sort((a, b) => (a.point || 0) - (b.point || 0))
          .map((colorStop, index) => (
            <stop
              key={`${index}`}
              offset={`${colorStop.point || 0}%`}
              stopColor={parseColorToString(colorStop.color)}
            />
          ))}
      </linearGradient>
    </defs>
  );
};

export const renderRadialGradient = (compID: string, color: IRadialGradientColor, size: ISize) => {
  const { colorStops, to, from, angle, widthRatio } = color;
  const stops = fullColorStops(colorStops);
  const { x: fx, y: fy } = from || { x: 0.5, y: 0.5, r: 0 };
  const { x: cx, y: cy, r } = to || { x: 0.5, y: 0.5, r: 0.5 };
  const transform = `translate(${fx}, ${fy}),
                    scale(${size.height / size.width}, 1),
                    rotate(${angle || 0}),
                    scale(${widthRatio || 1}, 1),
                    translate(${-fx}, ${-fy})`;
  return (
    <defs>
      <radialGradient id={`${compID}`} fx={fx} fy={fy} cx={cx} cy={cy} r={r} gradientTransform={transform}>
        {stops
          .sort((a, b) => (a.point || 0) - (b.point || 0))
          .map((colorStop, index) => (
            <stop
              key={`${index}`}
              offset={`${colorStop.point || 0}%`}
              stopColor={parseColorToString(colorStop.color)}
            />
          ))}
      </radialGradient>
    </defs>
  );
};

export const renderGradient = (fill: IFill | undefined, fillID: string, size: ISize) => {
  if (fill) {
    const { type, color } = fill;
    if (type === FillType.linear) {
      return renderLinearGradient(fillID, color as ILinearGradientColor, size);
    } else if (type === FillType.radial) {
      return renderRadialGradient(fillID, color as IRadialGradientColor, size);
    }
  }
  return null;
};

/**
 * 路径，基元组件实现背景
 */
export const renderClipFill = (
  option: {
    id: string;
    type: string;
    size: ISize;
    fill: IFill | undefined;
    scale: number | undefined;
    transition?: string;
    transform?: string;
    offset?: IPosition;
  },
  path: JSX.Element,
) => {
  const { id, type, size, fill, scale, transition, transform, offset } = option;
  let fillDom;
  const fillID = `${type}-fill-${id}`;
  const fillUrl = `url(#${fillID})`;
  const clipID = `${type}-clip-path-${id}-${getNewID()}`;
  if (fill && fill.disabled) {
    return null;
  }

  switch (fill?.type) {
    case FillType.linear:
      fillDom = renderLinearGradientClipPath(
        path,
        fillUrl,
        clipID,
        size,
        fill.color as ILinearGradientColor,
        scale,
        transition,
        transform,
        offset,
      );
      break;
    case FillType.radial:
      fillDom = renderRadialGradientClipPath(
        path,
        fillUrl,
        clipID,
        size,
        fill.color as IRadialGradientColor,
        scale,
        transition,
        transform,
        offset,
      );
      break;
    case FillType.solid:
      fillDom = renderSolidColorClipPath(path, clipID, size, fill.color as Color, scale, transition, transform, offset);
      break;
    default:
      return null;
  }
  return (
    <>
      {renderGradient(fill, fillID, size)}
      {fillDom}
    </>
  );
};

const renderSolidColorClipPath = (
  path: JSX.Element,
  clipID: string,
  size: ISize,
  color: Color,
  scale = 1,
  transition?: string,
  transform?: string,
  offset?: IPosition,
) => {
  const pureColor = parseColorToString(color);
  return (
    <>
      <defs>
        <clipPath id={`${clipID}`}>{path}</clipPath>
      </defs>
      {/* 渐变部分及渐变之外颜色 */}
      <g
        style={{
          clipPath: `url(#${clipID})`,
          transform,
        }}
      >
        <rect
          x={offset?.x || 0}
          y={offset?.y || 0}
          width={size.width * scale}
          height={size.height * scale}
          fill={pureColor}
          style={{
            transition: transition,
            MozTransition: transition,
            WebkitTransition: transition,
          }}
        />
      </g>
    </>
  );
};

const renderLinearGradientClipPath = (
  path: JSX.Element,
  fillUrl: string,
  clipID: string,
  size: ISize,
  color: ILinearGradientColor,
  scale = 1,
  transition?: string,
  transform?: string,
  offset?: IPosition,
) => {
  // 线性渐变背景用正方形切割
  const rectSize = max(size.width, size.height);
  return (
    <>
      <defs>
        <clipPath id={`${clipID}`}>{path}</clipPath>
      </defs>
      {/* 渐变部分及渐变之外颜色 */}
      <g
        style={{
          clipPath: `url(#${clipID})`,
          transform,
        }}
      >
        <rect
          x={offset?.x || 0}
          y={offset?.y || 0}
          width={rectSize * scale}
          height={rectSize * scale}
          fill={fillUrl}
          style={{
            transition: transition,
            MozTransition: transition,
            WebkitTransition: transition,
          }}
        />
      </g>
    </>
  );
};

const renderRadialGradientClipPath = (
  path: JSX.Element,
  fillUrl: string,
  clipID: string,
  size: ISize,
  color: IRadialGradientColor,
  scale = 1,
  transition?: string,
  transform?: string,
  offset?: IPosition,
) => {
  const newColor = reviseRadialGradient(color, size);
  const { colorStops } = newColor;
  const stops = fullColorStops(colorStops);
  if (!stops.length) return null;
  return (
    <>
      <defs>
        <clipPath id={clipID}>{path}</clipPath>
      </defs>
      {/* 渐变部分及渐变之外颜色，  */}
      <g
        style={{
          clipPath: `url(#${clipID})`,
          transform,
        }}
      >
        <rect
          x={offset?.x || 0}
          y={offset?.y || 0}
          width={size.width * scale}
          height={size.height * scale}
          fill={fillUrl}
          style={{
            transition: transition,
            MozTransition: transition,
            WebkitTransition: transition,
          }}
        />
      </g>
    </>
  );
};
