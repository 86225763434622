import { UIComponent, UIContainerComponent, UIGroupComponent } from '.';
import { IComponentData, IComponentSize } from '@fbs/rp/models/component';
import { AddChildrenOperation, ArtboardPatches, Operation, Ops, PagePatches } from '@fbs/rp/utils/patch';
import {
  ComponentChange,
  ContainerPatches,
  getLineValueByZoom,
  updateUnChangedComponentsPatches,
} from '@editor/comps/resizeHelper';
import { CCompoundPath, CGroup, CPath, CRect, CEllipse, CPolygon, CLine } from '@libs/constants';
import { depthClone, isEqualDate, removeItemFromArray } from '@utils/globalUtils';
import { coverPatches, mergePatches } from '@helpers/patchHelper';
import { CompoundOperation } from '@fbs/rp/models/properties/path';
import { compoundPath, onPathValueZoom } from '@helpers/pathHelper';
import { IBounds, IPoint, IPosition } from '@fbs/common/models/common';
import { ILineValue, IPathValue } from '@fbs/rp/models/value';
import { getNewID } from '@helpers/idHelper';
import ZOrderModel from '@consts/enums/zOrderType';
import { mapVectorToTargetCoordinates } from '@helpers/componentHelper';
import { getCenter, getNWPoint } from '@helpers/rotateHelper';
import { getPositionPatchesWhenFlip, IFlipModel } from '@helpers/flipHelper';
import { getViewBoundsOfComponents } from '@editor/bounds';

export default class UICompoundPathComponent extends UIContainerComponent {
  // 删除一批组件
  removeComponents = (
    removingComponents: UIComponent[],
  ): { patches: PagePatches; newActiveGroup?: UIContainerComponent } => {
    let data: { patches: PagePatches; newActiveGroup?: UIContainerComponent };
    const parent = this.parent!;
    const isChildOfPath = this.parent?.type === CCompoundPath;
    const isChildOfGroup = this.parent?.type === CGroup;
    const restChildren = this.components.filter((comp) => {
      return !removingComponents.map((item) => item.id).includes(comp.id);
    });
    // 所有子组件都被删除，等同于从父中删除自己
    if (restChildren.length == 0) {
      const newComps = parent.components.map((comp) => depthClone(comp.toJSON()));
      removeItemFromArray(
        newComps,
        newComps.find((comp) => comp._id === this.id),
      );
      let patches: ArtboardPatches = { do: {}, undo: {} };
      // 当剩下的组件不足以构成复合组件时
      if (newComps.length > 1) {
        patches = this.removeSelfPatches();
      }
      let activeGroup: UIContainerComponent | undefined;
      if (isChildOfPath) {
        const { newActiveGroup } = (this.parent! as UICompoundPathComponent).refreshPatchesWithNewChildren(
          newComps,
          patches,
        );
        activeGroup = newActiveGroup;
      } else if (isChildOfGroup) {
        (this.parent! as UIGroupComponent).refreshPatchesWithNewChildren(newComps, patches);
        if (newComps.length === 1) {
          activeGroup = this.parent!.parent;
        }
      }
      data = {
        patches: {
          [this.ownerArtboardID]: patches,
        },
        newActiveGroup: activeGroup || this.parent,
      };
    }

    // 只剩下一个组件，需要解除 Group
    else if (restChildren.length === 1) {
      const lastComponent = restChildren[0];
      const lastComponentData = depthClone(lastComponent.toJSON());
      lastComponentData._id = getNewID();
      lastComponentData.position = lastComponent.getPositionWithoutParent();
      lastComponentData.rotate = (lastComponentData.rotate || 0) + this.rotate;

      const patches = this.removeSelfPatches();
      const addPatches: ArtboardPatches = {
        do: { [parent.id]: [Ops.addChildren(`${-1}`, [lastComponentData])] },
        undo: { [parent.id]: [Ops.removeChildren([lastComponentData._id])] },
      };
      mergePatches(patches, addPatches);
      if (isChildOfPath) {
        const newComps = this.parent!.components.map((item) => depthClone(item.toJSON()));
        removeItemFromArray(
          newComps,
          newComps.find((comp) => comp._id === this.id),
        );
        newComps.push(lastComponentData);
        (this.parent! as UICompoundPathComponent).refreshPatchesWithNewChildren(newComps, patches);
      } else if (isChildOfGroup) {
        const newComps = this.parent!.components.map((item) => depthClone(item.toJSON()));
        removeItemFromArray(
          newComps,
          newComps.find((comp) => comp._id === this.id),
        );
        newComps.push(lastComponentData);
        (this.parent! as UIGroupComponent).refreshPatchesWithNewChildren(newComps, patches);
      }
      data = {
        patches: {
          [this.ownerArtboardID]: patches,
        },
        newActiveGroup: this.parent,
      };
    } else {
      const removePatches: ArtboardPatches = {
        do: { [this.id]: [Ops.removeChildren(removingComponents.map((comp) => comp.id))] },
        undo: {
          [this.id]: [
            Ops.addChildren(
              `${restChildren.length}`,
              removingComponents.map((comp) => comp.toJSON()),
            ),
          ],
        },
      };
      const { patches: changePathes } = this.refreshPatchesWithNewChildren(restChildren.map((item) => item.toJSON()));
      data = {
        patches: {
          [this.ownerArtboardID]: mergePatches(removePatches, changePathes),
        },
        newActiveGroup: this,
      };
    }
    return data;
  };

  replaceComponents(
    newAddedComponents: IComponentData[],
    removingComponents: UIComponent[],
  ): {
    patches: PagePatches;
    newActiveGroup?: UIContainerComponent;
  } {
    const index = this.components.findIndex((c) => c.id === removingComponents[0]?.id);
    const patches = {
      do: {
        [this.id]: [
          Ops.removeChildren(removingComponents.map((comp) => comp.id)),
          Ops.addChildren(`${index}`, newAddedComponents),
        ],
      },
      undo: {
        [this.id]: [
          Ops.removeChildren(newAddedComponents.map((comp) => comp._id)),
          Ops.addChildren(
            `${index}`,
            removingComponents.map((comp) => comp.toJSON()),
          ),
        ],
      },
    };

    const newComps = this.components
      .filter((comp) => !removingComponents.some((c) => c.id === comp.id))
      .map((c) => c.toJSON())
      .concat(newAddedComponents);
    this.refreshPatchesWithNewChildren(newComps, patches);
    return {
      patches: {
        [this.ownerArtboardID]: patches,
      },
    };
  }

  /**
   * 修改z序
   * @param {string[]} componentIDs
   * @param {ZOrderModel} zOrderModel
   * @returns {(PagePatches | null)}
   * @memberof UICompoundPathComponent
   */
  doChangeZOrder(
    componentIDs: string[],
    zOrderModel: ZOrderModel,
  ): {
    patches: PagePatches | null;
    newContainer?: UIContainerComponent;
  } {
    const data = this.doChangeZOrderBefor(componentIDs, zOrderModel);
    if (!data) {
      return {
        patches: null,
      };
    }
    const { patches, comps } = data;
    const newComps = depthClone(comps);
    const { newActiveGroup, patches: newPatches } = this.refreshPatchesWithNewChildren(newComps);
    if (newActiveGroup && newActiveGroup.id !== this.id) {
      // 如果当前容器被删除了，那么久不合并patches
      patches.do = newPatches.do;
      patches.undo = newPatches.undo;
    } else {
      mergePatches(patches, newPatches);
    }
    return {
      patches: {
        [this.ownerArtboardID]: patches,
      },
      newContainer: newActiveGroup,
    };
  }

  /**
   * 当由于复合路径的子的变化影响父级的路径时，调用该方法对修改的patches补充（为实时resize的定制版，comp数据获取至）
   * @memberof UICompoundPathComponent
   */
  refreshPatchesWithNewChildrenBynamicInfo = (
    components: IComponentData[],
    patches: ArtboardPatches = {
      do: {},
      undo: {},
    },
  ): {
    patches: ArtboardPatches;
    newActiveGroup?: UIContainerComponent;
  } => {
    const parent = this.parent!;
    const isChildOfPath = this.parent?.type === CCompoundPath;
    const isChildOfGroup = this.parent?.type === CGroup;
    let newActiveGroup: UIContainerComponent | undefined;
    if (components.length > 1) {
      newActiveGroup = this;
    }
    if (components.length === 1) {
      const lastComponentData = depthClone(components[0]);
      lastComponentData._id = getNewID();
      lastComponentData.position = new UIComponent(lastComponentData, this).getPositionWithoutParent();
      lastComponentData.rotate = (lastComponentData.rotate || 0) + this.rotate;
      // lastComponentData.position = {
      //   x: lastComponentData.position.x + this.position.x,
      //   y: lastComponentData.position.y + this.position.y,
      // }
      const removePatches = this.removeSelfPatches();
      mergePatches(patches, removePatches);

      const addPatches: ArtboardPatches = {
        do: { [parent.id]: [Ops.addChildren(`${-1}`, [lastComponentData])] },
        undo: { [parent.id]: [Ops.removeChildren([lastComponentData._id])] },
      };
      mergePatches(patches, addPatches);

      if (isChildOfPath || isChildOfGroup) {
        const newComps = depthClone(
          this.parent!.components.map((comp) => {
            const compData = depthClone(comp.toJSON());
            // dynamicInfo
            compData.position = comp.position;
            compData.size = comp.size;
            compData.rotate = comp.rotate;
            compData.value = comp.value;
            return compData;
          }),
        );
        removeItemFromArray(
          newComps,
          newComps.find((comp) => comp._id === this.id),
        );
        newComps.push(lastComponentData);
        if (isChildOfPath) {
          const { newActiveGroup: activeGroup } = (this
            .parent! as UICompoundPathComponent).refreshPatchesWithNewChildrenBynamicInfo(newComps, patches);
          newActiveGroup = newActiveGroup || activeGroup;
        } else {
          (this.parent! as UIGroupComponent).refreshPatchesWithNewChildren(newComps, patches);
          if (newComps.length === 1) {
            newActiveGroup = this.parent!.parent;
          }
        }
      }
      return {
        patches: patches,
        newActiveGroup: newActiveGroup,
      };
    }
    const compoundModel = this.properties.compoundModel?.componentOperation || CompoundOperation.Unite;
    const compData = compoundPath(components, compoundModel);

    if (!compData) {
      const changePatches = this.removeSelfPatches();
      mergePatches(patches, changePatches);
      newActiveGroup = this.parent;
      if (isChildOfPath || isChildOfGroup) {
        const newComps = depthClone(
          this.parent!.components.map((comp) => {
            const compData = depthClone(comp.toJSON());
            // TODO:???
            compData.position = comp.position;
            compData.size = comp.size;
            compData.rotate = comp.rotate;
            compData.value = comp.value;
            return compData;
          }),
        );
        removeItemFromArray(
          newComps,
          newComps.find((comp) => comp._id === this.id),
        );
        if (isChildOfPath) {
          const { newActiveGroup: activeGroup } = (this
            .parent! as UICompoundPathComponent).refreshPatchesWithNewChildrenBynamicInfo(newComps, patches);
          newActiveGroup = newActiveGroup || activeGroup;
        } else {
          (this.parent! as UIGroupComponent).refreshPatchesWithNewChildren(newComps, patches);
          if (newComps.length === 1) {
            newActiveGroup = this.parent!.parent;
          }
        }
      }
      return {
        patches: patches,
        newActiveGroup: newActiveGroup,
      };
    }

    // 剩下的子偏移
    const diff = {
      x: compData?.position.x || 0,
      y: compData?.position.y || 0,
    };
    const addComps: IComponentData[] = [];
    components.forEach((comp) => {
      const doOps: Operation[] = patches.do[comp._id] || [];
      const undoOps: Operation[] = patches.undo[comp._id] || [];
      const originPosition = comp.position;
      const newPosition = {
        x: originPosition.x - diff.x,
        y: originPosition.y - diff.y,
      };
      const isParentPositionChanged = diff.x || diff.y;
      if (isParentPositionChanged) {
        const currentComp = this.components.find((item) => item.id === comp._id);
        if (!currentComp) {
          addComps.push(comp);
        }
        const path = currentComp?.getCurrentPositionPath() || 'position';
        doOps.push(Ops.replace(path, newPosition));
        undoOps.push(Ops.replace(path, currentComp?.position || comp.position));
      }
      if (doOps.length > 0) {
        patches.do[comp._id] = doOps;
        patches.undo[comp._id] = undoOps;
      }
    });
    const addPathes = patches.do[this.id]?.find((item) => {
      return Ops.isAddChildrenOperation(item);
    }) as AddChildrenOperation;

    // 修正新增加的组件
    if (addPathes) {
      const compDatas = addPathes.value;
      compDatas.forEach((comp) => {
        if (addComps.find((item) => item._id === comp._id)) {
          comp.position = {
            x: comp.position.x - diff.x,
            y: comp.position.y - diff.y,
          };
        }
      });
    }

    // compData.position = {
    //   x: compData.position.x + this.position.x,
    //   y: compData.position.y + this.position.y,
    // };

    const vector = mapVectorToTargetCoordinates(compData.position, this.rotate);
    const originLeftTopPoint = this.getBoxPointsInParent()[0];
    const newLeftTopPoint = {
      x: originLeftTopPoint.x + vector.x,
      y: originLeftTopPoint.y + vector.y,
    };
    const newCenter = getCenter(newLeftTopPoint, compData.size, this.rotate);
    const newPosition = getNWPoint(newCenter, compData.size, 0);

    const sizePath = this.getCurrentSizePath();
    const positionPath = this.getCurrentPositionPath();
    const valuePath = this.getValuePath(this.type);

    const changePatches = {
      do: {
        [this.id]: [
          Ops.replace(sizePath, compData.size),
          Ops.replace(positionPath, newPosition),
          Ops.replace(valuePath, compData.value),
        ],
      },
      undo: {
        [this.id]: [
          Ops.replace(sizePath, this.size),
          Ops.replace(positionPath, this.position),
          Ops.replace(valuePath, this.value),
        ],
      },
    };
    mergePatches(patches, changePatches);
    // 处理渐变
    const fillPatches = this.updateRadialGradientPatchesWithNewSize(compData.size);
    mergePatches(patches, fillPatches);
    if (isChildOfPath || isChildOfGroup) {
      const newComps = depthClone(
        this.parent!.components.map((comp) => {
          const compData = depthClone(comp.toJSON());

          // const vector = mapVectorToTargetCoordinates(compData.position, this.rotate)
          // const originLeftTopPoint = this.getBoxPointsInParent()[0];
          // const newLeftTopPoint = {
          //   x: originLeftTopPoint.x + vector.x,
          //   y: originLeftTopPoint.y + vector.y,
          // }
          // const newCenter = getCenter(newLeftTopPoint, compData.size, this.rotate);
          // const newPosition = getNWPoint(newCenter, compData.size, 0);
          // compData.position = newPosition;
          compData.position = comp.position;
          compData.size = comp.size;
          compData.rotate = comp.rotate;
          compData.value = comp.value;
          return compData;
        }),
      );
      const newCompData = newComps.find((item) => item._id === this.id);
      // newCompData!.position = compData.position;

      const vector = mapVectorToTargetCoordinates(compData.position, this.rotate);
      const originLeftTopPoint = this.getBoxPointsInParent()[0];
      const newLeftTopPoint = {
        x: originLeftTopPoint.x + vector.x,
        y: originLeftTopPoint.y + vector.y,
      };
      const newCenter = getCenter(newLeftTopPoint, compData.size, this.rotate);
      const newPosition = getNWPoint(newCenter, compData.size, 0);
      newCompData!.position = newPosition;
      newCompData!.size = compData.size;
      newCompData!.value = compData.value;
      if (isChildOfPath) {
        const { newActiveGroup: activeGroup } = (this
          .parent! as UICompoundPathComponent).refreshPatchesWithNewChildrenBynamicInfo(newComps, patches);
        newActiveGroup = newActiveGroup || activeGroup;
      } else {
        (this.parent! as UIGroupComponent).refreshPatchesWithNewChildren(newComps, patches);
        if (newComps.length === 1) {
          newActiveGroup = this.parent?.parent;
        }
      }
    }

    return {
      patches: patches,
      newActiveGroup: newActiveGroup,
    };
  };

  /**
   * 当由于复合路径的子的变化影响父级的路径时，调用该方法对修改的patches补充
   * @memberof UICompoundPathComponent
   */
  refreshPatchesWithNewChildren = (
    components: IComponentData[],
    patches: ArtboardPatches = { do: {}, undo: {} },
  ): {
    patches: ArtboardPatches;
    newActiveGroup?: UIContainerComponent;
  } => {
    const parent = this.parent!;
    const isChildOfPath = this.parent?.type === CCompoundPath;
    const isChildOfGroup = this.parent?.type === CGroup;
    let newActiveGroup: UIContainerComponent | undefined;
    if (components.length > 1) {
      newActiveGroup = this;
    }
    if (components.length === 1) {
      const lastComponentData = depthClone(components[0]);
      lastComponentData._id = getNewID();
      lastComponentData.position = new UIComponent(lastComponentData, this).getPositionWithoutParent();
      lastComponentData.rotate = (lastComponentData.rotate || 0) + this.rotate;
      // lastComponentData.position = {
      //   x: lastComponentData.position.x + this.position.x,
      //   y: lastComponentData.position.y + this.position.y,
      // }
      const removePatches = this.removeSelfPatches();
      mergePatches(patches, removePatches);

      const addPatches: ArtboardPatches = {
        do: { [parent.id]: [Ops.addChildren(`${-1}`, [lastComponentData])] },
        undo: { [parent.id]: [Ops.removeChildren([lastComponentData._id])] },
      };
      mergePatches(patches, addPatches);
      if (isChildOfPath || isChildOfGroup) {
        const newComps = this.parent!.components.map((item) => depthClone(item.toJSON()));
        removeItemFromArray(
          newComps,
          newComps.find((comp) => comp._id === this.id),
        );
        newComps.push(lastComponentData);
        if (isChildOfPath) {
          const { newActiveGroup: activeGroup } = (this
            .parent! as UICompoundPathComponent).refreshPatchesWithNewChildren(newComps, patches);
          newActiveGroup = newActiveGroup || activeGroup;
        } else {
          (this.parent! as UIGroupComponent).refreshPatchesWithNewChildren(newComps, patches);
          if (newComps.length === 1) {
            newActiveGroup = this.parent!.parent;
          }
        }
      }
      return {
        patches: patches,
        newActiveGroup: newActiveGroup,
      };
    }
    const compoundModel = this.properties.compoundModel?.componentOperation || CompoundOperation.Unite;
    const compData = compoundPath(components, compoundModel);
    if (!compData) {
      const changePatches = this.removeSelfPatches();
      mergePatches(patches, changePatches);
      newActiveGroup = this.parent;
      if (isChildOfPath || isChildOfGroup) {
        const newComps = this.parent!.components.map((item) => depthClone(item.toJSON()));
        removeItemFromArray(
          newComps,
          newComps.find((comp) => comp._id === this.id),
        );
        if (isChildOfPath) {
          const { newActiveGroup: activeGroup } = (this
            .parent! as UICompoundPathComponent).refreshPatchesWithNewChildren(newComps, patches);
          newActiveGroup = newActiveGroup || activeGroup;
        } else {
          (this.parent! as UIGroupComponent).refreshPatchesWithNewChildren(newComps, patches);
          if (newComps.length === 1) {
            newActiveGroup = this.parent!.parent;
          }
        }
      }
      return {
        patches: patches,
        newActiveGroup: newActiveGroup,
      };
    }

    // 剩下的子偏移
    const diff = {
      x: compData?.position.x || 0,
      y: compData?.position.y || 0,
    };
    const addComps: IComponentData[] = [];
    components.forEach((comp) => {
      const doOps: Operation[] = patches.do[comp._id] || [];
      const undoOps: Operation[] = patches.undo[comp._id] || [];
      const originPosition = comp.position;
      const newPosition = {
        x: originPosition.x - diff.x,
        y: originPosition.y - diff.y,
      };
      const isParentPositionChanged = diff.x || diff.y;
      if (isParentPositionChanged) {
        const currentComp = this.components.find((item) => item.id === comp._id);
        if (!currentComp) {
          addComps.push(comp);
        }
        const path = '/position';
        // const path = currentComp?.getCurrentPositionPath() || '/position';
        doOps.push(Ops.replace(path, newPosition));
        undoOps.push(Ops.replace(path, currentComp?.position || comp.position));
      }
      if (doOps.length > 0) {
        patches.do[comp._id] = doOps;
        patches.undo[comp._id] = undoOps;
      }
    });
    const addPathes = patches.do[this.id]?.find((item) => {
      return Ops.isAddChildrenOperation(item);
    }) as AddChildrenOperation;

    // 修正新增加的组件
    if (addPathes) {
      const compData = addPathes.value;
      compData.forEach((comp) => {
        if (addComps.find((item) => item._id === comp._id)) {
          comp.position = {
            x: comp.position.x - diff.x,
            y: comp.position.y - diff.y,
          };
        }
      });
    }

    const vector = mapVectorToTargetCoordinates(compData.position, this.rotate);
    const originLeftTopPoint = this.getBoxPointsInParent()[0];
    const newLeftTopPoint = {
      x: originLeftTopPoint.x + vector.x,
      y: originLeftTopPoint.y + vector.y,
    };
    const newCenter = getCenter(newLeftTopPoint, compData.size, this.rotate);
    compData.position = getNWPoint(newCenter, compData.size, 0);

    const sizePath = this.getCurrentSizePath();
    const positionPath = this.getCurrentPositionPath();
    const valuePath = this.getValuePath(this.type);
    const changePatches = {
      do: {
        [this.id]: [
          Ops.replace(sizePath, compData.size),
          Ops.replace(positionPath, compData.position),
          Ops.replace(valuePath, compData.value),
        ],
      },
      undo: {
        [this.id]: [
          Ops.replace(sizePath, this.size),
          Ops.replace(positionPath, this.position),
          Ops.replace(valuePath, this.value),
        ],
      },
    };
    mergePatches(patches, changePatches);
    // 处理渐变
    const fillPatches = this.updateRadialGradientPatchesWithNewSize(compData.size);
    mergePatches(patches, fillPatches);
    if (isChildOfPath || isChildOfGroup) {
      const newComps = this.parent!.components.map((item) => depthClone(item.toJSON()));
      const newCompData = newComps.find((item) => item._id === this.id);
      newCompData!.position = compData.position;
      newCompData!.size = compData.size;
      newCompData!.value = compData.value;
      if (isChildOfPath) {
        const { newActiveGroup: activeGroup } = (this.parent! as UICompoundPathComponent).refreshPatchesWithNewChildren(
          newComps,
          patches,
        );
        newActiveGroup = newActiveGroup || activeGroup;
      } else {
        (this.parent! as UIGroupComponent).refreshPatchesWithNewChildren(newComps, patches);
        if (newComps.length === 1) {
          newActiveGroup = this.parent!.parent;
        }
      }
    }
    return {
      patches: patches,
      newActiveGroup: newActiveGroup,
    };
  };

  /**
   * 子resize/move,更新同级及父级路径
   * @param {ComponentChange[]} changes
   * @param {boolean} includeSelf
   * @returns {ContainerPatches}
   * @memberof UICompoundPathComponent
   */
  getPositionPatchesOfChildrenChanged(
    changes: ComponentChange[],
    includeSelf: boolean,
    willRemoveComps?: UIComponent[],
  ): ContainerPatches {
    const newComponents = this.components.map((item) => {
      const compData = depthClone(item.toJSON());
      compData.position = item.position;
      compData.size = item.size;
      compData.rotate = item.rotate;
      compData.value = item.value;
      return compData;
    });
    const originUnchangedComp = this.components.filter(
      (comp) => !changes.find((change) => change.id === comp.id) && !comp.isConnector,
    );
    const parent = this.parent!;
    const isChildOfPath = this.parent?.type === CCompoundPath;
    const isChildOfGroup = this.parent?.type === CGroup;
    const changePathes: ArtboardPatches = {
      do: {},
      undo: {},
    };

    newComponents.forEach((comp) => {
      const change = changes.find((item) => item.id === comp._id);

      if (change) {
        const scale = {
          x: change.size.width / comp.size.width,
          y: change.size.height / comp.size.height,
        };

        comp.rotate = change.rotate;
        comp.size = { ...change.size, lockedRatio: comp.size.lockedRatio };
        comp.position = change.position;
        if (change.value) {
          comp.value = change.value;
        } else {
          const value = change.value || comp.value;
          if (comp.type === CPath) {
            comp.value = onPathValueZoom(value as IPathValue, scale);
          } else if (comp.type === CCompoundPath) {
            comp.value = (value as IPathValue[]).map((item) => {
              return onPathValueZoom(item, scale);
            });
            // 修改resize的组件的子
            const currentComp = this.components.find((item) => item.id === comp._id);
            (currentComp as UICompoundPathComponent).updateChildrenOfCompoundPathByZoom(scale, changePathes, true);
          }
        }
      }
    });
    const compoundModel = this.properties.compoundModel?.componentOperation || CompoundOperation.Unite;

    const compData = compoundPath(newComponents, compoundModel);

    // 修正子
    const childPatches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    newComponents.forEach((comp) => {
      const currentComp = this.components.find((item) => item.id === comp._id)!;
      const positionPath = currentComp.getCurrentPositionPath();
      const sizePath = currentComp.getCurrentSizePath();
      const valuePath = '/value';
      const rotatePath = currentComp.getCurrentRotatePath();
      const newPosition = {
        x: comp.position.x - (compData?.position.x || 0),
        y: comp.position.y - (compData?.position.y || 0),
      };
      childPatches.do[comp._id] = [...(childPatches.do[comp._id] || []), Ops.replace(positionPath, newPosition)];
      childPatches.undo[comp._id] = [
        ...(childPatches.undo[comp._id] || []),
        Ops.replace(positionPath, currentComp.position),
      ];
      if (!isEqualDate(comp.size, currentComp.size)) {
        childPatches.do[comp._id] = [...(childPatches.do[comp._id] || []), Ops.replace(sizePath, comp.size)];
        childPatches.undo[comp._id] = [...(childPatches.undo[comp._id] || []), Ops.replace(sizePath, currentComp.size)];
        // 处理渐变
        const fillPatches = currentComp.updateRadialGradientPatchesWithNewSize(comp.size);
        childPatches.do[comp._id] = [...(childPatches.do[comp._id] || []), ...(fillPatches.do[comp._id] || [])];
        childPatches.undo[comp._id] = [...(childPatches.undo[comp._id] || []), ...(fillPatches.undo[comp._id] || [])];
      }
      if (!isEqualDate(comp.rotate, currentComp.rotate)) {
        childPatches.do[comp._id] = [...(childPatches.do[comp._id] || []), Ops.replace(rotatePath, comp.rotate)];
        childPatches.undo[comp._id] = [
          ...(childPatches.undo[comp._id] || []),
          Ops.replace(rotatePath, currentComp.rotate),
        ];
      }
      if (!isEqualDate(comp.value, currentComp.value)) {
        childPatches.do[comp._id] = [...(childPatches.do[comp._id] || []), Ops.replace(valuePath, comp.value)];
        childPatches.undo[comp._id] = [
          ...(childPatches.undo[comp._id] || []),
          Ops.replace(valuePath, currentComp.value),
        ];
      }
    });
    mergePatches(changePathes, childPatches);

    // 如果当前组的position有变化，需要修正所有非流程线子组件的position
    updateUnChangedComponentsPatches(
      originUnchangedComp,
      {
        x: compData?.position.x || 0,
        y: compData?.position.y || 0,
      },
      changePathes,
    );

    if (compData) {
      const valuePath = this.getValuePath(this.type);
      const sizePath = this.getCurrentSizePath();
      const positionPath = this.getCurrentPositionPath();
      const vector = mapVectorToTargetCoordinates(compData.position, this.rotate);
      const originLeftTopPoint = this.getBoxPointsInParent()[0];
      const newLeftTopPoint = {
        x: originLeftTopPoint.x + vector.x,
        y: originLeftTopPoint.y + vector.y,
      };
      const newCenter = getCenter(newLeftTopPoint, compData.size, this.rotate);
      const newPosition = getNWPoint(newCenter, compData.size, 0);
      const valuePathes: ArtboardPatches = {
        do: {
          [this.id]: [
            Ops.replace(valuePath, compData.value),
            Ops.replace(sizePath, compData.size),
            Ops.replace(positionPath, newPosition),
          ],
        },
        undo: {
          [this.id]: [
            Ops.replace(valuePath, this.value),
            Ops.replace(sizePath, this.size),
            Ops.replace(positionPath, this.position),
          ],
        },
      };
      mergePatches(changePathes, valuePathes);
      const fillPatches = this.updateRadialGradientPatchesWithNewSize(compData.size);
      mergePatches(changePathes, fillPatches);
      if (isChildOfPath || isChildOfGroup) {
        const newComps = depthClone(
          parent.components.map((comp) => {
            const compData = depthClone(comp.toJSON());
            compData.position = comp.position;
            compData.size = comp.size;
            compData.rotate = comp.rotate;
            compData.value = comp.value;
            return compData;
          }),
        );
        const currentComp = newComps.find((item) => item._id === this.id)!;
        currentComp.value = compData.value;
        currentComp.size = compData.size;
        const vector = mapVectorToTargetCoordinates(compData.position, this.rotate);
        const originLeftTopPoint = this.getBoxPointsInParent()[0];
        const newLeftTopPoint = {
          x: originLeftTopPoint.x + vector.x,
          y: originLeftTopPoint.y + vector.y,
        };
        const newCenter = getCenter(newLeftTopPoint, compData.size, this.rotate);
        const newPosition = getNWPoint(newCenter, compData.size, 0);
        currentComp.position = newPosition;
        if (isChildOfPath) {
          (parent as UICompoundPathComponent).refreshPatchesWithNewChildrenBynamicInfo(newComps, changePathes);
        } else {
          (parent as UIGroupComponent).refreshPatchesWithNewChildren(newComps, changePathes);
        }
      }
    } else {
      if (willRemoveComps) {
        willRemoveComps.push(this);
      }

      const valuePath = this.getValuePath(this.type);
      const sizePath = this.getCurrentSizePath();
      const valuePathes: ArtboardPatches = {
        do: {
          [this.id]: [
            Ops.replace(valuePath, []),
            Ops.replace(sizePath, {
              width: 0,
              height: 0,
            }),
          ],
        },
        undo: {
          [this.id]: [Ops.replace(valuePath, this.value), Ops.replace(sizePath, this.size)],
        },
      };
      mergePatches(changePathes, valuePathes);
      if (isChildOfPath || isChildOfGroup) {
        const newComps = parent.components.map((comp) => {
          const compData = depthClone(comp.toJSON());
          compData.position = comp.position;
          compData.size = comp.size;
          compData.rotate = comp.rotate;
          compData.value = comp.value;
          return compData;
        });
        removeItemFromArray(
          newComps,
          newComps.find((item) => item._id === this.id),
        );
        if (isChildOfPath) {
          (parent as UICompoundPathComponent).refreshPatchesWithNewChildrenBynamicInfo(newComps, changePathes);
        } else {
          (this.parent! as UIGroupComponent).refreshPatchesWithNewChildren(newComps, changePathes);
        }
      }
    }
    return {
      patches: changePathes,
    };
  }

  /**
   * 子修改并添加,更新同级及父级路径
   */
  getPatchesOfChildrenChangedAndAdd(
    changes: ComponentChange[],
    newAddedComponents: IComponentData[],
  ): ContainerPatches {
    const newComponents = this.components.map((item) => {
      const compData = depthClone(item.toJSON());
      compData.position = item.position;
      compData.size = item.size;
      compData.rotate = item.rotate;
      compData.value = item.value;
      return compData;
    });
    const originUnchangedComp = this.components.filter(
      (comp) => !changes.find((change) => change.id === comp.id) && !comp.isConnector,
    );
    const parent = this.parent!;
    const isChildOfPath = this.parent?.type === CCompoundPath;
    const isChildOfGroup = this.parent?.type === CGroup;
    const changePathes: ArtboardPatches = {
      do: {},
      undo: {},
    };

    // 新增
    const newPathComps = newAddedComponents.filter(({ type, lib }: IComponentData) => {
      return lib?.type !== 'hot-area' && [CCompoundPath, CPath].includes(type);
    });
    // 重置状态
    newPathComps.forEach((comp) => {
      comp.disabled = false;
      comp.selected = false;
      comp._currentState = undefined;
    });

    newComponents.forEach((comp) => {
      const change = changes.find((item) => item.id === comp._id);

      if (change) {
        const scale = {
          x: change.size.width / comp.size.width,
          y: change.size.height / comp.size.height,
        };

        comp.rotate = change.rotate;
        comp.size = { ...change.size, lockedRatio: comp.size.lockedRatio };
        comp.position = change.position;
        if (change.value) {
          comp.value = change.value;
        } else {
          const value = change.value || comp.value;
          if (comp.type === CPath) {
            comp.value = onPathValueZoom(value as IPathValue, scale);
          } else if (comp.type === CCompoundPath) {
            comp.value = (value as IPathValue[]).map((item) => {
              return onPathValueZoom(item, scale);
            });
            // 修改resize的组件的子
            const currentComp = this.components.find((item) => item.id === comp._id);
            (currentComp as UICompoundPathComponent).updateChildrenOfCompoundPathByZoom(scale, changePathes, true);
          }
        }
      }
    });
    const compoundModel = this.properties.compoundModel?.componentOperation || CompoundOperation.Unite;

    const compData = compoundPath([...newComponents, ...newAddedComponents], compoundModel);

    // 修正子
    const childPatches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    newComponents.forEach((comp) => {
      const currentComp = this.components.find((item) => item.id === comp._id)!;
      const positionPath = currentComp.getCurrentPositionPath();
      const sizePath = currentComp.getCurrentSizePath();
      const valuePath = '/value';
      const rotatePath = currentComp.getCurrentRotatePath();
      const newPosition = {
        x: comp.position.x - (compData?.position.x || 0),
        y: comp.position.y - (compData?.position.y || 0),
      };
      childPatches.do[comp._id] = [...(childPatches.do[comp._id] || []), Ops.replace(positionPath, newPosition)];
      childPatches.undo[comp._id] = [
        ...(childPatches.undo[comp._id] || []),
        Ops.replace(positionPath, currentComp.position),
      ];
      if (!isEqualDate(comp.size, currentComp.size)) {
        const fillPatches = currentComp.updateRadialGradientPatchesWithNewSize(comp.size);
        childPatches.do[comp._id] = [
          ...(childPatches.do[comp._id] || []),
          Ops.replace(sizePath, comp.size),
          ...(fillPatches.do[comp._id] || []),
        ];
        childPatches.undo[comp._id] = [
          ...(childPatches.undo[comp._id] || []),
          Ops.replace(sizePath, currentComp.size),
          ...(fillPatches.undo[comp._id] || []),
        ];
      }
      if (!isEqualDate(comp.rotate, currentComp.rotate)) {
        childPatches.do[comp._id] = [...(childPatches.do[comp._id] || []), Ops.replace(rotatePath, comp.rotate)];
        childPatches.undo[comp._id] = [
          ...(childPatches.undo[comp._id] || []),
          Ops.replace(rotatePath, currentComp.rotate),
        ];
      }
      if (!isEqualDate(comp.value, currentComp.value)) {
        childPatches.do[comp._id] = [...(childPatches.do[comp._id] || []), Ops.replace(valuePath, comp.value)];
        childPatches.undo[comp._id] = [
          ...(childPatches.undo[comp._id] || []),
          Ops.replace(valuePath, currentComp.value),
        ];
      }
    });
    mergePatches(changePathes, childPatches);

    // 如果当前组的position有变化，需要修正所有非流程线子组件的position
    updateUnChangedComponentsPatches(
      originUnchangedComp,
      {
        x: compData?.position.x || 0,
        y: compData?.position.y || 0,
      },
      changePathes,
    );

    if (newPathComps && compData) {
      const patches: ArtboardPatches = {
        do: {
          [this.id]: [
            Ops.addChildren(
              '-1',
              newPathComps.map((comp) => {
                comp.position = {
                  x: comp.position.x - compData.position.x,
                  y: comp.position.y - compData.position.y,
                };
                return comp;
              }),
            ),
          ],
        },
        undo: {
          [this.id]: [Ops.removeChildren(newAddedComponents.map((comp) => comp._id))],
        },
      };
      mergePatches(changePathes, patches);
    }

    if (compData) {
      const valuePath = this.getValuePath(this.type);
      const sizePath = this.getCurrentSizePath();
      const positionPath = this.getCurrentPositionPath();
      const vector = mapVectorToTargetCoordinates(compData.position, this.rotate);
      const originLeftTopPoint = this.getBoxPointsInParent()[0];
      const newLeftTopPoint = {
        x: originLeftTopPoint.x + vector.x,
        y: originLeftTopPoint.y + vector.y,
      };
      const newCenter = getCenter(newLeftTopPoint, compData.size, this.rotate);
      const newPosition = getNWPoint(newCenter, compData.size, 0);
      const fillPatches = this.updateRadialGradientPatchesWithNewSize(compData.size);
      const valuePathes: ArtboardPatches = {
        do: {
          [this.id]: [
            Ops.replace(valuePath, compData.value),
            Ops.replace(sizePath, compData.size),
            Ops.replace(positionPath, newPosition),
            ...(fillPatches.do[this.id] || []),
          ],
        },
        undo: {
          [this.id]: [
            Ops.replace(valuePath, this.value),
            Ops.replace(sizePath, this.size),
            Ops.replace(positionPath, this.position),
            ...(fillPatches.undo[this.id] || []),
          ],
        },
      };
      mergePatches(changePathes, valuePathes);
      if (isChildOfPath || isChildOfGroup) {
        const newComps = depthClone(
          parent.components.map((comp) => {
            const compData = depthClone(comp.toJSON());
            compData.position = comp.position;
            compData.size = comp.size;
            compData.rotate = comp.rotate;
            compData.value = comp.value;
            return compData;
          }),
        );
        const currentComp = newComps.find((item) => item._id === this.id)!;
        currentComp.value = compData.value;
        currentComp.size = compData.size;
        currentComp.position = newPosition;
        if (isChildOfPath) {
          (parent as UICompoundPathComponent).refreshPatchesWithNewChildrenBynamicInfo(newComps, changePathes);
        } else {
          (parent as UIGroupComponent).refreshPatchesWithNewChildren(newComps, changePathes);
        }
      }
    } else {
      const valuePath = this.getValuePath(this.type);
      const sizePath = this.getCurrentSizePath();
      const valuePathes: ArtboardPatches = {
        do: {
          [this.id]: [
            Ops.replace(valuePath, []),
            Ops.replace(sizePath, {
              width: 0,
              height: 0,
            }),
          ],
        },
        undo: {
          [this.id]: [Ops.replace(valuePath, this.value), Ops.replace(sizePath, this.size)],
        },
      };
      mergePatches(changePathes, valuePathes);
      if (isChildOfPath || isChildOfGroup) {
        const newComps = parent.components.map((comp) => {
          const compData = depthClone(comp.toJSON());
          compData.position = comp.position;
          compData.size = comp.size;
          compData.rotate = comp.rotate;
          compData.value = comp.value;
          return compData;
        });
        removeItemFromArray(
          newComps,
          newComps.find((item) => item._id === this.id),
        );
        if (isChildOfPath) {
          (parent as UICompoundPathComponent).refreshPatchesWithNewChildrenBynamicInfo(newComps, changePathes);
        } else {
          (parent as UIGroupComponent).refreshPatchesWithNewChildren(newComps, changePathes);
        }
      }
    }
    return {
      patches: changePathes,
    };
  }

  /**
   *  add子，更新父以及同级
   * @param {IComponentData[]} newAddedComponents
   * @returns {{
   *     patches: PagePatches,
   *     newActiveGroup?: UIContainerComponent,
   *   }}
   * @memberof UICompoundPathComponent
   */
  addComponents(
    newAddedComponents: IComponentData[],
    index = -1,
  ): {
    patches: PagePatches;
    newActiveGroup?: UIContainerComponent;
  } {
    const newPathComps = newAddedComponents.filter(({ type, lib }: IComponentData) => {
      return lib?.type !== 'hot-area' && [CCompoundPath, CPath, CRect, CEllipse, CPolygon, CLine].includes(type);
    });
    if (!newPathComps.length) {
      return {
        patches: {},
      };
    }
    // 重置状态
    newPathComps.forEach((comp) => {
      comp.disabled = false;
      comp.selected = false;
      comp._currentState = undefined;
    });
    const patches: ArtboardPatches = {
      do: {
        [this.id]: [Ops.addChildren(`${index}`, newPathComps)],
      },
      undo: {
        [this.id]: [Ops.removeChildren(newAddedComponents.map((comp) => comp._id))],
      },
    };

    const newChildren = depthClone(this.components.map((comp) => comp.toJSON()));
    newChildren.push(...newPathComps);
    const { newActiveGroup, patches: newPatches } = this.refreshPatchesWithNewChildren(newChildren);
    if (newActiveGroup && newActiveGroup.id !== this.id) {
      // 如果当前容器被删除了，那么久不合并patches
      patches.do = newPatches.do;
      patches.undo = newPatches.undo;
    } else {
      mergePatches(patches, newPatches);
    }

    return {
      patches: {
        [this.ownerArtboardID]: patches,
      },
      newActiveGroup,
    };
  }

  /**
   * resize复合路径时，更新子
   * @param {{x: number,y: number}} scale
   * @param {ArtboardPatches} patches
   * @memberof UICompoundPathComponent
   */
  updateChildrenOfCompoundPathByZoom(
    scale: { x: number; y: number },
    patches: ArtboardPatches,
    byDynamicInfo?: boolean,
  ) {
    if (scale.x === 1 && scale.y === 1) {
      return;
    }
    const children = this.components.filter((comp) => [CCompoundPath, CPath].includes(comp.type));
    children.forEach((child) => {
      // 将当前数据注入compData
      const childData = depthClone(child.toJSON());
      if (byDynamicInfo) {
        childData.position = child.position;
        childData.value = child.value;
        childData.size = child.size;
      }
      const value = childData.value;
      const newSize: IComponentSize = {
        ...childData.size,
        width: childData.size.width * scale.x,
        height: childData.size.height * scale.y,
      };
      const newPosition: IPosition = {
        ...childData.position,
        x: childData.position.x * scale.x,
        y: childData.position.y * scale.y,
      };
      const sizePath = child.getCurrentSizePath();
      const positionPath = child.getCurrentPositionPath();

      let newValue = value;
      const valuePath = [CCompoundPath, CPath].includes(child.type)
        ? '/value'
        : child.getCurrentPropertiesPath('/value');
      if (child.type === CPath) {
        newValue = onPathValueZoom(value as IPathValue, scale);
      } else if (child.type === CCompoundPath) {
        newValue = (value as IPathValue[]).map((item) => {
          return onPathValueZoom(item, scale);
        });
      } else if (child.type === CLine) {
        newValue = getLineValueByZoom(value as ILineValue, scale);
      }

      const newPatches: ArtboardPatches = {
        do: {
          [child.id]: [
            Ops.replace(sizePath, newSize),
            Ops.replace(positionPath, newPosition),
            Ops.replace(valuePath, newValue),
          ],
        },
        undo: {
          [child.id]: [
            Ops.replace(sizePath, childData.size),
            Ops.replace(positionPath, childData.position),
            Ops.replace(valuePath, value),
          ],
        },
      };

      mergePatches(patches, newPatches);
      // 处理渐变
      const fillPatches = child.updateRadialGradientPatchesWithNewSize(newSize);
      mergePatches(patches, fillPatches);
      if (child.type === CCompoundPath) {
        (child as UICompoundPathComponent).updateChildrenOfCompoundPathByZoom(scale, patches, byDynamicInfo);
      }
    });
  }

  /**
   * 当尺寸改变时，更新复合路径组件的路径数据
   * @param {IBounds} newBounds 调整尺寸后的新尺寸
   * @returns {ArtboardPatches | null}
   */
  protected doUpdateCompoundPathValue = (newBounds: IBounds): ArtboardPatches | null => {
    // 1.获取原来的大小
    const oldSize = this.currentState.size || this.data.size;
    if (newBounds.width === oldSize.width && newBounds.height === oldSize.height) {
      return null;
    }
    //2. 实时resize过程中的大小 与原来大小比较  得到scale倍数
    const scale = {
      x: newBounds.width / (oldSize.width || 1),
      y: newBounds.height / (oldSize.height || 1),
    };
    const scalePoint = (pt: IPoint): IPoint => {
      return {
        x: pt.x * scale.x,
        y: pt.y * scale.y,
      };
    };
    //3.将scale的倍数应用到point上
    const value = (this.currentState.value || this.data.value) as IPathValue[];
    const newValue = depthClone(value);
    newValue.forEach((item) => {
      item.data = item.data.map((pt) => {
        const { point, handleOut, handleIn } = pt;
        return {
          point: scalePoint(point),
          handleIn: scalePoint(handleIn),
          handleOut: scalePoint(handleOut),
        };
      });
    });
    const path = this.getValuePath(this.type);
    return {
      do: {
        [this.id]: [Ops.replace(path, newValue)],
      },
      undo: {
        [this.id]: [Ops.replace(path, value)],
      },
    };
  };

  flipHandler(flipModel: IFlipModel, value: boolean): ArtboardPatches {
    const patches = super.flipHandler(flipModel, value);
    const childrenBounds = getViewBoundsOfComponents(this.components!)!;
    this.components.forEach((comp) => {
      const path = flipModel === IFlipModel.Horizontal ? 'horizontal' : 'vertical';
      const childValue = !(comp.flip && comp.flip[path]);
      const childPatches = comp.flipHandler(flipModel, childValue);
      const positionPatches = getPositionPatchesWhenFlip(comp, flipModel, childValue, childrenBounds);
      coverPatches(patches, positionPatches);
      coverPatches(patches, childPatches);
    });
    return patches;
  }
}
