import * as React from 'react';
import classnames from 'classnames';
import { round } from 'lodash';

import { max, depthClone } from '@utils/globalUtils';

import { MobileType } from '@/fbs/rp/utils/preview';
import { ISize, IPosition } from '@fbs/idoc/models/common';
import { Orientation } from '@fbs/common/models/common';

import { getSizeFromOrientation } from '@/helpers/artboardDimensionHelper';
import { getCompsBoundsInArtboard } from '@/helpers/componentHelper';
import { IPreviewOption, isMobileAppType, isWatchAppType, isVehicleAppType } from '@helpers/previewHelper';
import { IReturnOption, getShellInfo, isMobileShell } from '@helpers/shellHelper';

import Theater from '../../../../../theater';
import DeviceShell from './DeviceShell';

import './index.scss';

const MIN_MARGIN = 0;
const MOBILE_TYPE_VERTICAL_MARGIN = 60; // 手机项目和平板项目,适应屏幕时的垂直margin

export interface IPreviewDeviceProp {
  page: Theater;
  advancePage?: Theater;
  scale: number;
  appSize: ISize;
  appType: string;
  orientation?: Orientation;
  viewPortSize: { viewPortHeight: number; viewPortWidth: number };
  option: IPreviewOption;
  zoomByWheel?: boolean;
  isStandalone?: boolean;
  remarkTagId?: string;
  devicePosition?: React.CSSProperties;
  // onScrollOffsetX: (offset: number)=>void;
  // onScrollOffsetY: (offset: number)=>void;
  onScrollBarUpdate: (offset?: IPosition) => void;
  onScrollBarRZ: () => void;
  onAutoScale: (value: number) => void;
}

export interface IPreviewDeviceState {
  isPressed: boolean;
}

class PreviewDevice extends React.Component<IPreviewDeviceProp, IPreviewDeviceState> {
  static defaultProps: Partial<IPreviewDeviceProp> = {};
  private deviceOffset?: IPosition;
  selfRef: React.RefObject<HTMLDivElement>;
  shell: React.RefObject<DeviceShell> = React.createRef();

  constructor(props: IPreviewDeviceProp) {
    super(props);
    this.state = {
      isPressed: false,
    };
    this.selfRef = React.createRef();
  }

  get orientation(): Orientation | undefined {
    const {
      orientation,
      page: {
        doc: { mainArtboard },
      },
    } = this.props;
    return orientation ?? mainArtboard.orientation;
  }

  get isLandscape() {
    // 手表只提供 竖屏
    if (this.isWatch) {
      return false;
    }
    return this.orientation === 'landscape';
  }

  /**
   * 考虑主画板方向后的项目尺寸
   */
  get rotatedAppSize() {
    const { appSize, appType } = this.props;
    let orientation = this.orientation;
    //手表类型有时候会被错误识别成横屏需要特殊处理一下
    if (appType === 'watch') {
      orientation = 'portrait';
    }
    return getSizeFromOrientation(orientation, appSize);
  }

  componentDidMount() {
    const {
      option: { autoScreen, mobileType, noBoundary, fullWidth },
    } = this.props;
    if (autoScreen) {
      // 计算适合屏幕的设备缩放比例
      if (isMobileShell(mobileType) && !noBoundary) {
        this.getAutoScreenScale(mobileType);
      } else {
        this.getAutoScreenScaleWithoutsShell();
      }
    } else if (fullWidth) {
      if (isMobileShell(mobileType) && !noBoundary) {
        this.getFullWidthScale(mobileType);
      } else {
        this.getFullWidthScaleWithoutsShell();
      }
    } else {
      this.turnOffAutoScreen(noBoundary);
    }
    this.props.onScrollBarUpdate();
    window.addEventListener('mousedown', this.handleMouseDown);
    window.addEventListener('mouseup', this.handleMouseUp);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleMouseDown);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseDown = () => {
    if (!this.shouldShowShell) {
      return;
    }
    if (!this.state.isPressed) {
      this.setState({ isPressed: true });
    }
  };

  handleMouseUp = () => {
    if (!this.shouldShowShell) {
      return;
    }
    if (this.state.isPressed) {
      this.setState({ isPressed: false });
    }
  };

  turnOffAutoScreen = (noBoundary = false) => {
    const {
      appType,
      onAutoScale,
      page,
      viewPortSize: { viewPortWidth },
      option: { scale },
    } = this.props;
    if (isMobileAppType(appType) || noBoundary) {
      onAutoScale(scale || 100);
    } else {
      const artboardSize = page.doc.mainArtboard.size;
      const newScale = Math.min(round((viewPortWidth - MIN_MARGIN * 2) / artboardSize.width, 2) * 100, scale || 100);
      onAutoScale(newScale);
    }
  };

  UNSAFE_componentWillUpdate() {
    let offset: IPosition | undefined = undefined;
    const deviceRect = this.selfRef.current?.getBoundingClientRect();
    const viewRect = document.querySelector('.preview-view-port')?.getBoundingClientRect();
    if (deviceRect && viewRect) {
      offset = {
        x: deviceRect.x - viewRect.x,
        y: deviceRect.y - viewRect.y,
      };
    }

    this.deviceOffset = offset;
  }

  componentDidUpdate(prevProps: IPreviewDeviceProp) {
    const {
      option: { autoScreen, mobileType, noBoundary, fullWidth },
      viewPortSize,
    } = prevProps;
    const { scale, page, option, zoomByWheel, onScrollBarUpdate, onScrollBarRZ, orientation } = this.props;
    const currentMobileType = option.mobileType!;
    const commonCondition =
      option.mobileType !== mobileType ||
      orientation !== prevProps.orientation ||
      this.props.viewPortSize !== viewPortSize ||
      page.doc.pageID !== prevProps.page.doc.pageID;
    const isNoBoundaryChanged = option.noBoundary !== noBoundary;
    const isAutoScreenChanged = option.autoScreen !== autoScreen;
    const isFullWidthChanged = option.fullWidth !== fullWidth;
    if (isAutoScreenChanged || isFullWidthChanged || isNoBoundaryChanged || commonCondition) {
      if (option.autoScreen) {
        if (isMobileShell(currentMobileType) && !option.noBoundary) {
          this.getAutoScreenScale(currentMobileType);
        } else {
          this.getAutoScreenScaleWithoutsShell();
        }
      } else if (option.fullWidth) {
        if (isMobileShell(mobileType) && !option.noBoundary) {
          this.getFullWidthScale(mobileType);
        } else {
          this.getFullWidthScaleWithoutsShell();
        }
      }
    }

    // 处理无边界模式下滚动条
    // 页面切换、缩放、模式切换
    if (prevProps.scale !== scale && !zoomByWheel) {
      onScrollBarUpdate();
    }
    if (prevProps.page.doc.pageID !== page.doc.pageID) {
      onScrollBarUpdate();
      window.dispatchEvent(new CustomEvent('resetPanelScrollbar', { detail: { reset: true } }));
    }
    if (!prevProps.option.noBoundary && !!option.noBoundary) {
      // 从一般模式到无边界模式，保持视觉上不变
      onScrollBarUpdate(this.deviceOffset);
    }
    // 还原滚动条
    if (!!prevProps.option.noBoundary && !option.noBoundary) {
      onScrollBarRZ();
    }
  }

  /**
   * 设备外壳相对于演示区偏移
   * @param pageDefaultSize
   * @param shellOption
   * @param defaultScale
   */
  getShellOffset = (
    pageDefaultSize: { width: number; height: number },
    shellOption: IReturnOption | null,
    defaultScale: number,
  ) => {
    const { viewPortSize, page, option, scale } = this.props;

    let minPaddingTop = MIN_MARGIN;
    let minPaddingLeft = MIN_MARGIN;
    let minBottom = MIN_MARGIN;
    let minRight = MIN_MARGIN;
    if (option.noBoundary) {
      // 获取主画板上组件的bounds
      const childrenBounds = getCompsBoundsInArtboard(page.doc.mainArtboard.components);
      if (childrenBounds.left < 0) {
        minPaddingLeft -= (childrenBounds.left * scale) / 100;
      }
      if (childrenBounds.top < 0) {
        minPaddingTop -= (childrenBounds.top * scale) / 100;
      }
      minBottom += (childrenBounds.bottom * scale) / 100;
      minRight += (childrenBounds.right * scale) / 100;
    }

    const { viewPortHeight, viewPortWidth } = viewPortSize;
    const { height, width } = pageDefaultSize;
    const screenScale = this.props.scale / 100;
    let offsetX = Math.round(0.5 * (viewPortWidth - width * screenScale));
    let offsetY = Math.round(0.5 * (viewPortHeight - height * screenScale));
    if (!shellOption || option.noBoundary) {
      offsetX = offsetX < minPaddingLeft ? minPaddingLeft : offsetX;
      offsetY = offsetY < minPaddingTop ? minPaddingTop : offsetY;
      return { offsetX, offsetY, minBottom, minRight };
    }
    const defaultX = Math.round(shellOption.screenBounds.left * defaultScale * screenScale);
    const defaultY = Math.round(shellOption.screenBounds.top * defaultScale * screenScale);
    offsetX = offsetX < defaultX + minPaddingLeft ? defaultX + minPaddingLeft : offsetX;
    offsetY = offsetY < defaultY + minPaddingTop ? defaultY + minPaddingTop : offsetY;

    return { offsetX, offsetY, minBottom, minRight };
  };

  /**
   * 获取带设备外壳时，自适应缩放比
   */
  getAutoScreenScale = (mobileType: MobileType) => {
    // 获取区域高宽
    // 获取设备默认100%高宽
    const { isLandscape, rotatedAppSize } = this;
    const {
      viewPortSize: { viewPortHeight, viewPortWidth },
    } = this.props;

    const shellOption = getShellInfo(mobileType, isLandscape);
    if (!shellOption) {
      return;
    }

    const defaultScale = this.getDefaultScale(shellOption);
    const { shellSize, screenBounds } = shellOption;

    const { left, top, width, height } = screenBounds;
    const right = shellSize.width - width - left;
    const bottom = shellSize.height - height - top;

    // 使屏幕居中
    const shellWidth = isLandscape
      ? max(left, right) * 2 * defaultScale + rotatedAppSize.width
      : shellSize.width * defaultScale;

    const shellHeight = isLandscape
      ? shellSize.height * defaultScale
      : max(top, bottom) * 2 * defaultScale + rotatedAppSize.height;

    let autoScreenScale = 1;
    const margin = MIN_MARGIN * 2;
    // 设备类型为mobile或者pad时，垂直方向加上MOBILE_TYPE_VERTICAL_MARGIN距离
    const resetHeight = viewPortHeight - MOBILE_TYPE_VERTICAL_MARGIN * 2;
    if (viewPortWidth / resetHeight < shellWidth / shellHeight) {
      autoScreenScale = round((viewPortWidth - margin) / shellWidth, 6);
    } else {
      autoScreenScale = round((resetHeight - margin) / shellHeight, 6);
    }
    autoScreenScale = autoScreenScale > 4 ? 4 : autoScreenScale;
    autoScreenScale = autoScreenScale < 0.1 ? 0.1 : autoScreenScale;

    if (this.props.scale === autoScreenScale * 100) {
      return;
    }
    this.props.onAutoScale(autoScreenScale * 100);
  };

  // 获取自适应宽度缩放
  getFullWidthScale = (mobileType: MobileType) => {
    // 获取区域高宽
    // 获取设备默认100%高宽
    const { isLandscape, rotatedAppSize } = this;
    const {
      viewPortSize: { viewPortWidth },
    } = this.props;

    const shellOption = getShellInfo(mobileType, isLandscape);
    if (!shellOption) {
      return;
    }

    const defaultScale = this.getDefaultScale(shellOption);
    const { shellSize, screenBounds } = shellOption;

    const { left, width } = screenBounds;
    const right = shellSize.width - width - left;

    // 使屏幕居中
    const shellWidth = isLandscape
      ? max(left, right) * 2 * defaultScale + rotatedAppSize.width
      : shellSize.width * defaultScale;

    let autoScreenScale = 1;
    const margin = MIN_MARGIN * 2;

    autoScreenScale = round((viewPortWidth - margin) / shellWidth, 6);
    autoScreenScale = autoScreenScale > 4 ? 4 : autoScreenScale;
    autoScreenScale = autoScreenScale < 0.1 ? 0.1 : autoScreenScale;

    if (this.props.scale === autoScreenScale * 100) {
      return;
    }
    this.props.onAutoScale(autoScreenScale * 100);
  };

  /**
   * 获取无设备外壳\无边界自适应缩放比
   */
  getAutoScreenScaleWithoutsShell = () => {
    // 获取区域高宽
    const { rotatedAppSize } = this;
    const {
      page,
      appType,
      option,
      viewPortSize: { viewPortHeight, viewPortWidth },
    } = this.props;
    const { doc } = page;
    let scale = 1;
    const pageSize = depthClone(doc.mainArtboard.size);
    const isMobileType = isMobileAppType(appType);

    const compBounds = getCompsBoundsInArtboard(doc.mainArtboard.components);
    const mainSize = isMobileType ? depthClone(rotatedAppSize) : pageSize;
    if (option.noBoundary) {
      // 无边界显示页面尺寸
      const widthL = pageSize.width - compBounds.left * 2;
      const widthR = pageSize.width + (compBounds.right - pageSize.width) * 2;
      const widthN = pageSize.width;
      const width = max(widthL, widthR, widthN);
      const heightT = pageSize.height - compBounds.top * 2;
      const heightB = pageSize.height + (compBounds.bottom - pageSize.height) * 2;
      const heightN = pageSize.height;
      const height = max(heightT, heightB, heightN);
      mainSize.width = width;
      mainSize.height = height;
    }
    // 演示为pad或者mobile项目时，即使没有外壳，也要加上MOBILE_TYPE_VERTICAL_MARGIN的边距
    const resetHeight =
      isMobileAppType(this.props.appType) && !option.noBoundary
        ? viewPortHeight - MOBILE_TYPE_VERTICAL_MARGIN * 2
        : viewPortHeight;
    if (mainSize.height / mainSize.width > resetHeight / viewPortWidth) {
      scale = round((resetHeight - MIN_MARGIN * 2) / mainSize.height, 6);
    } else {
      scale = round((viewPortWidth - MIN_MARGIN * 2) / mainSize.width, 6);
    }

    scale = scale > 4 ? 4 : scale;
    scale = scale < 0.1 ? 0.1 : scale;

    if (this.props.scale === scale * 100) {
      return;
    }
    this.props.onAutoScale(scale * 100);
  };

  // 获取适应宽度的自适应缩放
  getFullWidthScaleWithoutsShell = () => {
    const { rotatedAppSize } = this;
    const {
      page,
      appType,
      option,
      viewPortSize: { viewPortWidth },
    } = this.props;
    const { doc } = page;
    let scale = 1;
    const pageSize = depthClone(doc.mainArtboard.size);
    const isMobileType = isMobileAppType(appType);

    const compBounds = getCompsBoundsInArtboard(doc.mainArtboard.components);
    const mainSize = isMobileType ? depthClone(rotatedAppSize) : pageSize;
    if (option.noBoundary) {
      const widthL = pageSize.width - compBounds.left * 2;
      const widthR = pageSize.width + (compBounds.right - pageSize.width) * 2;
      const widthN = pageSize.width;
      const width = max(widthL, widthR, widthN);
      mainSize.width = width;
    }
    scale = round((viewPortWidth - MIN_MARGIN * 2) / mainSize.width, 6);

    scale = scale > 4 ? 4 : scale;
    scale = scale < 0.1 ? 0.1 : scale;

    if (this.props.scale === scale * 100) {
      return;
    }
    // this.curFullInfo.isFullWidth = true;
    this.props.onAutoScale(scale * 100);
  };

  getSkipStyleAndClassName(
    page: Theater | undefined,
    pageType: 'current' | 'next',
  ): { style: React.CSSProperties; classNames: string } {
    let animationName = 'null';
    const style: React.CSSProperties = {};
    if (!page) {
      return { style, classNames: '' };
    }
    const { animationEffect } = page;
    if (animationEffect) {
      style.animationTimingFunction = animationEffect.animation.effect;
      style.animationDuration = `${animationEffect.animation.duration || 1}ms`;
      animationName = animationEffect.params;
      style.animationFillMode = `${pageType == 'next' ? 'forwards' : ''}`;
    }
    const classNames = classnames([`${pageType}-${animationName}`], [`${pageType}-page`]);
    return {
      style,
      classNames,
    };
  }

  // 机型尺寸的倍率，@2x @3x 这种
  getDefaultScale = (shellOption: IReturnOption | null) => {
    if (!shellOption || this.props.option.noBoundary) {
      return 1;
    }

    const { appSize } = this.props;
    const { screenBounds } = shellOption;
    return Math.min(appSize.width, appSize.height) / Math.min(screenBounds.width, screenBounds.height);
  };

  getPageScale = (shellOption: IReturnOption | null, width: number) => {
    if (!shellOption) {
      return 1;
    }
    return shellOption.defaultScreenSize.width / width;
  };

  getPageDefaultSize = (shellOption: IReturnOption | null, width: number, height: number) => {
    if (!shellOption) {
      return { width, height };
    }
    return { width: shellOption.defaultScreenSize.width, height: shellOption.defaultScreenSize.height };
  };

  get isMobileType() {
    // return ['phone', 'pad'].includes(this.props.appType);
    const { appType } = this.props;
    return isMobileAppType(appType) || isWatchAppType(appType) || isVehicleAppType(appType);
  }

  get isWatch() {
    const { appType } = this.props;
    return isWatchAppType(appType);
  }

  get isVehicle() {
    const { appType } = this.props;
    return isVehicleAppType(appType);
  }

  get shellOption() {
    const {
      option: { mobileType, noBoundary },
    } = this.props;
    const { isLandscape } = this;
    return getShellInfo(noBoundary ? MobileType.None : mobileType!, isLandscape);
  }

  get deviceStyle(): React.CSSProperties {
    const { rotatedAppSize } = this;
    const { page, option, viewPortSize } = this.props;
    const { noBoundary } = option;
    const { mainArtboard } = page.doc;
    const pageSize = { ...mainArtboard.size };
    const defaultScale = this.getDefaultScale(this.shellOption);

    const { offsetX, offsetY, minRight, minBottom } = this.getShellOffset(
      this.isMobileType && !noBoundary ? rotatedAppSize : pageSize,
      this.shellOption,
      defaultScale,
    );

    const deviceSize = this.deviceSize;
    const deviceStyle: React.CSSProperties = {};
    deviceStyle.width = deviceSize.width;
    deviceStyle.height = deviceSize.height;
    deviceStyle.top = offsetY;
    deviceStyle.left = offsetX;
    if (noBoundary) {
      deviceStyle.width = Math.max(deviceStyle.width, minRight);
      deviceStyle.height = Math.max(deviceStyle.height, minBottom);
      deviceStyle.minHeight = 0.5 * deviceSize.height + 0.5 * viewPortSize.viewPortHeight;
      deviceStyle.minWidth = 0.5 * deviceSize.width + 0.5 * viewPortSize.viewPortWidth;
    }
    return deviceStyle;
  }

  get shouldShowShell() {
    return this.shellOption && this.isMobileType && !this.props.option.noBoundary;
  }

  get clientRect() {
    return this.selfRef.current?.getBoundingClientRect();
  }

  get deviceSize(): ISize {
    const { rotatedAppSize } = this;
    const { page, scale, option } = this.props;

    const screenScale = scale / 100;
    const { noBoundary } = option;
    const { mainArtboard } = page.doc;
    const pageSize = { ...mainArtboard.size };

    return {
      width: this.isMobileType && !noBoundary ? rotatedAppSize.width * screenScale : pageSize.width * screenScale,
      height: this.isMobileType && !noBoundary ? rotatedAppSize.height * screenScale : pageSize.height * screenScale,
    };
  }

  renderShell() {
    if (!this.shellOption) {
      return null;
    }
    return this.isLandscape ? this.renderLandscapeShell() : this.renderPortraitShell();
  }

  renderPortraitShell() {
    if (!this.shellOption) {
      return null;
    }

    const { rotatedAppSize } = this;
    const { scale } = this.props;
    const screenScale = scale / 100;
    const defaultScale = this.getDefaultScale(this.shellOption);
    const { screenBounds, shellSize, shellImage, otherScale } = this.shellOption;
    let shellWidth = shellSize.width * defaultScale * screenScale;
    let shellHeight =
      (shellSize.height - screenBounds.height) * defaultScale * screenScale + rotatedAppSize.height * screenScale;

    let bodyHeight = (rotatedAppSize.height - otherScale * shellSize.width * defaultScale) * screenScale;

    // 手表固定尺寸 和车载
    if (this.isWatch || this.isVehicle) {
      shellHeight = shellSize.height * defaultScale * screenScale;
      bodyHeight = shellHeight * otherScale;
    }

    return (
      <div
        className="device-box portrait"
        style={{
          left: -screenBounds.left * defaultScale * screenScale,
          top: -screenBounds.top * defaultScale * screenScale,
          width: shellWidth,
          height: shellHeight,
        }}
      >
        <img className="shell-img-top" alt="img" src={shellImage[0]} width={shellWidth} />
        <img className="shell-img-body" alt="img" src={shellImage[1]} width={shellWidth} height={bodyHeight} />
        <img className="shell-img-bottom" alt="img" src={shellImage[2]} width={shellWidth} />
      </div>
    );
  }

  renderLandscapeShell() {
    if (!this.shellOption) {
      return null;
    }

    const { rotatedAppSize } = this;
    const { scale } = this.props;
    const screenScale = scale / 100;
    const defaultScale = this.getDefaultScale(this.shellOption);
    const { screenBounds, shellSize, shellImage, otherScale } = this.shellOption;

    const shellWidth = ((shellSize.width - screenBounds.width) * defaultScale + rotatedAppSize.width) * screenScale;
    let shellHeight = shellSize.height * defaultScale * screenScale;
    let bodyWidth = (rotatedAppSize.width - otherScale * shellSize.height * defaultScale) * screenScale;
    //  车载
    if (this.isVehicle) {
      bodyWidth = shellWidth * otherScale;
    }

    return (
      <div
        className="device-box landscape"
        style={{
          left: -screenBounds.left * defaultScale * screenScale,
          top: -screenBounds.top * defaultScale * screenScale,
          width: shellWidth,
          height: shellHeight,
        }}
      >
        <img className="shell-img-top" alt="img" src={shellImage[0]} height={shellHeight} />
        <img className="shell-img-body" alt="img" src={shellImage[1]} height={shellHeight} width={bodyWidth} />
        <img className="shell-img-bottom" alt="img" src={shellImage[2]} height={shellHeight} />
      </div>
    );
  }

  render() {
    const { rotatedAppSize, deviceStyle } = this;
    const { page, advancePage, scale, option, zoomByWheel, appType, isStandalone, remarkTagId } = this.props;

    const screenScale = scale / 100;
    const { noBoundary } = option;
    const { mainArtboard } = page.doc;
    const pageSize = { ...mainArtboard.size };
    const showMoileBounday = this.isMobileType && !noBoundary;
    const deviceSize = {
      width: showMoileBounday ? rotatedAppSize.width * screenScale : pageSize.width * screenScale,
      height: showMoileBounday ? rotatedAppSize.height * screenScale : pageSize.height * screenScale,
    };

    const showShell = this.shouldShowShell;
    const showMobileCursor = !!showShell && !RP_CONFIGS.isHuaWei;
    return (
      <div ref={this.selfRef} className="preview-device" style={deviceStyle}>
        <div
          className={classnames('scale-box', {
            'no-boundary': noBoundary,
            mobile: showMobileCursor,
            isPressed: this.state.isPressed,
          })}
          style={{ transformOrigin: '0% 0%', height: deviceSize.height, width: deviceSize.width }}
        >
          <DeviceShell
            option={option}
            pageType="current"
            scale={scale}
            appType={appType}
            zoomByWheel={zoomByWheel}
            page={page}
            remarkTagId={remarkTagId}
            deviceSize={deviceSize}
            isStandalone={isStandalone}
            ref={this.shell}
            devicePosition={this.props.devicePosition}
            showMobileCursor={showMobileCursor}
          />
          {advancePage && (
            <DeviceShell
              option={option}
              appType={appType}
              pageType="next"
              scale={scale}
              zoomByWheel={zoomByWheel}
              page={advancePage}
              deviceSize={deviceSize}
              isStandalone={isStandalone}
              devicePosition={this.props.devicePosition}
              showMobileCursor={showMobileCursor}
            />
          )}
          {showShell && this.renderShell()}
        </div>
      </div>
    );
  }
}

export default PreviewDevice;
