import * as React from 'react';
import { isUndefined, memoize } from 'lodash';

import { parseColorToString, rgba2hex } from '@utils/graphicsUtils';
import { depthClone, isEqualDate } from '@utils/globalUtils';

import { IPathValue, IComponentValue } from '@fbs/rp/models/value';
import { StyleHelper } from '@helpers/styleHelper';
import { IComponentSize } from '@fbs/rp/models/component';
import {
  transformPathDataToPath,
  scalePath,
  isClosedPathWithArea,
  getNewPathPropertiesByScalingTheStrokeAndShadow,
} from '@helpers/pathHelper';

import { DeepBlueColor, GrayColor } from '@consts/colors';
import { IProperties } from '@/fbs/rp/models/property';
import { StrokePosition } from '@/fbs/rp/models/properties/stroke';

import appOptions from '@helpers/appOptions';

import ShapeTextBase, { IShapeTextCompState } from '../common/ShapeTextBase';
import { renderMaskStroke } from '../common/SvgStrokeFragment';
import { renderClipFill } from '../common/GradientFragment';
import { IComponentProps } from '../../types';
import { CCompoundPath } from '../../constants';
import { renderMarker } from '../common/MarkerFragment';

import './index.scss';

interface IPathCompState extends IShapeTextCompState {
  hover?: boolean;
}

class PathItem extends ShapeTextBase<IComponentProps, IPathCompState> {
  private allowStrokeHover = true;

  private size?: IComponentSize;
  private value?: IComponentValue;
  private text?: string;
  private properties?: IProperties;
  private opacity?: number;
  private transition?: string;
  private version: string;

  get pathValue() {
    const value = this.props.comp.value as IPathValue;
    const globalScale = this.props.globalScale || 1;
    return this.getNewPathValue(value, globalScale);
  }

  private memoizeTransformPathDataToPath = memoize(transformPathDataToPath);

  get pathData() {
    return this.memoizeTransformPathDataToPath(this.pathValue);
  }

  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      textStyle: this.parserTextStyle(props),
    };
    const lib = props.comp.libData;
    if (lib?.editor?.onValidateAllowEditor) {
      this.allowStrokeHover = lib.editor.onValidateAllowEditor(props.comp, 'dblClick') === 'path';
    }
    const { size, value, text, properties, opacity, version } = props.comp;
    this.size = size;
    this.value = value as IPathValue;
    this.text = text;
    this.properties = properties;
    this.opacity = opacity;
    this.transition = props.comp.getTransition();
    this.version = version;
  }

  UNSAFE_componentWillReceiveProps(nextProps: IComponentProps) {
    this.setState({
      textStyle: this.parserTextStyle(nextProps),
    });
  }

  shouldComponentUpdate(nextProps: IComponentProps, nextState: IPathCompState) {
    if (nextProps.comp.version !== this.version) {
      this.version = nextProps.comp.version;
      return true;
    }
    if (this.props.selected !== nextProps.selected) {
      return true;
    }
    if (this.props.isPreview !== nextProps.isPreview) {
      return true;
    }
    const compSize = nextProps.comp.size;
    if (!isEqualDate(this.size, compSize)) {
      this.size = depthClone(compSize);
      return true;
    }
    const compValue = nextProps.comp.value;
    if (!isEqualDate(this.value, compValue) || this.props.globalScale !== nextProps.globalScale) {
      this.value = depthClone(compValue) as IPathValue;
      return true;
    }
    const transition = nextProps.comp.getTransition();
    if (!isEqualDate(this.transition, transition)) {
      this.transition = transition;
      return true;
    }
    const compText = nextProps.comp.text;
    if (!isEqualDate(this.text, compText)) {
      this.text = depthClone(compText);
      return true;
    }
    const compProperties = nextProps.comp.properties;
    if (!isEqualDate(this.properties, compProperties)) {
      this.properties = depthClone(compProperties);
      return true;
    }
    const compOpacity = nextProps.comp.opacity;
    if (!isEqualDate(this.opacity, compOpacity)) {
      this.opacity = compOpacity;
      return true;
    }
    if (!isEqualDate(this.state.textStyle, nextState.textStyle)) {
      return true;
    }
    if (!isEqualDate(this.state.hover, nextState.hover)) {
      return true;
    }
    return this.props.valueEditing !== nextProps.valueEditing;
  }

  getNewPathValue(value: IPathValue, globalScale: number) {
    const pathValue = scalePath(value, { x: globalScale, y: globalScale });
    // 缩放圆角
    pathValue.data.forEach((item, i) => {
      const pathPoint = (value as IPathValue).data[i];
      if (pathPoint) {
        item.radius = isUndefined(pathPoint.radius) ? undefined : pathPoint.radius * globalScale;
      }
    });
    return pathValue;
  }

  handleMouseEnter = () => {
    const { isPreview, valueEditing, isParentActivated } = this.props;
    if (!isPreview && !valueEditing && isParentActivated && this.allowStrokeHover) {
      this.setState({ hover: true });
    }
  };

  handleMouseLeave = () => {
    if (this.state.hover) {
      this.setState({ hover: false });
    }
  };

  get newProperties() {
    const globalScale = this.props.globalScale || 1;
    return getNewPathPropertiesByScalingTheStrokeAndShadow(this.props.comp.properties, globalScale);
  }

  get markOpt() {
    const { comp } = this.props;
    const globalScale = this.props.globalScale || 1;
    const { id } = comp;
    // 深拷贝，防止render 死循环
    const properties = this.newProperties;

    const parser = StyleHelper.initSVGStyleParser(properties);
    const svgStroke = parser.getStroke();

    return {
      line: properties.line,
      strokeInfo: svgStroke,
      compID: id,
      scale: globalScale,
    };
  }

  get strokeOpt() {
    const pathValue = this.pathValue;
    const data = this.pathData;
    if (!pathValue || !data) {
      return undefined;
    }
    const { comp } = this.props;
    const globalScale = this.props.globalScale ?? 1;
    const { size, id } = comp;
    // 深拷贝，防止render 死循环
    const properties = this.newProperties;

    const parser = StyleHelper.initSVGStyleParser(properties);
    const svgStroke = parser.getStroke();
    const { stroke, strokeDasharray, strokeWidth, strokeLinecap, strokeLinejoin } = svgStroke;

    const isChildOfPath = comp.parent?.type === CCompoundPath;
    const { hover } = this.state;
    let showStroke = stroke;
    if (isChildOfPath) {
      showStroke = rgba2hex(GrayColor);
    }

    const hovered = hover && !this.props.selected;

    const transition = comp.getTransition();
    const forceCenter = !isClosedPathWithArea(pathValue);
    return {
      id,
      size,
      scale: globalScale,
      stroke: {
        stroke: hovered ? parseColorToString(DeepBlueColor) : showStroke,
        strokeWidth: isChildOfPath ? 1 : strokeWidth,
        strokeDasharray: isChildOfPath ? undefined : strokeDasharray,
        strokeLinecap: strokeLinecap,
        strokeLinejoin: strokeLinejoin,
      },
      data,
      strokePosition:
        isChildOfPath || forceCenter ? StrokePosition.center : properties.stroke?.position || StrokePosition.center,
      showMark: !pathValue.closed,
      transition,
    };
  }

  get fillOpt() {
    const { comp } = this.props;
    const globalScale = this.props.globalScale || 1;
    const { size, id, type } = comp;
    // 深拷贝，防止render 死循环
    const properties = this.newProperties;

    const transition = comp.getTransition();
    return {
      id,
      type,
      size,
      fill: this.isChildOfPath ? undefined : properties.fill,
      transition,
      scale: globalScale,
    };
  }

  get senseWidth() {
    const properties = this.newProperties;
    const parser = StyleHelper.initSVGStyleParser(properties);
    const svgStroke = parser.getStroke();
    const { strokeWidth } = svgStroke;

    const w = strokeWidth || 1;
    let senseWidth = w;
    if (w * appOptions.scale < 10) {
      senseWidth = 10 / appOptions.scale;
    }
    return senseWidth;
  }

  get isChildOfPath() {
    return this.props.comp.parent?.type === CCompoundPath;
  }

  render() {
    const strokeOpt = this.strokeOpt;
    if (!strokeOpt) {
      return;
    }
    const markOpt = this.markOpt;
    const pathValue = this.pathValue;
    const data = this.pathData;

    const { comp, isPreview, isParentActivated } = this.props;
    const globalScale = this.props.globalScale || 1;
    const { id, type, text, size } = comp;
    const properties = this.newProperties;
    const parser = StyleHelper.initSVGStyleParser(properties);
    const fill = parser.getFill(`${type}-fill-${id}`);
    const filter = parser.getShadow();

    const opacity = isUndefined(comp.opacity) ? 1 : comp.opacity / 100;

    const senseWidth = this.senseWidth;

    let transform = `scale(${1 / globalScale})`;
    const transition = comp.getTransition();
    const fillOpt = this.fillOpt;

    const svgStyle: React.CSSProperties = {
      pointerEvents: 'none',
      filter,
      overflow: 'visible',
      top: 0,
      left: 0,
      position: 'absolute',
      transform,
      transformOrigin: 'left top',
      opacity,
      transition,
    };

    if (isPreview) {
      Object.assign(svgStyle, {
        width: size.width || 1,
        height: size.height || 1,
      });
    }
    const fillStyle = {
      transition,
    };
    const canHover = isPreview || !this.allowStrokeHover || !isParentActivated;
    const isChildOfPath = this.isChildOfPath;
    const needShowText = !isChildOfPath;
    const needFill = !!(opacity && properties.fill && !properties.fill.disabled);
    const needStroke = !!(opacity && properties.stroke && !properties.stroke.disabled);
    return (
      <div className="lib-comp-path-item" style={{ ...size }}>
        <svg style={svgStyle} shapeRendering="geometricPrecision">
          {needFill &&
            renderClipFill(
              fillOpt,
              <path
                d={data}
                fill={isChildOfPath ? 'transparent' : fill}
                strokeWidth={0}
                style={fillStyle}
                shapeRendering="geometricPrecision"
              />,
            )}
          {needStroke && renderMaskStroke(strokeOpt)}
          {!pathValue.closed && !isChildOfPath && needStroke && renderMarker(markOpt)}
          {/* TODO 下面这个路径设置一个固定的宽度，用于处理鼠标的感应事件，当无填充的时候，如果路径线条太细，会导致鼠标不容易点击上*/}
          <path
            d={data}
            fill="none"
            strokeWidth={senseWidth}
            stroke="transparent"
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            className={canHover ? undefined : 'sense-path'}
          />
        </svg>
        {needShowText && this.renderTextFragment(text, properties, opacity)}
      </div>
    );
  }
}

export default PathItem;
