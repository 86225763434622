import * as React from 'react';

import { ITableSortOrder, ISortIcon } from '../../../../models';
import { styleColor, TableSortOrder } from '../../../../constants';

import Icon from '../../../Icon';

import './index.scss';

interface IProps {
  order: ITableSortOrder;
  sortIcon?: ISortIcon;
}

const TableStepper: React.FC<IProps> = (props: IProps) => {
  const { order, sortIcon } = props;

  if (sortIcon) {
    return (
      <div className="c-table-stepper">
        {order === TableSortOrder.ASC && sortIcon.Asc && <Icon cls={sortIcon.Asc} />}
        {order === TableSortOrder.DESC && sortIcon.Desc && <Icon cls={sortIcon.Desc} />}
        {order === TableSortOrder.NONE && sortIcon.None && <Icon cls={sortIcon.None} />}
      </div>
    );
  }

  /**
   * svg绘制三角形图标
   */
  const renderIconArrow = (size: React.CSSProperties, path: string, color: string, style?: React.CSSProperties) => {
    const box = '0 0 ' + size.width!.toString() + ' ' + size.height!.toString();
    return (
      <svg width={size.width} height={size.height} style={style} viewBox={box}>
        <path d={path} strokeWidth="0" stroke={color} fill={color} />
      </svg>
    );
  };

  return (
    <div className="c-table-stepper">
      {renderIconArrow(
        { width: 6, height: 4 },
        'M0 4 L3 0 L6 4 Z',
        order === TableSortOrder.ASC ? styleColor.colorDeepBlue : styleColor.colorGrey,
        { marginBottom: 2 },
      )}
      {renderIconArrow(
        { width: 6, height: 4 },
        'M0 0 L3 4 L6 0 Z',
        order === TableSortOrder.DESC ? styleColor.colorDeepBlue : styleColor.colorGrey,
      )}
    </div>
  );
};

export default TableStepper;
