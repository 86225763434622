import { isUndefined } from 'lodash';
import * as sanitizeHtml from 'sanitize-html';

import { depthClone, isEqualDate } from '@utils/globalUtils';
import { isSameColor, parseColorToString, RGBA2HEX, transColorToRGBA } from '@utils/graphicsUtils';

import { default as IFill } from '@fbs/rp/models/properties/fill';
import IStroke from '@fbs/rp/models/properties/stroke';
import ITextFormat from '@fbs/rp/models/properties/text';
import { Color, IGradientColor, PureColor } from '@fbs/rp/models/properties/color';
import { default as ITypography, ITypographyValue } from '@fbs/rp/models/ds/typography';
import { IComponentData } from '@fbs/rp/models/component';
import IColor from '@fbs/rp/models/ds/color';
import { ILibF, ResourceType } from '@fbs/rp/models/ds/lib';
import IComponent from '@fbs/rp/models/ds/component';
import IIcon from '@fbs/rp/models/properties/icon';
import { PropertyValue } from '@fbs/rp/models/property';
import IShadow from '@fbs/rp/models/properties/shadow';
import { IDSGroup } from '@fbs/rp/models/ds/group';

import { DefaultFontFamilys, DefaultFontSize } from '@consts/fonts';
import {
  DefaultIconColor,
  DefaultStrokeColor,
  DefaultTextColor,
  DefaultWhiteFillColor,
  ShadowColor,
} from '@consts/colors';

import { UIComponent, UIContainerComponent } from '@editor/comps';

import { getDefaultComponentName } from '@libs/libs';
import { isRichText, isTextType, isShapeText } from '@libs/helper';
import { CImage, CSnapshot, CText, CParagraph, CSymbol, CGroup, CContentPanelV2 } from '@libs/constants';

function containerColor(list: PureColor[], value: PureColor) {
  return list.some((c) => isSameColor(c, value));
}

export function getColorListFromComponent(comp: UIComponent) {
  const colors: PureColor[] = [];
  if (isRichText(comp.type)) {
    colors.push(...getRichTextColor(comp));
  }
  const properties = comp.properties;
  const keys = Object.keys(properties);
  for (let i = 0, c = keys.length; i < c; i++) {
    const key = keys[i];
    const property = properties[keys[i]];
    if (!property) {
      continue;
    }
    const prop = property.prop || key;
    if (!property.disabled && !property.hidden) {
      let color: PureColor | undefined;
      if (prop === 'fill') {
        const fill = property as IFill;
        if (fill.type === 'solid') {
          color = transColorToRGBA(((property as IFill).color as Color) || DefaultWhiteFillColor);
        } else {
          const gradient = fill.color as IGradientColor;
          gradient.colorStops.forEach((item) => {
            const gradientColorItem = transColorToRGBA(item.color);
            if (!containerColor(colors, gradientColorItem)) {
              colors.push(gradientColorItem);
            }
          });
        }
      } else if (prop === 'stroke') {
        color = transColorToRGBA((property as IStroke).color || DefaultStrokeColor);
      } else if (prop === 'textStyle' || prop === 'textFormat') {
        color = transColorToRGBA((property as ITextFormat).color || DefaultTextColor);
      } else if (prop === 'icon') {
        color = transColorToRGBA((property as IIcon).color || DefaultIconColor);
      } else if (prop === 'color') {
        color = property.value as PureColor;
      } else if (prop === 'shadow') {
        color = transColorToRGBA((property as IShadow).color || ShadowColor);
      }
      if (color && !containerColor(colors, color)) {
        colors.push(color);
      }
    }
  }
  return colors;
}

function parseToDOM(str: string): Node | null {
  let xmlDoc;
  let parser;
  if (!str) {
    return null;
  }
  const text = `<root>${str.replace(/(&nbsp;)/g, '')}</root>`;
  if (window.DOMParser) {
    parser = new DOMParser();
    xmlDoc = parser.parseFromString(text, 'text/xml');
    const root = xmlDoc.getRootNode().firstChild!;
    if (root.firstChild && root.firstChild.nodeName === 'parsererror') {
      return null;
    }
  } else if (window.ActiveXObject) {
    xmlDoc = new window.ActiveXObject('Microsoft.XMLDOM');
    xmlDoc.async = false;
    xmlDoc.loadXML(text);
  } else {
    throw new Error('cannot parse xml');
  }
  return xmlDoc;
}

interface ITextNode {
  style: {};
  parent?: ITextNode;
}

function parserStyleAttributeToStyleProperties(node: Node) {
  // @ts-ignore
  const attribute = node.getAttribute('style');
  const map: ITextFormat = { fontStyle: {} };
  if (attribute) {
    const styleList = (attribute as string).split(';');
    styleList.forEach((item) => {
      if (item && item.trim()) {
        const keyValue = item.split(':');
        const key = keyValue[0].trim();
        const value = keyValue[1].trim();
        switch (key) {
          case 'color':
            map.color = transColorToRGBA(value);
            break;
          case 'font-size':
            map.fontSize = parseInt(value.replace(/\s/g, ''), 10);
            break;
          case 'font-family':
            map.fontFamily = value;
            break;
          case 'font-style':
            map.fontStyle!.italic = value === 'italic';
            break;
          case 'font-weight':
            map.fontStyle!.bold = value === 'bold';
            break;
          case 'text-align':
            break;
          case 'text-decoration-line':
            map.fontStyle!.underline = value.indexOf('underline') !== -1;
            map.fontStyle!.strike = value.indexOf('line-through') !== -1;
            break;
        }
      }
    });
  }
  return map;
}

function getRichTextStyle(comp: UIComponent): ITextFormat | null {
  const value = comp.value as string;
  const xml = parseToDOM(value);
  if (xml && xml.childNodes) {
    const root = xml.childNodes[0];
    if (root.childNodes.length === 1 && root.childNodes[0].nodeName !== '#text') {
      return parserStyleAttributeToStyleProperties(root.firstChild!);
    }
  }
  return null;
}

function getRichTextColor(comp: UIComponent): PureColor[] {
  const value = isTextType(comp.type) ? (comp.value as string) : comp.text;
  if (!value || typeof value !== 'string') {
    return [];
  }
  const xml = value ? parseToDOM(value) : undefined;
  if (!xml) {
    return [];
  }
  const root = xml.getRootNode().firstChild!;
  const nodeList: Node[] = [];
  const flat = (node: Node) => {
    if (node.childNodes) {
      nodeList.push(...Array.from(node.childNodes));
      node.childNodes.forEach(flat);
    }
  };
  flat(root);
  const { textFormat, textStyle } = comp.properties;
  const color = textFormat?.color || textStyle?.color;
  const colorList: PureColor[] = [];
  nodeList.forEach((node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      // @ts-ignore
      const style = node.getAttribute('style') as string;
      if (style) {
        const attributes = style.split(';');
        const colorAttribute = attributes.find((item) => item.indexOf('color') !== -1);
        if (colorAttribute) {
          const colorValue = transColorToRGBA(colorAttribute.split(':')[1].trim());
          if (!containerColor(colorList, colorValue)) {
            colorList.push(colorValue);
          }
        }
      }
    }
  });
  if (color) {
    const colorValue = transColorToRGBA(color);
    if (!containerColor(colorList, colorValue)) {
      colorList.push(colorValue);
    }
  }
  return colorList;
}

export function getTypographyFromComponent(comp: UIComponent): ITypographyValue | undefined {
  let style: ITextFormat | null = null;
  const {
    type,
    value,
    text,
    properties: { textFormat, textStyle },
  } = comp;

  if (comp.isSymbol) {
    return undefined;
  }

  if ((isTextType(type) || isShapeText(type)) && !(textFormat || textStyle)?.disabled) {
    let val: string | undefined = text;
    if (!val && typeof value === 'string') {
      val = value;
    }
    const v = sanitizeHtml(val, {
      allowedTags: [],
      allowedAttributes: {},
    });
    if (!v) {
      return undefined;
    }
  }
  if ([CImage, CSnapshot].includes(type)) {
    return undefined;
  }
  if (comp.type === CText || comp.type === CParagraph) {
    style = getRichTextStyle(comp);
  }
  let compTextStyle: ITextFormat = {};
  const { properties } = comp;
  const keys = Object.keys(properties);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const prop = properties[key];
    if (!prop) {
      continue;
    }
    if (!prop.hidden && !prop.disabled && ['textStyle', 'textFormat'].includes(prop.prop || key)) {
      compTextStyle = prop as ITextFormat;
    }
  }
  const propFontStyle = compTextStyle.fontStyle || {};
  if (style) {
    const richFontStyle = style.fontStyle || {};
    return {
      family: style.fontFamily || compTextStyle.fontFamily || '',
      size: style.fontSize || compTextStyle.fontSize || DefaultFontSize,
      bold: !isUndefined(richFontStyle.bold) ? richFontStyle.bold : propFontStyle.bold || false,
      italic: !isUndefined(richFontStyle.italic) ? richFontStyle.italic : propFontStyle.italic || false,
      strike: !isUndefined(richFontStyle.strike) ? richFontStyle.strike : propFontStyle.strike || false,
      underline: !isUndefined(richFontStyle.underline) ? richFontStyle.underline : propFontStyle.underline || false,
      color: style.color
        ? parseColorToString(style.color)
        : parseColorToString(compTextStyle.color || DefaultTextColor),
    };
  } else {
    return {
      family: compTextStyle.fontFamily || '',
      size: compTextStyle.fontSize || DefaultFontSize,
      bold: !!propFontStyle.bold,
      italic: !!propFontStyle.italic,
      underline: !!propFontStyle.underline,
      strike: !!propFontStyle.strike,
      color: parseColorToString(compTextStyle.color || DefaultTextColor),
    };
  }
}

export function getComponentDataFromComponent(comp: UIComponent): Partial<IComponentData> {
  const data = depthClone(comp.toJSON()) as Partial<IComponentData>;
  delete data.position;
  delete data.v;
  delete data._currentState;
  delete data.locked;
  delete data.interaction;
  delete data.alias;
  delete data.name;
  delete data._animation;
  delete data._scale;
  delete data.connectors;
  return data;
}

function getPureComponentData(data: IComponentData): IComponentData {
  const result: IComponentData = {
    ...data,
    _id: '',
    interaction: {},
    connectors: [],
    name: '',
    v: 0,
    hidden: undefined,
    rotate: data.rotate || 0,
    position: { x: 0, y: 0 },
    symbol: undefined,
  };

  const fn = (comp: IComponentData) => {
    //@ts-ignore
    comp.allowReplace = undefined;
    comp.components?.forEach(fn);
  };
  fn(result);
  return result;
}

export function hasResource(
  value: PureColor[] | ITypographyValue | Partial<IComponentData>,
  type: ResourceType,
  list: (IColor | ITypography | IComponent)[],
) {
  if (type === ResourceType.color) {
    const colors = list.map((item) => item.value as PureColor);
    return (value as Array<PureColor>).every((color) => {
      return containerColor(colors, color);
    });
  } else {
    return list.some((item) => {
      if (type === ResourceType.component) {
        if (RP_CONFIGS.isHuaWei) {
          return false;
        }
        let comp = (item as IComponent).value;
        if (comp.type === CSymbol) {
          if (comp.components?.length === 1) {
            comp = comp.components[0];
          } else {
            comp = {
              ...comp,
              type: CGroup,
            };
          }
        }
        const targetValue = getPureComponentData(comp);
        const sourceValue = getPureComponentData(value as IComponentData);
        return isEqualDate(sourceValue, targetValue);
      }
      return isEqualDate(value, item.value);
    });
  }
}

//是否包含新内容画板组件
export function hashContainContentPanelV2Comp(comps: (UIComponent | IComponentData)[]): boolean {
  return comps.some((comp: UIComponent | IComponentData) => {
    if (comp.type === CContentPanelV2) {
      return true;
    }
    //如果包含子节点
    if ((comp as UIContainerComponent).components && (comp as UIContainerComponent).components.length) {
      return hashContainContentPanelV2Comp((comp as UIContainerComponent).components);
    }
    return false;
  });
}

export function getAllComponentResource(lib: ILibF): IComponent[] {
  return lib.components.reduce((acc, curr) => {
    acc.push(...(curr.children || []));
    return acc;
  }, [] as IComponent[]);
}

export function getDifferentColors(source: PureColor[], list: IColor[]) {
  const colors = list.map((item) => item.value as PureColor);
  return source.filter((color) => {
    return !containerColor(colors, color);
  });
}

export function hasProperty(comps: UIComponent[], propertyName: string): boolean {
  const containerProperty = (comp: UIComponent) => {
    const { properties } = comp;
    const keys = Object.keys(properties);
    if (keys.includes(propertyName)) {
      return true;
    }
    for (let i = 0, c = keys.length; i < c; i++) {
      const prop = properties[keys[i]]!;
      if (prop.prop === propertyName) {
        return true;
      }
    }
    return false;
  };
  return comps.some((comp) => containerProperty(comp));
}

export function getProperty(comps: UIComponent[], propertyName: string) {
  let original: PropertyValue | null = null;
  let extension: PropertyValue | null = null;
  let extensionPropertyName: string = propertyName;

  for (let i = 0; i < comps.length; i++) {
    const { properties } = comps[i];
    const keys = Object.keys(properties);
    for (let j = 0; j < keys.length; j++) {
      const key = keys[j];
      const property = properties[key]!;
      if (key === propertyName) {
        original = property;
        break;
      } else if (property.prop === propertyName) {
        if (!extension) {
          extension = property;
          extensionPropertyName = key;
        }
      }
    }
    if (original) {
      break;
    }
  }

  return {
    propertyName: original ? propertyName : extensionPropertyName,
    value: original || extension,
  };
}

export function getDefaultResourceName(
  data: PureColor | ITypographyValue | IComponentData,
  type: ResourceType,
): string {
  switch (type) {
    case ResourceType.component: {
      const compData = data as IComponentData;
      return compData.name || getDefaultComponentName(compData.type, compData.lib);
    }
    case ResourceType.typography:
      return DefaultFontFamilys.find((item) => item.id === (data as ITypographyValue).family)!.text;
    case ResourceType.color:
      return RGBA2HEX(data as PureColor);
    default:
      return '';
  }
}

export function getExecuteCommandName(key: keyof ITypographyValue | string) {
  let cmd = key as string;
  if (key === 'size') {
    cmd = 'fontSize';
  } else if (key === 'color') {
    cmd = 'foreColor';
  } else if (key === 'strike') {
    cmd = 'strikeThrough';
  } else if (key === 'family') {
    cmd = 'fontName';
  }
  return cmd;
}

export function getCSSAttributeName(key: 'italic' | 'bold' | 'underline' | 'strike') {
  switch (key) {
    case 'italic':
      return 'font-style';
    case 'bold':
      return 'font-weight';
    case 'underline':
      return 'text-decoration-line';
    case 'strike':
      return 'text-decoration-line';
  }
}

export function getCSSAttributeValue(key: 'italic' | 'bold' | 'underline' | 'strike') {
  switch (key) {
    case 'italic':
      return 'italic';
    case 'bold':
      return 'bold';
    case 'underline':
      return 'underline';
    case 'strike':
      return 'line-through';
    default:
      break;
  }
  return '';
}

export function getComponentByID(
  componentID: string,
  options: {
    groups?: IDSGroup[];
    library?: ILibF;
    components?: IComponent[];
    libraries?: ILibF[];
    libID?: string;
  },
): IComponent | undefined {
  const { groups, library, libraries, libID, components } = options;
  if (groups) {
    return getComponentFormGroups(componentID, groups);
  }
  if (library) {
    return getComponentFormGroups(componentID, library.components);
  }
  if (components) {
    return components.find((c) => c._id === componentID);
  }
  if (libraries) {
    // libID可有可无，有libID要相对快一些
    if (libID) {
      const groups = libraries.find((lib) => lib._id === libID)?.components;
      return groups ? getComponentFormGroups(componentID, groups) : undefined;
    }
    return libraries.reduce((component, lib) => {
      return component ? component : getComponentFormGroups(componentID, lib.components);
    }, undefined as IComponent | undefined);
  }
}

function getComponentFormGroups(componentID: string, groups: IDSGroup[]) {
  return groups.reduce((component, group) => {
    return component ? component : group.children.find((c) => c._id === componentID);
  }, undefined as IComponent | undefined);
}

/**
 * 是否能够使用symbol
 */
export function canUseSymbol() {
  // return !RP_CONFIGS.isPrivateDeployment;
  // const { isPrivateDeployment, isHuaWei } = RP_CONFIGS;
  // return isHuaWei || !isPrivateDeployment;
  return true;
}

/**
 * 是否能够编辑|删除|添加|重命名|移动|管理资源
 * 是否能够添加|删除|重命名|分组
 * @param lib
 * @param appID
 */
export function canEditResource(lib: ILibF, appID: string) {
  return lib.appID === appID;
  // return lib.app?._id === appID;
}

export function buildResourceLibs(comps: IComponent[]) {
  const libs: ILibF[] = [];
  const libMap: { [id: string]: ILibF } = {};
  const base = {
    _id: '',
    appID: '',
    appName: '',
    teamID: '',
    name: '',
    userID: 0,
    state: 0,
  };

  comps.forEach((comp) => {
    const { libID } = comp;
    let lib = libMap[libID];
    if (!lib) {
      lib = {
        ...base,
        _id: libID,
        colors: [],
        typographies: [],
        components: [
          {
            ...base,
            libID,
            index: 0,
            children: [],
          },
        ],
      };
      libMap[libID] = lib;
    }
    lib.components[0].children.push(comp);
  });

  Object.values(libMap).forEach((lib) => {
    libs.push(lib);
  });

  return libs;
}
