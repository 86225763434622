import { PureColor } from '@fbs/rp/models/properties/color';

export const PrimaryColor: PureColor = { r: 24, g: 144, b: 255, a: 1 };
export const LinkColor: PureColor = { r: 24, g: 144, b: 255, a: 1 };
export const SuccessColor: PureColor = { r: 82, g: 196, b: 26, a: 1 };
export const WarningColor: PureColor = { r: 250, g: 173, b: 20, a: 1 };
export const ErrorColor: PureColor = { r: 255, g: 77, b: 79, a: 1 };
export const HeadingColor: PureColor = { r: 0, g: 0, b: 0, a: 0.85 };
export const TextColor: PureColor = { r: 0, g: 0, b: 0, a: 0.55 };
export const TextSecondDaryColor: PureColor = { r: 0, g: 0, b: 0, a: 0.45 };
export const DisabledColor: PureColor = { r: 0, g: 0, b: 0, a: 0.25 };

//#f5f5f5
export const DisabledFillColor: PureColor = { r: 245, g: 245, b: 245, a: 1 };
//#d9d9d9
export const BorderColor: PureColor = { r: 217, g: 217, b: 217, a: 1 };
export const BoxShadowColor: PureColor = { r: 0, g: 0, b: 0, a: 0.12 };
// #FFFFFF
export const WhiteFillColor: PureColor = { r: 255, g: 255, b: 255, a: 1 };
// #CCCCCC
export const BackgroundColor: PureColor = { r: 204, g: 204, b: 204, a: 1 };

//按钮
export const PrimaryHoverColor: PureColor = { r: 64, g: 169, b: 255, a: 1 };
export const PrimaryPressedColor: PureColor = { r: 9, g: 109, b: 217, a: 1 };
export const ErrorHoverColor: PureColor = { r: 255, g: 120, b: 117, a: 1 };
export const ErrorPressedColor: PureColor = { r: 217, g: 54, b: 62, a: 1 };

//警告框
export const SuccessAlertFillColor: PureColor = { r: 246, g: 255, b: 237, a: 1 };
export const SuccessAlertBorderColor: PureColor = { r: 183, g: 235, b: 143, a: 1 };
export const WarnAlertFillColor: PureColor = { r: 255, g: 251, b: 230, a: 1 };
export const WarnAlertBorderColor: PureColor = { r: 255, g: 229, b: 143, a: 1 };
export const InfoAlertFillColor: PureColor = { r: 230, g: 247, b: 255, a: 1 };
export const InfoAlertBorderColor: PureColor = { r: 145, g: 213, b: 255, a: 1 };
export const ErrorAlertFillColor: PureColor = { r: 255, g: 242, b: 240, a: 1 };
export const ErrorAlertBorderColor: PureColor = { r: 255, g: 204, b: 199, a: 1 };

//Toast-icon-color
export const ToastIconColorError = '#fe4066';
export const ToastIconColorSuccess = '#1bbe0a';
export const ToastIconColorRedWarning = '#f8af1d';
