import { isEmpty } from 'lodash';
import { PagePatches, reversePagePatches } from '@/fbs/rp/utils/patch';
import { mergePatches, getEmptyArtboardPatchesOfArtboardSelf } from '@/helpers/patchHelper';
import { getContentPanelRelationFragments } from '@/helpers/contentPanelv2Helper';
import { CContentPanel, CContentPanelV2 } from '@libs/constants';

import { BaseCommand } from './Command';
import { UIFragment } from '../comps';
import CoreEditor from '../core';

interface ActionInfo {
  patch: PagePatches;
  // TODO: 目前未启用下面两个属性，
  // 要启用的话，得先解决 add artboard patch 的异步问题
  active?: {
    from: string;
    to: string;
  };
  select?: {
    fragment: string;
    component: string[];
  };
}

export class BatchRemoveFragmentCommand extends BaseCommand {
  constructor(private editor: CoreEditor, private fragments: UIFragment[], private relatingPatch?: PagePatches) {
    super();
  }

  actionInfo: ActionInfo | undefined;

  action() {
    const { editor, fragments, relatingPatch = {} } = this;

    // NOTE:
    // 多选画板删除时，激活画板一定在其中，
    // 不用和删除单个画板时一样添加 deletingArtboard

    this.updateContentPanelsVersion();
    //查询包含的新内容画板，关联的辅助画板
    const contentPanelRelatingFragments = getContentPanelRelationFragments(fragments, editor.doc);
    //删除画板
    const batchRemoveFragmentPatches = fragments
      .concat(contentPanelRelatingFragments)
      .reduce((prevPatches, { artboardID }) => {
        return mergePatches(prevPatches, editor.removeArtboardPatches(artboardID)['ROOT']);
      }, getEmptyArtboardPatchesOfArtboardSelf());
    //如果关联patch存在Root,则需要合并一下
    if (relatingPatch['ROOT']) {
      mergePatches(batchRemoveFragmentPatches, relatingPatch['ROOT']);
    }
    const patches: PagePatches = { ...relatingPatch, ROOT: batchRemoveFragmentPatches };
    editor.new_update(patches);

    const actionInfo: ActionInfo = {
      patch: patches,
    };

    const activeArtboardIsRemoved = fragments.find(({ artboardID }) => artboardID === editor.activeArtboard.artboardID);
    if (activeArtboardIsRemoved) {
      editor.setActiveArtboard(editor.doc.mainArtboard);
      editor.clearSelected();
    }

    editor.clearSelectedFragments();

    return actionInfo;
  }

  execute() {
    const actionInfo = this.action();
    if (!actionInfo) {
      return;
    }

    this.actionInfo = actionInfo;
    this.editor.pushUndoStack(this);
    this.editor.clearRedoStack();
  }

  undo() {
    if (!this.actionInfo) {
      return;
    }
    const {
      editor,
      actionInfo: { patch },
    } = this;

    editor.pushRedoStack(this);

    this.updateContentPanelsVersion();

    const undoPatches = reversePagePatches(patch);
    editor.new_update(undoPatches);
  }

  redo() {
    if (!this.actionInfo) {
      return;
    }

    const {
      editor,
      actionInfo: { patch },
    } = this;

    editor.pushUndoStack(this);

    this.updateContentPanelsVersion();

    editor.new_update(patch);
  }

  updateContentPanelsVersion() {
    const { editor, fragments } = this;

    // 更新内容面板
    const contentPanels = editor.doc.getComponentsByFilter((comp) => {
      if ((comp.type !== CContentPanel && comp.type !== CContentPanelV2) || isEmpty(comp.value)) {
        return false;
      }

      const contentPanelOwnerArtboardIsNotRemoved = !fragments.find(
        ({ artboardID }) => comp.ownerArtboardID === artboardID,
      );
      const contentPanelValueContainsRemovedFragment = fragments.some(({ artboardID }) => {
        return (comp.value as string[]).includes(artboardID);
      });

      return contentPanelOwnerArtboardIsNotRemoved && contentPanelValueContainsRemovedFragment;
    });

    contentPanels.forEach((comp) => {
      comp.toJSON().v += 1;
    });
  }
}
