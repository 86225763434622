import * as React from 'react';

import { copy } from '../../..//utils';
import i18n from '../../../../i18n';

import Input from '../../../Input';
import Button from '../../../Button';
import Message from '../../../Message';
import ReceivedForeverRecord from './ReceivedForeverRecord';

import './index.scss';

interface IInvitationProps {
  domain?: string;
  inviteCode: string;
  teamName: string;
  sendInvitation(emails: string[], token: string): void;
  getReceivedForeverRecord(cb: Function): void;
}

const Invitation: React.FC<IInvitationProps> = (props: IInvitationProps) => {
  const { inviteCode, teamName, getReceivedForeverRecord } = props;

  const [copySuccess, setCopySuccess] = React.useState(false);

  const getLink = () => {
    if(props.domain){
      return props.domain;
    }
    if (window.location.origin) {
      return window.location.origin;
    } else {
      return window.location.protocol +'//'+ window.location.host;
    }
  };

  const handleCopy = (link: string) => {
    if (link) {
      const copyText = i18n('rpActivity.invitation.copyText', link);
      const copyResult = copy(copyText);
      copyResult && setCopySuccess(true);
    }
  };

  const getInvitationLink = () => {
    return inviteCode ? getLink() + `/team/invitation/rpFree/${inviteCode}` : '';
  };

  const invitationLink = getInvitationLink();

  return (
    <div className="rp-free-invitation">
      <div className="invitation-link-info">
        <div className="title">{i18n('rpActivity.invitation.linkInvitation')}</div>
        <div className="invitation-link-wrap">
          <span className="invitation-link">
            <Input value={invitationLink} onChange={() => {}} onSubmit={() => handleCopy(invitationLink)} />
          </span>
          <span onClick={() => handleCopy(invitationLink)} className="button">
            <Button>{i18n('rpActivity.invitation.copyLink')}</Button>
          </span>
        </div>
        <div className="desc">{i18n('rpActivity.invitation.inviteLinkDesc', teamName)}</div>
      </div>
      <ReceivedForeverRecord getReceivedForeverRecord={getReceivedForeverRecord} />
      {copySuccess && (
        <Message message={i18n('rpActivity.invitation.copySuccess')} onClose={() => setCopySuccess(false)} />
      )}
    </div>
  );
};

export default Invitation;
