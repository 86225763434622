export type ThirdPartPlatform = {
  name: string;
  getPlayConfig: (
    url: string,
    options: { controls: boolean },
  ) => { src: string; iframeConfig: { [key: string]: string | number } };
};
export const FILE_EXTENSIONS = ['.mp4', '.webm', '.3gp', '.m4v'];
export const VALID_FILE_TYPE = ['video/mp4', 'video/webm', 'video/3gpp', 'video/x-m4v'];
export const THIRD_PART_PLATFORM: ThirdPartPlatform[] = [
  {
    name: 'youku',
    getPlayConfig(url: string) {
      const match = url.match(/id_([^/]+)\.html/);
      let src = url;
      if (match) {
        src = `https://player.youku.com/embed/${match[1]}`;
      }
      return {
        src: src,
        iframeConfig: {},
      };
    },
  },
  {
    name: 'bilibili',
    getPlayConfig(url: string) {
      const match = new URL(url).pathname.match(/video\/(.+?)(?:\/|\?|$)/);
      let src = url;
      if (match) {
        src = `https://player.bilibili.com/player.html?bvid=${match[1]}`;
      }
      return {
        src: src,
        iframeConfig: {
          sandbox: 'allow-top-navigation allow-same-origin allow-forms allow-scripts',
        },
      };
    },
  },
  {
    name: 'qq',
    getPlayConfig(url: string) {
      const match = url.match(/([^/]+)\.html$/);
      let src = url;
      if (match) {
        src = `https://v.qq.com/txp/iframe/player.html?vid=${match[1]}`;
      }
      return {
        src: src,
        iframeConfig: {},
      };
    },
  },
  {
    name: 'youtu',
    getPlayConfig(url: string, options) {
      const match = url.match(/([^/]+)$/);
      let src = url;
      if (match) {
        const params = [!options.controls ? '?controls=0' : ''].filter((v) => v !== '');
        src = `https://www.youtube.com/embed/${match[1]}${params.length ? '?' + params.join('&') : ''}`;
      }
      return {
        src: src,
        iframeConfig: {
          allow: 'accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
        },
      };
    },
  },
  {
    name: 'vimeo',
    getPlayConfig(url: string) {
      const match = url.match(/([^/]+)$/);
      let src = url;
      if (match) {
        const params = ['h=' + Date.now().toString(16)].filter((v) => v !== '');
        src = `https://player.vimeo.com/video/${match[1]}${params.length ? '?' + params.join('&') : ''}`;
      }
      return {
        src: src,
        iframeConfig: {},
      };
    },
  },
];

/**
 * 根据路径获取所属平台
 * @param url string
 */
export const getThirdPartPlatformByUrl = (url: string): ThirdPartPlatform | null => {
  if (typeof url !== 'string') {
    return null;
  }
  try {
    const urlInfo = new URL(url);
    const item = THIRD_PART_PLATFORM.find((d) => urlInfo.hostname.indexOf(d.name + '.') !== -1);
    if (item) {
      return item;
    }
  } catch (e) {
    console.warn(e);
  }
  return null;
};
