import { curry, defaultTo } from 'lodash';

import {
  ArtboardOperations,
  ArtboardPatches,
  ComponentOperations,
  ComponentPatches,
  Operation,
} from '@fbs/rp/utils/patch';
import { ComponentPatchesClass } from '@editor/patches/ComponentPatches';

export class ArtboardPatchesClass {
  private _do: ArtboardOperations;
  private _undo: ArtboardOperations;
  constructor(patches?: ArtboardPatches) {
    this._do = defaultTo(patches?.do, {});
    this._undo = defaultTo(patches?.undo, {});
  }

  get do() {
    return this._do;
  }

  get undo() {
    return this._undo;
  }

  /**
   *  合并新的 patches,对于有冲突的comp 的数据修改,一定优先应用新的 patches 中的修改;
   * @param newPatches
   */
  coverPatches(newPatches: ArtboardPatches) {
    for (let CompIdInNewPatches in newPatches.do) {
      const doOpsInNewPatches = newPatches.do[CompIdInNewPatches];
      const doOpsInOldPatches = this.do[CompIdInNewPatches];
      const isOpNotExitedInDoOpsInNewPatches = isOpNotExitedInComponentOps(doOpsInNewPatches);
      this.do[CompIdInNewPatches] = isCompExistedInDoOfPatches(this, CompIdInNewPatches)
        ? doOpsInOldPatches.filter(isOpNotExitedInDoOpsInNewPatches)
        : [];

      const undoOpsInNewPatches = newPatches.undo[CompIdInNewPatches];
      const undoOpsInOldPatches = this.undo[CompIdInNewPatches];
      const isOperationNotExitedInUnDoOpsInNewPatches = isOpNotExitedInComponentOps(undoOpsInNewPatches);
      this.undo[CompIdInNewPatches] = isCompExistedInUndoOfPatches(this, CompIdInNewPatches)
        ? undoOpsInOldPatches.filter(isOperationNotExitedInUnDoOpsInNewPatches)
        : [];

      // 这里是把新的 patches 中的所有操作都合并过来
      this.do[CompIdInNewPatches].push(...doOpsInNewPatches);
      this.undo[CompIdInNewPatches].push(...undoOpsInNewPatches);
    }
    return this;
  }

  isCompExistedInPatches(compId: string) {
    return this.do[compId];
  }
  getPatchesByCompChange(compId: string, componentPatches: ComponentPatches): ArtboardPatches {
    return {
      do: {
        [compId]: [...componentPatches.do],
      },
      undo: {
        [compId]: [...componentPatches.undo],
      },
    };
  }
  getAttrChangePatches(compId: string, path: string, valueChange: { oldVal: any; newVal: any }) {
    const componentPatches = new ComponentPatchesClass().getAttrChangePatches(compId, path, {
      oldVal: valueChange.oldVal,
      newVal: valueChange.newVal,
    });
    this.coverPatches(this.getPatchesByCompChange(compId, componentPatches));
    return this;
  }
  /**
   * 在当前的patches查找是否有满足fn的operation
   * @param fn
   */
  findOperationSuit(fn: (ops: Operation) => boolean): boolean {
    const doOps = this._do;
    //将patches中所有的 operation 组成一维数组然后用于 fn 做判断
    return Object.values(doOps)
      .reduce((acc, ops) => {
        acc = acc.concat(ops);
        return acc;
      }, [] as Operation[])
      .some(fn);
  }
}
export function isCompExistedInDoOfPatches(patches: ArtboardPatches, compId: string) {
  return Boolean(patches.do[compId]);
}

function isCompExistedInUndoOfPatches(patches: ArtboardPatches, CompIdInNewPatches: string) {
  return Boolean(patches.undo[CompIdInNewPatches]);
}

const isOpNotExitedInComponentOps = curry((componentOperations: ComponentOperations, operation: Operation) => {
  return !componentOperations.some((operationInComponentOperations) => {
    return operationInComponentOperations.path === operation.path;
  });
});
