// 飞书客户端相关
export const larkPC = window.navigator.userAgent.indexOf('LarkLocale') !== -1;
export const larkAppLink = 'https://applink.feishu.cn';

export const LARK_TITLE_HEIGHT = 37;

/**
 * 是否嵌入于飞书的单页演示模块
 * @return {boolean}
 */
export const needShowLarkRefreshTitle = () => {
  // 未嵌入飞书
  if (!larkPC) {
    return false;
  }
  // 未嵌入其它的iframe
  if (!window.frameElement) {
    // RP的单独演示时，认为是单页演示模式
    return ['run', 'editor'].some((item) => window.location.pathname.includes(`/${item}/`));
    // return window.location.pathname.includes(`/run/`);
  }
  // 无父窗口
  const path = window.parent?.location.pathname;
  if (!path) {
    return false;
  }
  // 单页模式时及其它演示场景嵌入到飞书中时
  return !['preview', 'specs', 'run', 'standalone'].some((item) => path.includes(`/${item}/`));
};

// export const larkTitleOffset = () => {
//   if (needShowLarkRefreshTitle()) {
//     return LARK_TITLE_HEIGHT;
//   }
//   return 0;
// };
