import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { getShapeData, DefaultFlowShapeSize, flowBase, getShapePropertiesData } from './shapeDataFragment';

// '十字'
export default {
  ...flowBase,
  name: i18n('resource.flow.cross'),
  type: 'cross',
  thumb: {
    spriteIconClass: SpriteThumb.Cross.className,
    dragPosition: SpriteThumb.Cross.position,
  },
  template: getShapeData(i18n('resource.flow.cross'), DefaultFlowShapeSize.size60_60, {
    data: [
      {
        point: {
          x: 20,
          y: 0,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 20,
          y: 20,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 0,
          y: 20,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 0,
          y: 40,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 20,
          y: 40,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 20,
          y: 60,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 40,
          y: 60,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 40,
          y: 40,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 60,
          y: 40,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 60,
          y: 20,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 40,
          y: 20,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 40,
          y: 0,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
    ],
    closed: true,
  }),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
