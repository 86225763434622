import { IBounds, IRectOptions, ITextStyle } from '../type';
import { makeTextForeignObject } from './makeForeignObject';
import { toSVGTransform } from './makeTransform';
import { makeTransform } from './makeTransform';

export const makeRect = async (bounds: IBounds | undefined, options: IRectOptions): Promise<string> => {
  if (bounds) {
    const {
      type,
      rx,
      style,
      stroke,
      strokeWidth,
      fillId,
      position,
      rotate,
      size,
      isRoot,
      outerBounds,
      matrix,
    } = options;
    const fill = fillId ? `fill="url(#${fillId})"` : 'fill="transparent"';

    let transform = '';
    if (matrix) {
      transform = toSVGTransform(matrix);
    } else {
      transform = makeTransform({ position, rotate, size, isRoot, outerBounds });
    }

    const rect = `<rect x="${bounds?.x}" y="${bounds?.y}" width="${bounds?.width}" height="${bounds?.height}"
        ${rx}${style} ${fill}  ${stroke} stroke-width="${strokeWidth}" ${transform} class="${type}"/>
        `;
    return rect;
  }
  return '';
};

export const makeTextInRect = (
  text?: string,
  textStyle?: ITextStyle,
  bounds?: IBounds,
  transform?: string,
): Promise<string> => {
  return makeTextForeignObject(text, textStyle, bounds, transform);
};
