import * as React from 'react';
import { isNumber, isUndefined } from 'lodash';

import { calcValueFromText } from '@/utils/globalUtils';
import { ComponentTheme } from '../common';
import Input from '../Input';
import KeyCodeMap from '@/dsm2/constants/KeyCodeMap';

import './index.scss';

interface ICalcInputProps {
  value: number | undefined;
  step?: number;
  placeHolder?: string;
  max?: number;
  min?: number;
  width?: number | string;
  readOnly?: boolean;
  disabled?: boolean;
  // 是否在创建后立即自动获取到焦点
  autoFocus?: boolean;
  // 是否在得到焦点时自动选中所有内容
  autoSelectWhenFocus?: boolean;
  allowAutoFixMinValue?: boolean;
  them?: 'normal' | 'no-border';
  colorTheme?: ComponentTheme;
  className?: string;
  style?: React.CSSProperties;
  // 小数点长度
  decimalCount?: number;
  prefix?: string;
  suffix?: string;

  onChange?: (value: number) => void;
  onBlur?: (value: number) => void | boolean;
  onEnter?: (value: number) => void;
  onFocus?: (e: React.FocusEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  onKeyUp?: (e: React.KeyboardEvent) => void;
}

interface ICalcInputStates {
  data: string | undefined;
}

export default class CalcInput extends React.PureComponent<ICalcInputProps, ICalcInputStates> {
  private input: React.RefObject<Input> = React.createRef();

  constructor(props: ICalcInputProps) {
    super(props);
    this.state = {
      data: this.parseNumberToStr(props.value, props.decimalCount),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: ICalcInputProps) {
    const unequalProp = nextProps.value !== this.props.value;
    const isBlur = !this.input.current?.focused;
    const unequalValue = this.input.current?.domValue !== nextProps.value;
    if (unequalProp || (isBlur && unequalValue)) {
      this.updateState(nextProps.value, nextProps.decimalCount);
    }
  }

  // 更新输入框value, 这里不要判断新老state是否变化，这里会修改上一步的setState
  updateState = (value: number | undefined, decimalCount: number | undefined) => {
    const newData = this.parseNumberToStr(value, decimalCount);
    this.setState({
      data: newData,
    });
  };

  doBlur = (value: number) => {
    const { value: propValue, decimalCount, onBlur } = this.props;
    if (onBlur) {
      const result = onBlur(value);
      if (result === false) {
        this.updateState(propValue, decimalCount);
      }
    }
  };

  /**
   * 数字转字符串，处理小数点位数
   */
  parseNumberToStr = (value?: number, decimalCount?: number) => {
    return isUndefined(value) ? undefined : this.keepDecimal(value, decimalCount).toString(10);
  };
  /**
   * 输入框结果字符串转数字
   */
  doValidata = (str: string) => {
    const { value } = this.props;
    let data = calcValueFromText(str);
    if (isUndefined(data)) {
      return value;
    }
    if (!isFinite(data)) {
      return value;
    }
    return this.validateNumber(data);
  };

  /**
   * 处理数据符合规则
   */
  private validateNumber = (value: number): number => {
    const { max, min, decimalCount } = this.props;
    let newData = value;
    if (isNumber(max)) {
      newData = Math.min(max, newData);
    }
    if (isNumber(min)) {
      newData = Math.max(min, newData);
    }
    newData = this.keepDecimal(newData, decimalCount);
    return newData;
  };

  /**
   * 处理小数位数
   */
  keepDecimal = (value: number, decimalCount: number = 0) => {
    if (value > 0) {
      return Math.floor(value * Math.pow(10, decimalCount)) / Math.pow(10, decimalCount);
    } else {
      return Math.ceil(value * Math.pow(10, decimalCount)) / Math.pow(10, decimalCount);
    }
  };

  /**
   * 键盘按下，处理上下方向增减
   */
  handleKeyDown = (e: React.KeyboardEvent) => {
    const { onKeyDown, value, step, min, max } = this.props;
    const { keyCode, target } = e;
    const { value: inputValue } = target as HTMLInputElement;
    let newNum: number | undefined = parseFloat(inputValue);

    if (!isFinite(newNum)) {
      newNum = value;
    }
    if (isUndefined(newNum)) {
      if (isNumber(min)) {
        newNum = min;
      } else {
        newNum = Math.min(0, max || 0);
      }
    }
    if ([KeyCodeMap.VK_UP, KeyCodeMap.VK_DOWN].includes(keyCode)) {
      if (keyCode === KeyCodeMap.VK_UP) {
        newNum += step || 1;
        newNum = this.validateNumber(newNum);
      } else if (keyCode === KeyCodeMap.VK_DOWN) {
        newNum -= step || 1;
        newNum = this.validateNumber(newNum);
      }
      const newData = this.parseNumberToStr(newNum);
      if (newData !== this.state.data) {
        this.setState({
          data: newData,
        });
      }
    }
    onKeyDown && onKeyDown(e);
  };

  // FIXME:
  handleBlur = (value: string) => {
    const { decimalCount, allowAutoFixMinValue, min } = this.props;

    if (allowAutoFixMinValue && value === '') {
      this.setState(
        {
          data: `${isNumber(min) && min > 0 ? min : 0}`,
        },
        () => {
          this.doBlur && this.doBlur(parseInt(this.state.data!));
        },
      );
      return;
    }

    const data = this.doValidata(value);
    const dataStr = this.parseNumberToStr(data, decimalCount);
    this.setState({ data: dataStr });
    this.input.current?.refreshDisplayValue(dataStr);
    if (!isUndefined(data)) {
      this.doBlur(data);
    }
  };

  handleChange = (value: string) => {
    const { onChange } = this.props;
    const data = this.doValidata(value);
    if (!isUndefined(data)) {
      onChange && onChange(data);
    }
  };

  handleEnter = (value: string) => {
    const { onEnter, decimalCount, allowAutoFixMinValue, min } = this.props;

    if (allowAutoFixMinValue && value === '') {
      this.setState(
        {
          data: `${isNumber(min) && min > 0 ? min : 0}`,
        },
        () => {
          onEnter && onEnter(parseInt(this.state.data!));
        },
      );
      return;
    }

    const data = this.doValidata(value);
    const dataStr = this.parseNumberToStr(data, decimalCount);

    this.setState({ data: dataStr });
    if (!isUndefined(data)) {
      onEnter && onEnter(data);
    }
  };

  // handleCheck = (value: string) => {
  //   return true;
  // }

  // handleValidata = (value: string) => {
  //   return value;
  // }

  handleFocus = (e: React.FocusEvent) => {
    const { onFocus, autoSelectWhenFocus } = this.props;
    onFocus && onFocus(e);
    if (autoSelectWhenFocus) {
      this.input?.current?.select();
    }
  };

  render() {
    const {
      placeHolder,
      width,
      readOnly,
      disabled,
      autoFocus,
      autoSelectWhenFocus,
      allowAutoFixMinValue,
      them,
      colorTheme,
      className,
      style,
      prefix,
      suffix,
      onKeyUp,
    } = this.props;
    const { data } = this.state;
    return (
      <Input
        ref={this.input}
        value={data}
        placeHolder={placeHolder}
        width={width}
        readOnly={readOnly}
        disabled={disabled}
        canEmpty={false}
        autoFocus={autoFocus}
        autoSelectWhenFocus={autoSelectWhenFocus}
        allowAutoFixMinValue={allowAutoFixMinValue}
        them={them}
        colorTheme={colorTheme}
        className={`dsm-c-rp-number-input-calc ${className}`}
        style={style}
        prefix={prefix}
        suffix={suffix}
        // filterChar={'0123456789.+-*/()×÷'}
        // onValidate={this.handleValidata}
        // onCheck={this.handleCheck}

        onFocus={this.handleFocus}
        onKeyUp={onKeyUp}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onEnter={this.handleEnter}
      />
    );
  }
}
