import * as React from 'react';

import './index.scss';

export interface ITextAreaProps {
  placeholder?: string;
  value: string;
  rows?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  setRef?: React.RefObject<HTMLTextAreaElement>;
  height?: number | string;
  minHeight?: number | string;

  onChange(value: string, e: React.ChangeEvent<HTMLTextAreaElement>): void;

  onFocus?(): void;

  onBlur?(): void;

  onKeyDown?(e: React.KeyboardEvent): void;

  onClick?(e: React.MouseEvent<HTMLTextAreaElement>): void;
}

class TextArea extends React.Component<ITextAreaProps> {
  constructor(props: ITextAreaProps) {
    super(props);
    this.onTextareaKeyDown = this.onTextareaKeyDown.bind(this);
  }
  static defaultProps = {
    cols: 10,
    rows: 10,
  };
  onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.onChange(e.target.value, e);
  };
  onFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    this.props.onFocus && this.props.onFocus();
  };
  onTextareaKeyDown(e: React.KeyboardEvent) {
    const { onKeyDown } = this.props;
    if (e.key !== 'Escape') {
      e.stopPropagation();
      onKeyDown && onKeyDown(e);
    }
  }
  render() {
    const {
      setRef,
      rows,
      value,
      placeholder,
      autoFocus,
      disabled,
      maxLength,
      readOnly,
      height,
      minHeight,
      onBlur,
      onClick,
    } = this.props;
    return (
      <div className="dsm-c-textarea2">
        <textarea
          ref={setRef}
          rows={rows}
          value={value}
          placeholder={placeholder}
          autoFocus={autoFocus}
          disabled={disabled}
          maxLength={maxLength}
          readOnly={readOnly}
          style={{ height, minHeight }}
          onFocus={this.onFocus}
          onChange={this.onChange}
          onBlur={onBlur}
          onKeyDown={this.onTextareaKeyDown}
          onClick={onClick}
        />
      </div>
    );
  }
}

export default TextArea;
