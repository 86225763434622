import * as React from 'react';

import { AutofillEvent } from '@/managers/eventEmit';
import { ITableValue, CellTypes } from '@fbs/rp/models/table';
import { IPosition } from '@/fbs/common/models/common';
import { PopupMenu, IMenuItem } from '@dsm';
import * as tableHelper from '@/helpers/tableHelper';
import { ICellPosition, ISelectArea } from '@/helpers/tableHelper';
import { ArtboardPatches } from '@fbs/rp/utils/patch';
import { UIComponent, UITableComponent } from '@/editor/comps';
import i18n from '@/i18n';
import { IPopUpsProps } from '@/components/Common/PopUps';

import './index.scss';

export type ITableMenuType = 'cell' | 'row' | 'column';

interface ITableMenuProps {
  comp: UITableComponent;
  tableValue: ITableValue;
  selectArea?: ISelectArea;
  selectRows: Array<number>;
  selectColumns: Array<number>;
  selectedAll: boolean;
  type: ITableMenuType;
  position: IPosition;
  hasTableCellsData: boolean;
  onSelectedCompsUpdate: (comps: UIComponent[]) => void;
  onMenuClose: () => void;
  onSelectedCleared: () => void;
  onCellsContentCleared: () => void;
  onCellsContentConvert: (type: CellTypes) => void;
  onConfirm: (config: IPopUpsProps | undefined) => void;
  onSubmit?: (value: ITableValue, childrenPatches?: ArtboardPatches) => void;
  onCopyCells: () => void;
  onPasteCells: (e?: ClipboardEvent) => void;
}
interface ITableMenuStates {}

export default class TableMenu extends React.Component<ITableMenuProps, ITableMenuStates> {
  constructor(props: ITableMenuProps) {
    super(props);
  }

  menuData = (type: ITableMenuType): IMenuItem[] => {
    const { selectArea, tableValue, selectRows, selectColumns } = this.props;
    let rows = [...selectRows];
    let columns = [...selectColumns];
    if (type === 'cell' && selectArea) {
      const { rows: newRows, columns: newColumns } = tableHelper.getRowsAndColumnsOfSelectArea(selectArea);
      rows = newRows;
      columns = newColumns;
    }
    switch (type) {
      case 'cell': {
        const selectedHeader = selectArea?.start.row === -1;
        return [
          {
            id: 'copy',
            text: i18n('general.copy'),
            disabled: false,
          },
          {
            id: 'paste',
            text: i18n('general.paste'),
            disabled: !this.props.hasTableCellsData,
          },
          {
            id: '1',
            text: '-',
          },
          {
            id: 'autofill',
            text: i18n('autoFill.title'),
            disabled: false,
          },
          {
            id: '1',
            text: '-',
          },
          {
            id: '2',
            text: i18n('editor.row'),
            children: [
              {
                id: 'unshiftRow',
                text: i18n('editor.unshiftRow'),
                hidden: false,
                disabled: selectedHeader,
              },
              {
                id: 'unshiftRows',
                text: i18n('editor.unshiftRows'),
                hidden: false,
                disabled: selectedHeader,
              },
              {
                id: 'pushRow',
                text: i18n('editor.pushRow'),
                hidden: false,
                disabled: false,
              },
              {
                id: 'pushRows',
                text: i18n('editor.pushRows'),
                hidden: false,
                disabled: false,
              },
              {
                id: 'deletRows',
                text: i18n('editor.deletRows'),
                hidden: false,
                disabled: !rows.length || !tableHelper.canRemoveRows(tableValue, rows),
              },
            ],
          },
          {
            id: '3',
            text: i18n('editor.column'),
            children: [
              {
                id: 'unshiftColumn',
                text: i18n('editor.unshiftColumn'),
                hidden: false,
                disabled: false,
              },
              {
                id: 'unshiftColumns',
                text: i18n('editor.unshiftColumns'),
                hidden: false,
                disabled: false,
              },
              {
                id: 'pushColumn',
                text: i18n('editor.pushColumn'),
                hidden: false,
                disabled: false,
              },
              {
                id: 'pushColumns',
                text: i18n('editor.pushColumns'),
                hidden: false,
                disabled: false,
              },
              {
                id: 'deletColumns',
                text: i18n('editor.deletColumns'),
                hidden: false,
                disabled: !columns.length || !tableHelper.canRemoveColumns(tableValue, columns),
              },
            ],
          },
          {
            id: '4',
            text: '-',
          },
          {
            id: 'mergeCells',
            text: i18n('editor.mergeCells'),
            hidden: !selectArea || !tableValue || !tableHelper.canMergeCells(tableValue, selectArea),
          },
          {
            id: 'splitCells',
            text: i18n('editor.splitCells'),
            hidden: !selectArea || !tableValue || !tableHelper.canSplitCells(tableValue, selectArea),
          },
          {
            id: 'cleanStyle',
            text: i18n('editor.cleanStyle'),
            hidden: !selectArea || !tableValue,
          },
          {
            id: 'cleanContent',
            text: i18n('editor.cleanContent'),
            hidden: !selectArea || !tableValue,
          },
          {
            id: '5',
            text: '-',
          },
          {
            id: 'contentType',
            text: i18n('editor.contentType'),
            children: [
              {
                id: 'convertToText',
                text: i18n('editor.convertToText'),
                hidden: !selectArea || !tableValue,
              },
              {
                id: 'convertToCheckBox',
                text: i18n('editor.convertToCheckBox'),
                hidden: !selectArea || !tableValue,
              },
              // {
              //   id: 'convertToRadio',
              //   text: '转为单选框',
              //   hidden: !selectArea || !tableValue,
              // },
            ],
          },
        ];
      }
      case 'column':
        return [
          {
            id: 'unshiftColumn',
            text: i18n('editor.unshiftColumn'),
            hidden: false,
            disabled: false,
          },
          {
            id: 'unshiftColumns',
            text: i18n('editor.unshiftColumns'),
            hidden: false,
            disabled: false,
          },
          {
            id: 'pushColumn',
            text: i18n('editor.pushColumn'),
            hidden: false,
            disabled: false,
          },
          {
            id: 'pushColumns',
            text: i18n('editor.pushColumns'),
            hidden: false,
            disabled: false,
          },
          {
            id: '',
            text: '-',
          },
          {
            id: 'deletColumns',
            text: i18n('general.delete'),
            hidden: false,
            disabled: !columns.length || !tableHelper.canRemoveColumns(tableValue, columns),
          },
        ];
      case 'row': {
        const selectedHeader = selectArea?.start.row === -1;
        return [
          {
            id: 'unshiftRow',
            text: i18n('editor.unshiftRow'),
            hidden: false,
            disabled: selectedHeader,
          },
          {
            id: 'unshiftRows',
            text: i18n('editor.unshiftRows'),
            hidden: false,
            disabled: selectedHeader,
          },
          {
            id: 'pushRow',
            text: i18n('editor.pushRow'),
            hidden: false,
            disabled: false,
          },
          {
            id: 'pushRows',
            text: i18n('editor.pushRows'),
            hidden: false,
            disabled: false,
          },
          {
            id: '',
            text: '-',
          },
          {
            id: 'deletRows',
            text: i18n('general.delete'),
            hidden: false,
            disabled: !rows.length || !tableHelper.canRemoveRows(tableValue, rows),
          },
        ];
      }
    }
  };

  handleItemClick = (item: IMenuItem) => {
    const {
      type,
      tableValue,
      comp,
      selectArea,
      selectRows,
      selectColumns,
      onSubmit,
      onSelectedCleared,
      onCellsContentCleared,
      onCellsContentConvert,
      onConfirm,
      onCopyCells,
      onPasteCells,
      onMenuClose,
    } = this.props;
    try {
      let rows = [...selectRows];
      let columns = [...selectColumns];
      if (type === 'cell' && selectArea) {
        const { rows: newRows, columns: newColumns } = tableHelper.getRowsAndColumnsOfSelectArea(selectArea);
        rows = newRows;
        columns = newColumns;
      }
      switch (item.id) {
        case 'copy': {
          onCopyCells();
          break;
        }
        case 'paste': {
          onPasteCells();
          break;
        }
        case 'autofill': {
          AutofillEvent.dispatch();
          break;
        }
        case 'mergeCells':
          if (selectArea && tableHelper.canMergeCells(tableValue, selectArea)) {
            if (comp.selectedComps.length > 1) {
              onConfirm({
                type: 'msg',
                massage: i18n('alert.makeSureToMergeCells'),
                buttonMode: 'activeCancel',
                onClickMsgOk: (data, e) => {
                  e?.stopPropagation();
                  const { newValue, childrenPatches } = comp.mergeCells(selectArea);
                  comp.needUpdateCellRightPanel = true;
                  onSubmit && onSubmit(newValue, childrenPatches);
                  onConfirm(undefined);
                },
                onClose: () => {
                  onConfirm(undefined);
                },
              });
            } else {
              const { newValue, childrenPatches } = comp.mergeCells(selectArea);
              comp.needUpdateCellRightPanel = true;
              onSubmit && onSubmit(newValue, childrenPatches);
            }
          }
          break;
        case 'splitCells':
          if (selectArea && tableHelper.canSplitCells(tableValue, selectArea)) {
            const { newValue, childrenPatches } = comp.splitCells(selectArea);
            comp.needUpdateCellRightPanel = true;
            onSubmit && onSubmit(newValue, childrenPatches);
          }
          break;
        case 'cleanStyle':
          if (selectArea) {
            const selectPositions: ICellPosition[] = [];
            for (let i = selectArea.start.row; i <= selectArea.end.row; i++) {
              for (let j = selectArea.start.column; j <= selectArea.end.column; j++) {
                selectPositions.push({ row: i, column: j });
              }
            }
            const { newValue, childrenPatches } = comp.cleanStyle(selectPositions);
            onSubmit && onSubmit(newValue, childrenPatches);
          }
          break;
        case 'cleanContent':
          onCellsContentCleared && onCellsContentCleared();
          break;
        case 'convertToText': {
          if (selectArea) {
            onCellsContentConvert && onCellsContentConvert(CellTypes.Text);
          }
          break;
        }
        case 'convertToCheckBox': {
          if (selectArea) {
            onCellsContentConvert && onCellsContentConvert(CellTypes.CheckBox);
          }
          break;
        }
        case 'convertToRadio': {
          if (selectArea) {
            onCellsContentConvert && onCellsContentConvert(CellTypes.Radio);
          }
          break;
        }
        case 'unshiftColumn':
          if (selectArea) {
            const { newValue, childrenPatches } = comp.unshiftColumns(columns, 1);
            onSelectedCleared && onSelectedCleared();
            onSubmit && onSubmit(newValue, childrenPatches);
          }
          break;
        case 'unshiftColumns':
          if (selectArea) {
            const { newValue, childrenPatches } = comp.unshiftColumns(columns, 3);
            onSelectedCleared && onSelectedCleared();
            onSubmit && onSubmit(newValue, childrenPatches);
          }
          break;
        case 'pushColumn':
          if (selectArea) {
            const { newValue, childrenPatches } = comp.pushColumns(columns, 1);
            onSelectedCleared && onSelectedCleared();
            onSubmit && onSubmit(newValue, childrenPatches);
          }
          break;
        case 'pushColumns':
          if (selectArea) {
            const { newValue, childrenPatches } = comp.pushColumns(columns, 3);
            onSelectedCleared && onSelectedCleared();
            onSubmit && onSubmit(newValue, childrenPatches);
          }
          break;
        case 'deletColumns':
          if (columns.length) {
            const { newValue, childrenPatches } = comp.removeColumns(columns);
            onSelectedCleared && onSelectedCleared();
            onSubmit && onSubmit(newValue, childrenPatches);
          }
          break;
        case 'unshiftRow':
          if (rows.length && !rows.includes(-1)) {
            const { newValue, childrenPatches } = comp.unshiftRows(rows, 1);
            onSelectedCleared && onSelectedCleared();
            onSubmit && onSubmit(newValue, childrenPatches);
          }
          break;
        case 'unshiftRows':
          if (rows.length && !rows.includes(-1)) {
            const { newValue, childrenPatches } = comp.unshiftRows(rows, 3);
            onSelectedCleared && onSelectedCleared();
            onSubmit && onSubmit(newValue, childrenPatches);
          }
          break;
        case 'pushRow':
          if (rows.length) {
            const { newValue, childrenPatches } = comp.pushRows(rows, 1);
            onSelectedCleared && onSelectedCleared();
            onSubmit && onSubmit(newValue, childrenPatches);
          }
          break;
        case 'pushRows':
          if (rows.length) {
            const { newValue, childrenPatches } = comp.pushRows(rows, 3);
            onSelectedCleared && onSelectedCleared();
            onSubmit && onSubmit(newValue, childrenPatches);
          }
          break;
        case 'deletRows':
          if (rows.length) {
            const { newValue, childrenPatches } = comp.removeRows(rows);
            onSelectedCleared && onSelectedCleared();
            onSubmit && onSubmit(newValue, childrenPatches);
          }
          break;

        default:
          break;
      }
    } finally {
      onMenuClose && onMenuClose();
    }
  };

  render() {
    const { type, position, onMenuClose } = this.props;
    // window.LANG
    return (
      <PopupMenu
        position={position}
        items={this.menuData(type)}
        onItemClick={this.handleItemClick}
        onClose={onMenuClose}
        width={100}
      />
    );
  }
}
