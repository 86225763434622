interface IVector {
  x: number;
  y: number;
  w: number;
}

/**
 * 矩阵对象
 */
export class Matrix {
  /**
   * 获取一个默认的矩阵对象
   * @returns {Matrix}
   */
  public static get identity(): Matrix {
    return new Matrix();
  }

  /**
   * 创建一个旋转的矩阵对象
   * @param {number} rad 旋转弧度
   * @returns {Matrix}
   */
  static createRotation(rad: number): Matrix {
    // const r = Math.PI * angle / 180;
    const sine = Math.sin(rad);
    const cosine = Math.cos(rad);
    const result = this.identity;
    result.m11 = cosine;
    result.m12 = sine;
    result.m21 = -sine;
    result.m22 = cosine;
    return result;
  }

  /**
   * 创建一个缩放的矩阵对象
   * @param {number} scaleX 水平缩放比例
   * @param {number} scaleY 垂直缩放比例
   * @returns {Matrix}
   */
  static createScaling(scaleX: number, scaleY?: number): Matrix {
    const result = this.identity;
    result.m11 = scaleX;
    result.m22 = scaleY || scaleX;
    return result;
  }

  /**
   * 创建一个位移的矩阵对象
   * @param {number} deltaX 水平位移
   * @param {number} deltaY 垂直位移
   * @returns {Matrix}
   */
  static createTranslation(deltaX: number, deltaY: number = 0): Matrix {
    const result = this.identity;
    result.m31 = deltaX;
    result.m32 = deltaY;
    return result;
  }

  public m11 = 1;
  public m12 = 0;
  public m13 = 0;
  public m21 = 0;
  public m22 = 1;
  public m23 = 0;
  public m31 = 0;
  public m32 = 0;
  public m33 = 1;

  /**
   * 矩阵相乘
   * @param {Matrix} matrix
   */
  public multiplyMatrix(matrix: Matrix) {
    const { m11, m12, m13, m21, m22, m23, m31, m32, m33 } = this;
    this.m11 = m11 * matrix.m11 + m12 * matrix.m21 + m13 * matrix.m31;
    this.m12 = m11 * matrix.m12 + m12 * matrix.m22 + m13 * matrix.m32;
    this.m13 = m11 * matrix.m13 + m12 * matrix.m23 + m13 * matrix.m33;
    this.m21 = m21 * matrix.m11 + m22 * matrix.m21 + m23 * matrix.m31;
    this.m22 = m21 * matrix.m12 + m22 * matrix.m22 + m23 * matrix.m32;
    this.m23 = m21 * matrix.m13 + m22 * matrix.m23 + m23 * matrix.m33;
    this.m31 = m31 * matrix.m11 + m32 * matrix.m21 + m33 * matrix.m31;
    this.m32 = m31 * matrix.m12 + m32 * matrix.m22 + m33 * matrix.m32;
    this.m33 = m31 * matrix.m13 + m32 * matrix.m23 + m33 * matrix.m33;
  }

  /**
   * 计算一个点通过矩阵转换后的位置
   * @param {number} x
   * @param {number} y
   * @returns {{x: number, y: number}}
   */
  public multiplyPoint({ x, y }: { x: number; y: number }): { x: number; y: number } {
    return {
      x: x * this.m11 + y * this.m21 + this.m31,
      y: x * this.m12 + y * this.m22 + this.m32,
    };
  }

  /**
   * 计算一个区域矩阵转换后，占用的位置
   * @param {number} left
   * @param {number} top
   * @param {number} right
   * @param {number} bottom
   * @returns {{left: number, top: number, right: number, bottom: number, width: number, height: number}}
   */
  public multiplyRect({ left, top, right, bottom }: { left: number; top: number; right: number; bottom: number }) {
    let tl = { x: left, y: top };
    let tr = { x: right, y: top };
    let bl = { x: left, y: bottom };
    let br = { x: right, y: bottom };
    tl = this.multiplyPoint(tl);
    tr = this.multiplyPoint(tr);
    bl = this.multiplyPoint(bl);
    br = this.multiplyPoint(br);

    const result = {
      left: Math.min(tl.x, bl.x, tr.x, br.x),
      top: Math.min(tl.y, bl.y, tr.y, br.y),
      right: Math.max(tl.x, bl.x, tr.x, br.x),
      bottom: Math.max(tl.y, bl.y, tr.y, br.y),
      width: 0,
      height: 0,
    };
    result.width = result.right - result.left;
    result.height = result.bottom - result.top;
    return result;
  }

  public miltiplyVector({ x, y, w }: IVector): IVector {
    return {
      x: x * this.m11 + y * this.m21 + w * this.m31,
      y: x * this.m12 + y * this.m22 + w * this.m32,
      w: x * this.m13 + y * this.m23 + w * this.m33,
    };
  }

  public scale(value: number) {
    this.m11 *= value;
    this.m12 *= value;
    // this.m13 *= value;
    this.m21 *= value;
    this.m22 *= value;
    // this.m23 *= value;
    // this.m31 *= value;
    // this.m32 *= value;
    // this.m33 *= value;
  }

  public translate(x: number, y: number) {
    this.m31 += x * this.m11 + y * this.m21;
    this.m32 += x * this.m12 + y * this.m22;
  }

  public rotate(angle: number, center: { x: number; y: number }) {
    const rad = angle * (Math.PI / 180);
    const cos = Math.cos(rad);
    const sin = Math.sin(rad);
    const tx = center.x - center.x * cos + center.y * sin;
    const ty = center.y - center.x * sin - center.y * cos;
    const { m11, m12, m21, m22 } = this;
    this.m11 = cos * m11 + sin * m21;
    this.m12 = cos * m12 + sin * m22;
    this.m21 = -sin * m11 + cos * m21;
    this.m22 = -sin * m12 + cos * m22;
    this.m31 += tx * m11 + ty * m21;
    this.m32 += tx * m12 + ty * m22;
  }

  /**
   * 矩阵克隆
   * @returns {Matrix}
   */
  public clone(): Matrix {
    const result = Matrix.identity;
    result.m11 = this.m11;
    result.m12 = this.m12;
    result.m13 = this.m13;
    result.m21 = this.m21;
    result.m22 = this.m22;
    result.m23 = this.m23;
    result.m31 = this.m31;
    result.m32 = this.m32;
    result.m33 = this.m33;
    return result;
  }

  public inverted(): Matrix {
    const { m11, m12, m21, m22, m31, m32 } = this;
    const a = m11;
    const b = m12;
    const c = m21;
    const d = m22;
    const tx = m31;
    const ty = m32;
    const det = a * d - b * c;
    const result = this.clone();
    if (det && !Number.isNaN(det) && isFinite(tx) && isFinite(ty)) {
      result.m11 = d / det;
      result.m12 = -b / det;
      result.m21 = -c / det;
      result.m22 = a / det;
      result.m31 = (c * ty - d * tx) / det;
      result.m32 = (b * tx - a * ty) / det;
    }
    return result;
  }

  public decompose() {
    const { m11, m12, m21, m22 } = this;
    const det = m11 * m22 - m12 * m21;
    const degrees = 180 / Math.PI;
    let rotate: number;
    let scale: number[];
    let skew: number[];
    if (m11 !== 0 || m12 !== 0) {
      const r = Math.hypot(m11, m12);
      rotate = Math.acos(m11 / r) * (m12 > 0 ? 1 : -1);
      scale = [r, det / r];
      skew = [Math.atan2(m11 * m21 + m12 * m22, r ** 2), 0];
    } else if (m21 !== 0 || m22 !== 0) {
      const r = Math.hypot(m21, m22);
      rotate = Math.asin(m21 / r) * (m22 > 0 ? 1 : -1);
      scale = [det / r, r];
      skew = [0, Math.atan2(m11 * m21 + m12 * m22, r * r)];
    } else {
      rotate = 0;
      skew = scale = [0, 0];
    }
    return {
      translation: this.translation,
      rotation: rotate * degrees,
      scaling: { x: scale[0], y: scale[1] },
      skewing: { x: skew[0] * degrees, y: skew[1] * degrees },
    };
  }

  get translation() {
    return {
      x: this.m31,
      y: this.m32,
    };
  }

  get scaling() {
    return this.decompose().scaling;
  }

  get rotation() {
    return this.decompose().rotation;
  }

  /**
   * 转换成字符串
   * @returns {string}
   */
  public toString() {
    const { m11, m12, m13, m21, m22, m23, m31, m32, m33 } = this;
    return `${m11} ${m12} ${m13} ${m21} ${m22} ${m23} ${m31} ${m32} ${m33} `;
  }
}
