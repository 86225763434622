import i18n from '@i18n';

// 10M
export const MaxImageSize = 10 * 1024 * 1024;
export const ImageSizeExceedTips = i18n('alert.imageSizeExceed');

export const MaxImageDimention = 10000;
export const ImageDimensionExceedTips = i18n('alert.imageDimensionExceed');

// 10M
export const MaxVideoSize = 10 * 1024 * 1024;
export const VideoSizeExceedTips = i18n('alert.videoSizeExceed');

// 10M
export const MaxAudioSize = 10 * 1024 * 1024;
export const AudioSizeExceedTips = i18n('alert.audioSizeExceed');

export const MaxSimultaneouslyUploadFileCount = 50;
