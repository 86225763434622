/**
 * 推广活动相关接口
 */

import { ITeams, isFreeTeam } from '..';
import { RPTeamPayment } from '../models/team';
import { IUserInfo } from '@/fbs/idoc/models/user';

export enum PopularizeDisplay {
  // 所有团队
  All = 'all',
  // 免费团队
  Free = 'free',
  // 免费且未领取永久无线设计包的团队
  FreeAndLimited = 'freeAndLimited',
  New = 'new', // 一定注册时间内的新用户
}

export type PopularizeType = 'user' | 'team';

export enum PopularizePosition {
  HomeTop = 'homeTop',
  HomeLeft = 'homeLeft',
  HomeOrEditInit = 'homeOrEditorInit',
  EditTop = 'editTop',
  DemoLeft = 'demoLeft',
}

export interface IPopularize {
  _id: string;
  onlineAt: Date;
  offlineAt: Date;
  // 活动图片
  image: string;
  // 活动链接
  url: string;
  position: PopularizePosition;
  // 显示状态
  displayMode: PopularizeDisplay;
  type: PopularizeType;
  priority: number; // 0 - infinity 数值越小越优先
  // 活动弹窗内容
  htmlContent?: string;
  // 是否能够被关闭
  enableColse?: boolean;
}

// 接口返回数据格式
export type IPopularizeResult = IPopularize[];

/**
 * 检查显示模式
 * @param mode 显示模式
 * @param team 团队信息
 * @returns 在当前显示模式下能否显示
 */
export function checkDisplayMode(mode: PopularizeDisplay, team: ITeams, user: IUserInfo): boolean {
  const newUserLimit = 3600 * 24 * 15 * 1000;
  switch (mode) {
    case PopularizeDisplay.All:
      return true;
    case PopularizeDisplay.Free:
      return isFreeTeam(team);
    case PopularizeDisplay.New:
      // 注册时间小于15天则判断为新注册用户
      return new Date().getTime() - new Date(user.addTime).getTime() < newUserLimit;
    case PopularizeDisplay.FreeAndLimited:
      return isFreeTeam(team) && team.rpDesignPayment !== RPTeamPayment.Perpetual;
    default:
      break;
  }
  return false;
}

/**
 * 获取可展示位置广告的数据
 * @param popularizes
 * @param position
 * @param teamInfo
 * @param userInfo
 */
export function getPopularize(
  popularizes: IPopularize[],
  position: PopularizePosition,
  teamInfo?: ITeams,
  userInfo?: IUserInfo,
): IPopularize | undefined {
  if (teamInfo && userInfo) {
    const popularizesData = popularizes
      .filter((item) => item.position === position && checkDisplayMode(item.displayMode, teamInfo, userInfo))
      .sort((a, b) => (a.priority < b.priority ? -1 : 1));
    return popularizesData[0];
  }
  return undefined;
}
