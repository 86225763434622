import { IComponentData } from '@/fbs/rp/models/component';
import { IChartValue } from '@/fbs/rp/models/chart';
import { makeCommonComponent } from '@/libs/helper';

import { IComponentItem } from '../../../types';
import { CDoughnutChart } from '../../../constants';

import { getChartConfig, makeChartData, getSeriesChartTwoDefaultData } from '../common';

export const DoughnutChartConfig: IComponentItem = getChartConfig(CDoughnutChart);

export function makeDoughnutChartChart(id: string): IComponentData {
  return makeChartData(id, CDoughnutChart);
}

export function makeAIAnnularChart(id: string, value: IChartValue): IComponentData {
  return makeCommonComponent(id, CDoughnutChart, {
    ...getSeriesChartTwoDefaultData(CDoughnutChart),
    size: {
      width: 400,
      height: 250,
    },
    lib: {
      id: 'chart',
      type: CDoughnutChart,
    },
    value: value,
  });
}
