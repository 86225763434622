import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

import CustomLink from '../../Link';

/**
 * 工具提示属性对象
 */
interface ITooltipProps {
  text?: string;
  width?: number | string;
  children?: React.ReactNode;
  maxWidth?: number;
  linkText?: string;
  align?: 'left' | 'center' | 'right';
  onClickLink?(): void;
}

const Tooltip: React.FC<ITooltipProps> = ({ text, children, width, linkText, align, onClickLink }: ITooltipProps) => (
  <div style={{ width }} className="dsm-upgrade-tooltip" onClick={onClickLink}>
    {text && (
      <div className={classnames('tips-content', align)}>
        <p>{text}</p>
        <CustomLink className="go-buy-link">{linkText}</CustomLink>
      </div>
    )}
    {children}
  </div>
);

Tooltip.defaultProps = {
  text: '',
  linkText: '',
};

export default Tooltip;
