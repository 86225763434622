import * as GraphicsUtils from '@utils/graphicsUtils';

import { HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import { FillType } from '@fbs/rp/models/properties/fill';
import { TextAlign } from '@fbs/rp/models/properties/text';
import { InputModel } from '@fbs/rp/models/properties/inputModel';
import IPadding from '@/fbs/rp/models/properties/padding';
import { UIContainerComponent } from '@/editor/comps';
import * as SystemsColor from '@consts/colors';
import { SpriteThumb } from '@consts/spriteIcons';
import { DefaultBorder } from '@consts/border';
import { DefaultPadding } from '@/consts/padding';
import { PredefinedStates } from '@/consts/state';
import i18n from '@i18n';
import { getNewID } from '@helpers/idHelper';
import { makeCommonComponent } from '../../helper';
import { IComponentItem } from '../../types';
import { CNumericStep, CCanvasPanel, CPath, CInput } from '../../constants';
import { SizeMode } from '../../enum';

/**
 * 处理右侧属性面板内边距最大值的处理
 * @param group
 * @param padding
 * @param fontSize
 */
function onCalcMaxPadding(group: UIContainerComponent, padding: IPadding, fontSize: number) {
  const { left, right, bottom, top } = padding;
  const textComp = group.getComponentByAlias('value') as UIContainerComponent;
  const { width, height } = textComp.size;
  const maxValue = {
    top: height - (bottom || 0) - fontSize,
    right: width - (left || 0) - fontSize,
    bottom: height - (top || 0) - fontSize,
    left: width - (right || 0) - fontSize,
  };
  const defaultMaxValue = Math.min(width / 2, height / 2);
  return { maxValue, defaultMaxValue };
}

export function makeNumericStep(id: string) {
  return makeCommonComponent(id, CNumericStep, {
    ...NumericStepConfig.getDefaultData?.(),
    position: { x: 0, y: 0 },
    size: { width: 150, height: 30 },
    sealed: true,
    layout: {
      vertical: VerticalAlign.TopAndBottom,
      horizontal: HorizontalAlign.Right,
      fixedWidth: true,
      fixedHeight: false,
      auto: true,
      responsive: false,
    },
    value: '',
    states: {},
    components: [
      makeCommonComponent(getNewID(), CCanvasPanel, {
        alias: 'arrowTotalWrapper',
        position: { x: 130, y: 0 },
        size: { width: 20, height: 30 },
        layout: {
          vertical: VerticalAlign.TopAndBottom,
          horizontal: HorizontalAlign.Right,
          fixedWidth: true,
          fixedHeight: false,
          auto: false,
          responsive: false,
        },
        components: [
          makeCommonComponent(getNewID(), CCanvasPanel, {
            position: { x: 0, y: 0 },
            alias: 'upArrow',
            size: { width: 20, height: 15 },
            layout: {
              horizontal: HorizontalAlign.LeftAndRight,
              vertical: VerticalAlign.Top,
              fixedHeight: false,
              fixedWidth: false,
              auto: false,
              responsive: false,
            },
            properties: {
              fill: {
                type: FillType.solid,
                color: { r: 255, g: 255, b: 255, a: 1 },
                disabled: true,
              },
              stroke: {
                color: { r: 119, g: 119, b: 119, a: 1 },
                thickness: 1,
                disabled: true,
              },
            },
            components: [
              makeCommonComponent(getNewID(), CPath, {
                position: {
                  x: 6,
                  y: 7,
                },
                value: {
                  closed: true,
                  data: [
                    { handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 }, point: { x: 0, y: 0 } },
                    { handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 }, point: { x: 8, y: 0 } },
                    { handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 }, point: { x: 4, y: 4 } },
                  ],
                },

                size: { width: 8, height: 4, lockedRatio: true },
                layout: {
                  auto: false,
                  responsive: true,
                  fixedWidth: true,
                  fixedHeight: true,
                  vertical: VerticalAlign.Middle,
                  horizontal: HorizontalAlign.Center,
                },
                rotate: 180,
                properties: {
                  fill: { ref: '@properties.iconColor' },
                  stroke: { color: GraphicsUtils.rgba2hex({ a: 1, b: 119, g: 119, r: 119 }), disabled: true },
                },
              }),
            ],
          }),
          makeCommonComponent(getNewID(), CCanvasPanel, {
            alias: 'downArrow',
            position: { x: 0, y: 15 },
            size: { width: 20, height: 15 },
            layout: {
              horizontal: HorizontalAlign.LeftAndRight,
              vertical: VerticalAlign.Bottom,
              fixedHeight: false,
              fixedWidth: false,
              auto: false,
              responsive: false,
            },
            properties: {
              fill: {
                type: FillType.solid,
                color: { r: 255, g: 255, b: 255, a: 1 },
                disabled: true,
              },
              stroke: {
                color: { r: 119, g: 119, b: 119, a: 1 },
                thickness: 1,
                disabled: true,
              },
            },
            components: [
              makeCommonComponent(getNewID(), CPath, {
                position: {
                  x: 6,
                  y: 4,
                },
                value: {
                  closed: true,
                  data: [
                    { handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 }, point: { x: 0, y: 0 } },
                    { handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 }, point: { x: 8, y: 0 } },
                    { handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 }, point: { x: 4, y: 4 } },
                  ],
                },

                size: { width: 8, height: 4, lockedRatio: true },
                layout: {
                  auto: false,
                  responsive: true,
                  fixedWidth: true,
                  fixedHeight: true,
                  vertical: VerticalAlign.Middle,
                  horizontal: HorizontalAlign.Center,
                },
                rotate: 0,
                properties: {
                  fill: { ref: '@properties.iconColor' },
                  stroke: { color: GraphicsUtils.rgba2hex({ a: 1, b: 119, g: 119, r: 119 }), disabled: true },
                },
              }),
            ],
          }),
        ],
      }),
      makeCommonComponent(getNewID(), CInput, {
        alias: 'value',
        position: { x: 0, y: 0 },
        size: { width: 130, height: 30 },
        layout: {
          horizontal: HorizontalAlign.LeftAndRight,
          vertical: VerticalAlign.TopAndBottom,
          auto: false,
          fixedWidth: false,
          fixedHeight: false,
          responsive: true,
        },
        properties: {
          stroke: {
            hidden: true,
            disabled: true,
          },
          fill: { hidden: true, disabled: true },
          textStyle: { ref: '@properties.textStyle' },
          inputModel: {
            hidden: true,
            value: InputModel.numeric,
          },
          padding: { ref: '@properties.padding' },
        },
        value: '@value',
      }),
    ],
  });
}

export const NumericStepConfig: IComponentItem = {
  type: CNumericStep,
  isTextComp: true,
  predefinedStates: [PredefinedStates.hover, PredefinedStates.disabled],
  thumb: {
    spriteIconClass: SpriteThumb.Numeric.className,
    dragPosition: SpriteThumb.Numeric.position,
  },
  editor: {
    specials: {
      onCalcMaxPadding,
    },
  },
  name: i18n('resource.components.numericStep'),
  constraint: {
    value: {
      resize: SizeMode.none,
    },
    arrowTotalWrapper: {
      resize: SizeMode.none,
    },
  },
  getDefaultData() {
    return {
      properties: {
        fill: {
          type: FillType.solid,
          color: SystemsColor.DefaultWhiteFillColor,
          disabled: false,
        },
        stroke: {
          thickness: 1,
          color: SystemsColor.DefaultStrokeColor1,
          disabled: false,
        },
        border: DefaultBorder,
        radius: {
          topLeft: 2,
          topRight: 2,
          bottomLeft: 2,
          bottomRight: 2,
          isPercent: false,
          disabled: false,
        },
        textStyle: {
          color: SystemsColor.DefaultTextColor,
          fontFamily: 'Microsoft YaHei',
          fontStyle: { underline: false, bold: false, strike: false, italic: false },
          fontSize: 12,
          textAlign: TextAlign.left,
        },
        iconColor: {
          name: i18n('property.propertyNames.icon'),
          prop: 'fill',
          type: FillType.solid,
          color: '#777777',
          disabled: false,
        },
        placeholder: {
          prop: 'string',
          name: i18n('property.propertyNames.placeholder'),
          value: '',
        },
        placeHolderStyle: {
          prop: 'color',
          name: i18n('property.propertyNames.placeholderColor'),
          value: SystemsColor.GrayColor,
        },
        padding: DefaultPadding,
        // icon: {
        //   color: DefaultTextColor,
        // },
      },
    };
  },
};
