import * as React from 'react';
import classnames from 'classnames';

import Icon from '../Icon';
import HistoryPanel from './HistoryPanel';

import './index.scss';

export interface ISearchInputProp {
  placeholder?: string;
  value?: string;
  autoFocus?: boolean;
  autoSelect?: boolean;
  showClose?: boolean;
  fullBorder?: boolean;
  inlineBox?: boolean;
  alwaysShowClose?: boolean;
  disabled?: boolean;
  setInputRef?: React.RefObject<HTMLInputElement>;
  width?: number | string;
  iconSize?: number;
  historyKey?: string;
  fontSize?: number;
  theme?: 'blue';
  onSubmit?(value: string): void;
  onChange?(value: string): void;
  onInput?(value: string): void;
  onStopSearch?(): void;
  onBlur?(): void;
  onFocus?(): void;
  onClearHistory?(): void;
}

interface ISearchInputState {
  focused: boolean;
  historyOptions: string[];
}

class SearchInput extends React.Component<ISearchInputProp, ISearchInputState> {
  static defaultProps: Partial<ISearchInputProp> = {
    placeholder: '',
    value: '',
    width: '140',
    fontSize: 14,
    autoFocus: false,
    showClose: false,
    fullBorder: false,
    inlineBox: false,
    alwaysShowClose: false,
    historyKey: '',
    theme: undefined,
    onStopSearch: () => {},
    onBlur: () => {},
  };
  constructor(props: ISearchInputProp) {
    super(props);
    this.inputRef = React.createRef();
    this.timer = null;
    this.state = {
      focused: false,
      historyOptions: this.getHistory(),
    };
  }
  private isWaitingCompositionEnd: boolean = false;
  inputRef: React.RefObject<HTMLInputElement>;
  timer?: NodeJS.Timeout | null;
  componentDidMount() {
    setTimeout(() => {
      if (!this.inputRef.current) {
        return;
      }
      if (this.props.autoSelect) {
        this.inputRef.current.focus();
        this.inputRef.current.select();
        const value = this.inputRef.current.value;
        const start = value.length;
        this.inputRef.current.selectionStart = 0;
        this.inputRef.current.selectionEnd = start;
      }
    }, 100);
  }
  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
  getHistory = (): string[] => {
    const { historyKey } = this.props;
    if (!!historyKey) {
      const cacheHistory = localStorage.getItem(historyKey);
      if (!!cacheHistory) {
        return JSON.parse(cacheHistory);
      }
    }
    return [];
  };

  onClearHistory = () => {
    const { historyKey, onClearHistory } = this.props;
    onClearHistory && onClearHistory();
    this.setState({ historyOptions: [] }, () => {
      historyKey && localStorage.removeItem(historyKey);
    });
  };

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    onChange && onChange(e.target.value);
  };

  onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onInput } = this.props;
    if (!onInput) {
      return;
    }
    e.persist();
    onInput(e.target.value);
  };

  onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { focused } = this.state;
    this.setState({ focused: true });
  };
  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = this.props;
    this.timer = setTimeout(() => {
      this.setState({ focused: false });
      onBlur && onBlur();
    }, 200);
  };

  onInputKeyDown = (e: any) => {
    const { onSubmit } = this.props;
    if (e.key !== 'Escape') {
      e.stopPropagation();
    }
    if (!onSubmit) {
      return;
    }
    if (this.isWaitingCompositionEnd) {
      return;
    }
    if (e.key === 'Enter') {
      e.stopPropagation();
      const value = e.target.value.trim();
      this.handleOnSearch(value);
    }
  };

  handleOnSearch = (value: string, fromHistory?: boolean) => {
    const { historyKey, onSubmit, onInput, onChange } = this.props;
    const { historyOptions } = this.state;
    if (fromHistory) {
      onInput && onInput(value);
      onChange && onChange(value);
    }

    if (historyKey && value.trim()) {
      if (historyOptions.includes(value)) {
        historyOptions.splice(historyOptions.indexOf(value), 1);
      }
      historyOptions.unshift(value);
      this.setState(
        {
          historyOptions,
        },
        () => {
          localStorage.setItem(historyKey, JSON.stringify(historyOptions));
        },
      );
    }
    onSubmit && onSubmit(value);
  };

  onCompositionStart = () => {
    if (this.isWaitingCompositionEnd) {
      return;
    }
    this.isWaitingCompositionEnd = true;
  };

  onCompositionUpdate = () => {
    if (this.isWaitingCompositionEnd) {
      return;
    }
    this.isWaitingCompositionEnd = true;
  };

  onCompositionEnd = () => {
    this.isWaitingCompositionEnd = false;
  };

  render() {
    const {
      placeholder,
      value,
      autoFocus,
      showClose,
      alwaysShowClose,
      width,
      setInputRef,
      iconSize,
      fullBorder,
      historyKey,
      inlineBox,
      fontSize,
      theme,
      onStopSearch,
    } = this.props;
    const { focused, historyOptions } = this.state;

    return (
      <div
        className={classnames('dsm-c-search-input', {
          'dsm-c-search-input-focus': focused,
          'dsm-c-search-input-full-border': fullBorder,
          'dsm-c-search-input-inline-box': inlineBox,
        })}
        style={{ width }}
      >
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          autoFocus={autoFocus}
          ref={setInputRef || this.inputRef}
          style={{ width, fontSize }}
          onChange={this.onInputChange}
          onKeyDown={this.onInputKeyDown}
          onInput={this.onInput}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onCompositionStart={this.onCompositionStart}
          onCompositionUpdate={this.onCompositionUpdate}
          onCompositionEnd={this.onCompositionEnd}
        />
        <Icon cls="search" disableHover={true} size={iconSize} />
        {((showClose && value) || alwaysShowClose) && <Icon cls="close" theme={theme} onClick={onStopSearch} />}
        {historyKey && historyOptions.length > 0 && focused && (
          <HistoryPanel
            options={historyOptions}
            onClearHistory={this.onClearHistory}
            onClickItem={this.handleOnSearch}
          />
        )}
      </div>
    );
  }
}

export default SearchInput;
