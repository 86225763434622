import * as React from 'react';

import './index.scss';

import Icon from '../Icon';
import Tooltip from './Tooltip';

export interface IUpgradeTipProps {
  tooltip?: string;
  linkText?: string;
  align?: 'left' | 'center' | 'right';
  onClickLink?(): void;
}

const UpgradeTip: React.FC<IUpgradeTipProps> = (props: IUpgradeTipProps) => {
  const { tooltip, linkText, onClickLink, align } = props;
  return (
    <Tooltip text={tooltip} linkText={linkText} onClickLink={onClickLink} align={align}>
      <Icon cls="cover_help dsm-help-icon" color={'#ffb300'} />
    </Tooltip>
  );
};

UpgradeTip.defaultProps = {
  tooltip: '',
  linkText: '',
};

export default UpgradeTip;
