import { UIFragment } from '@/editor/comps';
import { pushData } from '../util';
import { isArtboard } from '../util/artboardUtil';
import { parseArtboard } from './parseArtboard';
import { IExportData, IParseExportDataOptions } from '../type';
import { parseComponent } from './parseComponent';

/**
 * 数据转换
 * 组件模型转导出数据模型
 * @param components  IComponentData[]
 * @returns
 */
export const parseExportData = async (options: IParseExportDataOptions): Promise<IExportData[]> => {
  const { components, doc, app, renderBlankFilter, mobileType } = options;

  if (isArtboard(components) && doc) {
    return await parseArtboard(components as UIFragment[], doc, app, mobileType);
  }

  const result: IExportData[] = [];
  const processor = components.map(async (t) => {
    const refData = t.$data.sealed ? t : undefined;
    const isRoot = true;
    const data = await parseComponent({ t, isRoot, refData, doc, renderBlankFilter });
    pushData(result, data);
  });
  await Promise.all(processor);
  return result;
};
