import React from 'react';

import { cloneDeep, compact, isUndefined } from 'lodash';

import { measureTextSize, transBlankChart } from '@/utils/textUtils';

import {
  ITEM_SPACE,
  EXCLUDE_ITEM_TEXT_WIDTH,
  EXCLUDE_ITEM_TEXT_HEIGHT,
  ICON_PADDING_RIGHT,
  NEED_RESET_DRAG_INDEX,
} from '@/consts/defaultData/multipleSelect';
import { mergePatches } from '@helpers/patchHelper';
import { StyleHelper } from '@/helpers/styleHelper';
import { IUICompConstructOptions } from '@/customTypes';
import { CPureText } from '@libs/constants';
import { IComponentData } from '@fbs/rp/models/component';
import { IBasicLayout } from '@/fbs/rp/models/layout';
import { IBoundsOffset } from '@/fbs/common/models/common';
import { ArtboardPatches, Ops } from '@/fbs/rp/utils/patch';
import IPadding from '@/fbs/rp/models/properties/padding';

import { ComponentResizeResult, ResizeOptions } from './resizeHelper';
import { UIComponent, UIContainerComponent, UIPanelComponent } from '.';

const DEFAULT_PATCHES: ArtboardPatches = {
  do: {},
  undo: {},
};

/**
 * 在拖拽组件宽度，调整文本字体/字号/字间距，子项的选中和取消选中，子项的移除等会影响到到选中区域高度变化
 * 如果变化后的高度小于变化前的高度，需要设置组件高度自适应于选中区域的高度
 */
export default class UIMultipleSelectPanelComponent extends UIPanelComponent {
  public canMoveChildren = false;

  constructor(data: IComponentData, public parent?: UIContainerComponent, public options?: IUICompConstructOptions) {
    super(data, parent, options);

    // 兼容前版本value为空的情况,处理选中项顺序问题
    const listComps = this.listComp.components as UIContainerComponent[];
    const valueIds = listComps
      .filter((item) => item.selected)
      .map((item) => item.id)
      .join(',');
    if (valueIds && !this.value) {
      this.$data.value = valueIds;
    }

    // 兼容处理之前版本高度由选中区域生成自适应高度问题
    // 标记该版本为1，前版本生成的组件先进行高度计算，当组件高度发生变化，更改版本号为1，后续初始化不再进行高度计算
    if (data.updateVersion !== 1) {
      this.reArrangeMultipleSelectPanel();
    }
  }

  get mainComp() {
    return this.getComponentByAlias('main') as UIContainerComponent;
  }

  get listComp() {
    return this.getComponentByAlias('list') as UIContainerComponent;
  }

  get textComp() {
    return this.mainComp.components.find((comp) => comp.type === CPureText)! as UIContainerComponent;
  }

  /**
   * 值对应的comps
   */
  get valueComps() {
    const value = this.value as string;
    const valueIds = compact(value.split(','));
    const listComps = this.listComp.components as UIContainerComponent[];
    return valueIds.reduce((prev, id) => {
      const selectItem = listComps.find((item) => item.id === id);
      if (selectItem) prev.push(selectItem);
      return prev;
    }, [] as UIContainerComponent[]);
  }

  public getVerticalPadding(padding?: IPadding) {
    const _padding = padding || this.padding;
    return (_padding?.top || 0) + (_padding?.bottom || 0);
  }

  /**
   * 计算选中项的高度
   * @param comp
   * @param maxWidth
   * @param style
   */
  private calcValueCompHeight(comp: UIMultipleSelectPanelComponent, maxWidth?: number, style?: React.CSSProperties) {
    const { valueComps } = comp;
    let areaWidth = maxWidth;
    if (!areaWidth) {
      const excludeMainWidth = this.padding.left + this.padding.right + ICON_PADDING_RIGHT;
      areaWidth = comp.size.width - excludeMainWidth;
    }

    const executiveStyle =
      style || StyleHelper.initCSSStyleParser({ textStyle: comp.properties.textStyle }).getTextStyle();
    const mergeStyle = { ...executiveStyle, lineHeight: 'normal' };

    const { height } = measureTextSize(mergeStyle, 'j');
    let tempWidth = 0;
    let row = 1;
    if (maxWidth === 0) {
      row = valueComps.length;
    } else {
      valueComps.forEach((value, index) => {
        const { width } = measureTextSize(mergeStyle, transBlankChart(value.components?.[0].value as string), {
          cannotReplaceSpaceChar: true,
        });
        const valueWidth = width + EXCLUDE_ITEM_TEXT_WIDTH;
        tempWidth += tempWidth === 0 ? valueWidth : valueWidth + ITEM_SPACE;
        if (tempWidth > areaWidth! && index !== 0) {
          row++;
          tempWidth = valueWidth;
        }
      });
    }
    return row * (height + EXCLUDE_ITEM_TEXT_HEIGHT + ITEM_SPACE) - ITEM_SPACE;
  }

  private reArrangeMultipleSelectPanel() {
    const oldSize = this.size;
    const height = this.calcValueCompHeight(this);
    const calculatedHeight = height + this.getVerticalPadding();
    if (oldSize.height !== calculatedHeight) {
      this.$data.size.height = calculatedHeight;
      this.mainComp.$data.size.height = calculatedHeight;
      this.textComp.$data.size.height = height;
    }
  }

  /**
   * 调整panelComponent类的resizeHandler2方法生成的patch
   * @param comp
   * @param patches
   * @param height
   * @param width
   */
  private updateCompSizePatch(comp: UIContainerComponent, patches: ArtboardPatches, height?: number, width?: number) {
    const doPatches = patches.do[comp.id];
    if (doPatches) {
      doPatches.forEach((patch) => {
        if (patch.op === 'replace' && (patch.path === '/size' || patch.path === './size')) {
          if (!isUndefined(height)) patch.value.height = height;
          if (!isUndefined(width)) patch.value.width = width;
        }
      });
    }
  }

  /**
   * 刷新子组件
   * @override UIContainerComponent.refreshComponents
   */
  refreshComponents() {
    super.refreshComponents();
    // this.reArrangeMultipleSelectPanel(this.data);
  }

  addComponents() {
    return {
      patches: {
        [this.ownerArtboardID]: DEFAULT_PATCHES,
      },
    };
  }

  moveChildren() {
    return {
      [this.ownerArtboardID]: DEFAULT_PATCHES,
    };
  }

  /**
   * 计算value
   */
  calcLatestValue(triggerComp: UIComponent, checked: boolean) {
    const oldValue = this.value as string;
    const oldValueIds = compact(oldValue.split(','));
    const triggerId = triggerComp.id;
    const index = oldValueIds.indexOf(triggerId);
    if (checked && index === -1) {
      oldValueIds.push(triggerId);
    } else if (!checked && index !== -1) {
      oldValueIds.splice(index, 1);
    }
    return oldValueIds.join(',');
  }

  /**
   * 更新版本的patches
   */
  getUpdateVersionPatches() {
    return {
      do: { [this.id]: [Ops.replace('/updateVersion', 1)] },
      undo: { [this.id]: [Ops.replace('/updateVersion', this.$data.updateVersion)] },
    };
  }

  /**
   * 拖拽完成时还需生成的patches
   * @param patches
   */
  resetPatchesInDragEnd(patches: ArtboardPatches) {
    mergePatches(patches, this.getUpdateVersionPatches());
  }

  /**
   * 文本区域高度变化时，对整个组件高度的影响
   * @param height 高度
   * @param padding
   */
  getPatchesByHeightChange(height: number, padding?: IPadding): ArtboardPatches | null {
    const calculatedHeight = height + this.getVerticalPadding(padding);
    const { mainComp, textComp } = this;
    const path = '/size/height';
    // 设置textComp的高总是选中项的高
    const textPatches = {
      do: { [textComp.id]: [Ops.replace(path, height)] },
      undo: { [textComp.id]: [Ops.replace(path, textComp.size.height)] },
    };
    if (calculatedHeight > this.size.height) {
      const mainPatches = {
        do: {
          [this.id]: [Ops.replace(path, calculatedHeight)],
          [mainComp.id]: [Ops.replace(path, calculatedHeight)],
        },
        undo: {
          [this.id]: [Ops.replace(path, this.size.height)],
          [mainComp.id]: [Ops.replace(path, mainComp.size.height)],
        },
      };
      mergePatches(textPatches, mainPatches);
      mergePatches(textPatches, this.getUpdateVersionPatches());
    }
    return textPatches;
  }

  /**
   * 克隆组件并设置其中子项值的变化
   * @param itemId
   * @param type
   * @param value
   */
  cloneCompWidthItemChange(
    itemId: string,
    type: 'selected' | 'value',
    value: boolean | string,
  ): UIMultipleSelectPanelComponent {
    const cloneComp = cloneDeep(this);
    const listComp = cloneComp.getComponentByAlias('list') as UIContainerComponent;
    const listComps = listComp?.components as UIContainerComponent[];
    listComps.forEach((comp) => {
      if (comp.id === itemId) comp.$data[type] = value;
    });
    return cloneComp;
  }

  /**
   * 高级编辑时，选中项更改对组件高度的影响
   * @param item
   * @param checked
   */
  getUpdateItemCheckedPatches(item: UIComponent, checked: boolean) {
    const cloneComp = this.cloneCompWidthItemChange(item.id, 'selected', checked);
    const latestValue = this.calcLatestValue(item, checked);
    const patches = {
      do: { [this.id]: [Ops.replace('/value', latestValue)] },
      undo: { [this.id]: [Ops.replace('/value', this.value)] },
    };
    cloneComp.$data.value = latestValue;
    const height = this.calcValueCompHeight(cloneComp);
    const sizePatches = this.getPatchesByHeightChange(height);
    if (sizePatches) {
      mergePatches(patches, sizePatches);
    }
    return patches;
  }

  /**
   * 高级编辑时，修改子项组件值对组件高度的影响
   * @param comp 子项下的pureTextComp
   * @param value
   * @returns
   */
  getUpdateItemTextPatches(comp: UIComponent, value: string) {
    if (!comp.parent!.selected) return DEFAULT_PATCHES;
    const cloneComp = this.cloneCompWidthItemChange(comp.id, 'value', value);
    const height = this.calcValueCompHeight(cloneComp);
    return this.getPatchesByHeightChange(height);
  }

  /**
   * 文本样式修改对组件高度的影响
   * @param style
   */
  getUpdateTextStylePatches(style: React.CSSProperties) {
    const height = this.calcValueCompHeight(this, undefined, style);
    return this.getPatchesByHeightChange(height);
  }

  /**
   * 更改修改组件高度
   * @param padding 组件padding属性
   */
  getUpdatePaddingPatches(padding: IPadding) {
    const excludeMainWidth = (padding.left || 0) + (padding.right || 0) + ICON_PADDING_RIGHT;
    const maxWidth = Math.max(this.size.width - excludeMainWidth, 0);
    const height = this.calcValueCompHeight(this, maxWidth);
    return this.getPatchesByHeightChange(height, padding);
  }

  /**
   * 移除子项，修改组件的value
   * @param comp 子项panelComponent
   * @returns
   */
  getRemoveItemPatches(comp: UIComponent) {
    if (!comp.selected) return null;
    const latestValue = this.calcLatestValue(comp, false);
    return {
      do: { [this.id]: [Ops.replace('/value', latestValue)] },
      undo: { [this.id]: [Ops.replace('/value', this.value)] },
    };
  }

  /**
   * 用于拖拽过程中自适应高度
   * @override UIPanelComponent.resizeHandler2
   */
  resizeHandler2(
    offset: IBoundsOffset,
    layout: IBasicLayout,
    options: ResizeOptions,
    backupAllCompsLayout?: WeakMap<UIComponent, IBasicLayout>,
    dragIndex?: number,
  ): ComponentResizeResult {
    const resizeResult = super.resizeHandler2(offset, layout, options, backupAllCompsLayout);
    const { mainComp, textComp } = this;
    const patches = resizeResult.patches || DEFAULT_PATCHES;
    const excludeMainWidth = this.padding.left + this.padding.right + ICON_PADDING_RIGHT;
    const maxWidth = Math.max(resizeResult.size.width - excludeMainWidth, 0);
    const height = this.calcValueCompHeight(this, maxWidth);
    // 在宽度变化时，已选项高度大于组件原本高度，需要调整组件高度，将super执行生成的patches进行调整
    if (NEED_RESET_DRAG_INDEX.includes(dragIndex!)) {
      const calculatedHeight = height + this.getVerticalPadding();
      if (calculatedHeight > this.size.height) {
        resizeResult.size.height = calculatedHeight;
        this.updateCompSizePatch(mainComp, patches, calculatedHeight);
      }
    }
    this.updateCompSizePatch(textComp, patches, height, maxWidth);
    return resizeResult;
  }
}
