import IDesignManagerBase from './types/base';

/**
 * 设计资源管理器
 */
class DesignManager {
  private static _instance: IDesignManagerBase;
  private static _revisionInstance: IDesignManagerBase; // 用于历史版本 symbol 展示
  private static _isRevision: boolean = false; // 是否在展示历史版本

  /**
   *
   * @param instance 根据使用场景注入一个管理器
   */
  static register(instance: IDesignManagerBase) {
    DesignManager._instance = instance;
  }

  /**
   * 获取管理器实例
   */
  static getInstance<T extends IDesignManagerBase>(): T {
    if (!DesignManager._instance) {
      throw 'DesignManager not register!!';
    }
    return DesignManager._instance as T;
  }

  /**
   *
   * @param instance 根据使用场景注入一个管理器
   */
  static registerRevision(instance: IDesignManagerBase) {
    DesignManager._revisionInstance = instance;
  }

  /**
   * 获取管理器实例
   */
  static getRevisionInstance<T extends IDesignManagerBase>(): T {
    if (!DesignManager._revisionInstance) {
      throw 'DesignManager not register!!';
    }
    return DesignManager._revisionInstance as T;
  }

  /**
   * 修改是否为看历史版本状态
   */
  static changeRevisionState(isRevision: boolean): void {
    this._isRevision = isRevision;
  }

  /**
   * 获取是否是历史版本状态
   */
  static getRevisionState(): boolean {
    return this._isRevision;
  }
}

export default DesignManager;
