import { ArtboardOperations, ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import PropertyBase, { IRange } from '@fbs/rp/models/properties/base';
import { PropertyValue } from '@fbs/rp/models/property';

import { UIComponent, UIContainerComponent } from '@editor/comps';
import i18n from '@i18n';

import { PredefinedStates } from '@consts/state';
import { SpriteThumb } from '@/consts/spriteIcons';
import { getDefaultDataFromTemplate } from '@/libs/helper';

import { IComponentItem } from '../../types';
import { CScore } from '../../constants';

function onPropertyUpdate(comp: UIComponent, key: string, value: PropertyValue): ArtboardPatches | null {
  if (key === 'iconSize') {
    const group = comp as UIContainerComponent;
    const range = (value as PropertyBase).value as IRange;
    return group.components.reduce(
      (acc, curr) => {
        return {
          do: {
            ...acc.do,
            [curr.id]: [Ops.replace('/size', { ...curr.size, width: range.value, height: range.value })],
          },
          undo: {
            ...acc.undo,
            [curr.id]: [Ops.replace('/size', curr.size)],
          },
        };
      },
      {
        do: {},
        undo: {},
      },
    );
  }
  return null;
}

/**
 * 演示时预设状态的触发
 * @param {UIComponent} comp
 * @param {UIComponent} triggerComp
 * @param {string} stateName
 * @param {number} selectedIndex
 * @returns {ArtboardOperations | null}
 */
function onTriggerState(
  comp: UIComponent,
  triggerComp: UIComponent,
  stateName: string,
  selectedIndex: number,
): ArtboardOperations | null {
  const group = comp as UIContainerComponent;
  const options: ArtboardOperations = {};
  if (stateName === 'checked') {
    for (let i = 0; i < selectedIndex; i++) {
      const comp = group.components[i];
      options[comp.id] = [Ops.replace('/selected', true), Ops.replace('/_currentState', 'checked')];
      if (comp.states['checked']) {
        options[comp.id].push(Ops.replace('/states/checked/enabled', true));
      }
    }
    for (let i = selectedIndex + 1; i < group.components.length; i++) {
      const comp = group.components[i];
      options[comp.id] = [Ops.replace('/selected', false), Ops.replace('/_currentState', undefined)];
      if (comp.states['checked']) {
        options[comp.id].push(Ops.replace('/states/checked/enabled', false));
      }
    }
  } else if (stateName === 'unchecked') {
    if (
      group.components.some((item, i) => {
        return item.selected && i > selectedIndex;
      })
    ) {
      for (let i = 0; i < selectedIndex + 1; i++) {
        const comp = group.components[i];
        options[comp.id] = [Ops.replace('/selected', true), Ops.replace('/_currentState', 'checked')];
        if (comp.states['checked']) {
          options[comp.id].push(Ops.replace('/states/checked/enabled', true));
        }
      }
      for (let i = selectedIndex + 1; i < group.components.length; i++) {
        const comp = group.components[i];
        options[comp.id] = [Ops.replace('/selected', false), Ops.replace('/_currentState', undefined)];
        if (comp.states['checked']) {
          options[comp.id].push(Ops.replace('/states/checked/enabled', false));
        }
      }
    } else {
      for (let i = 0; i < group.components.length; i++) {
        const comp = group.components[i];
        options[comp.id] = [Ops.replace('/selected', false), Ops.replace('/_currentState', undefined)];
        if (comp.states['checked']) {
          options[comp.id].push(Ops.replace('/states/checked/enabled', false));
        }
      }
    }
  }
  if (Object.keys(options).length) {
    return options;
  }
  return null;
}

/**
 * 切换子项的选择
 * @param {UIComponent} group
 * @param {UIComponent} comp
 * @returns {ArtboardPatches | null}
 */
function onChildSelectedChange(group: UIComponent, comp: UIComponent): ArtboardPatches | null {
  const siblingComps = (group as UIContainerComponent).components;
  const index = siblingComps.indexOf(comp);
  const patches: ArtboardPatches = { do: {}, undo: {} };
  for (let i = 0; i < index; i++) {
    const comp = siblingComps[i];
    const opt = comp.changeStateEnabled(PredefinedStates.checked, true);

    if (opt) {
      patches.do[comp.id] = opt.do;
      patches.undo[comp.id] = opt.undo;
    }
  }
  for (let i = index + 1; i < siblingComps.length; i++) {
    const comp = siblingComps[i];
    const opt = comp.changeStateEnabled(PredefinedStates.checked, false);
    if (opt) {
      patches.do[comp.id] = opt.do;
      patches.undo[comp.id] = opt.undo;
    }
  }
  if (Object.keys(patches.do).length) {
    return patches;
  }
  return null;
}

export const ScoreCfg: IComponentItem = {
  type: CScore,
  name: i18n('resource.components.score'),
  thumb: {
    spriteIconClass: SpriteThumb.Score.className,
    dragPosition: SpriteThumb.Score.position,
  },
  isList: true,
  item: `{
      type: @@C.Icon,
      selected: false,
      position:{
        x: {0},
        y: 0,
      },
      size: {
        width: 12,
        height: 12,
        lockedRatio: true,
      },
      properties: {
        icon: {
          ref: '@properties.color',
        },
      },
      states:{
        checked:{
          enabled: true,
          properties:{},
          value: {fontName: 'lightIconFont',iconCode: 60682,}
        }
      },
      value: {fontName: 'lightIconFont',iconCode: 60686,},
    }`,
  template: `{
    type: @@C.StackPanel,
    size: { width: 100, height: 12 },
    layout:{
      vertical: 'middle',
      horizontal: 'left',
      fixedWidth: true,
      fixedHeight: true,
      auto: true,
      responsive: true,
    },
    sealed: true,
    properties: {
      layout: {
        hidden: false,
        disabled: false,
        direction: 'horizontal',
        horizontalGap: 10,
        verticalAlign: 'middle',
        disabledSwitch: true,
      },
      color: {
        prop: 'icon',
        name: '${i18n('property.propertyNames.color')}',
        color: @@SystemColors.DefaultSelectColor,
      },
      iconSize: {
        prop: 'number',
        name: '${i18n('property.propertyNames.iconSize')}',
        value:{
          min: 10,
          max: 100,
          step: 1,
          value: 12,
        },
      }
    },
    components: [
      ${new Array(5)
        .fill('', 0, 5)
        .map((item, i) => `@@ITEM-${22 * i}`)
        .join('\n,')},
    ],
    select: {
      target: 'child',
      minCount: 0,
      maxCount: -1,
      autoUnselect: false,
      reversible: true,
      enabled: true,
    },
  }`,
  editor: {
    onPropertyUpdate,
    onChildSelectedChange,
  },
  preview: {
    onTriggerState,
  },
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
};

const defaultData = getDefaultDataFromTemplate(ScoreCfg);
