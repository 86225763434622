import PropertyBase from './base';

export enum LinePointType {
  none = 'none',
  solidArrow = 'solidArrow',
  hollowArrow = 'hollowArrow',
  solidTriangle = 'solidTriangle',
  hollowTriangle = 'hollowTriangle',
  solidDot = 'solidDot',
  hollowDot = 'hollowDot',
  solidCube = 'solidCube',
  hollowCube = 'hollowCube',
  solidDiamond = 'solidDiamond',
  hollowDiamond = 'hollowDiamond',
  perpendicular = 'perpendicular',
}

export default interface ILine extends PropertyBase {
  startArrow?: boolean;
  endArrow?: boolean;
  startPointType?: LinePointType;
  endPointType?: LinePointType;
}

export const LinePropertyName = 'line';
