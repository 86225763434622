import * as React from 'react';
import * as ReactDom from 'react-dom';
import { cloneDeep, isEqual, isUndefined } from 'lodash';
import classnames from 'classnames';

import { StyleHelper } from '@/helpers/styleHelper';
import i18n from '@/i18n';
import { PureColor, RGBA2HEX } from '@/utils/graphicsUtils';
import { IRange } from '@/fbs/rp/models/properties/base';
import { EDatePickerType } from '@/fbs/rp/models/properties/common';
import { IProperties } from '@/fbs/rp/models/property';

import { IComponentProps } from '../../types';
import { DeviceType } from './config';
import { getDateFormat } from './utils';
import PhoneDatePicker from './PhoneDatePicker';
import WebDatePicker from './WebDatePicker';

import './index.scss';

export interface IDateInfo {
  year: number;
  month: number;
  date: number;
}

interface IDatePikerState {
  showDatePickerComponent: boolean;
  setTransformStyle: React.CSSProperties;
  selectDate: IDateInfo | undefined;
  selectedRangeDate: { startDate: IDateInfo; endDate?: IDateInfo } | undefined;
  position: { top?: number; bottom?: number; left?: number; right?: number } | undefined;
  // comp: UIComponent;
}

const PICKER_HEIGHT = 360;
const WIDTH_NORMAL = 300;
const WIDTH_RANGE = 600;
const PICKER_PHONE_WIDTH = 302;
const PICKER_PHONE_HEIGHT = 520;

export default class DatePicker extends React.Component<IComponentProps, IDatePikerState> {
  private datePickerRef: React.RefObject<HTMLDivElement> = React.createRef();
  private pickerRef: React.RefObject<HTMLDivElement> = React.createRef();
  private phonePickerRef: React.RefObject<HTMLDivElement> = React.createRef();
  private tempSize = {};
  private tempPosition = {};
  private tempProperties: IProperties = {};
  constructor(props: IComponentProps) {
    super(props);
    this.tempSize = { ...props.comp.size };
    this.tempPosition = { ...props.comp.position };
    this.tempProperties = cloneDeep(props.comp.properties);
    this.state = {
      showDatePickerComponent: false,
      setTransformStyle: { height: 0 },
      selectDate: this.getInitializeSelectDate(),
      selectedRangeDate: this.getInitializeSelectedRangeDate(),
      position: undefined,
      // comp: cloneDeep(props.comp),
    };
  }

  private get parentSize() {
    return window.appData?.size || { width: 0, height: 0 };
  }

  componentDidMount() {
    if (!this.props.isPreview) {
      this.registerDoubleClickListener();
      window.addEventListener('pageZoomChange', this.handlePageChange);
      window.addEventListener('pagePositionChange', this.handlePageChange);
    }
    if (this.props.isPreview) {
      window.addEventListener('pageScaleChange', this.handlePageScaleChange);
      window.addEventListener('wheel', this.handleWindowWheel);
      // 预览页面，DeviceType.Web类型监听滚动时隐藏弹出层
      if (this.props.comp.properties.deviceType!.value !== DeviceType.Phone) {
        document.addEventListener('scroll', this.handleScrollChange, true);
      }
    }
  }

  componentDidUpdate(_prevProps: IComponentProps, prevState: IDatePikerState) {
    if (this.tempProperties.dateType?.value !== this.props.comp.properties.dateType?.value) {
      this.setInitializeSelectDate(this.tempProperties.dateType?.value as EDatePickerType);
      this.tempProperties = cloneDeep(this.props.comp.properties);
    }
    if (!isEqual(this.tempPosition, this.props.comp.position)) {
      this.setIsDragging();
      this.tempPosition = { ...this.props.comp.position };
    }
    if (!this.props.isPreview) {
      if (this.props.onlySelected && this.props.selected) {
        this.registerDoubleClickListener();
      } else {
        this.removeDoubleClickListener();
      }
      if (!isEqual(this.tempSize, this.props.comp.size)) {
        this.handleCloseInEditor();
        this.tempSize = { ...this.props.comp.size };
      }

      // 编辑器展开日期选项弹出框时，滚轮滚动导致选择弹窗消失问题处理
      if (prevState.showDatePickerComponent !== this.state.showDatePickerComponent) {
        const dom = this.phonePickerRef.current || this.datePickerRef.current;
        if (this.state.showDatePickerComponent) {
          dom?.addEventListener('wheel', this.handleWheel);
        } else {
          dom?.removeEventListener('wheel', this.handleWheel);
        }
      }
    }
  }

  componentWillUnmount() {
    this.removeDoubleClickListener();
    window.removeEventListener('pageScaleChange', this.handlePageScaleChange);
    window.removeEventListener('pageZoomChange', this.handlePageChange);
    window.removeEventListener('pagePositionChange', this.handlePageChange);
    document.removeEventListener('scroll', this.handleScrollChange, true);
    window.removeEventListener('wheel', this.handleWindowWheel);
  }
  private getInitializeSelectDate = () => {
    const { text } = this.props.comp;
    const textSplit = text.split(',');
    if (text === '') {
      return undefined;
    } else {
      return {
        year: parseInt(textSplit[0]),
        month: parseInt(textSplit[1]),
        date: parseInt(textSplit[2]),
      };
    }
  };

  private getInitializeSelectedRangeDate = () => {
    const { text } = this.props.comp;
    const textSplitFront = text.split('/')[0].split(',');
    if (text === '') {
      return undefined;
    } else {
      if (text.indexOf('/') === -1 || (text.indexOf('/') !== -1 && text.split('/')[1].split(',')[0] === 'undefined')) {
        return {
          startDate: {
            year: parseInt(textSplitFront[0]),
            month: parseInt(textSplitFront[1]),
            date: parseInt(textSplitFront[2]),
          },
          endDate: undefined,
        };
      } else {
        const textSplitNext = text.split('/')[1].split(',');
        return {
          startDate: {
            year: parseInt(textSplitFront[0]),
            month: parseInt(textSplitFront[1]),
            date: parseInt(textSplitFront[2]),
          },
          endDate: {
            year: parseInt(textSplitNext[0]),
            month: parseInt(textSplitNext[1]),
            date: parseInt(textSplitNext[2]),
          },
        };
      }
    }
  };

  private setInitializeSelectDate = (currentType: EDatePickerType) => {
    this.setState({
      // comp: cloneDeep(this.props.comp),
      selectDate: currentType === EDatePickerType.Normal ? undefined : this.getInitializeSelectDate(),
      selectedRangeDate: currentType === EDatePickerType.Normal ? this.getInitializeSelectedRangeDate() : undefined,
    });
  };

  private setIsDragging = () => {
    this.setState({
      // comp: cloneDeep(this.props.comp),
      showDatePickerComponent: false,
    });
  };

  static getDerivedStateFromProps(nextProps: IComponentProps): Partial<IDatePikerState> | null {
    if (!nextProps.isPreview && (!nextProps.onlySelected || !nextProps.selected)) {
      return {
        showDatePickerComponent: false,
      };
    }
    return null;
  }

  /**
   * 处理演示界面滚动且不是缩放时，隐藏日期组件
   * @param e
   */
  private handleWindowWheel = (e: WheelEvent) => {
    if (this.props.isPreview && !e.ctrlKey) {
      this.handleCloseInEditor();
    }
  };

  /**
   * 处理编辑器打开选择下拉框滚轮滚动下拉框消失问题
   * @param e
   */
  private handleWheel = (e: WheelEvent) => {
    e.stopPropagation();
  };

  /**
   * 滚动关闭弹窗
   * @param e
   */
  private handleScrollChange = (e: Event) => {
    const dom = e.target as HTMLElement;
    if (this.datePickerRef.current?.contains(dom)) {
      return;
    }
    if (this.props.isPreview) {
      return;
    }
    this.handleCloseInEditor();
  };

  private handleClick = () => {
    if (!this.props.isPreview || this.state.showDatePickerComponent || this.props.comp.disabled) {
      return;
    }
    this.setState({ showDatePickerComponent: true }, () => {
      this.datePickerRef.current?.focus();
    });
    window.setTimeout(() => {
      this.setState({ setTransformStyle: { height: (this.parentSize.height / 3) * 2 } });
    }, 0);
    if (this.props.isPreview) {
      this.doPopup();
    }
  };

  private handlePageScaleChange = () => {
    if (this.state.showDatePickerComponent && this.props.isPreview) {
      this.doPopup();
    }
  };

  //计算演示界面位置 包含缩放
  private doPopup = () => {
    const {
      comp: { disabled, size, properties },
      isPreview,
    } = this.props;
    const { height, width } = size;
    const { dateType } = properties;
    const { height: sizeHeight, width: sizeWidth } = this.parentSize;
    if (disabled) {
      return;
    }
    const dom = this.pickerRef.current;
    if (!dom) {
      return;
    }
    const parent = this.getParent();
    if (!parent) {
      return;
    }
    const { left, top } = dom.getBoundingClientRect();
    const { left: parentLeft, top: parentTop } = parent.getBoundingClientRect();
    const _left = left - parentLeft;
    const _top = top - parentTop + height * window.pageScale;
    const position: { left: number; top: number } = { left: _left, top: _top };
    const pickerWidth = (dateType?.value === EDatePickerType.Normal ? WIDTH_NORMAL : WIDTH_RANGE) * window.pageScale;
    const pickerHeight = PICKER_HEIGHT * window.pageScale;

    if (!isPreview) {
      position.left = _left;
      position.top = _top;
    } else {
      // 超过容器底部时，重新设置组件底部对齐
      if ((_top + pickerHeight) * (1 + window.pageScale) > parentTop + sizeHeight * window.pageScale) {
        position.top = top - pickerHeight;
      }

      // 顶部不够时
      if ((_top - pickerHeight) * (1 + window.pageScale) < 0) {
        position.top = top + height * window.pageScale;
      }

      // 超过容器右侧时，重新设置组件右侧对齐
      if ((_left + pickerWidth) * (1 + window.pageScale) > parentLeft + sizeWidth * window.pageScale) {
        position.left = left - parentLeft - pickerWidth + width * window.pageScale;
      }
    }

    // 都超过了容器可见，则不处理
    this.setState({
      showDatePickerComponent: true,
      position: {
        ...position,
      },
    });
  };

  private registerDoubleClickListener = () => {
    this.removeDoubleClickListener();
    window.addEventListener('dblclick', this.handleWindowDoubleClick, true);
  };

  private removeDoubleClickListener = () => {
    window.removeEventListener('dblclick', this.handleWindowDoubleClick, true);
  };

  private handlePageChange = () => {
    this.handleCloseInEditor();
  };

  private handleWindowDoubleClick = () => {
    this.doPopup();
  };

  private handleCloseInEditor = () => {
    if (!this.state.showDatePickerComponent) {
      return;
    }
    this.setState({
      // comp: cloneDeep(this.props.comp),
      showDatePickerComponent: false,
    });
  };

  private handleClose = () => {
    this.setState({
      setTransformStyle: { height: 0 },
    });
    window.setTimeout(() => {
      !this.props.isPreview && this.setState({ showDatePickerComponent: false });
    }, 500);
  };

  private handleSelectDate = (date?: IDateInfo) => {
    const { comp, isPreview, onValueEdited } = this.props;
    this.setState({ selectDate: date }, () => {
      if (isPreview) {
        return;
      }
      date && onValueEdited(comp, `${date.year},${date.month},${date.date}`);
    });
    isPreview && comp.properties.dateType?.value === EDatePickerType.Normal && this.handleClose();
  };

  private handleSelectedRangeDate = (startDate: IDateInfo, endDate?: IDateInfo) => {
    const { comp, isPreview, onValueEdited } = this.props;
    this.setState({ selectedRangeDate: { startDate, endDate } }, () => {
      if (isPreview) {
        return;
      }
      comp.properties.dateType?.value === EDatePickerType.Range &&
        onValueEdited(
          comp,
          `${startDate.year},${startDate.month},${startDate.date}/${endDate?.year},${endDate?.month},${endDate?.date}`,
        );
    });
    endDate && isPreview && this.handleClose();
  };

  private handleClearDateInfo = () => {
    this.setState({ selectedRangeDate: undefined, selectDate: undefined }, () => {
      if (this.props.isPreview) {
        return;
      } else {
        this.props.onValueEdited(this.props.comp, '');
      }
    });
  };

  private getParent = () => {
    if (this.props.isPreview) {
      const deviceType = this.props.comp.properties.deviceType;
      if (deviceType?.value === DeviceType.Phone) {
        return document.querySelector('.preview-device-shell.current-page')!;
      }
      // web类型挂载到body，阻止弹出层上滚轮操作影响画板上滚动条
      return document.body;
    } else {
      return document.querySelector('#workspace')!;
    }
  };
  private handleOutsideClick = () => {
    if (!this.props.isPreview) {
      return;
    }
    this.setState({ setTransformStyle: { height: 0 } });
    window.setTimeout(() => {
      this.setState({ showDatePickerComponent: false });
    }, 500);
  };

  private renderDatePickerType = () => {
    const parent = this.getParent();
    if (!parent) {
      return null;
    }
    const { deviceType, phoneDatePickerMark, dateFormat, dateType, showSelectedYear } = this.props.comp.properties;
    const { size } = this.props.comp;
    const { isPreview, showMobileCursor } = this.props;
    const { setTransformStyle, position, selectDate, selectedRangeDate } = this.state;
    const { height, width } = this.parentSize;

    if (deviceType) {
      switch (deviceType.value) {
        case DeviceType.Phone:
          return ReactDom.createPortal(
            <div
              ref={this.phonePickerRef}
              className={classnames('date-picker-phone', {
                'date-picker-phone-not-preview': !isPreview,
                'date-picker-phone-preview': isPreview,
              })}
              style={
                isPreview
                  ? {
                      height: height,
                      transformOrigin: 'top',
                      transform: `scale(${window.pageScale})`,
                    }
                  : {}
              }
            >
              {!phoneDatePickerMark?.disabled && isPreview && (
                <div
                  className="date-picker-phone-mask"
                  style={{
                    backgroundColor: RGBA2HEX(phoneDatePickerMark?.value as PureColor),
                    width: width,
                    height: height,
                  }}
                ></div>
              )}
              <div
                className="date-picker-phone-content"
                ref={this.datePickerRef}
                tabIndex={-1}
                onBlur={this.handleOutsideClick}
                style={
                  isPreview
                    ? {
                        width: width,
                        ...setTransformStyle,
                      }
                    : {
                        ...position,
                        minWidth: PICKER_PHONE_WIDTH,
                        height: PICKER_PHONE_HEIGHT,
                      }
                }
              >
                <PhoneDatePicker
                  deviceType={deviceType.value}
                  dateFormat={dateFormat?.value}
                  selectedInfo={selectDate}
                  endDateInfo={selectedRangeDate?.endDate}
                  startDateInfo={selectedRangeDate?.startDate}
                  dateType={dateType?.value as EDatePickerType}
                  showSelectedYear={showSelectedYear?.disabled as boolean}
                  isPreview={isPreview}
                  onSelectDate={this.handleSelectDate}
                  onSelectedRangeDate={this.handleSelectedRangeDate}
                  onClearDateInfo={this.handleClearDateInfo}
                />
              </div>
            </div>,

            parent,
          );
        case DeviceType.Web:
          return ReactDom.createPortal(
            <div
              className={classnames('date-picker-web component-popup-container', {
                'date-picker-web-not-preview': !isPreview,
                'date-picker-web-preview': isPreview,
                mobile: showMobileCursor,
              })}
              style={
                isPreview
                  ? {
                      ...position,
                      transform: `scale(${window.pageScale})`,
                    }
                  : { ...position }
              }
              ref={this.datePickerRef}
              tabIndex={-1}
              onBlur={this.handleOutsideClick}
            >
              <WebDatePicker
                datePickerWidth={size.width}
                deviceType={deviceType.value}
                startDateInfo={selectedRangeDate?.startDate}
                endDateInfo={selectedRangeDate?.endDate}
                dateFormat={dateFormat?.value}
                selectedInfo={selectDate}
                dateType={dateType?.value as EDatePickerType}
                isPreview={isPreview}
                onSelectedRangeDate={this.handleSelectedRangeDate}
                onSelectDate={this.handleSelectDate}
                onClearDateInfo={this.handleClearDateInfo}
              />
            </div>,
            parent,
          );
        default:
          return null;
      }
    }
  };

  private renderDate = (index: 0 | 1, dateType: EDatePickerType) => {
    const { placeholder, placeHolderStyle, dateFormat, showSelectedYear, deviceType } = this.props.comp.properties;
    const { selectDate, selectedRangeDate } = this.state;
    const parser = StyleHelper.initCSSStyleParser(this.props.comp.properties);

    const textStyle = {
      ...parser.getTextStyle(),
    };
    const placeHolderTextStyle = cloneDeep(textStyle);
    delete placeHolderTextStyle.color;

    switch (dateType) {
      case EDatePickerType.Normal:
        if (!selectDate) {
          return (
            <div style={{ color: RGBA2HEX(placeHolderStyle?.value as PureColor), ...placeHolderTextStyle }}>
              {placeholder?.value}
            </div>
          );
        } else {
          return (
            <div style={textStyle}>
              {getDateFormat(
                dateFormat?.value,
                selectDate,
                showSelectedYear?.disabled,
                undefined,
                deviceType?.value as DeviceType,
              )}
            </div>
          );
        }
      case EDatePickerType.Range:
        if (index === 0) {
          return selectedRangeDate ? (
            <div style={textStyle}>
              {getDateFormat(
                dateFormat?.value,
                selectedRangeDate.startDate,
                showSelectedYear?.disabled,
                undefined,
                deviceType?.value as DeviceType,
              )}
            </div>
          ) : (
            <div style={{ color: RGBA2HEX(placeHolderStyle?.value as PureColor), ...placeHolderTextStyle }}>
              {i18n('resource.components.datePickerInfo.startDate')}
            </div>
          );
        } else {
          return selectedRangeDate?.endDate ? (
            <div style={textStyle}>
              {getDateFormat(
                dateFormat?.value,
                selectedRangeDate.endDate,
                showSelectedYear?.disabled,
                undefined,
                deviceType?.value as DeviceType,
              )}
            </div>
          ) : (
            <div style={{ color: RGBA2HEX(placeHolderStyle?.value as PureColor), ...placeHolderTextStyle }}>
              {i18n('resource.components.datePickerInfo.endDate')}
            </div>
          );
        }
      default:
        break;
    }
  };

  render() {
    const { size, opacity, properties } = this.props.comp;
    const { deviceType, datePickerIcon, iconSize, dateType } = properties;
    const { showDatePickerComponent } = this.state;
    const parser = StyleHelper.initCSSStyleParser(properties);

    const boxStyle = {
      ...parser.getFillStyle(),
      ...parser.getShadowStyle(),
      ...parser.getStrokeStyle(),
      ...parser.getRadiusStyle(size),
      opacity: isUndefined(opacity) ? 1 : opacity / 100,
      ...parser.getPaddingStyle(),
      ...size,
      lineHeight: `${size.height}px`,
    };

    return (
      <div
        className={classnames('date-picker', deviceType!.value as string, {
          'component-cursor-pointer': this.props.isPreview,
        })}
      >
        <div ref={this.pickerRef} className="date-picker-model" style={boxStyle} onClick={this.handleClick}>
          {this.renderDate(0, dateType?.value as EDatePickerType)}

          {dateType?.value === EDatePickerType.Range && (
            <>
              <label className="separator-text">~</label>
              {this.renderDate(1, dateType?.value as EDatePickerType)}
            </>
          )}
          {!datePickerIcon?.disabled && (
            <div
              className="date-picker-icon"
              style={{
                color: RGBA2HEX(datePickerIcon?.value as PureColor),
                fontSize: (iconSize?.value as IRange).value,
              }}
            ></div>
          )}
        </div>
        {showDatePickerComponent && this.renderDatePickerType()}
      </div>
    );
  }
}
