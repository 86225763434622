import * as React from 'react';
import * as ReactDom from 'react-dom';
import classnames from 'classnames';

import { titleBarHeight } from '@/utils/envUtils';

import withAutoClose, { IAutoCloseComponentProps, PopupManager } from '../withAutoClose';
import { IPosition } from '@fbs/common/models/common';
import { ComponentTheme } from '../common';

import './index.scss';

interface IPopupPanelProp extends IAutoCloseComponentProps, React.DOMAttributes<any> {
  position?: IPosition;
  forwardedRef?: React.RefObject<HTMLDivElement>;
  width?: number;
  height?: number;
  className?: string;
  isInPopup?: boolean;
  theme?: ComponentTheme;
  closeAllOnMount?: boolean;
}

interface IPopupMenuState {
  position: IPosition;
  opacity: number;
  direction: 'left' | 'right';
}

class PopupMenu extends React.Component<IPopupPanelProp, IPopupMenuState> {
  static defaultProps: Partial<IPopupPanelProp> = {
    position: {
      x: 0,
      y: 0,
    },
    width: 120,
    closeAllOnMount: true,
  };

  oldPosition?: IPosition;
  self: React.RefObject<HTMLDivElement>;

  constructor(props: IPopupPanelProp) {
    super(props);
    this.oldPosition = props.position;
    this.self = props.forwardedRef as React.RefObject<HTMLDivElement>;
    this.state = {
      opacity: 0,
      position: props.position || { x: 0, y: 0 },
      direction: 'right',
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.closeAllOnMount) PopupManager.manager.closeAllUnModal();
  }

  componentDidMount() {
    this.doAdjustPosition();
    this.setState({ opacity: 1 });
  }

  componentDidUpdate() {
    this.doAdjustPosition();
  }

  UNSAFE_componentWillReceiveProps(newProps: IPopupPanelProp) {
    const { position } = newProps;
    const { x, y } = position || { x: 0, y: 0 };
    if (this.oldPosition && x === this.oldPosition.x && y === this.oldPosition.y) {
      return;
    }
    this.setState({ opacity: 0 }, () => {
      this.doApplyPosition({ x, y });
      this.setState({ opacity: 1 });
    });
  }

  doApplyPosition = (position: IPosition) => {
    this.oldPosition = position;
    this.setState({ position });
  };

  doAdjustPosition = () => {
    if (!this.self.current) {
      return;
    }
    const bounds = this.self.current.getBoundingClientRect();
    const { width, height, right, bottom } = bounds;
    const { position, direction } = this.state;
    let { x, y } = position;
    let newDirection: 'left' | 'right' = direction;
    const winWidth = window.innerWidth;
    const winHeight = window.innerHeight;
    if (right > winWidth) {
      x -= width;
    }

    if (right + width > winWidth) {
      newDirection = 'left';
    } else {
      newDirection = 'right';
    }

    if (bottom > winHeight) {
      y -= height;
    }
    const minY = titleBarHeight;
    if (y < minY) {
      y = minY;
    }
    y = Math.round(y);
    x = Math.round(x);

    if (position.x !== x || position.y !== y || newDirection !== direction) {
      this.setState({
        position: {
          x,
          y,
        },
        direction: newDirection,
      });
    }
  };

  render() {
    const { className, theme, isInPopup, width, height } = this.props;
    const {
      position: { x: left, y: top },
      opacity,
    } = this.state;
    return ReactDom.createPortal(
      <div
        ref={this.self}
        className={classnames(
          'dsm-c-rp-popup-panel popup-with-body',
          className,
          theme,
          { 'in-popup': isInPopup },
          { animation: opacity !== 0 },
        )}
        style={{
          left,
          top,
          minWidth: width,
          opacity,
          height: height || 'auto',
        }}
        onMouseLeave={this.props.onMouseLeave}
      >
        {this.props.children}
      </div>,
      document.body,
    );
  }
}

export default withAutoClose(PopupMenu);
