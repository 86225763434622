import * as React from 'react';
import classnames from 'classnames';
import { isNumber } from 'lodash';
import { TextAlign } from '@fbs/rp/models/properties/text';
import { stopBubbleWhenSortCut } from '@helpers/shortCutHelper';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';
import { ComponentTheme } from '../common';
import { isMacOS } from '@utils/envUtils';

import './index.scss';

export interface IInputProp {
  value: string | undefined;
  type: string;
  name?: string;
  defaultValue?: string;
  placeHolder?: string;
  maxlength?: number;
  isShowLengthTips?: boolean;
  width?: number | string;
  readOnly?: boolean;
  disabled?: boolean;
  submitWithBlur?: boolean;
  canEmpty?: boolean;
  // 是否在创建后立即自动获取到焦点
  autoFocus?: boolean;
  // 是否在得到焦点时自动选中所有内容
  autoSelectWhenFocus?: boolean;
  allowAutoFixMinValue?: boolean;
  blurWhenEnter?: boolean;
  them?: 'normal' | 'no-border' | 'no-border-padding' | 'has-border';
  colorTheme?: ComponentTheme;
  className?: string;
  style?: React.CSSProperties;
  prefix?: string;
  suffix?: string;
  filterChar?: string;

  //设置input框内的文字大小，设置外面div的文字大小，input不会继承
  textAlign?: TextAlign;
  fontSize?: number;

  onCheck?: (value: string) => boolean;

  onTyped?: () => void;
  onChange?: (value: string) => void;
  onBlur?: (value: string, cancel?: boolean) => void;
  onFocus?: (e: React.FocusEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  onEnter?: (value: string) => void;
  onValidate?: (value: string) => string;
  onMouseDown?: (e: React.MouseEvent) => void;
  onContextMenu?: (e: React.MouseEvent) => void;
  onDoubleClick?: (e: React.MouseEvent) => void;
}

export interface IInputState {
  inputValue: string;
  currentLength: number;
  isFocus: boolean;
}

class Input extends React.PureComponent<IInputProp, IInputState> {
  public dom: React.RefObject<HTMLInputElement> = React.createRef();
  private isEscKey: boolean = false;
  private _focused: boolean = false;

  private isComposition: boolean = false;

  private timeID?: Timeout;
  private isCreated: boolean = false;

  static defaultProps: Partial<IInputProp> = {
    readOnly: false,
    disabled: false,
    them: 'normal',
    autoFocus: false,
    autoSelectWhenFocus: false,
    isShowLengthTips: false,
    canEmpty: true,
    blurWhenEnter: true,
  };

  get domValue() {
    return this.dom.current?.value;
  }

  get input() {
    return this.dom.current;
  }

  public refreshDisplayValue(value: string | undefined) {
    this.setState({
      inputValue: typeof value === 'undefined' ? '' : value,
    });
  }
  reloadValue() {
    const value = this.doFilterValue(this.props.value || '');
    this.setState(
      {
        inputValue: value,
      },
      () => {
        this.domValue = value;
        window.setTimeout(() => {
          const input = this.input;
          if (input) {
            input.select();
            input.focus();
          }
        }, 100);
      },
    );
  }

  set domValue(value: string | undefined) {
    if (this.dom.current) {
      this.dom.current.value = value || '';
    }
  }

  constructor(props: IInputProp) {
    super(props);
    let value = this.doFilterValue(props.value || '');
    this.state = {
      inputValue: value || '',
      currentLength: (value || '').length,
      isFocus: false,
    };
  }

  getBoundingClientRect() {
    return this.dom.current!.getBoundingClientRect();
  }

  componentDidMount() {
    this.resetFocus();
  }
  UNSAFE_componentWillReceiveProps(newProps: IInputProp) {
    if (newProps.value !== this.props.value) {
      let v = this.doFilterValue(newProps.value || '');
      this.setState({ inputValue: v || '', currentLength: (v || '').length });
    }
  }

  componentWillUnmount() {
    if (this.state.isFocus) {
      const value = this.domValue || '';
      const { value: propValue, onChange, onBlur } = this.props;
      const newValue = this.validateData(value);
      if (onChange && newValue !== undefined && propValue !== newValue) {
        onChange(newValue);
      }
      if (onBlur && newValue !== undefined) {
        onBlur(newValue, this.isEscKey);
      }
    }
    window.clearTimeout(this.timeID);
  }

  protected resetFocus() {
    const { autoFocus, autoSelectWhenFocus } = this.props;
    if (autoFocus) {
      this.focus();
      setTimeout(() => {
        if (autoSelectWhenFocus) {
          this.select();
        }
        this.isCreated = true;
      }, 30);
    } else {
      setTimeout(() => {
        this.isCreated = true;
      }, 30);
    }
  }

  private validateData = (value: string): string => {
    const { value: propValue, onCheck } = this.props;
    let newData = value;
    if (onCheck && !onCheck(newData)) {
      return propValue || '';
    }
    return newData;
  };

  private doApplyValueToState = (value: string) => {
    const { onCheck } = this.props;
    const newValue = this.doFilterValue(value);
    this.setState({ inputValue: newValue, currentLength: newValue.length }, () => {
      if (!this.isComposition && (!onCheck || onCheck(value))) {
        this.doNeedChange();
      }
    });
  };

  private handleInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    const { onValidate } = this.props;
    const v = onValidate ? onValidate(value) : value;
    this.doApplyValueToState(v);
  };

  private handleInput = () => {
    const { onTyped } = this.props;
    onTyped && onTyped();
  };

  private doFilterValue(value: string) {
    const { filterChar } = this.props;
    if (value && filterChar) {
      return value
        .split('')
        .filter((char) => {
          return filterChar.indexOf(char) !== -1;
        })
        .join('');
    }
    return value;
  }

  private doNeedChange = (data?: string) => {
    const { onChange, value } = this.props;
    let newValue = data || this.state.inputValue;
    if (onChange && newValue !== undefined && value !== newValue) {
      onChange(newValue);
    }
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode !== 9) {
      e.stopPropagation();
    }
    // @ts-ignore
    stopBubbleWhenSortCut(e);
    const keyCode = e.keyCode;
    const { onKeyDown, onEnter, submitWithBlur, blurWhenEnter } = this.props;
    this.isEscKey = false;
    if (keyCode === KeyCodeMap.VK_ENTER) {
      onEnter && onEnter(this.state.inputValue);
      if (blurWhenEnter && this.dom.current) {
        this.blur();
      }
    } else if (keyCode === KeyCodeMap.VK_ESCAPE) {
      this.isEscKey = true;
      if (this.dom.current) {
        this.blur();
        if (submitWithBlur) {
          return;
        }
      }
    }
    onKeyDown && onKeyDown(e);
  };

  handleKeyUp = (e: React.KeyboardEvent) => {
    e.stopPropagation();

    if (this.props.onKeyUp) {
      this.props.onKeyUp(e);
    }
  };

  handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const text = e.clipboardData.getData('text');
    if (!text) {
      return;
    }
    if (this.props.filterChar) {
      e.preventDefault();
      if (e.clipboardData) {
        const { maxlength } = this.props;
        let newText = text;
        const preValue = this.domValue;
        if (preValue) {
          const start = this.dom.current?.selectionStart;
          const end = this.dom.current?.selectionEnd;
          if (isNumber(start) && isNumber(end)) {
            newText = preValue.substring(0, start) + newText + preValue.substring(end);
          }
        }

        newText = this.doFilterValue(newText);
        if (maxlength) {
          newText = newText.substring(0, 6);
        }
        newText && this.setState({ inputValue: newText });
      }
    }
  };

  handleFocus = (e: React.FocusEvent) => {
    this._focused = true;
    const { onFocus } = this.props;
    const evt: React.FocusEvent = { ...e };
    this.setState({ isFocus: true }, () => {
      onFocus && onFocus(evt);
      if (this.props.autoSelectWhenFocus) {
        this.select();
      }
    });
  };

  handleBlur = (e: React.FocusEvent) => {
    if (!this.isCreated) {
      return;
    }
    this._focused = false;
    let { value } = e.target as HTMLInputElement;
    value = this.doFilterValue(value);
    const { value: propValue, onBlur, submitWithBlur, canEmpty, allowAutoFixMinValue } = this.props;
    this.setState({ isFocus: false });

    if (!canEmpty && !allowAutoFixMinValue && !value.trim()) {
      this.setState({ inputValue: propValue || '', currentLength: (propValue || '').length });
      onBlur && onBlur(propValue || '');
      return;
    }

    if (!this.isEscKey || submitWithBlur) {
      value = this.validateData(value);
      this.setState({ inputValue: value, currentLength: value.length });
      this.doNeedChange(value);
      onBlur && onBlur(value, this.isEscKey);
    } else {
      this.setState({ inputValue: propValue || '', currentLength: (propValue || '').length });
      onBlur && onBlur(propValue || '', true);
    }
  };

  handleCompositionStart = () => {
    this.isComposition = true;
  };

  handleCompositionEnd = () => {
    if (this.isComposition) {
      const value = this.domValue || '';
      this.doApplyValueToState(value);
    }
    this.isComposition = false;
  };

  handleMouseDown = (e: React.MouseEvent) => {
    // 影响表格移动
    // e.stopPropagation();
    const { onMouseDown } = this.props;
    onMouseDown && onMouseDown(e);
  };

  handleContextMenu = (e: React.MouseEvent) => {
    const { onContextMenu } = this.props;
    onContextMenu && onContextMenu(e);
  };

  focus = () => {
    if (!this.isEscKey) {
      this.dom.current?.focus();
    }
  };

  blur = () => {
    this.dom.current?.blur();
    this.timeID = window.setTimeout(() => {
      this.isEscKey = false;
    }, 15);
  };

  select = () => {
    document.getSelection()?.removeAllRanges(); // 解决二次失焦未全选的问题
    this.dom.current?.select();
  };

  get value() {
    return this.state.inputValue || '';
  }

  get focused(): boolean {
    return this._focused;
  }

  render() {
    const { inputValue, currentLength, isFocus } = this.state;
    const {
      width,
      disabled,
      readOnly,
      className,
      style,
      textAlign,
      fontSize,
      placeHolder,
      them,
      maxlength,
      isShowLengthTips,
      prefix,
      suffix,
      colorTheme,
      type,
      name,
      onDoubleClick,
    } = this.props;
    const styles = { ...(style || {}) };
    if (width) {
      styles.width = width;
    }
    return (
      <div
        className={classnames('dsm-c-rp-input', className, colorTheme, {
          'no-border': them === 'no-border',
          'no-border-padding': them === 'no-border-padding',
          'has-border': them === 'has-border',
          mac: isMacOS,
          readOnly: readOnly,
          disabled: disabled,
          focus: isFocus,
        })}
        style={styles}
        onDoubleClick={onDoubleClick}
        onClick={(e) => {
          e.stopPropagation();
          this.focus();
        }}
      >
        {prefix && <span className="input-prefix">{prefix}</span>}
        <input
          style={{ textAlign, fontSize }}
          className={classnames({
            'no-border': them === 'no-border',
            'no-border-padding': them === 'no-border-padding',
          })}
          name={name}
          ref={this.dom}
          defaultValue={this.props.defaultValue}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeHolder || ''}
          type={type || 'text'}
          draggable={false}
          maxLength={maxlength}
          value={inputValue}
          onPaste={this.handlePaste}
          onChange={this.handleInputChanged}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          onCompositionStart={this.handleCompositionStart}
          onCompositionEnd={this.handleCompositionEnd}
          onMouseDown={this.handleMouseDown}
          onContextMenu={this.handleContextMenu}
          onInput={this.handleInput}
        />
        {suffix && <span className="input-suffix">{suffix}</span>}
        {maxlength && isFocus && isShowLengthTips && (
          <span className="input-lenth">
            {currentLength}/{maxlength}
          </span>
        )}
      </div>
    );
  }
}

export default Input;
