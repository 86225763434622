// 组件内容编辑器类型
enum ValueEditorType {
  // 纯文字
  PureText,
  // 多项组件的子组件纯文本内容
  ListItemPureTextValues,
  // 富文本
  RichText,
  // 图片文件
  Image,
  // 快照
  Snapshot,
  // 图标
  Icon,
  // 开关
  Bool,
  //视频
  Video,
  //音频
  Audio,
  // 对象
  Object,
  //svg
  Svg,
  // 列表项
  ItemValue,
  //树
  Tree,

  Group,

  Path,

  Table,

  Symbol,
  //内容面板
  ContentPanel,

  None,
  // 图表
  Chart,
  // 导航菜单（普通、水平、垂直）
  Menu,
  // 轮播图
  CarouselChart,
  // 混合图表
  CombinationChart,
  // 地图
  MapChart,
}

export default ValueEditorType;

export function isTextEditorType(type: ValueEditorType): boolean {
  return type === ValueEditorType.PureText || type === ValueEditorType.RichText;
}
