// 基元
export const CRect = 'rect';
export const CEllipse = 'ellipse';
export const CIcon = 'icon';
export const CImage = 'image';
export const CSnapshot = 'snapshot';
export const CLine = 'line';
export const CPath = 'path';
export const CCompoundPath = 'compoundPath';
export const CPolygon = 'polygon';
export const CText = 'text';
export const CParagraph = 'paragraph';
export const CInput = 'input';
export const CTextArea = 'textarea';
export const CVideo = 'video';
export const CAudio = 'audio';
export const CSvg = 'svg';
export const CConnector = 'connector';
export const CButton = 'button';
export const CRectButton = 'rectButton';

// 隐藏的基元组件
export const CArc = 'arc';
export const CPureText = 'pureText';
// 容器
export const CGroup = 'group';
export const CCanvasPanel = 'canvas-panel';
export const CStackPanel = 'stack-panel';
export const CWrapPanel = 'wrap-panel';
export const CGridPanel = 'grid-panel';
export const CContentPanel = 'content-panel';
export const CContentPanelV2 = 'content-panel-v2';
export const CListLayoutPanel = 'list-layout-panel';
export const CSelectPanel = 'select-panel';
export const CFrame = 'frame';

export const CSymbol = 'symbol';

//图表
export const CBarChart = 'barChart';
export const CPieChart = 'chartPie';
export const CLineChart = 'lineChart';
export const CDoughnutChart = 'doughnutChart';
export const CBarChartHorizontal = 'barChartHorizontal';
export const CAreaChart = 'areaChart';
export const CRadarChart = 'radarChart';
export const CScatterChart = 'scatterChart';
export const CCombinationChart = 'combinationChart';
export const CGaugeChart = 'gaugeChart';
export const CMapChart = 'mapChart';
export const CChartTypes = [
  CBarChart,
  CPieChart,
  CLineChart,
  CDoughnutChart,
  CBarChartHorizontal,
  CAreaChart,
  CRadarChart,
  CScatterChart,
  CCombinationChart,
];

// 特殊组件
export const CSelect = 'select';
export const CSlider = 'slider';
export const CQRCode = 'qrcode';
export const CNumericStep = 'numericStep';
export const CKeyboard = 'keyboard';
export const CTree = 'tree';
export const CTreeItem = 'treeItem';
export const CTable = 'tabel';
export const CTableCell = 'TableCell';
export const CMultipleSelect = 'multipleSelect';
export const CScore = 'score';
export const CNavigationMenu = 'navigationMenu';
export const CVerticalMenu = 'verticalMenu';
export const CHorizontalMenu = 'horizontalMenu';
export const CImageTextTabs = 'imageTextTabs';
export const CVerticalTabs = 'verticalTabs';
export const CCarouselChart = 'carouselChart';
export const CList = 'list';
export const CBreadCrumbs = 'breadCrumbs';
export const CSegmentsControl = 'segments-control';
export const CSelectTab = 'selectTab';
export const CRadio = 'radio';
export const CRadioGroup = 'radio-button-group';
export const CCheckbox = 'checkbox';
export const CCheckboxGroup = 'checkbox-group';
export const CCollapse = 'collapse';

// 不在libs中或由基元组件修改属性创建的
export const CArtboard = 'artboard';
export const CHotArea = 'hot-area';

export const CViewport = 'viewPort';

export const CDatePicker = 'datePicker';
export const CTimePicker = 'timePicker';

// 形状组件
export const CRectangle = 'rectangle'; // 矩形
export const CRoundRectangle = 'roundRectangle'; // 圆角矩形
export const CStartOrEnd = 'start'; // 开始或结束
export const CFlowCircle = 'circle'; // 圆
export const CFlowEllipse = 'ellipse'; // 椭圆
export const CFlowTriangle = 'triangle'; // 三角形
export const CFlowPentagon = 'pentagon'; // 五边形
export const CFlowHexagon = 'hexagon'; // 六边形
export const CFlowOctagon = 'octagon'; // 八边形

export const CStickNote = 'stickNote'; //便签条
