export const MinSizeOfPath = 1;
export const MinSizeOfLine = 0;
export const MinSizeOfNormalComp = 1;
export const MinSizeOfStickNote = 50; // 便签条默认宽高

/** 顶部工具栏高度 */
export const ApplicationBarHeight = 40;
/** AI 输入区宽度 */
export const AIWidth = 482;
/** AI 输入区高度 */
export const AIHeight = 316;
