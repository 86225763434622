import * as React from 'react';

import { Dialog, IPopupComponent } from '@/dsm';

import i18n, { languageManager } from '@i18n';
import EditorContext from '@contexts/editor';
import appOptions from '@/helpers/appOptions';

import * as imgCH from '@/assets/image/icon_replace_tip.png';
import * as imgEN from '@/assets/image/icon_replace_tip_en.png';

import './index.scss';

const IconReplaceTipDialog = () => {
  const context = React.useContext(EditorContext);
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    const showTip = appOptions.showIconReplaceTip;
    setVisible(showTip);
  }, []);

  React.useEffect(() => {
    const popPanel = context.uiManager.listItemValueEditorPanel as IPopupComponent;
    popPanel.preventClose = visible;
  }, [visible]);

  const handleClose = () => {
    appOptions.showIconReplaceTip = false;
    setVisible(false);
  };

  if (!visible) return null;
  return (
    <Dialog
      title={i18n('tips.iconReplaceTip')}
      contentClassName="icon-replace-tip-dialog-content"
      titleClassName="icon-replace-tip-dialog-title"
      onClose={handleClose}
      backFade
    >
      <>
        <img src={languageManager.isEnLanguage ? imgEN : imgCH} />
        <footer>
          <span onClick={handleClose}>{i18n('general.gotIt')}</span>
        </footer>
      </>
    </Dialog>
  );
};

export default IconReplaceTipDialog;
