import * as React from 'react';
import { Dialog, CheckBox, Button, ProgressBar } from '@dsm';
import {
  selectImage,
  getLocalImageFileSize,
  uploadFile,
  selectVideo,
  selectAudio,
  AllowedImageTypes,
} from '@helpers/fileUploadHelper';
import ValueEditorType from '@consts/enums/valueEditorType';
import { ISize } from '@fbs/idoc/models/common';
import TargetAlert from '../../../../RightPanel/PropertyPanel/InteractionPanel/ActionList/TargetAlert';
import {
  MaxImageSize,
  ImageSizeExceedTips,
  MaxImageDimention,
  ImageDimensionExceedTips,
  MaxVideoSize,
  VideoSizeExceedTips,
  MaxAudioSize,
  AudioSizeExceedTips,
} from '@consts/file';
import { IComponentSize } from '@fbs/rp/models/component';
import AppOptions from '@helpers/appOptions';
import i18n from '@i18n';

import './index.scss';
import CoreEditor from '@editor/core';

export interface ISelectorEditorProps {
  type: ValueEditorType;
  editor: CoreEditor;
  compSize: IComponentSize;
  onCancel: () => void;
  onSelect?: (url: string, imgSize?: { height: number; width: number }, fileName?: string) => void;
}

export interface ISelectorEditorState {
  fileName?: string;
  selected?: string;
  originSize?: ISize;
  originFile?: File;
  url?: string;
  currentSize: ISize;
  popupTargetAlert?: boolean;
  alertContent?: string;
  fitComp: boolean;
  holdRetio: boolean;
  uploadProgress: number;
  uploading: boolean;
}

export default class SelectorEditor extends React.Component<ISelectorEditorProps, ISelectorEditorState> {
  constructor(props: ISelectorEditorProps) {
    super(props);
    this.state = {
      fileName: '',
      currentSize: {
        height: 0,
        width: 0,
      },
      fitComp: AppOptions.fileUploadOption.fitComp,
      holdRetio: AppOptions.fileUploadOption.holdRetio,
      uploadProgress: 0,
      uploading: false,
    };
  }

  info = {
    [ValueEditorType.Image.toString()]: i18n('resource.components.image'),
    [ValueEditorType.Video.toString()]: i18n('resource.components.video'),
    [ValueEditorType.Audio.toString()]: i18n('resource.components.audio'),
  };

  changeHoldRatio = (value: boolean) => {
    this.setState({ holdRetio: value });
  };

  changeFitComp = (value: boolean) => {
    this.setState({ fitComp: !value });
  };

  getScaleSize = (): ISize | undefined => {
    const { compSize } = this.props;
    const { originSize } = this.state;
    const componentSize: ISize = {
      width: compSize.width,
      height: compSize.height,
    };
    const defalutSize = {
      width: 100,
      height: 100,
    };
    const size: ISize = { ...(originSize || defalutSize) };
    let newSize = { height: 100, width: 100 };
    if (!componentSize || !size) {
      return;
    }
    const scale = size.height / size.width;
    const compScale = componentSize.height / componentSize.width;
    if (scale > compScale) {
      if (size.height < componentSize.height) {
        return size;
      }
      newSize.height = componentSize.height;
      newSize.width = newSize.height / scale;
    } else {
      if (size.width < componentSize.width) {
        return size;
      }
      newSize.width = componentSize.width;
      newSize.height = componentSize.width * scale;
    }
    return newSize;
  };

  updataUpLoadRrogress = (complet: number) => {
    this.setState({ uploadProgress: complet });
  };

  private doFileSelected = (file: File, imgSize: { width: number; height: number }, type: ValueEditorType) => {
    this.setState({ uploading: true });
    uploadFile(file, type, this.props.editor.isExample, this.updataUpLoadRrogress)
      .then((data) => {
        const { URL } = data;
        const { compSize } = this.props;
        const size = {
          width: data.size.width || compSize.width,
          height: data.size.height || compSize.height,
        };
        this.setState({
          url: URL,
          originFile: file,
          currentSize: size,
          originSize: size,
          uploading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ popupTargetAlert: true, alertContent: i18n('alert.failToUpload') });
      });
  };

  handleLoadImg = () => {
    selectImage(AllowedImageTypes)
      .then((file) => {
        if (file.size > MaxImageSize) {
          this.setState({ popupTargetAlert: true, alertContent: ImageSizeExceedTips });
          return;
        }
        const isSVG = file.name.toLowerCase().includes('.svg');
        const type = isSVG ? ValueEditorType.Svg : ValueEditorType.Image;
        getLocalImageFileSize(file)
          .then((res) => {
            if (res.size.width > MaxImageDimention || res.size.height > MaxImageDimention) {
              this.setState({ popupTargetAlert: true, alertContent: ImageDimensionExceedTips });
              return;
            }
            this.doFileSelected(file, res.size, type);
          })
          .catch(() => {});
      })
      .catch((error) => {
        this.setState({ popupTargetAlert: true, alertContent: error });
      });
  };

  handleLoadVideo = () => {
    selectVideo()
      .then((file: File) => {
        if (file.size > MaxVideoSize) {
          this.setState({ popupTargetAlert: true, alertContent: VideoSizeExceedTips });
          return;
        }
        this.setState({ uploading: true });
        uploadFile(file, ValueEditorType.Video, this.props.editor.isExample, this.updataUpLoadRrogress)
          .then((data) => {
            const { URL } = data;
            const { compSize } = this.props;
            const size = {
              width: data.size.width || compSize.width,
              height: data.size.height || compSize.height,
            };
            this.setState({ url: URL, originFile: file, currentSize: size, originSize: size, uploading: false });
          })
          .catch(() => {
            this.setState({ popupTargetAlert: true, alertContent: i18n('alert.failToUpload') });
          });
      })
      .catch((error) => {
        this.setState({ popupTargetAlert: true, alertContent: error });
      });
  };

  handleLoadAudio = () => {
    selectAudio()
      .then((file: File) => {
        if (file.size > MaxAudioSize) {
          this.setState({ popupTargetAlert: true, alertContent: AudioSizeExceedTips });
          return;
        }
        this.setState({ uploading: true });
        uploadFile(file, ValueEditorType.Audio, this.props.editor.isExample, this.updataUpLoadRrogress)
          .then((data) => {
            const { URL, name } = data;
            const { compSize } = this.props;
            const size = {
              width: data.size.width || compSize.width,
              height: data.size.height || compSize.height,
            };
            this.setState({
              url: URL,
              fileName: name,
              originFile: file,
              currentSize: size,
              originSize: size,
              uploading: false,
            });
          })
          .catch(() => {
            this.setState({ popupTargetAlert: true, alertContent: i18n('alert.failToUpload') });
          });
      })
      .catch((error) => {
        this.setState({ popupTargetAlert: true, alertContent: error });
      });
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    const { holdRetio, fitComp } = this.state;
    AppOptions.fileUploadOption = { holdRetio, fitComp };
    onCancel && onCancel();
  };

  handleLoad = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { type } = this.props;
    if (type === ValueEditorType.Image) this.handleLoadImg();
    if (type === ValueEditorType.Video) this.handleLoadVideo();
    if (type === ValueEditorType.Audio) this.handleLoadAudio();
  };

  handleOKBtnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.doSubmit();
  };

  handleCloseAlert = () => {
    this.setState({ popupTargetAlert: false, alertContent: '', uploading: false });
  };

  handleSubmit = () => {
    this.doSubmit();
  };

  doSubmit = () => {
    const { url, originSize, fitComp, fileName } = this.state;
    const holdRetio = false;
    if (!url || !this.props.onSelect || !originSize) {
      return;
    }
    if (this.props.onSelect) {
      let newSize;
      if (!fitComp) {
        newSize = originSize;
      } else if (!holdRetio) {
        newSize = {
          height: this.props.compSize.height,
          width: this.props.compSize.width,
        };
      } else {
        newSize = this.getScaleSize();
      }
      this.props.onSelect(url, newSize, fileName);
      this.handleCancel();
    }
  };

  renderContentByType = () => {
    const { type, editor } = this.props;
    const { url, originFile } = this.state;

    if (url && (originFile || editor.isExample)) {
      if (type === ValueEditorType.Image || type === ValueEditorType.Svg) {
        return <div className="preview-file" style={{ backgroundImage: `url(${url})` }} />;
      } else if (type === ValueEditorType.Video) {
        return (
          <div className="preview-file">
            <video style={{ pointerEvents: 'none' }}>
              <source src={url} type="video/mp4" />
            </video>
          </div>
        );
      } else if (type === ValueEditorType.Audio) {
        return (
          <div className="preview-file">
            <audio style={{ pointerEvents: 'none' }} preload="none" controls>
              <source src={url} type="audio/mpeg" />
            </audio>
          </div>
        );
      } else {
        return <div className="preview-file">{`URL: ${url}`}</div>;
      }
    } else {
      return (
        <div className="load-file">
          <span>{i18n('editor.clickAndUpload', this.info[type])}</span>
        </div>
      );
    }
  };

  renderByType = () => {
    const { url, fitComp, uploading, uploadProgress } = this.state;

    return (
      <div className="file-selector">
        <div>
          <div className="file-box" onClick={(e) => (uploading ? undefined : this.handleLoad(e))}>
            {uploading ? (
              <ProgressBar className="upload-progressbar" progress={uploadProgress} colorTheme="blue" />
            ) : (
              this.renderContentByType()
            )}
          </div>
        </div>
        {this.props.type !== ValueEditorType.Audio && (
          <div className="checkbox-group">
            <CheckBox
              theme="light"
              id="value"
              checked={!fitComp}
              text={i18n('editor.applyOriginalSize')}
              onChange={this.changeFitComp}
            />
          </div>
        )}
        <div className="button-bar footer-without-separator">
          <Button theme="dialog" width={60} activated={!!url} disabled={!url} onClick={this.handleOKBtnClick}>
            {i18n('general.ok')}
          </Button>
          <Button theme="dialog" onClick={this.handleCancel}>
            {i18n('general.cancel')}
          </Button>
        </div>
      </div>
    );
  };

  renderTargetAlert = () => {
    const { popupTargetAlert, alertContent } = this.state;
    if (!popupTargetAlert) {
      return null;
    }
    return <TargetAlert title={''} alertContent={alertContent || ''} onClose={this.handleCloseAlert} />;
  };

  render() {
    return (
      <>
        <Dialog width={500} onClose={this.handleCancel} onSubmit={this.handleSubmit} backFade allowDrag>
          {this.renderByType()}
        </Dialog>
        {this.renderTargetAlert()}
      </>
    );
  }
}
