/**
 * 高频事件调用帮助方法,将高频调用进行缓冲
 */
function optimizeCall(): { add: Function, remove: Function, call: any } {
  const callbacks: Function[] = [];
  let running = false;
  let callArgu: any;

  // run the actual callbacks
  function runCallbacks() {
    try {
      callbacks.forEach((callback) => {
        callback(...callArgu);
      });
    } finally {
      callArgu = null;
      running = false;
    }
  }

  // fired on resize event
  function call() {
    if (!running) {
      running = true;
      callArgu = arguments;
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(runCallbacks);
      } else {
        setTimeout(runCallbacks, 17);
      }
    }
  }

  // adds callback to loop
  function addCallback(callback: Function) {
    if (callback) {
      callbacks.push(callback);
    }
  }

  function removeCallback(callback: Function) {
    if (callback) {
      callbacks.splice(callbacks.indexOf(callback), 1);
    }
  }

  return {
    add: addCallback,
    remove: removeCallback,
    call,
  };
}

export default optimizeCall;
