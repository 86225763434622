import { ITeams } from '@fbs/idoc/models/team';
import { IUserInfo } from '@fbs/idoc/models/user';

import { ActionType } from '../types';
import { ShareAction } from './actions';

export interface IShareState {
  shareRunID: string;
  sharePassword: string;
  isLoadLink: boolean;
  teamInfo?: ITeams;
  userInfo?: IUserInfo;

  validatePassword: string;
  isPasswordValidate: boolean;
  validateResultAppID: string;
  isValidateLinkPassDone: boolean;
  previewNeedPassword: boolean;

  linkID: string;
  nextLinkID: string;
  hasShareLinkReset: boolean;
  waiting?: boolean;
  shortLinkID?: string;

  linkNodeIDs: string[];
}

const initialState: IShareState = {
  shareRunID: '',
  sharePassword: '',
  isLoadLink: false,
  teamInfo: undefined,
  userInfo: undefined,

  validatePassword: sessionStorage.getItem('runValidatePass') || '',
  isPasswordValidate: true,
  isValidateLinkPassDone: false,
  validateResultAppID: '',
  previewNeedPassword: false,

  linkID: '',
  nextLinkID: '',
  hasShareLinkReset: false,
  linkNodeIDs: [],
};

export default function (state = initialState, action: ShareAction): IShareState {
  switch (action.type) {
    case ActionType.Share_GetTeamInfo:
      return Object.assign({}, state, {
        teamInfo: action.payload,
      });
    case ActionType.Share_GetUserInfo:
      return Object.assign({}, state, {
        userInfo: action.payload,
      });
    case ActionType.Share_GetRunLink:
      return Object.assign({}, state, {
        isLoadLink: false,
      });
    case ActionType.Share_GetRunLinkDone:
      return Object.assign({}, state, {
        //@ts-ignore
        shareRunID: action.payload._id,
        sharePassword: action.payload.password ? action.payload.password : '',
        isLoadLink: true,
      });
    case ActionType.Share_SetLinkPassDone:
      return Object.assign({}, state, {
        //@ts-ignore
        shareRunID: action.payload._id,
        sharePassword: action.payload.password ? action.payload.password : '',
      });
    case ActionType.Share_GetLinkPass:
      return Object.assign({}, state, {
        isValidateLinkPassDone: false,
      });
    case ActionType.Share_GetLinkPassDone:
      return Object.assign({}, state, {
        validateResultAppID: action.payload,
        isPasswordValidate: true,
        isValidateLinkPassDone: true,
      });
    case ActionType.Share_ChangeValidatePassword:
      sessionStorage.setItem(`runValidatePass`, action.payload);
      return Object.assign({}, state, {
        validatePassword: action.payload,
        isPasswordValidate: true,
      });
    case ActionType.Share_ValidatePasswordError:
      return Object.assign({}, state, {
        validatePassword: '',
        isPasswordValidate: false,
      });
    case ActionType.Share_GetPwEnableDone: {
      const previewNeedPassword = action.payload.previewNeedPassword;
      return Object.assign({}, state, {
        previewNeedPassword,
        isProjectExist: action.payload,
      });
    }
    case ActionType.Share_ChangePwEnableDone: {
      const previewNeedPassword = action.payload;
      return Object.assign({}, state, {
        previewNeedPassword,
      });
    }
    case ActionType.Preview_resetLink: {
      return Object.assign({}, state, {
        nextLinkID: action.payload.linkID,
        linkID: action.payload.linkID,
        waiting: false,
      });
    }
    case ActionType.Preview_resetShortLink: {
      return Object.assign({}, state, {
        nextLinkID: action.payload.linkID,
        linkID: action.payload.linkID,
        shortLinkID: action.payload.shortLinkID,
        waiting: false,
      });
    }
    case ActionType.Preview_hasShareLinkReset: {
      return Object.assign({}, state, {
        hasShareLinkReset: action.payload.hasShareLinkReset,
      });
    }
    case ActionType.Preview_setCurrLinkID: {
      return Object.assign({}, state, {
        linkID: action.payload.linkID,
      });
    }
    case ActionType.Preview_EndRequestLink:
      return { ...state, waiting: false };
    case ActionType.Preview_StartRequestLink:
      return {
        ...state,
        waiting: true,
      };
    case ActionType.Share_SetLinkNodeIDs: {
      return Object.assign({}, state, {
        linkNodeIDs: action.payload.linkNodeIDs,
      });
    }
    default:
      return state;
  }
}
