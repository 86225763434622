import { ComponentOperations, ComponentPatches, Operation, Ops } from '@fbs/rp/utils/patch';
import { assign, defaultTo, isEqual } from 'lodash';
import { PropertyValue } from '@fbs/rp/models/property';
import { isSameProperty } from '@editor/corePartial/corePartialHelper/styleHelper';

export class ComponentPatchesClass {
  private _do: ComponentOperations;
  private _undo: ComponentOperations;

  get do() {
    return this._do;
  }

  get undo() {
    return this._undo;
  }

  get value() {
    return { do: this.do, undo: this.undo };
  }

  constructor(patches?: ComponentPatches) {
    this._do = defaultTo(patches?.do, []);
    this._undo = defaultTo(patches?.undo, []);
  }

  //如果该path是新的,不存在与原来的 patches 中,那么就新增
  doAddOperation = (path: string, newValue: any) => {
    const doOperations = this.do;
    const undoOperations = this.undo;
    if (!this.isPathExistedInPatches(path)) {
      doOperations.push(Ops.add(path, newValue));
      undoOperations.push(Ops.remove(path));
    }
  };

  /**
   * 新的修改 path 有冲突,应用新的 value 值
   * @param path 修改的路径
   * @param oldValue 组件中老的数据值
   * @param newValue 需要更新到的新的数据值
   */
  mergeNewValueToPatches(path: string, oldValue: PropertyValue, newValue: PropertyValue) {
    const isValueChanged = !isSameProperty(oldValue, newValue);
    if (isValueChanged && !this.isPathExistedInPatches(path)) {
      this.addOperation({
        do: Ops.replace(path, assign(oldValue, newValue)),
        undo: Ops.replace(path, oldValue),
      });
    }
  }

  doReplaceOperationByPath = (path: string, oldValue: any, newValue: any) => {
    const isValueChanged = !isEqual(oldValue, newValue);
    if (isValueChanged && !this.do.find((p) => p.path === path)) {
      this.do.push(Ops.replace(path, newValue));
      this.undo.push(Ops.replace(path, oldValue));
    }
  };

  /**
   * 判断当前patches 中是否有对 path 下的修改
   * @param path
   */
  isPathExistedInPatches(path: string) {
    return Boolean(this.do.find((op) => op.path === path));
  }

  /**
   * 无视 path 冲突,直接push进入
   */
  addOperation(Ops: { do: Operation; undo: Operation }) {
    this.do.push(Ops.do);
    this.undo.push(Ops.undo);
  }
  getAttrChangePatches(compId: string, path: string, valueChange: { oldVal: any; newVal: any }) {
    return new ComponentPatchesClass({
      do: [Ops.replace(path, valueChange.newVal)],
      undo: [Ops.replace(path, valueChange.oldVal)],
    });
  }
}

export const doAddOperationByNewValue = (path: string, newValue: any, patches: ComponentPatchesClass) => {
  if (!patches.isPathExistedInPatches(path)) {
    patches.do.push(Ops.add(path, newValue));
    patches.undo.push(Ops.remove(path));
  }
};
