import { Point, RPPoint } from '@helpers/point';
import { round } from '@/utils/globalUtils';

class RPVector {
  private _x: number;
  private _y: number;
  constructor(start: { x: number; y: number }, end: { x: number; y: number }) {
    this._y = end.y - start.y;
    this._x = end.x - start.x;
  }
  static subtractionOfVectors(subtractor: { x: number; y: number }, minuend: { x: number; y: number }) {
    return {
      x: subtractor.x - minuend.x,
      y: subtractor.y - minuend.y,
    };
  }
  get x() {
    return this._x;
  }
  get y() {
    return this._y;
  }
  round() {
    return Vector({
      x: round(this._x),
      y: round(this._y),
    });
  }
  add(vector: { x: number; y: number }) {
    return Vector({
      x: this._x + vector.x,
      y: this._y + vector.y,
    });
  }
  subtract(vector: { x: number; y: number }) {
    return Vector({
      x: this._x - vector.x,
      y: this._y - vector.y,
    });
  }
  scale(scale: number) {
    return Vector({
      x: this._x * scale,
      y: this._y * scale,
    });
  }
}
export function Vector(
  endPoint: RPPoint | { x: number; y: number },
  startPoint: RPPoint | { x: number; y: number } = Point({ x: 0, y: 0 }),
) {
  return new RPVector(startPoint, endPoint);
}
