import IFill from '@/fbs/rp/models/properties/fill';
import { ISize } from '@/fbs/common/models/common';
import { ILinearGradientColor, IRadialGradientColor } from '@/fbs/rp/models/properties/color';
import { FillType, fullColorStops, parseColorToString } from '@/utils/graphicsUtils';
import { max } from '@/utils/globalUtils';

export const makeGradient = (fill: IFill | undefined, id: string, size: ISize): string => {
  if (fill) {
    const gradient = renderGradient(fill, id, size);
    return gradient ? gradient : '';
  }
  return '';
};

export const renderLinearGradient = (compID: string, color: ILinearGradientColor, size: ISize) => {
  const { colorStops, direction } = color;
  const { x1, x2, y1, y2 } = direction || { x1: 0, x2: 0, y1: 0, y2: 1 };
  const stops = fullColorStops(colorStops);
  const rectSize = max(size.width, size.height);
  if (!rectSize) return null;
  const scale = {
    x: size.width / rectSize,
    y: size.height / rectSize,
  };

  return `<linearGradient id="${compID}" x1="${x1 * scale.x}" x2="${x2 * scale.x}" y1="${y1 * scale.y}" y2="${
    y2 * scale.y
  }">
        ${stops
          .sort((a, b) => (a.point || 0) - (b.point || 0))
          .map(
            (colorStop) =>
              `<stop offset="${colorStop.point || 0}%" stop-color="${parseColorToString(colorStop.color)}"/>`,
          )
          .join('')}
      </linearGradient>`;
};

export const renderRadialGradient = (compID: string, color: IRadialGradientColor, size: ISize) => {
  const { colorStops, to, from, angle, widthRatio } = color;
  const stops = fullColorStops(colorStops);
  const { x: fx, y: fy } = from || { x: 0.5, y: 0.5, r: 0 };
  const { x: cx, y: cy, r } = to || { x: 0.5, y: 0.5, r: 0.5 };
  const transform = `translate(${fx}, ${fy}),
                    scale(${size.height / size.width}, 1),
                    rotate(${angle || 0}),
                    scale(${widthRatio || 1}, 1),
                    translate(${-fx}, ${-fy})`;
  return `<radialGradient id="${compID}" fx="${fx}" fy="${fy}" cx="${cx}" cy="${cy}" r="${r}" gradientTransform="${transform}">
        ${stops
          .sort((a, b) => (a.point || 0) - (b.point || 0))
          .map(
            (colorStop) =>
              `<stop offset="${colorStop.point || 0}%" stop-color="${parseColorToString(colorStop.color)}" />`,
          )
          .join('')}
      </radialGradient>`;
};

export const renderGradient = (fill: IFill | undefined, fillID: string, size: ISize) => {
  if (fill) {
    const { type, color } = fill;
    if (type === FillType.linear) {
      return renderLinearGradient(fillID, color as ILinearGradientColor, size);
    } else if (type === FillType.radial) {
      return renderRadialGradient(fillID, color as IRadialGradientColor, size);
    }
  }
  return null;
};
