import { IComponentData } from '@/fbs/rp/models/component';
import { EChartType_basic } from '@/fbs/rp/models/properties/chart';
import { IChartValue } from '@/fbs/rp/models/chart';
import { makeCommonComponent } from '@/libs/helper';

import { IComponentItem } from '../../../types';
import { CBarChartHorizontal } from '../../../constants';
import { getChartConfig, makeChartData, getSeriesChartOneDefaultData } from '../common';

export const BarChartHorizontal: IComponentItem = getChartConfig(
  CBarChartHorizontal,
  EChartType_basic.BarChartHorizontal,
);

export function makeBarChartHorizontal(id: string): IComponentData {
  return makeChartData(id, CBarChartHorizontal, EChartType_basic.BarChartHorizontal);
}

export function makeAIBarChartHorizontal(id: string, value: IChartValue): IComponentData {
  return makeCommonComponent(id, CBarChartHorizontal, {
    ...getSeriesChartOneDefaultData(CBarChartHorizontal, EChartType_basic.BarChartHorizontal),
    size: {
      width: 400,
      height: 250,
    },
    lib: {
      id: 'chart',
      type: CBarChartHorizontal,
    },
    value: value,
  });
}
