import * as React from 'react';

const QRCode = require('qrcode.react');

import { rgba2hex } from '@utils/graphicsUtils';

import { PureColor } from '@fbs/rp/models/properties/color';

import { UIComponent } from '@editor/comps/index';
import { StyleHelper } from '@helpers/styleHelper';

interface IQRCodeProps {
  comp: UIComponent;
}

export default class QRCodeComp extends React.Component<IQRCodeProps> {
  render() {
    const { properties, value, size, opacity } = this.props.comp;
    const { width, height } = size;
    const s = Math.min(width, height);
    let level = 'H';
    if (s <= 80 && s > 60) {
      level = 'Q';
    } else if (s <= 60 && s > 40) {
      level = 'M';
    } else if (s <= 40) {
      level = 'L';
    }
    return (
      <div
        className="lib-comp-qr-code"
        style={{
          opacity: StyleHelper.getOpacity(opacity),
          ...size,
        }}
      >
        <QRCode
          size={s}
          renderAs="svg"
          bgColor={rgba2hex(properties.background!.value as PureColor)}
          fgColor={rgba2hex(properties.forgetColor!.value as PureColor)}
          value={value as string}
          level={level}
        />
      </div>
    );
  }
}
