import { ArtboardOperations, Ops } from '@fbs/rp/utils/patch';
import { IStateCommandParams } from '@fbs/rp/models/interactions';

import { isPredefinedState, PredefinedStates } from '@consts/state';

import { CGroup } from '@/libs/constants';
import { UIContainerComponent, UIComponent } from '@/editor/comps';

import CommandBase from './CommandBase';

export default class StateCommand extends CommandBase {
  cashOriginParams = () => {
    const { target } = this.command;
    this.saveCacheOriginParams('state', target.currentStateID);
  };

  private initOperation = (stateID: string, comp?: UIComponent) => {
    const { target } = this.command;
    const currComp = comp || target;

    const { id, ownerArtboardID, type } = currComp;
    const opt: ArtboardOperations = {
      [id]: [this.initAnimationOperation(), Ops.replace('/_currentState', stateID)],
    };

    if (type === CGroup ) {
      (currComp as UIContainerComponent).components.forEach((comp) => {
        Object.assign(opt, this.initOperation(stateID, comp)[ownerArtboardID]);
      });
    }

    if (isPredefinedState(stateID)) {
      switch (stateID) {
        case PredefinedStates.checked:
          opt[id].push(Ops.replace('/selected', true), Ops.replace('/disabled', false));
          break;
        case PredefinedStates.disabled:
          opt[id].push(Ops.replace('/selected', false), Ops.replace('/disabled', true));
          break;
        default:
          opt[id].push(Ops.replace('/selected', false), Ops.replace('/disabled', false));
          break;
      }
    } else {
      opt[id].push(Ops.replace('/selected', false), Ops.replace('/disabled', false));
    }
    return {
      [ownerArtboardID]: opt,
    };
  };

  private getTargetState = () => {
    const { stateID } = this.command.params as IStateCommandParams;
    if (stateID === PredefinedStates.normal) {
      return '';
    }
    return stateID;
  };

  private isStateEnabled(state: string) {
    const comp = this.command.target;
    if (isPredefinedState(state) || !state) {
      return true;
    }
    const enabledStates = Object.keys(comp.toJSON().states).filter((stateID) => {
      return comp.toJSON().states[stateID].enabled !== false;
    });
    if (enabledStates.includes(state)) {
      return true;
    }
    return false;
  }

  run() {
    const stateID = this.getTargetState();
    this.fulfilled = this.isStateEnabled(stateID);
    if (this.fulfilled) {
      const patches = this.initOperation(stateID);
      this.patch(patches);
    }
  }

  revert() {
    const stateID = this.getCacheOriginParams('state');
    this.fulfilled = this.isStateEnabled(stateID);
    if (this.fulfilled) {
      this.patch(this.initOperation(stateID));
    }
  }
}
