import PropertyBase from './base';
import { Color } from './color';

export default interface IShadow extends PropertyBase {
  color?: Color;
  x?: number;
  y?: number;
  blur?: number;
}

export const ShadowPropertyName = 'shadow';
