import * as React from 'react';

import i18n, { languageManager } from '@/i18n';

import { EN_WEEK_NAMES, getCurrentYearMonth, getDateByYearMonth, getDateFormat, WEEK_NAMES } from '../utils';
import { DeviceType } from '../config';
import { IDateInfo } from '..';
import { EDatePickerType } from '@/fbs/rp/models/properties/common';

import { Icon } from '@/dsm';
import { throttle } from 'lodash';
import DateComponent from '../DateComponent';

import './index.scss';
import classnames from 'classnames';

export interface IDatePickerComponentProps {
  selectedInfo?: IDateInfo;
  startDateInfo?: IDateInfo;
  endDateInfo?: IDateInfo;
  dateFormat?: any;
  dateType?: EDatePickerType;
  deviceType?: DeviceType;
  showSelectedYear?: boolean;
  isPreview?: boolean;
  onSelectDate: (date?: IDateInfo) => void;
  onSelectedRangeDate?: (startDate: IDateInfo, endDate?: IDateInfo) => void;
  onClearDateInfo?: () => void;
}

interface IDatePickerComponentState {
  month: number;
  year: number;
  currentDate: Date;
  moveStartY: number;
  moveEndY: number;
}

const MONTH_NUMBER = 12;
export default class PhoneDatePicker extends React.Component<IDatePickerComponentProps, IDatePickerComponentState> {
  private phonePickerRef: React.RefObject<HTMLDivElement> = React.createRef();
  private handleThrottle: (e: WheelEvent) => void;

  constructor(props: IDatePickerComponentProps) {
    super(props);
    this.state = {
      month: 0,
      year: 0,
      currentDate: new Date(),
      moveEndY: 0,
      moveStartY: 0,
    };
    this.handleThrottle = throttle(this.handleWheel, 500, { trailing: false });
  }

  componentDidMount() {
    const { selectedInfo, startDateInfo } = this.props;

    if (selectedInfo || startDateInfo) {
      const date = new Date();
      date.setFullYear(selectedInfo ? selectedInfo.year : startDateInfo!.year);
      date.setMonth(selectedInfo ? selectedInfo.month - 1 : startDateInfo!.month - 1);
      this.setState({ year: getCurrentYearMonth(date).year, month: getCurrentYearMonth(date).month });
    } else {
      this.setState({
        year: getCurrentYearMonth(this.state.currentDate).year,
        month: getCurrentYearMonth(this.state.currentDate).month,
      });
    }
    if (this.phonePickerRef.current) {
      this.phonePickerRef.current.addEventListener('wheel', this.handleThrottle, {
        passive: false,
      });
      this.phonePickerRef.current.addEventListener('touchstart', this.handleTouchStart);
      this.phonePickerRef.current.addEventListener('touchmove', this.handleTouchMove);
      this.phonePickerRef.current.addEventListener('touchend', this.handleTouchEnd, { capture: true });
    }
  }

  componentWillUnmount() {
    if (this.phonePickerRef.current) {
      this.phonePickerRef.current.removeEventListener('wheel', this.handleThrottle);
      this.phonePickerRef.current.addEventListener('touchstart', this.handleTouchStart);
      this.phonePickerRef.current.addEventListener('touchmove', this.handleTouchMove);
      this.phonePickerRef.current.addEventListener('touchend', this.handleTouchEnd, { capture: true });
    }
  }

  handleTouchStart = (e: TouchEvent) => {
    this.setState({ moveStartY: e.touches[0].pageY });
  };

  handleTouchMove = (e: TouchEvent) => {
    this.setState({ moveEndY: e.touches[0].pageY });
  };

  handleTouchEnd = () => {
    const { moveStartY, moveEndY, month, year } = this.state;
    if ([moveStartY, moveEndY].includes(0)) {
      return;
    }
    //手势添加滚动日期
    if (moveEndY < moveStartY) {
      this.setState({
        year: month === MONTH_NUMBER ? year + 1 : year,
        month: month === MONTH_NUMBER ? 1 : month + 1,
      });
    } else {
      this.setState({
        year: month === 1 ? year - 1 : year,
        month: month === 1 ? MONTH_NUMBER : month - 1,
      });
    }
    this.setState({
      moveEndY: 0,
      moveStartY: 0,
    });
  };

  //监听鼠标滚动滚动实现日期无限循环
  handleWheel = (e: WheelEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const { month, year } = this.state;
    if (e.wheelDeltaY > 0) {
      this.setState({
        year: month === 1 ? year - 1 : year,
        month: month === 1 ? MONTH_NUMBER : month - 1,
      });
    } else {
      this.setState({
        year: month === MONTH_NUMBER ? year + 1 : year,
        month: month === MONTH_NUMBER ? 1 : month + 1,
      });
    }
  };

  //当存在初始化日期时
  getDateRangeInfo = () => {
    const { startDateInfo, endDateInfo } = this.props;
    if (startDateInfo && !endDateInfo) {
      return [getDateByYearMonth(startDateInfo.year, startDateInfo.month, startDateInfo.date).getTime()];
    } else if (startDateInfo && endDateInfo) {
      return [
        getDateByYearMonth(startDateInfo.year, startDateInfo.month, startDateInfo.date).getTime(),
        getDateByYearMonth(endDateInfo.year, endDateInfo.month, endDateInfo.date).getTime(),
      ];
    } else {
      return [];
    }
  };

  handleClearSelected = () => {
    this.props.onClearDateInfo && this.props.onClearDateInfo();
  };

  //选择日期以及日期区间逻辑
  handleSelectDate = (dateInfo: { year: number; month: number; date: number }) => {
    const { year, month, date } = dateInfo;
    let dateRangeInfo: number[] = this.getDateRangeInfo();
    const dateTime = getDateByYearMonth(year, month, date).getTime();
    switch (this.props.dateType) {
      case EDatePickerType.Normal:
        this.props.onSelectDate(dateInfo);
        this.props.onSelectedRangeDate && this.props.onSelectedRangeDate(dateInfo, undefined);
        break;
      case EDatePickerType.Range:
        this.props.onSelectDate(undefined);
        if (dateRangeInfo.length === 0) {
          dateRangeInfo.push(dateTime);
        } else if (dateRangeInfo.length === 1) {
          if (dateTime <= dateRangeInfo[0]) {
            dateRangeInfo = [];
            dateRangeInfo.push(dateTime);
          } else {
            dateRangeInfo.push(dateTime);
          }
        } else {
          dateRangeInfo = [];
          dateRangeInfo.push(dateTime);
        }
        this.props.onSelectedRangeDate &&
          this.props.onSelectedRangeDate(
            getCurrentYearMonth(new Date(Math.min(...dateRangeInfo))),
            dateRangeInfo.length === 1 ? undefined : getCurrentYearMonth(new Date(Math.max(...dateRangeInfo))),
          );
        break;
      default:
        break;
    }
  };

  private renderDate = (index: 0 | 1, dateType: EDatePickerType) => {
    const { selectedInfo, startDateInfo, endDateInfo, dateFormat, showSelectedYear } = this.props;
    switch (dateType) {
      case EDatePickerType.Normal:
        if (!selectedInfo) {
          return <div className="head-input-placeholder">{i18n('resource.components.datePickerInfo.optionDate')}</div>;
        } else {
          return <div className="head-input-value">{getDateFormat(dateFormat, selectedInfo, showSelectedYear)}</div>;
        }
      case EDatePickerType.Range:
        if (index === 0) {
          return startDateInfo ? (
            <div className="head-input-value">{getDateFormat(dateFormat, startDateInfo, showSelectedYear)}</div>
          ) : (
            <div className="head-input-placeholder">{i18n('resource.components.datePickerInfo.startDate')}</div>
          );
        } else {
          return endDateInfo ? (
            <div className="head-input-value">{getDateFormat(dateFormat, endDateInfo, showSelectedYear)}</div>
          ) : (
            <div className="head-input-placeholder">{i18n('resource.components.datePickerInfo.endDate')}</div>
          );
        }
      default:
        break;
    }
  };

  render() {
    const { dateType, startDateInfo, endDateInfo, selectedInfo, deviceType, dateFormat, isPreview } = this.props;
    const { month, year } = this.state;

    return (
      <div ref={this.phonePickerRef} className="date-picker-content">
        <div className="content-head">
          <div className="head-input">
            <div className="head-input-date-info">
              {this.renderDate(0, dateType!)}
              {dateType === EDatePickerType.Range && <> ~ {this.renderDate(1, dateType)}</>}
            </div>
          </div>
          {(selectedInfo || startDateInfo) && (
            <Icon className="head-icon" cls="icon_assembly_eliminate_rp" onClick={this.handleClearSelected} />
          )}
        </div>
        <div className="content-details">
          <div className="content-details-head">
            {(languageManager.isEnVersion ? EN_WEEK_NAMES : WEEK_NAMES).map((week, key) => {
              return (
                <div className={classnames({ 'content-details-head-en': languageManager.isEnVersion })} key={key}>
                  {week}
                </div>
              );
            })}
          </div>
          <DateComponent
            isPreview={isPreview}
            deviceType={deviceType}
            showYearMonth
            onSelectDate={this.handleSelectDate}
            dateInfo={{ year, month }}
            selectedInfo={selectedInfo}
            startDateInfo={startDateInfo}
            endDateInfo={endDateInfo}
            dateType={dateType}
            dateFormal={dateFormat}
          />
          <DateComponent
            isPreview={isPreview}
            deviceType={deviceType}
            showYearMonth
            onSelectDate={this.handleSelectDate}
            dateInfo={{ year: month === MONTH_NUMBER ? year + 1 : year, month: month === MONTH_NUMBER ? 1 : month + 1 }}
            selectedInfo={selectedInfo}
            startDateInfo={startDateInfo}
            endDateInfo={endDateInfo}
            dateFormal={dateFormat}
            dateType={dateType}
          />
        </div>
      </div>
    );
  }
}
