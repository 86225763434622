import * as React from 'react';
import classnames from 'classnames';

import { UIContainerComponent } from '@editor/comps';
import { StyleHelper } from '@helpers/styleHelper';
import { hasInteraction } from '@helpers/interactionHelper';

import { getListEvents } from '../helps';
import { IListCompositeProps } from '../../types';

import './index.scss';
import '../general.scss';
import { transBlankChart } from '@utils/textUtils';

const Segment: React.FC<IListCompositeProps> = (props: IListCompositeProps) => {
  const { comp, itemEvents, isPreview, showInteract } = props;
  const contianer = comp as UIContainerComponent;
  const { size, properties, opacity, components } = contianer;
  const parser = StyleHelper.initCSSStyleParser(properties);
  const style = {
    ...size,
    opacity: StyleHelper.getOpacity(opacity),
    ...parser.getFillStyle(size),
    ...parser.getStrokeStyle(),
    ...parser.getRadiusStyle(size),
    ...parser.getShadowStyle(),
  };

  const { separator } = properties;
  const separatorStyle = StyleHelper.parserSeparator('right', separator);
  const len = components.length;
  const events = isPreview ? getListEvents(components, itemEvents) : {};
  const itemWidth = size.width / components.length;
  return (
    <div className={classnames('lib-comp-segment', { preview: isPreview })} style={style}>
      {components.map((comp, i) => {
        const {
          properties: itemProp,
          // size: { width },
        } = comp;
        const item = comp as UIContainerComponent;
        const text = item.getComponentByAlias('text') || item.components[0];
        const itemParser = StyleHelper.createCSSStyleParser(itemProp);
        const { properties: textProperties } = text;
        const align = textProperties.textStyle?.textAlign;
        const { style } = StyleHelper.createCSSStyleParser({}).getTextStyleData(text.size, textProperties.textStyle);
        const itemStyle = {
          width: itemWidth,
          ...itemParser.getFillStyle(),
          ...style,
          ...(i < len - 1 ? separatorStyle : {}),
          opacity: StyleHelper.getOpacity(comp.opacity),
        };
        const _hasInteraction = hasInteraction(comp);
        const needShowInteraction = !isPreview && _hasInteraction && false !== showInteract;
        return (
          <div
            key={comp.id}
            className={classnames('lib-comp-segment-item', align, {
              'item-interaction-flag': needShowInteraction,
              'item-hot-area': isPreview && _hasInteraction,
              'component-cursor-pointer': isPreview,
            })}
            style={itemStyle}
            {...(events[comp.id] || {})}
          >
            <label className="lib-comp-segment-item-value" style={{ opacity: StyleHelper.getOpacity(text.opacity) }}>
              {transBlankChart(text.value as string)}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default Segment;
