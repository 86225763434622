import * as React from 'react';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { useDrag, useDrop, DragSourceMonitor, DragPreviewImage } from 'react-dnd';
import { XYCoord } from 'dnd-core';

import { nodeMatchUpdate } from '@/utils/stringUtils';

import { Icon } from '@dsm2';
import { TREE_NODE, INode, DropMode } from '../model';
import { isNodeMatch } from '../utils';
import { isCtrlKey } from '../../utils';
import { Checkbox } from '@dsm2';

import './index.scss';

const EmptyImage = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

interface INodeProps {
  theme?: 'white' | 'dark' | 'spec-dark';
  data: INode;
  expanded: boolean;
  selected: boolean;
  cutted: boolean;
  draggable: boolean;
  showPreNodeheightLight?: boolean;
  customDragType: symbol[];
  justAcceptCustomDragType: boolean;
  offset: number;
  inputWidth: number;
  selectNodesCount: number;
  needFocusNodeId: string;
  searchKey?: string;
  searchKeyHighlight?: boolean;
  canLeafHasChildren?: boolean;
  showCustomIcon?: boolean;

  showCheckBox: boolean;
  checked: boolean;
  // 半选中
  indeterminate: boolean;
  disabledCheckbox: boolean;
  highlightChecked: boolean;
  nodeDisabled?: boolean;

  children?: React.ReactNode;
  needDebounce?: boolean;
  isNotShowCommentsIcon?: boolean;
  hideDefaultTitle: boolean;

  onExpand(expandAll: boolean): void;
  onClick(e: React.MouseEvent): void;
  onDragBegin(): void;
  // 拖拽结束
  onDragEnd(sourceNode: string, targetNode: string, mode: DropMode): void;
  onContextMenu(e: React.MouseEvent): void;
  onKeyDown(e: React.KeyboardEvent<HTMLSpanElement>): void;
  onMouseUp?(e: React.MouseEvent): void;
  onMouseDown?(e: React.MouseEvent): void;
  onMouseEnter?(e: React.MouseEvent): void;
  onMouseLeave?(e: React.MouseEvent): void;
  //是否支持平级拖动
  canPeersDrag: boolean;
  // 仅平级拖动
  justCanPeersDrag: boolean;
  canDropIn(source: string, target: string): boolean;
  onCheck(checked: boolean): void;
  onClickSuffixIcon?(e: React.MouseEvent, id: string): void;
  onClickOptionsIcon?(e: React.MouseEvent, id: string): void;
  onDblClickNode?(id: string): void;
  tip?(e: React.MouseEvent, text?: string): void;
  //重命名
  onRename?(name: string, node: INode): void;
  isEdit: boolean;
  renameMaxLength?: number;

  onClickControlIcon?(e: React.MouseEvent, node: any): void;
  showGroupNum: boolean;
  interactionHighlight?: boolean;
  hoverShowBg?: boolean;

  // 是否在进行搜索输入
  isSearchInputting?: boolean;

  // 是否是内容面板编辑模式
  isContentPanelEditor?: boolean;
}

interface IDragObject {
  id: string;
  type: symbol;
  // 表示被拖拽的该项是否是被选中的
  selected: boolean;
  appSetID?: string;
}

interface IDropResult {
  id: string;
  dropMode: DropMode;
}

const Node: React.FC<INodeProps> = (props: INodeProps) => {
  const {
    data,
    tip,
    isEdit,
    isSearchInputting,
    needFocusNodeId,
    selectNodesCount,
    nodeDisabled,
    isContentPanelEditor,
  } = props;
  const spanRef = React.useRef<HTMLSpanElement>(null);
  const ref = React.useRef<HTMLLIElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [dropMode, setMode] = React.useState<DropMode>(DropMode.None);
  const [isHover, setIsHover] = React.useState<boolean>(false);

  // 重命名修改选中
  React.useEffect(() => {
    if (isEdit) {
      inputRef.current?.select();
    }
  }, [isEdit]);

  React.useEffect(() => {
    // 页面树搜索框输入时，且不是需要聚焦的节点就不聚焦
    if (!isSearchInputting && needFocusNodeId === data.id) {
      spanRef.current?.focus();
    }
  }, [isSearchInputting, needFocusNodeId, selectNodesCount]);

  React.useEffect(() => {
    if (props.data?.isInteractionTarget) {
      // FIXME Mxj 没太看懂这里这个 scrollIntoViewIfNeeded 是从哪来的属性，ref.current这里应该是一个HTMLLIElement对象才对？先忽略掉，保证RP中没有TS编译报错
      // @ts-ignore
      ref.current?.scrollIntoViewIfNeeded && ref.current?.scrollIntoViewIfNeeded();
    }
  }, [props.data.isInteractionTarget]);

  const getDragType = () => {
    const { justAcceptCustomDragType, customDragType } = props;
    if (customDragType && customDragType.length > 0) {
      if (justAcceptCustomDragType) {
        return [...customDragType];
      }
      return [TREE_NODE, ...customDragType];
    }

    return TREE_NODE;
  };

  const [{ isDragging }, connectDrag, preview] = useDrag<
    IDragObject,
    IDropResult,
    {
      isDragging: boolean;
    }
  >({
    item: {
      id: data.id,
      selected: props.selected,
      type: props.justAcceptCustomDragType ? props.customDragType[0] || TREE_NODE : TREE_NODE,
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: () => {
      return props.draggable;
    },
    begin: () => {
      props.onDragBegin();
    },
    end: (item, monitor: DragSourceMonitor) => {
      if (!monitor.didDrop()) {
        return;
      }
      if (!item) {
        return;
      }
      const dropResult = monitor.getDropResult();
      if (item.id === dropResult.id) {
        return;
      }
      props.onDragEnd(item.id, dropResult.id, dropResult.dropMode);
    },
  });

  const [{ isOverCurrent, canDrop }, connectDrop] = useDrop<
    IDragObject,
    IDropResult,
    {
      isOverCurrent: boolean;
      canDrop: boolean;
    }
  >({
    accept: getDragType(),
    drop: (_, monitor) => {
      if (monitor.didDrop()) {
        return;
      }
      return {
        id: data.id,
        dropMode,
      };
    },
    collect: (monitor) => ({
      isOverCurrent: !!monitor.isOver({
        shallow: true,
      }),
      canDrop: !!monitor.canDrop(),
    }),
    hover: (_, monitor) => {
      if (!monitor.canDrop()) {
        setMode(DropMode.None);
        return;
      }
      if (!monitor.isOver({ shallow: true })) {
        return;
      }

      const hoverBoundingRect = ref.current!.getBoundingClientRect();
      const hoverMiddleY = hoverBoundingRect.top + 16;
      const clientOffset = monitor.getClientOffset() as XYCoord;

      if (!props.justCanPeersDrag && (props.canLeafHasChildren || !data.isLeaf)) {
        if (clientOffset.x >= hoverBoundingRect.left + 32) {
          setMode(DropMode.Child);
          return;
        }
      }

      if (props.canPeersDrag) {
        // Get pixels to the top
        const hoverClientY = clientOffset.y;
        if (hoverClientY < hoverMiddleY) {
          setMode(DropMode.Before);
        } else {
          setMode(DropMode.After);
        }
      }
    },
    canDrop: (item): boolean => {
      if (data.id === item.id) {
        return false;
      }

      if (item.appSetID && item.appSetID === data.id) {
        return false;
      }

      return props.canDropIn(item.id, data.id);
    },
  });

  connectDrag(ref);
  connectDrop(ref);

  let nodeText = data.name;
  if (data.serialNumber) {
    nodeText = `${data.serialNumber} ${data.name}`;
  }

  const isOpenGroupIcon = (data: INode) => {
    if (!props.expanded && !data.children?.length) {
      return false;
    } else if (props.expanded && !data.children?.length) {
      return false;
    } else if (!props.expanded) {
      return false;
    }
    return true;
  };

  // 兼容idoc rp 辅助画板重命名格式 [辅助画板名称]
  const dataName = new String(data.name) as string;
  const isRpFragment = data.source === 'rp-fragment' && dataName && dataName.startsWith('[') && dataName.endsWith(']');
  const defaultName = isRpFragment ? dataName.slice(0, dataName.length - 1).slice(1, dataName.length) : data.name;
  return (
    <li
      ref={ref}
      className={classnames('tree-node', {
        [`tree-node-drop-at-${dropMode}`]:
          isOverCurrent &&
          dropMode !== DropMode.None &&
          (props.justAcceptCustomDragType || !props.customDragType?.length),
        'tree-node-is-dragging': isDragging,
        'tree-node-cutted': props.cutted,
        'tree-node-disabled': nodeDisabled,
        highlight: props.highlightChecked && props.checked,
      })}
      id={`node_${data.id}`}
      // onMouseEnter={() => setIsHover(true)}
      // onMouseLeave={() => setIsHover(false)}
    >
      <div
        className="interaction_target_node"
        id={`interaction_target_node_${props.data.id}`}
        style={{ left: -props.offset + 16 }}
        // onClick={props.onClick}
        // 选中页面就加载数据，快速点击导致cpu过高，所以加了一个节流
        onClick={props.needDebounce ? debounce(props.onClick, 200) : props.onClick}
      />
      {props.data.showInteractionIcon && <div className="has-interaction" style={{ left: -props.offset + 16 }} />}
      {props.data.isInteractionTarget && <div className="isInteractionTarget" style={{ left: -props.offset + 16 }} />}
      {props.interactionHighlight && <div className="interaction-highlight" style={{ left: -props.offset + 16 }} />}
      {(props.selected ||
        (props.highlightChecked && props.checked) ||
        props.hoverShowBg ||
        (!!props.customDragType?.length && !props.justAcceptCustomDragType && isOverCurrent && canDrop)) && (
        <div
          className={classnames('node-bg', {
            'node-bg-selected': props.selected,
            'node-bg-checked': props.data.isLeaf && props.highlightChecked && props.checked,
            'node-bg-drop-over':
              props.customDragType?.length && !props.justAcceptCustomDragType && isOverCurrent && canDrop,
          })}
          style={{ left: -props.offset }}
        />
      )}
      {props.hoverShowBg && !props.selected && (
        <div
          className={classnames('node-bg', {
            'node-bg-hover': props.hoverShowBg && isHover,
          })}
          style={{ left: -props.offset }}
        />
      )}

      {isDragging && <div className="tree-node-is-dragging-bg" style={{ left: -props.offset }} />}

      {isEdit && (
        <input
          ref={inputRef}
          type="text"
          className="tree-node-input"
          defaultValue={defaultName}
          autoFocus
          maxLength={props.renameMaxLength}
          style={{
            left: -props.offset + 16,
            width: props.inputWidth - 32,
          }}
          onBlur={(e) => {
            if (props.onRename) {
              props.onRename(e.target.value, data);
            }
          }}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              if (props.onRename) {
                props.onRename(e.target.value, data);
              }
            }
            if (e.key === 'Escape') {
              if (props.onRename) {
                props.onRename(data.name, data);
              }
            }
          }}
        />
      )}

      {!isEdit && (
        <span
          className={classnames('tree-node-name', {
            selected: props.selected,
            dullness: props.searchKey && !isNodeMatch(data, props.searchKey),
            highlight: props.highlightChecked && props.checked,
            lowlight: props.highlightChecked && !props.checked,
            hover: isHover && !props.selected,
          })}
          ref={spanRef}
          onDoubleClick={(e: React.MouseEvent) => {
            if (props.onDblClickNode) {
              props.onDblClickNode(data.id);
            } else {
              props.onExpand(isCtrlKey(e));
            }
          }}
          onClick={props.onClick}
          tabIndex={-1}
          onKeyDown={(e) => {
            if (e.key === 'Delete' && spanRef.current) {
              spanRef.current.blur();
            }
            props.onKeyDown(e);
          }}
          onMouseUp={props.onMouseUp}
          onMouseDown={props.onMouseDown}
          onMouseEnter={(e) => {
            props.hoverShowBg && setIsHover(true);
            props.onMouseEnter && props.onMouseEnter(e);
          }}
          onMouseLeave={(e) => {
            props.hoverShowBg && setIsHover(false);
            props.onMouseLeave && props.onMouseLeave(e);
          }}
          onContextMenu={props.onContextMenu}
        >
          <div className="is-finding-interaction-target-node" style={{ left: -props.offset }} />
          <i
            className={classnames('icon-expand', {
              invisiable: !data.children || data.children.length === 0,
            })}
            onDoubleClick={(e: React.MouseEvent) => {
              props.onExpand(isCtrlKey(e));
              e.stopPropagation();
            }}
          >
            <Icon
              cls={props.expanded ? 'tag_downarrow' : 'tag_rightarrow'}
              hidden={!data.children || data.children.length === 0}
              solid
              onClick={(e: React.MouseEvent) => {
                props.onExpand(isCtrlKey(e));
                e.stopPropagation();
              }}
            />
          </i>
          <span className="title" onClick={() => props.showCheckBox && props.onCheck(!props.checked)}>
            {data.isLeaf ? (
              <>
                {data.icon && !props.showCustomIcon && (
                  <Icon
                    cls={data.icon === 'tree_group' ? (props.expanded ? 'tree_index' : 'tree_group') : data.icon}
                    solid
                    disableHoverColor
                    color={data.color}
                  />
                )}
                {data.icon && !!props.showCustomIcon && (
                  <Icon cls={data.icon} solid disableHoverColor color={data.color} />
                )}
              </>
            ) : (
              <Icon cls={isOpenGroupIcon(data) ? 'tree_index' : 'tree_group'} solid disableHoverColor />
            )}
            {data.isHomepage && <Icon cls="demo_home" solid disableHoverColor />}
            {data.isHorizontal && <Icon cls="tree_phone" solid disableHoverColor />}
            {props.showCheckBox && (
              <Checkbox
                darkMode={props.theme === 'dark' || props.theme === 'spec-dark'}
                disabled={props.disabledCheckbox}
                checked={props.checked}
                indeterminate={props.indeterminate}
                onChange={props.onCheck}
              />
            )}
            {props.searchKey && props.searchKeyHighlight ? (
              <span
                className={classnames('name', {
                  'interaction-highlight': props.interactionHighlight,
                })}
                onMouseOver={(e) => tip && tip(e, data.name)}
                onMouseLeave={(e) => tip && tip(e)}
                title={props.hideDefaultTitle ? '' : data.name}
                dangerouslySetInnerHTML={{
                  __html: nodeMatchUpdate(
                    data.name,
                    props.searchKey,
                    props.showGroupNum,
                    data.groupNum ? data.groupNum : 0,
                  ),
                }}
              />
            ) : (
              <span
                className={classnames('name', {
                  'interaction-highlight': props.interactionHighlight,
                  'pre-node-highlight': props.showPreNodeheightLight,
                })}
                title={props.hideDefaultTitle || tip ? '' : data.name}
                onMouseOver={(e) => tip && tip(e, data.name)}
                onMouseLeave={(e) => tip && tip(e)}
              >
                {nodeText}
                {props.showGroupNum && !!data.groupNum && <b className="group-num">（{data.groupNum}）</b>}
              </span>
            )}
          </span>
          {(data.suffixIcon || data.optionsIcon || data.commentsIcon) && (
            <div className="right-icon">
              {data.commentsIcon && data.commentsIcon.icon && !props.isNotShowCommentsIcon && (
                <i
                  className="comments-icon"
                  title={props.hideDefaultTitle || tip ? '' : data.commentsIcon.label}
                  onMouseOver={(e) => tip && tip(e, data.commentsIcon?.label)}
                  onMouseLeave={(e) => tip && tip(e)}
                >
                  <Icon color={data.commentsIcon.color} cls={data.commentsIcon.icon} solid />
                </i>
              )}
              {data.suffixIcon && (
                <i
                  className={classnames('suffix-icon', { disable: isContentPanelEditor })}
                  onMouseDown={(e) => {
                    if (props.onClickSuffixIcon) {
                      props.onClickSuffixIcon(e, props.data.id);
                    }
                  }}
                  title={props.hideDefaultTitle || tip ? '' : data.suffixIcon.label}
                  onMouseOver={(e) => tip && tip(e, data.suffixIcon?.label)}
                  onMouseLeave={(e) => tip && tip(e)}
                >
                  <Icon color={data.suffixIcon.color} cls={data.suffixIcon.icon} solid />
                </i>
              )}
              {data.optionsIcon && (
                <i
                  className="options-icon"
                  onMouseDown={(e) => {
                    if (props.onClickOptionsIcon) {
                      props.onClickOptionsIcon(e, props.data.id);
                    }
                  }}
                  title={props.hideDefaultTitle || tip ? '' : data.optionsIcon.label}
                  onMouseOver={(e) => tip && tip(e, data.optionsIcon?.label)}
                  onMouseLeave={(e) => tip && tip(e)}
                >
                  <Icon color={data.optionsIcon.color} cls={data.optionsIcon.icon} solid />
                </i>
              )}
            </div>
          )}
        </span>
      )}

      {!isEdit && <DragPreviewImage src={EmptyImage} connect={preview} />}
      {props.children && <div className="child">{props.children}</div>}
    </li>
  );
};

function areEqual(prevProps: INodeProps, nextProps: INodeProps) {
  const keysWhichValueIsNotFunction = Object.keys(nextProps).filter((key) => {
    const value = nextProps[key as keyof INodeProps];
    const propsValueIsNotFunction = !(typeof value === 'function');
    return propsValueIsNotFunction;
  });

  for (let key of keysWhichValueIsNotFunction) {
    const key1 = key as keyof INodeProps;
    try {
      if (!['children'].includes(key1) && JSON.stringify(nextProps[key1]) !== JSON.stringify(prevProps[key1])) {
        return false;
      } else if (['children'].includes(key1) && nextProps.children !== prevProps.children) {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  return true;
}

export default React.memo(Node, areEqual);
