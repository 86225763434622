import { copyStyleRuler } from './copyStyleRuler';
import { filter } from './filter';
import { replacer } from './replacer';

export const config = {
  filter,
  replacer,
  copyStyleRuler,
};

// const options = {

//     // bgcolor: '',
//     // width: 0,
//     // height: 0,
//     // style: undefined,
//     // quality: 0,
//     // imagePlaceholder: '',
//     // cacheBust: false
//   };
