import { SpriteThumb } from '@/consts/spriteIcons';
import i18n from '@/i18n';
import { IComponentItem } from '@/libs/types';
import { IComponentData } from '@/fbs/rp/models/component';
import { makeShapeBase } from '@/libs/basic/common/ShapeTextBase';
import { CRect, CRectButton } from '@/libs/constants';
import { isMobileAppType } from '@/helpers/previewHelper';
import { UIComponent, UIContainerComponent } from '@/editor/comps';
import { ISize } from '@/utils/boundsUtils';

const normalSize = {
  width: 70,
  height: 30,
};
const mobileSize = {
  width: 120,
  height: 48,
};
function initButtonData(appType: string, data: IComponentData, container?: UIContainerComponent, defaultSize?: ISize) {
  const size = defaultSize || (isMobileAppType(appType) ? mobileSize : normalSize);
  const { width, height } = size;
  data.size = { ...data.size, width, height };
}

const properties = {
  radius: {
    topLeft: 4,
    topRight: 4,
    bottomRight: 4,
    bottomLeft: 4,
    isPercent: false,
    disabled: false,
  },
};

export const RectButtonConfig: IComponentItem = {
  type: CRectButton,
  name: i18n('resource.components.button'),
  shortCut: 'B',
  thumb: {
    spriteIconClass: SpriteThumb.Button.className,
    dragPosition: SpriteThumb.Button.position,
  },
  editor: {
    onValidateAllowEditor: (comp: UIComponent, trigger: 'enter' | 'dblClick'): 'path' | 'value' => {
      return trigger === 'dblClick' ? 'path' : 'value';
    },
  },
  initialization: initButtonData,
};

export function makeRectButton(id: string, data?: Partial<IComponentData>): IComponentData {
  const template: Partial<IComponentData> = {
    ...data,
    size: mobileSize,
    text: i18n('general.confirm'),
    properties,
  };
  return makeShapeBase(id, CRect, true, template);
}
