import { HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import { TextAlign } from '@fbs/rp/models/properties/text';
import { DefaultCollapseIconType } from '@fbs/rp/models/properties/tree';
import { IComponentData } from '@fbs/rp/models/component';
import { PropertyValue } from '@fbs/rp/models/property';
import { ArtboardOperations, ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { ITreeData } from '@fbs/rp/models/value';
import { FillType } from '@fbs/rp/models/properties/fill';

import i18n from '@i18n';

import { UIComponent, UITreeComponent, UITreeItemComponent } from '@editor/comps';
import { defaultExpandIcon, defaultIndent, defaultLineHeight, getDefaultTreeData } from '@consts/defaultData/tree';
import { SpriteThumb } from '@consts/spriteIcons';
import * as SystemsColor from '@consts/colors';
import { DefaultBorder } from '@consts/border';

import {
  ITreeArrData,
  mapChildsIDByCompID,
  TreeCheckboxState,
  TreeItemAlias,
  TreeItemColumn,
  treeToArr,
  arrToTree,
  createAvlTreeWithTreeComp,
  getAdjustCompInfo,
} from '@helpers/treeCompHelper';
import { getNewID } from '@helpers/idHelper';

import { makeCommonComponent } from '../../helper';
import { makeIcon } from '../../basic/Icon';
import { IComponentItem } from '../../types';
import { CTree, CTreeItem, CCanvasPanel, CPureText } from '../../constants';

function onTreePropertyUpdate(container: UIComponent, propertyName: string, newValue: PropertyValue): ArtboardPatches {
  //切换默认的图标样式，需要更新所有的compItem 的 column === TreeItemColumn.Expand 的值 及修正treeValue 中的数据
  //修改图标颜色，需要修改每项compItem 相应 column 的 property: { icon: {}}
  //修改图标尺寸，需要修改每项compItem 相应 column 的 size

  return (container as UITreeComponent).modifyValueWhenPropertyChange(propertyName, newValue);
}

function onTreeClone(comp: IComponentData, idMaps: { [key: string]: string }) {
  const { expandACollapseIcon, relation } = comp.value as ITreeData;
  const newRelation = mapChildsIDByCompID(relation, idMaps);
  comp.value = { expandACollapseIcon, relation: newRelation };
}

export const TreeConfig: IComponentItem = {
  type: CTree,
  isList: true,
  isTextComp: true, // 数据填充文本-判断需要
  thumb: {
    spriteIconClass: SpriteThumb.Tree.className,
    dragPosition: SpriteThumb.Tree.position,
  },
  name: i18n('resource.components.tree'),
  editor: {
    onPropertyUpdate: onTreePropertyUpdate,
    onClone: onTreeClone,
    specials: {
      makeTreeItem,
    },
  },
  preview: {
    onTriggerState: (
      container: UIComponent,
      triggerComp: UIComponent,
      stateName: string,
      value: any,
    ): ArtboardOperations | null => {
      const options: ArtboardOperations = {};
      const treeComp = container as UITreeComponent;

      // 查找value 中的realation 中的数据， 修改triggerComp 对应的item 的expand 设置
      const { relation } = treeComp.value as ITreeData;
      const arrTree = treeToArr(relation);
      const expandCompId = triggerComp.parent?.id || '';
      if (value === TreeItemColumn.Expand) {
        const expand = stateName === 'checked';
        const newArrTree = arrTree.map((item) => {
          if (item.id === expandCompId) {
            item.expand = expand;
          }
          return item;
        });
        const newTreeData = arrToTree(newArrTree);
        options[container.id] = [Ops.replace('/value', { relation: newTreeData })];
      } else if (value === TreeItemColumn.CheckBox) {
        //找到当前节点， 往父级判断是否需要选择
        const compDatas = treeComp.components.map((comp) => comp.$data);
        const { tree } = createAvlTreeWithTreeComp(relation, compDatas);
        const node = tree.get(expandCompId);
        if (node) {
          const changeCompInfo = getAdjustCompInfo(node);
          treeComp.components.forEach((comp) => {
            if (changeCompInfo[comp.id] !== undefined) {
              const state = changeCompInfo[comp.id] === TreeCheckboxState.Checked;
              const checkboxCompIcon = (comp as UITreeItemComponent).getComponentByAlias(TreeItemAlias.CheckBoxIcon);
              const checkboxComp = (comp as UITreeItemComponent).getComponentByAlias(TreeItemAlias.CheckBox);
              options[checkboxComp!.id] = [
                Ops.replace('/selected', state),
                Ops.replace('/_currentState', state ? 'checked' : 'unchecked'),
              ];
              options[checkboxCompIcon!.id] = [
                Ops.replace('/selected', state),
                Ops.replace('/_currentState', state ? 'checked' : 'unchecked'),
              ];
            }
          });
        }
      }
      return options;
    },
  },
  getDefaultData() {
    return {
      properties: {
        container: {
          scroll: true,
          showScroll: true,
          hidden: true,
        },
        fill: {
          disabled: false,
          type: FillType.solid,
          color: {
            r: 255,
            g: 255,
            b: 255,
            a: 1,
          },
        },
        stroke: {
          thickness: 1,
          color: {
            r: 119,
            g: 119,
            b: 119,
            a: 1,
          },
          disabled: false,
        },
        border: DefaultBorder,
        textStyle: {
          color: SystemsColor.DefaultTextColor,
          fontFamily: 'Microsoft YaHei',
          fontStyle: { underline: false, bold: false, strike: false, italic: false },
          fontSize: 14,
          textAlign: TextAlign.left,
        },
        padding: {
          left: 10,
          right: 10,
          top: 5,
          bottom: 5,
          disabled: false,
        },
        itemLineHeight: {
          name: i18n('property.propertyNames.lineHeight'),
          prop: 'number',
          value: {
            value: defaultLineHeight,
          },
        },
        levelIndent: {
          name: i18n('property.component.tree.levelIndent'),
          prop: 'number',
          value: {
            value: defaultIndent,
          },
        },
        itemCheckedFill: {
          name: i18n('property.propertyNames.checkedBgcolor'),
          prop: 'fill',
          type: FillType.solid,
          color: SystemsColor.LightGrayColor,
          disabled: false,
        },
        itemCheckedTextStyle: {
          name: i18n('property.propertyNames.checkedText'),
          prop: 'textStyle',
          color: SystemsColor.DefaultSelectColor,
          fontFamily: 'Microsoft YaHei',
          fontStyle: { underline: false, bold: false, strike: false, italic: false },
          fontSize: 14,
          textAlign: TextAlign.left,
        },
        treeExpand: {
          name: i18n('property.component.tree.treeExpand'),
          prop: 'tree',
          isShow: true,
          chooseExpandType: DefaultCollapseIconType.Arrow,
          iconColor: SystemsColor.DefaultSelectColor,
          iconSize: 12,
        },
        treeCheckbox: {
          name: i18n('property.component.tree.treeCheckbox'),
          prop: 'tree',
          isShow: false,
          iconColor: SystemsColor.DefaultSelectColor,
          iconSize: 12,
        },
        treeNode: {
          name: i18n('property.component.tree.treeNode'),
          prop: 'tree',
          isShow: true,
          iconColor: SystemsColor.DefaultSelectColor,
          iconSize: 12,
          checkedIconColor: SystemsColor.DefaultSelectColor,
        },
      },
    };
  },
};

export function makeTree(id: string) {
  const treeItemData = getDefaultTreeData();
  return makeCommonComponent(id, CTree, {
    ...TreeConfig.getDefaultData?.(),
    size: { width: 200, height: 280 },
    sealed: true,
    layout: {
      vertical: VerticalAlign.TopAndBottom,
      horizontal: HorizontalAlign.Right,
      fixedWidth: true,
      fixedHeight: false,
      auto: true,
      responsive: false,
    },
    value: {
      expandACollapseIcon: defaultExpandIcon,
      relation: treeItemData,
    },
    components: [
      ...treeToArr(treeItemData).map((item, index) => {
        return makeTreeItem(item, index);
      }),
    ],
    select: {
      target: 'child',
      minCount: 1,
      maxCount: 1,
      autoUnselect: true,
      reversible: true,
      enabled: true,
    },
  });
}

/**
 * 构建的一个IComponentData
 * @param item
 * @param index
 * @param unSelected
 */
export function makeTreeItem(item: ITreeArrData, index: number, unSelected?: boolean) {
  return makeCommonComponent(item.id, CTreeItem, {
    size: { width: 200, height: defaultLineHeight },
    position: { x: 0, y: 0 },
    selected: index === 1 && unSelected,
    components: [
      makeIcon(getNewID(), {
        alias: TreeItemAlias.ExpandIcon,
        column: TreeItemColumn.Expand,
        size: { width: 12, height: 12 },
        value: defaultExpandIcon.collapse.icon,
        selected: true,
        properties: {
          icon: {
            color: SystemsColor.DefaultTextColor,
          },
        },
        states: {
          checked: {
            enabled: true,
            properties: {},
            value: defaultExpandIcon.expand.icon,
          },
        },
        select: {
          target: 'self',
          enabled: true,
          reversible: true,
        },
      }),
      makeCommonComponent(getNewID(), CCanvasPanel, {
        alias: TreeItemAlias.CheckBox,
        column: TreeItemColumn.CheckBox,
        size: { width: 12, height: 12, lockedRatio: true },
        layout: {
          responsive: true,
          auto: true,
          fixedWidth: false,
          fixedHeight: false,
          horizontal: HorizontalAlign.Auto,
          vertical: VerticalAlign.Auto,
        },
        properties: {
          stroke: {
            color: SystemsColor.DefaultStrokeColor,
            thickness: 1,
            disabled: false,
          },
          icon: {
            color: SystemsColor.DefaultTextColor,
          },
        },
        selected: true,
        components: [
          makeIcon(getNewID(), {
            alias: TreeItemAlias.CheckBoxIcon,
            size: { width: 12, height: 12, lockedRatio: true },
            layout: {
              responsive: true,
              auto: false,
              fixedWidth: false,
              fixedHeight: false,
              horizontal: HorizontalAlign.Center,
              vertical: VerticalAlign.Middle,
            },
            properties: {
              icon: { ref: '@properties.icon' },
            },
            hidden: true,
            value: { iconCode: 60040, fontName: 'lightIconFont' },
            states: {
              checked: {
                enabled: true,
                properties: {},
                hidden: false,
              },
            },
          }),
        ],
        select: {
          target: 'self',
          enabled: true,
          reversible: true,
        },
      }),
      makeIcon(getNewID(), {
        alias: TreeItemAlias.NodeIcon,
        column: TreeItemColumn.Node,
        size: { width: 12, height: 12 },
        value: item.isParent ? item.nodeIcon.expand.icon : item.nodeIcon.collapse.icon,
      }),
      makeCommonComponent(getNewID(), CPureText, {
        alias: TreeItemAlias.NodeText,
        size: { width: 100, height: 30 },
        column: TreeItemColumn.Text,
        value: `${i18n('property.component.tree.itemText')} ${item.index + 1}`,
        properties: {
          textStyle: { ref: '@properties.textStyle' },
        },
        states: {
          checked: {
            enabled: true,
            properties: {
              textStyle: { ref: '@properties.itemCheckedTextStyle' },
            },
          },
        },
      }),
    ],
  });
}
