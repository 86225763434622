import * as React from 'react';
import { isUndefined } from 'lodash';

import { UIComponent } from '@editor/comps';
import { IComponentData } from '@fbs/rp/models/component';
import { getNewID } from '@helpers/idHelper';
import { IBounds } from '@fbs/common/models/common';

import { makeCommonComponent, addTooltipsProperty } from '../../helper';
import { CSymbol } from '../../constants';

interface ISymbolGroup {
  comp: UIComponent;
}

export const makeSymbol = addTooltipsProperty(_makeSymbol);

function _makeSymbol(bounds: IBounds, components: IComponentData[]): IComponentData {
  const { left: x, top: y, width, height } = bounds;
  return makeCommonComponent(getNewID(), CSymbol, {
    position: { x, y },
    size: {
      width,
      height,
    },
    components: components,
  });
}

export default class SymbolGroup extends React.Component<ISymbolGroup> {
  render() {
    const { opacity: alpha } = this.props.comp;
    const opacity = isUndefined(alpha) ? 1 : alpha / 100;
    return (
      <div style={{ opacity }} className="lib-comp-symbol">
        {this.props.children}
      </div>
    );
  }
}
