import * as React from 'react';
import * as ReactDom from 'react-dom';

import classnames from 'classnames';
import { isUndefined, cloneDeep, isEqual } from 'lodash';
import { depthClone, isEqualDate, sameNumber } from '@utils/globalUtils';
import MathUtils from '@utils/mathUtils';

import { ArtboardPatches, Operation } from '@fbs/rp/utils/patch';
import { ISize } from '@fbs/common/models/common';
import { EventTypes } from '@fbs/rp/models/event';
import { ITableValue } from '@fbs/rp/models/table';
import { ISnapshotValue } from '@/fbs/rp/models/value';
import { MobileType } from '@/fbs/rp/utils/preview';

import { isMobileDevice, isStandalonePreview } from '@helpers/previewHelper';
import appOptions from '@helpers/appOptions';
import { hasInteraction } from '@helpers/interactionHelper';
import { getCompAbsoluteMatrix } from '@helpers/componentHelper';
import { IControllerKey } from '@/helpers/shortCutHelper';

import { UIComponent, UIContainerComponent /* UIContentPanelComponent */ } from '@editor/comps';
import { makeUIComponent } from '@editor/comps/factory';
import { DynamicEditInfo } from '@editor/comps/resizeHelper';
import CoreEditor from '@/editor/core';
import { isPredefinedState } from '@consts/state';
import EditorContext from '@contexts/editor';
import RefactorRemarkManager from '@managers/refactorRemarkManager';

import Tooltip from '@dsm/Tooltip';

import BasicComponentLib from './basic/lib';
import {
  IComponentMouseEvent,
  IComponentTouchEvent,
  IComponentFocusEvent,
  IListSealedComponentItemEvents,
  THotAreaVisibleModel,
} from './types';
import {
  CCarouselChart,
  CTable,
  CTree,
  CNavigationMenu,
  CHorizontalMenu,
  CVerticalMenu,
  CSelect,
  CAudio,
  CMultipleSelect,
  CCompoundPath,
  CConnector,
  CLine,
  CStickNote,
  CCollapse,
} from './constants';
import SelectingComponent, { RefProps } from './compSelecting';
import RemarkOrderNum from './RemarkOrderNum';
import Connector from '../components/Common/Connector';
import CacheComponentWrapper from './CacheComponentWrapper';

import './index.scss';

interface IComponentProps {
  children?: any;
  revision: string;
  comp: UIComponent;
  editor?: CoreEditor;
  hidden?: boolean;
  locked?: boolean;
  scale?: number;
  isArtboardChoosingRect?: boolean;
  isRevision?: boolean;
  showInteract?: boolean; // 是否显示交互标识
  /**
   * 全局缩放比例，取值0-1之间，它不受父组件的缩放比例影响
   */
  globalScale?: number;
  // 被其他谁选中了这个组件
  selectedByOtherWho?: string;
  isActivated?: boolean;
  isParentActivated?: boolean;
  isCMDPressed?: boolean;
  isActiveParent?: boolean;
  isPreview?: boolean;
  isForceUpdate?: boolean;
  mobileType?: MobileType;
  noBoundary?: boolean;
  showRemarkTag?: boolean;
  isContainerActiveContainer?: boolean;
  selected?: boolean;
  selectedTag?: string;
  // 组件偏移量，一般用于处理组件的移动过程中的偏移
  offsetX?: number;
  offsetY?: number;
  targetHovered?: boolean;
  forceHover?: boolean;
  forceUpdate?: boolean;
  hoverWithSelected?: boolean;
  isStandalone?: boolean;
  showPreviewRemarkPopupId?: string;
  hotAreaVisibleModel?: THotAreaVisibleModel; // 热区显示方式 always | hover
  showMobileCursor?: boolean; // 是否显示移动鼠标样式
  controllerKey?: IControllerKey;

  onMouseDown?: IComponentMouseEvent;
  onMouseUp?: IComponentMouseEvent;
  onTouchStart?: IComponentTouchEvent;
  onTouchEnd?: IComponentTouchEvent;
  onDoubleClick?: IComponentMouseEvent;
  onClick?: IComponentMouseEvent;
  onMouseEnter?: IComponentMouseEvent;
  onMouseLeave?: IComponentMouseEvent;
  onFocus?: IComponentFocusEvent;
  onBlur?: IComponentFocusEvent;
  onContextMenu?: IComponentMouseEvent;
  onTouchMove?: (event: React.TouchEvent, comp: UIComponent) => void;

  index?: number;
  /**
   * 演示时使用，用于处理 this.forceUpdate 调用时，children 能正常更新，
   * 不然会用 this.props.children （即旧的 children）进行渲染。
   */
  renderSubComponents?: (container: UIContainerComponent, scale: number, noBoundary?: boolean) => React.ReactNode;
  onInnerAction?: (actions: { comp: UIComponent; actions: Operation[] }[]) => void;
  // 是否需要强制更新自己、演示时使用
  onForceUpdate?: (comp?: UIComponent) => void;
  // TODO 考虑直接在组件上进行编辑器显示及操作
  onValueEdited?: (comp: UIComponent, value: any, option: { wrap?: boolean; size?: ISize }) => void;
  onValueEditing?: () => void;
  // 提交表格编辑
  onTableValueChanged?: (value: ITableValue, childrenPatches?: ArtboardPatches) => void;
  onSnapshotValueChanged?: (updateInfo: ISnapshotValue, size?: ISize | undefined) => void;
  valueEditing?: boolean;
  customValueEditing?: boolean;
  // 是否被选中，编辑时的临时状态
  selecting?: boolean;
  // 是否是拖动控制点进行组件连接
  canConnect?: boolean;

  onlySelected?: boolean;

  //组件连接点触发事件
  onMouseDownStartConnect?: (compId: string, direction: string) => void;

  onMouseUpEndConnect?: (compId: string, direction: string) => void;

  //组件连接点进入事件
  onControlMouseEnter?: (compId: string, direction: string) => void;

  onControlMouseLeave?(): void;

  onShowRemarkFloatPanel?: (
    isShow: boolean,
    comp: UIComponent,
    point?: {
      left: number;
      top: number;
    },
  ) => void;
}

interface IComponentState {
  hasError: boolean;
  isDragging: boolean;
  style: React.CSSProperties;
  stateComp?: UIComponent;
  showConnector: boolean;
  hover?: boolean;
  globalPoint?: { left: number; top: number };
}

export default class Component extends React.Component<IComponentProps, IComponentState> {
  static contextType = EditorContext;
  // @ts-ignore
  context: React.ContextType<typeof EditorContext>;
  private version?: string;
  private focused: boolean = false;
  private registMessateEvent: boolean = false;

  private itemEvents?: IListSealedComponentItemEvents;
  private isList: boolean = false;
  private oldDynamicInfo?: DynamicEditInfo;

  private remarkTagRef: React.RefObject<HTMLDivElement> = React.createRef();
  private dom: React.RefObject<HTMLDivElement> = React.createRef();
  private selectingCompDom: React.RefObject<RefProps> = React.createRef();

  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      hasError: false,
      isDragging: false,
      style: parserStyle(props),
      showConnector: false,
    };
    this.isList = !!props.comp.libData?.isList;
    // 轮播图子项只支持单击，双击和右键
    if (props.isPreview && props.comp.lib?.type === CCarouselChart) {
      this.itemEvents = {
        onItemContextMenu: this.handleItemMouseEvent,
        onItemClick: this.handleItemMouseEvent,
        onItemDoubleClick: this.handleItemMouseEvent,
      };
    } else if (this.isList && props.isPreview) {
      this.itemEvents = {
        onItemContextMenu: this.handleItemMouseEvent,
        onItemClick: this.handleItemMouseEvent,
        onItemDoubleClick: this.handleItemMouseEvent,
        onItemEnter: this.handleItemMouseEvent,
        onItemLeave: this.handleItemMouseEvent,
        onItemMouseDown: this.handleItemMouseEvent,
        onItemMouseUp: this.handleItemMouseEvent,
        onItemMouseOver: this.handleItemMouseEvent,
      };
    }
    this.version = props.comp.version; // 初始化组件version
  }

  componentDidMount() {
    const { comp, isPreview } = this.props;
    comp.updateComponentView = this.updateUI;
    comp.updateSelectingCompView = this.updateSelectingComp;
    this.calculateGlobalPoint();

    if (isPreview) {
      window.addEventListener('scroll', this.handleWindowScroll, { capture: true });

      // @ts-ignore
      if (!comp.hidden && comp.toJSON()._bringFront) {
        window.addEventListener('pageScaleChange', this.handleBringFrontCompScaleWhenPageScaleChange);
      }
    }
  }

  componentDidUpdate() {
    this.calculateGlobalPoint();
    // 副作用，移动到 didUpdate 中
    const { isPreview, comp } = this.props;
    comp.updateComponentView = this.updateUI;
    comp.updateSelectingCompView = this.updateSelectingComp;
    if (isPreview) {
      // @ts-ignore
      if (!comp.hidden && comp.toJSON()._bringFront) {
        if (!this.registMessateEvent) {
          this.registMessateEvent = true;
          window.addEventListener('message', this.handleMessage);
        }
        window.addEventListener('pageScaleChange', this.handleBringFrontCompScaleWhenPageScaleChange);
      } else {
        if (this.registMessateEvent) {
          this.registMessateEvent = false;
          window.removeEventListener('message', this.handleMessage);
        }
        window.removeEventListener('pageScaleChange', this.handleBringFrontCompScaleWhenPageScaleChange);
      }
    }
  }

  componentWillUnmount() {
    this.props.comp.updateComponentView = undefined;
    window.removeEventListener('scroll', this.handleWindowScroll, { capture: true });
    window.removeEventListener('message', this.handleMessage);
    window.removeEventListener('pageScaleChange', this.handleBringFrontCompScaleWhenPageScaleChange);
  }

  shouldComponentUpdate(nextProps: Readonly<IComponentProps>, nextState: Readonly<IComponentState>): boolean {
    return this.isNeedRender(nextProps, nextState);
  }

  componentDidCatch(error: Error): void {
    console.error(error);
    this.setState({
      hasError: true,
    });
  }

  static getDerivedStateFromProps(nextProps: IComponentProps, prevState: IComponentState) {
    // TODO 在框选阶段 不用计算 style
    if (!nextProps.isArtboardChoosingRect && !nextProps.comp.isMoving) {
      const style = parserStyle(nextProps);
      if (!isEqualDate(prevState.style, style)) {
        return { style };
      }
    }
    return null;
  }

  /**
   * 调用该方法达到只重新渲染当前组件
   * @param onlyBounds  判断是否为纯边界的更新 如移动
   * @param keyboardOffset   键盘事件移动坐标
   */
  updateUI = (onlyBounds?: boolean, keyboardOffset?: { x: number; y: number }) => {
    if (onlyBounds) {
      const { comp, scale, offsetX, offsetY } = this.props;
      const { x, y } = keyboardOffset || {};
      const style = comp.getWrapperStyle(
        { x: x || offsetX || 0, y: y || offsetY || 0 },
        { x: scale || 1, y: scale || 1 },
        scale || 1,
        // true,
        false,
      );
      const newStyle = { ...this.state.style, ...style };
      this.setState({ style: newStyle });
    } else {
      this.forceUpdate();
    }
  };

  updateSelectingComp = () => {
    this.selectingCompDom.current?.updateView();
  };

  /*
    计算是否使用快照属性
    因为content-visibility自带了一个类似overflow效果，要避免子组件超出父组件导致被错误隐藏的情况
    1. 流程线被隐藏：(问题原因: 父节点宽高为0)
    2. 流程线模式下：hover组件的x连接点显示不全（问题原因: 超出父节点宽高）
    3. 内容备注的编号被隐藏（问题原因: 超出父节点宽高）
    4. 表格组件边框显示不全（问题原因: 超出父节点宽高）
    5. 预览和被选中，被激活不显示
    6. 预设形状组件：数据库和对照表边框显示不全（问题原因: 超出父节点宽高）
    7. 存在阴影
    8. 形状组件，存在边框的情况
    9. 内部存在文本
    10. 字体大小大于行高
    11. icon组件,源文件可能存在一些边角，导致显示不全
  */
  doCalculateOptimization = (): boolean => {
    const { comp, isPreview, selectedByOtherWho, isActivated, isRevision, showRemarkTag, valueEditing } = this.props;
    const { style } = this.state;
    const { remark } = comp;

    //获取到当前设置的状态下属性
    const modelKeys = Object.keys(comp.states);
    const enabledKey = modelKeys.find((e) => comp.states[e] && (comp.states[e] as any).enabled);
    const properties = enabledKey ? comp.states[enabledKey].properties || comp.$data.properties : comp.$data.properties;
    //预览和选中，或者值编辑，不使用
    if (isPreview || selectedByOtherWho || isActivated || valueEditing) {
      return false;
    }
    //流程线这种宽高都为0的
    if ((!!style.height && style.height === 0) || (!!style.width && style.width === 0)) {
      return false;
    }
    //预设形状组件：数据库和对照
    //ComponentBase基础类继承属性data。目前ts识别存在问题，这里用any强制声明一下
    if (
      (comp as any).data &&
      ((comp as any).data.lib?.type === 'dataBase' || (comp as any).data.lib?.type === 'collate')
    ) {
      return false;
    }
    //icon组件,源文件可能存在一些边角，导致显示不全
    if ((comp as any).data && (comp as any).data.type === 'icon') {
      return false;
    }
    //形状组件，存在边框的情况
    if (comp.$data.type === 'path' && !properties.stroke?.disabled) {
      return false;
    }
    //内部存在文本
    if (comp.$data.text) {
      return false;
    }
    //存在阴影的情况
    if (!properties.shadow?.disabled) {
      return false;
    }
    //字体大小大于行高
    const lineHeight = properties.textFormat?.lineHeight || properties.textFormat?.lineHeightEx || 0;
    const fontSize = properties.textFormat?.fontSize || 0;
    if (fontSize > lineHeight) {
      return false;
    }
    //存在备注的情况也不显示
    const needShow = this.needShow;
    const hasRemark = !!remark;
    const needShowRemark = hasRemark && !isRevision && showRemarkTag;
    if (needShow && needShowRemark) {
      return false;
    }
    //连接线
    if (!valueEditing && comp.canConnect && this.context.mode === 'flow') {
      return false;
    }
    //线段和连接线
    if ([CConnector, CLine].includes(comp.type)) {
      return false;
    }
    return true;
  };

  handleMessage = (e: MessageEvent) => {
    if (e.data === 'viewportChanged') {
      this.calculateGlobalPoint();
    }
  };

  handleWindowScroll = () => {
    this.calculateGlobalPoint();
  };

  handleBringFrontCompScaleWhenPageScaleChange = () => {
    this.updateUI();
  };

  calculateGlobalPoint(): void {
    if (this.props.isPreview) {
      const { comp } = this.props;
      if (!comp.hidden && comp.toJSON()._bringFront) {
        const { left: x, top: y, right, bottom } = this.dom.current!.getBoundingClientRect();
        let centerX = (x + right) / 2;
        let centerY = (y + bottom) / 2;
        const bringFrontCompWrap = document.querySelector('.bring-front-comps');
        // 将交互置于顶部的元素append到bring-front-comps内部，位置相对于bring-front-comps计算
        if (bringFrontCompWrap) {
          const rectInfo = bringFrontCompWrap!.getBoundingClientRect();
          centerX -= rectInfo.left || 0;
          centerY -= rectInfo.top || 0;
        }
        const { globalPoint } = this.state;
        if (!globalPoint || globalPoint.left !== centerX || globalPoint.top !== centerY) {
          this.setState({ globalPoint: { left: centerX, top: centerY } });
        }
      }
    }
  }

  private isNeedRender(nextProps: Readonly<IComponentProps>, nextState: Readonly<IComponentState>) {
    //是否强制渲染
    if (nextProps.isForceUpdate) {
      return true;
    }
    // 移动过程中，style值发生了变化，所以判断直接触发更新就ok了。
    if (this.state.style !== nextState.style) {
      return true;
    }
    // mty NOTE: 因退出 symbol 编辑模式后备注编号更新问题而添加的，只是让 symbol 最外层re-render
    if (nextProps.comp.isSymbol) {
      return true;
    }
    if (this.props.customValueEditing) {
      return true;
    }
    if (this.props.index !== nextProps.index) {
      return true;
    }
    if (this.props.hidden !== nextProps.hidden) {
      return true;
    }
    if (this.props.locked !== nextProps.locked) {
      return true;
    }
    if (this.props.revision !== nextProps.revision) {
      return true;
    }
    const nextVersion = nextProps.comp.version;
    if (this.version !== nextVersion) {
      this.version = nextVersion;
      return true;
    }
    if (!isEqual(nextProps.comp.dynamicInfo, this.oldDynamicInfo)) {
      this.oldDynamicInfo = cloneDeep(nextProps.comp.dynamicInfo);
      return true;
    }
    if (this.props.selectedByOtherWho !== nextProps.selectedByOtherWho) {
      return true;
    }

    if (this.props.isActivated !== nextProps.isActivated) {
      return true;
    }

    if (this.props.isParentActivated !== nextProps.isParentActivated) {
      return true;
    }

    if (!sameNumber(this.props.offsetX || 0, nextProps.offsetX || 0)) {
      return true;
    }

    if (!sameNumber(this.props.offsetY || 0, nextProps.offsetY || 0)) {
      return true;
    }
    if (this.props.targetHovered !== nextProps.targetHovered) {
      return true;
    }
    if (this.props.selected !== nextProps.selected) {
      return true;
    }
    if (this.props.valueEditing !== nextProps.valueEditing) {
      return true;
    }
    if (this.props.selecting !== nextProps.selecting) {
      return true;
    }
    if (this.state.showConnector !== nextState.showConnector) {
      return true;
    }
    if (!sameNumber(this.props.globalScale || 1, nextProps.globalScale || 1)) {
      return true;
    }
    if (this.state.hover !== nextState.hover) {
      return true;
    }
    if (this.props.hoverWithSelected !== nextProps.hoverWithSelected) {
      return true;
    }
    if (this.props.forceHover !== nextProps.forceHover) {
      return true;
    }
    if (this.props.forceUpdate !== nextProps.forceUpdate || nextProps.forceUpdate) {
      return true;
    }
    if (this.props.isPreview) {
      if (this.state.globalPoint !== nextState.globalPoint) {
        return true;
      }
    }
    if (this.props.selectedTag !== nextProps.selectedTag) {
      return true;
    }
    if (this.props.showRemarkTag !== nextProps.showRemarkTag) {
      return true;
    }
    if (this.props.hotAreaVisibleModel !== nextProps.hotAreaVisibleModel) {
      return true;
    }
    if (this.props.showMobileCursor !== nextProps.showMobileCursor) {
      return true;
    }
    return false;
  }

  private doExecuteEventHandle(
    event: React.MouseEvent | React.TouchEvent,
    eventType: EventTypes,
    eventHandle?: IComponentMouseEvent | IComponentTouchEvent,
  ) {
    const { comp, isPreview } = this.props;
    if (isPreview && comp.disabled) {
      return;
    }
    this.stopPropagation(event, eventType);
    if (eventHandle) {
      if (event.nativeEvent instanceof MouseEvent) {
        (eventHandle as IComponentMouseEvent)(event as React.MouseEvent, comp);
      } else {
        (eventHandle as IComponentTouchEvent)(event as React.TouchEvent, comp);
      }
    }
  }

  handleClick = (e: React.MouseEvent) => {
    this.doExecuteEventHandle(e, EventTypes.click, this.props.onClick);
  };

  handleDoubleClick = (e: React.MouseEvent) => {
    this.doExecuteEventHandle(e, EventTypes.doubleClick, this.props.onDoubleClick);
  };

  handleMouseDown = (e: React.MouseEvent) => {
    if (isMobileDevice()) {
      return;
    }
    const { onMouseDown, comp, isPreview, targetHovered } = this.props;
    if (!isPreview && targetHovered) {
      e.stopPropagation();
    }
    if (isPreview && comp.disabled) {
      return;
    }
    if (onMouseDown) {
      onMouseDown(e, comp);
    }
  };

  handleMouseUp = (e: React.MouseEvent) => {
    if (isMobileDevice()) {
      return;
    }
    const { isPreview, comp, onMouseUp, targetHovered } = this.props;
    if (onMouseUp) {
      // 查找交互目标期间，阻止冒泡
      if (!isPreview && targetHovered) {
        e.stopPropagation();
      }
      if (isPreview && comp.disabled) {
        return;
      }
      onMouseUp(e, comp);
    }
  };

  // 手势需要，勿阻止冒泡
  handleTouchStart = (e: React.TouchEvent) => {
    this.doExecuteEventHandle(e, EventTypes.mouseDown, this.props.onTouchStart);
  };

  // 手势需要，勿阻止冒泡
  handleTouchMove = (e: React.TouchEvent) => {
    const { isPreview, comp, onTouchMove } = this.props;
    // 非演示界面及组件为禁用不执行该事件
    if (!isPreview || comp.disabled) {
      return false;
    }
    onTouchMove && onTouchMove(e, comp);
  };

  // 手势需要，勿阻止冒泡
  handleTouchEnd = (e: React.TouchEvent) => {
    this.doExecuteEventHandle(e, EventTypes.mouseUp, this.props.onTouchEnd);
  };

  private get needShow() {
    const { comp } = this.props;
    if (this.props.isPreview) {
      return !this.props.comp.hidden;
    }
    if (comp.hidden) {
      return MathUtils.variable<boolean>(this.context.userPreference?.generalSettings.showHiddenArea, true);
    }
    return true;
  }

  handleMouseEnter = (e: React.MouseEvent) => {
    const { onMouseEnter, comp, isPreview, targetHovered, valueEditing, isParentActivated, editor } = this.props;
    if (editor?.isArtboardChooseRectInMoving) {
      return;
    }
    if (!this.needShow) {
      return;
    }
    if (onMouseEnter) {
      if (!isPreview && targetHovered) {
        e.stopPropagation();
      }
      const allowTrigger = !isPreview || (isPreview && !comp.disabled);
      allowTrigger && onMouseEnter(e, comp);
    }
    if (!comp.isConnector) {
      this.setState({
        showConnector: true,
      });
    }
    if (valueEditing) {
      return;
    }
    if (!comp.locked && !comp.hidden && !isPreview && e.buttons === 0) {
      this.setState({ hover: isParentActivated });
    }
  };

  handleMouseLeave = (e: React.MouseEvent) => {
    if (this.props.editor?.isArtboardChooseRectInMoving) {
      return;
    }

    if (this.state.hover) {
      this.setState({ hover: false });
    }

    if (this.focused) {
      this.setState({ showConnector: false });
      return;
    }
    const { onMouseLeave, comp, isPreview } = this.props;
    const allowTrigger = !isPreview || (isPreview && !comp.disabled);
    if (onMouseLeave) {
      allowTrigger && onMouseLeave(e, comp);
    }
    if (!comp.isConnector && !comp.locked) {
      this.setState({
        showConnector: false,
      });
    }
  };

  handleContextMenu = (e: React.MouseEvent) => {
    const { isPreview, comp, targetHovered, onContextMenu } = this.props;
    if (isPreview && comp.disabled) {
      return;
    }
    // 屏蔽演示和编辑器页面下的鼠标右键
    e.preventDefault();
    if (onContextMenu) {
      this.stopPropagation(e, EventTypes.contextMenu);
      if (!isPreview && targetHovered) {
        e.stopPropagation();
      }
      onContextMenu(e, comp);
    }
  };

  handleMouseOver = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  handleFocus = (e: React.FocusEvent) => {
    this.focused = true;
    const { comp, onFocus } = this.props;
    onFocus && onFocus(e, comp);
  };

  handleBlur = (e: React.FocusEvent) => {
    this.focused = false;
    const { comp, onBlur } = this.props;
    onBlur && onBlur(e, comp);
  };

  handleItemMouseEvent = (e: React.MouseEvent, comp: UIComponent) => {
    const { type } = e;
    const {
      onClick,
      onMouseEnter,
      onMouseLeave,
      onMouseDown,
      onMouseUp,
      onDoubleClick,
      onContextMenu,
      comp: _comp,
    } = this.props;
    if (comp.disabled || _comp.disabled || comp.hidden || _comp.hidden) {
      return;
    }
    let event: IComponentMouseEvent | undefined = undefined;
    switch (type) {
      case 'click':
        event = onClick;
        break;
      case 'mousedown':
        event = onMouseDown;
        break;
      case 'mouseup':
        event = onMouseUp;
        break;
      case 'mouseenter':
        event = onMouseEnter;
        break;
      case 'dblclick':
        event = onDoubleClick;
        break;
      case 'mouseleave':
        event = onMouseLeave;
        break;
      case 'contextmenu':
        event = onContextMenu;
        break;
      default:
        break;
    }
    if (event) {
      event(e, comp);
    }
  };

  handleRemarkTagMouseDown = (ev: React.MouseEvent) => {
    const { isPreview } = this.props;
    if (!isPreview) {
      ev.stopPropagation();
    }
  };

  // 进入绑定在dom的事件中获取dom是必定存在的
  handleRemarkTagClick = (isShow: boolean, comp: UIComponent, ev: React.MouseEvent) => {
    const { isPreview } = this.props;
    if (!isPreview || comp.hidden || comp.parent?.hidden) {
      ev.stopPropagation();
      return false;
    }

    let point = {
      left: 0,
      top: 0,
    };
    // 设计稿预留的空隙宽度
    const moreWidth = 8;
    ev?.stopPropagation();
    this.props.onShowRemarkFloatPanel?.(isShow, comp); // 先插入到DOM后获取panel宽度去计算位置

    // 确保能获取到展示的panel的宽高， 获取后重新计算并设置位置信息
    window.setTimeout(() => {
      const bound = this.remarkTagRef.current?.getBoundingClientRect();
      const viewportBound = document.querySelector('.preview-view-port')?.getBoundingClientRect();
      const panelBound = document.querySelector('.remark-float-panel')?.getBoundingClientRect();
      if (bound && viewportBound && panelBound) {
        const { left, right, top } = bound;
        const { right: viewportRight, bottom: viewportBottom } = viewportBound;
        const { width: panelWidth, height: panelHeight } = panelBound;
        point.left = right + moreWidth;
        point.top = top;
        // 超出可视区情况
        const overRight = right + moreWidth + panelWidth > viewportRight;
        const overBottom = top + panelHeight > viewportBottom;
        const fitLeft = left - moreWidth - panelWidth;
        const fitTop = viewportBottom - panelHeight - moreWidth;
        if (overRight && overBottom) {
          point.left = fitLeft;
          point.top = fitTop;
        } else if (overBottom) {
          point.top = fitTop;
        } else if (overRight) {
          point.left = fitLeft;
        }
        if (point.top < 0) {
          point.top = 0;
        }
        this.props.onShowRemarkFloatPanel?.(isShow, comp, point);
      }
    }, 20);
  };

  renderContent() {
    const { comp, isPreview } = this.props;
    if (!isPreview) {
      return this.renderComp();
    }

    // @ts-ignore
    const bringFront = comp.toJSON()._bringFront;
    if (bringFront && comp.visibleInPreview) {
      return this.renderBringFrontComp();
    }

    return this.renderComp();
  }

  renderBringFrontComp() {
    const { globalPoint } = this.state;
    const { comp } = this.props;

    const matrix = getCompAbsoluteMatrix(comp);
    const { x, y } = matrix.translation;
    const { width, height } = comp.size;
    const left = globalPoint ? globalPoint.left - width / 2 : x;
    const top = globalPoint ? globalPoint.top - height / 2 : y;

    const { _animation: animation, _scale, rotate } = comp;
    if (comp.inAnimation()) {
      return null;
    }

    const style: React.CSSProperties = {
      left,
      top,
      width,
      height,
      transform: `scale(${(window.pageScale * _scale.x, window.pageScale * _scale.y)}) rotate(${rotate}deg)`,
    };
    style.transition = comp.getTransition();

    if (animation) {
      style.animationIterationCount = animation.animationIterationCount;
      // 内容面板切换keyframe动画
      style.animationTimingFunction = animation.timing;
      style.animationDuration = `${animation.duration || 1}ms`;
      // style.animationName = animation.animationName;
      style.animationFillMode = `${animation.name ? 'forwards' : ''}`;
    }
    return ReactDom.createPortal(
      <div className={classnames('bring-front-layer', { disabled: comp.disabled })} style={style}>
        {this.renderComp()}
        {hasInteraction(comp) && !comp.disabled && <div className="hot-area bring-front" />}
      </div>,
      document.querySelector('.bring-front-comps') || document.body,
    );
  }

  renderComp(): React.ReactNode {
    if (this.state.hasError) {
      return `Error to render this component ${this.props.comp.type}`;
    }
    const { comp, isPreview, isStandalone, isContainerActiveContainer, mobileType } = this.props;
    //流程线在编辑的隐藏状态下也进行渲染, 不然无法再次选中
    if (!isPreview && comp.hidden && !comp.isConnector && (!comp.isContainer || !isContainerActiveContainer)) {
      return (
        <div
          className={classnames({
            'hidden-layer': MathUtils.variable(this.context.userPreference?.generalSettings.showHiddenArea, true),
          })}
        />
      );
    }

    const {
      renderSubComponents,
      noBoundary,
      children,
      valueEditing,
      onValueEdited,
      scale,
      onTableValueChanged,
      onSnapshotValueChanged,
      globalScale,
      onValueEditing,
      isParentActivated,
      isRevision,
      onInnerAction,
      onForceUpdate,
      onClick,
      onDoubleClick,
      onContextMenu,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      onTouchStart,
      onMouseEnter,
      onMouseLeave,
      onFocus,
      onBlur,
      selected,
      selecting,
      hoverWithSelected,
      onlySelected,
      showInteract,
      hotAreaVisibleModel,
      showMobileCursor,
    } = this.props;
    const {
      properties: { tooltips },
    } = comp;
    const compChildren =
      renderSubComponents && comp.isContainer
        ? renderSubComponents(comp as UIContainerComponent, scale || 1, noBoundary)
        : children;
    const compRenderContent = BasicComponentLib.render(
      comp,
      {
        isPreview,
        mobileType,
        isStandalone,
        onInnerAction,
        compStyle: this.state.style,
        scale,
        globalScale,
        onForceUpdate,
        valueEditing,
        onValueEdited,
        onValueEditing,
        onTableValueChanged,
        onSnapshotValueChanged,
        isParentActivated,
        isContainerActiveContainer,
        isRevision,
        hover: this.state.hover || selecting,
        selectAndHovered: hoverWithSelected,
        selected,
        onlySelected,
        hotAreaVisibleModel,
        showMobileCursor,
        itemEvents: this.itemEvents,
        event: [
          CTable,
          CTree,
          CNavigationMenu,
          CHorizontalMenu,
          CVerticalMenu,
          CSelect,
          CAudio,
          CMultipleSelect,
          CCarouselChart,
          CCollapse,
        ].includes(comp.type) && {
          onClick,
          onDoubleClick,
          onContextMenu,
          onMouseDown,
          onMouseUp,
          onTouchEnd,
          onTouchStart,
          onMouseEnter,
          onMouseLeave,
          onFocus,
          onBlur,
          onInnerAction,
          onForceUpdate,
        },
        showInteract,
      },
      compChildren,
    );

    const tooltipsValue = isPreview && (!isStandalone || isStandalonePreview) ? tooltips?.value : undefined;

    if (tooltipsValue) {
      return (
        <Tooltip className="lib-comp-tooltips-layer" autoHide={false} text={tooltips?.value}>
          {compRenderContent}
        </Tooltip>
      );
    }
    return compRenderContent;
  }

  /**
   * 状态组件， 对组件设置了其他状态移动组件需显示原始状态
   */
  renderState() {
    const { comp, selected, isPreview, isParentActivated, isActivated, valueEditing, scale, children } = this.props;
    if (!selected || isPreview || !isParentActivated || isActivated) {
      return null;
    }
    const { position, currentStateID } = comp;
    if (!currentStateID || isPredefinedState(currentStateID) || valueEditing) {
      return null;
    }
    const data = {
      ...depthClone(comp.toJSON()),
      states: {},
      _currentState: undefined,
      opacity: 50,
      _id: `${comp.id}-state `,
    };
    const originalComp = makeUIComponent(data, comp.parent, comp.options);
    const { position: originalPosition, size } = originalComp;
    const style: React.CSSProperties = {
      transform: `translate(${originalPosition.x - position.x}px,${originalPosition.y - position.y}px)`,
      ...size,
      overflow: 'hidden',
    };
    return (
      <div style={style} className="component-state">
        {BasicComponentLib.render(originalComp, { scale }, children)}
      </div>
    );
  }

  stopPropagation = (e: React.MouseEvent | React.TouchEvent, event: EventTypes) => {
    const {
      comp: { interactions },
      isPreview,
    } = this.props;
    const hasActions = interactions[event] && interactions[event].actions.length > 0;
    if (hasActions && isPreview) {
      e.stopPropagation();
    }
  };

  render() {
    const { hover, hasError, style, showConnector } = this.state;
    if (hasError) {
      return (
        <div style={style} className="component-error-render" onMouseDown={this.handleMouseDown}>
          {`Error to render this component ${this.props.comp.type}`}
        </div>
      );
    }
    const {
      comp,
      isPreview,
      selectedByOtherWho,
      valueEditing,
      scale,
      forceHover,
      isActivated,
      onlySelected,
    } = this.props;
    const showCompondPath =
      isActivated && comp.type === CCompoundPath && comp.parent?.type !== CCompoundPath && !isPreview;
    // 被激活的组件不在此渲染, 渲染第一层复合路径容器
    if (isActivated && !showCompondPath) {
      return null;
    }

    const {
      _animation: animation,
      selected,
      type,
      size,
      remark,
      properties: { tooltips },
    } = comp;
    const _hasInteraction = isPreview ? comp.hasInteractionInPreview() : comp.hasInteraction();
    const hasRemark = !!remark;
    const isHovered: boolean = (!isPreview && (hover || this.props.selected || forceHover)) || false;
    let classNames = classnames(
      'component',
      { optimization: this.doCalculateOptimization() },
      { 'container-component': comp.isContainer },
      `component-${type}`,
      { 'next-comp': selected && !(animation && animation.name) },
      {
        'selected-by-others': selectedByOtherWho !== '',
        hidden: comp.hidden,
        preview: isPreview,
        disabled: isPreview && comp.disabled,
        'hover-by-find-target': this.props.targetHovered,
        'has-interaction': isPreview && _hasInteraction,
        'richtext-editing': valueEditing,
        cube: isPreview && animation,
      },
    );
    if (animation && animation.name) {
      classNames = classnames(`${classNames}`, selected ? 'next-comp' : 'current-comp', {
        [`${animation && animation.name}`]: animation,
      });
    }
    if (isPreview && comp.isGroup) {
      classNames = classnames(classNames, { group: !tooltips?.value });
    }

    // @ts-ignore
    const bringFront = isPreview && comp.toJSON()._bringFront;
    const visibleSelecting = comp.isTable ? !onlySelected : true;
    return (
      <div
        ref={this.dom}
        data-alias={comp.alias}
        id={comp.id}
        className={classNames}
        style={style}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
        onClick={this.handleClick}
        onDoubleClick={this.handleDoubleClick}
        onMouseLeave={this.handleMouseLeave}
        onMouseEnter={this.handleMouseEnter}
        onContextMenu={this.handleContextMenu}
        onMouseOver={this.handleMouseOver}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      >
        {this.renderState()}
        <CacheComponentWrapper
          shouldUpdate={() => {
            // 计算流程的的坐标,需要更新子组件
            return (
              !comp.isMoving || [CConnector, CTable].includes(comp.type) || [CStickNote].includes(comp.lib?.type || '')
            );
          }}
          renderUI={() => {
            // 使用匿名函数会使缓存组件pureComponent失去作用， 因此不能使用pureComponent
            return (
              <>
                {this.renderContent()}
                {selectedByOtherWho && <span className="selected-by-who">{selectedByOtherWho}</span>}
                {this.renderFlag(comp, hasRemark, _hasInteraction)}
                {isPreview && !bringFront && _hasInteraction && !comp.disabled && <div className="hot-area" />}
                {!isPreview && !valueEditing && comp.canConnect && this.context.mode === 'flow' && (
                  <Connector
                    id={comp.id}
                    size={size}
                    scale={scale}
                    isShowConnector={showConnector}
                    isActiveParent={this.props.isActiveParent}
                    onMouseDownStartConnect={this.props.onMouseDownStartConnect}
                    onMouseUpEndConnect={this.props.onMouseUpEndConnect}
                    onControlMouseEnter={this.props.onControlMouseEnter}
                    onControlMouseLeave={this.props.onControlMouseLeave}
                  />
                )}
              </>
            );
          }}
        />
        {/* SelectingComponent  组件选中时或鼠标经过时显示边框 */}
        {!isPreview && visibleSelecting && ![CConnector, CLine].includes(comp.type) && !this.props.targetHovered && (
          <SelectingComponent
            ref={this.selectingCompDom}
            type={type}
            targetHovered={this.props.targetHovered}
            size={size}
            componentIsHovered={isHovered}
            comp={comp}
            isPreview={isPreview}
            valueEditing={valueEditing}
            forceHover={forceHover}
            key={comp.id}
            getIsCompSelected={() => this.props.editor?.selectedComponents?.has(comp)}
          />
        )}
      </div>
    );
  }

  renderFlag(comp: UIComponent, hasRemark: boolean, hasInteraction: boolean) {
    const { isPreview, isRevision, showRemarkTag, showInteract, editor } = this.props;
    const needShow = this.needShow;
    if (!needShow) {
      return null;
    }
    const isInCompoundPath = comp.parent?.type === CCompoundPath;
    // 22年4月份需求-编辑器要显示序号
    const needShowRemark = hasRemark && !isRevision && showRemarkTag;
    const needShowLocked = !isPreview && comp.locked;
    const needShowInteraction =
      !editor?.isAdvancedEditor &&
      !isPreview &&
      hasInteraction &&
      !isInCompoundPath &&
      !comp.libData?.isList &&
      false !== showInteract;
    if (!needShowLocked && !needShowRemark && !needShowInteraction) {
      return null;
    }
    return (
      <>
        <div className={classnames('flags')}>
          {needShowLocked && this.renderAdditionFlag('lock')}
          {/* 高级编辑中去掉红色闪电 */}
          {needShowInteraction && this.renderAdditionFlag('interaction')}
        </div>
        <div className={classnames('flags')} style={{ transform: `rotate(${-comp.rotate % 360}deg)` }}>
          {needShowRemark && this.renderRemarkOrderNum(comp)}
        </div>
      </>
    );
  }

  // 渲染交互标识样式
  renderAdditionFlag = (cls: string) => {
    // const { comp } = this.props;
    return <i className={classnames('flag-item', `${cls}-flag`)} />;
  };

  // 渲染备注序号样式
  renderRemarkOrderNum = (comp: UIComponent) => {
    // 手机模式下与idoc预览模式下不显示
    if (this.props.isStandalone || isMobileDevice()) {
      return null;
    }

    const instance = RefactorRemarkManager.getInstance(comp.ownerArtboard.doc);

    return (
      <RemarkOrderNum
        ref={this.remarkTagRef}
        instance={instance}
        comp={comp}
        editor={this.props.editor}
        selectedTag={this.props.selectedTag}
        showPreviewRemarkPopupId={this.props.showPreviewRemarkPopupId}
        onClick={this.handleRemarkTagClick.bind(this, true, comp)}
        onMouseDown={this.handleRemarkTagMouseDown}
      />
    );
  };
}

function parserStyle(props: IComponentProps): React.CSSProperties {
  const { comp, isPreview, index, isParentActivated, scale, valueEditing } = props;
  const {
    _scale,
    _animation: animation,
    properties: { outline },
  } = comp;
  let componentScale: {
    x: number;
    y: number;
  } = scale
    ? {
        x: scale,
        y: scale,
      }
    : {
        x: 1,
        y: 1,
      };

  if (isPreview && !isUndefined(_scale)) {
    componentScale = {
      x: componentScale.x * _scale.x,
      y: componentScale.y * _scale.y,
    };
  }
  const style = comp.getWrapperStyle(
    {
      x: props.offsetX || 0,
      y: props.offsetY || 0,
    },
    componentScale,
    scale || 1,
    comp.isResizing, //comp.isMoving || comp.isResizing,
  );
  // 父容器未被激活，不响应鼠标事件；‘组’的交互由他的子触发
  if (!isPreview && !isParentActivated /* || (isPreview && isGroup) */) {
    const canResponseToEvent =
      props.controllerKey?.ctrlKey && !props.comp.isInSuchParent((parent) => parent.isSealed || parent.isSymbol);
    style.pointerEvents = canResponseToEvent ? 'unset' : 'none';
  }
  style.transition = comp.getTransition();

  if (animation) {
    style.animationIterationCount = animation.animationIterationCount;
    // 内容面板切换keyfram动画
    style.animationTimingFunction = animation.timing;
    style.animationDuration = `${animation.duration || 1}ms`;
    // style.animationName = animation.animationName;
    style.animationFillMode = `${animation.name ? 'forwards' : ''}`;
  }

  if (isPreview) {
    if (comp.hidden) {
      style.opacity = 0;
    }
  }
  style.zIndex = MathUtils.value(comp.dynamicInfo?.zIndex, index);
  if (comp.type === CTable && valueEditing && !isPreview) {
    style.zIndex = comp.parent!.components.length + 1;
  }
  if (comp.isConnector) {
    style.width = 0;
    style.height = 0;
  }
  if (outline) {
    style.outline = `${2 / (appOptions.scaleValue || 1)}px solid rgba(0,157,255,1)`;
  }

  return style;
}
