import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';
import { appMiddleware } from './app/middleware';

export const zipStore = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, appMiddleware)));
