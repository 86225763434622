import * as React from 'react';
import classNames from 'classnames';
import { isNumber, once } from 'lodash';

import { PureColor, rgba2hex } from '@/utils/graphicsUtils';
import { convertEventToHotKey } from '@/utils/hotkeysUtils';
import { dragDelegate } from '@/utils/mouseUtils';
import { max, min, isInputting } from '@/utils/globalUtils';

import { MouseButton } from '@/consts/enums/mouseButton';

import IFill, { FillType } from '@/fbs/rp/models/properties/fill';
import { IChartValue, IMapChartValue } from '@fbs/rp/models/chart';
import { IBounds, IPosition } from '@/fbs/common/models/common';

import CoreEditor from '@/editor/core';
import EditorContext from '@contexts/editor';
import { UIComponent } from '@/editor/comps';

import { ISelectArea } from '@helpers/tableHelper';
import { getParentDomByFilter } from '@/helpers/documentHelper';

import {
  IAutoCloseComponentProps,
  IAutoCloseProps,
  Input,
  InputNumber,
  ScrollBars,
  withAutoClose,
  FloatPanel,
} from '@/dsm';

import { ChartUtils } from './utils';
import ChartMenu from './menu';
import updatePreventClose, { InjectedUpdatePreventCloseProps } from './updatePreventClose';
import ReduxColorPicker from '../../../../components/Common/ReduxColorPicker';
import { DefaultChartColorList } from '../../../ChartComponents/common';

import './index.scss';

export interface IChartBaseProps extends IAutoCloseComponentProps {
  component: UIComponent;
  canAddRow: boolean;
  canAddColumn: boolean;
  coreEditor: CoreEditor;
  bounds: IBounds;
  // 是否能输入负数
  canInputNegative: boolean;
  onClose: () => void;
}

export interface IChartProps extends IChartBaseProps, InjectedUpdatePreventCloseProps {}

interface ITableCellData {
  text: string;
}

interface IChartState {
  // 是否编辑中
  editing: boolean;
  // 是否横向
  isHorizontal: boolean;
  position: {
    left: number;
    top: number;
  };
  menuPosition?: IPosition;
  selectedArea?: ISelectArea;
}

export const CellWidth = 80;
const CellHeight = 30;
const ColorPickerWidth = 10;
const MaxRowCount = 10.5;
const MaxColumnCount = 7.5;
const ButtonSize = 38;
const BorderWidth = 1;

export class ChartEditor extends React.Component<IChartProps, IChartState> {
  private tableRef: React.RefObject<HTMLTableElement> = React.createRef();
  private scrollBarRef: React.RefObject<ScrollBars> = React.createRef();
  private InputRef: React.RefObject<Input> = React.createRef();
  private InputNumberRef: React.RefObject<InputNumber> = React.createRef();
  private divRef: React.RefObject<HTMLDivElement> = React.createRef();

  static contextType = EditorContext;
  // @ts-ignore
  context: React.ContextType<typeof EditorContext>;

  private isShift = false;
  private startCell?: { row: number; column: number };
  protected afterUpdateFn?: () => void;
  // 颜色选择弹窗是否展开
  private colorPickPopup = false;

  constructor(props: IChartProps) {
    super(props);
    const {
      bounds: { left, top },
    } = props;
    this.state = {
      editing: false,
      isHorizontal: true,
      selectedArea: undefined,
      position: { left, top },
    };
  }

  private get inputDom(): InputNumber | Input | null {
    return this.InputNumberRef.current ?? this.InputRef.current;
  }

  public componentDidUpdate() {
    this.afterUpdateFn && this.afterUpdateFn();
  }

  public componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDownCapture, { capture: true });
    window.addEventListener('keyup', this.handleKeyUpCapture, { capture: true });
    window.addEventListener('mouseup', this.handleWindowMouseUp, { capture: true });
    this.divRef.current?.addEventListener('wheel', this.handleDivScroll);
  }

  public componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDownCapture, { capture: true });
    window.removeEventListener('keyup', this.handleKeyUpCapture, { capture: true });
    window.removeEventListener('mouseup', this.handleWindowMouseUp, { capture: true });
    this.divRef.current?.removeEventListener('wheel', this.handleDivScroll);
  }

  private handleDivScroll = (e: Event) => {
    e.stopPropagation();
  };

  private handleWindowMouseUp = () => {
    this.startCell = undefined;
    if (!this.colorPickPopup) {
      this.props.onMouseUp();
    }
  };

  protected getTableDataSource(): {
    value: number | undefined;
    rowIndex: number;
    columnIndex: number;
  }[][] {
    const { component } = this.props;
    const { dataSource, xAxis } = component.value as IChartValue;
    const { isHorizontal } = this.state;
    const result: {
      value: number | undefined;
      rowIndex: number;
      columnIndex: number;
    }[][] = [];

    const columnCount = xAxis.length;
    const rowCount = dataSource.length;
    if (isHorizontal) {
      dataSource.forEach((item, i) => {
        const { data } = item;
        const rowData = new Array(columnCount).fill(0).map((v, j) => {
          return {
            value: data[j],
            rowIndex: i + 1,
            columnIndex: j + 1,
          };
        });

        result.push(rowData);
      });
    } else {
      xAxis.forEach((v, i) => {
        const rowData = new Array(rowCount).fill(0).map((v, j) => {
          return {
            value: dataSource[j]?.data[i],
            rowIndex: j + 1,
            columnIndex: i + 1,
          };
        });
        result.push(rowData);
      });
    }
    return result;
  }

  checkIsValidData = (row: number, col: number, data: any) => {
    // 如果是首行或者首列，最多填充100
    if (!row || !col) {
      return Number.isNaN(Number(data)) ? data.substring(0, 100) : data;
    }
    const tempData: number = parseFloat(data);
    if (Number.isNaN(tempData)) {
      return 0;
    }
    if (!this.props.canInputNegative && tempData < 0) {
      return 0;
    } else if (tempData > Number.MAX_SAFE_INTEGER) {
      return Number.MAX_SAFE_INTEGER;
    } else if (tempData < Number.MIN_SAFE_INTEGER) {
      return Number.MIN_SAFE_INTEGER;
    }
    return Number(tempData.toFixed(2));
  };

  handleHotKeyCopy = () => {
    // if (this.state.selectedArea) {
    //   const { start, end } = this.state.selectedArea;
    //   const {
    //     component: { value },
    //   } = this.props;
    //   const { dataSource, xAxis } = value as IChartValue;
    //   const startRow = start.row;
    //   const startCol = start.column;
    //   const row = end.row - startRow;
    //   const col = end.column - startCol;
    //   const boardData = new Array(row + 1).fill(0).map(() => new Array(col + 1).fill(0));
    //   const boardDataTable = document.createElement('table');
    //   const boardDataThead = document.createElement('thead');
    //   const boardDataTbody = document.createElement('tbody');
    //   for (let i = 0; i <= row; i++) {
    //     const tr = document.createElement('tr');
    //     const defaultRow = startRow + i;
    //     for (let j = 0; j <= col; j++) {
    //       const td = document.createElement('td');
    //       let value: string | number = '';
    //       const defaultCol = startCol + j;
    //       if (!defaultRow && !defaultCol) {
    //         value = '';
    //       } else if (defaultRow && defaultCol) {
    //         value = dataSource[defaultRow - 1].data[defaultCol - 1]!;
    //       } else if (!defaultRow) {
    //         value = xAxis[defaultCol === 1 ? 0 : defaultCol - 1];
    //       } else {
    //         value = dataSource[defaultRow - 1].name;
    //       }
    //       td.innerText = String(value);
    //       tr.appendChild(td);
    //       boardData[i][j] = { text: value };
    //     }
    //     if (defaultRow) {
    //       boardDataTbody.appendChild(tr);
    //     } else {
    //       boardDataThead.appendChild(tr);
    //     }
    //   }
    //   boardDataTable.appendChild(boardDataThead);
    //   boardDataTable.appendChild(boardDataTbody);
    //   MockClipboard.clipboard?.writeAsync(boardDataTable.outerHTML, 'html');
    // }
  };

  handleHotKeyPaste = async () => {
    if (this.state.selectedArea) {
      const {
        canAddRow,
        canAddColumn,
        component: { value, type },
      } = this.props;
      const { start } = this.state.selectedArea;
      const row = start.row;
      const column = start.column;
      let tableCells: ITableCellData[][] = [];
      const [ClipboardItem] = await navigator.clipboard.read();
      const result = ClipboardItem.types.includes('text/html')
        ? ClipboardItem.getType('text/html').then((res) => res.text())
        : ClipboardItem.getType('text/plain').then((res) => res.text());
      result.then((res: string) => {
        const _document: Document = new DOMParser().parseFromString(res, 'text/html');
        const table: HTMLTableElement = _document.querySelector('table')!;
        if (table) {
          // 处理表格
          const trs = table.querySelectorAll('tr');
          tableCells = Array.from(new Array(trs.length), () => []);
          for (let r = 0, rlen = trs.length; r < rlen; r++) {
            const tr = trs[r];
            let currentCells = tableCells[r]; // 当前行单元格
            let cellIndex = 0;
            for (let c = 0, clen = tr.cells.length; c < clen; c++) {
              // 如果不为空，代表被填充，就继续往下填充
              while (currentCells[cellIndex]) {
                cellIndex++;
              }
              const cell = tr.cells[c];
              let rowSpan = cell.rowSpan,
                colSpan = cell.colSpan;
              let cellData = { text: cell.innerText };
              currentCells[cellIndex] = cellData;
              for (let r2 = 1, mergeRowIndex = r; r2 <= rowSpan; r2++, mergeRowIndex++) {
                for (let c2 = mergeRowIndex === r ? 1 : 0; c2 < colSpan; c2++) {
                  let mergeData = { text: cellData.text };
                  tableCells[mergeRowIndex][cellIndex + c2] = mergeData;
                }
              }
              cellIndex += colSpan;
            }
          }
        } else {
          // 解析html字符串
          const list = res.replace(/<[^>]*>(([^<])*)/gi, (a, b) => b).split('\r\n');
          for (let i = 0; i < list.length; i++) {
            tableCells[i] = [{ text: list[i] }];
          }
        }
        if (tableCells.length) {
          const { dataSource } = value as IChartValue;
          const tableData = this.getTableDataSource();
          const addRow = tableCells.length - (dataSource.length - (row - 1));
          const addCol = tableCells[0].length - (tableData[0].length - (column - 1));
          let newValue = value;
          if (addRow > 0 && canAddRow) {
            newValue = ChartUtils.insertRows(type, newValue as IChartValue, -1, addRow);
          }
          if (addCol > 0 && canAddColumn) {
            newValue = ChartUtils.insertColumns(type, newValue as IChartValue, -1, addCol);
          }
          // 如果复制数据（行，列），列超过原始数据（行，列）则以复制数据（行，列）为标准基数赋值
          const rowLength = tableCells.length;
          const colLength = tableCells[0].length;
          for (let i = 0; i < rowLength; i++) {
            for (let j = 0; j < colLength; j++) {
              // 此处如果原始数据与改动数据一样，取上一次得数据
              newValue =
                ChartUtils.changeValue(
                  newValue as IChartValue,
                  i + row,
                  j + column,
                  this.checkIsValidData(i + row, j + column, tableCells[i][j].text),
                ) || newValue;
            }
          }
          this.handleSubmit(newValue as IChartValue);
        }
      });
    }
  };

  protected isSelected(row: number, column: number): boolean {
    if (!row && !column) {
      return false;
    }
    const { selectedArea } = this.state;
    if (!selectedArea) {
      return false;
    }
    const { start, end } = selectedArea;
    return row >= start.row && row <= end.row && column >= start.column && column <= end.column;
  }

  protected isFirstSelected(row: number, column: number): boolean {
    const { selectedArea } = this.state;
    if (!selectedArea) {
      return false;
    }
    const { start } = selectedArea;
    return row === start.row && column == start.column;
  }

  protected getDeleteRowDisabled() {
    return false;
  }

  protected getCleanContentDisabled() {
    return false;
  }

  private handleColorPickerMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  private handleInputFocus = () => {
    // 不用timeout焦点异常丢失
    setTimeout(() => {
      const dom = this.inputDom;
      dom?.focus();
      dom?.select();
    });
  };

  private handleColorPickerFocus = () => {
    this.colorPickPopup = true;
  };

  private handleColorPickerBlur = () => {
    this.colorPickPopup = false;
    this.handleInputFocus();
  };

  private handleInputBlur = () => {
    setTimeout(() => {
      const dom = this.inputDom;
      dom?.blur();
    });
  };

  // 区域选中
  private setSelectedArea(area: ISelectArea | undefined, fn?: () => void) {
    const callback = () => {
      if (area) {
        this.handleInputFocus();
      } else {
        this.handleInputBlur();
      }
      fn && fn();
    };

    // 区域选中，设置当前选中单元格中输入框focus
    setTimeout(() => {
      this.inputDom?.focus();
    });

    this.setState({ selectedArea: area }, callback);
  }

  private handleTdDown(row: number, column: number, e: React.MouseEvent) {
    // 空行进行拖拽，并将之前选中全部清除
    if (row === 0 && column === 0) {
      this.handleMouseDown();
      this.setSelectedArea(undefined);
      document.getSelection()?.removeAllRanges();
      return;
    }
    const { editing, selectedArea } = this.state;
    if (editing) {
      this.setState({ editing: false });
      // return;
    }
    if (e.button === MouseButton.Right && this.isSelected(row, column)) {
      return;
    }

    this.startCell = { row, column };

    if (this.isShift && selectedArea) {
      this.handleShiftSelect(row, column);
      return;
    }

    this.setSelectedArea({ start: { row, column }, end: { row, column } });
  }

  private handleShiftSelect = (row: number, column: number) => {
    const { selectedArea } = this.state;
    if (!selectedArea) {
      return;
    }
    const { start, end } = selectedArea;
    const area = {
      start: { row: min(start.row, end.row, row), column: min(start.column, end.column, column) },
      end: { row: max(start.row, end.row, row), column: max(start.column, end.column, column) },
    };

    this.setSelectedArea(area);
  };

  private handleTdEnter(row: number, column: number) {
    if (!this.startCell) {
      return;
    }

    if (this.isShift && this.state.selectedArea) {
      this.handleShiftSelect(row, column);
      return;
    }
    const { row: r1, column: c1 } = this.startCell;

    const selectedArea = {
      start: { row: Math.min(r1, row), column: Math.min(c1, column) },
      end: { row: Math.max(r1, row), column: Math.max(c1, column) },
    };

    this.setSelectedArea(selectedArea);
  }

  // 设置光标位置处于最后边
  private setCursorPosition = () => {
    const inputEle = this.inputDom?.dom.current;
    if (inputEle) {
      const oldType = inputEle.type;
      const value = inputEle.value;
      inputEle.type = 'text';
      requestAnimationFrame(() => {
        inputEle.setSelectionRange(value.length, value.length);
        inputEle.type = oldType;
      });
    }
  };

  private handleTdDoubleClick = (e: React.MouseEvent) => {
    if (!this.divRef.current?.contains(e.target as HTMLElement)) {
      return;
    }
    this.setCursorPosition();
    this.setState({ editing: true });
  };

  private handleType = () => {
    const { editing } = this.state;
    if (editing) {
      return;
    }
    this.setState({ editing: true });
  };

  private selectedNextCell = (cmd: 'arrowleft' | 'arrowup' | 'arrowright' | 'arrowdown') => {
    const {
      component: { value },
    } = this.props;
    const { xAxis, dataSource } = value as IChartValue;
    const xCount = xAxis.length;
    const yCount = dataSource.length;
    let { row, column } = this.state.selectedArea?.start ?? { row: 0, column: 0 };

    switch (cmd) {
      case 'arrowleft': {
        column--;
        if (column < 0) {
          row--;
          column = xCount;
        }
        if (row < 0) {
          row = yCount;
        }
        break;
      }
      case 'arrowup': {
        row--;
        if (row < 0) {
          column--;
          row = yCount;
        }
        if (column < 0) {
          column = xCount;
        }
        break;
      }
      case 'arrowright': {
        column++;
        if (column > xCount) {
          row++;
          column = 0;
        }
        if (row > yCount) {
          row = 0;
        }
        break;
      }
      case 'arrowdown': {
        row++;
        if (row > yCount) {
          column++;
          row = 0;
        }
        if (column > xCount) {
          column = 0;
        }
        break;
      }
      default:
        break;
    }

    this.setSelectedArea({ start: { row, column }, end: { row, column } }, this.doScrollToView);
  };

  private doScrollToView = () => {
    const editorDom = this.props.forwardedRef?.current as HTMLDivElement | undefined;
    if (!editorDom) {
      return;
    }

    const cellDom = editorDom.querySelector('.isFirstSelected') ?? editorDom.querySelector('.isEmpty');
    if (!cellDom) {
      return;
    }

    const {
      left: editorLeft,
      top: editorTop,
      bottom: editorBottom,
      right: editorRight,
    } = editorDom.getBoundingClientRect();

    const { left: cellLeft, top: cellTop, bottom: cellBottom, right: cellRight } = cellDom.getBoundingClientRect();

    if (cellTop < editorTop) {
      return this.handleScroll({ x: 0, y: cellTop - editorTop });
    }
    if (cellBottom > editorBottom) {
      return this.handleScroll({ x: 0, y: cellBottom - editorBottom });
    }
    if (editorLeft > cellLeft) {
      return this.handleScroll({ x: cellLeft - editorLeft, y: 0 });
    }
    if (editorRight < cellRight) {
      return this.handleScroll({ x: cellRight - editorRight, y: 0 });
    }
  };

  private handleScroll = (offset: { x: number; y: number }) => {
    const tableDom = this.tableRef.current;
    if (!tableDom) {
      return;
    }
    const scrollDom = getParentDomByFilter(
      tableDom,
      (e: HTMLElement) => !!e.parentElement?.classList.contains('dsm-c-rp-scrollbars'),
    );

    if (!scrollDom) {
      return;
    }

    const position = {
      left: scrollDom.scrollLeft + offset.x,
      top: scrollDom.scrollTop + offset.y,
    };
    scrollDom.scroll(position);
  };

  // 清除内容
  private handleCleanContent = () => {
    const chartValue = this.props.component.value as IChartValue;
    const { selectedArea } = this.state;
    if (!selectedArea) {
      return;
    }
    const newValue = ChartUtils.clearContent(chartValue, selectedArea);
    this.handleSubmit(newValue);
    this.handleInputFocus();
  };

  // 全选
  private handleSelectedAll() {
    const { xAxis, dataSource } = this.props.component.value as IChartValue;
    this.setSelectedArea({
      start: {
        column: 0,
        row: 0,
      },
      end: {
        column: xAxis.length,
        row: dataSource.length,
      },
    });
  }

  /**
   * 快捷键
   */
  private handleKeyDownCapture = (e: KeyboardEvent) => {
    const isInner = this.divRef.current?.contains(e.target as HTMLElement);
    if ((!isInner && !this.state.selectedArea) || (!isInner && isInputting())) {
      return;
    }
    this.isShift = e.shiftKey;
    const hotkey = convertEventToHotKey(e);
    const { editing } = this.state;
    switch (hotkey) {
      case 'tab': {
        e.stopPropagation();
        e.preventDefault();
        this.selectedNextCell('arrowright');
        break;
      }
      case 'arrowleft':
      case 'arrowup':
      case 'arrowright':
      case 'arrowdown': {
        if (editing) {
          break;
        }
        e.stopPropagation();
        e.preventDefault();
        this.selectedNextCell(hotkey);
        break;
      }
      case 'enter': {
        if (editing) {
          break;
        }
        e.stopPropagation();
        this.handleType();
        this.setCursorPosition();
        break;
      }

      case 'backspace':
      case 'delete':
        if (!editing) {
          this.handleCleanContent();
        }
        break;
      // FIXME: 若果要添加复制粘贴的功能，注意不要影响输入框和颜色面板的复制粘贴
      // case 'ctrl+a':
      //   if (editing) {
      //     break;
      //   }
      //   this.handleSelectedAll();
      //   break;
      // case 'ctrl+c':
      //   e.preventDefault();
      //   this.handleHotKeyCopy();
      //   break;
      case 'ctrl+v':
        e.preventDefault();
        this.handleHotKeyPaste();
        break;
      case 'escape':
        if (!editing) {
          e.preventDefault();
          this.props.onClose();
        }
        break;
      case ' ':
        if (!editing) {
          e.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  /**
   * 快捷键
   */
  private handleKeyUpCapture = (e: KeyboardEvent) => {
    this.isShift = e.shiftKey;
  };

  private handleMenuClose = () => {
    if (this.state.menuPosition) {
      this.setState({ menuPosition: undefined });
    }
  };

  private handleContextMenu = (e: React.MouseEvent) => {
    const { selectedArea, menuPosition } = this.state;
    if (selectedArea && selectedArea.start.column > -1 && !menuPosition) {
      this.setState({
        menuPosition: {
          x: e.pageX,
          y: e.pageY,
        },
      });
    } else {
      this.setState({ menuPosition: undefined });
    }
  };

  // 内容修改
  private handleValueChange = (
    row: number,
    column: number,
    oldValue: string | number | undefined,
    value: string | number,
  ) => {
    //没有修改，不需要提交，以免错误在失去焦点时候覆盖右键菜单的提交操作
    if (oldValue === value) {
      return;
    }
    const { component } = this.props;
    const newValue = ChartUtils.changeValue(component.value as IChartValue, row, column, value);
    this.handleSubmit(newValue);
  };

  // 处理inputNumber输入空值
  private handleEmptyValue = (row: number, column: number) => {
    const { component } = this.props;
    const newValue = ChartUtils.changeValue(component.value as IChartValue, row, column, undefined);
    this.handleSubmit(newValue);
  };

  // 修改颜色
  protected handleColorChange(row: number, fill: IFill) {
    const rgba = fill.color as PureColor;
    const value = this.props.component.value as IChartValue;
    const newValue = ChartUtils.changeColor(value, row, rgba);
    this.handleSubmit(newValue);
  }

  /**
   * 输入框enter退出输入
   */
  private handleInputEnter = () => {
    this.setState({ editing: false }, () => {
      this.handleInputFocus();
    });
  };

  /**
   * 输入框enter退出输入
   */
  private handleInputMousedown = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.onMouseDown();
  };

  protected handleScrollTo = (direction: 'left' | 'top' | 'bottom' | 'right') => {
    const scrollbar = this.scrollBarRef.current;
    if (!scrollbar) {
      return;
    }
    const fnMap = {
      left: scrollbar.scrollToLeft,
      right: scrollbar.scrollToRight,
      top: scrollbar.scrollToTop,
      bottom: scrollbar.scrollToBottom,
    };
    fnMap[direction] && fnMap[direction]();
  };

  protected handleSubmit = (value?: IChartValue | IMapChartValue) => {
    if (!value) {
      return;
    }
    const { coreEditor, component } = this.props;
    coreEditor.setValue(component.type, value, [component]);
  };

  // 拖拽
  handleMouseDown = () => {
    const panelDom = this.props.forwardedRef?.current;
    if (!panelDom) return;
    const { canAddColumn, canAddRow } = this.props;
    const maxLeft = window.innerWidth - panelDom!.clientWidth - (canAddColumn ? ButtonSize : 0);
    const maxTop = window.innerHeight - panelDom!.clientHeight - (canAddRow ? ButtonSize : 0); // 加上添加按钮的高度
    const { position } = this.state;
    const startPoint = { left: position.left, top: position.top };
    const onMoving = (e: MouseEvent, delta: { x: number; y: number }) => {
      if (window.getSelection) window.getSelection()?.removeAllRanges(); // 防止双击选中文本
      const newPosition = {
        left: startPoint.left + delta.x,
        top: startPoint.top + delta.y,
      };
      if (newPosition.left < 0) {
        newPosition.left = 0;
      } else if (newPosition.left > maxLeft) {
        newPosition.left = maxLeft;
      }
      if (newPosition.top < 0) {
        newPosition.top = 0;
      } else if (newPosition.top > maxTop) {
        newPosition.top = maxTop;
      }
      this.setState({ position: newPosition });
    };
    const onMoveEnd = () => {};

    dragDelegate(onMoving, onMoveEnd);
    this.props.onMouseDown();
  };

  // 清除选中
  private clearSelectedArea = () => {
    this.setSelectedArea(undefined);
  };

  // 列添加
  private handleColumnAdd = () => {
    const {
      component: { value, type },
    } = this.props;
    const newValue = ChartUtils.insertColumns(type, value as IChartValue, -1, 1);
    this.handleSubmit(newValue);
    this.afterUpdateFn = once(() => {
      this.handleScrollTo('top');
      this.handleScrollTo('right');
    });
  };

  // 行添加
  protected handleRowAdd = () => {
    const {
      component: { value, type },
    } = this.props;
    const newValue = ChartUtils.insertRows(type, value as IChartValue, -1, 1);
    this.handleSubmit(newValue);
    this.afterUpdateFn = once(() => {
      this.handleScrollTo('left');
      this.handleScrollTo('bottom');
    });
  };

  /**
   * 计算表格的宽高及最大宽高
   */
  protected calcTableSize() {
    const { component } = this.props;
    const { dataSource, xAxis } = component.value as IChartValue;

    const width = xAxis.length * (CellWidth + BorderWidth) + (CellWidth + BorderWidth) + ColorPickerWidth;
    const height = (dataSource.length + 1) * CellHeight;
    const maxWidth = MaxColumnCount * (CellWidth + BorderWidth) + ColorPickerWidth + BorderWidth;
    const maxHeight = MaxRowCount * CellHeight + BorderWidth;
    return { width, height, maxWidth, maxHeight };
  }

  protected renderTd(
    children: JSX.Element | undefined,
    option: {
      row: number;
      column: number;
      isSelected: boolean;
      isHead: boolean;
      isEmpty?: boolean;
      isFirstSelected?: boolean;
      width?: number;
      colSpan?: number;
      rowSpan?: number;
    },
  ): JSX.Element {
    const { isSelected, isHead, row, column, width, isFirstSelected, isEmpty, colSpan, rowSpan } = option;
    return (
      <td
        style={{ width: width ?? CellWidth }}
        key={`${row}-${column}`}
        colSpan={colSpan}
        rowSpan={rowSpan}
        className={classNames('cell', { isSelected, isHead, isFirstSelected, isEmpty })}
        onMouseDown={this.handleTdDown.bind(this, row, column)}
        onMouseEnter={this.handleTdEnter.bind(this, row, column)}
        onDoubleClick={this.handleTdDoubleClick}
        onContextMenu={this.handleContextMenu}
      >
        {children}
      </td>
    );
  }

  protected renderXAxisHeader(name: string, row: number, column: number) {
    return this.renderTd(this.renderValueViewer(name, row, column, false), {
      isHead: true,
      isSelected: this.isSelected(row, column),
      row,
      column,
      isFirstSelected: this.isFirstSelected(row, column),
    });
  }

  // 可颜色修改
  private renderValueViewerWithColorPicker(value: string, color: PureColor, row: number, column: number) {
    return (
      <div className="color-cell">
        <div className="color" style={{ background: rgba2hex(color) }}>
          <ReduxColorPicker
            disabledGradient
            // width={24}
            color={{ type: FillType.solid, color }}
            onChange={this.handleColorChange.bind(this, row)}
            onBlur={this.handleColorPickerBlur}
            onMouseDown={this.handleColorPickerMouseDown}
            onFocus={this.handleColorPickerFocus}
          />
        </div>

        {this.renderValueViewer(value, row, column, false)}
      </div>
    );
  }

  // 行头
  protected renderYAxisHeader(name: string, color: PureColor, row: number, column: number) {
    return this.renderTd(this.renderValueViewerWithColorPicker(name, color, row, column), {
      isHead: true,
      isSelected: this.isSelected(row, column),
      row,
      column,
      width: CellWidth + ColorPickerWidth,
      isFirstSelected: this.isFirstSelected(row, column),
    });
  }

  // 数据单元格
  protected renderDataCell(value: number | undefined, row: number, column: number) {
    return this.renderTd(this.renderValueViewer(value, row, column, true), {
      isHead: false,
      isSelected: this.isSelected(row, column),
      row,
      column,
      isFirstSelected: this.isFirstSelected(row, column),
    });
  }

  // 表格内容
  protected renderContent() {
    const { component } = this.props;
    const { dataSource, xAxis } = component.value as IChartValue;
    const tableData = this.getTableDataSource();
    return (
      <>
        <thead>
          <tr className="chart-row">
            {this.renderTd(undefined, { isHead: true, row: 0, column: 0, isSelected: false, width: 90, isEmpty: true })}
            {xAxis.map((name, i) => this.renderXAxisHeader(name, 0, i + 1))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((rows, i) => {
            const { name, color } = dataSource[i];
            const rgba = color ?? DefaultChartColorList[i % DefaultChartColorList.length];
            return (
              <tr key={i} className="chart-row">
                {this.renderYAxisHeader(name, rgba, i + 1, 0)}
                {rows.map((item) => {
                  const { value, rowIndex, columnIndex } = item;
                  return this.renderDataCell(value, rowIndex, columnIndex);
                })}
              </tr>
            );
          })}
        </tbody>
      </>
    );
  }

  // 输入框
  private renderInput(isNum: boolean, value: string | number | undefined, row: number, column: number) {
    const { editing } = this.state;
    const { canInputNegative } = this.props;
    return (
      <div className={classNames('value-editor', { offscreen: !editing })}>
        {!isNum ? (
          <Input
            ref={this.InputRef}
            width="100%"
            autoFocus
            maxlength={100}
            value={value?.toString()}
            onTyped={this.handleType}
            onMouseDown={this.handleInputMousedown}
            onBlur={this.handleValueChange.bind(this, row, column, value?.toString())}
            onEnter={this.handleInputEnter}
          />
        ) : (
          <InputNumber
            ref={this.InputNumberRef}
            width="100%"
            autoFocus
            min={canInputNegative ? Number.MIN_SAFE_INTEGER : 0}
            decimalCount={2}
            value={isNumber(value) ? value : undefined}
            allowEmptyValue={true}
            onEmptyValue={this.handleEmptyValue.bind(this, row, column)}
            onTyped={this.handleType}
            onMouseDown={this.handleInputMousedown}
            onBlur={this.handleValueChange.bind(this, row, column, value?.toString())}
            onEnter={this.handleInputEnter}
          />
        )}
      </div>
    );
  }

  /**
   * 数据展示
   */
  protected renderValueViewer(value: number | string | undefined, row: number, column: number, isNum: boolean) {
    const selected = this.isFirstSelected(row, column);
    if (!selected) {
      return (
        <div className="text-cell">
          <span className="value-viewer">{value ?? ''}</span>
        </div>
      );
    }
    const { editing } = this.state;

    return (
      <>
        <div className="text-cell">
          {!editing && <span className="value-viewer">{value ?? ''}</span>}
          {this.renderInput(isNum, value, row, column)}
        </div>
      </>
    );
  }

  private renderButton() {
    const { canAddColumn, canAddRow } = this.props;
    return (
      <>
        {canAddColumn && (
          <div className="add-button right" onClick={this.handleColumnAdd}>
            +
          </div>
        )}
        {canAddRow && (
          <div className="add-button bottom" onClick={this.handleRowAdd}>
            +
          </div>
        )}
      </>
    );
  }

  render() {
    const { component, canAddColumn, canAddRow, onMouseDown } = this.props;
    const { position, menuPosition, selectedArea } = this.state;
    const { width, height, maxWidth, maxHeight } = this.calcTableSize();

    return (
      <>
        <FloatPanel style={position} onMouseDown={onMouseDown}>
          <div ref={this.divRef} tabIndex={-1}>
            <div
              ref={this.props.forwardedRef}
              className="chart-editor"
              style={{
                width: width + BorderWidth,
                height: height + BorderWidth,
                ...position,
                maxWidth,
                maxHeight,
              }}
            >
              <ScrollBars ref={this.scrollBarRef}>
                <table
                  ref={this.tableRef}
                  style={{ width, height }}
                  className="chart-table"
                  cellPadding="0"
                  cellSpacing="0"
                >
                  {this.renderContent()}
                </table>
              </ScrollBars>
              {this.renderButton()}
            </div>
          </div>
        </FloatPanel>
        {menuPosition && selectedArea && (
          <ChartMenu
            type={component.type}
            chartValue={component.value as IChartValue}
            selectArea={selectedArea}
            position={menuPosition}
            canAddRow={canAddRow}
            canAddColumn={canAddColumn}
            canCleanContent={this.getCleanContentDisabled()}
            deleteRowDisabled={this.getDeleteRowDisabled()}
            onInputFocus={this.handleInputFocus}
            onSelectedAreaClear={this.clearSelectedArea}
            onMenuClose={this.handleMenuClose}
            onSubmit={this.handleSubmit}
          />
        )}
      </>
    );
  }
}

// export default withAutoClose<IChartProps>(ChartEditor);
const PanelClass: React.ComponentClass<IChartProps & IAutoCloseProps> = withAutoClose<IChartProps>(ChartEditor);

export default updatePreventClose<IChartBaseProps & IAutoCloseProps>(PanelClass);
