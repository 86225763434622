import * as assert from 'assert';

import { depthClone, isNotEqual0, sameNumber, moveArrayItem, roundNumberObject } from '@utils/globalUtils';
import { IComponentData } from '@fbs/rp/models/component';
import { ArtboardPatches, Operation, Ops, PagePatches } from '@fbs/rp/utils/patch';
import { IBoundsOffset } from '@fbs/common/models/common';
import ILayout, { LayoutPropertyName } from '@fbs/rp/models/properties/layout';
import { IBasicLayout } from '@fbs/rp/models/layout';
import {
  ComponentChange,
  ComponentChangeType,
  ComponentResizeResult,
  ContainerPatches,
  ResizeOptions,
  updateEditComponentsPatches,
} from '@editor/comps/resizeHelper';
import { PropertyValue } from '@fbs/rp/models/property';
import ICell, { CellPropertyName } from '@fbs/rp/models/properties/cell';
import IPadding, { PaddingPropertyName } from '@fbs/rp/models/properties/padding';
import { ArtboardPatchesClass } from '@editor/patches/artboardPatches';
import { ComponentPatchesClass } from '@editor/patches/ComponentPatches';

import { getCenter, getNWPoint } from '@helpers/rotateHelper';
import { coverPatches } from '@helpers/patchHelper';
import { IUICompConstructOptions } from '@/customTypes';

import { UIComponent, UIContainerComponent, UIStackPanelComponent } from '.';

/**
 * 列表式布局复合组件容器
 */
export default class UIListLayoutSealedComponent extends UIStackPanelComponent {
  constructor(data: IComponentData, public parent?: UIContainerComponent, public options?: IUICompConstructOptions) {
    super(data, parent, options);
    if (options?.isPreview) {
      const { layout } = this.properties;
      let x = 0;
      let y = 0;
      const direction = layout ? layout.direction || 'vertical' : 'vertical';
      const verticalGap = layout?.verticalGap || 0;
      const horizontalGap = layout?.horizontalGap || 0;
      this.components.forEach((comp) => {
        if (direction === 'vertical') {
          comp.toJSON().position.x = 0;
          comp.toJSON().position.y = y;
          y += comp.toJSON().size.height + verticalGap;
        } else {
          comp.toJSON().position.y = 0;
          comp.toJSON().position.x = x;
          x += comp.toJSON().size.width + horizontalGap;
        }
      });
    }
  }

  doAfterCreate() {
    // 这里处理列表类组件的子的布局计算
    // reArrangeComponentOfListLayoutPanel(this.data);
  }

  /**
   *
   * @param {IBoundsOffset} offset
   * @param {IBasicLayout} layout
   * @param {ResizeOptions} options
   * @param backupAllCompsLayout
   * @return {ComponentResizeResult}
   */
  resizeHandler2(
    offset: IBoundsOffset,
    layout: IBasicLayout,
    options: ResizeOptions,
    // eslint-disable-next-line no-unused-vars
    backupAllCompsLayout?: WeakMap<UIComponent, IBasicLayout>,
  ): ComponentResizeResult {
    const { position, size } = this;
    if (offset.left === 0 && offset.right === 0 && offset.top === 0 && offset.bottom === 0) {
      return {
        size,
        position,
        rotate: this.rotate,
      };
    }
    const info = super.resizeHandler2(offset, layout, options);
    const realOffset = this.getRealOffsetForChild(offset, info);
    // 如果容器没有发生 resize，子组件就不用做任何修改了
    if (sameNumber(realOffset.right, realOffset.left) && sameNumber(realOffset.top, realOffset.bottom)) {
      return {
        position: info.position,
        size: info.size,
        rotate: this.rotate,
      };
    }

    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    // 坐标系偏移，在计算 panel 中组件偏移时需要计算进去
    const coordinateOffset = this.getCoordinateOffset(info);
    const { layout: layoutProp, cell } = this.properties;
    const { left: paddingLeft, right: paddingRight } = this.padding;

    if (layoutProp!.direction === 'vertical') {
      if (cell!.ratioHeight) {
        const newComponentsInfo = this.components.map((comp, index) => {
          // 先计算子应该变化多少高度，然后转化为top or bottom 的offset
          let myOffset = depthClone(realOffset);
          let heightChange: number;
          const childCount = this.components.length;
          const allGap = (layoutProp?.verticalGap || 0) * (childCount - 1);
          const finalCompHeight = (info.size.height - allGap) / childCount;
          heightChange = finalCompHeight - comp.size.height;
          if (isNotEqual0(offset.bottom)) {
            myOffset.bottom = heightChange;
          }
          if (isNotEqual0(offset.top)) {
            myOffset.top = -heightChange;
          }
          //处理子的大小改变
          const newCompInfo = comp.resizeMySelf(myOffset, false);

          if (newCompInfo.patches) {
            coverPatches(patches, newCompInfo.patches);
          }

          // 处理position的变化
          if (isNotEqual0(offset.top) || isNotEqual0(offset.bottom)) {
            newCompInfo.position = {
              x: comp.position.x,
              y: comp.position.y + heightChange * index + offset.top,
            };
          }
          return {
            id: comp.id,
            type: ComponentChangeType.Edit,
            position: {
              x: newCompInfo.position.x + coordinateOffset.x,
              y: newCompInfo.position.y + coordinateOffset.y,
            },
            size: newCompInfo.size,
            rotate: newCompInfo.rotate,
          };
        });
        // 所有子的改变要到这里来
        const res = this.getPositionPatchesOfChildrenChanged(newComponentsInfo);
        if (res.patches) {
          coverPatches(patches, res.patches);
        }
      } else {
        const newComponentsInfo = this.components.map((comp) => {
          const myOffset = {
            ...realOffset,
            top: 0,
            bottom: 0,
          };
          //处理子的大小改变

          const newCompInfo = comp.resizeMySelf(myOffset, false);

          if (newCompInfo.patches) {
            coverPatches(patches, newCompInfo.patches);
          }
          // 只有上拉的时候position才会变化
          if (isNotEqual0(realOffset.top) || isNotEqual0(realOffset.left)) {
            newCompInfo.position = {
              x: comp.position.x + realOffset.left,
              y: comp.position.y + realOffset.top,
            };
          }
          const newPosition = {
            x: newCompInfo.position.x + coordinateOffset.x,
            y: newCompInfo.position.y + coordinateOffset.y,
          };
          return {
            id: comp.id,
            type: ComponentChangeType.Edit,
            position: newPosition,
            size: newCompInfo.size,
            rotate: newCompInfo.rotate,
          };
        });
        // 所有子的改变要到这里来
        const res = this.getPositionPatchesOfChildrenChanged(newComponentsInfo);
        if (res.patches) {
          coverPatches(patches, res.patches);
        }
      }
    } else {
      if (cell!.ratioWidth) {
        const preComp = {
          x: 0,
          width: paddingLeft,
        };
        let accumulateFractionalPart = 0;
        const childCount = this.components.length;
        const horizontalGap = (layoutProp && layoutProp.horizontalGap) || 0;
        const allGap = horizontalGap * (childCount - 1);
        const averageCompWidth = (info.size.width - allGap - paddingLeft - paddingRight) / childCount;
        const averageCompWidthFractionalPart = averageCompWidth % 1;
        const averageCompWidthIntegerPart = averageCompWidth - averageCompWidthFractionalPart;
        const newComponentsInfo = this.components.map((comp, index) => {
          let widthOffset = averageCompWidthIntegerPart - comp.size.width;
          accumulateFractionalPart += averageCompWidthFractionalPart;
          if (accumulateFractionalPart >= 1) {
            accumulateFractionalPart -= 1;
            widthOffset += 1;
          }
          // 先计算子应该变化多少宽，然后转化为left or right 的offset
          let myOffset = depthClone(realOffset);
          if (isNotEqual0(realOffset.right)) {
            myOffset.right = widthOffset;
          }
          if (isNotEqual0(realOffset.left)) {
            myOffset.left = -widthOffset;
          }
          //这里是当组件和别人一起在resize时，就仅用right来控制大小变化
          if (isNotEqual0(realOffset.left) && isNotEqual0(realOffset.right)) {
            myOffset.left = 0;
            myOffset.right = widthOffset;
          }
          //处理子的大小改变
          const { position, size, rotate, patches: childPatches } = comp.resizeMySelf(myOffset, false);
          if (childPatches) {
            coverPatches(patches, childPatches);
          }
          position.x = preComp.x + preComp.width + (index === 0 ? 0 : horizontalGap);
          preComp.x = position.x;
          preComp.width = size.width;
          return {
            id: comp.id,
            type: ComponentChangeType.Edit,
            position: {
              x: position.x,
              y: position.y + coordinateOffset.y,
            },
            size,
            rotate,
          };
        });
        // 所有子的大小改变要到这里来
        const res = this.getPositionPatchesOfChildrenChanged(newComponentsInfo);
        if (res.patches) {
          coverPatches(patches, res.patches);
        }
      } else {
        const newComponentsInfo = this.components.map((comp) => {
          const myOffset = {
            left: 0,
            right: 0,
            top: offset.top,
            bottom: offset.bottom,
          };
          const newCompInfo = comp.resizeMySelf(myOffset, false);
          if (newCompInfo.patches) {
            coverPatches(patches, newCompInfo.patches);
          }
          if (isNotEqual0(realOffset.left)) {
            newCompInfo.position = {
              x: newCompInfo.position.x + realOffset.left,
              y: newCompInfo.position.y,
            };
          }
          return {
            id: comp.id,
            type: ComponentChangeType.Edit,
            position: {
              x: newCompInfo.position.x + coordinateOffset.x,
              y: newCompInfo.position.y + coordinateOffset.y,
            },
            size: newCompInfo.size,
            rotate: newCompInfo.rotate,
          };
        });
        const res = this.getPositionPatchesOfChildrenChanged(newComponentsInfo);
        if (res.patches) {
          coverPatches(patches, res.patches);
        }
      }
    }
    return {
      position: info.position,
      size: info.size,
      rotate: this.rotate,
      patches,
    };
  }

  /**
   *
   * @param {ComponentChange[]} changes
   * @param {boolean} includeSelf
   * @return {ContainerPatches}
   */
  // eslint-disable-next-line no-unused-vars
  getPositionPatchesOfChildrenChanged(changes: ComponentChange[], includeSelf: boolean = false): ContainerPatches {
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };

    // added, removed 让调用者处理
    // 处理 edit 的组件
    updateEditComponentsPatches(
      this.components,
      changes,
      {
        x: 0,
        y: 0,
      },
      patches,
    );
    return {
      patches,
    };
  }

  doRefreshBefore() {
    // reArrangeComponentOfListLayoutPanel(this.data);
  }

  get textAlign(): string {
    let algin = 'center';
    const textSty = this.data.properties.textStyle;
    if (textSty && textSty.textAlign) {
      algin = textSty.textAlign;
    }
    return algin;
  }

  setProperty(propertyName: string, value: PropertyValue): ArtboardPatches {
    const patches = super.setProperty(propertyName, value);
    const strategies: { [key: string]: Function } = {
      [PaddingPropertyName]: this.getPatchesWhenSetPadding.bind(this),
      [LayoutPropertyName]: this.getPatchesWhenSetLayoutProperty.bind(this),
      [CellPropertyName]: this.getPatchesWhenSetCellProperty.bind(this),
    };
    if (strategies[propertyName]) {
      const postPatches = strategies[propertyName](value);
      if (postPatches) {
        coverPatches(patches, postPatches);
      }
    }
    return patches;
  }

  getPatchesWhenSetPadding(value: IPadding) {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    let { disabled } = value;
    let paddingLeft = value.left || 0;
    let paddingTop = value.top || 0;
    let paddingRight = value.right || 0;
    let paddingBottom = value.bottom || 0;

    const properties = this.properties;
    const { layout: layoutProp } = properties;

    if (disabled) {
      paddingLeft = 0;
      paddingTop = 0;
      paddingRight = 0;
      paddingBottom = 0;
    }
    const containerWidth = this.size.width;
    const verticalGap = properties.layout?.verticalGap || 0;

    if (properties?.layout?.direction === 'vertical') {
      //这里代表非等分高度的列表组件
      //top会影响到position,paddingLeft,right影响到宽度，bottom什么都不影响
      const prevCompInfo = {
        y: 0,
        height: paddingTop || 0,
      };
      const newComponentInfo = this.components.map((comp, index) => {
        // 这里只修改宽度
        const {
          size: { width: oldWidth },
        } = comp;
        const leftDistance = comp.position.x;
        const rightDistance = containerWidth - leftDistance - oldWidth;
        const myOffset = {
          left: (paddingLeft || 0) - leftDistance,
          top: 0,
          right: rightDistance - (paddingRight || 0),
          bottom: 0,
        };
        const { position, size, rotate, patches: childPatches } = comp.resizeMySelf(myOffset, false);
        if (childPatches) {
          coverPatches(patches, childPatches);
        }
        const newPosition = {
          ...position,
          y: prevCompInfo.y + prevCompInfo.height + (index === 0 ? 0 : verticalGap),
        };
        prevCompInfo.y = newPosition.y;
        prevCompInfo.height = size.height;
        return {
          id: comp.id,
          type: ComponentChangeType.Edit,
          position: newPosition,
          size,
          rotate,
        };
      });
      const { patches: postPatches } = this.getPositionPatchesOfChildrenChanged(newComponentInfo, true);
      coverPatches(patches, postPatches);
    } else {
      if (properties.cell?.ratioWidth) {
        // 等分宽度的组件，设置padding,所有组件的高度受到padding.paddingTop,padding.bottom的挤占
        // 左右padding + 所有组件的宽度 + 间距 = 容器宽度，所以padding的追加 要减小组件的宽度
        // 我们把宽度调整统一放在myOffset.right上，位置的调整采用从左到右重新排列的方式
        const horizontalGap = (layoutProp && layoutProp.horizontalGap) || 0;
        const childCount = this.components.length;
        const allGap = horizontalGap * (childCount - 1);
        const averageCompWidth = (this.size.width - allGap - paddingLeft - paddingRight) / childCount;
        // 将每个组件的宽度拆分成整数和小数部分 例如 178.5 =  178 + 0.5
        const averageCompWidthFractionalPart = averageCompWidth % 1;
        const averageCompWidthIntegerPart = averageCompWidth - averageCompWidthFractionalPart;

        const prevCompInfo = {
          x: 0,
          width: paddingLeft,
        };
        let accumulateFractionalPart = 0;
        const newChildInfo = this.components.map((comp, index) => {
          let widthOffset = averageCompWidthIntegerPart - comp.size.width;
          accumulateFractionalPart += averageCompWidthFractionalPart;
          //当小数项积累到超过1的时候，那么让当前的多增加1px
          if (accumulateFractionalPart >= 1) {
            accumulateFractionalPart -= 1;
            widthOffset += 1;
          }
          const topChange = paddingTop - this.padding.top;
          const bottomChange = paddingBottom - this.padding.bottom;
          const myOffset = {
            left: 0,
            right: widthOffset,
            top: topChange,
            bottom: -bottomChange,
          };
          const { position, size, rotate, patches: childPatches } = comp.resizeMySelf(myOffset, false);
          position.x = prevCompInfo.x + prevCompInfo.width + (index === 0 ? 0 : horizontalGap);
          //将当前的信息更新到prevComp中
          prevCompInfo.width = size.width;
          prevCompInfo.x = position.x;

          if (childPatches) {
            coverPatches(patches, childPatches);
          }
          return {
            id: comp.id,
            type: ComponentChangeType.Edit,
            position,
            size,
            rotate,
          };
        });
        const { patches: postPatches } = this.getPositionPatchesOfChildrenChanged(newChildInfo, true);
        coverPatches(patches, postPatches);
      }
    }
    return patches;
  }

  getPatchesWhenSetCellProperty(value: PropertyValue) {
    const patches = { do: {}, undo: {} };
    const properties = this.properties;
    if (properties?.layout?.direction === 'vertical') {
      const { rowHeight } = value as ICell;
      const prevCompInfo = {
        y: properties.padding?.top || 0,
        height: 0,
      };
      const newComponentInfo = this.components.map((comp) => {
        const myOffset = {
          left: 0,
          top: 0,
          right: 0,
          bottom: rowHeight! - comp.size.height,
        };
        const { position, size, rotate, patches: childPatches } = comp.resizeMySelf(myOffset, false);
        position.y = prevCompInfo.y + prevCompInfo.height + (properties?.layout?.verticalGap || 0);
        prevCompInfo.y = position.y;
        prevCompInfo.height = size.height;
        if (childPatches) {
          coverPatches(patches, childPatches);
        }
        return {
          id: comp.id,
          type: ComponentChangeType.Edit,
          position,
          size,
          rotate,
        };
      });
      const { patches: postPatches } = this.getPositionPatchesOfChildrenChanged(newComponentInfo, true);
      coverPatches(patches, postPatches);
    }
    if (properties?.layout?.direction === 'horizontal') {
      const { columnWidth } = value as ICell;
      const prevCompInfo = {
        x: properties.padding?.left || 0,
        width: 0,
      };
      const newComponentInfo = this.components.map((comp) => {
        const myOffset = {
          left: 0,
          top: 0,
          right: columnWidth! - comp.size.width,
          bottom: 0,
        };
        const { position, size, rotate, patches: childPatches } = comp.resizeMySelf(myOffset, false);
        position.x = prevCompInfo.x + prevCompInfo.width + (properties.layout!.horizontalGap || 0);
        prevCompInfo.x = position.x;
        prevCompInfo.width = size.width;
        if (childPatches) {
          coverPatches(patches, childPatches);
        }
        return {
          id: comp.id,
          type: ComponentChangeType.Edit,
          position,
          size,
          rotate,
        };
      });
      const { patches: postPatches } = this.getPositionPatchesOfChildrenChanged(newComponentInfo, true);
      coverPatches(patches, postPatches);

      const valueChange = {
        oldVal: this.size,
        newVal: { height: this.size.height, width: columnWidth! * this.components.length },
      };
      //如果列宽改变,那么容器跟随伸缩
      if (columnWidth) {
        coverPatches(
          patches,
          new ArtboardPatchesClass().getPatchesByCompChange(
            this.id,
            new ComponentPatchesClass().getAttrChangePatches(this.id, this.getCurrentSizePath(), valueChange),
          ),
        );
      }
    }
    return patches;
  }

  getPatchesWhenSetLayoutProperty(value: PropertyValue) {
    const patches = { do: {}, undo: {} };
    // 当改变间距时，要调整各子位置，暂时不提供调整排列方向
    const properties = this.properties;
    if (properties?.layout?.direction === 'vertical') {
      if (properties?.cell?.ratioHeight) {
        assert.ok('false', '暂时没提供列表等分宽高时，调整行距的');
      } else {
        const { verticalGap } = value as ILayout;
        const prevCompInfo = {
          y: properties.padding?.top || 0,
          height: 0,
        };
        const newCompsInfo = this.components.map((comp, index) => {
          const { position, size, rotate } = comp;
          const newPosition = depthClone(position);
          newPosition.y = prevCompInfo.y + prevCompInfo.height + (index === 0 ? 0 : verticalGap || 0);
          prevCompInfo.y = newPosition.y;
          prevCompInfo.height = size.height;
          return {
            id: comp.id,
            type: ComponentChangeType.Edit,
            position: newPosition,
            size: size,
            rotate: rotate,
          };
        });
        const { patches: postPatches } = this.getPositionPatchesOfChildrenChanged(newCompsInfo, true);
        coverPatches(patches, postPatches);
      }
    }
    if (properties?.layout?.direction === 'horizontal') {
      const horizontalGap = (value as ILayout).horizontalGap || 0;
      //如果是等分宽度，不仅要位移每个元素，还要改变大小
      if (properties?.cell?.ratioWidth) {
        const { layout: layoutProp } = properties;
        const gap = layoutProp!.horizontalGap || 0;
        const totalGap = (this.components.length - 1) * gap;
        const { left: paddingLeft, right: paddingRight } = this.padding;
        const averageCompWidth = (this.size.width - totalGap - paddingLeft - paddingRight) / this.components.length;
        const averageCompWidthFractionalPart = averageCompWidth % 1;
        const averageCompWidthIntegerPart = averageCompWidth - averageCompWidthFractionalPart;
        const prevCompInfo = {
          x: 0,
          width: paddingLeft,
        };
        const newCompsInfo = this.components.map((comp, index) => {
          const myOffset = {
            left: 0,
            right: averageCompWidthIntegerPart - comp.size.width,
            top: 0,
            bottom: 0,
          };
          const { position, size, rotate, patches: childPatches } = comp.resizeMySelf(myOffset, false);
          if (childPatches) {
            coverPatches(patches, childPatches);
          }
          const isLastOne = index === this.components.length - 1;
          const lastOneEffect = isLastOne ? 1 : 0;
          // 如果是最后一个元素，那么把1像素的误差放在最后一个元素上
          // 因为采用的向下取整，那么一定会少1px,那么最后一个元素宽度增加1px
          position.x = prevCompInfo.x + prevCompInfo.width + (index === 0 ? 0 : horizontalGap);
          prevCompInfo.x = position.x;
          prevCompInfo.width = size.width + lastOneEffect;
          return {
            id: comp.id,
            type: ComponentChangeType.Edit,
            position,
            size: size,
            rotate: rotate,
          };
        });
        const { patches: postPatches } = this.getPositionPatchesOfChildrenChanged(newCompsInfo, true);
        coverPatches(patches, postPatches);
        const containerSizePatches = this.getContainerSizePatchesWhenSetHorizontalGap(horizontalGap);
        coverPatches(patches, containerSizePatches);
      } else {
        // 如果不是等分宽，改间距就仅仅是做位移
        const prevCompInfo = {
          x: 0,
          width: 0,
        };
        const newCompsInfo = this.components.map((comp) => {
          const { position, size, rotate } = comp;
          const newPosition = depthClone(position);
          newPosition.x = prevCompInfo.x + prevCompInfo.width + (horizontalGap || 0);
          prevCompInfo.x = newPosition.x;
          prevCompInfo.width = size.width;
          return {
            id: comp.id,
            type: ComponentChangeType.Edit,
            position: newPosition,
            size: size,
            rotate: rotate,
          };
        });
        const { patches: postPatches } = this.getPositionPatchesOfChildrenChanged(newCompsInfo, true);
        coverPatches(patches, postPatches);
      }
    }
    return patches;
  }

  getContainerSizePatchesWhenSetHorizontalGap(gap: number) {
    const containerPatches: ArtboardPatches = { do: {}, undo: {} };
    const newSize = depthClone(this.size);
    const oldGap = this.properties.layout?.horizontalGap || 0;
    newSize.width = (this.components.length - 1) * (gap - oldGap) + this.size.width;
    const path = this.getCurrentSizePath();
    const doOps: Operation[] = [];
    const undoOps: Operation[] = [];
    doOps.push(Ops.replace(`${path}`, { ...this.size, ...newSize }));
    undoOps.push(Ops.replace(`${path}`, this.size));
    containerPatches.do[this.id] = doOps;
    containerPatches.undo[this.id] = undoOps;
    return containerPatches;
  }

  addComponents(
    components: IComponentData[],
    index = -1,
  ): {
    patches: PagePatches;
    newActiveGroup?: UIContainerComponent;
  } {
    // 当cell.ratioHeight时或cell.ratioWidth时，每添加一个组件，则要动态改变所有子组件的高或宽及坐标
    const patches: PagePatches = {
      [this.ownerArtboardID]: {
        do: {
          [this.id]: [Ops.addChildren(`${index}`, components)],
        },
        undo: {
          [this.id]: [Ops.removeChildren(components.map((comp) => comp._id))],
        },
      },
    };
    const { layout: layoutProp, cell, padding } = this.properties;
    const horizontalGap = layoutProp?.horizontalGap || 0;
    const newAddedComp = components[0];
    if (layoutProp!.direction === 'vertical') {
      const postPatches: ArtboardPatches = {
        do: {},
        undo: {},
      };
      if (cell!.ratioHeight) {
        // 每个组件的大小会改变，并且位置也会改变
        const alreadyExistedComps = this.components;
        const totalCompsCount = alreadyExistedComps.length + components.length;
        const totalGap = this.components.length * (layoutProp!.verticalGap || 0);
        const averageCompHeight = (this.size.height - totalGap) / totalCompsCount;
        alreadyExistedComps.forEach((comp, index) => {
          const myOffset = {
            left: 0,
            right: 0,
            top: 0,
            bottom: averageCompHeight - comp.size.height,
          };
          // resize到平均高度
          const { position, size, rotate, patches: childPatches } = comp.resizeMySelf(myOffset, false);
          if (childPatches) {
            coverPatches(postPatches, childPatches);
          }
          const heightChange = averageCompHeight - comp.size.height;
          // 位置也会改变，修正Y的改变
          position.y = comp.position.y + heightChange * index;
          // 得到的最终patches
          const myPatches = this.getPositionPatchesOfChildrenChanged(
            [
              {
                id: comp.id,
                type: ComponentChangeType.Edit,
                position,
                size,
                rotate,
              },
            ],
            true,
          );
          coverPatches(postPatches, myPatches.patches);
        });
        // 重新计算新添加的元素的位置和大小
        newAddedComp.position.y = this.size.height - averageCompHeight;
        newAddedComp.size.height = averageCompHeight;
        // 将所有的patches 合并到一起
        coverPatches(patches[this.ownerArtboardID], postPatches);
      } else {
        const sumHeight = this.components.reduce((acc, curr) => {
          acc += curr.size.height;
          return acc;
        }, 0);
        const totalGap = (this.components.length + 1) * (layoutProp!.verticalGap || 0);
        newAddedComp.position.y = sumHeight + totalGap + ((padding && padding.top) || 0);
      }
    } else {
      const postPatches: ArtboardPatches = {
        do: {},
        undo: {},
      };
      if (cell!.ratioWidth) {
        // 每个组件的大小会改变，并且位置也会改变
        const alreadyExistedComps = this.components;
        const totalCompsCount = alreadyExistedComps.length + components.length;
        const gap = layoutProp!.horizontalGap || 0;
        const totalGap = this.components.length * gap;
        const { left: paddingLeft, right: paddingRight } = this.padding;
        const averageCompWidth = (this.size.width - totalGap - paddingLeft - paddingRight) / totalCompsCount;
        const averageCompWidthFractionalPart = averageCompWidth % 1;
        const averageCompWidthIntegerPart = averageCompWidth - averageCompWidthFractionalPart;
        let accumulateFractionalPart = 0;
        const preCompInfo = {
          preCompX: 0,
          preCompWidth: paddingLeft,
        };
        alreadyExistedComps.forEach((comp, index) => {
          let myRightOffset = averageCompWidthIntegerPart - comp.size.width;
          accumulateFractionalPart += averageCompWidthFractionalPart;
          if (accumulateFractionalPart >= 1) {
            accumulateFractionalPart -= 1;
            myRightOffset += 1;
          }
          const myOffset = {
            left: 0,
            right: myRightOffset,
            top: 0,
            bottom: 0,
          };
          // resize到平均宽度
          const { position, size, rotate, patches: childPatches } = comp.resizeMySelf(myOffset, false);
          if (childPatches) {
            coverPatches(postPatches, childPatches);
          }
          // 位置也会改变，修正x的改变
          position.x = preCompInfo.preCompX + preCompInfo.preCompWidth + (index === 0 ? 0 : gap);

          // 更新队列
          preCompInfo.preCompX = position.x;
          preCompInfo.preCompWidth = size.width;
          // 得到的最终patches
          const myPatches = this.getPositionPatchesOfChildrenChanged(
            [
              {
                id: comp.id,
                type: ComponentChangeType.Edit,
                position,
                size,
                rotate,
              },
            ],
            true,
          );
          coverPatches(postPatches, myPatches.patches);
        });
        // 重新计算新添加的元素的位置和大小
        newAddedComp.position.x = preCompInfo.preCompX + preCompInfo.preCompWidth + gap;
        newAddedComp.size.width = averageCompWidthIntegerPart + (accumulateFractionalPart >= 1 ? 1 : 0);
        //修改新增加的组件内的文字组件的宽度
        // textInNewComp.size.width = components[0].size.width * 0.6;
        newAddedComp.components?.forEach((comp) => {
          if (comp.layout.horizontal === 'center') {
            const parentCenter = getCenter({ x: 0, y: 0 }, newAddedComp.size);
            comp.position.x = getNWPoint(parentCenter, comp.size, 0).x;
          }
        });
        // 将所有的patches 合并到一起
        coverPatches(patches[this.ownerArtboardID], postPatches);
      } else {
        const sumWidth = this.components.reduce((acc, curr) => {
          acc += curr.size.height;
          return acc;
        }, 0);
        const totalGap = (this.components.length - 1) * (horizontalGap || 0);
        newAddedComp.position.x = sumWidth + totalGap;
      }
    }

    const posPatches = this.afterAppendComponents(components);
    if (posPatches) {
      coverPatches(patches[this.ownerArtboardID], posPatches);
    }
    return { patches };
  }

  addComponentsWhenContainerEmpty(components: IComponentData[]): PagePatches {
    // 当cell.ratioHeight时或cell.ratioWidth时，每添加一个组件，则要动态改变所有子组件的高或宽及坐标
    const patches: PagePatches = {
      [this.ownerArtboardID]: {
        do: {
          [this.id]: [Ops.addChildren('-1', components)],
        },
        undo: {
          [this.id]: [Ops.removeChildren(components.map((comp) => comp._id))],
        },
      },
    };
    const { layout: layoutProp, cell } = this.properties;
    const newAddedComp = components;
    const gap = layoutProp!.horizontalGap || 0;
    if (layoutProp!.direction === 'vertical') {
      if (cell!.ratioHeight) {
        // 每个组件的大小会改变，并且位置也会改变
        const preCompInfo = {
          preCompX: 0,
          preCompWidth: 0,
        };
        newAddedComp.forEach((comp) => {
          // resize到平均宽度
          const { position, size } = comp;
          // 位置也会改变，修正x的改变
          position.x = preCompInfo.preCompX + preCompInfo.preCompWidth + gap;
          comp.size.height = this.size.height / newAddedComp.length;
          // 更新队列
          preCompInfo.preCompX = position.x;
          preCompInfo.preCompWidth = size.width;
        });
      } else {
        const preCompInfo = {
          preCompX: 0,
          preCompWidth: 0,
        };
        newAddedComp.forEach((comp) => {
          // resize到平均宽度
          const { position, size } = comp;
          // 位置也会改变，修正x的改变
          position.x = preCompInfo.preCompX + preCompInfo.preCompWidth + gap;
          // 更新队列
          preCompInfo.preCompX = position.x;
          preCompInfo.preCompWidth = size.width;
        });
      }
    } else {
      const postPatches: ArtboardPatches = {
        do: {},
        undo: {},
      };
      if (cell!.ratioWidth) {
        // 每个组件的大小会改变，并且位置也会改变
        const newAddedComp = components;
        const gap = layoutProp!.horizontalGap || 0;
        const preCompInfo = {
          preCompX: 0,
          preCompWidth: 0,
        };
        newAddedComp.forEach((comp) => {
          // resize到平均宽度
          const { position, size } = comp;
          // 位置也会改变，修正x的改变
          position.x = preCompInfo.preCompX + preCompInfo.preCompWidth + gap;
          // 更新队列
          preCompInfo.preCompX = position.x;
          preCompInfo.preCompWidth = size.width;
        });
        // 重新计算新添加的元素的位置和大小
        // 将所有的patches 合并到一起
        coverPatches(patches[this.ownerArtboardID], postPatches);
      } else {
        const sumWidth = this.components.reduce((acc, curr) => {
          acc += curr.size.height;
          return acc;
        }, 0);
        const totalGap = this.components.length * (layoutProp!.horizontalGap || 0);
        components[0].position.x = sumWidth + totalGap;
      }
    }
    return patches;
  }

  removeComponents(
    removingComps: UIComponent[],
  ): {
    patches: PagePatches;
    newActiveGroup?: UIContainerComponent;
  } {
    // 移除组件，需要修正未选择的流程线的点
    // 第一种情况：不等分父时，当删除中间的组件时，要改变该组件之后的组件坐标
    // 第二种情况：等分父时，删除后，计算剩余组件尺寸及坐标
    const artboardPatches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    // 先设置当前的组件删除对应comps的 patches
    const doOps: Operation[] = [Ops.removeChildren(removingComps.map((comp) => comp.id))];
    const undoOps: Operation[] = removingComps.map((comp) =>
      Ops.addChildren(`${this.components.findIndex((c) => c === comp)}`, [comp.toJSON()]),
    );
    artboardPatches.do[this.id] = doOps;
    artboardPatches.undo[this.id] = undoOps;
    const { layout: layoutProp, cell, padding } = this.properties;
    const { left: paddingLeft, right: paddingRight } = this.padding;
    const verticalGap = (layoutProp && layoutProp.verticalGap) || 0;
    const horizontalGap = (layoutProp && layoutProp.horizontalGap) || 0;
    if (layoutProp?.direction === 'vertical') {
      if (cell?.ratioHeight) {
        //等分父的高度，删除后要重新校正剩下组件的坐标和大小
        const remainComponents = this.components.filter(
          (comp) => !removingComps.find((removingComp) => removingComp.id === comp.id),
        );
        const totalGap = (remainComponents.length - 1) * (layoutProp!.verticalGap || 0);
        const averageCompHeight = (this.size.height - totalGap) / remainComponents.length;
        const newCompsInfo = remainComponents.map((comp, index) => {
          const myOffset = {
            left: 0,
            right: 0,
            top: 0,
            bottom: averageCompHeight - comp.size.height,
          };
          const { position, size, rotate, patches: childPatches } = comp.resizeMySelf(myOffset, false);
          if (childPatches) {
            coverPatches(artboardPatches, childPatches);
          }
          // 在这里重置的位置信息
          position.y = averageCompHeight * index + (layoutProp!.verticalGap || 0) * index;

          return {
            id: comp.id,
            type: ComponentChangeType.Edit,
            position,
            size,
            rotate,
          };
        });

        const { patches: childPatches } = this.getPositionPatchesOfChildrenChanged(newCompsInfo);
        coverPatches(artboardPatches, childPatches);
      } else {
        const preCompInfo = {
          preCompY: (padding && padding.top) || 0,
          preCompHeight: 0,
        };
        const remainComponents = this.components.filter(
          (comp) => !removingComps.find((removingComp) => removingComp.id === comp.id),
        );
        const newInfo = remainComponents.map((comp, index) => {
          const newPosition = {
            x: comp.position.x,
            y: preCompInfo.preCompY + preCompInfo.preCompHeight + (index === 0 ? 0 : verticalGap),
          };
          preCompInfo.preCompY = newPosition.y;
          preCompInfo.preCompHeight = comp.size.height;
          return {
            id: comp.id,
            type: ComponentChangeType.Edit,
            size: comp.size,
            position: newPosition,
            rotate: comp.rotate,
          };
        });
        const { patches: childPatches } = this.getPositionPatchesOfChildrenChanged(newInfo);
        coverPatches(artboardPatches, childPatches);
      }
    } else {
      const remainComponents = this.components.filter(
        (comp) => !removingComps.find((removingComp) => removingComp.id === comp.id),
      );
      const totalGap = (remainComponents.length - 1) * (layoutProp!.horizontalGap || 0);

      const averageCompWidth = (this.size.width - totalGap - paddingLeft - paddingRight) / remainComponents.length;
      const averageCompWidthFractionalPart = averageCompWidth % 1;
      const averageCompWidthIntegerPart = averageCompWidth - averageCompWidthFractionalPart;
      let accumulateFractionalPart = 0;
      const preCompInfo = {
        preCompX: 0,
        preCompWidth: paddingLeft,
      };
      const newCompsInfo = remainComponents.map((comp, index) => {
        let myRightOffset = averageCompWidthIntegerPart - comp.size.width;
        accumulateFractionalPart += averageCompWidthFractionalPart;
        if (accumulateFractionalPart >= 1) {
          accumulateFractionalPart -= 1;
          myRightOffset += 1;
        }
        const myOffset = {
          left: 0,
          right: myRightOffset,
          top: 0,
          bottom: 0,
        };
        const { position, size, rotate, patches: childPatches } = comp.resizeMySelf(myOffset, false);
        if (childPatches) {
          coverPatches(artboardPatches, childPatches);
        }
        // 在这里重置的位置信息
        position.x = preCompInfo.preCompX + preCompInfo.preCompWidth + (index === 0 ? 0 : horizontalGap);
        // 更新队列
        preCompInfo.preCompX = position.x;
        preCompInfo.preCompWidth = size.width;
        return {
          id: comp.id,
          type: ComponentChangeType.Edit,
          position,
          size,
          rotate,
        };
      });

      const { patches: childPatches } = this.getPositionPatchesOfChildrenChanged(newCompsInfo);
      coverPatches(artboardPatches, childPatches);
    }
    const patches: PagePatches = {
      [this.ownerArtboardID]: artboardPatches,
    };
    const posPatches = this.afterRemoveComponents(removingComps.map((comp) => comp.id));
    if (posPatches && posPatches.do) {
      coverPatches(patches[this.ownerArtboardID], posPatches);
    }
    return {
      patches,
      newActiveGroup: this,
    };
  }

  moveChildOrder(oldIndex: number, newIndex: number): ArtboardPatches {
    const patches = super.moveChildOrder(oldIndex, newIndex);
    if (oldIndex !== newIndex) {
      const positions = this.components.map((comp) => comp.position);
      const comps = moveArrayItem([...this.components], oldIndex, newIndex);
      comps.forEach((comp, i) => {
        const _do = [Ops.replace('/position', roundNumberObject(positions[i]))];
        const _undo = [Ops.replace('/position', comp.position)];
        if (patches.do[comp.id]) {
          patches.do[comp.id].push(..._do);
          patches.undo[comp.id].push(..._undo);
        } else {
          patches.do[comp.id] = _do;
          patches.undo[comp.id] = _undo;
        }
      });
    }
    return patches;
  }
}
