import * as React from 'react';

import classNames from 'classnames';

import i18n from '@/i18n';

import { Icon, IListItem, List, PopupPanel, SearchBox, Tree, TreeItemData } from '@/dsm';
import { ComponentTheme } from '@/dsm/common';
import { treeUniteType } from '..';

import { IEmbedApp, IEmbedPage, IEmbedTeam } from '@/fbs/rp/models/embedDesignDraft';

import NoContent from '../NoContent';

interface ISelectorBoxProps {
  showTeamPanel: boolean;
  showProjectPanel: boolean;
  selectedTeam: IListItem | null;
  selectedProject: IEmbedApp | null;
  point?: {
    x: number;
    y: number;
  } | null;
  popUpPanelWidth: number;
  popUpPanelHeight: number;
  teams: IEmbedTeam[];
  projectTree: React.RefObject<Tree<IEmbedApp>>;
  projects: TreeItemData<treeUniteType>[];
  onToggleTeamSelectorPanel?: (e: React.MouseEvent) => void;
  onDoPanelClose: () => void;
  onRenderTreeItem?: (item: IEmbedApp | IEmbedPage) => JSX.Element;
  onTeamsSearch?: (value: string) => void;
  onTeamSelect?: (team: IListItem) => void;
  onToggleProjectSelectorPanel?: (e: React.MouseEvent) => void;
  onProjectsSearch?: (value: string) => void;
  onProjectTreeItemClick?: (e: React.MouseEvent, project: IEmbedApp) => void;
}

const normalSelectorWidth: number = 280;
const isHuaWei = RP_CONFIGS.isHuaWei;

export default React.memo(function SelectorBox(props: ISelectorBoxProps) {
  const {
    showTeamPanel,
    selectedTeam,
    point,
    popUpPanelWidth,
    popUpPanelHeight,
    teams,
    selectedProject,
    showProjectPanel,
    projects,
    projectTree,
    onToggleTeamSelectorPanel,
    onDoPanelClose,
    onTeamsSearch,
    onRenderTreeItem,
    onTeamSelect,
    onToggleProjectSelectorPanel,
    onProjectsSearch,
    onProjectTreeItemClick,
  } = props;

  const mapTeams = teams.map((team) => {
    return {
      id: team.id,
      text: team.name,
    };
  });

  return (
    <>
      <div className="selector-box">
        {isHuaWei && (
          <>
            <div className="team-selector team-selector-1" onClick={onToggleTeamSelectorPanel}>
              <p>{selectedTeam ? selectedTeam.text : i18n('resource.componentsText.selectTeam')}</p>
              <Icon cls="tag_downarrow" className="team-arrow" />
            </div>
            {showTeamPanel && (
              <PopupPanel
                className="snapshot-float-panel"
                position={point!}
                width={popUpPanelWidth}
                height={popUpPanelHeight}
                onClose={onDoPanelClose}
              >
                <SearchBox onChange={onTeamsSearch} />
                {mapTeams.length > 0 ? (
                  <List
                    data={mapTeams}
                    itemHeight={30}
                    maxRowCount={8}
                    className="team-item"
                    allowHover
                    theme={ComponentTheme.light}
                    selectedIndex={mapTeams.findIndex((item) => item.id === selectedTeam?.id)}
                    onSelect={onTeamSelect}
                  />
                ) : (
                  <NoContent />
                )}
              </PopupPanel>
            )}
          </>
        )}
        <div
          className={classNames({ 'team-selector': true, 'selector-no-flex': !isHuaWei })}
          onClick={onToggleProjectSelectorPanel}
        >
          <p className={classNames({ 'no-flex': !isHuaWei, 'set-display': !isHuaWei })}>
            {selectedProject ? selectedProject.name : i18n('resource.componentsText.selectProject')}
          </p>
          <Icon cls="tag_downarrow" className={classNames({ 'team-arrow': true, 'no-flex': !isHuaWei })} />
        </div>
        {showProjectPanel && (
          <PopupPanel
            className="snapshot-float-panel"
            position={point!}
            width={isHuaWei ? popUpPanelWidth : normalSelectorWidth}
            height={popUpPanelHeight}
            onClose={onDoPanelClose}
          >
            <SearchBox onChange={onProjectsSearch} />
            {projects.length > 0 ? (
              <Tree
                className="snapshot-project-tree"
                items={projects as TreeItemData<IEmbedApp>[]}
                itemRender={onRenderTreeItem}
                onItemClick={onProjectTreeItemClick}
                theme={ComponentTheme.light}
                prefixIndent={10}
                ref={projectTree}
              />
            ) : (
              <NoContent />
            )}
          </PopupPanel>
        )}
      </div>
    </>
  );
});
