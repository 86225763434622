import * as React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';

import * as GraphicsUtils from '@utils/graphicsUtils';

import { PureColor } from '@fbs/rp/models/properties/color';
import { ISize } from '@/fbs/common/models/common';
import { IProperties } from '@/fbs/rp/models/property';
import { IComponentValue } from '@/fbs/rp/models/value';

import * as SystemsColor from '@consts/colors';

import { StyleHelper } from '@helpers/styleHelper';

import { IComponentProps } from '../../types';

import TextAreaEditor, { ITextAreaEditorProps } from '../common/TextAreaEditor';
import WithSelectTextRange from '../common/WithSelectTextRange';
import Background from '../common/Background';

import './index.scss';

interface ITextAreaState {
  style: React.CSSProperties;
  value: string;
  placeholderStyle: React.CSSProperties;
  focus?: boolean;
}

const TextAreaEditorTextRange = WithSelectTextRange<ITextAreaEditorProps>(TextAreaEditor);

export default class TextArea extends React.Component<IComponentProps, ITextAreaState> {
  private lastCompValue: string;

  constructor(props: IComponentProps) {
    super(props);
    this.lastCompValue = props.comp.value as string;
    this.state = {
      style: this.parseStyle(props),
      value: this.lastCompValue,
      placeholderStyle: this.doParsePlaceHolderStyle(props),
    };

    this.param = this.getParams(props);
  }

  private param: {
    size: ISize;
    properties: IProperties;
    value?: IComponentValue;
    opacity: number;
    isPreview?: boolean;
    globalScale: number;
    transition: string;
    valueEditing?: boolean;
    version: string;
  } & { [key: string]: any };

  private getParams = (props: IComponentProps) => {
    const {
      comp: { size, properties, value, opacity, version },
      isPreview,
      globalScale,
      valueEditing,
    } = props;
    const transition = props.comp.getTransition();
    return {
      size: _.cloneDeep(size),
      properties: _.cloneDeep(properties),
      value: _.cloneDeep(value),
      opacity,
      isPreview,
      globalScale,
      transition,
      valueEditing,
      version,
    };
  };

  shouldComponentUpdate(nextProps: IComponentProps, nextState: ITextAreaState) {
    const newParam: { [key: string]: any } = this.getParams(nextProps);

    let flag = false;
    Object.keys(this.param).forEach((key) => {
      if (!_.isEqual(newParam[key], this.param[key])) {
        flag = true;
        this.param[key] = _.cloneDeep(newParam[key]);
      }
    });

    if (!_.isEqual(this.state.value, nextState.value)) {
      flag = true;
    }

    return flag;
  }

  UNSAFE_componentWillReceiveProps(nextProps: IComponentProps) {
    const { comp, isPreview } = nextProps;
    let value = isPreview ? this.state.value : (comp.value as string);
    if (isPreview && comp.value !== this.lastCompValue) {
      this.lastCompValue = comp.value as string;
      value = this.lastCompValue;
    }
    const style = this.parseStyle(nextProps);
    this.setState({
      style,
      value,
      placeholderStyle: {
        ...this.doParsePlaceHolderStyle(nextProps),
        fontFamily: style.fontFamily,
        fontSize: style.fontSize,
        fontStyle: style.fontStyle,
        fontWeight: style.fontWeight,
        textDecoration: style.textDecoration,
        lineHeight: style.lineHeight,
        textDecorationLine: style.textDecorationLine,
      },
    });
  }

  doParsePlaceHolderStyle = (props: IComponentProps): React.CSSProperties => {
    const {
      properties: { placeholderStyle },
      padding,
    } = props.comp;
    const { left, top, right, bottom } = padding;
    const margin = `${top}px ${right}px ${bottom}px ${left}px`;
    if (placeholderStyle) {
      const defaultColor: PureColor = placeholderStyle.value
        ? (placeholderStyle.value as PureColor)
        : SystemsColor.GrayColor;
      const color = GraphicsUtils.parseColorToString(defaultColor);
      return { color, margin };
    }
    return {
      margin,
      color: GraphicsUtils.parseColorToString(SystemsColor.GrayColor),
    };
  };

  private parseStyle = (props: IComponentProps): React.CSSProperties => {
    const { comp, valueEditing } = props;
    const parser = StyleHelper.initCSSStyleParser(comp.properties);
    const style: React.CSSProperties = {
      opacity: _.isUndefined(comp.opacity) ? 1 : comp.opacity / 100,
      transition: comp.getTransition(),
      display: 'flex',
      ...parser.getTextStyle(),
    };
    if (!valueEditing) {
      return {
        ...style,
        ...parser.getShadowStyle(),
      };
    }
    return style;
  };

  doSubmit = (value: string) => {
    const { onValueEdited, comp } = this.props;
    const selection = document.getSelection();
    if (selection) {
      selection.empty();
    }
    onValueEdited && onValueEdited(comp, value);
  };

  handleInput = (e: React.SyntheticEvent) => {
    const { isPreview } = this.props;
    if (isPreview) {
      const value = (e.target as HTMLTextAreaElement).value;
      this.setState({ value });
    }
  };

  handleFocus = (e: React.FocusEvent) => {
    if (!this.props.isPreview || this.props.valueEditing) {
      return;
    }
    if (e.type === 'focus') {
      this.setState({ focus: true });
    } else {
      this.setState({ focus: false });
    }
  };

  render() {
    const { isPreview, comp, valueEditing /*globalScale*/ } = this.props;
    const { style, placeholderStyle } = this.state;
    const { size, /*type, id,*/ properties, opacity } = comp;
    const { multiText, placeholder, textFormat, textStyle, padding, border, radius, fill, stroke } = properties;
    const wrap = multiText ? multiText.wrap : true;
    const fontSize: number = (textFormat || textStyle)?.fontSize || 14;
    const ratio = Math.min(1, fontSize / 12);
    let paddingLeft = 0;
    let paddingTop = 0;
    let paddingRight = 0;
    let paddingBottom = 0;
    if (padding && !padding.disabled) {
      paddingLeft = padding.left || 0;
      paddingTop = padding.top || 0;
      paddingBottom = padding.bottom || 0;
      paddingRight = padding.right || 0;
    }
    const baseStyle: React.CSSProperties = {
      fontStyle: style.fontStyle,
      lineHeight: style.lineHeight || comp.lineHeight,
      textDecoration: style.textDecoration,
      textDecorationLine: style.textDecorationLine,
      transform: `scale(${ratio})`,
      letterSpacing: 'inherit',
      position: 'absolute',
      top: 0,
      left: 0,
      paddingLeft,
      paddingRight,
      paddingBottom,
      paddingTop,
    };
    const isTransparent = opacity === 0;
    const parser = StyleHelper.initCSSStyleParser({ radius, border, stroke });
    const radiusStyle = parser.getRadiusStyle(size);
    return (
      <div className="lib-comp-text-area" style={style}>
        {!fill?.disabled && !isTransparent && (
          <Background size={size} properties={{ fill }} transition={comp.getTransition()} coverStyle={radiusStyle} />
        )}
        <div
          className={classnames('atom-comp-text-area-content', { editing: valueEditing, preview: isPreview })}
          style={radiusStyle}
        >
          {!isPreview && valueEditing && (
            <>
              <textarea
                style={{
                  ...baseStyle,
                  background: 'transparent',
                  width: size.width / ratio,
                  height: size.height / ratio,
                }}
                onWheel={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                className="text-area"
              />
              <TextAreaEditorTextRange
                className="text-area editing"
                wrap={wrap}
                style={{
                  ...baseStyle,
                  ...style,
                  background: 'transparent',
                  width: (size.width - 14) / ratio,
                  overflow: 'auto',
                }}
                value={this.state.value}
                onChange={this.doSubmit}
              />
            </>
          )}
          {(isPreview || !valueEditing) && (
            <textarea
              style={{
                ...baseStyle,
                ...radiusStyle,
                background: 'transparent',
                width: size.width / ratio,
                height: size.height / ratio,
                paddingRight: Math.max(0, paddingRight - 2),
                overflow: 'auto',
              }}
              value={this.state.value}
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              onBlur={this.handleFocus}
              className={classnames('text-area', { preview: isPreview && !comp.disabled })}
            />
          )}
          {!valueEditing && !this.state.value && (
            <label style={placeholderStyle} className="placeholder-text">
              {placeholder?.value as string}
            </label>
          )}
        </div>
        {!stroke?.disabled && !isTransparent && (
          <Background
            size={size}
            properties={{ stroke, border }}
            transition={comp.getTransition()}
            coverStyle={{ ...radiusStyle, zIndex: valueEditing ? 0 : 1 }}
          />
        )}
      </div>
    );
  }
}
