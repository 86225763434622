/**
 * @description: 腾讯会议分享
 * @author: koujing
 * @date: 2022-05-19 11:50
 **/

export enum ShareType {
  weMeet = 'weMeet',
  mock = 'mock',
}
