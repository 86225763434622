/* eslint-disable @typescript-eslint/no-unused-vars */
import * as assert from 'assert';
import { isEqual, update, cloneDeep } from 'lodash';
import { getValueByPointer } from 'fast-json-patch';

import { rotatePoint, isContainer } from '@utils/boundsUtils';
import { depthClone, sameNumber, getProxyTargetValue, roundNumberObject } from '@utils/globalUtils';
import { getParagraph, measureTextSize } from '@utils/textUtils';

import { IComponentData, IComponentSize, IImgSlice } from '@/fbs/rp/models/component';
import { StrokePosition } from '@/fbs/rp/models/properties/stroke';
import { IBounds, IBoundsOffset, IPosition, ISize } from '@fbs/common/models/common';
import { ETextBehaviour, IComponentState, IRemark } from '@fbs/rp/models/component';
import { IUserPreference } from '@fbs/rp/models/grid';
import { SessionInfo } from '@fbs/rp/models/io';
import { ILayout } from '@fbs/rp/models/layout';
import { MultiTextPropertyName } from '@fbs/rp/models/properties/multiText';
import { PolygonPropertyName } from '@fbs/rp/models/properties/polygon';
import ITextFormat, { TextPropertyName } from '@fbs/rp/models/properties/text';
import { TextFormatExPropertyName } from '@fbs/rp/models/properties/textFormat';
import { PropertyName, PropertyValue } from '@fbs/rp/models/property';
import { IComponentValue, IPathValue, ISnapshotValue, IconValue } from '@fbs/rp/models/value';
import { ArtboardPatches, Ops, PagePatches, ReplaceOperation } from '@fbs/rp/utils/patch';

import ParseSvg from '@/helpers/parseSvg';
import { getSupportStates } from '@/helpers/stateHelper';
import { StyleHelper } from '@/helpers/styleHelper';
import { getCompAbsoluteMatrix, getModifyFlipAttrPatches } from '@helpers/componentHelper';
import editorValidate from '@helpers/editorValidate';
import { getPatchesWhenFlip, IFlipModel } from '@helpers/flipHelper';
import { getNewID } from '@helpers/idHelper';
import { coverPatches, mergePatches, sortPatches } from '@helpers/patchHelper';
import { sessionOptions } from '@helpers/sessionHelper';
import { getArtboardLTInfo } from '@/helpers/workspaceHelper';
import { resetIconValue } from '@helpers/iconHelper';
import { reRenderComps } from './helper';

import {
  CompValueSettingOption,
  UIArtboard,
  UIComponent,
  UICompoundPathComponent,
  UIContainerComponent,
  UIGroupComponent,
  UISelectPanelComponent,
  UIStackPanelComponent,
  UIWrapPanelComponent,
  UIFragment,
  UIShapeComponent,
} from '@editor/comps';
import { ComponentChangeType, extractDynamicInfoFromPatch } from '@editor/comps/resizeHelper';
import CoreActive from '@editor/corePartial/active';
import Doc from '@editor/document';
import { ComponentPatchesClass } from '@editor/patches/ComponentPatches';
import { ArtboardPatchesClass } from '@editor/patches/artboardPatches';

import i18n from '@/i18n';

import { makeComponent, makeImageComponent, getLibDefaultData } from '@libs/libs';
import { DefaultCanvasData, makeCanvas } from '@libs/containers/Canvas/index.tsx';
import {
  CPath,
  CImageTextTabs,
  CText,
  CParagraph,
  CTextArea,
  CInput,
  CContentPanel,
  CContentPanelV2,
  CAudio,
  CLine,
  CCompoundPath,
  CCanvasPanel,
  CIcon,
} from '@libs/constants';
import { isTextType, isBasicComp } from '@libs/helper';

import { DefaultFontSize, MinFontSize } from '@consts/fonts';
import { GeneralPropertyName } from '@consts/types/component';
import RefactorRemarkManager from '@/managers/refactorRemarkManager';

import { getViewBoundsOfComponents } from '../bounds';
import AdvancedEditor from '../advancedEditor';
import { AutoFillType } from '@/components/Application/WorkContainer/Workspace/AutoFillPanel/common';

export interface TransformSvg {
  execute: () => Promise<boolean>;
  abort: () => void;
}

export default abstract class CoreSelect extends CoreActive {
  public querySearchCompsHandle?: () => UIComponent[];
  public isZooming = false; // 工作区是否为isZooming状态

  protected constructor(doc: Doc, appID: string, nodeID: string, session: SessionInfo) {
    super(doc, appID, nodeID, session);
  }

  /**
   * 选中一个区域的画板
   * @param boundsInArtboard 相对主画板的一个区域
   * @param append 是否追加选择
   * @param fn 获取画板在工作区的Bounds函数
   */
  selectByRangeBoundsOfArtboard(bounds: IBounds, append?: boolean, fn?: Function) {
    if (!append) {
      this.clearSelectedFragments();
    }
    this.doc.artboardsFragments.forEach((fragment) => {
      if (this.isSelectedByOthers(fragment.id)) {
        return;
      }
      const artboardBounds = fn ? fn(fragment) : getArtboardLTInfo(fragment, this.doc.mainArtboard);
      const isContainerFragment = isContainer(bounds, artboardBounds);
      if (isContainerFragment) {
        if (append && this.selectedFragments.has(fragment)) {
          this.selectedFragments.delete(fragment);
        } else {
          this.selectedFragments.add(fragment);
        }
      }
    });
    // 激活画板
    if (this.selectedFragments.size > 0) {
      const list = Array.from(this.selectedFragments);
      this.setActiveArtboard(list[0]);
      this.pushArtboardSelectedInfo();
      this.notifyUpdateListener(list.map((artboard) => artboard.artboardID));
    }
    this.message.emit(this.message.SELECTED_CHANGE);
  }

  /**
   * 选中一个区域的组件
   * @param {IBounds} boundsInArtboard 相对于激活画板的一个区域
   * @param {boolean} append 是否追加选择
   * @param {boolean} isIntersect 是否相交即选中
   * @param userPreference
   */
  selectByRangeBounds(
    boundsInArtboard: IBounds,
    append?: boolean,
    isIntersect: boolean = false,
    userPreference?: IUserPreference,
  ) {
    this.resetCloneOffset();
    if (!append) {
      this.clearSelected();
    }
    const { showHiddenArea = true } = userPreference?.generalSettings ?? {};
    const matrix = getCompAbsoluteMatrix(this.activeContainer);
    this.activeContainer.components.forEach((comp) => {
      // 忽略其它人选中的组件，忽略锁定组件，并根据首选项选择性忽略隐藏的组件
      const disableSelectingByCompHiddenProperty = showHiddenArea ? false : comp.hidden;
      if (this.isSelectedByOthers(comp.id) || disableSelectingByCompHiddenProperty) {
        return;
      }

      if (comp.intersectBounds(boundsInArtboard, matrix, 'artboard', !isIntersect)) {
        if (append && this.selectedComponents.has(comp)) {
          this.selectedComponents.delete(comp);
        } else {
          this.selectedComponents.add(comp);
        }
      }
    });
    this.pushArtboardSelectedInfo();
    this.notifyUpdateListener([this.activeArtboard.artboardID]);
    this.message.emit(this.message.SELECTED_CHANGE);
  }

  /**
   * 是否选中的有组件，某些时候，界面的可用状态判断只需要判断是否选定的有组件
   * @returns {boolean}
   */
  hasSelect(): boolean {
    return this.selectedComponents.size > 0;
  }

  hasSelectedFragments(): boolean {
    return this.selectedFragments.size > 0;
  }

  isMainArtboardSelected(): boolean {
    return this.selectedFragments.has(this.doc.mainArtboard);
  }

  isMultiSelectingFragments(): boolean {
    return this.selectedFragments.size > 1;
  }

  public startTextEditor(): void {
    const comp = this.firstSelectedComponent;
    if (this.fixContent && comp) {
      const { id } = comp;
      this.patch({
        [this.activeArtboard.artboardID]: {
          [id]: [Ops.replace('/_currentState', 'normal')],
        },
      });
    }
  }

  protected restoreToOriginalState() {
    const selComps = this.selectedComponentList;
    if (selComps.length) {
      const operations = selComps.reduce((acc, curr) => {
        if (curr.toJSON()._currentState) {
          let defaultStateID = undefined;
          if (curr.disabled) {
            defaultStateID = 'disabled';
          } else if (curr.selected) {
            defaultStateID = 'checked';
          }
          return {
            ...acc,
            // [curr.id]: curr.switchState(defaultStateID),
            ...curr.switchState(defaultStateID),
          };
        }
        return acc;
      }, {});
      if (Object.keys(operations).length) {
        this.patch({ [this.activeArtboard.artboardID]: operations });
      }
    }
  }

  changeLocked(locked: boolean) {
    const comps = this.selectedComponentList;
    if (comps.length) {
      const need = comps.filter((comp) => comp.locked !== locked && !comp.isConnector);
      if (need) {
        const artboardPatches: ArtboardPatches = { do: {}, undo: {} };
        need.reduce((acc, curr) => {
          const compPatches = curr.changeLocked(locked);
          acc.do[curr.id] = compPatches.do;
          acc.undo[curr.id] = compPatches.undo;
          return acc;
        }, artboardPatches);
        if (locked && need.length > 1) {
          this.clearSelected();
        }
        this.updateSingleArtboard(this.activeArtboard.artboardID, artboardPatches);
      }
    }
  }

  lockComp(
    locked: boolean,
    components: UIComponent[],
    artboardPatches: ArtboardPatches,
    includeChild: boolean = false,
  ) {
    if (components.length) {
      const need = components.filter((comp) => !comp.isConnector);
      if (need) {
        need.reduce((acc, curr) => {
          if (!!curr.locked !== locked) {
            const compPatches = curr.changeLocked(locked);
            acc.do[curr.id] = compPatches.do;
            acc.undo[curr.id] = compPatches.undo;
          }
          if (
            curr.isContainer &&
            includeChild &&
            !curr.isSealed &&
            !curr.isCompoundPath &&
            (curr as UIContainerComponent).components.length
          ) {
            this.lockComp(locked, (curr as UIContainerComponent).components, artboardPatches, includeChild);
          }
          return acc;
        }, artboardPatches);
      }
    }
  }

  lockAll(locked: boolean) {
    const comps = this.activeArtboard.components;
    const artboardPatches: ArtboardPatches = { do: {}, undo: {} };
    this.lockComp(locked, comps, artboardPatches, true);
    this.updateSingleArtboard(this.activeArtboard.artboardID, artboardPatches);
  }

  singleSelectFragment(fragment: UIFragment) {
    // 当前只有一个选中面板，且选中面板为传入面板
    if (this.selectedFragments.has(fragment) && this.selectedFragments.size === 1) {
      return;
    }
    const clearIds = [...this.selectedFragments].map((artboard) => artboard.artboardID);
    this.selectedFragments.clear();
    this.selectedFragments.add(fragment);
    this.notifyUpdateListener([fragment.artboardID, ...clearIds]);
  }

  multiSelectFragment(fragment: UIFragment) {
    this.selectedFragments.add(fragment);
    this.notifyUpdateListener(Array.from(this.selectedFragments).map((artboard) => artboard.artboardID));
  }

  unselectFragment(fragment: UIFragment) {
    this.selectedFragments.delete(fragment);

    this.batchSyncNoticeUpdate(() => {
      if (this.hasSelectedFragments()) {
        const firstSelectedFragment = Array.from(this.selectedFragments)[0];
        this.setActiveArtboard(firstSelectedFragment);
      }

      const updateArtboardIds = [fragment.artboardID];

      // 当激活画板没有变，且选中画板只有一个时，此画板应显示resize框(选框)
      if (this.selectedFragments.size === 1) {
        updateArtboardIds.push(this.activeArtboard.artboardID);
      }

      this.notifyUpdateListener(updateArtboardIds);
    });
  }

  clearSelectedFragments() {
    if (this.selectedFragments.size > 0) {
      const updateArtboardIds = Array.from(this.selectedFragments).map((artboard) => artboard.artboardID);
      this.selectedFragments.clear();
      this.notifyUpdateListener(updateArtboardIds);
    }
  }
  moveSelectedFragment(fragments: UIFragment[]) {
    this.notifyUpdateListener(fragments.map((artboard) => artboard.artboardID));
  }

  /**
   * 选中组件
   * @param {UIComponent[]} components
   * @param {boolean} append 是否追加到已选组件之后
   */
  select(components: UIComponent[], append: boolean) {
    assert.ok(components.length > 0, '必须要有选中的组件，如果需要清空，请使用 `clearSelected` 方法。');
    assert.ok(
      components.every((comp) => comp.parent === components[0].parent),
      '选中的组件必须父全部一样。',
    );
    // 更新画板id
    const updateArtboardIds = [this.activeArtboard.artboardID];
    this.restoreToOriginalState();
    if (!append) {
      const clearComps = Array.from(this.selectedComponents);
      this.selectedComponents.clear();
      // 手动更新之前选中组件，处理搜索替换功能中更新不及时问题
      reRenderComps(clearComps);
    }
    // 如果当前已经有选中组件，不允许选中其它组件了
    if (this.hasSelectedComponents) {
      if (this.activeContainer !== components[0].parent) {
        return;
      }
    } else {
      if (this.activeContainer !== components[0].parent) {
        assert.ok(components[0].parent, '被选中组件的父一定存在。');
        this.activeContainer = components[0].parent!;
        if (this.activeContainer.isArtboard) {
          this.setActiveArtboard(this.activeContainer as UIArtboard);
          updateArtboardIds.push((this.activeContainer as UIArtboard).artboardID);
        }
      }
    }
    components.forEach((comp) => this.selectedComponents.add(comp));
    /************begin***********注释相关代码（允许锁定组件）*******begin*********/

    // const noLockedComps = components.filter((comp) => !comp.locked);
    // if (noLockedComps.length === 0) {
    //   components.forEach((comp) => this.selectedComponents.add(comp));
    // } else {
    //   noLockedComps.forEach((comp) => this.selectedComponents.add(comp));
    // }
    /*************end**********注释相关代码（允许锁定组件）*****end***********/

    this.pushArtboardSelectedInfo();
    this.notifyUpdateListener(updateArtboardIds);
    this.message.emit(this.message.SELECTED_CHANGE);
  }

  /**
   * 全选所有能选中的组件
   */
  selectAll(ignoreHiddenComp?: boolean) {
    this.clearSelected();
    const comps = this.querySearchCompsHandle ? this.querySearchCompsHandle() : this.activeContainer.components;
    const componentsCanSelected = comps.filter(
      (comp) => !this.isSelectedByOthers(comp.id) && (!comp.hidden || (comp.hidden && !ignoreHiddenComp)),
    );
    if (componentsCanSelected.length) {
      this.select(componentsCanSelected, false);
    }
    this.message.emit(this.message.SELECTED_CHANGE);
  }

  /**
   * 反选组件
   * @param {UIComponent[]} components
   */
  unSelect(components: UIComponent[]) {
    if (components.length) {
      components.forEach((comp) => this.selectedComponents.delete(comp));
      this.pushArtboardSelectedInfo();
      this.notifyUpdateListener();
    }
    this.message.emit(this.message.SELECTED_CHANGE);
  }

  /**
   * 清除选中的组件，也即取消选中所有组件
   */
  clearSelected() {
    // 有选中情况时才清除，避免不必要的刷新
    if (this.selectedComponents.size) {
      this.restoreToOriginalState();
      // 在工作台处于isZooming的时候，组件的渲染被阻止，直接调用选框重选更新的方法重置选框
      if (this.isZooming) {
        const temp = Array.from(this.selectedComponents);
        this.selectedComponents.clear();
        temp.forEach((comp) => {
          comp.updateSelectingCompView?.();
        });
        temp.length = 0;
      } else {
        this.selectedComponents.clear();
      }
      this.pushArtboardSelectedInfo();
      this.notifyUpdateListener([this.activeArtboard.artboardID]);
    }
    this.message.emit(this.message.SELECTED_CHANGE);
  }

  /**
   * 将文本组件设置切换自动大小
   */
  selectedTextCompAutoSize = () => {
    // 锁定组件不允许设置属性
    if (this.hasSelectLockedComps) {
      return;
    }
    const texts = this.selectedTextComp;
    if (!texts.length) {
      return;
    }
    const updateAutoSizeAttrPatches: ArtboardPatchesClass = texts.reduce((artboardPatches, textComp) => {
      const { id, autoSize } = textComp;
      const compPatches = new ComponentPatchesClass().getAttrChangePatches(id, './autoSize', {
        oldVal: autoSize,
        newVal: !autoSize,
      });
      artboardPatches.coverPatches(new ArtboardPatchesClass().getPatchesByCompChange(id, compPatches));
      return artboardPatches;
    }, new ArtboardPatchesClass());

    const sizePathes: ArtboardPatches = texts.reduce((patches, curr) => {
      //由autoSize为 false 转变为 true 的时候才修改大小
      !curr.autoSize && patches.coverPatches(curr.autoTextSize());
      return patches;
    }, new ArtboardPatchesClass());

    this.updateSingleArtboard(texts[0].ownerArtboardID, updateAutoSizeAttrPatches.coverPatches(sizePathes));
  };

  protected doSetRichValue(comps: UIComponent[], TextFormat: ITextFormat, type: string) {
    const template: ArtboardPatches = { do: {}, undo: {} };
    return comps.reduce((acc, curr) => {
      let richValue: string = '';
      if (typeof curr.value === 'string' && type === 'value') {
        richValue = curr.value;
      } else {
        richValue = curr.text;
      }
      const { do: compDo, undo } = curr.setRichValue(TextFormat, richValue, type);
      Object.keys(compDo).forEach((id) => {
        acc.do[id] = compDo[id];
        acc.undo[id] = undo[id];
      });
      return acc;
    }, template);
  }

  protected doSetProperty(
    comps: UIComponent[],
    propertyName: PropertyName,
    value: PropertyValue,
    childPropName?: string,
  ) {
    const template: ArtboardPatches = { do: {}, undo: {} };
    return comps.reduce((acc, curr) => {
      const { do: compDo, undo } = curr.setProperty(propertyName, depthClone(value), undefined, childPropName);

      // 转换patch：和默认值相同的字段的replace会转为remove
      Object.keys(compDo).forEach((id) => {
        // TODO 在curr里用 id 取到 uicomp 好一点
        // TODO 新获取defaultData方式，需要获取 uicomp 对象？
        const data = this.doc.getComponentByID(id);
        const libDefaultData = data ? getLibDefaultData(data) : undefined;

        acc.do[id] = libDefaultData
          ? compDo[id].map((operation) => {
              const { op, path } = operation;
              if (op === 'replace' && /^\.?\/properties/.test(path)) {
                const { value } = operation as ReplaceOperation<any>;
                try {
                  // 没有 libDefaultData 会报错
                  const equal = isEqual(getValueByPointer(libDefaultData, path), value);
                  return equal ? { op: 'remove', path } : operation;
                } catch (error) {
                  window.debug && console.error('[Simplify: set prop]: ', error);
                  return operation;
                }
              }

              return operation;
            })
          : compDo[id];

        acc.undo[id] = undo[id];
      });
      return acc;
    }, template);
  }

  private resetIconValueWithOriginFontFamily(value: IconValue, originValue: IconValue): IconValue {
    return resetIconValue(value, originValue.fontName);
  }

  protected doSetValue(comps: UIComponent[], value: IComponentValue, option?: { wrap?: boolean; size?: ISize }) {
    const artboardPatches: ArtboardPatches = { do: {}, undo: {} };
    const useDefaultWidth = comps.length === 1;
    return comps.reduce((acc, curr) => {
      const libData = curr.libData;
      let patches: ArtboardPatches | null;
      if (libData?.value?.setValue) {
        patches = libData.value.setValue(curr, value);
      } else {
        let _value = value;
        if (curr.type === CIcon) {
          _value = this.resetIconValueWithOriginFontFamily(value as IconValue, curr.value as IconValue);
        }
        patches = curr.setValue(_value, useDefaultWidth ? option : undefined);
      }
      if (patches) {
        Object.keys(patches.do).forEach((id) => {
          acc.do[id] = patches!.do[id];
          acc.undo[id] = patches!.undo[id];
        });
      }
      return acc;
    }, artboardPatches);
  }

  protected doSetText(comps: UIComponent[], text: string) {
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    return comps.reduce((acc, curr) => {
      if (curr.text !== text) {
        const p = curr.setText(text);
        acc.do[curr.id] = p.do[curr.id];
        acc.undo[curr.id] = p.undo[curr.id];
      }
      return acc;
    }, patches);
  }

  /**
   * 设置组件属性，自动忽略不支持该属性的组件, 当修改富文本组件testStyle.fontStyle.strike/underline时，直接修改文本
   * @param {PropertyName} propertyName
   * @param {PropertyValue} value
   * @param {string} childPropName 暂未使用，用于考虑多选组件时，部分属性修改的情况
   */
  setProperty(
    propertyName: PropertyName,
    value: PropertyValue,
    comps = this.selectedComponentList,
    childPropName?: string,
  ) {
    // 锁定组件不允许设置属性
    if (!comps.length) {
      return;
    }
    let artboardID = this.firstSelectedComponent!.ownerArtboardID;
    const selIDs = comps.map((comp) => comp.id);
    const polygonComps = comps.filter((comp) => comp.type === CPath);
    const needReselect = propertyName === PolygonPropertyName && polygonComps.length !== 0;
    const patches = this.doSetProperty(comps, propertyName, value, childPropName);
    // 图文选项卡调整高级编辑产生的patches
    if (this.isAdvancedEditor) {
      // AdvancedEditor的继承链条处于this的后面，无法直接as AdvancedEditor
      const advanceEditor = (this as unknown) as AdvancedEditor;
      const originContainer = advanceEditor.originContainer;
      if (originContainer.lib?.type === CImageTextTabs) {
        originContainer.libData?.editor?.specials?.onPatchesFixByAdvanceEdit?.(
          patches,
          advanceEditor.currentStateID,
          comps,
          propertyName,
          value,
        );
      }
    }

    this.updateSingleArtboard(artboardID, patches);
    needReselect && this.selectByIDs(selIDs, false);
  }

  /**
   * 更新子属性
   * @param {string} name 'xxx.xxx.xxx||xxx/xxx/xxx||xxx\xxx\xxx'
   * @param value
   */
  setSubProperty(name: string, value: any): void {
    if (!this.hasSelect() || this.hasSelectLockedComps) {
      return;
    }
    const comps = this.selectedComponentList;
    // eslint-disable-next-line no-useless-escape
    const pathList = name.replace(/[\\|\/]/g, '.').split('.');
    const propertyName = pathList[0];
    pathList.shift();
    const path = pathList.join('.');
    const patches: ArtboardPatches = { do: {}, undo: {} };
    comps.forEach((comp) => {
      let propValue = comp.properties[propertyName];
      if (propValue) {
        const newValue = cloneDeep(propValue);
        update(newValue, path, () => value);
        newValue.disabled = false;
        const compPatches = comp.setProperty(propertyName, newValue);
        coverPatches(patches, compPatches);
      }
    });
    this.updateSingleArtboard(comps[0].ownerArtboardID, patches);
  }

  setCompTextValue(comp: UIComponent, value: string, option: { wrap?: boolean; size?: ISize }) {
    let patches: ArtboardPatches | undefined = undefined;
    if (isTextType(comp.type)) {
      if (comp.value !== value) {
        patches = this.doSetValue([comp], value, option);
      }
    } else {
      if (comp.text !== value) {
        patches = this.doSetText([comp], value);
      }
    }
    if (patches) {
      this.updateSingleArtboard(comp.ownerArtboardID, patches);
      if ([CText, CParagraph].includes(comp.type)) {
        this.refreshParent(comp);
      }
    }
  }

  /**
   * 设置组件内容
   */
  setValue(type: string, value: IComponentValue, selComps?: UIComponent[]) {
    const comps = selComps || [...this.selectedComponents].filter((comp) => comp.type === type);
    if (!comps || !comps.length || this.hasSelectLockedComps) {
      return;
    }

    const patches = this.doSetValue(comps, value);
    const artboardID = comps[0].ownerArtboardID;
    this.updateSingleArtboard(artboardID, patches);
    comps.forEach((comp) => {
      if ([CText, CParagraph].includes(comp.type)) {
        this.refreshParent(comp);
      }
    });
  }

  setText(text: string) {
    if (this.hasSelectLockedComps) {
      return;
    }
    const patches: ArtboardPatches = this.doSetText(this.selectedComponentList, text);
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  setRemark(info?: IRemark, comp?: UIComponent) {
    const template: ArtboardPatches = { do: {}, undo: {} };
    const { selectedComponentList, activeArtboard } = this;
    let resource: UIComponent[] = [];
    if (comp) {
      resource = [comp];
    } else {
      resource = selectedComponentList;
    }
    const patches = resource.reduce((acc, curr) => {
      const p = curr.getRemarkPatch(info);
      acc.do[curr.id] = p.do[curr.id];
      acc.undo[curr.id] = p.undo[curr.id];
      return acc;
    }, template);

    this.updateSingleArtboard(comp?.ownerArtboardID || activeArtboard.artboardID, patches);
  }

  clearRemarks(comps: UIComponent[]) {
    const artboardPatches: PagePatches = {};
    const remarkManager = RefactorRemarkManager.getInstance(this.doc);
    comps.forEach((comp) => {
      const { id, ownerArtboardID } = comp;
      if (!artboardPatches[ownerArtboardID]) {
        artboardPatches[ownerArtboardID] = { do: {}, undo: {} };
      }
      const patchesID = comp.isArtboard ? 'self' : id;
      const { do: _do, undo } = comp.getRemarkPatch(undefined, !comp.isArtboard);
      artboardPatches[ownerArtboardID].do[patchesID] = _do[patchesID];
      artboardPatches[ownerArtboardID].undo[patchesID] = undo[patchesID];
      remarkManager?.removeRemark(id);
    });
    // this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
    this.update(artboardPatches);
  }

  setArtboardRemark(info?: IRemark) {
    const artboard = this.doc.mainArtboard;

    // 更新 remarkManager
    const remarkManager = RefactorRemarkManager.getInstance(this.doc)!;
    remarkManager.updateRemark(artboard.realID, artboard);
    this.updateSingleArtboard(artboard.realID, artboard.getRemarkPatch(info));
  }

  autoFillText() {
    if (this.hasSelectLockedComps) {
      return;
    }
    const textComps = this.selectedComponentList.filter((comp) => editorValidate.allowTextEditor(comp));
    if (textComps.length) {
      const template: ArtboardPatches = { do: {}, undo: {} };
      const patches = textComps.reduce((acc, comp) => {
        let opt: ArtboardPatches;
        let text = getParagraph();

        if ([CText, CTextArea, CInput, CParagraph].includes(comp.type)) {
          let option: CompValueSettingOption | undefined = undefined;
          if (comp.type === CText) {
            const { properties, size } = comp;
            const { multiText, textFormat, textStyle } = properties;
            text = comp.resetValueWithNewFontStyle(text, textFormat || { ...textStyle, ...(multiText || {}) });
            option = { wrap: true, size: { ...size }, autoFill: true };
            const wrap = textFormat?.wrap || multiText?.wrap;
            const vertical = textFormat?.vertical || multiText?.vertical;
            if (!wrap) {
              const propName = textFormat ? TextFormatExPropertyName : MultiTextPropertyName;
              const value = textFormat || multiText;
              const pt = comp.setProperty(propName, { ...(value || { disabled: false }), wrap: true });
              Object.keys(pt.do).forEach((id) => {
                if (template.do[id]) {
                  template.do[id].push(...pt.do[id]);
                  template.undo[id].push(...pt.undo[id]);
                } else {
                  template.do[id] = pt.do[id];
                  template.undo[id] = pt.undo[id];
                }
              });
              this.resetOptionSize(vertical, option, size);
            }
          }
          opt = comp.setValue(text, option);
        } else {
          opt = comp.setText(text);
        }
        // FIXME 自动填充的时候取消自动大小
        opt.do[comp.id].push({ op: 'replace', path: './autoSize', value: false });
        opt.undo[comp.id].push({ op: 'replace', path: './autoSize', value: comp.autoSize });
        opt.do[comp.id].push(Ops.replace('/textBehaviour', ETextBehaviour.Both));
        opt.undo[comp.id].push(Ops.replace('/textBehaviour', comp.toJSON().textBehaviour));
        Object.keys(opt.do).forEach((id) => {
          if (acc.do[id]) {
            acc.do[id].push(...opt.do[id]);
            acc.undo[id].push(...opt.undo[id]);
          } else {
            acc.do[id] = opt.do[id];
            acc.undo[id] = opt.undo[id];
          }
        });
        coverPatches(acc, this.getAutoFillTextSizePatches(comp));

        return acc;
      }, template);
      this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
    }
  }

  public autoFill(comps: UIComponent[], callback: (val?: string) => string) {
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    comps.forEach((comp) => {
      let opt: ArtboardPatches | undefined = comp.autoFill(callback);
      opt && mergePatches(patches, opt);
    });
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  /**
   * 对选中的组件判断并自动填充相应数据
   * @param fillType   当前填充类型
   * @param valueCallback   获取填充数据的回调函数, 接收value值, 如果 value传值则对比值是否相同
   */
  public autoFillContent(fillType: AutoFillType, valueCallback: (value?: string) => string, category?: string) {
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };

    if (fillType === AutoFillType.image) {
      this.selectedComponentList.forEach((comp) => {
        if (!comp.allowImgAutofill()) {
          return;
        }
        const opt = comp.autoFill(valueCallback);
        if (opt) {
          mergePatches(patches, opt);
        }
      });
    }

    if (fillType === AutoFillType.text) {
      this.selectedComponentList.forEach((comp) => {
        if (!comp.allowTextAutofill()) {
          return;
        }
        const opt = comp.autoFill(valueCallback, category);
        if (opt) {
          mergePatches(patches, opt);
        }
      });
    }
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  private resetOptionSize(vertical: undefined | boolean, option: CompValueSettingOption, size: IComponentSize) {
    if (vertical) {
      option.size!.height = Math.max(size.height, 2300);
    } else {
      option.size!.width = Math.max(size.width, 300);
    }
  }

  getAutoFillTextSizePatches(comp: UIComponent): ArtboardPatches {
    const result: ArtboardPatches = {
      do: {},
      undo: {},
    };
    (result.do[comp.id] || (result.do[comp.id] = [])).push(Ops.replace('./autoSize', false));
    (result.undo[comp.id] || (result.undo[comp.id] = [])).push(Ops.replace('./autoSize', comp.autoSize));

    return result;
  }

  setComponentValue(comp: UIComponent, value: IComponentValue, option?: { wrap?: boolean; size?: ISize }) {
    const patches = this.doSetValue([comp], value, option);
    this.updateSingleArtboard(comp.ownerArtboardID, patches);
    if ([CText, CParagraph].includes(comp.type)) {
      this.refreshParent(comp);
    }
  }

  private refreshParent = (comp: UIComponent) => {
    if (comp.parent) {
      if (comp.parent instanceof UIStackPanelComponent || comp.parent instanceof UIWrapPanelComponent) {
        comp.parent.refreshComponents();
      }
    }
    const ownerSealedCom = comp.nearestSealedComponent;
    if (ownerSealedCom) {
      if (ownerSealedCom instanceof UIStackPanelComponent || ownerSealedCom instanceof UIWrapPanelComponent) {
        ownerSealedCom.refreshComponents();
      }
    }
  };

  /**
   * 交互小圆点探索到画板，是添加内容面板或辅画板交互
   */
  doSetContentPanelRef(artboardID: string): boolean {
    if (this.hasOnlyOneSelectedComponents) {
      const comp = this.firstSelectedComponent;
      if (comp && (comp.type === CContentPanel || comp.type === CContentPanelV2)) {
        this.setContentPanelRefValue(artboardID);
        return true;
      }
    }
    return false;
  }

  /**
   * 设置内容面板引用页面
   * @param {string} refValue
   */
  setContentPanelRefValue(refValue: string) {
    if (this.hasOnlyOneSelectedComponents && !this.hasSelectLockedComps) {
      const comp = this.firstSelectedComponent;
      if (comp) {
        let value: string[];
        if (comp.value) {
          value = comp.value as string[];
        } else {
          value = [];
        }
        const container = comp as UIContainerComponent;
        if (value.length !== container.components.length) {
          value = container.components.map((c) => `${c.value}`);
        }
        if (value.indexOf(refValue) === -1) {
          value.push(refValue);
          const { patches } = container.addComponents([
            makeCanvas(getNewID(), {
              components: [],
              properties: {
                container: {
                  showScroll: true,
                  scroll: true,
                  disabled: false,
                  hidden: true,
                },
              },
              value: refValue,
              selected: value.length === 1,
            }),
          ]);
          const p = comp.setValue(value);

          Object.keys(p.do).forEach((id) => {
            if (patches[comp.ownerArtboardID].do[id]) {
              patches[comp.ownerArtboardID].do[id].push(...p.do[id]);
              patches[comp.ownerArtboardID].undo[id].push(...p.undo[id]);
            } else {
              patches[comp.ownerArtboardID].do[id] = p.do[id];
              patches[comp.ownerArtboardID].undo[id] = p.undo[id];
            }
          });
          this.update(patches);
        }
      }
    }
  }

  /**
   * 删除内容面板的一个层
   * @param {UIContainerComponent} group
   * @param {string} refID
   */
  removeContentPanelItem(group: UIContainerComponent, refID: string) {
    if (this.hasSelectLockedComps) {
      return;
    }
    //放置splice修改引用类型导致错误
    const values: string[] = [...((group.value as string[]) ?? [])];
    const comps = group.components;
    const removeComps: UIComponent[] = [];
    const otherComps: UIComponent[] = [];
    comps.forEach((comp) => {
      if (comp.value === refID) {
        const refIndex = values.indexOf(refID);
        values.splice(refIndex, 1);
        removeComps.push(comp);
      } else {
        otherComps.push(comp);
      }
    });
    const { patches } = group.removeComponents(removeComps);
    const artboardID = group.ownerArtboardID;
    const groupID = group.id;
    const valuePatches = group.setValue([...values]);
    patches[artboardID].do[groupID].push(...valuePatches.do[groupID]);
    patches[artboardID].undo[groupID].push(...valuePatches.undo[groupID]);
    if (otherComps.length) {
      let selComp = otherComps.find((comp) => comp.selected);
      if (!selComp) {
        selComp = otherComps[0];
      }
      patches[artboardID].do[selComp.id] = [Ops.replace('/selected', true)];
      patches[artboardID].undo[selComp.id] = [Ops.replace('/selected', false)];
    }
    this.update(patches);
  }

  /**
   * 设置component.value，及尺寸（如果需要的话）
   * @param {string} url
   * @param {ISize} size
   */
  setImageCompSize(url: string, size?: ISize, fileName?: string) {
    if (this.hasSelectLockedComps) {
      return;
    }
    const comps = [...this.selectedComponents];
    if (!comps || !comps.length) {
      return;
    }
    const patches = comps.reduce(
      (acc, curr) => {
        let comp: UIComponent | null;
        // 如果该属性是引用的属性，需要编辑源，而不是该组件自身
        const isRef = curr.isRefValue();
        if (isRef) {
          comp = curr.nearestSealedComponent;
        } else {
          comp = curr;
        }
        if (!comp) {
          return acc;
        }

        const opt = comp.setValue(url);
        //自适应尺寸的组件，如果内容是文本时，根据文本内容进行测量尺寸
        if (size) {
          const sizeOpt = comp.setSize({ ...size, lockedRatio: comp.size.lockedRatio });
          opt.do[curr.id].push(...sizeOpt.do);
          opt.undo[curr.id].push(...sizeOpt.undo);
        }
        // 设置文件名为组件名
        if (comp && comp.type === CAudio && fileName) {
          this.setGeneralProperties('name', fileName, comp);
        }
        return {
          do: {
            ...acc.do,
            [comp.id]: opt.do[comp.id],
          },
          undo: {
            ...acc.undo,
            [comp.id]: opt.undo[comp.id],
          },
        };
      },
      {
        do: {},
        undo: {},
      },
    );
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  /**
   * 设置快照信息，及尺寸（如果需要的话）
   * @param {ISnapshotUpdateInfo} updateInfo
   * @param {ISize} compSize
   * @param {string} compID
   */
  setSnapshotCompInfo(updateInfo: ISnapshotValue, compSize?: ISize, compID?: string) {
    const fromConfirmUpdateInfo = compID ? [this.activeContainer.components.find((comp) => comp.id === compID)] : [];
    const comps = [...this.selectedComponents];
    // 当前没有选中的快照组件时，则取设计稿对应的快照组件
    const targetComps = comps.length > 0 ? comps : fromConfirmUpdateInfo;

    const patches = targetComps.reduce(
      (acc, curr) => {
        let comp: UIComponent | null;
        // 如果该属性是引用的属性，需要编辑源，而不是该组件自身
        const isRef = curr!.isRefValue();
        if (isRef) {
          comp = curr!.nearestSealedComponent;
        } else {
          comp = curr!;
        }
        if (!comp) {
          return acc;
        }
        const opt = comp.setValue(updateInfo);
        //自适应尺寸的组件，如果内容是文本时，根据文本内容进行测量尺寸
        if (compSize) {
          const sizeOpt = comp.setSize({ ...compSize, lockedRatio: comp.size.lockedRatio });
          opt.do[curr!.id].push(...sizeOpt.do);
          opt.undo[curr!.id].push(...sizeOpt.undo);
        }
        return {
          do: {
            ...acc.do,
            [comp.id]: opt.do[comp.id],
          },
          undo: {
            ...acc.undo,
            [comp.id]: opt.undo[comp.id],
          },
        };
      },
      {
        do: {},
        undo: {},
      },
    );
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  selectedChange(targets: UIComponent[], value: boolean) {
    const comps = targets.filter((comp) => {
      return comp.select && comp.select.target === 'self';
    });
    if (!comps.length) {
      return;
    }
    const template: ArtboardPatches = { do: {}, undo: {} };
    const patches = comps.reduce((acc, curr) => {
      // 忽略自动大小组件对尺寸的设置
      if (curr.selected === value) {
        return acc;
      }
      const opt: ArtboardPatches = curr.changeSelfSelected(value);
      coverPatches(acc, opt);
      return acc;
    }, template);
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  setImage(url: string, boundsOffset: IBoundsOffset) {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    const comp = this.firstSelectedComponent!;
    const {
      id,
      size: { lockedRatio },
      properties: { image },
    } = comp;
    coverPatches(patches, comp.setValue(url));
    comp.size.lockedRatio = false;
    const resizePatches = this.activeContainer.resizeChildren([comp], { offset: boundsOffset }, { shift: false });
    coverPatches(patches, resizePatches);
    if (lockedRatio) {
      patches.do[id].push(Ops.replace('./size/lockedRatio', lockedRatio));
    }
    if (image?.clipBounds) {
      patches.do[id].push(Ops.replace('./properties/image/clipBounds', undefined));
      patches.undo[id].push(Ops.replace('./properties/image/clipBounds', image.clipBounds));
    }
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  copyImageFromCropSource(url: string, boundsOffset: IBoundsOffset, name?: string) {
    const comp = this.firstSelectedComponent!;
    const { size, properties, position, rotate } = comp;
    const { width, height, lockedRatio } = size;
    const { left: l, top: t, bottom: b, right: r } = boundsOffset;
    const newSize = {
      lockedRatio,
      width: width - l + r,
      height: height - t + b,
    };
    const imgData = makeImageComponent(url, name || '', newSize);

    let newPosition = {
      x: position.x + l,
      y: position.y + t,
    };
    // 把新图层中心点旋转后，再计算新的坐标点
    let newCenter = {
      x: newPosition.x + newSize.width / 2,
      y: newPosition.y + newSize.height / 2,
    };
    const sourceCenter = {
      x: position.x + width / 2,
      y: position.y + height / 2,
    };
    newCenter = rotatePoint(newCenter, sourceCenter, rotate);
    newPosition.x = newCenter.x - newSize.width / 2;
    newPosition.y = newCenter.y - newSize.height / 2;
    imgData.position = newPosition;
    imgData.properties = cloneDeep(properties);
    if (imgData.properties.image) {
      imgData.properties.image.clipBounds = undefined;
    }
    imgData.rotate = rotate;
    const parent = comp.parent!;
    const patches = parent.addComponents([imgData]);
    this.update(patches.patches);
    this.selectByIDs([imgData._id], false);
  }

  sliceImage(imgSlices: IImgSlice[]) {
    // 被分割的图片组件
    const sourceImgComp = this.firstSelectedComponent!;
    const sourceImgCompParent = sourceImgComp.parent!;
    const { rotate, position, lockedRatio, size } = sourceImgComp;

    const comps = imgSlices.map(({ url, name, bounds }) => {
      const newSize = {
        width: bounds.width,
        height: bounds.height,
        lockedRatio,
      };
      const comp = makeImageComponent(url, name, newSize);

      let newPosition = {
        x: bounds.left,
        y: bounds.top,
      };
      // 把新图层中心点旋转后，再计算新的坐标点
      let newCenter = {
        x: newPosition.x + newSize.width / 2,
        y: newPosition.y + newSize.height / 2,
      };
      const sourceCenter = {
        x: position.x + size.width / 2,
        y: position.y + size.height / 2,
      };
      newCenter = rotatePoint(newCenter, sourceCenter, rotate);
      newPosition.x = newCenter.x - newSize.width / 2;
      newPosition.y = newCenter.y - newSize.height / 2;

      comp.position = newPosition;
      // 默认相对自己中心旋转
      comp.rotate = rotate;

      return comp;
    });

    const sourceImgCompOwnerArtboardId = sourceImgComp.ownerArtboardID;
    const removeSourceImgCompPatches = sourceImgCompParent.removeComponents([sourceImgComp]).patches[
      sourceImgCompOwnerArtboardId
    ];
    const addCompsPatches = sourceImgCompParent.addComponents(comps).patches[sourceImgCompOwnerArtboardId];

    const sliceImagePatches: PagePatches = {
      [sourceImgCompOwnerArtboardId]: mergePatches(removeSourceImgCompPatches, addCompsPatches),
    };

    this.update(sliceImagePatches);
    this.selectByIDs(
      comps.map(({ _id }) => _id),
      false,
    );
  }

  /**
   * 设置通用属性，该部分属性不在properties中
   * @param {GeneralPropertyName} name
   * @param {string | number | boolean | ISize | ILayout} value
   */
  setGeneralProperties(
    name: GeneralPropertyName,
    value: string | number | boolean | ISize | ILayout,
    comp?: UIComponent,
  ) {
    this.setGeneralPropertiesFromPatches(name, value, { do: {}, undo: {} }, comp);
  }

  changingOpacity(value: number) {
    this.selectedComponentList.forEach((comp) => {
      !comp.locked && comp.changingOpacity(value);
    });
  }

  /**
   * 设置通用属性，该部分属性不在properties中
   * @param {GeneralPropertyName} name
   * @param {string | number | boolean | ISize | ILayout} value
   */
  setGeneralPropertiesFromPatches(
    name: GeneralPropertyName,
    value: string | number | boolean | ISize | ILayout,
    template: ArtboardPatches,
    comp?: UIComponent,
  ) {
    let target: UIComponent[] = [];
    if (comp) {
      target = [comp];
    } else {
      target = [...this.selectedComponentList];
    }

    // const template: ArtboardPatches = { do: {}, undo: {} };
    const patches: ArtboardPatches = target.reduce((acc, curr) => {
      // 忽略自动大小组件对尺寸的设置
      if (name === 'size' && curr.autoSize) {
        return acc;
      }
      // 忽略屏蔽了的disabled,selected状态
      if (!getSupportStates(curr).find((c) => c.type === name) && ['disabled', 'selected'].includes(name)) {
        return acc;
      }
      const opt = curr.modifyGeneralProperties(name, value);
      Object.keys(opt.do).forEach((id) => {
        acc.do[id] = opt.do[id];
        acc.undo[id] = opt.undo[id];
      });
      return acc;
    }, template);
    this.updateSingleArtboard(comp?.ownerArtboardID || this.activeArtboard.artboardID, patches);
  }

  setComponentGeneralProperties(
    comp: UIComponent,
    name: GeneralPropertyName,
    value: string | number | boolean | ISize | ILayout,
  ) {
    if (comp.locked && name !== 'name') {
      return;
    }
    const patches = this.getChangeCompGeneralPropertiesPatches(comp, name, value);
    this.updateSingleArtboard(comp.ownerArtboardID, patches);
  }

  /**
   * svg转换
   * @param comps
   */
  transformSvg(comps: UIComponent[]): TransformSvg {
    const taskList: Function[] = [];
    let isCancel = false;
    const execute = async () => {
      try {
        const promiseList = comps.map((comp) => {
          const parse = new ParseSvg(comp);
          taskList.push(() => parse.abort());
          return parse.parseSvg();
        });

        const validateList = await Promise.all(promiseList).then((res) => {
          return res.reduce((prev, item, index) => {
            if (item) {
              prev.push({ add: item, remove: comps[index] });
            }
            return prev;
          }, [] as { add: IComponentData; remove: UIComponent }[]);
        });

        if (isCancel) {
          return false;
        }

        // 后面都为同步任务，无法拦截
        const patches: ArtboardPatches = { do: {}, undo: {} };
        const { activeContainer: container, activeArtboard } = this;
        const activeArtboardID = activeArtboard.artboardID;

        let newIds: string[] = [];
        if (container instanceof UIGroupComponent) {
          // 组内转换，获取所有新增删除数据，统一生成patches
          const addList: IComponentData[] = [];
          const removeList: UIComponent[] = [];
          validateList.forEach((item) => {
            addList.push(item.add);
            removeList.push(item.remove);
            newIds.push(item.add._id);
          });
          const replacePatches = container.replaceComponents(addList, removeList).patches[activeArtboardID];
          mergePatches(patches, replacePatches);
        } else {
          patches.do['ROOT'] = [];
          patches.undo['ROOT'] = [];

          const doPatches = patches.do['ROOT'];
          const undoPatches = patches.undo['ROOT'];
          const removeComps: UIComponent[] = [];
          const undoRemoveIds: string[] = [];
          // mergePatches耗时太长导致崩溃，add不使用mergePatches
          validateList.forEach((item) => {
            const index = this.activeContainer.components.findIndex((c) => c.id === item.remove.id);
            doPatches.push(Ops.addChildren(`${index}`, [item.add]));
            undoRemoveIds.push(item.add._id);
            removeComps.push(item.remove);
            newIds.push(item.add._id);
          });
          undoPatches.push(Ops.removeChildren(undoRemoveIds));
          // 移除patches
          const removePatches = container.removeComponents(removeComps).patches[activeArtboardID];
          mergePatches(patches, removePatches);
        }

        this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
        this.selectByIDs(newIds, false, true);
        return true;
      } catch (error) {
        console.error(error);
        throw new Error(error);
      }
    };

    // 取消，只能取消解析过程
    const abort = () => {
      taskList.forEach((task) => task());
      isCancel = true;
    };

    return {
      execute,
      abort,
    };
  }

  getChangeCompGeneralPropertiesPatches(
    comp: UIComponent,
    name: 'name' | 'size' | 'rotate' | 'hidden' | 'opacity' | 'disabled' | 'selected',
    value: string | number | boolean | ISize | ILayout,
  ) {
    const patches = comp.modifyGeneralProperties(name, value);
    const ownComp = comp.nearestSealedComponent;
    if (name === 'selected' && ownComp && ownComp instanceof UISelectPanelComponent) {
      const p = ownComp.switchSelectState(comp, !!value);
      coverPatches(patches, p);
    }
    return patches;
  }

  /**
   * 路径编辑修改，剪刀剩余部分为新增，变成非闭合路径时，调整描边属性为居中
   * 处理基元组件路径编辑后转换为路径
   */
  applyPathData(data: IPathValue, bounds: IBounds, position: IPosition, offcut?: IComponentData) {
    if (this.hasOnlyOneSelectedComponents) {
      const comp = this.firstSelectedComponent!;
      if (isBasicComp(comp.type)) {
        let patches: ArtboardPatches = { do: {}, undo: {} };
        const container = this.activeContainer;
        const activeArtboardID = this.activeArtboard.artboardID;
        const newSelectedID: Array<string> = [];
        if (comp.type === CPath) {
          const valuePatches = comp.setValue(data);
          patches = comp.parent!.getPositionPatchesOfChildrenChanged(
            [
              {
                id: comp.id,
                type: ComponentChangeType.Edit,
                size: { width: bounds.width, height: bounds.height },
                position: position,
                rotate: comp.rotate,
                value: data,
              },
            ],
            true,
          ).patches;
          coverPatches(patches, valuePatches);
          // 清理lib
          if (comp.lib) {
            const clearLibPatches: ArtboardPatches = {
              do: { [comp.id]: [Ops.remove('./lib')] },
              undo: { [comp.id]: [Ops.add('./lib', comp.lib)] },
            };
            coverPatches(patches, clearLibPatches);
          }
          // isTemplateShape类型被修改后，后续都已实际的pathValue为实际值
          if ((comp as UIShapeComponent).isTemplateShape) {
            const replaceTemplatePatches: ArtboardPatches = {
              do: { [comp.id]: [Ops.replace('./value/isTemplateShape', false)] },
              undo: { [comp.id]: [Ops.replace('./value/isTemplateShape', true)] },
            };
            coverPatches(patches, replaceTemplatePatches);
          }
        } else {
          const compData = makeComponent('basic', CPath, '')!;
          const {
            id,
            text,
            rotate,
            remark,
            opacity,
            flip,
            size,
            hidden,
            layout,
            interactions,
            states,
            properties,
            float,
            disabled,
          } = comp;
          compData._id = id;
          compData.name = comp.name || i18n('resource.components.path');
          compData.value = data;
          compData.position = position;
          compData.text = text;
          compData.rotate = rotate;
          compData.size = { ...size, width: bounds.width, height: bounds.height };
          compData.remark = remark;
          compData.opacity = opacity;
          compData.float = float;
          compData.flip = depthClone(flip);
          compData.hidden = hidden;
          compData.layout = depthClone(layout);
          const cloneInteraction = depthClone(interactions);
          // 重置交互id
          const events = Object.keys(cloneInteraction);
          events.forEach((event) =>
            cloneInteraction[event].actions.forEach((item) => {
              item.target === comp.id && (item.target = compData._id);
            }),
          );

          compData.interaction = cloneInteraction;

          compData.states = depthClone(states) as { [key: string]: IComponentState };
          compData.disabled = disabled;
          Object.keys(compData.states).forEach((key) => {
            const { properties: stateProperties } = compData.states[key];
            if (stateProperties) {
              delete stateProperties.radius;
              delete stateProperties.border;
              delete stateProperties.polygon;
              delete stateProperties.line;
            }
          });
          const { fill, shadow, stroke, padding, textFormat, line } = properties;
          shadow && (compData.properties.shadow = getProxyTargetValue(shadow));
          stroke && (compData.properties.stroke = getProxyTargetValue(stroke));
          padding && (compData.properties.padding = getProxyTargetValue(padding));
          textFormat && (compData.properties.textFormat = getProxyTargetValue(textFormat));
          fill && (compData.properties.fill = getProxyTargetValue(fill));
          line && (compData.properties.line = getProxyTargetValue(line));
          if (comp.type === CLine && compData.properties.fill) {
            compData.properties.fill.disabled = true;
          }
          if (compData.properties.stroke) {
            compData.properties.stroke.position = stroke?.position || StrokePosition.inner;
          }
          if (container instanceof UIGroupComponent || container instanceof UICompoundPathComponent) {
            patches = container.replaceComponents([compData], [comp]).patches[activeArtboardID];
          } else {
            const index = container.components.findIndex((c) => c.id === comp.id);
            const removePatches = container.removeComponents([comp]).patches[activeArtboardID];
            coverPatches(patches, removePatches);
            const addPatches = container.addComponents([compData], index).patches[activeArtboardID];
            coverPatches(patches, addPatches);
            sortPatches(patches);
          }
          newSelectedID.push(compData._id);
        }

        if (offcut) {
          if (container.type === CCompoundPath) {
            const valuePatches = comp.setValue(data);
            patches = (container as UICompoundPathComponent).getPatchesOfChildrenChangedAndAdd(
              [
                {
                  id: comp.id,
                  type: ComponentChangeType.Edit,
                  size: { width: bounds.width, height: bounds.height },
                  position: position,
                  rotate: comp.rotate,
                  value: data,
                },
              ],
              [offcut],
            ).patches;
            coverPatches(patches, valuePatches);
          } else {
            const { patches: pagePatches } = container.addComponents([offcut]);
            const addPatches = pagePatches[activeArtboardID];
            coverPatches(patches, addPatches);
          }
        }

        // 更新复合组件FIXME:
        this.updateSingleArtboard(comp.ownerArtboardID, patches);
        if (newSelectedID.length) {
          this.selectByIDs(newSelectedID, false);
        }
      }
    }
  }

  flipComponent(flipModel: IFlipModel, value: boolean) {
    const selectCompCount = this.selectedComponentList.length;
    if (!selectCompCount) {
      return;
    }
    const comps = this.selectedComponentList;
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    const selIDs = comps.map((comp) => comp.id);
    const posPatches = getPatchesWhenFlip(comps, flipModel, value);
    coverPatches(patches, posPatches);

    // 增加翻转属性的 patches
    coverPatches(patches, getModifyFlipAttrPatches(comps, flipModel, value));

    if (this.activeContainer.type === CCompoundPath) {
      const change = extractDynamicInfoFromPatch(posPatches);
      const newComps = depthClone(this.activeContainer.components.map((comp) => comp.toJSON()));
      Object.keys(change).forEach((id) => {
        const comp = newComps.find((item) => item._id === id);
        if (comp) {
          comp!.position = change[id].position || comp!.position;
          comp!.size = change[id].size || comp!.size;
          comp!.value = change[id].value || comp!.value;
          comp!.rotate = change[id].rotate || comp!.rotate;
        }
      });
      const { newActiveGroup, patches: newPatches } = (this
        .activeContainer as UICompoundPathComponent).refreshPatchesWithNewChildren(newComps);
      if (newActiveGroup && newActiveGroup.id !== this.activeContainer.id) {
        // 如果当前容器被删除了，那么久不合并patches
        patches.do = newPatches.do;
        patches.undo = newPatches.undo;
        this.setActiveContainer(newActiveGroup);
      } else {
        mergePatches(patches, newPatches);
      }
    }
    this.update({
      [this.activeArtboard.artboardID]: patches,
    });
    this.selectByIDs(selIDs, false);
  }

  quickSetFontStyle(styleName: 'bold' | 'italic' | 'underline' | 'strike', textComps?: UIComponent[]) {
    const comps = (textComps ?? this.selectedComponentList).filter(
      (comp) => !!comp.properties.textStyle || !!comp.properties.textFormat,
    );
    if (!comps.length) {
      return;
    }
    const initialPatches: ArtboardPatches = { do: {}, undo: {} };
    const patches = comps.reduce((acc, comp) => {
      const {
        properties: { textFormat, textStyle },
      } = comp;
      const fontStyle = (textFormat || textStyle)?.fontStyle || {};
      const newFontStyle = {
        ...fontStyle,
        [styleName]: !fontStyle[styleName],
      };

      let compPatches: ArtboardPatches = { do: {}, undo: {} };
      if (textFormat) {
        compPatches = comp.setProperty(TextFormatExPropertyName, { ...textFormat, fontStyle: newFontStyle });
      } else {
        compPatches = comp.setProperty(TextPropertyName, { ...textStyle, fontStyle: newFontStyle });
      }

      Object.keys(compPatches.do).forEach((id) => {
        if (!acc.do[id]) {
          acc.do[id] = compPatches.do[id];
          acc.undo[id] = compPatches.undo[id];
        } else {
          acc.do[id].push(...compPatches.do[id]);
          acc.undo[id].push(...compPatches.undo[id]);
        }
      });
      return acc;
    }, initialPatches);

    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  quickSetFontSize(stepModel: 'plus' | 'less', textComps?: UIComponent[]) {
    const comps = (textComps ?? this.selectedComponentList).filter(
      (comp) => !!comp.properties.textStyle || !!comp.properties.textFormat,
    );
    if (!comps.length) {
      return;
    }
    const template: ArtboardPatches = { do: {}, undo: {} };
    const patches = comps.reduce((acc, curr) => {
      const { properties } = curr;
      const { textStyle, textFormat } = properties;
      let fontSize = (textFormat || textStyle)?.fontSize || DefaultFontSize;
      if (stepModel === 'plus') {
        fontSize = Math.min(300, fontSize + 1);
      } else {
        fontSize = Math.max(MinFontSize, fontSize - 1);
      }
      let patches: ArtboardPatches = { do: {}, undo: {} };
      if (textFormat) {
        patches = curr.setProperty(TextFormatExPropertyName, { ...textFormat, fontSize });
      } else {
        patches = curr.setProperty(TextPropertyName, { ...textStyle, fontSize });
      }

      Object.keys(patches.do).forEach((id) => {
        if (!acc.do[id]) {
          acc.do[id] = patches.do[id];
          acc.undo[id] = patches.undo[id];
        } else {
          acc.do[id].push(...patches.do[id]);
          acc.undo[id].push(...patches.undo[id]);
        }
      });
      return acc;
    }, template);
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  changingProperty(name: PropertyName, value: PropertyValue, components = this.selectedComponentList) {
    components.forEach((comp) => comp.changingProperty(name, value));
    // this.notifyUpdateListener();
  }

  changeComponentsBounds(info: { id: string; bounds: IBounds; movedBounds: IBounds }[]) {
    const artboardPatch: ArtboardPatches = {
      do: {},
      undo: {},
    };
    info.forEach((item) => {
      const comps = this.activeContainer.components;
      const comp = comps.find((c) => c.id === item.id)!;
      const { position, id } = comp;
      const offset = {
        x: item.movedBounds!.left - item.bounds.left,
        y: item.movedBounds!.top - item.bounds.top,
      };
      if (!(sameNumber(offset.x, 0) && sameNumber(offset.y, 0))) {
        const newPosition = {
          x: position.x + offset.x,
          y: position.y + offset.y,
        };
        artboardPatch.do[id] = [Ops.replace('./position', roundNumberObject(newPosition))];
        artboardPatch.undo[id] = [Ops.replace('./position', position)];
      }
    });
    if (Object.keys(artboardPatch.do).length) {
      this.updateSingleArtboard(this.activeArtboard.artboardID, artboardPatch);
    } else {
      // 未有提交时，对于受影响的组件，修改下版本号，重新刷新
      const infoIDs = info.map((item) => item.id);
      this.selectedComponentList.forEach((item) => {
        if (infoIDs.includes(item.id)) {
          ++item.toJSON().v;
        }
      });
      this.select(this.selectedComponentList, false);
    }
  }

  /**
   * 将选中组件转换成面板
   * 1、右键
   * 2、快捷键-暂无
   */
  convertToPanel() {
    if (!this.canConvertToPanel) {
      return;
    }
    const bounds = getViewBoundsOfComponents(this.selectedComponentList);
    if (!bounds) {
      return;
    }
    const { left, top, width, height } = bounds;
    const canvasData = depthClone(DefaultCanvasData);
    canvasData.position = { x: left, y: top };
    canvasData.size = { width, height };
    try {
      // FIXME 除非常量被人修改，不然不会报错
      canvasData.properties!.stroke!.disabled = true;
      canvasData.properties!.fill!.disabled = true;
    } catch (err) {
      window.debug && console.log('[convert to panel] ', err);
    }
    canvasData.name = i18n('resource.components.canvasPanel') + sessionOptions.convertCanvasPanelCount;
    sessionOptions.convertCanvasPanelCount = sessionOptions.convertCanvasPanelCount + 1;

    const comps = this.activeContainer.components;

    canvasData.components = this.selectedComponentList
      .concat([])
      .sort((a: UIComponent, b: UIComponent) => {
        // 保持图层顺序排列
        const aIndex = comps.indexOf(a);
        const bIndex = comps.indexOf(b);
        return aIndex - bIndex;
      })
      .map((c) => c.resetPositionWhenGroup(bounds));

    const panelData = makeCanvas(getNewID(), canvasData);
    const oldComponents = this.selectedComponentList.map((c) => c.$data);
    const patches: ArtboardPatches = {
      do: {
        [this.activeContainer.id]: [
          Ops.removeChildren(oldComponents.map((c) => c._id)),
          Ops.addChildren('-1', [panelData]),
        ],
      },
      undo: {
        [this.activeContainer.id]: [Ops.removeChildren([panelData._id]), Ops.addChildren('-1', oldComponents)],
      },
    };
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
    this.selectByIDs([panelData._id], false);
  }

  /**
   * 从面板中脱离
   */
  detachPanel() {
    if (!this.canDetachPanel) {
      return;
    }
    const { oldData, newData } = this.selectedComponentList.reduce(
      (prev, curr) => {
        const { type, $data, components } = curr as UIContainerComponent;
        if (type !== CCanvasPanel) {
          return prev;
        }
        const newComponentsData = components.map((c) => {
          return {
            ...c.$data,
            position: c.getPositionWithoutParent(),
            rotate: c.rotateRelativeToArtboard,
          };
        });
        prev.oldData.push($data);
        prev.newData.push(...newComponentsData);
        return prev;
      },
      {
        oldData: [] as IComponentData[],
        newData: [] as IComponentData[],
      },
    );
    const patches: ArtboardPatches = {
      do: {
        [this.activeContainer.id]: [Ops.removeChildren(oldData.map((c) => c._id)), Ops.addChildren('-1', newData)],
      },
      undo: {
        [this.activeContainer.id]: [Ops.removeChildren(newData.map((c) => c._id)), Ops.addChildren('-1', oldData)],
      },
    };
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
    this.selectByIDs(
      newData.map((c) => c._id),
      false,
    );
  }

  changeFixContent(value?: boolean) {
    // 有一个是非固定文本就都设置成固定文本
    const newValue = value || this.selectedComponentList.some((c) => !c.fixContent && c.canFixContent);
    const patches = this.selectedComponentList.reduce(
      (prev, comp) => {
        const {
          id,
          fixContent,
          canFixContent,
          states,
          $data: { value: text, properties },
          autoSize,
          size: originSize,
        } = comp;
        if (canFixContent) {
          prev.do[id] = [Ops.replace('/fixContent', newValue)];
          prev.undo[id] = [Ops.replace('/fixContent', fixContent)];
        }
        if (autoSize) {
          Object.keys(states).forEach((stateID) => {
            const state = states[stateID] || {};
            const textFormat = state.properties?.textFormat;
            const parser = StyleHelper.initCSSStyleParser((textFormat && state.properties) || properties);
            const newSize = measureTextSize(parser.getTextStyle(), `${(!newValue && state.value) || text}`, {
              wrap: textFormat?.wrap,
              isMultiText: true,
            });
            const size = state.size || originSize;
            if (!isEqual(newSize, size)) {
              prev.do[id].push(Ops.add(`/states/${stateID}/size`, newSize));
              prev.undo[id].push(Ops.replace(`/states/${stateID}/size`, size));
            }
          });
        }
        return prev;
      },
      {
        do: {},
        undo: {},
      } as ArtboardPatches,
    );
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }
}
