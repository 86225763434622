import { EventTypes } from '@fbs/rp/models/event';
import { PageOperations } from '@fbs/rp/utils/patch';
import { IFragmentAction } from '@fbs/rp/models/interactions';

import Doc from '@editor/document';
import { UIComponent, UIFragment } from '@editor/comps';

import { IWorker, IWorkerExtensionFeature, IWorkerManager, IWorkManagerBase, DocTree } from './types';
import { ParallelWorker, SequenceWorker } from './Worker';
import INode, { INodeWithChildren } from '@/fbs/rp/models/node';

export default class WorkerManager implements IWorkerManager, IWorkManagerBase {
  private workerMap: Map<string, IWorker> = new Map<string, IWorker>();
  public fragmentCommandMap: Map<string, IFragmentAction> = new Map<string, IFragmentAction>();
  onFinish?: () => void;

  private _doc: Doc;
  private _docTree: DocTree;
  private _extensionFeature: IWorkerExtensionFeature = {};
  private _termination: boolean = false;

  private _allPageNodes: INodeWithChildren[] = [];

  private name?: string;

  constructor(doc: Doc, docTree: DocTree, appNodes: INode[]) {
    this._doc = doc;
    this._docTree = docTree;
    this.expandAllPageNodes(appNodes);
  }

  private expandAllPageNodes(appNodes: INode[]) {
    const flatNodes = (node: INodeWithChildren) => {
      // 忽略删除的页面，只记录正常页面
      if (node.state !== 0) {
        return;
      }
      if (node.type === 'page') {
        this._allPageNodes.push(node);
      }
      if (node.children?.length) {
        node.children.forEach(flatNodes);
      }
    };
    appNodes.forEach((node) => {
      flatNodes(node as INodeWithChildren);
    });
  }

  setName(value: string) {
    this.name = value;
  }

  get doc() {
    return this._doc;
  }

  get docTree() {
    return this._docTree;
  }

  get extensionFeature(): IWorkerExtensionFeature {
    return this._extensionFeature;
  }

  // public refreshDocTree(docTree: DocTree) {
  //   this._docTree = docTree;
  // }

  public createWorker(trigger: UIComponent, event: EventTypes): IWorker | null {
    const handle = trigger.interactions[event];
    if (!handle) {
      return null;
    }
    if (handle.parallel) {
      return new ParallelWorker(this, trigger, event);
    } else {
      return new SequenceWorker(this, trigger, event);
    }
  }

  private getWorker(trigger: UIComponent, event: EventTypes): IWorker | null {
    // const handle = trigger.interactions[event];
    const id = trigger instanceof UIFragment ? trigger.artboardID : trigger.id;
    const key = `${id}-${event}`;
    let worker: IWorker | null = this.workerMap.get(key) || null;
    if (!worker) {
      worker = this.createWorker(trigger, event);
      if (worker) {
        worker.onFinish = this.handleWorkerFinish;
        worker.init();
        this.workerMap.set(key, worker);
      }
    } else {
      worker.refreshCommands();
    }
    return worker;
  }

  private handleWorkerFinish = () => {
    if (this.onFinish) {
      this.onFinish();
    }
  };

  private handleWorkerExtensionWorkerStart = (worker: IWorker) => {
    worker.execute();
  };

  /**
   * 通过这里初始化一些参数，如画板叠加，页面跳转，缩放系数等目前重构阶段不好处理的数据
   */
  public initExtensionFeature(data: IWorkerExtensionFeature) {
    this._extensionFeature = Object.assign(this._extensionFeature, data);
  }

  public patch(patches: PageOperations) {
    if (this._termination) {
      return;
    }
    if (this._extensionFeature.patch) {
      this._extensionFeature.patch(patches);
    }
  }

  private timer?: Timeout;

  private cachePatches: PageOperations = {};

  /**
   * FIXME: Matt 2021-04-08 演示时数据应用优化，使同时工作看起来更像同时工作，待测试验证
   * 如果是密集patches，会卡住patches，不适合
   */
  private delayPatches(patches: PageOperations) {
    if (!this._extensionFeature.patch) {
      return;
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }
    const artboardIDs = Object.keys(patches);
    artboardIDs.forEach((id) => {
      const artboardOperation = this.cachePatches[id];
      const operation = patches[id];
      const compIDs = Object.keys(operation);
      if (!compIDs.length) {
        return;
      }
      if (!artboardOperation) {
        this.cachePatches[id] = operation;
      } else {
        compIDs.forEach((compID) => {
          const compOpt = operation[compID];
          if (!Object.keys(compOpt).length) {
            return;
          }
          if (!artboardOperation[compID]) {
            artboardOperation[compID] = compOpt;
          } else {
            artboardOperation[compID].push(...compOpt);
          }
        });
      }
    });
    this.timer = window.setTimeout(() => {
      if (Object.keys(this.cachePatches).length) {
        this._extensionFeature!.patch!({ ...this.cachePatches });
      }
      this.cachePatches = {};
      this.timer = undefined;
    }, 1);
  }

  /**
   * 是否有效的页面目标
   * @param pageID
   */
  public isValidPageTarget(pageID: string) {
    if (['@back', '@home'].includes(pageID)) {
      return true;
    }
    return this._allPageNodes.findIndex((item) => item._id === pageID) !== -1;
  }

  public startWorker(trigger: UIComponent, event: EventTypes) {
    if (this._termination) {
      this.terminationAllWorks();
      this.workerMap.clear();
      return;
    }
    const worker = this.getWorker(trigger, event);
    if (!worker) {
      return;
    }
    if (worker.isRunning) {
      worker.runAgainAfterFinished();
      return;
    }
    worker.execute();
  }

  public reset() {
    this.terminationAllWorks();
    this.fragmentCommandMap.clear();
    this.workerMap.clear();
    this._termination = false;
  }

  private terminationAllWorks = () => {
    this.workerMap.forEach((item) => {
      item.termination();
    });
  };

  public termination() {
    this._termination = true;
    this.terminationAllWorks();
    this.workerMap.clear();
  }
}
