import i18n from '../i18n';
// 最多能展示页数的个数
export const PaginationMaxDisplayItemCount = 7;

// 当前页的前后至少要展示页数的个数
export const PaginationDefaultPreOrNextCount = 2;

export const PaginationSizeOption = [
  {
    id: 10,
    text: i18n('pager.sizeOfPerPage', 10),
  },
  {
    id: 20,
    text: i18n('pager.sizeOfPerPage', 20),
  },
  {
    id: 50,
    text: i18n('pager.sizeOfPerPage', 50),
  },
  {
    id: 100,
    text: i18n('pager.sizeOfPerPage', 100),
  },
];
