import { IAppMembersInfo } from '@fbs/idoc/models/app';
import { IMemberInfo } from '@fbs/idoc/models/member';
import { get, patch, post } from './helper';

interface IInvitationLink {
  invitationID: string;
  role: string;
}

/**
 * 获取团队成员
 * @param {string} teamID
 * @returns {Promise<any>}
 */
export async function getTeamMember(teamID: string) {
  return await get<IMemberInfo[]>(`/team/${teamID}/members`);
}

/**
 * 获取项目成员
 * @param {string} appID
 */
export async function getAppMember(appID: string) {
  return await get<{
    appMembers: IAppMembersInfo[];
    parentMembers: IAppMembersInfo[];
    groups: number[];
    parentGroups: number[];
  }>(`/app/${appID}/members`);
}

/**
 * 获取项目邀请链接
 * @param {string} teamID
 * @param {string} appID
 * @param {string} role
 * @param timeLimit
 * @returns {Promise<IInvitationLink>}
 */
export async function getAppInvitationLink(teamID: string, appID: string, role: string, timeLimit?: string) {
  return await get<IInvitationLink>(`/team/${teamID}/invitationLink`, {
    params: {
      appID,
      role,
      timeLimit,
    },
  });
}

/**
 * 修改项目是团队还是私有
 * @param {string} appID
 * @param {"private" | "public"} visibility
 * @returns {Promise<any>}
 */
export async function modifyAppCompetence(appID: string, visibility: 'private' | 'internal') {
  return await patch(`/app/${appID}`, { visibility });
}

// 重置链接
export async function resetInvitationLink(teamID: string) {
  return post(`/team/${teamID}/resetInvitationLink`);
}
/**
 * 新增项目成员
 * @param appID
 * @param members
 */
export async function updateAppMembers(appID: string, members: number[]) {
  return await post<IAppMembersInfo[]>(`/app/${appID}/update-members`, {
    members,
  });
}

/**
 * 新增项目成员-部门
 * @param appID
 * @param groupIDs
 */
export function updateAppGroups(appID: string, groupIDs: number[]) {
  return patch<{ groupIDs: number[] }>(`/app/${appID}/update-groups`, { groupIDs });
}

export function changeInvitationLink(
  invitationID: string,
  data: {
    timeLimit?: string;
    role?: string;
    groupID?: number;
  },
) {
  return patch(`/team/invitationLink/${invitationID}`, data);
}
