import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { getShapeData, DefaultFlowShapeSize, flowBase, getShapePropertiesData } from './shapeDataFragment';

// 云
export default {
  ...flowBase,
  name: i18n('resource.flow.cloud'),
  type: 'cloud',
  thumb: {
    spriteIconClass: SpriteThumb.Cloud.className,
    dragPosition: SpriteThumb.Cloud.position,
  },
  template: getShapeData(i18n('resource.flow.cloud'), DefaultFlowShapeSize.size100_60, {
    data: [
      {
        point: {
          x: 30,
          y: 2,
        },
        handleIn: {
          x: 12.5,
          y: -3.5,
        },
        handleOut: {
          x: -12.5,
          y: 3.5,
        },
      },
      {
        point: {
          x: 18,
          y: 15,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 0,
          y: 29,
        },
        handleIn: {
          x: 0,
          y: -13,
        },
        handleOut: {
          x: 0,
          y: 13,
        },
      },
      {
        point: {
          x: 14.5,
          y: 46,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 23,
          y: 57,
        },
        handleIn: {
          x: -10.5,
          y: -5,
        },
        handleOut: {
          x: 13.5,
          y: 4,
        },
      },
      {
        point: {
          x: 43,
          y: 54.5,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 60,
          y: 60,
        },
        handleIn: {
          x: -13,
          y: 0.5,
        },
        handleOut: {
          x: 13,
          y: -0.5,
        },
      },
      {
        point: {
          x: 74,
          y: 52,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 92,
          y: 50,
        },
        handleIn: {
          x: -6.5,
          y: 7,
        },
        handleOut: {
          x: 6.5,
          y: -7,
        },
      },
      {
        point: {
          x: 92.5,
          y: 36,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 98.5,
          y: 20,
        },
        handleIn: {
          x: 4,
          y: 10.5,
        },
        handleOut: {
          x: -4,
          y: -10.5,
        },
      },
      {
        point: {
          x: 80.5,
          y: 11.5,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
      {
        point: {
          x: 66.5,
          y: 0,
        },
        handleIn: {
          x: 11,
          y: 0,
        },
        handleOut: {
          x: -11,
          y: 0,
        },
      },
      {
        point: {
          x: 51.5,
          y: 5.5,
        },
        handleIn: {
          x: 0,
          y: 0,
        },
        handleOut: {
          x: 0,
          y: 0,
        },
      },
    ],
    closed: true,
  }),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
