import * as React from 'react';

import { IItemProps } from '../../../models';
import Checkbox from '../../Checkbox';
import Icon from '../../Icon';
import Tooltip from '../../Tooltip';

import './CheckItem.scss';

interface ICheckItemProps {
  item: IItemProps;
  checked: boolean;
  showEmail?: boolean;
  showThirdPartyUUID?: boolean;

  onCheck(id: string): void;
  onCheckDisable?(id: string): void;
}

const CheckItem: React.FC<ICheckItemProps> = (props: ICheckItemProps) => {
  const { item, checked, showEmail, showThirdPartyUUID, onCheck, onCheckDisable } = props;

  const compareRef = React.useRef<HTMLSpanElement>(null);
  const contentRef = React.useRef<HTMLSpanElement>(null);
  const [tooltip, setTooltip] = React.useState('');

  // @ts-ignore
  const { id, name, imageURL, disabled } = item;

  const onMouseEnterComputeTip = (text?: string) => {
    {
      const content = contentRef.current?.getBoundingClientRect();
      const target = compareRef.current?.getBoundingClientRect();
      if (content && target) {
        if (target.width > content.width) {
          setTooltip(text || contentRef.current!.innerText);
        } else {
          setTooltip('');
        }
      }
    }
  };
  const onMouseLeaveComputeTip = () => {
    setTooltip('');
  };

  const renderTitle = () => {
    if (showEmail) {
      return (
        <span
          className="item-email-wrapper"
          ref={contentRef}
          onMouseEnter={() => onMouseEnterComputeTip(`${name}` + `(${item.email || ''})`)}
          onMouseLeave={onMouseLeaveComputeTip}
        >
          <span className="item-email-name">{name}</span>
          <span className="item-email">{` (${item.email || ''})`}</span>
          <span className="compare" ref={compareRef}>
            {name + ` (${item.email || ''})`}
          </span>
        </span>
      );
    }
    if (showThirdPartyUUID) {
      return (
        <span
          className="item-email-wrapper"
          ref={contentRef}
          onMouseEnter={() => onMouseEnterComputeTip(`${name}` + `(${item.thirdPartyUUID || ''})`)}
          onMouseLeave={onMouseLeaveComputeTip}
        >
          <span className="item-email-name">{name}</span>
          <span className="item-email">{` (${item.thirdPartyUUID || ''})`}</span>
          <span className="compare" ref={compareRef}>
            {name + ` (${item.thirdPartyUUID || ''})`}
          </span>
        </span>
      );
    }
    return (
      <span
        className="item-name"
        ref={contentRef}
        onMouseEnter={() => onMouseEnterComputeTip()}
        onMouseLeave={onMouseLeaveComputeTip}
      >
        {name}
        <span className="compare" ref={compareRef}>
          {name}
        </span>
      </span>
    );
  };

  return (
    <div
      className="check-item-box"
      onClick={() => {
        if (disabled) {
          onCheckDisable && onCheckDisable(id);
          return;
        }
        onCheck(id);
      }}
    >
      <Checkbox checked={checked} disabled={disabled} />
      {imageURL ? <div className="avatar" style={{ backgroundImage: `url(${imageURL})` }} /> : <Icon size={20} />}
      <div className="check-item-title">
        <Tooltip theme="small" text={tooltip}>
          {renderTitle()}
        </Tooltip>
      </div>
    </div>
  );
};

export default CheckItem;
