import CoreBox from './box';
import { EventType, IActionBase, IActionParam, IEventHandle, IInteraction } from '@fbs/rp/models/interactions';
import { convertUIOperationToPagePatches, mergePatches } from '@helpers/patchHelper';
import { ComponentPatches, Ops, PagePatches } from '@fbs/rp/utils/patch';
import { UIComponent, UIFragment } from '@editor/comps';
import { EventTypes } from '@/fbs/rp/models/event';

export interface IEventAndHandle {
  event: EventTypes;
  handle: IEventHandle | undefined;
  originHandle?: IEventHandle;
}
/**
 * 组件交互管理
 */
export default class CoreInteraction extends CoreBox {
  /**
   * 添加一个动作到事件中
   * @param {EventType} event
   * @param {IActionBase} action
   * @param {UIComponent} sourceCom
   */
  addAction(event: EventType, action: IActionBase, sourceCom?: UIComponent): boolean {
    const comp = sourceCom || this.firstSelectedComponent;

    if (!comp) {
      return false;
    }
    const ops = comp.addInteractionAction(event, action);
    if (ops) {
      this.update(convertUIOperationToPagePatches(comp, ops));
    }
    return true;
  }
  /**
   * 替换组件交互
   * @param newInteraction
   * @param oldInteraction
   * @param sourceCom
   * @returns
   */
  replaceInteraction(newInteraction: IInteraction, oldInteraction: IInteraction, sourceCom?: UIComponent) {
    const comp = sourceCom || this.firstSelectedComponent;

    if (!comp) {
      return;
    }
    const ops = comp.replaceInteraction(newInteraction, oldInteraction);
    if (ops) {
      this.update(convertUIOperationToPagePatches(comp, ops));
    }
    return;
  }

  /**
   * 同时为多个组件或者画板粘贴交互
   * @param patchInfo
   * @param artboardID
   */
  multiReplaceInteraction(
    patchInfo: { id: string; oldData: UIComponent | UIFragment; newData: UIComponent | UIFragment }[],
    parentId: string,
  ) {
    const getNewInteractionPatch = (
      info: { id: string; oldData: UIComponent | UIFragment; newData: UIComponent | UIFragment },
      isToComp: boolean,
    ) => {
      const { id, oldData, newData } = info;
      return {
        do: {
          [isToComp ? id : 'self']: [Ops.replace('/interaction', newData.interactions)],
        },
        undo: {
          [isToComp ? id : 'self']: [Ops.replace('/interaction', oldData.interactions)],
        },
      };
    };

    const getFragmentInteractionPatch = (): PagePatches => {
      const pagePatches: PagePatches = {};
      patchInfo.forEach((info) => {
        pagePatches[info.id] = getNewInteractionPatch(info, false);
      });
      return pagePatches;
    };

    const getCompInteractionPatch = () => {
      return patchInfo.reduce(
        (prevPatch, nextPatch) => {
          return mergePatches(prevPatch, getNewInteractionPatch(nextPatch, true));
        },
        {
          do: {},
          undo: {},
        },
      );
    };

    const batchPatches =
      parentId === 'ROOT'
        ? getFragmentInteractionPatch()
        : {
            [parentId]: getCompInteractionPatch(),
          };

    this.update(batchPatches);
  }

  /**
   * TODO: 该写法可能会导致数据丢失，使用的地方已被替换为replaceInteraction, 后续replaceInteraction不会出现数据丢失，则删除该函数
   * 替换旧事件下所有的命令到目标事件
   * @param currEventAndHandle 目标事件
   * @param targetEventAndHandle 旧事件下所有的命令
   * @param isReplace
   * @param sourceCom 触发源组件
   * @returns
   */
  replaceActions(
    currEventAndHandle: IEventAndHandle,
    targetEventAndHandle: IEventAndHandle,
    isReplace: boolean,
    sourceCom?: UIComponent,
  ): boolean {
    const comp = sourceCom || this.firstSelectedComponent;

    if (!comp) {
      return false;
    }

    const ops = comp.replaceInteractionActions(currEventAndHandle, targetEventAndHandle, isReplace);
    if (ops) {
      this.update(convertUIOperationToPagePatches(comp, ops));
    }
    return true;
  }

  /**
   * TODO: 该写法可能会导致数据丢失，使用的地方已被替换为replaceInteraction, 后续replaceInteraction不会出现数据丢失，则删除该函数
   * 移动当前事件中的命令到目标事件
   * @param currEventAndHandle 当前事件与其对应的命令
   * @param targetEventAndHandle 目标事件与其对应的命令
   * @param isReplace 是否替换
   * @param sourceCom 触发源
   * @returns
   */
  moveSingleActions(
    currEventAndHandle: IEventAndHandle,
    targetEventAndHandle: IEventAndHandle,
    isReplace: boolean,
    sourceCom?: UIComponent,
  ): boolean {
    const comp = sourceCom || this.firstSelectedComponent;

    if (!comp) {
      return false;
    }
    const ops = comp.moveSingleInteractionActions(currEventAndHandle, targetEventAndHandle, isReplace);
    if (ops) {
      this.update(convertUIOperationToPagePatches(comp, ops));
    }
    return true;
  }
  /**
   * 从事件中移除一个动作
   * @param {EventType} event
   * @param {string} actionID
   * @param sourceComp
   */
  removeAction(event: EventType, actionID: string, sourceComp?: UIComponent) {
    const comp = sourceComp || this.firstSelectedComponent;
    if (!comp) {
      return;
    }
    const ops = comp.removeInteractionAction(event, actionID);
    if (ops) {
      this.update(convertUIOperationToPagePatches(comp, ops));
    }
  }

  /**
   * 移除事件下所有动作
   * @param {EventType} event
   * @param sourceComp
   */
  removeAllAction(event: EventType, sourceComp?: UIComponent) {
    const comp = sourceComp || this.firstSelectedComponent;
    if (!comp) {
      return;
    }
    const ops = comp.removeAllInteractionActions(event);
    if (ops) {
      this.update(convertUIOperationToPagePatches(comp, ops));
    }
  }

  /**
   * 移动事件下动作的顺序
   * @param {EventType} event
   * @param {string} actionID
   * @param {number} newIndex
   * @param sourceComp
   */
  moveAction(event: EventType, actionID: string, newIndex: number, sourceComp?: UIComponent) {
    const comp = sourceComp || this.firstSelectedComponent;
    if (!comp) {
      return;
    }
    const ops = comp.moveInteractionAction(event, actionID, newIndex);
    if (ops) {
      this.update(convertUIOperationToPagePatches(comp, ops));
    }
  }

  /**
   * 标记事件的激活状态
   * @param {EventType} event
   */
  tagEventActive(event: EventType) {
    const comp = this.firstSelectedComponent;
    if (!comp) {
      return;
    }
    const ops = comp.markUpInteractionActive(event);
    if (ops) {
      this.update(convertUIOperationToPagePatches(comp, ops));
    }
  }

  /**
   * 标记事件下所有动作是否同时工作
   * @param {EventType} event
   * @param sourceComp
   */
  tagEventParallel(event: EventType, sourceComp?: UIComponent) {
    const comp = sourceComp || this.firstSelectedComponent;
    if (!comp) {
      return;
    }
    const ops = comp.markUpInteractionParallel(event);
    if (ops) {
      this.update(convertUIOperationToPagePatches(comp, ops));
    }
  }

  /**
   * 标记事件下所有动作在下一个触发时，是否反向执行
   * @param {EventType} event
   * @param sourceComp
   */
  tagEventAutoRevert(event: EventType, sourceComp?: UIComponent) {
    const comp = sourceComp || this.firstSelectedComponent;
    if (!comp) {
      return;
    }
    const ops = comp.markUpInteractionAutoRevert(event);
    if (ops) {
      this.update(convertUIOperationToPagePatches(comp, ops));
    }
  }

  /**
   * 修改事件下动作的参数
   * @param {EventType} event
   * @param {string} actionID
   * @param {IActionParam} params
   */
  modifyActionParams(event: EventType, actionID: string, params: IActionParam) {
    const comp = this.firstSelectedComponent;
    if (!comp) {
      return;
    }
    const ops = comp.modifyInteractionActionParams(event, actionID, params);
    if (ops) {
      this.update(convertUIOperationToPagePatches(comp, ops));
    }
  }

  /**
   * 修改事件下动作
   * @param {EventType} event
   * @param {string} actionID
   * @param {IActionBase} action
   * @param sourceCom
   */
  modifyActionData(event: EventType, actionID: string, action: IActionBase, sourceCom?: UIComponent) {
    const comp = sourceCom || this.firstSelectedComponent;
    if (!comp) {
      return;
    }
    const ops = comp.modifyInteractionActionData(event, actionID, action);
    if (ops) {
      this.update(convertUIOperationToPagePatches(comp, ops));
    }
  }

  /**
   * 修改一个动作的同时移除另一个动作
   * @param {EventType} event
   * @param {string} removeActionID
   * @param {string} replaceActionID
   * @param {IActionBase} action
   * @param sourceComp
   * @returns
   * @memberof CoreInteraction
   */
  removeAndModifyAction(
    event: EventType,
    removeActionID: string,
    replaceActionID: string,
    action: IActionBase,
    sourceComp?: UIComponent,
  ) {
    const comp = sourceComp || this.firstSelectedComponent;
    if (!comp) {
      return;
    }
    const modifyOps = comp.modifyInteractionActionData(event, replaceActionID, action);
    const removeOps = comp.removeInteractionAction(event, removeActionID);
    let ops: ComponentPatches | null = null;
    if (modifyOps && removeOps) {
      // 此处顺序非常重要
      ops = {
        do: [...modifyOps.do, ...removeOps.do],
        undo: [...removeOps.undo, ...modifyOps.undo],
      };
    } else {
      ops = modifyOps || removeOps;
    }
    if (ops) {
      this.update(convertUIOperationToPagePatches(comp, ops));
    }
  }
}
