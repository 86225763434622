import { makeImageBySvg } from '../image';
import { getImageBase64 } from '../image/fetchImage';
import { IExportData, IMakeSvgByBlobOptions, IMakeSvgByGroup, IMakeSvgByPathOptions } from '../type';
import { imageToCanvas, svgToDataUri, uid } from '../util';
import { makeSvgContentByHtml, makeSvgContentTemplate, makSvgByBase64, wrapSvgContent } from './makeSvgTemplate';
import { blobToBase64 } from '../util/blobUtil';
import { globalStatistics } from '../util/statisticsUtil';

export interface ISvgInfo {
  svg: string;
  scale: number;
  index: number;
}
/**
 * 创建编组svg
 * @param data
 * @returns
 */
export const makeSvgByGroup = async (options: IMakeSvgByGroup): Promise<ISvgInfo[]> => {
  const { data, size, rotate, scales } = options;
  const svgs: string[] = [];
  const p = data.map(async (t) => {
    const isRoot = t.isRoot;
    const svg = await makeSvgContent({ ...t }, isRoot);
    svgs.push(svg);
  });
  await Promise.all(p);

  // scales 多倍率
  return scales.map((t, index) => {
    return {
      svg: wrapSvgContent({ content: svgs.join(''), size, rotate, scale: t }),
      scale: t,
      index,
    };
  });
};

export const makeSvgContent = (options: IExportData, isRoot: boolean = true): Promise<string> => {
  const {
    id,
    type,
    pathData,
    blob,
    size,
    style,
    svgValue,
    html,
    text,
    position,
    rotate,
    innerBounds,
    outerBounds,
    offset,
    matrix,
  } = options;
  if (html) {
    return makeSvgContentByHtml({ html, size, isRoot, position, rotate, offset, outerBounds });
  }

  return blobToBase64(blob).then((uri) => {
    return makeSvgContentTemplate({
      id,
      type,
      patternUri: uri,
      pathData,
      text,
      size,
      style,
      svgValue,
      isRoot,
      position,
      rotate,
      innerBounds,
      outerBounds,
      offset,
      matrix,
    });
  });
};

export const makeSvgByPath = (options: IMakeSvgByPathOptions): Promise<string> => {
  const { id, pathData, size, style } = options;
  return makeSvgContentTemplate({
    id,
    pathData,
    size,
    style,
  });
};

export const makeSvgByBlob = (options: IMakeSvgByBlobOptions): Promise<string> => {
  const { id, blob, size, style } = options;
  return blobToBase64(blob).then((uri) => {
    return makeSvgContentTemplate({
      id,
      patternUri: uri,
      size,
      style,
    });
  });
};

export const makeImageSvgDataUri = (data: IExportData): Promise<string> => {
  return makeImageSvg(data).then((t) => svgToDataUri(t));
};

export const makeImageSvg = (data: IExportData): Promise<string> => {
  return getImageBase64(data).then((base64) => {
    return makSvgByBase64(base64, data.size);
  });
};
const makeUid = uid();

export const svgToCanvas = async (svg: string, name?: string, scale?: number): Promise<HTMLCanvasElement> => {
  return new Promise((resolve, reject) => {
    makeImageBySvg(svg, name)
      .then((image) => {
        const { width, height } = image;
        // 0.5倍兼容问题处理
        return imageToCanvas(image, {
          width: scale === 0.5 ? Math.ceil(width * scale) : width,
          height: scale === 0.5 ? Math.ceil(height * scale) : height,
        });
      })
      .then((canvas) => {
        resolve(canvas);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * svg转canvas
 * @param svg
 * @param name
 */
export const svgToCanvasBlob = async (svg: string, name?: string, scale?: number): Promise<Blob> => {
  const timeId = 'makeImage-' + name + '-' + makeUid();
  const timeStart = new Date().getTime();
  // 统计svg
  globalStatistics.addSvg(timeId, svg.length);

  return new Promise((resolve, reject) => {
    svgToCanvas(svg, name, scale)
      .then((canvas) => {
        canvas.toBlob((blob) => {
          if (blob) {
            // 统计blob
            const timeEnd = new Date().getTime();
            globalStatistics.addTime(timeId, timeEnd - timeStart);
            globalStatistics.addBlob(timeId, blob.size);
            resolve(blob);
            // 清理内存
            canvas.remove();
          }
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const svgToCanvasDataURL = async (svg: string, name?: string, scale?: number): Promise<string> => {
  const timeId = 'makeImage-' + name + '-' + makeUid();
  const timeStart = new Date().getTime();
  // 统计svg
  globalStatistics.addSvg(timeId, svg.length);
  return new Promise((resolve, reject) => {
    svgToCanvas(svg, name, scale)
      .then((canvas) => {
        const url = canvas.toDataURL();
        const timeEnd = new Date().getTime();
        globalStatistics.addTime(timeId, timeEnd - timeStart);
        resolve(url);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
