import * as React from 'react';
import * as tinycolor from 'tinycolor2';

import { dragDelegate } from '@utils/mouseUtils';
import { parseColorToString } from '@utils/graphicsUtils';
import { IColorInfo } from '../model';

import './index.scss';

export interface IHueProp {
  color: IColorInfo;
  disabled?: boolean;
  onChange: (hsv: tinycolor.ColorFormats.HSV, isChanging?: boolean) => void;
}

export interface IHueState {
  color: tinycolor.ColorFormats.HSV;
  afterChooseOffset: string;
}

class Hue extends React.Component<IHueProp, IHueState> {
  static defaultProps: Partial<IHueProp> = {};

  selfRef: React.RefObject<HTMLDivElement>;

  constructor(props: IHueProp) {
    super(props);
    this.state = {
      color: props.color.hsv,
      afterChooseOffset: `${props.color.hsv.h / 3.6}%`,
    };
    this.selfRef = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(newProps: IHueProp) {
    const hsv = newProps.color.hsv;
    const { color } = this.state;
    if (hsv !== color && color.v + hsv.v !== 0) {
      let afterChooseOffset = `${(hsv.h / 360) * (this.selfRef.current?.clientWidth || 0)}px`;
      //如果通过componentWillReceiveProps到这里，说明存在拾色器色板点击情况下，修改颜色，此时，s可能不为0，需要更新，不然拖拽色区滚动条rgba值转换可能会被错误的识别为255，255,255
      if ((hsv.h === 0 || hsv.h === 360) && (color.h === 0 || color.h === 360) && hsv.s === color.s) {
        return;
      }
      this.setState({
        color: hsv,
        afterChooseOffset,
      });
    }
  }

  handleColorChange = (offsetX: number, offsetWidth: number, isChaning?: boolean) => {
    const { onChange, color: oldColor } = this.props;
    const { color } = this.state;
    const newColor: tinycolor.ColorFormats.HSV = { ...color };
    newColor.h = (offsetX * 360) / offsetWidth;
    //不修改饱和度和亮度，防止修改面包调色的位置
    //注意mouseDown也修改了，下面这个注释，componentWillReceiveProps需要处理hsv.s的情况，可能不等于0，否则转换rgba会被错误转换成255,255,255导致修改面板
    // if (newColor.s === 0 || newColor.v === 0) {
    //   newColor.s = 1;
    //   newColor.v = 1;
    // }
    //初次拖动拾色器，面板并无修改的情况下
    if (newColor.s === 0 && oldColor?.hsv?.s === 0) {
      newColor.s = 1;
    }
    this.setState(
      {
        color: newColor,
        afterChooseOffset: `${offsetX}px`,
      },
      () => {
        onChange && onChange(newColor, isChaning);
      },
    );
  };

  handlePointMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { target } = e;
    const dom = target as HTMLElement;
    const parentNode = dom.parentElement;
    const left = dom.offsetLeft;
    const { onChange } = this.props;
    if (parentNode) {
      const w = parentNode.offsetWidth;
      dragDelegate(
        (e: MouseEvent, delta: { x: number; y: number }) => {
          e.preventDefault();
          const newLeft = Math.min(Math.max(0, left + delta.x), w);
          this.handleColorChange(newLeft, w, true);
        },
        () => {
          onChange && onChange(this.state.color);
        },
      );
    }
  };

  handleHueMouseDown = (e: React.MouseEvent) => {
    const { offsetX } = e.nativeEvent;
    const dom = e.target as HTMLElement;
    const { offsetWidth } = dom;
    e.stopPropagation();
    this.handleColorChange(offsetX, offsetWidth, true);
    dragDelegate(
      (e: MouseEvent, delta: { x: number; y: number }) => {
        e.preventDefault();
        const newLeft = Math.max(0, Math.min(offsetX + delta.x, offsetWidth));
        this.handleColorChange(newLeft, offsetWidth, true);
      },
      (e: MouseEvent, delta: { x: number; y: number }) => {
        const newLeft = Math.max(0, Math.min(offsetX + delta.x, offsetWidth));
        this.handleColorChange(newLeft, offsetWidth);
      },
    );
  };

  render() {
    const { color, afterChooseOffset } = this.state;
    const { disabled } = this.props;
    const style: React.CSSProperties = {
      left: afterChooseOffset,
    };
    return (
      <div
        ref={this.selfRef}
        className="dsm-c-rp-color-general-hue"
        onMouseDown={disabled ? undefined : this.handleHueMouseDown}
      >
        <div
          style={{
            ...style,
            background: parseColorToString({
              ...tinycolor({ ...color, s: 1, v: 1 }).toRgb(),
              a: 1,
            }),
          }}
          className="point"
          onMouseDown={disabled ? undefined : this.handlePointMouseDown}
        />
      </div>
    );
  }
}

export default Hue;
