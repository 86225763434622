import { convertEventToHotKey, isControlKeyPressed, isMacControlKeyPressed } from '@utils/hotkeysUtils';
import { isMacOS, isMockRPD } from '@utils/envUtils';
import { isInputting } from '@utils/globalUtils';
import { CustomObservableObject } from '@/utils/customObservableUtils';

const VK_F1 = 'f1';
const VK_CTRL_G = 'ctrl+g';
const VK_CTRL_D = 'ctrl+d';

export const textStyleShortCut = ['ctrl+b', 'ctrl+u', 'ctrl+i', 'ctrl+[', 'ctrl+]'];

const globalShortCut: string[] = [
  VK_F1,
  VK_CTRL_G,
  VK_CTRL_D,
  'ctrl+0',
  'ctrl+1',
  'ctrl+2',
  'ctrl+3',
  'ctrl+4',
  'ctrl+5',
  'ctrl+6',
  'ctrl+7',
  'ctrl+8',
  'ctrl+9',
  'ctrl++',
  'ctrl+-',
  'ctrl+=',
  'ctrl+z',
  'ctrl+y',
  'ctrl+l',
  'ctrl+f',
  'ctrl+p',
  'ctrl+r',
  'ctrl+t',
  'ctrl+o',
  'alt+w',
  'ctrl+n',
  'ctrl+h',
  'ctrl+f11',
  'ctrl+arrowup',
  'ctrl+arrowdown',
  'ctrl+arrowright',
  'ctrl+arrowleft',
  'ctrl+shift+arrowup',
  'ctrl+shift+arrowdown',
  'shift+arrowleft',
  'shift+arrowright',
  'shift+arrowup',
  'shift+arrowdown',
  'ctrl+tab',

  'ctrl+alt+l',
  'ctrl+alt+¬',

  'ctrl+alt+c',
  'ctrl+alt+ç',

  'ctrl+alt+r',
  'ctrl+alt+®',

  'ctrl+alt+t',
  'ctrl+alt+†',

  'ctrl+alt+m',
  'ctrl+alt+µ',

  'ctrl+alt+b',
  'ctrl+alt+∫',

  'ctrl+shift+h',
  'ctrl+shift+u',

  'alt+g',
  'alt+©',
  'alt+l',
  'alt+¬',

  'ctrl+s',
  'ctrl+f4',
  'alt+f4',
  'ctrl+f11',

  'ctrl+shift+alt+c',
  'ctrl+shift+alt+ç',

  'ctrl+shift+alt+v',
  'ctrl+shift+alt+◊',

  'ctrl+alt+[',
  'ctrl+alt+“',

  'ctrl+alt+]',
  'ctrl+alt+‘',

  'alt+h',
  'alt+c',
  'alt+r',
];

if (!isMockRPD) {
  globalShortCut.push('ctrl+w');
}

const editorShortCut: string[] = [
  'alt+arrowleft',
  'alt+arrowright',
  'alt+arrowup',
  'alt+arrowdown',
  'ctrl+[',
  'ctrl+]',
  'ctrl+s',
  'ctrl+f',
  'ctrl++',
  'ctrl+-',
  'ctrl+=',
  'ctrl+0',
  'ctrl+1',
  'ctrl+2',
  'ctrl+3',
  'ctrl+4',
  'ctrl+5',
  'ctrl+6',
  'ctrl+7',
  'ctrl+8',
  'ctrl+9',
  'ctrl+n',
];

const searchHotKey = 'ctrl+f';
// const macRefreshKey = 'ctrl+r';

const handleWindowKeyDown = (e: KeyboardEvent) => {
  stopBubbleWhenSortCut(e);
};

export function stopBubbleWhenSortCut(e: React.KeyboardEvent | KeyboardEvent) {
  const hotKey = convertEventToHotKey(e).replace(/^[ ]$/g, '');
  // 当前快捷键为macos ctrl+command+f， 应为全屏快捷键
  const isMacFullScreenKey = isMacControlKeyPressed(e) && hotKey === searchHotKey;
  // 输入状态下
  if (isInputting()) {
    if (editorShortCut.indexOf(hotKey) !== -1 && !isMacFullScreenKey) {
      e.preventDefault();
    }
    return;
  }
  // 全局忽略跳过的
  if (globalIgnoreKey.includes(hotKey) || isMacFullScreenKey) {
    return;
  }
  // 其它场景的
  if (globalShortCut.indexOf(hotKey) !== -1 || editorShortCut.indexOf(hotKey) !== -1) {
    e.preventDefault();
  }
  // ctrl + number
  if (isControlKeyPressed(e) && '1234567890'.indexOf(e.key) === 0) {
    e.preventDefault();
  }
}

let globalIgnoreKey: string[] = [];

export function listenerShortCut(ignoreKey?: string[]) {
  globalIgnoreKey = ignoreKey || [];
  window.addEventListener('keydown', handleWindowKeyDown, { capture: true });
}

export function unListenerShortCut() {
  globalIgnoreKey = [];
  window.removeEventListener('keydown', handleWindowKeyDown);
}

const macControllerKey: string[] = ['⇧', '⌥', '⌘'];

export function transShortCutKeyToMac(key: string) {
  const lst = key
    .trim()
    .split(' + ')
    .map((str) => {
      const text = str.trim();
      if (text === 'Alt') {
        return '⌥';
      }
      if (text === 'Ctrl') {
        return '⌘';
      }
      if (text === 'Shift') {
        return '⇧';
      }
      if (text === 'Delete' || text === 'Backspace') {
        return '⌫';
      }
      if (text === 'Enter') {
        return '↩';
      }
      return text;
    })
    .sort((a: string, b: string) => {
      const aIndex = macControllerKey.indexOf(a);
      const bIndex = macControllerKey.indexOf(b);
      if (aIndex === -1 || bIndex === -1) {
        return 1;
      }
      return aIndex - bIndex;
    });
  return lst.join('');
}

export function getShortCutKey(key: string, option?: { ctrlKey?: boolean; shiftKey?: boolean; altKey?: boolean }) {
  const arr: string[] = [];
  if (option) {
    if (option.ctrlKey) {
      arr.push('Ctrl');
    }
    if (option.altKey) {
      arr.push('Alt');
    }
    if (option.shiftKey) {
      arr.push('Shift');
    }
  }
  let k = key[0].toUpperCase() + key.substring(1).toLowerCase();
  const lowerCaseKey = key.toLowerCase();
  if (isMacOS) {
    if (['delete', 'backspace'].includes(lowerCaseKey)) {
      k = '⌫';
    }
    if (lowerCaseKey === 'enter') {
      k = '↩';
    }
  } else {
    if (['delete', 'backspace'].includes(lowerCaseKey)) {
      k = 'Del';
    }
  }
  arr.push(k);

  let text = arr.join(' + ');
  if (isMacOS) {
    text = transShortCutKeyToMac(text);
  }
  return text;
}

export interface IControllerKey {
  ctrlKey: boolean;
  altKey: boolean;
  shiftKey: boolean;
  spaceKey: boolean;
}

let observableControllerKey: CustomObservableObject<IControllerKey> | null = null;

export function createObservableControllerKey() {
  observableControllerKey = new CustomObservableObject({
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    spaceKey: false,
  });
  return observableControllerKey;
}

export function getObservableControllerKey() {
  if (!observableControllerKey) {
    console.error('please create first');
  }
  return observableControllerKey;
}
