import * as sanitizeHtml from 'sanitize-html';
import { cloneDeep } from 'lodash';

import { filterHTMLText } from '@utils/textUtils';
import { storages } from '@/utils/cacheUtils';

import { IInteraction } from '@/fbs/rp/models/interactions';
import { IComponentData } from '@fbs/rp/models/component';
import { ICellStyle, ITableCell } from '@fbs/rp/models/table';

import { DefaultCell } from '@consts/defaultData/table';

import { ITableClipboardData } from './tableHelper';
import MockClipboard from './MockClipboard';
import { TextAlign, VerticalAlign } from '@/fbs/rp/models/properties/text';
import { UIFragment } from '@editor/comps';
import { CContentPanelV2 } from '../libs/constants';
import IArtboard from '@/fbs/rp/models/artboard';

/********用于上下文菜单状态检查*************/
export const HAS_COMP_DATA = 'TEMP_HAS_COMP_DATA';
export const HAS_ARTBOARD_DATA = 'TEMP_HAS_ARTBOARD_DATA';
export const HAS_COMPONENT_STYLE_DATA = 'TEMP_HAS_COMPONENT_STYLE_DATA';
export const HAS_PAGE_NODE_DATA = 'TEMP_HAS_PAGE_NODE_DATA';
export const HAS_COMPONENT_INTERACTION_DATA = 'TEMP_HAS_COMPONENT_INTERACTION_DATA';
/********用于上下文菜单状态检查*************/

// 组件数据
export const COMPONENT_DATA_KEY = 'RP-MOCKPLUS-COMPONENT-DATA';
// 画板数据
export const ARTBOARD_DATA_KEY = 'RP-MOCKPLUS-ARTBOARD-DATA';
// 页面数据
export const NODE_DATA_KEY = 'RP-MOCKPLUS-NODE-DATA';
// 组件样式数据
export const COMPONENT_STYLE_DATA_KEY = 'RP-MOCKPLUS-STYLE-DATA';
// 组件交互数据
export const COMPONENT_INTERACTION_DATA_KEY = 'RP-MOCKPLUS-COMPONENT-INTERACTION-DATA';
// 表格单元格数据
export const TABLE_CELLS_DATA_KEY = 'RP-MOCKPLUS-TABLE-CELLS-DATA';

export enum ClipboardType {
  Component_Copy = 'MOCKPLUS-COMPONENT-DATA-COPIED',
  Component_Cut = 'MOCKPLUS-COMPONENT-DATA-CUT',
  Artboard_Copy = 'MOCKPLUS-ARTBOARD-DATA-COPIED',
  Node_Copy = 'MOCKPLUS-NODE-DATA-COPIED',
  Table_Copy = 'MOCKPLUS-TABLE-COPIED',
  Style_Copy = 'MOCKPLUS-STYLE-COPIED',
  RICH_HTML_COPY = 'MOCKPLUS-RICH-HTML-COPIED', // 用于RichTextEditor复制标识
  Mock_Component = 'mock-component',
  Mock_Artboard = 'mock-artboard',
  Mock_Page = 'mock-page',
  Mock_Interaction = 'mock-interaction',
  Text = 'text',
  Json = 'json',
  Normal = '',
}

export type NodeCopyType = 'copy' | 'cut';

export interface INodeCopyData {
  type: NodeCopyType;
  fromAppID: string;
  nodeIDs: string[];
  pageNodeCount: number;
}

export interface IInteractionWithPrivateDeployment {
  interaction: IInteraction;
  isPrivateDeployment: boolean;
  targetId: string;
  fragmentId?: string;
}

export async function copyHtmlOrText(html?: string, text?: string) {
  const data: Record<string, ClipboardItemDataType> = Object.create(null);
  if (html) {
    data['text/html'] = new Blob([html], { type: 'text/html' });
  }
  if (text) {
    data['text/plain'] = new Blob([text], { type: 'text/plain' });
  }
  await navigator.clipboard.write([new ClipboardItem(data)]);
}
export function copy(text: string) {
  const textarea = document.createElement('textarea');
  textarea.textContent = text;
  textarea.style.position = 'fixed';
  document.body.appendChild(textarea);
  textarea.select();
  try {
    return document.execCommand('copy');
  } catch (e) {
    console.warn('Copy to clipboard failed', e);
  } finally {
    document.body.removeChild(textarea);
  }
}

// 组件、画板、页面数据只能存在其中一个
export async function clearMutexCopiedData() {
  await clearComponentData();
  await clearArtboardData();
  await clearPageNodeData();
}

async function tryCatchClearIndexedDBDataFromKey(key: string) {
  try {
    await storages.indexedDB.removeItem(key);
  } catch (e) {
    console.error(e);
  }
}

// 清除在indexedDB中的组件的复制数据
export async function clearComponentData() {
  await tryCatchClearIndexedDBDataFromKey(COMPONENT_DATA_KEY);
}

// 清除在indexedDB中的画板的复制数据
export async function clearArtboardData() {
  await tryCatchClearIndexedDBDataFromKey(ARTBOARD_DATA_KEY);
}

// 清除在indexedDB中的页面的复制数据
export async function clearPageNodeData() {
  await tryCatchClearIndexedDBDataFromKey(NODE_DATA_KEY);
}

// 清除在indexedDB中的组件样式的复制数据
export async function clearComponentStyleData() {
  await tryCatchClearIndexedDBDataFromKey(COMPONENT_STYLE_DATA_KEY);
}

// 清除在indexedDB中的组件交互的复制数据
export async function clearComponentInteractionData() {
  await tryCatchClearIndexedDBDataFromKey(COMPONENT_INTERACTION_DATA_KEY);
}

// 清除在indexedDB中的表格组件单元格的复制数据
export async function clearTableCellsData() {
  await tryCatchClearIndexedDBDataFromKey(TABLE_CELLS_DATA_KEY);
}
// 复制组件数据
export async function copyComponentData(
  data: IComponentData[],
  type: ClipboardType,
  containerID: string,
  artboardsFragments: UIFragment[],
) {
  const currentActive = document.activeElement;
  //如果组件包含内容画板。需要找出来关联的画板数据
  const cloneArtboards = data
    .filter((comp) => comp.type === CContentPanelV2)
    .map((comp) => {
      return artboardsFragments
        .filter((artboard) => artboard.$data.ownerID && artboard.$data.ownerID === comp._id)
        .map((artboard) => cloneDeep(artboard.$data));
    })
    .reduce((prevArtboards, nextArtboards) => {
      return prevArtboards.concat(nextArtboards);
    }, []);
  //复制
  copy(type);
  try {
    if (MockClipboard.clipboard.isSupport()) {
      await MockClipboard.setBoard({ data, containerID, cloneArtboards }, ClipboardType.Mock_Component);
    } else {
      await clearMutexCopiedData();

      await storages.indexedDB.setItem(COMPONENT_DATA_KEY, { data, containerID, cloneArtboards });
    }
  } catch (e) {
    console.error(e);
  }

  if (currentActive && currentActive instanceof HTMLElement) {
    currentActive.focus();
  }
}

interface ICopyPayload {
  type: ClipboardType;
  containerID: string;
  data: IComponentData[];
  refArtboardsData: IArtboard[];
}

export async function copyComponentDataV2(payload: ICopyPayload) {
  const { type, containerID, data, refArtboardsData } = payload;

  const currentActive = document.activeElement;
  copy(type);
  try {
    if (MockClipboard.clipboard.isSupport()) {
      await MockClipboard.setBoard({ data, containerID, refArtboardsData }, ClipboardType.Mock_Component);
    } else {
      await clearMutexCopiedData();

      await storages.indexedDB.setItem(COMPONENT_DATA_KEY, { data, containerID, refArtboardsData });
    }
  } catch (e) {
    console.error(e);
  }

  if (currentActive && currentActive instanceof HTMLElement) {
    currentActive.focus();
  }
}
// 复制画板数据
export async function copyArtboardData(artboard: IComponentData | IComponentData[], type: ClipboardType) {
  const currentActive = document.activeElement;

  copy(type);

  try {
    if (MockClipboard.clipboard.isSupport()) {
      await MockClipboard.setBoardAsync({ data: artboard }, ClipboardType.Mock_Artboard);
    } else {
      await clearMutexCopiedData();

      await storages.indexedDB.setItem(ARTBOARD_DATA_KEY, { data: artboard });
    }
  } catch (e) {
    console.error(e);
  }

  if (currentActive && currentActive instanceof HTMLElement) {
    currentActive.focus();
  }
}

// 复制页面
// 这个数据量很少，不需要存在indexDB
export async function copyNodeData(data: INodeCopyData) {
  const currentActive = document.activeElement;

  copy(ClipboardType.Node_Copy);

  try {
    if (MockClipboard.clipboard.isSupport()) {
      await MockClipboard.setBoardAsync(data, ClipboardType.Mock_Page);
    } else {
      await clearMutexCopiedData();
      await storages.indexedDB.setItem(NODE_DATA_KEY, data);
    }
  } catch (e) {
    console.error(e);
  }

  if (currentActive && currentActive instanceof HTMLElement) {
    currentActive.focus();
  }
}

// 复制组件样式数据
export async function copyStyleData(data: IComponentData, type: ClipboardType) {
  const currentActive = document.activeElement;

  copy(type);

  try {
    if (MockClipboard.clipboard.isSupport()) {
      await MockClipboard.setBoardAsync(data, ClipboardType.Style_Copy);
    } else {
      await storages.indexedDB.setItem(COMPONENT_STYLE_DATA_KEY, data);
    }
  } catch (e) {
    console.error(e);
  }

  if (currentActive && currentActive instanceof HTMLElement) {
    currentActive.focus();
  }
}
/**
 * 复制组件交互数据
 */
export async function copyComponentInteractionData(data: any) {
  const currentActive = document.activeElement;

  copy(ClipboardType.Mock_Interaction);
  try {
    if (MockClipboard.clipboard.isSupport()) {
      MockClipboard.setBoardAsync(data, ClipboardType.Mock_Interaction);
    } else {
      await storages.indexedDB.setItem(COMPONENT_INTERACTION_DATA_KEY, data);
    }
  } catch (e) {
    console.error(e);
  }
  if (currentActive && currentActive instanceof HTMLElement) {
    currentActive.focus();
  }
}

/**
 * 复制表格组件单元格
 */
export async function copyTableCellsData(data: ITableClipboardData) {
  try {
    await storages.indexedDB.setItem(TABLE_CELLS_DATA_KEY, data);
  } catch (e) {
    console.error(e);
  }
}

export function copiedDataType(text: string): ClipboardType {
  if (text === ClipboardType.Component_Copy) {
    return ClipboardType.Component_Copy;
  }
  if (text === ClipboardType.Component_Cut) {
    return ClipboardType.Component_Cut;
  }
  if (text === ClipboardType.Artboard_Copy) {
    return ClipboardType.Artboard_Copy;
  }
  if (text === ClipboardType.Table_Copy) {
    return ClipboardType.Table_Copy;
  }
  if (text === ClipboardType.Style_Copy) {
    return ClipboardType.Style_Copy;
  }
  return ClipboardType.Normal;
}

// export async function getCopiedComponentData():Promise< { componentData: IComponentData[]; containerID: string; cid: number } | null> {
//   const item = await storages.indexedDB.getItem(COMPONENT_DATA_KEY);
//   if (!item) {
//     return null;
//   }
//   try {
//     const { data, containerID, cid } = item;
//     return { componentData: data as IComponentData[], containerID: containerID as string, cid: cid as number };
//   } catch (e) {
//     console.warn('Fail to decode component data', item);
//   }
//   return null;
// }

/**
 * 更新复制数据存储状态
 * 目前用于上文下，render的时候，会实时读取复制数据，以前是读的localStorage，因为是同步，没什么影响，现在是异步了
 * 如果上下文直接使用异步，右击显示上下文菜单，会过一会才会显示；
 */
export async function updateCopyStorageStatus() {
  let hasCompData = false;
  let hasArtboardData = false;
  let hasCompStyleData = false;
  try {
    hasCompData = await storages.indexedDB.has(COMPONENT_DATA_KEY);
    hasArtboardData = await storages.indexedDB.has(ARTBOARD_DATA_KEY);
    hasCompStyleData = await storages.indexedDB.has(COMPONENT_STYLE_DATA_KEY);
    await updateCompInteractionStorageStatus();
  } catch (e) {
    console.error(e);
  }
  storages.temp.setItem(HAS_COMP_DATA, hasCompData);
  storages.temp.setItem(HAS_ARTBOARD_DATA, hasArtboardData);
  storages.temp.setItem(HAS_COMPONENT_STYLE_DATA, hasCompStyleData);
}

export async function updateCompInteractionStorageStatus() {
  let hasCompInteractionData = false;
  try {
    hasCompInteractionData = await storages.indexedDB.has(COMPONENT_INTERACTION_DATA_KEY);
  } catch (e) {
    console.error(e);
  }
  storages.temp.setItem(HAS_COMPONENT_INTERACTION_DATA, hasCompInteractionData);
}

/**
 * 更新页面数据的临时存储
 *  */
export async function updatePageNodeData(): Promise<void> {
  try {
    const data = await getCopiedNodeData();
    storages.temp.setItem(HAS_PAGE_NODE_DATA, data);
  } catch (e) {
    console.warn(e);
  }
}

/**
 * 检查表格单元格是否有复制数据
 */
export async function getHasCopyTableCellsData(): Promise<boolean> {
  try {
    return storages.indexedDB.has(TABLE_CELLS_DATA_KEY);
  } catch {
    return false;
  }
}

export interface ICopiedComponentData {
  componentData: IComponentData[];
  containerID: string;
  refArtboardsData: IArtboard[];
}

export async function getCopiedComponentData(): Promise<ICopiedComponentData | null> {
  try {
    let item;
    if (MockClipboard.clipboard.isSupport()) {
      const data = await MockClipboard.getBoardAsync();
      item = data.get(ClipboardType.Mock_Component);
      item = JSON.parse(item);
    } else {
      item = await storages.indexedDB.getItem(COMPONENT_DATA_KEY);
    }

    const { data, containerID, refArtboardsData } = item;
    return {
      componentData: data,
      containerID: containerID,
      refArtboardsData,
    };
  } catch (e) {
    console.warn('Fail to decode component data', e);
  }
  return null;
}

export async function getCopiedArtboardData(): Promise<{ data: IComponentData | IComponentData[] } | null> {
  try {
    let item;
    if (MockClipboard.clipboard.isSupport()) {
      const data = await MockClipboard.getBoardAsync();
      item = data.get(ClipboardType.Mock_Artboard);
      item = JSON.parse(item);
    } else {
      item = await storages.indexedDB.getItem(ARTBOARD_DATA_KEY);
    }
    const { data } = item;
    return {
      data: data as IComponentData,
    };
  } catch (e) {
    console.warn('Fail to decode artboard data', e);
  }
  return null;
}

export async function getCopiedNodeData(): Promise<INodeCopyData | null> {
  try {
    let item;
    if (MockClipboard.clipboard.isSupport()) {
      const data = await MockClipboard.getBoardAsync();
      item = data.get(ClipboardType.Mock_Page);
      item = JSON.parse(item);
    } else {
      item = await storages.indexedDB.getItem(NODE_DATA_KEY);
    }
    return item;
  } catch (e) {
    console.warn('Fail to decode page node data', e);
  }
  return null;
}

// 获取粘贴组件样式
export async function getCopiedStyleData(): Promise<IComponentData | null> {
  try {
    return storages.indexedDB.getItem(COMPONENT_STYLE_DATA_KEY);
  } catch (e) {
    console.warn('Fail to decode style data', e);
  }
  return null;
}

/**
 * 获取组件交互数据
 */
export async function getCopiedCompInteractionData(): Promise<IInteractionWithPrivateDeployment | null> {
  try {
    let item;
    if (MockClipboard.clipboard.isSupport()) {
      const data = await MockClipboard.getBoardAsync();
      item = data.get(ClipboardType.Mock_Interaction);
      item = JSON.parse(item);
    } else {
      item = await storages.indexedDB.getItem(COMPONENT_INTERACTION_DATA_KEY);
    }
    return item;
  } catch (e) {
    console.warn('Fail to decode component interaction data', e);
  }
  return null;
}

/**
 * 获取表格组件单元格存储在indexedDB中的数据
 */
export async function getCopiedTableCellsData(): Promise<ITableClipboardData | null> {
  try {
    return storages.indexedDB.getItem(TABLE_CELLS_DATA_KEY);
  } catch (e) {
    console.warn('Fail to decode table cells data', e);
  }
  return null;
}

export function clearTextFormatFromClipboard(text: string, wrap?: boolean) {
  return filterHTMLText(text, wrap);
}

function singleCellText(text: string) {
  return {
    cells: [[{ ...cloneDeep(DefaultCell), data: { text } }]],
  };
}

/**
 *   重写解析table
 *   作为独立解析table部分
 * 格式化dom table to table组件数据
 */
function parseDomTableToExcelTable(doc: Document) {
  let colgroups = doc.querySelectorAll('table colgroup');
  let trs = doc.querySelectorAll<HTMLTableRowElement>('table tr');
  let tableClipboardData = {} as ITableClipboardData;
  const colWidths: Array<number | undefined> = new Array(trs.length);
  const rowHeights: Array<number | undefined> = new Array(trs.length);
  const tableCells: ITableCell[][] = Array.from(new Array(trs.length), () => []);

  function getWidth(el: HTMLElement) {
    let value: string | number = el.getAttribute('width') || el.style.width;
    value = parseInt(value);
    return isNaN(value) ? undefined : value;
  }
  function getHeight(el: HTMLElement) {
    let value: string | number = el.getAttribute('height') || el.style.height;
    value = parseInt(value);
    return isNaN(value) ? undefined : value;
  }
  function clampMax(value: number | undefined, newValue: number | undefined) {
    if (typeof newValue === 'number' && typeof value === 'number') {
      return Math.max(newValue, value);
    } else if (typeof newValue === 'number') {
      return newValue;
    }
    return value;
  }

  function getText(elem: HTMLElement) {
    let ret = '',
      nodeType = elem.nodeType;
    const ELEMENT_NODE = Node.ELEMENT_NODE,
      DOCUMENT_NODE = Node.DOCUMENT_NODE,
      DOCUMENT_FRAGMENT_NODE = Node.DOCUMENT_FRAGMENT_NODE;
    const TEXT_NODE = Node.TEXT_NODE,
      CDATA_SECTION_NODE = Node.CDATA_SECTION_NODE;
    let currentElem;
    if (nodeType === ELEMENT_NODE && elem.nodeName == 'BR') {
      ret += '\n';
    } else if (nodeType === ELEMENT_NODE || nodeType === DOCUMENT_NODE || nodeType === DOCUMENT_FRAGMENT_NODE) {
      for (currentElem = elem.firstChild; currentElem; currentElem = currentElem.nextSibling) {
        ret += getText(currentElem as HTMLElement);
      }
    } else if (nodeType === TEXT_NODE || nodeType === CDATA_SECTION_NODE) {
      return elem.nodeValue;
    }
    return ret;
  }
  // 解析colgroup
  if (colgroups.length > 0) {
    let firstColGroup = colgroups[0];
    if (firstColGroup.nodeType === Node.ELEMENT_NODE) {
      let cols = Array.from(firstColGroup.querySelectorAll('col'));
      cols.forEach((col, index) => {
        colWidths[index] = getWidth(col);
      });
    }
  }
  // 解析rows
  for (let r = 0, rlen = trs.length; r < rlen; r++) {
    const tr = trs[r];
    let currentCells: ITableCell[] = tableCells[r]; // 当前行单元格
    // 设置tr高
    rowHeights[r] = getHeight(tr);
    let cellIndex = 0;
    for (let c = 0, clen = tr.cells.length; c < clen; c++) {
      // 如果不为空，代表被填充，就继续往下填充
      while (currentCells[cellIndex]) {
        cellIndex++;
      }
      const cell = tr.cells[c];
      let rowSpan = cell.rowSpan,
        colSpan = cell.colSpan;
      let text = getText(cell);
      let cellData = {
        ...DefaultCell,
        style: {} as ICellStyle,
        data: {
          text: text,
        },
      };
      rowHeights[r] = clampMax(rowHeights[r], getHeight(cell));
      if (rowSpan > 0) {
        cellData.mergeDown = rowSpan - 1;
      }
      if (colSpan > 0) {
        cellData.mergeAcross = colSpan - 1;
      }

      currentCells[cellIndex] = cellData as ITableCell;

      for (let r2 = 1, mergeRowIndex = r; r2 <= rowSpan; r2++, mergeRowIndex++) {
        for (let c2 = mergeRowIndex === r ? 1 : 0; c2 < colSpan; c2++) {
          let mergeData = {
            ...DefaultCell,
            style: {} as ICellStyle,
            data: {
              text: '',
            },
            mergedBy: [cellIndex, r],
          } as ITableCell;
          tableCells[mergeRowIndex][cellIndex + c2] = mergeData;
        }
      }
      cellIndex += colSpan;
    }
  }
  tableClipboardData.cells = tableCells;
  tableClipboardData.colWidths = colWidths;
  tableClipboardData.rowHeights = rowHeights;
  return tableClipboardData;
}
/**
 * 重写
 * 新的解析excel to table
 */
export async function parseClipboardExcelToTable(e: ClipboardEvent): Promise<ITableClipboardData | undefined> {
  const { clipboardData } = e;
  if (!clipboardData) {
    return;
  }
  try {
    const { types } = clipboardData;
    if (types.includes('text/html') && clipboardData.getData('text/plain') !== ClipboardType.Table_Copy) {
      let html = clipboardData.getData('text/html');
      // 将html转换为dom
      let doc = new DOMParser().parseFromString(html, 'text/html');
      let table = doc.querySelector('table');
      if (table) {
        return parseDomTableToExcelTable(doc);
      } else {
        if (doc.body.innerText.trim() === '') {
          return singleCellText('');
        }
        const isInnerCopy = html.indexOf(ClipboardType.RICH_HTML_COPY) !== -1; // 识别是否来自系统内部富文本的复制，如果为true,保留样式,否则不保留样式
        let words = sanitizeHtml(html, {
          allowedTags: ['span', 'ol', 'ul', 'div', 'a', 'li'],
          allowedAttributes: isInnerCopy
            ? {
                '*': ['style'],
              }
            : {
                a: ['href'],
              },
        });
        words = words.replace(/^[\r\n]+|[\r\n]+$/g, ''); // 去掉粘贴时自动出现的首尾换行符
        const cell = singleCellText(words);
        if (isInnerCopy) {
          // 内部复制时，最外层元素的文本与垂直，不随元素
          let tempWrap = document.createElement('div');
          tempWrap.innerHTML = words;
          const current = tempWrap.firstElementChild as HTMLElement;
          if (current && current.style.textAlign) {
            cell.cells[0][0].style.textAlign = current.style.textAlign as TextAlign;
            current.style.textAlign = '';
          }
          if (current && current.style.verticalAlign) {
            cell.cells[0][0].style.verticalAlign = current.style.verticalAlign as VerticalAlign;
            current.style.verticalAlign = '';
          }
          cell.cells[0][0].data.text = tempWrap.innerHTML;
        }
        return cell;
      }
      // 读取所有
    } else if (types.includes('text/plain')) {
      const text = clipboardData.getData('text/plain');
      if (text === ClipboardType.Table_Copy) {
        const clipboardData = await getCopiedTableCellsData();
        if (clipboardData) {
          return clipboardData;
        } else {
          return singleCellText(text);
        }
      }
      return singleCellText(text);
    } else {
      return singleCellText('');
    }
  } catch (e) {
    console.log('paste error');
  }
}
