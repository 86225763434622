/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { isEqual, isUndefined, escapeRegExp, isEmpty } from 'lodash';
import * as assert from 'assert';
import * as sanitizeHtml from 'sanitize-html';

import {
  allowedTextStyles,
  IMeasureOptions,
  insertOrderedList,
  measureTextSize,
  transBlankChart,
  traverseRichTextByLine,
} from '@utils/textUtils';
import {
  depthClone,
  isEqual0,
  isEqualDate,
  isMoreOrLess,
  isNotEqual0,
  merge,
  notSameNumber,
  round,
  sameNumber,
  simpleMerge,
} from '@utils/globalUtils';
import { Matrix } from '@utils/matrixUtils';

import { IBounds, IBoundsOffset, IPoint, IPosition, ISize } from '@fbs/common/models/common';
import { EventType, IActionBase, IActionParams, IInteraction, IFragmentAction } from '@fbs/rp/models/interactions';
import { IComponentValue, ILineValue, IPathValue } from '@fbs/rp/models/value';
import { HorizontalAlign, IBasicLayout, ILayout, VerticalAlign } from '@fbs/rp/models/layout';
import { MoveDelta } from '@fbs/common/models/resize';
import { Select } from '@fbs/rp/models/select';
import {
  ETextBehaviour,
  IComponentData,
  IComponentSize,
  IComponentState,
  IComponentStateData,
  IRemark,
  IExportItemData,
} from '@fbs/rp/models/component';
import { IProperties, PropertyName, PropertyValue } from '@fbs/rp/models/property';
import { default as ITextFormat, TextAlign, TextPropertyName } from '@fbs/rp/models/properties/text';
import { ArtboardPatches, ComponentPatches, Operation, Ops } from '@fbs/rp/utils/patch';
import IMultiText, { MultiTextPropertyName } from '@fbs/rp/models/properties/multiText';
import IStroke, { StrokePosition, StrokePropertyName } from '@fbs/rp/models/properties/stroke';
import { default as IRadius /* RadiusPropertyName */ } from '@fbs/rp/models/properties/radius';
import { default as IInputModel, InputModel, InputModelPropertyName } from '@fbs/rp/models/properties/inputModel';
import ITextFormatEx, { TextFormatExPropertyName } from '@fbs/rp/models/properties/textFormat';
import { AnimateEffects } from '@fbs/rp/models/event';
import { ImgPropertyName } from '@/fbs/rp/models/properties/img';

import {
  findChildrenByFiltering,
  findParentByFiltering,
  getBoundsInParent,
  getChangedValue,
  getCheckedTextStylePatchesWhenMainTextStyleChange,
  getCompAbsoluteMatrix,
  getPadding,
  mapPositionToTargetCoordinates,
  mapVectorToTargetCoordinates,
  translateSizeMode,
  // transRadius,
  setValueWithFontStyle,
  getTextCompInSealed,
  getTextCompInListSealed,
  getImageCompInListSealed,
} from '@helpers/componentHelper';
import { getSmartLayout } from '@helpers/responseLayoutHelper';
import { ComponentSizeMode } from '@helpers/resizeHelper';
import * as refHelper from '@helpers/refHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { coverPatches, mergePatches } from '@helpers/patchHelper';
import { getCenter, getNWPoint } from '@helpers/rotateHelper';
import { isClosedPathWithArea, onPathValueZoom, transformPathDataToPath } from '@helpers/pathHelper';
import { IFlipModel, updatePatchesByWhenFlip } from '@helpers/flipHelper';
import {
  upgradeTextFormatForApply,
  upgradeTextFormatForDisplay,
  adaptCompUpdateProperty,
} from '@/helpers/propertiesHelper';
import appOptions from '@/helpers/appOptions';

import { GeneralPropertyName } from '@consts/types/component';
import ValueEditorType, { isTextEditorType } from '@consts/enums/valueEditorType';
import { PredefinedStates } from '@consts/state';
import { CompTextStyleNameConfig, NEED_DEEP_COMPARE_PROPS } from '@consts/component';
import SelectionFrameType from '@consts/enums/selectionBoxType';
import { DefaultFontSize } from '@consts/fonts';

import { convertRemovedComponentsToAddOps } from '@editor/corePartial/helper';
import { ArtboardPatchesClass } from '@editor/patches/artboardPatches';

import { TextCategory } from '@/components/Application/WorkContainer/Workspace/AutoFillPanel/common';

import {
  getComponent,
  getSealedCompoundComponentLib,
  getComponentSupportValueEditorType,
  getLibData,
} from '@libs/libs';
import {
  CTable,
  CLine,
  CRect,
  CEllipse,
  CPolygon,
  CText,
  CImage,
  CPath,
  CIcon,
  CListLayoutPanel,
  CTree,
  CMultipleSelect,
  CNavigationMenu,
  CVerticalMenu,
  CHorizontalMenu,
  CCompoundPath,
  CWrapPanel,
  CGridPanel,
  CPureText,
  CContentPanel,
  CContentPanelV2,
  CParagraph,
  CTextArea,
  CImageTextTabs,
  CSelect,
  CCanvasPanel,
  CButton,
  CInput,
  CFrame,
  CQRCode,
  CNumericStep,
  CStackPanel,
  CVideo,
  CAudio,
  CSnapshot,
  CGroup,
  CSymbol,
  CCarouselChart,
  CHotArea,
  CStickNote,
  CCollapse,
} from '@libs/constants';
import { isRichText, isShapeText, isTextType } from '@libs/helper';
import { IUICompConstructOptions } from '@/customTypes';

import {
  ComponentChange,
  ComponentChangeType,
  ComponentResizeResult,
  DynamicEditInfo,
  EditType,
  getCompChildrenWithoutGroupContainer,
  getCompSizeChangeByOffSet,
  getLineSelectionFrameType,
  getLineValueByZoom,
  getMinSizeOfComp,
  getNewPositionWhenCenter,
  getResizeMySelfLayout,
  InitialResizeInfo,
  ResizeOptions,
  responsiveFixedHeightStrategy,
  responsiveFixedWidthStrategy,
  updateSizeAsLockedRatio,
} from './resizeHelper';

import { getViewBoundsOfComponents } from '../bounds';
import Doc from '../document';
import RotatedComponent from './base/RotatedComponent';
import {
  UIConnectorComponent,
  UIContainerComponent,
  UIMultipleSelectPanelComponent,
  UITextComponent,
  UISymbolComponent,
} from '.';
import { IEventAndHandle } from '../corePartial/interaction';

export type CompValueSettingOption = { wrap?: boolean; size?: ISize; autoFill?: boolean };
export type TextBoundsChangeOption = {
  textFormat?: ITextFormatEx;
  stroke?: IStroke;
  defaultWidth?: number;
  autoFill?: boolean;
};

interface ICustomRegExpExecArray extends RegExpExecArray {
  start: number;
  end: number;
  lineNumber?: number;
}

interface IRichTextNodeOperationInfo {
  inlineNodes: ChildNode[];
  parentNode: Node;
  newChildNode: Node;
}

export interface IFindResult {
  pageID: string;
  component: UIComponent;
  match: ICustomRegExpExecArray;
}

interface IFindReplace {
  matchText(text: string, pageID: string): IFindResult[];
  replaceText(matchInfo: IFindResult['match'], text: string, newText: string): ArtboardPatches;
  replaceAllText(matchInfo: IFindResult['match'], text: string, newText: string): ArtboardPatches;
}

export default class UIComponent extends RotatedComponent implements IFindReplace {
  // 组，专指常规编组后的组
  public get isGroup(): boolean {
    return false;
  }

  public $actionIndex: number = 0; // 演示交互下,面板类显示画板的索引

  // 容器组件
  public readonly isContainer: boolean = false;

  // 画板
  public readonly isArtboard: boolean = false;

  public readonly isConnector: boolean;

  public readonly isTree: boolean;

  private _dynamicInfo: DynamicEditInfo;

  public readonly isCompoundPath: boolean;

  public initialResizeInfo: InitialResizeInfo;

  private _dynamicProperties?: IProperties;

  private _isSymbol: boolean = false;

  protected _isLostSymbol: boolean = false;

  /**
   * 查找替换文字：表示组件正在被定位，组件会据此调整下UI上的东西。
   * 比如：下拉框的下拉菜单在编辑页展示
   */
  protected _isFindReplaceLocating: boolean = false;

  constructor(data: IComponentData, public parent?: UIContainerComponent, public options?: IUICompConstructOptions) {
    super(data, parent, options);
    // 交互为空的数据进行修复
    Object.keys(data.interaction).forEach((k) => {
      data.interaction[k].actions = data.interaction[k].actions.filter((item) => item);
      // 修复交互数据，旧数据赋值pageId,避免复制粘贴跨页面交互丢失
      (data.interaction[k].actions as IFragmentAction[]).forEach((vv) => {
        if (vv.type === 'fragment' && !vv.pageId) {
          vv.pageId = appOptions.pageID;
        }
      });
    });
    this.isConnector = data.type === 'connector';
    this.isCompoundPath = data.type === 'compoundPath';
    this.isTree = data.type === 'tree';
    this.initialResizeInfo = {};
    this._dynamicInfo = {};
    this._isSymbol = data.type === 'symbol';

    if (this.isPreview) {
      const fn = this.libData?.preview?.init;
      if (fn) {
        fn(this.data);
      }
    }

    // 属性兼容旧数据
    adaptCompUpdateProperty(data, this);
  }

  public get dynamicInfo(): DynamicEditInfo {
    return this._dynamicInfo;
  }

  public set dynamicInfo(value: DynamicEditInfo) {
    this._dynamicInfo = value;
  }

  public get isFindReplaceLocating(): boolean {
    return this._isFindReplaceLocating;
  }

  public set isFindReplaceLocating(value: boolean) {
    this._isFindReplaceLocating = value;
    this.updateVision();
    this.updateComponentView?.();
  }

  public get isTable(): boolean {
    return this.type === CTable;
  }

  public get isSymbol(): boolean {
    return this._isSymbol;
  }

  public set isSymbol(value: boolean) {
    this._isSymbol = value;
  }

  get isLostSymbol(): boolean {
    return this._isLostSymbol;
  }
  get canMakeSelectionGroup(): boolean {
    if (this.locked || this.rotate !== 0 || this.type === CLine) {
      return false;
    }
    if ([CRect, CEllipse, CPolygon, CText, CImage, CPath, CIcon].includes(this.type)) {
      return true;
    }
    if (this.isGroup && this instanceof UIContainerComponent) {
      return this.components.every((comp) => comp.type === CLine || comp.canMakeSelectionGroup);
    }
    return false;
  }

  get column(): number {
    return this.data.column || 0;
  }

  get row(): number {
    return this.data.row || 0;
  }

  get hidden(): boolean {
    return !!this.currentState.hidden;
  }

  // 原来：无 lib 时，返回 null
  get libData() {
    if (this.lib) {
      return getComponent(this.lib) || null;
    }
    // 原来：没有lib的组件就是基本、容器，走到这好像肯定是 null
    return getSealedCompoundComponentLib(this.type) || null;
  }

  get sizeMode() {
    const libData = getComponent(this.lib || { id: 'basic', type: this.type }) || null;
    return libData?.sizeMode;
  }

  get document(): Doc | null {
    return this.parent?.document || null;
  }

  get opacity(): number {
    return this.dynamicInfo?.opacity ?? super.opacity;
  }

  get position(): IPosition {
    return this.dynamicInfo?.position || super.position;
  }

  get size(): IComponentSize {
    const dynamicSize = this.dynamicInfo?.size;
    if (dynamicSize) {
      return dynamicSize;
    } else if (this.type === CLine) {
      return this.getLineViewSize();
    }
    return super.size;
  }

  get sizeVersion(): number {
    return this.data.sizeVersion || 0;
  }

  private getLineViewSize() {
    const { value } = this;
    if (typeof value === 'object') {
      const { startPoint, endPoint } = this.value as ILineValue;
      if (startPoint && endPoint) {
        const sizeRotatePosition = {
          width: Math.abs(endPoint.x - startPoint.x),
          height: Math.abs(endPoint.y - startPoint.y),
        };
        const { height, width } = getBoundsInParent({
          size: sizeRotatePosition,
          rotate: this.rotate || 0,
          position: this.position,
        });
        return { height, width };
      }
    }
    return super.size;
  }

  get rotate(): number {
    return super.rotate;
  }

  get isMoving(): boolean {
    return this.dynamicInfo?.editType === EditType.move;
  }

  get isResizing(): boolean {
    return this.dynamicInfo?.editType === EditType.resize;
  }

  /**
   * 用于过滤数据损坏的组件，使得此类组件被选中时不崩溃。（简版实现）
   * 例如：路径组件没有 value 属性。
   */
  get isBroken(): boolean {
    switch (this.type) {
      case CPath:
      case CCompoundPath:
        return !this.value;

      default:
        return false;
    }
  }

  get centerLayoutInfo() {
    const { responsive, horizontal, vertical, auto } = this.layout;
    return {
      horizontal: responsive && !auto && horizontal === HorizontalAlign.Center,
      vertical: responsive && !auto && vertical === VerticalAlign.Middle,
    };
  }

  get rotateRelativeToArtboard(): number {
    let result = this.rotate;
    let parent = this.parent;
    while (parent && !parent.isArtboard) {
      result += parent.rotate;
      parent = parent.parent;
    }
    return result;
  }

  get positionRelativeToArtboard(): IPosition {
    let result = this.position;
    let parent = this.parent;
    while (parent && !parent.isArtboard) {
      result = this.getPositionWithoutParent(result, parent);
      if (parent.parent) parent = parent.parent;
    }
    return result;
  }

  get padding(): { left: number; right: number; bottom: number; top: number } {
    const { padding } = this.properties;
    //如果padding不存在或者padding不可用，则返回全0
    return getPadding(padding);
  }

  /**
   * 组件的内容
   * @returns {IComponentValue | undefined}
   */
  get value(): IComponentValue | undefined {
    const value = super.value;
    if (typeof value === 'string' && refHelper.isRefKey(value)) {
      const nearestSealedComponent = this.nearestSealedComponent;
      if (value === '@value' && nearestSealedComponent) {
        let v = refHelper.getRefValue(value, nearestSealedComponent.currentState);
        if (typeof v === 'string' && v !== '@value') {
          v = v.replace(/@@/g, '@');
        } else {
          v = value;
        }
        return v;
      }
      return value;
    }
    let v = value;
    if (typeof v === 'string' && v !== '@value') {
      v = v.replace(/@@/g, '@');
    }
    let dv = this.dynamicInfo?.value;
    if (dv && typeof dv === 'string') {
      dv = dv.replace(/@@/g, '@');
    }
    return dv || v;
  }

  get text(): string {
    const text = super.text;
    if (refHelper.isRefKey(text)) {
      const owner = this.nearestSealedComponent;
      if (owner) {
        return owner.text;
      }
    }
    return text; //text.replace(/@@/g, '@');
  }

  /**
   * 是否可以旋转
   * @returns {boolean}
   */
  get canRotate(): boolean {
    if (!this.parent) {
      return false;
    }
    const notAllowedRotatedType = [
      CLine,
      CListLayoutPanel,
      CTable,
      CTree,
      'checkbox',
      'checkbox-group',
      'select',
      'radio',
      'radio-button-group',
      'list',
      'numericStep',
      'breadCrumbs',
      CMultipleSelect,
      CNavigationMenu,
      CVerticalMenu,
      CHorizontalMenu,
      CStickNote,
      CCollapse,
    ];

    if (notAllowedRotatedType.includes(this.lib?.type || '') || notAllowedRotatedType.includes(this.type)) {
      return false;
    }
    const parent = this.parent!;
    if (parent!.isSealed) {
      if (parent.lib) {
        const libData = getComponent(parent.lib);
        if (libData && libData.isList) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * 非空图片，并且不是gif图片
   */
  notGifImage(): boolean {
    return this.type === CImage && this.data.value && !this.data.value.toLowerCase().endsWith('.gif');
  }

  /**
   * 是否可以翻转
   */
  get canFlip(): boolean {
    const allowFlipCompTypes = [CLine, CPath, CCompoundPath, CPolygon, CEllipse, CRect];
    const lib = this.libData;

    if (lib?.property?.disabledFlip) {
      return false;
    }
    if (this.isGroup) {
      const allComps = getCompChildrenWithoutGroupContainer((this as unknown) as UIContainerComponent);
      return allComps.every((comp) => allowFlipCompTypes.includes(comp.type) || comp.notGifImage());
    } else {
      return allowFlipCompTypes.includes(this.type) || this.notGifImage();
    }
  }

  get autoSize() {
    const dynamicAutoSize = this.dynamicInfo?.autoSize;
    return dynamicAutoSize === undefined ? super.autoSize : dynamicAutoSize;
  }

  get canConnect(): boolean {
    const parent = this.parent;
    if (parent?.isArtboard && !this.locked && !this.isConnector && this.type !== 'line' && !parent?.rotate) {
      return true;
    }
    //连接线不可连
    //线段不可连
    //锁定不可连
    //父组件旋转后不可连
    if (this.isConnector || this.locked || this.type === 'line' || !!parent?.rotate) {
      return false;
    }
    //复合组件不可连
    if (parent) {
      if (parent.isSealed || parent.isCompoundPath) {
        return false;
      }
      return parent.canConnect;
    }
    return true;
  }

  get constraintInSealedComp() {
    const parent = this.nearestSealedComponent;
    if (parent && parent.lib) {
      const libData = getLibData(parent.lib.type!);
      if (libData) {
        const constraint = libData.constraint;
        if (this.alias && constraint) {
          return constraint[this.alias];
        }
      }
    }
  }
  get constraint() {
    const libType = this.lib?.type;
    if (libType) {
      const libData = getLibData(libType);
      if (libData) {
        return libData.constraint;
      }
    }
  }

  get moveConstrainInSealedComp() {
    const constraint = this.constraintInSealedComp;
    if (constraint) {
      return constraint.move;
    }
  }

  //可以通过手动resize或者属性面板直接改变高度
  get canChangeHeight(): boolean {
    const nearestSealedComponent = this.nearestSealedComponent || (this.lib && this);
    if (nearestSealedComponent && this.alias) {
      const constraint = getLibData(nearestSealedComponent.lib?.type!)?.constraint;
      if (constraint && constraint[this.alias]?.resize) {
        return translateSizeMode(constraint[this.alias].resize!).canChangeHeight;
      }
    }
    return ![SelectionFrameType.none, SelectionFrameType.leftMiddle_to_rightMiddle].includes(this.selectFrameType);
  }

  //可以通过手动resize或者属性面板直接改变宽度
  get canChangeWidth(): boolean {
    if (this.parent?.type === CTable && this.type === CText) return false;
    const nearestSealedComponent = this.nearestSealedComponent || (this.lib && this);
    if (nearestSealedComponent && this.alias) {
      const constraint = getLibData(nearestSealedComponent.lib?.type!)?.constraint;
      if (constraint && constraint[this.alias]?.resize) {
        return translateSizeMode(constraint[this.alias].resize!).canChangeWidth;
      }
    }
    return ![SelectionFrameType.none, SelectionFrameType.topMiddle_to_bottomMiddle].includes(this.selectFrameType);
  }

  get selectFrameType() {
    //分类： 1.在某些父容器内，2.
    //固定宽高，3，不同的组件
    if (this.isInSuchParent((comp) => comp.type === CListLayoutPanel)) {
      return SelectionFrameType.none;
    }
    if (this.layout.responsive) {
      const { fixedHeight, fixedWidth } = this.layout;
      if (!this.layout.auto) {
        if (fixedHeight && !fixedWidth) {
          return SelectionFrameType.leftMiddle_to_rightMiddle;
        }
        if (fixedWidth && !fixedHeight) {
          return SelectionFrameType.topMiddle_to_bottomMiddle;
        }
      }
    }

    if (this.lockedRatio && this.type !== CLine) {
      return SelectionFrameType.corner;
    }
    if (this.type === CWrapPanel) {
      const {
        properties: { layout },
      } = this;
      if (layout?.direction === 'vertical') {
        return SelectionFrameType.topMiddle_to_bottomMiddle;
      } else {
        return SelectionFrameType.leftMiddle_to_rightMiddle;
      }
    }
    if (this.type === CGridPanel) {
      const cell = this.properties.cell;
      if (cell) {
        const { ratioHeight, ratioWidth } = cell;
        if (!ratioHeight && !ratioWidth) {
          return SelectionFrameType.none;
        }
      }
    }
    if (this.type === CPath) {
      if (this.size.height === 0 || this.size.width === 0) {
        return SelectionFrameType.corner;
      } else {
        return SelectionFrameType.box;
      }
    }

    if (this.type === CLine) {
      return getLineSelectionFrameType(this);
    }
    if (this.type === CText) {
      if (this.autoSize) {
        if (this.properties.multiText?.vertical || this.properties.textFormat?.vertical) {
          return SelectionFrameType.topMiddle_to_bottomMiddle;
        }
        return SelectionFrameType.leftMiddle_to_rightMiddle;
      }
      return SelectionFrameType.box;
    }

    const frameType = ComponentSizeMode[this.type];
    return frameType || SelectionFrameType.box;
  }

  get canSetSizeLockRatio() {
    return ![
      SelectionFrameType.topMiddle_to_bottomMiddle,
      SelectionFrameType.leftMiddle_to_rightMiddle,
      SelectionFrameType.none,
    ].includes(this.selectFrameType);
  }

  get isSvg() {
    if (this.type === CImage) {
      const value = this.value;
      if (typeof value === 'string') {
        const index = value.lastIndexOf('.');
        const extension = value.substring(index + 1).toLowerCase();
        return extension === 'svg';
      }
    }
    return false;
  }

  /**
   * 获取组件呈现时的坐标及尺寸样式
   * @param {MoveDelta} offset
   * @param {IPoint} scale
   * @param {number} screenScale
   * @param {boolean | undefined} use3d
   * @returns React.CSSProperties
   */
  getWrapperStyle(offset: MoveDelta, scale: IPoint, screenScale: number, use3d?: boolean): React.CSSProperties {
    let position = this.position;
    const { width, height } = this.size;
    let scaleOffset = {
      x: (width * (scale.x - 1)) / 2,
      y: (height * (scale.y - 1)) / 2,
    };

    //如果是流程线，其大小自动计算，设置属性时不考虑缩放
    if (this.isConnector) {
      scaleOffset = { x: 0, y: 0 };
    }
    const realPositionX = position.x + offset.x;
    const realPositionY = position.y + offset.y;
    const realWidth = width;
    const realHeight = height;
    const scaleStr = scale.x !== 1 || scale.y !== 1 ? `scale(${scale.x}, ${scale.y})` : '';
    const translate = {
      x: realPositionX * screenScale + scaleOffset.x,
      y: realPositionY * screenScale + scaleOffset.y,
    };
    // use3d = false;
    let translateStr;
    let boxShadow;
    let rotate = this.rotate || 0;
    if (!this.isPreview) {
      rotate %= 360;
    }
    if (use3d && this.type !== CLine) {
      translateStr = `translate3d(${translate.x}px, ${translate.y}px, 0) rotate(${rotate}deg)`;
    } else {
      translateStr = `translate(${translate.x}px, ${translate.y}px) rotate(${rotate}deg)`;
      boxShadow = '0 0 1px rgba(255,255,255,0)'; // 芭芭拉魔法--->2D渲染在缩放模式小操作组件出现残影的问题
    }
    const transform = translateStr + scaleStr;
    return {
      transform,
      width: realWidth,
      height: realHeight,
      transformOrigin: this.isConnector ? 'left top' : undefined,
      boxShadow,
    };
  }

  /**
   * 获取动画参数
   * @returns {string}
   */
  getTransition(): string {
    // delay通过流程控制
    if (!this.data._animation || this.data._animation.timing === AnimateEffects.none) {
      return 'unset';
    }
    return `all ${this.data._animation.duration}ms ${this.data._animation.timing}`;
  }

  changeLocked(locked: boolean): ComponentPatches {
    return {
      do: [Ops.replace('/locked', locked)],
      undo: [Ops.replace('/locked', this.locked)],
    };
  }

  shouldFlip(flipModel: IFlipModel, value: boolean) {
    const direction = flipModel === IFlipModel.Horizontal ? 'horizontal' : 'vertical';
    let result;
    if (this.flip === undefined) {
      result = true;
    } else {
      const valueNotChange = this.flip[direction] === value;
      result = !valueNotChange;
    }
    return result;
  }

  resetPositionWhenGroup(newGroupBounds: IBounds): IComponentData {
    // 克隆一份
    const data: IComponentData = depthClone(this.data);
    const position = this.position;
    data.position = {
      x: round(position.x - newGroupBounds.left),
      y: round(position.y - newGroupBounds.top),
    };
    return data;
  }

  resetPositionWhenUnGroup(): IComponentData {
    const data: IComponentData = depthClone(this.data);
    data.position = this.getPositionWithoutParent();
    return data;
  }

  // FIXME 贺瑞丰 2020-01-21 这个方法是不安全的，这里直接修改了源数据，当需要在它之后，生成undo数据的时候，就再也找不到原始的数据了
  resetCenterAnchorWhenGroup(components: UIComponent[]) {
    const layout = getSmartLayout(components, getViewBoundsOfComponents(components)!).get(this)!;
    const { vertical, horizontal, auto } = this.layout;
    if (!auto && horizontal === HorizontalAlign.Center) {
      this.layout.horizontal = layout.horizontal;
    }
    if (!auto && vertical === VerticalAlign.Middle) {
      this.layout.vertical = layout.vertical;
    }
  }

  /**
   * 自身根据父元素的offset，改变了自身的position和size,那么自身也会产生一个offset给自己的子元素
   * @param parentOffset
   * @param changedInfo
   */
  getRealOffsetForChild(parentOffset: IBoundsOffset, changedInfo: ComponentResizeResult) {
    let realOffset: IBoundsOffset = { left: 0, right: 0, top: 0, bottom: 0 };
    const { size, position } = this;
    if (this.rotate) {
      const widthChange = changedInfo.size.width - size.width;
      const heightChange = changedInfo.size.height - size.height;
      if (notSameNumber(parentOffset.top, 0)) {
        realOffset.top = -heightChange;
      }
      if (notSameNumber(parentOffset.bottom, 0)) {
        realOffset.bottom = heightChange;
      }
      if (notSameNumber(parentOffset.left, 0)) {
        realOffset.left = -widthChange;
      }
      if (notSameNumber(parentOffset.right, 0)) {
        realOffset.right = widthChange;
      }
    } else {
      realOffset = {
        left: changedInfo.position.x - position.x,
        top: changedInfo.position.y - position.y,
        right: changedInfo.position.x + changedInfo.size.width - position.x - size.width,
        bottom: changedInfo.position.y + changedInfo.size.height - position.y - size.height,
      };
    }
    return realOffset;
  }

  /**
   *  作为容器如果左上角发生了移动，那么对于子来说相当于坐标系移动了，要将容器在其
   *  父容器中的坐标变化 转换到以组件本身为坐标系的变化
   * @param info
   */
  protected getLeftTopChangeForChildren(info: ComponentResizeResult) {
    const oldLeftTopPoint = this.getBoxPointsInParent()[0];
    const newLeftTopPoint = getNWPoint(getCenter(info.position, info.size, 0), info.size, this.rotate);
    let xchange = newLeftTopPoint.x - oldLeftTopPoint.x;
    let ychange = newLeftTopPoint.y - oldLeftTopPoint.y;
    xchange = sameNumber(xchange, 0) ? 0 : xchange;
    ychange = sameNumber(ychange, 0) ? 0 : ychange;
    const leftTopPointChange = {
      x: xchange,
      y: ychange,
    };
    return mapVectorToTargetCoordinates(leftTopPointChange, -this.rotate);
  }

  /**
   * 对于面板，其大小位置不由组件影响，在做Resize的时候内部组件需要增加一个坐标系原点的变化（组件的左上角）
   * @param afterChangeInfo 组件改变后的值
   */
  getCoordinateOffset(afterChangeInfo: ComponentResizeResult) {
    const originLeftTop = this.getBoxPointsInParent()[0];
    const { rotate, size: newSize, position: newPosition } = afterChangeInfo;
    const newLeftTop = getNWPoint(getCenter(newPosition, newSize, 0), newSize, rotate);
    return mapVectorToTargetCoordinates(
      {
        x: originLeftTop.x - newLeftTop.x,
        y: originLeftTop.y - newLeftTop.y,
      },
      -this.rotate,
    );
  }

  /**
   *
   * @param offset
   * @param layout
   * @param options
   * @param backupLayoutMap
   * @param dragIndex 拖拽点的下标
   */
  resizeHandler2(
    offset: IBoundsOffset,
    layout: IBasicLayout | ILayout,
    options: ResizeOptions,
    // eslint-disable-next-line no-unused-vars
    backupLayoutMap?: WeakMap<UIComponent, IBasicLayout>,
    // eslint-disable-next-line no-unused-vars
    dragIndex?: number,
  ): ComponentResizeResult {
    if (options.container.isResizeMySelf) {
      return this.getResizeMySelfResult(offset, options);
    }
    const size = this.size;
    const originSize = this.currentState.size || this.data.size;
    const position = this.position;
    // 部分类型的组件不能改变大小
    let newPosition = {
      x: position.x,
      y: position.y,
    };
    let newSize = {
      width: size.width,
      height: size.height,
    };
    let isShift = options.shift;
    // 如果我是响应式的，那么我要沿用父容器的shift
    if (this.layout.responsive || this.isContainer) {
      isShift = options.shift;
    } else {
      isShift = true;
    }

    // 组和复合形状组件在非智能布局时，使用基本的布局方式，不应用智能布局
    if ((this.isGroup || this.type === CCompoundPath) && !this.layout.responsive) {
      isShift = true;
    }
    const { before, after, isResponsive: isParentResponsive } = options.container;
    // 抽取出来一个等宽高比的resize方法，然后这里调用
    if (isShift) {
      const h = options.scale.h;
      const v = options.scale.v;
      let newSize = {
        width: size.width * h,
        height: size.height * v,
      };
      //如果父响应式responsive是false的话，并且父shift拉动时，才同时改变我的宽高
      //该变量表示宽高改变是保持比例的
      let isWidthHeightChangeSimultaneously =
        (!isParentResponsive && options.shift) ||
        !!size.lockedRatio ||
        options.shift ||
        (this.isGroup && !this.layout.responsive && options.shift);
      newSize = updateSizeAsLockedRatio(size, newSize, isWidthHeightChangeSimultaneously);
      newPosition = {
        x: after.position.x + (position.x - before.position.x) * h,
        y: after.position.y + (position.y - before.position.y) * v,
      };
      newSize = this.adjustZeroSize(newSize);
      this.updateSizeWhenResizeText(newSize, size);
      // 让固定宽高优先级增高，这样用户能够精确的控制，并且复合组件也更好用
      if (this.layout.responsive && !this.layout.auto) {
        if (layout.fixedHeight) {
          newSize.height = size.height;
          newPosition.y = responsiveFixedHeightStrategy(position, size, offset, layout, options).y;
        }
        if (layout.fixedWidth) {
          newSize.width = size.width;
          newPosition.x = responsiveFixedWidthStrategy(position, size, offset, layout, options).x;
        }
      }
      newSize = {
        height: round(newSize.height),
        width: round(newSize.width),
      };
      return {
        position: newPosition,
        // 如果父容器非响应式，那么父shift拉动非顶点时，会同时改变子的宽高
        size: newSize,
        rotate: this.rotate,
      };
    }
    const newRotate = this.rotate;
    let newLeftTopPoint = this.getBoxPointsInParent()[0];
    if (layout.fixedWidth) {
      switch (layout.horizontal) {
        case HorizontalAlign.Left:
          newPosition.x = position.x + offset.left;
          break;
        case HorizontalAlign.Right:
          newPosition.x = position.x + offset.right;
          break;
        case HorizontalAlign.LeftAndRight:
          // BUG!
          assert.ok(false, '组件不能既固定宽度，又两边都锚定.');
          break;
        case HorizontalAlign.Auto:
          if (isEqual0(before.size.width - size.width)) {
            newPosition.x = position.x + offset.left + offset.right;
          } else {
            //将组件在组中的初始分布的位置记录下来，在resize过程中保持此比例不变
            if (!this.initialResizeInfo.xDistributionRatio) {
              this.initialResizeInfo.xDistributionRatio =
                (position.x - before.position.x) / (before.size.width - size.width);
            }
            newPosition.x =
              this.initialResizeInfo.xDistributionRatio * (after.size.width - size.width) + after.position.x;
          }
          break;
        case HorizontalAlign.Center:
          newPosition.x = after.position.x + after.size.width / 2 - size.width / 2;
          break;
        default:
          assert.ok(false, '不该有这种情况');
          break;
      }
    } else {
      switch (layout.horizontal) {
        case HorizontalAlign.Auto:
          newPosition.x = after.position.x + (position.x - before.position.x) * options.scale.h;
          newSize.width = size.width * options.scale.h;
          newLeftTopPoint.x = getNWPoint(getCenter(newPosition, newSize, 0), newSize, this.rotate).x;
          break;
        case HorizontalAlign.Center:
          newSize.width = size.width * options.scale.h;
          newPosition.x = after.position.x + after.size.width / 2 - newSize.width / 2;
          break;
        case HorizontalAlign.Right: {
          newSize.width = size.width * options.scale.h;
          //要保持右边距不变
          const originRight = before.size.width - position.x - size.width;
          newPosition.x = after.size.width - newSize.width - originRight + offset.left;
          if (notSameNumber(offset.right, 0)) {
            newLeftTopPoint.x = getNWPoint(getCenter(newPosition, newSize, 0), newSize, this.rotate).x;
          }
          break;
        }
        // 只有左右锚定时，大小的变化才不是按照scale来的
        case HorizontalAlign.LeftAndRight: {
          newSize.width = Math.max(getMinSizeOfComp(this).width, size.width + offset.right - offset.left);
          const vector = mapVectorToTargetCoordinates({ x: offset.left, y: 0 }, this.rotate);
          if (this.rotate) {
            if (notSameNumber(offset.left, 0)) {
              newLeftTopPoint.x += vector.x;
              newLeftTopPoint.y += vector.y;
            }
          } else {
            if (!this.initialResizeInfo.right) {
              this.initialResizeInfo.right = before.size.width - position.x - size.width;
            }
            newPosition.x = position.x + offset.left;
            if (this.type === CPath && originSize.width === 0) {
              newSize.width = 0;
            }
          }
          break;
        }
        default:
          if (offset.left !== 0) {
            newPosition.x = position.x + offset.left;
          }
          if (offset.right - offset.left !== 0) {
            newSize.width = size.width * options.scale.h;
          }
          if (notSameNumber(offset.left, 0)) {
            newLeftTopPoint.x = getNWPoint(getCenter(newPosition, newSize, 0), newSize, this.rotate).x;
          }
      }
    }

    if (layout.fixedHeight) {
      switch (layout.vertical) {
        case VerticalAlign.Top:
          newPosition.y = position.y + offset.top;
          break;
        case VerticalAlign.Bottom:
          newPosition.y = position.y + offset.bottom;
          break;
        case VerticalAlign.TopAndBottom:
          // BUG!
          assert.ok(false, '组件不能既固定宽度，又两边都锚定.');
          break;
        case VerticalAlign.Auto:
          if (isEqual0(before.size.height - size.height)) {
            newPosition.y = position.y + offset.top + offset.bottom;
          } else {
            if (!this.initialResizeInfo.yDistributionRatio) {
              this.initialResizeInfo.yDistributionRatio =
                (position.y - before.position.y) / (before.size.height - size.height);
            }
            newPosition.y =
              this.initialResizeInfo.yDistributionRatio * (after.size.height - size.height) + after.position.y;
            newLeftTopPoint.y = getNWPoint(getCenter(newPosition, newSize, 0), newSize, this.rotate).y;
          }
          break;
        case VerticalAlign.Middle:
          newPosition.y = after.position.y + after.size.height / 2 - size.height / 2;
          break;
        default:
          assert.ok(false, '不该有这种情况');
          break;
      }
    } else {
      switch (layout.vertical) {
        case VerticalAlign.Auto:
          newPosition.y = after.position.y + (position.y - before.position.y) * options.scale.v;
          newSize.height = size.height * options.scale.v;
          newLeftTopPoint.y = getNWPoint(getCenter(newPosition, newSize, 0), newSize, this.rotate).y;
          break;
        case VerticalAlign.Middle:
          newPosition.y = after.position.y + after.size.height / 2 - newSize.height / 2;
          newSize.height = size.height * options.scale.v;
          break;
        case VerticalAlign.TopAndBottom: {
          newSize.height = Math.max(getMinSizeOfComp(this).height, size.height + offset.bottom - offset.top);
          const vector = mapVectorToTargetCoordinates({ x: 0, y: offset.top }, this.rotate);
          if (this.type === CPath && originSize.height === 0) {
            newSize.height = 0;
          }
          if (this.rotate) {
            if (offset.top) {
              newLeftTopPoint.y += vector.y;
              newLeftTopPoint.x += vector.x;
            }
          } else {
            newPosition.y = position.y + offset.top;
          }
          break;
        }
        case VerticalAlign.Bottom: {
          newSize.height = size.height * options.scale.v;
          //要保持下边距不变
          const originBottom = before.size.height - position.y - size.height;
          newPosition.y = after.size.height - newSize.height - originBottom + offset.top;
          newLeftTopPoint = getNWPoint(getCenter(newPosition, newSize, 0), newSize, this.rotate);
          break;
        }
        default:
          if (offset.top !== 0) {
            newPosition.y = position.y + offset.top;
          }
          if (offset.bottom - offset.top !== 0) {
            newSize.height = size.height * options.scale.v;
          }
          break;
      }
    }
    newSize = this.adjustZeroSize(newSize);
    this.updateSizeWhenResizeText(newSize, size);

    newSize = this.updateSizeWhenResizeLine(newSize);
    newSize = updateSizeAsLockedRatio(size, newSize, this.lockedRatio);
    newSize = {
      height: round(newSize.height),
      width: round(newSize.width),
    };
    const isSizeChange = notSameNumber(newSize.height, size.height) || notSameNumber(newSize.width, size.width);
    if (this.rotate && isSizeChange) {
      newPosition = getNWPoint(getCenter(newLeftTopPoint, newSize, this.rotate), newSize, 0);
    }
    return {
      position: {
        x: newPosition.x,
        y: newPosition.y,
      },
      size: newSize,
      rotate: newRotate,
    };
  }

  /**
   *
   * @param newSize
   * @param updateSelf 是否更新自身
   */
  getSealedCompSpecialResizePatches(newSize: ISize, updateSelf?: boolean) {
    const fn = this.libData?.editor?.onResize;
    return fn ? fn(this, newSize, updateSelf) : null;
  }

  getResizeMySelfResult(offset: IBoundsOffset, resizeOptions?: ResizeOptions): ComponentResizeResult {
    const { size } = this;
    const LeftTopPointOfComp = this.getBoxPointsInParent()[0];
    const rotateAngle = this.rotate;

    // 将组件的变化转换为左上角顶点的变化
    const NWPointChange = mapVectorToTargetCoordinates({ x: offset.left, y: offset.top }, rotateAngle);
    const newLeftAndTopPoint = depthClone(LeftTopPointOfComp);
    newLeftAndTopPoint.x += NWPointChange.x;
    newLeftAndTopPoint.y += NWPointChange.y;

    //大小变化
    let newSize = depthClone(size);
    const { widthChange, heightChange } = getCompSizeChangeByOffSet(offset, this);
    newSize.width += widthChange!;
    newSize.height += heightChange!;
    newSize = this.adjustZeroSize(newSize);
    this.updateSizeWhenResizeText(newSize, size);
    newSize = updateSizeAsLockedRatio(size, newSize, resizeOptions?.shift || this.lockedRatio);

    //根据左上角点，倒推新的position
    const newCenter = getCenter(newLeftAndTopPoint, newSize, rotateAngle);
    const newPosition = getNWPoint(newCenter, newSize, 0);
    newSize = {
      height: round(newSize.height),
      width: round(newSize.width),
    };
    return {
      position: newPosition,
      size: newSize,
      rotate: rotateAngle,
    };
  }

  updateSizeWhenResizeText(newSize: ISize, oldSize: ISize) {
    if ([CText, CPureText].includes(this.type)) {
      const { textFormat, multiText, textStyle } = this.properties;
      const isVertical = !!(textFormat || multiText)?.vertical;
      const isWidthChange = notSameNumber(newSize.width, oldSize.width);
      const isHeightChange = notSameNumber(newSize.height, oldSize.height);
      const isSizeNotChange = !isWidthChange && !isHeightChange;
      const isWrongDirection = (isVertical && isWidthChange) || (!isVertical && isHeightChange);
      const text = this.value as string;
      const style = this.getStyleToCalculateSize({
        textFormat: textFormat || ({ ...textStyle, ...(multiText || {}), isMulti: !!multiText } as ITextFormatEx),
      });
      if (this.autoSize) {
        if (isSizeNotChange || isWrongDirection) {
          newSize.width = oldSize.width;
          newSize.height = oldSize.height;
          return;
        }
        if (isWidthChange) {
          let option = {
            defaultWidth: newSize.width,
            isMultiText: true,
            wrap: true,
            isRich: isRichText(this.type),
          };
          const { height } = measureTextSize(style, text, option);
          newSize.height = height;
        } else {
          let option = {
            defaultHeight: newSize.height,
            isMultiText: true,
            wrap: true,
          };
          const { width } = measureTextSize(style, text, option);
          newSize.width = width;
        }
      }
    }
  }

  updateSizeWhenResizeLine(newSize: ISize) {
    const { width: originWidth, height: originHeight } = this.toJSON().size;
    if (this.type === CLine) {
      // 水平线
      if (originHeight === 0) {
        return {
          height: 0,
          width: isNotEqual0(newSize.width) ? newSize.width : 1,
        };
      }
      // 垂直线
      else if (originWidth === 0) {
        return {
          height: isNotEqual0(newSize.height) ? newSize.height : 1,
          width: 0,
        };
      } else {
        //斜线
        return {
          height: isNotEqual0(newSize.height) ? newSize.height : 1,
          width: isNotEqual0(newSize.width) ? newSize.width : 1,
        };
      }
    }
    return newSize;
  }

  /**
   * 仅仅自己拉动自己，提供offset，shift就可resize
   * @param offset 左拉left为负值，右拉right为正,上拉top为负，下拉bottom为正
   * @param shift 是否是shift拉动
   */
  resizeMySelf(offset: IBoundsOffset, shift: boolean): ComponentResizeResult {
    const { size, position } = this;
    const layout = getResizeMySelfLayout();

    const containerBefore = {
      position,
      size,
    };
    const afterWidth = size.width + offset.right - offset.left;
    const afterHeight = size.height + offset.bottom - offset.top;
    const containerAfter = {
      position: {
        x: position.x + offset.left,
        y: position.y + offset.top,
      },
      size: {
        width: afterWidth,
        height: afterHeight,
      },
    };
    const childrenResizeOptions = {
      container: {
        before: containerBefore,
        after: containerAfter,
        isResponsive: true,
        isResizeMySelf: true,
      },
      shift,
      scale: {
        h: afterWidth / size.width,
        v: afterHeight / size.height,
      },
    };
    return this.resizeHandler2(offset, layout, childrenResizeOptions);
  }

  flipHandler(flipModel: IFlipModel, value: boolean): ArtboardPatches {
    const artboardPatches = new ArtboardPatchesClass();

    if (this.shouldFlip(flipModel, value)) {
      updatePatchesByWhenFlip(this, flipModel, artboardPatches);
    }
    return artboardPatches;
  }

  updateValueOrPropertiesWithBoundsChanged(newBounds: IBounds): ArtboardPatches | null {
    let patches: ArtboardPatches | null = null;
    switch (this.type) {
      case CPath:
        patches = this.doUpdatePathValue(newBounds);
        break;
      case CCompoundPath:
        patches = this.doUpdateCompoundPathValue(newBounds);
        break;
      case CLine:
        patches = this.doUpdateLineValue(newBounds);
        break;
      case CRect:
        patches = this.doUpdateRectRadius(newBounds);
        break;
      case CTable:
        patches = this.doUpdateTableValues(newBounds);
        break;
      default:
        break;
    }
    const fn = this.libData?.editor?.onResize;
    if (fn) {
      const artboardPatch = fn(this, newBounds);
      !patches ? (patches = artboardPatch) : artboardPatch && coverPatches(patches, artboardPatch);
    }
    return patches;
  }

  /**
   * 当尺寸改变时，更新路径组件的路径数据
   * @author Matt
   * @since 2019-12-2
   * @param {IBounds} newBounds 调整尺寸后的新尺寸
   * @returns {ArtboardPatches | null}
   */
  private doUpdatePathValue(newBounds: IBounds): ArtboardPatches | null {
    // 1.获取原来的大小
    const oldSize = this.currentState.size || this.data.size;
    if (this.type === CPath) {
      if (newBounds.width === oldSize.width && newBounds.height === oldSize.height) {
        return null;
      }
      //2. 实时resize过程中的大小 与原来大小比较  得到scale倍数
      const scale = {
        x: newBounds.width / (oldSize.width || 1),
        y: newBounds.height / (oldSize.height || 1),
      };
      //3.将scale的倍数应用到point上
      const value = (this.currentState.value || this.data.value) as IPathValue;
      const newValue: IPathValue = onPathValueZoom(value, scale);
      const path = [CCompoundPath, CPath].includes(this.type) ? '/value' : this.getCurrentPropertiesPath('/value');
      return {
        do: {
          [this.id]: [Ops.replace(path, newValue)],
        },
        undo: {
          [this.id]: [Ops.replace(path, value)],
        },
      };
    }
    return null;
  }

  /**
   * 当尺寸改变时，更新表格组件的value, 见UITableComponent
   * @param newBounds
   */
  // eslint-disable-next-line no-unused-vars
  protected doUpdateTableValues(newBounds: IBounds): ArtboardPatches | null {
    return null;
  }

  /**
   * 当尺寸改变时，更新复合路径组件的路径数据, 见UIComponudComponent
   * @param {IBounds} newBounds 调整尺寸后的新尺寸
   * @returns {ArtboardPatches | null}
   */
  // eslint-disable-next-line no-unused-vars
  protected doUpdateCompoundPathValue = (newBounds: IBounds): ArtboardPatches | null => {
    return null;
  };

  /**
   * 调整尺寸时，更新线段端点数据
   * @param {IBounds} newBounds
   * @returns {ArtboardPatches | null}
   */
  private doUpdateLineValue(newBounds: IBounds): ArtboardPatches | null {
    const oldSize = this.size;
    if (this.type === CLine) {
      const scale = {
        x: newBounds.width / (oldSize.width || 0.1),
        y: newBounds.height / (oldSize.height || 0.1),
      };
      let { startPoint: _start, endPoint: _end } = this.value as ILineValue;
      _start = _start || { x: 0, y: 0 };
      _end = _end || { x: this.toJSON().size.width, y: this.toJSON().size.height };
      const { startPoint, endPoint } = getLineValueByZoom({ startPoint: _start, endPoint: _end }, scale);
      const adjustPoint = (pt: IPoint) => {
        if (isMoreOrLess(pt.x, 0, 0.5)) {
          pt.x = 0;
        } else if (isMoreOrLess(pt.x, newBounds.width, 0.5)) {
          pt.x = newBounds.width;
        }
        if (isMoreOrLess(pt.y, 0, 0.5)) {
          pt.y = 0;
        } else if (isMoreOrLess(pt.y, newBounds.height, 0.5)) {
          pt.y = newBounds.height;
        }
      };
      adjustPoint(startPoint);
      adjustPoint(endPoint);

      const newValue: ILineValue = {
        startPoint,
        endPoint,
      };

      const path = [CCompoundPath, CPath].includes(this.type) ? '/value' : this.getCurrentPropertiesPath('/value');
      return {
        do: {
          [this.id]: [Ops.replace(path, newValue)],
        },
        undo: {
          [this.id]: [Ops.replace(path, this.value)],
        },
      };
    }

    return null;
  }

  /**
   * 更新矩形组件因尺寸变化引起的圆角变化
   * @param {IBounds} newBouds
   * @returns {ComponentPatches | null}
   */
  private doUpdateRectRadius(newBouds: IBounds): ArtboardPatches | null {
    const { radius } = this.properties;
    if (radius && !radius.disabled && !radius.isPercent) {
      const { width, height } = newBouds;
      const maxRadius = Math.round(Math.min(width, height) / 2);
      let { topLeft, topRight, bottomRight, bottomLeft } = radius;
      const adjustRadius = (value?: number): number | undefined => {
        if (value) {
          return Math.min(value, maxRadius);
        }
        return value;
      };
      topLeft = adjustRadius(topLeft);
      topRight = adjustRadius(topRight);
      bottomLeft = adjustRadius(bottomLeft);
      bottomRight = adjustRadius(bottomRight);
      // 仅当产生变化时，才进行更新
      if (
        topLeft !== radius.topLeft ||
        topRight !== radius.topRight ||
        bottomLeft !== radius.bottomLeft ||
        bottomRight !== radius.bottomRight
      ) {
        const newRadius: IRadius = {
          ...radius,
          topLeft,
          topRight,
          bottomLeft,
          bottomRight,
        };
        let path = '/properties/radius';
        if (this.data._currentState) {
          path = this.getCurrentPropertiesPath(path);
        }
        return {
          do: {
            [this.id]: [Ops.replace(path, newRadius)],
          },
          undo: {
            [this.id]: [Ops.replace(path, radius)],
          },
        };
      }
    }
    return null;
  }

  /**
   * 修改组件自身的选中
   * @param {*} value
   * @memberof UIComponent
   */
  changeSelfSelected(value: boolean) {
    return this.modifyGeneralProperties('selected', value);
  }

  private doModifySelectedState(selected: boolean): ArtboardPatches {
    const selectState = this.states[PredefinedStates.checked];
    const patches: ArtboardPatches = { do: {}, undo: {} };
    const appendOperationToPatches = (id: string, opt?: ComponentPatches) => {
      if (!opt) {
        return;
      }
      if (patches.do[id]) {
        patches.do[id].push(...opt.do);
        patches.undo[id].push(...opt.undo);
      } else {
        patches.do[id] = opt.do;
        patches.undo[id] = opt.undo;
      }
    };
    if (selected) {
      if (!selectState || !(selectState as IComponentState).enabled) {
        const stateOps = this.changeStateEnabled(PredefinedStates.checked, true);
        appendOperationToPatches(this.id, stateOps);
      }
    } else {
      if (selectState) {
        const stateOps = this.changeStateEnabled(PredefinedStates.checked, false);
        appendOperationToPatches(this.id, stateOps);
      }
    }
    let owner = this.isSealed ? this : this.nearestSealedComponent;
    owner = owner?.nearestSealedComponent || owner;
    const fn = owner?.libData?.editor?.onChildSelectedChange;
    if (fn) {
      const opt = fn(owner as UIContainerComponent, this, selected);
      if (opt) {
        const ids = Object.keys(opt.do);
        ids.forEach((id) => {
          appendOperationToPatches(id, {
            do: opt.do[id],
            undo: opt.undo[id],
          });
        });
      }
    } else {
      const select = owner?.select;
      if (select?.target === 'child' || owner?.type === CContentPanel || owner?.type === CContentPanelV2) {
        const maxCount = select?.target === 'child' ? select.maxCount : 1;
        if (selected && maxCount !== -1) {
          const selectSiblingComps = (owner as UIContainerComponent).components.filter((comp) => comp.selected);
          if (selectSiblingComps.length >= maxCount) {
            const count = selectSiblingComps.length - maxCount;
            for (let i = 0; i <= count; i++) {
              const opt = selectSiblingComps[i].changeStateEnabled(PredefinedStates.checked, false);
              if (opt) {
                opt.do.push(Ops.replace('./selected', false));
                opt.undo.push(Ops.replace('./selected', selectSiblingComps[i].selected));
              }
              appendOperationToPatches(selectSiblingComps[i].id, opt);
            }
          }
        }
      }
    }

    if (selected && this.disabled) {
      appendOperationToPatches(this.id, {
        do: [Ops.replace('/disabled', false)],
        undo: [Ops.replace('/disabled', true)],
      });
    }
    return patches;
  }

  /**
   * 修改组件通用属性
   * @param name
   * @param value
   * @returns {ComponentPatches}
   */
  modifyGeneralProperties(name: GeneralPropertyName, value: any): ArtboardPatches {
    const currentStateID = this.currentStateID;
    this._dynamicInfo = {};
    const updateDisabledState = () => {
      // 所有组件都是支持禁用的
      const disabledState = this.states[PredefinedStates.disabled];
      if (value) {
        if (!disabledState || !(disabledState as IComponentState).enabled) {
          const stateOps = this.changeStateEnabled(PredefinedStates.disabled, true);
          if (stateOps) {
            ops.do.push(...stateOps.do);
            ops.undo.push(...stateOps.undo);
          }
        }
      } else {
        if (disabledState) {
          const stateOps = this.changeStateEnabled(PredefinedStates.disabled, false);
          if (stateOps) {
            ops.do.push(...stateOps.do);
            ops.undo.push(...stateOps.undo);
          }
        }
      }
      if (value && this.selected) {
        ops.do.push(Ops.replace('/selected', false));
        ops.undo.push(Ops.replace('/selected', true));
      }
      // 启用和禁用时，同步一下hidden信息
      if (value) {
        ops.do.push(Ops.replace(`./states/${PredefinedStates.disabled}/hidden`, !!this.hidden));
        // 如果disabledStated存在，才支持undo，不然disabled会自动删除
        disabledState && ops.undo.push(Ops.replace(`./states/${PredefinedStates.disabled}/hidden`, !!this.hidden));
      } else {
        if (disabledState) {
          ops.do.push(Ops.replace(`/hidden`, !!disabledState.hidden));
          ops.undo.push(Ops.replace(`/hidden`, !disabledState.hidden));
        }
      }
    };

    const isBaseProperty = ['name', 'disabled', 'selected'].indexOf(name) !== -1;
    const path = isBaseProperty ? `/${name}` : this.getCurrentPropertiesPath(name);

    const ops: ComponentPatches = {
      do: [Ops.replace(path, value)],
      undo: [Ops.replace(path, this[name] != undefined ? this[name] : this.data[name])],
    };
    if (!isBaseProperty) {
      if (currentStateID && currentStateID !== 'normal') {
        if (!this.hasState(currentStateID)) {
          ops.do.unshift(Ops.add(`./states/${currentStateID}`, { properties: {} }));
          ops.undo.push(Ops.remove(`./states/${currentStateID}`));
        }
      }
    }
    let patches: ArtboardPatches = { do: {}, undo: {} };
    if (name === 'disabled') {
      updateDisabledState();
    } else if (name === 'selected') {
      patches = this.doModifySelectedState(value);
    }
    if (patches.do[this.id]) {
      patches.do[this.id].unshift(...ops.do);
      patches.undo[this.id].unshift(...ops.undo);
    } else {
      patches.do[this.id] = ops.do;
      patches.undo[this.id] = ops.undo;
    }
    return patches;
  }

  getTextPositionBySizeChange(newSize: ISize) {
    const { rotate, size } = this;
    const { textStyle, textFormat, multiText } = this.properties;
    const textAlign = textFormat?.textAlign || textStyle?.textAlign || TextAlign.left;
    const originCenter = getCenter(this.getBoxPointsInParent()[0], size, rotate);
    const originLeftTopPoint = this.getBoxPointsInParent()[0];
    // 此处是纵向的逻辑
    if (textFormat?.vertical || multiText?.vertical) {
      switch (textAlign) {
        case TextAlign.left: {
          const vector = mapVectorToTargetCoordinates({ x: -newSize.width, y: 0 }, rotate);
          const originRightTopPoint = this.getBoxPointsInParent()[1];
          const newLeftTopPoint = {
            x: originRightTopPoint.x + vector.x,
            y: originRightTopPoint.y + vector.y,
          };
          const newCenter = getCenter(newLeftTopPoint, newSize, rotate);
          return getNWPoint(newCenter, newSize, 0);
        }
        case TextAlign.center: {
          // 如果居中对齐时，宽度没有改变的话，那么中心的位置和之前一样
          if (sameNumber(newSize.width, size.width)) {
            return getNWPoint(originCenter, newSize, 0);
          }
          // 宽度如果改变了，右上角是不会变的，根据这个特点，可以计算出新的position
          else {
            const vector = mapVectorToTargetCoordinates({ x: -newSize.width, y: 0 }, rotate);
            const originRightTopPoint = this.getBoxPointsInParent()[1];
            const newLeftTopPoint = {
              x: originRightTopPoint.x + vector.x,
              y: originRightTopPoint.y + vector.y,
            };
            const newCenter = getCenter(newLeftTopPoint, newSize, rotate);
            return getNWPoint(newCenter, newSize, 0);
          }
        }
        case TextAlign.right: {
          // 右下角始终是保持不动的，那么我们根据右下角推出左上角的位置
          const vector = mapVectorToTargetCoordinates({ x: -newSize.width, y: -newSize.height }, rotate);
          const originRightBottomPoint = this.getBoxPointsInParent()[2];
          const newLeftTopPoint = {
            x: originRightBottomPoint.x + vector.x,
            y: originRightBottomPoint.y + vector.y,
          };
          const newCenter = getCenter(newLeftTopPoint, newSize, rotate);
          return getNWPoint(newCenter, newSize, 0);
        }
        default: {
          const vector = mapVectorToTargetCoordinates({ x: -newSize.width, y: 0 }, rotate);
          const originRightTopPoint = this.getBoxPointsInParent()[1];
          const newLeftTopPoint = {
            x: originRightTopPoint.x + vector.x,
            y: originRightTopPoint.y + vector.y,
          };
          const newCenter = getCenter(newLeftTopPoint, newSize, rotate);
          return getNWPoint(newCenter, newSize, 0);
        }
      }
    } else {
      switch (textAlign) {
        //需要维持左上角不动

        case TextAlign.left: {
          const newCenter = getCenter(originLeftTopPoint, newSize, rotate);
          return getNWPoint(newCenter, newSize, 0);
        }
        case TextAlign.center: {
          // 如果居中对齐时，高度没有改变的话，那么中心的位置和之前一样
          if (sameNumber(newSize.height, size.height)) {
            return getNWPoint(originCenter, newSize, 0);
          }
          // 高度如果改变了，左上角是不会变的，根据这个特点，可以计算出新的position
          else {
            // FIXME 这个获取中间坐标是不是有问题哦
            // const newCenter = getCenter(originLeftTopPoint, newSize, rotate);
            // return getNWPoint(newCenter, newSize, 0);
            return {
              x: (size.width - newSize.width) / 2 + originLeftTopPoint.x,
              y: originLeftTopPoint.y,
            };
          }
        }
        case TextAlign.right: {
          // 右上顶点固定不变，我们用右上角的坐标+组件的新宽度来得到新的左上角坐标
          const vector = mapVectorToTargetCoordinates({ x: -newSize.width, y: 0 }, rotate);
          const originRightTopPoint = this.getBoxPointsInParent()[1];
          const newLeftTopPoint = {
            x: originRightTopPoint.x + vector.x,
            y: originRightTopPoint.y + vector.y,
          };
          const newCenter = getCenter(newLeftTopPoint, newSize, rotate);
          return getNWPoint(newCenter, newSize, 0);
        }
        default: {
          const newCenter = getCenter(originLeftTopPoint, newSize, rotate);
          return getNWPoint(newCenter, newSize, 0);
        }
      }
    }
  }

  //临时的替代方案，当更新属性或更新内容时，可能引起内容或属性的更新，从而再次调用了尺寸的更新，
  // 这个字段先临时用来处理锁定状态，后续这块代码要重构，一个方法中，只能做一件事，是否触发另一种数据的改变，由外部调用方判断处理
  private lockBoundsChange: boolean = false;

  /**
   * 该方法用于修改文本时，同步更新组件尺寸，如果当前组件是一个复合组件，则同步更新内部子组件中引用了文本属性及内容的这部分组件的尺寸
   * 该方法仅用于组件内部调用，外部其它类、方法等都不可调用，否则可能引用异常
   * @param {string} text
   * @param options
   * @returns {ArtboardPatches}
   * @memberof UIComponent
   */
  updateBoundsWithTextChange(text: string, options: TextBoundsChangeOption): ArtboardPatches {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    if (this.isSealed) {
      this.updateBoundsWhenSealedCompTextChange(options, patches);
    } else {
      this.updateBoundsWhenBasicTextCompTextChange(options, text, patches);
    }
    return patches;
  }

  /**
   * 基本文本组件文本变化时,更新大小
   * @param options
   * @param text
   * @param patches
   * @private
   */
  private updateBoundsWhenBasicTextCompTextChange(
    options: TextBoundsChangeOption,
    text: string,
    patches: ArtboardPatches,
  ) {
    const valueType = getComponentSupportValueEditorType(this.type, this.lib);
    const isTextComp = [CPureText, CText, CParagraph].indexOf(this.type) !== -1;
    let needCalculateSize = false;
    if (isTextComp && !isUndefined(valueType)) {
      needCalculateSize = isTextEditorType(valueType);
      //TODO Matt 这个方式不好
      if (this.type === CPureText) {
        if (!isUndefined(this.data.autoSize) && !this.autoSize) {
          needCalculateSize = false;
        }
      }
    }
    const textBehaviour = this.toJSON().textBehaviour;
    let wrap =
      options.autoFill ||
      (isUndefined(textBehaviour) ? (options.textFormat?.wrap as boolean) : textBehaviour !== ETextBehaviour.Width);
    const indent = options.textFormat?.indent as boolean;
    const vertical = !!options.textFormat?.vertical;
    if (needCalculateSize) {
      const { size } = this;
      const isTextChange = text !== this.value;
      const autoFillDefaultWidth = 300;
      let option: IMeasureOptions = {
        defaultWidth: options.autoFill ? autoFillDefaultWidth : size.width,
        defaultHeight: undefined,
        isMultiText: [CText, CParagraph, CTextArea].indexOf(this.type) !== -1,
        wrap,
        indent,
        isRich: isRichText(this.type),
        cannotReplaceSpaceChar: this.type === CPureText,
      };
      const { stroke, textFormat, multiText } = this.properties;
      options.stroke = options.stroke || stroke;
      const style = this.getStyleToCalculateSize(options);
      if (option) {
        const fixedWidth = isUndefined(textBehaviour) ? this.autoSize : textBehaviour === ETextBehaviour.Width;
        const isVerticalText = style.writingMode === 'vertical-rl';
        const currentVertical = !!(textFormat?.vertical || multiText?.vertical);
        const isLayoutChange = currentVertical !== vertical;
        if ([CPureText].includes(this.type)) {
          delete option.defaultHeight;
          this.type === CPureText && delete option.defaultWidth;
        } else if (isVerticalText) {
          option.defaultWidth = undefined;
          option.defaultHeight = fixedWidth ? undefined : options.defaultWidth || size.height;
          if (isLayoutChange) {
            option.defaultHeight = size.width;
            option.defaultWidth = size.height;
          }
        } else {
          if (isTextChange) {
            // 文本变化的时候，固定高度自动计算宽度
            if (fixedWidth && !options.autoFill) {
              delete option.defaultWidth;
            }
          }
          if (isLayoutChange) {
            option.defaultHeight = size.width;
            option.defaultWidth = size.height;
          }
        }
      }
      let value = text;
      if ([CPureText].includes(this.type)) {
        value = transBlankChart(value);
      }
      let width: number;
      let height: number;
      if (value.trim()) {
        if (textBehaviour === ETextBehaviour.Both) {
          width = size.width;
          height = size.height;
        } else {
          const textSize = measureTextSize(style, value, option);
          // 文本组件的大小特性
          if (this.type === CText) {
            if (vertical) {
              width = textSize.width;
              height = this.autoSize ? textSize.height : Math.max(size.height, textSize.height);
            } else {
              width = this.autoSize ? textSize.width : Math.max(size.width, textSize.width);
              height = textSize.height;
            }
          } else {
            // 纯文本组件大小特性
            width = textSize.width;
            height = textSize.height;
          }
        }
      } else {
        width = 0;
        height = measureTextSize(style, 'j', option).height;
      }

      // 文本不能超过父容器的限制

      const sizeConstrainByParent = ['list', 'segmentControl', 'button'].includes(this.lib?.type || this.type);
      if (sizeConstrainByParent && width > this.parent!.size.width) {
        width = this.parent?.size.width!;
      }
      const isSizeChanged = !sameNumber(width, size.width) || !sameNumber(height, size.height);
      const isTextFormatVerticalChange = options.textFormat?.vertical !== this.properties.textFormat?.vertical;

      if (isSizeChanged) {
        //根据文字的对齐方式不同，当文字的大小变化时，position跟随调整
        //例如当文字右对齐时，输入文字，文字框大小增加，position.x随宽度的增加而减小
        let newPosition = this.getTextPositionBySizeChange({ height, width });
        if (isTextFormatVerticalChange) {
          newPosition = this.position;
        } else {
          newPosition = this.getTextPositionBySizeChange({ height, width });
        }
        //根据锚定需要做居中设置
        const newSize = { height, width };
        newPosition = getNewPositionWhenCenter(this, newPosition, newSize, this.parent!.size);
        const res = this.parent!.getPositionPatchesOfChildrenChanged(
          [
            {
              id: this.id,
              type: ComponentChangeType.Edit,
              position: newPosition,
              size: {
                width,
                height,
              },
              rotate: this.rotate,
              value: text,
            },
          ],
          true,
        );
        coverPatches(patches, res.patches);
      }
    }
  }

  /**
   * 复合组件文本变化时,更新大小
   * @param options
   * @param patches
   * @private
   */
  private updateBoundsWhenSealedCompTextChange(options: TextBoundsChangeOption, patches: ArtboardPatches) {
    const refComps: UIComponent[] = [];
    const filter = (comps: UIComponent[]) => {
      comps.forEach((comp) => {
        if (!comp.isContainer) {
          const { properties, value } = comp.toJSON();
          const { textStyle } = properties;
          if (textStyle && value && typeof value === 'string') {
            if (refHelper.isRefValue(textStyle)) {
              refComps.push(comp);
            } else if (refHelper.isRefKey(value)) {
              if (this.value) {
                refComps.push(comp);
              }
            }
          }
        } else {
          // @ts-ignore
          filter((comp as UIContainerComponent).components);
        }
      });
    };
    if (this instanceof UIContainerComponent) {
      filter(this.components);
      refComps.forEach((comp) => {
        // 如果是修改的列表式的复合组件，则这里传递的是具体组件本身的文本内容，列表式复合组件不能直接修改到内容
        const p = comp.updateBoundsWithTextChange(comp.value as string, options);
        Object.keys(p.do).forEach((id) => {
          patches.undo[id] = p.undo[id];
          patches.do[id] = p.do[id];
        });
      });
    }
  }

  protected getStyleToCalculateSize(options?: { textFormat?: ITextFormatEx; stroke?: IStroke }) {
    if (!options || !Object.keys(options).length) {
      return {};
    }

    const properties = depthClone(this.toJSON().properties);
    const { textFormat } = properties;

    if (!textFormat || typeof textFormat === 'string') {
      properties.textFormat = options.textFormat;
    } else {
      properties.textFormat = { ...textFormat, ...options.textFormat };
    }
    const parser = StyleHelper.initCSSStyleParser(properties);
    const style = {
      ...parser.getTextStyle(),
    };
    const thickness = options.stroke
      ? !options.stroke?.disabled && options.stroke?.thickness
      : !this.properties.stroke?.disabled && this.properties.stroke?.thickness;
    if (thickness) {
      style.boxSizing = 'border-box';
      style.border = `${thickness}px solid transparent`;
    }
    if (properties.textFormat) {
      if (options?.textFormat?.lineHeightEx !== properties.textFormat?.lineHeightEx) {
        delete style.lineHeight;
      }
    } else if (properties.multiText) {
      if (options?.textFormat?.lineHeightEx !== properties.multiText?.lineHeight) {
        delete style.lineHeight;
      }
    }
    return style;
  }

  // 更新特殊属性
  private updateSpecialProperty(propertyName: string, value: any): ArtboardPatches | null {
    let libData = this.libData;
    if (!libData) {
      libData = getComponent({ id: 'basic', type: this.type }) || null;
    }
    const fn = libData?.editor?.onPropertyUpdate;
    if (fn) {
      return fn(this, propertyName, value);
    }
    return null;
  }

  // 合并文本样式
  private adjustTextFormatStyleValue = (value: ITextFormat, oldValue: ITextFormat): ITextFormat => {
    const oldFontStyle = oldValue.fontStyle;
    if (oldFontStyle && value.fontStyle) {
      const newFontStyle = simpleMerge(value.fontStyle, oldFontStyle);
      return { ...value, fontStyle: newFontStyle };
    }
    return value;
  };

  // 校正属性值
  private adjustPropertyValue(propType: string, newValue: PropertyValue, oldValue: PropertyValue): PropertyValue {
    switch (propType) {
      case TextPropertyName:
        return this.adjustTextFormatStyleValue(newValue as ITextFormat, oldValue as ITextFormat);
      // case RadiusPropertyName:
      //   return transRadius(newValue, oldValue, this.size);
    }
    return newValue;
  }

  /**
   * 强制重置富文本 -> 处理外部属性相同，内部属性不同的情况[降低性能]
   * @param value
   */
  forceResetRichText(value: string) {
    return sanitizeHtml(value, {
      allowedTags: false,
      allowedAttributes: { '*': ['style'] },
      allowedStyles: { '*': {} },
    });
  }

  // 重置富文本  ----- fix: childPropName 使用,分隔支持多个属性的修改
  resetRichText(value: string, format?: ITextFormat, multi?: IMultiText, childPropName?: string): string {
    if (isRichText(this.type)) {
      const allowedStyles = { ...allowedTextStyles };
      const properties = this.properties;
      if (format) {
        let key = format.prop;
        if (!key) {
          if (properties.textFormat) {
            key = TextFormatExPropertyName;
          } else {
            key = TextPropertyName;
          }
        }
        const oldFormat = properties[key] as ITextFormatEx;

        // NOTE 需要知道那个样式变了，即 childPropName

        const oldFormatWithMixedStyleConsidered = upgradeTextFormatForDisplay(
          oldFormat,
          value,
          this.getInheritClassName(),
        );

        const propNames = childPropName?.split(',') || [];
        propNames.forEach((name) => {
          if (name === 'fontSize' && oldFormatWithMixedStyleConsidered?.fontSize !== format.fontSize) {
            delete allowedStyles['font-size'];
          }
          if (name === 'fontFamily' && oldFormatWithMixedStyleConsidered?.fontFamily !== format.fontFamily) {
            delete allowedStyles['font-family'];
          }
          if (name === 'color' && !isEqualDate(oldFormatWithMixedStyleConsidered?.color, format.color)) {
            delete allowedStyles['color'];
          }
        });
        if (oldFormatWithMixedStyleConsidered?.fontStyle && format.fontStyle) {
          if (oldFormatWithMixedStyleConsidered.fontStyle.italic !== format.fontStyle.italic) {
            delete allowedStyles['font-style'];
          }
          if (oldFormatWithMixedStyleConsidered?.fontStyle.bold !== format.fontStyle.bold) {
            delete allowedStyles['font-weight'];
          }
        }
      }
      if (multi) {
        let key = multi.prop;
        if (!key) {
          if (properties.textFormat) {
            key = TextFormatExPropertyName;
          } else {
            key = MultiTextPropertyName;
          }
        }
        const oldProps = properties[key] as IMultiText;

        if (oldProps?.wrap !== multi.wrap) {
          delete allowedStyles['white-space'];
        }
        if (oldProps.lineHeightEx !== multi.lineHeightEx) {
          delete allowedStyles['line-height'];
        }
      }
      const text = insertOrderedList(value, format?.listType);
      return sanitizeHtml(text, {
        allowedTags: false,
        allowedAttributes: { '*': ['style'] },
        allowedStyles: { '*': allowedStyles },
      });
    }
    return value;
  }

  /**
   * 文本组件自动大小
   * @memberof UIComponent
   */
  autoTextSize = () => {
    const text = this.value as string;
    const { textFormat, textStyle, multiText } = this.properties;
    let format = textFormat;
    if (!format) {
      format = {
        ...textStyle,
        ...(multiText || {}),
        isMulti: !!multiText,
      } as ITextFormatEx;
    }
    const style = this.getStyleToCalculateSize({
      textFormat: format,
    });
    const isWrap = format.wrap;
    let option = {
      isMultiText: true,
      wrap: false,
      isRich: isRichText(this.type),
    };

    // 富文本因目前以下样式会直接修改到内容上，所以这里测量的时候，需要同组件上应用这三个样式的方式保持一致
    if (this.type === CText) {
      delete style.textDecorationLine;
      delete style.fontWeight;
      delete style.fontStyle;
    }

    let { width, height } = measureTextSize(style, text, option);
    const widthDiff = width - this.data.size.width;
    const heightDiff = height - this.data.size.height;
    const align = format.textAlign || TextAlign.left;
    const vertical = !!format.vertical;
    let patches: ArtboardPatches = { do: {}, undo: {} };
    let newPosition = { ...this.data.position };
    if (vertical) {
      newPosition.x -= widthDiff;
      if (align === TextAlign.right) {
        newPosition.y -= heightDiff;
      } else if (align === TextAlign.center) {
        newPosition.y -= heightDiff / 2;
      }
    } else {
      if (align === TextAlign.right) {
        newPosition.x -= widthDiff;
      } else if (align === TextAlign.center) {
        newPosition.x -= widthDiff / 2;
      }
    }

    const centerPosition = getNewPositionWhenCenter(this, newPosition, { width, height }, this.parentSize);
    const componentChange: ComponentChange[] = [
      {
        id: this.id,
        type: ComponentChangeType.Edit,
        position: centerPosition,
        size: { width, height },
        rotate: this.rotate || 0,
      },
    ];
    const { patches: patches1 } = this.parent!.getPositionPatchesOfChildrenChanged(componentChange, true);
    coverPatches(patches, patches1);
    if (isWrap) {
      patches.do[this.id] = patches.do[this.id] || [];
      patches.undo[this.id] = patches.undo[this.id] || [];
      if (textFormat) {
        const path = this.getCurrentPropertiesPath('properties/textFormat');
        patches.do[this.id].push(Ops.replace(path, { ...textFormat, wrap: false }));
        patches.undo[this.id].push(Ops.replace(path, textFormat));
      } else if (multiText) {
        const path = this.getCurrentPropertiesPath('properties/multiText');
        patches.do[this.id].push(Ops.replace(path, { ...multiText, wrap: false }));
        patches.undo[this.id].push(Ops.replace(path, multiText));
      }
    }
    return patches;
  };

  /**
   * 更新关联文本属性
   * @param {string} propertyName
   * @param {string} propType
   * @param {ITextFormat} value
   * @returns {ArtboardPatches | null}
   */
  private doUpdateRelationTextProperty(propertyName: string, value: ITextFormat): ArtboardPatches | null {
    //如果是組件整体样式的修改，那么也要同步修改选中文本的样式
    //当前函数拿到的value是整体样式的结果，而不是修改值
    const { properties } = this;
    const type = this.lib?.type || this.type;
    if (CompTextStyleNameConfig[type]?.entireTextStyleName === propertyName) {
      const changedValue = getChangedValue(value as ITextFormat, properties[propertyName] as ITextFormat);
      return getCheckedTextStylePatchesWhenMainTextStyleChange(this, propertyName, changedValue);
    }
    return null;
  }

  /**
   * 更新某些属性内部的关联值
   * @param {string} propertyName
   * @param {string} propType
   * @param {PropertyValue} oldValue
   * @param {PropertyValue} newValue
   * @returns {ArtboardPatches}
   */
  private doUpdateWithAdjustPropertyValue(
    propertyName: string,
    propType: string,
    oldValue: PropertyValue,
    newValue: PropertyValue,
    syncPropertyToStateNormal?: boolean,
  ): ArtboardPatches {
    const val = this.adjustPropertyValue(propType, newValue, oldValue);
    const path = this.getCurrentPropertiesPath(`properties/${propertyName}`, syncPropertyToStateNormal);
    return {
      do: {
        [this.id]: [Ops.replace(path, val)],
      },
      undo: {
        [this.id]: [Ops.replace(path, oldValue)],
      },
    };
  }

  /**
   * 更新输入框因输入类型属性变化引起的值变化
   * @param {string} propertyName
   * @param {PropertyValue} value
   * @returns {ArtboardPatches | null}
   */
  private doUpdateInputModel(propertyName: string, value: PropertyValue): ArtboardPatches | null {
    // 更新因输入模式切换引起的内容改变
    if (propertyName !== InputModelPropertyName) {
      return null;
    }
    if ((value as IInputModel).value === InputModel.numeric) {
      const tx = this.value;
      const pattern = /^-?\d*\.?\d*$/;
      if (tx && !pattern.test(tx as string)) {
        return this.setValue('');
      }
    }
    return null;
  }

  private doUpdateOtherTextChange(
    propertyName: string,
    propType: string,
    newValue: PropertyValue,
    ignoreUpdateValue?: boolean,
    childPropName?: string,
  ): ArtboardPatches[] {
    const patchList: ArtboardPatches[] = [];
    // FIXME Matt 202009-22 验证当前状态中是否包含文本内容，如果不包含，则通过属性修改触发的文本变化不执行
    const { _currentState, states } = this.data;
    if (_currentState && _currentState !== PredefinedStates.normal) {
      const state = states[_currentState];
      if (state) {
        const { value, text } = state;
        if (!value && !text && !this.value) {
          return [];
        }
      }
    }
    if ([TextPropertyName, TextFormatExPropertyName, MultiTextPropertyName, StrokePropertyName].includes(propType)) {
      const type = this.lib?.type || this.type;
      const { properties } = this;
      const oldText = isShapeText(this.type) ? this.text : (this.value as string);
      let format: ITextFormat | undefined;
      let multi: IMultiText | undefined;
      if (propType === TextFormatExPropertyName) {
        format = newValue as ITextFormat;
        multi = newValue as IMultiText;
      } else if (propType === TextPropertyName) {
        format = newValue as ITextFormat;
        multi = properties.multiText;
      } else if (propType === MultiTextPropertyName) {
        multi = newValue as IMultiText;
        format = properties.textStyle;
      } else {
        format = properties.textStyle || properties.textFormat;
        multi = properties.multiText || properties.textFormat;
      }
      const strokeInProperties = properties.stroke?.disabled ? undefined : properties.stroke;
      const stroke = !this.isSealed
        ? propType === StrokePropertyName
          ? (newValue as IStroke)
          : strokeInProperties
        : undefined;

      // NOTE 这里会根据属性处理富文本样式
      const newText = this.resetRichText(oldText, format, multi, childPropName);

      const entireTextStyleName = CompTextStyleNameConfig[type]?.entireTextStyleName;
      const checkedTextStyleName = CompTextStyleNameConfig[type]?.checkedTextStyleName;
      const hoverTextStyleName = CompTextStyleNameConfig[type]?.hoverTextStyleName;
      const isChangeEntireTextStyle = entireTextStyleName === propertyName || propertyName === TextPropertyName;
      const isChangeSelectedTextStyle = checkedTextStyleName === propertyName;
      const isChangeHoveredTextStyle = hoverTextStyleName === propertyName;
      if (!(isChangeSelectedTextStyle || isChangeHoveredTextStyle)) {
        if (isShapeText(this.type)) {
          if (oldText !== newText) {
            patchList.push(this.setText(newText));
          }
        } else {
          const options = {
            textFormat: { ...multi, ...format, ...newValue, isMulti: this.type !== CPureText } as ITextFormatEx,
            stroke,
          };
          if (!this.lockBoundsChange) {
            if (this instanceof UITextComponent || this.autoSize) {
              this.lockBoundsChange = true;
              const compPatches = this.updateBoundsWithTextChange(newText, options);
              if (!ignoreUpdateValue && oldText !== newText && this.canEditRichText) {
                const valuePatches = this.setValue(newText);
                patchList.push(valuePatches);
              }
              patchList.push(compPatches);
            } else if (this.parent?.type === CTable) {
              const newOpt = Object.assign({}, options, { defaultWidth: this.size.width });
              const compPatches = this.updateBoundsWithTextChange(newText, newOpt);
              patchList.push(compPatches);
            }
          }
        }
      }
      // 修改多行文本、单行文本的下划线、删除线、加粗、斜体 固定文本的其他状态不修改内容
      if (
        !ignoreUpdateValue &&
        (propType === TextPropertyName || propType === TextFormatExPropertyName) &&
        isRichText(this.type) &&
        this.canEditRichText
      ) {
        const { fontStyle, listType } = newValue as ITextFormat;

        // TODO 检查 mty
        // FIXME 这里应该只处理变动的的值，而不是一股脑处理。比如 发现设置 textAlign 竟然还会将已有的加粗再执行加粗操作
        const newTextValue = setValueWithFontStyle(
          newText,
          fontStyle,
          (this.properties.textFormat ?? this.properties.textStyle)?.fontStyle,
        );
        const fontValue = insertOrderedList(newTextValue, listType);

        const fontStylePatches: ArtboardPatches = { do: {}, undo: {} };
        const path = this.getCurrentPropertiesPath(isShapeText(this.type) ? 'text' : 'value');
        fontStylePatches.do[this.id] = [Ops.replace(path, fontValue)];
        fontStylePatches.undo[this.id] = [Ops.replace(path, oldText)];
        // mergePatches(fontStylePatches);
        patchList.push(fontStylePatches);
      }
      // 修改复合组件内部富文本的下划线、删除线、加粗、斜体
      const textChildren = this.getChildWhichTypeIsText();
      if (
        (propType === TextPropertyName || propType === TextFormatExPropertyName) &&
        this.isSealed &&
        textChildren.length
      ) {
        if (isChangeEntireTextStyle) {
          textChildren.forEach((comp) => {
            // 修改默认文本样式及选中文本样式
            if (comp.type === CText) {
              const doOps: Operation[] = [];
              const undoOps: Operation[] = [];
              const newText = this.resetValueWithNewFontStyle(comp.value as string, newValue as ITextFormat);
              const patches: ArtboardPatches = { do: {}, undo: {} };
              const path = comp.getCurrentPropertiesPath('value');
              const checkedPath = comp.hasState(PredefinedStates.checked)
                ? comp.getStatePath(PredefinedStates.checked, 'value')
                : undefined;
              doOps.push(Ops.replace(path, newText));
              const originValue = comp.value;
              undoOps.push(Ops.replace(path, originValue));
              if (checkedPath) {
                doOps.push(Ops.replace(checkedPath, newText));
                undoOps.push(Ops.replace(checkedPath, originValue));
              }
              patches.do[comp.id] = doOps;
              patches.undo[comp.id] = undoOps;
              // mergePatches(patches);
              patchList.push(patches);
            }
          });
        } else if (isChangeSelectedTextStyle) {
          textChildren.forEach((comp) => {
            // 修改选中文本样式
            if (comp.type === CText) {
              const newText = this.resetValueWithNewFontStyle(comp.value as string, newValue as ITextFormat);
              const patches: ArtboardPatches = { do: {}, undo: {} };
              const path = comp.hasState(PredefinedStates.checked)
                ? comp.getStatePath(PredefinedStates.checked, 'value')
                : undefined;
              if (!path) {
                return;
              }
              patches.do[comp.id] = [Ops.replace(path, newText)];
              patches.undo[comp.id] = [Ops.replace(path, comp.value)];
              // mergePatches(patches);
              patchList.push(patches);
            }
          });
        } else if (isChangeHoveredTextStyle) {
          textChildren.forEach((comp) => {
            // 修改hover文本样式
            if (comp.type === CText) {
              const newText = this.resetValueWithNewFontStyle(comp.value as string, newValue as ITextFormat);
              const patches: ArtboardPatches = { do: {}, undo: {} };
              const path = comp.hasState(PredefinedStates.hover)
                ? comp.getStatePath(PredefinedStates.hover, 'value')
                : undefined;
              if (!path) {
                return;
              }
              patches.do[comp.id] = [Ops.replace(path, newText)];
              patches.undo[comp.id] = [Ops.replace(path, comp.value)];
              // mergePatches(patches);
              patchList.push(patches);
            }
          });
        }
      }
    }
    this.lockBoundsChange = false;
    return patchList;
  }

  /**
   * 根据组件属性设置富文本属性
   * @param {PropertyName} propertyName
   * @param {string} richValue
   */
  setRichValue(TextFormat: ITextFormat, richValue: string, type: string): ArtboardPatches {
    let patches: ArtboardPatches = { do: {}, undo: {} };
    const newRichValue = sanitizeHtml(richValue, {
      allowedTags: ['span', 'div', 'ol', 'li', 'ul', 'p', 'br'],
      allowedAttributes: { '*': [] },
    });
    const newValue = this.setRichValueWithTextFormat(newRichValue, TextFormat);
    if (type === 'text') {
      patches.do[this.id] = [Ops.replace(`/text`, newValue)];
      patches.undo[this.id] = [Ops.replace(`/text`, richValue)];
    } else {
      patches.do[this.id] = [Ops.replace(`/value`, newValue)];
      patches.undo[this.id] = [Ops.replace(`/value`, richValue)];
    }
    return patches;
  }

  setRichValueWithTextFormat(value: string, format: ITextFormat) {
    return this.resetValueWithNewFontStyle(value, format);
  }

  /**
   * 设置组件属性
   * @param {PropertyName} propertyName
   * @param {PropertyValue} newValue
   * @param {boolean} ignoreUpdateValue 是否忽略对值的更新，默认不忽略
   * @returns {ComponentPatches}
   */
  setProperty(
    propertyName: PropertyName,
    newValue: PropertyValue,
    ignoreUpdateValue?: boolean,
    childPropName?: string,
  ): ArtboardPatches {
    this._dynamicProperties = undefined;
    //patch合并不考虑冲突，完全就1+1 = 2
    const mergePatches = (otherPatches: ArtboardPatches | null) => {
      if (otherPatches) {
        Object.keys(otherPatches.do).forEach((id) => {
          const compDo = otherPatches.do[id];
          const compUndo = otherPatches.undo[id];
          if (compDo.length) {
            if (patches.do[id]) {
              patches.do[id].push(...compDo);
              patches.undo[id].push(...compUndo);
            } else {
              patches.do[id] = compDo;
              patches.undo[id] = compUndo;
            }
          }
        });
      }
    };
    let patches: ArtboardPatches = { do: {}, undo: {} };
    let sealedComp: UIComponent | null | UIContainerComponent;
    const currentState = this.currentState;
    if (!currentState.properties) {
      return patches;
    }
    const val = currentState.properties[propertyName];
    const property = this.properties[propertyName];
    // 多选组件disabled === false的不修改
    if (isUndefined(property) || (property?.disabled && newValue.disabled === undefined)) {
      return patches;
    }

    // 补全属性值，从旧属性中补全新属性中undefined部分
    // 图文选项卡和轮播图组件需要深比较补全undefined部分
    const needDeepCompare = property.prop && NEED_DEEP_COMPARE_PROPS.includes(property.prop);
    const value = simpleMerge(newValue, property, needDeepCompare);
    sealedComp = val && val.ref ? this.nearestSealedComponent : null;
    if (val && val.ref && sealedComp) {
      // sealedComp = this.nearestSealedComponent;
      // if (sealedComp) {
      const sealedPropertyName = val.ref.replace('@properties.', '');
      // 这里因为改的是被引用组件的属性，所以 value 并不完整，必须要把原来的属性合并进来
      patches = sealedComp.setProperty(
        sealedPropertyName,
        Object.assign({}, sealedComp.properties[sealedPropertyName], value),
      );
      // }
    } else {
      const syncPropertyToStateNormal = this.libData?.syncPropertyToStateNormal?.[propertyName];
      let stateID = this.currentStateID;
      const propName = property?.prop || propertyName;
      // 校正新属性值
      const adjustPatch = this.doUpdateWithAdjustPropertyValue(
        propertyName,
        propName,
        property,
        value,
        syncPropertyToStateNormal,
      );
      coverPatches(patches, adjustPatch);
      const relationTextPatch = this.doUpdateRelationTextProperty(propertyName, value as ITextFormat);
      relationTextPatch && coverPatches(patches, relationTextPatch);
      if (!stateID && this.disabled) {
        stateID = PredefinedStates.disabled;
      }

      if (stateID && stateID !== 'normal' && syncPropertyToStateNormal !== true) {
        const state = this.states[stateID];
        if (!state) {
          if (patches.do[this.id]) {
            patches.do[this.id].unshift(Ops.add(`./states/${stateID}`, { properties: {}, enabled: false }));
            patches.undo[this.id].push(Ops.remove(`./states/${stateID}`));
          } else {
            patches.do[this.id] = [Ops.add(`./states/${stateID}`, { properties: {}, enabled: false })];
            patches.undo[this.id] = [Ops.remove(`./states/${stateID}`)];
          }
        } else if (!state.properties) {
          if (patches.do[this.id]) {
            patches.do[this.id].unshift(Ops.add(`./states/${stateID}/properties`, {}));
            patches.undo[this.id].push(Ops.remove(`./states/${stateID}/properties`));
          } else {
            patches.do[this.id] = [Ops.add(`./states/${stateID}/properties`, {})];
            patches.undo[this.id] = [Ops.remove(`./states/${stateID}/properties`)];
          }
        }
      }
    }
    // 更新特殊属性
    mergePatches(this.updateSpecialProperty(propertyName, value));
    //如果子组件使用了ref的方式设置的textStyle1,那么要跟随父字体样式的变化，更新自己的bounds
    // 如果是图文选项卡，已在updateSpecialProperty处理完子组件变化，不在调用updateBoundsOfChildUsingRefTextStyle
    if (this.libData?.type !== CImageTextTabs) {
      const childPatches = this.updateBoundsOfChildUsingRefTextStyle(propertyName, value);
      coverPatches(patches, childPatches);
    }
    // 更新因文本变化引起的尺寸变化
    const propName = property?.prop || propertyName;
    const patchList = this.doUpdateOtherTextChange(propertyName, propName, value, ignoreUpdateValue, childPropName);
    patchList.forEach((item) => mergePatches(item));
    const inputModelPatch = this.doUpdateInputModel(propertyName, value);
    inputModelPatch && mergePatches(inputModelPatch);
    return patches;
  }

  resetValueWithNewFontStyle(value: string, format: ITextFormat, childPropName?: string) {
    const resetValue = this.resetRichText(value, format, undefined, childPropName);
    const { fontStyle, listType } = format;
    const newValue = setValueWithFontStyle(
      resetValue,
      fontStyle,
      (this.properties.textFormat ?? this.properties.textStyle)?.fontStyle,
    );
    return insertOrderedList(newValue, listType);
  }

  /**
   * 判断是否在满足某种条件的父容器中
   * @param parentJudgeFunc 接收一个父容器，然后自定义父的满足条件，返回布尔值
   */
  isInSuchParent(parentJudgeFunc: (comp: UIComponent) => boolean): boolean {
    const parent = this.parent;
    if (!parent) {
      return false;
    }
    const isParentSatisfied = parentJudgeFunc((parent as unknown) as UIComponent);
    if (isParentSatisfied) {
      return true;
    } else {
      return parent.isInSuchParent(parentJudgeFunc);
    }
  }

  // eslint-disable-next-line no-unused-vars
  updateBoundsOfChildUsingRefTextStyle = (propertyName: string, myNewValue: any): ArtboardPatches => {
    return {
      do: {},
      undo: {},
    };
  };

  // eslint-disable-next-line no-unused-vars
  updateResponsiveLayout = (responsive: boolean): ArtboardPatches => {
    return { do: {}, undo: {} };
  };

  getChildWhichTypeIsText = (): UIComponent[] => {
    return [];
  };

  /**
   * 设置组件内容
   * @param {IComponentValue} value
   * @param option
   * @returns {ComponentPatches}
   */
  setValue(value: IComponentValue, option?: CompValueSettingOption): ArtboardPatches {
    let patches: ArtboardPatches = { do: {}, undo: {} };

    this.doSetValue(patches, value);
    this.mergePropertiesPatchesToSetValue(patches, value, option);

    const newPatches = this.updataSelectedChildWithValueChange(value);
    if (newPatches) {
      mergePatches(patches, newPatches);
    }

    const parentPatches = this.updataParentWithValueChange(value);
    if (parentPatches) {
      mergePatches(patches, parentPatches);
    }
    if (this.libData?.editor?.onValueUpdate) {
      const valuePatches = this.libData.editor.onValueUpdate(this, value);
      if (valuePatches) {
        mergePatches(patches, valuePatches);
      }
    }
    return patches;
  }

  /**
   * 设置值的基本逻辑
   * @param patches 原patches，会被更改
   * @param value 值
   */
  private doSetValue(oldPatches: ArtboardPatches, value: IComponentValue): void {
    let patches: ArtboardPatches = { do: {}, undo: {} };
    let sealedComp: UIComponent | null | UIContainerComponent = null;
    if (this.isRefValue()) {
      sealedComp = this.nearestSealedComponent;
      let fn: Function | undefined;
      if (sealedComp) {
        if (sealedComp.nearestSealedComponent?.libData?.type === 'select') {
          fn = sealedComp.nearestSealedComponent.libData.editor?.onChildValueChange;
        }
        patches = fn ? fn(value, this) : sealedComp.setValue(value);
        mergePatches(oldPatches, patches);
        // patches = sealedComp.setValue(value);
      }
      return;
    }
    let v = value;
    if (typeof value === 'string') {
      v = value.replace(/@/g, '@@');
    }

    const path = this.getValuePath(this.type);
    patches.do[this.id] = [Ops.replace(path, v)];
    patches.undo[this.id] = [Ops.replace(path, this.value)];
    const stateID = this.currentStateID;
    if (stateID && !this.states[stateID]) {
      patches.do[this.id].unshift(Ops.replace(`./states/${stateID}`, { properties: {}, enabled: false }));
      patches.undo[this.id].push(Ops.replace(`./states/${stateID}`, undefined));
    }
    mergePatches(oldPatches, patches);
    // 路径修改描边
    this.updateStokeWhenModifyPathComp(v, patches);
  }

  /**
   * 处理设置内容时对属性造成的一些影响
   * @param patches 原patches，会被更改
   * @param value 值
   * @param option @type CompValueSettingOption
   */
  private mergePropertiesPatchesToSetValue(
    patches: ArtboardPatches,
    value: IComponentValue,
    option?: CompValueSettingOption,
  ): void {
    const { textStyle, multiText, textFormat } = this.properties;
    if (typeof value === 'string' && (textFormat || textStyle)) {
      const vertical = textFormat?.vertical || multiText?.vertical;
      let wrap = !!(textFormat?.wrap || multiText?.wrap);
      if (option && !isUndefined(option.wrap)) {
        wrap = option.wrap;
      }
      if (!this.lockBoundsChange) {
        this.lockBoundsChange = true;
        let isMulti = !!multiText;
        if (textFormat && !isUndefined(textFormat.isMulti)) {
          isMulti = textFormat.isMulti;
        }
        if (this instanceof UITextComponent || this.autoSize) {
          let textBoundsChangeOption: TextBoundsChangeOption = {
            textFormat: (textFormat
              ? { ...textFormat, wrap }
              : {
                  isMulti,
                  ...(textStyle || {}),
                  ...(multiText || {}),
                  wrap,
                }) as ITextFormatEx,
            defaultWidth: !vertical ? option?.size?.width : option?.size?.height,
            autoFill: option?.autoFill,
          };
          let p = this.updateBoundsWithTextChange(value, textBoundsChangeOption);
          Object.keys(p.do).forEach((id) => {
            const compDo = p.do[id];
            const compUndo = p.undo[id];
            if (compDo.length) {
              if (patches.do[id]) {
                patches.do[id].push(...compDo);
                patches.undo[id].push(...compUndo);
              } else {
                patches.do[id] = compDo;
                patches.undo[id] = compUndo;
              }
            }
          });
          if ((textFormat || multiText) && option) {
            const newWrap = !!option?.wrap;
            const oldWrap = (textFormat || multiText)!.wrap;
            if (oldWrap !== newWrap) {
              const pname = textFormat ? TextFormatExPropertyName : MultiTextPropertyName;

              p = this.setProperty(pname, { ...(textFormat || multiText)!, wrap: newWrap }, true);
              if (p) {
                mergePatches(patches, p);
              }
            }
          }
        }
      }
      this.lockBoundsChange = false;
    }
  }

  // 路径修改描边
  private updateStokeWhenModifyPathComp(v: IComponentValue, patches: ArtboardPatches) {
    if ([CCompoundPath, CPath].includes(this.type)) {
      const unclosed =
        this.type === CPath
          ? !isClosedPathWithArea(v as IPathValue)
          : (v as IPathValue[]).find((p) => !isClosedPathWithArea(p));
      if (unclosed) {
        const strokePatches = this.updatePathStrokePositionOfAllState(StrokePosition.center);
        if (strokePatches) {
          mergePatches(patches, strokePatches);
        }
      }
    }
  }

  /**
   * 修改所有状态的描边位置
   */
  updatePathStrokePositionOfAllState(strokePosition: StrokePosition) {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    const normalStroke = this.toJSON().properties.stroke;
    if (normalStroke) {
      const path = './properties/stroke';
      const newStroke = depthClone(normalStroke);
      newStroke.position = strokePosition;
      patches.do[this.id] = [Ops.replace(path, newStroke)];
      patches.undo[this.id] = [Ops.replace(path, normalStroke)];
    }
    const stateKeys = Object.keys(this.states);
    stateKeys.forEach((key) => {
      const stateStroke = this.states[key].properties?.stroke;
      if (stateStroke) {
        const newStroke = depthClone(stateStroke);
        newStroke.position = strokePosition;
        const path = `./states/${key}/properties/stroke`;
        patches.do[this.id] = (patches.do[this.id] || []).concat([Ops.replace(path, newStroke)]);
        patches.undo[this.id] = (patches.undo[this.id] || []).concat([Ops.replace(path, stateStroke)]);
      }
    });
    return patches;
  }

  /**
   * 直接修改下拉框当前值时，同步修改选择项的值
   */
  updataSelectedChildWithValueChange(value: IComponentValue) {
    if (this.type === CSelect) {
      const listPanels = findChildrenByFiltering(this, (comp: UIComponent) => comp.type === CListLayoutPanel);
      if (listPanels) {
        const selectedItems = findChildrenByFiltering(listPanels[0], (comp: UIComponent) => comp.selected);
        if (selectedItems) {
          const texts = findChildrenByFiltering(selectedItems[0], (comp: UIComponent) => comp.type === CPureText);
          if (texts && texts[0].value !== value) {
            const path = texts[0].getCurrentPropertiesPath('value');
            const patches: ArtboardPatches = { do: {}, undo: {} };
            patches.do[texts[0].id] = [Ops.replace(path, value)];
            patches.undo[texts[0].id] = [Ops.replace(path, texts[0].value)];
            return patches;
          }
        }
      }
    }
  }

  /**
   * 直接修改单选或者多选下拉框选择项的值，同步修改下拉框当前值时
   */
  updataParentWithValueChange(value: IComponentValue): ArtboardPatches | null {
    const selectItem = findParentByFiltering(this, (comp: UIComponent) => comp.selected);
    if (selectItem) {
      const listPanels = findParentByFiltering(selectItem, (comp: UIComponent) => comp.type === CListLayoutPanel);
      if (listPanels) {
        const select = findParentByFiltering(listPanels, (comp: UIComponent) => comp.type === CSelect);
        if (select && select.value !== value) {
          return select.setValue(value);
        }
        const multiSelect = findParentByFiltering(listPanels, (comp: UIComponent) => comp.type === CMultipleSelect);
        if (multiSelect) {
          return (multiSelect as UIMultipleSelectPanelComponent).getUpdateItemTextPatches(this, value as string);
        }
      }
    }
    return null;
  }

  setText(value: string): ArtboardPatches {
    const oldText = this.text;
    let patches: ArtboardPatches = { do: {}, undo: {} };
    const ownerComp = this.nearestSealedComponent;
    if (oldText && ownerComp && refHelper.isRefKey(oldText)) {
      patches = ownerComp.setText(value.replace(/@/g, '@@'));
    } else {
      if (this.currentStateID && !this.states[this.currentStateID]) {
        patches.do[this.id] = [Ops.replace(`/states/${this.currentStateID}`, { text: value })];
        patches.undo[this.id] = [Ops.remove(`/states/${this.currentStateID}`)];
      } else {
        const path = this.getCurrentPropertiesPath('text');
        patches.do[this.id] = [Ops.replace(path, value)];
        patches.undo[this.id] = [Ops.replace(path, oldText)];
      }
    }

    return patches;
  }

  // 注释功能
  getRemarkPatch(value?: IRemark, isCompRemark = true): ArtboardPatches {
    const targetId = isCompRemark ? this.id : 'self';
    const path = '/remark';
    return {
      do: { [targetId]: [value ? Ops.replace(path, value) : Ops.remove(path)] },
      undo: { [targetId]: [Ops.replace(path, this.remark)] },
    };
  }

  /**
   * 添加状态，用于从无到有的添加，一般用于添加自定义状态
   * @param {string} id
   * @param {string} name
   * @param {string} from
   * @returns {ComponentPatches}
   */
  addState(id: string, name: string, from?: string): ComponentPatches {
    let original: IComponentStateData;
    if (from && !isUndefined(this.states[from])) {
      original = this.states[from];
    } else {
      original = { properties: {} };
      // FIXME 新增自定义状态时添加properties属性会导致固定内容时无法跟随正常状态的内容
      Object.keys(this.data.properties).forEach((key) => {
        original.properties![key] = this.data.properties[key];
      });
    }

    original = depthClone(original);

    if (this.data.states) {
      const path = `/states/${id}`;
      return {
        do: [Ops.add(path, { ...original, enabled: true, name })],
        undo: [Ops.remove(path)],
      };
    } else {
      const path = `/states`;
      return {
        do: [Ops.add(path, { [id]: { ...original, name } })],
        undo: [Ops.remove(path)],
      };
    }
  }

  /**
   * 删除状态，这个会彻底从状态数据中清除，一般用于自定义状态的删除
   * @param {string} id
   * @returns {ComponentPatches}
   */
  removeState(id: string): ComponentPatches {
    const value = this.states[id];
    const path = `/states/${id}`;

    const result: ComponentPatches = {
      do: [Ops.remove(path)],
      undo: [Ops.add(path, value)],
    };
    return result;
  }

  /**
   * 修改状态是否激活
   * @param {string} id
   * @param {boolean} enabled
   * @returns {ComponentPatches | undefined}
   */
  changeStateEnabled(id: string, enabled: boolean): ComponentPatches | undefined {
    const state = this.states[id] as IComponentState;
    let patches: ComponentPatches | undefined;
    const path = `/states/${id}`;
    if (!state) {
      patches = {
        do: [Ops.add(path, { enabled, properties: {} })],
        undo: [Ops.remove(path)],
      };
    } else {
      patches = {
        do: [Ops.replace(`${path}/enabled`, enabled)],
        undo: [Ops.replace(`${path}/enabled`, state.enabled)],
      };
      // 补充上可能原来没有的属性节点，这个步骤不需要undo
      if (!state.properties) {
        patches.do.push(Ops.replace(`${path}/properties`, {}));
      }
    }
    return patches;
  }

  /**
   * 切换当前状态，不会修改状态本身，仅标记组件当前的选中了哪个状态，数据不会提交到后端
   * @param {string} id
   * @returns {Operation[]}
   */
  switchState(id?: string): { [id: string]: Operation[] } {
    const oldID = this.data._currentState;
    const path = '/_currentState';
    if (oldID && id) {
      return { [this.id]: [Ops.replace(path, id)] };
    }
    if (id) {
      return { [this.id]: [Ops.add(path, id)] };
    }
    return { [this.id]: [Ops.remove(path)] };
  }

  /**
   * 切换当前状态，不会修改状态本身，仅标记组件当前的选中了哪个状态，数据不会提交到后端
   * @param {string} id
   * @returns {Operation[]}
   */
  switchStateUndo(id?: string): ArtboardPatches {
    const oldID = this.data._currentState;
    const path = '/_currentState';
    if (oldID && id) {
      return {
        do: {
          [this.id]: [Ops.replace(path, id)],
        },
        undo: {
          [this.id]: [Ops.replace(path, oldID)],
        },
      };
    }
    if (id) {
      return {
        do: {
          [this.id]: [Ops.add(path, id)],
        },
        undo: {
          [this.id]: [Ops.remove(path)],
        },
      };
    }
    if (oldID) {
      return {
        do: {
          [this.id]: [Ops.remove(path)],
        },
        undo: {
          [this.id]: [Ops.add(path, oldID)],
        },
      };
    }
    return { do: {}, undo: {} };
  }
  /**
   * 修改状态名称
   * @param {string} stateID
   * @param {string} newName
   * @returns {ComponentPatches}
   */
  modifyStateName(stateID: string, newName: string): ComponentPatches {
    const oldValue = this.states[stateID].name;
    const path = `/states/${stateID}/name`;
    return {
      do: [Ops.replace(path, newName)],
      undo: [Ops.replace(path, oldValue)],
    };
  }

  /**
   * 克隆状态
   * @param {string} from
   * @param {string} to
   * @returns {ComponentPatches}
   */
  cloneState(from: string, to: string): ComponentPatches {
    const value = depthClone(this.states[from]);
    const path = `/states/${to}`;
    return {
      do: [Ops.add(path, value)],
      undo: [Ops.remove(path)],
    };
  }

  /**
   * 设置默认状态
   * @param {string} stateID
   * @returns {ComponentPatches}
   */
  setDefaultState(stateID?: string): ComponentPatches {
    const { selected, disabled } = this.data;
    const patches: ComponentPatches = { do: [], undo: [] };
    if (!stateID) {
      if (selected) {
        patches.do.push(Ops.replace('/selected', false));
        patches.undo.push(Ops.replace('/selected', true));
      }
      if (disabled) {
        patches.do.push(Ops.replace('/disabled', false));
        patches.undo.push(Ops.replace('/disabled', false));
      }
    } else if (stateID === PredefinedStates.disabled) {
      if (selected) {
        patches.do.push(Ops.replace('/selected', false));
        patches.undo.push(Ops.replace('/selected', true));
      }
      if (!disabled) {
        patches.do.push(Ops.replace('/disabled', true));
        patches.undo.push(Ops.replace('/disabled', false));
        const disabledState = this.states['disabled'];
        if (!disabledState) {
          patches.do.push(Ops.add('./states/disabled', { properties: {}, enabled: false }));
          patches.undo.push(Ops.remove('./states/disabled'));
        } else if (!disabledState.properties) {
          patches.do.push(Ops.add('./states/disabled/properties', {}));
        }
        patches.do.push(Ops.replace('./states/disabled/enabled', true));
        patches.undo.push(Ops.replace('./states/disabled/enabled', false));
      }
    } else if (stateID === PredefinedStates.checked) {
      if (!selected) {
        patches.do.push(Ops.replace('/selected', true));
        patches.undo.push(Ops.replace('/selected', false));

        const checkedState = this.states['checked'];
        if (!checkedState) {
          patches.do.push(Ops.add('./states/checked', { properties: {}, enabled: false }));
          patches.undo.push(Ops.remove('./states/checked'));
        } else if (!checkedState.properties) {
          patches.do.push(Ops.add('./states/checked/properties', {}));
        }
        patches.do.push(Ops.replace('./states/checked/enabled', true));
        patches.undo.push(Ops.replace('./states/checked/enabled', false));
      }
      if (disabled) {
        patches.do.push(Ops.replace('/disabled', false));
        patches.undo.push(Ops.replace('/disabled', true));
      }
    }
    return patches;
  }

  // 判断值是否是引用值
  isRefValue(): boolean {
    const val = this.data.value;
    if (typeof val !== 'string') {
      return false;
    }
    return refHelper.isRefKey(val) && val === '@value';
  }

  /**
   * 组件完整可用属性，只读
   * @returns {IProperties}
   */
  get properties(): IProperties {
    let curr = super.properties; //this.data.properties;
    if (this._dynamicProperties) {
      curr = Object.assign({}, curr, this._dynamicProperties);
    }
    const refProps: IProperties = {};
    let hasSealedOwner = false;
    let nearestSealedComponent: UIContainerComponent | UIComponent | null = null;
    let ownerData: IComponentStateData | undefined = undefined;
    for (let key in curr) {
      const val = curr[key];
      if (val?.ref) {
        if (!hasSealedOwner) {
          nearestSealedComponent = this.nearestSealedComponent;
          if (nearestSealedComponent) {
            const compData = nearestSealedComponent.currentState;
            ownerData = compData;
            if (nearestSealedComponent._dynamicProperties) {
              ownerData = merge(
                compData,
                { properties: nearestSealedComponent._dynamicProperties },
                (key, sourceValue, targetValue) => {
                  if (['colorStops', 'dashArray'].includes(key)) {
                    return targetValue;
                  }
                },
              );
            }
          }
        }
        hasSealedOwner = true;
        // const nearestSealedComponent = this.nearestSealedComponent;
        ownerData && (refProps[key] = refHelper.getRefValue(val.ref, ownerData));
      }
    }
    const dynProp = this.dynamicInfo && this.dynamicInfo.properties ? this.dynamicInfo.properties : {};
    // 通过配置扩展属性
    let extensionDefaultProperties: Partial<IProperties> | null = null;
    if (this.lib) {
      const fn = getLibData(this.lib.type)?.property?.getExtensionDefaultProperties;
      if (fn) {
        extensionDefaultProperties = fn(this);
      }
    }

    const result = Object.assign({}, curr, refProps, dynProp);
    extensionDefaultProperties &&
      Object.keys(extensionDefaultProperties).forEach((key) => {
        if (!result[key]) {
          result[key] = extensionDefaultProperties![key];
        }
      });
    return result;
  }

  get nearestPanelComponent(): UIContainerComponent | null {
    if (!this.parent) {
      return null;
    }
    if (this.parent.type === CCanvasPanel) {
      return this.parent;
    }
    return this.parent.nearestPanelComponent;
  }

  // 在做‘查找替换文字’功能时增加的，用于定位组件
  get nearestSymbolComponent(): UIContainerComponent | null {
    if (!this.parent) {
      return null;
    }
    if (this.parent.isSymbol) {
      return this.parent;
    }
    return this.parent.nearestSymbolComponent;
  }

  // 在做‘查找替换文字’功能时增加的，用于定位组件
  get nearestTableComponent(): UIContainerComponent | null {
    if (!this.parent) {
      return null;
    }
    if (this.parent.isTable) {
      return this.parent;
    }
    return this.parent.nearestTableComponent;
  }

  // 在做‘查找替换文字’功能时增加的，用于处理被定位组件的UI状态
  get nearestComponentRenderUnit(): UIComponent {
    let result: UIComponent = this.nearestSealedComponent ?? this;

    // 下拉框的处理
    if (result.alias === 'drop-down') {
      result = result.nearestSealedComponent ?? result;
    }

    // symbol处理
    if (result.parent instanceof UISymbolComponent) {
      return result.parent;
    }

    return result;
  }

  // 在做‘查找替换文字’功能时增加的，用于定位组件
  get nearestLocatingComponent(): UIComponent {
    return this.nearestSymbolComponent ?? this.nearestTableComponent ?? this.nearestComponentRenderUnit;
  }

  /**
   * 添加一个动作到事件中
   * @param {EventType} event
   * @param {IActionBase} action
   * @returns {ComponentPatches}
   */
  addInteractionAction(event: EventType, action: IActionBase): ComponentPatches {
    const handle = this.interactions[event];
    if (!handle) {
      const path = `/interaction/${event}`;
      return {
        do: [Ops.add(path, { parallel: false, active: true, autoRevert: false, actions: [action] })],
        undo: [Ops.remove(path)],
      };
    }
    const index = handle.actions.length;
    const path = `/interaction/${event}/actions/${index}`;
    return {
      do: [Ops.add(path, action)],
      undo: [Ops.remove(path)],
    };
  }

  /**
   * 导出图片设置
   * @param option
   */
  public addExportOption = (exportFormats: IExportItemData[]): ComponentPatches => {
    const path = `./exportImage`;
    return {
      do: exportFormats.map((t, index) => Ops.add(path + '/' + index, t)), // [Ops.add(path, exportFormats)],
      undo: exportFormats.map((t, index) => Ops.remove(path + '/' + index)), //[Ops.remove(path)]
    };
  };
  /**
   * 判断相等
   * @param t
   * @param option
   */
  private checkSameExportOptionItem = (t: IExportItemData, option: IExportItemData) => {
    return t.exportRatio === option.exportRatio && t.fileFormat === option.fileFormat;
  };

  public addExportOptionItem = (option: IExportItemData): ComponentPatches => {
    const { exportImage } = this.toJSON();
    let path: string;
    let value: IExportItemData | IExportItemData[];
    if (!exportImage) {
      path = './exportImage';
      value = [option];
    } else {
      // 判断是否重复
      if (exportImage.find((t) => this.checkSameExportOptionItem(t, option))) {
        return {
          do: [],
          undo: [],
        };
      }
      // 添加
      path = `./exportImage/${exportImage.length}`;
      value = option;
    }
    return {
      do: [Ops.add(path, value)],
      undo: [Ops.remove(path)],
    };
  };

  public removeExportOptionItem = (option: IExportItemData): ComponentPatches => {
    const { exportImage } = this.toJSON();
    let path: string;
    let value: IExportItemData | IExportItemData[];
    if (!exportImage) {
      return {
        do: [],
        undo: [],
      };
    } else {
      const index = exportImage.findIndex((t) => this.checkSameExportOptionItem(t, option));
      if (index < 0) {
        return {
          do: [],
          undo: [],
        };
      }
      path = `./exportImage/${index}`;
      value = exportImage[index];
      return {
        do: [Ops.remove(path)],
        undo: [Ops.add(path, value)],
      };
    }
  };

  public replaceExportOptionItem = (option: IExportItemData, seq?: number): ComponentPatches => {
    const { exportImage } = this.toJSON();
    let oldValue: IExportItemData | undefined;
    let index = seq;
    if (exportImage) {
      index =
        seq && exportImage[seq] ? seq : exportImage?.findIndex((t) => this.checkSameExportOptionItem(t, option)) ?? 0;
      oldValue = exportImage[index];
    }

    const path = `./exportImage/${index}`;
    const value = option;
    return {
      do: [Ops.replace(path, value)],
      undo: [Ops.replace(path, oldValue)],
    };
  };

  /**
   * 替换当前交互
   * @param newInteraction
   * @param oldInteraction
   * @returns
   */
  replaceInteraction(newInteraction: IInteraction, oldInteraction: IInteraction) {
    return {
      do: [Ops.replace('/interaction', newInteraction)],
      undo: [Ops.replace('/interaction', oldInteraction)],
    };
  }

  /**
   * 替换旧事件下所有的命令到目标事件
   * @param currEventAndHandle 当前事件与其对应的命令
   * @param targetEventAndHandle 目标事件与其对应的命令
   * @param isReplace 是否替换
   * @returns
   */
  replaceInteractionActions(
    currEventAndHandle: IEventAndHandle,
    targetEventAndHandle: IEventAndHandle,
    isReplace: boolean,
  ): ComponentPatches | undefined {
    const currPath = `/interaction/${currEventAndHandle.event}`;
    const targetPath = `/interaction/${targetEventAndHandle.event}`;
    const doOps = isReplace
      ? Ops.replace(targetPath, targetEventAndHandle.handle)
      : Ops.add(targetPath, targetEventAndHandle.handle);

    const undoOps = isReplace ? Ops.replace(targetPath, targetEventAndHandle.originHandle) : Ops.remove(targetPath);

    return {
      do: [Ops.remove(currPath), doOps],
      undo: [Ops.add(currPath, currEventAndHandle.handle), undoOps],
    };
  }

  /**
   * 移动当前事件中的命令到目标事件
   * @param currEventAndHandle 当前事件与其对应的命令
   * @param targetEventAndHandle 目标事件与其对应的命令
   * @param isReplace 是否替换
   * @returns
   */
  moveSingleInteractionActions(
    currEventAndHandle: IEventAndHandle,
    targetEventAndHandle: IEventAndHandle,
    isReplace: boolean,
  ): ComponentPatches | undefined {
    const currPath = `/interaction/${currEventAndHandle.event}`;
    const targetPath = `/interaction/${targetEventAndHandle.event}`;
    const undoOperateName = isReplace ? Ops.replace : Ops.remove;

    const isRemoveCurrEvent = !currEventAndHandle.handle?.actions?.length;

    return {
      do: [
        isRemoveCurrEvent ? Ops.remove(currPath) : Ops.replace(currPath, currEventAndHandle.handle),
        isReplace
          ? Ops.replace(targetPath, targetEventAndHandle.handle)
          : Ops.add(targetPath, targetEventAndHandle.handle),
      ],
      undo: [
        isRemoveCurrEvent
          ? Ops.add(currPath, currEventAndHandle.originHandle)
          : Ops.replace(currPath, currEventAndHandle.originHandle),
        undoOperateName(targetPath, targetEventAndHandle.originHandle),
      ],
    };
  }

  /**
   * 根据component的类型，当component的宽高为0的时候，输出一个校正大小
   * @param size
   */
  adjustZeroSize(size: ISize) {
    const { height, width } = size;
    const { height: originHeight, width: originWidth } = this.size;
    const minSize = getMinSizeOfComp(this);
    // 尺寸为0的路径，不允许resize
    if (this.type === CPath && (originHeight === 0 || originWidth === 0)) {
      if (originHeight === 0) {
        return {
          height: 0,
          width: isNotEqual0(width) ? width || 0 : minSize.width,
        };
      }
      if (originWidth === 0) {
        return {
          height: isNotEqual0(height) ? height || 0 : minSize.height,
          width: 0,
        };
      }
    }
    return {
      height: isNotEqual0(height) && height > minSize.height ? height : minSize.height,
      width: isNotEqual0(width) && width > minSize.width ? width : minSize.width,
    };
  }

  /**
   * 移动事件中动作的顺序
   * @param {EventType} event
   * @param {string} actionID
   * @param {number} newIndex
   * @returns {ComponentPatches | null}
   */
  moveInteractionAction(event: EventType, actionID: string, newIndex: number): ComponentPatches | null {
    const handle = this.interactions[event];
    if (!handle) {
      return null;
    }
    const originalIndex = handle.actions.findIndex((action) => action._id === actionID);
    if (originalIndex === -1) {
      return null;
    }
    const path = `/interaction/${event}/actions/${newIndex}`;
    const from = `/interaction/${event}/actions/${originalIndex}`;
    return {
      do: [Ops.move(path, from)],
      undo: [Ops.move(from, path)],
    };
  }

  /**
   * 移除事件中的一个动作
   * @param {EventType} event
   * @param {string} actionID
   * @returns {ComponentPatches | null}
   */
  removeInteractionAction(event: EventType, actionID: string): ComponentPatches | null {
    const handle = this.interactions[event];
    if (!handle) {
      return null;
    }
    if (handle.actions.length === 1) {
      return this.removeAllInteractionActions(event);
    }
    const actionIndex = handle.actions.findIndex((action) => action._id === actionID);
    if (actionIndex === -1) {
      return null;
    }
    const action = handle.actions[actionIndex];
    const path = `/interaction/${event}/actions/${actionIndex}`;
    return {
      do: [Ops.remove(path)],
      undo: [Ops.add(path, action)],
    };
  }

  /**
   * 移除事件中所有动作
   * @param {EventType} event
   * @returns {ComponentPatches | null}
   */
  removeAllInteractionActions(event: EventType): ComponentPatches | null {
    const handle = this.interactions[event];
    if (!handle) {
      return null;
    }
    const path = `/interaction/${event}`;
    return {
      do: [Ops.remove(path)],
      undo: [Ops.add(path, handle)],
    };
  }

  /**组件从父组中释放时，需要获取新的位置
   *
   */
  getPositionWithoutParent(position = this.position, parent = this.parent!) {
    const parentRotate = parent.rotate;
    const nwPointOfParent = parent.getBoxPointsInParent()[0];
    // 这里只是得到了西北角顶点的坐标而已，还需要转回原位置，才是我们需要的position坐标
    // 这里传入的旋转角度是父亲的旋转角度，我们仅仅要把父的旋转角度的效果抵消
    const nwPointWithoutParent = mapPositionToTargetCoordinates(position, parentRotate, nwPointOfParent);
    return this.getOriginPositionAccordingRotatingInfo(nwPointWithoutParent, parentRotate, this.size);
  }

  /**
   * 标记事件下所有动作是否同时工作
   * @param {EventType} event
   * @returns {ComponentPatches | null}
   */
  markUpInteractionParallel(event: EventType): ComponentPatches | null {
    const handle = this.interactions[event];
    if (!handle) {
      return null;
    }
    const path = `/interaction/${event}/parallel`;
    return {
      do: [Ops.replace(path, !handle.parallel)],
      undo: [Ops.replace(path, handle.parallel)],
    };
  }

  /**
   * 标记事件是否被激活
   * @param {EventType} event
   * @returns {ComponentPatches | null}
   */
  markUpInteractionActive(event: EventType): ComponentPatches | null {
    const handle = this.interactions[event];
    if (!handle) {
      return null;
    }
    const path = `/interaction/${event}/active`;
    return {
      do: [Ops.replace(path, !handle.active)],
      undo: [Ops.replace(path, handle.active)],
    };
  }

  /**
   * 标记事件中动作是否在下次执行时以相反的动作执行
   * @param {EventType} event
   * @returns {ComponentPatches | null}
   */
  markUpInteractionAutoRevert(event: EventType): ComponentPatches | null {
    const handle = this.interactions[event];
    if (!handle) {
      return null;
    }
    const path = `/interaction/${event}/autoRevert`;
    return {
      do: [Ops.replace(path, !handle.autoRevert)],
      undo: [Ops.replace(path, handle.autoRevert)],
    };
  }

  /**
   * 修改动作参数
   * @param {EventType} event
   * @param {string} actionID
   * @param {IActionParam} params 根据动作类型，确定修改数据类型
   * @returns {ComponentPatches | null}
   */
  modifyInteractionActionParams(event: EventType, actionID: string, params: IActionParams): ComponentPatches | null {
    const handle = this.interactions[event];
    if (!handle) {
      return null;
    }
    const actionIndex = handle.actions.findIndex((action) => action._id === actionID);
    if (actionIndex === -1) {
      return null;
    }
    const action = handle.actions[actionIndex];
    const oldParams = action.params;
    const path = `/interaction/${event}/actions/${actionIndex}/params`;
    return {
      do: [Ops.replace(path, params)],
      undo: [Ops.replace(path, oldParams)],
    };
  }

  /**
   * 修改动作参数
   * @param {EventType} event
   * @param {string} actionID
   * @param {IActionBase} newAction 根据动作类型，确定修改数据类型
   * @returns {ComponentPatches | null}
   */
  modifyInteractionActionData(event: EventType, actionID: string, newAction: IActionBase): ComponentPatches | null {
    const handle = this.interactions[event];
    if (!handle) {
      return null;
    }
    const actionIndex = handle.actions.findIndex((action) => action._id === actionID);
    if (actionIndex === -1) {
      return null;
    }

    const action = handle.actions[actionIndex];
    const path = `/interaction/${event}/actions/${actionIndex}`;
    return {
      do: [Ops.replace(path, newAction)],
      undo: [Ops.replace(path, action)],
    };
  }

  get select(): Select | undefined {
    return this.data.select;
  }

  // 当前组件是否可以选中
  get selectable(): boolean {
    // 已经选中的组件不允许再次选中
    if (this.selected) {
      return this._currentState !== 'checked';
    }

    const parent = this.parent;

    // 父组件对子组件的选中控制策略优先
    if (parent && parent.select && parent.select.target === 'child') {
      const parentSelect = parent.select;
      // 父组件允许无限选择子项或允许取消选中别的项
      if (parentSelect.maxCount === -1 || parentSelect.autoUnselect) {
        return true;
      }

      const parentSelectedCount = parent.components.filter((comp) => comp.selected).length;
      // 父组件允许的选择子项数量大于当前选中的数量
      if (parentSelect.maxCount > parentSelectedCount) {
        return true;
      }
    } else {
      const currentSelect = this.select;
      if (currentSelect && currentSelect.target === 'self' && currentSelect.enabled) {
        return true;
      }
    }
    return false;
  }

  get unselectable(): boolean {
    // 未选中的组件，不允许反选
    if (!this.selected) {
      return false;
    }

    const parent = this.parent;
    // 父组件对子组件的选中控制策略优先
    if (parent && parent.select && parent.select.target === 'child') {
      const parentSelect = parent.select;
      const parentSelectedCount = parent.components.filter((comp) => comp.selected).length;
      // 不允许反选
      if (!parentSelect.reversible) {
        return false;
      }
      // 当前选中数量大于最小要求的选择数量
      return parentSelectedCount > parentSelect.minCount;
    } else {
      const currentSelect = this.select;
      if (currentSelect && currentSelect.target === 'self' && currentSelect.enabled && currentSelect.reversible) {
        return true;
      }
    }
    return false;
  }

  get textAlign(): string {
    let algin = 'center';
    const textSty = this.data.properties.textStyle;
    if (textSty && textSty.textAlign) {
      algin = textSty.textAlign;
    }
    return algin;
  }

  get textFormat(): ITextFormatEx | undefined {
    const properties = this.properties;
    const { textStyle, textFormat, multiText } = properties;
    if (textStyle || textFormat || multiText) {
      const result = depthClone(textFormat || { ...textStyle, ...multiText }) as ITextFormatEx;
      const { fontSize, lineHeight, lineHeightEx } = result;
      if (!isUndefined(lineHeight) && isUndefined(lineHeightEx)) {
        result.lineHeightEx = lineHeight + (fontSize || DefaultFontSize);
      }
      if (isUndefined(lineHeight) && isUndefined(lineHeightEx)) {
        result.lineHeightEx = Math.round((fontSize || DefaultFontSize) * 1.4);
      }
      return upgradeTextFormatForApply(result, this.text);
    }
    return undefined;
  }

  getInheritClassName(): { [key: string]: boolean } {
    const state = this.states[this.currentStateID || ''];
    if (!this.fixContent || !state) {
      return {};
    }
    const textFormat = state.properties?.textFormat;
    if (!textFormat) {
      return {};
    }
    const { bold, italic, underline, strike } = textFormat.fontStyle ?? {};
    return {
      'global-family-inherit': Boolean(textFormat.fontFamily),
      'global-size-inherit': Boolean(textFormat.fontSize),
      'global-color-inherit': Boolean(textFormat.color),
      'global-align-inherit': Boolean(textFormat.textAlign),
      'global-bold-inherit': typeof bold === 'boolean',
      'global-italic-inherit': typeof italic === 'boolean',
      'global-underline-inherit': typeof underline === 'boolean',
      'global-strike-inherit': typeof strike === 'boolean',
    };
  }

  get matrix(): Matrix {
    return getCompAbsoluteMatrix(this);
  }

  getPositionPatches(newPosition: IPosition) {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    const doOps: Operation[] = [];
    const undoOps: Operation[] = [];
    if (!isEqual(this.position, newPosition)) {
      const path = this.getCurrentPositionPath();
      doOps.push(Ops.replace(path, newPosition));
      undoOps.push(Ops.replace(path, this.position));
    }
    patches.do[this.id] = doOps;
    patches.undo[this.id] = undoOps;
    return patches;
  }

  getLayoutChangePatches(newLayout: ILayout) {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    const doOps: Operation[] = [Ops.replace(`/layout`, newLayout)];
    const undoOps: Operation[] = [Ops.replace(`/layout`, this.layout)];
    patches.do[this.id] = doOps;
    patches.undo[this.id] = undoOps;
    return patches;
  }

  /**
   * 删除自己
   * @memberof UIComponent
   */
  removeSelfPatches = (): ArtboardPatches => {
    const parent = this.parent!;

    const connectLine = parent.components.filter((comp) => {
      if (comp.isConnector) {
        const startID = (comp as UIConnectorComponent).getStartCompID();
        const endID = (comp as UIConnectorComponent).getEndCompID();
        const startCompIsSelected = startID && this.id === startID;
        const endCompIsSelected = endID && this.id === endID;
        return startCompIsSelected || endCompIsSelected;
      } else {
        return false;
      }
    });
    const revertAdd = convertRemovedComponentsToAddOps(parent, [this]);

    const revertNoConnectLine = convertRemovedComponentsToAddOps(parent, connectLine);
    return {
      do: {
        [parent.id]: [Ops.removeChildren([this.id])],
      },
      undo: {
        [parent.id]: [...revertAdd, ...revertNoConnectLine],
      },
    };
  };

  changingProperty(name: PropertyName, value: PropertyValue) {
    if (!this._dynamicProperties) {
      this._dynamicProperties = {
        [name]: value,
      };
    } else {
      this._dynamicProperties[name] = value;
    }

    const doUpdateView = (comp: UIComponent) => {
      comp.updateComponentView && comp.updateComponentView();
      if (comp instanceof UIContainerComponent) {
        comp.components.forEach((child) => doUpdateView(child));
      }
    };
    doUpdateView(this);
  }

  changingOpacity(value: number) {
    if (!this._dynamicInfo) {
      this._dynamicInfo = {};
    }
    this._dynamicInfo.opacity = value;
    if (this.updateComponentView) {
      this.updateComponentView();
    }
  }

  /**
   * 仅限制用来更新组件本身，除现在已调用部分，其它地方禁止调用
   */
  updateComponentView?: (onlyBounds?: boolean, keyboardOffset?: { x: number; y: number }) => void;

  /**
   * 用来单独更新选中组件的选框
   */
  updateSelectingCompView?: () => void;

  chainedVersion() {
    let v = super.chainedVersion();
    if (this._dynamicProperties) {
      return `${v}-${Math.round(Math.random() * 100)}`;
    }
    return v;
  }

  // 不知道为啥加随机数，先保留吧另外Math.random()： [0, 1)。
  updateVision() {
    this.data.v = this.data.v + Math.floor(Math.random() * 10) + 1;
  }

  updateSelfAndChildrenVision() {
    this.updateVision();
    if (this instanceof UIContainerComponent) {
      this.components.forEach((comp) => comp.updateSelfAndChildrenVision());
    }
  }

  updateSelfAndParentVision() {
    this.updateVision();
    if (this.parent instanceof UIConnectorComponent) {
      this.parent.updateSelfAndParentVision();
    }
  }

  notifyUpdateView(onlyBounds?: boolean) {
    if (this.updateComponentView) {
      this.updateVision();
      this.updateComponentView(onlyBounds);
    }
  }

  // eslint-disable-next-line no-unused-vars
  getSuchChild(fn: (comp: UIComponent) => boolean, isRecursive: boolean) {
    let result: UIComponent[] = [];
    return result;
  }

  /**
   *  组件resize时。若其填充为径向渐变，同时修改径向渐变的数据
   */
  // eslint-disable-next-line no-unused-vars
  updateRadialGradientPatchesWithNewSize(newSize: IComponentSize) {
    // const { properties, size, states, id } = this;
    const patches: ArtboardPatches = { do: {}, undo: {} };
    // 修改当前状态下的fill
    // if(properties.fill?.type === FillType.radial){
    // const newFill = depthClone(properties.fill!);
    // const newColor = reviseRadialGradient(newFill.color as IRadialGradientColor, size);

    // newColor.angle = (newColor.angle || 0) % 360;
    // newColor.widthRatio = (newColor.widthRatio || 1);
    // newColor.to = newColor.to || { x: 0.5, y: 0.5, r: 0.5 };

    // 三角函数方式
    // newAngle应该没问题
    // const radian = Math.atan(Math.tan(newColor.angle * Math.PI / 180) * size.height * newSize.width / (newSize.height * size.width));
    // let newRadian = (radian + 10 * Math.PI) % (0.5 * Math.PI);
    // if (newColor.angle >= 90 && newColor.angle < 180) {
    //   newRadian = 0.5 * Math.PI + newRadian;
    // } else if (newColor.angle >= 180 && newColor.angle < 270) {
    //   newRadian = Math.PI + newRadian;
    // } else if (newColor.angle >= 270 && newColor.angle < 360) {
    //   newRadian = 1.5 * Math.PI + newRadian;
    // }
    // const newAngle = newRadian * 180 / Math.PI;
    // newR应该没问题
    // const newR = Math.abs((newColor.to.r * Math.cos(newColor.angle * Math.PI / 180)) / Math.cos(newAngle * Math.PI / 180));
    // const newWidthRatio = Math.abs(newColor.to.r * Math.cos(newColor.angle * Math.PI / 180) * size.height * newSize.width * newColor.widthRatio / (Math.cos(newAngle * Math.PI / 180) * newR * size.width * newSize.height));

    // // 坐标缩放方式
    // const oldF = {
    //   x: size.width * newColor.to.x,
    //   y: size.height * newColor.to.y,
    // };
    // const newF = {
    //   x: newSize.width * newColor.to.x,
    //   y: newSize.height * newColor.to.y,
    // };
    // // 长轴点
    // const oldA = {
    //   x: oldF.x - newColor.to.r * size.height * Math.sin(newColor.angle * Math.PI / 180),
    //   y: oldF.y + newColor.to.r * size.height * Math.cos(newColor.angle * Math.PI / 180),
    // };
    // const newA = {
    //   x: oldA.x * newSize.width / size.width,
    //   y: oldA.y * newSize.height / size.height,
    // }
    // const newAngle = (getAngleByPoint(newF, newA) + 270)%360;
    // const newR = Math.abs(getTowPointDis(newF, newA) / newSize.height);
    // // 短轴点
    // const oldB = {
    //   x: oldF.x - newColor.to.r * size.height * newColor.widthRatio * Math.cos(newColor.angle * Math.PI / 180),
    //   y: oldF.y - newColor.to.r * size.height * newColor.widthRatio * Math.sin(newColor.angle * Math.PI / 180),
    // };
    // const newB = {
    //   x: oldB.x * newSize.width / size.width,
    //   y: oldB.y * newSize.height / size.height,
    // };
    // const newWidthRatio = Math.abs(getTowPointDis(newF, newB) / (newR * newSize.height));

    // newColor.angle = newAngle;
    // newColor.to = { ...(newColor.to || { x: 0.5, y: 0.5, r: 0.5 }), r: newR };
    // newColor.widthRatio = newWidthRatio;

    // newFill.color = newColor;
    // const fillPath = this.getCurrentPropertiesPath('properties/fill')
    // patches.do[id] = [Ops.replace(fillPath, newFill)];
    // patches.undo[id] = [Ops.replace(fillPath, properties.fill)];
    // }
    // FIXME: 这里不清楚各状态的newSize
    // 更新默认状态的fill
    // 更新各state的fill
    return patches;
  }

  get displayName(): string {
    const { name, type, value, data } = this;
    const { name: customName } = data;
    if (!customName && value && typeof value === 'string') {
      if (type === CText) {
        return sanitizeHtml(value, { allowedTags: [] }).trim().substr(0, 20);
      } else if (type === CPureText) {
        return value || type;
      } else if (type === CImage) {
        const path = value.split('/');
        return path[path.length - 1];
      }
    }
    return name || type;
  }

  get visibleInPreview(): boolean {
    let visible = !this.hidden;
    if (!visible) {
      return false;
    }

    // 这段逻辑只针对始终置顶（bringFront）的组件
    if (this.parent) {
      if (this.parent.hidden) {
        return false;
      }
      if (this.parent.parent?.type === CContentPanel || this.parent.parent?.type === CContentPanelV2) {
        if (!this.parent.selected) {
          return false;
        }
      }
      return this.parent.visibleInPreview;
    }
    return visible;
  }

  get float(): boolean {
    return !!this.toJSON().float;
  }

  /**
   * 默认支持编辑类型，这个还待重构完善，暂用
   * @author Mxj
   * @createdAt 2021-11-1
   * @return {any}
   */
  get valueEditType() {
    if (this.locked) {
      return ValueEditorType.None;
    }
    const libData = this.libData;
    const valueType = libData?.value?.type;
    if (!isUndefined(valueType)) {
      return valueType;
    }
    switch (this.type) {
      case CText:
      case CRect:
      case CEllipse:
      case CPolygon:
      case CParagraph:
        return ValueEditorType.RichText;
      case CButton:
      case CPureText:
      case CInput:
      case CTextArea:
      case CFrame:
      case CQRCode:
      case CNumericStep:
        return ValueEditorType.PureText;
      case CSelect:
      case CListLayoutPanel:
      case CContentPanel:
      case CContentPanelV2:
      case CStackPanel:
      case CWrapPanel:
      case CMultipleSelect:
        return ValueEditorType.ItemValue;
      case CTree:
      case CCollapse:
        return ValueEditorType.Tree;
      case CNavigationMenu:
      case CVerticalMenu:
      case CHorizontalMenu:
        return ValueEditorType.Menu;
      case CImage:
        return ValueEditorType.Image;
      case CVideo:
        return ValueEditorType.Video;
      case CAudio:
        return ValueEditorType.Audio;
      case CSnapshot:
        return ValueEditorType.Snapshot;
      case CIcon:
        return ValueEditorType.Icon;
      case CGroup:
      case CCompoundPath:
      case CCanvasPanel:
        return ValueEditorType.Group;
      case CPath:
        return ValueEditorType.RichText;
      case CTable:
        return ValueEditorType.Table;
      case CSymbol:
        return ValueEditorType.Symbol;
      case CCarouselChart:
        return ValueEditorType.CarouselChart;
      case CHotArea:
      default:
        return ValueEditorType.None;
    }
  }

  /**
   * 路径值
   * @author Mxj
   * @createdAt 2021-11-1
   * @return {IPathValue | null}
   */
  get path(): IPathValue | null {
    return null;
  }

  /**
   * 路径字符串数据
   * @author Mxj
   * @createdAt 2021-11-1
   * @return {string | null}
   */
  get pathData(): string | null {
    const path = this.path;
    return path ? transformPathDataToPath(path) : null;
  }

  /**
   * 是否支持编辑路径
   * @author Mxj
   * @createdAt 2021-11-1
   * @return {boolean}
   */
  get allowPathEditor(): boolean {
    return false;
  }

  protected doMatchRichText(currentRichTextValue: string, text: string, pageID: string): IFindResult[] {
    const result: IFindResult[] = [];
    const escapedText = escapeRegExp(text);
    const pattern = escapedText.replace(/[ ]/g, '\\s+');
    const regex = new RegExp(pattern, 'gi');
    const richTextContainer = document.createElement('div');
    richTextContainer.innerHTML = currentRichTextValue;

    let lineNumber = 0;

    traverseRichTextByLine(richTextContainer, (inlineNodes) => {
      lineNumber++;

      const inlineText = inlineNodes.reduce((prevText, inlineNode) => prevText + inlineNode.textContent ?? '', '');

      let match: ReturnType<RegExp['exec']>;
      while ((match = regex.exec(inlineText)) !== null) {
        const customMatch = Object.assign(match, {
          start: match.index,
          end: match.index + match[0].length,
          lineNumber,
        });
        result.push({ pageID, component: this, match: customMatch });

        console.log(`Found ${match[0]} start=${match.index} end=${regex.lastIndex} line=${lineNumber}.`);
      }
    });

    return result;
  }

  protected doReplaceRichText(
    currentRichTextValue: string,
    matchInfo: IFindResult['match'],
    text: string,
    newText: string,
  ): string {
    const richTextContainer = document.createElement('div');
    richTextContainer.innerHTML = currentRichTextValue;

    let lineNumber = 0;
    const nodeOperationInfoCollection: IRichTextNodeOperationInfo[] = [];

    traverseRichTextByLine(richTextContainer, (inlineNodes, parentNode) => {
      lineNumber++;

      if (lineNumber === matchInfo.lineNumber) {
        // 修改 rich text dom tree
        const inlineText = inlineNodes.reduce((prevText, inlineNode) => prevText + inlineNode.textContent ?? '', '');
        const matchPart = matchInfo[0];
        const matchPartNotExist = matchPart !== inlineText.substring(matchInfo.start, matchInfo.end);
        if (matchPartNotExist) {
          return;
        }
        const newTextContent = inlineText.substring(0, matchInfo.start) + newText + inlineText.substring(matchInfo.end);
        const newChildNode = document.createTextNode(newTextContent);
        nodeOperationInfoCollection.push({ inlineNodes, parentNode, newChildNode });
      }
    });

    // 修改 rich text dom tree
    nodeOperationInfoCollection.forEach(({ inlineNodes, parentNode, newChildNode }) => {
      parentNode.insertBefore(newChildNode, inlineNodes[0]);
      inlineNodes.forEach((inlineNode) => {
        // 保留行
        if (inlineNode.nodeName.toLowerCase() !== 'br') {
          inlineNode.remove();
        }
      });
    });

    // 生成新 text
    const newValue = richTextContainer.innerHTML;

    return newValue;
  }

  protected doReplaceAllRichText(
    currentRichTextValue: string,
    matchInfo: IFindResult['match'],
    text: string,
    newText: string,
  ): string {
    const richTextContainer = document.createElement('div');
    richTextContainer.innerHTML = currentRichTextValue;

    const nodeOperationInfoCollection: IRichTextNodeOperationInfo[] = [];

    traverseRichTextByLine(richTextContainer, (inlineNodes, parentNode) => {
      const inlineText = inlineNodes.reduce((prevText, inlineNode) => prevText + inlineNode.textContent ?? '', '');
      const matchPart = matchInfo[0];
      const escapedMatchPart = escapeRegExp(matchPart);
      const regex = new RegExp(escapedMatchPart, 'gi');
      const newTextContent = inlineText.replace(regex, newText);
      const newChildNode = document.createTextNode(newTextContent);
      nodeOperationInfoCollection.push({ inlineNodes, parentNode, newChildNode });
    });

    // 修改 rich text dom tree
    nodeOperationInfoCollection.forEach(({ inlineNodes, parentNode, newChildNode }) => {
      parentNode.insertBefore(newChildNode, inlineNodes[0]);
      inlineNodes.forEach((inlineNode) => {
        // 保留行
        if (inlineNode.nodeName.toLowerCase() !== 'br') {
          inlineNode.remove();
        }
      });
    });

    // 生成新 text
    const newValue = richTextContainer.innerHTML;

    return newValue;
  }

  // eslint-disable-next-line no-unused-vars
  public matchText(text: string, pageID: string): IFindResult[] {
    // 匹配结果放回还是放在 this 中？
    return [];
  }

  // eslint-disable-next-line no-unused-vars
  public replaceText(matchInfo: IFindResult['match'], text: string, newText: string): ArtboardPatches {
    // 匹配结果放回还是放在 this 中？
    return {
      do: {},
      undo: {},
    };
  }

  // prettier-ignore
  // eslint-disable-next-line no-unused-vars
  public replaceAllText(matchInfo: IFindResult['match'], text: string, newText: string): ArtboardPatches {
    // 匹配结果放回还是放在 this 中？
    return {
      do: {},
      undo: {},
    };
  }

  /**
   * 是否在动画内
   * 上级是否正在执行动画
   * 递归
   */
  public inAnimation(): boolean {
    return !!this.parent?._animation || !!this.parent?.inAnimation();
  }

  public get isContentPanel(): boolean {
    return this.type === CContentPanel || this.type === CContentPanelV2;
  }

  /**
   * 是否在内容面板内
   */
  public inContentPanel(): boolean {
    return !!this.parent?.isContentPanel || !!this.parent?.inContentPanel();
  }

  public getHeightByText(value: string, width?: number): number {
    let option = {
      defaultWidth: width || this.size.width,
      isMultiText: true,
      wrap: true,
      isRich: true,
    };

    const { textFormat, textStyle, multiText } = this.properties;

    const style = this.getStyleToCalculateSize({
      textFormat: textFormat || ({ ...textStyle, ...multiText, isMulti: !!multiText } as ITextFormatEx),
    });
    const { height } = measureTextSize(style, value, option);
    return height;
  }

  public hasInteraction(): boolean {
    return !isEmpty(this.interactions);
  }

  public get isAncestralGroupComponentWithInteraction(): boolean {
    if (!this.parent) {
      return false;
    }
    if (this.parent.isGroup && this.parent.hasInteraction()) {
      return true;
    }
    return this.parent.isAncestralGroupComponentWithInteraction;
  }

  /**
   * 演示时，
   * 组本身不显示热区；
   * 组件本身是否有交互：有就有；如果没有，就看祖先：祖先有组，就看这个祖先有没有交互；
   */
  public hasInteractionInPreview(): boolean {
    if (this.isGroup) {
      return false;
    }

    return this.hasInteraction() || this.isAncestralGroupComponentWithInteraction;
  }

  /**
   * 组件执行自动填充
   * @param valueBuildCallback
   */
  autoFill(valueBuildCallback: (val?: string) => string, category?: string): ArtboardPatches | undefined {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    const { type } = this;

    const fn = (_type?: string, value?: string) => {
      return valueBuildCallback(value);
    };

    const setImageFitMode = (imgComp: UIComponent) => {
      const imgProperties = imgComp.properties.image ?? { showModel: 'placeholder' };
      mergePatches(patches, imgComp.setProperty(ImgPropertyName, { ...imgProperties, fitMode: 'stretch' }));
    };

    const setCarouselImageAlias = (imgComp: UIComponent) => {
      const opt = {
        do: {
          [imgComp.id]: [Ops.replace('./alias', 'normal')],
        },
        undo: {
          [imgComp.id]: [Ops.replace('./alias', imgComp.alias)],
        },
      };
      mergePatches(patches, opt);
    };

    const [textComps, imgComps] = this.getAutofillComps();

    if (!textComps.length && !imgComps.length) {
      return undefined;
    }

    textComps.forEach((item) => {
      const value = fn(item.type, (item.value || item.text) as string);
      if (value) {
        const compPatch = isShapeText(item.type) ? item.setText(value) : item.setValue(value);
        mergePatches(patches, compPatch);
        if (item.type === CText && category === TextCategory.text && item.$data.textBehaviour !== 'height') {
          const opt = (item as UITextComponent).setTextHeightToPatch(value);
          mergePatches(patches, opt);
        }
      }
    });

    imgComps.forEach((item) => {
      const value = fn(item.type, item.value as string);
      if (value) {
        mergePatches(patches, item.setValue(value));
        if (type === CCarouselChart) {
          setCarouselImageAlias(item);
        }
        setImageFitMode(item);
      }
    });
    return patches;
  }

  allowTextAutofill() {
    const [textComps] = this.getAutofillComps();
    return textComps.length;
  }

  allowImgAutofill() {
    const [, imgComps] = this.getAutofillComps();
    return imgComps.length;
  }

  allowAutofill() {
    const [textComps, imgComps] = this.getAutofillComps();
    return imgComps.length || textComps.length;
  }

  /**
   * 获取自动填充时，可进行文本填充的组件数量
   */
  getSelectedTextCompsCountWithAutoFill(): number {
    if (isShapeText(this.type) || isTextType(this.type)) {
      return 1;
    }
    return 0;
  }

  /**
   * 获取允许数据填充的类型 - 对选中的组件包含是否可以被填充的子组件
   */
  private getAutofillComps = (): [UIComponent[], UIComponent[]] => {
    let textComps: UIComponent[] = [];
    let imgComps: UIComponent[] = [];

    const isHotArea = this.lib?.type === CHotArea;
    const isNumberStep = this.type === CNumericStep;
    if (isHotArea || isNumberStep) {
      return [textComps, imgComps];
    }

    if (!this.isContainer) {
      if (isShapeText(this.type) || isTextType(this.type)) {
        textComps = [this];
      }
      if (this.type === CImage || this.type === CCarouselChart) {
        imgComps = [this];
      }
    } else if (this.isSealed) {
      const libData = this.libData;
      const container = (this as unknown) as UIContainerComponent;

      // 特殊组件 - 表格组件
      if (libData?.type === CTable) {
        textComps = [container];
        return [textComps, []];
      }
      if (libData?.isList) {
        if (libData.isTextComp) {
          textComps = getTextCompInListSealed(container);
        } else {
          imgComps = getImageCompInListSealed(container);
        }
      } else if (libData?.isTextComp) {
        const comp = getTextCompInSealed(container);
        textComps = comp ? [comp] : [];
      }

      // 容器复合里边的组件有可能存在不可填充对象，需过滤
      textComps = textComps.filter((comp) => comp.type === CTable || isShapeText(comp.type) || isTextType(comp.type));
      imgComps = imgComps.filter((comp) => comp.type === CImage);
    }
    return [textComps, imgComps];
  };

  translate() {
    const does: [string, (v: string) => void][] = [];
    console.log('component translate', this.$data);
    this.$data.name &&
      does.push([
        this.$data.name,
        (res: string) => {
          Object.assign(this.$data, { name: res });
        },
      ]);
    this.$data.text &&
      does.push([
        this.$data.text,
        (res: string) => {
          Object.assign(this.$data, { name: res });
        },
      ]);
    this.$data.value &&
      does.push([
        JSON.stringify(this.$data.value),
        (res: string) => {
          Object.assign(this.$data, { value: JSON.parse(res) });
        },
      ]);
    return does;
  }
}
