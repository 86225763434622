import i18n from '@/i18n';
import { IComponentItem } from '@/libs/types';
import { CInput } from '@/libs/constants';
import { SpriteThumb } from '@/consts/spriteIcons';
import * as SystemsColor from '@consts/colors';
import { FillType } from '@/fbs/rp/models/properties/fill';
import { StrokeLineCap, StrokeLineJoin } from '@/fbs/rp/models/properties/stroke';
import { TextAlign } from '@/fbs/rp/models/properties/text';
import { InputModel } from '@/fbs/rp/models/properties/inputModel';
import { IComponentData } from '@/fbs/rp/models/component';
import { makeCommonComponent, getDefaultShadow } from '@/libs/helper';
import { PredefinedStates } from '@/consts/state';

export const inputConfig: IComponentItem = {
  type: CInput,
  name: i18n('resource.components.input'),
  thumb: {
    spriteIconClass: SpriteThumb.TextInput.className,
    dragPosition: SpriteThumb.TextInput.position,
  },
  getDefaultData() {
    return {
      properties: {
        fill: {
          type: FillType.solid,
          color: SystemsColor.DefaultWhiteFillColor,
          disabled: false,
        },
        stroke: {
          thickness: 1,
          color: SystemsColor.DefaultStrokeColor1,
          cap: StrokeLineCap.Round,
          join: StrokeLineJoin.Round,
          disabled: false,
        },
        shadow: getDefaultShadow(),
        radius: {
          topRight: 0,
          topLeft: 0,
          bottomLeft: 0,
          bottomRight: 0,
          isPercent: false,
          disabled: false,
        },
        border: {
          disabled: true,
          top: true,
          left: true,
          right: true,
          bottom: true,
        },
        textStyle: {
          fontFamily: 'Microsoft YaHei',
          fontStyle: { underline: false, bold: false, strike: false, italic: false },
          fontSize: 14,
          color: SystemsColor.DefaultTextColor,
          textAlign: TextAlign.left,
        },
        inputModel: {
          value: InputModel.normal,
        },
        placeholder: {
          prop: 'string',
          name: '@property.propertyNames.placeholder', // i18n('property.propertyNames.placeholder'),
          value: '',
        },
        placeHolderStyle: {
          prop: 'color',
          name: '@property.propertyNames.placeholderColor',
          value: SystemsColor.GrayColor,
        },
        padding: {
          left: 8,
          top: 0,
          right: 6,
          bottom: 0,
          disabled: false,
        },
      },
    };
  },
};

export function makeInput(id: string): IComponentData {
  return makeCommonComponent(id, CInput, {
    size: {
      width: 150,
      height: 30,
    },
    value: '',
    properties: {},
    states: {
      [PredefinedStates.focus]: {
        enabled: true,
        properties: {
          stroke: {
            thickness: 2,
            color: SystemsColor.FocusStrokeColor,
          },
        },
      },
      [PredefinedStates.disabled]: {
        enabled: true,
        properties: {
          stroke: {
            color: SystemsColor.DisabledStrokeColor,
          },
          textStyle: {
            color: SystemsColor.DisabledTextColor,
          },
        },
      },
      [PredefinedStates.hover]: {
        enabled: true,
        properties: {
          stroke: {
            color: SystemsColor.HoverStrokeColor,
          },
        },
      },
    },
  });
}
