import { ISwitchContentParams, PageSkipEffectType } from '@fbs/rp/models/interactions';
import { EventTypes } from '@fbs/rp/models/event';
import { ArtboardOperations, Ops } from '@fbs/rp/utils/patch';

import { UIFragment } from '@editor/comps';

import carouselManager from '../carouselManager';
import { revertActionType } from '@/helpers/revertHelper';

import CommandBase from './CommandBase';

/**
 * FIXME: 是否需要在当前内容面板做切换动作时进行保护，不触发其他切换动作？
 */
export default class ContentSwitchCommand extends CommandBase {
  public get afterEvent() {
    return EventTypes.loaded;
  }

  cashOriginParams = () => {
    this.saveCacheOriginParams('index', this.getCurrentIndex());
  };

  getCurrentIndex = () => {
    const { target } = this.command;
    const index = target.toJSON().components?.findIndex((comp) => comp.selected) ?? 0;
    return index === -1 ? 0 : index;
  };

  getTargetIndex = () => {
    const { params, target: comp } = this.command;
    const { target } = params as ISwitchContentParams;
    const currentIndex = this.getCurrentIndex();
    const comps = comp.toJSON().components || [];
    const maxIndex = this.getMaxIndex();
    let nextIndex = 0;

    if (target === '@next') {
      nextIndex = currentIndex < maxIndex ? currentIndex + 1 : 0;
    } else if (target === '@prev') {
      nextIndex = currentIndex > 0 ? currentIndex - 1 : maxIndex;
    } else {
      nextIndex = comps.findIndex((item) => item.value === target);
      // 交互中切换到指定的辅助画板，且切换的是同一个面板，不执行交互
      if (nextIndex === currentIndex) {
        return null;
      }
    }
    return nextIndex;
  };

  getMaxIndex = (): number => {
    const comps = this.command.target.toJSON().components || [];
    return comps.length - 1;
  };

  protected cleanAnimation = () => {
    const operations: ArtboardOperations = {};
    const { target } = this.command;
    const comps = target.toJSON().components ?? [];
    comps.forEach((comp) => {
      operations[comp._id] = [Ops.replace('./_animation', undefined)];
    });
    this.patch({ [target.ownerArtboardID]: operations });
  };

  initOperation(nextIndex: number, revert: boolean) {
    const currentIndex = this.getCurrentIndex();
    const { target, params, animate } = this.command;
    const { ownerArtboardID } = target;
    const comps = target.toJSON().components ?? [];
    const currentComp = comps[currentIndex];
    const nextComp = comps[nextIndex];
    const otherComps = comps.filter((comp, i) => i !== currentIndex && i !== nextIndex);
    const otherOperations: ArtboardOperations = {};
    otherComps.forEach((comp) => {
      otherOperations[comp._id] = [Ops.replace('./selected', false), Ops.replace('./_animation', undefined)];
    });
    const actionType = revert ? revertActionType(params.effect as PageSkipEffectType) : params.effect;
    const animation = {
      timing: animate.effect,
      delay: animate.delay,
      duration: animate.duration,
      animationIterationCount: animate.loop ? 'infinite' : 1,
    };

    return {
      [ownerArtboardID]: {
        ...otherOperations,
        [currentComp._id]: [
          Ops.replace('./selected', false),
          Ops.replace('./_animation', {
            ...animation,
            name: `current-comp-${actionType}`,
          }),
        ],
        [nextComp._id]: [
          Ops.replace('./selected', true),
          Ops.replace('./_animation', {
            ...animation,
            name: `next-comp-${actionType}`,
          }),
        ],
      },
    };
  }

  private getFragmentByIndex(index: number): UIFragment | null {
    const comps = this.command.target.toJSON().components || [];
    const selComp = comps[index];
    const fragmentID = selComp?.value as string;
    if (!fragmentID) {
      return null;
    }
    const fragment = this.doc.artboardsFragments.find((f) => f.artboardID === fragmentID) ?? null;
    return fragment;
  }

  startSubWorker = () => {
    if (this.isTermination) {
      return;
    }
    const fragment = this.getFragmentByIndex(this.getCurrentIndex());
    if (!fragment || !this.workManager?.startWorker || !this.fulfilled) {
      return;
    }
    this.fulfilled = false;
    this.workManager?.startWorker(fragment, this.afterEvent);
  };

  run() {
    if (this.isTermination) {
      return;
    }
    let index = this.getTargetIndex();

    if (index === null) {
      return;
    }

    this.fulfilled = index !== -1;

    if (!this.fulfilled) {
      return;
    }

    this.command.target.$actionIndex = index;

    const {
      params: { loopSwitch },
      target: { id },
    } = this.command;

    if (carouselManager.getPassiveValue(id).isInit) {
      carouselManager.setPassiveValue(id, {
        index,
      });
    } else {
      carouselManager.setPassiveValue(id, {
        isInit: true,
      });
    }
    if (loopSwitch) {
      this.autoRun();
    } else {
      this.patch(this.initOperation(index, false));
    }
  }

  autoRun = () => {
    const {
      animate: { delay, duration },
      params,
      target: { id },
    } = this.command;
    // 避免同一次循环被多次叠加触发，导致预料之外的结果
    if (carouselManager.isTargetExist(id)) {
      carouselManager.clearSingleCustomTimeout(id);
    }

    const switchParams = params as ISwitchContentParams;
    const isNext = switchParams.target === '@next';
    const isPrev = switchParams.target === '@prev';
    const nextIndex = this.getTargetIndex()!;
    let timer: number = -1;
    let autoIndex = isNext ? nextIndex - 1 : nextIndex + 1;

    // 使用自定义setInterval
    const setCustomInterval = () => {
      window.clearTimeout(timer);
      // 通过其他组件交互改变当前轮播序号
      const passiveIndex = carouselManager.getPassiveValue(id).index;
      if (passiveIndex > -1) {
        autoIndex = passiveIndex;
      }
      // 判断边界情况
      if (isNext && autoIndex >= this.getMaxIndex()) {
        autoIndex = -1;
      } else if (isPrev && autoIndex <= 0) {
        autoIndex = this.getMaxIndex() + 1;
      }

      this.patch(this.initOperation(isNext ? ++autoIndex : --autoIndex, false));
      this.command.target.$actionIndex = autoIndex;

      /* 
       解决delay为0时，切换到第3张图时没有动画的bug。
       只要delay不为0，就不会出现上述bug。
       具体原因未知，可能与计时器计时不稳定有关
        */
      timer = window.setTimeout(setCustomInterval, (delay || 10) + duration);
      carouselManager.setTimers(id, timer);
      // 重置被动设置的index
      carouselManager.resetPassiveValue(id);
    };
    setCustomInterval();
  };

  revert() {
    if (this.isTermination) {
      return;
    }
    const index = this.getCacheOriginParams('index');

    this.fulfilled = index !== -1;
    if (this.fulfilled) {
      this.patch(this.initOperation(index, true));
    }
  }
}
