import * as React from 'react';
import classnames from 'classnames';

import Icon from '../Icon';

import './index.scss';

const themeToClassNameMap: {
  [name: string]: string;
} = {
  border: 'dsm-c-button-border',
  greyBorder: 'dsm-c-button-grey-border',
  blueBorder: 'dsm-c-button-blue-border',
  icon: 'dsm-c-button-icon',
  small: 'dsm-c-button-small',
};

export interface IButtonProps {
  width?: number | string;
  minWidth?: number | string;
  theme?: 'basic' | 'border' | 'greyBorder' | 'icon' | 'small' | 'blueBorder';
  background?: 'white' | 'black' | 'blue';
  icon?: string;
  disabled?: boolean;
  children?: any | string;
  onClick?: React.MouseEventHandler;
  solid?: boolean;
  classname?: string;
  content?: string;
  classList?: string[];
}

const Button: React.FC<IButtonProps> = ({
  theme,
  icon,
  disabled,
  onClick,
  children,
  width,
  minWidth,
  background,
  solid,
  classname,
  content,
  classList,
}: IButtonProps) => {
  const themeClassName = theme ? themeToClassNameMap[theme] : '';
  return (
    <button
      className={
        classname ||
        classnames('dsm-c-button', themeClassName, background, classList && classList.length > 0 ? [...classList] : [])
      }
      disabled={disabled}
      onClick={onClick}
      style={{
        width,
        minWidth,
      }}
    >
      {theme === 'icon' && <Icon solid={solid} cls={icon} />} {content || ''}
      {children}
    </button>
  );
};

Button.defaultProps = {
  theme: 'basic',
  icon: '',
  disabled: false,
  width: 100,
  solid: false,
  classname: '',
  content: '',
};

export default Button;
