import * as React from 'react';
import classnames from 'classnames';
import TabPanelItem from './TabPanelItem';

import './index.scss';

interface ITabelItem {
  title: string;
}

export interface ITabPanelProp {
  selectedIndex: number;
  width?: number;
  className?: string;
  onSelected?: (index: number) => void;
}

export interface ITabPanelState {
  selectedIndex: number;
}

class TabPanel extends React.Component<ITabPanelProp, ITabPanelState> {
  constructor(props: ITabPanelProp) {
    super(props);
    this.state = { selectedIndex: props.selectedIndex || 0 };
  }

  UNSAFE_componentWillReceiveProps(newProps: ITabPanelProp) {
    if (newProps.selectedIndex !== this.state.selectedIndex) {
      this.setState({ selectedIndex: newProps.selectedIndex });
    }
  }

  onTitleSelected = (index: number) => {
    const { onSelected } = this.props;
    this.setState({ selectedIndex: index }, () => {
      onSelected && onSelected(index);
    });
  };

  render() {
    const { children, className, width } = this.props;
    return (
      <div className={classnames(`dsm-c-rp-tab-panel ${className}`)} style={{ width }}>
        {
          // eslint-disable-next-line react/jsx-key
          Array.isArray(children) && children.map((item) => <TabPanelItem>{item}</TabPanelItem>)
        }
      </div>
    );
  }
}

export default TabPanel;
