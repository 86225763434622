import * as React from 'react';
import { isEqual } from 'lodash';

import CheckList from './CheckList';
import SelectList from './SelectList';

import { TreeTransferKeys } from '../../constants/TreeTransfer';
import { IGroupProps, IItemProps } from '../../models';
import { saveToCahche } from '../utils';

import './index.scss';

const GroupSculpture = 'https://img02.mockplus.cn/idoc/image/2020-09-23/fbdfd2f1-fd67-11ea-9a10-8fdd726ad5b3.png';

interface ITreeTransferProps {
  enableEmailSearch?: boolean;
  showAddGroup?: boolean;
  height?: string | number;
  autoFocus?: boolean;
  groups?: IGroupProps[];
  singles?: IItemProps[];
  searchPlaceholder?: string;
  lastKey?: TreeTransferKeys;
  checkIds?: string[];
  checkGroupIds?: string[];
  addGroupTooltip?: string;
  disabledGroup?: number[];
  maxCount?: number;
  enableThirdPartyUUIDSearch?: boolean;

  onCheck?(checkIds: string[]): void;
  onCheckGroup?(checkGroupIds: string[]): void;
  onCheckDisable?(id: string): void;
  onSearch?(search?: string): void;
}

const getAllItems = (groups?: IGroupProps[], singles?: IItemProps[]) => {
  let items: IItemProps[] = [];

  if (groups && groups.length > 0) {
    groups.forEach((group: IGroupProps) => {
      const { children } = group;
      if (children && children.length > 0) {
        children.forEach((item) => {
          const existItem = items.filter((groupItem) => groupItem.id === item.id);
          if (existItem.length <= 0) {
            items.push(item);
          }
        });
      }
    });
  }

  if (singles && singles.length > 0) {
    singles.forEach((item) => {
      const existItem = items.filter((groupItem) => groupItem.id === item.id);
      if (existItem.length <= 0) {
        items.push(item);
      }
    });
  }

  return items;
};

const TreeTransfer: React.FC<ITreeTransferProps> = (props: ITreeTransferProps) => {
  const {
    enableEmailSearch,
    showAddGroup,
    height,
    autoFocus,
    groups,
    singles,
    searchPlaceholder,
    lastKey,
    checkIds,
    checkGroupIds,
    addGroupTooltip,
    disabledGroup,
    maxCount,
    enableThirdPartyUUIDSearch,
    onCheck,
    onCheckGroup,
    onCheckDisable,
    onSearch,
  } = props;

  const [checkedItems, setCheckedItems] = React.useState(checkIds || []);
  const [checkedGroups, setCheckedGroups] = React.useState(checkGroupIds || []);

  const allItems = getAllItems(groups, singles);

  React.useEffect(() => {
    if (checkIds && !isEqual(checkIds, checkedItems)) {
      setCheckedItems(checkIds);
    }
  }, [checkIds]);

  React.useEffect(() => {
    if (checkGroupIds && !isEqual(checkGroupIds, checkedGroups)) {
      setCheckedGroups(checkGroupIds);
    }
  }, [checkGroupIds]);

  React.useEffect(() => {
    if (lastKey) {
      saveToCahche(`${lastKey}-items`, checkedItems);
      showAddGroup && saveToCahche(`${lastKey}-groups`, checkedGroups);
    }
  }, [checkedItems, checkedGroups]);

  React.useEffect(() => {
    onCheck && onCheck(checkedItems);
  }, [checkedItems]);

  React.useEffect(() => {
    if (showAddGroup) {
      onCheckGroup && onCheckGroup(checkedGroups);
    }
  }, [checkedGroups]);

  const getSelectList = () => {
    let selectGroup: IItemProps[] = [];
    if (showAddGroup && groups && checkedGroups.length > 0) {
      selectGroup = groups
        .filter((item) => checkedGroups.includes(item.id))
        .map((item) => {
          return {
            id: item.id,
            imageURL: GroupSculpture,
            name: item.name,
            email: '',
            disabled: disabledGroup?.includes(Number(item.id)),
            isGroup: true,
          };
        });
    }
    let selectItem: IItemProps[] =
      (!!checkedItems.length && !!allItems.length && allItems.filter((item) => checkedItems.includes(item.id))) || [];

    return [...selectGroup, ...selectItem];
  };

  return (
    <div className={'c-tree-transfer'} style={{ height }}>
      <CheckList
        enableEmailSearch={enableEmailSearch}
        showAddGroup={showAddGroup}
        autoFocus={autoFocus}
        groups={groups || []}
        singles={singles || []}
        searchPlaceholder={searchPlaceholder || ''}
        lastKey={lastKey}
        addGroupTooltip={addGroupTooltip || ''}
        checkedItems={checkedItems}
        checkedGroups={checkedGroups}
        disabledGroup={disabledGroup}
        maxCount={maxCount || 0}
        hideGroupCheck={typeof maxCount === 'number' && maxCount < allItems.length}
        allItems={allItems}
        enableThirdPartyUUIDSearch={enableThirdPartyUUIDSearch}
        onCheckItem={setCheckedItems}
        onCheckGroup={setCheckedGroups}
        onCheckDisable={onCheckDisable}
        onSearch={onSearch}
      />
      <SelectList
        showAddGroup={!!showAddGroup}
        items={getSelectList()}
        checkedItems={checkedItems}
        checkedGroups={checkedGroups}
        onCheckItems={setCheckedItems}
        onCheckGroups={setCheckedGroups}
        onCheckDisable={onCheckDisable}
      />
    </div>
  );
};

export default TreeTransfer;
