import * as React from 'react';

import { IChartValue } from '@/fbs/rp/models/chart';
import { IPosition } from '@/fbs/common/models/common';
import { EChartType_basic } from '@/fbs/rp/models/properties/chart';
import { CCombinationChart } from '@/libs/constants';
import { ISelectArea } from '@/helpers/tableHelper';
import { IMenuItem, PopupMenu } from '@/dsm';
import i18n from '@/i18n';
import { ChartUtils } from './utils';

import './index.scss';

interface IChartMenuProps {
  type: string;
  chartValue: IChartValue;
  selectArea: ISelectArea;
  position: IPosition;
  canAddRow: boolean;
  canAddColumn: boolean;
  canCleanContent: boolean;
  deleteRowDisabled?: boolean;
  onSelectedAreaClear: () => void;
  onInputFocus: () => void;
  onMenuClose: () => void;
  onSubmit: (value?: IChartValue) => void;
}

enum MenuType {
  Copy = 'copy',
  Paste = 'paste',
  UnshiftRow = 'unshiftRow',
  PushRow = 'pushRow',
  DeleteRows = 'deleteRows',
  UnshiftColumn = 'unshiftColumn',
  PushColumn = 'pushColumn',
  DeleteColumns = 'deleteColumns',
  CleanContent = 'cleanContent',
}

export default class TableMenu extends React.Component<IChartMenuProps> {
  constructor(props: IChartMenuProps) {
    super(props);
  }

  private canDeleteRow(): boolean {
    const {
      chartValue: { dataSource },
      selectArea,
    } = this.props;
    const {
      start: { row: startRow },
      end: { row: endRow },
    } = selectArea;
    const onlyHeaderSelected = startRow === endRow && startRow < 1;
    const length = dataSource.length;
    const selectedAll = startRow <= 1 && endRow >= length;
    return onlyHeaderSelected || selectedAll;
  }

  private canDeleteColumn(): boolean {
    const {
      chartValue: { xAxis },
      selectArea,
    } = this.props;
    const {
      start: { column: startColumn },
      end: { column: endColumn },
    } = selectArea;
    const onlyHeaderSelected = startColumn === endColumn && endColumn < 1;
    const length = xAxis.length;
    const selectedAll = startColumn <= 1 && endColumn >= length;
    return onlyHeaderSelected || selectedAll;
  }

  private handleCopy = () => {
    // const { chartValue, selectArea: { start, end } } = this.props;
    return;
  };

  private handlePaste = () => {
    // const { chartValue, selectArea: { start, end } } = this.props;
    return;
  };

  private addRowOrColumn = (
    addRow: boolean,
    index: number,
    dataType?: EChartType_basic.Bar | EChartType_basic.Line,
  ) => {
    const { chartValue, type } = this.props;
    const newValue = addRow
      ? ChartUtils.insertRows(type, chartValue, index, 1, dataType)
      : ChartUtils.insertColumns(type, chartValue, index);
    this.props.onSubmit(newValue);
    this.props.onSelectedAreaClear();
  };

  private handleUnshiftRow = () => {
    const { chartValue, type, selectArea } = this.props;
    const currentRow = selectArea.start.row;
    let dataType;
    // 组合图上方添加行，维持type一致
    if (type === CCombinationChart) {
      const list = chartValue.dataSource;
      dataType = list[currentRow - 1]?.type;
    }
    this.addRowOrColumn(true, currentRow, dataType as EChartType_basic.Bar | EChartType_basic.Line);
  };

  private handlePushRow = () => {
    this.addRowOrColumn(true, this.props.selectArea.start.row + 1);
  };

  private handleDeleteRows = () => {
    const {
      chartValue,
      selectArea: { start, end },
    } = this.props;
    const count = end.row - start.row + 1;
    const newValue = ChartUtils.removeRows(chartValue, start.row, count);
    this.props.onSubmit(newValue);
    this.props.onSelectedAreaClear();
  };

  private handleUnshiftColumn = () => {
    this.addRowOrColumn(false, this.props.selectArea.start.column);
  };

  private handlePushColumn = () => {
    this.addRowOrColumn(false, this.props.selectArea.start.column + 1);
  };

  private handleDeleteColumns = () => {
    const {
      chartValue,
      selectArea: { start, end },
    } = this.props;
    const count = end.column - start.column + 1;
    const newValue = ChartUtils.removeColumns(chartValue, start.column, count);
    this.props.onSubmit(newValue);
    this.props.onSelectedAreaClear();
  };

  private handleCleanContent = () => {
    const { chartValue, selectArea } = this.props;
    const newValue = ChartUtils.clearContent(chartValue, selectArea);
    this.props.onSubmit(newValue);
    this.props.onInputFocus();
  };

  private getMenuData = (): IMenuItem[] => {
    const {
      canAddRow,
      canAddColumn,
      deleteRowDisabled,
      canCleanContent,
      selectArea: { start },
    } = this.props;

    return [
      // {
      //   id: MenuType.Copy,
      //   text: i18n('general.copy'),
      //   disabled: false,
      // },
      // {
      //   id: MenuType.Paste,
      //   text: i18n('general.paste'),
      //   disabled: !hasTableClipboarDataInStorage(),
      // },
      {
        id: '1',
        text: '-',
      },
      {
        id: MenuType.UnshiftRow,
        text: i18n('editor.unshiftRow'),
        hidden: !canAddRow,
        disabled: start.row < 1,
      },
      {
        id: MenuType.PushRow,
        text: i18n('editor.pushRow'),
        hidden: !canAddRow,
        disabled: false,
      },
      {
        id: MenuType.DeleteRows,
        text: i18n('editor.deletRows'),
        hidden: !canAddRow,
        disabled: this.canDeleteRow() || deleteRowDisabled,
      },
      {
        id: '3',
        text: '-',
      },
      {
        id: MenuType.UnshiftColumn,
        text: i18n('editor.unshiftColumn'),
        hidden: !canAddColumn,
        disabled: start.column < 1,
      },
      {
        id: MenuType.PushColumn,
        text: i18n('editor.pushColumn'),
        hidden: !canAddColumn,
        disabled: false,
      },
      {
        id: MenuType.DeleteColumns,
        text: i18n('editor.deletColumns'),
        hidden: !canAddColumn,
        disabled: this.canDeleteColumn(),
      },
      {
        id: '4',
        text: '-',
      },
      {
        id: MenuType.CleanContent,
        hidden: canCleanContent,
        text: i18n('editor.cleanContent'),
      },
    ];
  };

  private handleItemClick = (item: IMenuItem) => {
    const { onMenuClose } = this.props;
    try {
      switch (item.id) {
        case MenuType.Copy: {
          this.handleCopy();
          break;
        }
        case MenuType.Paste: {
          this.handlePaste();
          break;
        }
        case MenuType.UnshiftRow: {
          this.handleUnshiftRow();
          break;
        }
        case MenuType.PushRow: {
          this.handlePushRow();
          break;
        }
        case MenuType.DeleteRows: {
          this.handleDeleteRows();
          break;
        }
        case MenuType.UnshiftColumn: {
          this.handleUnshiftColumn();
          break;
        }
        case MenuType.PushColumn: {
          this.handlePushColumn();
          break;
        }
        case MenuType.DeleteColumns: {
          this.handleDeleteColumns();
          break;
        }
        case MenuType.CleanContent: {
          this.handleCleanContent();
          break;
        }
        default:
          break;
      }
    } finally {
      onMenuClose && onMenuClose();
    }
  };

  render() {
    const { position, onMenuClose } = this.props;
    return (
      <PopupMenu
        position={position}
        items={this.getMenuData()}
        onItemClick={this.handleItemClick}
        onClose={onMenuClose}
        width={100}
      />
    );
  }
}
