import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

interface IProps {
  dot?: boolean; // 显示右上角徽标
  dotSize: 'default' | 'mini';
  dotPosition: 'right' | 'right-top';
  dotOffset?: (number | string)[];
  count?: number;
  position: 'right' | 'right-top';
  offset?: (number | string)[]; // [offset x, offset y]
  size?: number;
  className?: string;
  onClick?: React.MouseEventHandler;
}

interface IState {}

class Badge extends React.PureComponent<IProps, IState> {
  static defaultProps: IProps = {
    dotSize: 'default',
    dotPosition: 'right-top',
    position: 'right-top',
  };

  constructor(props: IProps) {
    super(props);
  }

  /**
   * 获取偏移量
   * @param offset
   */
  getBadgeTranslate(offset?: (number | string)[]) {
    if (!offset) {
      return;
    }
    let [offsetX, offsetY] = offset;
    offsetX = offsetX || 0;
    offsetY = offsetY || 0;
    if (typeof offsetX === 'number') {
      offsetX = offsetX + 'px';
    }
    if (typeof offsetY === 'number') {
      offsetY = offsetY + 'px';
    }
    return `translate(${offsetX}, ${offsetY})`;
  }

  renderBadgeItem() {
    const { dot, dotSize, position, dotPosition, size, count, offset, dotOffset, onClick } = this.props;
    const style: React.CSSProperties = {};
    if (count) {
      style.minWidth = size;
      style.height = size;
      style.borderRadius = size;
      style.transform = this.getBadgeTranslate(offset);
      return (
        <span className={classnames('dsm-c-rp-badge-item count', position)} style={style} onClick={onClick}>
          {count < 100 ? count : '99+'}
        </span>
      );
    }
    if (dot) {
      style.transform = this.getBadgeTranslate(dotOffset);
      return (
        <span className={classnames('dsm-c-rp-badge-item dot', dotSize, dotPosition)} style={style} onClick={onClick} />
      );
    }
    return null;
  }

  render() {
    const { className, children } = this.props;
    return (
      <div className={classnames(className, 'dsm-c-rp-badge')}>
        {children}
        {this.renderBadgeItem()}
      </div>
    );
  }
}

export default Badge;
