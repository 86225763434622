import { CNavigationMenu, CHorizontalMenu, CVerticalMenu } from '@/libs/constants';
import { ImageTextTabsIconStylePropertyName } from '@/fbs/rp/models/properties/imageTextTabsIconStyle';
import { CarouselChartPropertyName } from '@/fbs/rp/models/properties/carouselChart';
import { MapChartPropertyName } from '@/fbs/rp/models/properties/mapChart';
import { GaugeChartPropertyName } from '@/fbs/rp/models/properties/gaugeChart';

interface IConfig {
  entireTextStyleName?: string;
  checkedTextStyleName?: string;
  hoverTextStyleName?: string;
}

export const StructurePropertyName = 'structure';

export const CompTextStyleNameConfig: { [key: string]: IConfig } = {
  'checkbox-group': {
    entireTextStyleName: 'textStyle1',
    checkedTextStyleName: 'textStyle2',
  },
  'radio-button-group': {
    entireTextStyleName: 'textStyle1',
    checkedTextStyleName: 'textStyle2',
  },
  'segments-control': {
    entireTextStyleName: 'textStyle',
    checkedTextStyleName: 'textStyle2',
  },
  selectTab: {
    entireTextStyleName: 'textStyle',
    checkedTextStyleName: 'textStyleChecked',
  },
  imageTextTabs: {
    entireTextStyleName: 'textStyle',
    checkedTextStyleName: 'textStyleChecked',
  },
  verticalTabs: {
    entireTextStyleName: 'textStyle',
    checkedTextStyleName: 'textStyleChecked',
  },
  list: {
    entireTextStyleName: 'textStyle',
    checkedTextStyleName: 'selectTextStyle',
  },
  breadCrumbs: {
    entireTextStyleName: 'textStyle',
    hoverTextStyleName: 'hoverTextStyle',
  },
  tree: {
    entireTextStyleName: 'textStyle',
    checkedTextStyleName: 'itemCheckedTextStyle',
  },
  [CNavigationMenu]: {
    entireTextStyleName: 'textStyle',
    checkedTextStyleName: 'itemCheckedTextStyle',
  },
  [CHorizontalMenu]: {
    entireTextStyleName: 'textStyle',
    checkedTextStyleName: 'itemCheckedTextStyle',
  },
  [CVerticalMenu]: {
    entireTextStyleName: 'textStyle',
    checkedTextStyleName: 'itemCheckedTextStyle',
  },
};

export const CBarChart = 'barChart';
export const CPieChart = 'chartPie';
export const CLineChart = 'lineChart';
export const CDoughnutChart = 'doughnutChart';
export const CBarChartHorizontal = 'barChartHorizontal';
export const CAreaChart = 'areaChart';
export const CRadarChart = 'radarChart';
export const CScatterChart = 'scatterChart';
export const CCombinationChart = 'combinationChart';

/**
 * 选中多个组件时进行属性对比时，需要进行深层比较的属性key
 * 合并组件修改时，还原单个组件的属性值时，需深层对比还原的key
 */
export const NEED_DEEP_COMPARE_PROPS = [
  ImageTextTabsIconStylePropertyName,
  CarouselChartPropertyName,
  GaugeChartPropertyName,
  MapChartPropertyName,
];
