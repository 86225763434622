import ILanguage from './type';

const isHuawei = RP_CONFIGS.isHuaWei;
const isHwDc = RP_CONFIGS.isHuaWeiDataCenter;
const isPrivateDeployment = RP_CONFIGS.isPrivateDeployment;

const zh_tw: ILanguage = {
  common: {
    title: isHuawei ? (isHwDc ? '{0} - Core Design' : '{0} - Tiny Design') : '{0} - 摹客RP',
    mock: '摹客',
  },
  // 主介面
  application: {
    flow: '流程圖範式',
    pen: '鋼筆',
    pencil: '鉛筆',
    group: '編組',
    ungroup: '取消編組',
    undo: '撤銷',
    redo: '重做',
    showStatePanel: '狀態面板', // 詳見 https://redmine.jongde.com/issues/40606
    hiddenStatePanel: '狀態面板',
    showArtboardPanel: '畫板列表',
    hiddenArtboardPanel: '畫板列表',
    artboardPanel: '畫板列表',
    play: '演示',
    playFromFirst: '從首頁開始',
    playFromCurrent: '從當前頁開始',
    switchRightPanelVisible: '收起/展開右側面板',
    newArtboard: '添加畫板',
    newSubArtboard: '添加輔助畫板',
    help: '幫助',
    navigation: '導航',
    fullScreen: '全屏',
    showLeftPanel: '顯示左側面板',
    showRightPanel: '顯示右側面板',
    title: isHuawei ? (isHwDc ? '{0} - Core Design' : '{0} - Tiny Design') : '{0} - 摹客',
    shortCut: '快捷鍵',
    tutorial: '教程',
    feedback: '提交意見',
    home: '官網',
    showGrid: '顯示網格',
    showLayout: '顯示布局',
    showRuler: '顯示標尺',
    showGuideline: '顯示參考線',
    removeAllVerticalGuides: '移除所有垂直參考線',
    removeAllHorizontalGuides: '移除所有水平參考線',
    removeGuides: '移除參考線',
    gridSettings: '網格設定',
    layoutSettings: '布局設定',
    alginConfig: '進階標記',
    tutorialURL: isPrivateDeployment
      ? `${window.location.origin}${window.apis.helpDomain}/p/360`
      : 'https://www.mockplus.cn/mockplus-rp/help',
    homeURL: 'https://www.mockplus.cn?home=1',
    feedbackURL: 'https://www.mockplus.cn/community/摹客在線原型',
    buyURL: 'https://www.mockplus.cn/buy/rp',
    forgotPassword: 'https://user.mockplus.cn/forgotpassword',
    exampleListURL: 'https://www.mockplus.cn/example/rp',
    signInUrl: 'https://user.mockplus.cn/signin?next={0}',
    coopers: '{0}等{1}人',
    zoom: '縮放',
    pageCount: '共{0}個頁面',
    pageCount2: '{0}個頁面',
    zoom4canvas: '適應所有畫板',
    zoom4selection: '適應選區',
    zoom4artboard: '適應畫板',
    option: '首選項',
    scaleBorderAndRadius: '縮放邊角', // 待審核
    scaleShadow: '縮放陰影', // 待審核
    settings: '設定',
    generalSettings: '常規選項',
    offlineDemo: '下載離線演示包',
    backToHomePage: '回返首頁',
    exitEdit: '退離編輯',
    showViewport: '#视图',
    showActiveFlag: '#显示交互标识',
    showRemarkNum: '#显示备注编号',
  },
  findReplaceTool: {
    findReplaceText: '查找与替换文字',
    findInput: '查找',
    replaceInput: '替换',
    inCurrentPage: '当前页面',
    inAllPage: '全部页面',
    replace: '替换',
    replaceAll: '全部替换',
    find: '查找',
    noResult: '无结果',
    replaceAllConfirmTips: '确定要将{0}中 {1} 处 “{2}” 替换为 “{3}” 吗？',
    resourceExpired: '此引用组件的资源已被删除或转换，确定要将其从资源中脱离并进行替换吗？',
    replaceAllResourceExpired: '部分引用组件的资源已被删除或转换，确定要将其从资源中脱离并进行替换吗？',
  },
  // 工作區
  workspace: {
    artboard: {
      downloadArtboard: '匯出畫板',
      downloadArtboardSvg: '匯出畫板Svg',
      downloadArtboardAsPdf: '导出画板为PDF',
      downloadSetting: '匯出畫板為圖片',
      downloadRatioSelect: '尺寸',
      downloadingMessage: '正在匯出圖片，這個過程可能耗時較長，請耐心等待。',
      downloadedMessage: '圖片已經匯出完成，請點擊下載按鈕下載檔案。',
      downloadTimeoutErrorMessage: '抱歉，导出超时，请尝试分批导出。如需帮助，请{0}。',
      downloadErrorMessage: '抱歉，导出失败。',
      copy: '副本',
      consoleErrorMessage: '图片导出异常：{0}',
      tooManyCheckedPages: '当前选中页面数量较多，为提高导出效率，建议分批操作',
      exportMainArtboardsOnly: '仅导出主画板',
      exportScale: '导出倍率',
    },
    grid: {
      blockSize: '網格尺寸',
      thickLinesEvery: '粗線間隔',
      colors: '顏色',
      makeDefault: '設定為預設',
      dark: '暗',
      light: '亮',
      blocks: '格',
    },
    layout: {
      columns: '列',
      totalWidth: '總寬',
      offset: '偏離',
      center: '居中',
      columnCount: '列數',
      gutterOnOutside: '外圍間距',
      gutterWidth: '間距',
      gutterHeight: '間距',
      columnWidth: '列寬',
      rows: '行',
      rowHeight: '行高',
      visuals: '視覺',
      fillGrid: '填充網格',
      strokeOutline: '外側描邊',
      drawAllGridLines: '繪制所有水平線',
    },
    alignType: {
      normalAlign: '常規對齊',
      equidistantAlign: '等距偵測',
      distanceAlign: '距離標記',
    },
    backToEditor: '回返',
    statusBar: {
      projectType: '專案類別',
      phone: '手機',
      pad: '平板',
      web: '網頁',
      watch: '手表',
      vehicle: '车载中控',
      custom: '自訂尺寸',
      blank: '空白',
      huawei: '華為',
    },
    generalSettings: {
      pasteOffset: {
        label: '貼上物件偏移距離',
        x: 'X',
        y: 'Y',
      },
      pasteAtTop: '貼上物件置于頂層',
      showHiddenArea: '顯示隱藏的區域',
      showLayerNameInPropertiesPanel: '在屬性面板顯示圖層名稱',
    },
  },
  // 資源庫
  resource: {
    project: '頁面',
    artboard: '畫板',
    component: '元件',
    icon: '圖示',
    resource: '設計資源',
    outline: '圖層',
    searchPage: '搜尋',
    searchComponent: '搜尋',
    searchComponentlib: '#搜索全部组件库',
    searchIcon: '搜尋',
    searchLib: '搜尋',
    searchLayer: '搜尋',
    recycleBin: '回收站',
    // 元件庫
    components: {
      groupName: '#预设',
      basic: '基本',
      container: '容器',
      common: '常用',
      flow: '形狀',
      antDesign: '', // 未使用
      comment: '批注',
      chart: '#图表',
      text: '文字',
      textContent: '請輸入文字內容',
      rect: '矩形',
      ellipse: '橢圓',
      image: '圖片',
      line: '線條',
      triangle: '三角形',
      polygon: '多邊形',
      paragraph: '多行文字',
      input: '輸入框',
      textarea: '文字框',
      pureText: '文字',
      icon: '圖示',
      canvasPanel: '面板',
      contentPanel: '內容面板',
      listLayoutPanel: '列表面板',
      stackPanel: '堆疊面板',
      wrapPanel: '折行面板',
      selectPanel: '選項組',
      // extension
      button: '按鈕',
      iconButton: '圖示按鈕',
      switch: '開關',
      checkbox: '復選框',
      checkboxGroup: '復選框組',
      radio: '單選按鈕',
      radioButtonGroup: '單選按鈕組',
      select: '下拉框',
      list: '列表',
      numericStep: '數字輸入器',
      breadCrumbs: '面包屑',
      segmentsControl: '分段元件',
      selectTab: '選項卡',
      slider: '滑塊',
      progressBar: '進度條',
      circleProgressBar: '環形進度條',
      score: '評分元件',
      video: '視訊',
      qrcode: '二維碼',
      keyboard: '鍵盤',
      audio: '音訊',
      tree: '樹',
      table: '表格',
      hotArea: '熱區',
      //datePicker
      datePicker: '#日期选择器',
      histogram: '#柱状图',
      datePickerInfo: {
        optionDate: '#请选择日期',
        startDate: '#开始日期',
        endDate: '#结束日期',
        Mon: '#一',
        Tues: '#二',
        Wed: '#三',
        Thur: '#四',
        Fri: '#五',
        Sat: '#六',
        Sun: '#日',
        year: '#年',
        month: '#月',
        date: '#日',
      },
      timePicker: '#时间选择器',
      //图表类
      barChart: '#柱状图',
      pieChart: '#饼图',
      doughnutChart: '#环形图',
      lineChart: '#折线图',
      barDiagram: '#条形图',
      areaChart: '#面积图',
      radarChart: '#雷达图',
      scatterChart: '#散点图',
      // comment
      arrow: '箭頭',
      callOut: '編號',
      stickNote: '便簽條',
      drop: '水滴',
      dropDown: '下拉列表',
      snapshot: '快照',
      verticalCurly: '垂直花括號',
      horizontalCurly: '水平花括號',
      verticalSeparatorLine: '垂直分隔線',
      horizontalSeparatorLine: '水平分隔線',
      labelInput: '標簽輸入框',
      searchBox: '搜尋框',
      link: '鏈結',
      path: '路徑',
      compoundPath: '復合路徑',
      connector: '配接線',
      group: '組',
      verticalTab: '垂直选项卡',
    },
    componentsText: {
      itemText: '選項',
      optionText: '選項',
      newItem: '新條目',
      listItemText: '條目',
      xAxisText: '#值{0}',
      yAxisText: '#数据{0}',
      breadCrumbsText: '一級目錄|二級目錄|三級目錄|四級目錄',
      qrCode: 'https://www.mockplus.cn',
      selectTeam: '請選擇團隊',
      selectProject: '請選擇專案',
      snapshotText: '雙點插入設計稿',
      snapshotCanNotFound: '無法找到源設計稿',
      snapshotInvalid: '快照失效',
      VPCImageURL: 'https://img02.mockplus.cn',
      // replaceVPCImageURL: 'http://mockplus-static.oss-cn-hangzhou-internal.aliyuncs.com',
      snapshotTitle: '插入設計稿',
      noContent: '暫無內容',
      resetCropArea: '清除選區',
      fitCompText: '適應當前尺寸',
      currentSize: '尺寸',
    },
    // 元件： 圖形庫
    flow: {
      rectangle: '矩形',
      parallelogram: '平行四邊形',
      diamond: '菱形',
      circle: '圓',
      ellipse: '橢圓',
      triangle: '三角形',
      pentagon: '五邊形',
      roundRectangle: '圓角矩形',
      semicircle: '半圓',
      octagon: '八邊形',
      keying: '鍵入',
      start: '開始/結束',
      rightTriangle: '右三角形',
      trapezoid: '梯形',
      hexagon: '六邊形',
      drop: '水滴',
      heart: '心形',
      sector: '扇形',
      star: '星形',
      cloud: '云',
      dataBase: '資料庫',
      punchedCard: '卡片',
      roundedSpeechBubble: '對話框（圓）',
      squareSpeechBubble: '對話框（方）',
      rightArrowButton: '右箭頭按鈕',
      leftArrowButton: '左箭頭按鈕',
      offPageConnector: '跨頁引用',
      ready: '預備',
      beveledRectangle: '斜角矩形',
      sectorDiagram: '扇形圖',
      cross: '十字',
      limitCycle: '迴圈限值',
      manualInput: '人工輸入',
      punchedTape: '條帶',
      display: '展示',
      externalData: '外部資料',
      manualOperation: '手動作業',
      rightArrow: '右箭頭',
      leftArrow: '左箭頭',
      horizontalDoubleArrow: '左右箭頭',
      upArrow: '上箭頭',
      downArrow: '下箭頭',
      verticalDoubleArrow: '上下箭頭',
      upGrid: '矩形格（上）',
      leftGrid: '矩形格（左）',
      classicTab: '經典標簽',
      queueData: '佇列資料',
      delay: '推遲',
      APQC: 'APQC',
      collate: '對照',
      leftBracket: '注釋（左）',
      rightBracket: '注釋（右）',
      leftBrace: '備注（左）',
      rightBrace: '備注（右）',
      document: '文檔',
      pieChart: '餅圖',
      rightAngle: '右拐角',
      rightTurn: '右回返',
      leftTurn: '左回返',
    },
    // 圖示庫
    icons: {
      lightIcon: '細線',
      boldIcon: '粗線',
      pay: '支付',
      office: '檔案辦公',
      chart: '圖表資料',
      gesture: '手勢',
      social: '社交',
      device: '裝置',
      traffic: '交通',
      symbol: '符號',
      direction: '方向',
      media: '多媒體',
      edit: '編輯',
      treatment: '醫療',
      letter: '數字/字母',
    },
    // 設計資源庫
    libs: {
      arrangementMode: '布局',
      component: '元件',
      color: '顏色',
      typography: '文字樣式',
      addLib: '{0}存為資源',
      createSymbol: '添加資源',
      share: '共享',
      cancelSharing: '取消共享',
      cancelSharingToAll: '取消共享(所有團隊)',
      placeholder: '{0}的設計資源',
      shareToMyTeam: '將資源共享至當前團隊嗎？',
      shareToAllTeams: '將資源共享至：',
      cancelShareToMyTeam: '當前團隊正在共享此資源，確定要取消共享嗎？',

      cancelShareToAllTeams: '系統中所有團隊正在共享此資源，確定要取消共享嗎？',

      myTeam: '當前團隊',
      allTeams: '系統內所有團隊',
      sync: '資源相互引用，并自動同步修改',
      addGroup: '新建分組',
      management: '管理資源',
      defaultGroupName: '新分組',
      moveGroup: '刪除“{0}”分組后不可恢復，確定繼續嗎？\n你可以將組內的{1}個資源移至其它分組，否則資源將被一同刪除。',

      removeGroup: '確定要刪除“{0}”分組嗎？',
      noMoveGroup: '刪除資源',
      moveAssetTo: '移動資源到',
      moveAsset: '移動',
      noGroup: '暫無分組',
      noComponent: '暫無元件',
      addComponentPlaceholder: '資源名稱',
      addGroupPlaceholder: '分組名稱',
      resourceLoadFail: '抱歉，載入出錯了...',
      reload: '重新載入',
      searchGroup: '搜尋',
      cannotModify: '此元件為共享的同步資源，只有資源創建者才可對其進行修改。',
      deleteSyncComponent: '確定要刪除當前資源嗎？此作業不可恢復。',
      deleteAsyncComponent: '確定要刪除當前資源嗎？此作業不可恢復。',
      deleteSharedComponent: '當前資源已共享，請取消共享后再刪除。',
      appName: '來源：{0}',
      noApp: '來源：被刪除的專案',

      cannotAddRefResource: '你正在使用共享的設計資源庫，請切換至當前專案的設計資源庫后再試。',
      convertToSymbol: '轉為引用資源',
    },
  },
  // 專案
  project: {
    page: '頁面',
    group: '分組',
    newPage: '新建頁面',
    newGroup: '新建分組',
    addNew: '新建',
    childPage: '子頁面',
    childGroup: '子分組',
    insert: '插入',
    undo: '恢復',
    delete: '清除',
    resetAll: '恢復所有',
    clearAll: '清除所有',
    defaultPageName: '頁面1',
    undoNow: '立即恢復',
    showPageNumber: '顯示頁面編號',
    hiddenPageNumber: '隱藏頁面編號',
    showPage: '顯示',
    hiddenPage: '隱藏',
    hiddenPageTip: '演示介面中，隱藏的頁面對無專案編輯權限者不可見。',
    mainName: '主畫板',
    mainDTName: '畫板',
    subArtboard: '輔助畫板 {0}',
    subDTArtboard: '畫板 {0}',
    revisions: {
      title: '歷史版本',
      revert: '還原到該歷史版本',
      noRevision: '當前版本下，該畫板沒有歷史記錄',
      revertTips: '確定將當前頁面內容還原到該歷史版本嗎？其它頁面不受影響。',
      tips: '摹客會自動為你生成歷史版本，你可以根據需要恢復歷史資料。',

      noPageRevisions: '當前頁面還未產生歷史版本!',
      currentVersion: '當前版本',
      revisions: '版本',
      artboard: '畫板',
      moreRevisions: '更多歷史版本',
      upgradeTips: '當前僅展示7天內的歷史版本，參加免費計劃或更新團隊可檢視全部版本記錄。', // '更新團隊后，可檢視更多歷史版本。',
      upgradeHelp: '更新團隊',
    },
    empty: {
      welcome: '開始你的設計',
      tips: '創建高保真設計、交互原型，多人實時編輯',
    },
    currentProject: '當前專案',
  },
  // 編輯器
  editor: {
    clickAndUpload: '點擊上載',
    applyOriginalSize: '按照原始尺寸',
    commonlyUsedColor: '常用顏色',
    solidFill: '純色',
    linearFill: '線性漸變',
    radialFill: '徑向漸變',
    append: '添加',
    up: '上移',
    down: '下移',
    addChild: '添加子項',
    mergeCells: '合并單元格',
    splitCells: '拆分單元格',
    cleanStyle: '清除格式',
    cleanContent: '清除內容',
    row: '行',
    column: '列',
    unshiftColumn: '左側插入1列',
    unshiftColumns: '左側插入3列',
    pushColumn: '右側插入1列',
    pushColumns: '右側插入3列',
    unshiftRow: '上方插入1行',
    unshiftRows: '上方插入3行',
    pushRow: '下方插入1行',
    pushRows: '下方插入3行',
    dragToAddRows: '點擊或拖拽以添加行',
    dragToAddColumns: '點擊或拖拽以添加列',
    tableInfo: '行：{0}，列：{1}',
    deletColumns: '刪除所在列',
    deletRows: '刪除所在行',
    contentType: '單元格類別',
    convertToText: '文字',
    convertToCheckBox: '復選框',
  },
  // 桌面應用程式
  pc: {
    name: '摹客RP',
    homepage: '首頁',
    logout: '登出', // 同線上
    team: {
      change: {
        label: '切換團隊', // 同線上
      },
      name: {
        label: '名稱', // 同線上
      },
      role: {
        label: '身份', // 同線上
        value: {
          superAdministrator: '超級管理員', // 同線上
          administrator: '管理員', // 同線上
          member: '成員', // 同線上
          visitor: '協同人', // 同線上
        },
        ability: {
          administrator: '管理員（全部功能）',
          member: '成員（可編輯和設計）',
          visitor: '協同人（僅可檢視、評論和下載）',
        },
      },
      homepage: {
        label: '團隊首頁',
        url: 'https://rp.mockplus.cn/team/{0}',
      },
      upgrade: {
        content: '通用版不支援此功能，請參加免費計劃或更新團隊后使用。',
        activity: '參加免費計劃',
        buy: '更新團隊',
        buyNow: '立即更新',
      },
      create: {
        title: '新建團隊',
        label: '在新團隊中開始你的設計',
        placeholder: '請輸入團隊名稱',
        value: '{0}的團隊',
      },
    },
    app: {
      createLabel: '專案',
      appsIsMaximum: '當前團隊專案數量已達上限，請更新團隊后重試。',
      newApp: '新建',
      create: {
        title: '新建專案',
      },
      user: {
        label: '作者', // 同線上
      },
      time: {
        create: '創建時間', // 同線上
        update: '更新時間', // 同線上
      },
      name: {
        label: '名稱', // 同線上
        value: '新專案', // 同線上
        placeholder: '專案名稱', // 同線上
      },
      type: {
        label: '專案類別',
        recent: '最近更新', // 同線上
        star: '我的收藏', // 同線上
        myCreate: '我創建的', // 同線上
        all: '全部', // 同線上
        recycle: '回收站', // 同線上
        achive: '已歸檔', // 同線上
        private: {
          value: '私有',
          explain: '僅專案內成員可見',
        },
        teamApp: {
          value: '團隊',
          explain: '團隊的所有成員都可見',
        },
      },
      appSet: {
        create: {
          title: '創建新專案集', // 同線上
        },
        name: {
          placeholder: '請輸入專案集名稱', // 同線上
        },
        parent: {
          placeholder: '根目錄',
        },
      },
      restore: '恢復', // 同線上
      clear: {
        value: '清除', // 同線上
        title: '清除后不可恢復，確定要清除嗎？', // 同線上
      },
      star: '收藏', // 同線上
      unStar: '取消收藏', // 同線上
      move: '移動', // 同線上
      delete: '確定要刪除“{0}”嗎？',
      noContent: '抱歉，暫無內容',
      search: '搜尋',
      screen: '篩選',
      module: {
        rp: {
          label: '僅RP專案',
        },
        all: '全部專案',
      },
      demo: '演示',
      member: {
        label: '專案成員',
        authorization: '權限管理',
        search: '搜尋',
        invitation: {
          label: '邀請新成員',
          link: '邀請鏈結',
          copyLink: '復制鏈結',
          term: '鏈結有效期',
          invalid: {
            label: '{0}天',
            content: '此鏈結將在{0}天后失效',
          },
          forever: {
            label: '永久',
            content: '此鏈結將永久有效',
          },
          fullStarffed: {
            tipOne: '成員人數已達',
            tipTwo: '人，請擴容后再添加更多成員。',
            expansion: '立即擴容',
          },
          overflow: {
            title: '成員數量已超出限制，請聯系摹客客服處理。',
            contact: '聯系客服',
          },
        },
        department: '所屬部門',
        addDepartment: '添加部門',
        submit: '提交',
        submitSuccess: '提交成功',
        ungrouped: '未分組',
        lastSelection: '上次的選擇',
        noMembers: '團隊中還未加入其他成員',
        removeParent: {
          group: '父級專案集的部門禁用，請在父級的成員管理中取消選擇。',
          member: '父級專案集的成員禁用，請在父級的成員管理中取消勾選。',
          link: isPrivateDeployment
            ? `${window.location.origin}${window.apis.helpDomain}/p/238`
            : 'https://help.mockplus.cn/p/238',
        },
        removeCreator: '所有者無法被移除',
      },
      width: '寬',
      height: '高',
    },
    user: {
      email: {
        placeholder: '信箱/摹客賬號', // 同線上
        noValue: '請輸入格式正確信箱。', // 同線上
        register: {
          placeholder: '請輸入注冊信箱', // 同線上
        },
      },
      password: {
        placeholder: '密碼', // 同線上
        register: {
          placeholder: '請輸入登入密碼', // 同線上
        },
        repeat: '請再次輸入密碼', // 同線上
        forgot: '忘記密碼', // 同線上
        noValue: '密碼長度為6-20。', // 同線上
        different: '兩次密碼輸入不相同。', // 同線上
      },
      vocation: {
        value: {
          pm: '產品經理',
          projectManager: '專案經理',
          designer: '設計師',
          developer: '開發工程師',
          operator: '運營人員',
          other: '其他',
        },
        placeholder: '請選擇你的職業',
      },
      noUser: '還沒有賬號？', // 同線上
      register: {
        link: '立即注冊', // 同線上
        value: '注冊', // 同線上
      },
      agree: {
        read: '我已仔細閱讀并同意', // 同線上
        value: '《摹客使用協定》', // 同線上
      },
      login: {
        title: '摹客RP',
        content: '讓設計更快更簡單',
        value: '登入', // 同線上
        hasAccount: '已有賬號？', // 同線上
        link: '立即登入', // 同線上
        linkToWebsite: '', // 暫未上線
        networkError: '網路異常，請檢查后重試',
      },
      admin: {
        label: '賬戶管理',
        link: 'https://www.mockplus.cn/me',
      },
      guest: {
        cannotEdit: '此客戶端僅用于專案編輯\n協同人沒有權限',
        contactAdministrator: '請聯系團隊管理員修改身份后再試',
      },
      switch: '切換賬號',
    },
  },
  theme: {
    apply: '應用程式并關閉',
    cancel: '取消',
  },
  // 屬性
  property: {
    properties: '屬性',
    interactions: '交互',
    // 屬性名
    propertyNames: {
      fill: '填充',
      bgcolor: '背景色',
      hoverFill: '懸停背景',
      forgetColor: '前景色',
      buttonColor: '按鈕色',
      checkedColor: '選中顏色',
      checkedBgcolor: '選中背景',
      stroke: '邊框',
      lineStroke: '線條',
      checkedUnderbar: '選中標識條',
      border: '邊框',
      radius: '圓角',
      text: '文字樣式',
      checkedText: '選中文字樣式',
      defaultText: '預設文字樣式',
      multiText: '排版',
      polygon: '多邊形',
      icon: '圖示',
      iconColor: '圖示顏色',
      color: '顏色',
      img: '圖片',
      iconSize: '圖示大小',
      layout: '布局',
      container: '容器',
      shadow: '陰影',
      line: '線段',
      lineHeight: '行高',
      inputModel: '輸入類別',
      arrow: '箭頭',
      placeholder: '占位文字',
      placeholderColor: '占位文字顏色',
      angle: '旋轉',
      close: '閉合',
      controls: '顯示控制條',
      autoPlay: '自動播放',
      hoverTextStyle: '鏈結樣式',
      checked: '選中',
      trackStyle: '軌道樣式',
      progressStyle: '進度樣式',
      progress: '進度',
      progressColor: '進度顏色',
      trackFill: '軌道顏色',
      indicatorColor: '滑塊顏色',
      separator: '分隔線',
      separatorRatio: '分隔線长度',
      keyboard: '鍵盤類別',
      featureButtonColor: '功能鍵顏色',
      fontColor: '文字顏色',
      textBgColor: '文字背景色',
      defaultSelected: '選中',
      defaultOpen: '預設開啟',
      padding: '內邊距',
      extensionLink: '外部鏈結',
      remark: '備注',
      blur: '模糊',
      colorFilter: '調整顏色',
      float: '卷軸時固定位置',
      deviceType: '#设备类型',
      dateType: '#默认样式',
      dateTheme: '#颜色风格',
      dateFormat: '#日期格式',
      showMark: '#显示遮罩',
      showSelectedYear: '#显示年份',
      replaceShape: '#形状',
      axis: '#坐标轴',
      dataLabel: '#数据标签',
      legendPosition: '#图例',
      type: '#类型切换',
      smooth: '#端点平滑过渡',
      stack: '#堆积',
      loopPlay: '循環播放',
      crossPagePlay: '跨頁播放',
      tooltips: '#提示文字',
      length: '长度',
      // 导出
      export: {
        export: '導出',
        preview: '预览',
        prefix: '前缀',
        suffix: '后缀',
        multiExport: '导出{0}个图层',
        loadingText: '图片准备中，已完成{0}/{1}，请稍候...',
      },
    },
    // 元件屬性
    component: {
      alignLeft: '左對齊',
      alignVerticalCenter: '水平居中對齊',
      alignRight: '右對齊',
      alignTop: '頂對齊',
      alignJustify: '兩端對齊',
      alignHorizontalCenter: '垂直居中對齊',
      alignBottom: '底對齊',
      alignAverageHorizontal: '水平等距',
      alignAverageVertical: '垂直等距',
      showComp: '顯示',
      hideComp: '隱藏',
      enabledComp: '啟用',
      disabledComp: '禁用',
      selectComp: '選擇了 {0} 個元件',
      opacity: '不透明度',
      clipPath: '裁剪路徑',
      openPath: '開啟路徑',
      closePath: '閉合路徑',
      segRadius: '圓角',
      straight: '筆直',
      mirrored: '對稱',
      disconnected: '分離',
      asymmetric: '不對稱',
      shape: '外形',
      text: '文字',
      extend: '擴展',
      relation: {
        label: '關聯',
        add: '添加鏈結',
        init: {
          specificationDocument: '檢視規范文檔',
          componentCode: '檢視元件程式碼',
          visualAnnotation: '檢視視覺標記',
        },
      },
      border: {
        left: '左邊框',
        right: '右邊框',
        bottom: '下邊框',
        top: '上邊框',
      },
      bounds: {
        lockRatio: '鎖定寬高比',
        flipHorizontal: '水平翻轉',
        flipVertical: '垂直翻轉',
        fixedWidth: '固定寬度',
        fixedHeight: '固定高度',
        horizontalCenter: '水平居中',
        verticalCenter: '垂直居中',
        responsive: '啟用響應式布局',
        auto: '自動',
        manual: '手動',
      },
      cell: {
        rowHeight: '行高',
        rowCount: '行數',
        itemHeight: '子項高度',
        itemWidth: '子項寬度',
        columnWidth: '列寬',
        columnCount: '列數',
        columnGap: '列間距',
        rowGap: '行間距',
        gap: '間距',
      },
      container: {
        scroll: '支援卷軸',
        showScroll: '顯示卷軸條',
      },
      img: {
        upLoadImg: '上載圖片',
        fit: '填充',
        tile: '平鋪',
        original: '適應',
        stretch: '拉伸',
        defaultStyle: '預設樣式',
      },
      colorFilter: {
        brightness: '亮度',
        contrast: '對比度',
        hueRotate: '色相',
        saturate: '飽和度',
        invert: '反相',
        revert: '恢復',
      },
      layout: {
        vertical: '垂直',
        horizontal: '水平',
        gap: '間距',
      },
      line: {
        startArrow: '起點',
        endArrow: '終點',
        none: '無端點',
      },
      multiText: {
        lineHeight: '行高',
        wrap: '自動換行',
        autoSize: '自動大小',
        indent: '首行縮進',
        vertical: '豎排',
      },
      polygon: {
        sideCount: '邊數',
      },
      radius: {
        allRadius: '全部圓角',
        eachRadius: '單個圓角',
        isPercent: '百分比圓角',
      },
      textStyle: {
        richText: '富文字',
        bold: '加粗',
        italic: '斜體',
        underline: '下劃線',
        deleteline: '刪除線',
        MicrosoftYahei: '微軟雅黑',
        pingfang: '蘋方',
        SimSun: '宋體',
        FangSong: '仿宋',
        KaiTi: '楷體',
        SimHei: '黑體',
        letterSpacing: '字間距',
        olist: '編號',
        ulist: '專案符號',
      },
      stroke: {
        thickness: '寬度',
        dash: '虛線',
        gap: '間隔',
        buttCap: '平頭端點',
        roundCap: '圓頭端點',
        squareCap: '矩形端點',
        miterJoin: '斜接配接',
        roundJoin: '圓角配接',
        bevelJoin: '斜面配接',
        centerStroke: '居中描邊',
        innerStroke: '內描邊',
        outerStroke: '外描邊',
      },
      keyboard: {
        letter: '字母',
        numeric: '數字',
        symbol: '符號',
      },
      direction: {
        top: '上',
        left: '左',
      },
      inputModel: {
        normal: '文字',
        numeric: '數字',
        password: '密碼',
      },
      tree: {
        levelIndent: '縮進',
        treeExpand: '按鈕',
        treeNode: '圖示',
        treeCheckbox: '復選框',
        itemText: '專案',
        triangle: '三角',
        plus: '加號',
        arrow: '箭頭',
      },
      table: {
        rowHeight: '行高',
        columnWidth: '列寬',
        border: '邊框',
        fill: '填充',
        rowLine: '行線',
        columnLine: '列線',
      },
      fixContent: '各狀態文字內容保持一致',
      dateType_normal: '#日期',
      dateType_range: '#日期区间',
      web: '#网页设备',
      phone: '#手机设备',
      dark: '#深色模式',
      light: '#浅色模式',
      dateformat_normal: '#yyyy-mm-dd',
      dateformat_secondStyle: '#yyyy/mm/dd',
      dateformat_thirdStyle: '#yyyy年mm月dd日',
      dateformat_fourStyle: 'dd.mm.yyyy',
      timePickerModelNormal: '时间',
      timePickerModelRange: '时间区间',
      legendPosition_bottom: '#底部',
      legendPosition_top: '#顶部',
      legendPosition_left: '#左侧',
      legendPosition_right: '#右侧',
      number: '#数值',
      percent: '#百分比',
      pie_normal: '#饼图',
      pie_abNormal: '#环形图',
      barChart: '#柱形图',
      barDiagramChart: '#条形图',
      areaChart: '#面积图',
      radarChart: '#雷达图',
      lineChart: '#折线图',
      scatter: '#散点图',
    },
    // 頁面屬性
    page: {
      resetSize: '設定為預設尺寸',
    },
    // 交互
    interaction: {
      fragmentAction: '畫板交互',
      invalidTarget: '失效目標',
      invalidState: '失效狀態',
      invalidFragment: '失效畫板',
      addAction: '添加交互',
      home: '首頁',
      back: '上一頁',
      actionOrder: '命令',
      autoRevert: '自動還原',
      parallel: '同時開始',
      compCommand: '[{0}] {1}: {2}',
      pageCommand: '跳轉到頁面: {0}',
      fragmentCommand: '顯示畫板: {0}',
      closeFragmentCommand: '關閉畫板: {0}',
      linkCommand: '開啟鏈結: {0}',
      eventType: '觸發',
      command: '命令',
      selectTarget: '目標',
      currentComp: '自己',
      currentFragment: '此畫板',
      switchPage: '頁面跳轉',
      closeArtboard: '關閉畫板',
      popUpArtboard: '顯示畫板',

      triggerconditions: {
        click: '點擊后',
        doubleClick: '雙點后',
        contextMenu: '右鍵后',
        mouseDown: '滑鼠按下后',
        mouseUp: '滑鼠松開后',
        mouseEnter: '滑鼠進入后',
        mouseLeave: '滑鼠離開后',
        loaded: '載入時',
        afterMove: '移動后',
        afterZoom: '縮放后',
        afterRotate: '旋轉后',
        afterVisible: '顯示后',
        afterHide: '隱藏后',
        afterResize: '調整尺寸后',
        checked: '選中后',
        unChecked: '取消選中后',
        toggleCheck: '選中改變后',
        focus: '獲取焦點后',
        blur: '失去焦點后',
        scroll: '卷軸時',
        on: '開啟',
        off: '關閉',
        toggle: '開啟/關閉',
      },
      // 交互描述
      pageIntro: '{0}，跳轉頁面{1}',
      fragmentIntro1: '{0}，關閉{1}',
      fragmentIntro2: '{0}，顯示{1}',
      compIntro: '{0}，對{1}執行{2}',
      compIntro2: '{0}，卷軸到{1}',
      openInCurrPage: '在當前視窗開啟',

      // 交互引數
      timing: '緩動',
      duration: '時長',
      delay: '延時',
      loop: '迴圈執行',
      transitions: '效果',
      fromCurrent: '相對值',
      content: '內容',
      width: '寬度',
      height: '高度',
      rotate: '角度',
      state: '狀態',
      show: '顯示',
      hidden: '隱藏',
      toggle: '切換',
      toggle2: '切換隱藏/顯示',
      horizontal: '水平',
      vertical: '垂直',
      both: '同時',
      mode: '位置',
      forceFront: '始終置頂',
      showBackground: '顯示遮罩',
      clickExternalClose: '點擊遮罩時關閉',
      artboardListTutorialUrl: 'https://help.mockplus.cn/p/358#4',
      siteName: '名稱',
      url: '網址',
      removeLink: '刪除',
      addLink: '添加',
      selectApp: '#选择协作项目',
      noApp: '#暂无协作项目',
      noPrototype: '#暫無檔案',
      howToUploadAxureFiles: '如何關聯Axure檔案？',
      axureLinkTutorialUrl: isPrivateDeployment
        ? `${window.location.origin}${window.apis.helpDomain}/p/379#5`
        : 'https://help.mockplus.cn/p/379#5',
      selectPrototype: '#選擇檔案',
      searchPrototype: '搜尋',
      noPage: '暫無頁面',
      selectPage: '選擇頁面',
      searchPage: '搜尋',
      web: '網頁',
      openWithSelf: '在當前視窗開啟',

      // 觸發方式
      click: '點擊',
      doubleClick: '雙點',
      contextMenu: '右鍵',
      mouseDown: '滑鼠按下',
      mouseUp: '滑鼠松開',
      mouseEnter: '滑鼠進入',
      mouseLeave: '滑鼠離開',
      loaded: '載入時',
      afterMove: '移動后',
      afterZoom: '縮放后',
      afterRotate: '旋轉后',
      afterVisible: '顯示后',
      afterHide: '隱藏后',
      afterResize: '調整尺寸后',
      checked: '選中',
      unChecked: '取消選中',
      toggleCheck: '選中改變',
      focus: '焦點',
      blur: '失去焦點',
      scroll: '卷軸',

      // 交互命令
      move: '移動',
      moveBy: '移動',
      moveTo: '移動到',
      resize: '調整尺寸',
      resizeBy: '尺寸',
      resizeTo: '尺寸至',
      rotation: '旋轉',
      rotationBy: '旋轉',
      rotationTo: '旋轉至',
      toggleVisible: '顯示/隱藏',
      zoom: '縮放',
      zoomBy: '縮放',
      zoomTo: '縮放至',
      toggleState: '切換狀態',
      switchContent: '切換內容',
      prev: '上一個',
      next: '下一個',

      // 動畫效果
      none: '無',
      pushToLeft: '右側推入',
      pushToRight: '左側推入',
      pushToTop: '底部推入',
      pushToBottom: '頂部推入',
      slideToLeft: '右側滑入',
      slideToRight: '左側滑入',
      slideToTop: '底部滑入',
      slideToBottom: '頂部滑入',
      fadeIn: '淡入',
      zoomIn: '放大彈出',
      slideFromLeft: '右側滑出',
      slideFromRight: '左側滑出',
      slideFromTop: '底部滑出',
      slideFromBottom: '頂部滑出',
      fadeOut: '淡出',
      zoomOut: '縮小退離',
      flipInLeft: '左側翻出',
      flipInRight: '右側翻出',
      flipInTop: '頂部翻出',
      flipInBottom: '底部翻出',

      // 輔畫板交互位置範式
      custom: '自訂',
      center: '居中',
      left: '左側',
      right: '右側',
      top: '頂部',
      bottom: '底部',

      // 時間曲線
      noTiming: '無',
      linear: '線性',
      easeIn: '加速',
      easeOut: '減速',
      easeInOut: '變速',
      tips: {
        move: '勾選時，相對于當前位置進行移動\n未勾選時，移動到指定位置',
        resize: '勾選時，相對于當前尺寸進行縮放\n未勾選時，調整到指定尺寸',
        scale: '勾選時，相對于當前比例進行縮放\n未勾選時，縮放到指定比例',
        rotate: '勾選時，相對于當前角度進行旋轉\n未勾選時，旋轉到指定角度',
      },
    },
  },
  // 元件狀態
  state: {
    state: '狀態',
    originalState: '正常',
    componentState: '預設狀態 - {0}',
    customState: '自訂狀態',
    hover: '懸停',
    pressed: '按下',
    checked: '選中',
    focus: '焦點',
    disabled: '禁用',
    default: '預設',
    addState: '添加狀態',
  },
  // 選單
  menu: {
    applyFill: '應用程式到填充',
    applyStroke: '應用程式到邊框',
    applyText: '應用程式到文字',
    align: '順序',
    bringToFront: '置為頂層',
    frontForward: '上移一層',
    backForward: '下移一層',
    sendToBack: '置為底層',
    sameSize: '調整大小',
    minWidth: '最小等寬',
    maxWidth: '最大等寬',
    minHeight: '最小等高',
    maxHeight: '最大等高',
    addColor: '把顏色存為資源',
    addTypography: '把字元樣式存為資源',
    addComponent: '把元件存為資源',
    unlock: '解除鎖定',
    lock: '鎖定',
    unlockAll: '全部解鎖',
    selectionGroup: '選項組',
    flip: '翻轉',
    horizontalFlip: '水平翻轉',
    verticalFlip: '垂直翻轉',
    autoFillText: '自動填充',
    editStyle: '進階編輯',
    path: '布爾運算',
    exclude: '排除',
    subtract: '減去',
    unite: '聯合',
    intersect: '相交',
    divide: '取消合成',
    convertToPath: '轉為路徑',
    detachSymbol: '從資源中脫離',
    cropImage: '裁切',
    crop: '裁剪',
    convertToPanel: '轉為面板',
    detachPanel: '從面板中脫離',
    revertOriginSize: '恢復原始尺寸',
    cleanInteraction: '清除交互',
    copyInteraction: '复制交互',
    pasteInteraction: '粘贴交互',
    copyStyle: '复制样式',
    pasteStyle: '粘贴样式',
    exportPicture: '导出图片',
    exportPDF: '导出PDF',
    exportSvg: '导出SVG',
    downloadOriginImage: '下载原图',
  },
  // 演示
  preview: {
    alwaysShowLinkArea: '始終顯示鏈結區域',
    showLinkAreaWhenHovered: '滑鼠經過時，顯示鏈結區域',
    autoScreen: '自動適應熒幕',
    noBoundary: '顯示畫板外內容',
    showControllerPanel: '顯示控制面板',
    mobileType: '裝置外殼',
    qrcode: '二維碼',
    controlPanel: '控制面板',
    home: '回到首頁',
    backward: '后退',
    forward: '前進',
    exit: '退離演示',
    shareUrl: '分享演示鏈結',

    shareRunTitile: '分享演示鏈結',
    sharePageTip: '請選擇分享的項目頁面',
    copy: '復制',
    enablePass: '啟用密碼',
    inputPass: '請設定密碼',
    setPassInputError: '請輸入字母、數字或者符號',
    setPassEmptyError: '需設定密碼后，存取密碼才會生效',
    updateSuccess: '設定成功',
    linkText: `演示鏈結：{0}`,
    pwdText: `存取密碼：{0}`,
    iosExitText: '從熒幕邊緣右滑退離演示', // 只有中文ios手機端演示退離才有
    projectNotExistOrDeleted: '專案不存在或已經被刪除。',
    noPermissionText: '無法檢視此專案',
    linkHasNotValidText: '此鏈結已失效',
    resetLinkSuccess: '重設成功',
    onlyMember: '僅專案成員才能檢視',
    loginTip: '請登入后繼續',
    xiaoMi: '#小米',
    iPhoneBlack: '#iPhone 8 Plus 黑色',
    iPhone: '#iPhone 8 Plus',
    iPhoneX: '#iPhone X/12/13',
    galaxy: '#Galaxy',
    huaweiP40: '#HUAWEI P40',
    huaweiMate: '#HUAWEI Mate',
    iPad: '#iPad',
    // iPadAir: '#iPad Air',
    surfacePro: '#Surface Pro',
    appleWatch38mm: 'Apple Watch 38mm',
    appleWatch40mm: 'Apple Watch 40mm',
    appleWatch42mm: 'Apple Watch 42mm',
    appleWatch44mm: 'Apple Watch 44mm',
    appleWatch45mm: 'Apple Watch 45mm',
    appleWatch49mm: 'Apple Watch 49mm',
    appleWatch: 'Apple Watch',
    appleWatchUltra: 'Apple Watch Ultra',
    watch: '手表',
    vehicle: '车载中控',
    setNodeIdsSuccess: '#设置成功',
    shareChangeTip:
      '演示界面地址栏中的链接现仅支持项目成员进行访问，老链接不受影响。如需向非项目成员分享，请使用底部工具栏中的分享演示链接功能。',
  },
  // 通用
  general: {
    multipleSelect: '多選',
    ignoreArchiveTip: '不再提示',
    gotIt: '我知道了',
    next: '#下一步',
    back: '回返',
    ok: '確定',
    confirm: '確認',
    cancel: '取消',
    close: '關閉',
    delete: '刪除',
    clone: '克隆',
    copy: '復制',
    cut: '剪下',
    paste: '貼上',
    selectAll: '全選',
    rename: '重命名',
    edit: '編輯',
    none: '無',
    width: '寬',
    height: '高',
    exit: '退離',
    preserve: '儲存',
    or: '或',
    refresh: '重新載入',
    download: '下載',
    enterFullScreen: '全屏', // idoc 已翻譯
    leaveFullScreen: '退離全屏', // idoc 已翻譯
  },
  // 警告資訊
  alert: {
    refresh: '重新載入',
    pageHasBeenDeleted: '抱歉，當前頁面已被其他成員刪除。',
    makeSureToDeletePage: '確定要刪除當前頁面嗎？',
    makeSureToDeletePageGroup: '確定要刪除當前分組嗎？',
    networkDisconnected: '抱歉，網路配接異常，請檢查網路并重新載入頁面。',
    afk: '由于長時間未作業，已經儲存你的資料并斷開配接。\n繼續工作請重新載入頁面。',

    fileTypeError: '檔案類別不支援。',
    errorSelectingFileType: '請選擇的檔案格式：\n{0}',
    failToUpload: '上載失敗，請重試。',
    alreadyExistPageAction: '此元件已經存在一個頁面交互，確定替換嗎？',
    alreadyExistFragmentAction: '此元件已經存在一個畫板的交互，確定替換嗎？',

    imageSizeExceed: '抱歉，僅支援 10M 以內的圖片。',
    imageDimensionExceed: '抱歉，僅支援 10000 x 10000像素以內的圖片。',
    videoSizeExceed: '抱歉，僅支援 10M 以內的視訊。',

    moreFileUploading: '正在匯入，已完成{0}/{1}，請稍候...',
    moreFileUploadFaiFile: '抱歉，{0}個檔案匯入失敗。\n請匯入10M以內的檔案。',
    moreFileUploadFailFormat: '抱歉，{0}個檔案匯入失敗。\n請匯入以下格式的檔案：jpg、png、svg、gif、mp3、mp4。',
    moreFileUploadFailSize: '抱歉，{0}個檔案匯入失敗。\n請匯入10,000 × 10,000以內的圖片。',
    moreFileUploadFailAll: '抱歉，{0}個檔案匯入失敗。\n請匯入10M以內且格式正確（jpg、png、svg、gif、mp3、mp4）的檔案。',
    uploadMaxCount: '單次最多匯入50個圖片或視訊。',
    uploadNetworkError: '網路異常，請檢查后重試。', // 這個內容在客戶端登入處有重復的
    offlineEditing: '離線編輯中...',
    offlineSaving: '本地儲存中...',
    saving: '儲存中...',
    saveSuccessfully: '儲存成功',
    failToSave: '儲存失敗',
    unableToCreateNode: '抱歉，網路已斷開，不能創建頁面或分組。',
    unableToMoveNode: '抱歉，網路已斷開，不能移動頁面或分組。',
    unableToRemoveNode: '抱歉，網路已斷開，不能刪除頁面或分組。',
    unableToEditNode: '抱歉，網路已斷開，不能修改頁面或分組。',
    unableToCopyNode: '抱歉，網路已斷開，請在網路恢復后創建頁面。',
    unableToUpload: '抱歉，網路已斷開，請在網路恢復后上載。',
    unableToHideNode: '抱歉，網路已斷開，不能隱藏頁面。',
    networkReconnectionSuccessful: '網路已重新配接',
    saveOfflineData: '同步離線資料中...',
    manualSaveOfflineData: '資料儲存中，請稍候...',
    failedToJoinProjectCollaboration: '加入專案協作失敗',
    multiplayerCollaborativeSuccess: '多人協同初始化成功',
    mayCauseCompsToBeDeleted: '當前作業可能導致元件被刪除，確定繼續嗎？',
    selectGroupTarget: '如果你需要選中組，可以這樣作業：',
    makeSureToMergeCells: '合并后的單元格將只保留左上角單元格中的值，確定繼續嗎？',
    trashClearAlert: '清除后不可恢復，確定要清除嗎？',
    trashPageAlert: '已刪除的頁面需恢復后才可編輯。',
    trashRecoverAlert: '確定將所有頁面放回原處嗎？此作業不支援恢復。',
    headCannotBeMoved: '表頭不能移動。',
    cannotMoveToHeader: '不能移動到表頭。',
    cannotMoveRow1: '抱歉，不可只移動合并單元格中的部分行，請拆分單元格后再試。',

    cannotMoveRow2: '抱歉，不可將行移動到合并單元格中，請拆分單元格后再試。',
    cannotMoveColumn1: '抱歉，不可只移動合并單元格中的部分列，請拆分單元格后再試。',

    duplicateName: '#重复命名',

    cannotMoveColumn2: '抱歉，不可將列移動到合并單元格中，請拆分單元格后再試。',
    offlineAlert: '網路異常，部分功能禁用，請檢查網路配接。',
    socketOfflineAlert: '網路異常，部分功能禁用，請嘗試使用手動儲存',
    networkOfflineAlert: '離線編輯中，部分功能禁用，請檢查網路配接。',

    offlineMsg3: '頁面尚未載入，請在網路恢復后重試',
    imageCropFail: '圖片裁切失敗，請重試',
    packageLoading: '檔案正在準備中，請耐心等待，可能需要幾分鐘。',
    successMessage: '離線演示包打包成功！',
    downloadPreviewDemoZIP: '未能成功下載離線演示包，請重試。',
    audioSizeExceed: '請上傳10M以內的mp3文件。',
  },
  // 提示資訊
  tips: {
    clickCompnentToSwitchState: '點擊元件以切換狀態',
    interactionPrompt1: '選中元件或畫板，點擊 “添加交互”',
    interactionPrompt2: '或拖拽元件右上角的鏈結點，至任意元件或頁面',
    interactionPrompt2DT: '或拖拽元件右上角的小紅點，至任意畫板或頁面',
    contentPanelTips:
      '小提示：\n拖拽鏈結點到一個輔助畫板，將其添加為層；\n可添加多個層；\n同一個輔助畫板只能被添加一次。',
    doubleClickComponent: '雙點進入編輯狀態',
    doubleClickUploadVideo: '雙點上載視訊',
    inputSiteName: '名稱',
    inputUrl: '網址',
    inputMaxLength: '名稱不能超過{0}個字元',
    revertLinear: '反轉',
    revertRadial: '反轉',
    rotationAngle: '調整角度',
    pleaseInput: '請輸入{0}',
    copySuccess: '復制成功',
    clickSelectTarget: '點擊選擇目標',
    selectTarget: '選擇觸發方式',
    selectCommand: '選擇命令',
    selectContent: '選擇內容',
    selectState: '選擇狀態',
    revert: '還原',
    changeProjectTypePrompt1: '專案類別將修改為 {0}',
    changeProjectTypePrompt2: '新建畫板會應用程式新的專案尺寸，現有畫板的尺寸不受影響，確定繼續嗎？',
    zoomOutline: '縮放',
    resizeTo: '調整尺寸至',
    scaleFromTopLeft: '左上擴展',
    scaleFromTopRight: '右上擴展',
    scaleFromBottomRight: '右下擴展',
    scaleFromBottomLeft: '左下擴展',
    scaleFromTop: '頂部擴展',
    scaleFromRight: '右側擴展',
    scaleFromBottom: '底部擴展',
    scaleFromLeft: '左側擴展',
    scaleFromCenter: '中心擴展',
    appSizeChangedByOther: '其他成員修改了專案類別，已同步相關設定。',

    artboardPanelTip: '拖拽交互點至畫板名稱以添加交互',
    noSearchResultsWereFound: '未搜尋到結果',
    pageAddOver: '當前專案的頁面數量已達{0}個，更新團隊可創建更多頁面。',
    pageRecoverOver: '當前專案的頁面數量已達{0}個，請更新團隊后再恢復頁面。',
    shareSetting: '修改預設演示設定后，演示鏈結會更新，請重新復制并分享',

    notSearchResult: '未搜尋到結果',
    noPage: '暫無頁面',
    resourceExpired: '相關資源已被刪除，確定將當前元件從資源中脫離以繼續編輯嗎?',

    tutorial: '檢視教程',
    officialWebsite: '存取官網',
    activity: {
      freePrograms: '用摹客RP，永久免費做設計！',
      partInFreePrograms: '?? 點擊參與免費計劃',
    },
    design: '協作和交付',
    nonPrivateProject: '此專案為團隊所有人可見\n請修改為私有專案后，再設定專案成員',

    cannotUseOfflineDome: '', // 中文版不處理
    doubleClickUploadAudio: '雙點上載音頻',
  },
  placeholders: {
    renameHolder: '頁面名稱',
    textInputHolder: '請輸入文字內容',
  },
  lark: {
    larkAppID: 'cli_9d2d59be33e0910c',
    previewPrompt: '邀請你檢視摹客專案演示，點擊鏈結即可進入演示。',
    openInLark: '在飛書開啟：',
    openInBrowser: '在瀏覽器開啟：',
  },
  extend: {
    chrome: {
      noExtend: {
        colorExtractor: '吸管工具僅支援在Chrome瀏覽器上使用，需安裝外掛程式。',
      },
      tutorialURL: isPrivateDeployment
        ? `${window.location.origin}${window.apis.helpDomain}/p/370#6`
        : 'https://help.mockplus.cn/p/370#6',
      downloadUrl: 'https://img02.mockplus.cn/idoc/plugins/mockplus_for_chrome_v1.0.3.zip',
    },
    tutorial: '檢視教程',
    download: '下載外掛程式',
    version: '外掛程式版本過低，請下載安裝最新版本。',
  },
  login: {
    title: '密碼登入',
    accountPlaceHolder: '信箱或摹客賬號',
    passwordPlaceHolder: '密碼',
    noAccount: '還沒有賬號？',
    registerNow: '立即注冊',
    forgetPassword: '忘記密碼',
    loginBtn: '登入',
    emailValidate: '請輸入格式正確的信箱。',
    passwordValidate: '密碼長度為6-20。',
  },
  register: {
    title: '注冊賬戶',
    passwordValidateAgin: '兩次密碼輸入不相同。',
    agreementURL: 'https://www.mockplus.cn/tos',
    accountPlaceHolder: '請輸入注冊信箱',
    passwordPlaceHolder: '請輸入登入密碼',
    passwordAginPlaceHolder: '請再次輸入密碼',
    hadRead: '我已仔細閱讀并同意',
    agreement: '《摹客使用協定》',
    register: '注冊',
    hadAccount: '已有賬號？',
    loginNow: '立即登入',
    readValidate: '請閱讀并同意協定。',
  },
  example: {
    label: '範本例子',
    tip: {
      partOne: '你正在體驗摹客在線設計，',
      partTwo: '或',
      partThree: '儲存本專案至當前團隊。',
      partFour: '點擊儲存',
      partFive: '本專案至當前團隊。',
    },
    saving: '專案儲存中...',
    savingError: '儲存失敗，請重新嘗試',
    type: {
      all: '全部',
    },
    nocontent: '暫無內容',
  },
  selectionGroup: {
    selectionGroupSetting: '選項組設定',
    rowCount: '行數',
    columnCount: '列數',
    rowGap: '行間距',
    columnGap: '列間距',
    iconEditButton: '點擊切換到圖示庫以選擇圖示',
    imageEditButton: '點擊上載圖片',
  },
  // 訂製功能
  customization: {
    huawei: {
      standard: {
        tips: '設計規范',
        label: '體驗規范',
        management: '管理側規范',
        officialWebsite: '官網規范',
        mobile: '移動端規范',
        operationFlow: '業務流程規范',
      },
      libraries: {
        icon: '圖示資源',
        image: '圖片資源',
      },
      guide: '使用指南',
      tutorial: '指導教程',
    },
  },
  share: {
    label: '分享',
    shareApp: '分享此專案',
    privateShare: '私密分享',
    publicShare: '公開分享',
    resetLink: '重設鏈結',
    availableResetLink: '如需重置链接，请先取消密码',
    privateShareTips: '適用于多人編輯、檢討和交付，僅專案成員可開啟鏈結。',
    publicShareTips: '僅用于演示和預覽設計，所有人都可開啟鏈結。',
    publicShareTipsInPrivateVersion: '僅用于演示和預覽設計，登入后即可開啟鏈結。',

    copyLink: '復制鏈結',
    inviteTips: '邀請有效期為7天，過期后專案成員仍可以開啟鏈結',
    inviteToApp: '同時邀請對方成為專案成員',
    notCanInviteToApp: '如果對方不是此專案成員，不能檢視專案',
    onlyShareCurrentModel: '僅分享當前版塊',
    iframeShare: '嵌入分享',
    iframeShareTips: '用于嵌入第三方應用程式，所有人都可開啟鏈結。',
    shareModule: {
      label: '模組',
      prototypeModule: '原型稿',
      designModule: '設計稿',
      rpModule: 'RP設計稿',
    },
    shareContent: {
      label: '內容',
      idocContent: '畫板介面',
      previewContent: '演示介面',
    },
  },
  message: {
    label: '訊息和申請',
    teamMessage: '專案訊息',
    aboutMe: '與我相關',
    apply: '申請訊息',
    markRead: '標記已讀',
    oneClickRead: '一鍵已讀',
    linkToAll: '檢視全部',
    hadRead: '已讀',
    applyToViewApp: '申請檢視專案“',
    applyToViewAppRight: '”',
    agree: '同意',
    disagree: '拒絕',
    agreed: '已同意',
    disagreed: '已拒絕',
    remark: '備注：',
    noContent: '暫時沒有新訊息',
  },
  // 沒有引文版
  contact: {
    leftTop: '你好，我是',
    name: 'Thomas',
    addMe: '立即掃碼加我吧！',
    role: '摹客百科全書級\n產品顧問',
    bottom: '或郵件至: service@jongde.com',
  },
  crashError: {
    title: '抱歉，出現異常問題',
    label: '請嘗試重新載入，如果問題反復出現，請',
  },
  file: {
    importMrpLabel: '匯入mrp檔案',
    importMrpTitle: '正在匯入mrp檔案，請稍候…',
    importError: '抱歉，匯入失敗。如需幫助，請',
    importErrorEnd: '。',
    importSuccess: '匯入成功',
    importMrpFileError: '無效的檔案類別，請選擇mrp檔案進行匯入。',
    importNetworkError: '網路異常，匯入失敗，請檢查網路后重試。',
    exportMrpLabel: '匯出mrp檔案',
    exportMrpTitle: '檔案正在準備中，請耐心等待，可能需要幾分鐘。',
    exportError: '抱歉，匯出失敗，請重試。',
    exportMrpSuccess: 'mrp檔案打包成功，請點擊下載。',

    upgradeToExportMrp: '',
  },
  support: {
    label: '聯系我們',
    url: 'https://www.mockplus.cn/support',
  },
  manualSave: {
    save: '儲存',
    saving: '儲存中...',
    saveSuccessfully: '儲存成功',
    savingError: '儲存失敗，請重試',
    offlineEditing: '離線中...',
  },
  newFeature: {
    title0: '#报告！摹客RP功能界面调整',
    content0: '#1、提供独立界面以创建及管理摹客RP项目，并可快速切换至协作平台。',
    content1: '#2、如需进行项目评审、查看图层数据、交付原型等操作，请在编辑界 面将项目发布至协作平台。',
  },
};

export default zh_tw;
