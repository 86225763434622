import * as React from 'react';
import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import { IconValue } from '@/fbs/rp/models/value';
import { IComponentSize } from '@/fbs/rp/models/component';
import { DefaultIconColor } from '@/consts/colors';
import { StyleHelper } from '@/helpers/styleHelper';
import { parseColorToString } from '@/utils/graphicsUtils';
import { measureTextSize, transBlankChart } from '@/utils/textUtils';
import { UIHorizontalMenuComponent, UITreeItemComponent } from '@/editor/comps';
import { ITreeItem } from '@/helpers/treeCompHelper';
import { markerStripSize, textAndIconSpace } from '@/consts/defaultData/menu';

export interface IHorizontalMenuItemProps {
  _id: string;
  item: ITreeItem;
  comp: UITreeItemComponent;
  horizontalComp: UIHorizontalMenuComponent;
  initTree: ITreeItem[];
  horizontalMenuHoverItemID?: string;
  horizontalContentRects?: DOMRectList;
  padding: { left: number; right: number; top: number; bottom: number };
  lineHeight: number;
  selectedIDs?: string[];
  level: number;
  index: number;
  parentIndex: number;
  itemIndex: number;
  maxChildLength?: number;
  size: IComponentSize;
  intention: number;
  checkedFill: React.CSSProperties;
  checkedMarkerStrip: React.CSSProperties;
  checkedTextStyle: React.CSSProperties;
  checkedIconColor: string;
  paddingStyle: React.CSSProperties;
  showExpandIcon: boolean;
  showNodeIcon: boolean;
  isPreview: boolean;
  floatPanelRects?: Record<string, number>;
  defaultScale?: number;
  children?: any;
  showInteractionBackGround?: boolean;
  showMobileCursor?: boolean;
}

const renderExtendIcon = (props: IHorizontalMenuItemProps) => {
  const { showExpandIcon, item, comp, checkedTextStyle, selectedIDs } = props;

  if (!showExpandIcon) {
    return null;
  }
  const expandComp = comp.expandComp;
  if (!expandComp) {
    return null;
  }
  if (!item.children?.length) {
    return null;
  }

  const { size, value, properties } = expandComp;
  const icon = value as IconValue;
  const fontSize = Math.min(size.width, size.height);
  let color: string | undefined = parseColorToString(properties.icon?.color || DefaultIconColor);
  // if (itemMainHover) {
  //   color = checkedTextStyle.color;
  // }
  if (selectedIDs && selectedIDs.includes(comp.id)) {
    color = checkedTextStyle.color;
  }
  return (
    <div
      className="comp-horizontal-menu-expand"
      style={{
        width: size.width,
        minWidth: size.width,
        height: size.height,
        marginLeft: textAndIconSpace,
        fontFamily: icon.fontName,
        fontSize,
        color,
      }}
    >
      {String.fromCharCode(icon.iconCode)}
    </div>
  );
};

const renderNodeIcon = (props: IHorizontalMenuItemProps) => {
  const { comp, showNodeIcon, level, selectedIDs, checkedIconColor } = props;
  if (!showNodeIcon) {
    return;
  }

  if (level) {
    return;
  }

  const nodeComp = comp.nodeComp;
  if (!nodeComp) {
    return null;
  }
  const { size, value, properties } = nodeComp;
  const icon = value as IconValue;
  const fontSize = Math.min(size.width, size.height);
  let color: string | undefined = parseColorToString(properties.icon?.color || DefaultIconColor);
  // if (itemMainHover) {
  //   color = checkedTextStyle.color;
  // }
  if (selectedIDs && selectedIDs.includes(comp.id)) {
    color = checkedIconColor;
  }
  return (
    <div
      className="comp-horizontal-menu-node"
      style={{
        width: size.width,
        height: size.height,
        fontSize,
        fontFamily: icon.fontName,
        lineHeight: `${fontSize}px`,
        color,
      }}
    >
      {String.fromCharCode(icon.iconCode)}
    </div>
  );
};

const renderText = (props: IHorizontalMenuItemProps) => {
  const { comp, checkedTextStyle, selectedIDs, item } = props;
  const { textComp } = comp;
  const { size: textSize, position, properties: oldProperties, value } = textComp;
  const properties = cloneDeep(oldProperties);
  const parser = StyleHelper.createCSSStyleParser({});
  let { style } = parser.getTextStyleData(textSize, properties.textStyle);
  if (selectedIDs && selectedIDs.includes(comp.id)) {
    style = { ...checkedTextStyle, lineHeight: style.lineHeight };
  }
  // if (itemMainHover) {
  //   style.color = checkedTextStyle.color;
  // }
  const textStyle: React.CSSProperties = {
    ...style,
    top: position.y,
  };
  const textWidth = measureTextSize(textStyle, transBlankChart(`${value}`)).width / 2;

  const textBoxStyle = {
    // 文本初始值在配置时被固定，无法直接使用textSize
    width: textWidth,
  };
  const markerStripWidth = textWidth + 20;

  return (
    <>
      <div className="comp-horizontal-menu-box">
        {renderNodeIcon(props)}
        <div className="comp-horizontal-menu-textBox" style={textBoxStyle}>
          <div className="comp-horizontal-menu-text" style={textStyle}>
            {transBlankChart(`${value}`)}
          </div>
        </div>
        {item.parent && renderExtendIcon(props)}
      </div>
      {renderCheckedMarkerStrip(props, markerStripWidth)}
    </>
  );
};

const renderCheckedMarkerStrip = (props: IHorizontalMenuItemProps, markerStripWidth: number) => {
  const { comp, level, checkedMarkerStrip, selectedIDs } = props;
  if (level !== 0 || !selectedIDs?.includes(comp.id)) {
    return;
  }
  // const markerStripWidth = 'calc(100% - 20px)'
  return (
    <span
      className="item-selected-markerStrip"
      style={{
        ...checkedMarkerStrip,
        width: markerStripWidth,
        height: markerStripSize,
      }}
    ></span>
  );
};

// const renderChildItem = (props:IHorizontalMenuItemProps) => {
//     return <MenuChildItem {...props} />;
//   };

const renderMain = (props: IHorizontalMenuItemProps) => {
  const { lineHeight, initTree, size, level } = props;
  let style: React.CSSProperties = {
    width: size.width / initTree.length,
    lineHeight: level ? lineHeight : size.height,
    height: level ? lineHeight : size.height,
  };
  if (level) {
    style.width = '100%';
  }
  return (
    <div
      className={classnames('item-main', {
        //   'item-interaction-flag': !isPreview && !item.children?.length && hasInteraction(comp),
      })}
      style={style}
    >
      {/* {renderSelectBack(props)}
        {renaderInteractionBack} */}
      <div className="item-main-inner">{renderText(props)}</div>
      {/* {(horizontalMenuHoverItemID === _id || itemMainHover) && props.children && renderChildItem()} */}
    </div>
  );
};

export const renderHorizontalMenuItem = (props: IHorizontalMenuItemProps) => {
  const { isPreview, _id } = props;
  return (
    <div
      key={'HorizontalMenuItem' + _id}
      className={classnames('lib-comp-horizontal-menu-item', { 'component-cursor-pointer': isPreview })}
    >
      {renderMain(props)}
    </div>
  );
};
