import * as _ from 'lodash';

import { FragmentPositionMode, IFragmentAction, PageSkipEffectType } from '@fbs/rp/models/interactions';
import { EventTypes } from '@fbs/rp/models/event';

import { CommandFinishEventHandle, FragmentOverlayHandle, IFragmentCommand, IWorker, IWorkerManager } from '../types';

export default class FragmentCommand implements IFragmentCommand {
  onFinish?: CommandFinishEventHandle;
  onBegin?: FragmentOverlayHandle;
  _worker: IWorker;
  action: IFragmentAction;
  workManager?: IWorkerManager;
  readonly afterEvent = EventTypes.loaded;

  private delayTime?: Timeout;

  constructor(worker: IWorker, action: IFragmentAction) {
    this._worker = worker;
    this.action = action;
  }

  termination() {
    this.delayTime && window.clearTimeout(this.delayTime);
  }

  get afterTrigger() {
    const { target } = this.action;
    return this._worker.doc.artboardsFragments.find((item) => item.artboardID === target) || null;
  }

  protected get canRevert() {
    return !this.action.params.disabledAutoRevert;
  }

  protected updateTarget() {
    const { target, isExit, params } = this.action;
    const { mode, position } = params;

    const targetFragment = this._worker.doc.artboardsFragments.find((item) => item.artboardID === target);
    if (!targetFragment) {
      return;
    }
    if (isExit) {
      return;
    }
    const size = this._worker.doc.mainArtboard.size;
    const center = (size.width - targetFragment.size.width) / 2;
    const middle = (size.height - targetFragment.size.height) / 2;
    let pt: { x: number; y: number };
    switch (mode) {
      case FragmentPositionMode.Top:
        pt = { x: center, y: 0 };
        break;
      case FragmentPositionMode.Bottom:
        pt = { x: center, y: size.height - targetFragment.size.height };
        break;
      case FragmentPositionMode.Left:
        pt = { x: 0, y: middle };
        break;
      case FragmentPositionMode.Right:
        pt = { x: size.width - targetFragment.size.width, y: middle };
        break;
      case FragmentPositionMode.Center:
        pt = { x: center, y: middle };
        break;
      default:
        pt = position || { x: center, y: middle };
        break;
    }
    targetFragment.tempPosition = { ...pt };
  }

  private doExecute = (revert: boolean) => {
    const action = _.cloneDeep(this.action);
    if (action.type !== 'fragment') {
      // 如果交互目录为辅助画板，不执行该逻辑
      if (revert && this.canRevert) {
        action.isExit = !action.isExit;
      }
      if (action.isExit && !revert) {
        action.params.effect = 'auto' as PageSkipEffectType;
      }
    }
    if (this.onBegin) {
      this.onBegin(action);
    }
    const {
      animation: { duration },
    } = action;
    if (this.onFinish) {
      duration ? window.setTimeout(this.doFinish) : this.doFinish();
    }
  };

  private doFinish = () => {
    this.onFinish && this.onFinish(this);
    this.updateTarget();
  };

  execute = (revert?: boolean) => {
    const { animation } = this.action;

    animation.delay
      ? (this.delayTime = window.setTimeout(this.doExecute.bind(this, !!revert), animation.delay))
      : this.doExecute(!!revert);
  };

  // startSubWorker = () => {
  // 辅画板的loaded交互在<page>触发
  // const fragment = this.afterTrigger;
  // if (this.action.isExit || !fragment || !this.workManager?.startWorker) {
  //   return;
  // }
  // this.workManager?.startWorker(fragment, this.afterEvent);
  // };
}
