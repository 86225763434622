import { measureTextSize, transBlankChart } from '@utils/textUtils';

import i18n from '@i18n';
import {
  UIComponent,
  UIMultipleSelectPanelComponent,
  UIContainerComponent,
  UIListLayoutSealedComponent,
} from '@editor/comps';

import * as SystemsColor from '@consts/colors';
import { SpriteThumb } from '@consts/spriteIcons';
import { DefaultPadding } from '@/consts/padding';
import { ICON_PADDING_RIGHT, MAX_PADDING_VALUE } from '@/consts/defaultData/multipleSelect';
import { PredefinedStates } from '@consts/state';
import { getNewID } from '@helpers/idHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { mergePatches } from '@/helpers/patchHelper';
import { getChangedValue } from '@/helpers/componentHelper';
import { HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import { default as ITextFormat, TextAlign, TextPropertyName } from '@fbs/rp/models/properties/text';
import { StrokeLineCap, StrokeLineJoin, StrokePosition } from '@fbs/rp/models/properties/stroke';
import { ArtboardPatches, Ops, ReplaceOperation, Operation } from '@fbs/rp/utils/patch';
import { SelectListPropertyName } from '@/fbs/rp/models/properties/selectList';
import { FillType } from '@fbs/rp/models/properties/fill';
import { IComponentData } from '@fbs/rp/models/component';
import { PropertyValue } from '@fbs/rp/models/property';
import IPadding, { PaddingPropertyName } from '@fbs/rp/models/properties/padding';

import { makeCommonComponent } from '../../helper';
import { IComponentItem } from '../../types';
import { CListLayoutPanel, CCanvasPanel, CPureText, CIcon, CMultipleSelect } from '../../constants';

const DROP_DOWN_PADDING = 10; // 下拉框每项的内边距
const DEFAULT_TEXT_STYLE = {
  // 默认字体样式
  textAlign: TextAlign.left,
  fontSize: 14,
  fontFamily: 'Microsoft YaHei',
  fontStyle: { underline: false, bold: false, strike: false, italic: false },
};

/**
 * 演示时的状态触发
 * @param {UIComponent} container
 * @param {UIComponent} triggerComp
 * @param {string} stateName
 * @return {any}
 */
function onTriggerState(
  container: UIComponent,
  triggerComp: UIComponent,
  stateName: string,
): { [x: string]: ReplaceOperation<string>[] } {
  const comp = container as UIMultipleSelectPanelComponent;
  const latestValue = comp.calcLatestValue(triggerComp, stateName === PredefinedStates.checked);
  return {
    [container.id]: [Ops.replace('./value', latestValue)],
  };
}

/**
 * 当下拉部分选项改变时，更新主体的文本显示内容
 * @param {UIComponent} group
 * @param {UIComponent} comp
 * @param {boolean} selected
 * @return {ArtboardPatches}
 */
function onChildSelectedChange(container: UIComponent, comp: UIComponent, selected: boolean): ArtboardPatches {
  const group = container as UIMultipleSelectPanelComponent;
  return group.getUpdateItemCheckedPatches(comp, selected);
}

// 克隆组件，替换value的id
function onClone(compData: IComponentData, idMap: Record<string, string>) {
  const oldValue = compData.value;
  const valueIds = oldValue
    .split(',')
    .map((id: string) => idMap[id])
    .join(',');
  compData.value = valueIds;
}

/**
 * 下拉列表属性更改
 * @param container
 * @param propertyValue
 */
function onSelectListUpdate(container: UIContainerComponent, propertyValue: PropertyValue) {
  const diffProperty = getChangedValue(propertyValue, container.properties.selectList);
  if (diffProperty) {
    if ('lineHeight' in diffProperty) {
      const listComp = container.getComponentByAlias('list') as UIListLayoutSealedComponent;
      const cell = listComp.properties.cell;
      return listComp.getPatchesWhenSetCellProperty({ ...cell, rowHeight: diffProperty.lineHeight });
    } else {
      const comp = container.getComponentByAlias('drop-down')!;
      const doPatches: Operation[] = [];
      const undoPatches: Operation[] = [];
      if ('bgColor' in diffProperty) {
        const path = '/properties/fill/color';
        doPatches.push(Ops.replace(path, diffProperty.bgColor));
        undoPatches.push(Ops.replace(path, comp.properties.fill!.color));
      }
      const patches = { do: { [comp.id]: doPatches }, undo: { [comp.id]: undoPatches } };
      return patches;
    }
  }

  return null;
}

/**
 * 选中背景变化
 * @param container
 * @param propertyValue
 */
function onSelectedBgColorUpdate(container: UIMultipleSelectPanelComponent, propertyValue: PropertyValue) {
  const listComp = container.listComp;
  const listComps = listComp.components as UIContainerComponent[];

  const patches: ArtboardPatches = { do: {}, undo: {} };
  // 文本背景
  listComps.forEach((comp) => {
    const textComp = comp.components[0]; // 文本组件
    if (textComp) {
      const path = '/properties/fill';
      const oldValue = textComp.properties.fill;
      patches.do[textComp.id] = [Ops.replace(path, { ...oldValue, color: propertyValue.value })];
      patches.undo[textComp.id] = [Ops.replace(path, oldValue)];
    }
  });

  // 下拉列表选中背景
  const dropComp = container.getComponentByAlias('drop-down')!;
  const dropPath = '/properties/itemSelectFill';
  const oldDropValue = dropComp.properties.itemSelectFill;
  patches.do[dropComp.id] = [Ops.replace(dropPath, { ...oldDropValue, color: propertyValue.value })];
  patches.undo[dropComp.id] = [Ops.replace(dropPath, oldDropValue)];

  // 清除图标背景
  const mainComp = container.mainComp;
  const iconComp = mainComp.components[0];
  const iconPath = '/properties/fill';
  const oldIconValue = dropComp.properties.fill;
  patches.do[iconComp.id] = [Ops.replace(iconPath, { ...oldIconValue, color: propertyValue.value })];
  patches.undo[iconComp.id] = [Ops.replace(iconPath, oldIconValue)];

  return patches;
}

/**
 * 图标颜色变化
 * @param container
 * @param propertyValue
 */
function onIconBgColorUpdate(container: UIMultipleSelectPanelComponent, propertyValue: PropertyValue) {
  const listComp = container.listComp;
  const listComps = listComp.components as UIContainerComponent[];

  const path = '/properties/textStyle';
  const patches: ArtboardPatches = { do: {}, undo: {} };
  listComps.forEach((comp) => {
    const clearIconComp = comp.components[1];
    const selectedIconComp = comp.components[2];
    // 文本删除图标颜色
    if (clearIconComp) {
      const oldValue = clearIconComp.properties.textStyle;
      patches.do[clearIconComp.id] = [Ops.replace(path, { ...oldValue, color: propertyValue.value })];
      patches.undo[clearIconComp.id] = [Ops.replace(path, oldValue)];
    }
    // 下拉框选中图标颜色
    if (selectedIconComp) {
      const oldValue = selectedIconComp.properties.textStyle;
      patches.do[selectedIconComp.id] = [Ops.replace(path, { ...oldValue, color: propertyValue.value })];
      patches.undo[selectedIconComp.id] = [Ops.replace(path, oldValue)];
    }
  });

  // 清除图标背景
  const mainComp = container.mainComp;
  const iconComp = mainComp.components[0];
  const oldIconValue = iconComp.properties.textStyle;
  patches.do[iconComp.id] = [Ops.replace(path, { ...oldIconValue, color: propertyValue.value })];
  patches.undo[iconComp.id] = [Ops.replace(path, oldIconValue)];

  return patches;
}

/**
 * 当下拉属性修改时
 * @param {UIComponent} container
 * @param {string} propertyName
 * @param {PropertyValue} propertyValue
 * @return {ArtboardPatches | null}
 */
function onPropertyUpdate(
  container: UIComponent,
  propertyName: string,
  propertyValue: PropertyValue,
): ArtboardPatches | null {
  const select = container as UIMultipleSelectPanelComponent;

  // 下拉框属性
  if (propertyName === 'selectList') {
    return onSelectListUpdate(select, propertyValue);
  }

  // 选中背景
  if (propertyName === 'selectedBgColor') {
    return onSelectedBgColorUpdate(select, propertyValue);
  }

  if (propertyName === 'iconBgColor') {
    return onIconBgColorUpdate(select, propertyValue);
  }

  const listComp = select.listComp;
  if (!listComp) {
    return null;
  }
  if (propertyName === TextPropertyName) {
    const {
      id,
      properties: { textStyle },
    } = listComp.parent!;
    const {
      components,
      properties: { cell },
      id: dropDownID,
    } = listComp;
    const textStylePath = '/properties/textStyle';
    const selectedTextStylePath = '/properties/itemSelectTextStyle';
    const cellPath = '/properties/cell';
    const listPath = '/properties/selectList';

    const oldSelectList = select.properties.selectList;
    const { fontFamily, fontSize, letterSpace, color } = propertyValue as ITextFormat;
    const newPropertyValue = { ...textStyle, fontFamily, fontSize, letterSpace };
    const style = StyleHelper.initCSSStyleParser({ textStyle: newPropertyValue }).getTextStyle();
    const { height } = measureTextSize(style, 'j', {});
    const newCell = { ...cell, rowHeight: height };
    const itemPatches = components.reduce(
      (acc, comp) => {
        const { size } = comp;
        acc.do[comp.id] = [Ops.replace('/size/height', height + DROP_DOWN_PADDING)];
        acc.undo[comp.id] = [Ops.replace('/size/height', size.height)];
        return acc;
      },
      { do: {}, undo: {} } as ArtboardPatches,
    );
    const opt: ArtboardPatches = {
      do: {
        [id]: [
          Ops.replace(textStylePath, { ...textStyle, fontFamily, fontSize }),
          Ops.replace(selectedTextStylePath, { ...textStyle, fontFamily, fontSize, color }),
        ],
        [dropDownID]: [Ops.replace(cellPath, newCell)],
        [select.id]: [Ops.replace(listPath, { ...oldSelectList, selectedFontColor: color })],
        ...itemPatches.do,
      },
      undo: {
        [id]: [Ops.replace(textStylePath, textStyle), Ops.replace(selectedTextStylePath, textStyle)],
        [dropDownID]: [Ops.replace(cellPath, cell)],
        [select.id]: [Ops.replace(listPath, oldSelectList)],
        ...itemPatches.undo,
      },
    };
    const sizePatches = select.getUpdateTextStylePatches(style);
    if (sizePatches) {
      mergePatches(opt, sizePatches);
    }
    return opt;
  }
  if (propertyName === PaddingPropertyName) {
    return select.getUpdatePaddingPatches(propertyValue as IPadding);
  }
  return null;
}

/**
 * 处理右侧属性面板内边距最大值的处理
 * @param group
 * @param padding
 * @param fontSize
 */
function onCalcMaxPadding(group: UIContainerComponent, padding: IPadding, fontSize: number) {
  const { left, right } = padding;
  const width = group.size.width - ICON_PADDING_RIGHT;
  const maxValue = {
    top: MAX_PADDING_VALUE,
    right: width - (left || 0) - fontSize,
    bottom: MAX_PADDING_VALUE,
    left: width - (right || 0) - fontSize,
  };
  const defaultMaxValue = Math.min(width / 2, MAX_PADDING_VALUE / 2);
  return { maxValue, defaultMaxValue };
}

/**
 * 创建一条下拉项数据
 * @param {string} value
 * @param {number} y
 * @param {boolean} selected
 * @return {IComponentData}
 */
function makeDropDownItem(value: string, y: number, selected: boolean = false) {
  return makeCommonComponent(getNewID(), CCanvasPanel, {
    name: 'listItem',
    size: { width: 190, height: 30 },
    position: { x: 1, y },
    layout: {
      auto: true,
      responsive: true,
      fixedWidth: false,
      fixedHeight: true,
      vertical: VerticalAlign.Top,
      horizontal: HorizontalAlign.LeftAndRight,
    },
    selected,
    properties: {
      border: { disabled: true, hidden: true },
      fill: { ref: '@properties.fill' },
      textStyle: { ref: '@properties.textStyle' },
    },
    states: {
      [PredefinedStates.hover]: {
        enabled: true,
        properties: {
          fill: { ref: '@properties.itemHoverFill' },
        },
      },
      [PredefinedStates.checked]: {
        enabled: true,
        properties: {
          fill: { ref: '@properties.itemSelectFill' },
        },
      },
    },
    components: [
      // 每项的值
      makeCommonComponent(getNewID(), CPureText, {
        position: { x: 8, y: 5 },
        size: { height: 20, width: 172 },
        autoSize: false,
        layout: {
          auto: false,
          responsive: true,
          fixedWidth: false,
          fixedHeight: true,
          vertical: VerticalAlign.Middle,
          horizontal: HorizontalAlign.LeftAndRight,
        },
        properties: {
          textStyle: { ref: '@properties.textStyle' },
          fill: {
            type: FillType.solid,
            color: SystemsColor.DefaultDarkFillColor,
            disabled: false,
          },
        },
        value,
      }),
      // 删除每一项图标
      makeCommonComponent(getNewID(), CIcon, {
        value: 'icon_Close',
        properties: {
          textStyle: {
            fontSize: 16,
            color: SystemsColor.DefaultIconColor,
          },
        },
      }),
      // 选中图标
      makeCommonComponent(getNewID(), CIcon, {
        value: 'icon_check',
        properties: {
          textStyle: {
            color: SystemsColor.DefaultIconColor,
          },
        },
      }),
    ],
  });
}

/**
 * 创建下拉部分数据
 * @return {IComponentData}
 */
function makeDropDown() {
  return makeCommonComponent(getNewID(), CCanvasPanel, {
    alias: 'drop-down',
    name: 'dropDown',
    hidden: true,
    sealed: true,
    position: {
      x: 0,
      y: 31,
    },
    size: {
      width: 192,
      height: 136,
    },
    layout: {
      auto: false,
      responsive: true,
      fixedWidth: false,
      fixedHeight: true,
      vertical: VerticalAlign.Bottom,
      horizontal: HorizontalAlign.LeftAndRight,
    },
    properties: {
      shadow: {
        x: 0,
        y: 3,
        blur: 6,
        color: SystemsColor.ShadowColor,
        disabled: false,
      },
      fill: {
        type: FillType.solid,
        color: SystemsColor.WhiteColor,
        disabled: false,
      },
      stroke: {
        thickness: 1,
        color: SystemsColor.LightGrayColor,
        disabled: false,
      },
      itemSelectFill: {
        prop: 'fill',
        name: i18n('property.propertyNames.checkedBgcolor'),
        type: FillType.solid,
        color: SystemsColor.DefaultDarkFillColor,
        disabled: false,
      },
      textStyle: {
        color: SystemsColor.DefaultTextColor,
        ...DEFAULT_TEXT_STYLE,
      },
      itemSelectTextStyle: {
        prop: 'textStyle',
        name: i18n('property.propertyNames.checkedText'),
        color: SystemsColor.DefaultTextColor,
        ...DEFAULT_TEXT_STYLE,
      },
    },
    components: [
      makeCommonComponent(getNewID(), CListLayoutPanel, {
        alias: 'list',
        name: 'list',
        position: { x: 1, y: 10 },
        size: {
          width: 190,
          height: 122,
        },
        layout: {
          auto: true,
          responsive: true,
          fixedWidth: false,
          fixedHeight: false,
          vertical: VerticalAlign.Top,
          horizontal: HorizontalAlign.LeftAndRight,
        },
        properties: {
          layout: {
            hidden: true,
            direction: 'vertical',
            horizontalAlign: 'left',
          },
          cell: {
            disabled: true,
            name: i18n('property.propertyNames.lineHeight'),
            rowHeight: 30,
            ratioHeight: false,
            ratioWidth: false,
          },
          container: {
            scroll: true,
            showScroll: true,
            disabled: false,
          },
        },
        select: {
          minCount: 0,
          maxCount: -1,
          target: 'child',
          reversible: true,
          autoUnselect: true,
          enabled: true,
        },
        components: [1, 2, 3, 4].map((i) =>
          makeDropDownItem(transBlankChart(`${i18n('resource.componentsText.optionText')} ${i}`), (i - 1) * 30, false),
        ),
      }),
    ],
  });
}

/**
 * 创建主体部分数据
 * @return {IComponentData}
 */
function makeMain() {
  return makeCommonComponent(getNewID(), CCanvasPanel, {
    alias: 'main',
    name: 'main',
    position: { x: 0, y: 0 },
    size: { width: 192, height: 30 },
    layout: {
      auto: true,
      responsive: true,
      fixedWidth: false,
      fixedHeight: true,
      vertical: VerticalAlign.TopAndBottom,
      horizontal: HorizontalAlign.LeftAndRight,
    },
    properties: {
      textStyle: { ref: '@properties.textStyle' },
      fill: { ref: '@properties.fill' },
      radius: { ref: '@properties.radius' },
      border: { ref: '@properties.border' },
    },
    value: '@value',
    components: [
      makeCommonComponent(getNewID(), CIcon, {
        position: {
          x: 164,
          y: 12,
        },
        value: 'icon_newworkb02-_Smallx_RP',
        size: { width: 16, height: 16, lockedRatio: true },
        layout: {
          auto: false,
          responsive: true,
          fixedWidth: true,
          fixedHeight: true,
          vertical: VerticalAlign.Middle,
          horizontal: HorizontalAlign.Right,
        },
        properties: {
          fill: {
            type: FillType.solid,
            color: SystemsColor.DefaultDarkFillColor,
          },
          textStyle: {
            fontSize: 12,
            color: SystemsColor.DefaultIconColor,
          },
        },
      }),
      makeCommonComponent(getNewID(), CPureText, {
        autoSize: false,
        position: {
          x: 0,
          y: 0,
        },
        size: { height: 30, width: 164 },
        layout: {
          auto: false,
          responsive: true,
          fixedWidth: false,
          fixedHeight: false,
          vertical: VerticalAlign.Top,
          horizontal: HorizontalAlign.Left,
        },
        properties: {
          textStyle: { ref: '@properties.textStyle' },
        },
        value: '@value',
      }),
    ],
  });
}

/**
 * 创建整个下拉数据
 * @param {string} id
 * @return {IComponentData}
 */
export function makeMultipleSelect(id: string): IComponentData {
  return makeCommonComponent(id, CMultipleSelect, {
    ...MultipleSelectConfig.getDefaultData?.(),
    _id: id,
    alias: 'multipleSelect',
    size: { width: 192, height: 30 },
    value: '',
    states: {
      [PredefinedStates.disabled]: {
        enabled: true,
        properties: {
          textStyle: {
            color: SystemsColor.DisabledTextColor,
          },
          fill: {
            type: FillType.solid,
            color: SystemsColor.DisabledWhiteFillColor,
          },
        },
      },
    },
    sealed: true,
    updateVersion: 1,
    components: [makeMain(), makeDropDown()],
  });
}

export const MultipleSelectConfig: IComponentItem = {
  type: CMultipleSelect,
  thumb: {
    spriteIconClass: SpriteThumb.MultipleSelect.className,
    dragPosition: SpriteThumb.MultipleSelect.position,
  },
  predefinedStates: [PredefinedStates.hover, PredefinedStates.focus, PredefinedStates.disabled],
  isList: true,
  isTextComp: true,
  name: i18n('resource.components.multipleSelect'),
  editor: {
    onPropertyUpdate,
    onChildSelectedChange,
    onClone,
    specials: {
      onCalcMaxPadding,
    },
  },
  preview: {
    onTriggerState,
  },
  getDefaultData() {
    return {
      properties: {
        layout: {
          direction: 'vertical',
          hidden: true,
        },
        textStyle: {
          color: SystemsColor.DefaultTextColor,
          fontSize: 14,
          fontFamily: 'Microsoft YaHei',
          fontStyle: { underline: false, bold: false, strike: false, italic: false },
        },
        border: { bottom: true, top: true, left: true, right: true },
        stroke: {
          cap: StrokeLineCap.Butt,
          color: SystemsColor.DefaultStrokeColor1,
          disabled: false,
          join: StrokeLineJoin.Miter,
          mode: 'custom',
          thickness: 1,
          position: StrokePosition.inner,
        },
        fill: {
          type: FillType.solid,
          color: SystemsColor.DefaultWhiteFillColor,
          disabled: false,
        },
        radius: {
          topLeft: 2,
          topRight: 2,
          bottomLeft: 2,
          bottomRight: 2,
          isPercent: false,
          disabled: false,
        },
        selectedBgColor: {
          prop: 'color',
          name: i18n('property.propertyNames.selectList.selectedBgColor'),
          value: SystemsColor.LightGrayColor,
        },
        iconBgColor: {
          prop: 'color',
          name: i18n('property.propertyNames.icon'),
          value: SystemsColor.DefaultIconColor,
        },
        selectList: {
          prop: SelectListPropertyName,
          bgColor: SystemsColor.DefaultWhiteFillColor,
          selectedFontColor: SystemsColor.DefaultTextColor,
          lineHeight: 30,
        },
        canSearch: {
          prop: 'boolean',
          name: i18n('property.propertyNames.canSearch'),
          value: false,
        },
        placeholder: {
          prop: 'string',
          name: i18n('property.propertyNames.placeholder'),
          value: i18n('placeholders.selectHolder'),
        },
        placeHolderStyle: {
          prop: 'color',
          name: i18n('property.propertyNames.placeholderColor'),
          value: SystemsColor.GrayColor,
        },
        padding: { ...DefaultPadding, left: 8, top: 4, bottom: 4 },
      },
    };
  },
};
