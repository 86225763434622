import { mergeColor } from '@utils/graphicsUtils';
import { roundNumberObject } from '@utils/globalUtils';

import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { IComponentData } from '@fbs/rp/models/component';
import { HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import { default as PropertyBase, IRange } from '@fbs/rp/models/properties/base';
import { PropertyValue } from '@fbs/rp/models/property';
import { FillType } from '@fbs/rp/models/properties/fill';

import i18n from '@i18n';

import { getNewID } from '@helpers/idHelper';
import { coverPatches } from '@helpers/patchHelper';
import { UIComponent, UIContainerComponent } from '@editor/comps';
import { ComponentChangeType } from '@editor/comps/resizeHelper';

import { PredefinedStates } from '@consts/state';
import * as SystemColors from '@consts/colors';
import { SpriteThumb } from '@consts/spriteIcons';

import { IComponentConfigInfo, IComponentItem } from '../../types';
import { CSlider, CRect, CEllipse } from '../../constants';
import { MoveMode, SizeMode } from '../../enum';
import { makeCommonComponent } from '../../helper';
import { makeCanvas } from '../../containers/Canvas';

function onPropertyUpdate(comp: UIComponent, key: string, value: PropertyValue): ArtboardPatches | null {
  if (key === 'progress') {
    const range = (value as PropertyBase).value as IRange;
    const group = comp as UIContainerComponent;
    const { width } = comp.size;
    const progress = group.getComponentByAlias('progress', true);
    const indicator = group.getComponentByAlias('indicator', true);
    if (progress && indicator) {
      const { position } = progress;
      const maxWidth = width - position.x * 2;
      const newWidth = Math.round((maxWidth * range.value) / 100);
      const newLeft = newWidth;
      const myOffset = {
        top: 0,
        left: 0,
        right: newWidth - progress.size.width,
        bottom: 0,
      };
      const patches: ArtboardPatches = {
        do: {},
        undo: {},
      };
      const { size: newSize, position: newPosition, rotate, patches: childPatches } = progress.resizeMySelf(
        myOffset,
        false,
      );
      if (childPatches) {
        coverPatches(patches, childPatches);
      }
      const { patches: progressPatch } = progress.parent!.getPositionPatchesOfChildrenChanged(
        [
          {
            id: progress.id,
            type: ComponentChangeType.Edit,
            position: newPosition,
            size: { ...newSize, height: progress.size.height },
            rotate,
          },
          {
            type: ComponentChangeType.Edit,
            id: indicator.id,
            position: {
              ...indicator.position,
              x: newLeft,
            },
            size: indicator.size,
            rotate: 0,
          },
        ],
        true,
      );
      coverPatches(patches, progressPatch);
      return patches;
    }
  }
  return null;
}

function onResize(comp: UIComponent, { width, height }: { width: number; height: number }): ArtboardPatches | null {
  const group = comp as UIContainerComponent;
  const { properties } = comp;
  const progressValue = (properties.progress!.value as IRange).value;
  const indicator = group.getComponentByAlias('indicator', true);
  const progress = group.getComponentByAlias('progress', true);
  if (indicator && progress) {
    const progressRect = (progress as UIContainerComponent).components[0];
    const { position, size } = indicator;
    const progressSize = progressRect.size;
    const x = Math.round((width - size.width) * (progressValue / 100));
    const y = (height - size.height) / 2;
    return {
      do: {
        [indicator.id]: [Ops.replace('/position', roundNumberObject({ y, x }))],
        [progressRect.id]: [Ops.replace('/size', roundNumberObject({ ...progressSize, width: x }))],
      },
      undo: {
        [indicator.id]: [Ops.replace('/position', position)],
        [progressRect.id]: [Ops.replace('/size', progressSize)],
      },
    };
  }

  return null;
}

const configInfo: IComponentConfigInfo = {
  getMinSize: (comp: UIComponent): { width?: number; height?: number } | undefined => {
    const { alias, parent } = comp;
    if (alias === 'tracker' || alias === 'progress' || parent?.alias === 'progress') {
      return {
        height: 3,
      };
    }
    return undefined;
  },
};

export function makeSlider(id: string): IComponentData {
  return makeCommonComponent(id, CSlider, {
    ...SliderConfig.getDefaultData?.(),
    size: {
      width: 200,
      height: 15,
    },

    states: {
      [PredefinedStates.disabled]: {
        enabled: false,
        properties: {
          indicatorColor: {
            type: FillType.radial,
            color: {
              colorStops: [
                { color: SystemColors.DeepBlueColor },
                { color: mergeColor(SystemColors.DeepBlueColor, 0.6) },
              ],
            },
          },
          progressColor: {
            name: i18n('property.propertyNames.progressColor'),
            prop: 'fill',
            type: FillType.solid,
            color: SystemColors.HalfTransparentDarkGreenColor,
          },
        },
      },
    },
    sealed: true,
    components: [
      makeCommonComponent(getNewID(), CRect, {
        alias: 'tracker',
        layout: {
          fixedWidth: false,
          fixedHeight: true,
          vertical: VerticalAlign.Middle,
          horizontal: HorizontalAlign.LeftAndRight,
          responsive: true,
          auto: false,
        },
        position: {
          x: 7,
          y: 6,
        },
        size: {
          width: 186,
          height: 3,
        },
        properties: {
          radius: {
            topLeft: 5,
            topRight: 5,
            bottomRight: 5,
            bottomLeft: 5,
            isPercent: false,
            disabled: false,
          },
          fill: { ref: '@properties.fill' },
        },
      }),
      makeCanvas(getNewID(), {
        alias: 'progress',
        position: {
          x: 7,
          y: 6,
        },
        layout: {
          horizontal: HorizontalAlign.Left,
          vertical: VerticalAlign.Middle,
          fixedHeight: true,
          fixedWidth: false,
          auto: false,
          responsive: true,
        },
        size: {
          width: Math.round((200 - 15) * 0.7),
          height: 3,
        },
        components: [
          makeCommonComponent(getNewID(), CRect, {
            alias: 'progressRect',
            position: {
              x: 0,
              y: 0,
            },
            size: { width: Math.round((200 - 15) * 0.7), height: 3 },
            layout: {
              horizontal: HorizontalAlign.LeftAndRight,
              vertical: VerticalAlign.TopAndBottom,
              fixedHeight: false,
              fixedWidth: false,
              auto: false,
              responsive: true,
            },
            properties: {
              fill: { ref: '@properties.progressColor' },
              radius: {
                topLeft: 5,
                topRight: 5,
                bottomRight: 5,
                bottomLeft: 5,
                isPercent: false,
                disabled: false,
              },
            },
          }),
        ],
      }),
      makeCanvas(getNewID(), {
        alias: 'indicator',
        position: {
          x: Math.round((200 - 15) * 0.7),
          y: 0,
        },
        size: {
          width: 15,
          height: 15,
          lockedRatio: true,
        },
        layout: {
          horizontal: HorizontalAlign.Left,
          vertical: VerticalAlign.Middle,
          fixedHeight: true,
          fixedWidth: true,
          auto: false,
          responsive: true,
        },
        components: [
          makeCommonComponent(getNewID(), CEllipse, {
            opacity: 100,
            alias: 'ellipse',
            position: {
              x: 0,
              y: 0,
            },
            size: { width: 15, height: 15 },
            layout: {
              horizontal: HorizontalAlign.Center,
              vertical: VerticalAlign.Middle,
              fixedHeight: true,
              fixedWidth: true,
              auto: false,
              responsive: true,
            },
            properties: {
              fill: { ref: '@properties.indicatorColor' },
            },
          }),
        ],
      }),
    ],
  });
}

export const SliderConfig: IComponentItem = {
  type: CSlider,
  thumb: {
    spriteIconClass: SpriteThumb.Slider.className,
    dragPosition: SpriteThumb.Slider.position,
  },
  name: i18n('resource.components.slider'),
  configInfo,
  editor: {
    onResize,
    onPropertyUpdate,
  },
  constraint: {
    progress: {
      move: MoveMode.neither,
      resize: SizeMode.none,
    },
    progressRect: {
      move: MoveMode.neither,
      resize: SizeMode.none,
    },
    tracker: {
      move: MoveMode.neither,
      resize: SizeMode.none,
    },
    indicator: {
      move: MoveMode.horizontal,
    },
    ellipse: {
      move: MoveMode.neither,
    },
  },
  getDefaultData() {
    return {
      properties: {
        fill: {
          name: i18n('property.propertyNames.trackFill'),
          type: FillType.solid,
          color: SystemColors.SilverWhiteColor,
          disabled: false,
        },
        indicatorColor: {
          prop: 'fill',
          name: i18n('property.propertyNames.indicatorColor'),
          type: FillType.solid,
          color: { r: 0, g: 157, b: 255, a: 1 },
          disabled: false,
        },
        progressColor: {
          name: i18n('property.propertyNames.progressColor'),
          prop: 'fill',
          type: FillType.solid,
          color: SystemColors.DeepBlueColor,
          disabled: false,
        },
        progress: {
          prop: 'number',
          name: i18n('property.propertyNames.progress'),
          value: {
            max: 100,
            min: 0,
            value: 70,
            unit: '%',
          },
        },
      },
    };
  },
};
