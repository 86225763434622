import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import IStroke from '@fbs/rp/models/properties/stroke';
import { PropertyValue } from '@fbs/rp/models/property';

import i18n from '@i18n';

import { UIComponent } from '@editor/comps';

import { SpriteThumb } from '@/consts/spriteIcons';
import { getDefaultDataFromTemplate } from '@/libs/helper';

import { IComponentItem } from '../../types';
import { SizeMode, MoveMode } from '../../enum';

/**
 * 属性改变
 * @param {UIComponent} comp
 * @param {string} key
 * @param {PropertyValue} value
 * @returns {ArtboardPatches | null}
 */
function onPropertyUpdate(comp: UIComponent, key: string, value: PropertyValue): ArtboardPatches | null {
  let path: string | undefined = undefined;
  let data: IStroke | undefined = undefined;
  const properties = comp.properties;
  if (key === 'trackStyle') {
    path = comp.getCurrentPropertiesPath('/properties/progressStyle');
    data = properties.progressStyle as IStroke;
  } else if (key === 'progressStyle') {
    path = comp.getCurrentPropertiesPath('/properties/trackStyle');
    data = properties.trackStyle as IStroke;
  }
  if (path && data) {
    const stroke = value as IStroke;
    if (data.thickness !== stroke.thickness) {
      const newData = { ...data, thickness: stroke.thickness };
      return {
        do: {
          [comp.id]: [Ops.replace(path, newData)],
        },
        undo: {
          [comp.id]: [Ops.replace(path, data)],
        },
      };
    }
  }

  return null;
}

export const CircleProgressBarCfg: IComponentItem = {
  type: 'circleProgressBar',
  name: i18n('resource.components.circleProgressBar'),
  thumb: {
    spriteIconClass: SpriteThumb.CircleProgress.className,
    dragPosition: SpriteThumb.CircleProgress.position,
  },
  sizeMode: SizeMode.ratio,
  predefinedStates: [],
  constraint: {
    progressBar: {
      move: MoveMode.neither,
      resize: SizeMode.none,
    },
    track: {
      move: MoveMode.neither,
      resize: SizeMode.none,
    },
  },
  template: `{
    type: @@C.CanvasPanel,
    size: {
      width: 100,
      height: 100,
      lockedRatio: true,
    },
    layout:{
      vertical: 'middle',
      horizontal: 'left',
      fixedWidth: true,
      fixedHeight: true,
      auto: true,
      responsive: true,
    },
    properties:{
      trackStyle: {
        prop: 'stroke',
        name: '${i18n('property.propertyNames.trackStyle')}',
        thickness: 6,
        color: @@SystemColors.DefaultDarkFillColor,
        disabled: false,
        cap:'round'
      },
      progressStyle: {
        prop: 'stroke',
        name: '${i18n('property.propertyNames.progressStyle')}',
        thickness: 6,
        color: "#009DFF",
        disabled: false,
        cap:'round'
      },
      progress:{
        prop: 'number',
        name: '${i18n('property.propertyNames.progress')}',
        disabled: false,
        value: {
          min: 0,
          max: 100,
          value: 66,
          step: 1,
          unit: '%',
        },
      }
    },
    components:[{
      type: @@C.Arc,
      alias:'track',
      size: {
        width: 100,
        height: 100,
      },
      layout:{
        vertical: @@VerticalAlign.TopAndBottom,
        horizontal: @@HorizontalAlign.LeftAndRight,
        fixedWidth: false,
        fixedHeight: false,
        auto: true,
        responsive: true,
      },
      properties:{
        stroke: {ref: '@properties.trackStyle'},
        angle:{
          prop:'number',
          value:{
            value: 100,
          },
          hidden:true,
        }
      },
    },{
      type: @@C.Arc,
      alias:'progressBar',
      size: {width: 100, height: 100},
      layout:{
        vertical: @@VerticalAlign.TopAndBottom,
        horizontal: @@HorizontalAlign.LeftAndRight,
        fixedWidth: false,
        fixedHeight: false,
        auto: true,
        responsive: true,
      },
      properties: {
        stroke: {ref: '@properties.progressStyle'},
        angle:{
          ref: '@properties.progress'
        },
      },
    }],
    sealed: true,
  }`,
  editor: {
    onPropertyUpdate,
  },
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
};

const defaultData = getDefaultDataFromTemplate(CircleProgressBarCfg);
