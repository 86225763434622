import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// const basePosition = '(sw>=60?30:sw*(30/60))';
const basePosition = 'Math.min(sw,sh)/2';

// 右箭头按钮
export default {
  ...flowBase,
  name: i18n('resource.flow.rightArrowButton'),
  type: 'rightArrowButton',
  thumb: {
    spriteIconClass: SpriteThumb.RightArrowButton.className,
    dragPosition: SpriteThumb.RightArrowButton.position,
  },
  template: getShapeData(
    i18n('resource.flow.rightArrowButton'),
    {
      width: 60,
      lockedRatio: false,
      height: 60,
    },
    {
      data: [
        {
          point: {
            x: 0,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 30,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 60,
            y: 30,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 30,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
      closed: true,
      isTemplateShape: true,
      templateData: [
        {
          point: {
            x: 0,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 'sh',
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: `sw-${basePosition}`,
            y: 'sh',
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 'sw',
            y: 'sh/2',
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: `sw-${basePosition}`,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
