import * as React from 'react';
import classnames from 'classnames';

import Icon from '../Icon';

import './index.scss';

interface IProps {
  current: number; // 当前页数
  total: number; // 总页数
  size?: number; // 按钮尺寸
  className?: string;
  locale?: { prevText: string; nextText: string }; // 上下一页本地化
  onChange: (current: number) => void;
}

interface IState {
  items: {}[];
}

const MAX_SHOW = 6;

class ResourceGroup extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  handleNumberClick = (value: number) => {
    this.doChangeValue(value);
  };

  handleNextClick = () => {
    this.doChangeValue(Math.min(this.props.current + 1, this.props.total));
  };

  handlePrevClick = () => {
    this.doChangeValue(Math.max(this.props.current - 1, 1));
  };

  doChangeValue(value: number) {
    if (value !== this.props.current) {
      this.props.onChange(value);
    }
  }

  renderButton(option: { child: any; key?: string; className?: string; clickHandler?: React.MouseEventHandler }) {
    const { size } = this.props;
    return (
      <div
        style={{
          width: size,
          height: size,
          lineHeight: size ? `${size}px` : undefined,
        }}
        key={option.key}
        className={classnames('button', option.className)}
        onClick={option.clickHandler}
      >
        {option.child}
      </div>
    );
  }

  renderPrevButton() {
    const { locale } = this.props;
    const child = locale?.prevText ? locale.prevText : <Icon className="prev-button" theme="tag" cls="back" />;
    return this.renderButton({
      child,
      className: locale?.nextText ? 'custom-next-button' : undefined,
      clickHandler: this.handlePrevClick,
    });
  }

  renderNextButton() {
    const { locale } = this.props;
    const child = locale?.nextText ? locale.nextText : <Icon cls="back" className="next-button" theme="tag" />;
    return this.renderButton({
      child,
      className: locale?.nextText ? 'custom-next-button' : undefined,
      clickHandler: this.handleNextClick,
    });
  }

  renderNumberButton = (value: number) => {
    const className = classnames('pagination-number', { selected: value === this.props.current });
    return this.renderButton({
      child: value,
      key: `${value}`,
      className,
      clickHandler: this.handleNumberClick.bind(this, value),
    });
  };

  renderHiddenNumberButton = () => {
    return this.renderButton({
      child: <Icon cls="icon_more" theme="tag" />,
      className: 'hidden-number',
    });
  };

  calcNumberList() {
    const { current, total } = this.props;
    let prevNumber: number[] = [];
    let nextNumber: number[] = [];
    // 总数大于最大显示数量时
    if (total > MAX_SHOW) {
      nextNumber.push(total);
      // 当前值小于最大显示数量前几个时
      if (current < MAX_SHOW - 1) {
        for (let i = 1; i < MAX_SHOW && i <= total; i++) {
          prevNumber.push(i);
        }
      }
      // 当前值位于最后几个时
      else if (current > total - (MAX_SHOW - 1)) {
        nextNumber = [];
        prevNumber = [1];
        for (let i = total; i > total - (MAX_SHOW - 1); i--) {
          nextNumber.unshift(i);
        }
      }
      // 当前值位于中间时
      else {
        for (let i = 1; i < MAX_SHOW; i++) {
          const num = current + 1 - (MAX_SHOW - 1) + i;
          prevNumber.push(num);
        }
      }
    } else {
      for (let i = 1; i <= total; i++) {
        prevNumber.push(i);
      }
    }

    return {
      prev: prevNumber,
      next: nextNumber,
    };
  }

  render() {
    const { className, total } = this.props;
    const { prev, next } = this.calcNumberList();
    return (
      <div className={classnames(className, 'dsm-c-rp-pagination')}>
        {this.renderPrevButton()}
        {prev.map(this.renderNumberButton)}
        {total > MAX_SHOW && this.renderHiddenNumberButton()}
        {next.map(this.renderNumberButton)}
        {this.renderNextButton()}
      </div>
    );
  }
}

export default ResourceGroup;
