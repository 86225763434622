import { getNewID } from '@/helpers/idHelper';
import { TextAlign } from '@/fbs/rp/models/properties/text';

export const DEFAULT_SIZE = { width: 300, height: 166 };

export const DEFAULT_TEXT_STYLE = {
  fontSize: 14,
  textAlign: TextAlign.left,
  fontFamily: 'Microsoft YaHei',
  fontStyle: { underline: false, bold: false, strike: false, italic: false },
};

export const getDefaultCollapseData = () => [
  {
    id: getNewID(),
    index: 0,
    expand: true,
    children: [
      {
        id: getNewID(),
        index: 0,
        expand: false,
      },
      {
        id: getNewID(),
        index: 1,
        expand: false,
      },
    ],
  },
  {
    id: getNewID(),
    index: 1,
    expand: false,
    children: [
      {
        id: getNewID(),
        index: 0,
        expand: false,
      },
      {
        id: getNewID(),
        index: 1,
        expand: false,
      },
    ],
  },
];

export const DEFAULT_EXPAND_ICON = {
  iconMode: 'font',
  expand: {
    icon: { iconCode: 60570, fontName: 'boldIconFont' },
  },
  collapse: {
    icon: { iconCode: 60571, fontName: 'boldIconFont' },
  },
};

export const RELATION_DATA = {
  id: '',
  index: 0,
  expand: false,
};

export const COLLAPSE_MAX_LEVEL = 2;
