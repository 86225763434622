import { PolygonData } from '@utils/graphicsUtils';

import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { ILineValue, IPathPoint, IPathValue } from '@fbs/rp/models/value';
import { IBounds, IPoint } from '@fbs/common/models/common';

import {
  getCenter,
  getMidpointOfTwoPoints,
  getNWPoint,
  getSymmetricPoint,
  ILineDirection,
} from '@helpers/rotateHelper';
import { UIComponent } from '@editor/comps';

import { CPath, CPolygon, CCompoundPath, CImage, CRect, CEllipse } from '@libs/constants';

import { coverPatches } from './patchHelper';
import { getModifyAttrArtboardPatches } from './componentHelper';

export enum IFlipModel {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export function getPatchesWhenFlip(comps: UIComponent[], flipModel: IFlipModel, value: boolean) {
  // 1.更新内部value数据
  // 2.更新position数据
  // 3.如果本身是个组，那么就不仅仅要更新位置，还要递归的更新子
  const patches: ArtboardPatches = {
    do: {},
    undo: {},
  };

  comps.forEach((comp) => {
    const newPatches = comp.flipHandler(flipModel, value);
    coverPatches(patches, newPatches);
  });

  return patches;
}

function flipPolygons(comps: UIComponent[], flipModel: IFlipModel) {
  const patches: ArtboardPatches = {
    do: {},
    undo: {},
  };
  comps.forEach((comp) => {
    const {
      id,
      size,
      properties: { polygon },
    } = comp;
    const { sideCount } = polygon!;
    const points = new PolygonData(size, sideCount).toPolygonPoints(0);
    const pathPoints = points.map((pt) => {
      const x = flipModel === IFlipModel.Horizontal ? size.width - pt.x : pt.x;
      const y = flipModel === IFlipModel.Vertical ? size.height - pt.y : pt.y;
      return {
        point: { x, y },
        handleOut: { x: 0, y: 0 },
        handleIn: { x: 0, y: 0 },
      } as IPathPoint;
    });
    const value: IPathValue = {
      data: pathPoints,
      closed: true,
    };
    patches.do[id] = [Ops.replace('./type', CPath), Ops.replace('./value', value)];
    patches.undo[id] = [Ops.replace('./type', CPolygon), Ops.remove('./value')];
  });
  return patches;
}

export function getPositionPatchesWhenFlip(comp: UIComponent, flipModel: IFlipModel, value: boolean, bounds: IBounds) {
  const centerOfBounds = {
    x: bounds.left + bounds.width / 2,
    y: bounds.top + bounds.height / 2,
  };
  // 先计算中心点，然后得到轴对称之后的中心点，再根据中心点反推position信息
  const { position, size } = comp;
  const originalCenter = getCenter(position, size);
  const lineOfBoundsArea = getSymmetricLine(centerOfBounds, flipModel);
  const newCenter = getSymmetricPoint(originalCenter, lineOfBoundsArea);
  const newPosition = getNWPoint(newCenter, size, 0);
  const path = comp.getCurrentPositionPath();
  return getModifyAttrArtboardPatches(comp.id, path, { oldValue: position, newValue: newPosition });
}

function getLineFlipResult(comp: UIComponent, flip: IFlipModel) {
  const { startPoint, endPoint } = comp.value as ILineValue;
  const midPoint = getMidpointOfTwoPoints(startPoint, endPoint);
  const symmetricLineOfComp = getSymmetricLine(midPoint, flip);

  // 得到对称点
  const newValue = {
    startPoint: getSymmetricPoint(startPoint, symmetricLineOfComp),
    endPoint: getSymmetricPoint(endPoint, symmetricLineOfComp),
  };
  const path = comp.getCurrentPropertiesPath('/value');
  return { newValue, path };
}

function getNewValueOfPathFlip(comp: UIComponent, flip: IFlipModel) {
  const value = comp.value as IPathValue;
  const centerPathArea = getCenterOfPath(comp);
  const lineOfComp = getSymmetricLine(centerPathArea, flip);

  const newData = value.data.map((allPoints) => {
    const { point, handleIn, handleOut, radius } = allPoints;
    const newPoint = getSymmetricPoint(point, lineOfComp);
    // 计算handleIn,handleOut的对称线
    const symmetricLineOfPoint = getSymmetricLine({ x: 0, y: 0 }, flip);
    const [newHandleIn, newHandleOut] = [handleIn, handleOut].map((inOutPoint) =>
      getSymmetricPoint(inOutPoint, symmetricLineOfPoint),
    );
    return {
      point: newPoint,
      handleIn: newHandleIn,
      handleOut: newHandleOut,
      radius,
    };
  });
  const newValue = { ...value, ...{ data: newData } };
  const path = [CCompoundPath, CPath].includes(comp.type) ? '/value' : comp.getCurrentPropertiesPath('/value');
  return { newValue, path };
}

function getNewRotateValueOfPathFlip(comp: UIComponent) {
  const newRotate = (-comp.rotate + 360) % 360;
  const rotatePath = comp.getCurrentRotatePath();
  return { newRotate, rotatePath };
}

function getNewValueOfCompoundPathFlip(comp: UIComponent, flip: IFlipModel) {
  const value = comp.value as IPathValue[];
  const newValue = value.map((item) => {
    const centerPathArea = getCenterOfPath(comp);
    const lineOfComp = getSymmetricLine(centerPathArea, flip);

    const newData = item.data.map((allPoints) => {
      const { point, handleIn, handleOut, radius } = allPoints;
      const newPoint = getSymmetricPoint(point, lineOfComp);
      // 计算handleIn,handleOut的对称线
      const symmetricLineOfPoint = getSymmetricLine({ x: 0, y: 0 }, flip);
      const [newHandleIn, newHandleOut] = [handleIn, handleOut].map((inOutPoint) =>
        getSymmetricPoint(inOutPoint, symmetricLineOfPoint),
      );
      return {
        point: newPoint,
        handleIn: newHandleIn,
        handleOut: newHandleOut,
        radius,
      };
    });
    return { ...item, ...{ data: newData } };
  });
  const path = [CCompoundPath, CPath].includes(comp.type) ? '/value' : comp.getCurrentPropertiesPath('/value');
  return { newValue, path };
}

export function updatePatchesByWhenFlip(comp: UIComponent, flipModel: IFlipModel, patches: ArtboardPatches) {
  const id = comp.id;
  const oldValue = comp.value;
  if (comp.type === 'line') {
    // 计算轴对称线的信息
    const { newValue, path } = getLineFlipResult(comp, flipModel);
    // 得到patches
    coverPatches(patches, getModifyAttrArtboardPatches(id, path, { oldValue: oldValue, newValue }));
  } else if (comp.type === 'path') {
    // 先计算point的对称线
    const { newValue, path } = getNewValueOfPathFlip(comp, flipModel);
    coverPatches(patches, getModifyAttrArtboardPatches(id, path, { oldValue: oldValue, newValue }));

    const { newRotate, rotatePath } = getNewRotateValueOfPathFlip(comp);
    coverPatches(
      patches,
      getModifyAttrArtboardPatches(id, rotatePath, {
        oldValue: comp.rotate,
        newValue: newRotate,
      }),
    );
  } else if (comp.type === CCompoundPath) {
    const { newValue, path } = getNewValueOfCompoundPathFlip(comp, flipModel);
    coverPatches(patches, getModifyAttrArtboardPatches(id, path, { oldValue: oldValue, newValue }));
  } else if (comp.type === CPolygon) {
    coverPatches(patches, flipPolygons([comp], flipModel));
  } else if (comp.type === CImage || comp.type === CRect || comp.type === CEllipse) {
    const { newRotate, rotatePath } = getNewRotateValueOfPathFlip(comp);
    coverPatches(
      patches,
      getModifyAttrArtboardPatches(id, rotatePath, {
        oldValue: comp.rotate,
        newValue: newRotate,
      }),
    );
  }
}
/**
 * 根据路径编辑的point计算得到整个区域的中心点
 * @param comp 路径组件
 */
export function getCenterOfPath(comp: UIComponent) {
  const { width, height } = comp.size;
  return {
    x: width / 2,
    y: height / 2,
  };
}

export function getSymmetricLine(center: IPoint, flip: IFlipModel) {
  const lineDirection = flip === IFlipModel.Vertical ? ILineDirection.Horizontal : ILineDirection.Vertical;
  return {
    direction: lineDirection,
    intersectionOnXAxes: center.x,
    intersectionOnYAxes: center.y,
  };
}
