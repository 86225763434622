import * as React from 'react';
import classnames from 'classnames';

import Icon from '../Icon';

import './index.scss';

export interface IIconButtonProps {
  width?: number | string;
  height?: number | string;
  className?: string;
  actived?: boolean;
  style?: React.CSSProperties;
  cls?: string;
  disabled?: boolean;
  text?: string;
  onClick?: React.MouseEventHandler;
  onDoubleClick?: React.MouseEventHandler;
  tips?: string;
  noborder?: boolean;
}

const IconButton: React.FC<IIconButtonProps> = (props: IIconButtonProps) => {
  const {
    cls,
    disabled,
    onClick,
    onDoubleClick,
    width,
    height,
    className,
    actived,
    style,
    tips,
    text,
    noborder,
  } = props;
  return (
    <button
      className={classnames('dsm-c-rp-icon-button', className, { actived: actived, noborder })}
      disabled={disabled}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      style={{
        ...style,
        width,
        height,
      }}
    >
      {cls && <Icon selected={actived} tips={tips} cls={cls} />}
      {text && <span className="btn-text">{text}</span>}
    </button>
  );
};

IconButton.defaultProps = {
  disabled: false,
  width: 24,
  height: 24,
};

export default React.memo(IconButton);
