import i18n from '@/i18n';
import { IComponentItem } from '@/libs/types';
import { CLine } from '@/libs/constants';
import { SpriteThumb } from '@/consts/spriteIcons';
import { UIComponent } from '@/editor/comps';
import { IComponentData } from '@/fbs/rp/models/component';
import { DefaultStrokeColor1 } from '@/consts/colors';
import { StrokeLineCap, StrokeLineJoin, StrokePosition } from '@/fbs/rp/models/properties/stroke';
import { makeShapeBase, getShapeBaseDefaultData } from '../common/ShapeTextBase';

export const lineConfig: IComponentItem = {
  type: CLine,
  name: i18n('resource.components.line'),
  shortCut: 'L',
  thumb: {
    spriteIconClass: SpriteThumb.Line.className,
    dragPosition: SpriteThumb.Line.position,
  },
  editor: {
    onValidateAllowEditor: (comp: UIComponent, trigger: 'enter' | 'dblClick'): 'path' | 'value' => {
      return trigger === 'dblClick' ? 'path' : 'value';
    },
  },
  getDefaultData() {
    const shapeBaseDefaultData = getShapeBaseDefaultData(false);

    Object.assign(shapeBaseDefaultData.properties, {
      line: {
        startArrow: false,
        endArrow: false,
      },
      stroke: {
        thickness: 1,
        color: DefaultStrokeColor1,
        cap: StrokeLineCap.Butt,
        join: StrokeLineJoin.Miter,
        position: StrokePosition.center,
        disabled: false,
      },
    });

    return shapeBaseDefaultData;
  },
};

export function makeLine(id: string, data?: Partial<IComponentData>): IComponentData {
  const initialWidth = 150;
  return makeShapeBase(
    id,
    CLine,
    false,
    Object.assign(
      {
        size: {
          width: 150,
          height: 0,
        },
        value: {
          //这里要保证线条是水平的，不能有1px的偏差
          startPoint: {
            x: 0,
            y: 0,
          },
          endPoint: {
            x: initialWidth,
            y: 0,
          },
        },
      },
      data,
    ),
  );
}
