export enum RpPreviewOption {
  PAGE_DIDMOUNT = 'PAGE_DIDMOUNT',
  PAGE_CHANGED = 'PAGE_CHANGED',
  SCALE_CHANGED = 'SCALE_CHANGED',
  PAGE_OPTION = 'PAGE_OPTION',
  URL_CHANGED = 'URL_CHANGED',
  SPEC_APPEARANCE_CHANGED = 'SPEC_APPEARANCE_CHANGED',
}

export interface PageScale {
  scale: number;
}

export interface PageChange {
  pageID: string;
}

export interface PageOption {
  ha: number; // hover热区
  la: number; // 显示热区
  fc: number; // 自适应
  dt: string; // 外壳
}

// 通讯信息
export interface PostMessageData {
  event: RpPreviewOption;
  payload: PageScale | PageChange | PageOption;
}
