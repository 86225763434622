import * as React from 'react';
import classnames from 'classnames';

import Icon from '../../../Icon';

import './index.scss';

interface IPaginationPrevOrNextProps {
  current: number;
  maxPage: number;
  type: 'left' | 'right';

  onChange?(pageNumber: number): void;
}

const PaginationPrevOrNext: React.FC<IPaginationPrevOrNextProps> = ({
  current,
  maxPage,
  type,
  onChange,
}: IPaginationPrevOrNextProps) => {
  const onPrev = () => {
    onChange && onChange(current - 1);
  };
  const isPrevDisabled = current === 1;

  if (type === 'left') {
    return (
      <li
        className={classnames('dsm-c-pagination-prev', {
          disabled: isPrevDisabled,
        })}
        onClick={() => {
          if (isPrevDisabled) {
            return;
          }
          onPrev();
        }}
        tabIndex={isPrevDisabled ? -1 : 0}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            !isPrevDisabled && onPrev();
          }
        }}
      >
        <Icon cls={'pageturning_left'} />
      </li>
    );
  }

  const onNext = () => {
    onChange && onChange(current + 1);
  };

  const isNextDisabled = current === maxPage;

  return (
    <li
      className={classnames('dsm-c-pagination-next', {
        disabled: isNextDisabled,
      })}
      onClick={() => {
        if (isNextDisabled) {
          return;
        }
        onNext();
      }}
      tabIndex={isNextDisabled ? -1 : 0}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          !isNextDisabled && onNext();
        }
      }}
    >
      <Icon cls={'pageturning_right'} />
    </li>
  );
};

export default PaginationPrevOrNext;
