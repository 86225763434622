import { IPageAction } from '@fbs/rp/models/interactions';

import { IPageCommand, CommandFinishEventHandle, IWorker, PageSkipHandle } from '../types';

import carouselManager from '../carouselManager';

export default class PageSkipCommand implements IPageCommand {
  public onFinish?: CommandFinishEventHandle;
  onSkip?: PageSkipHandle;

  private delayTime?: Timeout;

  public readonly _worker: IWorker;
  protected action: IPageAction;

  constructor(worker: IWorker, action: IPageAction) {
    this._worker = worker;
    this.action = action;
  }

  termination() {
    this.delayTime && window.clearTimeout(this.delayTime);
  }

  protected doSkip = () => {
    if (this.onSkip) {
      this.onSkip(this.action, this._worker.doc.pageID);
    }
    carouselManager.clearCustomTimeout();
    carouselManager.resetAllPassiveValue();
    this.onFinish && this.onFinish(this);
  };

  execute = () => {
    const { animation } = this.action;
    const { delay } = animation;
    delay ? (this.delayTime = window.setTimeout(this.doSkip, delay)) : this.doSkip();
  };
}
