import { Base } from './base';

export interface ITeamInvitation extends Base {
  teamID: string;
  groupID?: number;
  appID?: string;
  apps?: Array<string>;
  role: string;
  fromAppShare?: boolean;
  timeLimit?: TimeLimit;
}

// v1/team/info/:token GET 获取邀请链接信息
export interface ITeamInvitationInfo {
  teamInvitation: ITeamInvitation;
  isInTeam: boolean;
  userName: string;
  teamName: string;
  // 是否开启审批
  inviteCheck?: boolean;
  teamIsFull: boolean;
}

// v1/team/:teamID/invitationLink GET //获取邀请链接的token  IGetTeamInvitationTokenWarn | IGetTeamInvitationTokenSucc
export interface IGetTeamInvitationTokenWarn {
  message: string;
  code: number;
  token: string;
}

export interface IGetTeamInvitationTokenSucc {
  code: number;
  message: string;
  payload: string;
}

// v1/team/:teamID/invitationLink GET
export interface IGetTeamInvitationLinkF {
  code: number;
  message: string;
  payload: IGetInvitationLinkInfo;
}

export interface IGetInvitationLinkInfo {
  invitationID: string;
  role: string;
}

export enum TimeLimit {
  Forever = '',
  Seven = 'seven',
  Fifteen = 'fifteen',
}

export interface IPatchInvitationLinkReq {
  apps?: string[];
  groupID?: number;
  role?: string;
  timeLimit?: string;
}
