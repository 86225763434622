import * as React from 'react';
import classNames from 'classnames';
import { UIComponent } from '@/editor/comps';
import { getWrapperStyle } from './getWrapperStyle';

interface IProps {
  comp: UIComponent;
  scale: number;
  isPreview?: boolean;
}

export const renderComponentWrapper = ({ comp, scale }: IProps, content?: JSX.Element | null) => {
  const { id, type, hidden } = comp;
  const style = getWrapperStyle(comp, { x: 0, y: 0 }, { x: scale, y: scale }, scale, false);

  return (
    <div
      key={id}
      id={id}
      style={style}
      className={classNames('component', `${type ? 'component-' + type : ''}`, { hidden })}
    >
      {content}
    </div>
  );
};
