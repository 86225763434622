import * as React from 'react';

import MathUtils from '@utils/mathUtils';
import Icon from '../../Icon';
import i18n from '@i18n';

interface IPosition {
  from: { x: number; y: number; r: number };
  to: { x: number; y: number; r: number };
}

interface IGradientPosition {
  position: IPosition;
  onChange?: (position: IPosition) => void;
}

const POSITION: { mode: string; value: IPosition }[] = [
  { mode: 'center center', value: { from: { x: 0.5, y: 0.5, r: 0 }, to: { x: 0.5, y: 0.5, r: 0.5 } } },
  { mode: 'top left', value: { from: { x: 0, y: 0, r: 0 }, to: { x: 0, y: 0, r: 1 } } },
  { mode: 'top center', value: { from: { x: 0.5, y: 0, r: 0 }, to: { x: 0.5, y: 0, r: 1 } } },
  { mode: 'top right', value: { from: { x: 1, y: 0, r: 0 }, to: { x: 1, y: 0, r: 1 } } },
  { mode: 'center right', value: { from: { x: 1, y: 0.5, r: 0 }, to: { x: 1, y: 0.5, r: 1 } } },
  { mode: 'bottom right', value: { from: { x: 1, y: 1, r: 0 }, to: { x: 1, y: 1, r: 1 } } },
  { mode: 'bottom center', value: { from: { x: 0.5, y: 1, r: 0 }, to: { x: 0.5, y: 1, r: 1 } } },
  { mode: 'bottom left', value: { from: { x: 0, y: 1, r: 0 }, to: { x: 0, y: 1, r: 1 } } },
  { mode: 'center left', value: { from: { x: 0, y: 0.5, r: 0 }, to: { x: 0, y: 0.5, r: 1 } } },
];

class GradientPosition extends React.Component<IGradientPosition> {
  private validateSamePosition = (a: IPosition, b: IPosition) => {
    return (
      MathUtils.equal(a.from.x, b.from.x) &&
      MathUtils.equal(a.from.y, b.from.y) &&
      MathUtils.equal(a.from.r, b.from.r) &&
      MathUtils.equal(a.to.x, b.to.x) &&
      MathUtils.equal(a.to.y, b.to.y) &&
      MathUtils.equal(a.to.r, b.to.r)
    );
  };

  handlePositionClick = () => {
    const { position, onChange } = this.props;
    const index = (POSITION.findIndex((item) => this.validateSamePosition(item.value, position)) + 1) % 9;
    const newValue = POSITION[index].value;
    onChange && onChange(newValue);
  };

  render() {
    return (
      <div className="dsm-c-rp-color-gradient-position">
        <div className="gradient-reset">
          <Icon cls="reset" disabled tips={i18n('tips.rotationAngle')} onClick={this.handlePositionClick} />
        </div>
      </div>
    );
  }
}

export default GradientPosition;
