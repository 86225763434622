import * as React from 'react';

import classNames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';

import { depthClone, isInputting } from '@utils/globalUtils';
import { dragDelegate } from '@utils/mouseUtils';

import { MouseButton } from '@/consts/enums/mouseButton';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';
import { IPosition, ISize, Orientation } from '@fbs/common/models/common';
import { getSizeFromOrientation } from '@/helpers/artboardDimensionHelper';
import { getCompsBoundsInArtboard } from '@helpers/componentHelper';
import { IPreviewOption, isMobileAppType } from '@helpers/previewHelper';
import { doInputInvertSelection } from '@/helpers/propertyPanelHelper';

import { ScrollBars } from '@dsm';
import Theater from '../../../../theater';
import PreviewDevice from './PreviewDevice';

import './index.scss';

export interface IViewPortProp {
  page?: Theater;
  advancePage?: Theater;
  theme: 'dark' | 'light';
  scale: number;
  zoomByWheel?: boolean;
  appSize: ISize;
  appType: string;
  desktopTitleHeight: number;
  option: IPreviewOption;
  isStandalone: boolean;
  orientation?: Orientation;
  remarkTagId?: string;
  onAutoScale: (value: number) => void;
  renderNavigationPanel?: (offsetLeft: number, offsetX: number) => React.ReactNode;
}

export interface IViewPortState {
  spaceKey?: boolean;
  viewPortSize: { viewPortHeight: number; viewPortWidth: number; offsetLeft: number };
  devicePosition?: { left: number; top: number };
}

const MIN_MARGIN = 0;

class ViewPort extends React.Component<IViewPortProp, IViewPortState> {
  static defaultProps: Partial<IViewPortProp> = {};
  private resizeObserver?: ResizeObserver;
  timeout?: Timeout;

  selfRef: React.RefObject<HTMLDivElement> = React.createRef();
  scrollBar: React.RefObject<ScrollBars> = React.createRef();
  point: IPosition = { x: 0, y: 0 };
  private oldScrollPosition: IPosition = { x: 0, y: 0 };
  private isScrollV: boolean = false;
  private isScrollH: boolean = false;

  private dom: PreviewDevice | undefined;

  constructor(props: IViewPortProp) {
    super(props);
    this.state = {
      viewPortSize: { viewPortHeight: 0, viewPortWidth: 0, offsetLeft: 0 },
    };
  }

  componentDidMount() {
    if (this.selfRef.current) {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target.className === this.selfRef.current?.className) {
            const viewPortSize = {
              viewPortWidth: entry.contentRect.width,
              viewPortHeight: entry.contentRect.height,
              offsetLeft: (entry.target as HTMLElement).offsetLeft,
            };
            this.setState({ viewPortSize });
            this.doCalcFloatOffset();
          }
        }
      });
      this.resizeObserver.observe(this.selfRef.current);
    }
    this.getViewPortSize();
    window.addEventListener('mousemove', this.handleWindowMousemove, true);
    window.addEventListener('keydown', this.handleWindowKeyDown);
    window.addEventListener('keyup', this.handleWindowKeyUp, true);
    window.addEventListener('blur', this.handleWindowBlur);
  }

  /**
   * 考虑主画板方向后的项目尺寸
   */
  get rotatedAppSize() {
    const { page, appSize, orientation } = this.props;
    if (page) {
      const { mainArtboard } = page.doc;
      return getSizeFromOrientation(orientation ?? mainArtboard.orientation, appSize);
    }
    return appSize;
  }

  deviceRef = (dom: PreviewDevice) => {
    this.dom = dom;
  };

  handleWindowMousemove = (e: MouseEvent) => {
    this.point = {
      x: e.pageX,
      y: e.pageY,
    };
  };

  handleWindowKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === KeyCodeMap.VK_SPACE && !isInputting()) {
      e.preventDefault();
      this.setState({ spaceKey: true });
    }
  };

  handleWindowBlur = () => {
    this.setState({ spaceKey: false });
  };

  handleWindowKeyUp = (e: KeyboardEvent) => {
    if (e.keyCode === KeyCodeMap.VK_SPACE) {
      this.setState({ spaceKey: false });
    }
  };

  /**
   * 获取鼠标在演示区域的位置
   */
  getMousePointInViewPort = () => {
    const viewPort = this.selfRef.current;
    if (!viewPort) {
      return undefined;
    }
    const { top, left } = viewPort.getBoundingClientRect();
    const { x, y } = this.point;
    return {
      x: x - left,
      y: y - top,
    };
  };

  doZoomByWheel = (preScale: number) => {
    const scrollBar = this.scrollBar.current;
    const mousePoint = this.getMousePointInViewPort();
    if (!scrollBar || !mousePoint) {
      return;
    }
    const { scrollLeft, scrollTop } = scrollBar;

    const { scale } = this.props;
    const { x: oldLeft, y: oldTop } = this.oldScrollPosition;
    if (this.isScrollV) {
      const pointTop = (mousePoint.y + oldTop) / preScale;
      const newTop = pointTop * scale;
      const newScrollTop = newTop - mousePoint.y;
      scrollTop(newScrollTop);
    }
    if (this.isScrollH) {
      const pointLeft = (mousePoint.x + oldLeft) / preScale;
      const newLeft = pointLeft * scale;
      const newScrollLeft = newLeft - mousePoint.x;
      scrollLeft(newScrollLeft);
    }
  };

  /**
   * 记住缩放的滚动条位置
   */
  upDateOldScrollPosition = () => {
    const scrollBar = this.scrollBar.current;
    if (!scrollBar) {
      return;
    }
    const { getScrollTop, getScrollLeft, getScrollHeight, getScrollWidth, getClientHeight, getClientWidth } = scrollBar;
    const curScrollTop = getScrollTop();
    const curScrollLeft = getScrollLeft();
    const scrollHeight = getScrollHeight();
    const scrollWidth = getScrollWidth();
    const clientHeight = getClientHeight();
    const clientWidth = getClientWidth();

    this.isScrollV = scrollHeight - clientHeight > 0;
    this.isScrollH = scrollWidth - clientWidth > 0;
    this.oldScrollPosition = {
      x: curScrollLeft,
      y: curScrollTop,
    };
  };

  UNSAFE_componentWillReceiveProps(nextProp: IViewPortProp) {
    const isZoom = nextProp.scale !== this.props.scale;
    // 在快照预览时不能缩放
    if (nextProp.zoomByWheel && isZoom && !document.querySelector('.snapshot-preview-mask')) {
      this.upDateOldScrollPosition();
    }

    // 翻页重置滚动条位置
    const nextPageId = nextProp.page?.doc.pageID;
    const currPageId = this.props.page?.doc.pageID;
    if (nextPageId !== currPageId) {
      this.scrollBar.current?.scrollToTop();
      this.scrollBar.current?.scrollToLeft();
    }
  }

  componentDidUpdate(preProps: IViewPortProp) {
    if (
      preProps.option.mobileType !== this.props.option.mobileType ||
      preProps.option.noBoundary !== this.props.option.noBoundary
    ) {
      this.doCalcFloatOffset();
    }

    const isZoom = preProps.scale !== this.props.scale;
    // 在快照预览时不能缩放
    if (this.props.zoomByWheel && isZoom && !document.querySelector('.snapshot-preview-mask')) {
      this.doZoomByWheel(preProps.scale);
    }

    if (!this.state.devicePosition || preProps.scale !== this.props.scale || this.props.page !== preProps.page) {
      setTimeout(() => {
        this.doCalcFloatOffset();
      }, 0);
    }
  }

  private doDisableSelect = (e: Event) => {
    e.preventDefault();
  };

  private handleMouseDown = (e: React.MouseEvent) => {
    if (!this.scrollBar.current) {
      return;
    }
    const isSpaceKeyOrMouseWheel = this.state.spaceKey || e.button === MouseButton.Wheel;
    if (isSpaceKeyOrMouseWheel) {
      e.preventDefault();
      this.setState({ spaceKey: true });
      window.addEventListener('selectstart', this.doDisableSelect);
      const originTop = this.scrollBar.current.getScrollTop();
      const originLeft = this.scrollBar.current.getScrollLeft();
      dragDelegate(
        (e, delta) => {
          let newTop = originTop - delta.y;
          let newLeft = originLeft - delta.x;
          this.scrollBar.current!.scrollTo(newLeft, newTop);
        },
        () => {
          window.removeEventListener('selectstart', this.doDisableSelect);
        },
      );
    }
    // 处理演示内输入组件反选问题
    doInputInvertSelection(e);
  };
  handleMouseUp = (e: React.MouseEvent) => {
    if (e.button === MouseButton.Wheel) {
      this.setState({ spaceKey: false });
    }
  };

  private doCalcFloatOffset() {
    const device = this.dom;
    const { page, appType, desktopTitleHeight } = this.props;
    if (device && page) {
      const { left, top } = this.dom?.clientRect || { left: 0, top: 0 };
      // 设备外壳范围外外滚动时设置固定层级的top和left同步滚动
      if (isMobileAppType(appType)) {
        this.setState({ devicePosition: { left, top } });
      } else {
        this.setState({
          devicePosition: { left: Math.max(left, MIN_MARGIN), top: Math.max(top, MIN_MARGIN + desktopTitleHeight) },
        });
      }
    }
  }

  handleScroll = () => {
    this.doCalcFloatOffset();
  };

  setScrollBarWhenDeviceDidMountAndPageChange = (newOffset?: IPosition) => {
    const { rotatedAppSize } = this;
    const { option, scale, page, appType } = this.props;
    const screenScale = scale / 100;
    const viewSize = this.state.viewPortSize;
    if (option.noBoundary && page && viewSize) {
      const pageSize = page.doc.mainArtboard.size;
      const isMobileType = isMobileAppType(appType);
      const deviceSize = {
        width: isMobileType && !option.noBoundary ? rotatedAppSize.width * screenScale : pageSize.width * screenScale,
        height:
          isMobileType && !option.noBoundary ? rotatedAppSize.height * screenScale : pageSize.height * screenScale,
      };
      const { left, top } = getCompsBoundsInArtboard(page.doc.mainArtboard.components);
      const padding = {
        left: left < 0 ? 10 - left * screenScale : 10,
        top: top < 0 ? 10 - top * screenScale : 10,
      };

      const offset = newOffset || {
        x: Math.max(viewSize.viewPortWidth * 0.5 - deviceSize.width * 0.5, 10),
        y: Math.max(viewSize.viewPortHeight * 0.5 - deviceSize.height * 0.5, 10),
      };
      // 不清楚。但只在setstate回调时有作用
      this.setState({}, () => {
        this.scrollBar.current?.scrollLeft(padding.left - offset.x);
        this.scrollBar.current?.scrollTop(padding.top - offset.y);
      });
    }
  };

  setScrollBarRZ = () => {
    this.setState({}, () => {
      this.scrollBar.current?.scrollToLeft();
      this.scrollBar.current?.scrollToTop();
    });
  };

  componentWillUnmount() {
    this.resizeObserver?.disconnect();
    window.removeEventListener('mousemove', this.handleWindowMousemove);
    window.removeEventListener('keydown', this.handleWindowKeyDown);
    window.removeEventListener('keyup', this.handleWindowKeyUp, true);
    window.removeEventListener('blur', this.handleWindowBlur);
  }

  getViewPortSize = () => {
    const viewPortDom = this.selfRef.current as HTMLDivElement;
    const viewPortHeight = viewPortDom.clientHeight;
    const viewPortWidth = viewPortDom.clientWidth;
    const offsetLeft = viewPortDom.offsetLeft;
    const viewPortSize = depthClone(this.state.viewPortSize);
    viewPortSize.viewPortHeight = viewPortHeight || window.innerHeight;
    viewPortSize.viewPortWidth = viewPortWidth;
    viewPortSize.offsetLeft = offsetLeft;
    this.setState({ viewPortSize });
    return viewPortSize;
  };

  render() {
    const {
      page,
      advancePage,
      scale,
      onAutoScale,
      option,
      appSize,
      appType,
      zoomByWheel,
      isStandalone,
      orientation,
      remarkTagId,
      theme,
      renderNavigationPanel,
    } = this.props;
    const { viewPortSize, spaceKey } = this.state;
    const style: React.CSSProperties = {
      background: '#ebedee',
    };
    if (isStandalone) {
      style.background = theme === 'dark' ? 'radial-gradient(#434f65 5%, #202735 75%)' : '#ebedee';
    }
    return (
      <div
        ref={this.selfRef}
        style={style}
        className={classNames('preview-view-port', { 'no-boundary': option.noBoundary, dragging: spaceKey })}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
      >
        <ScrollBars
          ref={this.scrollBar}
          className="scroll-box no-scale-scroll"
          theme="dark"
          thumbTheme="large"
          style={{ width: '100%', height: '100%' }}
          onScroll={this.handleScroll}
        >
          {page && viewPortSize.viewPortWidth > 0 && (
            <PreviewDevice
              ref={this.deviceRef}
              viewPortSize={viewPortSize}
              remarkTagId={remarkTagId}
              appType={appType}
              option={option}
              zoomByWheel={zoomByWheel}
              scale={scale}
              page={page}
              appSize={appSize}
              advancePage={advancePage}
              isStandalone={isStandalone}
              orientation={orientation}
              onAutoScale={onAutoScale}
              onScrollBarUpdate={this.setScrollBarWhenDeviceDidMountAndPageChange}
              onScrollBarRZ={this.setScrollBarRZ}
              devicePosition={this.state.devicePosition}
            />
          )}
        </ScrollBars>
        {/* {!isMobileAppType(appType) && this.dom?.shell.current?.renderFloatComps(scale, this.state.devicePosition)} */}
        {!!renderNavigationPanel &&
          renderNavigationPanel(viewPortSize.offsetLeft, viewPortSize.offsetLeft + viewPortSize.viewPortWidth)}
      </div>
    );
  }
}

export default ViewPort;
