import { IComponentSize } from '@fbs/rp/models/component';
import { IPathValue } from '@fbs/rp/models/value';
import * as SystemColors from '@consts/colors';
import { FillType } from '@fbs/rp/models/properties/fill';
import { StrokePosition } from '@fbs/rp/models/properties/stroke';
import { TextAlign, VerticalAlign as TextVerticalAlign } from '@fbs/rp/models/properties/textFormat';
import { IProperties } from '@fbs/rp/models/property';
import { IComponentItem } from '@libs/types';
import { UIComponent } from '@editor/comps';

import { getDefaultShadow } from '../helper';

interface IConfig {
  needFill?: boolean;
  closed?: boolean;
  properties?: Partial<IProperties>;
}

const defaultConfig: IConfig = {
  needFill: true,
  closed: true,
};

export function getShapePropertiesData(config: IConfig = defaultConfig) {
  const { needFill, closed, properties } = config;
  const template: IProperties = {
    fill: {
      disabled: !needFill,
      type: FillType.solid,
      color: SystemColors.DefaultWhiteFillColor,
    },
    stroke: {
      thickness: 1,
      color: SystemColors.DefaultStrokeColor,
      position: closed ? StrokePosition.inner : StrokePosition.center,
      disabled: false,
    },
    textFormat: {
      disabled: false,
      fontFamily: 'Microsoft YaHei',
      fontSize: 14,
      textAlign: TextAlign.center,
      color: SystemColors.DefaultTextColor,
      fontStyle: {
        bold: false,
        italic: false,
        underline: false,
        strike: false,
      },
      wrap: true,
      isMulti: true,
      verticalAlign: TextVerticalAlign.middle,
      letterSpace: 0,
    },
    padding: {
      disabled: false,
      hidden: false,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    shadow: getDefaultShadow(),
  };
  let result = template;
  if (properties) {
    const keys = Object.keys(template).reduce((acc, curr) => {
      if (!acc.includes(curr)) {
        acc.push(curr);
      }
      return acc;
    }, Object.keys(properties));
    type PropKey = keyof IProperties;
    keys.forEach((key) => {
      const prop = key as PropKey;
      if (result[prop] && properties[prop]) {
        result[prop] = {
          ...result[prop],
          ...properties[prop],
        };
      }
    });
  }
  return result;
}

export const shapeDefaultSize100_60 = {
  width: 100,
  height: 60,
  lockedRatio: false,
};

interface IDefaultFlowShapeSize {
  size100_60: IComponentSize;
  size60_60: IComponentSize;
  size60_80: IComponentSize;
}

export const DefaultFlowShapeSize: IDefaultFlowShapeSize = {
  size100_60: {
    width: 100,
    height: 60,
    lockedRatio: false,
  },
  size60_60: {
    width: 60,
    height: 60,
    lockedRatio: false,
  },
  size60_80: {
    width: 60,
    height: 80,
    lockedRatio: false,
  },
};

export function getShapeData(name: string, size: IComponentSize, value: IPathValue) {
  return `{
    type: 'path',
    fixContent: true,
    connectors: [],
    layout: {
        responsive: false,
        auto: true,
        fixedWidth: false,
        fixedHeight: false,
        horizontal: 'auto',
        vertical: 'auto',
    },
    properties: {},
    interaction: {},
    size: ${JSON.stringify(size)},
    states: {
    },
    name: '${name}',
    value: ${JSON.stringify(value)}
  }`;
}

export const flowBase: Partial<IComponentItem> = {
  editor: {
    onValidateAllowEditor: (comp: UIComponent, trigger: 'enter' | 'dblClick'): 'path' | 'value' => {
      return trigger === 'dblClick' ? 'path' : 'value';
    },
  },
};
