export const TableRowLineHeight = 36;

export enum TableSortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE',
}

export const MenuPaddingTB = 20;
export const MenuOptionLineHeight = 30;

export const MenuOptionDefaultLength = 4;
export const MenuOptionTextSize = 13;

export const MenuDefaultWidth = 120;

export const MenuPaddingLR = 40;

export const moreIconHeight = 30;
