import * as React from 'react';
import { cloneDeep } from 'lodash';

import * as GraphicsUtils from '@utils/graphicsUtils';

import { GrayColor } from '@/consts/colors';
import { IComponentProps } from '../../types';
import { UIContainerComponent } from '@editor/comps';
import { StyleHelper } from '@helpers/styleHelper';
import { PureColor } from '@/fbs/rp/models/properties/color';

import ArrowPathIcon from '../common/ArrowPathIcon';

import './index.scss';

interface IState {
  value: string;
}

class Numerice extends React.Component<IComponentProps, IState> {
  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      value: `${props.comp.value}`,
    };
  }

  handleUpClick = () => {
    this.doStep(1);
  };

  handleDownClick = () => {
    this.doStep(-1);
  };

  handleInputChange = (e: React.ChangeEvent) => {
    const el = e.target as HTMLInputElement;
    const value = el.value;
    if (/^-?\d*\.?\d*$/.test(value)) {
      this.setState({ value: el.value });
    }
  };

  private doStep(step: number) {
    const {
      comp: { disabled },
      isPreview,
    } = this.props;
    if (isPreview && disabled) {
      return;
    }
    let { value } = this.state;
    value = value || '0';
    // 处理value为.或者其他value无法转化为数值的情况
    value = Number.isNaN(Number(value)) ? '0' : value;
    const digitIndex = value.indexOf('.');
    let decimalLen = 0;
    let increment = Math.abs(step);
    if (digitIndex !== -1) {
      decimalLen = value.substr(digitIndex + 1).length;
      increment = 10 ** -decimalLen;
    }
    const v = parseFloat(value) + increment * step;
    this.setState({ value: v.toFixed(decimalLen) });
  }

  /*
        ArrowPathIcon,会存在UIComponent.resizeHandler2 计算中导致错误的position
        删除相关的2个元素样式left,top
  */
  handleComputerStyle = (style: React.CSSProperties) => {
    let newStyle = cloneDeep(style);
    delete newStyle.top;
    delete newStyle.left;
    return newStyle;
  };

  handleMouseLeave = (e: React.MouseEvent) => {
    const { comp, event } = this.props;
    comp.$data._currentState = undefined;
    event.onMouseLeave?.(e, comp);
  };

  render() {
    const { isPreview, comp } = this.props;
    const group = comp as UIContainerComponent;
    const { size, properties, opacity, components, disabled, value } = group;
    const { placeholder, placeHolderStyle } = properties;
    const parser = StyleHelper.createCSSStyleParser(properties);
    const style = {
      ...size,
      ...parser.getFillStyle(size),
      ...parser.getRadiusStyle(size),
      ...parser.getShadowStyle(),
    };

    const v = isPreview ? this.state.value : value;
    const textComp = group.getComponentByAlias('value') || components[1];
    const buttons = (group.getComponentByAlias('arrowTotalWrapper') || components[0]) as UIContainerComponent;
    const upBtn = (buttons.getComponentByAlias('upArrow') || buttons.components[0]) as UIContainerComponent;
    const downBtn = (buttons.getComponentByAlias('downArrow') || buttons.components[1]) as UIContainerComponent;
    const { size: textSize, position: textPosition, properties: textProperties } = textComp;

    const { textStyle: textProp } = textProperties;
    const { fontSize } = textProp || {};
    const txProp = {
      ...textProperties,
      textStyle: textProp ? { ...textProp, fontSize: (fontSize || 14) * 2 } : undefined,
    };
    const textParser = StyleHelper.createCSSStyleParser(txProp);

    const textStyle: React.CSSProperties = {
      width: textSize.width * 2,
      height: textSize.height * 2,
      left: textPosition.x,
      top: textPosition.y,
      ...textParser.getTextStyle(),
      ...parser.getPaddingStyleByFontScale(),
      lineHeight: `${textSize.height * 2}px`,
      pointerEvents: !isPreview || disabled ? 'none' : 'auto',
      opacity: StyleHelper.getOpacity(textComp.opacity),
    };

    const defaultColor: PureColor = (placeHolderStyle?.value as PureColor) || GrayColor;
    const placeHolderColor = GraphicsUtils.parseColorToString(defaultColor);
    // 在没有内容和没有占位文字的时候，演示应该显示为0，目前演示的时候显示为空
    const placeholderValue = placeholder?.value;
    let inputVal = `${v}`;
    // CCKL-1931需求和产品确认，这里其实是为空才是对的，不是为0
    // if (isPreview && !inputVal && !placeholderValue) {
    //   inputVal = '0';
    // }
    return (
      <div
        className="lib-comp-numeric"
        onMouseLeave={this.handleMouseLeave}
        style={{
          ...size,
          opacity: StyleHelper.getOpacity(opacity),
          ...parser.getRadiusStyle(size),
        }}
      >
        <div className="lib-comp-numeric-border" style={style} />
        <input
          className="lib-comp-numeric-value"
          value={inputVal}
          style={textStyle}
          readOnly={!isPreview}
          onChange={this.handleInputChange}
        />
        {!inputVal && (
          <div
            className="lib-comp-numeric-placeholder"
            style={{
              ...textStyle,
              color: placeHolderColor,
            }}
          >
            {placeholderValue}
          </div>
        )}
        <div className="lib-comp-numeric-buttons" style={{ ...buttons.size, left: buttons.position.x }}>
          {[upBtn, downBtn].map((btn, i) => {
            const pathComp = btn.components[0];
            const parser = StyleHelper.createCSSStyleParser(btn.properties);
            const buttonStyle = {
              ...btn.size,
              opacity: StyleHelper.getOpacity(btn.opacity),
              //position计算布局，在高度设置为0的时候存在问题，采用盒模型修正这个布局问题
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            };
            return (
              <div
                key={btn.id}
                className="lib-comp-numeric-button"
                style={{ ...buttonStyle, ...parser.getFillStyle() }}
                onClick={i ? this.handleDownClick : this.handleUpClick}
              >
                <ArrowPathIcon
                  onComputerStyle={this.handleComputerStyle}
                  comp={pathComp}
                  className="lib-comp-numeric-button-icon"
                />
                <div
                  className="lib-comp-numeric-button-border"
                  style={{ ...buttonStyle, ...parser.getStrokeStyle() }}
                />
              </div>
            );
          })}
        </div>
        <div
          className="lib-comp-numeric-border"
          style={{ ...parser.getRadiusStyle(size), ...parser.getStrokeStyle() }}
        />
      </div>
    );
  }
}

export default Numerice;
