import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { setTxConfig } from '@wemeet/src/helper/weMeet';

import { IAppWithNestedChildren } from '@fbs/rp/models/app';
import { INodeWithChildren } from '@fbs/rp/models/node';
import { IUserInfo } from '@/fbs/idoc/models/user';
import { Role } from '@/fbs/teamManagement';

import Theater from '../../../theater';
import MobileViewPort from './MobileViewPort';
import TreePanel from './TreePanel';

import './index.scss';

export interface IMobileIndexProp {
  appID: string;
  app: IAppWithNestedChildren;
  userInfo: IUserInfo | undefined;
  currentPage?: Theater;
  nextPage?: Theater;
  role?: Role;
  onPageSelect?: (node: INodeWithChildren) => void;
}

// const MobileIndex: React.FC<IMobileIndexProp> = ({
//   currentPage,
//   app,
//   userInfo,
//   nextPage,
//   onPageSelect,
// }: IMobileIndexProp) => (
//   <div className="mobile-preview-container">
//     <MobileViewPort page={currentPage} appSize={app.size} advancePage={nextPage} />
//     <TreePanel app={app} userInfo={userInfo} selected={currentPage?.doc.pageID} onPageSelect={onPageSelect} />
//   </div>
// );

window.pageScale = 1;

class MobileIndex extends React.Component<IMobileIndexProp> {
  constructor(props: IMobileIndexProp) {
    super(props);
  }

  private mobileViewPort: React.RefObject<MobileViewPort> = React.createRef();
  private containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  private resizeObserver?: ResizeObserver;

  /**
   * 获取整体缩放
   */
  get globalScale() {
    return this.mobileViewPort.current?.globalScale || 1;
  }

  componentDidMount() {
    const observeTarget = this.containerRef.current;
    if (observeTarget) {
      this.resizeObserver = new ResizeObserver(() => {
        this.handleWindowResizeWhenDeviceRotate();
      });
      this.resizeObserver.observe(observeTarget);
    }
    // 腾讯会议鉴权
    setTxConfig(this.props.userInfo?.token ?? '');
  }

  componentWillUnmount() {
    const observeTarget = this.containerRef.current;
    observeTarget && this.resizeObserver?.unobserve(observeTarget);
  }

  handleWindowResizeWhenDeviceRotate = () => {
    this.forceUpdate();
  };

  render() {
    const { currentPage, app, userInfo, nextPage, role, onPageSelect } = this.props;
    return (
      <div ref={this.containerRef} className="mobile-preview-container">
        <MobileViewPort
          ref={this.mobileViewPort}
          page={currentPage}
          appSize={app.size}
          advancePage={nextPage}
          // orientation={app.orientation ?? 'portrait'}
        />
        <TreePanel
          app={app}
          userInfo={userInfo}
          selected={currentPage?.doc.pageID}
          onPageSelect={onPageSelect}
          role={role}
        />
      </div>
    );
  }
}

export default MobileIndex;
