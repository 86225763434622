import { UIComponent, UISymbolComponent, UIContainerComponent, UICompoundPathComponent } from '@/editor/comps';
import Doc from '@/editor/document';
import { IComponentData } from '@/fbs/rp/models/component';
import { CGroup, CCanvasPanel, CSymbol } from '@/libs/constants';

const managerMap = new Map<string, RefactorRemarkManager>();
const remarkFocus = new Map<string, boolean>();

const interceptors = {
  delete(this: Map<string, UIComponent>, effectFn: Function, key: string) {
    const result = this.delete(key);
    effectFn();
    return result;
  },
  set(this: Map<string, UIComponent>, effectFn: Function, key: string, value: UIComponent) {
    const result = this.set(key, value);
    effectFn();
    return result;
  },
};

function createProxy<T extends object>(target: T, effectFn: () => void) {
  return new Proxy(target, {
    get(target, key, receiver) {
      return key in interceptors
        ? Reflect.get(interceptors, key, receiver).bind(target, effectFn)
        : Reflect.get(target, key, receiver).bind(target);
    },
  });
}

// const initCreateSymbol = new Map<string, Map<string, boolean>>(); // symbol在画板中第一次生成，需重写remark时间，以此做记录。见updateRemark方法

export default class RefactorRemarkManager {
  static instance: RefactorRemarkManager;
  static create(doc: Doc) {
    // symbol编辑面板有自己的id，要避免混用
    const pageID = doc.realAppID + doc.pageID + doc._id;
    managerMap.set(pageID, new RefactorRemarkManager(doc));
    return managerMap.get(pageID)!;
  }

  static getInstance(doc?: Doc) {
    if (!doc) {
      return undefined;
    }
    const pageID = doc.realAppID + doc.pageID + doc._id;
    const instance = managerMap.get(pageID);
    if (!instance) {
      return RefactorRemarkManager.create(doc);
    }
    return instance;
  }

  static getInstanceByPageID(id?: string): RefactorRemarkManager | null {
    if (!id) {
      return null;
    }
    return managerMap.get(id) || null;
  }

  static remarkFocus(id: string, bool: boolean) {
    remarkFocus.set(id, bool);
  }
  static getRemarkFocus(id: string) {
    return remarkFocus.get(id);
  }
  static cleanRemarkFocus() {
    remarkFocus.clear();
  }

  compToRemarkMap = createProxy(new Map<string, UIComponent>(), () => {
    this.publish();
  });

  private listeners: Set<Function> = new Set();

  private _maxNumber: number = 0;

  public get maxNumber(): number {
    return this._maxNumber;
  }

  public incMaxNumber() {
    this._maxNumber++;
    return this._maxNumber;
  }

  private symbolRemarkMap: Map<string, UIComponent[]> = new Map<string, UIComponent[]>();

  constructor(doc: Doc) {
    // NOTE: 处理出现多个页面备注问题
    this.updateRemark(doc.mainArtboard.realID, doc.mainArtboard);
    doc.artboardsFragments.forEach((fragment) => {
      this.retrieveChildrenRemark(fragment.components);
    });
  }

  subscribe(listener: Function) {
    this.listeners.add(listener);
  }

  unsubscribe(listener: Function) {
    this.listeners.delete(listener);
  }

  publish = () => {
    for (const listener of this.listeners) {
      listener();
    }
  };

  reload(doc: Doc) {
    doc.artboardsFragments.forEach((fragment) => {
      this.retrieveChildrenRemark(fragment.components);
    });
  }

  /**
   * 仅更新组件的备注索引为最大值
   * @param compData 组件数组
   * todo: ts组件类型与core未统一，一边是UIComponent[]一边是ICompontData
   */
  public upRemarkIndex = (compData: any[]) => {
    const isContainerComp = (type: string) => {
      return [CGroup, CCanvasPanel, CSymbol].includes(type);
    };
    compData.forEach((comp) => {
      if (isContainerComp(comp.type) && comp.components) {
        this.upRemarkIndex(comp.components);
      }
      // 兼容-旧数据的备注内容是字符串
      if (comp.remark && typeof comp.remark !== 'string') {
        this.incMaxNumber();
        comp.remark.num = this.maxNumber;
      }
    });
    return compData;
  };

  /**
   * 仅对symbol组件操作
   * @param comps
   * @param id
   */
  public toSymbolUpRemark = (comps: UIComponent[], id: string) => {
    // 删除旧的symbol备注
    this.symbolRemarkMap.get(id)?.forEach((comp) => {
      this.removeRemark(comp.id);
    });
    this.symbolRemarkMap.set(id, []);
    // 更新新的symbol备注
    comps.forEach((comp: UIComponent) => {
      if (comp.remark) {
        this.symbolRemarkMap.get(id)?.push(comp);
        this.updateRemark(comp.realID, comp);
      }
      if (comp instanceof UIContainerComponent) {
        this.toSymbolUpRemark(comp.components, id);
      }
    });
  };

  /**
   * 仅对symbol组件操作
   * @param comps
   * @param id
   */
  public toRemoveSymbolRemark = (comps: UIComponent[], id: string) => {
    // 删除旧的symbol备注
    this.symbolRemarkMap.get(id)?.forEach((comp) => {
      this.removeRemark(comp.id);
    });
    this.symbolRemarkMap.delete(id);
    // 更新新的symbol备注
    comps.forEach((comp: UIComponent) => {
      if (comp instanceof UIContainerComponent && comp.type === 'symbol') {
        this.toRemoveSymbolRemark(comp.components, comp.id);
      }
    });
  };

  public retrieveChildrenRemark = (comps: UIComponent[]) => {
    comps.forEach((comp) => {
      this.retrieveComponentRemark(comp);
    });
  };

  public retrieveComponentRemark = (comp: UIComponent) => {
    const { id, remark } = comp;
    remark && this.updateRemark(id, comp);

    // if (comp instanceof UISymbolComponent || comp instanceof UICompoundPathComponent) {
    //   return;
    // }

    if (comp instanceof UIContainerComponent) {
      this.retrieveChildrenRemark(comp.components);
      return;
    }
  };

  public removeChildrenRemark = (comps: UIComponent[]) => {
    comps.forEach((comp) => {
      this.removeComponentRemark(comp);
    });
  };

  private removeComponentRemark = (comp: UIComponent) => {
    const { id, remark } = comp;
    remark && this.removeRemark(id);

    if (comp instanceof UISymbolComponent || comp instanceof UICompoundPathComponent) {
      return;
    }

    if (comp instanceof UIContainerComponent) {
      this.removeChildrenRemark(comp.components);
      return;
    }
  };

  // TODO memo
  // TODO 这个算法好像还有点问题
  get compsWithRemark() {
    return Array.from(this.compToRemarkMap.values()).sort((prev, next) => {
      let num1 = prev.remark ? prev.remark.num || -1 : -1;
      let num2 = next.remark ? next.remark.num || -1 : -1;
      if (prev.isArtboard) {
        // 主画板备注永远在前面
        return 0;
      }
      if (next.isArtboard) {
        // 主画板备注永远在前面
        return 0;
      }
      // // 判断有问题
      // if (prev.nearestSymbolComponent) {
      //   // symbol先放队列后面
      //   return 1;
      // }
      // if (next.nearestSymbolComponent) {
      //   // symbol先放队列后面
      //   return 1;
      // }
      if (num1 < 0) {
        // 旧数据先放队列后面
        return 1;
      }
      if (num2 < 0) {
        // 旧数据先放队列后面
        return 1;
      }
      if (num1 > num2) {
        return 1;
      }
      if (num1 < num2) {
        return -1;
      }
      return 0;
    });
  }

  // TODO rename
  // 删除组件
  removeRemark(compId: string) {
    this.compToRemarkMap.delete(compId);
  }

  private getComps(comps: UIComponent[]) {
    let ids: string[] = [];
    comps.forEach((comp) => {
      if (comp instanceof UIContainerComponent) {
        ids.push(...this.getComps(comp.components));
      }
      ids.push(comp.id);
    });
    return ids;
  }

  // 删除因symbol产生的无效备注 ---- 无效 todo：高级编辑应用后拿到的remarkMap不是最新的
  removeInvalidRemark(comp: IComponentData | undefined) {
    if (!comp) {
      return false;
    }
    if (comp.components) {
      comp.components.forEach((item) => {
        this.removeInvalidRemark(item);
      });
    } else {
      this.removeRemark(comp._id);
    }
  }

  /**
   * 更新备注缓存数据
   * @param {string} compId
   * @param {UIComponent} comp
   * todo：这里会拿到所有的symbol，没法确定是哪个symbol做了备注，进入高级编辑后也判断不了它是symbol类型
   */
  updateRemark(compId: string, comp: UIComponent) {
    if (this.compToRemarkMap.has(compId)) {
      return;
    }

    // 复合类型 symbol，不显示备注序号
    if ((comp.parent && comp.parent?.type === 'compoundPath') || comp.nearestSymbolComponent) {
      return;
    }

    comp.remark && (this._maxNumber = Math.max(comp.remark.num, this._maxNumber));
    this.compToRemarkMap.set(compId, comp);
  }

  getRemark(compId: string) {
    return this.compToRemarkMap.get(compId);
  }
}
