import * as React from 'react';
import classnames from 'classnames';
import Radio from './Radio';

import './index.scss';

export { Radio };

export interface IRadioItemProp {
  id: string | number | boolean;
  text: string;
  disabled?: boolean;
}

export interface IRadioButtonProp {
  data: Array<IRadioItemProp>;
  selected: number | string | boolean;
  them?: 'light' | 'dark';
  layout?: 'vertical' | 'horizontal';
  className?: string;
  onChange?: (id: number | string | boolean) => void;
}

class RadioButtonGroup extends React.Component<IRadioButtonProp, { selected: number | string | boolean }> {
  constructor(props: IRadioButtonProp) {
    super(props);
    this.state = {
      selected: props.selected,
    };
  }

  onItemClicked(id: number | string | boolean) {
    const { onChange } = this.props;
    this.setState({
      selected: id,
    }, () => {
      onChange && onChange(id);
    });
  }

  render() {
    const { them, layout, className } = this.props;
    return (
      <div className={classnames('dsm-c-rp-radio-button', className, layout || 'horizontal')}>
        {this.props.data.map((item, index) => (
          <Radio
            key={index}
            text={item.text}
            them={them}
            checked={item.id === this.state.selected}
            disabled={item.disabled}
            onClick={this.onItemClicked.bind(this, item.id)}
          />
        ))}
      </div>
    );
  }
}


export default RadioButtonGroup;

