import { groupBy, isEmpty, mapValues, maxBy } from 'lodash';

import { depthClone, max, round } from '@utils/globalUtils';
import { ISize } from '@utils/boundsUtils';

import { IComponentData, IComponentStateData } from '@fbs/rp/models/component';
import ILayout from '@fbs/rp/models/properties/layout';

import { UIComponent } from '@editor/comps';

export function isContainer(comp: IComponentData): boolean {
  return ['artboard', 'group', 'panel', 'stack-panel', 'wrap-panel'].indexOf(comp.type) !== -1 || comp.sealed || false;
}

function getComponentCurrentData(component: IComponentData): IComponentStateData {
  let data: IComponentStateData = component;
  let state = component._currentState;
  if (!state) {
    if (component.disabled) {
      state = 'disabled';
    } else if (component.selected) {
      state = 'checked';
    }
  }

  if (state && component.states && component.states[state]) {
    data = component.states[state];

    const { size, opacity, hidden, properties, value, ...other } = component;
    return {
      ...other,
      size: size || data.size,
      opacity: opacity || data.opacity,
      hidden: hidden || data.hidden,
      value: value || data.value,
      properties: {
        ...properties,
        ...data.properties,
      },
    };
  }
  return data;
}

function createTempComp(data: IComponentData): UIComponent {
  return new UIComponent(data);
}
/**
 * 重新排列 stack panel 中的组件和计算 stack panel 自身的尺寸
 */
export function reArrangeComponentsOfStackPanel(component: IComponentData, layout?: ILayout): ISize {
  if (!component.components || component.components.length === 0) {
    return { width: 0, height: 0 };
  }
  const data = getComponentCurrentData(component);
  const properties = { ...component.properties, ...data.properties };
  const { direction, verticalAlign, horizontalAlign, horizontalGap, verticalGap } =
    layout ||
    properties.layout ||
    ({
      direction: 'vertical',
      verticalAlign: 'top',
      horizontalAlign: 'center',
      horizontalGap: 0,
      verticalGap: 0,
    } as ILayout);

  let maxSize = 0;
  // 这里只处理的纵向排列
  const size: {
    width: number;
    height: number;
    lockedRatio?: boolean;
  } = component.components!.reduce(
    (acc, curr) => {
      const component = createTempComp(curr);
      // 旋转之后组件轮廓的size
      const { width: compWidth, height: compHeight } = component.getViewBoundsInParent();
      if (direction === 'vertical') {
        maxSize = Math.max(maxSize, compWidth);
      } else {
        maxSize = Math.max(maxSize, compHeight);
      }
      return {
        width: direction === 'vertical' ? Math.max(acc.width, compWidth) : acc.width + compWidth,
        height: direction === 'vertical' ? acc.height + compHeight : Math.max(acc.height, compHeight),
        lockedRatio: acc.lockedRatio,
      };
    },
    {
      width: 0,
      height: 0,
      lockedRatio: component.size.lockedRatio,
    },
  );
  let currentHeight = 0;
  let currentWidth = 0;
  let left = 0;
  let top = 0;
  component.components!.forEach((comp, idx) => {
    // let compWidth;
    // let compHeight;
    const component = createTempComp(comp);
    // 旋转之后组件轮廓的size
    let { width: compWidth, height: compHeight } = component.getViewBoundsInParent();
    // compWidth = compSize.width;
    // compHeight = compSize.height;

    // const leftOffset = 0.5 * (compWidth - comp.size.width);
    // const topOffset = 0.5 * (compHeight - comp.size.height);

    let size1 =
      (comp._currentState && comp.states[comp._currentState] && comp.states[comp._currentState].size) || comp.size;
    if (direction === 'vertical') {
      top = currentHeight;
      // top += topOffset;
      top += (verticalGap || 0) * idx;
      if (horizontalAlign === 'center') {
        left = (size.width - size1.width) / 2;
      } else if (horizontalAlign === 'right') {
        // left = size.width - comp.size.width - leftOffset;
        left = size.width - size1.width;
      } else if (horizontalAlign === 'left') {
        // left = leftOffset;
      }
    } else {
      left = currentWidth;
      // left += leftOffset;
      left += (horizontalGap || 0) * idx;
      if (verticalAlign === 'middle') {
        top = (size.height - size1.height) / 2;
      } else if (verticalAlign === 'bottom') {
        // top = size.height - comp.size.height - topOffset;
        top = size.height - size1.height;
      } else if (verticalAlign === 'top') {
        // top = topOffset;
      }
    }
    comp.position = {
      x: round(left),
      y: round(top),
    };
    if (direction === 'vertical') {
      currentHeight += compHeight;
    } else {
      currentWidth += compWidth;
    }
  });
  if (direction === 'vertical') {
    size.height =
      (currentHeight ? currentHeight : size.height) + (verticalGap || 0) * (component.components!.length - 1);
  } else if (direction === 'horizontal') {
    size.width = (currentWidth ? currentWidth : size.width) + (horizontalGap || 0) * (component.components!.length - 1);
  }
  component.size = size;
  return size;
}

export function reArrangeComponentOfWrapPanel(wrapPanelContainer: IComponentData) {
  if (!wrapPanelContainer.components || !wrapPanelContainer.components.length) {
    return { width: 0, height: 0, lockedRatio: wrapPanelContainer.size.lockedRatio };
  }
  let properties = wrapPanelContainer.properties;
  let state = wrapPanelContainer._currentState;
  if (!state) {
    if (wrapPanelContainer.disabled) {
      state = 'disabled';
    } else if (wrapPanelContainer.selected) {
      state = 'checked';
    }
  }
  if (state && wrapPanelContainer.states && wrapPanelContainer.states[state]) {
    properties = { ...properties, ...wrapPanelContainer.states[state].properties };
  }

  const { direction, verticalAlign, horizontalAlign, horizontalGap, verticalGap } = properties.layout || {
    direction: 'vertical',
    verticalAlign: 'top',
    horizontalAlign: 'center',
    horizontalGap: 0,
    verticalGap: 0,
  };
  let { width, height, lockedRatio } = wrapPanelContainer.size;

  // fixme matt 这里要通过构建临时的UIComponentData的方式来计算每个元素的坐标及WrapPanel的尺寸，现在以下的代码是有问题的

  const cellComps: { size: { width: number; height: number }; component: UIComponent }[][] = getCellComps(
    wrapPanelContainer,
  );

  const { rowOrColumnSize, maxHeight, maxWidth, maxColumnWidth } = getRowOrColumnSize(cellComps, wrapPanelContainer);
  // 计算最大宽度或高度、每行或每列的宽或高

  let left = 0;
  let top = 0;
  let columnLeft = 0;
  let rowTop = 0;
  // 更新子布局属性
  cellComps.forEach((row, rowIndex) => {
    const cellSize = rowOrColumnSize[rowIndex];
    row.forEach((cell, columnIndex) => {
      const maxWidth = maxColumnWidth[columnIndex];
      const { component: comp, size } = cell;
      if (direction === 'vertical') {
        if (horizontalAlign === 'center') {
          left = columnLeft + (cellSize - size.width) / 2;
        } else if (horizontalAlign === 'right') {
          left = columnLeft + cellSize - size.width;
        } else {
          left = columnLeft;
        }
      } else {
        if (verticalAlign === 'middle') {
          top = rowTop + (cellSize - size.height) / 2;
        } else if (verticalAlign === 'bottom') {
          top = rowTop + cellSize - size.height;
        } else {
          top = rowTop;
        }
      }
      const data = comp.toJSON();
      const { width, height } = data.size;
      const offsetX = (size.width - width) / 2;
      const offsetY = (size.height - height) / 2;
      data.position = {
        x: left + offsetX,
        y: top + offsetY,
      };
      if (direction === 'vertical') {
        top += size.height;
        top += verticalGap || 0;
      } else {
        left += maxWidth;
        left += horizontalGap || 0;
      }
    });
    if (direction === 'vertical') {
      columnLeft += cellSize + (horizontalGap || 0);
      top = 0;
    } else {
      rowTop += cellSize + (verticalGap || 0);
      left = 0;
    }
  });

  let newWidth = 0;
  let newHeight = 0;

  if (direction === 'vertical') {
    newHeight = Math.max(
      height,
      wrapPanelContainer.components.reduce((acc, curr) => {
        return Math.max(acc, curr.size.height);
      }, 0),
    );
    wrapPanelContainer.size = { height: newHeight, width: maxWidth };
  } else {
    newWidth = Math.max(
      width,
      wrapPanelContainer.components.reduce((acc, curr) => {
        return Math.max(acc, curr.size.width);
      }, 0),
    );
    wrapPanelContainer.size = { width: newWidth, height: maxHeight, lockedRatio };
  }
  return { width: newWidth, height: maxHeight, lockedRatio };
}

export type CellInfo = { id: string; rowIndex: number; columnIndex: number; comp: IComponentData }[];

export function getCellComps(wrapPanelContainer: IComponentData) {
  let { width, height } = wrapPanelContainer.size;
  let left = 0;
  let top = 0;
  // fixme matt 这里要通过构建临时的UIComponentData的方式来计算每个元素的坐标及WrapPanel的尺寸，现在以下的代码是有问题的

  const cellComps: { size: { width: number; height: number }; component: UIComponent }[][] = [[]];
  // 拆分数据成行列

  wrapPanelContainer.components?.forEach((compData, i) => {
    const comp = createTempComp(compData);
    const { width: compWidth, height: compHeight } = comp.getViewBoundsInParent();
    const size = { width: compWidth, height: compHeight };
    const cell = { size, component: comp };
    let properties = wrapPanelContainer.properties;
    const { direction, horizontalGap, verticalGap } = properties.layout || {
      direction: 'vertical',
      verticalAlign: 'top',
      horizontalAlign: 'center',
      horizontalGap: 0,
      verticalGap: 0,
    };
    if (direction === 'vertical') {
      // 满一列换列
      if (top + compHeight > height && i > 0) {
        top = 0;
        cellComps.push([]);
      }
      cellComps[cellComps.length - 1].push(cell);
      top += compHeight + (verticalGap || 0);
    } else {
      // 满一行换行
      //先计算出下一个cell位于第几列，然后得到这一列的最大宽度，作为每一个组件的占位宽度
      const nextLineIndex = cellComps[cellComps.length - 1].length;
      let maxColumnWidth = compWidth;
      for (let rowIndex = 0; rowIndex < cellComps.length; rowIndex++) {
        const row = cellComps[rowIndex];
        const cell = row[nextLineIndex];
        if (cell) {
          maxColumnWidth = max(maxColumnWidth, cell.size.width);
        }
      }
      if (left + maxColumnWidth > width && i > 0) {
        left = 0;
        cellComps.push([]);
      }
      cellComps[cellComps.length - 1].push(cell);
      // 更新left，宽度应该为当前cell所在列的最大cell宽

      left += maxColumnWidth + (horizontalGap || 0);
    }
  });

  return cellComps;
}

export function getRowOrColumnSize(
  cellComps: { size: { width: number; height: number }; component: UIComponent }[][],
  wrapPanelContainer: IComponentData,
) {
  let maxWidth = 0;
  let maxHeight = 0;

  let maxColumnWidth = [];
  const lineNum = cellComps.reduce((acc, curr) => {
    return max(acc, curr.length);
  }, 0);
  for (let columnIndex = 0; columnIndex < lineNum; columnIndex++) {
    let maxWidth = 0;
    for (let rowIndex = 0; rowIndex < cellComps.length; rowIndex++) {
      const row = cellComps[rowIndex];
      const cell = row[columnIndex];
      if (cell) {
        maxWidth = max(maxWidth, cell.size.width);
      }
    }
    maxColumnWidth.push(maxWidth);
  }
  const rowOrColumnSize: number[] = [];
  let properties = wrapPanelContainer.properties;
  const { direction, horizontalGap, verticalGap } = properties.layout || {
    direction: 'vertical',
    verticalAlign: 'top',
    horizontalAlign: 'center',
    horizontalGap: 0,
    verticalGap: 0,
  };
  // 计算最大宽度或高度、每行或每列的宽或高
  const len = cellComps.length;
  cellComps.forEach((row, index) => {
    let rowHeight = 0;
    let columnWidth = 0;
    row.forEach((cell) => {
      const { size } = cell;
      if (direction === 'vertical') {
        columnWidth = Math.max(columnWidth, size.width);
      } else {
        rowHeight = Math.max(rowHeight, size.height);
      }
    });
    if (direction === 'vertical') {
      rowOrColumnSize.push(columnWidth);
      maxWidth += columnWidth;
      if (index < len - 1) {
        maxWidth += horizontalGap || 0;
      }
    } else {
      rowOrColumnSize.push(rowHeight);
      maxHeight += rowHeight;
      if (index < len - 1) {
        maxHeight += verticalGap || 0;
      }
    }
  });
  return { rowOrColumnSize, maxHeight, maxWidth, maxColumnWidth };
}

// eslint-disable-next-line
export function reArrangeSelectPanel(component: IComponentData) {}

export function reArrangeGridPanel(gridContainer: IComponentData) {
  const { cells, containerSize } = getCellInfo(gridContainer);
  const newChildrenPosition = getGridChildrenPosition(gridContainer);
  gridContainer.components?.forEach((comp) => {
    const id = comp._id;
    const cell = cells.find((cell) => cell.id === id);
    if (cell) {
      const { rowIndex, columnIndex } = cell;
      const coordinate = `${rowIndex}-${columnIndex}`;
      comp.position = newChildrenPosition[coordinate];
    }
  });
  gridContainer.size = containerSize;
}

export function getCellInfo(container: IComponentData, newChild?: IComponentData[]) {
  const result: {
    cells: { id: string; rowIndex: number; columnIndex: number; comp: IComponentData }[];
    lineWidth: { [key: string]: number };
    rowHeight: { [key: string]: number };
    containerSize: { width: number; height: number };
  } = {
    cells: [],
    lineWidth: {},
    rowHeight: {},
    containerSize: depthClone(container.size),
  };
  const columnCount = container.properties.cell?.columnCount;
  const newChildren = (newChild ? container.components?.concat(newChild) : container.components) || [];
  if (columnCount && container.components) {
    // 1.根据列数，将所有的子依次塞入，得到行列分布。
    result.cells = newChildren.reduce((acc, compData, childIndex) => {
      const rowIndex = Math.floor(childIndex / columnCount);
      const columnIndex = childIndex % columnCount;
      acc.push({ id: compData._id, rowIndex, columnIndex, comp: compData });
      return acc;
    }, [] as CellInfo);
    // 2. 用每列中的最宽元素作为该列的宽度
    const lineDistribution = groupBy(result.cells, 'columnIndex');
    result.lineWidth = mapValues(lineDistribution, (allLineItems) => {
      return maxBy(allLineItems, 'comp.size.width')?.comp.size.width!;
    });
    // 3. 用每行最高的元素作为该行的高度
    const rowDistribution = groupBy(result.cells, 'rowIndex');
    result.rowHeight = mapValues(rowDistribution, (allLineItems) => {
      return maxBy(allLineItems, 'comp.size.height')?.comp.size.height!;
    });
    // 4. 根据每列每行的高度宽度 计算去容器的大小
    // eslint-disable-next-line no-unused-vars
    result.containerSize.height = Object.entries(result.rowHeight).reduce((accHeight, [key, rowHeight], index) => {
      accHeight += rowHeight + (index === 0 ? 0 : container.properties.layout?.verticalGap || 0);
      return accHeight;
    }, 0);
    // eslint-disable-next-line no-unused-vars
    result.containerSize.width = Object.entries(result.lineWidth).reduce((accHeight, [key, lineWidth], index) => {
      accHeight += lineWidth + (index === 0 ? 0 : container.properties.layout?.horizontalGap || 0);
      return accHeight;
    }, 0);
  }
  return result;
}

export function getAcumulatePositionX(lineWidth: { [key: string]: number }, horizontalGap: number) {
  return Object.entries(lineWidth).reduce(
    (acc, [columnIndex], index) => {
      acc[index] = acc.accumulate;
      acc.accumulate += lineWidth[columnIndex] + horizontalGap;
      return acc;
    },
    { accumulate: 0 } as { [key: string]: number },
  );
}

export function getAcumulatePositionY(rowHeight: { [key: string]: number }, verticalGap: number) {
  return Object.entries(rowHeight).reduce(
    (acc, [rowIndex], index) => {
      acc[index] = acc.accumulate;
      acc.accumulate += rowHeight[rowIndex] + verticalGap;
      return acc;
    },
    { accumulate: 0 } as { [key: string]: number },
  );
}

export function getGridChildrenPosition(gridContainer: IComponentData) {
  const { cells, lineWidth, rowHeight } = getCellInfo(gridContainer);
  const map: { [key: string]: { x: number; y: number } } = {};
  const verticalGap = gridContainer.properties.layout?.verticalGap || 0;
  const horizontalGap = gridContainer.properties.layout?.horizontalGap || 0;
  if (gridContainer.properties.cell?.ratioWidth) {
    console.log('暂未实现');
  } else {
    const accumulatePositionX = getAcumulatePositionX(lineWidth, horizontalGap);
    cells.forEach((cell) => {
      const { rowIndex, columnIndex } = cell;
      if (isEmpty(map[`${rowIndex}-${columnIndex}`])) {
        map[`${rowIndex}-${columnIndex}`] = { x: 0, y: 0 };
      }
      map[`${rowIndex}-${columnIndex}`].x = accumulatePositionX[columnIndex];
    });
  }
  if (gridContainer.properties.cell?.rowHeight) {
    console.log('暂未实现');
  } else {
    const accumulatePositionY = getAcumulatePositionY(rowHeight, verticalGap);
    cells.forEach((cell) => {
      const { rowIndex, columnIndex } = cell;
      if (isEmpty(map[`${rowIndex}-${columnIndex}`])) {
        map[`${rowIndex}-${columnIndex}`] = { x: 0, y: 0 };
      }
      const offset = (rowHeight[cell.rowIndex] - cell.comp.size.height) / 2;
      map[`${rowIndex}-${columnIndex}`].y = accumulatePositionY[rowIndex] + offset;
    });
  }
  return map;
}
