import * as React from 'react';
import { Dialog, Button } from '@/dsm';
import i18n from '@/i18n';

import * as ShareChangeImage from '@assets/image/preview/share-change-tip-image.jpg';

import './index.scss';

interface IProps {
  onClose: () => void;
}
interface IState {}
export default class ShareChangeTipDialog extends React.Component<IProps, IState> {
  render(): React.ReactNode {
    const { onClose } = this.props;
    return (
      <Dialog onClose={onClose} backFade containerClassName="share-change-tip-container">
        <div className="share-change-tip-title">{i18n('tips.featureChangeTitle')}</div>
        <div className="share-change-tip-content">
          <img src={ShareChangeImage} alt="" />
          <div className="tip-text">{i18n('preview.shareChangeTip')}</div>
        </div>

        <div className="share-change-tip-button">
          <Button theme="dialog" width="90px" height="28px" onClick={onClose}>
            {i18n('general.gotIt')}
          </Button>
        </div>
      </Dialog>
    );
  }
}
