export enum MouseButton {
  Left = 0,
  Wheel = 1,
  Right = 2,
  Back = 3,
  Forward = 4,
}

export enum MouseButtons {
  Null = 0,
  Left = 1,
  Right = 2,
  Wheel = 4,
  Back = 8,
  Forward = 16,
}
