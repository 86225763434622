import axios from 'axios';

import { IUserInfo } from '@fbs/idoc/models/user';
import DocCookies from '@helpers/cookie';

import { axDelete, get, post, patch } from './helper';

/**
 * 获取用户信息
 * @returns {Promise<IUserInfo>}
 */
export async function getUserInfo(): Promise<IUserInfo> {
  return get<IUserInfo>(`/user/info?rnd=${new Date().getTime()}`);
}

function getEmailName(account: string) {
  const index = account.indexOf('@');
  return account.slice(0, index);
}

/**
 * 登录
 * @param account
 * @param password
 */
export async function login(account: string, password: string) {
  return await post<{ token: string }>(`/user/signin`, {
    email: account,
    password,
    source: 'rp',
    name: getEmailName(account),
  });
}

// export async function registerUser(account: string, password: string) {
//   return await post(`/user/signup`, { email: account, password, source: 'rp', name: getEmailName(account) });
// }
export async function registerUser(account: string, password: string) {
  return await post(`/user/signup`, { email: account, password, source: 'rp', name: getEmailName(account) });
}

export async function signWithUserAndPassword(data: {
  email: string;
  password: string;
}): Promise<{ token: string; user: IUserInfo }> {
  return post<{ token: string; user: IUserInfo }>(`/user/signin`, data);
}

/**
 * 退出登录
 */
export async function logout(): Promise<undefined> {
  return axDelete(`/user/logout`);
}

/**
 * 桌面端注册账号
 * @param data 账号信息
 */
export async function register(data: {
  email: string;
  name?: string;
  password: string;
  profession: string;
  source: string;
}): Promise<IUserInfo> {
  return post<IUserInfo>(`/user/signUpDesktop`, data);
}

export async function getWXQRCode(): Promise<{ ticket: string; QRCodeURL: string }> {
  return get<{ ticket: string; QRCodeURL: string }>('/user/getWxQRCode');
}

export async function checkWXLoginState(): Promise<IUserInfo> {
  return get<IUserInfo>('/user/wx-check');
}

export async function signWithLark(): Promise<IUserInfo> {
  return post<IUserInfo>('/lark/signinfromweb');
}

export function sigin(token: string) {
  if (window.debug) {
    const apis = window.apis;
    apis.Token = token;
    window.apis = apis;
    localStorage.setItem('apis', JSON.stringify(apis));
  }
  axios.defaults.headers['Authorization'] = `Bearer ${token}`;
}

export async function cancelUnsubscribe() {
  return patch(`/user/cancelUnsubscribe`, {});
}

export function clearTokenWithSignout() {
  sigin('');
  axios.defaults.headers['Authorization'] = undefined;
  DocCookies.cookie.removeItem('ds.sid.sig');
  DocCookies.cookie.removeItem('ds.sid');
}
