import { TextAlign, VerticalAlign } from '@/fbs/rp/models/properties/textFormat';
import { ITextStyle } from '../type';

export const styleWrapper = (content: string, name?: string): string => {
  if (name) {
    return content ? `<style  name="${name}">${content}</style>` : '';
  }
  return content ? `<style>${content}</style>` : '';
};

export const makeFlexStyle = (textStyle?: ITextStyle): string => {
  const { textAlign, verticalAlign } = textStyle ?? {};

  // 居中样式
  const styles = ['display: flex'];

  switch (textAlign) {
    case TextAlign.center:
      styles.push('justify-content: center');
      break;
    case TextAlign.left:
      styles.push('justify-content: flex-start');
      break;
    case TextAlign.right:
      styles.push('justify-content: flex-end');
      break;
    default:
      styles.push('justify-content: center');
  }

  switch (verticalAlign) {
    case VerticalAlign.middle:
      styles.push('align-items: center');
      break;
    case VerticalAlign.bottom:
      styles.push('align-items: flex-end');
      break;
    case VerticalAlign.top:
      styles.push('align-items: flex-start');
      break;
    default:
      styles.push('align-items: center');
  }

  return styles.join(';');
};

export const makeFitStyle = () => {
  return `margin:0;padding:0;width:100%;height: 100%;`;
};

// 驼峰转连字符驼峰转连字符 kebab
export const pascalToKebab = (str: string) => {
  return str.replace(/([A-Z])/g, '-$1').toLowerCase();
};
const isNumber = (value: number | string): value is number => {
  // 如果返回 true 则说明 传入的 value 是 is 后面的type
  return !isNaN(Number(value));
};

//
export const makeCssText = (styleObj?: ITextStyle): string => {
  if (!styleObj) {
    return '';
  }
  // 删除冲突样式
  delete styleObj.whiteSpace;
  const styles: string[] = [];
  Object.keys(styleObj).forEach((key) => {
    const value = styleObj[key];
    if (typeof value === 'number' || typeof value === 'string') {
      if (isNumber(value)) {
        styles.push(`${pascalToKebab(key)}:${value}px`);
      } else {
        styles.push(`${pascalToKebab(key)}:${value}`);
      }
    }
  });
  return styles.join(';');
};
