import { Dispatch } from 'redux';

import * as messageApi from '@apis/message';

import { ActionsUnion, ActionType, createAction } from '../types';
import { GlobalThunkActions } from '../desktopActions';

export const Actions = {
  loadMessageUnreadCount: (data: {
    appUnReadCount: number;
    appUnReadCountSender: number;
    memberJoinAppNum: number;
    systemUnReadCount: number;
    teamUnReadCount: number;
  }) => createAction(ActionType.Message_Get_UnreadCount, data),
  readMessage: () => createAction(ActionType.Message_Read_App),
  readMessageAboutMe: () => createAction(ActionType.Message_Read_App_About_Me),
  resolveApply: () => createAction(ActionType.Message_Resolve_Join),
  readAllMessage: () => createAction(ActionType.Message_Read_All),
  readAllMessageAboutMe: () => createAction(ActionType.Message_Read_All_About_Me),
};

export const ThunkActions = {
  loadMessageUnreadCount: (teamID: string) => (dispatch: Dispatch) => {
    messageApi
      .loadMessageUnreadCount(teamID)
      .then((res) => {
        dispatch(Actions.loadMessageUnreadCount(res));
      })
      .catch((e) => GlobalThunkActions.thunkError(e.message)(dispatch));
  },
  readMessage: () => (dispatch: Dispatch) => {
    dispatch(Actions.readMessage());
  },
  readMessageAboutMe: () => (dispatch: Dispatch) => {
    dispatch(Actions.readMessageAboutMe());
  },
  resolveApply: () => (dispatch: Dispatch) => {
    dispatch(Actions.resolveApply());
  },
  readAllMessage: () => (dispatch: Dispatch) => {
    dispatch(Actions.readAllMessage());
  },
  readAllMessageAboutMe: () => (dispatch: Dispatch) => {
    dispatch(Actions.readAllMessageAboutMe());
  },
};

export type MessageAction = ActionsUnion<typeof Actions>;
