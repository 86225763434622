import * as React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';

import { isContainerPoint } from '@/utils/boundsUtils';
import { getEnumName } from '@/utils/enumUtils';

import { IListItem, List, Icon } from '@dsm';
import { ComponentTheme } from '@/dsm/common';

import { ETimeFormat, ETimePickerType } from '@fbs/rp/models/properties/common';

import i18n from '@i18n';

import { defaultTime, ETimeKey, getTimeStr, ITime, TimeKeyMaxValueMap } from '../type';

import './index.scss';

export interface ISelectorProps {
  appType: string;
  position: { left: number; top: number; right: number; bottom: number };
  format: ETimeFormat;
  type: ETimePickerType;
  preview?: boolean;
  time?: ITime;
  selected: 0 | 1;
  showMobileCursor?: boolean;
  onChange: (time: ITime | undefined, index: 0 | 1) => void;
  onClose: () => void;
}

export interface ISelectorState {
  originPosition: { left: number; top: number; right: number; bottom: number };
  position: { left: number; top: number };
  positionOrigin: 'top left' | 'bottom left';
  index: number;
}

export default class TimeSelector extends React.Component<ISelectorProps, ISelectorState> {
  protected hours: IListItem[];
  protected minutes: IListItem[];
  protected seconds: IListItem[];
  public self: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: ISelectorProps) {
    super(props);
    this.hours = this.initList(TimeKeyMaxValueMap[getEnumName(ETimeKey, ETimeKey.Hour)]);
    this.minutes = this.initList(TimeKeyMaxValueMap[getEnumName(ETimeKey, ETimeKey.Minute)]);
    this.seconds = this.initList(TimeKeyMaxValueMap[getEnumName(ETimeKey, ETimeKey.Second)]);
    this.state = {
      originPosition: props.position,
      position: { left: props.position.left, top: props.position.bottom },
      index: props.selected,
      positionOrigin: 'top left',
    };
  }

  static getDerivedStateFromProps(props: ISelectorProps, prevState: ISelectorState): Partial<ISelectorState> | null {
    if (props.selected !== prevState.index || !_.isEqual(props.position, prevState.originPosition)) {
      return {
        originPosition: props.position,
        position: { left: props.position.left, top: props.position.bottom },
        index: props.selected,
        positionOrigin: 'top left',
      };
    }
    return null;
  }

  /**
   * 处理编辑器打开选择下拉框滚轮滚动下拉框消失问题
   * @param e
   */
  private handleWheel = (e: Event) => {
    e.stopPropagation();
  };

  componentDidMount() {
    this.adjustPosition();
    if (!this.props.preview && this.self.current) {
      this.self.current.addEventListener('wheel', this.handleWheel);
    }
  }

  componentDidUpdate() {
    this.adjustPosition();
  }

  componentWillUnmount() {
    this.self.current?.removeEventListener('wheel', this.handleWheel);
  }

  protected adjustPosition() {
    if (!this.props.preview) {
      return;
    }
    const dom = this.self.current;
    if (!dom) {
      return;
    }
    const parent = dom.parentElement!;
    const { right, bottom, height } = this.self.current!.getBoundingClientRect();
    const { right: offsetRight, bottom: parentBottom } = parent.getBoundingClientRect();
    const { position } = this.state;

    let l = position.left;
    let t = position.top;
    const offsetTop = 1;
    let positionOrigin = this.state.positionOrigin;
    if (bottom > parentBottom) {
      t = Math.max(this.props.position.top - height / window.pageScale - offsetTop, 0);
      positionOrigin = 'bottom left';
    }
    if (right > offsetRight) {
      l = Math.max(0, l - (right - offsetRight));
    }
    l = Math.round(l);
    t = Math.round(t);
    let newState: ISelectorState = { ...this.state };
    let isChange = false;
    if (t !== this.state.position.top || l !== this.state.position.left) {
      newState.position = { left: l, top: t };
      isChange = true;
    }
    if (positionOrigin !== this.state.positionOrigin) {
      isChange = true;
      newState.positionOrigin = positionOrigin;
    }
    if (isChange) {
      this.setState(newState);
    }
  }

  public containerPoint(pt: { x: number; y: number }): boolean {
    if (!this.self.current) {
      return false;
    }
    const { x: left, y: top } = pt;
    const bounds = this.self.current.getBoundingClientRect();
    return isContainerPoint(bounds, { left, top });
  }

  private initList = (count: number): IListItem[] => {
    const result: IListItem[] = [];
    let i = 0;
    for (; i < count; i++) {
      let text;
      if (i < 10) {
        text = `0${i}`;
      } else {
        text = `${i}`;
      }
      result.push({
        id: i,
        text,
        itemClassName: 'time-select-item',
      });
    }
    return result;
  };

  private getDefaultTime = (time?: ITime): ITime => {
    if (!time) {
      return { ...defaultTime };
    }
    return { ...defaultTime, ...time };
  };

  private updateValue(timeKey: ETimeKey, value: number): void {
    const { time } = this.props;
    const key = getEnumName(ETimeKey, timeKey).toLocaleLowerCase();
    const _time: ITime = { ...this.getDefaultTime(time), [key]: value };
    this.props.onChange(_time, this.props.selected);
  }

  protected handleTimeValueChange = (key: ETimeKey, item: IListItem): void => {
    this.updateValue(key, item.id as number);
  };

  protected handleClose = () => {
    const { onChange, onClose, selected, preview } = this.props;
    onChange(undefined, selected);
    preview && onClose();
  };

  protected renderItemValue(data: IListItem[], selectedIndex: number | undefined, valueKey: ETimeKey) {
    return (
      <List
        allowHover={false}
        className="selector-item"
        theme={ComponentTheme.light}
        maxRowCount={6}
        data={data}
        selectedIndex={selectedIndex}
        onSelect={this.handleTimeValueChange.bind(this, valueKey)}
      />
    );
  }

  protected renderSelector() {
    const { time, format, showMobileCursor } = this.props;
    return (
      <div className={classnames('time-selector', 'component-popup-container', { mobile: showMobileCursor })}>
        {this.renderItemValue(this.hours, time?.hour, ETimeKey.Hour)}
        {this.renderItemValue(this.minutes, time?.minute, ETimeKey.Minute)}
        {format === ETimeFormat.HH_mm_ss && this.renderItemValue(this.seconds, time?.second, ETimeKey.Second)}
      </div>
    );
  }

  protected renderBackground = () => {
    //TODO Matt 2021-11-09 预留之后手机演示时的底部显示时的蒙板
    return null;
  };

  protected renderLabel() {
    const { time, format, type, selected } = this.props;
    const value = getTimeStr(time, format);
    let tips = '';
    if (!value) {
      if (type === ETimePickerType.Normal) {
        tips = i18n('resource.componentsText.timePickerTips');
      } else {
        tips = selected === 0 ? i18n('resource.componentsText.startTime') : i18n('resource.componentsText.endTime');
      }
    }
    return (
      <div className={classnames('lib-comp-time-value', { empty: !value })}>
        <label>{value || tips}</label>
        <Icon className="head-clear" cls="icon_assembly_eliminate_rp" onClick={this.handleClose} />
      </div>
    );
  }

  render() {
    const { position, positionOrigin } = this.state;
    const { preview } = this.props;
    const style: React.CSSProperties = {
      ...position,
      transformOrigin: positionOrigin,
      transform: `scale(${this.props.preview ? window.pageScale : 1})`,
    };
    return (
      <>
        {this.renderBackground()}
        <div ref={this.self} className={classnames('lib-comp-time-selector', { preview })} style={style}>
          {this.renderLabel()}
          {this.renderSelector()}
        </div>
      </>
    );
  }
}
