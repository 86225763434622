import * as React from 'react';
import Icon from '../Icon/index';
import { Input, Menu } from '../../index';
import classnames from 'classnames';
import { IMenuOption } from '../Menu';
import './index.scss';

import { isMacOS, isFirefox, isSafari, keydown, convertEventToHotKey } from '../utils';

interface ExtendItem {
  id: string;
  text: string;

  callBack(): void;
}

interface IZoomerProp {
  min?: number;
  max?: number;
  value?: number;
  step?: number;
  canSelect?: boolean;
  canInput?: boolean;
  presetValues?: number[];
  className?: string;
  extendSelectedItem?: ExtendItem[];

  onZoom(value: number): void;
}

interface IZoomerState {
  editing: boolean;
  popup: boolean;
  value: number;
  tempValue: any;
}

const ZOOMER_MIN = 10;
const ZOOMER_MAX = 200;
const ZOOMER_STEP = 1;
const ZOOMER_VALUE = 100;

class Zoomer extends React.Component<IZoomerProp, IZoomerState> {
  timeID: number = 0;
  runing: boolean = false;
  scaleTimer: number = 0;
  lastDirection: number | undefined = undefined;
  keyDowns = keydown(['ctrl+1', 'ctrl+2', 'ctrl+3', 'ctrl+4', 'ctrl+=', 'ctrl++', 'ctrl+-']);
  static defaultProps: Partial<IZoomerProp> = {
    className: '',
    min: ZOOMER_MIN,
    max: ZOOMER_MAX,
    step: ZOOMER_STEP,
    value: ZOOMER_VALUE,
    presetValues: [10, 25, 50, 75, 100, 150, 200, 300, 400],
    canInput: true,
    canSelect: true,
  };

  inputRef: React.RefObject<HTMLInputElement>;
  selfRef: React.RefObject<HTMLDivElement>;

  constructor(props: IZoomerProp) {
    super(props);
    this.inputRef = React.createRef();
    this.selfRef = React.createRef();
    this.handleWindowWheel = this.handleWindowWheel.bind(this);
    this.handleWindowKeyDown = this.handleWindowKeyDown.bind(this);
    this.handleWindowMouseDown = this.handleWindowMouseDown.bind(this);
    this.handleZoomIn = this.handleZoomIn.bind(this);
    this.handleZoomOut = this.handleZoomOut.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleInputSubmit = this.handleInputSubmit.bind(this);
    this.handleMenuSelect = this.handleMenuSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.doChange = this.doChange.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.validateValue = this.validateValue.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDblClick = this.handleDblClick.bind(this);
    this.closePanel = this.closePanel.bind(this);
    this.state = {
      editing: false,
      popup: false,
      value: props.value !== undefined ? Math.round(props.value) : 0,
      tempValue: null,
    };
  }

  componentDidMount() {
    if (isFirefox()) {
      window.addEventListener('DOMMouseScroll', this.handleWindowWheel, {
        passive: false,
      });
    } else {
      window.addEventListener('mousewheel', this.handleWindowWheel, {
        passive: false,
      });
    }
    window.addEventListener('keydown', this.handleWindowKeyDown);
    window.addEventListener('keyup', this.keyDowns.removeKey);
    window.addEventListener('blur', this.keyDowns.clear);
  }

  componentWillUpdate(newProps: IZoomerProp, newState: any) {
    const { popup } = this.state;
    if (newState.popup !== popup) {
      if (newState.popup) {
        window.addEventListener('mousedown', this.handleWindowMouseDown);
      } else {
        window.removeEventListener('mousedown', this.handleWindowMouseDown);
      }
    }
  }

  componentDidUpdate() {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  componentWillUnmount() {
    if (isFirefox()) {
      window.removeEventListener('DOMMouseScroll', this.handleWindowWheel);
    } else {
      window.removeEventListener('mousewheel', this.handleWindowWheel);
    }
    window.removeEventListener('keydown', this.handleWindowKeyDown);
    window.removeEventListener('keyup', this.keyDowns.removeKey);
    window.removeEventListener('mousedown', this.handleWindowMouseDown);
    window.removeEventListener('blur', this.keyDowns.clear);
    if (this.scaleTimer) {
      clearTimeout(this.scaleTimer);
    }
  }

  closePanel() {
    this.setState({ tempValue: null, editing: false, popup: false });
  }

  handleWindowMouseDown(e: MouseEvent) {
    const { target } = e;
    let parent: Node | null = target as Node;
    const self = this.selfRef.current;
    while (parent !== self) {
      parent = parent.parentNode;
      if (!parent) {
        break;
      }
    }
    if (parent === self) {
      return;
    }
    const { tempValue, value, editing } = this.state;
    if (editing) {
      this.doChange(this.validateValue(tempValue || value));
    }
    this.setState({ tempValue: null, editing: false, popup: false });
  }

  handleWindowWheel(e: any) {
    // 在mac上 双指是ctrlkey 加滚轮所有取消对mac的判断
    if (e.metaKey || e.ctrlKey) {
      if (e.stopImmediatePropagation) {
        e.stopImmediatePropagation();
      }
      e.preventDefault();
      if (this.runing) {
        return;
      }
      this.runing = true;
      // @ts-ignore
      const deltaY = isFirefox() ? e.detail : e.deltaY || -e.wheelDelta;
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(() => {
          this.doWheel(deltaY);
        });
      } else {
        setTimeout(this.doWheel, 66, deltaY);
      }
    }
  }

  doWheel = (deltaY: number) => {
    const value = this.props.value ?? ZOOMER_VALUE;
    try {
      const direction = Math.abs(deltaY) - deltaY === 0 ? -1 : 1;

      if (this.lastDirection && direction !== this.lastDirection) {
        return;
      }

      if (this.scaleTimer) {
        clearTimeout(this.scaleTimer);
        this.scaleTimer = 0;
      }

      this.scaleTimer = window.setTimeout(() => {
        this.lastDirection = undefined;
      }, 100);

      const min = (value / 50) ** 1.7 + 4;
      const max = (value / 30) ** 2 + 4;
      const step = Math.min(min, max);
      let newValue = value + step * direction;
      const mode = newValue % 100;
      const s = Math.round(newValue / 100);
      if (mode > 100 - 3 * s || mode < 3 * s) {
        newValue = newValue - mode + Math.round(mode / 100) * 100;
      }
      this.doChange(newValue);
    } finally {
      this.runing = false;
    }
  };

  getHotKey = (e: KeyboardEvent) => {
    const hotKey = convertEventToHotKey(e);
    if (hotKey === 'ctrl+=') {
      return 'ctrl++';
    }
    if (hotKey === 'ctrl+_') {
      return 'ctrl+-'
    }
    return hotKey;
  }

  handleWindowKeyDown(e: KeyboardEvent) {
    const value = this.props.value ?? ZOOMER_VALUE;
    const step = this.props.step ?? ZOOMER_STEP;
    let newValue = value;

    const key = this.getHotKey(e);
    switch (key) {
      case 'ctrl+1':
      case 'ctrl+2':
      case 'ctrl+3':
      case 'ctrl+4':
      case 'ctrl+=':
      case 'ctrl++':
      case 'ctrl+-':
        e.preventDefault();
        break;
    }
    switch (key) {
      case 'ctrl+1':
      case 'ctrl+2':
      case 'ctrl+3':
        newValue = parseInt(e.key, 10) * 100;
        break;
      case 'ctrl+4':
        newValue = 50;
        break;
      case 'ctrl++':
      case 'ctrl+=':
        newValue = value + step;
        break;
      case 'ctrl+-':
        newValue = value - step;
        break;
    }
    if (value !== newValue) {
      this.doChange(newValue);
    }
  }

  handleZoomIn() {
    const { value, step } = this.props;
    this.doChange((value ?? ZOOMER_VALUE) + (step ?? ZOOMER_STEP));
  }

  handleZoomOut() {
    const { value, step } = this.props;
    this.doChange((value ?? ZOOMER_VALUE) - (step ?? ZOOMER_STEP));
  }

  validateValue(value: number) {
    const min = this.props.min ?? ZOOMER_MIN;
    const max = this.props.max ?? ZOOMER_MAX;
    return Math.max(min, Math.min(value, max));
  }

  doChange(value: number) {
    if (value) {
      const v = this.validateValue(value);
      const { onZoom } = this.props;
      onZoom(v);
      this.setState({ popup: false, editing: false });
    }
  }

  handleInputSubmit(value: number) {
    const { onZoom } = this.props;
    const v = this.validateValue(value);
    this.setState({ value: v, popup: false, editing: false, tempValue: null });
    onZoom(v);
  }

  handleInputChange(value: string) {
    const v = value.replace(/\D/g, '');
    this.setState({ tempValue: v });
  }

  handleInputBlur() {
    const { onZoom } = this.props;
    const { value, tempValue } = this.state;
    const v = this.validateValue(tempValue || value);
    onZoom(v);
    this.setState({
      popup: false,
      editing: false,
      value: v,
      tempValue: null,
    });
  }

  handleMenuSelect(id: string | number) {
    const { onZoom, extendSelectedItem } = this.props;
    let value: any = id;
    if (extendSelectedItem && extendSelectedItem.length > 0) {
      const extendItem = extendSelectedItem.find((item) => item.id === id);
      if (extendItem && extendItem.callBack) {
        value = extendItem.callBack();
        this.setState({ value, editing: false, popup: false, tempValue: null });
        return;
      } else if (extendItem) {
        value = 100;
      }
    }
    const v = this.validateValue(value);
    this.setState({ value: v, editing: false, popup: false, tempValue: null });
    onZoom(v);
  }

  doPopup(needPopup: boolean) {
    if (this.timeID) {
      clearTimeout(this.timeID);
      this.timeID = 0;
    }
    const { value, canSelect, canInput } = this.props;
    const { popup, editing } = this.state;
    const p = needPopup && canSelect && !popup && !editing;
    this.setState({
      value: value ?? ZOOMER_VALUE,
      popup: p || false,
      editing: ((!needPopup || editing) && canInput) || false,
    });
  }

  handleDblClick() {
    this.doPopup(false);
  }

  handleClick() {
    if (this.timeID) {
      clearTimeout(this.timeID);
    }
    this.timeID = window.setTimeout(() => {
      this.doPopup(true);
    }, 250);
  }

  renderInput() {
    const { editing, value, tempValue } = this.state;
    const v = `${tempValue !== null ? tempValue : Math.round(value)}`;
    if (editing) {
      return (
        <Input
          type="text"
          setInputRef={this.inputRef}
          value={v}
          width="100%"
          onBlur={this.handleInputBlur}
          onSubmit={this.handleInputSubmit}
          onChange={this.handleInputChange}
        />
      );
    }
    return null;
  }

  renderMenu() {
    const { popup, value } = this.state;
    const { presetValues, min, max, canInput, extendSelectedItem } = this.props;
    const vs = presetValues ? presetValues.filter((v) => v >= (min ?? 10) && v <= (max ?? 200)) : [];
    vs.sort((v1: string | number, v2: string | number) => {
      const a = typeof v1 === 'number' ? v1 : parseInt(v1, 10);
      const b = typeof v2 === 'number' ? v2 : parseInt(v2, 10);
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    const { length } = vs;
    if (vs[length - 1] < (max ?? ZOOMER_MAX)) {
      vs.push(max ?? ZOOMER_MAX);
    }
    if (vs.length > 0) {
      if (vs[0] > (min ?? ZOOMER_MIN)) {
        vs.unshift(min ?? ZOOMER_MIN);
      }
    }
    const selIndex = vs.findIndex((v) => Math.round(v) === Math.round(value));
    let options: IMenuOption[] = vs.map((item) => {
      const v = Math.round(item);
      let text = `${v}%`;
      const macCtrl = isSafari() ? '^' : '⌘';
      const ctrl = isMacOS ? macCtrl : 'Ctrl';
      if (v === 50) {
        text = `${text}　　${ctrl} + 4`;
      } else if (v === 100) {
        text = `${text} 　 ${ctrl} + 1`;
      } else if (v === 200) {
        text = `${text} 　 ${ctrl} + 2`;
      } else if (v === 300) {
        text = `${text} 　 ${ctrl} + 3`;
      }
      return { id: v, text };
    });

    if (extendSelectedItem) {
      options.push({ text: '-' });
      options = options.concat(extendSelectedItem);
    }
    if (popup) {
      return (
        <Menu
          options={options}
          onSelect={this.handleMenuSelect}
          selectIdx={selIndex}
          onClose={() => {
            this.setState({ popup: false });
          }}
        />
      );
    }
    return null;
  }

  render() {
    const { value, className, min, max } = this.props;
    const { editing } = this.state;
    const disableL = value !== undefined && min !== undefined && value <= min;
    const disableR = value !== undefined && max !== undefined && value >= max;
    return (
      <div className={classnames(`dsm-c-zoomer ${className}`, { disableL, disableR })} ref={this.selfRef}>
        <div className="zoom-btn" onClick={this.handleZoomOut}>
          <Icon cls="zoomout" color={'#1bbe0a'} />
          <Icon cls="zoomout_pressed" />
        </div>
        <span
          className={editing ? 'editing' : 'no-editing'}
          onClick={this.handleClick}
          onDoubleClick={this.handleDblClick}
        >
          {this.renderInput()}
          {value === undefined ? 0 : Math.round(value)}
        </span>

        <div className="zoom-btn" onClick={this.handleZoomIn}>
          <Icon cls="zoomin" color={'#f8af1d'} />
          <Icon cls="zoomin_pressed" />
        </div>
        {this.renderMenu()}
      </div>
    );
  }
}

export default Zoomer;
