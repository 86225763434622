import * as React from 'react';

import CheckList from './CheckList';
import SelectList from './SelectList';
import { IItemProps, IGroupProps } from '../../models';

const GroupSculpture = 'https://img02.mockplus.cn/idoc/image/2020-09-23/fbdfd2f1-fd67-11ea-9a10-8fdd726ad5b3.png';

import './index.scss';

interface memberInfo {
  userID: number;
  email: string;
  name?: string;
  imageURL?: string;
  groupName?: string;
  pinyinName?: string;
}

interface ICheckSelectProps {
  groups?: IGroupProps[];
  singles?: IItemProps[];
  canCustomStatus?: boolean;
  checkIds?: string[];
  searchPlaceholder?: string;
  maxCount?: number;
  enableEmailSearch?: boolean;
  height?: number;
  showAddGroup?: boolean;
  addGroupTooltip?: string;
  disabledGroup?: number[];
  enableThirdPartyUUIDSearch?: boolean;

  onSearch?(search?: string): void;
  onCheck(checkMembers: memberInfo[]): void;
  onCheckDisable?(id: string): void;
  scrollBottom?(): void;
}

const getAllItems = (groups: IGroupProps[] | undefined, singles: IItemProps[] | undefined): IItemProps[] => {
  let items: IItemProps[] = [];

  if (groups && groups.length > 0) {
    groups.forEach((group: IGroupProps) => {
      const { children } = group;
      if (children && children.length > 0) {
        children.forEach((item) => {
          const existItem = items.filter((groupItem) => groupItem.id === item.id);
          if (existItem.length <= 0) {
            items.push(item);
          }
        });
      }
    });
  }

  if (singles && singles.length > 0) {
    singles.forEach((item) => {
      const existItem = items.filter((groupItem) => groupItem.id === item.id);
      if (existItem.length <= 0) {
        items.push(item);
      }
    });
  }

  return items;
};

const CheckSelect: React.FC<ICheckSelectProps> = (props: ICheckSelectProps) => {
  const {
    groups,
    singles,
    checkIds,
    searchPlaceholder,
    maxCount,
    canCustomStatus,
    enableEmailSearch,
    height,
    showAddGroup,
    addGroupTooltip,
    disabledGroup,
    enableThirdPartyUUIDSearch,
    onCheck,
    onCheckDisable,
    scrollBottom,
    onSearch,
  } = props;

  const [checked, setChecked] = React.useState(checkIds || []);

  const allItems = getAllItems(groups, singles);

  const checkItems = allItems.filter((item: IItemProps) => checked.includes(item.id));
  const checkGroup = (showAddGroup && groups && groups.filter((item) => checked.includes(item.id))) || [];
  const formatCheckItems =
    checkItems &&
    checkItems.map((item: IItemProps) => {
      return {
        userID: Number(item.id),
        name: item.name,
        email: item.email,
        // @ts-ignore
        disabled: item.disabled,
      };
    });
  if (showAddGroup) {
    checkGroup.forEach((item) => {
      formatCheckItems.push({
        userID: Number(item.id),
        name: item.name,
        email: '',
        disabled: disabledGroup?.includes(Number(item.id)),
      });
    });
  }
  React.useEffect(() => {
    onCheck(formatCheckItems);
  }, [formatCheckItems.length, checkGroup.length]);

  // 去重
  const selectedList: IItemProps[] = [];
  const selectedIDs: string[] = [];
  checkItems &&
    checkItems.forEach((item) => {
      if (!selectedIDs.includes(item.id)) {
        selectedIDs.push(item.id);
        selectedList.push(item);
      }
    });
  if (showAddGroup) {
    checkGroup.forEach((item) => {
      if (!selectedIDs.includes(item.id)) {
        selectedIDs.push(item.id);
        selectedList.push({
          id: item.id,
          imageURL: GroupSculpture,
          name: item.name,
          email: '',
          disabled: disabledGroup?.includes(Number(item.id)),
        });
      }
    });
  }

  return (
    <div className="c-check-select" style={{ height }}>
      <div className="c-check-list">
        <CheckList
          groups={groups}
          singles={singles}
          checked={checked}
          searchPlaceholder={searchPlaceholder}
          canCustomStatus={canCustomStatus}
          hideGroupCheck={!!maxCount && maxCount < allItems.length}
          allItems={allItems}
          itemCount={allItems.length}
          enableEmailSearch={!!enableEmailSearch}
          showAddGroup={!!showAddGroup}
          addGroupTooltip={addGroupTooltip || ''}
          enableThirdPartyUUIDSearch={enableThirdPartyUUIDSearch}
          onCheck={setChecked}
          onCheckDisable={onCheckDisable}
          onSearch={onSearch}
          scrollBottom={scrollBottom}
        />
      </div>
      <div className="c-select-list">
        <SelectList
          items={selectedList}
          checked={checked}
          groups={groups}
          showAddGroup={showAddGroup}
          onCheck={setChecked}
          onCheckDisable={onCheckDisable}
        />
      </div>
    </div>
  );
};

CheckSelect.defaultProps = {
  enableEmailSearch: false,
  canCustomStatus: true,
  enableThirdPartyUUIDSearch: false,
};

export default CheckSelect;
