export default {
  TagButtonGroup: {
    all: 'All',
    settings: 'Settings',
  },
  Dialog: {
    ok: 'OK',
    cancel: 'Cancel',
  },
  RadioButton: {
    text: 'Radio Button',
  },
  Select: {
    placeholder: 'Select or type here',
  },
  TagsManager: {
    maxLength: '50',
    error: 'The group name cannot exceed 50 characters',
    dialog: 'After the group is deleted, it can’t be restored. Are you sure you want to delete it?',
    placeholder: 'Type the group name',
    title: 'Manage groups',
    actionName: 'Create group',
    rename: 'Rename',
    delete: 'Delete',
  },
  tree: {
    emptySearch: 'No results found',
    annotationTip: '{0} comment(s) unsolved.',
  },
  colorPanel: {
    recentUse: 'Recent colors',
    customizeColor: 'Custom colors',
  },
  shortCutKey: {
    shortCutKey: 'Shortcut Keys',
    feature: 'Features',
    projectTree: 'Project Tree',
    toStowOrExpandAll: 'Expand/collapse all nodes',
    toStowOrExpand: 'Expand/collapse icon',
    canvas: 'Canvas',
    storyboard: 'Storyboard',
    page: 'Page',
    prototype: 'Prototype',
    design: 'Design',
    drag: 'Drag',
    leftMouseButton: 'Click+Space',
    zoom: 'Zoom',
    mouseWheel: 'Mouse wheel',
    leftMouseButtonOrMouseWheel: 'Left + space or press and hold the scroll wheel',
    zoomOut: 'Zoom out',
    zoomIn: 'Zoom in',
    zoomToOne: 'Zoom to 100%',
    zoomToTwo: 'Zoom to 200%',
    zoomToThree: 'Zoom to 300%',
    zoomToFour: 'Zoom to 50%',
    zoomToArtboard: 'Fit to artboard',
    delete: 'Delete',
    leftKey: 'Click',
    overview: 'Overview',
    map: 'Map',
    preview: 'Full screen',
    lineDescription: 'Line description',
    multiSelect: 'Multiselect',
    rename: 'Rename',
    copy: 'Copy',
    paste: 'Paste',
    cut: 'Cut',
    magnifier: 'Magnifier',
    doubleClick: 'Double click',
    percentageSpecs: 'Percentage specs',
    copyLink: 'Copy link',
    select: 'Select',
    pin: 'Pin',
    rectangle: 'Rectangle',
    circle: 'Circle',
    straightLine: 'Straight line',
    arrow: 'Arrow',
    text: 'Text',
    imgLayers: 'Layer list',
    createrHotArea: 'Create link area rapidly',
    undoBtn: 'Undo',
    redoBtn: 'Redo',
    prePage: 'Previous',
    nextPage: 'Next',
    left: 'Left',
    right: 'Right',
    selectAll: 'Select all',
    commentMode: 'Comment',
    reviewMode: 'Review',
    developMode: 'Development',
    prdDocTree: 'Document tree',
    focus: 'Focus',
    generateOperation: '通用操作',
    dragWorkspace: 'Drag workspace',
    mouseWheelUp: 'Mousewheel up',
    mouseWheelDown: 'Mousewheel down',
    basicOperation: 'Common',
    lock: 'Lock / Unlock',
    demonstrate: 'Preview',
    componentClone: 'Clone',
    dragComponent: '拖动组件',
    verticalClone: '水平或垂直克隆组件',
    mergeOrdissolve: 'Group / Ungroup',
    hidden: 'Show / Hide',
    containSelect: 'Select contained',
    mouseRegion: '鼠标框选',
    selectDown: 'Select layer below',
    unSelect: 'Deselect',
    addElement: 'Insert',
    texts: 'Text',
    button: 'Button',
    image: 'Image',
    pen: 'Pen',
    stickyNote: 'Sticky Note',
    circleMarker: 'Circle Marker',
    dropMarker: 'Drop Marker',
    attributeSetting: 'Properties',
    componentHeight: '调整组件高度',
    componentWidth: '调整组件宽度',
    componentTransparent: 'Adjust opacity',
    numberKey: '0~9',
    addFontsize: 'Font size +',
    decreaseFontsize: 'Font size -',
    bold: 'Bold',
    italic: 'Italic',
    line: 'Line',
    underline: 'Underline',
    oval: 'Ellipse',
    layoutSetting: 'Layout',
    componentMove: 'Move',
    componentMoveFast: 'Move 10 px',
    verticalMove: '水平/垂直移动',
    click: 'Click',
    addRegion: 'Add to frame selection',
    selectRegion: 'Frame selection',
    forwardTier: 'Bring forward',
    abackTier: 'Send backward',
    toTop: 'Send to front',
    toBottom: 'Send to back',
    boardInterval: 'Show distance',
    MouseOverComponent: 'Mouse hover',
    selectComponent: '选中组件',
    seeMouseOver: '悬停要查看的组件',
    justifyLeft: 'Align left',
    justifyRight: 'Align right',
    justifyTop: 'Align top',
    justifyBottom: 'Align bottom',
    justifyCenter: 'Align center',
    justifyVertical: 'Align middle',
    levelIsometry: 'Distribute horizontally',
    verticalIsometry: 'Distribute vertically',
    projectSetting: '项目设置',
    newPage: 'Add page',
    actionName: 'Add group',
    pageUp: '页面上移',
    pageDown: '页面下移',
    recentPage: '切换到最近打开页面',
    InterfaceView: 'Misc',
    interfaceSetting: 'Project',
    openTree: '打开项目',
    openComponent: '打开组件',
    openIcons: '打开图标',
    openResource: '打开资源',
    openLayer: '打开图层',
    unfoldLeftBoard: '收起/展开左侧面板',
    unfoldRightBoard: '收起/展开右侧面板',
    levelRoll: 'Horizontally scroll workspace',
    locateMainBoard: '定位到主画板',
    searchFast: 'Quick search',
    switchTabs: 'Switch left panel tabs',
    showHideGrid: 'Show/Hide Grid',
    showHideLayout: 'Show/Hide Layout',
    artboard: 'Artboard',
    notes: 'Notes',
    autoSize: 'AutoSize',
    showRuler: 'Show rulers',
    save: 'Save',
  },
  mobileDialog: {
    title: 'Enjoy all Mockplus features on your PC',
    text: ' If you want to preview a mobile project, try the Mockplus Mobile App.',
    ok: 'OK',
    openApp: 'Start Mockplus Mobile App now',
    unInstall: "Haven't installed it yet?",
    clickDownload: 'Install now',
    openAppFail: 'Sorry, app cannot be started',
    downloadApp: 'Download the latest version',
    openManually: 'Latest version installed? Start manually',
    cancel: 'Got it',
    download: 'Download',
    appUrl: 'https://idoc.mockplus.com',
  },
  checkSelect: {
    hasSelected: '{0} member(s)',
    hasSelectedGroup: ',{0} group(s)',
    clearAll: 'Deselect',
    unclassified: 'Ungrouped',
    selectAll: 'Select all',
    hasNotFound: 'No results found',
    maximumOfPeople: 'You cannot add more.',
    last: 'Last selection',
  },
  searchInput: {
    searchHistory: '搜索历史', //#12-1
    clearHistory: '清除', //#12-1
  },
  //#03.08rp-free
  rpActivity: {
    title: '摹客RP免费计划',
    inviteToGetFree: '邀请{0}名新成员注册并加入团队，即可永久获得无限设计包！',
    deadline: '活动截止：{0}',
    whatUnlimitedDesign: '什么是无限设计包？',
    definitionOfUnlimitedDesign:
      '无限设计包中包含摹客RP所有功能，页面数量无上限，设计功能无限制！获取后，团队所有成员均可享受。',
    invitationProgress: '邀请进度',
    invitationStatus: '已获得{0}个月无限设计包，再邀请{1}人即可永久获得，点击查看邀请记录。',
    invitationStatusHasGot: '已获得永久无限设计包，点击查看邀请记录。',
    viewInvitationHistory: '查看邀请记录',
    oneYear: '1年',
    oneMonth: '1个月', //#5-rp-activity
    addThreeMonth: '+3个月',
    forever: '永久',
    inviteSeveral: '邀请{0}名成员',
    hasGotIt: '已获得',
    inviteNow: '立即邀请',
    getForeverPackage: '领取永久无限包',
    hasGotPackage: '团队已领取',
    goBack: '返回',
    invitation: {
      linkInvitation: '链接邀请',
      copyLink: '复制链接',
      inviteLinkDesc: '将链接发送给同事，邀请对方加入“{0}”',
      emailInvitation: '邮箱邀请',
      emailPlaceholder: '请输入企业邮箱，多个邮箱使用分号隔开',
      copySuccess: '复制成功',
      sendInvitation: '发送邀请',
      sendInvitationSuccess: '已成功发送',
      emailFormatError: '存在错误的邮箱地址，请更正后再试',
      copyText:
        'Hi~这款永久免费的原型设计工具，我必须要分享给你！功能强大，上手简单，不限页面，不限功能。马上注册，开始使用吧：{0}',
    },
    guestLimitTip: '你在“{0}”中的身份是协同人，无法参与此活动。 请切换团队后再试。',
    record: {
      num: '序号',
      time: '时间',
      sender: '邀请人',
      receiver: '受邀人',
      reward: '奖励',
      threeMonthReward: '3个月无限设计包',
      emptyTip: '还没有被邀请的成员呢，赶紧去邀请吧~',
    },
    receiveForever: {
      scanQR: '扫描活动客服二维码',
      get: '领取',
      unlimitedPackage: '永久无限设计包',
      rewardWillSoonArrived: '永久无限设计包预计1个工作日内发放至团队',
    },
  },
  pager: {
    total: '{0} in total',
    checkAll: 'Select all',
    select: ' {0} selected',
    selected: ', {0} selected',
    batchOperate: 'Batch edit',
    sizeOfPerPage: '{0} / page',
    jumpTo: 'Go to',
    page: '-',
  },
  emptyTip: {
    emptyTip: 'Nothing found here yet.',
    searchEmptyTip: 'Sorry, no matching results found.',
  },
  role: {
    superAdministrator: 'Super Admin',
    administrator: 'Admin',
    member: 'Member',
    guest: 'Collaborator',
  },

  linkLoading: {
    loading: 'Loading...',
  },

  share: {
    shareHint: 'Share the project',
    deadTimeReminder: 'This link will expire in {0} days',
    projectPermissions: 'Only the project member can view this project.',
    InviteOther: 'Invite your partner',
    joinInTeam: ' to become a project member.',
    inviteCollaborators: 'Invite as collaborator',
    cooperativePersonAuthority: 'A collaborator can view and comment on projects, but can not edit.',
    setShareURLInTeam: 'Only team members can view this project',
    shareURLInTeamHelp: 'Only team admins can set this item',
    invitationTitle: "You've received an invitation from {0}",
    accept: 'Join Now',
    onlyViewProject: 'View Prototype Only',
    joinSuccess: "You've joined {0} successfully",
    copy: 'Copy',
    copySuccess: 'Copied successfully.',
    memberAuthorityLink: 'https://help.mockplus.com/p/295',
    shareLinkNoEfficacy2: 'Your link has expired',
    login: 'Log in',
    goWebsite: 'Back to official website',
    loginContent: 'Please log in to continue',
    switchAccount: 'Switch account',
    loginUserInfo: 'The current account is {0}',
    notPermission: 'Unable to view this project',
    loginNow: 'Log in',
    onlyMember: 'Only the project member can view this project',
    onlyMembers: 'Only the project member can view this project',
    contactAdmin: 'Please contact',
    applyJoin: 'to join in the project',
    goHome: 'Back to home page',
    publicProjectWarnInfo: "You're sharing a Public project, and everyone can open it with login.",
    shareAndInvite: 'Also invite your partner to become a project member ',
    inviteWillLoseEfficacy: 'Invite anyone via this link in 7 days. After that, it will work as a share link only.',
    applyForContact: "You can ask for {0}'s permission to join the project.",
    applyForViewProject: 'Request access',
    privateShareTip: 'Share only to project members. This link grants access to design reviews, specs.',
    publicShareTip: 'Share with everyone. With preview and presentation view access only.',
    privateDeploymentTip: "Share with everyone who's logged in. With preview and presentation view access only.",
    privateShare: 'Private share',
    publicShare: 'Public share',
    resetLink: 'Reset',
    publicProjectShareTip: 'A public project is accessible to anyone. Try sharing it via "Private share" tab.',
    onlyShareCurMode: 'Share current section only',
    embeddedSharing: 'Embed project',
    embeddedTip: 'Embed this project to a third party application via this link. The link is accessible to all.',
    mode: 'Mode',
    mockPrototype: 'Prototype',
    design: 'Design',
    onlineDesign: 'RP',
    content: 'Content',
    noneInterface: 'None',
    storyboardInterface: 'Storyboard',
    previewInterface: 'Preview',
    resetTip: 'Reset will invalidate all previous share links of this project. Are you sure you want to do this?',
    dontShow: "Don't ask me again",
  },

  txShare: {
    tencentShare: 'Tencent Conference Sharing', // koujing 5-1
    mockShare: 'Mockplus Sharing', // koujing 5-1
  },
  txMicroIcon: {
    waitToJoin: 'waitToJoinMeeting',
    startMeeting: 'startMeeting',
    jumpToTxMeeting: 'jumpToTxMeeting',
  },
  txJoinDialog: {
    dialogTitle: 'The meeting you initiated is in progress ...',
    dialogInvite: '{0} invite you to join the video conference',
    dialogContent: 'This conference is held at the Tencent meeting, click the button to join immediately',
    meetingNumber: 'Meeting ID/ID：{0}',
    joinNow: 'Join now',
    closeMeeting: 'Meeting dismiss',
    dontShow: 'do not show again',
    closeNotice: 'End notice',
  },
};
