import * as React from 'react';

import { parseColorToString, PureColor } from '@/utils/graphicsUtils';

import { IComponentProps } from '@/libs/types';
import { UICollapseComponent, UITreeItemComponent } from '@editor/comps';
import { ITreeItem, mapRelationWithComp } from '@helpers/treeCompHelper';
import { StyleHelper } from '@/helpers/styleHelper';
import IStroke from '@/fbs/rp/models/properties/stroke';
import ITextFormat from '@/fbs/rp/models/properties/text';
import { IRange } from '@/fbs/rp/models/properties/base';
import { ITreeData, ITreeDataItem } from '@fbs/rp/models/value';

import CollapseItem from './CollapseItem';
import CanvasPanel from '../../containers/Canvas';

import './index.scss';

const Collapse: React.FC<IComponentProps> = (props) => {
  const { comp, isPreview, hotAreaVisibleModel } = props;
  const collapseComp = props.comp as UICollapseComponent;

  const { properties, padding } = collapseComp;
  const titleBgColor = parseColorToString(properties.titleBgColor?.value as PureColor);
  const contentBgColor = parseColorToString(properties.contentBgColor?.value as PureColor);
  let borderValue: string | undefined;
  if (!properties.separator?.disabled) {
    const { borderWidth, borderStyle, borderColor } = StyleHelper.parserStroke(properties.separator as IStroke);
    borderValue = `${borderWidth}px ${borderStyle} ${borderColor}`;
  }

  const getCompFormNode = (node: ITreeDataItem) => {
    return collapseComp.getItemCompById(node.id);
  };

  const handleItemClick = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (isPreview && !comp.disabled) {
      const itemComp = getCompFormNode(node);
      if (itemComp) {
        props.event?.onClick?.(e, itemComp);
      }
    }
  };

  const handleItemMouseDown = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (isPreview && !comp.disabled) {
      const itemComp = getCompFormNode(node);
      if (itemComp) {
        props.event?.onMouseDown?.(e, itemComp);
      }
    }
  };

  const handleItemExpandClick = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (comp.disabled) {
      return;
    }
    const itemComp = getCompFormNode(node);
    const checkboxComp = itemComp.expandComp;
    if (checkboxComp) {
      props.event?.onClick?.(e, checkboxComp);
    }
  };

  const handleItemDoubleClick = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (isPreview) {
      const comp = getCompFormNode(node);
      comp && props.event?.onDoubleClick?.(e, comp);
    }
  };

  const handleItemMouseUp = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (isPreview) {
      const comp = getCompFormNode(node);
      comp && props.event?.onMouseUp?.(e, comp);
    }
  };

  const handleItemMouseEnter = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (isPreview) {
      const comp = getCompFormNode(node);
      comp && props.event?.onMouseEnter?.(e, comp);
    }
  };

  const handleItemMouseLeave = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (isPreview) {
      const comp = getCompFormNode(node);
      comp && props.event?.onMouseLeave?.(e, comp);
    }
  };

  const handleItemContext = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (isPreview) {
      const comp = getCompFormNode(node);
      comp && props.event?.onContextMenu?.(e, comp);
    }
  };

  const getStrokeStyle = () => {
    const parser = StyleHelper.initCSSStyleParser(properties);
    return parser.getStrokeStyle();
  };

  /**
   * @param item
   * @param level
   * @param isLast
   */
  const renderItem = (item: ITreeItem, level: number, isLast: boolean) => {
    const itemComp = collapseComp.components.find((comp) => comp.id === item.data.id);
    if (!itemComp) {
      return null;
    }

    const { properties } = comp;
    return (
      <CollapseItem
        isLast={isLast}
        titleBgColor={titleBgColor}
        contentBgColor={contentBgColor}
        verticalPadding={(properties.verticalPadding?.value as IRange)?.value ?? 12}
        sepInfo={borderValue}
        textStyle={(level === 0 ? properties.titleTextStyle : properties.contentTextStyle) as ITextFormat}
        key={item.data.id}
        item={item}
        comp={itemComp as UITreeItemComponent}
        level={level}
        isPreview={!!isPreview}
        hotAreaVisibleModel={hotAreaVisibleModel}
        onExpandClick={handleItemExpandClick}
        onItemClick={handleItemClick}
        onItemMouseDown={handleItemMouseDown}
        onItemMouseUp={handleItemMouseUp}
        onItemDoubleClick={handleItemDoubleClick}
        onItemMouseEnter={handleItemMouseEnter}
        onItemMouseLeave={handleItemMouseLeave}
        onItemContext={handleItemContext}
      >
        {item.data.expand && item.children?.length && renderItems(item.children, level + 1)}
      </CollapseItem>
    );
  };

  const renderItems = (items: ITreeItem[], level: number) => {
    return items.map((item, index) => renderItem(item, level, index === items.length - 1));
  };

  const renderCollapse = () => {
    const childComps = collapseComp.components.map((comp) => comp.$data);
    const { relation } = comp.value as ITreeData;
    const initTree = mapRelationWithComp(relation, childComps);

    return initTree.map((item, index) => renderItem(item, 0, index === initTree.length - 1));
  };

  return (
    <div className="lib-comp-collapse">
      <CanvasPanel comp={comp} isPreview={isPreview} ignoreStroke>
        <div
          className="lib-comp-collapse-content"
          style={{
            paddingLeft: padding.left,
            paddingBottom: padding.bottom,
            paddingRight: padding.right,
            paddingTop: padding.top,
            ...getStrokeStyle(),
          }}
        >
          {renderCollapse()}
        </div>
      </CanvasPanel>
    </div>
  );
};

export default Collapse;
