import { max, min } from '@utils/globalUtils';

import { IScrollCommandParams } from '@fbs/rp/models/interactions';

import { getOffsetScrollToTarget, getParentDomByFilter } from '@helpers/documentHelper';
import appOptions from '@helpers/appOptions';

import CommandBase from './CommandBase';

export default class ScrollCommand extends CommandBase {
  cashOriginParams = () => {};

  private initScrollPromise = (dom: HTMLElement, _scale: number) => {
    const {
      params,
      animate: { duration },
    } = this.command;
    const { horizontal, vertical } = params as IScrollCommandParams;
    return new Promise(() => {
      const scrollDom = getParentDomByFilter(
        dom,
        (el) => !!el.parentElement?.classList.contains('dsm-c-rp-scrollbars'),
      );
      if (scrollDom) {
        const isNoScroll = scrollDom.parentElement?.classList.contains('no-scale-scroll');
        const scale = isNoScroll ? 1 : _scale;
        const scrollBounds = scrollDom.getBoundingClientRect();
        const targetBounds = dom.getBoundingClientRect();
        // 获取缩放后的scroll相关参数
        const scrollHeight = scrollDom.scrollHeight * scale;
        const scrollWidth = scrollDom.scrollWidth * scale;
        const scrollLeft = scrollDom.scrollLeft * scale;
        const scrollTop = scrollDom.scrollTop * scale;
        const scrollOpt = { scrollHeight, scrollWidth, scrollLeft, scrollTop };
        const offset = getOffsetScrollToTarget(scrollBounds, targetBounds, scrollOpt);
        const unitTime = 20;
        const maxTime = duration / unitTime || 1;
        let stepX = horizontal ? offset.x / maxTime : 0;
        let stepY = vertical ? offset.y / maxTime : 0;
        let curLeft = scrollLeft;
        let curTop = scrollTop;
        const maxLeft = Math.ceil(scrollLeft + offset.x);
        const maxTop = Math.ceil(scrollTop + offset.y);
        let i = 0;
        const interval = setInterval(() => {
          curLeft = curLeft + stepX;
          curTop = curTop + stepY;

          if (stepX >= 0) {
            curLeft = min(curLeft, maxLeft);
          } else {
            curLeft = max(curLeft, maxLeft);
          }
          if (stepY >= 0) {
            curTop = min(curTop, maxTop);
          } else {
            curTop = max(curTop, maxTop);
          }
          scrollDom.scroll({
            left: curLeft / scale,
            top: curTop / scale,
          });

          const hOver = stepX >= 0 ? curLeft >= maxLeft : curLeft <= maxLeft;
          const vOver = stepY >= 0 ? curTop >= maxTop : curTop <= maxTop;
          if ((vOver || !vertical) && (hOver || !horizontal)) {
            clearInterval(interval);
          }
          i++;
          // 保险；避免奇怪的误差
          if (i > maxTime + 10) {
            clearInterval(interval);
          }
        }, unitTime);
      }
    });
  };

  run = () => {
    const { target } = this.command;
    const { id } = target;
    const doms: NodeListOf<HTMLElement> = document.querySelectorAll(`[id="${id}"]`);
    const scale: number = appOptions.previewScale / 100;
    Promise.all(
      Array.from(doms).map((dom) => {
        this.initScrollPromise(dom, scale);
      }),
    );
    this.fulfilled = true;
  };

  revert() {
    this.run();
    // 无自动还原
  }
}
