import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// const basePosition = '(sw>=14?7:(7-(14-sw)/2))';
// const handlePosition = '(sh>=36?18:(18-(36-sh)/2))';
const basePosition = 'Math.min(sw,sh)*(7/60)';
const handlePosition = 'sh*(18/60)';

// 键入
export default {
  ...flowBase,
  name: i18n('resource.flow.keying'),
  type: 'keying',
  thumb: {
    spriteIconClass: SpriteThumb.Keying.className,
    dragPosition: SpriteThumb.Keying.position,
  },
  template: getShapeData(
    i18n('resource.flow.keying'),
    {
      width: 100,
      lockedRatio: false,
      height: 60,
    },
    {
      data: [
        {
          point: {
            x: 7,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 30,
          },
          handleIn: {
            x: 0,
            y: -18,
          },
          handleOut: {
            x: 0,
            y: 18,
          },
        },
        {
          point: {
            x: 7,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 93,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 100,
            y: 30,
          },
          handleIn: {
            x: 0,
            y: 18,
          },
          handleOut: {
            x: 0,
            y: -18,
          },
        },
        {
          point: {
            x: 93,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
      closed: true,
      isTemplateShape: true,
      templateData: [
        {
          point: {
            x: basePosition,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 'sh/2',
          },
          handleIn: {
            x: 0,
            y: `-${handlePosition}`,
          },
          handleOut: {
            x: 0,
            y: handlePosition,
          },
        },
        {
          point: {
            x: basePosition,
            y: 'sh',
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: `sw-${basePosition}`,
            y: 'sh',
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 'sw',
            y: 'sh/2',
          },
          handleIn: {
            x: 0,
            y: handlePosition,
          },
          handleOut: {
            x: 0,
            y: `-${handlePosition}`,
          },
        },
        {
          point: {
            x: `sw-${basePosition}`,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
