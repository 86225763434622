import * as React from 'react';

import Icon from '../Icon';
import './index.scss';

interface ILarkRefresh {
  isZh: boolean,
  homeURL?: string;
}

const LarkRefresh: React.FC<ILarkRefresh> = (props: ILarkRefresh) => {
  const { isZh, homeURL } = props;
  return (
    <div className="lark-refresh-contain">
      <div className="head-menu">
        <Icon
          cls="tag_home"
          onClick={() => {
            window.location.href = `/${homeURL||'app'}`;
          }}
          size={18}
        />
        <Icon
          cls="tag_refresh_arrow"
          onClick={() => {
            window.top.document.location.reload();
          }}
        />
        <Icon
            cls="helpbar_contact"
            size={18}
            onClick={() => {
              window.location.href = isZh ?
                'https://applink.feishu.cn/client/helpdesk/open?id=6798113634239561729&extra=%7B%22channel%22%3A1%2C%22created_at%22%3A1618209113%7D':
                'https://applink.larksuite.com/client/helpdesk/open?id=6979512589873053702&extra=%7B%22channel%22%3A1%2C%22created_at%22%3A1625104253%7D';
            }}
          />
      </div>
    </div>
  )
};

export default LarkRefresh;
