import * as React from 'react';
import { UIComponent } from '@editor/comps';
import RichTextEditor from './RichTextEditor';
// import { Input } from '@dsm';
import { ISize } from '@/fbs/common/models/common';
import { replaceOldFontSize } from '@utils/textUtils';

export function createTextFragment(
  text: string,
  styles?: { className?: string; style?: React.CSSProperties; opacity?: number },
): React.ReactNode {
  const { style, className, opacity } = styles || {};
  return (
    <span
      style={{ ...style, opacity }}
      className={className}
      dangerouslySetInnerHTML={{ __html: replaceOldFontSize(text, 'out') }}
    />
  );
}

export function createEditorFragment(
  comp: UIComponent,
  value: string,
  onChanged: (
    value: string,
    option: {
      wrap?: boolean;
      size?: ISize;
    },
    holdEdit?: boolean,
  ) => void,
  onChanging: () => void,
  styles?: {
    style?: React.CSSProperties;
    className?: string;
    wrap?: boolean;
  },
): React.ReactNode {
  const { style, className, wrap } = styles || {};
  const { multiText, textFormat } = comp.properties;

  return (
    <RichTextEditor
      value={value}
      wrap={wrap}
      multi={!!multiText || textFormat?.isMulti}
      vertical={textFormat?.vertical || multiText?.vertical}
      style={{ ...(style || {}) }}
      ownerComp={comp}
      className={className || ''}
      onValueChanged={onChanged}
      autoSave
      onSelectChanged={onChanging}
    />
  );
}

interface IShapeCompTextOptions {
  style?: React.CSSProperties;
  className?: string;
  wrap?: boolean;
  editing?: boolean;
  opacity?: number;
  onChanged: (
    value: string,
    option?: {
      wrap?: boolean;
      size?: ISize;
    },
    holdEdit?: boolean,
  ) => void;
  onChanging: () => void;
}

export function createShapeCompTextFragment(
  comp: UIComponent,
  value: string,
  options: IShapeCompTextOptions,
): React.ReactNode {
  const { style, className, wrap, editing, onChanged, onChanging, opacity } = options;
  if (editing) {
    return createEditorFragment(comp, value, onChanged, onChanging, { style, className, wrap });
  }
  return value ? createTextFragment(value, { style, className, opacity }) : null;
}

// export function createPureTextEditor(comp: UIComponent, value: string, options: IShapeCompTextOptions) {
//   const { style, className, onChanged, editing } = options;
//   if (editing) {
//     return <Input value={value} style={style} className={className} onBlur={onChanged} />;
//   }
//   return null;
// }
