import { round, depthClone, min, sameNumber } from '@utils/globalUtils';
import { measureTextSize } from '@utils/textUtils';
import * as BoundsUtils from '@utils/boundsUtils';
import { isIntersetLine } from '@utils/boundsUtils';
import {
  getPathPlannerOfContainer,
  getSizeByPaths,
  getSearchPoint,
  getRealPoint,
  getSearchPointByRealPoint,
  offsetPointArray,
  getMidpointWithPath,
  convertPointArrayToPoint,
  getPositionByPaths,
  searchPathByPlanner,
  searchPathBySelf,
  judgeExtendWithPut,
  removePathPointByLine,
} from '@helpers/pathFinderHelper';
import { getDefaultComponentName } from '@libs/libs';
import { StyleHelper } from '@helpers/styleHelper';
import { IComponentData } from '@fbs/rp/models/component';
import { IConnectorLineValue } from '@fbs/rp/models/value';
import { IConnectorPoint } from '@fbs/rp/models/value';
import { IPoint, IPosition, ISize, IBounds } from '@fbs/common/models/common';
import ILine, { LinePointType } from '@/fbs/rp/models/properties/line';
import ITextFormat from '@/fbs/rp/models/properties/text';
import { Matrix } from '@utils/matrixUtils';
import { ArtboardPatches } from '@/fbs/rp/utils/patch';
import UIComponent, { IFindResult } from './UIComponent';

export interface IConnnectTextInfo {
  midpoint: IPoint;
  textCss: React.CSSProperties;
  textSize: ISize;
  textPosition: IPosition;
}

export interface IConnectorProperties {
  position: IPosition;
  strokeStyle: StyleHelper.ISVGStroke;
  strokeColor: string;
  strokeWidth: number;
  strokeDashArray?: string;
  textStyle?: ITextFormat;
  bgFill: React.CSSProperties;
  opacity: number;
  line: ILine;
}

export default class UIConnectorComponent extends UIComponent {
  private cachedPathVersion: string = '';
  private cachedPaths: number[] = [];
  private newPosition: IPosition = { x: 0, y: 0 };
  private newSize: ISize = { width: 0, height: 0 };
  private oldRealStartPoint: IPoint = { x: 0, y: 0 };
  private oldRealEndPoint: IPoint = { x: 0, y: 0 };

  chainedVersion(): string {
    let version = super.version;
    const parent = this.parent;
    if (!parent) {
      return version;
    }
    const startId = this.getStartCompID();
    const endId = this.getEndCompID();
    return parent.components
      .filter((comp) => !comp.isConnector && (comp.id === startId || comp.id === endId))
      .reduce((acc, curr) => {
        return acc + curr.version;
      }, version);
  }

  get position(): IPosition {
    this.doSearch();
    return this.newPosition;
  }

  get size(): ISize {
    this.doSearch();
    return this.newSize;
  }

  get paths(): number[] {
    const { path } = this.value as IConnectorLineValue;
    return path || [];
  }

  get catchPaths(): number[] {
    return this.cachedPaths;
  }

  set catchPaths(paths: number[]) {
    this.cachedPaths = [...paths];
  }

  get line(): ILine {
    const { arrow, line } = this.properties;
    const showStart = line?.startArrow || false;
    const showEnd = !!arrow?.value || line?.endArrow || false;
    return {
      startArrow: showStart,
      startPointType: (showStart && line?.startPointType) || LinePointType.none,
      endArrow: showEnd,
      endPointType: arrow?.value ? LinePointType.solidArrow : (showEnd && line?.endPointType) || LinePointType.none,
    };
  }

  get name() {
    return getDefaultComponentName(this.type, this.lib);
  }

  public getStartCompID(): string {
    const { startPoint } = this.value as IConnectorLineValue;
    return startPoint.id;
  }

  public getEndCompID(): string {
    const { endPoint } = this.value as IConnectorLineValue;
    return endPoint.id;
  }

  public getStartPoint(isReal?: boolean): IPoint {
    const { startPoint } = this.value as IConnectorLineValue;
    const parent = this.parent;
    if (!parent) {
      return {
        x: 0,
        y: 0,
      };
    }

    return isReal ? getRealPoint(parent, startPoint) : getSearchPoint(parent, startPoint);
  }

  public getEndPoint(isReal?: boolean) {
    const { endPoint } = this.value as IConnectorLineValue;
    const parent = this.parent;
    if (!parent) {
      return {
        x: 0,
        y: 0,
      };
    }
    return isReal ? getRealPoint(parent, endPoint) : getSearchPoint(parent, endPoint);
  }

  public getStartDirection(): string {
    const { startPoint } = this.value as IConnectorLineValue;
    return startPoint.direction;
  }

  public getEndDirection(): string {
    const { endPoint } = this.value as IConnectorLineValue;
    return endPoint.direction;
  }

  /**
   * 判断删除后是否是独立的线条
   *
   * @param selectIds
   */
  public isAloneLineAfterDelete(selectIds: string[]): boolean {
    const parent = this.parent;
    if (!parent) {
      return true;
    }
    const { startPoint, endPoint } = this.value as IConnectorLineValue;
    const startComp = parent.components.find((comp) => comp.id === startPoint.id);
    const endComp = parent.components.find((comp) => comp.id === endPoint.id);
    return (
      ((selectIds.includes(endPoint.id) || selectIds.includes(startPoint.id)) && (!startComp || !endComp)) ||
      (startPoint.id === endPoint.id && selectIds.includes(startPoint.id)) ||
      (selectIds.includes(startPoint.id) && selectIds.includes(endPoint.id))
    );
  }

  /**
   *  渲染连接线时的属性
   */
  public parseProperties(): IConnectorProperties {
    const position = { ...this.newPosition };
    const { opacity, line } = this;
    const properties = this.properties;
    const { stroke, textStyle } = properties;
    const parser2 = StyleHelper.initCSSStyleParser(properties);
    const strokeStyle = StyleHelper.parseSVGStroke(stroke!);
    const { strokeWidth: width, stroke: color, strokeDasharray: dash } = strokeStyle;
    const strokeColor = this.hidden ? '#fdaf32' : color;
    const strokeWidth = min(20, width && !this.hidden ? width : this.hidden ? 2 : 1);
    const strokeDashArray = this.hidden ? '3,4' : dash;
    return depthClone({
      position,
      strokeStyle,
      strokeColor,
      strokeWidth,
      strokeDashArray,
      textStyle,
      bgFill: parser2.getFillStyle(),
      opacity,
      line,
    });
  }

  /**
   * 文本样式整理
   */
  public parseStyle = (): React.CSSProperties => {
    const parser = StyleHelper.initCSSStyleParser(this.properties);
    return {
      ...parser.getTextStyle(), //这个方法默认是不换行
      whiteSpace: 'pre-wrap',
      outline: 'none',
    };
  };

  /**
   * 计算文本相关信息
   */
  public computedTextInfo(tempText?: string): IConnnectTextInfo {
    const midpoint = getMidpointWithPath(this.cachedPaths);
    const textCss = this.parseStyle();
    const fontSize = Number(textCss.fontSize) || 0;
    let textSize = { width: 0, height: 0 };
    const realText = tempText || this.text;
    if (realText) {
      //先不换行测量宽度是否超过最大宽度
      textSize = measureTextSize(textCss, realText, { isMultiText: true, wrap: false, isRich: true });
      if (textSize.width > 100) {
        textSize = measureTextSize(textCss, realText, {
          defaultWidth: 100,
          isMultiText: true,
          wrap: true,
          isRich: true,
        });
      }
    }

    // 字号小于12时，进行缩放制造更小的字号, 这里需要保持对应的大小，只缩小文字
    if (fontSize < 12 && fontSize) {
      textSize = {
        width: textSize.width / (fontSize / 12),
        height: textSize.height / (fontSize / 12),
      };
    }

    const textPosition = {
      x: Math.floor(midpoint.x - textSize.width / 2 - this.newPosition.x),
      y: Math.floor(midpoint.y - textSize.height / 2 - this.newPosition.y),
    };
    return {
      midpoint,
      textCss,
      textSize,
      textPosition,
    };
  }

  //判断第三个点是否在2个点之间
  pointBetweenTwoPoints(points: { x: number; y: number }[]) {
    const point1 = points[0];
    const point2 = points[1];
    const point3 = points[2];
    if (point1.x === point2.x && point1.x === point3.x) {
      return (
        ((point3.y > point1.y && point3.y < point2.y) ||
          (point3.y < point1.y && point3.y > point2.y) ||
          point3.y === point1.y) &&
        point1.y !== point2.y
      );
    }

    if (point1.y === point2.y && point1.y === point3.y) {
      return (
        ((point3.x > point1.x && point3.x < point2.x) ||
          (point3.x < point1.x && point3.x > point2.x) ||
          point3.x === point1.x) &&
        point1.x !== point2.x
      );
    }
  }

  // 获取2个点的偏移大小
  getOffSetBetweenPoints(point1: IPoint, point2: IPoint) {
    return {
      x: round(point1.x - point2.x),
      y: round(point1.y - point2.y),
    };
  }

  private doSearch(): number[] {
    //版本号未发生改变，则使用缓存的路径，不重新进行计算
    if (this.chainedVersion() === this.cachedPathVersion) {
      return this.cachedPaths;
    }

    let path: number[] = [];
    const realStart = this.getStartPoint(true);
    const realEnd = this.getEndPoint(true);

    //当手动控制点不存在或者开始结束点发生变化时，忽略手动路径
    if (!this.paths.length) {
      const parent = this.parent!;
      const start = this.getStartPoint();
      const end = this.getEndPoint();

      if (Math.hypot(end.x - start.x, end.y - start.y) < 100) {
        path = [realStart.x, realStart.y];
        switch (this.getStartDirection()) {
          case 'top':
          case 'bottom':
            path.push(realStart.x, realEnd.y);
            break;
          case 'right':
          case 'left':
            path.push(realEnd.x, realStart.y);
            break;
        }
        path.push(realEnd.x, realEnd.y);
      } else {
        //如果开始点与起始点偏移同样的位置，那么2端绑定的组件该是被一起移动了
        const beginOffset = this.getOffSetBetweenPoints(realStart, this.oldRealStartPoint);
        const endOffset = this.getOffSetBetweenPoints(realEnd, this.oldRealEndPoint);
        if (
          (beginOffset.x !== 0 || beginOffset.y !== 0) &&
          sameNumber(beginOffset.x, endOffset.x) &&
          sameNumber(beginOffset.y, endOffset.y)
        ) {
          path = offsetPointArray(this.cachedPaths, { offsetX: beginOffset.x, offsetY: beginOffset.y });
        } else {
          // 自动寻路
          // 创建planner
          const { planner, bigBounds, scaleNum } = getPathPlannerOfContainer(parent);
          // 搜索
          path = searchPathByPlanner(start, end, planner, bigBounds, scaleNum);
          // 无Path硬处理为折线
          if (!path.length) {
            path = searchPathBySelf(start, end);
          }
          path.unshift(realStart.x, realStart.y);
          path.push(realEnd.x, realEnd.y);
        }
      }
    } else {
      path = [...this.paths];
    }

    //清除在一条线上的中间点, 整理清除相同的点
    path = removePathPointByLine(path, { removeSamePoint: true });
    //判断是否具有拓展点，没有则补充上
    path = judgeExtendWithPut(path);

    this.newPosition = getPositionByPaths(path);
    this.newSize = getSizeByPaths(path);
    this.cachedPathVersion = this.chainedVersion();
    this.cachedPaths = [...path];
    this.oldRealStartPoint = { x: realStart.x, y: realStart.y };
    this.oldRealEndPoint = { x: realEnd.x, y: realEnd.y };

    return path;
  }

  public searchPaths(): number[] {
    return this.doSearch();
  }

  // 根据传入的开始和结束点计算路径
  public searchPathsByPoint(newStart: IConnectorPoint, newEnd: IConnectorPoint) {
    const realStart = { x: newStart.x, y: newStart.y };
    const realEnd = { x: newEnd.x, y: newEnd.y };
    const start = getSearchPointByRealPoint(realStart, newStart.direction);
    const end = getSearchPointByRealPoint(realEnd, newEnd.direction);

    const { planner } = getPathPlannerOfContainer(this.parent!);
    const path: number[] = [];
    planner.search(start.x, start.y, end.x, end.y, path);
    if (!path.length) {
      const centerPoint = {
        x: start.x,
        y: end.y,
      };

      let arr: number[] = [start.x, start.y, centerPoint.x, centerPoint.y, end.x, end.y];
      if (realStart.x === realEnd.x || realStart.y === realEnd.y) {
        arr = [];
      } else {
        if (this.pointBetweenTwoPoints([end, centerPoint, realEnd])) {
          arr.splice(4);
        }
        if (this.pointBetweenTwoPoints([start, centerPoint, realStart])) {
          arr.splice(0, 2);
        }
      }
      path.push(...arr);
    }

    path.unshift(realStart.x, realStart.y);
    path.push(realEnd.x, realEnd.y);

    return {
      newPosition: getPositionByPaths(path),
      newSize: getSizeByPaths(path),
    };
  }

  /**
   * 判断路径是否经过某个区域
   * @param {IBounds} bounds
   * @param {Matrix} matrix,
   * @param {"page" | "artboard"} boundsReference 区域是相对于画板的，还是相对于页面的
   * @param {boolean} container
   * @returns {boolean}
   */
  intersectBounds(bounds: IBounds, matrix: Matrix, boundsReference: 'page' | 'artboard', container?: boolean): boolean {
    if (container) {
      return super.intersectBounds(bounds, matrix, boundsReference, container);
    }
    const parent = this.parent!;
    let compBounds: IBounds;
    if (boundsReference === 'page') {
      compBounds = parent.getViewBoundsInPage(matrix);
    } else {
      compBounds = parent.getViewBoundsInArtboard(matrix);
    }
    const points = convertPointArrayToPoint(this.cachedPaths);
    const lines: { start: IPoint; end: IPoint }[] = [];
    const { left, top } = compBounds;
    for (let i = 1, c = points.length; i < c; i++) {
      const p1 = points[i - 1];
      const p2 = points[i];
      lines.push({
        start: {
          x: p1.x + left,
          y: p1.y + top,
        },
        end: {
          x: p2.x + left,
          y: p2.y + top,
        },
      });
    }
    return lines.some((line) => {
      return isIntersetLine(bounds, line);
    });
  }

  /**
   * 编组时，修改对应的点的坐标及相对位置, 如果固定的组件没有一起成组，则解绑固定的组件
   * @param newGroupBounds
   * @param selectedCompsIDs
   */
  resetConnectPointWhenGroup(newGroupBounds: IBounds, selectedCompsIDs: string[]) {
    const realStart = this.getStartPoint(true);
    const realEnd = this.getEndPoint(true);
    const data: IComponentData = depthClone(this.data);
    const { startPoint, endPoint, path: oldPath } = data.value;
    const start = depthClone(startPoint);
    const end = depthClone(endPoint);
    const path = depthClone(oldPath);
    if (!startPoint || !selectedCompsIDs.includes(startPoint.id)) {
      start.id = '';
      start.direction = '';
    }
    start.x = round(realStart.x - newGroupBounds.left);
    start.y = round(realStart.y - newGroupBounds.top);

    if (!endPoint || !selectedCompsIDs.includes(endPoint.id)) {
      end.id = '';
      end.direction = '';
    }
    end.x = round(realEnd.x - newGroupBounds.left);
    end.y = round(realEnd.y - newGroupBounds.top);

    data.value['startPoint'] = start;
    data.value['endPoint'] = end;
    path?.length &&
      (data.value['path'] = offsetPointArray(path, {
        offsetX: 0 - newGroupBounds.left,
        offsetY: 0 - newGroupBounds.top,
      }));
    return data;
  }

  /**
   * 解组时的位置
   */
  resetConnectPointWhenUnGroup() {
    const realStart = this.getStartPoint(true);
    const realEnd = this.getEndPoint(true);
    const parent = this.parent!;
    const afterBounds = parent.getViewBoundsInParent();
    const data: IComponentData = depthClone(this.data);
    const { startPoint, endPoint, path: oldPath } = data.value;
    const start = depthClone(startPoint);
    const end = depthClone(endPoint);
    const path = depthClone(oldPath);
    start.x = round(realStart.x + afterBounds.left - 1);
    start.y = round(realStart.y + afterBounds.top - 1);
    end.x = round(realEnd.x + afterBounds.left - 1);
    end.y = round(realEnd.y + afterBounds.top - 1);
    data.value['startPoint'] = start;
    data.value['endPoint'] = end;
    path?.length &&
      (data.value['path'] = offsetPointArray(path, {
        offsetX: afterBounds.left,
        offsetY: afterBounds.top,
      }));
    return data;
  }

  /**
   * 根据偏移修正组件的点
   * @param diff
   * @param disconnect 可配置是否保留原来的连接组件id
   */
  modifyConnectPointByDiff(diff: { x: number; y: number }, disconnect?: { start?: boolean; end?: boolean }) {
    const realStart = this.getStartPoint(true);
    const realEnd = this.getEndPoint(true);
    const data: IComponentData = depthClone(this.data);
    const { startPoint, endPoint } = data.value;
    data.value['startPoint'] = {
      id: disconnect?.start ? '' : startPoint.id,
      direction: disconnect?.start ? '' : startPoint.direction,
      x: realStart.x + diff.x,
      y: realStart.y + diff.y,
    };
    data.value['endPoint'] = {
      id: disconnect?.end ? '' : endPoint.id,
      direction: disconnect?.end ? '' : endPoint.direction,
      x: realEnd.x + diff.x,
      y: realEnd.y + diff.y,
    };
    this.paths?.length &&
      (data.value['path'] = offsetPointArray(this.paths, {
        offsetX: diff.x,
        offsetY: diff.y,
      }));

    return data;
  }

  /**
   * 判断点是否在路径上
   * @param {{x: number, y: number}} point 相对于组件本身的一个坐标点
   * @param deviation 允许的偏差值，默认为8
   * @returns {boolean}
   */
  pointAtPath(point: { x: number; y: number }, deviation: number = 8) {
    const isPointAtLine = (p1: { x: number; y: number }, p2: { x: number; y: number }) => {
      let bounds: IBounds;
      const { left, top, right, bottom } = BoundsUtils.getBoundsWithPoints([p1, p2]);
      if (sameNumber(p1.y, p2.y)) {
        bounds = BoundsUtils.init({ left, top: top - deviation }, { left: right, top: bottom + deviation });
      } else {
        bounds = BoundsUtils.init({ left: left - deviation, top }, { left: right + deviation, top: bottom });
      }
      return BoundsUtils.isContainerPoint(bounds, { left: point.x, top: point.y });
    };
    if (this.cachedPaths) {
      const points = convertPointArrayToPoint(this.cachedPaths);
      let minLeft = points[0].x;
      let minTop = points[0].y;
      points.forEach((pt) => {
        minLeft = min(minLeft, pt.x);
        minTop = min(minTop, pt.y);
      });

      points.forEach((pt) => {
        pt.x -= minLeft;
        pt.y -= minTop;
      });

      let count = 0;
      while (count < points.length - 1) {
        const value = isPointAtLine(points[count], points[count + 1]);
        if (value) {
          return value;
        }
        count++;
      }
    }
    return false;
  }

  public matchText(text: string, pageID: string): IFindResult[] {
    const currentRichTextValue = this.text;
    return this.doMatchRichText(currentRichTextValue, text, pageID);
  }

  public replaceText(matchInfo: IFindResult['match'], text: string, newText: string): ArtboardPatches {
    const currentRichTextValue = this.text;
    const newValue = this.doReplaceRichText(currentRichTextValue, matchInfo, text, newText);
    const patches = this.setText(newValue);
    window.debug && console.log('replaceText patches: ', patches);
    return patches;
  }

  public replaceAllText(matchInfo: IFindResult['match'], text: string, newText: string): ArtboardPatches {
    const currentRichTextValue = this.text;
    const newValue = this.doReplaceAllRichText(currentRichTextValue, matchInfo, text, newText);
    const patches = this.setText(newValue);
    window.debug && console.log('replaceText patches: ', patches);
    return patches;
  }
}
