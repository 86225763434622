import * as React from 'react';
import Button from '../Button';
import i18n from '../../i18n';
const mobileDialogPic1 = require('./images/mobile-dialog1.png');
const mobileDialogPic2 = require('./images/mobile-dialog2.png');
const loadingGif = require('./images/Loading.gif');

import './index.scss';

const pic = Math.floor(Math.random() * 10000) % 2 === 0 ? mobileDialogPic1 : mobileDialogPic2;

interface IMobileDialogProps {
  onClose(): void;
}

interface IMobileDialogState {
  mobileAppLoading: boolean;
  openAppFail: boolean;
  downLoadMobileApp: boolean;
}

class MobileDialog extends React.Component<IMobileDialogProps, IMobileDialogState> {
  constructor(props: IMobileDialogProps) {
    super(props);
    this.state = {
      mobileAppLoading: false,
      openAppFail: false,
      downLoadMobileApp: true,
    };
    this.mobileOpenApp = this.mobileOpenApp.bind(this);
    this.clearMobileTimeID = this.clearMobileTimeID.bind(this);
  }

  componentDidMount() {
    window.addEventListener('visibilitychange', this.clearMobileTimeID);
  }

  componentWillUnmount() {
    window.removeEventListener('visibilitychange', this.clearMobileTimeID);
  }

  mobileOpenApp = () => {
    const { onClose } = this.props;
    window.location.href = 'mockplus://cn.mockplus.www';
    this.setState({
      mobileAppLoading: true,
    });
    window.setTimeout(() => {
      const { downLoadMobileApp } = this.state;
      if (downLoadMobileApp) {
        this.setState({
          openAppFail: true,
          mobileAppLoading: false,
        });
      } else {
        onClose();
      }
    }, 4000);
  };

  downloadApp = () => {
    const UA = window.navigator.userAgent;
    const isAndroid = UA.indexOf('Android') > -1 || UA.indexOf('Adr') > -1; // android终端
    window.location.href = isAndroid
      ? `${i18n('mobileDialog.appUrl')}/lark/pc-download/android`
      : `${i18n('mobileDialog.appUrl')}/lark/pc-download/ios`;
  };

  clearMobileTimeID() {
    this.setState({
      downLoadMobileApp: false,
    });
  }

  render() {
    const { onClose } = this.props;
    const { mobileAppLoading, openAppFail } = this.state;
    if (openAppFail) {
      return (
        <div className="mobile-dialog mobile-dialog-fail">
          <div className="mobile-content">
            <div className="title">{i18n('mobileDialog.openAppFail')}</div>
            <div className="text">
              <p>{i18n('mobileDialog.downloadApp')}</p>
              <p>{i18n('mobileDialog.openManually')}</p>
            </div>
            <div className="buttons">
              <Button onClick={onClose} background="white">
                {i18n('mobileDialog.cancel')}
              </Button>
              <Button onClick={this.downloadApp}>{i18n('mobileDialog.download')}</Button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="mobile-dialog">
        {mobileAppLoading ? (
          <div className="mobile-loading">
            <img src={loadingGif} />
          </div>
        ) : (
          <div className="mobile-content">
            <img src={pic} alt="" />
            <div className="contents">
              <div className="title">{i18n('mobileDialog.title')}</div>
              <div className="text">{i18n('mobileDialog.text')}</div>
              <div className="buttons">
                <Button onClick={onClose} background="white">
                  {i18n('mobileDialog.ok')}
                </Button>
                <Button onClick={this.mobileOpenApp}>{i18n('mobileDialog.openApp')}</Button>
              </div>
              <div className="other">
                {i18n('mobileDialog.unInstall')}
                <span className="red" onClick={this.downloadApp}>
                  {i18n('mobileDialog.clickDownload')}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MobileDialog;
