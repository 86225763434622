import * as React from 'react';
import classnames from 'classnames';

import { ISize } from '@utils/boundsUtils';
import { isEqualDate } from '@utils/globalUtils';

import { ScrollBars } from '@dsm';

import { IFragmentAction, FragmentPositionMode } from '@fbs/rp/models/interactions';

import { getSizeFromOrientation } from '@/helpers/artboardDimensionHelper';
import { getClassNameAndStyleOfFragmentAction } from '@helpers/interactionHelper';

import Theater, { PreviewCache } from '../../../../../theater';
import Page from '../../../Page';

import './index.scss';
import { Orientation } from '@fbs/common/models/common';

export interface IPageShellProp {
  page: Theater;
  pageType: 'current' | 'next';
  appSize: ISize;
  viewPortSize: ISize;
  orientation?: Orientation;
}

export interface IPageShellState {
  activeFragmentAction?: IFragmentAction;
}

class PageShell extends React.Component<IPageShellProp, IPageShellState> {
  static defaultProps: Partial<IPageShellProp> = {};
  private scrollBar: React.RefObject<ScrollBars> = React.createRef();
  public globalScale = 0;

  selfRef: React.RefObject<HTMLDivElement>;
  page: React.RefObject<Page>;

  constructor(props: IPageShellProp) {
    super(props);
    this.state = {};
    this.selfRef = React.createRef();
    this.page = React.createRef();
  }

  componentDidMount() {
    this.doCachePageBounds();
    this.doCalcScale();
  }

  componentDidUpdate() {
    this.doCachePageBounds();
  }

  doCalcScale = (props = this.props) => {
    const { viewPortSize } = props;
    this.globalScale = viewPortSize.width / this.rotatedAppSize.width;
    window.pageScale = this.globalScale;
  };

  private doCachePageBounds() {
    if (this.selfRef.current) {
      const { left, top, right, bottom, width, height } = this.selfRef.current.getBoundingClientRect();
      PreviewCache['pageBounds'] = { left, top, right, bottom, width, height };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: IPageShellProp) {
    if (!isEqualDate(this.state.activeFragmentAction, nextProps.page.activeFragmentAction)) {
      this.setState({ activeFragmentAction: nextProps.page.activeFragmentAction });
    }
    if (nextProps.page.doc.pageID !== this.props.page.doc.pageID) {
      this.scrollBar.current?.scrollToTop();
      this.scrollBar.current?.scrollToLeft();
    }
    this.doCalcScale(nextProps);
  }

  getSkipStyleAndClassName(
    page: Theater | undefined,
    pageType: 'current' | 'next',
  ): { style: React.CSSProperties; classNames: string } {
    let animationName = 'null';
    const style: React.CSSProperties = {};
    if (!page) {
      return { style, classNames: '' };
    }
    const { animationEffect } = page;
    if (animationEffect) {
      style.animationTimingFunction = animationEffect.animation.effect;
      style.animationDuration = `${animationEffect.animation.duration || 1}ms`;
      animationName = animationEffect.params;
      style.animationFillMode = `${pageType == 'next' ? 'forwards' : ''}`;
    }
    const classNames = classnames([`${pageType}-${animationName}`], [`${pageType}-page`]);
    return {
      style,
      classNames,
    };
  }

  /**
   * 点击退出辅画板叠加
   * @memberof DeviceShell
   */
  handleEndFragment = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { onEndFragmentAction, activeFragmentAction } = this.props.page;
    if (onEndFragmentAction && activeFragmentAction) {
      onEndFragmentAction(activeFragmentAction);
    }
  };

  /**
   * 画板动画结束
   * @memberof DeviceShell
   */
  handleFragmentAnimationEnd = () => {
    const { activeFragmentAction } = this.state;
    if (activeFragmentAction && activeFragmentAction.isExit) {
      this.handleFragmentActionClean(this.props.page.removeFragmentAction);
    }
  };

  /**
   * 清除动画
   */
  handleFragmentActionClean = (fn: Function) => {
    this.setState({ activeFragmentAction: undefined }, () => {
      fn && fn();
    });
  };

  /**
   * 页面动画结束
   */
  handleAnimationEnd = () => {
    this.handleFragmentAnimationEnd();
    if (this.props.pageType === 'next') {
      this.props.page.pageAnimationEnd();
    }
  };

  renderFragment = (scale: number, action: IFragmentAction, initialStyle?: React.CSSProperties) => {
    const fragment = this.page.current?.getActiveFragment(action.target);
    if (!fragment) {
      return null;
    }
    const style: React.CSSProperties = {
      height: fragment.size.height * (scale || 1),
      width: fragment.size.width * (scale || 1),
    };
    const { animationStyle, bgStyle, keyframeName } = getClassNameAndStyleOfFragmentAction(action, {
      fragment,
      scale,
      deviceSize: this.props.appSize,
    });
    const classNames = classnames('active-fragment', keyframeName);

    return (
      <div
        key={fragment.artboardID}
        className="fragment-bg"
        style={{ ...(initialStyle || {}), ...bgStyle }}
        onClick={this.props.page.closeFragmentByAction.bind(this.props.page, action, this.forceUpdate.bind(this))}
      >
        <div
          className={classNames}
          onAnimationEnd={!keyframeName.endsWith('null') ? this.handleFragmentAnimationEnd : undefined}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              this.props.page.closeFragmentByAction(action, this.forceUpdate.bind(this));
            }
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            e.stopPropagation();
          }}
          onContextMenu={(e) => {
            e.stopPropagation();
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
            this.page.current?.handlePageDown();
          }}
          onMouseUp={(e) => {
            e.stopPropagation();
            this.page.current?.handlePageUp();
          }}
          onTouchStart={(e) => {
            e.stopPropagation();
            this.page.current?.handleTouchStart(e);
          }}
          onTouchEnd={(e) => {
            e.stopPropagation();
            this.page.current?.handleTouchEnd(e);
          }}
          onTouchMove={(e) => {
            e.stopPropagation();
            this.page.current?.handleTouchMove(e);
          }}
          style={{
            ...animationStyle,
            ...style,
          }}
        >
          <div style={{ transform: `scale(${scale}, ${scale})`, transformOrigin: '0 0' }}>
            {fragment && this.page.current?.renderComponents(fragment, 1)}
          </div>
        </div>
      </div>
    );
  };

  get rotatedAppSize() {
    const { page, appSize, orientation } = this.props;
    const { mainArtboard } = page.doc;
    return getSizeFromOrientation(orientation ?? mainArtboard.orientation, appSize);
  }

  update = () => {
    this.forceUpdate();
  };

  private get nonCustomFragmentCommands() {
    const { page, pageType } = this.props;
    if (pageType !== 'current') {
      return [];
    }
    return Array.from(page.fragmentCommandMap?.values() ?? []).filter((item) => {
      return item.params.mode !== FragmentPositionMode.Custom;
    });
  }

  render() {
    const { activeFragmentAction } = this.state;
    const { page, pageType } = this.props;
    const { width, height } = page.doc.mainArtboard.size;
    const shellScale = this.globalScale;

    const { style, classNames } = this.getSkipStyleAndClassName(page, pageType);
    return (
      <div
        className={'preview-device-shell ' + classNames}
        style={style}
        ref={this.selfRef}
        onAnimationEnd={this.handleAnimationEnd}
      >
        <ScrollBars ref={this.scrollBar} className="no-scale-scroll" style={{ width: '100%', height: '100%' }}>
          <div
            className="page-view"
            style={{
              width: width * shellScale,
              height: height * shellScale,
              transformOrigin: '0 0',
            }}
          >
            <div
              style={{
                width,
                height,
                transformOrigin: '0 0',
                transform: `scale(${shellScale})`,
              }}
            >
              <Page
                ref={this.page}
                page={page}
                pageType={pageType}
                action={activeFragmentAction}
                scale={1}
                onParentForceUpdate={this.update}
                onEndFragment={this.handleEndFragment}
                onFragmentAnimationEnd={this.handleFragmentAnimationEnd}
              />
            </div>
            {this.page.current?.renderFloatComps(page.doc.mainArtboard, {
              scale: 1,
              style: { position: 'fixed', top: 0, left: 0, transform: `scale(${shellScale}, ${shellScale})` },
            })}
            {this.page.current?.renderCustomFragmentCommands(shellScale)}
          </div>
        </ScrollBars>
        {!!this.nonCustomFragmentCommands &&
          this.nonCustomFragmentCommands.map((item) => this.renderFragment(shellScale, item))}
        <div className="bring-front-comps"></div>
      </div>
    );
  }
}

export default PageShell;
