import * as React from 'react';
import i18n from '../../../i18n';

import { IItemProps, IGroupProps } from '../../../models';
import SelectItem from './SelectItem';
import ScrollView from '../../ScrollView';

import './index.scss';

interface ISelectListProps {
  items: IItemProps[];
  checked: string[];
  groups?: IGroupProps[];
  showAddGroup?: boolean;

  onCheck(ids: string[]): void;
  onCheckDisable?(id: string): void;
}

const SelectList: React.FC<ISelectListProps> = (props: ISelectListProps) => {
  const { items, checked, groups, showAddGroup, onCheck, onCheckDisable } = props;
  // @ts-ignore
  const disabledList = items.filter((item) => item.disabled);
  const disabledIDs = (!!disabledList.length && disabledList.map((item) => item.id)) || [];
  const groupIDs = groups?.map((item) => item.id);
  const memberCount = showAddGroup ? items.filter((item) => !groupIDs?.includes(item.id)).length : items.length;
  const groupCount = showAddGroup ? items.filter((item) => groupIDs?.includes(item.id)).length : 0;
  return (
    <div className="select-box">
      <div className="select-header">
        <span>{i18n('checkSelect.hasSelected', memberCount)}</span>
        {groupCount > 0 && <span>{i18n('checkSelect.hasSelectedGroup', groupCount)}</span>}
        <span className="remove-all" onClick={() => onCheck(disabledIDs)}>
          {i18n('checkSelect.clearAll')}
        </span>
      </div>
      <div className="select-list">
        <ScrollView height="100%">
          {items.map((item: IItemProps) => (
            <SelectItem
              key={item.id}
              item={item}
              onRemove={() => {
                onCheck(checked.filter((check) => item.id !== check));
              }}
              onCheckDisable={onCheckDisable}
            />
          ))}
        </ScrollView>
      </div>
    </div>
  );
};

export default SelectList;
