import { IComponentValue } from '@fbs/rp/models/value';
import { TextAlign, VerticalAlign } from '@fbs/rp/models/properties/text';
import { PureColor, Color } from '@fbs/rp/models/properties/color';
import { IUserPreference } from '@fbs/rp/models/grid';
import ITextFormatEx from '@/fbs/rp/models/properties/textFormat';
import { ITypographyValue } from '@fbs/rp/models/ds/typography';
import { ISize } from '@fbs/common/models/common';

import { UIComponent, UIFragment, UITableComponent } from '@editor/comps';

import { ISegment, SegType } from './path';

export interface IControl {
  isContainerPagePoint(x: number, y: number): boolean;
}

export interface IFocusComponent {
  focus(): void;
}

export interface IWorkSpace extends IFocusComponent {
  triggerComponentHover(forceUpdateComps: string[], hovered?: boolean): void;

  activeArtboard(artboardID: string): void;

  scrollToSelectCenter: (force?: boolean, cb?: () => void) => void;

  enter(): void;

  popupCreateSymbolDialog(): void;

  showGradientControl(isShow: boolean): void;

  readonly isCroping: boolean;

  doGridShow: (payload: { isShow?: boolean; userPreferencePayload?: IUserPreference }) => Partial<IUserPreference>;
  doLayoutShow: (payload: { isShow?: boolean; userPreferencePayload?: IUserPreference }) => Partial<IUserPreference>;

  readonly page: IPage | null;

  // readonly canCallSystemPaste: boolean;

  getInteractionPageIds: (comp?: UIComponent) => string[] | undefined;

  doQuickAddComponent: (key: string) => void;

  computedScaleByType: (type: string) => void;

  readonly pagePosition: { x: number; y: number };

  movePageTo(position: { x: number; y: number }): void;
  movePageBy(offset: { x: number; y: number }): void;
  movePageToActiveFragmentPosition(activeFragment: UIFragment): void;
  movePageToComponentPosition(component: UIComponent): void;

  clearScroll(): void;

  readonly dom: HTMLElement | null;
  readonly isDragMoving: boolean;

  getWorkClient(): { left: number; top: number; right: number; bottom: number; width: number; height: number };
  // artboard移动状态
  changeIsMovingArtboard(isMovingArtboard: boolean): void;
}

export interface ITextEditor extends IFocusComponent {
  blur(): void;

  getFormat(): any;

  readonly isSelected: boolean;

  changeFormatWithHotKey(hotKey: string): void;

  setFontSize(size: number, disabledEnter?: boolean): void;

  execRichTextCommand(command: string, value?: any, param?: boolean | undefined, fn?: Function | undefined): void;

  cancel: () => void;

  readonly focused: boolean;
}

export interface IPathEditor {
  selectedSegments: ISegment[];
  setSegmentsRadius?: (radius: number, shouldSubmit?: boolean) => void;
  setSegmentsTypes?: (type: SegType) => void;
}

export enum ValueEditorValueType {
  StringType = 'string',
  IconType = 'icon',
  ImageType = 'image',
  CustomType = 'custom',
  OtherType = 'other',
}

export interface IValueEditor {
  setValue(value: IComponentValue): void;
  replaceIconValue?(value: IComponentValue): void; // 图标替换
  close?(): void;
  canReplaceIcon: boolean;
  readonly valueType: ValueEditorValueType;
}
export interface ITableEditor {
  tableComponent: UITableComponent;
  selectRows: number[];
  selectColumns: number[];
  selectArea?: {
    start: { row: number; column: number };
    end: { row: number; column: number };
  };
  // 是否在文本编辑中
  textEditing: boolean;

  setRowsHeight?: (height: number, inputValue?: string) => void;
  setColumnsWidth?: (width: number) => void;
  setTextAlign?: (align: TextAlign) => void;
  setVerticalAlign?: (align: VerticalAlign) => void;
  setCellsBorder?: (width: number | undefined, color: Color | undefined, mode?: string) => void;
  setFill?: (fill: PureColor) => void;
  setTextFormat?: (key: keyof ITextFormatEx, value: ITextFormatEx[keyof ITextFormatEx]) => void;
  setTextColor?: (fill: PureColor) => void;
  applyTypography?: (value: ITypographyValue) => void;
}

export interface IStatePanel extends IControl {}

export interface IPage {
  startFindInteractionTarget(e: React.MouseEvent, comp: UIComponent): void;
}

export interface ISelectionBox {
  refresh: () => void;
}

export interface IInteractionPanel {
  refresh: () => void;
}

export interface IValueEditorPanel {
  // preventClose?: boolean;
}

export interface IGradientController {
  refresh(): void;

  setSelectedIndex(index: number): void;
}

export interface IRecycleBin {
  recoverByPageID(ids: string[]): void;
}

export interface IProjectTree {
  showTipWhenPageExceedsMaximum(
    operateType: 'add' | 'recover',
    ids: string[],
    fun?: Function,
    addPageNum?: number,
  ): void;

  refresh(comp?: UIComponent): void;
}

export interface IResourceLibraryPanel {
  update(): void;
}

export interface OnAddComponentByComponentType {
  (lib: string, componentType: string, value?: any, size?: ISize, name?: string): void;
}

export interface IUICompConstructOptions {
  isPreview?: boolean;
  isInAdvanceEditor?: boolean;
  isContentPanelEditor?: boolean;
}
