import { IcomponentLibGroup, IComponentCustomLib } from '@libs/types';
import { ActionType } from '../types';

import { ComponentAction, ComponentDataMap } from './actions';

export interface ICustomComponentsState {
  customComponetLibs: IcomponentLibGroup<IComponentCustomLib>[];
  customComponentDataMap: ComponentDataMap;
}

const initState: ICustomComponentsState = {
  customComponetLibs: [],
  customComponentDataMap: {},
};

export default function (state: ICustomComponentsState = initState, action: ComponentAction): ICustomComponentsState {
  switch (action.type) {
    case ActionType.CustomComponentLibs_SetComponentsLibs:
      return {
        ...state,
        customComponetLibs: action.payload,
      };
    case ActionType.CustomComponentLibs_UpdateComponentsDataMap:
      return {
        ...state,
        customComponentDataMap: {
          ...state.customComponentDataMap,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
