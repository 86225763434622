import { isUndefined } from 'lodash';
import { round, min, max, abs, floor, depthClone } from '@utils/globalUtils';
import { ISize } from '@utils/boundsUtils';
import { getRotateOffset } from '@utils/rotateUtils';
import * as BoundsUtils from '@utils/boundsUtils';
import { CustomObservableObject } from '@/utils/customObservableUtils';

import { IPoint, IBounds, IPosition } from '@fbs/common/models/common';
import { MoveDelta } from '@fbs/common/models/resize';
import { IComponentData, IComponentSize } from '@fbs/rp/models/component';

import { Cursors } from '@consts/resources/cursor';
import { minArtboardSize, maxArtboardSize, maxControlSize, maxArtboardHeight } from '@consts/artboard';

import { UIFragment, UIArtboard, UIComponent, UIContainerComponent, UIGroupComponent } from '@editor/comps';
import CoreEditor from '@editor/core';
import ContentPanelEditor from '@editor/contentPanelEditor';

import { getLibByShortCutOrType, getNameForNewComponent, makeComponent } from '@libs/libs';
import { CText, CLine, CPath } from '@libs/constants';
import { IUserPreference, IGridSettings, ILayoutSettings, IAlignConfig } from '@/fbs/rp/models/grid';
import { PureColor } from '@/fbs/rp/models/properties/color';
import { ILineValue } from '@/fbs/rp/models/value';
import { TextAlign } from '@/fbs/rp/models/properties/textFormat';
import { getCompAbsoluteMatrix, getCompsBoundsInArtboard } from '@helpers/componentHelper';

import { onPathValueZoom } from './pathHelper';
import { getUrlBase64, IMGAGE_EXTENSION } from './fileUploadHelper';
import appOptions from './appOptions';

const CustomDefaultAddCompCursor = `url(${Cursors.DrawDefault}) 12 12, default`;

//因为 workspace里面有一个isMouseDown,但是没有放在state,更新实时渲染太耗性能，这里单独监听一下这个字段
export const mouseObservableObject = new CustomObservableObject({
  isMouseDown: false,
}) as CustomObservableObject<{ isMouseDown: boolean }>;

export const showPixelGridScaleValue = 6;

const CursorsWithType: { type: string; url: string }[] = [
  { type: 'nw-resize', url: Cursors.SouthEastOrNorthWest, position: { x: 10, y: 10 }, defaultType: 'nw-resize' },
  { type: 'ne-resize', url: Cursors.NorthEastOrWestSouth, position: { x: 10, y: 10 }, defaultType: 'ne-resize' },
  { type: 'se-resize', url: Cursors.SouthEastOrNorthWest, position: { x: 10, y: 10 }, defaultType: 'nw-resize' },
  { type: 'sw-resize', url: Cursors.NorthEastOrWestSouth, position: { x: 10, y: 10 }, defaultType: 'sw-resize' },
  { type: 'ns-resize', url: Cursors.NorthASouth, position: { x: 10, y: 10 }, defaultType: 'ns-resize' },
  { type: 'ew-resize', url: Cursors.WestAEast, position: { x: 10, y: 10 }, defaultType: 'ew-resize' },
  { type: 'move', url: Cursors.Rotate, position: { x: 10, y: 10 }, defaultType: 'move' },

  { type: 'add-R', url: Cursors.DrawRect, position: { x: 8, y: 17 }, defaultType: 'crosshair' },
  { type: 'add-O', url: Cursors.DrawCircle, position: { x: 8, y: 17 }, defaultType: 'crosshair' },
  { type: 'add-L', url: Cursors.DrawLine, position: { x: 12, y: 12 }, defaultType: 'crosshair' },
  { type: 'add-T', url: Cursors.DrawText, position: { x: 12, y: 12 }, defaultType: 'text' },
  { type: 'add-I', url: Cursors.DrawImg, position: { x: 12, y: 12 }, defaultType: 'crosshair' },
  { type: 'add-B', url: Cursors.DrawBtn, position: { x: 12, y: 12 }, defaultType: 'crosshair' },
  { type: 'add-W', url: Cursors.DrawLine, position: { x: 12, y: 12 }, defaultType: 'crosshair' },
  { type: 'add-Default', url: Cursors.DrawDefault, position: { x: 12, y: 12 }, defaultType: 'crosshair' },

  { type: 'drag', url: Cursors.Drag, position: { x: 12, y: 12 }, defaultType: 'drag' },
  { type: 'drop', url: Cursors.Drop, position: { x: 12, y: 12 }, defaultType: 'drop' },

  { type: 'Pen', url: Cursors.Pen, position: { x: 7, y: 3 }, defaultType: 'default' },
  { type: 'PenAdd', url: Cursors.PenAdd, position: { x: 7, y: 3 }, defaultType: 'default' },
  { type: 'PenMove', url: Cursors.PenMove, position: { x: 6, y: 6 }, defaultType: 'default' },
  { type: 'PenClose', url: Cursors.PenClose, position: { x: 6, y: 3 }, defaultType: 'default' },
  { type: 'Shears1', url: Cursors.Shears1, position: { x: 18, y: 12 }, defaultType: 'default' },
  { type: 'Shears2', url: Cursors.Shears2, position: { x: 18, y: 12 }, defaultType: 'default' },
  { type: 'Pencil', url: Cursors.Pencil, position: { x: 5, y: 20 }, defaultType: 'default' },
].map((info) => {
  // 离线演示包不需要执行，会报错
  !RP_CONFIGS.isOfflineDemo &&
    getUrlBase64(
      info.url,
      IMGAGE_EXTENSION.PNG,
      (url, data) => {
        const { position, defaultType } = data;
        info.url = `url(${url}) ${position?.x} ${position?.y}, ${defaultType}`;
      },
      info,
    );
  return info;
});

//鼠标是否超出工作区的返回类型
export interface WorkspaceOutStatus {
  left: boolean;
  top: boolean;
  right: boolean;
  bottom: boolean;
}

// 获取默认鼠标没有超出工作区
export function defaultWorkspaceOutStatus(): WorkspaceOutStatus {
  return { left: false, top: false, right: false, bottom: false };
}

/**
 * 判断一个点是否超过某个区域
 *
 * @param point
 * @param areaBounds
 * @param boundaryThreshold （可选）面积四周缩小多少，作为感应区
 * @returns 具体哪些方向超过了
 * @description 点和区域数据的相对对象应该一致
 */
export function judgePointOverAreaDeriction(
  point: IPoint,
  areaBounds: ClientRect | IBounds,
  boundaryThreshold?: number,
) {
  let left = false;
  let top = false;
  let right = false;
  let bottom = false;
  const workSpaceBoundaryThreshold = boundaryThreshold || 5; //感应超过的区域
  const minTop = areaBounds.top + workSpaceBoundaryThreshold;
  const maxtop = areaBounds.bottom - workSpaceBoundaryThreshold;
  const minLeft = areaBounds.left + workSpaceBoundaryThreshold;
  const maxLeft = areaBounds.right - workSpaceBoundaryThreshold;
  if (point.x < minLeft) {
    left = true;
  }

  if (point.y < minTop) {
    top = true;
  }

  if (point.x > maxLeft) {
    right = true;
  }

  if (point.y > maxtop) {
    bottom = true;
  }

  return { left, right, bottom, top };
}

/**
 * 拖拽区域限制
 *
 * @param leftNew
 * @param topNew
 * @param coreEditor
 * @param workSpaceClient
 * @param compsBounds
 * @returns 限定后的left top
 */
export function regionalRestrictions(
  leftNew: number,
  topNew: number,
  coreEditor: CoreEditor,
  workSpaceClient: ClientRect,
  compsBounds: IBounds,
) {
  const boundaryBorder = -5; //可移动范围边界宽度
  const minX = compsBounds.right - boundaryBorder;
  const maxX = workSpaceClient.width - compsBounds.left - boundaryBorder;
  const minY = compsBounds.bottom - boundaryBorder;
  const maxY = workSpaceClient.height - compsBounds.top - boundaryBorder;
  const artboardTitleHight = 30; //面板标题高度
  //判断是否超出边界
  if (leftNew < 0 && abs(leftNew) > minX) {
    leftNew = 0 - minX;
  }
  if (leftNew > 0 && leftNew > maxX) {
    leftNew = maxX;
  }
  if (topNew < 0 && abs(topNew) > minY) {
    topNew = 0 - minY;
  }

  if (topNew > 0 && topNew > maxY + artboardTitleHight) {
    topNew = maxY + artboardTitleHight;
  }

  return { left: leftNew, top: topNew };
}

/**
 * 缓存页面的位置信息到localStorage
 *
 * @param appID 项目ID
 * @param pageID 页面ID
 * @param position
 * @param scaleFactor
 * @description 缓存数据格式
 * WORKSPACE = [
 *  {
 *    'appID': string,
 *    'pages': [{
 *        pageId: string,
 *        position: {x: number, y: number}
 *        scaleFactor: number
 *    }]
 *  }
 * ]
 */
export function catchPagePositionAndScale(appID: string, pageID: string, position: IPosition, scaleFactor: number) {
  appOptions.savePageWorkspaceCatch(appID, pageID, { position, scaleFactor });
}

/**
 * 查找当前页面的缓存位置，如果没有则激活画板居中
 * 如果当前缩放比与缓存位置时的缩放比不一致，也居中画板
 *
 * @param appID
 * @param pageID
 * @param clientBounds
 * @param activeArtboard
 * @param scaleFactor
 */
export function getPagePosition(
  appID: string,
  pageID: string,
  clientBounds: ClientRect | IBounds,
  activeArtboard: UIFragment,
  scaleFactor: number,
) {
  const catchPosition = appOptions.getWorkspaceCatch(appID, pageID)?.position;
  if (catchPosition) return catchPosition;

  const { width, height } = activeArtboard.size;
  const mainArtBoardPosition = activeArtboard.position;
  const scale = scaleFactor / 100;
  let left = 0;
  let top = 0;
  const mainArtBoardScaleSize = { width: width * scale, height: height * scale };

  if (clientBounds.width < mainArtBoardScaleSize.width) {
    left = 30 - mainArtBoardPosition.x * scale;
  } else {
    left = round((clientBounds.width - mainArtBoardScaleSize.width) / 2 - mainArtBoardPosition.x * scale);
  }

  if (clientBounds.height < mainArtBoardScaleSize.height) {
    top = 30 - mainArtBoardPosition.y * scale;
  } else {
    top = round((clientBounds.height - mainArtBoardScaleSize.height) / 2 - mainArtBoardPosition.y * scale);
  }
  return { x: left, y: top };
}

/**
 * 获取不同条件下的鼠标样式
 *
 * @param spaceKey 空行键是否按下
 * @param cursorType 鼠标约定的样式
 * @param artboardCreating 新建画板状态
 */
export function getCursorStyle(
  spaceKey: boolean,
  cursorType: string,
  artboardCreating: boolean,
  isMouseDown?: boolean,
) {
  let cursor = 'default';

  //空格按下细分鼠标是否按住
  if (spaceKey && isMouseDown) {
    cursor = `url(${Cursors.Drag}) 12 12,default`;
  } else if (spaceKey) {
    cursor = `url(${Cursors.Drop}) 12 12,default`;
  }

  const nowCursor = CursorsWithType.find((cur) => cur.type === cursorType);

  if (nowCursor) {
    cursor = nowCursor.url;
  } else if (cursorType.startsWith('add-')) {
    cursor = CustomDefaultAddCompCursor;
  }
  if (artboardCreating) {
    cursor = `url(${Cursors.AddArtboard}) 8 8, copy`;
  }

  return { cursor };
}

/**
 * 获取某个面板与其组件形成的区域基于Page左上角的数据,
 * @param artbord
 * @param activeContainer
 */
export function getArtboardLTInfo(artbord: UIFragment | UIArtboard, activeContainer: UIContainerComponent) {
  const info: IBounds = {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: 0,
    height: 0,
  };
  const { position, size } = artbord;
  position.x = position.x || 0;
  position.y = position.y || 0;

  const comps = [...artbord.components];

  info.left = position.x;
  info.top = position.y;
  info.right = position.x + size.width;
  info.bottom = position.y + size.height;

  if (activeContainer !== artbord && activeContainer.ownerArtboardID === artbord.artboardID) {
    const { left, top, right, bottom } = getCompsBoundsInArtboard(activeContainer.components);
    info.left = Math.min(info.left, left + position.x);
    info.right = Math.max(info.right, right + position.x);
    info.top = Math.min(info.top, top + position.y);
    info.bottom = Math.max(info.bottom, bottom + position.y);
  }
  const matrix = getCompAbsoluteMatrix(artbord);
  while (comps.length) {
    const comp = comps[0];
    comps.shift();
    const isGroupComp = comp instanceof UIGroupComponent;
    const bounds = comp.getViewBoundsInArtboard(matrix, isGroupComp);
    info.left = Math.min(info.left, bounds.left + position.x);
    info.top = Math.min(info.top, bounds.top + position.y);
    info.right = Math.max(info.right, bounds.right + position.x);
    info.bottom = Math.max(info.bottom, bounds.bottom + position.y);
  }

  info.width = info.right - info.left;
  info.height = info.bottom - info.top;

  return info;
}

/**
 * 获取所有画板与组件的大区域块（考虑缩放）
 *
 * @param coreEditor
 * @param isNoScale 是否考虑缩放
 * @param scaleFactor 缩放比
 */
export function getAllRange(coreEditor: CoreEditor, isNoScale?: boolean, scaleFactor?: number) {
  const result: IBounds = {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: 0,
    height: 0,
  };
  const fragments = coreEditor.doc.artboardsFragments;
  const allLeft: number[] = [];
  const allTop: number[] = [];
  const allRight: number[] = [];
  const allBottom: number[] = [];
  fragments.forEach((item: UIFragment) => {
    const info = getArtboardLTInfo(item, coreEditor.activeContainer);
    allLeft.push(info.left);
    allTop.push(info.top);
    allRight.push(info.right);
    allBottom.push(info.bottom);
  });

  result.left = min(...allLeft);
  result.top = min(...allTop);
  result.right = max(...allRight);
  result.bottom = max(...allBottom);
  result.width = result.right - result.left;
  result.height = result.bottom - result.top;

  type KeyName = keyof IBounds;
  if (!isNoScale && scaleFactor) {
    const scale = parseFloat((scaleFactor / 100).toFixed(2));
    for (let key in result) {
      const _k = key as KeyName;
      result[_k] = round((result[_k] || 1) * scale);
    }
  }
  return result;
}

/**
 * 判断是不是负零
 * @param num
 */
export function isNegativeZero(num: number) {
  return num === 0 && 1 / num < 0;
}

/**
 * 判断是不是正数
 * @param num
 */
export function isPositiveNum(num: number) {
  if (num === 0) {
    return !isNegativeZero(num);
  }
  return +num === num && num >= 0;
}

interface IPagePoint {
  pageX: number;
  pageY: number;
}

interface ICompBounds {
  left: number;
  top: number;
  width: number;
  height: number;
}

/**
 * 获取拖拽添加组件时，目标容器及对应容器中的放置点
 * @param {IPagePoint} pagePoint
 * @param {ICompBounds} bounds
 * @param {CoreEditor} coreEditor
 * @param {HTMLElement[]} artboardDom
 * @param {HTMLElement | null | undefined} maskDom
 * @param {number} scaleFactor
 * @return {{point: IPoint, artboard: UIFragment}}
 */
export function getComponentDropInfo(
  pagePoint: IPagePoint,
  bounds: ICompBounds,
  coreEditor: CoreEditor,
  artboardDom: HTMLElement[],
  maskDom: HTMLElement | undefined | null,
  scaleFactor: number,
): { point: IPoint; artboard: UIFragment } {
  const { pageX, pageY } = pagePoint;
  const { left, top, width, height } = bounds;
  const { activeContainer, activeArtboard, doc } = coreEditor;

  let el: HTMLElement | undefined;
  let artboard: UIFragment | undefined;
  let point: { x: number; y: number } = { x: 0, y: 0 };
  // 拖拽添加到非画板的组中时
  if (!activeContainer.isArtboard) {
    artboard = activeArtboard;
    if (maskDom) {
      const bounds = maskDom.getBoundingClientRect();
      let container: UIComponent | undefined = activeContainer;
      let sumRotate = 0;
      while (container instanceof UIComponent && !container.isArtboard) {
        sumRotate += container.rotate;
        container = container.parent;
      }
      //获得相对于容器中心的位置，再计算无旋转的情况下相对容器中心的位置
      const rotateOffset = getRotateOffset(
        pageX - bounds.left - bounds.width / 2,
        -(pageY - bounds.top - bounds.height / 2),
        -sumRotate,
      );
      const width = activeContainer.size.width;
      const height = activeContainer.size.height;
      //加上无旋转的情况下，中心的位置，获得无旋转的情况下 在容器内的坐标
      point.x = (rotateOffset.x + (width / 2) * scaleFactor) / scaleFactor;
      point.y = (-rotateOffset.y + (height / 2) * scaleFactor) / scaleFactor;
    }
  } else {
    const compBounds = {
      left,
      top,
      right: left + width,
      bottom: top + height,
      width,
      height,
    };
    const artboardMap: { [id: string]: UIFragment } = [doc.mainArtboard, ...doc.fragments]
      .filter((artboard) => {
        if (coreEditor.isContentPanelEditor) {
          return artboard.$data.ownerID === (coreEditor as ContentPanelEditor).ownerComp.id;
        }
        return artboard;
      })
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr.artboardID]: curr,
        };
      }, {});

    const artboardInfo: {
      id: string;
      artboard: UIFragment;
      bounds: ClientRect;
      dom: HTMLElement;
    }[] = artboardDom
      .filter((dom) => {
        if (coreEditor.isContentPanelEditor) {
          const artboard = artboardMap[dom.dataset['id'] as string];
          if (!artboard) {
            return false;
          }
          return artboard.$data.ownerID === (coreEditor as ContentPanelEditor).ownerComp.id;
        }
        return dom;
      })
      .map((dom) => {
        const bounds = dom.getBoundingClientRect();
        const id = dom.dataset['id'] as string;
        return {
          id,
          bounds,
          artboard: artboardMap[id],
          dom,
        };
      });
    let dropArtbaord: HTMLElement | undefined;
    const unionArtbaords: HTMLElement[] = [];
    const activeInfo = artboardInfo.find((item) => item.id === activeArtboard.artboardID);
    const isInActiveArtboard = BoundsUtils.isContainerPoint(activeInfo!.bounds, { left: pageX, top: pageY });

    for (let i = 0, c = artboardInfo.length; i < c; i++) {
      const { bounds, dom } = artboardInfo[i];
      if (BoundsUtils.isContainerPoint(bounds, { left: pageX, top: pageY })) {
        dropArtbaord = isInActiveArtboard ? activeInfo!.dom : dom;
        break;
      } else if (BoundsUtils.isIntersect(bounds, compBounds)) {
        unionArtbaords.push(dom);
      }
    }

    if (dropArtbaord) {
      el = dropArtbaord;
    } else if (unionArtbaords.length === 0) {
      const info = artboardInfo.find((info) => info.id === activeArtboard.artboardID);
      if (info) {
        el = info.dom;
      }
    } else if (unionArtbaords.length === 1) {
      el = unionArtbaords[0];
    } else {
      let size = 0;
      let index = 0;
      unionArtbaords.forEach((dom, i) => {
        const id = dom.dataset['id'] as string;
        const info = artboardInfo.find((item) => item.id === id);
        if (info) {
          const bounds = info.bounds;
          const intersectBounds = BoundsUtils.intersect(bounds, compBounds);
          const l = BoundsUtils.size(intersectBounds);
          if (l > size) {
            size = l;
            index = i;
          }
        }
      });
      el = unionArtbaords[index];
    }

    if (el) {
      const id = el.dataset['id'] as string;
      artboard = [doc.mainArtboard, ...doc.fragments].find((item) => item.artboardID === id);
      const bounds = el.getBoundingClientRect();
      point.x = (pageX - bounds.left) / scaleFactor;
      point.y = (pageY - bounds.top) / scaleFactor;
    }
  }
  return {
    point,
    artboard: artboard || activeArtboard,
  };
}

/**
 * 计算自动滚动的数据改变
 * @param offsetX
 * @param offsetY
 * @param step
 * @param scaleFactor
 * @param moveState
 */
export function loopInComputed(
  offsetX: number,
  offsetY: number,
  step: number,
  scaleFactor: number,
  moveState: WorkspaceOutStatus,
) {
  const posit = { x: 0, y: 0 };
  //移动page,将移动的值计入position
  if (moveState.left) {
    offsetX += step;
    posit.x += step * scaleFactor;
  }

  if (moveState.top) {
    offsetY += step;
    posit.y += step * scaleFactor;
  }

  if (moveState.right) {
    offsetX -= step;
    posit.x -= step * scaleFactor;
  }

  if (moveState.bottom) {
    offsetY -= step;
    posit.y -= step * scaleFactor;
  }

  return { offsetX, offsetY, posit };
}

/**
 * 映射size计算
 *
 * @param index
 * @param moveX
 * @param moveY
 */
export function getCalcStrategySize(index: number, moveX: number, moveY: number) {
  const [LEFT_TOP, TOP, RIGHT_TOP, RIGHT, RIGHT_BOTTOM, BOTTOM, LEFT_BOTTOM, LEFT] = [0, 1, 2, 3, 4, 5, 6, 7];
  const indexMapOfSize: { [key: number]: number[] } = {
    [LEFT_TOP]: [-moveX, -moveY],
    [TOP]: [0, -moveY],
    [RIGHT_TOP]: [moveX, -moveY],
    [RIGHT]: [moveX, 0],
    [RIGHT_BOTTOM]: [moveX, moveY],
    [BOTTOM]: [0, moveY],
    [LEFT_BOTTOM]: [-moveX, moveY],
    [LEFT]: [-moveX, 0],
  };
  return indexMapOfSize[index];
}

// /**
//  * 加上自动滚动后，矫正停止时的宽高
//  *
//  * @param index
//  * @param moveX
//  * @param moveY
//  * @param offset
//  */
// export function conputedSizeAWithOffset(index: number, moveX: number, moveY: number, offset: IPosition) {
//   const [xdiff, ydiff] = getCalcStrategySize(index, moveX, moveY);
//   let newDiffX = xdiff;
//   let newDiffY = ydiff;
//   const [LEFT_TOP, TOP, RIGHT_TOP, RIGHT, RIGHT_BOTTOM, BOTTOM, LEFT_BOTTOM, LEFT] = [0, 1, 2, 3, 4, 5, 6, 7];
//
//   index === LEFT && (newDiffX += offset.x);
//   index === TOP && (newDiffY += offset.y);
//   index === RIGHT && (newDiffX -= offset.x);
//   index === BOTTOM && (newDiffY -= offset.y);
//
//   index === LEFT_TOP && (newDiffX += offset.x) && (newDiffY += offset.y);
//   index === RIGHT_TOP && (newDiffX -= offset.x) && (newDiffY += offset.y);
//   index === RIGHT_BOTTOM && (newDiffX -= offset.x) && (newDiffY -= offset.y);
//   index === LEFT_BOTTOM && (newDiffX += offset.x) && (newDiffY -= offset.y);
//
//   return [newDiffX, newDiffY];
// }

/**
 * 获取计算拖动后的面板大小size
 * @param args
 * @param newWidth 缩放状态下的宽
 * @param newHeight 缩放状态下的高
 * @param scaleFactor
 * @param minSize
 */
export function getFrameSize(
  args: number[],
  newWidth: number,
  newHeight: number,
  scaleFactor: number,
  minSize?: number,
) {
  const [xdiff, ydiff] = args;
  const width = newWidth + xdiff;
  const height = newHeight + ydiff;
  //计算未缩放时的大小
  const unscaledWidth = min(max(minSize || minArtboardSize, round(width / scaleFactor)), maxArtboardSize);
  const unscaledHeight = min(max(minSize || minArtboardSize, round(height / scaleFactor)), maxArtboardHeight);
  return { width: unscaledWidth, height: unscaledHeight };
}

/**
 * 获取面板resize后的position
 * @param index
 * @param artboardSize 当前面板大小
 * @param nowFrameSize 当前resize框的大小
 */
export function getFramePosition(index: number, artboardSize: ISize, nowFrameSize: ISize) {
  const [LEFT_TOP, TOP, RIGHT_TOP, LEFT_BOTTOM, LEFT] = [0, 1, 2, 6, 7];
  let [positX, positY] = [0, 0];
  if (index === LEFT_TOP || index === LEFT_BOTTOM || index === LEFT) {
    positX = artboardSize.width - nowFrameSize.width;
  }

  if (index === LEFT_TOP || index === TOP || index === RIGHT_TOP) {
    positY = artboardSize.height - nowFrameSize.height;
  }
  return {
    x: positX,
    y: positY,
  };
}

/**
 * 获取工作区框选的区域信息
 * @param delta
 * @param downPoint
 */
export function getChooseRangeBounds(delta: MoveDelta, downPoint: IPoint) {
  const width = abs(delta.x);
  const height = abs(delta.y);
  const left = delta.x > 0 ? downPoint.x : downPoint.x - width;
  const top = delta.y > 0 ? downPoint.y : downPoint.y - height;
  return { width, height, left, top, right: left + width, bottom: top + height };
}

/**
 * 从相对page的点，计算框选形成的区域
 * 这样可以固定开始点
 *
 * @param nowPoint 框选结束点
 * @param pagePoint 框选开始点（相对page）
 * @param pagePosit page位置
 * @param workspacePosit 工作区位置
 * @param scaleFactor 缩放比
 * @param shiftKey
 */
export function getChooseBoundsByPagePoint(
  nowPoint: IPoint,
  pagePoint: IPoint,
  pagePosit: IPosition,
  workspacePosit: ClientRect,
  scaleFactor: number,
  shiftKey: boolean,
) {
  //相对整个可视界面的点
  const mousePoint = {
    x: pagePosit.x + pagePoint.x / scaleFactor + workspacePosit.left,
    y: pagePosit.y + pagePoint.y / scaleFactor + workspacePosit.top,
  };

  //按下与现在的点的偏移
  const offset: { x: number; y: number } = {
    x: nowPoint.x - mousePoint.x,
    y: nowPoint.y - mousePoint.y,
  };

  //正方形
  if (shiftKey) {
    offset.x = offset.y = Math.max(offset.x, offset.y);
    // if (offset.x > offset.y) {
    //   offset.y = offset.x;
    // } else {
    //   offset.x = offset.y;
    // }
  }

  //相对工作区的点
  const downPoint = {
    x: mousePoint.x - workspacePosit.left,
    y: mousePoint.y - workspacePosit.top,
  };

  //相对工作区的选框bounds
  return getChooseRangeBounds(offset, downPoint);
}

//==================Page=== 组件移动===================================================

/**
 * 修正组件移动按住shift时，基于直角或斜角的偏移
 *
 * @param diff 正常偏移
 */
export function changeDiffWhenRightOrBevel(diff: IPosition) {
  // TODO 21-8-3 取消45度角吸附
  // 斜边长
  const z = Math.hypot(diff.x, diff.y);
  // 角度
  const angle = z !== 0 ? 180 / (Math.PI / Math.acos(Math.abs(diff.x) / z)) : 0;
  if (angle < 45) {
    diff.y = 0;
  } else {
    diff.x = 0;
  }
  // // angle值在0-90之间
  // if (angle < 30) {
  //   // 更靠近x轴
  //   diff.y = 0;
  // } else if (angle > 60) {
  //   // 更靠近y轴
  //   diff.x = 0;
  // } else {
  //   //在x和y之间, 取绝对值更大的坐标，赋给xy,保留原坐标的正负
  //   const absX = Math.abs(diff.x);
  //   const absY = Math.abs(diff.y);
  //   const max = Math.max(absX, absY);
  //   diff.x = (diff.x * max) / absX;
  //   diff.y = (diff.y * max) / absY;
  // }
}

// FIXME 周家琪,考虑废弃掉这个对象，所有关于界面操作的功能，之后考虑以命令的方式整合到CoreEditor
export default class CompLoadHelper {
  private computedWorkSpaceBoundsOfContainer?: () => IBounds | undefined;

  private computedOffsetOfContainerInWorkSpace?: () => IPosition | undefined;

  registerComputedWorkSpaceBoundsOfContainer(fn: () => IBounds | undefined) {
    this.computedWorkSpaceBoundsOfContainer = fn;
  }

  registerComputedContainerInWorkSpace(fn: () => IPosition | undefined) {
    this.computedOffsetOfContainerInWorkSpace = fn;
  }

  getWorkSpaceBoundsOfContainer() {
    return this.computedWorkSpaceBoundsOfContainer && this.computedWorkSpaceBoundsOfContainer();
  }

  getOffsetOfContainerInWorkSpace() {
    return this.computedOffsetOfContainerInWorkSpace && this.computedOffsetOfContainerInWorkSpace();
  }

  /**
   * 获取组件粘贴的新坐标
   * @param {IComponentSize} size
   * @param {IBounds} containerBounds
   * @returns
   * @memberof CompLoadHelper
   */
  getInitPositionContainer(size: IComponentSize, containerBounds: IBounds) {
    let centerPoint = { x: 0, y: 0 };
    const bounds = this.getWorkSpaceBoundsOfContainer() as IBounds;
    if (isUndefined(bounds)) {
      // 容器中心
      centerPoint = {
        x: Math.round(0.5 * containerBounds.width - 0.5 * size.width),
        y: Math.round(0.5 * containerBounds.height - 0.5 * size.height),
      };
    } else {
      if (bounds.width === 0 || bounds.height === 0) {
        // 工作区中心
        const offset = this.getOffsetOfContainerInWorkSpace() || { x: 0, y: 0 };
        centerPoint = {
          x: Math.round(-offset.x - 0.5 * size.width),
          y: Math.round(-offset.y - 0.5 * size.width),
        };
      } else {
        // 容器可视中心
        centerPoint = {
          x: Math.round(0.5 * bounds.width - 0.5 * size.width + bounds.left),
          y: Math.round(0.5 * bounds.height - 0.5 * size.height + bounds.top),
        };
      }
    }
    return centerPoint;
  }
}

/**
 * 根据新建的文本大小，调整对应的文本位置
 * @param data
 * @param size
 * @param position
 */
function modifyTextPositionBySize(data: IComponentData, size: ISize, position: { x: number; y: number }) {
  const { textFormat } = data.properties;
  const newPosition = { ...position };
  if (textFormat) {
    switch (textFormat.textAlign) {
      case TextAlign.right:
        newPosition.x -= size.width;
        break;
      case TextAlign.center:
        newPosition.x -= Math.round(size.width / 2);
        break;
      default:
        break;
    }
    newPosition.y -= 8;
  }
  return newPosition;
}

/**
 * 快速添加组件
 * @param {CoreEditor} editor
 * @param {string} key 快捷键
 * @param {IPosition} point
 * @param {string} appType
 * @param {string} artboardID
 * @param size
 * @param shiftKey
 * @param linePoints
 * @param isClickAdd
 */
export function quickAddComponentToPoint(
  editor: CoreEditor,
  key: string,
  point: IPosition,
  appType: string,
  artboardID: string,
  size: ISize,
  shiftKey: boolean,
  linePoints?: ILineValue,
  isClickAdd?: boolean,
) {
  if (!key) {
    return;
  }
  const _size = { width: round(size.width), height: round(size.height) };
  const cfg = getLibByShortCutOrType(key);
  if (cfg) {
    const data = makeComponent(cfg.type, cfg.data.type, appType);
    const originSize: ISize = { ...data!.size };
    let position = { ...point };
    if (data) {
      if (cfg.data.initialization) {
        cfg.data.initialization('', data, undefined, _size);
      }
      // 当点击添加文本时，进行位置的偏移，使更符合用户习惯
      if (isClickAdd && data.type === CText) {
        position = modifyTextPositionBySize(data, _size, position);
      }
      data.position = {
        x: round(position.x),
        y: round(position.y),
      };
      if (data.type !== CText) {
        data.size = { ..._size, lockedRatio: shiftKey && data.type !== CLine };
      }
      if (data.type === CLine && linePoints) {
        data.value = depthClone(linePoints);
      }
      if (data.type === CPath && !cfg.data.initialization) {
        const scale = {
          x: _size.width / originSize.width,
          y: _size.height / originSize.height,
        };
        data.value = onPathValueZoom(data.value, scale);
      }
      if (data.type !== CText) {
        data.name = getNameForNewComponent(editor.activeContainer.components, {
          id: cfg.type,
          type: cfg.data.type,
        });
      }
      editor.addComponents([data], { artboardID: artboardID, autoSelect: true });
    }
  }
}

/**
 * 计算缩放面板时，连接点的大小和缩放比
 * @param direction
 * @param compSize
 * @param scaleSize
 * @param initScaleNum //计算组件中的连接点时，初始的scaleNum不同
 */
export function calcaulateSizeAndRealScaleWhenScale(
  direction: string,
  compSize: ISize,
  scaleSize: number = 1,
  initScaleNum: number = 1,
): {
  controlSize: number;
  scaleNum: number;
} {
  const criticalSizeValue = 26;
  const realCompSize = { width: compSize.width * scaleSize, height: compSize.height * scaleSize };
  //当实际组件的宽或高小于连接点和控制点的和时，不做hover拓展
  const isHorizontal = realCompSize.width > criticalSizeValue;
  const isVertical = realCompSize.height > criticalSizeValue;
  let controlSize = maxControlSize;
  let scaleNum = initScaleNum;
  if (!isHorizontal && (direction === 'top' || direction === 'bottom')) {
    controlSize = realCompSize.width;
    scaleNum = scaleNum * (realCompSize.width / criticalSizeValue);
  }
  if (!isVertical && (direction === 'left' || direction === 'right')) {
    controlSize = realCompSize.height;
    scaleNum = scaleNum * (realCompSize.height / criticalSizeValue);
  }
  return { controlSize, scaleNum };
}

/**
 * 解析网格需要的参数，
 * 优先级： 用户设置 > 默认设置 > 系统定义
 * 用户设置: 针对每个面板有单独的设置
 * 默认设置：用户勾选设置为默认后， 覆盖系统定义
 * 系统定义：软件本身自定义设置
 * @param data
 * @param artboardID
 * @param type
 */
export function parseGridOrLayoutSetting(
  data: IUserPreference,
  artboardID: string,
  type: 'grid' | 'layout',
): {
  thickColor: PureColor;
  lightColor: PureColor;
  realSetting: IGridSettings | ILayoutSettings;
} {
  const { layoutAndGridColor, appLayoutAndGrid, artboardsLayoutAndGrid } = depthClone(data);
  const customGrid = artboardsLayoutAndGrid[artboardID];
  const realSetting = Object.assign({}, appLayoutAndGrid[type], customGrid && customGrid[type]);
  return {
    ...layoutAndGridColor,
    realSetting,
  };
}

/**
 * 解析侦测配置，补充不存在的数据
 *
 * @export
 * @param {IAlignConfig} [data]
 * @returns {*}  {IAlignConfig}
 */
export function parseAlignConfig(data?: IAlignConfig): IAlignConfig {
  return {
    normalAlign: data?.normalAlign ?? true,
    testEquidistant: data?.testEquidistant ?? true,
    markDistance: data?.markDistance ?? true,
  };
}

/**
 * 解析栅格设置为对齐线数组
 *
 * @param {ILayoutSettings} layoutSetting
 * @param artboardSize
 */
export function parseLayoutSettingToSnaplines(
  layoutSetting: ILayoutSettings,
  artboardSize: ISize,
): {
  x: number[];
  y: number[];
} {
  const data: {
    x: number[];
    y: number[];
  } = {
    x: [],
    y: [],
  };
  const { totalWidth, offset, columns, rows } = layoutSetting;
  if (columns.visible) {
    const { gutterOnOutside, gutterWidth, columnsCount } = columns;
    const { blockArr, afterSplitOffset } = computedSplitWidthAndOffset(
      totalWidth,
      columnsCount,
      gutterWidth,
      gutterOnOutside,
    );
    const snaplines = computedLayoutColLine(blockArr, offset + afterSplitOffset, gutterOnOutside, gutterWidth);
    data.x.push(...snaplines);
  }

  if (rows.visible) {
    const { gutterHeight, rowHeight, drawAllLines } = rows;
    let y = 0;
    if (drawAllLines) {
      while (y <= artboardSize.height) {
        data.y.push(y);
        y += gutterHeight;
      }
    } else {
      const flag = true;
      while (y <= artboardSize.height) {
        data.y.push(y);
        if (flag) {
          y += gutterHeight * rowHeight;
        } else {
          y += gutterHeight;
        }
      }
    }
  }
  return data;
}

// 当栅格需要补充outside 到每列时， 向下取整，避免小数
function computedOutSpaceWhenShowOutSide(isShowOutSpace: boolean, gutterWidth: number): number {
  return isShowOutSpace ? floor(gutterWidth / 2) * 2 : 0;
}

/**
 * 计算栅格当前尺寸下的宽度信息
 *
 * @param width
 * @param columnNum
 * @param gutterWidth
 * @param isShowOutSpace
 *
 */
export function computedWidthInfoOfLayout(
  width: number,
  columnNum: number,
  gutterWidth: number,
  isShowOutSpace: boolean,
): {
  innerWidth: number;
  extraWidth: number;
  bigWidth: number;
} {
  const bigWidth = floor(width / columnNum);
  const innerWidth = bigWidth - gutterWidth;
  const outSpace = computedOutSpaceWhenShowOutSide(!isShowOutSpace, gutterWidth);
  const extraWidth = width - bigWidth * columnNum + outSpace;

  return {
    innerWidth,
    extraWidth,
    bigWidth,
  };
}

/**
 * 计算栅格纵向的块的分割及分割需要偏离的offset, 默认gutter 固定计算
 *
 * 如果 勾选外围间距，则将多余的宽分到2侧， 否则，平分到每列
 *
 * @param {number} width
 * @param {number} columnNum
 * @param {number} gutterWidth
 * @param {boolean} isShowOutSpace
 * @returns
 */
export function computedSplitWidthAndOffset(
  width: number,
  columnNum: number,
  gutterWidth: number,
  isShowOutSpace: boolean,
) {
  const { extraWidth, innerWidth, bigWidth } = computedWidthInfoOfLayout(width, columnNum, gutterWidth, isShowOutSpace);
  let afterSplitOffset = 0;
  let blockArr: { bigWidth: number; innerWidth: number }[] = new Array(columnNum).fill({ bigWidth, innerWidth });
  if (!isShowOutSpace) {
    //将剩余的像素均分到每个间隔
    for (let i = 0; i < extraWidth; i++) {
      const index = i >= columnNum ? i % columnNum : i;
      const { bigWidth, innerWidth } = blockArr[index];
      blockArr[index] = { bigWidth: bigWidth + 1, innerWidth: innerWidth + 1 };
    }
  } else {
    afterSplitOffset += floor(extraWidth / 2);
  }

  return {
    blockArr,
    afterSplitOffset,
  };
}

/**
 * 根据计算的栅格的列的数据，获取每列间的线条位置
 * @param blockArr
 * @param offset
 * @param gutterOnOutside
 * @param gutterWidth
 */
export function computedLayoutColLine(
  blockArr: { bigWidth: number; innerWidth: number }[],
  offset: number,
  gutterOnOutside: boolean,
  gutterWidth: number,
) {
  let result: number[] = [];
  const outSpace = computedOutSpaceWhenShowOutSide(gutterOnOutside, gutterWidth);
  let count: number = offset + outSpace / 2;
  for (let i = 0, len = blockArr.length; i < len; i++) {
    const { bigWidth, innerWidth } = blockArr[i];
    result.push(count, count + innerWidth);
    count += bigWidth;
  }
  return result;
}

/**
 * 修正快捷键绘制后的位置，得到相对容器的位置
 * @param activeContainer
 * @param activeArtboard
 * @param position 相对page的点
 */
export function revisedPositionAfterDraw(
  activeContainer: UIContainerComponent,
  activeArtboard: UIFragment,
  position: IPosition,
) {
  const relativePosition = activeContainer.positionRelativeToArtboard;
  let groupOffset = { x: 0, y: 0 };
  if (!activeContainer.isArtboard) {
    groupOffset.x = activeArtboard.position.x;
    groupOffset.y = activeArtboard.position.y;
  }

  return BoundsUtils.offsetPoint(position, {
    x: round(0 - relativePosition.x - groupOffset.x),
    y: round(0 - relativePosition.y - groupOffset.y),
  });
}

// 两点确定的向量
export function makeVerctor(p1: IPoint, p2: IPoint) {
  return {
    x: p2.x - p1.x,
    y: p2.y - p1.y,
  };
}

// 向量的模
export function makeVerctorNorm(verctor1: IPoint) {
  return Math.hypot(verctor1.x, verctor1.y);
}

// 向量的乘积
export function makeVerctorProduct(verctor1: IPoint, verctor2: IPoint) {
  return verctor1.x * verctor2.x + verctor1.y * verctor2.y;
}

/**
 * 平面内任意一点到某直线内的投影的长度 占 直线的比例(百分比)
 * @param p1 直线开始点
 * @param p2 直线结束点
 * @param p3 任意一点
 *
 * @returns  0 ~ 100
 */
export function computedFootRatioInLine(p1: IPoint, p2: IPoint, p3: IPoint) {
  const p1p2Vector = makeVerctor(p1, p2);
  const p1p3Vector = makeVerctor(p1, p3);

  const vectorNorm1 = makeVerctorProduct(p1p2Vector, p1p3Vector);
  const p1p2Norm = makeVerctorNorm(p1p2Vector);

  const mapNorm = vectorNorm1 / p1p2Norm;
  let radio = mapNorm / p1p2Norm;

  if (radio < 0) {
    radio = 0;
  }

  if (radio > 1) {
    radio = 1;
  }

  return Number((radio * 100).toFixed(2));
}

/**
 * 平面内任意一点到某直线的垂直距离
 *
 * @param p1 直线开始点
 * @param p2 直线结束点
 * @param p3 任意一点
 */
export function computedFootLengthOfLine(p1: IPoint, p2: IPoint, p3: IPoint) {
  const p1p2Vector = makeVerctor(p1, p2);
  const p1p3Vector = makeVerctor(p1, p3);

  const vectorNorm1 = makeVerctorProduct(p1p2Vector, p1p3Vector);
  const p1p2Norm = makeVerctorNorm(p1p2Vector);
  const p1p3Norm = makeVerctorNorm(p1p3Vector);

  const mapNorm = vectorNorm1 / p1p2Norm;
  //由于投影与高度构成的是直角
  return Math.sqrt(Math.pow(p1p3Norm, 2) - Math.pow(mapNorm, 2));
}

/**
 * 计算一个矩形填充另一个矩形的缩放比
 *
 * @export
 * @param {{ width: number, height: number }} rectSize 原始矩形大小
 * @param {{ mapWidth: number, mapHeight: number }} mapSize 需要填充矩形大小
 * @returns {*}
 */
export function computedMapRectScale(
  rectSize: { width: number; height: number },
  mapSize: { width: number; height: number },
) {
  const { width, height } = rectSize;
  const { width: mapWidth, height: mapHeight } = mapSize;
  return Math.min(mapWidth / width, mapHeight / height);
}

/**
 *根据鼠标位置，判断下方的画板应该是哪一个
 *
 * 多个画板时层级关系： active > index
 *
 * tips: 所有的画板都相对page
 *
 * @export
 * @param {IPoint} mouse
 * @param {CoreEditor} coreEditor
 */
export function activeArtboardByMouse(mouse: IPoint, coreEditor: CoreEditor) {
  const {
    activeArtboard: { artboardID },
    doc: { artboardsFragments },
  } = coreEditor;

  // 当前激活容器不是画板，则不进行激活
  if (!coreEditor.activeContainer.isArtboard) return;

  const pointArtboard = artboardsFragments
    .filter((artboard) => {
      //非编辑模式下，过滤掉隐藏的画板
      if (!coreEditor?.isContentPanelEditor) {
        return !artboard.$data.ownerID;
      }
      return artboard;
    })
    .find((artboard) => {
      const { id, position, size } = artboard;
      if (id === artboardID) {
        return true;
      } else {
        const bounds = BoundsUtils.initBoundsWithPositionAndSize(position, size);
        if (BoundsUtils.isContainerPoint(bounds, { left: mouse.x, top: mouse.y })) {
          return true;
        }
      }
    });

  pointArtboard && coreEditor.setActiveArtboard(pointArtboard);
}

export function getLinePointsBySize(startPoint: IPoint, endPoint: IPoint, size: ISize) {
  const { width, height } = size;
  const type = [
    [
      { x: 0, y: 0 },
      { x: width, y: 0 },
    ],
    [
      { x: 0, y: 0 },
      { x: 0, y: height },
    ],
    [
      { x: 0, y: 0 },
      { x: width, y: height },
    ],
    [
      { x: 0, y: height },
      { x: width, y: 0 },
    ],
  ];

  let realStart = { x: 0, y: 0 };
  let realEnd = { x: 0, y: 0 };

  // 判断8个方向
  if (startPoint.x === endPoint.x) {
    if (startPoint.y < endPoint.y) {
      [realStart, realEnd] = type[1];
    } else {
      [realEnd, realStart] = type[1];
    }
  } else if (startPoint.x < endPoint.x) {
    if (startPoint.y === endPoint.y) {
      [realStart, realEnd] = type[0];
    } else if (startPoint.y < endPoint.y) {
      [realStart, realEnd] = type[2];
    } else {
      [realStart, realEnd] = type[3];
    }
  } else {
    if (startPoint.y === endPoint.y) {
      [realEnd, realStart] = type[0];
    } else if (startPoint.y < endPoint.y) {
      [realEnd, realStart] = type[3];
    } else {
      [realEnd, realStart] = type[2];
    }
  }
  return { realStart, realEnd };
}
