import { IBounds, IPosition } from '@fbs/common/models/common';
import { UIComponent } from '@editor/comps';
import { IComponentData } from '@fbs/rp/models/component';
import { getBoundsOfRotatedRectangle } from '@helpers/rotateHelper';
import * as BoundsUtils from '@utils/boundsUtils';
import { Matrix } from '@utils/matrixUtils';
import { getCompAbsoluteBounds } from '@helpers/componentHelper';

function getRotatedBoundsOfComponentData(data: IComponentData): IBounds {
  return getBoundsOfRotatedRectangle({
    position: data.position,
    size: data.size,
    rotate: data.rotate || 0,
  });
}

/**
 * 获取一批组件数据的 view position
 * 注意：这个方法目前仅仅是给 group 内组件用的，所以对锚定有要求，只能通过左上角布局的方式来锚定才能获取
 * @param components
 */
export function getViewPositionOfComponentsData(components: IComponentData[]): IPosition | null {
  if (components.length === 0) {
    return null;
  }
  const firstComponent = components[0];

  const firstBounds = getRotatedBoundsOfComponentData(firstComponent);
  const initialPosition = {
    x: firstBounds.left,
    y: firstBounds.top,
  };

  if (components.length <= 1) {
    return initialPosition;
  }
  return components.reduce((acc: IPosition, curr: IComponentData) => {
    const currentBounds = getRotatedBoundsOfComponentData(curr);
    return {
      x: Math.min(acc.x, currentBounds.left),
      y: Math.min(acc.y, currentBounds.top),
    };
  }, initialPosition);
}

/**
 * 获取一组组件的 view bounds
 */
export function getViewBoundsOfComponents(components: UIComponent[]): IBounds | null {
  if (components.length === 0) {
    return null;
  }
  return BoundsUtils.union(
    ...components.map((comp) => {
      return comp.getViewBoundsInParent();
    }),
  );
}

export function getViewBoundsOfComponentsWithArtboard(components: UIComponent[], parentMatrix: Matrix): IBounds | null {
  if (components.length === 0) {
    return null;
  }
  return BoundsUtils.union(
    ...components.map((comp) => {
      return getCompAbsoluteBounds(comp, parentMatrix); // comp.getViewBoundsInArtboard();
    }),
  );
}
