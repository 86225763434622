import { IComponentData } from '@/fbs/rp/models/component';
import { IProperties } from '@/fbs/rp/models/property';
import BasicComponentLib from '@libs/basic';
import { CVideo } from '@libs/constants';
import { IUICompConstructOptions } from '@/customTypes';

import { UIComponent, UIContainerComponent } from '.';

export default class UIMediaComponent extends UIComponent {
  constructor(data: IComponentData, public parent?: UIContainerComponent, public options?: IUICompConstructOptions) {
    super(data, parent, options);
    const defaultProperties: IProperties = {};
    if (data.type === CVideo) {
      const videoProperties = BasicComponentLib.make(data.type).properties;
      Object.assign(defaultProperties, videoProperties);
    }
    data.properties = {
      ...defaultProperties,
      ...data.properties,
    };
  }
}
