import { deleteUselessAttributes } from './adjustComponentStyle';
import { addPropValueToCache, addUsedFontFaceToCache, getFontFamily, PropfontFamily } from './fontFaces';
import { ICopyStyleRuler } from '../type';

// 复制属性前的预检
const checkBeforeCopyProps = (source: CSSStyleDeclaration, original: Element): boolean => {
  // 排除svg标签。组件库中不需要样式属性
  // 有子节点的不需要复制样式
  if (original instanceof SVGElement) {
    return false;
  }

  const node = original as HTMLElement;
  // 不需要样式的节点
  if (/^br$/.test(node.tagName)) {
    return false;
  }

  // size为0的不复制  ,注意可能存在伪元素
  const width = source.getPropertyValue('width');
  const height = source.getPropertyValue('height');
  if (width === '0px' || height === '0px') {
    return false;
  }

  return true;
};

//克隆样式
export function copyStyle(original: Element, clone: HTMLElement, copyStyleRuler?: ICopyStyleRuler, isRoot?: boolean) {
  const source: CSSStyleDeclaration = window.getComputedStyle(original);
  const target = clone.style;

  if (source.cssText) {
    target.cssText = source.cssText;
  } else if (checkBeforeCopyProps(source, original)) {
    // 不要class id 属性, 调试时候打开
    deleteUselessAttributes(clone);

    // 处理伪元素
    const hasPSeudoElement = handlePseudoElement(original, target, clone);
    if (hasPSeudoElement) {
      return;
    }

    // 标记在使用的字体
    const isDiectTextNode = addUsedFontFaceToCache(source, original as HTMLElement);

    // 复制样式策略
    if (copyStyleRuler) {
      copyStyleRuler({
        node: original,
        source,
        target,
        isDiectTextNode,
        isRoot,
      });
    } else {
      // 不讲策略, 复制所有样式
      Array.from(source).forEach((name: string) => {
        const value = source.getPropertyPriority(name) || source.getPropertyValue(name);
        target.setProperty(name, value);
      });
    }

    // 不定义字体
    if (!isDiectTextNode) {
      target.removeProperty(PropfontFamily);
    }
  }
}

// 伪元素
export const getPseudoElement = (
  node: Element,
):
  | {
      fontFamily: string;
      text: string;
    }
  | undefined => {
  const before = window.getComputedStyle(node, '::before');
  const beforContent = before.getPropertyValue('content');
  const str = beforContent !== 'none' ? beforContent.replace(/"/g, '') : '';
  const fontFamily = getFontFamily(before);

  if (str) {
    return {
      fontFamily,
      text: str,
    };
  }
};
// 处理伪元素
const handlePseudoElement = (original: Element, target: CSSStyleDeclaration, clone: HTMLElement): boolean => {
  const pele = getPseudoElement(original);
  if (pele) {
    clone.insertAdjacentText('afterbegin', pele.text);
    target.setProperty(PropfontFamily, pele.fontFamily);
    addPropValueToCache(pele.fontFamily);
    return true;
  }
  return false;
};
