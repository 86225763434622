export function free() {
  if (!window || !window.localStorage) {
    return;
  }
  const cacheItems: string[] = [];
  for (let i = 0; i < window.localStorage.length; i++) {
    const item = window.localStorage.key(i);
    if (item && (item.startsWith('cache_')||item.startsWith('rp_app_cache'))) {
      cacheItems.push(item);
    }
  }
  cacheItems.forEach(item => window.localStorage.removeItem(item));
}


export function saveToLocalStorage(key: string, value: string) {
  if (window && window.localStorage) {
    window.localStorage.setItem(key, value);
  }
}

export function clearLocalStorageItem(key: string) {
  window?.localStorage?.removeItem(key);
}

export function getLocalStorageItem(key: string) {
  return window?.localStorage?.getItem(key);
}