import * as React from 'react';

import classNames from 'classnames';

import { storages } from '@/utils/cacheUtils';

import * as Actions from '@/store/actions';
import { ToastType } from '@/store/types';
import store from '@/store';
import i18n from '@/i18n';

import { Icon } from '@/dsm';

import './index.scss';

const SESSION_KEY = 'sessionHideIndexDBTip';

interface IndexedDBErrorTipProps {
  className?: string;
}

const IndexedDBErrorTip: React.FC<IndexedDBErrorTipProps> = ({ className }) => {
  const handleClose = () => {
    storages.session.setItem(SESSION_KEY, 'true');
    store.dispatch(Actions.Global.closeToast(ToastType.IndexedDBError));
  };

  return (
    <div className={classNames(className, 'indexedDB-err')}>
      <div>{i18n('indexedDBError.tip')}</div>
      <a className="btn btn-blue" href={i18n('indexedDBError.url')} target="support" rel="noopener">
        {i18n('indexedDBError.howToResolve')}
      </a>
      {!RP_CONFIGS.isPrivateDeployment && (
        <a className="btn btn-grey" href={i18n('support.url')} target="support" rel="noopener">
          {i18n('support.label')}
        </a>
      )}
      <Icon size={16} className="global-toast-icon-close btn-icon" onClick={handleClose} cls="close" />
    </div>
  );
};

export default IndexedDBErrorTip;
