import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

/**
 * 工具提示属性对象
 */
interface ITooltipProps {
  direction?: 'up' | 'down';
  text?: string;
  width?: number | string;
  align?: 'left' | 'center' | 'right';
  children?: JSX.Element[] | JSX.Element;
  maxWidth?: number;
  showTip: boolean;
}

const Tooltip: React.FC<ITooltipProps> = ({ direction, text, children, width, align, showTip }: ITooltipProps) => (
  <div style={{ width }} className="dsm-c-arrow-point-tip">
    {text && showTip && (
      <React.Fragment>
        <div className={classnames('arrow-point', direction, align)}></div>
        <div className={classnames('arrow-point-tips', direction, align)}>{text}</div>
      </React.Fragment>
    )}
    {children}
  </div>
);

Tooltip.defaultProps = {
  direction: 'down',
  text: '',
  align: 'center',
};

export default Tooltip;
