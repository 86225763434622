import INoteConnectedLine, { NoteConnectedLinePropertyName } from '@/fbs/rp/models/properties/noteConnectedLine';
import { PresetDashModel } from '@/fbs/rp/models/properties/stroke';
import { LinePointType } from '@/fbs/rp/models/properties/line';

import { DeepBlueColor } from './colors';

export const DefaultNoteConnectedLine: INoteConnectedLine = {
  prop: NoteConnectedLinePropertyName,
  disabled: false,
  color: DeepBlueColor,
  lineWidth: 1,
  lineType: PresetDashModel.solid,
  startType: LinePointType.solidDot,
  endType: LinePointType.solidDot,
};

export const DefaultNoteConnectedLineLen = 88; // 连接线默认长度

export const StartPointSpace = 10; // 起始点离四边的最小间距

export const DEFAULT_END_POINT = { x: -DefaultNoteConnectedLineLen, y: 90 };
