import * as React from 'react';

import { StartPointSpace } from '@/consts/noteConnectedLine';
import INoteConnectedLine from '@/fbs/rp/models/properties/noteConnectedLine';
import { INoteValue } from '@/fbs/rp/models/value';
import { LinePointType } from '@/fbs/rp/models/properties/line';
import { StyleHelper } from '@/helpers/styleHelper';
import { getPathStartOrEndTwoPoint, parseLineStr } from '@/helpers/pathFinderHelper';
import { calcPathByTwoPoints } from '@/helpers/pathHelper';
import PathItem from '@/libs/basic/Path/PathItem';
import { IComponentProps } from '@/libs/types';
import { getArrowInfoByType } from '@/libs/basic/common/ConnectorPath/Arrow';

import './index.scss';

// 空心箭头集合
const HollowArrowList = [
  LinePointType.hollowTriangle,
  LinePointType.hollowDot,
  LinePointType.hollowCube,
  LinePointType.hollowDiamond,
];

const StickNote: React.FC<IComponentProps> = (props) => {
  const { comp } = props;
  const { size } = comp;
  const value = comp.value as INoteValue;

  const getLinePointData = () => {
    const { size } = comp;
    // 结束点位置是和组件position的相对位置
    const position = { x: 0, y: 0 };
    const endPoint = { ...value.endPoint! };
    const startPoint = { ...position };
    let offsetY = 0;
    let offsetX = 0;
    let direction: 'left' | 'right' | 'top' | 'bottom' = 'left';

    // 结束点在组件内部
    const isInside =
      endPoint.x >= position.x &&
      endPoint.x <= size.width + position.x &&
      endPoint.y >= position.y &&
      endPoint.y <= size.height + position.y;
    /**
     * 根据组件与终点的相对位置，获取真实的起点
     * 结束点处于组件左侧，右侧或者内部时，都为横向展示连接线
     */
    if (endPoint.x < position.x || endPoint.x > size.width + position.x || isInside) {
      // 结束点在组件左侧
      direction = endPoint.x <= position.x + size.width / 2 ? 'left' : 'right';
      offsetX = direction === 'left' ? 0 : size.width;
      startPoint.x += offsetX;
      // 结束点的y值处于便签条y轴方向{y+10~height-10}内，视起始点与结束点y轴一致，始终保持为一条横直线
      if (endPoint.y >= StartPointSpace + position.y && endPoint.y <= size.height + position.y - StartPointSpace) {
        startPoint.y = endPoint.y;
        offsetY = endPoint.y - position.y;
      } else if (endPoint.y < StartPointSpace + position.y) {
        startPoint.y = StartPointSpace + position.y;
        offsetY = StartPointSpace;
      } else {
        startPoint.y = size.height + position.y - StartPointSpace;
        offsetY = size.height - StartPointSpace;
      }
    } else if (endPoint.y < position.y || endPoint.y > size.height + position.y) {
      // 结束点在组件上方
      direction = endPoint.y < position.y ? 'top' : 'bottom';
      offsetY = endPoint.y < position.y ? 0 : size.height;
      startPoint.y += offsetY;
      // 结束点的x值处于便签条x轴方向{x+10~width-10}内，视起始点与结束点x轴一致，始终保持为一条竖直线
      if (endPoint.x >= position.x + StartPointSpace && endPoint.x <= size.width + position.x - StartPointSpace) {
        startPoint.x = endPoint.x;
        offsetX = endPoint.x - position.x;
      } else if (endPoint.x < StartPointSpace + position.x) {
        startPoint.x = StartPointSpace + position.x;
        offsetX = StartPointSpace;
      } else {
        startPoint.x = size.width + position.x - StartPointSpace;
        offsetX = size.width - StartPointSpace;
      }
    }

    const width = Math.abs(endPoint.x - startPoint.x);
    const height = Math.abs(endPoint.y - startPoint.y);

    let realStartPoint = { x: 0, y: 0 };
    let realEndPoint = { x: 0, y: 0 };
    // 计算起终点构成svg，两点的相对位置
    if (startPoint.x > endPoint.x) {
      // 起点在终点右侧，终点x轴的位置作为svg起点x轴的位置，起点位置x轴的作为x轴的结束位置
      realStartPoint.x = width;
    } else {
      realEndPoint.x = width;
    }
    if (startPoint.y > endPoint.y) {
      // 起点在终点下侧，终点y轴的位置作为svg起点的y轴的位置，起点位置的y轴作为y轴的结束位置
      realStartPoint.y = height;
    } else {
      realEndPoint.y = height;
    }

    const paths = calcPathByTwoPoints(realStartPoint, realEndPoint, direction);

    return {
      direction,
      paths,
      size: { width, height },
      points: { startPoint: realStartPoint, endPoint: realEndPoint },
      svgPosition: {
        left: -realStartPoint.x + offsetX,
        top: -realStartPoint.y + offsetY,
      },
    };
  };

  const rerenderArrow = (lineType: LinePointType, ele?: React.ReactElement) => {
    if (!ele) {
      return null;
    }
    return HollowArrowList.includes(lineType) ? React.cloneElement(ele, { fill: '#fff' }) : ele;
  };

  /**
   * 连接线渲染
   */
  const renderConnectedLine = () => {
    if (!value.endPoint) {
      return null;
    }

    const lineProperties = comp.properties.connectedLine as INoteConnectedLine;
    const { startType, endType, color, lineWidth, lineType, disabled } = lineProperties;

    if (disabled) {
      return null;
    }

    const strokeProperty = {
      color,
      thickness: lineWidth,
      dashModel: lineType,
    };
    const parser = StyleHelper.initSVGStyleParser({ stroke: strokeProperty });
    const svgStroke = parser.getStroke();
    const { svgPosition, paths } = getLinePointData();

    const { point1: startPoint1, point2: startPoint2 } = getPathStartOrEndTwoPoint(paths, true);
    const { point1: endPoint1, point2: endPoint2 } = getPathStartOrEndTwoPoint(paths, false);
    const startConfig = {
      pointType: startType,
      linePoints: { startPoint: startPoint1, endPoint: startPoint2 },
      strokeInfo: svgStroke,
      isEnd: false,
    };
    const endConfig = {
      pointType: endType,
      linePoints: { startPoint: endPoint1, endPoint: endPoint2 },
      strokeInfo: svgStroke,
      isEnd: true,
    };
    const lineProps = {
      startArrow: true,
      endArrow: true,
      startPointType: startType,
      endPointType: endType,
    };

    const startArrowInfo = getArrowInfoByType(startConfig);
    const endArrowInfo = getArrowInfoByType(endConfig);
    const startClip = { width: startArrowInfo?.clipSize?.width || 0, height: startArrowInfo?.clipSize?.height || 0 };
    const endClip = { width: endArrowInfo?.clipSize?.width || 0, height: endArrowInfo?.clipSize?.height || 0 };
    const linePath = parseLineStr(paths, lineWidth, lineProps, startClip, endClip);
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" className="lib-comp-stick-note-line" style={{ ...svgPosition }}>
          <path {...svgStroke} d={linePath} fill="transparent" shapeRendering="geometricPrecision" />
          {rerenderArrow(startType, startArrowInfo?.el)}
          {rerenderArrow(endType, endArrowInfo?.el)}
        </svg>
      </>
    );
  };

  return (
    <div className="lib-comp-stick-note" style={{ ...size }}>
      <PathItem {...props} />
      {renderConnectedLine()}
    </div>
  );
};

export default StickNote;
