import * as React from 'react';

import { isUndefined, set, get, cloneDeep } from 'lodash';

import { isControlKeyPressed } from '@utils/hotkeysUtils';
import { dragDelegate } from '@/utils/mouseUtils';

import i18n from '@i18n';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';
import CoreEditor from '@editor/core';
import EditorContext from '@contexts/editor';
import { resetID, resetInteractionTargetID } from '@helpers/componentHelper';
import { getShortCutKey } from '@helpers/shortCutHelper';
import { mergePatches } from '@/helpers/patchHelper';
import { BatchUploadFilesItem } from '@/helpers/fileUploadHelper';
import {
  calcIndicatorSizeAndPosition,
  calcIndicatorItemInfo,
  ImageAliasType,
} from '@libs/compoundComponents/CarouselChart/config';
import { UIComponent, UIContainerComponent } from '@editor/comps';
import { IAutoCloseProps, IPopupComponent } from '@/dsm/withAutoClose';
import { IBounds } from '@fbs/common/models/common';
import { IComponentData } from '@fbs/rp/models/component';
import { Ops, ArtboardPatches } from '@/fbs/rp/utils/patch';

import { FloatPanel, IAutoCloseComponentProps, Icon, ScrollBars, withAutoClose } from '@dsm';
import Upload from './Upload';
import ValueItem from './ValueItem';

import './index.scss';

interface ICarouselChartEditorBaseProps extends IAutoCloseComponentProps {
  comp: UIContainerComponent;
  coreEditor: CoreEditor;
  sourceBounds: IBounds;
  onOpenIconLibrariesPanel?: Function;
}

interface ICarouselChartEditorProps extends ICarouselChartEditorBaseProps, IAutoCloseProps {
  onMouseDown: React.MouseEventHandler;
  onMouseUp: React.MouseEventHandler;
}

interface ICarouselChartEditorState {
  selectedIndex?: number;
  position: {
    left: number;
    top: number;
  };
}

const ITEM_HEIGHT = 66; // 轮播图每项的高度
const MAX_IMAGES = 10; // 最长子项

class CarouselChartEditor extends React.Component<ICarouselChartEditorProps, ICarouselChartEditorState> {
  static contextType = EditorContext;
  // @ts-ignore
  context: React.ContextType<typeof EditorContext>;
  private contentDom: React.RefObject<HTMLDivElement> = React.createRef();
  private scrollBar: React.RefObject<ScrollBars> = React.createRef();
  private uploadClass: React.RefObject<Upload> = React.createRef();

  private imageComp: UIContainerComponent;
  private indicatorComp: UIContainerComponent;

  constructor(props: ICarouselChartEditorProps) {
    super(props);
    this.imageComp = props.comp.getComponentByAlias('image-main') as UIContainerComponent;
    this.indicatorComp = props.comp.getComponentByAlias('indicator-main') as UIContainerComponent;
    const selectedIndex = this.imgLen > 0 ? 0 : undefined;
    this.state = {
      position: {
        left: props.sourceBounds.left,
        top: props.sourceBounds.top,
      },
      selectedIndex,
    };
  }

  componentDidMount() {
    if (this.contentDom.current) {
      this.contentDom.current.focus();
      this.adjustPosition();
    }
  }

  componentWillUnmount() {
    this.context.actionFinderManager.updateSource(undefined);
    this.props.comp.selectedItem = undefined;
    this.context.uiManager.interactionPanel?.refresh();
    this.context.uiManager.updatePropertiesPanel();
    this.context.uiManager.projectTree?.refresh();
  }

  get imgLen() {
    return this.imageComp.components.length;
  }

  // 给外部调用
  close() {
    this.props.onClose();
  }

  focus() {
    this.contentDom.current?.focus();
  }

  private adjustPosition() {
    if (this.props.forwardedRef) {
      const panel = this.props.forwardedRef.current;
      if (panel) {
        const { width, height, right, bottom } = panel.getBoundingClientRect();
        let { left, top } = this.state.position;
        const winHeight = window.innerHeight;
        const winWidth = window.innerWidth;
        if (bottom > winHeight) {
          top = Math.round(top - height);
        }
        if (right > winWidth) {
          left = Math.round(winWidth - width);
        }
        if (left !== this.state.position.left || top !== this.state.position.top) {
          this.setState({ position: { left, top } });
        }
      }
    }
  }

  private doUpdateSelectedIndex = (newIndex: number) => {
    if (this.state.selectedIndex !== newIndex) {
      this.setState({ selectedIndex: newIndex }, () => {
        this.doScroll();
        this.doSetCompSelectItem(this.state.selectedIndex);
      });
    }
  };

  handleAppendItem = () => {
    this.doAppendItem();
  };

  handleRemoveItem = () => {
    this.doRemoveItem(this.state.selectedIndex!);
  };

  handleMouseEnterItem = (index: number) => {
    const { comp } = this.props;
    this.context.uiManager.projectTree?.refresh(comp.components[index]);
  };

  handleMoveItemIndex = (oldIndex: number, newIndex: number) => {
    this.doMoveItemIndex(oldIndex, newIndex);
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    const { selectedIndex } = this.state;
    if (selectedIndex === undefined) {
      return;
    }

    if (e.keyCode === KeyCodeMap.VK_DEL || e.keyCode === KeyCodeMap.VK_BACKSPACE) {
      e.stopPropagation();
      this.doRemoveItem(selectedIndex);
      return;
    }
    if (
      [KeyCodeMap.VK_UP, KeyCodeMap.VK_DOWN, KeyCodeMap.VK_LEFT, KeyCodeMap.VK_RIGHT, KeyCodeMap.VK_ENTER].includes(
        e.keyCode,
      )
    ) {
      e.stopPropagation();
    }

    if (isControlKeyPressed(e)) {
      if (e.keyCode === KeyCodeMap.VK_UP) {
        // 上移
        if (selectedIndex === 0) {
          return;
        }
        this.doMoveItemIndex(selectedIndex, selectedIndex - 1);
      } else if (e.keyCode === KeyCodeMap.VK_DOWN) {
        // 下移
        if (selectedIndex === this.imgLen - 1) {
          return;
        }
        this.doMoveItemIndex(selectedIndex, selectedIndex + 2);
      } else if (e.keyCode === KeyCodeMap.VK_ENTER) {
        // 添加
        this.doAppendItem();
      }
    } else {
      if (e.keyCode === KeyCodeMap.VK_UP) {
        if (selectedIndex !== 0) {
          this.doUpdateSelectedIndex(selectedIndex - 1);
        }
      } else if (e.keyCode === KeyCodeMap.VK_DOWN) {
        if (selectedIndex !== this.imgLen - 1) {
          this.doUpdateSelectedIndex(selectedIndex + 1);
        }
      } else if (e.keyCode === KeyCodeMap.VK_ENTER) {
        this.handleImageChange(this.imageComp.components[selectedIndex]);
      }
    }
  };

  handleItemClick = (index: number) => {
    this.setState({ selectedIndex: index }, () => {
      this.doSetCompSelectItem(this.state.selectedIndex);
    });
  };

  // 更改选中值
  handleItemChecked = (index: number, checked: boolean) => {
    if (!checked) {
      return;
    }
    const { coreEditor, comp } = this.props;
    const oldValue = comp.value as number;
    const activeComp = this.indicatorComp.components[index];
    const oldActiveComp = this.indicatorComp.components[oldValue];
    const patches = {
      do: {
        [activeComp.id]: [Ops.replace('./selected', true)],
        [oldActiveComp.id]: [Ops.replace('./selected', false)],
        [comp.id]: [Ops.replace('./value', index)],
      },
      undo: {
        [activeComp.id]: [Ops.replace('./selected', false)],
        [oldActiveComp.id]: [Ops.replace('./selected', true)],
        [comp.id]: [Ops.replace('./value', oldValue)],
      },
    };
    coreEditor.updateSingleArtboard(coreEditor.activeArtboard.artboardID, patches);
  };

  handleMoveItem = (model: 'up' | 'down') => {
    const { selectedIndex } = this.state;
    if (!isUndefined(selectedIndex)) {
      let newIndex = selectedIndex;
      if (model === 'up') {
        newIndex = Math.max(0, selectedIndex - 1);
      } else {
        newIndex = Math.min(this.imgLen, selectedIndex + 2);
      }
      if (selectedIndex !== newIndex) {
        this.doMoveItemIndex(selectedIndex, newIndex);
      }
    }
  };

  handleInteractionDragger = (e: React.MouseEvent, index: number) => {
    const comp = this.imageComp?.components[index];
    if (comp) {
      this.handleItemClick(index);
      this.context.uiManager.workSpace?.page?.startFindInteractionTarget(e, comp);
    }
  };

  // 拖拽
  handleMouseDown = (e: React.MouseEvent) => {
    const panelDom = this.props.forwardedRef?.current;
    if (!panelDom) return;
    const maxLeft = window.innerWidth - panelDom!.clientWidth;
    const maxTop = window.innerHeight - panelDom!.clientHeight;
    const { position } = this.state;
    const startPoint = { left: position.left, top: position.top };
    const onMoving = (e: MouseEvent, delta: { x: number; y: number }) => {
      const newPosition = {
        left: startPoint.left + delta.x,
        top: startPoint.top + delta.y,
      };
      if (newPosition.left < 0) {
        newPosition.left = 0;
      } else if (newPosition.left > maxLeft) {
        newPosition.left = maxLeft;
      }
      if (newPosition.top < 0) {
        newPosition.top = 0;
      } else if (newPosition.top > maxTop) {
        newPosition.top = maxTop;
      }
      this.setState({ position: newPosition });
    };

    const onMoveEnd = () => {
      this.props.onMouseUp(e);
    };

    dragDelegate(onMoving, onMoveEnd);
  };

  private doScroll() {
    const { selectedIndex } = this.state;
    if (this.scrollBar.current && selectedIndex !== undefined) {
      const scrollBar = this.scrollBar.current;
      // 已滚动距离
      const top = scrollBar.getScrollTop();
      const selTop = ITEM_HEIGHT * selectedIndex;
      const selBottom = selTop + ITEM_HEIGHT;
      // 视口范围
      const viewHeight = scrollBar.getClientHeight();
      if (selTop - top >= viewHeight) {
        scrollBar.scrollTop(selBottom - viewHeight + top);
      } else if (selBottom - top <= 0) {
        scrollBar.scrollTop(selTop);
      }
    }
  }

  private doSetCompSelectItem(index?: number) {
    const { comp } = this.props;
    if (isUndefined(index)) {
      comp.selectedItem = undefined;
    } else {
      comp.selectedItem = this.imageComp.components[index];
    }
    this.context.uiManager.interactionPanel?.refresh();
    this.context.uiManager.updatePropertiesPanel();
  }

  private calcIndicatorInfoPatches = (len: number) => {
    const { comp } = this.props;
    const { indicatorComp } = this;
    const { size, position } = calcIndicatorSizeAndPosition(
      len,
      comp.size,
      get(comp, 'properties.carousel.indicator.indicatorType'),
    );
    return {
      do: {
        [indicatorComp.id]: [Ops.replace('./size', size), Ops.replace('./position', position)],
      },
      undo: {
        [indicatorComp.id]: [
          Ops.replace('./size', indicatorComp.size),
          Ops.replace('./position', indicatorComp.position),
        ],
      },
    };
  };

  /**
   * 重置选中指示器
   * @param index 最新的选中指示器下标
   */
  private resetSelectedIndicatorPatches = (index: number) => {
    const { comp } = this.props;
    const patches: ArtboardPatches = { do: {}, undo: {} };
    if (comp.value === index) {
      return patches;
    }
    const indicatorComps = this.indicatorComp.components;
    const selectedComp = indicatorComps.find((item) => item.selected);
    if (selectedComp) {
      patches.do[selectedComp.id] = [Ops.replace('./selected', false)];
      patches.undo[selectedComp.id] = [Ops.replace('./selected', true)];
    }
    patches.do[indicatorComps[index].id] = [Ops.replace('./selected', true)];
    patches.undo[indicatorComps[index].id] = [Ops.replace('./selected', false)];
    patches.do[comp.id] = [Ops.replace('./value', index)];
    patches.undo[comp.id] = [Ops.replace('./value', comp.value)];
    return patches;
  };

  /**
   * 删除组件，删除当前图片组件，对应的指示器组件，修改指示器的position和size，轮播图组件的value
   * 直接删除最后一个指示器，并重置选中指示器
   * @param index
   */
  private doRemoveItem = (index: number) => {
    const { coreEditor, comp } = this.props;
    const { imageComp, indicatorComp, imgLen } = this;
    const imgItemComp = imageComp.components[index];
    const indicatorItemComp = indicatorComp.components[imgLen - 1];
    const artboardID = coreEditor.activeArtboard.artboardID;
    if (this.imgLen > 1) {
      const value = comp.value as number;
      const { patches: imagePatches } = imageComp.removeComponents([imgItemComp]);
      const { patches: indicatorPatches } = indicatorComp.removeComponents([indicatorItemComp]);
      const resPatches = this.calcIndicatorInfoPatches(indicatorComp.components.length - 1);
      mergePatches(resPatches, imagePatches[artboardID]);
      mergePatches(resPatches, indicatorPatches[artboardID]);
      let newValue = value;
      if (value === index) {
        // 删除当前选中项，将删除前一项或者后一项置为选中
        newValue = Math.max(index - 1, 0);
      } else if (value > index) {
        // 删除选中项之前的子项，comp.value-1
        newValue = value - 1;
      }
      mergePatches(resPatches, this.resetSelectedIndicatorPatches(newValue));
      coreEditor.updateSingleArtboard(artboardID, resPatches);
      const editIndex = Math.max(index - 1, 0);
      this.doUpdateSelectedIndex(editIndex);
    }
    this.focus();
  };

  // 更换顺序
  private doMoveItemIndex = (oldIndex: number, newIndex: number) => {
    const { coreEditor, comp } = this.props;
    const value = comp.value as number;
    const patches: ArtboardPatches = { do: {}, undo: {} };
    const imagePatches = this.imageComp.moveChildOrder(oldIndex, newIndex);
    mergePatches(patches, imagePatches);
    const lastIndex = newIndex > oldIndex ? newIndex - 1 : newIndex;
    if (comp.value! >= Math.min(oldIndex, lastIndex) && comp.value! <= Math.max(oldIndex, lastIndex)) {
      let newVal = -1;
      if (comp.value === oldIndex) {
        // 本身发生变更
        newVal = lastIndex;
      } else if (newIndex > oldIndex) {
        // 往前移动
        newVal = value - 1;
      } else {
        // 往后移动
        newVal = value + 1;
      }
      mergePatches(patches, this.resetSelectedIndicatorPatches(newVal));
    }
    coreEditor.updateSingleArtboard(coreEditor.activeArtboard.artboardID, patches);
    this.doUpdateSelectedIndex(lastIndex);
  };

  private doAppendItem() {
    if (this.imgLen >= MAX_IMAGES) {
      return;
    }
    this.uploadClass.current?.doUploadImage(true, this.doAppendMultipleImg);
  }

  private handleAfterDrag = (e: React.DragEvent) => {
    e.stopPropagation();
    this.props.onMouseUp && this.props.onMouseUp(e);
  };

  /**
   * 批量添加图片,同时处理指示点的添加与位置调整
   * @param res
   */
  private doAppendMultipleImg = (res: BatchUploadFilesItem[]) => {
    const { coreEditor, comp } = this.props;
    const { imageComp, indicatorComp, imgLen } = this;
    const artboardID = coreEditor.activeArtboard.artboardID;

    let addImageDates: IComponentData[] = [];
    let addIndicatorDates: IComponentData[] = [];
    const { space, size } = calcIndicatorItemInfo(comp);
    res.forEach((res, index) => {
      // 图片数据复制并修改
      const baseImageDate = cloneDeep(imageComp.components[0].toJSON());
      Reflect.deleteProperty(baseImageDate.properties, 'fill');
      set(baseImageDate, 'properties.image.fitMode', 'original');
      set(baseImageDate, 'value', res.data.URL);
      set(baseImageDate, 'alias', ImageAliasType.Normal);
      addImageDates.push(baseImageDate);

      // 指示器数据复制与修改
      const baseIndicatorDate = cloneDeep(indicatorComp.components[imgLen - 1].toJSON());
      set(baseIndicatorDate, 'size.width', size.width);
      set(baseIndicatorDate, 'position.x', (size.width + space) * (imgLen + index));
      set(baseIndicatorDate, 'selected', false);
      addIndicatorDates.push(baseIndicatorDate);
    });

    // 图片patches
    const imageIdMap = resetID(addImageDates);
    resetInteractionTargetID(addImageDates, imageIdMap);
    const { patches: imagePatches } = imageComp.addComponents(addImageDates);

    // 指示器
    const indicatorIdMap = resetID(addIndicatorDates);
    resetInteractionTargetID(addIndicatorDates, indicatorIdMap);
    const { patches: indicatorPatches } = indicatorComp.addComponents(addIndicatorDates);

    const resPatches = this.calcIndicatorInfoPatches(res.length + imgLen);
    mergePatches(resPatches, imagePatches[artboardID]);
    mergePatches(resPatches, indicatorPatches[artboardID]);
    coreEditor.updateSingleArtboard(artboardID, resPatches);
    this.doUpdateSelectedIndex(imgLen);
  };

  // 更改图片地址
  private doChangeImageValue = (res: BatchUploadFilesItem[], component: UIComponent) => {
    const { coreEditor } = this.props;
    const url = res[0].data.URL;
    const fitModePath = './properties/image/fitMode';
    const patches = {
      do: {
        [component.id]: [
          Ops.replace('./value', url),
          Ops.replace(fitModePath, 'original'),
          Ops.replace('./alias', ImageAliasType.Normal),
        ],
      },
      undo: {
        [component.id]: [
          Ops.replace('./value', component.value),
          Ops.replace(fitModePath, get(component, 'properties.image.fitMode')),
          Ops.replace('./alias', component.alias),
        ],
      },
    };
    coreEditor.updateSingleArtboard(coreEditor.activeArtboard.artboardID, patches);
    this.forceUpdate();
  };

  private handleImageChange = (component: UIComponent) => {
    this.uploadClass.current?.doUploadImage(false, (res: BatchUploadFilesItem[]) => {
      this.doChangeImageValue(res, component);
    });
  };

  renderToolBar() {
    const { selectedIndex } = this.state;
    const imageLen = this.imgLen;
    const isAddDisable = imageLen >= MAX_IMAGES;
    const isRemoveDisable = imageLen <= 1 || isUndefined(selectedIndex) || selectedIndex === -1;

    return (
      <div className="value-item-editor-toolbar" onMouseDown={this.handleMouseDown}>
        <Icon
          tips={`${i18n('editor.append')} (${getShortCutKey('Enter', { ctrlKey: true })})`}
          cls="layer_plus"
          disabled={isAddDisable}
          onClick={this.handleAppendItem}
        />
        <div className="right-operation">
          <Icon
            tips={`${i18n('editor.up')} (${getShortCutKey('↑', { ctrlKey: true })})`}
            cls="icon_arrow_up"
            disabled={isUndefined(selectedIndex) || selectedIndex < 1}
            onClick={this.handleMoveItem.bind(this, 'up')}
          />
          <Icon
            tips={`${i18n('editor.down')} (${getShortCutKey('↓', { ctrlKey: true })})`}
            cls="icon_arrow_down"
            disabled={isUndefined(selectedIndex) || selectedIndex === -1 || selectedIndex >= imageLen - 1}
            onClick={this.handleMoveItem.bind(this, 'down')}
          />
          <Icon
            tips={`${i18n('general.delete')} (${getShortCutKey('Delete')})`}
            cls="demo_delete"
            disabled={isRemoveDisable}
            onClick={this.handleRemoveItem}
          />
        </div>
      </div>
    );
  }

  renderItemValueList() {
    const { selectedIndex } = this.state;
    const { comp: container } = this.props;
    const components = this.imageComp.components;
    const height = ITEM_HEIGHT * Math.min(components.length, 5);
    return (
      <div className="item-value-list" style={{ height }}>
        <ScrollBars ref={this.scrollBar} hiddenHorizontalScrollBar>
          {components.map((comp, index) => {
            const hasInteraction = Object.keys(components[index].interactions || {}).length > 0;
            return (
              <ValueItem
                moveable
                key={comp!.id}
                comp={comp!}
                index={index}
                hasInteraction={hasInteraction}
                checked={container.value === index}
                atLeastOne={components.length <= 1}
                selected={selectedIndex === index}
                onMouseEnter={this.handleMouseEnterItem}
                onMoveItem={this.handleMoveItemIndex}
                onClick={this.handleItemClick}
                onChecked={this.handleItemChecked}
                onInteractionDragger={this.handleInteractionDragger}
                onImageChange={this.handleImageChange}
              />
            );
          })}
        </ScrollBars>
      </div>
    );
  }

  render() {
    const { position } = this.state;
    const { onMouseUp, onMouseDown } = this.props;
    return (
      <FloatPanel
        customRef={this.props.forwardedRef}
        className="carousel-chart-editor"
        style={position}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        <div
          ref={this.contentDom}
          className="item-value-editor-content"
          tabIndex={-1}
          onKeyDown={this.handleKeyDown}
          onDragEnd={this.handleAfterDrag}
        >
          {this.renderToolBar()}
          {this.renderItemValueList()}
          <Upload ref={this.uploadClass} maxUploadLen={MAX_IMAGES - this.imgLen} />
        </div>
      </FloatPanel>
    );
  }
}

const PanelClass: React.ComponentClass<ICarouselChartEditorProps & IAutoCloseProps> = withAutoClose<
  ICarouselChartEditorProps
>(CarouselChartEditor);

export default class C extends React.Component<ICarouselChartEditorBaseProps & IAutoCloseProps> {
  static contextType = EditorContext;
  private timeID?: Timeout = undefined;

  componentDidMount() {
    this.context.uiManager.listItemValueEditorPanel = this;
  }

  componentWillUnmount() {
    this.context.uiManager.listItemValueEditorPanel = undefined;
    clearTimeout(this.timeID);
  }

  get preventClose(): boolean {
    if (this.panel.current) {
      return !!(this.panel.current as IPopupComponent).preventClose;
    }
    return false;
  }

  set preventClose(value: boolean) {
    if (this.panel.current) {
      (this.panel.current as IPopupComponent).preventClose = value;
    }
  }

  handleMouseDown = () => {
    clearTimeout(this.timeID);
    if (this.panel.current) {
      (this.panel.current as IPopupComponent).preventClose = true;
    }
  };

  handleMouseUp = () => {
    this.timeID = window.setTimeout(() => {
      if (this.panel.current) {
        (this.panel.current as IPopupComponent).preventClose = false;
      }
    });
  };

  // @ts-ignore
  panel: React.RefObject<PanelClass> = React.createRef();

  render() {
    return (
      <PanelClass ref={this.panel} {...this.props} onMouseUp={this.handleMouseUp} onMouseDown={this.handleMouseDown} />
    );
  }
}
