import { isInWeMeet } from '@wemeet/src/helper/weMeet';

const UA = window.navigator.userAgent;

const isMac = UA.indexOf('Macintosh') !== -1;
const isFF = UA.indexOf('Firefox') !== -1;
const _isElectron = UA.toLowerCase().indexOf(' electron') !== -1;

enum OSSystem {
  macOS = 'macOS',
  Windows = 'windows',
  Android = 'android',
  Linux = 'linux',
  iOS = 'ios',
  Unknown = 'unknown',
}

/**
 * 当前系统是否为mac
 * @type {boolean}
 */
export const isMacOS = isMac;

/**
 * 当前浏览器是否为FireFox
 * @type {boolean}
 */
export const isFireFox = isFF;

export const isElectron = _isElectron;

export const isChrome = (): boolean => {
  return /Chrome/.test(UA);
};

export const isSafari = (): boolean => {
  return /Safari/.test(UA) && !isChrome();
};

/**
 * 当前是否为桌面端mockplus
 * @type {boolean}
 */
export const isMockRPD = UA.includes('mockRPD') || UA.includes('mockplusrp');

export const isLarkPC = UA.indexOf('LarkLocale') !== -1; // 嵌入飞书的

export const titleBarHeight = isMockRPD ? (isMacOS ? 24 : 40) : isLarkPC ? 36 : 0;

/**
 * 判断是否在iframe元素内
 */
export const isIframe = window.self !== window.top;

/**
 * 当前是否在嵌入文档内
 * 在分享时选择嵌入文档时生成的链接重定向后的地址中
 */
export const isShareFrame = new URLSearchParams(location.search).get('isFrame') === 'true';

/**
 * 当前浏览器是否为ie
 * @returns {boolean}
 */
export const isIE = (): boolean => {
  const { userAgent } = window.navigator;
  if ('ActiveXObject' in window) {
    return true;
  }
  //判断是否Edge浏览器
  return userAgent.indexOf('Trident') > -1;
};

/**
 * 获取显示器的 DPI
 */
export function getDevicePixelRatio(): number {
  return window.devicePixelRatio || 1;
}

/**
 * 获取当前操作系统
 * 注意这个方法并不严谨
 * https://developers.whatismybrowser.com/useragents/explore/operating_system_name/
 */
export function getOSSystem(): OSSystem {
  if (isMac) {
    return OSSystem.macOS;
  }
  if (UA.indexOf('Windows') !== -1) {
    return OSSystem.Windows;
  }
  if (UA.indexOf('Android') !== -1 || UA.indexOf('Adr') !== -1) {
    return OSSystem.Android;
  }
  if (UA.indexOf('Linux') !== -1) {
    return OSSystem.Linux;
  }
  if (UA.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    return OSSystem.iOS;
  }
  if (UA.indexOf('iPhone') !== -1 || UA.indexOf('iPad') !== -1) {
    return OSSystem.iOS;
  }
  return OSSystem.Unknown;
}

export const isDev = process.env.NODE_ENV === 'development';

/**
 * 当前是否苹果系统
 * @type {boolean}
 */
export const isAppleOS = [OSSystem.macOS, OSSystem.iOS].includes(getOSSystem());

/**
 * 在腾讯会议中的AppleOS
 * @type {boolean}
 */
export const isAppleOSInWeMeet = isInWeMeet() && isAppleOS;

/**
 * 是否是例子项目编辑页
 */
export const isExample = window.location.href.includes('/example');
