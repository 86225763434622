import * as React from 'react';

import classNames from 'classnames';

import { getRealUrl } from '../config';
import { getListEvents, IDomListEvent, IDomEvents } from '../../helps';
import { StyleHelper } from '@/helpers/styleHelper';
import { hasInteraction } from '@/helpers/interactionHelper';
import { UIContainerComponent, UIComponent } from '@/editor/comps';
import { IListSealedComponentItemEvents } from '@libs/types';

import './index.scss';

interface ICarouselEffectProps {
  imageMainComp: UIContainerComponent;
  value: number;
  playSpeed: number;
  isPreview?: boolean;
  itemEvents?: IListSealedComponentItemEvents;
  disabled?: boolean;
  onTransitionEnd?: () => void;
}

interface ICarouselEffectState {
  current: number;
  canAnimate: boolean;
  slideIndex: number;
}

const EVENT_MAP = {
  onClick: 'click',
  onDoubleClick: 'doubleClick',
  onContextMenu: 'contextMenu',
} as { [event: string]: string };

export class SlideCarouselEffect extends React.Component<ICarouselEffectProps, ICarouselEffectState> {
  events: IDomListEvent;

  constructor(props: ICarouselEffectProps) {
    super(props);
    this.state = {
      current: props.value,
      slideIndex: props.value,
      canAnimate: !!props.isPreview,
    };
    this.events = props.isPreview ? getListEvents(props.imageMainComp.components, props.itemEvents) : {};
  }

  // 获取每个图标的交互事件，图片与整个轮播图存在相同的交互事件，只执行图片交互
  getItemEvents = (comp: UIComponent) => {
    const { disabled } = this.props;
    const itemEvents = this.events[comp.id];
    const interactions = comp.interactions;
    if (itemEvents) {
      return Object.keys(itemEvents).reduce((prev, key) => {
        if (interactions[EVENT_MAP[key]]) {
          prev[key] = (
            e: React.MouseEvent<Element, MouseEvent> & React.FocusEvent<Element, Element> & React.TouchEvent<Element>,
          ) => {
            e.stopPropagation();
            if (!disabled) {
              itemEvents[key](e);
            }
          };
        }
        return prev;
      }, {} as IDomEvents);
    }
    return {};
  };

  get imageComps() {
    const components = this.props.imageMainComp.components as UIComponent[];
    return components.length === 1 ? [components[0], components[0]] : components;
  }

  get style() {
    return { ...this.props.imageMainComp.size };
  }

  get animateDuration() {
    return this.state.canAnimate ? this.props.playSpeed : 0;
  }

  // override
  public doActiveIndexChange = (index: number) => {
    this.setState({
      slideIndex: index,
      canAnimate: this.props.isPreview ? true : false,
    });
  };

  // 动画结束，处于临界状态时，暂停动画，重置下标
  handleTransitionEnd = () => {
    const { slideIndex } = this.state;
    const { imageComps } = this;
    if (slideIndex > imageComps.length - 1) {
      // 当在最末端的时候 取消动画 并将坐标重制为0
      this.setState({
        current: 0,
        slideIndex: 0,
        canAnimate: false,
      });
    } else if (slideIndex < 0) {
      // 当在最前端的时候 取消动画 并将坐标重制为最大
      this.setState({
        current: imageComps.length - 1,
        slideIndex: imageComps.length - 1,
        canAnimate: false,
      });
    } else {
      this.setState({
        current: slideIndex,
        canAnimate: false,
      });
    }
    this.props.onTransitionEnd?.();
  };

  renderImg = (itemComponent: UIComponent, index?: number, style?: React.CSSProperties) => {
    const fitMode = itemComponent.properties.image?.fitMode;
    const fill = itemComponent.properties.fill;
    const imgClassName = classNames('img-box', { [`fit-mode-${fitMode}`]: !!fitMode });
    const { background } = StyleHelper.initCSSStyleParser({ fill }).getFillStyle();
    const _hasInteraction = hasInteraction(itemComponent);
    return (
      <div
        key={`${itemComponent.id}-${index}`}
        className={classNames('image-item', { 'item-hot-area': this.props.isPreview && _hasInteraction })}
        style={{ ...itemComponent.size, ...style, backgroundColor: background as string }}
        {...this.getItemEvents(itemComponent)}
      >
        <img
          className={imgClassName}
          draggable={false}
          src={getRealUrl(itemComponent)}
          style={{ ...itemComponent.size }}
        />
      </div>
    );
  };

  renderItem = (item: UIComponent, index = 0) => {
    if (index === this.state.current + 1) {
      const _hasInteraction = hasInteraction(item);
      return (
        <div
          className={classNames({ 'item-hot-area': this.props.isPreview && _hasInteraction })}
          style={this.style}
          key={`${item.id}-${index}`}
          {...this.getItemEvents(item)}
        ></div>
      );
    }
    return this.renderImg(item, index);
  };

  // slide effect 效果
  // @override
  renderCarouselChart = () => {
    const { imageComps, animateDuration, style } = this;
    const { slideIndex, current } = this.state;
    const carouselImages = [imageComps[imageComps.length - 1], ...imageComps, imageComps[0]];
    const currentComp = imageComps[current];
    const innerStyle = {
      width: `${carouselImages.length * 100}%`,
      transition: `transform ${animateDuration}ms linear`,
      transform: `translateX(${-(slideIndex + 1) * (style.width as number)}px)`,
    };
    return (
      <div className="image-main-inner image-slide-effect">
        {this.renderImg(currentComp)}
        <div className="image-slide-content" style={innerStyle}>
          {carouselImages.map((item, index) => this.renderItem(item, index))}
        </div>
      </div>
    );
  };

  renderEditorImage = () => {
    const currentComp = this.imageComps[this.props.value];
    return <div className="image-main-inner">{this.renderImg(currentComp)}</div>;
  };

  render() {
    const { imageMainComp, isPreview } = this.props;
    return (
      <div className="carousel-effect" style={imageMainComp.size} onTransitionEnd={this.handleTransitionEnd}>
        {isPreview ? this.renderCarouselChart() : this.renderEditorImage()}
      </div>
    );
  }
}

export class FadeCarouselEffect extends SlideCarouselEffect {
  public doActiveIndexChange = (_index: number, realIndex?: number) => {
    this.setState({
      current: realIndex as number,
      canAnimate: this.props.isPreview ? true : false,
    });
  };

  // override
  handleTransitionEnd = () => {
    this.props.onTransitionEnd?.();
  };

  renderCarouselChart = () => {
    const { imageComps, animateDuration, style } = this;
    return (
      <div className="image-main-inner image-fade-effect" style={style}>
        {imageComps.map((item, index) =>
          this.renderImg(item, index, {
            opacity: Number(index === this.state.current),
            transition: `opacity ${animateDuration}ms linear`,
          }),
        )}
      </div>
    );
  };
}

export class PushCarouselEffect extends SlideCarouselEffect {
  // override
  public doActiveIndexChange = (index: number) => {
    this.setState({
      current: index,
      canAnimate: this.props.isPreview ? true : false,
    });
  };

  // override
  handleTransitionEnd = () => {
    const { current } = this.state;
    const { imageComps } = this;
    if (current > imageComps.length - 1) {
      // 当在最末端的时候 取消动画 并将坐标重制为0
      this.setState({
        current: 0,
        canAnimate: false,
      });
    } else if (current < 0) {
      // 当在最前端的时候 取消动画 并将坐标重制为最大
      this.setState({
        current: imageComps.length - 1,
        canAnimate: false,
      });
    }
    this.props.onTransitionEnd?.();
  };

  // slide effect 效果
  renderCarouselChart = () => {
    const { imageComps, animateDuration, style } = this;
    const carouselImages = [imageComps[imageComps.length - 1], ...imageComps, imageComps[0]];
    const innerStyle = {
      width: `${carouselImages.length * 100}%`,
      transition: `transform ${animateDuration}ms linear`,
      transform: `translateX(${-(this.state.current + 1) * (style.width as number)}px)`,
    };
    return (
      <div className="image-main-inner image-push-effect" style={innerStyle}>
        {carouselImages.map((item, index) => this.renderImg(item, index))}
      </div>
    );
  };
}
