import * as React from 'react';
import classnames from 'classnames';

import Badge from '../Badge';
import { ComponentTheme } from '../common';

import './index.scss';

export interface ITabBarItem {
  id: string | number;
  text: string;
  disabled?: boolean;
  width?: number;
  count?: number;
}

export interface ITabBarProp {
  items: ITabBarItem[];
  height?: number;
  width?: number;
  selected: number;
  // 是否使用不同宽度的项，为true时，忽略width属性
  differentItemWidth?: boolean;
  direction?: 'horizontal' | 'vertical';
  className?: string;
  style?: React.CSSProperties;
  theme?: ComponentTheme;
  showIndicator: boolean;
  borderBottomWidth?: number | string;
  borderBottomSize?: number;
  borderBottomColor?: string;
  checkoutItemDisabled?: (item: ITabBarItem) => boolean;
  onItemClick?: (itemID: string | number) => void;
  onMouseDown?: React.MouseEventHandler;
  onMouseUp?: React.MouseEventHandler;
}

export interface ITabBarState {
  height?: number;
  width?: number;
  effect: boolean;
}

class TabBar extends React.Component<ITabBarProp, ITabBarState> {
  static defaultProps: Partial<ITabBarProp> = {
    direction: 'horizontal',
    showIndicator: true,
    height: 36,
  };
  private self: React.RefObject<HTMLDivElement> = React.createRef();

  private itemWidth: number[] = [];

  constructor(props: ITabBarProp) {
    super(props);
    this.state = {
      height: props.height,
      width: props.width,
      effect: !props.differentItemWidth,
    };
  }

  componentDidMount() {
    if (this.self.current) {
      const nodes = this.self.current.querySelectorAll('.bar-item');
      const { direction, differentItemWidth } = this.props;
      nodes.forEach((dom) => {
        const { width, height } = dom.getBoundingClientRect();
        direction === 'vertical' ? this.itemWidth.push(height) : this.itemWidth.push(width);
      });
      if (!this.state.height || this.state.width) {
        const { width, height } = this.self.current.getBoundingClientRect();
        this.setState({
          width: this.props.width || Math.round(width),
          height: this.props.height || Math.round(height),
        });
      }
      if (differentItemWidth) {
        this.setState({ effect: false }, () => {
          this.setState({ effect: true });
        });
      }
    }
  }

  render() {
    const {
      items,
      selected,
      className,
      style,
      theme,
      differentItemWidth,
      showIndicator,
      direction,
      borderBottomColor,
      borderBottomSize,
      borderBottomWidth,
      checkoutItemDisabled,
      onItemClick,
      onMouseDown,
      onMouseUp,
    } = this.props;
    const styles: React.CSSProperties = { ...(style || {}) };
    const { width, height, effect } = this.state;
    if (direction === 'vertical') {
      styles.width = width;
    } else {
      styles.height = height;
      styles.lineHeight = `${height}px`;
    }

    const calculatePercentWH = `${100 / items.length}%`;
    const calculatePercentLT = `${(selected / items.length) * 100}%`;
    const selStyle: React.CSSProperties = {
      width: direction === 'vertical' ? '100%' : calculatePercentWH,
      height: direction === 'vertical' ? calculatePercentWH : '100%',
      left: direction === 'vertical' ? 0 : calculatePercentLT,
      top: direction === 'vertical' ? calculatePercentLT : 0,
    };
    if (differentItemWidth && this.itemWidth.length) {
      let left = 0;
      for (let i = 0; i < this.itemWidth.length && i < selected; i++) {
        left += this.itemWidth[i];
      }
      selStyle.left = left;
      if (selected < this.itemWidth.length && selected >= 0) {
        selStyle.width = this.itemWidth[selected];
      }
    }

    return (
      <div
        ref={this.self}
        className={classnames('dsm-c-rp-tab-bar', direction ?? 'horizontal', theme ?? '', className ?? '')}
        style={styles}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        {showIndicator && selected !== -1 && selected < items.length && (
          <div className={classnames('tab-bar-select-indicator', { effect })} style={selStyle}>
            <div
              className={classnames(direction)}
              style={{
                width: borderBottomWidth,
                height: borderBottomSize,
                backgroundColor: borderBottomColor,
              }}
            />
          </div>
        )}
        <div className={classnames('tab-bar-content', direction || 'horizontal')}>
          {items.map((item, i) => {
            const { id, text, count, width } = item;
            let { disabled } = item;
            if (checkoutItemDisabled) {
              disabled = checkoutItemDisabled(item);
            }
            return (
              <div
                key={`${id}`}
                className={classnames('bar-item', { selected: i === selected, disabled })}
                style={{ width: width || (differentItemWidth ? 'auto' : `${100 / items.length}%`) }}
                onClick={() => {
                  onItemClick && onItemClick(id);
                }}
              >
                <Badge count={count} position="right" size={16}>
                  {text}
                </Badge>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default TabBar;
