import { PagePatches, ArtboardPatches } from '@fbs/rp/utils/patch';
import { reArrangeComponentOfWrapPanel } from '@helpers/groupHelper';

import { IComponentData } from '@fbs/rp/models/component';
import { Ops } from '@fbs/rp/utils/patch';
import { MoveDelta } from '@fbs/common/models/resize';
import { coverPatches } from '@helpers/patchHelper';
import {
  ComponentChange,
  ComponentChangeType,
  ContainerPatches,
  updateEditComponentsPatches,
  updateUnChangedComponentsPatches,
} from '@editor/comps/resizeHelper';
import { getBoundsInParent } from '@helpers/componentHelper';
import * as BoundsUtils from '@utils/boundsUtils';
import { IUICompConstructOptions } from '@/customTypes';

import { makeComponents } from './factory';
import { UIComponent, UIContainerComponent } from '.';

export default class UIWrapPanelComponent extends UIContainerComponent {
  /**
   * 不可移动子。继承的属性
   * @type {boolean}
   * @override UIContainerComponent.canMoveChildren
   */
  public canMoveChildren = false;

  constructor(data: IComponentData, public parent?: UIContainerComponent, public options?: IUICompConstructOptions) {
    super(data, parent, options);
    // reArrangeComponentOfWrapPanel(data);
    if (data.components) {
      this.components = makeComponents(data.components, this, options);
    } else {
      this.components = [];
    }
    reArrangeComponentOfWrapPanel(data);
  }

  /**
   * 刷新子组件
   * @override UIContainerComponent.refreshComponents
   */
  refreshComponents() {
    super.refreshComponents();
    reArrangeComponentOfWrapPanel(this.data);
  }

  /**
   * 添加子组件
   * @param {IComponentData[]} components
   * @param index
   * @returns {{
   *     patches: PagePatches,
   *     newActiveGroup?: UIContainerComponent,
   *   }}
   * @memberof UIWrapPanelComponent
   */
  addComponents(
    components: IComponentData[],
    // eslint-disable-next-line no-unused-vars
    index: number = -1,
  ): {
    patches: PagePatches;
    newActiveGroup?: UIContainerComponent;
  } {
    /*
      1、计算添加子组件锚定
      2、添加子组件
      3、计算自身尺寸
     */
    const patches: ArtboardPatches = {
      do: {
        [this.id]: [Ops.addChildren('-1', components)],
      },
      undo: {
        [this.id]: [Ops.removeChildren(components.map((comp) => comp._id))],
      },
    };
    const allComponents = this.components.map((comp) => comp.toJSON()).concat(components);
    const { layout } = this.properties;
    const { direction } = layout || {
      direction: 'horizontal',
      verticalAlign: 'middle',
      horizontalAlign: 'center',
    };

    let maxCellSize = 0;
    let newHeight = 0;
    let newWidth = 0;
    const size = this.size;
    const { width, height } = size;
    if (direction === 'vertical') {
      newHeight = height;
    } else {
      newWidth = width;
    }

    let totalSize = 0;

    allComponents.forEach((comp) => {
      const compSize = comp.size;
      if (direction === 'vertical') {
        totalSize += compSize.height;
        if (totalSize > height) {
          totalSize = 0;
          newWidth += maxCellSize;
          maxCellSize = 0;
        } else {
          maxCellSize = Math.max(maxCellSize, compSize.width);
        }
      } else {
        totalSize += compSize.width;
        if (totalSize > width) {
          totalSize = 0;
          newHeight += maxCellSize;
          maxCellSize = 0;
        } else {
          maxCellSize = Math.max(maxCellSize, compSize.height);
        }
      }
    });
    if (newWidth !== width || newHeight !== height) {
      patches.do[this.id].push(Ops.replace('', { ...size, height: newHeight, width: newWidth }));
      patches.undo[this.id].push(Ops.replace('', size));
    }
    const posPatches = this.afterAppendComponents(components);
    if (posPatches) {
      coverPatches(patches, posPatches);
    }
    return {
      patches: {
        [this.ownerArtboardID]: patches,
      },
    };
  }

  /**
   *
   * @param {[]} components
   * @param {MoveDelta} delta
   * @return {PagePatches}
   */
  // eslint-disable-next-line no-unused-vars
  moveChildren(components: UIComponent[], delta: MoveDelta): PagePatches {
    /*
      两种方案：
      1、禁止子组件移动
      2、根据移动位置重新确定顺序
     */
    return {
      [this.ownerArtboardID]: {
        do: {},
        undo: {},
      },
    };
  }

  getPositionPatchesOfChildrenChanged(changes: ComponentChange[], includeSelf: boolean = false): ContainerPatches {
    // 计算出因为这些影响导致的前后该容器的区域变化，总体 x 偏移多少，y 偏移多少
    // 移动所有剩下的子的 position，注意不修改本组件，本组件由父来决定是否修改
    // 向上遍历，让父决定它的子如何移动
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    // 1. 获取 this.size 确定当前组件的区域，位置 (0,0)
    // 2. 根据 change 结合 this.components 计算新的组件的区域 newBounds
    // 3. 计算出偏移 x, y
    // 4. 根据 x, y 偏移不在 change 之中的组件，注意：不是要移动这些组件，而是要让他们因为这个改变，在视觉上仍然能保持在原来的位置上，例如 x = -50，则给剩下的组件 +50
    // 5. added, removed 的组件由调用者自行处理
    // 6. edit 的组件，要根据 x, y 重新确定最终实际修改为多少
    // 7. 调用父的 getPositionPatchesOfChildrenChanged chang 为当前容器，type 为 edit，参数通过 newBounds.size, this.position + (x,y) 计算得来

    // 新的Group边界 = (其余未改变的comp)的边界 + （changedCompBounds - removed comp）边界
    const changedCompBounds = changes
      .filter((changedComp) => changedComp.type !== ComponentChangeType.Removed)
      .map((changedCompData) => {
        const { size, position, rotate } = changedCompData;
        return getBoundsInParent({ size, position, rotate });
      });

    const changeCompsIDArr = changes.map((comp) => comp.id);
    const originUnchangedComp = this.components.filter((comp) => !changeCompsIDArr.includes(comp.id));
    const originUnchangedCompBounds = originUnchangedComp.map((comp) => comp.getViewBoundsInParent());
    const totalCompNewBounds = BoundsUtils.union(...changedCompBounds.concat(originUnchangedCompBounds));

    const xDiff = totalCompNewBounds.left;
    const yDiff = totalCompNewBounds.top;

    //对于edit的组件的处理
    // 先修改变化的子到新的position和size,如果父的position有变化了，需要修正position
    updateEditComponentsPatches(
      this.components,
      changes,
      {
        x: xDiff,
        y: yDiff,
      },
      patches,
    );

    // 对于added组件的处理
    changes
      .filter((changedComp) => changedComp.type === ComponentChangeType.Add)
      .forEach((addingComp) => {
        // 因为addingComp.position 和 最终 patch [this.id]: [Ops.addChildren('-1', newAddedComponents)] 中的newAddedComponents.position
        // 都是同一个position(指针相同)，所以在这里直接修改，而不要用undo,redo patch
        addingComp.position.x = xDiff ? addingComp.position.x - xDiff : addingComp.position.x;
        addingComp.position.y = yDiff ? addingComp.position.y - yDiff : addingComp.position.y;
      });

    // 如果当前组的position有变化，需要修正所有子组件的position
    updateUnChangedComponentsPatches(
      originUnchangedComp,
      {
        x: xDiff,
        y: yDiff,
      },
      patches,
    );

    const newPosition = {
      x: xDiff,
      y: yDiff,
    };

    const newSize = {
      width: totalCompNewBounds.width,
      height: totalCompNewBounds.height,
    };

    if (includeSelf && this.parent) {
      const position = this.position;
      const parentChange = this.parent.getPositionPatchesOfChildrenChanged(
        [
          {
            id: this.id,
            type: ComponentChangeType.Edit,
            position: {
              x: position.x + xDiff,
              y: position.y + yDiff,
            },
            size: newSize,
            rotate: 0,
          },
        ],
        true,
      );
      if (parentChange.patches) {
        coverPatches(patches, parentChange.patches);
      }
    }
    return {
      patches,
      position: newPosition,
      size: newSize,
      rotate: 0,
    };
  }
}
