import * as React from 'react';

import ColorPicker from './ColorPicker';
import { IColorInfo } from './ColorPicker/models';

import './index.scss';

interface IColorProps {
  isShow: boolean;
  color: string;

  closeColor(): void;
  openColor(): void;
  onClose(color: IColorInfo): void; // 弹框关闭时会被调用
  liveUpdate(color: IColorInfo): void;
  onTrigger?(show: boolean): void;
}

interface IColorState {
  chooseColor: boolean;
}

class Color extends React.Component<IColorProps, IColorState> {
  static defaultProps: Partial<IColorProps> = {
    isShow: true,
  };
  color: IColorInfo | null;

  constructor(props: IColorProps) {
    super(props);
    this.color = null;
    this.state = {
      chooseColor: false,
    };
  }

  componentWillReceiveProps(nextProps: IColorProps) {
    if (nextProps.isShow !== this.props.isShow) {
      this.setState({
        chooseColor: nextProps.isShow,
      });
    }
  }

  chooseColor = (e: any) => {
    this.setState(
      {
        chooseColor: !this.state.chooseColor,
      },
      () => {
        this.props.onTrigger && this.props.onTrigger(this.state.chooseColor);
      },
    );
    e.stopPropagation();
  };

  onChangeColor = (color: IColorInfo) => {
    this.color = color;
    if (this.props.liveUpdate) {
      this.props.liveUpdate(color);
    }
  };

  onClose = () => {
    this.setState({ chooseColor: false }, () => {
      if (this.color) {
        this.props.onClose && this.props.onClose(this.color);
      }
    });
  };

  render() {
    const { color } = this.props;
    return (
      <div className="dsm-c-color">
        <div className="dsm-c-color-block" style={{ backgroundColor: this.props.color }} onClick={this.chooseColor} />
        {this.state.chooseColor && (
          // @ts-ignore
          <ColorPicker color={color} changeColor={this.onChangeColor} onClose={this.onClose} />
        )}
      </div>
    );
  }
}

export default Color;
