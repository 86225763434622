import * as React from 'react';
import { isUndefined } from 'lodash';

import classnames from 'classnames';

import { ScrollBars } from '@dsm';

import { IComponentData } from '@fbs/rp/models/component';
import { FillType } from '@fbs/rp/models/properties/fill';
import { HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import { StyleHelper } from '@helpers/styleHelper';

import { DefaultStrokeColor, DefaultWhiteFillColor, ShadowColor } from '@consts/colors';
import { ScrollMode } from '@consts/enums/scrollMode';
import { SpriteThumb } from '@consts/spriteIcons';
import { DefaultBorder } from '@/consts/border';

import { UIContainerComponent } from '@editor/comps';
import EditorContext from '@contexts/editor';
import { isMacOS } from '@utils/envUtils';
import { depthClone } from '@utils/globalUtils';
import { isSafari } from '@dsm2/components/utils';

import i18n from '@i18n';

import { makeCommonComponent, addTooltipsProperty } from '../../helper';
import { IComponentProps, IComponentItem } from '../../types';
import { CCanvasPanel, CHorizontalMenu } from '../../constants';
import Background from '../../basic/common/Background';

import './index.scss';

interface IPanelProps extends IComponentProps {
  isContainerActiveContainer?: Boolean;
  ignoreFill?: boolean;
  ignoreStroke?: boolean;
}

export const DefaultCanvasData: Partial<IComponentData> = {
  size: {
    width: 300,
    height: 300,
  },
  layout: {
    responsive: false,
    auto: true,
    fixedWidth: false,
    fixedHeight: false,
    horizontal: HorizontalAlign.Auto,
    vertical: VerticalAlign.Auto,
  },
  properties: {
    container: {
      scroll: true,
      showScroll: true,
      scrollMode: ScrollMode.Both,
    },
    fill: {
      disabled: false,
      type: FillType.solid,
      color: DefaultWhiteFillColor,
    },
    stroke: {
      thickness: 1,
      color: DefaultStrokeColor,
      disabled: false,
    },
    border: DefaultBorder,
    radius: {
      isPercent: false,
      disabled: true,
      topRight: 0,
      topLeft: 0,
      bottomRight: 0,
      bottomLeft: 0,
    },
    shadow: {
      disabled: true,
      x: 0,
      y: 3,
      blur: 6,
      color: ShadowColor,
    },
  },
};

export const makeCanvas = addTooltipsProperty(_makeCanvas);

function _makeCanvas(id: string, data?: Partial<IComponentData>): IComponentData {
  if (data) {
    return makeCommonComponent(id, CCanvasPanel, data);
  }
  return makeCommonComponent(id, CCanvasPanel, depthClone(DefaultCanvasData));
}

export const CanvasPanelConfig: IComponentItem = {
  type: CCanvasPanel,
  name: i18n('resource.components.canvasPanel'),
  thumb: {
    spriteIconClass: SpriteThumb.CanvasPanel.className,
    dragPosition: SpriteThumb.CanvasPanel.position,
  },
};
export default class CanvasPanel extends React.Component<IPanelProps> {
  static contextType = EditorContext;
  private scrollbarDom: React.RefObject<ScrollBars> = React.createRef();

  componentDidMount() {
    window.addEventListener('resetPanelScrollbar', this.resetScrollbar);
  }

  componentWillUnmount() {
    window.removeEventListener('resetPanelScrollbar', this.resetScrollbar);
  }

  private resetScrollbar = () => {
    const scrollbarDom = this.scrollbarDom.current;
    if (scrollbarDom) {
      scrollbarDom.scrollToTop();
      scrollbarDom.scrollToLeft();
    }
  };

  render() {
    const { comp, isPreview, isContainerActiveContainer, ignoreFill, ignoreStroke } = this.props;
    const { properties, size, isSealed, opacity } = comp;
    const { container, stroke, fill, radius, border } = properties;
    const styleParser = StyleHelper.initCSSStyleParser(properties);
    let style: React.CSSProperties = {
      ...styleParser.getShadowStyle(isSafari()),
    };
    const transition = comp.getTransition();
    style = {
      ...size,
      ...style,
      ...styleParser.getRadiusStyle(size),
      opacity: isUndefined(comp.opacity) ? 1 : comp.opacity / 100,
      transition,
    };

    const hiddenScrollBars = !container?.showScroll;
    const showBothScroll = !container?.scrollMode || container?.scrollMode === ScrollMode.Both;
    const showHorizontalScroll = container?.scrollMode === ScrollMode.Horizontal || showBothScroll;
    const showVerticalScroll = container?.scrollMode === ScrollMode.Vertical || showBothScroll;

    const expand = !isPreview && isContainerActiveContainer;
    const clip = isPreview || !isContainerActiveContainer;
    const isTransparent = opacity === 0;
    return (
      <div
        className={classnames('lib-comp-panel', {
          preview: isPreview,
          clip,
          expand,
          'no-sealed': !isSealed,
          macOS: isMacOS && hiddenScrollBars,
        })}
        style={style}
      >
        {fill && !fill.disabled && !isTransparent && (
          <Background
            size={size}
            transition={transition}
            properties={{ fill: ignoreFill ? undefined : fill, radius }}
          />
        )}
        {container && container.scroll && isPreview ? (
          <ScrollBars
            ref={this.scrollbarDom}
            autoHide
            hiddenHorizontalScrollBar={!showHorizontalScroll || hiddenScrollBars}
            hiddenVerticalScrollBar={!showVerticalScroll || hiddenScrollBars}
            disabledHoriScroll={!showHorizontalScroll}
            disabledVertScroll={!showVerticalScroll}
            thumbClassName="lib-comp-canvas-scroll-thumb"
          >
            {this.props.children}
          </ScrollBars>
        ) : (
          this.props.children
        )}
        {!isPreview && !(comp as UIContainerComponent).components.length && (
          <p className="empty-container-tips" style={{ lineHeight: `${comp.size.height}px` }}>
            {i18n('tips.doubleClickComponent')}
          </p>
        )}
        {stroke && !stroke.disabled && !isTransparent && (
          <Background
            size={size}
            transition={transition}
            properties={{
              stroke: ignoreStroke ? undefined : stroke,
              radius,
              // 默认边框不受border属性影响
              border: stroke?.disabled ? undefined : border,
            }}
            zIndex={(comp as UIContainerComponent).components.length}
            coverStyle={comp.type === CHorizontalMenu ? { borderStyle: 'none' } : undefined}
          />
        )}
      </div>
    );
  }
}
