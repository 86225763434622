import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// 星
export default {
  ...flowBase,
  name: i18n('resource.flow.star'),
  type: 'star',
  thumb: {
    spriteIconClass: SpriteThumb.Star.className,
    dragPosition: SpriteThumb.Star.position,
  },
  template: getShapeData(
    i18n('resource.flow.star'),
    {
      width: 60,
      lockedRatio: false,
      height: 60,
    },
    {
      data: [
        {
          point: {
            x: 17,
            y: 37,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 23,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 22,
            y: 23,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 30,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 38,
            y: 23,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 60,
            y: 23,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 43,
            y: 37,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 49,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 30,
            y: 46,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 11,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
      closed: true,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
