import * as React from 'react';
import i18n from '@/i18n';

import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { once } from 'lodash';

import { IAppWithNestedChildren } from '@fbs/rp/models/app';
import { RpPreviewOption } from '@/fbs/idoc/models/preview';
import { findNodeByID, getFirstPageNodeOfTree } from '@/fbs/rp/utils/app';
import { IFragmentAction, IPageAction, IScrollCommandParams, OpenWindowModel } from '@fbs/rp/models/interactions';
import INode, { INodeWithChildren } from '@fbs/rp/models/node';
import { IUserInfo } from '@/fbs/idoc/models/user';
import { IAppMembersInfo } from '@/fbs/idoc/models/app';
import { EventTypes } from '@/fbs/rp/models/event';

import { Dialog, Button } from '@dsm';

import { IMainState } from '../../store/webTypes';
import { isMobileDevice, isMobileApp, couldPagePreview } from '@helpers/previewHelper';
import { getParentDomByFilter } from '@/helpers/documentHelper';
import previewDispatcher, { IPreviewDispatcher } from '@dispatchers/previewDispatcher';
import { depthClone, formatString, useLabelAOpenUrl } from '@utils/globalUtils';
import { saveToCache } from '@utils/cacheUtils';
import { isMockRPD } from '@utils/envUtils';
import { parseUrlSearch } from '@/utils/urlUtils';

import { languageManager } from '@i18n';
import apis from '@/apis';

import Theater from '../../theater';
import designManager from '../../managers/DesignManager';
import SimpleDesignManager from '../../managers/DesignManager/factory/SimpleDesignManager';
import getResourceDispatcher, { IResourceDispatcher } from '@dispatchers/resourceDispatcher';
import { IResourceState } from '@/store/resource/reducers';
import { IAppState } from '@/store/app/reducers';
import { UIContainerComponent, UISymbolComponent } from '@editor/comps';
import * as Actions from '@/store/actions';
import { isStandalone } from '@helpers/previewHelper';
import { canUseSymbol } from '@helpers/resourceHelper';
import { getOfflineDemoData } from '@apis/offlineDemo/data';
import { preloadAssetsInPreview } from '@/helpers/preloadHelper';
import { desktopServer } from '@/services/desktop';
import carouselManager from '@/theater/carouselManager';
import { IGlobalState } from '@/store/global/reducers';
import { LOGIN_URL } from '@/consts/link';
import { ErrorCode, Role, ITeams } from '@/fbs/teamManagement';
import { offlinePath } from '@/consts/route';
import { IPopularize, PopularizePosition, getPopularize } from '@/fbs/teamManagement/utils/popularize';
import Routers from '@consts/router';

import WebIndex from './Web';
import MobileIndex from './Mobile';
import Loading from '../Common/Loading';

import './index.scss';

interface IPreviewDispatchProp {
  dispatcher: IPreviewDispatcher;
  resourceDispatcher: IResourceDispatcher;
  history?: any;
  match?: any;
  checkShareLinkReset: (shareID: string, linkID: string) => void;
  setCurrLinkID: () => void;
  setQueryLinkID: () => void;
  loadAppMembers: (appID: string) => void;
  // getSnapshotAllPages: (appID: string) => void;
  // getUserInfo: () => void;
}

interface IPreviewStateProp {
  global: IGlobalState;
  appStoreState: IAppState;
  appID: string;
  realAppID: string;
  pageID: string;
  linkID?: string;
  app: IAppWithNestedChildren | null;
  currentTheater?: Theater;
  nextTheater?: Theater;
  resource: IResourceState;
  userInfo?: IUserInfo;
  roleInTeam?: Role;
  showMsg: boolean;
  message: string;
  msgId: number;
  msg: string;
  isStandalone: boolean;
  hasShareLinkReset: boolean;
  isProjectExist: boolean;
  popularizes: IPopularize[];
  waterMark?: string;
  teamInfo?: ITeams;
  membersFromApp?: IAppMembersInfo[];
}

export interface IPreviewProp extends IPreviewDispatchProp, IPreviewStateProp {
  actionEffect?: IPageAction;
}

export interface IPreviewState {
  reRender: boolean;
  pageID: string;
  checkLinkTimer?: number;
  isHistoryUpdate?: boolean;
}

const isOfflineDemo = !!RP_CONFIGS.isOfflineDemo;

class Preview extends React.Component<IPreviewProp, IPreviewState> {
  notifyCliFun: any;
  constructor(props: IPreviewProp) {
    super(props);
    designManager.register(new SimpleDesignManager());
    this.notifyCliFun = once(this.notifyCliWebLoading);
    const { moduleID } = parseUrlSearch();
    this.moduleID = moduleID;
    this.state = {
      reRender: false,
      pageID: props.pageID,
      checkLinkTimer: 0,
    };
  }

  private resourceLoading: boolean = true;
  private warterMarkChecking = true;
  private mobileIndex: React.RefObject<MobileIndex> = React.createRef();
  private webIndex: React.RefObject<WebIndex> = React.createRef();
  private moduleID: string;

  private appSymbolTheater: string[] = [];

  /**
   * 获取整体缩放
   */
  get globalScale() {
    if (this.mobileIndex.current) {
      return this.mobileIndex.current.globalScale;
    } else if (this.webIndex.current) {
      return this.webIndex.current.globalScale;
    } else {
      return 1;
    }
  }

  notifyCliWebLoading = () => {
    if (isMockRPD) {
      desktopServer.doLoaded();
    }
  };

  componentDidMount() {
    // this.postMsgAfterPageDidMount();
    const { pageID, app, dispatcher, setCurrLinkID, setQueryLinkID /* , getUserInfo */ } = this.props;
    setCurrLinkID();
    setQueryLinkID();
    // getUserInfo && getUserInfo();
    if (!app) {
      dispatcher.loadApp(pageID);
    } else {
      document.title = i18n('application.title', app.name);
    }

    // 如果是展示历史版本的演示模式，单独获取 libs
    if (this.moduleID) {
      this.getSnapshotLibs();
    }

    preloadAssetsInPreview();
    if (!isOfflineDemo) {
      dispatcher.loadPopularizes();
      // 离线演示包不监听popstate实现浏览器前进后退
      window.addEventListener('popstate', this.handleHistoryPopState);
    }
  }

  private async getSnapshotLibs() {
    const libs = await apis.ds.lib.getSnapshotLibsByModuleID(this.moduleID);
    designManager.getInstance().setLibs(libs);
  }

  private handleFaceDidMount = () => {
    this.postMsgAfterPageDidMount();
  };

  /**
   * 监听浏览器历史前进后退，切换页面
   */
  private handleHistoryPopState = () => {
    const { pageID, dispatcher } = this.props;
    if (pageID) {
      this.setState({ pageID, isHistoryUpdate: true }, () => {
        dispatcher.loadPage({ _id: pageID } as INode);
      });
    }
  };

  // UNSAFE_componentWillUpdate() {
  //   const { currentTheater, nextTheater } = this.props;
  //   this.loadTheaterListener(currentTheater);
  //   this.loadTheaterListener(nextTheater);
  // }

  componentDidUpdate(preProps: IPreviewProp) {
    const {
      // resourceDispatcher,
      app,
      userInfo,
      dispatcher,
      currentTheater,
      nextTheater,
      global,
      isProjectExist,
      hasShareLinkReset,
      loadAppMembers,
    } = this.props;
    if (app) {
      window.appData = {
        appType: app.appType,
        size: app.size,
      };
    }
    if (this.resourceLoading && app) {
      // resourceDispatcher.loadLib(app._id);

      !isOfflineDemo && userInfo && !this.moduleID && loadAppMembers(app._id);
      this.resourceLoading = false;
      // preProps.getSnapshotAllPages && preProps.getSnapshotAllPages(app._id);
    }
    if (RP_CONFIGS.isPrivateDeployment && this.warterMarkChecking && userInfo && !isMobileDevice()) {
      this.warterMarkChecking = false;
      dispatcher.checkWaterMark();
    }
    if (nextTheater) {
      nextTheater.onAfterSkipPage = this.doAfterSkip;
      this.postMsgAfterPageChange(nextTheater.doc.pageID);
    }
    if (preProps.currentTheater !== currentTheater) {
      preProps.currentTheater?.removeUpdateListener();
      if (currentTheater) {
        currentTheater.addUpdateListener(() => {
          this.forceUpdate();
        });
      }
    }
    this.loadTheaterListener(currentTheater);

    if (currentTheater?.doLoaded && currentTheater?.fragmentOverlayInitialized) {
      currentTheater.afterLoaded();
      currentTheater.start(currentTheater.doc.mainArtboard, EventTypes.loaded);
    }

    if (global.networkErrorCode === ErrorCode.TeamDestroy) {
      location.href = Routers.getNoPermissionPageUrl('', ErrorCode.TeamDestroy);
    }

    // 项目不存在
    if (isProjectExist !== preProps.isProjectExist && !isProjectExist) {
      location.href = Routers.getNoPermissionPageUrl('', ErrorCode.AppDestroy);
    }

    // 项目链接失效
    if (hasShareLinkReset !== preProps.hasShareLinkReset && hasShareLinkReset) {
      location.href = Routers.getNoPermissionPageUrl('', ErrorCode.PublishShare);
    }
  }

  private loadTheaterListener = (theater?: Theater) => {
    if (!theater) {
      return;
    }
    theater.onSkipToPage = this.doSkipToPage;
    theater.onScrollToComp = this.doScrollToComp;
    theater.onBeginFragmentAction = this.doBeginFragmentAction;
    theater.onEndFragmentAction = this.doEndFragmentAction;
    theater.onBackward = this.doBackward;
    theater.onForward = this.doForward;
    theater.onHome = this.doHome;
    theater.updateWorkerManagerExtensionFeature({
      onPageSkip: this.doSkipToPage.bind(this),
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps: IPreviewProp) {
    const { linkID, match, checkShareLinkReset, appStoreState } = nextProps;

    // 项目不存在或没有权限时的跳转
    if (appStoreState.noPermissionState) {
      location.href = Routers.getNoPermissionPageUrl(
        appStoreState.teamInfo?.id || appStoreState.userInfo?.defaultIDocTeamID,
        appStoreState.noPermissionState,
      );
      return;
    }

    if (linkID && !this.state.checkLinkTimer) {
      this.setState({ checkLinkTimer: 1 });
      checkShareLinkReset(match.params.id, linkID);
    }

    // 离线演示包的前进后退功能
    if (isOfflineDemo && nextProps.pageID !== this.props.pageID) {
      nextProps.dispatcher.loadPage({ _id: nextProps.pageID } as INode);
    }

    if (!isOfflineDemo && nextProps.currentTheater) {
      const pageID = nextProps.currentTheater.doc.pageID;
      if (pageID !== this.state.pageID) {
        if (this.state.isHistoryUpdate) {
          this.setState({ isHistoryUpdate: false });
          return;
        }
        this.setState({ pageID });
        this.updateNodeIDInUrl(pageID);
      }
    }
    if (nextProps.app?.name) {
      const name = i18n('application.title', nextProps.app.name);
      if (document.title !== name) {
        document.title = name;
      }
    }

    // 加载 libs, symbol，注意切换页面的情景
    const { resource, currentTheater } = nextProps;

    if (this.props.currentTheater !== currentTheater && currentTheater?.usedLibsID && !this.moduleID) {
      this.props.resourceDispatcher.loadUsedLib(currentTheater.usedLibsID);
    }

    if (this.props.resource.libs !== resource.libs && !this.moduleID) {
      designManager.getInstance().setLibs(resource.libs);

      if (canUseSymbol() && resource.libs.length && currentTheater) {
        if (currentTheater) {
          this.doApplySymbol(currentTheater);
          this.forceUpdate();
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatcher.exit();
    if (!isOfflineDemo) {
      window.removeEventListener('popstate', this.handleHistoryPopState);
    }
  }

  postMessage = (event: string, payload: any) => {
    if (window.parent) {
      window.parent.postMessage(
        {
          event,
          payload,
        },
        '*',
      );
    }
  };

  /**
   * idoc单页模式postMessage： 页面载入完毕
   */
  postMsgAfterPageDidMount = () => {
    if (this.props.isStandalone) {
      this.postMessage(RpPreviewOption.PAGE_DIDMOUNT, undefined);
    }
  };

  /**
   * idoc单页模式postMessage: 页面切换
   */
  postMsgAfterPageChange = (pageID: string) => {
    if (this.props.isStandalone) {
      this.postMessage(RpPreviewOption.PAGE_CHANGED, {
        pageID,
      });
    }
  };

  doApplySymbol(theater: Theater) {
    const { doc } = theater;
    let hasSymbol = false;
    const refresh = (container: UIContainerComponent) => {
      container.components.forEach((comp) => {
        if (comp instanceof UISymbolComponent) {
          comp.loadSymbolDataByPreview();
          comp.refreshComponents();
          hasSymbol = true;
          // this.props.currentTheater?.refreshSymbolTreeNode(comp);
        }
        if (comp instanceof UIContainerComponent) {
          refresh(comp);
        }
      });
    };

    refresh(doc.mainArtboard);
    doc.fragments.forEach(refresh);
    if (hasSymbol) {
      theater.updateTree();
    }
  }

  /**
   * 页面后退
   */
  doBackward = () => {
    this.props.dispatcher.goBackward(undefined);
  };

  /**
   * 页面前进
   */
  doForward = () => {
    this.props.dispatcher.goForward();
  };

  /**
   * 返回home
   * @param homeID
   * @param artboardID
   */
  doHome = (homeID: string, artboardID: string) => {
    const { userInfo, app, roleInTeam } = this.props;
    const node = findNodeByID(app?.children || [], homeID);
    if (!couldPagePreview(node, roleInTeam, app, userInfo?.id)) {
      return;
    }
    this.props.dispatcher.goHome(homeID, artboardID);
  };

  /**
   * 开始画板交互
   * @param action
   */
  doBeginFragmentAction = (action: IFragmentAction) => {
    this.props.dispatcher.showFragment(action);
    this.setState({ reRender: !this.state.reRender });
  };

  /**
   * 结束画板交互
   * @param action
   */
  doEndFragmentAction = (action: IFragmentAction) => {
    const newAction = depthClone(action);
    newAction.isExit = true;
    this.props.dispatcher.showFragment(newAction);
    this.setState({ reRender: !this.state.reRender });
  };

  /**
   * 执行组件交互滚动
   */
  doScrollToComp = (target: string, params: IScrollCommandParams, duration: number, isUsedByRemark = false) => {
    const comps: NodeListOf<HTMLElement> = document.querySelectorAll(`[id="${target}"]`);
    const { horizontal, vertical } = params;
    const promiseList = Array.from(comps).map((comp) => {
      return new Promise(() => {
        // 此处做了判断，用于点击备注tag的定位
        const scrollDom = !isUsedByRemark
          ? getParentDomByFilter(comp, (e: HTMLElement) => !!e.parentElement?.classList.contains('dsm-c-rp-scrollbars'))
          : document.querySelector('.scroll-box')?.firstElementChild;

        if (scrollDom) {
          // const isNoScaleScroll = scrollDom.parentElement?.classList.contains('no-scale-scroll');
          // const scale = isNoScaleScroll ? 1 : this.globalScale;
          const compBounds = comp.getBoundingClientRect();
          const scrollBounds = scrollDom.getBoundingClientRect();
          const xy = { x: scrollBounds.width / 2, y: scrollBounds.height / 2 };
          const orignxy = { x: xy.x - compBounds.width / 2, y: xy.y - compBounds.height / 2 };
          let movexy = { x: 0, y: 0 };
          movexy.x = orignxy.x - compBounds.left;
          movexy.y = orignxy.y - compBounds.top;
          const unitTime = 20;
          const maxTime = duration / unitTime || 1;
          let stepX = horizontal ? movexy.x / maxTime : 0;
          let stepY = vertical ? movexy.y / maxTime : 0;
          let time = 0;
          const interval = setInterval(() => {
            time += 20;
            scrollDom.scroll({
              left: scrollDom.scrollLeft - stepX,
              top: scrollDom.scrollTop - stepY,
            });
            if (time + 5 >= duration) {
              clearInterval(interval);
            }
          }, unitTime);

          // 获取缩放后的scroll相关参数
          // const scrollHeight = scrollDom.scr     ollHeight * scale;
          // const scrollWidth = scrollDom.scrollWidth * scale;
          // const scrollLeft = scrollDom.scrollLeft * scale;
          // const scrollTop = scrollDom.scrollTop * scale;
          // const scrollOpt = { scrollHeight, scrollWidth, scrollLeft, scrollTop };
          // const offset = getOffsetScrollToTarget(scrollBounds, compBounds, scrollOpt);
          // const unitTime = 20;
          // const maxTime = duration / unitTime || 1;
          // let stepX = horizontal ? movexy.x / maxTime : 0;
          // let stepY = vertical ? movexy.y / maxTime : 0;
          // let curLeft = scrollLeft;
          // let curTop = scrollTop;
          // let stageLeft = scrollWidth > scrollLeft ? (scrollDom.clientWidth - compBounds.width) / 2 : 0;
          // let stageTop = (scrollDom.clientHeight - compBounds.height) / 2;
          // const maxLeft = Math.ceil(scrollLeft + offset.x);
          // const maxTop = Math.ceil(scrollTop +           offset.y);
          // let i = 0;
          // const interval = setInterval(() => {
          //   curLeft = curLeft + stepX;
          //   curTop = curTop + stepY;

          //   if (stepX >= 0) {
          //     curLeft = min(curLeft, maxLeft) / scale;
          //   } else {
          //     curLeft = max(curLeft, maxLeft) / scale;
          //   }
          //   if (stepY >= 0) {
          //     curTop = min(curTop, maxTop) / scale;
          //   } else {
          //     curTop = max(curTop, maxTop) / scale;
          //   }
          //   scrollDom.scroll({
          //     left: curLeft - stageLeft,
          //     top: curTop - stageTop,
          //   });

          //   const hOver = stepX >= 0 ? curLeft >= maxLeft : curLeft <= maxLeft;
          //   const vOver = stepY >= 0 ? curTop >= maxTop : curTop <= maxTop;
          //   if ((vOver || !vertical) && (hOver || !horizontal)) {
          //     stageLeft = 0;
          //     stageTop = 0;
          //     clearInterval(interval);
          //   }
          //   i++;
          //   // 保险；避免奇怪的误差
          //   if (i > maxTime + 10) {
          //     stageLeft = 0;
          //     stageTop = 0;
          //     clearInterval(interval);
          //   }
          // }, unitTime);
        }
      });
    });
    Promise.all(promiseList);
  };

  /**
   * 页面跳转
   * @param action
   * @param currentPageId
   */
  doSkipToPage = (action: IPageAction, currentPageId?: string) => {
    const { roleInTeam, app, dispatcher, userInfo } = this.props;
    if (!app) {
      return;
    }
    const pages: INode[] = [];
    const doFlat = (nodes: INode[]) => {
      pages.push(...nodes);
      nodes.forEach((node) => {
        if (node.type === 'folder' || node.type === 'page') {
          doFlat((node as INodeWithChildren).children);
        }
      });
    };
    doFlat(app.children);
    let target = action.target;
    if (target === '@home') {
      const home = getFirstPageNodeOfTree(app.children, (node) =>
        couldPagePreview(node, roleInTeam, app, userInfo?.id),
      );
      if (!couldPagePreview(home, roleInTeam, app, userInfo?.id)) {
        return;
      }
      target = home!._id;
    }
    if (target === '@back') {
      dispatcher.goBackward(action);
      return;
    }
    const nextPage = getFirstPageNodeOfTree(
      app.children,
      (node) => node._id === target && couldPagePreview(node, roleInTeam, app, userInfo?.id),
    );
    const currentPage = pages.find((p) => p._id === currentPageId);
    if (nextPage && currentPage) {
      // 新窗口打开
      if (action.params.openWindowModel === OpenWindowModel.newWindow) {
        const regExp = /\/[-\w#_]+\?/;
        const nextUrl = window.location.href
          .replace(`${offlinePath}?`, `${offlinePath}/${nextPage._id}?`)
          .replace(regExp, `/${nextPage._id}?`);
        this.doFormSkip(nextUrl);
        // window.open(nextUrl, '_blank');
        return;
      }
      if (isOfflineDemo) {
        this.props.history.push(`${offlinePath}/${nextPage._id}`);
      }
      // 载入目标页
      dispatcher.beforeSkipToPage(nextPage, currentPage, action);
    }
  };

  doFormSkip = (url: string) => {
    useLabelAOpenUrl(url);
  };

  doAfterSkip = () => {
    this.props.dispatcher.afterSkip();
  };

  /**
   * 演示url变化，通知父页面
   */
  postMsgAfterPreviewChanged = () => {
    this.postMessage(RpPreviewOption.URL_CHANGED, window.location.href);
  };

  handleLoadPage = (node: INode) => {
    // 重置轮播图的timer
    carouselManager.clearCustomTimeout();
    carouselManager.resetAllPassiveValue();
    if (isOfflineDemo) {
      this.handleOfflineLoadPage(node);
    } else {
      this.handleOnlineLoadPage(node);
    }
  };

  // 离线演示包修改url，在componentWillReceiveProps根据pageID加载对应的页面
  handleOfflineLoadPage = (node: INode) => {
    if (node._id !== this.props.pageID) {
      this.props.history.push(`${offlinePath}/${node._id}`);
    }
  };

  handleOnlineLoadPage = (node: INode) => {
    const { _id } = node;
    this.props.dispatcher.loadPage({ _id } as INode);
    this.postMsgAfterPreviewChanged();
  };

  /**
   * 更新url的nodeid
   */
  updateNodeIDInUrl = (nodeID: string) => {
    if (isOfflineDemo) {
      return;
    }
    const { linkID, history, match, isStandalone } = this.props;
    const resolvedLinkID = linkID ? linkID + '/' : '';
    const search = location.search;
    let urlPrefix: string;
    if (isStandalone) {
      urlPrefix = `${Routers.routePrefix}/standalone/rp`;
    } else {
      urlPrefix = `${Routers.routePrefix}/run`;
    }
    history.push(`${urlPrefix}/${match.params.id}/${resolvedLinkID}${nodeID}${search}`);
  };

  private handleSearchParams = (value: string): void => {
    let newUrl = this.props.match.url + '?' + value;
    if (isOfflineDemo) {
      newUrl = `#${newUrl}`;
    }
    window.history.replaceState({}, '', newUrl);
  };

  handleMsgPop = (msg: string, time?: number) => {
    const fn = this.props.dispatcher.showMessage;
    fn && fn(msg, time);
  };

  handleFullScreenMsgPop = (msg: string, time: number, id: number) => {
    const fn = this.props.dispatcher.showMessageWithId;
    fn && fn(msg, time, id);
  };

  handleLogout = () => {
    if (isMockRPD) {
      desktopServer.logout();
    } else {
      window.location.href = formatString(LOGIN_URL, encodeURIComponent(window.location.href));
    }
  };

  renderRemoteLoginDialog() {
    if (!this.props.global.forceLogout) {
      return null;
    }
    return (
      <Dialog
        onClose={this.handleLogout}
        onSubmit={this.handleLogout}
        backFade
        contentClassName="rp-remote-login-dialog"
      >
        <p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: i18n('tips.remoteLoginTips') }} />
        <div className="rp-remote-login-button">
          <Button
            width={languageManager.isEnLanguage ? 120 : 90}
            theme="onlyText"
            activated
            className="idoc"
            onClick={this.handleLogout}
          >
            {i18n('tips.reLogin')}
          </Button>
        </div>
      </Dialog>
    );
  }

  render() {
    if (!this.props.isProjectExist || this.props.hasShareLinkReset) {
      return null;
    }

    if (!(isMobileDevice() || isMobileApp) && this.props.global.forceLogout) {
      return this.renderRemoteLoginDialog();
    }

    if (!this.props.app || (typeof this.props.resource.isFail !== 'boolean' && !this.moduleID)) {
      this.notifyCliFun();
      return <Loading size="mini" theme="light" />;
    }

    const {
      app,
      appID,
      realAppID,
      currentTheater,
      nextTheater,
      pageID,
      message,
      showMsg,
      msgId,
      msg,
      userInfo,
      teamInfo,
      global,
      roleInTeam,
      membersFromApp,
      popularizes,
    } = this.props;
    if (isMobileDevice() || isMobileApp) {
      return (
        <MobileIndex
          ref={this.mobileIndex}
          app={app}
          userInfo={userInfo}
          appID={appID}
          currentPage={currentTheater}
          nextPage={nextTheater}
          role={roleInTeam}
          onPageSelect={this.handleLoadPage}
        />
      );
    } else {
      const popularize = getPopularize(popularizes, PopularizePosition.DemoLeft, teamInfo, userInfo);
      return (
        <WebIndex
          ref={this.webIndex}
          previewDispatcher={this.props.dispatcher}
          global={global}
          app={app}
          appID={appID}
          realAppID={realAppID}
          pageID={pageID}
          currentPage={currentTheater}
          nextPage={nextTheater}
          message={message}
          showMsg={showMsg}
          msgId={msgId}
          msg={msg}
          userInfo={userInfo}
          role={roleInTeam}
          isOfflineDemo={isOfflineDemo}
          isStandalone={this.props.isStandalone}
          waterMark={this.props.waterMark}
          teamInfo={this.props.teamInfo}
          popularize={popularize}
          membersFromApp={membersFromApp}
          onPageSelect={this.handleLoadPage}
          onMsgPopup={this.handleMsgPop}
          onFullScreenMsgPopup={this.handleFullScreenMsgPop}
          onScrollToComp={this.doScrollToComp}
          onDidMount={this.handleFaceDidMount}
          onSearchParams={this.handleSearchParams}
        />
      );
    }
  }
}

const mapStateToProps = (state: IMainState, ownProps: any): IPreviewStateProp => {
  const appID = isOfflineDemo ? getOfflineDemoData()?.app.shareID : ownProps.match.params.id;
  const pageID = ownProps.match.params.pageID;
  const linkID = ownProps.match.params.linkID;

  return {
    global: state.global,
    app: state.preview.app,
    appStoreState: state.app,

    waterMark: state.preview.waterMark,
    currentTheater: state.preview.currentTheater,
    nextTheater: state.preview.nextTheater,
    appID,
    realAppID: state.preview.appID,
    pageID,
    linkID,
    resource: state.resource,
    showMsg: state.preview.showMsg,
    message: state.preview.message,
    msgId: state.preview.msgId,
    msg: state.preview.msg,
    userInfo: state.share.userInfo,
    hasShareLinkReset: state.share.hasShareLinkReset,
    roleInTeam: state.share.teamInfo?.roleInTeam as Role | undefined,
    isStandalone: isStandalone,
    isProjectExist: state.preview.isProjectExist,
    popularizes: state.preview.popularizes,
    teamInfo: state.share.teamInfo,
    membersFromApp: state.member.membersFromApp,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, any>, ownProps: any): IPreviewDispatchProp => {
  const appID = isOfflineDemo ? getOfflineDemoData()?.app.shareID : ownProps.match.params.id;
  const linkID = ownProps.match.params.linkID;

  saveToCache('currentAppID', appID);

  const search = ownProps.location.search as string;
  const queryMap = search
    .replace('?', '')
    .split('&')
    .reduce((acc, item: string) => {
      const [key, value] = item.split('=');
      acc[key] = value;
      return acc;
    }, {} as { [key: string]: string });

  const prototypeID = queryMap['moduleID'];
  const iDocAppID = queryMap['appID'];
  return {
    dispatcher: previewDispatcher(appID, prototypeID, iDocAppID, linkID, dispatch),
    resourceDispatcher: getResourceDispatcher(appID, dispatch),
    // getUserInfo: () => {
    //   dispatch(Actions.ShareThunkActions.getUserInfo());
    // },
    checkShareLinkReset: (shareID: string, linkID: string) => {
      dispatch(Actions.ShareThunkActions.checkShareLinkReset(shareID, linkID));
    },
    setCurrLinkID: () => {
      dispatch(Actions.PreviewThunkActions.setCurrLinkID(linkID));
    },
    setQueryLinkID: () => {
      dispatch(Actions.Preview.setQueryLinkID(linkID));
    },
    loadAppMembers: (appID: string) => {
      dispatch(Actions.MemberActions.loadAppMembers(appID));
    },
    // getSnapshotAllPages: (appID: string) => {
    //   Actions.AppThunkActions.getSnapshotAllPages(appID);
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
