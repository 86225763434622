import { PureColor } from './properties/color';
import { FillData, FillType } from './properties/fill';

export enum ViewMode {
  fill = 'fill',
  stroke = 'stroke',
}

/**
 * 方形网格参数
 */
export interface IGridSettings {
  visible: boolean;
  blockSize: number; //网格间距
  thickEvery: number; //每间隔多少个网格为一个重点渲染的网格线
}

/**
 * 布局栅格参数
 */
export interface ILayoutSettings {
  visible: boolean;
  totalWidth: number; // 总宽度
  offset: number; //偏移
  columns: {
    visible: boolean; // 是否显示列
    gutterOnOutside: boolean; //是否展示外侧空白(这个空白等于于最后一个水槽的一半， 相当于整体居中)
    gutterWidth: number; //水平的槽
    columnsCount: number; //列数
  };
  rows: {
    visible: boolean; // 是否显示行
    gutterHeight: number; // 行间距
    rowHeight: number; //行高度（几倍列宽）
    drawAllLines: boolean;
  };
  viewMode: ViewMode; //显示方式：填充或描边
}

export interface ILayoutAndGrid {
  grid: IGridSettings;
  layout: ILayoutSettings;
}

export interface ILayoutAndGridColors {
  thickColor: PureColor; // 颜色设置与网格的同步
  lightColor: PureColor;
}

export interface IAlignConfig {
  normalAlign: boolean; // 常规对齐
  testEquidistant: boolean; // 等距侦测
  markDistance: boolean; // 距离标记
}

// 常规选项
export interface IGeneralSettings {
  pasteOffset: {
    x: number;
    y: number;
  };
  pasteAtTop: boolean;
  showHiddenArea: boolean;
  enableCmdShiftGToUnGrouping: boolean;
  backgroundColor: PureColor;
}

export interface IPreferenceSettings {
  generalSettings: IGeneralSettings;
  layoutAndGridColor: ILayoutAndGridColors;
  gridSettings: IGridSettings;
  layoutSettings: ILayoutSettings;
  alignConfig: IAlignConfig;
}

export interface IMakeDefaultSettings {
  makeDefaultGridSettings: boolean;
  makeDefaultLayoutSettings: boolean;
}

export type SemiFinishedUserPreference = Partial<
  Omit<IUserPreference, 'artboardsLayoutAndGrid' | 'appLayoutAndGrid'> & {
    artboardGrid: IGridSettings;
    artboardLayout: ILayoutSettings;
    appGrid: IGridSettings;
    appLayout: ILayoutSettings;
  }
>;

interface IFill {
  type: FillType;
  color: FillData;
}

export interface IPresetColor {
  id: string;
  name: string;
  value: IFill;
  // 旧数据中只使用了纯色 10-27~10-28期间数据
  color?: PureColor;
}

// 用户项目内偏好
// TODO: 考虑项目被删除后的清理偏好问题
// TODO: 考虑项目克隆后克隆个人偏好的问题
export interface IUserPreference {
  _id: string;
  userID: number;
  appID: string;
  layoutAndGridColor: ILayoutAndGridColors;
  appLayoutAndGrid: ILayoutAndGrid;
  // TODO: 考虑画板被删除之后的清理数据问题，而且不能在画板删除后立即就清理，undo 回来的时候会丢失
  artboardsLayoutAndGrid: {
    [artboardID in string]: ILayoutAndGrid;
  };
  alignConfig: IAlignConfig;
  generalSettings: IGeneralSettings;
  colors: IPresetColor[];
}
