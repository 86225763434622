import * as React from 'react';
import { SessionInfo } from '@fbs/rp/models/io';
import CoreEditor from '@editor/core';
import UIManager from '../components/uiManager';
import { IActionFinderManager } from '@/managers/actionFinderManager';
import { IFragmentActionManager } from '@/managers/fragmentActionManager';
import { IUserPreference } from '@fbs/rp/models/grid';
import { IUserInfo } from '@fbs/idoc/models/user';

// 编辑器环境内需要的 context
export interface IEditorProviderState {
  appID: string;
  // flow 表示流程图模式
  mode: 'default' | 'flow';
  coopers: SessionInfo[];
  originUserPreference?: IUserPreference;
  userPreference?: IUserPreference;
  coreEditor?: CoreEditor;
  uiManager: UIManager;
  actionFinderManager: IActionFinderManager;
  fragmentActionManager: IFragmentActionManager;
  userInfo?: IUserInfo;
  isTrashPage?: boolean; // 标识当前页面是否是回收站中的页面
  isExample?: boolean; // 标识当前是否是例子项目
  changeMode: (newMode: string) => void;
  changeUsePreference?: (userPreference: Partial<IUserPreference>, isSubmit?: boolean) => void;
  ruleConfig: {
    hiddenRule?: boolean;
    hiddenAllGuides?: boolean;
    hideActiveFlag?: boolean;
    hideRemarkNum?: boolean;
  };
  // 英文版个人空间移动项目
  moveProject: () => void;
}

export default React.createContext({} as IEditorProviderState);
