import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { getNewID } from '@/helpers/idHelper';

import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';
import { CFlowEllipse } from '../constants';
import { makeEllipse } from '../basic/Ellipse/config';

export const makeFlowEllipse = () => ({
  ...makeEllipse(getNewID()),
  name: i18n('resource.flow.circle'),
  size: {
    width: 100,
    lockedRatio: false,
    height: 60,
  },
});

// 椭圆
export default {
  ...flowBase,
  name: i18n('resource.flow.ellipse'),
  type: CFlowEllipse,
  thumb: {
    spriteIconClass: SpriteThumb.Ellipse.className,
    dragPosition: SpriteThumb.Ellipse.position,
  },
  template: getShapeData(
    i18n('resource.flow.ellipse'),
    {
      width: 100,
      lockedRatio: false,
      height: 60,
    },
    {
      data: [
        {
          point: {
            x: 50,
            y: 0,
          },
          handleIn: {
            x: -28,
            y: 0,
          },
          handleOut: {
            x: 28,
            y: 0,
          },
        },
        {
          point: {
            x: 100,
            y: 30,
          },
          handleIn: {
            x: 0,
            y: -16,
          },
          handleOut: {
            x: 0,
            y: 16,
          },
        },
        {
          point: {
            x: 50,
            y: 60,
          },
          handleIn: {
            x: 28,
            y: 0,
          },
          handleOut: {
            x: -28,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 30,
          },
          handleIn: {
            x: 0,
            y: 16,
          },
          handleOut: {
            x: 0,
            y: -16,
          },
        },
      ],
      closed: true,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
