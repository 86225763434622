import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

interface IProps {
  index: number;
  changed: boolean;
  className?: string;
  onChange?: (index: number) => void;
}

interface IState {}

class CarouselDot extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  private handleClick = () => {
    const { index, onChange } = this.props;
    onChange && onChange(index);
  };

  render() {
    const { className, changed } = this.props;
    return <div className={classnames(className, 'dsm-c-rp-carousel-dot', { changed })} onClick={this.handleClick} />;
  }
}

export default CarouselDot;
