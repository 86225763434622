import IRadius from '@fbs/rp/models/properties/radius';

export const DefaultRadius: IRadius = {
  topLeft: 0,
  topRight: 0,
  bottomLeft: 0,
  bottomRight: 0,
  isPercent: false,
  disabled: false,
};
