export default {
  TagButtonGroup: {
    all: '全部',
    settings: '設定',
  },
  Dialog: {
    ok: '確定',
    cancel: '取消',
  },
  RadioButton: {
    text: '單選按鈕',
  },
  Select: {
    placeholder: '請選擇或輸入',
  },
  TagsManager: {
    maxLength: '20',
    error: '分組名稱長度限制20個字元。',
    dialog: '刪除專案分組后不可恢復，確定仍要刪除嗎？',
    placeholder: '請輸入{0}名稱',
    title: '分組管理',
    actionName: '新建分組',
    rename: '重命名',
    delete: '刪除',
  },
  tree: {
    emptySearch: '未搜尋到結果',
  },
  colorPanel: {
    recentUse: '最近使用',
    customizeColor: '自訂顏色',
  },
  shortCutKey: {
    shortCutKey: '快捷鍵',
    projectTree: '專案樹',
    canvas: '畫布',
    storyboard: '畫板',
    page: '頁面',
    prototype: '交互',
    design: '設計',
    drag: '拖動',
    leftMouseButton: '左鍵 + 空格',
    zoom: '縮放',
    mouseWheel: '滾輪',
    zoomOut: '放大工作區',
    zoomIn: '縮小工作區',
    zoomToOne: '100%顯示',
    zoomToTwo: '200%顯示',
    zoomToThree: '300%顯示',
    zoomToFour: '50%顯示',
    zoomToArtboard: '適應畫板',
    delete: '刪除',
    leftKey: '左鍵',
    overview: '全覽',
    map: '導航',
    preview: '全屏範式',
    lineDescription: '邏輯線描述',
    multiSelect: '多選',
    rename: '重命名',
    copy: '復制',
    paste: '貼上',
    cut: '剪下',
    magnifier: '放大鏡',
    doubleClick: '雙點',
    percentageSpecs: '百分比標記',
    copyLink: '復制鏈結',
    select: '選擇',
    pin: '圖釘',
    rectangle: '矩形',
    circle: '圓形',
    straightLine: '直線',
    arrow: '箭頭',
    text: '文字標記',
    imgLayers: '圖層列表',
    createrHotArea: '快速創建熱區',
    undoBtn: '撤銷',
    redoBtn: '恢復',
    prePage: '上一頁',
    nextPage: '下一頁',
    left: '左',
    right: '右',
    selectAll: '全選',
    commentMode: '評論',
    reviewMode: '定稿',
    developMode: '開發',
    prdDocTree: '文檔樹',
    focus: '聚焦',
    generateOperation: '通用作業',
    dragWorkspace: '拖動工作區',
    mouseWheelUp: '滾輪向上',
    mouseWheelDown: '滾輪向下',
    basicOperation: '基礎',
    lock: '鎖定/取消鎖定',
    demonstrate: '演示',
    componentClone: '元件克隆',
    dragComponent: '拖動元件',
    verticalClone: '水平或垂直克隆元件',
    mergeOrdissolve: '編組/取消編組', // 待刪除
    hidden: '顯示/隱藏',
    containSelect: '包含框選',
    mouseRegion: '滑鼠框選',
    selectDown: '選中下層',
    unSelect: '取消選擇',
    addElement: '元素',
    texts: '文字',
    button: '按鈕',
    image: '圖片',
    pen: '鋼筆',
    stickyNote: '便簽條',
    circleMarker: '編號',
    dropMarker: '水滴',
    attributeSetting: '屬性',
    componentHeight: '調整元件高度',
    componentWidth: '調整元件寬度',
    componentTransparent: '調整元件透明度',
    numberKey: '0~9',
    addFontsize: '字型大小增大',
    decreaseFontsize: '字型大小減小',
    bold: '粗體',
    italic: '斜體',
    line: '直線',
    underline: '下劃線',
    oval: '橢圓',
    layoutSetting: '布局',
    componentMove: '元件移動',
    componentMoveFast: '元件移動10px',
    verticalMove: '水平/垂直移動',
    click: '單點',
    addRegion: '追加框選',
    selectRegion: '框選',
    forwardTier: '元件層級上移',
    abackTier: '元件層級下移',
    toTop: '元件置頂',
    toBottom: '元件置底',
    boardInterval: '檢視間距',
    MouseOverComponent: '懸停',
    selectComponent: '選中元件',
    seeMouseOver: '懸停要檢視的元件',
    justifyLeft: '左對齊',
    justifyRight: '右對齊',
    justifyTop: '頂對齊',
    justifyBottom: '底對齊',
    justifyCenter: '水平居中對齊',
    justifyVertical: '垂直居中對齊',
    levelIsometry: '水平等距',
    verticalIsometry: '垂直等距',
    projectSetting: '專案設定',
    newPage: '新建頁面',
    actionName: '新建分組',
    pageUp: '頁面上移',
    pageDown: '頁面下移',
    recentPage: '切換到最近開啟頁面',
    InterfaceView: '其它',
    interfaceSetting: '專案',
    openTree: '開啟專案',
    openComponent: '開啟元件',
    openIcons: '開啟圖示',
    openResource: '開啟資源',
    openLayer: '開啟圖層',
    unfoldLeftBoard: '收起/展開左側面板',
    unfoldRightBoard: '收起/展開右側面板',
    levelRoll: '水平卷軸工作區',
    locateMainBoard: '定位到主畫板',
    searchFast: '快速搜尋',
    switchTabs: '切換分類',
    showHideGrid: '顯示/隱藏網格',
    showHideLayout: '顯示/隱藏布局',
    artboard: '畫板',
    notes: '備注',
    autoSize: '自動大小',
    showRuler: '顯示標尺',
    save: '儲存',
  },
  mobileDialog: {
    title: '請在電腦上體驗摹客完整功能',
    text: '如需進行移動端專案演示，請使用摹客App',
    ok: '好的',
    openApp: '開啟App',
    unInstall: '尚未安裝？',
    clickDownload: '點擊下載',
    openAppFail: '抱歉，當前無法自動開啟App',
    downloadApp: '點擊下載最新版摹客App',
    openManually: '如已安裝，請手動開啟',
    cancel: '取消',
    download: '下載',
    appUrl: 'https://app.mockplus.cn',
  },
  checkSelect: {
    hasSelected: '已選 {0} 人',
    hasSelectedGroup: '，{0} 個部門',
    clearAll: '清空',
    unclassified: '未分組',
    selectAll: '全選',
    hasNotFound: '沒有找到結果',
    maximumOfPeople: '已達人數上限。',
    last: '上次的選擇',
  },
  searchInput: {
    searchHistory: '搜尋歷史',
    clearHistory: '清除',
  },

  rpActivity: {
    title: '摹客RP免費計劃',
    inviteToGetFree: '邀請{0}名成員注冊并加入團隊一起工作，永久獲得無限設計包',
    deadline: '活動截止：{0}',
    whatUnlimitedDesign: '什么是無限設計包？',
    definitionOfUnlimitedDesign:
      '無限設計包中包含摹客設計所有功能，頁面數量無上限，設計功能無限制！獲取后，團隊所有成員均可享受。',
    invitationProgress: '邀請進度',
    invitationStatusExpired: '無限設計包已于{0}到期，再邀請{1}人即可永久獲得，點擊檢視邀請記錄。',
    invitationStatusWillExpire: '無限設計包將于{0}到期，再邀請{1}人即可永久獲得，點擊檢視邀請記錄。',
    invitationStatusHasGot: '已獲得永久無限設計包，點擊檢視邀請記錄。',
    viewInvitationHistory: '檢視邀請記錄',
    oneYear: '1年',
    oneMonth: '1個月',
    addThreeMonth: '+3個月',
    forever: '永久',
    inviteSeveral: '邀請第{0}名成員',
    hasGotIt: '已獲得',
    inviteNow: '立即邀請',
    getForeverPackage: '領取永久無限包',
    hasGotPackage: '團隊已領取',
    goBack: '回返',
    invitation: {
      linkInvitation: '鏈結邀請',
      copyLink: '復制鏈結',
      inviteLinkDesc: '將鏈結傳送給同事，邀請對方加入“{0}”',
      emailInvitation: '信箱邀請',
      emailPlaceholder: '請輸入企業信箱，多個信箱使用分號隔開',
      copySuccess: '復制成功',
      sendInvitation: '傳送邀請',
      sendInvitationSuccess: '已成功傳送',
      emailFormatError: '存在錯誤的信箱網址，請更正后再試',
      copyText:
        'Hi~這款永久免費的原型設計工具，我必須要分享給你！功能強大，上手簡單，不限頁面，不限功能。馬上注冊，開始使用吧：{0}',
      foreverRPFree: '終身無限設計包',
      teamGotForeverFree: ' 的團隊獲得了終身無限設計包',
    },
    guestLimitTip: '你在“{0}”中的身份是協同人，無法參與此活動。',
    guestSwitchTeam: '請切換團隊后再試。',
    record: {
      num: '序號',
      time: '時間',
      sender: '邀請人',
      receiver: '受邀人',
      reward: '獎勵',
      threeMonthReward: '3個月無限設計包',
      unlimitedPackage: '永久無限設計包',
      emptyTip: '還沒有被邀請的成員呢，趕緊去邀請吧~',
    },
    receiveForever: {
      scanQR: '掃描活動客服二維碼',
      get: '領取',
      unlimitedPackage: '永久無限設計包',
      rewardWillSoonArrived: '永久無限設計包預計1個工作日內發放至團隊',
    },
  },

  pager: {
    total: '共 {0} 個',
    checkAll: '全選',
    selected: '，已選{0}',
    batchOperate: '批量作業',
    sizeOfPerPage: '{0} 條/頁',
    jumpTo: '跳至',
    page: '頁',
  },
  emptyTip: {
    emptyTip: '暫無內容',
    searchEmptyTip: '未搜尋到相關內容',
  },
  role: {
    superAdministrator: '超級管理員',
    administrator: '管理員',
    member: '成員',
    guest: '協同人',
  },
};
