import * as React from 'react';
import * as _ from 'lodash';

import { PolygonData, rgba2hex } from '@utils/graphicsUtils';

import { StrokePosition } from '@/fbs/rp/models/properties/stroke';
import { IComponentValue, IPathValue } from '@fbs/rp/models/value';
import { ISize } from '@/fbs/common/models/common';
import { IProperties } from '@/fbs/rp/models/property';
import { StyleHelper } from '@helpers/styleHelper';
import { GrayColor } from '@/consts/colors';

import { IComponentProps } from '../../types';
import { CPath, CCompoundPath } from '../../constants';
import { renderClipFill } from '../common/GradientFragment';
import { renderMaskStroke } from '../common/SvgStrokeFragment';
import ShapeTextBase, { IShapeTextCompState, makeShapeBase } from '../common/ShapeTextBase';

import './index.scss';

interface IPolygonState extends IShapeTextCompState {
  style: React.CSSProperties;
  filter: string;
  path: string;
  stroke: StyleHelper.ISVGStroke;
}

export function makeTriangle(id: string) {
  const data = makeShapeBase(id, CPath, true);
  const points = new PolygonData(data.size, 3).toPolygonPoints(0).map((pt) => {
    return {
      point: pt,
      handleIn: { x: 0, y: 0 },
      handleOut: { x: 0, y: 0 },
    };
  });
  const pathValue: IPathValue = {
    data: points,
    closed: true,
  };
  data.value = pathValue;
  return data;
}

class Polygon extends ShapeTextBase<IComponentProps, IPolygonState> {
  constructor(props: IComponentProps) {
    super(props);
    this.state = this.doBuilderStyle(props);
    this.param = this.getParams(props);
  }

  private param: {
    size: ISize;
    properties: IProperties;
    value?: IComponentValue;
    opacity: number;
    isPreview?: boolean;
    globalScale: number;
    transition: string;
    isChildOfPath: boolean;
    valueEditing?: boolean;
    text: string;
    version: string;
  } & { [key: string]: any };

  private getParams(props: IComponentProps) {
    const {
      comp: { size, properties, value, opacity, parent, text, version },
      isPreview,
      globalScale,
      valueEditing,
    } = props;
    const transition = props.comp.getTransition();
    const isChildOfPath = parent?.type === CCompoundPath;
    return {
      size: _.cloneDeep(size),
      properties: _.cloneDeep(properties),
      value: _.cloneDeep(value),
      opacity,
      isPreview,
      globalScale,
      transition,
      isChildOfPath,
      valueEditing,
      text,
      version,
    };
  }

  shouldComponentUpdate(nextProps: IComponentProps) {
    const newParam: { [key: string]: any } = this.getParams(nextProps);

    let flag = false;
    Object.keys(this.param).forEach((key) => {
      if (!_.isEqual(newParam[key], this.param[key])) {
        flag = true;
        this.param[key] = _.cloneDeep(newParam[key]);
      }
    });

    return flag;
  }

  doBuilderStyle = (props: IComponentProps): IPolygonState => {
    const { properties, size } = props.comp;
    const { polygon } = properties;
    const svgPropsBuilder = StyleHelper.initSVGStyleParser(properties);
    const filter = svgPropsBuilder.getShadow();
    const style: React.CSSProperties = {
      fill: svgPropsBuilder.getFill(`${props.comp.type}-fill-${props.comp.id}`),
    };
    const sideCount = polygon ? polygon.sideCount : 3;

    const polygonData = new PolygonData(size, sideCount);
    const points = polygonData.toPolygonPoints(0);

    const path = this.polygon2path(points, { x: 0, y: 0 });
    return {
      style,
      filter,
      path,
      stroke: svgPropsBuilder.getStroke(),
      textStyle: this.parserTextStyle(props),
    };
  };

  UNSAFE_componentWillReceiveProps(nextProps: IComponentProps) {
    this.setState({ ...this.doBuilderStyle(nextProps) });
  }

  polygon2path(points: { x: number; y: number }[], offset = { x: 0, y: 0 }) {
    let path = '';
    points.forEach((item, i) => {
      if (i === 0) {
        path += `M ${item.x + offset.x} ${item.y + offset.y} L `;
      } else {
        path += `${item.x + offset.x} ${item.y + offset.y} `;
      }
    });
    path += 'Z';
    return path;
  }

  get isChildOfPath() {
    return this.props.comp.parent?.type === CCompoundPath;
  }

  render() {
    const { comp, isPreview } = this.props;
    const globalScale = this.props.globalScale || 1;
    const { properties, text, id, type, size } = comp;
    const { style, filter, path, stroke } = this.state;
    const opacity = _.isUndefined(comp.opacity) ? 1 : comp.opacity / 100;
    const isChildOfPath = this.isChildOfPath;
    const option = {
      id,
      type,
      size,
      fill: isChildOfPath ? undefined : properties.fill,
      transition: this.props.comp.getTransition(),
      scale: 1,
    };
    const strokeOpt = {
      id,
      size,
      stroke,
      data: path,
      scale: globalScale,
      strokePosition: isChildOfPath ? StrokePosition.center : properties.stroke?.position || StrokePosition.inner,
      transition: this.props.comp.getTransition(),
    };

    if (isChildOfPath) {
      strokeOpt.stroke.stroke = rgba2hex(GrayColor);
      strokeOpt.stroke.strokeWidth = 1;
      strokeOpt.stroke.strokeDasharray = undefined;
    }

    const svgStyle: React.CSSProperties = {
      filter,
      transition: comp.getTransition(),
      opacity,
      pointerEvents: 'none',
    };

    if (isPreview) {
      Object.assign(svgStyle, {
        width: size.width,
        height: size.height,
      });
    }

    return (
      <div className="lib-comp-polygon" style={{ ...size }}>
        <svg style={svgStyle} shapeRendering="geometricPrecision">
          {renderClipFill(
            option,
            <path
              d={path}
              fill={isChildOfPath ? 'transparent' : style.fill}
              strokeWidth={0}
              style={{
                transition: comp.getTransition(),
              }}
            />,
          )}
          {renderMaskStroke(strokeOpt)}
          {/* <path
            d={path}
            {...stroke}
            shapeRendering="geometricPrecision"
            fill={'transparent'}
            style={{ ...style, transition: comp.getTransition(), fill: 'transparent' }}
          /> */}
        </svg>
        {!isChildOfPath && this.renderTextFragment(text, properties, opacity)}
      </div>
    );
  }
}

export default Polygon;
