import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { UIComponent, UIContainerComponent } from '@/editor/comps';
import BasicComponentLib from '@/libs/basic';
import {
  CAreaChart,
  CBarChart,
  CBarChartHorizontal,
  CDoughnutChart,
  CLineChart,
  CPieChart,
  CRadarChart,
  CScatterChart,
  CFrame,
  CAudio,
  CHorizontalMenu,
  CCombinationChart,
  CGaugeChart,
  CMapChart,
  CSnapshot,
  CContentPanelV2,
} from '@/libs/constants';
import { renderChartWrapper } from './renderChartWrapper';
import { renderAudio } from './renderAudio';
import { renderHorizontalMenuWrapper } from './renderHorizontalMenu';
import { renderComponentWrapper } from './renderComponentWrapper';
import { renderBlankFilter as defaultBlankFilter } from '../util/filterUtil';
import { IRenderBlankFilter, IHtmlContent, ICompInfo } from '../type';
import { renderHidden } from './renderHidden';
import { assembleResource } from '../style';
import { renderSnapshot } from './renderSnapshot';
import { renderContentPanelV2 } from './renderContentPanelV2';

export const renderToHtml = async (
  t: UIComponent,
  renderBlankFilter?: IRenderBlankFilter,
): Promise<IHtmlContent | undefined> => {
  const jsx = renderJSX(t, renderBlankFilter);
  if (!jsx) {
    return;
  }
  return JSXtoHtml(jsx, { name: t.name, type: t.type });
};

export const JSXtoHtml = async (jsx: JSX.Element, compInfo: ICompInfo): Promise<IHtmlContent | undefined> => {
  // 生成html
  let html = renderToStaticMarkup(jsx);
  // try {
  // 装配资源
  return assembleResource(html, {
    abbrStyleName: true,
    compressStyle: true,
    compInfo,
  });
  // } catch (e) {
  //   // tofix: 异常处理
  //   // console.log('tofix: 异常处理',e);
  //   exceptionCollector.add(e);
  //   return {
  //     content: html,
  //   };
  // }
};

export const isChartData = (type: string) => {
  return [
    CBarChart,
    CBarChartHorizontal,
    CLineChart,
    CPieChart,
    CDoughnutChart,
    CAreaChart,
    CRadarChart,
    CScatterChart,
    CCombinationChart,
    CGaugeChart,
    CMapChart,
  ].includes(type);
};

export const renderJSX = (t: UIComponent, renderBlankFilter?: IRenderBlankFilter): JSX.Element | null => {
  return renderSimpleComponent(t, 1, renderBlankFilter);
};

function renderContainerChildren(
  group: UIContainerComponent,
  scale: number,
  renderBlankFilter?: IRenderBlankFilter,
): (JSX.Element | null)[] {
  return group.components.map((comp) => renderSimpleComponent(comp, scale, renderBlankFilter));
}

export function renderSimpleComponent(
  comp: UIComponent,
  scale: number = 1,
  renderBlankFilter: IRenderBlankFilter = defaultBlankFilter,
): JSX.Element | null {
  // 隐藏 热区 透明 导出空白
  if (renderBlankFilter(comp)) {
    return null;
  }
  // 隐藏
  if (comp.hidden) {
    return renderHidden(comp);
  }

  if (isChartData(comp.type)) {
    // 图表
    return renderChartWrapper(comp);
  }

  // 音频
  if (CAudio === comp.type) {
    return renderAudio(comp, scale);
  }

  if (CHorizontalMenu === comp.type) {
    return renderHorizontalMenuWrapper(comp);
  }

  // 快照
  if (comp.type === CSnapshot) {
    return renderSnapshot(comp);
  }

  // 网页
  const isPreview = comp.type !== CFrame;

  return renderBasicComponent({
    comp,
    scale,
    isPreview,
    renderBlankFilter,
  });
}

interface IProps {
  comp: UIComponent;
  scale: number;
  isPreview: boolean;
  renderBlankFilter?: IRenderBlankFilter;
}

export const renderBasicComponent = ({ comp, scale, isPreview, renderBlankFilter }: IProps): JSX.Element | null => {
  try {
    const children =
      comp.isContainer && !comp.isTable && renderContainerChildren(comp as UIContainerComponent, 1, renderBlankFilter);

    let content;
    if (comp.type === CContentPanelV2) {
      // 新内容面板独立渲染
      content = renderContentPanelV2(comp, <>{children}</>);
    } else {
      content = <>{BasicComponentLib.render(comp, { isPreview, isStandalone: true }, children)}</>;
    }

    return renderComponentWrapper({ comp, scale }, content);
  } catch (e) {
    console.log(e);
    return null;
  }
};
