import * as React from 'react';
import classnames from 'classnames';

import { Button, TreeItemData, Tree } from '@dsm';
import { Icon } from '@dsm2';

import { IAppWithNestedChildren } from '@fbs/rp/models/app';
import { INodeWithChildren } from '@fbs/rp/models/node';
import { ControlPanelState } from '@/fbs/rp/models/preview';
import { PreviewUrlSearchKey } from '@/fbs/rp/utils/preview';
import { IUserInfo } from '@fbs/idoc/models/user';
import { getAllPageCount } from '@fbs/rp/utils/app';
import {
  isLarkApplets,
  isLarkBrowser,
  isWeChatApplets,
  isBaiduApplets,
  isPhoneIn,
  parseDataToRPTree,
  shouldHiddenPageVisible,
} from '@helpers/previewHelper';
import i18n, { languageManager } from '@i18n';
import { getOSSystem } from '@utils/envUtils';
import { OSSystem } from '@fbs/common/models/platform';
import { getQueryString } from '@helpers/urlHelper';
import { Role } from '@/fbs/teamManagement';

import './index.scss';

export interface ITreePanelProp {
  app: IAppWithNestedChildren;
  userInfo: IUserInfo | undefined;
  selected?: string;
  role?: Role;
  onPageSelect: (node: INodeWithChildren) => void;
}

export interface ITreePanelState {
  show?: boolean;
  treeData: TreeItemData<INodeWithChildren>[];
  selected?: string;
}

class TreePanel extends React.Component<ITreePanelProp, ITreePanelState> {
  static defaultProps: Partial<ITreePanelProp> = {};

  selfRef: React.RefObject<HTMLDivElement>;
  private touchTimeID?: Timeout;

  private touchPoint: { x: number; y: number } = { x: 0, y: 0 };
  private isTouchMoved?: boolean;

  private allowExit?: boolean = isPhoneIn && (isLarkApplets ? languageManager.isZHLanguage : true);

  get canVisitHiddenPage() {
    const { role, userInfo, app } = this.props;
    return shouldHiddenPageVisible(role, app, userInfo?.id);
  }

  get showPageNumber() {
    return this.props.app.showRPPageNumber;
  }

  get hideControlPanel(): boolean {
    return getQueryString(PreviewUrlSearchKey.ControlPanelState) === ControlPanelState.Hide;
  }

  constructor(props: ITreePanelProp) {
    super(props);
    const { selected, app } = this.props;
    this.state = {
      treeData: parseDataToRPTree(selected || '', app.children, {
        allCollapsed: true,
        shouldHidePage: !this.canVisitHiddenPage,
        showPageNumber: this.showPageNumber,
      }),
      selected: props.selected,
    };
    this.selfRef = React.createRef();
    // this.calcAllowExit();
  }

  static getDerivedStateFromProps(props: ITreePanelProp, state: ITreePanelState) {
    const { selected, app, userInfo, role } = props;
    if (!state.treeData || selected !== state.selected) {
      return {
        treeData: parseDataToRPTree(selected || '', app.children, {
          allCollapsed: true,
          shouldHidePage: !shouldHiddenPageVisible(role, app, userInfo?.id),
          showPageNumber: app.showRPPageNumber,
        }),
        selected: props.selected,
      };
    }
    return state;
  }

  componentDidMount() {
    window.addEventListener('touchstart', this.handleTouchStart);
    document.addEventListener('touchmove', this.handleTouchMove);
    window.addEventListener('touchend', this.handleTouchEnd, { capture: true });
    window.addEventListener('popstate', this.handleExitListener);
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.handleTouchStart);
    window.removeEventListener('touchmove', this.handleTouchMove);
    window.removeEventListener('touchend', this.handleTouchEnd, { capture: true });
    window.removeEventListener('popstate', this.handleExitListener);
  }

  handleTouchStart = (e: TouchEvent) => {
    if (this.hideControlPanel) {
      return;
    }
    const { pageX: x, pageY: y } = e.touches[0];
    this.touchPoint = { x, y };
    this.isTouchMoved = false;
    if (this.state.show) {
      this.setState({ show: false });
      return;
    } else {
      if (e.touches.length > 1) {
        return;
      }
      this.touchTimeID = window.setTimeout(() => {
        if (!this.isTouchMoved) {
          this.setState({ show: true });
        }
      }, 500);
    }
  };

  handleTouchMove = (e: TouchEvent) => {
    const diff = 4;
    const { pageX: x, pageY: y } = e.touches[0];
    const { x: lastX, y: lastY } = this.touchPoint;
    // 如果手指已滑动过一定距离后再滑动回来的，算作已滑动过了
    if (this.isTouchMoved) {
      return;
    }
    // 仅允许单击
    if (e.detail > 1) {
      this.isTouchMoved = true;
      return;
    }
    // 仅允许单指
    if (e.touches.length > 1) {
      this.isTouchMoved = true;
      return;
    }
    if (Math.abs(x - lastX) > diff || Math.abs(y - lastY) > diff) {
      this.isTouchMoved = true;
    }
  };

  handleTouchEnd = () => {
    window.clearTimeout(this.touchTimeID);
  };

  handleContentTouch = (e: React.TouchEvent) => {
    e.stopPropagation();
  };

  handleCollapseChange = (e: React.MouseEvent) => {
    const classList = (e.target as HTMLElement).classList;
    if (!classList.contains('mobile-tree-panel') && !classList.contains('controller-ball')) {
      return;
    }
    const { show } = this.state;
    if (show) {
      this.setState({ show: false });
    } else {
      this.setState({ show: true });
    }
  };

  handleTreeItemClick = (e: React.MouseEvent, item: INodeWithChildren) => {
    if (item.type === 'folder') {
      return;
    }
    this.props.onPageSelect(item);
    this.setState({ show: false });
  };

  handleExitPreview = () => {
    if (isLarkApplets) {
      // @ts-ignore
      if (tt && tt.miniProgram && tt.miniProgram.navigateBack) {
        // @ts-ignore
        tt.miniProgram.navigateBack();
      }
    } else if (isLarkBrowser) {
      // @ts-ignore
      if (window.h5sdk) {
        // @ts-ignore
        window.h5sdk.ready(function () {
          // @ts-ignore
          window.h5sdk.biz.navigation.close({});
        });
      }
    } else {
      // @ts-ignore
      if (window.ReactNativeWebView) {
        // @ts-ignore
        window.ReactNativeWebView.postMessage('exit');
      }
    }
  };

  /** 处理监听小程序返回事件直接退出,此处只监听微信和百度小程序 */
  handleExitListener() {
    if (isWeChatApplets) {
      // @ts-ignore
      wx?.miniProgram?.navigateBack();
    } else if (isBaiduApplets) {
      // @ts-ignore
      swan?.webView?.navigateBack();
    }
  }

  renderItem = (item: INodeWithChildren) => {
    const name = item.serialNumber ? `${item.serialNumber} ${item.name}` : item.name;
    return (
      <div className="tree-item-render">
        {item.type === 'folder' && <Icon cls="tree_index" />}
        <div className="text">{name}</div>
        {item.hidden && <Icon cls="icon_tree_unview" />}
      </div>
    );
  };

  /** 飞书小程序和App端安卓环境退出按钮，保留原有逻辑 */
  renderExitBtton = () => {
    return (
      <div className="bottom">
        <Button theme="border" className="exit-button" onClick={this.handleExitPreview}>
          {i18n('general.exit')}
        </Button>
      </div>
    );
  };

  /** IOS环境右滑动提示 */
  renderExitTips = () => {
    return (
      <div className="bottom">
        <div className="tree-exit-preview-ios">
          <div className="tree-exit-preview-ios">
            <Icon cls="icon_right" size={22} />
            <div className="text">{i18n('preview.iosExitText')}</div>
          </div>
        </div>
      </div>
    );
  };

  /** 处理手机端各种环境退出逻辑，新增微信、百度小程序不显示右滑动提示文案，不显示退出按钮，监听顶部导航返回事件直接退出 */
  renderExitContainer = () => {
    if (!isBaiduApplets && !isWeChatApplets) {
      if (!isLarkApplets && getOSSystem() === OSSystem.iOS) {
        // App端在IOS环境显示右滑提示文案
        return this.renderExitTips();
      } else {
        // 1、飞书小程序保留原有逻辑显示退出按钮；2、App端在安卓环境保留原有逻辑显示退出按钮
        return this.renderExitBtton();
      }
    }
    return null;
  };

  render() {
    const { app } = this.props;
    const { show } = this.state;
    return (
      <div className={classnames('mobile-tree-panel', { show })} onClick={this.handleCollapseChange}>
        <div className="content" onTouchStart={this.handleContentTouch}>
          <div className="app-title">
            <div className="title-content">
              <div className="rp-logo" />
              <div className="app-info">
                <div className="app-name">{app.name}</div>
                <div className="page-count">
                  {i18n(
                    'application.pageCount2',
                    getAllPageCount(app.children, { noHiddenPage: !this.canVisitHiddenPage }),
                  )}
                </div>
              </div>
            </div>
          </div>
          <Tree
            items={this.state.treeData}
            itemHeight={40}
            onItemClick={this.handleTreeItemClick}
            itemRender={this.renderItem}
          />
          {this.allowExit && this.renderExitContainer()}
        </div>
      </div>
    );
  }
}

export default TreePanel;
