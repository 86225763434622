import { get, cloneDeep, isEqual, forEach, round } from 'lodash';

import { measureTextSize, IMeasureReturnType } from '@utils/textUtils';
import { FillType } from '@/utils/graphicsUtils';

import i18n from '@i18n';
import {
  makeCommonComponent,
  getDefaultShadow,
  getDeafultRadius,
  getDefaultStroke,
  getDefaultBorder,
} from '../../helper';
import { UIComponent, UIContainerComponent } from '@/editor/comps';
import { mergePatches } from '@/helpers/patchHelper';
import { getNewID } from '@/helpers/idHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { PredefinedStates } from '@consts/state';
import * as SystemsColor from '@consts/colors';
import { SpriteThumb } from '@consts/spriteIcons';

import { ArtboardPatches, Ops, ReplaceOperation } from '@/fbs/rp/utils/patch';
import ITextFormat, { TextAlign, TextPropertyName } from '@fbs/rp/models/properties/text';
import { IComponentData, IComponentStateData } from '@/fbs/rp/models/component';
import { HorizontalAlign, VerticalAlign } from '@/fbs/rp/models/layout';
import { NumberProperty } from '@/fbs/rp/models/properties/base';
import { PropertyValue } from '@/fbs/rp/models/property';
import IVerticalTabsIconStyle, {
  IconPositionMode,
  VerticalTabsIconStylePropertyName,
} from '@/fbs/rp/models/properties/verticalTabsIconStyle';
import { IPosition, IBoundsOffset, ISize } from '@/fbs/common/models/common';
import { MoveDelta } from '@/fbs/common/models/resize';
import { CCanvasPanel, CIcon, CListLayoutPanel, CPureText, CRect, CVerticalTabs } from '@libs/constants';
import { IComponentItem } from '@libs/types';
import { SizeMode } from '@libs/enum';
import IMarkerStrip, { MarkerStripPositionMode, MarkerStripPropertyName } from '@/fbs/rp/models/properties/makerstrip';

export const DEFAULT_INDICATOR_WIDTH = 40; // 默认标识条宽度
export const DEFAULT_INDICATOR_HEIGHT = 40; // 默认标识条高度
const DEFAULT_TABS_SIZE = { width: 105, height: 196 };
const DEFAULT_ITEM_SIZE = { width: 105, height: 49 }; // 默认item大小
const DEFAULT_RECT_SIZE = { width: 4, height: DEFAULT_INDICATOR_HEIGHT }; // 默认标识条大小
const DEFAULT_ICON_SIZE = 14; // 默认图标大小
export const ITEM_PADDING = 8; // 图标和文本距顶部或者底部的间距
const DEFAULT_TEXT_STYLE = {
  fontSize: 14,
  textAlign: TextAlign.center,
  fontFamily: 'Microsoft YaHei',
  fontStyle: { underline: false, bold: false, strike: false, italic: false },
};
const ITEM_FONT_ICON_IDS = [60321, 59693, 60308, 60291];
let iconPositionIndex = 0;

export const getIconCompPosition = (iconPositionMode: number) => {
  // left
  if (iconPositionMode === IconPositionMode.Left) {
    return {
      x: ITEM_PADDING,
      y: Math.round((DEFAULT_ITEM_SIZE.height - DEFAULT_ICON_SIZE) / 2),
    };
  }
  // top
  return {
    x: ITEM_PADDING,
    y: ITEM_PADDING,
  };
};

// 文本位置， 受到 图标大小影响
export const getTextCompPosition = (iconPositionMode: number, iconSize: number = DEFAULT_ICON_SIZE) => {
  // left
  if (iconPositionMode === IconPositionMode.Left) {
    return {
      x: ITEM_PADDING + iconSize + ITEM_PADDING,
      y: Math.round((DEFAULT_ITEM_SIZE.height - DEFAULT_ICON_SIZE) / 2),
    };
  }
  // top
  return {
    x: ITEM_PADDING,
    y: Math.round((DEFAULT_ITEM_SIZE.height - iconSize) / 2 + ITEM_PADDING),
  };
};

// 计算文本的实际尺寸
function calcTextSize(textStyle: ITextFormat, text: string) {
  const parser = StyleHelper.initCSSStyleParser({ textStyle });
  const textSize = measureTextSize(parser.getTextStyle(), text, { returnType: IMeasureReturnType.Round });
  return textSize;
}

// 文本组件position计算, 文本缩放时，根据中心位置进行缩放
function calcTextCompPosition(itemWidth: number, textSize: ISize, oldPosition: IPosition, oldSize: ISize) {
  return {
    x: round((itemWidth - textSize.width) / 2, 2),
    y: oldPosition.y - (textSize.height - oldSize.height) / 2,
  };
}

/**
 * 循环组件的states数据
 * @param states
 * @param shouldAddNormalState 是否添加normal状态
 * @param cb 回调函数
 */
function statesForeach(
  states: { [name: string]: IComponentStateData },
  shouldAddNormalState: boolean,
  cb: (state: PredefinedStates) => void,
) {
  const list = Object.keys(states) || [];
  if (shouldAddNormalState) {
    list.unshift(PredefinedStates.normal);
  }
  list.forEach((state) => cb(state as PredefinedStates));
}

function createPositionAndSizeInfo(textComp: UIComponent | IComponentData, stateId?: PredefinedStates) {
  const validatedState = stateId && stateId !== PredefinedStates.normal;
  const compData = textComp instanceof UIComponent ? textComp.toJSON() : textComp;
  const oldPosition = get(compData.states, `${stateId}.position`, compData.position) as IPosition;
  const oldSize = get(compData.states, `${stateId}.size`, compData.size) as ISize;
  const sizePath = validatedState ? `./states/${stateId}/size` : './size';
  const positionPath = validatedState ? `./states/${stateId}/position` : './position';
  return { sizePath, positionPath, oldSize, oldPosition };
}

// 改图标大小，影响到位置变动
function mergeIconCompSizeStatesPatches(iconComp: UIComponent, fontSize: number, state?: PredefinedStates) {
  const patches: ArtboardPatches = { do: {}, undo: {} };
  const { sizePath, oldSize, positionPath, oldPosition } = createPositionAndSizeInfo(iconComp, state);
  const newSize = { ...iconComp.size, width: fontSize, height: fontSize };
  const y = round((DEFAULT_ITEM_SIZE.height - fontSize) / 2, 2);
  patches.do[iconComp.id] = [Ops.replace(sizePath, newSize), Ops.replace(positionPath, { x: oldPosition.x, y })];
  patches.undo[iconComp.id] = [Ops.replace(sizePath, oldSize), Ops.replace(positionPath, oldPosition)];
  return patches;
}

function mergeCompPositionStatesPatches(textComp: UIComponent, newPosition: IPosition, state?: PredefinedStates) {
  const patches: ArtboardPatches = { do: {}, undo: {} };
  const { positionPath, oldPosition } = createPositionAndSizeInfo(textComp, state);
  patches.do[textComp.id] = [Ops.replace(positionPath, newPosition)];
  patches.undo[textComp.id] = [Ops.replace(positionPath, oldPosition)];
  return patches;
}

function onPropertyUpdate(container: UIComponent, propertyName: string, value?: PropertyValue): ArtboardPatches | null {
  const group = container as UIContainerComponent;
  // 文本样式和选中文本样式修改，默认文本样式被更改，选中文本样式同步修改
  // if (propertyName === TextPropertyName || propertyName === 'textStyleChecked') {
  //   const isNormalTextStyleChanged = propertyName === TextPropertyName;
  //   const textStyle = value as ITextFormat;
  //   const components = group.components as UIContainerComponent[];
  //   const patches: ArtboardPatches = { do: {}, undo: {} };
  //   components.forEach((itemComp) => {
  //     const textComp = itemComp.getComponentByAlias('text') as UIComponent;
  //     const { size: oldSize, position: oldPosition } = textComp;
  //     const textSize = calcTextSize(textStyle, textComp.value as string);
  //     const { size: checkedSize, position: checkedPosition } = textComp.states.checked;
  //     const newPosition = calcTextCompPosition(
  //       itemComp.size.width,
  //       textSize,
  //       checkedPosition || oldPosition,
  //       checkedSize || oldSize,
  //     );
  //     patches.do[textComp.id] = [
  //       Ops.replace('./states/checked/size', { ...oldSize, ...textSize }),
  //       Ops.replace('./states/checked/position', newPosition),
  //     ];
  //     patches.undo[textComp.id] = [
  //       Ops.replace('./states/checked/size', textComp.states.checked.size || oldSize),
  //       Ops.replace('./states/checked/position', textComp.states.checked.position || oldPosition),
  //     ];
  //     if (isNormalTextStyleChanged) {
  //       patches.do[textComp.id].push(Ops.replace('./size', roundNumberObject({ ...oldSize, ...textSize })));
  //       patches.do[textComp.id].push(Ops.replace('./position', roundNumberObject(newPosition)));
  //       patches.undo[textComp.id].push(Ops.replace('./size', oldSize));
  //       patches.undo[textComp.id].push(Ops.replace('./position', oldPosition));
  //     }
  //   });
  //   return patches;
  // }

  // 修改图标大小
  if (propertyName === 'iconStyle') {
    const iconStyle = container.properties.iconStyle as IVerticalTabsIconStyle;
    const newStyle = value as IVerticalTabsIconStyle;

    if (iconStyle.iconSize !== newStyle.iconSize || iconStyle.iconPositionMode !== newStyle.iconPositionMode) {
      const patches: ArtboardPatches = { do: {}, undo: {} };
      // 更改图标位置
      iconStyle.iconPositionMode !== newStyle.iconPositionMode &&
        group.components.forEach((item) => {
          const itemComp = item as UIContainerComponent;

          // 图标
          const iconComp = itemComp.getComponentByAlias('fontIcon') as UIComponent;
          const newIconPosition = getIconCompPosition(newStyle.iconPositionMode);
          statesForeach(iconComp.states, true, (state) => {
            mergePatches(patches, mergeCompPositionStatesPatches(iconComp, newIconPosition, state as PredefinedStates));
          });

          // 文本
          const textComp = itemComp.getComponentByAlias('text') as UIComponent;
          const newTextPosition = getTextCompPosition(newStyle.iconPositionMode, newStyle.iconSize);
          statesForeach(textComp.states, true, (state) => {
            mergePatches(patches, mergeCompPositionStatesPatches(textComp, newTextPosition, state as PredefinedStates));
          });
        });

      // 图标大小
      iconStyle.iconSize !== newStyle.iconSize &&
        group.components.forEach((item) => {
          const itemComp = item as UIContainerComponent;

          // 图标 大小
          const iconComp = itemComp.getComponentByAlias('fontIcon') as UIComponent;
          statesForeach(iconComp.states, true, (state) => {
            mergePatches(
              patches,
              mergeIconCompSizeStatesPatches(iconComp, newStyle.iconSize, state as PredefinedStates),
            );
          });

          // 文本 位置
          const textComp = itemComp.getComponentByAlias('text') as UIComponent;
          const newTextPosition = getTextCompPosition(newStyle.iconPositionMode, newStyle.iconSize);
          statesForeach(textComp.states, true, (state) => {
            mergePatches(patches, mergeCompPositionStatesPatches(textComp, newTextPosition, state as PredefinedStates));
          });
        });
      return patches;
    }
  }

  return null;
}

/**
 * 对不同状态下高级编辑产生的patches进行修正
 * 高级组件编辑时，不同状态下组件大小或者位置发生变化，每个状态都是应用的同一个position和size
 * 此处对该现象进行修正，不同状态下使用不同的size和position
 * textStyle变化时，文本组件中心缩放
 * @param patches
 * @param stateId
 * @param updatedComps
 */
function onPatchesFixByAdvanceEdit(
  patches: ArtboardPatches,
  stateId: PredefinedStates,
  updatedComps: UIContainerComponent[],
  propertyName: string,
  value: PropertyValue,
) {
  if (propertyName === TextPropertyName && value) {
    const doOperations = patches.do;
    const undoOperations = patches.undo;
    const reg = /^(.)?\/(position)$/;
    updatedComps.forEach((itemComp) => {
      if (itemComp?.alias && itemComp.alias === 'text') {
        const textSize = calcTextSize(value as ITextFormat, itemComp!.value as string);
        const isNormalState = stateId === PredefinedStates.normal;
        const { positionPath, oldPosition, oldSize } = createPositionAndSizeInfo(itemComp, stateId);
        const newPosition = calcTextCompPosition(itemComp.parent!.size.width, textSize, oldPosition, oldSize);
        const compDoPatches = doOperations[itemComp.id];
        const compUndoPatches = undoOperations[itemComp.id];
        const statePath = `./states/${stateId}`;
        const addValue = { properties: {}, disabled: false };
        let hasStateAdd = false;
        if (!isNormalState && !Reflect.has(itemComp.states, stateId)) {
          compDoPatches.unshift(Ops.add(statePath, addValue));
          compUndoPatches.push(Ops.remove(statePath));
          hasStateAdd = true;
        }
        doOperations[itemComp.id] = compDoPatches.filter((patch, index) => {
          // 重复添加，合并添加值
          if (hasStateAdd && index !== 0 && patch.op === 'add' && patch.path === statePath) {
            Object.assign(addValue, patch.value);
            return false;
          }
          if (reg.test(patch.path)) {
            return false;
          }
          return true;
        });
        doOperations[itemComp.id].push(Ops.replace(positionPath, newPosition));

        undoOperations[itemComp.id] = compUndoPatches.filter((patch, index) => {
          // 重复添加, 不再移除
          if (
            hasStateAdd &&
            index !== compUndoPatches.length - 1 &&
            patch.op === 'remove' &&
            patch.path === statePath
          ) {
            return false;
          }
          // 移除原有positionPath,后面unshift对应状态的position
          if (reg.test(patch.path)) {
            return false;
          }
          return true;
        });
        undoOperations[itemComp.id].unshift(Ops.replace(positionPath, oldPosition));
      }
    });
  }
}

function createItemSizeAndPositionPatches(
  textComp: UIComponent,
  textStyle: ITextFormat,
  stateId?: PredefinedStates,
  value?: string,
  newPosition?: IPosition,
) {
  const updatePatches: ArtboardPatches = { do: {}, undo: {} };
  const textSize = calcTextSize(textStyle, value || (textComp!.value as string));
  const { oldPosition, oldSize, sizePath, positionPath } = createPositionAndSizeInfo(textComp, stateId);
  updatePatches.do[textComp!.id] = [
    Ops.replace(sizePath, { ...oldSize, ...textSize }),
    Ops.replace(positionPath, {
      ...(newPosition || oldPosition),
      x: round((textComp.parent!.size.width - textSize.width) / 2, 2),
    }),
  ];
  updatePatches.undo[textComp!.id] = [Ops.replace(sizePath, oldSize), Ops.replace(positionPath, oldPosition)];
  return updatePatches;
}

// 比较testStyle
function equalTextStyle(oldTextStyle: ITextFormat, newTextStyle: ITextFormat) {
  const ignoreKeys = ['ref', 'name', 'props'];

  function removeKey(textStyle: ITextFormat) {
    const cloneStyle = cloneDeep(textStyle);
    ignoreKeys.forEach((key) => {
      Reflect.deleteProperty(cloneStyle, key);
    });
    return cloneStyle;
  }

  return isEqual(removeKey(oldTextStyle), removeKey(newTextStyle));
}

/**
 * 修正应用高级编辑产生的patches
 * 当text组件textStyle发生变化时,除模板外的组件的position和size准确外
 * 其余子项的position和size都使用的模板组件值，在该处处理所有子项变化后的position和size
 * @param comp
 * @param patches
 */
function onPatchesFixByApplyAdvanceEdit(
  comp: UIContainerComponent,
  template: IComponentData,
  patches: ArtboardPatches,
) {
  const doOperations = patches.do;
  const textTemplate = template.components?.[1];
  const originTextComp = (comp.components[0] as UIContainerComponent).getComponentByAlias('text');
  const allTextComps = comp.components.map((c) =>
    (c as UIContainerComponent).getComponentByAlias('text'),
  ) as UIComponent[];

  function mergeTextCompStatesPatches(textStyle: ITextFormat, stateId?: PredefinedStates) {
    allTextComps.forEach((item) => {
      const positionPath =
        stateId && stateId !== PredefinedStates.normal ? `./states/${stateId}/position` : './position';
      const itemPatches = doOperations[item.id];
      let newPosition;
      forEach(itemPatches, (patch) => {
        if (patch.op === 'replace' && patch.path === positionPath) {
          newPosition = patch.value;
          return false;
        }
        if (stateId && patch.op === 'add' && patch.path === `./states/${stateId}`) {
          newPosition = patch.value.position;
          return false;
        }
      });
      mergePatches(patches, createItemSizeAndPositionPatches(item, textStyle, stateId, undefined, newPosition));
    });
  }

  if (textTemplate) {
    const states = textTemplate.states;
    statesForeach(states, true, (state) => {
      let stateTextStyle = get(states[state], 'properties.textStyle', {});
      let originTextStyle = get(originTextComp, `states.${state}.properties.textStyle`, {});
      // 默认状态
      if (state === PredefinedStates.normal) {
        stateTextStyle = get(textTemplate, 'properties.textStyle', {});
        originTextStyle = get(comp, 'properties.textStyle');
      }
      if (state === PredefinedStates.checked) {
        originTextStyle = get(comp, 'properties.textStyleChecked');
      }
      // applyStyle方法每次都会生成默认状态下的信息，且size和position都是模板数据，不准确
      // 默认状态下，不管是否textStyle发生变更，执行一遍进行修正
      if (state === PredefinedStates.normal || !equalTextStyle(stateTextStyle, originTextStyle)) {
        mergeTextCompStatesPatches(stateTextStyle, state);
      }
    });
  }
}

// 文本长度变化，textComponent下不同states的进行同步变化，用于维持高级编辑位置正确
function onItemValueChanged(comp: UIContainerComponent, textComp: UIComponent, value: string) {
  const states = textComp.states;
  const patches: ArtboardPatches = { do: {}, undo: {} };
  statesForeach(states, true, (state) => {
    let textStyle = get(states[state], 'properties.textStyle', comp.properties.textStyle);
    if (state === PredefinedStates.checked) {
      textStyle = comp.properties.textStyleChecked;
    }
    mergePatches(patches, createItemSizeAndPositionPatches(textComp, textStyle, state, value));
  });
  return patches;
}

// 合并组件不同状态下的position Patches
function mergeCompPositionPatchesInStates(
  itemComp: IComponentData | undefined,
  itemWidth: number,
  offsetY = 0,
  patches: ArtboardPatches,
) {
  if (!itemComp) {
    return;
  }
  statesForeach(itemComp!.states, true, (state) => {
    const { oldPosition, oldSize, positionPath } = createPositionAndSizeInfo(itemComp, state);
    const y = itemComp.alias === 'fontIcon' ? oldPosition.y : oldPosition.y + offsetY;
    const doPatches = [Ops.replace(positionPath, { y, x: round((itemWidth - oldSize.width) / 2, 2) })];
    const undoPatches = [Ops.replace(positionPath, oldPosition)];
    const positionPatches = {
      do: { [itemComp._id]: doPatches },
      undo: { [itemComp._id]: undoPatches },
    };
    mergePatches(patches, positionPatches);
  });
}

// 批量生成文本组件不同状态下的position/x Patches
function createCompsStatesPatches(compDates: IComponentData[], itemWidth: number, offsetY?: number) {
  const patches: ArtboardPatches = { do: {}, undo: {} };

  compDates.forEach((item) => {
    mergeCompPositionPatchesInStates(item.components?.[1], itemWidth, offsetY, patches); // 文本组件
    mergeCompPositionPatchesInStates(item.components?.[0], itemWidth, offsetY, patches); // 图标组件
  });
  return patches;
}

/**
 * 移除子组件，对所有文本组件不同状态下的位置进行调整,维持高级编辑时文本位置居中
 * @param comp
 * @param removeIds
 */
function onRemoveChildren(container: UIContainerComponent, removeIds: string[]) {
  const components = container.components as UIContainerComponent[];
  const itemWidth = round(container.size.width / (components.length - removeIds.length));

  const compDates = components.reduce((prev, item) => {
    if (!removeIds.includes(item.id)) {
      prev.push(item.toJSON());
    }
    return prev;
  }, [] as IComponentData[]);

  return createCompsStatesPatches(compDates, itemWidth);
}

/**
 * 添加组件，需要调整每个文本组件不同states下的位置，维持高级编辑位置正确
 */
function onAddChildren(container: UIContainerComponent, addComponents: IComponentData[]) {
  const components = container.components as UIContainerComponent[];
  const itemWidth = round(container.size.width / (components.length + addComponents.length), 2);

  const compDates = components.reduceRight(
    (prev, item) => {
      prev.unshift(item.toJSON());
      return prev;
    },
    [...addComponents],
  );

  return createCompsStatesPatches([...compDates, ...addComponents], itemWidth);
}

// 拖拽完成后处理
function onResize(container: UIComponent, newSize: ISize, updateSelf?: boolean) {
  if (updateSelf) {
    const group = container as UIContainerComponent;
    const components = group.components as UIContainerComponent[];
    const itemWidth = round(newSize.width / components.length, 2);
    const offsetY = newSize.height - container.size.height;
    const compDates = components.map((item) => item.toJSON());
    return createCompsStatesPatches(compDates, itemWidth, offsetY);
  }
  return null;
}

/**
 * 拖拽完成时，处理不同状态下文本组件位置
 * 合并拖拽时，组件size未发生变化，不执行组件内元素的变更
 */
function onFixPatchesBeforeResized(container: UIComponent, patches: ArtboardPatches) {
  const group = container as UIContainerComponent;
  const groupPatches = patches.do[group.id] || [];
  const sizePatch = groupPatches.find((patch) => patch.op === 'replace' && patch.path === './size') as ReplaceOperation<
    ISize
  >;
  const newSize = sizePatch?.value;
  if (newSize && !isEqual(newSize, container.size)) {
    const childrenPatches = onResize(container, newSize, true);
    if (childrenPatches) {
      mergePatches(patches, childrenPatches);
    }
  }
}

/**
 * 高级编辑拖拽完成时，处理不同状态下文本组件位置
 */
function onFixAdvancePatchesBeforeResized(container: UIComponent, offset: IBoundsOffset, patches: ArtboardPatches) {
  const group = container as UIContainerComponent;
  // 高级编辑tabItem拖拽完成时，生成的patches必定存在对应的变化
  // patches不存在tabItem的变更，表示为子元素的变更，不进行处理
  if (group.alias === 'tabItem' && Reflect.has(patches.do, group.id)) {
    const itemWidth = round(container.size.width - offset.left + offset.right, 2);
    const offsetY = offset.bottom - offset.top;
    const positionPatches = createCompsStatesPatches([group.toJSON()], itemWidth, offsetY);
    mergePatches(patches, positionPatches);
  }
}

// 高级编辑移动组件，更改所有对应组件所有状态下的position.y
function onChildMove(delta: MoveDelta, _sealedComp: UIContainerComponent, movedComps?: UIComponent[] | undefined) {
  if (movedComps) {
    const patches: ArtboardPatches = { do: {}, undo: {} };
    const offsetY = delta.y;
    movedComps.forEach((comp) => {
      if (comp.alias && ['fontIcon', 'text'].includes(comp.alias)) {
        statesForeach(comp.states, true, (state) => {
          const { oldPosition, positionPath } = createPositionAndSizeInfo(comp, state);
          const doPatches = [Ops.replace(positionPath, { y: oldPosition.y + offsetY, x: oldPosition.x })];
          const undoPatches = [Ops.replace(positionPath, oldPosition)];
          const positionPatches = {
            do: { [comp.id]: doPatches },
            undo: { [comp.id]: undoPatches },
          };
          mergePatches(patches, positionPatches);
        });
      }
    });
    return patches;
  }
  return null;
}

function makeItem(iconId: number, index: number) {
  const textValue = `${i18n('resource.componentsText.optionText')} ${index + 1}`;
  const textSize = calcTextSize(DEFAULT_TEXT_STYLE, textValue);
  const textPosition = {
    x: round((DEFAULT_ITEM_SIZE.width - textSize.width) / 2, 2),
    y: round((DEFAULT_ITEM_SIZE.height - textSize.height) / 2),
  };
  const indicatorPosition = {
    x: Math.round(DEFAULT_ITEM_SIZE.width - DEFAULT_RECT_SIZE.width),
    y: Math.round((DEFAULT_ITEM_SIZE.height - DEFAULT_RECT_SIZE.height) / 2),
  };

  return makeCommonComponent(getNewID(), CCanvasPanel, {
    alias: 'tabItem',
    properties: {
      fill: { ref: '@properties.background' },
    },
    size: DEFAULT_ITEM_SIZE,
    position: {
      x: DEFAULT_ITEM_SIZE.width * index,
      y: 0,
    },
    selected: index === 0,
    states: {
      [PredefinedStates.checked]: {
        enabled: true,
        properties: {
          fill: {
            ref: '@properties.fill2',
          },
        },
      },
    },
    layout: {
      vertical: VerticalAlign.TopAndBottom,
      horizontal: HorizontalAlign.Auto,
      fixedWidth: false,
      fixedHeight: false,
      auto: false,
      responsive: true,
    },
    components: [
      makeCommonComponent(getNewID(), CIcon, {
        alias: 'fontIcon',
        position: getIconCompPosition(iconPositionIndex),
        layout: {
          vertical: VerticalAlign.Middle,
          horizontal: HorizontalAlign.Left,
          auto: false,
          fixedWidth: true,
          fixedHeight: true,
          responsive: true,
        },
        size: { width: DEFAULT_ICON_SIZE, height: DEFAULT_ICON_SIZE, lockedRatio: true },
        value: { iconCode: iconId, fontName: 'lightIconFont' },
        properties: {
          icon: { ref: '@properties.iconStyle.iconDefaultStyle' },
        },
        states: {
          [PredefinedStates.checked]: {
            enabled: true,
            properties: {
              icon: { ref: '@properties.iconStyle.iconSelectedStyle' },
            },
          },
        },
      }),
      makeCommonComponent(getNewID(), CPureText, {
        alias: 'text',
        position: textPosition,
        autoSize: true,
        size: textSize,
        layout: {
          vertical: VerticalAlign.Middle,
          horizontal: HorizontalAlign.Center,

          fixedWidth: true,
          fixedHeight: true,
          auto: false,
          responsive: true,
        },
        properties: {
          textStyle: { ref: '@properties.textStyle' },
        },
        states: {
          [PredefinedStates.checked]: {
            enabled: true,
            size: textSize,
            position: textPosition,
            properties: {
              textStyle: { ref: '@properties.textStyleChecked' },
            },
          },
        },
        value: textValue,
      }),
      makeCommonComponent(getNewID(), CRect, {
        size: DEFAULT_RECT_SIZE,
        alias: 'indicator',
        position: indicatorPosition,
        layout: {
          vertical: VerticalAlign.Middle,
          horizontal: HorizontalAlign.Right,
          auto: false,
          fixedWidth: true,
          fixedHeight: true,
          responsive: true,
        },
        properties: {
          fill: {
            type: FillType.solid,
            color: SystemsColor.DefaultWhiteFillColor,
            disabled: true,
          },
          radius: {
            disabled: true,
            topLeft: 0,
            topRight: 0,
            bottomLeft: 0,
            bottomRight: 0,
            isPercent: false,
          },
        },
        states: {
          [PredefinedStates.checked]: {
            enabled: true,
            properties: {
              fill: { ref: '@properties.fillChecked' },
            },
          },
        },
      }),
    ],
  });
}

export function makeVerticalTabs(id: string): IComponentData {
  return makeCommonComponent(id, CListLayoutPanel, {
    ...VerticalTabsCfg.getDefaultData?.(),
    alias: CVerticalTabs,
    size: DEFAULT_TABS_SIZE,
    sealed: true,
    layout: {
      vertical: VerticalAlign.Middle,
      horizontal: HorizontalAlign.Left,
      fixedWidth: true,
      fixedHeight: true,
      auto: true,
      responsive: false,
    },
    select: {
      target: 'child',
      minCount: 1,
      maxCount: 1,
      reversible: false,
      autoUnselect: true,
      enabled: true,
    },
    components: ITEM_FONT_ICON_IDS.map((iconId, index) => makeItem(iconId, index)),
  });
}

export const VerticalTabsCfg: IComponentItem = {
  type: CVerticalTabs,
  name: i18n('resource.components.verticalTab'),
  thumb: {
    spriteIconClass: SpriteThumb.VerticalTab.className,
    dragPosition: SpriteThumb.VerticalTab.position,
    newBadge: SpriteThumb.VerticalTab.newBadge,
  },
  isList: true,
  isTextComp: true, // 数据填充文本-判断需要
  editor: {
    onPropertyUpdate,
    onRemoveChildren,
    onAddChildren,
    onResize,
    onChildMove,
    specials: {
      onPatchesFixByAdvanceEdit,
      onPatchesFixByApplyAdvanceEdit,
      onItemValueChanged,
      onFixPatchesBeforeResized,
      onFixAdvancePatchesBeforeResized,
    },
  },
  constraint: {
    text: {
      resize: SizeMode.none,
    },
    fontIcon: {
      resize: SizeMode.none,
    },
    indicator: {
      resize: SizeMode.vertical,
    },
  },
  predefinedStates: [PredefinedStates.disabled],
  getDefaultData() {
    return {
      properties: {
        layout: {
          hidden: true,
          direction: 'vertical',
          horizontalGap: 0,
          verticalGap: 0,
          disabled: false,
        },
        cell: {
          ratioWidth: true,
          hidden: true,
        },
        background: {
          prop: 'fill',
          type: FillType.solid,
          color: SystemsColor.DefaultWhiteFillColor,
          disabled: false,
        },
        fill2: {
          prop: 'fill',
          name: i18n('property.propertyNames.checkedBgcolor'),
          type: FillType.solid,
          color: SystemsColor.DefaultWhiteFillColor,
          disabled: false,
        },
        separator: {
          thickness: 1,
          color: SystemsColor.DefaultSelectColor,
          disabled: true,
        },
        separatorRatio: {
          prop: 'number',
          type: 'number',
          name: i18n('property.propertyNames.separatorRatio'),
          value: {
            value: 100,
            unit: '%',
          },
        } as NumberProperty,
        textStyle: {
          disabled: false,
          color: SystemsColor.DefaultTextColor,
          ...DEFAULT_TEXT_STYLE,
        },
        textStyleChecked: {
          prop: 'textStyle',
          name: i18n('property.propertyNames.checkedText'),
          color: SystemsColor.DeepBlueColor,
          disabled: false,
          ...DEFAULT_TEXT_STYLE,
        },
        fillChecked: {
          prop: MarkerStripPropertyName, //fill
          name: i18n('property.propertyNames.checkedUnderbar'),
          type: FillType.solid,
          color: SystemsColor.DeepBlueColor,
          disabled: false,
          positionMode: MarkerStripPositionMode.Right,
          lengthRatio: 80,
        } as IMarkerStrip,
        iconStyle: {
          isShow: true,
          iconPosition: IconPositionMode.Left,
          name: i18n('property.component.tree.treeNode'),
          prop: VerticalTabsIconStylePropertyName,
          iconSize: DEFAULT_ICON_SIZE,
          iconDefaultStyle: {
            name: i18n('property.propertyNames.defaultColor'),
            prop: 'icon',
            color: SystemsColor.DefaultTextColor,
            fontWeightHidden: true,
          },
          iconSelectedStyle: {
            name: i18n('property.propertyNames.checkedColor'),
            prop: 'icon',
            color: SystemsColor.DeepBlueColor,
            fontWeightHidden: true,
          },
        },
        stroke: getDefaultStroke(),
        shadow: getDefaultShadow(),
        radius: getDeafultRadius(),
        border: getDefaultBorder(),
      },
    };
  },
};
