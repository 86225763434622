import * as React from 'react';

import mathUtils from '@/utils/mathUtils';

import { IPoint, ISize } from '@/fbs/common/models/common';
import { LinePointType } from '@/fbs/rp/models/properties/line';
import { StrokeLineJoin } from '@/fbs/rp/models/properties/stroke';
import { ILineValue } from '@/fbs/rp/models/value';

import { StyleHelper } from '@/helpers/styleHelper';

export interface IArrowOption {
  pointType: LinePointType;
  linePoints: ILineValue;
  strokeInfo: StyleHelper.ISVGStroke;
  isEnd: boolean;
}

export interface IArrowMarkerInfo {
  el: JSX.Element | undefined;
  refX?: number;
  refY?: number;
  clipSize?: ISize;
}

enum ArrowDirection {
  Left,
  Right,
  Up,
  Down,
  None,
}

abstract class Arrow {
  protected type: string;
  protected strokeInfo: StyleHelper.ISVGStroke;
  protected strokeWidth: number;
  protected isEnd: boolean;
  protected lineWidth: number = 1;
  protected startPoint: IPoint;
  protected endPoint: IPoint;
  constructor(option: IArrowOption) {
    const {
      pointType,
      linePoints: { startPoint, endPoint },
      strokeInfo,
      isEnd,
    } = option;
    this.type = pointType;
    this.strokeInfo = strokeInfo;
    this.strokeWidth = strokeInfo.strokeWidth || 0;
    this.isEnd = isEnd;
    this.startPoint = { ...startPoint };
    this.endPoint = { ...endPoint };
  }

  get size(): number {
    const list = [10, 7]; // 用于width数值为1， 2 时，size 的调整
    const { strokeWidth } = this;
    return (strokeWidth && (strokeWidth < 6 ? list[strokeWidth - 1] || 6 : 6) * strokeWidth) || 0;
  }

  get direction(): ArrowDirection {
    const { angle } = this;
    const directionWithAngle = {
      left: 180,
      right: 0,
      top: 270,
      bottom: 90,
    };
    if (directionWithAngle.left === angle) {
      return ArrowDirection.Left;
    }
    if (directionWithAngle.right === angle) {
      return ArrowDirection.Right;
    }
    if (directionWithAngle.top === angle) {
      return ArrowDirection.Up;
    }
    if (directionWithAngle.bottom === angle) {
      return ArrowDirection.Down;
    }
    return ArrowDirection.None;
  }

  get refX(): number {
    return this.startPoint.x;
  }

  get refY(): number {
    return this.startPoint.y;
  }

  get offsetNum(): number {
    return this.calcOffset(this.strokeWidth);
  }

  get offsetX(): number {
    return 0;
  }

  get offsetY(): number {
    // 现阶段只做了正向的箭头处理，固定为4个反向
    if ([ArrowDirection.Right, ArrowDirection.Up].includes(this.direction)) {
      return this.offsetNum;
    }
    if ([ArrowDirection.Left, ArrowDirection.Down].includes(this.direction)) {
      return 0 - this.offsetNum;
    }
    return 0;
  }

  get diff(): number {
    return this.refX * this.strokeWidth;
  }

  get angle(): number {
    const { startPoint, endPoint } = this;
    return (Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x) * (180 / Math.PI) + 360) % 360;
  }

  get transform(): string {
    return `translate(${this.refX}, ${this.refY}) rotate(${this.angle}) translate(${this.offsetX}, ${
      -this.size / 2 + this.offsetY
    })`;
  }

  get clipSize(): ISize {
    return this.getClipSizeWithDirection(this.size - 1);
  }

  public calcOffset(size: number) {
    return (size % 2) / 2;
  }

  public getClipSizeWithDirection(offset: number) {
    const newSize: ISize = { width: 0, height: 0 };
    switch (this.direction) {
      case ArrowDirection.Left:
        newSize.width = 0 - offset;
        break;
      case ArrowDirection.Right:
        newSize.width = offset;
        break;
      case ArrowDirection.Up:
        newSize.height = 0 - offset;
        break;
      case ArrowDirection.Down:
        newSize.height = offset;
        break;
      default:
    }
    return newSize;
  }

  abstract get path(): string;
  abstract getInfo(): IArrowMarkerInfo;
}

class SolidArrow extends Arrow {
  get path(): string {
    const { size } = this;
    return `M0 ${size / 2}  L${size} 0 L${size * 0.75} ${size / 2} L${size} ${size}z`;
  }

  get clipSize(): ISize {
    return this.getClipSizeWithDirection(this.strokeWidth);
  }

  getInfo() {
    const { stroke, strokeLinejoin } = this.strokeInfo;
    return {
      el: (
        <path
          d={this.path}
          stroke={stroke}
          fill={stroke}
          strokeWidth={0}
          strokeLinejoin={strokeLinejoin === StrokeLineJoin.Round ? 'round' : 'miter'}
          transform={this.transform}
        />
      ),
      clipSize: this.clipSize,
    };
  }
}

class HollowArrow extends Arrow {
  get path(): string {
    const { size } = this;
    // stroke绘制，需要处理外边距产生的大小，加上边距偏移
    return `M${size + this.strokeWidth} 0 L${this.strokeWidth} ${size / 2} L${size + this.strokeWidth} ${size}`;
  }

  get clipSize(): ISize {
    return this.getClipSizeWithDirection(this.strokeWidth);
  }

  getInfo() {
    const { stroke, strokeLinejoin, strokeLinecap } = this.strokeInfo;
    return {
      el: (
        <path
          d={this.path}
          stroke={stroke}
          fill="none"
          strokeWidth={this.strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
          transform={this.transform}
        />
      ),
      clipSize: this.clipSize,
    };
  }
}

class SolidDot extends Arrow {
  get path(): string {
    const r = this.size / 2;
    return `
      M 0,${r}
      a ${r},${r} 0 1,0 ${r * 2},0 
      a ${r},${r} 0 1,0 ${-r * 2},0     
    `;
  }

  get clipSize(): ISize {
    return this.getClipSizeWithDirection(this.strokeWidth);
  }
  getInfo() {
    const {
      strokeInfo: { stroke },
    } = this;
    return {
      el: <path d={this.path} stroke="none" fill={stroke} transform={this.transform} />,
      clipSize: this.clipSize,
    };
  }
}

class HollowDot extends Arrow {
  get path(): string {
    const halfLineWidth = this.strokeWidth / 2;
    const r = this.size / 2 - halfLineWidth;
    return `
      M ${halfLineWidth},${r + halfLineWidth}
      a ${r},${r} 0 1,0 ${r * 2},0 
      a ${r},${r} 0 1,0 ${-r * 2},0
    `;
  }
  getInfo() {
    const {
      strokeInfo: { stroke },
      strokeWidth,
    } = this;
    return {
      el: <path d={this.path} stroke={stroke} strokeWidth={strokeWidth} fill="none" transform={this.transform} />,
      clipSize: this.clipSize,
    };
  }
}

class SolidCube extends Arrow {
  get path(): string {
    return '';
  }

  get offsetNum(): number {
    return this.calcOffset(this.size);
  }

  getInfo() {
    const {
      size,
      strokeInfo: { stroke, strokeLinejoin },
    } = this;
    return {
      el: (
        <rect
          x={0}
          y={0}
          width={size}
          height={size}
          fill={stroke}
          stroke="none"
          strokeLinejoin={strokeLinejoin}
          transform={this.transform}
        />
      ),
      clipSize: this.clipSize,
    };
  }
}

class SolidTriangle extends Arrow {
  get path(): string {
    const { size } = this;
    return `M0 ${size / 2} L${size} 0 L${size} ${size}z`;
  }

  get clipSize(): ISize {
    return this.getClipSizeWithDirection(this.strokeWidth);
  }

  getInfo() {
    const { stroke } = this.strokeInfo;
    return {
      el: <path d={this.path} stroke="none" fill={stroke} transform={this.transform} />,
      clipSize: this.clipSize,
    };
  }
}

class HollowTriangle extends Arrow {
  get path(): string {
    const { size } = this;
    // stroke绘制，需要处理外边距产生的大小，加上边距偏移
    return `M${this.strokeWidth} ${size / 2} L${size + this.strokeWidth} 0 L${size + this.strokeWidth} ${size}z`;
  }

  get clipSize(): ISize {
    return this.getClipSizeWithDirection(this.size);
  }

  getInfo() {
    const { stroke, strokeLinejoin } = this.strokeInfo;
    return {
      el: (
        <path
          d={this.path}
          fill="none"
          stroke={stroke}
          strokeWidth={this.strokeWidth}
          strokeLinejoin={strokeLinejoin}
          transform={this.transform}
        />
      ),
      clipSize: this.clipSize,
    };
  }
}

class SolidDiamond extends Arrow {
  get path(): string {
    const { size } = this;
    return `M0,${size / 2} L${size / 2},${size * 0.1} L${size},${size / 2} L${size / 2},${size * 0.9}z`;
  }

  get clipSize(): ISize {
    return this.getClipSizeWithDirection(this.strokeWidth);
  }

  getInfo() {
    const { stroke } = this.strokeInfo;
    return {
      el: <path d={this.path} stroke="none" fill={stroke} transform={this.transform} />,
      clipSize: this.clipSize,
    };
  }
}

class HollowDiamond extends Arrow {
  private get adjustOffset(): number {
    return (mathUtils.sqrt(2) / 2) * this.size * 0.1;
  }

  private get adjustedSize(): number {
    return this.size - this.adjustOffset * 2;
  }

  get path(): string {
    const { adjustOffset, adjustedSize } = this;
    // stroke绘制，需要处理外边距产生的大小，加上边距偏移
    return (
      `M${this.strokeWidth},${adjustedSize / 2}` +
      ` L${adjustedSize / 2 + this.strokeWidth},${adjustOffset}` +
      ` L${adjustedSize + this.strokeWidth},${adjustedSize / 2}` +
      ` L${adjustedSize / 2 + this.strokeWidth},${adjustedSize - adjustOffset}z`
    );
  }

  get transform(): string {
    return `translate(${this.refX}, ${this.refY}) rotate(${this.angle}) translate(${this.offsetX}, ${
      -this.adjustedSize / 2 + this.offsetY
    })`;
  }

  get clipSize(): ISize {
    return this.getClipSizeWithDirection(this.adjustedSize);
  }

  getInfo() {
    const { stroke, strokeLinejoin } = this.strokeInfo;
    return {
      el: (
        <path
          d={this.path}
          fill="none"
          stroke={stroke}
          strokeWidth={this.strokeWidth}
          strokeLinejoin={strokeLinejoin}
          transform={this.transform}
        />
      ),
      clipSize: this.clipSize,
    };
  }
}

class HollowCube extends Arrow {
  get path(): string {
    return '';
  }

  // stroke绘制，需要处理外边距产生的大小，加上边距偏移
  getInfo() {
    const {
      size,
      strokeInfo: { stroke, strokeLinejoin },
      strokeWidth,
    } = this;
    return {
      el: (
        <rect
          x={strokeWidth / 2}
          y={0}
          width={size}
          height={size}
          stroke={stroke}
          strokeWidth={strokeWidth}
          fill="none"
          strokeLinejoin={strokeLinejoin}
          transform={this.transform}
        />
      ),
      clipSize: this.clipSize,
    };
  }
}

class Perpendicular extends Arrow {
  get path(): string {
    return '';
  }

  get clipSize(): ISize {
    return this.getClipSizeWithDirection(this.strokeWidth || 0);
  }

  getInfo() {
    const {
      size,
      strokeInfo: { stroke, strokeLinecap },
      strokeWidth,
    } = this;
    return {
      el: (
        <line
          x1={strokeWidth / 2}
          x2={strokeWidth / 2}
          y1={0}
          y2={size}
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          transform={this.transform}
        />
      ),
      clipSize: this.clipSize,
    };
  }
}

/**
 * 根据传入的箭头class 动态创建实例
 *
 * @param {{ new (type: string, strokeInfo: StyleHelper.ISVGStroke, isEnd: boolean): T }} c
 * @param {string} type
 * @param {StyleHelper.ISVGStroke} strokeInfo
 * @param {boolean} isEnd
 * @returns {*}
 */
function genericArrowCreator<T>(c: { new (option: IArrowOption): T }, option: IArrowOption): T {
  return new c(option);
}

const arrowList = {
  [LinePointType.none]: undefined,
  [LinePointType.solidArrow]: SolidArrow,
  [LinePointType.hollowArrow]: HollowArrow,
  [LinePointType.solidTriangle]: SolidTriangle,
  [LinePointType.hollowTriangle]: HollowTriangle,
  [LinePointType.solidDot]: SolidDot,
  [LinePointType.hollowDot]: HollowDot,
  [LinePointType.solidCube]: SolidCube,
  [LinePointType.hollowCube]: HollowCube,
  [LinePointType.solidDiamond]: SolidDiamond,
  [LinePointType.hollowDiamond]: HollowDiamond,
  [LinePointType.perpendicular]: Perpendicular,
};

const getArrowInstance = (option: IArrowOption) => {
  const { pointType, strokeInfo } = option;
  if (!pointType || pointType === LinePointType.none || strokeInfo.strokeWidth === 0) {
    return undefined;
  }
  const arrowClass = arrowList[pointType];
  return genericArrowCreator(arrowClass, option);
};

export const getArrowInfoByType = (option: IArrowOption): IArrowMarkerInfo | undefined => {
  const firstClass = getArrowInstance(option);
  return firstClass?.getInfo();
};
