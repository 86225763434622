import PropertyBase from './base';
import { PureColor } from './color';

export enum DefaultCollapseIconType {
  Arrow,
  Plus,
  Triangle,
}

export default interface ITree extends PropertyBase {
  isShow: boolean,
  chooseExpandType?: DefaultCollapseIconType,
  iconColor: PureColor,
  iconSize: number
  checkedIconColor?: PureColor,
}

export const TreePropertyName = 'tree';


/*
export const defaultSelectIcon = [
  {
    id: DefaultCollapseIconType.Arrow,
    text: i18n('property.component.tree.arrow'),
    value: [{ iconCode: 59833, fontName: 'lightIconFont' }, { iconCode: 59838, fontName: 'lightIconFont' }],
  },
  {
    id: DefaultCollapseIconType.Plus,
    text: i18n('property.component.tree.plus'),
    value: [{ iconCode: 60034, fontName: 'lightIconFont' }, { iconCode: 60031, fontName: 'lightIconFont' }],
  },
  {
    id: DefaultCollapseIconType.Triangle,
    text: i18n('property.component.tree.triangle'),
    value: [{ iconCode: 59897, fontName: 'lightIconFont' }, { iconCode: 59895, fontName: 'lightIconFont' }],
  },
];
 */