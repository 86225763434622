import * as React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';

import ScrollBars from '../ScrollBars';
import { ComponentTheme } from '../common';

import './index.scss';

export interface IListItem {
  id: string | number;
  text: string;
  showSeparator?: boolean;
  disabled?: boolean;
  itemClassName?: string;

  [key: string]: any;
}

export interface IListProp {
  data: Array<IListItem>;
  onSelect: (item: IListItem, index?: number) => void;
  selectedIndex?: number;
  selectedAutoEnd?: boolean; // 选中条目不在可视范围内时，是否自动滚动到末尾
  maxRowCount?: number;
  itemHeight?: number;
  autoHeight?: boolean;
  allowDrag?: boolean;
  className?: string;
  maxWidth: number;
  itemRender?: (data: IListItem, selected: boolean) => React.ReactNode;
  itemRenderBody?: (data: IListItem, index: number) => React.ReactNode; // 历史原因，需要完全独立样式，使用这个
  // onDragStart?: (e: React.DragEvent, data: IListItem, index: number) => void;
  // onDragOver?: (e: React.DragEvent, index: number) => void;
  // onDrop?: (e: React.DragEvent, index: number) => void;
  theme?: ComponentTheme;
  allowHover?: boolean;
  scrollSubtractDistanced?: number; // 判断使用-滚动时需要减去的高度
  /**
   *
   * @param {number} sourceIndex 拖拽源在数组中的原始索引
   * @param {number} target 拖拽源在放置后最终在数组的结果索引
   * @description 比如从索引0的位置拖拽到索引2的位置放置时，则最终结果是索引0的对象会放到索引2的对象处，索引2及之前的对象在排除原来索引0的对象后，往前挤
   */
  onAfterDrop?: (sourceIndex: number, target: number) => void;
  onScrollBottom?: (done: () => void) => void;
  scrollBottomRender?: () => React.ReactNode;
}

type Direction = 'before' | 'after';

export interface IListState {
  itemHeight?: number;

  dragOverItem?: IListItem;
  dragItem?: IListItem;
  dragOverDirection?: Direction;
  dragOverIndicatorPoint?: number;

  data: IListItem[];
}

class List extends React.Component<IListProp, IListState> {
  scrollNode: React.RefObject<ScrollBars> = React.createRef();
  contentNode: React.RefObject<HTMLDivElement> = React.createRef();
  private dragSource?: IListItem;
  private scrollBottomDone?: boolean = true;

  static defaultProps: Partial<IListProp> = {
    selectedIndex: -1,
    itemHeight: 30,
    maxWidth: 200,
  };

  constructor(props: IListProp) {
    super(props);
    this.state = {
      itemHeight: props.itemHeight,
      data: props.data.length <= 25 ? props.data : props.data.slice(0, 25),
    };
  }

  componentDidMount() {
    if (this.state.data !== this.props.data) {
      this.setState({ data: this.props.data }, () => {
        this.afterMount();
      });
    } else {
      this.afterMount();
    }
  }

  private afterMount() {
    this.doCalculateItemHeight();
    this.doAutoScrollToSelected(this.props.selectedIndex || 0);
  }

  private calcHeightWithSeparator(height: number) {
    const { data } = this.props;
    if (data) {
      return data.reduce((prev, item) => {
        // 12是分隔符的高度
        if (item.showSeparator) prev += 12;
        return prev;
      }, height);
    }
    return height;
  }

  componentDidUpdate() {
    this.doCalculateItemHeight();
  }

  UNSAFE_componentWillReceiveProps(newProps: IListProp) {
    if (newProps.selectedIndex !== this.props.selectedIndex) {
      this.doAutoScrollToSelected(newProps.selectedIndex || 0);
    }
  }

  doAutoScrollToSelected = (index: number) => {
    if (this.scrollNode.current && this.contentNode.current) {
      const oldTop = this.scrollNode.current.getScrollTop();
      const { itemHeight } = this.state;
      const { selectedAutoEnd } = this.props;
      const ih = itemHeight || 24;
      const itemTop = ih * index;
      const height = this.scrollNode.current.getClientHeight();
      if (oldTop > itemTop || oldTop + height < itemTop + ih) {
        this.scrollNode.current.scrollTop(selectedAutoEnd ? itemTop - height + ih : itemTop);
      }
    }
  };

  doCalculateItemHeight = () => {
    let { itemHeight } = this.props;
    if (!itemHeight && this.contentNode.current) {
      if (this.contentNode.current.childElementCount) {
        const dom = this.contentNode.current.firstChild as HTMLElement;
        const { offsetHeight } = dom;
        itemHeight = Math.round(offsetHeight);
      } else {
        itemHeight = 0;
      }
    }
    if (this.state.itemHeight !== itemHeight) {
      this.setState({ itemHeight });
    }
  };

  doScrollDone = () => {
    this.scrollBottomDone = true;
  };

  defaultItemRender = (data: IListItem, selected: boolean) => {
    const maxWidth = this.props.maxWidth;
    const { id, text } = data;
    let lineHeight: string | undefined = undefined;
    if (this.props.itemHeight) {
      lineHeight = `${this.props.itemHeight}px`;
    }
    return (
      <div className={classnames('default-list-item', { selected })}>
        <label style={{ maxWidth: maxWidth - 40, lineHeight }}>{text || id}</label>
      </div>
    );
  };

  get contentWidth(): number {
    if (this.contentNode.current) {
      let width = 0;
      const childNodes = this.contentNode.current.children;
      for (let i = 0, c = childNodes.length; i < c; i++) {
        const el = childNodes[i] as HTMLElement;
        const dom = (childNodes[i] as HTMLElement).firstChild! as HTMLElement;
        if (dom?.nodeType === Node.ELEMENT_NODE) {
          width = Math.max(width, dom.offsetWidth);
        } else {
          width = Math.max(width, el.offsetWidth);
        }
        width = Math.min(width, this.props.maxWidth || width);
      }
      return width;
    }
    return 0;
  }

  handleDragStart = (item: IListItem, e: React.DragEvent) => {
    if (!this.props.allowDrag) {
      return;
    }
    e.stopPropagation();
    this.dragSource = item;
    e.dataTransfer.setData('custom-list-item-format', JSON.stringify(item));
    e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.effectAllowed = 'move';
    this.setState({ dragItem: item });
  };

  handleDragEnd = (e: React.DragEvent) => {
    e.stopPropagation();
    this.dragSource = undefined;
    this.setState({
      dragItem: undefined,
      dragOverItem: undefined,
      dragOverDirection: undefined,
      dragOverIndicatorPoint: undefined,
    });
  };

  handleDragOver = (item: IListItem, e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const { offsetY } = e.nativeEvent;
    const height = (e.target as HTMLElement).offsetHeight;
    let direction: Direction = 'after';
    if (offsetY < height / 2) {
      direction = 'before';
    }
    const dom = e.currentTarget as HTMLElement;
    const { offsetTop, offsetHeight } = dom;
    this.setState({
      dragOverItem: item,
      dragOverDirection: direction,
      dragOverIndicatorPoint: direction === 'before' ? offsetTop : offsetTop + offsetHeight,
    });
  };

  doFixTargetIndex = (originIndex: number, targetIndex: number) => {
    const { dragOverDirection } = this.state;
    if (dragOverDirection === 'after') {
      targetIndex += 1;
    }
    if (targetIndex > originIndex) {
      targetIndex -= 1;
    }
    return targetIndex;
  };

  doDrop = (targetItem: IListItem) => {
    const { data, onAfterDrop } = this.props;
    const originIndex = data.indexOf(this.dragSource!);
    const targetIndex = this.doFixTargetIndex(originIndex, data.indexOf(targetItem));

    if (targetIndex !== originIndex) {
      onAfterDrop && onAfterDrop(originIndex, targetIndex);
    }
  };

  handleDrop = (item: IListItem, e: React.DragEvent) => {
    e.stopPropagation();
    this.doDrop(item);
  };

  handleContainerDrop = (e: React.DragEvent) => {
    e.stopPropagation();
    const { dragOverItem } = this.state;
    if (!dragOverItem) {
      return;
    }
    this.doDrop(dragOverItem);
  };

  handleContainerDragOver = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    // TODO 20230817 Mxj 解决快速拖拽到末尾空白处问题
    const dom = this.contentNode.current;
    const { data } = this.props;
    const { dragOverItem, dragOverDirection } = this.state;

    if (!dom || !data.length) {
      return;
    }
    if (
      (dragOverItem === data[0] && dragOverDirection === 'before') ||
      (dragOverItem === data[data.length - 1] && dragOverDirection === 'after')
    ) {
      return;
    }
    const { top, bottom, height } = dom.getBoundingClientRect();
    const { pageY } = e;
    if (pageY <= top) {
      this.setState({ dragOverItem: data[0], dragOverIndicatorPoint: 0, dragOverDirection: 'before' });
    } else if (pageY >= bottom) {
      this.setState({
        dragOverItem: data[data.length - 1],
        dragOverIndicatorPoint: height,
        dragOverDirection: 'after',
      });
    }
  };

  handleItemClick = (item: IListItem, index: number) => {
    const { onSelect } = this.props;
    onSelect && onSelect(item, index);
  };

  handleScroll = () => {
    const current = this.scrollNode.current;
    const { onScrollBottom, scrollSubtractDistanced } = this.props;
    if (!current) {
      return;
    }
    if (!this.scrollBottomDone) {
      return;
    }
    const scrollHeight = current.getScrollHeight();
    const clientHeight = current.getClientHeight();
    const scrollTop = current.getScrollTop();
    if (clientHeight + scrollTop > scrollHeight - (scrollSubtractDistanced || 0)) {
      this.scrollBottomDone = false;
      onScrollBottom?.(this.doScrollDone);
    }
  };

  renderItem() {
    const { data, selectedIndex, itemRender, allowDrag, itemRenderBody } = this.props;
    const { itemHeight, dragOverDirection, dragItem, dragOverIndicatorPoint } = this.state;
    const render = itemRender || this.defaultItemRender;
    const style: React.CSSProperties = {};
    if (itemHeight) {
      style.height = itemHeight;
      style.lineHeight = `${itemHeight}px`;
    }
    return (
      <div ref={this.contentNode} className="list-content">
        {data &&
          data.map((item, index) => (
            <React.Fragment key={`${item.id}-${index}`}>
              {itemRenderBody ? (
                itemRenderBody(item, index)
              ) : (
                <div
                  draggable={allowDrag}
                  className={classnames(
                    'list-item',
                    {
                      selected: index === selectedIndex,
                      disabled: item.disabled,
                      'drag-start': item === dragItem,
                    },
                    item.itemClassName,
                    dragOverDirection,
                  )}
                  style={style}
                  onClick={this.handleItemClick.bind(this, item, index)}
                  onDragStart={this.handleDragStart.bind(this, item)}
                  onDragOver={this.handleDragOver.bind(this, item)}
                  onDrop={this.handleDrop.bind(this, item)}
                  onDragEnd={this.handleDragEnd}
                >
                  {render(item, selectedIndex === index)}
                </div>
              )}
              {item.showSeparator && <div className="separator" />}
            </React.Fragment>
          ))}
        {!isUndefined(dragOverIndicatorPoint) && (
          <i className="drag-over-line" style={{ top: dragOverIndicatorPoint }} />
        )}
      </div>
    );
  }

  render() {
    const { maxRowCount, data, autoHeight, className, allowHover, theme, scrollBottomRender } = this.props;
    const { itemHeight } = this.state;
    let height: number | string;
    if (autoHeight && data) {
      height = this.calcHeightWithSeparator(itemHeight || 24 * data.length);
    } else {
      height = maxRowCount
        ? this.calcHeightWithSeparator(Math.min(maxRowCount, data ? data.length : 0) * (itemHeight || 24))
        : 'auto';
    }
    return (
      <div
        className={classnames('dsm-c-rp-list', className, theme, { 'allow-hover': allowHover })}
        style={{ height }}
        onDrop={this.handleContainerDrop}
        onDragOver={this.handleContainerDragOver}
      >
        <ScrollBars
          hiddenHorizontalScrollBar
          className="list-scroll"
          ref={this.scrollNode}
          onScroll={this.handleScroll}
        >
          {this.renderItem()}
          {scrollBottomRender?.()}
        </ScrollBars>
      </div>
    );
  }
}

export default List;
