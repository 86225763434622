import IArtboard from '@fbs/rp/models/artboard';
import { IPosition } from '@fbs/common/models/common';

import { get } from './helper';
import { appDataManager } from '../services/appDataManager';
import ErrorCode from '@consts/ErrorCode';

import * as artboardCommon from './common/artboard';

export async function getArtboardByID(artboardID: string): Promise<IArtboard> {
  const socketOffline = appDataManager.socketOffline;
  try {
    if (socketOffline) {
      return await appDataManager.getArtboardByID(artboardID);
    } else {
      return await getArtboardByID_XHR(artboardID);
    }
  } catch (e) {
    if (!socketOffline) {
      return await appDataManager.getArtboardByID(artboardID);
    }
    throw e;
  }
}

async function getArtboardByID_XHR(artboardID: string): Promise<IArtboard> {
  const artboard = await artboardCommon.getArtboardByID(artboardID);
  appDataManager.pushArtboard([artboard]);
  return artboard;
}

export async function getAllArtboardsByNodeID(
  nodeID: string,
  prototypeID?: string,
  isExample: boolean = false,
): Promise<IArtboard[]> {
  const socketOffline = appDataManager.socketOffline;
  try {
    if (socketOffline) {
      return await appDataManager.getAllArtboardsByNodeID(nodeID);
    } else {
      return await getAllArtboardsByNodeID_XHR(nodeID, prototypeID, isExample);
    }
  } catch (e) {
    if (appDataManager.socketSyncError) {
      return await appDataManager.getAllArtboardsByNodeID(nodeID);
    }
    if (!socketOffline && e.code !== ErrorCode.ERROR_MULTI_SIGN_IN) {
      return await appDataManager.getAllArtboardsByNodeID(nodeID);
    }
    throw e;
  }
}

async function getAllArtboardsByNodeID_XHR(
  nodeID: string,
  prototypeID?: string,
  isExample: boolean = false,
): Promise<IArtboard[]> {
  const artboards = await artboardCommon.getArtboardsByPageID(nodeID, isExample);
  if (!artboards || !artboards.length) {
    return appDataManager.getAllArtboardsByNodeID(nodeID);
  }
  appDataManager.updateArtboardWithNodeID(nodeID, artboards);
  return artboards;
}

// TODO  拆分后涉及到查询最新数据和历史版本数据
export async function getAllArtboardsByMainArtboardID(
  mainArtboardID: string,
  params: {
    moduleID?: string;
    snapshotID?: string;
    [key: string]: string | undefined;
  },
): Promise<IArtboard[]> {
  const { moduleID, ...other } = params;
  const artboards = await artboardCommon.getArtboardsByMainID(mainArtboardID, moduleID, other);
  if (artboards.length) {
    appDataManager.updateArtboardWithNodeID(artboards[0]._id, artboards);
  }
  return artboards;
}

// TODO  拆分后涉及到查询最新数据和历史版本数据
export async function getAllPreviewArtboardsByNodeID(nodeID: string, prototypeID?: string): Promise<IArtboard[]> {
  const artboards = await artboardCommon.getArtboardsByPageIDWithPreview(nodeID, prototypeID);
  appDataManager.pushArtboard(artboards);
  return artboards;
}

export async function cloneArtboard(nodeID: string, artboard: IArtboard, position: IPosition): Promise<string> {
  return await artboardCommon.cloneArtboard(nodeID, artboard, position);
}

export async function download(
  appID: string,
  artboardID: string,
  waterMark?: string,
  scale?: number,
): Promise<{
  URL: string;
  taskID?: string;
}> {
  return await get<{
    URL: string;
  }>(`/artboard/download/${appID}/${artboardID}?waterMark=${waterMark}${scale ? `&scale=${scale}` : ''}`);
}
// 请求失败之后再次请求的接口
export async function downloaded(
  taskID: string,
): Promise<{
  URL: string;
  taskID?: string;
}> {
  return await get<{
    URL: string;
  }>(`/artboard/downloaded/${taskID}`);
}
