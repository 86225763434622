import * as React from 'react';

import classnames from 'classnames';

import { getRealUrl } from '@libs/compoundComponents/CarouselChart/config';
import EditorContext from '@contexts/editor';
import { UIComponent } from '@editor/comps';

import { Radio } from '@dsm';

import './index.scss';

interface IValueItemProps {
  comp: UIComponent;
  index: number;
  selected?: boolean;
  checked?: boolean;
  hasInteraction?: boolean;
  atLeastOne?: boolean;
  hideDragger?: boolean;
  moveable?: boolean;
  onMoveItem: (oldIndex: number, newIndex: number) => void;
  onMouseEnter: (index: number) => void;
  onClick: (index: number) => void;
  onChecked: (index: number, checked: boolean) => void;
  onInteractionDragger: (e: React.MouseEvent, index: number) => void;
  onImageChange: (comp: UIComponent) => void;
}

interface IValueItemState {
  overTop?: boolean;
  overBottom?: boolean;
}

class ValueItem extends React.Component<IValueItemProps, IValueItemState> {
  static contextType = EditorContext;
  // @ts-ignore
  context: React.ContextType<typeof EditorContext>;

  constructor(props: IValueItemProps) {
    super(props);
    this.state = {};
  }

  handleDragStart = (e: React.DragEvent) => {
    e.stopPropagation();

    const { index } = this.props;
    e.dataTransfer.setData('drag-component-item', `${index}`);
    e.dataTransfer.setDragImage(e.target as HTMLElement, e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    e.dataTransfer.effectAllowed = 'move';
  };

  handleDragOver = (e: React.DragEvent) => {
    e.stopPropagation();
    const { types } = e.dataTransfer;
    if (types.length && types[0] === 'drag-component-item') {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';
      const dom = e.target as HTMLElement;
      const { clientHeight } = dom;
      const { offsetY } = e.nativeEvent;
      const overTop = offsetY < clientHeight / 2;
      const overBottom = !overTop;
      this.setState({ overTop, overBottom });
    }
  };

  handleDragLeave = (e: React.DragEvent) => {
    e.stopPropagation();
    this.setState({ overTop: false, overBottom: false });
  };

  handleDrop = (e: React.DragEvent) => {
    e.stopPropagation();

    const { overBottom, overTop } = this.state;
    this.setState({ overTop: false, overBottom: false });
    const data = e.dataTransfer.getData('drag-component-item');
    if (data) {
      const oldIndex = parseInt(data, 10);
      const { index, onMoveItem } = this.props;
      let newIndex = index;
      if (overTop) {
        if (newIndex >= oldIndex && newIndex - oldIndex <= 1) {
          return;
        }
      }
      if (overBottom) {
        if (newIndex <= oldIndex && oldIndex - newIndex <= 1) {
          return;
        }
      }
      if (oldIndex !== newIndex) {
        if (overBottom) {
          newIndex += 1;
        }
        onMoveItem(oldIndex, newIndex);
      }
    }
  };

  handleDragEnd = (e: React.DragEvent) => {
    e.dataTransfer.clearData('drag-component-item');
  };

  handleClick = () => {
    const { index, onClick } = this.props;
    onClick(index);
  };

  handleMouseEnter = () => {
    const { index, onMouseEnter } = this.props;
    onMouseEnter(index);
  };

  handleMouseLeave = () => {
    this.context.uiManager.projectTree?.refresh();
  };

  handleRadioClick = () => {
    const { checked, atLeastOne } = this.props;
    if (atLeastOne && !!checked) {
      return;
    }
    this.doSelectChange(!checked);
  };

  doSelectChange(checked: boolean) {
    const { index, onChecked } = this.props;
    onChecked(index, checked);
  }

  handleInteractionDraggerMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const { onInteractionDragger, index } = this.props;
    onInteractionDragger && onInteractionDragger(e, index);
  };

  handleImageChange = () => {
    this.props.onImageChange(this.props.comp);
  };

  renderValue() {
    const { comp } = this.props;
    return (
      <div className="value-item-value">
        <div className="img-box" onClick={this.handleImageChange}>
          <img src={getRealUrl(comp)} draggable={false} />
        </div>
      </div>
    );
  }

  renderSelect() {
    const { checked } = this.props;
    return <Radio checked={!!checked} onClick={this.handleRadioClick} />;
  }

  renderOperations() {
    const { hideDragger, hasInteraction } = this.props;
    if (hideDragger) {
      return null;
    }
    return (
      <div
        className={classnames('interaction-dragger', { interaction: hasInteraction })}
        onMouseDown={this.handleInteractionDraggerMouseDown}
      />
    );
  }

  render() {
    const { selected, moveable, hasInteraction } = this.props;
    const { overBottom, overTop } = this.state;

    return (
      <div
        draggable={moveable}
        className={classnames('carousel-chart-value-item', {
          'over-top': overTop,
          'over-bottom': overBottom,
          'interaction-flag': hasInteraction,
          selected,
        })}
        onDragStart={this.handleDragStart}
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDrop}
        onDragEnd={this.handleDragEnd}
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {this.renderSelect()}
        {this.renderValue()}
        {this.renderOperations()}
      </div>
    );
  }
}

export default ValueItem;
