import i18n from '@i18n';
import { UIContainerComponent } from '@editor/comps';
import { IPathValue } from '@fbs/rp/models/value';
import { IComponentData } from '@fbs/rp/models/component';
import { ISize } from '@fbs/common/models/common';
import { scalePath } from '@helpers/pathHelper';
import { SpriteThumb } from '@/consts/spriteIcons';

import { IComponentItem } from '../types';
import { getDefaultDataFromTemplate } from '../helper';

export const CallOutCfg: IComponentItem = {
  type: 'callOut',
  name: `${i18n('resource.components.callOut')}`,
  thumb: {
    spriteIconClass: SpriteThumb.CallOut.className,
    dragPosition: SpriteThumb.CallOut.position,
  },
  isTextComp: true,
  predefinedStates: ['hover', 'pressed', 'disabled'],
  editor: {
    // eslint-disable-next-line no-unused-vars
    onValidateAllowEditor: () => {
      return 'value';
    },
  },
  initialization: (appType: string, data: IComponentData, container?: UIContainerComponent, size?: ISize) => {
    const {
      size: { width, height },
    } = data;
    if (size) {
      const scale = {
        x: size.width / width,
        y: size.height / height,
      };
      const value = data.value as IPathValue;
      data.value = scalePath(value, scale);
    }
  },
  shortCut: 'C',
  template: `{
    type: @@C.Path,
    fixContent: true,
    size: {
      width: 30,
      height: 30,
      lockedRatio: true,
    },
    layout: {
      vertical: @@VerticalAlign.Auto,
      horizontal: @@HorizontalAlign.Auto,
      fixedWidth: false,
      fixedHeight: false,
      auto: true,
      responsive: true
    },
    properties: {
      fill: {
        disabled: false,
        type: @@FillType.solid,
        color: @@SystemColors.DeepBlueColor,
      },
      stroke: {
        thickness: 2,
        color: { r: 0, g: 157, b: 255, a: 1 },
        disabled: true
      },
      textFormat: {
        disabled: false,
        fontFamily: 'Microsoft YaHei',
        fontSize: 14,
        textAlign: @@TextAlign.center,
        color: { r: 255, g: 255, b: 255, a: 1 },
        fontStyle: { bold: true, italic: false, underline: false, strike: false },
        letterSpace: 0,
        wrap: true,
        isMulti: true,
      },
      shadow: {
        disabled: false,
        x: 0,
        y: 2,
        blur: 4,
        color: {r: 0, g: 0, b: 0, a: 0.3}
      },
    },
    text: '1',
    value: {
      data: [
        {      
          point: { x: 15, y: 0 },
          handleIn: { x: -8.399999999999965, y: 0 },
          handleOut: { x: 8, y: 0 }
        },
        {
          point: { x: 30, y: 15 },
          handleIn: { x: 0, y: -8.400000000000027 },
          handleOut: { x: 0, y: 8 }
        },
        {
          point: { x: 15, y: 30 },
          handleIn: { x: 8, y: 0 },
          handleOut: { x: -8.399999999999965, y: 0 }
        },
        {
          point: { x: 0, y: 15 },
          handleIn: { x: 0, y: 8 },
          handleOut: { x: 0, y: -8.400000000000027 }
        }
      ],
      closed: true
    }
  }`,
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
};

const defaultData = getDefaultDataFromTemplate(CallOutCfg);
