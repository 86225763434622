import * as React from 'react';

import './index.scss';

export interface ITabPanelitemProp {
  children: any;
}

const TabPanelItem: React.FC<ITabPanelitemProp> = (props: ITabPanelitemProp) => {
  const { children } = props;
  return (
    <>
      {children}
    </>
  );
};

export default TabPanelItem;