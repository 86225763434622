import * as React from 'react';
import classnames from 'classnames';
import { isUndefined, memoize } from 'lodash';

import { parseColorToString } from '@utils/graphicsUtils';
import { TransparentColor, WhiteColor } from '@consts/colors';

import i18n from '@i18n';
import { UIComponent, UIContainerComponent } from '@editor/comps';
import { makeUIComponent } from '@editor/comps/factory';

import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { makeCommonComponent } from '../../helper';
import { StyleHelper } from '@helpers/styleHelper';
import { contenPanelLayoutLineLimit } from '@helpers/contentPanelv2Helper';
import { SpriteThumb } from '@consts/spriteIcons';
import { FillType } from '@fbs/rp/models/properties/fill';
import { DefaultStrokeColor } from '@consts/colors';
import { ScrollMode } from '@consts/enums/scrollMode';
import { DefaultBorder } from '@/consts/border';
import { defaultDisabledStroke } from '@/consts/stroke';
import Component from '@libs';
import { Color } from '@fbs/rp/models/properties/color';
import { IComponentData } from '@/fbs/rp/models/component';

import { IComponentItem, ICustomCompositeProps } from '../../types';
import { CContentPanelV2 } from '../../constants';
import Background from '../../basic/common/Background';

import * as tipIamge from '@assets/image/contentPanel-tips@2x.png';

import './index.scss';
import './animation.scss';

export function makeContentPanelV2(id: string) {
  return makeCommonComponent(id, CContentPanelV2, {
    size: { width: 300, height: 300 },
    sealed: true,
    properties: {
      container: {
        scroll: true,
        scrollMode: ScrollMode.Both,
        showScroll: true,
      },
      stroke: {
        thickness: 1,
        color: DefaultStrokeColor,
        disabled: true,
      },
      contentPanelLayout: {
        layoutLine: contenPanelLayoutLineLimit,
      },
      border: DefaultBorder,
    },
    value: [],
  });
}

function changeContentPanelSelect(
  group: UIContainerComponent,
  comp: UIComponent,
  selected: boolean,
): ArtboardPatches | null {
  let patches: ArtboardPatches = { do: {}, undo: {} };
  const selComp = group.components.find((comp) => comp.selected);
  if (!selected || selComp?.id === comp.id) {
    return null;
  }

  group.components.forEach((item) => {
    if (item.selected) {
      patches.do[item.id] = [Ops.replace('/selected', false)];
      patches.undo[item.id] = [Ops.replace('/selected', true)];
    }
    if (item.id === comp.id) {
      patches.do[item.id] = [Ops.replace('/selected', true)];
      patches.undo[item.id] = [Ops.replace('/selected', false)];
    }
  });

  return patches;
}

function onContentPanelV2Clone(comp: IComponentData, idMaps: { [key: string]: string }) {
  comp.components?.forEach((child) => (child.value = idMaps[child.value]));
  comp.value = (comp.value as string[]).map((item) => idMaps[item]);
}

export const ContentPanelV2Config: IComponentItem = {
  type: CContentPanelV2,
  name: i18n('resource.components.contentPanelV2'),
  thumb: {
    spriteIconClass: SpriteThumb.ContentPanelV2.className,
    dragPosition: SpriteThumb.ContentPanelV2.position,
  },
  editor: {
    onChildSelectedChange: changeContentPanelSelect,
    onClone: onContentPanelV2Clone,
  },
};

class ContentPanelV2 extends React.Component<ICustomCompositeProps> {
  constructor(props: ICustomCompositeProps) {
    super(props);
  }

  renderChildComponent = (comp: UIComponent, scale: number) => {
    if (comp.isContainer) {
      return (comp as UIContainerComponent).components.map((comp) => {
        return this.renderComponent(comp, scale);
      });
    }
    return null;
  };

  renderComponent = (comp: UIComponent, scale: number = 1) => {
    return (
      <Component key={comp.id} comp={comp} revision={comp.chainedVersion()} scale={scale}>
        {this.renderChildComponent(comp, scale)}
      </Component>
    );
  };

  renderSelectedFragmentContent() {
    const { comp, children, isPreview } = this.props;
    const selectFragment = comp.components.find((compItem) => compItem.selected);
    const selectFragmentId = selectFragment?.value;
    if (!selectFragment) {
      return null;
    }
    const matchFragment = comp.document?.artboardsFragments?.find(
      (fragment) =>
        fragment.$data.ownerID && fragment.$data.ownerID === comp.id && fragment.artboardID === selectFragmentId,
    );
    if (!matchFragment) {
      return null;
    }
    //保留panelcomp,将匹配的matchFragment填充过去，防止丢失selectd next-comp样式等的选中状态
    const renderChildren = makeUIComponent(comp.toJSON()) as UIContainerComponent;
    renderChildren.components = [...(matchFragment as UIContainerComponent).components];
    //bg-style
    const { size } = comp;
    const artboardBGColor = matchFragment.$data.background?.disabled
      ? TransparentColor
      : (matchFragment.$data.background?.color as Color) || WhiteColor;
    const contentStyle = memoize(
      (): React.CSSProperties => ({
        backgroundColor: artboardBGColor ? parseColorToString(artboardBGColor) /*使用rgba值*/ : 'transparent',
        width: size.width,
        height: size.height,
      }),
    )();
    return (
      <div data-id={matchFragment.artboardID} className="content" style={contentStyle}>
        {isPreview ? children : this.renderChildComponent(renderChildren, 1)}
      </div>
    );
  }

  renderEmptyContent() {
    return (
      <div className="content-panel-v2-tips-wrap">
        <div className="content-panel-v2-tips">
          <img src={tipIamge} />
          <p className="title">{i18n('tips.contentPanelV2TipsTitle')}</p>
          <p className="content">{i18n('tips.contentPanelV2TipsContent0')}</p>
          <p className="content">{i18n('tips.contentPanelV2TipsContent1')}</p>
        </div>
      </div>
    );
  }

  render() {
    const { comp, isPreview } = this.props;
    const { size, properties } = comp;
    const { stroke } = properties;
    //不允许编辑，默认提供给Background使用
    const fill = properties.fill ?? {
      type: FillType.solid,
      color: '#fff',
      disabled: true,
    };
    const showPlaceBorder = !isPreview && fill?.disabled && stroke?.disabled;
    const showStroke = showPlaceBorder ? defaultDisabledStroke : stroke;
    const showProperties = { ...properties, stroke: showStroke };
    // 默认边框不受border属性影响
    if (stroke?.disabled) {
      delete showProperties.border;
    }
    const styleParser = StyleHelper.initCSSStyleParser(showProperties);
    const opacity = isUndefined(comp.opacity) ? 1 : comp.opacity / 100;
    const isTransparent = opacity === 0;
    const transition = comp.getTransition();
    return (
      <div
        className={classnames('lib-comp-content-panel-v2', {
          preview: isPreview,
          editing: !isPreview,
        })}
        style={{
          opacity,
          transition,
          ...size,
          ...styleParser.getRadiusStyle(size),
          ...styleParser.getStrokeStyle(),
        }}
      >
        {!fill?.disabled && !isTransparent && <Background size={size} properties={{ fill }} transition={transition} />}
        {!comp.document && isPreview && this.props.children}
        {comp.document && comp.components.length > 0 && this.renderSelectedFragmentContent()}
        {!isPreview && !comp.components.length && this.renderEmptyContent()}
      </div>
    );
  }
}

export default ContentPanelV2;
