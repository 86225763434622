import { Dispatch } from 'redux';

import * as componentApi from '@apis/component';
import { storages } from '@utils/cacheUtils';

import { IcomponentLibGroup, IComponentCustomLib, ICustomComponentItem } from '@libs/types';

import { ActionsUnion, ActionType, createAction } from '@/store/types';

import { ThunkActions as GlobalThunkActions } from '../global/actions';

export interface UpdateInfoType {
  libId: string;
  groupIds: string[];
  compMap: {
    [groupIds: string]: string[];
  };
}
export interface ComponentDataMap {
  [id: string]: ICustomComponentItem;
}

const CustomComponentCacheKey = 'indexDB-[CustomComponentCacheKey]';
const ReportResolveCompoentCacheKey = 'localStorage-[ReportResolveCompoentCacheKey]';

export const Actions = {
  setLibGroupComponents: (libs: IcomponentLibGroup<IComponentCustomLib>[]) =>
    createAction(ActionType.CustomComponentLibs_SetComponentsLibs, libs),
  updateComponentsDataMap: (map: ComponentDataMap) =>
    createAction(ActionType.CustomComponentLibs_UpdateComponentsDataMap, map),
};

export const ThunkActions = {
  getCustomComponentGroupList: () => async (dispatch: Dispatch) => {
    const customComponentDataMapCache = await storages.indexedDB.getItem(CustomComponentCacheKey);
    //如果有数据缓存,需要读取
    if (customComponentDataMapCache) {
      dispatch(Actions.updateComponentsDataMap(customComponentDataMapCache));
    }
    //请求三方组件列表
    componentApi
      .getCustomComponentGroupList()
      .then((res) => {
        if (!res || !Array.isArray(res) || !res.length) {
          return;
        }
        const componentLibs = res.map((item) => {
          return { ...item, isCustom: true };
        }) as IcomponentLibGroup<IComponentCustomLib>[];
        dispatch(Actions.setLibGroupComponents(componentLibs));
      })
      .catch((e) => {
        GlobalThunkActions.thunkError(e.message)(dispatch);
      });
  },
  getCustomComponentData: (updateInfos: UpdateInfoType[]) => async (dispatch: Dispatch) => {
    if (!updateInfos.length) {
      return;
    }
    const newComponentsDataMap: ComponentDataMap = {};
    Promise.all(
      updateInfos.map((info: UpdateInfoType) => {
        const { libId, groupIds, compMap } = info;
        return Promise.all(
          groupIds.map((id: string) => {
            const compIds = compMap[id];
            return componentApi
              .getCustomComponetList(libId, id, compIds)
              .then((res) => {
                //装载目标数据
                res.forEach((comp) => {
                  newComponentsDataMap[comp.id] = comp;
                });
                //每个请求回来就可以装载
                dispatch(Actions.updateComponentsDataMap(newComponentsDataMap));
              })
              .catch((e) => {
                GlobalThunkActions.thunkError(e.message)(dispatch);
              });
          }),
        );
      }),
    )
      .catch((e) => {
        GlobalThunkActions.thunkError(e.message)(dispatch);
      })
      .finally(async () => {
        let customComponentDataMapCache = await storages.indexedDB.getItem(CustomComponentCacheKey);
        //更新缓存数据
        if (customComponentDataMapCache) {
          customComponentDataMapCache = { ...customComponentDataMapCache, ...newComponentsDataMap };
        } else {
          customComponentDataMapCache = newComponentsDataMap;
        }
        //记录到缓存
        storages.indexedDB.setItem(CustomComponentCacheKey, customComponentDataMapCache);
      });
  },
  //统计使用资源组件的情况
  reportResolveComponent: async (masterIds: string | undefined, isOffline: boolean) => {
    if (!masterIds) {
      return;
    }
    let reportComponentCache = storages.local.getItem(ReportResolveCompoentCacheKey) || [];
    let libs = [masterIds];
    //当前离线,记录到缓存
    if (isOffline) {
      reportComponentCache.push(masterIds);
      storages.local.setItem(ReportResolveCompoentCacheKey, reportComponentCache);
      return;
    }
    //如果存在缓存
    if (reportComponentCache) {
      libs = libs.concat(reportComponentCache);
      storages.local.removeItem(ReportResolveCompoentCacheKey);
    }
    //上报数据
    if (libs.length) {
      componentApi.reportResolveCompUseCount(libs as string[]);
    }
  },
};

export type ComponentAction = ActionsUnion<typeof Actions>;
