import * as React from 'react';
import * as _ from 'lodash';

import {
  fullColorStops,
  parseGradientColorToString,
  reviseRadialGradient,
  parseColorToString,
  rgba2hex,
} from '@/utils/graphicsUtils';
import * as BoundsUtils from '@utils/boundsUtils';
import { IBounds } from '@fbs/common/models/common';
import { max, round } from '@utils/globalUtils';

import { FillType } from '@/fbs/rp/models/properties/fill';
import { IRadialGradientColor } from '@/fbs/rp/models/properties/color';

import { StyleHelper } from '@helpers/styleHelper';
import { FontBoxScale } from '@consts/fonts';
import { GrayColor } from '@/consts/colors';

import ShapeTextBase, { IShapeTextCompState } from '../../basic/common/ShapeTextBase';
import { IComponentProps } from '../../types';
import { CCompoundPath, CEllipse } from '../../constants';

import './index.scss';

interface IState extends IShapeTextCompState {
  style: React.CSSProperties;
}

/**
 * @class div实现的矩形和圆
 */
export default class SimpleShape extends ShapeTextBase<IComponentProps, IState> {
  constructor(props: IComponentProps) {
    super(props);
    this.state = this.builderStyle(props);
  }

  private builderStyle(props: IComponentProps): { style: React.CSSProperties; textStyle: React.CSSProperties } {
    const {
      comp: { properties, size, opacity },
    } = props;
    const { shadow, stroke, fill, radius } = properties;
    const parser = StyleHelper.initCSSStyleParser(properties);
    const textStyle = this.parserTextStyle(props);

    let isFill = true;
    if (fill?.disabled && stroke?.disabled) {
      isFill = false;
    }
    let shadowStyle: React.CSSProperties = {};

    if (!shadow?.disabled) {
      shadowStyle = isFill ? parser.getShadowStyle() : parser.getTextShadow(1 / FontBoxScale);
    }

    const isChildOfPath = this.props.comp.parent?.type === CCompoundPath;

    const style: React.CSSProperties = {
      width: round(size.width),
      height: round(size.height),
      ...(!fill?.disabled ? parser.getFillStyle(size) : {}),
      ...(!stroke?.disabled ? parser.getStrokeStyle() : {}),
      ...(isFill ? shadowStyle : {}),
      ...(!radius?.disabled ? parser.getRadiusStyle(size) : {}),
      opacity: opacity === 100 ? undefined : (opacity ?? 1) / 100,
      transition: props.comp.getTransition(),
    };

    if (isChildOfPath) {
      // 处理复合路径中样式
      style.background = undefined;
      style.borderWidth = 1;
      style.borderColor = rgba2hex(GrayColor);
      style.borderStyle = 'solid';
    }

    return {
      style,
      textStyle,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: IComponentProps) {
    this.setState(this.builderStyle(nextProps));
  }

  private renderRadialDiv() {
    const {
      comp: { properties, type, size, opacity },
    } = this.props;
    const color = properties.fill?.color as IRadialGradientColor;
    if (!color) {
      return null;
    }
    const parser = StyleHelper.initCSSStyleParser(properties);
    const isCircle = type === CEllipse;
    const transition = this.props.comp.getTransition();
    const style = Object.assign(
      {
        opacity: (opacity ?? 1) / 100,
        transition,
      },
      parser.getRadiusStyle(size),
      isCircle ? { borderRadius: '50%' } : undefined,
    );

    const newColor = reviseRadialGradient(color, size);

    const { colorStops, to, from, angle, widthRatio } = newColor;
    const stops = fullColorStops(colorStops);
    const { x: fx, y: fy } = from ?? { x: 0.5, y: 0.5 };
    const { r } = to ?? { r: 0.5 };

    if (!r) {
      Object.assign(style, {
        background: parseColorToString(stops[stops.length - 1].color),
      });
      return <div className="radial-container" style={style} />;
    }

    const fillSize = {
      height: size.height * r * 2,
      width: size.height * r * 2 * (widthRatio ?? 1),
    };
    const rotate = angle ?? 0;

    const focusOffset = {
      x: (fx - 0.5) * size.width,
      y: (fy - 0.5) * size.height,
    };

    const originOffset = {
      x: focusOffset.x + (size.width - fillSize.width) / 2,
      y: focusOffset.y + (size.height - fillSize.height) / 2,
    };

    const bounds1 = BoundsUtils.createBoundsBySize(fillSize);
    const _bounds2 = BoundsUtils.initBoundsWithPositionAndSize({ x: -originOffset.x, y: -originOffset.y }, size);
    const center = BoundsUtils.center(bounds1);
    const bounds2 = BoundsUtils.rotate(_bounds2, { x: center.left, y: center.top }, -rotate);

    // 缩放颜色区域以覆盖整个面积，再缩小渐变比例；
    const scale = this.getScaleByBoundsCoverOne(bounds1, bounds2);

    const fillRectSize = {
      height: fillSize.height * scale,
      width: fillSize.width * scale,
    };

    if (scale !== 1) {
      stops.forEach((s) => (s.point = (s.point ?? 0) / scale));
      const newEnd = _.cloneDeep(stops[stops.length - 1]);
      newEnd.point = 1;
      stops.push(newEnd);
    }

    const offset = {
      x: focusOffset.x + (size.width - fillRectSize.width) / 2,
      y: focusOffset.y + (size.height - fillRectSize.height) / 2,
    };
    if (!stops.length) return null;

    const fillStyle: React.CSSProperties = {
      width: fillRectSize.width,
      height: fillRectSize.height,
      transformOrigin: 'center',
      transform: `translate(${offset.x}px, ${offset.y}px) rotate(${rotate}deg)`,
      background: `radial-gradient(closest-side, ${parseGradientColorToString(stops)})`,
      transition,
    };

    return (
      <div className="radial-container" style={style}>
        <div className="radial-fill" style={fillStyle} />
      </div>
    );
  }

  /**
   * bounds1缩放，以覆盖bounds2
   */
  private getScaleByBoundsCoverOne(bounds1: IBounds, bounds2: IBounds) {
    const { left: l1, right: r1, top: t1, bottom: b1, width, height } = bounds1;
    const { left: l2, right: r2, top: t2, bottom: b2 } = bounds2;
    const maxXDiff = max(l1 - l2, r2 - r1, 0);
    const maxYDiff = max(t1 - t2, b2 - b1, 0);
    return max((maxXDiff / width) * 2, (maxYDiff / height) * 2) + 1;
  }

  render() {
    const {
      comp: { text, properties, type },
      isPreview,
      isChildOfPath,
    } = this.props;
    let { style } = this.state;
    let needRenderText = true;
    if (isChildOfPath || (isPreview && !text)) {
      needRenderText = false;
    }
    const isCircle = type === CEllipse;
    if (isCircle) {
      style = Object.assign({}, style, { borderRadius: '50%' });
    }
    const { fill } = properties;
    const isRadial = fill?.type === FillType.radial && !fill.disabled;
    if (isRadial) {
      style = Object.assign({}, style, { background: undefined });
    }
    return (
      <>
        {isRadial && this.renderRadialDiv()}
        <div className={isCircle ? 'lib-comp-circle' : 'lib-comp-rect'} style={style}>
          {needRenderText && this.renderTextFragment(text, properties, 1)}
        </div>
      </>
    );
  }
}
