import { AvlTree } from '@tyriar/avl-tree';

import { IComponentData } from "../models/component";

export function updateNodeAVLTree(tree: AvlTree<string, IComponentData>, components: IComponentData[]) {
  components.forEach((comp) => {
    addComponentNode(tree, comp);
  });
}

export function addComponentNode(tree: AvlTree<string, IComponentData>, component: IComponentData) {
  if(!component){
    return;
  }
  tree.insert(component._id, component);
  if (component.components && component.components.length > 0) {
    component.components.forEach(c => addComponentNode(tree, c));
  }
}

export function removeComponentNode(tree: AvlTree<string, IComponentData>, component: IComponentData) {
  tree.delete(component._id);
  if (component.components && component.components.length > 0) {
    component.components.forEach(comp => removeComponentNode(tree, comp));
  }
}

export function removeComponentNodeByID(tree: AvlTree<string, IComponentData>, id: string) {
  if (!tree.contains(id)) {
    return;
  }
  const node = tree.get(id);
  if (!node) {
    return;
  }
  removeComponentNode(tree, node);
}
