import { each, isUndefined } from 'lodash';

import zhLang from './locales/zh_CN';
import enLang from './locales/en_US';
import twLang from './locales/zh_TW';
import { isFigma } from '../components/utils';

interface ILanguageNode {
  [index: string]: ILanguageNode | string;
}

export enum MockPlusLang {
  ZH = 'zh-cn',
  EN = 'en-us',
  TW = 'zh-tw',
}

const languages: {
  [name in string]: ILanguageNode;
} = {
  'zh-cn': zhLang,
  'en-us': enLang,
  'zh-tw': twLang,
};

const LANG = window.LANG || 'zh-cn';

let language: ILanguageNode = languages[LANG];

try {
  if (!isFigma()) {
    const key = localStorage?.getItem('mockplus_language');
    language = (key && languages[key]) || language;
  }
} catch (error) {}

export default (key: string, ...args: Array<any>) => {
  let value: ILanguageNode | string = '';
  const pathArr = key.split('.');
  each(pathArr, (name: string) => {
    if (!value) {
      value = language[name];
      return;
    }
    value = (<ILanguageNode>value)[name];
    return;
  });
  if (!value) {
    console.log(`i18n 错误: ${key} not exist.`);
    return '';
  }

  return <string>value.replace(/{(\d+)}/g, (match, number) => {
    if (isUndefined(args[number])) {
      return match;
    }
    return args[number];
  });
};
