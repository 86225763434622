export enum TimeFormatType {
  format_YYYY_MM_DD = 'YYYY-MM-DD',
  format_YYYY_MM_DD_HH_mm = 'YYYY-MM-DD HH:mm',
  format_YYYY_MM_DD_HH_mm_ss = 'YYYY-MM-DD HH:mm:ss',
  format_M = 'M',
  format_MMM = 'MMM',
  format_YYYY_MM = 'YYYY-MM',
  format_HH_MM = 'HH:mm',
  format_slash_YYYY_MM_DD = 'YYYY/MM/DD',
  format_from_now = 'from-now',
}
