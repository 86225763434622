import PropertyBase from './base';
import { PresetDashModel } from './stroke';
import ITextFormat from './text';
import { PureColor } from './color';

export enum ELegendPosition {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

export enum EChartDataLabelType {
  number = 'number',
  percent = 'percent',
}

export enum EChartType_pie {
  Normal = 'normal',
  AbNormal = 'abNormal',
}

export enum EChartType_basic {
  Bar = 'bar',
  BarChartHorizontal = 'barChartHorizontal',
  Line = 'line',
  Area = 'area',
  Radar = 'radar',
  Scatter = 'scatter',
}

export interface IChartSmooth extends PropertyBase {
  value: PresetDashModel; // 线条类型
  strokeWidth?: number; // 线条宽度
}

export interface IChartAxis extends PropertyBase {
  yAxisMin?: number | '';
  yAxisGap?: number | '';
  labelRotate?: number;
  // 当前为组合图表的axis设置
  isCombinationAxis?: boolean;
  // 组合图表双坐标轴设置
  lineYAxisMin?: number | '';
  lineYAxisGap?: number | '';
  // 刻度线
  scaleMark?: boolean;
}

export interface IChartPopup extends PropertyBase {
    disabled?: boolean;
    border: {
        disabled: boolean;
        color: PureColor;
    };
    fill: {
        disabled: boolean;
        color: PureColor;
    };
    textStyle: ITextFormat;
}

export interface IChartDataLabel extends PropertyBase {
  value?: EChartDataLabelType;
  showValue?: boolean;
  unit?: '';
}

export interface ILegendPosition extends PropertyBase {
  value: ELegendPosition;
  gap?: number | ''; // ''为自动
  gapHidden?: boolean; // 不展示gap
}

export interface IChartBarWidth extends Omit<PropertyBase, 'value'> {
  value?: number | '';
}

export const ChartSmoothPropertyName = 'isSmooth'; // 折线平滑
export const ChartAxisPropertyName = 'axis';
export const ChartDataLabelPropertyName = 'dataLabel';
export const ChartLegendPositionPropertyName = 'legendPosition';
export const ChartBarWidthPropertyName = 'barWidth'; // 柱宽
export const ChartPopupPropertyName = 'popup'; //悬浮窗口