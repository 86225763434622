import PropertyBase from './base';
import { IPoint } from '../../../common/models/common';

export enum BlurType {
  gaussian = 'gaussian',
  motion = 'motion',
  scale = 'scale',
  background = 'background'
}

interface IBlurValue {
  aMount: number;
}

interface IGuassianBlurValue extends IBlurValue {

}

interface IBackgroundBlurValue extends IBlurValue {
  saturate: number;
}

interface IMotionBlurValue extends IBlurValue {
  angle: number;
}

interface IScaleBlurValue extends IBlurValue {
  center: IPoint;
}

export default interface IBlur extends PropertyBase {
  type: BlurType;
  blurValue: IBlurValue;
}

export const BlurPropertyName = 'blur';