import ITextFormat from './text';
import PropertyBase from './base';
import { PureColor } from './color';

export enum MapType {
  Polychrome = 'polychrome', // 多色
  Monochrome = 'monochrome', // 单色
  Heatmap = 'heatmap', // 热力图，单色系随数值渐变
}

export enum LegendPosition {
  Rightbottom = 'right_bottom',
  Righttop = 'right_top',
  Leftbottom = 'left_bottom',
  Lefttop = 'left_top',
}

export const MapChartPropertyName = 'mapChart';

interface IMapChart extends PropertyBase {
  region: string; // 区域
  type: MapType;
  showLabel: boolean;
  regionBorder: {
    color: PureColor;
  };
  hover: {
    disabled?: boolean;
    border: {
      disabled: boolean;
      color: PureColor;
    };
    fill: {
      disabled: boolean;
      color: PureColor;
    };
    textStyle: ITextFormat;
  };
  popup: {
    disabled?: boolean;
    border: {
      disabled: boolean;
      color: PureColor;
    };
    fill: {
      disabled: boolean;
      color: PureColor;
    };
    textStyle: ITextFormat;
  };
  legend: {
    disabled: boolean;
    position: LegendPosition;
  };
}

export default IMapChart;
