import * as React from 'react';
import { isUndefined } from 'lodash';
import classnames from 'classnames';

import { UIComponent } from '@editor/comps';
import { IComponentData } from '@fbs/rp/models/component';
import { getNewID } from '@helpers/idHelper';
import { IBounds } from '@fbs/common/models/common';
import { HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import { getDefaultComponentName } from '@libs/libs';

import { makeCommonComponent, addTooltipsProperty } from '../../helper';
import { IComponentProps } from '../../types';
import { CGroup } from '../../constants';

import './index.scss';

interface IGroupProps extends IComponentProps {
  comp: UIComponent;
}

export const makeGroup = addTooltipsProperty(_makeGroup);

function _makeGroup(position: IBounds, components: IComponentData[]): IComponentData {
  return makeCommonComponent(getNewID(), CGroup, {
    name: getDefaultComponentName(CGroup),
    position: {
      x: position.left,
      y: position.top,
    },
    size: {
      width: position.width,
      height: position.height,
    },
    layout: {
      responsive: false,
      auto: true,
      fixedWidth: false,
      fixedHeight: false,
      horizontal: HorizontalAlign.Auto,
      vertical: VerticalAlign.Auto,
    },
    components: components,
  });
}

export default class Group extends React.Component<IGroupProps> {
  render() {
    const { comp, isPreview } = this.props;
    const { opacity, size } = comp;
    const alpha = isUndefined(opacity) ? 1 : opacity / 100;
    let style: React.CSSProperties = {
      opacity: alpha,
      ...size,
    };

    return (
      <div className={classnames('lib-comp-group', { preview: isPreview })} style={style}>
        {this.props.children}
      </div>
    );
  }
}
