import * as React from 'react';
import * as ReactDom from 'react-dom';
import classnames from 'classnames';

import { isUndefined } from 'lodash';

import { FillData, FillType, IFill, isLight, PureColor } from '@utils/graphicsUtils';
import { saveToCache, loadFromCache } from '@utils/cacheUtils';

import { IGradientColor } from '@fbs/rp/models/properties/color';
import { IUserPreference } from '@/fbs/rp/models/grid';

import { ComponentTheme } from '../common';
import ColorPanel from './ColorPanel';
import { renderClipFill } from '../../libs/basic/common/GradientFragment';

import { MAX_FAVORITE_COLOR_COUNT } from './types';

import './index.scss';

export { default as ColorInput } from './ColorInput';

export { default as colorPanelManager } from './types';

const DEFAULT_COLOR = { r: 255, g: 255, b: 255, a: 1 };

export interface IColorPickerProp {
  color: {
    type: FillType | undefined;
    color: FillData | undefined;
  };
  colorModels?: FillType[];
  autoFocus?: boolean;
  disabled?: boolean;
  disabledGradient?: boolean;
  disabledAlpha?: boolean;
  width?: number;
  className?: string;
  isInPopup?: boolean;
  theme?: ComponentTheme;
  hidden?: boolean;
  userPreference?: IUserPreference;
  children?: React.ReactNode;
  onChange: (color: IFill) => void;
  onChanging?: (color: IFill) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onFavouriteColor?: (color: IFill) => void;
  onColorStopIndexChanged?: (index: number) => void;
  onSwitchToGradient?: (isGradient: boolean) => void;
  onPatchPreference?: (value: Partial<IUserPreference>) => void;
  onMouseDown?: React.MouseEventHandler;
  onDownloadExtension: () => void;
}

export interface IColorPickerState {
  popup: boolean;
  sourceBounds: {
    left: number;
    top: number;
    width: number;
    height: number;
  };
  favoriteColors?: Array<IFill>;
  recentColors: Array<IFill>;
}

function getFavoriteColors(): Array<IFill> {
  const data = localStorage.getItem('favorite-color');
  const result: Array<IFill> = [];
  if (data) {
    const json = JSON.parse(data);
    if (Array.isArray(json)) {
      json.forEach((item) => {
        result.push(item as IFill);
      });
    }
  }
  return result;
}

function favoriteColor(colors: IFill[] | undefined) {
  const data = colors ? JSON.stringify(colors) : '';
  localStorage.setItem('favorite-color', data);
}

class ColorPicker extends React.PureComponent<IColorPickerProp, IColorPickerState> {
  static defaultProps: Partial<IColorPickerProp> = {
    autoFocus: true,
    theme: ComponentTheme.dark,
  };
  private released: boolean = false;
  private lastActiveEl: Element | null = null;

  constructor(props: IColorPickerProp) {
    super(props);
    this.state = {
      popup: false,
      sourceBounds: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      },
      favoriteColors: [],
      recentColors: [],
    };
  }

  componentDidMount() {
    this.updateRecentColors();
  }

  componentWillUnmount() {
    this.released = true;
  }

  updateRecentColors = () => {
    let recentColors = loadFromCache('mockplus-rp-recent-colors');
    if (isUndefined(recentColors)) {
      recentColors = [];
    }
    this.setState({ recentColors });
  };

  onClosePopup = () => {
    if (this.released) {
      return;
    }
    this.setState({ popup: false }, () => {
      this.onFocusChange();
      if (this.lastActiveEl) {
        (this.lastActiveEl as HTMLElement).focus();
      }
      this.lastActiveEl = null;
    });
    // favoriteColor(this.state.favoriteColors);
  };

  private onFocusChange() {
    const { onFocus, onBlur } = this.props;
    const { popup } = this.state;
    popup && onFocus && onFocus();
    !popup && onBlur && onBlur();
  }

  onMouseDown = (e: React.MouseEvent) => {
    if (this.props.disabled === true) {
      return;
    }
    this.lastActiveEl = document.activeElement;
    const dom = e.target as HTMLElement;
    const bounds = dom.getBoundingClientRect();
    const { left, top, width, height } = bounds;
    this.setState(
      {
        popup: !this.state.popup,
        sourceBounds: {
          left,
          top,
          width,
          height,
        },
        favoriteColors: getFavoriteColors(),
      },
      () => {
        this.onFocusChange();
      },
    );
    if (!this.state.popup) {
      this.updateRecentColors();
    }
  };

  onColorChange = (color: IFill) => {
    const { onChange } = this.props;
    let recentColors = this.state.recentColors;
    // 缓存颜色
    if (isUndefined(recentColors)) {
      recentColors = [color];
    }
    if (Array.isArray(recentColors)) {
      const index = recentColors.findIndex((item) => JSON.stringify(item) === JSON.stringify(color));
      if (index !== -1) {
        recentColors.splice(index, 1);
      }
      recentColors.unshift(color);
    }
    saveToCache('mockplus-rp-recent-colors', recentColors.slice(0, 8));
    this.setState({ recentColors: recentColors.slice(0, 8) });
    onChange && onChange(color);
  };

  onColorChanging = (color: IFill) => {
    const { onChanging } = this.props;
    onChanging && onChanging(color);
  };

  onFavoriteColor = (color: IFill) => {
    const { favoriteColors } = this.state;
    const data = JSON.stringify(color);
    const index = favoriteColors ? favoriteColors.findIndex((item) => JSON.stringify(item) === data) : -1;
    const tempColors = favoriteColors?.filter((colorItem) => colorItem.type === color.type);
    const count = tempColors ? tempColors.length : 0;
    if (index === -1 && count < MAX_FAVORITE_COLOR_COUNT) {
      this.setState({ favoriteColors: [...(favoriteColors || []), color] }, () => {
        favoriteColor(this.state.favoriteColors);
      });
    }
  };

  onRemoveFavorite = (color: IFill) => {
    const { favoriteColors } = this.state;
    if (favoriteColors) {
      const data = JSON.stringify(color);
      const index = favoriteColors.findIndex((item) => JSON.stringify(item) === data);
      if (index !== -1) {
        favoriteColors.splice(index, 1);
        this.setState({ favoriteColors: [...favoriteColors] });
        favoriteColor(favoriteColors);
      }
    }
  };

  renderPopup = () => {
    const { popup, sourceBounds, favoriteColors, recentColors } = this.state;
    const {
      color,
      disabledGradient,
      disabledAlpha,
      isInPopup,
      theme,
      colorModels,
      onMouseDown,
      onColorStopIndexChanged,
      onSwitchToGradient,
      onPatchPreference,
      onDownloadExtension,
    } = this.props;
    if (!popup) {
      return null;
    }

    let newColor = color;
    if (!color || !color.type || !color.color) {
      newColor = {
        type: FillType.solid,
        color: DEFAULT_COLOR,
      };
    }

    return ReactDom.createPortal(
      <ColorPanel
        color={newColor as IFill}
        theme={theme}
        isInPopup={isInPopup}
        autoFocusToColorValue={this.props.autoFocus}
        disabledGradient={disabledGradient}
        disabledAlpha={disabledAlpha}
        sourceBounds={sourceBounds}
        favorite={favoriteColors}
        recentColors={recentColors}
        colorModels={colorModels}
        presetColors={this.props.userPreference?.colors}
        onPanelMouseDown={onMouseDown}
        onClose={this.onClosePopup}
        onChanged={this.onColorChange}
        onChanging={this.onColorChanging}
        onFavoriteColor={this.onFavoriteColor}
        onRemoveFavoriteColor={this.onRemoveFavorite}
        onColorStopIndexChanged={onColorStopIndexChanged}
        onSwitchToGradient={onSwitchToGradient}
        onPatchPreference={onPatchPreference}
        onDownloadExtension={onDownloadExtension}
      >
        {this.props.children}
      </ColorPanel>,
      document.body,
    );
  };

  render() {
    const { color, width, className, disabled, theme, hidden } = this.props;
    let newColor = color;
    if (!color || !color.type || !color.color) {
      newColor = {
        type: FillType.solid,
        color: DEFAULT_COLOR,
      };
    }

    let lightColor: boolean;
    if (newColor.type === FillType.solid) {
      lightColor = isLight(newColor.color as PureColor);
    } else {
      const colors = (newColor.color as IGradientColor).colorStops;
      lightColor = colors.every((c) => {
        return isLight(c.color as PureColor);
      });
    }

    const option = {
      id: Math.random().toString(16),
      type: 'path',
      size: {
        width: 14,
        height: 14,
      },
      fill: newColor as IFill,
      scale: 1,
    };
    return (
      <React.Fragment>
        <div className={classnames('dsm-c-rp-color-picker', className, theme)} style={{ width }}>
          <div
            className={`dsm-c-rp-color-indicator ${disabled ? 'disabled' : ''}`}
            style={{ width }}
            onMouseDown={this.onMouseDown}
          >
            <div />
            <div
              className={classnames(
                'color-content',
                { light: lightColor, none: !color || !color.color },
                { 'light-border': theme === ComponentTheme.light },
              )}
              style={{ pointerEvents: 'none' }}
            >
              {hidden || !color || !color.color ? (
                <div className="no-color" />
              ) : (
                <svg version="1.2" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
                  {renderClipFill(option, <rect width={option.size.width} height={option.size.height} />)}
                </svg>
              )}
            </div>
          </div>
        </div>
        {this.renderPopup()}
      </React.Fragment>
    );
  }
}

export default ColorPicker;
