/**
 * 后续避免使用该文件导出方法；
 * 请使用 import { } from '@utils/envUtils';
 */

const UA = window.navigator.userAgent;

export const isMacOS = window.navigator.userAgent.indexOf('Macintosh') !== -1;

export const isCtrlKey = (e: { ctrlKey: boolean; metaKey: boolean }) => {
  return e.ctrlKey || e.metaKey;
};

export const isIE = (): boolean => {
  const { userAgent } = window.navigator;
  if ('ActiveXObject' in window) {
    return true;
  }
  // 判断是否Edge浏览器
  return userAgent.indexOf('Trident') > -1;
};

export const isChrome = (): boolean => {
  return /Chrome/.test(UA);
};

export const isSafari = (): boolean => {
  return /Safari/.test(UA) && !isChrome();
};

export const isFirefox = () => {
  return /Firefox/.test(UA);
};

export const isMac = UA.indexOf('Macintosh') !== -1;

export const isLarkPC = UA.indexOf('LarkLocale') !== -1;
