import * as React from 'react';
import { isUndefined, isEqual } from 'lodash';
import i18n from '@i18n';

import { isEqualPoint } from '@utils/boundsUtils';
import { UIConnectorComponent, IConnnectTextInfo } from '@editor/comps';
import { IComponentData } from '@fbs/rp/models/component';
import { HorizontalAlign, VerticalAlign } from '@fbs/rp/models/layout';
import { IConnectorPoint } from '@fbs/rp/models/value';
import * as SystemsColor from '@consts/colors';
import { getNewID } from '@helpers/idHelper';
import { StrokeLineCap, StrokeLineJoin } from '@fbs/rp/models/properties/stroke';
import { IPosition } from '@fbs/common/models/common';
import { TextAlign } from '@fbs/rp/models/properties/text';
import ILine, { LinePointType } from '@/fbs/rp/models/properties/line';

import { makeCommonComponent } from '../../helper';
import { IComponentProps, IComponentItem } from '../../types';
import { CConnector } from '../../constants';
import ConnectorPath from '../common/ConnectorPath';

export const ConnectorCfg: IComponentItem = {
  hidden: true,
  type: CConnector,
  name: i18n('resource.components.connector'),
  thumb: {},
  getDefaultData() {
    return {
      properties: {
        stroke: {
          name: i18n('property.propertyNames.lineStroke'),
          thickness: 1,
          color: SystemsColor.DeepBlueColor,
          cap: StrokeLineCap.Butt,
          join: StrokeLineJoin.Miter,
          disabled: false,
        },
        // arrow: {
        //   name: i18n('property.propertyNames.arrow'),
        //   disabled: false,
        //   prop: 'boolean',
        //   value: true,
        // },
        line: {
          startArrow: false,
          startPointType: LinePointType.none,
          endArrow: true,
          endPointType: LinePointType.solidArrow,
        },
        textStyle: {
          fontFamily: 'Microsoft YaHei',
          fontStyle: { underline: false, bold: false, strike: false, italic: false },
          fontSize: 14,
          color: SystemsColor.DefaultTextColor,
          textAlign: TextAlign.center,
        },
        padding: {
          disabled: false,
          hidden: true,
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        },
      },
    };
  },
};

export function makeConnector(start: IConnectorPoint, end: IConnectorPoint): IComponentData {
  const id = getNewID();
  return makeCommonComponent(id, CConnector, {
    ...ConnectorCfg.getDefaultData?.(),
    layout: {
      responsive: true,
      auto: true,
      fixedHeight: false,
      fixedWidth: false,
      horizontal: HorizontalAlign.Left,
      vertical: VerticalAlign.Top,
    },
    size: {
      width: 100,
      height: 100,
    },
    value: {
      startPoint: start,
      endPoint: end,
    },
    text: ``,
  });
}

interface IConnectorState {
  position: IPosition;
  paths: string;
  textValue: string;
  textInfo: IConnnectTextInfo;
  strokeWidth: number;
  strokeColor: string;
  opacity: number;
  strokeDasharray: string | undefined;
  line: ILine;
}

export default class Connector extends React.Component<IComponentProps, IConnectorState> {
  constructor(props: IComponentProps) {
    super(props);
    const {
      position,
      paths,
      textVal,
      textInfo,
      strokeWidth,
      strokeColor,
      opacity,
      strokeDasharray,
      line,
    } = this.getConnctorInfo(props);
    this.state = {
      position,
      paths,
      textValue: textVal,
      textInfo,
      strokeWidth,
      strokeColor,
      opacity,
      strokeDasharray,
      line,
    };
  }

  getConnctorInfo(props: IComponentProps) {
    const component = props.comp as UIConnectorComponent;
    const paths = component.searchPaths().join(',');
    const { position, strokeWidth, strokeColor, opacity, strokeStyle, line } = component.parseProperties();
    const textInfo = component.computedTextInfo();
    const { text } = component;
    return {
      paths,
      position,
      textVal: text.toString() || '',
      textInfo,
      strokeWidth,
      strokeColor,
      opacity,
      strokeDasharray: strokeStyle.strokeDasharray,
      line,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: IComponentProps) {
    const {
      position,
      paths,
      textVal,
      textInfo,
      strokeWidth,
      strokeColor,
      opacity,
      strokeDasharray,
      line,
    } = this.getConnctorInfo(nextProps);
    if (!isEqual(this.state.position, position)) {
      this.setState({ position });
    }

    if (!isEqual(this.state.paths, paths)) {
      this.setState({ paths });
    }

    if (!isEqual(this.state.textValue, textVal)) {
      this.setState({ textValue: textVal });
    }

    if (!isEqual(this.state.textInfo, textInfo)) {
      this.setState({ textInfo });
    }

    if (!isEqual(this.state.strokeWidth, strokeWidth)) {
      this.setState({ strokeWidth });
    }

    if (!isEqual(this.state.strokeColor, strokeColor)) {
      this.setState({ strokeColor });
    }

    if (!isEqual(this.state.opacity, opacity)) {
      this.setState({ opacity });
    }

    if (!isEqual(this.state.strokeDasharray, strokeDasharray)) {
      this.setState({ strokeDasharray });
    }

    if (!isEqual(this.state.line, line)) {
      this.setState({ line });
    }
  }

  shouldComponentUpdate(nextProps: IComponentProps, nextState: IConnectorState) {
    if (!isEqual(this.state.position, nextState.position)) {
      return true;
    }

    if (!isEqual(this.state.paths, nextState.paths)) {
      return true;
    }

    if (!isEqual(this.state.textValue, nextState.textValue)) {
      return true;
    }

    if (!isEqual(this.state.textInfo, nextState.textInfo)) {
      return true;
    }

    if (!isEqual(this.state.strokeWidth, nextState.strokeWidth)) {
      return true;
    }

    if (!isEqual(this.state.strokeColor, nextState.strokeColor)) {
      return true;
    }

    if (!isEqual(this.state.opacity, nextState.opacity)) {
      return true;
    }

    if (!isEqual(this.state.strokeDasharray, nextState.strokeDasharray)) {
      return true;
    }

    if (!isEqual(this.state.line, nextState.line)) {
      return true;
    }
    return false;
  }

  render() {
    const component = this.props.comp as UIConnectorComponent;
    const startPoint = component.getStartPoint(true);
    const endPoint = component.getEndPoint(true);
    if (isEqualPoint(startPoint, endPoint)) {
      return <></>;
    }
    const { textInfo } = this.state;
    const { textCss, textSize, textPosition } = textInfo;
    const opacityStyle: React.CSSProperties = {};
    opacityStyle.opacity = isUndefined(component.opacity) ? 1 : component.opacity / 100;
    opacityStyle.transition = component.getTransition();
    return (
      <div className="lib-comp-connector" style={opacityStyle}>
        <ConnectorPath
          connector={component}
          // style={{ transform: `translate(${0 - position.x}px, ${0 - position.y}px)` }}
          scale={1}
          showTextEditor={false}
          textCss={textCss}
          textSize={textSize}
          textPosition={textPosition}
        />
      </div>
    );
  }
}
