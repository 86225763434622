import * as React from 'react';
import classnames from 'classnames';

import Icon from '../Icon';

import './index.scss';

export interface ITagProps {
  className?: string,
  activated?: boolean,
  deletable?: boolean,
  disabled?: boolean,
  children?: any | string,
  onClick?: React.MouseEventHandler,
  onDelete?: React.MouseEventHandler,
}

const Tag: React.FC<ITagProps> = (props: ITagProps) => {
  const { disabled, onClick, onDelete, children, className, activated, deletable } = props;
  return (
    <div
      className={classnames('dsm-c-rp-tag', className, { 'activated': activated }, { 'disabled': disabled })}
      onClick={onClick}
    >
      <span>{children}</span>
      {
        deletable && <Icon
          cls="close"
          onClick={(e)=>{
            e.stopPropagation();
            onDelete&&onDelete(e);
          }}
        />
      }

    </div>
  );
};

Tag.defaultProps = {
  disabled: false,
  deletable: false,
};

export default Tag;
