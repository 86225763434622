import * as React from 'react';

import Loading from '../../../Loading';

import { CustomerServiceQRList } from '../../../../constants/rpFreeProgram';
import i18n from '../../../../i18n';

import './index.scss';

interface IReceiveForeverProps {
  getCustomerServiceQR(cb: Function): void;
  setCustomerServiceQR(url: string): void;
}

const ReceiveForever: React.FC<IReceiveForeverProps> = (props: IReceiveForeverProps) => {
  const { getCustomerServiceQR, setCustomerServiceQR } = props;

  const [qrCode, setQrCode] = React.useState('');

  React.useEffect(() => {
    getCustomerServiceQR((qr: string) => {
      if (!qr) {
        const randomQR = getRandomCustomerServiceQR();
        setCustomerServiceQR(randomQR);
        setQrCode(randomQR);
        return;
      }
      setQrCode(qr);
    });
  }, []);

  const getRandomCustomerServiceQR = () => {
    const list = CustomerServiceQRList;
    return list[Math.floor(Math.random() * 3)];
  };

  return (
    <div className="rp-free-receive-forever">
      <div className="qr-code">
        {qrCode ? <img src={qrCode} alt="qr_code" width={'100%'} /> : <Loading pastDelay={200} />}
      </div>
      <div className="desc-wrap">
        <p className="desc">{i18n('rpActivity.receiveForever.scanQR')}</p>
        <p className="desc">
          {i18n('rpActivity.receiveForever.get')}
          <span className="red">{i18n('rpActivity.receiveForever.unlimitedPackage')}</span>
        </p>
      </div>
      <div className="notes">
        <span className="note">*</span>
        <span>{i18n('rpActivity.receiveForever.rewardWillSoonArrived')}</span>
      </div>
    </div>
  );
};

export default ReceiveForever;
