export enum LeftPanelType {
  project,
  outline,
  component,
  icon,
  resource,
  none,
}

export enum COMP_LIB_TAB_ITEMS {
  preset,
  more,
}
