import { transBlankChart } from '@/utils/textUtils';
import { INode, NodeIconType } from '@/dsm/PageTree/model';
import { INodeWithChildren } from '@/fbs/rp/models/node';

interface INodeParseOption {
  showPageNumber: boolean;
  count?: number;
  parentNumber?: string;
}

// 项目页面树结构转为组件PageTree结构
export const mapNodes = (
  nodes: INodeWithChildren[] | undefined,
  option: INodeParseOption,
  nodefilter?: (node: INode) => boolean,
): INode[] | undefined => {
  if (!nodes) {
    return;
  }
  const { showPageNumber, parentNumber } = option;
  const result = nodes.map(
    (node, i): INode => {
      let serialNumber = '';
      if (showPageNumber) {
        const n = i + 1;
        serialNumber = parentNumber ? `${parentNumber}.${n}` : `${n}`;
      }

      const childOption = {
        showPageNumber,
        parentNumber: serialNumber,
        count: node.children.filter((childNode) => childNode.type === 'page').length,
      };
      const children = mapNodes(node.children, childOption, nodefilter);
      option.count = (option.count || 0) + childOption.count;

      return {
        id: node._id,
        serialNumber: serialNumber,
        name: transBlankChart(node.name),
        children,
        childCount: node.type === 'folder' ? childOption.count : 0,
        data: node,
        isLeaf: node.type === 'page',
        icon: node.type === 'folder' ? NodeIconType.Folder : NodeIconType.Word,
        suffixIcon: !node.hidden
          ? undefined
          : {
              icon: (node.hidden ? 'icon_tree_unview' : NodeIconType.Word) as NodeIconType,
              color: '',
              label: '',
              disabled: false,
            },
      };
    },
  );
  if (nodefilter) {
    return result.filter((t) => nodefilter(t));
  }

  return result;
};
