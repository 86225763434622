import { has } from 'lodash';

import IndexedDBBase from '@/utils/indexedDBBase';

import { free } from '@fbs/common/utils/localStorage';

/**
 * 置一个项目的数据的缓存
 * @template T
 * @param {string} appID
 * @param {string} key
 * @param {T} value
 * @returns
 */
export function setAppCache<T>(appID: string, key: string, value: T) {
  return window.localStorage.setItem(`rp_app_cache_${appID}-${key}`, JSON.stringify(value));
}

/**
 * 获取一个项目的缓存数据
 * @template T
 * @param {string} appID
 * @param {string} key
 * @returns {(T | null)}
 */
export function getAppCache<T>(appID: string, key: string): T | null {
  const value = window.localStorage.getItem(`rp_app_cache_${appID}-${key}`);
  if (!value) {
    return null;
  }
  try {
    return JSON.parse(value);
  } catch (e) {
    console.error(e);
  }
  return null;
}

let needClear = true;

/**
 * 缓存数据
 * @param {string} key
 * @param value
 */
export function saveToCache(key: string, value: any) {
  try {
    if (needClear) {
      autoClearLocalStorage();
    }
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error(e);
  }
}

/**
 * 从缓存中移除数据
 * @param key
 */
export function removeToCache(key: string): void {
  window.localStorage.removeItem(key);
}

/**
 * 从缓存中获取数据
 * @param {string} key
 * @param {any} defaultValue
 * @returns {any | null}
 */
export function loadFromCache(key: string, defaultValue?: any): any | null {
  const value = window.localStorage.getItem(key);
  if (value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      return defaultValue || value;
    }
  }
  return defaultValue;
}

export function autoClearLocalStorage() {
  if (!window.localStorage) {
    return;
  }
  if (window.localStorage.length > (window.debug ? 20 : 80)) {
    free();
  }
  needClear = false;
}

const RP_DATA_STORAGE = 'RP_DATA_STORAGE';

type StorageValueResult<T> = T | null;

export interface IStorage {
  clear(): void;
  has(key: string): boolean;
  removeItem(key: string): void;
  getItem<T = any>(key: string): StorageValueResult<T>;
  setItem<T = any>(key: string, value: T): Boolean;
  getAsyncItem<T>(key: string): Promise<StorageValueResult<T>>;
  setAsyncItem<T = any>(key: string, value: T): Promise<void>;
}
export class MemoryStorage implements IStorage {
  private cache: { [key: string]: any } = new Map();
  clear() {
    this.cache.clear();
  }
  has(key: string): boolean {
    return this.cache.has(key);
  }
  removeItem(key: string) {
    this.cache.delete(key);
  }
  getItem<T = any>(key: string) {
    return this.cache.get(key) as StorageValueResult<T>;
  }
  setItem<T = any>(key: string, value: T) {
    try {
      this.cache.set(key, value);
    } catch (e) {
      return false;
    }
    return true;
  }
  async setAsyncItem<T = any>(key: string, value: T) {
    return this.setItem(key, value) ? Promise.resolve() : Promise.reject();
  }
  async getAsyncItem<T = any>(key: string) {
    return this.getItem<StorageValueResult<T>>(key);
  }
}
export class IndexedDBStorage {
  private storage: IndexedDBBase;
  constructor() {
    this.storage = new IndexedDBBase(RP_DATA_STORAGE);
  }
  async has(key: string) {
    return this.storage.hasKey(key);
  }
  async removeItem(key: string) {
    await this.storage.deleteKey(key);
  }
  async setItem<T = any>(key: string, value: T) {
    return this.storage.saveValue(key, JSON.stringify(value));
  }
  async getItem<T = any>(key: string) {
    return this.storage.getValue(key).then<StorageValueResult<T>>((jsonData) => {
      return typeof jsonData === 'string' ? JSON.parse(jsonData) : jsonData;
    });
  }
}

class StorageManager<T extends Storage> implements IStorage {
  private storage: T;
  constructor(storage: T) {
    this.storage = storage;
  }
  clear() {
    this.storage.clear();
  }
  has(key: string): boolean {
    return has(this.storage, key);
  }
  removeItem(key: string) {
    this.storage.removeItem(key);
  }
  getItem<T = any>(key: string) {
    let data;
    try {
      if (this.has(key)) {
        data = JSON.parse(this.storage.getItem(key)!);
      }
    } catch (e) {
      console.log(e);
    }
    return data as StorageValueResult<T>;
  }
  setItem<T = any>(key: string, value: T) {
    try {
      const jsonData = JSON.stringify(value);
      this.storage.setItem(key, jsonData);
    } catch (e) {
      return false;
    }
    return true;
  }
  async setAsyncItem<T = any>(key: string, value: T) {
    return this.setItem(key, value) ? Promise.resolve() : Promise.reject();
  }
  async getAsyncItem<T = any>(key: string) {
    return this.getItem<StorageValueResult<T>>(key);
  }
}

const memoryStorage = new MemoryStorage();
const sessionStorage = new StorageManager(window.sessionStorage);
const localStorage = new StorageManager(window.localStorage);
const indexedDBStorage = new IndexedDBStorage();
export const storages = {
  temp: memoryStorage,
  local: localStorage,
  session: sessionStorage,
  indexedDB: indexedDBStorage,
};
