import i18n from '@/i18n';
import { IComponentItem } from '@/libs/types';
import { CPolygon } from '@/libs/constants';
import { SpriteThumb } from '@/consts/spriteIcons';
import { UIComponent } from '@/editor/comps';
import { IComponentData } from '@/fbs/rp/models/component';
import { makeShapeBase, getShapeBaseDefaultData } from '../common/ShapeTextBase';
import { PolygonTypeConfig } from '@/consts/polygonPath';
import { getNewID } from '@/helpers/idHelper';

export const polygonConfig: IComponentItem = {
  type: CPolygon,
  name: i18n('resource.components.polygon'),
  shortCut: 'Y',
  thumb: {
    spriteIconClass: SpriteThumb.Polygon.className,
    dragPosition: SpriteThumb.Polygon.position,
  },
  editor: {
    onValidateAllowEditor: (comp: UIComponent, trigger: 'enter' | 'dblClick'): 'path' | 'value' => {
      return trigger === 'dblClick' ? 'path' : 'value';
    },
  },
  getDefaultData() {
    const shapeBaseDefaultData = getShapeBaseDefaultData(true);

    Object.assign(shapeBaseDefaultData.properties, {
      polygon: {
        sideCount: 3,
      },
    });

    return shapeBaseDefaultData;
  },
};

export function makePolygon(id: string): IComponentData {
  return makeShapeBase(id, CPolygon, true);
}

/**
 * 返回多边形组件初始数据
 * @param componentName
 */
export function makePolygonCompData(componentName: string) {
  const config = PolygonTypeConfig[componentName];
  const shapeData = makeShapeBase(getNewID(), CPolygon, true, {
    size: config.size,
    name: config.name,
    properties: {
      polygon: {
        sideCount: config.sideCount,
      },
    },
  });

  return shapeData;
}
