import * as lib from './lib';
import * as color from './color';
import * as typography from './typography';
import * as component from './component';
import * as group from './group';

export default {
  lib,
  group,
  resourceItem: {
    color: {
      add: color.addColor,
      remove: color.removeColor,
      patch: color.patchColor,
      move: color.moveColor,
    },
    typography: {
      add: typography.addTypography,
      remove: typography.removeTypography,
      patch: typography.patchTypography,
      move: typography.moveTypography,
    },
    component: {
      add: component.addComponent,
      remove: component.removeComponent,
      patch: component.patchComponent,
      move: component.moveComponent,
      resetGroup: component.resetComponentsGroup,
      removeComponents: component.removeComponents,
      addRelations: component.addRelations,
      patchRelations: component.patchRelations,
      removeRelations: component.removeRelations,
      convertToSymbol: component.convertToSymbol,
      convertSymbolToNormal: component.convertSymbolToNormal,
    },
  },
};
