import * as React from 'react';
import classnames from 'classnames';

import { isMacOS } from '@utils/envUtils';

import { Icon, Input, Tooltip } from '@dsm';

import i18n from '@i18n';
import { desktopServer, DesktopEventType } from '@/services/desktop';

import './index.scss';

interface IProps {
  title?: string | boolean;
  transparent?: boolean;
  minimizable?: boolean;
  maximizable?: boolean;
  status: 'maximized' | 'unMaximized';
  canChange?: boolean;
  className?: string;
  onChange?: (title: string) => void;
}

interface IState {
  isMaximized: boolean;
  editing?: boolean;
  editTitle: string;
}

class DesktopTitle extends React.PureComponent<IProps, IState> {
  static defaultProps: Partial<IProps> = {
    minimizable: false,
    maximizable: false,
  };

  constructor(props: IProps) {
    super(props);
    const { status, title } = props;
    this.state = {
      isMaximized: status === 'maximized',
      editTitle: typeof title === 'string' ? title : '',
    };
  }

  componentDidMount() {
    desktopServer.checkMaximize();
    window.ipcRenderer?.addListener(DesktopEventType.maximize, this.doMaximize);
    window.ipcRenderer?.addListener(DesktopEventType.unmaximize, this.doUnmaximize);
  }

  componentWillUnmount() {
    window.ipcRenderer?.removeListener(DesktopEventType.maximize, this.doMaximize);
    window.ipcRenderer?.removeListener(DesktopEventType.unmaximize, this.doUnmaximize);
  }

  componentDidUpdate(oldProps: IProps) {
    const { title } = this.props;
    if (title !== oldProps.title && typeof title === 'string') {
      this.doChangeEditTitle(title);
    }
  }

  private doMaximize = () => {
    this.setState({
      isMaximized: true,
    });
  };

  private doUnmaximize = () => {
    this.setState({
      isMaximized: false,
    });
  };

  private handleMinimizeWindow = () => {
    desktopServer.minimizeWindow();
  };

  private handleMaximizeWindow = () => {
    if (this.props.maximizable) {
      return;
    }
    this.doMaximize();
    desktopServer.maximizeWindow();
  };

  private handleUnmaximizeWindow = () => {
    if (this.props.maximizable) {
      return;
    }
    this.doUnmaximize();
    desktopServer.unmaximizeWindow();
  };

  private handleDoubleClick = () => {
    if (this.props.maximizable) {
      return;
    }
    if (this.state.isMaximized) {
      this.doUnmaximize();
      desktopServer.unmaximizeWindow();
    } else {
      this.doMaximize();
      desktopServer.maximizeWindow();
    }
  };

  private handleCloseWindow = () => {
    desktopServer.closeWindow();
  };

  private handleDragWindow = () => {
    // 禁用手动拖拽标题栏
    // dragDelegate(
    //   (e, delta) => {
    //     const { x, y } = delta;
    //     desktopServer.setPosition(x, y, false);
    //   },
    //   () => {},
    // );
  };

  private doClearKeyDown = (e: React.KeyboardEvent) => {
    e.preventDefault();
  };

  private doClearMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  private handleRenameInput = () => {
    if (this.props.canChange) {
      this.setState({
        editing: true,
      });
    }
  };

  private doCancelEditing = () => {
    this.setState({
      editing: false,
    });
  };

  private handleRename = (value: string, cancel?: boolean) => {
    const newTitle = value?.trim() || '';
    const { title, onChange } = this.props;
    this.doCancelEditing();
    if (title === newTitle || !newTitle || cancel) {
      return;
    }
    this.doChangeEditTitle(newTitle);
    onChange && onChange(newTitle);
  };

  private doChangeEditTitle = (value: string) => {
    this.setState({
      editTitle: value,
    });
  };

  renderMinimizeButton() {
    if (this.props.minimizable) {
      return null;
    }
    return (
      <div className="button" onClick={this.handleMinimizeWindow} onKeyDown={this.doClearKeyDown}>
        <Icon theme="tag" cls="icon_Minimize" />
      </div>
    );
  }

  renderMaximizeButton() {
    if (!this.props.maximizable && !this.state.isMaximized) {
      return (
        <div className="button" onClick={this.handleMaximizeWindow} onKeyDown={this.doClearKeyDown}>
          <Icon theme="tag" size={14} cls="icon_Maximize" />
        </div>
      );
    }
    return null;
  }

  renderUnmaximizeButton() {
    if (!this.props.maximizable && this.state.isMaximized) {
      return (
        <div className="button" onClick={this.handleUnmaximizeWindow} onKeyDown={this.doClearKeyDown}>
          <Icon theme="tag" size={14} cls="icon_Revert" />
        </div>
      );
    }
  }

  renderWindowOperate() {
    if (isMacOS) {
      return null;
    }

    return (
      <div className="desktop-title-close">
        {this.renderMinimizeButton()}
        {this.renderMaximizeButton()}
        {this.renderUnmaximizeButton()}
        <div className="button close" onClick={this.handleCloseWindow} onKeyDown={this.doClearKeyDown}>
          <Icon theme="tag" cls="icon_Close" />
        </div>
      </div>
    );
  }

  renderContent() {
    const { title } = this.props;
    switch (title) {
      case false:
        return '';
      case true:
      case undefined:
        return <span className="label">{i18n('pc.name')}</span>;
      default:
        break;
    }
    const { editTitle } = this.state;
    const { editing } = this.state;
    return editing ? (
      <div onDoubleClick={this.doClearMouseDown}>
        <Input
          value={editTitle}
          className="desktop-title-input"
          autoFocus
          autoSelectWhenFocus
          onBlur={this.handleRename}
          onMouseDown={this.doClearMouseDown}
        />
      </div>
    ) : (
      <Tooltip text={title} popupClassName="desktop-title-tooltip" className="desktop-title-tooltip-content">
        <span className="desktop-title-label" onClick={this.handleRenameInput}>
          {title}
        </span>
      </Tooltip>
    );
  }

  render() {
    const { className, transparent } = this.props;
    return (
      <div
        className={classnames(className, 'desktop-title', { mac: isMacOS, transparent })}
        onMouseDown={this.handleDragWindow}
        onDoubleClick={this.handleDoubleClick}
      >
        {this.renderContent()}
        {this.renderWindowOperate()}
      </div>
    );
  }
}

export default DesktopTitle;
