/**
 * 异常信息收集
 */

export class ExceptionCollector {
  private list: string[] = [];

  get length(): number {
    return this.list.length;
  }
  get value() {
    return this.list;
  }

  add(msg: string): void {
    this.list.push(msg);
  }

  reset() {
    this.list = [];
  }
}

// 全局变量
export const exceptionCollector = new ExceptionCollector();
