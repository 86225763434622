import { ISize } from '@/utils/boundsUtils';
import { svgToDataUri } from '.';

export function dataAsUrl(content: string, type: string): string {
  return 'data:' + type + ';base64,' + content;
}

export function isDataUrl(url: string) {
  return url.search(/^(data:)/) !== -1;
}

/**
所有的Web浏览器都会限制canvas元素的宽度，高度和面积。
对于谷歌浏览器，最大允许的宽度和高度为32,767像素，最大允许面积为268,435,456像素。
对于 Firefox，最大允许宽度和高度为32,767像素，最大允许区域为472,907,776像素。
对于IE，最大允许宽度和高度为8,192像素。
对于 IE Mobile，最大允许宽度和高度为4,096像素。
 */
export const getMaxSize = (width: number, height: number): ISize => {
  // 面积不能超过  2.6
  if (width * height > 260000000) {
    const radio = Math.sqrt(260000000 / (width * height));
    height = Math.floor(height * radio);
    width = Math.floor(width * radio);
  }
  // 最大图片长宽
  const maxWidth = 30000;
  const maxHeight = 30000;
  if (width > maxWidth) {
    height = Math.floor((height * maxWidth) / width);
    width = maxWidth;
  }
  if (height > maxHeight) {
    width = Math.floor((width * maxHeight) / height);
    height = maxHeight;
  }

  return {
    width: width,
    height: height,
  };
};

/**
 * 空白图片
 */
export const blankImageUri = () => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  width="100" height="100" viewBox="0 0 100 100"></svg>`;
  return svgToDataUri(svg);
};

export const blankImage = (): Promise<HTMLImageElement> => {
  return new Promise((reslove) => {
    const image = new Image();
    image.onload = () => {
      reslove(image);
    };
    image.src = blankImageUri();
  });
};

export const genInvalideImage = () => {
  return `<div style='position:absolute'><img width='500' height='500' src='https://d3h2k7ug3o5pb3.cloudfront.net/image/2023-01-29/bfd1bf30-9fb3-11ed-b6f8-6f862fe84d9c.jpg'></div>`;
};

/**
 * fixme: 跨域图片处理 notwork
 * @param url
 */
export const image2Blob = (url: string): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    // 不加这个属性，canvas不能写入
    // 加个这个属性，跨域会与浏览器缓存图片冲突
    // 在 <img> 中和 JS 中的 <img> 都加上 crossorigin = "anonymous"，图片可以正常加了，同时也可以被复用到 <canvas> 上去了
    image.crossOrigin = 'anonymous';
    function resolveImage() {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(image, 0, 0);
        canvas.toBlob((blob) => {
          blob ? resolve(blob) : reject();
        });
      }
    }

    image.onload = resolveImage;
    image.onerror = (e) => {
      reject(e);
    };
    image.src = url + '?id=crossOrigin';
  });
};

export async function getImageSize(url: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
      img.remove();
    };
    img.onerror = () => {
      reject();
      img.remove();
    };
  });
}
