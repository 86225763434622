import { Base } from './base';
import { IPage, IPageF } from './page';
import { IGroup } from './group';
import { IInteraction } from './interaction';
import { IAppPathNode } from './appPathNode';
import { Orientation } from '../../common/models/common';

// v1/app/export/:appID GET 导出所有页面 string
// 项目类型
export enum AppTypes {
  App = 'app',
  AppSet = 'app-set',
  // FIXME: 等待升级
  RP = 'rp-app',
  // FIXME: 等待升级
  Mockplus = 'mockplus-app',
  // FIXME: 等待升级
  Axure = 'axure-app',
  SubDesign = 'sub-design-app',
  SubRP = 'sub-rp-app',
  SubDT = 'sub-dt-app',
  SubMockPlus = 'sub-mockplus-app',
  SubAxure = 'sub-axure-app',
}

// 是否是iDoc项目
export function isNormalApp(appType: AppTypes) {
  return [AppTypes.App, AppTypes.SubDesign].includes(appType);
}

export function isSpecialApp(appType: AppTypes) {
  return [AppTypes.RP, AppTypes.Axure, AppTypes.SubAxure, AppTypes.Mockplus, AppTypes.SubMockPlus].includes(appType);
}

export function needDecorateApp(appType: AppTypes) {
  return [AppTypes.App, AppTypes.RP, AppTypes.Mockplus, AppTypes.Axure].includes(appType);
}

// 是否是RP项目
export function isRpApp(appType: AppTypes) {
  return [AppTypes.RP, AppTypes.SubRP].includes(appType);
}

// 是否是Axure项目
export function isAxureApp(appType: AppTypes) {
  return [AppTypes.Axure, AppTypes.SubAxure].includes(appType);
}

export function isMockplusApp(appType: AppTypes) {
  return [AppTypes.Mockplus, AppTypes.SubMockPlus].includes(appType);
}

// 是否是Axure Mockplus项目
export function isAxureOrMockplus(appType: AppTypes) {
  return [AppTypes.Mockplus, AppTypes.SubMockPlus, AppTypes.Axure, AppTypes.SubAxure].includes(appType);
}

// 是否是项目集
export function isAppSet(appType: AppTypes): boolean {
  return [AppTypes.AppSet].includes(appType);
}

// 是否是普通的项目，非子项目，非项目集
export function isRegularApp(appType: AppTypes): boolean {
  return [AppTypes.App, AppTypes.Mockplus, AppTypes.Axure, AppTypes.RP].includes(appType);
}

// 是否是子项目（即分类）
export function isSubApp(appType: AppTypes): boolean {
  return [AppTypes.SubMockPlus, AppTypes.SubAxure].includes(appType);
}

//摹客设计稿，只支持DT和RP项目
export function isMockplusDesgin(classify: AppClassify): boolean {
  return classify === AppClassify.Mockplus;
}

export enum visibility {
  Private = 'private',
  Internal = 'internal',
  Public = 'public',
}

// /v1/app/joinApp/:appID PATCH 加入项目
// /v1/app/:appID/importFromAxureHTML POST 导入Axure离线演示包
// v1/member/:teamID/:memberID GET 获取成员参与的所有项目
export interface IAppF extends IApp {
  _id: string;
  recycleList: any[];
  author: {
    avatar: string;
    name: string;
    userID: number;
  };
}

// v1/app/ PUT 创建项目
export interface ICreateAppInfo extends IAppF {
  createUserName: string;
  createUserIcon: string;
  pinyinName: string;
  groupCount: number;
  pageCount: number;
}

// v1/app/:appID GET 获取单个项目信息
export interface IGetAppInfo extends IAppF {
  createUserName: string;
  createUserIcon: string;
  pinyinName: string;
  groupCount: number;
  pageCount: number;
}

// v1/app/cloneApp PUT  克隆项目
// v1/app/:appID PATCH  修改项目
export interface ICloneAppInfo extends IAppF {
  createUserName: string;
  createUserIcon: string;
  pinyinName: string;
  groupCount: number;
  pageCount: number;
  originAppID?: string;
  // classicCover: string;
}
export interface ICloneAppSlimInfoF extends IAppAppSetF {
  //原项目id
  originAppID?: string;
}

export interface IAppMembersInfoF {
  id: number;
  name: string;
  email: string;
  avatar: string;
  role?: string;
  teamRole: string;
  teamGroup: number;
  groupName: string;
  memberID: number;
  createdAt: Date;
}

export interface ICloneAppSlimInfoF extends IAppAppSetF {
  //原项目id
  originAppID?: string;
}
// v1/app/:appID/update-members POST 修改项目成员
// v1/app/:appID/members GET 获取项目成员 Array
export interface IAppMembersInfo {
  id: number;
  name: string;
  email: string;
  avatar: string;
  wxOpenID: string;
  role?: string;
  teamRole: string;
  teamGroup: number;
  groupName: string;
  memberID: number;
  createdAt: Date;
}

export interface IMembersInfo {
  appMembers: IAppMembersInfo[];
  groups?: number[];
  parentGroups: number[];
  parentMembers: IAppMembersInfo[];
}

// v1/app/allApp/:teamID GET 获取团队中所有项目 Array
export interface IGetAllAppInfo extends IAppF {
  userName: string;
  memberCount: number;
}

// 获取团队所有项目时每个项目的信息
export interface IAppsOfTeam extends IAppBasicInfo {
  accessedAt?: string;
  allowShare: boolean;
  cover: string;
  createUserName: string;
  createdAt: string;
  desc: string;
  device?: {
    size: {
      width: number;
      height: number;
    };
    name: string;
  };
  isArchived: boolean;
  isExample: boolean;
  name: string;
  ownerAppID: string;
  path: string;
  pinyinName: string;
  shareID: string;
  sort: number;
  source: string;
  state: number;
  index: number;
  tags: string[];
  teamID: string;
  type: AppTypes;
  updatedAt: string;
  updatedBy: number;
  userID: number;
  visibility: visibility;
  _id: string;
  isStar: boolean;
  children?: IAppsOfTeam[];
  usedModule?: {
    rp: boolean;
    design: boolean;
    prototypeDesign: boolean;
  };
  defaultModule?: 'design' | 'rp' | 'prototype' | 'prd';
  archivedAt?: Date;
  archivedBy?: number;
  archivedByName?: string;
  isDT?: boolean;
  userModule?: {
    //项目文档、原型稿、设计稿模块是否有内容
    prd: boolean;
    prototype: boolean;
    design: boolean;
  };
  userDefaultModule?: boolean;
}

// v1/app/multiple/addChildrenToApp/:appID PUT 上传树结构到项目中 包括分组和页面Array<IAppChildPage | IAppChildGroup>
// v1/app/multiple/cloneChildren/:appID PUT 克隆项目树上子元素 包括分组和页面Array<IAppChildPage | IAppChildGroup>
export interface IAppChildPage extends IPage {
  _id: string;
  isGroup: boolean;
  children: Array<IAppChildPage | IAppChildGroup>;
}

export interface IAppChildGroup extends IGroup {
  _id: string;
  isGroup: boolean;
  children: Array<IAppChildPage | IAppChildGroup>;
}

// v1/app/getAllSlicePages/:appID GET 打包下载所有切图 Array<ISliceInPage>
export interface ISliceInPage {
  url?: string;
  name: string;
  w: number;
  h: number;
  eH: number;
  eW: number;
  pageName?: string;
  device?: string;
  bitmapURL: string;
  svgURL: string;
  type?: string;
  pageSize?: {
    width: number;
    height: number;
  };
  clientID?: string;
  pageID?: string;
}

// v1/app/multiple/:appID DELETE 删除项目中页面
// v1/appCollection/ GET 获取所有收藏的项目 Array
export interface IAppInfo extends IAppF {
  id: string;
  userAvatar?: string;//头像
  createUserName?: string;
  archivedByName?: string;
  createUserIcon?: string;
  pinyinName?: string;
  isStar?: boolean;
  groupCount?: number;
  pageCount?: number;
  userName?: string;
  memberCount?: number;
}

// v1/app/editAppArchived/:appID PATCH 改变项目归档状态
export interface IAppArchived {
  archivedAt: Date;
  archivedByName: string;
  successArr?:any,
  errArr?:any,
}

// v1/app/multiple/restore/:appID PATCH 还原项目中页面
export interface IRestoreInfo {
  interactions: Array<IInteraction>;
  app: IAppInfo;
}

// App 类型
export enum IAppType {
  Phone = 'phone',
  Web = 'web',
  Pad = 'pad',
  Custom = 'custom',
}

export enum AppType {
  Phone = 'phone',
  Web = 'web',
}
//项目分类
export enum AppClassify {
  //默认融合项目
  Default = '',
  //DT单项目
  DT = 'DT',
  //RP单项目
  RP = 'RP',
  //摹客设计稿，只支持DT和RP项目
  Mockplus = 'mockplus',
  Example = 'example',
}

export enum Device {
  ios1x = 'ios1x',
  ios2x = 'ios2x',
  ios3x = 'ios3x',
  mdpi = 'mdpi',
  hdpi = 'hdpi',
  xhdpi = 'xhdpi',
  xxhdpi = 'xxhdpi',
  xxxhdpi = 'xxxhdpi',
  Web1x = 'Web1x',
  Web2x = 'Web2x',
}

export enum AppMobileType {
  Pad = 'Pad',
  IPhoneX = 'iPhoneX',
  IPhone = 'iPhone',
  Android = 'Android',
  Null = 'none',
}

export const getAppMobileType = (type: Device): string => {
  const appMobileType: {
    [k in Device]: string;
  } = {
    [Device.ios1x]: AppMobileType.IPhone,
    [Device.ios2x]: AppMobileType.IPhone,
    [Device.ios3x]: AppMobileType.IPhone,
    [Device.mdpi]: AppMobileType.Android,
    [Device.hdpi]: AppMobileType.Android,
    [Device.xhdpi]: AppMobileType.Android,
    [Device.xxhdpi]: AppMobileType.Android,
    [Device.xxxhdpi]: AppMobileType.Android,
    [Device.Web1x]: AppMobileType.Null,
    [Device.Web2x]: AppMobileType.Null,
  };
  return appMobileType[type] ? appMobileType[type] : '';
};

export interface IApp extends Base {
  _id?: string;
  name: string;
  cover?: string;
  // 请勿使用这个字段，这个字段只是为了升级之前的数据留下的 by massimo @ 2020-08-27
  classicCover?: string;
  desc?: string;
  colorVal?: string;
  canvasBgColor?: string;
  linkColor?: string;
  stateRotationTime?: number;
  carouselEffect?: string;
  userAvatar?: string;//头像
  // 项目类型： phone | web
  // appType: string;
  teamID: string;
  // 子项目所属项目 ID
  ownerAppID: string;
  appSetID: string;
  isAppSet: boolean;
  isExample: boolean;
  appSets: Array<any>;
  index: number;
  tags?: Array<string>;
  homepage?: string;
  sort?: number;
  larkToken?: string;
  children?: Array<any>;
  recycle?: Array<any>;
  members: Array<number>;
  mobileType?: string;
  showControllerPanel?: boolean;
  // rp发布的快照ID
  snapshotID?: string;
  showToolbar?: boolean;
  alwaysShowLinkArea?: boolean;
  showLinkAreaWhenHovered?: boolean;
  isPublic?: boolean;
  isPublish?: boolean;
  classify?: AppClassify;
  cloneFrom?: string;
  shareID: string;
  rpShareID?: string;
  isArchived?: boolean;
  autoScreen?: boolean;
  autoHiddenToolbar?: boolean;
  archivedAt?: Date;
  archivedBy?: number;
  // 来源 mockplus | axure | rp
  source: string;
  // 客户端文件的 hash
  clientHash: string;
  pinMode: string;
  groups?: Array<number>;
  // 是否允许非管理员分享项目
  allowShare: boolean;
  // 是否开启页面编号
  showRPPageNumber?: boolean;
  device: {
    name: string;
    size: {
      width: number;
      height: number;
    };
  };
  orientation?: Orientation;
  type: AppTypes;
  size: {
    height: number;
    width: number;
  };
  // 标记是由于rp脚本删除，用于意外的数据恢复
  deleteByRpScript?: boolean;
  dataURL: string;
  imageURL: string;
  appID: string;
  clientID: string;
  hmsr?: string;
  visibility: 'private' | 'internal' | 'public';
  appPathNode?: IAppPathNode;
  hasAuth?: boolean;
  canMoveAppInPathNode?: boolean;
  isDT?: boolean;
  path?: string;
  parent?: IAppF;
  isStar?: boolean;
  accessedAt?: string | Date;
  rpAccessedAt?: Date;
  // 是否开启演示带密码
  previewNeedPassword?: boolean;
  defaultModule?: 'design' | 'rp' | 'prototype' | 'prd';
  libs?: string[];
  usedModule: {
    rp: boolean;
    design: boolean;
    prototypeDesign: boolean;
  };
  // 记录是哪个rp发布过来的
  publishFor?: string;
  // 是否勾选模块
  modules?: {
    prd: {
      enabled: boolean;
      index: number;
    };
    prototypeDesign: {
      enabled: boolean;
      index: number;
    };
    design: {
      enabled: boolean;
      index: number;
    };
    rp: {
      enabled: boolean;
      index: number;
    };
    doc: {
      enabled: boolean;
      index: number;
    };
    style: {
      enabled: boolean;
      index: number;
    };
    activity: {
      enabled: boolean;
      index: number;
    };
    taskmanage: {
      enabled: boolean;
      index: number;
    };
  };
}

/**
 * 项目返回通用数据
 * 这个接口在2021-10-14添加
 * 为配合前端整理返回数据
 */
export interface IAppCommonInfoF extends IAppBasicInfo {
  appSetID: string;
  isArchived: false;
  archivedAt?: Date;
  archivedBy?: number;
  // 是否允许非管理员分享项目
  allowShare: boolean;
  defaultModule?: string;
  createdAt: Date;
  device: {
    name: string;
    size: {
      width: number;
      height: number;
    };
  };
  teamID: string;
  visibility: visibility;
  appPathNode?: IAppPathNode;
  canMoveAppInPathNode?: boolean;
  path?: string;
  // 是否开启演示带密码
  previewNeedPassword?: boolean;
  groups?: number[];
  // 画板设置
  storyboard: {
    canvasBgColor?: string;
    linkColor?: string;
    stateRotationTime?: number;
    carouselEffect?: string;
  };
  // 是否开启页面编号
  showRPPageNumber?: boolean;

  author: {
    userID: number;
    name?: string;
    avatar?: string;
  };
  larkToken: string;
  createUserName?: string;
  userAvatar?: string;//头像
  hasAuth?: boolean;
  updatedAt: string;
  shareID?: string;
  //rp项目是否已经发布
  isPublish?: string;
}

export interface IAppBasicInfo {
  _id: string;
  name: string;
  teamID: string;
  userID: number;
  desc?: string;
  cover?: string;
  isAppSet: boolean;
  members?: Array<number>;
  appSetID: string;
  appSets: string[];
  groups?: Array<number>;
  classify?: AppClassify;
}

// 项目的基本信息，各个模块共享
export interface IAppCommonInfo extends IAppBasicInfo {
  // 项目类型： phone | web
  // appType: string;
  appSetID: string;
  homepage?: string;
  shareID: string;
  isArchived?: boolean;
  archivedAt?: Date;
  archivedBy?: number;
  ownerAppID?: string;
  // 是否允许非管理员分享项目
  allowShare: boolean;
  defaultModule?: string;
  createdAt: Date;
  device: {
    name: string;
    size: {
      width: number;
      height: number;
    };
  };
  visibility: visibility;
  appPathNode?: IAppPathNode;
  canMoveAppInPathNode?: boolean;
  path?: string;
  // 是否开启演示带密码
  previewNeedPassword?: boolean;
  groups?: number[];
  // 画板设置
  storyboard: {
    canvasBgColor?: string;
    linkColor?: string;
    stateRotationTime?: number;
    carouselEffect?: string;
  };

  author: {
    userID: number;
    name?: string;
    avatar?: string;
  };

  // 是否开启页面编号
  showRPPageNumber?: boolean;

  // 是否勾选模块和模块顺序
  modules?: {
    prd: {
      enabled: boolean;
      index: number;
    };
    prototypeDesign: {
      enabled: boolean;
      index: number;
    };
    design: {
      enabled: boolean;
      index: number;
    };
    rp: {
      enabled: boolean;
      index: number;
    };
    doc: {
      enabled: boolean;
      index: number;
    };
    style: {
      enabled: boolean;
      index: number;
    };
    activity: {
      enabled: boolean;
      index: number;
    };
    taskmanage: {
      enabled: boolean;
      index: number;
    };
  };
  larkToken: string;
  createUserName?: string;
  hasAuth?: boolean;
  updatedAt?: string;
}

// 项目模块数据定义
export interface IModuleData {
  _id: string;
  name?: string;
  pages: IPageF[];
  recycle: IPageF[];
  states: any;
  snapshotID?: string;
  device?: {
    size: {
      width: number;
      height: number;
    };
    name: string;
  };
  rpAppID?: string; // rp拆分后，rp那边的appID
  user: {
    id: number;
    name: string;
    avatar: string;
  };
  updatedAt: string;
  homepage?: string;
  type: AppTypes;
  isPublish?: boolean;
  preview: {
    mobileType?: string;
    showControllerPanel?: boolean;
    showToolbar?: boolean;
    alwaysShowLinkArea?: boolean;
    showLinkAreaWhenHovered?: boolean;
    autoScreen?: boolean;
  };
}

export interface IPrototypeInfo {
  _id: string;
  name: string;
  type: AppTypes;
  userID: number;
  updatedBy: number;
  createdAt: string;
  updatedAt: string;
  state: number;
  lastUpdatedUserName?: string;
  index?: number;
  path?: number;
  pinyinName?: string;
  snapshotID?: string;
  needTransformation?: boolean;
  isPublish?: boolean;
}

export enum AppStyle {
  Design = 'design',
  RP = 'rp',
  Prototype = 'prototype',
  AppSet = 'app-set',
  PRD = 'prd',
  DT = 'dt',
}

export const getAppModule = (type: AppTypes): string => {
  const moduleType: {
    [k in AppTypes]: string;
  } = {
    [AppTypes.App]: AppStyle.Design,
    [AppTypes.RP]: AppStyle.RP,
    [AppTypes.Mockplus]: AppStyle.Prototype,
    [AppTypes.Axure]: AppStyle.Prototype,
    [AppTypes.SubDesign]: AppStyle.Design,
    [AppTypes.SubRP]: AppStyle.RP,
    [AppTypes.SubMockPlus]: AppStyle.Prototype,
    [AppTypes.SubAxure]: AppStyle.Prototype,
    [AppTypes.AppSet]: AppStyle.AppSet,
    [AppTypes.SubDT]: AppStyle.DT,
  };
  return moduleType[type];
};

export const getModuleID = (app: any) => {
  if (!app) {
    return AppStyle.Design;
  }
  if (isAxureApp(app.type) || isMockplusApp(app.type)) {
    return app._id;
  }
  if (isRpApp(app.type)) {
    return AppStyle.RP;
  }
  return AppStyle.Design;
};

export interface IAppsF {
  apps: IAppsOfTeam[];
  archivedApp: IAppsOfTeam[];
}
/**
 * 克隆项目返回
 */
export interface ICloneAppTaskF {
  taskID: string;
}
/**
 * 判断删除的成员是否有个人创建项目
 */
export interface IHasAppF {
  hasApp: boolean;
}

/**
 *
 */
export interface IAppAppSetF extends IAppBasicInfo {
  accessedAt: string;
  allowShare: boolean;
  cover: string;
  createUserName: string;
  userAvatar?: string;//头像
  createdAt: string;
  isArchived: boolean;
  name: string;
  ownerAppID: string;
  pinyinName: string;
  source: string;
  index: number;
  tags: string[];
  teamID: string;
  type: AppTypes;
  updatedAt: string;
  updatedBy: number;
  userID: number;
  visibility: visibility;
  _id: string;
  isStar: boolean;
  children: IAppsOfTeam[];
  usedModule: {
    rp: boolean;
    design: boolean;
    prototypeDesign: boolean;
  };
  defaultModule: AppStyle;
  archivedAt?: Date;
  archivedBy?: number;
  rpAccessedAt?: Date;
  userModule?: {//项目文档、原型稿、设计稿模块是否有内容
    prd: boolean;
    prototype: boolean;
    design: boolean;
  };
  userDefaultModule?: boolean;//
}

/**
 *
 */
export interface IAllAppAppSetF {
  apps: IAppAppSetF[];
  archivedApp: IAppAppSetF[];
}
/**
 * child字段类型
 */
export interface IAppChild {
  id: string;
  isGroup: boolean;
  children: Array<IAppChild>;
}
