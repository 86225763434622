import * as React from 'react';
import * as ReactDOM from 'react-dom';
import classnames from 'classnames';

import i18n from '@/i18n';
import { IToast } from '@/store/global/reducers';
import { ToastType } from '@/store/types';

import { Icon, Button } from '@dsm';
import IndexedDBErrorTip from '../IndexedDBErrorTip';

import './index.scss';

interface IToastMessageProps {
  toast: IToast[];
  dispacher: {
    closeToast: (type: ToastType) => void;
  };
}

const GlobalToast = (props: IToastMessageProps) => {
  const { toast, dispacher } = props;
  const getToastIconName = (toast?: IToast) => {
    switch (toast?.type) {
      case ToastType.Success:
        return 'icon_tag_resolved';
      case ToastType.Error:
      case ToastType.Info:
        return 'tag_exclamation';
      default:
        return toast?.options?.iconCls;
    }
  };

  const renderItemToast = (toast: IToast) => {
    const type = toast.type;
    const iconColor = toast.options?.iconColor;
    const iconCls = getToastIconName(toast);

    if (type === ToastType.IndexedDBError) {
      return <IndexedDBErrorTip key={type} className="global-toast" />;
    }
    return (
      <div className="global-toast" key={type}>
        {iconCls && (
          <Icon
            size={14}
            className={classnames(
              'global-toast-icon',
              `global-toast-icon-color-${type}`,
              `global-toast-icon-position-${toast.options?.rightIcon ? 'right' : 'left'}`,
            )}
            cls={iconCls}
            theme="tag"
            style={{ color: iconColor }}
          />
        )}
        <span className="global-toast-text">{toast.tip}</span>
        {toast.btnOptions?.submitBtn && (
          <Button
            className="global-toast-button global-toast-button-submit"
            theme="onlyText"
            onClick={() => {
              toast.btnOptions?.onSubmit && toast.btnOptions?.onSubmit();
            }}
          >
            {toast.btnOptions?.submitBtn}
          </Button>
        )}
        {toast.btnOptions?.closeBtn && (
          <Button
            className="global-toast-button global-toast-button-close"
            theme="onlyText"
            onClick={() => {
              toast.btnOptions?.onClose && toast.btnOptions?.onClose();
              dispacher.closeToast(toast.type);
            }}
          >
            {toast.btnOptions?.closeBtn}
          </Button>
        )}
        {toast.btnOptions?.needCloseIcon && (
          <div className="global-toast-icon-close">
            <Icon
              onClick={() => {
                dispacher.closeToast(toast.type);
              }}
              cls="close"
            />
          </div>
        )}
        {/* 添加联系我们 */}
        {!RP_CONFIGS.isPrivateDeployment && toast.btnOptions?.needSupport && (
          <a className="support" href={i18n('support.url')} target="support" rel="noopener">
            {i18n('support.label')}
          </a>
        )}
      </div>
    );
  };
  return ReactDOM.createPortal(
    toast.map((item) => renderItemToast(item)),
    document.getElementById('notice-container') as HTMLDivElement,
  );
};

export default GlobalToast;
