import { IListSealedComponentItemEvents } from '@libs/types';
import { UIComponent } from '@editor/comps';

export interface IDomEvents {
  [key: string]: React.MouseEventHandler | React.FocusEventHandler | React.TouchEventHandler;
}

export interface IDomListEvent {
  [key: string]: IDomEvents;
}

/**
 * ReactElements上可支持的事件，key是React的标准事件属性名称
 */
const mouseEventConfig: { [key: string]: keyof IListSealedComponentItemEvents } = {
  onClick: 'onItemClick',
  onDoubleClick: 'onItemDoubleClick',
  onMouseDown: 'onItemMouseDown',
  onMouseUp: 'onItemMouseUp',
  onMouseEnter: 'onItemEnter',
  onMouseLeave: 'onItemLeave',
  onContextMenu: 'onItemContextMenu',
  onTouchStart: 'onItemTouchStart',
  onTouchEnd: 'onItemTouchEnd',
};

/**
 * 获取列表类复合组件各项上可支持的事件
 * @param {UIComponent[]} comps
 * @param {IListSealedComponentItemEvents} events
 * @returns {IDomListEvent}
 */
export function getListEvents(comps: UIComponent[], events?: IListSealedComponentItemEvents): IDomListEvent {
  const result: IDomListEvent = {};
  events &&
    comps.forEach((comp) => {
      const itemEvent: IDomEvents = {};
      result[comp.id] = itemEvent;
      Object.keys(mouseEventConfig).forEach((key) => {
        const v = mouseEventConfig[key];
        const evt = events![v];
        if (evt) {
          itemEvent[key] = (e: React.MouseEvent | React.TouchEvent | React.FocusEvent) => {
            // TODO Matt 2021-4-25 这里临时忽略警告，目前这里是把所有事件类型以一种通用的方式处理的
            // @ts-ignore
            evt(e, comp);
          };
        }
      });
    });
  return result;
}
