import { IComponentData } from '@/fbs/rp/models/component';
import { EChartType_basic } from '@/fbs/rp/models/properties/chart';
import { IChartValue } from '@/fbs/rp/models/chart';
import { makeCommonComponent } from '@/libs/helper';

import { IComponentItem } from '../../../types';
import { CAreaChart } from '../../../constants';
import { getChartConfig, makeChartData, getSeriesChartOneDefaultData } from '../common';

export const AreaChartConfig: IComponentItem = getChartConfig(CAreaChart, EChartType_basic.Area);

export function makeAreaChart(id: string): IComponentData {
  return makeChartData(id, CAreaChart, EChartType_basic.Area);
}

export function makeAIAreaChart(id: string, value: IChartValue): IComponentData {
  return makeCommonComponent(id, CAreaChart, {
    ...getSeriesChartOneDefaultData(CAreaChart, EChartType_basic.Area),
    size: {
      width: 400,
      height: 250,
    },
    lib: {
      id: 'chart',
      type: CAreaChart,
    },
    value: value,
  });
}
