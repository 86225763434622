import IComponent, { IRelation } from '@fbs/rp/models/ds/component';
import { IComponentData } from '@fbs/rp/models/component';
import { axDelete, patch, post, put } from '../helper';

interface IAddComponentParam {
  thumb: string;
  component: Partial<IComponentData>;
  name: string;
  description?: string;
  groupID?: string;
  appID?: string;
}

interface IPayloadData extends Partial<IComponent> {
  appID?: string;
}

interface IRemoveParams {
  appID: string;
}

export async function addComponent(libID: string, data: IAddComponentParam): Promise<IComponent> {
  return await put<IComponent>(`/ds/component/${libID}`, data);
}

export async function removeComponent(id: string, data: IRemoveParams): Promise<void> {
  await axDelete(`/ds/component/${id}`, {
    data,
  });
}

export async function patchComponent(id: string, data: IPayloadData): Promise<IComponent> {
  return await patch(`/ds/component/${id}`, data);
}

export async function moveComponent(libID: string, resID: string, index: number): Promise<IComponent> {
  return await post<IComponent>(`/ds/component/${libID}/move/${resID}`, { index });
}

export async function resetComponentsGroup(libID: string, resIDs: string[], groupID: string): Promise<any> {
  return await post(`/ds/component/${libID}/moveTo/${groupID}`, { components: resIDs });
}

export async function removeComponents(componentIDs: string[], data: IRemoveParams): Promise<any> {
  return await axDelete(`/ds/component/remove/components`, {
    data: {
      ids: componentIDs,
      ...data,
    },
  });
}

/**
 * 新增组件关联
 * @param relation
 */
export async function addRelations(componentID: string, relations: Partial<IRelation>[]): Promise<IRelation[]> {
  return await post(`/ds/component/${componentID}/relations`, { relations });
}

/**
 * 批量修改组件关联
 * @param componentID
 * @param relations
 */
export async function patchRelations(componentID: string, relations: Partial<IRelation>[]) {
  return await patch(`/ds/component/${componentID}/relations`, { relations });
}

/**
 * 批量删除组件关联
 * @param componentIDs
 */
export async function removeRelations(componentID: string, componentIDs: string[]) {
  return await axDelete(`/ds/component/${componentID}/relations`, { data: componentIDs });
}

/**
 * 非同步资源批量转为同步资源
 * @param componentIDs
 */
export function convertToSymbol(componentIDs: string[]): Promise<null> {
  return patch(`/ds/component/transform/toSymbol`, { componentIDs });
}

/**
 * 将symbol资源转换为普通资源
 * @param componentIDs
 */
export function convertSymbolToNormal(componentIDs: string[]): Promise<null> {
  return patch(`/ds/component/transform/toNormal`, { componentIDs });
}
