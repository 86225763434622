import * as React from 'react';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { UITreeComponent, UITreeItemComponent } from '@editor/comps';
import { FloatPanel, IAutoCloseComponentProps, withAutoClose, ScrollBars, Icon } from '@dsm';

import { depthClone } from '@utils/globalUtils';
import { dragDelegate } from '@/utils/mouseUtils';
import { isControlKeyPressed } from '@utils/hotkeysUtils';

import CoreEditor from '@editor/core';
import { IBounds } from '@fbs/common/models/common';
import ITree from '@fbs/rp/models/properties/tree';
import { ArtboardPatches } from '@fbs/rp/utils/patch';
import { IconValue, ITreeData } from '@fbs/rp/models/value';

import { CCollapse } from '@/libs/constants';
import EditorContext from '@contexts/editor';
import { MaxInsertLevel } from '@consts/defaultData/tree';
import { COLLAPSE_MAX_LEVEL } from '@/consts/defaultData/collapse';
import {
  ITreeItem,
  moveNode,
  parseTreeData,
  createAvlTreeWithTreeData,
  createAvlTreeWithTreeDataMapStatus,
  DropMode,
  TreeCheckboxState,
  TreeItemColumn,
} from '@helpers/treeCompHelper';
import { getNewID } from '@helpers/idHelper';
import { mergePatches } from '@/helpers/patchHelper';
import { AvlTree } from '@tyriar/avl-tree';
import { getShortCutKey } from '@helpers/shortCutHelper';

import i18n from '@i18n';
import { IAutoCloseProps, IPopupComponent } from '@dsm';
import { IValueEditorPanel } from '@/customTypes';
import KeyCodeMap from '@/dsm2/constants/KeyCodeMap';

import DragTreeItem from './DragTreeItem';
import withReplaceIcon, { InjectedReplaceIconProps, ReplaceIconImplements, ReplaceIconProps } from '../withReplaceIcon';
import CollapseItem from './DragTreeItem/CollapseItem';

import './index.scss';

interface ITreeEditorProps extends IAutoCloseComponentProps {
  comp: UITreeComponent;
  coreEditor: CoreEditor;
  bounds: IBounds;
}

interface ITreeEditorState {
  position: {
    left: number;
    top: number;
  };
  selectedIndex: number;
  editIndex?: number;
}

class TreeEditor extends React.Component<ITreeEditorProps & InjectedReplaceIconProps, ITreeEditorState>
  implements ReplaceIconImplements {
  static contextType = EditorContext;
  private contentDom: React.RefObject<HTMLDivElement> = React.createRef();
  private mapIdWithIndex: { [key: string]: number } = {};
  private scrollBar: React.RefObject<ScrollBars> = React.createRef();
  private avlTree: AvlTree<string, ITreeItem> = new AvlTree<string, ITreeItem>();
  private showSelectBox: boolean = false;
  constructor(props: ITreeEditorProps & InjectedReplaceIconProps) {
    super(props);
    this.state = {
      position: {
        left: props.bounds.left,
        top: props.bounds.top,
      },
      selectedIndex: 0,
      editIndex: 0,
    };
    this.mapIdWithIndex = props.comp.mapIDWithIndex();
    this.props.setAllIconIds(Object.keys(this.mapIdWithIndex));
  }

  componentDidMount() {
    const { selectedIndex } = this.state;
    const selectID = Object.keys(this.mapIdWithIndex)[selectedIndex];
    this.doSetCompSelectItem(selectedIndex, selectID);
    this.context.uiManager.valueEditor = this;
  }

  componentWillUnmount() {
    this.context.uiManager.valueEditor = undefined;
    this.props.comp.selectedItem = undefined;
    this.context.uiManager.interactionPanel?.refresh();
    this.context.uiManager.updatePropertiesPanel();
  }

  UNSAFE_componentWillReceiveProps(nextProps: ITreeEditorProps) {
    this.mapIdWithIndex = nextProps.comp.mapIDWithIndex();
    this.props.setAllIconIds(Object.keys(this.mapIdWithIndex));
  }

  get canReplaceIcon() {
    return (this.props.comp.properties.treeNode as ITree)?.isShow;
  }

  doSubmit = (patches: ArtboardPatches) => {
    const { coreEditor } = this.props;
    coreEditor.updateSingleArtboard(coreEditor.activeArtboard.artboardID, patches);
  };

  dowAddNewToNext = (selectedIndex: number) => {
    const newID = getNewID();
    const { comp } = this.props;
    const targetID = comp.findTreeNode(selectedIndex);
    const patches = comp.insertNewNodeAsSibling(targetID, newID);
    this.doSubmit(patches);
    const newIndex = comp.findTreeNodeIndexById(newID);
    this.setState({ selectedIndex: newIndex, editIndex: newIndex }, () => {
      this.doScroll();
      const selectID = Object.keys(this.mapIdWithIndex)[this.state.selectedIndex];
      this.doSetCompSelectItem(this.state.selectedIndex, selectID);
    });
  };

  doNodeUp = (selectedIndex: number) => {
    const { comp } = this.props;
    const targetID = comp.findTreeNode(selectedIndex);
    if (targetID) {
      const patches = comp.doMoveNodeUp(targetID);
      if (patches) {
        this.doSubmit(patches);
        const selectedIndex = comp.findTreeNodeIndexById(targetID);
        this.setState({ selectedIndex: selectedIndex });
      }
    }
  };

  doNodeDown = (selectedIndex: number) => {
    const { comp } = this.props;
    const targetID = comp.findTreeNode(selectedIndex);
    if (targetID) {
      const patches = comp.doMoveNodeDown(targetID);
      if (patches) {
        this.doSubmit(patches);
        const selectedIndex = comp.findTreeNodeIndexById(targetID);
        this.setState({ selectedIndex: selectedIndex });
      }
    }
  };

  doRemoveNode = (selectedIndex: number) => {
    const { comp, selectedNodeIcons, setSelectedIconIds } = this.props;
    const targetID = comp.findTreeNode(selectedIndex);
    if (targetID) {
      const patches = comp.deleteNode(targetID);
      this.doSubmit(patches);
      const length = Object.keys(this.mapIdWithIndex).length;
      const newSelectIndex = selectedIndex === length - 1 ? selectedIndex - 1 : selectedIndex;
      const newSelectedIcons = selectedNodeIcons.filter((node) => node !== targetID);
      setSelectedIconIds(newSelectedIcons);
      this.setState({ selectedIndex: newSelectIndex, editIndex: undefined }, () => {
        const selectID = Object.keys(this.mapIdWithIndex)[this.state.selectedIndex];
        this.doSetCompSelectItem(this.state.selectedIndex, selectID);
      });
    }
  };

  doScroll = () => {
    const { selectedIndex } = this.state;
    if (this.scrollBar.current && selectedIndex !== undefined) {
      const scrollBar = this.scrollBar.current;
      // 已滚动距离
      const top = scrollBar.getScrollTop();
      const selTop = 30 * selectedIndex;
      const selBottom = selTop + 30;
      // 视口范围
      const viewHeight = scrollBar.getClientHeight();
      if (selTop - top >= viewHeight) {
        scrollBar.scrollTop(selBottom - viewHeight + top);
      } else if (selBottom - top <= 0) {
        scrollBar.scrollTop(selTop);
      }
    }
  };

  doSetCompSelectItem = (selectedIndex: number, selectedID: string) => {
    const { comp } = this.props;
    this.setState(
      {
        selectedIndex: selectedIndex,
      },
      () => {
        comp.selectedItem = comp.getItemCompById(selectedID);
        this.context.uiManager.interactionPanel?.refresh();
        this.context.uiManager.updatePropertiesPanel();
      },
    );
  };

  // 树组件更改图标，外部执行该方法
  public replaceIconValue = (iconValue: IconValue) => {
    const { selectedNodeIcons } = this.props;
    const { comp } = this.props;
    if (selectedNodeIcons.length > 0) {
      const initialPatches: ArtboardPatches = { do: {}, undo: {} };
      const patches = selectedNodeIcons.reduce((prev, id) => {
        const itemComp = comp.getItemCompById(id);
        if (itemComp) {
          const itemPatches = itemComp.modifyCompValuePathes(TreeItemColumn.Node, true, iconValue);
          mergePatches(prev, itemPatches);
        }
        return prev;
      }, initialPatches);
      this.doSubmit(patches);
    }
  };

  handleItemClick = (selectedID: string) => {
    const selectedIndex = this.mapIdWithIndex[selectedID];
    if (selectedIndex !== this.state.selectedIndex) {
      this.doSetCompSelectItem(selectedIndex, selectedID);
    }

    if (!isUndefined(this.state.editIndex)) {
      this.setState({ editIndex: undefined });
    }
  };

  handleMouseEnterItem = (id: string) => {
    const { comp } = this.props;
    const panelComp = comp.components.find((comp) => comp.id === id);
    this.context.uiManager.projectTree?.refresh(panelComp);
  };

  handleItemDoubleClick = (selectedID: string) => {
    const selectedIndex = this.mapIdWithIndex[selectedID];
    this.setState({ editIndex: selectedIndex });
  };

  handleEditNext = (itemComp: UITreeItemComponent, offset: number, canAdd?: boolean) => {
    setTimeout(() => {
      const index = this.mapIdWithIndex[itemComp.id];
      const next = index + offset;
      if (next === Object.keys(this.mapIdWithIndex).length) {
        if (canAdd) {
          this.dowAddNewToNext(index);
        }
      } else {
        this.setState({ selectedIndex: next, editIndex: next }, () => {
          this.doSetCompSelectItem(next, itemComp.id);
          this.doScroll();
        });
      }
    }, 0);
  };

  handleValueChanged = (itemComp: UITreeItemComponent, text: string) => {
    const patches = itemComp.modifyTextPatches(text);
    this.doSubmit(patches);
    if (this.contentDom.current) {
      this.contentDom.current!.focus();
    }
    this.setState({ editIndex: undefined });
  };

  handleDropEnd = (dragItemID: string, dropItemID: string, dropMode: DropMode) => {
    if (dropMode === DropMode.None) return;
    const { comp } = this.props;
    const { relation, expandACollapseIcon } = comp.value as ITreeData;
    const { tree, treeRelation } = createAvlTreeWithTreeData(depthClone(relation));
    const drag = tree.get(dragItemID);
    const drop = tree.get(dropItemID);
    if (drag && drop) {
      const dragLevel = comp.getChildLevel(drag);
      const dropLevel = comp.getNodeLevel(drop);

      if (dropMode === DropMode.Child && dragLevel + dropLevel > MaxInsertLevel) {
        return;
      }

      if ((dropMode === DropMode.Before || dropMode === DropMode.After) && dragLevel + dropLevel > MaxInsertLevel + 1) {
        return;
      }

      moveNode(treeRelation, drag, drop, dropMode);
      const newValue = parseTreeData(treeRelation);
      this.props.coreEditor.setValue(comp.type, { expandACollapseIcon, relation: newValue });
      this.props.setSelectedIconIds([]);
    }
  };

  handleExpandClick = (id: string) => {
    const { comp } = this.props;
    const pathes = comp.modifyExpandState(id);
    this.doSubmit(pathes);
    this.setState({ editIndex: undefined });
  };

  handleSelectClick = (id: string) => {
    const { comp } = this.props;
    const pathes = comp.doDefaultChecked(id);
    this.doSubmit(pathes);
    this.setState({ editIndex: undefined });
  };

  handleInteractionDragger = (e: React.MouseEvent, selectedID: string) => {
    const { comp } = this.props;
    const panelComp = comp.components.find((comp) => comp.id === selectedID);
    if (panelComp) {
      this.context.uiManager.workSpace?.page?.startFindInteractionTarget(e, panelComp);
    }
    this.setState({ editIndex: undefined });
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    const { selectedIndex } = this.state;
    if (selectedIndex === undefined) {
      return;
    }

    if (e.keyCode === KeyCodeMap.VK_ENTER) {
      e.stopPropagation();
      // @ts-ignore
      if (isControlKeyPressed(e)) {
        setTimeout(() => {
          this.dowAddNewToNext(selectedIndex);
        }, 0);
      } else {
        this.setState({ selectedIndex: selectedIndex, editIndex: selectedIndex });
      }
      return;
    }
    if (e.keyCode === KeyCodeMap.VK_BACKSPACE || e.keyCode === KeyCodeMap.VK_DEL) {
      e.stopPropagation();
      this.doRemoveNode(selectedIndex);
      return;
    }

    if ([37, 38, 39, 40].includes(e.keyCode)) {
      e.stopPropagation();
    }

    // @ts-ignore
    if (isControlKeyPressed(e)) {
      if (e.keyCode == KeyCodeMap.VK_UP) {
        if (selectedIndex === 0) {
          return;
        }
        this.doNodeUp(selectedIndex);
      } else if (e.keyCode == KeyCodeMap.VK_DOWN) {
        this.doNodeDown(selectedIndex);
      }
      // @ts-ignore
    } else if (!isControlKeyPressed(e)) {
      if (e.keyCode === KeyCodeMap.VK_UP) {
        if (selectedIndex !== 0) {
          this.setState({ selectedIndex: selectedIndex - 1 }, () => {
            this.doScroll();
            const selectedID = Object.keys(this.mapIdWithIndex)[selectedIndex];
            this.doSetCompSelectItem(this.state.selectedIndex, selectedID);
          });
        }
      } else if (e.keyCode === KeyCodeMap.VK_DOWN) {
        if (selectedIndex !== Object.keys(this.mapIdWithIndex).length - 1) {
          this.setState({ selectedIndex: selectedIndex + 1 }, () => {
            this.doScroll();
            const selectedID = Object.keys(this.mapIdWithIndex)[selectedIndex];
            this.doSetCompSelectItem(this.state.selectedIndex, selectedID);
          });
        }
      }
    }
  };

  handleAddNewToNext = () => {
    setTimeout(() => {
      const { selectedIndex } = this.state;
      this.dowAddNewToNext(selectedIndex);
    }, 0);
  };

  hanleApendChild = () => {
    const { comp } = this.props;
    const { selectedIndex } = this.state;
    const newNodeID = getNewID();
    const targetID = comp.findTreeNode(selectedIndex);
    if (targetID) {
      const patches = comp.insertNewNode(targetID, newNodeID);
      this.doSubmit(patches);
    }
  };

  handleMoveUp = () => {
    const { selectedIndex } = this.state;
    this.doNodeUp(selectedIndex);
  };

  handleMoveDown = () => {
    const { selectedIndex } = this.state;
    this.doNodeDown(selectedIndex);
  };

  handleRemoveItem = () => {
    const { selectedIndex } = this.state;
    this.doRemoveNode(selectedIndex);
  };

  // 拖拽
  handleTitleMoveDown = () => {
    const panelDom = this.props.forwardedRef?.current;
    if (!panelDom) return;
    const maxLeft = window.innerWidth - panelDom!.clientWidth;
    const maxTop = window.innerHeight - panelDom!.clientHeight;
    const { position } = this.state;
    const startPoint = { left: position.left, top: position.top };
    const onMoving = (e: MouseEvent, delta: { x: number; y: number }) => {
      const newPosition = {
        left: startPoint.left + delta.x,
        top: startPoint.top + delta.y,
      };
      if (newPosition.left < 0) {
        newPosition.left = 0;
      } else if (newPosition.left > maxLeft) {
        newPosition.left = maxLeft;
      }
      if (newPosition.top < 0) {
        newPosition.top = 0;
      } else if (newPosition.top > maxTop) {
        newPosition.top = maxTop;
      }
      this.setState({ position: newPosition });
    };

    const onMoveEnd = () => {};

    dragDelegate(onMoving, onMoveEnd);
  };

  handleItemChecked = (itemComp: UITreeItemComponent, checked: boolean) => {
    // 判断当前是否为父节点
    // this.avlTree.get()
    let components = this.props.comp.components;
    let compDataItem = this.avlTree.get(itemComp.id);
    if (compDataItem?.children && compDataItem.children.length > 0) {
      let patchs: ArtboardPatches = { do: {}, undo: {} };
      if (compDataItem.checked === TreeCheckboxState.Half || compDataItem.checked === TreeCheckboxState.UnChecked) {
        checked = true;
      } else {
        checked = false;
      }
      let updateState = (treeItem: ITreeItem[]) => {
        treeItem.forEach((child) => {
          let childComp = components.find((d) => d.id === child.data.id) as UITreeItemComponent;
          if (child.children && child.children.length) {
            updateState(child.children);
          }
          if (childComp) {
            let currentPatchs = childComp.checkBoxComp.changeSelfSelected(checked);
            patchs.do = {
              ...patchs.do,
              ...currentPatchs.do,
            };
            patchs.undo = {
              ...patchs.undo,
              ...currentPatchs.undo,
            };
          }
        });
      };
      updateState(compDataItem.children);
      this.doSubmit(patchs);
    } else {
      let checkBoxComp = itemComp.checkBoxComp;
      let pathschs = checkBoxComp.changeSelfSelected(checked);
      this.doSubmit(pathschs);
    }
  };

  renderToolbar = () => {
    const { selectedIndex } = this.state;
    const { comp } = this.props;
    const { relation } = comp.value as ITreeData;
    const selectedID = comp.findTreeNode(selectedIndex);
    const selectedNode = this.avlTree.get(selectedID);
    const parent = selectedNode?.parent;
    const collaction = parent ? parent.data.children! : relation;
    let minIndex = 0;
    let maxIndex = 0;
    if (collaction?.length && selectedIndex !== undefined) {
      minIndex = this.mapIdWithIndex[collaction[0].id];
      maxIndex = this.mapIdWithIndex[collaction[collaction.length - 1].id];
    }

    const level = (selectedNode && comp.getNodeLevel(selectedNode)) || 0;
    let canAddChild = level < MaxInsertLevel && !!selectedNode;
    if (comp.type === CCollapse) {
      canAddChild = level < COLLAPSE_MAX_LEVEL;
    }

    const noDelete = relation.length == 1 && selectedIndex === 0;

    return (
      <div className="value-item-editor-toolbar" onMouseDown={this.handleTitleMoveDown}>
        <div className="left-operation">
          <Icon
            tips={`${i18n('editor.append')} (${getShortCutKey('Enter', { ctrlKey: true })})`}
            cls="layer_plus"
            onClick={this.handleAddNewToNext}
            size={16}
          />
          <Icon
            tips={`${i18n('editor.addChild')}`}
            cls="icon_add_subitem"
            disabled={!canAddChild}
            onClick={this.hanleApendChild}
            size={16}
          />
        </div>
        <div className="right-opertation">
          <Icon
            tips={`${i18n('editor.up')} (${getShortCutKey('↑', { ctrlKey: true })})`}
            cls="icon_arrow_up"
            disabled={isUndefined(selectedIndex) || selectedIndex <= minIndex}
            onClick={this.handleMoveUp}
            size={16}
          />
          <Icon
            tips={`${i18n('editor.down')} (${getShortCutKey('↓', { ctrlKey: true })})`}
            cls="icon_arrow_down"
            disabled={isUndefined(selectedIndex) || selectedIndex === -1 || selectedIndex >= maxIndex}
            onClick={this.handleMoveDown}
            size={16}
          />
          <Icon
            tips={`${i18n('general.delete')} (${getShortCutKey('Delete')})`}
            cls="demo_delete"
            disabled={isUndefined(selectedIndex) || selectedIndex === -1 || noDelete}
            onClick={this.handleRemoveItem}
            size={16}
          />
        </div>
      </div>
    );
  };

  renderTreeItems = (children: ITreeItem[], level: number) => {
    return children.map((child) => {
      return this.renderItem(child, level);
    });
  };

  renderItem = (item: ITreeItem, level: number) => {
    const { comp, selectedNodeIcons, onFontIconClick } = this.props;
    const { selectedIndex, editIndex } = this.state;
    const itemComp = comp.getItemCompById(item.data.id);
    if (!itemComp) return;

    const selected = comp.findTreeNode(selectedIndex) === item.data.id;
    const { showSelectBox } = this;

    const nodeIconSelected = selectedNodeIcons.includes(item.data.id);

    //自动选中
    let autoStartEditor = !isUndefined(editIndex) && comp.findTreeNode(editIndex) === item.data.id;
    let checked =
      item.checked === TreeCheckboxState.Checked ? true : item.checked === TreeCheckboxState.Half ? undefined : false;

    const C = comp.type === CCollapse ? CollapseItem : DragTreeItem;
    return (
      <C
        key={item.data.id}
        item={item}
        level={level}
        showSelectBox={showSelectBox}
        showNodeIcon={this.canReplaceIcon}
        itemComp={itemComp}
        selected={selected}
        checked={checked}
        nodeIconSelected={nodeIconSelected}
        autoStartEditor={autoStartEditor}
        onMouseEnter={this.handleMouseEnterItem}
        draggable={isUndefined(editIndex)}
        onInteractionDragger={this.handleInteractionDragger}
        onItemClick={this.handleItemClick}
        onItemDoubleClick={this.handleItemDoubleClick}
        onItemChecked={this.handleItemChecked}
        onEditNext={this.handleEditNext}
        onChangeValue={this.handleValueChanged}
        onDropEnd={this.handleDropEnd}
        onExpandClick={this.handleExpandClick}
        onSelectClick={this.handleSelectClick}
        onFontIconClick={onFontIconClick}
      >
        {item.children?.length && item.data.expand && this.renderTreeItems(item.children!, level + 1)}
      </C>
    );
  };

  renderTree = () => {
    const { comp } = this.props;
    const { relation } = comp.value as ITreeData;
    const showSelectBox = (comp.properties.treeCheckbox as ITree)?.isShow || false;
    const childComps = (comp as UITreeComponent).components.map((comp) => comp.$data);
    const { tree, treeRelation } = createAvlTreeWithTreeDataMapStatus(relation, childComps);
    this.avlTree = tree;
    this.showSelectBox = showSelectBox;
    return this.renderTreeItems(treeRelation, 0);
  };

  render() {
    const { position } = this.state;
    return (
      <FloatPanel
        customRef={this.props.forwardedRef}
        className={classNames('editor-tree-value-editor', this.props.floatPanelClassName)}
        style={position}
      >
        <div ref={this.contentDom} className="tree-value-editor-content" tabIndex={-1} onKeyDown={this.handleKeyDown}>
          {this.renderToolbar()}
          <div style={{ height: 320 }}>
            <ScrollBars ref={this.scrollBar} hiddenHorizontalScrollBar>
              <ul style={{ marginTop: 1 }}>{this.renderTree()}</ul>
            </ScrollBars>
          </div>
        </div>
      </FloatPanel>
    );
  }
}

// export default withAutoClose<ITreeEditorProps>(TreeEditor);

const TreeEditorWithNodeReplace: React.ComponentClass<ITreeEditorProps> = withReplaceIcon<
  ITreeEditorProps & ReplaceIconProps
>(TreeEditor);

const PanelClass: React.ComponentClass<ITreeEditorProps & IAutoCloseProps> = withAutoClose<ITreeEditorProps>(
  TreeEditorWithNodeReplace,
);

export default class C extends React.Component<ITreeEditorProps & IAutoCloseProps & ReplaceIconProps>
  implements IValueEditorPanel {
  static contextType = EditorContext;

  componentDidMount() {
    this.context.uiManager.listItemValueEditorPanel = this;
  }

  componentWillUnmount() {
    this.context.uiManager.listItemValueEditorPanel = undefined;
  }

  get preventClose(): boolean {
    if (this.panel.current) {
      return !!(this.panel.current as IPopupComponent).preventClose;
    }
    return false;
  }

  set preventClose(value: boolean) {
    if (this.panel.current) {
      (this.panel.current as IPopupComponent).preventClose = value;
    }
  }

  // @ts-ignore
  panel: React.RefObject<PanelClass> = React.createRef();

  render() {
    return <PanelClass ref={this.panel} {...this.props} />;
  }
}
