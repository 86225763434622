import PropertyBase from './base';

export default interface ICell extends PropertyBase {
  //行高
  rowHeight?: number;
  //列宽
  columnWidth?: number;
  // 是否等高，等高时，忽略行高属性，各子组件平分父的高度
  ratioHeight?: boolean;
  // 是否等宽，等宽时，忽略列宽属性，各子组件平分父的宽度
  ratioWidth?: boolean;

  columnCount?: number; //行数
  rowCount?:number; //列数

  columnGap?: number; //垂直间距
  rowGap?: number; //水平间距
}

export const CellPropertyName = 'cell';
