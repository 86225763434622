// Chrome Extension Service
import { loadFromCache } from '@utils/cacheUtils';

const version = '1.0.3';
// const ExtID = 'kpnahmpoedhhpcdajfndhlblfmakenam';//'apcolpiphipoegdbnplmemobcbjjbigg';

let fonts: LocalFont[] = [];

export enum extensionsType {
  sign = 'sign',
  version = 'version',
  captured = 'captured',
  fonts = 'fonts',
}

export enum extensionError {
  noChrome = 'noChrome',
  noExtension = 'noExtension', // no ext or disabled or cs not run
  exception = 'exception',
  version = 'version',
  time = 'Response timeout',
}

interface LocalFont {
  displayName: string;
  fontId: string;
}

export async function getLocalFonts(): Promise<LocalFont[]> {
  if (!window.chrome || !window.chrome.runtime || !window.chrome.runtime.sendMessage) {
    return fonts;
  }
  return new Promise((resolve) => {
    return resolve([]);
    // window.chrome?.runtime.sendMessage(ExtID, { action: 'getFontList' }, (response: any) => {
    //   if (!response || !response.fontList) {
    //     resolve([]);
    //     return;
    //   }
    //   fonts = response.fontList;
    //   resolve(fonts);
    // });
  });
}

export function sendMessageToExtensions<T>(type: extensionsType): Promise<T> {
  return new Promise((resolve, reject) => {
    if (!window.chrome) {
      return reject(extensionError.noChrome);
    }

    if (!window.chrome.runtime) {
      window.debug && console.warn('[rp-color-picker]: no chrome runtime');
    }

    // --------------------------- 判断是否有扩展可用 -------------------------------------
    // ----------------- NOTE 旧ID方案 -----------------------
    // NOTE: 问题：只要安装过一次，不清缓存，这个值始终存在。禁用和卸载后，刷新仍会存在
    const extensionId = loadFromCache('mockplus-for-chrome');
    if (!extensionId) {
      // IP请求 =>(未打开扩展 || manifest.json未配置)
      // 本地无ID => (未打开扩展 || 未刷新页面)
      return reject(extensionError.noExtension);
    }
    // ----------------- NOTE 旧ID方案 -----------------------

    // --------------------------- 判断是否有扩展可用 -------------------------------------

    const handleResponse = (response: { code: number; payload: T; message: string; version: string }) => {
      // chrome ext api 内部错误
      if (!response) {
        return reject(extensionError.exception);
      }

      // ok
      if (response.code === 0) {
        return resolve(response.payload);
      }

      // 自定义错误：插件业务逻辑出错，参数异常
      if (response.code === 1) {
        console.error('[mockplus-for-chrome]: ', response.message);
        return reject(extensionError.exception);
      }

      // 版本不同导致部分功能失效
      if (version !== response.version) {
        return reject(extensionError.version);
      }
    };

    // ------------------------------ mock 的方案 -------------------------------------
    window.addEventListener(
      'mock-extend-send',
      (e: MessageEvent) => {
        const resp = e.data as { code: number; payload: T; message: string; version: string };
        handleResponse(resp);
      },
      { once: true },
    );

    console.log('runtime', window.chrome.runtime);

    // NOTE: 只应由 ext content_script 注入此方法
    if (window.mockRequestMessage) {
      window.mockRequestMessage({ action: type });
      return;
    }
    // ------------------------------ mock 的方案 -------------------------------------

    // NOTE 私有部署始终不能访问 runtime：因为域名不在 ext externally_connectable 白名单中
    // 这个只适用于线上公有版
    if (window.chrome.runtime) {
      // 兼容老版无 mock 方法插件
      window.chrome.runtime?.sendMessage(extensionId, { action: type }, handleResponse);
      return;
    }

    reject(extensionError.noExtension);
  });
}
