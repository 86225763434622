import * as React from 'react';

import { UIContainerComponent } from '@editor/comps';
import { StyleHelper } from '@helpers/styleHelper';

import { IComponentProps } from '../../types';

import './index.scss';

function parserStyle(container: UIContainerComponent) {
  const { size, opacity, properties } = container;
  const indicator = container.getComponentByAlias('progress') || container.components[0];
  const { size: indicatorSize, properties: indicatorProperties, position } = indicator;
  const parser = StyleHelper.createCSSStyleParser(properties);
  const progressParser = StyleHelper.createCSSStyleParser(indicatorProperties);
  let thickness = 0;
  if (!properties.stroke?.disabled) {
    thickness = properties.stroke?.thickness || 0;
  }
  return {
    style: {
      opacity: StyleHelper.getOpacity(opacity),
      ...size,
      ...parser.getStrokeStyle(),
      ...parser.getFillStyle(size),
      ...parser.getRadiusStyle(size),
      ...parser.getShadowStyle(),
    },
    progressStyle: {
      left: position.x - thickness,
      top: (size.height - indicatorSize.height) / 2 - thickness,
      ...indicatorSize,
      ...progressParser.getFillStyle(indicatorSize),
      ...progressParser.getStrokeStyle(),
      ...progressParser.getRadiusStyle(indicatorSize),
    },
  };
}

const ProgressBar: React.FC<IComponentProps> = (props: IComponentProps) => {
  const { style, progressStyle } = parserStyle(props.comp as UIContainerComponent);
  return (
    <div className="lib-comp-progress" style={style}>
      <div className="lib-comp-progress-indicator" style={progressStyle} />
    </div>
  );
};

export default ProgressBar;
