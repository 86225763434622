export function getEnumName<T>(typeInfo: T, value: any): string {
  if (typeof typeInfo === 'object') {
    for (let k in typeInfo) {
      if (typeInfo[k] === value) {
        return k;
      }
    }
  }
  return '';
}

export function getEnumValue<T>(typeInfo: T, key: string): any {
  for (let k in typeInfo) {
    if (k === `${key}`) {
      return typeInfo[k];
    }
  }
  return null;
}

export function enumToArray<T>(typeInfo: T): any[] {
  const result: any[] = [];
  if (typeof typeInfo === 'object') {
    for (let k in typeInfo) {
      if (/^\d+$/.test(k)) {
        continue;
      }
      const value = typeInfo[k];
      result.push(value);
    }
  }
  return result;
}

export function subtractEnum<T>(enums: T[], ...args: T[]): any[] {
  const arr = Array.from(args);
  const result = [...enums];
  arr.forEach((item) => {
    const index = result.indexOf(item);
    if (index !== -1) {
      result.splice(index, 1);
    }
  });
  return result;
}

export function includeEnum<T>(typeInfo: T, value: any): boolean {
  if (typeof typeInfo === 'object') {
    for (let k in typeInfo) {
      if (typeInfo[k] === value) {
        return true;
      }
    }
  }
  return false;
}
