import * as React from 'react';
import classnames from 'classnames';
import * as moment from 'moment';

import MatchStrSpan from '../../../../MatchStrSpan';

import i18n from '../../../../../i18n';
import { RPFreeProgramProgressNode, IRPFreeProgressNode } from '../../../../../models/rpFreeProgram';
import { rpFreeProgressList, rpFreeProgressInviteCount } from '../../../../../constants/rpFreeProgram';

import './index.scss';

const ForeverPicture = 'https://img02.mockplus.cn/image/2021-02-26/91a54cb1-d41c-8fbb-2d90-33c50a2c91ed.png';

interface IInvitationProgressProps {
  invitedCount: number;
  isNewRpActivity: boolean;
  hasGotPackage: boolean;
  expireTime?: Date;
  viewAllInvitation(): void;
}

const InvitationProgress: React.FC<IInvitationProgressProps> = (props: IInvitationProgressProps) => {
  const invitedMonth = 3;
  const { invitedCount, isNewRpActivity, hasGotPackage, expireTime, viewAllInvitation } = props;

  const [progressList, setProgressList] = React.useState<IRPFreeProgressNode[]>(rpFreeProgressList);

  React.useEffect(() => {
    const copyList = computeActiveNodes();

    let initNode = copyList.find((item) => item.id === RPFreeProgramProgressNode.Initial);
    if (initNode) {
      initNode.text = isNewRpActivity ? i18n('rpActivity.oneMonth') : initNode?.text;
    }

    setProgressList([...copyList]);
  }, [invitedCount, isNewRpActivity]);

  const computeActiveNodes = () => {
    let copyList: IRPFreeProgressNode[] = JSON.parse(JSON.stringify(rpFreeProgressList));
    copyList.forEach((item: IRPFreeProgressNode) => {
      if (invitedCount >= item.count) {
        item.active = true;
      }
      if (hasGotPackage) {
        item.active = true;
      }
    });
    return copyList;
  };

  const renderProgressIconNode = (node: IRPFreeProgressNode) => {
    switch (node.id) {
      case RPFreeProgramProgressNode.Last:
        return renderProgressNode(node, true);

      default:
        return renderProgressNode(node);
    }
  };

  const renderProgressNode = (node: IRPFreeProgressNode, isLast?: boolean) => {
    if (isLast) {
      return (
        <div className={classnames('progress-node')} key={node.id}>
          <div className={`progress-node-last`}>
            <img src={ForeverPicture} width={'100%'} alt="" />
          </div>
          {renderProgressNodeDesc(node)}
        </div>
      );
    }
    return (
      <div className={classnames('progress-node')} key={node.id}>
        <div className={`progress-node-${node.active ? 'active' : 'normal'}`}></div>
        {renderProgressNodeDesc(node)}
      </div>
    );
  };

  const renderProgressNodeDesc = (node: IRPFreeProgressNode) => {
    return (
      <div
        className={classnames('progress-node-desc', {
          active: node.active,
        })}
      >
        <p>{node.text}</p>
        <p>
          {node.active ? (
            i18n('rpActivity.hasGotIt')
          ) : (
            <MatchStrSpan str={i18n('rpActivity.inviteSeveral', node.count)} targetStr={`${node.count}`} />
          )}
        </p>
        <p>{node.value}</p>
      </div>
    );
  };

  const getRemainingInvitationTasks = () => {
    return rpFreeProgressInviteCount - invitedCount;
  };

  const computeIsExpired = () => {
    if (!expireTime) {
      return false;
    }
    const now = new Date();
    const deadLine = new Date(expireTime);
    return deadLine.getTime() - now.getTime() <= 0;
  };

  const getExpireTime = () => {
    const isExpired = computeIsExpired();

    if (isExpired) {
      return i18n('rpActivity.invitationStatusExpired', moment(expireTime).format('YYYY-MM-DD'), getRemainingInvitationTasks());
    }

    return i18n(
      'rpActivity.invitationStatusWillExpire',
      1 + invitedCount * invitedMonth,
      getRemainingInvitationTasks(),
    );
  };

  const firstNodeWidth = 48;
  const perNodeWidth = 108;

  const width =
    invitedCount >= rpFreeProgressInviteCount || hasGotPackage ? '100%' : firstNodeWidth + perNodeWidth * invitedCount;

  return (
    <div className="invitation-progress-container">
      <div className="header">
        <div className="title">{i18n('rpActivity.invitationProgress')}</div>
        <div className="desc">
          <MatchStrSpan
            str={
              invitedCount >= rpFreeProgressInviteCount || hasGotPackage
                ? i18n('rpActivity.invitationStatusHasGot')
                : getExpireTime()
            }
            targetStr={i18n('rpActivity.viewInvitationHistory')}
            targetClassName={'link-b'}
            onClickTarget={viewAllInvitation}
          />
        </div>
        <div className="title explain-title">{i18n('rpActivity.invitationExplainTitle')}</div>
        <div className="desc explain-desc">{i18n('rpActivity.invitationExplainContent')}</div>
      </div>
      <div className="progress-container">
        <div className="progress-bar">
          <div className="inner" style={{ width }}></div>
          {progressList.map((progressNode: IRPFreeProgressNode) => renderProgressIconNode(progressNode))}
        </div>
      </div>
    </div>
  );
};

export default InvitationProgress;
