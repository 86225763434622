import * as React from 'react';
import * as ReactDom from 'react-dom';
import classnames from 'classnames';

import { getFragmentShouldRComponents } from '@/helpers/contentPanelv2Helper';
import { TheaterManager } from '@/managers/pageArtboardManager';
import { UIArtboard, UIComponent, UIContainerComponent, UIFragment } from '@editor/comps';
import { Operation } from '@fbs/rp/utils/patch';
import { PredefinedStates } from '@consts/state';
import { IPreviewOption, isMobileDevice } from '@helpers/previewHelper';
import { createClassNameAndFragmentAction } from '@/helpers/interactionHelper';
import { Icon, ScrollBars } from '@/dsm';
import { FillData, FillType } from '@fbs/rp/models/properties/fill';
import { EventTypes } from '@/fbs/rp/models/event';
import { dragDelegate } from '@/utils/mouseUtils';
import { getEnumValue } from '@utils/enumUtils';
import { applyFillToStyle, parseColorToString } from '@utils/graphicsUtils';
import { getDefaultComponentName } from '@libs/libs';
import { FragmentPositionMode, IFragmentAction, PageSkipEffectType } from '@/fbs/rp/models/interactions';
import { GrayColor } from '@/consts/colors';
import { FragmentOverlayHandle } from '@/theater/types';
import { THotAreaVisibleModel } from '@libs/types';

import Component from '../../../libs';
import Theater from '../../../theater';

import './index.scss';
import './animation.scss';
import './fragmentAnimation.scss';
import './customFragmentAnimation.scss';

export interface IPageProp {
  page: Theater;
  pageType: 'current' | 'next';
  remarkTagId?: string;
  scale: number;
  option?: IPreviewOption;
  action?: IFragmentAction;
  isStandalone?: boolean;
  showMobileCursor?: boolean;
  onParentForceUpdate: () => void;
  onEndFragment: (e: React.MouseEvent<Element, MouseEvent>) => void;
  onFragmentAnimationEnd: (targetId?: string) => void;
}

export interface IPageState {
  originalData: Theater;
  showRemarkPopUp: boolean;
  showRemarkPanel: boolean;
  selectedTag: string;
  remarkPopUpPoint?: {
    left: number;
    top: number;
  };
  targetComp?: UIComponent;
  forceUpdateComp?: UIComponent;
}

interface IPoint {
  x: number;
  y: number;
  time: number;
}

interface ISwipeConfig {
  start?: IPoint;
  end?: IPoint;
  // selectstart: false;
  moved: boolean;
}

class Page extends React.Component<IPageProp, IPageState> {
  static defaultProps: Partial<IPageProp> = {};

  selfRef: React.RefObject<HTMLDivElement>;
  private remarkPopUpRef: React.RefObject<HTMLDivElement> = React.createRef();

  private currentDownPage?: string = undefined;
  private compMouseUpEvent: { [id: string]: EventListener } = {};
  private windowMouseUpEvent?: (e: MouseEvent) => void;
  private rowTextCount: number = 30;
  private maxFloatPanelHeight: number = 390;
  private swipeConfig: ISwipeConfig = {
    start: undefined,
    end: undefined,
    moved: false,
  };
  private mouseEventsList: UIComponent[] = [];
  private prevFragmentCommand?: [string, IFragmentAction] = undefined;

  constructor(props: IPageProp) {
    super(props);
    this.selfRef = React.createRef();
    this.state = {
      originalData: this.props.page,
      showRemarkPopUp: false,
      showRemarkPanel: false,
      remarkPopUpPoint: {
        left: 0,
        top: 0,
      },
      targetComp: undefined,
      selectedTag: '',
    };
    this.setFragmentCommandHandle(props.page, props.pageType, this.doFragmentCommand.bind(this));
  }

  public getHotAreaVisibleModel = (option = this.props.option): THotAreaVisibleModel | undefined | null => {
    if (option) {
      const { alwaysShowLinkArea, showLinkAreaWhenHovered } = option;
      if (showLinkAreaWhenHovered && !alwaysShowLinkArea) {
        return 'only-hover';
      } else if (alwaysShowLinkArea) {
        return 'always';
      }
    }
    return undefined;
  };

  private setFragmentCommandHandle = (page: Theater, pageType: 'current' | 'next', fn?: FragmentOverlayHandle) => {
    if (pageType !== 'current') {
      return;
    }
    page.updateWorkerManagerExtensionFeature({
      onFragmentOverlay: fn,
    });

    // 这里执行一次画板初始化页面loaded交互更新
    if (!page.fragmentOverlayInitialized) {
      page.updateListener && page.updateListener();
    }
    page.fragmentOverlayInitialized = true;
  };

  private doFragmentCommand = async (fragmentAction: IFragmentAction) => {
    const { onParentForceUpdate, page, pageType } = this.props;
    let action = fragmentAction;
    const { animation, isExit, params, pageId } = action;
    const fragments = page.getAllFragments;

    let fragmentID = action.target;
    let fragment = fragments.find((f) => f.artboardID === fragmentID);

    // 处理跨页面的交互画板、 在演示模式只有权限的页面才交互才生效
    if (pageId && TheaterManager.hasPageArtboards(pageId) && !fragment) {
      const artboards = await TheaterManager.loadArtboard(pageId);
      page.makeDocToDocs(page.appID, artboards, pageId);
      fragment = page.getAllFragments.find((f) => f.artboardID === fragmentID);
    }

    const fragmentCommandMap = page.fragmentCommandMap;

    if (pageType !== 'current' || !fragmentCommandMap) {
      return;
    }
    // 退出的画板没显示或画板不存在
    if (!fragment || (isExit && !fragmentCommandMap.has(fragmentID))) {
      return;
    }
    if (!page.multiFragments && !isExit) {
      // 非多画板且执行画板打开时，清除其他所有画板
      fragmentCommandMap.clear();
    }
    if (isExit && params.effect === ('auto' as PageSkipEffectType)) {
      const originAction = fragmentCommandMap.get(fragmentID);
      if (originAction?.isExit === false) {
        const effect = originAction.params.effect ?? 'none';
        params.effect = effect;
      }
    }
    if (params.effect === 'none') {
      animation.duration = 1;
    }

    if (params.effect === 'autoEffect' && this.prevFragmentCommand) {
      fragmentID = this.prevFragmentCommand[0];
      action = this.prevFragmentCommand[1];
      action.params.autoEffect = true;
    }

    this.prevFragmentCommand = [fragmentID, action];

    fragmentCommandMap.set(fragmentID, action);

    // 对需要在画板载入时生成的交互生效，比如：触发显示辅助画板，并伴有滚动
    onParentForceUpdate();

    const ms = animation.delay + animation.duration + 50;
    setTimeout(() => {
      if (!isExit) {
        // 如果是画板打开的动画，触发画板loaded；
        page.start(fragment!, EventTypes.loaded);
      }
      if (isExit && action.params.effect === 'none') {
        // 如果是画板关闭，且无动效，清除动画直接关闭画板
        fragmentCommandMap.clear();
      }
      // 强制刷新
      onParentForceUpdate();
    }, ms);
  };

  componentDidUpdate() {
    this.setFragmentCommandHandle(this.props.page, this.props.pageType, this.doFragmentCommand.bind(this));
  }

  /**
   * 下面组件相关交互的事件触发
   * @param e
   * @param comp
   * @param shouldRunInteraction 是否需要执行交互
   */
  handleComponentClick = (e: React.MouseEvent, comp: UIComponent, shouldRunInteraction = true) => {
    const { page } = this.props;

    if (comp.hidden) {
      return;
    }
    // 如果触发了手势交互，则不触发点击事件
    if (this.swipeConfig.moved) {
      // 重置moved状态
      this.swipeConfig.moved = false;
      return false;
    }
    if (comp.selected) {
      page.triggerPredefinedState(comp, PredefinedStates.toggleCheck);
      page.triggerPredefinedState(comp, PredefinedStates.unchecked);
    } else {
      page.triggerPredefinedState(comp, PredefinedStates.toggleCheck);
      page.triggerPredefinedState(comp, PredefinedStates.checked);
    }

    if (
      shouldRunInteraction &&
      comp.interactions[EventTypes.click] &&
      comp.interactions[EventTypes.click].actions.length > 0
    ) {
      page.start(comp, EventTypes.click);
    }
  };

  handleComponentDoubleClick = (e: React.MouseEvent, comp: UIComponent) => {
    if (
      comp.interactions[EventTypes.doubleClick] &&
      comp.interactions[EventTypes.doubleClick].actions.length > 0 &&
      !comp.hidden
    ) {
      this.props.page.start(comp, EventTypes.doubleClick);
    }
  };

  handleComponentDown = (e: React.MouseEvent, comp: UIComponent) => {
    const { page } = this.props;
    if (comp.hidden) {
      return;
    }
    const allowPressed = comp.hasActiveState(PredefinedStates.pressed);
    let trigger = comp;
    let parent = comp.parent;
    if (allowPressed) {
      let parentList: UIComponent[] = [comp];
      while (parent) {
        parentList.unshift(parent);
        parent = parent.parent;
      }
      let allowPressedParent: UIComponent | undefined = undefined;
      while (!allowPressedParent && parentList.length) {
        const p = parentList.shift()!;
        if (p.hasActiveState(PredefinedStates.pressed)) {
          trigger = p;
          break;
        }
      }
      page.triggerPredefinedState(trigger, PredefinedStates.pressed);
    }
    this.mouseEventsList.push(comp);
    this.doInteractionSwipes(comp, EventTypes.mouseDown);
    // fixed 固定内容组件脱离了preview-page，交互事件的监听单独处理
    this.doDragDelegate(comp);
    // 处理compMouseUpEvent
    this.proxyWindowMouseUp(trigger);
  };

  // component mouseUp 清除mouseDown状态
  handleComponentUp = (comp: UIComponent) => {
    const { page } = this.props;
    page.clearPressedState(comp);
  };

  /**
   * 作用在组件上的触发事件
   * @param e
   * @param comp
   */
  handleComponentTouchStart = (e: React.TouchEvent, comp: UIComponent) => {
    const { page } = this.props;
    if (comp.hidden) {
      return;
    }
    if (comp.hasActiveState(PredefinedStates.pressed)) {
      page.triggerPredefinedState(comp, PredefinedStates.pressed);
    }
    this.doTouchStart(e, comp);
  };

  handleComponentContextMenu = (e: React.MouseEvent, comp: UIComponent) => {
    const { page } = this.props;
    if (comp.hidden) {
      return;
    }
    page.start(comp, EventTypes.contextMenu);
  };

  private handleCompnentMouseUp = (comp: UIComponent) => {
    const { page } = this.props;
    if (comp.hidden) {
      return;
    }
    page.start(comp, EventTypes.mouseUp);
  };

  doDragDelegate = (comp?: UIComponent) => {
    const nowTime = new Date().getTime();
    this.swipeConfig = {
      moved: false,
      start: {
        x: 0,
        y: 0,
        time: nowTime,
      },
    };
    dragDelegate(
      () => {},
      (e, delta) => {
        this.swipeConfig.end = {
          x: delta.x,
          y: delta.y,
          time: new Date().getTime() - nowTime,
        };
        this.handleWindowOneMouseUp(comp);
      },
    );
  };

  /**
   * 执行手势或点击按下交互命令；
   * @param comp
   * @param swipeType  EventTypes
   */
  doInteractionSwipes = (comp: UIComponent, swipeType: EventTypes) => {
    const { page } = this.props;
    if (comp.hidden) {
      return;
    }
    const interactionCompIndex = this.mouseEventsList.findIndex(
      (item) => item.interactions[swipeType] && item.interactions[swipeType].actions.length > 0,
    );
    if (interactionCompIndex !== -1) {
      page.start(this.mouseEventsList[interactionCompIndex], swipeType);
      this.mouseEventsList.splice(interactionCompIndex, 1); // 交互执行一个、删除一个，确保交互执行在该组件上
    }
  };

  doTouchStart(e: React.TouchEvent, target: UIComponent | UIArtboard) {
    this.swipeConfig.moved = false;
    this.swipeConfig.start = {
      x: e.touches[0].pageX,
      y: e.touches[0].pageY,
      time: new Date().getTime(),
    };
    this.mouseEventsList.push(target);
    this.doInteractionSwipes(target, EventTypes.mouseDown);
  }

  private triggerParentHover = (comp: UIComponent) => {
    if (comp.hidden) {
      return;
    }
    if (comp.hasActiveState(PredefinedStates.hover) && !comp.selected) {
      const { page } = this.props;
      page.triggerPredefinedState(comp, PredefinedStates.hover);
    } else if (comp.parent) {
      this.triggerParentHover(comp.parent);
    }
  };

  private stopParentPressed = (comp: UIComponent) => {
    if (comp.hidden) {
      return;
    }
    if (comp.hasActiveState(PredefinedStates.pressed) && comp.currentStateID === PredefinedStates.pressed) {
      const { page } = this.props;
      page.triggerPredefinedState(comp, PredefinedStates.normal);
    } else if (comp.parent) {
      this.stopParentPressed(comp.parent);
    }
  };

  private doTriggerParentState = (comp: UIComponent, stateName: PredefinedStates) => {
    const { parent } = comp;
    if (parent && !parent.hidden) {
      if (parent.hasActiveState(stateName)) {
        this.props.page.triggerPredefinedState(parent, stateName);
      } else {
        this.doTriggerParentState(parent, stateName);
      }
    }
  };

  private handleWindowMouseUp = (comp: UIComponent, target: EventTarget) => {
    const { page } = this.props;
    if (comp.hidden) {
      return;
    }
    const focused = comp._currentState === PredefinedStates.focus;
    const event = window.event as MouseEvent;
    const upTarget = event.target as HTMLElement;
    const isUpOnSelf = (target as HTMLElement).contains(upTarget);
    if (!focused) {
      if (comp.hasActiveState(PredefinedStates.hover) && !comp.selected && isUpOnSelf) {
        page.triggerPredefinedState(comp, PredefinedStates.hover);
      } else if (comp.hasActiveState(PredefinedStates.focus) && isUpOnSelf) {
        page.triggerPredefinedState(comp, PredefinedStates.focus);
      } else {
        this.stopParentPressed(comp);
        this.triggerParentHover(comp.parent!);
      }
    }
  };

  private proxyMouseUp = (target: EventTarget, comp: UIComponent) => {
    const eventListener: EventListener = ((comp: UIComponent) => {
      return ((e: MouseEvent) => {
        e.target!.removeEventListener('mouseup', this.compMouseUpEvent[comp.id]);
        delete this.compMouseUpEvent[comp.id];
        this.handleCompnentMouseUp(comp);
      }) as EventListener;
    })(comp);
    this.compMouseUpEvent[comp.id] = eventListener;
    target.addEventListener('mouseup', eventListener);
  };

  private proxyWindowMouseUp = (comp: UIComponent) => {
    if (this.windowMouseUpEvent) {
      return;
    }
    this.windowMouseUpEvent = ((comp: UIComponent) => {
      return () => {
        if (this.windowMouseUpEvent) {
          window.removeEventListener('mouseup', this.windowMouseUpEvent);
        }
        this.windowMouseUpEvent = undefined;
        this.handleComponentUp(comp);
      };
    })(comp);
    window.addEventListener('mouseup', this.windowMouseUpEvent);
  };

  getActiveFragment = (fragmentID: string) => {
    const fragments = this.props.page.getAllFragments.filter((item) => {
      return item.artboardID === fragmentID;
    });
    if (fragments) {
      return fragments[0];
    }
  };

  handleComponentTouchEnd = (e: React.TouchEvent, comp: UIComponent) => {
    if (comp.hidden) {
      return;
    }
    const { page } = this.props;
    if (comp.hasActiveState(PredefinedStates.hover) && !comp.selected) {
      page.triggerPredefinedState(comp, PredefinedStates.hover);
    } else {
      page.triggerPredefinedState(comp, PredefinedStates.normal);
    }
    this.handleTouchEnd(e, comp);
  };

  handleComponentEnter = (e: React.MouseEvent, comp: UIComponent) => {
    if (comp.hidden) {
      return;
    }
    const { page } = this.props;
    const focused = comp._currentState === PredefinedStates.focus;
    if (!focused) {
      if (comp.hasActiveState(PredefinedStates.hover) && !comp.selected) {
        page.triggerPredefinedState(comp, PredefinedStates.hover);
      }
    }
    page.start(comp, EventTypes.mouseEnter);
  };

  handleComponentLeave = (e: React.MouseEvent, comp: UIComponent) => {
    if (comp.hidden) {
      return;
    }
    const { page } = this.props;
    const focused = comp._currentState === PredefinedStates.focus;
    if (!focused) {
      if (comp.hasActiveState(PredefinedStates.hover) && !comp.selected) {
        page.triggerPredefinedState(comp, PredefinedStates.normal);
      }
    }
    page.start(comp, EventTypes.mouseLeave);
  };

  handleComponentFocus = (e: React.FocusEvent, comp: UIComponent) => {
    if (comp.hidden) {
      return;
    }
    const { page } = this.props;
    if (comp.hasActiveState(PredefinedStates.focus)) {
      page.triggerPredefinedState(comp, PredefinedStates.focus);
    } else {
      this.doTriggerParentState(comp, PredefinedStates.focus);
    }
    page.start(comp, EventTypes.focus);
  };

  handleComponentBlur = (e: React.FocusEvent, comp: UIComponent) => {
    if (comp.hidden) {
      return;
    }
    const { page } = this.props;
    if (comp.hasActiveState(PredefinedStates.focus)) {
      page.triggerPredefinedState(comp, PredefinedStates.normal);
    }
    page.start(comp, EventTypes.blur);
  };

  handlePageClick = (e: React.MouseEvent) => {
    window.debug && console.log(TheaterManager);
    e.stopPropagation();
    const { page } = this.props;
    const artboard = page.doc.mainArtboard;
    if (this.currentDownPage !== artboard.artboardID) {
      this.currentDownPage = undefined;
      return;
    }
    this.currentDownPage = undefined;
    // 如果触发了手势交互，则不触发点击事件
    if (this.swipeConfig.moved) {
      // 重置moved状态
      this.swipeConfig.moved = false;
      return false;
    }
    page.start(artboard, EventTypes.click);
  };

  handlePageDoubleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { page } = this.props;
    const artboard = page.doc.mainArtboard;
    page.start(artboard, EventTypes.doubleClick);
  };

  // 手机端、web端调用此处方法
  handleWindowOneMouseUp = (comp?: UIComponent) => {
    const { end } = this.swipeConfig;
    const target = comp || this.props.page.doc.mainArtboard;
    // 滑动时间大于500ms不触发 且 滑动距离小于10不触发
    if (end!.time > 500 || Math.sqrt(Math.pow(end!.x, 2) + Math.pow(end!.y, 2)) < 10) {
      this.mouseEventsList.push(target);
      this.doInteractionSwipes(target, EventTypes.mouseUp);
      this.mouseEventsList = [];
      return false;
    }
    if (Math.abs(end!.x) > Math.abs(end!.y)) {
      // 水平滑动
      if (end!.x > 0) {
        // 目标存在手势交互时才让其阻止鼠标点击和按下的事件
        if (target.interactions[EventTypes.swipeRight]?.actions?.length) {
          this.swipeConfig.moved = true;
        }
        this.doInteractionSwipes(target, EventTypes.swipeRight);
      } else {
        // 目标存在手势交互时才让其阻止鼠标点击和按下的事件
        if (target.interactions[EventTypes.swipeLeft]?.actions?.length) {
          this.swipeConfig.moved = true;
        }
        this.doInteractionSwipes(target, EventTypes.swipeLeft);
      }
    } else {
      // 垂直滑动
      if (end!.y > 0) {
        // 目标存在手势交互时才让其阻止鼠标点击和按下的事件
        if (target.interactions[EventTypes.swipeDown]?.actions?.length) {
          this.swipeConfig.moved = true;
        }
        this.doInteractionSwipes(target, EventTypes.swipeDown);
      } else {
        // 目标存在手势交互时才让其阻止鼠标点击和按下的事件
        if (target.interactions[EventTypes.swipeUp]?.actions?.length) {
          this.swipeConfig.moved = true;
        }
        this.doInteractionSwipes(target, EventTypes.swipeUp);
      }
    }
    // 交互都应用后则销毁事件队列
    this.mouseEventsList = [];
  };

  handlePageDown = () => {
    if (isMobileDevice()) {
      return false;
    }
    // 防止出现文字、元素被选中状态导致移动事件失效
    document.getSelection()?.removeAllRanges();
    const { page } = this.props;
    const artboard = page.doc.mainArtboard;
    if (!artboard) {
      return;
    }
    this.currentDownPage = artboard.artboardID;
    this.mouseEventsList.push(artboard);
    this.doInteractionSwipes(artboard, EventTypes.mouseDown);
    this.doDragDelegate();
  };

  handlePageUp = () => {
    if (isMobileDevice()) {
      return false;
    }
    const { page } = this.props;
    const artboard = page.doc.mainArtboard;
    if (!artboard) {
      return;
    }
    if (this.currentDownPage !== artboard.artboardID) {
      return;
    }
  };

  /**
   * 画板触摸事件开始
   * @param e
   * @param comp
   */
  handleTouchStart = (e: React.TouchEvent) => {
    // 重置默认值
    this.swipeConfig = {
      moved: false,
    };
    const { page } = this.props;
    const artboard = page.doc.mainArtboard;
    if (!artboard) {
      return;
    }
    this.swipeConfig.start = {
      x: e.touches[0].pageX,
      y: e.touches[0].pageY,
      time: new Date().getTime(),
    };
    this.currentDownPage = artboard.artboardID;
    this.doTouchStart(e, artboard);
  };

  /**
   * 触摸移动事件，作用在画板及组件上
   * @param e
   */
  handleTouchMove = (e: React.TouchEvent) => {
    this.swipeConfig.end = {
      x: e.touches[0].pageX - this.swipeConfig.start!.x,
      y: e.touches[0].pageY - this.swipeConfig.start!.y,
      time: this.swipeConfig.start!.time,
    };
  };

  /**
   * 移动端事件
   * @param e
   * @param comp 有值时触发组件，无值时触发画板，手势交互不阻止冒泡
   */
  handleTouchEnd = (e: React.TouchEvent, comp?: UIComponent) => {
    const { page } = this.props;
    const artboard = page.doc.mainArtboard;
    if (!artboard) {
      return;
    }
    // 判断触发了手势交互
    if (!this.swipeConfig.end) {
      this.swipeConfig.end = {
        x: 0,
        y: 0,
        time: 0,
      };
    }
    this.swipeConfig.end.time = new Date().getTime() - this.swipeConfig.start!.time;
    this.handleWindowOneMouseUp(comp);
  };

  handlePageContextMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { page } = this.props;
    const artboard = page.doc.mainArtboard;
    const selection = document.getSelection();
    if (selection) {
      const { focusOffset, anchorOffset, focusNode, anchorNode } = selection;
      if (focusNode === anchorNode && focusOffset === anchorOffset) {
        e.preventDefault();
      }
    }
    // e.preventDefault();
    if (!artboard) {
      return;
    }
    if (this.currentDownPage !== artboard.artboardID) {
      this.currentDownPage = undefined;
      return;
    }
    this.currentDownPage = undefined;
    page.start(artboard, EventTypes.contextMenu);
  };

  handleInnerAction = (actions: { comp: UIComponent; actions: Operation[] }[]) => {
    this.props.page.triggerInnerAction(actions);
  };

  handleUpdate = () => {
    // this.forceUpdate();
    this.props.onParentForceUpdate();
  };

  handleForceUpdateComp = (comp?: UIComponent) => {
    this.setState({ forceUpdateComp: comp });
  };

  /**
   * 显示备注内容
   * @param isShow   boolean
   * @param comp     UIComponent
   * @param point    （可选）位置信息， 不传时panel不显示出来
   */
  handleShowRemarkFloatPanel = (
    isShow: boolean,
    comp: UIComponent,
    point?: {
      left: number;
      top: number;
    },
  ) => {
    this.setState(
      {
        showRemarkPopUp: isShow,
        targetComp: comp,
        remarkPopUpPoint: point,
        selectedTag: comp.id,
      },
      () => {
        this.remarkPopUpRef.current?.focus();
      },
    );
  };

  handleRemarkPopUpClose = () => {
    this.setState({
      showRemarkPopUp: false,
      selectedTag: '',
    });
  };

  renderComp = (
    comp: UIComponent,
    scale?: number,
    noBoundary?: boolean,
    hotAreaVisibleModel = this.getHotAreaVisibleModel(),
  ) => {
    let renderComp = comp;
    const { forceUpdateComp, selectedTag, targetComp, showRemarkPopUp } = this.state;
    const { remarkTagId, option, isStandalone, showMobileCursor, page } = this.props;
    if (forceUpdateComp && forceUpdateComp.id === comp.id) {
      renderComp = forceUpdateComp;
    }
    const v2 = getFragmentShouldRComponents(comp);
    return (
      <Component
        key={`${renderComp.id}-${page.pageID}`}
        revision={renderComp.chainedVersion()}
        offsetX={0}
        offsetY={0}
        isActivated={false}
        isParentActivated={false}
        isPreview
        noBoundary={noBoundary}
        mobileType={option?.mobileType}
        showMobileCursor={showMobileCursor}
        showRemarkTag={option?.showRemarkTag}
        comp={renderComp}
        scale={scale}
        selectedTag={selectedTag || remarkTagId}
        isStandalone={isStandalone}
        renderSubComponents={this.renderComponents}
        onClick={this.handleComponentClick}
        onDoubleClick={this.handleComponentDoubleClick}
        onContextMenu={this.handleComponentContextMenu}
        onMouseDown={this.handleComponentDown}
        onTouchEnd={this.handleComponentTouchEnd}
        onTouchStart={this.handleComponentTouchStart}
        onTouchMove={this.handleTouchMove}
        onMouseEnter={this.handleComponentEnter}
        onMouseLeave={this.handleComponentLeave}
        onFocus={this.handleComponentFocus}
        onBlur={this.handleComponentBlur}
        onInnerAction={this.handleInnerAction}
        onForceUpdate={this.handleForceUpdateComp}
        onShowRemarkFloatPanel={this.handleShowRemarkFloatPanel}
        showPreviewRemarkPopupId={showRemarkPopUp && targetComp ? targetComp.id : ''}
        hotAreaVisibleModel={hotAreaVisibleModel || undefined}
      >
        {v2.map((v) => this.renderComp(v, scale, noBoundary, hotAreaVisibleModel))}
      </Component>
    );
  };

  renderComponents = (
    container: UIContainerComponent,
    scale: number,
    noBoundary?: boolean,
    hotAreaVisibleModel?: THotAreaVisibleModel | null,
  ) => {
    let comps = container.components;
    const isOutermost = container.type === 'artboard';
    if (container instanceof UIFragment && container.isMain) {
      comps = comps.filter((comp) => !comp.float);
    }
    const v2Comps = getFragmentShouldRComponents(container);
    return (v2Comps.length ? v2Comps : comps).map((comp) => {
      return this.renderComp(comp, isOutermost ? scale : undefined, noBoundary, hotAreaVisibleModel);
    });
  };

  renderFloatComps = (
    artboard: UIFragment,
    option: {
      scale: number;
      noBoundary?: boolean;
      style?: React.CSSProperties;
      options?: IPreviewOption;
    },
  ) => {
    const floatComps = artboard.components.filter((comp) => comp.toJSON().float);
    if (!floatComps.length) {
      return null;
    }
    const { scale, noBoundary, style, options } = option;
    let className = 'float-comp-container';
    if (options?.alwaysShowLinkArea) {
      className = classnames(className, 'always');
    } else if (options?.showLinkAreaWhenHovered) {
      className = classnames(className, 'only-hover');
    }
    return (
      <div className={className} style={style || {}}>
        {floatComps.map((comp) => this.renderComp(comp, scale, noBoundary))}
      </div>
    );
  };

  renderRemarkPopUp() {
    const { remarkPopUpPoint, targetComp } = this.state;
    const { scale } = this.props;
    if (!targetComp) {
      return null;
    }

    const { type, remark, displayName, lib } = targetComp;
    let left: number = 0;
    let top: number = 0;
    if (remarkPopUpPoint) {
      left = remarkPopUpPoint.left;
      top = remarkPopUpPoint.top;
    }

    if (!remark) {
      return;
    }
    const componentName = getDefaultComponentName(type, lib);
    const realName = displayName || componentName;

    const calcRemarkLength = (remark: string): number => {
      if (remark.includes('\n')) {
        const splitStrArr = remark.split('');
        const wrapTextLength = splitStrArr.filter((str) => str.includes('\n')).length * this.rowTextCount;
        const normalTextLength = splitStrArr.filter((str) => !str.includes('\n')).length;
        return wrapTextLength + normalTextLength;
      }
      return remark.length;
    };

    return ReactDom.createPortal(
      <div
        ref={this.remarkPopUpRef}
        tabIndex={-1}
        className="remark-float-panel"
        style={{
          left: left / scale,
          top: top / scale,
          transform: `scale(${scale})`,
          opacity: left === 0 && top === 0 ? 0 : 1,
        }}
        onBlur={this.handleRemarkPopUpClose}
      >
        <div className="close">
          <Icon cls="close" isInPopup onClick={this.handleRemarkPopUpClose} />
        </div>
        <p className="title">{realName}</p>
        {calcRemarkLength(remark?.value) > this.maxFloatPanelHeight ? (
          <ScrollBars style={{ height: 308 }}>
            <p className="content">{remark?.value}</p>
          </ScrollBars>
        ) : (
          <p className="content">{remark?.value}</p>
        )}
      </div>,
      document.body,
    );
  }

  private get customFragmentCommands() {
    const { page, pageType } = this.props;
    if (pageType !== 'current') {
      return [];
    }
    return Array.from(page.fragmentCommandMap?.values() ?? []).filter((item) => {
      return item.params.mode === FragmentPositionMode.Custom;
    });
  }

  renderCustomFragmentCommands = (scale = 1) => {
    if (!this.customFragmentCommands.length) return null;
    return this.customFragmentCommands.map((item) =>
      this.renderFragment(1, item, this.props.option?.noBoundary, scale),
    );
  };

  /**
   * 渲染自定义辅画板叠加
   */
  renderFragment = (scale: number, action: IFragmentAction, noBoundary?: boolean, transformScale?: number) => {
    const fragment = this.getActiveFragment(action.target);
    if (!fragment) return null;
    const { onFragmentAnimationEnd } = this.props;
    let size = fragment && {
      height: fragment.size.height * (scale || 1),
      width: fragment.size.width * (scale || 1),
    };
    const style: React.CSSProperties = {};
    const bgStyle: React.CSSProperties = {};

    if (action.params.showBackground) {
      if (action.params.backgroundColor) {
        const { color, type } = action.params.backgroundColor;
        if (color && type) {
          applyFillToStyle({ color, type }, bgStyle);
        }
      } else {
        bgStyle.background = parseColorToString({ ...GrayColor, a: 0.5 });
      }
    }
    bgStyle.pointerEvents = 'none';
    if (action.params.showBackground) {
      bgStyle.pointerEvents = 'all';
    }
    let animationName: PageSkipEffectType | 'null' = 'null';
    if (action) {
      style.animationTimingFunction = action.animation.effect;
      style.animationDuration = `${action.animation.duration || 1}ms`;
      animationName = action.params.effect;
      if (animationName === 'none') {
        style.animationDuration = '1ms';
      }
      style.animationFillMode = `forwards`;
    }

    const artboardSize = {
      width: this.props.page.doc.mainArtboard.size.width * scale,
      height: this.props.page.doc.mainArtboard.size.height * scale,
    };
    const fragmentPosition = action.params.position || { x: 0, y: 0 };
    let actionName = createClassNameAndFragmentAction(action, {
      scale,
      deviceSize: artboardSize,
      fragment,
      keyframeStyle: {
        left: fragmentPosition.x * scale,
        top: fragmentPosition.y * scale,
        transformX: 0,
        transformY: 0,
        transform: `translate(0px, 0px)`,
      },
    });
    if (actionName) {
      style.transformOrigin = 'center';
    }
    if (action.params.autoEffect) {
      actionName = actionName + '-reverse';
    }

    const classNames = classnames(
      'active-custom-fragment',
      this.getHotAreaVisibleModel(),
      { [actionName]: !action.isExit },
      { [`fragment-custom-${animationName}`]: !action.isExit },
      { [`revert-fragment-custom-${animationName}`]: action.isExit },
      { ['over-visible']: !!noBoundary },
    );
    return (
      <div
        key={fragment.artboardID}
        className="custom-fragment-bg"
        style={{
          ...bgStyle,
          ...artboardSize,
          position: 'absolute',
          top: 0,
          left: 0,
          transform: `scale(${transformScale})`,
        }}
        onClick={this.props.page.closeFragmentByAction.bind(this.props.page, action, this.handleUpdate.bind(this))}
      >
        <div
          className={classNames}
          onAnimationEnd={() => {
            if (animationName !== 'null') {
              onFragmentAnimationEnd(action.params.autoEffect || action.isExit ? action.target : '');
            }
          }}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              this.props.page.closeFragmentByAction(action, this.handleUpdate.bind(this));
            }
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            e.stopPropagation();
          }}
          onContextMenu={(e) => {
            e.stopPropagation();
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
            this.handlePageDown();
          }}
          onMouseUp={(e) => {
            e.stopPropagation();
            this.handlePageUp();
          }}
          onTouchStart={(e) => {
            e.stopPropagation();
            this.handleTouchStart(e);
          }}
          onTouchEnd={(e) => {
            e.stopPropagation();
            this.handleTouchEnd(e);
          }}
          onTouchMove={(e) => {
            e.stopPropagation();
            this.handleTouchMove(e);
          }}
          style={{
            ...style,
            ...size,
            left: fragmentPosition.x * scale,
            top: fragmentPosition.y * scale,
          }}
        >
          {fragment && this.renderComponents(fragment, scale, noBoundary)}
        </div>
      </div>
    );
  };

  render() {
    const { page, option } = this.props;
    const { doc } = page;
    const { showRemarkPopUp } = this.state;

    const bgData = doc.mainArtboard.background;
    const style: React.CSSProperties = {};
    let newColor = {
      type: bgData.type,
      color: bgData.color,
    };
    if (!bgData.type || !bgData.color || bgData.disabled) {
      newColor = {
        type: FillType.solid,
        color: { r: 0, g: 0, b: 0, a: 0 },
      };
    }
    applyFillToStyle({ type: getEnumValue(FillType, newColor.type!), color: newColor.color as FillData }, style);

    const calssNames = classnames(
      'preview-page',
      {
        'no-boundary': option?.noBoundary,
      },
      option ? this.getHotAreaVisibleModel() : '',
    );
    return (
      <div
        className={calssNames}
        style={style}
        onClick={this.handlePageClick}
        onDoubleClick={this.handlePageDoubleClick}
        onContextMenu={this.handlePageContextMenu}
        onMouseDown={this.handlePageDown}
        onMouseUp={this.handlePageUp}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
      >
        {this.renderComponents(doc.mainArtboard, 1, option?.noBoundary)}
        {/*{this.renderFloatComps(doc.mainArtboard, scale, option?.noBoundary)}*/}
        {/* {!!this.customFragmentCommands.length &&
          this.customFragmentCommands.map((item) => this.renderFragment(1, item, option?.noBoundary))} */}
        {showRemarkPopUp && this.renderRemarkPopUp()}
      </div>
    );
  }
}

export default Page;
