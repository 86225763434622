import * as React from 'react';
import classnames from 'classnames';
import { debounce } from 'lodash';

import { nodeMatchUpdate } from '@/utils/stringUtils';

import { Checkbox, Icon } from '@dsm2';
import { INode, DropMode } from '../model';
import { isNodeMatch, isCtrlKey } from '../utils';

import './index.scss';

interface INodeProps {
  theme?: 'white' | 'dark' | 'spec-dark';
  data: INode;
  expanded: boolean;
  selected: boolean;
  cutted: boolean;
  draggable: boolean;
  customDragType: symbol[];
  justAcceptCustomDragType: boolean;
  offset: number;
  inputWidth: number;
  searchKey?: string;
  searchKeyHighlight?: boolean;
  canLeafHasChildren?: boolean;
  showCustomIcon?: boolean;
  showCheckBox: boolean;
  checked: boolean;
  //是否允许半选 之前被注释掉了，为了不影响其他部分，这里单独处理下 新增个变量控制
  //dengchuandong is offline  dengchuandong, 11 months ago   (May 30th, 2022 11:43 AM) db63032
  //fix: 选择框\文件图标\文字没对齐(issue #81211)
  isShowIndeterminate: boolean;
  // 半选中
  indeterminate: boolean;
  disabledCheckbox: boolean;
  highlightChecked: boolean;
  children?: React.ReactNode;
  needDebounce?: boolean;
  isNotShowCommentsIcon?: boolean;
  hideDefaultTitle: boolean;
  onExpand(expandAll: boolean): void;
  onClick(e: React.MouseEvent): void;
  onContextMenu(e: React.MouseEvent): void;
  onKeyDown(e: React.KeyboardEvent<HTMLSpanElement>): void;
  onMouseUp?(e: React.MouseEvent): void;
  onMouseDown?(e: React.MouseEvent): void;
  onMouseEnter?(e: React.MouseEvent): void;
  onMouseLeave?(e: React.MouseEvent): void;
  onCheck(checked: boolean): void;
  onClickSuffixIcon?(e: React.MouseEvent, id: string): void;
  onClickOptionsIcon?(e: React.MouseEvent, id: string): void;
  onDblClickNode?(id: string): void;
  tip?(e: React.MouseEvent, text?: string): void;
  onClickControlIcon?(e: React.MouseEvent, node: any): void;
  showGroupNum: boolean;
  interactionHighlight?: boolean;
  hoverShowBg?: boolean;
}

interface IDragObject {
  id: string;
  type: symbol;
  // 表示被拖拽的该项是否是被选中的
  selected: boolean;
  appSetID?: string;
}

interface IDropResult {
  id: string;
  dropMode: DropMode;
}

const Node: React.FC<INodeProps> = (props: INodeProps) => {
  const data = props.data;
  const tip = props.tip;
  const spanRef = React.useRef<HTMLSpanElement>(null);
  const ref = React.useRef<HTMLLIElement>(null);
  const [isHover, setIsHover] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (props.data?.isInteractionTarget) {
      // FIXME Mxj 没太看懂这里这个 scrollIntoViewIfNeeded 是从哪来的属性，ref.current这里应该是一个HTMLLIElement对象才对？先忽略掉，保证RP中没有TS编译报错
      // @ts-ignore
      ref.current?.scrollIntoViewIfNeeded && ref.current?.scrollIntoViewIfNeeded();
    }
  }, [props.data.isInteractionTarget]);

  let nodeText = data.name;
  if (data.serialNumber) {
    nodeText = `${data.serialNumber} ${data.name}`;
  }
  // if (data.childCount) {
  //   nodeText = `${nodeText} (${data.childCount})`;
  // }
  return (
    <li
      ref={ref}
      className={classnames('tree-node-rp', {
        'tree-node-cutted': props.cutted,
        highlight: props.highlightChecked && props.checked,
        'tree-node-display': props.disabledCheckbox,
      })}
      id={`node_${data.id}`}
      // onMouseEnter={() => setIsHover(true)}
      // onMouseLeave={() => setIsHover(false)}
    >
      <div
        className="interaction_target_node"
        id={`interaction_target_node_${props.data.id}`}
        style={{ left: -props.offset + 16 }}
        onClick={props.needDebounce ? debounce(props.onClick, 200) : props.onClick}
      />
      {props.data.showInteractionIcon && <div className="has-interaction" style={{ left: -props.offset + 16 }} />}
      {props.data.isInteractionTarget && <div className="isInteractionTarget" style={{ left: -props.offset + 16 }} />}
      {props.interactionHighlight && <div className="interaction-highlight" style={{ left: -props.offset + 16 }} />}
      {(props.selected ||
        (props.highlightChecked && props.checked) ||
        props.hoverShowBg ||
        (!!props.customDragType?.length && !props.justAcceptCustomDragType)) && (
        <div
          className={classnames('node-bg', {
            'node-bg-selected': props.selected,
            'node-bg-checked': props.data.isLeaf && props.highlightChecked && props.checked,
            'node-bg-drop-over': props.customDragType?.length && !props.justAcceptCustomDragType,
          })}
          style={{ left: -props.offset }}
        />
      )}
      {props.hoverShowBg && !props.selected && (
        <div
          className={classnames('node-bg', {
            'node-bg-hover': props.hoverShowBg && isHover,
          })}
          style={{ left: -props.offset }}
        />
      )}

      {
        <span
          className={classnames('tree-node-name', {
            selected: props.selected,
            dullness: props.searchKey && !isNodeMatch(data, props.searchKey),
            highlight: props.highlightChecked && props.checked,
            lowlight: props.highlightChecked && !props.checked,
            hover: isHover && !props.selected,
          })}
          ref={spanRef}
          onDoubleClick={(e: React.MouseEvent) => {
            if (props.onDblClickNode) {
              props.onDblClickNode(data.id);
            } else {
              props.onExpand(isCtrlKey(e));
            }
          }}
          onClick={props.onClick}
          tabIndex={-1}
          onKeyDown={(e) => {
            if (e.key === 'Delete' && spanRef.current) {
              spanRef.current.blur();
            }
            props.onKeyDown(e);
          }}
          onMouseUp={props.onMouseUp}
          onMouseDown={props.onMouseDown}
          onMouseEnter={(e) => {
            props.hoverShowBg && setIsHover(true);
            props.onMouseEnter && props.onMouseEnter(e);
          }}
          onMouseLeave={(e) => {
            props.hoverShowBg && setIsHover(false);
            props.onMouseLeave && props.onMouseLeave(e);
          }}
          onContextMenu={props.onContextMenu}
        >
          <div className="is-finding-interaction-target-node" style={{ left: -props.offset }} />
          <i
            className={classnames('icon-expand', {
              invisiable: !data.children || data.children.length === 0,
            })}
            onDoubleClick={(e: React.MouseEvent) => {
              props.onExpand(isCtrlKey(e));
              e.stopPropagation();
            }}
          >
            <Icon
              cls={props.expanded ? 'tag_downarrow' : 'tag_rightarrow'}
              hidden={!data.children || data.children.length === 0}
              solid
              onClick={(e: React.MouseEvent) => {
                props.onExpand(isCtrlKey(e));
                e.stopPropagation();
              }}
            />
          </i>
          <span
            className="title"
            onClick={() => props.showCheckBox && props.onCheck(!(props.checked || props.indeterminate))}
          >
            {props.showCheckBox && (
              <Checkbox
                darkMode={props.theme === 'dark' || props.theme === 'spec-dark'}
                disabled={props.disabledCheckbox}
                checked={props.checked || props.indeterminate}
                indeterminate={props.isShowIndeterminate && props.indeterminate}
                // onChange={props.onCheck}
              />
            )}
            {data.isLeaf ? (
              <>
                {data.icon && !props.showCustomIcon && (
                  <Icon
                    cls={data.icon === 'tree_group' ? (props.expanded ? 'tree_index' : 'tree_group') : data.icon}
                    solid
                    disableHoverColor
                    color={data.color}
                  />
                )}
                {data.icon && !!props.showCustomIcon && (
                  <Icon cls={data.icon} solid disableHoverColor color={data.color} />
                )}
              </>
            ) : (
              !data.isHomepage &&
              !data.isHorizontal && <Icon cls={props.expanded ? 'tree_index' : 'tree_group'} solid disableHoverColor />
            )}
            {data.isHomepage && <Icon cls="demo_home" solid disableHoverColor />}
            {data.isHorizontal && <Icon cls="tree_phone" solid disableHoverColor />}
            {props.searchKey && props.searchKeyHighlight ? (
              <span
                className={classnames('name', {
                  'interaction-highlight': props.interactionHighlight,
                })}
                onMouseOver={(e) => tip && tip(e, data.name)}
                onMouseLeave={(e) => tip && tip(e)}
                title={props.hideDefaultTitle ? '' : data.name}
                dangerouslySetInnerHTML={{
                  __html: nodeMatchUpdate(
                    data.name,
                    props.searchKey,
                    props.showGroupNum && !data.isLeaf,
                    data.groupNum ? data.groupNum : 0,
                  ),
                }}
              />
            ) : (
              <span
                className={classnames('name', {
                  'interaction-highlight': props.interactionHighlight,
                })}
                title={props.hideDefaultTitle || tip ? '' : data.name}
                onMouseOver={(e) => tip && tip(e, data.name)}
                onMouseLeave={(e) => tip && tip(e)}
              >
                {nodeText}
                {props.showGroupNum && !data.isLeaf && !!data.groupNum && (
                  <b className="group-num">（{data.groupNum}）</b>
                )}
              </span>
            )}
          </span>
          {(data.suffixIcon || data.optionsIcon || data.commentsIcon) && (
            <div className="right-icon">
              {data.commentsIcon && data.commentsIcon.icon && !props.isNotShowCommentsIcon && (
                <i
                  className="comments-icon"
                  title={props.hideDefaultTitle || tip ? '' : data.commentsIcon.label}
                  onMouseOver={(e) => tip && tip(e, data.commentsIcon?.label)}
                  onMouseLeave={(e) => tip && tip(e)}
                >
                  <Icon color={data.commentsIcon.color} cls={data.commentsIcon.icon} solid />
                </i>
              )}
              {data.suffixIcon && (
                <i
                  className="suffix-icon"
                  onMouseDown={(e) => {
                    if (props.onClickSuffixIcon) {
                      props.onClickSuffixIcon(e, props.data.id);
                    }
                  }}
                  title={props.hideDefaultTitle || tip ? '' : data.suffixIcon.label}
                  onMouseOver={(e) => tip && tip(e, data.suffixIcon?.label)}
                  onMouseLeave={(e) => tip && tip(e)}
                >
                  <Icon color={data.suffixIcon.color} cls={data.suffixIcon.icon} solid />
                </i>
              )}
              {data.optionsIcon && (
                <i
                  className="options-icon"
                  onMouseDown={(e) => {
                    if (props.onClickOptionsIcon) {
                      props.onClickOptionsIcon(e, props.data.id);
                    }
                  }}
                  title={props.hideDefaultTitle || tip ? '' : data.optionsIcon.label}
                  onMouseOver={(e) => tip && tip(e, data.optionsIcon?.label)}
                  onMouseLeave={(e) => tip && tip(e)}
                >
                  <Icon color={data.optionsIcon.color} cls={data.optionsIcon.icon} solid />
                </i>
              )}
            </div>
          )}
        </span>
      }
      {props.children && <div className="child">{props.children}</div>}
    </li>
  );
};

function areEqual(prevProps: INodeProps, nextProps: INodeProps) {
  const keysWhichValueIsNotFunction = Object.keys(nextProps).filter((key) => {
    const value = nextProps[key as keyof INodeProps];
    const propsValueIsNotFunction = !(typeof value === 'function');
    return propsValueIsNotFunction;
  });

  for (let key of keysWhichValueIsNotFunction) {
    const key1 = key as keyof INodeProps;
    try {
      if (!['children'].includes(key1) && JSON.stringify(nextProps[key1]) !== JSON.stringify(prevProps[key1])) {
        return false;
      } else if (['children'].includes(key1) && nextProps.children !== prevProps.children) {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  return true;
}

export default React.memo(Node, areEqual);
