import i18n from '@i18n';

import { ArrowCfg } from './arrow';
import { CallOutCfg } from './callout';
import { StickNoteCfg } from './StickNote/config';
import { DropsCfg } from './drops';
import { SnapshotCfg } from './snapshot';

import { IComponentLib } from '../types';

export default {
  id: 'comment',
  name: `${i18n('resource.components.comment')}`,
  components: [ArrowCfg, CallOutCfg, StickNoteCfg, DropsCfg, SnapshotCfg],
} as IComponentLib;
