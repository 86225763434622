import * as React from 'react';
import * as ReactDom from 'react-dom';
import classnames from 'classnames';

import './index.scss';

export interface ITooltipProp {
  children: any;
  text: string;
  className?: string;
  style?: React.CSSProperties;
  autoHide?: boolean;
  ownerIsDialog?: boolean;
}

export interface ITooltipState {
  show: boolean;
  popupPosition: { left: number; top: number };
}

class Tooltip extends React.Component<ITooltipProp, ITooltipState> {
  static defaultProps: Partial<ITooltipProp> = {
    autoHide: true,
  };

  selfRef: React.RefObject<HTMLDivElement> = React.createRef();
  popup: React.RefObject<HTMLDivElement> = React.createRef();
  triggerTimeID: number = 0;
  private pagePoint: { x: number; y: number } = { x: 0, y: 0 };

  constructor(props: ITooltipProp) {
    super(props);
    this.state = { show: false, popupPosition: { left: 0, top: 0 } };
  }

  componentWillUnmount(): void {
    window.clearTimeout(this.triggerTimeID);
    window.removeEventListener('mousemove', this.onWindowMoving);
  }

  componentDidUpdate() {
    this.doAdjustPopupPosition();
  }

  doAdjustPopupPosition = () => {
    const { popupPosition } = this.state;
    if (this.popup.current && this.selfRef.current) {
      let { left, top, right, bottom, width, height } = this.popup.current.getBoundingClientRect();
      const { top: t, right: r } = this.selfRef.current.getBoundingClientRect();
      const offset = 6;
      const { innerWidth, innerHeight } = window;
      if (right > innerWidth) {
        left = r - width;
      }
      if (bottom > innerHeight) {
        top = t - height - offset;
      }
      left = Math.round(left);
      top = Math.round(top);
      if (left !== popupPosition.left || top !== popupPosition.top) {
        this.setState({ popupPosition: { left, top } });
      }
    }
  };

  onWindowMoving = (e: MouseEvent) => {
    this.pagePoint.x = e.clientX;
    this.pagePoint.y = e.clientY;
  };

  onMouseEnter = (e: React.MouseEvent) => {
    this.pagePoint.x = e.clientX;
    this.pagePoint.y = e.clientY;
    if (this.selfRef.current) {
      window.addEventListener('mousemove', this.onWindowMoving);
      this.triggerTimeID = window.setTimeout(() => {
        if (this.triggerTimeID) {
          window.clearTimeout(this.triggerTimeID);
        }
        // 确保正常情况下，始终位于光标点的下部20px处
        this.doShow();
      }, 250);
    }
  };

  doShow = () => {
    this.setState(
      {
        show: true,
        popupPosition: {
          left: this.pagePoint.x,
          top: this.pagePoint.y + 22,
        },
      },
      () => {
        if (this.props.autoHide) {
          const { text } = this.props;
          this.triggerTimeID = window.setTimeout(this.doHide, 2000 + (text || '').length * 50);
        }
      },
    );
  };

  doHide = () => {
    if (this.state.show) {
      this.setState({ show: false });
    }
  };

  onMouseLeave = () => {
    window.removeEventListener('mousemove', this.onWindowMoving);
    if (this.triggerTimeID) {
      clearTimeout(this.triggerTimeID);
    }
    this.doHide();
  };

  renderTips = () => {
    const { text, ownerIsDialog } = this.props;
    const { popupPosition } = this.state;
    if (!text) {
      return null;
    }
    return ReactDom.createPortal(
      <div
        ref={this.popup}
        style={popupPosition}
        className={classnames('dsm-c-rp-tool-tips', { 'in-dialog': ownerIsDialog })}
      >
        {text}
      </div>,
      document.body,
    );
  };

  render() {
    const { children, className, style } = this.props;
    const { show } = this.state;
    return (
      <React.Fragment>
        <div
          style={style}
          className={className}
          ref={this.selfRef}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          {children}
        </div>
        {show && this.renderTips()}
      </React.Fragment>
    );
  }
}

export default Tooltip;
