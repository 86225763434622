import { measureTextSize } from '@utils/textUtils';
import { max, round } from '@utils/globalUtils';

import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { MoveDelta } from '@fbs/common/models/resize';
import { IRange } from '@fbs/rp/models/properties/base';
import { PropertyValue } from '@fbs/rp/models/property';
import { EventTypes } from '@fbs/rp/models/event';

import i18n from '@i18n';
import { getTextCompInSealed } from '@helpers/componentHelper';
import { coverPatches } from '@helpers/patchHelper';
import appOptions from '@/helpers/appOptions';

import { ComponentChangeType, getNewPositionWhenCenter, getOffsetBySizeChange } from '@editor/comps/resizeHelper';
import { UIComponent, UIContainerComponent } from '@editor/comps';

import { PredefinedStates } from '@consts/state';
import ValueEditorType from '@consts/enums/valueEditorType';
import { SpriteThumb } from '@consts/spriteIcons';
import { getDefaultDataFromTemplate } from '@/libs/helper';

import { IComponentItem } from '../../types';
import { CPureText } from '../../constants';
import { SizeMode, MoveMode, PropertyStructureAlign } from '../../enum';
import { compoundStructureList } from '../lib';

const { width, height } = measureTextSize(
  {
    fontSize: 14,
    fontFamily: 'Microsoft YaHei',
  },
  i18n('resource.components.checkbox'),
);

const defaultHeight = Math.max(16, height);

/**
 * 移动子项
 * @description 用于高级编辑
 * @param {MoveDelta} delta
 * @param {UIContainerComponent} checkboxComp
 * @param {UIComponent[]} child
 * @returns {ArtboardPatches|null}
 */
export function onChildMove(
  delta: MoveDelta,
  checkboxComp: UIContainerComponent,
  child?: UIComponent[],
): ArtboardPatches | null {
  const textComp = getTextCompInSealed(checkboxComp)!;
  const properties = checkboxComp.properties;
  const originGap = properties.layout?.horizontalGap!;
  const first = child?.length ? child[0] : undefined;
  if (first?.type === CPureText) {
    const originPosition = textComp.position;
    const horizontalDiff = first.position.x - originPosition.x;
    const path = checkboxComp.getCurrentPropertiesPath('properties/layout');
    const newGap = max(0, originGap + horizontalDiff);
    const newValue = { ...properties.layout, horizontalGap: newGap };
    return {
      do: {
        [checkboxComp.id]: [Ops.replace(path, newValue)],
      },
      undo: {
        [checkboxComp.id]: [Ops.replace(path, properties.layout)],
      },
    };
  }
  return null;
}

/**
 * 更新属性
 * @param {UIComponent} comp
 * @param {string} propertyName
 * @param {PropertyValue} value
 * @returns {ArtboardPatches | null}
 */
const onPropertyUpdate = (comp: UIComponent, propertyName: string, value: PropertyValue): ArtboardPatches | null => {
  if (propertyName === 'iconSize') {
    const iconWrapper = (comp as UIContainerComponent).getComponentByAlias('icon', true);
    if (iconWrapper) {
      const [icon] = (iconWrapper as UIContainerComponent).components;
      const { size, id, position } = iconWrapper;
      const iconNewSize = (value.value as IRange).value; // Math.min(parentSize.height, range.value);
      const { id: iconID, size: iconSize, position: iconPosition } = icon;
      //@ts-ignore
      const sizeChangeRatio = iconNewSize / comp.properties.iconSize?.value.value;
      if (!sizeChangeRatio) {
        return null;
      }
      const propertyPath = comp.getCurrentPropertiesPath('/properties/iconSize');
      const sizePath = comp.getCurrentSizePath();
      const positionPath = comp.getCurrentPositionPath();

      const newSizeOfIconWrapper = {
        ...size,
        width: size.width * sizeChangeRatio,
        height: size.height * sizeChangeRatio,
      };
      const newIconSize = {
        ...iconSize,
        width: round(iconSize.width * sizeChangeRatio),
        height: round(iconSize.height * sizeChangeRatio),
      };

      const patches = {
        do: {
          [id]: [Ops.replace(sizePath, newSizeOfIconWrapper)],
          [iconID]: [Ops.replace(sizePath, newIconSize)],
          [comp.id]: [
            Ops.replace(propertyPath, { ...value, value: { ...(value.value as IRange), value: iconNewSize } }),
          ],
        },
        undo: {
          [id]: [Ops.replace(sizePath, size), Ops.replace(`${positionPath}/y`, position.y)],
          [iconID]: [Ops.replace(sizePath, iconSize), Ops.replace(positionPath, iconPosition)],
          [comp.id]: [Ops.replace(propertyPath, comp.properties['iconSize'])],
        },
      };
      const selectIcon = (comp as UIContainerComponent).getComponentByAlias('selectIcon', true);
      if (selectIcon) {
        const { size: oldSize } = selectIcon;
        const newSize = {
          ...oldSize,
          width: oldSize.width * sizeChangeRatio,
          height: oldSize.height * sizeChangeRatio,
        };
        const { position, rotate, size } = selectIcon.getResizeMySelfResult(getOffsetBySizeChange(oldSize, newSize));
        const newPosition = getNewPositionWhenCenter(selectIcon, position, size, newSizeOfIconWrapper, {
          isLayoutCenterAtHorizontal: true,
          isLayoutMiddleAtVertical: true,
        });
        const { patches: selectIconPatches } = selectIcon.parent!.getPositionPatchesOfChildrenChanged(
          [
            {
              id: selectIcon.id,
              type: ComponentChangeType.Edit,
              size,
              rotate,
              position: newPosition,
            },
          ],
          false,
        );
        coverPatches(patches, selectIconPatches);
      }

      return patches;
    }
  }
  return null;
};
export const CheckBoxCfg = {
  type: 'checkbox',
  name: i18n('resource.components.checkbox'),
  isTextComp: true,
  thumb: {
    spriteIconClass: SpriteThumb.CheckBox.className,
    dragPosition: SpriteThumb.CheckBox.position,
  },
  sizeMode: SizeMode.none,
  predefinedStates: [PredefinedStates.checked, PredefinedStates.disabled],
  value: {
    type: ValueEditorType.PureText,
  },
  editor: {
    onChildMove,
    onPropertyUpdate,
  },
  interactions: {
    defaultEvent: EventTypes.checked,
  },
  constraint: {
    text: {
      move: MoveMode.horizontal,
    },
    icon: {
      move: MoveMode.neither,
      resize: SizeMode.none,
    },
  },
  syncPropertyToStateNormal: {
    structure: true,
  },
  property: {
    getValueNames() {
      return compoundStructureList;
    },
    getExtensionDefaultProperties(comp: UIComponent) {
      const compData = comp.toJSON();
      const typeName = compData.lib?.type ?? compData.type;
      // 兼容旧数据
      if (compData.properties.structure) {
        return null;
      }
      return {
        structure: appOptions.getStructureAlign(typeName) || {
          value: PropertyStructureAlign.TextRight,
          prop: 'enum',
          name: 'structure',
        },
      };
    },
  },
  template: `
  {
    type: @@C.StackPanel,
    size: {
      width: ${width + 24},
      height: ${defaultHeight},
    },
    layout:{
      vertical: 'middle',
      horizontal: 'left',
      fixedWidth: true,
      fixedHeight: true,
      auto: true,
      responsive: true,
     },
    properties: {
     
      textStyle: {
        textAlign: @@TextAlign.left,
        color: @@SystemColors.DefaultTextColor,
        fontSize: 14,
        fontFamily: 'Microsoft YaHei',
        fontStyle: { underline: false, bold: false, strike: false, italic: false },
        disabled: false,
      },
      layout:{
        direction: 'horizontal',
        verticalAlign: 'middle',
        horizontalGap: 10,
        disabledSwitch: true,
        disabled: false,
      },
      iconSize: {
        prop: 'number',
        name: '${i18n('property.propertyNames.iconSize')}',
        value: {
          min: 10,
          max: 100,
          value: 13
        },
      }
    },
    value: '${i18n('resource.components.checkbox')}',
    states:{
      disabled:{
        enabled: true,
        opacity: 30,
        properties:{},
      },
      checked:{
        enabled: true,
        properties:{},
      }
    },
    components: [
      {
        type: @@C.CanvasPanel,
        alias: 'icon',
        size: {
          width: 13,
          height: 13,
          lockedRatio:true,
        },
        position:{
          x: 0,
          y: ${Math.floor((defaultHeight - 16) / 2)},
        },
        layout:{
          vertical: 'middle',
          horizontal: 'left',
          fixedWidth: false,
          fixedHeight: false,
          auto: false,
          responsive: true,
        },
        properties:{
          stroke: {
            color: @@SystemColors.DefaultSelectColor,
            thickness: 1,
            disabled: false,
          },
          fill:{
            type: 'solid',
            color: {r: 255, g: 255, b: 255, a: 1},
            disabled: false,
          },
        },
        states:{
          checked: {
            enabled: true,
            name: '${i18n('property.propertyNames.checked')}',
            properties:{
              fill:{
                type: @@FillType.solid,
                color: @@SystemColors.DefaultSelectColor,
                disabled: false,
              },
              stroke:{disabled:true}
            },
          },
        },
        components: [
          {
            type: @@C.Path,
            alias:'selectIcon',
            position: {
              x: 3.5,
              y: 4.5,
            },
            size: {
              width: 6,
              height: 4,
              lockedRatio:true,
            },
            layout:{
              vertical: 'middle',
              horizontal: 'center',
              fixedWidth: false,
              fixedHeight: false,
              auto: false,
              responsive: true,
            },
            properties: {
              stroke: {
                cap:"butt",
                color:{a:1,b:255,g:255,r:255},
                disabled:false,
                join:"miter",
                position:"center",
                thickness:2
              },
            },
            value: {
              closed: false,
              data: [
                { handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 }, point: { x: 0, y: 1 } },
                { handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 }, point: { x: 2.5, y: 3.5 } },
                { handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 }, point: { x: 6, y: 0 } },
              ],
            },
            hidden: true,
            states: {
              checked: {
                enabled: true,
                name: '${i18n('property.propertyNames.checked')}',
                hidden: false,
                properties:{
                 
                },
              },
            },
          },
        ],
      },
      {
        type: @@C.PureText,
        alias: 'text',
        autoSize: true,
        canMove:{
          horizontal:true,
          vertical:false,
        },
        size: {
          width: ${width},
          height: ${height},
        },
        position:{
          x: 24,
          y: ${Math.floor((defaultHeight - height) / 2)},
        },
        layout:{
          vertical: 'middle',
          horizontal: 'left&right',
          fixedWidth: false,
          fixedHeight: true,
          auto: false,
          responsive: true,
        },
        value: '@value',
        properties: {
          textStyle: {
            ref: '@properties.textStyle',
          },
        },
      },
    ],
    select: {
      target: 'self',
      enabled: true,
      reversible: true,
    },
    sealed: true,
  }
  `,
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
} as IComponentItem;

const defaultData = getDefaultDataFromTemplate(CheckBoxCfg);
