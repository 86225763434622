import * as React from 'react';
import classnames from 'classnames';

import CarouselItem, { ICarouselItem } from './CarouselItem';

import CarouselDot from './CarouselDot';

import './index.scss';

interface IProps {
  data: ICarouselItem[];
  /**
   * 当前选中索引
   * 默认 0
   */
  changed: number;
  /**
   * 面板指示点位置
   * 默认 bottom
   */
  dotPosition: 'left' | 'right' | 'top' | 'bottom';
  /**
   * 是否展示指示点
   * 默认 true
   */
  dots: boolean;
  /**
   * 动画效果 位置偏移|渐显
   * 默认 位置偏移
   */
  effect: 'scroll' | 'fade';
  /**
   * 是否自动切换 并循环显示
   * 默认 true
   */
  autoplay: boolean;
  /**
   * 过渡动画效果
   * 默认 渐进
   */
  easing: 'ease' | 'ease-in' | 'ease-in-out' | 'ease-out' | 'linear';
  /**
   * 自定义过渡动画效果
   */
  customEasing: string;
  /**
   * 自动切换的时间间隔，单位为毫秒
   * 默认 3000
   */
  interval: number;
  /**
   * 过渡时间
   */
  duration: number;
  className?: string;

  /**
   * 点击子组件
   */
  onChange?: (data: ICarouselItem, index: number) => void;
  /**
   * 切换索引的钩子 当索引发生改变
   */
  onIndexChanges?: (index: number) => void;

  itemRender?: (data: ICarouselItem, index: number) => React.ReactElement;
}

interface IState {
  /**
   * 当前组件在界面中所占宽度
   */
  width: number;
  /**
   * 当前组件在界面中所占高度
   */
  height: number;
  /**
   * 是否加载完成
   */
  loaded: boolean;
  /**
   * 当前选中索引
   */
  changedIndex: number;
  /**
   * 虚拟数据 优化样式
   */
  virtualData: ICarouselItem[];
  /**
   * 是否使用过渡效果
   */
  transition: boolean;
}

class Carousel extends React.PureComponent<IProps, IState> {
  static defaultProps: Partial<IProps> = {
    dotPosition: 'bottom',
    changed: 0,
    dots: true,
    autoplay: true,
    easing: 'linear',
    interval: 5000,
    duration: 100,
    effect: 'scroll',
    data: [],
  };

  private containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  private intervalTimer = 0;
  private timeoutTimer = 0;
  /**
   * 禁用间隔
   */
  private disableInterval = false;
  private disableIntervalTimer = 0;

  constructor(props: IProps) {
    super(props);
    const { changed, data } = props;
    this.state = {
      width: 0,
      height: 0,
      loaded: false,
      transition: true,
      changedIndex: changed,
      virtualData: this.getVirtualData(data),
    };
  }

  componentDidMount() {
    const { interval, autoplay, data } = this.props;
    if (autoplay && data.length > 1) {
      this.intervalTimer = window.setInterval(() => {
        !this.disableInterval && this.next();
      }, interval);
    }
    this.doChangeContainerSize();
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalTimer);
    window.clearTimeout(this.timeoutTimer);
    window.clearTimeout(this.disableIntervalTimer);
  }

  componentDidUpdate(oldProps: IProps, oldState: IState) {
    const { changedIndex, virtualData } = this.state;
    const { changed, data } = this.props;
    // 最后一个轮播组件也是第一个轮播组件
    if (oldState.changedIndex !== changedIndex && virtualData.length - 1 === changedIndex) {
      window.clearTimeout(this.timeoutTimer);
      this.timeoutTimer = window.setTimeout(() => {
        this.doClearTransition(() => {
          this.next(this.doRestoreTransition);
        });
      }, this.props.interval / 2);
    }
    if (data !== oldProps.data) {
      this.setState({ virtualData: this.getVirtualData(data), changedIndex: changed });
    }
    if (changed !== oldProps.changed && changed !== changedIndex) {
      this.setState({ changedIndex: changed });
    }
    this.doChangeContainerSize();
  }

  /**
   * 改变容器大小
   */
  private doChangeContainerSize = () => {
    if (!this.containerRef.current) {
      return;
    }
    const { width, height } = this.containerRef.current.getBoundingClientRect();
    if (width === this.state.width && height === this.state.height) {
      return;
    }
    this.setState({
      width: Math.ceil(width),
      height: Math.ceil(height),
      loaded: true,
    });
  };

  /**
   * 切换到下一个轮播组件
   * 如果是最后一个会跳转到第一个
   */
  public next = (callback?: Function) => {
    this.doChangeIndex(this.state.changedIndex + 1, callback);
  };

  /**
   * 切换到上一个轮播组件
   * 如果是第一个会跳转到最后一个
   * @param callback 回调函数
   */
  public prev = (callback: Function) => {
    this.doChangeIndex(this.state.changedIndex - 1, callback);
  };

  /**
   * 关闭 过渡动画
   */
  private doClearTransition = (callback: Function) => {
    this.setState(
      {
        transition: false,
      },
      () => {
        callback && callback();
      },
    );
  };

  /**
   * 恢复 过渡动画
   */
  private doRestoreTransition = () => {
    this.setState({
      transition: true,
    });
  };

  /**
   * 获取用于渲染的虚拟数据
   * 最后一个组件也是第一个组件
   * 达到无限循环的显示状态
   * @param items
   */
  private getVirtualData(items: ICarouselItem[]) {
    if (items.length > 1) {
      return [...items, items[0]];
    }
    return items;
  }

  /**
   * 切换索引
   * @param index 目标索引
   * @param callback 回调函数
   */
  private doChangeIndex = (index: number, callback?: Function) => {
    this.setState(
      (state) => {
        const { virtualData } = state;
        const maxIndex = virtualData.length - 1;
        const minIndex = 0;
        if (index > maxIndex) {
          return { changedIndex: minIndex };
        }
        if (index < minIndex) {
          return { changedIndex: maxIndex };
        }
        return {
          changedIndex: index,
        };
      },
      () => {
        window.setTimeout(() => {
          callback && callback();
        });
        const { onIndexChanges, data } = this.props;
        const maxIndex = data.length - 1;
        const { changedIndex } = this.state;
        onIndexChanges && onIndexChanges(changedIndex > maxIndex ? 0 : changedIndex);
      },
    );
  };

  private handleChangeIndex = (index: number) => {
    this.disableInterval = true;
    window.clearTimeout(this.disableIntervalTimer);
    this.disableIntervalTimer = window.setTimeout(() => {
      this.disableInterval = false;
    }, 2000);
    this.doChangeIndex(index);
  };

  private handleChange = (data: ICarouselItem, index: number) => {
    const { onChange } = this.props;
    onChange && onChange(data, index);
  };

  renderItem = (item: ICarouselItem, index: number) => {
    const { itemRender } = this.props;

    if (itemRender) {
      return <React.Fragment key={index}>{itemRender(item, index)}</React.Fragment>;
    }

    const { width, height, changedIndex } = this.state;
    return (
      <CarouselItem
        changed={changedIndex === index}
        width={width}
        height={height}
        data={item}
        key={index}
        index={index}
        onClick={this.handleChange}
      />
    );
  };

  renderDotItem = (item: ICarouselItem, index: number) => {
    const { changedIndex, virtualData } = this.state;
    const changed = index === 0 ? [0, virtualData.length - 1].includes(changedIndex) : index === changedIndex;
    return <CarouselDot key={index} index={index} changed={changed} onChange={this.handleChangeIndex} />;
  };

  renderDots() {
    const { data, dots, dotPosition } = this.props;
    if (dots) {
      return <div className={classnames('dsm-c-rp-carousel-dots', dotPosition)}>{data.map(this.renderDotItem)}</div>;
    }
    return null;
  }

  renderItems() {
    const { dotPosition, effect, easing, customEasing, duration } = this.props;
    const { width, height, loaded, changedIndex, virtualData, transition } = this.state;
    let [offsetX, offsetY, totalWidth, totalHeight] = [0, 0, width, height];
    switch (dotPosition) {
      case 'top':
      case 'bottom':
        offsetX = changedIndex * width;
        totalWidth = width * virtualData.length;
        break;
      case 'left':
      case 'right':
        offsetY = changedIndex * height;
        totalHeight = height * virtualData.length;
        break;
      default:
        break;
    }
    const transform = `translate(-${offsetX}px, -${offsetY}px)`;
    return (
      <div
        className={classnames('dsm-c-rp-carousel-items', dotPosition, effect, { hidden: !loaded, transition })}
        style={{
          width: totalWidth,
          height: totalHeight,
          transform,
          transitionTimingFunction: easing || customEasing,
          transitionDuration: `${transition ? duration / 1000 : 0}s`,
        }}
      >
        {virtualData.map(this.renderItem)}
      </div>
    );
  }

  render() {
    const { className } = this.props;
    return (
      <div className={classnames(className, 'dsm-c-rp-carousel')} ref={this.containerRef}>
        {this.renderItems()}
        {this.renderDots()}
      </div>
    );
  }
}

export default Carousel;
