import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// 饼图
export default {
  ...flowBase,
  name: i18n('resource.flow.pieChart'),
  type: 'pieChart',
  thumb: {
    spriteIconClass: SpriteThumb.PieChart.className,
    dragPosition: SpriteThumb.PieChart.position,
  },
  template: getShapeData(
    i18n('resource.flow.pieChart'),
    {
      width: 60,
      height: 60,
    },
    {
      data: [
        {
          point: {
            x: 30,
            y: 0,
          },
          handleIn: {
            x: 17.861111111111143,
            y: 0,
          },
          handleOut: {
            x: -5.63637824774537,
            y: 0,
          },
        },
        {
          point: {
            x: 15.18942044768238,
            y: 3.108665348368105,
          },
          handleIn: {
            x: 4.346757530964339,
            y: -2.572783330740093,
          },
          handleOut: {
            x: 3.1338359527485977,
            y: 5.650498503815581,
          },
        },
        {
          point: {
            x: 30,
            y: 30,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 2.816089257290855,
            y: 16.682623331072413,
          },
          handleIn: {
            x: 2.488542256158439,
            y: 1.1513011707071423,
          },
          handleOut: {
            x: -1.8892328270171106,
            y: 3.9328165022656645,
          },
        },
        {
          point: {
            x: 0,
            y: 30,
          },
          handleIn: {
            x: 0,
            y: -4.137916003080873,
          },
          handleOut: {
            x: 0,
            y: 14.431216931216788,
          },
        },
        {
          point: {
            x: 30,
            y: 60,
          },
          handleIn: {
            x: -18.77730946450555,
            y: 0,
          },
          handleOut: {
            x: 18.77730946450555,
            y: 0,
          },
        },
        {
          point: {
            x: 60,
            y: 30,
          },
          handleIn: {
            x: 0,
            y: 13.835978835978773,
          },
          handleOut: {
            x: 0,
            y: -13.835978835978887,
          },
        },
      ],
      closed: true,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
