import * as React from 'react';

interface IProsetColor {
  color: string;
  bdc: string;
}

class PresetColor extends React.PureComponent<IProsetColor> {
  render() {
    const style = {
      background: this.props.color,
      borderColor: this.props.bdc,
    };
    return <div className="c-preset-color" style={style} />;
  }
}

export default PresetColor;
