import * as React from 'react';
import classnames from 'classnames';

import { isIframe, isMacOS, isMockRPD } from '@utils/envUtils';

import { DesktopEventType, DesktopInvokeChannel } from '@/services/desktop';

import DesktopTitle from './DesktopTitle';
import './index.scss';

interface IProps {
  title?: string | boolean;
  transparent?: boolean;
  minimizable?: boolean;
  maximizable?: boolean;
  status?: 'maximized' | 'unMaximized';
  canChangeTitle?: boolean;
  className?: string;
  children?: React.ReactNode;
  onChangeTitle?: (title: string) => void;
  /**
   * 全屏状态被切换时的钩子
   */
  onFullscreenChange?: (isFull: boolean) => void;
}

interface IState {
  isFullscreen: boolean;
}

class DesktopContainer extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isFullscreen: false,
    };
  }

  componentDidMount() {
    window.ipcRenderer?.invoke(DesktopInvokeChannel.checkFullScreen).then(this.handleCheckFullScreen);
    window.ipcRenderer?.addListener(DesktopEventType.enterFullScreen, this.handleEnterFullScreen);
    window.ipcRenderer?.addListener(DesktopEventType.leaveFullScreen, this.handleLeaveFullScreen);
  }

  componentWillUnmount() {
    window.ipcRenderer?.removeListener(DesktopEventType.enterFullScreen, this.handleEnterFullScreen);
    window.ipcRenderer?.removeListener(DesktopEventType.leaveFullScreen, this.handleLeaveFullScreen);
  }

  private handleCheckFullScreen = (isFullscreen: boolean): void => {
    this.setState({ isFullscreen });
    this.props.onFullscreenChange?.(isFullscreen);
  };

  private handleEnterFullScreen = () => {
    this.setState({ isFullscreen: true });
    const { onFullscreenChange } = this.props;
    onFullscreenChange && onFullscreenChange(true);
  };

  private handleLeaveFullScreen = () => {
    this.setState({ isFullscreen: false });
    const { onFullscreenChange } = this.props;
    onFullscreenChange && onFullscreenChange(false);
  };

  renderDesktopTitle() {
    const { title, transparent, minimizable, maximizable, status, canChangeTitle, onChangeTitle } = this.props;
    return (
      <DesktopTitle
        title={title}
        transparent={transparent}
        status={status}
        minimizable={minimizable}
        maximizable={maximizable}
        canChange={canChangeTitle}
        onChange={onChangeTitle}
      />
    );
  }

  render() {
    const { className, children } = this.props;
    // 显示title： 是桌面端 不在iframe中 不是全屏
    const hasTitle = isMockRPD && !isIframe && !this.state.isFullscreen;
    return (
      <div
        className={classnames(className, 'desktop-container', {
          desktop: hasTitle,
          mac: isMacOS,
        })}
      >
        {hasTitle && this.renderDesktopTitle()}
        {children}
      </div>
    );
  }
}

export default DesktopContainer;
