import { getEnumName, getEnumValue } from '@utils/enumUtils';

import i18n from '@i18n';

// import { sendMessageToExtensions, extensionsType } from '@/services/chromeExt';
// import { desktopServer } from '@/services/desktop';

// interface IFontFamilyForExtend {
//   fontId: string;
//   displayName: string;
// }

export enum FontFamilys {
  'MicrosoftYaHei' = 'Microsoft YaHei',
  'PingFangSC' = 'PingFang SC',
  'SimSun' = 'SimSun',
  'FangSong' = 'FangSong',
  'KaiTi' = 'KaiTi',
  'SimHei' = 'SimHei',
  'Roboto' = 'Roboto',
  'Arial' = 'Arial',
  'ArialBlack' = 'Arial Bold',
  'TimesNewRoman' = 'Times New Roman',
  'Tahoma' = 'Tahoma',
  'Verdana' = 'Verdana',
  'Helvetica' = 'Helvetica',
  SiYuanHei = 'SiYuanHei',
  Unsafe_SiYuanHei = 'SiYuanHei',
  SiYuanSong = 'SiYuanSong',
  Unsafe_SiYuanSong = 'SiYuanSong',
  ALiBaBa = 'ALiBaBa',
  ZhanKuGaoDuanHei = 'ZhanKuGaoDuanHei',
  ZhanKuHappy = 'ZhanKuHappy',
  ZhanKuKuHei = 'ZhanKuKuHei',
  ZhanKuLOGO = 'ZhanKuLOGO',
  ZhanKuHuangYou = 'ZhanKuHuangYou',
  YouSheTitle = 'YouSheTitle',
  YouSheGoodBody = 'YouSheGoodBody',
  YuanQuanYuan = 'YuanQuanYuan',
  TaiBeiHei = 'TaiBeiHei',
  PangMenTitle = 'PangMenTitle',
  PangMenBold = 'PangMenBold',
  OPPOSans = 'OPPO Sans',
  HarmonySansCondensed = 'HarmonyOS Sans Condensed',
  HarmonySansSC = 'Harmony Sans SC',
  HarmonySansTC = 'Harmony Sans TC',
}

/*
微软雅黑
苹方
Roboto
Arial
Helvetica
Times New Roman
 */
export const DefaultFontFamilys: {
  id: FontFamilys | string;
  text: string;
}[] = [
  { id: FontFamilys.MicrosoftYaHei, text: i18n('property.component.textStyle.MicrosoftYahei') },
  { id: FontFamilys.PingFangSC, text: i18n('property.component.textStyle.pingfang') },
  { id: FontFamilys.SimSun, text: i18n('property.component.textStyle.SimSun') },
  { id: FontFamilys.FangSong, text: i18n('property.component.textStyle.FangSong') },
  { id: FontFamilys.KaiTi, text: i18n('property.component.textStyle.KaiTi') },
  { id: FontFamilys.SimHei, text: i18n('property.component.textStyle.SimHei') },
  { id: FontFamilys.Roboto, text: 'Roboto' },
  { id: FontFamilys.Arial, text: 'Arial' },
  { id: FontFamilys.ArialBlack, text: 'Arial Bold' },
  { id: FontFamilys.TimesNewRoman, text: 'Times New Roman' },
  { id: FontFamilys.Tahoma, text: 'Tahoma' },
  { id: FontFamilys.Verdana, text: 'Verdana' },
  { id: FontFamilys.Helvetica, text: 'Helvetica' },
  { id: FontFamilys.SiYuanHei, text: i18n('property.component.textStyle.siYuanHei') },
  { id: FontFamilys.SiYuanSong, text: i18n('property.component.textStyle.siYuanSong') },
  { id: FontFamilys.ALiBaBa, text: i18n('property.component.textStyle.aLiBaBa') },
  { id: FontFamilys.ZhanKuGaoDuanHei, text: i18n('property.component.textStyle.zhanKuGaoDuanHei') },
  { id: FontFamilys.ZhanKuHappy, text: i18n('property.component.textStyle.zhanKuHappy') },
  { id: FontFamilys.ZhanKuKuHei, text: i18n('property.component.textStyle.zhanKuKuHei') },
  { id: FontFamilys.ZhanKuLOGO, text: i18n('property.component.textStyle.zhanKuLOGO') },
  { id: FontFamilys.ZhanKuHuangYou, text: i18n('property.component.textStyle.zhanKuHuangYou') },
  { id: FontFamilys.YouSheTitle, text: i18n('property.component.textStyle.youSheTitle') },
  { id: FontFamilys.YouSheGoodBody, text: i18n('property.component.textStyle.youSheGoodBody') },
  { id: FontFamilys.YuanQuanYuan, text: i18n('property.component.textStyle.yuanQuanYuan') },
  { id: FontFamilys.TaiBeiHei, text: i18n('property.component.textStyle.taiBeiHei') },
  { id: FontFamilys.PangMenTitle, text: i18n('property.component.textStyle.pangMenTitle') },
  { id: FontFamilys.PangMenBold, text: i18n('property.component.textStyle.pangMenBold') },
  { id: FontFamilys.OPPOSans, text: 'OPPO Sans' },
  { id: FontFamilys.HarmonySansCondensed, text: 'HarmonyOS Sans Condensed' },
  { id: FontFamilys.HarmonySansSC, text: 'HarmonyOS Sans SC' },
  { id: FontFamilys.HarmonySansTC, text: 'HarmonyOS Sans TC' },
];

/**
 * 浏览器插件获取本地字体
 */
// sendMessageToExtensions<IFontFamilyForExtend[]>(extensionsType.fonts)
//   .then((localFonts) => {
//     if (!localFonts?.length) {
//       return false;
//     }
//     const currentFontFamilyNames = DefaultFontFamilys.map((f) => f.id);
//     localFonts.forEach((font) => {
//       !currentFontFamilyNames.includes(font.fontId) &&
//         DefaultFontFamilys.push({
//           id: font.fontId,
//           text: font.displayName,
//         });
//     });
//   })
//   .catch((errType) => {
//     window.debug && console.warn(errType);
//   });

/**
 * 桌面端获取本地字体
 */
// desktopServer
//   .getLocalFonts()
//   .then((res) => {
//     const localFonts = res
//       .filter((f) => DefaultFontFamilys.every(({ id, text }) => !id.includes(f) && !text.includes(f)))
//       .map((f) => ({ id: f, text: f }));
//     DefaultFontFamilys.push(...localFonts);
//   })
//   .catch((err) => {
//     window.debug && console.warn(err);
//   });

// 此处配置用于属性字体选择显示的值和组件文本font-family样式的值
export const fontFamilysConfig = {
  [FontFamilys.MicrosoftYaHei]: ['Microsoft YaHei', '微软雅黑'], // mac使用 微软雅黑
  [FontFamilys.PingFangSC]: ['PingFang SC', 'BlinkMacSystemFont', '-apple-system'], // mac使用 PingFang SC
  [FontFamilys.SimSun]: ['SimSun', 'STSong'], // mac使用 STSong
  [FontFamilys.FangSong]: ['FangSong', 'FangSong_GB2312', 'STFangsong'], // mac使用 STFangsong
  [FontFamilys.KaiTi]: ['KaiTi', 'KaiTi_GB2312', 'STKaiti'], // mac使用 STKaiti
  [FontFamilys.SimHei]: ['SimHei', '"Heiti SC"', '黑体', 'sans-serif'], // mac使用 "Heiti SC"
  [FontFamilys.Roboto]: 'Roboto',
  [FontFamilys.Arial]: 'Arial',
  [FontFamilys.ArialBlack]: '"Arial Black"',
  [FontFamilys.TimesNewRoman]: '"Times New Roman"',
  [FontFamilys.Tahoma]: 'Tahoma',
  [FontFamilys.Verdana]: 'Verdana',
  [FontFamilys.Helvetica]: 'Helvetica',
  [FontFamilys.Unsafe_SiYuanHei]: 'SiYuanHei',
  [FontFamilys.SiYuanHei]: 'Source Han Sans SC',
  [FontFamilys.Unsafe_SiYuanSong]: 'SiYuanSong',
  [FontFamilys.SiYuanSong]: 'Source Han Serif SC',
  [FontFamilys.ALiBaBa]: ['阿里巴巴普惠体', 'ALiBaBa'],
  [FontFamilys.ZhanKuGaoDuanHei]: ['站酷高端黑', 'ZhanKuGaoDuanHei'],
  [FontFamilys.ZhanKuHappy]: ['站酷快乐体', 'ZhanKuHappy'],
  [FontFamilys.ZhanKuKuHei]: ['站酷酷黑体', 'ZhanKuKuHei'],
  [FontFamilys.ZhanKuLOGO]: ['站酷小薇LOGO体', 'ZhanKuLOGO'],
  [FontFamilys.ZhanKuHuangYou]: ['站酷庆科黄油体', 'ZhanKuHuangYou'],
  [FontFamilys.YouSheTitle]: ['优设标题黑', 'YouSheTitle'],
  [FontFamilys.YouSheGoodBody]: ['优设好身体', 'YouSheGoodBody'],
  [FontFamilys.YuanQuanYuan]: ['GenSenMaruGothic TW TTF Regular', 'YuanQuanYuan'],
  [FontFamilys.TaiBeiHei]: ['Taipei Sans TC Beta', 'TaiBeiHei'],
  [FontFamilys.PangMenTitle]: ['庞门正道标题体', 'PangMenTitle'],
  [FontFamilys.PangMenBold]: ['庞门正道粗书体', 'PangMenBold'],
  [FontFamilys.OPPOSans]: 'OPPO Sans',
  [FontFamilys.HarmonySansCondensed]: ['Harmony Sans Condensed', 'HarmonyOS Sans Condensed'],
  [FontFamilys.HarmonySansSC]: ['HarmonyOS Sans SC', 'Harmony Sans SC'],
  [FontFamilys.HarmonySansTC]: ['Harmony Sans TC', 'HarmonyOS Sans TC'],
};

export const getFontFamily = (key: string): string => {
  if (Object.keys(fontFamilysConfig).includes(key)) {
    const value = fontFamilysConfig[key as FontFamilys];
    if (typeof value === 'string') {
      return value;
    }
    return value[0];
  } else {
    return key;
  }
};

export const getStyleFontFamily = (key: string): string => {
  if (Object.keys(fontFamilysConfig).includes(key)) {
    const value = fontFamilysConfig[key as FontFamilys];
    if (typeof value === 'string') {
      return value;
    }
    return value.join();
  } else {
    return key;
  }
};

export const getFontKey = (value: string): string => {
  const key = Object.keys(fontFamilysConfig).find((k) => {
    try {
      // 正则表达式存在错误隐患
      // 如果value包含不能regexp创建的表达式,则该字符串存在不合理字符
      //  如: value = '\\abc\' 用regexp无法创建正确的表达式
      const v = fontFamilysConfig[k as FontFamilys];
      // 忽略大小写 + 扩展搜索范围 + 清除引号
      const regExp = new RegExp(value.replace(/'|"/g, ''), 'i');
      if (typeof v === 'string') {
        return v.match(regExp);
      }
      return v.join().match(regExp);
    } catch (error) {
      return false;
    }
  });
  const families = value.split(',');
  if (families.length > 1) {
    return families.find((f) => getFontKey(f) !== f) || key || value;
  }
  return key || value;
};

export const DefaultTextSize = [
  { id: 8, text: '8' },
  { id: 9, text: '9' },
  { id: 10, text: '10' },
  { id: 11, text: '11' },
  { id: 12, text: '12' },
  { id: 13, text: '13' },
  { id: 14, text: '14' },
  { id: 16, text: '16' },
  { id: 18, text: '18' },
  { id: 20, text: '20' },
  { id: 22, text: '22' },
  { id: 24, text: '24' },
  { id: 26, text: '26' },
  { id: 28, text: '28' },
  { id: 36, text: '36' },
  { id: 42, text: '42' },
  { id: 48, text: '48' },
  { id: 72, text: '72' },
];

export const FontBoxScale = 0.5;

export const DefaultRichTextFontSize = DefaultTextSize; //.filter(item=>item.id>=12);

export const DefaultFontSize = 14;

export const MinFontSize = DefaultTextSize[0].id;

export const MinRichTextSize = DefaultRichTextFontSize[0].id;

export function checkFontFamily(family?: string) {
  if (!family) {
    return FontFamilys.MicrosoftYaHei;
  }
  const fms: string[] = family.split(',');
  let result: string = '';
  let index = Number.POSITIVE_INFINITY;
  fms.forEach((f, i) => {
    const family: string = f.trim();
    const v: string = getEnumName(FontFamilys, family);
    if (v && i < index) {
      index = i;
      result = getEnumValue(FontFamilys, v);
    }
  });
  return result;
}

export function isChineseFont(fontName: string) {
  const hasChineseCharRegex = /[\u4e00-\u9fa5]/;
  return hasChineseCharRegex.test(fontName);
}
