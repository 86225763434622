import * as React from 'react';

import './index.scss';

export interface IPanelProp {
  children: any;
}

export interface IPanelState {}

class Panel extends React.Component<IPanelProp, IPanelState> {
  static defaultProps: Partial<IPanelProp> = {};

  selfRef: React.RefObject<HTMLDivElement>;

  constructor(props: IPanelProp) {
    super(props);
    this.state = {};
    this.selfRef = React.createRef();
  }

  render() {
    const { children } = this.props;
    return <div ref={this.selfRef}>{children}</div>;
  }
}

export default Panel;
