import { Dispatch } from 'redux';

import i18n from '@/i18n';
import { CustomError } from '@fbs/common/models/error';
import { IOResponseCode } from '@/fbs/rp/models/io';
import { ErrorCode } from '@/fbs/idoc/consts/ErrorCode';
import { countDownTime } from '@/utils/globalUtils';
import { ToastIconColorError } from '@/consts/colors/ant';

import { ActionsUnion, ActionType, createAction, DialogType, ToastType, IDialogOptions } from '../types';
import { AppThunkActions } from '../actions';

export const Actions = {
  showToast: (
    type: ToastType,
    tip: string,
    options?: { autoClose?: boolean; delay?: number; iconCls?: string; iconColor?: string; rightIcon?: boolean },
    btnOptions?: {
      needCloseIcon?: boolean;
      submitBtn?: string;
      closeBtn?: string;
      onSubmit?: Function;
      onClose?: Function;
    },
  ) => createAction(ActionType.Global_Toast, { type, tip, options, btnOptions }),
  closeToast: (type: ToastType) => createAction(ActionType.Global_Close_Toast, type),
  ioMessage: (message: string) => createAction(ActionType.Global_IO_Message, message),
  socketStateChange: (available: boolean) => createAction(ActionType.Global_SOCKET_STATE_CHANGE, available),
  networkStateChange: (available: boolean) => createAction(ActionType.Global_NETWORK_STATE_CHANGE, available),

  afk: () => createAction(ActionType.Global_AFK),
  waiting: (waiting: boolean, theme?: string) => createAction(ActionType.Global_Waiting, { waiting, theme }),
  toggleOfflineSync: (isSync: boolean) => createAction(ActionType.Global_Offline_Sync, isSync),
  remoteLogin: () => createAction(ActionType.Global_Remote_Login, true),
  loginExpired: () => createAction(ActionType.Global_Login_Expired, true),
  setErrorCode: (code?: ErrorCode) => createAction(ActionType.Global_ErrorCode, code),
  LoseProjectPermission: () => createAction(ActionType.Global_Loss_Of_Project_Permission, true),
  setLogOffTeamPageTip: (unsubscribeTime: number) => createAction(ActionType.Global_Log_Off_Team, unsubscribeTime),
  changeWeMeetLimitTipDialogStatus: (isShow: boolean) =>
    createAction(ActionType.Global_Show_We_Meet_Limit_Tip_Dialog, isShow),
  showDialog: (type: DialogType, opts?: IDialogOptions) => createAction(ActionType.Global_Dialog, { type, opts }),
  pushDialogToQueue: (dialog: React.ReactNode) => createAction(ActionType.Global_Push_Dialog_To_Queue, dialog),
  popDialogFromQueue: () => createAction(ActionType.Global_Pop_Dialog_From_Queue),
  loginOut: () => createAction(ActionType.Global_Login_Out, true),
};

const DEFAULT_DELAY_TIME = 2000;
let toastTimer: number = 0;

export const ThunkActions = {
  showToast: (
    type: ToastType,
    tip: string,
    options?: { autoClose?: boolean; delay?: number; iconCls?: string; iconColor?: string; rightIcon?: boolean },
    btnOptions?: {
      needCloseIcon?: boolean;
      needSupport?: boolean;
      submitBtn?: string;
      closeBtn?: string;
      onSubmit?: Function;
      onClose?: Function;
    },
  ) => async (dispatch: Dispatch) => {
    if (RP_CONFIGS.isPrivateDeployment) {
      if (btnOptions && btnOptions.needSupport) {
        btnOptions.needSupport = false;
      }
      dispatch(Actions.showToast(type, tip, options, btnOptions));
    } else {
      //接口处捕获的网络错误，文字替换, 取消联系我们
      const textTip = tip === 'Network Error' ? i18n('alert.uploadNetworkError') : tip;
      if (btnOptions && btnOptions.needSupport && tip === 'Network Error') {
        btnOptions.needSupport = false;
      }
      dispatch(Actions.showToast(type, textTip, options, btnOptions));
    }
    if (
      options?.autoClose ||
      type === ToastType.Success ||
      type === ToastType.Error ||
      type === ToastType.Warning ||
      type === ToastType.Normal ||
      type === ToastType.Info
    ) {
      if (toastTimer) {
        clearTimeout(toastTimer);
      }
      toastTimer = window.setTimeout(() => {
        dispatch(Actions.closeToast(type));
      }, options?.delay ?? DEFAULT_DELAY_TIME);
    }
  },
  closeToast: (type: ToastType) => async (dispatch: Dispatch) => {
    dispatch(Actions.closeToast(type));
  },
  // socket网络状态改变
  thunkSocketStateChange: (available: boolean) => (dispatch: Dispatch) => {
    dispatch(Actions.socketStateChange(available));
  },
  // 网络状态改变
  thunkNetworkStateChange: (available: boolean) => (dispatch: Dispatch) => {
    dispatch(Actions.networkStateChange(available));
  },
  // 显示报错信息
  thunkError: (errorMessage: string, timeToRemove?: number, needSupport: boolean = true) => (dispatch: Dispatch) => {
    window.debug && console.error(errorMessage);
    dispatch(Actions.waiting(false));
    ThunkActions.showToast(ToastType.Error, errorMessage, { delay: timeToRemove }, { needSupport })(dispatch);
  },

  thunkErrorMessage: (error: CustomError, toast?: boolean, timeToRemove?: number, needSupport?: boolean) => (
    dispatch: Dispatch,
  ) => {
    console.error(error);
    dispatch(Actions.setErrorCode(error.code));
    switch (error.code) {
      case IOResponseCode.Unauthorized:
        dispatch(Actions.loginExpired());
        break;
      case ErrorCode.LoginLimit:
        dispatch(Actions.remoteLogin());
        break;
      case ErrorCode.AppDestroy:
      case ErrorCode.NotMember:
      case ErrorCode.HasNoRight:
      case ErrorCode.NotAppMember:
        dispatch(Actions.LoseProjectPermission());
        break;
      case ErrorCode.ContactUs:
      case ErrorCode.PaymentRequired:
      case ErrorCode.PaymentNeedExpansion:
      case ErrorCode.PaymentRenew:
        break;
      case ErrorCode.TeamAppLimit:
        ThunkActions.thunkError(error.message, timeToRemove, needSupport)(dispatch);
        break;
      case ErrorCode.TeamAppPageLimit:
        ThunkActions.showDialog(DialogType.fullPage)(dispatch);
        break;
      default: {
        if (toast) {
          ThunkActions.showToast(
            ToastType.Error,
            error.message,
            { delay: timeToRemove },
            { needSupport: needSupport !== undefined ? needSupport : true },
          )(dispatch);
        } else {
          ThunkActions.thunkError(error.message, timeToRemove, needSupport)(dispatch);
        }
      }
    }
  },

  setLogOffTeamPageTip: (unsubscribeTime: number) => (dispatch: Dispatch) => {
    dispatch(Actions.waiting(false));
    if (!unsubscribeTime) {
      return;
    }
    const time = countDownTime(unsubscribeTime);
    dispatch(
      Actions.showToast(
        ToastType.LogOffTeamPage,
        i18n('message.logOffTeamTip', time?.day, time?.hour),
        { iconColor: ToastIconColorError, iconCls: 'tag_exclamation' },
        {
          needCloseIcon: true,
          submitBtn: i18n('message.recallCancellation'),
          onSubmit: () => {
            AppThunkActions.recallCancellation()(dispatch);
            dispatch(Actions.closeToast(ToastType.LogOffTeamPage));
          },
        },
      ),
    );
  },

  showDialog: (type: DialogType, opts?: IDialogOptions) => async (dispatch: Dispatch) => {
    dispatch(Actions.showDialog(type, opts));
  },

  closeDialog: () => async (dispatch: Dispatch) => {
    dispatch(Actions.showDialog(DialogType.none, undefined));
  },

  showLoading: (wait: boolean, theme?: string) => async (dispatch: Dispatch) => {
    dispatch(Actions.waiting(wait, theme));
  },
};

export type GlobalAction = ActionsUnion<typeof Actions>;
