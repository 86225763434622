import ITextFormat from './text';
import PropertyBase from './base';
import { PureColor } from './color';

export enum ChartType {
  Normal = 'normal', // 普通
  Stage = 'stage', // 阶段
  Grade = 'grade', // 等级
}

export enum PointType {
  Line = 'line', // 直角
  Arc = 'arc', // 圆角
}

export const GaugeChartPropertyName = 'gaugeChart';

interface IGaugeChart extends PropertyBase {
  type: ChartType;
  backgroundColor: PureColor;
  progressColor: PureColor;
  stageColorIndex: 0 | 1 | 2; // 颜色组下标，只提供三组，ChartType为Stage可用
  gradeColorIndex: 0 | 1 | 2; // 颜色组下标，只提供三组，ChartTypeGrade可用
  notchAngle: number; // 缺口角度
  arcWidth: number; // 弧线宽度，仪表盘圆弧宽度
  pointType: PointType; // 端点类型

  defaultValue: number;
  min: number;
  max: number;

  showLabel: boolean;
  labelStyle: ITextFormat;
  labelUnit: string;

  showTick: boolean; // 显示刻度
  showTickLabel: boolean; // 显示大刻度
  bTickSplitNumber: number; // 大刻度分割数量
  tickFontSize: number; //
  tickFontColor: PureColor;
  showSTick: boolean; // 显示小刻度
  sTickSplitNumber: number; // 小刻度分割数量

  showPointer: boolean; // 显示指针
  pointerColor: PureColor;

  showDataFluctuation: boolean; // 显示数据波动
}

export default IGaugeChart;
