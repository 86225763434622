import { isUndefined } from 'lodash';
import { PagePatches, reversePagePatches } from '@/fbs/rp/utils/patch';
import { CContentPanel, CContentPanelV2 } from '@libs/constants';
import { BaseCommand } from './Command';
import CoreEditor from '../core';

interface ActionInfo {
  patch: PagePatches;
  // TODO: 目前未启用下面两个属性，
  // 要启用的话，得先解决 add artboard patch 的异步问题
  active?: {
    from: string;
    to: string;
  };
  select?: {
    fragment: string;
    component: string[];
  };
}

export class RemoveFragmentCommand extends BaseCommand {
  constructor(private editor: CoreEditor, private artboardID: string) {
    super();
  }

  actionInfo: ActionInfo | undefined;

  action() {
    const { editor, artboardID } = this;

    const deletingArtboard = editor.doc.artboardsFragments.find((fram) => fram.artboardID === artboardID);
    editor.setDeletingArtboard(deletingArtboard);

    this.updateContentPanelsVersion();

    const patches: PagePatches = editor.removeArtboardPatches(artboardID);
    editor.new_update(patches);

    const actionInfo: ActionInfo = {
      patch: patches,
    };

    if (editor.activeArtboard.artboardID === artboardID) {
      editor.setActiveArtboard(editor.doc.mainArtboard);
      editor.clearSelected();
    }

    editor.clearSelectedFragments();

    return actionInfo;
  }

  execute() {
    const actionInfo = this.action();
    if (!actionInfo) {
      return;
    }

    this.actionInfo = actionInfo;
    this.editor.pushUndoStack(this);
    this.editor.clearRedoStack();
  }

  undo() {
    if (!this.actionInfo) {
      return;
    }

    const {
      editor,
      actionInfo: { patch },
    } = this;

    editor.pushRedoStack(this);

    this.updateContentPanelsVersion();

    const undoPatches = reversePagePatches(patch);
    editor.new_update(undoPatches);
  }

  redo() {
    if (!this.actionInfo) {
      return;
    }

    const {
      editor,
      actionInfo: { patch },
    } = this;

    editor.pushUndoStack(this);

    this.updateContentPanelsVersion();

    editor.new_update(patch);
  }

  updateContentPanelsVersion() {
    const { editor, artboardID } = this;

    // 处理关联的内容面板
    const contentPanels = editor.doc.getComponentsByFilter((comp) => {
      return (
        (comp.type === CContentPanel || comp.type === CContentPanelV2) &&
        !isUndefined(comp.value) &&
        (comp.value as string[]).includes(artboardID) &&
        comp.ownerArtboardID !== artboardID
      );
    });
    contentPanels.forEach((comp) => {
      comp.toJSON().v += 1;
    });
  }
}
