import mathUtils from '@/utils/mathUtils';
import * as BoundsUtils from '@utils/boundsUtils';
import { distance, isMoreOrLess } from '@utils/globalUtils';

import { Orientation } from '@/fbs/common/models/common';

import { IBounds, IPoint, ISize } from '@fbs/common/models/common';

import { UIFragment } from '@editor/comps';

export namespace ArtboardDimensionHelper {
  type ArtboardBounds = {
    bounds: IBounds;
    artbord: UIFragment;
  };

  type AlignArtboardBounds = ArtboardBounds[];

  export const initSniffNearArtboards = (): ISniffNearArtboards => ({
    topSameSpaceArtboard: [],
    bottomSpaceArtboard: [],
    leftSameSpaceArtboard: [],
    rightSpaceArtboard: [],
  });

  interface ISniffNearArtboards {
    topAlignToTop?: ArtboardBounds;
    topAlignToBottom?: ArtboardBounds;
    topAlignToMiddle?: ArtboardBounds;

    bottomAlignToBottom?: ArtboardBounds;
    bottomAlignToTop?: ArtboardBounds;
    bottomAlignToMiddle?: ArtboardBounds;

    middleAlignToMiddle?: ArtboardBounds;
    middleAlignToTop?: ArtboardBounds;
    middleAlignToBottom?: ArtboardBounds;

    leftAlignToLeft?: ArtboardBounds;
    leftAlignToRight?: ArtboardBounds;
    leftAlignToCenter?: ArtboardBounds;

    rightAlignToRight?: ArtboardBounds;
    rightAlignToLeft?: ArtboardBounds;
    rightAlignToCenter?: ArtboardBounds;

    centerAlignToCenter?: ArtboardBounds;
    centerAlignToLeft?: ArtboardBounds;
    centerAlignToRight?: ArtboardBounds;

    topSameSpaceArtboard: AlignArtboardBounds;
    leftSameSpaceArtboard: AlignArtboardBounds;
    rightSpaceArtboard: AlignArtboardBounds;
    bottomSpaceArtboard: AlignArtboardBounds;
    // 有包含关系的目标组件及区域
    containerArtboard?: ArtboardBounds;
  }

  export interface INearArtboardAlignResult {
    //吸附后的目标区域
    bounds: IBounds;
    // 显示哪些参考线
    sniffLine: {
      left: boolean;
      center: boolean;
      right: boolean;
      top: boolean;
      middle: boolean;
      bottom: boolean;
    };
    // 各方向上有对齐关系的区域
    sameAlign: {
      top: IBounds[];
      left: IBounds[];
      right: IBounds[];
      bottom: IBounds[];
    };
  }

  class testArtboardAlign {
    //面板移动侦测吸附距离
    private adsorptionDistance = 10;
    //鼠标移动侦测吸附距离
    private mouseAdsorptionDistance = 5;
    //鼠标按下时记录当前的所有面板信息
    private artboardBoundsCathe: { [key: string]: ArtboardBounds } = {};

    public catheArtbordBounds = (artborads: UIFragment[]) => {
      this.clear();
      artborads.forEach((artboard) => {
        this.artboardBoundsCathe[artboard.artboardID] = {
          bounds: artboard.getViewBoundsInPage(),
          artbord: artboard,
        };
      });
    };

    public clear() {
      this.artboardBoundsCathe = {};
    }

    public get artboardCacheBounds() {
      return this.artboardBoundsCathe;
    }

    private getArtboardSnapAlignValue = (
      artboards: UIFragment[],
      targetBounds: IBounds,
      activeArtboard?: UIFragment,
    ) => {
      const unActiveArtboard = artboards.filter(
        (artboard) => activeArtboard && artboard.artboardID !== activeArtboard.artboardID,
      );
      const value = initSniffNearArtboards();

      const leftArtboards: AlignArtboardBounds = [];
      const topArtboards: AlignArtboardBounds = [];
      const rightArtboards: AlignArtboardBounds = [];
      const bottomArtboards: AlignArtboardBounds = [];
      unActiveArtboard.forEach((artboard: UIFragment) => {
        const data = this.artboardBoundsCathe[artboard.artboardID];
        const referenceBounds = data.bounds;
        const referenceCenter = BoundsUtils.center(referenceBounds).left;
        const referenceMiddle = BoundsUtils.center(referenceBounds).top;

        const targetCenter = BoundsUtils.center(targetBounds).left;
        const targetMiddle = BoundsUtils.center(targetBounds).top;

        let inVerticalAlign = true;
        let inHorizontalAlign = true;
        let newDistance = 0;
        let oldDistance = 0;

        // 1、center align center
        if (isMoreOrLess(referenceCenter, targetCenter, this.adsorptionDistance)) {
          if (value.centerAlignToCenter) {
            newDistance = distance(referenceCenter, targetCenter);
            oldDistance = distance(BoundsUtils.center(value.centerAlignToCenter.bounds).left, targetCenter);
            if (newDistance < oldDistance) {
              value.centerAlignToCenter = data;
            }
          } else {
            value.centerAlignToCenter = data;
          }
        }
        // 2、left align left
        else if (isMoreOrLess(referenceBounds.left, targetBounds.left, this.adsorptionDistance)) {
          if (value.leftAlignToLeft) {
            newDistance = distance(referenceBounds.left, targetBounds.left);
            oldDistance = distance(value.leftAlignToLeft.bounds.left, targetBounds.left);
            if (newDistance < oldDistance) {
              value.leftAlignToLeft = data;
            }
          } else {
            value.leftAlignToLeft = data;
          }
        }

        // 3、right align right
        else if (isMoreOrLess(referenceBounds.right, targetBounds.right, this.adsorptionDistance)) {
          if (value.rightAlignToRight) {
            newDistance = distance(referenceBounds.right, targetBounds.right);
            oldDistance = distance(value.rightAlignToRight.bounds.right, targetBounds.right);
            if (newDistance < oldDistance) {
              value.rightAlignToRight = data;
            }
          } else {
            value.rightAlignToRight = data;
          }
        } else {
          inVerticalAlign = false;
        }

        // 10、middle align middle
        if (isMoreOrLess(referenceMiddle, targetMiddle, this.adsorptionDistance)) {
          if (value.middleAlignToMiddle) {
            newDistance = distance(referenceMiddle, targetMiddle);
            oldDistance = distance(BoundsUtils.center(value.middleAlignToMiddle.bounds).top, targetMiddle);
            if (newDistance < oldDistance) {
              value.middleAlignToMiddle = data;
            }
          } else {
            value.middleAlignToMiddle = data;
          }
        }
        // 11、top align top
        else if (isMoreOrLess(referenceBounds.top, targetBounds.top, this.adsorptionDistance)) {
          if (value.topAlignToTop) {
            newDistance = distance(referenceBounds.top, targetBounds.top);
            oldDistance = distance(value.topAlignToTop.bounds.top, targetBounds.top);
            if (newDistance < oldDistance) {
              value.topAlignToTop = data;
            }
          } else {
            value.topAlignToTop = data;
          }
        }
        // 12、bottom align bottom
        else if (isMoreOrLess(referenceBounds.bottom, targetBounds.bottom, this.adsorptionDistance)) {
          if (value.bottomAlignToBottom) {
            newDistance = distance(referenceBounds.bottom, targetBounds.bottom);
            oldDistance = distance(value.bottomAlignToBottom.bounds.bottom, targetBounds.bottom);
            if (newDistance < oldDistance) {
              value.bottomAlignToBottom = data;
            }
          } else {
            value.bottomAlignToBottom = data;
          }
        } else {
          inHorizontalAlign = false;
        }

        if (inHorizontalAlign) {
          if (referenceBounds.right < targetBounds.left) {
            leftArtboards.push(data);
          } else if (referenceBounds.left > targetBounds.right) {
            rightArtboards.push(data);
          }
        }

        if (inVerticalAlign) {
          if (referenceBounds.bottom < targetBounds.top) {
            topArtboards.push(data);
          } else if (referenceBounds.top > targetBounds.bottom) {
            bottomArtboards.push(data);
          }
        }
      });
      return {
        snapAlignValue: value,
        left: leftArtboards,
        top: topArtboards,
        right: rightArtboards,
        bottom: bottomArtboards,
      };
    };

    /**
     * 面板移动时的侦测对齐
     */
    public testMovingAlign = (
      artboards: UIFragment[],
      activeArtboardBounds: IBounds,
      activeArtboard?: UIFragment,
    ): INearArtboardAlignResult => {
      const { snapAlignValue, left, top, right, bottom } = this.getArtboardSnapAlignValue(
        artboards,
        activeArtboardBounds,
        activeArtboard,
      );
      let showLeftSniffLine = false;
      let showCenterSniffLine = false;
      let showRightSniffLine = false;
      let showTopSniffLine = false;
      let showMiddleSniffLine = false;
      let showBottomSniffLine = false;

      let previous: ArtboardBounds;

      // 1、左侧有对齐关系面板的最近一个的距离
      if (left.length > 0) {
        left.sort((v1, v2): number => {
          if (v1.bounds.right > v2.bounds.right) {
            return -1;
          } else if (v1.bounds.right < v2.bounds.right) {
            return 1;
          }
          return 0;
        });
        previous = left[0];
        snapAlignValue.leftSameSpaceArtboard.push(previous);
      }

      // 2、右侧有对齐关系的等间距组件区域数据
      if (right.length > 0) {
        right.sort((v1, v2): number => {
          if (v1.bounds.left < v2.bounds.left) {
            return -1;
          } else if (v1.bounds.left > v2.bounds.left) {
            return 1;
          }
          return 0;
        });
        previous = right[0];
        snapAlignValue.rightSpaceArtboard.push(previous);
      }

      // 3、上侧有对齐关系的等间距组件区域数据
      if (top.length > 0) {
        top.sort((v1, v2): number => {
          if (v1.bounds.bottom > v2.bounds.bottom) {
            return -1;
          } else if (v1.bounds.bottom < v2.bounds.bottom) {
            return 1;
          }
          return 0;
        });
        previous = top[0];
        snapAlignValue.topSameSpaceArtboard.push(previous);
      }

      // 4、下侧有对齐关系的等间距组件区域数据
      if (bottom.length > 0) {
        bottom.sort((v1, v2): number => {
          if (v1.bounds.top < v2.bounds.top) {
            return -1;
          } else if (v1.bounds.top > v2.bounds.top) {
            return 1;
          }
          return 0;
        });
        previous = bottom[0];
        snapAlignValue.bottomSpaceArtboard.push(previous);
      }

      let newBounds = { ...activeArtboardBounds };
      // 存在居中对齐的组件时，自动居中吸附对齐
      if (snapAlignValue.centerAlignToCenter) {
        showCenterSniffLine = true;
        if (snapAlignValue.centerAlignToCenter.bounds.width === activeArtboardBounds.width) {
          showLeftSniffLine = showRightSniffLine = showCenterSniffLine;
        }
        newBounds = BoundsUtils.setLocation(newBounds, {
          left:
            BoundsUtils.center(snapAlignValue.centerAlignToCenter.bounds).left -
            Math.round(activeArtboardBounds.width / 2),
          top: newBounds.top,
        });
      }
      // 存在左侧对齐的组件时，自动吸附到右侧
      else if (snapAlignValue.leftAlignToLeft) {
        showLeftSniffLine = true;
        if (snapAlignValue.leftAlignToLeft.bounds.width === activeArtboardBounds.width) {
          showCenterSniffLine = showRightSniffLine = showLeftSniffLine;
        }
        newBounds = BoundsUtils.setLocation(newBounds, {
          left: snapAlignValue.leftAlignToLeft.bounds.left,
          top: newBounds.top,
        });
      }
      // 存在右侧对齐组件时，自动右侧吸附对齐
      else if (snapAlignValue.rightAlignToRight) {
        showRightSniffLine = true;
        if (snapAlignValue.rightAlignToRight.bounds.width === activeArtboardBounds.width) {
          showLeftSniffLine = showCenterSniffLine = showRightSniffLine;
        }
        newBounds = BoundsUtils.setLocation(newBounds, {
          left: snapAlignValue.rightAlignToRight.bounds.right - activeArtboardBounds.width,
          top: newBounds.top,
        });
      }

      if (snapAlignValue.middleAlignToMiddle) {
        showMiddleSniffLine = true;
        if (snapAlignValue.middleAlignToMiddle.bounds.height === activeArtboardBounds.height) {
          showTopSniffLine = showBottomSniffLine = showMiddleSniffLine;
        }
        newBounds = BoundsUtils.setLocation(newBounds, {
          left: newBounds.left,
          top:
            BoundsUtils.center(snapAlignValue.middleAlignToMiddle.bounds).top -
            Math.round(activeArtboardBounds.height / 2),
        });
      } else if (snapAlignValue.topAlignToTop) {
        showTopSniffLine = true;
        if (snapAlignValue.topAlignToTop.bounds.height === activeArtboardBounds.height) {
          showMiddleSniffLine = showBottomSniffLine = showTopSniffLine;
        }
        newBounds = BoundsUtils.setLocation(newBounds, {
          left: newBounds.left,
          top: snapAlignValue.topAlignToTop.bounds.top,
        });
      } else if (snapAlignValue.bottomAlignToBottom) {
        showBottomSniffLine = true;
        if (activeArtboardBounds.height === snapAlignValue.bottomAlignToBottom.bounds.height) {
          showTopSniffLine = showMiddleSniffLine = showBottomSniffLine;
        }
        newBounds = BoundsUtils.setLocation(newBounds, {
          left: newBounds.left,
          top: snapAlignValue.bottomAlignToBottom.bounds.bottom - activeArtboardBounds.height,
        });
      }

      return {
        bounds: newBounds,
        sniffLine: {
          left: showLeftSniffLine,
          center: showCenterSniffLine,
          right: showRightSniffLine,
          top: showTopSniffLine,
          middle: showMiddleSniffLine,
          bottom: showBottomSniffLine,
        },
        sameAlign: {
          left: [newBounds, ...snapAlignValue.leftSameSpaceArtboard.map((item) => ({ ...item.bounds }))],
          right: [newBounds, ...snapAlignValue.rightSpaceArtboard.map((item) => ({ ...item.bounds }))],
          top: [newBounds, ...snapAlignValue.topSameSpaceArtboard.map((item) => ({ ...item.bounds }))],
          bottom: [newBounds, ...snapAlignValue.bottomSpaceArtboard.map((item) => ({ ...item.bounds }))],
        },
      };
    };

    public getArtboardSnapAlignValueWithMouse = (artboards: UIFragment[], mouse: IPoint) => {
      const value = initSniffNearArtboards();
      const leftArtboards: AlignArtboardBounds = [];
      const topArtboards: AlignArtboardBounds = [];
      const rightArtboards: AlignArtboardBounds = [];
      const bottomArtboards: AlignArtboardBounds = [];
      const targetBounds = BoundsUtils.empty();
      targetBounds.left = mouse.x;
      targetBounds.top = mouse.y;
      this.catheArtbordBounds(artboards);
      artboards.forEach((artboard) => {
        const data = this.artboardBoundsCathe[artboard.artboardID];
        const referenceBounds = data.bounds;
        const referenceCenter = BoundsUtils.center(referenceBounds).left;
        const referenceMiddle = BoundsUtils.center(referenceBounds).top;
        let newDistance = 0;
        let oldDistance = 0;
        // 2、left align left
        if (isMoreOrLess(referenceBounds.left, targetBounds.left, this.mouseAdsorptionDistance)) {
          if (value.leftAlignToLeft) {
            newDistance = distance(referenceBounds.left, targetBounds.left);
            oldDistance = distance(value.leftAlignToLeft.bounds.left, targetBounds.left);
            if (newDistance < oldDistance) {
              value.leftAlignToLeft = data;
            }
          } else {
            value.leftAlignToLeft = data;
          }
        }

        // 5、left align center
        else if (isMoreOrLess(referenceCenter, targetBounds.left, this.mouseAdsorptionDistance)) {
          if (value.leftAlignToCenter) {
            newDistance = distance(referenceCenter, targetBounds.left);
            oldDistance = distance(BoundsUtils.center(value.leftAlignToCenter.bounds).left, targetBounds.left);
            if (newDistance < oldDistance) {
              value.leftAlignToCenter = data;
            }
          } else {
            value.leftAlignToCenter = data;
          }
        }

        // 4、left align right
        else if (isMoreOrLess(referenceBounds.right, targetBounds.left, this.mouseAdsorptionDistance)) {
          if (value.leftAlignToRight) {
            newDistance = distance(referenceBounds.right, targetBounds.left);
            oldDistance = distance(value.leftAlignToRight.bounds.right, targetBounds.left);
            if (newDistance < oldDistance) {
              value.leftAlignToRight = data;
            }
          } else {
            value.leftAlignToRight = data;
          }
        }

        // 11、top align top
        if (isMoreOrLess(referenceBounds.top, targetBounds.top, this.adsorptionDistance)) {
          if (value.topAlignToTop) {
            newDistance = distance(referenceBounds.top, targetBounds.top);
            oldDistance = distance(value.topAlignToTop.bounds.top, targetBounds.top);
            if (newDistance < oldDistance) {
              value.topAlignToTop = data;
            }
          } else {
            value.topAlignToTop = data;
          }
        }
        // 13、top align bottom
        else if (isMoreOrLess(referenceBounds.bottom, targetBounds.top, this.adsorptionDistance)) {
          if (value.topAlignToBottom) {
            newDistance = distance(referenceBounds.bottom, targetBounds.top);
            oldDistance = distance(value.topAlignToBottom.bounds.bottom, targetBounds.top);
            if (newDistance < oldDistance) {
              value.topAlignToBottom = data;
            }
          } else {
            value.topAlignToBottom = data;
          }
        }
        // 14、top align middle
        else if (isMoreOrLess(referenceMiddle, targetBounds.top, this.adsorptionDistance)) {
          if (value.topAlignToMiddle) {
            newDistance = distance(referenceMiddle, targetBounds.top);
            oldDistance = distance(BoundsUtils.center(value.topAlignToMiddle.bounds).top, targetBounds.top);
            if (newDistance < oldDistance) {
              value.topAlignToMiddle = data;
            }
          } else {
            value.topAlignToMiddle = data;
          }
        }
      });

      return {
        snapAlignValue: value,
        left: leftArtboards,
        top: topArtboards,
        right: rightArtboards,
        bottom: bottomArtboards,
      };
    };

    /**
     * 判断当前的鼠标与面板之间的对齐关系
     */
    public creatingArtboardMovingAlign = (artboards: UIFragment[], mouse: IPoint) => {
      const { snapAlignValue } = this.getArtboardSnapAlignValueWithMouse(artboards, mouse);
      let showLeftSniffLine = false;
      let showCenterSniffLine = false;
      let showRightSniffLine = false;
      let showTopSniffLine = false;
      let showMiddleSniffLine = false;
      let showBottomSniffLine = false;
      let newBounds = BoundsUtils.empty();
      newBounds.left = mouse.x;
      newBounds.top = mouse.y;
      if (snapAlignValue.leftAlignToLeft) {
        showLeftSniffLine = true;
        newBounds = BoundsUtils.setLocation(newBounds, {
          left: snapAlignValue.leftAlignToLeft.bounds.left,
          top: newBounds.top,
        });
      } else if (snapAlignValue.leftAlignToCenter) {
        showLeftSniffLine = true;
        newBounds = BoundsUtils.setLocation(newBounds, {
          left: BoundsUtils.center(snapAlignValue.leftAlignToCenter.bounds).left,
          top: newBounds.top,
        });
      } else if (snapAlignValue.leftAlignToRight) {
        showLeftSniffLine = true;
        newBounds = BoundsUtils.setLocation(newBounds, {
          left: snapAlignValue.leftAlignToRight.bounds.right,
          top: newBounds.top,
        });
      }

      if (snapAlignValue.topAlignToTop) {
        showTopSniffLine = true;
        newBounds = BoundsUtils.setLocation(newBounds, {
          left: newBounds.left,
          top: snapAlignValue.topAlignToTop.bounds.top,
        });
      } else if (snapAlignValue.topAlignToMiddle) {
        showTopSniffLine = true;
        newBounds = BoundsUtils.setLocation(newBounds, {
          left: newBounds.left,
          top: BoundsUtils.center(snapAlignValue.topAlignToMiddle.bounds).top,
        });
      } else if (snapAlignValue.topAlignToBottom) {
        showTopSniffLine = true;
        newBounds = BoundsUtils.setLocation(newBounds, {
          left: newBounds.left,
          top: snapAlignValue.topAlignToBottom.bounds.bottom,
        });
      }

      return {
        bounds: newBounds,
        sniffLine: {
          left: showLeftSniffLine,
          center: showCenterSniffLine,
          right: showRightSniffLine,
          top: showTopSniffLine,
          middle: showMiddleSniffLine,
          bottom: showBottomSniffLine,
        },
        sameAlign: {
          left: [],
          right: [],
          top: [],
          bottom: [],
        },
      };
    };
  }

  export const util = new testArtboardAlign();
}

export function getSizeFromOrientation(orientation: Orientation | undefined, prevSize: ISize): ISize {
  const { width, height } = prevSize;
  const min = Math.min(width, height);
  const max = Math.max(width, height);
  switch (orientation) {
    case 'portrait':
      return { width: min, height: max };
    case 'landscape':
      return { width: max, height: min };
    default:
      return prevSize;
  }
}

export function getOrientationFromSize(size: ISize): Orientation {
  const { width, height } = size;
  return mathUtils.round(width) <= mathUtils.round(height) ? 'portrait' : 'landscape';
}
