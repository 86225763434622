import IArtboard from '@/fbs/rp/models/artboard';
import i18n from '@i18n';
import { max } from '@/utils/globalUtils';

import CoreEditor from '@/editor/core';
import { UIFragment } from '@/editor/comps';

export const copyArtboardName = (artboardData: IArtboard, editor: CoreEditor) => {
  //修改面板名称，兼容旧数据
  const originName = artboardData.name;
  const reg = new RegExp(`^([^-]+)\\s*-\\s*(副本|copy)\\-*\\s*(\\d+)$`);
  const name = originName;
  const m = name?.match(reg);
  // 为啥replace?: 编辑后返回的字符串中的空格与自定义的空格不相同，需要统一空格；
  const copyName = m ? m[1].trim().replace(/\s/g, ' ') : name || '';

  const artboardsFragments = editor.doc.visibleArtboardsFragments;
  const allSameNames = artboardsFragments.reduce<{ originFragName?: string; name: string }[]>((prev, curr) => {
    const fragName = curr.name?.replace(/\s/g, ' ') || '';
    fragName.indexOf(copyName) !== -1 && prev.push({ originFragName: curr.$data.name, name: fragName });
    return prev;
  }, []);

  let maxIndex = 0;
  const sameNameListInSelectFragments: UIFragment[] = [];
  allSameNames.forEach(({ originFragName, name }) => {
    editor.selectedFragments.forEach((fragment) => {
      if (fragment.$data.name === originFragName) {
        sameNameListInSelectFragments.push(fragment);
      }
    });
    // find max index
    const matchArr = name?.match(reg);
    const indexInName = Number(matchArr ? matchArr[3] : 0);
    !isNaN(indexInName) && (maxIndex = max(maxIndex, indexInName));
  });

  // 选中的画板中有同名的情况需要加上索引
  // 在选中的画板中同名画板列表的索引
  let indexInSameNameList = 0;
  sameNameListInSelectFragments.forEach((fragment, index) => {
    if (fragment.$data.name === originName) {
      indexInSameNameList = index;
    }
  });

  return `${copyName} - ${i18n('workspace.artboard.copy')} ${maxIndex + indexInSameNameList + 1}`;
};
