import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { parseUrlSearch } from '@/utils/urlUtils';

import i18n from '@i18n';
import router from '@/consts/router';
import { DialogIsOfflineDemo } from '@/consts/dialog';
import {
  IPreviewOption,
  IShareOption,
  shareOptionToSearchStr,
  getPreviewOptFromUrl,
  getUrlSearchObj,
  SearchParamConfig,
} from '@/helpers/previewHelper';
import { mapNodes } from '@/helpers/exportPageHelper';
import { mobileTypeState } from '@/helpers/shareAppHelper';
import * as Actions from '@/store/actions';
import { IMainState } from '@/store/webTypes';
import { IAppWithNestedChildren } from '@fbs/rp/models/app';
import { ControlPanelState } from '@/fbs/rp/models/preview';
import { Role, MemberPermission } from '@/fbs/teamManagement';
import { INode } from '@/dsm/PageTree/model';
import { getGroupPageNum, getCheckPageKeys, flattenChildren } from '@/dsm/PageTree/utils';
import apis from '@apis';

import { Dialog, Icon, CheckBox, Label, Button } from '@/dsm';
import { Input /* Checkbox, */ } from '@dsm2';
import PreviewCommonOptions from '../../../../Common/PreviewCommonOptions';
import PageTreeDialog from '../../../../Common/PageTreeDialog';

import * as WaitingImg from '@assets/image/waiting.gif';

import './index.scss';

interface IShareStateProps {
  currentPageID: string;
  teamID?: string;
  roleInTeam?: Role;
  userID?: number;
  userName?: string;
  previewNeedPassword: boolean;
  shareRunID: string;
  sharePassword: string;
  isLoadLink: boolean;
  waiting?: boolean;
  linkID: string;
  nextLinkID: string;
  shortLinkID?: string;
  onMsgPopup: (msg: string, time?: number | undefined) => void;
}

interface IDispatcherProps {
  getUserInfo: () => void;
  getShareRunLink: (appID: string) => void;
  setShareLinkPass: (appID: string, password: string) => void;
  getPwEnable: (shareID: string, prototypeID?: string) => void;
  changePwEnable: (appID: string, enabled: boolean) => void;
  resetLink: (appID: string, shareID?: string, pageID?: string, moduleID?: string, idocAppID?: string) => void;
  setShareLinkNodeIds: (linkID: string, nodes: string[]) => void;
  setShortLinkProps: (shortLinkID: string, url: string) => void;
}

interface IShareRunDialogProps extends IShareStateProps, IDispatcherProps {
  app: IAppWithNestedChildren;
  appID: string;
  runUrl: string;
  option?: IPreviewOption;
  controlPanelState: ControlPanelState;
  remarkPanelState: ControlPanelState;

  closeShareRun: () => void;
  onCopyPreviewURL: (url: string, pswd: string, isEnablePass: boolean) => void;
  onSelectProjectPage: (checkedKeys: string[], checkedPageKeys: string[]) => void;
}

interface IShareRunDialogState {
  password?: string;
  isEnablePass: boolean;
  isInputError: string;
  isShowPageSelect: boolean;
  shareOpt: IShareOption;
  isShowPassword: boolean;
  linkReseting: boolean;
  nextLinkID: string;
  nodes: INode[] | undefined;
  checkedPageKeys: string[];
  checkedKeys: string[];
  expandedKeys: string[];
  shortLinkID?: string;
}

class ShareRunDialog extends React.Component<IShareRunDialogProps, IShareRunDialogState> {
  private pageTreeRef: React.RefObject<PageTreeDialog> = React.createRef();
  private pageTreeWrapRef: React.RefObject<HTMLDivElement> = React.createRef();
  private moduleID: string;
  private idocAppID: string;

  constructor(props: IShareRunDialogProps) {
    super(props);
    const showPageNumber = props.app?.showRPPageNumber ?? false;
    const nodes = mapNodes(props.app?.children, { showPageNumber });
    const { moduleID, appID } = parseUrlSearch();
    this.moduleID = moduleID;
    this.idocAppID = appID;

    this.handleCopy = this.handleCopy.bind(this);
    this.isCreator = this.isCreator.bind(this);
    this.state = {
      password: undefined,
      isEnablePass: false,
      isShowPageSelect: false,
      isInputError: '',
      shareOpt: {},
      isShowPassword: false,
      linkReseting: false,
      nextLinkID: '',
      nodes,
      checkedPageKeys: [],
      checkedKeys: [],
      expandedKeys: [],
    };
  }

  componentDidMount() {
    const { app, getShareRunLink, getUserInfo, getPwEnable, linkID } = this.props;
    getUserInfo();
    getShareRunLink(this.moduleID || app._id);
    getPwEnable(app.shareID, this.moduleID || '');
    //如果linkID获取到,重复打开
    if (linkID) {
      this.updateLinkProject(linkID);
    }
  }

  updateLinkProject = async (linkID: string) => {
    const { onSelectProjectPage, currentPageID, app } = this.props;
    const { nodes = [] } = this.state;
    //过滤掉隐藏的页面
    const flatShowNodesMap: { [id: string]: INode } = {};
    flattenChildren(nodes).forEach((e: INode) => {
      if (!e.data.hidden) {
        flatShowNodesMap[e.id] = e;
      }
    });
    try {
      // 短链接
      const res = await apis.share.getPageInShareRunLink(
        linkID,
        app.shareID,
        currentPageID,
        this.isEnablePassChecked,
        this.moduleID,
        this.idocAppID,
      );
      const shortLinkID = res.shortLinkID;
      shortLinkID && (await this.initShareOpt(shortLinkID));

      let checkedKeys = res.nodes.filter((e) => flatShowNodesMap[e]);
      let checkedPageKeys: string[];
      let allSelectKeys = false;
      //是否全选
      if (!checkedKeys.length) {
        const nodesThatHasPage = nodes!.filter((node) => {
          return getGroupPageNum([node]).length > 0;
        });
        checkedKeys = getGroupPageNum(nodesThatHasPage!);
        checkedPageKeys = getCheckPageKeys(checkedKeys, nodes!);
        allSelectKeys = true;
      } else {
        checkedPageKeys = getCheckPageKeys(checkedKeys, nodes!);
      }
      const expandedKeys = checkedPageKeys;
      this.setState({ checkedKeys, checkedPageKeys, expandedKeys, shortLinkID });
      onSelectProjectPage(checkedKeys, checkedPageKeys);
      if (this.pageTreeRef?.current) {
        this.pageTreeRef?.current?.updateTreeCheck(checkedKeys, checkedPageKeys, expandedKeys, allSelectKeys);
      } else if (this.pageTreeWrapRef.current) {
        //等待组件挂载，挂载后调用更新
        var observer = new MutationObserver(() => {
          this.pageTreeRef?.current?.updateTreeCheck(checkedKeys, checkedPageKeys, expandedKeys, allSelectKeys);
          observer.disconnect();
        });
        observer.observe(this.pageTreeWrapRef.current, { childList: true, characterData: true });
      }
    } catch (err) {
      window.debug && console.log(err);
    }
  };

  initShareOpt = async (shortLinkID: string) => {
    const { app } = this.props;
    const defaultOptions = this.getDefaultShareOpt(app.appType);

    const longUrl = await apis.share.getShortLinkUrl(shortLinkID);
    const longRawUrl = longUrl.includes('?') ? longUrl.split('?')[1] : longUrl;
    const longUrlRawOptions = getUrlSearchObj(longRawUrl);
    const longUrlOptions = getPreviewOptFromUrl(longUrlRawOptions);

    const shareOpt = Object.assign({}, defaultOptions, longUrlOptions);

    if (longUrlRawOptions[SearchParamConfig.controlPanelState]) {
      shareOpt.controlPanelState = longUrlRawOptions[SearchParamConfig.controlPanelState] as ControlPanelState;
    }

    if (longUrlRawOptions[SearchParamConfig.remarkPanelState]) {
      shareOpt.remarkPanelState = longUrlRawOptions[SearchParamConfig.remarkPanelState] as ControlPanelState;
    }

    this.setState({ shareOpt });
  };

  getDefaultShareOpt = (appType: string) => {
    return {
      showNavigationBar: true,
      controlPanelState: ControlPanelState.Expand,
      remarkPanelState: ControlPanelState.Expand,
      showRemarkTag: true,
      alwaysShowLinkArea: false,
      noBoundary: false,
      showLinkAreaWhenHovered: false,
      mobileType: mobileTypeState(appType!),
    };
  };

  //这里需要调用一下自身方法,改成UNSAFE_componentWillReceiveProps
  UNSAFE_componentWillReceiveProps(nextProps: IShareRunDialogProps) {
    const { sharePassword, isLoadLink, nextLinkID, onMsgPopup, setShareLinkNodeIds, shortLinkID } = nextProps;
    let { checkedKeys, checkedPageKeys, nodes, linkReseting, nextLinkID: preNextLinkId } = this.state;
    if (sharePassword && isLoadLink && isUndefined(this.state.password) && !this.state.isInputError) {
      this.setState({
        password: sharePassword,
      });
    }
    //初次可能store没获取到，要等待更新设置后，获取到linkID同步一下设置的linkID的页面数据
    if (!this.props.linkID && nextProps.linkID) {
      this.updateLinkProject(nextProps.linkID);
    }
    //重置链接相关
    if (nextLinkID !== preNextLinkId && linkReseting) {
      onMsgPopup(i18n('preview.resetLinkSuccess'), 1000);
      const PageCount = getGroupPageNum(nodes || []).length;
      const isAll = checkedPageKeys.length === PageCount;
      if (shortLinkID) {
        // 短链接
        this.setState({
          nextLinkID: nextLinkID,
          linkReseting: false,
          shortLinkID,
        });
      } else {
        //linkID有更新需要重新请求一下
        setShareLinkNodeIds(nextLinkID, isAll ? [] : checkedKeys);
        this.setState({
          nextLinkID: nextLinkID,
          linkReseting: false,
        });
      }
    }
    return false;
  }

  handleCopy = () => {
    const { onCopyPreviewURL } = this.props;
    const { password } = this.state;
    onCopyPreviewURL(this.shareUrl, password!, this.isEnablePassChecked);
  };

  handleReset = () => {
    const { app, resetLink, currentPageID } = this.props;
    this.setState({
      linkReseting: true,
    });
    resetLink(this.moduleID || app._id, app.shareID, currentPageID, this.moduleID, this.idocAppID);
  };

  isCreator() {
    const { app, userID } = this.props;
    return app.userID === userID;
  }

  private handleShareLinkPassInput = (val: string) => {
    this.setState({ password: val.trim(), isInputError: '' });
  };

  handleSetShareLinkPass = () => {
    const { app, sharePassword, setShareLinkPass } = this.props;
    const { password } = this.state;
    let reg = new RegExp(
      /^[\w`~!@#$%^&*()\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{0,8}$/,
    );
    const isError = !(password && reg.test(password) && password.indexOf(' ') < 0);
    if (isError) {
      let errorState = this.getErrorState(password, sharePassword);
      this.setState(errorState);
      return;
    }
    this.setState({ isInputError: '' });
    if (password !== sharePassword && !isUndefined(password)) {
      setShareLinkPass(app._id, password);
    }
  };

  /**
   * 是否有权限设置密码
   */
  get canOperatePass() {
    const { userID, roleInTeam, app } = this.props;
    return MemberPermission.canEncryptDemoLink({ id: userID!, role: roleInTeam as Role }, app as any);
  }

  /**
   * 是否禁用设置密码的开关
   */
  get isEnablePassChecked() {
    const { app } = this.props;
    const { isEnablePass } = this.state;

    if (this.canOperatePass) {
      // 如果是管理员或创建者
      if (isEnablePass) {
        // 自己启用了密码
        return true;
      } else {
        // 自己未启用密码
        return !!app.previewNeedPassword;
      }
    } else {
      // 普通成员依据项目是否开启密码
      return !!app.previewNeedPassword;
    }
  }

  /**
   * 是否来源是CC
   */
  get isRefererCC() {
    return !!this.moduleID;
  }

  private getErrorState(password: string | undefined, sharePassword: string) {
    let errorState;
    if (!password) {
      if (sharePassword) {
        errorState = { isInputError: '', password: sharePassword };
      } else {
        errorState = { isInputError: i18n('preview.setPassEmptyError'), password: '' };
      }
    } else {
      errorState = { isInputError: i18n('preview.setPassInputError'), password: '' };
    }
    return errorState;
  }

  private afterShareOptChanged = () => {
    const { setShortLinkProps } = this.props;
    const { shortLinkID } = this.state;

    // 修改短链接属性
    if (shortLinkID) {
      setShortLinkProps(shortLinkID, this.shareLongUrl);
    }
  };

  handleShowPasswordChange = () => {
    this.setState({
      isShowPassword: !this.state.isShowPassword,
    });
  };

  handleShareOptionChange = (newOpt: IShareOption) => {
    const newShareOpt = { ...this.state.shareOpt, ...newOpt };
    this.setState({ shareOpt: newShareOpt }, this.afterShareOptChanged);
  };

  handleSelectPage = (isShowPageSelect: boolean) => {
    this.setState({ isShowPageSelect });
  };

  onSubmit = (checkedKeys: string[], checkedPageKeys: string[], expandedKeys: string[]) => {
    const { linkID, setShareLinkNodeIds, onSelectProjectPage } = this.props;
    const { nodes } = this.state;
    this.setState({ checkedKeys, checkedPageKeys, expandedKeys, isShowPageSelect: false });
    const PageCount = getGroupPageNum(nodes || []).length;
    const isAll = checkedPageKeys.length === PageCount;
    // 全选状态下，传空数组过去
    setShareLinkNodeIds(linkID, isAll ? [] : checkedKeys);
    onSelectProjectPage(checkedKeys, checkedPageKeys);
  };

  get isMobile() {
    return ['phone', 'pad', 'watch', 'vehicle'].includes(this.props.app.appType);
  }

  get isEditSharePage() {
    const { roleInTeam } = this.props;
    return roleInTeam && MemberPermission.canEditShareProjectPage(roleInTeam);
  }

  /**
   * 分享链接
   */
  get shareUrl() {
    // 短链接  ，  启用密码 恢复长连接
    const { currentPageID } = this.props;
    const { shortLinkID } = this.state;
    return `${window.location.protocol}//${window.location.host}/rps/${shortLinkID}/${currentPageID}`;
  }

  get shareLongUrl() {
    const { runUrl, shareRunID, currentPageID } = this.props;
    const { shareOpt, isEnablePass } = this.state;
    const urlWithPass = isEnablePass
      ? `${window.location.protocol}//${window.location.host}${router.routePrefix}/view/${shareRunID}/${currentPageID}`
      : '';
    let urlNoPass = !isEnablePass ? runUrl : '';
    const url = urlWithPass ? urlWithPass : urlNoPass;
    if (!this.isMobile) {
      delete shareOpt.mobileType;
    }
    // 配合 cc 允许成员分享，分享弹窗里的链接不受限制
    const searchStr = shareOptionToSearchStr(shareOpt!, ['appID', 'as']);
    return `${url}?${searchStr}`;
  }

  get pageNodeCount() {
    const { nodes, checkedPageKeys } = this.state;
    if (!checkedPageKeys.length) {
      return '...';
    }
    if (nodes) {
      const PageCount = getGroupPageNum(nodes).length;
      if (PageCount === checkedPageKeys.length) {
        return i18n('example.type.all');
      }
      return checkedPageKeys.length + '/' + PageCount;
    }
    return;
  }

  changePasswordEnable = (checked: boolean) => {
    const { changePwEnable, app, setShortLinkProps } = this.props;
    const { shortLinkID } = this.state;

    this.setState(
      {
        isEnablePass: checked,
      },
      () => {
        shortLinkID && setShortLinkProps(shortLinkID, this.shareLongUrl);
      },
    );

    changePwEnable(app._id, checked);
  };

  renderSelectPage() {
    const { checkedKeys, checkedPageKeys, nodes, expandedKeys } = this.state;
    return (
      <PageTreeDialog
        ref={this.pageTreeRef}
        defaultExpandLevel={-1}
        allCheckedOption
        dialogTitle={i18n('preview.shareRunTitile')}
        dialogTip={i18n('preview.sharePageTip')}
        dialogType={DialogIsOfflineDemo}
        dialogCloseButtonText={i18n('general.back')}
        nodeIDs={nodes ? nodes : []}
        onClose={() => this.handleSelectPage(false)}
        onSubmit={this.onSubmit}
        checkedKeys={checkedKeys}
        checkedPageKeys={checkedPageKeys}
        expandedKeys={expandedKeys}
      ></PageTreeDialog>
    );
  }

  renderShare() {
    const { app, sharePassword, closeShareRun, previewNeedPassword, waiting } = this.props;
    const { password, isInputError, shareOpt, isShowPassword } = this.state;

    const couldShare = !!this.shareUrl && !!(this.isEnablePassChecked ? password : true);
    return (
      <Dialog
        title={i18n('preview.shareRunTitile')}
        titleClassName="share-dialog-title"
        contentClassName="share-dialog-content"
        backFade
        onClose={closeShareRun}
      >
        <div className="share-input-box">
          {waiting && (
            <div className="waiting-load-link">
              <img src={WaitingImg} />
              <label>{i18n('general.loading')}</label>
            </div>
          )}
          <Input disabled width={310} maxLength={280} value={waiting ? '' : this.shareUrl} />
          {this.isEditSharePage && (
            <Icon
              className="url-reset"
              tips={previewNeedPassword ? i18n('share.availableResetLink') : i18n('share.resetLink')}
              cls="icon_refresh"
              size={14}
              onClick={this.handleReset}
              disabled={previewNeedPassword}
            />
          )}
          <Button className="button" width={100} disabled={!couldShare || waiting} onClick={this.handleCopy}>
            {i18n('preview.copy')}
          </Button>
        </div>
        {/* <p className="share-tip">{i18n('tips.shareSetting')}</p> */}
        {/* 项目页面设置 */}
        {!this.isRefererCC && (
          <div className={classNames('share-select', { 'has-mobile-type': this.isMobile })}>
            <div className="page-select">
              <Label>{i18n('application.projectPage')}</Label>
              <Button
                className={classNames('share-page-select-button', { disabled: !this.isEditSharePage })}
                theme="onlyText"
                onClick={() => this.handleSelectPage(true)}
                activated
              >
                {this.pageNodeCount}
              </Button>
            </div>
          </div>
        )}

        <PreviewCommonOptions
          appType={app.appType}
          shareOpt={shareOpt}
          onChange={this.handleShareOptionChange}
          filterKeys={['autoScreen']}
        />

        <div className="password-wrapper">
          <CheckBox
            checked={this.isEnablePassChecked}
            disabled={!this.canOperatePass}
            text={i18n('preview.enablePass')}
            theme="light"
            onChange={(checked) => {
              this.changePasswordEnable(checked);
            }}
          />
          {this.canOperatePass && this.isEnablePassChecked && (
            <>
              <Input
                type={isShowPassword ? 'text' : 'password'}
                width={160}
                placeholder={i18n('preview.inputPass')}
                maxLength={8}
                error={isInputError}
                value={password}
                onSubmit={this.handleSetShareLinkPass}
                onBlur={this.handleSetShareLinkPass}
                onInput={this.handleShareLinkPassInput}
              />
              <Icon cls={isShowPassword ? 'icon_see_view' : 'icon_see_hide'} onClick={this.handleShowPasswordChange} />
            </>
          )}
          {!this.canOperatePass && previewNeedPassword && <span className="password-value">{sharePassword}</span>}
        </div>
      </Dialog>
    );
  }

  render() {
    const { isShowPageSelect } = this.state;
    return (
      <>
        <div ref={this.pageTreeWrapRef}>{isShowPageSelect && this.renderSelectPage()}</div>
        {!isShowPageSelect && this.renderShare()}
      </>
    );
  }
}

const mapStateToProps = (state: IMainState) => {
  const { share } = state;
  return {
    teamID: share.teamInfo?.id,
    roleInTeam: share.teamInfo?.roleInTeam as Role | undefined,

    userID: share.userInfo?.id,
    userName: share.userInfo?.name,

    previewNeedPassword: share.previewNeedPassword,

    shareRunID: share.shareRunID,
    sharePassword: share.sharePassword,
    isLoadLink: share.isLoadLink,
    waiting: share.waiting,
    linkID: share.linkID,
    nextLinkID: share.nextLinkID,
    shortLinkID: share.shortLinkID,
  };
};

const mapDispatchToProps = (dispatch: Function): IDispatcherProps => {
  return {
    getUserInfo: () => {
      dispatch(Actions.ShareThunkActions.getUserInfo());
    },
    getShareRunLink: (appID: string) => {
      dispatch(Actions.ShareThunkActions.getShareRunLink(appID));
    },
    setShareLinkPass: (appID: string, password: string) => {
      dispatch(Actions.ShareThunkActions.setShareLinkPass(appID, password));
    },
    getPwEnable: (shareID: string, prototypeID?: string) => {
      dispatch(Actions.ShareThunkActions.getPwEnable(shareID, prototypeID));
    },
    changePwEnable: (appID: string, enabled: boolean) => {
      dispatch(Actions.ShareThunkActions.changePwEnable(appID, enabled));
    },
    resetLink: (appID: string, shareID?: string, pageID?: string, moduleID?: string, idocAppID?: string) => {
      dispatch(Actions.ShareThunkActions.resetLink(appID, shareID, pageID, moduleID, idocAppID));
    },
    setShareLinkNodeIds: (linkID: string, nodes: string[]) => {
      dispatch(Actions.ShareThunkActions.setShareLinkNodeIds(linkID, nodes));
    },
    setShortLinkProps: (shortLinkID: string, url: string) => {
      dispatch(Actions.ShareThunkActions.setShortLinkProps(shortLinkID, url));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareRunDialog);
