import * as React from 'react';

import classnames from 'classnames';

import { transBlankChart } from '@utils/textUtils';

import { IRange } from '@fbs/rp/models/properties/base';
import { FontBoxScale } from '@/consts/fonts';
import { UIContainerComponent } from '@editor/comps';
import { StyleHelper } from '@helpers/styleHelper';
import { hasInteraction } from '@helpers/interactionHelper';

import { DEFAULT_INDICATOR_WIDTH } from './config';
import { IListCompositeProps } from '../../types';
import { getListEvents } from '../helps';

import './index.scss';
import '../general.scss';

const Tabs: React.FC<IListCompositeProps> = (props: IListCompositeProps) => {
  const { comp, itemEvents, isPreview, showInteract } = props;
  const container = comp as UIContainerComponent;
  const {
    size,
    properties: { separator, separatorRatio },
    opacity,
    components,
  } = container;
  const showSeparator = separator ? !separator.disabled : false;
  const len = components.length;

  const parser = StyleHelper.initCSSStyleParser(comp.properties);
  const style = {
    ...size,
    ...parser.getShadowStyle(),
    ...parser.getStrokeStyle(),
    ...parser.getRadiusStyle(size),
    opacity: StyleHelper.getOpacity(opacity),
  };

  const separatorStyle = !showSeparator
    ? {}
    : {
        ...StyleHelper.parserSeparator('right', separator),
        width: 0,
        height: `${separatorRatio ? (separatorRatio.value as IRange).value : 100}%`,
      };
  const events = isPreview ? getListEvents(components, itemEvents) : {};
  return (
    <div className={classnames('lib-comp-tabs', { preview: isPreview })} style={style}>
      {components.map((comp, i) => {
        const _hasInteraction = hasInteraction(comp);
        const needShowInteraction = !isPreview && _hasInteraction && false !== showInteract;
        const item = comp as UIContainerComponent;
        const rect = item.getComponentByAlias('indicator') || item.components[1];
        const text = item.getComponentByAlias('text') || item.components[0];
        const {
          properties: { fill, stroke },
          size,
        } = item;
        const {
          properties: { textStyle },
          position,
        } = text!;
        const itemParser = StyleHelper.initCSSStyleParser({ fill, stroke, textStyle });
        const { style } = itemParser.getTextStyleData(text!.size, textStyle);
        const itemStyle = {
          ...size,
          ...itemParser.getFillStyle(),
          opacity: StyleHelper.getOpacity(comp.opacity),
        };
        const boxStyle = {
          maxWidth: size.width / FontBoxScale,
          height: size.height / FontBoxScale,
        };
        const textStyles = {
          ...style,
          top: position.y / FontBoxScale,
          opacity: StyleHelper.getOpacity(text?.opacity),
        };
        let selectorStyle: React.CSSProperties = {};
        if (rect) {
          const parser = StyleHelper.createCSSStyleParser(rect.properties);
          const selectorSize = rect.size;
          selectorStyle = {
            top: rect.position.y / FontBoxScale,
            ...selectorSize,
            ...parser.getFillStyle(),
            ...parser.getRadiusStyle(selectorSize),
            ...parser.getStrokeStyle(),
            opacity: StyleHelper.getOpacity(rect.opacity),
          };
          // 标识条依然为默认值，认定标识条宽度未修改，维持标识条宽度等于文本宽度
          if (selectorSize.width === DEFAULT_INDICATOR_WIDTH) {
            selectorStyle.width = '50%';
          }
        }

        return (
          <div
            className={classnames('lib-comp-tabs-item', {
              'item-interaction-flag': needShowInteraction,
              'item-hot-area': isPreview && _hasInteraction,
              'component-cursor-pointer': isPreview,
            })}
            key={comp.id}
            style={itemStyle}
            {...(events[comp.id] || {})}
          >
            <div className="lib-comp-tabs-item-box" style={boxStyle}>
              <label className="lib-comp-tabs-item-value" style={textStyles}>
                {transBlankChart(`${text?.value}`)}
              </label>
              <div className="lib-comp-tabs-selected-indicator" style={selectorStyle} />
            </div>
            {showSeparator && i < len - 1 && <div className="lib-comp-tabs-separator" style={separatorStyle} />}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
