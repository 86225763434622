import { IScaleCommandParams } from '@fbs/rp/models/interactions';
import { Ops } from '@fbs/rp/utils/patch';
import { IPosition } from '@fbs/common/models/common';
import { EventTypes } from '@fbs/rp/models/event';

import CommandBase from './CommandBase';

export default class ScaleCommand extends CommandBase {
  public get afterEvent() {
    return EventTypes.afterZoom;
  }
  cashOriginParams() {
    const {
      target: { _scale, position },
    } = this.command;
    this.saveCacheOriginParams('scale', { scale: _scale, position });
  }

  private initOperation = ({ scale, position }: { scale: { x: number; y: number }; position: IPosition }) => {
    const { id, ownerArtboardID } = this.command.target;
    return {
      [ownerArtboardID]: {
        [id]: [
          Ops.replace('/_scale', scale),
          Ops.replace(this.getCurrentPath('position'), position),
          this.initAnimationOperation(),
        ],
      },
    };
  };

  private getTargetScale = (revert: boolean) => {
    const { fromCurrent, x, y, transformOrigin } = this.command.params as IScaleCommandParams;
    if (!fromCurrent && revert) {
      return this.getCacheOriginParams('scale');
    }
    const { size, position, _scale: originScale } = this.command.target;
    let scaleX = fromCurrent ? (originScale?.x ?? 1) * x : x;
    let scaleY = fromCurrent ? (originScale?.y ?? 1) * y : y;

    scaleX = revert ? (originScale?.x ?? 1) / x : scaleX;
    scaleY = revert ? (originScale?.y ?? 1) / y : scaleY;

    const w = size.width * scaleX;
    const h = size.height * scaleY;
    let { x: left, y: top } = position;
    const offsetWidth = w - size.width * originScale.x;
    const offsetHeight = h - size.height * originScale.y;
    switch (transformOrigin) {
      case 'center':
      case 'top':
      case 'bottom':
        left -= offsetWidth / 2;
        break;
      case 'right':
      case 'topRight':
      case 'bottomRight':
        left -= offsetWidth;
        break;
      default:
        break;
    }
    switch (transformOrigin) {
      case 'center':
      case 'left':
      case 'right':
        top -= offsetHeight / 2;
        break;
      case 'bottom':
      case 'bottomLeft':
      case 'bottomRight':
        top -= offsetHeight;
        break;
      default:
        break;
    }

    return {
      scale: {
        x: scaleX,
        y: scaleY,
      },
      position: {
        x: left,
        y: top,
      },
    };
  };

  run = () => {
    this.patch(this.initOperation(this.getTargetScale(false)));
    this.fulfilled = true;
  };

  revert = () => {
    this.patch(this.initOperation(this.getTargetScale(true)));
    this.fulfilled = true;
  };
}
