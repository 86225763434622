import { ILibF } from '@fbs/rp/models/ds/lib';

import ds from '../ds/index';
import * as lib from '../ds/lib';
// import { getOfflineDemoData } from './data';

import { getOfflineLibs, getOfflineUsedLibs } from './helper';

/**
 * 获取项目内所有的设计资源库，包含别人共享的库(离线演示包)
 */
async function getLibs(): Promise<ILibF[]> {
  return getOfflineLibs();

  // return new Promise((resolve, reject) => {
  //   const libInfo = getOfflineDemoData()?.libs;
  //   if (libInfo) {
  //     resolve(libInfo as ILibF[]);
  //   } else {
  //     reject();
  //   }
  // });
}

async function getUsedLibs(ids: string[]) {
  return getOfflineUsedLibs(ids);
}

export default {
  ...ds,
  lib: {
    ...lib,
    getLibs,
    getUsedLibs,
  },
};
