import PropertyBase from './base';

export enum InputModel {
  normal = 'normal',
  numeric = 'numeric',
  password = 'password'
}

export default interface IInputModel extends PropertyBase {
  value: InputModel;
}

export const InputModelPropertyName = 'inputModel';