import { UIComponent, UIContainerComponent } from '@editor/comps';
import { AddChildrenOperation, Ops, PagePatches } from '@fbs/rp/utils/patch';
import { MoveDelta } from '@/fbs/common/models/resize';
import { ComponentChange, ComponentChangeType } from '../comps/resizeHelper';
import { tansPointInArtBoardToGroup } from '@/helpers/componentHelper';
import { round } from '@utils/globalUtils';
import { ArtboardPatchesClass } from '@editor/patches/artboardPatches';

/**
 * 将需要删除的组件，转为 undo 时的操作数组
 * @param group
 * @param components
 */
export function convertRemovedComponentsToAddOps(
  group: UIContainerComponent,
  components: UIComponent[],
): AddChildrenOperation[] {
  return components.map((comp) => {
    return Ops.addChildren(`${group.components.findIndex((c) => c === comp)}`, [comp.toJSON()]);
  });
}

export function getFinalPositionWhenMove(comps: UIComponent[], delta: MoveDelta, needRoundedPosition?: boolean) {
  if (!comps || !comps.length) {
    return [];
  }
  const parent = comps[0].parent;
  const parentCoordinatesInArtboard = parent!.getBoxPointsInArtboard()[0];

  const futureCompsData: ComponentChange[] = comps.map((comp) => {
    // 1.先将comp的顶点转化为相对与画板的
    // 2.在左上角顶点（画板坐标系）+moveDelata（画板下的）
    // 3.在将画板下的新顶点转化为当前组坐标系下面的
    // 4.根据新的顶点计算当前的新组在未旋转时的position是多少
    const originNWPointInParent = comp.getBoxPointsInParent().shift()!;
    const originNWPointInArtBoard = comp.getBoxPointsInArtboard().shift()!;
    const newNWPositionInArtBoard = {
      x: originNWPointInArtBoard.x + delta.x,
      y: originNWPointInArtBoard.y + delta.y,
    };

    const newNWPointInGroup = tansPointInArtBoardToGroup(
      [newNWPositionInArtBoard],
      comp.parent!,
      parentCoordinatesInArtboard,
    ).shift()!;
    const deltaInGroup = {
      x: newNWPointInGroup.x - originNWPointInParent.x,
      y: newNWPointInGroup.y - originNWPointInParent.y,
    };
    const movedPositionX = needRoundedPosition
      ? round(comp.position.x + deltaInGroup.x)
      : comp.position.x + deltaInGroup.x;
    const movedPositionY = needRoundedPosition
      ? round(comp.position.y + deltaInGroup.y)
      : comp.position.y + deltaInGroup.y;
    const finalPosition = {
      x: comp.isLayoutCenterAtHorizontal ? comp.position.x : movedPositionX,
      y: comp.isLayoutMiddleAtVertical ? comp.position.y : movedPositionY,
    };
    return {
      id: comp.id,
      type: ComponentChangeType.Edit,
      position: finalPosition,
      size: comp.size,
      rotate: comp.rotate,
    };
  });
  return futureCompsData;
}

export function getIsRemovingCurrentActiveContainer(pagePatches: PagePatches, id: string) {
  return Object.values(pagePatches)
    .map((artboardPatches) => new ArtboardPatchesClass(artboardPatches))
    .some((artboardPatches) => {
      return artboardPatches.findOperationSuit((op) => op.op === 'remove-children' && op.value.includes(id));
    });
}

/**
 * 组件重新渲染，更新视图
 * @param comps
 * @param onlyBounds
 */
export function reRenderComps(comps: UIComponent[], onlyBounds?: boolean, includeChild?: boolean) {
  comps.forEach((comp) => {
    includeChild ? comp.updateSelfAndChildrenVision() : comp.updateVision();
    if (comp.updateComponentView) {
      comp.updateComponentView(onlyBounds);
    }
  });
}
