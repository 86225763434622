import { IComponentData } from '@/fbs/rp/models/component';
import { IChartValue } from '@/fbs/rp/models/chart';
import { makeCommonComponent } from '@/libs/helper';

import { IComponentItem } from '../../../types';
import { CPieChart } from '../../../constants';
import { getChartConfig, makeChartData, getSeriesChartTwoDefaultData } from '../common';

export const PieChartConfig: IComponentItem = getChartConfig(CPieChart);

export function makePieChart(id: string): IComponentData {
  return makeChartData(id, CPieChart);
}

export function makeAIPieChart(id: string, value: IChartValue) {
  return makeCommonComponent(id, CPieChart, {
    ...getSeriesChartTwoDefaultData(CPieChart),
    size: {
      width: 400,
      height: 250,
    },
    lib: {
      id: 'chart',
      type: CPieChart,
    },
    value: value,
  });
}
