import * as echarts from 'echarts';

import { UIComponent } from '@/editor/comps';
import { ICompInfo } from '../type';

import MapChart from '@/libs/ChartComponents/MapChart';

export const getMapChartDom = async (data: string) => {
  const dom = document.createElement('div');
  const chart = echarts.init(dom, undefined, {
    renderer: 'svg',
  });
  const comp = new UIComponent(JSON.parse(data));
  const chartInstance = new MapChart({ comp });
  await chartInstance.renderExportChart({ comp, isPreview: false }, chart);
  return dom.innerHTML;
};

const reg = /map-chart-data=(.*?)__end/g;

export const replaceRealChartDom = async (html: string, compInfo?: ICompInfo): Promise<string> => {
  if (!compInfo) {
    return html;
  }
  const list: Promise<string>[] = [];
  html.replace(reg, (_str, b) => {
    list.push(getMapChartDom(b));
    return '';
  });
  const replaces = await Promise.all(list);
  return html.replace(reg, () => replaces.shift()!);
};
