import * as React from 'react';
import { IShellBonds } from '../util/shellUtil';
import { IReturnOption } from '@/helpers/shellHelper';
export const renderDeviceShell = (bounds: IShellBonds, shellOption: IReturnOption) => {
  const { shellImage } = shellOption;
  const { width, height, otherHeight, y } = bounds;
  const headHeight = y;
  const bodyHeight = otherHeight ?? 100;
  const footHeigth = height - bodyHeight - headHeight;

  const useSvg = false;

  if (!useSvg) {
    return (
      <>
        <img className="shell-img-top" alt="img" src={shellImage[0]} width={width} />
        <img className="shell-img-body" alt="img" src={shellImage[1]} width={width} height={bodyHeight} />
        <img className="shell-img-bottom" alt="img" src={shellImage[2]} width={width} />
      </>
    );
  }

  // 以下 svg pattern存在问题，暂不使用
  return (
    <svg width={width} height={height}>
      <defs>
        <pattern id="p1" patternUnits="userSpaceOnUse" width={width} height={headHeight}>
          <image
            x="0"
            y="1"
            width={width}
            height={headHeight}
            xlinkHref={shellImage[0]}
            preserveAspectRatio="none"
            vectorEffect="non-scaling-stroke"
          />
        </pattern>
        <pattern id="p2" patternUnits="userSpaceOnUse" width={width} height={bodyHeight}>
          <image
            x="0"
            y="0"
            width={width}
            height={bodyHeight}
            xlinkHref={shellImage[1]}
            preserveAspectRatio="none"
            vectorEffect="non-scaling-stroke"
          />
        </pattern>
        <pattern id="p3" patternUnits="userSpaceOnUse" width={width} height={footHeigth}>
          <image
            x="0"
            y="0"
            width={width}
            height={footHeigth}
            xlinkHref={shellImage[2]}
            preserveAspectRatio="none"
            vectorEffect="non-scaling-stroke"
          />
        </pattern>
      </defs>
      <rect width={width} height={headHeight} x="0" y="0" stroke="none" fill="url(#p1)" />
      <rect width={width} height={bodyHeight} x="0" y={headHeight} stroke="none" fill="url(#p2)" />
      <rect width={width} height={footHeigth} x="0" y={bodyHeight + headHeight} stroke="none" fill="url(#p3)" />
    </svg>
  );
};

/**
 * 手机壳中间部分,竖屏自适应
 * @param bounds
 * @param shellOption
 */
export const renderDevicePortraitShellBody = (bounds: IShellBonds, shellOption: IReturnOption) => {
  const { shellImage } = shellOption;
  const { width, otherHeight } = bounds;
  const bodyHeight = otherHeight ?? 400;
  const offset = 100 - bodyHeight;

  return (
    <svg width={width} height="100%">
      <defs>
        <symbol id="bodyImg">
          <image
            x="0"
            y="0"
            width={width}
            height={bodyHeight}
            xlinkHref={shellImage[1]}
            preserveAspectRatio="none"
            vectorEffect="non-scaling-stroke"
          />
        </symbol>

        <pattern id="p1" patternUnits="userSpaceOnUse" width={width} height={bodyHeight}>
          <use xlinkHref="#bodyImg"></use>
        </pattern>
        <pattern id="p2" patternUnits="userSpaceOnUse" width={width} height={bodyHeight + offset}>
          <use xlinkHref="#bodyImg" y={offset}></use>
        </pattern>
      </defs>
      <rect width={width} height={bodyHeight} x="0" y={0} stroke="none" fill="url(#p1)" />
      <rect width={width} height="100%" x="0" y={bodyHeight - 1} stroke="none" fill="url(#p2)" />
    </svg>
  );
};

/**
 * 手机壳中间部分,横屏自适应
 * @param bounds
 * @param shellOption
 */
export const renderDeviceLandscapeShellBody = (bounds: IShellBonds, shellOption: IReturnOption) => {
  const { shellImage } = shellOption;
  const { otherWidth, height } = bounds;
  const bodyWidth = otherWidth ?? 400;
  const offset = 100 - bodyWidth;

  return (
    <svg width="100%" height={height}>
      <defs>
        <symbol id="bodyImg">
          <image
            x="0"
            y="0"
            width={bodyWidth}
            height={height}
            xlinkHref={shellImage[1]}
            preserveAspectRatio="none"
            vectorEffect="non-scaling-stroke"
          />
        </symbol>

        <pattern id="p1" patternUnits="userSpaceOnUse" width={bodyWidth} height={height}>
          <use xlinkHref="#bodyImg"></use>
        </pattern>
        <pattern id="p2" patternUnits="userSpaceOnUse" width={bodyWidth + offset} height={height}>
          <use xlinkHref="#bodyImg" x={offset}></use>
        </pattern>
      </defs>
      <rect width={bodyWidth} height={height} x="0" y={0} stroke="none" fill="url(#p1)" />
      <rect width="100%" height={height} y="0" x={bodyWidth - 1} stroke="none" fill="url(#p2)" />
    </svg>
  );
};
