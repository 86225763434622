import {
  ILegendPosition,
  IChartDataLabel,
  IChartAxis,
  ChartLegendPositionPropertyName,
  ChartDataLabelPropertyName,
  ChartAxisPropertyName,
  ChartSmoothPropertyName,
} from '@/fbs/rp/models/properties/chart';
import { isUndefined, isString, get, set } from 'lodash';
import * as tinycolor from 'tinycolor2';

import { arrayEquals, depthClone, isEqualDate, merge } from '@utils/globalUtils';
import {
  measureTextSize,
  getManyRichStyleByRichTextDom,
  getTextFormatByRichTextDom,
  getListTypeByRichTextDom,
  transformTextToRichTextDom,
} from '@utils/textUtils';
import { parseColorToString } from '@utils/graphicsUtils';
import { getThirdPartPlatformByUrl } from '@/utils/videoUtils';

import { DefaultPadding } from '@/consts/padding';
import { DefaultRadius } from '@consts/radius';
import { DefaultBorder } from '@/consts/border';
import { DefaultFontSize } from '@consts/fonts';
import { DefaultNoteConnectedLine } from '@/consts/noteConnectedLine';
import { PropPlaceHolder, PropPlaceHolderStyle, PropIconColor } from '@fbs/rp/models/properties/base';
import { FillPropertyName } from '@fbs/rp/models/properties/fill';
import {
  default as IStroke,
  SeparatorPropertyName,
  StrokePropertyName,
  PresetDashModel,
} from '@fbs/rp/models/properties/stroke';
import { RadiusPropertyName } from '@fbs/rp/models/properties/radius';
import { ShadowPropertyName } from '@fbs/rp/models/properties/shadow';
import { default as ITextFormat, TextAlign, TextPropertyName } from '@fbs/rp/models/properties/text';
import { default as IIcon, IconPropertyName } from '@fbs/rp/models/properties/icon';
import { default as IMultiText, MultiTextPropertyName } from '@fbs/rp/models/properties/multiText';
import { ImgPropertyName } from '@fbs/rp/models/properties/img';
import { LayoutPropertyName } from '@fbs/rp/models/properties/layout';
import { ContainerPropertyName } from '@fbs/rp/models/properties/container';
import { default as ILine, LinePropertyName } from '@fbs/rp/models/properties/line';
import {
  ColorFilterPropertyName,
  default as IColorFilter,
  FilterName,
  IFilter,
} from '@fbs/rp/models/properties/colorFilter';
import { PolygonPropertyName } from '@fbs/rp/models/properties/polygon';
import { BorderPropertyName } from '@fbs/rp/models/properties/border';
import { InputModelPropertyName } from '@fbs/rp/models/properties/inputModel';
import { PaddingPropertyName } from '@fbs/rp/models/properties/padding';
import { default as ITextFormatEx, TextFormatExPropertyName } from '@fbs/rp/models/properties/textFormat';
import { IProperties, PropertyName, PropertyValue } from '@fbs/rp/models/property';
import { IComponentData } from '@fbs/rp/models/component';
import IBlur, { BlurPropertyName } from '@fbs/rp/models/properties/blur';
import { IPathValue, IconValue } from '@fbs/rp/models/value';
import { IFragmentActionParams, IFragmentAction, FragmentAnimationEffects } from '@/fbs/rp/models/interactions';
import {
  CText,
  CConnector,
  CIcon,
  CLine,
  CPath,
  CArtboard,
  CButton,
  CTextArea,
  CImage,
  CCanvasPanel,
  CContentPanel,
  CContentPanelV2,
  CList,
  CNumericStep,
  CTree,
  CNavigationMenu,
  CVerticalMenu,
  CSelect,
  CMultipleSelect,
  CVideo,
  CStickNote,
  CSelectTab,
  CScatterChart,
  CRadarChart,
  CAreaChart,
  CDoughnutChart,
  CPieChart,
  CLineChart,
  CBarChartHorizontal,
  CBarChart,
} from '@libs/constants';
import BasicComponentLib from '@/libs/basic';
import i18n from '@i18n';
import { UIComponent } from '@editor/comps';

import { setValueWithFontStyle } from './componentHelper';
import { StyleHelper } from './styleHelper';
import { resetIconValue } from './iconHelper';
import { makeComponent } from '@/libs/libs';

export const PropertyOrder: { [key: string]: number } = [
  'opacity',
  TextPropertyName,
  TextFormatExPropertyName,
  MultiTextPropertyName,
  FillPropertyName,
  IconPropertyName,
  StrokePropertyName,
  BorderPropertyName,
  RadiusPropertyName,
  ShadowPropertyName,
  PaddingPropertyName,
  InputModelPropertyName,
  PolygonPropertyName,
  ImgPropertyName,
  ColorFilterPropertyName,
  BlurPropertyName,
  LinePropertyName,
  LayoutPropertyName,
  ContainerPropertyName,
  PropIconColor,
  PropPlaceHolder,
  PropPlaceHolderStyle,
].reduce((acc, curr, i) => {
  return {
    ...acc,
    [curr]: i,
  };
}, {});

export function sortProperty(propertyNames: { name: string; type: string }[]) {
  propertyNames.sort((a: { name: string; type: string }, b: { name: string; type: string }) => {
    // 不处于排序列表内的属性，按照组件property对象key值顺序往后展示
    return (PropertyOrder[a.name] || Number.MAX_SAFE_INTEGER) - (PropertyOrder[b.name] || Number.MAX_SAFE_INTEGER);
  });
}

const defaultPropertyName: { [type: string]: string } = {
  [FillPropertyName]: i18n('property.propertyNames.fill'),
  [StrokePropertyName]: i18n('property.propertyNames.stroke'),
  [BorderPropertyName]: i18n('property.propertyNames.border'),
  [RadiusPropertyName]: i18n('property.propertyNames.radius'),
  [TextPropertyName]: i18n('property.propertyNames.text'),
  [PolygonPropertyName]: i18n('property.propertyNames.polygon'),
  [IconPropertyName]: i18n('property.propertyNames.color'),
  [ImgPropertyName]: i18n('property.propertyNames.img'),
  [LayoutPropertyName]: i18n('property.propertyNames.layout'),
  [ContainerPropertyName]: i18n('property.propertyNames.container'),
  [ShadowPropertyName]: i18n('property.propertyNames.shadow'),
  [LinePropertyName]: i18n('property.propertyNames.line'),
  [InputModelPropertyName]: i18n('property.propertyNames.inputModel'),
  [PaddingPropertyName]: i18n('property.propertyNames.padding'),
  [SeparatorPropertyName]: i18n('property.propertyNames.separator'),
  color: i18n('property.propertyNames.color'),
};

export const shapeProps = [
  'opacity',
  FillPropertyName,
  StrokePropertyName,
  ShadowPropertyName,
  RadiusPropertyName,
  BorderPropertyName,
];
export const textProps = [TextPropertyName, TextFormatExPropertyName, MultiTextPropertyName];

export function getDefaultPropertyName(type: PropertyName): string {
  return defaultPropertyName[type] || type;
}

/**
 * 判断两个属性是否相等
 * @param {PropertyValue} p1
 * @param {PropertyValue} p2
 * @returns {boolean}
 */
export function compareProperty(p1: PropertyValue, p2: PropertyValue): boolean {
  const keys1 = Object.keys(p1);
  const keys2 = Object.keys(p2);

  if (!arrayEquals(keys1, keys2)) {
    return false;
  }

  type keyType = keyof PropertyValue;
  return keys1.every((key) => {
    const t = key as keyType;
    if (t === 'ref' || t === 'prop' || t === 'name' || t === 'hidden') {
      return true;
    }
    const v1 = p1[t];
    const v2 = p2[t];
    const t1 = typeof v1;
    const t2 = typeof v1;
    if (t1 !== t2) {
      return false;
    }
    if (Array.isArray(v1) && Array.isArray(v2)) {
      return arrayEquals(v1, v2);
    } else if (t1 === 'object' && t2 === 'object') {
      return isEqualDate(v1, v2);
    } else {
      return v1 === v2;
    }
  });
}

export function isSamePropertyValue(v1: any, v2: any): boolean {
  const type = typeof v2;
  let oldValue = v1;
  if (type === 'object') {
    if (!oldValue) {
      return false;
    }
    return Object.keys(v2).every((k) => {
      return isSamePropertyValue(v1[k], v2[k]);
    });
  }
  switch (type) {
    case 'number':
      oldValue = oldValue || 0;
      break;
    case 'string':
      oldValue = oldValue || '';
      break;
    case 'boolean':
      oldValue = oldValue || false;
      break;
    default:
      return true;
  }
  return oldValue === v2;
}

interface ICompPropertyCache {
  [CText]?: {
    [MultiTextPropertyName]?: IMultiText;
    [TextPropertyName]?: ITextFormat;
    [TextFormatExPropertyName]: ITextFormatEx;
  };
  [CConnector]?: {
    [StrokePropertyName]?: IStroke;
  };
  [CIcon]?: {
    [IconPropertyName]?: IIcon;
  };
  [CLine]?: {
    [StrokePropertyName]?: IStroke;
    [LinePropertyName]?: ILine;
  };
  // [CRect]?:{
  //   [StrokePropertyName]?: IStroke,
  // },
  // [CEllipse]?:{
  //   [StrokePropertyName]?: IStroke,
  // },
  // [CPolygon]?:{
  //   [StrokePropertyName]?: IStroke,
  // },
  // [CPath]?:{
  //   [StrokePropertyName]?: IStroke,
  // }
}

class RecentPropertyCache {
  private cache: ICompPropertyCache = {};

  private support = {
    [CText]: [TextPropertyName, MultiTextPropertyName, TextFormatExPropertyName],
    [CConnector]: [StrokePropertyName],
    [CIcon]: [IconPropertyName],
    [CLine]: [StrokePropertyName, LinePropertyName],
    // [CRect]:[StrokePropertyName],
    // [CEllipse]:[StrokePropertyName],
    // [CPolygon]:[StrokePropertyName],
    // [CPath]:[StrokePropertyName],
  };

  constructor() {
    this.cache = {};
  }

  canCache(type: string, propertyName: string): boolean {
    // @ts-ignore
    const compCache = this.support[type];
    if (!compCache) {
      return false;
    }
    return compCache.indexOf(propertyName) !== -1;
  }

  get(type: string, propertyName: string): PropertyValue | undefined {
    // @ts-ignore
    return this.cache[type]?.[propertyName];
  }

  save(type: string, propertyName: string, value: PropertyValue) {
    if (!this.canCache(type, propertyName)) {
      return;
    }
    // @ts-ignore
    let compCache = this.cache[type];
    if (!compCache) {
      // @ts-ignore
      compCache = this.cache[type] = {};
    }
    // @ts-ignore
    compCache[propertyName] = depthClone(value);
    console.log(value);
  }
}

export const propertyCache = new RecentPropertyCache();

/**
 * 图标类型缓存管理
 */
class IconTypeCache {
  private _type?: string;
  set iconType(value: string) {
    this._type = value;
  }

  /**
   * 应用图标类型缓存
   * @param value
   */
  applyIconType2Value(value: IconValue): IconValue {
    if (!this._type || value.fontName === this._type) {
      return value;
    }
    return resetIconValue(value, this._type);
  }
}

export const iconTypeCache = new IconTypeCache();

export function mergePropertyCacheToComp(comp: IComponentData) {
  const { properties, type } = comp;
  const oldFontStyle = {
    ...(properties.textFormat ?? properties.textStyle)?.fontStyle,
  };

  type PropName = keyof IProperties;
  Object.keys(properties).forEach((k) => {
    const propName = k as PropName;
    const propItem = properties[propName]!;
    const itemName = propItem.prop || k;
    if (!propItem.hidden) {
      // @ts-ignore
      const cacheProp = propertyCache.get(type, itemName);
      if (cacheProp) {
        // 不可直接替换
        Object.keys(cacheProp).forEach((k) => {
          if (!['hidden', 'name', 'prop', 'icon-type'].includes(k)) {
            // @ts-ignore
            propItem[k] = cacheProp[k];
          }
        });
        const lineHeight = Math.round(((properties.textStyle || properties.textFormat)?.fontSize || 14) * 1.4);
        if (itemName === MultiTextPropertyName) {
          properties.multiText!.wrap = false;
          properties.multiText!.lineHeightEx = lineHeight;
        } else if (itemName === TextFormatExPropertyName) {
          properties.textFormat!.wrap = false;
          // properties.textFormat!.lineHeightEx = lineHeight;
          properties.textFormat!.lineHeightEx = undefined;
          properties.textFormat!.lineHeight = undefined;
          properties.textFormat!.listType = undefined;
        }
      }
    }
  });
  if (comp.type === CText) {
    const { textFormat, textStyle } = properties;
    const fontStyle = textFormat?.fontStyle || textStyle?.fontStyle;
    if (textFormat || textStyle) {
      comp.value = setValueWithFontStyle(comp.value, fontStyle, oldFontStyle);
      const parser = StyleHelper.initCSSStyleParser(properties);
      const style = { ...parser.getTextStyle() };
      const { width, height } = measureTextSize(style, comp.value, { isRich: true, wrap: false, isMultiText: true });
      comp.size.width = width;
      comp.size.height = height;
    }
  }
}

export function getEffectValue(key: keyof IColorFilter, value?: number) {
  if (isUndefined(value)) {
    return 0;
  }
  return value;
}

export interface IEffectData {
  name: string;
  value: number;
  min: number;
  max: number;
  step: number;
}

export function getEffectData(key: keyof IColorFilter, value?: number): IEffectData {
  const result = {
    name: i18n(`property.component.colorFilter.${key}`),
    value: getEffectValue(key, value),
    min: -100,
    max: 100,
    step: 1,
  };
  if (key === FilterName.invert) {
    result.min = 0;
  }
  return result;
}

/**
 * hueRotate >saturate >brightness >contrast >invert;
 * 色相， 饱和， 亮度， 对比， 反相
 * 模糊最后
 * 注：顺序不能换
 * @param {IColorFilter} colorFilter
 */
function sortFilter(keys: FilterName[]): FilterName[] {
  const sortList = {
    [FilterName.hueRotate]: 0,
    [FilterName.saturate]: 1,
    [FilterName.brightness]: 2,
    [FilterName.contrast]: 3,
    [FilterName.invert]: 4,
  };
  return keys.sort((a, b) => sortList[a] ?? 5 - sortList[b] ?? 5);
}

export function getImageColorFilter(colorFilter: IColorFilter | undefined, blur: IBlur | undefined) {
  let filterStr: string = '';
  if (colorFilter) {
    const { disabled } = colorFilter;
    if (!disabled) {
      const keys = sortFilter(Object.keys(colorFilter) as FilterName[]);
      const filters = keys.map((key) => {
        const k = key as keyof IFilter;
        return transEffectValueToCSSValue(k, colorFilter[k]!);
      });
      filterStr = filters.join(' ');
    }
  }
  if (blur && !blur.disabled && blur.blurValue.aMount) {
    filterStr = `${filterStr || ''} blur(${blur.blurValue.aMount}px)`;
  }
  return filterStr;
}

export function transEffectValueToCSSValue(key: keyof IColorFilter, value: number) {
  if (!value) {
    return '';
  }
  switch (key) {
    case FilterName.hueRotate:
      return `hue-rotate(${(value * 1.8 + 360) % 360}deg)`;
    case FilterName.saturate:
      return `${key}(${value + 100}%)`;
    case FilterName.brightness:
      return `${key}(${(value + 100) / 2 + 50}%)`;
    case FilterName.contrast:
      return `${key}(${(value + 100) / 2 + 50}%)`;
    case FilterName.invert:
      return `${key}(${value}%)`;
  }
  return '';
}

export function mergeProperties(source: IProperties, target: IProperties) {
  if (!target) {
    return source;
  }
  const result = depthClone(source);
  const keys = Object.keys(target).reduce((acc, curr) => {
    if (!acc.includes(curr)) {
      acc.push(curr);
    }
    return acc;
  }, Object.keys(source));

  keys.forEach((key) => {
    const propName = key as PropertyName;
    const sourceValue = result[propName];
    const targetValue = target[propName];
    if (!sourceValue) {
      result[propName] = targetValue;
    } else {
      if (targetValue) {
        result[propName] = merge(sourceValue, targetValue, (k, s, t) => {
          if (['colorStops', 'color', 'radius', 'dashArray'].includes(k)) {
            return t;
          }
        });
        if (!targetValue.ref && result[propName]) {
          delete result[propName]!.ref;
        }
      }
    }
  });

  return result;
}

function canDeleteFontFamily(families: string[], family: string): boolean {
  if (families.length === 1 && families[0]) {
    return !families[0].toLocaleLowerCase().includes(family.toLocaleLowerCase());
  }
  return canDeleteTextFormat(families, family);
}

function canDeleteTextFormat(values: string[], value?: string): boolean {
  // 本身就无值
  if (!value) {
    return false;
  }
  // 有复数不同值
  if (values.length > 1) {
    return true;
  }
  // 有复数与父组件相同值
  if (values.length === 1 && isEqualDate(values[0], value)) {
    return false;
  }

  // length === 1 values[0] === ''
  return false;
}

function canDeleteListType(richTextDom: HTMLElement, value?: 'order' | 'unOrder') {
  if (!value) {
    return false;
  }
  const tagName = value === 'order' ? 'OL' : 'UL';
  const childNodes = richTextDom.childNodes;
  if (childNodes.length === 1 && childNodes.item(0).nodeName === tagName) {
    return false;
  }
  return true;
}

/**
 * 将组件属性转换成富文本样式
 * @param textFormat 组件属性
 */
function getRichStyleByTextFormat(textFormat: ITextFormatEx) {
  const textDecoration = [];
  if (textFormat.fontStyle?.strike) {
    textDecoration.push('line-through');
  }
  if (textFormat.fontStyle?.underline) {
    textDecoration.push('underline');
  }
  return {
    fontFamily: textFormat.fontFamily,
    fontSize: (textFormat.fontSize || 14) + 'px',
    color: parseColorToString(textFormat.color || ''),
    textAlign: textFormat.textAlign as string,
    fontWeight: textFormat.fontStyle?.bold ? 'bold' : '',
    fontStyle: textFormat.fontStyle?.italic ? 'italic' : '',
    textDecorationLine: textDecoration.join(' '),
  };
}

/**
 * 通过内容查看是否需要修改数据 返回新数据
 * @param textFormat 原数据
 * @param text 文本内容
 */
export function upgradeTextFormatForApply(oldTextFormat: ITextFormatEx, text: string): ITextFormatEx {
  let textFormat = depthClone(oldTextFormat);

  const richTextDom = transformTextToRichTextDom(text);
  const newTextFormat = getTextFormatByRichTextDom(richTextDom, getRichStyleByTextFormat(textFormat));

  const { strike, bold, italic, underline } = newTextFormat.fontStyle || {};
  textFormat = {
    ...textFormat,
    ...newTextFormat,
    textAlign: textFormat.textAlign,
    fontStyle: {
      strike: strike ?? textFormat.fontStyle?.strike,
      bold: bold ?? textFormat.fontStyle?.bold,
      italic: italic ?? textFormat.fontStyle?.italic,
      underline: underline ?? textFormat.fontStyle?.underline,
    },
  };
  if (newTextFormat.textAlign) {
    textFormat.textAlign = newTextFormat.textAlign as TextAlign;
  }
  if (isString(textFormat.color)) {
    const tColor = tinycolor(textFormat.color);
    textFormat.color = { ...tColor.toRgb(), a: tColor.getAlpha() };
  }
  const listType = getListTypeByRichTextDom(richTextDom);
  if (listType) {
    textFormat.listType = listType;
  }

  return textFormat;
}

/**
 * 通过内容修改属性值:是否隐藏此属性
 * @param textFormat 原属性值
 * @param text 组件内容
 */
export function upgradeTextFormatForDisplay(
  oldTextFormat: ITextFormatEx,
  text: string,
  inheritClassNames: { [key: string]: boolean | string | number } = {},
): ITextFormatEx {
  // 逻辑里的 canDelete 就是 isMixed，判断是否为混合样式

  const textFormat = depthClone(oldTextFormat);
  const richTextDom = transformTextToRichTextDom(text);
  const cssStyle = getManyRichStyleByRichTextDom(richTextDom);
  // 如果富文本内部的样式有不同于组件样式的值，将清除组件样式
  const { fontFamily, fontSize, textAlign, listType, color } = textFormat;

  const canDeleteFamily = fontFamily && canDeleteFontFamily(cssStyle.fontFamily, fontFamily);
  if (canDeleteFamily && !inheritClassNames['global-family-inherit']) {
    delete textFormat.fontFamily;
  }

  const canDeleteFontSize = canDeleteTextFormat(cssStyle.fontSize, `${fontSize}px`);
  if (canDeleteFontSize && !inheritClassNames['global-size-inherit']) {
    delete textFormat.fontSize;
  }

  const canDeleteColor =
    color &&
    canDeleteTextFormat(
      cssStyle.color.map((c) => tinycolor(c).toHex8String()),
      tinycolor(color).toHex8String(),
    );
  if (canDeleteColor && !inheritClassNames['global-color-inherit']) {
    delete textFormat.color;
  }

  // fontStyle 不用处理混合的情况，直接用组件的属性值对应的样式即可

  const canDeleteTextAlign = canDeleteTextFormat(cssStyle.textAlign, textAlign);
  if (canDeleteTextAlign && !inheritClassNames['global-align-inherit']) {
    delete textFormat.textAlign;
  }

  if (canDeleteListType(richTextDom, listType)) {
    delete textFormat.listType;
  }

  return textFormat;
}

export function upgradeTextProperValue(value: Partial<ITextFormatEx> | undefined) {
  if (!value) {
    return value;
  }
  const { lineHeight, lineHeightEx, fontSize } = value;
  if (isUndefined(lineHeightEx) && !isUndefined(lineHeight)) {
    return { ...depthClone(value), lineHeightEx: lineHeight + (fontSize || DefaultFontSize) };
  }
  if (isUndefined(lineHeightEx) && isUndefined(lineHeight)) {
    return { ...depthClone(value), lineHeightEx: 1.4 * (fontSize || DefaultFontSize) };
  }
  return value;
}

export function getPublicPropertiesByComps(selectComps: Set<UIComponent>) {
  const result: { name: string; type: string; locked?: boolean | undefined }[] = [];
  const comps = [...selectComps];
  const len = comps.length;
  if (len) {
    const propsCount: { [name: string]: { count: number; type: string } } = {};
    const count: { [name: string]: { count: number; type: string } } = comps.reduce((acc, curr) => {
      const { properties, type, $data } = curr;
      const properNames = Object.keys(properties);
      properNames.forEach((propName) => {
        if (
          type === CVideo &&
          ['autoPlay', 'controls', 'loopPlay'].includes(propName) &&
          getThirdPartPlatformByUrl($data.value)
        ) {
          return;
        }

        let propData = properties[propName];
        if (!isUndefined(propData) && !propData) {
          propData = {};
        }
        if (propData) {
          if (!acc[propName]) {
            acc[propName] = { count: 1, type: propData.prop || propName };
          } else {
            acc[propName].count = acc[propName].count + 1;
          }
          if (propData.hidden) {
            acc[propName].count = acc[propName].count - 1;
          }
        }
      });
      return acc;
    }, propsCount);

    Object.keys(count).forEach((key) => {
      if (count[key].count === len) {
        result.push({ name: key, type: count[key].type });
      }
    });
  }
  // 多选去除‘layout’
  if (len > 1) {
    const index = result.findIndex((item) => {
      return item.type === 'layout';
    });
    if (index !== -1) {
      result.splice(index, 1);
    }
  }

  // 去除闭合路径的线头
  const hidenLine = comps.some((c) => c.type === CPath && (c.value as IPathValue).closed);
  if (hidenLine) {
    const index = result.findIndex((item) => {
      return item.type === 'line';
    });
    if (index !== -1) {
      result.splice(index, 1);
    }
  }

  sortProperty(result);
  result.unshift({ name: 'opacity', type: 'opacity' });
  return result;
}

// properties共有属性覆盖(根据允许属性)
export function getNewPropertiesByAllowProperties(
  oldProperties: IProperties,
  newProperties: IProperties,
  allowProperties: ReturnType<typeof getPublicPropertiesByComps>,
  isSameTypeComp: boolean,
): IProperties {
  const TEXT_FORMAT = 'textFormat';
  const TEXT_STYLE = 'textStyle';
  const result: IProperties = {};
  /*  FIXME 这一坨是因为被粘贴的组件中textFormat可能有以下属性，而复制的组件可能没有以下属性
      而这些属性都会改变组件的size，如果复制的组件中没有这些属性，会导致组件size发生错乱
      再者，textStyle中不需要以下属性，所以先定义出来，后边再赋值
  */
  const oldWrap = oldProperties.textFormat?.wrap;
  const newWrap = newProperties.textFormat?.wrap;
  const newIndent = newProperties.textFormat?.indent;
  const newVertical = newProperties.textFormat?.vertical;
  const newLetterSpace = newProperties.textFormat?.letterSpace;
  delete newProperties[TEXT_FORMAT]?.wrap;
  delete newProperties[TEXT_FORMAT]?.indent;
  delete newProperties[TEXT_FORMAT]?.vertical;
  delete newProperties[TEXT_FORMAT]?.letterSpace;
  for (let propertyName of allowProperties) {
    // eslint-disable-next-line no-prototype-builtins
    if (oldProperties.hasOwnProperty(propertyName.type) && newProperties.hasOwnProperty(propertyName.type)) {
      // FIXME 可能需要将粘贴组件所有允许的属性与复制组件的属性一一比较，删除不必要的复制组件属性
      // 相同类型组件过滤被粘贴组件上面复制组件不存在的属性
      if (isSameTypeComp) {
        const property: PropertyValue = oldProperties[propertyName.type]!;
        Object.keys(property).forEach((propertyItem) => {
          // eslint-disable-next-line no-prototype-builtins
          if (!newProperties[propertyName.type]!.hasOwnProperty(propertyItem)) {
            Reflect.deleteProperty(oldProperties[propertyName.type]!, propertyItem);
          }
        });
      }
      result[propertyName.type] = { ...oldProperties[propertyName.type], ...newProperties[propertyName.type] };
    }
  }
  // textFormat与textStyle需要单独处理
  if (Object.keys(oldProperties).includes(TEXT_FORMAT) && Object.keys(newProperties).includes(TEXT_FORMAT)) {
    result[TEXT_FORMAT] = {
      ...result[TEXT_FORMAT],
      wrap: (isSameTypeComp ? newWrap : oldWrap) ?? true,
      indent: newIndent ?? false,
      vertical: newVertical ?? false,
      letterSpace: newLetterSpace ?? 0,
    };
  }
  if (Object.keys(oldProperties).includes(TEXT_FORMAT) && Object.keys(newProperties).includes(TEXT_STYLE)) {
    result[TEXT_FORMAT] = {
      ...oldProperties.textFormat,
      ...newProperties.textStyle,
      wrap: oldWrap ?? true,
    };
  }
  if (Object.keys(oldProperties).includes(TEXT_STYLE) && Object.keys(newProperties).includes(TEXT_FORMAT)) {
    result[TEXT_STYLE] = { ...oldProperties.textStyle, ...newProperties.textFormat };
  }
  return result;
}

// 需要添加border显隐属性的组件
const ADD_BORDER_PROPERTY_COMPS = [
  CButton,
  CTextArea,
  CImage,
  CText,
  CCanvasPanel,
  CContentPanel,
  CContentPanelV2,
  CList,
  CNumericStep,
  CTree,
  CNavigationMenu,
  CVerticalMenu,
];

const NEED_ADAPT_CHARTS = [
  CBarChart,
  CBarChartHorizontal,
  CLineChart,
  CPieChart,
  CDoughnutChart,
  CAreaChart,
  CRadarChart,
  CScatterChart,
];

/**
 * 图表旧数据兼容
 * @param data
 */
function adaptChartProperty(data: IComponentData) {
  const properties = data.properties;
  const defaultProperties = BasicComponentLib.make(data.type).properties;

  // 折线图线条样式
  const isSmooth = properties.isSmooth;
  if (isSmooth && isSmooth?.prop !== ChartSmoothPropertyName) {
    properties.isSmooth = { ...defaultProperties.isSmooth, disabled: !isSmooth?.value, hidden: isSmooth?.hidden };
  }

  // 图例兼容
  const legendPosition = properties.legendPosition;
  if (legendPosition && legendPosition?.prop !== ChartLegendPositionPropertyName) {
    const newLegendPosition = { ...defaultProperties.legendPosition } as ILegendPosition;
    newLegendPosition.value = legendPosition.value;
    newLegendPosition.disabled = legendPosition.disabled;
    properties.legendPosition = newLegendPosition;
  }

  // 饼图legendPosition
  const pieLegendPosition = properties.pieLegendPosition as ILegendPosition;
  if (pieLegendPosition && pieLegendPosition?.prop !== ChartLegendPositionPropertyName) {
    const newLegendPosition = { ...defaultProperties.pieLegendPosition } as ILegendPosition;
    newLegendPosition.value = pieLegendPosition.value;
    newLegendPosition.disabled = pieLegendPosition.disabled;
    properties.pieLegendPosition = newLegendPosition;
  }

  // 标签单位
  const dataLabel = properties.dataLabel;
  if (dataLabel && dataLabel.prop !== ChartDataLabelPropertyName) {
    const newDataLabel = { ...defaultProperties.dataLabel } as IChartDataLabel;
    newDataLabel.disabled = !dataLabel.value;
    properties.dataLabel = newDataLabel;
  }

  // 饼图标签单位
  const pieDataLabel = properties.pieDataLabel as IChartDataLabel;
  if (pieDataLabel && pieDataLabel.prop !== ChartDataLabelPropertyName) {
    const newDataLabel = { ...defaultProperties.pieDataLabel } as IChartDataLabel;
    newDataLabel.value = pieDataLabel.value;
    newDataLabel.disabled = pieDataLabel.disabled;
    properties.pieDataLabel = newDataLabel;
  }

  // 坐标轴
  const axis = properties.axis;
  if (axis && axis?.prop !== ChartAxisPropertyName) {
    const newAxis = { ...defaultProperties.axis } as IChartAxis;
    newAxis.disabled = !axis.value;
    properties.axis = newAxis;
  }

  // 柱形宽度
  if (!properties.barWidth) {
    properties.barWidth = defaultProperties.barWidth;
  }

  // 动画
  if (!properties.animation) {
    properties.animation = defaultProperties.animation;
  }
}

// 兼容旧数据
export function adaptCompUpdateProperty(data: IComponentData, comp: UIComponent) {
  // 排除流程线，画板
  const isNotConnector = data.type !== CConnector;
  const isNotArtboard = data.type !== CArtboard && data.type !== 'main' && data.type !== 'fragment';
  if (isNotConnector && isNotArtboard && data.properties) {
    /**
     * 旧数据处理边框显示隐藏属性
     * 按钮、多行输入框、图片、文本、面板、内容面板、列表、数字输入器、树、导航菜单、垂直菜单
     */
    const compType = data.lib?.type || data.type;
    if (
      compType &&
      !comp.options?.isInAdvanceEditor &&
      (comp.isSealed || !comp.nearestSealedComponent) && // 复合组件或者最接近非组成复合组件的单组件
      ADD_BORDER_PROPERTY_COMPS.includes(compType) &&
      !data.properties.border
    ) {
      data.properties.border = DefaultBorder;
    }

    // 数值输入框
    if (compType === CNumericStep) {
      if (!data.properties.placeholder) {
        const defaultProperties = BasicComponentLib.make(CNumericStep).properties;
        // 旧数据兼容，数值输入框新增占位文字和占位颜色
        if (!data.properties.placeholder) {
          data.properties.placeholder = defaultProperties.placeholder;
          data.properties.placeHolderStyle = defaultProperties.placeHolderStyle;
        }
      }
      // 兼容旧数据， 数值输入框添加内边距
      if (!data.properties.padding) {
        const inputProperties = data.components?.[1]?.properties;
        if (inputProperties) {
          const inputPadding = inputProperties?.padding;
          // 从子组件的padding属性中获
          data.properties.padding = inputPadding || DefaultPadding;
          inputProperties.padding = { ref: '@properties.padding' };
        }
      }
    }

    // 兼容旧数据， 单选下拉框添加内边距
    if (compType === CSelect && !data.properties.padding) {
      data.properties.padding = DefaultPadding;
    }

    // 兼容旧数据， 多选下拉框添加内边距
    if (compType === CMultipleSelect && !data.properties.padding) {
      data.properties.padding = { ...DefaultPadding, left: 8, top: 4, bottom: 4, right: 20 };
    }

    // 旧数据兼容，多行文本添加radius属性
    if (compType === CTextArea && !data.properties.radius) {
      data.properties.radius = DefaultRadius;
    }

    // 处理旧的并且错误的关闭画板交互数据
    if (data.interaction) {
      const params: IFragmentActionParams = { effect: 'none' };
      for (const { actions } of Object.values(data.interaction)) {
        for (const action of actions) {
          if (action.type === 'fragment') {
            const fragmentAction = action as IFragmentAction;
            if (
              fragmentAction.isExit &&
              ![
                FragmentAnimationEffects.autoEffect,
                FragmentAnimationEffects.fadeInRevert,
                FragmentAnimationEffects.zoomInRevert,
                FragmentAnimationEffects.spreadInRevert,
                FragmentAnimationEffects.slideToBottomRevert,
                FragmentAnimationEffects.slideToLeftRevert,
                FragmentAnimationEffects.slideToRightRevert,
                FragmentAnimationEffects.slideToTopRevert,
              ].includes(fragmentAction.params.effect as FragmentAnimationEffects)
            ) {
              action.params = params;
            }
          }
        }
      }
    }

    // 图表属性旧数据兼容
    if (data.lib?.type && NEED_ADAPT_CHARTS.includes(data.lib.type)) {
      adaptChartProperty(data);
    }

    // 便签条
    if (compType === CStickNote && !data.properties.connectedLine) {
      data.properties.connectedLine = {
        ...DefaultNoteConnectedLine,
        disabled: true,
      };
    }
  }
}

/**
 * 根据alias移除组件中的子组件
 * @param comp
 * @param alias
 */
function removeCompByAlias(comp: IComponentData, alias: string) {
  const comps = comp.components || [];
  for (let i = 0; i < comps.length; i++) {
    if (comp.alias === alias) {
      return comps.splice(i, 1);
    }
    removeCompByAlias(comps[i], alias);
  }
}

/**
 * 更改compData中的内容
 * @param comp
 */
export function adaptCompData(data: IComponentData): IComponentData {
  // 排除流程线，画板
  const isNotConnector = data.type !== CConnector;
  const isNotArtboard = data.type !== CArtboard && data.type !== 'main' && data.type !== 'fragment';
  const hasPropertiesButNoTooltips = data.properties && !data.properties.tooltips;

  // 为组件添加tooltip属性
  if (isNotConnector && isNotArtboard && hasPropertiesButNoTooltips) {
    data.properties.tooltips = {
      name: i18n('property.propertyNames.tooltips'),
      prop: 'string',
      value: '',
    };
  }

  const compType = data.lib?.type || data.type;
  // 移除多选组件中的no-result component
  if (compType === CMultipleSelect) {
    removeCompByAlias(data, 'no-result');
  }

  // 处理形状矩形、基本矩形统一带来的特别数据：
  // { type: 'rect', lib: { id: 'flow', type:'rectangle' }}
  if (data.lib?.id === 'flow' && data.type !== 'path') {
    // @ts-ignore
    delete data.lib;
  }

  // 选项卡超旧数据兼容处理
  if (compType === CSelectTab) {
    const defaultData = makeComponent('common', CSelectTab, '');
    const defaultRectProperties = get(defaultData, 'components[0].components[1].properties') || {};
    if (defaultRectProperties) {
      const keys = Object.keys(defaultRectProperties);
      const comps = data.components || [];
      comps.forEach((comp) => {
        const rectComp = get(comp, 'components[1]');
        set(rectComp, 'alias', 'indicator');
        const rectProperties = get(comp, 'components[1].properties') || {};
        Object.keys(rectProperties).forEach((key) => {
          if (!keys.includes(key)) {
            delete rectProperties[key];
          }
        });
      });
    }
  }

  return data;
}

export function calcColumnMaxWidth(columnCount: number, comps: UIComponent[]): number[] {
  // 获取每一列的最大宽度
  const columnMaxWidthList: number[][] = [];
  const count = columnCount;
  for (let i = 1; i <= count; i++) {
    columnMaxWidthList.push([]);
  }
  comps.map((comp, i) => {
    columnMaxWidthList[i % count].push(comp.size.width);
  });
  return columnMaxWidthList.map((val) => Math.max(...val));
}

export function parseDashModel(dashModel: PresetDashModel) {
  switch (dashModel) {
    case PresetDashModel.shortDash:
    case PresetDashModel.dashDot:
    case PresetDashModel.dashDotDot:
    case PresetDashModel.longDash:
    case PresetDashModel.longDashDot:
    case PresetDashModel.middleDash:
      return 'dashed';
    case PresetDashModel.dot:
      return 'dotted';
    case PresetDashModel.solid:
      return 'solid';
    default:
      return 'solid';
  }
}
