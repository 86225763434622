import * as React from 'react';

import * as _ from 'lodash';
import classnames from 'classnames';

import { dragDelegate } from '@utils/mouseUtils';

import { IRange } from '@fbs/rp/models/properties/base';
import { MoveDelta } from '@fbs/common/models/resize';
import { IPosition } from '@fbs/common/models/common';
import { Operation, Ops } from '@fbs/rp/utils/patch';

import { UIComponent, UIContainerComponent } from '@editor/comps';
import { StyleHelper } from '@helpers/styleHelper';

import { IComponentProps } from '../../types';

import './index.scss';

interface ISliderState {
  value: number;
  trackStyle: React.CSSProperties;
  progressStyle: React.CSSProperties;
  ballStyle: React.CSSProperties;
}

enum RotateAngle {
  'startEast' = 0,
  'endEast' = 360,
  'south' = 90,
  'west' = 180,
  'north' = 270,
}

enum QuadrantIndex {
  'first' = 1,
  'second' = 2,
  'third' = 3,
  'fourth' = 4,
}

interface IQuadrantMethods {
  [key: number]: () => number;
}

class Slider extends React.Component<IComponentProps, ISliderState> {
  private pressed?: boolean;

  constructor(props: IComponentProps) {
    super(props);
    this.state = this.parserStyle(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: IComponentProps) {
    this.setState(this.parserStyle(nextProps));
  }

  private parserStyle = (props: IComponentProps, newValue?: number): ISliderState => {
    const group = props.comp as UIContainerComponent;
    const {
      properties: { progress },
      components,
      size: { width, height },
    } = group;
    const trackerComp = group.getComponentByAlias('tracker') || components[0];
    const progressContainer = (group.getComponentByAlias('progress') || components[1]) as UIContainerComponent;
    const progressComp = progressContainer.getComponentByAlias('progressRect') || progressContainer.components[0];
    const ballContainer = (group.getComponentByAlias('indicator') || components[2]) as UIContainerComponent;
    const ball = group.getComponentByAlias('ellipse') || ballContainer.components[0];

    const { properties: trackProp, size: trackSize, position: trackPos } = trackerComp;
    const trackParser = StyleHelper.createCSSStyleParser(trackProp);
    const { properties: progressProp, size: progressSize } = progressComp;
    const progressParser = StyleHelper.createCSSStyleParser(progressProp);
    const { size: ballSize, properties: ballProperties } = ball;
    const ballParser = StyleHelper.createCSSStyleParser(ballProperties);

    let value = 0;
    if (!_.isUndefined(newValue)) {
      value = newValue;
    } else if (progress) {
      value = (progress.value as IRange).value;
    }
    return {
      value,
      ballStyle: {
        left: Math.round(((width - ballSize.width) * value) / 100),
        top: Math.round((height - ballSize.height) / 2),
        ...ballSize,
        ...ballParser.getFillStyle(),
        ...ballParser.getStrokeStyle(),
        opacity: StyleHelper.getOpacity(ball.opacity),
      },
      trackStyle: {
        left: trackPos.x,
        top: trackPos.y,
        ...trackSize,
        ...trackParser.getFillStyle(),
        ...trackParser.getRadiusStyle(trackSize),
        ...trackParser.getStrokeStyle(),
        opacity: StyleHelper.getOpacity(trackerComp.opacity),
      },
      progressStyle: {
        width: `${value}%`,
        height: progressSize.height,
        ...progressParser.getStrokeStyle(),
        ...progressParser.getFillStyle(),
        ...progressParser.getRadiusStyle(progressSize),
        opacity: StyleHelper.getOpacity(progressComp.opacity),
      },
    };
  };

  private handleTrackMouseDown = (e: React.MouseEvent) => {
    const { comp, isPreview } = this.props;
    if (!isPreview) {
      return;
    }
    const ball = (comp as UIContainerComponent).getComponentByAlias('ellipse');
    const width = ball!.size.width;
    const deltaX = e.nativeEvent.offsetX;
    const newValue = (deltaX / (comp.size.width - width)) * 100;
    this.doSubmitValue(newValue);
  };

  private handleBallMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();

    const { scale: _scale, comp } = this.props;
    const slider = comp as UIContainerComponent;
    const { rotate } = comp;
    const { min, max } = Math;

    let scale = _scale || 1;
    const indicatorComp = slider.getComponentByAlias('indicator', true)!;
    const left = indicatorComp.position.x;

    const maxWidth = slider.size.width - indicatorComp.size.width;

    const { width: boundWidth, height: boundHeight } = slider.getViewBoundsInArtboard();

    let newValue: number = left;
    this.pressed = true;
    e.preventDefault();
    document.getSelection()?.empty();
    dragDelegate(
      (e: MouseEvent, delta: MoveDelta) => {
        e.preventDefault();
        const { x, y } = delta;

        let processLength: number = 0;
        // 垂直水平方向
        if (RotateAngle[rotate]) {
          processLength = this.doAxisDirection(rotate, x / scale, y / scale);
        } else {
          let computedX: number = 0,
            computedY: number = 0;
          // 是否以X为基准计算Y
          const xFlag = boundWidth >= boundHeight;
          if (xFlag) {
            computedX = x;
            computedY = (x / boundWidth) * boundHeight;
          } else {
            computedX = (y / boundHeight) * boundWidth;
            computedY = y;
          }

          processLength = this.doQuadrantDirection({ x: computedX / scale, y: computedY / scale }, rotate, xFlag);
        }
        processLength = processLength / window.pageScale;
        newValue = max(0, min(left + processLength, maxWidth));
        this.setState(this.parserStyle(this.props, (newValue * 100) / maxWidth));
      },
      () => {
        this.doSubmitValue((newValue * 100) / maxWidth);
      },
    );
  };

  doSubmitValue = (value: number) => {
    // 提交应用数据
    const { onInnerAction, comp } = this.props;
    if (onInnerAction) {
      const { width } = comp.size;
      const container = comp as UIContainerComponent;
      // const [, progressComp, indicatorComp] = this.props.comp.components;
      const progressComp = container.getComponentByAlias('progress', true)!;
      const indicatorComp = container.getComponentByAlias('indicator', true)!;
      const v = Math.max(0, Math.min(100, value));

      const left = ((width - indicatorComp.size.width) * v) / 100;

      const path = indicatorComp.getCurrentPropertiesPath('position/x');
      const progress = (progressComp as UIContainerComponent).components[0];
      const actions: { comp: UIComponent; actions: Operation[] }[] = [
        {
          comp: indicatorComp,
          actions: [Ops.replace(path, left)],
        },
        {
          comp: progressComp,
          actions: [Ops.replace(progressComp.getCurrentPropertiesPath('size/width'), left)],
        },
        {
          comp: progress,
          actions: [Ops.replace(progress.getCurrentPropertiesPath('size/width'), left - progress.position.x)],
        },
        {
          comp,
          actions: [Ops.replace(comp.getCurrentPropertiesPath('properties/progress/value/value'), value)],
        },
      ];
      onInnerAction(actions);
    }
  };

  /**
   * @description 计算当前旋转角度属于哪个象限
   * @param rotate 组件旋转角度
   * @returns 象限序号
   */
  doCalculateRotateInQuadrant = (rotate: number): number => {
    let quadrantIndex: number = 0;
    if (rotate > RotateAngle.startEast && rotate < RotateAngle.south) {
      quadrantIndex = QuadrantIndex.fourth;
    } else if (rotate > RotateAngle.south && rotate < RotateAngle.west) {
      quadrantIndex = QuadrantIndex.third;
    } else if (rotate > RotateAngle.west && rotate < RotateAngle.north) {
      quadrantIndex = QuadrantIndex.second;
    } else if (rotate > RotateAngle.north && rotate < RotateAngle.endEast) {
      quadrantIndex = QuadrantIndex.first;
    }
    return quadrantIndex;
  };
  /**
   * @description 针对象限编写策略模式
   * @param delta 坐标对象
   * @param xFlag 是否以X为基准计算Y
   * @returns
   */
  calculateSliderProcess = (delta: IPosition, xFlag: boolean): IQuadrantMethods => {
    const { round, abs, hypot } = Math;
    let x = delta.x,
      y = delta.y;
    let processLength: number = round(hypot(x, y));

    return {
      [QuadrantIndex.first]: () => {
        !xFlag && (x = -x);
        if (x < 0) {
          processLength = -processLength;
        }
        return processLength;
      },
      [QuadrantIndex.second]: () => {
        if (x <= 0) {
          // 增加进度条值
          processLength = abs(processLength);
        } else {
          // 减少进度条值
          processLength = -processLength;
        }
        return processLength;
      },
      [QuadrantIndex.third]: () => {
        !xFlag && (x = -x);
        if (x > 0) {
          processLength = -processLength;
        }
        return processLength;
      },
      [QuadrantIndex.fourth]: () => {
        if (x >= 0) {
          // 增加进度条值
          processLength = abs(processLength);
        } else {
          // 减少进度条值
          processLength = -processLength;
        }
        return processLength;
      },
    };
  };
  /**
   * @description 处理垂直水平方向上滑块
   * @param rotate 组件旋转角度
   * @param x 鼠标在X轴上的移动量
   * @param y 鼠标在Y轴上的移动量
   * @returns
   */
  doAxisDirection = (rotate: number, x: number, y: number): number => {
    const { abs } = Math;
    let processLength: number = 0;
    // 坐标系上进度条值处理
    if (rotate === RotateAngle.startEast) {
      processLength = x;
    } else if (rotate === RotateAngle.south) {
      processLength = y;
    } else if (rotate === RotateAngle.west) {
      if (x >= 0) {
        processLength = -x;
      } else {
        processLength = abs(x);
      }
    } else if (rotate === RotateAngle.north) {
      if (y >= 0) {
        processLength = -y;
      } else {
        processLength = abs(y);
      }
    }
    return processLength;
  };

  /**
   * @description 处理方向在象限内的滑块
   * @param delta 坐标对象
   * @param rotate 组件旋转角度
   * @param xFlag
   * @returns
   */
  doQuadrantDirection = (delta: IPosition, rotate: number, xFlag: boolean) => {
    const quadrantIndex = this.doCalculateRotateInQuadrant(rotate);
    return this.calculateSliderProcess(delta, xFlag)[quadrantIndex]();
  };

  render() {
    const { isPreview, comp } = this.props;
    const { size, opacity, disabled } = comp;
    const { ballStyle, trackStyle, progressStyle } = this.state;
    const style = {
      opacity: StyleHelper.getOpacity(opacity),
      ...size,
    };
    const allowEvent = isPreview && !disabled;
    return (
      <div className="lib-comp-slider" style={style} onMouseDown={allowEvent ? this.handleTrackMouseDown : undefined}>
        <div className="lib-slider-track" style={trackStyle}>
          <div className="lib-slider-progress" style={progressStyle} />
        </div>
        <div
          className={classnames('lib-slider-indicator-ball', { 'component-cursor-pointer': isPreview })}
          style={ballStyle}
          onMouseDown={allowEvent ? this.handleBallMouseDown : undefined}
        />
      </div>
    );
  }
}

export default Slider;
