import * as React from 'react';
import KeyCodeMap from '../../constants/KeyCodeMap';
import classnames from 'classnames';
import './index.scss';

export interface ISliderProp {
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  width?: number;
  className?: string;
  left?: boolean;
  disabled?: boolean;
  showText?: boolean;

  onSlider(value: number): void;
  //代表正在移动中的数据
  onMovingSlider(value: number): void;
}

const SLIDER_MIN = 0;
const SLIDER_MAX = 100;
const SLIDER_STEP = 1;
const SLIDER_VALUE = 50;
const SLIDER_WIDTH = 200;

class Slider extends React.Component<ISliderProp, { mouseDown: boolean; disX: number; vue: number }> {
  static defaultProps = {
    min: SLIDER_MIN,
    max: SLIDER_MAX,
    step: SLIDER_STEP,
    value: SLIDER_VALUE,
    className: '',
    width: SLIDER_WIDTH,
    left: false,
    disabled: false,
    showText: true,
  };

  constructor(props: ISliderProp) {
    super(props);
    this.state = {
      mouseDown: false,
      disX: 0,
      vue: Math.max(props.min ?? SLIDER_MIN, Math.min(props.value ?? SLIDER_VALUE, props.max ?? SLIDER_MAX)),
    };
    this.doChange = this.doChange.bind(this);
    this.OnMouseDown = this.OnMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleBlock = this.handleBlock.bind(this);
    this.calculateValue = this.calculateValue.bind(this);
  }

  componentDidUpdate(prevProps: ISliderProp) {
    const { value, min, max } = this.props;
    if (value !== prevProps.value) {
      this.setState({ vue: Math.max(min ?? SLIDER_MIN, Math.min(value ?? SLIDER_VALUE, max ?? SLIDER_MAX)) });
    }
  }

  calculateValue(disX: number) {
    const { min, max, width } = this.props;
    return Math.max(min ?? SLIDER_MIN, Math.min(Math.floor((disX / (width ?? SLIDER_WIDTH)) * 100), max ?? SLIDER_MAX));
  }

  OnMouseDown(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    const target = e.target as HTMLElement;
    const parent: HTMLElement = (target.parentNode as HTMLElement).offsetParent as HTMLElement;
    const disX = e.pageX - parent.getBoundingClientRect().left;
    this.setState({
      mouseDown: true,
      disX: parent.getBoundingClientRect().left,
      vue: this.calculateValue(disX),
    });
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseMove(e: MouseEvent) {
    e.preventDefault();
    const { onMovingSlider } = this.props;
    const { min, max } = this.props;

    const { disX, mouseDown } = this.state;
    if (mouseDown) {
      let left = e.pageX - disX;
      const { width } = this.props;
      if (left < 0) {
        left = 0;
      }
      if (left > (width ?? SLIDER_WIDTH)) {
        left = width ?? SLIDER_WIDTH;
      }
      const vue = Math.max(min ?? SLIDER_MIN, Math.min(this.calculateValue(left), max ?? SLIDER_MAX));
      this.setState({
        vue,
      });
      onMovingSlider && onMovingSlider(vue);
    }
  }

  handleMouseUp(e: MouseEvent | React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    const { vue } = this.state;
    this.doChange(vue);
    this.setState({
      mouseDown: false,
    });
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleBlock(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    const target = e.target as HTMLElement;
    if (!target.offsetParent) {
      return;
    }
    let disX = e.pageX - target.offsetParent.getBoundingClientRect().left;
    if (disX < 0) {
      disX = 0;
    }
    const { width } = this.props;
    if (disX > (width ?? SLIDER_WIDTH)) {
      disX = width ?? SLIDER_WIDTH;
    }
    this.setState({
      vue: this.calculateValue(disX),
    });
  }

  doChange(value: number) {
    const { onSlider, min, max } = this.props;
    if (!onSlider) {
      return;
    }
    const v = Math.max(min ?? SLIDER_MIN, Math.min(value, max ?? SLIDER_MAX));
    onSlider(v);
  }

  render() {
    const { width, left, disabled, className, showText } = this.props;
    const { vue } = this.state;
    return (
      <div className={['dsm-c-slider', className || ''].join(' ')}>
        {left && (
          <span
            className={classnames('dsm-c-block-title', {
              'disabled-slider-title': disabled,
            })}
          >
            {vue}%
          </span>
        )}
        <div
          className="dsm-c-sliding-block"
          style={{ width: `${width}px` }}
          onDragStart={(e) => {
            e.preventDefault();
          }}
          onMouseUp={!disabled ? this.handleMouseUp : undefined}
          draggable={false}
        >
          <div
            className="dsm-c-slider-click"
            style={{ width: `${width}px` }}
            onMouseDown={!disabled ? this.handleBlock : undefined}
          />
          <div
            className={classnames('dsm-c-block', {
              'disabled-slider-block': disabled,
            })}
            style={{ width: `${vue}%` }}
          >
            <span className="dsm-c-block-btn" onMouseDown={!disabled ? this.OnMouseDown : undefined} />
          </div>
        </div>
        {showText && !left && (
          <span
            className={classnames('dsm-c-block-title', {
              'disabled-slider-title': disabled,
            })}
          >
            {vue}%
          </span>
        )}
      </div>
    );
  }
}

export default Slider;
