export interface IPresetColor {
  color: string;
  bdc: string;
}

export const COLOR_PICKER_PRESET_COLOR: Array<IPresetColor> = [
  { color: '#d0021b', bdc: '#b40f22' },
  { color: '#f5a623', bdc: '#d18d1e' },
  { color: '#f8e71c', bdc: '#d4c623' },
  { color: '#8b572a', bdc: '#764a24' },
  { color: '#7ed321', bdc: '#72b627' },
  { color: '#417505', bdc: '#376404' },
  { color: '#bd10e0', bdc: '#a10ebf' },
  { color: '#9013fe', bdc: '#7b10d8' },
  { color: '#4a90e2', bdc: '#4981c2' },
  { color: '#50e3c2', bdc: '#4ec3a9' },
  { color: '#b8e986', bdc: '#a1c879' },
  { color: '#000000', bdc: '#000000' },
  { color: '#4a4a4a', bdc: '#3f3f3f' },
  { color: '#9b9b9b', bdc: '#848484' },
  { color: '#ffffff', bdc: '#d9d9d9' },
];

export const COLOR_PANEL_PICKER_TOP_COLOR: Array<string> = [
  'rgba(255, 255, 255, 1)',
  'rgba(255, 0, 0, 1)',
  'rgba(0, 0, 0, 1)',
  'rgba(255, 153, 0, 1)',
  'rgba(255, 255, 0, 1)',
  'rgba(152, 250, 28, 1)',
  'rgba(108, 222, 255, 1)',
  'rgba(64, 62, 214, 1)',
  'rgba(237, 65, 253, 1)',
];

export const COLOR_PANEL_PICKER_LIST_COLOR: Array<string> = [
  'rgba(233, 233, 233, 1)',
  'rgba(123, 123, 123, 1)',
  'rgba(255, 200, 184, 1)',
  'rgba(255, 225, 178, 1)',
  'rgba(255, 242, 204, 1)',
  'rgba(217, 234, 211, 1)',
  'rgba(223, 248, 255, 1)',
  'rgba(207, 199, 244, 1)',
  'rgba(254, 228, 255, 1)',
  'rgba(217, 217, 217, 1)',
  'rgba(92, 92, 92, 1)',
  'rgba(233, 152, 153, 1)',
  'rgba(255, 184, 77, 1)',
  'rgba(255, 229, 154, 1)',
  'rgba(172, 219, 126, 1)',
  'rgba(133, 212, 230, 1)',
  'rgba(140, 123, 232, 1)',
  'rgba(238, 147, 246, 1)',
  'rgba(196, 196, 196, 1)',
  'rgba(51, 51, 51, 1)',
  'rgba(224, 102, 102, 1)',
  'rgba(251, 141, 0, 1)',
  'rgba(255, 217, 102, 1)',
  'rgba(135, 193, 32, 1)',
  'rgba(76, 194, 238, 1)',
  'rgba(63, 73, 185, 1)',
  'rgba(208, 65, 225, 1)',
  'rgba(157, 157, 157, 1)',
  'rgba(38, 38, 38, 1)',
  'rgba(204, 0, 0, 1)',
  'rgba(231, 82, 0, 1)',
  'rgba(255, 183, 0, 1)',
  'rgba(103, 143, 0, 1)',
  'rgba(1, 136, 251, 1)',
  'rgba(39, 65, 177, 1)',
  'rgba(164, 25, 211, 1)',
];
