import Base from './base';
import { IDesignStatus } from '../../idoc/models/designStatus';
import { ISize, IPosition, Orientation } from '../../common/models/common';

export default interface INode extends Base {
  _id: string;
  appID: string;
  name: string;
  path: string;
  // 同级中的排序
  index: number;
  // 类型 page | folder
  type: string;
  artboardID: string;
  artboards?: Array<{ artboardID: string; name: string; size: ISize; position: IPosition }>;
  _collapse?: boolean;
  position?: {
    x: number;
    y: number;
  };
  size?: {
    width: number;
    height: number;
  };
  icon?: string;
  color?: string;
  links?: string[];
  imageURL?: string;
  imageCreatedAt?: Date;
  designStatusID?: string;
  designStatus?: IDesignStatus;
  taskID?: string;
  hidden?: boolean;
  nodeID?: string;
}
export interface IGroupPatch {
  nodes: INode[];
  newNodeID: string;
}

interface INodeAddInfo {
  name: string;
  userID: number;
  size: ISize;
  parentID?: string;
  index?: number;
  _id: string;
  artboardID: string;
  path?: string;
  pageID: string;
  selectedIDs: string[];
  nodes: INode[];
  appID?: string;
}

export interface IUngroupPatch {
  nodes: INode[];
  node: INodeAddInfo;
  nodeIDs: string[];
}

export interface INodeClonePatch {
  nodes: INode[];
  nodeIDs: string[];
}

export interface INodeWithChildren extends INode {
  children: INodeWithChildren[];
  parent?: INodeWithChildren;
  // 前端页面编号
  serialNumber?: string;
}

export enum NodeType {
  Page = 'page',
  Folder = 'folder',
}

export interface INodeAddPatch {
  _id: string;
  artboardID: string;
  artboardName: string;
  appID: string;
  name: string;
  type: NodeType;
  parentID?: string;
  // 插入位置，-1 表示插入到最后
  index: number;
  size?: ISize;
  orientation?: Orientation;
  userID: number;
  path?: string;
  notifyAll?: boolean;
}

export interface INodeCopyPatch {
  appID: string;
  nodeIDs: string[];
  toPath: string;
  index: number;
  sourceAppID: string;
  withChildren: boolean;
}
export interface INodeMovePatch {
  appID: string;
  nodeIDs: string[];
  toPath: string | string[];
  sourceAppID?: string;
  withChildren?: boolean;
  index: number | number[];
}

export interface INodeRemovePatch {
  appID: string;
  nodeIDs: string[];
}

export interface INodeRemoveAllPatch {
  appID: string;
}

export interface INodeRecoverPatch {
  appID: string;
  nodeIDs: string[];
  targetParent?: string;
  targetIndex?: number;
}

export interface INodeUngroupPatch {
  appID: string;
  nodeID: string;
}

export interface INodePatchPatch {
  appID: string;
  nodeID: string;
  name: string;
}

export interface INodeHidePatch {
  appID: string;
  nodeIDs: string[];
  hidden: boolean;
}
