/****************************************************************
 * 组合图表编辑器，继承普通图表编辑器
 ****************************************************************/
import * as React from 'react';

import { once } from 'lodash';

import { ChartUtils } from './utils';

import { DefaultChartColorList } from '@/libs/ChartComponents/common';
import i18n from '@/i18n';
import { withAutoClose, IAutoCloseProps } from '@/dsm';
import { IChartValue, IChartData } from '@/fbs/rp/models/chart';
import { EChartType_basic } from '@/fbs/rp/models/properties/chart';

import { ChartEditor, IChartProps, IChartBaseProps } from '.';
import updatePreventClose from './updatePreventClose';

const FirstColumnWidth = 66; // 第一列宽度

class CombinationChartEdit extends ChartEditor {
  private getTypeRows(dataSource: IChartData[]) {
    return dataSource.reduce(
      (prev, item) => {
        item.type === EChartType_basic.Bar ? prev.barRows++ : prev.lineRows++;
        return prev;
      },
      { barRows: 0, lineRows: 0 },
    );
  }

  // @override
  calcTableSize() {
    const res = super.calcTableSize();
    res.width += FirstColumnWidth;
    res.maxWidth += FirstColumnWidth;
    return res;
  }

  // @override
  getDeleteRowDisabled() {
    const { component } = this.props;
    const { dataSource } = component.value as IChartValue;
    const { barRows } = this.getTypeRows(dataSource);
    const {
      start: { row: startRow },
      end: { row: endRow },
    } = this.state.selectedArea!;
    return (startRow <= 1 && endRow >= barRows) || (startRow <= barRows + 1 && endRow >= dataSource.length);
  }

  // @override
  protected handleRowAdd = () => {
    const {
      component: { value, type },
    } = this.props;
    const chartValue = value as IChartValue;
    const { barRows, lineRows } = this.getTypeRows(chartValue.dataSource);
    // 如果柱状、折线的行数相同，则在柱状、折线各增加1行，否则只在柱状增加1行
    let newValue = ChartUtils.insertRows(type, chartValue, barRows + 1, 1); // 柱状增加一行;
    if (barRows === lineRows) {
      newValue = ChartUtils.insertRows(type, newValue as IChartValue, -1, 1); // 折线增加一行
    }
    this.handleSubmit(newValue);
    this.afterUpdateFn = once(() => {
      this.handleScrollTo('left');
      this.handleScrollTo('bottom');
    });
  };

  // 表格内容 @override
  renderContent() {
    const { component } = this.props;
    const { dataSource, xAxis } = component.value as IChartValue;

    const tableData = this.getTableDataSource();
    const { barRows, lineRows } = this.getTypeRows(dataSource);

    return (
      <>
        <thead>
          <tr className="chart-row">
            {this.renderTd(undefined, {
              isHead: true,
              row: 0,
              column: 0,
              isSelected: false,
              width: 90 + FirstColumnWidth,
              isEmpty: true,
              colSpan: 2,
            })}
            {xAxis.map((name, i) => this.renderXAxisHeader(name, 0, i + 1))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((rows, i) => {
            const { name, color } = dataSource[i];
            const rgba = color ?? DefaultChartColorList[i % DefaultChartColorList.length];
            return (
              <tr key={i} className="chart-row">
                {(i === 0 || i === barRows) &&
                  this.renderTd(
                    <div className="text-cell">
                      {i18n(`resource.components.${i === 0 ? 'barLabel' : 'lineLabel'}`)}
                    </div>,
                    {
                      isHead: true,
                      row: i + 1,
                      column: -1,
                      isSelected: false,
                      width: FirstColumnWidth,
                      isEmpty: true,
                      rowSpan: i === 0 ? barRows : lineRows,
                    },
                  )}
                {this.renderYAxisHeader(name, rgba, i + 1, 0)}
                {rows.map((item) => {
                  const { value, rowIndex, columnIndex } = item;
                  return this.renderDataCell(value, rowIndex, columnIndex);
                })}
              </tr>
            );
          })}
        </tbody>
      </>
    );
  }
}

const PanelClass: React.ComponentClass<IChartProps & IAutoCloseProps> = withAutoClose<IChartProps>(
  CombinationChartEdit,
);

export default updatePreventClose<IChartBaseProps & IAutoCloseProps>(PanelClass);
