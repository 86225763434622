import * as React from 'react';

import {
  IPager,
  ITableColumn,
  ITableData,
  IPoint,
  IPosition,
  TableSelection,
  ITableSortOrder,
  IPagerSelectAction,
  ICompareRefs,
} from '../../models';
import { IMenuOption } from '../Menu';
import {
  MenuDefaultWidth,
  MenuOptionDefaultLength,
  MenuOptionLineHeight,
  MenuOptionTextSize,
  MenuPaddingTB,
  TableRowLineHeight,
  MenuPaddingLR,
  moreIconHeight,
} from '../../constants';

import Tooltip from '../Tooltip';
import Menu from '../Menu';
import EmptyTip from '../EmptyTip';
import Content from './Content';
import StickyContent from './StickyContent';
import LoadingPoints from '../LoadingPoints';
import Pagination from '../Pagination';

import './index.scss';

interface ITableProps {
  theme?: 'red' | 'blue';
  sticky?: boolean;
  loading?: boolean;
  loadingMask?: boolean;
  dataSource: ITableData[];
  columns: ITableColumn[];
  totalText?: string;
  showActionsAsMenu?: boolean;
  pageInfo?: IPager;
  menuOptions?: IMenuOption[];
  rowSelectionType?: TableSelection;
  disableSelectionRows?: (string | number)[];
  checkedArr?: (string | number)[];
  // 单个选中动作按钮
  selectActionText?: string;
  // 多个选中动作按钮
  selectActions?: IPagerSelectAction[];
  searchKey?: string;
  cellStyle?: React.CSSProperties;
  selectedRow?: ITableData;
  emptyPicture?: string;
  searchEmptyPicture?: string;
  showTotal?: boolean;
  showPageIndex?: boolean;
  showSizeChanger?: boolean;
  showQuickJumper?: boolean;
  emptyTip?: string;
  searchEmptyTip?: string;

  onFormat?(data: ITableData, column: ITableColumn, ref?: ICompareRefs): React.ReactChild;
  onChangePage?(pageNum: number, pageSize?: number): void;
  onChangePageSize?(pageSize: number, number: number): void;
  // 左键单击某一行
  onClick?(data: ITableData): void;
  // 选中菜单项
  onSelectMenu?(menuType: string | number, data: ITableData): void;
  onCheckRow?(checked: (string | number)[]): void;
  // 勾选（复选框/单选框）某几行后的全选旁的动作按钮点击事件
  onCheckAction?(id?: string | number): void;
  onSort?(dataKey: string, sort: ITableSortOrder): void;
  onSelectRow?(data: ITableData): void;
  // 动态计算menu是否显示
  computeDynamicMenu?(data: ITableData): boolean;
}

const Table: React.FC<ITableProps> = (props: ITableProps) => {
  const {
    theme,
    sticky,
    loading,
    dataSource,
    columns,
    totalText,
    showActionsAsMenu,
    pageInfo,
    menuOptions,
    rowSelectionType,
    checkedArr,
    selectActionText,
    selectActions,
    searchKey,
    cellStyle,
    selectedRow,
    emptyPicture,
    searchEmptyPicture,
    disableSelectionRows,
    loadingMask,
    showTotal,
    showPageIndex,
    showSizeChanger,
    showQuickJumper,
    emptyTip,
    searchEmptyTip,
    onChangePage,
    onChangePageSize,
    onClick,
    onSelectMenu,
    onCheckRow,
    onCheckAction,
    onFormat,
    onSort,
    onSelectRow,
    computeDynamicMenu,
  } = props;
  const [showMenu, setShowMenu] = React.useState(false);
  const [menuOpacity, setMenuOpacity] = React.useState<0 | 1>(1);
  const [contextMenuPosition, setContextMenuPosition] = React.useState<IPosition>();
  const [activeMenuRow, setActiveMenuRow] = React.useState<ITableData>();
  const [checked, setChecked] = React.useState<(string | number)[]>(checkedArr || []);
  const [tipText, setTipText] = React.useState('');
  const [tipStyle, setTipStyle] = React.useState<React.CSSProperties | null>(null);
  const tableRef = React.useRef<HTMLTableElement>(null);
  const menuContainerRef = React.useRef<HTMLDivElement>(null);
  const [cacheMousePoint, setCacheMousePoint] = React.useState<IPoint>();
  const [cacheIsMenuFromOperateBtn, setCacheIsMenuFromOperateBtn] = React.useState(false);
  let timer: number | null = null;

  React.useEffect(() => {
    if (checkedArr) {
      const isEqual =
        checkedArr.some((item) => !checked.includes(item)) || checked.some((item) => !checkedArr.includes(item));
      isEqual && setChecked(checkedArr || []);
    }
  }, [checkedArr]);

  React.useEffect(() => {
    onCheckRow && onCheckRow(checked);
  }, [checked]);

  React.useEffect(() => {
    setChecked([]);
  }, [searchKey]);

  React.useEffect(() => {
    if (typeof selectedRow !== undefined) {
      onSelectRow && onSelectRow(activeMenuRow);
    }
  }, [activeMenuRow]);

  React.useEffect(() => {
    if (menuOptions?.length) {
      cacheMousePoint && timer && window.clearTimeout(timer);
      cacheMousePoint && setContextMenuPosition(computeMenuPosition(cacheMousePoint, cacheIsMenuFromOperateBtn));
    }
  }, [menuOptions]);

  const handleClickRow = (data: ITableData) => {
    onClick && onClick(data);
  };

  const handleContextRow = (data: ITableData, mousePoint: IPoint, fromOperateBtn?: boolean) => {
    if (!menuOptions?.length) {
      return;
    }
    if (computeDynamicMenu && !computeDynamicMenu(data)) {
      return;
    }
    setCacheMousePoint(mousePoint);
    setCacheIsMenuFromOperateBtn(!!fromOperateBtn);

    timer = window.setTimeout(() => {
      setContextMenuPosition(computeMenuPosition(mousePoint, fromOperateBtn));
    }, 2);

    setTimeout(() => {
      setActiveMenuRow(data);
      setTimeout(() => {
        setShowMenu(true);
        setTimeout(() => {
          setContextMenuPosition(computeMenuPosition(mousePoint, fromOperateBtn));
        }, 10);
      }, 2);
    }, 2);
  };

  const handleCheckedRow = (checked: string[] | number[]) => {
    setChecked(checked);
  };

  const onCheckAll = (isChecked: boolean) => {
    if (!!disableSelectionRows?.length) {
      const allCanCheckData = dataSource.filter((item) => !disableSelectionRows.includes(item.id));
      isChecked ? setChecked(allCanCheckData.map((item) => item.id)) : setChecked([]);
      return;
    }

    isChecked ? setChecked(dataSource.map((item) => item.id)) : setChecked([]);
  };

  const changePageNum = (pageNum: number, pageSize?: number) => {
    checked.length > 0 && setChecked([]);
    onChangePage && onChangePage(pageNum, pageSize);
  };

  const changePageSize = (pageSize: number, pageNumber: number) => {
    checked.length > 0 && setChecked([]);
    onChangePageSize && onChangePageSize(pageSize, pageNumber);
  };

  const onCheckActionBtn = (id?: string | number) => {
    onCheckAction && onCheckAction(id);
  };

  const computeMenuPosition = (mousePoint: IPoint, fromOperateBtn?: boolean) => {
    let menuWidth = 0;
    let menuHeight = 0;
    if (!menuOptions) {
      return {
        x: 0,
        y: 0,
      };
    }

    if (!menuContainerRef.current) {
      setMenuOpacity(0);
      contextMenuPosition && setContextMenuPosition(undefined);
      // 菜单未激活，使用所有菜单项的最大文字长度来计算菜单宽度，使用菜单项数量来计算菜单高度
      const menuTextLengthList = menuOptions.map((item) => item.text.length);
      const max = Math.max(...menuTextLengthList);
      menuWidth = max > MenuOptionDefaultLength ? max * MenuOptionTextSize + MenuPaddingLR : MenuDefaultWidth;
      menuHeight = MenuPaddingTB + MenuOptionLineHeight * menuOptions.length;
    } else {
      // 菜单已激活，只需要更换位置，获取菜单Dom的真实宽高
      const menuBounds = menuContainerRef.current.getBoundingClientRect();
      menuWidth = menuBounds.width;
      menuHeight = menuBounds.height;
      setMenuOpacity(1);
    }
    const tableBounds = tableRef.current!.getBoundingClientRect();
    let { x, y } = mousePoint;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    // 点击区域右侧宽度小于菜单宽度，需放在左边
    if (windowWidth - x < menuWidth) {
      x = x - tableBounds.left - menuWidth;
    } else {
      // 点击区域右侧宽度大于菜单宽度
      x = x - tableBounds.left;
    }
    if (windowHeight - y < menuHeight) {
      y = y - tableBounds.top - menuHeight - (fromOperateBtn ? moreIconHeight : 0);
    } else {
      y = y - tableBounds.top;
    }
    y = Math.round(y);
    x = Math.round(x);
    return {
      left: x,
      top: y,
    };
  };

  const closeMenu = () => {
    setActiveMenuRow(null);
    setShowMenu(false);
  };

  const setTip = (text: string, style: React.CSSProperties | null) => {
    setTipText(text);
    const tdLeft = style?.left as number | undefined;
    const tdTop = style?.top as number | undefined;
    if (tdLeft !== undefined && tdTop !== undefined) {
      const tableBounds = tableRef.current!.getBoundingClientRect();
      setTipStyle({ ...style, left: tdLeft - tableBounds.left, top: tdTop - tableBounds.top });
      return;
    }
    setTipStyle(style);
  };

  const maxRow = Math.max(...columns.map((column) => column.parentText?.length || 0)) + 1;
  return (
    <div className="c-table">
      <div className="c-table-content">
        {sticky ? (
          <StickyContent
            theme={theme || 'blue'}
            columns={columns}
            tableRef={tableRef}
            dataSource={dataSource}
            cellStyle={cellStyle}
            rowSelectionType={rowSelectionType}
            activeMenuRow={activeMenuRow}
            checked={checked}
            searchKey={searchKey || ''}
            disableSelectionRows={disableSelectionRows || []}
            onSort={onSort}
            onFormat={onFormat}
            handleClickRow={handleClickRow}
            handleContextRow={handleContextRow}
            handleCheckedRow={handleCheckedRow}
            setTip={setTip}
          />
        ) : (
          <Content
            theme={theme || 'blue'}
            columns={columns}
            tableRef={tableRef}
            dataSource={dataSource}
            cellStyle={cellStyle}
            rowSelectionType={rowSelectionType}
            activeMenuRow={activeMenuRow}
            checked={checked}
            searchKey={searchKey || ''}
            disableSelectionRows={disableSelectionRows || []}
            onSort={onSort}
            onFormat={onFormat}
            handleClickRow={handleClickRow}
            handleContextRow={handleContextRow}
            handleCheckedRow={handleCheckedRow}
            setTip={setTip}
          />
        )}
        {loading && (
          <div className="loading-box" style={{ top: maxRow * TableRowLineHeight }}>
            <LoadingPoints position="absolute" showMask={loadingMask} />
          </div>
        )}
        {!loading && !dataSource.length && (
          <EmptyTip
            emptyPicture={emptyPicture}
            searchEmptyPicture={searchEmptyPicture}
            top={maxRow * TableRowLineHeight}
            searchMode={!!searchKey}
            emptyTip={emptyTip}
            searchEmptyTip={searchEmptyTip}
          />
        )}
        {showMenu && menuOptions && menuOptions.length > 0 && (
          <div
            className="c-table-content-row-menu"
            ref={menuContainerRef}
            style={{ ...contextMenuPosition, opacity: menuOpacity }}
          >
            <Menu
              closeOnContext
              width={'auto'}
              options={menuOptions}
              onClose={closeMenu}
              onSelect={(id) => {
                closeMenu();
                onSelectMenu && onSelectMenu(id, activeMenuRow);
              }}
            />
          </div>
        )}
      </div>
      {pageInfo && pageInfo.count > 0 && (
        <div className="c-table-pager">
          <Pagination
            pageIndex={pageInfo.pageIndex}
            pageSize={pageInfo.pageSize}
            total={pageInfo.count}
            showTotalSelect={rowSelectionType !== TableSelection.None}
            checked={checked}
            currentPageCount={dataSource.length}
            showActionsAsMenu={showActionsAsMenu}
            selectActionText={selectActionText}
            selectActions={selectActions}
            checkAllCount={
              !!disableSelectionRows?.length
                ? dataSource.filter((item) => !disableSelectionRows.includes(item.id)).length
                : dataSource.length
            }
            theme={theme || 'blue'}
            showTotal={showTotal}
            totalText={totalText}
            showPageIndex={showPageIndex}
            showSizeChanger={showSizeChanger}
            showQuickJumper={showQuickJumper}
            onCheckAll={onCheckAll}
            onCheckAction={onCheckActionBtn}
            onChange={changePageNum}
            onChangeSize={changePageSize}
          />
        </div>
      )}
      {tipText && (
        <div className="c-table-tooltip" style={tipStyle || undefined}>
          <Tooltip
            theme={'small'}
            align="left"
            text={tipText}
            tipStyle={{
              whiteSpace: 'normal',
              height: 'fit-content',
              wordBreak: 'break-all',
              position: 'relative',
              top: 0,
              bottom: 'unset',
              display: 'block',
            }}
          />
        </div>
      )}
    </div>
  );
};

Table.defaultProps = {
  theme: 'blue',
  sticky: false,
  rowSelectionType: TableSelection.None,
  disableSelectionRows: [],
  selectActionText: '',
  selectActions: [],
  searchKey: '',
  cellStyle: {},
  emptyPicture: '',
  searchEmptyPicture: '',
  showActionsAsMenu: false,
  loading: false,
  loadingMask: true,
  showTotal: true,
  showPageIndex: true,
  showSizeChanger: true,
  showQuickJumper: true,
  emptyTip: '',
  searchEmptyTip: '',
};

export default Table;
