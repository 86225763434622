import * as React from 'react';
import classnames from 'classnames';

import { parseColorToString } from '@utils/graphicsUtils';

import { IconValue } from '@fbs/rp/models/value';

import { StyleHelper } from '@helpers/styleHelper';
import { hasInteraction } from '@helpers/interactionHelper';
import { UIContainerComponent, UIComponent } from '@editor/comps';
import initCSSStyleParser = StyleHelper.initCSSStyleParser;

import { IListCompositeProps } from '../../types';
import { getListEvents } from '../helps';

import './index.scss';

function parserIconStyle(comp: UIComponent) {
  const { size, properties, value, opacity } = comp;
  const { width, height } = size;
  const compSize = Math.min(width, height);
  const fontSize = Math.max(12, compSize);
  const parser = initCSSStyleParser(properties);
  const iconValue = value as IconValue;
  const style: React.CSSProperties = {
    width,
    height,
    fontSize,
    fontFamily: iconValue ? iconValue.fontName || '' : '',
    lineHeight: `${fontSize}px`,
    color: properties.icon ? parseColorToString(properties.icon.color!) : '#000',
    transition: comp.getTransition(),
    opacity: StyleHelper.getOpacity(opacity),
    ...parser.getTextShadow(),
  };
  let scale = 1;
  if (compSize < 12) {
    scale = compSize / 12;
    style.width = style.height = 12;
    style.transform = `scale(${scale})`;
  }
  return {
    style,
    value: iconValue ? String.fromCharCode(iconValue.iconCode) : '',
  };
}

const Score: React.FC<IListCompositeProps> = (props: IListCompositeProps) => {
  const { comp, isPreview, itemEvents } = props;
  const container = comp as UIContainerComponent;
  const { size, opacity } = container;
  const events = isPreview ? getListEvents(container.components, itemEvents) : {};
  return (
    <div
      className={classnames('lib-comp-score', { preview: isPreview })}
      style={{
        ...size,
        opacity: StyleHelper.getOpacity(opacity),
      }}
    >
      {container.components.map((comp) => {
        const { style, value } = parserIconStyle(comp);
        return (
          <div
            className={classnames('lib-comp-score-item', {
              'item-hot-area': isPreview && hasInteraction(comp),
              'component-cursor-pointer': isPreview,
            })}
            key={comp.id}
            style={style}
            {...(events[comp.id] || {})}
          >
            {value}
          </div>
        );
      })}
    </div>
  );
};

export default Score;
