import jsPDF from 'jspdf';
import { ICreatePdfImageOptins } from './type';

const getOrientation = (width: number, height: number) => {
  return width > height ? 'l' : 'p';
};
export function createPdfByImage(options: ICreatePdfImageOptins) {
  const { images, name } = options;
  if (images.length === 0) {
    return Promise.resolve();
  }
  const { width, height } = images[0].size;
  const pdf = new jsPDF(getOrientation(width, height), 'px', [width, height], true);

  images.forEach((t, index) => {
    const { value, size } = t;
    const { width, height } = size;
    if (index > 0) {
      pdf.addPage([width, height], getOrientation(width, height));
    }
    pdf.addImage(value, 'JPEG', 0, 0, width, height, undefined, 'FAST', 0);
  });
  return pdf.save(name, { returnPromise: true });
}
