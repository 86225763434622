import * as React from 'react';

import ScrollView from '../../ScrollView';
import SelectItem from './SelectItem';

import { IItemProps } from '../../../models';
import i18n from '../../../i18n';

import './index.scss';

interface ISelectListProps {
  showAddGroup: boolean;
  items: IItemProps[];
  checkedItems: string[];
  checkedGroups: string[];
  onCheckItems(ids: string[]): void;
  onCheckGroups(ids: string[]): void;
  onCheckDisable?(id: string): void;
}

const SelectList: React.FC<ISelectListProps> = (props: ISelectListProps) => {
  const { showAddGroup, items, checkedItems, checkedGroups, onCheckItems, onCheckGroups, onCheckDisable } = props;

  const renderHeader = () => {
    return (
      <div className="select-header">
        <span>{i18n('checkSelect.hasSelected', checkedItems.length)}</span>
        {checkedGroups.length > 0 && <span>{i18n('checkSelect.hasSelectedGroup', checkedGroups.length)}</span>}
        <span
          className="remove-all"
          onClick={() => {
            if (showAddGroup) {
              const disabledGroups = items.filter((item) => item.disabled && item.isGroup).map((item) => item.id);
              onCheckGroups(disabledGroups);
            }
            const disabledItems = items.filter((item) => item.disabled && !item.isGroup).map((item) => item.id);
            onCheckItems(disabledItems);
          }}
        >
          {i18n('checkSelect.clearAll')}
        </span>
      </div>
    );
  };

  const renderList = () => {
    return (
      <div className="select-list">
        <ScrollView height={'100%'}>
          {items.map((item: IItemProps) => (
            <SelectItem
              key={item.id}
              item={item}
              onRemoveItem={(id: string) => onCheckItems([...checkedItems.filter((checked) => id !== checked)])}
              onRemoveGroup={(id: string) => onCheckGroups([...checkedGroups.filter((checked) => id!==checked)])}
              onCheckDisable={onCheckDisable}
            />
          ))}
        </ScrollView>
      </div>
    );
  };

  return (
    <div className="c-tree-transfer-select-list">
      {renderHeader()}
      {renderList()}
    </div>
  );
};

export default SelectList;
