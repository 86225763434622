import { AvlTree } from '@tyriar/avl-tree';

import { depthClone } from '@/utils/globalUtils';

import { IBoundsOffset, ISize } from '@fbs/common/models/common';
import { IComponentData, ISymbolComponentData } from '@fbs/rp/models/component';
import IComponent from '@fbs/rp/models/ds/component';
import { IInteraction } from '@fbs/rp/models/interactions';
import { IBasicLayout, ILayout } from '@fbs/rp/models/layout';
import { applyOperations, ComponentOperations } from '@fbs/rp/utils/patch';

import { resetComponentsData } from '@/helpers/componentHelper';
import { updateSymbolDataByComp } from '@helpers/symbolHelper';

import { ComponentResizeResult, ResizeOptions } from '@editor/comps/resizeHelper';

import designManager from '@managers/DesignManager';
import { IUICompConstructOptions } from '@/customTypes';

import { UIPanelComponent, UIComponent, UIContainerComponent } from './';

export default class UISymbolComponent extends UIPanelComponent {
  private _symbolData?: ISymbolComponentData;

  private symbolDataTree?: AvlTree<string, IComponentData>;

  public get isGroup(): boolean {
    return !this.isSymbol;
  }

  private get symbolData(): ISymbolComponentData | undefined {
    return this._symbolData;
  }

  private set symbolData(value: ISymbolComponentData | undefined) {
    this._symbolData = value;
  }

  constructor(data: IComponentData, public parent?: UIContainerComponent, public options?: IUICompConstructOptions) {
    super(data, parent, options);
    this.updateSymbolData();
  }

  private buildTree() {
    const doInsertComp = (comp: IComponentData) => {
      this.symbolDataTree!.insert(comp._id, comp);
      comp.components?.forEach(doInsertComp);
    };
    if (this.isPreview) {
      if (!this.symbolDataTree) {
        this.symbolDataTree = new AvlTree<string, IComponentData>((a: string, b: string) => {
          return a.localeCompare(b);
        });
      }
      doInsertComp(this.data);
    }
  }

  private get resourceData(): IComponent | undefined {
    const { symbol } = super.data;
    if (!symbol) {
      return;
    }
    const { libID, masterID } = symbol;
    return designManager.getInstance().getSymbolData(libID, masterID, { enableClone: false }, this.id);
  }

  get symbolSizeVersion(): number {
    return this.resourceData?.value.sizeVersion || 0;
  }

  protected updateSymbolData() {
    const { symbol } = super.data;
    if (symbol) {
      const { libID, masterID } = symbol;
      if (this.isPreview) {
        if (!this._symbolData) {
          const data = designManager.getInstance().getSymbolData(libID, masterID, undefined, this.id);
          this.symbolData = data?.value;
        }
      } else if (designManager.getRevisionState()) {
        if (!this._symbolData) {
          try {
            const data = designManager.getRevisionInstance().getSymbolData(libID, masterID, undefined, this.id);
            this.symbolData = data?.value;
          } catch (e) {
            console.log(e);
          }
        }
      } else {
        const data = designManager.getInstance().getSymbolData(libID, masterID, { enableClone: false }, this.id);
        // 被删除的资源
        if (data?.state === 4) {
          this._isLostSymbol = true;
        }
        // 判断是否有更新，只有有更新的情况下，才重新赋值
        if (data && data.updatedAt !== this._symbolData?.updatedAt) {
          const clonedCompData = depthClone(data.value);
          resetComponentsData([clonedCompData]);
          this.symbolData = Object.assign(clonedCompData, { updatedAt: data.updatedAt });
        }
      }
      if (this._symbolData) {
        if (updateSymbolDataByComp(super.data, this._symbolData)) {
          // 当需要更新时，临时修改symbol备份的版本号
          this._symbolData.v = Math.round(Math.random() * 100);
        }
        // this.resetSymbolInteraction();
        return;
      } else {
        this._isLostSymbol = true;
      }
    }
  }

  get size(): ISize {
    const { size: componentSize, sizeVersion: componentSizeVersion } = super.data;
    const resourceData = this.resourceData;
    if (!resourceData) {
      return componentSize;
    }
    const { size: symbolSize, sizeVersion: symbolSizeVersion } = resourceData.value;
    return (symbolSizeVersion || 0) < (componentSizeVersion || 0) ? componentSize : { ...symbolSize };
  }

  loadSymbolDataByPreview() {
    if (!this.isPreview) {
      return;
    }
    if (this._symbolData) {
      return;
    }
    const { symbol } = super.data;
    if (symbol) {
      const { libID, masterID } = symbol;
      this.symbolData = designManager.getInstance().getSymbolData(libID, masterID, undefined, this.id)?.value;
    }
  }

  /**
   * 重置组件交互目标
   * @author Matt
   * @since 2020-12-2
   * @description 待定，旧数据不完善，可能存在不能兼容旧数据问题, 暂未启用
   */
  resetSymbolInteraction() {
    if (!this._symbolData) {
      return;
    }
    const data = super.data;

    const map: { [masterID: string]: IInteraction } = {};

    const parser = (comp: IComponentData) => {
      if (comp.masterID) {
        map[comp.masterID] = comp.interaction;
      }
      comp.components?.forEach(parser);
    };
    parser(data);

    const reset = (comp: IComponentData) => {
      const interaction = comp.interaction;
      if (interaction) {
        const evts = Object.keys(interaction);
        evts.forEach((evt) => {
          const event = interaction[evt];
          event?.actions.forEach((action) => {
            if (action.type === 'component') {
              if (!map[action.target]) {
                //目标不是symbol内部的
                const target = map[comp._id][evt]?.actions.find((a) => a._id === action._id)?.target;
                if (target) {
                  action.target = target;
                }
              }
            }
          });
        });
      }
      comp.components?.forEach(reset);
    };
    reset(this._symbolData);
  }

  public patchSymbolData(compID: string, operations: ComponentOperations) {
    if (this.isPreview && this.symbolDataTree) {
      const comp = this.symbolDataTree.get(compID);
      if (comp) {
        applyOperations(comp, operations);
      }
    }
  }

  protected get data(): IComponentData {
    if (this._symbolData) {
      const {
        _id,
        name,
        type,
        position,
        layout,
        rotate,
        opacity,
        hidden,
        locked,
        disabled,
        selected,
        symbol,
        states,
        _currentState,
        _scale,
        _animation,
        alias,
        remark,
        v,
        sizeVersion,
        float,
        interaction,
        exportImage,
      } = super.data;
      return {
        ...this._symbolData,
        _id,
        name,
        type,
        position,
        layout,
        rotate,
        opacity,
        hidden,
        locked,
        disabled,
        selected,
        symbol,
        states,
        _currentState,
        _scale,
        _animation,
        remark,
        alias,
        v,
        sizeVersion,
        float,
        interaction,
        exportImage,
      };
    }
    // TODO Matt 2021-12-22 这里不清楚之前为什么隐藏掉，会导致一些新的问题，暂时放开，用来测试看看有什么问题
    return {
      ...super.data,
      // hidden: this.isPreview,
    };
  }

  public get id() {
    return super.data._id;
  }

  public updateSymbolBounds() {
    this.updateSymbolData();
  }

  refreshComponents() {
    // if (!this.isPreview) {
    this.updateSymbolData();
    super.refreshComponents();
    // if (this._symbolData?.components) {
    //   this.components = this._symbolData.components.map((c) => makeUIComponent(c, this, this.options));
    // } else {
    //   super.refreshComponents();
    // }
    // } else {
    //   super.refreshComponents();
    //   // @ts-ignore
    //   if (super.data.symbolData) {
    //     // @ts-ignore
    //     this.symbolData = super.data.symbolData;
    //   }
    //   this.buildTree();
    // }
    if (this.isPreview) {
      this.buildTree();
    }
  }

  resizeHandler2(
    offset: IBoundsOffset,
    layout: IBasicLayout | ILayout,
    options: ResizeOptions,
    backupLayoutMap?: WeakMap<UIComponent, IBasicLayout>,
  ): ComponentResizeResult {
    const result = super.resizeHandler2(offset, layout, options, backupLayoutMap);
    if (this._symbolData) {
      if (result.patches && Object.keys(result.patches).length) {
        this._symbolData.v = Math.round(Math.random() * 100);
      }
    }
    return result;
  }
}
