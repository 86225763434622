import * as paper from 'paper';
import { uniqueId } from 'lodash';

import { ISize, union } from '@/utils/boundsUtils';
import { measureTextSize } from '@/utils/textUtils';

import { CPath, CCompoundPath, CGroup, CImage, CText } from '@/libs/constants';
import { makeComponent, getDefaultComponentName } from '@/libs/libs';
import { makeGroup } from '@/libs/containers/Group';
import { makeText, getDefaultTextFormat } from '@/libs/basic/NewText';
import { makeImage } from '@/libs/basic/Image/config';

import { PredefinedStates } from '@/consts/state';
import { DefaultFontFamilys } from '@/consts/fonts';
import { TransparentColor } from '@consts/colors';
import { UIComponent } from '@editor/comps';
import { IComponentData, IComponentStateData, IComponentState } from '@fbs/rp/models/component';
import IFill, { FillType } from '@/fbs/rp/models/properties/fill';
import { CompoundOperation } from '@/fbs/rp/models/properties/path';
import IStroke, { StrokeLineJoin, StrokeLineCap, StrokePosition } from '@/fbs/rp/models/properties/stroke';
import IShadow from '@/fbs/rp/models/properties/shadow';
import ITextFormatEx from '@/fbs/rp/models/properties/textFormat';
import { fitModeType } from '@/fbs/rp/models/properties/img';
import { IInteraction } from '@/fbs/rp/models/interactions';

import { segmentToData, compoundPath } from './pathHelper';
import { getNewID } from './idHelper';
import { getBoundsInParent } from './componentHelper';
import { StyleHelper } from './styleHelper';
import { getImageSize } from './fileUploadHelper';

interface UnitNode {
  data: paper.Item;
  stateNode: undefined | IComponentData;
  childrenNodes: IComponentData[];
  parent: null | UnitNode;
  children: null | UnitNode[];
  next: null | UnitNode;
}

interface IColor extends paper.Color {
  origin: paper.Point;
  destination: paper.Point;
}

interface TransformRes {
  add: IComponentData;
  remove: UIComponent;
}

type ParseType = 'Raster' | 'ItemText' | 'PointText' | 'Path' | 'CompoundPath';

/**
 * 將tspan标签替换成paper.js可转换的text标签
 * @param tspan
 */
function replaceTspanToText(tspan: SVGTSpanElement, text: SVGTextElement) {
  const attributes = tspan.attributes;
  const res = text.cloneNode() as SVGTextElement;
  res.appendChild(document.createTextNode(tspan.textContent as string));
  Array.from(attributes).forEach((attr) => {
    res.setAttribute(attr.nodeName, tspan.getAttribute(attr.nodeName)!);
  });
  return res;
}

/**
 * 调整svg内路径的属性
 * FIXME: 正则表达式
 * @param text
 */
function adjustSvgElement(text: string, size: ISize) {
  // stroke为null，被解析成默认黑框，移除
  let _text = text.replace(/stroke="null"/g, '');

  const dom = new window.DOMParser().parseFromString(_text.trim(), 'image/svg+xml');
  const svgDom = dom.querySelector('svg') as SVGSVGElement;

  // 补足svg width和height, 缺少会导致还原异常
  const svgWidth = svgDom.getAttribute('width');
  const svgHeight = svgDom.getAttribute('height');
  if (!svgWidth) {
    svgDom.setAttribute('width', `${size.width}px`);
  }
  if (!svgHeight) {
    svgDom.setAttribute('height', `${size.height}px`);
  }

  // rx/ry单边存在无法解析圆角，补足rect标签圆角属性
  const rectDoms = dom.querySelectorAll('rect');
  rectDoms.forEach((node) => {
    const rx = node.getAttribute('rx');
    const ry = node.getAttribute('ry');
    if (rx && !ry) {
      node.setAttribute('ry', rx);
    }
    if (ry && !rx) {
      node.setAttribute('rx', ry);
    }
  });

  // 处理id为`${number}`类型时，解析错误问题
  const allDoms = dom.querySelectorAll('*');
  allDoms.forEach((node) => {
    const id = node.getAttribute('id');
    if (id && !Number.isNaN(Number(id))) {
      node.setAttribute('id', `parse-item-${id}`);
    }
  });

  /**
   * paper.js无法解析tspan标签，转换成text标签处理
   * TODO: text和tspan标签的dx/dy属性暂未处理
   */
  const textDoms = dom.querySelectorAll('text');
  textDoms.forEach((node) => {
    const tspanDoms = node.querySelectorAll('tspan');
    if (tspanDoms.length > 0) {
      const fragment = document.createDocumentFragment();
      tspanDoms.forEach((item) => {
        const insertDom = replaceTspanToText(item, node);
        fragment.append(insertDom);
      });
      node.parentNode?.replaceChild(fragment, node);
    }
  });

  // use标签引用图片，在paper解析raster时，use引用的图片解析的位置产生偏移，将引用部分替换成真实的image标签
  const useDoms = dom.querySelectorAll('use');
  useDoms.forEach((node) => {
    const link = node.getAttribute('xlink:href');
    if (link) {
      const id = link.replace('#', '');
      const target = dom.getElementById(id);
      if (target?.nodeName == 'image') {
        const cloneTarget = target.cloneNode(true) as SVGImageElement;
        cloneTarget.id = uniqueId('image_copy_');
        const attributes = node.attributes;
        Array.from(attributes).forEach((attr) => {
          if (attr.nodeName !== 'xlink:href') {
            cloneTarget.setAttribute(attr.nodeName, node.getAttribute(attr.nodeName)!);
          }
        });
        node.parentNode?.replaceChild(cloneTarget, node);
      }
    }
  });
  return svgDom.outerHTML;
}

/**
 * 计算组的bounds信息
 * @param comps
 */
function calcGroupBounds(comps: IComponentData[]) {
  return union(
    ...comps.map((comp) => {
      return getBoundsInParent({
        position: comp.position,
        size: comp.size,
        rotate: comp.rotate || 0,
      });
    }),
  );
}

function calcPathValue(path: paper.Path) {
  const segments = path.segments || [];
  const pathData = segments.map(segmentToData);
  return {
    data: pathData,
    closed: !!path.closed,
  };
}

/**+
 * 当前group是否需要保留
 * group的展示属性由子元素决定，暂只考虑opacity的影响
 * @param group
 */
function isValidateGroup(group: paper.Group, child: IComponentData): boolean {
  return getOpacity(group) !== child.opacity;
}

/**
 * 当前形状被包裹，计算pathValue时，先移动后再计算返回，之后在重新移回
 * @param path
 * @param bounds
 * @param fn
 */
function executeFnWidthTranslate(path: paper.Item, bounds: paper.Rectangle, fn: Function) {
  let result;
  path.translate(new paper.Point(-(bounds?.left || 0), -(bounds?.top || 0)));
  result = fn();
  path.translate(new paper.Point(bounds?.left || 0, bounds?.top || 0));
  return result;
}

/**
 * 获取普通颜色
 */
function getColor(color: paper.Color, alpha = 1) {
  const pathColor = color || TransparentColor;
  return {
    r: (pathColor.red || 0) * 255,
    g: (pathColor.green || 0) * 255,
    b: (pathColor.blue || 0) * 255,
    a: (pathColor.alpha ?? 1) * alpha,
  };
}

/**
 * 计算x在已y为起点，在z中的占比
 * @param x
 * @param y
 * @param z
 */
function calcProportion(x: number | null, y: number | null, z: number | null) {
  return ((x || 0) - (y || 0)) / (z || 0);
}

/**
 * 获取线型渐变颜色
 */
function getGradientColor(color: paper.Color, bounds: paper.Rectangle) {
  const { gradient, origin, destination, alpha } = color as IColor;
  const { left, top, width, height } = bounds;
  const stops = gradient?.stops || [];

  const direction = {
    x1: calcProportion(origin.x, left, width),
    y1: calcProportion(origin.y, top, height),
    x2: calcProportion(destination.x, left, width),
    y2: calcProportion(destination.y, top, height),
  };

  const colorStops = stops.map((item) => ({
    color: getColor(item.color as paper.Color, alpha ?? 1),
    point: (item.offset || 0) * 100,
  }));

  return {
    colorStops,
    direction,
  };
}

/**
 * 获取fill颜色
 * @param fillColor
 */
function getPathFill(fillColor: paper.Color | null, bounds: paper.Rectangle): IFill {
  let color;
  let type;
  // 线型渐变
  if (fillColor?.type === 'gradient') {
    color = getGradientColor(fillColor, bounds);
    type = FillType.linear;
  } else {
    color = getColor(fillColor as paper.Color);
    type = FillType.solid;
  }
  return {
    type,
    color,
    disabled: false,
  };
}

/**
 * 获取stroke属性
 */
function getStroke(stroke: paper.Style, scale = 1): IStroke {
  const dashArray = stroke.dashArray || [];
  const strokeWidth = stroke.strokeWidth || 0;
  return {
    thickness: strokeWidth * scale,
    color: getColor(stroke.strokeColor as paper.Color),
    cap: (stroke.strokeCap as StrokeLineCap) || undefined,
    join: (stroke.strokeJoin as StrokeLineJoin) || undefined,
    // dashArray渲染时方法strokeWidth倍，此处缩小
    dashArray: stroke.dashArray ? dashArray.map((item) => (item / strokeWidth) * scale) : undefined,
    position: StrokePosition.center,
    disabled: false,
  };
}

/**
 * 获取shadow属性
 */
function getShadow(style: paper.Style): IShadow {
  const shadowOffset = style.shadowOffset || ({} as paper.Point);
  return {
    disabled: false,
    color: getColor(style.shadowColor as paper.Color),
    x: shadowOffset.x || 0,
    y: shadowOffset.y || 0,
    blur: style.shadowBlur || 0,
  };
}

/**
 * 字体粗细
 * @param bold
 */
function getFontWeight(bold: string | number | null) {
  if (Number(bold) > 500) {
    return true;
  }
  if (typeof bold === 'string') {
    return bold === 'bold' || bold === 'bolder';
  }
  return false;
}

/**
 * 获取文本size
 * @param size
 */
function getFontSize(size: string | number | null) {
  const fontSize = Number(size);
  return Number.isNaN(fontSize) ? undefined : fontSize;
}

/**
 * 获取fontFamily
 * @param fontFamily
 */
function getFontFamily(fontFamily: string) {
  const list = fontFamily.split(',');
  for (let i = 0; i < list.length; i++) {
    const item = list[i].trim();
    const res = DefaultFontFamilys.find((v) => v.text === item || v.id === item);
    if (res) {
      return res;
    }
  }
}

/**
 * 文本组件样式
 * @param style
 */
function getTextStyle(style: paper.Style, scale: number): ITextFormatEx {
  const defaultStyle = getDefaultTextFormat();
  const fontFamily = getFontFamily(style.fontFamily || '');

  let fontSize = getFontSize(style.fontSize) || defaultStyle.fontSize;
  fontSize = Math.round(fontSize! * scale);

  return {
    ...defaultStyle,
    fontSize,
    fontStyle: {
      ...defaultStyle.fontStyle,
      bold: getFontWeight(style.fontWeight),
    },
    fontFamily: fontFamily?.id || defaultStyle.fontFamily,
    color: getColor(style.fillColor!),
    lineHeightEx: fontSize,
  };
}

/**
 * 透明度
 * @param node
 */
function getOpacity(node: paper.Item) {
  return (node.opacity || 0) * 100;
}

/**
 * 根据旋转角度计算偏移高度
 * @param long
 * @param angle
 */
function getOffsetByRotate(long: number, angle = 0) {
  const radian = ((2 * Math.PI) / 360) * angle;
  return Math.sin(radian) * long;
}

/**
 * 图片在svg内默认展示方式为适应，根据图片原始宽高和图片展示宽高返回空白区域
 * @param size
 * @param originalSize
 * @param scaling
 */
function getImageSpace(size: paper.Size, originalSize: paper.Size, scaling: paper.Point) {
  const width = size.width || 0;
  const height = size.height || 0;

  const radio = width / height;
  const oriRadio = originalSize.width! / originalSize.height!;
  let spaceX = 0;
  let spaceY = 0;
  if (radio > oriRadio) {
    spaceX = width - height * oriRadio;
  } else {
    spaceY = height - width / oriRadio;
  }
  return { spaceX: spaceX * scaling.x, spaceY: spaceY * scaling.y };
}

/**
 * 还原属性样式
 * 该方法会重置position属性，需要放到compData设置position后面
 * @param compData
 * @param node
 * @param strokeScale
 * @param responsive
 */
function resumeProperties(compData: IComponentData, node: paper.Item, strokeScale: number, responsive: boolean) {
  const style = node.style;

  // 边框
  if (compData.properties.stroke) {
    compData.properties.stroke.disabled = true;
    if (style?.strokeColor && style?.strokeWidth) {
      compData.properties.stroke = getStroke(style!, strokeScale);
    }
  }

  // 填充， 文本填充设置文本颜色
  if (compData.properties.fill) {
    compData.properties.fill.disabled = true;
    if (node.hasFill() && !['PointText', 'ItemText'].includes(node.className as ParseType)) {
      compData.properties.fill = getPathFill(style!.fillColor, node.bounds!);
    }
  }

  // 投影
  if (compData.properties.shadow) {
    compData.properties.shadow.disabled = true;
    if (node.hasShadow()) {
      compData.properties.shadow = getShadow(style!);
    }
  }

  // 透明度
  compData.opacity = getOpacity(node);

  // 对智能布局的继承
  compData.layout.responsive = responsive;
}

/**
 * Raster加载
 * @param item
 */
async function isRasterLoad(item: paper.Raster) {
  // return new Promise((resolve, reject) => {
  //   item.onLoad = resolve;
  //   item.onError = reject;
  // });
  //
  // Raster.onLoad在paper.js解析过程中存在丢失的问题，模拟Raster.onLoad实现
  await getImageSize(item.source as string);
  return new Promise((resolve) => setTimeout(resolve, 0));
}

/**
 * 图片组件transform还原
 * @param compData
 * @param node
 * @param originalSize
 * FIXME: viewBox未设置&当前为拉伸状态&图片存在拉伸时，存在大小问题
 */
function resumeImageTransformProperties(compData: IComponentData, node: paper.Raster, originalSize: paper.Size) {
  const transformData = node.matrix?.decompose() as Record<string, any>;
  const rotation = transformData.rotation;
  const scaling = transformData.scaling;
  const { spaceX, spaceY } = getImageSpace(node.size!, originalSize!, scaling);
  node.rotate(-rotation);

  const bounds = node.bounds!;
  compData.rotate = rotation;
  compData.size.width = bounds.width! - spaceX;
  compData.size.height = bounds.height! - spaceY;
  compData.position.x = bounds.x! + spaceX / 2;
  compData.position.y = bounds.y! + spaceY / 2;
  node.rotate(rotation);
}

/**
 * 合并原组件属性
 * @param compData
 * @param comp
 */
function mergeOriginalCompProperties(compData: IComponentData, comp: UIComponent) {
  const { remark, float, layout, states, disabled, locked, interactions } = comp;
  const compPosition = compData.position;
  // 交互
  const _interaction: IInteraction = {};
  Object.keys(interactions).forEach((event) => {
    _interaction[event] = interactions[event];
    const actions = _interaction[event].actions;
    actions.forEach((item) => {
      if (item.target === comp.id) {
        item.target = compData._id;
      }
    });
  });

  compData.name = comp.name!;
  compData.interaction = _interaction;
  compData.remark = remark;
  compData.float = float;
  compData.layout = layout;
  compData.disabled = disabled;
  compData.locked = locked;
  compData.states = {};

  // properties属性不同类型的组件展示方式不同，暂不继承样式属性
  // const canMergedProperties = ['stroke', 'shadow', 'radius', 'border'];
  // const tempComp = makeUIComponent(compData);
  // const tempProperties = tempComp.properties;
  // const mergedKeys = Object.keys(tempProperties).filter((key) => canMergedProperties.includes(key));
  // 需要继承tooltips属性
  const mergedKeys = ['tooltips'];
  [PredefinedStates.normal, ...Object.keys(states)].forEach((key) => {
    let data: IComponentState | IComponentData = comp.toJSON();
    let recvData: IComponentStateData = compData;
    if (key !== PredefinedStates.normal) {
      data = states[key] as IComponentState;
      compData.states[key] = {
        enabled: data.enabled ?? false,
        properties: {},
      };
      recvData = compData.states[key];
    }
    const stateProperties = data.properties || {};
    const position = data.position;
    recvData.name = data.name;
    recvData.hidden = data.hidden;
    recvData.rotate = compData.rotate || 0 + (data.rotate || 0);
    recvData.opacity = data.opacity;
    if (position) {
      recvData.position = {
        x: position.x + compPosition.x,
        y: position.y + compPosition.y,
      };
    }
    if (recvData.size) {
      recvData.size.lockedRatio = data.size?.lockedRatio;
    }
    Object.keys(stateProperties).forEach((property) => {
      if (mergedKeys.includes(property)) {
        recvData.properties![property] = stateProperties[property];
      }
    });
  });
}

// 每个同步解析任务执行时间
const SyncTaskTime = 200;

class ParseSvg {
  private url: string; // 地址
  private rootNode: UnitNode | null; // 遍历链表根节点
  private originalComp: UIComponent;
  private compSize: ISize; // 组件size
  private fitMode: fitModeType | undefined; //图片填充类型
  private svgSize: ISize;
  private strokeScale: number; // stroke缩放值
  private scaleX: number; // x轴缩放值
  private scaleY: number; // y轴缩放值
  private ignoreParseType: ParseType[]; // 忽略解析类型
  private isCancel: boolean | undefined; //

  constructor(comp: UIComponent, ignoreParseType?: ParseType[]) {
    this.url = comp.value as string;
    this.compSize = comp.size;
    this.fitMode = comp.properties.image?.fitMode;
    this.ignoreParseType = ignoreParseType || [];
    this.originalComp = comp;

    this.rootNode = null;
    this.svgSize = {
      width: 0,
      height: 0,
    };
    this.strokeScale = 1;
    this.scaleX = 1;
    this.scaleY = 1;
  }

  /**
   * 文本或边框缩放值
   */
  get itemScale() {
    return Math.min(this.scaleX, this.scaleY);
  }

  /**
   * 可进行解析的类型
   */
  private isAllowParseType(item: paper.Item) {
    return ![...this.ignoreParseType, 'Shape'].includes(item.className as ParseType);
  }

  /**
   * 获取svgItem，如果导入的svg存在viewBox属性，数据中会将svg标签信息添加到group的第一个children
   */
  private getSvgItem(root: paper.Item): paper.Shape | undefined {
    const box = root.children?.[0];
    if (box && box instanceof paper.Shape && box.clipMask) {
      return box;
    }
  }

  /**
   * 属性填充
   */
  private fillCompProperties(compData: IComponentData, path: paper.Item) {
    const responsive = this.originalComp.layout.responsive;
    return resumeProperties(compData, path, this.strokeScale, responsive);
  }

  /**
   * 获取stroke缩放值
   * 根据svg的viewBox和svg的宽高计算得出
   * @param group
   */
  private calcStrokeScale(root: paper.Item) {
    const box = this.getSvgItem(root);
    if (box) {
      const scaling = box.scaling!;
      return Math.min(scaling.x, scaling.y);
    }
    return this.itemScale;
  }

  /**
   * path 数据
   * @param node
   */
  private createPath(node: UnitNode) {
    const path = node.data as paper.Path;

    const bounds = path.bounds!;
    if (bounds.width === 0 || bounds.height === 0) {
      return undefined;
    }
    const compData = makeComponent('basic', CPath, '')!; // 不占位的path不解析
    const pathValue = executeFnWidthTranslate(path, bounds, () => calcPathValue(path));

    compData.name = getDefaultComponentName(CPath) + path.id;
    compData.value = pathValue;
    compData.size = {
      width: bounds.width || 0,
      height: bounds.height || 0,
    };
    compData.position = {
      x: bounds?.left || 0,
      y: bounds?.top || 0,
    };
    compData.states = {};
    this.fillCompProperties(compData, path);

    return compData;
  }

  /**
   * 生成复合形状的数据
   * @param item
   */
  private createCompoundPath(node: UnitNode, compoundModel: CompoundOperation) {
    const path = node.data;
    const children = node.childrenNodes;
    if (children.length <= 1) {
      return node.childrenNodes[0];
    }
    const compData = compoundPath(children, compoundModel);
    if (compData) {
      this.fillCompProperties(compData, path);
      compData.name = getDefaultComponentName(CCompoundPath) + path.id;
    }
    return compData;
  }

  /**
   * 生成文本组件
   * @param node
   */
  private createText(node: UnitNode): IComponentData | undefined {
    const item = node.data as paper.PointText;
    const bounds = item.bounds!;
    const text = item.content || undefined;
    const transformData = item.matrix?.decompose() as Record<string, any>;
    const rotation = transformData.rotation;
    const scaling = transformData.scaling;

    // 文本缩放包含图片本身的拉伸缩放和transform scale缩放
    const textStyle = getTextStyle(item.style!, Math.min(scaling.x, scaling.y));
    const compData = makeText(getNewID(), text, textStyle);
    const parser = StyleHelper.initCSSStyleParser({ textStyle });
    const textSize = measureTextSize(parser.getTextStyle(), text || '');
    const position = {
      x: bounds.left || 0,
      y: bounds.top || 0,
    };

    compData.name = getDefaultComponentName(CText) + item.id;
    compData.size = textSize;
    compData.position = position;

    this.fillCompProperties(compData, item);

    if (rotation) {
      const offset = getOffsetByRotate(textSize.width / 2, rotation);
      compData.position = { x: position.x, y: position.y + offset };
      compData.rotate = rotation;
    }

    return compData;
  }

  /**
   * 图片组件
   * paper.Raster.onLoad前获取的img信息都为原始信息
   * onLoad后为处理后的信息
   * TODO: 上传图片需要时长，解析出的图片是否需要上传？
   * @param node
   */
  private async createImage(node: UnitNode): Promise<IComponentData | undefined> {
    try {
      const item = node.data as paper.Raster;
      const img = item.image;
      if (img) {
        const url = item.source;
        // 图片原始size
        const originalSize = item.size;
        await isRasterLoad(item);

        const bounds = item.bounds!;
        const name = getDefaultComponentName(CImage) + item.id;
        const compData = makeImage(getNewID(), url as string, name, {
          width: bounds.width || 0,
          height: bounds.height || 0,
        });
        compData.position = {
          x: bounds?.left || 0,
          y: bounds?.top || 0,
        };
        compData.properties.image!.fitMode = 'fit';

        this.fillCompProperties(compData, item);
        resumeImageTransformProperties(compData, item, originalSize!);
        return compData;
      }
    } catch (error) {
      throw new Error('Raster load error');
    }
  }

  /**
   * 生成编组数据
   * @param item
   */
  private createGroup(node: UnitNode): IComponentData | undefined {
    const components = node.childrenNodes;
    const bounds = calcGroupBounds(components);
    const compData = makeGroup(
      bounds,
      components.map((comp) => {
        const position = comp.position;
        return {
          ...comp,
          position: {
            x: position.x - (bounds.left || 0),
            y: position.y - (bounds.top || 0),
          },
        };
      }),
    );

    compData.name = getDefaultComponentName(CGroup) + node.data.id;
    this.fillCompProperties(compData, node.data);

    return compData;
  }

  /**
   * 不同类型创建compData
   * @param node
   */
  private async createComponentData(node: UnitNode): Promise<IComponentData | undefined> {
    const data = node.data;
    switch (data.className) {
      case 'Path': {
        return this.createPath(node);
      }
      case 'CompoundPath': {
        return this.createCompoundPath(node, CompoundOperation.Exclude);
      }
      case 'PointText':
      case 'ItemText': {
        return this.createText(node);
      }
      case 'Raster': {
        return await this.createImage(node);
      }
      case 'Group': {
        const nodes = node.childrenNodes;
        if (nodes && nodes.length > 0) {
          // group标签只存在一个子元素，且当前group不存在有效样式，返回当前子元素
          if (nodes.length === 1 && !isValidateGroup(data as paper.Group, nodes[0])) {
            return nodes[0];
          }
          return this.createGroup(node);
        }
        return undefined;
      }
      default: {
        const nodes = node.childrenNodes;
        if (nodes && nodes.length > 0) {
          return this.createGroup(node);
        }
      }
    }
  }

  /**
   * 初始化unitData
   * @param data
   */
  private createUnitNode(data: paper.Item): UnitNode {
    return {
      data,
      stateNode: undefined,
      childrenNodes: [],
      parent: null,
      children: null,
      next: null,
    };
  }

  /**
   * 给所有子节点创建unitNode，并添加链条关系
   * @param parent
   * @param children
   */
  private reconcileChildren(parent: UnitNode, children: paper.Item[]) {
    let prev: UnitNode | null = null;
    const childrenNode = children.reduce((res, child) => {
      if (this.isAllowParseType(child)) {
        const childNode = this.createUnitNode(child);
        childNode.parent = parent;
        if (prev) {
          prev.next = childNode;
        }
        prev = childNode;
        res.push(childNode);
      }
      return res;
    }, [] as UnitNode[]);
    parent.children = childrenNode;
    return childrenNode[0] || null;
  }

  /**
   * 创建子节点的unitNode，children只存在undefined和数组的情况
   * @param unitNode
   */
  private beginWork(unitNode: UnitNode): UnitNode | null {
    const data = unitNode.data;
    const children = data.children;
    if (children && children.length > 0) {
      return this.reconcileChildren(unitNode, children);
    }
    return null;
  }

  /**
   * 给节点生成compData
   * @param unitNode
   */
  private async completeWork(unitNode: UnitNode): Promise<UnitNode | null> {
    let current = unitNode;
    while (current) {
      const parent = current.parent;
      const stateNode = await this.createComponentData(current);
      current.stateNode = stateNode;
      if (!parent) {
        return null;
      }
      if (stateNode) {
        parent.childrenNodes.push(stateNode);
      }
      // 兄弟节点重复beginWork过程
      if (current.next) {
        return current.next;
      }
      // completeWork
      current = parent;
    }
    return null;
  }

  /**
   * 深度遍历节点，原理同react fiber tree构建
   * beginWork生成unitNode，并返回子节点继续构建
   * 单条支链构建完成，调用completeWork生成compData并添加到父节点的childrenNodes中，返回兄弟节点调用beginWork执行上一步
   * 兄弟节点全部遍历完成，让父元素节点生成compData数据，再次返回父元素节点的兄弟节点，重复执行，直到遍历完成
   * @param unitNode
   */
  private async performUnitNode(unitNode: UnitNode): Promise<UnitNode | null> {
    let next = this.beginWork(unitNode);
    if (next === null) {
      next = await this.completeWork(unitNode);
    }
    return next;
  }

  /**
   * 依次解析节点，执行超过TaskTime时间，先响应ui操作，然后执行任务
   */
  private async asyncRunTask(): Promise<IComponentData | undefined> {
    return new Promise((resolve) => {
      const { port1, port2 } = new MessageChannel();
      let unitNode = this.rootNode;
      const workLoop = async () => {
        let taskTime = 0;
        const taskStartTime = performance.now();
        while (unitNode !== null && taskTime < SyncTaskTime && !this.isCancel) {
          unitNode = await this.performUnitNode(unitNode);
          taskTime = performance.now() - taskStartTime;
        }
        // 超时
        if (unitNode !== null && taskTime >= SyncTaskTime) {
          port1.postMessage(null);
        }
        // 遍历完成
        if (unitNode === null) {
          resolve(this.rootNode?.stateNode);
        }
        if (this.isCancel) {
          resolve(undefined);
        }
      };
      port2.onmessage = workLoop;
      workLoop();
    });
  }

  /**
   * 同步遍历节点
   */
  private async syncRunTask() {
    let unitNode: UnitNode | null = this.rootNode;
    while (unitNode !== null && !this.isCancel) {
      unitNode = await this.performUnitNode(unitNode);
    }
    if (this.isCancel) {
      return undefined;
    }
    return this.rootNode!.stateNode;
  }

  /**
   * 从跟节点开始向下遍历
   * @param data
   * @param isSync
   */
  private async performRoot(data: paper.Item, isSync: boolean): Promise<IComponentData | undefined> {
    const root = this.createUnitNode(data);
    this.rootNode = root;
    if (isSync) {
      return await this.syncRunTask();
    }
    return await this.asyncRunTask();
  }

  /**
   * 初始化处理svg
   * @param url
   */
  private async initialParse(): Promise<{ size: ISize; data: string } | undefined> {
    const size = await getImageSize(this.url);

    // 读取svg文件，axios设置了baseUrl，图片在私有部署，路径为相对路径时，无法获取对应的文件，使用fetch代替
    const res = await window.fetch(this.url).then((res) => res.text());
    if (res) {
      const svg = adjustSvgElement(res, size);
      return { size, data: svg };
    }
  }

  /**
   * 计算填充模式缩放
   * @param group
   * @param groupSize
   */
  private calcStretchScale(group: paper.Group, groupSize: ISize) {
    const originRadio = groupSize.width / groupSize.height; // 原图宽高比
    const compRadio = this.compSize.width / this.compSize.height; // 设置组件宽高数据的比值
    const svgItem = this.getSvgItem(group);

    let scaleX = 1;
    let scaleY = 1;
    let originScale = 1; // svg宽高缩放比值
    if (svgItem) {
      const scaling = svgItem.scaling!;
      originScale = scaling.x / scaling.y; // 宽高长度缩放比值
    }
    if (originScale >= 1) {
      // 宽高比未超过原图比，图片未被拉伸拉伸，只存在位置变化
      if (compRadio < originRadio) {
        // 固定y轴
        scaleY = this.compSize.height / groupSize.height;
        scaleX = scaleY / originScale;
      } else if (compRadio > 1) {
        // 超过宽高比，且当前为横图，固定Y轴
        scaleY = this.compSize.width / groupSize.width;
        scaleX = scaleY / originScale;
      } else {
        // 超过宽高比，且当前为竖图，固定X轴
        scaleX = this.compSize.width / groupSize.width;
        scaleY = scaleX * originScale;
      }
    } else {
      // 宽高比未超过原图比，图片未被拉伸拉伸，只存在位置变化
      if (compRadio < originRadio) {
        // 固定y轴
        scaleX = this.compSize.height / groupSize.height;
        scaleY = scaleX * originScale;
      } else if (compRadio > 1) {
        // 超过宽高比，且当前为横图，固定Y轴
        scaleX = this.compSize.width / groupSize.width;
        scaleY = scaleX * originScale;
      } else {
        // 超过宽高比，且当前为竖图，固定X轴
        scaleX = this.compSize.width / groupSize.width;
        scaleY = scaleX * originScale;
      }
    }
    return { scaleX, scaleY };
  }

  /**
   * 计算拉伸模式缩放
   * @param group
   * @param groupSize
   */
  private calcFitScale(group: paper.Group, groupSize: ISize) {
    const compRadio = this.compSize.width / this.compSize.height; // 设置组件宽高数据的比值
    const svgItem = this.getSvgItem(group);

    let scaleX = this.compSize.width / groupSize.width;
    let scaleY = this.compSize.height / groupSize.height;
    let svgRadio = 1;
    let originScale = 1; // 宽高长度缩放比值
    if (svgItem) {
      const svgSize = svgItem.size as ISize; // viewBox值
      const scaling = svgItem.scaling!;
      svgRadio = svgSize.width! / svgSize.height!; // 整个图片的宽高比
      originScale = scaling.x / scaling.y; // 宽高长度缩放比值

      if (compRadio <= svgRadio) {
        scaleX = this.compSize.width / groupSize.width;
        scaleY = scaleX * originScale;
      } else {
        // 宽高比超过原图，缩放由y轴决定
        scaleY = this.compSize.height / groupSize.height;
        scaleX = scaleY / originScale;
      }
    }

    return { scaleX, scaleY };
  }

  /**
   * 适应模式缩放
   * @param group
   * @param groupSize
   */
  private calcOriginScale(group: paper.Group, groupSize: ISize) {
    const svgItem = this.getSvgItem(group);
    const originRadio = groupSize.width / groupSize.height; // 原图宽高比
    const compRadio = this.compSize.width / this.compSize.height; // 设置组件宽高数据的比值

    let scaleX = 1;
    let scaleY = 1;
    let originScale = 1; // 宽高长度缩放比值
    let scalingX = 1;
    let scalingY = 1;
    let svgSize = groupSize;
    if (svgItem) {
      const scaling = svgItem.scaling!;
      svgSize = svgItem.size as ISize; // viewBox值
      originScale = scaling.x / scaling.y; // 宽高长度缩放比值
      scalingX = scaling.x;
      scalingY = scaling.y;
    }

    if (originScale <= 1) {
      if (compRadio <= originRadio) {
        scaleX = this.compSize.width / groupSize.width;
        scaleY = scaleX * originScale;
      } else {
        let _scale = 1;
        if (originScale > 1) {
          // x轴缩放大于y轴，使用y轴，缩放x轴
          _scale = (scalingY * svgSize.width) / groupSize.width;
        } else {
          _scale = (scalingX * svgSize.height) / groupSize.height;
        }
        scaleY = (this.compSize.height / groupSize.height) * _scale;
        scaleX = scaleY / originScale;
      }
    } else {
      if (compRadio >= originRadio) {
        scaleY = this.compSize.height / groupSize.height;
        scaleX = scaleY / originScale;
      } else {
        if (originScale > 1) {
          // x轴缩放大于y轴，使用y轴，缩放x轴
          const _scale = (scalingY * svgSize.width) / groupSize.width;
          scaleX = (this.compSize.width / groupSize.width) * _scale;
          scaleY = scaleX * originScale;
        } else {
          const _scale = (scalingX * svgSize.height) / groupSize.height;
          scaleY = (this.compSize.height / groupSize.height) * _scale;
          scaleX = scaleY / originScale;
        }
      }
    }

    return { scaleX, scaleY };
  }

  /**
   * 不同模式缩放值
   * @param group
   * @param groupSize
   */
  private calcGroupScale(group: paper.Group, groupSize: ISize) {
    switch (this.fitMode) {
      case 'stretch': {
        return this.calcStretchScale(group, groupSize);
      }
      case 'fit': {
        return this.calcFitScale(group, groupSize);
      }
      default: {
        return this.calcOriginScale(group, groupSize);
      }
    }
  }

  /**
   * 根据组件的size缩放svgGroupRoot
   * @param root
   * @param compSize
   * @param rootSize
   */
  private scaleRootGroup(root: paper.Group, rootSize: ISize): paper.Group {
    const center = root.bounds!.center as paper.Point;
    const { scaleX, scaleY } = this.calcGroupScale(root, rootSize);

    center.x = center.x * scaleX;
    center.y = center.y * scaleY;
    this.scaleX = scaleX;
    this.scaleY = scaleY;
    root.scale(scaleX, scaleY, center);

    this.svgSize = {
      width: rootSize.width * scaleX,
      height: rootSize.height * scaleY,
    };
    return root;
  }

  /**
   * 获取起始点位置
   */
  private getOriginPosition() {
    const children = this.rootNode?.children || [];
    let minLeft: number | undefined;
    let minTop: number | undefined;
    children.forEach((child) => {
      const position = child.stateNode?.position;
      if (position) {
        minLeft = minLeft === undefined ? position.x : Math.min(minLeft, position.x);
        minTop = minTop === undefined ? position.y : Math.min(minTop, position.y);
      }
    });
    return { left: minLeft || 0, top: minTop || 0 };
  }

  /**
   * 根据原组件位置调整新组件位置
   * @param compData
   */
  private resetRootCompPosition(compData: IComponentData) {
    const spaceX = this.compSize.width - (this.svgSize.width || 0);
    const spaceY = this.compSize.height - (this.svgSize.height || 0);
    const position = this.getOriginPosition();
    compData.position = {
      x: spaceX / 2 + position.left,
      y: spaceY / 2 + position.top,
    };
  }

  /**
   * svg文件转为paper.Item
   * @param project
   * @param data
   */
  private loadSvgDom(project: paper.Project, data: string): Promise<paper.Group> {
    return new Promise((resolve, reject) => {
      project.importSVG(data, {
        expandShapes: true,
        onLoad: resolve,
        onError: reject,
      });
    });
  }

  public async parseSvg(isSync = false): Promise<IComponentData | undefined> {
    if (!paper.project) {
      return undefined;
    }
    const { applyMatrix, insertItems } = paper.settings;
    try {
      const data = await this.initialParse();
      if (!data) {
        return undefined;
      }
      const root = await this.loadSvgDom(paper.project, data.data);
      const group = this.scaleRootGroup(root, data.size);

      this.strokeScale = this.calcStrokeScale(root);
      const stateNode = await this.performRoot(group, isSync);
      if (stateNode) {
        this.resetRootCompPosition(stateNode);
        mergeOriginalCompProperties(stateNode, this.originalComp);
      }
      this.rootNode = null;

      return stateNode;
    } catch (error) {
      console.warn(error);
      // 解析错误，paper库未初始化paper.setting，手动修改
      paper.settings.applyMatrix = applyMatrix;
      paper.settings.insertItems = insertItems;
      throw new Error(error);
    }
  }

  /**
   * 取消解析
   */
  public abort() {
    this.isCancel = true;
  }
}

export default ParseSvg;
