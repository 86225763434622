import { ErrorInfo } from 'react';
import * as React from 'react';

interface IErrorHandlerProps {
  children: React.ReactChild;
}

interface IErrorHandlerState {
  hasError: boolean;
}

class ErrorHandler extends React.Component<IErrorHandlerProps, IErrorHandlerState> {
  state = { hasError: false };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) return <p>Something went wrong!</p>;
    return this.props.children;
  }
}
export default ErrorHandler;
