import { escapeString, mimeType, resolveUrl } from '../util';
import { dataAsUrl, isDataUrl } from '../util/imageUtil';
import { isHttpUrl, getAndEncode } from '../util/httpUtil';

export const inliner = newInliner();
export function newInliner() {
  let URL_REGEX = /url\(['"]?([^'"]+?)['"]?\)/g;

  return {
    inlineAll: inlineAll,
    shouldProcess: shouldProcess,
    impl: {
      readUrls: readUrls,
      inline: inline,
    },
  };

  function shouldProcess(string: string) {
    return string.search(URL_REGEX) !== -1;
  }

  function readUrls(string: string): string[] {
    const result = [];
    let match;
    while ((match = URL_REGEX.exec(string)) !== null) {
      result.push(match[1]);
    }
    const list = result.filter(function (url) {
      return !isDataUrl(url);
    });
    // 只取一个地址
    return list.length > 1 ? list.slice(0, 1) : list;
  }

  function inline(string: string, url: string, baseUrl: string | undefined) {
    return Promise.resolve(url)
      .then((url) => {
        const newUrl = baseUrl ? resolveUrl(url, baseUrl) : isHttpUrl(url) ? url : resolveUrl(url, location.origin);
        return newUrl;
      })
      .then(getAndEncode)
      .then((data) => {
        return dataAsUrl(data, mimeType(url));
      })
      .then((dataUrl) => {
        return string.replace(urlAsRegex(url), '$1' + dataUrl + '$3');
      })
      .catch((e) => {
        console.log(e);
        return '';
      });

    function urlAsRegex(url: string) {
      return new RegExp('(url\\([\'"]?)(' + escapeString(url) + ')([\'"]?\\))', 'g');
    }
  }

  function inlineAll(string: string, baseUrl?: string): Promise<string> {
    if (nothingToInline()) return Promise.resolve(string);
    return Promise.resolve(string)
      .then(readUrls)
      .then((urls) => {
        let done = Promise.resolve(string);
        urls.forEach((url) => {
          done = done.then((string) => {
            return inline(string, url, baseUrl);
          });
        });
        return done;
      });

    function nothingToInline() {
      return !shouldProcess(string);
    }
  }
}
