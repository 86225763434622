import * as React from 'react';
import classnames from 'classnames';

import { UITheme } from '../../../models';
import { IPagerSelectAction } from '../../../models/pager';
import i18n from '../../../i18n';

import MatchStrSpan from '../../MatchStrSpan';
import Checkbox from '../../Checkbox';
import Menu from '../../Menu';

import './index.scss';

interface ITotalProps {
  text?: string;
  total: number;
  theme: UITheme;
  showTotal: boolean | React.ReactNode;
  showSelect?: boolean;

  selectActionText?: string;
  checked?: (string | number)[];
  currentPageCount?: number;
  selectActions?: IPagerSelectAction[];
  showActionsAsMenu?: boolean;
  checkAllCount?: number;
  containerRef: React.RefObject<HTMLUListElement>;

  onCheckAll?(isChecked: boolean): void;
  onCheckAction?(id?: string | number): void;
}

const Total: React.FC<ITotalProps> = (props: ITotalProps) => {
  const {
    total,
    text,
    theme,
    showTotal,
    showSelect,
    selectActionText,
    checked,
    currentPageCount,
    selectActions,
    showActionsAsMenu,
    checkAllCount,
    containerRef,
    onCheckAll,
    onCheckAction,
  } = props;

  const [showActionMenu, setShowActionMenu] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const [hideTotalCount, setHideTotalCount] = React.useState<boolean>(false);

  React.useEffect(() => {
    handleResizeWidth();
    window.addEventListener('resize', handleResizeWidth);
    return () => {
      window.removeEventListener('resize', handleResizeWidth);
    };
  }, []);

  const handleResizeWidth = () => {
    const totalBounding = ref.current?.getBoundingClientRect();
    const containerLeft = containerRef.current?.getBoundingClientRect().left || 0;

    if (containerLeft && totalBounding) {
      setHideTotalCount(containerLeft - totalBounding.left < totalBounding.width);
    }
  };

  const handleCheckAll = (isChecked: boolean) => {
    onCheckAll && onCheckAll(isChecked);
  };

  const handleCheckAction = (id?: number | string) => {
    onCheckAction && onCheckAction(id);
  };

  const renderActions = () => {
    if (selectActionText) {
      return (
        <span className="action-btn">
          <span className="dsm-c-pagination-split-line"></span>
          <span className="link-b" onClick={() => handleCheckAction()}>
            {selectActionText}
          </span>
        </span>
      );
    }
    if (selectActions && selectActions.length > 0) {
      if (showActionsAsMenu) {
        return (
          <span className="action-btn-container">
            <span className="dsm-c-pagination-split-line" />
            <span className="btn-container">
              <span className="link-b" onClick={() => setShowActionMenu(true)}>
                {i18n('pager.batchOperate')}
              </span>
              {showActionMenu && (
                <Menu
                  minWidth={150}
                  options={selectActions.map((item) => ({ ...item, disable: item.disabled }))}
                  onClose={() => setShowActionMenu(false)}
                  onSelect={handleCheckAction}
                />
              )}
            </span>
            <span
              className={classnames('dsm-c-pagination-split-line', {
                overflowHide: hideTotalCount,
              })}
            />
          </span>
        );
      }

      return (
        <span className="action-btn-container">
          <span className="dsm-c-pagination-split-line"></span>
          <span className="btn-container">
            {selectActions.map((action, index) => (
              <span
                key={index}
                className={classnames('link-b', {
                  disabled: action.disabled,
                })}
                onClick={() => {
                  !action.disabled && handleCheckAction(action.id);
                }}
              >
                {action.text}
              </span>
            ))}
          </span>
        </span>
      );
    }
    return null;
  };

  const renderDefaultTotal = () => {
    if (!showSelect) {
      return <MatchStrSpan theme={theme} str={text || i18n('pager.total', total)} targetStr={`${total}`} />;
    }
    if (!checked) {
      return null;
    }

    return (
      <>
        <Checkbox
          checked={checked.length > 0}
          indeterminate={checked.length < (checkAllCount || currentPageCount || 0)}
          text={i18n('pager.checkAll')}
          fontSize={12}
          onChange={handleCheckAll}
        />
        {checked.length > 0 && renderActions()}
        <div
          className={classnames('total', {
            'show-action-menu': checked.length > 0 && selectActions && selectActions.length > 0 && showActionsAsMenu,
            overflowHide: hideTotalCount,
          })}
        >
          <MatchStrSpan theme={theme} str={text || i18n('pager.total', total)} targetStr={`${total}`} />
          <MatchStrSpan theme={theme} str={i18n('pager.selected', checked.length)} targetStr={`${checked.length}`} />
        </div>
      </>
    );
  };

  if (typeof showTotal === 'boolean') {
    if (showTotal) {
      return (
        <div className="dsm-c-pagination-total" ref={ref}>
          {renderDefaultTotal()}
        </div>
      );
    }
    return null;
  }

  if (React.isValidElement(showTotal)) {
    return (
      <div className="dsm-c-pagination-total" ref={ref}>
        {showTotal}
      </div>
    );
  }
  return null;
};

Total.defaultProps = {
  text: '',
  checked: [],
};

export default Total;
