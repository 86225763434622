import { IUserInfo } from '@/fbs/idoc/models/user';
import { SessionInfo } from '@fbs/rp/models/io';
import { getRandomSeed } from './idHelper';

let currentAppID: string = '';
let currentSessionID: string = '';
let currentCoopers: SessionInfo[] = [];

export function setSessionID(appID: string, sessionID: string) {
  currentAppID = appID;
  currentSessionID = sessionID;
}

export function getSessionInfo(): {
  appID: string;
  sessionID: string;
} {
  return {
    appID: currentAppID,
    sessionID: currentSessionID,
  };
}

export function setCoopers(coopers: SessionInfo[]) {
  currentCoopers = coopers;
}

export function getCoopers(): SessionInfo[] {
  return currentCoopers;
}

export function getDefaultSession(): SessionInfo {
  return {
    id: getRandomSeed(),
    avatar: '',
    createdAt: new Date(),
    name: 'Me',
    nickName: '',
    userID: -1,
    IP: '',
    UA: window.navigator.userAgent,
  };
}

export function getSessionByUserInfo(userInfo: IUserInfo): SessionInfo {
  const { name, avatar, addTime, id } = userInfo;
  return {
    id: getRandomSeed(),
    avatar,
    createdAt: addTime,
    name,
    nickName: '',
    userID: id,
    IP: '',
    UA: window.navigator.userAgent,
  };
}

class SessionOptions {
  private state: { [key: string]: any };

  constructor() {
    this.state = JSON.parse(window.sessionStorage.getItem('mockSession') || '{}');
  }

  private getValue<T>(value: string, defaultValue: T): T {
    return this.state[value] || defaultValue;
  }

  private saveValue<T>(key: string, value: T) {
    this.state[key] = value;
    window.sessionStorage.setItem('mockSession', JSON.stringify(this.state));
  }

  public get convertCanvasPanelCount(): number {
    return this.getValue('convertCanvasPanelCount', 1);
  }

  public set convertCanvasPanelCount(value: number) {
    this.saveValue('convertCanvasPanelCount', value);
  }
}

export const sessionOptions = new SessionOptions();

export default SessionOptions;
