import i18n from '@/i18n';
import { SpriteThumb } from '@/consts/spriteIcons';

import { updateSnapshotValue } from '../basic/Snapshot';
import { CSnapshot } from '../constants';
import { IComponentItem } from '../types';

export const SnapshotCfg: IComponentItem = {
  hidden: !(RP_CONFIGS.isPrivateDeployment && RP_CONFIGS.isHuaWei),
  type: CSnapshot,
  name: i18n('resource.components.snapshot'),
  thumb: {
    spriteIconClass: SpriteThumb.Snapshot.className,
    dragPosition: SpriteThumb.Snapshot.position,
  },
  editor: {
    onValueUpdate: updateSnapshotValue,
  },
};
