import * as React from 'react';
import * as ReactDom from 'react-dom';
import Dialog from '../Dialog';
import Input from '../Input';

export interface IDialogWithInputProp {
  title?: string;
  width?: string;
  value?: string;
  showCancel?: boolean;
  placeholder?: string;
  errorMsg?: string;
  showClose?: boolean;
  // 光标是否聚焦后缀名的前面停留
  focusSuffix?: boolean;
  maxLength?: number;
  theme?: 'blue';

  onOk(value: string): void;

  onCancel(): void;

  onChange(value: string): void;
}

class DialogWithInput extends React.Component<IDialogWithInputProp, { value: string }> {
  static defaultProps: Partial<IDialogWithInputProp> = {
    width: '100%',
    showCancel: true,
    placeholder: '',
    showClose: false,
    focusSuffix: false,
  };

  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: IDialogWithInputProp) {
    super(props);
    this.state = {
      value: props.value || '',
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    // FIXME Vincent: 这个地方用timeout 有点不合理，需要优化机制对策
    setTimeout(() => {
      if (!this.inputRef.current) {
        return;
      }
      this.inputRef.current.focus();
      this.inputRef.current.select();
      const value = this.inputRef.current.value;
      let start = value.length;
      if (this.props.focusSuffix) {
        const index = value.lastIndexOf('.');
        if (index !== -1) {
          start = index;
        }
      }
      this.inputRef.current.selectionStart = 0;
      this.inputRef.current.selectionEnd = start;
    }, 100);
  }

  onInputChange(value: string) {
    const { onChange } = this.props;
    this.setState({
      value,
    });
    onChange(value);
  }

  onSubmit() {
    const { onOk, errorMsg } = this.props;
    const { value } = this.state;
    if (!errorMsg) {
      onOk(value);
    }
  }

  render() {
    const { width, showCancel, onCancel, placeholder, errorMsg, title, showClose, maxLength, theme } = this.props;
    const { value } = this.state;
    return ReactDom.createPortal(
      <div
        onMouseDown={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <Dialog
          title={title}
          showCancel={showCancel}
          theme={theme}
          onOk={this.onSubmit}
          onCancel={onCancel}
          showClose={showClose}
          disableOk={!value.trim() || !!errorMsg}
        >
          <div>
            <Input
              setInputRef={this.inputRef}
              width={width}
              placeholder={placeholder}
              onChange={this.onInputChange}
              onSubmit={this.onSubmit}
              value={value}
              error={errorMsg}
              maxLength={maxLength}
            />
          </div>
        </Dialog>
      </div>,
      document.body,
    );
  }
}

export default DialogWithInput;
