import { PureColor } from './properties/color';
import { TextAlign, VerticalAlign } from './properties/text';
import IStroke from './properties/stroke';
import ITextFormatEx from './properties/textFormat';

// 单元格类型
export enum CellTypes {
  Text = 'text',
  CheckBox = 'checkBox',
  Radio = 'radio',
  Button = 'button',
}

// 单元格样式
export type ICellStyle = {
  textAlign: TextAlign;
  verticalAlign: VerticalAlign;
  fill: PureColor;
  border: CellBorder;
  wrap: boolean;
  // 表格预设文本样式
  textFormat?: Partial<ITextFormatEx>;
};

// 单元格边框
export type CellBorder = {
  top: IStroke;
  bottom: IStroke;
  left: IStroke;
  right: IStroke;
};

// 表格列
export type ITableColumn = {
  type: CellTypes;
  style: ICellStyle;
  data: ICellData;
  dataSource: string[];
  width: number;
  mergeAcross: number;
  mergeDown: number;
  mergedBy?: [number, number];
};

// 表格行
export type ITableRow = {
  height: number;
  selected: boolean;
  fixedHeight?: boolean;
};

// 单元格数据
export type ICellData = {
  text: string;
  [key: string]: any;
};

// 单元格
export type ITableCell = {
  type: CellTypes;
  data: ICellData;
  dataSource: string[];
  style: ICellStyle;
  mergeAcross: number;
  mergeDown: number;
  mergedBy?: [number, number];
};

// 表格组件value
export interface ITableValue {
  showHeader: boolean; //是否显示表头
  headerHeight: number; //表头高度
  columns: ITableColumn[]; //列数据
  rows: ITableRow[]; //行数据
  cells: ITableCell[][]; //单元格数据
}
