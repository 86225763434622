import * as React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'util';
import { languageManager } from '@/i18n';

import { isMac } from '../utils';

import './index.scss';

export interface ICheckboxProps {
  id?: number | string;
  disabled?: boolean;
  checked?: boolean;
  text?: string;
  className?: string;
  theme?: 'normal' | 'light';
  showChoseIconWhenUnChecked?: boolean;
  indeterminate?: boolean;
  onChange?: (value: boolean) => void;
}

class CheckBox extends React.PureComponent<ICheckboxProps, {}> {
  static defaultProps: Partial<ICheckboxProps> = {
    text: '',
  };

  constructor(props: ICheckboxProps) {
    super(props);

    this.check = this.check.bind(this);
  }

  check() {
    const { onChange, disabled } = this.props;
    const newState = !this.props.checked;
    if (disabled) {
      return;
    }
    onChange && onChange(newState);
  }

  render() {
    const { disabled, checked, text, className, theme, showChoseIconWhenUnChecked, indeterminate } = this.props;
    return (
      <div
        className={classnames(
          `dsm-c-rp-checkbox ${className || ''}`,
          { 'disabled-checkbox': disabled },
          { light: theme === 'light' },
        )}
        onClick={this.check}
      >
        <span
          className={classnames('dsm-c-rp-checkbox-viewer', {
            'checkbox-checked': checked,
            'checkbox-undefined': isUndefined(checked) && !showChoseIconWhenUnChecked,
            showChoseIconWhenUnChecked: !checked && showChoseIconWhenUnChecked,
            indeterminate,
          })}
        />
        {text && (
          <span className={classnames('dsm-c-rp-checkbox-text', { win: !isMac, en: languageManager.isEnVersion })}>
            {text}
          </span>
        )}
      </div>
    );
  }
}

export default CheckBox;
