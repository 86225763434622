import { WatermarkOptions } from '../models/watermark';

export const drawCanvasWm = (data: WatermarkOptions) => {
  const {
    container = document.body,
    width = 240,
    height = 120,
    textAlign = 'center',
    textBaseline = 'middle',
    fontSize = 13,
    fillStyle = 'rgba(0, 0, 0, .1)',
    content = '',
    rotate = '16',
    zIndex = 1000,
  } = data;

  const _rotate = -(Math.PI / 180) * Number(rotate);

  let canvas = document.createElement('canvas');

  canvas.setAttribute('width', `${width * 2}px`);
  canvas.setAttribute('height', `${height * 2}px`);
  let ctx = canvas.getContext('2d');
  if (ctx && container) {
    ctx.textAlign = textAlign;
    ctx.textBaseline = textBaseline;
    ctx.font = `${fontSize}px`;
    ctx.fillStyle = fillStyle;
    const { width: textWidth } = ctx.measureText(content);
    ctx.save();
    ctx.translate((width - textWidth) / 2, height / 2);
    ctx.rotate(_rotate);
    ctx.fillText(content, textWidth / 2, fontSize / 2);
    ctx.restore();
    ctx.translate(width + (width - textWidth) / 2, height / 2);
    ctx.rotate(_rotate);
    ctx.fillText(content, textWidth / 2, fontSize / 2);

    const bgMark = canvas.toDataURL();
    const __wm = document.querySelector('.__wm');

    const watermarkDiv = __wm || document.createElement('div');
    watermarkDiv.classList.add('__wm');

    const styleStr = `
      position: absolute;
      top: 0;
      left: 0;
      width: ${window.innerWidth + width}px;
      height: ${window.innerHeight + height}px;
      z-index: ${zIndex};
      pointer-events: none;
      background:  url('${bgMark}') 0 0 repeat, url('${bgMark}') ${-width / 2}px ${height}px repeat;
    `;

    watermarkDiv.setAttribute('style', styleStr);

    watermarkDiv.classList.add('__wm');

    if (!__wm) {
      container.insertBefore(watermarkDiv, container.firstChild);
    }

    // @ts-ignore
    const MutationObserver = window.MutationObserver || window.WebkitMutationObserver;

    if (MutationObserver) {
      let mo: MutationObserver | null = new MutationObserver(() => {
        const __wm = document.querySelector('.__wm');

        if (((__wm && __wm.getAttribute('style') !== styleStr) || !__wm) && mo) {
          mo.disconnect();
          mo = null;
          drawCanvasWm({ ...data });
        }
      });

      mo.observe(container, {
        attributes: true,
        subtree: true,
        childList: true,
      });
    }
  }
};
