import * as React from 'react';
import { InputModel } from '@fbs/rp/models/properties/inputModel';
import { stopBubbleWhenSortCut } from '@helpers/shortCutHelper';
import { validateNumberText } from '@utils/globalUtils';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';

export interface IInputEditorProps {
  value: string;
  width?: number;
  style: React.CSSProperties;
  className: string;
  inputModel: InputModel;
  ref?: React.MutableRefObject<HTMLDivElement | null>;
  onChange: (value: string) => void;
  onMouseUp?: () => void;
  onMouseDown?: () => void;
  onDragEnd?: () => void;
}

interface IInputTextEditorState {
  value: string;
}

export default class InputEditor extends React.Component<IInputEditorProps, IInputTextEditorState> {
  private dom: React.RefObject<HTMLInputElement> = React.createRef();

  constructor(props: IInputEditorProps) {
    super(props);
    this.state = { value: props.value };
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.handleWindowMouseDown, true);
    window.addEventListener('mouseup', this.handleWindowMouseUp, true);
    document.onvisibilitychange = this.handlePageVisibleChanged;
    this.dom.current!.focus();
    this.dom.current!.select();
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleWindowMouseDown, true);
    window.removeEventListener('mouseup', this.handleWindowMouseUp, true);
    document.onvisibilitychange = null;
  }

  handlePageVisibleChanged = () => {
    if (document.visibilityState === 'hidden') {
      this.doSubmit();
    }
  };

  handleWindowMouseUp = () => {
    this.props.onMouseUp?.();
  };

  handleWindowMouseDown = (e: MouseEvent) => {
    const self = this.dom.current!;
    if (self.contains(e.target as HTMLElement)) {
      return;
    }
    let dom = document.querySelector('.right-panel');
    if (dom && dom.contains(e.target as HTMLElement)) {
      return;
    }
    dom = document.querySelector('.popup-with-body');
    if (dom && dom.contains(e.target as HTMLElement)) {
      return;
    }
    this.doSubmit();
  };

  handleMouseUp = (e: React.MouseEvent) => {
    this.props.onMouseUp?.();
    e.stopPropagation();
  };

  handleMouseDown = () => {
    this.props.onMouseDown?.();
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    // @ts-ignore
    stopBubbleWhenSortCut(e);
    if (e.keyCode === KeyCodeMap.VK_ENTER || e.keyCode === KeyCodeMap.VK_ESCAPE) {
      this.doSubmit();
    }
  };

  handleInputChange = (e: React.FormEvent) => {
    let value = (e.target as HTMLInputElement).value;
    const { inputModel } = this.props;
    if (inputModel === InputModel.numeric) {
      value = validateNumberText(value, this.state.value);
    }
    this.setState({ value });
  };

  doSubmit = () => {
    const { value } = this.state;
    const { onChange } = this.props;
    onChange(value);
  };

  render() {
    const { value } = this.state;
    const { style, className, width } = this.props;
    return (
      <input
        ref={this.dom}
        style={style}
        className={className}
        value={value}
        maxLength={100}
        width={width}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleInputChange}
        onBlur={this.handleInputChange}
        onMouseUp={this.handleMouseUp}
        onMouseDown={this.handleMouseDown}
        onDragEnd={this.handleMouseUp}
        onContextMenu={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      />
    );
  }
}
