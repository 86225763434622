import * as React from 'react';
import { UIComponent } from '@/editor/comps';
import * as audioSvg from './audio.svg';
import { renderComponentWrapper } from './renderComponentWrapper';

export const renderAudio = (comp: UIComponent, scale: number = 1): JSX.Element => {
  // const { id } = comp;
  // const style = comp.getWrapperStyle({ x: 0, y: 0 }, { x: scale, y: scale }, scale, false);
  // 兼容fireforx
  // style.background = `url('${audioSvg}') no-repeat`
  //  background-image在webkit浏览器中不兼容
  // style.backgroundImage = `url(${audioSvg})`;
  // style.backgroundSize = 'contain';
  // style.backgroundRepeat = 'no-repeat';
  // style.objectFit = 'contain'
  // console.log(style)
  // return <div key={id} id={id} style={style} className='component'></div>
  const imgStyle: React.CSSProperties = {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  };
  const content = (
    <>
      <img src={audioSvg} style={imgStyle} />
    </>
  );
  // return (
  //   <div key={id} id={id} style={style} className="component">
  //     <img src={audioSvg} style={imgStyle} />
  //   </div>
  // );

  return renderComponentWrapper({ comp, scale }, content);
};
