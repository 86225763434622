import * as React from 'react';
import * as ReactDnD from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import * as dnd from '@consts/dnd';
import classnames from 'classnames';

import { transBlankChart } from '@/utils/textUtils';

import KeyCodeMap from '@/dsm2/constants/KeyCodeMap';
import { DropMode, ITreeItem, isParentDrag } from '@/helpers/treeCompHelper';
import { UIContainerComponent, UITreeItemComponent } from '@/editor/comps';
import { Radio, Icon, Input, CheckBox } from '@/dsm';
import { IconValue } from '@/fbs/rp/models/value';

import IconRender from '../../withReplaceIcon/IconRender';

import './index.scss';

interface ITreeEditorItemProps {
  item: ITreeItem;
  level: number;
  itemComp: UITreeItemComponent;
  selected: boolean;
  autoStartEditor: boolean;
  draggable: boolean;
  showSelectBox: boolean;
  showNodeIcon: boolean;
  nodeIconSelected?: boolean;
  checked?: boolean;
  onInteractionDragger: (e: React.MouseEvent, selectedID: string) => void;
  onEditNext: (itemComp: UITreeItemComponent, offset: number, canAdd?: boolean) => void;
  onChangeValue: (itemComp: UITreeItemComponent, text: string) => void;
  onExpandClick: (id: string) => void;
  onSelectClick: (id: string) => void;
  onFontIconClick: (e: React.MouseEvent, id: string) => void;
  onItemClick?: (selectedID: string) => void;
  onItemDoubleClick?: (selectedID: string) => void;
  onItemChecked?: (itemComp: UITreeItemComponent, checked: boolean) => void;
  onMouseEnter?: (id: string) => void;
}

interface IDragSourceProp {
  isDragging?: boolean;
  connectDragSource: ReactDnD.ConnectDragSource;
  connectDragPreview: ReactDnD.ConnectDragPreview;
}

export interface IDropTargetProp {
  isOver?: boolean;
  connectDropTarget: ReactDnD.ConnectDropTarget;
}

export interface IDragTreeItemProps extends IDragSourceProp, IDropTargetProp, ITreeEditorItemProps {
  onDropEnd?: (dragItemID: string, dropItemID: string, dropMode: DropMode) => void;
}

interface IDragTreeItemState {
  dropMode: DropMode;
  editing?: boolean;
  text?: string;
}

export class DragTreeItem extends React.Component<IDragTreeItemProps, IDragTreeItemState> {
  dragDom: React.RefObject<HTMLLIElement> = React.createRef();

  constructor(props: IDragTreeItemProps) {
    super(props);
    this.state = {
      dropMode: DropMode.None,
      editing: props.autoStartEditor,
      text: props.itemComp.getText(),
    };
  }

  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage());
  }

  UNSAFE_componentWillReceiveProps(nextProps: IDragTreeItemProps) {
    if (nextProps.autoStartEditor && this.props.autoStartEditor !== nextProps.autoStartEditor) {
      this.setState({ editing: true, text: nextProps.itemComp.getText() });
    }
  }

  handleInteractionDraggerMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const { onInteractionDragger, item } = this.props;
    onInteractionDragger && onInteractionDragger(e, item.data.id);
  };

  handleStartEditing = (e: React.MouseEvent) => {
    e.stopPropagation();
    // this.setState({ editing: true });
    const { onItemDoubleClick, itemComp } = this.props;
    onItemDoubleClick && onItemDoubleClick(itemComp.id);
  };

  handleInputEnter = (value: string) => {
    this.setState({ editing: false });
    if (value.trim()) {
      const { onEditNext, itemComp } = this.props;
      onEditNext(itemComp, 1, true);
    }
  };

  handleInputBlur = (value: string) => {
    const { onChangeValue, itemComp } = this.props;
    onChangeValue(itemComp, value);
    this.setState({ editing: false, text: undefined });
  };

  handleInputChange = (value: string) => {
    this.setState({ text: value });
  };

  handleInputKeyDown = (e: React.KeyboardEvent) => {
    const { onEditNext, itemComp } = this.props;
    if (e.keyCode === KeyCodeMap.VK_DOWN) {
      onEditNext(itemComp, 1);
    } else if (e.keyCode === KeyCodeMap.VK_UP) {
      onEditNext(itemComp, -1);
    }
  };

  handleExpandclick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { onExpandClick, item } = this.props;
    item.children?.length && onExpandClick(item.data.id);
  };

  handleSelectClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { onSelectClick, item } = this.props;
    onSelectClick(item.data.id);
  };

  handleSelectCheckbox = (checked: boolean) => {
    let { onItemChecked } = this.props;
    if (onItemChecked) {
      onItemChecked(this.props.itemComp, checked);
    }
  };

  renderText(text: string) {
    const { editing } = this.state;
    if (editing) {
      return (
        <Input
          autoFocus
          autoSelectWhenFocus
          submitWithBlur
          value={text}
          maxlength={100}
          onEnter={this.handleInputEnter}
          onBlur={this.handleInputBlur}
          onChange={this.handleInputChange}
          onKeyDown={this.handleInputKeyDown}
        />
      );
    } else {
      return (
        <label onDoubleClick={this.handleStartEditing} className="tree-node-text">
          {transBlankChart(text)}
        </label>
      );
    }
  }

  renderDragger() {
    // const { editing } = this.state;
    // if (editing) {
    //   return;
    // }
    return <div className="interaction-dragger" onMouseDown={this.handleInteractionDraggerMouseDown} />;
  }

  renderNodeIcon = (itemComp: UIContainerComponent) => {
    const { item, nodeIconSelected, showNodeIcon, onFontIconClick } = this.props;
    if (!showNodeIcon) {
      return null;
    }

    const nodeComp = itemComp.getComponentByAlias('nodeIcon');
    if (!nodeComp) {
      return null;
    }

    const { value } = nodeComp;
    const icon = value as IconValue;

    return (
      <IconRender
        className="item-node-icon"
        iconValue={icon}
        selected={!!nodeIconSelected}
        onFontIconClick={onFontIconClick}
        compId={item.data.id}
      />
    );
  };

  renderMain() {
    const { itemComp, item, onItemClick, isOver, selected, level, checked, showSelectBox, onMouseEnter } = this.props;
    const { dropMode } = this.state;
    const text = itemComp.getText();
    const listWidth = 165;
    const hasInteraction = Object.keys(itemComp.interactions).length > 0;
    return (
      <div
        className={classnames('tree-editor-drag-item', {
          [`tree-node-drop-at-${dropMode}`]: isOver && dropMode !== DropMode.None,
          interaction: hasInteraction,
        })}
        onClick={onItemClick?.bind(this, item.data.id)}
        onMouseEnter={onMouseEnter?.bind(this, item.data.id)}
      >
        <div className={classnames('list-tree-item', { selected })} style={{ maxWidth: listWidth - 16 * (level - 1) }}>
          <div className="item-icon" onClick={this.handleExpandclick}>
            {item.children?.length && <Icon size={16} cls={item.data.expand ? 'tag_downarrow' : 'Right'} />}
          </div>
          {showSelectBox && (
            <CheckBox
              onChange={this.handleSelectCheckbox}
              checked={checked}
              className="list-tree-item-checkbox"
            ></CheckBox>
          )}
          {this.renderNodeIcon(itemComp)}
          {this.renderText(text)}
        </div>
      </div>
    );
  }

  render() {
    const { children, itemComp, selected, level } = this.props;
    const { connectDragSource, connectDropTarget } = this.props;
    const intention = 16;
    const indent = 32; //缩进
    const paddingLeft = level === 0 ? indent : intention;
    return connectDropTarget(
      connectDragSource(
        <li className="list-tree-li" ref={this.dragDom}>
          <div
            className="list-tree-select"
            style={{ left: -16 * (level - 1) - (level === 0 ? 8 : 24) }}
            onClick={this.handleSelectClick}
          >
            <Radio checked={!!itemComp.selected} />
          </div>
          <div className="list-tree-action">{this.renderDragger()}</div>
          <div
            className={classnames('list-tree-bg', { selected: selected })}
            style={{ marginLeft: -indent - 16 * level, width: `calc(100% + ${indent + 16 * level}px)` }}
          />
          <div style={{ paddingLeft }}>
            {this.renderMain()}
            <div className="tree-editor-children">
              <ul>{children}</ul>
            </div>
          </div>
        </li>,
      ),
    );
  }
}

interface DragObject {
  data: ITreeItem;
  type: Symbol;
}

interface DropResult {
  data: ITreeItem;
  dropMode: DropMode;
}

export const dragSpec: ReactDnD.DragSourceSpec<IDragSourceProp, DragObject> = {
  // eslint-disable-next-line no-unused-vars
  canDrag: (props: IDragSourceProp, monitor: ReactDnD.DragSourceMonitor) => {
    return (props as IDragTreeItemProps).draggable;
  },
  // eslint-disable-next-line no-unused-vars
  beginDrag: (props: IDragSourceProp, monitor: ReactDnD.DragSourceMonitor) => {
    return { data: (props as IDragTreeItemProps).item, type: dnd.COMP_TREE_NODE };
  },
  endDrag: (props: IDragSourceProp, monitor: ReactDnD.DragSourceMonitor, component: DragTreeItem) => {
    if (!monitor.didDrop()) {
      return;
    }
    const dragItem: ITreeItem = monitor.getItem().data;
    if (!dragItem) {
      return;
    }
    const dropResult: DropResult = monitor.getDropResult();
    const { data: dropItem, dropMode } = dropResult;
    if (dragItem.data.id === dropItem.data.id) {
      return;
    }
    component.props.onDropEnd && component.props.onDropEnd(dragItem.data.id, dropItem.data.id, dropMode);
  },
};

export const dropSpec: ReactDnD.DropTargetSpec<IDropTargetProp> = {
  canDrop: (props: IDropTargetProp, monitor: ReactDnD.DropTargetMonitor) => {
    const dragItem: ITreeItem = monitor.getItem().data;
    const dropItem: ITreeItem = (props as IDragTreeItemProps).item;
    // 子级不能作为放置点
    if (isParentDrag(dragItem, dropItem)) {
      return false;
    }
    if (dragItem.data.id === dropItem.data.id) {
      return false;
    }

    if (!monitor.isOver({ shallow: true })) {
      return false;
    }
    return true;
  },

  drop: (props: IDropTargetProp, monitor: ReactDnD.DropTargetMonitor, component: any) => {
    const dropItem: ITreeItem = (props as IDragTreeItemProps).item;
    const mode = component.decoratedRef.current.state.dropMode;
    return {
      data: dropItem,
      dropMode: mode,
    };
  },

  hover: (props: IDropTargetProp, monitor: ReactDnD.DropTargetMonitor, component: any) => {
    if (!monitor.canDrop()) {
      return;
    }

    if (!monitor.isOver()) {
      return;
    }

    const intention = 16;
    const dragDomBounds = component.dragDom.current?.getBoundingClientRect();
    const hoverOffset = monitor.getClientOffset();
    const siblingLeft = intention + 32;
    if (dragDomBounds && hoverOffset && hoverOffset.x > dragDomBounds.left + siblingLeft) {
      component.setState({ dropMode: DropMode.Child });
      return;
    }
    if (dragDomBounds && hoverOffset && hoverOffset.y < dragDomBounds.top + 30 / 2) {
      component.setState({ dropMode: DropMode.Before });
    } else {
      component.setState({ dropMode: DropMode.After });
    }
  },
};

export function dragCollect(
  connect: ReactDnD.DragSourceConnector,
  monitor: ReactDnD.DragSourceMonitor,
): IDragSourceProp {
  return {
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
  };
}

export function dropCollect(
  connect: ReactDnD.DropTargetConnector,
  monitor: ReactDnD.DropTargetMonitor,
): IDropTargetProp {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
  };
}

const DragTarget = ReactDnD.DragSource(dnd.COMP_TREE_NODE, dragSpec, dragCollect)(DragTreeItem);
export default ReactDnD.DropTarget([dnd.COMP_TREE_NODE], dropSpec, dropCollect)(DragTarget);
