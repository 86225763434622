import * as React from 'react';

import './index.scss';
import Checkbox from '../Checkbox';

interface CheckboxModel {
  id: number | string;
  checked: boolean;
  text: string;
}

export interface ICheckboxGroupProps {
  data: Array<CheckboxModel>;

  onItemChange(id: string | number, checked: boolean): void;
}

// TODO: 子项的布局方式需要再处理一下，要根据我们的应用场景来考虑
class CheckboxGroup extends React.Component<ICheckboxGroupProps, {}> {
  render() {
    return (
      <div className="dsm-c-checkbox-group">
        {this.props.data.map((data) => (
          <Checkbox
            key={data.id}
            checked={data.checked}
            text={data.text}
            onChange={(checked: boolean) => {
              this.props.onItemChange(data.id, checked);
            }}
          />
        ))}
      </div>
    );
  }
}

export default CheckboxGroup;
