import { replaceHtml } from '../util/replaceUtil';

/**
 * 精简html
 * 去掉无用样式
 * @param html
 */
export const compressHtml = (html: string) => {
  // 行内样式
  const inlineStyle: string[] = [];
  // inlineStyle.push('transform:translate3d\\(0px,\\s*?0px,\\s*?0\\);');
  inlineStyle.push('transform:scale\\(1, 1\\);');
  inlineStyle.push('pointer-events:none;');
  inlineStyle.push('transition:\\s*?unset;?');
  inlineStyle.push('filter:;');
  inlineStyle.push('opacity:1;');
  inlineStyle.push('locked-ratio:;');
  inlineStyle.push('transform:translate\\(0px, 0px\\)\\s*?rotate\\(0deg\\);');

  const inDiv: string[] = [];
  // 去掉data-xxx属性
  inDiv.push('data-[a-z]*?="[^<>]*?"');
  // 去掉id属性
  inDiv.push('id="[^<>]*?"');

  return replaceHtml(html, {
    inlineStyle,
    inDiv,
  });
};

/**
 * 处理可能导致错误的特殊字符
错误提示：PCDATA invalid Char
utf8_for_xml
illegal characters for XML parsing
ascii 值 8   String.fromCharCode(8) \b
 * @param html 
 */
export const stripInvalidChar = (html: string): string => {
  return html.replace(/[\b\t]/g, '');
};

/**
 * 精简style
 * 去掉无用style
 * @param style
 */
export const compressStyle = (style: string) => {
  const inStyle: string[] = [];
  inStyle.push('pointer-events:[^:;{}]*?;');
  inStyle.push('cursor:[^:;{}]*?;');
  inStyle.push('user-select:[^:;{}]*?;');
  inStyle.push('transition[-a-z]*?:[^:;{}]*?;');
  inStyle.push('animation[-a-z]*?:[^:;{}]*?;');

  return replaceHtml(style, {
    inStyle,
  });
};
