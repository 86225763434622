import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

function calcPositionX(x: number) {
  // 组件宽度小于60，x轴压缩。大于等于60，维持x轴不变
  return `sw>=60?${x}:sw*(${x}/60)`;
  // return `(Math.min(sw,sh)*(${x}/60))`;
}

// 高度大于60，维持尖角高度不变，小于60，压缩y轴
const positionY = '(sh>=60?(sh-10):sh*(50/60))';

// 对话框（方）
export default {
  ...flowBase,
  name: i18n('resource.flow.squareSpeechBubble'),
  type: 'squareSpeechBubble',
  thumb: {
    spriteIconClass: SpriteThumb.SquareSpeechBubble.className,
    dragPosition: SpriteThumb.SquareSpeechBubble.position,
  },
  template: getShapeData(
    i18n('resource.flow.squareSpeechBubble'),
    {
      width: 60,
      lockedRatio: false,
      height: 60,
    },
    {
      data: [
        {
          point: {
            x: 0,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 50,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 10,
            y: 50,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 15,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 25,
            y: 50,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 60,
            y: 50,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 60,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
      closed: true,
      isTemplateShape: true,
      templateData: [
        {
          point: {
            x: 0,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: positionY,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: calcPositionX(10),
            y: positionY,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: calcPositionX(15),
            y: 'sh',
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: calcPositionX(25),
            y: positionY,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 'sw',
            y: positionY,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 'sw',
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
