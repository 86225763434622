import * as React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { isString } from 'lodash';

import './index.scss';

import { styleColor } from '../../constants';
import Icon from '../Icon';
import ScrollView from '../ScrollView';
import Tooltip from '../Tooltip';
import UpgradeTip from '../UpgradeTip';
import withAutoClose, { WithOnCloseProps } from '../withAutoClose';

const isSplitLine = (text: string) => isString(text) && text.match(/^-+$/);

export interface IMenuOption {
  id?: string | number;
  text: string;
  description?: string;
  linkTo?: string;
  href?: string;
  target?: string;
  disable?: boolean;
  icon?: string;
  iconColor?: string;
  showIcon?: boolean;
  // 一个红色的 tips 提示，专为 RP 公测添加的
  tips?: string;
  tipText?: string;
  // iDoc购买提示
  showUpgradeTip?: boolean;
  upgradeTipText?: string;
  upgradeLinkText?: string;
  badgeType?: 'dot' | 'count' | null;
  badgeCount?: number;
  badgeCountLimit?: number;
  hide?: boolean;
  children?: IMenuOption[];
  // 自定义后缀
  suffix?: string | React.ReactNode;
  /**
   * 自定义后缀
   */
  prefix?: string | React.ReactNode;
}

export interface IMenuProp extends WithOnCloseProps {
  options?: Array<IMenuOption>;
  className?: string;
  autoHeightMax?: number;
  childrenAutoHeightMax?: number;
  disabled?: boolean;
  selectIdx?: number;
  width?: string | number;
  maxWidth?: string | number;
  emptyMenuText?: string;
  minWidth?: string | number;
  suffix?: string;
  theme?: string;
  onMouseHover?(id: string | number): void;
  onSelect?(id: string | number, index: number, parentID?: string | number): void;
  forwardedRef?: React.RefObject<HTMLUListElement>;
  onClickLink?(): void;
  showManageButton?: boolean;
  style?: React.CSSProperties;
  manageButtonInfo?: { id: string; text: string; disable: boolean; tipText: string };
}

interface IMenuState {
  childrenMenuDirection: 'left' | 'right';
  hoverIdx: number;
  positionTop: null | number;
}

class Menu extends React.Component<IMenuProp, IMenuState> {
  constructor(props: IMenuProp) {
    super(props);
    this.state = {
      childrenMenuDirection: 'right',
      hoverIdx: 0,
      positionTop: null,
    };
  }

  static defaultProps: Partial<IMenuProp> = {
    options: [],
    disabled: false,
    style: {},
    autoHeightMax: 0, //0表示不应用最大高度
  };

  menuRef = React.createRef<HTMLUListElement>();

  componentDidMount() {
    const ref = this.props.forwardedRef;
    if (ref?.current) {
      const { bottom: rootBottom } = ref.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (windowHeight - rootBottom < 0) {
        this.setState({ positionTop: windowHeight - rootBottom - 20 });
      }
    }
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  onClickItem = (id: string | number, index: number, e: any) => {
    if (this.props.onSelect) {
      this.props.onSelect(id, index);
    }
    e && e.stopPropagation();
  };

  onMouseOver = (id: number | string, e: any, index: number) => {
    if (this.state.hoverIdx !== index) {
      this.setState({ hoverIdx: index });
    }
    if (this.props.onMouseHover) {
      this.props.onMouseHover(id);
    }
    e && e.stopPropagation();
  };

  renderBadge = (badgeType: 'dot' | 'count', badgeCount: number, badgeCountLimit?: number) => {
    if (badgeType === 'dot') {
      return (
        <span className="badge-dot">
          <span className="dot" />
        </span>
      );
    }
    if (badgeCount) {
      return (
        <span
          className="badge-count"
          style={{ padding: badgeCount < 10 ? 0 : '0 6px', borderRadius: badgeCount < 10 ? '50%' : 10 }}
        >
          {badgeCountLimit && badgeCount > badgeCountLimit ? '99+' : badgeCount}
        </span>
      );
    }
    return null;
  };

  computeChildrenMenuDirection = (): 'left' | 'right' => {
    const ref = this.props.forwardedRef;
    if (ref?.current) {
      const { width: rootWidth, right: rootRight } = ref.current.getBoundingClientRect();

      const windowWidth = window.innerWidth;

      if (windowWidth - rootRight > rootWidth) {
        return 'right';
      }
      return 'left';
    }
    return 'right';
  };

  onContextmenu(e: React.MouseEvent) {
    e.preventDefault();
  }

  render() {
    const {
      options,
      className,
      disabled,
      selectIdx,
      width,
      maxWidth,
      minWidth,
      autoHeightMax,
      emptyMenuText,
      theme,
      onClickLink,
      onSelect,
      showManageButton,
      manageButtonInfo,
      childrenAutoHeightMax,
    } = this.props;
    const { positionTop } = this.state;

    // 空列表时的显示
    const isShowText = options && !options.length && emptyMenuText;

    const MenuDom = options
      ? options.map(
          (
            {
              id,
              text,
              description,
              linkTo,
              href,
              target,
              disable,
              icon,
              iconColor,
              showIcon,
              tips,
              tipText,
              showUpgradeTip,
              upgradeTipText,
              upgradeLinkText,
              badgeType,
              badgeCount,
              badgeCountLimit,
              hide,
              suffix,
              prefix,
              children,
            },
            index,
          ) => {
            if (hide) {
              return <></>;
            }
            if (isSplitLine(text)) {
              return <li className="split-line" key={index} />;
            }

            return (
              <li
                key={index}
                className={classnames('', {
                  'has-icon': !!icon || showIcon,
                  'has-children': !!children && !!children.length,
                  disable,
                  'select-item': selectIdx === index,
                })}
                onClick={(e: React.MouseEvent) => {
                  if (disabled || disable) {
                    e.stopPropagation();
                    return;
                  }
                  !disabled && !disable && this.onClickItem(id ?? '', index, e);
                }}
                onMouseOver={(e: any) => {
                  this.onMouseOver(id || '', e, index);
                }}
              >
                {icon && <Icon cls={icon} color={iconColor} disabled={disabled || disable} />}
                {!icon && showIcon && <Icon hidden />}
                {linkTo && (
                  <Link
                    className={classnames('menu-item', {
                      'dsm-c-disabled': disabled || disable,
                    })}
                    to={linkTo}
                  >
                    {text}
                  </Link>
                )}
                {href && (
                  <a
                    className={classnames('menu-item', {
                      'dsm-c-disabled': disabled || disable,
                    })}
                    href={href}
                    target={target || '_self'}
                  >
                    {text}
                  </a>
                )}
                {!linkTo && !href && (
                  <span
                    className={classnames('menu-item', {
                      'dsm-c-disabled': disabled || disable,
                      suffix: !!suffix,
                      prefix: !!prefix,
                    })}
                  >
                    {prefix && <span className="prefix-item">{prefix}</span>}
                    <span
                      className={classnames('menu-item-title', { 'menu-item-title-badge': !!badgeType && badgeCount })}
                    >
                      {text}
                      {!!badgeType && this.renderBadge(badgeType, Number(badgeCount), badgeCountLimit)}
                    </span>
                    {!!suffix && <span className="menu-item-suffix">{suffix}</span>}
                    {tips && <i className="tips">{tips}</i>}
                    {tipText && <i className="tipText">{tipText}</i>}
                    {showUpgradeTip && upgradeTipText && (
                      <UpgradeTip tooltip={upgradeTipText} linkText={upgradeLinkText} onClickLink={onClickLink} />
                    )}
                    {description && <p>{description}</p>}
                    {children && children.length > 0 && (
                      <Icon cls={'tag_rightarrow children-arrow'} color={styleColor.colorGrey} />
                    )}
                  </span>
                )}
                {children && this.state.hoverIdx === index && (
                  <Menu
                    minWidth={minWidth}
                    maxWidth={maxWidth}
                    options={children}
                    forwardedRef={this.menuRef}
                    autoHeightMax={childrenAutoHeightMax}
                    className={classnames(theme, 'dsm-c-drop-child', this.state.childrenMenuDirection, {
                      'dsm-c-disabled': disabled || disable,
                    })}
                    onSelect={(childID, childIndex) => {
                      onSelect && onSelect(childID, childIndex, id);
                    }}
                  />
                )}
              </li>
            );
          },
        )
      : [];

    return (
      <ul
        className={classnames(theme, 'dsm-c-drop', className)}
        style={positionTop === null ? { width, maxWidth, minWidth } : { width, maxWidth, minWidth, top: positionTop }}
        ref={this.props.forwardedRef}
        onMouseEnter={() => {
          const newDirection = this.computeChildrenMenuDirection();
          newDirection !== this.state.childrenMenuDirection && this.setState({ childrenMenuDirection: newDirection });
        }}
        onContextMenu={this.onContextmenu}
        onClick={(e) => e.stopPropagation()}
      >
        {autoHeightMax ? (
          <>
            <ScrollView autoHeight={true} autoHeightMax={autoHeightMax}>
              {MenuDom}
            </ScrollView>
          </>
        ) : (
          MenuDom
        )}
        {isShowText && (
          <li>
            <span className="menu-item">{emptyMenuText}</span>
          </li>
        )}
        {showManageButton && (
          <li
            className="button-box"
            onClick={(e: React.MouseEvent) => {
              if (disabled || manageButtonInfo?.disable) {
                e.stopPropagation();
                return;
              }
              !disabled && !manageButtonInfo?.disable && this.onClickItem(manageButtonInfo?.id ?? '', 0, e);
            }}
          >
            <span
              className={classnames('menu-item-button', {
                'dsm-c-disabled': disabled || manageButtonInfo?.disable,
              })}
            >
              {manageButtonInfo?.disable ? (
                <Tooltip text={manageButtonInfo?.tipText} direction={'up'} theme={'small'}>
                  {manageButtonInfo?.text}
                </Tooltip>
              ) : (
                manageButtonInfo?.text
              )}
            </span>
          </li>
        )}
      </ul>
    );
  }
}

export default withAutoClose(Menu);
