import * as React from 'react';
import classnames from 'classnames';

import './TagButton.scss';

export interface ITagButtonProp {
  id?: number | string;
  text?: string;
  selected?: boolean;

  onClick?(): void;

  children?: JSX.Element[] | JSX.Element | string | any;
}

function TagButton(props: ITagButtonProp) {
  return (
    <div
      className={classnames('dsm-c-option-button', {
        'option-selected': props.selected,
      })}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

export default TagButton;
