import { Base } from './base';

export interface ITeamMember extends Base {
  name: string;
  teamID: string;
  role: Role;
}

export enum Role {
  SuperAdmin = 'super-admin',
  Admin = 'admin',
  Member = 'member',
  Guest = 'guest', // 协同人
  Observer = 'observer',  // 访客
}

// rp拆分后，团队成员信息接口
export interface IMemberInfo {
  id: number;
  name: string;
  email: string;
  avatar: string;
  wxOpenID: string;
  teamRole: string;
  teamGroup: number;
  groupName: string;
  memberID: number;
  createdAt: Date;
  pinyinName: string;
  loginTime: Date;
  isActivation: boolean;
  state: number;
}
