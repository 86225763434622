export enum weMeetConf {
  sdkId = '19600139159', // 应用id
  secret = '', // 应用密钥
  corpId = '215845805', // 企业id
}

export interface IJsTicketConfig {
  timestamp: string;
  ticket: string;
  nonceStr?: string;
}

export interface IMeetingInfo {
  meeting_number: number;
  meeting_info_list: {
    subject: string;
    meeting_id: string;
    meeting_code: string;
    type: number;
    join_url: string;
    hosts: { userid: string }[];
    participants: { userid: string }[];
    start_time: string;
    end_time: string;
    settings: {
      mute_enable_join: boolean;
      allow_unmute_self: boolean;
      mute_all: boolean;
      mute_enable_type_join: number;
    };
    meeting_type: number;
    enable_live: boolean;
    location: string;
  }[];
}
export interface IMeetingStatus {
  isOwner: boolean;
  isMeeting: boolean;
  ownerAvatar: string;
  ownerName: string;
  inMeeting: boolean;
  meetingCode: string;
}
