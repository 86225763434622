import * as React from 'react';

import mathUtils from '@/utils/mathUtils';

import ILine, { LinePointType } from '@/fbs/rp/models/properties/line';
import { StrokeLineJoin } from '@/fbs/rp/models/properties/stroke';

import { StyleHelper } from '@/helpers/styleHelper';

export const renderMarker = (opt: {
  line: ILine | undefined;
  strokeInfo: StyleHelper.ISVGStroke;
  compID: string;
  scale: number;
}) => {
  const { line, strokeInfo, compID, scale } = opt;
  if (!line || strokeInfo.strokeWidth === 0) {
    return null;
  }
  const { startPointType, startArrow, endArrow, endPointType } = line;
  if (!endArrow && !startArrow) {
    return null;
  }
  const startArrowInfo = startArrow ? getMarkerInfo(startPointType, strokeInfo, scale, false) : undefined;
  const endArrowInfo = endArrow ? getMarkerInfo(endPointType, strokeInfo, scale, true) : undefined;
  return (
    <defs>
      {!!startArrowInfo && (
        <marker
          id={`${compID}-start-marker`}
          markerWidth={startArrowInfo.markW}
          markerHeight={startArrowInfo.markH}
          refX={startArrowInfo.refX}
          refY={startArrowInfo.refY}
          orient="auto"
          markerUnits="strokeWidth"
          style={{ overflow: 'visible' }}
        >
          {startArrowInfo.el}
        </marker>
      )}
      {!!endArrowInfo && (
        <marker
          id={`${compID}-end-marker`}
          markerWidth={endArrowInfo.markW}
          markerHeight={endArrowInfo.markH}
          refX={endArrowInfo.refX}
          refY={endArrowInfo.refY}
          orient="auto"
          markerUnits="strokeWidth"
          style={{ overflow: 'visible' }}
        >
          {endArrowInfo.el}
        </marker>
      )}
    </defs>
  );
};

const getMarkerInfo = (
  pointType: LinePointType | undefined,
  strokeInfo: StyleHelper.ISVGStroke,
  // comp: UIComponent,
  scale: number,
  isEnd?: boolean,
): { el: JSX.Element | null; refX?: number; refY?: number; markW?: number; markH?: number } | undefined => {
  if (!pointType || strokeInfo.strokeWidth === 0) {
    return undefined;
  }
  const { stroke } = strokeInfo;
  const lineWidth = 1;
  const size = 4 * (lineWidth + 1);
  const type = pointType || LinePointType.solidArrow;
  switch (type) {
    case LinePointType.solidArrow: {
      const d = isEnd
        ? `M${size} ${size / 2}  L0 0 L${size * 0.25} ${size / 2} L${0} ${size}z`
        : `M0 ${size / 2}  L${size} 0 L${size * 0.75} ${size / 2} L${size} ${size}z`;
      const refX = isEnd ? size * 0.85 : size * 0.15;
      const refY = size / 2;
      return {
        el: (
          <path
            d={d}
            stroke={stroke}
            fill={stroke}
            strokeWidth={0}
            strokeLinejoin={strokeInfo.strokeLinejoin === StrokeLineJoin.Round ? 'round' : 'miter'}
          />
        ),
        refX,
        refY,
        markW: size,
        markH: size,
      };
    }
    case LinePointType.hollowArrow: {
      const d = isEnd ? `M${0} ${0} L${size} ${size / 2} L${0} ${size}` : `M${size} 0 L0 ${size / 2} L${size} ${size}`;
      const refX = isEnd ? size * 0.85 : size * 0.15;
      const refY = size / 2;
      return {
        el: (
          <path
            d={d}
            stroke={stroke}
            fill="none"
            strokeWidth={lineWidth}
            strokeLinecap={strokeInfo.strokeLinecap}
            strokeLinejoin={strokeInfo.strokeLinejoin}
          />
        ),
        refX,
        refY,
        markW: size,
        markH: size,
      };
    }
    case LinePointType.solidTriangle: {
      const d = isEnd ? `M${size} ${size / 2}  L0 0 L${0} ${size}z` : `M0 ${size / 2}  L${size} 0 L${size} ${size}z`;
      const refX = isEnd ? size * 0.85 : size * 0.15;
      const refY = size / 2;
      return {
        el: <path d={d} stroke={stroke} fill={stroke} strokeWidth={0} />,
        refX,
        refY,
        markW: size,
        markH: size,
      };
    }
    case LinePointType.hollowTriangle: {
      const d = isEnd ? `M${size} ${size / 2}  L0 0 L${0} ${size}z` : `M0 ${size / 2}  L${size} 0 L${size} ${size}z`;
      const refX = isEnd ? size * 0.05 : size * 0.95;
      const refY = size / 2;
      return {
        el: (
          <path
            d={d}
            stroke={stroke}
            fill="none"
            strokeWidth={lineWidth}
            strokeLinejoin={strokeInfo.strokeLinejoin === StrokeLineJoin.Round ? 'round' : 'miter'}
          />
        ),
        refX,
        refY,
        markW: size,
        markH: size,
      };
    }
    case LinePointType.solidDot: {
      const refX = isEnd ? size * 0.95 : size * 0.05;
      const refY = size / 2;
      return {
        el: <circle r={size / 2} cx={size / 2} cy={size / 2} stroke="none" fill={stroke} />,
        refX,
        refY,
        markW: size,
        markH: size,
      };
    }
    case LinePointType.hollowDot: {
      const refX = isEnd ? 0 : size;
      const refY = size / 2;
      return {
        el: (
          <circle
            r={size / 2 - lineWidth / 2}
            cx={size / 2}
            cy={size / 2}
            stroke={stroke}
            strokeWidth={lineWidth}
            fill="none"
          />
        ),
        refX,
        refY,
        markW: size,
        markH: size,
      };
    }
    case LinePointType.solidCube: {
      const refX = isEnd ? size * 0.95 : size * 0.05;
      const refY = size / 2;
      return {
        el: (
          <rect
            x={0}
            y={0}
            width={size}
            height={size}
            fill={stroke}
            stroke="none"
            strokeLinejoin={strokeInfo.strokeLinejoin}
          />
        ),
        refX,
        refY,
        markW: size,
        markH: size,
      };
    }
    case LinePointType.hollowCube: {
      const refX = isEnd ? 0 : size;
      const refY = size / 2;
      return {
        el: (
          <rect
            x={lineWidth / 2}
            y={lineWidth / 2}
            width={size - lineWidth}
            height={size - lineWidth}
            stroke={stroke}
            strokeWidth={lineWidth}
            fill="none"
            strokeLinejoin={strokeInfo.strokeLinejoin}
          />
        ),
        refX,
        refY,
        markW: size,
        markH: size,
      };
    }
    case LinePointType.solidDiamond: {
      const path = `M0,${size / 2} L${size / 2},${lineWidth} L${size},${size / 2} L${size / 2},${size - lineWidth}z`;
      const refX = isEnd ? size * 0.85 : size * 0.15;
      const refY = size / 2;
      return {
        el: <path d={path} fill={stroke} />,
        refX,
        refY,
        markW: size,
        markH: size,
      };
    }
    case LinePointType.hollowDiamond: {
      const offset = (mathUtils.sqrt(2) / 2) * lineWidth;
      const adjustedSize = size - offset * 2;
      const path =
        `M0,${adjustedSize / 2}` +
        ` L${adjustedSize / 2},${offset}` +
        ` L${adjustedSize},${adjustedSize / 2}` +
        ` L${adjustedSize / 2},${adjustedSize - offset}z`;
      const refX = isEnd ? offset : adjustedSize - offset;
      const refY = adjustedSize / 2;
      return {
        el: <path d={path} fill="none" stroke={stroke} strokeLinejoin={strokeInfo.strokeLinejoin} />,
        refX,
        refY,
        markW: adjustedSize,
        markH: adjustedSize,
      };
    }

    case LinePointType.perpendicular: {
      const refX = size / 2;
      const refY = size / 2;
      return {
        el: (
          <line
            x1={size / 2}
            x2={size / 2}
            y1={0}
            y2={size}
            stroke={stroke}
            strokeWidth={lineWidth}
            strokeLinecap={strokeInfo.strokeLinecap}
          />
        ),
        refX,
        refY,
        markW: size,
        markH: size,
      };
    }
    default:
      return undefined;
  }
};
