import * as React from 'react';
import { isUndefined } from 'lodash';
import classnames from 'classnames';

import { ComponentTheme } from '../common';
import { DropMode } from '../PageTree/model';
import { IProps, TreeItemData } from './helper';

import TreeItem, { TreeContext, ITreeContext } from './TreeItem';
import ScrollBars from '../ScrollBars';

import './index.scss';

export interface ITreeProp<T> {
  items: IProps<T> | Array<IProps<T>>;
  theme: ComponentTheme;
  itemHeight?: number;
  allowDrag?: boolean;
  isClickFolderOnExpand?: boolean;
  dropMode?: DropMode;
  isOver?: boolean;
  dropModeStyle?: { left: number; top: number; width: number };
  prefixIndent?: number;
  className?: string;
  disabled?: boolean;
  isFindingInteractionTarget?: boolean;
  itemRender: (item: T, collapse?: boolean) => React.ReactNode;
  onItemClick?: (e: React.MouseEvent, item: T) => void;
  onItemDoubleClick?: (e: React.MouseEvent, item: T) => void;
  onItemExpandChanged?: (item: T, expand: boolean) => void;
  onItemContextMenu?: (e: React.MouseEvent, data: TreeItemData<T>) => void;
  onDragStart?: (e: React.DragEvent, item: T) => void;
  onDrop?: (e: React.DragEvent, item: T) => void;
  onDragOver?: (e: React.DragEvent, item: T) => void;
  onDragEnter?: (e: React.DragEvent, item: T) => void;
  onDragEnd?: (e: React.DragEvent, item: T) => void;
  onItemMouseEnter?: (e: React.MouseEvent, data: TreeItemData<T>) => void;
  onItemMouseUp?: (e: React.MouseEvent, data: TreeItemData<T>) => void;
  onItemMouseLeave?: (e: React.MouseEvent, data: TreeItemData<T>) => void;
  onMainContentMouseEnter?: (comp: T) => void;
  onMainContentMouseLeave?: (comp: T) => void;
  onScroll?: (e: React.MouseEvent, ref: React.RefObject<ScrollBars>) => void;
  onItemCanBeInteractionTarget?(data: TreeItemData<T>): boolean;
}

export interface ITreeState {}

class Tree<T> extends React.PureComponent<ITreeProp<T>, ITreeState> {
  private scrollBars: React.RefObject<ScrollBars> = React.createRef();
  private self: React.RefObject<HTMLDivElement> = React.createRef();
  static defaultProps: Partial<ITreeProp<any>> = {
    theme: ComponentTheme.dark,
  };

  private treeContext: ITreeContext<T> = {
    selectedNodes: [],
    allNodes: [],
  };

  constructor(props: ITreeProp<T>) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillReceiveProps() {
    this.treeContext.selectedNodes = [];
    this.treeContext.allNodes = [];
  }

  scrollTop = (value: number) => {
    this.scrollBars.current?.scrollTop(value);
  };

  scrollToNode = (node: T) => {
    let treeNode = this.treeContext.allNodes.find((item) => item.data === node);
    if (treeNode) {
      const bounds = treeNode.clientBounds;
      if (bounds) {
        const selfBounds = this.self.current!.getBoundingClientRect();
        if (bounds.bottom > selfBounds.bottom || bounds.top < selfBounds.top) {
          const offset = bounds.top - selfBounds.top;
          const lastScrollTop = this.scrollBars.current!.getScrollTop();
          this.scrollBars.current!.scrollTop(offset + lastScrollTop);
        }
      }
    }
  };
  getBoundingClientRect = () => {
    return this.self.current?.getBoundingClientRect();
  };

  renderItem = (item: IProps<T>, index: number) => {
    const {
      disabled,
      itemRender,
      allowDrag,
      itemHeight,
      onItemClick,
      onItemContextMenu,
      onDrop,
      onDragStart,
      onDragOver,
      onDragEnd,
      prefixIndent,
      onItemExpandChanged,
      onItemMouseEnter,
      onItemMouseLeave,
      onItemDoubleClick,
      onItemMouseUp,
      onItemCanBeInteractionTarget,
      onMainContentMouseEnter,
      onMainContentMouseLeave,
      isClickFolderOnExpand,
    } = this.props;
    const indent = isUndefined(prefixIndent) ? 20 : prefixIndent;
    return (
      <TreeItem
        marked={item.marked}
        isClickFolderOnExpand={isClickFolderOnExpand}
        level={0}
        indent={indent}
        key={`${item}-${index}`}
        props={item}
        disabled={disabled}
        height={itemHeight}
        draggable={allowDrag}
        render={itemRender}
        onClick={onItemClick}
        onDoubleClick={onItemDoubleClick}
        onItemExpandChanged={onItemExpandChanged}
        onDrop={onDrop}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDragEnd={onDragEnd}
        onContextMenu={onItemContextMenu}
        onMouseEnter={onItemMouseEnter}
        onMouseLeave={onItemMouseLeave}
        onMouseUp={onItemMouseUp}
        onCanBeInteractionTarget={onItemCanBeInteractionTarget}
        onMainContentMouseEnter={onMainContentMouseEnter}
        onMainContentMouseLeave={onMainContentMouseLeave}
      />
    );
  };

  renderItems() {
    const { items } = this.props;
    if (!items) {
      return null;
    }
    if (Array.isArray(items)) {
      return items.map((item, index) => this.renderItem(item, index));
    } else {
      return this.renderItem(items, 0);
    }
  }

  onScroll = (e: React.MouseEvent) => {
    e.preventDefault();
    const { onScroll } = this.props;
    onScroll && onScroll(e, this.scrollBars);
  };

  render() {
    const { className, theme, isFindingInteractionTarget, dropMode, dropModeStyle, isOver } = this.props;
    return (
      <TreeContext.Provider value={this.treeContext}>
        <div
          className={classnames('dsm-c-rp-tree', className, theme, {
            'can-be-interaction-target': isFindingInteractionTarget,
          })}
          ref={this.self}
        >
          <ScrollBars autoHide ref={this.scrollBars} onScroll={this.onScroll}>
            <div className="tree-content" style={{ display: 'inline-block' }}>
              {this.renderItems()}
              {dropMode && dropMode !== DropMode.None && (
                <div
                  className={classnames('dropMoveBox', {
                    isOver,
                  })}
                  style={{
                    left: dropModeStyle?.left,
                    top: dropModeStyle?.top,
                    width: `${dropModeStyle?.width}px`,
                    pointerEvents: 'none',
                  }}
                ></div>
              )}
            </div>
          </ScrollBars>
        </div>
      </TreeContext.Provider>
    );
  }
}

export default Tree;
