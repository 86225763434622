import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { StrokePosition } from '@fbs/rp/models/properties/stroke';
import { getShapeData, DefaultFlowShapeSize, flowBase, getShapePropertiesData } from './shapeDataFragment';

// 对照
export default {
  ...flowBase,
  name: i18n('resource.flow.collate'),
  type: 'collate',
  thumb: {
    spriteIconClass: SpriteThumb.Collate.className,
    dragPosition: SpriteThumb.Collate.position,
  },
  template: getShapeData(
    i18n('resource.flow.collate'),
    DefaultFlowShapeSize.size100_60,
    {
      data: [
        {
          point: {
            x: 0,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 100,
            y: 0,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 0,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
        {
          point: {
            x: 100,
            y: 60,
          },
          handleIn: {
            x: 0,
            y: 0,
          },
          handleOut: {
            x: 0,
            y: 0,
          },
        },
      ],
      closed: true,
    },
    // false,
    // { stroke: { position: StrokePosition.center } },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({
        needFill: false,
        closed: true,
        properties: { stroke: { position: StrokePosition.center } },
      }),
    };
  },
};
