import { IUserPreference } from '@fbs/rp/models/grid';

import {
  defaultAlignConfig,
  defaultAppGridSettings,
  defaultAppLayoutSettings,
  defaultLayoutAndGridColors,
  defaultGeneralSettings,
} from '@consts/defaultData/grid';

import { ActionType } from '@/store/types';

import { UserPreferenceAction } from './actions';

export interface IUserPreferenceState {
  userPreference: IUserPreference;
}

const initialState: IUserPreferenceState = {
  userPreference: {
    userID: 0,
    appID: '',
    _id: '',
    appLayoutAndGrid: {
      grid: defaultAppGridSettings,
      layout: defaultAppLayoutSettings,
    },
    layoutAndGridColor: defaultLayoutAndGridColors,
    artboardsLayoutAndGrid: {},
    alignConfig: defaultAlignConfig,
    generalSettings: defaultGeneralSettings,
    colors: [],
  },
};

export default function (
  state: IUserPreferenceState = initialState,
  action: UserPreferenceAction,
): IUserPreferenceState {
  switch (action.type) {
    case ActionType.User_Preference_Load:
      return {
        ...state,
        userPreference: {
          ...state.userPreference,
          ...action.payload,
        },
      };
    case ActionType.User_Preference_Patch:
      return {
        ...state,
        userPreference: {
          ...state.userPreference,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
