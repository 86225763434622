import * as React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';

import './index.scss';

export interface ISwitchProp {
  isOn: boolean | undefined;
  height: number;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  onChange: (on: boolean) => void;
}

export interface ISwitchState {}

class Switch extends React.PureComponent<ISwitchProp, ISwitchState> {
  static defaultProps: Partial<ISwitchProp> = {
    height: 16,
  };

  selfRef: React.RefObject<HTMLDivElement>;

  constructor(props: ISwitchProp) {
    super(props);
    this.state = {};
    this.selfRef = React.createRef();
  }

  render() {
    const { className, isOn, style, onChange, height, disabled } = this.props;
    const styles = {
      ...style,
      height,
      width: height * 2,
      borderRadius: height / 2,
    };
    return (
      <div
        className={classnames(`dsm-c-rp-switch ${className || ''}`, {
          on: isOn,
          off: isOn === false,
          undefined: isUndefined(isOn),
          disabled,
        })}
        style={styles}
        onClick={() => {
          onChange(!isOn);
        }}
      >
        <i
          style={{
            width: height - 4,
            height: height - 4,
            borderRadius: height / 2 - 2,
            left: isUndefined(isOn)
              ? `calc(50% - ${height / 2 - 2}px)`
              : isOn
              ? `calc(100% - ${height - 4}px - 2px)`
              : 2,
          }}
        />
      </div>
    );
  }
}

export default Switch;
