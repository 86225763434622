export enum IconType {
  light,
  bold,
}

export const IconFontName: { [IconType.light]: string; [IconType.bold]: string } = {
  [IconType.bold]: 'boldIconFont',
  [IconType.light]: 'lightIconFont',
};

export enum SizeMode {
  custom,
  ratio,
  vertical,
  horizontal,
  none,
}

export enum MoveMode {
  custom,
  vertical,
  horizontal,
  neither,
}

// Checkbox/CheckboxGroup/Radio/RadioGroup
export enum PropertyStructureAlign {
  TextLeft = 'text-left',
  TextRight = 'text-right',
}
