import * as React from 'react';
import classnames from 'classnames';

import ScrollBar from '../ScrollBars';

import './index.scss';

export interface ITileListProp<T> {
  items: Array<T>;
  allowDrag?: boolean;
  className?: string;
  itemClassName?: string;
  style?: React.CSSProperties;
  ItemComponent?: React.ComponentType<T>;
  itemRender?: (data: T, index: number) => React.ReactNode;
  columnCount?: number;
  onItemClick?: (data: T) => void;
  onItemDoubleClick?: (data: T) => void;
  onItemDragStart?: (data: T) => void;
  onItemDragEnd?: (data: T) => void;
  onItemHovered?: (data: T) => void;
  onItemLeaved?: (data: T) => void;
  itemHeight: number;
  itemWidth: number;
}

export interface ITileListState<T> {
  itemWidth: number;
  // 必须明确指定itemWidth, itemHeight
  start: number;
  end: number;
  items: T[];
}

// 必须明确指定itemWidth, itemHeight

// TODO 性能优化机制： 载入时，计算列表的尺寸，可显示行数，列数，首次渲染时，仅显示一屏，滚动时，逐步加载后面的，已加载的不再销毁，
class TileList<T> extends React.Component<ITileListProp<T>, ITileListState<T>> {
  selfRef: React.RefObject<ScrollBar>;
  private size: { width: number; height: number } = { width: 0, height: 0 };
  private rowCount: number = 0;
  private columnCount: number = 0;
  private showCount: number = 0;

  constructor(props: ITileListProp<T>) {
    super(props);
    this.state = {
      itemWidth: props.itemWidth || 0,
      start: 0,
      end: 0,
      items: [],
    };
    this.selfRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.lazyLoadItems();
    });
    this.doCalculateItemWidth();
    this.calculate(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: ITileListProp<T>) {
    if (nextProps.items !== this.props.items || nextProps.items.length !== this.props.items.length) {
      this.setState({
        items: nextProps.items,
      });
      this.calculate(nextProps);
    }
  }

  private calculate(props: ITileListProp<T>) {
    const dom = this.selfRef.current!;
    this.size = {
      width: dom.getClientWidth(),
      height: dom.getClientHeight(),
    };
    const { itemHeight, itemWidth, items } = props;
    this.columnCount = Math.floor(this.size.width / itemWidth);
    this.rowCount = Math.ceil(this.size.height / itemHeight);
    const maxCount = items.length;
    this.showCount = Math.min(this.columnCount * (this.rowCount + 1) + this.showCount, maxCount);
    this.setState({
      end: this.showCount - 1,
    });
  }

  private lazyLoadItems() {
    const { items, itemHeight, itemWidth } = this.props;
    const width = this.selfRef.current!.getClientWidth();
    const height = this.selfRef.current!.getClientHeight();
    const colCount = Math.floor(width / itemWidth);
    const rowCount = Math.ceil(height / itemHeight);
    const firstItems = items.slice(0, rowCount * colCount);
    this.setState(
      {
        items: firstItems.length === items.length ? items : firstItems,
      },
      () => {
        if (this.state.items !== items) {
          this.setState({ items });
        }
      },
    );
  }

  doCalculateItemWidth = () => {
    if (!this.selfRef.current) {
      return;
    }
    const { itemWidth } = this.props;
    if (itemWidth) {
      return;
    }
    const w = this.selfRef.current.getClientWidth();
    const { columnCount } = this.props;
    const iw = Math.round(w / (columnCount || 1));
    if (this.state.itemWidth !== iw) {
      this.setState({ itemWidth: iw });
    }
  };

  handleItemClick = (data: T) => {
    if (this.props.onItemClick) {
      this.props.onItemClick(data);
    }
  };

  handleItemDoubleClick = (data: T) => {
    if (this.props.onItemDoubleClick) {
      this.props.onItemDoubleClick(data);
    }
  };

  handleItemDragStart = (data: T) => {
    const { onItemDragStart } = this.props;
    onItemDragStart && onItemDragStart(data);
  };

  handleItemDragEnd = (data: T) => {
    const { onItemDragEnd } = this.props;
    onItemDragEnd && onItemDragEnd(data);
  };

  handleItemHover = (data: T) => {
    this.props.onItemHovered && this.props.onItemHovered(data);
  };

  handleItemLeave = (data: T) => {
    this.props.onItemLeaved && this.props.onItemLeaved(data);
  };

  handleScroll = () => {
    if (this.selfRef.current) {
      if (this.props.items.length < this.showCount) {
        return;
      }
      const { itemHeight } = this.props;
      const top = this.selfRef.current.getScrollTop();
      const beforeRowCount = Math.floor(top / itemHeight);
      const start = beforeRowCount * this.columnCount;
      const end = Math.max(this.state.end, start + this.showCount - 1);
      if (end > this.state.end) {
        this.setState({
          end,
        });
      }
    }
  };

  renderChildList(item: T, index: number) {
    const { start, end } = this.state;
    if (index < start || index > end) {
      return null;
    }
    const { itemRender, ItemComponent } = this.props;
    if (ItemComponent) {
      return <ItemComponent key={`${index}`} {...item} />;
    }
    if (itemRender) {
      return itemRender(item, index);
    }
  }

  renderContent() {
    const { items } = this.state;
    if (!items.length) {
      return null;
    }
    const { itemHeight, itemWidth, itemClassName, allowDrag } = this.props;
    const style: React.CSSProperties = {
      width: itemWidth,
      height: itemHeight || 'auto',
    };
    return (
      <div className="dsm-c-rp-tile-list">
        {items &&
          items.map((item, index) => (
            <div
              key={`${item} ${index}`}
              className={classnames('list-item-hot', itemClassName || 'list-item-hot-default')}
              style={style}
              draggable={allowDrag}
              onDoubleClick={this.handleItemDoubleClick.bind(this, item)}
              onClick={this.handleItemClick.bind(this, item)}
              onDragStart={this.handleItemDragStart.bind(this, item)}
              onDragEnd={this.handleItemDragEnd.bind(this, item)}
              onMouseEnter={this.handleItemHover.bind(this, item)}
              onMouseLeave={this.handleItemLeave.bind(this, item)}
            >
              {this.renderChildList(item, index)}
            </div>
          ))}
      </div>
    );
  }

  render() {
    const { className, style } = this.props;
    return (
      <ScrollBar
        hiddenHorizontalScrollBar
        autoHide
        ref={this.selfRef}
        className={className}
        style={style}
        onScroll={this.handleScroll}
      >
        {this.renderContent()}
      </ScrollBar>
    );
  }
}

export default TileList;
