import * as React from 'react';
import * as tinycolor from 'tinycolor2';

import { IColorInfo } from './models';

interface IAlphaProps {
  color: IColorInfo;

  changeColor(color: tinycolor.ColorFormats.HSVA): void;
}

class Alpha extends React.Component<IAlphaProps> {
  private container: HTMLDivElement | null;

  constructor(props: IAlphaProps) {
    super(props);
    this.container = null;
    this.handleChange = this.handleChange.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.unbindEventListeners = this.unbindEventListeners.bind(this);
  }

  handleMouseDown() {
    window.addEventListener('mousemove', this.handleChange);
    window.addEventListener('mouseup', this.handleChange);
    window.addEventListener('mouseup', this.unbindEventListeners);
  }

  unbindEventListeners() {
    window.removeEventListener('mousemove', this.handleChange);
    window.removeEventListener('mouseup', this.handleChange);
    window.removeEventListener('mouseup', this.unbindEventListeners);
  }

  handleChange(e: MouseEvent) {
    e.preventDefault();
    const container = this.container;
    if (!container) {
      return;
    }
    const containerWidth = container.clientWidth;

    const rect = container.getBoundingClientRect();
    const offsetX = rect.left + (window.scrollX || window.pageXOffset);
    const left = e.pageX - offsetX;

    let a;

    if (left < 0) {
      a = 0;
    } else if (left > containerWidth) {
      a = 360;
    } else {
      a = left / containerWidth;
    }

    this.changeColor({
      ...this.props.color.hsv,
      a,
    });
  }

  changeColor(param: tinycolor.ColorFormats.HSVA) {
    this.props.changeColor(param);
  }

  render() {
    const pickerStyle = {
      left: `${this.props.color.alpha * 100}%`,
    };
    const rgba = this.props.color.rgba;
    const str = [rgba.r, rgba.g, rgba.b].join(', ');
    const bgStyle = {
      background: `linear-gradient(to right, rgba(${str}, 0) 0%, rgba(${str}, 1) 100%`,
    };
    return (
      <div className="c-alpha-wrap" ref={(div) => (this.container = div)} onMouseDown={this.handleMouseDown}>
        <div className="alpha-bg" style={bgStyle} />
        <div className="alpha-pointer">
          <div className="alpha-picker" style={pickerStyle} />
        </div>
      </div>
    );
  }
}

export default Alpha;
