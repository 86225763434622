import * as React from 'react';
import * as ReactDom from 'react-dom';
import classnames from 'classnames';

import { isMacOS, titleBarHeight } from '@utils/envUtils';
import withAutoClose, { IAutoCloseComponentProps, PopupManager } from '../withAutoClose';
import Icon from '../Icon';
import { IPosition } from '@fbs/common/models/common';
import { MouseButton } from '@/consts/enums/mouseButton';
import { ComponentTheme } from '../common';

import Badge from '../Badge';

import './index.scss';
import '../Icon/font_1475460_nrd5bd5wvpf/iconfont.css';
//item选项主题样式className 定制
const themeItemToClassNameMap: { [name: string]: string } = {
  itemMoreBtn: 'item-more-btn', //更多按钮选项
  itemTitle: 'item-show-title', //标题选项
  itemTextEllipsis: 'item-text-ellipsis', //文本超出显示省略号选项
  itemNoHover: 'item-no-hover', //文本无hover效果纯展示选项
};

export interface IMenuItem {
  id: string | number;
  text: string | React.ReactElement;
  icon?: string;
  checked?: boolean;
  link?: string; // 点击选项可直接进行跳转的链接
  shortCut?: string;
  disabled?: boolean;
  hidden?: boolean;
  children?: IMenuItem[];
  content?: string;
  dot?: boolean;
  count?: number;
  selected?: boolean;
  data?: any;
  themeItem?: string; //选项主题样式
  rightNode?: any; //选项右侧节点
  action?: Function;
  renderCustomIcon?: () => React.ReactNode;
}

export interface IPopupMenuProp extends IAutoCloseComponentProps {
  items: IMenuItem[];
  position?: IPosition;
  useCheck?: boolean;
  checkIcon?: string;
  forwardedRef?: React.RefObject<HTMLDivElement>;
  width?: number;
  className?: string;
  isInPopup?: boolean;
  theme?: ComponentTheme;
  iconPosition?: 'left' | 'right';
  container?: Element | null;
  zIndex?: number;
  customItemClassName?: (item: IMenuItem) => string | undefined;
  onItemClick?: (item: IMenuItem, e: React.MouseEvent) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

interface IPopupMenuState {
  position: IPosition;
  opacity: number;
  direction: 'left' | 'right';
  childItemTop: { [id: string]: number };
  selectId?: string;
}

/**
 * 该组件被继承，注意public方法修改影响的范围
 */
export class PopupMenu<P extends IPopupMenuProp> extends React.Component<P, IPopupMenuState> {
  static defaultProps: Partial<IPopupMenuProp> = {
    position: {
      x: 0,
      y: 0,
    },
    useCheck: false,
    checkIcon: 'icon_confirm',
    iconPosition: 'left',
  };

  oldPosition?: IPosition;
  self: React.RefObject<HTMLDivElement>;

  constructor(props: P) {
    super(props);
    this.renderItem = this.renderItem.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.doAdjustPosition = this.doAdjustPosition.bind(this);
    this.doApplyPosition = this.doApplyPosition.bind(this);
    this.oldPosition = props.position;
    this.self = props.forwardedRef as React.RefObject<HTMLDivElement>;
    this.state = {
      opacity: 0,
      position: props.position || { x: 0, y: 0 },
      direction: 'right',
      childItemTop: {},
      selectId: '', //选择ID
    };
  }

  UNSAFE_componentWillMount() {
    PopupManager.manager.closeAllUnModal();
  }

  componentDidMount() {
    this.doAdjustPosition();
    this.setState({ opacity: 1 });
  }

  componentDidUpdate() {
    this.doAdjustPosition();
  }

  UNSAFE_componentWillReceiveProps(newProps: IPopupMenuProp) {
    const { position } = newProps;
    const { x, y } = position || { x: 0, y: 0 };
    if (this.oldPosition && x === this.oldPosition.x && y === this.oldPosition.y) {
      return;
    }
    this.setState({ opacity: 0 }, () => {
      this.doApplyPosition({ x, y });
      this.setState({ opacity: 1 });
    });
  }

  get clientBounds() {
    return this.self.current?.getBoundingClientRect();
  }

  doApplyPosition(position: IPosition) {
    this.oldPosition = position;
    this.setState({ position });
  }

  doAdjustPosition() {
    if (!this.self.current) {
      return;
    }
    const bounds = this.self.current.getBoundingClientRect();
    const { width, height, right, bottom } = bounds;
    const { position, direction } = this.state;
    let { x, y } = position;
    let newDirection: 'left' | 'right' = direction;
    const winWidth = window.innerWidth;
    const winHeight = window.innerHeight;
    if (right > winWidth) {
      x -= width;
    }

    if (right + width > winWidth) {
      newDirection = 'left';
    } else {
      newDirection = 'right';
    }

    if (bottom > winHeight) {
      y -= height;
    }
    const minY = titleBarHeight;
    if (y < minY) {
      y = minY;
    }
    y = Math.round(y);
    x = Math.round(x);

    if (position.x !== x || position.y !== y || newDirection !== direction) {
      this.setState({
        position: {
          x,
          y,
        },
        direction: newDirection,
      });
    }
  }

  handleItemClick(e: React.MouseEvent<HTMLElement>, item: IMenuItem) {
    e.stopPropagation();
    if (!item.disabled) {
      if (e.button === MouseButton.Left) {
        if (item.children) {
          return;
        }
        if (item.action) {
          item.action();
        } else {
          if (this.props.onItemClick) {
            this.props.onItemClick(item, e);
          }
        }
      }
    }
  }

  handleItemMouseEnter = (e: React.MouseEvent) => {
    const dom = e.currentTarget as HTMLElement;
    const childMenu = dom.querySelector('.children-menu');
    const { childItemTop } = this.state;
    this.setState({ selectId: dom.dataset.id }); //设置选中项id
    if (childMenu) {
      const { height, bottom: childBottom } = childMenu.getBoundingClientRect();
      const { innerHeight } = window;
      if (childBottom > innerHeight) {
        const t = -height + 10 + dom.offsetHeight;
        const id = dom.dataset['id']!;
        const info = childItemTop[id];
        if (t !== info) {
          this.setState({ childItemTop: { ...childItemTop, [id]: Math.round(t) } });
        }
      }
    }
  };

  handleItemMouseLeave = (e: React.MouseEvent) => {
    const dom = e.target as HTMLElement;
    const id = dom.dataset['id'];
    this.setState({ selectId: '' }); //清除选中项id
    if (id) {
      const data = { ...this.state.childItemTop };
      if (data[id] !== undefined) {
        delete data[id];
        this.setState({ childItemTop: data });
      }
    }
  };

  private doBuilderItems(items: IMenuItem[]) {
    //排除隐藏的项
    const showItems = items.filter((item) => !item.hidden);
    // 忽略最前面的分隔线
    while (showItems && showItems.length && showItems[0].text === '-') {
      showItems.shift();
    }
    // 忽略最后面的分隔线
    let i = showItems ? showItems.length - 1 : -1;
    while (showItems && showItems.length && showItems[i].text === '-') {
      showItems.pop();
      i = showItems.length - 1;
      if (i === -1) {
        break;
      }
    }
    // 忽略连接的分隔线
    i = 1;
    while (i < showItems.length) {
      if (showItems[i].text === '-' && showItems[i - 1].text === '-') {
        showItems.splice(i, 1);
        i -= 1;
      }
      i++;
    }
    return showItems;
  }

  public renderItemContent(item: IMenuItem) {
    const itemContent = (
      <>
        {this.props.useCheck && (
          <Icon
            size={12}
            cls={this.props.checkIcon}
            theme="tag"
            className={`checker${item.checked ? '' : ' checker-no'}`}
          />
        )}
        {item.icon && <Icon cls={item.icon} theme="tag" className="menu-item-icon" />}
        {item.renderCustomIcon && item.renderCustomIcon()}
        <div className="text">
          <Badge dot={item.dot} count={item.count} dotOffset={[10, 10]} position="right">
            <span>{item.text}</span>
          </Badge>
        </div>

        {item.shortCut && <kbd className={classnames('shortcut', { mac: isMacOS })}>{item.shortCut}</kbd>}

        {/* 选项右侧组件节点渲染:选中该项则显示右侧节点 */}
        {item.id === this.state.selectId && item.rightNode && <div className="right-node">{item.rightNode}</div>}
      </>
    );
    if (item.link) {
      return (
        <a className="link" href={item.link} target="_blank" rel="noreferrer">
          {itemContent}
        </a>
      );
    }
    return itemContent;
  }

  public renderChildItems(items: IMenuItem[]) {
    return this.renderChildren(items);
  }

  public renderItem(item: IMenuItem, index: number) {
    const { iconPosition, customItemClassName } = this.props;

    if (item.text === '-') {
      return <div key={`separator_${index.toString()}`} className="separator" />;
    }
    const childItems = item.children ? this.doBuilderItems(item.children) : undefined;
    if (item.children?.length && !childItems?.length) {
      return null;
    }
    const itemClass = customItemClassName ? customItemClassName(item) : undefined;
    //选项主题定制item-class
    const themeItemClassName = item.themeItem ? themeItemToClassNameMap[item.themeItem] || '' : '';
    return (
      <div
        key={item.id}
        data-id={item.id}
        className={classnames('item', itemClass, themeItemClassName, {
          disabled: item.disabled,
          enabled: !item.disabled,
          hidden: item.hidden,
          selected: item.selected,
          'link-item': !!item.link,
        })}
        onMouseDown={(e) => {
          this.handleItemClick(e, item);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        onMouseEnter={this.handleItemMouseEnter}
        onMouseLeave={this.handleItemMouseLeave}
      >
        <div className={classnames('title', { reverse: iconPosition === 'right' })}>
          {this.renderItemContent(item)}

          {childItems?.length ? <i className="children-arrow mockplus_rp mockplus_rp_Right" /> : null}
          {childItems?.length ? (
            <div
              className={classnames('children-menu', this.state.direction)}
              style={{
                top: this.state.childItemTop[item.id],
              }}
            >
              {this.renderChildItems(childItems)}
            </div>
          ) : null}
        </div>
        {item.content && <p className="content">{item.content}</p>}
      </div>
    );
  }

  public renderChildren(items: IMenuItem[]) {
    return items.map(this.renderItem);
  }

  public renderPopup(content: React.ReactNode) {
    return ReactDom.createPortal(
      <div
        ref={this.self}
        className={classnames(
          'dsm-c-rp-popup-menu popup-with-body',
          this.props.className,
          this.props.theme,
          { 'in-popup': this.props.isInPopup },
          { animation: this.state.opacity !== 0 },
        )}
        style={{
          left: this.state.position.x,
          top: this.state.position.y,
          minWidth: this.props.width,
          opacity: this.state.opacity,
          zIndex: this.props.zIndex,
        }}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      >
        {content}
      </div>,
      this.props.container ?? document.body,
    );
  }

  render() {
    const items = this.doBuilderItems(this.props.items);
    if (items.length > 0 && this.state.position) {
      return this.renderPopup(this.renderChildren(items));
    }
    return null;
  }
}

export default withAutoClose(PopupMenu);
