// import { getDeviceRatio } from '@/utils/graphicsUtils';

interface ISize {
  width: number;
  height: number;
}

/**
 * 将某段像素数据扩散 R 倍
 * @param imgData 像素数据
 * @param ratio 放大倍数
 * @param size?.width 扩散后宽度
 * @param size?.height 扩散后高度
 */
export function spreadImageData(imgData: ImageData, ratio: number, size?: ISize): ImageData {
  const { width, height, data } = imgData;
  const result = new ImageData(size?.width || width * ratio, size?.height || height * ratio);
  const { width: rw, data: rData } = result;
  // 把源数据一个像素点填充到目标的10个像素点上，达到把图像放大10倍的目的
  for (let sourceRowIndex = 0; sourceRowIndex < height; sourceRowIndex++) {
    for (let sourceColumnIndex = 0; sourceColumnIndex < width; sourceColumnIndex++) {
      const sourcePixelIndex = (sourceRowIndex * width + sourceColumnIndex) * 4;
      const [r, g, b, a] = [
        data[sourcePixelIndex],
        data[sourcePixelIndex + 1],
        data[sourcePixelIndex + 2],
        data[sourcePixelIndex + 3],
      ];
      for (let resultRowIndex = 0; resultRowIndex < ratio; resultRowIndex++) {
        const resultPixelIndex = sourceRowIndex * ratio + resultRowIndex;
        for (let resultColumnIndex = 0; resultColumnIndex < ratio; resultColumnIndex++) {
          const resultDataIndex = (resultPixelIndex * rw + sourceColumnIndex * ratio + resultColumnIndex) * 4;
          rData[resultDataIndex] = r;
          rData[resultDataIndex + 1] = g;
          rData[resultDataIndex + 2] = b;
          rData[resultDataIndex + 3] = a;
        }
      }
    }
  }
  return result;
}

/**
 * 放大图片
 * @param url 图片路径
 * @param scale 放大倍数
 */
export async function enlargeImage(url: string, scale: number = 1) {
  const imageData = await getImageDataFromImageURL(url);
  const newData = spreadImageData(imageData, scale);
  return getDataURLFromImageData(newData);
}

/**
 * 从图片路径中获取图片数据
 */
export async function getImageDataFromImageURL(imgURL: string): Promise<ImageData> {
  const imgElement = await getImageElementFromImageURL(imgURL);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = imgElement.width;
  canvas.height = imgElement.height;
  if (ctx) {
    ctx.drawImage(imgElement, 0, 0);
    return ctx.getImageData(0, 0, imgElement.width, imgElement.height);
  }
  return new ImageData(imgElement.width, imgElement.height);
}

/**
 * 从图片数据中获取 base64 格式的图片路径
 * @param imageData
 */
export function getDataURLFromImageData(imageData: ImageData): string {
  const canvas = document.createElement('canvas');
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  const ctx = canvas.getContext('2d');
  ctx?.putImageData(imageData, 0, 0);
  return canvas.toDataURL();
}

/**
 * 图片路径 转 base64
 * @param img
 */
export async function getDataURLFromImageURL(imgURL: string) {
  const canvasElement = await getImageCanvasFromImageURL(imgURL);
  return canvasElement.toDataURL();
}

/**
 * 获取某图片路径对应的画布
 * @param imgURL
 */
export async function getImageCanvasFromImageURL(imgURL: string): Promise<HTMLCanvasElement> {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const img = await getImageElementFromImageURL(imgURL);
  ctx?.drawImage(img, 0, 0);
  return canvas;
}

/**
 * 获取某路径下的图片元素
 * @param imgURL
 */
export function getImageElementFromImageURL(imgURL: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imgURL;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = reject;
  });
}

/**
 * 获取视频的第一帧
 * @param src 视频路径
 */
export function getFirstFrameFromVideoURL(src: MediaStream | string, size?: ISize): Promise<string> {
  const video = document.createElement('video');
  if (src instanceof MediaStream) {
    video.srcObject = src;
  } else {
    video.src = src;
  }
  return getFirstFrameFromVideo(video, size);
}

/**
 * 获取视频的第一帧
 * @param video
 */
export function getFirstFrameFromVideo(video: HTMLVideoElement, size?: ISize): Promise<string> {
  if (size) {
    video.width = size.width;
    video.height = size.height;
  }
  video.autoplay = true;
  return new Promise((resolve, reject) => {
    video.onloadedmetadata = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d')!;
      canvas.width = video.width;
      canvas.height = video.height;
      ctx.drawImage(video, 0, 0);
      resolve(canvas.toDataURL());
    };
    video.onerror = reject;
  });
}
