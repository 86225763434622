import { UIContainerComponent } from '@editor/comps';
import { PagePatches, Ops, ArtboardOperations } from '@fbs/rp/utils/patch';
import { makeComponentItem } from './libs';
import { IComponentData } from '@fbs/rp/models/component';

// 多项组件的值编获取
export function getListItemsValue(comp: UIContainerComponent): string {
  const texts = comp.components.map((c) => {
    return (c as UIContainerComponent).components[1].value;
  });
  return texts.join('\n');
}

// 多项组件的值编辑
export function setListItemsValue(
  comp: UIContainerComponent,
  newValue: string,
  libInfo: {
    id: string;
    type: string;
  },
): PagePatches {
  const doOps: ArtboardOperations = {};
  const undoOps: ArtboardOperations = {};
  const texts = newValue.split('\n');
  comp.components.forEach((c, idx) => {
    const itemComp = c as UIContainerComponent;
    if (!itemComp.components) {
      return;
    }
    const currentTextComp = itemComp.components[1];
    if (!currentTextComp) {
      return;
    }
    if (idx <= texts.length - 1) {
      if (currentTextComp.value === texts[idx]) {
        return;
      }
      undoOps[currentTextComp.id] = [Ops.replace('/value', currentTextComp.value)];
      doOps[currentTextComp.id] = [Ops.replace('/value', texts[idx])];
    }
  });

  // 文本比当前的组件项多，需要添加组件
  if (texts.length > comp.components.length) {
    const toAdd: IComponentData[] = [];
    const toRemove: string[] = [];
    texts.forEach((textValue, idx) => {
      if (idx < comp.components.length) {
        return;
      }
      // FIXME: appType 没有传值
      const item = makeComponentItem(libInfo.id, libInfo.type, '', [textValue]);
      toAdd.push(item);
      toRemove.push(item._id);
    });
    doOps[comp.id] = [Ops.addChildren('-1', toAdd)];
    undoOps[comp.id] = [Ops.removeChildren(toRemove)];
  }
  // 需要删除组件
  else if (texts.length < comp.components.length) {
    const toRemove: string[] = [];
    undoOps[comp.id] = [];
    comp.components.forEach((c, idx) => {
      if (idx < texts.length) {
        return;
      }
      toRemove.push(c.id);
      undoOps[comp.id].push(Ops.addChildren(`${idx}`, [c.toJSON()]));
    });
    doOps[comp.id] = [Ops.removeChildren(toRemove)];
  }
  const patches: PagePatches = {
    [comp.ownerArtboardID]: {
      do: doOps,
      undo: undoOps,
    },
  };
  return patches;
}
