import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

interface IProps {
  className?: string;
  children?: React.ReactNode;
  content?: React.ReactNode;
  contentWidth?: number;
  visible?: boolean;
}

const PADDING_LEFT = 12;

function Popover(props: IProps): JSX.Element {
  const { className, children, content, contentWidth, visible } = props;
  const width = contentWidth ? contentWidth + PADDING_LEFT : undefined;
  const renderPopover = () => {
    if (visible === false) {
      return;
    }
    return (
      <div className="dsm-c-rp-popover" style={{ width }}>
        <div className="dsm-c-rp-popover-content">{content}</div>
        <div className="dsm-c-rp-popover-arrow">
          <span className="dsm-c-rp-popover-arrow-content"></span>
        </div>
      </div>
    );
  };
  return (
    <div className={classnames(className, 'dsm-c-rp-popover-container')}>
      {children}
      {renderPopover()}
    </div>
  );
}

export default Popover;
