import i18n from '@i18n';
import { IComponentData } from '@/fbs/rp/models/component';

import { IComponentLib } from '../types';
import ellipse, { makeFlowEllipse } from './ellipse';
import rectangle, { makeRectangle } from './rectangle';
import diamond from './diamond';
import pentagon from './pentagon';
import parallelogram from './parallelogram';
import triangle from './triangle';
import semicircle from './semicircle';
import octagon from './octagon';
import keying from './keying';
import rightTriangle from './rightTriangle';
import trapezoid from './trapezoid';
import hexagon from './hexagon';
import drop from './drop';
import heart from './heart';
import sector from './sector';
import cloud from './cloud';
import beveledRectangle from './beveledRectangle';
import punchedCard from './punchedCard';
import roundRectangle, { makeRoundRectangle } from './roundRectangle';
import circle, { makeFlowCircle } from './circle';
import start, { makeStartOrEnd } from './start';
import star from './star';
import squareSpeechBubble from './squareSpeechBubble';
import roundedSpeechBubble from './roundedSpeechBubble';
import rightArrowButton from './rightArrowButton';
import leftArrowButton from './leftArrowButton';
import ready from './ready';
import sectorDiagram from './sectorDiagram';
import cross from './cross';
import rightArrow from './rightArrow';
import leftArrow from './leftArrow';
import horizontalDoubleArrow from './horizontalDoubleArrow';
import limitCycle from './limitCycle';
import manualInput from './manualInput';
import punchedTape from './punchedTape';
import offPageConnector from './offPageConnector';
import display from './display';
import externalData from './externalData';
import manualOperation from './manualOperation';
import upGrid from './upGrid';
import leftGrid from './leftGrid';
import classicTab from './classicTab';
import queueData from './queueData';
import delay from './delay';
import APQC from './APQC';
import document from './document';
import collate from './collate';
import leftBracket from './leftBracket';
import rightBracket from './rightBracket';
import leftBrace from './leftBrace';
import rightBrace from './rightBrace';
import pieChart from './pieChart';
import upArrow from './upArrow';
import verticalDoubleArrow from './verticalDoubleArrow';
import downArrow from './downArrow';
import rightAngle from './rightAngle';
import dataBase from './dataBase';
import rightTurn from './rightTurn';
import leftTurn from './leftTurn';
import { CRectangle, CRoundRectangle, CStartOrEnd, CFlowCircle, CFlowEllipse } from '../constants';

const FlowComponentLibs: IComponentLib = {
  id: 'flow',
  name: i18n('resource.components.flow'),
  components: [
    rectangle,
    parallelogram,
    diamond,
    roundRectangle,
    start,
    ready,
    beveledRectangle,
    keying,
    circle,
    ellipse,
    semicircle,
    triangle,
    rightTriangle,
    trapezoid,
    pentagon,
    hexagon,
    //
    octagon,
    star,
    drop,
    heart,
    sector,

    sectorDiagram,
    pieChart,
    cloud,
    squareSpeechBubble,
    roundedSpeechBubble,

    leftArrowButton,
    rightArrowButton,
    cross,
    leftArrow,
    rightArrow,
    horizontalDoubleArrow,
    upArrow,
    downArrow,
    verticalDoubleArrow,
    leftTurn,
    rightTurn,
    rightAngle,
    leftBracket,
    rightBracket,
    leftBrace,
    rightBrace,
    upGrid,
    leftGrid,
    classicTab,

    manualOperation,
    externalData,
    queueData,
    dataBase,

    delay,
    display,
    punchedTape,
    manualInput,
    punchedCard,
    offPageConnector,
    limitCycle,
    APQC,
    document,
    collate,
  ],
};

export const flowMakeConfig: { [key: string]: () => IComponentData } = {
  [CRectangle]: makeRectangle,
  [CRoundRectangle]: makeRoundRectangle,
  [CStartOrEnd]: makeStartOrEnd,
  [CFlowCircle]: makeFlowCircle,
  [CFlowEllipse]: makeFlowEllipse,
};

export default FlowComponentLibs;
