import * as React from 'react';
import classnames from 'classnames';
import i18n from '../../i18n';

import './Radio.scss';

export interface IRadioProp {
  checked: boolean;
  text: string;
  disabled?: boolean;
  darkMode?: boolean;

  onClick?(): void;
}

const Radio: React.FC<IRadioProp> = ({ checked, text, disabled, darkMode, onClick }) => (
  <div
    className={classnames('dsm-c-radio', {
      'c-radio-disabled': disabled,
      'c-radio-dark': darkMode,
    })}
    onClick={disabled ? undefined : onClick}
  >
    <span
      className={classnames('radio-viewer', {
        'radio-checked': checked,
      })}
    />
    <span className="radio-text">{text}</span>
  </div>
);

Radio.defaultProps = {
  checked: false,
  text: i18n('RadioButton.text'),
  disabled: false,
  darkMode: false,
};

export default Radio;
