import { ThunkDispatch } from 'redux-thunk';

import { default as ITypography, ITypographyValue } from '@fbs/rp/models/ds/typography';
import { PureColor } from '@fbs/rp/models/properties/color';
import { IComponentData } from '@fbs/rp/models/component';
import { IResourceItem, ResourceType } from '@fbs/rp/models/ds/lib';
import IColor from '@fbs/rp/models/ds/color';
import IComponent, { IRelation } from '@fbs/rp/models/ds/component';

import * as Actions from '../store/actions';

export interface IResourceDispatcher {
  loadLib: (id?: string, isExample?: boolean) => void;
  loadUsedLib: (ids: string[]) => Promise<void>;
  addLib: () => void;
  switchLib: (libID: string) => void;
  updateLib: (libID: string, data: any) => void;
  addColor: (libID: string, color: PureColor | PureColor[]) => void;
  addComponent: (
    libID: string,
    tempID: string,
    data: {
      thumb: string;
      component: Partial<IComponentData>;
      name: string;
      groupID?: string;
      description?: string;
    },
  ) => void;
  addTypography: (libID: string, data: ITypographyValue) => void;
  removeResource: (res: IResourceItem, type: ResourceType) => void;
  patchResourceItem: (
    libID: string,
    resID: string,
    data: Partial<IColor | ITypography | IComponent>,
    type: ResourceType,
  ) => void;
  changeResourceItemIndex: (type: ResourceType, res: IResourceItem, targetIndex: number) => void;
  resetComponentsGroup: (libID: string, targetGroupID: string, comps: IComponent[]) => void;
  addGroup: (libID: string, name: string) => void;
  removeGroup: (libID: string, groupID: string) => void;
  renameGroup: (libID: string, groupID: string, name: string) => void;
  moveGroup: (libID: string, groupID: string, newIndex: number) => void;
  removeAfterMoveGroup: (libID: string, removeGroupID: string, targetGroupID: string, comps: IComponent[]) => void;
  removeComponents: (libID: string, componentIDs: string[]) => void;
  addRelations: (libID: string, componentID: string, data: Partial<IRelation>[]) => void;
  patchRelations: (libID: string, componentID: string, data: IRelation[]) => void;
  removeRelations: (libID: string, componentID: string, relationIDs: string[]) => void;
  convertToSymbol: (comps: IComponent[]) => void;
  convertSymbolToNormal: (comps: IComponent[]) => void;
  convertToSymbolOrNormal: (comps: IComponent[], symbolComps: IComponent[]) => void;
}

export default function getResourceDispatcher(
  appID: string,
  dispatch: ThunkDispatch<any, null, any>,
): IResourceDispatcher {
  return {
    loadLib: (id?: string, isExample?: boolean) => {
      dispatch(Actions.ResourceThunkActions.thunkLoadLib(id || appID, isExample));
    },

    loadUsedLib: (ids: string[]) => {
      return Actions.ResourceThunkActions.thunkLoadUsedLib(ids)(dispatch);
    },

    addLib: () => {
      dispatch(Actions.ResourceThunkActions.thunkAddLib(appID));
    },
    switchLib: (libID: string) => {
      dispatch(Actions.ResourceThunkActions.switchLib(libID));
    },
    updateLib: (libID: string, data: any) => {
      dispatch(Actions.ResourceThunkActions.thunkUpdateLib(libID, data));
    },

    addColor: (libID: string, color: PureColor | PureColor[]) => {
      dispatch(Actions.ResourceThunkActions.addColor(libID, color));
    },

    addComponent: (
      libID: string,
      tempID: string,
      data: {
        thumb: string;
        component: Partial<IComponentData>;
        name: string;
        groupID?: string;
        description?: string;
      },
    ) => {
      dispatch(Actions.ResourceThunkActions.addComponent(libID, tempID, data));
    },
    addTypography: (libID: string, data: ITypographyValue) => {
      dispatch(Actions.ResourceThunkActions.addTypography(libID, data));
    },

    removeResource: (res: IResourceItem, type: ResourceType) => {
      dispatch(Actions.ResourceThunkActions.removeResourceItem(res, type));
    },

    patchResourceItem: (
      libID: string,
      resID: string,
      data: Partial<IColor | ITypography | IComponent>,
      type: ResourceType,
    ) => {
      switch (type) {
        case ResourceType.color:
          dispatch(Actions.ResourceThunkActions.patchColor(resID, data as IColor));
          break;
        case ResourceType.typography:
          dispatch(Actions.ResourceThunkActions.patchTypography(resID, data as ITypography));
          break;
        case ResourceType.component:
          dispatch(Actions.ResourceThunkActions.patchComponent(resID, data as IComponent));
          break;
        default:
          break;
      }
    },
    changeResourceItemIndex: (type: ResourceType, res: IResourceItem, targetIndex: number) => {
      dispatch(Actions.ResourceThunkActions.changeResourceIndex(type, res, targetIndex));
    },

    addGroup: (libID: string, name: string) => {
      dispatch(Actions.ResourceThunkActions.addGroup(libID, name));
    },

    removeGroup: (libID: string, groupID: string) => {
      dispatch(Actions.ResourceThunkActions.removeGroup(libID, groupID));
    },

    renameGroup: (libID: string, groupID: string, name: string) => {
      dispatch(Actions.ResourceThunkActions.renameGroup(libID, groupID, name));
    },

    moveGroup: (libID: string, groupID: string, newIndex: number) => {
      dispatch(Actions.ResourceThunkActions.moveGroup(libID, groupID, newIndex));
    },

    resetComponentsGroup: (libID: string, targetGroupID: string, comps: IComponent[]) => {
      dispatch(Actions.ResourceThunkActions.resetComponentsGroup(libID, comps, targetGroupID));
    },

    removeAfterMoveGroup: (libID: string, removeGroupID: string, targetGroupID: string, comps: IComponent[]) => {
      dispatch(Actions.ResourceThunkActions.removeAfterMoveGroup(libID, removeGroupID, targetGroupID, comps));
    },

    removeComponents: (libID: string, componentIDs: string[]) => {
      dispatch(Actions.ResourceThunkActions.removeComponents(libID, componentIDs));
    },

    addRelations: (libID: string, componentID: string, data: Partial<IRelation>[]) => {
      dispatch(Actions.ResourceThunkActions.addRelations(libID, componentID, data));
    },

    patchRelations: (libID: string, componentID: string, data: IRelation[]) => {
      dispatch(Actions.ResourceThunkActions.patchRelations(libID, componentID, data));
    },

    removeRelations: (libID: string, componentID: string, relationIDs: string[]) => {
      dispatch(Actions.ResourceThunkActions.removeRelations(libID, componentID, relationIDs));
    },

    convertToSymbol: (comps: IComponent[]): void => {
      dispatch(Actions.ResourceThunkActions.convertToSymbol(comps));
    },

    convertSymbolToNormal: (comps: IComponent[]): void => {
      dispatch(Actions.ResourceThunkActions.convertSymbolToNormal(comps));
    },

    convertToSymbolOrNormal: (comps: IComponent[], symbolComps: IComponent[]): void => {
      dispatch(Actions.ResourceThunkActions.convertToSymbolOrNormal(comps, symbolComps));
    },
  };
}
