import * as React from 'react';

export interface WithOnCloseProps {
  closeOnContext?: boolean;
  capture?: boolean;
  triggerMethod?: 'click' | 'mousedown';
  onClose(e?: MouseEvent): void;
}

function withAutoClose<P extends WithOnCloseProps>(WrappedComponent: React.ComponentType<P>): React.ComponentType<P> {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  return class extends React.Component<P, any> {
    private domRef = React.createRef();
    public static displayName = `withAutoClose(${displayName})`;

    componentDidMount(): void {
      const { closeOnContext, capture = true, triggerMethod = 'click' } = this.props;
      // 不要立即开始监听事件，会造成打开后，立即就消失了

      setTimeout(() => {
        window.addEventListener(triggerMethod, this.onWindowClick, !!capture);
        closeOnContext && window.addEventListener('contextmenu', this.onWindowClick, true);
        window.addEventListener('keydown', this.onWindowKeyDown, true);
      }, 1);
    }

    componentWillUnmount(): void {
      const { closeOnContext, capture = true, triggerMethod = 'click' } = this.props;
      window.removeEventListener(triggerMethod, this.onWindowClick, !!capture);
      closeOnContext && window.removeEventListener('contextmenu', this.onWindowClick, true);
      window.removeEventListener('keydown', this.onWindowKeyDown, true);
    }

    onWindowKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        this.props.onClose();
      }
    };

    onWindowClick = (e: MouseEvent) => {
      const { closeOnContext } = this.props;
      try {
        // e.button !== 2 解决firefox打开直接关闭的问题
        if (
          this.domRef.current &&
          !(this.domRef.current as HTMLElement).contains(e.target as HTMLElement) &&
          ((!closeOnContext && e.button !== 2) || closeOnContext)
        ) {
          // 这里采用异步关闭的方式，为了跟打开菜单的地方避免冲突
          // 如果先关闭了，会造成打开菜单的点击以为菜单没有打开，又重新打开
          setTimeout(() => {
            this.props.onClose && this.props.onClose(e);
          }, 1);
        }
      } catch {}
    };

    render() {
      return <WrappedComponent forwardedRef={this.domRef} {...this.props} />;
    }
  };
}

export default withAutoClose;
