import * as React from 'react';

import * as echarts from 'echarts';
import classNames from 'classnames';
import { isUndefined, isEqual, debounce, cloneDeep } from 'lodash';

import { rgba2hex } from '@/utils/graphicsUtils';
import { toThousands } from '@/utils/globalUtils';

import i18n from '@/i18n';
import { DeepBlueColor } from '@/consts/colors';
import { IComponentProps } from '@/libs/types';
import { StyleHelper } from '@/helpers/styleHelper';
import { IProperties } from '@/fbs/rp/models/property';
import { IComponentSize } from '@/fbs/rp/models/component';
import IMapChart, { MapType, LegendPosition } from '@/fbs/rp/models/properties/mapChart';
import { IMapChartValue } from '@/fbs/rp/models/chart';
import { IComponentValue } from '@/fbs/rp/models/value';
import { getRegionData } from '@/apis/component';
import { isCtrlKey } from '@/dsm/utils';

import './index.scss';

interface LabelData {
  name: string;
  value: number;
  itemStyle?: { color: string };
}

/**
 * 中国地图必须要map为china才可显示南沙群岛
 * @param region
 */
function getValidateRegion(region: string) {
  return region === '100000' ? 'china' : region;
}

// 演示界面同时加载两个相同区域的地图数据，只加载一次，加载完通过自定义事件发送给其余地图组件
const LOADING_MAP_IDS: string[] = [];

class MapChart extends React.Component<IComponentProps> {
  private chartInstance?: echarts.ECharts;
  private properties: IProperties;
  private size?: IComponentSize;
  private value?: IComponentValue;
  private opacity: number;

  private chartDomID = `map-chart-${this.props.comp.id}-${Date.now()}`;
  private isCtrl = false;

  constructor(props: IComponentProps) {
    super(props);
    const { properties, size, opacity, value } = props.comp;
    this.properties = properties;
    this.size = size;
    this.opacity = opacity;
    this.value = value;
  }

  async componentDidMount() {
    const dom = document.getElementById(this.chartDomID);
    if (!dom) {
      return;
    }

    this.chartInstance = echarts.init(dom, undefined, {
      renderer: 'svg',
    });

    window.addEventListener('mapChartsLoaded', this.listenMapsDataLoaded);

    if (this.props.isPreview) {
      window.addEventListener('keydown', this.listenKeydown);
      window.addEventListener('keyup', this.listenKeyup);
    }
    await this.loadMap();
  }

  async UNSAFE_componentWillReceiveProps(nextProps: IComponentProps) {
    const { properties, size, opacity, value } = nextProps.comp;
    if (
      !isEqual(this.properties, properties) ||
      !isEqual(this.size, size) ||
      !isEqual(this.opacity, opacity) ||
      !isEqual(this.value, value) ||
      !isEqual(nextProps.mapsLoaded, this.props.mapsLoaded)
    ) {
      await this.loadMap(nextProps);
      this.properties = cloneDeep(properties);
      this.size = cloneDeep(size);
      this.opacity = cloneDeep(opacity);
      this.value = cloneDeep(value);
    }
  }

  componentWillUnmount() {
    this.chartInstance?.dispose();
    window.removeEventListener('mapChartsLoaded', this.listenMapsDataLoaded);
    window.removeEventListener('keydown', this.listenKeydown);
    window.removeEventListener('keyup', this.listenKeyup);
  }

  listenKeydown = (event: KeyboardEvent) => {
    const isCtrl = isCtrlKey(event);
    if (isCtrl && isCtrl !== this.isCtrl) {
      this.chartInstance?.setOption({
        series: [{ roam: false }],
      });
    }
    this.isCtrl = isCtrl;
  };

  listenKeyup = () => {
    if (this.isCtrl) {
      this.chartInstance?.setOption({
        series: [{ roam: true }],
      });
    }
    this.isCtrl = false;
  };

  // 地图数据加载完成，重新渲染
  listenMapsDataLoaded = (e: Event) => {
    // 演示界面，判断接收的id
    if (this.props.isPreview) {
      const id = (e as CustomEvent).detail;
      if (id === this.props.comp.id) {
        this.loadMap();
        return;
      }
    }
    // 编辑界面都重新渲染
    this.loadMap();
  };

  // 地图加载动画
  showMapLoading = () => {
    this.chartInstance?.showLoading({
      color: rgba2hex(DeepBlueColor),
      text: i18n('general.loading'),
      spinnerRadius: 8,
    });
  };

  fetchMapJson = async (region: string): Promise<boolean> => {
    // 地图未注册，先注册地图数据
    const validateRegion = getValidateRegion(region);
    if (!echarts.getMap(validateRegion)) {
      if (this.props.isPreview && !RP_CONFIGS.isOfflineDemo) {
        // 演示界面，每次只获取对应的地图数据
        this.showMapLoading();
        if (!LOADING_MAP_IDS.includes(region)) {
          LOADING_MAP_IDS.push(region);
          const data = await getRegionData(region);
          echarts.registerMap(validateRegion, data as any);
          window.dispatchEvent(new CustomEvent('mapChartsLoaded', { detail: this.props.comp.id }));
        } else {
          return false;
        }
      } else {
        // 离线演示包或者编辑界面地图数据加载完成
        if (window.mapCharts) {
          const data = window.mapCharts.find((item: { region: string }) => item.region === region).data;
          echarts.registerMap(validateRegion, data);
        } else {
          this.showMapLoading();
          return false;
        }
      }
    }
    this.chartInstance?.hideLoading();
    return true;
  };

  // 注册地图，渲染数据
  loadMap = async (props = this.props) => {
    try {
      const { properties } = props.comp;
      const region = (properties.mapChart as IMapChart).region;
      const loaded = await this.fetchMapJson(region);
      loaded && this.debouncedRenderChart(props);
    } catch (error) {
      console.error(error);
    }
  };

  debouncedRenderChart = debounce((nextProps) => {
    this.renderChart(nextProps);
  }, 0);

  /**
   * 组装数据
   * @param colorType
   */
  assembleData = (colorType: MapType, value: IMapChartValue) => {
    const { dataSource, color, colors } = value;
    return dataSource!.map((item, index) => {
      const res: LabelData = {
        name: item.name,
        value: item.data[0]!,
      };
      if (colorType === MapType.Monochrome) {
        res.itemStyle = {
          color: rgba2hex(colors![index]),
        };
      } else if (colorType === MapType.Polychrome) {
        res.itemStyle = {
          color: rgba2hex(color!),
        };
      }
      return res;
    });
  };

  getVisualMap = (data: LabelData[], { disabled, position }: IMapChart['legend']) => {
    const _position: Record<string, number> = {};
    switch (position) {
      case LegendPosition.Leftbottom: {
        _position.left = 0;
        break;
      }
      case LegendPosition.Lefttop: {
        _position.left = 0;
        _position.top = 0;
        break;
      }
      case LegendPosition.Rightbottom: {
        _position.right = 0;
        break;
      }
      case LegendPosition.Righttop: {
        _position.right = 0;
        _position.top = 0;
      }
    }

    let min = data[0].value;
    let max = min;

    data.forEach((item) => {
      const _value = item.value;
      min = Math.min(min, _value);
      max = Math.max(max, _value);
    });

    return {
      min,
      max,
      show: !disabled,
      itemHeight: 100,
      text: ['High', 'Low'],
      realtime: false,
      calculable: true,
      ..._position,
      inRange: {
        color: ['lightskyblue', 'yellow', 'orangered'],
      },
    };
  };

  renderFormatter = (name: string, value: IMapChartValue) => {
    const { dataSource, xAxis } = value;
    const index = dataSource!.findIndex((item) => item.name === name);
    if (index === -1) {
      return undefined;
    }

    const boxEle = document.createElement('div');
    boxEle.className = 'map-chart-popup';

    const nameEle = document.createElement('div');
    nameEle.className = 'popup-name';
    nameEle.innerText = name;

    const listEle = document.createElement('ul');
    listEle.className = 'popup-list';

    xAxis!.forEach((item, i) => {
      const itemEle = document.createElement('li');
      const value = dataSource![index].data[i];
      const str = value === undefined || value === null ? 0 : toThousands(value);
      itemEle.innerText = `${item}：${str}`;
      listEle.appendChild(itemEle);
    });

    boxEle.appendChild(nameEle);
    boxEle.appendChild(listEle);

    return boxEle;
  };

  // 图片导出渲染
  renderExportChart = async (props: IComponentProps, chart = this.chartInstance) => {
    const { properties } = props.comp;
    const region = (properties.mapChart as IMapChart).region;
    const validateRegion = getValidateRegion(region);
    if (!echarts.getMap(validateRegion)) {
      const data = await getRegionData(region);
      echarts.registerMap(validateRegion, data as any);
    }
    this.renderChart(props, chart);
  };

  renderChart = (props: IComponentProps, chart = this.chartInstance) => {
    if (!chart) {
      return;
    }

    const { comp } = props;
    const { size, properties } = comp;
    const config = comp.properties.mapChart as IMapChart;
    const value = comp.value as IMapChartValue;
    const colorType = config.type;
    const animation = 'animation' in properties ? !!properties.animation?.value : true;

    let hoverFillColor = config.hover.fill.color;
    let popupFillColor = config.popup.fill.color;
    if (config.hover.fill.disabled && hoverFillColor instanceof Object) {
      hoverFillColor = { ...hoverFillColor, a: 0 };
    }
    if (config.popup.fill.disabled && popupFillColor instanceof Object) {
      popupFillColor = { ...popupFillColor, a: 0 };
    }

    const data = this.assembleData(colorType, value);
    const seriesData = {
      type: 'map',
      map: getValidateRegion(config.region),
      roam: true,
      labelLayout: {
        hideOverlap: true,
      },
      data,
      label: {
        show: config.showLabel,
        ...StyleHelper.initCSSStyleParser({ textStyle: properties.textStyle }).doGetTextStyle(),
      },
      emphasis: {
        disabled: !!config.hover.disabled,
        itemStyle: {
          areaColor: rgba2hex(hoverFillColor),
          borderColor: rgba2hex(config.hover.border.color),
          borderWidth: config.hover.border.disabled ? 0 : 1,
        },
        label: {
          ...StyleHelper.initCSSStyleParser({ textStyle: config.hover.textStyle }).doGetTextStyle(),
        },
      },
      itemStyle: {
        borderColor: rgba2hex(config.regionBorder.color),
      },
    };

    const chartOption: Record<string, any> = {
      visualMap: colorType === MapType.Heatmap ? this.getVisualMap(data, config.legend) : { show: false },
      series: [seriesData],
      animation: props.isPreview && animation,
    };

    if (!config.popup.disabled) {
      chartOption.tooltip = {
        trigger: 'item',
        backgroundColor: rgba2hex(popupFillColor),
        borderColor: rgba2hex(config.popup.border.color),
        borderWidth: config.popup.border.disabled ? 0 : 1,
        textStyle: { ...StyleHelper.initCSSStyleParser({ textStyle: config.popup.textStyle }).doGetTextStyle() },
        formatter: (params: { name: string }) => {
          return this.renderFormatter(params.name, value);
        },
        padding: [8, 16],
      };
    }

    chart.resize({
      width: size.width,
      height: size.height,
    });
    chart.clear();
    chart.setOption(chartOption, true);
  };

  render() {
    const { isPreview, comp } = this.props;
    const { properties, size, opacity } = comp;
    const parser = StyleHelper.initCSSStyleParser(properties);

    const boxStyle: React.CSSProperties = {
      ...parser.getFillStyle(),
      ...parser.getShadowStyle(),
      ...parser.getStrokeStyle(),
      ...parser.getRadiusStyle(size),
      opacity: isUndefined(opacity) ? 1 : opacity / 100,
      pointerEvents: isPreview ? 'auto' : 'none',
    };

    return (
      <div
        id={this.chartDomID}
        style={boxStyle}
        className={classNames('map-chart', { 'map-chart-preview': isPreview })}
      ></div>
    );
  }
}

export default MapChart;
