import { cloneDeep } from 'lodash';
import { isNumber } from 'util';

// import apis from '@apis';

import { getBoundsWithPoints, ISize } from '@utils/boundsUtils';
import { depthClone, removeItemFromArray, sameNumber, roundNumberObject } from '@utils/globalUtils';

import { StrokePosition } from '@/fbs/rp/models/properties/stroke';
import { IBounds } from '@fbs/common/models/common';
import { IComponentData } from '@fbs/rp/models/component';
import { SessionInfo } from '@fbs/rp/models/io';
import { CompoundOperation } from '@fbs/rp/models/properties/path';
import { IConnectorLineValue, ILineValue, IPathValue } from '@fbs/rp/models/value';
import { ArtboardPatches, Ops, PagePatches } from '@fbs/rp/utils/patch';
// import IArtboard from '@/fbs/rp/models/artboard';
import CoreEditor /*,{ IArtboardPasteInfo }*/ from '@editor/core';

import { componentToSvgValue } from '@/helpers/exportSvgHelper';
import { centerRotatePoints, getAllResizePoints, getAngleOfLine, getDragPoints } from '@/helpers/rotateHelper';
import {
  findParentByFiltering,
  resetID,
  resetInteractionTargetID,
  resetValueMap,
  tansPointInArtBoardToGroup,
  updateCompDataBaseOnContainer,
  getArtboardInContentPanleV2,
  getAllContentPanelv2RefArtboardsData,
} from '@helpers/componentHelper';
import editorValidate from '@helpers/editorValidate';
import { getNewID } from '@helpers/idHelper';
import { coverPatches, mergePatches, sortPatches, getEmptyArtboardPatchesOfArtboardSelf } from '@helpers/patchHelper';
import {
  compoundPath,
  getPathItemOfComp,
  isClosedPathWithArea,
  resetPathValueRotate,
  supportedPathProperties,
} from '@helpers/pathHelper';
import { getCompIsContentPanleFragments } from '@helpers/contentPanelv2Helper';

import {
  UIComponent,
  UIFragment,
  UICompoundPathComponent,
  UIConnectorComponent,
  UIContainerComponent,
  UIContentPanelComponent,
  UIContentPanelV2Component,
  UIGridPanelComponent,
  UIListLayoutSealedComponent,
  UIStackPanelComponent,
  UITableComponent,
  UIWrapPanelComponent,
  UIGroupComponent,
  UIMultipleSelectPanelComponent,
} from '@editor/comps';
import { ComponentChangeType, getMinMaxXY } from '@editor/comps/resizeHelper';
import { convertRemovedComponentsToAddOps } from '@editor/corePartial/helper';
import CoreState from '@editor/corePartial/state';
// import { cloneArtboardData } from '@editor/coreHelper';
import Doc from '@editor/document';
import { CGroup, CMultipleSelect, CLine, CCompoundPath, CPath, CTable } from '@libs/constants';
import { isBasicComp } from '@libs/helper';

import ZOrderModel from '@consts/enums/zOrderType';

import { getDefaultComponentName, makeComponent } from '../../libs/libs';
import { GroupComponentsCommand } from '../commands';

function getCornerCoordinate(size: ISize) {
  const { width, height } = size;
  return {
    leftTop: { x: 0, y: 0 },
    rightTop: { x: width, y: 0 },
    rightBottom: { x: width, y: height },
    leftBottom: { x: 0, y: height },
  };
}

export type AddComponentsOptions = {
  artboardID?: string;
  autoSelect?: boolean;
  index?: number;
  isPaste?: boolean;
  resetCloneOffset?: boolean;
};
/**
 * 关于组的操作
 */
export default abstract class CoreGroup extends CoreState {
  hasNestSymbolDetach?: boolean = false;

  protected constructor(doc: Doc, appID: string, nodeID: string, session: SessionInfo) {
    super(doc, appID, nodeID, session);
  }

  /**
   * 添加一批组件
   */
  doAddComponents(components: IComponentData[], options?: AddComponentsOptions) {
    const { artboardID, index, resetCloneOffset = true } = options || {};
    resetCloneOffset && this.resetCloneOffset();
    let container = this.activeContainer;
    if (
      (container instanceof UIStackPanelComponent ||
        container instanceof UIWrapPanelComponent ||
        container instanceof UIGridPanelComponent) &&
      components.some((comp) => {
        return comp.type !== 'panel';
      })
    ) {
      return null;
    }

    this.restoreToOriginalState();
    if (artboardID && this.activeArtboard.artboardID !== artboardID) {
      const artboard = [this.doc.mainArtboard, ...this.doc.fragments].find(
        (artboard) => artboard.artboardID === artboardID,
      );
      if (artboard) {
        this.setActiveArtboard(artboard);
        container = artboard;
      }
    }

    // 去除多余的选中状态
    const select = container.select;
    let data = components;
    if (select && select.target === 'child') {
      const { maxCount } = select;
      if (maxCount !== -1) {
        let count = container.components.reduce((acc, curr) => {
          if (curr.toJSON().selected) {
            acc++;
          }
          return acc;
        }, 0);

        data = components.map((item) => {
          const comp = depthClone(item);
          if (comp.selected) {
            count++;
          }
          if (count >= maxCount) {
            comp.selected = false;
          }
          return comp;
        });
      }
    }
    data.forEach((compData) => updateCompDataBaseOnContainer(container, compData));
    const { patches, newActiveGroup } = container.addComponents(data, index);
    newActiveGroup && this.setActiveContainer(newActiveGroup);
    return patches;
  }

  doDetachSymbolComponents(components: IComponentData[]): IComponentData[] {
    return components.map((data) => {
      if (data.components?.length) {
        data.components = this.doDetachSymbolComponents(data.components);
      }
      if (data.symbol) {
        this.hasNestSymbolDetach = true;
        return {
          ...data,
          type: CGroup,
        };
      }
      return data;
    });
  }

  addArtboardPatches(artboardID: string) {
    return {
      ROOT: {
        do: {
          self: [Ops.add(artboardID, '')],
        },
        undo: {
          self: [Ops.remove(artboardID)],
        },
      },
    };
  }

  removeArtboardPatches(artboardID: string) {
    return {
      ROOT: {
        do: {
          self: [Ops.remove(artboardID)],
        },
        undo: {
          self: [Ops.add(artboardID, '')],
        },
      },
    };
  }

  addComponents(components: IComponentData[], options?: AddComponentsOptions /*,cloneArtboards?: IArtboard[]*/) {
    this.batchSyncNoticeUpdate(() => {
      if (this.isSymbolEditor) {
        components = this.doDetachSymbolComponents(components);
      }
      const patches = this.doAddComponents(components, options);
      patches && this.update(patches);
      //   //如果是粘贴的情况下,需要检查是否存在内容画板，连带clone辅助画板
      //   if (options?.isPaste) {
      //     patches &&
      //       this.cloneContentPanelOwnerFragments(components, cloneArtboards).then((batchAddFragmentPatches) => {
      //         if (!batchAddFragmentPatches) {
      //           this.update(patches);
      //         } else {
      //           this.update({
      //             ...patches,
      //             ROOT: batchAddFragmentPatches,
      //           });
      //         }
      //       });
      //   } else {
      //     //正常添加
      //     patches && this.update(patches);
      //   }
      if (options?.autoSelect) {
        this.selectByIDs(
          components.map((comp) => comp._id),
          false,
        );
        this.notifyUpdateListener([options.artboardID || this.activeArtboard.artboardID]);
        this.pushArtboardSelectedInfo();
      }
    });
  }

  //新内容画板,克隆添加相关的辅助画板
  //   cloneContentPanelOwnerFragments(components: IComponentData[],cloneArtboards: IArtboard[] = []): Promise<ArtboardPatches | void> {
  //     const contentPanelComps = components.filter((e) => e.type === CContentPanelV2);
  //     if (!contentPanelComps.length) {
  //       return Promise.resolve();
  //     }
  //     const collectionArtboardCloneInfos = contentPanelComps
  //       .map((comp) => {
  //         return comp.value
  //           .map((id: string) => {
  //             const targetArtboards = cloneArtboards.find((artboard) => artboard._id === id);
  //             if (!targetArtboards) {
  //               return;
  //             }
  //             const { position } = targetArtboards;
  //             const clonedArtboardData = cloneArtboardData(targetArtboards) as IArtboard;
  //             //重置ownerId
  //             clonedArtboardData.ownerID = comp._id;
  //             // 粘贴画板时，页面id改为选中页面的id
  //             clonedArtboardData.nodeID = this.activeArtboard.doc.pageID;
  //             return {
  //               data: clonedArtboardData,
  //               position,
  //             };
  //           })
  //           .filter((e: IArtboardPasteInfo | undefined) => e) as IArtboardPasteInfo[];
  //       })
  //       .reduce((preArtboardInfos, nextArtboardInfos) => {
  //         return preArtboardInfos.concat(nextArtboardInfos);
  //       }, []);
  //     //如果没有需要clone的画板
  //     if(!collectionArtboardCloneInfos.length){
  //         return Promise.resolve();
  //     }
  //     //添加克隆画板
  //     return Promise.all(
  //       collectionArtboardCloneInfos.map(({ data, position }) => {
  //         return apis.artboard.cloneArtboard(this.nodeID, data as IArtboard, position);
  //       }),
  //     ).then((newArtboardIDs) => {
  //       const batchAddFragmentPatches = newArtboardIDs.reduce((prevPatches, newArtboardID) => {
  //         return mergePatches(prevPatches, this.addArtboardPatches(newArtboardID)['ROOT']);
  //       }, getEmptyArtboardPatchesOfArtboardSelf());
  //       return batchAddFragmentPatches;
  //     });
  //   }

  appendChildren(components: IComponentData[], group: UIContainerComponent) {
    const select = group.select;
    let data = components;
    if (select && select.target === 'child') {
      const { maxCount } = select;
      if (maxCount !== -1) {
        let count = group.components.reduce((acc, curr) => {
          if (curr.toJSON().selected) {
            acc++;
          }
          return acc;
        }, 0);

        data = components.map((item) => {
          const comp = depthClone(item);
          if (comp.selected) {
            count++;
          }
          if (count >= maxCount) {
            comp.selected = false;
          }
          return comp;
        });
      }
    }
    const { patches } = group.addComponents(data);

    this.update(patches);
  }

  /**
   * 从流程线自动连接到一个新增的组件上
   * @param {IComponentData} component
   * @param {UIConnectorComponent} connector
   * @param endPointDirection
   * @param {AddComponentsOptions} options
   */
  addComponentFromConnector(
    component: IComponentData,
    connector: UIConnectorComponent,
    endPointDirection: 'left' | 'right' | 'top' | 'bottom',
    options?: AddComponentsOptions,
  ) {
    this.batchSyncNoticeUpdate(() => {
      const patches = this.doAddComponents([component], options);
      if (patches) {
        const { endPoint } = connector.value as IConnectorLineValue;
        const artboardID = this.activeArtboard.artboardID;
        const id = connector.id;
        patches[artboardID].do[id] = [
          Ops.replace('./value/endPoint', { ...endPoint, direction: endPointDirection, id: component._id }),
        ];
        patches[artboardID].undo[id] = [Ops.replace('./value/endPoint', endPoint)];
        this.update(patches);
      }
      if (options?.autoSelect) {
        this.selectByIDs([component._id], false);
        this.notifyUpdateListener([options.artboardID || this.activeArtboard.artboardID]);
        this.pushArtboardSelectedInfo();
      }
    });
  }

  /**
   * 删除选中的组件
   */
  removeSelectedComponent(): boolean {
    if (!this.hasSelectedComponents) {
      return false;
    }
    if (this.allSelectedComponentAreLocked) {
      return false;
    }

    if (!editorValidate.allowChildrenStructureChange(this.activeContainer, 'remove')) {
      return false;
    }

    const info = this.activeContainer.removeComponents(this.allSelectedUnLockedComps);
    //如果存在新的多画板,需要删除关联的画板
    let contentPanelRelatingFragments: UIFragment[] = [];
    this.allSelectedUnLockedComps.forEach((comp) => {
      contentPanelRelatingFragments = contentPanelRelatingFragments.concat(
        getCompIsContentPanleFragments(comp, this.doc),
      );
    });
    //检查深层关联的画板
    contentPanelRelatingFragments = contentPanelRelatingFragments.concat(
      contentPanelRelatingFragments
        .map((fragments) => {
          return getArtboardInContentPanleV2(fragments.$data)
            .map((comp) => getAllContentPanelv2RefArtboardsData(comp, this.doc))
            .reduce((prev, next) => {
              return prev.concat(next);
            }, [])
            .map((fragmentData) => this.doc.getArtboardByID(fragmentData._id))
            .filter((e) => e);
        })
        .reduce((prev, next) => {
          return prev.concat(next);
        }, []) as UIFragment[],
    );
    //合并删除关联画板
    if (contentPanelRelatingFragments && contentPanelRelatingFragments.length) {
      const removeCorrelationArtboardsPatchs = contentPanelRelatingFragments.reduce((prevPatches, { artboardID }) => {
        return mergePatches(prevPatches, ((this as unknown) as CoreEditor).removeArtboardPatches(artboardID)['ROOT']);
      }, getEmptyArtboardPatchesOfArtboardSelf());
      info.patches['ROOT'] = coverPatches(
        info.patches['ROOT'] || getEmptyArtboardPatchesOfArtboardSelf(),
        removeCorrelationArtboardsPatchs,
      );
    }

    if (info.newActiveGroup) {
      this.setActiveContainer(info.newActiveGroup);
    }
    this.selectByIDs([], false);
    this.update(info.patches);
    return true;
  }

  /**
   * 多选下拉组件子项移除时，同步处理组件的value值
   * @param patches
   * @param group
   * @param childrenComps
   */
  updateWithMultiSelectChildrenRemoved(patches: ArtboardPatches, group: UIComponent, childrenComps: UIComponent[]) {
    const multiSelect = findParentByFiltering(group, (comp: UIComponent) => comp.type === CMultipleSelect);
    if (multiSelect) {
      childrenComps.forEach((item) => {
        const valuePatches = (multiSelect as UIMultipleSelectPanelComponent).getRemoveItemPatches(item);
        if (valuePatches) {
          mergePatches(patches, valuePatches);
        }
      });
    }
  }

  /**
   * 删除复合组件中的子元素
   * @param {UIComponent[]} comps
   * @param {UIContainerComponent} group
   * @description 当复合组件是一个列表类型时，如果删除最后一项时，自动添加一个新的项
   */
  removeSealedChildComponents(comps: UIComponent[], group: UIContainerComponent) {
    const selectItem = (data: IComponentData) => {
      if (select.target === 'child' && select.maxCount > 0) {
        data.selected = true;
        const { states } = data;
        if (!states) {
          data.states = {
            checked: {
              enabled: true,
              properties: {},
            },
          };
        } else {
          if (!states['checked']) {
            states['checked'] = {
              enabled: true,
              properties: {},
            };
          }
        }
      }
    };
    const len = group.components.length;
    const template = group.components[len - 1].toJSON();
    const info = group.removeComponents(comps);
    const { patches } = info;
    const artboardID = this.activeArtboard.artboardID;
    const groupID = group.id;
    const selIDs: string[] = [];
    const select = group.select!;
    if (len - comps.length === 0) {
      const data = depthClone(template);
      selectItem(data);
      const idMap = resetID([data]);
      resetInteractionTargetID([data], idMap);
      resetValueMap([data], idMap);
      let addPatches: PagePatches;
      if (group instanceof UIListLayoutSealedComponent) {
        addPatches = group.addComponentsWhenContainerEmpty([data]);
      } else {
        const { patches: newPatches } = group.addComponents([data]);
        addPatches = newPatches;
      }
      patches[artboardID].do[groupID].push(...addPatches[artboardID].do[groupID]);
      patches[artboardID].undo[groupID].push(...addPatches[artboardID].undo[groupID]);
      selIDs.push(data._id);
    } else {
      if (select.target === 'child' && select.maxCount > 0) {
        const otherSelect = group.components.filter((c) => {
          return !comps.includes(c) && c.selected;
        });
        if (otherSelect.length === 0) {
          let firstIndex = group.components.indexOf(comps[0]);
          let lastIndex = group.components.indexOf(comps[comps.length - 1]);
          let index = firstIndex;
          if (firstIndex > lastIndex) {
            firstIndex = lastIndex;
            lastIndex = index;
          }
          index = firstIndex - 1;
          if (index < 0) {
            index = lastIndex + 1;
          }
          if (index >= group.components.length) {
            for (index; index > firstIndex; index < lastIndex) {
              const c = group.components[index];
              if (!comps.includes(c)) {
                break;
              }
            }
          }
          if (index >= 0 && index < group.components.length) {
            const nextItem = group.components[index];
            if (nextItem) {
              const op = nextItem.modifyGeneralProperties('selected', true);
              mergePatches(patches[this.activeArtboard.artboardID], op);
            }
          }
        }
      }
    }

    this.updateWithMultiSelectChildrenRemoved(patches[this.activeArtboard.artboardID], group, comps);

    this.batchSyncNoticeUpdate(() => {
      this.update(patches);
      if (group === this.activeContainer) {
        if (selIDs.length) {
          this.selectByIDs(selIDs, false);
        } else {
          this.clearSelected();
        }
      }
    });
  }

  /**
   * 编组
   */
  group() {
    // @ts-ignore
    const command = new GroupComponentsCommand(this);
    command.execute();
  }

  protected doUnGroup(group: UIContainerComponent): { patches: ArtboardPatches; ids: string[] } {
    const groupParent = group.parent!;
    // @ts-ignore
    const groupIndexAtParent = groupParent.components.findIndex((comp) => comp === group);

    const rotate = group.rotate;
    let sumContainer: UIContainerComponent | undefined = group;
    // let parentRotate = 0;
    while (sumContainer instanceof UIComponent && !sumContainer.isArtboard) {
      // parentRotate += sumContainer.rotate;
      sumContainer = sumContainer.parent;
    }
    const compsDataAfterUnGroup = group.components.map((uiComp) => {
      if (uiComp.type === CLine) {
        const compData = cloneDeep(uiComp.toJSON());
        this.resetInfoWhenCompIsLine(uiComp, compData);
        return compData;
      } else {
        if (uiComp.isConnector) {
          const comp = uiComp as UIConnectorComponent;
          return comp.resetConnectPointWhenUnGroup();
        }
        const compData = uiComp.resetPositionWhenUnGroup();
        compData.rotate = uiComp.rotate + rotate || 0;
        return compData;
      }
    });

    const addingCompsDataForUpperContainer = compsDataAfterUnGroup.map((compData) => {
      const { _id: id, position, size, rotate } = compData;
      return {
        id,
        type: ComponentChangeType.Add,
        position,
        size,
        rotate: rotate || 0,
      };
    });
    addingCompsDataForUpperContainer.push({
      id: group.id,
      type: ComponentChangeType.Removed,
      position: group.position,
      size: group.size,
      rotate: group.rotate || 0,
    });

    //查找解组后孤立的流程线
    const aloneConnects: { index: number; comp: IComponentData }[] = [];
    const aloneConnectIds: string[] = [];
    groupParent.components.forEach((comp, index) => {
      if (comp.isConnector && (comp as UIConnectorComponent).isAloneLineAfterDelete([group.id])) {
        aloneConnects.push({ index, comp: comp.toJSON() });
        aloneConnectIds.push(comp.id);
      }
    });

    const artboardPatches: ArtboardPatches = {
      do: {
        [groupParent.id]: [
          Ops.removeChildren([group.id, ...aloneConnectIds]),
          Ops.addChildren(`${groupIndexAtParent}`, compsDataAfterUnGroup),
        ],
      },
      undo: {
        [groupParent.id]: [
          Ops.removeChildren(compsDataAfterUnGroup.map((comp) => comp._id)),
          Ops.addChildren(`${groupIndexAtParent}`, [group.toJSON()]),
          ...aloneConnects.map((connect) => Ops.addChildren(`${connect.index}`, [connect.comp])),
        ],
      },
    };

    // 要刪除組，并且添加組内的子给上层，那么会对与父元素的position带来变化
    if (!groupParent.isArtboard) {
      const res = this.activeContainer.getPositionPatchesOfChildrenChanged(addingCompsDataForUpperContainer, true);
      coverPatches(artboardPatches, res.patches);
    }
    return {
      patches: artboardPatches,
      ids: compsDataAfterUnGroup.map((comp) => comp._id),
    };
  }

  /**
   * 解组
   * @returns {boolean}
   */
  unGroup(): boolean {
    if (!this.hasOnlyOneSelectedComponents) {
      return false;
    }
    const firstComponent = this.firstSelectedComponent;
    if (!firstComponent) {
      return false;
    }
    let isGroup = firstComponent.isGroup;
    if (!isGroup) {
      return false;
    }
    if (firstComponent.locked) {
      return false;
    }
    if (!editorValidate.allowChildrenStructureChange(this.activeContainer)) {
      return false;
    }
    if (this.activeContainer.type === CCompoundPath) {
      return false;
    }
    const group = (firstComponent as unknown) as UIContainerComponent;
    // 如果找不到组的父，不支持解组，这是一种非法的情况，只有 artboard 才没有父，artboard 不能被解组
    if (!group.parent) {
      return false;
    }

    const { patches: artboardPatches, ids } = this.doUnGroup(group);

    const ownerArtboardID = this.activeContainer.ownerArtboardID;
    const patches: PagePatches = {
      [ownerArtboardID]: artboardPatches,
    };
    this.batchSyncNoticeUpdate(() => {
      this.update(patches);
      this.selectByIDs(ids, false);
    });
    return true;
  }

  unGroupMore(): void {
    if (!this.hasSelect() || this.hasSelectLockedComps) {
      return;
    }
    const comps = this.selectedComponentList;
    if (comps.length === 1) {
      this.unGroup();
      return;
    }

    const atomComs: UIComponent[] = [];
    const groupComps: UIGroupComponent[] = [];
    comps.forEach((comp) => {
      if (comp instanceof UIGroupComponent) {
        groupComps.push(comp);
      } else {
        atomComs.push(comp);
      }
    });
    if (!groupComps.length) {
      return;
    }
    const selIDs = atomComs.map((comp) => comp.id);
    const patches: ArtboardPatches = { do: {}, undo: {} };
    groupComps.reverse().forEach((group) => {
      const {
        patches: { do: _do, undo },
        ids,
      } = this.doUnGroup(group);
      Object.keys(_do).forEach((id) => {
        if (patches.do[id]) {
          patches.do[id].push(..._do[id]);
        } else {
          patches.do[id] = [..._do[id]];
        }
      });
      Object.keys(undo).forEach((id) => {
        if (patches.undo[id]) {
          patches.undo[id].push(...undo[id]);
        } else {
          patches.undo[id] = [...undo[id]];
        }
      });
      selIDs.push(...ids);
    });
    this.batchSyncNoticeUpdate(() => {
      this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
      this.selectByIDs(selIDs, false);
    });
  }

  /**
   * 是否可以路径解除合成
   * @type {boolean}
   */
  public get isSelectCompoundPath(): boolean {
    return (
      this.selectedComponents.size === 1 &&
      !this.hasSelectLockedComps &&
      this.firstSelectedComponent?.type === CCompoundPath
    );
  }

  /**
   * 是否选择的组件全部为复合路径
   */
  public get isOnlySelectCompoundPath(): boolean {
    return (
      !!this.selectedComponentList.length &&
      this.selectedComponentList.every((comp) => comp.type === CCompoundPath) &&
      !this.hasSelectLockedComps
    );
  }

  /**
   * 是否可以复合路径转单路径
   */
  public get couldConvertToPath(): boolean {
    if (this.selectedComponents.size < 1) {
      return false;
    }
    if (this.activeContainer.isSealed || !editorValidate.allowChildrenStructureChange(this.activeContainer)) {
      return false;
    }
    if (this.hasSelectLockedComps) {
      return false;
    }

    const couldConvert = this.selectedComponentList.every((comp) => {
      if (editorValidate.allowPathEditor(comp)) {
        return true;
      } else if (comp.type === CCompoundPath && (comp.value as IPathValue[]).length === 1) {
        return true;
      }
      return false;
    });

    const hasRotatePath = this.selectedComponentList.some(
      (comp) => editorValidate.allowPathEditor(comp) && comp.rotate,
    );
    const hasCompoundSinglePath = this.selectedComponentList.some(
      (comp) => comp.type === CCompoundPath && (comp.value as IPathValue[]).length === 1,
    );

    return couldConvert && (hasRotatePath || hasCompoundSinglePath);
  }

  /**
   * 是否可以路径合成
   * @type {boolean}
   */
  public get couldCompoundPath(): boolean {
    const pathCompsCount = this.selectedComponentList.filter((comp) => {
      // 基元组件布尔运算
      // return comp.lib?.type !== 'hot-area' && [CPath, CCompoundPath].includes(comp.type);
      return comp.allowPathEditor;
    }).length;
    return pathCompsCount > 1 && pathCompsCount === this.selectedComponentList.length;
  }

  /**
   * 切换路径闭合
   * @memberof CoreGroup
   */
  closePath = () => {
    // 尺寸位置都可能变化
    if (this.selectedComponents.size !== 1) return false;
    if (!isBasicComp(this.selectedComponentList[0].type)) return false;

    const path = this.selectedComponentList[0];
    if (path.type === CPath) {
      const patches: ArtboardPatches = {
        do: {},
        undo: {},
      };
      const value = path.value as IPathValue;
      const {
        size,
        position,
        properties: { polygon },
      } = path;

      const newValue = depthClone(value);
      newValue.closed = !newValue.closed;

      const newPathComp = depthClone(path.toJSON());

      // 取新的value
      newPathComp.value = newValue;
      // 去掉旋转的影响
      newPathComp.rotate = 0;
      const pathItem = getPathItemOfComp(newPathComp);
      const { bounds } = pathItem;

      const newSize = { ...size };
      const newPosition = { ...position };
      isNumber(bounds?.width) && (newSize.width = bounds?.width!);
      isNumber(bounds?.height) && (newSize.height = bounds?.height!);

      const boundsLeft = bounds?.left || 0;
      const boundsTop = bounds?.top || 0;

      const center = { x: size.width / 2, y: size.height / 2 };
      const newCenter = { x: boundsLeft + newSize.width / 2, y: boundsTop + newSize.height / 2 };
      // 计算新的中心点绕原中心点旋转n°后与新中心点旋转前的偏移 + 尺寸变化的偏移；
      const newRotateCenter = centerRotatePoints([newCenter], center, path.rotate)[0];
      const offset = {
        x: newRotateCenter.x - newCenter.x + boundsLeft,
        y: newRotateCenter.y - newCenter.y + boundsTop,
      };

      if (isNumber(bounds?.left)) {
        // 中心点x方向偏移
        newPosition.x += offset.x;
        newValue.data.forEach((item) => (item.point.x -= bounds?.left!));
      }
      if (isNumber(bounds?.top)) {
        // 中心点x方向偏移
        newPosition.y += offset.y;
        newValue.data.forEach((item) => (item.point.y -= bounds?.top!));
      }
      patches.do[path.id] = [
        Ops.replace('./value', newValue),
        Ops.replace('./position', roundNumberObject(newPosition)),
        Ops.replace('./size', roundNumberObject(newSize)),
      ];
      patches.undo[path.id] = [
        Ops.replace('./value', value),
        Ops.replace('./position', position),
        Ops.replace('./size', size),
      ];

      if (polygon) {
        patches.do[path.id].push(Ops.remove('./properties/polygon'));
        patches.undo[path.id].push(Ops.add('./properties/polygon', polygon));
      }
      if (!isClosedPathWithArea(value)) {
        const strokePatches = path.updatePathStrokePositionOfAllState(StrokePosition.center);
        if (strokePatches) {
          mergePatches(patches, strokePatches);
        }
      }
      this.update({
        [this.activeContainer.ownerArtboardID]: patches,
      });
    } else {
      const svgValue = componentToSvgValue(path.toJSON());
      if (!svgValue) {
        return;
      }
      const { data } = svgValue;
      if (!data.length) {
        return;
      }
      const bounds: IBounds = {
        width: path.size.width,
        height: path.size.height,
        left: path.position.x,
        top: path.position.y,
        right: path.position.x + path.size.width,
        bottom: path.position.y + path.size.height,
      };
      const newPathValue = data[0];
      newPathValue.closed = false;
      this.applyPathData(data[0], bounds, path.position);
    }
  };

  /**
   * 复合路径转单路径
   * 基元组件转单路径
   * 单路径转正向单路径
   */
  convertToPath = (appType: string = 'phone') => {
    if (!this.couldConvertToPath) {
      return false;
    }
    const singlePaths = [...this.selectedComponentList];
    if (!singlePaths.length) {
      return false;
    }

    const newPaths = singlePaths.map((comp) => {
      const path = makeComponent('basic', CPath, appType)!;
      const { name, properties, opacity, layout, row, column, rotate, position } = comp;
      path.name = name || getDefaultComponentName(path.type, path.lib);

      const newProperties = Object.assign({}, path.properties, properties);
      // 移除多余的
      Object.keys(newProperties).forEach((key) => {
        if (!supportedPathProperties.includes(key)) {
          delete newProperties[key];
        }
      });

      path.properties = newProperties;
      path.opacity = opacity;
      path.layout = layout;
      path.row = row;
      path.column = column;

      const data = componentToSvgValue(comp.toJSON());
      const originValue: IPathValue = data?.data[0] || {
        data: [],
        closed: false,
      };
      const { newValue, newSize, newPosition } = resetPathValueRotate(originValue, rotate, position);
      if (comp.parent?.type !== CTable) {
        path.position = newPosition;
      } else {
        path.position = { x: 0, y: 0 };
      }
      path.value = newValue;
      path.size = newSize;
      path.rotate = 0;
      return path;
    });

    const noConnectLine: UIConnectorComponent[] = []; //删除孤立的线
    this.activeContainer.components
      .filter((comp) => comp.isConnector)
      .forEach((c) => {
        const comp = c as UIConnectorComponent;
        const startID = comp.getStartCompID();
        const endID = comp.getEndCompID();
        const startCompIsSelected = startID && singlePaths.some((sc) => sc.id === startID);
        const endCompIsSelected = endID && singlePaths.some((sc) => sc.id === endID);
        if (startCompIsSelected || endCompIsSelected) {
          noConnectLine.push(comp);
        }
      });

    const removeComps = singlePaths.concat(noConnectLine);
    const revertAdd = convertRemovedComponentsToAddOps(this.activeContainer, removeComps);

    let patches: PagePatches = {};
    if (this.activeContainer instanceof UIGroupComponent || this.activeContainer instanceof UICompoundPathComponent) {
      patches = this.activeContainer.replaceComponents(newPaths, removeComps).patches;
    } else {
      const index = this.activeContainer.components.findIndex((c) => c.id === removeComps[0]?.id);
      patches = {
        [this.activeContainer.ownerArtboardID]: {
          do: {
            [this.activeContainer.id]: [
              Ops.removeChildren(removeComps.map((comp) => comp.id)),
              Ops.addChildren(`${index}`, newPaths),
            ],
          },
          undo: {
            [this.activeContainer.id]: [...revertAdd, Ops.removeChildren(newPaths.map((comp) => comp._id))],
          },
        },
      };
    }

    sortPatches(patches[this.activeContainer.ownerArtboardID]);
    this.update(patches);
    this.selectByIDs(
      newPaths.map((comp) => comp._id),
      false,
    );
  };

  /**
   * 生成复合路径
   * @memberof CoreGroup
   */
  compoundPath = (compoundType: CompoundOperation): boolean => {
    if (this.selectedComponents.size === 0) {
      return false;
    }
    if (this.activeContainer.isSealed || !editorValidate.allowChildrenStructureChange(this.activeContainer)) {
      return false;
    }

    // const selectedIDs = this.selectedComponentList.map((c) => c.id);
    const paths = this.selectedComponentList.filter(
      // 过滤参与布尔运算的基元组件、路径、复合路径，保持原本的图层顺序
      (comp) => comp.allowPathEditor,
      // selectedIDs.includes(comp.id) && comp.lib?.type !== 'hot-area' && [CPath, CCompoundPath].includes(comp.type),
    );
    if (paths.length < 2) {
      return false;
    }

    const newComp = compoundPath(
      paths.map((item) => item.toJSON()),
      compoundType,
    );

    const remainComponents = this.activeContainer.components;
    const noConnectLine: UIConnectorComponent[] = []; //删除孤立的线
    remainComponents
      .filter((comp) => comp.isConnector)
      .forEach((c) => {
        const comp = c as UIConnectorComponent;
        const startID = comp.getStartCompID();
        const endID = comp.getEndCompID();
        const startCompIsSelected = startID && paths.some((sc) => sc.id === startID);
        const endCompIsSelected = endID && paths.some((sc) => sc.id === endID);
        if (startCompIsSelected || endCompIsSelected) {
          noConnectLine.push(comp);
        }
      });

    const toCompoundComponents = [...paths, ...noConnectLine];
    // 将数组A中不需要合并成组的筛选出来，剩下的元素的个数即为我们需要的index
    const restComps = remainComponents.filter((comp) => !toCompoundComponents.find((item) => item.id === comp.id));

    let editeContainer: UIContainerComponent | undefined = this.activeContainer;

    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };

    if (this.activeContainer.type === CCompoundPath) {
      // 合成组件为空 同组没有剩余组件
      const grandpa = this.activeContainer.parent!;
      if (restComps.length === 0 && !newComp) {
        if (grandpa.components.length > 2) {
          const removePatches = this.activeContainer.removeSelfPatches();
          mergePatches(patches, removePatches);
          let newContainer;
          if (grandpa.type === CCompoundPath) {
            const newComps = depthClone(grandpa.components.map((comp) => comp.toJSON()));
            removeItemFromArray(
              newComps,
              newComps.find((item) => item._id === this.activeContainer.id),
            );
            const { newActiveGroup } = ((grandpa as unknown) as UICompoundPathComponent).refreshPatchesWithNewChildren(
              newComps,
              patches,
            );
            newContainer = newActiveGroup;
          }
          editeContainer = newContainer || grandpa;
        } else if (grandpa.components.length === 2) {
          if (grandpa.type === CCompoundPath) {
            const newComps = depthClone(grandpa.components.map((comp) => comp.toJSON()));
            removeItemFromArray(
              newComps,
              newComps.find((item) => item._id === this.activeContainer.id),
            );
            const { newActiveGroup } = ((grandpa as unknown) as UICompoundPathComponent).refreshPatchesWithNewChildren(
              newComps,
              patches,
            );
            editeContainer = newActiveGroup || grandpa;
          } else if (grandpa.type === CGroup) {
            const removePatches = grandpa.removeSelfPatches();
            mergePatches(patches, removePatches);
            const newComps = depthClone(grandpa.components.map((comp) => comp.toJSON()));
            removeItemFromArray(
              newComps,
              newComps.find((item) => item._id === this.activeContainer.id),
            );
            newComps[0].position = new UIComponent(newComps[0], grandpa).getPositionWithoutParent();
            newComps[0].rotate = (newComps[0].rotate || 0) + grandpa.rotate;

            newComps[0]._id = getNewID();
            const addPatches: ArtboardPatches = {
              do: {
                [grandpa.parent!.id]: [Ops.addChildren('-1', newComps)],
              },
              undo: {
                [grandpa.parent!.id]: [Ops.removeChildren(newComps.map((item) => item._id))],
              },
            };
            mergePatches(patches, addPatches);
            editeContainer = grandpa.parent;
          }
        }
      } else if (restComps.length === 0 && newComp) {
        newComp.position = new UIComponent(newComp, this.activeContainer).getPositionWithoutParent();
        newComp.rotate = (newComp.rotate || 0) + this.activeContainer.rotate;
        const removePatches = this.activeContainer.removeSelfPatches();
        mergePatches(patches, removePatches);
        const addPatches: ArtboardPatches = {
          do: {
            [grandpa.id]: [Ops.addChildren('-1', [newComp])],
          },
          undo: {
            [grandpa.id]: [Ops.removeChildren([newComp._id])],
          },
        };
        mergePatches(patches, addPatches);
        let newContainer;
        if (grandpa.type === CCompoundPath) {
          const newComps = depthClone(grandpa.components.map((comp) => comp.toJSON()));
          removeItemFromArray(
            newComps,
            newComps.find((item) => item._id === this.activeContainer.id),
          );
          newComps.push(newComp);
          const { newActiveGroup } = ((grandpa as unknown) as UICompoundPathComponent).refreshPatchesWithNewChildren(
            newComps,
            patches,
          );
          newContainer = newActiveGroup;
        }
        editeContainer = newContainer || grandpa;
      } else if (restComps.length === 1 && !newComp) {
        const restComp = restComps[0].toJSON();
        restComp.position = new UIComponent(restComp, this.activeContainer).getPositionWithoutParent();
        restComp.rotate = (restComp.rotate || 0) + this.activeContainer.rotate;
        restComp._id = getNewID();
        const removePatches = this.activeContainer.removeSelfPatches();
        mergePatches(patches, removePatches);
        const addPatches: ArtboardPatches = {
          do: {
            [grandpa.id]: [Ops.addChildren('-1', [restComp])],
          },
          undo: {
            [grandpa.id]: [Ops.removeChildren([restComp._id])],
          },
        };

        mergePatches(patches, addPatches);
        let newContainer;

        if (grandpa.type === CCompoundPath) {
          const newComps = depthClone(grandpa.components.map((comp) => comp.toJSON()));
          removeItemFromArray(
            newComps,
            newComps.find((item) => item._id === this.activeContainer.id),
          );
          newComps.push(restComp);
          const { newActiveGroup } = ((grandpa as unknown) as UICompoundPathComponent).refreshPatchesWithNewChildren(
            newComps,
            patches,
          );
          newContainer = newActiveGroup;
        }
        editeContainer = newContainer || grandpa;
      } else {
        const newComps = depthClone(this.activeContainer.components.map((comp) => comp.toJSON()));
        toCompoundComponents.forEach((comp) => {
          mergePatches(patches, comp.removeSelfPatches());
          removeItemFromArray(
            newComps,
            newComps.find((item) => item._id === comp.id),
          );
        });
        if (newComp) {
          const addPatches: ArtboardPatches = {
            do: {
              [this.activeContainer.id]: [Ops.addChildren('-1', [newComp])],
            },
            undo: {
              [this.activeContainer.id]: [Ops.removeChildren([newComp._id])],
            },
          };
          mergePatches(patches, addPatches);
        }
        newComp && newComps.push(newComp);
        const { newActiveGroup } = ((this
          .activeContainer as unknown) as UICompoundPathComponent).refreshPatchesWithNewChildren(newComps, patches);
        editeContainer = newActiveGroup || this.activeContainer;
      }
    } else if (this.activeContainer.type === CGroup) {
      // 合成组件为空 同组没有剩余组件
      const grandpa = this.activeContainer.parent!;
      if (restComps.length === 0 && !newComp) {
        if (grandpa.components.length > 2) {
          const removePatches = this.activeContainer.removeSelfPatches();
          editeContainer = grandpa;
          mergePatches(patches, removePatches);
        } else if (grandpa.components.length === 2) {
          if (grandpa.type === CGroup) {
            const removePatches = grandpa.removeSelfPatches();
            mergePatches(patches, removePatches);
            const newComps = depthClone(grandpa.components.map((comp) => comp.toJSON()));
            removeItemFromArray(
              newComps,
              newComps.find((item) => item._id === this.activeContainer.id),
            );
            newComps[0].position = new UIComponent(newComps[0], grandpa).getPositionWithoutParent();
            newComps[0].rotate = (newComps[0].rotate || 0) + grandpa.rotate;
            newComps[0]._id = getNewID();
            const addPatches: ArtboardPatches = {
              do: {
                [grandpa.parent!.id]: [Ops.addChildren('-1', newComps)],
              },
              undo: {
                [grandpa.parent!.id]: [Ops.removeChildren(newComps.map((item) => item._id))],
              },
            };
            mergePatches(patches, addPatches);
            editeContainer = grandpa.parent;
          }
        }
      } else if (restComps.length === 0 && newComp) {
        newComp.position = new UIComponent(newComp, this.activeContainer).getPositionWithoutParent();
        newComp.rotate = (newComp.rotate || 0) + this.activeContainer.rotate;
        const removePatches = this.activeContainer.removeSelfPatches();
        mergePatches(patches, removePatches);
        const addPatches: ArtboardPatches = {
          do: {
            [grandpa.id]: [Ops.addChildren('-1', [newComp])],
          },
          undo: {
            [grandpa.id]: [Ops.removeChildren([newComp._id])],
          },
        };
        mergePatches(patches, addPatches);
        editeContainer = grandpa;
      } else if (restComps.length === 1 && !newComp) {
        const restComp = restComps[0].toJSON();
        restComp.position = new UIComponent(restComp, this.activeContainer).getPositionWithoutParent();
        restComp.rotate = (restComp.rotate || 0) + this.activeContainer.rotate;
        restComp._id = getNewID();
        const removePatches = this.activeContainer.removeSelfPatches();
        mergePatches(patches, removePatches);
        const addPatches: ArtboardPatches = {
          do: {
            [grandpa.id]: [Ops.addChildren('-1', [restComp])],
          },
          undo: {
            [grandpa.id]: [Ops.removeChildren([restComp._id])],
          },
        };

        mergePatches(patches, addPatches);
        editeContainer = grandpa;
      } else {
        toCompoundComponents.forEach((comp) => {
          mergePatches(patches, comp.removeSelfPatches());
        });
        if (newComp) {
          const addPatches: ArtboardPatches = {
            do: {
              [this.activeContainer.id]: [Ops.addChildren('-1', [newComp])],
            },
            undo: {
              [this.activeContainer.id]: [Ops.removeChildren([newComp._id])],
            },
          };
          mergePatches(patches, addPatches);
        }
      }
    } else {
      toCompoundComponents.forEach((comp) => {
        const removePatches = comp.removeSelfPatches();
        mergePatches(patches, removePatches);
      });
      if (newComp) {
        const addPatches: ArtboardPatches = {
          do: {
            [this.activeContainer.id]: [Ops.addChildren('-1', [newComp])],
          },
          undo: {
            [this.activeContainer.id]: [Ops.removeChildren([newComp._id])],
          },
        };
        mergePatches(patches, addPatches);
      }
    }
    sortPatches(patches);
    this.update({
      [this.activeContainer.ownerArtboardID]: patches,
    });
    editeContainer && this.setActiveContainer(editeContainer);
    newComp ? this.selectByIDs([newComp._id], false) : this.selectByIDs([], false);
    return true;
  };

  /**
   * 解除复合路径
   * @memberof CoreGroup
   */
  dividePath = () => {
    if (this.selectedComponents.size === 0) {
      return false;
    }
    if (this.activeContainer.isSealed || !editorValidate.allowChildrenStructureChange(this.activeContainer)) {
      return false;
    }
    if (this.hasSelectLockedComps) {
      return false;
    }
    const paths = this.selectedComponentList.filter((comp) => comp.type === CCompoundPath);
    if (!paths.length) {
      return false;
    }

    const remainComponents = this.activeContainer.components;

    const noConnectLine: UIConnectorComponent[] = []; //删除孤立的线
    remainComponents
      .filter((comp) => comp.isConnector)
      .forEach((c) => {
        const comp = c as UIConnectorComponent;
        const startID = comp.getStartCompID();
        const endID = comp.getEndCompID();
        const startCompIsSelected = startID && paths.some((sc) => sc.id === startID);
        const endCompIsSelected = endID && paths.some((sc) => sc.id === endID);
        if (startCompIsSelected || endCompIsSelected) {
          noConnectLine.push(comp);
        }
      });

    const children: IComponentData[] = [];
    paths.forEach((path) => {
      const components = (((path as unknown) as UIContainerComponent).toJSON().components || []).map((comp) => {
        return depthClone(comp);
      });
      components.forEach((comp) => {
        comp.position = new UIComponent(comp, (path as unknown) as UIContainerComponent).getPositionWithoutParent();
        comp.rotate = (comp.rotate || 0) + path.rotate;
      });
      children.push(...components);
    });

    const revertAdd = convertRemovedComponentsToAddOps(this.activeContainer, paths);

    const revertNoConnectLine = convertRemovedComponentsToAddOps(this.activeContainer, noConnectLine);

    const patches: PagePatches = {
      [this.activeContainer.ownerArtboardID]: {
        do: {
          [this.activeContainer.id]: [
            Ops.removeChildren(paths.map((comp) => comp.id)),
            Ops.removeChildren(noConnectLine.map((comp) => comp.id)),
            Ops.addChildren(`${-1}`, children),
          ],
        },
        undo: {
          [this.activeContainer.id]: [
            ...revertAdd,
            ...revertNoConnectLine,
            Ops.removeChildren(children.map((comp) => comp._id)),
          ],
        },
      },
    };

    if (this.activeContainer.type === CCompoundPath) {
      const newComps = depthClone(
        remainComponents
          .filter((comp) => comp.type === CCompoundPath || comp.type === CPath)
          .map((item) => item.toJSON()),
      );
      paths.forEach((path) => {
        const deletePath = newComps.find((comp) => comp._id === path.id)!;
        removeItemFromArray(newComps, deletePath);
      });
      newComps.push(...children);
      ((this.activeContainer as unknown) as UICompoundPathComponent).refreshPatchesWithNewChildren(
        newComps,
        patches[this.activeContainer.ownerArtboardID],
      );
    }
    sortPatches(patches[this.activeContainer.ownerArtboardID]);
    this.update(patches);
    this.selectByIDs(
      children.map((comp) => comp._id),
      false,
    );

    return true;
  };

  /**
   * 刷新选中的组件
   * FIXME: 这个更多其实是一个临时办法，为了解决组件通过 undo/redo 删除后还被选中的问题,正确的做法是把受影响的组件自动选中/或取消选中
   */
  refreshSelectedComponents() {
    // TODO Symbol 的脱离在撤销、恢复过程中，会使对象不再是原来的对象，导致选择后的操作不是针对的同一个而产生问题，改为如下方式
    const compIDs = this.selectedComponentList.map((comp) => comp.id);
    this.selectedComponents.clear();
    compIDs.forEach((id) => {
      const comp = this.activeContainer.components.find((c) => c.id === id);
      comp && this.selectedComponents.add(comp);
    });
    // this.selectedComponents.forEach((comp) => {
    //   if (!this.activeContainer.components.includes(comp)) {
    //     this.selectedComponents.delete(comp);
    //   }
    // });
  }

  /**
   * 调整选中组件顺序
   * @param {ZOrderModel} model
   */
  changeZOrder(model: ZOrderModel) {
    const ids = [...this.selectedComponents].map((comp) => comp.id);
    const { patches, newContainer } = this.activeContainer.doChangeZOrder(ids, model);
    if (patches) {
      this.selectedComponentList.forEach((comp) => {
        comp.toJSON().v = comp.toJSON().v + Math.round(Math.random() * 100);
      });
      newContainer && this.setActiveContainer(newContainer);
      this.update(patches);
    }
  }

  /**
   * 把一个组件从一个层级移动到另一个层级
   * @param {number} oldIndex
   * @param {number} newIndex
   * @param {UIContainerComponent} group
   */
  moveChildOrder(oldIndex: number, newIndex: number, group: UIContainerComponent) {
    if (this.hasSelectLockedComps) {
      return;
    }
    const patches = group.moveChildOrder(oldIndex, newIndex);
    if (group instanceof UIContentPanelComponent || group instanceof UIContentPanelV2Component) {
      const p = group.moveValue(oldIndex, newIndex);
      if (p) {
        Object.keys(p.do).forEach((id) => {
          if (patches.do[id]) {
            patches.do[id].push(...p.do[id]);
            patches.undo[id].push(...p.undo[id]);
          } else {
            patches.do[id] = p.do[id];
            patches.undo[id] = p.undo[id];
          }
        });
      }
    }
    this.updateSingleArtboard(this.activeArtboard.artboardID, patches);
  }

  /**
   * 是否选中一个表格组件
   * @type {boolean}
   */
  public get isSelectTable(): boolean {
    return this.selectedComponents.size === 1 && Array.from(this.selectedComponents)[0].type === CTable;
  }

  public get selectedTable(): UITableComponent | undefined {
    return this.isSelectTable ? (this.selectedComponents.values().next().value as UITableComponent) : undefined;
  }

  protected resetInfoWhenCompIsLine(comp: UIComponent, compData: IComponentData) {
    if (comp.type !== CLine) {
      return;
    }

    const boxPointsInArtboard = comp.getBoxPointsInArtboard(true);
    const allResizePoints = getAllResizePoints(boxPointsInArtboard);
    const dragPoints = getDragPoints(allResizePoints, comp.selectFrameType);

    const { minX, minY } = getMinMaxXY(dragPoints);
    compData.position = tansPointInArtBoardToGroup([{ x: minX, y: minY }], comp.parent?.parent!)[0];
    const compViewBounds = getBoundsWithPoints(dragPoints);
    const { leftTop, leftBottom, rightBottom, rightTop } = getCornerCoordinate({
      width: compViewBounds.width,
      height: compViewBounds.height,
    });
    const value = compData.value as ILineValue;

    const { startPoint, endPoint } = value;
    const startXEqualZero = sameNumber(startPoint.x, 0);
    const startYEqualZero = sameNumber(startPoint.y, 0);
    const isLeftTopRightLine = startXEqualZero && startYEqualZero;
    const isVerticalLine = startPoint.x === endPoint.x;
    const parentPlusLineRotate =
      ((((comp.parent!.rotate || 0) + 360 * 10000) % 360) + getAngleOfLine(startPoint, endPoint)) % 360;
    //从左至右的水平直线
    if (isLeftTopRightLine && !isVerticalLine) {
      if (parentPlusLineRotate < 90 || parentPlusLineRotate === 360) {
        compData.value.startPoint = leftTop;
        compData.value.endPoint = rightBottom;
      } else if (parentPlusLineRotate >= 90 && parentPlusLineRotate < 180) {
        compData.value.startPoint = rightTop;
        compData.value.endPoint = leftBottom;
      } else if (parentPlusLineRotate >= 180 && parentPlusLineRotate < 270) {
        compData.value.startPoint = rightBottom;
        compData.value.endPoint = leftTop;
      } else if (parentPlusLineRotate >= 270 && parentPlusLineRotate < 360) {
        compData.value.startPoint = leftBottom;
        compData.value.endPoint = rightTop;
      }
    }
    //从右到左的水平直线
    else {
      if (parentPlusLineRotate < 90 || parentPlusLineRotate === 360) {
        compData.value.startPoint = rightBottom;
        compData.value.endPoint = leftTop;
      } else if (parentPlusLineRotate >= 90 && parentPlusLineRotate < 180) {
        compData.value.startPoint = leftBottom;
        compData.value.endPoint = rightTop;
      } else if (parentPlusLineRotate >= 180 && parentPlusLineRotate < 270) {
        compData.value.startPoint = leftTop;
        compData.value.endPoint = rightBottom;
      } else if (parentPlusLineRotate >= 270 && parentPlusLineRotate < 360) {
        compData.value.startPoint = rightTop;
        compData.value.endPoint = leftBottom;
      }
    }
    compData.rotate = 0;

    compData.size.width = compViewBounds.width;
    compData.size.height = compViewBounds.height;
  }
}
