import IStroke, { PresetDashModel, StrokePosition } from '@fbs/rp/models/properties/stroke';
import { CPath, CCompoundPath } from '@libs/constants';
import { SystemDarkColor } from './colors';

export const presetDashModelConfig: { id: PresetDashModel; text: string }[] = [
  { id: PresetDashModel.solid, text: '' },
  // { id: PresetDashModel.longDash, text: '20,10' },
  // { id: PresetDashModel.middleDash, text: '10,5' },
  // { id: PresetDashModel.shortDash, text: '4,2' },
  { id: PresetDashModel.shortDash, text: '3,2' },
  // { id: PresetDashModel.dashDot, text: '10,5,1,5' },
  // { id: PresetDashModel.dashDotDot, text: '10,5,1,5,1,5' },
  // { id: PresetDashModel.longDashDot, text: '20,5,5,5' },
  { id: PresetDashModel.dot, text: '1,1' },
];

export const defaultStrokePosition = (compType?: string) => {
  let position: StrokePosition = StrokePosition.inner;
  switch (compType) {
    case CPath:
    case CCompoundPath:
      position = StrokePosition.center;
      break;
  }
  return position;
};

// 不显示边框属性时，默认虚线边框，暂用于面板和内容面板
export const defaultDisabledStroke: IStroke = {
  thickness: 1,
  color: SystemDarkColor,
  dashModel: PresetDashModel.dashDot,
};
