class OfflineDemoManager {
  static get init() {
    return new OfflineDemoManager();
  }

  private _id?: string;
  private _reject?: (msg?: string) => void;
  private _resolve?: (url: string) => void;

  public addListener = (id: string, resolve: (url: string) => void, reject: (msg?: string) => void) => {
    this._id = id;
    this._reject = reject;
    this._resolve = resolve;
  };

  public reject = (id: string, msg?: string) => {
    if (id === this._id && this._reject) {
      this._reject(msg);
    }
  };

  public resolve = (id: string, url: string) => {
    if (id === this._id && this._resolve) {
      this._resolve(url);
    }
  };
}

export const offlineDemoManager = OfflineDemoManager.init;
