export enum ErrorCode {
  Fail = 1, //失败
  Warn = 2, //警告
  InTeamNotAppSetMember = 5,
  NotAppSetAndTeamMember = 6,
  SendMailLimit = 101, // 邮件发送已达上线
  UserDeleted = 201, // 用户已被删除
  LoginLimit = 202, // 多处登录
  UserLogout = 203, // 用户退出
  Unauthorized = 401, // 未登录
  PaymentRequired = 402, // 需要升级
  PaymentRenew = 403, // 需要续费
  NotFound = 404, // 未找到资源
  ContactUs = 405, // 联系我们
  PaymentNeedExpansion = 406, //需要扩容
  FreeTeamLimit = 407, //需要升级、转让、解散
  TeamAppLimit = 408, //项目超出限制
  TeamAppPageLimit = 409, //页面超出限制
  WhiteListLimit = 410, //白名单超出限制（私有华为）
  NotMember = 411, // 不是团队成员
  HasNoRight = 412, // 是团队成员，但是没有足够权限
  NotAppMember = 415, // 是团队不是项目成员
  HasNoRightInApp = 416, // 是项目成员 权限不够
  NeedJoinTeam = 417, // 需要加入团队
  PaymentRequiredNeedHelp = 419, // 需要升级查看帮助
  PDCreatedLimit = 420,
  PublishShare = 421,
  PDTeamExpired = 422,  // 华为白名单授权过期
  PDAppExpired = 423,
  LicenseError = 425,
  ShareIsNotOpen = 424, // 未开启允许成员分享
  DSNotMember = 426, // DS分享不是团队成员
  PDLicenseExpired = 431,  // 私有部署license授权过期
  PDLicenseNoRP = 432,  // 没有RP
  TaskInProgress = 501, // 任务进行中
  AppDestroy = 901, //项目已被删除
  TeamDestroy = 902, //团队已解散
  TaskDestroy = 903, //任务已被删除
  AppArchivedLimit = 904, //项目已经归档
  TXMeetingLoginOut = 460, // 腾讯会议授权过期
  TXMeetingExist = 461, // 该项目已存在腾讯会议
  Converting = 429, // 任务进行时
  ServerErro = 500, // 服务器错误
  DtAppInexistent = 1302, //dt项目不存在
  NotUserInfo = 1104, // dt 未登陆
}
