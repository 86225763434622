import * as React from 'react';
import classnames from 'classnames';

import { UITheme } from '../../../../models';
import { PaginationSizeOption } from '../../../../constants/pagination';

import Select from '../../../Select';

import './index.scss';

interface ISizeChangerProps {
  theme: UITheme;
  show: boolean;
  size: number;

  onChangeSize?(size: number): void;
}

const SizeChanger: React.FC<ISizeChangerProps> = (props: ISizeChangerProps) => {
  const { theme, show, size, onChangeSize } = props;

  if (!show) {
    return null;
  }

  const options = PaginationSizeOption.map((item) => item.text);
  const currentOption = PaginationSizeOption.find((item) => item.id === size) || PaginationSizeOption[0];

  return (
    <div className={classnames('dsm-c-pagination-size-changer', theme)} tabIndex={0}>
      <Select
        value={currentOption.text}
        fullBorder
        theme={theme}
        direction="up"
        options={options}
        onSelect={(item, index) =>
          item !== currentOption.text && onChangeSize && onChangeSize(PaginationSizeOption[index].id)
        }
      />
    </div>
  );
};

export default SizeChanger;
