import { IActionBase, IInteraction } from '@/fbs/rp/models/interactions';
import { getNewID } from '@/helpers/idHelper';
import CoreEditor from '@/editor/core';
import {
  HAS_COMPONENT_INTERACTION_DATA,
  copyComponentInteractionData,
  getCopiedCompInteractionData,
  IInteractionWithPrivateDeployment,
} from '@helpers/clipboardHelper';
import { storages } from '@utils/cacheUtils';

export interface IPasteActionBase extends IActionBase {
  isCreateByPaste?: boolean;
}
export default class InteractionClipboard {
  static instance: InteractionClipboard;
  private core: CoreEditor | undefined;
  // 避免私有部署与线上环境数据混淆
  public isPrivateDeployment: boolean = RP_CONFIGS.isHuaWei!;

  constructor(core: CoreEditor) {
    this.core = core;
  }

  static create(core?: CoreEditor) {
    if (core) {
      this.instance = new InteractionClipboard(core);
    }
    return this.instance;
  }
  public hasClipData = () => {
    return !!storages.temp.getItem(HAS_COMPONENT_INTERACTION_DATA);
  };

  public getClipData = async (needResetId: boolean = true): Promise<IInteractionWithPrivateDeployment> => {
    const interactionClipboardData = await getCopiedCompInteractionData();
    if (interactionClipboardData && needResetId) {
      const { interaction, targetId, fragmentId } = interactionClipboardData;
      // 防止重复粘贴时actionID相同
      for (const event in interaction) {
        interaction[event].actions.forEach((action) => {
          const pasteAction = action as IPasteActionBase;
          pasteAction._id = getNewID();
          // 对于多选粘贴时，先打一个标记，方便在后面做针对自身交互粘贴的判断
          if (this.core && this.core.selectedComponentList.length > 1) {
            pasteAction.isCreateByPaste = true;
          }
          if (this.core) {
            // 在提取时就修改action指向，避免将原有action误修改
            const { componentID, artboardID, advanceComp } = this.core.getCommonInteractionInfo();
            this.core.changeActionsTarget(
              pasteAction,
              {
                newTargetId: componentID || artboardID,
                originTargetId: targetId,
              },
              {
                newFragmentId: artboardID,
                originFragmentId: fragmentId,
              },
              advanceComp,
            );
          }
        });
      }
    }
    return (
      interactionClipboardData ?? {
        interaction: {},
        isPrivateDeployment: this.isPrivateDeployment,
        targetId: '',
        fragmentId: undefined,
      }
    );
  };

  public setClipData = async (interaction: IInteraction, targetId: string, fragmentId?: string) => {
    //TODO： 过滤掉空命令的事件
    const interactionClipboardData = {
      interaction,
      isPrivateDeployment: this.isPrivateDeployment,
      targetId,
      fragmentId,
    };
    await copyComponentInteractionData(interactionClipboardData);
  };

  public clearClipboard = async () => {
    const interactionClipboardData = {
      interaction: {},
      isPrivateDeployment: this.isPrivateDeployment,
      targetId: '',
      fragmentId: undefined,
    };
    await copyComponentInteractionData(interactionClipboardData);
  };
}
