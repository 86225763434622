import * as React from 'react';
import { Dialog, Button } from '@dsm';
import i18n from '@i18n';

import './index.scss';

export interface IOperationProps {
  title: string;
  confirmBtnText?: string;
  width?: number;
  alertContent?: string;
  onClose: () => void;
}

class TargetAlert extends React.Component<IOperationProps> {
  static defaultProps: Partial<IOperationProps> = {
    width: 500,
  };

  constructor(props: IOperationProps) {
    super(props);
  }

  handleConfirm = () => {
    const { onClose } = this.props;
    onClose();
    return true;
  };

  handleCancel = () => {
    const { onClose } = this.props;
    onClose();
    return true;
  };

  render() {
    const { title, width, onClose, alertContent, confirmBtnText } = this.props;
    return (
      <Dialog onSubmit={onClose} title={title} width={width} onClose={onClose} backFade>
        <div className="alert-content">
          <div className="content-text" dangerouslySetInnerHTML={{ __html: alertContent || '' }} />
          <div className="button-bar footer-without-separator">
            <Button theme="dialog" onClick={onClose} activated>
              {confirmBtnText ? confirmBtnText : i18n('general.ok')}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default TargetAlert;
