import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

/**
 * 用户头像数据对象
 * @author Matt
 * img: string 图片路径
 * size: number 显示尺寸
 * className: string 应用样式表
 * onClick: React.MouseEvent<HTMLElement> 点击事件
 */
interface IAvatarProp {
  img: string;
  size?: number;
  className?: string;
  onClick?: React.MouseEventHandler;
}

const Avatar: React.FC<IAvatarProp> = (avatar: IAvatarProp) => {
  const { img, size, className, onClick } = avatar;
  return (
    <div
      className={classnames('dsm-c-avatar', className)}
      onClick={onClick}
      style={{
        width: size,
        height: size,
        backgroundImage: `url(${img})`,
      }}
    />
  );
};

Avatar.defaultProps = {
  size: 30,
};

export default Avatar;
