import * as React from 'react';
import { UIComponent } from '@/editor/comps';
import { Icon } from '@/dsm';
import i18n from '@/i18n';
import snapshotManager from '@/managers/snapshotManager';
import { ISize } from '@/utils/boundsUtils';
import { ISnapshotValue, IBounds } from '@/fbs/rp/models/value';
import { renderComponentWrapper } from './renderComponentWrapper';
import { uid } from '../util';

const makeid = uid();

const renderEmptyImage = () => {
  return (
    <div className="snapshot-placeholder">
      <p>{i18n('resource.componentsText.snapshotText')}</p>
      <Icon cls="icon_snapshot" theme="tag" />
    </div>
  );
};
const renderPageInvalid = () => {
  return (
    <div className="snapshot-placeholder">
      <div>
        <p className="page-invalid">{i18n('resource.componentsText.snapshotInvalid')}</p>
        <p>{i18n('resource.componentsText.snapshotCanNotFound')}</p>
      </div>
      <Icon cls="icon_snapshot" theme="tag" />
    </div>
  );
};

const renderNotEmpty = (url: string, imageState: ImageStateEnum, compSize: ISize, cropAreaInfo?: IBounds) => {
  const pageInvalid = imageState === ImageStateEnum.Invalid;

  return pageInvalid ? renderPageInvalid() : renderCanvasImg(url, compSize, cropAreaInfo);
};

const renderCanvasImg = (url: string, compSize: ISize, cropAreaInfo?: IBounds) => {
  if (!cropAreaInfo || !(cropAreaInfo && cropAreaInfo.width)) {
    // 无截图信息
    const style = {
      backgroundImage: `url(${url})`,
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      width: compSize.width,
      height: compSize.height,
    };
    return <div style={style}></div>;
  }

  // 从原图中截取
  // 图片原图大小，在后续图片加载后获取大小，再替换值
  const imageSize = {
    width: '${width}', // cropAreaInfo.width + cropAreaInfo.left,
    height: '${height}', //cropAreaInfo.height + cropAreaInfo.top,
  };
  const { width, height } = imageSize;
  const x = -(cropAreaInfo?.left || 0);
  const y = -(cropAreaInfo?.top || 0);

  const transform = `scale(${compSize.width / cropAreaInfo.width},${compSize.height / cropAreaInfo.height})`;

  const patternId = `snapshot_img_${makeid()}`;
  const fill = `url(#${patternId})`;
  return (
    <svg width="100%" height="100%">
      <defs>
        <pattern id={patternId} x="0" y="0" width="1" height="1" patternUnits="objectBoundingBox">
          <image
            xlinkHref={`${url}`}
            x={x}
            y={y}
            width={width}
            height={height}
            preserveAspectRatio="none"
            vectorEffect="non-scaling-stroke"
          />
        </pattern>
      </defs>
      <rect width={cropAreaInfo.width} height={cropAreaInfo.height} fill={fill} transform={transform} />
    </svg>
  );
};

enum ImageStateEnum {
  None,
  Invalid,
  Normal,
}

export const renderSnapshot = (comp: UIComponent) => {
  let { cropAreaInfo, url, source } = comp.value as ISnapshotValue;
  let imgState = ImageStateEnum.Normal;

  const urlInPage = snapshotManager.getURLByPageID(source?.pageID);
  const realUrl = urlInPage ?? url;
  // 离线演示状态
  const isOfflineDemo = RP_CONFIGS.isOfflineDemo && comp.isPreview;

  if (!source?.pageID && !realUrl) {
    imgState = ImageStateEnum.None;
  }

  if (!isOfflineDemo) {
    if (source?.pageID && !urlInPage) {
      imgState = ImageStateEnum.Invalid;
    }
  } else {
    if (source?.pageID && !realUrl) {
      imgState = ImageStateEnum.Invalid;
    }
  }

  const isEmptyImage = imgState === ImageStateEnum.None;
  const compSize = comp.size;
  const content = (
    <div className="lib-comp-snapshot">
      {isEmptyImage ? renderEmptyImage() : renderNotEmpty(realUrl, imgState, compSize, cropAreaInfo)}
    </div>
  );

  return renderComponentWrapper({ comp, scale: 1 }, content);
};
