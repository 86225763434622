// 输入框

// 整理节点
// 在过滤时候已经做了hasInputValue(node)判断，这里不用重复
/**
 * replacer作用在 克隆节点上，可以做修改。
 *
 * @param node
 * @returns
 */
export const replacer = (clone: HTMLElement): Node => {
  const tagName = clone.tagName;
  if (/input/i.test(tagName)) {
    return replaceInput(clone as HTMLInputElement);
  }
  if (/textarea/i.test(tagName)) {
    return replaceTextarea();
  }
  // if (/lib-comp-list-item/.test(clone.className)) {
  //   clone.style.setProperty('font-size', '14px');
  // }
  // if (/label/i.test(tagName) && 0) {
  //   return replaceLabel(clone as HTMLLabelElement, original);
  // }
  return clone;
};

export const hasInputValue = (node: Element): boolean => {
  if (node instanceof HTMLInputElement) {
    return Boolean((node as HTMLInputElement).value);
  }

  if (node instanceof HTMLTextAreaElement) {
    return Boolean((node as HTMLTextAreaElement).value);
  }

  return false;
};

const replaceInput = (clone: HTMLInputElement): Node => {
  // const textNode = document.createTextNode(node.value);
  // return textNode;
  const label = document.createElement('label');
  label.innerText = clone.value;
  // label.setAttribute('style', node.style.cssText)
  // label.appendChild(textNode);
  // div.setAttribute("style", "padding:0px 6px 0px 8px")
  return label;
};

// 不需要设置innerText
// 因为texteare有子节点。 input只有value
const replaceTextarea = (): Node => {
  // const textNode = document.createTextNode(node.value);
  // return textNode;
  // console.log(textNode)
  const label = document.createElement('label');
  // label.innerText = node.value
  // label.setAttribute('style', node.style.cssText)

  return label;
};

// const replaceLabel = (clone: HTMLLabelElement, original: Element): Node => {
//   // console.log(clone);
//   const textNode = document.createTextNode(original.textContent ?? '');
//   return textNode;
// };
