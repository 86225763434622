import i18n from '@i18n';
import { SpriteThumb } from '@consts/spriteIcons';
import { flowBase, getShapeData, getShapePropertiesData } from './shapeDataFragment';

// 右拐角
export default {
  ...flowBase,
  name: i18n('resource.flow.rightTurn'),
  type: 'rightTurn',
  thumb: {
    spriteIconClass: SpriteThumb.RightTurn.className,
    dragPosition: SpriteThumb.RightTurn.position,
  },
  template: getShapeData(
    i18n('resource.flow.rightTurn'),
    {
      width: 60,
      lockedRatio: false,
      height: 60,
    },
    {
      data: [
        { point: { x: 15, y: 60 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 0, y: 60 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 0, y: 20 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 25, y: 0 }, handleIn: { x: -26, y: 0 }, handleOut: { x: 26, y: 0 } },
        { point: { x: 50, y: 20 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 50, y: 45 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 60, y: 45 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 42.5, y: 60 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 25, y: 45 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 35, y: 45 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 35, y: 20 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
        { point: { x: 25, y: 13 }, handleIn: { x: 10, y: 0 }, handleOut: { x: -10, y: 0 } },
        { point: { x: 15, y: 20 }, handleIn: { x: 0, y: 0 }, handleOut: { x: 0, y: 0 } },
      ],
      closed: true,
    },
  ),
  getDefaultData() {
    return {
      properties: getShapePropertiesData({ needFill: true, closed: true }),
    };
  },
};
