import { Dispatch } from 'redux';
import apis from '@apis';

import { ActionsUnion, ActionType, createAction } from '../types';
import { ThunkActions as GlobalActions } from '../global/actions';

import { IUserPreference } from '@fbs/rp/models/grid';

export const Actions = {
  loadUserPreference: (userPreference: IUserPreference) =>
    createAction(ActionType.User_Preference_Load, userPreference),
  patchUserPreference: (userPreference: Partial<IUserPreference>) =>
    createAction(ActionType.User_Preference_Patch, userPreference),
};

export const ThunkActions = {
  thunkLoadUserPreference: (appID: string) => async (dispatch: Dispatch) => {
    apis.userPreference
      .loadUserPreference(appID)
      .then((data) => {
        dispatch(Actions.loadUserPreference(data));
      })
      .catch((e) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
      });
  },
  thunkPatchUserPreference: (settingID: string, preference: Partial<IUserPreference>) => async (dispatch: Dispatch) => {
    return apis.userPreference
      .patchUserPreference(settingID, preference)
      .then(() => {
        // 这里不能用后端返回的数据 #97281 数据版本可能有问题
        dispatch(Actions.patchUserPreference(preference));
        return preference as IUserPreference;
      })
      .catch((e) => {
        GlobalActions.thunkErrorMessage(e, true)(dispatch);
      });
  },
  changeStoreBackgroundColor: (data: IUserPreference) => async (dispatch: Dispatch) => {
    dispatch(Actions.patchUserPreference(data));
  },
  // 非私有部署版个人空间邀请链接进入RP设置个人偏好
  thunkCheckPersonalSpaceUserPreference: () => async (dispatch: Dispatch) => {
    !RP_CONFIGS.isPrivateDeployment &&
      apis.userPreference
        .getUserPreference()
        .then((res) => {
          !res &&
            apis.userPreference.setUserDefaultTeamType('normal').catch((e) => {
              GlobalActions.thunkErrorMessage(e, true)(dispatch);
            });
        })
        .catch((e) => {
          GlobalActions.thunkErrorMessage(e, true)(dispatch);
        });
  },
};

export type UserPreferenceAction = ActionsUnion<typeof Actions>;
