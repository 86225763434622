import * as React from 'react';
import classnames from 'classnames';

import Tooltip from '../Tooltip';

import './font_864087_apmqh0tjkv9/iconfont.css';

import './index.scss';

export interface IconProps {
  cls?: string;
  color?: string;
  tooltip?: string;
  toolTipDirection?: 'up' | 'down';
  size?: number | string;
  solid?: boolean;
  disabled?: boolean;
  tooltipAlign?: 'left' | 'center' | 'right';
  disableHover?: boolean; // hover的时候图标颜色不变,鼠标形状变为默认的
  disableHoverColor?: boolean; // hover的时候图标颜色不变，鼠标形状还是手型
  clickArea?: { width: number; height: number };
  tooltipStyle?: React.CSSProperties;
  allowDisabledClick?: boolean; //允许禁用时触发自定义点击事件
  theme?: 'blue';
  hidden?: boolean;
  iconRef?: React.RefObject<HTMLElement>;

  onClick?: React.MouseEventHandler;
  onContextMenu?: React.MouseEventHandler;
}

const Icon: React.FC<IconProps> = (props: IconProps) => {
  const {
    cls,
    color,
    tooltip,
    toolTipDirection,
    size,
    solid,
    disabled,
    tooltipAlign,
    disableHover,
    disableHoverColor,
    clickArea,
    tooltipStyle,
    allowDisabledClick,
    theme,
    hidden,
    iconRef,
    onClick,
    onContextMenu,
  } = props;
  const iconComp: any = (
    <i
      ref={iconRef}
      className={classnames('dsm-c-icon', 'idoc_iconfont', `icon-_${cls}`, {
        'dsm-c-icon-solid': solid,
        'dsm-c-icon-disabled': disabled,
        'dsm-c-icon-solid-disabled': disabled && solid,
        'dsm-c-icon-solid-disableHover': disableHover,
        'dsm-c-icon-solid-disableHoverColor': disableHoverColor,
        'dsm-c-icon-orea': !!clickArea,
        'dsm-c-icon-blue': theme === 'blue',
        'dsm-c-icon-hidden': hidden,
      })}
      style={{
        fontSize: size,
        width: clickArea ? clickArea.width : size,
        height: clickArea ? clickArea.height : size,
        color,
      }}
      onClick={!disabled || allowDisabledClick ? onClick : undefined}
      onContextMenu={!disabled ? onContextMenu : undefined}
    />
  );
  if (tooltip) {
    return (
      <Tooltip text={tooltip} align={tooltipAlign} theme="small" tipStyle={tooltipStyle} direction={toolTipDirection}>
        {iconComp}
      </Tooltip>
    );
  }
  return iconComp;
};

Icon.defaultProps = {
  solid: false,
  cls: 'tag_peace',
  size: 16,
  tooltip: '',
  color: '',
  toolTipDirection: 'down',
  tooltipAlign: 'center',
  disabled: false,
  disableHover: false,
  disableHoverColor: false,
};

export default Icon;
