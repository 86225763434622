import * as React from 'react';

import { ITreeData, ITreeDataItem } from '@fbs/rp/models/value';
import {
  ITreeItem,
  mapRelationWithComp,
  createAvlTreeWithTreeComp,
  getParentIDsByCompTree,
} from '@helpers/treeCompHelper';
import { UINavigationMenuComponent, UITreeItemComponent } from '@editor/comps';

import { IComponentProps } from '../../types';
import CanvasPanel from '../../containers/Canvas';
import NavigationMenuItem from './NavigationMenuItem';

import './index.scss';

export default class NavigationMenu extends React.Component<IComponentProps> {
  private selectedIDs: string[] = [];
  private getCompFormNode = (node: ITreeDataItem) => {
    const { comp } = this.props;
    return (comp as UINavigationMenuComponent).getItemCompById(node.id);
  };

  handleItemClick = (node: ITreeDataItem, e: React.MouseEvent) => {
    const { comp, isPreview } = this.props;
    if (isPreview && !comp.disabled) {
      const itemComp = this.getCompFormNode(node);
      if (itemComp) {
        const clickEvent = this.props.event?.onClick;
        clickEvent && clickEvent(e, itemComp);
      }
    }
  };

  handleItemMouseDown = (node: ITreeDataItem, e: React.MouseEvent) => {
    const { comp, isPreview } = this.props;
    if (isPreview && !comp.disabled) {
      const itemComp = this.getCompFormNode(node);
      if (itemComp) {
        const onMouseDown = this.props.event?.onMouseDown;
        onMouseDown && onMouseDown(e, itemComp);
      }
    }
  };

  handleItemExpandClick = (node: ITreeDataItem, e: React.MouseEvent) => {
    const { comp } = this.props;
    if (comp.disabled) {
      return;
    }
    const itemComp = this.getCompFormNode(node);
    const checkboxComp = itemComp.expandComp;
    if (checkboxComp) {
      const clickEvent = this.props.event?.onClick;
      clickEvent && clickEvent(e, checkboxComp);
    }
  };

  handleItemDoubleClick = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      const comp = this.getCompFormNode(node);
      const onDoubleClick = this.props.event?.onDoubleClick;
      comp && onDoubleClick && onDoubleClick(e, comp);
    }
  };

  handleItemMouseUp = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      const comp = this.getCompFormNode(node);
      const onMouseUp = this.props.event?.onMouseUp;
      comp && onMouseUp && onMouseUp(e, comp);
    }
  };

  handleItemMouseEnter = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      const comp = this.getCompFormNode(node);
      const onMouseEnter = this.props.event?.onMouseEnter;
      comp && onMouseEnter && onMouseEnter(e, comp);
    }
  };

  handleItemMouseLeave = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      const comp = this.getCompFormNode(node);
      const onMouseLeave = this.props.event?.onMouseLeave;
      comp && onMouseLeave && onMouseLeave(e, comp);
    }
  };

  handleItemContext = (node: ITreeDataItem, e: React.MouseEvent) => {
    if (this.props.isPreview) {
      const comp = this.getCompFormNode(node);
      const onContextMenu = this.props.event?.onContextMenu;
      comp && onContextMenu && onContextMenu(e, comp);
    }
  };

  renderItem = (item: ITreeItem, level: number) => {
    const { isPreview, comp, hotAreaVisibleModel } = this.props;
    const {
      lineHeight,
      intention,
      checkedFill,
      checkedMarkerStrip,
      checkedMarkerStripWidth,
      padding,
      showExpandIcon,
      showNodeIcon,
      checkedIconColor,
      size,
    } = (comp as UINavigationMenuComponent).parseProperties();
    const itemComp = (comp as UINavigationMenuComponent).components.find((comp) => comp.id === item.data.id);
    if (!itemComp) return;
    return (
      <NavigationMenuItem
        key={item.data.id}
        item={item}
        comp={itemComp as UITreeItemComponent}
        paddingLeft={padding.left}
        paddingRight={padding.right}
        lineHeight={lineHeight}
        level={level}
        intention={intention}
        checkedFill={checkedFill}
        checkedMarkerStrip={checkedMarkerStrip}
        checkedMarkerStripWidth={checkedMarkerStripWidth}
        showExpandIcon={showExpandIcon}
        showNodeIcon={showNodeIcon}
        size={size}
        selectedIDs={this.selectedIDs}
        checkedIconColor={checkedIconColor}
        isPreview={!!isPreview}
        hotAreaVisibleModel={hotAreaVisibleModel}
        onExpandClick={this.handleItemExpandClick}
        onItemClick={this.handleItemClick}
        onItemMouseDown={this.handleItemMouseDown}
        onItemMouseUp={this.handleItemMouseUp}
        onItemDoubleClick={this.handleItemDoubleClick}
        onItemMouseEnter={this.handleItemMouseEnter}
        onItemMouseLeave={this.handleItemMouseLeave}
        onItemContext={this.handleItemContext}
      >
        {item.data.expand && item.children?.length && this.renderItems(item.children, level + 1)}
      </NavigationMenuItem>
    );
  };

  renderItems = (items: ITreeItem[], level: number) => {
    return items.map((item) => {
      return this.renderItem(item, level);
    });
  };

  renderNavigationMenu = () => {
    const { comp } = this.props;
    const childComps = (comp as UINavigationMenuComponent).components.map((comp) => comp.$data);
    const { relation } = comp.value as ITreeData;
    const initTree = mapRelationWithComp(relation, childComps);
    const { tree } = createAvlTreeWithTreeComp(relation, childComps);
    const selectComp = childComps.filter((item) => {
      return item.selected === true;
    });
    const selectCompTree = selectComp.length && tree.get(selectComp[0]._id);
    if (selectCompTree) {
      this.selectedIDs = getParentIDsByCompTree(selectCompTree);
    } else {
      this.selectedIDs = [];
    }
    return initTree.map((item) => this.renderItem(item, 0));
  };

  private _paddingStyle: { paddingLeft: number; paddingRight: number; paddingTop: number; paddingBottom: number } = {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  };

  private getPaddingStyle = (): React.CSSProperties => {
    const { comp } = this.props;
    const {
      padding: { left, top, right, bottom },
    } = (comp as UINavigationMenuComponent).parseProperties();
    const isChanged =
      left !== this._paddingStyle.paddingLeft ||
      right !== this._paddingStyle.paddingRight ||
      top !== this._paddingStyle.paddingTop ||
      bottom !== this._paddingStyle.paddingBottom;
    isChanged &&
      (this._paddingStyle = {
        paddingLeft: left,
        paddingBottom: bottom,
        paddingRight: right,
        paddingTop: top,
      });

    return this._paddingStyle;
  };

  render() {
    const { isPreview, comp } = this.props;
    const style = this.getPaddingStyle();
    return (
      <div className="lib-comp-navigation-menu">
        <CanvasPanel comp={comp} isPreview={isPreview}>
          <div className="lib-comp-navigation-menu-content" style={style}>
            {this.renderNavigationMenu()}
          </div>
        </CanvasPanel>
      </div>
    );
  }
}
