import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

export interface IBoxProp {
  selected: boolean;
  onClick?: React.MouseEventHandler;
  children?: React.ReactChildren[] | React.ReactChildren | string;
}

const Box: React.FC<IBoxProp> = (props: IBoxProp) => {
  const { selected, onClick, children } = props;
  return (
    <div
      onClick={onClick}
      className={classnames('dsm-c-area-select', {
        selected,
      })}
    >
      {children}
    </div>
  );
};

export default Box;
