import ILanguage from './type';

const isHuawei = RP_CONFIGS.isHuaWei;
const isHwDc = RP_CONFIGS.isHuaWeiDataCenter;
const isPrivateDeployment = RP_CONFIGS.isPrivateDeployment;
const isDisplayMock = RP_CONFIGS.isDisplayMock ?? true;

const en_us: ILanguage = {
  common: {
    title: isHuawei
      ? isHwDc
        ? '{0} - Core Design'
        : '{0} - Tiny Design'
      : isDisplayMock
      ? '{0} - Mockplus'
      : '{0} - Prototyping',
    mock: '#摹客',
  },
  email: '', //------------------------
  enterpriseCustomerService: 'Enterprise Service',
  enterpriseCustomerEmail: 'support-e@jongde.com',
  // 主界面
  application: {
    flow: 'Connect mode',
    pen: 'Pen',
    pencil: 'Pencil',
    group: 'Group',
    ungroup: 'Ungroup',
    undo: 'Undo',
    redo: 'Redo',
    showStatePanel: 'State panel', // 详见 https://redmine.jongde.com/issues/40606
    hiddenStatePanel: 'State panel',
    showArtboardPanel: 'Artboard list',
    hiddenArtboardPanel: 'Artboard list',
    startTxMeeting: 'startMeeting',
    waitTxMeeting: '#腾讯会议待加入',
    isInTxMeeting: '#腾讯会议中...',
    artboardPanel: 'Artboards',
    play: 'Preview',
    playFromFirst: 'Start from homepage',
    playFromCurrent: 'Start from current page',
    switchRightPanelVisible: 'Show/Hide right panel',
    newArtboard: 'Add artboard',
    // newSubArtboard: 'Add sub-artboard',
    newSubArtboard: 'Sub-artboard',
    addNewSubArtboard: '#添加内容',
    addNewSubArtboardLine: '#列数',
    goBack: '#返回上一层',
    upTopMove: '#置于首位',
    upMove: '#前移一位',
    downMove: '#后移一位',
    help: 'Help',
    navigation: 'Navigation',
    fullScreen: 'Enter full screen',
    showLeftPanel: 'Show left panel',
    showRightPanel: 'Show right panel',
    hiddenLeftPanel: 'Hide left panel',
    hiddenRightPanel: 'Hide right panel',
    title: isHuawei
      ? isHwDc
        ? '{0} - Core Design'
        : '{0} - Tiny Design'
      : isDisplayMock
      ? '{0} - Mockplus'
      : '{0} - Prototyping',
    shortCut: 'Shortcuts',
    tutorial: 'Tutorials',
    feedback: 'Feedback',
    community: '讨论区', // 没有英文版
    home: 'Our website',
    showGrid: 'Show grid',
    showLayout: 'Show layout',
    showRuler: 'Show rulers',
    showGuideline: 'Show guides',
    removeAllVerticalGuides: 'Remove all vertical guides',
    removeAllHorizontalGuides: 'Remove all horizontal guides',
    removeGuides: 'Remove guide',
    gridSettings: 'Grid settings',
    layoutSettings: 'Layout settings',
    alginConfig: 'Advanced measurements',
    tutorialURL: isPrivateDeployment
      ? `${window.location.origin}${window.apis.helpDomain}/p/401`
      : 'https://help.mockplus.com/p/401',
    dtPosterURL: '', //----------------------
    homeURL: 'https://www.mockplus.com/mockplus-rp',
    feedbackURL: 'https://www.mockplus.com/support',
    buyURL: 'https://www.mockplus.com/pricing/mockplus-rp',
    upgradePersonalSpaceURL: 'https://www.mockplus.com/pricing/mockplus-rp?license=prorp&payment=annual',
    upgradeTeamURL: 'https://www.mockplus.com/pricing/mockplus-rp?license=ultimate&payment=annual',
    forgotPassword: 'https://user.mockplus.com/forgotpassword',
    exampleListURL: '', // 英文版不支持模板例子
    signInUrl: 'https://user.mockplus.com/signin?next={0}',
    coopers: '{0} +{1} more',
    zoom: 'Scale',
    pageCount: '{0} page (s) in total',
    pageCount2: '{0} page(s) in total',
    zoom4canvas: 'Fit to canvas',
    zoom4selection: 'Fit to selection',
    zoom4artboard: 'Fit to artboard',
    option: 'Preferences',
    scaleBorderAndRadius: '', // DT相关功能
    scaleShadow: '', // DT相关功能
    settings: 'Settings',
    generalSettings: 'General options',
    offlineDemo: 'Download offline prototype',
    backToHomePage: 'Back to home',
    exitEdit: 'Exit edit mode',
    //返回按钮下拉菜单列表
    goHomePage: 'Homepage',
    mainMenu: 'Menu',
    recentlyEdited: 'Recently edited',
    noRecentlyEdited: 'No such project found',
    backGroupProject: 'Back to Cloud',
    moreText: 'More',
    newWindowOpen: 'Open in new window',
    iDocDomain: 'https://app.mockplus.cn',
    showViewport: 'Views',
    showActiveFlag: 'Show interaction markers',
    showRemarkNum: 'Show note markers',

    techSupport: 'Tech support',
    discord: 'Discord',
    slack: 'Slack',
    discordURL: 'https://discord.gg/twd4VCsN6S',
    slackURL: 'https://join.slack.com/t/mockplus-community/shared_invite/zt-o3lv7zj7-OrBeDaIBDqx0sITcgpDU4A',

    //========================
    publishButton: 'Publish',
    publish: 'Publish to Mockplus Cloud',
    publishInstruction:
      'Sync your project to Mockplus Cloud for instant online collaboration, handoff and layer data check.',
    team: 'Target team',
    apps: 'Target Cloud project',
    prototypes: 'Target Prototype',
    recentApps: 'Recently published',
    noTeams: 'No team available yet.',
    selectedTeam: 'Select a target team',
    selectedApp: 'Select a target Cloud project',
    selectedProject: 'Please select pages',
    selectedPrototype: 'Select a prototype',
    noPrototype: '#暂无原型稿，请先进行创建',
    selectedAppSet: 'Select a target project folder',
    noApps: 'No project available yet. Please create one first.',
    noAppSets: 'No project folder available yet. Please create one first.',
    appName: 'Name',
    appSet: 'Project folder',
    search: 'Search',
    appMembers: 'Members',
    location: 'Location',
    publishProgress: 'Publishing, please wait...',
    publishFail: 'Sorry, publish failed. Please try again later.',
    publishSuccess: 'Project published successfully.',
    checkViewApp: 'View project',
    rootAppSets: 'All',
    recentPublish: 'Recently published',
    publishRecord: 'Recently published',
    publishRange: '#发布范围',
    createNewProto: '#新建原型稿',
    createNewProtoPlaceholder: '#请输入原型稿名称',
    //========================
    projectPage: 'Page range',
    savePlaceholder: 'Version name',
    exportRpFileHelp:
      isPrivateDeployment && !isHuawei
        ? `${window.location.origin}${window.apis.helpDomain}/p/532`
        : 'https://help.mockplus.com/p/524',
  },
  findReplaceTool: {
    findReplaceText: 'Find and replace text',
    findInput: 'Find',
    replaceInput: 'Replace',
    inCurrentPage: 'Current page',
    inAllPage: 'All pages',
    replace: 'Replace',
    replaceAll: 'Replace all',
    find: 'Find',
    noResult: 'No results',
    replaceAllConfirmTips: 'Are you sure you want to replace {1} result(s) "{2}" in {0} with "{3}"?',
    resourceExpired:
      'The source of the found asset instances was deleted or converted to another type. Do you want to detach them from the asset source to replace the text?',
    replaceAllResourceExpired:
      'The source of some asset instances found has been deleted or converted to another type. Do you want to detach them from the asset source to replace the text?',
  },
  // 工作区
  workspace: {
    artboard: {
      downloadArtboard: 'Export artboard',
      downloadArtboardSvg: 'Export artboard as svg',
      downloadArtboardAsPdf: '导出画板为PDF',
      downloadSetting: 'Export artboard as image',
      downloadRatioSelect: 'Size',
      downloadingMessage: 'Exporting. Please wait...',
      downloadedMessage: 'Image(s) exported. Please click to download.',
      downloadErrorMessage: 'Sorry, export failed. ',
      downloadTimeoutErrorMessage:
        'Sorry, the export process has timed out. Please try exporting in smaller batches. If you need any help, please {0}.',
      copy: 'copy',
      exportingMessage: 'Exporting the image, please wait ...',
      exportOversize: 'The artboard is a bit large. Please choose a small size to export it faster.',
      consoleErrorMessage: 'Failed to export image:{0}',
      tooManyCheckedPages: 'For faster exporting, try selecting less pages.',
      exportMainArtboardsOnly: '#仅导出主画板',
      exportScale: '#导出倍率',
      applyToCover: 'Set as cover',
      applyCoverSuccess: '#项目封面设置成功',
    },
    grid: {
      blockSize: 'Grid block size',
      thickLinesEvery: 'Thick lines every',
      colors: 'Colors',
      makeDefault: 'Make default settings',
      dark: 'Dark',
      light: 'Light',
      blocks: 'blocks',
    },
    layout: {
      columns: 'Columns',
      totalWidth: 'Total width',
      offset: 'Offset',
      center: 'Center',
      columnCount: 'Columns',
      gutterOnOutside: 'Gutter on outside',
      gutterWidth: 'Gutter width',
      columnWidth: 'Column width',
      rows: 'Rows',
      gutterHeight: 'Gutter height',
      rowHeight: 'Row height',
      visuals: 'Visuals',
      fillGrid: 'Fill grid',
      strokeOutline: 'Stroke outline',
      drawAllGridLines: 'Draw all horizontal lines',
    },
    alignType: {
      normalAlign: 'Basic alignment',
      equidistantAlign: 'Even distribution',
      distanceAlign: 'Spacing specs',
    },
    backToEditor: 'Return',
    statusBar: {
      projectType: 'Project type',
      phone: 'Mobile',
      pad: 'Tablet',
      web: 'Web',
      watch: 'Watch',
      vehicle: 'Vehicle',
      custom: 'Custom',
      huawei: 'Huawei',
    },
    generalSettings: {
      pasteOffset: {
        label: 'Offset pasted object',
        x: 'X',
        y: 'Y',
      },
      canvasColor: 'Canvas color',
      resetColor: 'Reset',
      pasteAtTop: 'Always bring pasted object to front',
      showHiddenArea: 'Show hidden area',
      showLayerNameInPropertiesPanel: 'Show layer names on the Property panel',
    },
  },
  // 资源库
  resource: {
    project: 'Pages',
    artboard: 'Artboard',
    component: 'Compos',
    icon: 'Icons',
    resource: 'Assets',
    outline: 'Layers',
    searchPage: 'Search',
    searchComponent: 'Search',
    searchComponentlib: 'Search all libraries',
    searchCurrentComponentlib: 'Search current library',
    searchIcon: 'Search',
    searchLib: 'Search',
    searchLayer: 'Search',
    recycleBin: 'Recycle Bin',
    // 组件库
    components: {
      customCompGuidance: 'Click this to access more libraries.',
      newContentPanelGuidanceTitle: '#推荐使用全新内容面板！',
      newContentPanelGuidanceContent: '#为帮助大家过渡使用习惯，老版内容面板目前仍可使用，后续将择期下线。',
      groupName: 'Default',
      basic: 'Basic',
      container: 'Containers',
      common: 'Common',
      flow: 'Shapes',
      antDesign: 'Ant Design',
      comment: 'Markup',
      chart: 'Charts',
      text: 'Text',
      textContent: 'Type something',
      rect: 'Rectangle',
      ellipse: 'Ellipse',
      circle: 'Circle',
      image: 'Image',
      line: 'Line',
      triangle: 'Triangle',
      polygon: 'Polygon',
      paragraph: 'Text',
      input: 'Input',
      textarea: 'Text Area',
      pureText: 'Text',
      icon: 'Icon',
      canvasPanel: 'Panel',
      contentPanel: 'Content Panel',
      contentPanelV2: '#动态面板',
      frame: 'Web View',
      listLayoutPanel: 'List Panel',
      stackPanel: 'Stack Panel',
      wrapPanel: 'Wrap Panel',
      selectPanel: 'Select Panel',
      // extension
      button: 'Button',
      iconButton: 'Icon Button',
      switch: 'Switch',
      checkbox: 'Checkbox',
      checkboxGroup: 'Checkbox Group',
      radio: 'Radio Button',
      radioButtonGroup: 'Radio Button Group',
      select: 'Single-select Droplist',
      multipleSelect: 'Multi-select Droplist',
      carouselChart: 'Image Carousels',
      list: 'List',
      numericStep: 'Number Input',
      breadCrumbs: 'Breadcrumb',
      segmentsControl: 'Scope Bar',
      selectTab: 'Tab Bar',
      imageTextTab: 'App Navbar',
      verticalTab: '#垂直选项卡',
      slider: 'Slider',
      progressBar: 'Progress Bar',
      circleProgressBar: 'Progress View',
      score: 'Rating Bar',
      video: 'Video',
      qrcode: 'QR Code',
      keyboard: 'Keyboard',
      audio: 'Audio',
      tree: 'Tree',
      table: 'Table',
      hotArea: 'Hotspot',
      collapse: '#折叠面板',
      //datePicker
      datePicker: 'Date Picker',
      navigationMenu: 'Navigation Menu',
      verticalMenu: 'Vertical Menu',
      horizontalMenu: 'Horizontal Menu',
      datePickerInfo: {
        optionDate: 'Pick date',
        optionDateRange: '',
        startDate: 'Start',
        endDate: 'End',
        Mon: 'Mon',
        Tues: 'Tue',
        Wed: 'Wed',
        Thur: 'Thur',
        Fri: 'Fri',
        Sat: 'Sat',
        Sun: 'Sun',
        // year: '#年',
        // month: '#月',
        // date: '#日',
      },
      timePicker: 'Time Picker',
      //图表类
      barChart: 'Column Chart',
      pieChart: 'Pie Chart',
      doughnutChart: 'Donut Chart',
      lineChart: 'Line Chart',
      barChartHorizontal: 'Bar Chart',
      areaChart: 'Area Chart',
      radarChart: 'Radar Chart',
      scatterChart: 'Scatter Chart',
      combinationChart: 'Combo Chart',
      gaugeChart: '#仪表盘',
      mapChart: '#统计地图',
      data: 'Data',
      data_one: 'Data 1',
      data_two: 'Data 2',
      data_three: 'Data 3',
      project_one: 'Category 1',
      project_two: 'Category 2',
      project_three: 'Category 3',
      project_four: 'Category 4',
      project_five: 'Category 5',
      Jan: 'Jan',
      Fed: 'Feb',
      Mar: 'Mar',
      Apr: 'Apr',
      May: 'May',
      Jun: 'Jun',
      barLabel: 'Column',
      lineLabel: 'Line',
      // comment
      arrow: 'Arrow',
      callOut: 'Circle Marker',
      stickNote: 'Sticky Note',
      drop: 'Drop Marker',
      dropDown: 'Droplist options',
      snapshot: 'Snapshot',
      verticalCurly: 'V.Curly Brace',
      horizontalCurly: 'H.Curly Brace',
      verticalSeparatorLine: 'V.Rule',
      horizontalSeparatorLine: 'H.Rule',
      labelInput: 'Label Text Input',
      searchBox: 'Search Box',
      link: 'Link',
      path: 'Path',
      compoundPath: 'Compound Path',
      connector: 'Connector',
      group: 'Group',
    },
    componentsText: {
      itemText: 'Item',
      optionText: 'Item',
      newItem: 'New item',
      listItemText: 'Item',
      title: '#标题',
      content: '#内容',
      xAxisText: 'Category {0}',
      yAxisText: 'Data {0}',
      breadCrumbsText: 'Level 1|Level 2|Level 3|Level 4',
      qrCode: 'https://www.mockplus.com',
      selectTeam: 'Select team',
      selectProject: 'Select project',
      snapshotText: `Double click to insert Design page`,
      snapshotCanNotFound: 'Unable to find Design page',
      snapshotInvalid: 'Snapshot invalidated',
      VPCImageURL: 'https://img02.mockplus.cn',
      // replaceVPCImageURL: 'http://mockplus-static.oss-cn-hangzhou-internal.aliyuncs.com',
      snapshotTitle: 'Insert Design page',
      noContent: 'Nothing here',
      resetCropArea: 'Remove',
      fitCompText: 'Fit to current size',
      currentSize: 'Size',
      frameTips: 'Double click to enter a web address',
      timePickerTips: 'Pick time',
      startTime: 'Start',
      endTime: 'End',
      frameInQqDocsTips: '抱歉，当前环境中不支持显示网页内容',
    },
    // 组件： 图形库
    flow: {
      rectangle: 'Rectangle',
      parallelogram: 'Parallelogram',
      diamond: 'Diamond',
      circle: 'Circle',
      ellipse: 'Ellipse',
      triangle: 'Triangle',
      pentagon: 'Pentagon',
      roundRectangle: 'Rounded Rectangle',
      semicircle: 'Semicircle',
      octagon: 'Octagon',
      keying: 'Keying',
      start: 'Start/End',
      rightTriangle: 'Right Triangle',
      trapezoid: 'Trapezoid',
      hexagon: 'Hexagon',
      drop: 'Water Drop',
      heart: 'Heart',
      sector: 'Sector',
      star: 'Star',
      cloud: 'Cloud',
      dataBase: 'Database',
      punchedCard: 'Card',
      roundedSpeechBubble: 'Chat Bubble',
      squareSpeechBubble: 'Chat Box',
      rightArrowButton: 'Right Arrow Button',
      leftArrowButton: 'Left Arrow Button',
      offPageConnector: 'Off Page Connector',
      ready: 'Ready',
      beveledRectangle: 'Beveled Rectangle',
      sectorDiagram: 'Sector Diagram',
      cross: 'Cross',
      limitCycle: 'Limit Cycle',
      manualInput: 'Manual Input',
      punchedTape: 'Punched Tape',
      display: 'Display',
      externalData: 'External Data',
      manualOperation: 'Manual Operation',
      rightArrow: 'Arrow Right',
      leftArrow: 'Arrow Left',
      horizontalDoubleArrow: 'Left and Right Arrow',
      upArrow: 'Arrow Up',
      downArrow: 'Arrow Down',
      verticalDoubleArrow: 'Up and Down Arrow',
      upGrid: 'Up Grid',
      leftGrid: 'Left Grid',
      classicTab: 'Classic Tab',
      queueData: 'Queue Data',
      delay: 'Delay',
      APQC: 'APQC',
      collate: 'Collate',
      leftBracket: 'Left Bracket',
      rightBracket: 'Right Bracket',
      leftBrace: 'Left Brace',
      rightBrace: 'Right Brace',
      document: 'Document',
      pieChart: 'Pie',
      rightAngle: 'Right Angle',
      rightTurn: 'Right Turn',
      leftTurn: 'Left Turn',
    },
    // 图标库
    icons: {
      pay: 'Payment',
      office: 'Office',
      chart: 'Chart',
      gesture: 'Gesture',
      social: 'Social ',
      device: 'Device',
      traffic: 'Transportation',
      symbol: 'Symbol',
      direction: 'Direction',
      media: 'Multimedia',
      edit: 'Edit',
      treatment: 'Treatment',
      letter: 'Number/Letter',
      finance: 'Finance',
      eating: 'Food',
      nature: 'Nature',
    },
    // 设计资源库
    libs: {
      arrangementMode: 'Layout',
      component: 'Components',
      color: 'Colors',
      typography: 'Texts',
      addLib: 'Add {0} to assets',
      createSymbol: 'Create asset',
      share: 'Share',
      cancelSharing: 'Unshare',
      cancelSharingToAll: 'Unshare (to all teams)',
      placeholder: 'Assets from {0}s',
      shareToMyTeam: 'Are you sure you want to share this asset library to the current team?',
      shareToAllTeams: 'Share this asset library to:',
      cancelShareToMyTeam:
        'This asset library is being shared with the current team. Are you sure you want to stop sharing it?',
      cancelShareToAllTeams:
        'This asset library is being shared with all the teams within the system. Are you sure you want to stop sharing it?',
      myTeam: 'The current team',
      allTeams: 'All teams within the system',
      sync: 'Changes auto-sync to all instances',
      addGroup: 'Add group',
      management: 'Manage assets',
      defaultGroupName: 'New group',
      moveGroup:
        'The group "{0}" can\'t be restored once deleted. Are you sure you want to continue?\n If you want to keep {1} asset(s) inside, please move them to another group.',
      removeGroup: 'Are you sure you want to delete the group "{0}"?',
      noMoveGroup: 'Delete assets',
      moveAssetTo: 'Move assets to',
      moveAsset: 'Move',
      noGroup: 'No groups yet',
      noComponent: 'No assets yet',
      addComponentPlaceholder: 'Asset name',
      useComponentCount: '#已被添加使用{0}次',
      addGroupPlaceholder: 'Group name',
      resourceLoadFail: 'Sorry, loading error occurred.',
      reload: 'Reload',
      searchGroup: 'Search',
      cannotModify: 'Changes to this asset will auto sync to all instances. Only its creator can edit it.',
      deleteSyncComponent: 'Are you sure you want to delete this asset{0}? This action cannot be undone.',
      deleteAsyncComponent: "Are you sure you want to delete the asset{0}? This action can't be undone.",
      deleteSharedComponent: 'Shared assets cannot be deleted. You need to unshare the library first.',
      appName: 'From project: {0}',
      noApp: 'From a deleted project',
      cannotAddRefResource:
        'You are using a shared asset library. Please switch to the asset library of current project to add a new asset.',
      convertTips: 'Convert',
      convertSymbolToNormal: 'Convert to Regular Asset',
      convertToastText: 'Converted successfully',
      dialogConvertToSymbol:
        'Are you sure you want to convert the selected to Auto-sync Assets? This cannot be undone.',
      dialogConvertSymbolToNormal:
        'Are you sure you want to convert the asset to Regular Assets? This means that any new changes made to the asset will not be automatically synced across all instances. This cannot be undone.',
      dialogConvertSymbolOrNormal:
        'Are you sure you want to convert? This means that any new changes made to the Auto-sync assets will not be automatically synced across all instances. This cannot be undone.',
      convertToSymbol: 'Convert to Auto-sync Asset', //邹：转为引用资源的字符串需改为：Convert to Auto-sync Asset
      shareResources: '', // 英文没有
      contentPanelV2NoAddComponentsTip: '抱歉，动态面板不支持存为资源',
    },
  },
  // 项目
  project: {
    page: 'Page',
    group: 'Group',
    newPage: 'Add Page',
    newGroup: 'Add Group',
    addNew: 'Add',
    childPage: 'Child page',
    childGroup: 'Child group',
    insert: 'Insert',
    undo: 'Restore',
    delete: 'Clear',
    move: 'Move',
    moveUp: 'Up',
    moveDown: 'Down',
    moveUpLevel: 'One level up',
    moveDownLevel: 'One level down',
    resetAll: 'Restore all',
    clearAll: 'Clear all',
    defaultPageName: 'Page 1',
    undoNow: ' Restore now',
    showPageNumber: 'Show page numbers',
    hiddenPageNumber: 'Hide page numbers',
    showPage: 'Show',
    hiddenPage: 'Hide',
    hiddenPageTip: 'In Preview mode, hidden pages are invisible to those without project editing permissions.',
    mainName: 'Main artboard',
    mainDTName: 'Artboard',
    subArtboard: 'Sub-artboard {0}',
    subContent: '#动态面板 {0}',
    subDTArtboard: 'Artboard {0}',
    revisions: {
      title: 'Revision history',
      revert: 'Restore this version',
      noRevision: 'This artboard has no any revision history yet.',
      revertTips:
        'Are you sure you want to restore this page to the selected version? This action will only affect this page.',
      deleteVersion: 'Are you sure you want to delete this version? This cannot be undone.',
      deleteSuccessText: 'Version deleted',
      tips: 'Mockplus will generate revision history automatically. You can freely view and restore previous versions.',
      noPageRevisions: 'This page has no any revision history yet.',
      currentVersion: 'Current version',
      revisions: 'Revision history',
      artboard: 'Artboards',
      moreRevisions: 'View more',
      upgradeTips: 'Upgrade your team to view more revision histories.',
      personalSpaceUpgradeTips: 'Upgrade your team to view more revision histories.',
      upgradeHelp: 'Upgrade now',
      loadFailed: 'Loading failed. Click to retry',
      noneMore: 'No more',
      saveSuccessText: 'Current page version saved',
      renameVersionTitle: 'Rename',
      conditionHasAuto: 'Show autosave versions',
      filterTooltips: 'Filter',
      deleteTooltips: 'Delete',
      revertTooltips: 'Restore',
      rollbackTips:
        'This page has been restored to the version "{1}" by {0}. And you will be auto taken to that version by clicking the "OK" below.',
    },
    empty: {
      welcome: 'Start Designing',
      tips: 'Hi-fi interactive prototyping with real-time collaboration',
    },
    currentProject: 'Current project',
  },
  // 编辑器
  editor: {
    clickAndUpload: 'Click to upload',
    applyOriginalSize: 'keep original size',
    commonlyUsedColor: 'Favorites',
    solidFill: 'Flat color',
    linearFill: 'Linear gradient',
    radialFill: 'Radial gradient',
    append: 'Add',
    up: 'Move up',
    down: 'Move down',
    addChild: 'Add child item',
    mergeCells: 'Merge cells',
    splitCells: 'Unmerge cells',
    cleanStyle: 'Clear formats',
    cleanContent: 'Clear contents',
    row: 'Row',
    column: 'Column',
    unshiftColumn: 'Insert 1 column left',
    unshiftColumns: 'Insert 3 columns left',
    pushColumn: 'Insert 1 column right',
    pushColumns: 'Insert 3 columns right',
    unshiftRow: 'Insert 1 row above',
    unshiftRows: 'Insert 3 rows above',
    pushRow: 'Insert 1 row below',
    pushRows: 'Insert 3 rows below',
    dragToAddRows: 'Click or drag to add rows',
    dragToAddColumns: 'Click or drag to add columns',
    tableInfo: 'Row: {0}, Column: {1}',
    deletColumns: 'Delete current column',
    deletRows: 'Delete current row',
    contentType: 'Cell type',
    convertToText: 'Text',
    convertToCheckBox: 'Checkbox',
    locate: 'Locate',
  },
  // 桌面应用
  pc: {
    name: 'Mockplus RP',
    homepage: 'Home',
    logout: 'Log out',
    team: {
      change: {
        label: 'Switch team',
      },
      name: {
        label: 'Name',
      },
      role: {
        label: 'Role',
        value: {
          superAdministrator: 'Super Admin',
          administrator: 'Admin',
          member: 'Member',
          visitor: 'Collaborator',
          // observer: '#访客', //--------------------------------
        },
        ability: {
          administrator: 'Admin (all permissions)',
          member: 'Member (edit and design)',
          visitor: 'Collaborator (comment and view specs)',
          observer: 'Guest (comment and preview only)', //----------------------------
        },
      },
      homepage: {
        label: 'Team homepage',
        url: 'https://rp.mockplus.com/team/{0}',
      },
      upgrade: {
        personalSpaceContent: "Sorry, Free plan users can't access this feature. Please upgrade to unlock it.",
        content: "Sorry, Free plan users can't access this feature. Please upgrade to unlock it.", // 与免费计划相关不翻译
        activity: '', // 与免费计划相关不翻译
        buy: 'Upgrade now',
        buyNow: 'Upgrade now',
      },
      create: {
        title: 'Create new team',
        label: 'Create a team to start designing',
        placeholder: 'Team name',
        value: 'Team of {0}', // 同线上
      },
      expansion: 'Expand the team',
      expansionNow: 'Expand now',
      upgradeTips:
        'Please upgrade your team to add more teammates. For now, you can still invite Guests to view your projects.',
      expansionTips:
        'Please expand your team to add more teammates. For now, you can still invite Guests to view your projects.',
      contactUs1: "This team doesn't have any member seats left. Please contact",
      contactUs2: ', to add more seats.',
    },
    app: {
      createLabel: 'Project',
      appsIsMaximum: 'Max project limit has reached. Please upgrade the team to try again.',
      newApp: 'New',
      create: {
        title: 'New project',
      },
      user: {
        label: 'Creator',
      },
      time: {
        create: 'Date created',
        update: 'Last updated',
      },
      name: {
        label: 'Name',
        value: 'New project',
        placeholder: 'Project name',
      },
      type: {
        label: 'Project type',
        recent: 'Recently edited',
        star: 'My favorites',
        myCreate: 'My projects',
        all: 'All',
        recycle: 'Recycle bin',
        achive: 'Archived projects',
        private: {
          value: 'Private',
          explain: 'Accessible to all members of this project',
        },
        teamApp: {
          value: 'Team',
          explain: 'Accessible to all members of the current team',
        },
      },
      appSet: {
        create: {
          title: 'Create project folder',
        },
        name: {
          placeholder: 'Project folder name',
        },
        parent: {
          placeholder: 'Root',
        },
      },
      restore: 'Restore',
      clear: {
        value: 'Clear',
        title: 'Are you sure you want to clear? This action cannot be undone.',
      },
      star: 'Add to favorites',
      unStar: 'Remove from favorites',
      move: 'Move',
      delete: 'Are you sure you want to delete "{0}"?',
      noContent: 'Nothing created here yet',
      search: 'Search',
      screen: 'Filters',
      module: {
        rp: {
          label: 'RP projects',
        },
        all: 'All projects',
      },
      demo: 'Preview',
      member: {
        label: 'Members',
        authorization: 'Permissions',
        search: 'Search',
        invitation: {
          label: 'Invite members',
          link: 'Invite link',
          copyLink: 'Copy',
          term: 'Expire after',
          invalid: {
            label: '{0} days',
            content: 'This link will expire in {0} days',
          },
          forever: {
            label: 'Never',
            content: 'This link will never expire',
          },
          fullStarffed: {
            tipOne: 'You have reached the max limit of',
            tipTwo: 'members. Please expand your team to add more members.',
            expansion: 'Expand now',
          },
          overflow: {
            // 未用到
            title: '成员数量已超出限制，请联系摹客客服处理。',
            contact: '联系客服',
          },
        },
        department: 'Group',
        addDepartment: 'Add group',
        submit: 'Submit',
        submitSuccess: 'Done',
        ungrouped: 'Ungrouped',
        lastSelection: 'Last selection',
        noMembers: 'This team does not have any other members yet.',
        removeParent: {
          group:
            'Groups of the parental project folder are disabled. Please deselect them in the "Manage members" page of the parent project folder.',
          member:
            'Members of the parental project folder are disabled. Please deselect them in the "Manage members" page of the parent project folder.',
          link: isPrivateDeployment
            ? `${window.location.origin}${window.apis.helpDomain}/p/240`
            : 'https://help.mockplus.com/p/240',
        },
        removeCreator: 'You are not allowed to remove the project owner.',
      },
      width: 'W',
      height: 'H',
      overrideTips: '', //------------------------
    },
    user: {
      email: {
        placeholder: 'Email',
        noValue: 'Please enter a valid email address.',
        register: {
          placeholder: 'Email',
        },
      },
      password: {
        placeholder: 'Password',
        register: {
          placeholder: 'Password (6-20 characters)',
        },
        repeat: 'Confirm password',
        forgot: 'Forgot password?',
        noValue: 'The password must be between 6-20 characters long.',
        different: 'Password does not match.',
      },
      vocation: {
        value: {
          pm: 'Product manager',
          projectManager: 'Project manager',
          designer: 'Designer',
          developer: 'Developer',
          operator: 'Marketing specialist',
          other: 'Others',
        },
        placeholder: 'What kind of work do you do?',
      },
      noUser: "Don't have an account?",
      register: {
        link: 'Sign up',
        value: 'Continue',
      },
      agree: {
        read: 'By signing up, I agree to Mockplus ',
        value: 'Terms of Service.',
      },
      login: {
        title: 'Mockplus',
        content: 'Design easier and faster',
        value: 'Sign in',
        hasAccount: 'Already have an account?',
        link: 'Sign in',
        linkToWebsite: '',
        networkError: 'Network error. Please try again.',
      },
      admin: {
        label: 'My profile',
        link: 'https://www.mockplus.cn/me',
      },
      guest: {
        cannotEdit: 'Collaborators cannot edit or access projects in this desktop app.',
        contactAdministrator: 'Please contact Admin to change your permissions and try again',
      },
      switch: 'Switch account',
    },
  },
  theme: {
    apply: 'Apply',
    cancel: 'Discard',
  },
  // 属性
  property: {
    properties: 'Properties',
    interactions: 'Interactions',
    remarks: 'Notes',
    // 属性名
    propertyNames: {
      direction: '#方向',
      fill: 'Fill',
      bgcolor: 'Background color',
      hoverFill: 'Hover color',
      forgetColor: 'Foreground color',
      buttonColor: 'Button color',
      checkedColor: 'Selected color',
      defaultColor: 'Default color',
      checkedBgcolor: 'Selected background',
      stroke: 'Border',
      lineStroke: 'Connector',
      checkedUnderbar: 'Underline',
      border: 'Slide', // 未应用
      radius: 'Corner', // 未应用
      text: 'Text',
      checkedText: 'Selected text style',
      defaultText: 'Default text style',
      multiText: '', // 未应用
      polygon: 'Polygon',
      icon: 'Icon',
      iconColor: '', // 未应用
      color: 'Color',
      img: 'Image',
      iconSize: 'Icon size',
      layout: 'Layout',
      container: '', // 未应用
      shadow: 'Shadow',
      line: '', // 未应用
      lineHeight: 'Item height',
      inputModel: 'Input mask',
      arrow: 'Arrow',
      placeholder: 'Placeholder',
      placeholderColor: 'Placeholder color',
      angle: 'Rotation',
      close: '', // 未应用
      controls: 'Show controller',
      autoPlay: 'Autoplay',
      hoverTextStyle: 'Link style',
      checked: 'Selected',
      trackStyle: 'Track style',
      progressStyle: 'Progress style',
      progress: 'Value',
      progressColor: 'Progress color',
      trackFill: 'Track color',
      indicatorColor: 'Slider color',
      separator: 'Divider line',
      separatorRatio: 'Divider height',
      keyboard: 'Type',
      featureButtonColor: 'Function key color',
      fontColor: 'Text color',
      textBgColor: '', // 未应用
      defaultSelected: 'Selected',
      defaultOpen: 'On',
      padding: 'Padding',
      extensionLink: 'External link',
      remark: 'Notes',
      blur: 'Blur',
      colorFilter: 'Adjust color',
      float: 'Fix position when scrolling',
      timePickerType: 'Type',
      timeFormat: 'Format',
      deviceType: 'Device',
      dateType: 'Type',
      dateFormat: 'Format',
      showMark: 'Show mask',
      showSelectedYear: 'Show Year',
      replaceShape: 'Shape',
      axis: 'Axis',
      dataLabel: 'Data labels',
      legendPosition: 'Legend',
      gap: 'Distance with chart',
      type: 'Type',
      smooth: 'Smoothed line',
      stack: 'Stacked',
      loopPlay: 'Loop',
      crossPagePlay: 'Play in background',
      canSearch: 'Searchable',
      markerStrip: 'Underline',
      autoCarousel: 'Autoplay',
      indicator: 'Indicator',
      turnPageButton: 'Arrow buttons',
      playEffect: 'Animation',
      playInterval: 'Interval',
      playSpeed: 'Speed',
      length: 'Length',
      indicatorType: {
        circle: 'Dot',
        rect: 'Rectangle',
      },
      playEffectType: {
        push: 'Swipe in',
        slide: 'Slide in',
        fade: 'Fade in',
      },
      tooltips: 'Tooltip',
      unit: 'Unit',
      yAxisStart: 'Start value',
      yAxisGap: 'Interval value',
      barYAxisStart: 'Column start value',
      barYAxisGap: 'Column interval value',
      lineYAxisStart: 'Line start value',
      lineYAxisGap: 'Line interval value',
      axisLabelRotate: 'Label angle',
      scaleMark: '#刻度线',
      barWidth: 'Bar width',
      showAnimation: 'Loading animation',

      noteConnectedLine: {
        showLine: '#连接线',
        width: '#宽度',
      },
      gaugeChartProperties: {
        dialPlate: '#表盘',
        basic: '#基础',
        stage: '#阶段',
        grade: '#等级',
        bgColor: '#背景色',
        progressColor: '#进度色',
        color: '#颜色',
        notchAngle: '#缺口角度',
        arcWidth: '#宽度',
        pointType: '#端点',
        data: '#数据',
        defaultValue: '#默认值',
        min: '#最小值',
        max: '#最大值',
        dataLabel: '#数据标签',
        labelUnit: '#单位',
        tick: '#刻度',
        tickNumber: '#数值',
        tickGap: '#间隔',
        smallTick: '#小刻度',
        pointer: '#指针',
        dataFluctuation: '#数据波动',
      },
      mapChart: {
        region: '#国家',
        type: '#类型',
        polychrome: '#单色',
        monochrome: '#多色',
        heatmap: '#热力图',
        regionName: '#区域名称',
        regionBorder: '#区域边框',
        hoverArea: '#悬停区域',
        popupWin: '#悬浮窗口',
        rightBottom: '#右下角',
        leftBottom: '#左下角',
        rightTop: '#右上角',
        leftTop: '#左上角',
      },
      selectList: {
        labelName: '#下拉列表',
        bgColor: '#背景颜色',
        selectedBgColor: '#选中背景',
        selectedFontColor: '#选中字色',
      },
      // 导出
      export: {
        export: 'Export',
        preview: 'preview',
        prefix: 'Prefix',
        suffix: 'Suffix',
        multiExport: 'Export {0} layers',
        loadingText: 'prepare files, completeed {0}/{1},please wait...',
      },
      expandOneItem: '#单次只展开一项',
      textVerticalPadding: '#文本垂直边距',
      titleBgColor: '#标题背景',
      contentBgColor: '#内容背景',
      titleTextStyle: '#标题文本样式',
      contentTextStyle: '#内容文本样式',
    },
    // 组件属性
    component: {
      onLeft: '#左边',
      onRight: '#右边',
      structureAlignLeft: '#文字在左', // 中文版暂未上线
      structureAlignRight: '#文字在右', // 中文版暂未上线
      alignLeft: 'Align left',
      alignVerticalCenter: 'Align center',
      alignRight: 'Align right',
      alignTop: 'Align top',
      alignJustify: 'Justify',
      alignHorizontalCenter: 'Align middle',
      alignBottom: 'Align bottom',
      alignAverageHorizontal: 'Distribute horizontally',
      alignAverageVertical: 'Distribute vertically',
      horizontalSpacing: 'Horizontal spacing',
      verticalSpacing: 'Vertical spacing',
      showComp: 'Show',
      hideComp: 'Hide',
      enabledComp: 'Enable',
      disabledComp: 'Disable',
      selectComp: '{0} components selected',
      opacity: 'Opacity',
      clipPath: 'Cut path',
      openPath: 'Open path',
      closePath: 'Close path',
      segRadius: 'Radius',
      straight: 'Straight',
      mirrored: 'Mirrored',
      disconnected: 'Disconnected',
      asymmetric: 'Asymmetric',
      shape: 'Style',
      text: 'Text',
      extend: 'Extension',
      relation: {
        label: 'Link to',
        add: 'Add new link',
        init: {
          specificationDocument: 'Style guides',
          componentCode: 'Component codes',
          visualAnnotation: 'Visual specs',
        },
      },
      border: {
        left: 'Left border',
        right: 'Right border',
        bottom: 'Bottom border',
        top: 'Top border',
      },
      bounds: {
        lockRatio: 'Maintain aspect ratio',
        flipHorizontal: 'Flip horizontal',
        flipVertical: 'Flip vertical',
        fixedWidth: 'Fixed width',
        fixedHeight: 'Fixed height',
        horizontalCenter: 'Center horizontally',
        verticalCenter: 'Middle vertically',
        responsive: 'Smart layout',
        auto: 'Auto',
        manual: 'Manual',
      },
      cell: {
        rowHeight: 'Row height',
        rowCount: 'Row',
        itemHeight: '', // 未应用
        itemWidth: '', // 未应用
        columnWidth: 'Column width',
        columnCount: 'Column',
        columnGap: 'Column gap',
        rowGap: 'Row gap',
        gap: '', // 未应用
      },
      container: {
        scroll: 'Scrollable',
        showScroll: 'Show scrollbar',
        modeBothText: 'Both',
        modeVerticalText: 'Vertically',
        modeHorizontalText: 'Horizontally',
      },
      img: {
        upLoadImg: 'Upload',
        fit: 'Fill',
        tile: 'Tile',
        original: 'Fit',
        stretch: 'Stretch',
        defaultStyle: 'Default style',
        transformShape: 'Convert to vector shape',
      },
      icon: {
        lightIcon: 'Thin',
        boldIcon: 'Bold',
        defaultStyle: 'Style',
      },
      colorFilter: {
        brightness: 'Brightness',
        contrast: 'Contrast',
        hueRotate: 'Hue',
        saturate: 'Saturation',
        invert: 'Invert',
        revert: 'Restore',
      },
      layout: {
        vertical: 'Vertical',
        horizontal: 'Horizontal',
        gap: 'Gap',
      },
      line: {
        startArrow: 'Start',
        endArrow: 'End',
        none: 'None',
      },
      multiText: {
        lineHeight: 'Line spacing',
        wrap: 'WordWrap',
        autoSize: 'AutoSize',
        indent: 'Text-indent',
        vertical: 'Vertical',
      },
      polygon: {
        sideCount: 'Sides',
      },
      radius: {
        allRadius: 'All corners',
        eachRadius: 'Individual corner',
        isPercent: 'By percentage',
      },
      textStyle: {
        richText: 'Rich text',
        bold: 'Bold',
        italic: 'Italic',
        underline: 'Underline',
        deleteline: 'Strikethrough',
        MicrosoftYahei: 'Microsoft Yahei',
        pingfang: 'PingFang SC',
        SimSun: 'SimSun',
        FangSong: 'FangSong',
        KaiTi: 'KaiTi',
        SimHei: 'SimHei',
        siYuanHei: 'Source Han Sans',
        siYuanSong: 'Source Han Serif',
        aLiBaBa: 'Alibaba Sans',
        zhanKuGaoDuanHei: 'ZCOOL GaoDuanHei',
        zhanKuHappy: 'ZCOOL KuaiLe',
        zhanKuKuHei: 'ZCOOL KuHeiTi',
        zhanKuLOGO: 'ZCOOL XiaoWeiLogo',
        zhanKuHuangYou: 'ZCOOL QingKe HuangYou',
        youSheTitle: 'YouSheBiaoTiHei',
        youSheGoodBody: 'YouSheHaoShenTi',
        yuanQuanYuan: 'GenSenMaruGothicTW',
        taiBeiHei: 'Beta Bold',
        pangMenTitle: 'PangMenZhengDao',
        pangMenBold: 'PangMenZhengDao-Cu',
        letterSpacing: 'Character spacing',
        olist: 'Numbered list',
        ulist: 'Bulleted list',
        fitWidth: 'Auto width',
        fitHeight: 'Auto height',
        fitBoth: 'Fixed size',
        capitalization: 'Title case',
        uppercase: 'Uppercase',
        lowercase: 'Lowercase',
      },
      stroke: {
        thickness: 'Width',
        dash: 'Dash',
        gap: 'Gap',
        buttCap: 'Butt cap',
        roundCap: 'Round cap',
        squareCap: 'Square cap',
        miterJoin: 'Miter join',
        roundJoin: 'Round join',
        bevelJoin: 'Bevel join',
        centerStroke: 'Center',
        innerStroke: 'Inside',
        outerStroke: 'Outside',
      },
      keyboard: {
        letter: 'Alphabet',
        numeric: 'Number',
        symbol: 'Symbol',
      },
      direction: {
        top: 'Top',
        left: 'Left',
      },
      inputModel: {
        normal: 'Text',
        numeric: 'Number',
        password: 'Password',
      },
      tree: {
        levelIndent: 'Indentation',
        treeExpand: 'Button',
        treeNode: 'Icon',
        treeCheckbox: 'Checkbox',
        itemText: 'Item',
        triangle: 'Triangle',
        plus: 'Plus',
        arrow: 'Arrow',
      },
      table: {
        rowHeight: 'Row height',
        columnWidth: 'Column width',
        border: 'Border',
        fill: 'Fill',
        rowLine: 'Row line',
        columnLine: 'Column line',
      },
      fixContent: 'All states share the same text',
      dateType_normal: 'Date',
      dateType_range: 'Date range',
      web: 'Web',
      phone: 'Mobile',
      dateformat_normal: 'mm-dd-yyyy',
      dateformat_secondStyle: 'mm/dd/yyyy',
      // dateformat_thirdStyle: 'yyyy年mm月dd日', 英文版不需要
      dateformat_fourStyle: 'dd.mm.yyyy',
      timePickerModelNormal: 'Time',
      timePickerModelRange: 'Time range',
      legendPosition_bottom: 'Bottom',
      legendPosition_top: 'Top',
      legendPosition_left: 'Left',
      legendPosition_right: 'Right',
      number: 'Number',
      percent: 'Percentage',
      pie_normal: 'Pie chart',
      pie_abNormal: 'Donut chart',
      barChart: 'Column chart',
      barChartHorizontal: 'Bar chart',
      areaChart: 'Area chart',
      radarChart: 'Radar chart',
      lineChart: 'Line chart',
      scatter: 'Scatter chart',
    },
    // 页面属性
    page: {
      resetSize: 'Set as default size',
      selectArtboard: '{0} artboard(s) selected',
    },
    // 交互
    interaction: {
      fragmentAction: 'Artboard interaction',
      invalidTarget: 'Invalid target',
      invalidState: 'Invalid state',
      invalidFragment: 'Invalid artboard',
      addAction: 'Add interaction',
      home: 'Home',
      back: 'Back link',
      actionOrder: 'Commands',
      autoRevert: 'Auto recovery',
      parallel: 'Start simultaneously',
      compCommand: '[{0}] {1}: {2}',
      pageCommand: 'Go to page : {0}',
      fragmentCommand: 'Show artboard: {0}',
      closeFragmentCommand: 'Close artboard: {0}',
      linkCommand: 'Open link: {0}',
      eventType: 'Triggers',
      command: 'Commands',
      selectTarget: 'Targets',
      currentComp: 'Self',
      currentFragment: 'This artboard',
      switchPage: 'Page jump',
      closeArtboard: 'Close artboard',
      popUpArtboard: 'Show artboard',
      loopSwitch: 'Loop continuously',
      triggerconditions: {
        click: 'Click ',
        doubleClick: 'Double click',
        contextMenu: 'Right click',
        mouseDown: 'Mouse down',
        mouseUp: 'Mouse up',
        mouseEnter: 'Mouse enter',
        mouseLeave: 'Mouse leave',
        swipeDown: 'Slide down',
        swipeLeft: 'Slide left',
        swipeUp: 'Slide up',
        swipeRight: 'Slide right',
        loaded: 'Loaded',
        afterMove: 'Moved',
        afterZoom: 'Zoomed',
        afterRotate: 'Rotated',
        afterVisible: 'Shown',
        afterHide: 'Hidden',
        afterResize: 'Resized',
        checked: 'Selected',
        unChecked: 'Unselected',
        toggleCheck: 'Selected or unselected',
        focus: 'Got focus',
        blur: 'Lost focus',
        scroll: 'Scroll',
        on: 'On',
        off: 'Off',
        toggle: 'On/Off',
      },
      // 交互描述
      pageIntro: 'When {0}, go to page {1}',
      fragmentIntro1: 'When {0}, close {1}',
      fragmentIntro2: 'When {0}, show {1}',
      compIntro: 'When {0}, make {1} {2}',
      compIntro2: 'When {0}, scroll to {1}',
      openInCurrPage: 'Open in current window',

      // 交互参数
      timing: 'Easing',
      duration: 'Duration',
      delay: 'Delay',
      loop: '', // 未应用
      transitions: 'Animate',
      fromCurrent: 'Relative value',
      content: 'Content',
      width: 'Width',
      height: 'Height',
      rotate: 'Angle',
      state: 'State',
      show: 'Show',
      hidden: 'Hide',
      toggle: 'Toggle',
      toggle2: 'Show/Hide',
      horizontal: 'Horizontally',
      vertical: 'Vertically',
      both: 'Both',
      mode: 'Position',
      forceFront: 'Always on top',
      showBackground: 'Show mask',
      clickExternalClose: 'Click mask to close',
      artboardListTutorialUrl: 'https://help.mockplus.com/p/474#1',
      siteName: 'Name',
      url: 'URL',
      removeLink: 'Delete',
      addLink: 'Add',
      selectApp: 'Select Cloud project',
      noApp: 'No Cloud projects yet',
      noPrototype: 'No Prototype projects yet',
      howToUploadAxureFiles: 'How to link to an Axure file',
      axureLinkTutorialUrl: isPrivateDeployment
        ? `${window.location.origin}${window.apis.helpDomain}/p/452#6`
        : 'https://help.mockplus.com/p/452#6',
      selectPrototype: 'Select Prototype project',
      searchPrototype: 'Search',
      noPage: 'No pages yet',
      selectPage: 'Select page',
      searchPage: 'Search',
      web: 'Web',
      openWithSelf: 'Open in current window',

      // 触发方式
      click: 'Click',
      doubleClick: 'Double click',
      contextMenu: 'Right click',
      mouseDown: 'Mouse down',
      mouseUp: 'Mouse up',
      mouseEnter: 'Mouse enter',
      mouseLeave: 'Mouse leave',
      loaded: 'On load',
      afterMove: 'Moved',
      afterZoom: 'Zoomed',
      afterRotate: 'Rotated',
      afterVisible: 'Shown',
      afterHide: 'Hidden',
      afterResize: 'Resized',
      checked: 'Selected',
      unChecked: 'Unselected',
      toggleCheck: 'Selected or unselected',
      focus: 'Got focus',
      blur: 'Lost focus',
      scroll: 'Scroll',
      swipeLeft: 'Slide left',
      swipeUp: 'Slide up',
      swipeRight: 'Slide right',
      swipeDown: 'Slide down',

      // 交互命令
      move: 'Move',
      moveBy: 'Move by',
      moveTo: 'Move to',
      resize: 'Resize',
      resizeBy: 'Resize by',
      resizeTo: 'Resize to',
      rotation: 'Rotate',
      rotationBy: 'Rotate by',
      rotationTo: 'Rotate to',
      toggleVisible: 'Show/Hide',
      zoom: 'Zoom',
      zoomBy: 'Zoom by',
      zoomTo: 'Zoom to',
      toggleState: 'Switch state',
      switchContent: 'Switch content',
      prev: 'Previous',
      next: 'Next',
      more: '更多',
      // 动画效果
      none: 'None',
      autoEffect: 'Auto',
      zoomInRevert: 'Contract to center',
      slideToBottomRevert: 'Slide out from bottom',
      slideToRightRevert: 'Slide out from right',
      slideToLeftRevert: 'Slide out from left',
      slideToTopRevert: 'Slide out from top',
      fadeInRevert: 'Fade out',
      spreadInRevert: 'Flip out',
      pushToLeft: 'Swipe in from right',
      pushToRight: 'Swipe in from left',
      pushToTop: 'Swipe in from bottom',
      pushToBottom: 'Swipe in from top',
      slideToLeft: 'Slide in from right',
      slideToRight: 'Slide in from left',
      slideToTop: 'Slide in from bottom',
      slideToBottom: 'Slide in from top',
      fadeIn: 'Fade in',
      zoomIn: 'Zoom in',
      slideFromLeft: 'Slide right',
      slideFromRight: 'Slide left',
      slideFromTop: 'Slide down',
      slideFromBottom: 'Slide up',
      fadeOut: 'Fade out',
      zoomOut: 'Zoom out',
      flipInLeft: 'Flip left',
      flipInRight: 'Flip right',
      flipInTop: 'Flip up',
      flipInBottom: 'Flip down',
      spreadIn: 'Flip in',

      // 辅画板交互位置模式
      custom: 'Custom',
      center: 'Center',
      left: 'Left',
      right: 'Right',
      top: 'Top',
      bottom: 'Bottom',

      // 时间曲线
      noTiming: 'None',
      linear: 'Linear',
      easeIn: 'Ease in',
      easeOut: 'Ease out',
      easeInOut: 'Ease in out',
      tips: {
        move: 'If ticked, move relative to the current position\nIf unticked, move to the specified location',
        resize: 'If ticked, resize relative to the current size\nIf unticked, resize to the specified size',
        scale: 'If ticked, scale relative to the current size\nIf unticked, scale to the specified percentage',
        rotate: 'If ticked, rotate relative to the current angle\nIf unticked, rotate to the specified angle',
        loadedDisabled: 'This only works for an artboard being selected as the trigger source',
        revertEffect: 'Auto use the opposite effect of showing the artboard',
        showArtboard: '#辅助画板',
      },
    },
    remark: {
      pageTitle: 'Page notes',
      clear: 'Clear',
    },
  },
  // 组件状态
  state: {
    state: 'State',
    originalState: 'Normal',
    componentState: 'Default state - {0}',
    customState: 'Custom state',
    hover: 'Mouse hover',
    pressed: 'Pressed',
    checked: 'Selected',
    focus: 'Focused',
    disabled: 'Disabled',
    default: 'Default',
    addState: 'Add state',
  },
  // 菜单
  menu: {
    applyFill: 'Apply to Fill',
    applyStroke: 'Apply to Border',
    applyText: 'Apply to Text',
    align: 'Order',
    bringToFront: 'Bring to front',
    frontForward: 'Bring forward ',
    backForward: 'Send backward',
    sendToBack: 'Send to back ',
    sameSize: 'Adjust size',
    minWidth: 'Min equal width',
    maxWidth: 'Max equal width',
    minHeight: 'Min equal height',
    maxHeight: 'Max equal height',
    addColor: 'Add color to assets',
    addTypography: 'Add text style to assets',
    addComponent: 'Add component to assets',
    unlock: 'Unlock',
    lock: 'Lock',
    unlockAll: 'Unlock all components',
    selectionGroup: 'Selection group',
    flip: 'Flip',
    horizontalFlip: 'Flip horizontally',
    verticalFlip: 'Flip vertically',
    autoFillText: 'Auto fill',
    editStyle: 'Advanced edit',
    path: 'Boolean operation',
    exclude: 'Exclude',
    subtract: 'Subtract',
    unite: 'Unite',
    intersect: 'Intersect',
    divide: 'Break apart',
    convertToPath: 'Convert to path',
    detachSymbol: 'Detach from asset',
    cropImage: 'Crop',
    crop: 'Crop',
    sliceImage: 'Slice',
    convertToPanel: 'Convert to Panel',
    detachPanel: 'Detach from Panel',
    revertOriginSize: 'Revert original size',
    cleanInteraction: '#清除交互',
    copyInteraction: 'Copy interaction',
    pasteInteraction: 'Paste interaction',
    copyStyle: 'Copy styles',
    pasteStyle: 'Paste styles',
    exportPicture: 'Export image',
    exportPDF: 'Export PDF',
    exportSvg: 'Export SVG',
    downloadOriginImage: 'Export image',
  },
  // 演示
  preview: {
    alwaysShowLinkArea: 'Always show link area',
    showLinkAreaWhenHovered: 'Show link area on mouse hover',
    autoScreen: 'Fit to screen',
    noBoundary: 'Show content outside artboard',
    showNavigationBar: 'Show toolbar',
    showControllerPanel: 'Show control panel',
    showRemarkTag: 'Show note markers',
    mobileType: 'Device frame',
    qrcode: 'QR code',
    controlPanel: 'Control panel',
    remarkPanel: 'Note panel',
    noRemark: 'No notes created for this page yet.',
    controlPanelState: {
      expand: 'Expand',
      collapse: 'Collapse',
      hide: 'Hide',
    },
    home: 'Back to home',
    backward: 'Back',
    forward: 'Forward',
    exit: 'Exit',
    shareUrl: 'Share preview link',
    folder: 'Contents',
    remark: 'Notes',
    shareRunTitile: 'Share preview link',
    sharePageTip: 'Select the pages and folders you want to share',
    copy: 'Copy',
    enablePass: 'Set password',
    inputPass: 'Enter your password',
    setPassInputError: '',
    setPassEmptyError: 'Enter a password to protect your project',
    updateSuccess: 'Password set successfully',
    linkText: 'Preview link: {0}',
    pwdText: 'Password: {0}',
    iosExitText: 'Slide right to exit previewing',
    projectNotExistOrDeleted: 'The project does not exist or has been deleted. ',
    noPermissionText: 'You cannot view this project',
    linkHasNotValidText: 'This link has expired',
    resetLinkSuccess: 'Reset successfully',
    onlyMember: 'Only the project member can view this project',
    loginTip: 'Please first log in to continue',
    xiaoMi: 'Xiaomi',
    iPhoneBlack: 'iPhone 8 Plus Black',
    iPhone: 'iPhone 8 Plus',
    iPhoneX: 'iPhone 14',
    galaxy: 'Samsung',
    huaweiP40: 'Huawei P40',
    huaweiMate: 'Huawei Mate 40',
    iPad: 'iPad',
    surfacePro: 'Surface Pro',
    appleWatch38mm: 'Apple Watch 38mm',
    appleWatch40mm: 'Apple Watch 40mm',
    appleWatch42mm: 'Apple Watch 42mm',
    appleWatch44mm: 'Apple Watch 44mm',
    appleWatch45mm: 'Apple Watch 45mm',
    appleWatch49mm: 'Apple Watch 49mm',
    appleWatch: 'Apple Watch',
    appleWatchUltra: 'Apple Watch Ultra',
    watch: 'Watch',
    vehicle: 'Vehicle',
    prev: 'Previous page',
    next: 'Next page',
    fullScreen: 'Fit to screen',
    fullWidth: 'Fit to width',
    more: 'More',
    showControlInterface: 'Show control panel',
    showPagePanel: 'Show page panel',
    hidePagePanel: 'Hide page panel',
    showRemarkPanel: 'Show note panel',
    hideRemarkPanel: 'Hide note panel',
    bottomTip: 'The toolbar is hidden. Show it again by moving cursor into the below area.',
    autoHideNavPanel: 'Auto hide the toolbar',
    shareProjectName: 'Please view 《{0}》',
    prevStep: 'Back',
    nextStep: 'Next',
    gotIt: 'Got it',
    guideTips: {
      step1: {
        title: 'The toolbar',
        desc: 'The toolbar is now moved to the bottom.',
      },
      step2: {
        title: 'Views',
        desc: 'New buttons let you show/hide control panels or directly enter full screen.',
      },
      step3: {
        title: 'More',
        desc: 'Click here to change your preview settings.',
      },
      step4: {
        title: 'Auto hide the toolbar',
        desc: 'Hide the toolbar to enjoy a more immersive experience.',
      },
    },
    setNodeIdsSuccess: 'Set successfully',
  },
  // 通用
  general: {
    multipleSelect: 'Multiselect',
    ignoreArchiveTip: "Don't show again",
    gotIt: 'Got it',
    next: 'Next',
    prev: 'Previous',
    back: 'Back',
    ok: 'OK',
    skip: 'Skip',
    more: 'Learn more',
    details: 'View more',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    delete: 'Delete',
    clone: 'Clone',
    copy: 'Copy',
    cloneCurrentNode: 'Current item',
    cloneWithChildren: 'Branch',
    cut: 'Cut',
    paste: 'Paste',
    selectAll: 'Select all',
    rename: 'Rename',
    edit: 'Edit',
    none: 'None',
    width: 'Width',
    height: 'Height',
    exit: 'Exit',
    preserve: 'Save',
    or: 'or',
    refresh: 'Refresh',
    download: 'Download',
    enterFullScreen: 'Full screen', // idoc 已翻译
    leaveFullScreen: 'Exit full screen', // idoc 已翻译
    loading: 'Loading...',
    replace: 'Replace',
    export: 'Export',
    exportResourceLibs: 'Export asset library',
    scale: {
      min: 'Zoom out',
      max: 'Zoom in',
    },
  },
  // 警告信息
  alert: {
    refresh: 'Refresh',
    pageHasBeenDeleted: 'Sorry, this page has been deleted by other members.',
    makeSureToDeletePage: 'Are you sure to delete this page?',
    makeSureToDeletePageGroup: 'Are you sure to delete this group?',
    networkDisconnected: 'Sorry, there is a network connection error. Please check the network and refresh the page.',
    afk:
      'All changes have been saved and the current session closed due to inactivity. Please refresh the page to continue.',
    fileTypeError: 'This file type is not supported.',
    errorSelectingFileType: 'Please select file format, such as \n{0}',
    failToUpload: 'Upload failed, please try again.',
    alreadyExistPageAction:
      'This trigger is already connected to one Page interaction or external link. Are you sure you want to replace?',
    alreadyExistFragmentAction:
      'This component already has an Artboard interaction with the same trigger. Do you want to replace it?',
    imageSizeExceed: 'Sorry, please upload images within 10M',
    imageDimensionExceed: 'Sorry, please select image within 10000 x 10000 px.',
    videoSizeExceed: 'Sorry, please upload videos within 10M',
    moreFileUploading: 'Importing {0}/{1} files, please wait...',
    moreFileUploadFaiFile: isPrivateDeployment
      ? "Sorry, {0} file(s) can't be imported. Please select files less than 10 MB and try again."
      : "Sorry, {0} image(s) can't be imported. Please select images less than 10 MB and try again.",
    moreFileUploadFailFormat: isPrivateDeployment
      ? "Sorry, {0} file(s) can't be imported. Please try again with the following file formats: {1}."
      : "Sorry, {0} image(s) can't be imported. Please try again with the following formats: {1}.",
    moreFileUploadFailSize: isPrivateDeployment
      ? "Sorry, {0} file(s) can't be imported. Please try with image dimensions less than 10,000 × 10,000 px."
      : "Sorry, {0} image(s) can't be imported. Please try with image dimensions less than 10,000 × 10,000 px.",
    moreFileUploadFailAll: isPrivateDeployment
      ? "Sorry, {0} file(s) can't be imported. Please select files less than 10 MB in format {1}."
      : "Sorry, {0} image(s) can't be imported. Please select images less than 10 MB and in ({1}) format.",

    imageUploading: 'Importing <span>{0}/{1}</span>, please wait ...',

    uploadMaxCount: isPrivateDeployment
      ? 'You can only import max 50 images or videos each time.'
      : 'A maximum of 50 image files can be imported at a time.',
    uploadNetworkError: 'Network error. Please try again.', // 这个内容在客户端登录处有重复的
    offlineEditing: 'Editing offline...',
    offlineSaving: 'Saving data locally...',
    saving: 'Saving...',
    saveSuccessfully: 'Saved successfully.',
    failToSave: 'Save failed.',
    unableToCreateNode: 'Sorry, the network is disconnected. You cannot add pages or groups.',
    unableToMoveNode: 'Sorry, the network is disconnected. You cannot move pages or groups.',
    unableToGroupNode: 'Sorry, internet is disconnected, unable to group pages',
    unableToUnGroupNode: 'Sorry, internet is disconnected, unable to ungroup pages',
    unableToRemoveNode: 'Sorry, the network is disconnected. You cannot delete pages or groups.',
    unableToEditNode: 'Sorry, the network is disconnected. You cannot edit pages or groups.',
    unableToCopyNode: 'Sorry, network disconnected. Please try to create a page after your connection is restored.',
    unableToUpload: 'Sorry, network disconnected. Please try again after connection is restored.',
    unableToHideNode: 'Sorry, network interrupted. Hidden pages disabled.',
    networkReconnectionSuccessful: 'Network reconnected.',
    saveOfflineData: 'Syncing offline data...',
    manualSaveOfflineData: 'Saving data, Please wait...',
    failedToJoinProjectCollaboration: 'Join collaboration failed.',
    multiplayerCollaborativeSuccess: 'Collaboration initialized.',
    mayCauseCompsToBeDeleted: 'This operation may cause components deleted. Are you sure to continue?',
    selectGroupTarget: 'If you need to use the group for interactions, you can do it like this:',
    makeSureToMergeCells: 'Merging cells only keeps the upper-left value. Are you sure you want to continue?',
    trashClearAlert: 'This action cannot be undone. Are you sure you want to clear?',
    trashPageAlert: 'Please restore the deleted page first if you want to edit it.',
    trashRecoverAlert: 'Are you sure you want to restore all pages? This cannot be undone.',
    headCannotBeMoved: '', // 未翻译
    cannotMoveToHeader: '', // 未翻译
    cannotMoveRow1:
      "Sorry, you can't move rows which contain only part of a merged cell. Please unmerge the cell and try again.",
    cannotMoveRow2: "Sorry, you can't move rows to a merged cell. Please unmerge the cell and try again.",
    cannotMoveColumn1:
      "Sorry, you can't move columns which contain only part of a merged cell. Please unmerge the cell and try again.",
    cannotMoveColumn2: "Sorry, you can't move columns to a merged cell. Please unmerge the cell and try again.",
    duplicateName: 'This category or data name already exists.',
    offlineAlert: 'Sorry, network interrupted. Some features are currently disabled. Please check your connection.',
    socketOfflineAlert: 'Network error. Some features are disabled. \nPlease save changes manually',
    networkOfflineAlert:
      'Network disconnected. Some features are disabled. Please check your connection to be back online.',
    offlineMsg3: 'Page not loaded yet. \nPlease try again after your connection is restored.',
    imageCropFail: 'Failed to crop, please try again.',
    packageLoading: 'Preparing the file, please wait...',
    successMessage: 'HTML prototype prepared successfully!',
    downloadPreviewDemoZIP: 'Failed to download HTML prototype. Please try again later.',
    pasteInteractionFail:
      'Part of your copied Triggers have been removed because they cannot work with this component.',
    redundantPage: 'A Trigger can only have one page interaction. Extra ones have been removed here.',
    redundantFragment: 'A Trigger can only have one Artboard interaction. Extra ones have been removed here.',
    redundantPageAndFragment:
      'You can only have one Page interaction and one Artboard interaction for one trigger. Extra ones have been removed here.',
    audioSizeExceed: 'Please upload an .mp3 file smaller than 10 MB.', // 音频组件上传
    unloadDialogMessage: 'Some of your changes have not been saved. Are you sure you want to continue?',
    whoEditingSymbol: 'Oops, {0} is editing this component asset. Please try again later.',
    denyNestSymbol: "Sorry, it is not supported to add other assets here. What's added has been detached from asset.",
    //多人编辑
    multiPersonEditingAlert: 'This component has been selected by {0}. You may edit it once it has been deselected.',
    //复制粘贴内容画板
    contentPanelEditorPasteContentPanelComp: '#抱歉，内容面板中不支持粘贴其他内容面板',
    symbolEditorPasteContentPanelComp: '#抱歉，引用组件中不支持添加动态面板',
    contentPanelEditorHandleSymbol: '#内容面板中暂不支持编辑引用组件资源，请退出内容面板后再试',
    contentPanelUseSymbolContentPanelComp: '#抱歉，动态面板中不支持添加内容面板',
    contentPanelResolveFilterContentPanelComp: '#动态面板中不支持添加内容面板，已自动过滤相关组件',
    contentPanelDeleteAll: '#不可删除所有内容，请至少保留1个',
    denyOpenExternalLink: '#抱歉，当前环境中不支持打开外部链接。',
  },
  // 提示信息
  tips: {
    clickCompnentToSwitchState: '', // 未审核
    interactionPrompt1: 'Select a component or artboard and click "Add interaction"',
    interactionPrompt2: 'Or drag the Link Point of the component to any target component or page.',
    interactionPrompt2DT: 'Or click and drag the red dot of the component to a page or artboard',
    contentPanelTips:
      'Tips: \nDrag the link point of this panel to a sub-artboard to create a connection. \nConnect to as many sub-artboards as you need. \nOne sub-artboard can be connected only once.',
    contentPanelV2TipsTitle: '#双击开始编辑内容面板',
    contentPanelV2TipsContent0: '#将动态面板选为交互目标，可使其中的内容',
    contentPanelV2TipsContent1: '#进行切换展示',
    contentPanelV2TipsGuide: '#查看教程',
    contentPanelValueEditorTips: 'No sub-artboards linked with this panel yet.',
    doubleClickComponent: 'Double click to edit',
    inputSiteName: 'Name',
    inputUrl: 'URL',
    inputMaxLength: 'The name cannot exceed {0} characters.',
    revertLinear: 'Reverse',
    revertRadial: 'Reverse',
    rotationAngle: 'Adjust angle',
    pleaseInput: 'Please enter {0}',
    copySuccess: 'Copied successfully',
    clickSelectTarget: 'Select target',
    selectTarget: 'Select trigger',
    selectCommand: 'Select command',
    selectContent: 'Select content',
    selectState: 'Select state',
    revert: 'Restore',
    changeProjectTypePrompt1: 'The project type will be changed into {0}',
    changeProjectTypePrompt2: `The new project size will be applied to new artboards. The existing artboards won't be affectted. Are you sure you want to continue?`,
    zoomOutline: 'Scale layers',
    resizeTo: 'Resize to',
    scaleFromTopLeft: 'Scale from top left',
    scaleFromTopRight: 'Scale from top right',
    scaleFromBottomRight: 'Scale from bottom right',
    scaleFromBottomLeft: 'Scale from bottom left',
    scaleFromTop: 'Scale from top',
    scaleFromRight: 'Scale from right',
    scaleFromBottom: 'Scale from bottom',
    scaleFromLeft: 'Scale from left',
    scaleFromCenter: 'Scale from center',
    appSizeChangedByOther:
      'The project type has been changed by another member. All related settings have been auto synced',
    artboardPanelTip: 'Drag Link Point to an artboard here to add an interaction.',
    noSearchResultsWereFound: 'No results found',
    pageAddOver: "You've reached the max limit of {0} pages. Please upgrade to create more pages.",
    personalSpacePageAddOver: "You've reached the max limit of {0} pages. Please upgrade to create more pages.",
    shareSetting:
      'This preview link will be different when you change the settings below. Please copy the new link to share then.',
    notSearchResult: 'No results found',
    noPage: 'No pages yet',
    noResourceResult: 'No assets yet',
    resourceExpired:
      'The source of this asset instance has been deleted or converted to another type. Are you sure you want to detach it from the asset to continue editing?',
    tutorial: 'Tutorials',
    officialWebsite: 'Our website',
    activity: {
      freePrograms: '', // 免费计划相关不翻译
      partInFreePrograms: '', // 免费计划相关不翻译
    },
    design: 'Collaboration and handoff',
    nonPrivateProject:
      'This is a Team project that is accessible to all team members.\nPlease change it to a Private project and set the project members.',
    cannotUseOfflineDome:
      "Oops! It seems you're using the basic free plan. Upgrade your account to download the HTML prototype and more.",
    remoteLoginTips:
      "You are currently logged in on another device or browser.\nIf that wasn't you, go to your profile page to change the password.",
    reLogin: 'Log in anyway',
    loginExpired: '', //----------------------------
    logoutTip: 'The current account has been logged out. Please first log in and try again. ',
    // 音频组件与视频组件
    doubleClickUploadVideo: isPrivateDeployment
      ? 'Double click to upload video'
      : 'Double click to add video source link',
    doubleClickUploadAudio: isPrivateDeployment
      ? 'Double click here to upload audio'
      : 'Double click to add audio source link', // 音频组件
    audioUrlPlaceholder: 'Type in audio source link',
    videoUrlPlaceholder: 'Type in video source link',
    invalidAudioUrl: 'Unable to fetch audio data. Check the link',
    invalidVideoUrl: 'Unable to fetch video data. Check the link',
    // 查看教程
    audioEditHelpUrl: 'https://help.mockplus.com/p/416#19',
    videoEditHelpUrl: 'https://help.mockplus.com/p/416#18',
    // 引导
    audioVideoEditTutorialUrl: 'https://help.mockplus.com/p/416#18',
    audioVideoGuideText: 'Video and Audio components are now adjusted to be added via online links.',
    videoEditPlayText: 'Please play the video from preview screen',

    chromeTips:
      'For the best experience, please use <a target="_blank" href="https://www.google.com/chrome/">Google Chrome browser</a>.',
    loseProjectPermission: "Oops, your editing permission has been changed. You can't continue editing it.",
    weMeetNotSupportExport: '暂不支持在腾讯会议中进行导出，请在浏览器中进行操作。',
    iconReplaceTip:
      'To swap icons, try to select an icon in the component, and click the icon you want in icon libraries. ',
    useEscExit: 'Press Esc to exit the {0} mode',
    featureChangeTitle: 'Feature changed',
    publishChangeTip: '#支持发布至协作项目的不同原型稿，版本管理更方便。',
    appHasDeleteByCC: 'The Cloud project is deleted thus not available.',
    appHasDeleteByCreateApp: 'Publishing failed. The selected Cloud project has been deleted. ',
    appArchivedLimit: 'The selected Cloud project has been archived. Please unarchive it and try again. ',
    hideAllPageTip:
      'This project has no pages or all pages are hidden. At least one unhidden page is required before you can perform this action.',
    svgParsing: 'Converting the image(s), please wait...',
    svgParseSuc: 'Converted successfully',
    svgParseErr: 'Failed to convert the image(s',
  },
  placeholders: {
    renameHolder: 'Page name',
    textInputHolder: 'Type something',
    selectHolder: 'Select',
  },
  lark: {
    larkAppID: 'cli_9ecaf77e03aed105',
    previewPrompt: 'You are invited to preview Mockplus project. Click the link below to preview it now.',
    openInLark: 'View in Lark: ',
    openInBrowser: 'View in browser: ',
  },
  extend: {
    chrome: {
      noExtend: {
        colorExtractor: 'Eyedropper tool only works on Chrome with plugin required.',
      },
      tutorialURL: isPrivateDeployment
        ? `${window.location.origin}${window.apis.helpDomain}/p/424#1`
        : 'https://help.mockplus.com/p/424#1',
      downloadUrl: 'https://mockplus-static.s3-us-west-1.amazonaws.com/idoc/plugins/mockplus_for_chrome_v1.0.3.zip',
    },
    download: 'Download plugin',
    tutorial: 'View tutorial',
    version: 'Plugin version too low. Please download the latest version. ',
  },
  login: {
    title: 'Sign in to Mockplus',
    accountPlaceHolder: 'Email',
    passwordPlaceHolder: 'Password',
    noAccount: "Don't have an account?\u00a0",
    registerNow: 'Sign up',
    forgetPassword: 'Forgot password?',
    loginBtn: 'Sign in',
    emailValidate: 'Please enter a valid email address.',
    passwordValidate: 'The password must be between 6-20 characters long.',
  },
  register: {
    title: 'Create a Mockplus free account',
    passwordValidateAgin: 'Password does not match.',
    agreementURL: 'https://www.mockplus.com/tos',
    accountPlaceHolder: 'Email',
    passwordPlaceHolder: 'Password (6-20 characters)',
    passwordAginPlaceHolder: 'Confirm password',
    hadRead: 'By signing up, I agree to Mockplus\u00a0',
    agreement: 'Terms of Service.',
    register: 'Sign up',
    hadAccount: 'Already have an account?\u00a0',
    loginNow: 'Sign in',
    readValidate: 'Please read and accept the User Agreement.',
  },
  example: {
    label: 'Templates',
    tip: {
      partOne: 'You are viewing the sample project in Mockplus RP,',
      partTwo: ' or ',
      partThree: 'to save the project in current team.',
      partFour: 'click to save',
      partFive: 'the project in current team.',
    },
    saving: 'Saving...',
    savingError: 'Failed to save data, please try again later',
    type: {
      all: 'All',
    },
    nocontent: "Nothing's here",
    guideEntryDescription: 'Free templates',
    noPermission: "Sorry, as a Collaborator, you don't have permission to perform this action.",
    noTeams: 'You have not joined any team yet. Please contact your Admin or change your account.',
    appQuantityExceedsLimit: 'Max project limit reached. Please contact your Admin to process this.',
    saveExampleTitle: 'Save the template',
    pleaseCreateTeam: 'Create a team to continue',
    placeHolderTeam: 'Team name',
    saveExampleTips:
      "You've reached the max limit of ({0}) pages in this workspace. Please upgrade or switch to another workspace and try again.",
    upgrade: 'Upgrade now',
  },
  selectionGroup: {
    selectionGroupSetting: 'Selection group settings',
    rowCount: 'Row',
    columnCount: 'Column',
    rowGap: 'Row gap',
    columnGap: 'Column gap',
    iconEditButton: 'Click to select an icon from the library',
    imageEditButton: 'Click to upload an image',
  },
  // 定制功能
  customization: {
    huawei: {
      standard: {
        tips: 'Design systems',
        label: 'UX design systems',
        management: 'Management-side',
        officialWebsite: 'Official website',
        mobile: 'Mobile',
        operationFlow: 'Business flows',
      },
      libraries: {
        icon: 'Icon assets',
        image: 'Image assets',
      },
      guide: 'User guide',
      tutorial: 'Tutorials',
    },
  },
  share: {
    label: 'Share',
    shareApp: 'Share the project',
    privateShare: 'Private share',
    publicShare: 'Public share',
    resetLink: 'Reset',
    availableResetLink: '#如需重置链接，请先取消密码',
    privateShareTips: 'Share only to project members. This link grants to co-designing, reviews and specs.',
    publicShareTips: 'Share with everyone. With preview and presentation view access only.',
    publicShareTipsInPrivateVersion:
      "Share with everyone who's logged in. With preview and presentation view access only.",
    copyLink: 'Copy',
    copySuccess: 'Copied',
    inviteTips: 'Invite anyone via this link in 7 days. After that, it will work as a share link only.',
    inviteToApp: 'Also invite your partner to become a project member ',
    notCanInviteToApp: 'Only the project member can view this project.',
    onlyShareCurrentModel: 'Share current section only',
    iframeShare: 'Embed project',
    iframeShareTips: 'Embed this project to a third party application via this link. The link is accessible to all.',
    shareModule: {
      prototypeModule: 'Prototype',
      designModule: 'Design',
      rpModule: 'RP',
    },
    shareContent: {
      label: 'Content',
      idocContent: 'Storyboard',
      previewContent: 'Preview',
    },
  },
  message: {
    label: 'Notifications and requests',
    teamMessage: 'Projects',
    aboutMe: 'Mentions',
    apply: 'Requests',
    markRead: 'Mark as read',
    oneClickRead: 'Mark all as read',
    linkToAll: 'Read all',
    hadRead: 'Read',
    applyToViewApp: 'requested to access the project "',
    agree: 'Approve',
    disagree: 'Reject',
    agreed: 'Approved',
    disagreed: 'Rejected',
    remark: 'Note:',
    noContent: 'No new notification',
    memberPrivilege: 'edit permissions',
    guestPrivilege: 'comment/view permissions',
    observerPrivilege: 'preview permissions',
    joinTheTeamBy: 'Join the team as a {0}',
    approve: 'Approve request',
    upgradeTips:
      'This team has reached the maximum member limit. Please upgrade your team or allow new users to join as a Guest.',
    expansionTips:
      'This team has reached the maximum member limit. Please expand your team or allow new users to join as a Guest.',
    payTeamExpired:
      'Your Team plan expired. Please renew the plan to add more teammates. For now, you can still invite Guests to view your projects.',
    agreeAsObserver: 'Join as a Guest',
    observerTip: 'Guests can only preview projects',
    consumeDescription: 'Only ${0} per user/day',
    agreeToJoin: 'Approve',
    renewNow: 'Renew now',
    logOffTeamTip: 'Your account will be closed in {0} day(s) and {1} hour(s).',
    recallCancellation: 'Withdraw',
    recallSuccess: 'Withdrawn',
    createAppLimitByFree: '',
  },
  // 没有引文版
  contact: {
    leftTop: '',
    name: '',
    addMe: '',
    role: '',
    bottom: '',
  },
  crashError: {
    title: 'Sorry, an error has occurred',
    label: 'Please try to refresh. If the problem persists, please ',
    labelNoReset: ' If the problem persists, please ',
  },
  file: {
    importMrpLabel: 'Import RP file',
    importMrpTitle: 'Importing, please wait...',
    importError: 'Sorry, failed to import the file. Please ',
    importErrorEnd: ' for help.',
    importSuccess: 'File imported.',
    importMrpFileError: 'Invalid file format. Please select a valid RP file format (.mrp) and try again.',
    importNetworkError: 'Network error. Import failed. Please check your network connection and try again.',
    exportMrpLabel: 'Export RP file',
    exportMrpTitle: 'The file is being prepared for exporting. It may take a few minutes.',
    exportError: 'Sorry, failed to export the file. Please try again later.',
    exportMrpSuccess: 'RP file packed, please click download.',
    exportMrpRoleLimit:
      "Sorry, you don't have access to this. Please contact your Super Admin or Admin to perform this instead.",
    upgradeToExportMrp: "Oops! It seems you're using the free plan. Upgrade your account to export RP file and more.",
    enUpgradeToExportMrp: "Sorry, Free plan users can't access this feature. Please upgrade to unlock it. ",
    enUpgradeToDownloadDemo: "Sorry, Free plan users can't access this feature. Please upgrade to unlock it. ",
    selected: 'selected',
    selectProjectPage: 'Select pages and folders:',
  },
  support: {
    label: 'Contact us',
    url: 'https://www.mockplus.com/support',
  },
  indexedDBError: {
    tip: 'An error detected when saving data to the local storage. The data may not be saved properly.',
    howToResolve: 'How to fix',
    url:
      isPrivateDeployment && !isHuawei
        ? `${window.location.origin}${window.apis.helpDomain}/p/641`
        : 'https://help.mockplus.com/p/602',
  },
  manualSave: {
    saveVersion: 'Save current page version',
    save: 'Save',
    saving: 'Saving...',
    saveSuccessfully: 'Saved successfully',
    savingError: 'Failed to save. Please try again',
    offlineEditing: 'Offline',
  },
  newFeature: {
    link: 'https://www.mockplus.com/newfeatures/post/new-multipurpose-sketch-plugin-and-more-features',
    // detailsLink: 'https://help.mockplus.cn/p/629',
    detailsLink: 'https://help.mockplus.com/p/581',
    title: "What's new",
    content0: '#动态面板支持多层嵌套、拖拽调整内容顺序、设置内容列数等。',
    content1: '#新增折叠面板组件。',
    content2: '#便签条终点支持吸附在图层上。',
    content3: '#修复开启“滚动时保持固定”时，在移动端演示时缩放异常的问题。',
    content4: '#新增组件库：HarmonyOS、Taro UI及Semidesign。',
  },
  // RP客户端登录
  clientLogin: {
    comfirm: 'OK',
    cancel: 'Cancel',
    loginIndex: {
      title: 'Mockplus RP',
      subTitle: 'Design easier and faster',
      openLogin: 'Log in with browser',
      register: 'Sign up',
    },
    loginWait: {
      title: 'Please log in via your web browser.',
      subTitle: 'Already logged in? Please',
      click: 'click here',
    },
    notLogin: {
      title: "You're not logged in yet",
      subTitle: 'Please go to your browser to complete the login, and',
      retry: 'try again',
    },
    loginFail: {
      title: 'Sorry, login failed',
      retry: 'try again',
    },
    loginComplete: {
      title: 'Login success',
    },
    tips: {
      loginFail: 'No successful login detected, please try again later.',
    },
    guest: {
      cannotEdit: '协同人或访客无编辑权限',
      prev: 'Back',
    },
  },
  guestCanNotCreateApp:
    "As a Collaborator or Guest, you can't edit anything here. Please contact your Admin to change your role or directly switch to another team.",
  permission: {
    rpEditor: 'RP editor',
    rpViewer: 'RP viewer',
    permissionLabel: 'Permission',
    perpetualTip: '当前为终身特权团队，不支持添加更多协作成员。', // 中文版才有这个团队类型
  },

  personalSpace: {
    moveProject: 'Move now',
    memberTips:
      'Sorry, projects in your personal space are only available to you. To collaborate on it, you need to first move it to your team space and invite more teammates.',
    publishDialogTitle: "Oops! You can't publish a project in your personal space",
    publishDialogDescribe: 'Move the project to your team space and publish it again to enjoy a seamless collaboration',
    publishDialogItem1P1: 'Storyboard view',
    publishDialogItem1P2: 'View all pages in one screen and create screen flows',
    publishDialogItem2P1: 'Review and feedback',
    publishDialogItem2P2: 'Review and discuss design details better together',
    publishDialogItem3P1: 'Easier handoff',
    publishDialogItem3P2: 'Inspect layer data and deliver front-end codes via a link',
    publishDialogItem4P1: 'Many more',
    publishDialogItem4P2: 'Manage third-party designs, tasks and PRDs in a breeze',
    axureTips:
      "Projects in your personal space can't be linked to any Axure file. Move them to your team space and try again.",
    moveProjectBtn: 'Move now',
    all: 'All',
    sureMove: 'Are you sure you want to move this project folder to the project folder "{1}" of {0}?',
    moveProjectSubTitle: 'Select a target location',
    moveProjectSelectorTitle: 'Target team',
    moveProjectAppSetsTitle: 'Project folder',
    search: 'Search',
    noResult: 'No results found.',
    moveLimit:
      'Sorry, you can only move projects to a team where you have a Member or higher access level. Please create or join a team with that access level and try again.',
    fullAppLimit: 'The target team has reached the max project limit. Please upgrade or renew that team and try again.',
    moveSuccessful: 'Moved successfully',
    createAppLimit: "You've reached the max limit of {0} projects. Please upgrade to create more.",
    createAppPersonalSpaceLimit: "You've reached the max limit of {0} projects. Please upgrade to create more.",
    createAppUpperLimit: 'You have reached the max limit of {1} {0}. Please contact {2} to add more.',
    createJointAppUpperLimit: 'You have reached the max limit of {1} All files. Please upgrade the team to add more.',
  },
  limit: {
    createAppUpperLimit: 'You have reached the max limit of {0} RP projects. Please contact {1} to add more.',
    privatePageTip: 'You have reached the max limit of {0} pages. Please contact {1} to add more.',
  },
  upgrade: {
    alreadyUpgradeConfirm: "If you've completed the upgrade, please click the button below to verify.",
    success: 'Upgraded successfully! Thank you',
    failButHasPayTeam:
      'Sorry, it seems like this workspace hasn\'t been upgraded yet. You can easily move your project to your team workspace "{0}", which has already been upgraded, and start enjoying all the premium features there.',
    failButHasPersonalTeam:
      "Sorry, it seems like this workspace hasn't been upgraded yet. You can easily move your project to your Personal Space, which has already been upgraded, and start enjoying all the premium features there.",
    fail:
      "Sorry, it seems like this workspace hasn't been upgraded yet. Please feel free to contact us if you have any questions about this.",
    startUse: 'Get started',
    alreadyUpgrade: "I've upgraded",
    howToMove: 'How to move',
    moveHelpUrl: 'https://help.mockplus.com/p/409#4',
  },
  designProject: {
    type: 'Type',
    administrator: 'your Admin',
    sampleProject: 'RP projects',
    mockProject: 'Mockplus files',
    allProject: 'All files',
    joinProject: 'Cloud projects',
    rpProjectDesc: 'Import RP files only',
    rpDTProjectDesc: 'Import RP/DT files only',
    allProjectDesc: 'Import Sketch/Figma/XD/PS/Axure/RP/DT files',
    allProjectNoDTDesc: 'Import Sketch/Figma/XD/PS/Axure/RP files',
  },
  clipboard: {
    // noSurpport: '您当前浏览器不支持使用系统剪切板~', // 暂不用
    safeCopyText: 'Your RP project is copied. Please paste it to any editing place you want to continue. ',
    // denyRead: '不允许读取系统剪切板数据', // 暂不用
    // denyWrite: '没有开启剪切版写入权限', // 暂不用
    // denyCopyData : '不被允许写入的数据类型', // 暂不用
    // denyPasteMode: '不支持右键粘贴' // 暂不用
  },
  autoFill: {
    title: '#Auto fill',
    type: {
      text: '#Text',
      img: '#Image',
    },
    text: {
      people: {
        name: '#人名',
        male: '#Male',
        female: '#Female',
        both: '#Both',
      },
      phone: {
        name: '#电话',
        mobile: '#手机',
        tel: '#固话',
      },
      number: {
        name: '#数字',
        price: '#金额',
      },
      email: '#Email',
      time: {
        name: '#Time',
        hm: '#时分',
      },
      date: {
        name: '#Date',
        ymd: '年月日',
        ym: '年月',
        week: '#星期',
      },
      location: {
        name: '#位置',
        country: '#Country',
        city: '#City',
        nativePlace: '#街道门牌号',
      },
      content: '#Content',
      serial: {
        name: '#序号',
        asc: '#Asc',
        desc: '#Desc',
        random: '#Random',
        serial: '#Serial',
      },
    },
    image: {
      people: '#People',
      office: '#Office',
      animal: '#Animal',
      science: '#Technology',
      scenery: '#Scenery',
      food: '#Food',
      edifice: '#Edifice',
      misc: '#Misc',
    },
    info: '#本功能由摹客RP团队提供',
  },
  tip618: '618钜惠，升级低至6折，终身版立减￥300！',
};

export default en_us;
