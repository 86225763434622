import * as React from 'react';
import classnames from 'classnames';

import './index.scss';

export interface ICarouselItem {
  img: string;
  text?: string;
  [key: string]: string | number | boolean | undefined;
}

interface IProps {
  data: ICarouselItem;
  index: number;
  changed: boolean;
  width: number;
  height: number;
  className?: string;
  onClick?: (data: ICarouselItem, index: number) => void;
}

interface IState {}

class CarouselItem extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  private handleClick = () => {
    const { data, index, onClick } = this.props;
    onClick && onClick(data, index);
  };

  render() {
    const { className, data, width, height, changed } = this.props;
    const { text, img } = data;
    return (
      <div
        className={classnames(className, 'dsm-c-rp-carousel-item', { changed })}
        style={{
          width,
          height,
        }}
        onClick={this.handleClick}
      >
        <img src={img} className="dsm-c-rp-carousel-item-img" alt="carouse item" />
        <p className="dsm-c-rp-carousel-item-text">{text}</p>
      </div>
    );
  }
}

export default CarouselItem;
