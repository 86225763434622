import * as _ from 'lodash';

import * as BoundsUtils from '@utils/boundsUtils';

import { Orientation } from '@/fbs/common/models/common';

import { IBounds, IPosition, ISize } from '@fbs/common/models/common';
import IArtboard, { IArtboardBackground } from '@fbs/rp/models/artboard';
import { IGuides } from '@fbs/rp/models/guides';
import { IBasicLayout } from '@fbs/rp/models/layout';
import { FillType } from '@fbs/rp/models/properties/fill';
import { ArtboardPatches, Ops } from '@fbs/rp/utils/patch';
import { IRemark } from '@fbs/rp/models/component';

import { getOrientationFromSize } from '@/helpers/artboardDimensionHelper';
import { coverPatches } from '@helpers/patchHelper';
import { collectComponentsLayout } from '@helpers/responseLayoutHelper';

import { makeUIComponent } from '@editor/comps/factory';
import { ComponentChangeType, getNewPositionWhenCenter, getOffsetBySizeChange } from '@editor/comps/resizeHelper';
import { IUICompConstructOptions } from '@/customTypes';

import Doc from '../document';
import { UIComponent, UIPanelComponent } from './';

export default class UIFragment extends UIPanelComponent {
  public readonly ownerContentPanelID?: string;
  public readonly artboardID: string;
  public isMain: boolean = false;
  public readonly isArtboard: boolean = true;

  constructor(data: IArtboard, public doc: Doc, public options?: IUICompConstructOptions) {
    super(data, undefined, options);
    this.artboardID = data._id;
    this.ownerContentPanelID = data.ownerID;
    this.dynamicInfo = {};
  }

  get position(): IPosition {
    return this.dynamicInfo.position || this.data.position;
  }

  get size(): ISize {
    return this.dynamicInfo.size || this.data.size;
  }

  get $data(): IArtboard {
    return this.data as IArtboard;
  }

  // 获取画板的背景色，默认启用，为白色纯色背景
  get background(): IArtboardBackground {
    if (!this.$data.background || _.isEmpty(this.$data.background)) {
      return {
        disabled: false,
        type: FillType.solid,
        color: { r: 255, g: 255, b: 255, a: 1 },
      };
    }
    return this.$data.background;
  }

  get document(): Doc | null {
    return this.doc;
  }

  /**
   * orientation与size分开管理，
   * 默认值， 通过 size 派生来
   */
  get orientation(): Orientation {
    // return getOrientationFromSize(this.size);
    return this.$data.orientation || getOrientationFromSize(this.size);
  }

  getViewBoundsInParent(): IBounds {
    const { width, height } = this.size;
    return {
      left: 0,
      top: 0,
      right: width,
      bottom: height,
      width,
      height,
    };
  }

  // 注释功能
  getRemarkPatch(value?: IRemark): ArtboardPatches {
    const path = '/remark';
    return {
      do: { self: [Ops.replace(path, value || '')] },
      undo: { self: [Ops.replace(path, this.remark)] },
    };
  }

  updateSize = (
    oldSize: { width: number; height: number },
    newSize: { width: number; height: number },
    backupLayoutMap?: WeakMap<UIComponent, IBasicLayout>,
  ) => {
    //更新组件的响应式布局，只往下递归
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };
    const realOffset = getOffsetBySizeChange(oldSize, newSize);
    const layoutMap =
      backupLayoutMap ||
      collectComponentsLayout(this.componentsExceptConnector, BoundsUtils.createBoundsBySize(oldSize));

    const childrenResizeOptions = {
      container: {
        before: {
          position: {
            x: 0,
            y: 0,
          },
          size: this.size,
        },
        after: {
          position: {
            x: 0,
            y: 0,
          },
          size: newSize,
        },
        isResponsive: true,
      },
      shift: false,
      scale: {
        h: newSize.width / this.size.width,
        v: newSize.height / this.size.height,
      },
    };
    if (this.components.length > 0) {
      const newComponentsInfo = this.components
        .filter((comp) => !comp.isConnector)
        .map((comp) => {
          const newCompInfo = comp.resizeHandler2(realOffset, layoutMap.get(comp)!, childrenResizeOptions);
          if (newCompInfo.patches) {
            coverPatches(patches, newCompInfo.patches);
          }
          let newPosition = {
            x: newCompInfo.position.x,
            y: newCompInfo.position.y,
          };
          // 如果组件是居中的话 要重置
          newPosition = getNewPositionWhenCenter(comp, newPosition, newCompInfo.size, newSize);
          return {
            id: comp.id,
            type: ComponentChangeType.Edit,
            position: newPosition,
            size: newCompInfo.size,
            rotate: newCompInfo.rotate,
          };
        });
      const res = this.getPositionPatchesOfChildrenChanged(newComponentsInfo);
      if (res.patches) {
        coverPatches(patches, res.patches);
      }
    }
    return patches;
    // const abv = super.resizeHandler2()
  };

  forceRefreshComponents() {
    this.components = this.data.components!.map((comp) => {
      // comp.v += 1;
      return makeUIComponent(comp, this, this.options);
    });
  }

  get guides() {
    const data = this.toJSON() as IArtboard;
    return data.guides;
  }

  get horizontalGuides(): IGuides[] {
    return this.guides?.horizontal || [];
  }

  get verticalGuides(): IGuides[] {
    return this.guides?.vertical || [];
  }

  tempPosition?: IPosition;
}
