export enum EventTypes {
  click = 'click',
  doubleClick = 'doubleClick',
  contextMenu = 'contextMenu',
  mouseDown = 'mouseDown',
  // longPress = 'longPress',
  mouseUp = 'mouseUp',
  mouseEnter = 'mouseEnter',
  mouseLeave = 'mouseLeave',
  // mouseMove = 'mouseMove',
  swipeLeft = 'swipeLeft',
  swipeRight = 'swipeRight',
  swipeUp = 'swipeUp',
  swipeDown = 'swipeDown',
  loaded = 'loaded',

  afterMove = 'afterMove',
  afterResize = 'afterResize',
  afterRotate = 'afterRotate',
  afterZoom = 'afterZoom',
  afterVisible = 'afterVisible',
  afterHide = 'afterHide',

  checked = 'checked',
  unChecked = 'unChecked',
  toggleCheck = 'toggleCheck',
  focus = 'focus',
  blur = 'blur',
  // change = 'change',
  scroll = 'scroll',
}

export enum CommandTypes {
  move = 'move',
  resize = 'resize',
  rotation = 'rotation',
  zoom = 'scale',
  toggleVisible = 'toggleVisible',
  toggleState = 'toggleState',
  switchContent = 'switchContent',
  scroll = 'scroll',
}

export enum ArtboardCommand {
  showArtboard = 'showArtboard',
  closeArtboard = 'closeArtboard',
}

export enum AnimateEffects {
  none = 'none',
  linear = 'linear',
  easeIn = 'ease-in',
  easeOut = 'ease-out',
  easeInOut = 'ease-in-out',
}

export enum PageSkipEffects {
  none = 'none',
  pushToLeft = 'pushToLeft',
  pushToRight = 'pushToRight',
  pushToTop = 'pushToTop',
  pushToBottom = 'pushToBottom',
  slideToLeft = 'slideToLeft',
  slideToRight = 'slideToRight',
  slideToTop = 'slideToTop',
  slideToBottom = 'slideToBottom',
  fadeIn = 'fadeIn',
  zoomIn = 'zoomIn',
  flipInLeft = 'flipInLeft',
  flipInRight = 'flipInRight',
  flipInTop = 'flipInTop',
  flipInBottom = 'flipInBottom',
}
