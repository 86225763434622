import * as React from 'react';
import classnames from 'classnames';

import { UITheme } from '../../models';
import i18n from '../../i18n';

import Icon from '../Icon/index';
import Menu from '../Menu/index';
import Tooltip from '../Tooltip';

import './index.scss';

export interface ISelectProp {
  theme?: UITheme;
  isShow?: boolean;
  editable?: boolean;
  disabled?: boolean;
  options?: Array<string | number>;
  value?: string | number;
  unit?: string;
  tooltip?: string;
  placeholder?: string;
  type?: string;
  autoHeightMax?: number;
  isNeedBorder?: boolean;
  // 是否开启搜索功能，根据关键字搜索options
  isSearch?: boolean;
  // 回车搜索
  isSearchOnSubmit?: boolean;
  emptySearchText?: string;
  showSelectAll?: boolean;
  onSelect(item: string | number, index: number): void;

  onBlur?(value: string | number): void;

  onFocus?(): void;

  onSearch?(searchKey: string): void;

  closePanel?: Function;
  openPanel?: Function;
  width?: string | number;
  menuWidth?: string | number;
  maxValue?: number;
  minValue?: number;
  direction?: 'up' | 'down';
  position?: 'left' | 'right';
  fullBorder?: boolean;
  titlePrefix?: () => React.ReactChild;
  selectedIcon?: string;
}
interface ISelectState {
  isShow: boolean;
  value: string | number;
  selectIdx: number;
  ownOptions: Array<string | number>;
  isShowInput: boolean;
  inputFocus: boolean;
}

class Select extends React.Component<ISelectProp, ISelectState> {
  inputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  static defaultProps: Partial<ISelectProp> = {
    isSearch: false,
    isSearchOnSubmit: false,
    editable: false,
    tooltip: '',
    disabled: false,
    options: [],
    unit: '',
    value: '',
    placeholder: i18n('Select.placeholder'),
    width: 120,
    direction: 'down',
    isShow: false,
    type: 'text',
    position: 'left',
    showSelectAll: false,
    fullBorder: false,
    selectedIcon: '',
  };

  constructor(props: ISelectProp) {
    super(props);

    this.state = {
      isShow: false,
      value: props.value !== undefined ? props.value : 0,
      selectIdx: props.value !== undefined && props.options ? props.options.indexOf(props.value) : 0,
      ownOptions: this.initOwnOptions(props),
      isShowInput: false,
      inputFocus: false,
    };
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  initOwnOptions = (props: ISelectProp) => {
    const { showSelectAll, options } = props;
    if (showSelectAll) {
      options &&
        options.length > 0 &&
        options[0] !== i18n('TagButtonGroup.all') &&
        options.unshift(i18n('TagButtonGroup.all'), '-');
    }
    return options || [];
  };

  closePanel = () => {
    const { inputFocus } = this.state;
    this.setState({
      isShow: inputFocus,
      ownOptions: this.props.options || [],
    });
  };

  componentWillReceiveProps(nextProps: ISelectProp) {
    const { isShow, value, options } = this.props;

    if (nextProps.isShow !== isShow) {
      this.setState({
        isShow: nextProps.isShow || false,
        selectIdx: nextProps.value !== undefined && nextProps.options ? nextProps.options.indexOf(nextProps.value) : 0,
      });
    }
    if (nextProps.value !== value) {
      this.setState({
        value: nextProps.value !== undefined ? nextProps.value : 0,
        selectIdx: nextProps.value !== undefined && nextProps.options ? nextProps.options.indexOf(nextProps.value) : 0,
      });
    }
    if (nextProps.options !== options) {
      this.setState({
        ownOptions: this.initOwnOptions(nextProps),
      });
    }
  }

  doSwitchPopup = () => {
    const { closePanel, openPanel, value } = this.props;
    const { isShow, ownOptions } = this.state;
    this.setState({
      isShow: !isShow,
      selectIdx: value !== undefined && ownOptions ? ownOptions.indexOf(value) : 0,
    });
    if (isShow) {
      closePanel && closePanel();
    } else {
      openPanel && openPanel();
    }
  };

  onContainerClick = (e: React.MouseEvent<HTMLElement>) => {
    const { disabled, editable, isSearch } = this.props;
    if (editable || disabled || isSearch) {
      return;
    }
    this.doSwitchPopup();
    e.stopPropagation();
  };

  toggleShow = (e: React.MouseEvent<HTMLElement>) => {
    const { disabled } = this.props;
    const { isShow } = this.state;
    if (disabled) {
      return;
    }
    !isShow && this.inputRef.current?.focus();
    this.doSwitchPopup();
    e.stopPropagation();
  };

  onSelect = (index: number) => {
    const { value, onSelect, isSearch, options, showSelectAll } = this.props;
    const { ownOptions } = this.state;

    this.setState(
      {
        isShow: false,
        selectIdx: value !== undefined && ownOptions ? ownOptions.indexOf(value) : 0,
      },
      () => {
        if (ownOptions) {
          if (isSearch) {
            // 修正index
            const currIndex = options?.indexOf(ownOptions[index]) || 0;
            onSelect(ownOptions[currIndex], currIndex);
          } else {
            showSelectAll ? onSelect(ownOptions[index - 2], index - 2) : onSelect(ownOptions[index], index);
          }
        }
      },
    );
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { type, options, isSearch, isSearchOnSubmit, onSearch } = this.props;
    const { ownOptions } = this.state;
    let str = e.target.value;
    if (isSearch) {
      if (isSearchOnSubmit) {
        onSearch && onSearch(str);
        this.setState({ value: str });
        return;
      }
      const ownOptions = options?.filter((option) => typeof option === 'string' && option.indexOf(str) >= 0) || [];
      this.setState({
        ownOptions,
        value: str,
      });
    } else {
      if (type === 'int') {
        str = str.replace(/\D/g, '');
      }
      this.setState({
        value: str,
        selectIdx: ownOptions ? ownOptions.indexOf(str) : 0,
      });
    }
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { isSearch, isSearchOnSubmit } = this.props;
    e.stopPropagation();
    if (e.key === 'Enter' && isSearchOnSubmit) {
      this.onSubmit();
      return;
    }
    if (e.key !== 'Enter' || isSearch) {
      return;
    }
    let value = parseInt((e.target as HTMLInputElement).value, 10);

    // 如果有最大值和最小值的话就取区间的数
    const { maxValue, minValue } = this.props;
    if (maxValue && minValue) {
      value = Math.max(Math.min(value, maxValue), minValue);
    }

    this.setState({
      value: value,
    });
    this.props.onSelect(value, -1);
  };

  onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;
    this.setState({ inputFocus: true, isShow: true });
    onFocus && onFocus();
  };

  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value, 10) || 0;
    const { type, onBlur, options, maxValue, minValue, isSearch } = this.props;
    const { ownOptions } = this.state;
    if (isSearch) {
      // 失去焦点时重置数据
      this.setState({ value: this.props.value || '', isShowInput: false, inputFocus: false });
      onBlur && onBlur(this.props.value || '');
    } else {
      if (type === 'int') {
        if (maxValue !== undefined && minValue !== undefined) {
          value = Math.max(Math.min(value, maxValue), minValue);
        }
        this.setState({
          value: value,
          selectIdx: ownOptions ? ownOptions.indexOf(value) : 0,
          isShowInput: false,
          inputFocus: false,
        });
      }
      onBlur && onBlur(value);
    }
  };

  onSubmit = () => {
    const { onSearch } = this.props;
    const { value } = this.state;
    onSearch && onSearch(`${value}`);
  };

  onMouseHover = (index: number) => {
    this.setState({
      selectIdx: index,
    });
  };

  onClickValue = () => {
    const { editable, isSearch } = this.props;
    if (editable) {
      if (isSearch) {
        this.setState({ isShow: true, isShowInput: true });
      } else {
        this.setState({ isShowInput: true });
      }
    }
  };

  render() {
    const {
      disabled,
      menuWidth,
      tooltip,
      editable,
      unit,
      placeholder,
      width,
      direction,
      position,
      autoHeightMax,
      isNeedBorder,
      isSearch,
      emptySearchText,
      isSearchOnSubmit,
      theme,
      fullBorder,
      selectedIcon,
      titlePrefix,
    } = this.props;
    const { isShow, value, selectIdx, ownOptions, isShowInput, inputFocus } = this.state;

    const opts = ownOptions
      ? ownOptions.map((val: string | number, index: number) => ({
          id: index,
          text: typeof val === 'number' ? val.toString() : val,
          icon: selectedIcon && value === val ? selectedIcon : '',
          showIcon: !!selectedIcon,
        }))
      : [];
    return (
      <div
        style={editable && !isSearch ? { width: width } : { maxWidth: width }}
        className={classnames('dsm-c-select', {
          'disabled-select': disabled,
          'position-left': position === 'left',
          'position-right': position === 'right',
          'search-mode': isSearch,
          'search-mode-only-submit': isSearchOnSubmit,
          blue: theme === 'blue',
          'full-border': fullBorder,
        })}
        onClick={this.onContainerClick}
      >
        {editable ? (
          <input
            ref={this.inputRef}
            className={classnames('dsm-c-select-title input-title', {
              'disable-edit': !editable,
              'disable-unit': unit,
              'disabled-select-input': disabled,
              'input-focus': inputFocus,
              'submit-search': isSearchOnSubmit,
            })}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            disabled={disabled}
            draggable={disabled}
          />
        ) : (
          <Tooltip text={isShow ? '' : tooltip} theme="small">
            <span
              className={classnames('dsm-c-select-title', {
                'disable-edit': !editable,
                'disable-unit': unit,
                'disabled-select-value': disabled,
              })}
              onClick={this.onClickValue}
            >
              {titlePrefix && titlePrefix()}
              {value || 0}
            </span>
          </Tooltip>
        )}
        <div
          className={classnames('select-unit', {
            hide: !unit,
            'disabled-select-unit': disabled,
          })}
        >
          {unit}
        </div>
        <Icon cls="tag_downarrow" disabled={disabled} solid onClick={this.toggleShow} />
        {this.state.isShow && (
          <div
            className={classnames('dsm-c-select-menu', {
              'direction-up': direction === 'up',
              needBorder: isNeedBorder,
            })}
          >
            <Menu
              selectIdx={selectIdx}
              options={opts}
              disabled={!editable ? disabled : false}
              onSelect={this.onSelect}
              onMouseHover={this.onMouseHover}
              maxWidth={menuWidth}
              autoHeightMax={autoHeightMax}
              onClose={this.closePanel}
              emptyMenuText={emptySearchText}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Select;
