import * as React from 'react';
import { Link } from 'react-router-dom';

import Icon from '../Icon';
import Input from '../Input';

import './index.scss';

export type PanelProp = {
  title?: string;
  backLink?: string;
  titleEditable?: boolean;
  actions?: string[];
  onEditTitle?(value: string): void;
  onClickAction(index: number): void;
};

class Panel extends React.Component<PanelProp, { editTitle: boolean; title: string }> {
  static defaultProps: Partial<PanelProp> = {
    title: '',
    actions: [],
    titleEditable: false,
  };

  titleRef: React.RefObject<HTMLInputElement>;

  constructor(props: PanelProp) {
    super(props);
    this.state = {
      editTitle: false,
      title: props.title || '',
    };
    this.onStartEditTitle = this.onStartEditTitle.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onSubmitNewTitle = this.onSubmitNewTitle.bind(this);
    this.titleRef = React.createRef();
  }

  onStartEditTitle() {
    this.setState(
      {
        editTitle: true,
      },
      () => {
        this.titleRef.current?.focus();
      },
    );
  }

  onTitleChange(value: string) {
    this.setState({
      title: value,
    });
  }

  onSubmitNewTitle() {
    this.setState(
      {
        editTitle: false,
      },
      () => {
        this.props.onEditTitle?.(this.state.title);
      },
    );
  }

  render() {
    const { titleEditable, actions, onClickAction, children, backLink } = this.props;
    const onClick = (idx: number) => onClickAction(idx);
    return (
      <div className="dsm-c-panel">
        <div className="dsm-c-panel-title">
          {this.state.editTitle ? (
            <div className="edit-title">
              <Input
                setInputRef={this.titleRef}
                value={this.state.title}
                onChange={this.onTitleChange}
                onBlur={this.onSubmitNewTitle}
                onSubmit={this.onSubmitNewTitle}
                width={300}
              />
            </div>
          ) : (
            <div className="panel-title-left">
              {backLink && (
                <Link to={backLink} className="back-link">
                  <Icon cls="tree_leftback" />
                </Link>
              )}
              <h5 className={this.props.backLink && 'back-title'}>
                {this.props.title}
                {titleEditable && <Icon cls="tree_edit" solid onClick={this.onStartEditTitle} />}
              </h5>
            </div>
          )}
          <div className="actions">
            {actions &&
              actions.map((action, index) => (
                <span className="link-b" key={index} onClick={onClick.bind(null, index)}>
                  {action}
                </span>
              ))}
          </div>
        </div>
        <div className="dsm-c-panel-content">{children}</div>
      </div>
    );
  }
}

export default Panel;
