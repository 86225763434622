import { IComponentData } from '@fbs/rp/models/component';
import { Ops, PagePatches } from '@fbs/rp/utils/patch';
import { reArrangeComponentsOfStackPanel } from '@helpers/groupHelper';
import { coverPatches } from '@helpers/patchHelper';

import { UIComponent, UIContainerComponent } from '.';
import { ArtboardPatches } from '@fbs/rp/utils/patch';
import { ComponentChange, ContainerPatches, updateEditComponentsPatches } from '@editor/comps/resizeHelper';

export default class UIStackPanelComponent extends UIContainerComponent {
  /**
   *
   * @type {boolean}
   * @override UIContainerComponent.canMoveChildren
   */
  public canMoveChildren = false;

  /**
   * 组件创建之后
   * @override UIContainerComponent.doAfterCreate
   */
  doAfterCreate() {
    super.refreshComponents();
    reArrangeComponentsOfStackPanel(this.data);
  }

  /**
   * 子元素更新之前
   */
  doRefreshBefore() {
    super.refreshComponents();
    reArrangeComponentsOfStackPanel(this.data);
  }

  /**
   * 刷新子元素
   * @override UIContainerComponent.refreshComponents
   */
  refreshComponents() {
    this.doRefreshBefore();
    super.refreshComponents();
  }

  // eslint-disable-next-line no-unused-vars
  moveChildren(components: UIComponent[]): PagePatches {
    return {
      [this.ownerArtboardID]: {
        do: {},
        undo: {},
      },
    };
  }

  /**
   * 添加子元素
   * @param {IComponentData[]} components
   * @param index
   * @returns {{
   *     patches: PagePatches,
   *     newActiveGroup?: UIContainerComponent,
   *   }}
   * @memberof UIStackPanelComponent
   */
  addComponents(
    components: IComponentData[],
    // eslint-disable-next-line no-unused-vars
    index: number = -1,
  ): {
    patches: PagePatches;
    newActiveGroup?: UIContainerComponent;
  } {
    const patches: PagePatches = {
      [this.ownerArtboardID]: {
        do: {
          [this.id]: [Ops.addChildren('-1', components)],
        },
        undo: {
          [this.id]: [Ops.removeChildren(components.map((comp) => comp._id))],
        },
      },
    };
    const posPatches = this.afterAppendComponents(components);
    if (posPatches) {
      coverPatches(patches[this.ownerArtboardID], posPatches);
    }
    return { patches };
  }

  // eslint-disable-next-line no-unused-vars
  getPositionPatchesOfChildrenChanged(changes: ComponentChange[], includeSelf: boolean = false): ContainerPatches {
    const patches: ArtboardPatches = {
      do: {},
      undo: {},
    };

    // added, removed 让调用者处理
    // 处理 edit 的组件
    updateEditComponentsPatches(
      this.components,
      changes,
      {
        x: 0,
        y: 0,
      },
      patches,
    );
    return {
      patches,
    };
  }

  // resizeHandler(oldPoints: IPoint[], newPoints: IPoint[], base: CoreBase): ArtboardPatches {
  //   const components = this.components;
  //   const patches: ArtboardPatches = {
  //     do: {},
  //     undo: {},
  //   };
  //   let layout: DirectionType | undefined = this.properties.layout && this.properties.layout.direction;
  //   const simpleComponents: UIComponent[] = [];
  //   const filterSimpleComp = (comps: UIComponent[]) => {
  //     comps.forEach(item => {
  //       if (item.isGroup || item instanceof UIStackPanelComponent) {
  //         filterSimpleComp((item as UIContainerComponent).components);
  //       } else {
  //         simpleComponents.push(item);
  //       }
  //     });
  //   };
  //   filterSimpleComp(components);
  //   simpleComponents.forEach(comp => {
  //     let container: UIComponent | undefined = this;
  //     let sumRotate = comp.rotate;
  //     while (container instanceof UIComponent && !container.isArtboard) {
  //       sumRotate += container.rotate;
  //       container = container.parent;
  //     }
  //     //考虑组件旋转，计算实际offset
  //     const offset = {
  //       ...getOffsetByPoints(oldPoints, newPoints, sumRotate),
  //     };
  //     if (layout == 'vertical') {
  //       offset.top = offset.top / simpleComponents.length;
  //       offset.bottom = offset.bottom / simpleComponents.length;
  //     } else if (layout == 'horizontal') {
  //       offset.left = offset.left / simpleComponents.length;
  //       offset.right = offset.right / simpleComponents.length;
  //     } else {
  //       offset.left = 0;
  //       offset.right = 0;
  //       offset.top = 0;
  //       offset.bottom = 0;
  //     }
  //     const {oldPoints: curOldPoints, newPoints: curNewPoints} = comp.getIPointsOfStartAndEnd(offset, comp);
  //     const curPatches: ArtboardPatches = comp.resizeHandler2(curOldPoints, curNewPoints, base);
  //     coverPatches(patches, curPatches);
  //   });
  //   return patches;
  // }
}
