import * as React from 'react';
import classnames from 'classnames';

import { languageManager } from '@/i18n';
import { EN_WEEK_NAMES, getDateByYearMonth, getDayText, LINE, LINES, getDateFormat, WEEK_NAMES } from '../utils';
import { DateFormat, DeviceType } from '../config';
import { IDateInfo } from '..';
import { EDatePickerType } from '@/fbs/rp/models/properties/common';

import './index.scss';

interface IDateComponentProps {
  //年月信息
  dateInfo: { year: number; month: number };
  //选中日期(针对日期)
  selectedInfo?: IDateInfo;
  //选中开始日期(针对日期区间)
  startDateInfo?: IDateInfo;
  //选中结束日期(针对日期区间)
  endDateInfo?: IDateInfo;
  //默认样式
  dateType?: EDatePickerType;
  showYearMonth?: boolean;
  showWeek?: boolean;
  deviceType?: DeviceType;
  dateFormal?: DateFormat;
  isPreview?: boolean;
  //选择日期
  onSelectDate: (date: IDateInfo) => void;
}

interface IDateComponentState {
  currentDate: Date;
}
export default class DateComponent extends React.Component<IDateComponentProps, IDateComponentState> {
  constructor(props: IDateComponentProps) {
    super(props);
    this.state = {
      currentDate: new Date(),
    };
  }

  handleDateSelect(date: number | null) {
    const { year, month } = this.props.dateInfo;
    const { dateType, onSelectDate } = this.props;
    if (date === null) {
      return;
    }
    if (dateType === EDatePickerType.Normal) {
      onSelectDate({ year, month, date: date });
    } else {
      onSelectDate({ year, month, date });
    }
  }

  //当前日期样式
  isCurrentDate = (dayText: number | null) => {
    const { currentDate } = this.state;
    const { year, month } = this.props.dateInfo;
    return (
      year === currentDate.getFullYear() && month === currentDate.getMonth() + 1 && dayText === currentDate.getDate()
    );
  };

  //选中日期样式
  isSelectedDate = (dayText: number | null) => {
    const { year, month } = this.props.dateInfo;
    const { selectedInfo, startDateInfo, endDateInfo } = this.props;
    return (
      (year === selectedInfo?.year && month === selectedInfo.month && dayText === selectedInfo.date) ||
      (year === startDateInfo?.year && month === startDateInfo.month && dayText === startDateInfo.date) ||
      (year === endDateInfo?.year && month === endDateInfo.month && dayText === endDateInfo.date)
    );
  };

  //日期区间样式
  inRangeDate = (dayText: number | null) => {
    const { startDateInfo, endDateInfo } = this.props;
    const { year, month } = this.props.dateInfo;
    if (dayText === null) {
      return;
    }
    return (
      startDateInfo &&
      endDateInfo &&
      getDateByYearMonth(startDateInfo.year, startDateInfo.month, startDateInfo.date).getTime() <
        getDateByYearMonth(year, month, dayText).getTime() &&
      getDateByYearMonth(endDateInfo.year, endDateInfo.month, endDateInfo.date).getTime() >
        getDateByYearMonth(year, month, dayText).getTime() &&
      dayText
    );
  };

  isStartDate = (dayText: number | null) => {
    const { startDateInfo, endDateInfo } = this.props;
    const { year, month } = this.props.dateInfo;
    if (dayText === null) {
      return;
    }
    return (
      startDateInfo &&
      endDateInfo &&
      getDateByYearMonth(startDateInfo.year, startDateInfo.month, startDateInfo.date).getTime() ===
        getDateByYearMonth(year, month, dayText).getTime()
    );
  };

  isEndDate = (dayText: number | null) => {
    const { endDateInfo } = this.props;
    const { year, month } = this.props.dateInfo;
    if (dayText === null) {
      return;
    }
    return (
      endDateInfo &&
      getDateByYearMonth(endDateInfo.year, endDateInfo.month, endDateInfo.date).getTime() ===
        getDateByYearMonth(year, month, dayText).getTime()
    );
  };

  render() {
    const { year, month } = this.props.dateInfo;
    const { showWeek, showYearMonth, deviceType, dateFormal, isPreview } = this.props;
    return (
      <div
        className={classnames('date-picker-content-calendar', {
          'date-picker-content-calendar-phone': deviceType === DeviceType.Phone && isPreview,
        })}
      >
        {showWeek && (
          <div className="date-picker-content-calendar-head">
            {(languageManager.isEnVersion ? EN_WEEK_NAMES : WEEK_NAMES).map((week, key) => {
              return (
                <div
                  className={classnames({ 'date-picker-content-calendar-head-en': languageManager.isEnVersion })}
                  key={key}
                >
                  {week}
                </div>
              );
            })}
          </div>
        )}
        <div className="date-picker-table-tbody">
          {showYearMonth && (
            <span className="show-date">
              {getDateFormat(dateFormal, { year: year, month: month, date: 0 }, false, true)}
            </span>
          )}
          {LINE.map((l, key) => {
            return (
              <div key={key} className="tbody-list">
                {LINES.map((item, index) => {
                  const dayText: number | null = getDayText(key, index, year, month);
                  return (
                    <div
                      style={{ position: 'relative' }}
                      key={index}
                      className={classnames('date-item', {
                        'in-range': this.inRangeDate(dayText),
                      })}
                      onClick={this.handleDateSelect.bind(this, dayText)}
                    >
                      <div
                        className={classnames({
                          'start-date': this.isStartDate(dayText),
                          'end-date': this.isEndDate(dayText),
                        })}
                      ></div>
                      <div
                        className={classnames('date-item-content', {
                          'is-current-date': this.isCurrentDate(dayText),
                          'is-selected': this.isSelectedDate(dayText),
                        })}
                      >
                        {dayText}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
