import { IPoint } from '@/fbs/common/models/common';
import { MoveDelta } from '@/fbs/common/models/resize';
import { CLine } from '@/libs/constants';
import { UIComponent } from '@/editor/comps';

/**
 * 获取组件呈现时的坐标及尺寸样式
 * @param {MoveDelta} offset
 * @param {IPoint} scale
 * @param {number} screenScale
 * @param {boolean | undefined} use3d
 * @returns React.CSSProperties
 */
export const getWrapperStyle = (
  comp: UIComponent,
  offset: MoveDelta,
  scale: IPoint,
  screenScale: number,
  use3d?: boolean,
): React.CSSProperties => {
  let position = comp.position;
  const { width, height } = comp.size;
  let scaleOffset = {
    x: (width * (scale.x - 1)) / 2,
    y: (height * (scale.y - 1)) / 2,
  };

  //如果是流程线，其大小自动计算，设置属性时不考虑缩放
  if (comp.isConnector) {
    scaleOffset = { x: 0, y: 0 };
  }
  const realPositionX = position.x + offset.x;
  const realPositionY = position.y + offset.y;
  const realWidth = width;
  const realHeight = height;
  const scaleStr = scale.x !== 1 || scale.y !== 1 ? `scale(${scale.x}, ${scale.y})` : '';

  const x = Math.round(realPositionX * screenScale + scaleOffset.x);
  const y = Math.round(realPositionY * screenScale + scaleOffset.y);

  // use3d = false;
  let translateStr;
  let boxShadow;
  let rotate = comp.rotate || 0;
  if (!comp.isPreview) {
    rotate %= 360;
  }

  const rotateStr = rotate ? `rotate(${rotate}deg)` : '';

  if (use3d && comp.type !== CLine) {
    translateStr = x || y ? `translate3d(${x}px, ${y}px, 0)` : '';
  } else {
    translateStr = x || y ? `translate(${x}px, ${y}px)` : '';
    // boxShadow = '0 0 1px rgba(255,255,255,0)'; // 芭芭拉魔法--->2D渲染在缩放模式小操作组件出现残影的问题
  }
  const transform = [translateStr, rotateStr, scaleStr].filter((t) => !!t).join(' ');
  return {
    transform: transform ? transform : undefined,
    width: realWidth,
    height: realHeight,
    transformOrigin: comp.isConnector ? 'left top' : undefined,
    boxShadow,
  };
};
