import { initBoundsWithPositionAndSize } from '@/utils/boundsUtils';

import { IPosition, IBounds } from '@/fbs/common/models/common';
import { IComponentData } from '@/fbs/rp/models/component';

export abstract class ArtboardPositionCalculator {
  constructor(
    protected artboards: IComponentData[],
    protected artboardData: IComponentData,
    protected originPointPosition: IPosition,
    protected positionCalcThreshold: number,
  ) {}

  /**
   * 获取处于当前象限区域的所有画板
   */
  abstract getArtboardsInTheArea(): IComponentData[];

  /**
   * 获取处于当前象限区域y轴方向的边缘的画板
   * @param artboards
   */
  abstract getArtboardInTheAreaEdge(artboards: IComponentData[]): IComponentData;

  /**
   * 根据处于当前象限区域y轴方向的边缘的画板的位置，来调整新画板位置到边缘画板周围。
   * @param result
   * @param artboardInTheAreaEdge
   */
  abstract doAdjustResultAccordingToArtboardInTheAreaEdge(
    result: IPosition,
    artboardInTheAreaEdge: IComponentData,
  ): void;

  /**
   * 如果新画板位置所占空间与被比较的画板所占空间重叠，则调整新画板位置到被比较画板周围。
   * @param result
   * @param boundsOfResultPosition
   * @param boundsToCompare
   * @returns 是否调整了 result
   */
  abstract doAdjustResultIfIntersect(
    result: IPosition,
    boundsOfResultPosition: IBounds,
    boundsToCompare: IBounds,
  ): boolean;

  /**
   * 如果新画板位置超过阈值，则调整新画板位置到区域边缘的画板周围，贴近y轴的位置。
   * @param result
   * @param artboardInTheAreaEdge
   * @returns 是否调整了 result
   */
  abstract doAdjustResultIfExceedThresholdAccordingToArtboardInTheAreaEdge(
    result: IPosition,
    artboardInTheAreaEdge: IComponentData,
  ): boolean;

  // 模板方法
  calc(): IPosition {
    let result: IPosition = { ...this.artboardData.position };

    const artboardsInTheArea = this.getArtboardsInTheArea();

    let artboardInTheAreaEdge: IComponentData = this.getArtboardInTheAreaEdge(artboardsInTheArea);

    this.doAdjustResultAccordingToArtboardInTheAreaEdge(result, artboardInTheAreaEdge);

    // eslint-disable-next-line no-constant-condition
    while (true) {
      let toBreak = true;
      let lastIntersectArtboard = undefined;

      // 新位置会覆盖已有画板，重新调整位置
      artboardsInTheArea.forEach((f) => {
        const b = initBoundsWithPositionAndSize(f.position, f.size);
        const nb = initBoundsWithPositionAndSize(result, this.artboardData.size);
        const isAdjusted = this.doAdjustResultIfIntersect(result, nb, b);
        if (isAdjusted) {
          lastIntersectArtboard = f;
        }
      });

      const isAdjusted = this.doAdjustResultIfExceedThresholdAccordingToArtboardInTheAreaEdge(
        result,
        lastIntersectArtboard || artboardInTheAreaEdge,
      );

      // result 没有调整才 break
      toBreak = !isAdjusted;

      if (toBreak) {
        break;
      }
    }

    return result;
  }
}
