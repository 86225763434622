import i18n from '@i18n';

import { IComponentLib } from '../types';
import { ContentPanelV2Config } from './ContentPanelV2';
import { ContentPanelConfig } from './ContentPanel/config';
import { CanvasPanelConfig } from './Canvas';
import { FrameConfig } from './Frame';

const ContainerComponentLib: IComponentLib = {
  id: 'container',
  name: i18n('resource.components.container'),
  components: [CanvasPanelConfig, ContentPanelConfig, ContentPanelV2Config, FrameConfig],
};

export default ContainerComponentLib;
