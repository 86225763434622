import { getRemoveNodeIncludeChild, parseNodesToTree, findNextPageNode, getNodesByIDs } from '@/fbs/rp/utils/app';
import { INodeWithChildren } from '@/fbs/rp/models/node';
import { ISize } from '@/fbs/idoc/models/common';
import { IProjectTreeProps } from './ProjectTree';
import { INode } from '@/dsm2/components/Tree/model';
import { INodeAddInfo, INodeRemoveInfo } from '@/dispatchers/appDispatcher';

export enum PageDoAndUndoType {
  add = 'add',
  clone = 'clone',
  remove = 'remove',
  move = 'move',
  beforeMove = 'beforeMove',
  afterMove = 'afterMove',
  beforeName = 'beforeName',
  afterName = 'afterName',
  group = 'group',
  unGroup = 'unGroup',
  hiddenPage = 'hiddenPage',
  showPage = 'showPage',
  showPageNumber = 'showPageNumber',
  hiddenPageNumber = 'hiddenPageNumber',
  none = 'none',
}

interface IDataType {
  _id?: string | string[];
  artboardID?: string;
  artboardName?: string;
  name?: string;
  appID?: string;
  parentID?: string;
  userID?: number;
  nodeID?: string;
  size?: ISize;
  nodes?: INodeWithChildren[] | INode[];
  nodeIDs?: string[];
  allNodes?: INodeWithChildren[];
  type?: string;
  path?: string;
  index?: number;
  pageID?: string;
  selectedIDs?: string | string[];
  flag?: boolean;
  showPageNumber?: boolean;
  moveIdList?: string[];
  movePathList?: string | string[];
  moveIndexList?: number | number[];
  nextNodeId?: string;
  data?: IDataType;
}

interface OperationType {
  type: PageDoAndUndoType;
  data: { [key: string]: IDataType };
}

export const pageTreeRedoList: OperationType[] = [];
export const pageTreeUndoList: OperationType[] = [];

function doNodesChildrenAllIds(nodes: INodeWithChildren[]): string[] {
  const ids = nodes.reduce((result: string[], node) => {
    if (node.children) {
      result.push(...doNodesChildrenAllIds(node.children));
    }
    result.push(node._id);
    return result;
  }, []);
  return ids;
}

export function redoOperations(props: IProjectTreeProps) {
  if (pageTreeRedoList.length) {
    const operationData = pageTreeRedoList.pop()!;
    pageTreeApplyOperations(operationData, props);
    pageTreeUndoList.push(operationData);
  }
}

export function undoOperations(props: IProjectTreeProps) {
  if (pageTreeUndoList.length) {
    const operationData = pageTreeUndoList.pop()!;
    pageTreeApplyOperations(operationData, props);
    pageTreeRedoList.push(operationData);
  }
}

export function findNodesAndChildIds(nodes: INodeWithChildren[], IDs: string[]): string[] {
  const byNodes = doNodesChildrenAllIds(getNodesByIDs(nodes, IDs));
  return byNodes;
}

function pageTreeApplyOperations(operationData: OperationType, props: IProjectTreeProps) {
  const { app, appID, appDispatcher, pageID, nodes } = props;
  const type = operationData.type;
  const data = operationData.data;
  switch (type) {
    case PageDoAndUndoType.add: {
      operationData.type = PageDoAndUndoType.remove;
      const removeItem = data[PageDoAndUndoType.remove] as INodeRemoveInfo;
      const nextPageNode = findNextPageNode(nodes, removeItem.nodeIDs, pageID);
      removeItem.nextNodeId = nextPageNode?._id;
      appDispatcher.removeNodes(removeItem);
      break;
    }
    case PageDoAndUndoType.remove: {
      // 删除是放入垃圾站，所以redo 是走恢复
      operationData.type = PageDoAndUndoType.add;
      const removeNodeIDs = (data[PageDoAndUndoType.remove] as INodeRemoveInfo).nodeIDs;

      const tempChildren = parseNodesToTree(app!.tempChildren.sort((a, b) => a.path.length - b.path.length));
      const needRemoveIds: string[] = [];
      getRemoveNodeIncludeChild(tempChildren, data[PageDoAndUndoType.add]._id as string[], needRemoveIds);
      // 移除子节点中多余的节点
      const removeIds = needRemoveIds.filter((id) => removeNodeIDs.includes(id));

      appDispatcher.recoverNode(appID, removeIds, undefined, undefined, data[PageDoAndUndoType.add]._id as string);
      break;
    }
    case PageDoAndUndoType.group: {
      operationData.type = PageDoAndUndoType.unGroup;
      const groupInfo = data[PageDoAndUndoType.unGroup];
      appDispatcher.nodeUnGroup(groupInfo.appID!, groupInfo.nodeID!);
      break;
    }
    case PageDoAndUndoType.unGroup: {
      operationData.type = PageDoAndUndoType.group;
      const groupInfo = data[PageDoAndUndoType.group];
      appDispatcher.nodeGroup(groupInfo as INodeAddInfo);
      break;
    }
    case PageDoAndUndoType.showPageNumber: {
      operationData.type = PageDoAndUndoType.hiddenPageNumber;
      const pageInfo = data[PageDoAndUndoType.hiddenPageNumber];
      appDispatcher.editAppInfo(pageInfo.appID!, { showRPPageNumber: pageInfo.showPageNumber });
      break;
    }
    case PageDoAndUndoType.hiddenPageNumber: {
      operationData.type = PageDoAndUndoType.showPageNumber;
      const pageInfo = data[PageDoAndUndoType.showPageNumber];
      appDispatcher.editAppInfo(pageInfo.appID!, { showRPPageNumber: pageInfo.showPageNumber });
      break;
    }
    case PageDoAndUndoType.afterName: {
      operationData.type = PageDoAndUndoType.beforeName;
      const renameData = data[PageDoAndUndoType.beforeName];
      appDispatcher.patchNode(renameData.nodeID as string, renameData.name!);
      break;
    }
    case PageDoAndUndoType.beforeName: {
      operationData.type = PageDoAndUndoType.afterName;
      const renameData = data[PageDoAndUndoType.afterName];
      appDispatcher.patchNode(renameData.nodeID as string, renameData.name!);
      break;
    }
    case PageDoAndUndoType.afterMove: {
      operationData.type = PageDoAndUndoType.beforeMove;
      const moveData = data[PageDoAndUndoType.beforeMove];
      appDispatcher.moveNode(moveData.moveIdList!, moveData.movePathList!, moveData.moveIndexList!);
      break;
    }
    case PageDoAndUndoType.beforeMove: {
      operationData.type = PageDoAndUndoType.afterMove;
      const moveData = data[PageDoAndUndoType.afterMove];
      appDispatcher.moveNode(moveData.moveIdList!, moveData.movePathList!, moveData.moveIndexList!);
      break;
    }
    case PageDoAndUndoType.showPage: {
      operationData.type = PageDoAndUndoType.hiddenPage;
      const pageViewData = data[PageDoAndUndoType.hiddenPage];
      appDispatcher.treePageHide(pageViewData.selectedIDs as string[], pageViewData.flag!);
      break;
    }
    case PageDoAndUndoType.hiddenPage: {
      operationData.type = PageDoAndUndoType.showPage;
      const pageViewData = data[PageDoAndUndoType.showPage];
      appDispatcher.treePageHide(pageViewData.selectedIDs as string[], pageViewData.flag!);
      break;
    }
  }
}
