import * as React from 'react';
import classnames from 'classnames';

import { transBlankChart } from '@utils/textUtils';

import CoreEditor from '@editor/core';
import EditorContext from '@contexts/editor';
import { ValueEditorValueType } from '@/customTypes';
import { UIComponent, UIContainerComponent } from '@editor/comps';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';
import { IComponentValue, IconValue } from '@fbs/rp/models/value';

import { CheckBox, Input, Radio } from '@dsm';
import IconRender from '../../withReplaceIcon/IconRender';
import CustomValueItemEditor from '../CustomValueItemEditor';

import './index.scss';

interface IValueItemProps {
  value: IComponentValue;
  comp: UIComponent;
  coreEditor: CoreEditor;
  type: ValueEditorValueType;
  index: number;
  iconSelected: boolean;
  selected?: boolean;
  checked?: boolean;
  isMultiSelect?: boolean;
  hasInteraction?: boolean;
  atLeastOne?: boolean;
  hideDragger?: boolean;
  disabledValueChange?: boolean;
  autoStartEditor?: boolean;
  moveable?: boolean;
  invalid?: boolean;
  onMoveItem: (oldIndex: number, newIndex: number) => void;
  onMouseEnter: (index: number) => void;
  onEditNext: (index: number, offset: number, canAdd?: boolean) => void;
  onChangeValue: (index: number, value: IComponentValue) => void;
  onClick: (index: number) => void;
  onChecked: (index: number, checked: boolean) => void;
  onExitEditor: (index: number) => void;
  onInteractionDragger: (e: React.MouseEvent, index: number) => void;
  onFontIconClick: (e: React.MouseEvent, id: string) => void;
  onOpenIconLibrariesPanel?: Function;
  setTargetComp: (comp: UIContainerComponent | UIComponent | undefined) => void;
}

interface IValueItemState {
  overTop?: boolean;
  overBottom?: boolean;
  editing?: boolean;
  value?: string;
  showCustomEditorPanel?: boolean;
  clickPoint?: { left: number; top: number };
}

class ValueItem extends React.Component<IValueItemProps, IValueItemState> {
  private self: React.RefObject<HTMLDivElement> = React.createRef();
  static contextType = EditorContext;
  // @ts-ignore
  context: React.ContextType<typeof EditorContext>;
  static defaultProps = {
    moveable: true,
  };

  constructor(props: IValueItemProps) {
    super(props);
    this.state = {
      editing: props.autoStartEditor,
    };
  }

  componentDidMount() {
    const { editing } = this.state;
    const { type } = this.props;
    if (editing && type === ValueEditorValueType.CustomType) {
      this.doShowCustomValueEditor();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: IValueItemProps) {
    //下一个传入的是自动开始编辑 并且 状态发生了改变
    if (nextProps.autoStartEditor && nextProps.autoStartEditor !== this.props.autoStartEditor) {
      this.setState({ editing: true, value: nextProps.value as string });
      if (nextProps.type === ValueEditorValueType.CustomType) {
        this.doShowCustomValueEditor();
      }
    }
    if (!nextProps.selected && this.state.showCustomEditorPanel) {
      this.setState({ showCustomEditorPanel: false });
    }
  }

  private doShowCustomValueEditor = () => {
    const dom = this.self.current;
    if (!dom) {
      return;
    }
    const { left: x, top: y, right, bottom } = dom.getBoundingClientRect();
    const width = 620;
    const height = 420;
    const maxTop = window.innerHeight - height;
    const maxRight = window.innerWidth - width;

    let left = right;
    if (left > maxRight) {
      left = x - width;
    }
    if (left < 0) {
      left = maxRight;
    }
    let top = y;
    if (top > maxTop) {
      top = bottom - height;
    }
    if (top < 0) {
      top = maxTop;
    }
    this.setState({ showCustomEditorPanel: true, clickPoint: { left, top } });
  };

  handleDragStart = (e: React.DragEvent) => {
    e.stopPropagation();

    const { index } = this.props;
    e.dataTransfer.setData('drag-component-item', `${index}`);
    e.dataTransfer.setDragImage(e.target as HTMLElement, e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    e.dataTransfer.effectAllowed = 'move';
  };

  handleDragOver = (e: React.DragEvent) => {
    e.stopPropagation();
    const { types } = e.dataTransfer;
    if (types.length && types[0] === 'drag-component-item') {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';
      const dom = e.target as HTMLElement;
      const { clientHeight } = dom;
      const { offsetY } = e.nativeEvent;
      const overTop = offsetY < clientHeight / 2;
      const overBottom = !overTop;
      this.setState({ overTop, overBottom });
    }
  };

  handleDragLeave = (e: React.DragEvent) => {
    e.stopPropagation();
    this.setState({ overTop: false, overBottom: false });
  };

  handleDrop = (e: React.DragEvent) => {
    e.stopPropagation();

    const { overBottom, overTop } = this.state;
    this.setState({ overTop: false, overBottom: false });
    const data = e.dataTransfer.getData('drag-component-item');
    if (data) {
      const oldIndex = parseInt(data, 10);
      const { index, onMoveItem } = this.props;
      let newIndex = index;
      if (overTop) {
        if (newIndex >= oldIndex && newIndex - oldIndex <= 1) {
          return;
        }
      }
      if (overBottom) {
        if (newIndex <= oldIndex && oldIndex - newIndex <= 1) {
          return;
        }
      }
      if (oldIndex !== newIndex) {
        if (overBottom) {
          newIndex += 1;
        }
        onMoveItem(oldIndex, newIndex);
      }
    }
  };

  handleDragEnd = (e: React.DragEvent) => {
    e.dataTransfer.clearData('drag-component-item');
  };

  handleInputEnter = (value: string) => {
    this.setState({ editing: false, value: undefined });
    if (value.trim()) {
      const { onEditNext, index } = this.props;
      onEditNext(index, 1, true);
    }
  };

  handleInputBlur = (value: string) => {
    this.setState({ editing: false, value: undefined });
    const { onChangeValue, index } = this.props;
    onChangeValue(index, value);
  };

  handleInputChange = (value: string) => {
    this.setState({ value });
  };

  handleStartEditing = () => {
    this.setState({ editing: true }, () => {
      if (this.props.type === ValueEditorValueType.CustomType) {
        this.doShowCustomValueEditor();
      }
    });
  };

  handleClick = () => {
    const { index, onClick } = this.props;
    onClick(index);
    const { showCustomEditorPanel } = this.state;
    !this.stopClick &&
      showCustomEditorPanel &&
      this.setState({ showCustomEditorPanel: false }, () => {
        this.props.onExitEditor(index);
      });
  };

  handleMouseEnter = () => {
    const { index, onMouseEnter } = this.props;
    onMouseEnter(index);
  };

  handleMouseLeave = () => {
    this.context.uiManager.projectTree?.refresh();
  };

  handleInputKeyDown = (e: React.KeyboardEvent) => {
    const { onEditNext, index } = this.props;
    if (e.keyCode === KeyCodeMap.VK_DOWN) {
      onEditNext(index, 1);
    } else if (e.keyCode === KeyCodeMap.VK_UP) {
      onEditNext(index, -1);
    }
  };

  handleCheckedChange = (checked: boolean) => {
    this.doStopClick();
    this.doSelectChange(checked);
  };

  handleRadioClick = () => {
    this.doStopClick();
    const { checked, atLeastOne } = this.props;
    if (atLeastOne && !!checked) {
      return;
    }
    this.doSelectChange(!checked);
  };
  private stopClick = false;

  doStopClick() {
    const { showCustomEditorPanel } = this.state;
    if (!showCustomEditorPanel) {
      return;
    }
    this.stopClick = true;
    setTimeout(() => {
      this.stopClick = false;
    });
  }

  doSelectChange(checked: boolean) {
    const { index, onChecked } = this.props;
    onChecked(index, checked);
  }

  handleInteractionDraggerMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const { onInteractionDragger, index } = this.props;
    onInteractionDragger && onInteractionDragger(e, index);
  };

  renderStringValue = (value: IComponentValue) => {
    const { disabledValueChange } = this.props;
    const { editing, value: editValue } = this.state;
    let tx: string = editValue || '';
    if (editValue === undefined) {
      tx = value as string;
    }
    if (editing && !disabledValueChange) {
      return (
        <Input
          autoFocus
          autoSelectWhenFocus
          submitWithBlur
          value={tx}
          maxlength={100}
          onEnter={this.handleInputEnter}
          onBlur={this.handleInputBlur}
          onChange={this.handleInputChange}
          onKeyDown={this.handleInputKeyDown}
        />
      );
    }
    return <label onDoubleClick={this.handleStartEditing}>{transBlankChart(tx)}</label>;
  };

  renderImageValue = (value: IComponentValue) => {
    return <img src={value as string} />;
  };

  renderIconValue = (value: IComponentValue, style?: React.CSSProperties) => {
    const { iconSelected, comp, onFontIconClick } = this.props;
    return (
      <label style={style}>
        <IconRender
          iconValue={value as IconValue}
          compId={comp.id}
          selected={iconSelected}
          onFontIconClick={onFontIconClick}
        />
      </label>
    );
  };

  renderCustomValue = () => {
    const { comp } = this.props;
    return <label onDoubleClick={this.handleStartEditing}>{comp.displayName}</label>;
  };

  renderOtherValue = (value: IComponentValue) => {
    return (
      <label
        className={classnames({ invalid: this.props.invalid })}
        dangerouslySetInnerHTML={{ __html: value as string }}
      />
    );
  };

  renderValue() {
    const { type, value } = this.props;
    switch (type) {
      case ValueEditorValueType.StringType:
        return this.renderStringValue(value);
      case ValueEditorValueType.ImageType:
        return this.renderImageValue(value);
      case ValueEditorValueType.IconType:
        return this.renderIconValue(value);
      case ValueEditorValueType.OtherType:
        return this.renderOtherValue(value);
      case ValueEditorValueType.CustomType:
        return this.renderCustomValue();
      default:
        return null;
    }
  }

  renderSelect() {
    const { isMultiSelect, checked } = this.props;
    if (isMultiSelect) {
      return <CheckBox checked={checked} onChange={this.handleCheckedChange} />;
    } else {
      return <Radio checked={!!checked} onClick={this.handleRadioClick} />;
    }
  }

  renderDragger() {
    const { hideDragger, hasInteraction } = this.props;
    if (hideDragger) {
      return null;
    }
    return (
      <div
        className={classnames('interaction-dragger', { interaction: hasInteraction })}
        onMouseDown={this.handleInteractionDraggerMouseDown}
      />
    );
  }

  // 用于 override
  renderOperations() {
    return <>{this.renderDragger()}</>;
  }

  renderCustomItemValueEditor() {
    if (!this.state.showCustomEditorPanel) {
      return null;
    }
    return (
      <CustomValueItemEditor
        comp={this.props.comp}
        point={this.state.clickPoint}
        coreEditor={this.props.coreEditor}
        checked={this.props.checked}
        onOpenIconLibrariesPanel={this.props.onOpenIconLibrariesPanel}
        setTargetComp={this.props.setTargetComp}
      />
    );
  }

  render() {
    const { type, selected, moveable, hasInteraction } = this.props;
    const { overBottom, overTop, editing, showCustomEditorPanel } = this.state;

    return (
      <div
        draggable={!editing && moveable && !showCustomEditorPanel}
        className={classnames(
          'value-item',
          {
            'over-top': overTop,
            'over-bottom': overBottom,
            'interaction-flag': hasInteraction,
            selected,
          },
          type,
        )}
        onDragStart={this.handleDragStart}
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDrop}
        onDragEnd={this.handleDragEnd}
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={this.self}
      >
        {this.renderSelect()}
        {this.renderValue()}
        {this.renderOperations()}
        {this.renderCustomItemValueEditor()}
      </div>
    );
  }
}

export default ValueItem;
