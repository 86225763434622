import * as React from 'react';
import * as ReactDom from 'react-dom';

import classnames from 'classnames';

import { IVerticalMenuItemProps } from '.';
import { menuItemTopOrBottomPadding, itemHorizontalSpace } from '@/consts/defaultData/menu';
import { getParentIDsByCompTree } from '@/helpers/treeCompHelper';

import Background from '../../../basic/common/Background';

export const ChildItem = (props: IVerticalMenuItemProps) => {
  const scale = window.pageScale;

  const self = React.useRef<HTMLDivElement>(null);

  const [itemStyle, setItemStyle] = React.useState<React.CSSProperties>({
    transform: `scale(${scale || 1}, ${scale || 1})`,
    paddingLeft: `${itemHorizontalSpace}px`,
    opacity: 0,
  });

  React.useEffect(() => {
    if (self.current) {
      setItemStyle(parseStyle());
    }
  }, [scale]);

  const calcRealWidth = (width: number) => (width * scale) / props.defaultScale;

  const calcOriginWidth = (width: number) => (width * props.defaultScale) / scale;

  const parseStyle = () => {
    const { lineHeight, level, index, verticalContentRects, padding, floatPanelRects, _id, item } = props;
    const compRect = verticalContentRects[0];
    const top = (index * lineHeight + padding.top - menuItemTopOrBottomPadding) * scale + compRect.top;
    const parentIds = getParentIDsByCompTree(item).filter((id) => item.data.id !== id);

    // 将第一次渲染计算出的当前弹出框width数据存储，后续数据都从floatPanelRects获取
    let selfWidth = floatPanelRects[_id];
    if (!selfWidth) {
      selfWidth = calcOriginWidth(self.current?.getBoundingClientRect()!.width as number);
      floatPanelRects[_id] = selfWidth;
    }

    let firstWidth = selfWidth;
    if (level > 0) {
      firstWidth = floatPanelRects[parentIds[parentIds.length - 1]];
    }
    firstWidth = calcRealWidth(firstWidth);

    // 前弹出层宽度总和
    const parentWidth = parentIds.reduce((prev, item) => {
      prev += floatPanelRects[item];
      return prev;
    }, 0);

    const itemStyle: React.CSSProperties = {
      opacity: 1,
      top,
      left: calcRealWidth(parentWidth) + compRect.right,
      transform: `scale(${scale || 1}, ${scale || 1})`,
      paddingLeft: `${itemHorizontalSpace}px`,
    };
    const pageRects = document.getElementsByClassName('preview-page')[0]?.getClientRects()[0];
    // 弹出子项的左右位置根据第一个子项的位置确定，第一项在左边，所有子项弹窗都置于左边
    if (
      pageRects &&
      pageRects.right - compRect.right < firstWidth &&
      pageRects.right - compRect.right < compRect.left - pageRects.left
    ) {
      itemStyle.left = calcRealWidth(-selfWidth - parentWidth) + compRect.left;
      itemStyle.paddingLeft = undefined;
      itemStyle.paddingRight = `${itemHorizontalSpace}px`;
    }
    return itemStyle;
  };

  const renderChild = () => {
    const { lineHeight, comp, size, verticalComp, children, showMobileCursor } = props;
    const { properties, opacity } = verticalComp;
    const transition = comp.getTransition();
    const isTransparent = opacity === 0;
    return (
      <div
        ref={self}
        className={classnames('vertical-menu-item-inner', 'component-popup-container', {
          mobile: showMobileCursor,
          disabled: verticalComp.disabled,
        })}
        style={itemStyle}
      >
        <div className="vertical-menu-item-child">
          {properties.fill && !properties.fill.disabled && !isTransparent && (
            <Background
              size={{ width: size.width, height: children!.length * lineHeight + menuItemTopOrBottomPadding * 2 }}
              transition={transition}
              properties={{ fill: properties.fill, radius: properties.radius }}
              coverStyle={{ width: '100%', height: '100%' }}
            />
          )}
          {children}
        </div>
      </div>
    );
  };

  return ReactDom.createPortal(renderChild(), document.body);
};

export default ChildItem;
