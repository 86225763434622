import * as React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';
import i18n from '@i18n';
import { StyleHelper } from '@helpers/styleHelper';
import { defaultDisabledStroke } from '@/consts/stroke';

import { ICustomCompositeProps } from '../../types';
import Background from '../../basic/common/Background';

import './index.scss';
import './animation.scss';

class ContentPanel extends React.Component<ICustomCompositeProps> {
  constructor(props: ICustomCompositeProps) {
    super(props);
  }

  render() {
    const { comp, isPreview } = this.props;
    const { size, properties } = comp;
    const { fill, stroke } = properties;
    const showPlaceBorder = !isPreview && fill?.disabled && stroke?.disabled;
    const showStroke = showPlaceBorder ? defaultDisabledStroke : stroke;
    const showProperties = { ...properties, stroke: showStroke };
    // 默认边框不受border属性影响
    if (stroke?.disabled) {
      delete showProperties.border;
    }
    const styleParser = StyleHelper.initCSSStyleParser(showProperties);
    const opacity = isUndefined(comp.opacity) ? 1 : comp.opacity / 100;
    const isTransparent = opacity === 0;
    const transition = comp.getTransition();
    return (
      <div
        className={classnames('lib-comp-content-panel', {
          preview: isPreview,
          editing: !isPreview,
        })}
        style={{
          opacity,
          transition,
          ...styleParser.getRadiusStyle(size),
          ...styleParser.getStrokeStyle(),
          ...size,
        }}
      >
        {!fill?.disabled && !isTransparent && <Background size={size} properties={{ fill }} transition={transition} />}
        {isPreview && this.props.children}
        {!isPreview &&
          comp.document &&
          comp.components.map((item) => {
            let invalid = false;
            let name = comp.document!.getFragmentNameByID(item.value as string);
            if (name === '') {
              name = i18n('property.interaction.invalidFragment');
              invalid = true;
            }
            return (
              <div key={item.id} className="content-panel-item-name">
                <label className={classnames({ invalid: invalid })}>{name}</label>
              </div>
            );
          })}
        {!isPreview && !comp.components.length && (
          <div className="content-panel-operation-tips">{i18n('tips.contentPanelTips')}</div>
        )}
      </div>
    );
  }
}

export default ContentPanel;
