import { IArtboardMetaPatch } from '../models/artboard';
import { ComponentOperations } from './patch';

export function getArtboardMetaPatch(operations: ComponentOperations): IArtboardMetaPatch {
  const meta: IArtboardMetaPatch = {};
  type keyType = keyof IArtboardMetaPatch;
  operations.forEach((op) => {
    let path = op.path;
    if (path.startsWith('.')) {
      path = path.substring(1);
    }
    const key = path.replace('/', '') as keyType;
    if (op.op === 'replace') {
      if (key === 'remark') {
        meta[key] = op.value || null;
      } else {
        meta[key] = op.value;
      }
    } else if (op.op === 'remove' && key === 'remark') {
      meta[key] = null;
    } else if (op.op === 'add') {
      meta[key] = op.value;
    }
  });
  return meta;
}
