import * as React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { isFireFox } from '@utils/envUtils';
import { isControlKeyPressed } from '@utils/hotkeysUtils';

import { offlinePath, offlineRoute } from '@/consts/route';

import Preview from '../components/Preview/Loadable';

class Main extends React.Component {
  componentDidMount() {
    if (isFireFox) {
      window.addEventListener('DOMMouseScroll', this.handleWindowMouseWheel, {
        passive: false,
      });
    } else {
      window.addEventListener('mousewheel', this.handleWindowMouseWheel, {
        passive: false,
      });
    }
  }

  componentWillUnmount() {
    if (isFireFox) {
      window.addEventListener('DOMMouseScroll', this.handleWindowMouseWheel, {
        passive: false,
      });
    } else {
      window.addEventListener('mousewheel', this.handleWindowMouseWheel, {
        passive: false,
      });
    }
  }

  handleWindowMouseWheel(e: Event) {
    if (isControlKeyPressed(e as MouseEvent)) {
      e.preventDefault();
    }
  }

  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route exact path={offlineRoute} component={Preview} />
            <Redirect to={offlinePath} />
          </Switch>
        </Router>
      </>
    );
  }
}

export default Main;
