import i18n from '@i18n';
import { SpriteThumb } from '@/consts/spriteIcons';
import ValueEditorType from '@consts/enums/valueEditorType';

import { IComponentItem } from '../types';
import { getDefaultDataFromTemplate } from '../helper';

export const ArrowCfg: IComponentItem = {
  thumb: {
    spriteIconClass: SpriteThumb.Arrow.className,
    dragPosition: SpriteThumb.Arrow.position,
  },
  type: 'arrow',
  lib: 'extension',
  shortCut: 'W',
  name: `${i18n('resource.components.arrow')}`,
  value: {
    type: ValueEditorType.RichText,
  },
  template: `{
    type: @@C.Line,
    size: {width: 150, height: 0},
    fixContent: true,
    properties: {
      line: {
        startArrow: true,
        endArrow: true,
        startPointType: @@LinePointType.solidDot,
        endPointType: @@LinePointType.solidArrow,
      },
      stroke: {
        thickness: 1,
        color: @@SystemColors.DeepBlueColor,
        cap: @@StrokeLineCap.Butt,
        join: @@StrokeLineJoin.Miter,
        disabled: false,
      },
      textFormat: {
        disabled: false,
        fontFamily: 'Microsoft YaHei',
        fontSize: 14,
        textAlign: @@TextAlign.center,
        color: @@defaultTextColor,
        fontStyle: {
          bold: false,
          italic: false,
          underline: false,
          strike: false,
        },
        verticalAlign: @@TextVerticalAlign.middle,
        letterSpace: 0,
        wrap: true,
        isMulti: true,
      },
      shadow: {
        disabled: true,
        x: 0,
        y: 3,
        blur: 6,
        color: { r: 0, g: 0, b: 0, a: 1},
      },
    },
    value: {
      startPoint:{
        x: 0, y: 0,
      },
      endPoint:{
        x: 150,
        y: 0
      }
    }
  }`,
  getDefaultData() {
    return {
      properties: defaultData?.properties,
    };
  },
};

const defaultData = getDefaultDataFromTemplate(ArrowCfg);
