import ILanguage from './type';

const isHuawei = RP_CONFIGS.isHuaWei;
const isHwDc = RP_CONFIGS.isHuaWeiDataCenter;
const isPrivateDeployment = RP_CONFIGS.isPrivateDeployment;
const isDisplayMock = RP_CONFIGS.isDisplayMock ?? true;

const zh_cn: ILanguage = {
  common: {
    title: isHuawei
      ? isHwDc
        ? '{0} - Core Design'
        : '{0} - Tiny Design'
      : isDisplayMock
      ? '{0} - 摹客RP'
      : '{0} - 原型设计',
    mock: '摹客',
  },
  email: 'service@jongde.com',
  enterpriseCustomerService: '企业客服',
  enterpriseCustomerEmail: 'support-e@jongde.com',
  // 主界面
  application: {
    flow: '流程图模式',
    pen: '钢笔',
    pencil: '铅笔',
    group: '编组',
    ungroup: '取消编组',
    undo: '撤销',
    redo: '重做',
    showStatePanel: '状态面板', // 详见 https://redmine.jongde.com/issues/40606
    hiddenStatePanel: '状态面板',
    showArtboardPanel: '画板列表',
    hiddenArtboardPanel: '画板列表',
    startTxMeeting: '发起腾讯会议',
    waitTxMeeting: '腾讯会议待加入',
    isInTxMeeting: '腾讯会议中...',
    artboardPanel: '画板列表',
    play: '演示',
    playFromFirst: '从首页开始',
    playFromCurrent: '从当前页开始',
    switchRightPanelVisible: '收起/展开右侧面板',
    newArtboard: '添加画板',
    newSubArtboard: '辅助画板',
    addNewSubArtboard: '添加内容',
    addNewSubArtboardLine: '列数',
    goBack: '返回上一层',
    upTopMove: '置于首位',
    upMove: '前移一位',
    downMove: '后移一位',
    help: '帮助',
    navigation: '导航',
    fullScreen: '全屏',
    showLeftPanel: '显示左侧面板',
    showRightPanel: '显示右侧面板',
    hiddenLeftPanel: '隐藏左侧面板',
    hiddenRightPanel: '隐藏右侧面板',
    title: isHuawei
      ? isHwDc
        ? '{0} - Core Design'
        : '{0} - Tiny Design'
      : isDisplayMock
      ? '{0} - 摹客'
      : '{0} - 原型设计',
    shortCut: '快捷键',
    tutorial: '教程',
    feedback: '意见反馈',
    community: '讨论区', // 没有英文版
    home: '访问官网',
    showGrid: '显示网格',
    showLayout: '显示布局',
    showRuler: '显示标尺',
    showGuideline: '显示参考线',
    removeAllVerticalGuides: '移除所有垂直参考线',
    removeAllHorizontalGuides: '移除所有水平参考线',
    removeGuides: '移除参考线',
    gridSettings: '网格设置',
    layoutSettings: '布局设置',
    alginConfig: '高级标注',
    tutorialURL: isPrivateDeployment
      ? `${window.location.origin}${window.apis.helpDomain}/p/360`
      : 'https://www.mockplus.cn/mockplus-rp/help',
    dtPosterURL: 'https://www.mockplus.cn/dt',
    homeURL: 'https://www.mockplus.cn/rp',
    feedbackURL: 'https://www.mockplus.cn/support',
    communityURL: 'https://www.mockplus.cn/community/摹客在线原型/all',
    buyURL: 'https://www.mockplus.cn/buy/rp',
    upgradePersonalSpaceURL: 'https://www.mockplus.cn/buy/rp?license=prorp',
    upgradeTeamURL: 'https://www.mockplus.com/pricing/mockplus-rp?license=ultimate&payment=monthly',
    forgotPassword: 'https://user.mockplus.cn/forgotpassword',
    exampleListURL: 'https://www.mockplus.cn/example/rp',
    signInUrl: 'https://user.mockplus.cn/signin?next={0}',
    coopers: '{0}等{1}人',
    zoom: '缩放',
    pageCount: '共{0}个页面',
    pageCount2: '{0}个页面',
    zoom4canvas: '适应画布',
    zoom4selection: '适应选区',
    zoom4artboard: '适应画板',
    option: '首选项',
    scaleBorderAndRadius: '缩放边角', // 待审核
    scaleShadow: '缩放阴影', // 待审核
    settings: '设置',
    generalSettings: '常规选项',
    offlineDemo: '导出离线演示包',
    backToHomePage: '返回首页',
    exitEdit: '退出编辑',
    //返回按钮下拉菜单列表
    goHomePage: '首页',
    mainMenu: '主菜单',
    recentlyEdited: '最近编辑',
    noRecentlyEdited: '暂无相关项目',
    backGroupProject: '返回协作项目',
    moreText: '更多',
    newWindowOpen: '新窗口打开',
    iDocDomain: 'https://app.mockplus.cn',
    showViewport: '视图',
    showActiveFlag: '显示交互标识',
    showRemarkNum: '显示备注编号',

    techSupport: '', // 仅在英文版使用
    discord: '', // 仅在英文版使用
    slack: '', // 仅在英文版使用
    discordURL: '', // 仅在英文版使用
    slackURL: '', // 仅在英文版使用

    //========================
    publishButton: '发布',
    publish: '发布项目',
    publishInstruction: '将项目上传至摹客协作平台进行分享、评审、查看图层数据。',
    team: '团队',
    apps: '协作项目',
    prototypes: '原型稿',
    recentApps: '最近项目',
    noTeams: '暂无可选团队',
    selectedTeam: '请选择团队',
    selectedApp: '请选择协作项目',
    selectedProject: '请选择项目页面',
    selectedPrototype: '请选择原型稿',
    noPrototype: '暂无原型稿，请先进行创建',
    selectedAppSet: '请选择项目集',
    noApps: '暂无可选项目，请先进行创建。',
    noAppSets: '暂无可选项目集，请先进行创建。',
    appName: '名称',
    appSet: '项目集',
    search: '搜索',
    appMembers: '成员管理',
    location: '位置',
    publishProgress: '项目发布中，请稍候...',
    publishFail: '抱歉，发布失败，请稍后重试。',
    publishSuccess: '发布成功。',
    checkViewApp: '查看项目',
    rootAppSets: '全部',
    recentPublish: '最近发布',
    publishRecord: '发布记录',
    publishRange: '发布范围',
    createNewProto: '新建原型稿',
    createNewProtoPlaceholder: '请输入原型稿名称',
    //========================
    projectPage: '项目页面',
    savePlaceholder: '请输入版本名称',
    exportRpFileHelp:
      isPrivateDeployment && !isHuawei
        ? `${window.location.origin}${window.apis.helpDomain}/p/532`
        : 'https://help.mockplus.cn/p/532',
  },
  findReplaceTool: {
    findReplaceText: '查找与替换文字',
    findInput: '查找',
    replaceInput: '替换',
    inCurrentPage: '当前页面',
    inAllPage: '全部页面',
    replace: '替换',
    replaceAll: '全部替换',
    find: '查找',
    noResult: '无结果',
    replaceAllConfirmTips: '确定要将{0}中 {1} 处 “{2}” 替换为 “{3}” 吗？',
    resourceExpired: '此引用组件的资源已被删除或转换，确定要将其从资源中脱离并进行替换吗？',
    replaceAllResourceExpired: '部分引用组件的资源已被删除或转换，确定要将其从资源中脱离并进行替换吗？',
  },
  // 工作区
  workspace: {
    artboard: {
      downloadArtboard: '导出画板',
      downloadArtboardSvg: '导出画板Svg',
      downloadArtboardAsPdf: '导出画板为PDF',
      downloadSetting: '导出画板为图片',
      downloadRatioSelect: '尺寸',
      downloadingMessage: '正在导出图片，这个过程可能耗时较长，请耐心等待。',
      downloadedMessage: '图片已经导出完成，请点击下载按钮下载文件。',
      downloadTimeoutErrorMessage: '抱歉，导出超时，请尝试分批导出。如需帮助，请{0}。',
      downloadErrorMessage: '抱歉，导出失败。',
      copy: '副本',
      exportingMessage: '图片导出中，请稍候...',
      exportOversize: '此画板较大，为保证导出效率，请选择低倍率进行导出。',
      consoleErrorMessage: '图片导出异常：{0}',
      tooManyCheckedPages: '当前选中页面数量较多，为提高导出效率，建议分批操作',
      exportMainArtboardsOnly: '仅导出主画板',
      exportScale: '导出倍率',
      downloadImageError: '图片下载失败',
      applyToCover: '设置为封面',
      applyCoverSuccess: '项目封面设置成功',
    },
    grid: {
      blockSize: '网格尺寸',
      thickLinesEvery: '粗线间隔',
      colors: '颜色',
      makeDefault: '设置为默认',
      dark: '暗',
      light: '亮',
      blocks: '格',
    },
    layout: {
      columns: '列',
      totalWidth: '总宽',
      offset: '偏离',
      center: '居中',
      columnCount: '列数',
      gutterOnOutside: '外围间距',
      gutterWidth: '间距',
      gutterHeight: '间距',
      columnWidth: '列宽',
      rows: '行',
      rowHeight: '行高',
      visuals: '视觉',
      fillGrid: '填充网格',
      strokeOutline: '外侧描边',
      drawAllGridLines: '绘制所有水平线',
    },
    alignType: {
      normalAlign: '常规对齐',
      equidistantAlign: '等距侦测',
      distanceAlign: '距离标注',
    },
    backToEditor: '返回',
    statusBar: {
      projectType: '项目类型',
      phone: '手机',
      pad: '平板',
      web: '网页',
      watch: '手表',
      vehicle: '车载中控',
      custom: '自定义尺寸',
      huawei: '华为',
    },
    generalSettings: {
      pasteOffset: {
        label: '粘贴对象偏移距离',
        x: 'X',
        y: 'Y',
      },
      canvasColor: '画布颜色',
      resetColor: '恢复默认',
      pasteAtTop: '粘贴对象置于顶层',
      showHiddenArea: '显示隐藏的区域',
      showLayerNameInPropertiesPanel: '在属性面板显示图层名称',
    },
  },
  // 资源库
  resource: {
    project: '页面',
    artboard: '画板',
    component: '组件',
    icon: '图标',
    resource: '资源',
    outline: '图层',
    searchPage: '搜索',
    searchComponent: '搜索',
    searchComponentlib: '搜索全部组件库',
    searchCurrentComponentlib: '搜索当前组件库',
    searchIcon: '搜索',
    searchLib: '搜索',
    searchLayer: '搜索',
    recycleBin: '回收站',
    // 组件库
    components: {
      customCompGuidance: '点击查看更多可用组件。',
      newContentPanelGuidanceTitle: '试一试全新的动态面板！',
      newContentPanelGuidanceContent:
        '动态面板用于制作内容切换效果。且相较于内容面板，动态面板提供更方便的内容制作、管理方式。',
      groupName: '预设',
      basic: '基本',
      container: '容器',
      common: '常用',
      flow: '形状',
      antDesign: '', // 未使用
      comment: '批注',
      chart: '图表',
      text: '文本',
      textContent: '请输入文本内容',
      rect: '矩形',
      ellipse: '椭圆',
      circle: '圆',
      image: '图片',
      line: '线条',
      triangle: '三角形',
      polygon: '多边形',
      paragraph: '多行文字',
      input: '单行输入框',
      textarea: '多行输入框',
      pureText: '文本',
      icon: '图标',
      canvasPanel: '面板',
      contentPanel: '内容面板',
      contentPanelV2: '动态面板',
      frame: '网页',
      listLayoutPanel: '列表面板',
      stackPanel: '堆叠面板',
      wrapPanel: '折行面板',
      selectPanel: '选项组',
      // extension
      button: '按钮',
      iconButton: '图标按钮',
      switch: '开关',
      checkbox: '复选框',
      checkboxGroup: '复选框组',
      radio: '单选按钮',
      radioButtonGroup: '单选按钮组',
      select: '单选下拉框',
      multipleSelect: '多选下拉框',
      carouselChart: '轮播图',
      list: '列表',
      numericStep: '数字输入器',
      breadCrumbs: '面包屑',
      segmentsControl: '分段控件',
      selectTab: '选项卡',
      imageTextTab: '图文选项卡',
      verticalTab: '垂直选项卡',
      slider: '滑块',
      progressBar: '进度条',
      circleProgressBar: '环形进度条',
      score: '评分控件',
      video: '视频',
      qrcode: '二维码',
      keyboard: '键盘',
      audio: '音频',
      tree: '树',
      table: '表格',
      hotArea: '热区',
      //datePicker
      datePicker: '日期选择器',
      navigationMenu: '导航菜单',
      verticalMenu: '垂直菜单',
      horizontalMenu: '水平菜单',
      collapse: '折叠面板',
      datePickerInfo: {
        optionDate: '请选择日期',
        optionDateRange: '',
        startDate: '开始日期',
        endDate: '结束日期',
        Mon: '一',
        Tues: '二',
        Wed: '三',
        Thur: '四',
        Fri: '五',
        Sat: '六',
        Sun: '日',
        year: '年',
        month: '月',
        date: '日',
      },
      timePicker: '时间选择器',
      //图表类
      barChart: '柱状图',
      pieChart: '饼图',
      doughnutChart: '环形图',
      lineChart: '折线图',
      barChartHorizontal: '条形图',
      areaChart: '面积图',
      radarChart: '雷达图',
      scatterChart: '散点图',
      combinationChart: '柱折组合图',
      gaugeChart: '仪表盘',
      mapChart: '统计地图',
      data: '数据',
      data_one: '数据1',
      data_two: '数据2',
      data_three: '数据3',
      project_one: '项目1',
      project_two: '项目2',
      project_three: '项目3',
      project_four: '项目4',
      project_five: '项目5',
      user_count: '用户数',
      sale_count: '销量',
      Jan: '一月',
      Fed: '二月',
      Mar: '三月',
      Apr: '四月',
      May: '五月',
      Jun: '六月',
      barLabel: '柱状',
      lineLabel: '折线',
      // comment
      arrow: '箭头',
      callOut: '编号',
      stickNote: '便签条',
      drop: '水滴',
      dropDown: '下拉列表',
      snapshot: '快照',
      verticalCurly: '垂直花括号',
      horizontalCurly: '水平花括号',
      verticalSeparatorLine: '垂直分隔线',
      horizontalSeparatorLine: '水平分隔线',
      labelInput: '标签输入框',
      searchBox: '搜索框',
      link: '链接',
      path: '路径',
      compoundPath: '复合路径',
      connector: '连接线',
      group: '组',
    },
    componentsText: {
      itemText: '选项',
      optionText: '选项',
      newItem: '新条目',
      listItemText: '条目',
      title: '标题',
      content: '内容',
      xAxisText: '项目{0}',
      yAxisText: '数据{0}',
      mapAxisText: '销量{0}',
      breadCrumbsText: '一级目录|二级目录|三级目录|四级目录',
      qrCode: 'https://www.mockplus.cn',
      selectTeam: '请选择团队',
      selectProject: '请选择项目',
      snapshotText: '双击插入设计稿',
      snapshotCanNotFound: '无法找到源设计稿',
      snapshotInvalid: '快照失效',
      VPCImageURL: 'https://img02.mockplus.cn',
      // replaceVPCImageURL: 'http://mockplus-static.oss-cn-hangzhou-internal.aliyuncs.com',
      snapshotTitle: '插入设计稿',
      noContent: '暂无内容',
      resetCropArea: '清除选区',
      fitCompText: '适应当前尺寸',
      currentSize: '尺寸',
      frameTips: '双击输入链接地址',
      timePickerTips: '请选择时间',
      startTime: '开始时间',
      endTime: '结束时间',
      frameInQqDocsTips: '抱歉，当前环境中不支持显示网页内容',
    },
    // 组件： 图形库
    flow: {
      rectangle: '矩形',
      parallelogram: '平行四边形',
      diamond: '菱形',
      circle: '圆',
      ellipse: '椭圆',
      triangle: '三角形',
      pentagon: '五边形',
      roundRectangle: '圆角矩形',
      semicircle: '半圆',
      octagon: '八边形',
      keying: '键入',
      start: '开始/结束',
      rightTriangle: '右三角形',
      trapezoid: '梯形',
      hexagon: '六边形',
      drop: '水滴',
      heart: '心形',
      sector: '扇形',
      star: '星形',
      cloud: '云',
      dataBase: '数据库',
      punchedCard: '卡片',
      roundedSpeechBubble: '对话框（圆）',
      squareSpeechBubble: '对话框（方）',
      rightArrowButton: '右箭头按钮',
      leftArrowButton: '左箭头按钮',
      offPageConnector: '跨页引用',
      ready: '预备',
      beveledRectangle: '斜角矩形',
      sectorDiagram: '扇形图',
      cross: '十字',
      limitCycle: '循环限值',
      manualInput: '人工输入',
      punchedTape: '条带',
      display: '展示',
      externalData: '外部数据',
      manualOperation: '手动操作',
      rightArrow: '右箭头',
      leftArrow: '左箭头',
      horizontalDoubleArrow: '左右箭头',
      upArrow: '上箭头',
      downArrow: '下箭头',
      verticalDoubleArrow: '上下箭头',
      upGrid: '矩形格（上）',
      leftGrid: '矩形格（左）',
      classicTab: '经典标签',
      queueData: '队列数据',
      delay: '推迟',
      APQC: 'APQC',
      collate: '对照',
      leftBracket: '注释（左）',
      rightBracket: '注释（右）',
      leftBrace: '备注（左）',
      rightBrace: '备注（右）',
      document: '文档',
      pieChart: '饼图',
      rightAngle: '右拐角',
      rightTurn: '右返回',
      leftTurn: '左返回',
    },
    // 图标库
    icons: {
      pay: '支付',
      office: '文件办公',
      chart: '图表数据',
      gesture: '手势',
      social: '社交',
      device: '设备',
      traffic: '交通',
      symbol: '符号',
      direction: '方向',
      media: '多媒体',
      edit: '编辑',
      treatment: '医疗',
      letter: '数字/字母',
      finance: '金融理财',
      eating: '饮食',
      nature: '自然',
    },
    // 设计资源库
    libs: {
      arrangementMode: '布局',
      component: '组件',
      color: '颜色',
      typography: '文本样式',
      addLib: '{0}存为资源',
      createSymbol: '添加资源',
      share: '共享',
      cancelSharing: '取消共享',
      cancelSharingToAll: '取消共享(所有团队)',
      placeholder: '{0}的设计资源',
      shareToMyTeam: '将资源共享至当前团队吗？',
      shareToAllTeams: '将资源共享至：',
      cancelShareToMyTeam: '当前团队正在共享此资源，确定要取消共享吗？',
      //
      cancelShareToAllTeams: '系统中所有团队正在共享此资源，确定要取消共享吗？',
      //
      myTeam: '当前团队',
      allTeams: '系统内所有团队',
      sync: '资源相互引用，并自动同步修改',
      addGroup: '新建分组',
      management: '管理资源',
      defaultGroupName: '新分组',
      moveGroup: '删除“{0}”分组后不可恢复，确定继续吗？\n你可以将组内的{1}个资源移至其它分组，否则资源将被一同删除。',
      //
      removeGroup: '确定要删除“{0}”分组吗？',
      noMoveGroup: '删除资源',
      moveAssetTo: '移动资源到',
      moveAsset: '移动',
      noGroup: '暂无分组',
      noComponent: '暂无组件',
      addComponentPlaceholder: '资源名称',
      useComponentCount: '已被添加使用{0}次',
      addGroupPlaceholder: '分组名称',
      resourceLoadFail: '抱歉，加载出错了...',
      reload: '重新加载',
      searchGroup: '搜索',
      cannotModify: '此组件为共享的同步资源，只有资源创建者才可对其进行修改。',
      deleteSyncComponent: '确定要删除当前资源吗？此操作不可恢复。',
      deleteAsyncComponent: '确定要删除当前资源吗？此操作不可恢复。',
      deleteSharedComponent: '当前资源已共享，请取消共享后再删除。',
      appName: '来源：{0}',
      noApp: '来源：被删除的项目',
      //
      cannotAddRefResource: '你正在使用共享的设计资源库，请切换至当前项目的设计资源库后再试。',
      convertTips: '转换',
      convertToSymbol: '转为引用资源',
      convertSymbolToNormal: '转为普通资源',
      convertToastText: '转换成功',
      dialogConvertToSymbol: '确定要将所选资源转为引用资源吗？此操作不可撤销。',
      dialogConvertSymbolToNormal: '确定要将此资源转为普通资源吗？其所有实例之间将不再自动同步修改，此操作不可撤销。',
      dialogConvertSymbolOrNormal:
        '确定要将所选资源进行转换吗？相关引用资源的所有实例之间将不再自动同步修改，此操作不可撤销。',
      shareResources: '共享设计资源',
      contentPanelV2NoAddComponentsTip: '抱歉，动态面板不支持存为资源',
    },
  },
  // 项目
  project: {
    page: '页面',
    group: '分组',
    newPage: '新建页面',
    newGroup: '新建分组',
    addNew: '新建',
    childPage: '子页面',
    childGroup: '子分组',
    insert: '插入',
    undo: '恢复',
    delete: '清除',
    move: '移动',
    moveUp: '上移',
    moveDown: '下移',
    moveUpLevel: '提级',
    moveDownLevel: '降级',
    resetAll: '恢复所有',
    clearAll: '清除所有',
    defaultPageName: '页面1',
    undoNow: '立即恢复',
    showPageNumber: '显示页面编号',
    hiddenPageNumber: '隐藏页面编号',
    showPage: '显示',
    hiddenPage: '隐藏',
    hiddenPageTip: '演示界面中，隐藏的页面对无项目编辑权限者不可见。',
    mainName: '主画板',
    mainDTName: '画板',
    subArtboard: '辅助画板 {0}',
    subContent: '内容 {0}',
    subDTArtboard: '画板 {0}',
    revisions: {
      title: '历史版本',
      revert: '还原到该历史版本',
      noRevision: '当前版本下，该画板没有历史记录',
      revertTips: '确定要将页面内容还原到所选版本吗？其他页面内容不受影响。',
      deleteVersion: '确定要删除此版本记录吗？此操作不可撤销。',
      deleteSuccessText: '版本记录删除成功',
      tips: '摹客会自动为你生成历史版本，你可以根据需要恢复历史数据。',
      //
      noPageRevisions: '当前页面还未产生历史版本!',
      currentVersion: '当前版本',
      revisions: '版本',
      artboard: '画板',
      moreRevisions: '更多历史版本',
      upgradeTips: '当前仅展示7天内的历史版本，升级团队可查看全部版本记录。', // '升级团队后，可查看更多历史版本。',
      personalSpaceUpgradeTips: '当前仅展示7天内的历史版本，升级可查看全部版本记录。',
      upgradeHelp: '立即升级',
      loadFailed: '加载失败，点击重试',
      noneMore: '暂无更多版本记录',
      saveSuccessText: '当前页面版本已保存',
      renameVersionTitle: '重命名',
      conditionHasAuto: '显示自动保存版本',
      filterTooltips: '筛选',
      deleteTooltips: '删除',
      revertTooltips: '还原',
      rollbackTips: '此页面已由成员{0}还原至历史版本“{1}”，确定后将自动前往目标版本。',
    },
    empty: {
      welcome: '开始你的设计',
      tips: '创建高保真设计、交互原型，多人实时编辑',
    },
    currentProject: '当前项目',
  },
  // 编辑器
  editor: {
    clickAndUpload: '点击上传',
    applyOriginalSize: '按照原始尺寸',
    commonlyUsedColor: '常用颜色',
    solidFill: '纯色',
    linearFill: '线性渐变',
    radialFill: '径向渐变',
    append: '添加',
    up: '上移',
    down: '下移',
    addChild: '添加子项',
    mergeCells: '合并单元格',
    splitCells: '拆分单元格',
    cleanStyle: '清除格式',
    cleanContent: '清除内容',
    row: '行',
    column: '列',
    unshiftColumn: '左侧插入1列',
    unshiftColumns: '左侧插入3列',
    pushColumn: '右侧插入1列',
    pushColumns: '右侧插入3列',
    unshiftRow: '上方插入1行',
    unshiftRows: '上方插入3行',
    pushRow: '下方插入1行',
    pushRows: '下方插入3行',
    dragToAddRows: '点击或拖拽以添加行',
    dragToAddColumns: '点击或拖拽以添加列',
    tableInfo: '行：{0}，列：{1}',
    deletColumns: '删除所在列',
    deletRows: '删除所在行',
    contentType: '单元格类型',
    convertToText: '文本',
    convertToCheckBox: '复选框',
    locate: '定位',
  },
  // 桌面应用
  pc: {
    name: '摹客RP',
    homepage: '首页',
    logout: '退出登录', // 同线上
    team: {
      change: {
        label: '切换团队', // 同线上
      },
      name: {
        label: '名称', // 同线上
      },
      role: {
        label: '身份', // 同线上
        value: {
          superAdministrator: '超级管理员', // 同线上
          administrator: '管理员', // 同线上
          member: '成员', // 同线上
          visitor: '协同人', // 同线上
          observer: '访客',
        },
        ability: {
          administrator: '管理员（全部功能）',
          member: '成员（可编辑和设计）',
          visitor: '协同人（可在摹客协作进行评论和查看标注）',
          observer: '访客（仅可预览设计稿）',
        },
      },
      homepage: {
        label: '团队首页',
        url: 'https://rp.mockplus.cn/team/{0}',
      },
      upgrade: {
        content: '免费版不支持此功能，请升级团队后使用。',
        personalSpaceContent: '免费版不支持此功能，请升级后再试。',
        activity: '参加免费计划',
        buy: '立即升级',
        buyNow: '立即升级',
      },
      create: {
        title: '新建团队',
        label: '在新团队中开始你的设计',
        placeholder: '请输入团队名称',
        value: '{0}的团队',
      },
      expansion: '扩容团队',
      expansionNow: '立即扩容',
      upgradeTips: '升级团队后，即可添加更多协作成员，当前仍可邀请访客加入。',
      expansionTips: '扩容后，即可添加更多协作成员，当前仍可邀请访客加入。',
      contactUs1: '团队人数已达到限制，发送邮件至',
      contactUs2: '，联系我们获取更多成员名额。',
    },
    app: {
      createLabel: '项目',
      appsIsMaximum: '当前团队项目数量已达上限，请升级团队后重试。',
      newApp: '新建',
      create: {
        title: '新建项目',
      },
      user: {
        label: '作者', // 同线上
      },
      time: {
        create: '创建时间', // 同线上
        update: '更新时间', // 同线上
      },
      name: {
        label: '名称', // 同线上
        value: '新项目', // 同线上
        placeholder: '项目名称', // 同线上
      },
      type: {
        label: '项目类型',
        recent: '最近更新', // 同线上
        star: '我的收藏', // 同线上
        myCreate: '我创建的', // 同线上
        all: '全部', // 同线上
        recycle: '回收站', // 同线上
        achive: '已归档', // 同线上
        private: {
          value: '私有',
          explain: '仅项目内成员可见',
        },
        teamApp: {
          value: '团队',
          explain: '团队的所有成员都可见',
        },
      },
      appSet: {
        create: {
          title: '新建项目集', // 同线上
        },
        name: {
          placeholder: '请输入项目集名称', // 同线上
        },
        parent: {
          placeholder: '根目录',
        },
      },
      restore: '恢复', // 同线上
      clear: {
        value: '清除', // 同线上
        title: '清除后不可恢复，确定要清除吗？', // 同线上
      },
      star: '收藏', // 同线上
      unStar: '取消收藏', // 同线上
      move: '移动', // 同线上
      delete: '确定要删除“{0}”吗？',
      noContent: '抱歉，暂无内容',
      search: '搜索',
      screen: '筛选',
      module: {
        rp: {
          label: '仅RP项目',
        },
        all: '全部项目',
      },
      demo: '演示',
      member: {
        label: '成员管理',
        authorization: '权限管理',
        search: '搜索',
        invitation: {
          label: '邀请新成员',
          link: '邀请链接',
          copyLink: '复制链接',
          term: '链接有效期',
          invalid: {
            label: '{0}天',
            content: '此链接将在{0}天后失效',
          },
          forever: {
            label: '永久',
            content: '此链接将永久有效',
          },
          fullStarffed: {
            tipOne: '成员人数已达',
            tipTwo: '人，请扩容后再添加更多成员。',
            expansion: '立即扩容',
          },
          overflow: {
            //
            title: '成员数量已超出限制，请联系摹客客服处理。',
            contact: '联系客服',
          },
        },
        department: '所属部门',
        addDepartment: '添加部门',
        submit: '提交',
        submitSuccess: '提交成功',
        ungrouped: '未分组',
        lastSelection: '上次的选择',
        noMembers: '团队中还未加入其他成员',
        removeParent: {
          group: '父级项目集的部门禁用，请在父级的成员管理中取消选择。',
          //
          member: '父级项目集的成员禁用，请在父级的成员管理中取消勾选。',
          //
          link: isPrivateDeployment
            ? `${window.location.origin}${window.apis.helpDomain}/p/238`
            : 'https://help.mockplus.cn/p/238',
        },
        removeCreator: '所有者无法被移除',
      },
      width: '宽',
      height: '高',
      overrideTips: '当前团队的项目数量已达 {0} 个，请升级后再创建更多项目。',
    },
    user: {
      email: {
        placeholder: '邮箱/摹客账号', // 同线上
        noValue: '请输入格式正确邮箱。', // 同线上
        register: {
          placeholder: '请输入注册邮箱', // 同线上
        },
      },
      password: {
        placeholder: '密码', // 同线上
        register: {
          placeholder: '请输入登录密码', // 同线上
        },
        repeat: '请再次输入密码', // 同线上
        forgot: '忘记密码', // 同线上
        noValue: '密码长度为6-20。', // 同线上
        different: '两次密码输入不相同。', // 同线上
      },
      vocation: {
        value: {
          pm: '产品经理',
          projectManager: '项目经理',
          designer: '设计师',
          developer: '开发工程师',
          operator: '运营人员',
          other: '其他',
        },
        placeholder: '请选择你的职业',
      },
      noUser: '还没有账号？', // 同线上
      register: {
        link: '立即注册', // 同线上
        value: '注册', // 同线上
      },
      agree: {
        read: '我已仔细阅读并同意', // 同线上
        value: '《摹客使用协议》', // 同线上
      },
      login: {
        title: '摹客RP',
        content: '让设计更快更简单',
        value: '登录', // 同线上
        hasAccount: '已有账号？', // 同线上
        link: '立即登录', // 同线上
        linkToWebsite: '', // 暂未上线
        networkError: '网络异常，请检查后重试',
      },
      admin: {
        label: '账户管理',
        link: 'https://www.mockplus.cn/me',
      },
      guest: {
        cannotEdit: '客户端仅用于项目编辑\n协同人或访客无编辑权限',
        contactAdministrator: '请联系团队管理员修改身份后再试',
      },
      switch: '切换账号',
    },
  },
  theme: {
    apply: '应用并关闭',
    cancel: '取消',
  },
  // 属性
  property: {
    properties: '属性',
    interactions: '交互',
    remarks: '备注',
    // 属性名
    propertyNames: {
      direction: '方向',
      structure: '结构',
      fill: '填充',
      bgcolor: '背景色',
      hoverFill: '悬停背景',
      forgetColor: '前景色',
      buttonColor: '按钮色',
      checkedColor: '选中颜色',
      defaultColor: '默认颜色',
      checkedBgcolor: '选中背景',
      stroke: '边框',
      lineStroke: '线条',
      checkedUnderbar: '选中标识条',
      border: '边框',
      radius: '圆角',
      text: '文本样式',
      checkedText: '选中文本样式',
      defaultText: '默认文本样式',
      multiText: '排版',
      polygon: '多边形',
      icon: '图标',
      iconColor: '图标颜色',
      color: '颜色',
      img: '图片',
      iconSize: '图标大小',
      layout: '布局',
      container: '容器',
      shadow: '阴影',
      line: '线段',
      lineHeight: '行高',
      inputModel: '输入类型',
      arrow: '箭头',
      placeholder: '占位文字',
      placeholderColor: '占位文字颜色',
      angle: '旋转',
      close: '闭合',
      controls: '显示控制条',
      autoPlay: '自动播放',
      hoverTextStyle: '链接样式',
      checked: '选中',
      trackStyle: '轨道样式',
      progressStyle: '进度样式',
      progress: '进度',
      progressColor: '进度颜色',
      trackFill: '轨道颜色',
      indicatorColor: '滑块颜色',
      separator: '分隔线',
      separatorRatio: '分隔线长度',
      keyboard: '键盘类型',
      featureButtonColor: '功能键颜色',
      fontColor: '文字颜色',
      textBgColor: '文本背景色',
      defaultSelected: '选中',
      defaultOpen: '默认开启',
      padding: '内边距',
      extensionLink: '外部链接',
      remark: '备注',
      blur: '模糊',
      colorFilter: '调整颜色',
      float: '滚动时保持固定',
      timePickerType: '类型',
      timeFormat: '格式',
      deviceType: '样式',
      dateType: '类型',
      dateFormat: '格式',
      showMark: '显示遮罩',
      showSelectedYear: '显示年份',
      replaceShape: '形状',
      axis: '坐标轴',
      dataLabel: '数据标签',
      legendPosition: '图例',
      gap: '间距',
      type: '类型',
      smooth: '线条平滑',
      stack: '数据堆叠',
      loopPlay: '循环播放',
      crossPagePlay: '跨页播放',
      canSearch: '支持搜索',
      markerStrip: '选中标识条',
      autoCarousel: '自动轮播',
      indicator: '指示器',
      turnPageButton: '翻页按钮',
      playEffect: '播放效果',
      playInterval: '播放间隔',
      playSpeed: '播放速度',
      length: '长度',
      indicatorType: {
        circle: '圆点',
        rect: '矩形',
      },
      playEffectType: {
        push: '推入',
        slide: '滑入',
        fade: '淡入',
      },
      tooltips: '提示文字',
      // 导出
      export: {
        export: '导出',
        preview: '预览',
        prefix: '前缀',
        suffix: '后缀',
        multiExport: '导出{0}个图层',
        loadingText: '图片准备中，已完成{0}/{1}，请稍候...',
      },
      unit: '单位',
      yAxisStart: '起始数值',
      yAxisGap: '数值间距',
      barYAxisStart: '柱状起始数值',
      barYAxisGap: '柱状数值间距',
      lineYAxisStart: '折线起始数值',
      lineYAxisGap: '折线数值间距',
      axisLabelRotate: '轴标签角度',
      scaleMark: '刻度线',
      barWidth: '柱状宽度',
      showAnimation: '载入动画',
      noteConnectedLine: {
        showLine: '连接线',
        width: '宽度',
      },
      gaugeChartProperties: {
        dialPlate: '表盘',
        basic: '基础',
        stage: '阶段',
        grade: '等级',
        bgColor: '背景色',
        progressColor: '进度色',
        color: '颜色',
        notchAngle: '缺口角度',
        arcWidth: '宽度',
        pointType: '端点',
        data: '数据',
        defaultValue: '默认值',
        min: '最小值',
        max: '最大值',
        dataLabel: '数据标签',
        labelUnit: '单位',
        tick: '刻度',
        tickNumber: '数值',
        tickGap: '间隔',
        smallTick: '小刻度',
        pointer: '指针',
        dataFluctuation: '数据波动',
      },
      mapChart: {
        region: '区域',
        type: '类型',
        polychrome: '单色',
        monochrome: '多色',
        heatmap: '自动配色',
        regionName: '区域名称',
        regionBorder: '区域边框',
        hoverArea: '悬停区域',
        popupWin: '悬浮窗口',
        rightBottom: '右下',
        leftBottom: '左下',
        rightTop: '右上',
        leftTop: '左上',
      },
      expandOneItem: '单次只展开一项',
      textVerticalPadding: '文本垂直边距',
      titleBgColor: '标题背景',
      contentBgColor: '内容背景',
      titleTextStyle: '标题文本样式',
      contentTextStyle: '内容文本样式',
      selectList: {
        labelName: '下拉列表',
        bgColor: '列表背景',
        selectedBgColor: '选中背景',
        selectedFontColor: '选中字色',
      },
    },
    // 组件属性
    component: {
      onLeft: '左侧',
      onRight: '右侧',
      structureAlignLeft: '文字在左',
      structureAlignRight: '文字在右',
      alignLeft: '左对齐',
      alignVerticalCenter: '水平居中对齐',
      alignRight: '右对齐',
      alignTop: '顶对齐',
      alignJustify: '两端对齐',
      alignHorizontalCenter: '垂直居中对齐',
      alignBottom: '底对齐',
      alignAverageHorizontal: '水平等距',
      alignAverageVertical: '垂直等距',
      horizontalSpacing: '水平间距',
      verticalSpacing: '垂直间距',
      showComp: '显示',
      hideComp: '隐藏',
      enabledComp: '启用',
      disabledComp: '禁用',
      selectComp: '选择了 {0} 个组件',
      opacity: '不透明度',
      clipPath: '裁剪路径',
      openPath: '打开路径',
      closePath: '闭合路径',
      segRadius: '圆角',
      straight: '笔直',
      mirrored: '对称',
      disconnected: '分离',
      asymmetric: '不对称',
      shape: '外形',
      text: '文本',
      extend: '扩展',
      relation: {
        label: '关联',
        add: '添加链接',
        init: {
          specificationDocument: '查看规范文档',
          componentCode: '查看组件代码',
          visualAnnotation: '查看视觉标注',
        },
      },
      border: {
        left: '左边框',
        right: '右边框',
        bottom: '下边框',
        top: '上边框',
      },
      bounds: {
        lockRatio: '固定比例',
        flipHorizontal: '水平翻转',
        flipVertical: '垂直翻转',
        fixedWidth: '固定宽度',
        fixedHeight: '固定高度',
        horizontalCenter: '水平居中',
        verticalCenter: '垂直居中',
        responsive: '智能布局',
        auto: '自动',
        manual: '手动',
      },
      cell: {
        rowHeight: '行高',
        rowCount: '行数',
        itemHeight: '子项高度',
        itemWidth: '子项宽度',
        columnWidth: '列宽',
        columnCount: '列数',
        columnGap: '列间距',
        rowGap: '行间距',
        gap: '间距',
      },
      container: {
        scroll: '支持滚动',
        showScroll: '显示滚动条',
        modeBothText: '双向',
        modeVerticalText: '垂直',
        modeHorizontalText: '水平',
      },
      img: {
        upLoadImg: '上传图片',
        fit: '填充',
        tile: '平铺',
        original: '适应',
        stretch: '拉伸',
        defaultStyle: '默认样式',
        transformShape: '转为矢量形状',
      },
      icon: {
        lightIcon: '细线',
        boldIcon: '粗线',
        defaultStyle: '样式',
      },
      colorFilter: {
        brightness: '亮度',
        contrast: '对比度',
        hueRotate: '色相',
        saturate: '饱和度',
        invert: '反相',
        revert: '恢复',
      },
      layout: {
        vertical: '垂直',
        horizontal: '水平',
        gap: '间距',
      },
      line: {
        startArrow: '起点',
        endArrow: '终点',
        none: '无端点',
      },
      multiText: {
        lineHeight: '行高',
        wrap: '自动换行',
        autoSize: '自动大小',
        indent: '首行缩进',
        vertical: '竖排',
      },
      polygon: {
        sideCount: '边数',
      },
      radius: {
        allRadius: '全部圆角',
        eachRadius: '独立圆角',
        isPercent: '百分比圆角',
      },
      textStyle: {
        richText: '富文本',
        bold: '加粗',
        italic: '斜体',
        underline: '下划线',
        deleteline: '删除线',
        MicrosoftYahei: '微软雅黑',
        pingfang: '苹方',
        SimSun: '宋体',
        FangSong: '仿宋',
        KaiTi: '楷体',
        SimHei: '黑体',
        siYuanHei: '思源黑体',
        siYuanSong: '思源宋体',
        aLiBaBa: '阿里巴巴普惠字体',
        zhanKuGaoDuanHei: '站酷高端黑',
        zhanKuHappy: '站酷快乐体',
        zhanKuKuHei: '站酷酷黑体',
        zhanKuLOGO: '站酷小薇LOGO体',
        zhanKuHuangYou: '站酷庆科黄油体',
        youSheTitle: '优设标题黑',
        youSheGoodBody: '优设好身体',
        yuanQuanYuan: '源泉圆体',
        taiBeiHei: '台北黑体',
        pangMenTitle: '庞门正道标题体',
        pangMenBold: '庞门正道粗书体',
        letterSpacing: '字间距',
        olist: '编号',
        ulist: '项目符号',
        fitWidth: '自动宽度',
        fitHeight: '自动高度',
        fitBoth: '固定尺寸',
        capitalization: '词首大写',
        uppercase: '大写',
        lowercase: '小写',
      },
      stroke: {
        thickness: '宽度',
        dash: '虚线',
        gap: '间隔',
        buttCap: '平头端点',
        roundCap: '圆头端点',
        squareCap: '矩形端点',
        miterJoin: '斜接连接',
        roundJoin: '圆角连接',
        bevelJoin: '斜面连接',
        centerStroke: '居中描边',
        innerStroke: '内描边',
        outerStroke: '外描边',
      },
      keyboard: {
        letter: '字母',
        numeric: '数字',
        symbol: '符号',
      },
      direction: {
        top: '上',
        left: '左',
      },
      inputModel: {
        normal: '文字',
        numeric: '数字',
        password: '密码',
      },
      tree: {
        levelIndent: '缩进',
        treeExpand: '按钮',
        treeNode: '图标',
        treeCheckbox: '复选框',
        itemText: '项目',
        triangle: '三角',
        plus: '加号',
        arrow: '箭头',
      },
      table: {
        rowHeight: '行高',
        columnWidth: '列宽',
        border: '边框',
        fill: '填充',
        rowLine: '行线',
        columnLine: '列线',
      },
      fixContent: '各状态文本内容保持一致',
      dateType_normal: '日期',
      dateType_range: '日期区间',
      web: '网页',
      phone: '手机',
      dateformat_normal: 'yyyy-mm-dd',
      dateformat_secondStyle: 'yyyy/mm/dd',
      dateformat_thirdStyle: 'yyyy年mm月dd日',
      dateformat_fourStyle: 'dd.mm.yyyy',
      timePickerModelNormal: '时间',
      timePickerModelRange: '时间区间',
      legendPosition_bottom: '底部',
      legendPosition_top: '顶部',
      legendPosition_left: '左侧',
      legendPosition_right: '右侧',
      number: '数值',
      percent: '百分比',
      pie_normal: '饼图',
      pie_abNormal: '环形图',
      barChart: '柱状图',
      barChartHorizontal: '条形图',
      areaChart: '面积图',
      radarChart: '雷达图',
      lineChart: '折线图',
      scatter: '散点图',
    },
    // 页面属性
    page: {
      resetSize: '设置为默认尺寸',
      selectArtboard: '选择了 {0} 个画板',
    },
    // 交互
    interaction: {
      pageName: '（{0}）', // 页面名称
      fragmentAction: '画板交互',
      invalidTarget: '失效目标',
      invalidState: '失效状态',
      invalidFragment: '失效画板',
      addAction: '添加交互',
      home: '首页',
      back: '上一页',
      actionOrder: '命令',
      autoRevert: '自动还原',
      parallel: '同时开始',
      compCommand: '[{0}] {1}: {2}',
      pageCommand: '跳转到页面: {0}',
      fragmentCommand: '显示画板: {0}',
      closeFragmentCommand: '关闭画板: {0}',
      linkCommand: '打开链接: {0}',
      eventType: '触发',
      command: '命令',
      selectTarget: '目标',
      currentComp: '自己',
      currentFragment: '此画板',
      switchPage: '页面跳转',
      closeArtboard: '关闭画板',
      popUpArtboard: '显示画板',
      loopSwitch: '循环切换',
      triggerconditions: {
        click: '点击后',
        doubleClick: '双击后',
        contextMenu: '右键后',
        mouseDown: '鼠标按下后',
        mouseUp: '鼠标松开后',
        mouseEnter: '鼠标进入后',
        mouseLeave: '鼠标离开后',
        swipeDown: '下滑后',
        swipeLeft: '左滑后',
        swipeUp: '上滑后',
        swipeRight: '右滑后',
        loaded: '载入时',
        afterMove: '移动后',
        afterZoom: '缩放后',
        afterRotate: '旋转后',
        afterVisible: '显示后',
        afterHide: '隐藏后',
        afterResize: '调整尺寸后',
        checked: '选中后',
        unChecked: '取消选中后',
        toggleCheck: '选中改变后',
        focus: '获取焦点后',
        blur: '失去焦点后',
        scroll: '滚动时',
        on: '开启',
        off: '关闭',
        toggle: '开启/关闭',
      },
      // 交互描述
      pageIntro: '{0}，跳转页面{1}',
      fragmentIntro1: '{0}，关闭{1}',
      fragmentIntro2: '{0}，显示{1}',
      compIntro: '{0}，对{1}执行{2}',
      compIntro2: '{0}，滚动到{1}',
      openInCurrPage: '在当前窗口打开',

      // 交互参数
      timing: '缓动',
      duration: '时长',
      delay: '延时',
      loop: '循环执行',
      transitions: '效果',
      fromCurrent: '相对值',
      content: '内容',
      width: '宽度',
      height: '高度',
      rotate: '角度',
      state: '状态',
      show: '显示',
      hidden: '隐藏',
      toggle: '切换',
      toggle2: '切换隐藏/显示',
      horizontal: '水平',
      vertical: '垂直',
      both: '同时',
      mode: '位置',
      forceFront: '始终置顶',
      showBackground: '显示遮罩',
      clickExternalClose: '点击遮罩时关闭',
      artboardListTutorialUrl: 'https://help.mockplus.cn/p/358#4',
      siteName: '名称',
      url: '地址',
      removeLink: '删除',
      addLink: '添加',
      selectApp: '选择协作项目',
      noApp: '暂无协作项目',
      noPrototype: '暂无原型稿',
      howToUploadAxureFiles: '如何关联Axure文件？',
      axureLinkTutorialUrl: isPrivateDeployment
        ? `${window.location.origin}${window.apis.helpDomain}/p/379#5`
        : 'https://help.mockplus.cn/p/379#5',
      selectPrototype: '选择原型稿',
      searchPrototype: '搜索',
      noPage: '暂无页面',
      selectPage: '选择页面',
      searchPage: '搜索',
      web: '网页',
      openWithSelf: '在当前窗口打开',

      // 触发方式
      click: '点击',
      doubleClick: '双击',
      contextMenu: '右键',
      mouseDown: '鼠标按下',
      mouseUp: '鼠标松开',
      mouseEnter: '鼠标进入',
      mouseLeave: '鼠标离开',
      loaded: '载入时',
      afterMove: '移动后',
      afterZoom: '缩放后',
      afterRotate: '旋转后',
      afterVisible: '显示后',
      afterHide: '隐藏后',
      afterResize: '调整尺寸后',
      checked: '选中',
      unChecked: '取消选中',
      toggleCheck: '选中改变',
      focus: '焦点',
      blur: '失去焦点',
      scroll: '滚动',
      swipeLeft: '左滑',
      swipeUp: '上滑',
      swipeRight: '右滑',
      swipeDown: '下滑',

      // 交互命令
      move: '移动',
      moveBy: '移动',
      moveTo: '移动到',
      resize: '调整尺寸',
      resizeBy: '尺寸',
      resizeTo: '尺寸至',
      rotation: '旋转',
      rotationBy: '旋转',
      rotationTo: '旋转至',
      toggleVisible: '显示/隐藏',
      zoom: '缩放',
      zoomBy: '缩放',
      zoomTo: '缩放至',
      toggleState: '切换状态',
      switchContent: '切换内容',
      prev: '上一个',
      next: '下一个',

      // 动画效果
      none: '无',
      autoEffect: '自动',
      zoomInRevert: '向内收缩',
      slideToBottomRevert: '底部滑出',
      slideToRightRevert: '右侧滑出',
      slideToLeftRevert: '左侧滑出',
      slideToTopRevert: '顶部滑出',
      fadeInRevert: '淡出',
      spreadInRevert: '翻出',
      pushToLeft: '右侧推入',
      pushToRight: '左侧推入',
      pushToTop: '底部推入',
      pushToBottom: '顶部推入',
      slideToLeft: '右侧滑入',
      slideToRight: '左侧滑入',
      slideToTop: '底部滑入',
      slideToBottom: '顶部滑入',
      fadeIn: '淡入',
      zoomIn: '放大弹出',
      slideFromLeft: '右侧滑出',
      slideFromRight: '左侧滑出',
      slideFromTop: '底部滑出',
      slideFromBottom: '顶部滑出',
      fadeOut: '淡出',
      zoomOut: '缩小退出',
      flipInLeft: '左侧翻出',
      flipInRight: '右侧翻出',
      flipInTop: '顶部翻出',
      flipInBottom: '底部翻出',
      spreadIn: '翻入',

      // 辅画板交互位置模式
      custom: '自定义',
      center: '居中',
      left: '左侧',
      right: '右侧',
      top: '顶部',
      bottom: '底部',

      // 时间曲线
      noTiming: '无',
      linear: '线性',
      easeIn: '加速',
      easeOut: '减速',
      easeInOut: '变速',
      tips: {
        move: '勾选时，相对于当前位置进行移动\n未勾选时，移动到指定位置',
        resize: '勾选时，相对于当前尺寸进行缩放\n未勾选时，调整到指定尺寸',
        scale: '勾选时，相对于当前比例进行缩放\n未勾选时，缩放到指定比例',
        rotate: '勾选时，相对于当前角度进行旋转\n未勾选时，旋转到指定角度',
        loadedDisabled: '仅当交互触发源为画板时可用',
        revertEffect: '自动使用与画板显示时相反的效果',
        showArtboard: '辅助画板',
      },
    },
    remark: {
      pageTitle: '页面备注',
      clear: '清空',
    },
  },
  // 组件状态
  state: {
    state: '状态',
    originalState: '正常',
    componentState: '默认状态 - {0}',
    customState: '自定义状态',
    hover: '悬停',
    pressed: '按下',
    checked: '选中',
    focus: '焦点',
    disabled: '禁用',
    default: '默认',
    addState: '添加状态',
  },
  // 菜单
  menu: {
    applyFill: '应用到填充',
    applyStroke: '应用到边框',
    applyText: '应用到文字',
    align: '顺序',
    bringToFront: '置为顶层',
    frontForward: '上移一层',
    backForward: '下移一层',
    sendToBack: '置为底层',
    sameSize: '调整大小',
    minWidth: '最小等宽',
    maxWidth: '最大等宽',
    minHeight: '最小等高',
    maxHeight: '最大等高',
    addColor: '把颜色存为资源',
    addTypography: '把字符样式存为资源',
    addComponent: '把组件存为资源',
    unlock: '解除锁定',
    lock: '锁定',
    unlockAll: '全部解锁',
    selectionGroup: '选项组',
    flip: '翻转',
    horizontalFlip: '水平翻转',
    verticalFlip: '垂直翻转',
    autoFillText: '自动填充',
    editStyle: '高级编辑',
    path: '布尔运算',
    exclude: '排除',
    subtract: '减去',
    unite: '联合',
    intersect: '相交',
    divide: '取消合成',
    convertToPath: '转为路径',
    detachSymbol: '从资源中脱离',
    cropImage: '裁切',
    crop: '裁剪',
    sliceImage: '切割',
    convertToPanel: '转为面板',
    detachPanel: '从面板中脱离',
    revertOriginSize: '恢复原始尺寸',
    cleanInteraction: '清除交互',
    copyInteraction: '复制交互',
    pasteInteraction: '粘贴交互',
    copyStyle: '复制样式',
    pasteStyle: '粘贴样式',
    exportPicture: '导出图片',
    exportPDF: '导出PDF',
    exportSvg: '导出SVG',
    downloadOriginImage: '下载原图',
  },
  // 演示
  preview: {
    alwaysShowLinkArea: '始终显示链接区域',
    showLinkAreaWhenHovered: '鼠标经过时，显示链接区域',
    autoScreen: '自动适应屏幕',
    noBoundary: '显示画板外内容',
    showRemarkTag: '显示备注编号',
    showNavigationBar: '显示导航条',
    // showControllerPanel: '显示控制面板',
    mobileType: '设备外壳',
    qrcode: '二维码',
    controlPanel: '控制面板',
    remarkPanel: '备注面板',
    noRemark: '此页面暂无备注',
    controlPanelState: {
      expand: '展开',
      collapse: '收起',
      hide: '隐藏',
    },
    home: '回到首页',
    backward: '后退',
    forward: '前进',
    exit: '退出演示',
    shareUrl: '分享演示链接',
    folder: '目录',
    remark: '备注',
    shareRunTitile: '分享演示链接',
    sharePageTip: '请选择分享的项目页面',
    copy: '复制',
    enablePass: '启用密码',
    inputPass: '请设置密码',
    setPassInputError: '请输入字母、数字或者符号',
    setPassEmptyError: '需设置密码后，访问密码才会生效',
    updateSuccess: '设置成功',
    linkText: `演示链接：{0}`,
    pwdText: `访问密码：{0}`,
    iosExitText: '从屏幕边缘右滑退出演示', // 只有中文ios手机端演示退出才有
    projectNotExistOrDeleted: '项目不存在或已经被删除。',
    noPermissionText: '无法查看此项目',
    linkHasNotValidText: '此链接已失效',
    resetLinkSuccess: '重置成功',
    onlyMember: '仅项目成员才能查看',
    loginTip: '请登录后继续',
    xiaoMi: '小米',
    iPhoneBlack: 'iPhone 8 Plus 黑色',
    iPhone: 'iPhone 8 Plus',
    iPhoneX: 'iPhone 14',
    galaxy: '三星',
    huaweiP40: '华为 P40',
    huaweiMate: '华为 Mate 40',
    iPad: 'iPad',
    surfacePro: 'Surface Pro',
    appleWatch38mm: 'Apple Watch 38mm',
    appleWatch40mm: 'Apple Watch 40mm',
    appleWatch42mm: 'Apple Watch 42mm',
    appleWatch44mm: 'Apple Watch 44mm',
    appleWatch45mm: 'Apple Watch 45mm',
    appleWatch49mm: 'Apple Watch 49mm',
    appleWatch: 'Apple Watch',
    appleWatchUltra: 'Apple Watch Ultra',
    watch: '手表',
    vehicle: '车载中控',
    prev: '上一页',
    next: '下一页',
    fullScreen: '适应屏幕',
    fullWidth: '适应宽度',
    more: '更多',
    showControlInterface: '显示控制界面',
    showPagePanel: '显示页面面板',
    hidePagePanel: '隐藏页面面板',
    showRemarkPanel: '显示备注面板',
    hideRemarkPanel: '隐藏备注面板',
    bottomTip: '导航条已自动隐藏，鼠标移入底部即可唤出。',
    autoHideNavPanel: '自动隐藏导航条',
    shareProjectName: '请查看《{0}》',
    prevStep: '上一步',
    nextStep: '下一步',
    gotIt: '知道了',
    guideTips: {
      step1: {
        title: '导航条',
        desc: '导航条现已移至底部。',
      },
      step2: {
        title: '视图',
        desc: '调整面板收展按钮，新增全屏模式。',
      },
      step3: {
        title: '更多',
        desc: '点击修改演示设置。',
      },
      step4: {
        title: '自动隐藏导航条',
        desc: '启用自动隐藏导航条，演示体验更沉浸。',
      },
    },
    setNodeIdsSuccess: '设置成功',
    shareChangeTip:
      '演示界面地址栏中的链接现仅支持项目成员进行访问，老链接不受影响。如需向非项目成员分享，请使用底部工具栏中的分享演示链接功能。',
  },
  // 通用
  general: {
    multipleSelect: '多选',
    ignoreArchiveTip: '不再提示',
    gotIt: '我知道了',
    next: '下一条',
    prev: '上一条',
    back: '返回',
    backResearch: '退出搜索',
    ok: '确定',
    skip: '跳过',
    more: '了解更多',
    details: '查看详情',
    confirm: '确认',
    cancel: '取消',
    close: '关闭',
    delete: '删除',
    clone: '克隆',
    cloneCurrentNode: '仅当前项',
    cloneWithChildren: '含子项',
    copy: '复制',
    cut: '剪切',
    paste: '粘贴',
    selectAll: '全选',
    rename: '重命名',
    edit: '编辑',
    none: '无',
    width: '宽',
    height: '高',
    exit: '退出',
    preserve: '保存',
    or: '或',
    refresh: '刷新',
    download: '下载',
    enterFullScreen: '全屏', // idoc 已翻译
    leaveFullScreen: '退出全屏', // idoc 已翻译
    loading: '加载中...',
    replace: '替换',
    export: '导出',
    exportResourceLibs: '同步导出资源库',
    scale: {
      min: '缩小',
      max: '放大',
    },
  },
  // 警告信息
  alert: {
    refresh: '刷新',
    pageHasBeenDeleted: '抱歉，当前页面已被其他成员删除。',
    makeSureToDeletePage: '确定要删除当前页面吗？',
    makeSureToDeletePageGroup: '确定要删除当前分组吗？',
    networkDisconnected: '抱歉，网络连接异常，请检查网络并刷新页面。',
    afk: '由于长时间未操作，已经保存你的数据并断开连接。\n继续工作请刷新页面。',
    //
    fileTypeError: '文件类型不支持。',
    errorSelectingFileType: '请选择的文件格式：\n{0}',
    failToUpload: '上传失败，请重试。',
    alreadyExistPageAction: '同一触发方式下仅支持一个页面交互或外部链接，确定要替换吗？',
    alreadyExistFragmentAction: '同一触发方式下仅支持一个画板交互，确定替换吗？',
    //
    imageSizeExceed: '抱歉，仅支持 10M 以内的图片。',
    imageDimensionExceed: '抱歉，仅支持 10000 x 10000像素以内的图片。',
    videoSizeExceed: '抱歉，仅支持 10M 以内的视频。',
    moreFileUploading: '正在导入，已完成{0}/{1}，请稍候...',
    moreFileUploadFaiFile: isPrivateDeployment
      ? '抱歉，{0}个文件导入失败。\n请导入10M以内的文件。'
      : '抱歉，{0}个文件导入失败。\n请导入10M以内的图片。',
    moreFileUploadFailFormat: isPrivateDeployment
      ? '抱歉，{0}个文件导入失败。\n请导入以下格式的文件：{1}。'
      : '抱歉，{0}个文件导入失败。\n请导入以下格式的图片：{1}。',
    //
    moreFileUploadFailSize: isPrivateDeployment
      ? '抱歉，{0}个文件导入失败。\n请导入10,000 × 10,000以内的图片。'
      : '抱歉，{0}个文件导入失败。\n请导入10,000 × 10,000以内的图片。',
    //
    moreFileUploadFailAll: isPrivateDeployment
      ? '抱歉，{0}个文件导入失败。\n请导入10M以内且格式正确（{1}）的文件。'
      : '抱歉，{0}个文件导入失败。\n请导入10M以内且格式正确（{1}）的图片。',
    //
    imageUploading: '正在导入，已完成<span>{0}/{1}</span>，请稍候...',

    uploadMaxCount: isPrivateDeployment ? '单次最多导入50个图片或视频。' : '单次最多导入50张图片文件。',
    uploadNetworkError: '网络异常，请检查后重试。', // 这个内容在客户端登录处有重复的
    offlineEditing: '离线编辑中...',
    offlineSaving: '本地保存中...',
    saving: '保存中...',
    saveSuccessfully: '保存成功',
    failToSave: '保存失败',
    unableToCreateNode: '抱歉，网络已断开，不能创建页面或分组。',
    unableToMoveNode: '抱歉，网络已断开，不能移动页面或分组。',
    unableToGroupNode: '抱歉，网络已断开，不能进行页面编组',
    unableToUnGroupNode: '抱歉，网络已断开，不能进行页面解组',
    unableToRemoveNode: '抱歉，网络已断开，不能删除页面或分组。',
    unableToEditNode: '抱歉，网络已断开，不能修改页面或分组。',
    unableToCopyNode: '抱歉，网络已断开，请在网络恢复后创建页面。',
    unableToUpload: '抱歉，网络已断开，请在网络恢复后上传。',
    unableToHideNode: '抱歉，网络已断开，不能隐藏页面。',
    networkReconnectionSuccessful: '网络已重新连接',
    saveOfflineData: '同步离线数据中...',
    manualSaveOfflineData: '数据保存中，请稍候...',
    failedToJoinProjectCollaboration: '加入项目协作失败',
    multiplayerCollaborativeSuccess: '多人协同初始化成功',
    mayCauseCompsToBeDeleted: '当前操作可能导致组件被删除，确定继续吗？',
    selectGroupTarget: '如果你需要选中组，可以这样操作：',
    makeSureToMergeCells: '合并后的单元格将只保留左上角单元格中的值，确定继续吗？',
    trashClearAlert: '清除后不可恢复，确定要清除吗？',
    trashPageAlert: '已删除的页面需恢复后才可编辑。',
    trashRecoverAlert: '确定将所有页面放回原处吗？此操作不支持恢复。',
    headCannotBeMoved: '表头不能移动。',
    cannotMoveToHeader: '不能移动到表头。',
    cannotMoveRow1: '抱歉，不可只移动合并单元格中的部分行，请拆分单元格后再试。',
    //
    cannotMoveRow2: '抱歉，不可将行移动到合并单元格中，请拆分单元格后再试。',
    cannotMoveColumn1: '抱歉，不可只移动合并单元格中的部分列，请拆分单元格后再试。',
    //
    cannotMoveColumn2: '抱歉，不可将列移动到合并单元格中，请拆分单元格后再试。',
    duplicateName: '请勿输入重复的项目或数据名称。',
    offlineAlert: '网络异常，部分功能禁用，请检查网络连接。',
    socketOfflineAlert: '网络异常，部分功能禁用，请尝试使用手动保存',
    networkOfflineAlert: '离线编辑中，部分功能禁用，请检查网络连接。',
    //
    offlineMsg3: '页面尚未加载，请在网络恢复后重试',
    imageCropFail: '图片裁切失败，请重试',
    packageLoading: '文件正在准备中，请稍候...',
    successMessage: '离线演示包打包成功！',
    downloadPreviewDemoZIP: '未能成功下载离线演示包，请重试。',
    pasteInteractionFail: '当前组件不支持部分触发方式，已自动过滤。',
    //
    redundantPage: '同一触发方式下仅支持一个页面交互，已自动过滤同类设置。',
    redundantFragment: '同一触发方式下仅支持一个画板交互，已自动过滤同类设置。',
    redundantPageAndFragment: '同一触发方式下仅支持一个页面交互和画板交互，已自动过滤同类设置。',
    // 音频组件
    audioSizeExceed: '请上传10M以内的mp3文件。',
    unloadDialogMessage: '当前存在尚未保存的编辑内容，确定要退出吗？',
    whoEditingSymbol: '{0}正在编辑此组件资源，请在其结束编辑后再试。',
    denyNestSymbol: '抱歉，不支持在此添加其它引用组件资源，已将其从资源中脱离。',
    //多人编辑
    multiPersonEditingAlert: '此图层已被{0}选中，请在其取消选中后再编辑。',
    //复制粘贴内容画板
    contentPanelEditorPasteContentPanelComp: '抱歉，动态面板中不支持添加内容面板',
    symbolEditorPasteContentPanelComp: '抱歉，引用组件中不支持添加动态面板',
    contentPanelEditorHandleSymbol: '动态面板中暂不支持编辑引用组件资源，请退出动态面板后再试',
    contentPanelUseSymbolContentPanelComp: '抱歉，动态面板中不支持添加内容面板',
    contentPanelResolveFilterContentPanelComp: '动态面板中不支持添加内容面板，已自动过滤相关组件',
    contentPanelDeleteAll: '不可删除所有内容，请至少保留1个',
    denyOpenExternalLink: '抱歉，当前环境中不支持打开外部链接。',
  },
  // 提示信息
  tips: {
    clickCompnentToSwitchState: '点击组件以切换状态',
    interactionPrompt1: '选中组件或画板，点击 “添加交互”',
    interactionPrompt2: '或拖拽组件右上角的链接点，至任意组件或页面',
    interactionPrompt2DT: '或拖拽组件右上角的小红点，至任意画板或页面',
    contentPanelTips:
      '小提示：\n拖拽链接点到一个辅助画板，将其关联为层；\n可关联多个层；\n同一个辅助画板只能被关联一次。',
    contentPanelV2TipsTitle: '双击为动态面板添加内容',
    contentPanelV2TipsContent0: '将动态面板选为交互目标，可使其中的内容',
    contentPanelV2TipsContent1: '进行切换展示',
    contentPanelV2TipsGuide: '查看教程',
    contentPanelValueEditorTips: '暂未关联辅助画板，请点击\n添加按钮或拖动链接点至画板进行关联',
    doubleClickComponent: '双击进入编辑状态',
    inputSiteName: '名称',
    inputUrl: '地址',
    inputMaxLength: '名称不能超过{0}个字符',
    revertLinear: '反转',
    revertRadial: '反转',
    rotationAngle: '调整角度',
    pleaseInput: '请输入{0}',
    copySuccess: '复制成功',
    clickSelectTarget: '点击选择目标',
    selectTarget: '选择触发方式',
    selectCommand: '选择命令',
    selectContent: '选择内容',
    selectState: '选择状态',
    revert: '还原',
    changeProjectTypePrompt1: '项目类型将修改为 {0}',
    changeProjectTypePrompt2: '新建画板会应用新的项目尺寸，现有画板的尺寸不受影响，确定继续吗？',
    zoomOutline: '缩放',
    resizeTo: '调整尺寸至',
    scaleFromTopLeft: '左上扩展',
    scaleFromTopRight: '右上扩展',
    scaleFromBottomRight: '右下扩展',
    scaleFromBottomLeft: '左下扩展',
    scaleFromTop: '顶部扩展',
    scaleFromRight: '右侧扩展',
    scaleFromBottom: '底部扩展',
    scaleFromLeft: '左侧扩展',
    scaleFromCenter: '中心扩展',
    appSizeChangedByOther: '其他成员修改了项目类型，已同步相关设置。',
    //
    artboardPanelTip: '拖拽交互点至画板名称以添加交互',
    noSearchResultsWereFound: '未搜索到结果',
    pageAddOver: '免费版最多支持{0}张页面，升级团队可创建更多页面。',
    personalSpacePageAddOver: '免费版最多支持{0}张页面，升级可创建更多页面。',
    shareSetting: '修改默认演示设置后，演示链接会更新，请重新复制并分享',
    //
    notSearchResult: '未搜索到结果',
    noPage: '暂无页面',

    noResourceResult: '暂无组件资源',
    resourceExpired: '相关资源已被删除或转换，确定将当前组件从资源中脱离以继续编辑吗？',
    //
    tutorial: '查看教程',
    officialWebsite: '访问官网',
    activity: {
      freePrograms: '用摹客RP，永久免费做设计！',
      partInFreePrograms: '👆 点击参与免费计划',
    },
    design: '协作和交付',
    nonPrivateProject: '此项目为团队所有人可见\n请修改为私有项目后，再设置项目成员',
    //
    cannotUseOfflineDome: '', // 中文版不处理
    //
    remoteLoginTips: '账号已在其它设备或浏览器登录。\n如非本人操作，建议前往个人信息页面修改密码。',
    //
    reLogin: '重新登录',
    loginExpired: '当前账号的登录状态已过期，请刷新后再进行操作。',
    logoutTip: '当前账号已退出登录，如需操作请重新登录。',
    // 音频组件和视频组件
    doubleClickUploadVideo: isPrivateDeployment ? '双击上传视频' : '双击添加视频链接',
    doubleClickUploadAudio: isPrivateDeployment ? '双击上传音频' : '双击添加音频链接',
    audioUrlPlaceholder: '请输入音频链接',
    videoUrlPlaceholder: '请输入视频链接',
    invalidAudioUrl: '无法获取音频内容，请检查链接',
    invalidVideoUrl: '无法获取视频内容，请检查链接',
    // 查看教程
    audioEditHelpUrl: 'https://help.mockplus.cn/p/373#18',
    videoEditHelpUrl: 'https://help.mockplus.cn/p/373#17',
    // 引导
    audioVideoEditTutorialUrl: 'https://help.mockplus.cn/p/637',
    audioVideoGuideText: '视频、音频组件现已调整为通过在线链接进行添加。',
    videoEditPlayText: '请前往演示界面进行播放',

    chromeTips:
      '为保证良好的使用体验，建议使用<a href="https://www.google.cn/intl/zh-CN/chrome/" target="_blank">Chrome浏览器</a>访问。',
    loseProjectPermission: '抱歉，你在此项目中的权限发生改变，无法继续编辑。',
    weMeetNotSupportExport: '暂不支持在腾讯会议中进行导出，请在浏览器中进行操作。',
    iconReplaceTip: '在编辑窗口中选中图标，并单击图标库中的图标可进行替换。',
    useEscExit: '使用Esc即可退出{0}模式',
    featureChangeTitle: '功能变更提示',
    publishChangeTip: '支持发布至协作项目的不同原型稿，版本管理更方便。',
    appHasDeleteByCC: '相关协作项目已被删除，无法查看。',
    appHasDeleteByCreateApp: '所选协作项目已被删除，无法发布。',
    appArchivedLimit: '所选协作项目已被归档，请激活后重试。',
    hideAllPageTip: '此项目暂无页面或所有页面都被隐藏，至少需要一张未隐藏的页面才可进行操作。',
    svgParsing: '图片转换中，请稍候...',
    svgParseSuc: '图片转换成功',
    svgParseErr: '图片转换失败',
  },
  placeholders: {
    renameHolder: '页面名称',
    textInputHolder: '请输入文本内容',
    selectHolder: '请选择',
  },
  lark: {
    larkAppID: 'cli_9d2d59be33e0910c',
    previewPrompt: '邀请你查看摹客项目演示，点击链接即可进入演示。',
    openInLark: '在飞书打开：',
    openInBrowser: '在浏览器打开：',
  },
  extend: {
    chrome: {
      noExtend: {
        colorExtractor: '吸管工具仅支持在Chrome浏览器上使用，需安装插件。',
      },
      tutorialURL: isPrivateDeployment
        ? `${window.location.origin}${window.apis.helpDomain}/p/370#6`
        : 'https://help.mockplus.cn/p/370#6',
      downloadUrl: 'https://img02.mockplus.cn/idoc/plugins/mockplus_for_chrome_v1.0.3.zip',
    },
    tutorial: '查看教程',
    download: '下载插件',
    version: '插件版本过低，请下载安装最新版本。',
  },
  login: {
    title: '密码登录',
    accountPlaceHolder: '邮箱或摹客账号',
    passwordPlaceHolder: '密码',
    noAccount: '还没有账号？',
    registerNow: '立即注册',
    forgetPassword: '忘记密码',
    loginBtn: '登录',
    emailValidate: '请输入格式正确的邮箱。',
    passwordValidate: '密码长度为6-20。',
  },
  register: {
    title: '注册账户',
    passwordValidateAgin: '两次密码输入不相同。',
    agreementURL: 'https://www.mockplus.cn/tos',
    accountPlaceHolder: '请输入注册邮箱',
    passwordPlaceHolder: '请输入登录密码',
    passwordAginPlaceHolder: '请再次输入密码',
    hadRead: '我已仔细阅读并同意',
    agreement: '《摹客使用协议》',
    register: '注册',
    hadAccount: '已有账号？',
    loginNow: '立即登录',
    readValidate: '请阅读并同意协议。',
  },
  example: {
    label: '模板例子',
    tip: {
      partOne: '你正在体验摹客RP，',
      partTwo: '或',
      partThree: '保存本项目至当前团队。',
      partFour: '点击保存',
      partFive: '本项目至当前团队。',
    },
    saving: '项目保存中...',
    savingError: '保存失败，请重新尝试',
    type: {
      all: '全部',
    },
    nocontent: '暂无内容',
    guideEntryDescription: '查看例子项目',
    noPermission: '抱歉，你在当前团队的身份为协同人，不能进行此操作。',
    noTeams: '你尚未加入任何团队，请联系管理员，或切换账号。',
    appQuantityExceedsLimit: '项目数量已达上限，请联系管理员处理。',
    saveExampleTitle: '保存模板例子',
    pleaseCreateTeam: '请创建一个团队以继续',
    placeHolderTeam: '请输入团队名称',
    saveExampleTips: '你在此团队中创建的项目数已达{0}个，请升级或更换团队后再试。',
    upgrade: '立即升级',
  },
  selectionGroup: {
    selectionGroupSetting: '选项组设置',
    rowCount: '行数',
    columnCount: '列数',
    rowGap: '行间距',
    columnGap: '列间距',
    iconEditButton: '点击切换到图标库以选择图标',
    imageEditButton: '点击上传图片',
  },
  // 定制功能
  customization: {
    huawei: {
      standard: {
        tips: '设计规范',
        label: '体验规范',
        management: '管理侧规范',
        officialWebsite: '官网规范',
        mobile: '移动端规范',
        operationFlow: '业务流程规范',
      },
      libraries: {
        icon: '图标资源',
        image: '图片资源',
      },
      guide: '使用指南',
      tutorial: '指导教程',
    },
  },
  share: {
    label: '分享',
    shareApp: '分享此项目',
    privateShare: '私密分享',
    publicShare: '公开分享',
    resetLink: '重置链接',
    availableResetLink: '如需重置链接，请先取消密码',
    privateShareTips: '适用于多人编辑、评审和交付，仅项目成员可打开链接。',
    publicShareTips: '仅用于演示和预览设计，所有人都可打开链接。',
    publicShareTipsInPrivateVersion: '仅用于演示和预览设计，登录后即可打开链接。',
    //
    copyLink: '复制链接',
    copySuccess: '复制成功',
    inviteTips: '邀请有效期为7天，过期后项目成员仍可以打开链接',
    inviteToApp: '同时邀请对方成为项目成员',
    notCanInviteToApp: '如果对方不是此项目成员，不能查看项目',
    onlyShareCurrentModel: '仅分享当前版块',
    iframeShare: '嵌入分享',
    iframeShareTips: '用于嵌入第三方应用，所有人都可打开链接。',
    shareModule: {
      prototypeModule: '原型稿',
      designModule: '设计稿',
      rpModule: 'RP设计稿',
    },
    shareContent: {
      label: '内容',
      idocContent: '画板界面',
      previewContent: '演示界面',
    },
  },
  message: {
    label: '消息和申请',
    teamMessage: '项目消息',
    aboutMe: '与我相关',
    apply: '申请消息',
    markRead: '标记已读',
    oneClickRead: '一键已读',
    linkToAll: '查看全部',
    hadRead: '已读',
    applyToViewApp: '申请',
    agree: '同意',
    disagree: '拒绝',
    agreed: '已同意',
    disagreed: '已拒绝',
    remark: '备注：',
    noContent: '暂时没有新消息',
    memberPrivilege: '编辑权限',
    guestPrivilege: '评论/查看标注权限',
    observerPrivilege: '预览权限',
    joinTheTeamBy: '以{0}身份加入团队',
    approve: '同意审批',
    upgradeTips: '团队协作成员数量已达上限，请升级团队或将申请者以访客身份加入团队。',
    //
    expansionTips: '团队协作成员数量已达上限，请扩容团队或将申请者以访客身份加入团队。',
    //
    payTeamExpired: '你的团队已过期，续费后即可添加更多协作成员，当前仍可邀请访客加入。',
    agreeAsObserver: '同意以访客身份加入',
    observerTip: '访客仅有预览权限',
    consumeDescription: '每人仅需 {0} 元 /天',
    agreeToJoin: '同意加入',
    renewNow: '立即续费',
    logOffTeamTip: '你的账号将于{0}天{1}小时后注销。',
    recallCancellation: '撤回注销',
    recallSuccess: '撤回成功',
    createAppLimitByFree: '当前项目数量已达{0}个，请参加免费计划或升级团队后再试。',
  },
  // 没有引文版
  contact: {
    leftTop: '你好，我是',
    name: 'Thomas',
    addMe: '立即扫码加我吧！',
    role: '摹客百科全书级\n产品顾问',
    bottom: '或邮件至: service@jongde.com',
  },
  crashError: {
    title: '抱歉，出现异常问题',
    label: '请尝试刷新，如果问题反复出现，请',
    labelNoReset: '如需帮助，请',
  },
  file: {
    importMrpLabel: '导入RP项目文件',
    importMrpTitle: '正在导入RP项目文件，请稍候…',
    importError: '抱歉，导入失败。如需帮助，请',
    importErrorEnd: '。',
    importSuccess: '导入成功',
    importMrpFileError: '无效的文件类型，请选择RP项目文件进行导入。',
    importNetworkError: '网络异常，导入失败，请检查网络后重试。',
    exportMrpLabel: '导出RP项目文件',
    exportMrpTitle: '文件正在准备中，请耐心等待，可能需要几分钟。',
    exportError: '抱歉，导出失败，请重试。',
    exportMrpSuccess: 'RP项目文件打包成功，请点击下载。',
    exportMrpRoleLimit: '此功能仅限团队超级管理员及管理员可用，如有需要请联系相关人员。',
    //
    upgradeToExportMrp: '',
    enUpgradeToExportMrp: '',
    enUpgradeToDownloadDemo: '',
    //
    selected: '已选',
    selectProjectPage: '请选择导出的项目内容',
  },
  support: {
    label: '联系我们',
    url: 'https://www.mockplus.cn/support',
  },
  indexedDBError: {
    tip: '检测到本地存储错误，数据可能无法正常保存。',
    howToResolve: '如何解决',
    url:
      isPrivateDeployment && !isHuawei
        ? `${window.location.origin}${window.apis.helpDomain}/p/641`
        : 'https://help.mockplus.cn/p/641',
  },
  manualSave: {
    saveVersion: '保存页面版本',
    save: '保存',
    saving: '保存中...',
    saveSuccessfully: '保存成功',
    savingError: '保存失败，请重试',
    offlineEditing: '离线中...',
  },
  newFeature: {
    link: 'https://www.mockplus.cn/product/releasenotes?product=rp',
    detailsLink: 'https://help.mockplus.cn/p/637',
    title: '更新内容',
    content0: '新增垂直选项卡组件。',
  },
  // RP客户端登录
  clientLogin: {
    comfirm: '确认',
    cancel: '取消',
    switchEnterpriseLogin: '切换至企业登录',
    switchNormalLogin: '切换至普通登录',
    loginPlaceHolder: '请输入摹客访问网址',

    loginIndex: {
      title: '摹客RP',
      subTitle: '让设计更快更简单',
      openLogin: '通过浏览器登录',
      register: '立即注册',
    },
    loginWait: {
      title: '请在浏览器完成登录操作',
      subTitle: '已完成登录？请',
      click: '点击这里',
    },
    notLogin: {
      title: '你还未完成登录操作',
      subTitle: '请在浏览器中完成登录后',
      retry: '点击重试',
    },
    loginFail: {
      title: '抱歉，登录失败',
      retry: '点击重试',
    },
    loginComplete: {
      title: '登录成功',
    },
    tips: {
      loginFail: '未检测到登录成功，请稍后再试!',
      createError: '创建失败',
      helper: '在使用摹客应用时，浏览器地址中的 {0} 或 {1} 就是访问网址',
      domain: '“域名”',
      ip: '“IP+端口号”',
      formatError: '格式不正确',
      urlError: '服务器地址不正确。',
    },
    guest: {
      cannotEdit: '协同人或访客无编辑权限',
      prev: '上一步',
    },
  },
  guestCanNotCreateApp: '协同人或访客无编辑权限，请联系团队管理员\n更改身份或切换团队后再试。',

  permission: {
    rpEditor: 'RP编辑者',
    rpViewer: 'RP查看者',
    permissionLabel: '设计权限',
    perpetualTip: '当前为终身特权团队，不支持添加更多协作成员。',
  },
  // 中文版没有个人空间
  personalSpace: {
    moveProject: '移动',
    memberTips: '抱歉，个人空间中的项目不支持邀请成员。将项目移动至团队空间即可体验多人实时编辑。',
    publishDialogTitle: '个人空间不支持发布项目至摹客协作平台',
    publishDialogDescribe: '将项目移动至团队空间即可发布项目，体验流畅的项目协作与交付',
    publishDialogItem1P1: '全貌画板',
    publishDialogItem1P2: '全览项目<br/>绘制页面流程图',
    publishDialogItem2P1: '团队评审',
    publishDialogItem2P2: '与团队成员一起<br/>发表、管理项目意见',
    publishDialogItem3P1: '交付开发',
    publishDialogItem3P2: '查看图层数据<br/>复制前端代码',
    publishDialogItem4P1: '更多',
    publishDialogItem4P2: '管理三方设计稿<br/>在线文档、任务管理',
    axureTips: '个人空间不支持关联Axure项目<br/>请移动项目至团队空间后再试',
    moveProjectBtn: '移动',
    all: '全部',
    sureMove: '确定要将此项目移动至{0}中的"{1}"下吗?',
    moveProjectSubTitle: '请选择项目移动的目标位置',
    moveProjectSelectorTitle: '团队',
    moveProjectAppSetsTitle: '项目集',
    search: '搜索',
    noResult: '没有符合条件的结果',
    moveLimit: '抱歉，仅支持将项目移动至你的身份为成员及以上的团队，请创建或加入相关团队后再试。',
    fullAppLimit: '目标团队项目已满，请升级或续费后再试。',
    moveSuccessful: '移动成功',
    createAppLimit: '当前项目数量已达{0}个，请升级团队后再试。',
    createAppPersonalSpaceLimit: '当前项目数量已达{0}个，请升级后再试。',
    createAppUpperLimit: '{0}项目数量已达上限（{1}个），请联系{2}升级以解除限制。',
    createPublicAppUpperLimit: '所有设计稿数量已达上限，请升级团队后再试。',
  },
  limit: {
    createAppUpperLimit: 'RP体验项目数量已达上限（{0}个），请联系{1}升级以解除限制。',
    privatePageTip: 'RP体验页面数量已达上限（{0}张），请联系{1}升级以解除限制。',
  },
  upgrade: {
    alreadyUpgradeConfirm: '如已完成升级，请点击下方按钮进行确认。',
    success: '升级成功，感谢选择摹客RP！',
    failButHasPayTeam: '抱歉，此空间尚未完成升级。你的团队“{0}”正在付费使用中，你可以将项目移至该团队进行使用。',
    failButHasPersonalTeam: '抱歉，此空间尚未完成升级。你的个人空间正在付费使用中，你可以将项目移至个人空间进行使用。',
    fail: '抱歉，此空间尚未完成升级。如有问题，请与我们联系。',
    startUse: '开始使用',
    alreadyUpgrade: '我已升级',
    howToMove: '如何移动',
    moveHelpUrl: 'https://help.mockplus.cn/p/354#5',
  },
  designProject: {
    type: '类型',
    administrator: '管理员',
    sampleProject: 'RP体验',
    mockProject: '摹客设计稿',
    allProject: '所有设计稿',
    joinProject: '协作',
    rpProjectDesc: '仅支持RP设计稿',
    rpDTProjectDesc: '仅支持RP/DT设计稿',
    allProjectDesc: '支持Sketch/Figma/XD/PS/Axure/RP/DT设计稿',
    allProjectNoDTDesc: '支持Sketch/Figma/XD/PS/Axure/RP设计稿',
  },
  clipboard: {
    // noSurpport: '您当前浏览器不支持使用系统剪切板~', // 暂不用
    safeCopyText: '已复制摹客RP项目数据，请在编辑界面中进行粘贴。',
    // denyRead: '不允许读取系统剪切板数据', // 暂不用
    // denyWrite: '没有开启剪切版写入权限', // 暂不用
    // denyCopyData : '不被允许写入的数据类型', // 暂不用
    // denyPasteMode: '不支持右键粘贴' // 暂不用
  },

  autoFill: {
    title: '智能填充',
    noneComponentFill: '请选中需要填充的图层',
    notAllowFill: '抱歉，所选目标不支持进行文本填充',
    notAllowFillImg: '请选中需要填充的图片图层或轮播图',
    type: {
      text: '文字',
      img: '图片',
    },
    text: {
      people: {
        name: '人名',
        male: '男',
        female: '女',
        both: '综合',
      },
      phone: {
        name: '电话',
        mobile: '手机',
        tel: '座机',
      },
      number: {
        name: '数字',
        price: '金额',
      },
      email: '邮箱',
      time: {
        name: '时间',
        hms: '时分秒',
        hm: '时分',
        ms: '分秒',
      },
      date: {
        name: '日期',
        ymd: '年月日',
        ym: '年月',
        md: '月日',
        week: '星期',
      },
      location: {
        name: '位置',
        country: '国家',
        province: '省份',
        city: '市区',
        nativePlace: '街道门牌号',
      },
      content: '段落',
      serial: {
        name: '序号/编号',
        asc: '正序',
        desc: '倒序',
        identity: '身份证',
        serial: '序列号',
      },
    },
    image: {
      people: '人物',
      office: '商务',
      animal: '动物',
      science: '科技',
      scenery: '风景',
      food: '美食',
      edifice: '建筑',
      misc: '综合',
      clothing: '服饰',
    },
    info: '!本功能由摹客RP团队提供',
    ai: {
      textPlaceholder: '请描述你想要的文本',
      imgPlaceholder: '请描述你想要的图片',
    },
  },
  mockAI: {
    historyTips: '历史记录',
    copy: '复制结果',
    copySuccess: '复制成功',
    tutorial: '查看教程',
    tutorialLink: 'https://help.mockplus.cn/p/650',
    logoTips: '小摹AI',
    coming: '即将上线',
    creating: '全力生成中，请耐心等待…',
    cost: '本次消耗',
    unit: '{0}pt',
    back: '返回',
    view: '立即查看',
    generateError: '抱歉，检测到生成错误',
    errorPlaceholder: '本次操作不消耗点数',
    Erorr3101: '抱歉，小摹暂无法完成内容生成，请稍后再试',
    Erorr3102: '文本生成失败，本次操作不消耗点数，请重试',
    tips: {
      creating: '关闭窗口将终止生成操作（本次生成不消化点数），确定要终止吗？',
      confirm: '终止',
      cancel: '继续生成',
      notEnough: '团队点数不足，请联系团队管理员获得更多点数后重试。',
      generateText1: '右键菜单的智能填充功能，也支持AI生成文本或图片内容',
      generateText2: '生成原型后，配合智能填充功能，可以快速实现高保真原型设计',
      generateText3: '内测期间，小摹会不定期向大家发放使用点数',
      generateText4: '内容生成期间，如果手动停止，不会消耗点数',
      generateText5: '如果对小摹的服务有任何意见或建议，请反馈给摹客的小伙伴',
    },
    prototype: {
      title: '智能原型',
      desc: '生成可编辑的移动端原型',
      start: '生成页面',
      complete: '原型页面已生成',
      inputPlaceholder: '请描述你想要的页面，或点击上方的示例',
      placeholder1: { title: '简洁个人博客', value: '风格简洁的个人博客页面，包含文章列表和读者反馈' },
      placeholder2: { title: '登录注册页', value: '常规的登录注册页面' },
      placeholder3: { title: '手机生鲜商城', value: '生鲜电商平台的手机端首页' },
      options: {
        tip: '填充设置',
        placeholder: '占位符',
        grey: '灰度图片',
        colorful: '彩色图片',
      },
    },
    text: {
      title: '智能文本',
      desc: '轻松撰写文案、描述、PRD…',
      start: '生成文本',
      complete: '文本已生成',
      inputPlaceholder: '请描述你想要的文本，或点击上方的示例',
      placeholder1: { title: '数码商品详情介绍', value: '对某一款数码商品进行简单介绍' },
      placeholder2: { title: '社交APP用户反馈', value: '一款社交类APP在应用商店中的用户反馈' },
    },
    image: {
      title: '智能图片',
      desc: '按需生成你想要的图片',
      start: '生成图片',
      complete: '图片已生成',
      inputPlaceholder: '请描述你想要的图片，或点击上方的示例',
      placeholder1: { title: '团队办公图片', value: '企业风格的员工协作办公图' },
      placeholder2: { title: '夏日海边度假旅行', value: '充满夏日气息的海边度假风景图' },
    },
    chart: {
      title: '智能图表',
      start: '生成图表',
      complete: '图表已生成',
      desc: '生成多种数据可视化图表',
      inputPlaceholder: '请描述你想要的图表内容，或点击上方的示例',
      placeholder1: { title: '个人支出统计', value: '统计个人上半年的消费支出情况' },
      placeholder2: { title: '市场份额一览', value: '展示某家电品牌的市场份额' },
      placeholder3: { title: '销量与利润表', value: '绘制一个能同时展示销量和利润的图表' },
    },
    table: {
      title: '智能表格',
      start: '生成表格',
      complete: '表格已生成',
      inputPlaceholder: '请描述你想要的表格内容，或点击上方的示例',
      desc: '生成包含特定内容的表格',
      placeholder1: { title: '工作计划表', value: '规划最近一周的工作内容表' },
      placeholder2: { title: '库存报表', value: '货物的库存数据报表' },
      placeholder3: { title: '员工一览表', value: '包含员工的姓名和基本信息的一览表' },
    },
    translate: {
      title: '智能翻译',
      desc: '快速翻译页面或文字内容',
      start: '开始翻译',
      complete: '画板翻译成功',
      artBoard: {
        title: '画板翻译',
        supportLanguage: '仅支持"中译英"',
        displayMessages: '结果将以新页面展示',
        startPlaceholder: '请输入待翻译的中文内容',
        endPlaceholder: '英文结果将显示在这里',
      },
      input: '输入内容',
    },
  },
  tip618: '618钜惠，升级低至6折，终身版立减￥300！',
};

export default zh_cn;
