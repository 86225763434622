import * as React from 'react';
import classnames from 'classnames';
import { clamp, isEqual, unionWith } from 'lodash';

import { IFill, getFillToolColorString, transColorToRGBA, Color } from '@utils/graphicsUtils';

import { Icon, ScrollBars, PopupMenu, IMenuItem, Tooltip } from '@dsm';

import i18n from '@i18n';
import { IPresetColor } from '@fbs/rp/models/grid';
import { IPosition } from '@fbs/common/models/common';
import { FillType } from '@fbs/rp/models/properties/fill';
import { getNewID } from '@helpers/idHelper';
import appOptions from '@helpers/appOptions';

import { renderClipFill } from '../../../libs/basic/common/GradientFragment';

import './index.scss';

enum Operate {
  Delete,
}

const MAX_COL_COUNT = 8;
const MAX_ROW_COUNT = 3;
const ADD_ICON = 1;
const VIEWED_ITEM_COUNT = MAX_ROW_COUNT * MAX_COL_COUNT - ADD_ICON;

interface IProps {
  colors: IPresetColor[];
  value: IFill;
  className?: string;
  disabledGradient?: boolean;
  onChange: (color: IFill) => void;
  onPatch?: (value: IPresetColor[]) => void;
}

interface IState {
  popupPosition?: IPosition;
  popupData?: IPresetColor;
  // 正在新增的颜色ID 用于闪烁效果
  addColorID?: string;
}

class PreferenceColors extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    const cacheColors = [...appOptions.favoriteColors];
    const { colors: oc } = this.props;
    // 10-27之前的数据 10-27~10-28的数据需要重新提交
    if (cacheColors.length > 0 || oc.some((c) => c.color)) {
      const colors = oc.map(({ id, name, value, color }) => ({
        // 10-27~10-28的数据类型： { id: string, name: string, color: PureColor }
        id,
        name,
        value: value || { type: FillType.solid, color },
      }));
      // 10-27之前的数据类型: IFill[]
      const newColors: IPresetColor[] = cacheColors
        .filter((ite) => colors.every((c) => !isEqual(ite.color, c)))
        .map((ite) => ({ id: getNewID(), name: '', value: ite }));
      this.doPatch(newColors.concat(colors));
      appOptions.favoriteColors = [];
    }
  }

  private doPatch(value: IPresetColor[]): void {
    this.props.onPatch?.(value);
  }

  /**
   * 新增常用颜色
   * 相关策略 https://redmine.jongde.com/issues/83212
   * FIXME 前三排颜色重复添加时在当前位置闪烁 否则往第一个放 啧啧啧
   * 彬哥严格把关 双击关注666
   */
  private handleAddColor = (): void => {
    const { colors, value } = this.props;
    const addColorID = getNewID();
    this.setState({ addColorID });
    value.color = transColorToRGBA(value.color as Color); // 统一格式：字符串转换为r,g,b,a
    const newColors = unionWith(colors, this.isEqualPresetColor);
    const newData: IPresetColor = {
      id: addColorID,
      name: '',
      value,
    };
    const viewedItems = newColors.slice(0, VIEWED_ITEM_COUNT);
    const viewedIndex = viewedItems.findIndex((item) => isEqual(item.value, value));
    if (viewedIndex === -1) {
      newColors.unshift(newData);
    } else {
      newColors.splice(viewedIndex, 1, newData);
    }
    this.doPatch(newColors);
  };

  private isEqualPresetColor(prev: IPresetColor, curr: IPresetColor): boolean {
    return isEqual(prev.value, curr.value);
  }

  private handleChange = (color: IPresetColor, e: React.MouseEvent): void => {
    const LEFT_BUTTONS = 1;
    // const RIGHT_BUTTONS = 2;
    if (e.buttons === LEFT_BUTTONS) {
      this.props.onChange?.(color.value);
    }
  };

  private handleOperate = (color: IPresetColor, e: React.MouseEvent): void => {
    this.setState({
      popupPosition: { x: e.pageX, y: e.pageY },
      popupData: color,
    });
  };

  private doClosePopup(): void {
    this.setState({ popupPosition: undefined, popupData: undefined });
  }

  private handleClosePopup = (): void => {
    this.doClosePopup();
  };

  private handleItemClick = (item: IMenuItem): void => {
    switch (item.id) {
      case Operate.Delete: {
        const { colors } = this.props;
        this.doPatch(colors.filter((ite) => ite.id !== this.state.popupData?.id));
        this.doClosePopup();
        break;
      }
      default:
        break;
    }
  };

  private renderColorItem = (item: IPresetColor): React.ReactNode => {
    const { id, value } = item;
    const option = {
      id,
      type: 'favorite',
      size: {
        width: 18,
        height: 18,
      },
      fill: value,
      scale: 1,
    };
    const blink = this.state.addColorID === id;
    return (
      <Tooltip text={getFillToolColorString(item.value)} key={id}>
        <div
          className={classnames('dsm-c-rp-preference-color-item', { blink })}
          onMouseDown={this.handleChange.bind(this, item)}
          onContextMenu={this.handleOperate.bind(this, item)}
        >
          <svg
            version="1.2"
            width={option.size.width}
            height={option.size.height}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {renderClipFill(option, <rect width={option.size.width} height={option.size.height} />)}
          </svg>
        </div>
      </Tooltip>
    );
  };

  private renderPopup(): React.ReactNode {
    const { popupPosition, popupData } = this.state;
    if (!popupPosition || !popupData) {
      return null;
    }
    const items: IMenuItem[] = [{ id: Operate.Delete, text: i18n('general.delete') }];
    return (
      <PopupMenu
        items={items}
        width={90}
        position={popupPosition}
        onClose={this.handleClosePopup}
        onItemClick={this.handleItemClick}
      />
    );
  }

  private itemFilter = (item: IPresetColor): boolean => {
    // 数据更新 不显示老数据
    if (!item.value) {
      return false;
    }
    return item.value.type === FillType.solid || !this.props.disabledGradient;
  };

  public render(): React.ReactNode {
    const { className, colors } = this.props;
    const row = Math.ceil((colors.length + 1) / MAX_COL_COUNT);
    const itemWidth = 20;
    const padding = 8;
    const maxHeight = itemWidth * MAX_ROW_COUNT + padding * 2;
    return (
      <React.Fragment>
        <ScrollBars
          hiddenHorizontalScrollBar
          className={classnames(className, 'dsm-c-rp-preference-color-container')}
          style={{ height: clamp(row * (itemWidth + padding) - padding, itemWidth, maxHeight) }}
        >
          <div className={classnames('dsm-c-rp-preference-color')}>
            <Icon onClick={this.handleAddColor} cls="add_normal" />
            {colors.filter(this.itemFilter).map(this.renderColorItem)}
          </div>
        </ScrollBars>
        {this.renderPopup()}
      </React.Fragment>
    );
  }
}

export default PreferenceColors;
