import * as React from 'react';

import classNames from 'classnames';

import { Direction } from '@/consts/enums/direction';

import { IAreaStyle } from '..';
import CropBox from './CropBox';

interface IImgPreviewProps {
  url?: string;
  isSpaceKey?: boolean;
  imageBounds?: IAreaStyle;
  imageWrapperRef?: React.RefObject<HTMLDivElement>;
  imageRef?: React.RefObject<HTMLImageElement>;
  viewPortSize: { width: number; height: number };

  showCropArea?: boolean;
  cropBounds?: IAreaStyle;
  cropRef: React.RefObject<HTMLDivElement>;

  onImgWrapperMouseDown?: (e: React.MouseEvent) => void;
  onImageBoxWheel?: (e: React.WheelEvent) => void;

  onDoCalcPercent?: (num: number) => string;
  onCropAreaMouseDown?: (e: React.MouseEvent) => void;
  onDragPointsMouseDown?: (type: Direction, e: React.MouseEvent) => void;
  onResetCropArea?: () => void;
}

export default React.memo(function ImgPreview(props: IImgPreviewProps) {
  const {
    url,
    imageBounds,
    isSpaceKey,
    imageWrapperRef,
    imageRef,
    viewPortSize,
    showCropArea,
    cropBounds,
    cropRef,
    onImgWrapperMouseDown,
    onImageBoxWheel,
    onDoCalcPercent,
    onCropAreaMouseDown,
    onDragPointsMouseDown,
    onResetCropArea,
  } = props;
  const { left, top, width, height } = imageBounds || { left: 0, top: 0, width: 0, height: 0 };
  const style: React.CSSProperties = {
    left: 0,
    top: 0,
    transform: `translate3d(${left}px, ${top}px, 0)`,
    width,
    height,
  };

  return (
    <div
      className={classNames({ 'snapshot-file-box': true, 'drag-move': isSpaceKey })}
      onMouseDown={onImgWrapperMouseDown}
      onWheel={onImageBoxWheel}
    >
      <div className="image-container" ref={imageWrapperRef} style={{ ...viewPortSize }}>
        <div className="preview-image" style={style} ref={imageRef}>
          {url && <img src={url} alt="image" />}
        </div>
        <div className="preview-image crop-box-container" style={style}>
          <CropBox
            isSpaceKey={isSpaceKey}
            imageBounds={imageBounds}
            showCropArea={showCropArea}
            cropBounds={cropBounds}
            cropRef={cropRef}
            viewPortSize={viewPortSize}
            onDoCalcPercent={onDoCalcPercent}
            onCropAreaMouseDown={onCropAreaMouseDown}
            onDragPointsMouseDown={onDragPointsMouseDown}
            onResetCropArea={onResetCropArea}
          />
        </div>
      </div>
    </div>
  );
});
