import * as React from 'react';
import classnames from 'classnames';
import { isEqual } from 'lodash';

import { getNewPathPropertiesByScalingTheStrokeAndShadow } from '@/helpers/pathHelper';
import { StyleHelper } from '@/helpers/styleHelper';

import { UIConnectorComponent, IConnectorProperties } from '@/editor/comps';
import { getPathStartOrEndTwoPoint, parseLineStr, removePathPointByLine } from '@/helpers/pathFinderHelper';
import { IPosition, ISize } from '@/fbs/idoc/models/common';
import ILine, { LinePointType } from '@/fbs/rp/models/properties/line';

import { getArrowInfoByType } from './Arrow';

import './index.scss';

interface IConnectorPathProps {
  connector: UIConnectorComponent;
  scale: number;

  style?: React.CSSProperties;
  showTextEditor?: boolean;
  textCss: React.CSSProperties;
  textOutCss?: React.CSSProperties;
  connectorSvgCss?: React.CSSProperties;
  textSize: ISize;
  textEditSize?: ISize;
  textPosition: IPosition;

  onLineMouseDown?: (e: React.MouseEvent) => void;
  onLineDbClick?: (e: React.MouseEvent) => void;

  onTextMouseDown?: (e: React.MouseEvent) => void;
  onTextDbClick?: (e: React.MouseEvent) => void;
  onTextInput?: (e: React.FormEvent) => void;
  onTextKeyDown?: (e: React.KeyboardEvent) => void;
  onTextBlur?: (e: React.FocusEvent) => void;
}

interface IConnectorPathState {
  linePath: string;
  properties: IConnectorProperties;
  lineStartArrow: JSX.Element | undefined;
  lineEndArrow: JSX.Element | undefined;
}

export default class ConnectorPath extends React.Component<IConnectorPathProps, IConnectorPathState> {
  static DefaultProps: Partial<IConnectorPathProps> = {
    scale: 1,
    showTextEditor: false,
  };

  private connectTextRef: React.RefObject<HTMLParagraphElement> = React.createRef();
  constructor(props: IConnectorPathProps) {
    super(props);
    const { properties, linePath, startArrowInfo, endArrowInfo } = this.getConnectorInfo(props);
    this.state = {
      properties: properties,
      linePath,
      lineStartArrow: startArrowInfo?.el,
      lineEndArrow: endArrowInfo?.el,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: IConnectorPathProps) {
    const { properties: newProperties, linePath, startArrowInfo, endArrowInfo } = this.getConnectorInfo(nextProps);
    if (this.state.linePath !== linePath) {
      this.setState({
        linePath,
      });
    }
    if (!isEqual(this.state.lineStartArrow, startArrowInfo?.el)) {
      this.setState({
        lineStartArrow: startArrowInfo?.el,
      });
    }

    if (!isEqual(this.state.lineEndArrow, endArrowInfo?.el)) {
      this.setState({
        lineEndArrow: endArrowInfo?.el,
      });
    }
    if (!isEqual(this.state.properties, newProperties)) {
      this.setState({
        properties: newProperties,
      });
    }
  }

  shouldComponentUpdate(nextProps: IConnectorPathProps, nextState: IConnectorPathState) {
    if (!isEqual(this.state.linePath, nextState.linePath)) {
      return true;
    }

    if (!isEqual(this.state.properties, nextState.properties)) {
      return true;
    }

    if (!isEqual(this.state.lineStartArrow, nextState.lineStartArrow)) {
      return true;
    }

    if (!isEqual(this.state.lineEndArrow, nextState.lineEndArrow)) {
      return true;
    }

    if (!isEqual(this.props.style, nextProps.style)) {
      return true;
    }

    if (!isEqual(this.props.scale, nextProps.scale)) {
      return true;
    }

    if (!isEqual(this.props.showTextEditor, nextProps.showTextEditor)) {
      return true;
    }

    if (!isEqual(this.props.textCss, nextProps.textCss)) {
      return true;
    }

    if (!isEqual(this.props.textOutCss, nextProps.textOutCss)) {
      return true;
    }

    if (!isEqual(this.props.textSize, nextProps.textSize)) {
      return true;
    }

    if (!isEqual(this.props.textEditSize, nextProps.textEditSize)) {
      return true;
    }

    if (!isEqual(this.props.textPosition, nextProps.textPosition)) {
      return true;
    }

    return false;
  }

  get connectorText(): React.RefObject<HTMLParagraphElement> {
    return this.connectTextRef;
  }

  get newProperties() {
    return getNewPathPropertiesByScalingTheStrokeAndShadow(this.props.connector.properties, 1);
  }

  getArrowInfo(pathes: number[], line: ILine, strokeStyle: StyleHelper.ISVGStroke) {
    const paths = removePathPointByLine(pathes, { removeSamePoint: true });
    const { point1: startPoint1, point2: startPoint2 } = getPathStartOrEndTwoPoint(paths, true);
    const { point1: endPoint1, point2: endPoint2 } = getPathStartOrEndTwoPoint(paths, false);
    const startConfig = {
      pointType: line.startPointType || LinePointType.none,
      linePoints: { startPoint: startPoint1, endPoint: startPoint2 },
      strokeInfo: strokeStyle,
      isEnd: false,
    };
    const endConfig = {
      pointType: line.endPointType || LinePointType.none,
      linePoints: { startPoint: endPoint1, endPoint: endPoint2 },
      strokeInfo: strokeStyle,
      isEnd: true,
    };
    return {
      startArrowInfo: getArrowInfoByType(startConfig),
      endArrowInfo: getArrowInfoByType(endConfig),
    };
  }

  // 设置连接线根据连接线起始点位置定位，不根据page定位，将path路径减去定位点的位置
  resetPaths(paths: number[], position: IPosition) {
    return paths.map((path, index) => {
      if (index % 2 === 1) return path - position.y;
      return path - position.x;
    });
  }

  getConnectorInfo(props: IConnectorPathProps) {
    const { connector } = props;
    const paths = connector.searchPaths();
    const newPaths = this.resetPaths(paths, connector.position);
    const properties = connector.parseProperties();
    const { strokeWidth, line, strokeStyle } = properties;
    const { startArrowInfo, endArrowInfo } = this.getArrowInfo(newPaths, line, strokeStyle);
    const startClip = { width: startArrowInfo?.clipSize?.width || 0, height: startArrowInfo?.clipSize?.height || 0 };
    const endClip = { width: endArrowInfo?.clipSize?.width || 0, height: endArrowInfo?.clipSize?.height || 0 };
    const linePath = parseLineStr(newPaths, strokeWidth, line, startClip, endClip);
    return {
      properties,
      linePath,
      startArrowInfo,
      endArrowInfo,
    };
  }

  renderArrow() {
    const { lineStartArrow, lineEndArrow } = this.state;
    return (
      <>
        {lineStartArrow}
        {lineEndArrow}
      </>
    );
  }

  render() {
    const {
      connector,
      style,
      scale,
      showTextEditor,
      textCss,
      textOutCss,
      textSize,
      textEditSize,
      textPosition,
      onLineMouseDown,
      onLineDbClick,
      onTextInput,
      onTextKeyDown,
      onTextBlur,
      onTextDbClick,
      onTextMouseDown,
      connectorSvgCss,
    } = this.props;
    const { linePath, properties } = this.state;
    const { id, text } = connector;
    const { strokeStyle, strokeColor, strokeWidth, strokeDashArray, opacity, line } = properties;
    const textValue = text?.toString() || '';
    return (
      <React.Fragment>
        <div style={{ ...connectorSvgCss }}>
          <svg
            className="component-connector-svg"
            style={{
              ...style,
              pointerEvents: 'none',
              opacity: opacity / 100,
            }}
          >
            <path
              d={linePath}
              fill="none"
              {...strokeStyle}
              stroke={strokeColor}
              strokeWidth={strokeWidth}
              strokeDasharray={strokeDashArray}
              markerStart={line?.startArrow ? `url('#${id}-start-marker')` : undefined}
              markerEnd={line?.endArrow ? `url('#${id}-end-marker')` : undefined}
            />
            <path
              d={linePath}
              fill="none"
              {...strokeStyle}
              stroke="transparent"
              strokeWidth={strokeWidth + Math.min(8, 10 / scale)}
              onMouseDown={onLineMouseDown}
              onDoubleClick={onLineDbClick}
            />
            {this.renderArrow()}
          </svg>
        </div>
        {(!!textValue.trim() || showTextEditor) && (
          <div
            style={{
              left: textPosition.x,
              top: textPosition.y,
              width: textSize.width + 1,
              height: textSize.height,
              position: 'absolute',
              ...textOutCss,
            }}
          >
            <div
              ref={this.connectTextRef}
              className={classnames('connector-text-and-editor', `font-size-${textCss.fontSize}`, {
                editing: showTextEditor,
              })}
              contentEditable={showTextEditor}
              dangerouslySetInnerHTML={{ __html: text }}
              style={{
                ...textCss,
                minWidth: showTextEditor && textEditSize ? Math.min(textEditSize.width, 100) : 0,
                minHeight: showTextEditor ? 8 : undefined,
              }}
              onMouseDown={onTextMouseDown}
              onInput={onTextInput}
              onKeyDown={onTextKeyDown}
              onBlur={onTextBlur}
              onDoubleClick={onTextDbClick}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}
