import * as React from 'react';

interface IProps {
  isSpacePlusMouseToMovingPage: boolean;
  isWheeling: boolean;
  isSelectByRect: boolean;
  isZooming: boolean;
  isArtboardMoving: boolean;
  isNormalArtboardResizing: boolean; // 非智能布局的画板resizing
  isPageScrolling: boolean;
  renderContainer(): React.ReactNode;
}

/**
 * 主要作用: 组件缓存
 */
class ComponentsWrapper extends React.Component<IProps> {
  shouldComponentUpdate(nextProps: IProps) {
    const {
      isSpacePlusMouseToMovingPage,
      isWheeling,
      isSelectByRect,
      isZooming,
      isArtboardMoving,
      isNormalArtboardResizing,
      isPageScrolling,
    } = nextProps;
    const isZoomingUpdate = isZooming || this.props.isZooming !== isZooming; //  缩放中不用render每个组件
    const isSpaceMovingUpdate =
      isSpacePlusMouseToMovingPage || isSpacePlusMouseToMovingPage !== this.props.isSpacePlusMouseToMovingPage;

    return !(
      isPageScrolling ||
      isSpaceMovingUpdate ||
      isZoomingUpdate ||
      isWheeling ||
      isSelectByRect ||
      isArtboardMoving ||
      isNormalArtboardResizing
    );
  }

  render() {
    return this.props.renderContainer();
  }
}

export default ComponentsWrapper;
