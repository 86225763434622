import * as React from 'react';
import classnames from 'classnames';
import { isEqual, cloneDeep } from 'lodash';

import { /*convertEventToHotKey,*/ isControlKeyPressed } from '@utils/hotkeysUtils';
import { isMockRPD, isShareFrame } from '@utils/envUtils';
import { dragDelegate } from '@utils/mouseUtils';
import * as GraphcsUtils from '@utils/graphicsUtils';
import { loadFromCache, saveToCache } from '@/utils/cacheUtils';
import { parseUrlSearch } from '@/utils/urlUtils';
import { nodeMatchUpdate } from '@/utils/stringUtils';

import { WhiteColor } from '@consts/colors/index';

import {
  Icon,
  IListItem,
  Tree,
  TreeItemData,
  searchTreeNodes,
  searchNodes,
  modifyAllChilrenExpand,
  findNode,
  findRootNode,
  Tooltip,
  EllipsisTooltip,
} from '@dsm';
import { getGroupPageNum } from '@/dsm/InnerTree/utils';
import { SearchInput, Icon as Icon2 } from '@dsm2';
import KeyCodeMap from '@dsm2/constants/KeyCodeMap';
import Routes from '@consts/router';

import i18n, { languageManager } from '@i18n';
import appOptions from '@helpers/appOptions';
import {
  IPreviewOption,
  parseDataToRPTree,
  shouldHiddenPageVisible,
  getIsHaveProjectPermission,
} from '@helpers/previewHelper';
import { IPreviewAppInfo } from '@fbs/rp/models/app';
import { INodeWithChildren } from '@fbs/rp/models/node';
import { IUserInfo } from '@fbs/idoc/models/user';
import { getAllPageCount } from '@fbs/rp/utils/app';
import { MobileType } from '@fbs/rp/utils/preview';
import { desktopServer } from '@/services/desktop';
import { ControlPanelState } from '@/fbs/rp/models/preview';
import { Role, ITeams } from '@/fbs/teamManagement';
import { IPopularize } from '@/fbs/teamManagement/utils/popularize';
import { IAppMembersInfo } from '@/fbs/idoc/models/app';
import { TheaterManager } from '@/managers/pageArtboardManager';

import * as ProjectEmpty from '@assets/image/projectEmpty.png';

import './index.scss';

export interface IControllerPanelProp {
  app: IPreviewAppInfo;
  userInfo?: IUserInfo;
  role?: Role;
  selected?: string;
  mobileType: MobileType;
  option: IPreviewOption;
  collapse: boolean;
  isFullscreen?: boolean;
  teamInfo?: ITeams;
  popularize?: IPopularize;
  membersFromApp?: IAppMembersInfo[];
  onPageSelect: (node: INodeWithChildren) => void;
  onOptionChange: (option: IPreviewOption) => void;
  onCollapseChange: (e: React.MouseEvent, controlPanelState: ControlPanelState) => void;
}

export interface IControllerPanelState {
  showAdvertisement: boolean;
  treeData: TreeItemData<INodeWithChildren>[];
  selected?: string;
  showLinkAreaWhenHovered: boolean;
  alwaysShowLinkArea: boolean;
  autoScreen: boolean;
  noBoundary: boolean;
  width: number;
  draggingSplit?: boolean;
  searchValue: string;
  showSearchBox?: boolean;
  showRemarkTag: boolean;
  hiddenPopularize: boolean;
}

const MIN_WIDTH = isShareFrame ? 216 : 280;

export class ControllerPanel extends React.Component<IControllerPanelProp, IControllerPanelState> {
  static defaultProps: Partial<IControllerPanelProp> = {};
  private isCtrKeyPressed: boolean = false;

  selfRef: React.RefObject<HTMLDivElement>;
  private treeRef: React.RefObject<Tree<INodeWithChildren>>;

  private disabledScroll: boolean = false;
  private moduleID: string;
  private appID: string;

  // 页面树下广告配置
  private readonly advertisementOpt: {
    en_us?: {
      image: any;
      key: string;
      url: string;
    };
    zh_cn?: {
      image: any;
      key: string;
      url: string;
    };
  } = {
    // zh_cn: {
    //   // dt测试服推广活动
    //   image: Advertisement,
    //   // `Advertisement_${Date.now().toString(32)}`
    //   key: 'Advertisement_1fmrbqg58',
    //   url: 'https://www.mockplus.cn/dt',
    // },
  };

  constructor(props: IControllerPanelProp) {
    super(props);
    this.state = {
      treeData: this.initTreeData(),
      selected: props.selected,
      showLinkAreaWhenHovered: !!props.option.showLinkAreaWhenHovered,
      alwaysShowLinkArea: !!props.option.alwaysShowLinkArea,
      autoScreen: !!props.option.autoScreen,
      noBoundary: !!props.option.noBoundary,
      width: Math.max(Math.min(Math.round(window.innerWidth / 2), appOptions.previewControllPanelWidth), MIN_WIDTH),
      searchValue: '',
      showAdvertisement: this.needShowAdvertisement(),
      showRemarkTag: !!props.option.showRemarkTag,
      hiddenPopularize: !!props.popularize && !!appOptions.hiddenPopularizes[props.popularize._id],
    };
    this.selfRef = React.createRef();
    this.treeRef = React.createRef();
    const { moduleID, appID, pdAppID } = parseUrlSearch();
    this.moduleID = moduleID;
    // 私有部署使用pdAppID
    this.appID = appID || pdAppID;
    this.doPageToTheaterManager();
  }

  get shouldHidePage(): boolean {
    const { role, app, userInfo, membersFromApp } = this.props;
    return !shouldHiddenPageVisible(role, app, userInfo?.id, membersFromApp);
  }

  get showPageNumber() {
    return this.props.app.showRPPageNumber ?? false;
  }

  doPageToTheaterManager() {
    const { app } = this.props;
    // 演示界面设置每个页面占位画板，表示可以从这些页面加载跨页面画板交互
    Object.values(app.flatChildren).forEach((v) => {
      shouldHiddenPageVisible();
      if (!(this.shouldHidePage && v.hidden) && v.type === 'page') {
        TheaterManager.setArtboards(v._id, []);
      }
    });
  }

  /**
   * 初始化树， 选中节点的顶级节点下所有后代节点全部展开，其他所有节点收起
   */
  initTreeData = () => {
    // const treeData = this.doParseToTreeData(this.props, true);
    const { selected, app } = this.props;
    let treeData = parseDataToRPTree(selected || '', app.children, {
      allCollapsed: true,
      showPageNumber: this.showPageNumber,
      //过滤空的文件夹,以及显示
      filterCallback: (node) =>
        !(node.type === 'folder' && !node.children.length) && (this.shouldHidePage ? !node.hidden : true),
    });
    //过滤掉空文件夹分组
    ControllerPanel.modifyTreeDataToSetSelectedNodeExpand(treeData);
    return treeData;
  };

  /**
   * 将选中的节点的顶级节点下的所有后代节点展开
   */
  static modifyTreeDataToSetSelectedNodeExpand = (treeData: TreeItemData<INodeWithChildren>[]) => {
    const selectNode = findNode(treeData, (item: TreeItemData<INodeWithChildren>) => !!item.selected);
    if (!selectNode) {
      return;
    }

    // 打开所有的父
    const parent = findRootNode(selectNode);
    if (!parent.isLeaf) {
      parent.expand = true;
    }
    if (parent.children?.length) {
      modifyAllChilrenExpand(parent.children, true);
    }
    // 关闭所有子
    // if(selectNode.children?.length){
    //   modifyAllChilrenExpand(selectNode.children, false);
    // }
  };

  /**
   * 只将选中的节点下的所有后代节点展开
   */
  static toOnlyExpandSelectedNode = (treeData: TreeItemData<INodeWithChildren>[]) => {
    const selectNode = findNode(treeData, (item: TreeItemData<INodeWithChildren>) => !!item.selected);
    if (!selectNode) {
      return;
    }
    if (selectNode.children) {
      selectNode.expand = true;
      modifyAllChilrenExpand(selectNode.children, true);
    }
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleWindowKeydown);
    window.addEventListener('keyup', this.handleWindowKeyup);
    isShareFrame && this.handleAutoScreenChange(true);
    this.autoScrollToSelNode();
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleWindowKeydown);
    window.removeEventListener('keyup', this.handleWindowKeyup);
  }

  componentDidUpdate(prevProps: IControllerPanelProp): void {
    const { popularize } = this.props;
    if (popularize && !isEqual(popularize, prevProps.popularize)) {
      this.setState({
        hiddenPopularize: appOptions.hiddenPopularizes[popularize._id],
      });
    }
    this.autoScrollToSelNode();
  }

  private autoScrollToSelNode() {
    if (this.disabledScroll) {
      this.disabledScroll = false;
      return;
    }
    const node = findNode(this.state.treeData, (item) => {
      return item.selected!!;
    });
    if (node) {
      this.treeRef.current?.scrollToNode(node.data);
    }
  }

  handleWindowKeydown = (e: KeyboardEvent) => {
    this.isCtrKeyPressed = isControlKeyPressed(e);
    const { keyCode } = e;
    const dom = e.target as HTMLElement;
    if ([KeyCodeMap.VK_LEFT, KeyCodeMap.VK_UP].includes(keyCode)) {
      if (dom.tagName === 'TEXTAREA') {
        return;
      }
      if (dom.tagName === 'INPUT' && keyCode === KeyCodeMap.VK_LEFT) {
        return;
      }
      this.goPage('last');
    } else if ([KeyCodeMap.VK_RIGHT, KeyCodeMap.VK_DOWN].includes(keyCode)) {
      if (dom.tagName === 'TEXTAREA') {
        return;
      }
      if (dom.tagName === 'INPUT' && keyCode === KeyCodeMap.VK_RIGHT) {
        return;
      }
      this.goPage('next');
    }

    // const hotKey = convertEventToHotKey(e);
    // if (hotKey === 'ctrl+f' && !this.state.showSearchBox) {
    //   // this.setState({ showSearchBox: true });
    // } else
    if (keyCode === KeyCodeMap.VK_ESCAPE && this.state.showSearchBox) {
      this.setState({ showSearchBox: false });
    }
  };

  handleWindowKeyup = (e: KeyboardEvent) => {
    this.isCtrKeyPressed = isControlKeyPressed(e);
  };

  static getDerivedStateFromProps(props: IControllerPanelProp, state: IControllerPanelState) {
    const newState = { ...state };
    if (!state.treeData || props.selected !== state.selected) {
      if (props.selected && newState.treeData) {
        const chooseNodes: TreeItemData<INodeWithChildren>[] = ControllerPanel.getNodesByID(
          props.selected,
          newState.treeData,
          [],
          true,
        );
        let node = chooseNodes.length ? chooseNodes[0] : undefined;
        if (node) {
          node.selected = true;
          while (node.parent) {
            node.parent.expand = true;
            node = node.parent;
          }
        }
        const treeData = cloneDeep(newState.treeData);
        newState.treeData = treeData;
      }
      newState.selected = props.selected;
    }
    return newState;
  }

  /**
   * 根据id查找node
   * @param {string} nodeID
   * @param {TreeItemData<INodeWithChildren>[]} nodes
   * @param {TreeItemData<INodeWithChildren>[]} chooseNodes
   * @param {boolean} deselectAll
   * @returns
   * @memberof ControllerPanel
   */
  static getNodesByID(
    nodeID: string,
    nodes: TreeItemData<INodeWithChildren>[],
    chooseNodes: TreeItemData<INodeWithChildren>[],
    deselectAll?: boolean,
  ) {
    const search = (
      nodeID: string,
      nodes: TreeItemData<INodeWithChildren>[],
      chooseNodes: TreeItemData<INodeWithChildren>[],
    ) => {
      nodes.forEach((node) => {
        deselectAll && (node.selected = false);
        if (node.data._id === nodeID) {
          chooseNodes.push(node);
          node.children && search(nodeID, node.children, chooseNodes);
        } else {
          node.children && search(nodeID, node.children, chooseNodes);
        }
      });
    };
    search(nodeID, nodes, chooseNodes);
    return chooseNodes;
  }

  /**
   * 选择热区切换方式
   */
  handleOptionChange = () => {
    const { onOptionChange } = this.props;
    const { autoScreen, alwaysShowLinkArea, showLinkAreaWhenHovered, noBoundary, showRemarkTag } = this.state;
    onOptionChange({ autoScreen, alwaysShowLinkArea, showLinkAreaWhenHovered, noBoundary, showRemarkTag });
  };

  handleMobileTypeSelected = (value: IListItem) => {
    const { onOptionChange } = this.props;
    onOptionChange({ mobileType: value.id as MobileType });
  };

  handleCollapse = (e: React.MouseEvent) => {
    const { onCollapseChange } = this.props;
    onCollapseChange && onCollapseChange(e, ControlPanelState.Collapse);
  };

  handleExpand = (e: React.MouseEvent) => {
    const { onCollapseChange } = this.props;
    onCollapseChange && onCollapseChange(e, ControlPanelState.Expand);
  };

  doChangeFolderCollapseState(item: INodeWithChildren) {
    const nodes = searchNodes(this.state.treeData, item._id, (data, value) => {
      return data._id === value;
    });
    const flat = (nodes: TreeItemData<INodeWithChildren>[], list: TreeItemData<INodeWithChildren>[]) => {
      list.push(...nodes);
      nodes.forEach((node) => {
        if (node.children) {
          flat(node.children, list);
        }
      });
    };
    if (nodes && nodes.length) {
      const list: TreeItemData<INodeWithChildren>[] = [];
      flat(nodes, list);
      const node = list.find((nodeItem) => nodeItem.data === item);
      if (node) {
        this.doItemExpandChanged(item, !node.expand);
      }
    }
  }

  handleTreeItemClick = (e: React.MouseEvent, item: INodeWithChildren) => {
    this.disabledScroll = true;
    if (isControlKeyPressed(e)) {
      const newTreeData = cloneDeep(this.state.treeData);
      modifyAllChilrenExpand(newTreeData, !this.isTreeExpand(newTreeData));
      this.setState({ treeData: newTreeData });
    } else {
      if (item.type === 'folder') {
        return;
      }
      this.props.onPageSelect(item);
    }
  };

  goPage = (type: 'last' | 'next') => {
    const { treeData, selected } = this.state;
    if (selected) {
      const node = ControllerPanel.getNodesByID(selected, treeData, [])[0];
      if (node) {
        const nodes = this.doDelayer(treeData);
        const index = nodes.findIndex((item) => item._id === node.data._id);
        if (type === 'last' && index > 0) {
          this.props.onPageSelect(nodes[index - 1]);
        } else if (type === 'next' && index < nodes.length - 1) {
          this.props.onPageSelect(nodes[index + 1]);
        }
      }
    }
  };

  doDelayer = (treeData: TreeItemData<INodeWithChildren>[], items: INodeWithChildren[] = []) => {
    if (!treeData.length) return items;
    treeData.forEach((item) => {
      if (item.data.type !== 'folder') {
        items.push(item.data);
      }
      if (item.children) {
        this.doDelayer(item.children, items);
      }
    });
    return items;
  };

  handleItemExpandChanged = (item: INodeWithChildren, expand: boolean) => {
    this.disabledScroll = true;
    if (this.isCtrKeyPressed) {
      const newTreeData = cloneDeep(this.state.treeData);
      modifyAllChilrenExpand(newTreeData, expand);
      this.setState({ treeData: newTreeData });
    } else {
      this.doItemExpandChanged(item, expand);
    }
  };

  isTreeExpand(treeData: TreeItemData<INodeWithChildren>[]) {
    return treeData.some((item) => {
      return !item.isLeaf && item.expand;
    });
  }

  doItemExpandChanged = (item: INodeWithChildren, expand: boolean) => {
    const { treeData } = this.state;
    const nodeID = item._id;
    if (nodeID && treeData) {
      const nodes = ControllerPanel.getNodesByID(nodeID, treeData, []);
      if (nodes[0]) {
        nodes[0].expand = expand;
        // if (nodes[0].children) {
        //   modifyAllChilrenExpand(nodes[0].children);
        // }
        this.setState({ treeData });
      }
    }
  };

  handleStartDragSplit = () => {
    const { width } = this.state;
    const maxWidth = Math.round(window.innerWidth / 2);
    this.setState({ draggingSplit: true });
    let w = width;
    dragDelegate(
      (e: MouseEvent, delta: { x: number; y: number }) => {
        w = Math.max(Math.min(width + delta.x, maxWidth), MIN_WIDTH);
        this.setState({ width: w });
      },
      () => {
        this.setState({ draggingSplit: false });
        appOptions.previewControllPanelWidth = w;
      },
    );
  };

  handleSearchPage = (value: string) => {
    const { selected, app } = this.props;
    let newTree = parseDataToRPTree(selected || '', app.children, {
      allCollapsed: false,
      shouldHidePage: this.shouldHidePage,
      showPageNumber: this.showPageNumber,
    });
    const _searchValue = value.toLowerCase().replace(/\s+/g, '');
    if (_searchValue) {
      newTree = searchTreeNodes(
        newTree,
        _searchValue,
        (data, searchValue) => {
          const name = data.name.toLowerCase().replace(/\s+/g, '');
          const i = name.indexOf(searchValue);
          return i !== -1;
        },
        false,
      );
    }

    const { treeData, searchValue } = this.state;
    if (!isEqual(newTree, treeData) || searchValue !== value) {
      this.setState({ searchValue: value, treeData: newTree });
    }
  };

  handleShowSearch = () => {
    if (!this.state.showSearchBox) {
      this.setState({ showSearchBox: true });
    }
  };

  handleCloseSearch = () => {
    this.setState({ showSearchBox: false, searchValue: '' });

    if (this.state.searchValue) {
      const { selected, app } = this.props;
      let newTree = parseDataToRPTree(selected || '', app.children, {
        allCollapsed: false,
        shouldHidePage: this.shouldHidePage,
        showPageNumber: this.showPageNumber,
      });
      this.setState({
        treeData: newTree,
      });
    }
  };

  handleNoBoundaryChange = (value: boolean) => {
    this.setState({ noBoundary: value }, () => {
      this.handleOptionChange();
    });
  };

  handleShowRemarkTagChange = (value: boolean) => {
    this.setState({ showRemarkTag: value }, () => {
      this.handleOptionChange();
    });
  };

  handleAutoScreenChange = (value: boolean) => {
    this.setState({ autoScreen: value }, () => {
      this.handleOptionChange();
    });
  };

  handleShowLinkChange = (value: boolean) => {
    this.setState({ showLinkAreaWhenHovered: value }, () => {
      this.handleOptionChange();
    });
  };

  handleAlwaysShowLinkChange = (value: boolean) => {
    this.setState({ alwaysShowLinkArea: value }, () => {
      this.handleOptionChange();
    });
  };

  renderTreeItem = (item: INodeWithChildren, extend?: boolean) => {
    const { searchValue } = this.state;
    const isFolder = item.type === 'folder';
    const name = item.serialNumber ? `${item.serialNumber} ${item.name}` : item.name;
    let groupNum = 0;
    if (item.children && item.children.length > 0) {
      const leafArr = getGroupPageNum(item.children, this.shouldHidePage);
      groupNum = leafArr.length;
    }
    return (
      <div className="tree-item-render">
        {isFolder && (extend ? <Icon cls="icon_tree_group" /> : <Icon cls="tree_index" />)}
        <Tooltip className="item-name" text={item.name}>
          <span
            dangerouslySetInnerHTML={{
              __html: nodeMatchUpdate(name, searchValue, true, groupNum),
            }}
          ></span>
        </Tooltip>
        {item.hidden && <Icon cls="icon_tree_unview" />}
      </div>
    );
  };

  handleBackToProject = () => {
    if (RP_CONFIGS.isOfflineDemo) {
      return;
    }
    const { app, role, userInfo, membersFromApp } = this.props;
    const { appID } = app;
    const { moduleID, appID: prototypeID } = this;

    // 跳转返回cc
    if (moduleID && prototypeID) {
      location.href = `${window.apis.iDocDomain}/app/${moduleID}/prototype/${prototypeID || ''}`;
      return;
    }

    if (!getIsHaveProjectPermission(role, app, userInfo?.id, membersFromApp)) {
      return;
    }

    if (isMockRPD) {
      desktopServer.openApp(appID);
      return;
    }
    // 暂不设置权限
    // if (!this.isTeamMember) {
    //   return;
    // }
    const url = `${window.location.origin}${Routes.routePrefix}/editor/${appID}/${this.state.selected || ''}`;
    location.href = url;
  };

  renderExpandHandle() {
    if (!this.props.collapse) {
      return null;
    }

    return (
      <div className="expand-handle" onClick={this.handleExpand}>
        <Icon cls="list" theme="tag" tips={i18n('preview.folder')} />
      </div>
    );
  }

  private getAdOpt() {
    const { zh_cn, en_us } = this.advertisementOpt;
    return languageManager.isZHLanguage ? zh_cn : en_us;
  }

  private needShowAdvertisement() {
    const opt = this.getAdOpt();
    if (!opt) {
      return false;
    }
    return !loadFromCache(opt.key);
  }

  // 关闭广告
  private handleAdvertisementClose = () => {
    const key = this.getAdOpt()?.key;
    if (!key) {
      return;
    }
    this.setState({ showAdvertisement: false }, () => {
      saveToCache(key, true);
    });
  };

  // 打开广告
  private handleAdClick = () => {
    const url = this.getAdOpt()?.url;
    window.open(url, '_blank');
  };

  private renderAdvertisement() {
    if (RP_CONFIGS.isPrivateDeployment) {
      return null;
    }
    const opt = this.getAdOpt();
    if (!opt) {
      return null;
    }
    const { image } = opt;
    if (!this.state.showAdvertisement) {
      return null;
    }
    const padding = 10;
    const btnPadding = 14;
    const imageWidth = MIN_WIDTH - padding * 2;
    return (
      <div className="advertisement">
        <img
          className="ad_image"
          width={MIN_WIDTH - padding * 2}
          src={image}
          alt="advertising"
          onClick={this.handleAdClick}
        />
        <div
          className="closeMe"
          style={{
            left: padding + imageWidth - btnPadding,
            top: padding + btnPadding,
          }}
        >
          <Icon
            size={12}
            cls="close"
            style={{ color: GraphcsUtils.parseColorToString(WhiteColor) }}
            onClick={this.handleAdvertisementClose}
          />
        </div>
      </div>
    );
  }

  private handleClosePopularize = (): void => {
    const { popularize } = this.props;
    if (!popularize) {
      return;
    }
    this.setState({
      hiddenPopularize: true,
    });
    appOptions.hiddenPopularizes = { [popularize._id]: true };
  };

  private renderPopularize(): React.ReactNode {
    const { popularize } = this.props;
    if (this.state.hiddenPopularize || !popularize) {
      return null;
    }
    const { url, image, enableColse } = popularize;
    return (
      <div className="preview-popularize-container">
        <a href={url} target="_blank" className="preview-popularize-link" rel="noreferrer">
          <img srcSet={image} className="preview-popularize-img" />
        </a>
        {enableColse && (
          <div className="preview-popularize-close" onClick={this.handleClosePopularize}>
            <Icon size={14} cls="icon_newworkb02-_Smallx_RP" />
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      app: { children, name, username },
      role,
      collapse,
      userInfo,
      isFullscreen,
      membersFromApp,
    } = this.props;
    if (isFullscreen) return null;
    const { width, draggingSplit: dragging, showSearchBox, searchValue, treeData } = this.state;
    const disabledLinkToEditor =
      RP_CONFIGS.isOfflineDemo ||
      !role ||
      !getIsHaveProjectPermission(role, this.props.app, userInfo?.id, membersFromApp);
    const disabledLinkToCC = !(this.moduleID && this.appID);
    const disabledLink = disabledLinkToEditor && disabledLinkToCC;
    return (
      <div
        className={classnames('preview-controller-panel', { collapse, dragging })}
        style={{
          width,
          marginLeft: collapse ? -width : 0,
        }}
      >
        <div className="preview-tool-bar">
          <div className="project-name">
            <div className="back-wrapper">
              {!disabledLink && (
                <Icon
                  onClick={this.handleBackToProject}
                  cls="icon_back02"
                  className="app-back"
                  tips={i18n('general.back')}
                />
              )}
              <EllipsisTooltip text={name} className={classnames('app-name-wrapper', { disabled: disabledLink })}>
                <label
                  onClick={this.handleBackToProject}
                  className={classnames('app-name', { disabled: disabledLink })}
                >
                  {name}
                </label>
              </EllipsisTooltip>
            </div>
            <div className="app-info">
              {!!userInfo && (
                <>
                  <span className="author">{username}</span>
                  <span>&ensp;·&ensp;</span>
                </>
              )}
              <span className="count">
                {i18n('application.pageCount2', getAllPageCount(children, { noHiddenPage: this.shouldHidePage }))}
              </span>
            </div>
          </div>
        </div>

        <div className="search-bar">
          <div
            className={classnames('search-content', { 'search-content--without-search': !showSearchBox })}
            onClick={this.handleShowSearch}
          >
            {showSearchBox ? (
              <SearchInput
                autoFocus
                alwaysShowClose
                value={searchValue}
                onStopSearch={this.handleCloseSearch}
                onChange={this.handleSearchPage}
                iconSize={14}
              />
            ) : (
              <Icon2 cls="search" disableHover={true} size={14} />
            )}
          </div>
        </div>
        <div className="content" style={{ width }}>
          {treeData.length ? (
            <Tree
              ref={this.treeRef}
              items={treeData}
              onItemClick={this.handleTreeItemClick}
              onItemExpandChanged={this.handleItemExpandChanged}
              itemRender={this.renderTreeItem}
              prefixIndent={12}
              itemHeight={36}
              className="tree-extension"
            />
          ) : (
            <div className="no-page">
              <img className="image" width="64" src={ProjectEmpty} alt="project_empty" />
              <span className="text">{i18n('tips.noPage')}</span>
            </div>
          )}
          {this.renderAdvertisement()}
        </div>
        {this.renderPopularize()}
        <div className={classnames('split-dragger', { hidden: collapse })} onMouseDown={this.handleStartDragSplit} />
      </div>
    );
  }
}

export default ControllerPanel;
