import * as React from 'react';

import { convertEventToHotKey, isShiftKeyPressed, isControlKeyPressed } from '@/utils/hotkeysUtils';

import { IconValue } from '@/fbs/rp/models/value';

import IconReplaceTipDialog from '../IconReplaceTipDialog';

interface ReplaceIconState {
  selectedNodeIcons: string[];
  canShowTip: boolean;
}

export interface ReplaceIconProps {
  onOpenIconLibrariesPanel?: Function;
}

export interface ReplaceIconImplements {
  canReplaceIcon: boolean;
  replaceIconValue: (value: IconValue) => void;
}

export interface InjectedReplaceIconProps {
  selectedNodeIcons: string[];
  floatPanelClassName: string;
  setSelectedIconIds: (selectedNodeIcons: string[]) => void;
  setAllIconIds: (selectedNodeIcons: string[]) => void;
  onFontIconClick: (e: React.MouseEvent, id: string) => void;
}

const SELECT_ALL_SHORTCUT_KEY = 'ctrl+a'; // 全选快捷键

export default function withReplaceIcon<P extends ReplaceIconProps>(
  WrapperComponent: React.ComponentType<P & InjectedReplaceIconProps>,
) {
  return class ReplaceIconInner extends React.Component<P, ReplaceIconState> {
    private list: string[];
    private editPanel: React.RefObject<any>;

    constructor(props: P & ReplaceIconProps) {
      super(props);
      this.list = [];
      this.editPanel = React.createRef();
      this.state = {
        selectedNodeIcons: [],
        canShowTip: false,
      };
    }

    get canReplaceIcon() {
      if (this.editPanel.current) {
        return this.editPanel.current.canReplaceIcon;
      }
      return false;
    }

    doSetSelectedNodeIcons = (list: string[]) => {
      if (!this.canReplaceIcon) return;
      this.setState({ selectedNodeIcons: list });
      this.props.onOpenIconLibrariesPanel?.();
    };

    listenKeydown = (e: KeyboardEvent) => {
      e.stopPropagation();
      e.preventDefault();
      const hotKey = convertEventToHotKey(e);
      if (hotKey === SELECT_ALL_SHORTCUT_KEY) {
        this.doSetSelectedNodeIcons(this.list);
      }
    };

    listenMousedown = (e: React.MouseEvent | MouseEvent) => {
      const target = e.target as HTMLElement;
      const floatPanel = document.querySelector('.with-replace-icon-float-panel');
      if (
        floatPanel &&
        floatPanel.contains(target) &&
        target &&
        !target.classList.contains('component-edit-item-font-icon')
      ) {
        this.setState({ selectedNodeIcons: [] });
      }
    };

    componentDidMount() {
      if (this.canReplaceIcon) {
        window.addEventListener('keydown', this.listenKeydown);
        window.addEventListener('mousedown', this.listenMousedown);
        this.setState({ canShowTip: true });
      }
    }

    componentWillUnmount() {
      if (this.canReplaceIcon) {
        window.removeEventListener('keydown', this.listenKeydown);
        window.removeEventListener('mousedown', this.listenMousedown);
      }
    }

    handleSetListIcons = (list: string[]) => {
      this.list = list;
    };

    handleSetSelectedIcons = (selectedNodeIcons: string[]) => {
      this.setState({ selectedNodeIcons });
    };

    handleFontIconClick = (e: React.MouseEvent, compId: string) => {
      let res = [...this.state.selectedNodeIcons];
      const allKeys = this.list;
      const isShift = isShiftKeyPressed(e);
      const isCtrl = isControlKeyPressed(e);
      const currentIndex = res.findIndex((node) => node === compId);
      if (isShift) {
        // 按住shift键，区域选中，从起始到当前项
        const firstId = res[0];
        const firstIndex = allKeys.findIndex((node) => node === firstId);
        const lastIndex = allKeys.findIndex((node) => node === compId);
        if (res.length === 0) {
          res = [compId];
        } else if (firstIndex > lastIndex) {
          // 此处保证第一项位置不变
          res = [firstId, ...allKeys.slice(lastIndex, firstIndex)];
        } else {
          res = allKeys.slice(firstIndex, lastIndex + 1);
        }
      } else if (isCtrl) {
        // ctrl键，被点击项单独添加或移除
        if (currentIndex === -1) {
          res.push(compId);
        } else {
          res.splice(currentIndex, 1);
        }
      } else {
        // 普通点击为单选
        if (currentIndex === -1 || res.length > 1) {
          res = [compId];
        } else {
          res = [];
        }
      }
      this.doSetSelectedNodeIcons(res);
    };

    render() {
      return (
        <>
          <WrapperComponent
            {...this.props}
            ref={this.editPanel}
            floatPanelClassName="with-replace-icon-float-panel"
            selectedNodeIcons={this.state.selectedNodeIcons}
            onFontIconClick={this.handleFontIconClick}
            setSelectedIconIds={this.handleSetSelectedIcons}
            setAllIconIds={this.handleSetListIcons}
          />
          {this.state.canShowTip && <IconReplaceTipDialog />}
        </>
      );
    }
  };
}
