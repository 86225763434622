import { OffLineOperation, OperationType } from '@fbs/rp/models/io';
import { IAppDataModel } from '../types/appData.type';
import { AppDataModel } from '../models/appData.model';
import { generateRandomStr } from '@/helpers/idHelper';

export type LocalStorageOfflineData = {
  appid: string;
  key: string;
  data: OffLineOperation[];
};
const OFFLINE_DATA_KEY_PREFIX = 'offline-operations-';

export class SyncLocalStorage {
  private _dataModel: IAppDataModel = new AppDataModel();
  /*******************同步标识-begin*****************************************/
  private _deviceID: string = this.getDeviceID();
  /**
   * 用于保存每个tab中对于数据的一个标识
   */
  private _patchIndex: number = 1;
  private _randomMask: string = generateRandomStr();
  /*******************同步标识-end*****************************************/
  private getDeviceID(): string {
    let value = localStorage.getItem('RP-IO-deviceId');
    if (!value) {
      value = generateRandomStr();
      localStorage.setItem('RP-IO-deviceId', value);
    }
    return value;
  }

  /**
   * 获取所有历史存储在localStorage中的数据
   */
  private getAllHistoryOfflineData() {
    const allOfflineData: LocalStorageOfflineData[] = [];
    for (let i = 0, len = localStorage.length; i < len; i++) {
      const key = localStorage.key(i);
      if (key?.startsWith(OFFLINE_DATA_KEY_PREFIX)) {
        const strJsonData = localStorage.getItem(key);
        const appid = key.replace(OFFLINE_DATA_KEY_PREFIX, '');
        if (strJsonData === null) {
          continue;
        }
        try {
          const data = JSON.parse(strJsonData);
          if (!Array.isArray(data)) {
            continue;
          }
          allOfflineData.push({
            appid: appid,
            key: key,
            data: data,
          });
        } catch {
          console.error(`key:${key} data jsonParser fail`);
        }
      }
    }
    return allOfflineData;
  }
  public async sync() {
    /**
     * 该代码只能存在一个月，一个月之后的数据不再需要进行数据兼容处理。
     */
    const offlineData: LocalStorageOfflineData[] = this.getAllHistoryOfflineData();

    const doSyncData = async (dataItem: LocalStorageOfflineData) => {
      const { appid, key, data } = dataItem;
      let isError = false;
      let i = 0;
      try {
        for (let len = data.length; i < len; i++) {
          const dataItem = data[i];

          let newDataItem: OffLineOperation = {
            ...dataItem,
            randomMask: this._randomMask,
            patchIndex: this._patchIndex++,
            patchesID: generateRandomStr(),
            deviceID: this._deviceID,
          };

          /**
           * 如果是之前保存过patchID则用之前保存的ID来做数据兼容
           */
          if (newDataItem.type === OperationType.PushArtboardPatches) {
            newDataItem.patchesID = newDataItem.data.patchesID ?? generateRandomStr();
          }

          // 检查历史数据是否拥有下面一系列值
          await this._dataModel.saveCache(appid, newDataItem.patchesID!, JSON.stringify(newDataItem));
        }
      } catch (e) {
        console.log('Add historical data to indexed-db error');
        isError = true;
        localStorage.setItem(key, JSON.stringify(data.slice(i))); // 保存出错位置的数据，方便下次继续
      }

      !isError && localStorage.removeItem(key); // 从localStorage移除
    };

    const allProcess: Promise<void>[] = [];
    // 并行处理
    offlineData.forEach((dataItem) => {
      allProcess.push(doSyncData(dataItem));
    });

    return Promise.allSettled(allProcess);
  }
}
