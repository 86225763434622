import axios, { AxiosRequestConfig } from 'axios';

export class CustomErrorWithPayload extends Error {
  constructor(message: string, public code: number = 1, public payload: any) {
    super(message);
    this.code = code;
    this.message = message;
    this.payload = payload;
  }
}

export async function get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
  const res = await axios.get<{
    code: number;
    payload: T;
    message: string;
  }>(url, config);

  if (res.data.code === 0) {
    return res.data.payload;
  }

  throw new CustomErrorWithPayload(res.data.message, res.data.code, res.data);
}

export async function post<T, D>(url: string, data?: D, config?: AxiosRequestConfig): Promise<T> {
  const res = await axios.post<{
    code: number;
    payload: T;
    message: string;
  }>(url, data, config);
  if (res.data.code === 0) {
    return res.data.payload;
  }
  throw new CustomErrorWithPayload(res.data.message, res.data.code, res.data);
}

export async function patch<T, D>(url: string, data?: D, config?: AxiosRequestConfig): Promise<T> {
  const res = await axios.patch<{
    code: number;
    payload: T;
    message: string;
  }>(url, data, config);
  if (res.data.code === 0) {
    return res.data.payload;
  }
  throw new CustomErrorWithPayload(res.data.message, res.data.code, res.data);
}

export async function put<T, D>(url: string, data?: D, config?: AxiosRequestConfig): Promise<T> {
  const res = await axios.put<{
    code: number;
    payload: T;
    message: string;
  }>(url, data, config);
  if (res.data.code === 0) {
    return res.data.payload;
  }

  throw new CustomErrorWithPayload(res.data.message, res.data.code, res.data);
}

export async function axDelete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
  const res = await axios.delete(url, config);
  if (res.data?.code === 0) {
    return res.data.payload;
  }
  throw new CustomErrorWithPayload(res.data.message, res.data.code, res.data);
}

// 纯粹的 xhr get 请求
// why： axios 默认会带 headers，带这些会触发浏览器的 CORS 检测，会阻止请求
export async function xhrGet(url: string): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('get', url);
    xhr.send(null);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        // 处理响应正文responseText，多数是json数据
        if (xhr.status === 200) {
          resolve(xhr.responseText);
          return;
        }
        reject(new Error('Error to get data.'));
      }
    };
  });
}

export function logFatalError(message: string, stack: string, componentStack: string): void {
  put('/utils/log', {
    message,
    stack,
    componentStack,
    url: window.location.href,
  })
    .then((payload) => {
      console.log(`%cERROR CODE:%c ${payload} `, 'background: red; color: white;', 'background: #222; color: #bada55');
    })
    .catch((err) => {
      console.error(err);
    });
}
