import * as React from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';

import i18n from '@i18n';

import { SpriteThumb, WaitingImageBgTransparency } from '@/consts/spriteIcons';

import { ISize } from '@fbs/common/models/common';
import { IComponentData } from '@fbs/rp/models/component';
import { IProperties } from '@/fbs/rp/models/property';
import { IComponentValue } from '@/fbs/rp/models/value';

import { StyleHelper } from '@helpers/styleHelper';
import { isStandalonePreview } from '@/helpers/previewHelper';

import { makeCommonComponent } from '../../helper';
import { IComponentProps, IComponentItem } from '../../types';
import { CAudio } from '../../constants';
import { SizeMode } from '../../enum';

import './index.scss';

const FILE_EXTENSIONS = ['.mp3', '.ogg'];
const VALID_FILE_TYPE = ['audio/mpeg', 'audio/ogg'];

enum FILE_STATUS {
  idle = 'idle',
  invalid = 'invalid',
  valid = 'valid',
  loading = 'loading',
}

export const AudioConfig: IComponentItem = {
  type: CAudio,
  name: i18n('resource.components.audio'),
  thumb: {
    spriteIconClass: SpriteThumb.Audio.className,
    dragPosition: SpriteThumb.Audio.position,
  },
  sizeMode: SizeMode.horizontal,
  getDefaultData() {
    return {
      properties: {
        controls: {
          name: i18n('property.propertyNames.controls'),
          prop: 'boolean',
          value: true,
          hidden: true,
        },
        autoPlay: {
          name: i18n('property.propertyNames.autoPlay'),
          prop: 'boolean',
          value: false,
          hidden: true,
        },
        loop: {
          name: i18n('property.propertyNames.loopPlay'),
          prop: 'boolean',
          value: false,
        },
        crossPagePlay: {
          name: i18n('property.propertyNames.crossPagePlay'),
          prop: 'boolean',
          value: true,
          hidden: true,
        },
      },
    };
  },
};

export function makeAudio(
  id: string,
  URL: string = '',
  name: string = '',
  size: ISize = {
    width: 300,
    height: 45,
  },
): IComponentData {
  const componentData = makeCommonComponent(id, CAudio, {
    ...AudioConfig.getDefaultData?.(),
    name,
    size: {
      width: size.width,
      height: size.height,
      lockedRatio: false,
    },
    value: URL,
    // select: {
    //   target: 'self',
    //   enabled: true,
    //   reversible: true,
    // },
  });

  componentData.layout.fixedHeight = true;
  return componentData;
}

interface IAudioState {
  value: string;
  fileStatus: FILE_STATUS;
}

class Audio extends React.Component<IComponentProps, IAudioState> {
  private audioDom: React.RefObject<HTMLAudioElement> = React.createRef();
  private isPlaying: boolean = false; // 记录用户是否点击了
  private lastPlaying: boolean = false;
  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      value: props.comp.value as string,
      fileStatus: FILE_STATUS.idle,
    };
    this.param = this.getParams(props, this.state);
  }

  private param: {
    size: ISize;
    properties: IProperties;
    value?: IComponentValue;
    opacity: number;
    isPreview?: boolean;
    transition: string;
    version: string;
  } & { [key: string]: any };

  private getParams(props: IComponentProps, state: IAudioState) {
    const {
      comp: { size, properties, value, opacity, version },
      isPreview,
    } = props;
    const transition = props.comp.getTransition();
    return {
      size: _.cloneDeep(size),
      properties: _.cloneDeep(properties),
      value: _.cloneDeep(value),
      opacity,
      isPreview,
      transition,
      version,
      fileStatus: state.fileStatus,
    };
  }

  shouldComponentUpdate(nextProps: IComponentProps, nextState: IAudioState) {
    const newParam: { [key: string]: any } = this.getParams(nextProps, nextState);
    let flag = false;
    Object.keys(this.param).forEach((key) => {
      if (!_.isEqual(newParam[key], this.param[key])) {
        flag = true;
        this.param[key] = _.cloneDeep(newParam[key]);
      }
    });
    if (this.state.value !== nextState.value) {
      flag = true;
    }
    return flag;
  }

  checkUrlValid(url: string) {
    if (typeof url !== 'string') {
      return false;
    }
    if (!FILE_EXTENSIONS.some((extension) => url.toLowerCase().endsWith(extension))) {
      return false;
    }
    return true;
  }

  checkFileValid(url: string) {
    return new Promise((resolve, reject) => {
      const audio = document.createElement('audio');
      let isExecute = false;
      const handleError = () => {
        if (isExecute) {
          return;
        }
        isExecute = true;
        reject();
      };
      const handleSuccess = () => {
        if (isExecute) {
          return;
        }
        isExecute = true;
        resolve(true);
      };
      VALID_FILE_TYPE.forEach((type) => {
        const source = document.createElement('source');
        source.type = type;
        source.src = url;
        source.onerror = source.onabort = () => {
          source.onerror = source.onabort = null;
          handleError();
        };
        audio.appendChild(source);
      });
      audio.preload = 'none';
      audio.autoplay = false;
      audio.onloadeddata = () => {
        audio.onloadeddata = null;
        handleSuccess();
      };
      audio.oncanplay = () => {
        audio.oncanplay = null;
        handleSuccess();
      };
      audio.onabort = audio.onerror = () => {
        audio.onabort = audio.onerror = null;
        handleError();
      };
      audio.load();
    });
  }

  checkAudioValid(url: string, callback?: () => void) {
    if (url === '') {
      this.setState({ value: url, fileStatus: FILE_STATUS.idle });
      return;
    }
    const isValid = this.checkUrlValid(url);
    if (isValid) {
      this.setState({ value: url, fileStatus: FILE_STATUS.loading });
      this.checkFileValid(url)
        .then(() => {
          this.setState({ value: url, fileStatus: FILE_STATUS.valid }, () => {
            callback && callback();
          });
        })
        .catch(() => {
          this.setState({ value: url, fileStatus: FILE_STATUS.invalid });
        });
    } else {
      this.setState({ value: url, fileStatus: FILE_STATUS.invalid });
    }
  }

  loadAudioMateData() {
    if (isStandalonePreview || !this.props.isStandalone) {
      this.audioDom.current?.load();
    }
  }

  componentDidUpdate() {
    const value = this.props.comp.value;
    if (this.props.isPreview) {
      if (this.state.value !== value) {
        this.setState({ value: value as string }, () => {
          this.loadAudioMateData();
        });
      }
    } else if (RP_CONFIGS.isPrivateDeployment) {
      if (this.state.value !== value) {
        this.setState({ value: value as string });
      }
    } else {
      if (this.state.value !== value) {
        this.checkAudioValid(value as string);
      }
    }
  }
  handlePageVisibilityChange = () => {
    if (!document.hidden && this.lastPlaying) {
      this.audioDom.current?.play();
    } else if (document.hidden) {
      this.lastPlaying = this.isPlaying;
      this.audioDom.current?.pause();
    }
  };
  handlePause = () => {
    this.isPlaying = false;
  };
  handleWaiting = () => {
    // this.props.currentTheater.start(this.props.comp, EventTypes.unChecked);
  };
  handlePlay = () => {};
  handlePlaying = () => {
    this.isPlaying = true;
    // this.props.currentTheater.start(this.props.comp, EventTypes.checked);
  };
  componentDidMount() {
    // const { comp, isPreview } = this.props;
    // const { properties } = comp;
    // if (isPreview && !properties.crossPagePlay?.value) {
    //   document.addEventListener('visibilitychange', this.handlePageVisibilityChange);
    // }
    if (this.props.isPreview) {
      this.loadAudioMateData();
    } else if (!RP_CONFIGS.isPrivateDeployment) {
      this.checkAudioValid(this.state.value);
    }
  }

  componentWillUnmount() {
    //document.removeEventListener('visibilitychange', this.handlePageVisibilityChange);
  }

  renderPublicEditContent() {
    const fileStatus = this.state.fileStatus;
    if (fileStatus === FILE_STATUS.invalid) {
      return (
        <div className="invalid-audio-url">
          <div>{i18n('tips.invalidAudioUrl')}</div>
        </div>
      );
    } else if (!this.state.value) {
      return (
        <div className="default-view">
          <div className="lib-comp-audio-nothing">{i18n('tips.doubleClickUploadAudio')}</div>
        </div>
      );
    } else if (fileStatus === FILE_STATUS.loading) {
      return (
        <div className="loading-view">
          <img src={WaitingImageBgTransparency} />
          <label>{i18n('general.loading')}</label>
        </div>
      );
    }
  }

  /**
   * 渲染公有版本（支持url）
   * **/
  renderPublic() {
    const { fileStatus, value } = this.state;
    const { comp, isPreview, isStandalone } = this.props;
    const { properties, disabled, size } = comp;

    // const controls = !!(properties.controls && properties.controls.value);
    const autoPlay = !!(properties.autoPlay && properties.autoPlay.value);
    const loop = !!(properties.loop && properties.loop.value);
    const style: React.CSSProperties = { ...size };
    style.transition = comp.getTransition();
    style.opacity = StyleHelper.getOpacity(comp.opacity);

    const preload = isPreview && !isStandalone;
    const visibleAudio = isPreview ? true : fileStatus === FILE_STATUS.valid;

    return (
      <div className={classnames('lib-comp-audio', { empty: !value, preview: isPreview })} style={style}>
        {!isPreview && this.renderPublicEditContent()}
        {visibleAudio && value && (
          <div className="lib-comp-audio-control-wrap">
            <audio
              className="compatible"
              autoPlay={isPreview ? autoPlay && !disabled : false}
              loop={isPreview && loop}
              controls
              preload={preload ? 'auto' : 'none'}
              onPlay={this.handlePlay}
              onPlaying={this.handlePlaying}
              onPause={this.handlePause}
              onWaiting={this.handleWaiting}
              playsInline
              x5-audio-player-type="h5"
              ref={this.audioDom}
            >
              <source src={value as string} type="audio/mpeg" />
            </audio>
          </div>
        )}
      </div>
    );
  }

  /**
   *渲染私有版本(支持上传)
   **/
  renderPrivate() {
    const { value } = this.state;
    const { comp, isPreview, isStandalone } = this.props;
    const { properties, disabled, size } = comp;
    // const controls = !!(properties.controls && properties.controls.value);
    const autoPlay = !!(properties.autoPlay && properties.autoPlay.value);
    const loop = !!(properties.loop && properties.loop.value);
    const style: React.CSSProperties = { ...size };
    style.transition = comp.getTransition();
    style.opacity = StyleHelper.getOpacity(comp.opacity);

    const preload = isPreview && !isStandalone;

    return (
      <div className={classnames('lib-comp-audio', { empty: !value, preview: isPreview })} style={style}>
        {!value && !isPreview && (
          <div className="default-view">
            <p className="lib-comp-audio-nothing">{i18n('tips.doubleClickUploadAudio')}</p>
          </div>
        )}
        {value && (
          <div className="lib-comp-audio-control-wrap">
            <audio
              className="compatible"
              autoPlay={isPreview && !isStandalone ? autoPlay && !disabled : false}
              loop={isPreview && loop}
              controls
              preload={preload && !isStandalone ? 'auto' : 'none'}
              onPlay={this.handlePlay}
              onPlaying={this.handlePlaying}
              onPause={this.handlePause}
              onWaiting={this.handleWaiting}
              playsInline
              x5-audio-player-type="h5"
              ref={this.audioDom}
            >
              <source src={value as string} type="audio/mpeg" />
            </audio>
          </div>
        )}
      </div>
    );
  }
  render() {
    return RP_CONFIGS.isPrivateDeployment ? this.renderPrivate() : this.renderPublic();
  }
}

export default Audio;
