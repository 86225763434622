import * as React from 'react';
import classnames from 'classnames';

import { parseColorToString } from '@utils/graphicsUtils';
import { transBlankChart } from '@utils/textUtils';

import { DefaultIconColor } from '@consts/colors';
import { textAndIconSpace } from '@/consts/defaultData/menu';
import { IconValue, ITreeDataItem } from '@fbs/rp/models/value';
import { IComponentSize } from '@/fbs/rp/models/component';
import { ITreeItem } from '@helpers/treeCompHelper';
import { StyleHelper } from '@helpers/styleHelper';
import { hasInteraction } from '@helpers/interactionHelper';
import { UITreeItemComponent } from '@editor/comps';

import './index.scss';

export interface INavigationMenuItemProps {
  item: ITreeItem;
  comp: UITreeItemComponent;
  paddingLeft: number;
  paddingRight: number;
  lineHeight: number;
  level: number;
  intention: number;
  selectedIDs: string[];
  checkedIconColor: string;
  checkedFill: React.CSSProperties;
  checkedMarkerStrip: React.CSSProperties;
  checkedMarkerStripWidth: number;
  showExpandIcon: boolean;
  showNodeIcon: boolean;
  isPreview: boolean;
  size: IComponentSize;
  children?: any;
  hotAreaVisibleModel?: string;

  onExpandClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onCheckBoxClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseDown?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseUp?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemDoubleClick?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemContext?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseLeave?: (node: ITreeDataItem, e: React.MouseEvent) => void;
  onItemMouseEnter?: (node: ITreeDataItem, e: React.MouseEvent) => void;
}

const NavigationMenuItem: React.FC<INavigationMenuItemProps> = (props: INavigationMenuItemProps) => {
  const handleItemClick = (e: React.MouseEvent) => {
    const { onItemClick, item, onExpandClick } = props;
    if (item.children?.length) {
      const node = props.item.data;
      onExpandClick && onExpandClick(node, e);
      document.getSelection()?.removeAllRanges();
      return;
    }
    onItemClick && onItemClick(item.data, e);
  };

  const handleItemMouseDown = (e: React.MouseEvent) => {
    const { onItemMouseDown, item } = props;
    onItemMouseDown && onItemMouseDown(item.data, e);
  };

  const handleItemDoubleClick = (e: React.MouseEvent) => {
    const { onItemDoubleClick, item } = props;
    onItemDoubleClick && onItemDoubleClick(item.data, e);
  };

  const handleItemMouseUp = (e: React.MouseEvent) => {
    const { onItemMouseUp, item } = props;
    onItemMouseUp && onItemMouseUp(item.data, e);
  };

  const handleItemMouseEnter = (e: React.MouseEvent) => {
    const { onItemMouseEnter, item } = props;
    onItemMouseEnter && onItemMouseEnter(item.data, e);
  };

  const handleItemMouseLeave = (e: React.MouseEvent) => {
    const { onItemMouseLeave, item } = props;
    onItemMouseLeave && onItemMouseLeave(item.data, e);
  };

  const handleItemContext = (e: React.MouseEvent) => {
    const { onItemContext, item } = props;
    onItemContext && onItemContext(item.data, e);
  };

  const renderExtendIcon = () => {
    const { showExpandIcon, item, comp } = props;

    if (!showExpandIcon) {
      return null;
    }
    const expandComp = comp.expandComp;
    if (!expandComp) {
      return null;
    }
    const { size, value, properties } = expandComp;
    const icon = value as IconValue;
    const fontSize = Math.min(size.width, size.height);
    return (
      <div
        className="comp-navigation-menu-expand"
        style={{
          width: size.width,
          minWidth: size.width,
          height: size.height,
          fontSize,
          fontFamily: icon.fontName,
          lineHeight: `${fontSize}px`,
          color: parseColorToString(properties.icon?.color || DefaultIconColor),
        }}
      >
        {item.children?.length && String.fromCharCode(icon.iconCode)}
      </div>
    );
  };

  const renderNodeIcon = () => {
    const { comp, showNodeIcon, level, selectedIDs, checkedIconColor } = props;
    if (!showNodeIcon) {
      return;
    }

    const nodeComp = comp.nodeComp;
    if (!nodeComp) {
      return null;
    }
    const { size, value, properties } = nodeComp;
    const icon = value as IconValue;
    const fontSize = Math.min(size.width, size.height);
    const nodeStyle: React.CSSProperties = {
      width: size.width,
      height: size.height,
      fontSize,
      fontFamily: icon.fontName,
      lineHeight: `${fontSize}px`,
      color: parseColorToString(properties.icon?.color || DefaultIconColor),
    };
    if (selectedIDs && selectedIDs.includes(comp.id)) {
      nodeStyle.color = checkedIconColor;
    }
    return (
      <div className="comp-navigation-menu-node" style={nodeStyle}>
        {!level && String.fromCharCode(icon.iconCode)}
      </div>
    );
  };

  const renderText = () => {
    const { comp, item, showExpandIcon, showNodeIcon } = props;
    const { textComp, nodeComp, expandComp } = comp;
    let { size: expandSize } = expandComp;
    let { size: nodeSize } = nodeComp;
    if (!showExpandIcon) {
      expandSize = { ...expandSize, width: 0 };
    }
    if (!showNodeIcon) {
      nodeSize = { ...nodeSize, width: 0 };
    }
    const { size: textSize, position, properties, value } = textComp;
    const parser = StyleHelper.createCSSStyleParser({});
    const boxStyle: React.CSSProperties = {
      maxWidth: item.children
        ? `calc(100% - ${nodeSize.width + expandSize.width + textAndIconSpace / 2}px)`
        : `calc(100% - ${nodeSize.width + textAndIconSpace / 2}px)`,
    };
    const { size: textStyleSize, style } = parser.getTextStyleData(textSize, properties.textStyle);
    style.width = undefined;
    // calc(200%)是因为有scale（0.5），使用scale文档流中实际长度不变，缩小后的文本长度需要扩大一倍
    const textMaxWidth = item.children
      ? `calc(200% - ${expandSize.width + textAndIconSpace / 2}px)`
      : `calc(200% - ${textAndIconSpace / 2}px)`;
    const textStyle: React.CSSProperties = {
      ...textStyleSize,
      ...style,
      top: position.y,
      maxWidth: textMaxWidth,
    };

    return (
      <div className="comp-navigation-menu-textBox" style={boxStyle}>
        <div className="comp-navigation-menu-text" style={textStyle}>
          {transBlankChart(`${value}`)}
        </div>
      </div>
    );
  };

  const renderSelectBack = () => {
    const {
      comp,
      lineHeight,
      paddingRight,
      paddingLeft,
      checkedFill,
      checkedMarkerStrip,
      checkedMarkerStripWidth,
    } = props;
    if (!comp.selected) {
      return null;
    }
    return (
      <div
        className="item-selected-background"
        style={{
          width: `calc(100% + ${paddingLeft + paddingRight}px)`,
          height: lineHeight,
          left: -paddingLeft,
          ...checkedFill,
        }}
      >
        <span
          className="item-selected-markerStrip"
          style={{ height: lineHeight, ...checkedMarkerStrip, width: checkedMarkerStripWidth }}
        ></span>
      </div>
    );
  };

  const renderInteractionBack = React.useMemo(() => {
    const { comp, lineHeight, paddingRight, paddingLeft, hotAreaVisibleModel } = props;
    if (!hotAreaVisibleModel || !hasInteraction(comp)) {
      return null;
    }
    return (
      <div
        className={`item-interaction-background item-interaction-background-${hotAreaVisibleModel}`}
        style={{
          width: `calc(100% + ${paddingLeft + paddingRight}px)`,
          height: lineHeight,
          left: -paddingLeft,
        }}
      ></div>
    );
  }, [props.comp.interactions.length, props.hotAreaVisibleModel]);

  const renderMain = () => {
    const { lineHeight, intention, level, isPreview, comp, item } = props;
    return (
      <div
        className={classnames('item-main', {
          'item-interaction-flag': !isPreview && !item.children?.length && hasInteraction(comp),
          'component-cursor-pointer': isPreview,
        })}
        style={{ lineHeight: `${lineHeight}px`, height: lineHeight }}
        onClick={handleItemClick}
        onMouseDown={handleItemMouseDown}
        onDoubleClick={handleItemDoubleClick}
        onMouseUp={handleItemMouseUp}
        onMouseEnter={handleItemMouseEnter}
        onMouseLeave={handleItemMouseLeave}
        onContextMenu={handleItemContext}
      >
        {renderSelectBack()}
        {renderInteractionBack}
        <div className="item-main-inner" style={{ marginLeft: intention * level }}>
          {renderNodeIcon()}
          {renderText()}
          {renderExtendIcon()}
        </div>
      </div>
    );
  };

  const render = () => {
    return (
      <div className="lib-comp-navigation-menu-item">
        {renderMain()}
        <div className="item-child">{props.children}</div>
      </div>
    );
  };
  return render();
};

export default React.memo(NavigationMenuItem);
