import { Base } from './base';

/**
 * 团队等级
 * @deprecated RP中没用到这个 RP用的TeamVersion
 */
export enum TeamLicense {
  FREE = 'free',
}

export enum TeamState {
  DEFAULT = 0,
  DELETE = 4,
}

export interface ITeams extends Base {
  id: string;
  name: string;
  // TODO 需要转化成枚举
  license: string;
  // FIXME 不知道其他版本的team有没有什么区别
  teamType?: string; // 团队类型，teambition，lark
  // rp设计到期时间
  rpDesignExpireTime?: Date;
  // rp设计购买类型
  // TODO 需要转化成枚举
  rpDesignPayment?: string;
}

// 個人空間例子項目数量限制
export const overseasAppCountLimit = {
  freeOrExpired: 3,
};

/**
 * 团队版本
 */
 export enum TeamVersion {
  // 中文
  Free = 'free', // 免费版 -- 如果无限设计包没有过期，功能限制跟新创业版保持一致，但版本还是为free
  startupCC = 'startupCC', // CC创业版，只作用于CC，在RP中为免费版
  entCC = 'entCC', // CC企业版，只作用于CC，在RP中为免费版
  Startup = 'startup', //  经典创业版
  Ent = 'ent', // 经典企业版 -- 如果payment === 'perpetual'则为终身特权版
  StartupRP = 'startupRP', // 新创业版
  EntRP = 'entRP', // 新企业版
  SuperEnt = 'superEnt', // 超级企业版
  // 英文
  EnFree = 'free', // 免费版（包括个人空间、团队）
  Prorp = 'prorp', // 个人空间的升级版(分为包年和终身)
  Pro = 'pro',  // 专业版(作用于团队，分为包月和包年) -- 同为free，RP中仍会受到功能限制
  Ultimate = 'ultimate' // 旗舰版(作用于团队，分为包月和包年)
}

/**
 * RP 团队版本时间类型
 */
export enum RPTeamPayment {
  // 免费版
  Free = 'free',
  // 永久
  Perpetual = 'perpetual',
  // 时间限制
  TimeLimit = 'timeLimit',
  // 包月
  Monthly = 'monthly',
  // 包年
  Annual = 'annual',
}
